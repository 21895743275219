import {copyObjectValues, deepCompareObjects, formatDateMMDDYYY, getTranslationFile} from "../../../../class/utils.js";
import {ReactComponent as CalendarsIcon} from "../../../../styles/images/icons/calendars.svg";
import {API_URL, BUTTON_TYPE, BUTTON_VARIANT, SIZES} from "../../../../class/constants";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../../../class/networkUtils";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import RadioButton from "../../../../newComponents/RadioButton.js";
import Button from "../../../../newComponents/Button.js";

import NewBanner from "../../../NewBanner.js";
import CostCentersTable from "./CostCentersTable";
import AddNewStateModal from "./addNewStateModal.js";
//FIXME: remove the below component and use the same one above twice

const lang = getTranslationFile();

const CostCenters = (props, ref) => {

    const [costCentersData, setCostCentersData] = useState(null);
    const [showExistingCostCenter, setShowExistingCostCenter] = useState(false);// set default state for radio buttoins, no should be checked by default
    const [introText, setIntroText] = useState("");
    const [availableNewCostCenters, setAvailableNewCostCenters] = useState(false);
    const [costCenterStates, setCostCenterStates] = useState({});
    const newCostCentersTableRef = useRef();
    const existingCostCentersTableRef = useRef();
    const addNewStateRef = useRef();

    const {endDate} = props;
    const {period} = props;
    const {startDate} = props;
    const {configureTieOutNext} = props;
    const {renderMonthlyBuildBanner} = props;

    const bannerText = lang.monthly_build.tie_out_pl_gl.period_banner
    .replace("[start_date]", formatDateMMDDYYY(startDate))
    .replace("[end_date]", formatDateMMDDYYY(endDate))
    .split("[period]");

        useImperativeHandle(ref, () => ({
            costCenterCannotBePredicted:() =>{
                return costCentersData?.costCenterCannotBePredicted;
            },
            getNewCostCentersWithLatestPeriod: () => {
                return newCostCentersTableRef?.current?.getData();
            },
            saveCostCenters: (callback) => {
                return saveCostCenters(callback);
            }
        }));

    useEffect(() => {
        formatMessage();
        setCostCenterStates(costCentersData?.costCenterStates);
    }, [costCentersData]);

    useEffect(() => {
        if (period) {
            getCostCentersData();
        }
    }, [period]);

    const formatMessage = () => {
        let fullText = lang.monthly_build.cost_centers.intro_text;
        if (!costCentersData?.costCenterCannotBePredicted) {
            fullText += costCentersData?.newCostCentersWithLatestPeriod?.length > 0 ? lang.monthly_build.cost_centers.new_text : lang.monthly_build.cost_centers.no_new_text;
        }
        let lines = fullText.split('\n');
        let message = lines.map((line, index) => (
            <div key={index} className="fs-14 uk-margin-small-bottom">{line}</div>));

        setIntroText(message);
    }

    const saveCostCenters = (callback) => {
        let modifiedNewCostCentersForAllPeriods = modifyNewCostCentersForAllPeriods();
        const periodsToUpload = [...new Set(modifiedNewCostCentersForAllPeriods.map(costCenter => costCenter.period))];

        const hasPredictedCostCenters = costCentersData?.existingCostCentersForCurrentPeriod.some(costCenter => costCenter.is_predicted);
        if (!periodsToUpload.includes(period) && (hasPredictedCostCenters || existingCostCentersListIsModified())) {
            periodsToUpload.push(period);
        }

        let costCentersToUpload = getCostCentersToUpload(period, periodsToUpload, modifiedNewCostCentersForAllPeriods);

      
        generateCostCentersStates(periodsToUpload, costCentersToUpload, callback);
       
    }

    const modifyNewCostCentersForAllPeriods = () => {
        let newCostCenters = costCentersData?.newCostCenters;

        let newCostCentersWithLatestPeriod = newCostCentersTableRef?.current?.getData();

        return newCostCenters.map(newCostCenter => {
            const matchingUpdateItem = newCostCentersWithLatestPeriod.find(updatedNewCostCenter => (
                newCostCenter?.cost_center === updatedNewCostCenter?.cost_center
            ));

            if (matchingUpdateItem) {
                newCostCenter.cost_center_state = matchingUpdateItem.cost_center_state;
            }

            return newCostCenter;
        });
    }

    const getCostCentersToUpload = (currentPeriod, periodsToUpload, newCostCentersForAllPeriods) => {
        let costCentersToUpload = [];

        periodsToUpload.forEach(periodToUpload => {
            const newCostCentersByPeriod = newCostCentersForAllPeriods.filter(newCostCenterObj => newCostCenterObj.period === periodToUpload);
            costCentersToUpload.push(...newCostCentersByPeriod);

            if(periodToUpload === currentPeriod) {
                let existingCostCentersForCurrentPeriod = showExistingCostCenter ? existingCostCentersTableRef?.current?.getData() : costCentersData.existingCostCenters;

                const existingMatchingCostCenters = existingCostCentersForCurrentPeriod.filter(originalNewCostCenter => (
                    periodToUpload === originalNewCostCenter?.period
                ));
                costCentersToUpload.push(...existingMatchingCostCenters);
            } else {
                const existingMatchingCostCenters = costCentersData.existingCostCenters.filter(originalNewCostCenter => (
                    periodToUpload === originalNewCostCenter?.period
                ));
                costCentersToUpload.push(...existingMatchingCostCenters);
            }
        });
        
        return costCentersToUpload;
    }

    const generateCostCentersStates = (periodsToUpload, costCentersToUpload, callback) => {
        let periodToUpload = periodsToUpload.map(period => {
            return {"period": period}
        });

        const query = {
            action: "generateCostCenterStates",
            edited_periods: JSON.stringify(periodToUpload),
            cost_centers: JSON.stringify(costCentersToUpload),
            isMonthlyBuild: true
        }
        let onThenCallback = (data) => {
            if (!data || data.SUCCESS !== "true"){
                setCostCentersErrorMessage(lang.monthly_build.cost_centers.generate_cost_centers_error_message);
            }
            if(callback){
                configureTieOutNext(callback)
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "generateCostCenterStates",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    const existingCostCentersListIsModified = () => {
        const tabulatorExistingCostCenterDataForCurrentPeriod = existingCostCentersTableRef?.current?.getData();
        const existingCostCenterDataForCurrentPeriod = costCentersData.existingCostCentersForCurrentPeriod;

        if (!tabulatorExistingCostCenterDataForCurrentPeriod || !existingCostCenterDataForCurrentPeriod) {
            return false;
        }

        return !deepCompareObjects(tabulatorExistingCostCenterDataForCurrentPeriod, existingCostCenterDataForCurrentPeriod);
    }

    const getCostCentersData = () => {
        var query = {
            action: "getCostCentersData", //It's still required by networkUtils.js
            period: period,
        };

        let onThenCallback = (data) => {
            setCostCentersData(data);
            let isNewCostCentersAvailable = data.newCostCentersWithLatestPeriod?.length > 0;
            setAvailableNewCostCenters(isNewCostCentersAvailable);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getCostCentersData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/get-cost-centers",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]: period
        };
        fetchAPI(fetchOptions);
    };

    const handleShowCostCenterChange = (param) => {
        let show = param === lang.modal.buttons.yes;
        if(param === lang.modal.buttons.no){ //In case you chose no take original existing data
            existingCostCentersTableRef?.current?.replaceDataWithParam(costCentersData?.existingCostCentersForCurrentPeriod)
        }
        setShowExistingCostCenter(show);
    }

    const renderAddNewStateButton = () => {
        return  <Button
                    id="add-new-state-btn"
                    label={lang.manage_cost_centers.add_state}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={()=>addNewStateRef?.current.openAddNewStateDialog(true)}
                    leftIcon={<i className={"fa-lg fal fa-plus-circle"}/>}
                />
    }

    const addToCostCenterStates = (state) => {
        let costCenterStatesModified = costCenterStates;
        costCenterStatesModified.push(state);
        setCostCenterStates(costCenterStatesModified);
        existingCostCentersTableRef?.current?.replaceData();
        newCostCentersTableRef?.current?.replaceData();
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "32px",
                width: "100%"
            }}
        >
            <div className="small-gap-flex">
          <span className="p-text small-padding-bottom">
            {lang.monthly_build.cost_centers.step_5}
          </span>
                <span className="h1-text small-padding-bottom">
            {lang.monthly_build.cost_centers.cost_centers_title}
          </span>
                <NewBanner
                    label={
                        <span>
                        {bannerText[0]}
                        <b>{period}</b>
                        {bannerText[1]}
                        </span>
                    }
                    bannerClassName={"banner-info full-width"}
                    icon={<CalendarsIcon />}
                    labelClassName={"banner-text yellow-text"}
                />
                {renderMonthlyBuildBanner()}
        </div>
        {!costCentersData?.costCenterCannotBePredicted?
        <>
        <div className="small-gap-flex">
        <div >{introText}</div>
        {availableNewCostCenters ?
            <div className="small-gap-flex">
                <div className="uk-text-bolder fs-14">
                {lang.monthly_build.cost_centers.new_title.replace(
                    "[count]",
                    costCentersData.newCostCentersWithLatestPeriod?.length
                )}
                <span style={{float:"right"}}>
                    {renderAddNewStateButton()}
                </span>
                </div>
                <CostCentersTable
                    id="define_new_cost_center"
                    ref={newCostCentersTableRef}
                    data={!newCostCentersTableRef?.current?.getData()?.length  ? copyObjectValues(costCentersData?.newCostCentersWithLatestPeriod) : newCostCentersTableRef?.current?.getData()}
                    columns={costCentersData?.columns}
                    costCenterStates={costCenterStates}
                />
                <div>
                    <Button
                        id="close-btn"
                        label={ showExistingCostCenter ? lang.monthly_build.cost_centers.hide_cost_centers : lang.monthly_build.cost_centers.view_cost_centers}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={() => setShowExistingCostCenter(!showExistingCostCenter)}
                        leftIcon={<i className={"fal " + (showExistingCostCenter ? "fa-chevron-up" : "fa-chevron-down")}/> }
                    />
                </div>
            </div>
        :' '
        }
        {!availableNewCostCenters &&
            <div className="uk-display-flex radio-button-flex">
                <div>
                    <RadioButton
                        className="radioButton uk-margin-remove-top uk-margin-large-right"
                        name={lang.modal.buttons.yes}
                        value={lang.modal.buttons.yes}
                        checked={showExistingCostCenter}
                        onChange={() => handleShowCostCenterChange(lang.modal.buttons.yes)}
                        label={lang.modal.buttons.yes}
                    />
                </div>
                 <div>
                    <RadioButton
                        className="radioButton uk-margin-remove-top uk-margin-small-right"
                        name={lang.modal.buttons.no}
                        value={lang.modal.buttons.no}
                        checked={!showExistingCostCenter}
                        onChange={() => handleShowCostCenterChange(lang.modal.buttons.no)}
                        label={lang.modal.buttons.no}
                    />
                </div>
            </div>
        }
    </div>

                    { (
                        <div className={"small-gap-flex " + (costCentersData && showExistingCostCenter ? "" :"uk-hidden")}>
                            <div className="uk-text-bolder fs-14">
                                {lang.monthly_build.cost_centers.existing_title.replace(
                                    "[period]",
                                    period
                                )}
                                {
                                    !availableNewCostCenters && 
                                    <span style={{float:"right"}}>
                                        {renderAddNewStateButton()}
                                    </span>
                                }
                            </div>
                            <CostCentersTable
                                id="define_existing_cost_center"
                                ref={existingCostCentersTableRef}
                                data={!existingCostCentersTableRef?.current?.getData()?.length ? copyObjectValues(costCentersData?.existingCostCentersForCurrentPeriod) : existingCostCentersTableRef?.current?.getData()}
                                columns={costCentersData?.columns}
                                costCenterStates={costCenterStates}
                            />
                        </div>
                    )}

                </>
                :
                <>
                    <div style={{width: "100%"}}>{introText}</div>
                    <NewBanner
                        id={"undefined-cost-centers-banner"}
                        bannerClassName={"banner-error banner-body-configure fs-12"}
                        body={"Undefined cost centers detected for previous periods! Please contact your admin or Profit Isle representative for assistance."}
                    />
                </>
            }
            <AddNewStateModal ref = {addNewStateRef} costCenterStates = {costCenterStates} addToCostCenterStates={addToCostCenterStates}/>
        </div>
    );
};

export default forwardRef(CostCenters);
