import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Button from "../../newComponents/Button";
import { ReactComponent as LeftArrow } from "../../styles/images/icons/chevron-left-regular.svg";

import { ReportHeader } from "../ReportHeader";
import '../../styles/TLCLandscape.css';

import "../styles/reportBody.css";

import {
  ALL_WIDGETS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CLIENT_PROFIT_SEGMENT_DICTIONARY,
  DROPDOWN_TYPE,
  HEADER_ELEMENT,
  ROLLING_SEGMENTS,
  SIZES,
  SUPPLY_CHAIN_SETUP,
  TLC_CHART_COLORS
} from "../../class/constants";
import { lang } from "../../language/messages_en";

import {
  getLocalStorageValueByParameter,
    handleCurrency,
    logout,
    updateWindowLoading,
} from "../../class/common";
import { saveCookie } from "../../class/jqueries";
import {areObjectsNotEmpty, copyObjectValues, findOptionByKey, tryParse } from "../../class/utils";
import ErrorBoundary from "../../ErrorBoundary";
import {
  updatePeriodsStatusState, updateVectorState,
} from "../../actions/scenariosActions";
import { fetchConfiguration, getClientPeriods, getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated } from "../CustomHooks";
import {
  renderButton,
  renderTitle,
} from "../functions/componentFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import DropDown from "../../newComponents/DropDown";
import { Loader, ToggleTab } from "../../form/elements";
import { TLCLandscapeTrendChart } from "../../supplyChain/TLCLandscapeTrendChart";
import { TLCLandscapeBarChart } from "../../supplyChain/TLCLandscapeBarChart";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import { getPreviousXPeriods } from "../../class/date";
import { convertPxToViewport } from "../../class/formatting";
import { SelectEntitiesDropdown } from "../../components/tlcLandscape/SelectEntitiesDropdown";
import { updateVectorEntities } from "../actions/vectorEntitiesActions";
/**
 * @author Mostafa Haydar
 * @description component renders {@link } component 
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link }
 */
const TLCLandscapeWrapper = (props) => {
  const SEGMENTS = [
    {
        label: "Drain",
        value:"D",
        segment:"D",
    },
    {
        label: "Flat",
        value:"F",
        segment:"F",
    },
    {
        label: "Peak",
        value:"P",
        segment:"P",
    }
  ];
  const _uniqueKey = "uniqueKey";

  /**Store */
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;

  const vectorEntitiesFromStore = useSelector(state => state.vectorEntities);
  
  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  
  const reportRef = useRef();
  const chartRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [view, setView] = useState(lang.TLC_SELECTION.VIEWS[0].value);
  const [lense, setLense] = useState(lang.TLC_SELECTION.LENSES[0].value);
  const [callFetch, setCallFetch] = useState(false);
  const [lenses, setLenses] = useState(lang.TLC_SELECTION.LENSES);
  const [vectorState, setVectorState] = useState();

  /**Header Related States */
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.TLC_LANDSCAPE);
  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.TLC.TLC_LANSCAPE);
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [callGo, setCallGo] = useState(false);

  const [totalCost, setTotalCost] = useState([]);
  const [revenueReturnName, setRevenueReturnName] = useState({});
  const [totalMargin, setTotalMargin] = useState([]);
  const [trendData, setTrendData] = useState([]);

  const [isTrend, setIsTrend] = useState(false);
  const [configurationData, setConfigurationData] = useState();
  const [selectedBucket, setSelectedBucket] = useState({});
  const [trendViewType, setTrendViewType] = useState({});

  const [vectorEntities, setVectorEntities] = useState([]);
  const [appliedVectorEntities, setAppliedVectorEntities] = useState();
  const [shouldFetchEntities, setShouldFetchEntities] = useState(false); // this state is used to trigger fetchVectorEntities() when needed (when we chnage value in period dropdown)
  const [dataBeforeTrend, setDataBeforeTrend] = useState({});

  let showSkeleton = !(
    Object.keys(periodsStatusState).length > 0 &&
    Object.keys(scenarioState).length > 0 &&
    headerElements.length > 0
  );
  
  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);


  useEffect(() => {
    if (scenarioState) {
      fetchConfiguration(true, setConfigurationData)
      setPeriodsStatusState({});
      setClientPeriodsState({});
      setVectorState({});
      setShowGreyOverLay(true);
      getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, undefined, userSettings, vectorState);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(clientPeriodsState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [periodsStatusState, clientPeriodsState, showGreyOverLay, headerChangedCounter, reportRef.current, view, lense, revenueReturnName, lenses, isTrend, vectorEntities, appliedVectorEntities]);

  /**
   * If we click on header apply button, fetch bar data.
   * In case we are in department/channel lense, and no entities are fetched, fetch entities and then fetch bar data
   */
  useEffect(()=>{
    if (lense && callFetch && !showGreyOverLay) {
      if([lang.TLC_SELECTION.LENSES[2].value, lang.TLC_SELECTION.LENSES[3].value].includes(lense)) {
        fetchEntitiesByType(lense);
      } else {
        fetchBarData();
      }
    }
  },[lense])

  useEffect(()=>{
    callRenderChart();
  },[view, revenueReturnName, appliedVectorEntities])

  useEffect(() => {
    if(selectedBucket && isTrend){
      fetchBarData();
    } else if (!isTrend){
      callRenderChart();
    }
  }, [selectedBucket, isTrend]);


  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton]);

  /**
   * Fetch vector entities when changing lense and when we have a grey overlay.
   * If we don't have grey overlay, and no entities were fetched, they will be fetched in another useEffect.
   */
  useEffect(() => {
    let areStatesNotEmpty = areObjectsNotEmpty([periodsStatusState, clientPeriodsState]);
    if (areStatesNotEmpty && configurationData?.vectorsConfiguration && vectorState?.vectorOptions && showGreyOverLay) {
      if([lang.TLC_SELECTION.LENSES[2].value, lang.TLC_SELECTION.LENSES[3].value].includes(lense)) {
        fetchEntitiesByType(lense);
      }
    }
  }, [periodsStatusState, lense, configurationData, vectorState]);

  /**
   * Remove lense tab if its vector is not defined in the vectors setup tab or if it is not found in vectors options.
   */
  useEffect(() => {
    if (configurationData?.vectorsConfiguration && vectorState?.vectorOptions) {
      let profitSegmentsVectorName = getVectorNameFromConfig(SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.LOWEST_VECTOR);
      let productHierarchyVectorName = getVectorNameFromConfig(SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.PRODUCT_HIERARCHY);
      let channelVectorName = getVectorNameFromConfig(SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.CHANNEL);

      let visibleLenses = copyObjectValues(lenses);

      if (profitSegmentsVectorName === "" || !isVectorFoundInVectorOptions(vectorState.vectorOptions, profitSegmentsVectorName)) {
        visibleLenses = visibleLenses.filter(e => e.value !== lang.TLC_SELECTION.LENSES[1].value);
      }
      
      /** Checking vector for product hierarchy and setting the label of selected vector if defined */
      if (channelVectorName === "" || !isVectorFoundInVectorOptions(vectorState.vectorOptions, channelVectorName)) {
        visibleLenses = visibleLenses.filter(e => e.value !== lang.TLC_SELECTION.LENSES[3].value);
      } else {
        let vector = getVectorFromVectorOptions(vectorState.vectorOptions, channelVectorName);
        visibleLenses.find(e => e.value === lang.TLC_SELECTION.LENSES[3].value).label = vector?.label;
      }
      
      /** Checking vector for product hierarchy and setting the label of selected vector if defined */
      if (productHierarchyVectorName === "" || !isVectorFoundInVectorOptions(vectorState.vectorOptions, productHierarchyVectorName)) {
        visibleLenses = visibleLenses.filter(e => e.value !== lang.TLC_SELECTION.LENSES[2].value);
      } else {
        let vector = getVectorFromVectorOptions(vectorState.vectorOptions, productHierarchyVectorName);
        visibleLenses.find(e => e.value === lang.TLC_SELECTION.LENSES[2].value).label = vector?.label;
      }
      setLenses(visibleLenses);
    }
  }, [configurationData, vectorState]);

  /**
   * Checks if the vector addded in supply chain setup is found in vector options.
   * @param {JSONArray} vectorOptions vector options fetched in getVectors()
   * @param {String} vectorName vector machine_name/value (ex: customer_123)
   * @returns true if vector is found in vector options
   */
  const isVectorFoundInVectorOptions = (vectorOptions, vectorName) => {
    return vectorOptions?.filter(e => e.value === vectorName)?.length > 0;
  }

  /**
   * Find the vector selected in supply chain setup and return its object from vector options.
   * @param {JSONArray} vectorOptions vector options fetched in getVectors()
   * @param {String} vectorName vector machine_name/value (ex: customer_123)
   * @returns vector object
   */
  const getVectorFromVectorOptions = (vectorOptions, vectorName) => {
    return vectorOptions?.find(e => e.value === vectorName);
  }
  
  /**
   * Get the vector name (ex: customer_123) from supply chain setup vectors configuration
   * @param {String} type (departement or channel)
   * @returns vector name (ex: customer_123)
   */
  const getVectorNameFromConfig = (type) => {
    if(configurationData?.vectorsConfiguration) {
      let vector = configurationData?.vectorsConfiguration?.filter(e => e[SUPPLY_CHAIN_SETUP.VECTOR_TYPE] === type)[0];
      let vectorName = vector.vector_name;
      return vectorName;
    }

    return "";
  }

  /**
   * Find vector based on type (channel/department) and fetch its entities.
   * @param {String} type (channel/department)
   */
  const fetchEntitiesByType = (lense) => {
    let type = lense === lang.TLC_SELECTION.LENSES[2].value ? SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.PRODUCT_HIERARCHY : SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.CHANNEL;
    let vectorName = getVectorNameFromConfig(type);
    let vector = getVectorFromVectorOptions(vectorState.vectorOptions, vectorName);
    fetchVectorEntities(vector);
  }

  /**
   * If shouldFetchEntities is true then send a request to fetch the vector entities with the new selected period.
   * Based on type we send the related vector.
   * We set shouldFetchEnttiies back to false when already fetch them.
   */
  useEffect(() => {
    if(shouldFetchEntities && [lang.TLC_SELECTION.LENSES[2].value, lang.TLC_SELECTION.LENSES[3].value].includes(lense)) {
      fetchEntitiesByType(lense);
      setShouldFetchEntities(false);
    }
  }, [shouldFetchEntities]);

  const viewTrend=(e)=>{
    const obj = {};
    obj.lense = lense;
    obj.appliedVectorEntities = copyObjectValues(appliedVectorEntities);
    obj.vectorEntities = copyObjectValues(vectorEntities);
    let isMarginView = view === lang.TLC_SELECTION.VIEWS[0].value;
    setSelectedBucket(e);
    setIsTrend(true);
    setTrendViewType(isMarginView?lang.trend_types[0]: lang.trend_types[1]);
    setDataBeforeTrend(obj);
  }

  const onBack=()=>{
    setIsTrend(false); 
    setTrendViewType({});
    setLense(dataBeforeTrend.lense);
    if(dataBeforeTrend.appliedVectorEntities){
      setVectorEntities(dataBeforeTrend.vectorEntities);
      setAppliedVectorEntities(dataBeforeTrend.appliedVectorEntities);
    }
  }

  /**HeaderElements Related Functions */
  const getHeaderElements = () => {
    let headerElements = [];
    let periods = periodsStatusState.builtPeriods.filter(e=>e.generated && e.rolling === 12);
    let period = clientPeriodsState.period || periods[0];

    let trend_types = copyObjectValues(lang.trend_types);
    trend_types.map(e=> e.label = handleCurrency(e.label));
    trend_types[0].label = trend_types[0].label.replace("%REVENUE%",revenueReturnName.name);
    let isMarginView = view === lang.TLC_SELECTION.VIEWS[0].value;
    let valueObj = trendViewType?.value? findOptionByKey(trend_types, trendViewType): isMarginView?trend_types[0]: trend_types[1];// period && period.value ? findOptionByKey(periods, period.value) : findOptionByKey(clientPeriodsState.periodOptions, period);

    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className='first_header_row gap_between_buttons'>
        {renderTitle(reportTitle)}
        {renderPeriodsDropdown(period, periods)}
        {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}
        <div className={(isTrend? "disabled":"")} style={{ display: "flex", flexDirection: "row", marginLeft: "auto", width:convertPxToViewport(200)}}>
          <ToggleTab options={lang.TLC_SELECTION.VIEWS} defaultValue={lang.TLC_SELECTION.VIEWS[0].value}
            onSelectTab={(tab) => {if(!isTrend){setView(tab)}}}
          />
        </div>
      </div>
    );
    headerElements.push(
      <div className='second_row_header' style={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
        <div style={{display: "flex", width: "100%", alignItems:"flex-start", columnGap:'0.5vw'}}>
          {isTrend ? renderTrendHeader() : renderChartTitle()}
        </div>
        <div className="uk-margin-small-top" style={{ display: "flex", width: "100%", justifyContent: "flex-end", columnGap: "0.55vw" }}>
          {isTrend && <div> 
            <DropDown
              id="trenViewType"
              className="uk-width-small input__dropdown"
              value={valueObj}
              onChange={(e) => handleElementChange(HEADER_ELEMENT.TREND_VIEW_TYPE, e)}
              options={trend_types}
              type={DROPDOWN_TYPE.INPUT}
            />
          </div>}
          <div style={{ display: "flex", width: (convertPxToViewport(lenses.length * 100)) }}>
            <ToggleTab
              options={lenses}
              defaultValue={lense || lang.TLC_SELECTION.LENSES[0].value}
              onSelectTab={(tab) => {
                if(tab === lense){
                  return;
                }
                setLense(tab);
                setCallFetch(true);
              }}
            />
          </div>
          {[lang.TLC_SELECTION.LENSES[2].value, lang.TLC_SELECTION.LENSES[3].value].includes(lense) && (
            <SelectEntitiesDropdown vectorEntities={vectorEntities} onSelectEntitiesApply={onSelectEntitiesApply} isTrend={isTrend}/>
          )}
        </div>
      </div>
    );
  
    return headerElements;
  };

  const renderPeriodsDropdown = (period, periods) =>{
    let valueObj = period && period.value ? findOptionByKey(periods, period.value) : findOptionByKey(clientPeriodsState.periodOptions, period);
    return (<div className={"periodRangeContainer" + (isTrend? " disabled":"")} key={Math.random()}>
      <DropDown
        id="PeriodRange"
        className="uk-width-medium input__dropdown"
        name="PeriodRange"
        value={valueObj}
        onChange={(e) => handleElementChange(HEADER_ELEMENT.PERIOD, e)}
        options={periods}
        type={DROPDOWN_TYPE.INPUT}
        isTextAddedToIndicator = {true}
      />
    </div>);
  }

  const handleElementChange = (name, e) => {
    switch (name) {
      case HEADER_ELEMENT.PERIOD: {
        if(isTrend){
          return;
        }
        let tempState = copyObjectValues(clientPeriodsState);
        tempState.period = e;
        setClientPeriodsState(tempState);
        setShowGreyOverLay(true);
        setShouldFetchEntities([lang.TLC_SELECTION.LENSES[2].value, lang.TLC_SELECTION.LENSES[3].value].includes(lense)); // set it to true if we are in a lense with select entities dropdown
        if(!props.additionalProps?.isEntity){
          saveCookie("period", e.value);
        }
        break;
      }
      case HEADER_ELEMENT.TREND_VIEW_TYPE:{
        setTrendViewType(e);
        break;
      }
    }
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
  };

  const onSelectEntitiesApply = (selectedEntities) => {
    setAppliedVectorEntities(selectedEntities);
    setCallFetch(true);
  }

  /**
   * Fetch bar data when select new enitites and we apply if we have a grey overlay.
   */
  useEffect(() => {
    if (appliedVectorEntities && callFetch && !showGreyOverLay) {
      fetchBarData()
    }
  }, [appliedVectorEntities])

  const apply = () => {
    fetchBarData();
    setShowGreyOverLay(false);
  };

  const fetchBarData=()=>{
    let periods = periodsStatusState.builtPeriods.filter(e=>e.generated && e.rolling === 12);
    let selectedPeriod = clientPeriodsState.period || periods[0];
    let prevPeriodsObj = getPreviousXPeriods(periodsStatusState.builtPeriods,periods.find(e=>e.value === selectedPeriod.value), 12, true);
    let prevPeriods = prevPeriodsObj.map(e=>{return e.value}).join("','");
    let entities = [];
    switch (lense) {
      case lang.TLC_SELECTION.LENSES[1].value: {// segment
        entities = SEGMENTS;
        break;
      }
      case lang.TLC_SELECTION.LENSES[2].value: //channel or department
      case lang.TLC_SELECTION.LENSES[3].value: {
        entities = appliedVectorEntities;
        break;
      }
    }
    entities.map(e=>e[_uniqueKey]=e.value);
    var query = {
      periods: prevPeriods,
      rollingSegment: ROLLING_SEGMENTS.R_12,
      action: "getTLCLandscapeData",
      scenario_id : scenarioState.scenario,
      lense: lense,
      isTrend: isTrend,
      selectedBucket: selectedBucket?.value,
      entities: entities
    }
    
    var onThenCallback = (data) => {
      data = data.data;
      if(isTrend){
        let trendData = data.trend_data;
        trendData.sort((a, b) => {
          return a.period.localeCompare(b.period);
        });
        setTrendData(trendData)
      }else{
        setTotalMargin(data.total_margin);
        setTotalCost(data.total_cost);
      }
      setCallFetch(false);
      setRevenueReturnName(data.revenue);
    }
    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getTLCLandscapeData",
      [FETCHAPI_PARAMS.path]: "/get-tlc-landscape-data",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
    }
    fetchAPI(fetchOptions);
  }

  const fetchVectorEntities = (vector) => {
    let periods = periodsStatusState.builtPeriods.filter(e=>e.generated && e.rolling === 12);
    let selectedPeriod = clientPeriodsState.period || periods[0];
    let prevPeriodsObj = getPreviousXPeriods(periodsStatusState.builtPeriods,periods.find(e=>e.value === selectedPeriod.value), 12, true);
    let prevPeriods = prevPeriodsObj.map(e=>{return e.value}).join("','");
    let query = {
      periods: prevPeriods,
      rollingSegment: ROLLING_SEGMENTS.R_12,
      action: "fetchVectorEntities",
      scenario_id : scenarioState?.scenario,
      vector: JSON.stringify(vector)
    }
    
    let onThenCallback = (data) => {
      if (data.data) {
        dispatch(updateVectorEntities([data], scenarioState?.scenario + "_" + vector.value + "_" + selectedPeriod.label));
        
        let formattedEntities = formatEntities(data.data);
        setVectorEntities(formattedEntities);
        setAppliedVectorEntities(formattedEntities.filter(f => f.checked));
      }
    }

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "fetchVectorEntities",
      [FETCHAPI_PARAMS.path]: "/get-vector-entities",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.dataKey]: "vectorEntities",
      [FETCHAPI_PARAMS.logout]: logout,
      [FETCHAPI_PARAMS.useStore]:  true,
      [FETCHAPI_PARAMS.scenarioId]: scenarioState?.scenario + "_" + vector.value + "_" + selectedPeriod.label
    }

    let comp = { props: { vectorEntities: vectorEntitiesFromStore, dispatch: dispatch } }
    fetchAPI(fetchOptions, comp);
  }

  
  const formatEntities = (ents) => {
    return ents?.map((entity, index) => {
      let isEntityChecked = [0, 1, 2].includes(index); //select the first 3 by default
      return {
        key: entity.key,
        label: entity.name,
        value: entity.key,
        checked: isEntityChecked,
        legendColor: isEntityChecked ? TLC_CHART_COLORS[index].color : "",
        bubbleColor: isEntityChecked ? TLC_CHART_COLORS[index].color : "",
      };
    });
  };
  
  const callRenderChart = ()=> {
    if (lense === lang.TLC_SELECTION.LENSES[0].value) {
      chartRef?.current?.renderBarChart(view === lang.TLC_SELECTION.VIEWS[0].value ? totalMargin : totalCost);
    } else {
      chartRef?.current?.renderStackedChart(view === lang.TLC_SELECTION.VIEWS[0].value ? totalMargin : totalCost);
    }
  }

  const renderChart = () =>{
    let entities = [];
    switch (lense) {
      case lang.TLC_SELECTION.LENSES[1].value: {// segment
        entities = SEGMENTS;
        break;
      }
      case lang.TLC_SELECTION.LENSES[2].value: //channel or department
      case lang.TLC_SELECTION.LENSES[3].value: {
        entities = appliedVectorEntities;
        break;
      }
    }
    if(isTrend){
      return <TLCLandscapeTrendChart trendData={trendData} lense={lense} lenses={lenses} entities={entities} view={view} trendViewType={trendViewType} revenueReturnName={revenueReturnName}/>
    } else {
      return !callFetch && <TLCLandscapeBarChart ref={chartRef} totalCost={totalCost} totalMargin={totalMargin} view={view} viewTrend={viewTrend} lense={lense} entities={entities} callFetch={callFetch} revenueReturnName={revenueReturnName}/>
    }
  }

  const renderTrendHeader = () =>{
    return(
    <>
      <div className="uk-display-flex">
        <Button
          leftIcon={<LeftArrow style={{ width: convertPxToViewport(14), height: convertPxToViewport(14) }} />}
          label={lang.navigation.btns.back}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={onBack}
        />
        <span className="bucket_title uk-margin-small-left uk-margin-xsmall-top">{selectedBucket?.label}</span>
      </div>
    </>)
  }

  const renderChartTitle = () =>{
    let title = "";
    if(revenueReturnName?.name){
      if(view === lang.TLC_SELECTION.VIEWS[0].value) {
        title = lang.viewing_pecentage_net_revenue.replace("%REVENUE%", revenueReturnName.name);
      } else {
        title = lang.viewing_pecentage_cogs;
      }
    }
    
    return <span style={{fontSize: convertPxToViewport(14), fontWeight: 700}}>{title}</span>
  }

  return (
    <>
      {showSkeleton && <LoaderSkeleton />}
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div id={"main-component-container"} className={"main-component-container "} style={{ "--banner-height": 0 }}>
            <Loader newLoader />
            <div className={"header-banner-div-hidden"} />
            <div className="main-report-header">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className={("main_report")+(showGreyOverLay?"":(" tlc_main_report_container"+(isTrend?"_trend":"")))}>
              {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{lang.overlay_go_msg}</div>}
              <div id="main_report_container" className={"main_report_container tlc_main_report_container"+(isTrend?"_trend":"")}>
                { !showSkeleton && renderChart() }
              </div>
            </div>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { TLCLandscapeWrapper };

