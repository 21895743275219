import React, { Component } from 'react';
import { getTranslationFile, tryParse, capitalizeFirstLetter} from '../class/utils.js';
import { COST_FUNCTIONS_FIELDS, INVOICELINETYPE, FormatTypes} from '../class/constants.js';
import { getReadableRuleText, getReadableFilterText } from '../class/common.js';
import { formatValString } from '../class/format';
import { convertPxToViewport } from '../class/formatting';
const MESSAGES = getTranslationFile();
const shortid = require("shortid");
class CostClassification extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
        }

       
    }

	fetchPeriods(config, index){
		var periods = this.props.periods;
		var periodstoPush = [];
		for (var em in periods) {
			var found = false;
			for (var e in config) {
				if (index !== e) {
					if (config[e].periods.includes(periods[em].value)) {
						found = true
					}
				}
			}
			if (!found) {
				periodstoPush.push(periods[em].value);
			}
		}
		return periodstoPush
	}

    getCostTooltipData(rowData){
        var comp = this;
		var html = MESSAGES.no_data_available;
        var rowDataFyCost = this.props.showFYData && !!rowData ? rowData["fy_cost"] : "";
        var rowDataFyCostPerc = this.props.showFYData && !!rowData ? rowData["fy_cost_perc"] : "";
        var rowDatacostCenter = this.props.showFYData && !!rowData ? rowData["costCenter"] : "";
        var returnName = !!rowData ? rowData["returnName"] : "";
        var description = !!rowData ? rowData["profit_stack_line_description"] : "";

		if(comp.props.costClassification){
			var costClassification = comp.props.costClassification.filter(e => e.returnname === returnName);
			var glCosts = "";
			var rules = [];
			var filters = [];
			var planTerm = MESSAGES.not_defined_value;
			if(costClassification.length > 0)  {
				glCosts = comp.props.glCosts.map(function(cost, index){
					var costName = cost.name + (costClassification[0][cost.name] && costClassification[0][cost.name].includes(", ") ? "s" : "");
					return <div key={returnName + "_cost_" + shortid.generate()} className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis whitespace-initial uk-text-capitalize">{ costName + ": " + costClassification[0][cost.name]}</div>
				});
				if (costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG].length > 0) {
					for (var e in costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG]) {
						var periods = costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG].length === 1 ? [] : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods && costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length === 0 ? comp.fetchPeriods(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG], e) : [costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods];
						var rule =  [undefined, ""].includes(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE]) ? MESSAGES.undefined_value : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE];
						var readableRule = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE ? rule.replace("_cc","") : getReadableRuleText(rule, comp.props.vectorOptions,comp.props.metricFields,comp.props.hiddenVectors);
						var ruleToShow = periods.length === 0 ? readableRule : readableRule + " (" + periods.join(",") + ")"
						rules.push(ruleToShow);
						var filter = [undefined, ""].includes(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER]) ? MESSAGES.psl_tooltip.none_value : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER];
						var readableFilter = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE || filter === MESSAGES.psl_tooltip.none_value
						? filter : getReadableFilterText(tryParse(filter, "") ? tryParse(filter, "").filter : "", comp.props.vectorOptions);
						var filterToShow = periods.length === 0 ? readableFilter : readableFilter + " (" + periods.join(",") + ")"
						filters.push(filterToShow);
					}
				}
				planTerm = [undefined, ""].includes(costClassification[0]["planterm"]) ? MESSAGES.not_defined_value : costClassification[0]["planterm"];
			}
			
			html = <div className="accruals-psl-configuration">
					{/* <div className="more-details-dialog-title uk-text-bold uk-text-center">{MESSAGES.psl_tooltip.profit_stack_line + name}</div> */}
					<div className="fs-12 more-details-dialog-body">
                        {this.props.showFYData ? 
                            <div className="uk-display-flex">
                                <div style={{width: 'auto', marginRight: convertPxToViewport(30)}}>
                        			<div className="uk-text-bold">{MESSAGES.psl_tooltip.fy_cost}</div>
									<div className="width-fit-content">{MESSAGES.psl_tooltip.amount}: {formatValString(rowDataFyCost, FormatTypes.AMOUNT)}</div>
									<div className="width-fit-content">{MESSAGES.psl_tooltip.percentage}: {formatValString(rowDataFyCostPerc, FormatTypes.PERCENTAGE)}</div>
								</div>
								<div>
									<div className="width-fit-content uk-text-bold">{"By "+ ([null, 'null', 'ALL', undefined].includes(rowDatacostCenter) ? this.props.costCenter : capitalizeFirstLetter(rowDatacostCenter))}</div>
									<div className="width-fit-content">{[null, 'null', 'ALL', undefined].includes(rowDatacostCenter) ? 'No': 'Yes'}</div>
								</div>
							</div>
						:""}
                        
						{description !== undefined && description !== "" &&
							<div className="uk-padding-small-top">
								<div className="uk-text-bold">{MESSAGES.psl_tooltip.description}</div>
								{description} test
							</div>				
						}
						<div className="uk-padding-small-top">
							<div className="uk-text-bold">{MESSAGES.psl_tooltip.gl_cost_source}</div>
							{glCosts}
						</div>
						<div className="uk-padding-small-top">
							<div className="uk-text-bold">{MESSAGES.psl_tooltip.cost_function}</div>
							<div title={rules.join(",")} className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis">{MESSAGES.psl_tooltip.rule + rules.join(",")}</div>
							<div title={filters.join(",")} className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis">{MESSAGES.psl_tooltip.filter + filters.join(",")}</div>
						</div>
						<div className="uk-padding-small-top">
							<div className="uk-text-bold">{MESSAGES.psl_tooltip.plan_term}</div>
							<div className="uk-padding-xxsmall-top">{planTerm}</div>
						</div>
					</div>
				</div>;	
		}
	
			return html;
		
	}
    
   

    render() {
       

        return (
            this.getCostTooltipData(this.props.rowData)
        );
    }
}

export default CostClassification;