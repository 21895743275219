import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../class/constants";

/**
 * RadioButton component
 * @param {string} id Id of the button
 * @param {string} className additional classNames
 * @param {Boolan} disabled Sets the button to be disbaled
 * @param {Boolan} label if there's a value next to the input it adds it 

 * @returns The rendered button component
 */
const RadioButton = ({
  checked,
  className,
  disabled,
  label,
  labelClassName,
  divWidth,
  ...props // other props that needs to be added. like: aria-label...
}, ref) => {
  useImperativeHandle(ref, () =>({
    setIsDisabled: (val) => {
      setIsDisabled(val)
    }
  }))

  // const [isDisabled, setIsDisabled] = useState(disabled); //This is not working as expected use disabled from props instead
  
  let radioClassName = "input-radio-default"; 
  radioClassName += disabled ? " input-radio-disabled" : "";

  return (
    <div style={{display: "flex", columnGap: 3, alignItems: "center", width: divWidth}} >
    <input
        {...props} 
        type="radio" 
        className={radioClassName}
        checked={checked}
        disabled={disabled}
    />
    {label ? <span className={"uk-margin-xsmall-left uk-margin-xsmall-top uk-margin-xsmall-right " + (labelClassName || "uk-text-medium")}>{label}</span> : ""}
    </div>
    
  );
};


export default forwardRef(RadioButton);
