import { lang } from "../language/messages_en"

const HEADER_ELEMENT= {
    VIEW: "view",
    GO: "go",
    FUNCTION: "function",
    SEPARATOR: "seperator",
    FY: "fy",
    CUSTOM_DATE: "custom_date",
    PERIOD: "period",
    START_DATE: "start_date",
    END_DATE: "end_date",
    QUARTER_SELECTION_RANGE: "quarter_selection_range",
    DATASET: "dataset",
    VECTOR: "vector",
    FORMAT: "format",
    XLS: "xls",
    CSV: "csv",
    DIMENSIONS: "dimensions",
    SCENARIO: "scenario",
    COMPARISON_SCENARIO: "comparison_scenario",
    LIMIT: "limit",
    CUSTOM_VIEW: "custom_view",
    ORDER: "order",
    ADD_COLUMNS: "add_columns",
    SETTINGS: "settings",
    FISCAL_YEARS: "fiscal_years",
    COMPARISON: "comparison",
    SELECTION_RANGE: "selection_range",
    SECONDARY_REPORT: "secondary_report",
    BRIDGE: "Bridge",
    MIX_VECTOR: "Mix Vector",
    QUARTER_RANGE: "quarter_range",
    HEADER_BUTTON_SAVE: "header_button_save",
    HEADER_BUTTON_SAVE_TWO: "header_button_save_two",
    HEADER_LOADER: "header_loader",
    HEADER_BUTTON_SAVE_GOTO_BUILD: "header_button_save_goto_build",
    HEADER_BUTTON_BACK: "header_button_back",
    PROFIT_STACK: "profit_stack",
    PERIOD_RANGE: "time_range",
    NAVIGATION_BACK: "navigation_back",
    ADD_DASHBOARDS: "add_dashboards",
    CREATE_DASHBOARDS: "create_dashboard",
    ADD_WIDGET: "add_widget",
    THREE_DOTS: "three_dots",
    ADD_TOGGLE_ELEMENT: "add_toggle_revenue",
    CHANGES_NOT_SAVED: "changes_not_saved",
    STACKS_INFO:"stacks_info",
    ADD_STACK:"add_stack",
    HEADER_BUTTON_MANAGE_BUILD: "header_button_manage_build",
    HEADER_BUTTON_REFRESH: "header_button_refresh",
    HEADER_BUTTON_PRINT:"header_button_print",
    HEADER_BUTTON_PERIODS:"header_button_periods",
    TOGGLE_MFA:"toggle_mfa",
    VIEW_STACK: "view_stack",
    LAST_GENERATED_TOKEN:"last_generated_token",
    TREND_VIEW_TYPE:"trend_view_type"
}

const header_comparison_values = {
    YOY: "year_over_year",
    PREV_AD: "previous_adjacent",
    TOP_QUAD: "top_quadrant",
    TOP_QUAD_TIER: "top_quadrant_tier",
    BP_QUAD_TIER: "bp_quadrant_tier"
}

const DROP_DOWN_ELEMENT = {
    clone : "Clone ",
    edit : "Edit ",
    resetPasswordAction : "Reset Password",
    deactivate : "Deactivate ",
    deleteUserAction : "Delete User",
    activate : "Activate ",
    define: "Define",
    resetMFA:"Reset MFA",
    unblock:"Unblock"
}
const COST_ASSIGNMENTS={
    FIELDS:{
        NAME:"Name",
        NUMBER:"_number",
        ASSIGNED_COST:"ac",
        ASSIGNED_PSL:"psl_perc",
        COUNT_LINES:"countline"
    },
    TITLES:{
        NAME:"Name",
        NUMBER:"Number",
        ASSIGNED_COST:"Assigned Cost",
        ASSIGNED_PSL:"Assigned % PSL",
        COUNT_LINES:"Lines"
    }
}

const time_range_values = {
    ytd: "period_ytd",
    qtd: "period_qtd",
    periods: "periods",
}

const CC_LABELS = {
    YES: "Yes",
    NO: "No",
    ON: "ON",
    OFF: "OFF",
}

const FY_VALUES = {
    ON: "ON",
    OFF: "OFF",
    M3: "M3",
    M6: "M6",
    M9: "M9",
    M12: "M12",
    FY: "FY",
}
const ORDER_VALUES = {
    DESC: "DESC",
    ASC: "ASC"
}

const INVOICE_LINE = {
    value: "InvoiceLine",
    label: "InvoiceLine",
    vector_group: "Operations"
}

const CROSSWALK_FIELDS = {
    ID: "raw_crosswalk_field_id",
    SOURCE_FILE_TYPE: "source_raw_file_type",
    SOURCE_FIELD_NAME: "source_raw_file_field_name",
    SOURCE_SUBTYPE: "source_raw_file_subtype",
    SOURCE_SUBTYPE_ID: "source_raw_file_subtype_id",
    TARGET_FILE_TYPE: "target_raw_file_type",
    TARGET_FIELD_NAME: "target_raw_file_field_name",
    TARGET_SUBTYPE: "target_raw_file_subtype",
    TARGET_SUBTYPE_ID: "target_raw_file_subtype_id",
    SOURCE_COLUMNS: "source_columns",
    TARGET_COLUMNS: "target_columns",
    SOURCE_CROSSWALK_FIELD_NAME: "source_cw_field_name",
    TARGET_CROSSWALK_FIELD_NAME: "target_cw_field_name",
    IS_UNIQUE: "isUnique",
    TARGET_ENTITY: "target_entity",
    SOURCE_ENTITY: "source_entity",
    START_PERIOD_ID: "start_period_id",
    END_PERIOD_ID: "end_period_id",
    START_PERIOD: "start_period",
    END_PERIOD: "end_period",
    SOURCE_PERIOD_FIELD_NAME: "period_field_name",
    FILE_NAME: "file_name",
    CROSSWALK_SUBTYPE: "crosswalk_subtype",
    CROSSWALK_FILE_ID: "raw_file_id",
    IS_OLD_ARCH: "is_old_arch"
}

const PI_CW = "_PI_CW";

/** upload data constants */
const UPLOAD_TITLES = {
    FILE_NAME_TITLE: "File Name",
    FILE_NUMBER_TITLE: "ID",
    UPLOADED_BY_TITLE: "Uploaded By",
    UPLOADED_DATE_TITLE: "Uploaded Date",
    STATUS_TITLE: "Status",
    DELIMITER_TITLE: "Delimiter",
    AMENDMENT_TITLE: "Amendments",
    DATA_DICTIONARY_TITLE: "Data Dictionary",
    RESPONSE_TITLE: "Response",
    RAW_FILE_ID_TITLE: "File ID",
    PROGRESS_TITLE: "Progress",
    DISPLAY_FILE_SUBTYPE: "Subcategory",
    START_PERIOD: "Start Period",
    END_PERIOD: "End Period",
    PERIODS: "Periods",
    ACTIONS: "Actions"
}

const UPLOAD_FIELDS = {
    FILE_NAME_FIELD: "file_name",
    FILE_NUMBER_FIELD: "file_number",
    UPLOADED_BY_FIELD: "created_by",
    UPLOADED_DATE_FIELD: "created_date",
    UPLOADED_STATUS_FIELD: "status",
    DELIMITER_FIELD: "delimiter",
    AMENDMENT_FIELD: "amendment",
    RESPONSE_FIELD: "response",
    RAW_FILE_ID_FIELD: "raw_file_id",
    PROGRESS_FIELD: "progress",
    ALL_PERIODS: "all_periods",
    FILE_SUBTYPE: "file_subtype",
    FILE_TYPE: "file_type",
    DISPLAY_FILE_SUBTYPE: "display_file_subtype",
    START_PERIOD: "start_period",
    END_PERIOD: "end_period",
    PERIODS: "periods",
    ACTIONS: "actions",
    PRIVATE: "is_private"
}

/** define data constants */
const DEFINE_TITLES = {
    FIELD_NAME_TITLE: "Field Name",
    FIELD_NUMBER_TITLE: "Field Number",
    DATA_TITLE: "Data",
    DATA_TYPE_TITLE: "Data Type",
    DESCRIPTION_TITLE: "Description",
    DISTINCT_VALUES_TITLE: "Distinct Values",
    ROW_NUMBER_TITLE: "Row Number",
    RAW_FILE_FIELD_ID_TITLE: "Raw File Field Id",
    DATA_TYPE_ID_TITLE: "Datatype Id",
    MIN_TITLE: "min",
    MAX_TITLE: "max", 
    FORMAT_TITLE: "Format",
    DECIMAL_TITLE: "decimal",
    MIN_LENGTH_TITLE: "minLength",
    MAX_LENGTH_TITLE: "maxLength",
    IS_TRAILING_TITLE: "isTrailing",
    TRAIL_OR_LEAD_TITLE: "trailOrLeadChar",
    TRUE_INPUT_TITLE: "trueInput",
    FALSE_INPUT_TITLE: "falseInput", 
    DATE_FORMAT_TITLE: "dateFormat",
    SAMPLES_TITLE: "Sample Values",
    CURRENCY_CONVERSION: "Currency Conversion"
}
const DEFINE_FIELDS = {
    FIELD_NAME_FIELD: "raw_field_name",
    DATA_FIELD: "name",
    DATA_TYPE_FIELD: "field_data_type",
    DESCRIPTION_FIELD: "description",
    DISTINCT_VALUES_FIELD: "distinct_value",
    ROW_NUMBER_FIELD: "row_number",
    RAW_FILE_FIELD_ID_FIELD: "raw_file_field_id",
    RAW_SUBTYPE_FIELD_ID_FIELD: "raw_subtype_field_id",
    DATA_TYPE_ID_FIELD: "raw_file_field_datatype_id",
    FORMAT_FIELD: "format",
    MIN_FIELD: "min",
    MAX_FIELD: "max",
    DECIMAL_FIELD: "decimal",
    MIN_LENGTH_FIELD: "minLength",
    MAX_LENGTH_FIELD: "maxLength",
    IS_TRAILING_FIELD: "isTrailing",
    TRAIL_OR_LEAD_FIELD: "trailOrLeadChar",
    TRUE_INPUT_FIELD: "trueInput",
    FALSE_INPUT_FIELD: "falseInput",
    DATE_FORMAT_FIELD: "dateFormat",
    SAMPLES_FIELD: "samples",
    CURRENCY_CONVERSION: "currency_conversion",
    CURRENCY_FIELD_NAME: "currency_field_name",
    CURRENCY_SUBTYPE_ID: "currency_subtype_id",
    STATIC:"is_static",
    CURRENCY_DATE: "currency_field_date",
    DEFINED_SUBTYPE_ID: "defined_subtype_id"
}
const DEFINE_CREDIT_DEBIT_ENTRY_TYPE = {
    ENTRY_TYPE: 'Entry Type',
    CREDIT: 'Credit',
    DEBIT: 'Debit',
}

/** history data constants */
const HISTORY_TITLES = {
    FILE_NAME: "File Name",
    FILE_NUMBER: "ID",
    EXECUTED_ACTION: "Action",
    USER: "User",
    EXECUTED_DATE: "Date",
    STATUS: "Status",
    SECTION: "Section",
    SUBSECTION: "Sub Section",
    REPLACED_FILES: "Replaced Files",
    ERROR_MESSAGE: "Error Message"
}
const HISTORY_FIELDS = {
    FILE_NAME: "file_name",
    FILE_NUMBER: "file_number",
    EXECUTED_ACTION: "executed_action",
    USER: "user",
    EXECUTED_DATE: "executed_date",
    STATUS: "status",
    SECTION: "section",
    SUBSECTION: "subsection",
    REPLACED_FILES: "replaced_files",
    ERROR_MESSAGE: "error_message"
}

const DATATYPE_ENUM = {
    STRING: "string",
    NUMERIC: "integer",
    BOOLEAN: "boolean",
    DATE: "date",
    DATETIME: "datetime",
    TIME: "time",
}

const FILE_CENSUS_FIELDS = {
    RAW_FILE_TYPE_ID_FIELD: "raw_file_type_id",
    RAW_FILE_TYPE_FIELD: "file_type",
    SECTION_FIELD: "section",
    SUB_SECTION_FIELD: "subsection",
    EXPECTED_FILES_FIELD: "expected_files",
    START_PERIOD_FIELD: "start_period",
    END_PERIOD_FIELD: "end_period",
    UPDATE_FLAG_FIELD: "update_flag",
    RAW_FILE_SUBTYPE_ID :"raw_file_subtype_id",
    INSERT_FLAG_FIELD: "insert_flag",
    PRIVATE: "is_private",
    NAMING_TEMPLATE : "naming_template",
    DELIMITER : "delimiter",
    HEADER_ROWS_TO_SKIP:"header_rows_to_skip",
    RESTRICTION_ACCESS:"restriction_access",
    ACTIONS: "actions",
    HAS_DEFINED_FILES: "has_defined_files",
    HAS_DEFINED_COLUMNS: "has_defined_columns",
    HAS_HEADER_COLUMNS: "has_header_columns",
    COLUMN_HEADER_NAMES: "column_header_names",
    FILE_COUNT: "file_count",
    SUB_CATEGORY_MUDULE:"sub_category_module",
    IS_AUTOMATED:"is_automated"
}

const FILE_CENSUS_TITLES = {
    RAW_FILE_TYPE_ID_TITLE: "raw_file_type_id",
    RAW_FILE_TYPE_TITLE: "file_type",
    SECTION_TITLE: "section",
    SUB_SECTION_TITLE: "Data Source",
    EXPECTED_FILES_TITLE: "Expected Files",
    START_PERIOD_TITLE: "From",
    END_PERIOD_TITLE: "To",
    UPDATE_FLAG_TITLE: "update_flag",
    INSERT_FLAG_TITLE : "insert_flag",
    NAMING_TEMPLATE : "File Naming Template",
    DELIMITER : "Delimiter",
    ENCRYPTION_EXTENSION : "Encryption Ext.",
    HEADER_ROWS_TO_SKIP:"Header Rows To Skip",
    RESTRICTION_ACCESS:"Restricted Access",
}

const FILECENSUS_NAMING_TEMPLATE_KEYWORDS = {
    period: "[period]",
    start_period: "[start_period]",
    end_period: "[end_period]"
}

const STAGE_FIELDS = {
    TIME_PERIOD_FIELD: "period_name",
    PERIOD_ID: "client_period_id",
    STATUS_FIELD: "invoiceline_staging_status",
    ASSIGNED_COMBINATION_PERC_FIELD: "assigned_combination_perc",
    ASSIGNED_AMOUNTS_PERC_FIELD: "assigned_amount_perc",
    EXCLUDED_COMBINATION_PERC_FIELD: "excluded_combination_perc",
    EXCLUDED_AMOUNTS_PERC_FIELD: "excluded_amount_perc",
    UNASSIGNED_COMBINATION_PERC_FIELD: "unassigned_combination_perc",
    UNASSIGNED_AMOUNTS_PERC_FIELD: "unassigned_amount_perc",
    PROFIT_STACK_STATUS_FIELD: "profit_stack_staging_status",
    COST_CENTER_STATUS: "cost_center_status",
    REVENUE_FIELD: "revenue",
    COGS_FIELD: "cogs",
    LINES_FIELD: "lines",
    UNITS_FIELD: "units",
    ORDERS_FIELD: "orders",
    START_DATE_FIELD: "start_date",
    END_DATE_FIELD: "end_date",
    PROGRESS_FIELD: "progress",
    GROSS_MARGIN_FIELD: "gross_margin",
    STAGED_PERIODS_COUNT_FIELD: "staged_periods",
    NOT_STAGED_PERIODS_COUNT_FIELD: "not_staged_periods",
    INVALID_PERIODS_COUNT_FIELD: "invalid_periods",
    COMPLETED_METRICS: "completed_metrics",
    METRIC_STATUS: "metric_staging_status",
    VECTOR_STATUS: "vector_staging_status",
    COUNT: "count",
    STAGED: 'staged',
    NOT_STAGED: 'not_staged',
    INVALID: 'invalid',
    TOTAL: 'total',
    ASSIGNED_COMBINATION_COUNT_FIELD: "assigned_count",
    UNASSIGNED_COMBINATION_COUNT_FIELD: "unassigned_count",
    EXCLUDED_COMBINATION_COUNT_FIELD: "excluded_count",
    TOTAL_COMBINATION_FIELD: "total_combination",
    ALL_GL_MAPPED: "isAllGLMapped"
}

const STAGE_TITLES = {
    TIME_PERIOD_TITLE: "Period",
    PERIOD_ID: "Period ID",
    STATUS_TITLE: "Status",
    COSTCENTER_STATUS_TITLE: "CostCenter State Status",
    ASSIGNED_COMBINATION_TITLE: "Assigned %",
    ASSIGNED_AMOUNTS_TITLE: "Assigned/Total Amount",
    EXCLUDED_COMBINATION_TITLE: "Excluded %",
    EXCLUDED_AMOUNTS_TITLE: "Excluded/Total Amount",
    UNASSIGNED_COMBINATION_TITLE: "Unassigned %",
    UNASSIGNED_AMOUNTS_TITLE: "Unassigned Amount",
    REVENUE_TITLE: "Revenue",
    COGS_TITLE: "COGS",
    LINES_TITLE: "Lines",
    UNITS_TITLE: "Units",
    ORDERS_TITLE: "Orders",
    START_DATE_TITLE: "Start Date",
    END_DATE_TITLE: "End Date",
    PROGRESS_TITLE: "Details",
    GROSS_MARGIN_TITLE: "Gross Margin",
    STAGED_PERIODS_COUNT_TITLE: "Staged Periods",
    NOT_STAGED_PERIODS_COUNT_TITLE: "Not Staged Periods",
    INVALID_PERIODS_COUNT_TITLE: "Invalid Periods",
    COMPLETED_METRICS: "Completed Metrics",
    STAGED_VECTORS: 'Staged Vectors',
    NOT_STAGED_VECTORS: 'Not Staged Vectors',
    INVALID_VECTORS: 'Invalid Vectors',
    TOTAL_VECTORS: 'Total Vectors',
    STAGED_METRICS: 'Staged Metrics',
    NOT_STAGED_METRICS: 'Not Staged Metrics',
    INVALID_METRICS: 'Invalid Metrics',
    TOTAL_METRICS: 'Total Metrics',
    ASSIGNED_COMBINATION_COUNT_TITLE: "Assigned Count",
    UNASSIGNED_COMBINATION_COUNT_TITLE: "Unassigned Count",
    EXCLUDED_COMBINATION_COUNT_TITLE: "Excluded Count",
    TOTAL_COMBINATION_TITLE: "Total Combinations",

}

/** profit stack mapping  data constants */
const NAME_FIELD = "name";
const NAME_TITLE = "Name";
const NUMBER_FIELD = "number";
const NUMBER_TITLE = "Number";
const PROFIT_TIER = "ProfitTier";
const EXPAND_FIELD="expand";
const ADD_FIELD = "add";
const DELETE_FIELD = "delete";
const MAP_FIELD = "map";
const COST_KEY_TITLE = "costKey";
const COST_KEY_FIELD = "costKey";
const HANDLE_ROW = "handlerow";
const FILTER_FIELD = "Filter";
const COST_CENTER_FIELD = "CostCenter";
const AMOUNT_FIELD = "amount";
const FILTER_TITLE = "Filter";
const COST_CENTER_TITLE = "Cost Center";
const AMOUNT_TITLE = "Amount";
const PARENT_COST_KEY_TITLE = "parentcostKey";
const PARENT_COST_KEY_FIELD = "parentcostKey";
const RAW_FILE = {
    TOTAL_COUNT: "total_count",
}

const VECTOR_GROUPS = {
    CUSTOMER: "Customer",
    PRODUCT: "Product",
    OPERATIONS: "Operations"
}
const SEGMENT = "Profit Segments";
const VECTOR_STAGING_ATTRIBUTES = {
    ID:"client_scenario_vector_id",
    NAME: "display_name",
    MACHINE_NAME: "vector_name",//"machine_name",
    STAGING_STATUS: "staging_status",
    GROUP: "vector_group",
    DISPLAY_ORDER: "display_order",
    DISPLAY_GROUP_ORDER: "display_group_order",
    MAX_ENTITY_COUNT:"max_entity_count",
    GENERATE_QUADS:"generate_quads",
    GENERATED:"generated",
    GENERATE_BY:"generate_by",
    IS_EDITED:"is_edited",
    GENERATED_QUARTERS:"generated_quarters",
    IS_DEFAULT_GROUP_VECTOR:"is_default_group_vector",
    DETAILS: "details",
    VISIBILITY: "visibility",
    GROUP_VALUES: {
        CUSTOMER: VECTOR_GROUPS.CUSTOMER.toLowerCase(),
        PRODUCT: VECTOR_GROUPS.PRODUCT.toLowerCase(),
        OPERATIONS: VECTOR_GROUPS.OPERATIONS.toLowerCase()
    },
    HANDLE_ROW: "handle_row",
    IS_DEFAULT: "is_default",
    DECRIPTION: "vector_description",
    TITLES:{
        ORDER:"Order",
        VECTORS:"Vectors",
        GROUP:"Group",
        MAX_ENTITY_COUNT:"Entity Count",
        SHOW_VECTOR:"Show Vector",
        GENERATE_QUADS:"Generate " + SEGMENT,
        GENERATE_BY:"Generate " + SEGMENT + " By",
        DEFAULT_VECTOR:"Default Vector"
    }
}

const STAGING_STATUS_ENUM = {
    VALID: "valid",
    INVALID: "Invalid",
    NOT_STAGED:"Not Staged",
    STAGED:"Staged"
}

const DATA_DICTIONARY_ENUM = {
    passed: "Passed",
    not_defined: "Not Defined",
    pending_decryption: "Pending Decryption",
    inactive: "Inactive",
    overwritten: "Overwritten",
    failed_upload: "Failed Upload",
    failed_define: "Failed Define",
    failed_decrypt: "Failed Decrypt",
    decrypting: "Decrypting",
    decrypted: "Decrypted",
    failed_redefine: "Failed Redefine"
}

const STATUS_ENUM = {
    pass: "Pass",
    fail: "Fail",
    warning: "Warning"
}

const TRANSACTION_STATUS_ENUM = {
    STAGED: "Staged Vectors", 
    INVALID: "Invalid Vectors", 
    NOT_STAGED: "Not Staged Vectors",
}

const PERIOD_STATUS_ENUM = {
    STAGED: "Staged", 
    INVALID: "Invalid", 
    VALID: "Valid",
    NOT_STAGED: "Not staged",
    NOT_STAGED_NEW: "not_staged",
    BUILT: "BUILT", 
    NOT_BUILT: "NOT_BUILT",
    BUILDING: "BUILDING",
    GENERATED: "Generated",
    NOT_GENERATED: "Not Generated"
}

const GENERATE_STATUS = {
    GENERATED: "GENERATED",
    NOT_GENERATED: "NOT_GENERATED",
    GENERATING: "GENERATING",
    INVALID: "INVALID",
    FAILED: "FAILED",
}

const PERIOD_ONWARD = "Onward";

const FILE_VALIDATIONS = {
    header: "Header row validation.",
    data: "Data validation.",
    datatype: "Data Type validation.",
    define: "Define validation."
}

const RAW_FILE_VALIDATION = {
    VALIDATION_CODE: "validation_code",
    VALIDATION_STATUS: "validation_status",
    VALIDATION_NAME: "validation_name",
    VALIDATION_MESSAGE: "validation_message"
}

const SELECTED_CONF_OPTIONS = {
    AMOUNT_TYPE:"amountType",
    PER_PSL:"psl",
    // PER_ATTRIBUTE:"perAttribute",
    // PER_VECTOR:"perVector"
}
const SECTION = {
    ID: "id",
    NAME: "name",
    PARENT_ID: "parent_id",
    RETURN_NAME: "section_return_name",
    TYPE: "section_type",
    URL:"url",
    PARENT_SECTION: "parent_section",
    CHILDREN: "children",
    IS_TABLEAU_REPORT: "is_tableau_report",
    ALLOWED_PARAMS: "allowed_params",
    PARAMS: {
        PERIOD_SELECTION: "period_selection"
    },
}

const MENU_ITEM = {
    COLUMNS:{
        MENU_ITEM_ID: "menu_item_id",
        MENU_ITEM_MACHINE_NAME:"menu_item_machine_name",
        ORDER: "order1",
        MENU_ITEM_DISPLAY_NAME: "menu_item_display_name",
        MENU_ITEM_DESCRIPTION: "description",
        CATEGORY: "category",
        PARENT_MACHINE_NAME: "parent_machine_name",
        URL:"url",
        MENU_ITEM_CATEGORY:"menu_item_category"
    },
    FIELDS:{
        MANAGE_STACKS:"manage_stacks",
        CREATE_EDIT_COMPANY_DASHBOARDS:"create_edit_company_dashboards",
        CREATE_EDIT_USER_DASHBOARDS:"create_edit_user_dashboards",
        DASHBOARDS:"dashboards",
        VIEW_COMPANY_DASHBOARDS:"view_company_dashboards",
        VIEW_COMPANY_STACKS:"view_company_stacks",
        CREATE_EDIT_COMPANY_STACKS:"create_edit_company_stacks",
        EXPORT_DATA_TO_CSV: "export_data_to_csv",
        VECTOR_DEFINITIONS:"vector_definitions",
        BRIDGE_REPORT:"bridge_report",
        PROFIT_LANDSCAPE:"profit_landscape",
        MANAGE_SETTINGS:"manage_settings",
        CHECK_OUT_SCENARIO:"check_out_scenario",
        UPDATE_SCENARIO:"update_scenario",
        PUBLISH_SCENARIO:"publish_scenario",
        STATISTICS_MEDIANS:"statistics_medians",
        TEMPORARY_DATA_MODELING:"temporary_data_modeling",
        DEFINE_FILE:"define_file",
        EROSION_AND_GROWTH:"erosion_and_growth",
        REVIEW_SCENARIOS:"review_scenarios",
        UPDATE_VECTOR:"update_vector",
        PSL_TOOLTIPS:"psl_tooltips",
        HEATMAP:"heatmap",
        FEATURE:"feature",
        MANAGE_CHACHING:"manage_caching",
        DATA_MODELING:"profit_modeling",
        REPORT_CONFIGURATIONS: "report_configurations",
        REPORTING:"reporting",
        LIST:"list",
        GENERAL_SETTINGS: 'general_settings',
        CONFIGURE_BUILD: "configure_and_build",
        MENU_ITEM: "menu_item",
    },
    CATEGORIES:{
        REPORTING:"REPORTING"
    }
}

const CUSTOM_REPORT = "custom_report";
const CUSTOM_REPORTS = {
    CUSTOM_REPORT_FIRST_TEXT: "Find this custom report",
    CUSTOM_REPORT_SECOND_TEXT: "in a new window👉🏽",
}
const MANAGE_CUSTOM_REPORT = {
    DESCRIPTION : "reportDescription",
    URL: "reportUrl",
    NAME: "reportName"
}

/**
 * to be discarded when fixing #technicalDebt -- replace with ALL_WIDGETS.FIELDS
 */
const ALL_REPORTS = {
    PROFIT_MAP: "Profit Map",
	LIST_TOP: "List",
	LIST_BOTTOM: "List Bottom",
	LIST_QUADRANT: "List Quadrant",
    LIST_QUADRANT_TIER: "List Quadrant Tier",
    LIST_DRILL: "List Drill",
    SECOND_DIMENSION: "Profit Heatmap",
    // HEATMAP: "Heat Map",
    // CONTOUR_MAP: "Contour Map",
    HEATMAP: "heatMap",
    CONTOUR_MAP: "contourMap",
    PROFIT_VECTORS: "ProfitVectors",
    DATA_MODELING: "DataModeling",
    CUSTOM_VIEWS: "Custom Views",
    MANAGE_STACKS:"Manage Stacks",
    PROFIT_STACK: "Profit Stack",
    MANAGE_ACCRUALS: "Manage Accruals",
    MANAGE_SCENARIO: "Manage Scenarios",
    PROFIT_LANDSCAPE: "Profit Landscape",
    GEOGRAPHY_CONCENTRATION: "Geography Concentration",
    PROFIT_ISLE_START: "profit_isle_start"

}

const ALL_WIDGETS = {
    DASHBOARDS: "dashboards",
    ADMINISTRATION: "administration",
    DATA_MODELING: "datamodeling",
    PROFIT_LANDSCAPE: "profitLandscape",
    EXPORT_ALL: "exportall",
    EXPORT_CSV_PSL:"export_csv_psl",
    SETTINGS: "settings",
    DEFINE_FILE: "definefile",
    UPLOAD: "upload_data",
    CONFIGURE: "configure",
    PS_MAPPING_MENU: "profitstackmapping",
    BUILD: "build",
    UPDATE_VECTOR: "updatevector",
    UPDATE_SCENARIO: "updatescenario",
    PREVIEW_PRIVATE_FILES: "previewPrivateFiles",
    PUBLISH_SCENARIO: "publishscenario",
    CHECKOUT_SCENARIO: "checkoutscenario",
    PERIOD:"period",
    MANAGE_ACCRUALS: "manage_accruals",
    MANAGE_ACCRUALS_NEW: "manage_accruals_new",
    BRIDGE_REPORT:"bridgereport",
    NONE: "None",
    MANAGE_USER_DASHBOARDS: "manage_user_dashboards",
    MANAGE_COMPANY_DASHBOARDS: "manage_company_dashboards",
    VIEW_USER_DASHBOARD: "view_user_dashboards",
    VIEW_COMPANY_DASHBOARD: "view_company_dashboards",
    COMPANY_DASHBOARDS:"company_dashboards",
    USER_DASHBOARDS:"user_dashboards",
    YEAR_OVER_YEAR:"Year over year",
    NEW_ARCHITECTURE_TRANSITION:"new_architecture_transition",
    MODELING_SETTINGS:"modeling_settings",
    FORCE_ASSIGN_COST:"force_assign_cost",
    BUDGETING:"budgeting",
    PROFIT_LANDSCAPE_BUBBLE: "profit_landscape_bubble",
    MANUAL_AUTOMATION: "automated_data_manipulation",
    MONTHLY_BUILD: "monthly_build",
    CONFIGURE_GL_ACCOUNT_RANGES: "configure_tie_out",
    CONFIGURE_AND_BUILD: "configure_and_build",
    GEOGRAPHY_CONCENTRATION: "geography_concentration",
    TLC_LANDSCAPE: "tlc_landscape",
    STAGE_BUTTONS:"stage_buttons",
    SYSTEM: {
        SYSTEM: "system",
        CACHE_MANAGER: "cachemanager",
        BRIDGE_CONFIGURATIONS_MANAGER:"bridgeconfigurationsmanager"
    },
    MANAGE_COST_CENTERS: "manage_cost_centers",

    TITLES: {
        PROFIT_LANDSCAPE: {
            PROFIT_MAP: "Profit Map",
            List: "List",
            HEATMAP: "Heatmap",
            CONTOUR_MAP: "Contour Map",
            SECOND_DIMENSION: "Profit Heatmap",
            CUSTOM_VIEWS: "Custom Views",
            VECTOR_DEFINITIONS: "Vector Definitions",
            VECTOR_ANALYSIS: "Erosion & Growth",
            TABLEAU: "Tableau",
            BRIDGE_REPORT: "Bridge Report",
            MANAGE_STACKS: "Manage Stacks",
            ADD_STACKS: "Add Stack",
            EDIT_STACK:"Edit Stack"
        },
        TLC:{
            TLC_LANSCAPE:"TLC Landscape"
        },
        DASHBOARDS:"Dashboard",
        COMPANY_DEFAULT: "System Default",
        MANAGE_COST_CENTERS: "Manage Cost Centers",
        MANAGE_SETTINGS: "Manage Settings",
        SYSTEM: {
            SYSTEM: "System",
            CACHE_MANAGER: "Manage Caching",
            BRIDGE_CONFIGURATIONS_MANAGER:"Configure Bridge Reports",
            MANAGE_ACCESS: "Manage Access"
        },
        DATA_MODELING: {
            COST_CENTER: "Cost Center",
            MANAGE_ACCRUALS: "Manage Accruals",
            COMPARE_SCENARIOS: "Compare Scenarios",
            MODELING_SETTINGS:"Modeling Settings",
            CONFIGURE_BUILD: "Configure & Build",
            UPLOAD_DATA: "Upload Data",
            STAGE: "Stage"
        },
        COMPARE_STACKS: "Compare Stacks",
        MONTHLY_BUILD: "Monthly Build",
        CONFIGURE_GL_ACCOUNT_RANGES: "Configure Tie Out",
    },
    FIELDS: {
/** Add all sections in the followinG array in order to be able 
 * to authorize each one individually when entering from URL -- this should be a replica or section_return_name col in section table*/
        PROFIT_LANDSCAPE: "ProfitLandscape",
        PROFIT_MAP: "profitMap",
        LIST: "list",
        LIST_TOP: "listtop",
        LIST_BOTTOM: "listbottom",
        LIST_QUADRANT: "listquadrant",
        LIST_QUADRANT_TIER: "listquadranttier",
        CHECKOUT_SCENARIO: "checkoutscenario",
        STATISTICS: "statistics",
        PROFIT_STACK: "profitStack",
        SECOND_DIMENSION: "secondDimension",
        HEATMAP: "heatMap",
        CONTOUR_MAP: "contourmap",
        CATEGORY_TOOLKIT: "categoryToolkit",
        PROFIT_VECTORS: "ProfitVectors",
        BRIDGE_REPORT: "bridgereport",
        DATA_MODELING: "datamodeling",
        STAGE: "stage",
        CONFIGURE: "configure",
        BUILD: "build",
        MANAGE_ACCESS: "manage_access",
        MANAGE_ACCRUALS: "manageaccruals",
        MANAGE_ACCRUALS_NEW: "manageaccrualsnew",
        MANAGE_SCENARIOS: "manage_scenarios",
        PUBLISH_SCENARIO: "publishscenario",
        UPDATE_SCENARIO: "updatescenario",
        UPDATE_VECTOR: "updatevector",
        REVIEW_SCENARIOS: "review_scenarios",
        PREVIEW_PRIVATE_FILES: "previewPrivateFiles",
        ADMINISTRATION: "",
        UPLOAD: "datamodeling/Upload_Data",
        DEIFNE: "definefile",
        USERS: "User",
        SYSTEM: "Settings",
        ACCESS_GROUPS: "AccessGroup",
        FORMAT: "formatting",
        PERIOD: "period",
        MANAGE_REPORTS: "manageReports",
        CUSTOM_REPORT: "customReport",
        FILTER_INDIXES: "filterindexesmanager",
        CACHE_MANAGER: "cachemanager",
        PROFIT_STACK_LINE_TOOLTIP:"profitstacklinetooltip",
        EDIT:"edit",
        LIST_OF_VECTOR_DEFINITIONS:"vector_definitions",
        PROFIT_STACK_LINE_DEFINITIONS:"profit_stack_line_definitions",
        VECTOR_ANALYSIS: "erosion_growth",
        COMPARE_SCENARIOS: "compare_scenarios",
        PROFIT_STACKS_PARENT: "financial_reporting",
        PROFIT_STACKS: "profit_stacks",
        STACKS_YTD:"ytd_stacks",
        STACKS_YOY:"yoy_stacks",
        STACKS_MOM:"mom_stacks",
        YTD_STACKS:"YTD Stacks",
        YOY_STACKS:"YoY Stacks",
        MOM_STACKS:"MoM Stacks",
        STACKS_BY_RANGE_URL:"stacks_by_range",
        TOTAL_STACKS_URL:"total_stacks",
        STACKS_BY_RANGE:"Stacks by Range",
        TOTAL_STACKS:"Total Stacks",
        ENTITY_STACKS:"Entity Stacks",
        COMPARE_STACKS:"Compare Stacks",
        TREND_CHART: "Trend Chart",
	    MANAGE_COLUMNS: "manage_columns",
	    MANAGE_USER_COLUMNS: "manage_user_columns",
	    VIEW_COMPANY_COLUMNS: "view_company_columns",
        EDIT_COMPANY_COLUMNS: "edit_company_columns",
        VIEW_VECTOR_COLUMNS: "view_vectors",
        VIEW_LINE_ATTRIBUTE_COLUMNS: "view_line_attributes",
        CALCULATED_COLUMNS: "calculated_columns",
        SELECT_STACK:"select_stack",
        VIEW_STACK: "view_stack",
        MONTHLY_BUILD:"monthly_build",
        PS_MAPPING:{
            UNASSIGN: "unassign",
            ASSIGN: "assign",
            EXCLUDE: "exclude",
            FILTER_BY_PSS: "filter_by_pss",
            FILTER_BY_GL_SOURCE: "filter_by_glsource",
            EXPORT: "export",
            FLIP_SIGN: "flipSign"
            
        },
        VIEW_COMPANY_STACKS:"view_company_stacks",
        VIEW_USER_STACKS:"view_user_stacks",
        ENTITY_STACKS_VALUE:"entity_stacks",
    }
}

const STAGING_SECTIONS = {
    PROFIT_STACK_MAPPING: "profit_stack_mapping",
    PROFIT_STACK_MAPPING_NEW: "profit_stack_mapping_new",
    TRANSACTION_MAPPING : "transaction_mapping",
    METRICS_MAPPING: "metrics_mapping",
    VECTOR_MAPPING: "vector_mapping",
    CALCULATED_COLUMNS: "calculated_columns",
    MAPPING_TIE_OFF: "mapping_&_tie_off",
    VIEW_MORE: "invoiceline_details",
    DEFINE_COSTCENTER_STATE: "define_costcenter_state",
    CONFIGURE_TIE_OUT:"configure_tie_out"
}

const STAGING_SECTIONS_TITLES = {
    PROFIT_STACK: "Profit Stack",
    TRANSACTION : "Invoice Line",
    VECTORS: "Vectors",
    METRICS: "Metrics",
    CALCULATED: lang.calculated_columns,
    DEFINE_COSTCENTER_STATE: lang.cost_center_state
}

const MAPPING_TITLES = {
  profit_stack_mapping_new: "Stage - Profit Stack Mapping",
  calculated_columns: "Stage Invoice Line - Calculated Columns",
  "mapping_&_tie_off": "Stage - Invoice Line",
  metrics_mapping: "Stage - Metrics",
  vector_mapping: "Stage - Vectors"
}

const STAGING_SECTIONS_ARRAY_TITLES = [ "Profit Stack", "Invoice Line", "Vectors", "Metrics", "Define CostCenter"]

const GLACCOUNTS_FIELDS = {
    COMBINATION :"combination",
    AMOUNT: "amount",
    COUNT: "count",
    NAME: "name",
    MAP_EXCEPTION_VALUES: {
        NONE: "NONE",
        TRANSACTION: "TRANSACTION",
        ANCILLARY: "ANCILLARY",
        TRANSACTION_VAR: "TRANSACTION_VAR",
        ANCILLARY_VAR: "ANCILLARY_VAR",
        TRANSACTION_MAT: "TRANSACTION_MAT",
        ANCILLARY_MAT: "ANCILLARY_MAT",
        OTHER:"OTHER"
    }
}

const GLACCOUNTS_TITLES = {
    COMBINATION :"Combination",
    AMOUNT: "Amount",
    COUNT: "Count",
    NAME: "Name"
}

const METRIC_MAPPING_COLUMNS = {
    TITLES:{
        VECTOR_KEY: "Vector Key",
        COST_CENTER_KEY: "Cost Center Key",
        AMOUNT: "Amount"
    },
    FIELDS:{
        VECTOR_KEY: "primary_key",
        COST_CENTER_KEY: "cost_center",
        AMOUNT: "amount",
        TOTAL_ENTITIES: "total_entities",
        TOTAL_AMOUNT: "total_amount"
    }
}

const FORMULA_ELEMENT_TYPE = {
    COLUMN: "column",
    OPERATOR: "operator",
    CONTROL: "control",
    PSS_LINE: "pss_line",
    PS_LINE_FORMULA: "Count(Distinct f.InvoiceLineKey)"
}

const CALCULATED_COLUMNS = {
    TITLES: {
        ID: "",
        ADD: "",
        NAME: "Name",
        FORMULA: "Calculated Columns",
        DESCRIPTION: "Description",
        TOTAL_AMOUNT: "Total Amount",
        DELETE: "Delete",
        DETAILS: "Details",
        SCENARIO_ID: ""
    },
    FIELDS: {
        ID: "invoiceline_calculated_column_id",
        ADD: "add",
        NAME: "column_name",
        DISPLAY_NAME: "display_column_name",
        FORMULA: "column_formula",
        DESCRIPTION: "column_description",
        TOTAL_AMOUNT: "total_amount",
        DELETE: "delete",
        DETAILS:"details",
        SCENARIO_ID: "client_model_scenario_id",
        MAPPING_TYPE: "mapping_type",
        IS_USED_IN_MAPPING: "is_used_in_mapping",
        RETURN_NAME:"returnName",
        TOTAL_AMOUNT_COMPUTED:"total_amount_computed",
        COLUMN_FIELDS: {
            TYPE: "type",
            VALUE: "value",
            CLASS: "class",
            FILE: "file",
            COLUMN: "column",
            LABEL:"label",
            COLUMN_INDEX: "colIndex",
            NAME:"name",
        }
    },
    VALUES: {
        MAPPING_TYPE: {
            basic: lang.calc_cols.mapping.mapping_type.basic,
            advanced: lang.calc_cols.mapping.mapping_type.advanced
        }
    }
}

const MACHINE_NAME_SUFFIXES = {
    _AC: /AC+$/,                      //for profit stack lines
    _CC: /_cc+$/,          //for calculated columns
    _NUMBER: /_[0-9]+$/,             //for vectors (this regex matches the id that is at the end of vectors machine_names)
    _CPCF: /_cpcf+$/                   //for custom manage columns fields
}

const PS_MAPPING = {
    TITLES: {
        NAME: "Name",
        COSTTYPE: "Line Type",
        COST_KEY: "",
        FILTER: "Filter",
        COST_CENTER: "Driver",
        AMOUNT: "Amount",
        PARENT_COST_KEY: "parentCostKey",
        RETURN_NAME: "returnname",
        ACTION: "Actions",
        AC_TYPE: "AC Type",
        MAPPED: "Mapped",
        COST_TERM: "Plan Term",
        ACCRUAL_STATUS: "Accrual Status",
        IN_PROFIT_MAP: "in_profit_map",
        EXCLUDE_FROM_PS: "exclude_from_profitstack",
        INCLUDE_IN_PM_TOTALS: "include_in_pm_totals",
        ATTR_TYPE: "attribute_type",
        SIGN:"Sign",
        FLIPPED:"Flipped"
    },
    FIELDS: {
        NAME: "name",
        ADD: "add",
        DELETE: "delete",
        ACTION: "action",
        EXPAND: "expand",
        COST_KEY: "costKey",
        COST_CENTER: "costCenter",
        FILTER: "filter",
        AMOUNT: "amount",
        AMOUNT_DETAILS: "details",
        PARENT_COST_KEY: "parentCostKey",
        HANDLE_ROW: "handlerow",
        RETURN_NAME: "returnName",
        CHILDREN: "children",
        IS_NOT_AC: "isNotAC",
        NAME_IN_FACT: "nameInFact",
        LEADING_PSS_ID:"pssLeadingId",
        PSS_ID:"pssId",
        RAW_FILE_FIELD_NAME: "rawFileFieldName",
        RAW_FILE_FIELD_NAME_DB: "raw_file_field_name",
        DELETED:"deleted",
        FIELDS_COMBINATIONS: "fieldsCombination",
        FIELDS_COMBINATION: "fields_combination",
        ASSIGNED_TO: "assigned_to",
        CHECK: "check",
        MAPPING_EXCEPTION: "mappingException",
        COST_TERM_ID: "cost_term_id",
        LEADING_COSTKEY: "pssLeadingCostKey",
        AC_TYPE: "acType",
        MAPPED: "Mapped",
        FORMULA_IN_FACT: "formulaInFact",
        ACCRUAL_STATUS:"accrual_status",
        ACTUAL_ID:"actualId",
        ACTUAL_COST_KEY:"actualCostKey",
        ID: "id",
        COST_TERM: "cost_term",
        COSTTYPE: "costtype",
        PERCENTAGE_MACHINE_NAME:"percentage_machine_name",
        MAP_FORMULA: "formula",
        ORIGINAL_KEY: "original_key",
        DESCRIPTION: "profit_stack_line_description",
        FORMULA: "formula_in_fact",
        AC_TYPES: {
            ATTRIBUTE:"ATTRIBUTE",
            CALCULATED :"CALCULATED",
            ASSIGNABLE: "ASSIGNABLE"
        },
        ATTRIBUTE: "attribute",
        ATTRIBUTE_FUNCTION: "attribute_function",
        ATTR_TYPE: "attribute_type",
        IN_PROFIT_MAP: "in_profit_map",
        EXCLUDE_FROM_PS: "exclude_from_profitstack",
        INCLUDE_IN_PM_TOTALS: "include_in_pm_totals",
        PATH: "path",
        DESCRIPTION_FIELD:"description",
        PERIOD:"period",
        METRIC_DESCRIPTION: "metric_description",
        SIGN:"sign",
        IS_EXPANDABLE:"isExpandable",
        CREATED_GROUP_SUFFIX: "_ms"
    },
    INSERT:"Insert",
    IS_INHERITED:"is_inherited",
    TYPE:{
        COUNT:{
            COUNT_TITLE:"Count",
            COUNT_FIELD:"count"
        },
        SUM:{
            SUM_TITLE:"Sum",
            SUM_FIELD:"sum"
        }
    },
    ATTRIBUTE_COLUMNS:{
        VECTOR:"vector",
        VECTORS:"vectors"
    },
    EXCEPTION_FIELDS: {
        FILE: "subType",    //should be file
        COLUMN: "column",
        PSL_NAME: "psl_name",
        ENTITY: "entity",
        PERCENTAGE: "split_percentage",
        AMOUNT: "amount",
        ANCILLARY_FILTER: "ancillaryFilter",
        isMatched: "is_matched",
        CALCULATED_COL: CALCULATED_COLUMNS.FIELDS.NAME      //use the same key used in calculated columns
    },
    ATTR_TYPE_VALUES: {
        CALC: "calculated",
        VEC: "vector"
    },
    PSS_FILTER_LABELS:{
        ASSIGNED_LABEL:"Assigned",
        UNASSIGNED_LABEL:"Unassigned",
        EXCLUDED_LABEL:"Excluded",
    },
    COMBINATION_STATUS:{
        ASSIGNED:"ASSIGNED",
        UNASSIGNED:"UNASSIGNED",
        EXCLUDED:"EXCLUDED",
    },
    DESCRIPTION:"description"
}

const costtype = {
    identifiers: "identifiers",
    ratios: "ratios",
    totalratios: "totalratios",
    medians: "medians",
    invoicelinetype: "invoicelinetype",
    accrued: "accrued",
    standard: "standard",
    attribute: "attribute",
    calculated: "calculated",
    count: "count",
    standardGroup:"standardGroup"
}

const ROW_NUMBER = "rowNumber"; //this constant is used when adding row number to options (addRowNumberToOptions)
const RAND = "rand"; //this constant is used when adding a random row number to each object in an array, to be used in the key

const ROW_STATUS = {
    TITLE: "Row Status",
    FIELD: "row_status",
    VALUES: {
        OLD: "old",
        NEW: "new_row",
        EDITED: "edited",
        DELETED: "deleted"
    }
}

const VIEW_MORE_INVOICE_LINE_FIELDS = {
    NAME: "name",
    TOTAL: "total",
    MAXIMUM: "maximum",
    MINIMUM: "minimum",
    MANDATORY: "mandatory",
    CALCULATED: "calculated",
    EXCLUDED: "excluded",
    TYPE: "type",
    DATATYPE: "datatype"
}

const VIEW_MORE_INVOICE_LINE_TITLES = {
    NAME: "Name",
    TOTAL: "Total",
    MAXIMUM: "Maximum",
    MINIMUM: "Minimum",
    MANDATORY: "Mandatory",
    CALCULATED: "Calculated",
    EXCLUDED: "Excluded",
    TYPE: "Type"
}

const EXCLUDED_LINES_FIELDS = {
    DATA_FILE_TYPE: "data_file_type",
    FILE_TYPE: "file_type",
    COLUMN: "exclusion_column",
    FUNCTION: "exclusion_function",
    VALUE: "exclusion_value",
    TOTAL_LINES: "total_lines",
    TOTAL_INVOICES: "total_invoices",
    TOTAL_REVENUE: "total_revenue",
    TOTAL_COGS: "total_cogs",
    ADD: "add",
    DELETE: "delete",
    EXPAND: "expand",
    ROW_POSITION: "row_position",
    FIELD_DATA_TYPE: "field_data_type",
    DISPLAY_FILE_TYPE: "display_file_type",
    RAW_FILE_SUBTYPE_ID: "raw_file_subtype_id",
    FILE: "file"
}

const EXCLUDED_LINES_TITLES = {
    DATA_FILE_TYPE: "Data File Type",
    FILE_TYPE: "File Type",
    COLUMN: "Column",
    FUNCTION: "Function",
    VALUE: "Value",
    TOTAL_LINES: "Total Lines",
    TOTAL_INVOICES: "Total invoices",
    TOTAL_REVENUE: "Total Revenue",
    TOTAL_COGS: "Total COGS",
    ADD: "add",
    DELETE: "delete",
    EXPAND: "expand",
    DISPLAY_FILE_TYPE: "Display file type",
    RAW_FILE_SUBTYPE_ID: "RAW_FILE_SUBTYPE_ID",
    FILE: "File"
}

const EXCLUDED_LINES_TIE_OFF_FIELDS = {
    NAME: "tieoff_name",
    INVOICELINE_TOTALS: "tieoff_invoiceLine_totals",
    GL_TOTALS: "tieoff_gl_totals",
    VARIANCE: "tieoff_variance",
    VARIANCE_PERC: "tieoff_variance_perc",
    TRANSACTION: "transaction"
}

const EXCLUDED_LINES_TIE_OFF_TITLES = {
    NAME: "Name",
    INVOICELINE_TOTALS: "Transactions",
    GL_TOTALS: "GL",
    VARIANCE: "Variance",
    VARIANCE_PERC: "Variance %",
    TRANSACTION: "Map to Transactions"
}

const VIEWMORE_TIEOFF_EXCLUSIONS_COLUMNS_FILEDS = {
    NAME: "tieoff_name",
    ORG_INVOICELINE_TOTAL: "tieoff_original_invoiceLine_totals",
    INVOICELINE_TOTAL: "tieoff_invoiceLine_totals",
    GL_TOTAL: "tieoff_gl_totals",
    VARIANCE: "tieoff_variance",
    VARIANCE_PERC: "tieoff_variance_perc",
}


const VIEWMORE_TIEOFF_EXCLUSIONS_COLUMNS_TITLES = {
    NAME: "Name ",
    ORG_INVOICELINE_TOTAL: "Original Invoice Line Totals",
    INVOICELINE_TOTAL: "Transactions",
    GL_TOTAL: "GL",
    VARIANCE: "Variance",
    VARIANCE_PERC: "Variance %"
}

const TOTAL_EXCLUSIONS_FILEDS = {
    LINES: "total_lines",
    INVOICE_LINES: "total_invoices",
    REVENUE: "total_revenue",
    COGS: "total_cogs"
}

const TOTAL_EXCLUSIONS_TITLES = {
    LINES: "Total Excluded Lines",
    INVOICE_LINES: "Total Excluded Invoices",
    REVENUE: "Total Excluded Revenue",
    COGS: "Total Excluded COGS"
}

const RAW_ITEMS = {
    SUBTYPE_ID: "raw_file_subtype_id",
    SUBTYPE_DISPLAY_NAME: "display_file_type",
    SUBTYPE_NAME: "file_type",
    FIELD_DATA_TYPE: "field_data_type",
    FIELD_NAME: "raw_field_name",
    TYPE_NAME: "data_file_type",
    SECTION: "section",
    DATA_FIELD: "name",
    DESCRIPTION_FIELD: "description",
    NAME: "name",
    TYPE: "type",
    VALUE: "value",
    CLASS: "class",
    FILE: "file",
    COLUMN: "column",
    COLUMN_INDEX: "colIndex",
    FILE_SUBTYPE: "file_subtype",
    FILE_SUBTYPE_DISPLAY:"display_file_subtype"
}

const METRICS_MAPPING = {
    FIELDS: {
        DELETE: "metric_delete",
        FETCH_DATA:"fetch_data",
        MAP: "metric_map",
        METRIC: "metric_display_name",
        VECTOR: "vector",
        VECTOR_DISPLAY: "display_name",
        COST_CENTER: "is_cost_center",
        STATUS: "status",
        RAW_FILE_SUBTYPE_ID: RAW_ITEMS.SUBTYPE_ID,
        FILTER: "filter",
        TRANSLATED_FILTER:"translated_filter",
        METRIC_VALUE: "metric_value",
        ROW_STATUS: "row_status",
        RAW_FILE_DISPLAY_SUBTYPE: RAW_ITEMS.SUBTYPE_DISPLAY_NAME,
        RAW_FILE_SUBTYPE: RAW_ITEMS.SUBTYPE_NAME,
        DATA_TYPE_FIELD: RAW_ITEMS.FIELD_DATA_TYPE,
        RULE: "rule",
        COST_KEY: "costkeys",
        LINE: "lines",
        DESCRIPTION: "metric_description",
        METRIC_CONFIGURATION:"metric_configuration",
        ROW_STATUS_VALUES: {
            NEW: "new",     //this value indicates that the row has just been created
            NOT_SAVED: "not_saved",     //this value indicates that the row has just been created, and has been edited before being saved
            OLD: "old",     //this value indicates that the row has been saved in DB
            EDITED: "edited",   //this value indicates that the row has been saved in DB, and has been edited
            DELETED: "deleted"   //this value indicates that the row has been saved in DB, and has been edited
        }
    }, TITLES: {
        METRIC: "Name",
        VECTOR: "Vector",
        METRIC_VECTOR: "Metric Vector",
        COST_CENTER: "By CostCenter",
        METRIC_VALUE: "Metric Value",
        // MAP: "Map",
        DETAILS: "Details",
        BY_COST_CENTER: "By CostCenter"
    },METRIC_STAGING_STATUS:{
        STAGED:"STAGED",
        NOT_STAGED:"NOT_STAGED",
        INVALID:"INVALID"
    }

}

const ACCRUALS = {
    FIELDS:{
        COSTKEY:"costKey",
        COST_AMOUNT:"costAmount",
        COST_AMOUNT_ARR:"costAmountArr",
        PROJECTION_AMOUNT_ARR:"projectionAmountArr",
        PERIODS:"periods",
        ID:"pssId",
        ACTUAL_ID: "actualId",
        NAME:"name",
        FY_COST:"fy_cost",
        FY_COST_PERC:"fy_cost_perc",
        BE_ACCRUED:"be_accrued",
        STATUS:"accrual_status",
        ACTIONS: "actions",
        ROW_STATUS:"row_status",
        PARENT_COST_KEY:"parentCostKey",
        COSTTYPE: "costtype",
        RULE:"rule",
        FILTER:"costFilter",
        JSON_FILTER:"jsonFilter",
        ACCRUE_STATUS:"accrue_status",
        CHILDREN:"children",
        STATUS_VALUES:{
            UNDEFINED: "UNDEFINED",
            EXCLUDED:"EXCLUDED",
            INCLUDED:"INCLUDED",
            ACCRUED:"ACCRUED",
            ACCRUAL:"ACCRUAL",
        },
        ROW_STATUS_VALUES:{
            NEW:"new",
            OLD:"old",
            EDITED:"edited",
            DELETED:"deleted",
            REMOVED:"removed"
        },
        COST_CENTER: "costCenter"
    },
    TITLES:{
        ID:"ID",
        NAME:"Name",
        FY_COST:"FY Cost",
        FY_COST_PERC:"FY Cost %",
        BE_ACCRUED:"Likeliness to be Accrued",
        STATUS:"Status",
        ACTIONS:"Actions"
    },
    ACTIONS:{
        REMOVE_ACCRUAL: "Remove Accrual",
        INCLUDE:"Include",
    },
}

const FOCUS_ACCRUALS = {
    FIELDS: {
        PERIOD: "period",
        FY_PROJECTION: "fy_projection",
        PREDICTED_FY_PROJECTTION: "predicted_fy_projection",
        VARIANCE: "variance",
        PREDICTED_VARIANCE: "predicted_variance",
        CALCULATED_ACCRUAL: "calculated_accrual",
        PREDICTED_CALCULATED_ACCRUAL: "predicted_calculated_accrual",
        COST:"cost",
        RATIO_PERC: "ratio"
    },
    TITLES: {
        COST: "Period Build",
        PERIOD: "Period",
        AMOUNT: "Amount",
        FY_PROJECTION: "FY Build",
        VARIANCE: "Variance %",
        CALCULATED_ACCRUAL:"Calculated Accrual",
        REFERENCE_FY_BUILD: "Reference FY Build",
        RATIO_PERC: "Ratio %"
    },
    CONFIG_FIELDS: {
        PSS_ID: "pss_id",
        COSTKEY: "costKey",
        PREDICT_TYPE: "predict_type",
        PREDICT_REF_YEAR: "predict_ref_year",
        IS_BY_YEAR: "is_by_year",
        PERIOD_ID: "period_id",
        PERIOD_NUMBER: "period_number",
        RATIO: "ratio",
        AMOUNT: "amount",
        ACCRUAL_PERIOD_DATA: "accrual_period_data"
    }
}
const ACCOUNT_AMOUNT_FIELDS = {
    ASSIGNED_COMBINATION: "assigned_combination",
    UNASSIGNED_COMBINATION: "unassigned_combination",
    EXCLUDED_COMBINATION: "excluded_combination",
    ASSIGNED_AMOUNT: "assigned_amount",
    UNASSIGNED_AMOUNT: "unassigned_amount",
    EXCLUDED_AMOUNT: "excluded_amount"
}

const ACCOUNT_AMOUNT_TITLES = {
    ASSIGNED_COMBINATION: "Assigned Combinations",
    UNASSIGNED_COMBINATION: "Unassigned Combinations",
    EXCLUDED_COMBINATION: "Excluded Combinations",
    ASSIGNED_AMOUNT: "Assigned Amount",
    UNASSIGNED_AMOUNT: "Unassigned Amount",
    EXCLUDED_AMOUNT: "Excluded Amount"
}

const SCENARIO_ACTION = {
    RENAME: "edit_name",
    ACTION: "action",
    DELETE: "delete",
    CLONE: "clone",
    CHECKOUT: "checkout",
    PUBLISH: "publish",
    REVIEW: "review",
    ADD: "add",
    CLONE_NEW_ARCH:"clone",
    CLONE_AS_BUDGETING:"clone_as_budgeting",
    CONFIGURE_BUDGETING:"configure_budgeting"
}

const SCENARIO_FIELDS = {
    ADD_ROW: "",
    ID: "scenario_id",
    NUMBER: "scenario_number",
    NAME: "name",
    CREATED_BY: "created_by",
    LAST_MODIFIED_BY: "last_modified_by",
    CREATED_DATE: "created_date",
    STATUS: "status",
    STAGED_PERIODS: "staged_periods",
    ASSIGNED_COSTS_PERIODS:"assigned_cost_periods",
    COMPUTED_QUARTERS: "computed_quarters",
    ACTIONS:"actions",
    PROGRESS: "progress",
    CLIENT_MODAL_SCENARIO_ID: "client_model_scenario_id",
    SCENARIO_TYPE:"scenario_type",
    ICON:"icon"
}



const SCENARIO_TYPE = {
   NORMAL:"ACTUAL",
   BUDGETING:"BUDGET",
   TITLES: {
        NORMAL:"Actual",
        BUDGETING:"Budget",
   }
}

const DATA_TYPE = {
    NORMAL:"ACTUAL",
    BUDGETING:"BUDGET",
    PRELIMINARY:"PRELIMINARY",
}

const SCENARIO_TITLES = {
    ID: "Scenario Id",
    NUMBER: "Scenario Number",
    NAME: "Scenario Name",
    CREATED_BY: "Created By",
    LAST_MODIFIED_BY: "Last Modified By",
    CREATED_DATE: "Created Date",
    STATUS: "Status",
    STAGED_PERIODS: "Periods Staged",
    ASSIGNED_COSTS_PERIODS:"Periods With Assigned Costs",
    COMPUTED_QUARTERS: "Computed Quarters",
    ACTIONS:"Actions",
    PROGRESS: "Progress",
    ICON: ""
}

const DEFAULT_TIME_ZONE = "America/New_York";
const DEFAULT_DATETIME_FORMAT = "MM/dd/yyyy hh:mm:ss a";
const DEFAULT_DATETIME_FORMAT_MS = "MM/dd/yyyy hh:mm:ss.sss a";
const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";

const SAVED_FILTER_ID_COOKIE = "current_saved_filter_id";
const CURRENT_FILTER_COOKIE = "current_filter";
const CURRENT_FILTER_VISIBILITY_COOKIE = "current_filter_visibility";

const UPLOAD_SECTIONS = {
    TITLES: {
        P_AND_L: "P & L",
        GENERAL_LEDGER: "General Ledger",
        TRANSACTION_DATA: "Transaction Data",
        ANCILLARY_FILES: "Ancillary Files",
        MASTER_DATA: "Master Data",
        DATA_SUMMARY:"Summary",
        CROSSWALK: "Crosswalk",
        CURRENCY:"Currency",
        PRELIMINARY:"Preliminary Data"
    },
    FIELDS: {
        P_AND_L: "p_&_l",
        GENERAL_LEDGER: "general_ledger",
        TRANSACTION_DATA: "transaction_data",
        ANCILLARY_FILES: "ancillary_files",
        MASTER_DATA: "master_data",
        CROSSWALK: "crosswalk",
        CURRENCY: "currency",
        BUDGETING_DATA:"budgeting_data",
        PRELIMINARY_DATA:"preliminary_data",
        INVENTORY_DATA:"inventory_data",
        WAREHOUSE_INVENTORY_MOVEMENT:"warehouse_inventory_movement",
        PLANOGRAM:"planogram"
    }
}

const SUMMARY_TITLES = {
    PERIOD: "Period",
    STATUS: "Status",
    PASSED_FILES: "Passed Files",
    FAILED_FILES: "Failed Files",
    PENDING_FILES: "Pending Files",
    EXPECTED_FILES: "Expected Files",
    DETAILS: "Details",
}
const SUMMARY_FIELDS = {
    PERIOD: "period_name",
    STATUS: "status",
    PASSED_FILES: "passed_files",
    FAILED_FILES: "failed_files",
    PENDING_FILES: "pending_files",
    EXPECTED_FILES: "expected_files",
    DETAILS: "details",
    PERIOD_ID: "client_period_id"
}

const CURRENCY_FIELDS = {
    CURRENCY: "Currency",
    PERIOD: "Period",
    DATE: "Date",
    RATE: "Rate"
}

const Formats = {String: "STRING", Boolean:"BOOLEAN", Numeric:"NUMERIC", DateTime: "DATETIME", Date: "DATE", Time:"TIME", Timestamp: "TIMESTAMP"}

const PERIOD_CUSTOM_DATE_RANGE = "Custom Date Range";

export const GRAPH_COLOR_WHEEL = ['#7B0828', '#5BC0EB', '#9BC53D', '#FDE74C', '#FA7921', '#8A716A', '#EABDA8', '#522B47', '#E55934', '#FFC4D1'];

const Amendments = { New: "New", Append: "Append", Replace: "Replace" };
const Delimiters = { Comma: ",", Tab: "\t", Pipe: "|", Other: "Other"};
const ENCRYPTION_OPTIONS =  ["None",".gpg"];
const UPLOAD_DATA = "upload_data";
const DEFINE_DATA = "define_data";
const HISTORY_DATA = "history_data";
const NEW_SECTION = "newSection";
const TRAILING_CHARACTERS = "Trailing Characters";
const LEADING_CHARACTERS = "Leading Characters";
const dateFormats = { DATE: "YYYY-MM-DD", DATETIME: "YYYY-MM-DDThh:mm:ss", TIMESTAMP: "YYYY-MM-DD hh:mm:ss" };

const ALERT_DEFINE_MESSAGE = "No succesfully uploaded file to be defined.";
const ALERT_FILL_MESSAGE = "Please make sure to fill all the mandatory data fields correctly.";
const ALERT_BOOLEAN_MESSAGE = "Kindly define the format of the boolean entity.";
const PRIMARY_KEY = "primary_key";
const ENTRY_TYPE = "entry_type";

const DEFAULT_DATE_FORMAT_UPLOAD = "%Y-%m-%d";
const Type = {ALL: 'ALL', COST_CENTER: 'CostCenter'};
const INFO_MESSAGE = "To be defined later";

const CHATBOT_PROMISE_ERROR = "Failed to get session ID";
const SCENARIO_STATUS = {SANDBOX: 'SANDBOX', REVIEW: 'REVIEW', PUBLISHED: 'PUBLISHED'};

const SCENARIOS = {
    F: {
        ID: "client_model_scenario_id",
        NUMBER: "scenario_number",
        STATUS: "scenario_status",
        NAME: "scenario_name",
        TYPE:"scenario_type"
    }
}

const FILTER_UNSAVED_FILTER = "None";

//this constant contains the return names of the major PS lines
const PSL_RETURN_NAMES = {
    KEY: "key",
    NUMBER: "number",
    NAME: "name",
    QUADRANT: "quadrant",
    QUADRANT_TIER: "quadranttier",
    ENTITY_COUNT: "entity_count",                       // manage columns view
    COUNT_PER_SELECTION: "count_per_selection",         // manage columns view
    COUNT_PER_TOTAL: "count_per_total",                 // manage columns view
    NET_PROFIT_PERC: "netprofitperc",
    GROSS_PROFIT: "grossprofit",
    NET_PROFIT: "netprofit",
    PROFIT: "profit",
    UNITS:"units",
    INVOICEHEADERS:"invoiceheaders",
    LINES: "lines",
    ORDERS: "orders",
    REVENUE: "revenue",
    NET_REVENUE: "netrevenue",
    GROSS_REVENUE: "grossrevenueAC",
    COST_OF_SALES: "costofsales",
    VARCOGS:"varcogsac",
    VARREVENUE:"varrevenueac"
}

const CONFIGURE_VARS = {
    [PSL_RETURN_NAMES.VARREVENUE]: {
        rule: "InvoiceLine:Revenue",
        filter: {filter:[{
            "field":"REVENUE",
            "type":"engine_filter",
            "operator":"GT",
            "value":[{value:'0',label:'0'}]
        }]}
    },
    [PSL_RETURN_NAMES.VARCOGS]: {
        rule: "InvoiceLine:COGS",
        filter: {filter:[{
            "field":"COGS",
            "type":"engine_filter",
            "operator":"GT",
            "value":[{value:'0',label:'0'}]
        }]}
    },
    default:{
        rule:"InvoiceLine:CountLines"
    }
}

const PROFIT_SUMMARY = "Profit summary";

const PREFIX_ID_SPAN = "parent_plus_minus_";
const PREFIX_ID_CHECKBOX = "checkbox_column_";
const PREFIX_ID_DIV = "parent_div_";

const API_URL = {
    ADMINISTRATION: "/Admin",
    PROFIT_LANDSCAPE_EXPORT_ALL: "/ProfitLandscapeExportAll",
    PROFIT_LANDSCAPE: "/ProfitLandscape",
    PROFIT_VECTORS: "/ErosionAndGrowth",
    USER_SETTINGS: "/UserSettings",
    USER: "/User",
    ACL: "/ACL",
    DATA_MODELING: "/DataModeling",
    CACHE: "/CacheServlet",
    PING: "/ping",
    FORMAT: "/format",
    FILTER: "/Filter",
    CUSTOM_LOG: "/CustomLogger",
    CLIENT_DOMAIN: "/ClientDomain",
    DEEP_DIVE: "/DeepDive",
    PROFIT_STACK: "/ProfitStack",
    CLIENT_INFO: "/ClientInfo",
    MANAGE_SCENARIOS: "/ManageScenarios",
    MANAGE_COLUMNS: "/ManageColumns",
    MANAGE_ACCESS: "/ManageAccess",
    DASHBOARD: "/DashBoard",
    DataPipeline: "/DataPipeline",
    ASSIGN_COST: "/AssignCost",
    GENERATE_QUADRANTS: "/GenerateQuadrants",
    PI_Schedule: "/PISchedule",
    MANAGE_STACKS:"/ManageStacks",
    MANAGE_COST_CENTER:"/ManageCostCenter",
    SESSION: "/Session",
    TABLEAU: "/Tableau",
    DATAPIPELINE:"/DataPipeline",
    BUDGETING:"/Budgeting",
    GL_ACCOUNT_CONFIGURATION: "/GLAccountConfiguration",
    MONTHLY_BUILD : "/monthly-build",
    TLC_LANDSCAPE : "/tlc_landscape"
}

const ENGINE_JOB_TYPE = {
    BUILD: "Build",
    FY_PROJECTION: "projectFY",
    FACT: "Fact",
    INDEX: "Index",
    MERGE: "Merge",
    QUADRANTS: SEGMENT,
    VALIDATE: "Validate",
}

const FILTER_FUNCTIONS = {
    FILTER_FUNCTION_CT: "CT",
    FILTER_FUNCTION_NCT: "NCT",
    FILTER_FUNCTION_EQ: "EQ",
    FILTER_FUNCTION_NEQ: "NEQ",
    FILTER_FUNCTION_GT: "GT",
    FILTER_FUNCTION_LT: "LT"
}

const COST_FUNCTIONS_FIELDS = {
    PSS_ID: "pssId",
    ID: "costKey",
    NAME: "name",
    COST_CENTER: "costCenter",
    FILTER: "filter",
    RULE: "rule",
    SHOW_CHILDREN: "inProfitMap",
    AMOUNT: "amount",
    EXPAND: "expand",
    IS_VALID: "is_valid",
    RETURN_NAME: "returnName",
    VALIDATION_STATUS: "validationStatus",
    ACTUAL_ID:"actualId",
    VALIDATION_STATUS_ENUM: {
        PENDING: "pending_validation",  //if validation is currently running
        VALID: "valid",                 //if validation is run and result is valid
        WARNING: "warning",             //if validation is run and result is invalid
        NOT_VALIDATED: "not_validated",  //if validation not run yet
        MISSING_RULE: "missing_rule"
    },
    PARENT_COST_KEY: "parentCostKey",
    COST_TYPE: "costType",
    AC_TYPE: "acType",
    CHILDREN: "children",
    JSON_FILTER: "json_filter",
    CONFIGURED_PERIODS: "configured_periods",
    DEFAULT: "default",
    DELETE: "delete",
    ADD:"add",
    CONFIG:"config",
    IS_DEFAULT :"is_default",
    CHILDREN_ACCESS:"children_access"
    
}

const COST_FUNCTIONS_TITLES = {
    ID: "ID",
    NAME: "Name",
    COST_CENTER: "Driver",
    FILTER: "Filter",
    RULE: "Rule",
    SHOW_CHILDREN: "Show Children",
    AMOUNT: "Amount",
    EXPAND: "",
    VALID: "Validate All",
    PARENT_COST_KEY: "Parent Cost Key",
    RETURN_NAME: "Return Name",
    JSON_FILTER: "json_filter",
    ACTUAL_ID:"actual_id",
    CONFIGURED_PERIODS: "Configured Periods",
    DEFAULT: "Default",
    ADD: "",
    CHILDREN_ACCESS:"Children Access"
}

const CONFIGURE_SECTIONS = {
    FIELDS: {
        INTRODUCTION: "introduction",
        CONFIG_COST_FUNCTIONS: "configureCostFunctions",
        CONFIG_VECTORS: "configureVectors",
        CONFIG_QUADRANTS: "configureQuadrants",
    },
    TITLES: {
        INTRODUCTION: "Introduction",
        CONFIG_COST_FUNCTIONS: "Cost Functions",
        VIEW_COST_FUNCTIONS: "View Cost Function",
        CONFIG_VECTORS: "Vectors",
        CONFIG_QUADRANTS: SEGMENT,
    },
    SAVE_MESSAGE: "Save & Continue to Build",
    BASED_PREFIX: " Based",
}

const BUILD_SECTIONS = {
    TITLES: {
        INTRODUCTION: "Introduction",
        BUILD_MODEL: "Build Periods",
        GENERATE_QUADRANTS: "Generate " + SEGMENT,
        BUILD_REVIEW: "Build Review"
    },
    FIELDS: {
        INTRODUCTION: "introduction",
        BUILD_MODEL: "buildModel",
        GENERATE_QUADRANTS: "generateQuadrants",
        BUILD_REVIEW: "buildReview"
    },
    SAVE_MESSAGE:"Configure Cost Function"
}

const BUILD_MODEL = {
    FIELDS: {
        COST_KEY: "id",
        NAME: "name",
        PERIOD: "period_name",
        STATUS: "build_status",
        PARENT_COST_KEY: "parent_costKey",
        CHECKED: "checked",
        RULE_EXIST: "ruleexist",
        CHILDREN_COUNT: "children_count",
        IS_PENDING_MERGE: "is_pending_merge",
        VECTOR_STAGING_STATUS: "vector_staging_status",
        METRIC_STAGING_STATUS: "metric_staging_status"
    },
    TITLES: {
        COST_KEY: "ID",
        NAME: "Name",
        PERIOD: "Period",
        STATUS: "Status",
        CHECKED: "checked"
    },
    RESULT_STATUS: "_result_status",
    RESULT_STATUS_VALUES: {
        VALID: "VALID",
        INVALID: "INVALID",
        WARNING: "WARNING",
        NOT_BUILT: "NOT_BUILT",
        BUILT: "BUILT"
    },
    BUILD_STATUS_VALUES: {
        BUILT: "BUILT",
        NOT_BUILT: "NOT_BUILT",
        BUILDING: "BUILDING",
        INVALID: "INVALID",
    }
}

const GENERATE_QUADRANTS = {
    FIELDS: {
        VECTOR: "vector_name",
        VECTOR_DISPLAY: "display_name",
        VECTOR_ID: "client_scenario_vector_id",
        CHECKED: "checked"
    },
    TITLES: {
        VECTOR: "Vector",
        VECTOR_DISPLAY: "Vector",
        CHECKED: "checked"
    },
    BUILT_COUNT: "_built_count",
    RESULT_STATUS: "_result_status",
    RESULT_STATUS_VALUES: {
        BUILT: "BUILT",
        NOT_BUILT: "NOT_BUILT",
        INVALID: "INVALID"
    },
}

const TOOLTIP_AMENDMENT_MESSAGE = "There are no files uploaded yet to Append or Replace.";

const SCENARIO_ID_COOKIE_NAME = "selectedScenario";
const INVOICELINETYPE = "invoicelinetype";

const RULE_ENTITIES = {
    INVOICE_LINE: "InvoiceLine",
    LINE: "Line",
    COUNT: "Count",
    COUNT_LINES: "CountLines"
};

const RULE_ENTITIES_PSL_DEFINITIONS = {
    INVOICE_LINE: "InvoiceLine",
    LINE: "line",
    COUNT: "count",
    COUNT_LINES: "countLines"
}

const VECTOR_MAPPING = {
    TITLES:{
        VECTOR_COLUMN_NAME: 'Vector',
        VECTOR_KEY_FIELD: 'ID',
        VECTOR_NUMBER_VALUE: 'Number',
        VECTOR_NAME_VALUE: 'Name',
        ENTITY_COUNT: 'Entity Count',
        DETAILS: 'Details',
        VECTOR_NUMBER_VALUE_TRANSLATED: 'Vector Number',
        VECTOR_NAME_VALUE_TRANSLATED: 'Vector Name',
        VECTOR_KEY_VALUE_TRANSLATED: 'Vector ID',
        VECTOR_ID: "client_scenario_vector_id",
        IS_DEFALUT:"is_default",
        ROW_STATUS: "",
        VECTOR_NUMBER_VALUE_DISPLAY: 'Number',
        VECTOR_NAME_VALUE_DISPLAY: 'Name',
        VECTOR_KEY_VALUE_DISPLAY: 'ID',
        PERIOD: "period_name",
        STATUS: "status",
        IS_USED: "is_used",
        DISABLED: "disabled"
    }, 
    FIELDS:{
        VECTOR_DISPLAY_NAME :'display_name',
        VECTOR_COLUMN_NAME :'vector_name',
        VECTOR_NUMBER_VALUE: 'number_value',
        VECTOR_KEY_VALUE: 'key_value',
        VECTOR_NAME_VALUE: 'name_value',
        ENTITY_COUNT: 'count',
        DETAILS: 'details',
        VECTOR_NAME_SUBTYPE_ID:'name_subtype_id',
        VECTOR_NUMBER_SUBTYPE_ID:'number_subtype_id',
        VECTOR_KEY_FIELD:'raw_field_name',
        VECTOR_KEY_SUBTYPE_ID:'key_subtype_id',
        VECTOR_NUMBER_VALUE_TRANSLATED: 'display_number_value',
        VECTOR_NAME_VALUE_TRANSLATED: 'display_name_value',
        VECTOR_KEY_VALUE_TRANSLATED: 'display_raw_field_name',
        VECTOR_NUMBER_VALUE_DISPLAY: 'display_number_value',
        VECTOR_NAME_VALUE_DISPLAY: 'display_name_value',
        VECTOR_KEY_VALUE_DISPLAY: 'display_raw_field_name',
        MAP: 'map',
        DELETE: 'delete',
        VECTOR_ID: "client_scenario_vector_id",
        IS_DEFAULT:"is_default",
        ROW_STATUS: "row_status",
        PERIOD: "period_name",
        STATUS: "status",
        IS_USED: "is_used",
        DISABLED: "disabled",
        VECTOR_DESCRIPTION : 'vector_description',
        VECTOR_TYPE: "vector_type",
        ENTITY_COUNT_COMPUTED: "entity_count_computed"
    },
    ATTR: {
        VECTOR_NAME: 'vector_name',
        VECTOR_DESCRIPTION : 'vector_description',
        KEY_SUBTYPE_ID: 'key_subtype_id',
        KEY_VALUE: 'key_value',
        NAME_SUBTYPE_ID: 'name_subtype_id',
        NAME_VALUE: 'name_value',
        NUMBER_SUBTYPE_ID: 'number_subtype_id',
        NUMBER_VALUE: 'number_value',
        VECTOR_NUMBER_VALUE_TRANSLATED: 'display_number_value',
        VECTOR_NAME_VALUE_TRANSLATED: 'display_name_value',
        VECTOR_KEY_VALUE_TRANSLATED: 'display_raw_field_name',
        VALUE: 'value',
        TYPE: 'type',
        VECTOR_ID: "client_scenario_vector_id",
        IS_DEFAULT:"is_default",
        DELETE : 'delete',
        TYPE_VALUES:{
            FIELD: "field",
            CONNECTOR: "connector",
            FILE:"raw_file_subtype_id"
        },
        VECTOR_TYPE: "vector_type"
    },
    ROW_STATUS_VALUES: {
        NEW: "new",
        DELETED: "deleted",
        OLD: "old",
        EDITED: "edited"
    }
}

const LANDSCAPE_BUBBLE= {
    CONFIGURE:{
        ENTITY_COUNT:"Entity Count",
        ENTITY_COUNT_VALUE:"entitycount"
    }
}

const VECTOR_MAPPING_VIEW_MORE = {
    TITLES:{
        VECTOR_COLUMN_NAME: 'Name',
        VECTOR_KEY: 'ID',
        VECTOR_NUMBER: 'Number',
        TOTAL_LINE: 'Total Lines',
        TOTAL_REVENUE: 'Total Revenue',
        TOTAL_COGS: 'Total COGS'
    }, 
    FIELDS:{
        VECTOR_COLUMN_NAME :'name',
        VECTOR_KEY: 'key',
        VECTOR_NUMBER: 'number',
        TOTAL_LINE: 'total_lines',
        TOTAL_REVENUE: 'total_revenue',
        TOTAL_COGS: 'total_cogs'
    }
}

const VECTOR_MAPPING_VIEW_MORE_SUMMARY = {
    TITLES:{
        VECTOR_COLUMN_NAME_COUNT: 'Null Name Count',
        VECTOR_KEY_COUNT: 'Null ID Count',
        VECTOR_NUMBER_COUNT: 'Null Number Count',
        TOTAL_LINE_COUNT: 'Total Zero Lines Count',
        TOTAL_REVENUE_COUNT: 'Total Zero Revenue Count',
        TOTAL_COGS_COUNT: 'Total Zero COGS Count'
    }, 
    FIELDS:{
        VECTOR_COLUMN_NAME_COUNT :'name_count',
        VECTOR_KEY_COUNT: 'key_count',
        VECTOR_NUMBER_COUNT: 'number_count',
        TOTAL_LINE_COUNT: 'total_lines_count',
        TOTAL_REVENUE_COUNT: 'total_revenue_count',
        TOTAL_COGS_COUNT: 'total_cogs_count'
    }
}

const SYSTEM_CONFIG = {
    SETTING_ID: "setting_id",
    SETTING_NAME: "setting_name",
    SETTING_VALUE: "setting_value",
}

const CONFIG_SETTINGS = {
    COST_CENTER_STATE_DEFINED: "cost_center_state_defined",
    SYSTEM_SCENARIO_LIMIT: "system_scenario_limit",
    CURRENCY_ABBREVIATION: "currency_abbreviation",
    SECOND_DIMENSION_SYSTEM_PS_LINE: "system_default_second_dimension_ps_value",
    SECOND_DIMENSION_CLIENT_PS_LINE: "client_default_second_dimension_ps_value",
    HEATMAP_DIM_1_MAX_ENTITIES: "heatmap_dim_1_max_entities",
    HEATMAP_DIM_2_MAX_ENTITIES: "heatmap_dim_2_max_entities",
    MAX_ENTITIES_COUNT:"max_entities_count",
    HEATMAP_POSITIVE_START_COLOR_CODE: "heatmap_positive_start_color_code",
    HEATMAP_POSITIVE_END_COLOR_CODE: "heatmap_positive_end_color_code",
    HEATMAP_NEGATIVE_START_COLOR_CODE: "heatmap_negative_start_color_code",
    HEATMAP_NEGATIVE_END_COLOR_CODE: "heatmap_negative_end_color_code",
    HEATMAP_NEUTRAL_COLOR_CODE: "heatmap_neutral_color_code",
    GEOGRAPHY_POSITIVE_START_COLOR_CODE: "geography_positive_start_color_code",
    GEOGRAPHY_POSITIVE_END_COLOR_CODE: "geography_positive_end_color_code",
    GEOGRAPHY_NEGATIVE_START_COLOR_CODE: "geography_negative_start_color_code",
    GEOGRAPHY_NEGATIVE_END_COLOR_CODE: "geography_negative_end_color_code",
    GEOGRAPHY_NEUTRAL_COLOR_CODE: "geography_neutral_color_code",
    GEOGRAPHY_STANDARD_START_COLOR_CODE: "geography_standard_start_color_code",
    GEOGRAPHY_STANDARD_END_COLOR_CODE: "geography_standard_end_color_code"
}

const IDENTIFIERS = {
    KEY: "key",
    NAME: "name",
    NUMBER: "number",
    DISPLAY_NAME:{
        KEY: "ID",
        NAME: "Name",
        NUMBER: "Number",

    }
}
const RED_GREEN_COLORS = ["#B54848","#FF6565","#FF9E9E","#B9DDAE","#91B685","#617A59"];

const QUADRANTS = {
    CR: {
        name: "Coral Reefs",
        label: "Coral Reefs",
        value: "cr"
    },
    IOP: {
        name: "Islands Of Profit",
        label: "Islands Of Profit",
        value: "iop"
    },
    MN: {
        name: "Minnows",
        label: "Minnows",
        value: "mn"
    },
    PT: {
        name: "Palm Trees",
        label: "Palm Trees",
        value: "pt"
    }
}

const SEGMENTS = {
    PEAK : {
        label:"Peak",
        value:"peak",
        abv:"P"
    },
    FLAT :{
        label:"Flat",
        value:"flat",
        abv:"F"
    },
    DRAIN: {
        label:"Drain",
        value:"drain",
        abv:"D"
    }
}

const SEGMENTS_TIERS = {
    PEAK_1 : {
        label:"Peak 1",
        value:"ptt"
    },
    PEAK_2 : {
        label:"Peak 2",
        value:"pbt"
    },
    PEAK_3 : {
        label:"Peak 3",
        value:"ptb"
    },
    PEAK_4 : {
        label:"Peak 4",
        value:"pbb"
    },
    FLAT_1 :{
        label:"Flat 1",
        value:"ftt"
    },
     FLAT_2 :{
        label:"Flat 2",
        value:"fbt"
    },
     FLAT_3 :{
        label:"Flat 3",
        value:"ftb"
    },
     FLAT_4 :{
        label:"Flat 4",
        value:"fbb"
    },
    DRAIN_1: {
        label:"Drain 1",
        value:"dtt"
    },
    DRAIN_2: {
        label:"Drain 2",
        value:"dbt"
    },
    DRAIN_3: {
        label:"Drain 3",
        value:"dtb"
    },
    DRAIN_4: {
        label:"Drain 4",
        value:"dbb"
    }
}

const SEGMENTS_TITLES = {
    PROFIT_SEGMENTS:{label:SEGMENT ,value :"profit_segments", abv: "S"},
    PROFIT_TIERS:{label:"Profit Tiers", value:"profit/tiers", abv: "T", variableName: "profit_tiers"},
    ENTITY:{label: "Entity", value: "entity"}
}

const ENGINE_FILTER = {
    KEYS: {
        FILE: "fileType", //id
        COLUMN: "column",
        FIELD: "field",
        VALUES: "values",
        VALUE_OPTIONS: "value_options",
        COLUMN_OPTIONS: "column_options",
        FUNCTION_OPTIONS: "function_options",
        FILE_OPTIONS: "file_options",
        VALUE: "value",
        FIELD_DATA_TYPE: "field_data_type",
        DISPLAY_FILE_TYPE: "display_file_type",
        RAW_FILE_SUBTYPE_ID: "raw_file_subtype_id",
        DATA_FILE_TYPE: "data_file_type",
        FILE_TYPE: "file_type",
        LOGICAL_OPERATOR: "logical_operator",
        ROW_POSITION: "row_position",
        PARENTHESIS_AFTER: "parenthesis_after",
        PARENTHESIS_BEFORE: "parenthesis_before",
        OPERATOR: "operator",
        FUNCTION:"function",
        FIELD_OPTIONS: "fields"
    },
    VALUES: {
        LOGICAL_OPERATOR: {
            AND: "AND",
            OR: "OR"
        }
    },
}

const FILTER = {
    FILTER_NAME:"Filter Name",
    KEYS: {
        VECTOR: "vector",   //this is the attribute (vector=Customer)
        VECTOR_LABEL: "vectorLabel",   //this is the attribute (vector=Customer)
        FIELD: "field",
        ATTRIBUTE: "attribute",     //old, must be deleted when filter Phase 3 is delivered #Section_TBD
        FUNCTION: "function",
        TYPE: "type",
        ENTITIES: "entities",
        ENTITIES_OPTIONS: "entities_options",
        ENTITY: "entity",     //old, must be deleted when filter Phase 3 is delivered #Section_TBD
        FROM_QUARTER: "quarter",
        TO_QUARTER: "is_12_Mo",
        LOGICAL_OPERATOR: "logical_operator",
        PARENTHESIS_BEFORE: "parenthesis_before",
        PARENTHESIS_AFTER: "parenthesis_after",
        FILTER_ROW_TYPE: "filter_row_type",
        TO_QUARTER_OPTIONS: "to_options",
        MIN: "min",
        MAX: "max",
    },
    VALUES: {
        FIELD: {
            KEY: "Key",
            NAME: "Name",
            NUMBER: "Number",
            QUADRANT: "Quadrant",
            QUADRANT_TIER: "Quadrant/Tier",
        },
        TYPE: {
            VECTOR: "vector",   //this is the type value (filter type=vector)
            PROFIT_STACK: "profit_stack"
        },
        LOGICAL_OPERATOR: {
            AND: "AND",
            OR: "OR"
        },
        FILTER_ROW_TYPE: {
            FILTER: "filter",   //filter type
            QUADRANT: "quadrant",   //quadrant type (quadrants on PM are also sent as filters)
            PROFIT_STACK: "profit_stack",   //profit stack entity is also sent as filter
            DRILL: "drill",      //drill type for drilling conditions cz they are sent to and treated as filter on the API
            HEATMAP: "heatmap",
            USER_LIMIT_ACCESS: "user_limit_access"
        }
    },
    SAVED_FILTER: {
        IS_PUBLIC: "is_public",
        NAME: "name",
        ACTION: "action",
        VALUE: "value",
        LABEL: "label",
        ID: "saved_filter_id",
        FILTER: "filter",
        EDITABLE: "editable",
        CREATED_BY: "created_by_email",
        TYPE:"type",
        ADVANCED:"ADVANCED",
        BASIC:"BASIC"
    },
    VISIBILITY_OPTIONS:{
        COMPANY_LABEL: "Company",
        USER_LABEL: "User",
        COMPANY_VALUE: "COMPANY",
        USER_VALUE: "USER"
    }
}

const PROFIT_STACK_VIEW = {     //aka custom view
    ID: "custom_view_id",
    NAME: "name",
    FILTER_ID: "saved_filter_id",
    VALID_SCENARIOS: "valid_scenarios",
    CLIENT_ID: "client_id",
    CREATED_BY: "created_by",
    CREATED_BY_EMAIL: "created_by_email",
    DATE_CREATED: "date_created",
    DATE_MODIFIED: "date_modified",
    DESCRIPTION: "description",
    EDITABLE: "editable",
    GROUPS: "groups",
    INVALID_SCENARIOS: "invalid_scenarios",
    IS_PUBLIC: "is_public",
    IS_VALID: "is_valid",
    LAST_MODIFIED_BY: "last_modified_by",
    GROUPS_ATTRS: {
        NAME: "name",
        RETURN_NAME: "return_name"
    }
}

const CACHE_MANAGER = {
    TITLES: {
        IDENTIFIER: "",
        KEY_NAME: "Key",
        HIT_DATE: "Hit Date",
        VALUE: "Key Value",
        CREATED_DATE: "Created Date",
        ACTION: "Action",
    },
    FIELDS: {
        IDENTIFIER: "identifier",
        KEY_NAME: "cache_key",
        HIT_DATE: "hit_date",
        VALUE: "value",
        CREATED_DATE: "created_date",
        ACTION: "action",
    },
    FLUSH_ACTION: "Flush"
}

const IS_TRANSITIONED = "is_transitioned";
export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

const FORMAT_TYPE_NAMES = {
    EU: "European/Continental",
    EN: "English/US"
}

const FORMAT_SETTINGS_PRIVATE = {
    PREFIX: "format_",
    SETTING_NAMES: {
        AMOUNT_DECIMALS: "amount_decimals",
        AMOUNT_MULTIPLIER: "amount_multiplier",
        RATIO_DECIMALS: "ratio_decimals",
        RATIO_MULTIPLIER:"ratio_multiplier",
        PERCENTAGE_DECIMALS: "percentage_decimals",
        PERCENTAGE_MULTIPLIER: "percentage_multiplier",
        NUMERIC_DECIMALS: "numeric_decimals",
        NUMERIC_MULTIPLIER: "numeric_multiplier",
        CURRENCY_SIGN: "currency_sign",
        CURRENCY_SIGN_POSITION: "currency_sign_position",
        PERCENTAGE_SIGN_POSITION: "percentage_sign_position",
        BASIS_POINT_DECIMALS:"basis_point_decimals",
        BASIS_POINT_MULTIPLIER:"basis_point_multiplier",
        NEGATIVE_VALUES_FORMAT: "negative_values_format",
        NEGATIVE_COLORING: "negative_coloring",
        NEGATIVE_COLOR: "negative_color",
        NUMERIC_FORMAT_NAME: "numeric_format_name"
    }
};

const FORMAT_SETTINGS = {
        AMOUNT_DECIMALS: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.AMOUNT_DECIMALS,
        AMOUNT_MULTIPLIER: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.AMOUNT_MULTIPLIER,
        RATIO_DECIMALS: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.RATIO_DECIMALS,
        RATIO_MULTIPLIER: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.RATIO_MULTIPLIER,
        PERCENTAGE_DECIMALS: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.PERCENTAGE_DECIMALS,
        PERCENTAGE_MULTIPLIER: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.PERCENTAGE_MULTIPLIER,
        NUMERIC_DECIMALS: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_DECIMALS,
        NUMERIC_MULTIPLIER: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_MULTIPLIER,
        CURRENCY_SIGN: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.CURRENCY_SIGN,
        CURRENCY_SIGN_POSITION: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.CURRENCY_SIGN_POSITION,
        PERCENTAGE_SIGN_POSITION: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.PERCENTAGE_SIGN_POSITION,
        BASIS_POINT_DECIMALS: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.BASIS_POINT_DECIMALS,
        BASIS_POINT_MULTIPLIER: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.BASIS_POINT_MULTIPLIER,
        NEGATIVE_VALUES_FORMAT: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NEGATIVE_VALUES_FORMAT,
        NEGATIVE_COLORING: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NEGATIVE_COLORING,
        NEGATIVE_COLOR: FORMAT_SETTINGS_PRIVATE.PREFIX + FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NEGATIVE_COLOR
};

//for column header filters in cleanupTabColumns
const ColFilterTypes = {
    TEXT: "plaintext",
    TEXT_AREA: "textarea",
    QUADRANT: "quadrant",
    INPUT: "input",
    FILES: "files",
    DATE: "date",
    STATUS:"status",
    MONEY: "money",
    NUMBER: "number",
    PERCENTAGE: "percentage",
    RATIO: "ratio",
    HEADER_SELECT: "headerselect",
    SELECT: "select"
}

//for formatting values using formatVal functions
const FormatTypes = {
    TEXT:"text",
    QUADRANT:"quadrant",
    AMOUNT:"amount",
    RATIO:"ratio",
    PERCENTAGE:"percentage",
    BASIS_POINT:"basis_point",
    NUMERIC:"numeric",
    RANGE:"range"
}

const FormatTypeSymbols = {
    percentage: '%',
    amount: '$'
}
const FORMAT_TYPE = "format_type";

//same as format types, but copies the values in DB (format_type table) in the form: {format_type_id: machine_name}
const ALL_FORMATS = {
    1: "text",
    2: "quadrant",
    3: "amount",
    4: "ratio",
    5: "percentage",
    6: "basis_point",
    7: "numeric",
}

const DATASET = {
    DATASET: "dataset",
	START_DATE: "start_date",
	END_DATE: "end_date",
	VERSION: "version",
	CONO: "cono"
}

const PERIOD = {
    PERIOD_NAME: "period_name",
	START_DATE: "start_date",
	END_DATE: "end_date",
	IS_BUILT: "is_built",
	COUNT: "count",
    PERIDO_ID: "client_period_id",
    QUARTER: "quarter"
}

const FISCAL_YEARS = {
    fiscal_year: "fiscal_year",
    all_built: "all_built",
    is_complete: "is_complete"
}

const QUARTERS = {
    Q1: {label: "Q1", value: "Q1"},
    Q2: {label: "Q2", value: "Q2"},
    Q3: {label: "Q3", value: "Q3"},
    Q4: {label: "Q4", value: "Q4"}
}

const PERIODS = {
    P1: {label: "P1", value: "P01"},
    P2: {label: "P2", value: "P02"},
    P3: {label: "P3", value: "P03"},
    P4: {label: "P4", value: "P04"},
    P5: {label: "P5", value: "P05"},
    P6: {label: "P6", value: "P06"},
    P7: {label: "P7", value: "P07"},
    P8: {label: "P8", value: "P08"},
    P9: {label: "P9", value: "P09"},
    P10: {label: "P10", value: "P10"},
    P11: {label: "P11", value: "P11"},
    P12: {label: "P12", value: "P12"},
}


const PSS = {
    ID: "id",
    NAME: "name",
    COSTKEY: "costkey",
    PARENT_COSTKEY: "parent_costkey",
    NAME_IN_FACT: "name_in_fact",
    RANK: "rank",
    LEVEL: "level",
    COSTTYPE: "costtype",
    RETURN_NAME: "returnname",
    DESCRIPTION:"description",
    COST_CENTER:"cost_center",
    IS_EXPANDABLE: "is_expandable",
    DISPLAY_VALUE_ONLY: "display_value_only",
    PROFIT_LIST_DISPLAY: "profit_list_display",
    PROFIT_LIST_DISPLAY_ONLY: "profitlist_display_only",
    PROFIT_LIST_COLUMN_NAME: "profitlist_columnname",
    UNIT: "unit",
    INCLUDE_IN_PM_TOTALS: "include_in_pm_totals",
    INCLUDE_IN_QUADRANT_TOTALS: "include_in_quadrant_totals",
    FORMAT_ID: "format_id",
    FORMAT_TYPE_ID: "format_type_id",
    FORMAT_MACHINE_NAME: "format_machine_name",

    DUPLICATE_KEYS: {   //unfortunately, some APIs use these keys instead of all lowercase :(
        COSTTYPE: "costType",
        RETURNNAME: "returnName",
        NAME_IN_FACT: "nameInFact",
    }
}
/**
 * Object Constant having fields and titles in objects each carrying column field names and column titles
 * @type {Object}
 */
const FISCAL_YEAR = {
    TITLES: {
        YEAR: "Year",
        PERIOD: "Period",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        QUARTER: "Quarter",
        ACTIONS: "Actions"
    },
    FIELDS:{
        YEAR: "year",
        PERIOD: "period",
        START_DATE: "start_date_var",
        END_DATE: "end_date_var",
        QUARTER: "quarter",
        ACTIONS: "Actions",
        PERIODS_VALUES: ["P01","P02","P03","P04","P05","P06","P07","P08","P09","P10","P11","P12","P13"],
        QUARTERS_VALUES: ["Q1","Q2","Q3","Q4"],
        ROW_STATUS: "status",
        PERIOD_STATUS: "period_status",
        PERIOD_APPLY_STATUS: "period_apply_status",
        OLD_STATUS: "old_status",
        ACTIVE_STATUS:"is_active",
        PERIOD_ID: "period_id",
        PERIOD_NAME:"period_name"
    },
    ROW_STATUS_VALUES:{
        ADDED:"added",
        EDITED:"edited",
    },
    PERIOD_STATUS_VALUES:{
        NEW:"new",
        INACTIVE:"INACTIVE",
        OLD:"old",
        ACTIVE:"ACTIVE",
    },
    PERIOD_APPLY_VALUES:{
        APPLIED:"applied",
        NOT_APPLIED:"not_applied",
    }
 }
const TOTAL_COMPANY = "TOTALCOMPANY";

const UI_ACTIONS = {
    URL_LOAD: "url_load",
    FORGOT_PWD: "forgot_password",
    RESET_PWD: "reset_pwd",
    LOGIN: "login",
    OPEN_FILTER: "open_filter",
    FILTER_AUTOCOMPLETE: "filter_autocomplete",
    SAVE_FILTER: "save_filter",
    ONE_DIM_PM: "one_dimension_pm",
    ONE_DIM_LIST: "one_dimension_list",
    TWO_DIM_HM: "two_dimension_heatmap",
    TWO_DIM_CM: "two_dimension_contourmap",
    GENERATE_HM: "generate_heatmap",
    GENERATE_CM: "generate_contourmap",
    PRE_DRILL: "pre_drill",
    DRILL: "drill",
    PROFIT_STACK: "profit_stack",
    EXPORT_ALL: "export_all",
    _12_MO: "12_months",
    CHANGE_FORMATTING: "change_formatting",
    PS_EXPAND: "ps_expand",
    SELECT_FILTER: "select_filter",
    FORGET_FILTER: "forget_fitler"
}

const LOG_LEVEL = {
    INFO: "INFO",
    WARNING: "WARNING",
    SEVERE: "SEVERE",
}

const LOG_OBJ_KEYS = {
    PI_ACTION: "pi_action",
    UNIQUE_ID: "unique_id",
    HREF: "href",
    USER_EMAIL: "user_email",
    MACHINE_NAME: "machine_name",
    PROFIT_FORMAT: "profit_format",
    SUB_ACTIONS: "sub_actions",

    UI_STARTED: "ui_started",
    UI_SENT: "ui_sent",
    API_RECEIVED: "api_received",
    API_SENT: "api_sent",
    UI_RECEIVED: "ui_received",
    UI_FINISHED: "ui_finished",
    NETWORK_SENT: "network_sent",
    NETWORK_RECEIVED: "network_received",
    DURATION: "duration"
}

const COSTCENTER_PREDICTED_STATE = {
    SELLING: "selling",
    NOT_SELLING: "not_selling",
    OPENING: "opening",
    CLOSING: "closing",
    CLOSED: "closed",
}

const COSTCENTER_STATE_STATUS = {
    PREDICTED: "predicted",
    DEFINED: "defined",
    NOT_DEFINED: "not-defined"
}

const COSTCENTER_STATE_STATUS_PREDICTED = {
    NEW: "New",
    LOST: "Lost",
    CHANGED: "Changed",
    UNCHANGED: "Unchanged"
}

const COSTCENTER_STATE_STATUS_NOT_DEFINED = {
    MISSING_GL_FILE: "Missing GL File",
    MISSING_TRANSACTION_FILE: "Missing Transaction File"
}

const DEFINE_COSTCENTER = {
    FIELDS: {
        PERIOD: "period",
        STATUS: "cost_center_status", 
        DEFINE: "define", 
        OTHER_BRANCHES: "otherBranches",
        VALID_GL_STAGING: "validForGLStaging",
        VALID_IL_STAGING: "validForInvoiceLineStaging",
    }, 
    TITLES: {
        PERIOD: "Period",
        STATUS: "Status", 
        DEFINE: "Define",
        OTHER_BRANCHES: "Other Branches"
    }
}

const EDIT_COST_CENTER_COLUMNS = {
    FIELDS: {
        BRANCH: "cost_center_id",
        NAME: "cost_center_name", 
        GL_AMOUNT: "gl_amount",
        TRANSACTION_REVENUE: "td_revenue",
        TRANSACTION_COUNT: "td_lines",
        STATUS: "status",
        PREDICTED_STATE: "client_cost_center_state_id",
        PERIOD: "period",
        HISTORICAL_DATA_REVENUE: "historicalDataRevenue"
    }, 
    TITLES: {
        BRANCH: "Branch",
        NAME: "Name", 
        GL_AMOUNT: "GL Amount",
        TRANSACTION_REVENUE: "T Revenue",
        TRANSACTION_COUNT: "T Lines Count",
        STATUS: "Notice",
        PREDICTED_STATE: "Predict costcenter State",
        PERIOD: "period",
        HISTORICAL_DATA_REVENUE: "historicalDataRevenue"
    }
}

const ADD_CC_COLUMNS = {
    FIELDS: {
        NAME: "new_cost_center_name", 
        COST_CENTER_DESC: "new_cost_center_desc"
    }, 
    TITLES: {
        NAME: "Name", 
        COST_CENTER_DESC: "Description",
    }
}

const DM_SET_REPORT_ACTIONS = {
    NEXT: lang.navigation.btns.next.toLowerCase(),
    BACK: lang.navigation.btns.back.toLowerCase(),
    SAVE: lang.navigation.btns.save.toLowerCase(),
    CANCEL: lang.navigation.btns.cancel.toLowerCase(),
    CONFIGURE: lang.navigation.btns.config.toLowerCase()
}

const MANAGE_REPORT = {
    FIELDS:{
        NAME:"name",
        DESCRIPTION:"description",
        URL:"url",
        CLICKS:"clicks",
        CREATION_DATE:"creation_date",
        ROW_STATUS: "row_status",
        ADD_ROW: "add_row",
        DELETE:"delete_row",
        ID:"client_custom_report_id",
        GROUP_ID:"access_group_id",
        SECTION_ID:"section_id",
        IS_TABLEAU_REPORT: "is_tableau_report",
        ALLOWED_PARAMS: "allowed_params",
        PARAMS: {
            PERIOD_SELECTION: "period_selection"
        }
    },
    TITLES:{
        NAME:"Report Name",
        DESCRIPTION:"description",
        URL:"URL",
        CLICKS:"Clicks",
        CREATION_DATE: "Creation Date",
        ROW_STATUS: "row_status",
        ACCESS_GROUPS:"Access Groups",
        DRILL_PARAMS: "Drill Parameters"
    }
}

const CONFIG_QUADS = {
    FIELDS: {
        CONFIG_LINE: "config_line",
        PS_LINE: "ps_line",
    },
    TITLES: {
        CONFIG_LINE: "Line",
        PS_LINE: "Profit Stack Line",
    }
}

const FILTER_VALUES_LIMIT = 50;

const MANAGE_ACCRUALS_STATUSES= {
    ACCURED: "Accured Lines",
    EXCLUDED: "Excluded Lines",
    UNDEFINED: "Undefined Lines"
};


const ACCRUAL_STATUS= {
    EXCLUDED: "excluded",
    ACCURED: "accured",
    UNDEFINED: "undefined"
};

const VECTOR_DEFINITONS = {
    FIELDS: {
        GROUP_NAME:"vector_group",
        NAME:"vector_name",
        DISPLAY_NAME:"display_name",
        DESCRIPTION:"vector_description",
        NUMBER_DEFINITION:"display_number_value",
        NUMBER_SAMPLE_ENTITIES:"sample_number_entities",
        NAME_DEFINITION:"display_raw_field_name",
        NAME_SAMPLE_ENTITIES:"sample_name_entities",
        ESTIMATED_ENTITY_COUNT:"entities_count"
     },
     TITLES : {
        GROUP_NAME:"Group Name",
        NAME:"Name",
        DESCRIPTION:"Description",
        NUMBER_DEFINITION:"Number Definition",
        NUMBER_SAMPLE_ENTITIES:"Number Sample Entities",
        NAME_DEFINITION:"Name Definition",
        NAME_SAMPLE_ENTITIES:"Name Sample Entities",
        ESTIMATED_ENTITY_COUNT:"Estimated Entity Count"
     }
};

const VECTOR_ANALYSIS = {
    VALUE: "erosiongrowth",
    FIELDS : {
        ATTRIBUTE: "attribute",
        RETURN_NAME: "returnname",
        LEVEL: "level",
        COUNTER: "counter",
        NAME: "name",
        DESCRIPTION: "description",
        NUMBER: "number",
        PQS: "pqs",
        KEY:"key",
        REVENUE_COMPARISON: "rev_comparison",
        REVENUE_SELECTION: "rev_selection",
        REVENUE_DIFFERENCE: "rev_difference",
        GROSS_PROFIT_COMPARISON: "gross_profit_comparison",
        GROSS_PROFIT_SELECTION: "gross_profit_selection",
        GROSS_PROFIT_DIFFERENCE: "gross_profit_difference",
        PROFIT_COMPARISON: "profit_comparison",
        PROFIT_SELECTION: "profit_selection",
        PROFIT_DIFFERENCE: "profit_difference",
        ALPHA_SYMBOL: "α",
        ALPHA:"alpha",
        BETA_SYMBOL: "β",
        BETA:"beta",
        DELTA_SYMBOL: "Δ",
        DELTA_FORMULA: "Δ = β - α",
        IOP_NAME: "IOP",
        IOP1_NAME: "IOP_1",
        BP_NAME: "BP",
        NA_NAME: "N/A",

    }, 
    TITLES: {
        NAME: "Name",
        NUMBER: "Number",
        PQS: "PQs",
        COMPARISON: "Comparison",
        SELECTION: "Selection",
        DIFFERENCE: "Difference",
        NET_REVENUE: "Net Revenue",
        GROSS_PROFIT: "Gross Profit",
        PROFIT: "Profit"
    }
}

const STACKS_RANGE = {
    FIELDS : {
        NAME: "name",
        AMOUNT: "amount",
        RETURN_NAME:"returnName"
    }, 
}
const PROFIT_STACK_LINES = "Profit Stack Lines";  //used as title for the column that contains the ps lines

const bridge_config = {
    fields: {
        id: "report_id",
        report_name: "report_name",
        custom_view_id: "custom_view_id",
        field_qc: "field_quantity_shipped",
        field_rev: "field_revenue",
    }
}

const NET_REVENUE = "Net Revenue";
const GROSS_PROFIT = "Gross Profit";
const YEAR_OVER_YEAR = "year_over_year";
const PREVIOUS_ADJACENT = "previous_adjacent";
const TOP_QUADRANT = "top_quadrant";
const TOP_QUADRANT_TIER = "top_quadrant_tier";
const BP_QUADRANT_TIER = "bp_quadrant_tier";
const COMPARE_SCENARIOS = "compare_scenarios";

const columnConfig = {
    isPrimary: "isPrimary",
    isDefault: "isDefault",
    isApplied: "isApplied"
}

const comparison_suffixes = {
	selection: "_selection",
	reference: "_reference",
	comparison: "_comparison",
    difference: "_difference"
}


// const column_suffixes = {
//     per_selection: /_per_selection$/,
//     per_total: /_per_total$/,
//     min: /_minimum$/,
//     max: /_maximum$/,
//     average: /_average$/,
//     median: /_median$/,
//     range: /_range$/,
//     entity_count: /_entity_count$/,  //should be before count so that count doesn't remove half of it and it wouldn't work anymore
//     // count: /^(?!entity\_).*count$/,  // /count$/,           //match "count", unless it is "entity_count"
//     count_per_selection: /count_per_selection$/,
//     count_per_total: /count_per_total$/
//     //"custom_counts_per_total".replace(/^((?!.*count_).*_per_total.*$)/i, "")
// }

const column_suffixes = { //Negative Lookbehind is not supported in safari we need to find another way to do these(count_per_selection,count_per_total,entity_count)
    //count_per_selection: /((?<!^)count_per_selection)/,
   // count_per_total: /((?<!^)count_per_total)/,
    per_selection: /_per_selection$/,
    per_total: /_per_total$/,
    min: /_minimum$/,
    max: /_maximum$/,
    average: /_average$/,
    median: /_median$/,
    range: /_range$/,
   // entity_count: /((?<!^)entity_count)/,  //should be before count so that count doesn't remove half of it and it wouldn't work anymore
    // count: /count$/,
}

const column_suffixes_values = {
    per_selection: {value : "per_selection", label:"/Selection"},
    per_total: {value : "per_total", label:"/Total"},
    minimum: {value : "minimum", label:"Min"},
    maximum: {value : "maximum", label:"Max"},
    average:  {value : "average", label:"Avg"},
    median: {value : "median", label:"Med"},
    range: {value : "range", label:"Rng"},
    entity_count: {value : "entity_count", label:"Entity Count"},  //should be before count so that count doesn't remove half of it and it wouldn't work anymore
    count: {value:"count", label:"Count"},
    count_per_selection: {value:"count_per_selection", label:"/Selection"},
    count_per_total: {value:"count_per_total", label:"/Total"},
    value: {value:"value", label:""},
    name:{value:"name",label:"Name"},
    number:{value:"number",label:"Number"},
    key:{value:"key",label:"Key"},
    quadrant:{value:"quadrant",label:"S"},
    quadrant_tier:{value:"quadranttier",label:"T"},
}
const session_storage_keys = {
    heatmap_row_view: "heatmap_row_view",
    heatmap_column_view: "heatmap_column_view",
    heatmap_ps_line: "heatmap_ps_line",
    heatmap_threshold: "heatmap_threshold"
}

const COLUMN_PROFILE = {
    ID: "column_profile_id",
    NAME: "name",
    LABEL: "label",
    VISIBILITY: "visibility",
    COLUMN_PROFILE_ID: "column_profile_id",
    IS_SAVED: "is_saved",
    IS_APPLIED: "is_applied",
    IS_MODIFIED: "is_modified",
    IS_COMPANY_DEFAULT: "is_company_default",
    IS_USER_DEFAULT: "is_user_default",
    IS_VALID: "is_valid",
    COLUMNS: "columns",
    SIMPLIFIED_COLUMNS: "simplified_columns",
    CREATED_BY: "created_by",
    UPDATED_BY: "updated_by",
    CREATOR_NAME: "creator_name",
    CHECKED_ITEMS:"checked_items",
    DISPLAY_NAME: "display_name",
    IS_FAVORITE: "is_favorite",
    IS_CREATOR_SYSTEM: "is_creator_system",
    PROFILE:"profile",
    PROFILE_VECTOR_ANALYSIS :"profile_erosion_growth",
    VISIBILITY_OPTIONS: {
        COMPANY: "company",
        USER: "user"
    }
}

const PROFILE_COLUMN = {
    NAME: "column_name",
    RETURN_NAME: "column_return_name",
    IS_CHECKED: "is_column_checked",
    IS_EXPANDABLE: "is_column_expandable",
    IS_EXPANDED: "is_column_expanded",
    HAS_BLUE_DOT: "has_blue_dot",
    CHILDREN: "column_children",
    LEVEL: "column_level",
    IS_GROUP: "is_group",
    IS_IN_SEARCH_RESULT: "is_in_search_result",
    ORDER: "row_order",
    HAS_WARNING: "has_warning",
    WARNING_MSG: "warning_message",
    MACHINE_NAME:"machine_name",
    TOOLTIP:"tooltip",
    DISPLAY_NAME:"display_name",
    VIEW_OPTION_ID:"column_view_option_id",
    TYPE: "type",
    LINE_TYPE:"line_type",
    COLUMN_DESCRIPTION:"column_description",
    COLUMN_GROUP:"column_group",
    COLUMN_GROUP_LINE:"column_group_line",
    COLUMN_TYPE:"column_type",
    COLUMN_TYPE_LINE:"column_type_line",
    COLUMN_FIELD_ID:"column_profile_custom_field_id",
    COLUMN_PROFILE_CUSTOM_GROUP:"column_profile_custom_group_id",
    FORMAT_ID:"format_type_id",
    NAME_IN_FACT:"nameInFact",
    PSL_KEY:"pslKey",
    COLUMN_ORDER: "column_order",
    VIEW_ORDER: "view_order",
    FORMULA: "formula",
    NUMBER:"number",
    FORMAT_NAME: "format_type_name",
    UNIT: "unit",
    VIEW_OPTIONS: {
        VALUE: "value",
        PER_SELECTION: "per_selection",
        PER_TOTAL: "per_total",
        QUADRANT_TIER: "quadrant_tier",
        QUADRANT: "quadrant",
        NAME: "name",
        NUMBER: "number",
        ENTITY_COUNT: "entity_count",
        COUNT_PER_SELECTION: "count_per_selection",
        COUNT_PER_TOTAL: "count_per_total",
        AVERAGE: "average",
        MEDIAN: "median",
        RANGE: "range",
        MINIMUM: "minimum",
        MAXIMUM: "maximum"
    }
}

const SELECTED_VECTOR_VIEWS = {
    NUMBER: "number",
    NAME: "name",
    QUADRANT: "quadrant",
    QUADRANT_TIER: "quadrant_tier",
}

const RULE_FOOTER_KEYS = {  
    total_assigned:'total_assigned',
    total_variance :'total_variance',
    maximum :'maximum',
    minimum : 'minimum',
    average :'averageac',
    median : 'median',
    max_perc:'max_perc',
    total_entities_count : 'total_entities_count',
    entities_assigned_zero : 'entities_assigned_zero',
    perc_entities_assigned_zero :'perc_entities_assigned_zero',
    sd_psl:'sd_psl',
    sd_ac:'sd_ac',
    threshold_ac:'threshold_ac',
    threshold_psl:'threshold_psl'
}

const MANAGE_ACCESS = {
    FIELDS:{
        NAME:"manage_access",
        STATUS :"status",
        ACCESS_GROUP:"access_group",
        ACCESS_GROUP_ID: "id",
        ROLE:"role",
        NAME:"name",
        TYPE:"type",
        ID:"id",
        DESCRIPTION: "description",
        EMAIL: "email",
        FIRST_NAME: "first_name",
        LAST_NAME: "last_name",
        MOBILE_NUMBER:"mobile_number",
        FAILED_ATTEMPTS_NUMBER:"failed_attempts_number",
        VECTOR_ACCESS: {
            AA_CUSTOMER: "always_allow_customer_group",
            AA_PRODUCT: "always_allow_product_group",
            AA_OPERATIONS: "always_allow_operations_group",
            SHOW_NAME: "show_name",
            SHOW_NUMBER: "show_number",
            USER_ALLOWED_VECTORS: "user_allowed_vectors"
        },
        ACCESS_GROUPS: "access_groups",
        ACCESS_GROUPS_MACHINE_NAMES: "access_groups_machine_names",
        VECTORS:"vectors",
        RESTRICTIONS:"restrictions",
        ALLOW_CUSTOMER:"always_allow_customer_group",
        ALLOW_PRODUCT:"always_allow_product_group",
        ALLOW_OPERATIONS:"always_allow_operations_group",
        ALLOW_PSS:"always_allow_profit_stack",
        SHOW_NAME: "show_name",
        SHOW_NUMBER:"show_number",
        LIMITED_ACCESS:"limited_access",
        IS_SYSTEM: "is_system",
        ACTIVE_USERS:"activeUsers",
        ACTIVE_USERS2:"activeusers",
        ACTIVE_ROLES:"activeRoles",
        ACTIVE_ROLES2:"activeroles",
    },
    USER: "Users",
    ACCESS_GROUP_SECTION: "Access Groups",
    ACTIVE:"active",
    INACTIVE: "inactive",
    ROLE:"Roles",
    CORPORATE:"Corporate",
    EVERYONE: "Everyone",
    SALES: "Sales",
    FEATURE: "Feature",
    DEACTIVATE: "deactivate",
    UNBLOCK: "unblock",
    PASSWORD: "password",
    MFA: "mfa",
    NAME:"Name ",
    EMAIL:"Email ",
    ROLE_NAME:"Role ",
    ACCESS_GROUP_NAME:"Access Group ",
    DEFAULT_MAXIMUM_ALLOWED_USERS:"100"
}
const ROLE_ACCESS = {
    ACCESS_GROUP: "Access Group",
    FEATURES: "Feature",
    PROFITSTACK: "ProfitStack",
    VECTOR: "Vector",
    PROFITSTACK1: "Profitstack",
    PROFITSTACK2: "Profit Stack"
}

const USERS_INPUTS ={
    FIRST_NAME: "First Name",
    EMAIL: "Email",
    PASSWORD: "Password",
    LAST_NAME: "Last Name",
    DOMAIN: "Domain",
    MOBILE_NUMBER: "Mobile Number",
    ROLE: "Role",
    SELECT_ROLE: "Select Role"
}

const ROLES_INPUTS ={
    NAME:"Name",
    DESCRIPTION:"Description",
    SELECT_ACCESS_GROUP:"Select Access Group",
    EDIT_ROLE_TITLE :"Edit Role"
}

const ACCESS_GROUP_INPUT ={
    NAME:"Name",
    DESCRIPTION:"Description",
}

const MANAGE_COLUMNS= {
    NAME_ID: "name_id",
    DESCRIPTION_ID: "description_id",
    CUSTOM_GROUP_ID: "custom_group_id",
    TYPE_ID: "type_id",
    GROUPS: {vector: "Vectors", line_attribute:"Line Attributes", profit_stack_line:"Profit Stack Lines" },
    VALUES: ["calculated_ps_line", "per_vector", "per_line_attribute"],
    DISPLAY_NAME: "display_name",
    GROUP_ID: "group_id",
    CHOSEN_GROUP_ID: "chosen_group_id",
    PER_VECTOR_ID: "per_vector_id",
    PER_LINE_ATTRIBUTE_ID: "per_line_attribute_id",
    COLUMN_RETURN_NAME: "column_return_name",
    COLUMN_NAME: "column_name",
    COLUMN_CHILDREN: "column_children",
    VECTOR: "Vector",
    VECTORS: "Vectors",
    PER_VECTOR: "per_vector",
    PER_LINE_ATTRIBUTE: "per_line_attribute",
    CALCULATED_PSL_LINE: "calculated_ps_line",
    id: "column_profile_custom_group_id",
    MEASUREMENT:"measurement",
    MEASUREMENT_DISPLAY_NAME:"Measurement",
    MACHINE_NAME:"machine_name",
    UNIT_SAMPLE:"unit_sample",
    DESCRIPTION:"column_description",
    CUSTOM_ID:"custom",
    PSL_ID:"psl",
    NEW_GROUP_LABEL:"(New Group)",
    COLUMN_IN_SAVED_LIST: "column_in_saved_list",
    NAMES_LIST: "names_list",
    COLUMNS: "columns",
    PSL_LINE: "psl_line",
    LINE_ATTRIBUTE:"line_attribute",
    CALCULATED_PSL_LABEL:"Calculated Profit Stack Line",
    VECTOR_TYPE:"vector",
    SELECTED_VECTOR_DISPLAY_NAME:"Selected Vector",
    SELECTED_VECTOR_MACHINE_NAME:"selected_vector",
    VECTOR_MACHINE_NAME:"vector"
}

const CHECK_FIELD ="check";
const CHECK_TITLE = "";
const APP_BLUE_COLOR = "#2684FF";

const DASHBOARDS = {
    NAME_ID: "name_id",
    DESCRIPTION_ID: "description_id",
    CREATED_BY:"created_by",
    IS_COMPANY_DEFAULT: "is_company_default",
    IS_USER_DEFAULT: "is_user_default",
    ID:"pi_dashboard_id",
    CREATOR_NAME: "creator_name",
    NAME:"name",
    VISIBILITY:"visibility",
    MACHINE_NAME: "machine_name",
    PROFILE_GROUP: "isProfileGroup",
    LIST_TYPE:"list_type",
    LINE_ATTRIBUTE: "line_attribute",
    LINE_ATTRIBUTE_TYPE: "line_attribute_type",
    PROFIT_FORMAT:"profit_format",
    VECTOR:"vector",
    BRIDGE:"bridge",
    PROFIT_STACK_LINE:"profit_stack_line",
    PROFIT_STACKS_TYPE:"profit_stack_type",
    COLUMN:"column",
    CUSTOM_VIEW: "custom_view",
    ORDER_BY:"order_by",
    ORDER_BY_VECTOR_ANALYSIS:"order_by_erosion_growth",
    ORDER:"order",
    FILTER:"filter",
    SIZE:"size",
    IS_DEFAULT:"is_default",
    LABEL:"label",
    CHANGE_COLOR_LOGIC:"change_color_logic",
    IS_SYSTEM :"is_system",
    DESCRIPTION: "description",
    MANAGE_COLUMNS: "manage_columns",
    COMPARISON:"comparison",
    COUNT_TYPE:"count_type",
    DASHBOARD_NAME:"dashboard_name",
    CONFIG_FIELD:{
        PROFIT_FORMAT:"profit_format",
        VECTOR:"vector",
        SORTING_PSL:"sorting_psl",
        SORTING_PSL_VECTOR_ANALYSIS:"sorting_psl_erosion_growth",
        SORTING_DIRECTION:"sorting_direction",
        COMPARISON:"comparison",
        PROFIT_STACK:"profit_stack",
        CUSTOM_VIEW:"custom_view",
        PROFIT_STACK_TYPE:"profit_stack_type",
        PROFIT_STACK_LINE:"profit_stack_line",
        LINE_ATTRIBUTE: "line_attribute",
        LABEL: "label",
        VALUE: "value",
        FORMAT: "format",
        COLUMN:"column",
        PROFILE:"profile",
        BRIDGE:"bridge"
    },
    COMPARISON_TYPES:{
        NONE:"None",
    },
    COMPARISON_VALUES:{
        NONE:"none",
    },
    WIDGET:{
        ID:"dashboard_widget_id",
        SECTION_NAME:"section_name",
        DESCRIPTION_ID:"description_id",
        WIDGET_TYPE:"widget_type",
        WIDGET_TITLE: "title",
        PARENT_SECTION_NAME:"parent_section_name",
        SIZE_ID:"widget_size_id",
        SECTION_ID:"widget_category_section_id",
        WIDTH:"width_ratio",
        HEIGHT:"height_ratio",
        WIDGET_NAME:"widget_name",
        NAME:"widget_name",
        DESCRIPTION:"description",
        DISPLAY_ORDER:"display_order",
        CONFIGURATION:"configuration",
        POSITION_X:"position_x",
        POSITION_Y:"position_y",
        PS_LINE_LIMIT: "ps_line_limit",
        SECTION_URL: "section_url",
        ATTRIBUTES:"attributes",
        CATEGORY:"category",
        PERIODS:"periods",
        IS_ACTIVE: "is_active"
    },
    WIDGET_TYPES:{
        LIST: "list",
        PROFIT_STACKS: "profit_stacks_charts",
        VECTOR_ANALYSIS: "erosion_and_growth",
        VECTOR_METRIC: "vector",
        PROFIT_STACK_LINE_METRIC:"profit_stack_line",
        OTHER_COLUMNS_METRIC:"other_columns",
        LINE_ATTRIBUTE: "line_attribute",
        BRIDGE_REPORT: "bridge"
    },
    CATEGORY:{
        DISPLAY_NAME:"category_display_name",
        MACHINE_NAME:"machine_name",
        CHILDREN:"category_children",
        ORIGINAL_DISPLAY_NAME:"display_name"
    },
    VISIBILITY_OPTIONS: {
        USER: "user",
        COMPANY: "company",
    }
  
    
}

const MODELING_SETTINGS = {
    TITLES: {
        PERIODS:"Periods",
        DATA_SOURCES:"Data Sources",
        set_column_headers: "Set Column Headers - "
    },
    FIELDS:{
        PERIODS:"periods",
        DATA_SOURCES:"data_sources",
        column_header_placeholder: "Input Column Headers"
    }

}

const MANAGE_STACKS = {
    FIELDS:{
        VIEW:"view",
        NAME:"name",
        VISIBILITY:"visibility",
        CREATED_BY:"created_by",
        CREATED_DATE:"created_date",
        MODIFIED_BY:"modified_by",
        MODIFIED_DATE:"modified_date",
        COMPATIBILITY:"compatibility",
        ACTIONS:"actions",
        PARENT_COST_KEY:"parentCostKey",
        RETURN_NAME:"returnName",
        COST_KEY:"costKey",
        COST_TYPE:"costtype",
        PLAN_TERM:"planTerm",
        DESCRIPTION:"profit_stack_line_description",
        EXPAND:"expand",
        CHECKBOX:"checkbox",
        INPUT_DESCRIPTION: "description",
        CUSTOM_STACK_ID: "custom_stack_id"

    }
}

    const MANAGE_COST_CENTER = {
        FIELDS:{
            STATUS: "status",
            ACTIONS: "actions",
            LAST_MODIFIED_DATE:"last_modified_date",
            LAST_MODIFIED_BY:"last_modified_by",
            PERIOD: "period",
            VALID_GL:"valid_gl_staging",
            VALID_TRANSACTION:"valid_il_staging",
            COST_CENTER_STATE:"cost_center_state",
            GENERATING_REVENUE: "revenue_generating",
            NON_GENERATING_REVENUE: "non_revenue_generating",
            GENERATING_REVENUE_TITLE: "Revenue Generating",
            NON_GENERATING_REVENUE_TITLE: "Non Revenue Generating",
            IS_REVENUE_GENERATING:"is_revenue_generating",
            CHECK: "check",
            ALERT: "alert",
            COST_CENTER_NAME: "cost_center_name",
            GL_AMOUNT: "amount",
            REVENUE: "revenue",
            COST_CENTER:"cost_center",
            LINE_COUNT: "line_count",
            STATES: "states",
            GL_COGS: "gl_cogs",
            GL_OPEX:"gl_opex",
            GL_REVENUE:"gl_revenue"
        },
        TITLES:{
            PERIOD: "Period",
            TYPE: "Type"
        },
        STATUS:{DEFINED:"passed", UNDEFINED: "undefined"}
    }   

const SIDE_PANEL_BUILD = {
    TITLES: {
        MANAGE_BUILD_TITLE: "Manage Build",
        BUILD_ACTION_TITLE: "Build Actions"
    },
    TOGGLES: {
        FORCE_ASSIGN_COST: "Force assign cost",
        GENERATE_QUADRANTS: "Generate " + SEGMENT
    },
    DROPDOWNS:{
        REASON_FOR_BUILD:"Reason for Build"
    },
    BUTTONS: {
        BUILD_BUTTON: "Build"
    },
    CHECKBOXES: {
        NOTIFY_EMAIL: "Notify me by email"
    },
    INFO:{
        MANAGE_BUILD_INFO:"title: This section enables you to choose how you want your build carried out and to decide whether you want to be notified of build outcomes.",
        DESCRIPTION_PLACEHOLDER:"Describe the build reason"
    },
    ACTIONS:{
        ASSIGNING_COST: "Assigning Costs",
        GENERATING_QUADRANTS: "Generating Quadrants",
        GENERATING_SEGMENTS: "Generating Segments",
        SENDING_FOR_REVIEW: "Sending For Review"
    }
}

const BUILD_DETAILS = {
    STATUS: {
        BUILT: "Period built",
        NOT_BUILT: "Period not built",
        VARIANCE: "Variance detected"
    },
    COLUMNS: {
        TITLES:{
            SUMMARY: "Summary",
            PERIOD: "Period",
            DESCRIPTION: "Description"
        },
        FIELDS: {
            SUMMARY: "summary",
            PERIOD: "period",
            DESCRIPTION: "description",
            EXPAND: "expand"
        }
    },
    SUMMARY:{
        BUILT_SUCCESSFULLY:"Period is built successfully",
        BUILT_WITH:"Period is built with ",
        ISSUES:"issues",
        READY_TO_BE_BUILT:"Period is ready to be built",
        NOT_ASSIGNES:"cost was not assigned completely"
    },
    DESCRIPTION:{
        NEED_TO_BUILD:"You need to build for your changes to take effect."
    }
}

const CURRENCY = "%CURRENCY%";
const USD_SIGN = "$";
const FIRST_ATTRIBUTE = {label: " " + CURRENCY + " per [selected attribute]", value: "firstAttribute"};
const SECOND_ATTRIBUTE = {label: CURRENCY + "/Attribute", value: "secondAttribute"};
const AMOUNT_OF = "% Amount of ";
const VECTOR_ATTRIBUTE = {label: " " + CURRENCY + " per [selected vector]", value: "secondAttribute"};

const PSL = {label: "%/PSL", value: "psl"};
const AVG = "AVG";
const CONFIGURE_TOGGLE_TABS = [
    { label: CURRENCY, value: "amount"},
    { label: "%", value: "perc"},
    { label: "%ToT", value: "perc_tot"},
];
const MOM_CONFIGURE_TOGGLE_TABS = [
    { label: "Value", value: "amount", sign: "$" },
    { label: "Percentage", value: "perc_tot", sign: "%" }
];
const STACK_CONF = {
    AMOUNT_TYPE :"amountType",
    IS_FIRST_MED:"isFirstMedian",
    IS_SECOND_MED:"isSecondMedian"
}

const CONFIGURE_TOGGLE_TABS_NOT_LIST = [
    { label: "Value", value: "amount" },
    { label: "Percentage", value: "perc_tot" },
];
const AVG_MED_TOGGLE_TABS = [
    { label: "AVG", value: "avg", title: "Average" },
    { label: "MED", value: "med", title: "Median" }
];

const GL_EXCLUSIONS_TABS = [
    { label: "Accounts range", value: "accounts_range" },
    { label: "By parameters", value: "by_parameters" },
]
const MFA_REPORTS = {
    SET_PASSWORD : "SET_PASSWORD",
    PHONE_NUMBER : "PHONE_NUMBER",
    VERIFY_CODE :  "VERIFY_CODE",
    RESET_PASSWORD: "resetPassword"
}

const NONE = {
    value: "none",
    label: "None"
}
const TOAST =  "toast";
const MAX_RECORDS_ALLOWED = 10000;
const MAX_RECORDS_ALLOWED2 = 20000;

const VIEWPORT_WIDTH = 1920;
const MIN_WIDTH_FACTOR = 0.8; 
const defineDataColumns = [
	{
		title: DEFINE_TITLES.FIELD_NAME_TITLE,
		field: DEFINE_FIELDS.FIELD_NAME_FIELD,
		format_type: FormatTypes.TEXT
	},
	{
		title: DEFINE_TITLES.DATA_TITLE,
		field: DEFINE_FIELDS.DATA_FIELD,
	},
	{
		title: DEFINE_TITLES.DATA_TYPE_TITLE,
		field: DEFINE_FIELDS.DATA_TYPE_FIELD,
	},
	{
		title: DEFINE_TITLES.DATA_TYPE_ID_TITLE,
		field: DEFINE_FIELDS.DATA_TYPE_ID_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DATE_FORMAT_TITLE,
		field: DEFINE_FIELDS.DATE_FORMAT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.FORMAT_TITLE,
		field: DEFINE_FIELDS.FORMAT_FIELD,
		headerSort: false,
	},
	{
		title: DEFINE_TITLES.DESCRIPTION_TITLE,
		field: DEFINE_FIELDS.DESCRIPTION_FIELD,
	},
	{
		title: DEFINE_TITLES.CURRENCY_CONVERSION,
		field: DEFINE_FIELDS.CURRENCY_CONVERSION,
		visible: false
	},
	{
		title: DEFINE_TITLES.DISTINCT_VALUES_TITLE,
		field: DEFINE_FIELDS.DISTINCT_VALUES_FIELD,
		sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
			a = Number(a.substring(0, a.indexOf("/")));
			b = Number(b.substring(0, b.indexOf("/")));
			//a, b - the two values being compared
			//aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
			//column - the column component for the column being sorted
			//dir - the direction of the sort ("asc" or "desc")
			//sorterParams - sorterParams object from column definition array

			return a.valueOf() - b.valueOf(); //you must return the difference between the two values
		}
	},
	{
		title: DEFINE_TITLES.SAMPLES_TITLE,
		field: DEFINE_FIELDS.SAMPLES_FIELD,
	},
	{
		title: UPLOAD_TITLES.RAW_FILE_ID_TITLE,
		field: UPLOAD_FIELDS.RAW_FILE_ID_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MIN_TITLE,
		field: DEFINE_FIELDS.MIN_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MAX_TITLE,
		field: DEFINE_FIELDS.MAX_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.IS_TRAILING_TITLE,
		field: DEFINE_FIELDS.IS_TRAILING_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.TRAIL_OR_LEAD_TITLE,
		field: DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.TRUE_INPUT_TITLE,
		field: DEFINE_FIELDS.TRUE_INPUT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.FALSE_INPUT_TITLE,
		field: DEFINE_FIELDS.FALSE_INPUT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DECIMAL_TITLE,
		field: DEFINE_FIELDS.DECIMAL_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MIN_LENGTH_TITLE,
		field: DEFINE_FIELDS.MIN_LENGTH_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MAX_LENGTH_TITLE,
		field: DEFINE_FIELDS.MAX_LENGTH_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DECIMAL_TITLE,
		field: DEFINE_FIELDS.DECIMAL_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.ROW_NUMBER_TITLE,
		field: DEFINE_FIELDS.ROW_NUMBER_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.RAW_FILE_FIELD_ID_TITLE,
		field: DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD,
		visible: false,
	}
];

const FilterUpdateType = {
    Replace: "replace",
    ADD: "add",
    REMOVE: "remove",
    ALL: "all"
}
const CLIENT_ID_STORAGE = "clientId"
const PER_SELECTIOn = "perSelection";
const BRIDGE_REPORT_GROUP_BY = "bridge_report_group_by";

const DEFAULT_CLIENT_ID = "defaultClientId";
const CLIENTS = "clients";
const COLORS_PALETTE = ["#0B84A5", "#F6C85F", "#6F4E7C", "#9DD866", "#F03B1F", "#FFA056", "#8DDDD0", "#C51C8A", "#2CA25F", "#B30000"];
const DRILL_PARAMETERS_LABEL = "Drill parameters:";

const SELECTED_DRILL_LIST = "selectedProfile_list_drill";

const SHOW_RELEASE_MEASSAGE = "show_release_message"

const SELECTED_PROFILE_EROSION = "selectedProfile_erosion";

const ENTITY_TYPES = {
    KEY:"Key",
    SEGMENT:"Segment",
    PROFITTIER:"ProfitTier"
}

const VECTOR_TABS_OPTIONS = [
    { label: "Name", value: "name" },
    { label: "Number", value: "number" },
];

const VECTOR_SEGMENTS_TABS_OPTIONS = [
    { label: "Profit Segment", value: "profit_segments" },
    { label: "Profit Tier", value: ENTITY_TYPES.PROFITTIER },
];

const HEATMAP = {
    LABELS: {
        show_vector_entity_by: "Show vector entities by",
        show_vector_segment_by: "Show vector segments by",
        psl_label: "Line used for calculation",
        threshold_label: "Show values above",
        stack_label: "Stack used for line selection",
    },
    INFO: {
        doesnt_apply_on_segments: "Does not apply on " + SEGMENT,
        doesnt_apply_on_entities: "Does not apply on " + FILTER.KEYS.ENTITIES,
        psl_tooltip: "The selected profit stack line will be used for the cross column percentage calculation.",
        threshold_tooltip: "The percentages in the threshold are in absolute value.",
    },
    CONFIGURE_OBJECT: {
        selected_vector_entity: "selectedVectorEntity",
        selected_vector_segment: "selectedVectorSegment",
        selected_psl: "psl",
        threshold: "threshold",
        stack: "stack",
    },
    COLUMNS :{
        CHECK:"check",
        ENTITIES:"entities",
        REVENUE:"revenue",
    }
}

const DATA_LAYER_PARAMS = {
    EVENT: "event",
    USER_EMAIL: "userEmail",
    SCENARIO: "scenario",
    SELECTED_RANGE: "selected_range",
    SELECTED_COLUMNS: "selected_columns",
    SELECTED_VECTORS: "selected_vectors",
    FILTER: "filter",
    PAGE_SIZE: "page_size",
    PAGE_NUMBER: "page_no",
    COLUMN_SORTED: "column_sorted",
}
const BOUNDARIES = {STATE:"State", ZIPCODE:"Zip Code"};

const SIZES = {
    SMALL: "small",
    DEFAULT: "default",
    MEDIUM: "medium",
    LARGE: "large",
    ICON: "icon"
}

const TABULATOR_BUTTON = {
    ROUNDED: "rounded",
    BUTTON: "button",
    EDGED: "edged",
    ICON: "icon",
    NO_BORDER: "no_border"
}

const BUTTON_VARIANT = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    // SECONDARY: "secondary2",
    SECONDARY3: "secondary3",
    TERTIARY: "tertiary",
    TERTIARY2: "tertiary2",
}

const BUTTON_DROPDOWN_VARIANT = {
    BUTTON: "button",
    INPUT: "input"
}

const BUTTON_TYPE = {
    DEFAULT: "default",
    DANGER: "danger",
}

const DROPDOWN_TYPE = {
    INPUT: "input",
    BUTTON: "button"
}

const INPUT_VARIANT = {
    DEFAULT: "default",
    ERROR: "error"
}

const DIALOG_SIZE = {
    XXLARGE: "xxl",
    XLARGE: "xl",
    LARGE: "l",
    MEDIUM: "m",
    SMALL: "s"
}
const DIALOG_TRANSITION = {
   BOTTOM: "bottom",
   TOP: "top"
}

const ROLLING_SEGMENTS = {
    R_12: "R12",
    R_3: "R3"
}

/** these colors are for the landing page menu items' icon's background color */
const MENU_ITEMS_SVG_COLOR = {
  "reporting": "#7678ED",
  "configuration": "#0097F6",
  "administration": "#8D8D8D"
}

const CONFIGURE_GL_ACCOUNT_RANGES = {
    COLUMNS : {
        TITLES : {
            ACCOUNT_CATEGORY: "Account Category",
            ACTION: "Action",
            ACCOUNTS_LIST:"Account Number"
        },
        FIELDS : {
            ACCOUNT_CATEGORY: "name",
            ACCOUNT_CATEGORY_DISPLAY: "display_name",
            ACTION: "action",
            ACCOUNTS_LIST:"account_number"
        }
    },
    MODAL : {
        TITLES : {
            gl_exclusions: "GL Out of scope",
            gl_revenue: "Configure revenue accounts",
            gl_cogs: "Configure Cost of sales accounts",
            gl_opex: "Configure Opex accounts",
        },
        INPUTS : {
            STARTING: "Starting Account",
            ENDING: "Ending Account"
        },
        BUTTON : {
            ADD_RANGE: "Add range",
            ADD_ACCOUNT: "Add accounts",
            EXCLUDE: "Exclude accounts",
            CANCEL: "Cancel"
        },
    },
    KEYS : {
        EXCLUSIONS : "gl_exclusions",
        ACCOUNT_START_RANGE: "account_start_range",
        ACCOUNT_END_RANGE: "account_end_range"
    }
}

const DETAILED_TIE_OUT = {
    MODAL : {
        TITLE : "Detailed Tie out",
        STEPPER: "P&L [TYPE] tie out",
        INPUTS_TITLES : "What is P&L [TYPE] value for [PERIOD]?",
        BUTTON : {
            TIE_OUT: "Tie out",
            PROCEED: "Proceed",
            EXPORT: "Export GL accounts",
            UPLOAD: "Go to upload files",
            FINISH: "Finish"
        }
    }
}

const TIE_OUT_PL_GL = {
    TIED_OUT: "TIED_OUT",
    NEARLY_TIED_OUT: "NEARLY_TIED_OUT",
    NOT_TIED_OUT:  "NOT_TIED_OUT",
    BANNER_CLASS_NAME : {
        TIED_OUT: "banner-tied-out",
        NEARLY_TIED_OUT: "banner-nearly-tied-out",
        NOT_TIED_OUT: "banner-not-tied-out",
    }
    }

    const DEFINE_COST_CENTER = {
        COLUMNS : {
            FIELDS : {
                COGS:"gl_cogs",
                OPEX:"gl_opex",
                GL_REVENUE:"gl_revenue",
                TRX_REVENUE:"revenue",
                STATE:"cost_center_state",
                NAME:"cost_center_name",
                COST_CENTER:"cost_center",
                TRX_LINE:"line_count"
            },
            DROP_DOWN : {
                IS_REVENUE_GENERATING:"is_revenue_generating",  
            }
        }
    }

    const GL_STRINGS_REVIEW = {
        COLUMNS : {
            TITLES : {
                ACCOUNT_NUMBER:"Account number",
                COST_CENTER_STATE:"Cost center state",
                DEPRATMENT:"Depratment",
                AMOUNT:"Amount",
                ASSIGNED_TO:"Assigned To",
                ACTION:"Action"
            },
            FIELDS : {
                ACCOUNT_NUMBER:"account_number",
                COST_CENTER_STATE:"state",
                DEPRATMENT:"department",
                AMOUNT:"total",
                ASSIGNED_TO:"assigned_to",
                ACTION:"fields_combination"
            }
        }
    }

    const LISTENER = {
        PORFILE : {
            LOGOUT_START: "LogoutEvent",
            LOGOUT_END: "ConfirmLogoutEvent",
            CHANGE_CLIENT_START: "SwitchClientEvent",
            CHANGE_CLIENT_END: "ConfirmSwitchClient"
        }
    }

    const INITIAL_COMPLETED_STEPS = {
        INTRODUCTION: false,
        UPLOAD_FILES: false,
        TIE_OUT_PL_GL: false,
        DEFINE_COST_CENTERS: false,
        SETUP_PROFIT_STACK: false,
        BUILD_SCENARIO: false
    }
    const BUILD_STEPS = {
        BUILD_PROGRESS: "BUILD_PROGRESS",
        PUBLISH_PERIOD: "PUBLISH_PERIOD"
    }
    const EMPTY_FILTER = "{\'filter\':[]}"

    const INITIAL_COMPLETED_BUILD_SUB_STEPS = {
        BUILD_PROGRESS: false,
        PUBLISH_PERIOD: false
    }

    const INITIAL_BUILD_SUB_STEPS = [
        { label: 'Build Progress', id: BUILD_STEPS.BUILD_PROGRESS, complete: false },
        { label: 'Publish Period', id: BUILD_STEPS.PUBLISH_PERIOD, complete: false },
    ];

    const BUILD_PROGRESS_STEPS = {
        PREPARING_DATA: "PREPARING_DATA",
        ASSIGNING_AMOUNTS: "ASSIGNING_AMOUNTS",
        GENERATING_DATA: "GENERATING_DATA",
        GENERATING_SEGMENTS: "GENERATING_SEGMENTS",
        BUILD_COMPLETE: "BUILD_COMPLETE",
    }

    const INITIAL_PROGRESS_STEPS = [
        { numb: 0, label: 'Preparing Data', id: BUILD_PROGRESS_STEPS.PREPARING_DATA, complete: false },
        { numb: 1, label: 'Assigning Amounts', id: BUILD_PROGRESS_STEPS.ASSIGNING_AMOUNTS, complete: false },
        { numb: 2, label: 'Generating Data', id: BUILD_PROGRESS_STEPS.GENERATING_DATA, complete: false },
        { numb: 3, label: 'Generating Segments', id: BUILD_PROGRESS_STEPS.GENERATING_SEGMENTS, complete: false },
        { numb: 4, label: 'Build Complete', id: BUILD_PROGRESS_STEPS.BUILD_COMPLETE, complete: false }
    ];

    const CURRENT_BUILD_STATUS = {
        RUNNING: "RUNNING",
        SUCCEEDED: "SUCCEEDED",
        FAILED: "FAILED",
        NOT_STARTED: "NOT_STARTED",
        CANCEL: [
            'canceling',
            'canceled'
            ]
    }

    const period_calendar_type = {
      BUILT: "built",
      ACTIVE: "active",
    }

    const IMAGE_TYPE={
        RELEASE_NOTES:"RELEASE_NOTES",
        PROFIT_SEGMENTATION_DEFINITION:"PROFIT_SEGMENTATION_DEFINITION",
        CLIENT_LOGOS:"CLIENT_LOGOS",
    }


    const drillParam = "DrillParam";
    const logged_in = "loggedIn";

const BUILD_STATUS = {
    STATUS: "buildStatus",
    SUCCEEDED: "succeeded",
    FAILED: "failed",
    CANCELING: "canceling",
    RUNNING: "running",
}
const BUILD_SCENARIO_ID = "buildScenarioId";
const IS_BUILD_RUNNING = "isBuildRunning";
const YTD_TOTAL_COLUMN_SUFFIX = "_selection_td2";


const SUFFIX = {
  NAME: "_name",
  NUMBER: "_number",
  SEGMENT: "_quadrant",
  SEGMENT_TIER: "_quadrant_tier",
  ENTITY_COUNT: "_entity_count",
  COUNT_PER_SELECTION: "_count_per_selection",
  COUNT_PER_TOTAL: "_count_per_total",
}

const ENTITY_STACKS_OBJECT = {
    VECTOR_NAME: "vectorName",
    VECTOR_MACHINE_NAME: "vectorMachineName",
}

const EXPORT_CONTAINER = {
    EXPORT_VISIBLE: "export_visible",
    EXPORT_ALL: "export_all",
}

const EXCLUSIONS = "Exclusions";
const BUCKETS = "Buckets";
const VECTORS_SETUP = "Vectors";
export const SUPPLY_CHAIN_SETUP = {
  SCREEN_TITLE: "Supply Chain Setup",
  PROFIT_FORMAT: "supply_chain_setup",
  VECTOR_TYPE: "vector_type",
  TABS: [VECTORS_SETUP, EXCLUSIONS, BUCKETS],
  TABS_DETAILS: {
    EXCLUSIONS: {
      NAME: EXCLUSIONS,
    },
    BUCKETS: {
      NAME: BUCKETS,
    },
    VECTORS: {
      NAME: VECTORS_SETUP,
    },
  },
    VECTORS_DROPDOWN_TYPE: {
        LOWEST_VECTOR: "lowest_vector",
        PRODUCT_HIERARCHY: "product_hierarchy",
        CHANNEL: "channel",
        SUPPLY: "supply"
    },
  API_URLS: {
    FETCH_CONFIGURATION: "/get-supply-chain-configuration",
    SAVE_EXCLUSIONS: "/save-exclusion-configuration",
    SAVE_BUCKETS: "/save-bucket-configuration",
    SAVE_VECTORS_CONFIGURATION: "/save-vectors-configuration",
  }
};

export const TLC_LANDSCAPE = {
    TABS: {
        MARGIN_VIEW: "margin_view",
        PRODUCT_HIERARCHY: "cost_view",
    },
    SUB_TABS: {
        TOTAL: "total",
        PROFIT_SEGMENTS: "profit_segment"
    }
};
const CLIENT_PROFIT_SEGMENT_DICTIONARY = "clientProfitSegmentDictionary";
export const MENU_ITEM_URLS = {
  PROFIT_PATTERNS_ENTITY_STACKS: "/financial_planning_analysis/profit_patterns/entity_stacks",
  LIST_ENTITY_STACKS: "/financial_planning_analysis/entity_stacks"  
}

export const TLC_CHART_COLORS = [
  { color: "#030451" },
  { color: "#5F60C8" },
  { color: "#5DC6F3" },
];

export const SORT_TITLES = {
    TITLE: {
        NAME: "Name",
        NUMBER: "Number",
        SEGMENT_TIER: "Segment/Tier",
        ENTITY_COUNT: "Entity Count"
    },
    VALUE: {
        NAME: "name",
        NUMBER: "number",
        ENTITYCOUNT: "entitycount",
        ENTITY_COUNT: "entity_count",
        COUNT_PER_SELECTION: "count_per_selection"
    },
    FIELD: {
        NAME_COMBINATION: "name_combination",
        NUMBER_COMBINATION: "number_combination",
        QUADRANT_COMBINATION: "quadrant_combination",
        QUADRANTTIER_COMBINATION: "quadranttier_combination"
    }
};

export const COMBINATION_FIELD = "combination";
export const vectorViews = [PSL_RETURN_NAMES.QUADRANT_TIER, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER,
    PSL_RETURN_NAMES.QUADRANT, PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.KEY,
    PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION, PSL_RETURN_NAMES.COUNT_PER_TOTAL,
    "name_combination",
    "number_combination",
    "quadrant_combination",
    "quadranttier_combination",
    "quadrant_tier_combination",
    "entity_count_combination",
    ];
export {HEADER_ELEMENT, header_comparison_values, time_range_values, FISCAL_YEARS, QUARTERS, PROFIT_STACK_LINES, bridge_config, columnConfig, comparison_suffixes,
    UPLOAD_TITLES, UPLOAD_FIELDS, DATATYPE_ENUM, COST_KEY_TITLE, COST_KEY_FIELD, NAME_TITLE, NAME_FIELD, EXPAND_FIELD, ADD_FIELD,
    DELETE_FIELD, MAP_FIELD, FILTER_FIELD, COST_CENTER_FIELD, COST_CENTER_TITLE, AMOUNT_FIELD, AMOUNT_TITLE, FILTER_TITLE,
    PARENT_COST_KEY_TITLE, PARENT_COST_KEY_FIELD, HANDLE_ROW, RAW_FILE, VECTOR_STAGING_ATTRIBUTES, HISTORY_FIELDS, HISTORY_TITLES,
    DATA_DICTIONARY_ENUM, STATUS_ENUM, DEFAULT_TIME_ZONE, DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT_MS, DEFAULT_DATE_FORMAT, FILE_VALIDATIONS, 
    SAVED_FILTER_ID_COOKIE, CURRENT_FILTER_COOKIE, UPLOAD_SECTIONS, SECTION,
    ALL_WIDGETS, DEFINE_FIELDS, DEFINE_TITLES, Formats, FILE_CENSUS_FIELDS, FILE_CENSUS_TITLES, FILECENSUS_NAMING_TEMPLATE_KEYWORDS, Amendments, Delimiters, ENCRYPTION_OPTIONS, UPLOAD_DATA,
    DEFINE_DATA, HISTORY_DATA, NEW_SECTION, TRAILING_CHARACTERS, LEADING_CHARACTERS, dateFormats, ALERT_DEFINE_MESSAGE, ALERT_FILL_MESSAGE,ALERT_BOOLEAN_MESSAGE,
    PRIMARY_KEY, RAW_FILE_VALIDATION, DEFAULT_DATE_FORMAT_UPLOAD, STAGING_SECTIONS, GLACCOUNTS_FIELDS, GLACCOUNTS_TITLES, PS_MAPPING,
    METRICS_MAPPING, Type, STAGE_FIELDS, STAGE_TITLES, ACCOUNT_AMOUNT_TITLES, ENTRY_TYPE, SCENARIOS,
    ACCOUNT_AMOUNT_FIELDS, INFO_MESSAGE, SCENARIO_FIELDS, SCENARIO_TITLES, SCENARIO_STATUS, FILTER_UNSAVED_FILTER, PSL_RETURN_NAMES, CONFIGURE_VARS,
    PERIOD_STATUS_ENUM, GENERATE_STATUS, PERIOD_ONWARD, TRANSACTION_STATUS_ENUM, ALL_REPORTS, PROFIT_SUMMARY, METRIC_MAPPING_COLUMNS, STAGING_STATUS_ENUM, PREFIX_ID_SPAN,
    PREFIX_ID_CHECKBOX, PREFIX_ID_DIV, API_URL, ENGINE_JOB_TYPE, LOG_LEVEL, FILTER_FUNCTIONS, STAGING_SECTIONS_TITLES, CONFIGURE_SECTIONS,
    BUILD_SECTIONS, TOOLTIP_AMENDMENT_MESSAGE, COST_FUNCTIONS_FIELDS, COST_FUNCTIONS_TITLES, SCENARIO_ID_COOKIE_NAME,
    BUILD_MODEL, GENERATE_QUADRANTS, INVOICELINETYPE, RULE_ENTITIES, PERIOD_CUSTOM_DATE_RANGE, EXCLUDED_LINES_FIELDS, EXCLUDED_LINES_TITLES,
    EXCLUDED_LINES_TIE_OFF_TITLES, TOTAL_EXCLUSIONS_TITLES, TOTAL_EXCLUSIONS_FILEDS, CALCULATED_COLUMNS, MACHINE_NAME_SUFFIXES,
    FORMULA_ELEMENT_TYPE, VIEWMORE_TIEOFF_EXCLUSIONS_COLUMNS_TITLES, VIEWMORE_TIEOFF_EXCLUSIONS_COLUMNS_FILEDS, VIEW_MORE_INVOICE_LINE_FIELDS, 
    VIEW_MORE_INVOICE_LINE_TITLES, VECTOR_MAPPING, VECTOR_MAPPING_VIEW_MORE, VECTOR_MAPPING_VIEW_MORE_SUMMARY, IDENTIFIERS, SYSTEM_CONFIG, RAW_ITEMS, IS_TRANSITIONED,
    FILTER, PROFIT_STACK_VIEW, VECTOR_GROUPS, CACHE_MANAGER, FORMAT_SETTINGS_PRIVATE, FORMAT_SETTINGS, ColFilterTypes, FormatTypes, FORMAT_TYPE, ALL_FORMATS,
    DATASET, PSS, DEFINE_CREDIT_DEBIT_ENTRY_TYPE, costtype, ROW_NUMBER, RAND, ROW_STATUS, TOTAL_COMPANY, QUADRANTS, EXCLUDED_LINES_TIE_OFF_FIELDS, SCENARIO_ACTION,
    SUMMARY_FIELDS, SUMMARY_TITLES, CC_LABELS, FY_VALUES, ORDER_VALUES, UI_ACTIONS, LOG_OBJ_KEYS, FISCAL_YEAR, STAGING_SECTIONS_ARRAY_TITLES, ENGINE_FILTER, COSTCENTER_STATE_STATUS,
    COSTCENTER_STATE_STATUS_PREDICTED, COSTCENTER_STATE_STATUS_NOT_DEFINED, DEFINE_COSTCENTER, EDIT_COST_CENTER_COLUMNS, COSTCENTER_PREDICTED_STATE, ADD_CC_COLUMNS,
    CONFIG_SETTINGS, DM_SET_REPORT_ACTIONS, MANAGE_REPORT, CONFIG_QUADS, FILTER_VALUES_LIMIT, ACCRUALS, STACK_CONF,
    MANAGE_ACCRUALS_STATUSES, ACCRUAL_STATUS, FOCUS_ACCRUALS, CROSSWALK_FIELDS, PI_CW, VECTOR_DEFINITONS, VECTOR_ANALYSIS, NET_REVENUE, GROSS_PROFIT,
    YEAR_OVER_YEAR,PREVIOUS_ADJACENT,TOP_QUADRANT,TOP_QUADRANT_TIER, PERIOD, CURRENCY_FIELDS, PERIODS, column_suffixes, session_storage_keys, COST_ASSIGNMENTS, COLUMN_PROFILE,
    PROFILE_COLUMN, SELECTED_VECTOR_VIEWS, RULE_FOOTER_KEYS, column_suffixes_values, TOAST, MAX_RECORDS_ALLOWED, MAX_RECORDS_ALLOWED2,
    CHECK_FIELD, CHECK_TITLE, APP_BLUE_COLOR,DROP_DOWN_ELEMENT,MANAGE_ACCESS,ROLE_ACCESS,USERS_INPUTS,ROLES_INPUTS,ACCESS_GROUP_INPUT, MANAGE_COLUMNS, DASHBOARDS,INVOICE_LINE,BP_QUADRANT_TIER,FormatTypeSymbols,
    MANAGE_STACKS,MODELING_SETTINGS, VIEWPORT_WIDTH, MIN_WIDTH_FACTOR, MANAGE_COST_CENTER ,SIDE_PANEL_BUILD, BUILD_DETAILS, MFA_REPORTS, RULE_ENTITIES_PSL_DEFINITIONS, COMPARE_SCENARIOS, defineDataColumns, NONE, STACKS_RANGE,
    CONFIGURE_TOGGLE_TABS, FIRST_ATTRIBUTE, SECOND_ATTRIBUTE, AVG_MED_TOGGLE_TABS, AVG, PSL, PER_SELECTIOn, BRIDGE_REPORT_GROUP_BY, FilterUpdateType,
    COLORS_PALETTE, CUSTOM_REPORT, DRILL_PARAMETERS_LABEL, CONFIGURE_TOGGLE_TABS_NOT_LIST, CLIENT_ID_STORAGE,SCENARIO_TYPE, DEFAULT_CLIENT_ID, CLIENTS, SELECTED_DRILL_LIST, SELECTED_PROFILE_EROSION, VECTOR_TABS_OPTIONS, HEATMAP, LANDSCAPE_BUBBLE,
    DATA_LAYER_PARAMS, DATA_TYPE, CURRENT_FILTER_VISIBILITY_COOKIE,BOUNDARIES, SIZES, BUTTON_VARIANT, BUTTON_TYPE,DROPDOWN_TYPE, INPUT_VARIANT, BUTTON_DROPDOWN_VARIANT, RED_GREEN_COLORS, 
    TABULATOR_BUTTON, DIALOG_SIZE, DIALOG_TRANSITION, SEGMENTS, SEGMENTS_TITLES, SEGMENTS_TIERS, ROLLING_SEGMENTS, MENU_ITEM, MENU_ITEMS_SVG_COLOR, CONFIGURE_GL_ACCOUNT_RANGES,GL_EXCLUSIONS_TABS, DETAILED_TIE_OUT, TIE_OUT_PL_GL,
    DEFINE_COST_CENTER, CUSTOM_REPORTS, GL_STRINGS_REVIEW, SHOW_RELEASE_MEASSAGE, LISTENER, INITIAL_COMPLETED_STEPS, MAPPING_TITLES,BUILD_STEPS, EMPTY_FILTER,
    period_calendar_type,IMAGE_TYPE,drillParam,ENTITY_TYPES, INITIAL_COMPLETED_BUILD_SUB_STEPS, INITIAL_BUILD_SUB_STEPS, logged_in, BUILD_STATUS, BUILD_SCENARIO_ID, IS_BUILD_RUNNING,
    YTD_TOTAL_COLUMN_SUFFIX, VECTOR_SEGMENTS_TABS_OPTIONS, MOM_CONFIGURE_TOGGLE_TABS, SUFFIX, ENTITY_STACKS_OBJECT, AMOUNT_OF, SELECTED_CONF_OPTIONS, VECTOR_ATTRIBUTE, EXPORT_CONTAINER,
    INITIAL_PROGRESS_STEPS, CURRENT_BUILD_STATUS, BUILD_PROGRESS_STEPS, CLIENT_PROFIT_SEGMENT_DICTIONARY, MANAGE_CUSTOM_REPORT, NUMBER_FIELD, NUMBER_TITLE, PROFIT_TIER, CHATBOT_PROMISE_ERROR, FORMAT_TYPE_NAMES, CURRENCY, USD_SIGN
}