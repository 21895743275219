import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../ErrorBoundary";
import { updateVectorState } from "../../actions/scenariosActions";
import { logout } from "../../class/common";
import { BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES, SUPPLY_CHAIN_SETUP, costtype } from "../../class/constants";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import ExclusionsConfiguration from "../../components/supplyChainSetup/ExclusionsConfiguration";
import "../../components/supplyChainSetup/supplyChainSetup.css";
import { Loader, Tabs } from "../../form/elements";
import { useAuthenticated } from "../CustomHooks";
import { ReportHeader } from "../ReportHeader";
import { fetchConfiguration, getProfitStackHierarchy, getVectors } from "../api/api";
import { renderTitle } from "../functions/componentFunctions";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import { areObjectsNotEmpty, copyObjectValues } from "../../class/utils";
import BucketsConfiguration from "../../components/supplyChainSetup/BucketsConfiguration";
import Toast from "../../newComponents/Toast";
import Modal from "../../newComponents/Modal";
import VectorsConfiguration from "../../components/supplyChainSetup/VectorsConfiguration";

const SupplyChainWrapper = (props) => {
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const propsScenarioState = props.scenarioState;
  const psLinesOptions = useSelector(state=>state.psLinesOptions);
  const { checkingForUnsavedChanges } = props;

  /** References & react utility */
  const reportRef = useRef();
  const toastRef = useRef(null);
  const dispatch = useDispatch();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /** State */
  const [vectorState, setVectorState] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [divIdState, setDivIdState] = useState();
  const [destinationClientIdState, setDestinationClientIdState] = useState();
  const [isCheckUnsavedChangesDialogOpen, setCheckUnsavedChangesDialogOpen] = useState(false);

  /** Header Related States */
  const reportTitle = SUPPLY_CHAIN_SETUP.SCREEN_TITLE;
  const profitFormat = SUPPLY_CHAIN_SETUP.PROFIT_FORMAT;

  const [headerElements, setHeaderElements] = useState([]);
  const [activeTab, setActiveTab] = useState(SUPPLY_CHAIN_SETUP.TABS[0]);
  const [selectedTab, setSelectedTab] = useState(undefined);
  const [subtitle, setSubtitle] = useState(lang.supply_chain_set_up.vectors.subtitle);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [psLines, setPSLines] = useState({});
  const [configurationData, setConfigurationData] = useState({});
  const [fromSaveButton, setFromSaveButton] = useState(false);

  /** On screen initialization: if user entered url with no access to screen, he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /**
   * @function useEffect()
   * On mount set header elements which is the title only in our case
   */
  useEffect(() => {
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, [activeTab, hasUnsavedChanges]);

    /**
   * @function useEffect()
   * On mount, we fetch the supply chain configration and the vectors of the published scenario
   */
    useEffect(() => {
      let comp = {props:{pslinesoptions:psLinesOptions, dispatch:dispatch}}
      getVectors(
        propsScenarioState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        [],
        userSettings,
        vectorState
      );
      getProfitStackHierarchy(0, propsScenarioState?.scenario, comp, setPSLData);
    }, []);
  
    useEffect(() => {
      if(areObjectsNotEmpty([vectorState, psLines])) {
        fetchConfiguration(false, setConfigurationData);
      }
    }, [vectorState, psLines])


    const setPSLData = (pslData) => {
      let pslArray = pslData.filter(f=>f.costtype !== costtype.attribute);
      setPSLines(pslArray);
    }

  /**
   * @function getHeaderElements()
   * Return header elements
   */
  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
      <div style={{ display: "flex", width: "100%" }}>
        <div key={"titles"} style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-start" }}>
          {renderTitle(reportTitle)}
          <span className="setup-subtitle">{subtitle}</span>
        </div>
        <div key={"tabs"} style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <Tabs activeTab={activeTab} onChange={onChangeTab} tabs={SUPPLY_CHAIN_SETUP.TABS} />
        </div>
      </div>
    );

    return headerElements;
  };

  /**
   * @function onChangeTab()
   * @description This function handles changes when we switch between tabs
   * @param {*} tab new selected tab
   */
  const onChangeTab = (tab, ignoreCheck=false) => {
    if(!tab) {return};
    if(hasUnsavedChanges && !ignoreCheck) {
      setSelectedTab(tab);
      setCheckUnsavedChangesDialogOpen(true);
     return;
    }

    setSelectedTab(undefined);
    setHasUnsavedChanges(false);
    setActiveTab(tab);
    changeSubtitle(tab);
  };

  const changeSubtitle = (tab) => {
    switch (tab) {
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.EXCLUSIONS.NAME:
        setSubtitle(lang.supply_chain_set_up.exclusions.subtitle);
        break;
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.BUCKETS.NAME: {
        setSubtitle(lang.supply_chain_set_up.buckets.subtitle);
        break;
      }
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.VECTORS.NAME: {
        setSubtitle(lang.supply_chain_set_up.vectors.subtitle);
        break;
      }
      default:
        setSubtitle(lang.supply_chain_set_up.vectors.subtitle);
        break;
    }
  };

  const fetchConfiguration = () => {
    let query = {
      action: "fetchConfiguration",
    };

    const onThenCallback = (data) => {
      if (data?.configuration) {
        setConfigurationData(data.configuration);
      } else {
        setConfigurationData({exclusionConfiguration: [], vectorConfiguration: [], bucketsConfiguration: []});
      }
    };

    const fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "fetchConfiguration",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: SUPPLY_CHAIN_SETUP.API_URLS.FETCH_CONFIGURATION,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    };

    fetchAPI(fetchOptions);
  };



  /**
   * @function renderTabBody()
   * @description Renders the body based on the active tab
   * @returns active tab's related component
   */
  const renderTabBody = () => {
    switch (activeTab) {
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.EXCLUSIONS.NAME: {
        return (
          <ExclusionsConfiguration
            ref={reportRef}
            vectorState={vectorState}
            scenarioId={propsScenarioState?.scenario}
            user={userSettings.user}
            filter={configurationData?.exclusionConfiguration}
            updateConfigurationObject={updateConfObjAndShowToast}
            setHasUnsavedChanges={setHasUnsavedChanges}
            psLines={psLines}
            dispatch={dispatch}
          />
        );
      }
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.BUCKETS.NAME: {
        return(
          <BucketsConfiguration
            ref={reportRef}
            psLines={psLines}
            bucketsConfiguration={configurationData.bucketsConfiguration}
            setHasUnsavedChanges={setHasUnsavedChanges}
            updateConfigurationObject={updateConfObjAndShowToast}
            userSettings={userSettings}
            fromSaveButton={fromSaveButton}
            setFromSaveButton={setFromSaveButton}
          />
      );
      }
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.VECTORS.NAME: {
        return (
          configurationData?.vectorsConfiguration &&
          <VectorsConfiguration 
            ref={reportRef}
            vectors={vectorState.vectorOptions}
            vectorsConfiguration={configurationData?.vectorsConfiguration}
            setHasUnsavedChanges={setHasUnsavedChanges}
            updateConfigurationObject={updateConfObjAndShowToast}
          />
        );
      }
    }
  };

  const saveConfiguration = (callback, fromSaveButton= true) => {
    setFromSaveButton(fromSaveButton)
    if (!hasUnsavedChanges) return;
    switch (activeTab) {
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.EXCLUSIONS.NAME: {
        reportRef.current?.saveExclusionsConf(callback);
        break;
      }
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.BUCKETS.NAME: {
        if (typeof callback === "function") { // if from dialog
          reportRef.current?.saveBucketConf(callback);
        }
        break;
      }
      case SUPPLY_CHAIN_SETUP.TABS_DETAILS.VECTORS.NAME: {
        reportRef.current?.saveVectorsConf(callback);
        break;
      }
    }
  };

  const updateConfObjAndShowToast = (isSuccess, tab, newObject = undefined) => {
    const isSaveSuccess = isSuccess;
    if (isSaveSuccess) {
      let confObjState = copyObjectValues(configurationData);
      switch (tab) {
        case SUPPLY_CHAIN_SETUP.TABS_DETAILS.EXCLUSIONS.NAME: {
          confObjState.exclusionConfiguration = newObject;
          break;
        }
        case SUPPLY_CHAIN_SETUP.TABS_DETAILS.BUCKETS.NAME: {
          confObjState.bucketsConfiguration = newObject;
          break;
        }
        case SUPPLY_CHAIN_SETUP.TABS_DETAILS.VECTORS.NAME: {
          confObjState.vectorsConfiguration = newObject;
          break;
        }
      }
      setConfigurationData(confObjState);
    }
  
    showToast(isSaveSuccess);
  };

  const showToast = (isSuccess) => {
    let message = isSuccess ? lang.saved : lang.not_saved;
    setToastStatus(isSuccess);
    setToastMessage(message);
    toastRef?.current?.displayToast();
  };

  /**
   * @function useEffect()
   * @description This function checks if the user has unsaved changes when he chooses to change report or to switch client.
   * In case he doesn't, he will be rerdirected to his chosen report, else a dialog opens to let him save/discard his changes before proceeding.
   */
  useEffect(() => {
    if (checkingForUnsavedChanges) {
      setDivIdState(props.divId);
      setDestinationClientIdState(props.destinationClientId);
      if (hasUnsavedChanges) {
        setCheckUnsavedChangesDialogOpen(true);
      } else {
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
      }
    }
  }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

  /**
   * @function closeUnsavedChangesDialog()
   * @description This function closes the unsaved changes' dialog
   */
  const closeUnsavedChangesDialog = () => {
    setCheckUnsavedChangesDialogOpen(false);
    setDestinationClientIdState(undefined);
    setDivIdState(undefined);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(false, false, false);
    setSelectedTab(undefined)

  };

  /**
   * @function onButtonNo()
   * @description This function is the on click function of the button 'No' in the 'Unsaved changes' dialog.
   * That's when the user decides to proceed without saving his changes.
   * It discards all changes and proceed in changing report/client
   */
  const onButtonNo = () => {
    setCheckUnsavedChangesDialogOpen(false);
    props.setCheckingForUnsavedChanges(false);
    if(selectedTab) {
      onChangeTab(selectedTab, true);
    } else {
      props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    }
  };

  /**
   * @function onButtonYes()
   * @description This function is the on click function of the button 'Yes' in the 'Unsaved changes' dialog.
   * That's when the user decides to save his changes before proceeding.
   * It triggers the save changes fucntion and when the changes are saved successfully, the user will be redirected to his chosen report
   */
  const onButtonYes = () => {
    let cb = () => {
      props.setCheckingForUnsavedChanges(false);
      if(selectedTab) {
        onChangeTab(selectedTab, true);
      } else {
        props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
      }
    };
    setCheckUnsavedChangesDialogOpen(false);
    saveConfiguration(cb, false);
  };

  /**
   * @function unsavedChangesDialogActions()
   * @description This function renders the 'Unsaved changes' dialog's buttons
   * @returns dialog's buttons
   */
  const unsavedChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonYes}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonNo}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeUnsavedChangesDialog}
        />
      </>
    );
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<p id="loading">Loading...</p>}>
        <Modal
          id={"supply-chain-unsaved-changes-dialog"}
          openDialog={isCheckUnsavedChangesDialogOpen}
          closeClick={closeUnsavedChangesDialog}
          bodyContent={() => <span className="fs-16">{lang.dashboards.messages.want_to_save_first}</span>}
          dialogActions={unsavedChangesDialogActions}
          size={DIALOG_SIZE.MEDIUM}
        />
        <div id={"main-component-container"} className={"main-component-container "} style={{ "--banner-height": 0 }}>
          <Loader newLoader />
          <div className={"header-banner-div-hidden"} />
          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            <div id="main_report_container" className="main_report_container supply-chain-setup">
              <div className="tab-body">{renderTabBody()}</div>
              <div className="save-btn-div">
                <Button
                  label={lang.modal.buttons.save}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={saveConfiguration}
                />
              </div>
            </div>
          </div>
          <Toast ref={toastRef} status={toastStatus} message={toastMessage} />
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export { SupplyChainWrapper };
