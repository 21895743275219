import {useEffect, useState, useImperativeHandle, forwardRef } from "react";

const Toast = forwardRef((props,ref) => {

    useImperativeHandle(ref, () => ({
        displayToast: () => {
            displayToast();
        }
    }));

    const displayToast = () => {
        $("#toast").addClass("show");
        setTimeout(function(){
            $("#toast").removeClass("show");
        }, 4000);
    }

    return (
        <div id="toast" className={props.status ? "toast toast-success" : "toast toast-fail"}>
            <div id="desc">
                <i className={"fa-lg fas uk-margin-small-right " + (props.status ? "fa-check-circle greenText" : "fa-minus-circle uk-text-primary")} aria-hidden="true"></i>
                <span>{props.message}</span>
            </div>
        </div>
    );
})
export default Toast;