import React, {useReducer, useRef, useEffect, useState} from "react";
import { auth } from "../../firebase/firebase.js";
import { useParams } from 'react-router-dom';
import PhoneNumberAdd from "./PhoneNumberAdd";
import VerifyCode from "./VerifyCode";
import "../../styles/login.css";
import LogoPI from "../../LogoPI";
import PasswordAndConfirmPasswordValidation from "./PasswordAndConfirmPasswordValidation";
import { MFA_REPORTS, API_URL, BUTTON_VARIANT, SIZES, BUTTON_TYPE } from "../../class/constants";

import { lang } from "../../language/messages_en";
import Popup from 'react-popup';
import "../../styles/popup.css";
import { termsRegister } from "../../files/terms-and-conditions.mjs";
import Button from "../../newComponents/Button.js";
import {onEnterClick} from "../../class/utils";

const baseUrl = process.env.REACT_APP_BASE_URL;
const SetPassword = (props) => {

  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {phone:"", code: "", verified:false, user_email: props.email || "",is_mfa : true,report: props.email? MFA_REPORTS.PHONE_NUMBER : MFA_REPORTS.SET_PASSWORD,password:props.password || ""}
  )
  const { hash } = useParams();
  const passwordRef = useRef({})
  const [showTerms, setShowTerms] = useState(false);
 
  useEffect(() => {
    if (sessionStorage.getItem("showToast")) {
      launchToast(true);
      sessionStorage.removeItem("showToast");
    }
    $(window).on('beforeunload', function () {
      auth.signOut();
      returnToLogin();
    });
    if(props.email){
      return;
    }
    let query = {
      action:"getUserDetails",
      hash:hash
    }
    fetch(`${baseUrl}${API_URL.USER}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then(response => response.json())
          .then((data)=>{
            if(data.user_details){
              let user_details = data.user_details;
              let is_mfa = data.is_mfa;
              let email = user_details.email;
              let uid = user_details.id;
              let first_name = user_details.first_name;
              let last_name = user_details.last_name;
              let verification_link_type = user_details.verification_link_type;
              let phone_number = user_details.mobile_number;
              let hash_url = user_details.hash_url;
              let is_accessible = user_details.is_accessible;
              setState({
                user_email:email,
                first_name:first_name,
                last_name:last_name,
                uid:uid,
                is_mfa:is_mfa,
                verification_link_type:verification_link_type,
                phone_number :phone_number,
                hash_url:hash_url,
                is_accessible:is_accessible
              })
              if (!verification_link_type || verification_link_type === 'setPassword'){
                setShowTerms(true);
              }
            }else if(!props.email) {
              setState({
                user_email:undefined
              })
            }
          });
          window.addEventListener("keydown",onEnterEvent);
  }, []);

  const updateAccess = (callback) => {
    $(window).off('beforeunload');
    let query = {
      action:"updateAccess",
      hash:state.hash_url,
      access:!state.is_accessible,
      isReset:state.verification_link_type === MFA_REPORTS.RESET_PASSWORD
    }
    fetch(`${baseUrl}${API_URL.USER}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then(response => response.json())
          .then((data)=>{
            if(typeof callback ==="function" && !props.firstSignin){
              callback();
            }
          });
  }

  const updateUser = (pwd, ismfa, fromVerify) => {
    let email = state.user_email;
    let phone = state.phone;
    let password = pwd && pwd !== null ? pwd : state.password;
    let query = {
      action:"updateUser",
      email:email,
      user_password:password,
      mobile_number:phone
    }
    fetch(`${baseUrl}${API_URL.USER}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then(response => response.json())
          .then((data)=>{
            if(!data.success && props.unEnrollUser){
              props.unEnrollUser();
            }
            if (ismfa && !fromVerify && state.verification_link_type !== MFA_REPORTS.RESET_PASSWORD){
              setState({report:MFA_REPORTS.PHONE_NUMBER, password:passwordRef.current.getPasswordInput()});
            } else {
              updateAccess(returnToLogin);
              if(state.verification_link_type === MFA_REPORTS.RESET_PASSWORD){
                setState({message:"Password changed successfully",isError:false})
                launchToast();
              }
            }
          });
  }

  const onNextClick = (e, type) => {
      if(type === MFA_REPORTS.SET_PASSWORD ) {
        if(!comparePassword()){
          updateUser(passwordRef.current.getPasswordInput(), state.is_mfa, false);
        }
      } else if(type === MFA_REPORTS.PHONE_NUMBER){
        setState({report:MFA_REPORTS.VERIFY_CODE});
      } else if(type === MFA_REPORTS.VERIFY_CODE){
        if(props.email){// from login.js
          props.setVerified(true);
        }
        updateUser(state.password, state.is_mfa, true);
      } 
  }

  const returnToLogin = () =>{
    let url = window.location.href;
    let url_array = url.split("//");
    let protocol = url_array[0];
    let domainUrl = url_array[1].split("/")[0];
    let fullUrl = protocol + "//" + domainUrl;
    window.location.replace(fullUrl);
  }

  const onBackClick = (e, type) => {
    if(type === MFA_REPORTS.VERIFY_CODE){
      setState({report:MFA_REPORTS.PHONE_NUMBER});
    } 
  }

  const setCode = (e) => {
    setState({code:e})
  }

  const setPhone = (e) => {
    setState({phone:e})
  }

  const launchToast=(keepToast)=> {
    let className = keepToast ? "keep" : "show";
    $("#toastResetPassword").addClass(className);
    if (!keepToast) {
      setTimeout(function(){
        $("#toastResetPassword").removeClass("show");
    }, 4000);
  }
}

  const onEnterEvent = (event) => {
    // If the user presses the "Enter" key on the keyboard
    onEnterClick(event, "terms-btn")
  }

  const comparePassword = () =>{
    if(window.history && window.history.state && window.history.state.comparePassUnified){
      return window.history.state && window.history.state.savedPass === $("#expiryPassword").val().trim();
    }else{
      return false;
    }
  }

  return (
    <div className="main-body">
      <div id="toastResetPassword" className={"toast "+ (!state.message ? "toast-info " :" toast-success")}>
        <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (state.isError ? "fa-minus-circle uk-text-primary" : state.message ? "fa-check-circle greenText" : "fal fa-info-circle text-blue")} aria-hidden="true"></i>{state.message || lang.manage_access.password_expired}</div>
      </div>
      {!state.is_accessible && state.verification_link_type === MFA_REPORTS.RESET_PASSWORD?<h1 className="uk-margin-large-top uk-margin-large-left">{lang.mfa.reset_password_link_expired}</h1> :
      state.user_email === undefined  ?<h1 className="uk-margin-large-top uk-margin-large-left">{lang.mfa.link_expired}</h1> :
      state.user_email === "" ? "":
      showTerms?
      <div>
        <main className="termsContainer">
          <header className="login-header">
          <LogoPI />
          </header>
          <section className="container login-container">
            <div className="terms-form mrgt5 mrgb10 terms-margin">
            <div dangerouslySetInnerHTML={{__html: termsRegister}}/>
            <div className="terms-button-container">
            <Button 
              id="terms-btn"
              label={lang.navigation.btns.next}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setShowTerms(false)}
            />
            </div>
            </div>
          </section>
        </main>
      </div>
        :
      <div>
        <header className="login-header">
          <LogoPI />
          <Popup />
        </header>
        {state.report === MFA_REPORTS.SET_PASSWORD && <PasswordAndConfirmPasswordValidation ref={passwordRef} samePasswordAsPrevious={comparePassword} onNextClick = {onNextClick} email={state.user_email} is_mfa={state.is_mfa} verification_link_type={state.verification_link_type}/>}
        {state.report === MFA_REPORTS.PHONE_NUMBER  && <PhoneNumberAdd isUnified={props.isUnified} onNextClick = {onNextClick} codeChange={setCode} setPhone={setPhone} phone={state.phone}  email={state.user_email} password={state.password}/>}
        {state.report === MFA_REPORTS.VERIFY_CODE  && <VerifyCode onNextClick = {onNextClick} verificationId={state.code} phone={state.phone} setPhone={setPhone} email={state.user_email} onBackClick={onBackClick} verification_link_type={state.verification_link_type} firstSignin = {props.firstSignin} goToMultiClients={props.goToMultiClients}/>}
      </div>
      }
     
    </div>
  );
}

export default SetPassword;
