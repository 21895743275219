import { useEffect, useRef, useState } from "react";
import { ALL_WIDGETS, BUTTON_TYPE, SIZES, BUTTON_VARIANT, DIALOG_SIZE, API_URL, CONFIGURE_GL_ACCOUNT_RANGES  } from "../../class/constants";
import { getTranslationFile } from "../../class/utils.js";
import ConfigureGlAccountRangesTable from "./ConfigureGlAccountRangesTable";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI} from "../../class/networkUtils";
import Button from "../../newComponents/Button";
import Modal from '../../newComponents/Modal';
import ConfigureGlAccountRangesModal from "./ConfigureGlAccountRangesModal";
import Toast from "../../newComponents/Toast";
import NewBanner from "../NewBanner";
import { ReactComponent as WarningIcon } from "../../styles/images/circle-exclamation.svg";
import ConfigureGlAccountsExport from "./ConfigureGlAccountsExport";
const lang = getTranslationFile();
const ConfigureGlAccountRanges = (props) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [rangesTableData, setRangesTableData] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toastStatus, setToastStatus] = useState(null);
    const [dataForExport, setDataForExport] = useState(null);
    const [toastMessage, setToastmessage] = useState("");
    const [error, setError] = useState(null);
    const [exclusionFilter,setExclusionFilter] = useState("");
    const [filtersRef,setFiltersRef] = useState("[]");
    const configureRangesRef = useRef(null);
    const glAccountsExportRef = useRef(null);
    const toastRef = useRef(null);
    const [showBanner, setShowBanner] = useState(null);


    const Style={width: "130px"}

    const setRangesDialogOpen = (open) => {
        setOpenDialog(open);
        setError(false);
    }

    const prepareSelectedRow = (row) => {
        setSelectedRow(row);
    }

    const setShowError = (isError) => {
        setError(isError);
    }

    const setFilter = (filters) => {
        setFiltersRef(filters)
    }

    const saveRanges = (filterObjects) => {
        setRangesTableData(configureRangesRef?.current?.getRanges());
        setRangesDialogOpen(false);
        setExclusionFilter(filterObjects)
    }

    useEffect(() => {
        fetchGLAccountConfiguration();
        allGLAccountNumbersAreDefined();
    }, []);

    const showToast = (status) =>{
        let message = status ? "Configuration Saved" : "Error Occured";
        setToastStatus(status);
        setToastmessage(message);
        toastRef?.current?.displayToast();
        allGLAccountNumbersAreDefined();
    }
    
    const fetchGLAccountConfiguration = () => {
        var query = {
          action: "fetchGLAccountConfiguration",
        };

        let onThenCallback = (data) => {
            if (data) {
                setRangesTableData(data.accountRanges);
                setExclusionFilter(data.accountExcludedParameters);
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchGLAccountConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.GL_ACCOUNT_CONFIGURATION,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        };
        
        fetchAPI(fetchOptions);
      };

      /**
       * takes data of ConfigureGlAccountRangesTable and inserts it to DB 
       */
      const saveGLAccountConfiguration = () => {
        let exclusionFilterJSON  = JSON.parse(exclusionFilter);
        exclusionFilterJSON.forEach(item=>{
            delete item.value_options;
            delete item.column_options;
            item.parenthesis_before = "";
            item.parenthesis_after = "";
            item.operator = item.function;
            if(item?.values?.length && typeof item?.values !== "string"){
                delete item.value;
            }
        })
        let exclusionFilterStr = JSON.stringify(exclusionFilterJSON,null,0);
        var query = {
          action: "saveGLAccountConfiguration",
          glAccountConfiguration: rangesTableData,
          glExcludedParameter:exclusionFilterStr
        };
        let onThenCallback = (data) => {
            if (data?.inserted){
                showToast(true);
            } else {
                showToast(false);
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveGLAccountConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.GL_ACCOUNT_CONFIGURATION,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        };
        
        fetchAPI(fetchOptions);
      };

      /**
       * Validates if all account in GL table are covered in ranges configuration. 
       */
      const allGLAccountNumbersAreDefined = () => {
        var query = {
          action: "allGLAccountNumbersAreDefined"
        };
        let onThenCallback = (data) => {
            // Show the banner in case NOT all the GL Account numbers are defined
            setShowBanner(!data?.allGLAccountNumbersAreDefined);
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "allGLAccountNumbersAreDefined",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.GL_ACCOUNT_CONFIGURATION,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        };
        
        fetchAPI(fetchOptions);
      };

    const getUndefinedGLAccountNumbers = () => {
        var query = {
            action: "getUndefinedGLAccountNumbers"
        };
        let onThenCallback = (data) => {
            setDataForExport(data?.undefinedGLAccountsNumber);
            glAccountsExportRef?.current?.exportTableToExcel();
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getUndefinedGLAccountNumbers",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.GL_ACCOUNT_CONFIGURATION,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        };

        fetchAPI(fetchOptions);
    };

      
    const renderBannerBody = () => {
        return(
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width:"100%"}}>
                <div>
                    <WarningIcon 
                        className="uk-margin-small-right"
                    />
                    <span className="warning-banner-body no-cursor">{lang.configure_account_ranges.wrong_configuration}</span>
                </div>
                
                <Button
                    label={lang.configure_account_ranges.export}
                    variant={"transparent"}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className={"fs-12"}
                    onBtnClick={()=> getUndefinedGLAccountNumbers()}
                /> 
            </div>
        );
    }

    return (
        <div style={{width: "35vw"}}>
            <div style={{width: "100%"}}>
            {/*<div className={"background-rect"}></div>*/}
            <div className="" style={{alignItems: "center"}}>
                <div>
                <div className="h1-text small-padding-bottom medium-margin-bottom">{ALL_WIDGETS.TITLES.CONFIGURE_GL_ACCOUNT_RANGES}</div>
                <ConfigureGlAccountRangesTable data={rangesTableData} openDialog={setRangesDialogOpen} prepareSelectedRow={prepareSelectedRow} /> {/*table where account categories and has configure button*/}
                {dataForExport ? 
                <ConfigureGlAccountsExport data={dataForExport}  ref={glAccountsExportRef}/> /*table to only export gl accounts that are not configured*/ 
                : ""}
                {showBanner ?
                    <NewBanner
                        bannerClassName={"banner-error large-margin-bottom banner-body-configure fs-12"}
                        body={renderBannerBody()}
                    /> : "" }
                 {/*Save button for whole screen*/}
                <Button
                    label={lang.modal.buttons.save}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.LARGE}
                    type={BUTTON_TYPE.DEFAULT}
                    className={"large-margin-bottom"}
                    onBtnClick={saveGLAccountConfiguration}
                /> 
                 {/*Modal that opens when configure is pressed*/}
            <Modal
                id={"header-info-dialog"}
                openDialog={openDialog}
                bodyContent={() => <ConfigureGlAccountRangesModal ref={configureRangesRef} data={rangesTableData} exclusionFilter = {exclusionFilter} selectedRow={selectedRow} saveRanges={saveRanges} setShowError={setShowError} glSubtypes={props.glSubtypes} allActualGLColumns={props.allActualGLColumns} setFilter={setFilter} filtersRef = {filtersRef}/>}
                dialogActions={() => (
                    <div style={{display:"flex"}}>
                        <Button 
                            label={selectedRow == CONFIGURE_GL_ACCOUNT_RANGES.KEYS.EXCLUSIONS ? CONFIGURE_GL_ACCOUNT_RANGES.MODAL.BUTTON.EXCLUDE : CONFIGURE_GL_ACCOUNT_RANGES.MODAL.BUTTON.ADD_ACCOUNT}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT} 
                            onBtnClick={() => configureRangesRef?.current?.validateAccounts(selectedRow === CONFIGURE_GL_ACCOUNT_RANGES.KEYS.EXCLUSIONS)}
                            />
                        <Button  
                            label={CONFIGURE_GL_ACCOUNT_RANGES.MODAL.BUTTON.CANCEL}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-margin-small-left"}
                            onBtnClick={() => setRangesDialogOpen(false)}
                            />
                        {error ? <div className='red uk-display-block uk-margin-left'><i className='fal fa-times-circle uk-margin-xsmall-right'></i>{lang.configure_account_ranges.wrong_input}</div> : ""}

                    </div>
                    )}
                closeClick={() => setRangesDialogOpen(false)} 
                size={DIALOG_SIZE.LARGE}
                />
            </div>
            </div>
        </div>
        <Toast
            ref={toastRef}
            status={toastStatus}
            message={toastMessage}
        />
        </div>
    );
};

export default ConfigureGlAccountRanges;
