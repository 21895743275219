import React, { Component } from 'react';
import Popup from 'react-popup';
// import {formatValString} from '../../class/Formatting';
import ReactTable from "react-table";
import shortid from 'shortid';
import Tabulator from "tabulator-tables"; //import Tabulator library
import UIkit from 'uikit';
// import { api } from '../../../package.json';
import { findIndexOfValue, getEmbeddedChild, checkLineUnderParent, checkLineExpanded} from '../../class/array';
import { cleanUpSingleTabulatorColumn, cleanUpTabulatorColumns, setDraggable ,toggleLoader} from '../../class/common';
import { ACCOUNT_AMOUNT_FIELDS, ACCOUNT_AMOUNT_TITLES, ACCRUALS, ALL_WIDGETS, API_URL, CALCULATED_COLUMNS, costtype, FormatTypes, FY_VALUES, GLACCOUNTS_FIELDS, HEADER_ELEMENT, METRICS_MAPPING, PSL_RETURN_NAMES, PSS, PS_MAPPING, ROW_STATUS, STAGING_SECTIONS, Type, VECTOR_MAPPING, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE } from '../../class/constants';
import { formatValHTML, formatValReact, formatValString } from '../../class/format';
import { convertPxToViewport } from '../../class/formatting';
import { extractCostKeys, toggleEnableHeaderNext, toggleHeaderBack } from '../../class/jqueries';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from "../../class/networkUtils";
import { is_aN } from '../../class/number';
import { extractNumber } from '../../class/string';
import { capitaliseFirstLetterAfterChar, capitalizeFirstLetter, copyObjectValues, deepCompareObjects, findOptionByKeyValue, getAttributeFromCostKey, getChildCostKeys, getTranslationFile, getTreeLeaves, parseBoolean, retrieveRowByAttr, tryParse } from '../../class/utils';
import { lang } from '../../language/messages_en';
import { getProfitStackLines } from './CommonRequests';
import {linearizeHierarchy} from '../../class/array';
import Button from '../../newComponents/Button';
import { getExpandCollapseButtons, getTableButton, getTableIcon, getTableIconButton } from '../../newComponents/tabulatorComponents';
import Modal from '../../newComponents/Modal';
/**
 *  PSLTable is a component in the PSLSection, it contains the profit stack line tabulator.
 * @author [Mostafa Haydar]
 * @extends Component
 * **/

// 
// const baseUrl = process.env.REACT_APP_BASE_URL;

const $ = require('jquery');
const cost_center = "Cost Center";
const _driverType = "driver_type";
const _metric = "metric";
const _ancillary = "ancillary";
const MESSAGES = getTranslationFile();

const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _leadingID = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _file = PS_MAPPING.EXCEPTION_FIELDS.FILE;
const _name = PS_MAPPING.FIELDS.NAME;
const _actualId = PS_MAPPING.FIELDS.ACTUAL_ID;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _mapFormula = PS_MAPPING.FIELDS.MAP_FORMULA;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _parentCostKey = PS_MAPPING.FIELDS.PARENT_COST_KEY;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const _deleted = PS_MAPPING.FIELDS.DELETED;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _costCenter = PS_MAPPING.FIELDS.COST_CENTER;
const _rawFileFieldName = PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME;
const GR = "Gross Revenue";
const COGS = "COGS";
const NR = "Net Revenue";
const CostOfSales = "Cost of Sales";
const _leadingCostKey = PS_MAPPING.FIELDS.LEADING_COSTKEY;
const _combinations = PS_MAPPING.FIELDS.FIELDS_COMBINATIONS;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _attribute = PS_MAPPING.FIELDS.ATTRIBUTE;
const _attributeFunction = PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION;
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _attributeType = PS_MAPPING.FIELDS.ATTR_TYPE;
const _inProfitMap = PS_MAPPING.FIELDS.IN_PROFIT_MAP;
const _excludeFromPs = PS_MAPPING.FIELDS.EXCLUDE_FROM_PS;
const _includeInPMTotals = PS_MAPPING.FIELDS.INCLUDE_IN_PM_TOTALS;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _matchedSuffix = MESSAGES.pss_map_exception.suffixes.matched;
const _variance = MESSAGES.pss_map_exception.suffixes.variance;
const NONE = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
const TRANSACTION = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
const _netrevenue = "netrevenue";
const _revenue = "revenue";
const _costofsales = "costofsales";
const _netprofit = "netprofit";
const _netprofitperc = "netprofitperc";
const _grossprofit = "grossprofit";
const _grossprofitperc = "grossprofitperc";
const _perc="perc";
const _orders="orders";

var initialPeriodTotal = {}
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.EXCLUDED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.ASSIGNED_AMOUNT] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.EXCLUDED_AMOUNT] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_AMOUNT] = 0;

const ACTION_TYPE = {
    SHOW_HIDE: "show/hide",
    DELETE: "delete",
    MAP: "map"
}
let globaltop = 0;
class PSLTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            data: "",//this.props.data
            mappedLines: [],
            toBeDeleted: [],
            pslLine: {},
            columns: "",
            isInfo: false,
            flipChecked: true
        };
        this.isLoading = false;
        this.previousCostKey = -1;
        this.count = 1;
        this.rowToBeMoved = "";
        this.expandedRowsCostkeys = [];
        this.reExpand = true;
        this.fetchAPI = fetchAPI.bind(this);
        this.hasUnmappedChildren = this.hasUnmappedChildren.bind(this);
        this.constructMenuDropDown = this.constructMenuDropDown.bind(this);
        this.onAddParentRow = this.onAddParentRow.bind(this);
        this.customTreeHeaderFilter = this.customTreeHeaderFilter.bind(this);
        this.filterTableOnLinks = this.filterTableOnLinks.bind(this);
        this.customTreeFilter = this.customTreeFilter.bind(this);
        this.showScenarioPopUp = this.showScenarioPopUp.bind(this);
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }

    /**
     *  copied from profitStackMapping
     * @returns
     */
    constructMenuDropDown = () => {
        var options = [];
        var stdobj = {};
        stdobj.displayName = "Standard Line"
        stdobj.id = "standard-line"
        options.push(stdobj);

        var calObj = {};
        calObj.displayName = "Calculated Line";
        calObj.id = "calculated-line";
        options.push(calObj);

        var attObj = {};
        attObj.displayName = "Attribute Line"
        attObj.id = "attribute-line"
        options.push(attObj);
        return options;
    }

    /**
     *  copied from profitStackMapping
     * @param {*} cell
     * @param {*} tooltipMessage
     * @returns
     */
    addTooltipTitleFormatter = (cell, tooltipMessage) => {
        var div = document.createElement("div");

        var span = document.createElement("span");
        span.classList.add("wrapped-title");
        span.innerHTML = typeof cell === "string" ? cell : cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        div.appendChild(span);
        div.appendChild(el);

        return div;
    }

    /**
     *  copied from profitStackMapping
     * @param {*} cell
     * @returns
     */
    formatTitle = (cell) => {
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = typeof cell === "string" ? cell : cell.getValue().replace("CostCenter", capitaliseFirstLetterAfterChar(this.props.costCenter));

        div.appendChild(p);
        return div;
    }

    customTreeHeaderFilter(searchVal, rowValue, rowData, filterParams) {
        if (rowValue.toLowerCase().replaceAll(" ", "").indexOf(searchVal.val.toLowerCase().replaceAll(" ", "")) >= 0) {
            return true;
        }
        if (rowData[filterParams.childrenKey] && rowData[filterParams.childrenKey].length > 0) {
            for (let index in rowData[filterParams.childrenKey]) {
                //let parent = rowData[filterParams.fieldKey][index];
                let child = rowData[filterParams.childrenKey][index];
                if (child[filterParams.fieldKey].toLowerCase().replaceAll(" ", "").indexOf(searchVal.val.toLowerCase().replaceAll(" ", "")) >= 0) {
                    return true;
                }
                if (child[filterParams.childrenKey] && child[filterParams.childrenKey].length > 0) {
                    let found = this.customTreeHeaderFilter(searchVal, rowValue, child, filterParams);
                    if (found) {
                        return true;
                    }
                }
            }
        }
        return false;
    }


    /**
     *  copied from profitStackMapping
     * @returns
     */

    generateSplitTypeToolTip() {
        let message = "<div><p>";
        message += lang.ps_mapping_split_type_one + "</p></br>";
        message += "<ul>";
        let abbr = lang.ps_mapping_split_type_two.map(abr => "<li>" + abr + "</li>");
        for (let e in abbr) {
            message += abbr[e];
        }
        message += "</ul></div>"
        return message
    }

    /**
     *  copied from profitStackMapping
     * @param {*} columns
     * @returns
     */
    getTabulatorColumns(columns) {
        var obj = this;
        columns = copyObjectValues(columns) || [];
        let costCenterMessage = MESSAGES.profit_stack_map_cc_title.replace(new RegExp("\\[Cost Center]", "g"), obj.props.costCenter);
        columns = cleanUpTabulatorColumns(columns, null, this.refreshFilterDivs, this.tabulator, {id: "PSLTable"})
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);

            if (col.field === PS_MAPPING.FIELDS.ADD) {
                col.headerFilter = "menuDropDown";
                col.minWidth = convertPxToViewport(33);
                col.width = convertPxToViewport(33);
                var options = {};
                options.fields = obj.constructMenuDropDown();
                options.id = "add-row-top-icon";
                col = cleanUpSingleTabulatorColumn(col, options);
                if (!obj.state.checked_combinations || obj.state.checked_combinations.length === 0) {
                    col.visible = true;
                } else {
                    col.visible = false;
                }
                col.formatter = this.getColumnFormatter(col.field);
            }

            if (col.field === PS_MAPPING.FIELDS.HANDLE_ROW) {
                col.cssClass = "psl-handle-row min-width-20 width-20";
            }

            if (col.field === "setup") {
                col.cssClass = "psl-actions-cell";
            }

            if (col.field === PS_MAPPING.FIELDS.NAME) {
                col.cssClass = "psl-name-cell";
                col.widthGrow = 5
                col.headerFilterFunc = this.customTreeHeaderFilter;
                col.headerFilterFuncParams = {childrenKey: _children, fieldKey: _name};
            }

            if (col.field === PS_MAPPING.FIELDS.EXPAND) {
                col.className = "psl-expand-cell";
            }

            if (col.field === _costCenter) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(obj.props.costCenter + " Based", "title: " + costCenterMessage);
                col.cssClass = "psl-cost-center-cell";
                col.minWidth = convertPxToViewport(95);
                col.width = convertPxToViewport(95);
            }

            if (col.field === _mappingException) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(lang.split_type_label, "title: " + this.generateSplitTypeToolTip());
                col.cssClass = "psl-split-type-cell";
                col.minWidth = convertPxToViewport(70);
                col.width = convertPxToViewport(70);
            }

            if (col.field === METRICS_MAPPING.FIELDS.COST_CENTER) {
                col.titleFormatter = (cell) => obj.formatTitle(cell);
            }

            if (col.field === PS_MAPPING.FIELDS.AMOUNT) {
                col.frozen = true;
                col.minWidth = convertPxToViewport(130)
            }
        });
        return columns;
    }

    /**
     *  copied from profitStackMapping
     * @param {*} data
     * @param {*} costKey
     * @returns
     */

    isGrandChild(data, costKey) {
        if (data && data !== null && data[_children]) {
            return this.isSubChild(data[_children], costKey) === 1 ? true : false;
        }
        return false;
    }

    /**
     *  copied from profitStackMapping
     * @param {*} data
     * @param {*} costKey
     * @returns
     */
    isSubChild(data, costKey) {
        for (let e in data) {
            if (data[e][_costKey] === costKey) {
                return 1;
            } else {
                if (data[e][_children]) {
                    if (this.isSubChild(data[e][_children], costKey) !== 1) {
                       continue;
                    } else {
                        return 1;
                    }
                }
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} rowData
     * @param {*} ck
     * @returns
     */
    getParentAmount(rowData, ck) {
        let _this = this;
        let parentAmount = 0;
        this.state.mappedLines.map((line) => {
            if (line[_costKey].toString().replace("Insert", "") === ck.replace("Insert", "") && line[_deleted] === 'false') {
                parentAmount = is_aN(line[PS_MAPPING.FIELDS.AMOUNT]) ? parseFloat(line[PS_MAPPING.FIELDS.AMOUNT]) : 0;
            } else if (line[_parentCostKey] === ck || _this.isGrandChild(rowData, line[_costKey])) {
                parentAmount += is_aN(line[PS_MAPPING.FIELDS.AMOUNT]) ? parseFloat(line[PS_MAPPING.FIELDS.AMOUNT]) : 0;
            }
        });

        return parentAmount;
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} row
     * @param {*} message
     */
    updateMessage(data, row, message) {
        for (let e in data) {
            if (data[e][PS_MAPPING.FIELDS.ACTUAL_ID] === row[PS_MAPPING.FIELDS.PSS_ID]) {
                message.push("This change affects these profit stack lines: " + data[e][PS_MAPPING.FIELDS.NAME].replace('Accrual', 'Actual') + " and " + data[e][PS_MAPPING.FIELDS.NAME]);
            } else if (data[e].children && data[e].children.length > 0) {
                this.updateMessage(data[e].children, row, message);
            }
        }

    }

    /**
     * copied from profitStackMapping
     * @param {*} cell
     */
    changeSwitch(cell,value) {
        var obj = this;
        var found = false;
        var tempData = obj.tabulator.getData();
        var message = [];
        this.updateMessage(tempData, cell.getRow().getData(), message)
        if (message.length > 0) {
            this.setWarningDialogOpen(true, message[0]);
        }
        obj.state.mappedLines.map(function (item) {
            if (item[_costKey] === cell.getRow().getData()[_costKey]) {
                item.costCenter = item.costCenter !== 'ALL' ? 'ALL' : obj.props.costCenter;
                tempData = obj.updateChildAttr(tempData, item[_costKey], [_costCenter], item.costCenter);
                found = true;
            }
            if (item[PS_MAPPING.FIELDS.ACTUAL_ID] === cell.getRow().getData()[_id]) {
                item.costCenter = item.costCenter !== 'ALL' ? 'ALL' : obj.props.costCenter;
                tempData = obj.updateChildAttr(tempData, item[_costKey], [_costCenter], item.costCenter);
            }
        });
        if (!found) {
            var newRow = {
                [_id]: cell.getRow().getData()[_id],
                [_leadingID]: cell.getRow().getData()[_id],
                [_leadingCostKey]: cell.getRow().getData()[_costKey],  //if not previously mapped, set leading id and costkey as its own
                [_name]: cell.getRow().getData()[_name],
                [_costKey]: cell.getRow().getData()[_costKey],
                [_costType]: cell.getRow().getData()[_costType],
                [_parentCostKey]: cell.getRow().getData()[_parentCostKey],
                [PS_MAPPING.FIELDS.FILTER]: "",
                [_combinations]: [],
                stagingQuery: "",
                [_mappingException]: NONE,
                fileName: "",
                [_costCenter]: obj.props.costCenter,
                [_deleted]: "false",
                [_returnName]: cell.getRow().getData()[_returnName],
                [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.NEW
            };
            obj.state.mappedLines.push(newRow);
            tempData = obj.updateChildAttr(tempData, newRow[_costKey], [_costCenter], newRow.costCenter);
        }
        obj.props.setNotSavedWarning(true, true);
        // let rowIndex = cell.getRow().getData()[_costKey];
        // let child = getEmbeddedChild(tempData,"children", _costKey, rowIndex);
        // obj.tabulator.updateRow(child[_costKey], child);
        obj.tabulator.replaceData(tempData);
        if(value === FY_VALUES.OFF && obj.props.fetchAmounts){ //parseAmount when you toggle on branch based
            obj.parseAmount();
        }
        obj.forceUpdate();

    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} costkey
     * @param {*} attr
     * @param {*} value
     * @returns
     */
    updateChildAttr(data, costkey, attr, value) {

        for (let row in data) {
            row = data[row];

            if (row[_costKey] === costkey) {
                row[attr[0]] = value.toString();
            } else if (row[_children]) {
                row[_children] = this.updateChildAttr(row[_children], costkey, attr, value);
            }
        }

        return data;
    }


    /**
     * copied from profitStackMapping
     * @param {*} mappedLines
     * @param {*} rowData
     * @returns
     */
    getSplitType(mappedLines, rowData) {
        if ([costtype.attribute, costtype.accrued, costtype.calculated].includes(rowData[_costType])) {
            return "";
        }
        let filteredMappedLine = mappedLines.filter(e => e.costKey === rowData[_costKey]);
        if (filteredMappedLine.length > 0) {
            let filteredMappedLineObj = filteredMappedLine[0];
            let link = mappedLines.filter(e => e[_leadingID] === filteredMappedLineObj[_leadingID] && ['true', true].includes(filteredMappedLineObj[_isMatched]));
            let leadingIdObj = mappedLines.filter(e => e[_id] === filteredMappedLineObj[_leadingID])[0];
            let rawFileFieldsInLinks = link.map((e) => e[_rawFileFieldName]).filter(e => e !== "");

            if (![TRANSACTION,NONE].includes(filteredMappedLineObj[_mappingException]) && (filteredMappedLineObj[_driverType] === _ancillary || (filteredMappedLineObj[_driverType] === "" && leadingIdObj && leadingIdObj[_driverType] === _ancillary)) && !['false', false].includes(filteredMappedLineObj[_isMatched]) && (rawFileFieldsInLinks.length > 0 || (filteredMappedLineObj[_rawFileFieldName] && filteredMappedLineObj[_rawFileFieldName] !== "")) && ['0', '', '0.0','100','NaN'].includes(filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE])) {
                return 'A';
            } else if (![TRANSACTION,NONE].includes(filteredMappedLineObj[_mappingException]) && (filteredMappedLineObj[_driverType] === _metric || (filteredMappedLineObj[_driverType] === "" && leadingIdObj && leadingIdObj[_driverType] === _metric)) && !['false', false].includes(filteredMappedLineObj[_isMatched]) && (rawFileFieldsInLinks.length > 0 || (filteredMappedLineObj[_rawFileFieldName] && filteredMappedLineObj[_rawFileFieldName] !== "")) && ['0', '', '0.0','100','NaN'].includes(filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE])) {
                return 'M';
            } else if (filteredMappedLineObj[_mappingException] === NONE && filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE] && !['100.0', '100', '0', '', '0.0'].includes(filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE])) {
                return 'S%';
            } else if (![TRANSACTION, NONE].includes(filteredMappedLineObj[_mappingException]) && (filteredMappedLineObj[_driverType] === _ancillary || ((filteredMappedLineObj[_driverType] === "" || !filteredMappedLineObj[_driverType]) && leadingIdObj && leadingIdObj[_driverType] === _ancillary)) && !['false', false].includes(filteredMappedLineObj[_isMatched]) && link.length > 1 && !['100.0', '100', '0', '', '0.0'].includes(filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE])) {
                return 'A%';
            } else if (![TRANSACTION,NONE].includes(filteredMappedLineObj[_mappingException]) && (filteredMappedLineObj[_driverType] === _metric || ((filteredMappedLineObj[_driverType] === "" || !filteredMappedLineObj[_driverType]) && leadingIdObj && leadingIdObj[_driverType] === _metric)) && !['false', false].includes(filteredMappedLineObj[_isMatched]) && link.length > 1 && !['100.0', '100', '0', '', '0.0'].includes(filteredMappedLineObj[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE])) {
                return 'M%';
            } else if (filteredMappedLineObj[_mappingException] === TRANSACTION && !['false', false].includes(filteredMappedLineObj[_isMatched])) {
                return 'T';
            } else if (['false', false].includes(filteredMappedLineObj[_isMatched])) {
                return 'V';
            }
        }
        return "";
    }

    hasUnmappedChildren(rows) {
        var obj = this;
        var hasUnmapped = false;
        for (let index in rows) {
            let row = rows[index];

            if (row[_costType] === costtype.standard || row[_costType] === costtype.invoicelinetype || row[_costType] === costtype.calculated || row[_costType] === costtype.attribute) {
                if (row[_children]) {
                    hasUnmapped = this.hasUnmappedChildren(row[_children]);
                } else {
                    // if (obj.state.mappedLines.length > 0) {
                        if (row[_costType] === costtype.calculated || row[_costType] === costtype.attribute){
                            let formulaInFact = row[PS_MAPPING.FIELDS.FORMULA_IN_FACT];
                            let mappedLines = obj.state.mappedLines;
                            let mappedLine = mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === row[PS_MAPPING.FIELDS.COST_KEY]);
                            let formula = mappedLine.length > 0 ? copyObjectValues(mappedLine[0][PS_MAPPING.FIELDS.MAP_FORMULA]) : copyObjectValues(row[PS_MAPPING.FIELDS.MAP_FORMULA]);
                           if( (formula && formula.length === 0 ) ){
                            // if( !(formula || formulaInFact)){
                                hasUnmapped = true;
                            }
                            if(formulaInFact && formulaInFact !== null || row.acType === "ATTRIBUTE" || row.attribute !== ""){ //for default calculated and default attribute
                                hasUnmapped = false; 
                            }
                        }else{
                            let line = obj.state.mappedLines.filter(line => line[_costKey] === row[_costKey])[0];
                            let leadingLine = line ? obj.state.mappedLines.filter(line2 => line2[_costKey] === line[_leadingCostKey])[0] : undefined;
                            hasUnmapped = leadingLine === undefined || !leadingLine[_combinations] || leadingLine[_combinations].length === 0;
                            if(!leadingLine && line && line[_combinations] && line[_combinations].length > 0){
                                hasUnmapped = false;
                            }
                        }
                    // }
                }
                if (hasUnmapped) {
                    break;
                }
            }
        }
        return hasUnmapped;
    }

    filterTableOnLinks(rowData, isFilterOnName = false) {
        let obj = this;
        let tempState = {};
        let filtered = [];
        let prvFilters = obj.tabulator.getFilters();
        if (prvFilters.length > 0 || !rowData) {
            obj.tabulator.clearFilter();
            tempState.tabulatorFiltered = false;
        } 
        if(rowData !== undefined && (!this.state.tabulatorFiltered || isFilterOnName)) {
            if (isFilterOnName) {
                filtered = obj.state.mappedLines.filter(el => el[_name] === rowData);
            } else {
                filtered = obj.state.mappedLines.filter(el => el[_id] === rowData[_id]);
            }
            let mapping = filtered.length > 0 ? filtered[0] : undefined;
            let links = [];
            if (mapping) {
                links = obj.state.mappedLines.filter(el => mapping[_leadingID] && el[_leadingID] === mapping[_leadingID]
                    || el[_id] === mapping[_id] || el[_actualId] === mapping[_id] || el[_id] === mapping[_actualId]).map(a => a[_id]);
                    
                let currentLinks = prvFilters.length > 0 ? prvFilters[0].type.values!==undefined? prvFilters[0].type.values : []: [];
                if(links && currentLinks && links.length !== currentLinks.length || (currentLinks && !links.every(function(value, index) { return value === currentLinks[index]}))) {
                    obj.tabulator.setFilter(obj.customTreeFilter, {childrenKey: _children, fieldKey: _id, "values": links});
                    tempState.tabulatorFiltered = true;    
                }
            }
        }
        this.setState(tempState);
    }

    customTreeFilter(rowData, filterParams) {
        if (filterParams.values.indexOf(rowData[filterParams.fieldKey]) >= 0) {
            return true;
        }
        if (rowData[filterParams.childrenKey] && rowData[filterParams.childrenKey].length > 0) {
            for (let index in rowData[filterParams.childrenKey]) {
                //let parent = rowData[filterParams.fieldKey][index];
                let child = rowData[filterParams.childrenKey][index];
                if (filterParams.values.indexOf(child[filterParams.fieldKey]) >= 0) {
                    return true;
                }
                if (child[filterParams.childrenKey] && child[filterParams.childrenKey].length > 0) {
                    let found = this.customTreeFilter(child, filterParams);
                    if (found) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * function receives an array of formula splitted by a certain operator loops over them and switches the returnname from formula with its amount and returns the overall amount
     * @param {*} arr
     * @param {*} operator
     * @returns
     */
    getEvalStringAmount=(arr, operator)=> {
        let res = [];
        let _this = this;
        for (let e in arr) {
            if (arr[e].match(".*[a-zA-Z]+.*") !== null) {
             if (arr[e].includes("-")) {
                let splittedArr = arr[e].split("-");
                res.push(_this.getEvalStringAmount(splittedArr, "-"))
             }else if(arr[e].includes("/")) {
                let splittedArr = arr[e].split("/");
                res.push(_this.getEvalStringAmount(splittedArr, "/"))
             }else if(arr[e].includes("*")) {
                let splittedArr = arr[e].split("*");
                res.push(_this.getEvalStringAmount(splittedArr, "*"))
             }else{
                 let returnName = arr[e].replaceAll("(","").replaceAll(")","").replaceAll(" ","").replaceAll("/","");
                 if (returnName.includes("*")) {
                     let multiplier = returnName.split("*");
                        for (let elt in multiplier) {
                            if (is_aN(multiplier[elt])) {
                                returnName = returnName.replace(multiplier[elt],"")
                            }
                        }
                        returnName = returnName.replaceAll("*","");
                    }
                 let rowData =  retrieveRowByAttr([returnName], _this.tabulator.getData(), PS_MAPPING.FIELDS.RETURN_NAME);
                 let amount = rowData ? _this.getParentAmount(rowData, rowData[PS_MAPPING.FIELDS.COST_KEY]) : 0;
                 if (amount === 0) {
                     let calcAmount = _this.props.calculatedColsAmount ? _this.props.calculatedColsAmount.filter(e=>e.column_name === returnName) : [];
                    if(calcAmount.length >0) {
                        amount = calcAmount[0].total_amount;
                    }
                 }
                 res.push(arr[e].replace(returnName, amount ? formatValString(amount, FormatTypes.NUMERIC).replaceAll(",","") === "-" ? "0" : formatValString(amount, FormatTypes.NUMERIC).replaceAll(",","")  :"0"));
             }
            }else{
                res.push(arr[e]);
            }
        }
        return operator === "+" ? eval(res.join(operator)) : res.join(operator) ;
    }

    getParentsAmount=()=> {
        let data = this.tabulator.getData();
        let amount = 0;
        for (let e in data) {
            if (data[e][_returnName] !== _netrevenue && data[e][_costType] !== costtype.calculated) {
                amount += this.getParentAmount(data[e], data[e][_costKey]);
            }
        }
        return amount;
    }
    /**
     * function computes the formula of default lines grossprofit(perc), netprofit(perc)
     * @param {*} formula
     * @param {*} name
     * @param {*} formulaInFact
     * @returns
     */
    computeStringFormula=(formula, name, formulaInFact)=>{
        let _this = this;
        let formulaArr = [];
        formula = formula.replace(/SUM/g,'').replace(/sum/g,'').replace(/\(/g,'').replace(/\)/g,'');
        let revRowData =  retrieveRowByAttr([_netrevenue, _revenue], _this.tabulator.getData(), PS_MAPPING.FIELDS.RETURN_NAME);
        let revAmount = revRowData ? _this.getParentAmount(revRowData, revRowData[PS_MAPPING.FIELDS.COST_KEY]) : 0;

        let cosRowData =  retrieveRowByAttr([_costofsales], _this.tabulator.getData(), PS_MAPPING.FIELDS.RETURN_NAME);
        let cosAmount = cosRowData ? _this.getParentAmount(cosRowData, cosRowData[PS_MAPPING.FIELDS.COST_KEY]) : 0;

        let amount = eval(revAmount + "- (" + cosAmount + ")");
        if (formula === _grossprofit) { // formula of gross profit for all pss is rev - cos and its percentage is rev - cos / rev * 100
            return amount;
        } else if (name === _grossprofitperc){
            if (amount === 0) {
                return 0;
            } else {
                return eval("(("+revAmount + "- (" + cosAmount+"))/("+Math.abs(revAmount)+"))*100")
            }
        } else if (name === _netprofitperc) {
            let profitRowData =  retrieveRowByAttr([_netprofit], _this.tabulator.getData(), PS_MAPPING.FIELDS.RETURN_NAME);
            let profitAmount = profitRowData ? _this.getCalculatedAmount(profitRowData) : 0;
            if (revAmount === 0){
                return 0;
            } else {
                return eval("("+ profitAmount + "/" + revAmount+")*100");
            }
        } else if (name === _netprofit) {
            let otherParents = _this.getParentsAmount();
            return eval(("("+ revAmount + "- (" + otherParents+"))").replaceAll("--","+"));
        } else {
            if (formulaInFact.includes("CASE WHEN")) { // net profit is much more complicated than gross profit so i did it in a more dynamic way (works for perc and regular line)
                formulaInFact = formulaInFact.replace("CASE","").replace("WHEN","");
                formulaArr = formulaInFact.split("=");
                let firstCond = _this.getEvalStringAmount(formulaArr[0].replace("0","").split("+"),"+");
                let secondCond = _this.getEvalStringAmount(formulaArr[1].replaceAll("0","").replace("THEN","").replace("ELSE","").replace("END","").replace("*100","").split("+"),"+");
                if (firstCond === 0) {
                    return 0;
                } else {
                    if (name.includes(_perc)) {
                        return secondCond*100;
                    } else {
                        return secondCond;
                    }
                }
            }else{
                formulaArr = formulaInFact.split("+");
                return _this.getEvalStringAmount(formulaArr,"+")
            }
        }
    }
    /**
     * function fecthed the rowData of the costKey fro PSLTable and return the amount of it
     * @param {*} costKey
     * @returns
     */
    getAmountByCostKey=(costKey)=>{
        let _this = this;
        let rowData = retrieveRowByAttr([costKey], _this.tabulator.getData(), PS_MAPPING.FIELDS.COST_KEY);
        return rowData?([_netprofit,_netprofitperc,_grossprofit,_grossprofitperc].includes(rowData[_returnName]) || rowData[_costType] === costtype.calculated ? _this.getCalculatedAmount(rowData): _this.getParentAmount(rowData, costKey)):"";
    }

    /**
     * functions returns the amount based on the formula
     * @param {*} formula
     */
    computeFormula=(formula)=>{
        let _this = this;
		let finalFormula = "";
		let currentFormula = "";
		let preOperator = "";
		let isSubFormula = false;
        let finalFormulaArr = [];
        for (let i=0; i < formula.length; i++) {
            let element = formula[i];
			let preType = (formula[i-1]  ?formula[i-1] : "");
            let currType = element.type;
            if (currType === preType.type && preType !== "") {
                finalFormulaArr[finalFormulaArr.length-1].value =  finalFormulaArr[finalFormulaArr.length-1].value + element.value;
            }else{
                finalFormulaArr.push(element);    
            }
        }
        let formulaFin = finalFormulaArr;
		for (let i=0; i < formulaFin.length; i++) {
			preOperator = (formulaFin[i-1]  ?formulaFin[i-1] : "");
			if(["operator","control"].includes(formulaFin[i].type)) {
					currentFormula += formulaFin[i].value;
					if (formulaFin[i].value === "(") {
						isSubFormula = true;
					}
			} else {
				currentFormula += _this.getAmountByCostKey(formulaFin[i].value);
				}   
			if (formulaFin[i+1] && formulaFin[i+1].value === "/") {
				finalFormula+= "("+currentFormula;
				currentFormula = "";
			}
			if (!isSubFormula) {
				finalFormula+= currentFormula;
				currentFormula = "";
			}
            if (preOperator.value === "/") {
                currentFormula+= ")";
            }
		}
		finalFormula+= currentFormula;
		
		return  eval("("+finalFormula.replaceAll("--","+")+")");
    }

    refreshAmounts=(mappedLines)=>{
        let _this = this;
        _this.setState({mappedLines: mappedLines},()=>{
            _this.tabulator.replaceData(_this.tabulator.getData())
            _this.setScrollingPosition();
        })
    }
    

    /**
     * function computes the formula the calculated line based on either its formula (if its created by user) or name in fact (if its created by default)
     * @param {*} row
     */
    getCalculatedAmount=(row)=>{
        let _this = this;
        let mappedLines = _this.state.mappedLines;
        let nameInFact = row[PS_MAPPING.FIELDS.NAME_IN_FACT];
        let formulaInFact = row[PS_MAPPING.FIELDS.FORMULA_IN_FACT];
        let mappedLine = mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === row[PS_MAPPING.FIELDS.COST_KEY]);
        let formula = mappedLine.length > 0 ? copyObjectValues(mappedLine[0][PS_MAPPING.FIELDS.MAP_FORMULA]) : copyObjectValues(row[PS_MAPPING.FIELDS.MAP_FORMULA]);
        if (formula.length > 0 && formula !== "[]") { // created calculated line by user
            return _this.computeFormula(formula);
        } else if (formulaInFact && formulaInFact !== null) { // default calculated line
            return _this.computeStringFormula(nameInFact, row[PS_MAPPING.FIELDS.RETURN_NAME], formulaInFact)
        }else{
            return 0;
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} colField
     * @returns
     */
    getColumnFormatter(colField) {
        let columnFormatter;
        let obj = this;
        switch(colField) {
            case _name:
                columnFormatter = function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let divParent = document.createElement("div");
                    let div = document.createElement("div");
                    let p = document.createElement("p");
                    p.classList.add("uk-flex-inline", "uk-cursor-pointer");
                    let em = document.createElement("em");
                    let span = document.createElement("span");
                    span.classList.add("uk-text-overflow-ellipsis", "psl-title-name", "uk-display-inline-block")
                    em.textContent = ([costtype.accrued, costtype.attribute, costtype.calculated].includes(rowData[_costType]) ? rowData[_costType].charAt(0).toUpperCase() + rowData[_costType].slice(1) : "");
                    em.classList.add("text-grey", "font-weight-normal", "uk-margin-xsmall-left");
                    em.style.padding = "0 " + convertPxToViewport(1);
                    span.textContent = cell.getValue() + " ";
                    span.setAttribute("id","new_line_"+rowData[_costKey]);

                    p.appendChild(span);
                    p.appendChild(em);
                    let mappedLines = obj.state.mappedLines;
                    let splitType  = obj.getSplitType(mappedLines,rowData);
                    let unmatchedLine = mappedLines.filter(line => line[_costKey] === rowData[_costKey] && parseBoolean(line[_isMatched]) === false)[0];
                    if(!obj.hasFetchedAmount && obj.props.fetchAmounts) {
                        cell.getElement().classList.add("no-events-cells");     //disabling editing before amounts are back
                    }

                    $(p).css("cursor", "pointer");
                    if (rowData[_costType] === costtype.calculated)
                        $(p).css("font-weight", "bold");

                    div.classList.add("uk-text-overflow-ellipsis");
                    p.classList.add("uk-text-overflow-ellipsis");

                    if (rowData["level"] !== 1 && rowData["level"] !== 2 && rowData["level"] !== 3) {
                        var pixels = (rowData["level"] - 1) * 5;
                        $(p).css("padding-left", convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) {
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color": "#DCDCDC"});
                    } else if (rowData["level"] === 2) {
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color": "#DCDCDC"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color": "#cacaca"});
                    }
                    if (rowData && unmatchedLine) {
                        $(p).css("cursor", "not-allowed");
                    }
                    div.appendChild(p);

                    if (!rowData[_children]) {
                        let isUnmapped = obj.hasUnmappedChildren([rowData]);
                        if (isUnmapped) {
                            if(rowData.costtype === 'calculated' || rowData.costtype === 'attribute'){
                                var redDotDiv = document.createElement("div");
                                redDotDiv.classList.add("column-red-dot-psl");
                                redDotDiv.setAttribute("uk-tooltip", lang.ps_mapping_not_setup);
                                div.appendChild(redDotDiv);
                            }else{
                                div.classList.add("red");
                            }
                           
                        }
                    } else {
                        let hasUnmappedChildren = obj.hasUnmappedChildren(rowData[_children]);
                        if (hasUnmappedChildren === true) {
                            var redDotDiv = document.createElement("div");
                            redDotDiv.classList.add("column-red-dot");
                            redDotDiv.setAttribute("uk-tooltip", lang.ps_mapping_not_setup);
                            div.appendChild(redDotDiv);
                        }
                    }

                    if (!rowData[_name] && rowData[VECTOR_MAPPING.ROW_STATUS_VALUES.NEW] && rowData[VECTOR_MAPPING.ROW_STATUS_VALUES.NEW] === "1") {
                        var label = document.createElement('label');
                        let em = document.createElement("em");
                        em.textContent = ([costtype.accrued, costtype.attribute, costtype.calculated].includes(rowData[_costType]) ? rowData[_costType].charAt(0).toUpperCase() + rowData[_costType].slice(1) : "");
                        em.classList.add("text-grey", "font-weight-normal", "uk-margin-xsmall-left");
                        em.style.padding = "0 " + convertPxToViewport(1);
                        label.textContent =lang.ps_mapping.enter_name;
                        label.classList.add("red", "uk-padding-remove-left");
                        divParent.classList.add("uk-display-inline-flex");
                        divParent.appendChild(label);
                        divParent.appendChild(em);

                        return divParent;
                    }
                    return div;
                }
                break;

            case PS_MAPPING.FIELDS.ADD:
                columnFormatter = function (cell) {
                    let rowData = cell.getRow().getData();
                    let isRowEmpty = rowData[_costKey] === undefined;
                    if (isRowEmpty) {	//if row is empty, do not add any elements
                        return "";
                    }
                    this.rowData = rowData;
                    this.row = cell.getRow();
                    if (rowData[_returnName] === undefined || 
                        ([PSL_RETURN_NAMES.UNITS, PSL_RETURN_NAMES.LINES, PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS, PSL_RETURN_NAMES.VARCOGS, PSL_RETURN_NAMES.VARREVENUE].indexOf(cell.getRow().getData()[_returnName].toLowerCase()) === -1
                        && ![costtype.calculated, costtype.attribute].includes(rowData[_costType]))) {
                            let parentDiv = obj.returnMenuDropDown(cell, rowData, "add", function () {
                                obj.onAddChild(rowData, rowData[_costKey], cell.getRow(), rowData["level"],cell)
                            });
                            return parentDiv;
                    }
                };
                break;
                case 'setup':
                    columnFormatter = function(cell){
                        let rowData = cell.getRow().getData();
                        cell.getElement().classList.add("psl-actions-cell");

                        let dropdownButton = document.createElement("div");
                        dropdownButton.classList.add("uk-button-icon");

                        let dropdownContainer = document.createElement("div");
                        dropdownContainer.classList.add("in-dropdown-container");
                        dropdownContainer.setAttribute("uk-dropdown", "mode: click; boundary: .tabulator-tableHolder");
                        // three dotted button
                        let dotsButtonContainer = document.createElement("div");
                        dotsButtonContainer.classList.add("uk-inline");
                        let dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "dots-button", "transparent-bg"]);                        

                        if(!rowData[_children] && (rowData[_costType] === costtype.standard || rowData[_costType] === costtype.invoicelinetype || rowData[_costType] === costtype.accrued)) {
                            let mappedLine = obj.state.mappedLines.filter(line => line[_costKey] === rowData[_costKey]);
                            let mappedLines = mappedLine.length > 0 ? obj.state.mappedLines.filter(line => mappedLine[0][_leadingCostKey] && line[_leadingCostKey] === mappedLine[0][_leadingCostKey]
                                    || line[_actualId] === mappedLine[0][_id] || line[_id] === mappedLine[0][_actualId] || line[_id] === mappedLine[0][_id]) : [];
                            if(mappedLines.length > 1) {
                                let filterPSLContainer = getTableButton(
                                   "filter PSL",
                                    [],
                                    ["uk-button-icon", "justify-content-start"], 
                                    ["fa-lg","fal","fa-link","width-30","d-inherit"],
                                    "left",
                                );
    
                                filterPSLContainer.onclick = () =>{
                                    UIkit.dropdown(dropdownContainer).hide();
                                    obj.filterTableOnLinks(rowData);
                                }
                                filterPSLContainer.setAttribute("uk-tooltip", MESSAGES.profit_stack_link_filter_tooltip);
                                dropdownContainer.appendChild(filterPSLContainer);
                            }
                        }

                        if (rowData[_costType] === costtype.attribute) {
                            let visibilityFunction = function (e) {
                                obj.props.setNotSavedWarning(true, true);
                                obj.hideDropDown();
                                obj.changeVisibiltyOfLine(e, rowData);
                            }

                            let spanTooltip = MESSAGES.profit_stack_mapping_attribute_show_hide;
                            let btnId = "";
                            let textCt = "";
                            let iconClass ="";

                            let separator = document.createElement("hr");
                            separator.classList.add("uk-hr-dropdown");

                            let inProfitMap = rowData[_inProfitMap];
                            let excludeFromPs = rowData[_excludeFromPs];
                            let includeINPmTotals = rowData[_includeInPMTotals];
                        
                            if (inProfitMap && !excludeFromPs && includeINPmTotals) {
                                textCt = lang.ps_mapping.attrtibute.hide_attribute;
                                iconClass= "fa-eye-slash";
                                btnId = "hidden-attribute";
                            } else {
                                textCt = lang.ps_mapping.attrtibute.show_attribute;
                                iconClass = "fa-eye";
                                btnId = "visible-attribute";
                            }
        
                            let parent = getTableButton(
                                textCt,
                                [],
                                ["uk-button-icon", "uk-border-bottom"],
                                ["fal", iconClass, "uk-padding-small-right", "width-30", "fa-lg"],
                                "left",
                                ""
                            ); 

                            parent.onclick = (e) => {
                                visibilityFunction(e)
                            }
                            parent.id = btnId

                            parent.setAttribute("uk-tooltip", "title: " + spanTooltip);
                            dropdownContainer.appendChild(parent);
                            dropdownContainer.appendChild(separator);
                        }

                        let editContainer = getTableButton(
                            "Setup",
                            [],
                            ["uk-button-icon", "justify-content-start"], 
                            ["fal", "fa-edit", "fa-lg", "width-30", "d-inherit"],
                            "left",
                        );
                        
                        editContainer.onclick=()=>{
                            obj.props.edit(cell.getRow().getData(), true)
                        }
                        if(!(cell.getData()[_children]  && cell.getData()[_children].length > 0)) {
                            dropdownContainer.appendChild(editContainer);
                        }
                        let mappedLine = obj.state.mappedLines.filter(line => line[_costKey] === rowData[_costKey]);
                        let isUnmatched = mappedLine.length > 0 ? parseBoolean(mappedLine[0][_isMatched]) === false && mappedLine[0][_leadingCostKey] !== mappedLine[0][_costKey] : false

                        let deleteContainer = getTableButton(
                            "Delete",
                            [],
                            ["uk-button-icon", "justify-content-start"], 
                            ["fal", "fa-trash-alt", "fa-lg", "uk-padding-small-right", "width-30"],
                            "left",
                        );
                
                        if (isUnmatched) {
                            deleteContainer.classList.add("disabled", "dropdown-button-disabled");
                        }else {
                            deleteContainer.onclick=()=>{
                                obj.showDeleteRowModal(cell.getRow().getData());
                                obj.setState({
                                    row: cell.getRow().getData()
                                });
                                $('#PSLTable .tabulator-tableHolder').animate({
                                    scrollTop:  globaltop
                                },100)
                            }
                        }

                        dropdownContainer.appendChild(deleteContainer);
                        dotsButtonContainer.appendChild(dotsButton);
                        dotsButtonContainer.appendChild(dropdownContainer);

                        if ([GR,NR,COGS,CostOfSales].includes(rowData[_name])) {
                            deleteContainer.classList.add("disabled", "dropdown-button-disabled", "uk-button-icon"); 
                        }
                        if (rowData[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.CALCULATED) {
                            deleteContainer.classList.add("disabled", "dropdown-button-disabled", "uk-button-icon");
                            editContainer.classList.add("hidden");
                        }
                        if(rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL || (rowData[_children] && rowData[_children].filter(e=>(e[PS_MAPPING.FIELDS.ACCRUAL_STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL)).length <1)){
                            deleteContainer.classList.add("disabled", "dropdown-button-disabled", "uk-button-icon");
                        }
                    if (rowData[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.ATTRIBUTE) {
                        return "";
                    }
                    return dotsButtonContainer;
                }
                break;

            case PS_MAPPING.FIELDS.FILTER:
            columnFormatter = function(cell, formatterParams) {
                let costKey = cell.getRow().getData()[_costKey];
                let mappedLines = obj.state.mappedLines;
                let filter = "";
                mappedLines.map(function(item){
                    if(item[_costKey] === costKey && item[_deleted] === "false"){
                        if(item[PS_MAPPING.FIELDS.FILTER]) {
                            var tempFilter= [];
                            var values = [];
                            var parsedFilter =  JSON.parse(item[PS_MAPPING.FIELDS.FILTER]).filter;
                            for(let e in parsedFilter) {
                                let values = [];
                                let parsedFilter = JSON.parse(item["filter"]).filter;
                                for (let e in parsedFilter) {
                                    let values = [];
                                    if (typeof parsedFilter[e].value === "object") {
                                        parsedFilter[e].value.forEach(function (val, key) {
                                            values.push(val.value.toLowerCase());    //setting everything to lower case so that the filter becomes case insensitive
                                        });
                                        tempFilter.push(parsedFilter[e].field + " " + parsedFilter[e].operator + " " + (values.length > 0 ? values.join(",") : "''"));
                                    }
                                }
                                filter = tempFilter.join(", ");
                            }
                        }
                    }
                });
                var p = document.createElement("p");
                p.textContent = filter;
                p.title = filter;
                $(p).css("font-weight", "bold")
                return p;
                }
                break;

            case _costCenter:
                columnFormatter = function (cell) {
                    var rowData = cell.getRow().getData();
                    var costKey = rowData[_costKey];
                    var costCenter = "";
                    var file = "";
                    var mappingException = "";
                    let line = obj.state.mappedLines.filter(item => item[_costKey] === costKey && (item[_deleted] === 'false' || item[_deleted] === undefined))[0];
                    let shouldCostCenterToggleBeDisabled = false;
                    
                    if (!!line) {
                        let siblings = obj.state.mappedLines.filter(item => item[_leadingID] === line[_leadingID] );
                        let containsTransaction = siblings.filter(e=>e[_mappingException] === TRANSACTION).length > 0;
                        let containsAncillary = siblings.filter(e=>e[_mappingException] === ANCILLARY ).length > 0;
                        shouldCostCenterToggleBeDisabled = containsTransaction && containsAncillary && !parseBoolean(line[_isMatched]);

                        line[_costCenter] = line[_mappingException] === TRANSACTION ? 'ALL' : line[_costCenter];
                        costCenter = line[_mappingException] === TRANSACTION ? 'ALL' : line[_costCenter];
                        file = line[PS_MAPPING.EXCEPTION_FIELDS.FILE];
                        mappingException = line[_mappingException];
                    }

                    var result = obj.isDisabledToggleButton(file, line);
                    //show toggle for AC type = assignable and children that don't have acType, no parents, no unmatched and no acType in (calculated, attribute)
                    if ([PS_MAPPING.FIELDS.AC_TYPES.ASSIGNABLE, undefined].includes(rowData[PS_MAPPING.FIELDS.AC_TYPE])
                        // && !rowData[_name].toLowerCase().includes("unmatched")
                        && ![costtype.calculated, costtype.attribute].includes(rowData[_costType])
                        && (!rowData[_children] || !rowData[_children].length)) {
                        // var p = document.createElement("p");
                        var input = document.createElement("input");
                        input.type = "checkbox";
                        input.name = "FY";
                        input.id = "toggle_button" + rowData[_costKey]
                        input.value = costCenter !== "ALL" && costCenter !== "" ? FY_VALUES.ON : FY_VALUES.OFF;
                        input.checked = costCenter !== "ALL" && costCenter !== "" ? true : false;
                        input.onchange = ()=>{obj.changeSwitch(cell, input.value)};
                        input.style.display = 'none';
                        var p2 = document.createElement("p");
                        p2.classList.add("toggle-switch-container");
                        var span = document.createElement("span");
                        span.classList.add('sliderPSS', 'round');
                        span.onclick = ()=>{obj.changeSwitch(cell, input.value)};

                        if (result) {
                            $(cell.getElement()).attr("uk-tooltip", MESSAGES.profit_stack_no_file_cost_center.replace((new RegExp('\\[Branch]', 'g')), obj.props.costCenter));
                            span.onclick = null;
                            span.classList.add("disabled");
                            p2.classList.add("cursorNotAllowed");
                        }
                        if (mappingException === TRANSACTION) {
                            span.classList.add("disabled");
                            p2.classList.add("cursorNotAllowed");
                            $(cell.getElement()).attr("uk-tooltip", MESSAGES.transaction_no_cost_center);
                        }

                        if(shouldCostCenterToggleBeDisabled) {
                            span.classList.add("disabled");
                            p2.classList.add("cursorNotAllowed");
                            $(cell.getElement()).attr("uk-tooltip", MESSAGES.transaction_mixed_with_psl_by_branch);
                        }
                        if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                            span.classList.add("disabled");
                            p2.classList.add("cursorNotAllowed");
                        }

                      
                       

                        p2.appendChild(input);
                        p2.appendChild(span);
                        return p2;
                    } else {
                        return "";
                    }
                }
                break;

            case _mappingException:
                columnFormatter = function (cell) {
                    var rowData = cell.getRow().getData();
                    var mappedLines = obj.state.mappedLines;
                    var p = document.createElement("p");
                    p.textContent = obj.getSplitType(mappedLines, rowData)
                    return p;
                }
                break;

            case PS_MAPPING.FIELDS.COST_TERM:
                columnFormatter = function (cell, formatterParams) {
                    var costKey = cell.getRow().getData()[_costKey];
                    var costTerm = "";
                    var mappedLines = obj.state.mappedLines;
                    mappedLines.map(function (item) {
                        if (item[_costKey] === costKey) {
                            costTerm = item[PS_MAPPING.FIELDS.COST_TERM_ID];
                        }
                    });
                    var label = "";
                    if (costTerm && costTerm !== "") {
                        var options = obj.props.clientCostTerms;
                        label = options.filter(e => e.cost_term_id.toString() === costTerm.toString())[0].psLabel;
                    }
                    var p = document.createElement("p");
                    p.textContent = label;
                    return p;
                }
                break;

            case PS_MAPPING.FIELDS.AMOUNT:
                columnFormatter = function (cell) {
                    var rowData = cell.getRow().getData();
                    var costKey = rowData[_costKey];
                    var mappedLines = obj.state.mappedLines;
                    var amount = obj.getParentAmount(rowData, costKey);
                    var costCenter = "";
                    var driverType = "";
                    var file = "";
                    let amountDetails = [];
                    let line = mappedLines.filter(item => item[_costKey].toString().replace("Insert", "") === costKey.replace("Insert", "") && item[_deleted] === 'false')[0];
                    let isUnmatched = false;
                    if (!!line) {
                        costCenter = line[_costCenter];
                        file = line[_file];
                        driverType = line[_driverType];
                        amountDetails = line[PS_MAPPING.FIELDS.AMOUNT_DETAILS];
                        isUnmatched = parseBoolean(line[_isMatched]) === false;
                    }
                    if (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated) {
                        amount = obj.getCalculatedAmount(rowData);
                    }
                    var p = document.createElement("p");
                    if (obj.isParsingAmount|| obj.state.isParsingAmount)  {
                        let img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        p.appendChild(img);
                    } else {
                        amount = amount === 0 ? "" : amount.toString();
                        if (rowData[PS_MAPPING.FIELDS.RETURN_NAME].includes(_perc)) {
                            p.innerHTML = formatValHTML(amount, FormatTypes.PERCENTAGE);
                            p.title = formatValString(amount, FormatTypes.PERCENTAGE);
                        }else{
                            p.innerHTML = formatValHTML(amount, FormatTypes.AMOUNT);
                            p.title = formatValString(amount, FormatTypes.AMOUNT);
                        }
                        if (Number(amount) < 0) {
                            p.classList.add("red");
                        }

                        let leadingLine = line;
                        if (isUnmatched && line[PS_MAPPING.FIELDS.ACCRUAL_STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                            leadingLine = mappedLines.filter(item => item[_id] === leadingLine[_leadingID])[0];
                            file = leadingLine? leadingLine[_file]:"";
                            driverType = leadingLine? leadingLine[_driverType]:"";
                        }
                        let hasMissingFile = !!leadingLine && leadingLine[_mappingException] === TRANSACTION && obj.props.missingPeriodFiles && obj.props.missingPeriodFiles.includes(leadingLine[_calcCol]);
                        let unstagedMetric = false;
                        let periods = obj.props.getSelectedPeriods();
                        if (driverType === _metric && obj.props.exceptionMetrics && obj.props.exceptionMetrics.filter(r => r.value === file).length > 0) {
                            for (let elt in periods) {
                                let exceptionMetricsPeriods = obj.props.exceptionMetrics.filter(r => r.value === file)[0];
                                if (exceptionMetricsPeriods.period_status[periods[elt]] &&  exceptionMetricsPeriods.period_status[periods[elt]].toUpperCase() !== METRICS_MAPPING.METRIC_STAGING_STATUS.STAGED) {
                                    unstagedMetric = true;
                                    break;
                                }
                            }
                        }
                        if (unstagedMetric || hasMissingFile) {
                            p.innerHTML = "";
                            let icon = document.createElement("i");
                            icon.classList.add("fas", "fa-lg", "fa-exclamation", "pi-text-yellow");
                            p.setAttribute("uk-tooltip", hasMissingFile ? MESSAGES.calc_col_file_missing_in_period + obj.props.getSelectedPeriods() : MESSAGES.not_staged_metric);
                            p.appendChild(icon);
                        }

                        if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL && !obj.state.isYearBuilt) {
                            p.innerHTML = formatValHTML("N/A", FormatTypes.TEXT);
                        } else {
                            if (costCenter && costCenter !== "" && costCenter !== Type.ALL) {
                                $(p).addClass("lined-amount");
                                if (amountDetails && amountDetails.length > 0) {
                                    if (obj.isPSCalculated) {
                                        $(p).addClass('uk-disabled');
                                        $(p).css('cursor', 'default');
                                    } else {
                                        $(p).addClass("uk-text-decoration-underline");
                                        $(p).css("cursor", "pointer");
                                    }
                                    p.onclick = () =>{ 
                                      obj.showEntitiesAmount(cell);
                                      obj.setAmountDialogOpen(true)
                                    };
                                } else {
                                    p.title = MESSAGES.profit_stack_refresh_amounts;
                                }
                            }
                        }
                    }

                return p;
                }
                break;

            case PS_MAPPING.FIELDS.HANDLE_ROW:
                let _this = this;
                columnFormatter = function (cell) {
                    let rowData = cell.getRow().getData();
                    if (!_this.state.checked_combinations && [PSL_RETURN_NAMES.GROSS_PROFIT, PSL_RETURN_NAMES.NET_PROFIT].includes(rowData[_returnName])) {
                        cell.getElement().style.pointerEvents = "none";     //disable drag
                    } else {
                        let div = document.createElement("div");
                        if (!rowData.children && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.calculated && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.attribute) {
                            let i = getTableIcon(["fa-lg", "far", "fa-plus", "assignToIcon", "uk-button-icon"]);
                            i.style.display = _this.state.checked_combinations && _this.state.checked_combinations.length > 0 ? "block" : "none";
                            i.title = "Assign to";
                            i.onclick = () => {
                                $('#PSLTable .tabulator-tableHolder').animate({
                                    scrollTop:  globaltop
                                },100)
                                obj.props.updateAssignedStatus(ALL_WIDGETS.FIELDS.PS_MAPPING.ASSIGN, rowData, cell);
                            }
                            div.appendChild(i);
                        } 
                        // else {
                            let i2 = document.createElement("i");
                            i2.classList.add("fa-lg", "fas", "fa-grip-vertical", "uk-grab", "moveRowIcon");
                            div.appendChild(i2);
                        // }

                        return div;
                    }
                }
                break;

            case _costKey:
                columnFormatter = function (cell) {
                    let ck = extractNumber(cell.getValue());
                    let circle = document.createElement("div");
                    circle.classList.add("psl-border-square", "uk-grab", "costkey-draggable", "costkey_" + cell.getValue());
                    if (cell.getValue() === obj.state.pslLine[_costKey] && obj.isPSCalculated) {
                        //if parseAmount request is received after the mapping window is opened, hide the draggable circle
                        circle.classList.add("uk-hidden")
                    }
                    circle.setAttribute("node_" + _costKey, ck);
                    circle.setAttribute("node_class", "psl-border-square uk-margin-xsmall-right-left");    //classes to be added when dropped into formula box
                    circle.innerText = ck;
                    return circle;
                }
                break;

            case _costType:
                columnFormatter = function (cell) {
                    return cell.getValue() ? cell.getValue() === costtype.invoicelinetype ? capitalizeFirstLetter(costtype.standard) : capitalizeFirstLetter(cell.getValue()) : "";
                }
                break;

            default:
                columnFormatter = function (cell) {
                    return cell.getValue();
                }
                break;
        }
        return columnFormatter;
    }


    componentDidUpdate(prevProps) {
        if(prevProps.missingPeriodFiles !== undefined && !deepCompareObjects(prevProps.missingPeriodFiles,this.props.missingPeriodFiles)) {
            this.tabulator.replaceData(this.tabulator.getData());
        } else if(prevProps.checked_combinations && this.props.checked_combinations && JSON.stringify(prevProps.checked_combinations) !== JSON.stringify(this.props.checked_combinations)){ //Use checked combinations as state from last updated props (PI-29632)
            this.setState({
                checked_combinations:this.props.checked_combinations
            },()=>{
                this.tabulator.replaceData(this.tabulator.getData())
            })
        }

    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} attr
     * @param {*} value
     * @returns
     */
    findPslRow = (data, attr, value) => {
        var found = null;
        for (let e in data) {
            if (data[e][attr] && data[e][attr].toLowerCase() === (value + "").toLowerCase()) {
                found = data[e];
                break;
            } else if (data[e][_children]) {
                found = this.findPslRow(data[e][_children], attr, value);
                if (found) {
                    break;
                }
            }
        }
        return found;
    }

    /**
     * copied from profitStackMapping
     * @param {*} cell
     */
    showEntitiesAmount = (cell) => {
        var obj = this;
        var costKeyClicked = obj.state.mappedLines.filter(item => item[_costKey] === cell.getRow().getData()[_costKey])[0];

        this.setState({
            amount: costKeyClicked[PS_MAPPING.FIELDS.AMOUNT_DETAILS]
        });
    }


    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} costKey
     * @param {*} row
     * @param {*} level
     */
    onAddChild = (data, costKey, row, level,cell) => {
        let _this = this;
        _this.props.setNotSavedWarning(true, true);
        let psFields = this.tabulator.getData();
        let children = [];
        let accrualCostKey;
        var mappedLines = this.state.mappedLines;
        let newCostKey = this.generateUniqueCostKey(psFields);
        let newId = this.generateUniqueIdDB(psFields);
        let newRow = {};
        let filteredLine = _this.state.mappedLines.filter(e=> e.costKey=== costKey);
        if(filteredLine.length>0){// if it's a mapped line
            if(filteredLine[0][_name].endsWith(lang.pss_map_exception.suffixes.unmatched)){
                psFields = this.updateChildAttr(psFields, costKey, [_name], filteredLine[0][_name].replace(lang.pss_map_exception.suffixes.unmatched,""));
                filteredLine[0][_name].replace(lang.pss_map_exception.suffixes.unmatched,"")
            }
            newRow = copyObjectValues(filteredLine);
            newRow[0][_name]= "";
            newRow[0][_id]= newId.toString();
            newRow[0][_leadingID]= newId.toString();
            newRow[0][_leadingCostKey]= newCostKey+ PS_MAPPING.INSERT;
            newRow[0][_returnName]= filteredLine[0][_returnName];
            newRow[0][_costKey]= newCostKey+ PS_MAPPING.INSERT;
            newRow[0][VECTOR_MAPPING.ROW_STATUS_VALUES.NEW]= "1";
            newRow[0][_parentCostKey]= costKey;
            newRow[0][ROW_STATUS.FIELD]= ROW_STATUS.VALUES.NEW;
            newRow[0][PSS.LEVEL]= level +1;
            newRow[0][PS_MAPPING.IS_INHERITED]= true;
            newRow[0][_combinations] = filteredLine[0][_combinations];
            newRow[0][PS_MAPPING.FIELDS.ACCRUAL_STATUS] = row.getData()[PS_MAPPING.FIELDS.ACCRUAL_STATUS];
            newRow[0][PS_MAPPING.FIELDS.ACTUAL_ID] = row.getData()[PS_MAPPING.FIELDS.ACTUAL_ID];
            newRow[0][_costType] = row.getData()[_costType];
            if(filteredLine[0][_leadingCostKey] === costKey){// if the selected line is leading
                newRow[0][_leadingCostKey]= newCostKey+ PS_MAPPING.INSERT;
                mappedLines.filter(e=> e[_leadingCostKey] === filteredLine[0][_leadingCostKey]).forEach(line=>{
                    line[_leadingCostKey] = newCostKey+ PS_MAPPING.INSERT;// change the leading id of the lines that have the old line as leader
                    line[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    line[_leadingID] = newId.toString();
                })
            } else{// not leading
                newRow[0][_leadingCostKey] = filteredLine[0][_leadingCostKey];
                newRow[0][_leadingID]= filteredLine[0][_leadingID];
            }
            let index = findIndexOfValue(mappedLines, _costKey, costKey);
            if(index > -1) {
                mappedLines[index][_deleted] = "true";//removing the old line to be replaced by the new one
            }
            accrualCostKey = mappedLines.filter(e=>e[PS_MAPPING.FIELDS.ACTUAL_ID] === filteredLine[0][PS_MAPPING.FIELDS.ID]).length > 0 ? mappedLines.filter(e=>e[PS_MAPPING.FIELDS.ACTUAL_ID] === filteredLine[0][PS_MAPPING.FIELDS.ID])[0][_costKey] : "";
            if(filteredLine[0][PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL || filteredLine[0][PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED){
                let newName = this.forceRenameLine(filteredLine[0][_name].replace(lang.pss_map_exception.suffixes.accrual,"").replace(lang.pss_map_exception.suffixes.accrual,""),data,cell)
                psFields = this.updateChildAttr(psFields, costKey, [_name], newName);
                psFields = this.updateChildAttr(psFields, costKey, [PS_MAPPING.FIELDS.ACCRUAL_STATUS], "");
                psFields = this.updateChildAttr(psFields, costKey, [_costType], costtype.standard);
                if (row.getData()[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
                    psFields = this.updateChildAttr(psFields, accrualCostKey, [PS_MAPPING.FIELDS.ACTUAL_ID], newId.toString());
                    psFields = this.updateChildAttr(psFields, accrualCostKey, [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                    psFields = this.updateChildAttr(psFields, accrualCostKey, [PS_MAPPING.FIELDS.LEADING_COSTKEY], newCostKey+ PS_MAPPING.INSERT);
                    psFields = this.updateChildAttr(psFields, accrualCostKey, [PS_MAPPING.FIELDS.ACTUAL_COST_KEY], newCostKey+ PS_MAPPING.INSERT);
                }
            }
            if(filteredLine[0][_mappingException] === TRANSACTION){
                psFields = this.updateChildAttr(psFields, costKey, [_costType] ,costtype.standard);
                psFields = this.updateChildAttr(psFields, costKey, [_mappingException] ,NONE);
                psFields = this.updateChildAttr(psFields, costKey, ["isExpandable"] ,true);
            }
            newRow=newRow[0];
            mappedLines.push(newRow);// add the new row to the state.mappedLines
        } else{
            newRow = {
                [_name] : "",
                [_id]: newId.toString(),
                ["id"]: newId.toString(),
                [_costKey]: newCostKey+PS_MAPPING.INSERT,
                [_parentCostKey]: costKey,
                [VECTOR_MAPPING.ROW_STATUS_VALUES.NEW]: "1",
                level: level+1,
                isNotAC:false,
                [_returnName]: "",
                [ROW_STATUS.FIELD]:
                ROW_STATUS.VALUES.NEW,
                [_costType]: data[_costType]    //copy parent's costtype
            };
        }
        children.push(newRow);
        psFields = this.updateProfitStackFields(psFields, costKey, children);
        mappedLines.map(function (item) {
            if (item[_costKey] === costKey) {
                item[_deleted] = "true";
            }
            if (item[_costKey] === accrualCostKey) {
                item[ROW_STATUS.FIELD]= ROW_STATUS.VALUES.EDITED;
                item[PS_MAPPING.FIELDS.ACTUAL_ID] = newId.toString();
                item[PS_MAPPING.FIELDS.LEADING_COSTKEY]= newCostKey+ PS_MAPPING.INSERT;
                item[PS_MAPPING.FIELDS.ACTUAL_COST_KEY]= newCostKey+ PS_MAPPING.INSERT;
            }
        });
        
        let tempState = {};
        tempState.parentName = data[_name]
        tempState.mappedLines = mappedLines;
        tempState.profitStackFields =  psFields;
        tempState.isChanged = true;
        tempState.message = lang.line_emherited.replace("PSL_NAME",filteredLine[0]? filteredLine[0][_name]:"");
        tempState.isInfo = true;
        tempState.newCostKey = newCostKey;
        
        //Get data for the parent row so we can update it's _children array
        let tempParentRowData = row.getData();
        //Add new row to children array
        if(!tempParentRowData.children){
            tempParentRowData.children = [];
        }
        tempParentRowData.children.push(newRow);
        //Update data table row with new children array
        row.update({children:tempParentRowData.children}).then(()=>{
             row.treeExpand();
             this.tabulator.scrollToRow(costKey,"top",false); //It doesn't always work
        });
        if(filteredLine.length > 0){
            _this.launchToast();
        }
        _this.updateRemovalOfMapping(_this.findPslRow(psFields, _costKey, costKey), false, tempState);
        _this.expandCreatedLine(_this.tabulator.getRows(), costKey);
        
    }

    /**
     * when creating a new psline, this function expand the parent in order to redirect the user to the created line
     * @param {*} costKey
     */
    expandCreatedLine=(rows, costKey)=>{
        let _this= this;
        rows.forEach(function(row){
            if(row.getData()[_costKey] === costKey) {
                if(row.getTreeChildren() && row.getTreeChildren().length > 0 && !row.isExpanded){
                    row.treeExpand();
                    row.isExpanded = true;
                   _this.tabulator.scrollToRow(row);
                }
            } else{
                // if (row.modules && row.modules.dataTree){
                    _this.expandCreatedLine(row.getTreeChildren() ,costKey);
                // }
            }
        });
    }



    triggerEdit=(costKey, rows)=> {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        allRows.forEach(function (row) {
            if(row.getData()[_costKey] === costKey) {
                let cell = row.getCell(PS_MAPPING.FIELDS.NAME);
                cell.getData()[PS_MAPPING.FIELDS.NAME] = obj.state.oldName;
                cell.edit();
            }else{
                if(row.getTreeChildren() && row.getTreeChildren().length > 0) {
                    obj.triggerEdit(costKey, row.getTreeChildren());
                }
            }
        })
    }

    /**
     * this function is for when the user press on "Cancel" after getting the rename dialog, it sets the cursor on the name
     */
    cancelRenamingLine=()=>{
        this.setOpenRenamePslDialog(false, "")
        this.triggerEdit(this.state.costKey);
    }

    /**
     * this function is to search for the edited line and change it name to be the generated name, whenn pressing on rename, ex: G&A (2)
     */
    renameLine=(data)=>{
        let _this = this;
        _this.setOpenRenamePslDialog(false, "")

        let newName = this.state.newName;
        let costKey = this.state.costKey;
        for(let e in data) {
            if(data[e]){
                if(data[e][_costKey] === costKey){
                    let tempData = this.updateChildAttr(_this.tabulator.getData(), costKey, [_name], newName);
                    data[e][_name]= newName;
                    let mLines = copyObjectValues(_this.state.mappedLines) 
                    if(mLines.filter(e=>e.costKey === costKey)[0]){
                        mLines.filter(e=>e.costKey === costKey)[0][_name] = newName;
                    }
                    this.setState({
                        mappedLines: mLines,
                    });
                    this.tabulator.replaceData(tempData);
                    return data;
                }
                if(data[e][_children]){
                    this.renameLine(data[e][_children])
                }
            }
        }

        return data;
    }

    /**
     * copied from profitStackMapping
     * @param {*} displayedRows
     * @param {*} costKey
     * @returns
     */
    getRowIndex = (displayedRows, costKey) => {
        var index = -1;
        displayedRows.forEach(function (item, key) {
            var costKeyItem = item.getData()[_costKey].replace("Insert", "");
            var costKeyEditted = costKey.replace("Insert", ""); //removing the insert text from costkey for the newly added rows

            if (Number(costKeyItem) === Number(costKeyEditted)) {
                index = Number(key);
                return false;
            }
        });

        return index;
    }

    /**
     * to inform the user when the line is inherited
     */
    launchToast() {
        $("#toastPSLTable").addClass("show");
        setTimeout(function(){
            $("#toastPSLTable").removeClass("show");
        }, 4000);
    }

    /**
     * copied from profitStackMapping
     * @param {*} costKey
     * @param {*} pssFields
     * @param {*} toBeDeleted
     * @param {*} id
     * @returns
     */
    collectDeletedChildren = (costKey, pssFields, toBeDeleted, id,mappedLines) => {
        var obj = this;
        for (let e in pssFields) {
            if (pssFields[e][_costKey] === costKey || pssFields[e][_parentCostKey] === costKey
                || (toBeDeleted.includes(pssFields[e][_parentCostKey]) && pssFields[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
                if(pssFields[e][VECTOR_MAPPING.ROW_STATUS_VALUES.NEW] === "1") {
                    pssFields.splice(e, 1);
                    if (pssFields[e] && pssFields[e][_children] && pssFields[e][_children].length === 0) {
                        delete pssFields[e][_children];
                    } 
                    continue;
                } else {
                    if (!toBeDeleted.includes(pssFields[e][_costKey])) {
                        toBeDeleted.push(pssFields[e][_costKey]);
                    }
                    pssFields[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                    var accrual = pssFields.filter(item => item[PS_MAPPING.FIELDS.ACTUAL_ID] === id);
                    if (accrual.length > 0) {
                        if (!toBeDeleted.includes(accrual[0][_costKey])) {
                            toBeDeleted.push(accrual[0][_costKey]);
                        }
                        pssFields.filter(item => item[PS_MAPPING.FIELDS.ACTUAL_ID])[0][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                    }
                    if (mappedLines.filter(l => l.pssLeadingId === id && !parseBoolean(l.is_matched)).length > 0) {
                        var unmatched = mappedLines.filter(l => l.pssLeadingId === id && !parseBoolean(l.is_matched))
                        if(!toBeDeleted.includes(unmatched[0][_costKey])) {
                            toBeDeleted.push(unmatched[0][_costKey]);
                        }
                        unmatched[0][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                    }
                }
            }
            if (pssFields[e][_children] && pssFields[e][_children].length > 0) {
                obj.collectDeletedChildren(costKey, pssFields[e][_children], toBeDeleted, id,mappedLines);
            }
            //  else if(pssFields[e][PS_MAPPING.FIELDS.LEADING_PSS_ID] === id) {
            //     if (!toBeDeleted.includes(pssFields[e][_costKey])) {
            //         toBeDeleted.push(pssFields[e][_costKey]);
            //     }            
            // } 
            else {
                delete pssFields[e][_children];
            }
        }
        return pssFields;
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} costkey
     * @param {*} parentCostKey
     * @returns
     */

    removeMovedChild = (data, costkey, parentCostKey) => {
        if (parentCostKey === '201') {
            var curIndex = data.findIndex(el => el[_costKey] === costkey)
            data.splice(curIndex, 1);
            return;
        }
        for (let e in data) {
            if (data[e][_costKey] === parentCostKey) {
                if (data[e][_children]) {
                    let index = data[e][_children].findIndex(el => el[_costKey] === costkey);
                    data[e][_children].splice(index, 1);
                }
            } else if (data[e][_children]) {
                this.removeMovedChild(data[e][_children], costkey, parentCostKey)
            }
        }
    }


    /**
     * copied from profitStackMapping
     * @param {*} row
     * @param {*} shouldDelete
     */
    updateRemovalOfMapping = (row, shouldDelete,tempState) => {
        this.props.setNotSavedWarning(true, true);
        let costKey = row[_costKey];
        let name = row[_name];
        const {profitStackFields,mappedLines,isChanged,newCostKey, ...secondObject} = tempState;
        let rowCombinations = mappedLines.filter(e=>e.returnName === row[_returnName]).length > 0 ?  mappedLines.filter(e=>e.returnName === row[_returnName])[0][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] : []
        let id = row[PS_MAPPING.FIELDS.PSS_ID];
        let toBeDeleted = this.state.toBeDeleted;
        let isMapped = false;
        let unmatchedLine = "";
        let pssFields =  profitStackFields;
        let editedLine = mappedLines.filter(el => el[_costKey] === costKey)[0];
        let isEditingLeading = !!editedLine ? editedLine[_costKey] === editedLine[_leadingCostKey] : false;
        let lonelyUnmatched = false;
        
        if (shouldDelete) {
             pssFields = this.collectDeletedChildren(costKey, this.tabulator.getData(), toBeDeleted, id,mappedLines);
        }
        for (let e in mappedLines) {
            if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
                mappedLines[e].deleted = "true";
            }
        }
        if (!!editedLine) {
            let leadingCK = editedLine[_leadingCostKey];
            let leadingLine = mappedLines.filter(el => el[_leadingCostKey] === leadingCK)[0];
            var ledLines = mappedLines.filter(function(el){if(el[_leadingCostKey] === leadingCK ) return el;});
            var ledLinesWithoutLeading = mappedLines.filter(function(el){if(el[_leadingCostKey] === leadingCK && el[_costKey] !== leadingCK) return el;});

            if (ledLinesWithoutLeading.length === 1 && parseBoolean(ledLinesWithoutLeading[0][_isMatched]) === false) {
                //if this mapping only has unmatched line other than the deleted leading line, delete its mapping
                //if its the same name as the leading, it will be deleted, if not, it will be kept as non mapped
                lonelyUnmatched = true; //to indicate that the unmatched line doesn't belong to any other leading line
                let unmatchedCostkey = ledLinesWithoutLeading[0][_costKey];
                let unmatchedIndex = findIndexOfValue(mappedLines, _costKey, unmatchedCostkey);
                mappedLines.splice(unmatchedIndex, 1);

                if(ledLinesWithoutLeading[0][_name].includes(_variance)) {
                    pssFields = this.updateDeletedProfitStackFields(pssFields, ledLinesWithoutLeading[0][_costKey], "");
                }
            }

            if (ledLines.length > 0 && ledLinesWithoutLeading.length > 0) {
                var newLeadingCostKey = isEditingLeading ? ledLinesWithoutLeading[0][_costKey] : leadingLine[_costKey];
                var newLeadingPssId = isEditingLeading ? ledLinesWithoutLeading[0][_id] : leadingLine[_id];
                var newUmatchedName = isEditingLeading ? ledLinesWithoutLeading[0][_name] : leadingLine[_name];
                if (isEditingLeading) {
                    ledLinesWithoutLeading[0][_amount] = (parseFloat(editedLine[_amount]) + parseFloat(ledLinesWithoutLeading[0][_amount])).toString();
                    ledLinesWithoutLeading[0][_percentage] = (parseFloat(editedLine[_percentage]) + parseFloat(ledLinesWithoutLeading[0][_percentage])).toString();
                } else {
                    // leadingLine[_amount] = (parseFloat(editedLine[_amount]) + parseFloat(leadingLine[_amount])).toString();
                    // leadingLine[_percentage] = (parseFloat(editedLine[_percentage]) + parseFloat(leadingLine[_percentage])).toString();
                }
                ledLines.map(line => line[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED); //update row_status for all mapped lines

                if (isEditingLeading) {  //moving combinations, file and filter from the old leading line to the new leading line
                    for (let e in ledLines) {
                        ledLines[e][_leadingCostKey] = newLeadingCostKey;
                        ledLines[e][_leadingID] = newLeadingPssId;
                        if (ledLines[e][_costKey] === newLeadingCostKey) {
                            ledLines[e][_combinations] = editedLine[_combinations];
                            ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] = !ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] ? editedLine[PS_MAPPING.EXCEPTION_FIELDS.FILE] : ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE];
                            ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] = !ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] ? editedLine[PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] : ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME];
                            ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] = !ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] ? editedLine[PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] : ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER];
                            ledLines[e][_driverType] = !ledLines[e][_driverType] ? editedLine[_driverType] : ledLines[e][_driverType];
                        }

                        if(ledLines[e][_name].includes(_variance)) { //this condition is for renaming previously unmatched lines edited after this change
                            ledLines[e][_name] = newUmatchedName +  _variance;
                            this.renameMatched(pssFields, ledLines[e][_costKey], false, newUmatchedName);
                        }

                        if (parseBoolean(ledLines[e][_isMatched]) === false && !lonelyUnmatched) {
                            //this condition is for preventing the deletion of the unmatched line if there is an alternative leading line
                            let unmatchedCostkey = ledLines[e][_costKey];   //get costkey of the saved unmatched line
                            unmatchedLine = getEmbeddedChild(this.tabulator.getData(), _children, _costKey, unmatchedCostkey);  //get unmatched line from table data
                            unmatchedLine = Object.assign({}, unmatchedLine, ledLines[e]);  //update this line from mappedLines
                            let indexOfDeletedUnmatched = !!unmatchedLine ? toBeDeleted.indexOf(unmatchedCostkey) : -1;
                            if (unmatchedLine && indexOfDeletedUnmatched > -1) {
                                toBeDeleted.splice(indexOfDeletedUnmatched, 1);     //since we're renaming unmatched line and not deleting it, do not delete it from DB
                            }
                        }
                    }
                }
            }
            this.tabulator.replaceData(pssFields);/// no need to replace data since it will be replaced after some lines
            this.setScrollingPosition();
        }
        if (shouldDelete) {
            pssFields = this.updateDeletedProfitStackFields(pssFields, costKey, name, id);
        } else {
            this.renameMatched(pssFields, costKey, false);
        }

        if(unmatchedLine && unmatchedLine[_name].includes(_variance)) {
            //do not reorder unless the unmatched line was automatically created
            pssFields = this.reOrderLine(pssFields, newLeadingCostKey, unmatchedLine);
        }
        this.removeEmptyChildrenCells(pssFields);
        if(unmatchedLine && unmatchedLine[_name].includes(_variance) || shouldDelete){
            this.tabulator.replaceData(pssFields);
            $('#PSLTable .tabulator-tableHolder').animate({
                scrollTop: globaltop
            },100);
        }
        mappedLines.map(function(item,key){
            if((item[_name] && name) && (item[_costKey] === costKey || (item[_parentCostKey] === costKey && shouldDelete))) {
                mappedLines.splice(key,1);
                isMapped = true;
            }
        });
        this.props.updateCombinationsTable(mappedLines,toBeDeleted);
        let ml = this.sortMappedLines(mappedLines);
        var originalFields = this.state.profitStackTableOriginalFields;
        var modifiedFields = this.tabulator.getData();
        var changed = this.compareFields(originalFields, modifiedFields) || isChanged;
        this.setState({
            profitStackFields: pssFields,
            mappedLines: ml,
            isChanged: changed ? changed : this.state.isMappingChanged,
            toBeDeleted: toBeDeleted,
            ...secondObject
        }, function () {
             if(newCostKey !== ""){
                $("#new_line_"+newCostKey+PS_MAPPING.INSERT).parent().parent().parent().parent().addClass("tabulator-editing");
            }
            $("#add-child-row-"+costKey).hide();
            if(shouldDelete && rowCombinations?.length > 0){
                this.props.unAssignOnLineRemoval(rowCombinations)
            }
            this.props.updateParent();
        });
        this.tabulator.addFilter(ROW_STATUS.FIELD, "in", [ROW_STATUS.VALUES.NEW, ROW_STATUS.VALUES.OLD, ROW_STATUS.VALUES.EDITED]);
    }

    /**
     * copied from profitStackMapping
     * @returns
     */
    generateUniqueCostKey = () => {
        var generatedId = "";
        do {
            var inPSFields = 0;
            generatedId = this.getRandomCostkey();
            for (let i = 0; i < this.state.costkeys.length; i++) {
                if (Number(this.state.costkeys[i].costkey.replace("Insert", "")) === generatedId) {
                    inPSFields = -1;
                    break;
                }
            }

            if (inPSFields === 0)
                inPSFields = 1;

        } while (inPSFields <= 0)
        this.state.costkeys.push({costkey: generatedId.toString()});
        return generatedId;
    }

    /**
     * copied from profitStackMapping
     * @returns
     */
    generateUniqueIdDB = () => {
        var generatedId = "";
        do {
            var inPSFields = 0;
            generatedId = this.getRandomCostkey();
            for (let i = 0; i < this.state.ids.length; i++) {
                if (Number(this.state.ids[i].id) === generatedId) {
                    inPSFields = -1;
                    break;
                }
            }

            if (inPSFields === 0)
                inPSFields = 1;

        } while (inPSFields <= 0)
        this.state.ids.push({id: generatedId});
        return generatedId;
    }

    /**
     * copied from profitStackMapping
     * @param {*} pssFields
     * @param {*} costKey
     * @param {*} children
     * @returns
     */
    updateProfitStackFields = (pssFields, costKey, children) => {
        var obj = this;
        for (let e in pssFields) {
            if (pssFields[e][_costKey] === costKey) {
                if (pssFields[e][_children])
                    pssFields[e][_children] = pssFields[e][_children].concat(children);
                else {
                    pssFields[e][_children] = children;
                }
            } else {
                if (pssFields[e][_children] && pssFields[e][_children].length > 0) {
                    obj.updateProfitStackFields(pssFields[e][_children], costKey, children);
                }
            }
        }
        return pssFields;
    }

    /**
     * copied from profitStackMapping
     */
    getProfitStackFields = (launchToast=false) => {
        let _this = this;
        var costKeys = [];
        var callback = (data) => {
            if (data) {
                var pss = tryParse(data.data);
                var columns = _this.getTabulatorColumns(data.columns);
                var _costkeys = JSON.parse(data.costkeys).costkeys;
                var ids = JSON.parse(data.costkeys).ids;
                pss.map(line => {
                    if (line[_mapFormula]) {
                        line[_mapFormula] = tryParse(line[_mapFormula], [])
                    }

                    line[_attribute] = line[_attribute] ? line[_attribute] : "";
                    line[_attributeFunction] = line[_attributeFunction] ? line[_attributeFunction] : "";
                    line[_attributeType] = line[_attributeType] ? line[_attributeType] : "";

                });
                _this.setState({
                    profitStackFields: pss,
                    originalData: copyObjectValues(pss),
                    profitStackTableOriginalFields: copyObjectValues(pss),
                    profitStackOriginalFields: extractCostKeys(pss, costKeys),
                    costkeys: _costkeys,
                    ids: ids,
                    columns: columns,
                }, function () {
                   let filters = _this.tabulator.getFilters(true)
                    if(!filters?.length){
                        _this.tabulator.setColumns(columns);
                    }
                    _this.tabulator.setData(pss);
                    toggleLoader(false, "updateScenario");
                    if(launchToast){
                        _this.launchToast()
                    }

                })
            }
        }

        getProfitStackLines(this.props.scenarioId, "false", callback);
    }

    /**
     * copied from profitStackMapping
     * @param {*} cell
     * @param {*} rowData
     * @param {*} type
     * @param {*} callback
     * @returns
     */
    returnMenuDropDown(cell, rowData, type, callback) {
        var obj = this;
        var parentDiv = document.createElement("div");
        parentDiv.classList.add("uk-inline");
        parentDiv.id = "add-child-row-" + rowData[_costKey];

        var dropDownDiv = document.createElement("div");
        dropDownDiv.classList.add("in-dropdown-container");
        dropDownDiv.id = "in-dropdown-container-" + rowData[_costKey];

        if (rowData[_costType] === costtype.attribute) {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:top-right; offset: 5');
        } else {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:bottom-left; offset: 5');
        }

        var optionChildDiv = document.createElement("div");

        if (type === "add") {
            if (!obj.state.checked_combinations || obj.state.checked_combinations.length === 0) {
                var iconParent = getTableIconButton(["far", "fa-plus-circle", "fa-lg"], ["uk-button-icon", "transparent-bg"])
                parentDiv.appendChild(iconParent);

                var spanDiv = getTableButton("Add Standard Line", [], ["uk-button-icon"]);
                spanDiv.id = "child-standard-line";
                spanDiv.onclick = () => {
                    callback();
                };
                optionChildDiv.append(spanDiv);
            }

            dropDownDiv.append(optionChildDiv);
        } else if (type === "edit") {
            var dotsButton = getTableIconButton(["fal", "fa-2x", "fa-ellipsis-v", "fa-lg"], ["uk-button-icon", "dots-button", "transparent-bg"]);
    
            var editFunction = function () {
                obj.hideDropDown();
                obj.saveChildSettings(cell);
                obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL', false);
            }

            var deleteFunction = function () {
                obj.hideDropDown();

                obj.showDeleteRowModal(cell.getRow().getData());
                obj.setState({
                    row: cell.getRow().getData()
                });
            }

            if (rowData[_costType] === costtype.calculated) {
                editFunction = function () {
                    obj.hideDropDown();
                    obj.saveChildSettings(cell);
                    obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                    obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL', false);
                };
            } else if (rowData[_costType] === costtype.attribute) {
                editFunction = function () {
                    toggleEnableHeaderNext(false);
                    toggleHeaderBack(false);
                    obj.hideDropDown();
                    obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                    obj.showAttributeFormula();
                };
            }

            if (rowData[_costType] === costtype.attribute) {
                var visibilityFunction = function (e) {
                    obj.hideDropDown();
                    obj.changeVisibiltyOfLine(e, rowData);
                }

                var showHideAction = obj.returnLineAction(ACTION_TYPE.SHOW_HIDE, "Hidden Attribute", "fa-eye-slash", cell, rowData, visibilityFunction);
                optionChildDiv.append(showHideAction);
            }
            var mapAction = obj.returnLineAction(ACTION_TYPE.MAP, "Map", "fa-edit", cell, rowData, editFunction);
            var deleteAction = obj.returnLineAction(ACTION_TYPE.DELETE, "Delete", "fa-trash-alt", cell, rowData, deleteFunction);

            optionChildDiv.append(mapAction);
            optionChildDiv.append(deleteAction);
            dropDownDiv.append(optionChildDiv);
            parentDiv.append(dotsButton);
        }
        parentDiv.append(dropDownDiv);

        return parentDiv;
    }

    /**
     *  copied from profitStackMapping
     * @returns
     */
    onTabulatorRenderComplete = () => {
        if (!this.tabulator) {
            return;
        }
        // if(this.isPSCalculated === true) {
        //     this.tabulator.setHeight("75vh");
        // }
        this.redrawTable = false;
        setDraggable("costkey-draggable");
        if(this.reExpand){
            this.reExpandCollapsedRows();
        }
    }

    deleteRowUsedDialogContent = () => {
      let row = this.state.selectedPslRow;
      if(!row) { return; }
      let usingLines = this.isRowUsedInCalculated(row);
      let msg = "";
      if (usingLines.length) {
          let lines = "";
          let addedLinesNb = 0;
          usingLines.forEach((usedLine) => {
            usedLine.childName.forEach((line) => {
                if(!msg.includes(line)){
                    msg += line +", ";
                    addedLinesNb ++;
                }
            });
           
        });
         msg = msg.slice(0, -2);//remove the last ", "
         msg += addedLinesNb > 1 ? lang.used_ps_no_delete_1_calc.replace("is","are"): lang.used_ps_no_delete_1_calc ;

          return (
              <div>
                  <h4>{`${msg}`}</h4>
                  <ul>
                      {usingLines.map((line) => <li key={line.pslName}><h4 className="uk-margin-left">{"- " + line.pslName}</h4></li>)}
                  </ul>
                  <h4>{lang.used_ps_no_delete_2}</h4>
              </div>
          )
      }
    }

  deleteRowUsedDialogActions = () => {
    return (
      <Button
        label={MESSAGES.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenDeleteRowUsedDialog(false)}
      />
    )
  }
  setOpenDeleteRowUsedDialog = (isOpen, row) => {
    let _this = this;
    _this.setState({
      openDeleteRowUsedDialog: isOpen,
      selectedPslRow: row
    })
  }

  showDeleteRowModal = (row) => {
    this.hideDropDown();
    let usingLines = this.isRowUsedInCalculated(row);
    if (usingLines.length) {
      this.setOpenDeleteRowUsedDialog(true, row);
      return;
    }
    else {
      this.setOpenConfirmDeleteRowDialog(true)
    }

    $('#PSLTable .tabulator-tableHolder').animate({
      scrollTop: globaltop
    }, 100)

  }

    isRowUsedInCalculated(row) {
        let _this = this;
        let deletedCK = row[_costKey];// parent row costkey
        let childsCostKeys = getChildCostKeys(deletedCK.replace("Insert", ""), _this.tabulator.getData()) // childs costkey
        childsCostKeys.push(parseInt(deletedCK));
        let calculatedMappedLines = this.state.mappedLines.filter(line => line[_costType] === costtype.calculated);
        let usingLines = [];
        calculatedMappedLines.forEach(line => {
            let formula = line[_mapFormula];
            let usedCostkeys = [];
            let isUsed = formula ? childsCostKeys.some(element => {
                if(formula.filter(item => Number(item[CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.VALUE]) === element && item.type !== "control").length > 0){
                    usedCostkeys.push(element);
                }
            }): undefined;
            if (usedCostkeys.length>0) {
                let names = [];
                usedCostkeys.forEach((costKey) => {
                    names.push(getAttributeFromCostKey(this.tabulator.getData(), costKey, "name",""));
                });
                usingLines.push({pslName:line[_name], childName:names});
            }
        })
        return usingLines;
    }

    /**
     *  copied from profitStackMapping
     * @param {*} pssField
     * @param {*} costKey
     * @returns
     */
    checkCellHasChildren = (pssField, costKey) => {
        var obj = this;
        if (pssField[_costKey] === costKey) {
            if (pssField[_children] && pssField[_children].length > 0) {
                return 1;
            } else {
                return 0;                    }
        } else {
            var hasChildren = -1;
            if (pssField[_children] && pssField[_children].length > 0) {
                var children = pssField[_children];
                for (let elt in children) {
                    hasChildren = obj.checkCellHasChildren(children[elt], costKey);
                    if (hasChildren === 0 || hasChildren === 1) {
                        return hasChildren;
                    }
                }
            } else {
                return -1;
            }

        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} type
     * @param {*} text
     * @param {*} iconClass
     * @param {*} cell
     * @param {*} rowData
     * @param {*} callback
     * @returns
     */
    returnLineAction = (type, text, iconClass, cell, rowData, callback) => {
        var obj = this;
        var disableAction = false;
        var hideAction = false;
       
        let dotsParentClassList = ["uk-button-icon"];
        let spanTooltip = "";
        var spanId = "";

        if (type === ACTION_TYPE.SHOW_HIDE) {
            spanTooltip = MESSAGES.profit_stack_mapping_attribute_show_hide
            dotsParentClassList.push("uk-border-bottom");

            var inProfitMap = rowData[_inProfitMap];
            var excludeFromPs = rowData[_excludeFromPs];
            var includeINPmTotals = rowData[_includeInPMTotals];

            if (inProfitMap && !excludeFromPs && includeINPmTotals) {
                text = "Hide Attribute";
                iconClass = "fa-eye-slash";
                spanId = "hidden-attribute";
            } else {
                text = "Show Attribute";
                iconClass = "fa-eye";
                spanId = "visible-attribute";
            }

        } else if (type === ACTION_TYPE.MAP) {
            if ([costtype.attribute].includes(rowData[_costType]) && parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                disableAction = true;
            } else if ([costtype.attribute, costtype.calculated].includes(rowData[_costType]) && !parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                    disableAction = true;
                }
            } else {
                var mappedLines = obj.state.mappedLines;
                var hideMapIcon = false;
                var unmatchedLine = mappedLines.filter(item => item[_costKey] === rowData[_costKey] && parseBoolean(item[_isMatched]) === false)[0];
                if (unmatchedLine) {
                    hideMapIcon = true;
                    hideAction = true;
                }

                if (!rowData[PS_MAPPING.FIELDS.IS_NOT_AC] && !unmatchedLine) {
                    if (!hideMapIcon) {
                        var costKey = rowData[_costKey];
                        var hasChildren = -1;
                        var table = cell.getRow().getTable();
                        var pssFields = table.getData();

                        for (let e in pssFields) {
                            hasChildren = obj.checkCellHasChildren(pssFields[e], costKey);
                            if (hasChildren === 0 || hasChildren === 1)
                                break;
                        }
                        if (((hasChildren === 0 && (rowData[_returnName] === undefined || obj.isMappable(rowData))) && !hideMapIcon)) {
                            if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                disableAction = true;
                            }
                        } else {
                            hideAction = true;
                        }
                    }
                }
            }
        } else if (type === ACTION_TYPE.DELETE) {

            var unmatchedLine = obj.state.mappedLines.filter(item => item[_costKey] === rowData[_costKey] && parseBoolean(item[_isMatched]) === false)[0];
            var disabledIcon = false;
            if (unmatchedLine) {
                disabledIcon = true;
            }
            if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                disabledIcon = true;
            }

            if ([GR, NR, COGS, CostOfSales].includes(rowData[_name])
                || [PSL_RETURN_NAMES.UNITS, PSL_RETURN_NAMES.LINES, PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS].includes(rowData[_returnName].toLowerCase())
                || rowData[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.CALCULATED
                || disabledIcon) {
                disableAction = true;
            }
        }

        if (disableAction) {
            dotsParentClassList.push("disabled");
        }

        if (hideAction) {
            dotsParentClassList.push("hidden");
        }

        var dotsParent = getTableButton(
            text,
            [],
            dotsParentClassList,
            ["fal", iconClass, "uk-margin-small-right"],
            "left",
            "",
            spanId
        );
        dotsParent.setAttribute("uk-tooltip", "title: " + spanTooltip);
        dotsParent.onclick = (e) => {
            if (callback) {
                callback(e);
            }
        }

        return dotsParent;
    }

    /**
     * copied from profitStackMapping
     * @param {*} rowData
     * @returns
     */
    isMappable = (rowData) => {
        if (![PSL_RETURN_NAMES.UNITS, PSL_RETURN_NAMES.LINES, PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS, PSL_RETURN_NAMES.VARCOGS, PSL_RETURN_NAMES.VARREVENUE]
            .includes(rowData[_returnName].toLowerCase()) && rowData[PS_MAPPING.FIELDS.AC_TYPE] !== PS_MAPPING.FIELDS.AC_TYPES.CALCULATED) {
            return true;
        }

        return false;
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} parentCostKey
     * @param {*} costkey
     * @param {*} item
     * @param {*} pushLast
     */
    addIndexChild = (data, parentCostKey, costkey, item, pushLast) => {
        for (let e in data) {
            if (data[e][_costKey] === parentCostKey) {
                if (data[e][_children]) {
                    let children = [];
                    for (let elt in data[e][_children]) {
                        item[_parentCostKey] = parentCostKey;

                        if (data[e][_children][elt][_costKey] === costkey) {
                            if (pushLast) {
                                children.push(data[e][_children][elt]);
                                children.push(item);
                            } else {
                                children.push(item);
                                children.push(data[e][_children][elt]);
                            }
                        } else {
                            children.push(data[e][_children][elt]);
                        }

                    }
                    data[e][_children] = children;
                }
            } else if (data[e][_children]) {
                this.addIndexChild(data[e][_children], parentCostKey, costkey, item, pushLast);
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} show
     */
    toggleAccountsChart(show = true) {
        if (show) {
            $('#accountsChart').show();
        } else {
            $("#accountsChart").hide();
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} e
     * @param {*} rowData
     */
    changeVisibiltyOfLine(e, rowData) {
        var obj = this;
        var data = obj.tabulator.getData();

        var inProfitMap = false;
        var excludeFromPs = true;
        var includeInPMTotals = false;

        if ([e.target.id, e.target.parentElement.id].includes("hidden-attribute")) {
            inProfitMap = false;
            excludeFromPs = true;
            includeInPMTotals = false;
        } else {
            inProfitMap = true;
            excludeFromPs = false;
            includeInPMTotals = true;
        }

        data.map((line) => {
            if (rowData[_costKey] === line[_costKey]) {
                line[_inProfitMap] = inProfitMap;
                line[_excludeFromPs] = excludeFromPs;
                line[_includeInPMTotals] = includeInPMTotals;
            }
        });

        this.setState({
            profitStackFields: copyObjectValues(data)
        }, function () {
            obj.tabulator.replaceData(data);
        });


    }

    /**
     * copied from profitStackMapping
     */
    showAttributeFormula() {
        var obj = this;
        //hide the Modal first and then reshow it in case it was opened for another PS line first
        this.hideAttributeFormula(function () {
            $("#metric_description").val("");

            obj.setState({
                showAttributeFormula: true,
                profitStackFieldsBeforeEditAttribute: copyObjectValues(obj.state.profitStackFields)
            }, function () {
                $("#submit_ps").hide();
                $("#selectPeriodDiv").hide();

                $('#GLAccountsModal').hide();
                $("#accountsChart").hide();
                $('#attributeFormulaModal').show('slide', {direction: 'right'}, 500);
                var selectedCostKey = obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.COST_KEY];
                var mappedLine = obj.state.mappedLines.filter(e => e[PS_MAPPING.FIELDS.COST_KEY] === selectedCostKey);
                if (mappedLine.length > 0) {
                    $("#metric_description").val(mappedLine[0][PS_MAPPING.FIELDS.DESCRIPTION]);
                } else {
                    $("#metric_description").val(obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.DESCRIPTION]);
                }
            });
        });
    }

    /**
     * copied from profitStackMapping
     * @param {*} cell
     */
    saveChildSettings(cell) {
        var _this = this;
        var mappedLines = this.state.mappedLines;
        var pslLine = {};
        var costCenter = "";
        var fieldsCombination = [];
        var rowData = cell.getRow().getData();
        var cost_term_id = "";
        //if the leaf has been previously mapped before, take the data from the mapped list
        mappedLines.map(function (item) {
            if (item[_costKey] === rowData[_costKey]) {
                pslLine = item;
                costCenter = item[_costCenter];
                fieldsCombination = item[_combinations];
                cost_term_id = item[PS_MAPPING.FIELDS.COST_TERM_ID];
            }
        });

        //fetching the leading PSS id
        let leadingMappedPSLine = mappedLines.filter(e => e[_costKey] === rowData[_costKey])[0];
        var leadingPssId = undefined;
        var leadingPssCostKey = undefined;
        var leadingPsLine = undefined;
        if (leadingMappedPSLine) {
            leadingPssId = leadingMappedPSLine[_leadingID];
            leadingPssCostKey = leadingMappedPSLine[_leadingCostKey];
            leadingPsLine = leadingPssId ? mappedLines.filter(e => e[_id] === leadingPssId)[0] : undefined;
        }

        var unusedCalculatedCols = copyObjectValues(this.state.calculatedCols);
        var pssSiblings = leadingPssId ? this.getSiblings(leadingPssId, mappedLines) : [];      //fetching siblings of leading PS Line
        let exceptionPSLOptions = [];
        let psLeaves = getTreeLeaves(this.tabulator.getData());
        psLeaves.map(line => {
            let mappedLeaf = findOptionByKeyValue(mappedLines, _costKey, line[_costKey]);
            let isMappedForCC = false;  //this var indicates if the line was added to mapped lines only for toggling the cost center
            if (mappedLeaf && mappedLeaf[_combinations] && !mappedLeaf[_combinations].length && mappedLeaf[_mappingException] === NONE && mappedLeaf[_id] === mappedLeaf[_leadingID]) {
                isMappedForCC = true;
            }

            let isSibling = findOptionByKeyValue(pssSiblings, _costKey, line[_costKey]);
            let isWeird = [costtype.calculated, costtype.attribute].includes(line[_costType]);    //it's also not standard :)
            if (!isSibling && mappedLeaf && mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]) {                //if this line is mapped, check if it has a calculated col, if it does, remove it from list of unusued cols
                let tempIndex = findIndexOfValue(unusedCalculatedCols, CALCULATED_COLUMNS.FIELDS.NAME, mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]);
                if (tempIndex !== -1) {
                    unusedCalculatedCols.splice(tempIndex, 1);
                }
            }

            if ((!!isSibling || !mappedLeaf || isMappedForCC) && _this.isMappable(line) && !isWeird) {
                //if it is not mapped before, or is mapped and is a sibling, add it to leaves for psl options
                line = mappedLeaf || line;   //if mapped line exists (sibling), use it
                line.value = mappedLeaf ? mappedLeaf[_returnName] : line[_returnName];
                line.label = mappedLeaf ? mappedLeaf[_name] : line[_name];
                exceptionPSLOptions.push(copyObjectValues(line));
            }
        });

        //create PS line if not found in list of mapped lines
        pslLine = Object.getOwnPropertyNames(pslLine).length > 0 ? pslLine :
            {
                [_id]: rowData[_id],
                [_costKey]: rowData[_costKey],
                [_parentCostKey]: rowData[_parentCostKey],
                [_name]: rowData[_name],
                [_returnName]: rowData[_returnName],
                [_costType]: rowData[_costType],
                [_mapFormula]: rowData[_mapFormula],
                [_combinations]: fieldsCombination,
                [_percentage]: "",
                [PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]: "",
                [PS_MAPPING.EXCEPTION_FIELDS.FILE]: "",
                [PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER]: "",
                [PS_MAPPING.FIELDS.COST_TERM_ID]: cost_term_id,
                [_deleted]: "false"
            };

        $("input[name=costCentertype][value='" + costCenter + "']").prop('checked', true);
        this.setState({
            profitStackFields: this.tabulator.getData(),
            accountsType: ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION,
            glType: 'ALL',
            pslLine: leadingPsLine || pslLine,  //if not found in mappedLines, take it from built object
            mappedLine: pslLine,
            pssSiblings: pssSiblings,
            leadingPssId: !leadingPssId ? rowData[_id] : leadingPssId,
            leadingPssCostKey: !leadingPssCostKey ? rowData[_costKey] : leadingPssCostKey,
            cell: cell,
            exceptionPSLOptions: exceptionPSLOptions,
            costTerm: leadingPsLine ? leadingPsLine[PS_MAPPING.FIELDS.COST_TERM_ID] : pslLine ? pslLine[PS_MAPPING.FIELDS.COST_TERM_ID] : "",
            unusedCalculatedCols: unusedCalculatedCols
        });
    }

    /**
     * copied from profitStackMapping
     * @param {*} accountsType
     * @param {*} glType
     * @param {*} isManageExclusions
     */
    showGLAccounts(accountsType, glType, isManageExclusions) {
        var obj = this;
        //hide the Modal first and then reshow it in case it was opened for another PS line first
        this.hideGLAccounts(function () {
            $("#metric_description").val("");
            obj.setState({
                accountsType: accountsType,
                showGLAccounts: true,
                glType: glType,
                isManageExclusions: isManageExclusions
            }, function () {
                $("#submit_ps").hide();
                $("#selectPeriodDiv").hide();
                obj.toggleAccountsChart(false);
                $('#GLAccountsModal').show('slide', {direction: 'right'}, 500);
                // if (typeof obj.props.setConfirmationRequirement === "function") {
                //     obj.props.setConfirmationRequirement(HEADER_ELEMENT.PERIOD, true, lang.ps_mapping_change_period);
                // }
                obj.isPSCalculated = obj.state.pslLine[_costType] === costtype.calculated && !isManageExclusions;
                obj.isPSAttribute = obj.state.pslLine[_costType] === costtype.attribute && !isManageExclusions;
                obj.toggleTableColumnsForCalculated();
                var selectedCostKey = obj.state.profitStackLineToMap ? obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.COST_KEY] : "";
                var mappedLine = obj.state.mappedLines.filter(e => e[PS_MAPPING.FIELDS.COST_KEY] === selectedCostKey);
                if (!isManageExclusions) {
                    if (mappedLine.length > 0) {
                        $("#metric_description").val(mappedLine[0][PS_MAPPING.FIELDS.DESCRIPTION]);
                    } else {
                        $("#metric_description").val(obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.DESCRIPTION]);
                    }
                }
            });
        });

        if (obj.state.showAttributeFormula) {
            obj.hideAttributeFormula(undefined, true);
            obj.toggleAccountsChart(false);
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} lineType
     * @param {*} cell
     * @param {*} newRow
     */
    setLineToMap(lineType, cell, newRow) {
        var tempState = {};

        if (lineType === STAGING_SECTIONS.PROFIT_STACK_MAPPING) {
            tempState.profitStackLine = cell.getRow().getData()[_name];
            tempState.profitStackLineToMap = cell.getRow().getData();
            tempState.onElementChange = false;
            tempState.onFunctionChange = false;
        } else if (lineType === STAGING_SECTIONS.METRICS_MAPPING) {
            if (newRow) {
                tempState.mappedMetric = newRow;
                tempState.mappedMetricPosition = 0;
                tempState.filter = "";
            } else {
                tempState.mappedMetric = cell.getRow().getData();
                tempState.mappedMetricPosition = cell.getRow().getPosition();
                tempState.filter = cell.getRow().getData()[METRICS_MAPPING.FIELDS.FILTER];  //save metric filter in state when clicking to map a metric
            }
        }

        this.setState(tempState);
    }

    /**
     * copied from profitStackMapping
     */
    hideDropDown() {
        UIkit.dropdown($('.in-dropdown-container.uk-dropdown')).hide();
    }


    /**
     * copied from profitStackMapping
     * @param {*} pssFields
     * @param {*} costKey
     * @param {*} name
     * @param {*} id
     * @returns
     */
    updateDeletedProfitStackFields(pssFields, costKey, name, id) {
        var obj = this;
        for(let e in pssFields) {

            if(pssFields[e][_costKey] === costKey) {
                pssFields.splice(e,1);
                if (pssFields[e]){
                    if(pssFields[e][_name].replace(_variance,"") === name.replace(_matchedSuffix,"")) {
                        pssFields.splice(e,1);
                    }
                }
            } else {
                if (pssFields[e][_children] && pssFields[e][_children].length > 0) {
                    obj.updateDeletedProfitStackFields(pssFields[e][_children], costKey, name, id);
                } else {
                    delete pssFields[e][_children];
                }
            }
            if (id && pssFields[e] && pssFields[e][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
                pssFields.splice(e, 1);
            }
        }
        return pssFields;
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @param {*} childCostKey
     * @param {*} flag
     * @param {*} name
     */
    renameMatched(data, childCostKey, flag, name) {
        for (var e in data) {
            if (data[e] && data[e][_costKey] === childCostKey) {
                if (flag) {
                    data[e][_name] = !data[e][_name].includes(_matchedSuffix) ? data[e][_name] + _matchedSuffix : data[e][_name];
                } else if(!name){
                    data[e][_name] = data[e][_name].replace(_matchedSuffix,"").replace(_variance, "");
                } else {
                    data[e][_name] = name.replace(_matchedSuffix, "").replace(_variance, "") + _variance;
                }
            } else if (data[e] && data[e][_children]) {
                this.renameMatched(data[e][_children], childCostKey, flag, name);
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @returns
     */
    sortMappedLines(data) {
        var res = [];
        var res2 = []
        for (var e in data) {
            if (data[e]["name"].includes("matched") && !data[e]["name"].includes("unmatched")) {
                res.push(data[e]);
            } else if (!data[e]["name"].includes("matched") && !data[e]["name"].includes("unmatched")) {
                res.push(data[e]);
            } else {
                res2.push(data[e])
            }
        }
        return res.concat(res2);
    }


    /**
     * copied from profitStackMapping
     * @param {*} pssFields
     */
    removeEmptyChildrenCells(pssFields) {
        var obj = this;
        for (var e in pssFields) {
            if (pssFields[e][_children] && pssFields[e][_children].length > 0) {
                obj.removeEmptyChildrenCells(pssFields[e][_children]);
            } else {
                if (pssFields[e][_children] && pssFields[e][_children].length === 0) {
                    pssFields[e][_children] = null;
                }
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} originalFields
     * @param {*} modifiedFields
     * @returns
     */
    compareFields(originalFields, modifiedFields) {
        // compare lengths - can save a lot of time 
        if (originalFields.length !== modifiedFields.length)
            return true;

        for (var i = 0, l = originalFields.length; i < l; i++) {
            var objectsAreDifferent = false;
            for (var propertyName in originalFields[i]) {
                // comparing objects 
                if (typeof modifiedFields[i][propertyName] === "object" && modifiedFields[i][propertyName] !== null) {
                    for (var subPropertyName in originalFields[i][propertyName]) {
                        if (originalFields[i] && modifiedFields[i]) {
                            if (originalFields[i][propertyName][subPropertyName] !== modifiedFields[i][propertyName][subPropertyName]) {
                                objectsAreDifferent = true;
                                break;
                            }
                        }
                    }
                } else if (originalFields[i][propertyName] !== modifiedFields[i][propertyName]) {
                    objectsAreDifferent = true;
                }
                if (objectsAreDifferent)
                    break;
            }
        }
        return objectsAreDifferent;
    }

    /**
     * copied from profitStackMapping
     * @returns
     */
    getRandomCostkey() {
        var costkey = Math.floor(Math.random() * 10000) + 1;
        return costkey === 201 ? this.getRandomCostkey() : costkey;
    }


    /**
     * copied from profitStackMapping
     * @param {*} name
     * @param {*} data
     * @param {*} costKey
     * @param {*} oldValue
     * @returns
     */
    checkExistingName(name, data, costKey, oldValue) {
        for(let e in data) {
            if (data[e] && data[e][_name]){
                if (data[e][_name].toLowerCase().includes(_variance) && data[e][_name].replace(_variance,"")  === oldValue.replace(_matchedSuffix,"")) {
                    data[e][_name] = name.replace(_matchedSuffix,"") + _variance;
                }
                if((data[e][_name] && data[e][_name].toLowerCase().replace(_matchedSuffix,"").replace(_variance,"") === name.toLowerCase()/*.replace(/[^a-zA-Z0-9_]/g, '')*/ && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
                    return -1;
                } else if (data[e].name.toLowerCase().replace(/ /g, "").replace(_matchedSuffix, "") === name.toLowerCase().replace(/ /g, "") && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                    return 1;
                } else {
                    if (data[e][_children]) {
                        let res = this.checkExistingName(name, data[e][_children], costKey, oldValue);
                        if (res !== 1 && res !== -1) {
                            continue;
                        } else {
                            return res;
                        }
                    }
                }
            }
        }
    }


    /**
     * Count the occurancy of a name in the data
     * @param {*} data 
     * @param {*} name 
     * @returns 
     */
    checkNameOcc(data, name, costKey) {
        name = name.toLowerCase().replaceAll(" ", "");
        data = data.filter(e=>e.name);
        let counts = [];
        for (let e in data) {
            if (data[e]) {
                if (data[e][_costKey] !== costKey && ((data[e][_name].toLowerCase().replaceAll(" ", "") === name)
                    || (data[e][_name].toLowerCase().replaceAll(" ", "").startsWith(name + "(") && data[e][_name].toLowerCase().replaceAll(" ", "").endsWith(")")
                        && !data[e][_name].endsWith("(matched)") && !data[e][_name].endsWith("(unmatched)")))) {
                    this.count++;
                }
                if ((data[e][_costKey] !== costKey &&  ( data[e][_name].toLowerCase().replaceAll(" ", "").startsWith(name +"(") || data[e][_name].toLowerCase().replaceAll(" ", "").startsWith(name +"(")) && data[e][_name].toLowerCase().replaceAll(" ", "").endsWith(")"))) {
                    counts.push(parseInt(data[e][_name].toLowerCase().replaceAll(" ", "").split(name)[1].replace("(","").replace(")","")));
                }
            }
        }
        return counts.length > 0 ? Math.max(...counts) + 1 : this.count;
    } 
  saveChanges() {
    let _this = this
    let not_emptyCells = _this.props.checkEmptyCells(_this.tabulator.getData()) !== 1;
    let hasRedDot = $('div').hasClass('column-red-dot-psl');
    if (!not_emptyCells || hasRedDot) {
      _this.props.setNotSavedWarning(true);
      _this.setWarningDialogOpen(true, !not_emptyCells && hasRedDot ? lang.profit_stack_map_empty_name + " And " + lang.profit_stack_map_red_dot : !not_emptyCells ? lang.profit_stack_map_empty_name : lang.profit_stack_map_red_dot)
    } else {
      this.showScenarioPopUp();
    }
  }

    showScenarioPopUp(filter, accountNumbers, accountsType) {
        var obj = this;
        this.state.profitStackFields = this.tabulator.getData();
        this.setState({
            profitStackFields: this.state.profitStackFields,
            filter: filter || "",
            accountNumbers: accountNumbers || [],
            accountsType: accountsType || ""
        }, function () {
            obj.props.showScenarioPopUp();
        })
    }

    forceRenameLine = (name, rowData, cell) => {
        let obj = this;
        let newName = name;
        let nameExists = obj.checkExistingName(name, obj.state.profitStackFields, rowData[_costKey], cell.getOldValue());
        if (nameExists === -1 || (nameExists === 1 && name!=="") || name.toLowerCase() === _orders){     
            obj.count = 0;
            let len = obj.checkNameOcc(linearizeHierarchy(cell.getTable().getData(), _children), name, rowData[_costKey]);
            if(len >0){
                newName = name.trim() + " (" + Number(len) + ")";
            }
        }
        return newName;
    }
    
    /**
     * copied from profitStackMapping
     */
    componentDidMount() {

        // $("#header-next").click(function (e) {
        //     obj.saveChanges();
        // });
        let obj = this;
        var options = {
            //"index" sets the main column of the table to be used as reference when using updateData
            index: _costKey,
            layout: "fitDataFill",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: true,
            resizableColumns: false,
            autoResize: true,
            dataTreeChildIndent: 30,
            dataTreeChildField: _children,
            // dataTreeStartExpanded: false,
            dataTreeElementColumn: PS_MAPPING.FIELDS.EXPAND,
            dataTreeCollapseElement: getExpandCollapseButtons(false, ["uk-width-1-1"]),
            dataTreeExpandElement:  getExpandCollapseButtons(true, ["uk-width-1-1"]),
            dataTreeBranchElement: false, //hide branch element
            virtualDom: true,
            virtualDomBuffer: 30000,
			invalidOptionWarnings: false,
            placeholder: MESSAGES.no_data_available,
            scrollToRowPosition: "center",
            scrollToRowIfVisible: false,
            width: "100%",
            height: "100%",
            renderComplete: this.onTabulatorRenderComplete,
            dataTree: true,
            reactiveData: true,
            tooltips: function (cell) {
                if (cell.getColumn().getField() === _mappingException) {
                    return ""
                }
                return cell.getValue();
            },
            keybindings: {
                "navNext": false, //disable navNext keybinding
            },
            rowMouseEnter: function (e,row){
                if(e.buttons === 0){// if no buttons are clicked (we are draging)
                    if(row.getPrevRow()){
                        obj.previousCostKey = row.getPrevRow().getData().costKey;// save the costKey of the previous line
                    }else{
                        obj.previousCostKey = 0;//first line in the table don't have parent
                    }
                }
            },
            rowMoved: function (row, index, index2) {
                var tabulator = obj.tabulator;
                let isNetRevenueExpanded = checkLineExpanded(tabulator, _netrevenue, _returnName);
                let isNetRevenueLine = row._row.data.returnName === _netrevenue; // check if netRevenue parent is moved
                var data = copyObjectValues(tabulator.getData());
                let isUnderRevenue = checkLineUnderParent(data, _netrevenue, _returnName, row.getData()[_returnName]);
                var movedRowCostKey = row.getData()[_costKey];
                var displayedRows = obj.tabulator.rowManager.displayRows[1];    //an array in tabulator that contains all displayed rows in order
                let displayedRowIndex = obj.getRowIndex(displayedRows, movedRowCostKey);
                let previousItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex - 1]);
                let nextItem =  displayedRowIndex === displayedRows.length -1 ? null : (displayedRows[displayedRowIndex + 1])
                let movedRowInfo  = {previousItem:previousItem,nextItem:nextItem}
                let movedToUnderRevenue = (previousItem !== null  && checkLineUnderParent(data, _netrevenue, _returnName, previousItem.getData()[_returnName])) || false;
                let hasCombinations = obj.props.checkLineHasCombinations(row.getData(),row.getData()[_name]);
                if(((isUnderRevenue && !movedToUnderRevenue) || (movedToUnderRevenue && !isUnderRevenue)) && hasCombinations && isNetRevenueExpanded && !isNetRevenueLine ){
                    obj.rowToBeMoved = row;
                    $("#flip_checkbox").prop('checked',true);
                    obj.props.setOpenCombinationFlipWarningDialog(true,movedRowInfo)    
                }else{
                    obj.executeMoveRow(row, data, obj);
                }
                obj.checkHasChildren(data)
                obj.profitStackFields = data;
                obj.forceUpdate();

            },
            dataTreeRowExpanded: function (row, level) {
                let costKey = row.getData()[_costKey];
                if (obj.expandedRowsCostkeys.indexOf(costKey) === -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    obj.reExpand = false;
                    obj.expandedRowsCostkeys.push(costKey);
                }
                $('#PSLTable .tabulator-tableHolder').animate({
                    scrollTop:  globaltop
                },100)
                setDraggable("costkey-draggable");
            },
            dataTreeRowCollapsed: function (row, level) {
                let costKey = row.getData()[_costKey];
                let index = obj.expandedRowsCostkeys.indexOf(costKey);
                if (index !== -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    obj.reExpand = false;
                    obj.expandedRowsCostkeys.splice(index, 1);
                }
                $('#PSLTable .tabulator-tableHolder').animate({
                    scrollTop:  globaltop
                },100)
                setDraggable("costkey-draggable");
            },
            cellEdited: function (cell) {
                var rowData = cell.getRow().getData();
                let name = rowData[_name];
                let firstChar = name[0];
                let warningMessage = "";
                obj.setState({
                    oldName:cell.getOldValue(),
                    costKey: rowData[_costKey]
                },function(){
                if (!isNaN(Number(firstChar))) {
                    warningMessage = MESSAGES.name_starting_number;
                    obj.setOpenRenamePslDialog(true, warningMessage)
                    name = "";
                }
                let newName = "";
                let nameExists = obj.checkExistingName(name, obj.state.profitStackFields, rowData[_costKey], cell.getOldValue());
                if (nameExists === -1 || (nameExists === 1 && name!=="") || name.toLowerCase() === _orders){
                   
                    let len = obj.checkNameOcc(linearizeHierarchy(cell.getTable().getData(), _children), name, rowData[_costKey]);
                    obj.count = 0;
                    if(len >0){
                        newName = name.trim() + " (" + Number(len) + ")";
                    }else {
                        newName = name;
                    }
                    warningMessage = MESSAGES.rename_line.replace("[EXISTING_NAME]",newName);
                    obj.setOpenRenamePslDialog(true, warningMessage)
                }

                let isChanged = obj.state.isChanged;
                if (nameExists !== 1 && nameExists !== -1) {
                    if (rowData[_costType] === costtype.attribute && !parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                        rowData[_returnName] = name;
                    }
                    isChanged = true

                    var mappedLines = obj.state.mappedLines;
                    for (let e in mappedLines) {
                        if (mappedLines[e][_costKey] === rowData[_costKey]) {
                            mappedLines[e][_name] = rowData[_name];
                            mappedLines[e][_returnName] = rowData[_name];
                            if (mappedLines[e]["row_status"] !== "new_row" && mappedLines[e][_costType] === "attribute") {
                                mappedLines[e]["row_status"] = 'edited';
                            }
                        }
                    }

                }
                var tempData = obj.updateChildAttr(cell.getTable().getData(), rowData[_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                tempData = obj.updateChildAttr(tempData, rowData[_costKey], [_name], newName!==""?newName:name);
                mappedLines = obj.updateChildAttr(obj.state.mappedLines, rowData[_costKey], [_name], newName!==""?newName:name);
                if(obj.state.mappedLines.filter(e=>e[_costKey] === obj.state.costKey)[0] && obj.state.mappedLines.filter(e=>e[_costKey] === obj.state.costKey)[0][PS_MAPPING.IS_INHERITED] === true){
                    obj.props.inheritAssignTo(rowData, obj.state.parentName);
                }
                obj.setState({
                    mappedLines: mappedLines,
                    newName: newName,
                    costKey :rowData[_costKey],
                    isChanged: isChanged,
                    warningMessage: warningMessage,
                    originalData: copyObjectValues(tempData),
                    profitStackFields: tempData
                },function(){
                    obj.props.setNotSavedWarning(true, true)
                    if (tempData) {
                        obj.tabulator.replaceData(tempData);
                    }    
                    obj.props.updateCombinationsTable(mappedLines,[], newName? newName: name, obj.state.oldName);
                    if(!!cell.getOldValue()){
                        obj.props.inheritAssignTo(rowData, obj.state.oldName);
                    }
                })

                cell.getColumn().getElement().removeEventListener('keypress', function (e) {
                    if (e.which === 13) {
                        e.stopImmediatePropagation();
                    }
                }, false);
            })
            },
            cellEditing: function (cell) {
                cell.getColumn().getElement().addEventListener('keydown', function (e) {
                    if (e.which === 13) {
                        e.stopImmediatePropagation();
                    }
                }, false);
            },
            rowFormatter:function(row, data){
                var rowData = row.getData();
                if (rowData[PS_MAPPING.FIELDS.PERCENTAGE_MACHINE_NAME] && rowData[PS_MAPPING.FIELDS.PERCENTAGE_MACHINE_NAME] === FormatTypes.PERCENTAGE && rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated){
                    row.getElement().classList.add("uk-hidden");
                }
            },
            scrollVertical:function(top){
                globaltop = top
            },
        }
        let tableColumns = null;
        tableColumns = copyObjectValues(obj.state.columns);
        tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
        options.columns = tableColumns;		//set the columns as the actual columns of the table
        obj.tabulator = new Tabulator(obj.refs.mainTable, options);
        if (obj.props.periods) {
            obj.setState({
                periods: obj.props.periods,
                periodName: obj.props.selectedPeriod ? obj.props.selectedPeriod.value : "",
                periodTotals: obj.props.totals ? obj.props.totals : []
            }, function () {
                obj.init();
            });
        }
        /*The .click() didn't work */
        $(document).on('click', '#calculated-line', function () {
            obj.onAddParentRow(costtype.calculated);
            obj.hideDropDown();
        })
        $(document).on('click', '#attribute-line', function () {
            obj.onAddParentRow(costtype.attribute);
            obj.hideDropDown();
        })
        $(document).on('click', '#standard-line', function () {
            obj.onAddParentRow(costtype.standard);
            obj.hideDropDown();
        })
    }
    
    /**
     * move the raw position in the psl tabulator, called from rowMoved event or from the proceed button in the warning modal (combinationSignageFlipWarning)
     * @param {*} row 
     * @param {*} data 
     * @param {*} obj 
     * @returns 
     */
    executeMoveRow=(row, data, obj,rowInfo)=>{
        var movedRowCostKey = row.getData()[_costKey];
        var movedRowParentCostKey = row.getData()[_parentCostKey];
        var displayedRows = obj.tabulator.rowManager.displayRows[1];    //an array in tabulator that contains all displayed rows in order
        let displayedRowIndex = obj.getRowIndex(displayedRows, movedRowCostKey);
        let previousItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex - 1]);
        let nextItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex + 1]);
        let currentItem = row.getData();
        if(rowInfo){
            previousItem = rowInfo.previousItem;
            nextItem = rowInfo.nextItem;
        }
        var movedRowLineType = currentItem[_costType];
        var allowMove = true;
        if((obj.previousCostKey === 0 && row.getPosition()===0) || (obj.previousCostKey > -1 && previousItem && previousItem.getData().costKey === obj.previousCostKey)){
            return;// return if we dragged to the same line, the position is considered the same if the previous line before and after moving is the same (same costKey).
        }
        obj.props.setNotSavedWarning(true, true);
        //dragging to top or bottom of fields
        if (previousItem === null || nextItem === null) {
            obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
            currentItem[_parentCostKey] = '201';
            obj.state.mappedLines.map(function (item) {
                if (item[_costKey] === movedRowCostKey) {
                    item[_parentCostKey] = '201';
                }
            });
            if (previousItem) {
                data.push(currentItem);
                obj.tabulator.replaceData(data);
            } else {
                data.unshift(currentItem);
                obj.tabulator.replaceData(data);
            }
            return;
        }

        if ((movedRowLineType === costtype.attribute && nextItem && nextItem.getData()[_costType] !== costtype.attribute
            || (movedRowLineType === costtype.calculated && ((previousItem && previousItem.getData()[_costType] === costtype.attribute) || nextItem.getData()["level"] > 1)))) {
            obj.tabulator.replaceData(obj.state.originalData); // this to prevent dragging attributes between other type lines or calculated between attribute lines or between children
            allowMove = false;
        } else if (previousItem && nextItem && previousItem.getData()[_costKey] === nextItem.getData()[_parentCostKey]) {
            if (previousItem.getData().level === 2
                && (movedRowLineType === costtype.attribute || movedRowLineType === costtype.calculated)) {
                    obj.tabulator.replaceData(data).then(()=>{
                        obj.props.setReorderedTreeStructure(data);
                    });
                return;
            } else {
                // splice from original data
                obj.state.mappedLines.map(function (item) {
                    if (item[_costKey] === movedRowCostKey) {
                        item[_parentCostKey] = previousItem.getData()[_costKey];
                    }
                });
                obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                // add to new parent children array
                obj.addIndexChild(data, previousItem.getData()[_costKey], nextItem.getData()[_costKey], currentItem);
                obj.tabulator.replaceData(data).then(()=>{
                    obj.props.setReorderedTreeStructure(data);
                });
            }
            return;
        } else if (previousItem && nextItem && (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey] ||
            currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey])) {
            //draggin to a same level
            if (previousItem.getData()["level"] === 2
                && [costtype.calculated, costtype.attribute].includes(movedRowLineType)) {
                obj.tabulator.replaceData(data);
                allowMove = false;
            } else {
                obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
                obj.state.mappedLines.map(function (item) {
                    if (item[_costKey] === movedRowCostKey) {
                        item[_parentCostKey] = previousItem.getData()[_parentCostKey];
                    }
                });

                if (previousItem.getData()[_parentCostKey] === '201') {
                    var currIndex = data.findIndex(el => el[_costKey] === nextItem.getData()[_costKey]);
                    data.splice(currIndex, 0, currentItem);
                } else {
                    if (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey]) {
                        obj.addIndexChild(data, previousItem.getData()[_parentCostKey], nextItem.getData()[_costKey], currentItem);
                    } else if (currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey] && currentItem[_parentCostKey] !== nextItem.getData()[_parentCostKey]
                        && [costtype.standard,costtype.invoicelinetype,costtype.accrued].includes(currentItem[_costType])) {
                        obj.addIndexChild(data, currentItem[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
                    }
                }
                obj.tabulator.replaceData(data).then(()=>{
                    obj.props.setReorderedTreeStructure(data);
                });
                
            }
            return;
        }
        // return;
        // else dragging a row under a child of a parent 
        if (row && currentItem && previousItem && previousItem.getData()) {
            currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
        }
        obj.state.mappedLines.map(function (item) {
            if (item[_costKey] === movedRowCostKey) {
                item[_parentCostKey] = previousItem.getData()[_parentCostKey];
            }
        });
        if(allowMove) {
            obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
            obj.setState({
                originalData: copyObjectValues(data)
            })
        }
        if (nextItem.getData()[_parentCostKey] === '201' && currentItem[_costType] === costtype.standard) {
            obj.addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
        } else {
            obj.addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem);
        }
        if(allowMove){
            obj.tabulator.replaceData(data);
        }
        obj.profitStackFields = data;
        obj.forceUpdate();
    }
    //copied from old profit stack mapping
    onAddParentRow(lineType) {
        let obj = this;
        obj.props.setNotSavedWarning(true, true);
        let newRow = {};
        let data = obj.tabulator.getData();//copyObjectValues(this.state.profitStackFields);
        clearTimeout(this.replaceDataTimeout);


        var costKey = this.generateUniqueCostKey(obj.state.profitStackFields);
        var newId = this.generateUniqueIdDB(obj.state.profitStackFields);
        newRow = {
            [_name]: "",
            [_costKey]: costKey + "Insert",
            [_parentCostKey]: "201",
            [_id]: newId.toString(),
            [VECTOR_MAPPING.ROW_STATUS_VALUES.NEW]: "1",
            level: 1,
            [PS_MAPPING.FIELDS.IS_NOT_AC]: false,
            returnName: "",
            [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.NEW,
            [PS_MAPPING.FIELDS.LEADING_PSS_ID]: "",
            [_costType]: lineType,
            [_attribute]: "",
            [_attributeFunction]: "",
            [_attributeType]: "",
            [PS_MAPPING.FIELDS.ACCRUAL_STATUS]: ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED,
            [_deleted]: "false",
        };

        if (lineType === costtype.attribute) {
            newRow[_inProfitMap] = true;
            newRow[_excludeFromPs] = false;
            newRow[_includeInPMTotals] = true;
        }

        if (lineType === costtype.standard) {
            var index = data.findIndex(e => e[_returnName] === PSL_RETURN_NAMES.NET_PROFIT);
            if (index > 0) {
                data.splice(index, 0, newRow);
            }
        } else {
            if (lineType === costtype.calculated) {
                newRow[_mapFormula] = []
            }
            data.push(newRow);
        }

        if (lineType === costtype.calculated) {
            let netprofitCK = getEmbeddedChild(data, _children, _returnName, PSL_RETURN_NAMES.NET_PROFIT);
            netprofitCK = !!netprofitCK ? netprofitCK[_costKey] : "";
            data = this.reOrderLine(data, netprofitCK, newRow, false);
        }

        this.setState({
            profitStackFields: copyObjectValues(data),
            originalData: copyObjectValues(data),
            isChanged: true
        }, function () {
            obj.replaceDataTimeout = setTimeout(function () {
                //replace data after user hasn't clicked on add for 500ms
                obj.tabulator.replaceData(data);
            }, 500)
        });

    }
    reOrderLine(pssFields, toCostKey, line, insertBefore = false) {
        if (toCostKey === line[_costKey]) {
            return pssFields;   //if source and destination are the same, do not enter loop
        }

        var obj = this;
        var spliced = false;
        var added = false;
        var index = 0;

        while ((!spliced || !added) && index < pssFields.length) {
            if (pssFields[index][_costKey] === line[_costKey]) {
                pssFields.splice(index, 1);     //when encountering this element, remove it from list
                spliced = true;
                index--; //re-adjust index after splicing
            } else if (pssFields[index][_costKey] === toCostKey) {
                if (insertBefore) {
                    pssFields.splice(Number(index), 0, line);   //when encountering the target costkey, push the element before it if insertBefore = true
                } else {
                    pssFields.splice(Number(index) + 1, 0, line);   //when encountering the target costkey, push the element behind it
                }
                added = true;
                index++; //re-adjust index after splicing
            } else if (pssFields[index][_children]) {
                obj.reOrderLine(pssFields[index][_children], toCostKey, line);
            }
            index++;
        }

        return pssFields;
    }

    /**
     * copied from profitStackMapping
     * @param {*} file
     * @param {*} item
     * @param {*} mappedLines
     * @returns
     */
    isDisabledToggleButton(file, item, mappedLines) {
        if (!item || (!!item && item[_mappingException] !== ANCILLARY)) {
            return false;
        }
        var ancColumns = this.props.allAncillaryColumns;
        if (!item[_isMatched]) {
            var pssSiblings = item[_leadingID] ? this.getSiblings(item[_leadingID], this.state.mappedLines).filter(e => e[_isMatched] && e[_file]) : [];      //fetching siblings of leading PS Line
            for (let e in pssSiblings) {
                var sibling = pssSiblings[e];
                if (sibling[_driverType] === _metric && this.props.exceptionMetricsRaw) {
                    var metric = this.props.exceptionMetricsRaw.filter(e => e.value === sibling[_file]);
                    if (metric.length > 0 && metric[0].is_cost_center === 'false') {
                        return true;
                    }
                } else {
                    var columns = ancColumns.filter(el => el.file_type === sibling[_file]);
                    var costCenterCol = columns.filter(el => el.name === cost_center);
                    if (costCenterCol.length === 0) {
                        return true;
                    }
                }
            }
            return false;
        } else {
            var ancFile = file;
            if (!file && !!item) {
                item = this.getLeadingItem(item[_leadingID], mappedLines);
                ancFile = item[_file];
            }
            if (!!item && item[_driverType] === _metric && this.props.exceptionMetricsRaw) {
                var metric = this.props.exceptionMetricsRaw.filter(e => e.value === ancFile);
                if (metric.length > 0 && metric[0].is_cost_center === 'true') {
                    return false;
                } else {
                    return true;
                }
            } else {
                var columns = ancColumns.filter(el => el.file_type === ancFile);
                var costCenterCol = columns.filter(el => el.name === cost_center);
                return costCenterCol.length === 0;
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} leadingPssId
     * @param {*} mappedLines
     * @returns
     */
    getSiblings(leadingPssId, mappedLines) {
        var siblings = [];
        mappedLines.map(function (item) {
            if (item[_leadingID] === leadingPssId) {
                item[_isMatched] = parseBoolean(item[_isMatched]);
                siblings.push(item);
            }
        });
        return siblings;
    }

    /**
     * copied from profitStackMapping
     * @param {*} leadingId
     * @param {*} mappedLines
     * @returns
     */
    getLeadingItem(leadingId, mappedLines) {
        mappedLines = mappedLines ? mappedLines.filter(el => el[_id] === leadingId) : this.state.mappedLines.filter(el => el[_leadingID] === leadingId);
        for (let e in mappedLines) {
            let file = mappedLines[e][_file];
            if (file) {
                return mappedLines[e];
            }
        }
        return "";
    }


    /**
     * copied from profitStackMapping
     * @param {*} rows
     * @param {*} expandAll
     */
    reExpandCollapsedRows(rows, expandAll) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows('active');
        allRows.forEach(function (row) {
            let isExpanded = row._row.modules.dataTree.open;
            if ((obj.expandedRowsCostkeys.indexOf(row.getData()[_costKey]) !== -1 && (!isExpanded || obj.redrawTable)) ||
                (row.getData()[_costKey] && !isExpanded && expandAll)) {
                //!isExpanded - if row is already expanded, do not retry to expand
                //if redrawTable = true because if a row has been moved, it is expanded in tabulator data, but not in the table, so re-expand anyways
                row.treeExpand();
                obj.reExpandCollapsedRows(row.getTreeChildren(), expandAll);
            }
        })
    }

    /**
     * copied from profitStackMapping
     * @param {*} period
     * @param {*} manageExclusions
     */
    getMappedLines = (period,fetchAmounts,callback) => {
        var obj = this;
        var query = {
            action: "getMappedLines",
            scenario_id: this.props.scenarioId,        
        }
        let onThenCallback = (data) => {
            if (data) {
                let parsedTotals = obj.state.periodTotals ? obj.state.periodTotals.filter(e => e.time_periods === obj.props.selectedPeriod.value) : [initialPeriodTotal];
                let lines = obj.sortMappedLines(data.data);
                lines.map(line => {
                    if (line[_mapFormula]) {
                        line[_mapFormula] = tryParse(line[_mapFormula], [])
                    }
                });

                let tempState = {
                    mappedLines: lines,
                    originalMappedLines: copyObjectValues(data.data),
                    dataTotals: parsedTotals,
                    dataTotals_id: shortid.generate(),
                }

                obj.setState(tempState, function () {
                    if(fetchAmounts){
                         obj.parseAmount(period);
                    }
                    if(callback && typeof callback === "function"){
                        callback();
                    }
                        
                });
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchAncillaryColsAmounts",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }
    removeItemsForTranscFromMappedLines = (newMappedLines) => {
        newMappedLines.map(item => {
            if(item.mappingException === TRANSACTION){ // We don't need those parameters for transaction
                delete item.ancillaryFilter;
                delete item.subType;
                delete item.driver_type;
            }
        })
    }
     //This is only for lines used in split percentages,are matched,not leading lines and not mapped to none
     fixInfoForSPMatchedLines = (mappedLines) => {
        let newMappedLines = mappedLines;
        this.removeItemsForTranscFromMappedLines(newMappedLines)
        let linesWithoutSubtypes = mappedLines.filter(e=>e.split_percentage && e.split_percentage!== "" && !isNaN(e.split_percentage) && Math.ceil(e.split_percentage) !== 100 && (!e.subType || e.subType === "" || !e.ancillaryFilter || e.ancillaryFilter === ""  || !e.driver_type || e.driver_type === "") && parseBoolean(e.is_matched) && e.costKey !== e.pssLeadingCostKey && e.mappingException !== 'NONE' && e.costtype === costtype.standard);
        newMappedLines = newMappedLines.filter(e=>!linesWithoutSubtypes.map(f=>f.costKey).includes(e.costKey))
        for(let line in linesWithoutSubtypes) {
            let leadingLine = mappedLines.filter(e=>e.costKey === linesWithoutSubtypes[line].pssLeadingCostKey)[0];
            linesWithoutSubtypes[line].subType = leadingLine.subType;
            linesWithoutSubtypes[line].ancillaryFilter = leadingLine.ancillaryFilter;
            linesWithoutSubtypes[line].driver_type = leadingLine.driver_type;
        }
        newMappedLines = newMappedLines.concat(linesWithoutSubtypes);
        return newMappedLines;
    } 
    
    abortParseAmountRequest = () => {
        //cancel request
        this.controller.abort()
        //Re initialize controller after canceling
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }

    /**
     * copied from profitStackMapping
     * @param {*} period
     * @param {*} manageExclusions
     * @param {*} loader
     */
    parseAmount(period, mappedLines) {
        //its false to show full loader
        var obj = this;
        let tempState = {};
        if( obj.isParsingAmount ) {
            obj.abortParseAmountRequest()
        }
        obj.isParsingAmount = true;
       
        obj.tabulator.replaceData(obj.tabulator.getData());
        obj.setScrollingPosition();
        var lines = mappedLines ? mappedLines : this.state.mappedLines;
        lines = this.sortMappedLines(obj.fixInfoForSPMatchedLines(lines));
        var flippedCombinations = this.props.getFlippedCombinations();
        var query = {
            action: "parseAmount",
            mappedLines: JSON.stringify(lines),
            selectedPeriods: period  ? period : this.props.getSelectedPeriods(),
            scenario_id: this.props.scenarioId,
            costKey: this.props.costKey,
            clientName: this.props.clientName,
            flippedCombinaitionsUI: flippedCombinations.join("','")
        }
        let onThenCallback = (data) => {
            obj.hasFetchedAmount = true;
            if (data) {
                let isYearBuilt = parseBoolean(tryParse(data.isYearBuilt));
                data = tryParse(data.data);
                var mappedLines = obj.state.mappedLines;
                var tempData = obj.tabulator.getData();
                obj.isParsingAmount = false;
                for (let elt in mappedLines) {

                    let filtered = data.filter(e => e["cost_key"]?.replace('Insert', '') === mappedLines[elt][_costKey].replace('Insert', '') ||
                        e["costkey"]?.replace('Insert', '') === mappedLines[elt][_costKey].replace('Insert', ''));
                    mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT] = 0;
                    if (filtered.length > 0) {
                        mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT] = filtered[0].amount;
                        mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT_DETAILS] = filtered[0][PS_MAPPING.FIELDS.AMOUNT_DETAILS];
                    }
                    tempData = obj.updateChildAttr(tempData, mappedLines[elt][_costKey], [PS_MAPPING.FIELDS.AMOUNT], mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT]);
                }
                tempState.mappedLines = obj.sortMappedLines(mappedLines);
                tempState.isYearBuilt = parseBoolean(isYearBuilt);
                obj.setState(tempState, () => {
                    obj.tabulator.replaceData(tempData);
                })
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "parseAmount",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.signal]: obj.signal,
            [FETCHAPI_PARAMS.periods]: period  ? period : this.props.getSelectedPeriods()
        };
        this.fetchAPI(fetchOptions);
    }


    /**
     * copied from profitStackMapping
     */
    init = () => {
        this.getMappedLines([],false,()=> {
        this.getProfitStackFields();
        });

    }

    onDeleteRow(row) {
        this.hideModal();
        let tempState = {};
        tempState.mappedLines = this.state.mappedLines;
        tempState.profitStackFields =  this.tabulator.getData();
        tempState.isChanged = true;
        tempState.newCostKey = "";
        this.updateRemovalOfMapping(row, true,tempState);
        if(this.props.fetchAmounts){
            this.parseAmount(this.props.getSelectedPeriods());
        }
       
    }

    /**
     * to collapse all not collapsed parent
     * @param {*} rows
     * @param {*} mainCall
     */
    // collapseAll(rows=this.tabulator.getRows(), mainCall=true){
    //     const _this = this;
    //     // rows.forEach(function(row) {
    //     //     row.treeCollapse();
    //     //     if(row.getData().expanded === true){
    //     //         row.getData().expanded = false;
    //     //     }
    //     //     if(row.getData().children){
    //     //         _this.collapseAll(row.getTreeChildren())
    //     //     }
    //     // });
    //     _this.tabulator.options.dataTreeStartExpanded = false;
    //     _this.tabulator.setData(_this.tabulator.getData())

    //     if(mainCall) {  //do not set state for every child that is collapsed, only once
    //         _this.setState({
    //             isAllExpended :false
    //         });
    //     }
    // }

    /**
     * to expand all not expanded parent
     * @param {*} rows
     * @param {*} mainCall
     */
    // expandAll(rows=this.tabulator.getRows(), mainCall=true){
    //     const _this = this;
    //     // rows.forEach(function(row) {
    //     //     row.treeExpand();
    //     //     if(row.getData().expanded === false){
    //     //         row.getData().expanded = true;
    //     //     }
    //     //     if(row.getData().children){
    //     //         _this.expandAll(row.getTreeChildren())
    //     //     }
    //     // });
    //     _this.tabulator.options.dataTreeStartExpanded = true;
    //     _this.tabulator.setData(_this.tabulator.getData())

    //     if(mainCall) {  //do not set state for every child that is collapsed, only once
    //         _this.setState({
    //             isAllExpended :true
    //         });
    //     }
    // }
    expandCollapseAll() {
        const _this = this;
        _this.tabulator.options.dataTreeStartExpanded = !_this.state.isAllExpended;
        _this.tabulator.setData(_this.tabulator.getData());
        
        if(_this.state.isAllExpanded) {
            _this.expandedRowsCostkeys= linearizeHierarchy(_this.tabulator.current.getData(),_children).filter(e => (e.children || (e.children && e.children.length > 0))).map(e=>e[_costKey]);
        }else {
            _this.expandedRowsCostkeys = []
       }
        _this.setState({
            isAllExpended :!_this.state.isAllExpended
        });
    }

    /**
     * called on checking the flip sign checkbox inside the warning modal
     */
    changeFlipCheckedState=()=>{
        this.setState({
            flipChecked: !this.state.flipChecked
        })
    }

    setScrollingPosition = () => {
        $('#PSLTable .tabulator-tableHolder').animate({
            scrollTop: globaltop
        },100);
    }

    hideModal = () => { 
      this.setOpenConfirmDeleteRowDialog(false)
        $('#PSLTable .tabulator-tableHolder').animate({
            scrollTop: globaltop
        },500);
    }

    checkHasChildren = (data) => {
        let _this = this;
        data.forEach((psl) => {
            if (!psl.children) {
                return;
            }
            if (psl["children"].length === 0) {
                delete psl["children"]
                psl["isExpandable"] = false
                psl["is_expandable"] = false
                this.tabulator.replaceData(this.tabulator.getData());
                return;
            }
            _this.checkHasChildren(psl.children);
        });
    }
    setOpenConfirmDeleteRowDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openConfirmDeleteRowDialog: isOpen,
      })
    }

    confirmDeleteRowDialogActions = () => {
      return (
        <>
          <Button
            label={MESSAGES.modal.buttons.delete}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.onDeleteRow(this.state.row)}
          />
          <Button
            label={MESSAGES.modal.buttons.cancel}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.hideModal()}
          />
        </>
      )
    }
    setOpenRenamePslDialog = (isOpen, msg) => {
      let _this = this;
      _this.setState({
        openRenamePslDialog: isOpen,
        warningMessage: msg
      })
    }

  renamePslDialogActions = () => {
    return (
      <>
        <Button
          label={MESSAGES.modal.buttons.rename}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.renameLine(this.tabulator.getData())}
        />
        <Button
          label={MESSAGES.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.cancelRenamingLine()}
        />
      </>
    )
  }
  setWarningDialogOpen = (isOpen, msg) => {
      let _this = this;
      _this.setState({
        openWarningDialog: isOpen,
        warningMessage: msg
      })
    }

  openWarningDialogActions = () => {
    return (
      <Button
        label={MESSAGES.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setWarningDialogOpen(false)}
      />
    )
  }
  setAmountDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openAmountDialog: isOpen,
    })
  }

  openAmountDialogActions = () => {
    return (
      <Button
        label={"Close"}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setAmountDialogOpen(false)}
      />
    )
  }
  openAmountDialogContent = () => {
    let {amount} = this.state;
    return (
      <div className="amount-table ">
        <ReactTable
          data={amount}
          columns={[
            {
              Header: this.props.costCenter + "Key",
              accessor: "costCenter",
              Cell: row => (
                <div style={{ textAlign: "center" }} id="fields">{row.original.costCenter}</div>
              )
            },
            {
              Header: "Amount",
              id: "amount",
              accessor: d => Number(d.amount),
              Cell: row => (
                <div style={{ textAlign: "center" }} id="fields">{formatValReact(row.original.amount, FormatTypes.AMOUNT)}</div>
              )
            }
          ]}
          sortable={true}
          defaultPageSize={5}
          showPagination={true}
        />
      </div>
    )
  }

    render() {
        let _this = this;
        _this.reExpand = true;
       
        return (
            <React.Fragment>
                {/* <Modal hideModal={this.hideModal} ref={r=>this.modalRef = r} trueText={lang.modal.buttons.ok}/> */}
                <div id="toastPSLTable" className={this.state.isInfo ? "toast toast-info" : this.state.isError ? "toast toast-fail" : "toast toast-success"}>
                    <div id="desc"><i className={"fa-lg fas" +(this.state.isInfo ? " fa-info-circle uk-margin-small-right blueText" : this.state.isError ? " fa-minus-circle uk-margin-small-right uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                </div>
                
                <div className="pi-box-shadow psl-container uk-border">
                    <div className="psl-header">
                        <span className="uk-text-bold uk-text-xmedium">{this.props.title}</span>
                        <div className="uk-flex uk-flex-bottom">
                            {this.state.isAllExpended ?
                                <Button 
                                    id="Collapse_PS"
                                    label={"Collapse all"}
                                    title={"Collapse all"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-display-none"
                                    leftIcon={<i className={"fal fa-minus-square fa-lg"} aria-hidden="true" />}
                                    onBtnClick={() => {  this.expandCollapseAll()}}
                                /> 
                                : 
                                <Button 
                                    id="Expand_PS"
                                    label={"Expand all"}
                                    title={"Expand all"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    leftIcon={<i className={"fal fa-plus-square fa-lg"} aria-hidden="true" />} 
                                    onBtnClick={() => { this.expandCollapseAll()}}
                                /> 
                            }
                            {this.state.tabulatorFiltered ?
                                <a className="text-link uk-margin-default-left" href="" uk-tooltip={MESSAGES.profit_stack_link_clear_filter_tooltip} onClick={(e) => { this.props.clearGLTableLinks(); this.filterTableOnLinks(); e.preventDefault();}}>{MESSAGES.ui_filter.titles.clear_filter}</a>
                                : ""
                            }
                        </div>
                    </div>
                    <div id="PSLTable" ref="mainTable"/>
                </div>
                <Modal
                  id={"delete-row-used-in-calculated-dialog"}
                  openDialog={this.state.openDeleteRowUsedDialog}
                  bodyContent={this.deleteRowUsedDialogContent}
                  dialogActions={this.deleteRowUsedDialogActions}
                  closeClick={() => this.setOpenDeleteRowUsedDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"confirm-delete-line-dialog"}
                  openDialog={this.state.openConfirmDeleteRowDialog}
                  bodyContent={() => <h4>{lang.calc_cols.delete_confirmation} {this.state.row && Object.getOwnPropertyNames(this.state.row).length > 0 ? this.state.row[PS_MAPPING.FIELDS.NAME] : ""} ?</h4>}
                  dialogActions={this.confirmDeleteRowDialogActions}
                  closeClick={() => this.setOpenConfirmDeleteRowDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"rename-psl-dialog"}
                  openDialog={this.state.openRenamePslDialog}
                  bodyContent={() => <h4>{this.state.warningMessage}</h4>}
                  dialogActions={this.renamePslDialogActions}
                  closeClick={() => this.setOpenRenamePslDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"warning-dialog"}
                  openDialog={this.state.openWarningDialog}
                  bodyContent={() => <h4>{this.state.warningMessage}</h4>}
                  dialogActions={this.openWarningDialogActions}
                  closeClick={() => this.setWarningDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"amount-dialog"}
                  openDialog={this.state.openAmountDialog}
                  bodyContent={this.openAmountDialogContent}
                  dialogActions={this.openAmountDialogActions}
                  closeClick={() => this.setAmountDialogOpen(false)}
                  size={DIALOG_SIZE.LARGE}
                />
            </React.Fragment>
        );

    }

}

export default PSLTable;