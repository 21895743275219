import { DialogActions } from '@mui/material';
import 'jquery';
import React, { Component } from 'react';
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DATASET, DIALOG_SIZE, DROPDOWN_TYPE, FILTER, Formats, HEADER_ELEMENT, RAW_ITEMS, SIZES, VECTOR_STAGING_ATTRIBUTES } from '../../class/constants.js';
import { getActualYear, getPeriodFromDate, getPeriodTextFromMonth } from '../../class/date.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { copyObjectValues, findOptionByKey } from '../../class/utils.js';
import TileCalendarContainer from "../quarterCalendar/TileCalendarContainer";
import Button from '../../newComponents/Button.js';
import Dropdown from '../../newComponents/DropDown.js';
import Input from '../../newComponents/Input.js';
import Modal from '../../newComponents/Modal.js';
import { lang } from '../../language/messages_en.js';

const _none = "none";
const _None = "None";
const budgetingColumnsTypes = {
    VECTOR:"vector",
    CALCULATED:"calculated"
}
/**
 * configure Budget
 * @author [Bassem]
 */
class ConfigureBudget extends Component {
		
	constructor(props) {
		super(props);
       
		this.state = {
            usedMetricsTitle: ""
        };
    }

    componentDidUpdate(prevProps){
        if(((!prevProps.token  && this.props.token) || (prevProps.token && this.props.token && prevProps.token !== this.props.token)) && this.props.periods && this.props.allBudgetingColumns && this.props.allNumericTransactionColumns){ //Each time we click configure we need to getBudgetConfigurations
            this.setState({
                budgetingDatasourcesOptions:this.formatBudgetingDatasources(this.props.allBudgetingColumns),
                yearsOptions: this.extractYearsFromPeriods(this.props.periods),
                trxColumnsOptions:this.formatTransactionColumns(this.props.allNumericTransactionColumns).concat([{value:_none,label:_None}]).filter((v,i,a)=>a.findIndex(v2=>(v2.value===v.value))===i),
                budgetingDatasource :"",
                referenceYear : "",
                nextCustomStartDate : "",
                nextCustomEndDate : "",
                budgetStartPeriodId: "",
                addedBy : "",
                sortedBy :"",
                mappings :[],
                selectedBudgetingGl:""
            },()=>{
                this.getBudgetConfigurations();
                this.getUsedMetrics();
            })
        }
    }
    componentDidMount(){
        let _this = this;
        $(document).on("click", function(e){
            if($(e.target).parents().length !==0
                && $(e.target).parents(".quarter-calendar").length === 0 && $(e.target).parents("#selection-range-container").length === 0 && _this.state.showCalendar) {
                _this.setState({showCalendar: false});
            }
        });
    }

    formatVectors = (vectors) => {
        let vectorOptions = [];
        let attributes = [FILTER.VALUES.FIELD.KEY,FILTER.VALUES.FIELD.NAME,FILTER.VALUES.FIELD.NUMBER];
        let vectorsData = vectors.data;
        for(let e in vectorsData){
            for(let f in attributes){
                let  obj = {};
                obj.value = vectorsData[e][VECTOR_STAGING_ATTRIBUTES.NAME].replaceAll(" ","")+attributes[f];
                obj.label = vectorsData[e][VECTOR_STAGING_ATTRIBUTES.NAME].replaceAll(" ","")+attributes[f];
                vectorOptions.push(obj)
            }
        }
        return vectorOptions;
    }

    getBudgetConfigurations = () =>{
        const _this = this;
        var query = {
            action: "getBudgetConfigurations",
            scenario_id:this.props.budgetScenarioId
        }
        let onThenCallback = (data)=>{
            if (data && data.data) {
                 let configuration = data.data.configuration;
                 let mappings = data.data.mappings;
                 let tempState = {};
                 let budgetingGlOptions = [];
                 data.glSubtypes.map(option=>{
                    budgetingGlOptions.push({ label: option.display_file_type, value: option.raw_file_subtype_id});
                 });
                 tempState.budgetingGlOptions = budgetingGlOptions;

                 if(Object.keys(configuration).length === 0){ //If No configurations yet put default value for everything
                    tempState.budgetingDatasource = _this.state.budgetingDatasourcesOptions[0] ? _this.state.budgetingDatasourcesOptions[0].value: "";
                    tempState.referenceYear = _this.state.yearsOptions[0] ? _this.state.yearsOptions[0].value : "";
                    tempState.nextCustomStartDate = _this.props.periods[0] ? _this.props.periods[0].start_date : "";
                    tempState.nextCustomEndDate = _this.props.periods[0] ? _this.props.periods[0].end_date : "";
                    tempState.budgetStartPeriodId = tempState.budgetEndPeriodId = _this.props.periods[0] ? _this.props.periods[0].period_id : "";
                    tempState.addedBy = "QuantityShipped";
                    tempState.sortedBy = "Revenue";
                    tempState.mappings = _this.initiateMappingsState(tempState.budgetingDatasource)
                    tempState.clientScenarioBudgetConfigId = 0;
                    tempState.selectedBudgetingGl = budgetingGlOptions[0] ? budgetingGlOptions[0].value : "";
                 } else {
                    tempState.budgetingDatasource = configuration.trx_datasource_id;
                    tempState.referenceYear = configuration.reference_year;
                    tempState.nextCustomStartDate = _this.props.periods.filter(e=>e.period_id === configuration.budget_start_period_id)[0].start_date;
                    tempState.nextCustomEndDate = _this.props.periods.filter(e=>e.period_id === configuration.budget_end_period_id)[0].end_date;
                    tempState.budgetStartPeriodId = configuration.budget_start_period_id;
                    tempState.budgetEndPeriodId =  configuration.budget_end_period_id;
                    tempState.addedBy = configuration.added_by;
                    tempState.sortedBy = configuration.sorted_by;
                    tempState.mappings = _this.formatMappings(mappings);   
                    tempState.clientScenarioBudgetConfigId = configuration.client_scenario_budget_config_id;
                    tempState.selectedBudgetingGl =  configuration.budget_gl_subtype;
                 }

                 let vectors = copyObjectValues(data.data.vectors)
                 let vectorOptions = _this.formatVectors(vectors);
                 tempState.vectorOptions =  vectorOptions.concat([{value:_none,label:_None}])
                _this.setState(tempState)
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getBudgetConfigurations",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.BUDGETING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    getUsedMetrics = () =>{
        const _this = this;
        var query = {
            action: "getUsedMetrics",
            scenario_id: this.props.budgetScenarioId
        }
        let onThenCallback = (data)=>{
            if (data && data.metrics) {
                let tempState = [];
                if(data.metrics.length > 0){
                    tempState.metrics = data.metrics;
                    tempState.usedMetricsTitle = lang.budgeting.configure_budgeting.metrics_using_a_gl_title;
                } else {
                    tempState.metrics = false;
                    tempState.usedMetricsTitle = lang.budgeting.configure_budgeting.no_metrics_using_a_gl_title;
                }
                this.setState(tempState);
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getUsedMetrics",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.BUDGETING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    launchToast() {
        $("#toastConfigureBudget").addClass("show");
        setTimeout(function(){
            $("#toastConfigureBudget").removeClass("show");
        }, 4000);
    }

    saveBudgetConfigurations = () =>{
        const _this = this;
        var query = {
            action: "saveBudgetConfigurations",
            scenario_id:this.props.budgetScenarioId,
            clientScenarioBudgetConfigId:this.state.clientScenarioBudgetConfigId,
            trxDatasourceId:this.state.budgetingDatasource,
            budgetStartPeriodId: this.state.budgetStartPeriodId,
            budgetEndPeriodId: this.state.budgetEndPeriodId,
            referenceYear:this.state.referenceYear,
            addedBy:this.state.addedBy,
            sortedBy:this.state.sortedBy,
            mappings:JSON.stringify(this.state.mappings),
            budgetingGl:this.state.selectedBudgetingGl
        }
        let onThenCallback = (data)=>{
            if (data) {
                if(data.result === "SUCCESS"){
                    _this.setState({
                        message:lang.budgeting.configure_budgeting.message,
                        clientScenarioBudgetConfigId:data.clientScenarioBudgetConfigId
                    },()=>{
                        _this.launchToast();
                    })
                }
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveBudgetConfigurations",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.BUDGETING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    getBudgetQuery = () =>{
        const _this = this;
        var query = {
            action: "getBudgetQuery",
            scenario_id:this.props.budgetScenarioId
        }
        let onThenCallback = (data)=>{
            if (data) {
                if(data.result === "SUCCESS"){
                    _this.setState({
                        // message:lang.budgeting.configure_budgeting.message,
                        // clientScenarioBudgetConfigId:data.clientScenarioBudgetConfigId
                    },()=>{
                        _this.launchToast();
                    })
                }
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getBudgetQuery",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.BUDGETING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    generateBudgetFiles = () =>{
        const _this = this;
        var query = {
            action: "generateBudgetFiles",
            scenario_id:this.props.budgetScenarioId
        }
        let onThenCallback = (data)=>{
            if (data) {
                if(data.result === "SUCCESS"){
                    _this.setState({
                        // message:lang.budgeting.configure_budgeting.message,
                        // clientScenarioBudgetConfigId:data.clientScenarioBudgetConfigId
                    },()=>{
                        _this.launchToast();
                    })
                }
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "generateBudgetFiles",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.BUDGETING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    extractYearsFromPeriods = (periods) => {
        let _key = "value";
        let years = []
        for(let e in periods){
            let yearOption  = {}
            let year = periods[e].value.split("P")[0];
            yearOption.value = year;
            yearOption.label = year;
            years.push(yearOption)
         }
        years = [...new Map(years.map(item =>
            [item[_key], item])).values()];
         
        return years;
    }

    formatTransactionColumns = (trxColumns) => {
        let trxColumnsOptions = []
        for(let e in trxColumns){
            let obj = {}
            obj.value = trxColumns[e].name.replace(" ","");
            obj.label = trxColumns[e].name;
            trxColumnsOptions.push(obj);
        }
        return trxColumnsOptions;
    }

    formatBudgetingDatasources = (budgetingDatasources) => {
        let budgetingDatasourcesOptions = [];
        let uniqueBudgetingObjArray = [...new Map(budgetingDatasources.map((item) => [item[RAW_ITEMS.SUBTYPE_ID], item])).values()];
        for(let e in uniqueBudgetingObjArray){
            let obj = {};
            obj.value = uniqueBudgetingObjArray[e].raw_file_subtype_id;
            obj.label = uniqueBudgetingObjArray[e].display_file_type;
            budgetingDatasourcesOptions.push(obj);
        }
        
        return budgetingDatasourcesOptions;
    }

    handleElementChange = (type,e,index) => {
        let tempState = {}
        if(e!== null) {
            if(type === lang.budgeting.configure_budgeting.titles.trx_datasource) {
                tempState.budgetingDatasource = e.value;
                tempState.mappings = this.initiateMappingsState(e.value)
            } else if (type === lang.budgeting.configure_budgeting.titles.budget_range){
                // let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")):"";
                let selectedYears = e.filter(year => year.isSelected);
                let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
                let startPeriod = sortedSelectedYears[0];
                let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
                // let startDate = new Date(periods.filter(e=>e.value === startPeriod)[0].start_date);
                // let endDate = new Date(periods.filter(e=>e.value === endPeriod)[0].end_date);
                let startDate = new Date(selectedYears.filter(e => e.year + e.value === startPeriod)[0].startDate);
                let endDate = new Date(selectedYears.filter(e => e.year + e.value === endPeriod)[0].endDate);
                let budgetStartPeriodId  = selectedYears.filter(e => e.year + e.value === startPeriod)[0].periodId;
                let budgetEndPeriodId  = selectedYears.filter(e => e.year + e.value === endPeriod)[0].periodId;
                tempState.budgetStartPeriodId = budgetStartPeriodId;
                tempState.budgetEndPeriodId = budgetEndPeriodId;
                tempState.nextCustomStartDate = startDate;
                tempState.nextCustomEndDate = endDate;
            } else if (type === lang.budgeting.configure_budgeting.titles.reference_year){
                tempState.referenceYear = e.value;
            } else if(type === lang.budgeting.configure_budgeting.titles.lines_to_be_added_based_on){
                tempState.addedBy = e.value;
            } else if(type === lang.budgeting.configure_budgeting.titles.lines_to_be_sorted_based_on){
                tempState.sortedBy = e.value;
            } else if(type === lang.budgeting.configure_budgeting.titles.map_trx_datasource){
                tempState.mappings = copyObjectValues(this.state.mappings);
                tempState.mappings[index].configColumn = e.value;
            } else if(type === lang.budgeting.configure_budgeting.titles.budgeting_gl){
                tempState.selectedBudgetingGl = e.value;
            }
            this.setState(tempState);
        }
    }

    initPeriodYears = () => {
        let periodYears = [];
        this.minYear = this.props.periods ? Number(this.props.periods[this.props.periods.length - 1].value.split("P")[0]) : this.minYear;
        this.maxYear = this.props.periods ? Number(this.props.periods[0].value.split("P")[0])  : this.maxYear;
        for (let i = this.minYear; i <= this.maxYear; i++) {
            let periods = this.props.allPeriods ? Array.from(new Set(this.props.allPeriods.filter((f) => Number(f.year) === i))) : [];
           
            periods
                .sort((a, b) => (a.value > b.value ? 1 : -1))
                .forEach((period) =>
                    periodYears.push({
                        year: Number(period.year),
                        value: "P" + period.value.split("P")[1],
                        isSelected: false,
                        isDisabled: false,
                        startDate: period.start_date,
                        endDate: period.end_date,
                        periodId:period.period_id
                    })
                );
        }
        return periodYears;
    }

    checkPeriodAvailability = (period) => {
        let availablePeriod = getPeriodFromDate(period);
        for (let i = 0; i < 20; i++) {
            if (this.isPeriodActive(availablePeriod)) {
                availablePeriod = period;
                break;
            } else {
                let time = period.setMonth(period.getMonth() - 1);
                availablePeriod = getPeriodFromDate(new Date(time));
            }
        }
        return availablePeriod
    }

    periodTileContent = (year, period) => {
        let yearParam = typeof year === 'object' ? year.year + year.value : year+period;
        return (
            <p uk-tooltip={
                !this.isPeriodActive(yearParam) ? lang.period_not_activated : "title:" 
              }>{ typeof year === 'object' ? year.value :period}
            </p>
        )
    }

    isPeriodTileDisabled = (year, period) => {
        let yearParam = typeof year === 'object' ? year.year + year.value : year+period;
        return !this.isPeriodActive(yearParam);
    }

    isPeriodActive=(periodDate)=> {
        if(typeof periodDate === "string" && this.props.periods) {
            let periods = this.props.periods.map(p => p.value);
            return periods.includes(periodDate);
        }
        
        for(let opt in this.props.periods) {
            let startD = new Date(this.props.periods[opt][DATASET.START_DATE]);
            let endD = new Date(this.props.periods[opt][DATASET.END_DATE]);

            if(isNaN(startD.getTime()) || isNaN(endD.getTime())) {
                continue;
            }

            //nullify the time to only compare the dates
            periodDate.setHours(0);
            periodDate.setMinutes(0);
            periodDate.setSeconds(0);
            periodDate.setMilliseconds(0);
            
            startD.setHours(0);
            startD.setMinutes(0);
            startD.setSeconds(0);
            startD.setMilliseconds(0);
            
            endD.setHours(0);
            endD.setMinutes(0);
            endD.setSeconds(0);
            endD.setMilliseconds(0);

            if(periodDate >= startD && periodDate <= endD) {
                return true;
            }
        }
        return false;
    }
    initiateMappingsState  = (budgetingDatasource) =>{
        let mappings = []
        if(this.props.allBudgetingColumns){
            let columns = this.props.allBudgetingColumns.filter(e=>e.raw_file_subtype_id === budgetingDatasource);
            for(let e in columns){
                let obj = {};
                obj.budgetColumn = columns[e][RAW_ITEMS.FIELD_NAME];
                obj.budgetColumnType = columns[e][RAW_ITEMS.FIELD_DATA_TYPE] === Formats.Numeric ? budgetingColumnsTypes.CALCULATED : budgetingColumnsTypes.VECTOR;
                obj.configColumn = _none;
                mappings.push(obj);
            }
        }
        return mappings;
    }
    formatMappings = (mappings) => {
        let newMappings = [];
        for(let e in mappings){
            let obj = {};
            obj.budgetColumn = mappings[e].budget_column;
            obj.budgetColumnType = mappings[e].budget_column_type;
            obj.configColumn = mappings[e].config_column;
            newMappings.push(obj);
        }
        return newMappings;
    }
    mappingDialogContent = () => {
        
        if(this.state.mappings && this.state.trxColumnsOptions && this.state.vectorOptions){
            let rows = this.state.mappings.map((item,index) => {
            let mappingOptions =  item.budgetColumnType === budgetingColumnsTypes.CALCULATED ? copyObjectValues(this.state.trxColumnsOptions):copyObjectValues(this.state.vectorOptions);
            return (
                    <div key={item.budgetColumn +"_"+item.configColumn+"_"+index} className="uk-flex uk-flex-middle uk-margin-default-bottom">
                                <label className="uk-text-xmedium width-170 uk-margin-small-right"
                                    htmlFor="select-set-attribute">{item.budgetColumn}</label>
                                <Dropdown
                                    id={"index_"+index}
                                    className="select-dataSet width-200 input__dropdown"
                                    value={findOptionByKey(mappingOptions,this.state.mappings[index].configColumn)}
                                    options={mappingOptions}
                                    onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.map_trx_datasource,e,index)}
                                    type={DROPDOWN_TYPE.INPUT}
                                />
                    </div>
                )
            })
            return  (
                <div style={{marginLeft:"1vw"}}>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <p className="uk-text-bold uk-text-decoration-underline uk-text-xmedium width-170 uk-margin-small-right">{lang.budgeting.configure_budgeting.mapping.columns}</p>
                        <p className="uk-text-bold uk-text-decoration-underline uk-text-xmedium width-170 uk-margin-small-right">{lang.budgeting.configure_budgeting.mapping.mapped}</p>
                    </div>
                    <div>{rows}</div>
                </div>
            )
        }
    }

    mappingDialogActions = () =>{
		return(
			<DialogActions className='margin-button '>
                 <Button 
                    label={"Cancel"}
                    aria-label="Close" 
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-padding-small-right uk-margin-default-right"
                    onBtnClick={this.onCancelClick}
                />
                <Button 
                    id ="save-new-arch" 
                    aria-label="Close"
                    label={"Submit"}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-padding-small-right uk-margin-default-right"                            
                    onBtnClick={this.onSubmitClick}
                />
			</DialogActions>
		)
	}

    usedMetricsDialogContent = () => {
        let _this = this;
        if (_this.state.metrics) {
          return (
            <div style={{ marginLeft: "1.5vw" }}>
              <div>
                {_this.state.metrics ? (
                    <div>
                        <div className="uk-text-bold uk-text-decoration-underline uk-text-xmedium uk-margin-small-right uk-margin-bottom">{lang.budgeting.configure_budgeting.metric_name}</div>
                     <div>
                     {_this.state.metrics.map(item => (
                        <div key={item.metric_name} className="uk-flex uk-flex-middle uk-margin-default-bottom">
                          <label className="uk-text-xmedium uk-margin-small-right" htmlFor="select-set-attribute">
                            - {item.metric_name}
                          </label>
                        </div>
                     ))}
                    </div>
                  </div>
                ) 
                : "" }
              </div>
            </div>
          );
        }
    }

    usedMetricsDialogActions = () =>{
		return(
			<DialogActions className='margin-button '>
                <Button 
                    label={"Close"}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    className="uk-padding-small-right uk-margin-default-Left"
                    onBtnClick={()=>{this.setUsedMetricsOpen(false)}}
                />
			</DialogActions>
		)
	}

    setMappingOpen = (value) => {
        let _this = this;
        if(value){
            this.setState({
                originalMappings: _this.state.mappings,
                mappingOpen:value
           }) 
        } else {
            this.setState({
                mappingOpen:value
           }) 
        }
        
    }

    setUsedMetricsOpen = (value) => {
        this.setState({
            usedMetrics:value
        }) 
     }

    onCancelClick = () => {
        let _this = this;
        _this.setState({
            mappings: _this.state.originalMappings
        });
        _this.setMappingOpen(false);
    }

    onSubmitClick = () => {
        this.setMappingOpen(false);
    }

    toggleCalendar = () =>{
        let showCalendar = !this.state.showCalendar
        this.setState({ showCalendar: showCalendar })
    }

    render() {
        let _this = this;
        let bannerHeight = $(".banner-component").height() ? $(".banner-component").height() + 25 : 0;
        let topValue = (window.innerHeight > 2000 ? 60 : 17) + bannerHeight;
        let calendarTop = $("#selection_range_input").length ? $("#selection_range_input").position().top+$("#selection_range_input").height() + topValue : 0;
    
        let startDate = this.state.nextCustomStartDate ? new Date(copyObjectValues(this.state.nextCustomStartDate)) : "";
        let endDate = this.state.nextCustomEndDate ? new Date(copyObjectValues(this.state.nextCustomEndDate)) : "";
        let nextCustomStartDate = this.state.nextCustomStartDate  ? this.checkPeriodAvailability(startDate) : "";
        let nextCustomEndDate = this.state.nextCustomEndDate  ? this.checkPeriodAvailability(endDate) : "";
        let inputValue = typeof nextCustomStartDate === 'string' ? nextCustomStartDate : (getActualYear(nextCustomStartDate) + getPeriodTextFromMonth(nextCustomStartDate));
        let endDateInput = typeof nextCustomEndDate === 'string' ? nextCustomEndDate : (getActualYear(nextCustomEndDate) + getPeriodTextFromMonth(nextCustomEndDate))
        if(inputValue !== endDateInput) {
            inputValue += " "+ lang.COMMON.TO.toLowerCase() +" "+ endDateInput;
        }
        
        this.minYear = _this.props.periods?.length ? Number(_this.props.periods[_this.props.periods.length - 1].value.split("P")[0]) : this.minYear;
        this.maxYear = _this.props.periods?.length ? Number(_this.props.periods[0].value.split("P")[0]) : this.maxYear;
        let startPeriod = typeof nextCustomStartDate === 'string' ? nextCustomStartDate : getPeriodFromDate(nextCustomStartDate);
        let endPeriod =typeof nextCustomEndDate === 'string' ? nextCustomEndDate : getPeriodFromDate(nextCustomEndDate);
        return (
                <div className="uk-padding-large">
                     <div id="toastConfigureBudget" className="toast toast-success">
                        <div id="desc"><i className={"fa-lg fas uk-margin-small-right fa-check-circle greenText"} aria-hidden="true"></i>{this.state.message}</div>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <p className="uk-text-bold uk-text-decoration-underline uk-text-xmedium width-170 uk-margin-small-right">{lang.budgeting.configure_budgeting.sections.configure_data}</p>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <label className="uk-text-xmedium width-170 uk-margin-small-right"
                            htmlFor="select-set-attribute">{lang.budgeting.configure_budgeting.titles.budgeting_gl}</label>
                        <Dropdown
                            id="select_custom_view"
                            className="select-dataSet width-200 input__dropdown"
                            value = {findOptionByKey(this.state.budgetingGlOptions, this.state.selectedBudgetingGl)}
                            options={this.state.budgetingGlOptions}
                            onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.budgeting_gl,e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        <p id="highlighted" className={"uk-margin-small-left"} style={{cursor:"pointer"}} onClick={()=>{this.setUsedMetricsOpen(true)}}>{lang.budgeting.configure_budgeting.metrics_using_a_gl}</p>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <label className="uk-text-xmedium width-170 uk-margin-small-right"
                            htmlFor="select-set-attribute">{lang.budgeting.configure_budgeting.titles.trx_datasource}</label>
                        <Dropdown
                            id="select_custom_view"
                            className="select-dataSet width-200 input__dropdown"
                            value={findOptionByKey(this.state.budgetingDatasourcesOptions, this.state.budgetingDatasource)}
                            options={this.state.budgetingDatasourcesOptions}
                            onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.trx_datasource,e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        <p id="highlighted" className={"uk-margin-small-left"} style={{cursor:"pointer"}} onClick={()=>{this.setMappingOpen(true)}}>Map Columns</p>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <label className="uk-text-xmedium width-170 uk-margin-small-right" id="selection_range_label">{lang.budgeting.configure_budgeting.titles.budget_range}</label>
                        <div className={`${(this.state.showCalendar ? "active-selection-range" : "")} uk-cursor-pointer`} id="selection-range-container" onClick={() => this.toggleCalendar()} onChange={() => { }}>
                            <i className="fa-lg fal fa-calendar-alt calendar-icon uk-display-flex" />
                            <Input readOnly autoComplete="off" id="selection_range_input" className="calendar-input form-control" value={inputValue} style={{width:"10.5vw"}} />
                        </div>
                        {this.state.showCalendar?
                            <div className={"uk-border-top-left-rounded uk-border-bottom-left-rounded uk-inline-clip quarter-calendar"}
                                    style={{ position: "fixed", zIndex: 25, top: calendarTop, left: $("#selection_range_input").length ? $("#selection_range_input").position().left :0}}>
                                <TileCalendarContainer
                                    minYear={this.minYear}
                                    maxYear={this.maxYear}
                                    years={this.initPeriodYears}
                                    nextStartTile={startPeriod}
                                    nextEndTile={endPeriod}
                                    handleElementChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.budget_range, e)}            
                                    type={HEADER_ELEMENT.PERIOD}
                                    columnNumber={3}
                                    onSelectCallback={()=>this.setState({showCalendar:false})}
                                    isTileDisabled={this.isPeriodTileDisabled}
                                    tileContent={this.periodTileContent}
                                    isYearsLimited
                                />
                            </div> 
                        : ""}
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <label className="uk-text-xmedium width-170 uk-margin-small-right"
                            htmlFor="select-set-attribute">{lang.budgeting.configure_budgeting.titles.reference_year}</label>
                        <Dropdown
                            id="reference_year"
                            className="select-dataSet width-200 input__dropdown"
                            value={findOptionByKey(this.state.yearsOptions,this.state.referenceYear)}
                            options={this.state.yearsOptions}
                            placeholder={"Choose year"}
                            onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.reference_year,e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                    <hr></hr>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <p className="uk-text-bold uk-text-decoration-underline uk-text-xmedium uk-margin-small-right">{lang.budgeting.configure_budgeting.sections.transaction_simulation_data}</p>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <label className="uk-text-xmedium uk-margin-small-right"
                        htmlFor="select-set-attribute">{lang.budgeting.configure_budgeting.titles.lines_to_be_added_based_on}</label>
                    <Dropdown
                        id="add_by"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(this.state.trxColumnsOptions ? this.state.trxColumnsOptions.filter(e=>e.value!== _none) :[],this.state.addedBy)}
                        options={this.state.trxColumnsOptions ? this.state.trxColumnsOptions.filter(e=>e.value!== _none) :[]}
                        onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.lines_to_be_added_based_on,e)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                    </div>
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                        <label className="uk-text-xmedium uk-margin-small-right"
                            htmlFor="select-set-attribute">{lang.budgeting.configure_budgeting.titles.lines_to_be_sorted_based_on}</label>
                        <Dropdown
                            id="sorted_by"
                            className="select-dataSet width-200 input__dropdown"
                            value={findOptionByKey(this.state.trxColumnsOptions ? this.state.trxColumnsOptions.filter(e=>e.value!== _none) :[],this.state.sortedBy)}
                            options={this.state.trxColumnsOptions ? this.state.trxColumnsOptions.filter(e=>e.value!== _none) :[]}
                            onChange={(e) => this.handleElementChange(lang.budgeting.configure_budgeting.titles.lines_to_be_sorted_based_on,e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                    <Modal
                      id={'mapping-submit'}
                      title={lang.budgeting.configure_budgeting.titles.map_trx_datasource}
                      openDialog={this.state.mappingOpen}
                      closeClick={this.onCancelClick}
                      bodyContent={this.mappingDialogContent}
                      dialogActions={this.mappingDialogActions}
                      size={DIALOG_SIZE.LARGE}
                    />
                    <Modal
                      id={'used-metrics'}
                      title={this.state.usedMetricsTitle}
                      openDialog={this.state.usedMetrics}
                      closeClick={() => { this.setUsedMetricsOpen(false) }}
                      bodyContent={this.usedMetricsDialogContent}
                      dialogActions={this.usedMetricsDialogActions}
                      size={DIALOG_SIZE.LARGE}
                    />
                    <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <Button 
                        id="run-getbudgetquery"
                        label={"1. Generate Transaction File"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}                   
                        onBtnClick={this.getBudgetQuery}
                    />
                    </div>
                    <Button 
                        id="run-getbudgetquery"
                        label={"2. Generate Other Files"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}                   
                        onBtnClick={this.generateBudgetFiles}
                    />
            </div>
            
        )
    }
    
}
export default ConfigureBudget