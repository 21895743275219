// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.1vw;
}

.items-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon-title-main-div {
  display: flex;
  align-items: flex-end;
  column-gap: 0.78vw;
  width: 30%;
}
.welcomeContainer .icon-title-main-div {
  width: auto;
  width: initial;
}

.icon-container {
  border-radius: 15%;
  display: flex;
  padding: 0.42vw;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.icon-title-description {
  line-height: normal;
}

.landing-page-contents .icon-title-main-div.hover:hover .icon-container,
.landing-page-contents .hover-icon{
  border: 0.05vw solid white
}

.empty-div-icon {
  width: 5vw;
  height: 4.8vw;
  position: absolute; 
  z-index: 0;
  align-self: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/templateLayout/styles/iconTitle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,WAAY;EAAZ,cAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE;AACF;;AAEA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".title-container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 1.1vw;\n}\n\n.items-title-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.icon-title-main-div {\n  display: flex;\n  align-items: flex-end;\n  column-gap: 0.78vw;\n  width: 30%;\n}\n.welcomeContainer .icon-title-main-div {\n  width: unset;\n}\n\n.icon-container {\n  border-radius: 15%;\n  display: flex;\n  padding: 0.42vw;\n  align-items: center;\n  justify-content: center;\n  z-index: 1;\n}\n\n.icon-title-description {\n  line-height: normal;\n}\n\n.landing-page-contents .icon-title-main-div.hover:hover .icon-container,\n.landing-page-contents .hover-icon{\n  border: 0.05vw solid white\n}\n\n.empty-div-icon {\n  width: 5vw;\n  height: 4.8vw;\n  position: absolute; \n  z-index: 0;\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
