import React, { Component } from 'react';
import Tabulator from "tabulator-tables";

import { getReadableFilterTextVectors, toggleLoader, setLocalStorageValueByParameter } from '../../class/common';
import {
    API_URL, FormatTypes, RAW_ITEMS, UPLOAD_SECTIONS, VECTOR_DEFINITONS,
    VECTOR_MAPPING
} from '../../class/constants';
import { formatValString } from "../../class/format";
import { convertPxToViewport } from '../../class/formatting';
import { alertAndLogError, prepareLogo } from '../../class/jqueries';
import { copyObjectValues } from '../../class/utils.js';
import { lang } from '../../language/messages_en';
import '../../styles/vectorDefinitions.css';

const _description = VECTOR_DEFINITONS.FIELDS.DESCRIPTION;
const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;

var VECTOR_DEFINITIONS_COLUMNS = [
    {
        title: VECTOR_DEFINITONS.TITLES.GROUP_NAME,
        field: VECTOR_DEFINITONS.FIELDS.GROUP_NAME,
        // headerSort: false,
        hozAlign:"left",
        dontFilter: true,
        cssClass: "group-name"
    },
    {
        title: VECTOR_DEFINITONS.TITLES.NAME,
        field: VECTOR_DEFINITONS.FIELDS.DISPLAY_NAME,
        // headerSort: false,
        hozAlign:"left",
        dontFilter: true
    },
    {
        title: VECTOR_DEFINITONS.TITLES.DESCRIPTION,
        field: VECTOR_DEFINITONS.FIELDS.DESCRIPTION,
        // headerSort: false,
        hozAlign:"left",
        dontFilter: true
    },
    {
        title: VECTOR_DEFINITONS.TITLES.NUMBER_SAMPLE_ENTITIES,
        field: VECTOR_DEFINITONS.FIELDS.NUMBER_SAMPLE_ENTITIES,
        // headerSort: false,
        hozAlign:"left",
        dontFilter: true
    },
    {
        title: VECTOR_DEFINITONS.TITLES.NAME_SAMPLE_ENTITIES,
        field: VECTOR_DEFINITONS.FIELDS.NAME_SAMPLE_ENTITIES,
        // headerSort: false,
        hozAlign:"left",
        dontFilter: true
    },
];

class VectorDefinitionsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.getVectors = this.getVectors.bind(this);
    }

    getColumnFormatter(colField) {
        var columnFormatter;
        var obj = this;

        switch (colField) {
            case VECTOR_DEFINITONS.FIELDS.DESCRIPTION:
                columnFormatter = function (cell) {
                    var div = document.createElement('div');
                    if (cell.getRow().getData()[_description] && cell.getRow().getData()[_description].length === 0 ) {
                        div.innerText = lang.no_description;
                    } else {
                        div.innerText = cell.getValue();
                    }
                    return div;
                }
                break;
                case VECTOR_DEFINITONS.FIELDS.NAME_SAMPLE_ENTITIES:
                    columnFormatter = function (cell, formatterParams) {
                        let row = cell.getValue(); // [anameSPLITTERanumber,bnameSPLITTERbnumber]
                        let fullArray = row.split('STRING_AGG'); //[anameSPLITTERanumber][bnameSPLITTERbnumber]
                        let outputArray = [];
                        for(var e in fullArray){
                            let item = fullArray[e].split('SPLITTER')[0]; //[aname][bname]
                            outputArray.push(item)
                        }
                        return outputArray.join(', ');//aname,bname
                    }
                break;
                case VECTOR_DEFINITONS.FIELDS.NUMBER_SAMPLE_ENTITIES:
                    columnFormatter = function (cell, formatterParams) {
                        let row = cell.getValue(); // [anameSPLITTERanumber,bnameSPLITTERbnumber]
                        let fullArray = row.split('STRING_AGG'); //[anameSPLITTERanumber][bnameSPLITTERbnumber]
                        let outputArray = [];
                        for(var e in fullArray){
                            let item = fullArray[e].split('SPLITTER')[1]; //[anumber][bnumber]
                            outputArray.push(item)
                        }
                        return outputArray.join(', ');//anumber,bnumber
                    }
                    
                break;

            default:
                columnFormatter = function (cell, formatterParams) {
                    return cell.getValue();
                }
                break;

        }
        return columnFormatter;
    }

   

    getAllFileTypeColumns(scenarioId) {
        var obj = this;
        var query = {
            action: "getAllFileTypeColumns",
            scenario_id: scenarioId,
            fileType: JSON.stringify([UPLOAD_SECTIONS.FIELDS.MASTER_DATA, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA, UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER])
        }

        toggleLoader(true, 'getAllFileTypeColumns');
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.OUTPUT,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: {withCredentials: true},
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                var datacolumns = response.data.filter(e => e[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES && e[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER);
                var allAncillaryColumns = response.data.filter(e => e[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES);
                var allTypeColumns = response.data;
                obj.setState({
                    dataColumns: response.data,
                    allAncillaryColumns: allAncillaryColumns,
                    allTypeColumns: allTypeColumns
                }, function () {
                    if (obj.tabulator) {
                        var tableColumns = copyObjectValues(VECTOR_DEFINITIONS_COLUMNS);
                        tableColumns.forEach(col => {
                            col.formatter = obj.getColumnFormatter(col.field);
                        });
                        obj.tabulator.setColumns(tableColumns);
                        // obj.tabulator.hideColumn("vector_group")
                    }

                });
            },
            error: function (error) {
                alertAndLogError(error);
            },
            complete: function () {
                toggleLoader(false, 'getAllFileTypeColumns');
            }
        });
    }

  

    getVectors(scenarioId) {
        var obj = this;
        toggleLoader(true, "getVectors");
        var query = {
            action: "getVectorsDefinition",
            scenario_id: scenarioId,
            timePeriod: "",
            vectors: "",
            isListOfVectorDefinitions: true,
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: {withCredentials: true},
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                if (response.data) {
                    $(".sidenav").removeClass("uk-hidden");
                    var data = response.data;
                    // var customer_groups = data.filter(e => e.vector_group === "Customer").length;
                    // var product_groups = data.filter(e => e.vector_group === "Product").length;
                    // var operations_groups = data.filter(e => e.vector_group === "Operations").length;
                    var height = convertPxToViewport((data.length * 35) + 1);
                    $(".sidenav").css("height", height);
                    obj.getAllFileTypeColumns(scenarioId);
                    obj.tabulator.replaceData(data);
                } else {
                    obj.tabulator.replaceData([]);
                    obj.tabulator.setColumns(VECTOR_DEFINITIONS_COLUMNS);
                }

            },
            error: function (error) {
                alertAndLogError(error);
            },
            complete: function (xhr, textStatus) {
                toggleLoader(false, "getVectors");
            }
        });

    }

    componentDidUpdate(prevProps) {
        if (this.props.scenario && this.props.scenario !== prevProps.scenario) {
            this.getVectors(this.props.scenario);
        }
    }

    componentDidMount() {
        var obj = this;
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            tooltips: false,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,         //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            autoResize: true,
            resizableRows: false,       //allow row order to be changed
            virtualDomBuffer: 450,
            placeholder: "",
            width: "100%",
            height: "100%",
            reactiveData: true,     //tabulator listens to any change in the data array and updates the table
            printAsHtml: true,
            printRowRange:"all",
        }
       
        this.tabulator = new Tabulator("#vector_definitions_table", options);
        this.getVectors(this.props.scenario);
    }


    render() {
        var  today = new Date()
        var month = today.toLocaleString('default', { month: 'long' })
        var dd = String(today.getDate()).padStart(2, '0');
        var yyyy = today.getFullYear();
        var date = month +" "+dd+", "+ yyyy;
        var logoImage = prepareLogo(window.location.host);
        var src = "/images/client/"+logoImage+".png";

        return (
          <>
            <div className="print-scenario-details uk-hidden">
              <div>
                <img className="width-200" src={src} alt={process.env.REACT_APP_CLIENT_NAME} />
              </div>
              <div className="uk-nav-center">
                <h2 className="uk-text-xxlarge">{this.props.reportTitle}</h2>
                <div className="uk-text-large">
                  {this.props.scenariosData ? "Scenario: " + this.props.scenariosData["scenario_number"] + " - " + this.props.scenariosData["scenario_name"] + " (" +  this.props.scenariosData["scenario_status"] +  ")" : ""}
                </div>
                <div className="uk-text-large">{date}</div>
              </div>
              <div>
              <img className="width-200" src={"/images/logo.png"} alt="Profit Isle logo" />
              </div>
            </div>

            <table id="vector_definitions_table" ref={(element) => (this.mainTable = element)} />
          </>
        );

    }


}

export default VectorDefinitionsTable;
