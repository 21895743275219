import React, { Component } from 'react';
import Popup from 'react-popup';
import { CustomSelect } from '../../form/elements.js';
import Tabulator from "tabulator-tables"; //import Tabulator library
import { cleanUpSingleTabulatorColumn, toggleLoader, setLocalStorageValueByParameter } from '../../class/common.js';
import {
    ALL_WIDGETS, API_URL, DEFINE_FIELDS, EXCLUDED_LINES_FIELDS, EXCLUDED_LINES_TIE_OFF_FIELDS, EXCLUDED_LINES_TIE_OFF_TITLES, Formats, FormatTypes, 
    INFO_MESSAGE, TOTAL_EXCLUSIONS_FILEDS, TOTAL_EXCLUSIONS_TITLES,
    ENGINE_FILTER,
    UPLOAD_SECTIONS,
    SCENARIO_STATUS,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DROPDOWN_TYPE,
    DIALOG_SIZE
} from '../../class/constants';
import { formatValHTML } from '../../class/format.js';
import { alertAndLogError, getObjectAsArray } from '../../class/jqueries';
import { capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey, getTranslationFile, extractValueFromObjects, deepCompareObjects } from '../../class/utils.js';
import EngineFilterDialog from '../filter/EngineFilterDialog.js';
import { convertPxToViewport } from '../../class/formatting.js';

import Button from '../../newComponents/Button.js';
import { lang } from '../../language/messages_en.js';
import DropDown from '../../newComponents/DropDown.js';
import Modal from '../../newComponents/Modal.js';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../../class/networkUtils.js';

const MESSAGES = getTranslationFile();
const $ = require('jquery');

const _empty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
const _ct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.contains.value;
const _nct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_contains.value;
const _startsWith = MESSAGES.ui_filter.dropdowns.engine_filter_functions.starts_with.value;
const _endsWith = MESSAGES.ui_filter.dropdowns.engine_filter_functions.ends_with.value;
const _nstartsWith = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_starts_with.value;
const _nendsWith = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_ends_with.value;

const stringFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, "string", "value_type");
const numericFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, "numeric", "value_type");

const baseUrl = process.env.REACT_APP_BASE_URL;
var cellObject = null;

const _file = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _values = ENGINE_FILTER.KEYS.VALUES;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _dataFileType = ENGINE_FILTER.KEYS.DATA_FILE_TYPE;
const _fileType = ENGINE_FILTER.KEYS.FILE_TYPE;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;
const _rowPosition = ENGINE_FILTER.KEYS.ROW_POSITION;
const _parenthesisBefore = ENGINE_FILTER.KEYS.PARENTHESIS_BEFORE;
const _parenthesisAfter = ENGINE_FILTER.KEYS.PARENTHESIS_AFTER;
const sampleRowTotal = {
    total_lines: "-", total_invoices: "-", total_revenue: "-", total_cogs: "-"
}
const or = "OR";
const and = "AND";
/**
 * 
 * @author [Lara Jannoun]
 */

var EXCLUDED_LINES_TIE_OFF_COLUMNS = [
    {
        title: EXCLUDED_LINES_TIE_OFF_TITLES.NAME,
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.NAME,
        dontFilter: true,
        headerSort: false,
        widthGrow: 2,
        tooltip: true
    },
    {
        title: EXCLUDED_LINES_TIE_OFF_TITLES.TRANSACTION,
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.TRANSACTION,
        dontFilter: true,
        widthGrow: 3,
        sorter: false,
        visible: true,
    },
    {
        title: EXCLUDED_LINES_TIE_OFF_TITLES.INVOICELINE_TOTALS,
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.INVOICELINE_TOTALS,
        dontFilter: true,
        widthGrow: 3,
        format_type: FormatTypes.AMOUNT,
        sorter: false,
        tooltip: true
    },
    {
        title: EXCLUDED_LINES_TIE_OFF_TITLES.GL_TOTALS,
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.GL_TOTALS,
        dontFilter: true,
        widthGrow: 2,
        format_type: FormatTypes.AMOUNT,
        sorter: false,
        tooltip: true
    },
    { 
        title: EXCLUDED_LINES_TIE_OFF_TITLES.VARIANCE, 
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.VARIANCE,
        dontFilter: true,
        visible: false,
        format_type: FormatTypes.AMOUNT,
        sorter: false,
        tooltip: true
    },
    { 
        title: EXCLUDED_LINES_TIE_OFF_TITLES.VARIANCE_PERC, 
        field: EXCLUDED_LINES_TIE_OFF_FIELDS.VARIANCE_PERC,
        dontFilter: true,
        widthGrow: 2,
        tooltip: true
    }
];

/**
 * Exclusion Lines Totals
 * @author [Lara Jannoun]
 */
var TOTAL_EXCLUSIONS_COLUMNS = [
    {
        title: TOTAL_EXCLUSIONS_TITLES.LINES,
        field: TOTAL_EXCLUSIONS_FILEDS.LINES,
        hozAlign:"left",
        dontFilter: true,
        headerSort: false
    },
    {
        title: TOTAL_EXCLUSIONS_TITLES.INVOICE_LINES,
        field: TOTAL_EXCLUSIONS_FILEDS.INVOICE_LINES,
        hozAlign:"left",
        dontFilter: true
    },
    {
        title: TOTAL_EXCLUSIONS_TITLES.REVENUE,
        field: TOTAL_EXCLUSIONS_FILEDS.REVENUE,
        dontFilter: true,
        format_type: FormatTypes.AMOUNT,
        sorter: false
    },
    { 
        title: TOTAL_EXCLUSIONS_TITLES.COGS, 
        field: TOTAL_EXCLUSIONS_FILEDS.COGS,
        dontFilter: true,
        format_type: FormatTypes.AMOUNT,
        sorter: false
    },    
];


/**
 * 
 * @author [Lara Jannoun]
 */
class ExcludedLines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPeriod: this.props.selectedPeriod.value,
            dataFields: [],
            exclusionsDataOriginal:[],
            rowTotals : []
        };
     
        this.setStagingReport = this.setStagingReport.bind(this);
        this.getExclusions = this.getExclusions.bind(this);
        this.getAttrValues = this.getAttrValues.bind(this);
        this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);
        this.clearDatalist = this.clearDatalist.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.setChosenTimePeriod = this.setChosenTimePeriod.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);
        this.prepareExclusion = this.prepareExclusion.bind(this);
        this.updateRowTotals = this.updateRowTotals.bind(this);
        this.getExclusionData = this.getExclusionData.bind(this);
        this.resetTotals = this.resetTotals.bind(this);
        this.validateExclusions = this.validateExclusions.bind(this);

        this.redrawTable = false;
    }

    getAttrValues(filterObj, attr, dataFileType, fieldDataType, rowIndex, input_value, callback) {
        var obj = this;
        var selectedColumn = filterObj[_column];
        var isDisabled = [_ct, _nct].indexOf(filterObj[_function]) > -1 ? true : false;

        if (rowIndex) {
            $(".value-loader-" + rowIndex).each(function () {
                $(this).removeClass("uk-hidden");
            });
        }
        var dataBody = { 
            action: "getAttributeValues",
            scenario_id: this.props.scenarioId,
            attribute: attr !== null ? attr : selectedColumn,
            fileType: dataFileType ? dataFileType : "", 
            isDefaultField: false,
            rawFileSubTypeId: filterObj[_rawFileSubtypeId] ? filterObj[_rawFileSubtypeId]: null, //rawfilesubtypeid
            field_dataType: fieldDataType ? fieldDataType : "", //STRING OR NUMERIC
            input_value: input_value ? input_value : "",
        };

        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = "/DataModeling";
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, { mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(dataBody) })
            .then((response) => {
                if (response.status === 403) {
                }
                return response.json()
            })
            .then((data) => {
                var values = [];

                if (data.data !== null) {
                    data.data.map(function (item) {
                        values.push({ label: item.attribute, value: item.attribute, action: "input-value", isDisabled: isDisabled })
                    });

                    obj.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_valueOptions] = values;
                    obj.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
                    let menuIsOpen = obj.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
                    obj.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(() =>{
                        if(menuIsOpen) {
                            obj.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
                        }
                    });
                }
            }).catch((error) => {
                alertAndLogError(error);
            }).then(()=>{
                if (rowIndex) {
                    $(".value-loader-" + rowIndex).each(function () {
                        $(this).addClass("uk-hidden");
                    });
                }
            });
    }

    setStagingReport() {
        if(this.state.changed){
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
        } else {
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        var shouldUpdate = !deepCompareObjects(nextState, this.state) ||
            !deepCompareObjects(nextProps, this.props);
        return shouldUpdate;
    }

    getTabulatorColumns(columns) {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);
        });
        return columns;
    }

    updateRowTotals(data) {
        if(typeof data === "object" && !Array.isArray(data)) {
            data = [data];
        }
        
        this.setState({
            rowTotals: data
        });
    }

    getAdvancedFilterText(filter) {
        var filterText = "";
        filter.map((e,index)=>{
            let tempFilter = copyObjectValues(e.ref.current.filterObj);
            filterText += " "+ tempFilter[_logicalOperator] +" "+ tempFilter[_parenthesisBefore] +" "+ (index + 1) +" "+ tempFilter[_parenthesisAfter];
        });
        return filterText;
    }

    resetRowTotals = (advancedFilterText) => {
       let modifiedAdvancedFilterText = advancedFilterText ? this.translateExpression(advancedFilterText) : this.translateExpression($('.advanced-filter-rect').first().text());
       let counts = this.countNumbersInParentheses(modifiedAdvancedFilterText)
       
       let rowTotals = [];
       for(let i = 0; i < counts.length; i++){
            let sampeRowTotal = copyObjectValues(sampleRowTotal);
            sampeRowTotal.count = counts[i].toString();
            rowTotals.push(sampeRowTotal);
       }
       
       this.updateRowOrder(rowTotals);
    
       this.setState({
        rowTotals:rowTotals
       },()=>{
            this.resetTotals();
       })
    }

    countNumbersInParentheses(expression) {
        const regex = /\((.*?)\)/g;
        const matches = expression.match(regex);
        const counts = [];
    
        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                const numbers = matches[i].match(/\d+/g);
                counts.push(numbers ? numbers.length : 0);
            }
        }  
        return counts;
    }

     translateExpression(expression) {
        // Matches groups of numbers within parentheses
        const regex = /\((.*?)\)|(\d+)/g;
        
        // Replace each matched group or standalone number with each number within parentheses wrapped individually
        expression = expression.replace(regex, function(match, group, number) {
            if (group) {
                // If it's a group within parentheses, just return it as it is
                return '(' + group + ')';
            } else {
                // If it's a standalone number, wrap it within parentheses
                return '(' + number + ')';
            }
        });
        
        return expression;
    }

     
    updateRowOrder = (rowTotals) => {
        for(let i = 0; i < rowTotals.length; i++){
            if(i === 0){
                rowTotals[i].rowOrder = 1;
            } else {
                rowTotals[i].rowOrder = rowTotals[i-1].rowOrder + Number(rowTotals[i-1].count)
            }
        }
    }

    getExclusions(period) {
        let operators = [];
        var obj = this;
        var query = {
            action: "getExclusions",
            scenario_id: this.props.scenarioId,
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      toggleLoader(true, "getExclusions");
        $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success:function(response, result, xhr){
                response = JSON.parse(response);
                if (response.data && response.data.length > 0) {
                    obj.showExclusionOverlay(false);
                    $(".add-exclusions-plus-top").removeClass("uk-hidden");
                    $("#exclusion-overlay-div").addClass("uk-hidden");

                    obj.filterDialRef.state.filterRefs = [];
                    response.data.map(function (item, index) {
                        var newFilter = obj.filterDialRef.getNewFilterRefObject();
                        var parsedObj = JSON.parse(item);
                        parsedObj[_rowPosition] = index;
                        var preparedExlusion = parsedObj ? obj.prepareExclusion2(parsedObj) : {};
                        preparedExlusion[_columnOptions] = obj.returnColumnOptions(preparedExlusion);
                        preparedExlusion[_functionOptions] = obj.returnFunctionOptions(preparedExlusion);
                        preparedExlusion[_valueOptions] = obj.getAttrValues(preparedExlusion, preparedExlusion[_column], preparedExlusion[_dataFileType], preparedExlusion[_fieldDataType], index);
                        newFilter.ref.current = { filterObj: preparedExlusion };
                        obj.filterDialRef.state.filterRefs.push(newFilter);
                        if(parsedObj.logical_operator !== '' && index !== 0) {
                            operators.push(parsedObj.logical_operator);
                        }

                    });
                   let advancedFilterText = obj.getAdvancedFilterText(obj.filterDialRef.state.filterRefs)
                    obj.resetRowTotals(advancedFilterText)
                } else {
                    obj.showExclusionOverlay(true);
                    $('#load_total_values').addClass("disabled uk-disabled")
                }

                let originalData = [];
                obj.filterDialRef.state.filterRefs.forEach(f=>{
                    if(f.ref && f.ref.current) {
                        let tempFilter = copyObjectValues(f.ref.current.filterObj);
                        delete tempFilter[_columnOptions];
                        delete tempFilter[_functionOptions];
                        delete tempFilter[_valueOptions];
                        originalData.push(tempFilter);
                    }
                })
                obj.setState({
                    exclusionsDataOriginal: originalData
                }, function () {
                    obj.forceUpdate();   
                })
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function (xhr, textStatus) {
                toggleLoader(false, "getExclusions");
            }
        });
    }

    validateExclusions(filterDialRef) {
        var result = false;
        let filterRefs = this.filterDialRef?.state?.filterRefs;
        if(this.filterDialRef === null){
            filterRefs = filterDialRef.state.filterRefs;
        }
        for(let e = 0; e < filterRefs.length; e++) {
            var object =  filterRefs[e]?.ref?.current?.filterObj;
            if (object && object[_file] !== "" && object[_column] !== "" && object[_function] !== ""
                && (( ![_empty, _nempty].includes(object[_function]) && object[_values].length > 0)
                     || ([_empty, _nempty].includes(object[_function]) && (!object[_values] || object[_values].length === 0 )))) {
                result = true;
            } else {
                result = false;
                break;
            }
        }
        return result;
    }

    saveExclusions() {
        var obj = this;
        var validateExclusions = this.validateExclusions();

        if (this.state.exclusionsDataOriginal.length === 0 && this.filterDialRef.state.filterRefs.length === this.state.exclusionsDataOriginal.length) {
            obj.props.goBackToStaging();
            return;
        }else if (this.filterDialRef.state.filterRefs.length !== 0  && !validateExclusions && this.filterDialRef.state.filterRefs.length > 0) {
            obj.setInfoDialogOpen(true, MESSAGES.empty_exclusion_fields);
            return;
        }

        var exclusions = [];
        toggleLoader(true, "saveExclusions");

        obj.filterDialRef.state.filterRefs.forEach(function (item) {
            var object = item.ref.current.filterObj;
            exclusions.push(obj.prepareExclusion(object));
        });

        var query = {
            action: "saveExclusions",
            scenario_id: this.props.scenarioId,
            exclusions: exclusions,
            overrideScenario: this.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX,
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success:function(response, result, xhr){
                response = JSON.parse(response);
                
                if(response && response.insertResponse > -1)  {
                  obj.props.setSaveDialogOpen("Mapping and Tie off changes are saved. Reloading data...")
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, "saveExclusions");
                obj.props.goBackToStaging(true);
            }
        });
    }

    resetTotals() {
        var obj = this;
        let totals = [{total_lines:"0",total_invoices:"0",total_revenue:"0",total_cogs:"0"}];
        obj.setState({totals:totals})
    }

    checkExclusionsValidity = (filterDialRef) => {
        if(!this.validateExclusions(filterDialRef)){
            $('#load_total_values').addClass("disabled uk-disabled")
        } else {
            $('#load_total_values').removeClass("disabled uk-disabled")
        }
    }
    
    getExclusionData = (filterObjs, callback) => {
        var obj = this;

        $('img[class^="total-loader-"]').each(function () {
            $(this).siblings("p").text("");
            $(this).removeClass("uk-hidden");
        });
        
        var exclusions = [];
        if (filterObjs && filterObjs.length > 0) {
            // after deleting, refreshing totals
            filterObjs.forEach(function (item) {
                var object = item.ref.current.filterObj;
                exclusions.push(obj.prepareExclusion(object));
            }); 
        } else {
            obj.filterDialRef && obj.filterDialRef.state.filterRefs.forEach(function (item) {
                var object = item.ref.current.filterObj;
                exclusions.push(obj.prepareExclusion(object));
            }); 
        }

        var query = {
            action: "getExclusionData",
            scenario_id: obj.props && obj.props.scenarioId ? obj.props.scenarioId : obj.scenario_id, //obj.scenario_id when we call the function from outside
            exclusions: exclusions,
            timePeriod: obj.props.selectedPeriod.value
        }
        let onThenCallback = (data) => {
          if(data?.data?.length) {

                if (filterObjs && filterObjs.length > 0) {
                    obj.updateRowTotals(data.data); //in engineFilterDialog
                } else {
                    obj.filterDialRef.state.rowTotals = data.data;
                    obj.updateRowTotals(data.data);
                }
            }
            obj.totalExclusionsTable.replaceData(data.totals);
            obj.setState({
                totals:data.totals
            })
            obj.forceUpdate();
            if (callback && typeof callback === "function") {
                callback();
            }
        }

        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getExclusionData",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.periods]: obj.props.selectedPeriod.value
        };
        fetchAPI(fetchOptions);
    }

    prepareExclusion2(filterObj) {
        var object = {};
        // object.value = filterObj["value"];t
        object.field = filterObj["field"];
        object.column = filterObj["field"]
        object.function = filterObj["operator"];
        object.operator = filterObj["operator"];
        object.file_type = filterObj[_fileType];
        object.data_file_type = filterObj[_dataFileType];
        object.raw_file_subtype_id = filterObj[_rawFileSubtypeId];
        object.logical_operator = filterObj[_logicalOperator];
        object.row_position = filterObj[_rowPosition];
        object.parenthesis_before = filterObj[_parenthesisBefore];
        object.parenthesis_after = filterObj[_parenthesisAfter];
        object.fileType = filterObj[_rawFileSubtypeId];
        object.field_data_type = filterObj[_fieldDataType];
        object.values = filterObj["values"];

        return object;
    }

    prepareExclusion(filterObj) {
        var object = {};
        // object.value = typeof filterObj[_values] === "string" ? [{value: filterObj[_values]}] : filterObj[_values];
        object.values = typeof filterObj[_values] === "string" ? [{value: filterObj[_values]}] : filterObj[_values];
        object.field = filterObj[_column];
        object.column = filterObj[_column];
        object.function = filterObj[_function];
        object.operator = filterObj[_function];
        object.file_type = filterObj[_fileType];
        object.data_file_type = filterObj[_dataFileType];
        object.raw_file_subtype_id = filterObj[_rawFileSubtypeId];
        object.logical_operator = filterObj[_logicalOperator];
        object.row_position = filterObj[_rowPosition];
        object.parenthesis_before = filterObj[_parenthesisBefore];
        object.parenthesis_after = filterObj[_parenthesisAfter];
        object.fileType = filterObj[_rawFileSubtypeId];
        object.field_data_type = filterObj[_fieldDataType];

        return object;
    }


    getColumnFormatter(colField) {
        var columnFormatter;
        var obj = this;

        switch (colField) {
            default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue() === "0" ? "-" : cell.getValue();
                }
            break;
        }
        return columnFormatter;  
    }

    getCalculatedColumns() {
        var dataBody = { 
            action: "getCalculatedFields",
            machine_name: this.props.machineName,
            dbDataSet: this.props.dbDataSet,
            tablePrefix: this.props.tablePrefix,
            token : this.props.idToken,
            project_id: this.props.projectId,
            scenario_id: this.props.scenarioId,
            clientName: this.props.clientName,
            clientId: this.props.clientId
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      const path = API_URL.DATA_MODELING;
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                return response.json();
            }).then((data)=>{
                if(data.error || data.result === 'ERROR'){
                    this.setInfoDialogOpen(true, 'Something went wrong. Please try again');
                } else if (data.data) {
                    var dataFields = [];
                    for (var elt in data.data) {
                        dataFields.push({
                            label: data.data[elt]["column_name"], value: data.data[elt]["column_name"], raw_file_subtype_id: data.data[elt]["invoiceline_calculated_column_id"],
                            description: data.data[elt]["column_description"], name: data.data[elt]["display_column_name"], raw_field_name: data.data[elt]["column_name"], field_data_type: "numeric"
                        });
                    }
                    this.setState({
                        dataFields: dataFields
                    })
                } 
            })
        .catch((error)=>{
            alertAndLogError(error);
        });       
    }
        
  componentDidUpdate(nextProps) {
    if (this.props.selectedPeriod.value !== nextProps.selectedPeriod.value) {
      this.setState({
        selectedPeriod: nextProps.selectedPeriod.value
      });
    }
    this.totalExclusionsTable.replaceData(this.state.totals)
  }

    componentWillUnmount() {
        $("#header-next").unbind('click');
    }

    componentWillUnmount() {
        //hiding the period drop down list in header
        $("#periodList-header").addClass("uk-hidden");
        // $("#header-buttons").addClass("uk-hidden");

        //enabling the scenario drop down list
        $("#scenarioList-header").removeClass("disabled");
        // this.setStagingReport();        
    }

    discardChangesAndGoBack(industry) {
        var exclusions = [];
        var comp = this;
        this.filterDialRef.state.filterRefs.forEach(function (item) {
            var obj = item.ref.current.filterObj;
            exclusions.push(comp.prepareExclusion(obj));
        });

        var data = exclusions.length > 0 ? exclusions : [];
        var changed = false;
        var originalData = this.state.exclusionsDataOriginal && this.state.exclusionsDataOriginal.length > 0 ? this.state.exclusionsDataOriginal : [];
        if (data.length !== originalData.length) {
            changed = true;
        } else if (!deepCompareObjects(data, originalData)) {
            changed = true;
        }
        //check if there are modified fields
        this.props.goBackToStaging(false, changed);
    }

    showExclusionOverlay(show) {
        if (show) {
            $("#exclusion-overlay-div").removeClass("uk-hidden");
            // Hide tables when #exclusion-overlay-div is active
            $(".total-exclusions-table").addClass("uk-hidden");
            $(".profitStackMapping").addClass("uk-hidden");
            $(".mapping-header.exclusions-table").addClass("uk-hidden");
        } else {
            $("#exclusion-overlay-div").addClass("uk-hidden");
            // Show tables when #exclusion-overlay-div is hidden
            $(".total-exclusions-table").removeClass("uk-hidden");
            $(".profitStackMapping").removeClass("uk-hidden");
            $(".mapping-header.exclusions-table").removeClass("uk-hidden");
        }
    }

    componentDidMount() {
        var obj = this;
        obj.getCalculatedColumns();
        obj.getExclusions();

        //showing the period drop down list in header
        $("#periodList-header").removeClass("uk-hidden");
        $("#periodList-header").removeClass("disabled");
        $("#header-next").unbind('click');
        $("#header-back").unbind('click');

        //disabling the scenario drop down list
        $("#scenarioList-header").addClass("disabled");

        //adding the back and apply buttons to header
        // $("#header-buttons").removeClass("uk-hidden");
        $("#header-title p").text(MESSAGES.mapping_tie_off);
        $("#header-title-icon").removeClass("uk-hidden");
                
        $("#header-next").click(function (e) {
            obj.props.showScenarioPopUp();
        });
        $("#header-back").click(function (e) {
            obj.discardChangesAndGoBack();
        });
        
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            index: EXCLUDED_LINES_FIELDS.COLUMN,
            tooltipsHeader:true,
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            height: convertPxToViewport(410),
            resizableColumns: false,
            autoResize: true,
            virtualDomBuffer: 800,
            width: "100%",
            placeholder: MESSAGES.no_data_available,
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            tooltips: function(column){
                return column._cell.value;
            },
        }


        //Total Exclusions table
        var totalExclusionsOptions = copyObjectValues(options);
        var totalExclusionsColumns = copyObjectValues(TOTAL_EXCLUSIONS_COLUMNS);
        totalExclusionsColumns.forEach(totalExclusionsColumn => {
            if(!totalExclusionsColumn.formatter && !totalExclusionsColumn.format_type) {
                totalExclusionsColumn.formatter = obj.getColumnFormatter(totalExclusionsColumn.field);
            } else {
                totalExclusionsColumn.formatterParams = {defaultValue:""}
                totalExclusionsColumn = cleanUpSingleTabulatorColumn(totalExclusionsColumn);
            }
        });
        totalExclusionsOptions.columns = totalExclusionsColumns;
        totalExclusionsOptions.height = convertPxToViewport(70);
        // Creating all tabulator tables

        obj.totalExclusionsTable = new Tabulator("#total-exclusions", totalExclusionsOptions);
        let totals = [{total_lines:"0",total_invoices:"0",total_revenue:"0",total_cogs:"0"}];
        obj.setState({totals:totals})
        // Hide tables when #exclusion-overlay-div is active
        // $(".total-exclusions-table").addClass("uk-hidden");
        $(".profitStackMapping").addClass("uk-hidden");
        $(".mapping-header.exclusions-table").addClass("uk-hidden");
        // Add a tabulator row on "Add Row" button click
        $("#exclude-lines-add-row").click(function(){
            $("#exclusion-overlay-div").addClass("uk-hidden");
            // Show tables when #exclusion-overlay-div is hidden
            $(".total-exclusions-table").removeClass("uk-hidden");
            $(".profitStackMapping").removeClass("uk-hidden");
            $(".mapping-header.exclusions-table").removeClass("uk-hidden");
            obj.filterDialRef.addNewFilter(true, _logicalOperator, "");
        });
    }

    setChosenTimePeriod(e) {
        this.setState({
            selectedPeriod: e.value
        },function(){
            this.resetRowTotals();
        });
    }

    onChangeTimePeriod(e) {
        var obj = this;
        this.setState({
            selectedPeriod: e.value
        });
    }

    clearDatalist() {
        $("#excludeLinesValues option").each(function() {
            $(this).val("");
            $(this).text("");
        });
        this.setState({
            values: []
        })
    }
    	
	addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");
        div.classList.add("uk-flex");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();
        p.classList.add("uk-width-5-6");

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-cursor-pointer", "padding-left-5px");
        el.setAttribute("uk-tooltip", tooltipMessage);
        p.appendChild(el);

        div.appendChild(p);
        return div;
    } 

    addNewRowTitleFormatter(cell, params){
        var div = document.createElement("div");
        var el = document.createElement("i");
        el.classList.add("fal","fa-plus-circle", "fa-lg","uk-cursor-pointer", "uk-margin-right", "add-exclusions-plus-top", "uk-hidden");
        el.title = "Add new row";
        el.onclick = params.funkName;
        div.appendChild(el);

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();
        div.appendChild(p);
        return div;
    }
    
    deleteRow() {
        cellObject.getRow().delete();
        
        if (this.filterDialRef.state.filterRefs && this.filterDialRef.state.filterRefs.length === 0) {
            this.totalExclusionsTable.clearData();
            this.setState({
                total_exclusions: []
            });
        } else {
            this.getExclusionData();
        }
    }

    returnColumnOptions(filterObj) {
        var comp = this;
        var calculatedColumns = comp.state.dataFields ? comp.state.dataFields : [];
        var cals = [];
        if (calculatedColumns.length > 0) {
            for (var key in calculatedColumns) {
                let value = calculatedColumns[key]["label"];
                let option = {};
                option.value = value;
                option[DEFINE_FIELDS.FIELD_NAME_FIELD] = value;

                cals.push(option);
            }

        }

        var masterTransactionColumns = comp.props.dataColumns && comp.props.dataColumns.length > 0 ? comp.props.dataColumns.filter(e => e[EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID] === filterObj[_file]) : [];
        var columns = masterTransactionColumns.concat(calculatedColumns);
        var columnOptions = [];
                              
        if (columns.length > 0) {
            for (let key in columns) {
                let description = columns[key] && columns[key][DEFINE_FIELDS.DESCRIPTION_FIELD] ? " (" + columns[key][DEFINE_FIELDS.DESCRIPTION_FIELD] + ")" : "";
                let value = columns[key] && columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD]
                    ? columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD]
                    : "";
                
                let label = columns[key] && columns[key][DEFINE_FIELDS.DATA_FIELD] && columns[key][DEFINE_FIELDS.DATA_FIELD] !== ""
                    ? columns[key][DEFINE_FIELDS.DATA_FIELD] + description
                    : columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD] 
                    ? columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD] + description
                        : "";
                
                let option = {};
                option[DEFINE_FIELDS.FIELD_NAME_FIELD] = columns[key] && columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD] ? columns[key][DEFINE_FIELDS.FIELD_NAME_FIELD] : "";
                option[DEFINE_FIELDS.DATA_TYPE_FIELD] = columns[key] && columns[key][DEFINE_FIELDS.DATA_TYPE_FIELD] ? columns[key][DEFINE_FIELDS.DATA_TYPE_FIELD] : "";
                option.value = value;
                option.label = label;
                columnOptions.push(option);
            }
        }

        return columnOptions;
    }

    returnFunctionOptions(filterObj) {
        var functionOptions = [];
        var comp = this;
        var obj = comp.props.dataColumns.filter(e => e.raw_field_name === filterObj[_column] && e[_rawFileSubtypeId] === filterObj[_rawFileSubtypeId] && e.file_type === filterObj[_dataFileType])[0];
        var fieldDataType = obj ? obj[_fieldDataType] : filterObj && filterObj[_fieldDataType] ? filterObj[_fieldDataType] : "";
        if (extractValueFromObjects(numericFunctionOptions, "value_type").includes(fieldDataType.toLowerCase()) || [Formats.Numeric.toLowerCase(), Formats.DateTime.toLowerCase(), Formats.Date.toLowerCase(), Formats.Timestamp.toLowerCase()].includes(fieldDataType.toLowerCase())) {
            functionOptions = numericFunctionOptions;
        } else if (extractValueFromObjects(stringFunctionOptions, "value_type").includes(fieldDataType.toLowerCase())) {
            functionOptions = stringFunctionOptions;
        } else {
            functionOptions = stringFunctionOptions;
        }

        return functionOptions;
    }

    onDropDownChange(filterObj, attribute, rowIndex, inputValue, callback) {
        var comp = this;
        switch (attribute) {
            case _file:
                var columnOptions = this.returnColumnOptions(filterObj);
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_columnOptions] = columnOptions;
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_rawFileSubtypeId] = filterObj[_file];
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_rowPosition] = rowIndex;
            break;
            case _column:
                var functionOptions = this.returnFunctionOptions(filterObj);
                var obj = comp.props.dataColumns.filter(e => e.raw_field_name === filterObj[_column] && e[EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID] === filterObj[_file])[0];

                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_functionOptions] = functionOptions;
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_fieldDataType] = obj ? obj.field_data_type : ""; //STRING OR NUMERIC
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_dataFileType] = obj ? obj.data_file_type : ""; //WHICH FILE TYPE ex: transaction_data
                comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_fileType] = obj ? obj.file_type : "";
     
                break;
            case _function:
                var fieldDataType = comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_fieldDataType]; //STRING OR NUMERIC
                var dataFileType = comp.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[_dataFileType]; //WHICH FILE TYPE ex: transaction_data

                if ([_empty, _nempty, _startsWith, _endsWith, _nstartsWith, _nendsWith].indexOf(filterObj[_function]) === -1) {
                    comp.getAttrValues(filterObj, filterObj[_column], dataFileType, fieldDataType, rowIndex, inputValue, callback)
                } else if ([_empty, _nempty].indexOf(filterObj[_function]) > -1) {
                    comp.resetRowTotals();
                }
                break;      
        }

        if (callback && typeof callback === "function") {
            callback();
        } else {
            comp.forceUpdate(); //to update the dropdowns
        }

    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }
    
    setOpenConfirmCancelDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openConfirmCancelDialog: isOpen
      })
    }

    openConfirmCancelDialogActions = () => {
      return (
        <>
          <Button
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => {
              this.props.goBackToStaging();
              this.setOpenConfirmCancelDialog(false);
            }}
          />
          <Button
            label={lang.modal.buttons.no}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.setOpenConfirmCancelDialog(false)}
          />
        </>
      )
    }



    render() {
        var obj = this;
        var fileTypes = obj.props.dataColumns && obj.props.dataColumns.length > 0 ?
        obj.props.dataColumns.map(item => ({
            file_type: item[EXCLUDED_LINES_FIELDS.FILE_TYPE],
            data_file_type: item[EXCLUDED_LINES_FIELDS.DATA_FILE_TYPE],
            raw_file_subtype_id: item[EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID],
            display_file_type: item[EXCLUDED_LINES_FIELDS.DISPLAY_FILE_TYPE]
        })) : [];
    
        var fileTypeValues = [];
        var fileTypeOptions = [];

        fileTypes.forEach(function (item) {
            var i = fileTypeValues.findIndex(x => x[EXCLUDED_LINES_FIELDS.FILE_TYPE] === item[EXCLUDED_LINES_FIELDS.FILE_TYPE]
                && x[EXCLUDED_LINES_FIELDS.DATA_FILE_TYPE] === item[EXCLUDED_LINES_FIELDS.DATA_FILE_TYPE]);
            if(i <= -1){
                fileTypeValues.push(item);
            }
        });

        if (fileTypeValues.length > 0) {
            for (var key in fileTypeValues) {
                if([UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(fileTypeValues[key][EXCLUDED_LINES_FIELDS.DATA_FILE_TYPE])) {
                    continue;
                }
                let option = {};
                option.fileType = fileTypeValues[key][EXCLUDED_LINES_FIELDS.FILE_TYPE]
                option.dataFileType = fileTypeValues[key][EXCLUDED_LINES_FIELDS.DATA_FILE_TYPE]
                option.rawFileSubTypeId = fileTypeValues[key][EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID]
                option.displayFileType = fileTypeValues[key][EXCLUDED_LINES_FIELDS.DISPLAY_FILE_TYPE]
                option.value = fileTypeValues[key] && fileTypeValues[key][EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID]
                ? fileTypeValues[key][EXCLUDED_LINES_FIELDS.RAW_FILE_SUBTYPE_ID] : "";
                option.label = fileTypeValues[key][EXCLUDED_LINES_FIELDS.DISPLAY_FILE_TYPE]
                    ? fileTypeValues[key][EXCLUDED_LINES_FIELDS.DISPLAY_FILE_TYPE]
                    : "";
                fileTypeOptions.push(option);
            }
        }

       
        return (
            <div>
                <div id="excludeLines-parent" className="uk-display-inline-flex uk-width-1-1">
                    <div className="excluded-mapping-parent">
                        <div id="exclusion-overlay-div" className="exclusion-overlay-div">
                            <div className="uk-display-flex uk-flex-column align-items-center">
                                <i className="far fa-plus-circle uk-heading-primary uk-add" id="exclude-lines-add-row"></i>
                                <h4>Add Exclusions</h4>
                            </div>
                        </div>
                        <div className="uk-margin-xmedium-right uk-background-default uk-border">
                            <div className="mapping-header exclusions-table">
                                <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{capitaliseFirstLetterAfterChar(this.props.stagingReport).replaceAll("_", " ")}</span>
                                <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE}></i>
                            </div>
                            <div className="profitStackMapping" id="engineFilter_1">
                                <EngineFilterDialog
                                    ref={el => this.filterDialRef = el}
                                    stagingReport={this.props.stagingReport}
                                    showFileDropdown={true}
                                    scenario_id={this.props.scenarioId}
                                    fileTypeOptions={fileTypeOptions}
                                    file={this.state.file}
                                    function={this.state.function}
                                    column={this.state.column}
                                    value={this.state.value}
                                    field_data_type={this.state.field_data_type}
                                    file_type={this.state.file_type}
                                    onDropDownChange={this.onDropDownChange}
                                    getExclusionData={this.getExclusionData}
                                    prepareExclusion={this.prepareExclusion}
                                    updateRowTotals={this.updateRowTotals}
                                    showExclusionOverlay={this.showExclusionOverlay}
                                    compNumber={1} //1 for exclusions, 2 for vectors
                                    resetRowTotals={this.resetRowTotals}
                                    resetTotals={this.resetTotals}
                                    rowTotals={this.state.rowTotals}
                                    getValues={this.getAttrValues}
                                    onChangeAdvancedFilter={this.getExclusionData}
                                    checkExclusionsValidity={this.checkExclusionsValidity}
                                    getAdvancedFilterText = {this.getAdvancedFilterText}
                                    filterDialRef={this.filterDialRef}
                                    
                                />
                            </div>
                        </div>
                        <div className={this.table && !this.table.getData().length ? "uk-hidden" : "total-exclusions-table uk-margin-xmedium-top uk-margin-xmedium-right uk-background-default uk-border"}>
                            <div className="mapping-header">
                                <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">Total Exclusions</span>
                                <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE}></i>
                            </div>
                            <div id="total-exclusions" ref="total-exclusions"/>
                        </div>
                    </div>
      
                </div>
                <Modal
                  id={"confirm-cancel-dialog"}
                  openDialog={this.state.openConfirmCancelDialog}
                  bodyContent={() => <h4>{lang.discard_changes_confirmation}</h4>}
                  dialogActions={this.openConfirmCancelDialogActions}
                  closeClick={() => this.setOpenConfirmCancelDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                
                  <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </div>
            
        );
    }
}

export default ExcludedLines;