import { FILTER, QUADRANTS, STAGING_SECTIONS, ENGINE_FILTER, CONFIGURE_SECTIONS, UPLOAD_SECTIONS, SEGMENTS_TITLES, PSL_RETURN_NAMES } from "../../class/constants";
import { getTranslationFile, returnQuadrantAbv, buildNewObject, isQuadrant } from "../../class/utils";
import { Segment } from "../../components/Segment";
const $ = require('jquery');

const MESSAGES = getTranslationFile();

const FILTER_TYPE_VECTOR = FILTER.VALUES.TYPE.VECTOR;
const FILTER_TYPE_PROFITSTACK = FILTER.VALUES.TYPE.PROFIT_STACK;

const FIELD_QUADRANT = MESSAGES.ui_filter.dropdowns.fields_attributes.quadrant.value;
const FIELD_QUADRANT_TIER = MESSAGES.ui_filter.dropdowns.fields_attributes.quadrant_tier.value;

//Filter Dialog
const _type = FILTER.KEYS.TYPE;
const _vector = FILTER.KEYS.VECTOR;
const _field = FILTER.KEYS.FIELD;
const _function = FILTER.KEYS.FUNCTION;
const _entities = FILTER.KEYS.ENTITIES;

//Engine Filter Dialog
const _file = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _engineFunction = ENGINE_FILTER.KEYS.FUNCTION;
const _values = ENGINE_FILTER.KEYS.VALUES;

const _eq = MESSAGES.ui_filter.dropdowns.functions.equals.value;
const _neq = MESSAGES.ui_filter.dropdowns.functions.not_equals.value;
const _ct = MESSAGES.ui_filter.dropdowns.functions.contains.value;
const _nct = MESSAGES.ui_filter.dropdowns.functions.not_contains.value;
const _gt = MESSAGES.ui_filter.dropdowns.functions.greater.value;
const _lt = MESSAGES.ui_filter.dropdowns.functions.less.value;
const _empty = MESSAGES.ui_filter.dropdowns.functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.functions.not_empty.value;

var getFiltersOfType = function(allFilterRefs, filterType) {
    var arr = [];
    allFilterRefs.forEach(ref => {
        let filter = ref.current.filterObj;
        filter = allFilterRefs[filter];
        if(filter[_type] === filterType) {
            arr.push(filter);
        }
    });
    return arr;
};

var getFilterDisplayClass = function(filter, quadrantsDisabled, psFilterDisabled) {
    var className = "";
    //when no entities and operator is different than empty or not empty
    if([_empty, _nempty].indexOf(filter[_function]) === -1 && (!filter[_entities] || !filter[_entities].length)){ 
        return "";
    }
    
    if(filter[_type] === FILTER_TYPE_VECTOR) {
        if([FIELD_QUADRANT, FIELD_QUADRANT_TIER].indexOf(filter[_field].toLowerCase()) !== -1) {
            if(quadrantsDisabled) {
                className += "filter-disabled ";
            } else {
                className += returnQuadrantAbv(filter[_entities][0].value) + " ";
            }
        } else {
            className += "pi-chip "
        }

        if([_neq, _nct].indexOf(filter[_function]) > -1) {
            className += "filter-not ";
        }
    } else if(filter[_type] === FILTER_TYPE_PROFITSTACK) {
        if(psFilterDisabled){
            className += "filter-disabled ";
        } else {
            className += "filter-ps ";
        }
        if(filter[_function] === _neq) {
            className += "filter-not ";
        }
    }
    return className;
};

/**
 * returns the color of each profit segment, profit tier, quadrant and qt
 * @param {*} filter 
 * @param {*} quadrantsDisabled 
 * @returns 
 */
var getFilterBackgroundColor = function(filter, quadrantsDisabled) {
    //when no entities and operator is different than empty or not empty
    if([_empty, _nempty].indexOf(filter[_function]) === -1 && (!filter[_entities] || !filter[_entities].length)){ 
        return;
    }
    if(filter[_type] === FILTER_TYPE_VECTOR && [SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value, FIELD_QUADRANT, FIELD_QUADRANT_TIER].includes(filter[_field].toLowerCase()) && !quadrantsDisabled) {
        let segment = new Segment();
        return segment.getSegmentObject(filter[_entities][0].value.toLowerCase());
    } 
};

var getFilterQuadrantColorStyle = function(filter) {
    var className = "";
    if(!filter[_entities] || !filter[_entities].length){
        return "";
    }
    
    if(filter[_type] === FILTER_TYPE_VECTOR) {
        if([FIELD_QUADRANT, FIELD_QUADRANT_TIER].indexOf(filter[_field].toLowerCase()) !== -1) {
            // className += returnQuadrantAbv(filter[_entities][0].value) + " ";
            className += "linear-gradient(to right";
            var index = 0;
            filter[_entities].forEach(ent=>{
                var color = returnQuadrantAbv(ent.value, true);
                className += ", " + color + ", " + color + " " + (++index * 100/filter[_entities].length) + "%";
            });
            className += ")";
        }
    }
    return className;
};

var getNewQuadrantFilter = function(vector, quadrant, filterRowType) {
    var _field = MESSAGES.ui_filter.dropdowns.fields_attributes.quadrant.value;
    var _op = FILTER.VALUES.LOGICAL_OPERATOR.AND;
    var filterRowType = filterRowType || FILTER.VALUES.FILTER_ROW_TYPE.QUADRANT;    //filterRowType = "quadrant" by default if none selected

    quadrant = [QUADRANTS[quadrant]];
    
    return getNewEntityFilter(vector, _field, quadrant, filterRowType, _op);
}

/**
 * This function builds and returns a new entity filter object
 * @param {*} vector  the filter vector
 * @param {*} field   the filter field -- name, number, quadrant... or PS line
 * @param {*} entities  the filter value
 * @param {*} filterRowType     type of the filter ("drill" for drilling, "quadrant" for quadrants in PM, "filter" for normal filters)
 */
var getNewEntityFilter = function(vector, field, entities, filterRowType, logicalOperator, filterFunction=MESSAGES.ui_filter.dropdowns.functions.equals.value) {
    field = field || FILTER.VALUES.FIELD.KEY;   //if field passed as "", replace by "Key"
    var keys = [FILTER.KEYS.VECTOR, FILTER.KEYS.FIELD, FILTER.KEYS.FUNCTION, FILTER.KEYS.TYPE, FILTER.KEYS.PARENTHESIS_BEFORE, FILTER.KEYS.PARENTHESIS_AFTER];
    var values = [vector, field.toLowerCase(), filterFunction, FILTER.VALUES.TYPE.VECTOR, "(", ")"];

    keys.push(FILTER.KEYS.ENTITIES);
    if(typeof entities === "object") {
        values.push(entities.map(ent=>{
            ent.value = ent.value.replace(':',"$_$");
            ent.label = ent.label ? ent.label.replace(':',"$_$") : "";
            return ent;
        }));
    } else {
        //if enitites is a string, add it in value attr inside an object inside array
        var tempObj = {}
        if (entities !== undefined) {
          if(field === SEGMENTS_TITLES.PROFIT_TIERS.value) {
            tempObj.value = new Segment().getSegmentObject(entities)?.value;
            tempObj.label = new Segment().getSegmentObject(entities)?.originalLabel;
          }else if(field === SEGMENTS_TITLES.PROFIT_SEGMENTS.value){
            tempObj.value = entities.replace(':',"$_$");
            tempObj.label = new Segment().getSegmentObject(entities)?.originalLabel;
          } else {
            tempObj.value = entities.replace(':',"$_$");
            tempObj.label = entities.toUpperCase();
          }
          values.push([tempObj]);
        }
    }

    keys.push(FILTER.KEYS.LOGICAL_OPERATOR);
    if(logicalOperator) {
        values.push(logicalOperator.toUpperCase());
    } else {
        values.push(FILTER.VALUES.LOGICAL_OPERATOR.AND);
    }

    keys.push(FILTER.KEYS.FILTER_ROW_TYPE);
    if(filterRowType) {
        values.push(filterRowType);
    } else {
        values.push(FILTER.VALUES.FILTER_ROW_TYPE.FILTER);
    }
    
    return buildNewObject(keys, values);
}

var setUpDrillFilter = function(filterFromBox, isQuad, isRoundTwo,cardsData, cardIndex, removeDrill) {
    let allDrills = "";
    let tier = "";
    let type = "";
    let value = "";
    let quad = "";
    let tempFilter = [];
    if (cardIndex > -1 && !removeDrill) {
        cardsData = cardsData.filter(function(value, index, arr){ 
        return index <= cardIndex;
        });
    }else if (removeDrill) {
        cardsData = cardsData.filter(function(value, index, arr){ 
            return index !== cardIndex;
            });
    }
    for(let e in cardsData){
        let cardsDataDetails = cardsData[e].data[0];
        tier = cardsDataDetails.tier;
        let tierKey = cardsDataDetails.tier_key.trim();
        if (typeof tier !== typeof undefined && tier !== false) {
            tier = tier.replaceAll(" ", "");
            allDrills = cardsDataDetails.key !== undefined ? cardsDataDetails.key : cardsDataDetails.quadranttier !== undefined ? cardsDataDetails.quadranttier :  cardsDataDetails.quadrant
            isQuad = cardsDataDetails.key !== undefined ? false: true;
            quad = isQuadrant(allDrills.toLowerCase(), isQuad);
            
            value = (quad) ? returnQuadrantAbv(allDrills.trim()) : allDrills.trim().toUpperCase();
            type = (quad === true) ? PSL_RETURN_NAMES.QUADRANT : PSL_RETURN_NAMES.KEY;
            
            tempFilter.push(getNewEntityFilter(tierKey, type, value.trim(), FILTER.VALUES.FILTER_ROW_TYPE.DRILL, FILTER.VALUES.LOGICAL_OPERATOR.AND));
        }

        let otherSelectedVectorsData = cardsData[e].otherSelectedVectorsData;
        for(let vector of otherSelectedVectorsData) {
          if(vector.skipInFilter) {
            continue; // this condition means that we don't want to add this vector to filter because it is not name, number, segment or segment tier
          } else if(vector.hasNameAndNumber) {
            tempFilter.push(getNewEntityFilter(vector.tier_key, PSL_RETURN_NAMES.NUMBER, vector.number.trim(), FILTER.VALUES.FILTER_ROW_TYPE.DRILL, FILTER.VALUES.LOGICAL_OPERATOR.AND));
            tempFilter.push(getNewEntityFilter(vector.tier_key, PSL_RETURN_NAMES.NAME, vector.name.trim(), FILTER.VALUES.FILTER_ROW_TYPE.DRILL, FILTER.VALUES.LOGICAL_OPERATOR.AND));
          } else {
            tempFilter.push(getNewEntityFilter(vector.tier_key, vector.type, vector.value.trim(), FILTER.VALUES.FILTER_ROW_TYPE.DRILL, FILTER.VALUES.LOGICAL_OPERATOR.AND));
          }
        }     
    }
    
    return filterFromBox.concat(tempFilter);
}

//temporary function, it should be removed while transforming profit vectors table to Tabulator    #Tabulator_PV
var setUpVectorsDrillFilter = function(filterFromBox, isQuad, isRoundTwo) {
    var allDrills = "";
    var tier = "";
    var type = "";
    var value = "";
    var quad = "";
    var tempFilter = [];

    $("#Table_Top").find("tbody tr").each( function() {
        tier = $(this).find(".tier").text().trim();

        if (typeof tier !== typeof undefined && tier !== false && tier !== "") {
            tier = tier.replaceAll(" ", "");
            allDrills = $(this).find(".number:first").text(); 
            quad = isQuadrant(allDrills, isQuad && (!isRoundTwo || tempFilter.length === 0));

            value = (quad) ? returnQuadrantAbv(allDrills.trim()) : allDrills.trim().toUpperCase();
            type = (quad === true) ? "quadrant" : "number";

            tempFilter.push(getNewEntityFilter(tier, type, value.trim(), FILTER.VALUES.FILTER_ROW_TYPE.DRILL, FILTER.VALUES.LOGICAL_OPERATOR.AND));
        }
    });

    return filterFromBox.concat(tempFilter);
}

/**
 * this function validates a string value, returns false if the string is undefined, null or empty
 * @param {*} str 
 */
var isValidVal = function (str) {
    if (str === null || str === undefined || str.length === 0) str = ""; //if it comes as an empty array
    return !!str || !!(str+"").length;
}

/**
 * This function loops over the attributes of the filter row and returns true if all the attributes are filled
 * @param {*} filter 
 */
var validateFilterRow = function (filter, report) {
    if ([STAGING_SECTIONS.METRICS_MAPPING, STAGING_SECTIONS.MAPPING_TIE_OFF, STAGING_SECTIONS.VECTOR_MAPPING, STAGING_SECTIONS.CALCULATED_COLUMNS, STAGING_SECTIONS.PROFIT_STACK_MAPPING,CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS].includes(report)) {
        if (isValidVal(filter[_file]) || isValidVal(filter[_column]) || isValidVal(filter[_engineFunction]) ||
        ((filter[_values] && filter[_values].length > 0 && ![_empty, _nempty].includes(filter[_engineFunction])) ||
                (filter[_values] && filter[_values].length === 0 && [_empty, _nempty].includes(filter[_engineFunction])))) {
            return true;       
        } else {
            return false
        }
    } else {
        if(!filter || !filter[_type] || !filter[_type].length){
            return false;
        }
        
        if (isValidVal(filter[_vector]) && isValidVal(filter[_field]) && isValidVal(filter[_function])) {
            if (filter[_type] === FILTER_TYPE_VECTOR) {
                if ([_empty, _nempty].indexOf(filter[_function]) === -1) { //not empty or is empty
                    if (filter[_entities] && filter[_entities].length === 0) {
                        return false;
                    } else if (Array.isArray(filter[_entities])) {
                        filter[_entities].forEach(ent => {
                            if (!isValidVal(ent.value)) {
                                return false;
                            }
                        });
                    } else {
                        if (!isValidVal(filter[_entities])) {
                            return false;
                        }
                    }
                } else {
                    return true; //no need to check for the value when the operator is empty or not empty
                }
            } else if(filter[_type] === FILTER_TYPE_PROFITSTACK) {
                if(!isValidVal(filter[_entities]) || isNaN(filter[_entities])) {
                    return false;
                }
            }
            return true;       
    }
}

    return false;
};

function returnLastDatasets(fromOptions, dataset) {
    let datasets = [];

    fromOptions.forEach((option, index)=> {
        if (option && option.value === dataset) {
            if(fromOptions[index] && fromOptions[index].value && !fromOptions[index]["isDisabled"]){
                datasets.push(fromOptions[index])
            }
            if(fromOptions[index-1] && fromOptions[index-1].value && !fromOptions[index-1]["isDisabled"]) {
                datasets.push(fromOptions[index - 1]);
            }
            if(fromOptions[index-2] && fromOptions[index-2].value && !fromOptions[index-2]["isDisabled"]) {
                datasets.push(fromOptions[index - 2]);
            }
            if(fromOptions[index-3] && fromOptions[index-3].value && !fromOptions[index-3]["isDisabled"]) {
                datasets.push(fromOptions[index - 3]);
            }
        }
    });

    return datasets;
}

function arrangeFileNames(data,excludeOthers = false) {
    function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const valueA = a.label ? a.label.toUpperCase() : "";
        const valueB = b.label ? b.label.toUpperCase() : "";
      
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
    }
    
    var fileNamesWithTitles = [];
    if (data && data.length > 0) {
        var GlFiles = data.filter(e => e.data_file_type === UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER || e.dataFileType === UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER);
        var TDFiles = data.filter(e => e.data_file_type === UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA || e.dataFileType === UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA);
        var AFiles = data.filter(e => e.data_file_type === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES || e.dataFileType === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES);
        var MDFiles = data.filter(e => e.data_file_type === UPLOAD_SECTIONS.FIELDS.MASTER_DATA || e.dataFileType === UPLOAD_SECTIONS.FIELDS.MASTER_DATA);
        var budgetingFiles  = data.filter(e => e.data_file_type === UPLOAD_SECTIONS.FIELDS.BUDGETING_DATA);
        var otherFiles = data.filter(e => !GlFiles.includes(e) && !TDFiles.includes(e) && !AFiles.includes(e) && !MDFiles.includes(e) && !budgetingFiles.includes(e));
        
       if (GlFiles.length > 0) {
           fileNamesWithTitles.push({ value: "general_ledger_title", label: "General Ledger", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
           fileNamesWithTitles = fileNamesWithTitles.concat(GlFiles.sort(compare));
       }
        
        if (TDFiles.length > 0) {
            fileNamesWithTitles.push({ value: "transaction_data_title", label: "Transaction Data", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            fileNamesWithTitles = fileNamesWithTitles.concat(TDFiles.sort(compare));
        }
        
        if (MDFiles.length > 0) {
            fileNamesWithTitles.push({ value: "master_data_title", label: "Master Data", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            fileNamesWithTitles = fileNamesWithTitles.concat(MDFiles.sort(compare));
        }

        if (AFiles.length > 0) {
            fileNamesWithTitles.push({ value: "anc_file_title", label: "Ancillary Files", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            fileNamesWithTitles = fileNamesWithTitles.concat(AFiles.sort(compare));
        }
        
        if (!excludeOthers && otherFiles.length > 0) {
            fileNamesWithTitles.push({ value: "other_title", label: "Other", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            fileNamesWithTitles = fileNamesWithTitles.concat(otherFiles.sort(compare));
        }
    }


    return fileNamesWithTitles;
}


function arrangeData(data) {
    function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const valueA = a.label ? a.label.toUpperCase() : "";
        const valueB = b.label ? b.label.toUpperCase() : "";
      
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
    }
    
    var dataWithTitles = [];
    if (data && data.length > 0) {
        var vectors = data.filter(e => e.file_type === "vectors");
        var calculatedLines = data.filter(e => e.file_type === "calculated_columns");
        
        if (vectors.length > 0) {
            dataWithTitles.push({ value: "vector_title", label: "Vectors", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            dataWithTitles = dataWithTitles.concat(vectors.sort(compare));
        }
        
        if (calculatedLines.length > 0) {
            dataWithTitles.push({ value: "cal_title", label: "Calculated Columns", isDisabled: true, data_file_type: "", file_type: "", raw_file_subtype_id: "" });
            dataWithTitles = dataWithTitles.concat(calculatedLines.sort(compare));
        }
    }


    return dataWithTitles;
}

export {
    getFiltersOfType, getFilterDisplayClass, getFilterQuadrantColorStyle, getNewQuadrantFilter,
    getNewEntityFilter, setUpDrillFilter, setUpVectorsDrillFilter, validateFilterRow, returnLastDatasets, arrangeFileNames, arrangeData, getFilterBackgroundColor
}