import { useEffect, useState,useImperativeHandle, forwardRef } from "react";
import { BUTTON_TYPE, SIZES, BUTTON_VARIANT, CONFIGURE_GL_ACCOUNT_RANGES, GL_EXCLUSIONS_TABS, STAGING_SECTIONS, ENGINE_FILTER, Formats, RAW_ITEMS, API_URL } from "../../class/constants";
import { copyObjectValues, getTranslationFile, hasLettersAndSpecialCharacters } from "../../class/utils.js";
import Button from "../../newComponents/Button";
import ConfigureGlAccountRangesRow from "./ConfigureGlAccountRangesRow";
import { ToggleTab } from "../../form/elements";
import { useRef } from "react";
import EngineFilterDialog from "../../sections/filter/EngineFilterDialog";
import { alertAndLogError, getObjectAsArray } from "../../class/jqueries";

import { setLocalStorageValueByParameter } from "../../class/common";
import Popup from 'react-popup';
const lang = getTranslationFile();

const ConfigureGlAccountRangesModal = forwardRef((props,ref) => {

    const isExclusion = props.selectedRow == CONFIGURE_GL_ACCOUNT_RANGES.KEYS.EXCLUSIONS;
    const title = CONFIGURE_GL_ACCOUNT_RANGES.MODAL.TITLES[props.selectedRow];
    const ACCOUNT_START_RANGE = CONFIGURE_GL_ACCOUNT_RANGES.KEYS.ACCOUNT_START_RANGE;
    const ACCOUNT_END_RANGE = CONFIGURE_GL_ACCOUNT_RANGES.KEYS.ACCOUNT_END_RANGE;
    const exclusionTabs = GL_EXCLUSIONS_TABS;
    const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
    const _fileType = ENGINE_FILTER.KEYS.FILE;
    const _column = ENGINE_FILTER.KEYS.COLUMN;
    const _function = ENGINE_FILTER.KEYS.FUNCTION;
    const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
    const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
    const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
    const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
    const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
    const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;
    const _empty = lang.ui_filter.dropdowns.engine_filter_functions.empty.value;
    const _nempty = lang.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
    const _ct = lang.ui_filter.dropdowns.engine_filter_functions.contains.value;
    const _nct = lang.ui_filter.dropdowns.engine_filter_functions.not_contains.value;
    
    const [ranges, setRanges] = useState({});
    const [exclusionTab,setExclusionTab] = useState(GL_EXCLUSIONS_TABS[0].value);
    const filterDialRef = useRef(null);
    const [valueOptions,setValueOptions] = useState({})

    useImperativeHandle(ref, () => ({
        getRanges:()=>{
            return ranges;
        },
        validateAccounts : (isExclusion) => {
            validateAccounts(isExclusion);
        }
    }));

    const validateFilter = () => {
        let result = true;
        if(filterDialRef.current.state.filterRefs.length > 1){
            for (let e in filterDialRef.current.state.filterRefs) {
                let currFilter =  filterDialRef.current.state.filterRefs[e].ref.current.filterObj;
                 if (currFilter[_fileType] === "" || currFilter[_column] === "" ||  currFilter[_function] === "" || !currFilter[_fileType] || !currFilter[_column]  || !currFilter[_function] || (Array.isArray(currFilter["values"]) && !currFilter["values"]?.length && [_empty, _nempty].indexOf(currFilter[_function]) === -1) 
                 || (!Array.isArray(currFilter["values"]) && currFilter["values"] === "")) {
                        result = false;
                 }
            }   
        } else {
            let currFilter = filterDialRef?.current?.state?.filterRefs[0]?.ref?.current?.filterObj
            if (!filterDialRef?.current?.state?.filterRefs.length) {
                result = true;
            }else if (currFilter[_fileType] !== "" && currFilter[_column] !== "" &&  currFilter[_function] !== "" && ((Array.isArray(currFilter["values"]) && currFilter["values"]?.length && [_empty, _nempty].indexOf(currFilter[_function]) === -1) || ([_empty, _nempty].indexOf(currFilter[_function]) !== -1)|| (!Array.isArray(currFilter["values"]) && currFilter["values"] !== "")) || 
            (currFilter[_fileType] === "" && currFilter[_column] === "" &&  currFilter[_function] === "" && ((Array.isArray(currFilter["values"]) && !currFilter["values"]?.length)))) {
                result = true;
            }  else {
                result = false;
            }
        }
        return result;
    }
    const fillColumnOptions = (item) => {
        var options = props.allActualGLColumns.filter(row=>row[RAW_ITEMS.SUBTYPE_ID] === Number(item[RAW_ITEMS.SUBTYPE_ID]));
                      var cols = [];
                      for (var e in options) {
                        cols.push({label: options[e][RAW_ITEMS.FIELD_NAME], value: options[e][RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_NAME]: options[e][RAW_ITEMS.SUBTYPE_NAME],
                            [RAW_ITEMS.SUBTYPE_ID]: options[e][RAW_ITEMS.SUBTYPE_ID], field_data_type: options[e][RAW_ITEMS.FIELD_DATA_TYPE],
                            [RAW_ITEMS.DATA_FIELD]: options[e][RAW_ITEMS.DATA_FIELD]
                        })
                      }
        return cols;
    }
    useEffect(() => {
        if(props.data){
            setRanges(props.data);
        }
        if(props.exclusionFilter) {
            if(filterDialRef?.current?.state){
                let filterObjects = copyObjectValues(JSON.parse(props.exclusionFilter)); //filter from api
                if(filterObjects?.length && !JSON.parse(props?.filtersRef)?.length){
                    filterDialRef.current.state.filterRefs = [];
                    filterObjects.forEach((item)=>{
                      if(item[_valueOptions]) {
                        let valueOptions = item[_valueOptions].map(e=> {
                          return {value:e.attribute,label:e.attribute}
                        })
                        item[_valueOptions] = valueOptions;
                      }
                      var newFilter = getNewFilterRefObject();
                      item[_columnOptions] = fillColumnOptions(item);
                      newFilter.ref.current = { filterObj: item };
                      filterDialRef.current.state.filterRefs.push(newFilter);
                      filterDialRef.current.refresh()
                    });
                } else if (JSON.parse(props?.filtersRef)?.length) {
                    filterDialRef.current.state.filterRefs = [];
                    JSON.parse(props?.filtersRef)?.map((item) => {
                        var newFilter = getNewFilterRefObject();
                        newFilter.ref.current = { filterObj: item };
                        filterDialRef.current.state.filterRefs.push(newFilter);
                        filterDialRef.current.refresh()
                    });
                }
              }
        }
    }, []);

 /**
  * this function is called upon changing input of row, so ranges are updated and ready to be saved in DB
  * @param {*} rowRanges updated ranges from table
  * @param {*} i index of edited/added row
  */
    const setRowRanges = (rowRanges, i) => {
        if(Object.keys(rowRanges).length > 0){
            let currData = copyObjectValues(ranges);
            let rowRangesKeys = Object.keys(rowRanges);
            if (currData[props.selectedRow].length > i){
                rowRangesKeys.map(key => currData[props.selectedRow][i][key] = rowRanges[key])
            } else {
                currData[props.selectedRow].push(rowRanges);
            }
            setRanges(currData);
        }
    }
    const validateAccounts = (isExclusion) => {
        if(isExclusion) {
            validateAccountExcludedParameter();
        } else {
            validateAccountRanges();
        }
    }

    

/**
 * validate if ranges has empty rows, or startRange > ende Range
 * if found prevent from proceeding and show error message
 * else close modal
 */
    const validateAccountExcludedParameter = () => {
        let filtersObj = extractFilterFromFilterRefs();
        let exclusionValidationOne =  ranges[props.selectedRow].length > 1  && ranges[props.selectedRow].filter(e=>e[ACCOUNT_START_RANGE] === "" || e[ACCOUNT_END_RANGE] === "").length > 0;
        let exclusionValidationTwo =  ranges[props.selectedRow].length === 1  && ranges[props.selectedRow].filter(e=>(e[ACCOUNT_START_RANGE] !== "" && e[ACCOUNT_END_RANGE] === "") || (e[ACCOUNT_START_RANGE] === "" && e[ACCOUNT_END_RANGE] !== "")).length > 0
        if(!validateFilter() || exclusionValidationOne  || exclusionValidationTwo){
            props.setShowError(true);
        } else {
            props.saveRanges(filtersObj);
            props.setFilter(filtersObj);
        }
    }

    const validateAccountRanges = () => {
        let rows =  ranges[props.selectedRow];
        let corruptedRows;
        if(isExclusion){
            corruptedRows = rows.find(row => Number(row[ACCOUNT_START_RANGE]) > Number(row[ACCOUNT_END_RANGE]));
        }else{
            corruptedRows = rows.find(row => Number(row[ACCOUNT_START_RANGE]) > Number(row[ACCOUNT_END_RANGE])
                                        || row[ACCOUNT_START_RANGE] === ""
                                        || row[ACCOUNT_END_RANGE] === "" 
                                        || validateAccountRangesWithChars(row)
                                        );
        }
        if(corruptedRows){
            props.setShowError(true);
        } else {
            let exclusionFilter = props.exclusionFilter
            if(Array.isArray(props.exclusionFilter)){
                exclusionFilter = JSON.stringify(props.exclusionFilter, null, 0);
            }
            props.saveRanges(exclusionFilter);
        }

    }
/**
 * if start or end range has chars, check if both ranges are equal
 * if not, the user should be blocked
 * @param {*} row 
 * @returns 
 */
    const validateAccountRangesWithChars = (row) => {
        let hasErrors = false;
        let startHasChar = hasLettersAndSpecialCharacters(row[ACCOUNT_START_RANGE]);
        let endHasChar = hasLettersAndSpecialCharacters(row[ACCOUNT_END_RANGE]);
        if(startHasChar || endHasChar){
            hasErrors = row[ACCOUNT_START_RANGE] !== row[ACCOUNT_END_RANGE];
        }
        return hasErrors
    }
 
/**
 * adds empty row to modal so user can add ranges
 */
    const addRow = () => {
        let currData = copyObjectValues(ranges);
        let emptyRow = {name:props.selectedRow, [ACCOUNT_START_RANGE]: "",[ACCOUNT_END_RANGE]: ""};
        currData[props.selectedRow].push(emptyRow);
        setRanges(currData);
    }

/**
 * removes row of index clicked
 * @param {*} indexToRemove index of row where delete is clicked
 */

    const removeRow = (indexToRemove) => {
        let currData = copyObjectValues(ranges);
        if (indexToRemove >= 0 && indexToRemove < currData[props.selectedRow].length) {
            const newArray = currData[props.selectedRow].slice(0, indexToRemove).concat(currData[props.selectedRow].slice(indexToRemove + 1));
            currData[props.selectedRow] = newArray;
          }
        setRanges(currData);
    }

    const handleSelectTab  = (tab) => {
        setExclusionTab(tab)
        props.setShowError(false);
    }
    const getNewFilterRefObject = () =>{
        return { ref: React.createRef(), lastUpdated: Date.now().valueOf(), rand: Math.random() };
      }
      const setFilterAttrOptions = (rowIndex, attribute, options) => {
       
        if(!filterDialRef.current.state.filterRefs[rowIndex] || !filterDialRef.current.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
          filterDialRef.current.state.filterRefs[rowIndex].ref.current.isLoading = false;
        }
        filterDialRef.current.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options;
        let menuIsOpen = filterDialRef.current.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        filterDialRef.current.state.filterRefs[rowIndex].ref.current.refresh(function() {
            if(menuIsOpen) {
              filterDialRef.current.state.filterRefs[rowIndex].ref.current.selectRef.focus();
            }
        });
      }
      const isFilterObjectEmpty = (filterObject) =>{
        if ((filterObject[_fileType] === "" && filterObject[_column] === "" && filterObject[_function] === "" && (filterObject["values"] === "" || !filterObject["values"].length)) || (!filterObject[_fileType] && !filterObject[_column] && !filterObject[_function] && !filterObject["values"])) {
            return true;
        }
        return false;
      }
      const extractFilterFromFilterRefs = () => {
        let filterObjects = [];
        if(filterDialRef?.current?.state?.filterRefs?.length){
            for(let e in filterDialRef.current.state.filterRefs){
              let filterObject = filterDialRef.current.state.filterRefs[e].ref.current.filterObj;
              if(isFilterObjectEmpty(filterObject)){
                    break;
              }
              filterObjects.push(filterObject);
            }
        } 
        let jsonString = JSON.stringify(filterObjects, null, 0)
        return jsonString;
      }
    
      const handleFilterRowChange = (object, attribute, rowIndex, conditionIndex, valueObj, inputValue, tempExceptionData) => {
       
       var row =  filterDialRef.current && filterDialRef.current.state.filterRefs &&
       filterDialRef.current.state.filterRefs[rowIndex].ref.current.filterObj ? filterDialRef.current.state.filterRefs[rowIndex].ref.current.filterObj : {};
        let callback = ()=>{};        
        switch (attribute){
            case _column:
                var functionOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);
                callback = function(values) {
                    //setting the value options
                    setFilterAttrOptions(rowIndex, _valueOptions, values);
                    row[_valueOptions] = values;
                }
                if (row && row[_fieldDataType].toLowerCase() !== Formats.Numeric.toLowerCase()) {
                    var subTypeId = row[RAW_ITEMS.SUBTYPE_ID];
                    var type = ""
                    var value = row[_column];     
                    let filterAttribute = "";
                    getColumnValues(subTypeId, row[_fieldDataType], value, rowIndex, row[_function], rowIndex, "", callback, type,"",filterAttribute);
                }
                break;
            case _fileType:
                var options = props.allActualGLColumns;
                var options = props.allActualGLColumns.filter(row=>row[RAW_ITEMS.SUBTYPE_ID] === valueObj[RAW_ITEMS.SUBTYPE_ID]);
                var cols = [];
                for (var e in options) {
                    cols.push({label: options[e][RAW_ITEMS.FIELD_NAME], value: options[e][RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_NAME]: options[e][RAW_ITEMS.SUBTYPE_NAME],
                        [RAW_ITEMS.SUBTYPE_ID]: options[e][RAW_ITEMS.SUBTYPE_ID], field_data_type: options[e][RAW_ITEMS.FIELD_DATA_TYPE],
                        [RAW_ITEMS.DATA_FIELD]: options[e][RAW_ITEMS.DATA_FIELD]
                    })
                }
                setFilterAttrOptions(rowIndex, _columnOptions, cols);
                break;
    
            case _logicalOperator: // to enable column drop down when adding new row
                setFilterAttrOptions(rowIndex+1, _fileOptions, row[_fileOptions], row[_rawFileSubtypeId]);
                setFilterAttrOptions(rowIndex+1, _columnOptions, row[_columnOptions]);
    
        }
    
    } 
    const getColumnValues =(subtypeId, fieldDataType, value, index, functionValue, rowIndex, inputValue, callback, type,vectorId,filterAttribute) => {
      var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
      // only send the request once for the same subtype and column
      if(valueOptions[subtypeId+"_"+value+"_values"] !== undefined) {
          if(valueOptions[subtypeId+"_"+value+"_values"] !== "request_pending") {
              if(callback && typeof callback === "function"){
                  callback(valueOptions[subtypeId+"_"+value+"_values"]);
              }
              if(filterDialRef.current.state.filterRefs.length > 0 &&filterDialRef.current.state.filterRefs[index].current){
                filterDialRef.current.state.filterRefs[rowIndex].ref.current.filterObj[_valueOptions] = obj.state[subtypeId+"_"+value+"_values"];
              }
          }
          return;
      } 
      valueOptions[subtypeId+"_"+value+"_values"] = "request_pending";
      var dataBody = { 
          action: "getColumnValues",
          rawFileSubTypeId: subtypeId,
          field_dataType: fieldDataType,
          attribute: value,
          input_value : inputValue ? inputValue : "",
          type: type ? type : "",
      };
      dataBody["filterAttribute"] = filterAttribute;
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const path = API_URL.DATA_MODELING;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
          .then((response)=>{    
              if(response.status === 403) {
              }
              return response.json()})
          .then((data)=>{
              if(data.data){
                  var values=[];
                  data.data.map(function(item, index){
                      if (![_nempty, _empty].includes(functionValue)) {
                          if (item.attribute !== "" && item.attribute !== undefined) {
                              values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                          }
                      } else {
                          values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                      }
                  });
                  valueOptions[subtypeId+"_"+value+"_values"] = values;
                  callback(values);
              }
          }).catch((error)=>{
              alertAndLogError(error);
      });
    }
    const renderRows = () => {
        const row = [];
            let rangesVar = ranges[props.selectedRow] || [];
            let rowCount = rangesVar.length;
            for (let i = 0; i < rowCount; i++) {
                row.push(<ConfigureGlAccountRangesRow rowCount={rowCount} key={i} count={i} data={rangesVar[i]} setRowRanges={setRowRanges} removeRow={removeRow} />);
            }
            
            return row;
        
      };

    return (
        <div>
            <h4 className="uk-text-bold">{title}</h4>
            <div style={{marginTop:"1vw"}}>
                {isExclusion ? 
                <ToggleTab className={""} isDisabled={false} options={exclusionTabs} type="table" onSelectTab={(tab) => {handleSelectTab(tab)}} defaultValue={exclusionTab}/> 
                : ""}
                <div className={(isExclusion && exclusionTab === GL_EXCLUSIONS_TABS[0].value ) || !isExclusion  ? "med-gap-flex" :" med-gap-flex uk-hidden"}> 
                    <div className="med-gap-flex fs-12">
                        <div style={{display: "flex", columnGap:10,marginRight:"30px"}}>
                            <span style={{width: "50%"}}>{CONFIGURE_GL_ACCOUNT_RANGES.MODAL.INPUTS.STARTING}</span>
                            <span style={{width: "50%"}}>{CONFIGURE_GL_ACCOUNT_RANGES.MODAL.INPUTS.ENDING}</span>
                        </div>
                        {renderRows()}
                    </div>
                    <div>
                        <Button  
                            label={CONFIGURE_GL_ACCOUNT_RANGES.MODAL.BUTTON.ADD_RANGE}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-button-icon justify-content-start"
                            onBtnClick={addRow}
                            leftIcon={<i className="far fa-plus" />}
                            />
                    </div>
                </div>
                <div style={{}} className={(isExclusion && exclusionTab ===  GL_EXCLUSIONS_TABS[1].value)  ? "" :"uk-hidden"}>
                    <EngineFilterDialog
                        ref={filterDialRef}
                        stagingReport={STAGING_SECTIONS.CONFIGURE_TIE_OUT}
                        showFileDropdown={true}
                        compNumber={0}
                        onDropDownChange={handleFilterRowChange}
                        fileTypeOptions={props.glSubtypes}
                        hideAdvancedFilter={true}
                    />
                </div>      
            </div>
         </div>
    );
});

export default ConfigureGlAccountRangesModal;
