import React, { Component } from 'react';
import { getTranslationFile, copyObjectValues } from '../../class/utils.js';
import { RAW_ITEMS, STAGING_SECTIONS, UPLOAD_SECTIONS, METRICS_MAPPING, RAND, BUTTON_VARIANT, SIZES, BUTTON_TYPE} from '../../class/constants';
import FormulaDrop from './FormulaDrop';
import ConditionValueFormula from './ConditionValueFormula';
import Button from '../../newComponents/Button.js';

const MESSAGES = getTranslationFile();
const SAMPLE_CONDITION_OBJ = {
    filters: [],
    result: []
};

const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;

/**
 * Value Formula Component containing add condition button and ConditionValueFormula Component
 * ValueFormula/ Metrics Mapping
 * @author [Sarah Farjallah]
 */
class ValueFormula extends Component{
    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.getNewConditionValueFormula = this.getNewConditionValueFormula.bind(this);
        this.addCondition = this.addCondition.bind(this);
        this.displayRows = this.displayRows.bind(this);
        this.setFormulaIndex = this.setFormulaIndex.bind(this);
        this.deleteLastFormulaItem = this.deleteLastFormulaItem.bind(this);
        this.resetFormulaChanges = this.resetFormulaChanges.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
        this.appendToFormula = this.appendToFormula.bind(this);
        this.initFormula = this.initFormula.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.getColumnValuesFilter = this.getColumnValuesFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.removeFilterRowInCondition = this.removeFilterRowInCondition.bind(this);
        this.setFiles = this.setFiles.bind(this);

        this.isAdvanced = false;
        this.finalFormula = {
            result: [],
            conditions: []
        };
        this.finalFormula_unchanged = {
            result: [],
            conditions: []
        };
        this.conditionRefs = [];
    }
    
    initFormula() {
        
    }

    deleteFormula(conditionNumber) {
        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result = [];
        } else {
            this.finalFormula.result = [];
        }
        this.setState(this.state);
    }

    resetFormulaChanges(conditionNumber) {
        var tempResult = [];
        //read from the unchanged formula, if the condition doesnt exist in the unchanged obj,
        //it's a new one, reset it by emptying it, hence "[]"
        var resultToLoop = conditionNumber !== undefined ? this.finalFormula_unchanged.conditions[conditionNumber] ?
        this.finalFormula_unchanged.conditions[conditionNumber].result : [] : this.finalFormula_unchanged.result;
        resultToLoop.map((item, key)=>{
            tempResult.push(item);
        });

        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result = tempResult;
        } else {
            this.finalFormula.result = tempResult;
        }
        this.setState(this.state);
    }

    deleteLastFormulaItem(conditionNumber) {
        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result.pop();
        } else {
            this.finalFormula.result.pop();
        }
        this.setState(this.state);
    }

    resetFormula = () => {
        if(this.finalFormula_unchanged){
            this.finalFormula.result=this.finalFormula_unchanged.result
        }
        for (var e in this.finalFormula.conditions) { this.resetFormulaChanges(Number(e)); }
        this.setState(this.state);
    }

    handleChange(type, e) {  
        var tempObj = this.state.metricColumnObject;
        if(e!== null) {
            if (this.state.metricConfigurationObject !== undefined) {
                tempObj[RAW_ITEMS.TYPE_NAME] = e[RAW_ITEMS.TYPE_NAME];
                tempObj[RAW_ITEMS.SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
                tempObj[RAW_ITEMS.SUBTYPE_NAME] = e.value;
                tempObj[RAW_ITEMS.FIELD_NAME] = "";
            } else{
                tempObj = {};
                tempObj[RAW_ITEMS.SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
                tempObj[RAW_ITEMS.TYPE_NAME] = e[RAW_ITEMS.TYPE_NAME];
                tempObj[RAW_ITEMS.SUBTYPE_NAME] = e.value;
            } 
        }
        this.setState({
            metricConfigurationObject: tempObj
        },function(){
            this.props.updateMetricObjects(tempObj, this.props.rowIndex);
        });       
    }

    getNewConditionValueFormula() {
        return { ref: React.createRef(), lastUpdated: Date.now().valueOf(), [RAND]: Math.random() };
    }

    addCondition() {
        this.finalFormula.conditions.push(copyObjectValues(SAMPLE_CONDITION_OBJ));
        this.conditionRefs.push(this.getNewConditionValueFormula());
        
        this.setState(this.state);
    }

    deleteCondition(index) {
        this.finalFormula.conditions.splice(index, 1);
        this.conditionRefs.splice(index, 1);
        
        this.setState(this.state);
    }

    removeFilterRowInCondition(rowIndex, conditionIndex) {
        this.finalFormula.conditions[conditionIndex].filters.splice(rowIndex, 1);

        this.setState(this.state);
    }

    setFiles(files) {
        this.setState({
            fileNames: files
        });
    }

    getFiles(files, doNotRefresh){
        var arr = this.props.allTypeColumns;
        var output = [];
            for(var i in arr) {
                if(output.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === arr[i][RAW_ITEMS.SUBTYPE_NAME]).length === 0){
                    output.push({value:arr[i][RAW_ITEMS.SUBTYPE_NAME], label:arr[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME], [RAW_ITEMS.SUBTYPE_ID]:arr[i][RAW_ITEMS.SUBTYPE_ID],
                            [RAW_ITEMS.SUBTYPE_NAME]: arr[i][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.TYPE_NAME]: arr[i][RAW_ITEMS.TYPE_NAME] });
                }
            }
            output =  output.filter(row=>row[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.MASTER_DATA || row[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA)
            if(output.filter(e=>e.value === "vectors").length === 0) {
                output.push({value:"vectors", label:"Vectors", [RAW_ITEMS.SUBTYPE_NAME]:"vectors", [RAW_ITEMS.SUBTYPE_ID]:"vectors", [RAW_ITEMS.TYPE_NAME]:"vectors"});
                output.push({value: _calcCols, label: MESSAGES.calculated_columns, [RAW_ITEMS.SUBTYPE_NAME]:_calcCols, [RAW_ITEMS.SUBTYPE_ID]:_calcCols, [RAW_ITEMS.TYPE_NAME]:_calcCols})    
        }

        files = files ? output.concat(files) : output;

        if (doNotRefresh) {
            return files;
        } else {
            this.setState({
                fileNames: files
            });     
        }    
    }
    
    getColumnValuesFilter(rawFileSubtypeId, fieldDataType, column, rowIndex, _function, index ,flag, callback, type,vectorId,filterAttribute){
        this.props.getColumnValuesFilter(rawFileSubtypeId, fieldDataType, column, rowIndex, _function, index ,flag, callback, type,vectorId,filterAttribute);
    }

    componentDidMount(){
        this.getFiles();
        this.finalFormula = this.props.mappedMetric && this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION] && this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION].length > 0 ? this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0] : {
            result: [],
            conditions: []
        };
        for (var i in this.finalFormula.conditions) {
            if(!this.finalFormula.conditions[i].filters.length && !this.finalFormula.conditions[i].result.length){
                this.finalFormula.conditions.splice(i,1);
            } else {
                //add condition to the refs array to be rendered when coming from the parent
                this.conditionRefs.push(this.getNewConditionValueFormula());
            }
        }
        this.finalFormula_unchanged = copyObjectValues(this.finalFormula);
    }

    handleConditionRef(condIndex, formulaConditionRef) {
        if(formulaConditionRef) {
            this.conditionRefs[condIndex] = this.conditionRefs[condIndex] ? this.conditionRefs[condIndex] : {};
            this.conditionRefs[condIndex].ref = formulaConditionRef;
        }
    }

    displayRows() {
        var _this = this;
        var conditionValueFormulas = [];
        
        var loopedOverArr =  this.props.mappedMetric && this.props.mappedMetric.metric_configuration && this.props.mappedMetric.metric_configuration.length > 0 && this.props.mappedMetric.metric_configuration[0].conditionFormula &&
            this.props.mappedMetric.metric_configuration[0].conditionFormula.length > 0 && this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions ? this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions :this.finalFormula.conditions.length > 0 ? this.finalFormula.conditions :[];

        var files = this.state.chosenFiles ? this.state.chosenFiles : this.state.fileNames ? this.state.fileNames : _this.getFiles(undefined, true);

        // for (var k = loopedOverArr.length; k > 0; k--) {
        for (var k = _this.conditionRefs.length; k > 0; k--) {
            var i = Number(k) -1;
            // var tempRef = loopedOverArr[i].ref ? loopedOverArr[i].ref : _this.getNewConditionValueFormula();
            conditionValueFormulas.push(<ConditionValueFormula ref={r => _this.handleConditionRef(i, r)} key={"ConditionValueFormula-" + _this.conditionRefs[i][RAND]}
                updateMetricObjects={this.props.updateMetricObjects} hasConditions={this.finalFormula.conditions.length > 0}
                rowIndex={i} fileNames={files} allTypeColumns={this.props.allTypeColumns} deleteCondition={this.deleteCondition} vectors={this.props.vectors}
                calculatedCols={this.props.calculatedCols} getColumnValues={this.getColumnValuesFilter}
                updateFilter={this.updateFilter} appendToFormula={this.appendToFormula} setFormulaIndex={this.setFormulaIndex}
                resetFormulaChanges={this.resetFormulaChanges} deleteLastFormulaItem={this.deleteLastFormulaItem} deleteFormula={this.deleteFormula}
                conditionNumber={i} conditionObject={this.finalFormula.conditions[i]} chosenFiles={this.props.chosenFiles} key={k}
                mappedMetric={this.props.mappedMetric} removeFilterRowInCondition={this.removeFilterRowInCondition} updateConditionFilter={this.updateConditionFilter}
                formula={this.finalFormula.conditions[i] ? this.finalFormula.conditions[i].result : []} vectorOptions ={this.props.vectorOptions} periodName={this.props.periodName} hiddenVectors ={this.props.hiddenVectors}/>)
        }

        return conditionValueFormulas;
    }

    appendToFormula(value, type, className, file, column, colIndex) {
        //define which result is being filled
        let formulaIndex = this.state.formulaIndex;     //using selected formula
        var resultFilled = formulaIndex !== undefined && this.finalFormula.conditions[formulaIndex] && this.finalFormula.conditions[formulaIndex].result ? this.finalFormula.conditions[formulaIndex].result : this.finalFormula.result;
        //take a copy of the value of this result in order to manipulate it
        var result = copyObjectValues(resultFilled);
        result.push({
            [RAW_ITEMS.TYPE]: type,
            [RAW_ITEMS.VALUE]: value,
            [RAW_ITEMS.CLASS]: className,
            [RAW_ITEMS.SUBTYPE_NAME]: file,
            [RAW_ITEMS.FIELD_NAME]: column,
            [RAW_ITEMS.COLUMN_INDEX]: colIndex
        });

        if(formulaIndex !== undefined) {
            this.finalFormula.conditions[formulaIndex].result = result;
        } else {
            this.finalFormula.result = result;
        }

        this.forceUpdate();
    }

    setFormulaIndex(formulaIndex) {
        this.setState({
            formulaIndex: formulaIndex
        })
    }
    updateConditionFilter=(filter,conditionNumber)=>{
        let _this = this;
        _this.finalFormula.conditions[conditionNumber].filters = filter;
        _this.setState(_this.state);
    }

    updateFilter(index, filter, rowIndex, refresh) {
        if (this.finalFormula.conditions[index]) {
            if(this.finalFormula.conditions[index].filters[rowIndex]) {
                this.finalFormula.conditions[index].filters[rowIndex]=filter;
            } else{
                this.finalFormula.conditions[index].filters.push(filter);
            }
        } else {
            for (var i=0; i <= index; i++) {
                this.finalFormula.conditions.push(copyObjectValues(SAMPLE_CONDITION_OBJ));
            }
            this.finalFormula.conditions[index].filters.push(filter);
            this.finalFormula.conditions[index].result.push({});
        }

        if (refresh)
            this.setState(this.state);
    }

    render() {
        var conditionValueFormulas = this.displayRows();
        var formula = this.finalFormula.result && this.finalFormula.result.length > 0 ? this.finalFormula.result :
        this.props.mappedMetric && this.props.mappedMetric.metric_configuration && this.props.mappedMetric.metric_configuration.length > 0 && this.props.mappedMetric.metric_configuration[0].conditionFormula &&
        this.props.mappedMetric.metric_configuration[0].conditionFormula.length > 0 && this.props.mappedMetric.metric_configuration[0].conditionFormula[0].result ? this.props.mappedMetric.metric_configuration[0].conditionFormula[0].result : [];
    return (
            <div>
                <Button 
                    label={"Add condition"}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    leftIcon={<i className="fa-lg far fa-plus-circle" />}
                    onBtnClick={()=>{this.addCondition()}}
                />
                <div>
                    {conditionValueFormulas}
                </div>
                <FormulaDrop ref={r => this.formulaDRef = r} appendToFormula={this.appendToFormula}
                    formula={formula} isInnerComp={false} setFormulaIndex={this.setFormulaIndex}
                    deleteLastFormulaItem={this.deleteLastFormulaItem} resetFormulaChanges={this.resetFormulaChanges}
                    deleteFormula={this.deleteFormula} hasConditions={this.finalFormula.conditions.length > 0}
                    report={STAGING_SECTIONS.METRICS_MAPPING}/>
            </div>
        );
    }
}

export default ValueFormula;