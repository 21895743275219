// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `df-messenger {
    z-index: 999;
    position: fixed;
    bottom: 16px;
    right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/chatBot/Chatbot.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;AACf","sourcesContent":["df-messenger {\n    z-index: 999;\n    position: fixed;\n    bottom: 16px;\n    right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
