import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import {Format} from "./class/format";
import { DEFAULT_DATETIME_FORMAT_MS, LOG_OBJ_KEYS } from './class/constants';
import { formatDate, convertLocalToEasternTZ } from './class/utils';
import { logUITracking, logUIError } from './class/networkUtils';
import Routes from './templateLayout/Routes';
import { configureStore } from '@reduxjs/toolkit';
import allReducers from './reducers/reducers';
import { Provider } from 'react-redux';

const $ = require('jquery');


window._pi_getTrackingDataObj = function() {
    var trackingData = null;
    try{
        trackingData = JSON.parse(sessionStorage.getItem("trackingData"));
    } catch(err){
        trackingData = null;
    }
    return trackingData;
}

/**
 * This function initialises the tracking object after deleting the old if
 * for some reason it was not logged and deleted
 */
window._pi_initialiseTracking = function(action, email, machineName, profitFormat, hasClosingCall) {
    var trackingData = {
        [LOG_OBJ_KEYS.PI_ACTION]: action,
        [LOG_OBJ_KEYS.UNIQUE_ID]: this.Date.now() +"_"+ this.Math.round(this.Math.random()*1000),
        [LOG_OBJ_KEYS.USER_EMAIL]: email || "",
        [LOG_OBJ_KEYS.MACHINE_NAME]: machineName || "",
        [LOG_OBJ_KEYS.PROFIT_FORMAT]: profitFormat || "",
        [LOG_OBJ_KEYS.HREF]: window.location.href || "",
        counter: hasClosingCall ? 1 : 0,     //counter starts with 0 because the first sub_action is added on btn click and doesn't get subtracted
        [LOG_OBJ_KEYS.SUB_ACTIONS]: {
            [action]: {
                [LOG_OBJ_KEYS.UI_STARTED]: formatDate(convertLocalToEasternTZ(new Date()), DEFAULT_DATETIME_FORMAT_MS, false, 3),
                [LOG_OBJ_KEYS.UI_SENT]: hasClosingCall ? formatDate(convertLocalToEasternTZ(new Date()), DEFAULT_DATETIME_FORMAT_MS, false, 3) : undefined
            }
        }
    }
    // localLog(action +": counter created " + trackingData.counter)
    
    window._pi_removeTrackingData();  //delete old tracking object
    sessionStorage.setItem("trackingData", JSON.stringify(trackingData));
}

/**
 * when the API request is sent
 * 
 * @param {*} subAction            //name of the subAction
 * @param {*} email             //user email if available
 * @param {*} hasClosingCall    //passed true when this specific action creates a new tracking obj and explicitly has another call
 *                              //to _pi_stopTracking that will redecrement the counter
 */
window._pi_startTrackingSubAction = function(subAction, email, machineName, profitFormat) {
    var trackingData = window._pi_getTrackingDataObj();
    if(trackingData) {
        trackingData.counter++;
        // localLog(subAction +": counter++ " + trackingData.counter)

        trackingData[LOG_OBJ_KEYS.SUB_ACTIONS][subAction] = {
            [LOG_OBJ_KEYS.UI_SENT]: formatDate(convertLocalToEasternTZ(new Date()), DEFAULT_DATETIME_FORMAT_MS, false, 3)
        }

        //updating user_email and machine_name since they might not have been provided at the time of initialisation
        if(email && email !== trackingData[LOG_OBJ_KEYS.USER_EMAIL]) {
            trackingData[LOG_OBJ_KEYS.USER_EMAIL] = email;
        }
        if(machineName && machineName !== trackingData[LOG_OBJ_KEYS.MACHINE_NAME]) {
            trackingData[LOG_OBJ_KEYS.MACHINE_NAME] = machineName;
        }
        if(profitFormat && profitFormat !== trackingData[LOG_OBJ_KEYS.PROFIT_FORMAT]) {
            trackingData[LOG_OBJ_KEYS.PROFIT_FORMAT] = profitFormat;
        }

        sessionStorage.setItem("trackingData", JSON.stringify(trackingData));
    }
}

/**
 * This function is used when creating a tracking obj from a user action that cannot be assigned a ui_tracking
 * property, such as changing format or selecting a dropdown option
 */

/**
 * when the API request is received
 */


window._pi_stopTracking = function(action, dates) {
    var trackingData = window._pi_getTrackingDataObj();
    var finishedSubAction = trackingData ? trackingData[LOG_OBJ_KEYS.SUB_ACTIONS][action]:"";
    if(trackingData && finishedSubAction) {
        dates = dates || {};
        var apiReceived = "", apiSent = "", networkSent = "", networkReceived = "";

        trackingData.counter--;
        // localLog(action +": counter-- " + trackingData.counter)

        //filling out remaining DateTime values
        finishedSubAction[LOG_OBJ_KEYS.UI_RECEIVED] = formatDate(convertLocalToEasternTZ(new Date()), DEFAULT_DATETIME_FORMAT_MS, false, 3);
        if(dates[LOG_OBJ_KEYS.API_RECEIVED] && dates[LOG_OBJ_KEYS.API_SENT]) {
            apiReceived = formatDate(dates[LOG_OBJ_KEYS.API_RECEIVED], DEFAULT_DATETIME_FORMAT_MS, false, 3);
            apiSent = formatDate(dates[LOG_OBJ_KEYS.API_SENT], DEFAULT_DATETIME_FORMAT_MS, false, 3);
            networkSent = (new Date(apiReceived) - new Date(finishedSubAction[LOG_OBJ_KEYS.UI_SENT])) + "ms";
            networkReceived = (new Date(finishedSubAction[LOG_OBJ_KEYS.UI_RECEIVED]) - new Date(apiSent)) + "ms";
        }        
        finishedSubAction[LOG_OBJ_KEYS.API_RECEIVED] = apiReceived;
        finishedSubAction[LOG_OBJ_KEYS.API_SENT] = apiSent;
        finishedSubAction[LOG_OBJ_KEYS.NETWORK_SENT] = networkSent;
        finishedSubAction[LOG_OBJ_KEYS.NETWORK_RECEIVED] = networkReceived;
        
        sessionStorage.setItem("trackingData", JSON.stringify(trackingData));
    }
}

/**
 * when the API request is complete
 */
window._pi_checkActionFinished = function(allowedtoEndTracking) {
    var trackingData = window._pi_getTrackingDataObj();
    if(trackingData && trackingData.counter <= 0 && allowedtoEndTracking) {   //<= 0 because some actions don't have any API request so don't increment the counter
        trackingData[LOG_OBJ_KEYS.HREF] = window.location.href;
        var piAction = trackingData[LOG_OBJ_KEYS.PI_ACTION];
        var mainSubAction = trackingData[LOG_OBJ_KEYS.SUB_ACTIONS][piAction];
        
        mainSubAction[LOG_OBJ_KEYS.UI_FINISHED] = formatDate(convertLocalToEasternTZ(new Date()), DEFAULT_DATETIME_FORMAT_MS, false, 3);
        mainSubAction[LOG_OBJ_KEYS.DURATION] = (new Date(mainSubAction[LOG_OBJ_KEYS.UI_FINISHED]) - new Date(mainSubAction[LOG_OBJ_KEYS.UI_STARTED])) + "ms";
        logUITracking(JSON.stringify(trackingData));
        window._pi_removeTrackingData();
    }
}

window._pi_removeTrackingData = function() {
    sessionStorage.removeItem("trackingData");
}

/**
 * for actions that are tracked without API calls, like forgot_password and reset_password
 */
window._pi_stopTrackingAndCheckAction = function(action) {
    window._pi_stopTracking(action);
    window._pi_checkActionFinished(true);
}

window._pi_isCurrentlyTracking = function() {
    return sessionStorage.getItem("trackingData") !== null;
}

window._pi_getTrackingParam = function(param) {
    if(window._pi_isCurrentlyTracking()) {
        var trackingData = window._pi_getTrackingDataObj();
        return trackingData[param];
    } else {
        return "";
    }
}


/**
 * setting global listener
 */
$(document).on("mousedown", "[ui_tracking]", function(e) {
    //delete old tracking obj if it wasn't logged for some reason, before starting the next logged action
    window._pi_removeTrackingData();

	window._pi_initialiseTracking($(e.currentTarget).attr("ui_tracking"));
});

/**
 * this is an error handler, an event fired on any uncaught error that occurs
 * ResizeObserver throws benign errors when unable to deliver all observations within a single animation frame.
 * more about ResizeObserver interface on https://web.dev/resize-observer/
 */
window.onerror = function myErrorHandler(errorMsg, url, lineNumber, colNumber, error) {
    if(error && !error.message.toLowerCase().includes("resizeobserver")) {
        logUIError(error.stack);
    } else if(!errorMsg.toLowerCase().includes("resizeobserver")) {
        logUIError(errorMsg);
    }
    return false;
}

const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
  // middleware:[thunk]
});

// let persistor = persistStore(store);
//initialise the app
window._format = new Format(null, function () {
  ReactDOM.render(
    <Provider store={store}>
      <Routes />
    </Provider>
    , document.getElementById('root'));
  registerServiceWorker();
});
