/**Library*/
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { RecoilRoot } from 'recoil';
import { ChainlitAPI, ChainlitContext } from "@chainlit/react-client";
/**Internal Components */
import LeftHandMenu from "./LeftHandMenu";
import NavigationHeader from "./NavigationHeader";
import { Footer } from "./Footer";
import SelectClient from "../SelectClient";

/**StyleSheets & CSS */
import "./styles/navigationHeader.css";
import "./styles/template.css";
import "./styles/landingPage.css";
import "./styles/leftHandMenu.css";
import "./styles/reportHeader.css";
import "./styles/reportBody.css";
import "../styles/dataModeling.css";
import "../styles/globalStyles/globalStyle.css"
import '../styles/common.css';
import '../styles/elements.css';
import '../styles/header.css';
import "./styles/footer.css";
import "../styles/profitStack.css";

import { ReactComponent as CloseIcon } from "../styles/images/icons/close.svg";


/**Constants */
import {
  CLIENTS,
  CLIENT_ID_STORAGE,
  ACTION_ID_STORAGE,
  CUSTOM_REPORT,
  DEFAULT_CLIENT_ID,
  MENU_ITEM,
  SELECTED_DRILL_LIST,
  SELECTED_PROFILE_EROSION,
  IMAGE_TYPE,
  BUTTON_VARIANT,
  SIZES,
  BUTTON_TYPE,
  ALL_WIDGETS,
  ALL_REPORTS,
  BUILD_SCENARIO_ID,
  IS_BUILD_RUNNING,
  logged_in,
} from "../class/constants";
import { lang } from "../language/messages_en";

/**utility functions && subcomponents */
import { updateUserSectionsData } from "./actions/userSectionsActions";
import { updateUserSettings } from "./actions/userSettingsActions";
import {getLocalStorageValueByParameter, setLocalStorageValueByParameter} from "../class/common";
import { readCookie, removeAllCookies } from "../class/jqueries";
import {clearStoreByScenario, clearStoreWhenSwitchingClient} from "../class/reduxStoreUtils";
import {
  getImagesSignedUrls,
  getScenarios,
  getUserSections,
  getUserSettings,
  logout,
  updateLastLoginDate
} from "./api/api";
import {updateScenarioState} from "../actions/scenariosActions";
import {trackBuildProgressOnFirstLoad, trackBuildProgressInInterval} from "./api/apiDataModeling";
import { convertPxToViewport } from "../class/formatting";
import {copyObjectValues, getSectionExists, parseBoolean} from "../class/utils";
import { ReactComponent as LeftArrow } from "../styles/images/chevron-right.svg";
import { ReactComponent as RightArrow } from "../styles/images/icons/chevron-left-regular.svg";
import { useLoading } from "./CustomHooks";
import { Loader } from "../form/elements";
import { useMemo } from "react";
import {
  dateToTemporalExpressions,
  getLeftMenuLinks,
  getMonthDayYear,
  openAppToast
} from "./functions/componentFunctions";
import SessionTimeout from "../SessionTimeout";
import { LoaderSkeleton } from "./LoaderSkeleton";
import ErrorBoundary from "../ErrorBoundary";
import { updateImagesSignedUrls } from "./actions/imagesSignedUrlsActions";
import Button from "../newComponents/Button";
import LogoPI from "../LogoPI";
import { linearizeHierarchy } from "../class/array";
import {IconTitle} from "./IconTitle";
import { getReportURLWithoutReportName } from "../class/acl";
import ChatbotWrapper from "../components/chatBot/ChatBotWrapper";

const PARENT_MACHINE_NAME = "parent_machine_name";
const MENU_ITEM_MACHINE_NAME = "menu_item_machine_name";
const IS_CUSTOM_REPORT = "is_custom_report";
const IS_TABLEAU = "is_tableau";
const MACHINE_NAME = "machine_name";
const URL = "url";
const LANDING_PAGE = "/profit_isle_start";

/**
 * @description Component Template is the first component that renders when accessing any screen.
 * This component contains the main architecture of the web application
 * it draws the (NavigationHeader, LeftHandMenu, Footer and body AKA PassedComponent or PassedCustomReportComponent)
 * @author Sarah Farjallah
 * @param {ObjectConstructor} PassedComponent gets rendered in renderbody(); contains the main component to be rendered (e-g: List, MonthlyBuild, TotalStacks ...)
 * @param {ObjectConstructor} PassedCustomReportComponent gets rendered in renderBody(); contains the custom report's component only to be rednered  
 * @param {Boolean} showLeftMenu if report has left menu  
 * @param {Boolean} noMenuReport if report has no left hand menu and no bento menu  
 * @param {Boolean} hideInfo if report has info icon hidden 
 * @param {Boolean} hideSettings if report has settings icon hidden  
 * @param {Boolean} isConfiguration if report is related to configuration menu group link  
 * @param {Boolean} isScenarioDisabledProps if report has scenario dropdown always disabled  
 * @param {Boolean} isScenarioPublished if report should display the published scenario by default without taking into consideration to the inherited one  
 * @param {Boolean} isScenarioHidden if report should display the scenario drop down in the left hand menu  
 * @param {Boolean} removeBackground remove gray background if true
 * @param {Boolean} byPassAccess byPassAccess to by pass access if sent true
 * @param {Object} props other props
 * @returns NavigationHeader, LeftHandMenu, Footer and PassedComponent or PassedCustomReportComponent
 *
 * --
*/
const Template = ({
  passedComponent: PassedComponent,
  passedCustomReportComponent: PassedCustomReportComponent,
  showLeftMenu = true,
  noMenuReport = false, // to indicate if this is a report with no menu and is not landing page
  hideInfo = false,
  hideSettings = false,
  isConfiguration = false,
  isScenarioDisabledProps = false,
  isScenarioPublished=false,
  isScenarioHidden = false,
  removeBackground = false,
  trackBuildOnLoad = false,
  showTemplateToast = true,
  byPassAccess = false,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let clientIdToBeSet = useRef(null);
  const sessionTimeoutRef = useRef();
  const _LOGGED_OUT = "logged_out";

  /**
   * data saved in redux store
   */
  const userSettingsFromStore = useSelector((state) => state?.clientUserSettings?.userSettings);
  const userSectionsFromStore = useSelector((state) => state?.clientUserSections?.userSectionsData);
  const scenarioStateFromStore = useSelector((state) => state?.scenarioState?.scenarioState);
  const periodsStatusFromStore = useSelector((state) => state.periodsStatusState);
  const vectorsFromStore = useSelector((state) => state.vectorState);
  const datasetFromStore = useSelector((state) => state.datasetState);
  const PSViewsFromStore = useSelector((state) => state.customViewDataState);
  const imagesSignedUrlsFromStore = useSelector((state) => state?.imagesSignedUrls.imagesSignedUrls);

  /**
   * profit modeling states
   */
  ////// to be sent as params to getEngineScenarios() and getAllFileTypeColumns() in apiDataMOdeling.js
  const [allFileTypeColumns, setAllFileTypeColumns] = useState(""); ////// tbd
  /**states */
  const [url, setUrl] = useState(window.location.pathname);
  const [blankPageMsg, setBlankPageMsg] = useState("Loading");
  const [openPersonalSettings, setPersonalSettingsOpened] = useState(false);
  const [userSettingsState, setUserSettingsState] = useState(userSettingsFromStore || []);
  const [scenarioState, setScenarioState] = useState(
    !isScenarioPublished && (scenarioStateFromStore || props.history.location?.state?.scenarioState || [])
  );
  const [userSectionsState, setUserSectionsState] = useState(
    (userSectionsFromStore?.length > 0 && userSectionsFromStore[0]) || undefined
  );
  const [imagesSignedUrlsState, setImagesSignedUrlsState] = useState(
    (imagesSignedUrlsFromStore?.length > 0 ?imagesSignedUrlsFromStore : []));
  const [userAllowedMenuLinks, setUserAllowedMenuLinks] = useState(
    (userSectionsFromStore?.length > 0 && userSectionsFromStore[0].data) || undefined
  );
  const [profitFormat, setProfitFormat] = useState("PM");
  const [leftMenuOpen, setLeftMenuOpen] = useState(true);
  const [isScenarioDisabled, setScenarioDisabled] = useState(false);
  const [isSwitchClientDisabled, setSwitchClientDisabled] = useState(noMenuReport);
  const [leftMenuAllowedMenuLinks, setLeftMenuAllowedMenuLinks] = useState(true);
  const [switchClient, setSwitchClient] = useState(false);
  const [checkingForUnsavedChanges, setCheckingForUnsavedChanges] = useState(false);
  const [checkedUnsavedChanges, setCheckedUnsavedChanges] = useState(false);
  const [divId, setDivId] = useState({});
  const [destinationClientId, setDestinationClientId] = useState();
  const [changingReport, setChangingReport] = useState(false);
  const [profitSegmentationOpen, setProfitSegmentationOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({message: "", error: null});

  const loading = useLoading();
  const params = useParams();

  const memoizedDate = useMemo(() => getMonthDayYear(userSettingsState.last_login_date), [props.userSettings]);
  const dateToTemporalExpression = useMemo(
    () => dateToTemporalExpressions(userSettingsState.last_login_date),
    [props.userSettings]
  );

  const CHAINLIT_SERVER = process.env.REACT_APP_CHATBOT_APP_BASE_URL + "/chainlit";

  const apiClient = new ChainlitAPI(CHAINLIT_SERVER, "webapp");

  let trackBuildInterval = useRef();

  /**
   * @function useEffect()
   * on page first initialization fetch userSettings and imagesSignedUrls
   **/
  useEffect(() => {
    getUserSettings(
      dispatch,
      updateUserSettings,
      setUserSettingsState,
      userSettingsState,
      profitFormat,
      "",
      undefined,
      setUserAllowedMenuLinks
    );
    getImagesSignedUrls(setImagesSignedUrlsState, dispatch, updateImagesSignedUrls, imagesSignedUrlsState);
  }, []);

  /**
   * @function useEffect()
   * when loading state changes disable scenario and switchClient dropdown if loading is true
   */
  useEffect(() => {
    if (!noMenuReport) {
      setScenarioDisabled(loading);
      setSwitchClientDisabled(loading);
    }
  }, [loading]);

  /**
   * @function useEffect()
   * if userSettingsState is fetched and not empty => fetch userSections that retrieve what screens and features the user has access to
   * updates last login date after fetching userSettings to display it in landing page so that last login date doesn't become now
   */
  useEffect(() => {
    if (Object.keys(userSettingsState)?.length > 0 && !sessionStorage.getItem(logged_in)) {
      sessionStorage.setItem(logged_in, true); // once the user logged in and login date is updated, don't send another request when rendering Template.js
      let email = userSettingsState?.user?.email ? userSettingsState?.user?.email : readCookie("user_email");
      updateLastLoginDate(email);
    }

    // on userSettings fetched
    getUserSections(
      dispatch,
      updateUserSectionsData,
      setUserSectionsState,
      setUserAllowedMenuLinks,
      userSectionsState,
      userSettingsState,
      profitFormat,
      true
    );
  }, [userSettingsState]);

  /**
   * @function useEffect()
   * fetchScenarios once userSections are retrieved and not empty.
   *
   * trackBuildOnLoad sent from profitIsleStart route to handle switching a client,
   * calling trackBuildProgressOnFirstLoad to fetch or remove the build progress based on the client's build status,
   * And we are sending trackBuildProgressInInterval() callback to set an interval and track build progress,
   * to be able to launch a toast no matter which screen is mounted.
   *
   * else we are directly calling trackBuildProgressInInterval(),
   * if we are not in the configure and build screen and a build is running to launch the toast no matter which screen is mounted.
   */
  useEffect(() => {
    if (userSectionsState && Object.keys(userSectionsState).length > 0) {
      if (trackBuildOnLoad) {
        trackBuildProgressOnFirstLoad(userSettingsState,trackBuildProgressInInterval, trackBuildInterval, launchAppToast)
      } else if(parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING)) && sessionStorage.getItem(BUILD_SCENARIO_ID) && showTemplateToast){
        trackBuildProgressInInterval(trackBuildInterval, launchAppToast);
      }
      getScenarios(
        undefined,
        undefined,
        dispatch,
        updateScenarioState,
        setScenarioState,
        params,
        scenarioState,
        userAllowedMenuLinks ? userAllowedMenuLinks[0] : []
      );
    }
  }, [userSectionsState]);

  /**
   * @function useEffect()
   * Once userallowedMenuLinks is extracted from userSections ; if it's not empty then extract the menu links of the designated menu link group from url 
   * i-e if url belongs to financial_analytics then set leftMenuLinks to all reports under financial analytics
   * if  userallowedMenuLinks is empty then user has no access to any screen and therefore blank page is displayed
   */
  useEffect(() => {
    if (userAllowedMenuLinks?.length === 0) {
      setBlankPageMsg(lang.no_menu_links_found_msg);
    } else if (showLeftMenu) {
      setLeftMenuAllowedMenuLinks(getLeftMenuLinks(userAllowedMenuLinks));
    }
  }, [userAllowedMenuLinks]);

  /** 
   * @function useEffect()
   * when exiting select client screen, we clear the session timeout
   **/
  useEffect(() => {
    if (switchClient) {
      sessionTimeoutRef?.current?.clearTimeOut();
    }
  }, [switchClient]);

 /**
 * @function historyPushAndReload(passedState)
 * functioanlity: reloads the page, but instead of using window.location.reload,it uses React Router functions to try and preserve the history state.
 * called when switching client in component {@link SelectClient}
 * @param {Object} passedState The state to be passed when reloading.
 */
 const historyPushAndReload = (passedState) => {
  passedState = passedState
    ? passedState
    : history?.location?.state
    ? history.location.state
    : {
        origin: window.location.href,
        profitFormat: profitFormat,
      };

  history.push({
    pathname: window.location.pathname,
    search: "?",
    hash: "",
    state: passedState,
  });
  history.go(); //pushing the state to the current pathname doesn't reload the page, so we use go()

};

  /**
   * @function callGetUserSection(notReadFromStore)
   * function calls getUserSecions and sends it a callback function to setLEftMenuLinks after userSections is retrieved
   * function called when changing in manageCustomReports the access on any custom report; in order to refresh the userAllowedMeuLinks and refetch from db
   * @param {Boolean} notReadFromStore flag true to fetch value from api , false to read value from redux store
   */
   const callGetUserSection = (notReadFromStore) => {
    let cb = () => {
      setLeftMenuAllowedMenuLinks(getLeftMenuLinks(userAllowedMenuLinks));
    };
    getUserSections(
      dispatch,
      updateUserSectionsData,
      setUserSectionsState,
      setUserAllowedMenuLinks,
      userSectionsState,
      userSettingsState,
      profitFormat,
      true,
      notReadFromStore,
      cb
    );
  };

  /**
   * @function clearAllSavedData()
   * clear session, localstorage and cookies
  */
  const clearAllSavedData = () => {
    removeAllCookies(true);
    setLocalStorageValueByParameter(window.location.host + "_lastTimeActive", "");
    let clients = getLocalStorageValueByParameter(CLIENTS);
    let defaultClientId = getLocalStorageValueByParameter(DEFAULT_CLIENT_ID);
    sessionStorage.clear();
    setLocalStorageValueByParameter(CLIENTS, clients);
    setLocalStorageValueByParameter(DEFAULT_CLIENT_ID, defaultClientId);
    setLocalStorageValueByParameter(CLIENT_ID_STORAGE, "");
    setLocalStorageValueByParameter(ACTION_ID_STORAGE, "");
  };

  /**
   * @function landingUrlCallback()
   * calls fetchUserSettings for formatting then redirects to the landing page and reloads screen so the switching of client is finalized
   * called from signInFunc
   * @param {String} landingPageUrl url of the landing page (currently being profit_isle_start)
   */
  const landingUrlCallback = (landingPageUrl) => {
    $(window).keydown(function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
    window._format.fetchUserSettings(function () {
      history.push({
        pathname: landingPageUrl,
        search: "",
        hash: "",
      });
      sessionStorage.removeItem(logged_in);
      localStorage.removeItem(window.location.host + "_" + _LOGGED_OUT);
      window.location.reload(); // on switching into another client screen must be refreshed
    });
  };

  /**
   * @function signInFunc()
   * calls to clear cookies, storage and redux store and changes the client id in localstorage then calls getUserSettings with additional props to execute landingUrlCallback
   * called from signIn function
   * @param {String} clientId value of switched to client's id
   */
  const signInFunc = (clientId) => {
    clearAllSavedData();
    clearStoreWhenSwitchingClient(dispatch);
    setLocalStorageValueByParameter(CLIENT_ID_STORAGE, clientId);
    setSwitchClient(true);
    setChangingReport(true)
    let additionalProps = { callback: landingUrlCallback, isSwitchingClient: true };
    getUserSettings(
      dispatch,
      updateUserSettings,
      setUserSettingsState,
      [],
      profitFormat,
      "",
      additionalProps,
      setUserAllowedMenuLinks
    );
  };

  /**
   * @function signIn()
   * calls checkForUnsavedChanges is report has unsaved changes
   * called from {@link SelectClient} and from setCheckedUnsavedChangesRef function
   * @param {String} clientId value of switched to client's id
   * @param {Boolean} ignoreCheck true to ignore check for unsaved changes in the report
   */
  const signIn = (clientId, ignoreCheck) => {
    // let _this = this;
    // _this.idToBeSet = clientId;
    clientIdToBeSet.current = clientId;
    if (isConfiguration && !checkedUnsavedChanges && !ignoreCheck) {
      //checking for unsaved changes before switching screen
      checkForUnsavedChanges(undefined, clientId);
      return;
    }
    // let checkForUnsavedChanges = _this.checkForUnsavedChanges();
    // } else {
    //   if (_this.stackConfig && _this.stackConfig.current && _this.stackConfig.current.getStackIsChanged() && _this.headerRef.secondaryHeader.state.isSaveButtonDisabled) {
    //     _this.setSaveBeforeDialogOpen(true, clientId)
    //   } else {
    //     _this.setSaveFirstDialogOpen(true, clientId);
    //   }
    // }
    // } else {
    signInFunc(clientId);
    // }
  };
  /**
   * @function checkForUnsavedChanges()
   * function can be called when switching client or when changinf report so for each case a state is changing accordingly and then setCheckingForUnsavedChanges is called with true flag
   * @param {String} divIdParam value to set state for clicked div Id (div being the report from left hand menu or bento menu combobox)
   * @param {String} clientId value of switched to client's id
  */
  const checkForUnsavedChanges = (divIdParam, clientId) => {
    setDivId(divIdParam);
    setDestinationClientId(clientId);
    setCheckingForUnsavedChanges(true);
  };

  /**
   * @function setCheckedUnsavedChangesRef()
   * Function called from wrapper when changes are saved either if from switching client or changing report , if it's goToReport flag and div Id is landing page it immediately
   *  redirect to landing page else it calls goToReport function.
   * @param {Boolean} check status of checkedUnsavedChanges in state
   * @param {Boolean} goToReportFlag true to execute goToLandingPage or goToReport
   * @param {Boolean} signInFlag true to execute signIn
  */
  const setCheckedUnsavedChangesRef = (check, goToReportFlag, signInFlag) => {
    setCheckedUnsavedChanges(check);
    if (goToReportFlag) {
      if (divId === LANDING_PAGE) {
        goToLandingPage(true);
      } else {
        goToReport(undefined, divId, true);
      }
      setDivId(undefined);
    }
    if (signInFlag) {
      signIn(destinationClientId, true);
    }
  };

 /**
  * @function goToReport()
  * Function checks if report is a configuration report and calls checkForUnsavedChanges before switching report
  * It removes from session what is needed to be removed
  * If reportUrl is not send as a parameter it extracts the all attributes from divId that are needed to build the url in the form of /ParentMachineName/MenuItemMachineName
  * once extracting attributes it filters userAllowedMenuLinks to reach last level carrying the reports and sorts them according to their order in db then extracts machine name of the first child and builts the array
  * if reportUrl is sent it uses it as is and redirect user to designated or built url
  * @param {String} divIdParam clicked div
  * @param {Object} divIdParamJQ clicked div returned from $(...)
  * @param {Boolean} ignoreCheck true if function wants to ignore if report has unsaved Changes
  * @param {*} reportUrl undefined or string of format .../.../...
 */
  const goToReport = (divIdParam, divIdParamJQ, ignoreCheck, reportUrl) => {
    //reportURL is added for click on link from manage custom reports table
   setChangingReport(true)
    if (isConfiguration && !checkedUnsavedChanges && !ignoreCheck) {
      //checking for unsaved changes before switching screen
      checkForUnsavedChanges($(divIdParam?.currentTarget));
      return;
    }

    setCheckedUnsavedChangesRef(false); // when changing between reports (specifically within the same <switch>), return checkedUnsavedChnages to false 
    if (sessionStorage.getItem(SELECTED_DRILL_LIST)) {
      // drillList is saved for the back navigation when going from entity stacks back to list drill screen
      // it should be removed if we go to another report
      sessionStorage.removeItem(SELECTED_DRILL_LIST);
    }
    if (sessionStorage.getItem(SELECTED_PROFILE_EROSION)) {
      sessionStorage.removeItem(SELECTED_PROFILE_EROSION);
    }
    let url = "";
    if (!reportUrl) {
      let selectedMenuItem = divIdParamJQ || $(divIdParam?.currentTarget);
      let machineName = selectedMenuItem.attr(MACHINE_NAME);
      let parentMachineName = selectedMenuItem.attr(PARENT_MACHINE_NAME);
      let menuItemMachineName = selectedMenuItem.attr(MENU_ITEM_MACHINE_NAME);
      let isCustomReport = selectedMenuItem.attr(IS_CUSTOM_REPORT);
      let isTableau = selectedMenuItem.attr(IS_TABLEAU);
      url = selectedMenuItem.attr(URL);
      let redirectOnly = selectedMenuItem.attr("redirect_only");

      if (!redirectOnly) {
        let filteredFirstLevelMenuLinks = userAllowedMenuLinks
          ? copyObjectValues(userAllowedMenuLinks).filter(
              (e) => e[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === parentMachineName
            )
          : [];
        let isSecondLevelAParent = filteredFirstLevelMenuLinks[0]?.children.length > 0 && filteredFirstLevelMenuLinks[0]?.children[0].menu_item_type === "link_group";
        
        if (!isSecondLevelAParent && filteredFirstLevelMenuLinks?.length > 0 && filteredFirstLevelMenuLinks[0]?.children?.length > 0) {
          let screens = filteredFirstLevelMenuLinks[0].children.sort(
            (a, b) => a[MENU_ITEM.COLUMNS.ORDER] - b[MENU_ITEM.COLUMNS.ORDER]
          );
          let designatedScreen = screens[0][URL];
          if (screens[0].category === "custom_report") {
            url = url + "/" + screens[0].menu_item_machine_name;
          } else {
            url = "/" + designatedScreen;
          }
        }
      } else {
        if (isCustomReport === "true") {
          let reportingMenuLinks = copyObjectValues(userAllowedMenuLinks).filter(f => f.menu_item_category === MENU_ITEM.FIELDS.REPORTING.toUpperCase());
          reportingMenuLinks = linearizeHierarchy(reportingMenuLinks, "children");
          let selectedLinkURL = getReportURLWithoutReportName(reportingMenuLinks, reportingMenuLinks.filter(f => f.menu_item_machine_name === menuItemMachineName)[0])
          url = selectedLinkURL + "/" + menuItemMachineName;
        }
      }
    } else {
      url = reportUrl;
    }

    setUrl(url);
    let state = history.location.state || {isManageStacks: false, profitStackViewId: 0};
    if(!state?.isRedirectionFromStacks) { // remove saved list profile when we go to a new report from the menu 
      sessionStorage.removeItem("selectedProfile_list"); 
    }
      
    state.isRedirectionFromStacks = false;
    state.isRedirectionFromBubble = false;
    state.isRedirectedFromBridge = false;
    state.origin_name = undefined;
    state.dimensionListFilter = undefined;
    state.toBeAddedFilter = undefined;
    state.periodRange = undefined;
    state.profitStackItems = undefined;
    state.inheritedPeriod = false;

    if (!url?.startsWith("/")) url = "/"+url;
    state.isManageStacks = false;
    state.profitStackViewId = 0;
    history.push({
      pathname: url,
      search: "",
      hash: "",
      state: state
    });
    
  };

  /**
   * @function goToLandingPage()
   * function checks if report has leftMenu and is a configuration report and there's unsaved changes then it calls checkForUnsavedChanges else it redirect user to landing page
   * @param {Boolean} ignoreCheck true to ignore checking is report has savedChanges
  */
  const goToLandingPage = (ignoreCheck) => {
    if (showLeftMenu) {
      if (isConfiguration && !checkedUnsavedChanges && !ignoreCheck) {
        //checking for unsaved changes before switching screen
        checkForUnsavedChanges(LANDING_PAGE);
        return;
      }
      history.push(LANDING_PAGE);
    }
  };

  /**
   * @function openCloseLeftMenu()
   * function checks if menu open or collapsed and adds the necessary css class and sets state for leftMenuOpen
   */
  const openCloseLeftMenu = () => {
    let isMenuOpen = leftMenuOpen;
    const leftMenuSlider = document.querySelector(".left_menu_container");

    if (isMenuOpen) {
      leftMenuSlider.setAttribute("transition", !isMenuOpen);
    }
    leftMenuSlider.setAttribute("open", !isMenuOpen);

    let transitioned = false;
    const element = document.getElementById("left_menu_container");
    element.addEventListener("transitionend", function (event) {
      if (!transitioned && !isMenuOpen) {
        transitioned = true;
        leftMenuSlider.setAttribute("transition", !isMenuOpen);
      }
    });

    setLeftMenuOpen(!isMenuOpen);
  };

  /**
   * @function handleScenarioChange()
   * function receives scenario object as a parameter and sets the new scenario object and saves it in redux store
   * @param {Object} newScenario scenarioObject containing label, value, id , number , status
  */
  const handleScenarioChange = (newScenario) => {
    let tempState = copyObjectValues(scenarioState);
    tempState.scenario = newScenario.value;
    tempState.scenarios = [newScenario.value];
    tempState.scenarioObjects = [newScenario];
    tempState.nextScenarios = [newScenario.value];
    tempState.nextScenariosObjects = [newScenario];
    tempState.scenarioStatus = newScenario.scenario_status;
    tempState.scenarioList = tempState.scenarioList;
    let state = history.location.state ||  {  // if history has a defined state then append to it scenarioState else declare one
      scenarioState: tempState,
      isManageStacks: history?.location?.state?.isManageStacks,
      profitStackViewId: history?.location?.state?.profitStackViewId
     };

     state.scenarioState = tempState,
     state.isManageStacks = history?.location?.state?.isManageStacks,
     state.profitStackViewId = history?.location?.state?.profitStackViewId
    
    history.push({
      pathname: window.location.pathname,
      search: "?",
      hash: "",
      state: state,
    });

    setScenarioState(tempState);
    dispatch(updateScenarioState(tempState));
  };

  /**
   * @function checkIfHasAccess()
   * function checks if user has access to the url entered or logs him out if not
   * it's called when user has no left menu links due to no access on any but has access to other features so in this case the wrapper won't render (this case is handles in a custom hoom in each wrapper)
   */
  const checkIfHasAccess = () => {
    let menuItemGroupArr = window.location.pathname.split('/');
    if (menuItemGroupArr.length > 0) {
      let menuItem = menuItemGroupArr[menuItemGroupArr.length-1];
      if (userAllowedMenuLinks?.length > 0) {
        let hasAccess = getSectionExists(userAllowedMenuLinks, menuItem);
        if (!hasAccess && !byPassAccess) {
          logout();
        }
      }
    }

  }

  /**
   * @function useEffect()
   * if Template unmounted clear interval inside trackBuildProgressInInterval function
   **/
  useEffect(() => {
    return () => {
      clearInterval(trackBuildInterval.current);
    };
  }, []);

  useEffect(() => {
    if (!!toastMessage.message) {
      openAppToast();
    }

  }, [toastMessage.message]);
  /**
   * @function launchAppToast()
   * function to launch a toast when build is completed no matter which report the user is using
   */
  const launchAppToast = (pass) => {
    let message = pass ? lang.build_status.passed :  lang.build_status.failed;
    setToastMessage({message: message, error: !pass});

    if (dispatch) {
      let scenarioId = getLocalStorageValueByParameter("scenarioForBuild") !== "" ? Number(getLocalStorageValueByParameter("scenarioForBuild")) : "";
      clearStoreByScenario(dispatch, scenarioId);
    }
  }


  /**
   * @function renderComponent()
   * function extracts from url filtering on userAllowedMenuLink and checks if it's a custom report to render {@link PassedCustomReportComponent} or render {@link PassedComponent}
   * called from renderBody component
   * @returns either {@link PassedComponent} or {@link PassedCustomReportComponent}
   */
  const renderComponent = () => {
    let menuItemGroupArr = window.location.pathname.split("/");
    let leftMenuAllowedLinks = getLeftMenuLinks(userAllowedMenuLinks);

    let isTableau = false;
    let isCustomReport = false;
    let customReport = [];
    let url = "";
    if (leftMenuAllowedLinks?.length > 0) {
      let report = params.report || menuItemGroupArr[menuItemGroupArr.length - 1];
      let reportInLinks = leftMenuAllowedLinks[0].children.find((e) => e.menu_item_machine_name === report);
      isTableau = reportInLinks?.is_tableau;
      customReport = reportInLinks;
      isCustomReport = customReport?.category === CUSTOM_REPORT;
      url = reportInLinks?.url;
    }

    return (
      <>
        {isCustomReport ? (
          <PassedCustomReportComponent ////Rendering PassedCustomReportCompoenent when we have a custom report under a reporting group
            userAllowedMenuLinks={userAllowedMenuLinks}
            userSettings={userSettingsState}
            goToReport={goToReport}
            goToLandingPage={goToLandingPage}
            scenarioState={scenarioState}
            periodsStatus={periodsStatusFromStore}
            tiers={vectorsFromStore}
            datasetOptions={datasetFromStore}
            customViewData={PSViewsFromStore}
            switchClient={switchClient}
            scrollable={props.scrollable}
            checkingForUnsavedChanges={checkingForUnsavedChanges}
            setCheckedUnsavedChangesRef={setCheckedUnsavedChangesRef}
            setCheckingForUnsavedChanges={setCheckingForUnsavedChanges}
            divId={divId}
            isMenuOpen={leftMenuOpen}
            destinationClientId={destinationClientId}
            sessionTimeoutRef={sessionTimeoutRef}
            callGetUserSection={callGetUserSection}
            url={url}
            isTableau={isTableau}
            history={props.history}
            customReport={customReport}
            location={props.location}
          />
        ) : (
          <PassedComponent
            userAllowedMenuLinks={userAllowedMenuLinks}
            userSettings={userSettingsState}
            goToReport={goToReport}
            goToLandingPage={goToLandingPage}
            scenarioState={scenarioState}
            periodsStatus={periodsStatusFromStore}
            tiers={vectorsFromStore}
            datasetOptions={datasetFromStore}
            customViewData={PSViewsFromStore}
            switchClient={switchClient}
            scrollable={props.scrollable}
            checkingForUnsavedChanges={checkingForUnsavedChanges}
            setCheckedUnsavedChangesRef={setCheckedUnsavedChangesRef}
            setCheckingForUnsavedChanges={setCheckingForUnsavedChanges}
            divId={divId}
            isMenuOpen={leftMenuOpen}
            destinationClientId={destinationClientId}
            sessionTimeoutRef={sessionTimeoutRef}
            additionalProps={props.additionalProps}
            independantFromScenario={props.independantFromScenario}
            callGetUserSection={callGetUserSection}
            location={props.location}
            setScenarioDisabled={setScenarioDisabled}
            setSwitchClientDisabled={setSwitchClientDisabled}
            isScenarioHidden = {props.isScenarioHidden}
            isDataModeling = {props.isDataModeling}
            imagesSignedUrls={imagesSignedUrlsState}
            changingReport={changingReport}
            setChangingReport={setChangingReport}
            launchAppToast={launchAppToast}
          />
        )}
        { (userSettingsState.chatbot && getSectionExists(userAllowedMenuLinks, "chat_bot")) ?  <ChainlitContext.Provider value={apiClient}><RecoilRoot><div><ChatbotWrapper userSettings={userSettingsState}/></div></RecoilRoot></ChainlitContext.Provider>  : ""}
      </>
    );
  };

  /**
   * @function renderBody()
   * if user has access to leftMenuLinks and scenario is defined or report is not by scenario it returns LeftHandMenu and renderedComponent
   * if report has no menu and scenario is defined or report is not by scenario then render component without leftHandMenu
   * if user has showLeftMenu false then render landing page
   * if user has no access to any feature/menuitem then render blank page with message no suffiecient access
   * @returns {@link LeftHandMenu}, {@link LoaderSkeleton}, rendererdComponent, blankPage with message
   */
  const renderBody = () => {
    if ((leftMenuAllowedMenuLinks?.length > 0 && (props.independantFromScenario || props.isDataModeling || scenarioState?.scenario))) { // has access to leftMenuLinks and scenario is defined or report is not by scenario
      /* Report with left menu */
      return (
        <div className="body-content" style={{background: removeBackground ? "white" : ""}}>
          <div id={"left_menu_container"} className="left_menu_container noprint">
            <div onClick={() => openCloseLeftMenu()} className="left_menu_slider">
              {leftMenuOpen ? (
                <RightArrow style={{ width: convertPxToViewport(14), height: convertPxToViewport(14) }} />
              ) : (
                <LeftArrow style={{ width: convertPxToViewport(14), height: convertPxToViewport(14) }} />
              )}
            </div>
            {/** This loader is added for when we need a full screen loader (ie: format popup when applying format) 
             * and we use toggleFullScreenLoader to display it.
             * But when the report is engine report, no need to use the toggleFullScreenLoader.
            */}
            <Loader uniqueClassName={props.isDataModeling ? "engine-full-loader" : "full-screen-loader"} />
            <LeftHandMenu
              menuLinks={leftMenuAllowedMenuLinks}
              user={userSettingsState?.user}
              goToReport={goToReport}
              history={history}
              scenarioOptions={scenarioState?.scenarioList}
              selectedState={scenarioState?.scenario}
              saveScenario={(newScenario) => handleScenarioChange(newScenario)}
              isScenarioDisabled={isScenarioDisabled || isSwitchClientDisabled}
              isScenarioHidden={isScenarioHidden}
              isScenarioDisabledProps={isScenarioDisabledProps}
              isMenuCollapsed={!leftMenuOpen}
              location={props.location}
            />
          </div>

          <div className="main_component_report">
            {userAllowedMenuLinks?.length > 0 && Object.keys(userSettingsState)?.length > 0 ? (
              renderComponent()
            ) : (
              <LoaderSkeleton />
            )}
          </div>
        </div>
      );
    } else if (noMenuReport && (props.independantFromScenario || scenarioState?.scenario)) { // has no menu {@example ProfitstackLineDefinitions} and scenario defined or report is not by scenario
      /* Report that don't have menus (no leftMenu and no switchTo menu) */
      return (
        <div className="no-menu-body-content">
          <div className="main_component_report">
            {userAllowedMenuLinks?.length > 0 && Object.keys(userSettingsState)?.length > 0 ? (
              renderComponent()
            ) : (
              <LoaderSkeleton />
            )}
          </div>
        </div>
      );
    } else if (!showLeftMenu) { // if not showLeftMenu then render landingpage
      /** Landing page */
      return (
        <>
          <div className="landing-page">
            <Loader />
            {userAllowedMenuLinks?.length > 0 && Object.keys(userSettingsState)?.length > 0
              ? renderComponent()
              : userAllowedMenuLinks?.length === 0 && ( // has no access to any menu item then load blank page with message no sufficient acces
                  <>
                    <div className="landing-page-contents">
                      <div className="upper-container" style={{height: "50%", width: "100%"}}>
                        <div className="landing_no_access_container">
                          <IconTitle
                              title={lang.menu.hi_text + userSettingsState.user?.first_name}
                              titleStyle={{color: "#FFF", fontSize: "0.78vw", fontWeight: 600}}
                              subtitleStyle={{color: "#AAABB0", fontSize: "0.57vw"}}
                              svgStyle={{width: "2.34375vw", height: "2.34375vw", backgroundColor: "#1AD18F"}}
                              titleContainerStyle={{justifySelf: "center"}}
                              iconTitleContainerStyle={{alignItems:"center"}}
                          />
                          <label style={{
                            alignSelf: "center",
                            fontSize: convertPxToViewport(24),
                            fontFamily: "Lato",
                            color: "white"
                          }}>
                            {blankPageMsg}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
          </div>
          <Footer className={"landing-footerContainer"} 
            footerBody={
              <div className="footer_logo_rights">
                <div className="footer_logo_text_container">
                  <span className="footer-subtitle">{lang.footer_text.powered_by}</span>
                  <img className={"footer-logo"} src="/images/logo.png"/>
                </div>
                <LogoPI skipLogo landingFooter/>
              </div>
            } 
          />
        </>
      );
    } else {
      checkIfHasAccess()
    }
  };

  return (
    <>
      <ErrorBoundary>
        {switchClient ?
            <>
              <div className="switch_client_animation_container">
                <div className="switch_client_animation_images">
                  <img src="/images/switch-client-logo.png" style={{height: "4.271vw"}}/>
                  <div style={{display: "flex", flexDirection: "column"}}>
                <span className="switch_client_animation_title">{lang.switching_client}
                  <span className="animation_dot1">.</span>
                  <span className="animation_dot2">.</span>
                  <span className="animation_dot3">.</span>
                </span>
                    <span className="switch_client_animation_text">{lang.switch_client_text}</span>
                  </div>
                </div>
              </div>
            </>
            :
        <>
        <div id="toastApp" className={!!toastMessage.error ? "toast toast-fail" :"toast toast-success"}>
          <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (!!toastMessage.error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{toastMessage.message}<span></span></div>
        </div>
        {profitSegmentationOpen?
            <div id={"profit_segmentation_image_container"} className={"profit_segmentation_image_container"}>

              <div className={"profit_segmentation_image"}>
            <img
                src={imagesSignedUrlsState?.filter(f => f.imageType === IMAGE_TYPE.PROFIT_SEGMENTATION_DEFINITION)[0]?.url} // Assuming imagesSignedUrls is an array containing the image details
                alt={imagesSignedUrlsState?.filter(f => f.imageType === IMAGE_TYPE.PROFIT_SEGMENTATION_DEFINITION)[0]?.name} // Assuming imagesSignedUrls is an array containing the image details
            />
                <Button
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.ICON}
                    type={BUTTON_TYPE.DEFAULT}
                    leftIcon={<CloseIcon />}
                    aria-label="close"
                    onBtnClick={()=>setProfitSegmentationOpen(false)}
                    style={{position:"absolute", right:0, background:"lightGray"}}
                />
              </div>
            </div>
            :
            ""}
        {openPersonalSettings && (
          <div id="selectClientMain">
            <SelectClient
              clients={getLocalStorageValueByParameter(CLIENTS) ? JSON.parse(getLocalStorageValueByParameter(CLIENTS)) : []}
              returnToLogin={logout}
              email={userSettingsState?.user?.email}
              signIn={signIn}
              isRedirect={true}
              closeMultiClient={() => setPersonalSettingsOpened(false)}
            />
          </div>
        )}
        {Object.keys(userSettingsState).length > 0 && (
          <SessionTimeout
            ref={sessionTimeoutRef}
            isAuthenticated={userSettingsState?.user && userSettingsState?.machine_name !== ""}
            logout={logout}
            session_timeout_ui={userSettingsState?.session_timeout_ui}
            session_timeout_api={userSettingsState?.session_timeout_api}
          />
        )}

        <div className={openPersonalSettings ? "uk-hidden" : "main-div"}>
          <NavigationHeader
            allowedMenuLinks={userAllowedMenuLinks}
            userSettings={userSettingsState}
            historyPushAndReload={historyPushAndReload}
            showPersonalSettings={() => setPersonalSettingsOpened(true)}
            scenario={scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0] : []}
            signIn={signIn}
            setProfitFormat={setProfitFormat}
            goToReport={goToReport}
            goToLandingPage={goToLandingPage}
            isSwitchDisabled={isSwitchClientDisabled}
            hideSwitchToMenu={noMenuReport || props.isLandingPage}
            hideInfo={hideInfo}
            hideSettings={hideSettings}
            imagesSignedUrls={imagesSignedUrlsState}
            profitSegmentationOpen={profitSegmentationOpen}
            setProfitSegmentationOpen={setProfitSegmentationOpen}
            clientLogo={imagesSignedUrlsState?.filter(f => f.imageType === IMAGE_TYPE.CLIENT_LOGOS)[0]?.url}
          />

          {renderBody()}
        </div>
        </>}
      </ErrorBoundary>
    </>
  );
};

export { Template };
