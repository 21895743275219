import {forwardRef, React, useEffect, useRef, useState, useImperativeHandle} from "react";
import { ReactComponent as NextIcon } from "../../../../styles/images/icons/next-white.svg";
import {convertFirstLetterToUpperCase, copyObjectValues, getTranslationFile, parseBoolean} from "../../../../class/utils";
import {
    API_URL,
    BUILD_PROGRESS_STEPS,
    BUILD_STEPS,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    CURRENT_BUILD_STATUS,
    SIDE_PANEL_BUILD,
    SIZES,
    INITIAL_PROGRESS_STEPS,
    DIALOG_SIZE
} from "../../../../class/constants";
import Button from "../../../../newComponents/Button";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../../../class/networkUtils";
import BuildProgressBar from "./BuildProgressBar";
import Modal from "../../../../newComponents/Modal";

const lang = getTranslationFile();

const BuildProgress = forwardRef((props, ref) => {

    const [buildSteps, setBuildSteps] = useState(INITIAL_PROGRESS_STEPS);
    const [activebuildStep, setActivebuildStep] = useState(0);
    const [currBuildStatus, setCurrBuildStatus] = useState(CURRENT_BUILD_STATUS.NOT_STARTED);
    const [buildPercentage, setBuildPercentage] = useState(0);
    const [openRestartBuildModal, setOpenRestartBuildModal] = useState(false);
    const [openCancelBuildModal, setOpenCancelBuildModal] = useState(false);
    const [changingStep, setChangingStep] = useState(false);
    const [callbackAfterCancel, setCallbackAfterCancel] = useState(false);
    const [isError, setIsError] = useState(false);
    const [pageVisibilty, setPageVisibilty] = useState('visible');
    const [buildCanceled, setBuildCanceled] = useState(false);
    const [lastSucceededJob, setLastSucceededJob] = useState("");

    const interval = useRef(null);
    const screenFirstLoad = useRef(true);
    const allowToastRef = useRef(false);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    const {
        publishedScenarioId,
        handleSubStepNext,
        lastMonthlyBuildStatus,
        handleStepCompletionStatus,
        setRunningRequests,
        setIsBuildRunning,
        setPercentage,
        setForceRebuild,
        setStepChecked,
        period,
        setCancelRevert,
        forceRebuild,
        isBuildInitiated,
        setIsBuildInitiated,
        callbackAfterCancelBuild
    } = props;

    useImperativeHandle(ref, () => ({
        setActivebuildStep : (step) => {
            setActivebuildStepParam(step);
        },
        setOpenCancelBuildModal : (open, callback) =>{
            setOpenCancelBuildModalParam(open, callback)
        },
        getCurrBuildStatus: () => {
            return currBuildStatus;
        }
    }));

    useEffect(() => {
        if(pageVisibilty === 'visible' && !interval.current > 0 && interval.current !=null){
            trackBuildProgress();
        }
    }, [pageVisibilty])
    

    useEffect(()=>{
        setActivebuildStepParam(sessionStorage.getItem("currentJob_"+publishedScenarioId));
        trackBuildProgress();
        return () => {
            if(interval.current !== null){
                clearInterval(interval.current)
            }
        }
    },[])

    useEffect(() => {
        const isBuildRunning =  parseBoolean(sessionStorage.getItem("isBuildRunning_"+publishedScenarioId)) && isBuildInitiated && !buildCanceled || false;
        const isBuildCompleted = parseBoolean(sessionStorage.getItem("isBuildCompleted_"+publishedScenarioId));
        const isBuildError =  parseBoolean(sessionStorage.getItem("isBuildError"));
        const buildPercentage = Math.round(Number(sessionStorage.getItem("buildPerc_"+publishedScenarioId))) || 0;
        const buildSuccess = (isBuildCompleted || lastMonthlyBuildStatus === "succeeded") && !isBuildError && !isBuildRunning;
        const buildFail = ( isBuildError || lastMonthlyBuildStatus === "failed") && !isBuildRunning;
        const notStartedDesign = forceRebuild || !isBuildInitiated || buildCanceled || CURRENT_BUILD_STATUS.CANCEL.includes(lastMonthlyBuildStatus);

        let tempStatus;
        if(isBuildRunning) {
            tempStatus = CURRENT_BUILD_STATUS.RUNNING
        } else if(notStartedDesign){
            tempStatus = CURRENT_BUILD_STATUS.NOT_STARTED
            setActivebuildStep(0);
        } else if(buildSuccess){
            tempStatus = CURRENT_BUILD_STATUS.SUCCEEDED;
            setActivebuildStep(4);
        } else if(buildFail) {
            tempStatus = CURRENT_BUILD_STATUS.FAILED
        } else {
            tempStatus = CURRENT_BUILD_STATUS.NOT_STARTED
            setActivebuildStep(0);
        }

        setCurrBuildStatus(tempStatus);
        setBuildPercentage(buildPercentage);
    })

    const setActivebuildStepParam = (param) => {
        let activebuildStepTemp = INITIAL_PROGRESS_STEPS.findIndex(step => step.id === param);
        activebuildStepTemp = activebuildStepTemp >= 0 ? activebuildStepTemp : 0;
        setActivebuildStep(activebuildStepTemp);
    }

    const trackBuildProgressWithInterval = () => {
        let count = 0;
        interval.current = setInterval(()=>{
            count++;
            trackBuildProgress(count);
        }, 10000);
    }
    
    const runMonthlyFullBuild = () => {
        
        let query = {
            action: "runMonthlyFullBuild",
            period: period
        }
            
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "runMonthlyFullBuild",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: "/run-monthly-full-build",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.periods]: period
        };

        fetchAPI(fetchOptions);
        setOpenRestartBuildModal(false)
        setRunningRequests(publishedScenarioId, SIDE_PANEL_BUILD.ACTIONS.ASSIGNING_COST);
        setPercentage(publishedScenarioId,0)
        setIsBuildRunning(publishedScenarioId,true);
        setActivebuildStepParam(BUILD_PROGRESS_STEPS.PREPARING_DATA);
        // props.handleSubStepNext();
        setForceRebuild(false);
        setCancelRevert(false);
        setIsBuildInitiated();
        trackBuildProgressWithInterval();
        setCurrBuildStatus(CURRENT_BUILD_STATUS.NOT_STARTED);
        setBuildCanceled(false);
        handleStepCompletionStatus(BUILD_STEPS.BUILD_PROGRESS, false);
        sessionStorage.removeItem("isBuildError");
    }

    const trackBuildProgress = (count)=>{
        
            let doneRequests = 0;
            let totalRequests = 8;
            let runningRequests = "";
            let query = {
                action: "trackBuildProgress",
                scenario_id: publishedScenarioId,
            }
            let onThenCallback = (data) => {
                screenFirstLoad.current = false;
                let succeededJobs = data.SucceededJobs.length > 0 ? copyObjectValues(data.SucceededJobs) : [lang.build_progress_currently_running_job.preparing_data[0]]
                let currentJob = convertFirstLetterToUpperCase(succeededJobs?.pop().replaceAll("_"," ").toLowerCase());
                setLastSucceededJob(data.SucceededJobs.length > 0 ? currentJob : "");
                let status = data.status;
                if (lang.build_progress_currently_running_job.preparing_data.includes(currentJob)) {
                    currentJob = BUILD_PROGRESS_STEPS.PREPARING_DATA;
                } else if (lang.build_progress_currently_running_job.assigning_amounts.includes(currentJob)){
                    currentJob = BUILD_PROGRESS_STEPS.ASSIGNING_AMOUNTS;
                } else if (lang.build_progress_currently_running_job.generating_data.includes(currentJob)){
                    currentJob = BUILD_PROGRESS_STEPS.GENERATING_DATA;
                } else if (currentJob === lang.build_progress_currently_running_job.generating_segments){
                    currentJob = BUILD_PROGRESS_STEPS.GENERATING_SEGMENTS;
                } else if (currentJob === lang.build_progress_currently_running_job.build_done){
                    currentJob = BUILD_PROGRESS_STEPS.BUILD_COMPLETE;
                    status = "succeeded";
                }
                doneRequests = data.SucceededJobs?data.SucceededJobs.length:0;
                totalRequests = data.TotalJobs;
                runningRequests = currentJob;
                
                let percentage = 100*(doneRequests/totalRequests);
                if(currentJob !== ''){
                    setRunningRequests(publishedScenarioId,runningRequests)
                }
                if(status !=="canceling"){
                   setPercentage(publishedScenarioId,percentage)
                }
                if(status === "running" && percentage !== undefined){
                    allowToastRef.current = true;
                   setIsBuildRunning(publishedScenarioId,true);
                   setForceRebuild(false);
                   if(interval.current == null){
                    trackBuildProgressWithInterval();
                   }
                }
                if(count > 1000 || percentage === 100 || status === "failed" || status === "succeeded" || status === "canceling" || !parseBoolean(sessionStorage.getItem("isBuildRunning_"+publishedScenarioId))) {
                    status = status === "running" & percentage === 100 ? "succeeded": status;
                    if(status === "succeeded") {
                        sessionStorage.setItem("isBuildCompleted_"+publishedScenarioId,true);
                        currentJob = BUILD_PROGRESS_STEPS.BUILD_COMPLETE;
                        if(allowToastRef.current){
                            setIsError(false);
                            launchToast(true);
                        }
                    } else if (status === "failed"){
                        sessionStorage.setItem("isBuildError",true);
                        setForceRebuild(true);
                        if(allowToastRef.current){
                            setIsError(true);
                            launchToast(false);
                        }
                    } else if (CURRENT_BUILD_STATUS.CANCEL.includes(status)){
                        setBuildCanceled(true);
                        setLastSucceededJob("");
                        setIsBuildRunning(publishedScenarioId,false)
                    }
                    setIsBuildRunning(publishedScenarioId,false)
                    clearInterval(interval.current);
                }
                setActivebuildStepParam(currentJob)
            }

            let onErrorCallback = () => {
                setIsBuildRunning(publishedScenarioId, false);
                clearInterval(interval.current);
                screenFirstLoad.current = false;
            }

            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "trackBuildProgress",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: screenFirstLoad.current,
                [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
              };
            fetchAPI(fetchOptions);
    }

    const cancelBuild = () => {
        let query = {
            action: "stopFullBuild",
            scenario_id: publishedScenarioId
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "stopFullBuild",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
        };
        fetchAPI(fetchOptions); 
        setOpenCancelBuildModal(false);
        setBuildCanceled(true);
        if(callbackAfterCancel || callbackAfterCancel === 0){ // == 0 is put to handle if use clicked on introduction step, since 0 in js is false;
            callbackAfterCancelBuild(callbackAfterCancel);
        }
    }

    const proceed = () => {
        handleSubStepNext();
        handleStepCompletionStatus(BUILD_STEPS.BUILD_PROGRESS, true);
        setStepChecked(BUILD_STEPS.BUILD_PROGRESS);
    }

    const buildProgressActionButton = () => {
        switch (currBuildStatus) {
            case CURRENT_BUILD_STATUS.NOT_STARTED:
                return (
                    <Button
                        id={"start-build"}
                        label={"Start Build"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        rightIcon={<NextIcon />}
                        className={"uk-margin-large-top"}
                        onBtnClick={runMonthlyFullBuild}
                    />
                )


            case CURRENT_BUILD_STATUS.RUNNING:
                return (
                        <Button
                            id={"start-build"}
                            label={"Cancel Build"}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-margin-large-top red"}
                            onBtnClick={ () => {
                                setOpenCancelBuildModal(true);
                                setCallbackAfterCancel(false);
                                setChangingStep(false);
                            }}
                        />
                )

            case CURRENT_BUILD_STATUS.SUCCEEDED:
                return (
                        <Button
                            id={"proceed"}
                            label={"Proceed"}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            rightIcon={<NextIcon />}
                            className={"uk-margin-large-top"}
                            onBtnClick={proceed}
                        />
                )
        
            default:
                break;
        }
    }

    const setOpenRestartBuildModalParam = (param) => {
        setOpenRestartBuildModal(param)
    }

    const setOpenCancelBuildModalParam = (open, callback) => {
        setChangingStep(true);
        setOpenCancelBuildModal(open);
        setCallbackAfterCancel(callback);
    }

    const openRestartBuildModalActions = () => {
        return (
          <>
            <Button
              label={lang.monthly_build.build_scenario.build_progress.restart_build_modal.restart_btn}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={runMonthlyFullBuild}
            />
    
            <Button
              label={lang.monthly_build.build_scenario.build_progress.restart_build_modal.dismiss_btn}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setOpenRestartBuildModal(false)}
            />
          </>
        );
      };

      const cancelBuildModalActions = () => {
        return (
          <>
            <Button
              label={lang.monthly_build.build_scenario.build_progress.cancel_build_modal.cancel_btn}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DANGER}
              onBtnClick={cancelBuild}
            />
    
            <Button
              label={lang.monthly_build.build_scenario.build_progress.cancel_build_modal.dismiss_btn}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setOpenCancelBuildModal(false)}
            />
          </>
        );
      };

      const cancelBuildModalBody = () => {
        const message = changingStep ? lang.monthly_build.build_scenario.build_progress.cancel_build_modal.body_moving : lang.monthly_build.build_scenario.build_progress.cancel_build_modal.body_cancel;

        return (
            <span className="p-text">
                {message}
            </span>
        );
      };

      function handleVisibilityChange() {
        if (document.visibilityState !== pageVisibilty) {
            setPageVisibilty(document.visibilityState)
        }
    }

    const launchToast=(success)=> {
        allowToastRef.current = false;
        if(success === true) {
            $("#toastRunMonthlyFullBuild #message").text( lang.build_status.passed);
        } else {
            $("#toastRunMonthlyFullBuild #message").text (lang.build_status.failed);
        }
        $("#toastRunMonthlyFullBuild").addClass("show");
        setTimeout(function(){
            $("#toastRunMonthlyFullBuild").removeClass("show");
        }, 4000);
    }


   
    return (
        <div>

            <div id="toastRunMonthlyFullBuild" className={!!isError ? "toast toast-fail" : "toast toast-success"}>
                <div id="desc"><i className= {"fa-lg fas uk-margin-small-right " + ( !!isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i><span id="message"/></div>
            </div>

            <BuildProgressBar
                buildSteps={buildSteps} activebuildStep={activebuildStep} buildPercentage={buildPercentage}
                currBuildStatus={currBuildStatus} setOpenRestartBuildModal={setOpenRestartBuildModalParam}
                lastSucceededJob={lastSucceededJob} />
            
            <div>
                {buildProgressActionButton()}
            </div>

            <Modal // modal appears when pressing on try again in footer of progress bar
                key={"restart-build"}
                id={"restart-build"}
                openDialog={openRestartBuildModal}
                title={lang.monthly_build.build_scenario.build_progress.restart_build_modal.header}
                bodyContent={ () => <span className="p-text">{lang.monthly_build.build_scenario.build_progress.restart_build_modal.body}</span>}
                dialogActions={openRestartBuildModalActions}
                closeClick={() => setOpenRestartBuildModal(false)}
                size={DIALOG_SIZE.MEDIUM}
            />

            <Modal // modal appears when pressing on try again in footer of progress bar
                key={"cancel-build"}
                id={"cancel-build"}
                openDialog={openCancelBuildModal}
                title={lang.monthly_build.build_scenario.build_progress.cancel_build_modal.header}
                bodyContent={cancelBuildModalBody}
                dialogActions={cancelBuildModalActions}
                closeClick={() => setOpenCancelBuildModal(false)}
                size={DIALOG_SIZE.MEDIUM}
            />
        </div>
    );
});
export default (BuildProgress);
