var map = {"type":"FeatureCollection", "features": [
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.357731,39.213903],[-84.390621,39.187355],[-84.324039,39.152661],[-84.299596,39.170445],[-84.296725,39.187037],[-84.296099,39.209712],[-84.312411,39.211104],[-84.326196,39.223629],[-84.357731,39.213903]]]},"properties":{"id":"45243","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.52114,39.107354],[-84.522525,39.092158],[-84.462078,39.121872],[-84.453392,39.123411],[-84.463639,39.127547],[-84.501832,39.126684],[-84.521338,39.119184],[-84.52114,39.107354]]]},"properties":{"id":"45202","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.401576,39.134683],[-84.453392,39.123411],[-84.462078,39.121872],[-84.433385,39.078],[-84.401598,39.109215],[-84.374759,39.138968],[-84.401576,39.134683]]]},"properties":{"id":"45226","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.521338,39.119184],[-84.501832,39.126684],[-84.497724,39.134862],[-84.50544,39.136908],[-84.525548,39.132614],[-84.529525,39.130966],[-84.521338,39.119184]]]},"properties":{"id":"45219","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.411132,39.193151],[-84.444529,39.18178],[-84.434782,39.164465],[-84.422889,39.165668],[-84.39678,39.180709],[-84.411132,39.193151]]]},"properties":{"id":"45213","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.501937,39.150201],[-84.483099,39.172358],[-84.486143,39.179559],[-84.497144,39.183557],[-84.509835,39.163637],[-84.501937,39.150201]]]},"properties":{"id":"45217","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.549475,39.217817],[-84.560721,39.188763],[-84.519584,39.176032],[-84.503114,39.199779],[-84.492444,39.207158],[-84.502845,39.216788],[-84.549475,39.217817]]]},"properties":{"id":"45224","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.525548,39.132614],[-84.50544,39.136908],[-84.501937,39.150201],[-84.509835,39.163637],[-84.527807,39.160784],[-84.532816,39.142465],[-84.525548,39.132614]]]},"properties":{"id":"45220","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.542945,39.124523],[-84.529525,39.130966],[-84.525548,39.132614],[-84.532816,39.142465],[-84.567526,39.157716],[-84.564335,39.135536],[-84.542945,39.124523]]]},"properties":{"id":"45225","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.567526,39.157716],[-84.532816,39.142465],[-84.527807,39.160784],[-84.519584,39.176032],[-84.560721,39.188763],[-84.57866,39.175857],[-84.567526,39.157716]]]},"properties":{"id":"45223","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.804143,40.936653],[-83.785599,40.877657],[-83.708176,40.936192],[-83.789513,41.00065],[-83.79915,41.000556],[-83.799183,41.007928],[-83.837004,40.993372],[-83.804143,40.936653]]]},"properties":{"id":"45881","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.6885,41.124125],[-83.880674,41.027561],[-83.880563,41.020108],[-83.837004,40.993372],[-83.799183,41.007928],[-83.789513,41.00065],[-83.708176,40.936192],[-83.672295,40.906797],[-83.538129,40.962583],[-83.477544,41.026604],[-83.477354,41.07079],[-83.585737,41.123564],[-83.6885,41.124125]]]},"properties":{"id":"45840","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.651426,40.819487],[-83.651315,40.848591],[-83.660794,40.848492],[-83.660974,40.827407],[-83.670526,40.819542],[-83.651426,40.819487]]]},"properties":{"id":"45897","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.785343,40.688389],[-83.785621,40.669055],[-83.766349,40.678705],[-83.785343,40.688389]]]},"properties":{"id":"45859","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630429,40.532894],[-83.677942,40.533501],[-83.708788,40.475131],[-83.630176,40.435889],[-83.579834,40.467208],[-83.630429,40.532894]]]},"properties":{"id":"43347","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.379805,39.079534],[-83.393831,39.078083],[-83.382297,39.069151],[-83.379805,39.079534]]]},"properties":{"id":"45172","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.2609,40.563463],[-82.181716,40.51136],[-82.106111,40.501173],[-82.069932,40.564463],[-82.131659,40.578668],[-82.166087,40.574701],[-82.2609,40.563463]]]},"properties":{"id":"44628","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.038302,40.60948],[-81.996003,40.602098],[-81.931782,40.667231],[-81.93318,40.696615],[-82.126537,40.749318],[-82.126457,40.733862],[-82.100044,40.678839],[-82.038302,40.60948]]]},"properties":{"id":"44676","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.801631,40.561757],[-81.80561,40.55799],[-81.79578,40.557497],[-81.795184,40.563372],[-81.801631,40.561757]]]},"properties":{"id":"44610","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.626897,41.122711],[-82.673173,41.104781],[-82.58601,41.067424],[-82.537444,41.102994],[-82.538473,41.145861],[-82.623634,41.141338],[-82.626897,41.122711]]]},"properties":{"id":"44855","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.590246,39.185513],[-82.447181,39.138797],[-82.417748,39.211243],[-82.590246,39.185513]]]},"properties":{"id":"45634","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.123176,39.446831],[-83.190836,39.311775],[-83.154659,39.282863],[-83.152345,39.277157],[-83.118936,39.212849],[-82.865889,39.135389],[-82.792729,39.10264],[-82.74802,39.14682],[-82.761953,39.218075],[-82.749709,39.357164],[-82.764815,39.402448],[-82.928459,39.477226],[-83.037259,39.507815],[-83.078402,39.481889],[-83.123176,39.446831]],[[-82.821753,39.205952],[-82.800537,39.209447],[-82.811374,39.196955],[-82.821753,39.205952]]]},"properties":{"id":"45601","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.792203,40.156208],[-80.794164,40.178205],[-80.807695,40.16173],[-80.792203,40.156208]]]},"properties":{"id":"43939","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.629029,40.516853],[-80.621573,40.515925],[-80.623379,40.523617],[-80.629029,40.516853]]]},"properties":{"id":"43961","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.377151,40.283172],[-82.585739,40.269944],[-82.582568,40.245542],[-82.517762,40.18982],[-82.444007,40.192421],[-82.28124,40.22269],[-82.272217,40.276236],[-82.28949,40.283627],[-82.359847,40.270168],[-82.377151,40.283172]]]},"properties":{"id":"43080","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.661956,40.572064],[-82.679121,40.478605],[-82.683813,40.474742],[-82.699562,40.422781],[-82.651863,40.403881],[-82.41153,40.471358],[-82.470297,40.539379],[-82.661956,40.572064]]]},"properties":{"id":"43019","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.661956,40.572064],[-82.470297,40.539379],[-82.407086,40.610938],[-82.389094,40.638588],[-82.453695,40.652744],[-82.48784,40.659755],[-82.663179,40.623187],[-82.661956,40.572064]]]},"properties":{"id":"44813","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.28949,40.283627],[-82.272217,40.276236],[-82.268991,40.285403],[-82.261941,40.2922],[-82.286777,40.289671],[-82.28949,40.283627]]]},"properties":{"id":"43005","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488739,41.624048],[-81.48794,41.586249],[-81.447155,41.569966],[-81.452689,41.623859],[-81.488739,41.624048]]]},"properties":{"id":"44092","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.613805,40.043622],[-82.560402,40.125652],[-82.666467,40.103667],[-82.613805,40.043622]]]},"properties":{"id":"43001","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.108118,41.447147],[-82.097326,41.421029],[-82.067869,41.430027],[-82.066972,41.478655],[-82.066554,41.508772],[-82.130079,41.489922],[-82.108118,41.447147]]]},"properties":{"id":"44054","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.184596,41.41824],[-82.179514,41.399684],[-82.160709,41.418165],[-82.184596,41.41824]]],[[[-82.184596,41.41824],[-82.199951,41.464626],[-82.283469,41.430789],[-82.282831,41.403744],[-82.184596,41.41824]]]]},"properties":{"id":"44053","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.62921,41.728001],[-83.623033,41.686804],[-83.584347,41.677856],[-83.585542,41.729237],[-83.62921,41.728001]]]},"properties":{"id":"43613","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.339071,41.58882],[-83.197756,41.632559],[-83.283227,41.675846],[-83.415919,41.618938],[-83.415773,41.604132],[-83.368227,41.574123],[-83.368176,41.582153],[-83.353305,41.58892],[-83.342395,41.60291],[-83.339071,41.58882]]]},"properties":{"id":"43412","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.649271,41.727382],[-83.806136,41.72237],[-83.800133,41.673265],[-83.791139,41.668551],[-83.760523,41.65863],[-83.695449,41.683059],[-83.69568,41.683669],[-83.649271,41.727382]]]},"properties":{"id":"43560","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.649271,41.727382],[-83.69568,41.683669],[-83.644451,41.688094],[-83.623033,41.686804],[-83.62921,41.728001],[-83.649271,41.727382]]]},"properties":{"id":"43623","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.445339,41.690738],[-83.444025,41.690759],[-83.44405,41.695282],[-83.445341,41.695297],[-83.445339,41.690738]]]},"properties":{"id":"43434","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.612447,41.091217],[-80.615481,41.07587],[-80.600891,41.064179],[-80.576999,41.06606],[-80.568996,41.083629],[-80.612447,41.091217]]]},"properties":{"id":"44405","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.576999,41.06606],[-80.600891,41.064179],[-80.615899,41.060609],[-80.572019,41.029245],[-80.576999,41.06606]]]},"properties":{"id":"44471","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.663947,41.060939],[-80.644975,41.060793],[-80.663983,41.087634],[-80.663947,41.060939]]]},"properties":{"id":"44507","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.956312,40.929846],[-81.897904,40.894415],[-81.878526,40.916275],[-81.853399,40.971849],[-81.961478,41.003049],[-81.956312,40.929846]]]},"properties":{"id":"44217","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.88352,41.275008],[-81.869149,41.218513],[-81.784902,41.201512],[-81.785097,41.27645],[-81.835917,41.275597],[-81.878053,41.275044],[-81.88352,41.275008]]]},"properties":{"id":"44212","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720804,40.938185],[-81.64785,40.925197],[-81.647653,40.980301],[-81.688608,41.005747],[-81.728744,40.98841],[-81.720804,40.938185]]]},"properties":{"id":"44230","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.614755,41.112242],[-81.683889,41.139664],[-81.691982,41.130091],[-81.686452,41.070234],[-81.599143,41.062355],[-81.614755,41.112242]]]},"properties":{"id":"44321","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.777854,39.029279],[-81.84282,39.027926],[-81.820921,38.946908],[-81.777854,39.029279]]]},"properties":{"id":"45770","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.20174,39.119667],[-82.181144,39.047253],[-82.103096,39.045606],[-82.20174,39.119667]]]},"properties":{"id":"45775","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.374376,39.989273],[-84.407621,39.963452],[-84.417318,39.959346],[-84.40437,39.91996],[-84.393164,39.920148],[-84.388108,39.920227],[-84.286062,39.921554],[-84.278085,39.921554],[-84.275087,39.950276],[-84.304603,39.997971],[-84.374376,39.989273]]]},"properties":{"id":"45383","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.246797,39.771222],[-84.199513,39.764739],[-84.244742,39.801857],[-84.273356,39.797758],[-84.270318,39.786356],[-84.246797,39.771222]]]},"properties":{"id":"45406","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.207127,39.728999],[-84.192936,39.740054],[-84.246797,39.771222],[-84.270318,39.786356],[-84.348975,39.775637],[-84.366627,39.760199],[-84.347561,39.701876],[-84.316143,39.687136],[-84.276432,39.695403],[-84.23615,39.70316],[-84.207127,39.728999]],[[-84.268283,39.739616],[-84.268504,39.746817],[-84.253077,39.749405],[-84.253001,39.74067],[-84.268283,39.739616]]]},"properties":{"id":"45417","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.268898,39.861393],[-84.292016,39.833079],[-84.254836,39.819825],[-84.23516,39.81129],[-84.217634,39.813985],[-84.268898,39.861393]]]},"properties":{"id":"45415","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.588797,39.564846],[-81.528501,39.584738],[-81.532626,39.59254],[-81.587358,39.625199],[-81.622432,39.658057],[-81.706632,39.588451],[-81.706302,39.586251],[-81.58066,39.564942],[-81.580588,39.565998],[-81.588797,39.564846]]]},"properties":{"id":"45715","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.794507,39.591409],[-81.970757,39.710331],[-82.026534,39.69791],[-82.035874,39.592846],[-81.953232,39.545568],[-81.794507,39.591409]]]},"properties":{"id":"43758","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.869809,40.156964],[-81.916564,40.07809],[-81.841133,40.029803],[-81.784306,40.103215],[-81.810176,40.141726],[-81.869809,40.156964]]]},"properties":{"id":"43802","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.731287,39.79567],[-81.879742,39.872804],[-81.902312,39.865726],[-81.986278,39.811859],[-82.022489,39.768149],[-81.996583,39.767852],[-81.770519,39.758497],[-81.731287,39.79567]]]},"properties":{"id":"43720","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.58066,39.564942],[-81.533335,39.476862],[-81.442619,39.491322],[-81.414414,39.536453],[-81.434192,39.582293],[-81.528501,39.584738],[-81.588797,39.564846],[-81.583731,39.567217],[-81.580588,39.565998],[-81.58066,39.564942]]]},"properties":{"id":"45744","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.419452,41.095743],[-84.438181,41.070341],[-84.419035,41.070324],[-84.419452,41.095743]]]},"properties":{"id":"45861","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.150528,39.581339],[-82.035874,39.592846],[-82.026534,39.69791],[-82.128976,39.657231],[-82.131044,39.658226],[-82.166115,39.600897],[-82.20161,39.587428],[-82.150528,39.581339]]]},"properties":{"id":"43730","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.128976,39.657231],[-82.162196,39.675373],[-82.131044,39.658226],[-82.128976,39.657231]]]},"properties":{"id":"43761","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.16373,39.538],[-83.078402,39.481889],[-83.037259,39.507815],[-83.108448,39.690229],[-83.150793,39.684404],[-83.213155,39.620754],[-83.16373,39.538]]]},"properties":{"id":"43164","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.16373,39.538],[-83.275352,39.504788],[-83.232434,39.456702],[-83.123176,39.446831],[-83.078402,39.481889],[-83.16373,39.538]]]},"properties":{"id":"43115","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.206667,39.768091],[-83.204697,39.768024],[-83.20643,39.769531],[-83.206667,39.768091]]]},"properties":{"id":"43117","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.311602,41.202385],[-81.298898,41.240578],[-81.322381,41.275667],[-81.382178,41.276552],[-81.391931,41.202855],[-81.311602,41.202385]]]},"properties":{"id":"44241","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.516075,40.752707],[-82.498917,40.755003],[-82.508199,40.77538],[-82.516075,40.752707]]]},"properties":{"id":"44902","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.268788,41.428917],[-83.221847,41.392493],[-83.159301,41.436104],[-83.220947,41.458189],[-83.268788,41.428917]]]},"properties":{"id":"43442","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.999916,41.457846],[-83.038646,41.457515],[-83.067459,41.457169],[-83.159301,41.436104],[-83.221847,41.392493],[-83.241842,41.37038],[-83.240457,41.303925],[-83.219406,41.253195],[-83.154557,41.25575],[-83.052238,41.288579],[-82.973958,41.357656],[-82.999916,41.457846]]]},"properties":{"id":"43420","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.386107,41.254447],[-83.264874,41.203839],[-83.240313,41.242602],[-83.254702,41.253136],[-83.301194,41.279644],[-83.360321,41.272908],[-83.386107,41.254447]]]},"properties":{"id":"44841","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.808431,38.592107],[-82.765355,38.657449],[-82.758286,38.679734],[-82.872527,38.680652],[-82.839282,38.586108],[-82.808431,38.592107]]]},"properties":{"id":"45629","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.248019,38.746646],[-83.20906,38.697504],[-83.090018,38.697162],[-83.093625,38.700933],[-83.087521,38.70059],[-83.086285,38.697381],[-83.079881,38.682671],[-83.01285,38.73054],[-83.012288,38.820478],[-83.129657,38.793051],[-83.248019,38.746646]]]},"properties":{"id":"45663","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.700842,40.698407],[-81.697123,40.692557],[-81.64982,40.668381],[-81.646769,40.666498],[-81.533427,40.648488],[-81.454517,40.67894],[-81.430954,40.715057],[-81.476958,40.762277],[-81.534081,40.758259],[-81.611104,40.757729],[-81.700842,40.698407]],[[-81.588266,40.70592],[-81.616747,40.707858],[-81.615662,40.721315],[-81.590941,40.726428],[-81.588266,40.70592]]]},"properties":{"id":"44662","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.505457,41.054472],[-81.541982,41.061559],[-81.536939,41.032855],[-81.505736,41.029071],[-81.505457,41.054472]]]},"properties":{"id":"44301","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.493571,41.162215],[-81.497108,41.123252],[-81.465034,41.118851],[-81.452634,41.124785],[-81.449934,41.148056],[-81.493571,41.162215]]]},"properties":{"id":"44221","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.704238,41.27232],[-80.711122,41.205288],[-80.6804,41.202987],[-80.62644,41.21732],[-80.611213,41.267151],[-80.645436,41.287619],[-80.704238,41.27232]]]},"properties":{"id":"44473","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.263861,40.348601],[-83.257276,40.354512],[-83.264263,40.356438],[-83.263861,40.348601]]]},"properties":{"id":"43036","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.452873,40.414516],[-83.53735,40.356351],[-83.543776,40.297073],[-83.39738,40.350227],[-83.452873,40.414516]]]},"properties":{"id":"43067","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.249631,39.37097],[-84.258548,39.351992],[-84.239438,39.365396],[-84.249631,39.37097]]]},"properties":{"id":"45034","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.84819,39.396385],[-81.85293,39.325373],[-81.797573,39.299606],[-81.755235,39.334864],[-81.72326,39.403235],[-81.741821,39.451562],[-81.825194,39.45962],[-81.829435,39.449303],[-81.84819,39.396385]]]},"properties":{"id":"45724","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.827874,40.798161],[-81.864114,40.726159],[-81.729136,40.674812],[-81.704905,40.69091],[-81.707201,40.696103],[-81.749499,40.751441],[-81.827874,40.798161]]]},"properties":{"id":"44606","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.534087,41.32612],[-83.592838,41.282259],[-83.563708,41.251482],[-83.496503,41.253708],[-83.447842,41.297367],[-83.47495,41.340838],[-83.534087,41.32612]]]},"properties":{"id":"43466","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.645375,41.268504],[-83.708556,41.31274],[-83.727745,41.309301],[-83.805265,41.313798],[-83.755961,41.255417],[-83.645375,41.268504]]]},"properties":{"id":"43462","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.492345,41.246185],[-83.477523,41.23911],[-83.477637,41.248056],[-83.492345,41.246185]]]},"properties":{"id":"43467","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.417425,40.811339],[-83.418681,40.816004],[-83.42119,40.817676],[-83.420965,40.809185],[-83.417425,40.811339]]]},"properties":{"id":"43330","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.515425,40.854491],[-83.457565,40.84315],[-83.380732,40.875759],[-83.486092,40.904945],[-83.515425,40.854491]]]},"properties":{"id":"43359","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.119139,40.732253],[-84.01322,40.731125],[-83.994156,40.731146],[-83.945288,40.758706],[-83.945351,40.755138],[-83.958638,40.753375],[-83.958228,40.75742],[-83.937461,40.819093],[-83.958691,40.833261],[-84.128524,40.776126],[-84.119139,40.732253]]]},"properties":{"id":"45801","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.204336,40.729924],[-84.139909,40.708644],[-84.119139,40.732253],[-84.128524,40.776126],[-84.204336,40.729924]]]},"properties":{"id":"45805","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.214862,40.991162],[-84.39893,40.946719],[-84.408077,40.917659],[-84.272343,40.837797],[-84.192843,40.859665],[-84.196132,40.979814],[-84.214862,40.991162]],[[-84.345833,40.925743],[-84.343964,40.933896],[-84.335695,40.934469],[-84.335463,40.925054],[-84.345833,40.925743]]]},"properties":{"id":"45844","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.090463,40.832301],[-84.080809,40.832978],[-84.077309,40.846535],[-84.084182,40.846497],[-84.090463,40.832301]]]},"properties":{"id":"45820","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.250834,40.773509],[-82.262101,40.777886],[-82.259678,40.768712],[-82.250834,40.773509]]]},"properties":{"id":"44838","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.614757,41.714141],[-80.695382,41.606891],[-80.706697,41.571227],[-80.643497,41.534222],[-80.519343,41.544137],[-80.519412,41.714731],[-80.614757,41.714141]]]},"properties":{"id":"44003","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.83416,41.789103],[-80.852759,41.714166],[-80.809743,41.654628],[-80.719264,41.571137],[-80.71322,41.571258],[-80.706697,41.571227],[-80.695382,41.606891],[-80.622537,41.748514],[-80.617823,41.781714],[-80.683638,41.825117],[-80.83416,41.789103]]]},"properties":{"id":"44047","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.643497,41.534222],[-80.706697,41.571227],[-80.71322,41.571258],[-80.647729,41.508705],[-80.643497,41.534222]]],[[[-80.643497,41.534222],[-80.519225,41.499924],[-80.519343,41.544137],[-80.643497,41.534222]]]]},"properties":{"id":"44093","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.172039,39.466581],[-82.187605,39.467496],[-82.183096,39.463211],[-82.170934,39.464077],[-82.172039,39.466581]]]},"properties":{"id":"45716","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.131073,39.393558],[-82.122907,39.392598],[-82.116292,39.406277],[-82.130062,39.405031],[-82.131073,39.393558]]]},"properties":{"id":"45719","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.032224,40.617489],[-84.280601,40.671152],[-84.353749,40.648563],[-84.29924,40.534148],[-84.230985,40.493713],[-84.068984,40.483135],[-84.002372,40.483115],[-83.9938,40.485106],[-83.94811,40.547421],[-83.95853,40.560871],[-83.946472,40.560342],[-83.946509,40.57168],[-84.032224,40.617489]],[[-84.078791,40.555964],[-84.078925,40.549832],[-84.089234,40.556325],[-84.078791,40.555964]],[[-84.09,40.602287],[-84.084768,40.606652],[-84.082666,40.599084],[-84.09,40.602287]],[[-84.257237,40.626296],[-84.256149,40.620361],[-84.26515,40.620407],[-84.265208,40.627637],[-84.257237,40.626296]]]},"properties":{"id":"45895","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.358359,40.48805],[-84.298072,40.453269],[-84.230985,40.493713],[-84.29924,40.534148],[-84.358359,40.48805]]]},"properties":{"id":"45871","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.831762,40.037531],[-80.847437,40.013263],[-80.834871,39.956697],[-80.740349,39.994291],[-80.737316,40.064584],[-80.831233,40.053639],[-80.831762,40.037531]],[[-80.831762,40.037531],[-80.81901,40.027311],[-80.831018,40.021022],[-80.831762,40.037531]]]},"properties":{"id":"43906","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.064059,39.905682],[-81.08628,39.789484],[-80.94886,39.766917],[-80.939479,39.774116],[-80.904709,39.833548],[-80.919265,39.871534],[-80.919942,39.871607],[-81.013216,39.909134],[-81.030069,39.944062],[-81.064059,39.905682]]]},"properties":{"id":"43716","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.123964,40.081822],[-81.086467,39.932246],[-81.064059,39.905682],[-81.030069,39.944062],[-81.062312,40.06577],[-81.070164,40.064192],[-81.075338,40.064562],[-81.07999,40.066603],[-81.060707,40.06618],[-81.065736,40.086306],[-81.123964,40.081822]]]},"properties":{"id":"43719","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.80892,40.12601],[-80.733028,40.084537],[-80.710055,40.137584],[-80.716545,40.157864],[-80.787774,40.155954],[-80.80892,40.12601]]]},"properties":{"id":"43935","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.831762,40.037531],[-80.831018,40.021022],[-80.81901,40.027311],[-80.831762,40.037531]]]},"properties":{"id":"43940","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.130259,40.171058],[-81.131687,40.160053],[-81.141213,40.163648],[-81.140838,40.171051],[-81.138049,40.217153],[-81.200389,40.218052],[-81.147843,40.101533],[-81.123964,40.081822],[-81.065736,40.086306],[-81.028158,40.095662],[-81.016449,40.109359],[-81.014266,40.113117],[-81.051432,40.165474],[-81.130259,40.171058]]]},"properties":{"id":"43977","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.695551,38.768614],[-83.839874,38.837056],[-83.915861,38.769766],[-83.783822,38.695826],[-83.736854,38.748813],[-83.696194,38.761722],[-83.695551,38.768614]]]},"properties":{"id":"45167","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.402824,39.377571],[-84.44324,39.315655],[-84.439499,39.299963],[-84.351007,39.317509],[-84.346331,39.368446],[-84.402824,39.377571]]]},"properties":{"id":"45069","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.571572,39.387456],[-84.545475,39.366662],[-84.543867,39.366634],[-84.49666,39.343418],[-84.476315,39.316712],[-84.44324,39.315655],[-84.402824,39.377571],[-84.436828,39.443843],[-84.543593,39.53087],[-84.579673,39.529547],[-84.560949,39.482339],[-84.552679,39.48444],[-84.54799,39.474364],[-84.558305,39.479486],[-84.571572,39.387456]]]},"properties":{"id":"45011","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.50273,39.305502],[-84.487503,39.266062],[-84.440804,39.260325],[-84.439499,39.299963],[-84.44324,39.315655],[-84.476315,39.316712],[-84.50273,39.305502]]]},"properties":{"id":"45246","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.545475,39.366662],[-84.571572,39.387456],[-84.592246,39.350856],[-84.545475,39.366662]]]},"properties":{"id":"45015","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347561,39.701876],[-84.480149,39.65705],[-84.487319,39.656955],[-84.479416,39.604091],[-84.365124,39.583999],[-84.327249,39.599291],[-84.316143,39.687136],[-84.347561,39.701876]]]},"properties":{"id":"45327","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.672897,40.019096],[-83.542995,39.994041],[-83.408259,40.056031],[-83.407738,40.060504],[-83.503084,40.120206],[-83.590522,40.130596],[-83.639037,40.082256],[-83.689952,40.019939],[-83.672897,40.019096]],[[-83.626714,39.998434],[-83.625172,40.003774],[-83.617953,40.004638],[-83.619764,39.996301],[-83.626714,39.998434]]]},"properties":{"id":"43044","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.816023,39.924462],[-83.810479,39.921309],[-83.806763,39.924384],[-83.808233,39.927021],[-83.816023,39.924462]]],[[[-83.820504,39.963943],[-83.732118,39.93433],[-83.682583,39.928899],[-83.672897,40.019096],[-83.689952,40.019939],[-83.832549,40.011774],[-83.834008,40.010876],[-83.820504,39.963943]]],[[[-83.949845,39.982633],[-83.916596,39.947411],[-83.835462,40.010798],[-83.83542,40.017343],[-83.918855,40.054683],[-83.939563,40.034945],[-83.949845,39.982633]],[[-83.949845,39.982633],[-83.946359,39.988815],[-83.939542,39.985265],[-83.949845,39.982633]]],[[[-83.888515,39.826074],[-83.829105,39.800535],[-83.821943,39.797051],[-83.81101,39.795876],[-83.781262,39.811905],[-83.704891,39.911359],[-83.813464,39.875637],[-83.934394,39.901871],[-83.954368,39.888473],[-83.939692,39.886995],[-83.888515,39.826074]]]]},"properties":{"id":"45502","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.301828,39.152566],[-84.260544,39.131027],[-84.25827,39.11907],[-84.160774,39.172587],[-84.18054,39.200036],[-84.288288,39.186487],[-84.288698,39.186526],[-84.296725,39.187037],[-84.299596,39.170445],[-84.301828,39.152566]]]},"properties":{"id":"45150","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.086389,39.305899],[-84.145285,39.2814],[-84.033569,39.241156],[-84.086389,39.305899]]]},"properties":{"id":"45162","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.244702,40.951904],[-81.14792,40.850542],[-81.131447,40.850409],[-81.012311,40.871241],[-81.027412,40.901478],[-81.031823,40.937699],[-81.050727,40.959446],[-81.086818,40.98587],[-81.144786,40.988214],[-81.143805,40.981034],[-81.153196,40.980985],[-81.15331,40.988221],[-81.24939,40.988359],[-81.244702,40.951904]]]},"properties":{"id":"44601","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.195411,40.250908],[-82.100799,40.210878],[-82.024791,40.231539],[-81.961331,40.435958],[-82.087322,40.424408],[-82.1138,40.415044],[-82.195411,40.250908]],[[-81.984617,40.396064],[-81.967864,40.40728],[-81.969332,40.394817],[-81.977087,40.387717],[-81.984617,40.396064]]]},"properties":{"id":"43844","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.697055,40.223788],[-81.787824,40.288888],[-81.849958,40.41132],[-81.960355,40.450511],[-81.961331,40.435958],[-82.024791,40.231539],[-81.999068,40.201468],[-81.919341,40.208009],[-81.875555,40.157153],[-81.869809,40.156964],[-81.810176,40.141726],[-81.776365,40.154168],[-81.695061,40.184089],[-81.697055,40.223788]],[[-81.723102,40.210686],[-81.714437,40.210515],[-81.713633,40.206815],[-81.725148,40.206887],[-81.723102,40.210686]]]},"properties":{"id":"43812","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.999068,40.201468],[-81.875555,40.157153],[-81.919341,40.208009],[-81.999068,40.201468]]]},"properties":{"id":"43811","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.185424,40.436794],[-82.1138,40.415044],[-82.087322,40.424408],[-82.106111,40.501173],[-82.181716,40.51136],[-82.185424,40.436794]]]},"properties":{"id":"43006","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.837396,40.83615],[-82.743598,40.901713],[-82.743298,40.962238],[-82.780155,40.995926],[-82.815772,40.996361],[-82.836148,40.884758],[-82.837396,40.83615]]]},"properties":{"id":"44887","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.940173,40.921169],[-82.836148,40.884758],[-82.815772,40.996361],[-82.901156,40.993659],[-82.940173,40.921169]]]},"properties":{"id":"44854","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.603556,41.463655],[-81.584604,41.45279],[-81.555502,41.457213],[-81.550362,41.480139],[-81.583024,41.490605],[-81.601617,41.494644],[-81.603556,41.463655]]]},"properties":{"id":"44120","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.658343,41.505423],[-81.656989,41.496473],[-81.628529,41.496351],[-81.628448,41.520406],[-81.626482,41.548673],[-81.652344,41.541588],[-81.658343,41.505423]]]},"properties":{"id":"44103","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.591256,41.510623],[-81.583024,41.490605],[-81.550362,41.480139],[-81.517021,41.498009],[-81.557862,41.526034],[-81.591256,41.510623]]]},"properties":{"id":"44118","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.463363,41.4559],[-81.493812,41.443593],[-81.488696,41.424268],[-81.391909,41.391263],[-81.297732,41.425093],[-81.297821,41.439674],[-81.376136,41.500412],[-81.390851,41.500748],[-81.438444,41.500842],[-81.463363,41.4559]]]},"properties":{"id":"44022","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.756995,41.421536],[-81.753963,41.350733],[-81.734229,41.352805],[-81.725301,41.421082],[-81.734353,41.421181],[-81.756995,41.421536]]]},"properties":{"id":"44129","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969465,41.478017],[-81.969963,41.418913],[-81.970263,41.411045],[-81.874667,41.448107],[-81.874492,41.449944],[-81.873653,41.476866],[-81.969465,41.478017]]]},"properties":{"id":"44145","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.614677,40.257538],[-84.586702,40.192445],[-84.523923,40.180755],[-84.441257,40.180776],[-84.432575,40.197037],[-84.43415,40.299396],[-84.434066,40.306613],[-84.535996,40.324938],[-84.614677,40.257538]]]},"properties":{"id":"45380","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.523923,40.180755],[-84.467822,40.064792],[-84.428777,40.050793],[-84.400617,40.051001],[-84.441257,40.180776],[-84.523923,40.180755]],[[-84.489974,40.114995],[-84.49391,40.109827],[-84.502197,40.112759],[-84.497556,40.119018],[-84.489974,40.114995]]]},"properties":{"id":"45308","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.571074,40.32917],[-84.570991,40.319609],[-84.576525,40.31961],[-84.576568,40.324449],[-84.737821,40.291319],[-84.709383,40.251267],[-84.614677,40.257538],[-84.535996,40.324938],[-84.552371,40.338993],[-84.571074,40.32917]]]},"properties":{"id":"45362","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.769606,39.968021],[-84.704293,39.916528],[-84.673567,39.916602],[-84.6244,39.927555],[-84.652958,39.97454],[-84.74109,40.049903],[-84.750264,40.049792],[-84.79662,40.043351],[-84.769606,39.968021]]]},"properties":{"id":"45346","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.638217,40.35178],[-84.647907,40.351811],[-84.766137,40.294451],[-84.737821,40.291319],[-84.576568,40.324449],[-84.571074,40.32917],[-84.552371,40.338993],[-84.515776,40.354037],[-84.58048,40.353371],[-84.632827,40.351747],[-84.638217,40.35178]]]},"properties":{"id":"45348","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.630716,41.356675],[-84.630522,41.252736],[-84.611228,41.25281],[-84.630716,41.356675]]]},"properties":{"id":"43536","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.458868,41.412878],[-84.592523,41.383499],[-84.515967,41.325536],[-84.458868,41.412878]]]},"properties":{"id":"43549","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.247264,41.194828],[-84.26667,41.180216],[-84.170513,41.166147],[-84.06739,41.181194],[-84.093542,41.19828],[-84.247264,41.194828]]]},"properties":{"id":"43548","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.46889,41.389447],[-82.457787,41.357469],[-82.424292,41.346346],[-82.344851,41.348945],[-82.282831,41.403744],[-82.283469,41.430789],[-82.46889,41.389447]]]},"properties":{"id":"44089","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.70189,41.591074],[-82.681198,41.611306],[-82.70731,41.619609],[-82.73472,41.603774],[-82.70189,41.591074]]]},"properties":{"id":"43438","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.456908,41.203275],[-82.496981,41.155329],[-82.458054,41.174756],[-82.456908,41.203275]]],[[[-82.534741,41.289416],[-82.461683,41.212526],[-82.442548,41.283874],[-82.534741,41.289416]]]]},"properties":{"id":"44826","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.767898,40.046273],[-82.771881,39.998341],[-82.730558,39.944303],[-82.68444,39.934666],[-82.629659,39.931964],[-82.607697,39.957794],[-82.5903,39.961343],[-82.572277,39.997338],[-82.613805,40.043622],[-82.666467,40.103667],[-82.765615,40.083674],[-82.767898,40.046273]]]},"properties":{"id":"43062","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.588141,39.596622],[-83.481922,39.68382],[-83.655686,39.693649],[-83.644561,39.614377],[-83.613621,39.607183],[-83.590001,39.596773],[-83.588141,39.596622]]]},"properties":{"id":"43128","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132368,40.063231],[-83.159907,40.055186],[-83.182333,40.060133],[-83.21028,40.069072],[-83.260877,40.002807],[-83.2494,39.980501],[-83.187283,39.979078],[-83.119646,40.010901],[-83.132368,40.063231]]]},"properties":{"id":"43026","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.862012,40.078854],[-82.822641,40.04241],[-82.767898,40.046273],[-82.765615,40.083674],[-82.78107,40.126764],[-82.862012,40.078854]]]},"properties":{"id":"43054","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.998542,39.939389],[-82.945377,39.933681],[-82.94769,39.948572],[-82.982374,39.95201],[-82.998542,39.939389]]]},"properties":{"id":"43206","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.981627,39.964395],[-82.982374,39.95201],[-82.94769,39.948572],[-82.950013,39.967996],[-82.951482,39.967835],[-82.981627,39.964395]]]},"properties":{"id":"43205","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.159907,40.055186],[-83.179297,40.07543],[-83.182333,40.060133],[-83.159907,40.055186]]]},"properties":{"id":"43002","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.944538,39.83062],[-82.938402,39.856233],[-83.017926,39.880377],[-83.032719,39.822353],[-83.025687,39.769929],[-82.937725,39.801007],[-82.944538,39.83062]]]},"properties":{"id":"43137","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.937725,39.801007],[-82.921101,39.800159],[-82.944538,39.83062],[-82.937725,39.801007]]]},"properties":{"id":"43217","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.368968,38.676859],[-82.316993,38.536698],[-82.303238,38.521162],[-82.1844,38.657629],[-82.275053,38.690051],[-82.368968,38.676859]]]},"properties":{"id":"45623","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.195866,38.945132],[-82.135012,38.905456],[-82.085996,38.977412],[-82.14868,39.005159],[-82.195866,38.945132]]]},"properties":{"id":"45620","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.391761,41.35729],[-81.28074,41.34743],[-81.196391,41.347555],[-81.190863,41.383957],[-81.23105,41.425434],[-81.297732,41.425093],[-81.391909,41.391263],[-81.391761,41.35729]]]},"properties":{"id":"44023","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.297821,41.439674],[-81.297732,41.425093],[-81.23105,41.425434],[-81.189931,41.495615],[-81.243739,41.499964],[-81.287289,41.49961],[-81.297821,41.439674]]]},"properties":{"id":"44065","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.457089,40.034692],[-81.43425,40.03509],[-81.435012,40.04093],[-81.44736,40.04125],[-81.457089,40.034692]]]},"properties":{"id":"43768","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.457089,40.034692],[-81.495483,39.990211],[-81.442067,39.960753],[-81.37757,40.09099],[-81.38399,40.12955],[-81.447325,40.128626],[-81.44736,40.04125],[-81.435012,40.04093],[-81.43425,40.03509],[-81.457089,40.034692]]]},"properties":{"id":"43755","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52709,39.91705],[-81.54724,39.92445],[-81.580856,39.93195],[-81.611414,39.935291],[-81.543837,39.838143],[-81.490435,39.82114],[-81.434829,39.883879],[-81.515024,39.914643],[-81.52709,39.91705]]]},"properties":{"id":"43772","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.181903,40.311463],[-81.181181,40.334659],[-81.200295,40.346394],[-81.36909,40.28065],[-81.389575,40.215508],[-81.180161,40.285291],[-81.181903,40.311463]]]},"properties":{"id":"44699","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.365994,39.282012],[-84.396207,39.237946],[-84.357731,39.213903],[-84.326196,39.223629],[-84.299161,39.240815],[-84.365994,39.282012]]]},"properties":{"id":"45242","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.628117,39.235077],[-84.667653,39.26298],[-84.696834,39.222786],[-84.703067,39.216276],[-84.67628,39.210745],[-84.628816,39.178035],[-84.601948,39.188104],[-84.599836,39.227116],[-84.628117,39.235077]]]},"properties":{"id":"45247","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.571327,39.308048],[-84.572165,39.223876],[-84.549475,39.217817],[-84.502845,39.216788],[-84.48811,39.260316],[-84.518749,39.258291],[-84.537477,39.265261],[-84.562347,39.308079],[-84.571327,39.308048]]]},"properties":{"id":"45231","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.819936,39.17472],[-84.71216,39.130388],[-84.711561,39.130748],[-84.702733,39.143201],[-84.705289,39.168224],[-84.787607,39.1668],[-84.819936,39.17472]]]},"properties":{"id":"45052","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.711561,39.130748],[-84.702355,39.143019],[-84.702733,39.143201],[-84.711561,39.130748]]]},"properties":{"id":"45001","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.299596,39.170445],[-84.324039,39.152661],[-84.322032,39.147023],[-84.301828,39.152566],[-84.299596,39.170445]]]},"properties":{"id":"45174","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.365994,39.282012],[-84.299161,39.240815],[-84.305683,39.301391],[-84.353209,39.292287],[-84.365994,39.282012]]]},"properties":{"id":"45249","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.477544,41.026604],[-83.453899,40.991688],[-83.374585,41.028777],[-83.362705,41.088581],[-83.477354,41.07079],[-83.477544,41.026604]]]},"properties":{"id":"44802","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630429,40.532894],[-83.579834,40.467208],[-83.520227,40.504077],[-83.561108,40.561428],[-83.630429,40.532894]]]},"properties":{"id":"43345","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.699394,40.790733],[-83.643184,40.746477],[-83.601916,40.745731],[-83.651426,40.819487],[-83.670526,40.819542],[-83.737429,40.819301],[-83.737886,40.819298],[-83.699394,40.790733]]]},"properties":{"id":"45836","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.806334,40.637647],[-83.677942,40.533501],[-83.630429,40.532894],[-83.561108,40.561428],[-83.439247,40.608971],[-83.419836,40.686823],[-83.601916,40.745731],[-83.643184,40.746477],[-83.729632,40.732618],[-83.730398,40.70352],[-83.806334,40.637647]]]},"properties":{"id":"43326","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.699394,40.790733],[-83.729632,40.732618],[-83.643184,40.746477],[-83.699394,40.790733]]]},"properties":{"id":"45835","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.313374,39.474398],[-82.320416,39.486134],[-82.333582,39.482948],[-82.330406,39.474203],[-82.313374,39.474398]]]},"properties":{"id":"43127","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.224246,39.559948],[-82.16114,39.534844],[-82.150528,39.581339],[-82.20161,39.587428],[-82.256083,39.655723],[-82.254477,39.676227],[-82.33757,39.652819],[-82.224246,39.559948]]]},"properties":{"id":"43766","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.807173,40.46853],[-80.753483,40.379011],[-80.729061,40.395695],[-80.712331,40.430106],[-80.747788,40.467078],[-80.806369,40.477697],[-80.807173,40.46853]]]},"properties":{"id":"43944","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.864543,40.450961],[-80.861195,40.448501],[-80.85794,40.450145],[-80.864543,40.450961]]]},"properties":{"id":"43925","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.359847,40.270168],[-82.28949,40.283627],[-82.286777,40.289671],[-82.359847,40.270168]]]},"properties":{"id":"43037","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.291226,41.735943],[-81.269532,41.641006],[-81.112673,41.658171],[-81.127798,41.707052],[-81.137141,41.727902],[-81.191078,41.784403],[-81.280326,41.760344],[-81.291226,41.735943]]]},"properties":{"id":"44077","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.868729,40.4623],[-83.879847,40.483768],[-83.907022,40.451295],[-83.868729,40.4623]]]},"properties":{"id":"43348","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.5853,40.28842],[-83.579479,40.28858],[-83.581617,40.292118],[-83.5853,40.28842]]]},"properties":{"id":"43336","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.098724,41.199823],[-82.081871,41.186446],[-81.974144,41.199833],[-81.974235,41.208924],[-81.980695,41.259884],[-81.990488,41.351373],[-82.020534,41.351675],[-82.105836,41.300298],[-82.098724,41.199823]]]},"properties":{"id":"44044","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.158878,41.418163],[-82.097326,41.421029],[-82.108118,41.447147],[-82.158878,41.418163]]]},"properties":{"id":"44055","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.067869,41.430027],[-82.067767,41.418342],[-81.969963,41.418913],[-81.969465,41.478017],[-82.066972,41.478655],[-82.067869,41.430027]]]},"properties":{"id":"44011","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.56128,41.657522],[-83.641148,41.661359],[-83.625926,41.631009],[-83.563687,41.638223],[-83.56128,41.657522]]]},"properties":{"id":"43607","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.644451,41.688094],[-83.641148,41.661359],[-83.56128,41.657522],[-83.57238,41.669661],[-83.573941,41.678029],[-83.584347,41.677856],[-83.623033,41.686804],[-83.644451,41.688094]]]},"properties":{"id":"43606","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.805523,41.55234],[-83.865728,41.495735],[-83.86518,41.495216],[-83.830942,41.466352],[-83.752637,41.529452],[-83.805523,41.55234]]]},"properties":{"id":"43571","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.563687,41.638223],[-83.625926,41.631009],[-83.630268,41.619501],[-83.588034,41.60095],[-83.569934,41.61715],[-83.53193,41.632551],[-83.563687,41.638223]]]},"properties":{"id":"43609","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.722993,41.646533],[-83.695449,41.683059],[-83.760523,41.65863],[-83.722993,41.646533]]]},"properties":{"id":"43617","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.73237,41.068796],[-80.711139,41.046061],[-80.663947,41.060939],[-80.663983,41.087634],[-80.674928,41.088365],[-80.711029,41.089158],[-80.73237,41.068796]]]},"properties":{"id":"44511","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.993508,40.987768],[-80.935087,40.974023],[-80.857303,40.987943],[-80.86792,41.012282],[-80.91806,41.061457],[-80.959859,41.085244],[-81.00236,41.062093],[-81.001797,41.00786],[-80.993508,40.987768]]]},"properties":{"id":"44401","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.56692,41.133324],[-80.519032,41.120872],[-80.519064,41.202984],[-80.554671,41.217976],[-80.616891,41.213582],[-80.649703,41.171377],[-80.56692,41.133324]]]},"properties":{"id":"44425","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.683889,41.139664],[-81.614755,41.112242],[-81.609786,41.113936],[-81.58012,41.176656],[-81.590442,41.202655],[-81.685744,41.200904],[-81.683889,41.139664]]]},"properties":{"id":"44333","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.059808,40.098468],[-84.079582,40.186927],[-84.108093,40.188819],[-84.144234,40.103236],[-84.125668,40.093494],[-84.059808,40.098468]]]},"properties":{"id":"45326","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.368516,39.890388],[-84.296658,39.833014],[-84.292016,39.833079],[-84.268898,39.861393],[-84.285056,39.869889],[-84.286062,39.921554],[-84.388108,39.920227],[-84.368516,39.890388]]]},"properties":{"id":"45322","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.120317,39.64299],[-81.215887,39.578074],[-81.225689,39.578375],[-81.24878,39.510985],[-81.272019,39.484126],[-81.250207,39.464396],[-81.151257,39.443022],[-80.988214,39.576698],[-81.068254,39.631445],[-81.120317,39.64299]]]},"properties":{"id":"45767","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.225342,39.629775],[-81.215887,39.578074],[-81.120317,39.64299],[-81.223211,39.709381],[-81.225342,39.629775]]]},"properties":{"id":"45734","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.94886,39.766917],[-80.944656,39.766517],[-80.939479,39.774116],[-80.94886,39.766917]]]},"properties":{"id":"43914","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.327249,39.599291],[-84.251253,39.578498],[-84.210539,39.588655],[-84.218973,39.631134],[-84.225163,39.637842],[-84.267357,39.681219],[-84.276432,39.695403],[-84.316143,39.687136],[-84.327249,39.599291]]]},"properties":{"id":"45342","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.225163,39.637842],[-84.216507,39.672629],[-84.252996,39.689661],[-84.267357,39.681219],[-84.225163,39.637842]]]},"properties":{"id":"45449","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.177135,39.757938],[-84.183992,39.750248],[-84.168795,39.739972],[-84.129623,39.739581],[-84.129031,39.748005],[-84.177135,39.757938]]]},"properties":{"id":"45410","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.573945,39.831953],[-81.697442,39.755572],[-81.622432,39.658057],[-81.587358,39.625199],[-81.406437,39.668751],[-81.319125,39.70736],[-81.384677,39.755838],[-81.490435,39.82114],[-81.543837,39.838143],[-81.57516,39.839074],[-81.573945,39.831953]],[[-81.562212,39.794159],[-81.554437,39.794318],[-81.552921,39.785556],[-81.562212,39.794159]]]},"properties":{"id":"43724","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.958063,40.557934],[-82.85894,40.523475],[-82.860145,40.630693],[-82.906716,40.645965],[-82.958063,40.557934]]]},"properties":{"id":"43320","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.822056,40.460289],[-82.835656,40.465936],[-82.838228,40.452232],[-82.822056,40.460289]]]},"properties":{"id":"43321","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.119758,39.84511],[-81.990818,39.837078],[-81.927361,39.872869],[-81.900439,39.896101],[-81.841947,39.936026],[-81.841133,40.029803],[-81.916564,40.07809],[-82.020243,40.052054],[-82.13967,40.018932],[-82.168487,39.922358],[-82.116207,39.876855],[-82.119758,39.84511]]]},"properties":{"id":"43701","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.141397,39.857194],[-82.139261,39.853495],[-82.135195,39.855678],[-82.141397,39.857194]]]},"properties":{"id":"43738","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.823914,41.685382],[-82.806002,41.671486],[-82.8042,41.689441],[-82.823914,41.685382]]]},"properties":{"id":"43446","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.299449,41.450531],[-83.321818,41.492744],[-83.414626,41.500228],[-83.415019,41.471717],[-83.415202,41.44942],[-83.415601,41.421448],[-83.299449,41.450531]]]},"properties":{"id":"43469","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.038826,41.519272],[-83.043536,41.519283],[-83.043639,41.51583],[-83.038805,41.516365],[-83.038826,41.519272]]]},"properties":{"id":"43439","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.870725,41.507024],[-82.889483,41.508521],[-82.888428,41.500944],[-82.870823,41.500801],[-82.870725,41.507024]]]},"properties":{"id":"43433","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.476106,41.194614],[-84.631349,41.208547],[-84.688271,41.133428],[-84.630878,41.062496],[-84.495928,41.062781],[-84.438181,41.070341],[-84.419452,41.095743],[-84.432099,41.165414],[-84.476106,41.194614]],[[-84.578131,41.087695],[-84.588147,41.085766],[-84.588275,41.091567],[-84.578251,41.091631],[-84.578131,41.087695]]]},"properties":{"id":"45879","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.252157,39.872617],[-82.334547,39.859067],[-82.385514,39.807235],[-82.346652,39.741201],[-82.271974,39.744966],[-82.166605,39.807214],[-82.188746,39.821671],[-82.252157,39.872617]]]},"properties":{"id":"43783","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.050522,39.043478],[-83.050299,39.047089],[-83.054488,39.048013],[-83.054073,39.044452],[-83.050522,39.043478]]]},"properties":{"id":"45642","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.489436,41.202963],[-81.391931,41.202855],[-81.382178,41.276552],[-81.391759,41.284053],[-81.463397,41.286695],[-81.489435,41.277053],[-81.537502,41.276146],[-81.489436,41.202963]]]},"properties":{"id":"44236","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.099016,41.062462],[-81.058451,41.003627],[-81.001797,41.00786],[-81.00236,41.062093],[-81.002307,41.063468],[-81.071633,41.062325],[-81.099016,41.062462]]]},"properties":{"id":"44411","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.493571,41.162215],[-81.449934,41.148056],[-81.414298,41.13556],[-81.392114,41.135722],[-81.391931,41.202855],[-81.489436,41.202963],[-81.507126,41.180983],[-81.493571,41.162215]]]},"properties":{"id":"44224","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.493659,39.901931],[-84.485472,39.908958],[-84.491317,39.906509],[-84.493659,39.901931]]]},"properties":{"id":"45378","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.475444,40.742168],[-82.508199,40.77538],[-82.498917,40.755003],[-82.475444,40.742168]]]},"properties":{"id":"44905","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.856037,38.851795],[-82.870078,38.729183],[-82.872527,38.680652],[-82.758286,38.679734],[-82.667951,38.74829],[-82.798317,38.825417],[-82.856037,38.851795]]]},"properties":{"id":"45694","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.086285,38.697381],[-83.087521,38.70059],[-83.093625,38.700933],[-83.090018,38.697162],[-83.086285,38.697381]]]},"properties":{"id":"45630","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.240313,41.242602],[-83.224998,41.252975],[-83.254702,41.253136],[-83.240313,41.242602]]]},"properties":{"id":"44815","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.097816,40.32732],[-84.086402,40.32946],[-84.094083,40.335918],[-84.097816,40.32732]]]},"properties":{"id":"45360","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.369161,40.798097],[-81.320201,40.789154],[-81.323673,40.812063],[-81.362791,40.809403],[-81.370542,40.809113],[-81.369161,40.798097]]]},"properties":{"id":"44704","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.372722,40.809163],[-81.370542,40.809113],[-81.362791,40.809403],[-81.348441,40.85973],[-81.364618,40.860113],[-81.368334,40.818276],[-81.372722,40.809163]]]},"properties":{"id":"44714","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.48244,40.846723],[-81.479696,40.878053],[-81.506078,40.902375],[-81.533569,40.897112],[-81.532486,40.880798],[-81.534081,40.758259],[-81.476958,40.762277],[-81.446411,40.78558],[-81.448536,40.792245],[-81.48244,40.846723]]]},"properties":{"id":"44646","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.647872,40.918859],[-81.647822,40.894992],[-81.579313,40.857726],[-81.532486,40.880798],[-81.533569,40.897112],[-81.647872,40.918859]]]},"properties":{"id":"44614","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.454517,40.67894],[-81.372995,40.633895],[-81.37285,40.63615],[-81.357273,40.652358],[-81.305287,40.703761],[-81.313933,40.726059],[-81.365256,40.727631],[-81.430954,40.715057],[-81.454517,40.67894]]]},"properties":{"id":"44626","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.495514,40.938611],[-81.368917,40.918195],[-81.367954,40.982176],[-81.395657,40.999062],[-81.495578,40.97484],[-81.517303,40.945259],[-81.515487,40.943927],[-81.495514,40.938611]]]},"properties":{"id":"44685","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.700842,40.698407],[-81.611104,40.757729],[-81.630137,40.775858],[-81.6767,40.846181],[-81.749499,40.751441],[-81.707201,40.696103],[-81.700842,40.698407]]]},"properties":{"id":"44618","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.465034,41.118851],[-81.497108,41.123252],[-81.518938,41.123855],[-81.527458,41.103764],[-81.524794,41.096957],[-81.490814,41.087588],[-81.465535,41.094756],[-81.465034,41.118851]]]},"properties":{"id":"44310","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.489436,41.202963],[-81.537502,41.276146],[-81.566284,41.28776],[-81.590708,41.251372],[-81.590442,41.202655],[-81.58012,41.176656],[-81.568329,41.200819],[-81.507126,41.180983],[-81.489436,41.202963]]]},"properties":{"id":"44264","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.568017,41.060703],[-81.541765,41.06192],[-81.525021,41.076361],[-81.52681,41.078399],[-81.539008,41.084329],[-81.568017,41.060703]]]},"properties":{"id":"44307","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.568017,41.060703],[-81.58179,41.039642],[-81.555835,41.022298],[-81.536939,41.032855],[-81.541982,41.061559],[-81.541765,41.06192],[-81.568017,41.060703]]]},"properties":{"id":"44314","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.637184,41.35019],[-80.645436,41.287619],[-80.611213,41.267151],[-80.568367,41.272321],[-80.598185,41.350382],[-80.624053,41.356232],[-80.637184,41.35019]]]},"properties":{"id":"44418","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519064,41.202984],[-80.519088,41.289912],[-80.56032,41.272639],[-80.554671,41.217976],[-80.519064,41.202984]]]},"properties":{"id":"44438","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.954661,41.458333],[-80.954241,41.464022],[-80.959624,41.456207],[-80.954975,41.448009],[-80.954661,41.458333]]]},"properties":{"id":"44439","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.412048,40.392757],[-81.49257,40.37359],[-81.408489,40.311207],[-81.390577,40.397441],[-81.412048,40.392757]]]},"properties":{"id":"44629","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.537606,40.647102],[-81.562145,40.633991],[-81.560318,40.558914],[-81.502373,40.602922],[-81.537606,40.647102]]]},"properties":{"id":"44680","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.707647,40.822383],[-84.550111,40.771245],[-84.435235,40.815773],[-84.456497,40.851992],[-84.476265,40.960978],[-84.497785,40.9779],[-84.630251,40.975073],[-84.74602,40.822337],[-84.726874,40.822361],[-84.707647,40.822383]]]},"properties":{"id":"45891","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.74602,40.822337],[-84.802551,40.873326],[-84.802271,40.789574],[-84.775279,40.796621],[-84.780211,40.804435],[-84.769058,40.804416],[-84.769139,40.797641],[-84.726874,40.822361],[-84.74602,40.822337]]],[[[-84.550111,40.771245],[-84.707647,40.822383],[-84.689163,40.727705],[-84.551879,40.728066],[-84.550111,40.771245]]]]},"properties":{"id":"45874","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.185048,39.397786],[-84.205318,39.351039],[-84.188909,39.311976],[-84.158661,39.283361],[-84.145285,39.2814],[-84.086389,39.305899],[-84.027152,39.337059],[-84.073628,39.401565],[-84.115027,39.39861],[-84.185048,39.397786]]]},"properties":{"id":"45152","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.300156,39.306352],[-84.188909,39.311976],[-84.205318,39.351039],[-84.224889,39.368465],[-84.239438,39.365396],[-84.258548,39.351992],[-84.300156,39.306352]]]},"properties":{"id":"45039","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.258548,39.351992],[-84.249631,39.37097],[-84.341405,39.412423],[-84.346331,39.368446],[-84.351007,39.317509],[-84.353209,39.292287],[-84.305683,39.301391],[-84.300156,39.306352],[-84.258548,39.351992]]]},"properties":{"id":"45040","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.631521,39.466961],[-81.630737,39.469496],[-81.632376,39.469816],[-81.633198,39.467092],[-81.631521,39.466961]]]},"properties":{"id":"45787","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.897904,40.894415],[-81.827011,40.838079],[-81.82281,40.885793],[-81.878526,40.916275],[-81.897904,40.894415]]]},"properties":{"id":"44677","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.588034,41.60095],[-83.55443,41.594135],[-83.543033,41.61405],[-83.569934,41.61715],[-83.588034,41.60095]]]},"properties":{"id":"43460","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.543361,41.348582],[-83.708556,41.31274],[-83.645375,41.268504],[-83.592838,41.282259],[-83.534087,41.32612],[-83.543361,41.348582]]]},"properties":{"id":"43451","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.515167,41.456915],[-83.415202,41.44942],[-83.415019,41.471717],[-83.531888,41.477738],[-83.515167,41.456915]]]},"properties":{"id":"43443","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.27245,40.956622],[-83.380732,40.875759],[-83.457565,40.84315],[-83.394173,40.696622],[-83.362371,40.717937],[-83.161156,40.789306],[-83.170083,40.891538],[-83.268738,40.956411],[-83.27245,40.956622]],[[-83.417425,40.811339],[-83.420965,40.809185],[-83.42119,40.817676],[-83.418681,40.816004],[-83.417425,40.811339]]]},"properties":{"id":"43351","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.37581,38.730188],[-83.20906,38.697504],[-83.248019,38.746646],[-83.352734,38.856322],[-83.427459,38.862372],[-83.375237,38.760992],[-83.37581,38.730188]]]},"properties":{"id":"45616","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.375237,38.760992],[-83.455522,38.725044],[-83.443882,38.690782],[-83.37581,38.730188],[-83.375237,38.760992]]]},"properties":{"id":"45650","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.203574,38.959959],[-83.261125,38.910242],[-83.352734,38.856322],[-83.248019,38.746646],[-83.129657,38.793051],[-83.118309,38.869696],[-83.203574,38.959959]]]},"properties":{"id":"45657","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.976065,40.914953],[-83.975823,40.836472],[-83.963122,40.833156],[-83.958691,40.833261],[-83.937461,40.819093],[-83.880063,40.819919],[-83.804701,40.841543],[-83.785599,40.877657],[-83.804143,40.936653],[-83.870346,40.951232],[-83.976065,40.914953]]]},"properties":{"id":"45817","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.963122,40.833156],[-83.975823,40.836472],[-83.988423,40.824992],[-83.963122,40.833156]]]},"properties":{"id":"45808","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.241599,40.744686],[-84.272939,40.747219],[-84.256044,40.72928],[-84.241599,40.744686]]],[[[-84.408077,40.917659],[-84.456497,40.851992],[-84.435235,40.815773],[-84.397115,40.765045],[-84.291618,40.786822],[-84.272343,40.837797],[-84.408077,40.917659]]]]},"properties":{"id":"45833","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.879834,40.732443],[-83.730398,40.70352],[-83.729632,40.732618],[-83.699394,40.790733],[-83.737886,40.819298],[-83.804701,40.841543],[-83.880063,40.819919],[-83.879834,40.732443]]]},"properties":{"id":"45810","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.429384,40.883153],[-82.362674,40.74153],[-82.366038,40.732198],[-82.261564,40.768278],[-82.259678,40.768712],[-82.262101,40.777886],[-82.250834,40.773509],[-82.174462,40.857114],[-82.224833,40.974105],[-82.222964,40.992308],[-82.280766,40.998919],[-82.360081,40.969717],[-82.369409,40.961498],[-82.407647,40.967725],[-82.447994,40.931541],[-82.455454,40.889858],[-82.429384,40.883153]]]},"properties":{"id":"44805","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.220789,41.084773],[-82.2797,41.065343],[-82.280766,40.998919],[-82.222964,40.992308],[-82.173359,40.992082],[-82.171355,41.069855],[-82.200942,41.090188],[-82.220789,41.084773]]]},"properties":{"id":"44880","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.125495,40.85817],[-82.126537,40.749318],[-81.93318,40.696615],[-81.864114,40.726159],[-81.827874,40.798161],[-81.827011,40.838079],[-81.897904,40.894415],[-81.956312,40.929846],[-82.029665,40.867904],[-82.125495,40.85817]]]},"properties":{"id":"44691","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.360081,40.969717],[-82.369814,40.973735],[-82.369409,40.961498],[-82.360081,40.969717]]]},"properties":{"id":"44874","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.998766,41.673412],[-81.002781,41.642],[-80.809743,41.654628],[-80.852759,41.714166],[-80.88403,41.730799],[-80.998766,41.673412]]]},"properties":{"id":"44084","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.140784,39.38885],[-82.111476,39.363489],[-82.136415,39.351631],[-82.141552,39.387349],[-82.212268,39.370038],[-82.211877,39.280206],[-82.075486,39.193641],[-82.010475,39.221892],[-81.949652,39.332396],[-81.947149,39.366452],[-82.016025,39.405669],[-82.131073,39.393558],[-82.130062,39.405031],[-82.170671,39.431533],[-82.140784,39.38885]]]},"properties":{"id":"45701","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.474246,40.436884],[-84.455276,40.422079],[-84.317321,40.423267],[-84.297981,40.438453],[-84.298072,40.453269],[-84.358359,40.48805],[-84.455165,40.494842],[-84.474246,40.436884]]]},"properties":{"id":"45869","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.230133,40.055227],[-81.237316,39.867828],[-81.211468,39.867281],[-81.086467,39.932246],[-81.123964,40.081822],[-81.147843,40.101533],[-81.228674,40.097158],[-81.2301,40.056151],[-81.230133,40.055227]]]},"properties":{"id":"43713","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.013216,39.909134],[-80.919942,39.871607],[-80.990764,39.93854],[-81.013216,39.909134]]]},"properties":{"id":"43902","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.842174,40.149892],[-80.837385,40.298216],[-80.887566,40.183436],[-80.88313,40.183358],[-80.883181,40.180674],[-80.882892,40.159495],[-80.842174,40.149892]]]},"properties":{"id":"43901","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.837385,40.298216],[-80.842174,40.149892],[-80.843917,40.1414],[-80.80892,40.12601],[-80.787774,40.155954],[-80.792203,40.156208],[-80.807695,40.16173],[-80.794164,40.178205],[-80.768845,40.264271],[-80.786043,40.263094],[-80.783542,40.276982],[-80.778775,40.297115],[-80.852957,40.307438],[-80.837385,40.298216]]]},"properties":{"id":"43917","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.756072,39.030402],[-83.745796,39.036351],[-83.75618,39.033303],[-83.756072,39.030402]]],[[[-83.842935,39.105367],[-83.886599,39.002031],[-83.910987,38.965505],[-83.758846,38.908012],[-83.706132,39.013394],[-83.756072,39.030402],[-83.757769,39.033147],[-83.756798,39.046288],[-83.784997,39.114992],[-83.842935,39.105367]]]]},"properties":{"id":"45171","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.696084,38.834509],[-83.691393,38.812926],[-83.689991,38.827903],[-83.696084,38.834509]]]},"properties":{"id":"45115","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.959033,38.787091],[-83.9728,38.793535],[-83.975132,38.787377],[-83.959033,38.787091]]]},"properties":{"id":"45131","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.494766,39.60231],[-84.702718,39.567578],[-84.671994,39.523186],[-84.579673,39.529547],[-84.543593,39.53087],[-84.550366,39.538112],[-84.554489,39.582305],[-84.56369,39.586967],[-84.564025,39.595375],[-84.554725,39.595237],[-84.551086,39.588119],[-84.494766,39.60231]]]},"properties":{"id":"45064","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.562347,39.308079],[-84.50273,39.305502],[-84.476315,39.316712],[-84.49666,39.343418],[-84.543867,39.366634],[-84.545475,39.366662],[-84.592246,39.350856],[-84.644442,39.305039],[-84.641814,39.305044],[-84.630772,39.311981],[-84.571327,39.308048],[-84.562347,39.308079]]]},"properties":{"id":"45014","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.550366,39.538112],[-84.439122,39.488668],[-84.335426,39.511353],[-84.365124,39.583999],[-84.479416,39.604091],[-84.494766,39.60231],[-84.551086,39.588119],[-84.554489,39.582305],[-84.550366,39.538112]]]},"properties":{"id":"45042","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.543593,39.53087],[-84.436828,39.443843],[-84.439122,39.488668],[-84.550366,39.538112],[-84.543593,39.53087]]]},"properties":{"id":"45067","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.320201,40.789154],[-81.310087,40.733391],[-81.237845,40.712905],[-81.198803,40.725715],[-81.196227,40.759959],[-81.195986,40.762387],[-81.204016,40.79901],[-81.314232,40.817158],[-81.323673,40.812063],[-81.320201,40.789154]]]},"properties":{"id":"44730","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.305287,40.703761],[-81.357273,40.652358],[-81.369491,40.635944],[-81.274003,40.582626],[-81.244445,40.626787],[-81.305287,40.703761]]]},"properties":{"id":"44643","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.974598,40.813783],[-81.01309,40.813754],[-81.043296,40.702904],[-81.016943,40.685859],[-80.999308,40.696094],[-80.955618,40.777828],[-80.955566,40.79403],[-80.974598,40.813783]]]},"properties":{"id":"44625","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.967612,40.241429],[-84.008419,40.228531],[-84.079582,40.186927],[-84.059808,40.098468],[-84.029857,40.111431],[-83.949728,40.196367],[-83.955598,40.207523],[-83.961437,40.219013],[-83.967612,40.241429]]]},"properties":{"id":"45317","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.955598,40.207523],[-83.955528,40.21975],[-83.961437,40.219013],[-83.955598,40.207523]]]},"properties":{"id":"43070","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.051656,39.876534],[-83.995342,39.875372],[-83.954368,39.888473],[-83.934394,39.901871],[-83.932536,39.91931],[-83.940426,39.919792],[-83.951589,39.920993],[-83.944973,39.92726],[-83.916596,39.947411],[-83.949845,39.982633],[-83.939563,40.034945],[-84.026132,40.047484],[-84.049679,40.025446],[-84.080432,39.9692],[-84.094495,39.89178],[-84.051656,39.876534]]]},"properties":{"id":"45344","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.940426,39.919792],[-83.944973,39.92726],[-83.951589,39.920993],[-83.940426,39.919792]]]},"properties":{"id":"45319","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.81101,39.795876],[-83.766656,39.791141],[-83.781262,39.811905],[-83.81101,39.795876]]],[[[-83.939806,39.843449],[-83.961659,39.785783],[-83.865964,39.7671],[-83.82649,39.794517],[-83.829105,39.800535],[-83.888515,39.826074],[-83.939806,39.843449]]]]},"properties":{"id":"45387","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.626714,39.998434],[-83.619764,39.996301],[-83.617953,40.004638],[-83.625172,40.003774],[-83.626714,39.998434]]]},"properties":{"id":"43010","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.247652,39.865133],[-83.35691,40.008301],[-83.408259,40.056031],[-83.542995,39.994041],[-83.54876,39.898817],[-83.576303,39.80873],[-83.481619,39.733478],[-83.472038,39.733214],[-83.413422,39.719142],[-83.243839,39.809327],[-83.21006,39.827364],[-83.247652,39.865133]]]},"properties":{"id":"43140","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.216057,38.821836],[-84.227317,38.821343],[-84.211048,38.804918],[-84.216057,38.821836]]]},"properties":{"id":"45156","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.322032,39.147023],[-84.374759,39.138968],[-84.401598,39.109215],[-84.352897,39.091999],[-84.30037,39.085794],[-84.260544,39.131027],[-84.301828,39.152566],[-84.322032,39.147023]]]},"properties":{"id":"45244","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.664609,40.698322],[-80.62048,40.735634],[-80.641402,40.79637],[-80.64447,40.813041],[-80.708017,40.813218],[-80.823238,40.825068],[-80.872612,40.813394],[-80.828661,40.702263],[-80.737602,40.668897],[-80.664609,40.698322]]]},"properties":{"id":"44432","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.268991,40.285403],[-82.195411,40.250908],[-82.1138,40.415044],[-82.185424,40.436794],[-82.188629,40.37538],[-82.260631,40.305375],[-82.261941,40.2922],[-82.268991,40.285403]]]},"properties":{"id":"43843","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.087322,40.424408],[-81.961331,40.435958],[-81.960355,40.450511],[-82.058699,40.573278],[-82.069932,40.564463],[-82.106111,40.501173],[-82.087322,40.424408]]]},"properties":{"id":"44637","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.548878,41.574782],[-81.52673,41.586339],[-81.526729,41.587272],[-81.54608,41.608007],[-81.568219,41.590101],[-81.548878,41.574782]]]},"properties":{"id":"44119","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.591051,41.545116],[-81.598906,41.523634],[-81.591256,41.510623],[-81.557862,41.526034],[-81.547793,41.558553],[-81.553655,41.561669],[-81.591051,41.545116]]]},"properties":{"id":"44112","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.759417,41.448437],[-81.716973,41.449969],[-81.716494,41.469664],[-81.711092,41.502635],[-81.714806,41.507297],[-81.769032,41.497326],[-81.768814,41.475553],[-81.759417,41.448437]]]},"properties":{"id":"44102","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.584604,41.45279],[-81.581759,41.435048],[-81.582384,41.424226],[-81.537317,41.424286],[-81.488696,41.424268],[-81.493812,41.443593],[-81.555502,41.457213],[-81.584604,41.45279]]]},"properties":{"id":"44128","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.557862,41.526034],[-81.517021,41.498009],[-81.511358,41.519484],[-81.510244,41.535129],[-81.527599,41.545331],[-81.547793,41.558553],[-81.557862,41.526034]]]},"properties":{"id":"44121","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.85595,41.419033],[-81.780984,41.41845],[-81.769644,41.422017],[-81.763945,41.444977],[-81.827336,41.45023],[-81.85595,41.419033]]]},"properties":{"id":"44135","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.550362,41.480139],[-81.555502,41.457213],[-81.493812,41.443593],[-81.463363,41.4559],[-81.511358,41.519484],[-81.517021,41.498009],[-81.550362,41.480139]]]},"properties":{"id":"44122","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.831958,41.463248],[-81.843982,41.494593],[-81.8738,41.489473],[-81.873653,41.476866],[-81.874492,41.449944],[-81.831958,41.463248]]]},"properties":{"id":"44116","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.709383,40.251267],[-84.708585,40.182166],[-84.586702,40.192445],[-84.614677,40.257538],[-84.709383,40.251267]]]},"properties":{"id":"45303","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.432575,40.197037],[-84.441257,40.180776],[-84.400617,40.051001],[-84.297642,40.077859],[-84.239864,40.096546],[-84.327362,40.197986],[-84.355949,40.197593],[-84.432575,40.197037]]]},"properties":{"id":"45318","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.515967,41.325536],[-84.592523,41.383499],[-84.630769,41.361359],[-84.630716,41.356675],[-84.611228,41.25281],[-84.524802,41.25281],[-84.515967,41.325536]]]},"properties":{"id":"43556","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.249593,40.362767],[-83.189067,40.20453],[-83.186004,40.372829],[-83.249558,40.376507],[-83.249593,40.362767]]]},"properties":{"id":"43061","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.651863,40.403881],[-82.699562,40.422781],[-82.822056,40.460289],[-82.838228,40.452232],[-82.909275,40.422048],[-82.920143,40.357669],[-82.79365,40.339824],[-82.67134,40.384242],[-82.651863,40.403881]]]},"properties":{"id":"43334","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.080718,40.400348],[-83.092105,40.436376],[-83.221477,40.423855],[-83.249558,40.376507],[-83.186004,40.372829],[-83.080718,40.400348]]]},"properties":{"id":"43066","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.614126,41.358625],[-82.610538,41.437007],[-82.952224,41.458239],[-82.847634,41.428756],[-82.766085,41.330907],[-82.747149,41.324381],[-82.66833,41.354949],[-82.614126,41.358625]]]},"properties":{"id":"44870","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.633757,39.811566],[-82.751789,39.764005],[-82.767533,39.732709],[-82.676421,39.56625],[-82.618327,39.59571],[-82.478126,39.664393],[-82.43598,39.746438],[-82.479271,39.773706],[-82.577316,39.797734],[-82.633757,39.811566]]]},"properties":{"id":"43130","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.824062,39.797065],[-82.808828,39.797159],[-82.811219,39.806017],[-82.824062,39.797065]]]},"properties":{"id":"43136","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.835281,39.676169],[-83.069443,39.726483],[-83.108448,39.690229],[-83.037259,39.507815],[-82.928459,39.477226],[-82.780385,39.528243],[-82.780321,39.551934],[-82.781039,39.557565],[-82.831317,39.666322],[-82.835281,39.676169]]]},"properties":{"id":"43113","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.35609,39.623605],[-83.257771,39.627109],[-83.35557,39.624145],[-83.35609,39.623605]]],[[[-83.481922,39.68382],[-83.588141,39.596622],[-83.585388,39.596455],[-83.585706,39.59015],[-83.588449,39.590886],[-83.590001,39.596773],[-83.613621,39.607183],[-83.545617,39.428832],[-83.457289,39.377772],[-83.33344,39.42881],[-83.232434,39.456702],[-83.275352,39.504788],[-83.35609,39.623605],[-83.481512,39.683687],[-83.481922,39.68382]]]]},"properties":{"id":"43160","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590001,39.596773],[-83.588449,39.590886],[-83.585706,39.59015],[-83.585388,39.596455],[-83.588141,39.596622],[-83.590001,39.596773]]]},"properties":{"id":"43142","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.33344,39.42881],[-83.457289,39.377772],[-83.465405,39.275308],[-83.42378,39.219242],[-83.339138,39.300311],[-83.275497,39.356244],[-83.33344,39.42881]]]},"properties":{"id":"45123","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.020879,39.980922],[-83.06789,39.991263],[-83.069056,39.991347],[-83.057651,39.968069],[-83.017381,39.951016],[-82.999708,39.939398],[-82.998542,39.939389],[-82.982374,39.95201],[-82.981627,39.964395],[-82.983202,39.97983],[-83.020879,39.980922]]]},"properties":{"id":"43215","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132368,40.063231],[-83.119646,40.010901],[-83.079357,39.997441],[-83.069056,39.991347],[-83.06789,39.991263],[-83.030585,40.003963],[-83.035191,40.018597],[-83.036695,40.024117],[-83.03817,40.029553],[-83.107394,40.068158],[-83.112597,40.067543],[-83.132368,40.063231]]]},"properties":{"id":"43221","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.187283,39.979078],[-83.143873,39.911239],[-83.092634,39.918855],[-83.090432,39.927573],[-83.079357,39.997441],[-83.119646,40.010901],[-83.187283,39.979078]]]},"properties":{"id":"43228","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.833772,39.920136],[-82.831115,39.954782],[-82.856277,39.943574],[-82.915805,39.93181],[-82.92055,39.917759],[-82.898421,39.902221],[-82.863791,39.890669],[-82.841116,39.90767],[-82.833772,39.920136]]]},"properties":{"id":"43232","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.017594,40.009969],[-82.995858,40.010895],[-82.996855,40.02419],[-82.997358,40.031183],[-83.036695,40.024117],[-83.035191,40.018597],[-83.017594,40.009969]]]},"properties":{"id":"43202","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.275053,38.690051],[-82.1844,38.657629],[-82.135012,38.905456],[-82.195866,38.945132],[-82.356018,38.873855],[-82.379558,38.871117],[-82.380109,38.841278],[-82.275053,38.690051]]]},"properties":{"id":"45631","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.422643,38.679568],[-82.43116,38.54536],[-82.402081,38.520111],[-82.316993,38.536698],[-82.368968,38.676859],[-82.422643,38.679568]]]},"properties":{"id":"45678","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.156146,39.820113],[-84.127485,39.793125],[-84.131506,39.781529],[-84.096937,39.793498],[-84.053736,39.850458],[-84.051656,39.876534],[-84.094495,39.89178],[-84.157223,39.88563],[-84.166668,39.866605],[-84.156146,39.820113]]]},"properties":{"id":"45424","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.138049,40.217153],[-81.177671,40.297071],[-81.180161,40.285291],[-81.389575,40.215508],[-81.41066,40.19819],[-81.38142,40.14212],[-81.228674,40.097158],[-81.200389,40.218052],[-81.138049,40.217153]]]},"properties":{"id":"43973","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.694365,39.891327],[-81.731287,39.79567],[-81.770519,39.758497],[-81.697442,39.755572],[-81.573945,39.831953],[-81.575657,39.83774],[-81.580313,39.839104],[-81.580063,39.849868],[-81.574602,39.844357],[-81.57516,39.839074],[-81.543837,39.838143],[-81.611414,39.935291],[-81.678848,39.936111],[-81.694365,39.891327]]]},"properties":{"id":"43732","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.746139,39.304769],[-84.81945,39.305153],[-84.819936,39.17472],[-84.787607,39.1668],[-84.696834,39.222786],[-84.667653,39.26298],[-84.641814,39.305044],[-84.644442,39.305039],[-84.746139,39.304769]]]},"properties":{"id":"45030","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.324039,39.152661],[-84.390621,39.187355],[-84.39678,39.180709],[-84.422889,39.165668],[-84.405992,39.149996],[-84.401576,39.134683],[-84.374759,39.138968],[-84.322032,39.147023],[-84.324039,39.152661]]]},"properties":{"id":"45227","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.628117,39.235077],[-84.630772,39.311981],[-84.641814,39.305044],[-84.667653,39.26298],[-84.628117,39.235077]]]},"properties":{"id":"45252","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.453392,39.123411],[-84.401576,39.134683],[-84.405992,39.149996],[-84.45488,39.145621],[-84.464063,39.134296],[-84.463639,39.127547],[-84.453392,39.123411]]]},"properties":{"id":"45208","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.434782,39.164465],[-84.455014,39.145661],[-84.45488,39.145621],[-84.405992,39.149996],[-84.422889,39.165668],[-84.434782,39.164465]]]},"properties":{"id":"45209","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.543555,39.099752],[-84.522525,39.092158],[-84.52114,39.107354],[-84.542646,39.121201],[-84.543555,39.099752]]]},"properties":{"id":"45203","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.740056,41.207219],[-83.765244,41.189986],[-83.771391,41.182856],[-83.780247,41.168289],[-83.707865,41.160134],[-83.592716,41.167194],[-83.611532,41.211194],[-83.740056,41.207219]]]},"properties":{"id":"45872","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.541571,40.949783],[-83.575855,40.862415],[-83.515425,40.854491],[-83.486092,40.904945],[-83.476823,40.952525],[-83.541571,40.949783]]]},"properties":{"id":"45867","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.689307,40.856177],[-83.672295,40.906797],[-83.708176,40.936192],[-83.785599,40.877657],[-83.804701,40.841543],[-83.737886,40.819298],[-83.737429,40.819301],[-83.689307,40.856177]]]},"properties":{"id":"45841","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.051432,40.165474],[-80.882892,40.159495],[-80.883181,40.180674],[-80.887566,40.183436],[-80.837385,40.298216],[-80.852957,40.307438],[-80.873862,40.328518],[-80.917365,40.326055],[-81.056602,40.362558],[-81.181181,40.334659],[-81.181903,40.311463],[-81.177671,40.297071],[-81.138049,40.217153],[-81.140838,40.171051],[-81.130259,40.171058],[-81.051432,40.165474]]]},"properties":{"id":"43907","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937161,40.424613],[-80.917365,40.326055],[-80.873862,40.328518],[-80.90437,40.423812],[-80.937161,40.424613]]]},"properties":{"id":"43976","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.071533,41.282962],[-84.093542,41.19828],[-84.06739,41.181194],[-84.016209,41.177],[-84.030174,41.2832],[-84.071533,41.282962]]]},"properties":{"id":"43524","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.260644,41.438693],[-84.251635,41.427801],[-84.247781,41.427813],[-84.256812,41.437818],[-84.260644,41.438693]]]},"properties":{"id":"43555","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.225034,39.557627],[-82.165379,39.488289],[-82.16114,39.534844],[-82.224246,39.559948],[-82.225034,39.557627]]]},"properties":{"id":"43144","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.870696,41.153566],[-82.976677,41.095757],[-82.901156,40.993659],[-82.815772,40.996361],[-82.780155,40.995926],[-82.781525,41.025797],[-82.835458,41.144053],[-82.870696,41.153566]]]},"properties":{"id":"44807","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.825503,40.524084],[-80.806369,40.477697],[-80.747788,40.467078],[-80.670757,40.562654],[-80.825503,40.524084]]]},"properties":{"id":"43932","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.61565,40.288711],[-80.649456,40.255675],[-80.647218,40.249161],[-80.61565,40.288711]]]},"properties":{"id":"43913","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.783542,40.276982],[-80.786043,40.263094],[-80.768845,40.264271],[-80.783542,40.276982]]]},"properties":{"id":"43948","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.5209,38.506612],[-82.629834,38.481608],[-82.487112,38.417899],[-82.480699,38.502334],[-82.5209,38.506612]]]},"properties":{"id":"45680","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.765355,38.657449],[-82.808431,38.592107],[-82.827009,38.577194],[-82.629834,38.481608],[-82.5209,38.506612],[-82.60733,38.583481],[-82.765355,38.657449]]]},"properties":{"id":"45638","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.484696,40.02697],[-82.418939,39.960822],[-82.413944,39.959427],[-82.288377,39.966043],[-82.256277,39.97314],[-82.224175,39.951043],[-82.214281,39.956098],[-82.195103,40.010362],[-82.22039,40.056973],[-82.473918,40.035806],[-82.484696,40.02697]]]},"properties":{"id":"43056","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.517762,40.18982],[-82.554455,40.151882],[-82.473918,40.035806],[-82.22039,40.056973],[-82.212021,40.137975],[-82.268889,40.199407],[-82.444007,40.192421],[-82.517762,40.18982]]]},"properties":{"id":"43055","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.473918,40.035806],[-82.554455,40.151882],[-82.560402,40.125652],[-82.613805,40.043622],[-82.572277,39.997338],[-82.484696,40.02697],[-82.473918,40.035806]]]},"properties":{"id":"43023","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.224175,39.951043],[-82.222272,39.948626],[-82.214281,39.956098],[-82.224175,39.951043]]]},"properties":{"id":"43740","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.5903,39.961343],[-82.607697,39.957794],[-82.599204,39.957914],[-82.5903,39.961343]]]},"properties":{"id":"43033","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.974513,40.417402],[-83.87158,40.390114],[-83.86457,40.410476],[-83.868729,40.4623],[-83.907022,40.451295],[-83.9938,40.485106],[-84.002372,40.483115],[-83.993473,40.417439],[-83.974513,40.417402]]]},"properties":{"id":"43333","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.184596,41.41824],[-82.282831,41.403744],[-82.344851,41.348945],[-82.316983,41.320159],[-82.228283,41.338041],[-82.179514,41.399684],[-82.184596,41.41824]]]},"properties":{"id":"44001","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.511982,41.693524],[-83.529653,41.664229],[-83.51146,41.659698],[-83.469878,41.692866],[-83.432378,41.711667],[-83.503995,41.73162],[-83.511982,41.693524]]]},"properties":{"id":"43611","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.786342,41.604346],[-83.805523,41.55234],[-83.752637,41.529452],[-83.722899,41.54621],[-83.751583,41.602803],[-83.786342,41.604346]]]},"properties":{"id":"43542","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.503995,41.73162],[-83.585542,41.729237],[-83.584347,41.677856],[-83.573941,41.678029],[-83.555626,41.687265],[-83.511982,41.693524],[-83.503995,41.73162]]]},"properties":{"id":"43612","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.555626,41.687265],[-83.573941,41.678029],[-83.57238,41.669661],[-83.545706,41.671317],[-83.555626,41.687265]]]},"properties":{"id":"43610","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519,40.98738],[-80.519032,41.120872],[-80.56692,41.133324],[-80.568996,41.083629],[-80.576999,41.06606],[-80.572019,41.029245],[-80.560733,40.987765],[-80.519,40.98738]]]},"properties":{"id":"44436","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.811163,41.134226],[-80.738183,41.133021],[-80.736037,41.134056],[-80.738265,41.133985],[-80.756141,41.1555],[-80.794473,41.1763],[-80.824546,41.134308],[-80.811163,41.134226]]]},"properties":{"id":"44440","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.640473,41.094366],[-80.615481,41.07587],[-80.612447,41.091217],[-80.639986,41.096095],[-80.640473,41.094366]]]},"properties":{"id":"44506","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.644286,41.102731],[-80.640473,41.094366],[-80.639986,41.096095],[-80.644286,41.102731]]]},"properties":{"id":"44503","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.324012,40.658186],[-83.161361,40.702513],[-83.169257,40.731283],[-83.362214,40.687302],[-83.361998,40.658182],[-83.324012,40.658186]]]},"properties":{"id":"43337","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.892776,41.062434],[-81.88591,41.083036],[-81.912488,41.076814],[-81.904411,41.057506],[-81.892776,41.062434]]]},"properties":{"id":"44215","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.933405,41.026034],[-81.918584,41.019673],[-81.915,41.034107],[-81.938438,41.038912],[-81.933405,41.026034]]]},"properties":{"id":"44251","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.961478,41.003049],[-81.976744,41.007325],[-82.039198,40.996858],[-82.029665,40.867904],[-81.956312,40.929846],[-81.961478,41.003049]]]},"properties":{"id":"44214","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.705151,41.277254],[-81.785097,41.27645],[-81.784902,41.201512],[-81.685646,41.202132],[-81.68495,41.277146],[-81.705151,41.277254]]]},"properties":{"id":"44233","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.546467,40.487391],[-84.548891,40.494101],[-84.552393,40.488975],[-84.546467,40.487391]]]},"properties":{"id":"45866","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.499512,40.436897],[-84.498182,40.433114],[-84.489186,40.431532],[-84.489371,40.436909],[-84.499512,40.436897]]]},"properties":{"id":"45826","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.689163,40.727705],[-84.707647,40.822383],[-84.726874,40.822361],[-84.769139,40.797641],[-84.775279,40.796621],[-84.802271,40.789574],[-84.802143,40.657555],[-84.689163,40.727705]]]},"properties":{"id":"45898","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.144234,40.103236],[-84.239864,40.096546],[-84.297642,40.077859],[-84.306168,40.041278],[-84.304603,39.997971],[-84.275087,39.950276],[-84.080432,39.9692],[-84.049679,40.025446],[-84.125668,40.093494],[-84.144234,40.103236]]]},"properties":{"id":"45373","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.958679,39.693407],[-80.873821,39.667376],[-80.88133,39.677023],[-80.867435,39.675617],[-80.866421,39.667253],[-80.865641,39.667253],[-80.824658,39.846553],[-80.904709,39.833548],[-80.939479,39.774116],[-80.944656,39.766517],[-80.958679,39.693407]]]},"properties":{"id":"43915","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.414414,39.536453],[-81.305937,39.562641],[-81.225689,39.578375],[-81.215887,39.578074],[-81.225342,39.629775],[-81.280588,39.707112],[-81.281109,39.707115],[-81.310582,39.707305],[-81.319125,39.70736],[-81.406437,39.668751],[-81.402113,39.61577],[-81.434192,39.582293],[-81.414414,39.536453]]]},"properties":{"id":"45745","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.295077,39.545974],[-81.24878,39.510985],[-81.225689,39.578375],[-81.305937,39.562641],[-81.295077,39.545974]]]},"properties":{"id":"45789","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.183992,39.750248],[-84.192936,39.740054],[-84.207127,39.728999],[-84.20161,39.701009],[-84.190336,39.699037],[-84.168934,39.732924],[-84.168795,39.739972],[-84.183992,39.750248]]]},"properties":{"id":"45409","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.166294,39.768808],[-84.127485,39.793125],[-84.156146,39.820113],[-84.19934,39.777858],[-84.188029,39.766791],[-84.166294,39.768808]]]},"properties":{"id":"45404","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.268283,39.739616],[-84.253001,39.74067],[-84.253077,39.749405],[-84.268504,39.746817],[-84.268283,39.739616]]]},"properties":{"id":"45428","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.480149,39.65705],[-84.347561,39.701876],[-84.481411,39.722809],[-84.480149,39.65705]]]},"properties":{"id":"45325","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.40437,39.91996],[-84.404669,39.907955],[-84.392679,39.904818],[-84.393164,39.920148],[-84.40437,39.91996]]]},"properties":{"id":"45354","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.686437,40.476881],[-82.679121,40.478605],[-82.661956,40.572064],[-82.663179,40.623187],[-82.718512,40.638514],[-82.860145,40.630693],[-82.85894,40.523475],[-82.686437,40.476881]]]},"properties":{"id":"43338","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778609,39.933765],[-81.841947,39.936026],[-81.900439,39.896101],[-81.879742,39.872804],[-81.731287,39.79567],[-81.694365,39.891327],[-81.778609,39.933765]]]},"properties":{"id":"43727","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.927361,39.872869],[-81.902312,39.865726],[-81.879742,39.872804],[-81.900439,39.896101],[-81.927361,39.872869]]]},"properties":{"id":"43734","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.406437,39.668751],[-81.587358,39.625199],[-81.532626,39.59254],[-81.402113,39.61577],[-81.406437,39.668751]]]},"properties":{"id":"45727","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.57516,39.839074],[-81.574602,39.844357],[-81.580063,39.849868],[-81.580313,39.839104],[-81.575657,39.83774],[-81.573945,39.831953],[-81.57516,39.839074]]]},"properties":{"id":"43711","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.528501,39.584738],[-81.434192,39.582293],[-81.402113,39.61577],[-81.532626,39.59254],[-81.528501,39.584738]]]},"properties":{"id":"45746","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.465758,41.591229],[-83.483247,41.529398],[-83.450474,41.542848],[-83.368419,41.562463],[-83.368227,41.574123],[-83.415773,41.604132],[-83.465758,41.591229]]]},"properties":{"id":"43447","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.780385,39.528243],[-82.928459,39.477226],[-82.764815,39.402448],[-82.780385,39.528243]]]},"properties":{"id":"45644","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.780321,39.551934],[-82.768116,39.551271],[-82.77569,39.557275],[-82.781039,39.557565],[-82.780321,39.551934]]]},"properties":{"id":"43156","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.298898,41.240578],[-81.311602,41.202385],[-81.27821,41.110744],[-81.098618,41.099107],[-81.037267,41.138594],[-81.02944,41.18456],[-81.157329,41.202154],[-81.148427,41.242948],[-81.298898,41.240578]],[[-81.07127,41.161406],[-81.067458,41.158738],[-81.07452,41.156365],[-81.07599,41.161208],[-81.07127,41.161406]]]},"properties":{"id":"44266","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.367954,40.982176],[-81.368917,40.918195],[-81.362409,40.917231],[-81.283898,40.931409],[-81.244702,40.951904],[-81.24939,40.988359],[-81.302535,41.003732],[-81.367954,40.982176]]]},"properties":{"id":"44632","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551086,39.588119],[-84.554725,39.595237],[-84.564025,39.595375],[-84.56369,39.586967],[-84.554489,39.582305],[-84.551086,39.588119]]]},"properties":{"id":"45070","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.183509,40.987353],[-83.995506,40.956853],[-83.880603,40.993155],[-83.880563,41.020108],[-83.880674,41.027561],[-83.880653,41.051189],[-84.12465,41.101721],[-84.131291,41.093463],[-84.198172,41.050066],[-84.207414,40.991381],[-84.183509,40.987353]]]},"properties":{"id":"45875","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.407867,41.334038],[-83.416371,41.38472],[-83.47495,41.340838],[-83.447842,41.297367],[-83.388233,41.298065],[-83.407867,41.334038]]]},"properties":{"id":"43406","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.870078,38.729183],[-82.856037,38.851795],[-82.906846,38.849557],[-83.017872,38.834732],[-83.012288,38.820478],[-83.01285,38.73054],[-82.870078,38.729183]]]},"properties":{"id":"45662","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.129657,38.793051],[-83.012288,38.820478],[-83.017872,38.834732],[-83.118309,38.869696],[-83.129657,38.793051]]]},"properties":{"id":"45652","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.533427,40.648488],[-81.646769,40.666498],[-81.654062,40.634514],[-81.562145,40.633991],[-81.537606,40.647102],[-81.533427,40.648488]]]},"properties":{"id":"44608","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.368334,40.818276],[-81.364618,40.860113],[-81.422763,40.858303],[-81.39479,40.823561],[-81.390806,40.80971],[-81.368334,40.818276]]]},"properties":{"id":"44709","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.507522,40.929468],[-81.515487,40.943927],[-81.517303,40.945259],[-81.565716,40.963023],[-81.647653,40.980301],[-81.64785,40.925197],[-81.647872,40.918859],[-81.533569,40.897112],[-81.506078,40.902375],[-81.507522,40.929468]]]},"properties":{"id":"44216","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.15331,40.988221],[-81.153196,40.980985],[-81.143805,40.981034],[-81.144786,40.988214],[-81.15331,40.988221]]]},"properties":{"id":"44640","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488988,41.349753],[-81.51864,41.350154],[-81.489435,41.277053],[-81.463397,41.286695],[-81.488988,41.349753]]]},"properties":{"id":"44056","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.805318,41.203162],[-80.794473,41.1763],[-80.756141,41.1555],[-80.719274,41.174042],[-80.6804,41.202987],[-80.711122,41.205288],[-80.79844,41.203161],[-80.805318,41.203162]]]},"properties":{"id":"44446","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.36346,40.56865],[-81.35343,40.557274],[-81.338092,40.56543],[-81.350874,40.576029],[-81.36346,40.56865]]]},"properties":{"id":"44678","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.550111,40.771245],[-84.551879,40.728066],[-84.513561,40.728204],[-84.475364,40.728219],[-84.397115,40.765045],[-84.435235,40.815773],[-84.550111,40.771245]],[[-84.479941,40.741517],[-84.479759,40.746265],[-84.47544,40.746309],[-84.475405,40.737868],[-84.479941,40.741517]]]},"properties":{"id":"45894","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.479941,40.741517],[-84.475405,40.737868],[-84.47544,40.746309],[-84.479759,40.746265],[-84.479941,40.741517]]]},"properties":{"id":"45838","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.115027,39.39861],[-84.073628,39.401565],[-84.008894,39.453639],[-84.125067,39.477421],[-84.115027,39.39861]]]},"properties":{"id":"45054","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.755235,39.334864],[-81.684375,39.306647],[-81.654441,39.355602],[-81.638649,39.442613],[-81.72326,39.403235],[-81.755235,39.334864]]]},"properties":{"id":"45784","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.580588,39.565998],[-81.583731,39.567217],[-81.588797,39.564846],[-81.580588,39.565998]]]},"properties":{"id":"45721","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.707201,40.696103],[-81.704905,40.69091],[-81.697123,40.692557],[-81.700842,40.698407],[-81.707201,40.696103]]]},"properties":{"id":"44659","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.786524,41.415756],[-83.727745,41.309301],[-83.708556,41.31274],[-83.543361,41.348582],[-83.515167,41.456915],[-83.531888,41.477738],[-83.712843,41.492494],[-83.780638,41.450152],[-83.786524,41.415756]],[[-83.74259,41.421511],[-83.737771,41.425054],[-83.737708,41.414852],[-83.742569,41.414853],[-83.74259,41.421511]],[[-83.64297,41.376968],[-83.629564,41.378126],[-83.629449,41.374526],[-83.64297,41.376968]],[[-83.710338,41.459343],[-83.714143,41.473938],[-83.692848,41.473699],[-83.694038,41.459173],[-83.710338,41.459343]]]},"properties":{"id":"43402","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.592838,41.282259],[-83.645375,41.268504],[-83.755961,41.255417],[-83.740056,41.207219],[-83.611532,41.211194],[-83.563708,41.251482],[-83.592838,41.282259]],[[-83.602004,41.246427],[-83.61201,41.260983],[-83.5929,41.260948],[-83.602004,41.246427]]]},"properties":{"id":"43413","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.710338,41.459343],[-83.694038,41.459173],[-83.692848,41.473699],[-83.714143,41.473938],[-83.710338,41.459343]]]},"properties":{"id":"43525","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.360489,36.65208],[-98.540502,36.623168],[-98.66627,36.6089],[-98.69352,36.549959],[-98.342113,36.550113],[-98.360489,36.65208]]]},"properties":{"id":"73726","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407099,34.334094],[-96.494345,34.298085],[-96.413552,34.160459],[-96.372795,34.216311],[-96.302975,34.273252],[-96.407099,34.334094]]]},"properties":{"id":"73432","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.366694,36.860167],[-100.192295,36.76535],[-99.890793,36.812118],[-100.002563,37.001706],[-100.423361,37.001104],[-100.366694,36.860167]]]},"properties":{"id":"73844","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.553623,35.16395],[-99.440657,35.116538],[-99.409291,35.114509],[-99.360226,35.116738],[-99.364392,35.212837],[-99.399628,35.291323],[-99.553623,35.16395]]]},"properties":{"id":"73627","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.547589,35.913271],[-98.543364,35.725193],[-98.472796,35.710741],[-98.373718,35.765328],[-98.313252,35.718047],[-98.224512,35.763982],[-98.316521,35.841063],[-98.425013,36.014193],[-98.479839,36.057387],[-98.555008,36.013775],[-98.547589,35.913271]]]},"properties":{"id":"73772","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.425013,36.014193],[-98.210134,35.999476],[-98.111584,36.050523],[-98.210544,36.16489],[-98.242521,36.196592],[-98.423808,36.216525],[-98.501111,36.165067],[-98.479839,36.057387],[-98.425013,36.014193]]]},"properties":{"id":"73763","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.357589,33.76944],[-96.353,33.780318],[-96.361244,33.780196],[-96.357589,33.76944]]]},"properties":{"id":"74747","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.391945,35.014775],[-98.268811,34.934816],[-98.180807,34.970849],[-98.093005,35.014147],[-98.092989,35.064887],[-98.179401,35.174843],[-98.332714,35.120444],[-98.391945,35.014775]]]},"properties":{"id":"73005","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.332714,35.120444],[-98.179401,35.174843],[-98.1532,35.189345],[-98.156321,35.24723],[-98.218829,35.36234],[-98.253908,35.354316],[-98.354616,35.188341],[-98.332714,35.120444]]]},"properties":{"id":"73042","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465212,35.406243],[-98.277579,35.357776],[-98.253908,35.354316],[-98.218829,35.36234],[-98.114056,35.420917],[-98.149226,35.455037],[-98.286141,35.528485],[-98.429619,35.55147],[-98.465212,35.406243]]]},"properties":{"id":"73047","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.570625,35.305186],[-98.659046,35.269413],[-98.667812,35.233096],[-98.619816,34.854953],[-98.545351,34.855104],[-98.497822,34.971023],[-98.520245,35.233095],[-98.570625,35.305186]]]},"properties":{"id":"73015","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.145368,34.854879],[-98.08901,34.834806],[-98.05992,34.840743],[-98.005674,34.961293],[-98.093005,35.014147],[-98.180807,34.970849],[-98.145368,34.854879]]]},"properties":{"id":"73017","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.674052,35.565697],[-97.674036,35.594822],[-97.673962,35.635266],[-97.834064,35.652931],[-97.86963,35.609459],[-97.813027,35.406207],[-97.689145,35.420599],[-97.689208,35.43509],[-97.689097,35.460297],[-97.671319,35.492956],[-97.67122,35.537199],[-97.674052,35.565697]]]},"properties":{"id":"73099","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.265097,34.071203],[-97.078969,34.036181],[-96.969627,34.07102],[-96.933671,34.172553],[-96.933457,34.332661],[-97.038723,34.366039],[-97.35213,34.274495],[-97.317501,34.230949],[-97.265097,34.071203]]]},"properties":{"id":"73401","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.564434,34.445076],[-97.54492,34.445301],[-97.544926,34.463302],[-97.5623,34.463254],[-97.564434,34.445076]]]},"properties":{"id":"73425","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.005951,36.263852],[-95.059199,36.161545],[-95.011303,36.090939],[-94.868591,36.161491],[-94.869048,36.205153],[-94.833163,36.212423],[-94.833174,36.219577],[-94.95829,36.292851],[-95.005951,36.263852]]]},"properties":{"id":"74364","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.840775,35.918846],[-94.900169,35.827103],[-94.846392,35.74056],[-94.807534,35.799739],[-94.840775,35.918846]]]},"properties":{"id":"74471","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.330286,34.142384],[-95.365422,33.982796],[-95.230865,33.959512],[-95.155882,33.936572],[-95.205944,33.997382],[-95.202381,34.026476],[-95.156506,34.120672],[-95.156487,34.14245],[-95.184296,34.271573],[-95.343584,34.199538],[-95.330286,34.142384]]]},"properties":{"id":"74735","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.489848,35.302276],[-97.512051,35.290761],[-97.559281,35.290686],[-97.564637,35.160607],[-97.42754,35.142917],[-97.407822,35.160662],[-97.430683,35.200322],[-97.441235,35.20386],[-97.445355,35.207461],[-97.489848,35.302276]]]},"properties":{"id":"73072","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.441865,35.298265],[-97.430683,35.200322],[-97.407822,35.160662],[-97.388252,35.175018],[-97.388255,35.305283],[-97.423604,35.305285],[-97.441865,35.298265]]]},"properties":{"id":"73071","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.537536,35.363293],[-97.580653,35.363062],[-97.599091,35.303351],[-97.559281,35.290686],[-97.512051,35.290761],[-97.512168,35.363381],[-97.537536,35.363293]]]},"properties":{"id":"73170","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.469037,34.723648],[-96.41421,34.547954],[-96.377521,34.549077],[-96.354537,34.679884],[-96.469037,34.723648]]]},"properties":{"id":"74572","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.354537,34.679884],[-96.377521,34.549077],[-96.336966,34.679835],[-96.354537,34.679884]]]},"properties":{"id":"74534","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.24711,34.528868],[-98.487816,34.507606],[-98.484479,34.42732],[-98.461104,34.405558],[-98.24711,34.528868]]]},"properties":{"id":"73543","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.527634,34.521919],[-98.642995,34.507457],[-98.484479,34.42732],[-98.487816,34.507606],[-98.492399,34.521854],[-98.527634,34.521919]]]},"properties":{"id":"73540","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.936701,34.942777],[-99.148985,34.798212],[-99.164837,34.783099],[-98.808686,34.790575],[-98.789982,34.855673],[-98.849003,34.884582],[-98.936701,34.942777]]]},"properties":{"id":"73564","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.452771,34.275396],[-98.301349,34.202518],[-98.13919,34.36233],[-98.139283,34.449193],[-98.156828,34.506967],[-98.24711,34.528868],[-98.461104,34.405558],[-98.609598,34.304502],[-98.609656,34.246249],[-98.452771,34.275396]]]},"properties":{"id":"73572","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.156828,34.506967],[-98.139283,34.449193],[-97.737308,34.376419],[-97.597512,34.405272],[-97.579908,34.466994],[-97.635572,34.448828],[-97.694398,34.449199],[-97.714006,34.507078],[-97.666762,34.507061],[-97.66689,34.477923],[-97.579877,34.469251],[-97.563112,34.52159],[-97.687555,34.615655],[-98.142046,34.594248],[-98.156828,34.506967]]]},"properties":{"id":"73533","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.460899,36.129117],[-96.474166,36.042192],[-96.458604,36.003006],[-96.336939,36.00547],[-96.320586,36.035579],[-96.297888,36.162279],[-96.384096,36.196311],[-96.460899,36.129117]]]},"properties":{"id":"74044","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.301446,35.969248],[-96.192495,35.791534],[-96.192491,35.839417],[-96.159545,35.85718],[-96.301446,35.969248]]]},"properties":{"id":"74039","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.622847,36.047063],[-96.619297,35.876609],[-96.58398,35.904749],[-96.564354,35.912215],[-96.557614,35.914028],[-96.458604,36.003006],[-96.474166,36.042192],[-96.548283,36.075665],[-96.590855,36.046471],[-96.622847,36.047063]]]},"properties":{"id":"74030","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.804712,36.15904],[-96.867388,36.116175],[-96.819677,36.072154],[-96.785555,36.053891],[-96.622847,36.047063],[-96.590855,36.046471],[-96.622549,36.099563],[-96.677819,36.224965],[-96.804712,36.15904]]]},"properties":{"id":"74085","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.773604,36.667819],[-94.81939,36.670344],[-94.844758,36.550526],[-94.617985,36.53266],[-94.617758,36.625442],[-94.773604,36.667819]]]},"properties":{"id":"74344","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.000404,36.420899],[-100.000396,35.880948],[-99.942857,35.906646],[-99.793001,36.1733],[-100.000404,36.420899]]]},"properties":{"id":"73858","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.695033,36.536852],[-99.595856,36.21703],[-99.488795,36.16449],[-99.605114,36.521917],[-99.695033,36.536852]]]},"properties":{"id":"73840","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.592313,36.592821],[-99.692391,36.593191],[-99.6953,36.560974],[-99.695033,36.536852],[-99.605114,36.521917],[-99.592313,36.592821]]]},"properties":{"id":"73841","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.014945,36.318245],[-98.068937,36.231897],[-98.077475,36.16501],[-97.988243,36.1648],[-97.782059,36.231296],[-97.747248,36.274952],[-97.783024,36.318047],[-97.872353,36.318401],[-97.897252,36.332837],[-97.907636,36.332927],[-98.014945,36.318245]]]},"properties":{"id":"73773","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.068937,36.231897],[-98.014945,36.318245],[-98.033014,36.36177],[-98.104477,36.318284],[-98.104418,36.272626],[-98.068937,36.231897]]]},"properties":{"id":"73735","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.533032,36.11608],[-97.514385,36.2317],[-97.532883,36.274995],[-97.604119,36.260409],[-97.760129,36.188409],[-97.782457,36.16462],[-97.675487,36.072779],[-97.559615,36.086946],[-97.533032,36.11608]]]},"properties":{"id":"73056","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.140283,36.463133],[-98.104477,36.318284],[-98.033014,36.36177],[-98.068887,36.448692],[-98.068782,36.463177],[-98.140283,36.463133]]]},"properties":{"id":"73754","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.032908,36.564838],[-98.104205,36.550066],[-98.068782,36.463177],[-98.068887,36.448692],[-97.961447,36.506642],[-97.996834,36.536054],[-98.032866,36.550303],[-98.032908,36.564838]]]},"properties":{"id":"73727","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.345481,36.43436],[-97.345516,36.557001],[-97.462459,36.593627],[-97.53375,36.59348],[-97.542503,36.563739],[-97.46119,36.390371],[-97.444039,36.390637],[-97.345481,36.43436]]]},"properties":{"id":"74630","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.477749,34.506784],[-97.457169,34.506927],[-97.352693,34.506851],[-97.317107,34.532214],[-97.282642,34.651809],[-97.398886,34.737665],[-97.492802,34.746391],[-97.527834,34.695549],[-97.528017,34.533589],[-97.477749,34.506784]]]},"properties":{"id":"73433","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.961308,35.145735],[-98.056682,35.160199],[-98.092989,35.064887],[-98.093005,35.014147],[-98.005674,34.961293],[-97.820413,34.986064],[-97.802399,34.986093],[-97.793862,35.087674],[-97.961308,35.145735]]]},"properties":{"id":"73018","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.671595,35.335934],[-97.833675,35.335362],[-97.856847,35.344955],[-97.918337,35.247203],[-97.882548,35.203645],[-97.758744,35.181988],[-97.670824,35.282993],[-97.671595,35.335934]]]},"properties":{"id":"73089","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.844585,34.506915],[-99.526456,34.411779],[-99.561424,34.536011],[-99.666512,34.550628],[-99.806486,34.521569],[-99.844585,34.506915]]]},"properties":{"id":"73537","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.208124,35.057897],[-95.178817,35.057897],[-95.145401,35.150851],[-95.323314,35.188977],[-95.349233,35.058514],[-95.208124,35.057897]]]},"properties":{"id":"74552","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.619816,34.854953],[-98.667812,35.233096],[-98.84582,35.175366],[-98.849003,34.884582],[-98.789982,34.855673],[-98.619816,34.854953]]]},"properties":{"id":"73062","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.481046,35.029263],[-95.507774,34.948985],[-95.514225,34.758703],[-95.297702,34.78192],[-95.120528,34.824956],[-95.147514,34.854252],[-95.208124,35.057897],[-95.349233,35.058514],[-95.481046,35.029263]]]},"properties":{"id":"74578","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.588499,34.869232],[-95.569161,34.86938],[-95.57088,34.845619],[-95.586257,34.851861],[-95.749928,34.762021],[-95.594656,34.737915],[-95.514225,34.758703],[-95.507774,34.948985],[-95.588499,34.869232]]]},"properties":{"id":"74547","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.699023,34.966859],[-94.522958,34.956421],[-94.447465,34.941921],[-94.442501,35.080197],[-94.643533,35.121953],[-94.84885,35.065485],[-94.699023,34.966859]]]},"properties":{"id":"74953","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.629114,35.189107],[-94.646838,35.148074],[-94.643533,35.121953],[-94.442501,35.080197],[-94.438824,35.202043],[-94.549309,35.208391],[-94.629114,35.189107]]]},"properties":{"id":"74932","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.194397,35.782827],[-97.140963,35.579535],[-97.034812,35.564924],[-96.981544,35.601055],[-97.034573,35.796936],[-97.070088,35.854993],[-97.176699,35.898534],[-97.176813,35.883646],[-97.194397,35.782827]]]},"properties":{"id":"74881","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.247096,35.884111],[-97.282825,35.739557],[-97.264958,35.73946],[-97.194397,35.782827],[-97.176813,35.883646],[-97.247096,35.884111]]]},"properties":{"id":"73058","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.026981,34.210587],[-94.997601,34.024399],[-94.938083,34.019434],[-94.895754,34.129971],[-95.026221,34.232531],[-95.026981,34.210587]]]},"properties":{"id":"74766","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.34482,35.435053],[-95.400615,35.348242],[-95.358297,35.306632],[-95.271993,35.281044],[-95.226087,35.2692],[-95.133345,35.380126],[-95.238802,35.435258],[-95.34482,35.435053]]]},"properties":{"id":"74455","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.68101,35.486152],[-95.822969,35.419993],[-95.560305,35.356281],[-95.400615,35.348242],[-95.34482,35.435053],[-95.344825,35.536779],[-95.358098,35.551807],[-95.393164,35.551759],[-95.485386,35.551926],[-95.486705,35.518599],[-95.497307,35.529067],[-95.492062,35.551897],[-95.586133,35.551714],[-95.68101,35.486152]]]},"properties":{"id":"74426","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.012741,35.638397],[-95.078284,35.638697],[-95.127491,35.67561],[-95.183543,35.601358],[-95.188159,35.574493],[-95.109663,35.495093],[-95.012741,35.638397]]]},"properties":{"id":"74435","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.23806,36.159319],[-97.103671,36.260953],[-97.085667,36.38631],[-97.345481,36.43436],[-97.444039,36.390637],[-97.407023,36.231639],[-97.282667,36.159297],[-97.23806,36.159319]]]},"properties":{"id":"73077","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.47686,35.595375],[-96.529665,35.444458],[-96.405974,35.488933],[-96.47686,35.595375]]]},"properties":{"id":"74829","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.60285,35.536819],[-97.547534,35.536809],[-97.512034,35.536852],[-97.511934,35.551129],[-97.540415,35.551125],[-97.609713,35.562788],[-97.603553,35.536819],[-97.60285,35.536819]]]},"properties":{"id":"73116","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.547913,35.462848],[-97.547692,35.499503],[-97.601163,35.500529],[-97.601161,35.500061],[-97.60478,35.464144],[-97.547913,35.462848]]]},"properties":{"id":"73107","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.601111,35.460203],[-97.601036,35.43525],[-97.539099,35.435229],[-97.530127,35.464265],[-97.547913,35.462848],[-97.60478,35.464144],[-97.601111,35.460203]]]},"properties":{"id":"73108","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.459196,35.42081],[-97.423569,35.420739],[-97.423568,35.435229],[-97.424059,35.460514],[-97.459169,35.461045],[-97.459196,35.42081]]]},"properties":{"id":"73115","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.388576,35.517852],[-97.335655,35.493029],[-97.319724,35.507583],[-97.30049,35.536672],[-97.388576,35.517852]]]},"properties":{"id":"73084","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.378985,35.434541],[-97.423568,35.435229],[-97.423569,35.420739],[-97.388364,35.398279],[-97.378985,35.434541]]]},"properties":{"id":"73145","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.424017,35.485149],[-97.424059,35.460514],[-97.423568,35.435229],[-97.378985,35.434541],[-97.370788,35.43434],[-97.3753,35.485769],[-97.424017,35.485149]]]},"properties":{"id":"73110","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.673951,35.63854],[-97.638552,35.63854],[-97.513847,35.638413],[-97.514085,35.696637],[-97.673994,35.696548],[-97.673951,35.63854]]]},"properties":{"id":"73012","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.425029,35.580174],[-97.424931,35.558253],[-97.424537,35.551115],[-97.39829,35.55828],[-97.398147,35.584025],[-97.425029,35.580174]]]},"properties":{"id":"73151","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.869303,36.640899],[-96.689729,36.68827],[-96.819974,36.765052],[-96.905861,36.737129],[-96.869303,36.640899]]]},"properties":{"id":"74633","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.120647,36.498379],[-96.053536,36.485846],[-96.066365,36.528503],[-96.120647,36.498379]]]},"properties":{"id":"74001","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.964845,36.263355],[-95.936872,36.249574],[-95.902561,36.264038],[-95.902086,36.322085],[-95.920113,36.3366],[-96.040098,36.262288],[-95.964845,36.263355]]]},"properties":{"id":"74073","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.384096,36.196311],[-96.297888,36.162279],[-96.250288,36.219981],[-96.279489,36.227079],[-96.43783,36.293681],[-96.556152,36.301947],[-96.570307,36.286381],[-96.553285,36.231815],[-96.499781,36.219801],[-96.384096,36.196311]]]},"properties":{"id":"74020","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.633369,34.608715],[-96.634846,34.636363],[-96.652382,34.621754],[-96.633369,34.608715]]]},"properties":{"id":"74842","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.779617,36.160624],[-95.75245,36.148009],[-95.618551,36.162508],[-95.605015,36.225672],[-95.744186,36.265665],[-95.81527,36.22445],[-95.779617,36.160624]]]},"properties":{"id":"74015","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.856033,36.481561],[-95.81231,36.443733],[-95.656629,36.478082],[-95.660373,36.597526],[-95.809617,36.597476],[-95.856033,36.481561]]]},"properties":{"id":"74080","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.467205,35.348039],[-96.441275,35.348138],[-96.441303,35.377324],[-96.458991,35.377262],[-96.467205,35.348039]]]},"properties":{"id":"74837","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.579877,34.469251],[-97.66689,34.477923],[-97.666762,34.507061],[-97.714006,34.507078],[-97.694398,34.449199],[-97.635572,34.448828],[-97.579908,34.466994],[-97.579877,34.469251]]]},"properties":{"id":"73491","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.829931,36.993969],[-101.84794,36.936006],[-101.992202,36.993224],[-102.028204,36.993102],[-102.031855,36.791307],[-101.672775,36.499545],[-101.583013,36.49952],[-101.632505,36.994915],[-101.829931,36.993969]]]},"properties":{"id":"73939","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.868562,36.002861],[-95.761463,35.918],[-95.76165,36.017439],[-95.850972,36.017315],[-95.868562,36.002861]]]},"properties":{"id":"74011","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.945038,36.225081],[-95.940324,36.159589],[-95.875031,36.160748],[-95.869132,36.161046],[-95.872197,36.218699],[-95.945038,36.225081]]]},"properties":{"id":"74115","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.967113,36.164489],[-95.99346,36.163464],[-95.985993,36.151754],[-95.981772,36.13418],[-95.970412,36.133364],[-95.967157,36.158625],[-95.967113,36.164489]]]},"properties":{"id":"74120","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.107188,35.320269],[-99.170164,35.320327],[-99.223363,35.320375],[-99.240985,35.320437],[-99.240892,35.255145],[-99.126368,35.218859],[-99.117393,35.218874],[-99.107188,35.320269]]]},"properties":{"id":"73641","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.063968,35.363898],[-98.857654,35.349291],[-98.87394,35.378277],[-99.028954,35.421853],[-99.063968,35.363898]]]},"properties":{"id":"73622","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.861029,35.722551],[-94.829001,35.638618],[-94.753695,35.638579],[-94.629908,35.638295],[-94.807534,35.799739],[-94.846392,35.74056],[-94.861029,35.722551]]]},"properties":{"id":"74931","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.774113,35.959627],[-94.519152,35.911302],[-94.554265,36.113896],[-94.732508,36.074472],[-94.774113,35.959627]]]},"properties":{"id":"74965","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.441123,35.450175],[-94.477907,35.669028],[-94.594475,35.638265],[-94.679555,35.427547],[-94.621156,35.300749],[-94.494738,35.332276],[-94.472803,35.375781],[-94.460414,35.404923],[-94.441123,35.450175]]]},"properties":{"id":"74948","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.930059,36.335603],[-94.95829,36.292851],[-94.833174,36.219577],[-94.73522,36.161675],[-94.56517,36.176312],[-94.593094,36.343866],[-94.879818,36.347839],[-94.930059,36.335603]]]},"properties":{"id":"74338","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.240044,36.806873],[-98.334132,36.898767],[-98.359712,36.928015],[-98.485976,36.819016],[-98.54059,36.681215],[-98.540502,36.623168],[-98.360489,36.65208],[-98.253042,36.651863],[-98.240044,36.806873]]]},"properties":{"id":"73728","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.525976,34.383373],[-96.539684,34.361369],[-96.531355,34.34952],[-96.494345,34.298085],[-96.407099,34.334094],[-96.354648,34.418373],[-96.442015,34.41838],[-96.525976,34.383373]]]},"properties":{"id":"73461","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.945463,36.998416],[-101.07191,36.863168],[-100.954039,36.73256],[-100.953873,36.717138],[-100.953933,36.67123],[-100.844508,36.759501],[-100.70336,36.83541],[-100.822781,36.998831],[-100.945463,36.998416]]]},"properties":{"id":"73950","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.853693,36.016153],[-98.63617,35.91326],[-98.547589,35.913271],[-98.555008,36.013775],[-98.725784,36.163263],[-98.853693,36.016153]]]},"properties":{"id":"73724","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.535483,33.91102],[-96.537761,33.905847],[-96.534273,33.904738],[-96.535483,33.91102]]]},"properties":{"id":"74753","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465212,35.406243],[-98.500266,35.290716],[-98.277579,35.357776],[-98.465212,35.406243]]]},"properties":{"id":"73053","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.156321,35.24723],[-97.918337,35.247203],[-97.856847,35.344955],[-98.095904,35.412219],[-98.114056,35.420917],[-98.218829,35.36234],[-98.156321,35.24723]]]},"properties":{"id":"73059","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.763439,35.725746],[-97.834064,35.652931],[-97.673962,35.635266],[-97.673951,35.63854],[-97.673994,35.696548],[-97.674026,35.72597],[-97.763439,35.725746]]]},"properties":{"id":"73078","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.689208,35.43509],[-97.601036,35.43525],[-97.601111,35.460203],[-97.689097,35.460297],[-97.689208,35.43509]]]},"properties":{"id":"73128","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.059199,36.161545],[-95.100618,36.08193],[-95.011303,36.090939],[-95.059199,36.161545]]]},"properties":{"id":"74452","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.861029,35.722551],[-94.995943,35.651224],[-94.854159,35.638699],[-94.829001,35.638618],[-94.861029,35.722551]]]},"properties":{"id":"74427","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.779225,34.317338],[-95.779376,34.156699],[-95.582525,34.132005],[-95.442215,34.156869],[-95.440334,34.156863],[-95.409183,34.288853],[-95.588137,34.375486],[-95.779225,34.317338]]]},"properties":{"id":"74523","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.380346,35.083259],[-97.342898,34.971496],[-97.254319,34.945365],[-97.142349,34.928176],[-97.14201,35.116252],[-97.14199,35.130725],[-97.380346,35.083259]]]},"properties":{"id":"73051","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.14201,35.116252],[-97.142349,34.928176],[-96.964976,34.938149],[-96.984293,35.043912],[-97.14201,35.116252]]]},"properties":{"id":"74878","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.319724,35.507583],[-97.331622,35.493051],[-97.335631,35.485766],[-97.317977,35.435368],[-97.266923,35.372667],[-97.264966,35.362818],[-97.194841,35.444242],[-97.221216,35.536314],[-97.30049,35.536672],[-97.319724,35.507583]]]},"properties":{"id":"73020","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.300445,34.883631],[-96.408639,34.930673],[-96.490786,34.910585],[-96.516736,34.871781],[-96.520988,34.752697],[-96.469037,34.723648],[-96.354537,34.679884],[-96.336966,34.679835],[-96.292838,34.766804],[-96.300445,34.883631]]]},"properties":{"id":"74825","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.442015,34.41838],[-96.514139,34.463188],[-96.555125,34.445002],[-96.525976,34.383373],[-96.442015,34.41838]]]},"properties":{"id":"74530","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.463512,34.724426],[-98.488184,34.746089],[-98.509956,34.724823],[-98.463512,34.724426]]]},"properties":{"id":"73557","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.808686,34.790575],[-99.164837,34.783099],[-99.162085,34.739445],[-99.10315,34.63795],[-98.82624,34.696034],[-98.808795,34.739683],[-98.808686,34.790575]]]},"properties":{"id":"73559","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.918239,36.757565],[-94.96334,36.75769],[-95.054062,36.728817],[-95.02373,36.531043],[-95.023567,36.509992],[-95.005719,36.491925],[-95.005718,36.491773],[-94.957517,36.515146],[-94.844758,36.550526],[-94.81939,36.670344],[-94.918239,36.757565]]]},"properties":{"id":"74331","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.02373,36.531043],[-95.043063,36.524669],[-95.023567,36.509992],[-95.02373,36.531043]]]},"properties":{"id":"74349","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.40762,36.999311],[-95.69616,36.99925],[-95.68375,36.88753],[-95.43179,36.8876],[-95.39569,36.89857],[-95.40762,36.999311]]]},"properties":{"id":"74072","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.192495,35.791534],[-96.301446,35.969248],[-96.336939,36.00547],[-96.458604,36.003006],[-96.557614,35.914028],[-96.487881,35.639075],[-96.482487,35.639061],[-96.35378,35.638989],[-96.192563,35.639087],[-96.192689,35.692403],[-96.192495,35.791534]],[[-96.270996,35.76916],[-96.315428,35.769725],[-96.294001,35.805716],[-96.270996,35.76916]]]},"properties":{"id":"74010","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.155214,35.551691],[-98.844963,35.551926],[-98.880373,35.595023],[-99.066417,35.711004],[-99.164257,35.580569],[-99.155214,35.551691]]]},"properties":{"id":"73620","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.892935,36.021385],[-99.061908,36.086656],[-99.221875,36.086727],[-99.055603,35.909258],[-98.823054,35.956742],[-98.892935,36.021385]]]},"properties":{"id":"73667","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.725784,36.163263],[-98.955985,36.188374],[-99.062098,36.162285],[-99.061908,36.086656],[-98.892935,36.021385],[-98.853693,36.016153],[-98.725784,36.163263]]]},"properties":{"id":"73663","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.747239,36.520808],[-97.640034,36.491586],[-97.542503,36.563739],[-97.53375,36.59348],[-97.642103,36.593462],[-97.74687,36.593256],[-97.747239,36.520808]]]},"properties":{"id":"74640","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.74687,36.593256],[-97.926115,36.593575],[-97.996834,36.536054],[-97.961447,36.506642],[-97.872857,36.521444],[-97.747239,36.520808],[-97.74687,36.593256]]]},"properties":{"id":"73753","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.102691,34.753128],[-97.282642,34.651809],[-97.317107,34.532214],[-97.054847,34.637066],[-97.002661,34.636803],[-97.102691,34.753128]]]},"properties":{"id":"73098","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.102691,34.753128],[-97.002661,34.636803],[-96.967643,34.636795],[-96.91511,34.723688],[-96.922685,34.911109],[-97.108104,34.840071],[-97.102691,34.753128]]]},"properties":{"id":"74872","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.967643,34.636795],[-96.827288,34.505989],[-96.74041,34.505828],[-96.69502,34.505658],[-96.657673,34.505655],[-96.633369,34.608715],[-96.652382,34.621754],[-96.758528,34.709287],[-96.91511,34.723688],[-96.967643,34.636795]]]},"properties":{"id":"74865","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.802399,34.986093],[-97.670504,34.986045],[-97.617795,34.975225],[-97.547487,35.035589],[-97.564637,35.160607],[-97.670824,35.282993],[-97.758744,35.181988],[-97.793862,35.087674],[-97.802399,34.986093]]]},"properties":{"id":"73010","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.490326,35.347995],[-96.633343,35.108657],[-96.597397,35.05788],[-96.54677,35.057845],[-96.421752,35.290249],[-96.441275,35.348138],[-96.467205,35.348039],[-96.490326,35.347995]]]},"properties":{"id":"74884","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.408639,34.930673],[-96.300445,34.883631],[-96.299957,34.983166],[-96.408639,34.930673]]]},"properties":{"id":"74827","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.229895,34.754464],[-99.405711,34.769025],[-99.423201,34.72502],[-99.382924,34.478958],[-99.219828,34.507148],[-99.229895,34.754464]],[[-99.373836,34.725063],[-99.387115,34.731299],[-99.374259,34.739582],[-99.373836,34.725063]]]},"properties":{"id":"73521","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.006744,36.015126],[-98.111584,36.050523],[-98.210134,35.999476],[-98.209951,35.913076],[-98.13639,35.898591],[-98.014491,35.969336],[-98.006744,36.015126]]]},"properties":{"id":"73756","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.219487,35.116697],[-99.15915,34.847738],[-99.148985,34.798212],[-98.936701,34.942777],[-98.955057,35.116431],[-99.099824,35.116633],[-99.219487,35.116697]]]},"properties":{"id":"73651","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.67328,35.191507],[-94.761161,35.28626],[-94.893039,35.202557],[-94.854225,35.110727],[-94.672819,35.148123],[-94.67328,35.191507]]]},"properties":{"id":"74930","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.67328,35.191507],[-94.672819,35.148123],[-94.646838,35.148074],[-94.629114,35.189107],[-94.67328,35.191507]]]},"properties":{"id":"74951","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.85859,34.695299],[-94.773907,34.656987],[-94.783636,34.618837],[-94.680295,34.612065],[-94.768725,34.679578],[-94.85859,34.695299]]]},"properties":{"id":"74949","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.247096,35.884111],[-97.353066,35.985451],[-97.497308,35.985402],[-97.612019,35.885465],[-97.620628,35.769873],[-97.478388,35.768807],[-97.355312,35.739842],[-97.282825,35.739557],[-97.247096,35.884111]]]},"properties":{"id":"73044","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.284255,36.262428],[-98.194098,36.303976],[-98.229701,36.463408],[-98.319527,36.463239],[-98.368332,36.41977],[-98.451952,36.372111],[-98.328695,36.278218],[-98.284255,36.262428]]]},"properties":{"id":"73768","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.328695,36.278218],[-98.423808,36.216525],[-98.242521,36.196592],[-98.284255,36.262428],[-98.328695,36.278218]]]},"properties":{"id":"73747","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.009785,36.506935],[-97.308468,36.550148],[-97.345516,36.557001],[-97.345481,36.43436],[-97.085667,36.38631],[-97.005201,36.485659],[-97.009785,36.506935]]]},"properties":{"id":"74651","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.43179,36.8876],[-95.68375,36.88753],[-95.701737,36.837876],[-95.431887,36.860405],[-95.43179,36.8876]]]},"properties":{"id":"74042","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512049,35.478101],[-97.512462,35.462513],[-97.502425,35.462587],[-97.49455,35.484547],[-97.511809,35.486],[-97.512049,35.478101]]]},"properties":{"id":"73104","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512456,35.462404],[-97.512514,35.406208],[-97.459302,35.391399],[-97.459196,35.42081],[-97.459169,35.461045],[-97.502425,35.462587],[-97.512462,35.462513],[-97.512456,35.462404]]]},"properties":{"id":"73129","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.632225,35.536935],[-97.67122,35.537199],[-97.671319,35.492956],[-97.619061,35.50051],[-97.632225,35.536935]]]},"properties":{"id":"73008","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.653916,35.406195],[-97.636236,35.394465],[-97.636263,35.406174],[-97.653916,35.406195]]]},"properties":{"id":"73097","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.192495,35.791534],[-96.192689,35.692403],[-96.007093,35.718166],[-96.000969,35.725965],[-95.989372,35.714502],[-95.87291,35.769291],[-96.192491,35.839417],[-96.192495,35.791534]]]},"properties":{"id":"74421","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.299335,36.571105],[-96.435257,36.611666],[-96.246177,36.476073],[-96.299335,36.571105]]]},"properties":{"id":"74084","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.844118,36.503882],[-96.877424,36.579756],[-96.909279,36.59631],[-97.009785,36.506935],[-97.005201,36.485659],[-96.926003,36.461499],[-96.844118,36.503882]]],[[[-96.844118,36.503882],[-96.526159,36.361035],[-96.474252,36.415443],[-96.844118,36.503882]]]]},"properties":{"id":"74650","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.1658,36.666334],[-96.014146,36.599235],[-95.979105,36.670132],[-95.972354,36.800526],[-96.000613,36.834057],[-96.018168,36.832741],[-96.1658,36.666334]]]},"properties":{"id":"74003","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.917218,36.626491],[-95.979105,36.670132],[-96.014146,36.599235],[-96.000719,36.580513],[-95.917218,36.626491]]]},"properties":{"id":"74051","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.636801,35.195201],[-95.615368,35.128984],[-95.597183,35.164144],[-95.636801,35.195201]]]},"properties":{"id":"74425","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.70131,35.129522],[-95.735171,35.192815],[-95.770525,35.181766],[-95.900753,35.146498],[-95.695662,35.105908],[-95.70131,35.129522]]]},"properties":{"id":"74442","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.487152,34.444469],[-95.275073,34.444319],[-95.279951,34.496394],[-95.487152,34.444469]]]},"properties":{"id":"74567","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.942857,35.906646],[-100.000396,35.880948],[-100.000393,35.745219],[-99.979364,35.757755],[-99.789981,35.711599],[-99.909938,35.926974],[-99.942857,35.906646]]]},"properties":{"id":"73642","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.000391,35.552456],[-99.99966,35.422364],[-99.858543,35.422142],[-99.793121,35.422139],[-99.858427,35.523644],[-100.000391,35.552456]]]},"properties":{"id":"73666","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.7584,36.32186],[-95.744186,36.265665],[-95.605015,36.225672],[-95.43552,36.26381],[-95.435533,36.379449],[-95.752407,36.34725],[-95.7584,36.32186]]]},"properties":{"id":"74019","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.872197,36.218699],[-95.869132,36.161046],[-95.833368,36.158994],[-95.779617,36.160624],[-95.81527,36.22445],[-95.851685,36.223788],[-95.872197,36.218699]]]},"properties":{"id":"74116","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.833163,35.507026],[-94.944608,35.402457],[-94.934239,35.395509],[-94.73782,35.354648],[-94.679555,35.427547],[-94.594475,35.638265],[-94.629908,35.638295],[-94.753695,35.638579],[-94.833163,35.507026]]]},"properties":{"id":"74955","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.632505,36.994915],[-101.583013,36.49952],[-101.340063,36.499566],[-101.241876,36.709357],[-101.428791,36.995964],[-101.632505,36.994915]]]},"properties":{"id":"73942","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.206199,34.338335],[-98.836416,34.275962],[-98.836351,34.333777],[-98.7168,34.391093],[-98.731633,34.507516],[-98.826103,34.543671],[-98.931063,34.56548],[-98.931108,34.550969],[-98.923414,34.464217],[-98.99404,34.475339],[-99.202776,34.369888],[-99.206199,34.338335]],[[-98.836351,34.333777],[-98.923315,34.334036],[-98.906325,34.363046],[-98.836336,34.377315],[-98.836351,34.333777]]]},"properties":{"id":"73542","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.904335,36.002804],[-95.908609,36.046423],[-95.974472,36.046295],[-95.931052,35.988313],[-95.904335,36.002804]]]},"properties":{"id":"74137","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.77965,36.090202],[-95.833218,36.075569],[-95.851195,36.07539],[-95.850972,36.017315],[-95.76165,36.017439],[-95.77965,36.090202]]]},"properties":{"id":"74012","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.833222,36.133445],[-95.761664,36.133471],[-95.75245,36.148009],[-95.779617,36.160624],[-95.833368,36.158994],[-95.833222,36.133445]]]},"properties":{"id":"74108","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.833236,36.118926],[-95.833218,36.075569],[-95.77965,36.090202],[-95.761664,36.133471],[-95.833222,36.133445],[-95.833236,36.118926]]]},"properties":{"id":"74134","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.057583,36.075469],[-96.044624,36.075445],[-95.986073,36.075194],[-95.986974,36.118993],[-95.992178,36.131829],[-96.083378,36.130889],[-96.101049,36.078494],[-96.096769,36.075534],[-96.063201,36.075496],[-96.057583,36.075469]]]},"properties":{"id":"74107","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.908588,36.075371],[-95.904541,36.118897],[-95.904558,36.12116],[-95.940271,36.118766],[-95.940123,36.075327],[-95.908588,36.075371]]]},"properties":{"id":"74135","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.869877,36.118963],[-95.904541,36.118897],[-95.908588,36.075371],[-95.90433,36.075388],[-95.870944,36.075432],[-95.869877,36.118963]]]},"properties":{"id":"74145","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.902561,36.264038],[-95.936872,36.249574],[-95.945275,36.225061],[-95.945038,36.225081],[-95.872197,36.218699],[-95.851685,36.223788],[-95.902561,36.264038]]]},"properties":{"id":"74117","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.597702,35.894175],[-95.601135,35.878513],[-95.579719,35.885689],[-95.597702,35.894175]]]},"properties":{"id":"74458","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.170164,35.320327],[-99.152515,35.378384],[-99.187905,35.378386],[-99.187989,35.320362],[-99.223363,35.320375],[-99.170164,35.320327]]]},"properties":{"id":"73624","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.66627,36.6089],[-98.540502,36.623168],[-98.54059,36.681215],[-98.64826,36.688613],[-98.6482,36.681416],[-98.66627,36.6089]]]},"properties":{"id":"73731","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.295283,36.638221],[-99.264165,36.34837],[-99.050757,36.237076],[-98.960754,36.347935],[-98.960486,36.402601],[-99.024156,36.661591],[-99.295283,36.638221]]]},"properties":{"id":"73852","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.774113,35.959627],[-94.732508,36.074472],[-94.797285,36.104426],[-94.814449,36.111317],[-94.797342,35.961787],[-94.774113,35.959627]]]},"properties":{"id":"74457","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.912917,36.998616],[-98.219484,36.997919],[-98.201552,36.956745],[-98.109747,36.854086],[-97.912917,36.998616]]]},"properties":{"id":"73758","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.265474,36.999527],[-98.970924,36.739827],[-98.729288,36.557292],[-98.69352,36.549959],[-98.66627,36.6089],[-98.6482,36.681416],[-98.66624,36.681397],[-98.666228,36.688563],[-98.64826,36.688613],[-98.54059,36.681215],[-98.485976,36.819016],[-98.503474,36.99867],[-99.265474,36.999527]]]},"properties":{"id":"73717","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.413552,34.160459],[-96.565989,34.160336],[-96.573763,34.114436],[-96.401162,34.142741],[-96.372795,34.216311],[-96.413552,34.160459]]]},"properties":{"id":"74748","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.61553,36.499457],[-100.44091,36.49949],[-100.192295,36.76535],[-100.366694,36.860167],[-100.70336,36.83541],[-100.844508,36.759501],[-100.61553,36.499457]]]},"properties":{"id":"73932","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.313252,35.718047],[-98.373718,35.765328],[-98.472796,35.710741],[-98.313252,35.718047]]]},"properties":{"id":"73043","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.535483,33.91102],[-96.534273,33.904738],[-96.528472,33.894535],[-96.411279,33.838466],[-96.320429,33.841054],[-96.465639,33.969029],[-96.535534,33.946334],[-96.545905,33.924384],[-96.535483,33.91102]]]},"properties":{"id":"74730","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.354616,35.188341],[-98.253908,35.354316],[-98.277579,35.357776],[-98.500266,35.290716],[-98.482613,35.247591],[-98.354616,35.188341]]]},"properties":{"id":"73009","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.689145,35.420599],[-97.813027,35.406207],[-97.830795,35.391662],[-97.833675,35.335362],[-97.671595,35.335934],[-97.671529,35.337508],[-97.671369,35.377151],[-97.671374,35.391654],[-97.689145,35.420599]]]},"properties":{"id":"73064","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.579908,34.466994],[-97.597512,34.405272],[-97.562709,34.332579],[-97.444138,34.376314],[-97.447639,34.390793],[-97.422301,34.448818],[-97.477749,34.506784],[-97.528017,34.533589],[-97.563112,34.52159],[-97.579877,34.469251],[-97.579908,34.466994]],[[-97.564434,34.445076],[-97.5623,34.463254],[-97.544926,34.463302],[-97.54492,34.445301],[-97.564434,34.445076]]]},"properties":{"id":"73481","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.515689,34.187437],[-97.484163,33.91551],[-97.453563,33.895222],[-97.439415,33.934],[-97.369488,33.998495],[-97.294494,34.07129],[-97.317501,34.230949],[-97.35213,34.274495],[-97.387166,34.332608],[-97.422249,34.332635],[-97.515689,34.187437]]]},"properties":{"id":"73463","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.477749,34.506784],[-97.422301,34.448818],[-97.404681,34.455968],[-97.457169,34.506927],[-97.477749,34.506784]]]},"properties":{"id":"73487","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.00233,36.603129],[-103.00229,36.500887],[-102.570696,36.500406],[-103.00233,36.603129]]]},"properties":{"id":"73937","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.14199,35.130725],[-97.142215,35.188915],[-97.142184,35.192086],[-97.388252,35.175018],[-97.407822,35.160662],[-97.42754,35.142917],[-97.404381,35.1118],[-97.380346,35.083259],[-97.14199,35.130725]]]},"properties":{"id":"73068","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.53906,35.404608],[-97.537536,35.363293],[-97.512168,35.363381],[-97.512235,35.377213],[-97.512514,35.406208],[-97.539069,35.406224],[-97.53906,35.404608]]]},"properties":{"id":"73139","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.580653,35.363062],[-97.618505,35.377116],[-97.671369,35.377151],[-97.671529,35.337508],[-97.599091,35.303351],[-97.580653,35.363062]]]},"properties":{"id":"73173","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.145368,34.854879],[-98.265552,34.855135],[-98.316974,34.827659],[-98.194129,34.709339],[-98.159323,34.724686],[-98.159303,34.739228],[-98.188331,34.739143],[-98.187341,34.753667],[-98.124145,34.753686],[-98.141778,34.724641],[-98.124176,34.695691],[-98.089085,34.753635],[-98.08901,34.834806],[-98.145368,34.854879]]]},"properties":{"id":"73541","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.301349,34.202518],[-98.452771,34.275396],[-98.46408,34.073775],[-98.267664,34.131853],[-98.283745,34.166256],[-98.301349,34.202518]]]},"properties":{"id":"73562","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.39569,36.89857],[-95.238274,36.815245],[-95.01729,36.85928],[-95.00762,36.999514],[-95.40762,36.999311],[-95.39569,36.89857]]]},"properties":{"id":"74369","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.063201,36.075496],[-96.096769,36.075534],[-96.047539,36.023251],[-96.044624,36.075445],[-96.057583,36.075469],[-96.0658,36.068253],[-96.069847,36.074345],[-96.063201,36.075496]]]},"properties":{"id":"74131","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.029477,35.959198],[-96.061205,35.915638],[-96.029543,35.91563],[-96.029477,35.959198]]]},"properties":{"id":"74041","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.512352,35.638985],[-96.565571,35.755145],[-96.547702,35.751128],[-96.565596,35.74459],[-96.619295,35.872408],[-96.823139,35.840538],[-96.836983,35.811356],[-96.764935,35.740223],[-96.751474,35.709638],[-96.698041,35.666225],[-96.662024,35.608231],[-96.621073,35.610148],[-96.512352,35.638985]]]},"properties":{"id":"74079","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.384096,36.196311],[-96.499781,36.219801],[-96.460899,36.129117],[-96.384096,36.196311]]]},"properties":{"id":"74081","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.605795,35.826343],[-99.570487,35.740429],[-99.305744,35.703882],[-99.146305,35.783327],[-99.110641,35.812412],[-99.120006,35.865672],[-99.381022,36.012925],[-99.45189,36.005508],[-99.610942,35.847454],[-99.605795,35.826343]]]},"properties":{"id":"73654","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.005749,36.47697],[-95.005615,36.347556],[-94.930059,36.335603],[-94.879818,36.347839],[-94.957517,36.515146],[-95.005718,36.491773],[-95.005749,36.47697]]]},"properties":{"id":"74342","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.832788,36.161542],[-94.851086,36.205195],[-94.869048,36.205153],[-94.868591,36.161491],[-94.832788,36.161542]]]},"properties":{"id":"74359","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.880166,35.812602],[-98.702502,35.812518],[-98.776454,35.878974],[-98.823054,35.956742],[-99.055603,35.909258],[-99.120006,35.865672],[-99.110641,35.812412],[-98.880166,35.812602]]]},"properties":{"id":"73659","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.621993,36.317787],[-97.747248,36.274952],[-97.782059,36.231296],[-97.760129,36.188409],[-97.604119,36.260409],[-97.621993,36.317787]]]},"properties":{"id":"73733","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.407023,36.231639],[-97.444039,36.390637],[-97.46119,36.390371],[-97.461094,36.347085],[-97.532883,36.274995],[-97.514385,36.2317],[-97.407023,36.231639]]]},"properties":{"id":"73757","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514385,36.2317],[-97.533032,36.11608],[-97.26482,36.086895],[-97.282667,36.159297],[-97.407023,36.231639],[-97.514385,36.2317]]]},"properties":{"id":"73073","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.110473,36.767649],[-98.109311,36.593588],[-98.104205,36.550066],[-98.032908,36.564838],[-98.03293,36.593522],[-97.893536,36.730871],[-97.965919,36.796401],[-98.110473,36.767649]]]},"properties":{"id":"73761","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.897252,36.332837],[-97.89612,36.338931],[-97.907536,36.345934],[-97.907636,36.332927],[-97.897252,36.332837]]]},"properties":{"id":"73705","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.964976,34.938149],[-97.142349,34.928176],[-97.254319,34.945365],[-97.14233,34.855081],[-97.108104,34.840071],[-96.922685,34.911109],[-96.887362,34.926849],[-96.964976,34.938149]]]},"properties":{"id":"74831","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820413,34.986064],[-97.793962,34.913246],[-97.70325,34.900395],[-97.670504,34.986045],[-97.802399,34.986093],[-97.820413,34.986064]]]},"properties":{"id":"73002","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.806486,34.521569],[-99.912398,34.845151],[-100.000381,34.854386],[-99.844585,34.506915],[-99.806486,34.521569]]]},"properties":{"id":"73550","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.605114,36.521917],[-99.488795,36.16449],[-99.453619,36.163926],[-99.381896,36.202815],[-99.264229,36.333898],[-99.264165,36.34837],[-99.295283,36.638221],[-99.367001,36.762229],[-99.572327,36.661606],[-99.592313,36.592821],[-99.605114,36.521917]]]},"properties":{"id":"73801","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.893039,35.202557],[-94.761161,35.28626],[-94.69086,35.329869],[-94.73782,35.354648],[-94.934239,35.395509],[-94.990964,35.214321],[-94.893039,35.202557]]]},"properties":{"id":"74941","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.299957,34.983166],[-96.171192,35.028774],[-96.15937,35.093249],[-96.388064,35.290141],[-96.421752,35.290249],[-96.54677,35.057845],[-96.490786,34.910585],[-96.408639,34.930673],[-96.299957,34.983166]]]},"properties":{"id":"74848","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.373836,34.725063],[-99.374259,34.739582],[-99.387115,34.731299],[-99.373836,34.725063]]]},"properties":{"id":"73556","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.162085,34.739445],[-99.229895,34.754464],[-99.219828,34.507148],[-99.208029,34.507134],[-99.082657,34.59278],[-99.10315,34.63795],[-99.162085,34.739445]]]},"properties":{"id":"73549","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.999858,34.289051],[-97.959737,34.245545],[-97.964954,34.289126],[-97.999858,34.289051]]]},"properties":{"id":"73520","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.771932,34.259667],[-96.881005,34.332977],[-96.933457,34.332661],[-96.933671,34.172553],[-96.752551,34.172547],[-96.733255,34.205122],[-96.771932,34.259667]]]},"properties":{"id":"73447","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.881005,34.332977],[-96.771932,34.259667],[-96.744444,34.286341],[-96.715922,34.419245],[-96.74041,34.505828],[-96.827288,34.505989],[-96.881005,34.332977]]]},"properties":{"id":"74856","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.928638,36.810863],[-96.905861,36.737129],[-96.819974,36.765052],[-96.828609,36.818054],[-96.928638,36.810863]]]},"properties":{"id":"74641","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.782457,36.16462],[-97.988243,36.1648],[-98.077475,36.16501],[-98.210544,36.16489],[-98.111584,36.050523],[-98.006744,36.015126],[-97.747378,36.036355],[-97.675487,36.072779],[-97.782457,36.16462]]]},"properties":{"id":"73742","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.82624,34.696034],[-99.10315,34.63795],[-99.082657,34.59278],[-98.931063,34.56548],[-98.826103,34.543671],[-98.82624,34.696034]]]},"properties":{"id":"73566","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.717621,34.807078],[-94.456083,34.681563],[-94.447465,34.941921],[-94.522958,34.956421],[-94.781566,34.920404],[-94.717621,34.807078]]]},"properties":{"id":"74937","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.990057,34.507088],[-94.937605,34.506831],[-94.832774,34.593907],[-94.990089,34.59375],[-95.010509,34.549818],[-94.990057,34.507088]]]},"properties":{"id":"74549","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.698041,35.666225],[-96.840061,35.564853],[-96.771232,35.550346],[-96.662024,35.608231],[-96.698041,35.666225]]]},"properties":{"id":"74869","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.194397,35.782827],[-97.264958,35.73946],[-97.264878,35.63804],[-97.194245,35.579614],[-97.140963,35.579535],[-97.194397,35.782827]]]},"properties":{"id":"73054","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.670824,35.282993],[-97.564637,35.160607],[-97.559281,35.290686],[-97.599091,35.303351],[-97.671529,35.337508],[-97.671595,35.335934],[-97.670824,35.282993]]]},"properties":{"id":"73065","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.342898,34.971496],[-97.423927,34.942535],[-97.335907,34.855274],[-97.195128,34.855231],[-97.14233,34.855081],[-97.254319,34.945365],[-97.342898,34.971496]]]},"properties":{"id":"73095","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.156506,34.120672],[-95.104427,34.149775],[-95.156487,34.14245],[-95.156506,34.120672]]]},"properties":{"id":"74755","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.229701,36.463408],[-98.194098,36.303976],[-98.104418,36.272626],[-98.104477,36.318284],[-98.140283,36.463133],[-98.211741,36.463338],[-98.229701,36.463408]]]},"properties":{"id":"73760","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.55702,36.402065],[-98.960486,36.402601],[-98.960754,36.347935],[-98.725758,36.202481],[-98.501111,36.165067],[-98.423808,36.216525],[-98.328695,36.278218],[-98.451952,36.372111],[-98.55702,36.402065]]]},"properties":{"id":"73737","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.62488,34.007571],[-96.880895,33.997932],[-96.897382,33.918643],[-96.576574,33.960467],[-96.62488,34.007571]]]},"properties":{"id":"73439","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.618551,36.162508],[-95.523052,36.046062],[-95.430666,36.075201],[-95.44002,36.20597],[-95.43552,36.26381],[-95.605015,36.225672],[-95.618551,36.162508]]]},"properties":{"id":"74036","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.245494,36.220427],[-95.44002,36.20597],[-95.430666,36.075201],[-95.312257,36.035322],[-95.245494,36.220427]]]},"properties":{"id":"74337","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.025261,36.442226],[-95.005749,36.47697],[-95.005718,36.491773],[-95.005719,36.491925],[-95.040702,36.468577],[-95.025261,36.442226]]]},"properties":{"id":"74340","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.123529,36.37732],[-95.103419,36.45217],[-95.14085,36.442323],[-95.166099,36.41517],[-95.123529,36.37732]]]},"properties":{"id":"74367","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.393164,35.551759],[-95.360116,35.580731],[-95.383031,35.624341],[-95.571034,35.653356],[-95.561029,35.624266],[-95.571064,35.595233],[-95.586133,35.551714],[-95.492062,35.551897],[-95.485386,35.551926],[-95.393164,35.551759]]]},"properties":{"id":"74450","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.561029,35.624266],[-95.588892,35.624261],[-95.571064,35.595233],[-95.561029,35.624266]]]},"properties":{"id":"74468","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.431887,36.860405],[-95.701737,36.837876],[-95.74384,36.82269],[-95.521657,36.754339],[-95.431887,36.860405]]]},"properties":{"id":"74027","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.621073,35.610148],[-96.623699,35.400722],[-96.535146,35.391371],[-96.529665,35.444458],[-96.47686,35.595375],[-96.482487,35.639061],[-96.487881,35.639075],[-96.512352,35.638985],[-96.621073,35.610148]]]},"properties":{"id":"74860","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.388064,35.290141],[-96.242102,35.341506],[-96.123293,35.514871],[-96.087755,35.519997],[-96.192563,35.639087],[-96.35378,35.638989],[-96.405974,35.488933],[-96.529665,35.444458],[-96.535146,35.391371],[-96.490326,35.347995],[-96.467205,35.348039],[-96.458991,35.377262],[-96.441303,35.377324],[-96.441275,35.348138],[-96.421752,35.290249],[-96.388064,35.290141]]]},"properties":{"id":"74859","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.601163,35.500529],[-97.60285,35.536819],[-97.603553,35.536819],[-97.632225,35.536935],[-97.619061,35.50051],[-97.601161,35.500061],[-97.601163,35.500529]]]},"properties":{"id":"73122","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.264878,35.63804],[-97.371659,35.647787],[-97.371547,35.625771],[-97.398147,35.584025],[-97.39829,35.55828],[-97.388576,35.517852],[-97.30049,35.536672],[-97.221216,35.536314],[-97.194245,35.579614],[-97.264878,35.63804]]]},"properties":{"id":"73049","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.962467,35.458222],[-95.936292,35.454907],[-95.929092,35.483481],[-95.956825,35.48151],[-95.962467,35.458222]]]},"properties":{"id":"74431","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.1658,36.666334],[-96.018168,36.832741],[-96.109674,36.999194],[-96.526161,36.998713],[-96.564708,36.680658],[-96.542015,36.561113],[-96.435257,36.611666],[-96.299335,36.571105],[-96.1658,36.666334]]]},"properties":{"id":"74056","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.00137,36.205917],[-96.001055,36.161294],[-95.99346,36.163464],[-95.967113,36.164489],[-95.965101,36.214871],[-95.96934,36.220575],[-96.00137,36.205917]]]},"properties":{"id":"74106","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.87755,36.91997],[-94.87754,36.91914],[-94.853383,36.943607],[-94.90443,36.943527],[-94.87755,36.91997]]]},"properties":{"id":"74339","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.282667,36.159297],[-97.26482,36.086895],[-97.247041,36.057925],[-97.157951,36.028854],[-96.944786,36.040066],[-96.819677,36.072154],[-96.867388,36.116175],[-96.890508,36.130998],[-97.067695,36.123441],[-97.073748,36.119655],[-97.075715,36.12679],[-97.23806,36.159319],[-97.282667,36.159297]]]},"properties":{"id":"74074","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.001081,34.869041],[-95.881978,34.782065],[-95.881738,34.782053],[-95.855937,34.830751],[-95.81186,34.811169],[-95.776192,34.787354],[-95.749928,34.762021],[-95.586257,34.851861],[-95.588499,34.869232],[-95.507774,34.948985],[-95.481046,35.029263],[-95.568697,35.103981],[-95.615368,35.128984],[-95.636801,35.195201],[-95.622598,35.215191],[-95.718271,35.188894],[-95.735171,35.192815],[-95.70131,35.129522],[-95.633424,35.144943],[-95.639361,35.109305],[-95.695662,35.105908],[-95.900753,35.146498],[-95.965551,35.152496],[-95.990279,35.150838],[-96.088843,35.049863],[-96.001081,34.869041]],[[-95.707283,34.905912],[-95.718839,34.93987],[-95.683728,34.925994],[-95.688467,34.907752],[-95.690632,34.903997],[-95.707283,34.905912]]]},"properties":{"id":"74501","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.688467,34.907752],[-95.683728,34.925994],[-95.718839,34.93987],[-95.707283,34.905912],[-95.688467,34.907752]]]},"properties":{"id":"74554","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.695662,35.105908],[-95.639361,35.109305],[-95.633424,35.144943],[-95.70131,35.129522],[-95.695662,35.105908]]]},"properties":{"id":"74430","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.823948,35.421855],[-96.982855,35.463519],[-97.065593,35.362073],[-96.807189,35.347563],[-96.776695,35.410886],[-96.823948,35.421855]]]},"properties":{"id":"74804","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.776794,35.195427],[-96.715715,35.41336],[-96.776695,35.410886],[-96.807189,35.347563],[-96.843413,35.259299],[-96.776794,35.195427]]]},"properties":{"id":"74840","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.089198,35.289656],[-96.843413,35.259299],[-96.807189,35.347563],[-97.065593,35.362073],[-97.089198,35.289656]]]},"properties":{"id":"74801","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.460414,35.404923],[-94.43572,35.419475],[-94.441123,35.450175],[-94.460414,35.404923]]]},"properties":{"id":"74954","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.833163,35.507026],[-94.753695,35.638579],[-94.829001,35.638618],[-94.854159,35.638699],[-94.833163,35.507026]]]},"properties":{"id":"74945","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.609598,34.304502],[-98.836416,34.275962],[-98.836182,34.156764],[-98.610158,34.157098],[-98.609656,34.246249],[-98.609598,34.304502]]]},"properties":{"id":"73546","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.99404,34.475339],[-98.923414,34.464217],[-98.931108,34.550969],[-98.99404,34.475339]]]},"properties":{"id":"73555","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.931052,35.988313],[-95.974472,36.046295],[-95.976272,36.050094],[-96.011898,36.015579],[-96.011836,35.988384],[-95.979386,35.973712],[-95.931052,35.988313]]]},"properties":{"id":"74037","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.869877,36.118963],[-95.870944,36.075432],[-95.851195,36.07539],[-95.833218,36.075569],[-95.833236,36.118926],[-95.869877,36.118963]]]},"properties":{"id":"74146","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.94033,36.159359],[-95.940355,36.133236],[-95.906903,36.133403],[-95.870297,36.133471],[-95.875031,36.160748],[-95.940324,36.159589],[-95.94033,36.159359]]]},"properties":{"id":"74112","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.76165,36.017439],[-95.761463,35.918],[-95.76147,35.915313],[-95.726456,35.903206],[-95.523052,36.046062],[-95.618551,36.162508],[-95.75245,36.148009],[-95.761664,36.133471],[-95.77965,36.090202],[-95.76165,36.017439]]]},"properties":{"id":"74014","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.964845,36.263355],[-95.96934,36.220575],[-95.965101,36.214871],[-95.945275,36.225061],[-95.936872,36.249574],[-95.964845,36.263355]]]},"properties":{"id":"74130","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.523052,36.046062],[-95.726456,35.903206],[-95.638912,35.822599],[-95.601135,35.878513],[-95.597702,35.894175],[-95.494256,35.955759],[-95.523052,36.046062]]]},"properties":{"id":"74429","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.807534,35.799739],[-94.629908,35.638295],[-94.594475,35.638265],[-94.477907,35.669028],[-94.519152,35.911302],[-94.774113,35.959627],[-94.797342,35.961787],[-94.840775,35.918846],[-94.807534,35.799739]]]},"properties":{"id":"74960","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.73522,36.161675],[-94.833174,36.219577],[-94.833163,36.212423],[-94.851086,36.205195],[-94.832788,36.161542],[-94.814449,36.111317],[-94.797285,36.104426],[-94.73522,36.161675]]]},"properties":{"id":"74347","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.068782,36.463177],[-98.104205,36.550066],[-98.109311,36.593588],[-98.140274,36.593619],[-98.211741,36.463338],[-98.140283,36.463133],[-98.068782,36.463177]]]},"properties":{"id":"73739","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.219484,36.997919],[-98.363776,36.998034],[-98.359712,36.928015],[-98.334132,36.898767],[-98.201552,36.956745],[-98.219484,36.997919]]]},"properties":{"id":"73719","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.81186,34.811169],[-95.881738,34.782053],[-95.881978,34.782065],[-95.994728,34.753014],[-95.980548,34.734784],[-96.010157,34.583579],[-95.974378,34.603803],[-95.812089,34.738312],[-95.776192,34.787354],[-95.81186,34.811169]]]},"properties":{"id":"74553","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.99966,35.422364],[-100.000384,35.030385],[-99.888571,35.030262],[-99.711464,35.116778],[-99.858543,35.422142],[-99.99966,35.422364]]]},"properties":{"id":"73645","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.320429,33.841054],[-96.411279,33.838466],[-96.409489,33.783297],[-96.313256,33.839173],[-96.320429,33.841054]]]},"properties":{"id":"74720","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.520245,35.233095],[-98.497822,34.971023],[-98.391945,35.014775],[-98.332714,35.120444],[-98.354616,35.188341],[-98.482613,35.247591],[-98.520245,35.233095]]]},"properties":{"id":"73038","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.497822,34.971023],[-98.545351,34.855104],[-98.422222,34.811684],[-98.316974,34.827659],[-98.265552,34.855135],[-98.268811,34.934816],[-98.391945,35.014775],[-98.497822,34.971023]]]},"properties":{"id":"73006","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.179401,35.174843],[-98.092989,35.064887],[-98.056682,35.160199],[-98.1532,35.189345],[-98.179401,35.174843]]]},"properties":{"id":"73092","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.265552,34.855135],[-98.145368,34.854879],[-98.180807,34.970849],[-98.268811,34.934816],[-98.265552,34.855135]]]},"properties":{"id":"73029","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.178819,35.725106],[-98.034303,35.623846],[-97.86963,35.609459],[-97.834064,35.652931],[-97.763439,35.725746],[-97.763487,35.754635],[-98.178819,35.725106]]]},"properties":{"id":"73762","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.562575,34.289182],[-97.772205,34.289236],[-97.720349,34.089073],[-97.676981,34.042209],[-97.630676,33.979289],[-97.484163,33.91551],[-97.515689,34.187437],[-97.562575,34.289182]]]},"properties":{"id":"73456","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.352693,34.506851],[-97.457169,34.506927],[-97.404681,34.455968],[-97.318949,34.376215],[-97.352693,34.506851]]]},"properties":{"id":"73444","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.317501,34.230949],[-97.294494,34.07129],[-97.265097,34.071203],[-97.317501,34.230949]]]},"properties":{"id":"73443","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.570696,36.500406],[-102.1438,36.500325],[-102.247784,36.675609],[-102.252199,36.99417],[-102.578655,36.995127],[-102.906951,36.848676],[-103.002069,36.906472],[-103.00233,36.603129],[-102.570696,36.500406]]]},"properties":{"id":"73933","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.252199,36.99417],[-102.247784,36.675609],[-102.031855,36.791307],[-102.028204,36.993102],[-102.252199,36.99417]]]},"properties":{"id":"73947","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.194841,35.444242],[-97.264966,35.362818],[-97.274402,35.323378],[-97.141949,35.247361],[-97.124419,35.275269],[-97.141796,35.420424],[-97.194841,35.444242]]]},"properties":{"id":"74857","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.489848,35.302276],[-97.445355,35.207461],[-97.447899,35.211101],[-97.441238,35.211115],[-97.441235,35.20386],[-97.430683,35.200322],[-97.441865,35.298265],[-97.489848,35.302276]]]},"properties":{"id":"73069","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.266923,35.372667],[-97.388238,35.377363],[-97.441384,35.363205],[-97.423604,35.305285],[-97.388255,35.305283],[-97.274402,35.323378],[-97.264966,35.362818],[-97.266923,35.372667]]]},"properties":{"id":"73165","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.41421,34.547954],[-96.514139,34.463188],[-96.442015,34.41838],[-96.354648,34.418373],[-96.151524,34.418676],[-96.216243,34.46211],[-96.145326,34.491003],[-96.092061,34.585888],[-96.092043,34.680556],[-96.196626,34.766997],[-96.292838,34.766804],[-96.336966,34.679835],[-96.377521,34.549077],[-96.41421,34.547954]],[[-96.409935,34.476425],[-96.46239,34.463211],[-96.458165,34.512668],[-96.409935,34.476425]]]},"properties":{"id":"74538","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.82624,34.696034],[-98.826103,34.543671],[-98.731633,34.507516],[-98.729279,34.521892],[-98.655121,34.638121],[-98.630062,34.68163],[-98.614918,34.709779],[-98.808795,34.739683],[-98.82624,34.696034]]]},"properties":{"id":"73552","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.238274,36.815245],[-95.054062,36.728817],[-94.96334,36.75769],[-95.01729,36.85928],[-95.238274,36.815245]]]},"properties":{"id":"74333","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.677819,36.224965],[-96.622549,36.099563],[-96.548283,36.075665],[-96.474166,36.042192],[-96.460899,36.129117],[-96.499781,36.219801],[-96.553285,36.231815],[-96.570307,36.286381],[-96.572358,36.281951],[-96.677819,36.224965]],[[-96.553285,36.231815],[-96.553185,36.21962],[-96.606328,36.217441],[-96.606338,36.231548],[-96.553285,36.231815]]]},"properties":{"id":"74038","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.061205,35.915638],[-96.029477,35.959198],[-96.011836,35.988384],[-96.011898,36.015579],[-96.047539,36.023251],[-96.096769,36.075534],[-96.101049,36.078494],[-96.320586,36.035579],[-96.336939,36.00547],[-96.301446,35.969248],[-96.159545,35.85718],[-96.061205,35.915638]]]},"properties":{"id":"74066","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.619295,35.872408],[-96.565596,35.74459],[-96.565571,35.755145],[-96.512352,35.638985],[-96.487881,35.639075],[-96.557614,35.914028],[-96.564354,35.912215],[-96.572831,35.901105],[-96.58398,35.904749],[-96.619297,35.876609],[-96.619295,35.872408]]]},"properties":{"id":"74028","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.103162,36.256022],[-96.201752,36.292078],[-96.250288,36.219981],[-96.297888,36.162279],[-96.320586,36.035579],[-96.101049,36.078494],[-96.083378,36.130889],[-96.090588,36.216248],[-96.103162,36.256022]]]},"properties":{"id":"74063","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.270996,35.76916],[-96.294001,35.805716],[-96.315428,35.769725],[-96.270996,35.76916]]]},"properties":{"id":"74071","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.844963,35.551926],[-99.155214,35.551691],[-99.028954,35.421853],[-98.87394,35.378277],[-98.835037,35.465078],[-98.844963,35.551926]]]},"properties":{"id":"73601","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.996819,36.593605],[-98.032866,36.550303],[-97.996834,36.536054],[-97.926115,36.593575],[-97.996819,36.593605]]],[[[-97.872353,36.318401],[-97.872857,36.521444],[-97.961447,36.506642],[-98.068887,36.448692],[-98.033014,36.36177],[-98.014945,36.318245],[-97.907636,36.332927],[-97.907536,36.345934],[-97.89612,36.338931],[-97.897252,36.332837],[-97.872353,36.318401]]]]},"properties":{"id":"73703","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.872857,36.521444],[-97.872353,36.318401],[-97.783024,36.318047],[-97.658008,36.419325],[-97.640034,36.491586],[-97.747239,36.520808],[-97.872857,36.521444]]]},"properties":{"id":"73701","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.586332,36.368855],[-97.621993,36.317787],[-97.604119,36.260409],[-97.532883,36.274995],[-97.461094,36.347085],[-97.586332,36.368855]]]},"properties":{"id":"73730","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.70325,34.900395],[-97.687957,34.777324],[-97.724769,34.715561],[-97.668483,34.693492],[-97.527834,34.695549],[-97.492802,34.746391],[-97.494503,34.942541],[-97.617795,34.975225],[-97.670504,34.986045],[-97.70325,34.900395]]]},"properties":{"id":"73052","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.687555,34.615655],[-97.563112,34.52159],[-97.528017,34.533589],[-97.527834,34.695549],[-97.668483,34.693492],[-97.687555,34.615655]]]},"properties":{"id":"73434","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.195128,34.855231],[-97.350693,34.831101],[-97.398886,34.737665],[-97.282642,34.651809],[-97.102691,34.753128],[-97.108104,34.840071],[-97.14233,34.855081],[-97.195128,34.855231]]]},"properties":{"id":"73075","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.70325,34.900395],[-97.793962,34.913246],[-97.834993,34.81217],[-97.687957,34.777324],[-97.70325,34.900395]]]},"properties":{"id":"73011","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.961308,35.145735],[-97.793862,35.087674],[-97.758744,35.181988],[-97.882548,35.203645],[-97.961308,35.145735]]]},"properties":{"id":"73004","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.893536,36.730871],[-97.669206,36.697489],[-97.588308,36.767214],[-97.480471,36.926753],[-97.46246,36.926859],[-97.462346,36.998701],[-97.876452,36.998657],[-97.965919,36.796401],[-97.893536,36.730871]]]},"properties":{"id":"73759","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.723217,34.85698],[-99.888571,35.030262],[-100.000384,35.030385],[-100.000381,34.854386],[-99.912398,34.845151],[-99.723217,34.85698]]]},"properties":{"id":"73571","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.764409,36.827308],[-99.572327,36.661606],[-99.367001,36.762229],[-99.456203,36.999471],[-99.774192,37.000976],[-99.764409,36.827308]]]},"properties":{"id":"73834","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.04731,35.057658],[-94.881759,35.099565],[-94.854225,35.110727],[-94.893039,35.202557],[-94.990964,35.214321],[-95.145401,35.150851],[-95.178817,35.057897],[-95.10357,35.057523],[-95.121261,35.103809],[-95.023369,35.096146],[-95.04731,35.057658]]]},"properties":{"id":"74944","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.088843,35.049863],[-95.990279,35.150838],[-96.071963,35.188754],[-96.15937,35.093249],[-96.171192,35.028774],[-96.132189,35.014295],[-96.088843,35.049863]]]},"properties":{"id":"74850","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.219828,34.507148],[-99.382924,34.478958],[-99.355404,34.448866],[-99.206905,34.338277],[-99.206199,34.338335],[-99.202776,34.369888],[-99.208029,34.507134],[-99.219828,34.507148]]]},"properties":{"id":"73539","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.675487,36.072779],[-97.747378,36.036355],[-97.729215,35.907193],[-97.714706,35.885466],[-97.612019,35.885465],[-97.497308,35.985402],[-97.559615,36.086946],[-97.675487,36.072779]]]},"properties":{"id":"73028","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927719,34.985659],[-94.8781,35.029211],[-94.881759,35.099565],[-95.04731,35.057658],[-95.10357,35.057523],[-95.178817,35.057897],[-95.208124,35.057897],[-95.147514,34.854252],[-94.927684,34.950092],[-94.927719,34.985659]]]},"properties":{"id":"74563","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.434024,35.317408],[-94.466842,35.312767],[-94.549309,35.208391],[-94.438824,35.202043],[-94.434024,35.317408]]]},"properties":{"id":"74902","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.629114,35.189107],[-94.549309,35.208391],[-94.466842,35.312767],[-94.494738,35.332276],[-94.621156,35.300749],[-94.69086,35.329869],[-94.761161,35.28626],[-94.67328,35.191507],[-94.629114,35.189107]]]},"properties":{"id":"74959","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.751474,35.709638],[-96.76822,35.69523],[-96.764935,35.740223],[-96.836983,35.811356],[-96.894071,35.811603],[-96.947034,35.811846],[-97.034573,35.796936],[-96.981544,35.601055],[-96.840061,35.564853],[-96.698041,35.666225],[-96.751474,35.709638]]]},"properties":{"id":"74834","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.764935,35.740223],[-96.76822,35.69523],[-96.751474,35.709638],[-96.764935,35.740223]]]},"properties":{"id":"74026","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.927126,35.898554],[-97.018566,35.869476],[-96.947034,35.811846],[-96.894071,35.811603],[-96.927126,35.898554]]]},"properties":{"id":"74875","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.620628,35.769873],[-97.674026,35.72597],[-97.673994,35.696548],[-97.514085,35.696637],[-97.476057,35.696303],[-97.478388,35.768807],[-97.620628,35.769873]]]},"properties":{"id":"73025","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.439415,33.934],[-97.453563,33.895222],[-97.225815,33.913833],[-97.30782,33.950052],[-97.369488,33.998495],[-97.439415,33.934]]]},"properties":{"id":"73430","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.439415,33.934],[-97.439393,33.882101],[-97.42198,33.881949],[-97.421913,33.933431],[-97.439415,33.934]]]},"properties":{"id":"73441","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.462565,34.454182],[-94.732225,34.371462],[-94.697648,34.326315],[-94.471571,34.330775],[-94.466412,34.330735],[-94.462565,34.454182]]]},"properties":{"id":"74963","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.891907,34.336798],[-94.861947,34.308057],[-94.883975,34.380949],[-94.891907,34.336798]]]},"properties":{"id":"74724","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.763778,33.759925],[-94.477299,33.940932],[-94.576462,33.951469],[-94.617945,33.938814],[-94.763778,33.759925]]]},"properties":{"id":"74740","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.050757,36.237076],[-98.955985,36.188374],[-98.725784,36.163263],[-98.725758,36.202481],[-98.960754,36.347935],[-99.050757,36.237076]]]},"properties":{"id":"73838","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.040702,36.468577],[-95.062598,36.459269],[-95.025261,36.442226],[-95.040702,36.468577]]]},"properties":{"id":"74350","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.35378,35.638989],[-96.482487,35.639061],[-96.47686,35.595375],[-96.405974,35.488933],[-96.35378,35.638989]]]},"properties":{"id":"74833","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.674036,35.594822],[-97.674052,35.565697],[-97.608526,35.580835],[-97.605459,35.594649],[-97.674036,35.594822]]]},"properties":{"id":"73162","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.606246,35.609164],[-97.605459,35.594649],[-97.608526,35.580835],[-97.609713,35.562788],[-97.540415,35.551125],[-97.531565,35.601594],[-97.606246,35.609164]]]},"properties":{"id":"73120","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.525797,35.464268],[-97.525604,35.478755],[-97.525438,35.500519],[-97.547692,35.499503],[-97.547913,35.462848],[-97.530127,35.464265],[-97.525797,35.464268]]]},"properties":{"id":"73106","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.459096,35.551157],[-97.459173,35.486341],[-97.427781,35.493201],[-97.424537,35.551115],[-97.424931,35.558253],[-97.459096,35.551157]]]},"properties":{"id":"73121","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.066365,36.528503],[-96.053536,36.485846],[-96.120647,36.498379],[-96.206157,36.446788],[-96.211939,36.304234],[-96.201752,36.292078],[-96.103162,36.256022],[-96.040098,36.262288],[-95.920113,36.3366],[-95.92032,36.424227],[-96.000854,36.535298],[-96.066365,36.528503]]]},"properties":{"id":"74070","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.211939,36.304234],[-96.323969,36.289989],[-96.279489,36.227079],[-96.250288,36.219981],[-96.201752,36.292078],[-96.211939,36.304234]]]},"properties":{"id":"74060","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.81939,36.670344],[-94.773604,36.667819],[-94.752412,36.799877],[-94.918239,36.757565],[-94.81939,36.670344]]]},"properties":{"id":"74343","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.707283,34.905912],[-95.690632,34.903997],[-95.688467,34.907752],[-95.707283,34.905912]]]},"properties":{"id":"74522","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.881738,34.782053],[-95.81186,34.811169],[-95.855937,34.830751],[-95.881738,34.782053]]]},"properties":{"id":"74565","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.758528,34.709287],[-96.652382,34.621754],[-96.634846,34.636363],[-96.66994,34.665505],[-96.758528,34.709287]]]},"properties":{"id":"74843","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.853051,34.928819],[-96.665145,34.928207],[-96.613181,35.021184],[-96.818061,35.076043],[-96.853051,34.928819]]]},"properties":{"id":"74849","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.570487,35.740429],[-99.605795,35.826343],[-99.789981,35.711599],[-99.979364,35.757755],[-99.858427,35.523644],[-99.793121,35.422139],[-99.575948,35.498699],[-99.514457,35.56633],[-99.570487,35.740429]]]},"properties":{"id":"73628","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.000391,35.552456],[-99.858427,35.523644],[-99.979364,35.757755],[-100.000393,35.745219],[-100.000391,35.552456]]]},"properties":{"id":"73660","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.752407,36.34725],[-95.435533,36.379449],[-95.435536,36.379649],[-95.525411,36.521295],[-95.679677,36.42248],[-95.739532,36.378448],[-95.752407,36.34725]]]},"properties":{"id":"74017","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.679555,35.427547],[-94.73782,35.354648],[-94.69086,35.329869],[-94.621156,35.300749],[-94.679555,35.427547]]]},"properties":{"id":"74936","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.836351,34.333777],[-98.836416,34.275962],[-98.609598,34.304502],[-98.7168,34.391093],[-98.836351,34.333777]]]},"properties":{"id":"73553","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.870297,36.133471],[-95.833222,36.133445],[-95.833368,36.158994],[-95.869132,36.161046],[-95.875031,36.160748],[-95.870297,36.133471]]]},"properties":{"id":"74128","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.940271,36.118766],[-95.986974,36.118993],[-95.986073,36.075194],[-95.940123,36.075327],[-95.940271,36.118766]]]},"properties":{"id":"74105","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.904541,36.118897],[-95.869877,36.118963],[-95.833236,36.118926],[-95.833222,36.133445],[-95.870297,36.133471],[-95.906903,36.133403],[-95.904558,36.12116],[-95.904541,36.118897]]]},"properties":{"id":"74129","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.972354,36.800526],[-95.979105,36.670132],[-95.917218,36.626491],[-95.809617,36.641581],[-95.809761,36.76418],[-95.972354,36.800526]]]},"properties":{"id":"74006","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.884229,36.451702],[-95.88422,36.449251],[-95.879717,36.448425],[-95.879758,36.452729],[-95.884229,36.451702]]]},"properties":{"id":"74082","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.126368,35.218859],[-99.099824,35.116633],[-98.955057,35.116431],[-98.972613,35.174642],[-99.117393,35.218874],[-99.126368,35.218859]]]},"properties":{"id":"73661","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.257864,36.23158],[-99.062098,36.162285],[-98.955985,36.188374],[-99.050757,36.237076],[-99.264165,36.34837],[-99.264229,36.333898],[-99.257864,36.23158]]]},"properties":{"id":"73853","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.140274,36.593619],[-98.253042,36.651863],[-98.360489,36.65208],[-98.342113,36.550113],[-98.319527,36.463239],[-98.229701,36.463408],[-98.211741,36.463338],[-98.140274,36.593619]]]},"properties":{"id":"73741","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.889359,34.227769],[-95.832206,34.156806],[-95.779376,34.156699],[-95.779225,34.317338],[-95.779156,34.417568],[-95.88228,34.325328],[-95.889359,34.227769]]],[[[-96.27841,34.280281],[-96.102196,34.186541],[-96.038042,34.157564],[-95.991578,34.156815],[-95.987765,34.156798],[-96.005083,34.343833],[-95.986067,34.533922],[-95.974378,34.603803],[-96.010157,34.583579],[-96.092061,34.585888],[-96.145326,34.491003],[-96.151524,34.418676],[-96.354648,34.418373],[-96.407099,34.334094],[-96.302975,34.273252],[-96.27841,34.280281]]]]},"properties":{"id":"74525","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.812089,34.738312],[-95.974378,34.603803],[-95.986067,34.533922],[-95.753048,34.593847],[-95.601657,34.593981],[-95.514334,34.74166],[-95.514225,34.758703],[-95.594656,34.737915],[-95.812089,34.738312]]]},"properties":{"id":"74560","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.889359,34.227769],[-95.987765,34.156798],[-95.991578,34.156815],[-95.859742,33.850376],[-95.76152,33.873118],[-95.832206,34.156806],[-95.889359,34.227769]]]},"properties":{"id":"74727","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.27841,34.280281],[-96.198041,34.17689],[-96.102196,34.186541],[-96.27841,34.280281]]]},"properties":{"id":"74533","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.61553,36.499457],[-100.844508,36.759501],[-100.953933,36.67123],[-100.954153,36.499916],[-100.61553,36.499457]]]},"properties":{"id":"73931","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.13639,35.898591],[-98.209951,35.913076],[-98.316521,35.841063],[-98.224512,35.763982],[-98.207087,35.768578],[-98.13639,35.898591]]]},"properties":{"id":"73764","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.725784,36.163263],[-98.555008,36.013775],[-98.479839,36.057387],[-98.501111,36.165067],[-98.725758,36.202481],[-98.725784,36.163263]]]},"properties":{"id":"73755","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.702502,35.812518],[-98.880166,35.812602],[-98.827256,35.638547],[-98.703187,35.638487],[-98.594348,35.686361],[-98.613989,35.729497],[-98.702502,35.812518]]]},"properties":{"id":"73669","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.576574,33.960467],[-96.535534,33.946334],[-96.465639,33.969029],[-96.549253,34.06244],[-96.62488,34.007571],[-96.576574,33.960467]]]},"properties":{"id":"73449","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.729217,35.377718],[-98.729336,35.276405],[-98.659046,35.269413],[-98.570625,35.305186],[-98.570445,35.334143],[-98.623247,35.405791],[-98.729217,35.377718]]]},"properties":{"id":"73021","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.156321,35.24723],[-98.1532,35.189345],[-98.056682,35.160199],[-97.961308,35.145735],[-97.882548,35.203645],[-97.918337,35.247203],[-98.156321,35.24723]]]},"properties":{"id":"73079","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.830795,35.391662],[-98.095904,35.412219],[-97.856847,35.344955],[-97.833675,35.335362],[-97.830795,35.391662]]]},"properties":{"id":"73090","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.689097,35.460297],[-97.601111,35.460203],[-97.60478,35.464144],[-97.601161,35.500061],[-97.619061,35.50051],[-97.671319,35.492956],[-97.689097,35.460297]]]},"properties":{"id":"73127","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.447639,34.390793],[-97.387166,34.332608],[-97.35213,34.274495],[-97.038723,34.366039],[-97.036542,34.375417],[-97.318949,34.376215],[-97.404681,34.455968],[-97.422301,34.448818],[-97.447639,34.390793]]]},"properties":{"id":"73458","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.562575,34.289182],[-97.515689,34.187437],[-97.422249,34.332635],[-97.444138,34.376314],[-97.562709,34.332579],[-97.562575,34.289182]]]},"properties":{"id":"73438","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.247784,36.675609],[-102.1438,36.500325],[-101.672775,36.499545],[-102.031855,36.791307],[-102.247784,36.675609]]]},"properties":{"id":"73949","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512168,35.363381],[-97.512051,35.290761],[-97.489848,35.302276],[-97.441865,35.298265],[-97.423604,35.305285],[-97.441384,35.363205],[-97.459227,35.37734],[-97.512235,35.377213],[-97.512168,35.363381]]]},"properties":{"id":"73160","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.445355,35.207461],[-97.441235,35.20386],[-97.441238,35.211115],[-97.447899,35.211101],[-97.445355,35.207461]]]},"properties":{"id":"73019","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.409935,34.476425],[-96.458165,34.512668],[-96.46239,34.463211],[-96.409935,34.476425]]]},"properties":{"id":"74535","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.139074,34.340597],[-98.283745,34.166256],[-98.267664,34.131853],[-98.139072,34.14187],[-98.095253,34.290285],[-98.139074,34.340597]]]},"properties":{"id":"73548","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.063201,36.075496],[-96.069847,36.074345],[-96.0658,36.068253],[-96.057583,36.075469],[-96.063201,36.075496]]]},"properties":{"id":"74050","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.194735,35.54881],[-99.240985,35.320437],[-99.223363,35.320375],[-99.187989,35.320362],[-99.187905,35.378386],[-99.152515,35.378384],[-99.170164,35.320327],[-99.107188,35.320269],[-99.063968,35.363898],[-99.028954,35.421853],[-99.155214,35.551691],[-99.164257,35.580569],[-99.194735,35.54881]]]},"properties":{"id":"73647","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.210544,36.16489],[-98.077475,36.16501],[-98.068937,36.231897],[-98.104418,36.272626],[-98.194098,36.303976],[-98.284255,36.262428],[-98.242521,36.196592],[-98.210544,36.16489]]]},"properties":{"id":"73718","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.494503,34.942541],[-97.492802,34.746391],[-97.398886,34.737665],[-97.350693,34.831101],[-97.335907,34.855274],[-97.423927,34.942535],[-97.494503,34.942541]]]},"properties":{"id":"73057","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.588308,36.767214],[-97.462309,36.738382],[-97.480471,36.926753],[-97.588308,36.767214]]]},"properties":{"id":"74636","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.711464,35.116778],[-99.888571,35.030262],[-99.723217,34.85698],[-99.720066,34.76845],[-99.492669,34.725013],[-99.423201,34.72502],[-99.405711,34.769025],[-99.311008,34.865581],[-99.31728,34.871489],[-99.519804,34.958357],[-99.677005,35.10224],[-99.711464,35.116778]]]},"properties":{"id":"73554","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.405711,34.769025],[-99.229895,34.754464],[-99.162085,34.739445],[-99.164837,34.783099],[-99.148985,34.798212],[-99.15915,34.847738],[-99.311008,34.865581],[-99.405711,34.769025]]]},"properties":{"id":"73526","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.912398,34.845151],[-99.806486,34.521569],[-99.666512,34.550628],[-99.720066,34.76845],[-99.723217,34.85698],[-99.912398,34.845151]]]},"properties":{"id":"73544","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.123007,35.242083],[-96.090718,35.289837],[-96.122846,35.289887],[-96.123007,35.242083]]],[[[-96.123007,35.242083],[-96.126441,35.289957],[-96.242102,35.341506],[-96.388064,35.290141],[-96.15937,35.093249],[-96.071963,35.188754],[-96.123007,35.242083]]]]},"properties":{"id":"74883","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.132189,35.014295],[-96.196626,34.766997],[-96.092043,34.680556],[-95.980548,34.734784],[-95.994728,34.753014],[-96.001081,34.869041],[-96.088843,35.049863],[-96.132189,35.014295]]]},"properties":{"id":"74570","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.561424,34.536011],[-99.526456,34.411779],[-99.355404,34.448866],[-99.382924,34.478958],[-99.423201,34.72502],[-99.492669,34.725013],[-99.561424,34.536011]]]},"properties":{"id":"73560","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.999858,34.289051],[-97.964954,34.289126],[-97.959737,34.245545],[-97.788533,34.289261],[-97.737308,34.376419],[-98.139283,34.449193],[-98.13919,34.36233],[-98.139074,34.340597],[-98.095253,34.290285],[-97.999858,34.289051]]]},"properties":{"id":"73529","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.744444,34.286341],[-96.733255,34.205122],[-96.752551,34.172547],[-96.585355,34.114459],[-96.573763,34.114436],[-96.565989,34.160336],[-96.531355,34.34952],[-96.539684,34.361369],[-96.610509,34.404897],[-96.715922,34.419245],[-96.744444,34.286341]]]},"properties":{"id":"73460","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.255591,36.883675],[-97.251392,36.688421],[-97.184085,36.593991],[-97.057042,36.593359],[-97.04938,36.82516],[-97.21054,36.854458],[-97.255591,36.883675]]]},"properties":{"id":"74601","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.228419,36.998892],[-97.21054,36.854458],[-97.04938,36.82516],[-96.928638,36.810863],[-96.828609,36.818054],[-96.749838,36.998988],[-97.228419,36.998892]]]},"properties":{"id":"74647","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927719,34.985659],[-94.892667,34.972882],[-94.927684,34.950092],[-95.147514,34.854252],[-95.120528,34.824956],[-94.727837,34.7706],[-94.717621,34.807078],[-94.781566,34.920404],[-94.699023,34.966859],[-94.84885,35.065485],[-94.8781,35.029211],[-94.927719,34.985659]],[[-94.985792,34.891105],[-94.990181,34.903259],[-94.962431,34.905414],[-94.96263,34.891271],[-94.985792,34.891105]]]},"properties":{"id":"74966","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.85859,34.695299],[-94.885181,34.666319],[-94.773907,34.656987],[-94.85859,34.695299]]]},"properties":{"id":"74577","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.947034,35.811846],[-97.018566,35.869476],[-97.070088,35.854993],[-97.034573,35.796936],[-96.947034,35.811846]]]},"properties":{"id":"74832","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.927126,35.898554],[-96.894071,35.811603],[-96.836983,35.811356],[-96.823139,35.840538],[-96.873585,35.956667],[-96.909611,35.955795],[-96.927126,35.898554]]]},"properties":{"id":"74824","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.140963,35.579535],[-97.194245,35.579614],[-97.221216,35.536314],[-97.194841,35.444242],[-97.141796,35.420424],[-97.034816,35.550429],[-97.034812,35.564924],[-97.140963,35.579535]]]},"properties":{"id":"73045","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.26482,36.086895],[-97.533032,36.11608],[-97.559615,36.086946],[-97.497308,35.985402],[-97.353066,35.985451],[-97.247041,36.057925],[-97.26482,36.086895]]]},"properties":{"id":"73063","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.371659,35.647787],[-97.264878,35.63804],[-97.264958,35.73946],[-97.282825,35.739557],[-97.355312,35.739842],[-97.371659,35.647787]]]},"properties":{"id":"73007","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.861947,34.308057],[-94.895754,34.129971],[-94.938083,34.019434],[-94.929647,33.974395],[-94.617945,33.938814],[-94.576462,33.951469],[-94.680624,34.194066],[-94.697648,34.326315],[-94.732225,34.371462],[-94.731328,34.406059],[-95.081258,34.417435],[-95.136744,34.344838],[-94.93141,34.376898],[-94.883975,34.380949],[-94.861947,34.308057]]]},"properties":{"id":"74728","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.883975,34.380949],[-94.93141,34.376898],[-94.891907,34.336798],[-94.883975,34.380949]]]},"properties":{"id":"74722","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.929647,33.974395],[-94.923527,33.81768],[-94.763778,33.759925],[-94.617945,33.938814],[-94.929647,33.974395]]]},"properties":{"id":"74745","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.123293,35.514871],[-95.981308,35.303383],[-95.981309,35.303263],[-95.822166,35.372081],[-95.822969,35.419993],[-95.68101,35.486152],[-95.716305,35.538586],[-95.789719,35.536639],[-95.922725,35.550341],[-95.953568,35.52202],[-95.956305,35.49298],[-95.963862,35.492709],[-95.963894,35.507236],[-95.956893,35.520748],[-96.087755,35.519997],[-96.123293,35.514871]],[[-95.962467,35.458222],[-95.956825,35.48151],[-95.929092,35.483481],[-95.936292,35.454907],[-95.962467,35.458222]]]},"properties":{"id":"74437","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.492062,35.551897],[-95.497307,35.529067],[-95.486705,35.518599],[-95.485386,35.551926],[-95.492062,35.551897]]]},"properties":{"id":"74459","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.880895,33.997932],[-96.934806,33.98279],[-96.93484,33.954453],[-96.897382,33.918643],[-96.880895,33.997932]]]},"properties":{"id":"73440","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.038723,34.366039],[-96.933457,34.332661],[-96.881005,34.332977],[-96.827288,34.505989],[-96.967643,34.636795],[-97.002661,34.636803],[-97.054847,34.637066],[-97.057462,34.401573],[-97.041286,34.385221],[-97.036542,34.375417],[-97.038723,34.366039]]]},"properties":{"id":"73086","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.570951,35.754874],[-95.553171,35.769358],[-95.535644,35.769333],[-95.535625,35.747509],[-95.553158,35.75117],[-95.60665,35.696939],[-95.635494,35.653218],[-95.571034,35.653356],[-95.383031,35.624341],[-95.402214,35.806633],[-95.579872,35.769743],[-95.570951,35.754874]]]},"properties":{"id":"74401","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.553158,35.75117],[-95.535625,35.747509],[-95.535644,35.769333],[-95.553171,35.769358],[-95.570951,35.754874],[-95.553158,35.75117]]]},"properties":{"id":"74463","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.635494,35.653218],[-95.60665,35.696939],[-95.730901,35.725809],[-95.784181,35.551764],[-95.716347,35.551811],[-95.635494,35.653218]]]},"properties":{"id":"74422","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.539571,36.615789],[-95.431731,36.673569],[-95.521657,36.754339],[-95.74384,36.82269],[-95.809894,36.81497],[-95.809761,36.76418],[-95.809617,36.641581],[-95.809617,36.597476],[-95.660373,36.597526],[-95.539571,36.615789]]]},"properties":{"id":"74048","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.547534,35.536809],[-97.547692,35.499503],[-97.525438,35.500519],[-97.512393,35.493377],[-97.512034,35.536852],[-97.547534,35.536809]]]},"properties":{"id":"73118","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.956893,35.520748],[-95.963894,35.507236],[-95.963862,35.492709],[-95.956305,35.49298],[-95.953568,35.52202],[-95.956893,35.520748]]]},"properties":{"id":"74460","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.007093,35.718166],[-95.989372,35.714502],[-96.000969,35.725965],[-96.007093,35.718166]]]},"properties":{"id":"74456","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.323969,36.289989],[-96.43783,36.293681],[-96.279489,36.227079],[-96.323969,36.289989]]]},"properties":{"id":"74054","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.87755,36.91997],[-94.88198,36.91808],[-94.88199,36.91446],[-94.87747,36.91451],[-94.87754,36.91914],[-94.87755,36.91997]]]},"properties":{"id":"74358","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.7584,36.32186],[-95.902086,36.322085],[-95.902561,36.264038],[-95.851685,36.223788],[-95.81527,36.22445],[-95.744186,36.265665],[-95.7584,36.32186]]]},"properties":{"id":"74055","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.715715,35.41336],[-96.776794,35.195427],[-96.776877,35.180698],[-96.633343,35.108657],[-96.490326,35.347995],[-96.535146,35.391371],[-96.623699,35.400722],[-96.715715,35.41336]],[[-96.670171,35.159363],[-96.652513,35.145312],[-96.651766,35.130579],[-96.671686,35.13044],[-96.670171,35.159363]]]},"properties":{"id":"74868","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.43572,35.419475],[-94.460414,35.404923],[-94.472803,35.375781],[-94.43559,35.386122],[-94.43572,35.419475]]]},"properties":{"id":"74946","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.836351,34.333777],[-98.836336,34.377315],[-98.906325,34.363046],[-98.923315,34.334036],[-98.836351,34.333777]]]},"properties":{"id":"73551","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.908609,36.046423],[-95.904335,36.002804],[-95.868562,36.002861],[-95.850972,36.017315],[-95.851195,36.07539],[-95.870944,36.075432],[-95.90433,36.075388],[-95.908609,36.046423]]]},"properties":{"id":"74133","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.011836,35.988384],[-96.029477,35.959198],[-96.029543,35.91563],[-95.975982,35.91565],[-95.979386,35.973712],[-96.011836,35.988384]]]},"properties":{"id":"74033","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.986974,36.118993],[-95.940271,36.118766],[-95.904558,36.12116],[-95.906903,36.133403],[-95.940355,36.133236],[-95.970412,36.133364],[-95.981772,36.13418],[-95.990272,36.132803],[-95.992178,36.131829],[-95.986974,36.118993]]]},"properties":{"id":"74114","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.998068,36.147911],[-95.990272,36.132803],[-95.981772,36.13418],[-95.985993,36.151754],[-95.998068,36.147911]]]},"properties":{"id":"74119","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.857654,35.349291],[-99.063968,35.363898],[-99.107188,35.320269],[-99.117393,35.218874],[-98.972613,35.174642],[-98.84582,35.175366],[-98.667812,35.233096],[-98.659046,35.269413],[-98.729336,35.276405],[-98.857654,35.349291]]]},"properties":{"id":"73632","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.64826,36.688613],[-98.666228,36.688563],[-98.66624,36.681397],[-98.6482,36.681416],[-98.64826,36.688613]]]},"properties":{"id":"73746","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.858543,35.422142],[-99.711464,35.116778],[-99.677005,35.10224],[-99.553623,35.16395],[-99.399628,35.291323],[-99.575948,35.498699],[-99.793121,35.422139],[-99.858543,35.422142]]]},"properties":{"id":"73662","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.702502,35.812518],[-98.613989,35.729497],[-98.543364,35.725193],[-98.547589,35.913271],[-98.63617,35.91326],[-98.776454,35.878974],[-98.702502,35.812518]]]},"properties":{"id":"73646","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.258515,34.026904],[-96.27605,33.841714],[-96.176554,33.803715],[-96.094882,33.84735],[-96.094638,34.099131],[-96.258515,34.026904]]]},"properties":{"id":"74726","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.570157,33.818983],[-96.419124,33.771825],[-96.409489,33.783297],[-96.411279,33.838466],[-96.528472,33.894535],[-96.570157,33.818983]]]},"properties":{"id":"74733","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.570625,35.305186],[-98.526446,35.290702],[-98.526444,35.334042],[-98.570445,35.334143],[-98.570625,35.305186]]]},"properties":{"id":"73033","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.095904,35.412219],[-97.830795,35.391662],[-97.813027,35.406207],[-97.86963,35.609459],[-98.034303,35.623846],[-98.149226,35.455037],[-98.114056,35.420917],[-98.095904,35.412219]]]},"properties":{"id":"73036","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.207104,35.72514],[-98.286141,35.528485],[-98.149226,35.455037],[-98.034303,35.623846],[-98.178819,35.725106],[-98.207104,35.72514]]]},"properties":{"id":"73014","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.689145,35.420599],[-97.671374,35.391654],[-97.653916,35.406195],[-97.636263,35.406174],[-97.636236,35.394465],[-97.618671,35.406183],[-97.601021,35.420721],[-97.601036,35.43525],[-97.689208,35.43509],[-97.689145,35.420599]]]},"properties":{"id":"73179","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.387166,34.332608],[-97.447639,34.390793],[-97.444138,34.376314],[-97.422249,34.332635],[-97.387166,34.332608]]]},"properties":{"id":"73437","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.900169,35.827103],[-94.840775,35.918846],[-94.797342,35.961787],[-94.814449,36.111317],[-94.832788,36.161542],[-94.868591,36.161491],[-95.011303,36.090939],[-95.126802,35.86446],[-95.127714,35.764812],[-95.127491,35.67561],[-95.078284,35.638697],[-94.900169,35.827103]]]},"properties":{"id":"74464","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.011303,36.090939],[-95.100618,36.08193],[-95.252188,35.987845],[-95.264845,35.883805],[-95.126802,35.86446],[-95.011303,36.090939]]]},"properties":{"id":"74441","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.997601,34.024399],[-95.026981,34.210587],[-95.104427,34.149775],[-95.156506,34.120672],[-95.202381,34.026476],[-95.191372,34.011906],[-95.205944,33.997382],[-95.155882,33.936572],[-95.064289,33.910097],[-95.034889,33.944699],[-95.017433,33.990061],[-94.997601,34.024399]]]},"properties":{"id":"74764","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.202381,34.026476],[-95.205944,33.997382],[-95.191372,34.011906],[-95.202381,34.026476]]]},"properties":{"id":"74761","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.331929,33.879818],[-95.426893,33.939228],[-95.502479,33.875199],[-95.331929,33.879818]]]},"properties":{"id":"74738","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.343584,34.199538],[-95.440334,34.156863],[-95.442215,34.156869],[-95.450752,34.114876],[-95.330286,34.142384],[-95.343584,34.199538]]]},"properties":{"id":"74760","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.597593,33.941496],[-95.582525,34.132005],[-95.779376,34.156699],[-95.832206,34.156806],[-95.76152,33.873118],[-95.597593,33.941496]]]},"properties":{"id":"74759","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.274402,35.323378],[-97.388255,35.305283],[-97.388252,35.175018],[-97.142184,35.192086],[-97.141949,35.247361],[-97.274402,35.323378]]]},"properties":{"id":"73026","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.545229,34.638021],[-98.42218,34.638225],[-98.352084,34.639611],[-98.384992,34.694336],[-98.390036,34.69433],[-98.463512,34.724426],[-98.509956,34.724823],[-98.595869,34.724692],[-98.614918,34.709779],[-98.630062,34.68163],[-98.632689,34.645145],[-98.545229,34.638021]]]},"properties":{"id":"73503","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.642995,34.507457],[-98.527634,34.521919],[-98.545229,34.638021],[-98.632689,34.645145],[-98.655121,34.638121],[-98.729279,34.521892],[-98.642995,34.507457]]]},"properties":{"id":"73527","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.492399,34.521854],[-98.42219,34.60932],[-98.42218,34.638225],[-98.545229,34.638021],[-98.527634,34.521919],[-98.492399,34.521854]]]},"properties":{"id":"73505","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.7168,34.391093],[-98.609598,34.304502],[-98.461104,34.405558],[-98.484479,34.42732],[-98.642995,34.507457],[-98.729279,34.521892],[-98.731633,34.507516],[-98.7168,34.391093]]]},"properties":{"id":"73528","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.834993,34.81217],[-98.05992,34.840743],[-98.08901,34.834806],[-98.089085,34.753635],[-97.724769,34.715561],[-97.687957,34.777324],[-97.834993,34.81217]]]},"properties":{"id":"73082","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.13919,34.36233],[-98.301349,34.202518],[-98.283745,34.166256],[-98.139074,34.340597],[-98.13919,34.36233]]]},"properties":{"id":"73568","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.356055,36.510218],[-95.166099,36.41517],[-95.14085,36.442323],[-95.34162,36.5977],[-95.356055,36.510218]]]},"properties":{"id":"74332","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.01729,36.85928],[-94.96334,36.75769],[-94.918239,36.757565],[-94.752412,36.799877],[-94.6797,36.89311],[-94.81943,36.94364],[-94.8532,36.99886],[-95.00762,36.999514],[-95.01729,36.85928]],[[-94.87755,36.91997],[-94.90443,36.943527],[-94.853383,36.943607],[-94.87754,36.91914],[-94.87747,36.91451],[-94.88199,36.91446],[-94.88198,36.91808],[-94.87755,36.91997]]]},"properties":{"id":"74354","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.58398,35.904749],[-96.572831,35.901105],[-96.564354,35.912215],[-96.58398,35.904749]]]},"properties":{"id":"74068","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.305744,35.703882],[-99.247568,35.566138],[-99.194735,35.54881],[-99.164257,35.580569],[-99.066417,35.711004],[-99.146305,35.783327],[-99.305744,35.703882]]]},"properties":{"id":"73625","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.329179,35.523357],[-99.364529,35.284115],[-99.240892,35.255145],[-99.240985,35.320437],[-99.194735,35.54881],[-99.247568,35.566138],[-99.329179,35.523357]]]},"properties":{"id":"73626","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.247568,35.566138],[-99.305744,35.703882],[-99.570487,35.740429],[-99.514457,35.56633],[-99.329179,35.523357],[-99.247568,35.566138]]]},"properties":{"id":"73650","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.110641,35.812412],[-99.146305,35.783327],[-99.066417,35.711004],[-98.880373,35.595023],[-98.827256,35.638547],[-98.880166,35.812602],[-99.110641,35.812412]]]},"properties":{"id":"73639","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.703187,35.638487],[-98.827256,35.638547],[-98.880373,35.595023],[-98.844963,35.551926],[-98.835037,35.465078],[-98.729217,35.377718],[-98.623247,35.405791],[-98.703187,35.638487]]]},"properties":{"id":"73096","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.957517,36.515146],[-94.879818,36.347839],[-94.593094,36.343866],[-94.617985,36.53266],[-94.844758,36.550526],[-94.957517,36.515146]]]},"properties":{"id":"74346","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.381022,36.012925],[-99.221875,36.086727],[-99.061908,36.086656],[-99.062098,36.162285],[-99.257864,36.23158],[-99.381896,36.202815],[-99.453619,36.163926],[-99.45189,36.005508],[-99.381022,36.012925]]]},"properties":{"id":"73859","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.658008,36.419325],[-97.783024,36.318047],[-97.747248,36.274952],[-97.621993,36.317787],[-97.586332,36.368855],[-97.658008,36.419325]]]},"properties":{"id":"73736","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.782059,36.231296],[-97.988243,36.1648],[-97.782457,36.16462],[-97.760129,36.188409],[-97.782059,36.231296]]]},"properties":{"id":"73720","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820413,34.986064],[-98.005674,34.961293],[-98.05992,34.840743],[-97.834993,34.81217],[-97.793962,34.913246],[-97.820413,34.986064]]]},"properties":{"id":"73067","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.39094,36.869],[-97.46246,36.926859],[-97.480471,36.926753],[-97.462309,36.738382],[-97.462326,36.73479],[-97.39044,36.73137],[-97.39094,36.869]]]},"properties":{"id":"74646","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.271993,35.281044],[-95.358297,35.306632],[-95.503569,35.25672],[-95.450836,35.210438],[-95.327152,35.231772],[-95.271993,35.281044]]],[[[-95.133345,35.380126],[-95.226087,35.2692],[-95.280936,35.224382],[-95.323314,35.188977],[-95.145401,35.150851],[-94.990964,35.214321],[-94.934239,35.395509],[-94.944608,35.402457],[-95.049933,35.458894],[-95.133345,35.380126]]]]},"properties":{"id":"74462","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.665145,34.928207],[-96.516736,34.871781],[-96.490786,34.910585],[-96.54677,35.057845],[-96.597397,35.05788],[-96.613181,35.021184],[-96.665145,34.928207]]]},"properties":{"id":"74867","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.973388,33.943771],[-97.676981,34.042209],[-97.720349,34.089073],[-98.112383,34.091087],[-97.973388,33.943771]]]},"properties":{"id":"73565","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.112383,34.091087],[-97.720349,34.089073],[-97.772205,34.289236],[-97.788533,34.289261],[-97.959737,34.245545],[-97.999858,34.289051],[-98.095253,34.290285],[-98.139072,34.14187],[-98.112383,34.091087]]]},"properties":{"id":"73573","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.615363,34.455896],[-96.69502,34.505658],[-96.74041,34.505828],[-96.715922,34.419245],[-96.610509,34.404897],[-96.598664,34.418997],[-96.615363,34.455896]]]},"properties":{"id":"74836","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.610509,34.404897],[-96.539684,34.361369],[-96.525976,34.383373],[-96.555125,34.445002],[-96.598664,34.418997],[-96.610509,34.404897]]],[[[-96.531355,34.34952],[-96.565989,34.160336],[-96.413552,34.160459],[-96.494345,34.298085],[-96.531355,34.34952]]]]},"properties":{"id":"73450","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.39094,36.869],[-97.39044,36.73137],[-97.251392,36.688421],[-97.255591,36.883675],[-97.39094,36.869]]]},"properties":{"id":"74631","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.255591,36.883675],[-97.21054,36.854458],[-97.228419,36.998892],[-97.462346,36.998701],[-97.46246,36.926859],[-97.39094,36.869],[-97.255591,36.883675]]]},"properties":{"id":"74632","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.729215,35.907193],[-98.014491,35.969336],[-98.13639,35.898591],[-98.207087,35.768578],[-98.207104,35.72514],[-98.178819,35.725106],[-97.763487,35.754635],[-97.714706,35.885466],[-97.729215,35.907193]]]},"properties":{"id":"73750","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.714706,35.885466],[-97.763487,35.754635],[-97.763439,35.725746],[-97.674026,35.72597],[-97.620628,35.769873],[-97.612019,35.885465],[-97.714706,35.885466]]]},"properties":{"id":"73016","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.15915,34.847738],[-99.219487,35.116697],[-99.360226,35.116738],[-99.409291,35.114509],[-99.31728,34.871489],[-99.311008,34.865581],[-99.15915,34.847738]]]},"properties":{"id":"73655","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.955057,35.116431],[-98.936701,34.942777],[-98.849003,34.884582],[-98.84582,35.175366],[-98.972613,35.174642],[-98.955057,35.116431]]]},"properties":{"id":"73041","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.132055,34.680819],[-95.051143,34.666254],[-95.054515,34.637144],[-95.042315,34.593753],[-94.990089,34.59375],[-94.832774,34.593907],[-94.783636,34.618837],[-94.773907,34.656987],[-94.885181,34.666319],[-94.85859,34.695299],[-94.768725,34.679578],[-94.727837,34.7706],[-95.120528,34.824956],[-95.297702,34.78192],[-95.24405,34.68055],[-95.132055,34.680819]]]},"properties":{"id":"74571","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.24405,34.68055],[-95.251952,34.5941],[-95.042315,34.593753],[-95.054515,34.637144],[-95.132055,34.680819],[-95.24405,34.68055]]],[[[-95.24405,34.68055],[-95.297702,34.78192],[-95.514225,34.758703],[-95.514334,34.74166],[-95.24405,34.68055]]]]},"properties":{"id":"74574","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.854225,35.110727],[-94.881759,35.099565],[-94.8781,35.029211],[-94.84885,35.065485],[-94.643533,35.121953],[-94.646838,35.148074],[-94.672819,35.148123],[-94.854225,35.110727]]]},"properties":{"id":"74956","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.937605,34.506831],[-94.731328,34.406059],[-94.732225,34.371462],[-94.462565,34.454182],[-94.458743,34.594024],[-94.680295,34.612065],[-94.783636,34.618837],[-94.832774,34.593907],[-94.937605,34.506831]]]},"properties":{"id":"74957","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.472803,35.375781],[-94.494738,35.332276],[-94.466842,35.312767],[-94.434024,35.317408],[-94.43559,35.386122],[-94.472803,35.375781]]]},"properties":{"id":"74901","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.985792,34.891105],[-94.96263,34.891271],[-94.962431,34.905414],[-94.990181,34.903259],[-94.985792,34.891105]]]},"properties":{"id":"74942","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927684,34.950092],[-94.892667,34.972882],[-94.927719,34.985659],[-94.927684,34.950092]]]},"properties":{"id":"74935","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.078969,34.036181],[-97.30782,33.950052],[-97.225815,33.913833],[-97.166851,33.840899],[-97.074043,33.839871],[-96.93484,33.954453],[-96.934806,33.98279],[-96.969627,34.07102],[-97.078969,34.036181]]]},"properties":{"id":"73448","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.822166,35.372081],[-95.981309,35.303263],[-95.965551,35.152496],[-95.900753,35.146498],[-95.770525,35.181766],[-95.822166,35.372081]]]},"properties":{"id":"74845","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.784043,35.740272],[-95.922725,35.550341],[-95.789719,35.536639],[-95.784181,35.551764],[-95.730901,35.725809],[-95.784043,35.740272]]]},"properties":{"id":"74445","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.133345,35.380126],[-95.049933,35.458894],[-95.109663,35.495093],[-95.188159,35.574493],[-95.268233,35.551708],[-95.238802,35.435258],[-95.133345,35.380126]]]},"properties":{"id":"74470","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.872761,35.856313],[-95.860875,35.850067],[-95.784043,35.740272],[-95.730901,35.725809],[-95.60665,35.696939],[-95.553158,35.75117],[-95.570951,35.754874],[-95.579872,35.769743],[-95.638912,35.822599],[-95.726456,35.903206],[-95.76147,35.915313],[-95.872761,35.856313]]]},"properties":{"id":"74436","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.057042,36.593359],[-97.184085,36.593991],[-97.308468,36.550148],[-97.009785,36.506935],[-96.909279,36.59631],[-97.057042,36.593359]]]},"properties":{"id":"74644","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.317977,35.435368],[-97.335631,35.485766],[-97.3753,35.485769],[-97.370788,35.43434],[-97.317977,35.435368]]]},"properties":{"id":"73130","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.459173,35.486341],[-97.494541,35.48594],[-97.49455,35.484547],[-97.502425,35.462587],[-97.459169,35.461045],[-97.424059,35.460514],[-97.424017,35.485149],[-97.427781,35.493201],[-97.459173,35.486341]]]},"properties":{"id":"73117","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.60285,35.536819],[-97.601163,35.500529],[-97.547692,35.499503],[-97.547534,35.536809],[-97.60285,35.536819]]]},"properties":{"id":"73112","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.511809,35.486],[-97.49455,35.484547],[-97.494541,35.48594],[-97.494179,35.55115],[-97.495668,35.553683],[-97.511934,35.551129],[-97.512034,35.536852],[-97.512393,35.493377],[-97.511809,35.486]]]},"properties":{"id":"73105","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.530127,35.464265],[-97.539099,35.435229],[-97.539069,35.406224],[-97.512514,35.406208],[-97.512456,35.462404],[-97.525797,35.464268],[-97.530127,35.464265]]]},"properties":{"id":"73109","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.606246,35.609164],[-97.531565,35.601594],[-97.531626,35.60902],[-97.600381,35.623659],[-97.606246,35.609164]]]},"properties":{"id":"73134","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.090588,36.216248],[-96.083378,36.130889],[-95.992178,36.131829],[-95.990272,36.132803],[-95.998068,36.147911],[-96.001055,36.161294],[-96.00137,36.205917],[-96.090588,36.216248]]]},"properties":{"id":"74127","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.526159,36.361035],[-96.556152,36.301947],[-96.43783,36.293681],[-96.323969,36.289989],[-96.211939,36.304234],[-96.206157,36.446788],[-96.246177,36.476073],[-96.435257,36.611666],[-96.542015,36.561113],[-96.474252,36.415443],[-96.526159,36.361035]]]},"properties":{"id":"74035","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.109674,36.999194],[-96.018168,36.832741],[-96.000613,36.834057],[-95.862878,36.887455],[-95.840842,36.999207],[-96.109674,36.999194]]]},"properties":{"id":"74022","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.96934,36.220575],[-95.964845,36.263355],[-96.040098,36.262288],[-96.103162,36.256022],[-96.090588,36.216248],[-96.00137,36.205917],[-95.96934,36.220575]]]},"properties":{"id":"74126","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.618053,36.91151],[-94.83034,36.998767],[-94.81943,36.94364],[-94.6797,36.89311],[-94.618053,36.91151]]]},"properties":{"id":"74363","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.572358,36.281951],[-96.818891,36.224701],[-96.804712,36.15904],[-96.677819,36.224965],[-96.572358,36.281951]]]},"properties":{"id":"74045","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.926003,36.461499],[-96.924973,36.28993],[-96.818891,36.224701],[-96.572358,36.281951],[-96.570307,36.286381],[-96.556152,36.301947],[-96.526159,36.361035],[-96.844118,36.503882],[-96.926003,36.461499]]]},"properties":{"id":"74058","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.553285,36.231815],[-96.606338,36.231548],[-96.606328,36.217441],[-96.553185,36.21962],[-96.553285,36.231815]]]},"properties":{"id":"74034","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.819677,36.072154],[-96.944786,36.040066],[-96.909611,35.955795],[-96.873585,35.956667],[-96.785555,36.053891],[-96.819677,36.072154]]]},"properties":{"id":"74062","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.586257,34.851861],[-95.57088,34.845619],[-95.569161,34.86938],[-95.588499,34.869232],[-95.586257,34.851861]]]},"properties":{"id":"74546","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.812089,34.738312],[-95.594656,34.737915],[-95.749928,34.762021],[-95.776192,34.787354],[-95.812089,34.738312]]]},"properties":{"id":"74528","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.818061,35.076043],[-96.880585,35.101472],[-96.984293,35.043912],[-96.964976,34.938149],[-96.887362,34.926849],[-96.853051,34.928819],[-96.818061,35.076043]]]},"properties":{"id":"74826","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.578241,34.441311],[-95.679434,34.506831],[-95.73176,34.506781],[-95.779156,34.417568],[-95.779225,34.317338],[-95.588137,34.375486],[-95.578241,34.441311]]]},"properties":{"id":"74557","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.010509,34.549818],[-95.263552,34.507027],[-95.279951,34.496394],[-95.275073,34.444319],[-95.184829,34.423152],[-94.990057,34.507088],[-95.010509,34.549818]]]},"properties":{"id":"74558","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.179928,36.997208],[-101.07191,36.863168],[-100.945463,36.998416],[-101.179928,36.997208]]]},"properties":{"id":"73951","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.001055,36.161294],[-95.998068,36.147911],[-95.985993,36.151754],[-95.99346,36.163464],[-96.001055,36.161294]]]},"properties":{"id":"74103","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.972354,36.800526],[-95.809761,36.76418],[-95.809894,36.81497],[-95.862878,36.887455],[-96.000613,36.834057],[-95.972354,36.800526]]]},"properties":{"id":"74029","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.554265,36.113896],[-94.56517,36.176312],[-94.73522,36.161675],[-94.797285,36.104426],[-94.732508,36.074472],[-94.554265,36.113896]]]},"properties":{"id":"74964","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.109311,36.593588],[-98.110473,36.767649],[-98.109747,36.854086],[-98.240044,36.806873],[-98.253042,36.651863],[-98.140274,36.593619],[-98.109311,36.593588]]]},"properties":{"id":"73749","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.88228,34.325328],[-96.005083,34.343833],[-95.987765,34.156798],[-95.889359,34.227769],[-95.88228,34.325328]]]},"properties":{"id":"74555","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.753048,34.593847],[-95.73176,34.506781],[-95.679434,34.506831],[-95.601657,34.593981],[-95.753048,34.593847]]]},"properties":{"id":"74540","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.010157,34.583579],[-95.980548,34.734784],[-96.092043,34.680556],[-96.092061,34.585888],[-96.010157,34.583579]]]},"properties":{"id":"74576","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.572327,36.661606],[-99.764409,36.827308],[-99.890793,36.812118],[-100.192295,36.76535],[-100.44091,36.49949],[-100.003762,36.499849],[-99.901763,36.593245],[-99.692391,36.593191],[-99.592313,36.592821],[-99.572327,36.661606]]]},"properties":{"id":"73848","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.575948,35.498699],[-99.399628,35.291323],[-99.364392,35.212837],[-99.364529,35.284115],[-99.329179,35.523357],[-99.514457,35.56633],[-99.575948,35.498699]]]},"properties":{"id":"73644","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.440657,35.116538],[-99.553623,35.16395],[-99.677005,35.10224],[-99.519804,34.958357],[-99.440657,35.116538]]]},"properties":{"id":"73673","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.425013,36.014193],[-98.316521,35.841063],[-98.209951,35.913076],[-98.210134,35.999476],[-98.425013,36.014193]]]},"properties":{"id":"73744","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.585355,34.114459],[-96.549253,34.06244],[-96.465639,33.969029],[-96.320429,33.841054],[-96.313256,33.839173],[-96.27605,33.841714],[-96.258515,34.026904],[-96.401162,34.142741],[-96.573763,34.114436],[-96.585355,34.114459]]]},"properties":{"id":"74701","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.419124,33.771825],[-96.176554,33.803715],[-96.27605,33.841714],[-96.313256,33.839173],[-96.409489,33.783297],[-96.419124,33.771825]]]},"properties":{"id":"74741","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.127714,35.764812],[-95.126802,35.86446],[-95.264845,35.883805],[-95.305056,35.8568],[-95.323696,35.838661],[-95.237263,35.693151],[-95.127714,35.764812]]]},"properties":{"id":"74434","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.245494,36.220427],[-95.312257,36.035322],[-95.252188,35.987845],[-95.100618,36.08193],[-95.059199,36.161545],[-95.005951,36.263852],[-95.167388,36.235457],[-95.245494,36.220427]]]},"properties":{"id":"74352","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.141796,35.420424],[-97.124419,35.275269],[-97.089198,35.289656],[-97.065593,35.362073],[-96.982855,35.463519],[-97.034816,35.550429],[-97.141796,35.420424]]]},"properties":{"id":"74851","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.633369,34.608715],[-96.657673,34.505655],[-96.615363,34.455896],[-96.598664,34.418997],[-96.555125,34.445002],[-96.514139,34.463188],[-96.41421,34.547954],[-96.469037,34.723648],[-96.520988,34.752697],[-96.66994,34.665505],[-96.634846,34.636363],[-96.633369,34.608715]]]},"properties":{"id":"74871","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.14199,34.659261],[-98.42219,34.60932],[-98.492399,34.521854],[-98.487816,34.507606],[-98.24711,34.528868],[-98.156828,34.506967],[-98.142046,34.594248],[-98.14199,34.659261]]]},"properties":{"id":"73501","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.282628,34.63757],[-98.194129,34.709339],[-98.316974,34.827659],[-98.422222,34.811684],[-98.390036,34.69433],[-98.384992,34.694336],[-98.282628,34.63757]]]},"properties":{"id":"73538","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.42219,34.60932],[-98.14199,34.659261],[-98.124176,34.695691],[-98.141778,34.724641],[-98.159323,34.724686],[-98.194129,34.709339],[-98.282628,34.63757],[-98.352084,34.639611],[-98.42218,34.638225],[-98.42219,34.60932]]],[[[-98.808795,34.739683],[-98.614918,34.709779],[-98.595869,34.724692],[-98.488184,34.746089],[-98.463512,34.724426],[-98.390036,34.69433],[-98.422222,34.811684],[-98.545351,34.855104],[-98.619816,34.854953],[-98.789982,34.855673],[-98.808686,34.790575],[-98.808795,34.739683]]]]},"properties":{"id":"73507","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.192491,35.839417],[-95.87291,35.769291],[-95.860875,35.850067],[-95.872761,35.856313],[-95.975982,35.91565],[-96.029543,35.91563],[-96.061205,35.915638],[-96.159545,35.85718],[-96.192491,35.839417]]]},"properties":{"id":"74047","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.565571,35.755145],[-96.565596,35.74459],[-96.547702,35.751128],[-96.565571,35.755145]]]},"properties":{"id":"74046","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.617758,36.625442],[-94.618053,36.91151],[-94.6797,36.89311],[-94.752412,36.799877],[-94.773604,36.667819],[-94.617758,36.625442]]]},"properties":{"id":"74370","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.103419,36.45217],[-95.123529,36.37732],[-95.122056,36.372486],[-95.128807,36.363185],[-95.005615,36.347556],[-95.005749,36.47697],[-95.025261,36.442226],[-95.062598,36.459269],[-95.103419,36.45217]]]},"properties":{"id":"74366","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.833163,36.212423],[-94.869048,36.205153],[-94.851086,36.205195],[-94.833163,36.212423]]]},"properties":{"id":"74368","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.63617,35.91326],[-98.853693,36.016153],[-98.892935,36.021385],[-98.823054,35.956742],[-98.776454,35.878974],[-98.63617,35.91326]]]},"properties":{"id":"73658","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.595856,36.21703],[-99.793001,36.1733],[-99.942857,35.906646],[-99.909938,35.926974],[-99.610942,35.847454],[-99.45189,36.005508],[-99.453619,36.163926],[-99.488795,36.16449],[-99.595856,36.21703]]]},"properties":{"id":"73832","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.658008,36.419325],[-97.586332,36.368855],[-97.461094,36.347085],[-97.46119,36.390371],[-97.542503,36.563739],[-97.640034,36.491586],[-97.658008,36.419325]]]},"properties":{"id":"73738","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.057462,34.401573],[-97.054847,34.637066],[-97.317107,34.532214],[-97.352693,34.506851],[-97.318949,34.376215],[-97.036542,34.375417],[-97.041286,34.385221],[-97.060939,34.389344],[-97.057462,34.401573]]]},"properties":{"id":"73030","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.335907,34.855274],[-97.350693,34.831101],[-97.195128,34.855231],[-97.335907,34.855274]]]},"properties":{"id":"73074","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.926115,36.593575],[-97.74687,36.593256],[-97.642103,36.593462],[-97.669206,36.697489],[-97.893536,36.730871],[-98.03293,36.593522],[-97.996819,36.593605],[-97.926115,36.593575]]]},"properties":{"id":"73766","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.965919,36.796401],[-97.876452,36.998657],[-97.912917,36.998616],[-98.109747,36.854086],[-98.110473,36.767649],[-97.965919,36.796401]]]},"properties":{"id":"73771","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.462459,36.593627],[-97.462326,36.73479],[-97.462309,36.738382],[-97.588308,36.767214],[-97.669206,36.697489],[-97.642103,36.593462],[-97.53375,36.59348],[-97.462459,36.593627]]]},"properties":{"id":"74643","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.440657,35.116538],[-99.519804,34.958357],[-99.31728,34.871489],[-99.409291,35.114509],[-99.440657,35.116538]]]},"properties":{"id":"73547","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.492669,34.725013],[-99.720066,34.76845],[-99.666512,34.550628],[-99.561424,34.536011],[-99.492669,34.725013]]]},"properties":{"id":"73532","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.226087,35.2692],[-95.271993,35.281044],[-95.327152,35.231772],[-95.280936,35.224382],[-95.226087,35.2692]]]},"properties":{"id":"74472","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.300445,34.883631],[-96.292838,34.766804],[-96.196626,34.766997],[-96.132189,35.014295],[-96.171192,35.028774],[-96.299957,34.983166],[-96.300445,34.883631]]]},"properties":{"id":"74531","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.771932,34.259667],[-96.733255,34.205122],[-96.744444,34.286341],[-96.771932,34.259667]]]},"properties":{"id":"73455","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.04938,36.82516],[-97.057042,36.593359],[-96.909279,36.59631],[-96.877424,36.579756],[-96.869303,36.640899],[-96.905861,36.737129],[-96.928638,36.810863],[-97.04938,36.82516]]]},"properties":{"id":"74604","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.251392,36.688421],[-97.39044,36.73137],[-97.462326,36.73479],[-97.462459,36.593627],[-97.345516,36.557001],[-97.308468,36.550148],[-97.184085,36.593991],[-97.251392,36.688421]]]},"properties":{"id":"74653","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.601657,34.593981],[-95.679434,34.506831],[-95.578241,34.441311],[-95.487152,34.444469],[-95.279951,34.496394],[-95.263552,34.507027],[-95.251952,34.5941],[-95.24405,34.68055],[-95.514334,34.74166],[-95.601657,34.593981]]]},"properties":{"id":"74536","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.247041,36.057925],[-97.353066,35.985451],[-97.247096,35.884111],[-97.176813,35.883646],[-97.176699,35.898534],[-97.157951,36.028854],[-97.247041,36.057925]],[[-97.264849,35.927187],[-97.275111,35.92764],[-97.273269,35.942965],[-97.265272,35.94272],[-97.264849,35.927187]]]},"properties":{"id":"73027","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.547487,35.035589],[-97.404381,35.1118],[-97.42754,35.142917],[-97.564637,35.160607],[-97.547487,35.035589]]]},"properties":{"id":"73093","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.184296,34.271573],[-95.156487,34.14245],[-95.104427,34.149775],[-95.026981,34.210587],[-95.026221,34.232531],[-95.158732,34.280376],[-95.184296,34.271573]]]},"properties":{"id":"74754","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.560305,35.356281],[-95.822969,35.419993],[-95.822166,35.372081],[-95.770525,35.181766],[-95.735171,35.192815],[-95.718271,35.188894],[-95.622598,35.215191],[-95.636801,35.195201],[-95.597183,35.164144],[-95.615368,35.128984],[-95.568697,35.103981],[-95.450836,35.210438],[-95.503569,35.25672],[-95.560305,35.356281]]]},"properties":{"id":"74432","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.789719,35.536639],[-95.716305,35.538586],[-95.716347,35.551811],[-95.784181,35.551764],[-95.789719,35.536639]]]},"properties":{"id":"74438","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.358098,35.551807],[-95.360116,35.580731],[-95.393164,35.551759],[-95.358098,35.551807]]],[[[-95.344825,35.536779],[-95.34482,35.435053],[-95.238802,35.435258],[-95.268233,35.551708],[-95.344825,35.536779]]]]},"properties":{"id":"74469","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.166099,36.41517],[-95.356055,36.510218],[-95.39962,36.40883],[-95.122056,36.372486],[-95.123529,36.37732],[-95.166099,36.41517]]]},"properties":{"id":"74330","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.383031,35.624341],[-95.360116,35.580731],[-95.358098,35.551807],[-95.344825,35.536779],[-95.268233,35.551708],[-95.188159,35.574493],[-95.183543,35.601358],[-95.237263,35.693151],[-95.323696,35.838661],[-95.339028,35.848485],[-95.375207,35.837879],[-95.402214,35.806633],[-95.383031,35.624341]]]},"properties":{"id":"74403","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.127714,35.764812],[-95.237263,35.693151],[-95.183543,35.601358],[-95.127491,35.67561],[-95.127714,35.764812]]]},"properties":{"id":"74423","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.531565,35.601594],[-97.540415,35.551125],[-97.511934,35.551129],[-97.495668,35.553683],[-97.497192,35.609133],[-97.531626,35.60902],[-97.531565,35.601594]]]},"properties":{"id":"73114","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.67122,35.537199],[-97.632225,35.536935],[-97.603553,35.536819],[-97.609713,35.562788],[-97.608526,35.580835],[-97.674052,35.565697],[-97.67122,35.537199]]]},"properties":{"id":"73132","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.425029,35.580174],[-97.497192,35.609133],[-97.495668,35.553683],[-97.494179,35.55115],[-97.459096,35.551157],[-97.424931,35.558253],[-97.425029,35.580174]]]},"properties":{"id":"73131","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.335655,35.493029],[-97.388576,35.517852],[-97.39829,35.55828],[-97.424537,35.551115],[-97.427781,35.493201],[-97.424017,35.485149],[-97.3753,35.485769],[-97.335631,35.485766],[-97.331622,35.493051],[-97.335655,35.493029]]]},"properties":{"id":"73141","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.531626,35.60902],[-97.497192,35.609133],[-97.425029,35.580174],[-97.398147,35.584025],[-97.371547,35.625771],[-97.485567,35.638313],[-97.513847,35.638413],[-97.638552,35.63854],[-97.600381,35.623659],[-97.531626,35.60902]]]},"properties":{"id":"73013","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512049,35.478101],[-97.525604,35.478755],[-97.525797,35.464268],[-97.512456,35.462404],[-97.512462,35.462513],[-97.512049,35.478101]]]},"properties":{"id":"73102","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.087755,35.519997],[-95.956893,35.520748],[-95.953568,35.52202],[-95.922725,35.550341],[-95.784043,35.740272],[-95.860875,35.850067],[-95.87291,35.769291],[-95.989372,35.714502],[-96.007093,35.718166],[-96.192689,35.692403],[-96.192563,35.639087],[-96.087755,35.519997]]]},"properties":{"id":"74447","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.844118,36.503882],[-96.474252,36.415443],[-96.542015,36.561113],[-96.564708,36.680658],[-96.689729,36.68827],[-96.869303,36.640899],[-96.877424,36.579756],[-96.844118,36.503882]]]},"properties":{"id":"74637","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.8532,36.99886],[-94.81943,36.94364],[-94.83034,36.998767],[-94.8532,36.99886]]]},"properties":{"id":"74360","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.075715,36.12679],[-97.073748,36.119655],[-97.067695,36.123441],[-97.075715,36.12679]]]},"properties":{"id":"74078","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.591298,34.868309],[-96.585191,34.85804],[-96.562464,34.861686],[-96.578396,34.879203],[-96.591298,34.868309]]]},"properties":{"id":"74844","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.054515,34.637144],[-95.051143,34.666254],[-95.132055,34.680819],[-95.054515,34.637144]]]},"properties":{"id":"74521","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.909938,35.926974],[-99.789981,35.711599],[-99.605795,35.826343],[-99.610942,35.847454],[-99.909938,35.926974]]]},"properties":{"id":"73638","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.992202,36.993224],[-101.84794,36.936006],[-101.829931,36.993969],[-101.992202,36.993224]]]},"properties":{"id":"67950","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.241876,36.709357],[-101.340063,36.499566],[-100.954153,36.499916],[-100.953933,36.67123],[-100.953873,36.717138],[-100.990137,36.712536],[-101.241876,36.709357]]]},"properties":{"id":"73944","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.836416,34.275962],[-99.206199,34.338335],[-99.206905,34.338277],[-98.836182,34.156764],[-98.836416,34.275962]]]},"properties":{"id":"73530","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.000854,36.535298],[-95.92032,36.424227],[-95.884229,36.451702],[-95.879758,36.452729],[-95.856033,36.481561],[-95.809617,36.597476],[-95.809617,36.641581],[-95.917218,36.626491],[-96.000719,36.580513],[-96.000854,36.535298]]]},"properties":{"id":"74061","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.240892,35.255145],[-99.364529,35.284115],[-99.364392,35.212837],[-99.360226,35.116738],[-99.219487,35.116697],[-99.099824,35.116633],[-99.126368,35.218859],[-99.240892,35.255145]]]},"properties":{"id":"73664","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.857654,35.349291],[-98.729336,35.276405],[-98.729217,35.377718],[-98.835037,35.465078],[-98.87394,35.378277],[-98.857654,35.349291]]]},"properties":{"id":"73024","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.264229,36.333898],[-99.381896,36.202815],[-99.257864,36.23158],[-99.264229,36.333898]]]},"properties":{"id":"73857","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.240044,36.806873],[-98.109747,36.854086],[-98.201552,36.956745],[-98.334132,36.898767],[-98.240044,36.806873]]],[[[-98.503474,36.99867],[-98.485976,36.819016],[-98.359712,36.928015],[-98.363776,36.998034],[-98.503474,36.99867]]]]},"properties":{"id":"73722","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.587364,36.463266],[-98.368332,36.41977],[-98.319527,36.463239],[-98.342113,36.550113],[-98.69352,36.549959],[-98.729288,36.557292],[-98.587364,36.463266]]]},"properties":{"id":"73716","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.753048,34.593847],[-95.986067,34.533922],[-96.005083,34.343833],[-95.88228,34.325328],[-95.779156,34.417568],[-95.73176,34.506781],[-95.753048,34.593847]]]},"properties":{"id":"74569","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.401162,34.142741],[-96.258515,34.026904],[-96.094638,34.099131],[-96.038042,34.157564],[-96.102196,34.186541],[-96.198041,34.17689],[-96.27841,34.280281],[-96.302975,34.273252],[-96.372795,34.216311],[-96.401162,34.142741]]]},"properties":{"id":"74729","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.366694,36.860167],[-100.423361,37.001104],[-100.822781,36.998831],[-100.70336,36.83541],[-100.366694,36.860167]]]},"properties":{"id":"73938","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.594348,35.686361],[-98.429619,35.55147],[-98.286141,35.528485],[-98.207104,35.72514],[-98.207087,35.768578],[-98.224512,35.763982],[-98.313252,35.718047],[-98.472796,35.710741],[-98.543364,35.725193],[-98.613989,35.729497],[-98.594348,35.686361]]]},"properties":{"id":"73040","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.703187,35.638487],[-98.623247,35.405791],[-98.570445,35.334143],[-98.526444,35.334042],[-98.526446,35.290702],[-98.570625,35.305186],[-98.520245,35.233095],[-98.482613,35.247591],[-98.500266,35.290716],[-98.465212,35.406243],[-98.429619,35.55147],[-98.594348,35.686361],[-98.703187,35.638487]]]},"properties":{"id":"73048","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.038042,34.157564],[-96.094638,34.099131],[-96.094882,33.84735],[-95.859742,33.850376],[-95.991578,34.156815],[-96.038042,34.157564]]]},"properties":{"id":"74723","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.545905,33.924384],[-96.570157,33.818983],[-96.528472,33.894535],[-96.534273,33.904738],[-96.537761,33.905847],[-96.535483,33.91102],[-96.545905,33.924384]]]},"properties":{"id":"74731","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.995943,35.651224],[-94.861029,35.722551],[-94.846392,35.74056],[-94.900169,35.827103],[-95.078284,35.638697],[-95.012741,35.638397],[-94.995943,35.651224]]]},"properties":{"id":"74451","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.012741,35.638397],[-95.109663,35.495093],[-95.049933,35.458894],[-94.944608,35.402457],[-94.833163,35.507026],[-94.854159,35.638699],[-94.995943,35.651224],[-95.012741,35.638397]]]},"properties":{"id":"74962","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.450752,34.114876],[-95.365422,33.982796],[-95.330286,34.142384],[-95.450752,34.114876]]]},"properties":{"id":"74756","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.502479,33.875199],[-95.426893,33.939228],[-95.331929,33.879818],[-95.230865,33.959512],[-95.365422,33.982796],[-95.450752,34.114876],[-95.442215,34.156869],[-95.582525,34.132005],[-95.597593,33.941496],[-95.502479,33.875199]]]},"properties":{"id":"74743","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.002069,36.906472],[-102.906951,36.848676],[-102.578655,36.995127],[-103.002069,36.906472]]]},"properties":{"id":"73946","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.618505,35.377116],[-97.580653,35.363062],[-97.537536,35.363293],[-97.53906,35.404608],[-97.601021,35.420721],[-97.618671,35.406183],[-97.618505,35.377116]]]},"properties":{"id":"73159","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.423569,35.420739],[-97.459196,35.42081],[-97.459302,35.391399],[-97.459227,35.37734],[-97.441384,35.363205],[-97.388238,35.377363],[-97.388364,35.398279],[-97.423569,35.420739]]]},"properties":{"id":"73135","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.653916,35.406195],[-97.671374,35.391654],[-97.671369,35.377151],[-97.618505,35.377116],[-97.618671,35.406183],[-97.636236,35.394465],[-97.653916,35.406195]]]},"properties":{"id":"73169","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.14201,35.116252],[-96.984293,35.043912],[-96.880585,35.101472],[-96.897016,35.145117],[-97.142215,35.188915],[-97.14199,35.130725],[-97.14201,35.116252]]]},"properties":{"id":"74852","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512514,35.406208],[-97.512235,35.377213],[-97.459227,35.37734],[-97.459302,35.391399],[-97.512514,35.406208]]]},"properties":{"id":"73149","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.388238,35.377363],[-97.266923,35.372667],[-97.317977,35.435368],[-97.370788,35.43434],[-97.378985,35.434541],[-97.388364,35.398279],[-97.388238,35.377363]]]},"properties":{"id":"73150","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.151524,34.418676],[-96.145326,34.491003],[-96.216243,34.46211],[-96.151524,34.418676]]]},"properties":{"id":"74556","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.141778,34.724641],[-98.124145,34.753686],[-98.187341,34.753667],[-98.188331,34.739143],[-98.159303,34.739228],[-98.159323,34.724686],[-98.141778,34.724641]]]},"properties":{"id":"73567","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.142046,34.594248],[-97.687555,34.615655],[-97.668483,34.693492],[-97.724769,34.715561],[-98.089085,34.753635],[-98.124176,34.695691],[-98.14199,34.659261],[-98.142046,34.594248]]]},"properties":{"id":"73055","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.46408,34.073775],[-98.452771,34.275396],[-98.609656,34.246249],[-98.610158,34.157098],[-98.46408,34.073775]]]},"properties":{"id":"73531","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.521657,36.754339],[-95.431731,36.673569],[-95.34162,36.5977],[-95.14085,36.442323],[-95.103419,36.45217],[-95.062598,36.459269],[-95.040702,36.468577],[-95.005719,36.491925],[-95.023567,36.509992],[-95.043063,36.524669],[-95.02373,36.531043],[-95.054062,36.728817],[-95.238274,36.815245],[-95.39569,36.89857],[-95.43179,36.8876],[-95.431887,36.860405],[-95.521657,36.754339]]]},"properties":{"id":"74301","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.525411,36.521295],[-95.435536,36.379649],[-95.39962,36.40883],[-95.356055,36.510218],[-95.34162,36.5977],[-95.431731,36.673569],[-95.539571,36.615789],[-95.525411,36.521295]]]},"properties":{"id":"74016","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.986073,36.075194],[-96.044624,36.075445],[-96.047539,36.023251],[-96.011898,36.015579],[-95.976272,36.050094],[-95.986073,36.075194]]]},"properties":{"id":"74132","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.622549,36.099563],[-96.590855,36.046471],[-96.548283,36.075665],[-96.622549,36.099563]]]},"properties":{"id":"74052","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.005615,36.347556],[-95.128807,36.363185],[-95.167388,36.235457],[-95.005951,36.263852],[-94.95829,36.292851],[-94.930059,36.335603],[-95.005615,36.347556]]]},"properties":{"id":"74365","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.381022,36.012925],[-99.120006,35.865672],[-99.055603,35.909258],[-99.221875,36.086727],[-99.381022,36.012925]]]},"properties":{"id":"73835","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.901763,36.593245],[-100.003762,36.499849],[-100.000404,36.420899],[-99.793001,36.1733],[-99.595856,36.21703],[-99.695033,36.536852],[-99.6953,36.560974],[-99.901763,36.593245]]]},"properties":{"id":"73843","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.901763,36.593245],[-99.6953,36.560974],[-99.692391,36.593191],[-99.901763,36.593245]]]},"properties":{"id":"73851","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.032866,36.550303],[-97.996819,36.593605],[-98.03293,36.593522],[-98.032908,36.564838],[-98.032866,36.550303]]]},"properties":{"id":"73743","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.970924,36.739827],[-99.265474,36.999527],[-99.456203,36.999471],[-99.367001,36.762229],[-99.295283,36.638221],[-99.024156,36.661591],[-98.970924,36.739827]]]},"properties":{"id":"73842","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.002563,37.001706],[-99.890793,36.812118],[-99.764409,36.827308],[-99.774192,37.000976],[-100.002563,37.001706]]]},"properties":{"id":"73855","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.450836,35.210438],[-95.568697,35.103981],[-95.481046,35.029263],[-95.349233,35.058514],[-95.323314,35.188977],[-95.280936,35.224382],[-95.327152,35.231772],[-95.450836,35.210438]]]},"properties":{"id":"74561","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.04731,35.057658],[-95.023369,35.096146],[-95.121261,35.103809],[-95.10357,35.057523],[-95.04731,35.057658]]]},"properties":{"id":"74943","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.123007,35.242083],[-96.122846,35.289887],[-96.126441,35.289957],[-96.123007,35.242083]]],[[[-96.123007,35.242083],[-96.071963,35.188754],[-95.990279,35.150838],[-95.965551,35.152496],[-95.981309,35.303263],[-95.981308,35.303383],[-96.090718,35.289837],[-96.123007,35.242083]]]]},"properties":{"id":"74839","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.676981,34.042209],[-97.973388,33.943771],[-97.630676,33.979289],[-97.676981,34.042209]]]},"properties":{"id":"73569","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.772205,34.289236],[-97.562575,34.289182],[-97.562709,34.332579],[-97.597512,34.405272],[-97.737308,34.376419],[-97.788533,34.289261],[-97.772205,34.289236]]]},"properties":{"id":"73442","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.657673,34.505655],[-96.69502,34.505658],[-96.615363,34.455896],[-96.657673,34.505655]]],[[[-96.922685,34.911109],[-96.91511,34.723688],[-96.758528,34.709287],[-96.66994,34.665505],[-96.520988,34.752697],[-96.516736,34.871781],[-96.665145,34.928207],[-96.853051,34.928819],[-96.887362,34.926849],[-96.922685,34.911109]],[[-96.591298,34.868309],[-96.578396,34.879203],[-96.562464,34.861686],[-96.585191,34.85804],[-96.591298,34.868309]]]]},"properties":{"id":"74820","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.819974,36.765052],[-96.689729,36.68827],[-96.564708,36.680658],[-96.526161,36.998713],[-96.749838,36.998988],[-96.828609,36.818054],[-96.819974,36.765052]]]},"properties":{"id":"74652","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.014491,35.969336],[-97.729215,35.907193],[-97.747378,36.036355],[-98.006744,36.015126],[-98.014491,35.969336]]]},"properties":{"id":"73734","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.727837,34.7706],[-94.768725,34.679578],[-94.680295,34.612065],[-94.458743,34.594024],[-94.456083,34.681563],[-94.717621,34.807078],[-94.727837,34.7706]]]},"properties":{"id":"74939","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.522958,34.956421],[-94.699023,34.966859],[-94.781566,34.920404],[-94.522958,34.956421]]]},"properties":{"id":"74940","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.873585,35.956667],[-96.823139,35.840538],[-96.619295,35.872408],[-96.619297,35.876609],[-96.622847,36.047063],[-96.785555,36.053891],[-96.873585,35.956667]]]},"properties":{"id":"74023","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.944786,36.040066],[-97.157951,36.028854],[-97.176699,35.898534],[-97.070088,35.854993],[-97.018566,35.869476],[-96.927126,35.898554],[-96.909611,35.955795],[-96.944786,36.040066]]]},"properties":{"id":"74059","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.982855,35.463519],[-96.823948,35.421855],[-96.771232,35.550346],[-96.840061,35.564853],[-96.981544,35.601055],[-97.034812,35.564924],[-97.034816,35.550429],[-96.982855,35.463519]]]},"properties":{"id":"74855","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.771232,35.550346],[-96.823948,35.421855],[-96.776695,35.410886],[-96.715715,35.41336],[-96.623699,35.400722],[-96.621073,35.610148],[-96.662024,35.608231],[-96.771232,35.550346]]]},"properties":{"id":"74864","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.485567,35.638313],[-97.371547,35.625771],[-97.371659,35.647787],[-97.355312,35.739842],[-97.478388,35.768807],[-97.476057,35.696303],[-97.485567,35.638313]]]},"properties":{"id":"73034","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.264849,35.927187],[-97.265272,35.94272],[-97.273269,35.942965],[-97.275111,35.92764],[-97.264849,35.927187]]]},"properties":{"id":"73050","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.074043,33.839871],[-97.166851,33.840899],[-97.205705,33.802908],[-97.095644,33.729222],[-97.074043,33.839871]]]},"properties":{"id":"73459","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.294494,34.07129],[-97.369488,33.998495],[-97.30782,33.950052],[-97.078969,34.036181],[-97.265097,34.071203],[-97.294494,34.07129]]]},"properties":{"id":"73453","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.404381,35.1118],[-97.547487,35.035589],[-97.617795,34.975225],[-97.494503,34.942541],[-97.423927,34.942535],[-97.342898,34.971496],[-97.380346,35.083259],[-97.404381,35.1118]]]},"properties":{"id":"73080","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.680624,34.194066],[-94.576462,33.951469],[-94.477299,33.940932],[-94.466412,34.330735],[-94.471571,34.330775],[-94.680624,34.194066]]]},"properties":{"id":"74734","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.064289,33.910097],[-94.923527,33.81768],[-94.929647,33.974395],[-94.938083,34.019434],[-94.997601,34.024399],[-95.017433,33.990061],[-95.014031,33.939218],[-95.034889,33.944699],[-95.064289,33.910097]]]},"properties":{"id":"74736","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.034889,33.944699],[-95.014031,33.939218],[-95.017433,33.990061],[-95.034889,33.944699]]]},"properties":{"id":"74750","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.68101,35.486152],[-95.586133,35.551714],[-95.571064,35.595233],[-95.588892,35.624261],[-95.561029,35.624266],[-95.571034,35.653356],[-95.635494,35.653218],[-95.716347,35.551811],[-95.716305,35.538586],[-95.68101,35.486152]]]},"properties":{"id":"74428","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.55702,36.402065],[-98.451952,36.372111],[-98.368332,36.41977],[-98.587364,36.463266],[-98.55702,36.402065]]]},"properties":{"id":"73729","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.729288,36.557292],[-98.970924,36.739827],[-99.024156,36.661591],[-98.960486,36.402601],[-98.55702,36.402065],[-98.587364,36.463266],[-98.729288,36.557292]]]},"properties":{"id":"73860","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.585355,34.114459],[-96.752551,34.172547],[-96.933671,34.172553],[-96.969627,34.07102],[-96.934806,33.98279],[-96.880895,33.997932],[-96.62488,34.007571],[-96.549253,34.06244],[-96.585355,34.114459]]]},"properties":{"id":"73446","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.122056,36.372486],[-95.39962,36.40883],[-95.435536,36.379649],[-95.435533,36.379449],[-95.43552,36.26381],[-95.44002,36.20597],[-95.245494,36.220427],[-95.167388,36.235457],[-95.128807,36.363185],[-95.122056,36.372486]]]},"properties":{"id":"74361","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.041286,34.385221],[-97.057462,34.401573],[-97.060939,34.389344],[-97.041286,34.385221]]]},"properties":{"id":"73032","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.085667,36.38631],[-97.103671,36.260953],[-97.034051,36.246488],[-96.924973,36.28993],[-96.926003,36.461499],[-97.005201,36.485659],[-97.085667,36.38631]]]},"properties":{"id":"73061","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.103671,36.260953],[-97.23806,36.159319],[-97.075715,36.12679],[-97.067695,36.123441],[-96.890508,36.130998],[-97.034051,36.246488],[-97.103671,36.260953]]]},"properties":{"id":"74075","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.924973,36.28993],[-97.034051,36.246488],[-96.890508,36.130998],[-96.867388,36.116175],[-96.804712,36.15904],[-96.818891,36.224701],[-96.924973,36.28993]]]},"properties":{"id":"74032","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.840842,36.999207],[-95.862878,36.887455],[-95.809894,36.81497],[-95.74384,36.82269],[-95.701737,36.837876],[-95.68375,36.88753],[-95.69616,36.99925],[-95.840842,36.999207]]]},"properties":{"id":"74083","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.090718,35.289837],[-95.981308,35.303383],[-96.123293,35.514871],[-96.242102,35.341506],[-96.126441,35.289957],[-96.122846,35.289887],[-96.090718,35.289837]]]},"properties":{"id":"74880","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.459173,35.486341],[-97.459096,35.551157],[-97.494179,35.55115],[-97.494541,35.48594],[-97.459173,35.486341]]]},"properties":{"id":"73111","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.605459,35.594649],[-97.606246,35.609164],[-97.600381,35.623659],[-97.638552,35.63854],[-97.673951,35.63854],[-97.673962,35.635266],[-97.674036,35.594822],[-97.605459,35.594649]]]},"properties":{"id":"73142","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.525604,35.478755],[-97.512049,35.478101],[-97.511809,35.486],[-97.512393,35.493377],[-97.525438,35.500519],[-97.525604,35.478755]]]},"properties":{"id":"73103","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514085,35.696637],[-97.513847,35.638413],[-97.485567,35.638313],[-97.476057,35.696303],[-97.514085,35.696637]]]},"properties":{"id":"73003","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.539069,35.406224],[-97.539099,35.435229],[-97.601036,35.43525],[-97.601021,35.420721],[-97.53906,35.404608],[-97.539069,35.406224]]]},"properties":{"id":"73119","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.331622,35.493051],[-97.319724,35.507583],[-97.335655,35.493029],[-97.331622,35.493051]]]},"properties":{"id":"73066","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.206157,36.446788],[-96.120647,36.498379],[-96.066365,36.528503],[-96.000854,36.535298],[-96.000719,36.580513],[-96.014146,36.599235],[-96.1658,36.666334],[-96.299335,36.571105],[-96.246177,36.476073],[-96.206157,36.446788]]]},"properties":{"id":"74002","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.142215,35.188915],[-96.897016,35.145117],[-96.776877,35.180698],[-96.776794,35.195427],[-96.843413,35.259299],[-97.089198,35.289656],[-97.124419,35.275269],[-97.141949,35.247361],[-97.142184,35.192086],[-97.142215,35.188915]]]},"properties":{"id":"74873","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.897016,35.145117],[-96.880585,35.101472],[-96.818061,35.076043],[-96.613181,35.021184],[-96.597397,35.05788],[-96.633343,35.108657],[-96.776877,35.180698],[-96.897016,35.145117]]]},"properties":{"id":"74854","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.275073,34.444319],[-95.487152,34.444469],[-95.578241,34.441311],[-95.588137,34.375486],[-95.409183,34.288853],[-95.18514,34.417345],[-95.184829,34.423152],[-95.275073,34.444319]]]},"properties":{"id":"74543","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.440334,34.156863],[-95.343584,34.199538],[-95.184296,34.271573],[-95.158732,34.280376],[-95.136744,34.344838],[-95.081258,34.417435],[-95.18514,34.417345],[-95.409183,34.288853],[-95.440334,34.156863]]]},"properties":{"id":"74562","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.902086,36.322085],[-95.7584,36.32186],[-95.752407,36.34725],[-95.739532,36.378448],[-95.81231,36.443733],[-95.856033,36.481561],[-95.879758,36.452729],[-95.879717,36.448425],[-95.88422,36.449251],[-95.884229,36.451702],[-95.92032,36.424227],[-95.920113,36.3366],[-95.902086,36.322085]]]},"properties":{"id":"74021","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.81231,36.443733],[-95.739532,36.378448],[-95.679677,36.42248],[-95.656629,36.478082],[-95.81231,36.443733]]]},"properties":{"id":"74053","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.670171,35.159363],[-96.671686,35.13044],[-96.651766,35.130579],[-96.652513,35.145312],[-96.670171,35.159363]]]},"properties":{"id":"74830","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.428791,36.995964],[-101.241876,36.709357],[-100.990137,36.712536],[-100.954039,36.73256],[-101.07191,36.863168],[-101.179928,36.997208],[-101.428791,36.995964]]]},"properties":{"id":"73945","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.954039,36.73256],[-100.990137,36.712536],[-100.953873,36.717138],[-100.954039,36.73256]]]},"properties":{"id":"73901","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.208029,34.507134],[-99.202776,34.369888],[-98.99404,34.475339],[-98.931108,34.550969],[-98.931063,34.56548],[-99.082657,34.59278],[-99.208029,34.507134]]]},"properties":{"id":"73570","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.975982,35.91565],[-95.872761,35.856313],[-95.76147,35.915313],[-95.761463,35.918],[-95.868562,36.002861],[-95.904335,36.002804],[-95.931052,35.988313],[-95.979386,35.973712],[-95.975982,35.91565]]]},"properties":{"id":"74008","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.940123,36.075327],[-95.986073,36.075194],[-95.976272,36.050094],[-95.974472,36.046295],[-95.908609,36.046423],[-95.90433,36.075388],[-95.908588,36.075371],[-95.940123,36.075327]]]},"properties":{"id":"74136","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.965101,36.214871],[-95.967113,36.164489],[-95.967157,36.158625],[-95.94033,36.159359],[-95.940324,36.159589],[-95.945038,36.225081],[-95.945275,36.225061],[-95.965101,36.214871]]]},"properties":{"id":"74110","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.970412,36.133364],[-95.940355,36.133236],[-95.94033,36.159359],[-95.967157,36.158625],[-95.970412,36.133364]]]},"properties":{"id":"74104","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.494256,35.955759],[-95.597702,35.894175],[-95.579719,35.885689],[-95.601135,35.878513],[-95.638912,35.822599],[-95.579872,35.769743],[-95.402214,35.806633],[-95.375207,35.837879],[-95.494256,35.955759]]]},"properties":{"id":"74454","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.312257,36.035322],[-95.430666,36.075201],[-95.523052,36.046062],[-95.494256,35.955759],[-95.375207,35.837879],[-95.339028,35.848485],[-95.305056,35.8568],[-95.264845,35.883805],[-95.252188,35.987845],[-95.312257,36.035322]],[[-95.269215,36.002427],[-95.267249,36.004924],[-95.265565,36.002412],[-95.269215,36.002427]]]},"properties":{"id":"74467","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.305056,35.8568],[-95.339028,35.848485],[-95.323696,35.838661],[-95.305056,35.8568]]]},"properties":{"id":"74446","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.269215,36.002427],[-95.265565,36.002412],[-95.267249,36.004924],[-95.269215,36.002427]]]},"properties":{"id":"74477","state_code":"40"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.090198,44.932067],[-117.879778,44.972239],[-118.085734,44.965592],[-118.090198,44.932067]]]},"properties":{"id":"97833","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.961729,44.954862],[-117.078805,44.818157],[-117.046244,44.743107],[-116.783902,45.079022],[-116.867028,45.079176],[-116.961729,44.954862]]]},"properties":{"id":"97840","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.18564,44.587179],[-123.193954,44.699366],[-123.335979,44.692432],[-123.334354,44.557177],[-123.289725,44.566777],[-123.269877,44.565541],[-123.18564,44.587179]]]},"properties":{"id":"97330","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.530105,45.243476],[-122.467768,45.190291],[-122.3526,45.225747],[-122.421826,45.303081],[-122.530105,45.243476]]]},"properties":{"id":"97004","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.290065,45.174051],[-122.127555,45.297752],[-122.179039,45.31261],[-122.235176,45.320465],[-122.38132,45.32806],[-122.421826,45.303081],[-122.3526,45.225747],[-122.290065,45.174051]]]},"properties":{"id":"97023","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.58626,45.250247],[-122.580983,45.199858],[-122.487759,45.171131],[-122.467768,45.190291],[-122.530105,45.243476],[-122.58626,45.250247]]]},"properties":{"id":"97042","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.818467,45.392055],[-122.784978,45.344493],[-122.722743,45.357283],[-122.708577,45.381962],[-122.721413,45.382614],[-122.750439,45.388439],[-122.818467,45.392055]]]},"properties":{"id":"97062","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.687129,45.078305],[-122.830946,45.100464],[-122.850594,45.077245],[-122.838153,45.057578],[-122.693345,45.053861],[-122.688693,45.053191],[-122.687129,45.078305]],[[-122.772484,45.05971],[-122.770192,45.054502],[-122.77855,45.0579],[-122.772484,45.05971]]]},"properties":{"id":"97362","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.071151,43.491533],[-124.286466,43.457072],[-124.393774,43.272737],[-124.29617,43.229277],[-124.06333,43.304274],[-124.071151,43.491533]]]},"properties":{"id":"97420","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.400207,42.615649],[-124.404163,42.277569],[-124.34825,42.234405],[-124.382016,42.706202],[-124.400207,42.615649]]]},"properties":{"id":"97444","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.45294,43.767878],[-121.568607,43.825866],[-121.612678,43.74738],[-121.45294,43.767878]]]},"properties":{"id":"97707","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.347106,43.266165],[-123.264375,43.293616],[-123.2833,43.314972],[-123.347106,43.266165]]]},"properties":{"id":"97495","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.182296,42.999657],[-123.172817,42.940711],[-122.998068,42.869626],[-122.946074,42.991339],[-123.020521,43.041205],[-123.182296,42.999657]]]},"properties":{"id":"97429","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.13751,43.649405],[-123.468229,43.640038],[-123.427791,43.547714],[-123.13268,43.476095],[-123.1075,43.545157],[-123.13751,43.649405]]]},"properties":{"id":"97499","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.850928,45.068383],[-120.495247,45.068549],[-120.371422,44.821568],[-120.385145,44.761165],[-120.003095,44.79244],[-119.763593,44.949103],[-119.850928,45.068383]]]},"properties":{"id":"97830","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.571279,42.697132],[-118.862993,43.129305],[-118.887156,42.919056],[-118.571279,42.697132]]]},"properties":{"id":"97722","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.894832,42.627339],[-122.804343,42.424229],[-122.807133,42.394139],[-122.716472,42.289521],[-122.540284,42.289652],[-122.580397,42.490681],[-122.671319,42.619034],[-122.755663,42.629491],[-122.755409,42.562731],[-122.79701,42.546194],[-122.834133,42.633912],[-122.894832,42.627339]]]},"properties":{"id":"97524","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.319378,42.728242],[-123.54248,42.716776],[-123.584061,42.649181],[-123.44607,42.596838],[-123.229794,42.626206],[-123.195073,42.72405],[-123.319378,42.728242]]]},"properties":{"id":"97497","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.725643,42.197442],[-123.66721,41.995405],[-123.447515,42.124011],[-123.394742,42.21099],[-123.421996,42.192498],[-123.641272,42.206313],[-123.654388,42.230634],[-123.68436,42.243594],[-123.725643,42.197442]]]},"properties":{"id":"97523","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.886574,43.896401],[-122.866603,43.883604],[-122.746584,43.833938],[-122.807034,43.914527],[-122.862202,43.95821],[-122.886574,43.896401]]]},"properties":{"id":"97431","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.549464,44.112715],[-123.470396,44.020646],[-123.430253,44.069036],[-123.443059,44.132815],[-123.486797,44.179909],[-123.546427,44.12602],[-123.549464,44.112715]]]},"properties":{"id":"97461","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.05024,44.044567],[-123.023845,44.033378],[-122.970077,44.025807],[-122.961302,44.023618],[-122.986417,44.081184],[-123.04688,44.0841],[-123.05024,44.044567]]]},"properties":{"id":"97477","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.05024,44.044567],[-123.07926,44.03893],[-123.023845,44.033378],[-123.05024,44.044567]]]},"properties":{"id":"97403","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.58686,44.133155],[-122.357225,44.145604],[-122.338535,44.225293],[-122.597653,44.1755],[-122.58686,44.133155]]]},"properties":{"id":"97488","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.672484,44.061106],[-123.811437,44.20894],[-123.917783,44.227553],[-123.949171,44.182066],[-123.892325,44.084511],[-123.699162,44.05582],[-123.672484,44.061106]]]},"properties":{"id":"97480","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.989214,44.735336],[-124.006045,44.569595],[-123.881417,44.499325],[-123.850766,44.511506],[-123.817346,44.712851],[-123.859053,44.709793],[-123.989214,44.735336]]]},"properties":{"id":"97391","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.995904,44.997108],[-123.911669,44.954545],[-123.948426,45.065708],[-124.016319,45.045746],[-124.00541,45.04495],[-123.982302,45.005159],[-123.982101,44.999388],[-123.995904,44.997108]]]},"properties":{"id":"97368","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.995904,44.997108],[-123.982101,44.999388],[-123.982302,45.005159],[-123.995904,44.997108]]]},"properties":{"id":"97364","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.009066,44.398571],[-123.206209,44.445858],[-123.233552,44.412375],[-123.219696,44.360224],[-123.025864,44.331021],[-123.009066,44.398571]]]},"properties":{"id":"97348","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.052988,44.737792],[-123.108251,44.589228],[-123.013175,44.541032],[-122.921815,44.630449],[-122.969229,44.676834],[-123.052988,44.737792]]]},"properties":{"id":"97322","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.375441,43.846944],[-117.609274,43.279194],[-117.113251,43.538869],[-117.026529,43.770528],[-116.969083,43.937747],[-117.122033,43.93344],[-117.375441,43.846944]]]},"properties":{"id":"97913","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.491572,44.242605],[-117.671318,44.353109],[-117.750612,44.254868],[-117.627214,44.146173],[-117.538242,44.179694],[-117.491572,44.242605]]]},"properties":{"id":"97903","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.026902,43.560189],[-117.026529,43.770528],[-117.113251,43.538869],[-117.026902,43.560189]]]},"properties":{"id":"97901","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.830946,45.100464],[-122.978032,45.134814],[-123.004161,45.160979],[-123.044192,45.093166],[-123.069192,45.075044],[-122.957077,45.076184],[-122.850594,45.077245],[-122.830946,45.100464]]]},"properties":{"id":"97026","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.772484,45.05971],[-122.77855,45.0579],[-122.770192,45.054502],[-122.772484,45.05971]]]},"properties":{"id":"97373","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.579056,45.522781],[-122.578679,45.504821],[-122.537683,45.504093],[-122.537767,45.522494],[-122.53919,45.522506],[-122.579056,45.522781]]]},"properties":{"id":"97216","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.433621,45.527827],[-122.384994,45.500123],[-122.319968,45.498358],[-122.29464,45.541404],[-122.416929,45.566697],[-122.433621,45.527827]]]},"properties":{"id":"97060","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.044507,44.943541],[-123.037305,44.966886],[-123.069192,45.075044],[-123.044192,45.093166],[-123.094234,45.098579],[-123.160352,45.072281],[-123.148863,44.932841],[-123.11315,44.92849],[-123.044507,44.943541]]]},"properties":{"id":"97304","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.895134,45.688502],[-123.875588,45.679512],[-123.882179,45.698874],[-123.895134,45.688502]]]},"properties":{"id":"97147","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.927686,45.561951],[-123.905645,45.553444],[-123.897705,45.568228],[-123.927686,45.561951]]]},"properties":{"id":"97118","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.867064,45.117948],[-117.563318,45.0606],[-117.431939,45.079793],[-117.266572,45.080805],[-117.319964,45.165922],[-117.520421,45.223607],[-117.86024,45.258059],[-117.930504,45.170857],[-117.867064,45.117948]]]},"properties":{"id":"97883","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.915876,45.641345],[-121.365447,45.703487],[-121.440781,45.532741],[-121.482008,45.50497],[-121.443351,45.413503],[-120.879164,45.392414],[-120.825734,45.514977],[-120.914489,45.63524],[-120.915876,45.641345]]]},"properties":{"id":"97058","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.858239,44.822975],[-120.371422,44.821568],[-120.495247,45.068549],[-120.503549,45.08295],[-120.626232,45.083598],[-120.751331,45.010788],[-120.736034,44.996694],[-120.915707,44.823125],[-120.893776,44.823067],[-120.858239,44.822975]]]},"properties":{"id":"97001","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.266127,45.307111],[-121.037313,45.26091],[-121.024033,45.264107],[-120.879164,45.392414],[-121.443351,45.413503],[-121.266127,45.307111]]]},"properties":{"id":"97021","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.082017,45.594628],[-123.103994,45.480617],[-123.063513,45.436289],[-123.029518,45.519988],[-123.013727,45.596998],[-123.044179,45.608409],[-123.082017,45.594628]]]},"properties":{"id":"97113","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.208,45.686151],[-123.235759,45.679099],[-123.232489,45.637785],[-123.208,45.686151]]]},"properties":{"id":"97125","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.373811,45.309739],[-123.115317,45.330275],[-123.096002,45.379328],[-123.284654,45.422056],[-123.373811,45.309739]]]},"properties":{"id":"97148","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.365907,44.829954],[-117.184029,44.664363],[-117.125145,44.589144],[-117.046244,44.743107],[-117.078805,44.818157],[-117.245284,44.874809],[-117.365907,44.829954]]]},"properties":{"id":"97870","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.04269,45.147075],[-118.085734,44.965592],[-117.879778,44.972239],[-117.867064,45.117948],[-117.930504,45.170857],[-118.04269,45.147075]]]},"properties":{"id":"97867","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.757234,44.508853],[-117.849001,44.498842],[-117.822815,44.443464],[-117.666517,44.444268],[-117.660953,44.512385],[-117.757234,44.508853]]]},"properties":{"id":"97819","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.193954,44.699366],[-123.18564,44.587179],[-123.214409,44.491014],[-123.162046,44.51233],[-123.108251,44.589228],[-123.052988,44.737792],[-123.143556,44.74967],[-123.16335,44.72015],[-123.193954,44.699366]]]},"properties":{"id":"97321","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.334354,44.557177],[-123.473933,44.434271],[-123.446364,44.384743],[-123.233552,44.412375],[-123.206209,44.445858],[-123.214409,44.491014],[-123.18564,44.587179],[-123.269877,44.565541],[-123.274183,44.56191],[-123.289725,44.566777],[-123.334354,44.557177]]]},"properties":{"id":"97333","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.471627,44.229049],[-123.378883,44.131547],[-123.303183,44.118634],[-123.153908,44.127498],[-123.11994,44.142436],[-123.165394,44.200021],[-123.199967,44.307563],[-123.455629,44.281121],[-123.471627,44.229049]]]},"properties":{"id":"97448","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.697396,45.436014],[-122.721413,45.382614],[-122.708577,45.381962],[-122.637781,45.398411],[-122.655605,45.422341],[-122.647743,45.438514],[-122.697396,45.436014]]]},"properties":{"id":"97034","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.322934,45.372869],[-122.413676,45.381959],[-122.38132,45.32806],[-122.235176,45.320465],[-122.322934,45.372869]]]},"properties":{"id":"97022","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.253749,45.445045],[-122.322934,45.372869],[-122.235176,45.320465],[-122.179039,45.31261],[-122.026708,45.36583],[-122.001887,45.405738],[-122.023962,45.461434],[-122.16663,45.461124],[-122.253749,45.445045]]]},"properties":{"id":"97055","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.938202,45.272171],[-121.991834,45.363262],[-122.026708,45.36583],[-122.179039,45.31261],[-122.127555,45.297752],[-121.938202,45.272171]]]},"properties":{"id":"97067","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.775612,45.216251],[-121.700841,45.366297],[-121.756941,45.401304],[-122.023962,45.461434],[-122.001887,45.405738],[-121.991834,45.363262],[-121.938202,45.272171],[-121.775612,45.216251]]]},"properties":{"id":"97049","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.978032,45.134814],[-122.830946,45.100464],[-122.687129,45.078305],[-122.695328,45.125626],[-122.729445,45.144178],[-122.87473,45.17836],[-122.885531,45.200421],[-122.978032,45.134814]]]},"properties":{"id":"97071","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.89903,46.105746],[-123.879332,46.179336],[-123.919347,46.194844],[-123.981853,46.172586],[-123.934147,46.046854],[-123.899167,46.061549],[-123.89903,46.105746]]]},"properties":{"id":"97146","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.963003,45.87301],[-123.948361,45.825193],[-123.938747,45.880082],[-123.963003,45.87301]]]},"properties":{"id":"97145","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.819371,45.833956],[-122.947362,45.857157],[-122.906688,45.797278],[-122.819371,45.833956]]]},"properties":{"id":"97053","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.269285,42.954486],[-124.480031,42.954963],[-124.543499,42.855129],[-124.277811,42.85765],[-124.269285,42.954486]]]},"properties":{"id":"97450","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.205207,42.90349],[-124.104971,42.966926],[-124.2037,42.991857],[-124.253999,42.95109],[-124.205207,42.90349]]]},"properties":{"id":"97414","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.172817,42.940711],[-123.182296,42.999657],[-123.323633,42.944546],[-123.294558,42.91891],[-123.172817,42.940711]]]},"properties":{"id":"97417","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.005372,43.332595],[-123.08127,43.363316],[-123.076588,43.316975],[-123.005372,43.332595]]]},"properties":{"id":"97447","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.357596,43.106277],[-123.33148,43.34119],[-123.361238,43.364885],[-123.460721,43.366615],[-123.534308,43.299442],[-123.701961,43.112444],[-123.575342,43.087359],[-123.549305,43.134546],[-123.507763,43.113324],[-123.379837,43.113323],[-123.357596,43.106277]]]},"properties":{"id":"97471","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.254379,42.837182],[-123.54248,42.716776],[-123.319378,42.728242],[-123.254379,42.837182]]]},"properties":{"id":"97442","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.701961,43.112444],[-123.596785,43.050324],[-123.569875,43.090161],[-123.575342,43.087359],[-123.701961,43.112444]]]},"properties":{"id":"97416","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.153484,43.817014],[-124.17002,43.831546],[-124.215121,43.667298],[-124.207839,43.668736],[-124.100933,43.709815],[-124.102829,43.71983],[-124.124697,43.776025],[-124.153484,43.817014]]]},"properties":{"id":"97441","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.237598,43.83718],[-123.277097,43.779907],[-123.257956,43.714971],[-123.13751,43.649405],[-123.1075,43.545157],[-122.94596,43.68623],[-122.870588,43.757052],[-122.896566,43.853853],[-123.183587,43.866768],[-123.237598,43.83718]]]},"properties":{"id":"97424","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.32989,43.340653],[-123.34132,43.321269],[-123.309774,43.335923],[-123.32989,43.340653]]]},"properties":{"id":"97494","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.360961,45.505791],[-120.005711,45.350614],[-120.191102,45.743509],[-120.653568,45.737247],[-120.649278,45.732027],[-120.375793,45.528385],[-120.360961,45.505791]]]},"properties":{"id":"97812","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.153168,44.253888],[-119.013813,44.177316],[-118.974146,44.212695],[-118.807155,44.354531],[-119.012289,44.347436],[-119.153168,44.253888]]]},"properties":{"id":"97820","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.43664,44.561736],[-119.49074,44.710954],[-119.671729,44.837869],[-119.652697,44.750086],[-119.654453,44.534133],[-119.653992,44.514618],[-119.43664,44.561736]]]},"properties":{"id":"97848","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.227435,44.039981],[-118.816631,43.974302],[-118.530471,43.63311],[-118.230045,43.779066],[-118.227435,44.039981]]]},"properties":{"id":"97904","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.214856,42.37641],[-118.702497,42.319428],[-118.197189,41.996995],[-118.173531,42.367997],[-118.214856,42.37641]]]},"properties":{"id":"97710","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.117086,45.539795],[-122.070104,45.523389],[-121.942646,45.598563],[-121.960532,45.631559],[-122.124022,45.577817],[-122.117086,45.539795]]]},"properties":{"id":"97014","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.229769,42.625268],[-123.17072,42.431526],[-122.995851,42.579015],[-122.993143,42.645531],[-123.229769,42.625268]]]},"properties":{"id":"97537","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.480122,42.505636],[-123.171057,42.431345],[-123.17072,42.431526],[-123.229769,42.625268],[-123.229794,42.626206],[-123.44607,42.596838],[-123.480122,42.505636]]]},"properties":{"id":"97526","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.055762,44.462316],[-120.885729,44.476433],[-120.817137,44.669126],[-120.833962,44.775495],[-120.893776,44.823067],[-120.915707,44.823125],[-121.090579,44.824171],[-121.276701,44.607195],[-121.286418,44.597609],[-121.299209,44.52558],[-121.291323,44.532624],[-121.115596,44.527482],[-121.055762,44.462316]]]},"properties":{"id":"97741","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.817137,44.669126],[-120.385383,44.755934],[-120.385145,44.761165],[-120.371422,44.821568],[-120.858239,44.822975],[-120.833962,44.775495],[-120.817137,44.669126]]]},"properties":{"id":"97711","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.933944,42.082371],[-121.678236,42.000319],[-121.505749,42.065072],[-121.470855,42.076183],[-121.513811,42.201412],[-121.676655,42.364922],[-121.727215,42.261403],[-121.928733,42.127911],[-121.933944,42.082371]],[[-121.82528,42.127397],[-121.815881,42.130401],[-121.80959,42.126235],[-121.824336,42.12278],[-121.82528,42.127397]]]},"properties":{"id":"97603","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.061084,42.763213],[-121.986158,42.163045],[-121.928733,42.127911],[-121.727215,42.261403],[-121.809895,42.385116],[-121.814464,42.383968],[-122.051077,42.590928],[-122.061084,42.763213]]]},"properties":{"id":"97601","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.477661,42.725423],[-120.595295,43.092781],[-120.612604,43.092777],[-120.859384,42.990843],[-120.764886,42.719144],[-120.477661,42.725423]]]},"properties":{"id":"97640","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.807034,43.914527],[-122.746584,43.833938],[-122.589615,43.821591],[-122.807034,43.914527]]]},"properties":{"id":"97452","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.303183,44.118634],[-123.378883,44.131547],[-123.443059,44.132815],[-123.430253,44.069036],[-123.303183,44.118634]]]},"properties":{"id":"97437","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.025864,44.331021],[-123.219696,44.360224],[-123.199967,44.307563],[-123.165394,44.200021],[-122.980013,44.200167],[-122.905515,44.207574],[-122.874105,44.258893],[-122.884659,44.302112],[-123.025864,44.331021]]]},"properties":{"id":"97446","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.071441,44.614061],[-124.075746,44.523856],[-124.02773,44.611803],[-124.071441,44.614061]]]},"properties":{"id":"97366","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.047718,44.858951],[-124.067935,44.765092],[-124.030677,44.775239],[-124.02026,44.780431],[-124.030363,44.85213],[-124.04071,44.863065],[-124.047718,44.858951]]]},"properties":{"id":"97341","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.227963,43.30563],[-118.214856,42.37641],[-118.173531,42.367997],[-117.610714,41.998542],[-117.026902,43.560189],[-117.113251,43.538869],[-117.609274,43.279194],[-118.227963,43.30563]]]},"properties":{"id":"97910","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.98753,44.91119],[-122.99836,44.88517],[-123.00874,44.84288],[-122.9056,44.845646],[-122.813142,44.883926],[-122.80287,44.9008],[-122.851594,44.964268],[-122.95855,44.94891],[-122.98753,44.91119]]]},"properties":{"id":"97317","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.840504,45.219142],[-122.838528,45.218665],[-122.844443,45.223778],[-122.841617,45.219389],[-122.840504,45.219142]]]},"properties":{"id":"97020","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.004161,45.160979],[-122.978032,45.134814],[-122.885531,45.200421],[-122.882506,45.255313],[-122.974568,45.279932],[-122.999677,45.26082],[-123.004161,45.160979]]]},"properties":{"id":"97137","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.686754,45.632388],[-119.75942,45.39233],[-119.455453,45.588446],[-119.434698,45.756433],[-119.622975,45.748389],[-119.686754,45.632388]]]},"properties":{"id":"97839","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.455453,45.588446],[-119.145442,45.499228],[-119.137028,45.494705],[-119.109765,45.775406],[-119.32373,45.783636],[-119.434698,45.756433],[-119.455453,45.588446]]]},"properties":{"id":"97826","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.382007,45.835682],[-119.508062,45.816584],[-119.624033,45.833843],[-119.623313,45.80639],[-119.622975,45.748389],[-119.434698,45.756433],[-119.32373,45.783636],[-119.00699,45.887275],[-118.976663,45.915737],[-118.97681,46.00027],[-119.265782,45.939035],[-119.382007,45.835682]]]},"properties":{"id":"97838","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.620312,45.535139],[-122.666746,45.535095],[-122.669003,45.527626],[-122.667093,45.523028],[-122.620153,45.52371],[-122.620312,45.535139]]]},"properties":{"id":"97232","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.743706,45.476444],[-122.743859,45.516665],[-122.748383,45.519528],[-122.802686,45.517455],[-122.772005,45.476254],[-122.743706,45.476444]]]},"properties":{"id":"97225","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.26322,44.989141],[-123.246456,44.897649],[-123.228657,44.897509],[-123.148863,44.932841],[-123.160352,45.072281],[-123.26322,44.989141]]]},"properties":{"id":"97371","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.43193,45.179475],[-123.572263,45.097642],[-123.576487,45.023897],[-123.550869,45.02891],[-123.43193,45.179475]]]},"properties":{"id":"97396","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.656841,45.379331],[-120.674055,45.083713],[-120.626232,45.083598],[-120.503549,45.08295],[-120.469596,45.476942],[-120.656841,45.379331]]]},"properties":{"id":"97033","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.882179,45.698874],[-123.948583,45.718753],[-123.946516,45.656138],[-123.895134,45.688502],[-123.882179,45.698874]]]},"properties":{"id":"97130","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.658137,45.37789],[-123.991526,45.348846],[-123.971475,45.27567],[-123.938204,45.161089],[-123.909138,45.125159],[-123.78437,45.104025],[-123.847065,45.236802],[-123.646345,45.336237],[-123.658137,45.37789]]]},"properties":{"id":"97112","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.773876,45.47196],[-122.816966,45.441103],[-122.853466,45.426371],[-122.851847,45.421032],[-122.744021,45.415311],[-122.743991,45.426023],[-122.743626,45.476439],[-122.743706,45.476444],[-122.772005,45.476254],[-122.773876,45.47196]]]},"properties":{"id":"97223","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.218092,45.573981],[-123.103994,45.480617],[-123.082017,45.594628],[-123.232489,45.637785],[-123.235759,45.679099],[-123.208,45.686151],[-123.240537,45.705297],[-123.263581,45.706827],[-123.31281,45.707075],[-123.339585,45.676881],[-123.218092,45.573981]]]},"properties":{"id":"97116","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.121433,45.253812],[-123.120901,45.242066],[-123.102372,45.250346],[-123.121433,45.253812]]]},"properties":{"id":"97127","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.084192,45.286585],[-123.102372,45.250346],[-123.120901,45.242066],[-123.13698,45.16708],[-123.094234,45.098579],[-123.044192,45.093166],[-123.004161,45.160979],[-122.999677,45.26082],[-123.084192,45.286585]]]},"properties":{"id":"97114","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.275407,44.953204],[-117.245284,44.874809],[-117.078805,44.818157],[-116.961729,44.954862],[-117.056259,45.079628],[-117.266572,45.080805],[-117.431939,45.079793],[-117.275407,44.953204]]]},"properties":{"id":"97834","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.726396,44.7034],[-123.597945,44.455757],[-123.566336,44.720296],[-123.787346,44.757233],[-123.726396,44.7034]]]},"properties":{"id":"97326","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.693345,45.053861],[-122.58478,44.904624],[-122.499716,44.918044],[-122.688693,45.053191],[-122.693345,45.053861]]]},"properties":{"id":"97375","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.579235,45.432535],[-122.577203,45.424081],[-122.573975,45.397787],[-122.571079,45.3914],[-122.513788,45.409314],[-122.485745,45.426259],[-122.579235,45.432535]]]},"properties":{"id":"97015","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.700841,45.366297],[-121.775612,45.216251],[-121.684974,45.283667],[-121.700841,45.366297]]]},"properties":{"id":"97028","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.722596,45.193788],[-122.717769,45.299067],[-122.849723,45.259654],[-122.882506,45.255313],[-122.885531,45.200421],[-122.87473,45.17836],[-122.722596,45.193788]],[[-122.840504,45.219142],[-122.841617,45.219389],[-122.844443,45.223778],[-122.838528,45.218665],[-122.840504,45.219142]]]},"properties":{"id":"97002","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.486584,45.461132],[-122.389346,45.4613],[-122.282652,45.468373],[-122.319968,45.498358],[-122.384994,45.500123],[-122.482949,45.491962],[-122.496748,45.460982],[-122.486584,45.461132]]]},"properties":{"id":"97080","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.938948,45.882336],[-123.739513,45.851544],[-123.753929,45.825638],[-123.46986,45.917497],[-123.484247,45.97975],[-123.899167,46.061549],[-123.934147,46.046854],[-123.937434,45.922153],[-123.938948,45.882336]]]},"properties":{"id":"97138","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.89903,46.105746],[-123.517949,46.176139],[-123.467329,46.212948],[-123.879332,46.179336],[-123.89903,46.105746]]]},"properties":{"id":"97103","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.739513,45.851544],[-123.912931,45.783346],[-123.98594,45.760238],[-123.948583,45.718753],[-123.882179,45.698874],[-123.875588,45.679512],[-123.874352,45.609957],[-123.849897,45.607891],[-123.753929,45.825638],[-123.739513,45.851544]]]},"properties":{"id":"97131","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.987213,45.862907],[-123.1097,45.895306],[-123.123826,45.869882],[-123.011137,45.751458],[-122.915073,45.693891],[-122.806426,45.840912],[-122.819371,45.833956],[-122.906688,45.797278],[-122.947362,45.857157],[-122.987213,45.862907]]]},"properties":{"id":"97056","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.929103,45.653055],[-122.872032,45.572073],[-122.772113,45.562938],[-122.764308,45.585316],[-122.763775,45.654424],[-122.76241,45.656438],[-122.785292,45.858331],[-122.806426,45.840912],[-122.915073,45.693891],[-122.929103,45.653055]]]},"properties":{"id":"97231","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.383546,44.050062],[-121.203499,44.051151],[-121.107753,43.91849],[-120.747881,43.87755],[-120.916192,44.154237],[-121.102792,44.171689],[-121.353699,44.300553],[-121.392717,44.294865],[-121.51003,44.217757],[-121.819083,44.050909],[-121.975444,43.855513],[-121.383546,44.050062]]]},"properties":{"id":"97701","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.404163,42.277569],[-124.354027,42.103539],[-124.113698,42.152708],[-124.166905,42.279842],[-124.34825,42.234405],[-124.404163,42.277569]]]},"properties":{"id":"97415","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.612678,43.74738],[-121.679381,43.520816],[-121.650571,43.524595],[-121.45294,43.767878],[-121.612678,43.74738]]]},"properties":{"id":"97739","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.596785,43.050324],[-123.393005,43.013402],[-123.379837,43.113323],[-123.507763,43.113324],[-123.569875,43.090161],[-123.596785,43.050324]]]},"properties":{"id":"97496","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.671729,44.837869],[-119.49074,44.710954],[-119.322869,44.621681],[-119.319997,44.995534],[-119.593831,44.995412],[-119.671987,44.994424],[-119.67134,44.899313],[-119.671729,44.837869]]]},"properties":{"id":"97864","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.580397,42.490681],[-122.655397,42.62432],[-122.671319,42.619034],[-122.580397,42.490681]]]},"properties":{"id":"97522","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.725643,42.197442],[-123.856786,42.141132],[-123.821472,41.995473],[-123.66721,41.995405],[-123.725643,42.197442]]]},"properties":{"id":"97534","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.809895,42.385116],[-121.529218,42.45757],[-121.440338,42.542533],[-121.448885,42.549165],[-121.977375,42.732971],[-121.94152,42.581442],[-121.814464,42.383968],[-121.809895,42.385116]]]},"properties":{"id":"97624","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.676655,42.364922],[-121.513811,42.201412],[-121.535481,42.366034],[-121.676655,42.364922]]]},"properties":{"id":"97625","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.747831,42.559721],[-119.995196,42.370428],[-119.841075,42.354636],[-119.747831,42.559721]]]},"properties":{"id":"97637","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.023043,43.962495],[-123.892325,44.084511],[-123.949171,44.182066],[-124.12663,44.190471],[-124.163461,43.876927],[-124.023043,43.962495]]]},"properties":{"id":"97439","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.153908,44.127498],[-123.303183,44.118634],[-123.318879,43.969771],[-123.100558,44.038963],[-123.101024,44.063215],[-123.153908,44.127498]]]},"properties":{"id":"97402","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.42758,43.984951],[-123.375495,43.890987],[-123.318879,43.969771],[-123.303183,44.118634],[-123.430253,44.069036],[-123.470396,44.020646],[-123.42758,43.984951]]]},"properties":{"id":"97487","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357225,44.145604],[-121.975444,43.855513],[-121.819083,44.050909],[-121.799359,44.258138],[-122.022644,44.373551],[-122.338535,44.225293],[-122.357225,44.145604]]]},"properties":{"id":"97413","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.597653,44.1755],[-122.725846,44.148072],[-122.663955,44.106796],[-122.58686,44.133155],[-122.597653,44.1755]]]},"properties":{"id":"97489","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.429592,43.703762],[-122.493955,43.759507],[-122.549606,43.696804],[-122.429592,43.703762]]]},"properties":{"id":"97492","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.928807,44.379845],[-123.856015,44.42705],[-123.850766,44.511506],[-123.881417,44.499325],[-124.082276,44.462423],[-124.092165,44.371612],[-123.928807,44.379845]]]},"properties":{"id":"97394","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.920177,44.85385],[-124.030363,44.85213],[-124.02026,44.780431],[-123.989214,44.735336],[-123.859053,44.709793],[-123.852052,44.779995],[-123.920177,44.85385]]]},"properties":{"id":"97380","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.832251,44.683985],[-121.952138,44.565774],[-121.800933,44.566328],[-121.794077,44.68394],[-121.832251,44.683985]]]},"properties":{"id":"97350","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.999905,44.468293],[-123.009066,44.398571],[-123.025864,44.331021],[-122.884659,44.302112],[-122.874383,44.413471],[-122.999905,44.468293]]]},"properties":{"id":"97327","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.672022,44.420055],[-122.596163,44.367162],[-122.47442,44.360358],[-122.517897,44.435022],[-122.672022,44.420055]]]},"properties":{"id":"97345","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.206209,44.445858],[-123.009066,44.398571],[-122.999905,44.468293],[-123.009112,44.50486],[-123.162046,44.51233],[-123.214409,44.491014],[-123.206209,44.445858]]]},"properties":{"id":"97377","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.230045,43.779066],[-118.232273,43.545627],[-117.798342,43.891668],[-118.167245,44.157989],[-118.228117,44.169916],[-118.227435,44.039981],[-118.230045,43.779066]]]},"properties":{"id":"97911","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.037305,44.966886],[-122.99884,45.00157],[-122.957077,45.076184],[-123.069192,45.075044],[-123.037305,44.966886]]]},"properties":{"id":"97303","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.654141,45.838901],[-119.999648,45.801464],[-119.998764,45.687979],[-119.623313,45.80639],[-119.624033,45.833843],[-119.654141,45.838901]]]},"properties":{"id":"97818","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.730522,45.564932],[-122.763775,45.654424],[-122.764308,45.585316],[-122.730522,45.564932]]]},"properties":{"id":"97203","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.867553,45.541975],[-122.815971,45.519959],[-122.802686,45.517455],[-122.748383,45.519528],[-122.772113,45.562938],[-122.872032,45.572073],[-122.867553,45.541975]]]},"properties":{"id":"97229","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.479374,44.843415],[-123.418786,44.90541],[-123.495044,44.880206],[-123.479374,44.843415]]]},"properties":{"id":"97344","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.228657,44.897509],[-123.16335,44.72015],[-123.143556,44.74967],[-123.092412,44.805352],[-123.149402,44.861254],[-123.11315,44.92849],[-123.148863,44.932841],[-123.228657,44.897509]]]},"properties":{"id":"97351","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.824416,45.562088],[-123.923043,45.540022],[-123.972943,45.568511],[-123.978678,45.472479],[-123.964358,45.463358],[-123.964843,45.435566],[-123.991526,45.348846],[-123.658137,45.37789],[-123.824416,45.562088]]]},"properties":{"id":"97141","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.909138,45.125159],[-123.938204,45.161089],[-123.979767,45.159543],[-124.016319,45.045746],[-123.948426,45.065708],[-123.909138,45.125159]]]},"properties":{"id":"97149","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.675583,45.804296],[-118.379797,45.602797],[-118.358584,45.642413],[-118.18881,45.725654],[-118.454421,45.775482],[-118.60912,45.876112],[-118.675583,45.804296]]]},"properties":{"id":"97810","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.180081,46.000555],[-118.658601,46.00096],[-118.606644,45.926865],[-118.473726,45.860106],[-117.977677,45.883431],[-117.977732,45.952304],[-118.119726,45.949454],[-118.180081,46.000555]]]},"properties":{"id":"97862","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.782502,45.825608],[-116.882445,45.079322],[-116.867028,45.079176],[-116.783902,45.079022],[-116.782502,45.825608]]]},"properties":{"id":"97842","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.440781,45.532741],[-121.365447,45.703487],[-121.441051,45.697236],[-121.440781,45.532741]]]},"properties":{"id":"97040","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.736034,44.996694],[-120.751331,45.010788],[-120.757383,44.996465],[-120.736034,44.996694]]]},"properties":{"id":"97057","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.340136,45.779787],[-123.31281,45.707075],[-123.263581,45.706827],[-123.272849,45.743678],[-123.340136,45.779787]]]},"properties":{"id":"97144","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.003095,44.79244],[-120.385145,44.761165],[-120.385383,44.755934],[-119.653992,44.514618],[-119.654453,44.534133],[-120.042238,44.645326],[-120.003095,44.79244]]]},"properties":{"id":"97750","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.215579,44.570374],[-118.305155,44.447604],[-118.149442,44.424127],[-118.183003,44.498175],[-118.215579,44.570374]]]},"properties":{"id":"97884","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.767929,44.283625],[-123.593574,44.253544],[-123.455629,44.281121],[-123.446364,44.384743],[-123.473933,44.434271],[-123.597501,44.443382],[-123.766472,44.415947],[-123.767929,44.283625]]]},"properties":{"id":"97324","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.571079,45.3914],[-122.607802,45.372342],[-122.660499,45.296595],[-122.58626,45.250247],[-122.530105,45.243476],[-122.421826,45.303081],[-122.38132,45.32806],[-122.413676,45.381959],[-122.432378,45.389551],[-122.513788,45.409314],[-122.571079,45.3914]]]},"properties":{"id":"97045","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.721413,45.382614],[-122.697396,45.436014],[-122.743991,45.426023],[-122.744021,45.415311],[-122.750439,45.388439],[-122.721413,45.382614]]]},"properties":{"id":"97035","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.637781,45.398411],[-122.708577,45.381962],[-122.722743,45.357283],[-122.69282,45.30029],[-122.660499,45.296595],[-122.607802,45.372342],[-122.621237,45.38027],[-122.637781,45.398411]]]},"properties":{"id":"97068","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.573975,45.397787],[-122.621237,45.38027],[-122.607802,45.372342],[-122.571079,45.3914],[-122.573975,45.397787]]]},"properties":{"id":"97027","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.712031,45.476137],[-122.743626,45.476439],[-122.743991,45.426023],[-122.697396,45.436014],[-122.647743,45.438514],[-122.660979,45.457819],[-122.667839,45.476478],[-122.712031,45.476137]]]},"properties":{"id":"97219","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.001887,45.405738],[-122.026708,45.36583],[-121.991834,45.363262],[-122.001887,45.405738]]]},"properties":{"id":"97011","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.29464,45.541404],[-122.319968,45.498358],[-122.282652,45.468373],[-122.253749,45.445045],[-122.16663,45.461124],[-122.117086,45.539795],[-122.124022,45.577817],[-122.29464,45.541404]]]},"properties":{"id":"97019","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.038886,45.948029],[-122.838572,45.982528],[-123.072955,46.163368],[-123.09364,45.99892],[-123.060793,45.941111],[-123.038886,45.948029]]]},"properties":{"id":"97048","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.258086,45.978304],[-123.340136,45.779787],[-123.272849,45.743678],[-123.195903,45.780006],[-123.123826,45.869882],[-123.1097,45.895306],[-123.112567,45.996299],[-123.258086,45.978304]]]},"properties":{"id":"97064","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.180855,43.611111],[-124.052229,43.526019],[-123.979004,43.609363],[-124.180855,43.611111]]]},"properties":{"id":"97449","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.286466,43.457072],[-124.071151,43.491533],[-124.052229,43.526019],[-124.180855,43.611111],[-124.207839,43.668736],[-124.215121,43.667298],[-124.286466,43.457072]]]},"properties":{"id":"97459","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.161165,42.874831],[-124.046148,42.839039],[-124.002036,42.887238],[-124.161165,42.874831]]]},"properties":{"id":"97466","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.385652,44.198352],[-120.50149,44.019813],[-120.085354,44.097009],[-120.085129,44.254032],[-120.385652,44.198352]]]},"properties":{"id":"97752","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-123.979004,43.609363],[-123.876446,43.654504],[-124.100933,43.709815],[-124.207839,43.668736],[-124.180855,43.611111],[-123.979004,43.609363]]],[[[-124.124697,43.776025],[-124.102829,43.71983],[-123.806788,43.705979],[-123.911022,43.899236],[-123.9148,43.899218],[-123.945558,43.862742],[-124.124697,43.776025]]]]},"properties":{"id":"97467","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.195073,42.72405],[-123.254379,42.837182],[-123.319378,42.728242],[-123.195073,42.72405]]]},"properties":{"id":"97410","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.460721,43.366615],[-123.551269,43.469514],[-123.534308,43.299442],[-123.460721,43.366615]]]},"properties":{"id":"97486","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.892888,44.884465],[-119.163882,44.995887],[-119.319997,44.995534],[-119.322869,44.621681],[-119.195304,44.619829],[-119.002777,44.608692],[-118.845136,44.736587],[-118.792229,44.768205],[-118.892888,44.884465]]]},"properties":{"id":"97856","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.530471,43.63311],[-118.816631,43.974302],[-119.15049,43.624991],[-119.111364,43.437077],[-119.115565,43.366872],[-118.416515,43.276453],[-118.506311,43.537414],[-118.530471,43.63311]]]},"properties":{"id":"97720","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.234129,43.068673],[-119.996787,43.180056],[-120.253542,43.354546],[-120.234129,43.068673]]]},"properties":{"id":"97758","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.180227,42.32784],[-123.171057,42.431345],[-123.480122,42.505636],[-123.585687,42.530512],[-123.608217,42.442685],[-123.491879,42.342656],[-123.421996,42.192498],[-123.394742,42.21099],[-123.229898,42.213781],[-123.180227,42.32784]]]},"properties":{"id":"97527","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.562412,42.772981],[-122.658586,42.72491],[-122.601053,42.697017],[-122.465236,42.737412],[-122.562412,42.772981]]]},"properties":{"id":"97536","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.804343,42.424229],[-122.894832,42.627339],[-122.951038,42.767304],[-122.993143,42.645531],[-122.995851,42.579015],[-122.971434,42.557584],[-122.804343,42.424229]]]},"properties":{"id":"97503","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.480122,42.505636],[-123.44607,42.596838],[-123.584061,42.649181],[-123.585687,42.530512],[-123.480122,42.505636]]]},"properties":{"id":"97532","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.13414,42.893343],[-122.054385,42.968881],[-122.281955,43.01072],[-122.282191,42.910756],[-122.13414,42.893343]]]},"properties":{"id":"97604","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.505749,42.065072],[-121.678236,42.000319],[-121.519107,41.997998],[-121.505749,42.065072]]]},"properties":{"id":"97633","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.82528,42.127397],[-121.824336,42.12278],[-121.80959,42.126235],[-121.815881,42.130401],[-121.82528,42.127397]]]},"properties":{"id":"97634","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.747831,42.559721],[-119.841075,42.354636],[-119.995196,42.370428],[-120.150993,42.062016],[-120.159459,41.994587],[-119.747831,42.559721]]]},"properties":{"id":"97620","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.100558,44.038963],[-123.318879,43.969771],[-123.375495,43.890987],[-123.398466,43.809029],[-123.371402,43.808641],[-123.237598,43.83718],[-123.183587,43.866768],[-122.966192,43.980592],[-122.970077,44.025807],[-123.023845,44.033378],[-123.07926,44.03893],[-123.100558,44.038963]]]},"properties":{"id":"97405","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.11994,44.142436],[-123.153908,44.127498],[-123.101024,44.063215],[-123.106722,44.095185],[-123.11994,44.142436]]]},"properties":{"id":"97404","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.546427,44.12602],[-123.486797,44.179909],[-123.471627,44.229049],[-123.455629,44.281121],[-123.593574,44.253544],[-123.546427,44.12602]]]},"properties":{"id":"97412","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.023043,43.962495],[-123.9148,43.899218],[-123.911022,43.899236],[-123.758717,43.944458],[-123.699162,44.05582],[-123.892325,44.084511],[-124.023043,43.962495]]]},"properties":{"id":"97453","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.493955,43.759507],[-122.429592,43.703762],[-122.31492,43.694787],[-122.372507,43.807937],[-122.493955,43.759507]]]},"properties":{"id":"97463","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.784864,44.283808],[-122.874105,44.258893],[-122.905515,44.207574],[-122.751778,44.152484],[-122.784864,44.283808]]]},"properties":{"id":"97454","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.04071,44.863065],[-124.023131,44.926976],[-124.047718,44.858951],[-124.04071,44.863065]]]},"properties":{"id":"97388","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.813549,44.821361],[-122.835384,44.770335],[-122.662462,44.75194],[-122.61836,44.79021],[-122.61953,44.78982],[-122.61928,44.79202],[-122.61827,44.79202],[-122.616123,44.818066],[-122.68572,44.83647],[-122.813549,44.821361]]]},"properties":{"id":"97383","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.750612,44.254868],[-118.167245,44.157989],[-117.798342,43.891668],[-117.58536,44.003276],[-117.627214,44.146173],[-117.750612,44.254868]]]},"properties":{"id":"97920","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.790426,45.067761],[-119.671987,44.994424],[-119.593831,44.995412],[-119.147424,45.241569],[-119.145442,45.499228],[-119.455453,45.588446],[-119.75942,45.39233],[-120.006397,45.276549],[-119.790426,45.067761]]]},"properties":{"id":"97836","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.670417,45.51307],[-122.618885,45.50513],[-122.619994,45.523045],[-122.620153,45.52371],[-122.667093,45.523028],[-122.670417,45.51307]]]},"properties":{"id":"97214","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.713216,45.502181],[-122.712031,45.476137],[-122.667839,45.476478],[-122.665103,45.501184],[-122.713216,45.502181]]]},"properties":{"id":"97239","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.720892,45.520439],[-122.69429,45.523215],[-122.692003,45.544869],[-122.702642,45.552166],[-122.730522,45.564932],[-122.764308,45.585316],[-122.772113,45.562938],[-122.748383,45.519528],[-122.743859,45.516665],[-122.720892,45.520439]]]},"properties":{"id":"97210","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.43193,45.179475],[-123.550869,45.02891],[-123.41822,44.969428],[-123.326764,45.040716],[-123.321903,45.090358],[-123.37052,45.234303],[-123.43193,45.179475]]]},"properties":{"id":"97378","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.813173,45.496033],[-120.375793,45.528385],[-120.649278,45.732027],[-120.914489,45.63524],[-120.825734,45.514977],[-120.813173,45.496033]]]},"properties":{"id":"97065","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.938204,45.161089],[-123.971475,45.27567],[-123.979767,45.159543],[-123.938204,45.161089]]]},"properties":{"id":"97135","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.145442,45.499228],[-119.147424,45.241569],[-118.998843,45.25762],[-118.9544,45.288122],[-118.453716,45.440088],[-118.560038,45.516899],[-119.137028,45.494705],[-119.145442,45.499228]]]},"properties":{"id":"97868","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.382007,45.835682],[-119.265782,45.939035],[-119.431889,45.918251],[-119.432769,45.888798],[-119.382007,45.835682]]]},"properties":{"id":"97882","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.60912,45.876112],[-118.454421,45.775482],[-118.473726,45.860106],[-118.606644,45.926865],[-118.60912,45.876112]]]},"properties":{"id":"97813","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.102353,45.446949],[-118.04269,45.147075],[-117.930504,45.170857],[-117.86024,45.258059],[-117.981994,45.442287],[-118.033757,45.449861],[-118.102353,45.446949]]]},"properties":{"id":"97850","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.033757,45.449861],[-117.981994,45.442287],[-117.840378,45.443678],[-117.924347,45.496537],[-118.033757,45.449861]]]},"properties":{"id":"97841","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.029518,45.519988],[-122.899627,45.515234],[-122.867553,45.541975],[-122.872032,45.572073],[-122.929103,45.653055],[-123.013727,45.596998],[-123.029518,45.519988]]]},"properties":{"id":"97124","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.183003,44.498175],[-118.011342,44.417939],[-117.822815,44.443464],[-117.849001,44.498842],[-118.022605,44.58547],[-118.044682,44.620397],[-118.215579,44.570374],[-118.183003,44.498175]]]},"properties":{"id":"97837","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.335979,44.692432],[-123.562551,44.766702],[-123.566336,44.720296],[-123.597945,44.455757],[-123.597501,44.443382],[-123.473933,44.434271],[-123.334354,44.557177],[-123.335979,44.692432]]]},"properties":{"id":"97370","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.389346,45.4613],[-122.432378,45.389551],[-122.413676,45.381959],[-122.322934,45.372869],[-122.253749,45.445045],[-122.282652,45.468373],[-122.389346,45.4613]]]},"properties":{"id":"97009","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.688693,45.053191],[-122.499716,44.918044],[-122.433605,45.125811],[-122.487759,45.171131],[-122.580983,45.199858],[-122.695328,45.125626],[-122.687129,45.078305],[-122.688693,45.053191]]]},"properties":{"id":"97038","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.496748,45.460982],[-122.537618,45.461243],[-122.57905,45.461518],[-122.581134,45.45527],[-122.579235,45.432535],[-122.485745,45.426259],[-122.486584,45.461132],[-122.496748,45.460982]]]},"properties":{"id":"97086","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.862272,45.289474],[-122.949597,45.38547],[-123.091228,45.381722],[-123.096002,45.379328],[-123.115317,45.330275],[-123.077465,45.296091],[-122.974568,45.279932],[-122.882506,45.255313],[-122.849723,45.259654],[-122.862272,45.289474]]]},"properties":{"id":"97132","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.205207,42.90349],[-124.161165,42.874831],[-124.002036,42.887238],[-124.029685,43.237422],[-124.256527,43.052792],[-124.269285,42.954486],[-124.253999,42.95109],[-124.2037,42.991857],[-124.104971,42.966926],[-124.205207,42.90349]]]},"properties":{"id":"97458","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.6657,43.87123],[-120.427118,43.872395],[-119.995009,43.957859],[-120.089941,43.925671],[-120.50149,44.019813],[-120.385652,44.198352],[-120.885729,44.476433],[-121.055762,44.462316],[-121.066864,44.390795],[-121.011961,44.341001],[-121.011608,44.334801],[-120.916192,44.154237],[-120.747881,43.87755],[-120.6657,43.87123]]]},"properties":{"id":"97754","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.159005,44.390663],[-121.291323,44.532624],[-121.299209,44.52558],[-121.319937,44.49904],[-121.384181,44.392883],[-121.392717,44.294865],[-121.353699,44.300553],[-121.011961,44.341001],[-121.066864,44.390795],[-121.159005,44.390663]]]},"properties":{"id":"97760","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.102792,44.171689],[-120.916192,44.154237],[-121.011608,44.334801],[-121.102792,44.171689]]]},"properties":{"id":"97753","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.51003,44.217757],[-121.392717,44.294865],[-121.384181,44.392883],[-121.51003,44.217757]]],[[[-122.022644,44.373551],[-121.799359,44.258138],[-121.800933,44.566328],[-121.952138,44.565774],[-122.022644,44.373551]]]]},"properties":{"id":"97759","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.383546,44.050062],[-121.107753,43.91849],[-121.203499,44.051151],[-121.383546,44.050062]]]},"properties":{"id":"97702","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.998068,42.869626],[-122.794826,42.792999],[-122.755405,42.828574],[-122.946074,42.991339],[-122.998068,42.869626]]]},"properties":{"id":"97484","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.514391,43.675682],[-123.763846,43.66149],[-123.692732,43.598098],[-123.650006,43.572167],[-123.427791,43.547714],[-123.468229,43.640038],[-123.514391,43.675682]]]},"properties":{"id":"97436","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.023043,43.962495],[-124.163461,43.876927],[-124.17002,43.831546],[-124.153484,43.817014],[-123.945558,43.862742],[-123.9148,43.899218],[-124.023043,43.962495]]]},"properties":{"id":"97493","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.037004,44.424377],[-119.002777,44.608692],[-119.195304,44.619829],[-119.408447,44.543046],[-119.316791,44.310152],[-119.153168,44.253888],[-119.012289,44.347436],[-119.037004,44.424377]]]},"properties":{"id":"97865","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.676969,44.640232],[-118.544451,44.608054],[-118.518358,44.697162],[-118.506184,44.789954],[-118.792229,44.768205],[-118.845136,44.736587],[-118.676969,44.640232]]]},"properties":{"id":"97817","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.013813,44.177316],[-119.114968,44.100884],[-118.968029,44.071897],[-118.974146,44.212695],[-119.013813,44.177316]]]},"properties":{"id":"97873","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.862993,43.129305],[-118.571279,42.697132],[-118.783948,42.550811],[-118.702497,42.319428],[-118.214856,42.37641],[-118.227963,43.30563],[-118.30555,43.345722],[-118.416515,43.276453],[-119.115565,43.366872],[-118.862993,43.129305]]]},"properties":{"id":"97721","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.506311,43.537414],[-118.416515,43.276453],[-118.30555,43.345722],[-118.506311,43.537414]]]},"properties":{"id":"97732","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.441051,45.697236],[-121.640536,45.548995],[-121.50998,45.507914],[-121.482008,45.50497],[-121.440781,45.532741],[-121.441051,45.697236]]]},"properties":{"id":"97031","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.230764,42.003854],[-122.866339,42.161932],[-122.879446,42.20427],[-122.955913,42.322303],[-123.046771,42.335389],[-123.180227,42.32784],[-123.229898,42.213781],[-123.230764,42.003854]]]},"properties":{"id":"97530","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.171057,42.431345],[-123.180227,42.32784],[-123.046771,42.335389],[-122.971434,42.557584],[-122.995851,42.579015],[-123.17072,42.431526],[-123.171057,42.431345]]]},"properties":{"id":"97525","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.069228,44.974303],[-121.276701,44.607195],[-121.090579,44.824171],[-121.069228,44.974303]]]},"properties":{"id":"97761","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.65589,44.432888],[-121.61819,44.55592],[-121.671947,44.540896],[-121.65589,44.432888]]]},"properties":{"id":"97730","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.535481,42.366034],[-121.513811,42.201412],[-121.470855,42.076183],[-121.316494,41.997338],[-121.337548,42.363144],[-121.374866,42.385288],[-121.547399,42.41511],[-121.535481,42.366034]]]},"properties":{"id":"97623","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.918887,43.336999],[-121.15209,43.288351],[-120.859384,42.990843],[-120.612604,43.092777],[-120.918887,43.336999]]]},"properties":{"id":"97638","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.699162,44.05582],[-123.758717,43.944458],[-123.42758,43.984951],[-123.470396,44.020646],[-123.549464,44.112715],[-123.672484,44.061106],[-123.699162,44.05582]]]},"properties":{"id":"97490","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.165394,44.200021],[-123.11994,44.142436],[-123.106722,44.095185],[-123.04688,44.0841],[-122.986417,44.081184],[-122.980013,44.200167],[-123.165394,44.200021]]]},"properties":{"id":"97408","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.237598,43.83718],[-123.371402,43.808641],[-123.291922,43.779952],[-123.277097,43.779907],[-123.237598,43.83718]]]},"properties":{"id":"97451","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.917783,44.227553],[-123.928807,44.379845],[-124.092165,44.371612],[-124.12663,44.190471],[-123.949171,44.182066],[-123.917783,44.227553]]]},"properties":{"id":"97498","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.006045,44.569595],[-123.989214,44.735336],[-124.02026,44.780431],[-124.030677,44.775239],[-124.058848,44.728863],[-124.071441,44.614061],[-124.02773,44.611803],[-124.006045,44.569595]]]},"properties":{"id":"97365","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.082276,44.462423],[-123.881417,44.499325],[-124.006045,44.569595],[-124.02773,44.611803],[-124.075746,44.523856],[-124.082276,44.462423]]]},"properties":{"id":"97376","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.874105,44.258893],[-122.784864,44.283808],[-122.596163,44.367162],[-122.672022,44.420055],[-122.700604,44.435683],[-122.874383,44.413471],[-122.884659,44.302112],[-122.874105,44.258893]]]},"properties":{"id":"97386","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.58536,44.003276],[-117.798342,43.891668],[-118.232273,43.545627],[-118.227963,43.30563],[-117.609274,43.279194],[-117.375441,43.846944],[-117.58536,44.003276]]]},"properties":{"id":"97906","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.173531,42.367997],[-118.197189,41.996995],[-117.610714,41.998542],[-118.173531,42.367997]]]},"properties":{"id":"89421","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.80287,44.9008],[-122.589518,44.87576],[-122.58478,44.904624],[-122.693345,45.053861],[-122.838153,45.057578],[-122.851594,44.964268],[-122.80287,44.9008]]]},"properties":{"id":"97381","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.794077,44.68394],[-121.881461,44.799483],[-121.972859,44.716242],[-121.832251,44.683985],[-121.794077,44.68394]]]},"properties":{"id":"97342","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.61827,44.79202],[-122.61928,44.79202],[-122.61953,44.78982],[-122.61836,44.79021],[-122.61827,44.79202]]]},"properties":{"id":"97384","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.563217,45.577961],[-122.620416,45.552852],[-122.579677,45.552406],[-122.563217,45.577961]]]},"properties":{"id":"97218","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.666743,45.553318],[-122.702642,45.552166],[-122.692003,45.544869],[-122.669003,45.527626],[-122.666746,45.535095],[-122.666743,45.553318]]]},"properties":{"id":"97227","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.579056,45.522781],[-122.619994,45.523045],[-122.618885,45.50513],[-122.618039,45.505276],[-122.578679,45.504821],[-122.579056,45.522781]]]},"properties":{"id":"97215","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.537618,45.461243],[-122.496748,45.460982],[-122.482949,45.491962],[-122.475593,45.504564],[-122.537683,45.504093],[-122.537618,45.461243]]]},"properties":{"id":"97236","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.433621,45.527827],[-122.416929,45.566697],[-122.441062,45.564169],[-122.453714,45.527089],[-122.433621,45.527827]]]},"properties":{"id":"97024","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.384994,45.500123],[-122.433621,45.527827],[-122.453714,45.527089],[-122.453692,45.524178],[-122.475593,45.504564],[-122.482949,45.491962],[-122.384994,45.500123]]]},"properties":{"id":"97030","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.013727,45.596998],[-122.929103,45.653055],[-122.915073,45.693891],[-123.011137,45.751458],[-123.122098,45.779983],[-123.114012,45.721419],[-123.044179,45.608409],[-123.013727,45.596998]]]},"properties":{"id":"97133","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.874352,45.609957],[-123.875588,45.679512],[-123.895134,45.688502],[-123.946516,45.656138],[-123.972943,45.568511],[-123.923043,45.540022],[-123.905645,45.553444],[-123.927686,45.561951],[-123.874352,45.609957]]]},"properties":{"id":"97136","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.927686,45.561951],[-123.897705,45.568228],[-123.905645,45.553444],[-123.923043,45.540022],[-123.824416,45.562088],[-123.849897,45.607891],[-123.874352,45.609957],[-123.927686,45.561951]]]},"properties":{"id":"97107","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.00699,45.887275],[-119.32373,45.783636],[-119.109765,45.775406],[-119.00699,45.887275]]]},"properties":{"id":"97875","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.973011,45.567148],[-118.102353,45.446949],[-118.033757,45.449861],[-117.924347,45.496537],[-117.973011,45.567148]]]},"properties":{"id":"97876","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.050201,45.996875],[-117.327153,45.190898],[-117.319964,45.165922],[-117.266572,45.080805],[-117.056259,45.079628],[-116.882445,45.079322],[-116.782502,45.825608],[-117.050201,45.996875]]]},"properties":{"id":"97846","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.050201,45.996875],[-117.550523,45.888591],[-117.302773,45.486709],[-117.327153,45.190898],[-117.050201,45.996875]]]},"properties":{"id":"97828","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.727628,45.57023],[-117.302773,45.486709],[-117.550523,45.888591],[-117.746377,45.622784],[-117.727628,45.57023]]]},"properties":{"id":"97885","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.319717,45.579126],[-123.218092,45.573981],[-123.339585,45.676881],[-123.319717,45.579126]]]},"properties":{"id":"97117","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.815971,45.519959],[-122.867553,45.541975],[-122.899627,45.515234],[-122.902211,45.497268],[-122.826111,45.489394],[-122.815971,45.519959]]]},"properties":{"id":"97006","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.818467,45.392055],[-122.750439,45.388439],[-122.744021,45.415311],[-122.851847,45.421032],[-122.818467,45.392055]]]},"properties":{"id":"97224","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.773876,45.47196],[-122.826094,45.476447],[-122.816966,45.441103],[-122.773876,45.47196]]]},"properties":{"id":"97008","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.323643,45.289275],[-123.121433,45.253812],[-123.102372,45.250346],[-123.084192,45.286585],[-123.077465,45.296091],[-123.115317,45.330275],[-123.373811,45.309739],[-123.323643,45.289275]]]},"properties":{"id":"97111","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.259094,44.618926],[-117.381991,44.721874],[-117.628585,44.605754],[-117.757234,44.508853],[-117.660953,44.512385],[-117.480156,44.465406],[-117.259094,44.618926]]]},"properties":{"id":"97905","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.22149,44.874452],[-118.506184,44.789954],[-118.518358,44.697162],[-118.228957,44.761589],[-118.22149,44.874452]]]},"properties":{"id":"97877","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.446364,44.384743],[-123.455629,44.281121],[-123.199967,44.307563],[-123.219696,44.360224],[-123.233552,44.412375],[-123.446364,44.384743]]]},"properties":{"id":"97456","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.577203,45.424081],[-122.655605,45.422341],[-122.637781,45.398411],[-122.621237,45.38027],[-122.573975,45.397787],[-122.577203,45.424081]]]},"properties":{"id":"97267","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.784978,45.344493],[-122.862272,45.289474],[-122.849723,45.259654],[-122.717769,45.299067],[-122.69282,45.30029],[-122.722743,45.357283],[-122.784978,45.344493]]]},"properties":{"id":"97070","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.949597,45.38547],[-122.862272,45.289474],[-122.784978,45.344493],[-122.818467,45.392055],[-122.851847,45.421032],[-122.853466,45.426371],[-122.920942,45.414981],[-122.949597,45.38547]]]},"properties":{"id":"97140","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.919347,46.194844],[-123.967066,46.204686],[-123.981853,46.172586],[-123.919347,46.194844]]]},"properties":{"id":"97121","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.06333,43.304274],[-124.29617,43.229277],[-124.256527,43.052792],[-124.029685,43.237422],[-124.06333,43.304274]]]},"properties":{"id":"97423","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.353699,44.300553],[-121.102792,44.171689],[-121.011608,44.334801],[-121.011961,44.341001],[-121.353699,44.300553]]]},"properties":{"id":"97756","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.6657,43.87123],[-120.513491,43.717328],[-120.427118,43.872395],[-120.6657,43.87123]]]},"properties":{"id":"97712","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.257956,43.714971],[-123.291922,43.779952],[-123.371402,43.808641],[-123.398466,43.809029],[-123.514391,43.675682],[-123.468229,43.640038],[-123.13751,43.649405],[-123.257956,43.714971]]]},"properties":{"id":"97435","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.805935,43.1484],[-121.719626,43.188256],[-121.802734,43.215055],[-121.805935,43.1484]]]},"properties":{"id":"97731","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.495247,45.068549],[-119.850928,45.068383],[-119.790426,45.067761],[-120.006397,45.276549],[-120.005711,45.350614],[-120.360961,45.505791],[-120.469596,45.476942],[-120.503549,45.08295],[-120.495247,45.068549]]]},"properties":{"id":"97823","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.653992,44.514618],[-119.316791,44.310152],[-119.408447,44.543046],[-119.43664,44.561736],[-119.653992,44.514618]]]},"properties":{"id":"97825","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.951038,42.767304],[-122.894832,42.627339],[-122.834133,42.633912],[-122.755663,42.629491],[-122.671319,42.619034],[-122.655397,42.62432],[-122.658586,42.72491],[-122.562412,42.772981],[-122.755405,42.828574],[-122.794826,42.792999],[-122.951038,42.767304]]]},"properties":{"id":"97541","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.394742,42.21099],[-123.447515,42.124011],[-123.230764,42.003854],[-123.229898,42.213781],[-123.394742,42.21099]]]},"properties":{"id":"97544","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.654388,42.230634],[-123.641272,42.206313],[-123.641432,42.220897],[-123.654388,42.230634]]]},"properties":{"id":"97531","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.646753,42.369036],[-123.491879,42.342656],[-123.608217,42.442685],[-123.646753,42.369036]]]},"properties":{"id":"97543","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.547399,42.41511],[-121.374866,42.385288],[-121.440338,42.542533],[-121.529218,42.45757],[-121.547399,42.41511]]]},"properties":{"id":"97639","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.440338,42.542533],[-121.374866,42.385288],[-121.337548,42.363144],[-121.174224,42.43624],[-121.162128,42.500443],[-121.448885,42.549165],[-121.440338,42.542533]]]},"properties":{"id":"97621","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.061084,42.763213],[-122.051077,42.590928],[-121.94152,42.581442],[-121.977375,42.732971],[-122.061084,42.763213]]]},"properties":{"id":"97626","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.786709,43.46532],[-121.686182,43.467474],[-121.650571,43.524595],[-121.679381,43.520816],[-121.786709,43.46532]]]},"properties":{"id":"97737","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.519107,41.997998],[-121.316494,41.997338],[-121.470855,42.076183],[-121.505749,42.065072],[-121.519107,41.997998]]]},"properties":{"id":"97632","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.986158,42.163045],[-121.933944,42.082371],[-121.928733,42.127911],[-121.986158,42.163045]]]},"properties":{"id":"97627","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.918887,43.336999],[-120.612604,43.092777],[-120.595295,43.092781],[-120.234129,43.068673],[-120.253542,43.354546],[-120.569989,43.501763],[-120.918887,43.336999]]]},"properties":{"id":"97641","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.569989,43.501763],[-121.15209,43.288351],[-120.918887,43.336999],[-120.569989,43.501763]]]},"properties":{"id":"97735","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.807034,43.914527],[-122.589615,43.821591],[-122.694295,44.05379],[-122.905909,43.998291],[-122.862202,43.95821],[-122.807034,43.914527]]]},"properties":{"id":"97438","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.811437,44.20894],[-123.672484,44.061106],[-123.549464,44.112715],[-123.546427,44.12602],[-123.593574,44.253544],[-123.767929,44.283625],[-123.811437,44.20894]]]},"properties":{"id":"97430","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.183587,43.866768],[-122.896566,43.853853],[-122.866603,43.883604],[-122.886574,43.896401],[-122.966192,43.980592],[-123.183587,43.866768]]]},"properties":{"id":"97426","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.030677,44.775239],[-124.067935,44.765092],[-124.058848,44.728863],[-124.030677,44.775239]]]},"properties":{"id":"97369","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.911669,44.954545],[-123.995904,44.997108],[-123.982302,45.005159],[-124.00541,45.04495],[-124.023131,44.926976],[-124.04071,44.863065],[-124.030363,44.85213],[-123.920177,44.85385],[-123.911669,44.954545]]]},"properties":{"id":"97367","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.108251,44.589228],[-123.162046,44.51233],[-123.009112,44.50486],[-123.013175,44.541032],[-123.108251,44.589228]]]},"properties":{"id":"97389","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.9056,44.845646],[-123.00874,44.84288],[-123.01678,44.82423],[-122.951206,44.719582],[-122.864989,44.758199],[-122.9056,44.845646]]]},"properties":{"id":"97392","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.813142,44.883926],[-122.9056,44.845646],[-122.864989,44.758199],[-122.835384,44.770335],[-122.813549,44.821361],[-122.813142,44.883926]]]},"properties":{"id":"97325","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.763775,45.654424],[-122.730522,45.564932],[-122.702642,45.552166],[-122.666743,45.553318],[-122.643907,45.609739],[-122.76241,45.656438],[-122.763775,45.654424]]]},"properties":{"id":"97217","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.619994,45.523045],[-122.579056,45.522781],[-122.579677,45.552406],[-122.620416,45.552852],[-122.620406,45.551846],[-122.620312,45.535139],[-122.620153,45.52371],[-122.619994,45.523045]]]},"properties":{"id":"97213","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.453692,45.524178],[-122.537767,45.522494],[-122.537683,45.504093],[-122.475593,45.504564],[-122.453692,45.524178]]]},"properties":{"id":"97233","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.692003,45.544869],[-122.69429,45.523215],[-122.676472,45.523062],[-122.667093,45.523028],[-122.669003,45.527626],[-122.692003,45.544869]],[[-122.680286,45.5287],[-122.677643,45.529881],[-122.677595,45.527321],[-122.680286,45.5287]]]},"properties":{"id":"97209","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.720892,45.520439],[-122.743859,45.516665],[-122.743706,45.476444],[-122.743626,45.476439],[-122.712031,45.476137],[-122.713216,45.502181],[-122.700208,45.518136],[-122.720892,45.520439]]]},"properties":{"id":"97221","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.700208,45.518136],[-122.681792,45.515349],[-122.676472,45.523062],[-122.69429,45.523215],[-122.720892,45.520439],[-122.700208,45.518136]]]},"properties":{"id":"97205","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.676472,45.523062],[-122.681792,45.515349],[-122.670417,45.51307],[-122.667093,45.523028],[-122.676472,45.523062]]]},"properties":{"id":"97204","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.572263,45.097642],[-123.700153,45.112535],[-123.650865,45.015882],[-123.576487,45.023897],[-123.572263,45.097642]]]},"properties":{"id":"97347","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.914489,45.63524],[-120.649278,45.732027],[-120.653568,45.737247],[-120.915876,45.641345],[-120.914489,45.63524]]]},"properties":{"id":"97050","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.879164,45.392414],[-121.024033,45.264107],[-120.674055,45.083713],[-120.656841,45.379331],[-120.813173,45.496033],[-120.825734,45.514977],[-120.879164,45.392414]]]},"properties":{"id":"97029","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.379797,45.602797],[-118.675583,45.804296],[-118.976663,45.915737],[-119.00699,45.887275],[-119.109765,45.775406],[-119.137028,45.494705],[-118.560038,45.516899],[-118.379797,45.602797]]]},"properties":{"id":"97801","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.976663,45.915737],[-118.675583,45.804296],[-118.60912,45.876112],[-118.606644,45.926865],[-118.658601,46.00096],[-118.97681,46.00027],[-118.976663,45.915737]]]},"properties":{"id":"97835","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.066579,45.737583],[-118.045054,45.773538],[-117.977677,45.883431],[-118.473726,45.860106],[-118.454421,45.775482],[-118.18881,45.725654],[-118.066579,45.737583]]]},"properties":{"id":"97886","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.180081,46.000555],[-118.119726,45.949454],[-117.977732,45.952304],[-118.180081,46.000555]]]},"properties":{"id":"99362","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.302773,45.486709],[-117.727628,45.57023],[-117.683803,45.441916],[-117.520421,45.223607],[-117.319964,45.165922],[-117.327153,45.190898],[-117.302773,45.486709]]]},"properties":{"id":"97857","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.519747,45.154672],[-121.069228,44.974303],[-121.090579,44.824171],[-120.915707,44.823125],[-120.736034,44.996694],[-120.757383,44.996465],[-120.751331,45.010788],[-120.626232,45.083598],[-120.674055,45.083713],[-121.024033,45.264107],[-121.037313,45.26091],[-121.519747,45.154672]]]},"properties":{"id":"97037","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.037313,45.26091],[-121.266127,45.307111],[-121.519747,45.154672],[-121.037313,45.26091]]]},"properties":{"id":"97063","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.772005,45.476254],[-122.802686,45.517455],[-122.815971,45.519959],[-122.826111,45.489394],[-122.826094,45.476447],[-122.773876,45.47196],[-122.772005,45.476254]]]},"properties":{"id":"97005","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.763593,44.949103],[-120.003095,44.79244],[-120.042238,44.645326],[-119.652697,44.750086],[-119.671729,44.837869],[-119.67134,44.899313],[-119.763593,44.949103]]]},"properties":{"id":"97874","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.121433,45.253812],[-123.323643,45.289275],[-123.37052,45.234303],[-123.321903,45.090358],[-123.13698,45.16708],[-123.120901,45.242066],[-123.121433,45.253812]]]},"properties":{"id":"97128","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.717769,45.299067],[-122.722596,45.193788],[-122.729445,45.144178],[-122.695328,45.125626],[-122.580983,45.199858],[-122.58626,45.250247],[-122.660499,45.296595],[-122.69282,45.30029],[-122.717769,45.299067]]]},"properties":{"id":"97013","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.579235,45.432535],[-122.581134,45.45527],[-122.620776,45.462676],[-122.660979,45.457819],[-122.647743,45.438514],[-122.655605,45.422341],[-122.577203,45.424081],[-122.579235,45.432535]]]},"properties":{"id":"97222","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.432378,45.389551],[-122.389346,45.4613],[-122.486584,45.461132],[-122.485745,45.426259],[-122.513788,45.409314],[-122.432378,45.389551]]]},"properties":{"id":"97089","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.290065,45.174051],[-122.3526,45.225747],[-122.467768,45.190291],[-122.487759,45.171131],[-122.433605,45.125811],[-122.290065,45.174051]]]},"properties":{"id":"97017","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.618885,45.50513],[-122.670417,45.51307],[-122.665103,45.501184],[-122.667839,45.476478],[-122.660979,45.457819],[-122.620776,45.462676],[-122.618039,45.505276],[-122.618885,45.50513]]]},"properties":{"id":"97202","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.722596,45.193788],[-122.87473,45.17836],[-122.729445,45.144178],[-122.722596,45.193788]]]},"properties":{"id":"97032","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.517949,46.176139],[-123.484247,45.97975],[-123.46986,45.917497],[-123.258086,45.978304],[-123.112567,45.996299],[-123.09364,45.99892],[-123.072955,46.163368],[-123.467329,46.212948],[-123.517949,46.176139]]]},"properties":{"id":"97016","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.963003,45.87301],[-123.938747,45.880082],[-123.938948,45.882336],[-123.937434,45.922153],[-123.965839,45.888346],[-123.963003,45.87301]]]},"properties":{"id":"97110","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.987213,45.862907],[-122.947362,45.857157],[-122.819371,45.833956],[-122.806426,45.840912],[-122.785292,45.858331],[-122.796752,45.88266],[-122.819699,45.905999],[-123.038886,45.948029],[-123.060793,45.941111],[-122.987213,45.862907]]]},"properties":{"id":"97051","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.838572,45.982528],[-123.038886,45.948029],[-122.819699,45.905999],[-122.810057,45.909665],[-122.838572,45.982528]]]},"properties":{"id":"97054","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.819699,45.905999],[-122.796752,45.88266],[-122.810057,45.909665],[-122.819699,45.905999]]]},"properties":{"id":"97018","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.304323,42.807025],[-124.532214,42.80638],[-124.400207,42.615649],[-124.382016,42.706202],[-124.304323,42.807025]]]},"properties":{"id":"97465","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.111954,42.557796],[-124.001391,42.496929],[-123.958278,42.714236],[-124.065032,42.649089],[-124.111954,42.557796]]]},"properties":{"id":"97406","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.216599,43.127511],[-123.357596,43.106277],[-123.379837,43.113323],[-123.393005,43.013402],[-123.365522,42.979041],[-123.323633,42.944546],[-123.182296,42.999657],[-123.020521,43.041205],[-123.216599,43.127511]]]},"properties":{"id":"97457","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.650006,43.572167],[-123.551269,43.469514],[-123.460721,43.366615],[-123.361238,43.364885],[-123.1982,43.420485],[-123.13268,43.476095],[-123.427791,43.547714],[-123.650006,43.572167]]]},"properties":{"id":"97462","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.876446,43.654504],[-123.692732,43.598098],[-123.763846,43.66149],[-123.806788,43.705979],[-124.102829,43.71983],[-124.100933,43.709815],[-123.876446,43.654504]]]},"properties":{"id":"97473","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.191102,45.743509],[-120.005711,45.350614],[-120.006397,45.276549],[-119.75942,45.39233],[-119.686754,45.632388],[-119.998764,45.687979],[-119.999648,45.801464],[-120.191102,45.743509]]]},"properties":{"id":"97843","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.163882,44.995887],[-118.892888,44.884465],[-118.9544,45.288122],[-118.998843,45.25762],[-119.163882,44.995887]]]},"properties":{"id":"97880","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.15049,43.624991],[-119.229665,43.50573],[-119.111364,43.437077],[-119.15049,43.624991]]]},"properties":{"id":"97738","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.886224,42.364039],[-122.819474,42.285005],[-122.790038,42.258763],[-122.772179,42.267569],[-122.716472,42.289521],[-122.807133,42.394139],[-122.886224,42.364039]]]},"properties":{"id":"97504","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.654388,42.230634],[-123.641432,42.220897],[-123.641272,42.206313],[-123.421996,42.192498],[-123.491879,42.342656],[-123.646753,42.369036],[-123.68436,42.243594],[-123.654388,42.230634]]]},"properties":{"id":"97538","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.686182,43.467474],[-121.786709,43.46532],[-122.001141,43.615417],[-122.112544,43.438246],[-121.825245,43.368394],[-121.686182,43.467474]]]},"properties":{"id":"97733","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.174224,42.43624],[-120.880604,42.23906],[-121.162128,42.500443],[-121.174224,42.43624]]]},"properties":{"id":"97622","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.764886,42.719144],[-120.40731,42.462227],[-120.388382,42.592055],[-120.477661,42.725423],[-120.764886,42.719144]]]},"properties":{"id":"97636","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.276929,41.993162],[-120.29804,42.004836],[-120.328657,41.993227],[-120.276929,41.993162]]]},"properties":{"id":"97635","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.07926,44.03893],[-123.05024,44.044567],[-123.04688,44.0841],[-123.106722,44.095185],[-123.101024,44.063215],[-123.100558,44.038963],[-123.07926,44.03893]]]},"properties":{"id":"97401","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.986417,44.081184],[-122.961302,44.023618],[-122.905909,43.998291],[-122.694295,44.05379],[-122.663955,44.106796],[-122.725846,44.148072],[-122.751778,44.152484],[-122.905515,44.207574],[-122.980013,44.200167],[-122.986417,44.081184]]]},"properties":{"id":"97478","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.766472,44.415947],[-123.856015,44.42705],[-123.928807,44.379845],[-123.917783,44.227553],[-123.811437,44.20894],[-123.767929,44.283625],[-123.766472,44.415947]]]},"properties":{"id":"97390","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.471627,44.229049],[-123.486797,44.179909],[-123.443059,44.132815],[-123.378883,44.131547],[-123.471627,44.229049]]]},"properties":{"id":"97419","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.597945,44.455757],[-123.726396,44.7034],[-123.817346,44.712851],[-123.850766,44.511506],[-123.856015,44.42705],[-123.766472,44.415947],[-123.597501,44.443382],[-123.597945,44.455757]]]},"properties":{"id":"97343","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.444929,44.730386],[-122.27528,44.78399],[-122.41324,44.77919],[-122.444929,44.730386]]]},"properties":{"id":"97346","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.517897,44.435022],[-122.47442,44.360358],[-122.28211,44.404965],[-122.261465,44.463702],[-122.432639,44.404888],[-122.517897,44.435022]]]},"properties":{"id":"97329","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.951206,44.719582],[-123.01678,44.82423],[-123.092412,44.805352],[-123.143556,44.74967],[-123.052988,44.737792],[-122.969229,44.676834],[-122.951206,44.719582]]]},"properties":{"id":"97352","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.491572,44.242605],[-117.538242,44.179694],[-117.357743,44.247744],[-117.283096,44.272546],[-117.437743,44.301531],[-117.446057,44.301512],[-117.491572,44.242605]]]},"properties":{"id":"97909","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.044507,44.943541],[-122.98753,44.91119],[-122.95855,44.94891],[-122.99884,45.00157],[-123.037305,44.966886],[-123.044507,44.943541]]]},"properties":{"id":"97301","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.99836,44.88517],[-123.149402,44.861254],[-123.092412,44.805352],[-123.01678,44.82423],[-123.00874,44.84288],[-122.99836,44.88517]]]},"properties":{"id":"97306","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.68572,44.83647],[-122.589518,44.87576],[-122.80287,44.9008],[-122.813142,44.883926],[-122.813549,44.821361],[-122.68572,44.83647]]]},"properties":{"id":"97385","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.549587,45.571336],[-122.53919,45.522506],[-122.537767,45.522494],[-122.453692,45.524178],[-122.453714,45.527089],[-122.441062,45.564169],[-122.586006,45.60474],[-122.549587,45.571336]]]},"properties":{"id":"97230","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.537683,45.504093],[-122.578679,45.504821],[-122.57905,45.461518],[-122.537618,45.461243],[-122.537683,45.504093]]]},"properties":{"id":"97266","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.700208,45.518136],[-122.713216,45.502181],[-122.665103,45.501184],[-122.670417,45.51307],[-122.681792,45.515349],[-122.700208,45.518136]]]},"properties":{"id":"97201","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.41822,44.969428],[-123.418786,44.90541],[-123.479374,44.843415],[-123.440847,44.81403],[-123.246456,44.897649],[-123.26322,44.989141],[-123.326764,45.040716],[-123.41822,44.969428]]]},"properties":{"id":"97338","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.646345,45.336237],[-123.847065,45.236802],[-123.761967,45.216139],[-123.646345,45.336237]]]},"properties":{"id":"97108","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.847065,45.236802],[-123.78437,45.104025],[-123.761967,45.216139],[-123.847065,45.236802]]]},"properties":{"id":"97122","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.964843,45.435566],[-123.964358,45.463358],[-123.978678,45.472479],[-123.964843,45.435566]]]},"properties":{"id":"97134","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.379797,45.602797],[-118.560038,45.516899],[-118.453716,45.440088],[-118.358584,45.642413],[-118.379797,45.602797]]]},"properties":{"id":"97859","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.86024,45.258059],[-117.520421,45.223607],[-117.683803,45.441916],[-117.840378,45.443678],[-117.981994,45.442287],[-117.86024,45.258059]]]},"properties":{"id":"97824","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.066579,45.737583],[-117.973011,45.567148],[-117.924347,45.496537],[-117.840378,45.443678],[-117.683803,45.441916],[-117.727628,45.57023],[-117.746377,45.622784],[-118.045054,45.773538],[-118.066579,45.737583]]]},"properties":{"id":"97827","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.319717,45.579126],[-123.284654,45.422056],[-123.096002,45.379328],[-123.091228,45.381722],[-123.063513,45.436289],[-123.103994,45.480617],[-123.218092,45.573981],[-123.319717,45.579126]]]},"properties":{"id":"97119","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.044179,45.608409],[-123.114012,45.721419],[-123.240537,45.705297],[-123.208,45.686151],[-123.232489,45.637785],[-123.082017,45.594628],[-123.044179,45.608409]]]},"properties":{"id":"97106","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.029518,45.519988],[-123.063513,45.436289],[-123.091228,45.381722],[-122.949597,45.38547],[-122.920942,45.414981],[-122.902211,45.497268],[-122.899627,45.515234],[-123.029518,45.519988]]]},"properties":{"id":"97123","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.671318,44.353109],[-117.491572,44.242605],[-117.446057,44.301512],[-117.631474,44.444357],[-117.671318,44.353109]]],[[[-117.480156,44.465406],[-117.437743,44.301531],[-117.283096,44.272546],[-117.047391,44.229582],[-117.125145,44.589144],[-117.184029,44.664363],[-117.259094,44.618926],[-117.480156,44.465406]]]]},"properties":{"id":"97907","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.275407,44.953204],[-117.563318,45.0606],[-117.867064,45.117948],[-117.879778,44.972239],[-118.090198,44.932067],[-118.22149,44.874452],[-118.228957,44.761589],[-118.044682,44.620397],[-118.022605,44.58547],[-117.628585,44.605754],[-117.381991,44.721874],[-117.365907,44.829954],[-117.245284,44.874809],[-117.275407,44.953204]]]},"properties":{"id":"97814","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.769836,44.377967],[-118.50837,44.573251],[-118.517918,44.59519],[-118.544451,44.608054],[-118.676969,44.640232],[-118.80018,44.463803],[-118.769836,44.377967]]]},"properties":{"id":"97869","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.562551,44.766702],[-123.335979,44.692432],[-123.193954,44.699366],[-123.16335,44.72015],[-123.228657,44.897509],[-123.246456,44.897649],[-123.440847,44.81403],[-123.562551,44.766702]]]},"properties":{"id":"97361","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.269877,44.565541],[-123.289725,44.566777],[-123.274183,44.56191],[-123.269877,44.565541]]]},"properties":{"id":"97331","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.618039,45.505276],[-122.620776,45.462676],[-122.581134,45.45527],[-122.57905,45.461518],[-122.578679,45.504821],[-122.618039,45.505276]]]},"properties":{"id":"97206","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.98594,45.760238],[-123.912931,45.783346],[-123.948361,45.825193],[-123.963003,45.87301],[-123.965839,45.888346],[-123.98594,45.760238]]]},"properties":{"id":"97102","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.29617,43.229277],[-124.393774,43.272737],[-124.480031,42.954963],[-124.269285,42.954486],[-124.256527,43.052792],[-124.29617,43.229277]]]},"properties":{"id":"97411","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.995009,43.957859],[-120.085129,44.254032],[-120.085354,44.097009],[-120.089941,43.925671],[-119.995009,43.957859]]]},"properties":{"id":"97751","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.532214,42.80638],[-124.304323,42.807025],[-124.277811,42.85765],[-124.543499,42.855129],[-124.532214,42.80638]]]},"properties":{"id":"97476","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.365522,42.979041],[-123.294558,42.91891],[-123.323633,42.944546],[-123.365522,42.979041]]]},"properties":{"id":"97469","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.33148,43.34119],[-123.357596,43.106277],[-123.216599,43.127511],[-123.10415,43.217038],[-123.076588,43.316975],[-123.08127,43.363316],[-123.08769,43.372968],[-123.309774,43.335923],[-123.34132,43.321269],[-123.32989,43.340653],[-123.33148,43.34119]],[[-123.347106,43.266165],[-123.2833,43.314972],[-123.264375,43.293616],[-123.347106,43.266165]]]},"properties":{"id":"97470","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.076588,43.316975],[-123.10415,43.217038],[-123.005372,43.332595],[-123.076588,43.316975]]]},"properties":{"id":"97443","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.08769,43.372968],[-123.1982,43.420485],[-123.361238,43.364885],[-123.33148,43.34119],[-123.32989,43.340653],[-123.309774,43.335923],[-123.08769,43.372968]]]},"properties":{"id":"97479","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.569875,43.090161],[-123.507763,43.113324],[-123.549305,43.134546],[-123.575342,43.087359],[-123.569875,43.090161]]]},"properties":{"id":"97481","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.012289,44.347436],[-118.807155,44.354531],[-118.769836,44.377967],[-118.80018,44.463803],[-119.037004,44.424377],[-119.012289,44.347436]]]},"properties":{"id":"97845","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.887156,42.919056],[-118.783948,42.550811],[-118.571279,42.697132],[-118.887156,42.919056]]]},"properties":{"id":"97736","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.684974,45.283667],[-121.50998,45.507914],[-121.640536,45.548995],[-121.756941,45.401304],[-121.700841,45.366297],[-121.684974,45.283667]]]},"properties":{"id":"97041","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.879446,42.20427],[-122.822207,42.259942],[-122.819474,42.285005],[-122.886224,42.364039],[-122.955913,42.322303],[-122.879446,42.20427]]]},"properties":{"id":"97501","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.755187,42.178977],[-122.540284,42.289652],[-122.716472,42.289521],[-122.772179,42.267569],[-122.755187,42.178977]]]},"properties":{"id":"97520","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.755663,42.629491],[-122.834133,42.633912],[-122.79701,42.546194],[-122.755409,42.562731],[-122.755663,42.629491]]]},"properties":{"id":"97539","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.755187,42.178977],[-122.772179,42.267569],[-122.790038,42.258763],[-122.822207,42.259942],[-122.879446,42.20427],[-122.866339,42.161932],[-122.755187,42.178977]]]},"properties":{"id":"97540","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.804343,42.424229],[-122.971434,42.557584],[-123.046771,42.335389],[-122.955913,42.322303],[-122.886224,42.364039],[-122.807133,42.394139],[-122.804343,42.424229]]]},"properties":{"id":"97502","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.819474,42.285005],[-122.822207,42.259942],[-122.790038,42.258763],[-122.819474,42.285005]]]},"properties":{"id":"97535","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.291323,44.532624],[-121.159005,44.390663],[-121.115596,44.527482],[-121.291323,44.532624]]],[[[-121.286418,44.597609],[-121.319937,44.49904],[-121.299209,44.52558],[-121.286418,44.597609]]]]},"properties":{"id":"97734","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.40731,42.462227],[-120.328657,41.993227],[-120.29804,42.004836],[-120.276929,41.993162],[-120.159459,41.994587],[-120.150993,42.062016],[-120.388382,42.592055],[-120.40731,42.462227]]]},"properties":{"id":"97630","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.94596,43.68623],[-122.748236,43.586572],[-122.606229,43.613987],[-122.870588,43.757052],[-122.94596,43.68623]]]},"properties":{"id":"97434","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.966192,43.980592],[-122.886574,43.896401],[-122.862202,43.95821],[-122.905909,43.998291],[-122.961302,44.023618],[-122.970077,44.025807],[-122.966192,43.980592]]]},"properties":{"id":"97455","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.859053,44.709793],[-123.817346,44.712851],[-123.726396,44.7034],[-123.787346,44.757233],[-123.852052,44.779995],[-123.859053,44.709793]]]},"properties":{"id":"97357","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.697243,44.611021],[-122.921815,44.630449],[-123.013175,44.541032],[-123.009112,44.50486],[-122.999905,44.468293],[-122.874383,44.413471],[-122.700604,44.435683],[-122.697243,44.611021]]]},"properties":{"id":"97355","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.483168,44.79327],[-122.520702,44.72715],[-122.444929,44.730386],[-122.41324,44.77919],[-122.41596,44.80032],[-122.483168,44.79327]]]},"properties":{"id":"97360","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.483168,44.79327],[-122.616123,44.818066],[-122.61827,44.79202],[-122.61836,44.79021],[-122.662462,44.75194],[-122.606355,44.727621],[-122.520702,44.72715],[-122.483168,44.79327]]],[[[-122.27528,44.78399],[-122.41596,44.80032],[-122.41324,44.77919],[-122.27528,44.78399]]]]},"properties":{"id":"97358","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.951206,44.719582],[-122.969229,44.676834],[-122.921815,44.630449],[-122.697243,44.611021],[-122.606355,44.727621],[-122.662462,44.75194],[-122.835384,44.770335],[-122.864989,44.758199],[-122.951206,44.719582]]]},"properties":{"id":"97374","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.357743,44.247744],[-117.122033,43.93344],[-116.969083,43.937747],[-117.047391,44.229582],[-117.283096,44.272546],[-117.357743,44.247744]]]},"properties":{"id":"97914","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167245,44.157989],[-117.750612,44.254868],[-117.671318,44.353109],[-117.631474,44.444357],[-117.666517,44.444268],[-117.822815,44.443464],[-118.011342,44.417939],[-118.228117,44.169916],[-118.167245,44.157989]]]},"properties":{"id":"97908","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.122033,43.93344],[-117.357743,44.247744],[-117.538242,44.179694],[-117.627214,44.146173],[-117.58536,44.003276],[-117.375441,43.846944],[-117.122033,43.93344]]]},"properties":{"id":"97918","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.98753,44.91119],[-123.044507,44.943541],[-123.11315,44.92849],[-123.149402,44.861254],[-122.99836,44.88517],[-122.98753,44.91119]]]},"properties":{"id":"97302","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.99884,45.00157],[-122.95855,44.94891],[-122.851594,44.964268],[-122.838153,45.057578],[-122.850594,45.077245],[-122.957077,45.076184],[-122.99884,45.00157]]]},"properties":{"id":"97305","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.624033,45.833843],[-119.508062,45.816584],[-119.432769,45.888798],[-119.431889,45.918251],[-119.654141,45.838901],[-119.624033,45.833843]]]},"properties":{"id":"97844","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.643907,45.609739],[-122.666743,45.553318],[-122.620406,45.551846],[-122.620416,45.552852],[-122.563217,45.577961],[-122.549587,45.571336],[-122.586006,45.60474],[-122.643907,45.609739]]]},"properties":{"id":"97211","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.620312,45.535139],[-122.620406,45.551846],[-122.666743,45.553318],[-122.666746,45.535095],[-122.620312,45.535139]]]},"properties":{"id":"97212","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.579056,45.522781],[-122.53919,45.522506],[-122.549587,45.571336],[-122.563217,45.577961],[-122.579677,45.552406],[-122.579056,45.522781]]]},"properties":{"id":"97220","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.680286,45.5287],[-122.677595,45.527321],[-122.677643,45.529881],[-122.680286,45.5287]]]},"properties":{"id":"97208","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.326764,45.040716],[-123.26322,44.989141],[-123.160352,45.072281],[-123.094234,45.098579],[-123.13698,45.16708],[-123.321903,45.090358],[-123.326764,45.040716]]]},"properties":{"id":"97101","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.375793,45.528385],[-120.813173,45.496033],[-120.656841,45.379331],[-120.469596,45.476942],[-120.360961,45.505791],[-120.375793,45.528385]]]},"properties":{"id":"97039","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.816966,45.441103],[-122.826094,45.476447],[-122.826111,45.489394],[-122.902211,45.497268],[-122.920942,45.414981],[-122.853466,45.426371],[-122.816966,45.441103]]]},"properties":{"id":"97007","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.195903,45.780006],[-123.272849,45.743678],[-123.263581,45.706827],[-123.240537,45.705297],[-123.114012,45.721419],[-123.122098,45.779983],[-123.195903,45.780006]]]},"properties":{"id":"97109","state_code":"41"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.084192,45.286585],[-122.999677,45.26082],[-122.974568,45.279932],[-123.077465,45.296091],[-123.084192,45.286585]]]},"properties":{"id":"97115","state_code":"41"}},{"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.965788,39.918266],[-77.018969,39.909372],[-77.006255,39.865366],[-76.931142,39.868438],[-76.948531,39.894179],[-76.965788,39.918266]]]},"properties":{"id":"17301","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.338264,39.877507],[-77.400474,39.952261],[-77.441466,39.839319],[-77.370978,39.830214],[-77.32991,39.856542],[-77.33899,39.867445],[-77.338264,39.877507]]]},"properties":{"id":"17353","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.370978,39.830214],[-77.29301,39.719904],[-77.200217,39.719844],[-77.109173,39.806226],[-77.082361,39.824052],[-77.134084,39.936745],[-77.144622,39.952299],[-77.184644,39.933264],[-77.322315,39.869924],[-77.318108,39.866913],[-77.32991,39.856542],[-77.370978,39.830214]]]},"properties":{"id":"17325","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.808422,40.280292],[-79.806057,40.241958],[-79.805015,40.241106],[-79.775139,40.287888],[-79.787853,40.297693],[-79.808422,40.280292]]]},"properties":{"id":"15018","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.90168,40.486218],[-79.922224,40.524485],[-79.938219,40.519269],[-79.938355,40.490983],[-79.934338,40.49157],[-79.90168,40.486218]]]},"properties":{"id":"15215","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.879336,40.5872],[-79.915228,40.567494],[-79.922224,40.524485],[-79.90168,40.486218],[-79.883772,40.483406],[-79.849451,40.507425],[-79.821799,40.536257],[-79.868798,40.556014],[-79.869114,40.562531],[-79.879336,40.5872]]]},"properties":{"id":"15238","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.112766,40.301825],[-80.094945,40.305907],[-80.116156,40.312324],[-80.112766,40.301825]]],[[[-80.116156,40.312324],[-80.095184,40.363368],[-80.090413,40.381981],[-80.105592,40.393184],[-80.120495,40.372373],[-80.127415,40.360947],[-80.138159,40.35486],[-80.140437,40.35289],[-80.17729,40.339742],[-80.183462,40.332774],[-80.170238,40.328624],[-80.132114,40.30627],[-80.116156,40.312324]]]]},"properties":{"id":"15017","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.764001,40.592735],[-79.775699,40.562828],[-79.77187,40.562967],[-79.764001,40.592735]]]},"properties":{"id":"15030","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.89899,40.436443],[-79.911536,40.414896],[-79.890529,40.410548],[-79.869512,40.414043],[-79.89899,40.436443]]]},"properties":{"id":"15218","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.083913,40.459466],[-80.05103,40.44922],[-80.037568,40.454049],[-80.051178,40.465599],[-80.083913,40.459466]]]},"properties":{"id":"15204","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.965944,40.459178],[-79.950431,40.427598],[-79.934033,40.437707],[-79.937986,40.446943],[-79.943082,40.454566],[-79.960349,40.460466],[-79.965944,40.459178]],[[-79.952502,40.440445],[-79.956438,40.441949],[-79.953545,40.443175],[-79.952502,40.440445]]]},"properties":{"id":"15213","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.067526,40.492271],[-80.108363,40.510521],[-80.120288,40.51314],[-80.16148,40.523803],[-80.099135,40.49943],[-80.067526,40.492271]]]},"properties":{"id":"15225","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.811297,40.561932],[-79.798976,40.549119],[-79.79883,40.562275],[-79.811297,40.561932]]]},"properties":{"id":"15049","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.814993,40.401185],[-79.807406,40.425386],[-79.837541,40.428476],[-79.835485,40.419773],[-79.83068,40.39952],[-79.818568,40.40058],[-79.814993,40.401185]]]},"properties":{"id":"15145","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.837776,40.622753],[-79.84233,40.598512],[-79.826493,40.598898],[-79.837776,40.622753]]]},"properties":{"id":"15076","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.599181,40.488912],[-79.666164,40.515363],[-79.7022,40.503331],[-79.703834,40.443526],[-79.722151,40.409098],[-79.68947,40.399773],[-79.599181,40.488912]]]},"properties":{"id":"15668","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.774324,40.253284],[-79.768288,40.245946],[-79.732272,40.254696],[-79.733473,40.27658],[-79.736684,40.291331],[-79.716153,40.288348],[-79.693267,40.264686],[-79.692003,40.26439],[-79.671954,40.268802],[-79.663139,40.269364],[-79.664868,40.274454],[-79.648547,40.293419],[-79.656419,40.316285],[-79.639394,40.325235],[-79.647766,40.329031],[-79.67098,40.32978],[-79.68551,40.32737],[-79.68714,40.334206],[-79.67179,40.335609],[-79.671105,40.335848],[-79.67189,40.33718],[-79.671456,40.340639],[-79.66892,40.39264],[-79.7232,40.36703],[-79.741942,40.360899],[-79.771082,40.375419],[-79.785882,40.359481],[-79.787608,40.299211],[-79.787853,40.297693],[-79.775139,40.287888],[-79.774324,40.253284]],[[-79.688207,40.293854],[-79.685251,40.296855],[-79.68429,40.29433],[-79.688207,40.293854]],[[-79.72529,40.34132],[-79.729618,40.346013],[-79.72019,40.34997],[-79.72006,40.34318],[-79.72529,40.34132]]]},"properties":{"id":"15642","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.587071,40.974036],[-79.648346,40.902613],[-79.610338,40.867684],[-79.555845,40.888564],[-79.532242,40.936679],[-79.551331,40.980456],[-79.587071,40.974036]]]},"properties":{"id":"16218","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.146782,41.022804],[-79.288261,41.122024],[-79.264862,41.030179],[-79.272201,41.018151],[-79.28141,40.995453],[-79.228276,40.957469],[-79.213719,40.951844],[-79.212546,40.96089],[-79.190102,40.976628],[-79.163541,40.99656],[-79.14421,41.006266],[-79.141909,41.021791],[-79.146782,41.022804]]]},"properties":{"id":"16240","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.326095,41.129407],[-79.310307,41.008004],[-79.290616,41.010018],[-79.296297,40.988702],[-79.28141,40.995453],[-79.272201,41.018151],[-79.281519,41.010364],[-79.29002,41.018118],[-79.264862,41.030179],[-79.288261,41.122024],[-79.314404,41.127238],[-79.326095,41.129407]]]},"properties":{"id":"16224","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.277508,40.897253],[-80.31853,40.843297],[-80.312966,40.833079],[-80.234919,40.783971],[-80.220547,40.791418],[-80.201272,40.880279],[-80.19333,40.923404],[-80.184432,40.944886],[-80.217154,40.954102],[-80.277508,40.897253]]]},"properties":{"id":"16117","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324728,40.723659],[-80.456194,40.713373],[-80.432396,40.627252],[-80.287584,40.695466],[-80.303268,40.716153],[-80.324728,40.723659]]]},"properties":{"id":"15009","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.72285,40.107803],[-78.610548,40.123967],[-78.604334,40.137155],[-78.572009,40.132486],[-78.587046,40.120487],[-78.588661,40.10409],[-78.511762,40.149804],[-78.579543,40.169405],[-78.698671,40.16145],[-78.721151,40.111858],[-78.72285,40.107803]]]},"properties":{"id":"15554","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.588661,40.10409],[-78.587046,40.120487],[-78.610548,40.123967],[-78.72285,40.107803],[-78.763202,40.056745],[-78.763017,40.036468],[-78.760833,39.979822],[-78.603614,40.048542],[-78.588661,40.10409]]]},"properties":{"id":"15559","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.534927,39.856353],[-78.65826,39.722873],[-78.49347,39.722864],[-78.32458,39.789522],[-78.290255,39.845713],[-78.269043,39.900166],[-78.297664,39.912487],[-78.534927,39.856353]]]},"properties":{"id":"15535","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.57483,40.194374],[-78.647953,40.270621],[-78.698671,40.16145],[-78.579543,40.169405],[-78.57483,40.194374]]]},"properties":{"id":"15521","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.359434,40.271058],[-78.36744,40.256727],[-78.30911,40.210896],[-78.258544,40.296831],[-78.189163,40.395519],[-78.290803,40.418078],[-78.345149,40.343633],[-78.359434,40.271058]],[[-78.359434,40.271058],[-78.336365,40.280877],[-78.342711,40.264596],[-78.359434,40.271058]]]},"properties":{"id":"16662","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.125377,40.238267],[-78.121146,40.286335],[-78.10142,40.320207],[-78.123935,40.342536],[-78.124221,40.342363],[-78.162955,40.428703],[-78.189163,40.395519],[-78.258544,40.296831],[-78.125377,40.238267]],[[-78.204757,40.32702],[-78.214775,40.329617],[-78.207359,40.337057],[-78.196802,40.341178],[-78.204757,40.32702]]]},"properties":{"id":"16657","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.507503,40.259158],[-78.504366,40.264856],[-78.511348,40.265574],[-78.513544,40.262657],[-78.507503,40.259158]]]},"properties":{"id":"16670","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.052961,40.394192],[-76.138431,40.30745],[-76.073005,40.297349],[-76.038561,40.369692],[-76.052961,40.394192]]]},"properties":{"id":"19565","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.921251,40.353285],[-75.967655,40.362634],[-75.947692,40.340587],[-75.939216,40.330948],[-75.91513,40.338252],[-75.918973,40.340258],[-75.921251,40.353285]]]},"properties":{"id":"19601","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.659274,40.42662],[-75.590845,40.356793],[-75.588439,40.354312],[-75.57282,40.341646],[-75.571265,40.340296],[-75.540741,40.367955],[-75.541827,40.384603],[-75.563813,40.404684],[-75.593951,40.395483],[-75.57207,40.419773],[-75.552277,40.426026],[-75.550324,40.434468],[-75.567351,40.456369],[-75.570985,40.459397],[-75.619061,40.457835],[-75.659274,40.42662]]]},"properties":{"id":"19504","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.943437,40.182676],[-75.916501,40.12063],[-75.861456,40.121194],[-75.855852,40.182597],[-75.855335,40.190479],[-75.861599,40.199134],[-75.87495,40.21505],[-75.943437,40.182676]]]},"properties":{"id":"19543","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.039864,40.430612],[-76.030815,40.37843],[-75.990512,40.368356],[-75.939647,40.443029],[-75.972005,40.472421],[-76.039864,40.430612]]]},"properties":{"id":"19533","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.188084,40.488187],[-76.178615,40.493503],[-76.181271,40.495325],[-76.188084,40.488187]]]},"properties":{"id":"19559","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.682238,40.543638],[-75.617959,40.53394],[-75.606803,40.54158],[-75.607046,40.551025],[-75.624336,40.579851],[-75.70773,40.580071],[-75.682238,40.543638]]]},"properties":{"id":"18031","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.434175,40.380489],[-78.429116,40.386974],[-78.431104,40.394144],[-78.440821,40.388257],[-78.434175,40.380489]]]},"properties":{"id":"16665","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.491944,40.638842],[-78.52851,40.654775],[-78.602454,40.591452],[-78.477526,40.550796],[-78.440167,40.638761],[-78.491944,40.638842]]]},"properties":{"id":"16636","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.311672,41.821462],[-76.392453,41.857935],[-76.301478,41.730351],[-76.311672,41.821462]]]},"properties":{"id":"18854","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.203942,41.673377],[-76.214235,41.568033],[-76.122157,41.611102],[-76.0862,41.70769],[-76.08669,41.753595],[-76.125747,41.770172],[-76.150721,41.765081],[-76.178047,41.758433],[-76.203942,41.673377]]]},"properties":{"id":"18623","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.302948,41.595305],[-76.276682,41.546298],[-76.220139,41.541285],[-76.214235,41.568033],[-76.203942,41.673377],[-76.302948,41.595305]]]},"properties":{"id":"18846","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.185004,40.342788],[-75.160465,40.286432],[-75.116154,40.265533],[-75.095368,40.27639],[-75.09176,40.305708],[-75.148878,40.339896],[-75.151084,40.341221],[-75.161542,40.347525],[-75.171161,40.349552],[-75.185004,40.342788]]]},"properties":{"id":"18901","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.257269,40.282535],[-75.243171,40.274395],[-75.214133,40.257422],[-75.177401,40.235047],[-75.158836,40.223583],[-75.160465,40.286432],[-75.185004,40.342788],[-75.244139,40.318949],[-75.260186,40.302759],[-75.257269,40.282535]]]},"properties":{"id":"18914","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.257269,40.282535],[-75.260186,40.302759],[-75.263297,40.286154],[-75.257269,40.282535]]]},"properties":{"id":"18932","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.043157,40.299742],[-75.005817,40.295503],[-74.909334,40.306804],[-74.901391,40.315468],[-75.060694,40.421333],[-75.066286,40.423039],[-75.091114,40.403349],[-75.067667,40.390058],[-75.043258,40.375557],[-75.049527,40.325519],[-75.051824,40.316195],[-75.056676,40.314002],[-75.043157,40.299742]]]},"properties":{"id":"18938","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.27436,40.569288],[-75.291714,40.555083],[-75.280092,40.557373],[-75.27436,40.569288]]]},"properties":{"id":"18081","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.518151,40.355848],[-75.472544,40.322448],[-75.448903,40.325169],[-75.383014,40.358049],[-75.363928,40.390191],[-75.419246,40.38465],[-75.470123,40.371618],[-75.482186,40.359273],[-75.518151,40.355848]]]},"properties":{"id":"18054","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.818026,40.193249],[-74.841735,40.165492],[-74.817853,40.140628],[-74.807994,40.15164],[-74.808795,40.181253],[-74.797834,40.19491],[-74.818026,40.193249]]]},"properties":{"id":"19054","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.369879,40.300319],[-75.290946,40.329423],[-75.383014,40.358049],[-75.448903,40.325169],[-75.369879,40.300319]]]},"properties":{"id":"18969","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.250454,40.322714],[-75.28133,40.319635],[-75.338976,40.265311],[-75.266746,40.259586],[-75.263297,40.286154],[-75.260186,40.302759],[-75.244139,40.318949],[-75.250454,40.322714]]]},"properties":{"id":"19440","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.200109,40.96791],[-80.217154,40.954102],[-80.184432,40.944886],[-80.19333,40.923404],[-80.201272,40.880279],[-80.178163,40.874707],[-80.142256,40.919634],[-80.070113,40.954901],[-80.17772,41.011342],[-80.200109,40.96791]]]},"properties":{"id":"16051","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.058202,40.722349],[-80.094667,40.745128],[-80.152562,40.744099],[-80.151953,40.729843],[-80.149806,40.692933],[-80.148451,40.67429],[-80.131782,40.673847],[-80.116498,40.673827],[-80.101542,40.674004],[-80.058202,40.722349]]]},"properties":{"id":"16066","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.670371,40.571911],[-78.654722,40.465666],[-78.6185,40.45567],[-78.587932,40.48394],[-78.560658,40.553472],[-78.596384,40.555943],[-78.615102,40.574341],[-78.653083,40.598438],[-78.670371,40.571911]]]},"properties":{"id":"15940","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.641698,40.405654],[-78.638026,40.407321],[-78.642081,40.410896],[-78.641698,40.405654]]]},"properties":{"id":"15925","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.747068,40.302572],[-78.758032,40.319515],[-78.770715,40.320228],[-78.783952,40.317434],[-78.797772,40.291029],[-78.747068,40.302572]]]},"properties":{"id":"15952","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.878249,40.387108],[-78.872399,40.392773],[-78.881197,40.39238],[-78.878249,40.387108]]],[[[-78.828094,40.448831],[-78.862823,40.367048],[-78.860223,40.360384],[-78.799244,40.365755],[-78.769948,40.379398],[-78.788141,40.436953],[-78.7996,40.446703],[-78.828094,40.448831]]]]},"properties":{"id":"15942","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.753572,40.483619],[-78.748675,40.489688],[-78.773064,40.498035],[-78.772479,40.481937],[-78.753572,40.483619]]]},"properties":{"id":"15948","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.964623,40.924949],[-75.955694,40.909444],[-75.90369,40.926823],[-75.935529,40.935361],[-75.964623,40.924949]]]},"properties":{"id":"18230","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.935529,40.935361],[-75.90369,40.926823],[-75.888499,40.952035],[-75.935529,40.935361]]]},"properties":{"id":"18216","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.546138,41.034291],[-75.481119,41.007574],[-75.437519,41.013244],[-75.526716,41.076236],[-75.625955,41.130343],[-75.645595,41.142762],[-75.636479,41.082601],[-75.546138,41.034291]]]},"properties":{"id":"18610","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.702251,40.727741],[-77.752054,40.763068],[-77.809049,40.736996],[-77.699645,40.721023],[-77.702251,40.727741]]],[[[-77.816881,40.80367],[-77.756164,40.762458],[-77.780603,40.812338],[-77.816881,40.80367]]]]},"properties":{"id":"16827","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.780603,40.812338],[-77.751958,40.826827],[-77.787807,40.839003],[-77.816881,40.80367],[-77.780603,40.812338]]]},"properties":{"id":"16851","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.786249,41.068675],[-77.625941,40.927997],[-77.622272,40.912596],[-77.555095,40.949742],[-77.540986,40.956601],[-77.514275,40.969562],[-77.535961,41.005753],[-77.54725,41.008021],[-77.579952,41.046982],[-77.633067,41.076052],[-77.862457,41.179325],[-77.834934,41.140025],[-77.786249,41.068675]]]},"properties":{"id":"16841","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.908073,41.047465],[-78.002903,41.047736],[-78.035923,40.983194],[-77.965901,40.924988],[-77.885995,41.003204],[-77.908073,41.047465]]]},"properties":{"id":"16874","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.059866,41.051911],[-78.0597,41.003571],[-78.035923,40.983194],[-78.002903,41.047736],[-77.98683,41.061735],[-78.059866,41.051911]]]},"properties":{"id":"16859","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.783679,39.858064],[-75.808746,39.793934],[-75.72501,39.786018],[-75.706135,39.799724],[-75.736675,39.82866],[-75.758777,39.821706],[-75.766385,39.846445],[-75.783679,39.858064]]]},"properties":{"id":"19311","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.605189,40.168998],[-75.618521,40.144355],[-75.578332,40.096986],[-75.456624,40.093854],[-75.424332,40.107906],[-75.443449,40.13388],[-75.454196,40.133052],[-75.460149,40.129591],[-75.464796,40.138061],[-75.49727,40.169729],[-75.531824,40.170612],[-75.605189,40.168998]],[[-75.583483,40.131429],[-75.581074,40.129278],[-75.582399,40.128127],[-75.585163,40.129724],[-75.583483,40.131429]],[[-75.507157,40.145764],[-75.494935,40.149294],[-75.499535,40.132212],[-75.505624,40.132634],[-75.507157,40.145764]]]},"properties":{"id":"19460","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.673269,39.817195],[-75.54087,39.838892],[-75.538236,39.850343],[-75.548375,39.88046],[-75.629023,39.887283],[-75.673269,39.817195]]]},"properties":{"id":"19317","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.602842,39.949913],[-75.598418,39.950827],[-75.601264,39.954068],[-75.604653,39.95253],[-75.602842,39.949913]]]},"properties":{"id":"19383","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.93762,39.840659],[-75.891264,39.825887],[-75.853072,39.889229],[-75.915339,39.924234],[-75.930684,39.914175],[-75.991665,39.882798],[-76.004047,39.870592],[-75.93762,39.840659]]]},"properties":{"id":"19330","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.525431,39.933501],[-75.524606,39.922435],[-75.512933,39.924283],[-75.508057,39.93098],[-75.525431,39.933501]]]},"properties":{"id":"19319","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.567791,40.030898],[-75.570732,40.026395],[-75.558659,40.028085],[-75.559346,40.031692],[-75.567791,40.030898]]]},"properties":{"id":"19345","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.563812,41.125013],[-79.552093,41.124029],[-79.552125,41.12761],[-79.560506,41.127785],[-79.563812,41.125013]]]},"properties":{"id":"16213","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.445045,41.321847],[-79.437927,41.339094],[-79.438392,41.340384],[-79.445556,41.322516],[-79.445045,41.321847]]],[[[-79.445045,41.321847],[-79.467557,41.306386],[-79.471619,41.257017],[-79.449013,41.262465],[-79.439499,41.286819],[-79.445045,41.321847]]]]},"properties":{"id":"16334","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.659702,41.148997],[-79.678522,41.154885],[-79.684015,41.151224],[-79.659702,41.148997]]]},"properties":{"id":"16036","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.363646,41.129729],[-79.472686,41.170208],[-79.540757,41.163218],[-79.55463,41.130119],[-79.548956,41.096404],[-79.446413,41.098103],[-79.363646,41.129729]]]},"properties":{"id":"16255","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.288261,41.122024],[-79.146782,41.022804],[-79.132665,41.02521],[-79.105893,41.023954],[-79.077314,41.033712],[-79.084194,41.040149],[-79.103043,41.059059],[-79.126844,41.09859],[-79.223567,41.166541],[-79.27739,41.172498],[-79.314404,41.127238],[-79.288261,41.122024]]]},"properties":{"id":"15864","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.614604,41.145009],[-78.697952,41.074493],[-78.656279,41.053513],[-78.593167,41.052611],[-78.55331,41.052906],[-78.546936,41.08651],[-78.614604,41.145009]]]},"properties":{"id":"15856","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.869623,41.21232],[-78.790253,41.147449],[-78.741495,41.202335],[-78.779508,41.282595],[-78.933802,41.307214],[-78.956329,41.242356],[-78.869623,41.21232]]]},"properties":{"id":"15824","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.505036,40.810518],[-78.499545,40.790146],[-78.482919,40.803731],[-78.505036,40.810518]]]},"properties":{"id":"16645","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.664761,41.201585],[-77.56712,41.065063],[-77.579952,41.046982],[-77.54725,41.008021],[-77.533957,41.007013],[-77.344923,41.107398],[-77.631197,41.307946],[-77.640578,41.298273],[-77.664761,41.201585]],[[-77.464939,41.085225],[-77.463804,41.085352],[-77.460544,41.083165],[-77.461264,41.082505],[-77.464789,41.083501],[-77.464939,41.085225]],[[-77.474629,41.060053],[-77.461861,41.053998],[-77.47502,41.041744],[-77.492875,41.059088],[-77.474629,41.060053]]]},"properties":{"id":"17751","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.464894,41.404814],[-77.464698,41.404637],[-77.464576,41.404528],[-77.464894,41.404814]]],[[[-77.464894,41.404814],[-77.516408,41.44294],[-77.517922,41.442855],[-77.465055,41.404712],[-77.464894,41.404814]]],[[[-77.358636,41.474069],[-77.409621,41.455701],[-77.414815,41.446348],[-77.389844,41.40034],[-77.388764,41.400914],[-77.358691,41.429398],[-77.35865,41.461153],[-77.358636,41.474069]]],[[[-77.327309,41.186687],[-77.315742,41.189926],[-77.307308,41.182696],[-77.324086,41.175858],[-77.302841,41.10598],[-77.193519,41.096878],[-77.177489,41.194145],[-77.157246,41.294453],[-77.193433,41.34201],[-77.291372,41.351643],[-77.395839,41.277947],[-77.3718,41.21517],[-77.373949,41.194187],[-77.327309,41.186687]]]]},"properties":{"id":"17740","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.373949,41.194187],[-77.3718,41.21517],[-77.396517,41.210455],[-77.373949,41.194187]]]},"properties":{"id":"17779","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.551792,40.883406],[-76.411487,40.83349],[-76.400346,40.816528],[-76.35993,40.818199],[-76.2991,40.831191],[-76.280024,40.887812],[-76.273046,40.894242],[-76.512421,40.946102],[-76.551792,40.883406]]]},"properties":{"id":"17820","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.207827,40.94974],[-76.273046,40.894242],[-76.280024,40.887812],[-76.188954,40.877472],[-76.134101,40.904621],[-76.142344,40.913993],[-76.158887,40.936943],[-76.162244,40.937822],[-76.188707,40.944737],[-76.207827,40.94974]]]},"properties":{"id":"17985","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.568283,41.846055],[-79.761307,41.850827],[-79.770897,41.821842],[-79.642858,41.723521],[-79.612773,41.736539],[-79.561065,41.767972],[-79.569822,41.791049],[-79.566082,41.845596],[-79.568283,41.846055]]]},"properties":{"id":"16434","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.221717,41.803111],[-80.182868,41.779532],[-80.143924,41.812523],[-80.08223,41.761205],[-80.02534,41.698028],[-79.950784,41.720022],[-79.911392,41.738776],[-79.910065,41.77394],[-79.935954,41.831147],[-80.053365,41.879791],[-80.221717,41.803111]]]},"properties":{"id":"16403","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.761307,41.850827],[-79.758719,41.960246],[-79.908304,42.030004],[-79.935954,41.831147],[-79.910065,41.77394],[-79.770897,41.821842],[-79.761307,41.850827]]]},"properties":{"id":"16438","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.315576,41.559584],[-80.393931,41.60549],[-80.420915,41.576308],[-80.416423,41.526651],[-80.359231,41.505469],[-80.330366,41.549567],[-80.315576,41.559584]]]},"properties":{"id":"16131","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.659646,40.426692],[-76.680792,40.353353],[-76.66077,40.354777],[-76.617168,40.376412],[-76.612449,40.382254],[-76.659646,40.426692]]]},"properties":{"id":"17028","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.386377,39.85007],[-75.420447,39.835385],[-75.393279,39.822348],[-75.344545,39.850673],[-75.348827,39.86444],[-75.356501,39.872136],[-75.378389,39.874882],[-75.386377,39.85007]]]},"properties":{"id":"19013","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.306464,39.896121],[-75.298319,39.876792],[-75.288386,39.880858],[-75.289617,39.888737],[-75.302167,39.898041],[-75.306464,39.896121]]]},"properties":{"id":"19074","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.348827,39.86444],[-75.336489,39.875553],[-75.335937,39.877085],[-75.339965,39.886156],[-75.350413,39.881246],[-75.356501,39.872136],[-75.348827,39.86444]]]},"properties":{"id":"19094","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.306693,40.06512],[-75.339617,40.058947],[-75.386632,40.012837],[-75.379965,40.009295],[-75.30103,40.058108],[-75.306693,40.06512]]]},"properties":{"id":"19085","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.639706,41.25326],[-78.548844,41.247548],[-78.548054,41.270925],[-78.639706,41.25326]]],[[[-78.414968,41.240151],[-78.236817,41.230475],[-78.262729,41.355513],[-78.420201,41.335654],[-78.516073,41.330575],[-78.513398,41.293674],[-78.547897,41.280846],[-78.508004,41.252961],[-78.52781,41.246239],[-78.484712,41.24394],[-78.414968,41.240151]]]]},"properties":{"id":"15868","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.420172,41.515362],[-78.642298,41.518118],[-78.641594,41.478017],[-78.648271,41.397243],[-78.420057,41.398014],[-78.420028,41.405046],[-78.420172,41.515362]]]},"properties":{"id":"15857","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.385723,42.022627],[-80.519387,41.950137],[-80.423405,41.961463],[-80.387962,41.988083],[-80.385723,42.022627]]]},"properties":{"id":"16411","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.917445,42.203562],[-79.923397,42.139625],[-79.887507,42.088425],[-79.761732,42.094753],[-79.91009,42.218251],[-79.917445,42.203562]]]},"properties":{"id":"16428","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.977924,42.158255],[-79.923397,42.139625],[-79.917445,42.203562],[-79.977924,42.158255]]]},"properties":{"id":"16421","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.652273,39.95368],[-79.63533,39.9023],[-79.585019,39.887374],[-79.488405,39.926831],[-79.512973,39.967722],[-79.64553,39.99606],[-79.647389,39.997853],[-79.72747,39.98998],[-79.72465,39.97027],[-79.71554,39.9708],[-79.70058,39.97511],[-79.692131,39.958432],[-79.665085,39.960157],[-79.652273,39.95368]]]},"properties":{"id":"15431","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.878094,40.125103],[-79.879161,40.125601],[-79.875324,40.122553],[-79.878094,40.125103]]],[[[-79.863033,40.161975],[-79.858,40.14215],[-79.85311,40.13877],[-79.859597,40.137965],[-79.90417,40.151967],[-79.877961,40.12699],[-79.854928,40.117153],[-79.803381,40.109954],[-79.77306,40.12299],[-79.747724,40.123746],[-79.74418,40.127368],[-79.744404,40.128168],[-79.777587,40.17935],[-79.816989,40.215779],[-79.85241,40.2038],[-79.861311,40.162514],[-79.863033,40.161975]]]]},"properties":{"id":"15012","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.71644,39.92235],[-79.72279,39.919313],[-79.714663,39.914096],[-79.711036,39.920322],[-79.71644,39.92235]]]},"properties":{"id":"15472","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.441116,40.191164],[-79.459465,40.087847],[-79.39899,40.06773],[-79.3932,40.05539],[-79.37806,40.07156],[-79.379623,40.085339],[-79.379344,40.115918],[-79.340436,40.189344],[-79.43377,40.20171],[-79.441116,40.191164]]]},"properties":{"id":"15610","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.40041,40.05303],[-79.41912,40.0471],[-79.39489,40.01775],[-79.389875,40.049753],[-79.3932,40.05539],[-79.395322,40.055011],[-79.40041,40.05303]]]},"properties":{"id":"15446","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.32524,39.999884],[-79.235403,39.999677],[-79.243757,40.005592],[-79.264232,40.065742],[-79.321052,40.07859],[-79.350434,40.085378],[-79.379623,40.085339],[-79.37806,40.07156],[-79.389875,40.049753],[-79.39489,40.01775],[-79.32524,39.999884]]]},"properties":{"id":"15622","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.709338,40.063032],[-79.76532,40.01925],[-79.72747,39.98998],[-79.647389,39.997853],[-79.646731,40.008795],[-79.652105,40.033759],[-79.660314,40.045643],[-79.709338,40.063032]]]},"properties":{"id":"15486","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.215,39.752207],[-79.26422,39.741885],[-79.242788,39.72198],[-79.208429,39.722048],[-79.215,39.752207]]],[[[-79.476667,39.831606],[-79.399734,39.754904],[-79.291727,39.721975],[-79.287459,39.721976],[-79.284961,39.743312],[-79.278038,39.837973],[-79.35604,39.92491],[-79.380637,39.925188],[-79.487258,39.869068],[-79.476667,39.831606]]]]},"properties":{"id":"15424","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.89386,39.90157],[-79.891602,39.868934],[-79.891231,39.866433],[-79.859262,39.859736],[-79.854479,39.852226],[-79.81045,39.86534],[-79.82237,39.90638],[-79.849387,39.933681],[-79.85268,39.9331],[-79.89386,39.90157]]]},"properties":{"id":"15458","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.877812,39.75281],[-79.786274,39.720777],[-79.719112,39.720977],[-79.70133,39.79626],[-79.754345,39.837852],[-79.81045,39.86534],[-79.854479,39.852226],[-79.898114,39.808774],[-79.91041,39.80415],[-79.915926,39.785617],[-79.877812,39.75281]]]},"properties":{"id":"15478","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.751101,40.105066],[-79.77306,40.12299],[-79.803381,40.109954],[-79.81554,40.05909],[-79.801189,40.016715],[-79.76532,40.01925],[-79.709338,40.063032],[-79.751101,40.105066]],[[-79.76671,40.04855],[-79.775716,40.056172],[-79.770899,40.072169],[-79.75139,40.062532],[-79.76671,40.04855]]]},"properties":{"id":"15473","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.78426,39.96163],[-79.777967,39.962511],[-79.779968,39.96643],[-79.787517,39.965272],[-79.78426,39.96163]]]},"properties":{"id":"15449","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.52381,40.09302],[-79.503196,40.087512],[-79.50693,40.09282],[-79.52381,40.09302]]],[[[-79.56151,40.09075],[-79.583464,40.127743],[-79.625845,40.137182],[-79.659357,40.097305],[-79.61294,40.08101],[-79.600846,40.081292],[-79.58193,40.08526],[-79.56151,40.09075]]]]},"properties":{"id":"15683","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.893033,40.080345],[-79.894761,40.070265],[-79.88422,40.068168],[-79.877945,40.078089],[-79.893033,40.080345]]]},"properties":{"id":"15466","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.933756,39.773894],[-79.915926,39.785617],[-79.91041,39.80415],[-79.914412,39.808367],[-79.915967,39.80724],[-79.933756,39.773894]]]},"properties":{"id":"15467","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.866268,39.966461],[-79.863404,39.955123],[-79.85985,39.95574],[-79.86594,39.966302],[-79.866268,39.966461]]]},"properties":{"id":"15420","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.80885,39.98024],[-79.813389,39.984409],[-79.814099,39.978409],[-79.80885,39.98024]]]},"properties":{"id":"15422","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.140613,39.722293],[-77.865539,39.722067],[-77.827142,39.872364],[-77.874315,39.886054],[-77.964743,39.899626],[-77.977255,39.86827],[-78.100131,39.764871],[-78.115314,39.7642],[-78.119372,39.766179],[-78.140613,39.722293]]]},"properties":{"id":"17236","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.687713,40.059244],[-77.676832,40.068499],[-77.641288,40.04],[-77.592987,40.03563],[-77.596088,40.078767],[-77.596703,40.078741],[-77.629132,40.092821],[-77.672074,40.135527],[-77.684495,40.119873],[-77.706301,40.117974],[-77.736215,40.101606],[-77.753907,40.088498],[-77.687713,40.059244]]]},"properties":{"id":"17244","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.593455,40.351205],[-77.643989,40.420226],[-77.734588,40.338296],[-77.70303,40.263576],[-77.69581,40.258291],[-77.65966,40.274536],[-77.595742,40.319542],[-77.586183,40.343418],[-77.593455,40.351205]]]},"properties":{"id":"17021","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.620565,39.76767],[-77.616552,39.772789],[-77.620174,39.775575],[-77.626712,39.769244],[-77.620565,39.76767]]]},"properties":{"id":"17272","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.93468,39.764774],[-79.950117,39.804578],[-79.9676,39.800415],[-79.985589,39.77269],[-79.974479,39.753306],[-79.987545,39.747032],[-79.992381,39.764935],[-80.000286,39.756466],[-80.00637,39.721192],[-79.91628,39.720777],[-79.93468,39.764774]]]},"properties":{"id":"15327","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.356974,40.032863],[-80.460709,40.026928],[-80.519056,40.008945],[-80.519251,39.931938],[-80.439251,39.927688],[-80.348141,40.001036],[-80.356974,40.032863]]]},"properties":{"id":"15377","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.306501,39.955234],[-80.306245,39.959711],[-80.307974,39.961307],[-80.306501,39.955234]]]},"properties":{"id":"15353","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.913746,40.21646],[-77.940203,40.142467],[-77.963988,40.109608],[-77.925276,40.103782],[-77.826762,40.249584],[-77.734588,40.338296],[-77.643989,40.420226],[-77.654295,40.444042],[-77.676371,40.443689],[-77.76172,40.368733],[-77.807725,40.324479],[-77.89667,40.246345],[-77.894053,40.236716],[-77.903958,40.237383],[-77.897051,40.24643],[-77.913746,40.21646]]]},"properties":{"id":"17243","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.888866,40.392178],[-77.931617,40.429904],[-78.003491,40.329095],[-78.036211,40.261693],[-78.042566,40.2263],[-78.013585,40.221594],[-78.004134,40.216614],[-78.000906,40.206046],[-77.976404,40.282771],[-77.952252,40.326877],[-77.888866,40.392178]]]},"properties":{"id":"17052","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.201998,40.2043],[-78.181148,40.192381],[-78.13697,40.212806],[-78.201998,40.2043]]]},"properties":{"id":"16634","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.897051,40.24643],[-77.903958,40.237383],[-77.894053,40.236716],[-77.89667,40.246345],[-77.897051,40.24643]]]},"properties":{"id":"17249","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.555705,40.485843],[-79.555659,40.465884],[-79.500199,40.454107],[-79.373741,40.453853],[-79.367596,40.461474],[-79.339356,40.581085],[-79.341507,40.584877],[-79.359948,40.602361],[-79.523271,40.512796],[-79.555705,40.485843]]]},"properties":{"id":"15681","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.902667,40.627442],[-78.875605,40.636445],[-78.897995,40.636191],[-78.908403,40.628765],[-78.902667,40.627442]]]},"properties":{"id":"15761","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.90322,40.800748],[-78.887135,40.794374],[-78.87866,40.814617],[-78.903317,40.81594],[-78.90322,40.800748]]]},"properties":{"id":"15741","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.125724,40.910561],[-79.081826,40.909956],[-79.051159,40.924481],[-79.125724,40.910561]]]},"properties":{"id":"15744","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.356254,40.532706],[-77.351554,40.534001],[-77.35383,40.538033],[-77.352694,40.539714],[-77.356805,40.542491],[-77.357981,40.541038],[-77.354594,40.538694],[-77.35492,40.533642],[-77.356254,40.532706]]]},"properties":{"id":"17056","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.638833,41.41848],[-75.636353,41.449725],[-75.66109,41.414257],[-75.659733,41.411489],[-75.638833,41.41848]]]},"properties":{"id":"18509","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.501526,41.531646],[-75.651573,41.635646],[-75.665062,41.613314],[-75.632233,41.579744],[-75.588674,41.520707],[-75.501526,41.531646]]]},"properties":{"id":"18433","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.678562,41.393951],[-75.725119,41.413255],[-75.722325,41.371638],[-75.707236,41.383196],[-75.678562,41.393951]]]},"properties":{"id":"18517","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.30019,41.484231],[-75.332861,41.506595],[-75.429732,41.508377],[-75.426936,41.507785],[-75.426893,41.50281],[-75.431269,41.503395],[-75.456383,41.533638],[-75.467679,41.533108],[-75.517954,41.449137],[-75.535823,41.433912],[-75.543209,41.427616],[-75.42404,41.405045],[-75.400857,41.411185],[-75.40038,41.395727],[-75.398403,41.367561],[-75.387311,41.351293],[-75.374364,41.354214],[-75.351312,41.363721],[-75.298342,41.367402],[-75.261738,41.410568],[-75.299339,41.441362],[-75.30019,41.484231]]]},"properties":{"id":"18436","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.66109,41.414257],[-75.67139,41.425116],[-75.672988,41.41094],[-75.659906,41.404525],[-75.659733,41.411489],[-75.66109,41.414257]]]},"properties":{"id":"18503","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.392122,41.68909],[-75.390725,41.689115],[-75.39522,41.691981],[-75.392122,41.68909]]],[[[-75.343854,41.661046],[-75.340442,41.677843],[-75.376062,41.674465],[-75.361288,41.646252],[-75.35984,41.644048],[-75.343854,41.661046]]],[[[-75.576761,41.641826],[-75.46106,41.616407],[-75.437207,41.719431],[-75.440087,41.720903],[-75.604147,41.657144],[-75.576761,41.641826]]]]},"properties":{"id":"18421","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.213119,40.147556],[-76.186311,40.158981],[-76.211509,40.168815],[-76.213119,40.147556]]]},"properties":{"id":"17501","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.211258,40.061927],[-76.310866,40.055546],[-76.293668,39.9907],[-76.211101,39.96089],[-76.204292,39.994878],[-76.20832,40.002202],[-76.196546,40.031947],[-76.197602,40.034613],[-76.199449,40.03934],[-76.196002,40.039307],[-76.211258,40.061927]]]},"properties":{"id":"17602","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.302837,40.111963],[-76.30564,40.113823],[-76.305542,40.110517],[-76.302837,40.111963]]]},"properties":{"id":"17606","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.213481,40.120857],[-76.216187,40.129434],[-76.224189,40.120651],[-76.213481,40.120857]]]},"properties":{"id":"17508","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.350704,40.938072],[-80.370441,40.937716],[-80.365955,40.922389],[-80.350281,40.928673],[-80.350704,40.938072]]]},"properties":{"id":"16160","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.231815,40.284691],[-76.23293,40.29474],[-76.24876,40.290795],[-76.238624,40.282053],[-76.231815,40.284691]]]},"properties":{"id":"17039","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.439027,40.278179],[-76.437687,40.276229],[-76.435976,40.275633],[-76.435156,40.278329],[-76.437078,40.277657],[-76.439027,40.278179]]]},"properties":{"id":"17083","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.335545,40.611029],[-76.465545,40.610351],[-76.535414,40.555157],[-76.456836,40.506094],[-76.440181,40.495952],[-76.24011,40.520529],[-76.245158,40.601993],[-76.24702,40.602019],[-76.246802,40.614439],[-76.282132,40.628931],[-76.335545,40.611029]]]},"properties":{"id":"17963","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.5295,40.65475],[-75.515628,40.702196],[-75.52218,40.712876],[-75.544252,40.714577],[-75.557382,40.703692],[-75.57539,40.665307],[-75.5295,40.65475]]]},"properties":{"id":"18037","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.661928,40.64161],[-75.589693,40.655545],[-75.57539,40.665307],[-75.557382,40.703692],[-75.650223,40.69347],[-75.68383,40.685288],[-75.661928,40.64161]]]},"properties":{"id":"18078","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.41677,40.679014],[-75.445286,40.66882],[-75.459489,40.663726],[-75.46389,40.641945],[-75.459749,40.624191],[-75.448329,40.5975],[-75.441851,40.599996],[-75.419638,40.616097],[-75.430127,40.643837],[-75.41677,40.679014]]]},"properties":{"id":"18109","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.652922,41.258314],[-75.841278,41.297917],[-75.849087,41.294549],[-75.868243,41.284339],[-75.809367,41.282048],[-75.835007,41.251355],[-75.870717,41.261036],[-75.884291,41.248456],[-75.873315,41.246976],[-75.886919,41.238629],[-75.890988,41.245569],[-75.911214,41.250107],[-75.928142,41.24097],[-75.849491,41.189033],[-75.815817,41.15344],[-75.755086,41.174326],[-75.7591,41.194142],[-75.738426,41.194219],[-75.754187,41.174549],[-75.665619,41.210007],[-75.652418,41.249562],[-75.652922,41.258314]]]},"properties":{"id":"18702","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.014268,41.161018],[-76.015355,41.059123],[-75.927643,41.088504],[-75.815817,41.15344],[-75.849491,41.189033],[-75.986907,41.166696],[-76.014268,41.161018]]]},"properties":{"id":"18707","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.19838,41.062064],[-76.053853,41.057881],[-76.015355,41.059123],[-76.014268,41.161018],[-76.053817,41.153818],[-76.093064,41.153765],[-76.143774,41.138338],[-76.19838,41.062064]]]},"properties":{"id":"18660","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.007173,41.384453],[-75.94285,41.42115],[-76.00105,41.41291],[-76.007173,41.384453]]],[[[-76.007173,41.384453],[-76.110946,41.34609],[-76.115219,41.339965],[-76.007173,41.384453]]]]},"properties":{"id":"18618","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.954692,40.992238],[-75.9749,40.987477],[-75.975554,40.980934],[-75.967467,40.983732],[-75.960325,40.985703],[-75.954692,40.992238]]],[[[-75.954692,40.992238],[-75.942295,40.986933],[-75.928188,40.989822],[-75.931789,41.011514],[-75.956268,41.005315],[-75.959463,41.005384],[-76.008057,40.999844],[-76.116785,40.959408],[-76.13219,40.940313],[-76.158887,40.936943],[-76.142344,40.913993],[-76.098862,40.918682],[-76.039976,40.905041],[-75.97172,40.976822],[-75.975698,40.97929],[-75.97559,40.980559],[-75.976635,40.987487],[-75.954692,40.992238]]]]},"properties":{"id":"18202","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.967467,40.983732],[-75.975698,40.97929],[-75.97172,40.976822],[-75.926798,40.982364],[-75.928188,40.989822],[-75.942295,40.986933],[-75.960325,40.985703],[-75.967467,40.983732]]]},"properties":{"id":"18223","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.45685,41.397395],[-77.398411,41.343265],[-77.389844,41.40034],[-77.414815,41.446348],[-77.45685,41.397395]]]},"properties":{"id":"17739","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.953962,41.687418],[-78.885048,41.685902],[-78.952022,41.735088],[-78.953962,41.687418]]]},"properties":{"id":"16333","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.377854,41.747274],[-78.38834,41.728979],[-78.365726,41.722021],[-78.355301,41.730811],[-78.378665,41.746536],[-78.377854,41.747274]]]},"properties":{"id":"16724","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.583533,40.654203],[-77.698488,40.669815],[-77.817115,40.582892],[-77.816757,40.500555],[-77.704957,40.578875],[-77.583889,40.653959],[-77.583533,40.654203]]]},"properties":{"id":"17004","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.410197,40.999248],[-75.434462,40.938042],[-75.363142,40.931056],[-75.410197,40.999248]]],[[[-75.362317,40.929178],[-75.417701,40.905775],[-75.442143,40.893997],[-75.378852,40.832527],[-75.373648,40.834291],[-75.294686,40.862295],[-75.29452,40.92075],[-75.294457,40.921849],[-75.294624,40.924345],[-75.302465,40.925619],[-75.355702,40.9334],[-75.362317,40.929178]]]]},"properties":{"id":"18353","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.363142,40.931056],[-75.434462,40.938042],[-75.421747,40.916812],[-75.417701,40.905775],[-75.362317,40.929178],[-75.363142,40.931056]]]},"properties":{"id":"18322","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.11277,41.009663],[-75.108819,41.005283],[-75.105756,41.011155],[-75.108758,41.015775],[-75.11277,41.009663]]]},"properties":{"id":"18356","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.24075,40.108225],[-75.22361,40.092907],[-75.221573,40.091755],[-75.197833,40.106492],[-75.204271,40.120323],[-75.233766,40.112276],[-75.24075,40.108225]]]},"properties":{"id":"19031","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.346892,40.111097],[-75.32483,40.158473],[-75.308832,40.177605],[-75.347009,40.200543],[-75.443449,40.13388],[-75.424332,40.107906],[-75.347487,40.109508],[-75.346892,40.111097]]]},"properties":{"id":"19403","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.49727,40.169729],[-75.489976,40.224649],[-75.535359,40.252611],[-75.592657,40.225491],[-75.580747,40.205212],[-75.580416,40.203855],[-75.531824,40.170612],[-75.49727,40.169729]]]},"properties":{"id":"19468","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.067132,40.128408],[-75.047165,40.142585],[-75.067081,40.141419],[-75.067132,40.128408]]]},"properties":{"id":"19009","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.162372,40.213873],[-75.15185,40.21206],[-75.152756,40.220028],[-75.158836,40.223583],[-75.177401,40.235047],[-75.226779,40.207371],[-75.228353,40.20833],[-75.236506,40.205346],[-75.245748,40.195646],[-75.255408,40.202095],[-75.279318,40.185719],[-75.274651,40.183122],[-75.254783,40.188572],[-75.248337,40.174819],[-75.232347,40.131483],[-75.186259,40.152561],[-75.154053,40.163486],[-75.162372,40.213873]],[[-75.233459,40.183523],[-75.231358,40.183597],[-75.228645,40.185822],[-75.227816,40.185222],[-75.231452,40.181604],[-75.233459,40.183523]]]},"properties":{"id":"19002","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.324983,40.103268],[-75.280317,40.092976],[-75.24075,40.108225],[-75.233766,40.112276],[-75.247666,40.122201],[-75.298153,40.143159],[-75.326924,40.105214],[-75.324983,40.103268]]]},"properties":{"id":"19462","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.410711,40.223648],[-75.397411,40.222936],[-75.402951,40.22574],[-75.410711,40.223648]]]},"properties":{"id":"19474","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.634517,41.136462],[-76.793052,41.118386],[-76.723904,41.084485],[-76.634517,41.136462]]]},"properties":{"id":"17772","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.48922,40.675993],[-75.486137,40.672999],[-75.483628,40.666873],[-75.459489,40.663726],[-75.445286,40.66882],[-75.422251,40.691857],[-75.479864,40.763495],[-75.534874,40.752972],[-75.531446,40.744414],[-75.527204,40.734068],[-75.52218,40.712876],[-75.515628,40.702196],[-75.48922,40.675993]]]},"properties":{"id":"18067","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.551359,40.804552],[-75.482322,40.770754],[-75.443267,40.818276],[-75.534615,40.806778],[-75.551359,40.804552]]]},"properties":{"id":"18038","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.36082,40.809622],[-75.288011,40.808132],[-75.290074,40.86393],[-75.294686,40.862295],[-75.373648,40.834291],[-75.36082,40.809622]]]},"properties":{"id":"18091","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.696437,40.812182],[-76.639758,40.834292],[-76.667892,40.82867],[-76.696523,40.820333],[-76.696437,40.812182]]],[[[-76.545897,40.793702],[-76.620922,40.780753],[-76.720369,40.781702],[-76.749814,40.776038],[-76.782151,40.767602],[-76.631971,40.734395],[-76.573553,40.731598],[-76.469523,40.743188],[-76.410147,40.764874],[-76.418086,40.763737],[-76.446909,40.759596],[-76.484913,40.759423],[-76.545897,40.793702]]]]},"properties":{"id":"17872","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.488127,40.797132],[-76.484913,40.759423],[-76.446909,40.759596],[-76.45173,40.782756],[-76.458363,40.801317],[-76.467551,40.799971],[-76.488127,40.797132]]]},"properties":{"id":"17834","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.820376,41.067987],[-76.818203,41.068401],[-76.816224,41.074217],[-76.819051,41.077071],[-76.820376,41.067987]]]},"properties":{"id":"17749","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.130438,40.372587],[-77.212907,40.363472],[-77.229166,40.283744],[-77.13295,40.295077],[-77.12783,40.340591],[-77.130438,40.372587]]]},"properties":{"id":"17090","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.130438,40.372587],[-77.109773,40.443526],[-77.242041,40.41761],[-77.229131,40.378688],[-77.212907,40.363472],[-77.130438,40.372587]]]},"properties":{"id":"17068","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.375355,40.478175],[-77.500236,40.420461],[-77.515369,40.402942],[-77.408006,40.403902],[-77.339775,40.447396],[-77.342602,40.452065],[-77.375355,40.478175]]]},"properties":{"id":"17037","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.316651,40.361057],[-77.229131,40.378688],[-77.242041,40.41761],[-77.287246,40.455376],[-77.301994,40.454783],[-77.339775,40.447396],[-77.408006,40.403902],[-77.316651,40.361057]]]},"properties":{"id":"17024","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.595742,40.319542],[-77.670212,40.256087],[-77.646283,40.240663],[-77.614665,40.198549],[-77.541415,40.219283],[-77.432675,40.282319],[-77.444809,40.297539],[-77.506152,40.384571],[-77.580621,40.358556],[-77.586183,40.343418],[-77.595742,40.319542]]]},"properties":{"id":"17006","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.025427,40.065647],[-74.986573,40.038958],[-74.973981,40.048595],[-74.977752,40.067904],[-75.015223,40.094626],[-75.020066,40.090171],[-75.035087,40.074617],[-75.025427,40.065647]]]},"properties":{"id":"19114","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.134724,40.046134],[-75.139367,40.023459],[-75.125988,40.013862],[-75.112773,40.027036],[-75.112821,40.027627],[-75.101572,40.038293],[-75.107033,40.04897],[-75.122147,40.053177],[-75.134724,40.046134]]]},"properties":{"id":"19120","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.112815,39.968358],[-75.130787,39.991397],[-75.132544,39.983166],[-75.135133,39.969442],[-75.125421,39.96196],[-75.112815,39.968358]]]},"properties":{"id":"19125","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.155169,39.943865],[-75.165153,39.945557],[-75.168423,39.930465],[-75.134301,39.925781],[-75.135539,39.929237],[-75.136071,39.932487],[-75.136248,39.940207],[-75.155169,39.943865]]]},"properties":{"id":"19147","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.167233,39.958431],[-75.168421,39.945591],[-75.165153,39.945557],[-75.164338,39.949346],[-75.164174,39.950113],[-75.161959,39.959571],[-75.167233,39.958431]]]},"properties":{"id":"19102","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.122147,40.053177],[-75.146683,40.067547],[-75.150142,40.051578],[-75.134724,40.046134],[-75.122147,40.053177]]]},"properties":{"id":"19126","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.168423,39.930465],[-75.174959,39.898488],[-75.135429,39.893009],[-75.134301,39.925781],[-75.168423,39.930465]]]},"properties":{"id":"19148","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.206973,40.01477],[-75.249181,40.041951],[-75.228149,40.026031],[-75.208008,40.012997],[-75.206973,40.01477]]]},"properties":{"id":"19127","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.206968,40.011372],[-75.19081,39.999899],[-75.165758,40.003952],[-75.161627,40.019982],[-75.196321,40.023928],[-75.206973,40.01477],[-75.208008,40.012997],[-75.206968,40.011372]]]},"properties":{"id":"19129","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.74129,41.406317],[-74.763971,41.409359],[-74.722389,41.347104],[-74.74129,41.406317]]]},"properties":{"id":"18336","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.035771,41.475308],[-75.053556,41.479276],[-75.047533,41.463895],[-75.035771,41.475308]]]},"properties":{"id":"18457","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.71036,41.7749],[-77.804037,41.72701],[-77.828518,41.651423],[-77.827816,41.617063],[-77.600321,41.588422],[-77.604471,41.758265],[-77.71036,41.7749]]]},"properties":{"id":"16922","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.769934,41.998489],[-77.74486,41.9783],[-77.736812,41.998689],[-77.769934,41.998489]]]},"properties":{"id":"16941","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.465545,40.610351],[-76.335545,40.611029],[-76.323955,40.684881],[-76.396156,40.664411],[-76.499563,40.622847],[-76.465545,40.610351]]]},"properties":{"id":"17981","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.342715,40.754822],[-76.342074,40.746638],[-76.325549,40.753929],[-76.342715,40.754822]]]},"properties":{"id":"17936","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.237631,40.792273],[-77.171779,40.709017],[-77.112335,40.727897],[-77.155489,40.848858],[-77.243358,40.832087],[-77.237631,40.792273]]]},"properties":{"id":"17813","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.171779,40.709017],[-77.237631,40.792273],[-77.269444,40.691344],[-77.171779,40.709017]]]},"properties":{"id":"17812","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.084423,40.769115],[-77.082059,40.768442],[-77.08231,40.775915],[-77.087776,40.769816],[-77.084423,40.769115]]]},"properties":{"id":"17861","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.264232,40.065742],[-79.214039,40.035384],[-79.243757,40.005592],[-79.235403,39.999677],[-79.047351,39.934484],[-79.041993,39.963023],[-79.040677,39.973607],[-79.030883,39.968306],[-78.953476,40.0131],[-79.051233,40.099132],[-79.073423,40.106309],[-79.071906,40.11295],[-79.073725,40.113018],[-79.172565,40.125542],[-79.229019,40.097813],[-79.328468,40.095291],[-79.321052,40.07859],[-79.264232,40.065742]],[[-79.085068,40.090626],[-79.096633,40.091178],[-79.090298,40.101176],[-79.079347,40.09401],[-79.085068,40.090626]]]},"properties":{"id":"15501","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.203402,40.179331],[-79.190802,40.139576],[-79.172565,40.125542],[-79.073725,40.113018],[-79.086252,40.132958],[-79.098737,40.129766],[-79.101608,40.142415],[-79.090281,40.141772],[-79.085195,40.134974],[-79.063784,40.138989],[-79.056645,40.144068],[-79.073035,40.154868],[-79.077494,40.170175],[-79.056081,40.161789],[-79.048205,40.146033],[-79.041497,40.147306],[-78.982759,40.152256],[-79.001981,40.248061],[-79.073699,40.264811],[-79.112212,40.211477],[-79.203402,40.179331]]]},"properties":{"id":"15531","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.948497,39.848762],[-78.814882,39.81699],[-78.758486,39.878144],[-78.76918,39.953457],[-78.948497,39.848762]]]},"properties":{"id":"15538","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.085195,40.134974],[-79.090281,40.141772],[-79.101608,40.142415],[-79.098737,40.129766],[-79.086252,40.132958],[-79.085195,40.134974]]]},"properties":{"id":"15544","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.278038,39.837973],[-79.284961,39.743312],[-79.26422,39.741885],[-79.215,39.752207],[-79.171918,39.793954],[-79.170706,39.807261],[-79.204101,39.850126],[-79.278038,39.837973]]]},"properties":{"id":"15540","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.079347,40.09401],[-79.08525,40.094263],[-79.085068,40.090626],[-79.079347,40.09401]]]},"properties":{"id":"15555","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.348884,41.460155],[-76.384748,41.439786],[-76.37327,41.435456],[-76.348884,41.460155]]]},"properties":{"id":"18632","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.69373,41.535754],[-76.653403,41.448016],[-76.612732,41.449308],[-76.52755,41.453625],[-76.508017,41.449842],[-76.554363,41.548294],[-76.677861,41.578541],[-76.69373,41.535754]]]},"properties":{"id":"18616","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.780467,41.495682],[-76.772402,41.471686],[-76.743284,41.401175],[-76.68392,41.389437],[-76.653403,41.448016],[-76.69373,41.535754],[-76.780467,41.495682]]]},"properties":{"id":"18619","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.058514,41.979669],[-76.135117,41.884586],[-76.130696,41.836577],[-76.101246,41.83008],[-75.956755,41.916602],[-75.988842,41.999019],[-76.005514,41.998983],[-76.058514,41.979669]]]},"properties":{"id":"18818","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.707788,41.99812],[-75.763536,41.997838],[-75.74122,41.96505],[-75.707788,41.99812]]]},"properties":{"id":"18821","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.702474,41.781778],[-75.676072,41.728619],[-75.68698,41.76452],[-75.702474,41.781778]]]},"properties":{"id":"18823","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.256888,41.999653],[-77.241195,41.972651],[-77.22887,41.999723],[-77.256888,41.999653]]]},"properties":{"id":"16940","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.981695,40.942423],[-76.95634,40.900818],[-76.8773,40.954579],[-76.866122,41.013234],[-76.882053,41.024753],[-77.058756,41.039145],[-76.981695,40.942423]]]},"properties":{"id":"17837","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.451905,41.923199],[-79.439183,41.818909],[-79.492488,41.813842],[-79.569822,41.791049],[-79.561065,41.767972],[-79.4828,41.696144],[-79.315575,41.790786],[-79.369528,41.906687],[-79.391831,41.952661],[-79.451905,41.923199]]]},"properties":{"id":"16340","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519038,40.477363],[-80.519001,40.318146],[-80.35649,40.308594],[-80.352286,40.315074],[-80.331077,40.345102],[-80.354257,40.379383],[-80.360345,40.379268],[-80.360613,40.382113],[-80.367172,40.45501],[-80.424455,40.477605],[-80.448114,40.477717],[-80.519038,40.477363]],[[-80.405553,40.364418],[-80.407287,40.361669],[-80.412869,40.361776],[-80.411754,40.364392],[-80.405553,40.364418]],[[-80.372727,40.351686],[-80.3793,40.344282],[-80.385601,40.348948],[-80.391771,40.363505],[-80.382011,40.363072],[-80.372727,40.351686]]]},"properties":{"id":"15021","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.989337,40.056502],[-80.015772,40.061165],[-80.020381,40.057831],[-79.989337,40.056502]]]},"properties":{"id":"15358","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.070783,40.097839],[-80.05883,40.098748],[-80.06729,40.108209],[-80.070783,40.097839]]]},"properties":{"id":"15324","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.877945,40.078089],[-79.870856,40.075305],[-79.87077,40.080211],[-79.879497,40.085001],[-79.893033,40.080345],[-79.877945,40.078089]]]},"properties":{"id":"15434","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.385601,40.348948],[-80.3793,40.344282],[-80.372727,40.351686],[-80.385601,40.348948]]]},"properties":{"id":"15004","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.30324,41.848076],[-75.362886,41.869126],[-75.376078,41.880831],[-75.414218,41.848438],[-75.417379,41.822909],[-75.271237,41.797837],[-75.30324,41.848076]]]},"properties":{"id":"18439","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.348871,41.635169],[-75.334281,41.600657],[-75.337829,41.578332],[-75.332861,41.506595],[-75.30019,41.484231],[-75.221384,41.516276],[-75.208266,41.537011],[-75.164581,41.566737],[-75.133684,41.632213],[-75.156225,41.675998],[-75.162693,41.69853],[-75.165796,41.699165],[-75.185575,41.720226],[-75.250935,41.762837],[-75.340442,41.677843],[-75.343854,41.661046],[-75.348933,41.635317],[-75.348871,41.635169]]]},"properties":{"id":"18431","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.185575,41.720226],[-75.165796,41.699165],[-75.176569,41.735144],[-75.185575,41.720226]]],[[[-75.162693,41.69853],[-75.156225,41.675998],[-75.085001,41.688407],[-75.162693,41.69853]]]]},"properties":{"id":"18469","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.861311,40.162514],[-79.85241,40.2038],[-79.866671,40.198776],[-79.857705,40.193175],[-79.861311,40.162514]]]},"properties":{"id":"15087","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.859597,40.137965],[-79.85311,40.13877],[-79.858,40.14215],[-79.859597,40.137965]]]},"properties":{"id":"15072","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.504419,40.35124],[-79.505222,40.344293],[-79.492888,40.347197],[-79.492592,40.354001],[-79.504419,40.35124]]]},"properties":{"id":"15635","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.478868,40.233629],[-79.463489,40.233713],[-79.466443,40.25024],[-79.478868,40.233629]]]},"properties":{"id":"15676","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.716153,40.288348],[-79.736684,40.291331],[-79.733473,40.27658],[-79.716153,40.288348]]]},"properties":{"id":"15678","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.797821,40.11012],[-76.840293,40.120802],[-76.856643,40.096962],[-76.849736,40.094528],[-76.874363,40.071101],[-76.953741,40.011325],[-76.923121,39.948768],[-76.85769,39.947401],[-76.79889,40.000697],[-76.799009,40.000845],[-76.803035,40.004952],[-76.781706,40.077903],[-76.797821,40.11012]]]},"properties":{"id":"17315","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.709495,40.107814],[-76.726127,40.032764],[-76.711984,40.019811],[-76.644508,40.058069],[-76.707881,40.110626],[-76.709495,40.107814]]]},"properties":{"id":"17347","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.814045,39.81524],[-76.68832,39.844574],[-76.691349,39.857007],[-76.713879,39.870427],[-76.715654,39.897702],[-76.755404,39.907688],[-76.804616,39.881247],[-76.814045,39.81524]]]},"properties":{"id":"17360","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.804616,39.881247],[-76.855276,39.906382],[-76.948531,39.894179],[-76.931142,39.868438],[-76.880368,39.777204],[-76.803331,39.792914],[-76.814045,39.81524],[-76.804616,39.881247]],[[-76.843334,39.817033],[-76.843752,39.81833],[-76.842145,39.81919],[-76.841192,39.817287],[-76.845357,39.813862],[-76.846052,39.815384],[-76.843334,39.817033]]]},"properties":{"id":"17362","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.715654,39.897702],[-76.713879,39.870427],[-76.700597,39.892423],[-76.715654,39.897702]]]},"properties":{"id":"17407","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.911898,40.18488],[-76.933149,40.140305],[-76.932974,40.093432],[-76.856643,40.096962],[-76.840293,40.120802],[-76.821658,40.130995],[-76.836965,40.178858],[-76.911898,40.18488]]]},"properties":{"id":"17339","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.375281,40.024742],[-77.315395,40.010223],[-77.154733,39.980424],[-77.137425,40.069945],[-77.148776,40.087593],[-77.147901,40.09916],[-77.195614,40.079418],[-77.274971,40.06598],[-77.374355,40.053474],[-77.375281,40.024742]]]},"properties":{"id":"17324","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.934946,40.242058],[-79.924513,40.243288],[-79.866671,40.198776],[-79.85241,40.2038],[-79.816989,40.215779],[-79.806057,40.241958],[-79.808422,40.280292],[-79.837621,40.314722],[-79.840955,40.320239],[-79.874683,40.317126],[-79.884144,40.311503],[-79.886293,40.276917],[-79.897369,40.267161],[-79.914139,40.252518],[-79.927749,40.24401],[-79.934946,40.242058]]]},"properties":{"id":"15037","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.881407,40.341406],[-79.885612,40.345168],[-79.891884,40.33899],[-79.884144,40.311503],[-79.874683,40.317126],[-79.881407,40.341406]]]},"properties":{"id":"15045","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.228579,40.573072],[-80.216771,40.574348],[-80.18784,40.623147],[-80.232983,40.626249],[-80.233982,40.582367],[-80.228579,40.573072]]]},"properties":{"id":"15003","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.843941,40.410369],[-79.846633,40.393702],[-79.83068,40.39952],[-79.835485,40.419773],[-79.843941,40.410369]]]},"properties":{"id":"15112","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.000665,40.402068],[-79.998568,40.389791],[-79.995076,40.384696],[-79.95872,40.409824],[-79.938594,40.394456],[-79.953974,40.418242],[-79.997548,40.425992],[-80.010429,40.414729],[-80.000665,40.402068]]]},"properties":{"id":"15210","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.869512,40.414043],[-79.890529,40.410548],[-79.876562,40.402272],[-79.854501,40.38877],[-79.853495,40.388332],[-79.846633,40.393702],[-79.843941,40.410369],[-79.869512,40.414043]]]},"properties":{"id":"15104","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.085754,40.594143],[-80.018303,40.58526],[-80.01978,40.613611],[-80.036668,40.668254],[-80.0703,40.653452],[-80.097719,40.66072],[-80.130801,40.661794],[-80.13924,40.622667],[-80.085754,40.594143]],[[-80.088416,40.629397],[-80.089752,40.640391],[-80.069778,40.642567],[-80.069356,40.632872],[-80.088416,40.629397]]]},"properties":{"id":"15090","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.876676,40.354337],[-79.893858,40.339983],[-79.891884,40.33899],[-79.885612,40.345168],[-79.876676,40.354337]]]},"properties":{"id":"15034","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.918572,40.410757],[-79.934033,40.437707],[-79.950431,40.427598],[-79.960296,40.428635],[-79.953974,40.418242],[-79.938594,40.394456],[-79.940651,40.374682],[-79.908844,40.376692],[-79.918572,40.410757]]]},"properties":{"id":"15207","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.960296,40.428635],[-79.950431,40.427598],[-79.965944,40.459178],[-79.973025,40.455714],[-80.01629,40.443207],[-80.02709,40.446199],[-80.029738,40.447858],[-80.027954,40.443623],[-80.003958,40.43201],[-79.960296,40.428635]]]},"properties":{"id":"15219","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.027954,40.443623],[-80.021096,40.425233],[-80.017414,40.421966],[-80.010429,40.414729],[-79.997548,40.425992],[-80.003958,40.43201],[-80.027954,40.443623]]]},"properties":{"id":"15211","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.847944,40.356932],[-79.876676,40.354337],[-79.885612,40.345168],[-79.881407,40.341406],[-79.840955,40.320239],[-79.837621,40.314722],[-79.818398,40.3189],[-79.816069,40.319611],[-79.826515,40.362056],[-79.836169,40.364992],[-79.847944,40.356932]]]},"properties":{"id":"15132","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.097719,40.66072],[-80.116498,40.673827],[-80.131782,40.673847],[-80.130801,40.661794],[-80.097719,40.66072]]],[[[-80.058202,40.722349],[-80.101542,40.674004],[-80.0703,40.653452],[-80.036668,40.668254],[-80.010116,40.675779],[-79.971173,40.744819],[-79.978645,40.744806],[-80.058202,40.722349]]]]},"properties":{"id":"16046","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.216771,40.574348],[-80.228579,40.573072],[-80.210117,40.553327],[-80.216771,40.574348]]]},"properties":{"id":"15056","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.33434,40.798642],[-79.32096,40.80814],[-79.327126,40.81913],[-79.33434,40.798642]]]},"properties":{"id":"16263","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.350936,40.899456],[-79.32215,40.82725],[-79.274268,40.801079],[-79.261435,40.792462],[-79.222337,40.788407],[-79.215002,40.784673],[-79.204894,40.804677],[-79.196783,40.828145],[-79.171964,40.910884],[-79.213719,40.951844],[-79.228276,40.957469],[-79.350936,40.899456]]]},"properties":{"id":"16222","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.567652,40.589951],[-79.551652,40.601887],[-79.556858,40.599165],[-79.565932,40.593403],[-79.567652,40.589951]]]},"properties":{"id":"15673","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.814298,41.173134],[-79.833493,41.122397],[-79.810849,41.135757],[-79.797754,41.146174],[-79.814298,41.173134]]],[[[-79.786775,41.129302],[-79.803086,41.090473],[-79.743955,41.053345],[-79.72584,41.054633],[-79.706229,41.042597],[-79.609757,41.009165],[-79.581319,40.99482],[-79.548956,41.096404],[-79.55463,41.130119],[-79.636938,41.1533],[-79.659702,41.148997],[-79.684015,41.151224],[-79.787239,41.146319],[-79.786775,41.129302]],[[-79.563812,41.125013],[-79.560506,41.127785],[-79.552125,41.12761],[-79.552093,41.124029],[-79.563812,41.125013]]]]},"properties":{"id":"16049","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.238642,40.751653],[-79.275272,40.743554],[-79.279664,40.692609],[-79.180339,40.675923],[-79.180067,40.676484],[-79.171985,40.69066],[-79.168917,40.702143],[-79.160552,40.711387],[-79.215235,40.776016],[-79.238642,40.751653]]]},"properties":{"id":"15732","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.151953,40.729843],[-80.189405,40.712811],[-80.149806,40.692933],[-80.151953,40.729843]]],[[[-80.163789,40.772475],[-80.152562,40.744099],[-80.094667,40.745128],[-80.094854,40.803031],[-80.167206,40.811351],[-80.170789,40.772331],[-80.163789,40.772475]]]]},"properties":{"id":"16063","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.220547,40.791418],[-80.170789,40.772331],[-80.167206,40.811351],[-80.178163,40.874707],[-80.201272,40.880279],[-80.220547,40.791418]]]},"properties":{"id":"16123","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.31853,40.843297],[-80.332502,40.834704],[-80.334679,40.833874],[-80.325525,40.826636],[-80.312966,40.833079],[-80.31853,40.843297]]]},"properties":{"id":"16136","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.487572,40.153981],[-78.511762,40.149804],[-78.588661,40.10409],[-78.603614,40.048542],[-78.644315,39.876103],[-78.718144,39.7603],[-78.742523,39.722988],[-78.65826,39.722873],[-78.534927,39.856353],[-78.399437,40.102296],[-78.487572,40.153981]]]},"properties":{"id":"15522","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.395654,40.24012],[-78.379372,40.181386],[-78.370595,40.158965],[-78.355075,40.169126],[-78.30911,40.210896],[-78.36744,40.256727],[-78.395654,40.24012]]]},"properties":{"id":"16695","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.660942,40.34617],[-78.620383,40.327025],[-78.564616,40.284129],[-78.539083,40.345766],[-78.537164,40.347154],[-78.563374,40.374794],[-78.641698,40.405654],[-78.642081,40.410896],[-78.654792,40.465419],[-78.746776,40.436551],[-78.719011,40.391131],[-78.718455,40.389005],[-78.717215,40.389424],[-78.706816,40.392394],[-78.660942,40.34617]]]},"properties":{"id":"15946","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.240443,40.151212],[-78.227314,40.159926],[-78.230834,40.162174],[-78.242196,40.158539],[-78.240443,40.151212]]]},"properties":{"id":"16633","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.95592,40.318995],[-75.984351,40.309464],[-75.992658,40.312644],[-76.00256,40.295372],[-75.915842,40.267506],[-75.920472,40.310594],[-75.95592,40.318995]]]},"properties":{"id":"19607","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.769226,40.481599],[-75.756869,40.473795],[-75.748744,40.481249],[-75.754175,40.483348],[-75.769226,40.481599]]]},"properties":{"id":"19536","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.805061,40.343414],[-75.804289,40.343707],[-75.805544,40.344593],[-75.805061,40.343414]]],[[[-75.805061,40.343414],[-75.804501,40.337006],[-75.801829,40.338529],[-75.805061,40.343414]]]]},"properties":{"id":"19535","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.869737,40.520211],[-75.874647,40.525838],[-75.882604,40.517106],[-75.869737,40.520211]]]},"properties":{"id":"19564","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.602454,40.591452],[-78.606869,40.582686],[-78.596384,40.555943],[-78.560658,40.553472],[-78.567537,40.495447],[-78.565125,40.48831],[-78.537116,40.50992],[-78.477526,40.550796],[-78.602454,40.591452]]]},"properties":{"id":"16613","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.396178,41.654315],[-76.301478,41.730351],[-76.392453,41.857935],[-76.411822,41.871917],[-76.495903,41.849171],[-76.595619,41.781388],[-76.602969,41.776488],[-76.611791,41.774139],[-76.630015,41.745928],[-76.442312,41.647843],[-76.396178,41.654315]]]},"properties":{"id":"18848","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.380364,41.960418],[-76.637756,41.925344],[-76.516189,41.880566],[-76.495903,41.849171],[-76.416194,41.916091],[-76.391356,41.91453],[-76.362459,41.914529],[-76.360113,41.920667],[-76.380364,41.960418]]]},"properties":{"id":"18810","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.679528,41.917631],[-76.624103,41.845978],[-76.617639,41.866755],[-76.516189,41.880566],[-76.637756,41.925344],[-76.679528,41.917631]]]},"properties":{"id":"18831","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.633344,41.810116],[-76.924419,41.837361],[-76.949439,41.824036],[-76.952063,41.73691],[-76.95275,41.698077],[-76.946098,41.672112],[-76.787238,41.718046],[-76.611791,41.774139],[-76.602969,41.776488],[-76.615382,41.785652],[-76.633344,41.810116]]]},"properties":{"id":"16947","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.977752,40.067904],[-74.973981,40.048595],[-74.909103,40.070505],[-74.902554,40.104119],[-74.904197,40.11355],[-74.898451,40.132235],[-74.953788,40.145856],[-74.980058,40.119883],[-74.97994,40.119742],[-74.977752,40.067904]]]},"properties":{"id":"19020","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.861671,40.159256],[-74.870981,40.171835],[-74.875866,40.126801],[-74.807994,40.15164],[-74.817853,40.140628],[-74.861671,40.159256]]]},"properties":{"id":"19057","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.043157,40.299742],[-75.056676,40.314002],[-75.09176,40.305708],[-75.095368,40.27639],[-75.035422,40.260535],[-75.014795,40.286525],[-75.005817,40.295503],[-75.043157,40.299742]]]},"properties":{"id":"18925","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466713,40.434649],[-75.541827,40.384603],[-75.540741,40.367955],[-75.518151,40.355848],[-75.482186,40.359273],[-75.492983,40.382394],[-75.471062,40.379392],[-75.470123,40.371618],[-75.419246,40.38465],[-75.466713,40.434649]]]},"properties":{"id":"18073","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.139245,40.211775],[-75.127729,40.171641],[-75.100093,40.153291],[-75.07341,40.172298],[-75.137782,40.210886],[-75.139245,40.211775]]]},"properties":{"id":"19040","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.142256,40.919634],[-80.066206,40.859526],[-80.028422,40.865716],[-80.017802,40.872709],[-80.035321,40.956419],[-80.070113,40.954901],[-80.142256,40.919634]]]},"properties":{"id":"16052","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17772,41.011342],[-80.070113,40.954901],[-80.035321,40.956419],[-79.947862,41.005456],[-79.938861,41.052333],[-79.980846,41.09916],[-79.99578,41.098996],[-80.02189,41.112214],[-80.029499,41.139738],[-80.096861,41.069804],[-80.229054,41.051084],[-80.17772,41.011342]]]},"properties":{"id":"16057","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.810849,41.135757],[-79.833493,41.122397],[-79.893035,41.089675],[-79.828942,41.059611],[-79.821547,41.060829],[-79.803086,41.090473],[-79.786775,41.129302],[-79.810849,41.135757]]]},"properties":{"id":"16040","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.040583,40.743471],[-80.035045,40.736245],[-80.032118,40.741578],[-80.040583,40.743471]]]},"properties":{"id":"16024","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.984041,41.235252],[-79.990864,41.250577],[-80.045011,41.249469],[-80.123996,41.226191],[-80.145447,41.112267],[-80.096861,41.069804],[-80.029499,41.139738],[-79.984041,41.235252]]]},"properties":{"id":"16127","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.781968,40.572254],[-78.670371,40.571911],[-78.653083,40.598438],[-78.698527,40.629859],[-78.757728,40.614378],[-78.759822,40.61551],[-78.759691,40.636175],[-78.768963,40.635107],[-78.777035,40.601416],[-78.781968,40.572254]],[[-78.761131,40.59659],[-78.760411,40.604919],[-78.752716,40.604885],[-78.761131,40.59659]]]},"properties":{"id":"15722","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.927822,40.323255],[-79.033915,40.316491],[-79.058868,40.276298],[-79.073699,40.264811],[-79.001981,40.248061],[-78.941773,40.246267],[-78.895344,40.270027],[-78.912265,40.28991],[-78.914553,40.314246],[-78.927822,40.323255]]]},"properties":{"id":"15905","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.769948,40.379398],[-78.746332,40.367271],[-78.660942,40.34617],[-78.706816,40.392394],[-78.71904,40.385525],[-78.719011,40.391131],[-78.746776,40.436551],[-78.788141,40.436953],[-78.769948,40.379398]]]},"properties":{"id":"15958","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.641698,40.405654],[-78.563374,40.374794],[-78.556958,40.409136],[-78.6185,40.45567],[-78.654722,40.465666],[-78.654792,40.465419],[-78.642081,40.410896],[-78.638026,40.407321],[-78.641698,40.405654]]]},"properties":{"id":"15938","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.91354,40.499641],[-78.906803,40.484066],[-78.855015,40.504255],[-78.863132,40.511585],[-78.87711,40.510357],[-78.90235,40.514521],[-78.91354,40.499641]]]},"properties":{"id":"15960","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.381743,40.679209],[-78.491944,40.638842],[-78.440167,40.638761],[-78.408629,40.644349],[-78.381743,40.679209]]]},"properties":{"id":"16619","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.799349,40.282789],[-78.812235,40.283141],[-78.811265,40.271003],[-78.799349,40.282789]]]},"properties":{"id":"15934","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.882667,40.765607],[-78.877533,40.748205],[-78.867129,40.732176],[-78.894719,40.672639],[-78.851168,40.669902],[-78.80422,40.701978],[-78.769317,40.697939],[-78.74663,40.693597],[-78.741471,40.725518],[-78.732426,40.785658],[-78.784552,40.806227],[-78.806476,40.809315],[-78.838874,40.780507],[-78.856085,40.803443],[-78.882667,40.765607]]]},"properties":{"id":"15724","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.725599,40.294946],[-78.711773,40.295535],[-78.717682,40.299344],[-78.725599,40.294946]]]},"properties":{"id":"15930","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.761762,40.883699],[-75.951537,40.873306],[-75.906242,40.833736],[-75.905813,40.833363],[-75.877567,40.842569],[-75.812004,40.845217],[-75.761762,40.883699]]]},"properties":{"id":"18240","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.625941,40.927997],[-77.786249,41.068675],[-77.908073,41.047465],[-77.885995,41.003204],[-77.924142,40.859288],[-77.915926,40.843129],[-77.825627,40.846705],[-77.787807,40.839003],[-77.751958,40.826827],[-77.625941,40.927997]],[[-77.786773,40.943068],[-77.785317,40.933329],[-77.791493,40.937252],[-77.791556,40.94227],[-77.786773,40.943068]]]},"properties":{"id":"16823","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.965901,40.924988],[-78.04831,40.867788],[-77.924142,40.859288],[-77.885995,41.003204],[-77.965901,40.924988]]]},"properties":{"id":"16844","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.939424,40.775485],[-77.915926,40.843129],[-77.924142,40.859288],[-78.04831,40.867788],[-78.211229,40.775364],[-78.116521,40.739127],[-77.939424,40.775485]]]},"properties":{"id":"16870","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.264214,40.824763],[-78.261629,40.807017],[-78.221902,40.814228],[-78.264214,40.824763]]]},"properties":{"id":"16677","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.972841,40.760439],[-78.078121,40.703813],[-78.098277,40.690909],[-78.104681,40.666083],[-77.996818,40.680692],[-77.894282,40.716203],[-77.879191,40.727422],[-77.893103,40.732003],[-77.90854,40.742664],[-77.972841,40.760439]]]},"properties":{"id":"16865","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.891264,39.825887],[-75.843064,39.781957],[-75.808746,39.793934],[-75.783679,39.858064],[-75.789627,39.885318],[-75.853072,39.889229],[-75.891264,39.825887]]]},"properties":{"id":"19390","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.6996,40.102993],[-75.680222,40.071019],[-75.618462,40.056072],[-75.578332,40.096986],[-75.618521,40.144355],[-75.674178,40.150449],[-75.6996,40.102993]]]},"properties":{"id":"19425","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.720835,39.939666],[-75.500632,39.965099],[-75.496978,39.973256],[-75.584689,40.029602],[-75.638013,40.014984],[-75.735142,39.95783],[-75.720835,39.939666]]]},"properties":{"id":"19380","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.525431,39.933501],[-75.508057,39.93098],[-75.512933,39.924283],[-75.524606,39.922435],[-75.525513,39.921935],[-75.515095,39.889415],[-75.546771,39.881002],[-75.548375,39.88046],[-75.538236,39.850343],[-75.473108,39.879968],[-75.477183,39.892772],[-75.443494,39.957245],[-75.504542,39.957474],[-75.525431,39.933501]]]},"properties":{"id":"19342","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.766385,39.846445],[-75.758777,39.821706],[-75.736675,39.82866],[-75.766385,39.846445]]]},"properties":{"id":"19374","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.432433,40.065082],[-75.404338,40.027364],[-75.386632,40.012837],[-75.339617,40.058947],[-75.341984,40.068692],[-75.456186,40.087776],[-75.474378,40.06271],[-75.436369,40.070533],[-75.432433,40.065082]]]},"properties":{"id":"19087","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.551078,41.299137],[-79.574521,41.282322],[-79.550543,41.283273],[-79.551078,41.299137]]]},"properties":{"id":"16331","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.551301,40.876014],[-78.602315,40.815182],[-78.581015,40.755596],[-78.499545,40.790146],[-78.505036,40.810518],[-78.52655,40.826141],[-78.551301,40.876014]]]},"properties":{"id":"16656","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.654576,41.186193],[-78.614604,41.145009],[-78.546936,41.08651],[-78.484712,41.24394],[-78.52781,41.246239],[-78.548844,41.247548],[-78.639706,41.25326],[-78.6445,41.253622],[-78.688409,41.207327],[-78.654576,41.186193]]]},"properties":{"id":"15849","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.533957,41.007013],[-77.54725,41.008021],[-77.535961,41.005753],[-77.533957,41.007013]]]},"properties":{"id":"16848","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.727999,41.353182],[-77.640578,41.298273],[-77.631197,41.307946],[-77.598129,41.478576],[-77.727999,41.353182]]]},"properties":{"id":"17760","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.464939,41.085225],[-77.464789,41.083501],[-77.461264,41.082505],[-77.460544,41.083165],[-77.463804,41.085352],[-77.464939,41.085225]]]},"properties":{"id":"17767","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.425552,41.044748],[-76.369896,41.068541],[-76.274553,41.124363],[-76.293604,41.149213],[-76.385727,41.152251],[-76.451539,41.167177],[-76.444665,41.10603],[-76.425552,41.044748]]]},"properties":{"id":"17859","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.350076,40.806603],[-76.313843,40.799415],[-76.30717,40.801809],[-76.2991,40.831191],[-76.35993,40.818199],[-76.350076,40.806603]]]},"properties":{"id":"17920","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.35993,40.818199],[-76.400346,40.816528],[-76.392373,40.79755],[-76.350076,40.806603],[-76.35993,40.818199]]]},"properties":{"id":"17888","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.501187,41.232496],[-76.447597,41.275629],[-76.570816,41.285779],[-76.602961,41.239991],[-76.596326,41.241211],[-76.577913,41.192661],[-76.501187,41.232496]]]},"properties":{"id":"17774","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519352,41.807798],[-80.240893,41.801502],[-80.235826,41.803248],[-80.268862,41.849472],[-80.519354,41.849563],[-80.519352,41.807798]]]},"properties":{"id":"16435","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.092017,41.942007],[-80.053365,41.879791],[-79.935954,41.831147],[-79.908304,42.030004],[-79.912334,42.057642],[-79.948605,42.075821],[-80.122171,42.007918],[-80.092017,41.942007]],[[-79.965674,41.88485],[-79.964277,41.874906],[-79.971249,41.876658],[-79.965674,41.88485]]]},"properties":{"id":"16441","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519354,41.849563],[-80.268862,41.849472],[-80.266156,41.890543],[-80.380061,41.923069],[-80.428142,41.936846],[-80.519308,41.90721],[-80.519354,41.849563]]]},"properties":{"id":"16401","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.13295,40.295077],[-77.269957,40.278671],[-77.322842,40.270721],[-77.274971,40.06598],[-77.195614,40.079418],[-77.157409,40.12147],[-77.085809,40.167869],[-77.077546,40.217804],[-77.06578,40.294273],[-77.063874,40.301967],[-77.13295,40.295077]],[[-77.295815,40.204175],[-77.272911,40.206162],[-77.272682,40.198493],[-77.291234,40.193982],[-77.295815,40.204175]]]},"properties":{"id":"17015","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.929066,40.311331],[-76.940354,40.309056],[-76.926792,40.301825],[-76.929066,40.311331]]]},"properties":{"id":"17093","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.812715,40.609634],[-76.816737,40.596071],[-76.802057,40.5989],[-76.812715,40.609634]]]},"properties":{"id":"17005","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.879155,40.616737],[-76.949414,40.628163],[-76.985424,40.526375],[-76.984389,40.514565],[-76.870068,40.529851],[-76.879155,40.616737]]]},"properties":{"id":"17061","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.396452,39.968971],[-75.364898,39.953513],[-75.335132,39.954973],[-75.343204,39.989629],[-75.344986,39.991885],[-75.3682,40.000982],[-75.396452,39.968971]]]},"properties":{"id":"19008","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.339965,39.886156],[-75.335937,39.877085],[-75.313855,39.892788],[-75.315365,39.898098],[-75.333957,39.900755],[-75.339965,39.886156]]]},"properties":{"id":"19033","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.247688,39.962817],[-75.280266,39.974964],[-75.281295,39.974528],[-75.297496,39.962673],[-75.276984,39.953991],[-75.250658,39.948445],[-75.249499,39.957189],[-75.247688,39.962817]]]},"properties":{"id":"19082","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.298319,39.876792],[-75.306464,39.896121],[-75.313855,39.892788],[-75.306709,39.875653],[-75.298319,39.876792]]]},"properties":{"id":"19076","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.679155,41.339542],[-78.779508,41.282595],[-78.741495,41.202335],[-78.688409,41.207327],[-78.6445,41.253622],[-78.679155,41.339542]]]},"properties":{"id":"15823","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.114437,42.11911],[-80.090693,42.126171],[-80.080836,42.129791],[-80.053651,42.14156],[-80.058292,42.150071],[-80.075425,42.153151],[-80.0879,42.148598],[-80.118561,42.129849],[-80.114437,42.11911]]]},"properties":{"id":"16507","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.039909,42.12358],[-80.072198,42.112415],[-80.062596,42.095615],[-80.027778,42.102888],[-80.039909,42.12358]],[[-80.056358,42.103508],[-80.054705,42.106703],[-80.050857,42.104186],[-80.056358,42.103508]]]},"properties":{"id":"16504","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.056358,42.103508],[-80.050857,42.104186],[-80.054705,42.106703],[-80.056358,42.103508]]]},"properties":{"id":"16546","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.70133,39.79626],[-79.719112,39.720977],[-79.702157,39.720977],[-79.52441,39.73764],[-79.521812,39.785854],[-79.503527,39.803562],[-79.620342,39.864764],[-79.682091,39.818358],[-79.684258,39.815984],[-79.70133,39.79626]]]},"properties":{"id":"15437","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.60123,39.84641],[-79.594647,39.841841],[-79.59039,39.84439],[-79.59573,39.84797],[-79.60123,39.84641]]]},"properties":{"id":"15421","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.863838,39.954542],[-79.859779,39.945358],[-79.86111,39.932129],[-79.85268,39.9331],[-79.849387,39.933681],[-79.85985,39.95574],[-79.863404,39.955123],[-79.863838,39.954542]]]},"properties":{"id":"15435","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.754345,39.837852],[-79.70133,39.79626],[-79.684258,39.815984],[-79.754345,39.837852]]]},"properties":{"id":"15436","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.3932,40.05539],[-79.39899,40.06773],[-79.395322,40.055011],[-79.3932,40.05539]]],[[[-79.41912,40.0471],[-79.40041,40.05303],[-79.421281,40.047937],[-79.41912,40.0471]]],[[[-79.3932,40.05539],[-79.389875,40.049753],[-79.37806,40.07156],[-79.3932,40.05539]]]]},"properties":{"id":"15462","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.64553,39.99606],[-79.646731,40.008795],[-79.647389,39.997853],[-79.64553,39.99606]]]},"properties":{"id":"15455","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.877812,39.75281],[-79.857353,39.720707],[-79.786274,39.720777],[-79.877812,39.75281]]]},"properties":{"id":"15451","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.88238,39.93889],[-79.89386,39.90157],[-79.85268,39.9331],[-79.86111,39.932129],[-79.88238,39.93889]]]},"properties":{"id":"15443","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.914412,39.808367],[-79.921716,39.831324],[-79.915967,39.80724],[-79.914412,39.808367]]],[[[-79.914412,39.808367],[-79.91041,39.80415],[-79.898114,39.808774],[-79.914412,39.808367]]]]},"properties":{"id":"15460","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.208413,41.355954],[-79.208878,41.331861],[-79.138479,41.342176],[-79.208413,41.355954]]]},"properties":{"id":"16217","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.688768,39.855424],[-77.698713,39.865193],[-77.708963,39.855402],[-77.688768,39.855424]]]},"properties":{"id":"17235","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.461536,39.774268],[-77.48166,39.720009],[-77.45943,39.719987],[-77.461536,39.774268]]]},"properties":{"id":"17214","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519495,39.744359],[-80.26446,39.721074],[-80.245737,39.723211],[-80.27185,39.757367],[-80.402158,39.812779],[-80.433549,39.814368],[-80.519495,39.744359]]]},"properties":{"id":"15352","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.308827,40.00694],[-80.228876,39.980523],[-80.1887,40.002782],[-80.25457,40.07542],[-80.340805,40.092235],[-80.356974,40.032863],[-80.348141,40.001036],[-80.308827,40.00694]]]},"properties":{"id":"15329","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.068691,40.56073],[-78.144267,40.491321],[-78.147157,40.451931],[-78.124221,40.342363],[-78.123935,40.342536],[-78.019993,40.329186],[-78.003491,40.329095],[-77.931617,40.429904],[-77.841827,40.550035],[-77.817115,40.582892],[-77.698488,40.669815],[-77.697353,40.681507],[-78.068691,40.56073]]]},"properties":{"id":"16652","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.856985,40.141911],[-77.859843,40.091432],[-77.844598,40.08935],[-77.830436,40.112865],[-77.805177,40.153006],[-77.817272,40.159486],[-77.856985,40.141911]]]},"properties":{"id":"17239","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.322037,40.552531],[-79.30383,40.564694],[-79.300638,40.581969],[-79.339356,40.581085],[-79.367596,40.461474],[-79.283205,40.536356],[-79.285016,40.539905],[-79.322037,40.552531]]]},"properties":{"id":"15725","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.28422,40.543596],[-79.285016,40.539905],[-79.283205,40.536356],[-79.28422,40.543596]]]},"properties":{"id":"15752","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.048625,40.449224],[-78.995216,40.365926],[-78.95238,40.433198],[-78.983631,40.465646],[-79.008744,40.459732],[-79.048625,40.449224]]]},"properties":{"id":"15954","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.190077,40.788894],[-79.182017,40.790474],[-79.172208,40.796034],[-79.174735,40.798162],[-79.189842,40.789883],[-79.190077,40.788894]]]},"properties":{"id":"16246","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.359948,40.602361],[-79.341507,40.584877],[-79.360728,40.622172],[-79.359948,40.602361]]]},"properties":{"id":"15783","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.847435,41.014028],[-78.850444,41.00949],[-78.840784,41.006381],[-78.830155,41.014739],[-78.837326,41.022037],[-78.847435,41.014028]]]},"properties":{"id":"15863","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.983965,40.950691],[-78.975355,40.956174],[-78.980576,40.969324],[-78.994823,40.964845],[-78.983965,40.950691]]]},"properties":{"id":"15781","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.709567,41.520633],[-75.6684,41.530813],[-75.690699,41.539933],[-75.709567,41.520633]]]},"properties":{"id":"18471","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.662199,41.47592],[-75.686015,41.458358],[-75.67139,41.425116],[-75.66109,41.414257],[-75.636353,41.449725],[-75.636311,41.449764],[-75.619727,41.479391],[-75.662199,41.47592]]]},"properties":{"id":"18508","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.638833,41.41848],[-75.659733,41.411489],[-75.659906,41.404525],[-75.586075,41.39666],[-75.583884,41.396221],[-75.638833,41.41848]]]},"properties":{"id":"18510","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.678562,41.393951],[-75.707236,41.383196],[-75.650887,41.36748],[-75.586075,41.39666],[-75.659906,41.404525],[-75.672988,41.41094],[-75.678562,41.393951]]]},"properties":{"id":"18505","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.760518,41.360942],[-75.742043,41.348684],[-75.722325,41.371638],[-75.725119,41.413255],[-75.725238,41.41312],[-75.760518,41.360942]]]},"properties":{"id":"18518","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.707206,41.342608],[-75.683242,41.338942],[-75.650887,41.36748],[-75.707236,41.383196],[-75.722325,41.371638],[-75.742043,41.348684],[-75.740054,41.347918],[-75.707206,41.342608]]]},"properties":{"id":"18507","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.86045,41.51606],[-75.888599,41.41267],[-75.831079,41.415459],[-75.817249,41.448464],[-75.844949,41.493429],[-75.844502,41.500078],[-75.86352,41.503758],[-75.85843,41.51589],[-75.86045,41.51606]]]},"properties":{"id":"18615","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.272566,39.898495],[-76.359266,39.854843],[-76.312445,39.805425],[-76.236972,39.855087],[-76.249842,39.88697],[-76.272566,39.898495]]]},"properties":{"id":"17532","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.077307,40.184758],[-76.086955,40.177113],[-76.145624,40.108194],[-76.112777,40.079182],[-76.057574,40.05827],[-76.04658,40.053235],[-76.033921,40.051971],[-76.005192,40.040138],[-76.000767,40.085192],[-76.056744,40.155277],[-76.058142,40.158494],[-76.060174,40.17745],[-76.077307,40.184758]]]},"properties":{"id":"17557","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.609337,40.092936],[-76.714129,40.132113],[-76.72162,40.12007],[-76.707881,40.110626],[-76.644508,40.058069],[-76.63303,40.057369],[-76.609337,40.092936]]]},"properties":{"id":"17502","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.609337,40.092936],[-76.63303,40.057369],[-76.54004,40.051311],[-76.533874,40.050818],[-76.527989,40.064077],[-76.591064,40.098643],[-76.609337,40.092936]],[[-76.5831,40.070716],[-76.586781,40.080517],[-76.581936,40.080844],[-76.578703,40.073545],[-76.5831,40.070716]]]},"properties":{"id":"17547","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.41288,40.996523],[-80.483551,40.994611],[-80.487119,40.95466],[-80.436988,40.911553],[-80.39031,40.915889],[-80.379151,40.957895],[-80.41288,40.996523]]]},"properties":{"id":"16102","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.486949,40.337935],[-76.3548,40.364982],[-76.354613,40.437693],[-76.363308,40.444393],[-76.403324,40.432639],[-76.518556,40.381135],[-76.486949,40.337935]]]},"properties":{"id":"17046","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.317441,40.296877],[-76.272238,40.308686],[-76.316398,40.301671],[-76.317441,40.296877]]]},"properties":{"id":"17088","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.72783,40.600704],[-75.708872,40.586412],[-75.673651,40.624321],[-75.67016,40.636531],[-75.661928,40.64161],[-75.68383,40.685288],[-75.757807,40.735414],[-75.841437,40.694121],[-75.72783,40.600704]]]},"properties":{"id":"18066","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.544252,40.714577],[-75.52218,40.712876],[-75.527204,40.734068],[-75.555541,40.72092],[-75.544252,40.714577]]]},"properties":{"id":"18059","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.467383,40.597671],[-75.467638,40.604943],[-75.474156,40.600732],[-75.471466,40.597247],[-75.467383,40.597671]]]},"properties":{"id":"18101","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.586025,40.550304],[-75.607046,40.551025],[-75.606803,40.54158],[-75.586025,40.550304]]]},"properties":{"id":"18087","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.116785,40.959408],[-76.008057,40.999844],[-76.07875,41.014346],[-76.074808,41.003506],[-76.075824,41.003411],[-76.079653,41.010133],[-76.08073,41.015286],[-76.141015,41.022957],[-76.219461,41.00487],[-76.184632,40.974075],[-76.18191,40.958922],[-76.116785,40.959408]],[[-76.065047,40.997352],[-76.045369,40.988132],[-76.060297,40.983796],[-76.065047,40.997352]]]},"properties":{"id":"18249","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.870717,41.261036],[-75.835007,41.251355],[-75.809367,41.282048],[-75.868243,41.284339],[-75.870717,41.261036]]]},"properties":{"id":"18705","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.065553,41.201544],[-76.053817,41.153818],[-76.014268,41.161018],[-75.986907,41.166696],[-75.985563,41.213901],[-76.01274,41.228436],[-76.065553,41.201544]]]},"properties":{"id":"18634","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.116785,40.959408],[-76.18191,40.958922],[-76.188707,40.944737],[-76.162244,40.937822],[-76.13219,40.940313],[-76.116785,40.959408]]]},"properties":{"id":"18241","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.100619,41.521761],[-77.04576,41.638846],[-77.087803,41.638513],[-77.170916,41.63677],[-77.216407,41.635807],[-77.213375,41.545413],[-77.193981,41.489829],[-77.100619,41.521761]]]},"properties":{"id":"16930","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.110059,41.274431],[-76.945359,41.324536],[-76.945626,41.326267],[-76.947015,41.328402],[-76.9525,41.345519],[-77.193433,41.34201],[-77.157246,41.294453],[-77.110059,41.274431]]]},"properties":{"id":"17728","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.596326,41.241211],[-76.602961,41.239991],[-76.609629,41.232001],[-76.596326,41.241211]]]},"properties":{"id":"17742","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.813162,41.335186],[-76.827349,41.411808],[-76.9525,41.345519],[-76.947015,41.328402],[-76.945626,41.326267],[-76.945359,41.324536],[-76.969918,41.245196],[-76.893808,41.230516],[-76.813162,41.335186]]]},"properties":{"id":"17754","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.058088,41.085575],[-76.881685,41.090581],[-76.88961,41.100638],[-76.896114,41.13907],[-77.042125,41.167649],[-77.193519,41.096878],[-77.144111,41.06884],[-77.058088,41.085575]]]},"properties":{"id":"17810","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.533114,41.999924],[-78.535005,41.958916],[-78.510315,41.999856],[-78.533114,41.999924]]]},"properties":{"id":"16727","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.302717,41.999383],[-78.264055,41.926205],[-78.222959,41.893322],[-78.147939,41.861338],[-78.090534,41.87345],[-77.98997,41.97488],[-78.026668,41.99936],[-78.302717,41.999383]]]},"properties":{"id":"16748","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.221146,41.368517],[-80.344159,41.322528],[-80.328016,41.287596],[-80.206974,41.33157],[-80.17862,41.369635],[-80.221146,41.368517]]]},"properties":{"id":"16124","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.158689,41.312861],[-80.123996,41.226191],[-80.045011,41.249469],[-80.158689,41.312861]]]},"properties":{"id":"16133","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.999779,41.388372],[-80.05307,41.419684],[-80.09043,41.436514],[-80.125498,41.45071],[-80.164123,41.429394],[-80.158539,41.388652],[-79.99978,41.33348],[-79.999779,41.388372]]]},"properties":{"id":"16145","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.924982,41.513503],[-79.999789,41.463844],[-80.05307,41.419684],[-79.999779,41.388372],[-79.914849,41.397662],[-79.924982,41.513503]]]},"properties":{"id":"16362","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.560126,40.643294],[-77.582627,40.628414],[-77.583889,40.653959],[-77.704957,40.578875],[-77.676371,40.443689],[-77.551258,40.549523],[-77.468999,40.624746],[-77.512352,40.690273],[-77.522867,40.685977],[-77.572256,40.662624],[-77.565349,40.650224],[-77.560126,40.643294]],[[-77.646094,40.539869],[-77.625831,40.564622],[-77.6124,40.560715],[-77.646094,40.539869]]]},"properties":{"id":"17044","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.522867,40.685977],[-77.667819,40.735335],[-77.681334,40.729758],[-77.697353,40.681507],[-77.698488,40.669815],[-77.583533,40.654203],[-77.572256,40.662624],[-77.522867,40.685977]]]},"properties":{"id":"17084","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.411092,41.015545],[-75.410197,40.999248],[-75.363142,40.931056],[-75.362317,40.929178],[-75.355702,40.9334],[-75.348999,40.935482],[-75.332498,40.932236],[-75.302465,40.925619],[-75.294624,40.924345],[-75.294457,40.921849],[-75.29452,40.92075],[-75.294686,40.862295],[-75.290074,40.86393],[-75.234029,40.899035],[-75.16179,40.948395],[-75.13896,40.954394],[-75.140167,40.989605],[-75.276306,41.005916],[-75.314462,41.004751],[-75.356293,40.992901],[-75.363312,41.018104],[-75.318609,41.011753],[-75.303032,41.02372],[-75.376215,41.06323],[-75.395507,41.070825],[-75.416087,41.022092],[-75.411092,41.015545]]]},"properties":{"id":"18360","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.082677,41.141715],[-74.991718,41.092284],[-74.882311,41.180649],[-75.082677,41.141715]]]},"properties":{"id":"18324","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.276306,41.005916],[-75.140167,40.989605],[-75.131525,40.989263],[-75.043421,41.03049],[-75.097857,41.053672],[-75.100517,41.052943],[-75.219315,41.072377],[-75.282641,41.057083],[-75.275117,41.034731],[-75.276306,41.005916]],[[-75.11277,41.009663],[-75.108758,41.015775],[-75.105756,41.011155],[-75.108819,41.005283],[-75.11277,41.009663]]]},"properties":{"id":"18301","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.36595,41.137778],[-75.432953,41.167646],[-75.431122,41.103],[-75.397564,41.106106],[-75.383109,41.112663],[-75.36595,41.137778]]]},"properties":{"id":"18346","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.318609,41.011753],[-75.363312,41.018104],[-75.356293,40.992901],[-75.314462,41.004751],[-75.318609,41.011753]]]},"properties":{"id":"18352","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.462905,40.928366],[-75.465346,40.909213],[-75.464601,40.903396],[-75.442143,40.893997],[-75.417701,40.905775],[-75.421747,40.916812],[-75.462905,40.928366]]]},"properties":{"id":"18331","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.197833,40.106492],[-75.16859,40.127008],[-75.170256,40.126897],[-75.204271,40.120323],[-75.197833,40.106492]]]},"properties":{"id":"19075","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.146682,40.137413],[-75.103676,40.133395],[-75.100093,40.153291],[-75.127729,40.171641],[-75.143155,40.156923],[-75.146682,40.137413]]]},"properties":{"id":"19090","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.232347,40.131483],[-75.248337,40.174819],[-75.274651,40.183122],[-75.279318,40.185719],[-75.293475,40.193337],[-75.308832,40.177605],[-75.32483,40.158473],[-75.298153,40.143159],[-75.247666,40.122201],[-75.232347,40.131483]]]},"properties":{"id":"19422","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.57282,40.341646],[-75.578391,40.33531],[-75.576866,40.333565],[-75.56914,40.337885],[-75.571265,40.340296],[-75.57282,40.341646]]]},"properties":{"id":"19472","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.098543,40.070166],[-75.131731,40.089333],[-75.154076,40.071732],[-75.146683,40.067547],[-75.122147,40.053177],[-75.098543,40.070166]]]},"properties":{"id":"19027","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.189117,40.073124],[-75.221573,40.091755],[-75.22361,40.092907],[-75.232952,40.083618],[-75.214554,40.05046],[-75.189117,40.073124]]]},"properties":{"id":"19118","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.608796,40.967125],[-76.605954,40.966302],[-76.601421,40.967437],[-76.602052,40.969762],[-76.606187,40.969584],[-76.608796,40.967125]]]},"properties":{"id":"17822","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.36082,40.809622],[-75.392532,40.697688],[-75.375401,40.685567],[-75.310816,40.705899],[-75.275833,40.755003],[-75.271338,40.764584],[-75.22131,40.786041],[-75.22804,40.822122],[-75.288011,40.808132],[-75.36082,40.809622]]]},"properties":{"id":"18064","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.174682,40.775669],[-75.181942,40.784832],[-75.19195,40.777493],[-75.174682,40.775669]]]},"properties":{"id":"18063","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.257638,40.747692],[-75.275833,40.755003],[-75.310816,40.705899],[-75.282548,40.641047],[-75.282207,40.641052],[-75.233386,40.702752],[-75.246675,40.736044],[-75.257638,40.747692]]]},"properties":{"id":"18045","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.257638,40.747692],[-75.246675,40.736044],[-75.2571,40.747763],[-75.257638,40.747692]]]},"properties":{"id":"18085","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.718785,40.922966],[-76.792878,40.946583],[-76.864025,40.931942],[-76.832419,40.897684],[-76.798747,40.878052],[-76.718785,40.922966]]]},"properties":{"id":"17857","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.301994,40.454783],[-77.342602,40.452065],[-77.339775,40.447396],[-77.301994,40.454783]]],[[[-77.242041,40.41761],[-77.109773,40.443526],[-76.953046,40.490572],[-76.984389,40.514565],[-76.985424,40.526375],[-77.069827,40.524555],[-77.287246,40.455376],[-77.242041,40.41761]]]]},"properties":{"id":"17074","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.968945,40.452835],[-76.968818,40.456083],[-76.973443,40.453815],[-76.971244,40.451969],[-76.968945,40.452835]]]},"properties":{"id":"17069","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.506152,40.384571],[-77.444809,40.297539],[-77.316651,40.361057],[-77.408006,40.403902],[-77.515369,40.402942],[-77.506152,40.384571]]]},"properties":{"id":"17047","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.132544,39.983166],[-75.154938,39.986118],[-75.156275,39.986283],[-75.159442,39.971467],[-75.135133,39.969442],[-75.132544,39.983166]]]},"properties":{"id":"19122","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.139367,40.023459],[-75.134724,40.046134],[-75.150142,40.051578],[-75.154415,40.039493],[-75.154245,40.025634],[-75.139367,40.023459]]]},"properties":{"id":"19141","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.154415,40.039493],[-75.175804,40.052241],[-75.197674,40.034404],[-75.196321,40.023928],[-75.161627,40.019982],[-75.154245,40.025634],[-75.154415,40.039493]]]},"properties":{"id":"19144","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.24694,39.916871],[-75.219196,39.923409],[-75.236833,39.933768],[-75.247704,39.921561],[-75.24694,39.916871]]]},"properties":{"id":"19142","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.183793,41.429569],[-75.213509,41.387434],[-75.192436,41.359792],[-75.183793,41.429569]]]},"properties":{"id":"18464","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.769934,41.998489],[-78.026668,41.99936],[-77.98997,41.97488],[-77.79789,41.82887],[-77.72779,41.959132],[-77.74486,41.9783],[-77.769934,41.998489]]]},"properties":{"id":"16923","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.07404,40.827453],[-76.081389,40.825066],[-76.134464,40.810307],[-76.136334,40.807861],[-76.180636,40.780912],[-76.194364,40.76914],[-76.082774,40.771401],[-76.068558,40.776624],[-75.975009,40.811199],[-76.07404,40.827453]]]},"properties":{"id":"18214","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.043198,40.846843],[-76.07404,40.827453],[-75.975009,40.811199],[-76.035817,40.761618],[-76.066832,40.726195],[-76.077795,40.718278],[-76.12859,40.692753],[-76.128346,40.692253],[-76.125185,40.68576],[-76.068869,40.696574],[-75.877256,40.765731],[-75.848177,40.792412],[-75.858369,40.798753],[-75.891671,40.821083],[-75.934361,40.805188],[-75.936157,40.818812],[-75.906242,40.833736],[-75.951537,40.873306],[-75.957873,40.878667],[-76.007079,40.864381],[-76.043198,40.846843]]]},"properties":{"id":"18252","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.283571,40.784077],[-76.268883,40.762045],[-76.209926,40.760299],[-76.194364,40.76914],[-76.180636,40.780912],[-76.136334,40.807861],[-76.134464,40.810307],[-76.198361,40.800905],[-76.227673,40.796844],[-76.250431,40.791719],[-76.283571,40.784077]]]},"properties":{"id":"17931","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.110246,40.630273],[-76.204514,40.571519],[-76.189864,40.568029],[-76.155384,40.544955],[-76.11729,40.558544],[-76.038516,40.582323],[-76.02734,40.587168],[-76.110246,40.630273]]]},"properties":{"id":"17922","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.245158,40.601993],[-76.239671,40.601914],[-76.242498,40.60925],[-76.24702,40.602019],[-76.245158,40.601993]]]},"properties":{"id":"17933","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.039976,40.905041],[-76.098862,40.918682],[-76.134101,40.904621],[-76.188954,40.877472],[-76.098097,40.862379],[-76.029113,40.901762],[-76.028511,40.902077],[-76.039976,40.905041]]]},"properties":{"id":"18248","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.012643,40.866039],[-76.935268,40.854973],[-76.832419,40.897684],[-76.864025,40.931942],[-76.8773,40.954579],[-76.95634,40.900818],[-76.956209,40.88402],[-76.990478,40.875321],[-77.012643,40.866039]]]},"properties":{"id":"17889","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.041993,39.963023],[-79.030883,39.968306],[-79.040677,39.973607],[-79.041993,39.963023]]]},"properties":{"id":"15510","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.89948,40.214819],[-78.967716,40.140632],[-78.853045,40.138168],[-78.829415,40.156002],[-78.874463,40.206623],[-78.892002,40.207128],[-78.897248,40.206521],[-78.89948,40.214819]]]},"properties":{"id":"15936","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.26422,39.741885],[-79.284961,39.743312],[-79.287459,39.721976],[-79.242788,39.72198],[-79.26422,39.741885]]],[[[-79.291727,39.721975],[-79.399734,39.754904],[-79.392459,39.721578],[-79.291727,39.721975]]]]},"properties":{"id":"15411","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.278038,39.837973],[-79.204101,39.850126],[-79.279758,39.920747],[-79.35604,39.92491],[-79.278038,39.837973]]]},"properties":{"id":"15551","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.052086,40.133062],[-79.041497,40.147306],[-79.048205,40.146033],[-79.056645,40.144068],[-79.056995,40.143653],[-79.052086,40.133062]]]},"properties":{"id":"15546","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.14666,39.730439],[-79.124515,39.737289],[-79.123008,39.74898],[-79.148781,39.744496],[-79.14666,39.730439]]]},"properties":{"id":"15562","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.70944,41.78293],[-75.775495,41.813541],[-75.87081,41.71731],[-75.8705,41.71715],[-75.71173,41.68462],[-75.65056,41.70425],[-75.648355,41.720645],[-75.64077,41.76916],[-75.64852,41.80198],[-75.676072,41.728619],[-75.702474,41.781778],[-75.702597,41.781953],[-75.70944,41.78293]]]},"properties":{"id":"18826","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.0862,41.70769],[-76.122157,41.611102],[-76.058935,41.577848],[-75.941402,41.632354],[-75.973009,41.728638],[-76.0862,41.70769]]]},"properties":{"id":"18630","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.90814,41.74908],[-75.90168,41.74668],[-75.91127,41.75439],[-75.90814,41.74908]]],[[[-75.929021,41.755815],[-75.913245,41.753964],[-75.924602,41.760885],[-75.929021,41.755815]]]]},"properties":{"id":"18816","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.010278,41.681757],[-77.009817,41.662141],[-76.946098,41.672112],[-76.95275,41.698077],[-77.010278,41.681757]]],[[[-77.016443,41.655943],[-77.016911,41.697773],[-77.113289,41.696214],[-77.087803,41.638513],[-77.04576,41.638846],[-77.015906,41.645436],[-77.016443,41.655943]]]]},"properties":{"id":"16912","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.050831,41.961567],[-77.292243,41.926803],[-77.211956,41.876176],[-77.181625,41.85586],[-77.014761,41.911686],[-77.050831,41.961567]]]},"properties":{"id":"16946","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.733269,41.406875],[-79.732143,41.446512],[-79.795579,41.414681],[-79.733269,41.406875]]]},"properties":{"id":"16343","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.731434,41.40629],[-79.711293,41.349681],[-79.603604,41.355624],[-79.731434,41.40629]]]},"properties":{"id":"16346","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.369528,41.906687],[-79.315575,41.790786],[-79.286729,41.78782],[-79.275876,41.873493],[-79.276606,41.873886],[-79.283541,41.883355],[-79.30911,41.924817],[-79.369528,41.906687]]]},"properties":{"id":"16371","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.445565,41.99843],[-79.391831,41.952661],[-79.369528,41.906687],[-79.30911,41.924817],[-79.301636,41.930684],[-79.26048,41.954925],[-79.20662,41.999065],[-79.445565,41.99843]],[[-79.301636,41.930684],[-79.304855,41.931367],[-79.304159,41.934114],[-79.300904,41.931692],[-79.301636,41.930684]]]},"properties":{"id":"16350","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.561873,41.998634],[-79.542615,41.893554],[-79.492877,41.899709],[-79.527993,41.998555],[-79.561873,41.998634]]]},"properties":{"id":"16405","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.439183,41.818909],[-79.451905,41.923199],[-79.475884,41.904822],[-79.479135,41.901906],[-79.492877,41.899709],[-79.542615,41.893554],[-79.568283,41.846055],[-79.566082,41.845596],[-79.569822,41.791049],[-79.492488,41.813842],[-79.472383,41.844585],[-79.439183,41.818909]]]},"properties":{"id":"16436","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.301636,41.930684],[-79.300904,41.931692],[-79.304159,41.934114],[-79.304855,41.931367],[-79.301636,41.930684]]]},"properties":{"id":"16312","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.982442,40.015259],[-79.992638,40.009449],[-79.988232,40.011906],[-79.974622,40.01386],[-79.982442,40.015259]]]},"properties":{"id":"15368","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.221141,40.223062],[-80.236278,40.221071],[-80.224884,40.211813],[-80.214969,40.222701],[-80.221141,40.223062]]]},"properties":{"id":"15347","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.934395,40.044593],[-80.012368,40.098121],[-80.015118,40.098296],[-80.015299,40.098267],[-80.020994,40.072958],[-80.015772,40.061165],[-79.989337,40.056502],[-79.963051,40.042786],[-79.934395,40.044593]]]},"properties":{"id":"15427","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.35649,40.308594],[-80.355712,40.252043],[-80.268486,40.279478],[-80.26359,40.288691],[-80.352286,40.315074],[-80.35649,40.308594]],[[-80.273766,40.275476],[-80.277263,40.27866],[-80.276708,40.28135],[-80.271717,40.276187],[-80.273766,40.275476]]]},"properties":{"id":"15340","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.842887,40.083593],[-79.854542,40.080062],[-79.853816,40.078089],[-79.842887,40.083593]]]},"properties":{"id":"15483","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.417966,40.244108],[-80.428828,40.247444],[-80.426449,40.237836],[-80.417966,40.244108]]]},"properties":{"id":"15379","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.271237,41.797837],[-75.417379,41.822909],[-75.440254,41.814459],[-75.440087,41.720903],[-75.437207,41.719431],[-75.39522,41.691981],[-75.390725,41.689115],[-75.376062,41.674465],[-75.340442,41.677843],[-75.250935,41.762837],[-75.271237,41.797837]]]},"properties":{"id":"18453","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.392122,41.68909],[-75.387588,41.67061],[-75.376062,41.674465],[-75.390725,41.689115],[-75.392122,41.68909]]],[[[-75.392122,41.68909],[-75.39522,41.691981],[-75.437207,41.719431],[-75.46106,41.616407],[-75.456383,41.533638],[-75.431269,41.503395],[-75.429732,41.508377],[-75.332861,41.506595],[-75.337829,41.578332],[-75.348871,41.635169],[-75.348933,41.635317],[-75.35984,41.644048],[-75.361288,41.646252],[-75.363796,41.646781],[-75.382894,41.6612],[-75.387588,41.67061],[-75.392122,41.68909]]]]},"properties":{"id":"18472","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.299339,41.441362],[-75.261738,41.410568],[-75.200599,41.458443],[-75.299339,41.441362]]]},"properties":{"id":"18438","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.671487,40.401011],[-79.60482,40.40203],[-79.555806,40.427127],[-79.546729,40.450201],[-79.555659,40.465884],[-79.555705,40.485843],[-79.599181,40.488912],[-79.68947,40.399773],[-79.671487,40.401011]]]},"properties":{"id":"15632","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.406058,40.358145],[-79.357874,40.324894],[-79.351627,40.323204],[-79.332141,40.317164],[-79.31767,40.30944],[-79.222015,40.357548],[-79.217199,40.360768],[-79.24755,40.384893],[-79.305838,40.360503],[-79.314838,40.3396],[-79.345232,40.348334],[-79.335646,40.375589],[-79.351593,40.391774],[-79.406058,40.358145]]]},"properties":{"id":"15627","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.739836,40.12526],[-79.744404,40.128168],[-79.74418,40.127368],[-79.739836,40.12526]]]},"properties":{"id":"15448","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.656419,40.316285],[-79.648547,40.293419],[-79.643765,40.296356],[-79.656419,40.316285]]]},"properties":{"id":"15611","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.229019,40.097813],[-79.286171,40.165736],[-79.337563,40.190256],[-79.340436,40.189344],[-79.379344,40.115918],[-79.349953,40.098034],[-79.328468,40.095291],[-79.229019,40.097813]]]},"properties":{"id":"15687","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.499877,40.520081],[-79.498135,40.521433],[-79.498738,40.522108],[-79.500605,40.520587],[-79.499877,40.520081]]]},"properties":{"id":"15680","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.741942,40.360899],[-79.7232,40.36703],[-79.732726,40.37489],[-79.741942,40.360899]]]},"properties":{"id":"15615","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.72529,40.34132],[-79.72006,40.34318],[-79.72019,40.34997],[-79.729618,40.346013],[-79.72529,40.34132]]]},"properties":{"id":"15647","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.60907,40.37013],[-79.63316,40.37132],[-79.62177,40.35529],[-79.60907,40.37013]]]},"properties":{"id":"15623","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.380982,39.874492],[-76.498872,39.825462],[-76.505054,39.804215],[-76.475785,39.807515],[-76.411794,39.746448],[-76.306073,39.799122],[-76.312445,39.805425],[-76.359266,39.854843],[-76.380982,39.874492]]]},"properties":{"id":"17302","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.821658,40.130995],[-76.745884,40.146123],[-76.806121,40.200618],[-76.808205,40.20062],[-76.836965,40.178858],[-76.821658,40.130995]]]},"properties":{"id":"17319","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.821658,40.130995],[-76.840293,40.120802],[-76.797821,40.11012],[-76.778493,40.102514],[-76.709495,40.107814],[-76.707881,40.110626],[-76.72162,40.12007],[-76.745884,40.146123],[-76.821658,40.130995]]]},"properties":{"id":"17370","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.789282,39.901557],[-76.788855,39.897571],[-76.784053,39.898495],[-76.784825,39.903563],[-76.789282,39.901557]]]},"properties":{"id":"17371","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.461536,39.774268],[-77.45943,39.719987],[-77.29301,39.719904],[-77.370978,39.830214],[-77.441466,39.839319],[-77.462908,39.810111],[-77.461536,39.774268]]]},"properties":{"id":"17320","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.470967,39.944274],[-77.534704,39.953955],[-77.578904,39.86928],[-77.559851,39.850493],[-77.527943,39.850284],[-77.523409,39.859655],[-77.490689,39.88026],[-77.503664,39.855429],[-77.519927,39.850208],[-77.52789,39.827678],[-77.462908,39.810111],[-77.441466,39.839319],[-77.400474,39.952261],[-77.470967,39.944274]]]},"properties":{"id":"17222","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.32991,39.856542],[-77.318108,39.866913],[-77.33899,39.867445],[-77.32991,39.856542]]],[[[-77.375281,40.024742],[-77.470967,39.944274],[-77.400474,39.952261],[-77.338264,39.877507],[-77.322315,39.869924],[-77.184644,39.933264],[-77.315395,40.010223],[-77.375281,40.024742]]]]},"properties":{"id":"17307","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.33899,39.867445],[-77.318108,39.866913],[-77.322315,39.869924],[-77.338264,39.877507],[-77.33899,39.867445]]]},"properties":{"id":"17343","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.837621,40.314722],[-79.808422,40.280292],[-79.787853,40.297693],[-79.798003,40.29332],[-79.805057,40.303784],[-79.792302,40.308588],[-79.818398,40.3189],[-79.837621,40.314722]]]},"properties":{"id":"15135","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.277715,40.536943],[-80.424751,40.558597],[-80.424455,40.477605],[-80.367172,40.45501],[-80.360873,40.477539],[-80.295236,40.48928],[-80.281392,40.498627],[-80.277715,40.536943]]]},"properties":{"id":"15026","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.885786,40.462391],[-79.899877,40.444715],[-79.89899,40.436443],[-79.869512,40.414043],[-79.843941,40.410369],[-79.835485,40.419773],[-79.837541,40.428476],[-79.885786,40.462391]]]},"properties":{"id":"15221","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.050967,40.351082],[-80.068558,40.362159],[-80.095184,40.363368],[-80.116156,40.312324],[-80.094945,40.305907],[-80.089795,40.302947],[-80.088806,40.298134],[-80.087859,40.298121],[-80.07396,40.299837],[-80.050967,40.351082]]]},"properties":{"id":"15241","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.751053,40.608855],[-79.74117,40.600666],[-79.733169,40.603135],[-79.751053,40.608855]]]},"properties":{"id":"15014","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.159874,40.432655],[-80.131428,40.396118],[-80.105592,40.393184],[-80.090413,40.381981],[-80.071555,40.385451],[-80.0667,40.412476],[-80.057026,40.41794],[-80.066507,40.416706],[-80.159874,40.432655]]]},"properties":{"id":"15106","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.885786,40.462391],[-79.837541,40.428476],[-79.807406,40.425386],[-79.779423,40.460961],[-79.781223,40.490018],[-79.783127,40.49044],[-79.785317,40.491744],[-79.884165,40.481397],[-79.888123,40.461331],[-79.885786,40.462391]]]},"properties":{"id":"15235","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.818398,40.3189],[-79.792302,40.308588],[-79.796214,40.318319],[-79.816069,40.319611],[-79.818398,40.3189]]]},"properties":{"id":"15047","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.866671,40.198776],[-79.924513,40.243288],[-79.938683,40.209814],[-79.981492,40.207395],[-79.998215,40.194909],[-79.984921,40.165247],[-79.977478,40.164638],[-79.968988,40.166887],[-79.968495,40.157286],[-79.900346,40.162503],[-79.864153,40.161815],[-79.857705,40.193175],[-79.866671,40.198776]]]},"properties":{"id":"15063","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.811517,40.386687],[-79.807011,40.379364],[-79.798438,40.386451],[-79.802594,40.391419],[-79.811517,40.386687]]]},"properties":{"id":"15035","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.131428,40.396118],[-80.120495,40.372373],[-80.105592,40.393184],[-80.131428,40.396118]]]},"properties":{"id":"15142","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.918572,40.410757],[-79.908844,40.376692],[-79.876562,40.402272],[-79.890529,40.410548],[-79.911536,40.414896],[-79.918572,40.410757]]]},"properties":{"id":"15120","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.99098,40.497174],[-80.045179,40.493307],[-80.053179,40.48217],[-80.048394,40.477106],[-80.020731,40.456753],[-80.021326,40.458834],[-80.018306,40.459398],[-80.017475,40.455869],[-80.02709,40.446199],[-80.01629,40.443207],[-79.976016,40.463091],[-79.970767,40.470658],[-79.99098,40.497174]]]},"properties":{"id":"15212","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.99098,40.497174],[-79.970767,40.470658],[-79.951054,40.487655],[-79.959865,40.495196],[-79.9668,40.520052],[-79.988204,40.524112],[-79.99017,40.506216],[-79.99098,40.497174]]]},"properties":{"id":"15209","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.976016,40.463091],[-80.01629,40.443207],[-79.973025,40.455714],[-79.976016,40.463091]]]},"properties":{"id":"15222","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.886293,40.276917],[-79.905468,40.274788],[-79.904198,40.271885],[-79.897369,40.267161],[-79.886293,40.276917]]]},"properties":{"id":"15088","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17729,40.339742],[-80.140437,40.35289],[-80.163055,40.360165],[-80.17729,40.339742]]]},"properties":{"id":"15031","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.952502,40.440445],[-79.953545,40.443175],[-79.956438,40.441949],[-79.952502,40.440445]]]},"properties":{"id":"15260","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.274268,40.801079],[-79.291006,40.789024],[-79.273461,40.784777],[-79.261435,40.792462],[-79.274268,40.801079]]]},"properties":{"id":"16244","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.571795,40.693992],[-79.460583,40.627305],[-79.443651,40.624006],[-79.421834,40.652751],[-79.402635,40.699023],[-79.368541,40.716848],[-79.519541,40.785594],[-79.529054,40.779903],[-79.56517,40.751367],[-79.58734,40.7458],[-79.582014,40.707402],[-79.571795,40.693992]],[[-79.540021,40.760628],[-79.534864,40.763119],[-79.531451,40.761164],[-79.535999,40.758423],[-79.540021,40.760628]]]},"properties":{"id":"16226","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.523808,40.84617],[-79.364175,40.901735],[-79.451718,40.98167],[-79.551331,40.980456],[-79.532242,40.936679],[-79.523808,40.84617]]]},"properties":{"id":"16259","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.523271,40.512796],[-79.359948,40.602361],[-79.360728,40.622172],[-79.370453,40.628622],[-79.458111,40.588534],[-79.523271,40.512796]],[[-79.499877,40.520081],[-79.500605,40.520587],[-79.498738,40.522108],[-79.498135,40.521433],[-79.499877,40.520081]]]},"properties":{"id":"15618","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.518028,40.793835],[-79.521862,40.788512],[-79.519541,40.785594],[-79.518028,40.793835]]]},"properties":{"id":"16238","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.384074,40.969459],[-79.376572,40.966855],[-79.358873,40.970733],[-79.359995,40.980958],[-79.374249,40.968889],[-79.384074,40.969459]]]},"properties":{"id":"16223","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.254711,40.675978],[-80.225208,40.657657],[-80.148451,40.67429],[-80.149806,40.692933],[-80.189405,40.712811],[-80.266081,40.691772],[-80.254711,40.675978]]]},"properties":{"id":"15042","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.432527,40.618456],[-80.448114,40.477717],[-80.424455,40.477605],[-80.424751,40.558597],[-80.428603,40.616852],[-80.432527,40.618456]]]},"properties":{"id":"15050","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.518993,40.637447],[-80.445598,40.623648],[-80.444495,40.623769],[-80.460829,40.714943],[-80.48159,40.723089],[-80.518934,40.723332],[-80.518993,40.637447]]]},"properties":{"id":"15059","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519044,40.851339],[-80.518934,40.723332],[-80.48159,40.723089],[-80.357348,40.814116],[-80.368584,40.819184],[-80.448715,40.843032],[-80.519044,40.851339]]]},"properties":{"id":"16115","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.228579,40.573072],[-80.233982,40.582367],[-80.233263,40.569657],[-80.228579,40.573072]]]},"properties":{"id":"15081","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519044,40.851339],[-80.448715,40.843032],[-80.436988,40.911553],[-80.487119,40.95466],[-80.519009,40.921601],[-80.519044,40.851339]]]},"properties":{"id":"16120","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.125377,40.238267],[-78.258544,40.296831],[-78.30911,40.210896],[-78.355075,40.169126],[-78.255404,40.16239],[-78.223356,40.190763],[-78.201998,40.2043],[-78.13697,40.212806],[-78.125377,40.238267]]]},"properties":{"id":"16678","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.15101,40.175072],[-78.151112,40.135785],[-78.132932,40.164159],[-78.148137,40.177302],[-78.15101,40.175072]]]},"properties":{"id":"16694","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.399437,40.102296],[-78.366963,40.109794],[-78.370696,40.158841],[-78.379372,40.181386],[-78.395654,40.24012],[-78.4421,40.271574],[-78.447895,40.244395],[-78.466981,40.2072],[-78.487572,40.153981],[-78.399437,40.102296]]]},"properties":{"id":"16664","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.255404,40.16239],[-78.246001,40.140194],[-78.240443,40.151212],[-78.242196,40.158539],[-78.223356,40.190763],[-78.255404,40.16239]]]},"properties":{"id":"16672","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.039864,40.430612],[-76.089212,40.51098],[-76.09804,40.508394],[-76.111213,40.513674],[-76.11729,40.558544],[-76.155384,40.544955],[-76.203562,40.530527],[-76.177559,40.501064],[-76.180978,40.495551],[-76.181271,40.495325],[-76.178615,40.493503],[-76.188084,40.488187],[-76.238358,40.454879],[-76.232764,40.448048],[-76.171346,40.413341],[-76.052961,40.394192],[-76.038561,40.369692],[-76.030815,40.37843],[-76.039864,40.430612]]]},"properties":{"id":"19506","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.624996,40.346423],[-75.618532,40.337862],[-75.615089,40.342087],[-75.624996,40.346423]]]},"properties":{"id":"19545","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.541827,40.384603],[-75.466713,40.434649],[-75.458819,40.441907],[-75.463276,40.456312],[-75.516584,40.438828],[-75.552277,40.426026],[-75.57207,40.419773],[-75.563813,40.404684],[-75.541827,40.384603]]]},"properties":{"id":"18041","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.888626,40.429493],[-75.903119,40.445677],[-75.898988,40.425643],[-75.888626,40.429493]]]},"properties":{"id":"19510","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.76839,40.258865],[-75.765253,40.259746],[-75.76612,40.263404],[-75.768624,40.262473],[-75.767298,40.260988],[-75.76839,40.258865]]]},"properties":{"id":"19542","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.359434,40.271058],[-78.342711,40.264596],[-78.336365,40.280877],[-78.359434,40.271058]]]},"properties":{"id":"16631","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.910488,41.899728],[-76.686859,41.941377],[-76.638104,42.000738],[-76.868498,42.001872],[-76.910488,41.899728]]]},"properties":{"id":"16925","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.853849,41.543731],[-76.78823,41.517128],[-76.798073,41.544528],[-76.853849,41.543731]]],[[[-76.813731,41.590034],[-76.694374,41.579883],[-76.682046,41.606051],[-76.637821,41.700239],[-76.787238,41.718046],[-76.946098,41.672112],[-77.009817,41.662141],[-77.016443,41.655943],[-77.015906,41.645436],[-76.813731,41.590034]]]]},"properties":{"id":"17724","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.611791,41.774139],[-76.787238,41.718046],[-76.637821,41.700239],[-76.630015,41.745928],[-76.611791,41.774139]]]},"properties":{"id":"16926","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.135117,41.884586],[-76.058514,41.979669],[-76.090973,41.998779],[-76.145519,41.998842],[-76.135671,41.891812],[-76.135117,41.884586]]]},"properties":{"id":"18830","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.191228,40.585701],[-75.26049,40.530365],[-75.217449,40.468775],[-75.188491,40.471857],[-75.16998,40.524439],[-75.166635,40.564216],[-75.191228,40.585701]]]},"properties":{"id":"18930","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.785508,40.18213],[-74.808795,40.181253],[-74.807994,40.15164],[-74.80647,40.149625],[-74.785508,40.18213]]],[[[-74.785508,40.18213],[-74.846702,40.259002],[-74.889142,40.265371],[-74.887551,40.224735],[-74.83084,40.195414],[-74.818026,40.193249],[-74.797834,40.19491],[-74.785508,40.18213]]]]},"properties":{"id":"19067","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.31047,40.496813],[-75.302356,40.467243],[-75.323601,40.462565],[-75.318494,40.491594],[-75.458819,40.441907],[-75.466713,40.434649],[-75.419246,40.38465],[-75.363928,40.390191],[-75.308603,40.409464],[-75.217449,40.468775],[-75.26049,40.530365],[-75.293461,40.534073],[-75.31047,40.496813]],[[-75.393324,40.437495],[-75.400328,40.43696],[-75.398689,40.438926],[-75.393324,40.437495]],[[-75.387542,40.408487],[-75.385248,40.416133],[-75.370586,40.413667],[-75.376517,40.407926],[-75.387542,40.408487]]]},"properties":{"id":"18951","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.318494,40.491594],[-75.323601,40.462565],[-75.302356,40.467243],[-75.31047,40.496813],[-75.318494,40.491594]]]},"properties":{"id":"18955","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.099482,40.475743],[-75.068668,40.481139],[-75.10746,40.4822],[-75.099482,40.475743]]]},"properties":{"id":"18920","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.043258,40.375557],[-75.067667,40.390058],[-75.067594,40.369708],[-75.043258,40.375557]]]},"properties":{"id":"18913","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.290946,40.329423],[-75.369879,40.300319],[-75.349838,40.261055],[-75.338976,40.265311],[-75.28133,40.319635],[-75.290946,40.329423]]]},"properties":{"id":"18964","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.070641,40.457344],[-75.066286,40.423039],[-75.060694,40.421333],[-75.070641,40.457344]]]},"properties":{"id":"18950","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.049527,40.325519],[-75.063679,40.328168],[-75.051824,40.316195],[-75.049527,40.325519]]]},"properties":{"id":"18912","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.927553,40.816395],[-79.985893,40.74788],[-79.978645,40.744806],[-79.971173,40.744819],[-79.88587,40.748982],[-79.815595,40.773533],[-79.767122,40.842005],[-79.777575,40.89605],[-79.813357,40.911664],[-79.844262,40.886056],[-79.856351,40.8754],[-79.918856,40.841513],[-79.91955,40.841619],[-79.927553,40.816395]]]},"properties":{"id":"16002","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.02189,41.112214],[-80.008409,41.111167],[-79.99578,41.098996],[-79.980846,41.09916],[-79.867372,41.172759],[-79.868936,41.189656],[-79.884511,41.208719],[-79.954897,41.230986],[-79.956396,41.232361],[-79.984041,41.235252],[-80.029499,41.139738],[-80.02189,41.112214]]]},"properties":{"id":"16038","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.806067,40.747556],[-79.815595,40.773533],[-79.88587,40.748982],[-79.87713,40.672309],[-79.866027,40.672264],[-79.825406,40.671201],[-79.791935,40.748032],[-79.794891,40.747863],[-79.806067,40.747556]]]},"properties":{"id":"16056","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.653083,40.598438],[-78.615102,40.574341],[-78.606869,40.582686],[-78.602454,40.591452],[-78.52851,40.654775],[-78.531788,40.697317],[-78.633919,40.725159],[-78.688189,40.724182],[-78.691282,40.724205],[-78.68964,40.637216],[-78.698527,40.629859],[-78.653083,40.598438]]]},"properties":{"id":"16668","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.877835,40.346902],[-78.895747,40.335939],[-78.914553,40.314246],[-78.912265,40.28991],[-78.877835,40.346902]]]},"properties":{"id":"15902","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.789364,40.528134],[-78.752816,40.522527],[-78.778568,40.563973],[-78.799123,40.554284],[-78.789364,40.528134]]]},"properties":{"id":"15927","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.892485,40.340013],[-78.895747,40.335939],[-78.877835,40.346902],[-78.876197,40.353502],[-78.862823,40.367048],[-78.828094,40.448831],[-78.882592,40.447113],[-78.910135,40.435157],[-78.881197,40.39238],[-78.872399,40.392773],[-78.878249,40.387108],[-78.892485,40.340013]]]},"properties":{"id":"15909","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.78443,40.647233],[-78.768963,40.635107],[-78.759691,40.636175],[-78.755236,40.636245],[-78.751402,40.639522],[-78.74663,40.693597],[-78.769317,40.697939],[-78.792724,40.684588],[-78.80422,40.701978],[-78.851168,40.669902],[-78.85892,40.644283],[-78.875605,40.636445],[-78.902667,40.627442],[-78.901359,40.613372],[-78.920606,40.56994],[-78.866087,40.592926],[-78.808588,40.633006],[-78.78443,40.647233]]]},"properties":{"id":"15714","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.780544,40.34434],[-78.784139,40.338241],[-78.770715,40.320228],[-78.758032,40.319515],[-78.763483,40.333361],[-78.780544,40.34434]],[[-78.772015,40.336407],[-78.772699,40.337046],[-78.77334,40.337637],[-78.775054,40.340293],[-78.771943,40.338488],[-78.771486,40.336702],[-78.772015,40.336407]]]},"properties":{"id":"15951","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.74663,40.693597],[-78.751402,40.639522],[-78.748331,40.636349],[-78.68964,40.637216],[-78.691282,40.724205],[-78.741471,40.725518],[-78.74663,40.693597]]]},"properties":{"id":"16646","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.589623,40.725423],[-78.517827,40.715597],[-78.446106,40.754513],[-78.424241,40.784138],[-78.412111,40.785868],[-78.406952,40.806175],[-78.482919,40.803731],[-78.499545,40.790146],[-78.581015,40.755596],[-78.589623,40.725423]]]},"properties":{"id":"16627","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.719011,40.391131],[-78.71904,40.385525],[-78.706816,40.392394],[-78.717215,40.389424],[-78.718455,40.389005],[-78.719011,40.391131]]]},"properties":{"id":"15962","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.676073,40.833027],[-75.529181,40.882209],[-75.562344,40.926169],[-75.80877,40.839845],[-75.858369,40.798753],[-75.848177,40.792412],[-75.757807,40.735414],[-75.69938,40.76612],[-75.69609,40.768898],[-75.72545,40.778085],[-75.703661,40.787013],[-75.691183,40.771216],[-75.609068,40.787523],[-75.657523,40.793308],[-75.671009,40.805085],[-75.673491,40.815257],[-75.678217,40.821948],[-75.676073,40.833027]]]},"properties":{"id":"18235","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.523303,40.885554],[-75.534615,40.806778],[-75.443267,40.818276],[-75.378852,40.832527],[-75.442143,40.893997],[-75.464601,40.903396],[-75.487679,40.896021],[-75.494587,40.908036],[-75.465346,40.909213],[-75.462905,40.928366],[-75.492259,40.980629],[-75.567342,40.954654],[-75.562344,40.926169],[-75.529181,40.882209],[-75.523303,40.885554]]]},"properties":{"id":"18058","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.872454,40.796768],[-77.852617,40.801241],[-77.856234,40.814729],[-77.872454,40.796768]]]},"properties":{"id":"16802","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.752054,40.763068],[-77.702251,40.727741],[-77.622272,40.912596],[-77.625941,40.927997],[-77.751958,40.826827],[-77.780603,40.812338],[-77.756164,40.762458],[-77.752054,40.763068]]]},"properties":{"id":"16828","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.786773,40.943068],[-77.791556,40.94227],[-77.791493,40.937252],[-77.785317,40.933329],[-77.786773,40.943068]]]},"properties":{"id":"16853","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.481135,40.882774],[-77.51134,40.793856],[-77.407101,40.835194],[-77.457728,40.893487],[-77.481135,40.882774]]]},"properties":{"id":"16832","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.130442,39.782676],[-76.135484,39.721147],[-75.96215,39.721792],[-76.080086,39.802505],[-76.130442,39.782676]]]},"properties":{"id":"19362","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.987858,39.962302],[-75.991665,39.882798],[-75.930684,39.914175],[-75.972407,39.98751],[-75.987858,39.962302]]]},"properties":{"id":"19310","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.500632,39.965099],[-75.720835,39.939666],[-75.73226,39.930389],[-75.629023,39.887283],[-75.548375,39.88046],[-75.546771,39.881002],[-75.525513,39.921935],[-75.524606,39.922435],[-75.525431,39.933501],[-75.504542,39.957474],[-75.500632,39.965099]],[[-75.602842,39.949913],[-75.604653,39.95253],[-75.601264,39.954068],[-75.598418,39.950827],[-75.602842,39.949913]]]},"properties":{"id":"19382","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.531798,41.274104],[-79.472686,41.170208],[-79.367982,41.259053],[-79.42292,41.338916],[-79.437927,41.339094],[-79.445045,41.321847],[-79.445556,41.322516],[-79.551945,41.321078],[-79.551078,41.299137],[-79.531798,41.274104]],[[-79.445045,41.321847],[-79.439499,41.286819],[-79.449013,41.262465],[-79.471619,41.257017],[-79.467557,41.306386],[-79.445045,41.321847]]]},"properties":{"id":"16254","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.574521,41.282322],[-79.615895,41.290968],[-79.540757,41.163218],[-79.472686,41.170208],[-79.531798,41.274104],[-79.550543,41.283273],[-79.574521,41.282322]]]},"properties":{"id":"16232","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.223567,41.166541],[-79.20976,41.23708],[-79.230532,41.293189],[-79.271275,41.290198],[-79.290519,41.284201],[-79.27739,41.172498],[-79.223567,41.166541]]]},"properties":{"id":"16258","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.238227,41.431343],[-79.320159,41.432156],[-79.328491,41.395224],[-79.347923,41.374297],[-79.271275,41.290198],[-79.230532,41.293189],[-79.209262,41.303381],[-79.208879,41.331576],[-79.208878,41.331861],[-79.208413,41.355954],[-79.208226,41.367302],[-79.240898,41.369323],[-79.238227,41.431343]]]},"properties":{"id":"16233","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.408211,41.344739],[-79.455332,41.38648],[-79.438392,41.340384],[-79.437927,41.339094],[-79.42292,41.338916],[-79.408211,41.344739]]]},"properties":{"id":"16326","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.374891,40.9468],[-78.330605,40.913143],[-78.278539,40.956591],[-78.282882,40.957243],[-78.301583,40.977026],[-78.318542,40.983696],[-78.355748,40.988627],[-78.395359,40.991102],[-78.374891,40.9468]]]},"properties":{"id":"16878","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.122452,41.008656],[-78.109211,40.994052],[-78.096482,41.00132],[-78.122452,41.008656]]]},"properties":{"id":"16839","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.176186,40.986225],[-78.170937,40.959293],[-78.135649,40.966677],[-78.143036,40.982128],[-78.171349,40.986549],[-78.176186,40.986225]]]},"properties":{"id":"16879","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.903317,40.81594],[-78.87866,40.814617],[-78.887135,40.794374],[-78.90322,40.800748],[-78.94921,40.780669],[-78.903232,40.760989],[-78.882667,40.765607],[-78.856085,40.803443],[-78.844981,40.811166],[-78.838874,40.780507],[-78.806476,40.809315],[-78.787889,40.824333],[-78.839423,40.848475],[-78.925896,40.828862],[-78.903317,40.81594]]]},"properties":{"id":"15742","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.084503,40.968256],[-78.143036,40.982128],[-78.135649,40.966677],[-78.122957,40.94309],[-78.084503,40.968256]]]},"properties":{"id":"16849","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.988661,41.421159],[-77.898195,41.252752],[-77.664761,41.201585],[-77.640578,41.298273],[-77.727999,41.353182],[-77.826161,41.476994],[-77.988661,41.421159]]]},"properties":{"id":"17764","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.501187,41.232496],[-76.577913,41.192661],[-76.59329,41.149172],[-76.444665,41.10603],[-76.451539,41.167177],[-76.501187,41.232496]]]},"properties":{"id":"17846","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.305918,41.198152],[-76.271845,41.216263],[-76.259902,41.22112],[-76.255201,41.259228],[-76.2831,41.376517],[-76.276784,41.391568],[-76.34592,41.398293],[-76.461886,41.347151],[-76.43927,41.307253],[-76.447597,41.275629],[-76.501187,41.232496],[-76.451539,41.167177],[-76.385727,41.152251],[-76.305918,41.198152]]]},"properties":{"id":"17814","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.584815,40.879902],[-76.524797,40.822995],[-76.490634,40.825198],[-76.411487,40.83349],[-76.551792,40.883406],[-76.584815,40.879902]]]},"properties":{"id":"17824","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.770897,41.821842],[-79.910065,41.77394],[-79.911392,41.738776],[-79.851805,41.639106],[-79.642858,41.723521],[-79.770897,41.821842]]]},"properties":{"id":"16404","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.240893,41.801502],[-80.244307,41.752599],[-80.041,41.681206],[-80.02534,41.698028],[-80.08223,41.761205],[-80.182868,41.779532],[-80.221717,41.803111],[-80.235826,41.803248],[-80.240893,41.801502]]]},"properties":{"id":"16433","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.642858,41.723521],[-79.851805,41.639106],[-79.854718,41.614448],[-79.859512,41.584697],[-79.724672,41.527509],[-79.659357,41.533564],[-79.556811,41.62435],[-79.612773,41.736539],[-79.642858,41.723521]],[[-79.720798,41.648745],[-79.725641,41.651321],[-79.728754,41.65572],[-79.726907,41.656391],[-79.720798,41.648745]]]},"properties":{"id":"16354","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.78057,40.459573],[-77.027007,40.380892],[-76.933589,40.356045],[-76.932147,40.354596],[-76.92604,40.361423],[-76.749258,40.444198],[-76.78057,40.459573]]]},"properties":{"id":"17018","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.874575,40.264518],[-76.846001,40.272975],[-76.871392,40.280616],[-76.888665,40.288278],[-76.878736,40.266499],[-76.874575,40.264518]]]},"properties":{"id":"17103","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.736981,40.251248],[-76.796601,40.22599],[-76.796427,40.213363],[-76.778728,40.214615],[-76.786792,40.206091],[-76.800102,40.208214],[-76.806121,40.200618],[-76.745884,40.146123],[-76.72162,40.12007],[-76.714129,40.132113],[-76.671518,40.226344],[-76.736981,40.251248]]]},"properties":{"id":"17057","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.870068,40.529851],[-76.984389,40.514565],[-76.953046,40.490572],[-76.968945,40.452835],[-76.971244,40.451969],[-77.027007,40.380892],[-76.78057,40.459573],[-76.577073,40.580677],[-76.68659,40.560732],[-76.767815,40.552781],[-76.870068,40.529851]]]},"properties":{"id":"17032","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.300476,39.904611],[-75.302167,39.898041],[-75.289617,39.888737],[-75.285069,39.905804],[-75.28905,39.916992],[-75.300476,39.904611]]]},"properties":{"id":"19036","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.236833,39.933768],[-75.250658,39.948445],[-75.276984,39.953991],[-75.286027,39.93545],[-75.271517,39.926054],[-75.247704,39.921561],[-75.236833,39.933768]]]},"properties":{"id":"19050","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.247704,39.921561],[-75.271517,39.926054],[-75.28905,39.916992],[-75.285069,39.905804],[-75.281355,39.907475],[-75.2525,39.906614],[-75.24694,39.916871],[-75.247704,39.921561]]]},"properties":{"id":"19023","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.288956,39.992439],[-75.286802,40.018258],[-75.324353,39.993561],[-75.288956,39.992439]]]},"properties":{"id":"19003","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.253515,39.876015],[-75.277414,39.860027],[-75.216015,39.871468],[-75.253515,39.876015]]]},"properties":{"id":"19113","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.854512,41.617149],[-78.848424,41.610122],[-78.828229,41.621131],[-78.851914,41.622822],[-78.854512,41.617149]]]},"properties":{"id":"16734","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.053651,42.14156],[-80.040279,42.135173],[-79.977924,42.158255],[-79.917445,42.203562],[-79.91009,42.218251],[-80.058292,42.150071],[-80.053651,42.14156]]]},"properties":{"id":"16511","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.428142,41.936846],[-80.380061,41.923069],[-80.244724,41.935519],[-80.184198,41.95907],[-80.203975,41.986533],[-80.286821,42.02888],[-80.387962,41.988083],[-80.423405,41.961463],[-80.428142,41.936846]]]},"properties":{"id":"16417","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.652273,39.95368],[-79.6823,39.90306],[-79.63533,39.9023],[-79.652273,39.95368]]]},"properties":{"id":"15456","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.620342,39.864764],[-79.503527,39.803562],[-79.476667,39.831606],[-79.487258,39.869068],[-79.488405,39.926831],[-79.585019,39.887374],[-79.620342,39.864764]],[[-79.60123,39.84641],[-79.59573,39.84797],[-79.59039,39.84439],[-79.594647,39.841841],[-79.60123,39.84641]]]},"properties":{"id":"15470","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.72747,39.98998],[-79.76532,40.01925],[-79.801189,40.016715],[-79.811422,40.002196],[-79.82066,39.95574],[-79.72465,39.97027],[-79.72747,39.98998]],[[-79.78426,39.96163],[-79.787517,39.965272],[-79.779968,39.96643],[-79.777967,39.962511],[-79.78426,39.96163]]]},"properties":{"id":"15480","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.521812,39.785854],[-79.52441,39.73764],[-79.533155,39.720856],[-79.392459,39.721578],[-79.399734,39.754904],[-79.476667,39.831606],[-79.503527,39.803562],[-79.521812,39.785854]]]},"properties":{"id":"15459","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.801189,40.016715],[-79.81554,40.05909],[-79.866117,40.033909],[-79.87299,39.990778],[-79.871374,39.981866],[-79.879671,39.976798],[-79.87734,39.973048],[-79.87737,39.97295],[-79.87136,39.97076],[-79.866268,39.966461],[-79.86594,39.966302],[-79.811422,40.002196],[-79.801189,40.016715]]]},"properties":{"id":"15442","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.888057,40.043241],[-79.866117,40.033909],[-79.81554,40.05909],[-79.803381,40.109954],[-79.854928,40.117153],[-79.848901,40.112136],[-79.842887,40.083593],[-79.853816,40.078089],[-79.870856,40.075305],[-79.877945,40.078089],[-79.88422,40.068168],[-79.888057,40.043241]]]},"properties":{"id":"15438","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.926888,39.853164],[-79.921716,39.831324],[-79.914412,39.808367],[-79.898114,39.808774],[-79.854479,39.852226],[-79.859262,39.859736],[-79.88101,39.85564],[-79.891231,39.866433],[-79.891602,39.868934],[-79.918557,39.875584],[-79.928263,39.863409],[-79.926888,39.853164]],[[-79.92408,39.86849],[-79.92437,39.8692],[-79.9194,39.87156],[-79.91792,39.86889],[-79.92086,39.86968],[-79.92408,39.86849]]]},"properties":{"id":"15461","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.660314,40.045643],[-79.652105,40.033759],[-79.645961,40.039315],[-79.660314,40.045643]]]},"properties":{"id":"15430","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.208879,41.331576],[-79.209262,41.303381],[-79.130634,41.294986],[-79.093765,41.41649],[-79.138479,41.342176],[-79.208878,41.331861],[-79.208879,41.331576]]]},"properties":{"id":"15828","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.718456,39.984815],[-77.669123,39.958976],[-77.64097,40.014267],[-77.718456,39.984815]]]},"properties":{"id":"17201","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.849674,39.961354],[-77.754157,40.000934],[-77.65818,40.04666],[-77.656855,40.048205],[-77.687713,40.059244],[-77.753907,40.088498],[-77.828794,40.035077],[-77.873082,39.931071],[-77.849674,39.961354]]]},"properties":{"id":"17265","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.656855,40.048205],[-77.641288,40.04],[-77.676832,40.068499],[-77.687713,40.059244],[-77.656855,40.048205]]]},"properties":{"id":"17246","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.527943,39.850284],[-77.559851,39.850493],[-77.52789,39.827678],[-77.519927,39.850208],[-77.527943,39.850284]]]},"properties":{"id":"17237","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.799565,39.853342],[-77.795478,39.853142],[-77.795205,39.856756],[-77.797311,39.859274],[-77.799565,39.853342]]]},"properties":{"id":"17270","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.024691,40.029831],[-78.072127,40.052496],[-78.11702,40.029671],[-78.127187,40.01767],[-78.158784,39.96861],[-78.090257,39.918963],[-78.024691,40.029831]]]},"properties":{"id":"17228","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.127187,40.01767],[-78.269043,39.900166],[-78.290255,39.845713],[-78.182264,39.946363],[-78.158784,39.96861],[-78.127187,40.01767]]]},"properties":{"id":"15536","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439251,39.927688],[-80.415634,39.870584],[-80.358957,39.906316],[-80.350875,39.918756],[-80.306501,39.955234],[-80.307974,39.961307],[-80.308827,40.00694],[-80.348141,40.001036],[-80.439251,39.927688]]]},"properties":{"id":"15337","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.068747,39.885212],[-80.035321,39.814416],[-80.013391,39.811725],[-79.926888,39.853164],[-79.928263,39.863409],[-79.921022,39.889404],[-79.927312,39.926197],[-79.936297,39.935126],[-79.994298,39.931632],[-80.068747,39.885212]]]},"properties":{"id":"15320","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.950117,39.804578],[-79.954476,39.816286],[-79.971312,39.817676],[-79.9676,39.800415],[-79.950117,39.804578]]]},"properties":{"id":"15334","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.245737,39.723211],[-80.26446,39.721074],[-80.243326,39.721117],[-80.245737,39.723211]]]},"properties":{"id":"15316","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.204757,40.32702],[-78.196802,40.341178],[-78.207359,40.337057],[-78.214775,40.329617],[-78.204757,40.32702]]]},"properties":{"id":"16638","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.826762,40.249584],[-77.817272,40.159486],[-77.805177,40.153006],[-77.781443,40.186633],[-77.749971,40.224033],[-77.70303,40.263576],[-77.734588,40.338296],[-77.826762,40.249584]]]},"properties":{"id":"17213","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.998126,40.551767],[-78.929163,40.547367],[-78.920606,40.56994],[-78.901359,40.613372],[-78.934186,40.620448],[-79.036277,40.644331],[-78.998126,40.551767]]]},"properties":{"id":"15765","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.133205,40.418494],[-79.152641,40.404656],[-79.118159,40.371381],[-79.133205,40.418494]]]},"properties":{"id":"15949","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.373741,40.453853],[-79.351593,40.391774],[-79.335646,40.375589],[-79.305838,40.360503],[-79.24755,40.384893],[-79.192722,40.414862],[-79.152641,40.404656],[-79.133205,40.418494],[-79.088666,40.464594],[-79.075406,40.487184],[-79.173859,40.499089],[-79.179194,40.498957],[-79.28422,40.543596],[-79.283205,40.536356],[-79.367596,40.461474],[-79.373741,40.453853]],[[-79.18181,40.477551],[-79.184603,40.49277],[-79.170738,40.482649],[-79.18181,40.477551]],[[-79.196915,40.474691],[-79.18178,40.472295],[-79.186359,40.457246],[-79.196915,40.474691]]]},"properties":{"id":"15717","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.973803,40.682043],[-78.955042,40.69987],[-78.949838,40.709349],[-78.953209,40.7087],[-78.973803,40.682043]]]},"properties":{"id":"15777","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.295442,40.557553],[-79.28853,40.562139],[-79.300638,40.581969],[-79.30383,40.564694],[-79.295442,40.557553]]]},"properties":{"id":"15756","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.322037,40.552531],[-79.295442,40.557553],[-79.30383,40.564694],[-79.322037,40.552531]]]},"properties":{"id":"15727","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.041261,41.086027],[-79.024608,41.104466],[-79.04991,41.107736],[-79.041261,41.086027]]]},"properties":{"id":"15847","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.134295,41.021715],[-79.125664,41.011256],[-79.105893,41.023954],[-79.132665,41.02521],[-79.134295,41.021715]]]},"properties":{"id":"15776","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.103043,41.059059],[-79.084194,41.040149],[-79.09139,41.065353],[-79.103043,41.059059]]]},"properties":{"id":"15730","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.977651,41.000771],[-78.959042,40.995504],[-78.949403,41.023898],[-78.977651,41.000771]]]},"properties":{"id":"15711","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.959042,40.995504],[-78.96671,40.982458],[-78.95621,40.984493],[-78.959042,40.995504]]]},"properties":{"id":"15733","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.651573,41.635646],[-75.501526,41.531646],[-75.467679,41.533108],[-75.456383,41.533638],[-75.46106,41.616407],[-75.576761,41.641826],[-75.604694,41.647015],[-75.650713,41.642177],[-75.652187,41.642196],[-75.651573,41.635646]]]},"properties":{"id":"18407","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.709567,41.520633],[-75.817249,41.448464],[-75.831079,41.415459],[-75.831555,41.396906],[-75.822456,41.39944],[-75.816832,41.386707],[-75.795286,41.378127],[-75.760518,41.360942],[-75.725238,41.41312],[-75.686015,41.458358],[-75.662199,41.47592],[-75.659954,41.531515],[-75.6684,41.530813],[-75.709567,41.520633]]]},"properties":{"id":"18411","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.619727,41.479391],[-75.636311,41.449764],[-75.614216,41.46367],[-75.619727,41.479391]]]},"properties":{"id":"18519","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.561302,41.480116],[-75.587283,41.490694],[-75.59242,41.493959],[-75.592566,41.494051],[-75.583695,41.475638],[-75.561302,41.480116]]]},"properties":{"id":"18452","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.843124,41.588967],[-75.652187,41.642196],[-75.650713,41.642177],[-75.634025,41.672407],[-75.65056,41.70425],[-75.71173,41.68462],[-75.83759,41.67901],[-75.899643,41.658975],[-75.86432,41.63805],[-75.843124,41.588967]]]},"properties":{"id":"18446","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.238624,40.282053],[-76.247482,40.27061],[-76.239885,40.260407],[-76.077307,40.184758],[-76.060174,40.17745],[-76.025997,40.18703],[-76.01748,40.196772],[-76.015092,40.1983],[-76.017977,40.206609],[-76.04649,40.223203],[-76.062094,40.234592],[-76.058528,40.240568],[-76.160762,40.312167],[-76.201736,40.296437],[-76.231815,40.284691],[-76.238624,40.282053]]]},"properties":{"id":"17517","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.249943,40.343707],[-76.272238,40.308686],[-76.317441,40.296877],[-76.336461,40.251284],[-76.247482,40.27061],[-76.238624,40.282053],[-76.24876,40.290795],[-76.23293,40.29474],[-76.231815,40.284691],[-76.201736,40.296437],[-76.176567,40.336549],[-76.231916,40.366292],[-76.249943,40.343707]]]},"properties":{"id":"17073","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.295305,39.930644],[-76.387946,39.908654],[-76.380982,39.874492],[-76.359266,39.854843],[-76.272566,39.898495],[-76.269869,39.920865],[-76.295305,39.930644]]]},"properties":{"id":"17565","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.147654,39.974245],[-76.139821,39.964432],[-76.127712,40.002287],[-76.141407,40.008071],[-76.147002,40.064418],[-76.156344,40.062756],[-76.196546,40.031947],[-76.20832,40.002202],[-76.204292,39.994878],[-76.147654,39.974245]]]},"properties":{"id":"17572","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.5831,40.070716],[-76.578703,40.073545],[-76.581936,40.080844],[-76.586781,40.080517],[-76.5831,40.070716]]]},"properties":{"id":"17550","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.556962,40.214863],[-76.547531,40.210094],[-76.527229,40.218901],[-76.556962,40.214863]]]},"properties":{"id":"17041","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.536697,40.402508],[-76.535258,40.403512],[-76.536777,40.403101],[-76.536697,40.402508]]]},"properties":{"id":"17077","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.430127,40.643837],[-75.419638,40.616097],[-75.358334,40.6177],[-75.430127,40.643837]],[[-75.391666,40.633812],[-75.390425,40.630498],[-75.395097,40.630475],[-75.395544,40.633733],[-75.391666,40.633812]]]},"properties":{"id":"18018","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.68383,40.685288],[-75.650223,40.69347],[-75.69938,40.76612],[-75.757807,40.735414],[-75.68383,40.685288]]]},"properties":{"id":"18053","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.558617,40.549662],[-75.563315,40.547192],[-75.560065,40.547293],[-75.558617,40.549662]]]},"properties":{"id":"18046","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.626473,40.584515],[-75.625905,40.58348],[-75.623191,40.584032],[-75.623541,40.585042],[-75.626473,40.584515]]]},"properties":{"id":"18195","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.135405,41.216937],[-76.098684,41.179961],[-76.065553,41.201544],[-76.01274,41.228436],[-75.997078,41.249755],[-76.039257,41.270879],[-76.113329,41.309005],[-76.19013,41.278866],[-76.135405,41.216937]]]},"properties":{"id":"18621","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.92312,41.011453],[-75.928188,40.989822],[-75.926798,40.982364],[-75.893883,40.994421],[-75.855562,40.992635],[-75.842987,41.033476],[-75.842555,41.038601],[-75.914491,41.04277],[-75.92312,41.011453]]]},"properties":{"id":"18224","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.760518,41.360942],[-75.795286,41.378127],[-75.794438,41.340479],[-75.760143,41.331714],[-75.740054,41.347918],[-75.742043,41.348684],[-75.760518,41.360942]]]},"properties":{"id":"18642","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.906292,41.289434],[-75.895547,41.280773],[-75.885129,41.280285],[-75.898875,41.294529],[-75.906292,41.289434]]]},"properties":{"id":"18709","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.754187,41.174549],[-75.738426,41.194219],[-75.7591,41.194142],[-75.755086,41.174326],[-75.754187,41.174549]]]},"properties":{"id":"18602","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.954692,40.992238],[-75.976635,40.987487],[-75.9749,40.987477],[-75.954692,40.992238]]]},"properties":{"id":"18234","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.464894,41.404814],[-77.465055,41.404712],[-77.45685,41.397395],[-77.414815,41.446348],[-77.409621,41.455701],[-77.516408,41.44294],[-77.464894,41.404814]],[[-77.464894,41.404814],[-77.464576,41.404528],[-77.464698,41.404637],[-77.464894,41.404814]]]},"properties":{"id":"17723","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.109882,41.224721],[-77.110059,41.274431],[-77.157246,41.294453],[-77.177489,41.194145],[-77.109882,41.224721]]]},"properties":{"id":"17744","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.712501,41.285943],[-76.721513,41.282309],[-76.714582,41.276111],[-76.703952,41.285575],[-76.712501,41.285943]]]},"properties":{"id":"17762","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.591052,41.842854],[-78.604294,41.877503],[-78.645315,41.873855],[-78.63852,41.840718],[-78.591052,41.842854]]]},"properties":{"id":"16732","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.890979,41.806217],[-78.769848,41.760312],[-78.591307,41.762349],[-78.575405,41.798007],[-78.63852,41.840718],[-78.645315,41.873855],[-78.890979,41.806217]]]},"properties":{"id":"16738","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.223693,41.151147],[-80.145447,41.112267],[-80.123996,41.226191],[-80.158689,41.312861],[-80.206974,41.33157],[-80.328016,41.287596],[-80.366452,41.249826],[-80.374407,41.207221],[-80.3732,41.197581],[-80.267754,41.185482],[-80.256781,41.147114],[-80.22586,41.157598],[-80.223693,41.151147]]]},"properties":{"id":"16137","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.51134,40.793856],[-77.667819,40.735335],[-77.522867,40.685977],[-77.512352,40.690273],[-77.32119,40.817187],[-77.356701,40.811365],[-77.36418,40.846937],[-77.407101,40.835194],[-77.51134,40.793856]]]},"properties":{"id":"17063","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.097857,41.053672],[-75.043421,41.03049],[-74.991718,41.092284],[-75.082677,41.141715],[-75.156507,41.150341],[-75.230503,41.126061],[-75.219315,41.072377],[-75.100517,41.052943],[-75.104538,41.065708],[-75.092666,41.068902],[-75.097857,41.053672]]]},"properties":{"id":"18302","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.462905,40.928366],[-75.421747,40.916812],[-75.434462,40.938042],[-75.410197,40.999248],[-75.411092,41.015545],[-75.416087,41.022092],[-75.437519,41.013244],[-75.481119,41.007574],[-75.492259,40.980629],[-75.462905,40.928366]]]},"properties":{"id":"18330","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.465346,40.909213],[-75.494587,40.908036],[-75.487679,40.896021],[-75.464601,40.903396],[-75.465346,40.909213]]]},"properties":{"id":"18333","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.103676,40.133395],[-75.146682,40.137413],[-75.156232,40.129927],[-75.131173,40.109693],[-75.096022,40.126429],[-75.103676,40.133395]]]},"properties":{"id":"19001","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.243171,40.274395],[-75.257269,40.282535],[-75.263297,40.286154],[-75.266746,40.259586],[-75.252887,40.265137],[-75.243171,40.274395]]]},"properties":{"id":"18915","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.252887,40.265137],[-75.266746,40.259586],[-75.338976,40.265311],[-75.349838,40.261055],[-75.372225,40.214061],[-75.347009,40.200543],[-75.308832,40.177605],[-75.293475,40.193337],[-75.243414,40.244971],[-75.23687,40.247812],[-75.214133,40.257422],[-75.243171,40.274395],[-75.252887,40.265137]]]},"properties":{"id":"19446","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.107033,40.04897],[-75.096733,40.068989],[-75.098543,40.070166],[-75.122147,40.053177],[-75.107033,40.04897]]]},"properties":{"id":"19012","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.274651,40.183122],[-75.248337,40.174819],[-75.254783,40.188572],[-75.274651,40.183122]]]},"properties":{"id":"19437","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.507157,40.145764],[-75.505624,40.132634],[-75.499535,40.132212],[-75.494935,40.149294],[-75.507157,40.145764]]]},"properties":{"id":"19453","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.790343,40.992616],[-76.788166,40.987172],[-76.783543,40.98889],[-76.785848,40.993376],[-76.790343,40.992616]]]},"properties":{"id":"17865","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.720369,40.781702],[-76.620922,40.780753],[-76.622797,40.784762],[-76.625721,40.79107],[-76.629278,40.798835],[-76.720369,40.781702]]]},"properties":{"id":"17881","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.87663,40.691545],[-76.80984,40.642047],[-76.794708,40.640836],[-76.701624,40.658082],[-76.69257,40.680643],[-76.831157,40.743058],[-76.856846,40.743697],[-76.87663,40.691545]]]},"properties":{"id":"17830","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.101572,40.038293],[-75.112821,40.027627],[-75.112773,40.027036],[-75.125988,40.013862],[-75.127287,40.007695],[-75.096184,40.000241],[-75.061339,40.012764],[-75.074944,40.026691],[-75.093532,40.035024],[-75.101572,40.038293]]]},"properties":{"id":"19124","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.093532,40.035024],[-75.074944,40.026691],[-75.040594,40.036859],[-75.046794,40.04117],[-75.066045,40.051181],[-75.093532,40.035024]]]},"properties":{"id":"19149","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.155169,39.943865],[-75.136248,39.940207],[-75.130404,39.958737],[-75.152046,39.958327],[-75.155169,39.943865]]]},"properties":{"id":"19106","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.165153,39.945557],[-75.155169,39.943865],[-75.152046,39.958327],[-75.161959,39.959571],[-75.164174,39.950113],[-75.162965,39.949962],[-75.163137,39.949205],[-75.164338,39.949346],[-75.165153,39.945557]]]},"properties":{"id":"19107","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.214554,40.05046],[-75.197674,40.034404],[-75.175804,40.052241],[-75.166968,40.060413],[-75.188461,40.07275],[-75.189117,40.073124],[-75.214554,40.05046]]]},"properties":{"id":"19119","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.280266,39.974964],[-75.247688,39.962817],[-75.237502,39.967854],[-75.25,39.99018],[-75.251438,39.989504],[-75.280266,39.974964]]]},"properties":{"id":"19151","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.125419,41.736538],[-78.090534,41.87345],[-78.147939,41.861338],[-78.204317,41.736369],[-78.125419,41.736538]]]},"properties":{"id":"16746","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.682708,41.876907],[-77.690369,41.927021],[-77.6917,41.94564],[-77.72779,41.959132],[-77.79789,41.82887],[-77.804037,41.72701],[-77.71036,41.7749],[-77.682708,41.876907]]]},"properties":{"id":"16948","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.565686,40.635151],[-76.512458,40.642377],[-76.514338,40.648898],[-76.558953,40.646199],[-76.578661,40.640394],[-76.567824,40.636136],[-76.565686,40.635151]]]},"properties":{"id":"17983","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.521884,40.591638],[-76.517207,40.589476],[-76.511532,40.591774],[-76.518892,40.594467],[-76.521884,40.591638]]]},"properties":{"id":"17957","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.265267,40.695515],[-76.271054,40.692439],[-76.262567,40.682957],[-76.25371,40.692301],[-76.265267,40.695515]]]},"properties":{"id":"17954","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.238859,40.695545],[-76.2363,40.693963],[-76.232832,40.69546],[-76.234557,40.698028],[-76.238859,40.695545]]]},"properties":{"id":"17974","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.906242,40.833736],[-75.936157,40.818812],[-75.934361,40.805188],[-75.891671,40.821083],[-75.893655,40.822806],[-75.905813,40.833363],[-75.906242,40.833736]]]},"properties":{"id":"18218","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.635832,40.63282],[-76.641566,40.621001],[-76.639697,40.619836],[-76.622898,40.609324],[-76.565686,40.635151],[-76.567824,40.636136],[-76.635832,40.63282]]]},"properties":{"id":"17978","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.071595,40.839757],[-76.081389,40.825066],[-76.07404,40.827453],[-76.043198,40.846843],[-76.069462,40.846497],[-76.071595,40.839757]]]},"properties":{"id":"18220","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.073246,40.744359],[-76.066832,40.726195],[-76.035817,40.761618],[-76.068558,40.776624],[-76.082774,40.771401],[-76.08242,40.770394],[-76.062764,40.750723],[-76.073246,40.744359]]]},"properties":{"id":"17952","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.057679,40.857385],[-77.04411,40.862916],[-77.05022,40.865741],[-77.057679,40.857385]]]},"properties":{"id":"17862","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.171918,39.793954],[-79.067752,39.768382],[-79.054391,39.769432],[-79.062261,39.753262],[-78.985151,39.722585],[-78.885721,39.722885],[-78.814882,39.81699],[-78.948497,39.848762],[-78.990395,39.867201],[-79.149823,39.852648],[-79.170706,39.807261],[-79.171918,39.793954]]]},"properties":{"id":"15552","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.067752,39.768382],[-79.171918,39.793954],[-79.215,39.752207],[-79.208429,39.722048],[-78.985151,39.722585],[-79.062261,39.753262],[-79.067752,39.768382]],[[-79.14666,39.730439],[-79.148781,39.744496],[-79.123008,39.74898],[-79.124515,39.737289],[-79.14666,39.730439]]]},"properties":{"id":"15558","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.348884,41.460155],[-76.276784,41.391568],[-76.2831,41.376517],[-76.206499,41.37862],[-76.220139,41.541285],[-76.276682,41.546298],[-76.554363,41.548294],[-76.508017,41.449842],[-76.418113,41.417453],[-76.384748,41.439786],[-76.348884,41.460155]]]},"properties":{"id":"18614","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.005514,41.998983],[-76.090973,41.998779],[-76.058514,41.979669],[-76.005514,41.998983]]],[[[-75.988842,41.999019],[-75.956755,41.916602],[-75.864791,41.958637],[-75.890619,41.998606],[-75.988842,41.999019]]]]},"properties":{"id":"18812","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.445292,41.82474],[-75.585453,41.778357],[-75.648355,41.720645],[-75.65056,41.70425],[-75.634025,41.672407],[-75.612861,41.663392],[-75.604147,41.657144],[-75.440087,41.720903],[-75.440254,41.814459],[-75.445292,41.82474]],[[-75.474101,41.768083],[-75.467344,41.72702],[-75.479189,41.735186],[-75.48039,41.76026],[-75.474101,41.768083]]]},"properties":{"id":"18470","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.604694,41.647015],[-75.576761,41.641826],[-75.604147,41.657144],[-75.612861,41.663392],[-75.604694,41.647015]]]},"properties":{"id":"18413","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.490756,41.688867],[-77.533617,41.771706],[-77.575567,41.80951],[-77.604471,41.758265],[-77.600321,41.588422],[-77.536987,41.65545],[-77.490756,41.688867]]]},"properties":{"id":"16921","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.366038,41.904456],[-77.38795,41.910171],[-77.42103,41.838828],[-77.211956,41.876176],[-77.292243,41.926803],[-77.317516,41.925967],[-77.351424,41.911171],[-77.366038,41.904456]]]},"properties":{"id":"16935","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.010278,41.681757],[-77.016911,41.697773],[-77.016443,41.655943],[-77.009817,41.662141],[-77.010278,41.681757]]]},"properties":{"id":"16939","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.118537,40.896169],[-77.12168,40.888036],[-77.052914,40.869329],[-77.012643,40.866039],[-76.990478,40.875321],[-76.95634,40.900818],[-76.981695,40.942423],[-77.058756,41.039145],[-77.058088,41.085575],[-77.144111,41.06884],[-77.14416,41.044338],[-77.221631,40.973282],[-77.24508,40.947403],[-77.118537,40.896169]]]},"properties":{"id":"17844","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.864544,41.019795],[-76.882053,41.024753],[-76.866122,41.013234],[-76.864544,41.019795]]]},"properties":{"id":"17886","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.95634,40.900818],[-76.990478,40.875321],[-76.956209,40.88402],[-76.95634,40.900818]]]},"properties":{"id":"17855","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.971583,41.252432],[-79.990864,41.250577],[-79.984041,41.235252],[-79.956396,41.232361],[-79.971583,41.252432]]],[[[-79.842234,41.323803],[-79.954897,41.230986],[-79.884511,41.208719],[-79.860883,41.18975],[-79.71432,41.319361],[-79.719969,41.345136],[-79.842234,41.323803]]]]},"properties":{"id":"16374","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.4828,41.696144],[-79.561065,41.767972],[-79.612773,41.736539],[-79.556811,41.62435],[-79.493454,41.624716],[-79.4828,41.696144]]]},"properties":{"id":"16420","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.057765,40.008485],[-80.010463,39.98472],[-79.992888,39.994333],[-79.992638,40.009449],[-79.982442,40.015259],[-79.963051,40.042786],[-79.989337,40.056502],[-80.020381,40.057831],[-80.024417,40.063352],[-80.055174,40.031534],[-80.057765,40.008485]]]},"properties":{"id":"15333","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.894761,40.070265],[-79.911661,40.046349],[-79.888057,40.043241],[-79.88422,40.068168],[-79.894761,40.070265]]]},"properties":{"id":"15419","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.992888,39.994333],[-80.010463,39.98472],[-80.010557,39.983342],[-79.998014,39.983322],[-79.992888,39.994333]]]},"properties":{"id":"15348","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.518861,40.195897],[-80.519056,40.008945],[-80.460709,40.026928],[-80.500119,40.190146],[-80.518861,40.195897]]]},"properties":{"id":"15376","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.87077,40.080211],[-79.870856,40.075305],[-79.853816,40.078089],[-79.854542,40.080062],[-79.87077,40.080211]]]},"properties":{"id":"15477","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.372727,40.351686],[-80.382011,40.363072],[-80.391771,40.363505],[-80.385601,40.348948],[-80.372727,40.351686]]]},"properties":{"id":"15078","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.360613,40.382113],[-80.360345,40.379268],[-80.354257,40.379383],[-80.360613,40.382113]]]},"properties":{"id":"15053","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.362886,41.869126],[-75.337458,41.890063],[-75.373539,41.902212],[-75.376078,41.880831],[-75.362886,41.869126]]]},"properties":{"id":"18455","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.337458,41.890063],[-75.277038,41.894578],[-75.272581,41.893168],[-75.361345,41.999445],[-75.391684,41.949711],[-75.395258,41.939364],[-75.373539,41.902212],[-75.337458,41.890063]]]},"properties":{"id":"18461","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.671089,40.106196],[-79.694369,40.184982],[-79.777587,40.17935],[-79.744404,40.128168],[-79.739836,40.12526],[-79.671089,40.106196]]]},"properties":{"id":"15479","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.644127,40.227907],[-79.614958,40.213782],[-79.615637,40.208717],[-79.58836,40.227535],[-79.591203,40.244259],[-79.617104,40.263668],[-79.657423,40.275135],[-79.663139,40.269364],[-79.671954,40.268802],[-79.677965,40.264668],[-79.666634,40.236519],[-79.644127,40.227907]]]},"properties":{"id":"15672","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.671954,40.268802],[-79.692003,40.26439],[-79.677965,40.264668],[-79.671954,40.268802]]]},"properties":{"id":"15625","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.476452,40.36212],[-79.460666,40.354977],[-79.406058,40.358145],[-79.351593,40.391774],[-79.373741,40.453853],[-79.500199,40.454107],[-79.516822,40.450009],[-79.476452,40.36212]]]},"properties":{"id":"15670","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.332141,40.317164],[-79.351627,40.323204],[-79.35359,40.318772],[-79.332141,40.317164]]]},"properties":{"id":"15620","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.697766,40.205183],[-79.69353,40.190458],[-79.692782,40.205047],[-79.697766,40.205183]]]},"properties":{"id":"15695","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.460666,40.354977],[-79.476452,40.36212],[-79.467372,40.3561],[-79.460666,40.354977]]]},"properties":{"id":"15624","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.482165,40.337086],[-79.479816,40.333677],[-79.474063,40.331355],[-79.472238,40.334409],[-79.482165,40.337086]]]},"properties":{"id":"15662","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.369237,40.281427],[-79.365026,40.27699],[-79.36079,40.281282],[-79.369237,40.281427]]]},"properties":{"id":"15696","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.692782,40.205047],[-79.685775,40.233601],[-79.697766,40.205183],[-79.692782,40.205047]]]},"properties":{"id":"15698","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.728574,40.22665],[-79.737637,40.216416],[-79.724103,40.217287],[-79.728574,40.22665]]]},"properties":{"id":"15640","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.48405,40.21409],[-79.48402,40.21367],[-79.48177,40.21376],[-79.48171,40.21419],[-79.48405,40.21409]]]},"properties":{"id":"15621","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.843952,41.553867],[-75.923639,41.643941],[-75.941402,41.632354],[-76.058935,41.577848],[-76.053847,41.467952],[-76.00105,41.41291],[-75.94285,41.42115],[-75.901405,41.409563],[-75.888599,41.41267],[-75.86045,41.51606],[-75.843952,41.553867]]]},"properties":{"id":"18657","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.475785,39.807515],[-76.491736,39.721304],[-76.396276,39.721246],[-76.411794,39.746448],[-76.475785,39.807515]]]},"properties":{"id":"17321","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.680132,39.907435],[-76.691349,39.857007],[-76.68832,39.844574],[-76.65589,39.854994],[-76.654684,39.930002],[-76.679141,39.907589],[-76.680132,39.907435]]]},"properties":{"id":"17313","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.049143,39.934449],[-77.018969,39.909372],[-76.965788,39.918266],[-76.923121,39.948768],[-76.953741,40.011325],[-77.000731,40.026991],[-77.042641,40.006982],[-77.096854,39.947314],[-77.049143,39.934449]]]},"properties":{"id":"17316","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.029035,40.384196],[-80.053642,40.385248],[-80.068558,40.362159],[-80.050967,40.351082],[-80.046257,40.352004],[-80.034277,40.373077],[-80.022866,40.380243],[-80.029035,40.384196]]]},"properties":{"id":"15228","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.050967,40.351082],[-80.07396,40.299837],[-80.038165,40.289194],[-80.009341,40.32112],[-80.01676,40.349605],[-80.046257,40.352004],[-80.050967,40.351082]]]},"properties":{"id":"15102","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.816989,40.215779],[-79.777587,40.17935],[-79.694369,40.184982],[-79.69353,40.190458],[-79.697766,40.205183],[-79.685775,40.233601],[-79.675118,40.240161],[-79.684183,40.249679],[-79.732272,40.254696],[-79.768288,40.245946],[-79.779812,40.229675],[-79.805015,40.241106],[-79.806057,40.241958],[-79.816989,40.215779]],[[-79.728574,40.22665],[-79.724103,40.217287],[-79.737637,40.216416],[-79.728574,40.22665]]]},"properties":{"id":"15089","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.017414,40.421966],[-80.029035,40.384196],[-80.022866,40.380243],[-79.998568,40.389791],[-80.000665,40.402068],[-80.010429,40.414729],[-80.017414,40.421966]]]},"properties":{"id":"15226","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.959865,40.495196],[-79.951054,40.487655],[-79.938355,40.490983],[-79.938219,40.519269],[-79.9668,40.520052],[-79.959865,40.495196]]]},"properties":{"id":"15223","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.020731,40.456753],[-80.048394,40.477106],[-80.051178,40.465599],[-80.037568,40.454049],[-80.029738,40.447858],[-80.02709,40.446199],[-80.017475,40.455869],[-80.020731,40.456753]]]},"properties":{"id":"15233","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.0667,40.412476],[-80.071555,40.385451],[-80.064301,40.393243],[-80.021096,40.425233],[-80.027954,40.443623],[-80.029738,40.447858],[-80.037568,40.454049],[-80.05103,40.44922],[-80.066507,40.416706],[-80.057026,40.41794],[-80.0667,40.412476]]]},"properties":{"id":"15220","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.82046,40.526271],[-79.783348,40.494836],[-79.7022,40.503331],[-79.666164,40.515363],[-79.647659,40.571642],[-79.686413,40.64385],[-79.688847,40.643842],[-79.733169,40.603135],[-79.74117,40.600666],[-79.764001,40.592735],[-79.77187,40.562967],[-79.794789,40.535785],[-79.821799,40.536257],[-79.82046,40.526271]]]},"properties":{"id":"15068","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.800242,40.294746],[-79.801926,40.303531],[-79.790901,40.308575],[-79.796214,40.318319],[-79.792302,40.308588],[-79.805057,40.303784],[-79.798003,40.29332],[-79.787853,40.297693],[-79.787608,40.299211],[-79.800242,40.294746]]]},"properties":{"id":"15028","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.794789,40.535785],[-79.77187,40.562967],[-79.775699,40.562828],[-79.79883,40.562275],[-79.798976,40.549119],[-79.794789,40.535785]]]},"properties":{"id":"15144","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.692476,40.668867],[-79.688847,40.643842],[-79.686413,40.64385],[-79.692476,40.668867]]],[[[-79.667588,40.757696],[-79.714286,40.669708],[-79.692587,40.669732],[-79.582014,40.707402],[-79.58734,40.7458],[-79.606002,40.754272],[-79.642369,40.767027],[-79.667588,40.757696]]]]},"properties":{"id":"16229","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.837067,41.028241],[-79.832765,40.995563],[-79.64889,40.98811],[-79.609757,41.009165],[-79.706229,41.042597],[-79.837067,41.028241]]]},"properties":{"id":"16041","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.529054,40.779903],[-79.519541,40.785594],[-79.521862,40.788512],[-79.518028,40.793835],[-79.529054,40.779903]]]},"properties":{"id":"16236","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.366963,40.109794],[-78.206706,40.086427],[-78.17704,40.109548],[-78.246001,40.140194],[-78.255404,40.16239],[-78.355075,40.169126],[-78.370595,40.158965],[-78.370696,40.158841],[-78.366963,40.109794]]]},"properties":{"id":"16650","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.005824,40.347441],[-75.990512,40.368356],[-76.030815,40.37843],[-76.038561,40.369692],[-76.073005,40.297349],[-76.033501,40.253192],[-76.00256,40.295372],[-75.992658,40.312644],[-76.005824,40.347441]]]},"properties":{"id":"19608","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.804501,40.337006],[-75.802204,40.326558],[-75.76839,40.258865],[-75.767298,40.260988],[-75.795954,40.212023],[-75.743178,40.216721],[-75.696782,40.241863],[-75.682392,40.259493],[-75.728724,40.317453],[-75.733001,40.318473],[-75.739387,40.321632],[-75.732363,40.322484],[-75.739455,40.337858],[-75.804289,40.343707],[-75.805061,40.343414],[-75.801829,40.338529],[-75.804501,40.337006]]]},"properties":{"id":"19518","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.95592,40.318995],[-75.947692,40.340587],[-75.967655,40.362634],[-75.990512,40.368356],[-76.005824,40.347441],[-75.984351,40.309464],[-75.95592,40.318995]]]},"properties":{"id":"19610","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.177559,40.501064],[-76.203562,40.530527],[-76.24011,40.520529],[-76.440181,40.495952],[-76.365946,40.471648],[-76.36468,40.471936],[-76.358745,40.473272],[-76.289923,40.457107],[-76.261449,40.46766],[-76.238358,40.454879],[-76.188084,40.488187],[-76.181271,40.495325],[-76.180978,40.495551],[-76.177559,40.501064]]]},"properties":{"id":"19507","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.363308,40.444393],[-76.365946,40.471648],[-76.440181,40.495952],[-76.456836,40.506094],[-76.403324,40.432639],[-76.363308,40.444393]]]},"properties":{"id":"17026","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.427015,40.469922],[-78.441375,40.476264],[-78.542303,40.468851],[-78.556958,40.409136],[-78.563374,40.374794],[-78.537164,40.347154],[-78.424001,40.368917],[-78.403243,40.448769],[-78.421426,40.449778],[-78.426543,40.450856],[-78.424099,40.46215],[-78.426988,40.46492],[-78.427015,40.469922]],[[-78.434175,40.380489],[-78.440821,40.388257],[-78.431104,40.394144],[-78.429116,40.386974],[-78.434175,40.380489]]]},"properties":{"id":"16635","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.457181,40.258541],[-78.453714,40.269892],[-78.460407,40.27204],[-78.459962,40.260022],[-78.457181,40.258541]]]},"properties":{"id":"16682","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.156674,41.998761],[-76.38154,41.998481],[-76.380364,41.960418],[-76.360113,41.920667],[-76.362459,41.914529],[-76.391356,41.91453],[-76.411822,41.871917],[-76.392453,41.857935],[-76.311672,41.821462],[-76.24242,41.857842],[-76.178333,41.883222],[-76.156674,41.998761]]]},"properties":{"id":"18837","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.187155,41.813685],[-76.24242,41.857842],[-76.311672,41.821462],[-76.301478,41.730351],[-76.396178,41.654315],[-76.302948,41.595305],[-76.203942,41.673377],[-76.178047,41.758433],[-76.187155,41.813685]]]},"properties":{"id":"18853","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.902554,40.104119],[-74.869547,40.081539],[-74.80647,40.149625],[-74.807994,40.15164],[-74.875866,40.126801],[-74.883701,40.127941],[-74.889465,40.128775],[-74.904197,40.11355],[-74.902554,40.104119]]]},"properties":{"id":"19007","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.887551,40.224735],[-74.94441,40.211453],[-74.941784,40.20995],[-74.958996,40.180896],[-74.953788,40.145856],[-74.898451,40.132235],[-74.889465,40.128775],[-74.883701,40.127941],[-74.883817,40.128067],[-74.888232,40.132145],[-74.870788,40.171931],[-74.83084,40.195414],[-74.887551,40.224735]]]},"properties":{"id":"19047","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.148878,40.339896],[-75.09176,40.305708],[-75.056676,40.314002],[-75.051824,40.316195],[-75.063679,40.328168],[-75.049527,40.325519],[-75.043258,40.375557],[-75.067594,40.369708],[-75.067667,40.390058],[-75.091114,40.403349],[-75.167805,40.38439],[-75.181885,40.370534],[-75.186084,40.362274],[-75.181369,40.359386],[-75.161542,40.347525],[-75.151084,40.341221],[-75.148878,40.339896]]]},"properties":{"id":"18902","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.356488,40.545243],[-75.448838,40.512319],[-75.48024,40.495068],[-75.463276,40.456312],[-75.458819,40.441907],[-75.318494,40.491594],[-75.31047,40.496813],[-75.293461,40.534073],[-75.280092,40.557373],[-75.291714,40.555083],[-75.333514,40.537057],[-75.348824,40.546586],[-75.356488,40.545243]]]},"properties":{"id":"18036","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.383014,40.358049],[-75.290946,40.329423],[-75.28133,40.319635],[-75.250454,40.322714],[-75.269394,40.342769],[-75.276138,40.342826],[-75.308603,40.409464],[-75.363928,40.390191],[-75.383014,40.358049]]]},"properties":{"id":"18960","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.156098,40.417626],[-75.167805,40.38439],[-75.091114,40.403349],[-75.066286,40.423039],[-75.070641,40.457344],[-75.068143,40.462249],[-75.068668,40.481139],[-75.099482,40.475743],[-75.156098,40.417626]]]},"properties":{"id":"18947","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.27436,40.569288],[-75.244633,40.597262],[-75.282207,40.641052],[-75.282548,40.641047],[-75.332554,40.631009],[-75.333955,40.622174],[-75.348824,40.546586],[-75.333514,40.537057],[-75.291714,40.555083],[-75.27436,40.569288]]]},"properties":{"id":"18055","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.116154,40.265533],[-75.104984,40.239447],[-75.033911,40.255602],[-75.035422,40.260535],[-75.095368,40.27639],[-75.116154,40.265533]]]},"properties":{"id":"18929","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.691968,40.81336],[-79.691524,40.883527],[-79.777575,40.89605],[-79.767122,40.842005],[-79.691968,40.81336]]]},"properties":{"id":"16034","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.837067,41.028241],[-79.706229,41.042597],[-79.72584,41.054633],[-79.734919,41.045371],[-79.743955,41.053345],[-79.803086,41.090473],[-79.821547,41.060829],[-79.823917,41.046099],[-79.837067,41.028241]]]},"properties":{"id":"16050","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.947862,41.005456],[-79.84632,40.936215],[-79.832765,40.995563],[-79.837067,41.028241],[-79.823917,41.046099],[-79.828942,41.059611],[-79.893035,41.089675],[-79.938861,41.052333],[-79.947862,41.005456]]]},"properties":{"id":"16061","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.029255,40.821447],[-80.028835,40.815776],[-80.010268,40.816467],[-80.011182,40.824043],[-80.029255,40.821447]]]},"properties":{"id":"16027","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.874463,40.206623],[-78.829415,40.156002],[-78.721151,40.111858],[-78.698671,40.16145],[-78.647953,40.270621],[-78.649517,40.275042],[-78.711773,40.295535],[-78.725599,40.294946],[-78.747068,40.302572],[-78.797772,40.291029],[-78.799349,40.282789],[-78.811265,40.271003],[-78.884862,40.244631],[-78.90465,40.227525],[-78.898514,40.212109],[-78.874463,40.206623]]]},"properties":{"id":"15963","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.699369,40.320739],[-78.709157,40.32266],[-78.697561,40.30984],[-78.693484,40.310532],[-78.699369,40.320739]]]},"properties":{"id":"15921","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.214005,41.228367],[-78.096349,41.411767],[-78.325216,41.404843],[-78.262729,41.355513],[-78.236817,41.230475],[-78.236831,41.230187],[-78.214005,41.228367]]]},"properties":{"id":"15832","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.671009,40.805085],[-75.661817,40.804305],[-75.657523,40.793308],[-75.609068,40.787523],[-75.608985,40.787387],[-75.551359,40.804552],[-75.534615,40.806778],[-75.523303,40.885554],[-75.529181,40.882209],[-75.676073,40.833027],[-75.678217,40.821948],[-75.673809,40.829891],[-75.667227,40.831471],[-75.667553,40.818337],[-75.673491,40.815257],[-75.671009,40.805085]]]},"properties":{"id":"18071","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.954692,40.992238],[-75.960325,40.985703],[-75.942295,40.986933],[-75.954692,40.992238]]],[[[-75.97172,40.976822],[-76.039976,40.905041],[-76.028511,40.902077],[-76.024516,40.903299],[-75.992025,40.908051],[-75.983192,40.90784],[-75.964623,40.924949],[-75.935529,40.935361],[-75.888499,40.952035],[-75.893883,40.994421],[-75.926798,40.982364],[-75.97172,40.976822]]]]},"properties":{"id":"18201","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.877567,40.842569],[-75.893655,40.822806],[-75.891671,40.821083],[-75.858369,40.798753],[-75.80877,40.839845],[-75.812004,40.845217],[-75.877567,40.842569]]]},"properties":{"id":"18250","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.199898,40.932861],[-78.201461,40.931692],[-78.199591,40.93057],[-78.199898,40.932861]]],[[[-78.264214,40.824763],[-78.30151,40.822937],[-78.374033,40.756652],[-78.359912,40.732592],[-78.211229,40.775364],[-78.04831,40.867788],[-77.965901,40.924988],[-78.035923,40.983194],[-78.0597,41.003571],[-78.081169,40.99332],[-78.084503,40.968256],[-78.122957,40.94309],[-78.192382,40.920568],[-78.207355,40.928058],[-78.278539,40.956591],[-78.330605,40.913143],[-78.264214,40.824763]],[[-78.264214,40.824763],[-78.221902,40.814228],[-78.261629,40.807017],[-78.264214,40.824763]]]]},"properties":{"id":"16866","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.214005,41.228367],[-78.236831,41.230187],[-78.096621,41.073383],[-78.059866,41.051911],[-77.98683,41.061735],[-77.834934,41.140025],[-77.862457,41.179325],[-77.898195,41.252752],[-77.959438,41.217497],[-77.973012,41.173021],[-78.071128,41.187651],[-78.093381,41.216931],[-78.214005,41.228367]]]},"properties":{"id":"16845","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.879191,40.727422],[-77.879645,40.736062],[-77.893103,40.732003],[-77.879191,40.727422]]]},"properties":{"id":"16868","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.456624,40.093854],[-75.578332,40.096986],[-75.618462,40.056072],[-75.584689,40.029602],[-75.496978,39.973256],[-75.460384,39.998597],[-75.475776,40.018369],[-75.487409,40.057856],[-75.474378,40.06271],[-75.456186,40.087776],[-75.456624,40.093854]],[[-75.567791,40.030898],[-75.559346,40.031692],[-75.558659,40.028085],[-75.570732,40.026395],[-75.567791,40.030898]]]},"properties":{"id":"19355","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.915339,39.924234],[-75.853072,39.889229],[-75.789627,39.885318],[-75.741809,39.906177],[-75.734542,39.904663],[-75.73226,39.930389],[-75.720835,39.939666],[-75.735142,39.95783],[-75.764416,39.992944],[-75.768402,39.995332],[-75.76993,39.996887],[-75.761336,40.005405],[-75.798733,40.046656],[-75.82053,40.053896],[-75.862165,40.058079],[-75.938429,40.02722],[-75.93487,40.006899],[-75.889579,39.965567],[-75.883325,39.966728],[-75.878584,39.96155],[-75.885892,39.959746],[-75.915339,39.924234]],[[-75.801089,39.962969],[-75.793167,39.962905],[-75.796969,39.956942],[-75.802679,39.960948],[-75.810584,39.961214],[-75.811558,39.964361],[-75.803775,39.966826],[-75.801089,39.962969]]]},"properties":{"id":"19320","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.6996,40.102993],[-75.798733,40.046656],[-75.761336,40.005405],[-75.764416,39.992944],[-75.735142,39.95783],[-75.638013,40.014984],[-75.680222,40.071019],[-75.6996,40.102993]]]},"properties":{"id":"19335","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.843064,39.781957],[-75.874879,39.722545],[-75.875102,39.72194],[-75.72501,39.786018],[-75.808746,39.793934],[-75.843064,39.781957]]]},"properties":{"id":"19350","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.889579,39.965567],[-75.885892,39.959746],[-75.878584,39.96155],[-75.883325,39.966728],[-75.889579,39.965567]]]},"properties":{"id":"19367","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.350416,41.374437],[-79.347923,41.374297],[-79.328491,41.395224],[-79.358589,41.376242],[-79.350416,41.374437]]]},"properties":{"id":"16361","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.537465,40.903654],[-78.465205,40.855087],[-78.43686,40.886172],[-78.403956,40.95943],[-78.48111,40.938717],[-78.48465,40.948112],[-78.501205,40.945116],[-78.537465,40.903654]]]},"properties":{"id":"16863","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.732426,40.785658],[-78.741471,40.725518],[-78.691282,40.724205],[-78.688189,40.724182],[-78.732426,40.785658]]]},"properties":{"id":"16692","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.804199,41.040156],[-78.804142,41.031924],[-78.777842,41.021044],[-78.769587,41.018725],[-78.697952,41.074493],[-78.614604,41.145009],[-78.654576,41.186193],[-78.688409,41.207327],[-78.741495,41.202335],[-78.790253,41.147449],[-78.804552,41.130956],[-78.804349,41.062642],[-78.804199,41.040156]]]},"properties":{"id":"15801","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.806476,40.809315],[-78.784552,40.806227],[-78.787889,40.824333],[-78.806476,40.809315]]]},"properties":{"id":"15721","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.301583,40.977026],[-78.282882,40.957243],[-78.295464,40.981931],[-78.30175,40.978855],[-78.301583,40.977026]]]},"properties":{"id":"16876","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.406952,40.806175],[-78.412111,40.785868],[-78.41024,40.78087],[-78.40399,40.808286],[-78.406952,40.806175]]]},"properties":{"id":"16671","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.471215,40.991338],[-78.457435,41.004582],[-78.467204,41.008397],[-78.47302,41.003175],[-78.465508,40.998581],[-78.471215,40.991338]]]},"properties":{"id":"16843","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.199591,40.93057],[-78.201461,40.931692],[-78.207355,40.928058],[-78.192382,40.920568],[-78.199591,40.93057]]]},"properties":{"id":"16840","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.344923,41.107398],[-77.533957,41.007013],[-77.535961,41.005753],[-77.514275,40.969562],[-77.14416,41.044338],[-77.144111,41.06884],[-77.193519,41.096878],[-77.302841,41.10598],[-77.344923,41.107398]]]},"properties":{"id":"17747","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.517922,41.442855],[-77.599151,41.525226],[-77.598129,41.478576],[-77.631197,41.307946],[-77.344923,41.107398],[-77.302841,41.10598],[-77.324086,41.175858],[-77.324295,41.182487],[-77.327309,41.186687],[-77.373949,41.194187],[-77.396517,41.210455],[-77.3718,41.21517],[-77.395839,41.277947],[-77.398411,41.343265],[-77.45685,41.397395],[-77.465055,41.404712],[-77.517922,41.442855]],[[-77.352218,41.154449],[-77.344057,41.15186],[-77.34467,41.14399],[-77.354747,41.146012],[-77.352218,41.154449]]]},"properties":{"id":"17745","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.813162,41.335186],[-76.893808,41.230516],[-76.894088,41.230356],[-76.880963,41.158044],[-76.793052,41.118386],[-76.634517,41.136462],[-76.617433,41.144597],[-76.59329,41.149172],[-76.577913,41.192661],[-76.596326,41.241211],[-76.609629,41.232001],[-76.742163,41.331084],[-76.813162,41.335186]]]},"properties":{"id":"17756","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.369896,41.068541],[-76.303474,41.037905],[-76.301433,41.03817],[-76.19838,41.062064],[-76.143774,41.138338],[-76.274553,41.124363],[-76.369896,41.068541]]]},"properties":{"id":"18603","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.319112,41.022595],[-76.301433,41.03817],[-76.303474,41.037905],[-76.319112,41.022595]]]},"properties":{"id":"18631","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.291759,41.665337],[-80.186638,41.548995],[-80.028412,41.58192],[-80.041,41.681206],[-80.244307,41.752599],[-80.301971,41.674026],[-80.303126,41.666473],[-80.291759,41.665337]]]},"properties":{"id":"16335","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519119,41.440733],[-80.380183,41.480679],[-80.416423,41.526651],[-80.420915,41.576308],[-80.519374,41.57847],[-80.519119,41.440733]]]},"properties":{"id":"16134","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.416423,41.526651],[-80.380183,41.480679],[-80.355409,41.484902],[-80.359231,41.505469],[-80.416423,41.526651]]]},"properties":{"id":"16110","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.96499,40.220929],[-77.077546,40.217804],[-77.085809,40.167869],[-77.055215,40.129061],[-77.009212,40.148917],[-77.007646,40.155187],[-76.986357,40.154892],[-76.933149,40.140305],[-76.911898,40.18488],[-76.925795,40.202751],[-76.96499,40.220929]]]},"properties":{"id":"17055","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.96499,40.220929],[-76.925795,40.202751],[-76.89377,40.234743],[-76.914977,40.271422],[-76.95,40.27739],[-76.96499,40.220929]]]},"properties":{"id":"17011","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.295815,40.204175],[-77.291234,40.193982],[-77.272682,40.198493],[-77.272911,40.206162],[-77.295815,40.204175]]]},"properties":{"id":"17081","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.846001,40.272975],[-76.874575,40.264518],[-76.88886,40.249019],[-76.878871,40.244],[-76.873248,40.237681],[-76.848907,40.243196],[-76.836867,40.267318],[-76.846001,40.272975]]]},"properties":{"id":"17104","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.888665,40.288278],[-76.914112,40.274466],[-76.913911,40.271938],[-76.904577,40.260572],[-76.885881,40.265418],[-76.880514,40.26635],[-76.878736,40.266499],[-76.888665,40.288278]]]},"properties":{"id":"17102","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.846001,40.272975],[-76.836867,40.267318],[-76.780643,40.298323],[-76.836737,40.307296],[-76.871392,40.280616],[-76.846001,40.272975]]]},"properties":{"id":"17109","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.767815,40.552781],[-76.79571,40.633975],[-76.810149,40.634914],[-76.879155,40.616737],[-76.870068,40.529851],[-76.767815,40.552781]],[[-76.812715,40.609634],[-76.802057,40.5989],[-76.816737,40.596071],[-76.812715,40.609634]]]},"properties":{"id":"17023","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.796427,40.213363],[-76.800102,40.208214],[-76.786792,40.206091],[-76.778728,40.214615],[-76.796427,40.213363]]]},"properties":{"id":"17034","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.617168,40.376412],[-76.66077,40.354777],[-76.649055,40.335935],[-76.639086,40.332155],[-76.581355,40.226349],[-76.556962,40.214863],[-76.527229,40.218901],[-76.478391,40.226495],[-76.516067,40.258414],[-76.617168,40.376412]],[[-76.58632,40.278537],[-76.58323,40.277599],[-76.585045,40.276322],[-76.58632,40.278537]]]},"properties":{"id":"17078","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.80984,40.642047],[-76.810149,40.634914],[-76.79571,40.633975],[-76.794708,40.640836],[-76.80984,40.642047]]]},"properties":{"id":"17080","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.577073,40.580677],[-76.78057,40.459573],[-76.749258,40.444198],[-76.67804,40.474717],[-76.535414,40.555157],[-76.465545,40.610351],[-76.499563,40.622847],[-76.609607,40.600932],[-76.577073,40.580677]],[[-76.521884,40.591638],[-76.518892,40.594467],[-76.511532,40.591774],[-76.517207,40.589476],[-76.521884,40.591638]]]},"properties":{"id":"17980","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.324353,39.993561],[-75.343204,39.989629],[-75.335132,39.954973],[-75.334361,39.954511],[-75.297496,39.962673],[-75.281295,39.974528],[-75.288956,39.992439],[-75.324353,39.993561]]]},"properties":{"id":"19083","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.443494,39.957245],[-75.477183,39.892772],[-75.451528,39.894845],[-75.419172,39.877165],[-75.386079,39.888085],[-75.359864,39.91277],[-75.364898,39.953513],[-75.396452,39.968971],[-75.443494,39.957245]]]},"properties":{"id":"19063","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.348827,39.86444],[-75.344545,39.850673],[-75.322055,39.859343],[-75.336489,39.875553],[-75.348827,39.86444]]]},"properties":{"id":"19022","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.281355,39.907475],[-75.277472,39.880699],[-75.264433,39.882167],[-75.2525,39.906614],[-75.281355,39.907475]]]},"properties":{"id":"19079","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.297496,39.962673],[-75.334361,39.954511],[-75.308225,39.940529],[-75.286027,39.93545],[-75.276984,39.953991],[-75.297496,39.962673]]]},"properties":{"id":"19026","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.324353,39.993561],[-75.286802,40.018258],[-75.284568,40.021787],[-75.293566,40.033181],[-75.344986,39.991885],[-75.343204,39.989629],[-75.324353,39.993561]]]},"properties":{"id":"19041","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.393279,39.822348],[-75.420447,39.835385],[-75.465732,39.86119],[-75.463409,39.824008],[-75.393279,39.822348]]]},"properties":{"id":"19061","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.288956,39.992439],[-75.281295,39.974528],[-75.280266,39.974964],[-75.251438,39.989504],[-75.262735,40.00063],[-75.2792,40.022586],[-75.284568,40.021787],[-75.286802,40.018258],[-75.288956,39.992439]]]},"properties":{"id":"19096","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.298319,39.876792],[-75.306709,39.875653],[-75.31347,39.866342],[-75.277414,39.860027],[-75.253515,39.876015],[-75.264433,39.882167],[-75.277472,39.880699],[-75.288386,39.880858],[-75.298319,39.876792]]]},"properties":{"id":"19029","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.755274,41.518423],[-78.641594,41.478017],[-78.642298,41.518118],[-78.754468,41.553963],[-78.755274,41.518423]]]},"properties":{"id":"15845","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.881384,41.45395],[-78.968845,41.316893],[-78.933802,41.307214],[-78.779508,41.282595],[-78.679155,41.339542],[-78.648271,41.397243],[-78.641594,41.478017],[-78.755274,41.518423],[-78.881384,41.45395]]]},"properties":{"id":"15853","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.325216,41.404843],[-78.420028,41.405046],[-78.420057,41.398014],[-78.420201,41.335654],[-78.262729,41.355513],[-78.325216,41.404843]]]},"properties":{"id":"15821","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.564251,41.762649],[-78.575405,41.798007],[-78.591307,41.762349],[-78.604134,41.739009],[-78.564251,41.762649]]],[[[-78.957926,41.54817],[-78.958989,41.452691],[-78.881384,41.45395],[-78.755274,41.518423],[-78.754468,41.553963],[-78.537526,41.68877],[-78.649178,41.688366],[-78.769848,41.760312],[-78.890979,41.806217],[-78.949961,41.79621],[-78.951632,41.751508],[-78.952022,41.735088],[-78.885048,41.685902],[-78.953962,41.687418],[-78.957418,41.579222],[-78.923713,41.581788],[-78.914977,41.547726],[-78.957926,41.54817]],[[-78.854512,41.617149],[-78.851914,41.622822],[-78.828229,41.621131],[-78.848424,41.610122],[-78.854512,41.617149]]]]},"properties":{"id":"16735","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.158636,42.035047],[-80.203975,41.986533],[-80.184198,41.95907],[-80.092017,41.942007],[-80.122171,42.007918],[-80.14257,42.040541],[-80.158636,42.035047]]]},"properties":{"id":"16426","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.380061,41.923069],[-80.266156,41.890543],[-80.244724,41.935519],[-80.380061,41.923069]]]},"properties":{"id":"16410","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.51155,40.06035],[-79.484294,39.977171],[-79.35975,39.96381],[-79.32524,39.999884],[-79.39489,40.01775],[-79.41912,40.0471],[-79.421281,40.047937],[-79.51155,40.06035]]]},"properties":{"id":"15469","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.64553,39.99606],[-79.512973,39.967722],[-79.484294,39.977171],[-79.51155,40.06035],[-79.503196,40.087512],[-79.52381,40.09302],[-79.56151,40.09075],[-79.58193,40.08526],[-79.588,40.08071],[-79.600846,40.081292],[-79.61294,40.08101],[-79.645961,40.039315],[-79.652105,40.033759],[-79.646731,40.008795],[-79.64553,39.99606]]]},"properties":{"id":"15425","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.77306,40.12299],[-79.751101,40.105066],[-79.747724,40.123746],[-79.77306,40.12299]]]},"properties":{"id":"15492","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.89386,39.90157],[-79.88238,39.93889],[-79.90568,39.954],[-79.930539,39.93898],[-79.93206,39.9326],[-79.927312,39.926197],[-79.921022,39.889404],[-79.918557,39.875584],[-79.891602,39.868934],[-79.89386,39.90157]]]},"properties":{"id":"15410","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.751101,40.105066],[-79.709338,40.063032],[-79.660314,40.045643],[-79.645961,40.039315],[-79.61294,40.08101],[-79.659357,40.097305],[-79.671089,40.106196],[-79.739836,40.12526],[-79.74418,40.127368],[-79.747724,40.123746],[-79.751101,40.105066]]]},"properties":{"id":"15428","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.790553,39.896219],[-79.782996,39.885544],[-79.774498,39.890125],[-79.77478,39.89932],[-79.790553,39.896219]]]},"properties":{"id":"15484","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.70058,39.97511],[-79.70389,39.96286],[-79.692131,39.958432],[-79.70058,39.97511]]]},"properties":{"id":"15489","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.403287,41.584264],[-79.404445,41.569963],[-79.386119,41.581655],[-79.403287,41.584264]]]},"properties":{"id":"16321","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.827142,39.872364],[-77.865539,39.722067],[-77.724073,39.720894],[-77.724113,39.721356],[-77.724443,39.725578],[-77.723067,39.727105],[-77.715085,39.734419],[-77.647165,39.810319],[-77.773618,39.87327],[-77.827142,39.872364]],[[-77.799565,39.853342],[-77.797311,39.859274],[-77.795205,39.856756],[-77.795478,39.853142],[-77.799565,39.853342]],[[-77.678723,39.779833],[-77.678092,39.783428],[-77.681455,39.784797],[-77.679918,39.787153],[-77.675024,39.78474],[-77.678723,39.779833]]]},"properties":{"id":"17225","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.903429,40.04191],[-77.964743,39.899626],[-77.874315,39.886054],[-77.873082,39.931071],[-77.828794,40.035077],[-77.878652,40.052841],[-77.903429,40.04191]]]},"properties":{"id":"17224","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.176154,39.722385],[-78.182264,39.946363],[-78.290255,39.845713],[-78.32458,39.789522],[-78.380599,39.722599],[-78.176154,39.722385]]]},"properties":{"id":"17267","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.964743,39.899626],[-77.903429,40.04191],[-77.925848,40.059331],[-77.988232,40.039471],[-78.024691,40.029831],[-78.090257,39.918963],[-78.077469,39.841228],[-77.977255,39.86827],[-77.964743,39.899626]]]},"properties":{"id":"17233","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.913746,40.21646],[-77.976404,40.282771],[-78.000906,40.206046],[-78.013585,40.221594],[-78.042566,40.2263],[-78.078353,40.133244],[-78.077247,40.133037],[-78.046842,40.126574],[-77.940203,40.142467],[-77.913746,40.21646]]]},"properties":{"id":"17264","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.988232,40.039471],[-77.925848,40.059331],[-77.916902,40.097974],[-77.925276,40.103782],[-77.963988,40.109608],[-77.988232,40.039471]]]},"properties":{"id":"17223","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.903429,40.04191],[-77.878652,40.052841],[-77.864489,40.061568],[-77.859843,40.091432],[-77.856985,40.141911],[-77.916902,40.097974],[-77.925848,40.059331],[-77.903429,40.04191]]]},"properties":{"id":"17215","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.358957,39.906316],[-80.332747,39.892234],[-80.350875,39.918756],[-80.358957,39.906316]]],[[[-80.098001,39.972099],[-80.162894,40.003858],[-80.1887,40.002782],[-80.228876,39.980523],[-80.271394,39.881543],[-80.281063,39.874711],[-80.325653,39.888837],[-80.258914,39.806274],[-80.170843,39.785269],[-80.035321,39.814416],[-80.068747,39.885212],[-80.082765,39.926962],[-80.085832,39.932853],[-80.07895,39.962943],[-80.098001,39.972099]]]]},"properties":{"id":"15370","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.27185,39.757367],[-80.245737,39.723211],[-80.243326,39.721117],[-80.174176,39.721151],[-80.170843,39.785269],[-80.258914,39.806274],[-80.27185,39.757367]]]},"properties":{"id":"15362","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.921022,39.889404],[-79.928263,39.863409],[-79.918557,39.875584],[-79.921022,39.889404]]]},"properties":{"id":"15351","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.844598,40.08935],[-77.859843,40.091432],[-77.864489,40.061568],[-77.863893,40.06147],[-77.844598,40.08935]]],[[[-77.826762,40.249584],[-77.925276,40.103782],[-77.916902,40.097974],[-77.856985,40.141911],[-77.817272,40.159486],[-77.826762,40.249584]]]]},"properties":{"id":"17255","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.91226,40.323723],[-77.807725,40.324479],[-77.76172,40.368733],[-77.834599,40.391228],[-77.838904,40.393071],[-77.894907,40.3989],[-77.888866,40.392178],[-77.952252,40.326877],[-77.91226,40.323723]]]},"properties":{"id":"17066","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.91226,40.323723],[-77.952252,40.326877],[-77.976404,40.282771],[-77.913746,40.21646],[-77.897051,40.24643],[-77.89667,40.246345],[-77.807725,40.324479],[-77.91226,40.323723]]]},"properties":{"id":"17260","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.124221,40.342363],[-78.147157,40.451931],[-78.162955,40.428703],[-78.124221,40.342363]]]},"properties":{"id":"16647","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.125377,40.238267],[-78.13697,40.212806],[-78.181148,40.192381],[-78.15101,40.175072],[-78.148137,40.177302],[-78.090142,40.21418],[-78.096653,40.221499],[-78.121146,40.286335],[-78.125377,40.238267]]]},"properties":{"id":"16621","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.279664,40.692609],[-79.260641,40.585282],[-79.256345,40.558461],[-79.152185,40.564012],[-79.140432,40.557671],[-78.998126,40.551767],[-79.036277,40.644331],[-79.060554,40.714801],[-79.079622,40.715031],[-79.158686,40.705722],[-79.171985,40.69066],[-79.180067,40.676484],[-79.169313,40.681039],[-79.157465,40.678823],[-79.156441,40.672436],[-79.180339,40.675923],[-79.279664,40.692609]]]},"properties":{"id":"15701","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.932966,40.738736],[-79.003953,40.735034],[-78.984578,40.731304],[-79.012237,40.720504],[-79.060554,40.714801],[-79.036277,40.644331],[-78.934186,40.620448],[-78.908403,40.628765],[-78.897995,40.636191],[-78.85892,40.644283],[-78.851168,40.669902],[-78.894719,40.672639],[-78.955042,40.69987],[-78.973803,40.682043],[-78.953209,40.7087],[-78.949838,40.709349],[-78.932966,40.738736]]]},"properties":{"id":"15728","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.877533,40.748205],[-78.903232,40.760989],[-78.94921,40.780669],[-79.08164,40.838796],[-79.131276,40.817469],[-79.079622,40.715031],[-79.060554,40.714801],[-79.012237,40.720504],[-79.003953,40.735034],[-78.932966,40.738736],[-78.867129,40.732176],[-78.877533,40.748205]]]},"properties":{"id":"15759","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.168917,40.702143],[-79.171985,40.69066],[-79.158686,40.705722],[-79.160552,40.711387],[-79.168917,40.702143]]]},"properties":{"id":"15723","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.18181,40.477551],[-79.170738,40.482649],[-79.184603,40.49277],[-79.18181,40.477551]]]},"properties":{"id":"15750","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.838874,40.780507],[-78.844981,40.811166],[-78.856085,40.803443],[-78.838874,40.780507]]]},"properties":{"id":"15712","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.821317,40.906048],[-78.971681,40.862948],[-78.925896,40.828862],[-78.839423,40.848475],[-78.821317,40.906048]]]},"properties":{"id":"15772","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.158686,40.705722],[-79.079622,40.715031],[-79.131276,40.817469],[-79.196783,40.828145],[-79.204894,40.804677],[-79.189842,40.789883],[-79.174735,40.798162],[-79.172208,40.796034],[-79.182017,40.790474],[-79.190077,40.788894],[-79.215015,40.784196],[-79.215235,40.776016],[-79.160552,40.711387],[-79.158686,40.705722]]]},"properties":{"id":"15747","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.619727,41.479391],[-75.614216,41.46367],[-75.543209,41.427616],[-75.535823,41.433912],[-75.583695,41.475638],[-75.592566,41.494051],[-75.588674,41.520707],[-75.632233,41.579744],[-75.659954,41.531515],[-75.662199,41.47592],[-75.619727,41.479391]]]},"properties":{"id":"18447","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.67139,41.425116],[-75.686015,41.458358],[-75.725238,41.41312],[-75.725119,41.413255],[-75.678562,41.393951],[-75.672988,41.41094],[-75.67139,41.425116]]]},"properties":{"id":"18504","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.707197,41.598323],[-75.701643,41.598917],[-75.713472,41.60915],[-75.715148,41.598152],[-75.707197,41.598323]]]},"properties":{"id":"18420","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.228683,39.798471],[-76.130442,39.782676],[-76.080086,39.802505],[-76.066845,39.807914],[-76.06799,39.890062],[-76.083331,39.937552],[-76.136785,39.944531],[-76.176929,39.917726],[-76.249842,39.88697],[-76.236972,39.855087],[-76.228683,39.798471]]]},"properties":{"id":"17566","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.991665,39.882798],[-75.987858,39.962302],[-76.006523,39.966724],[-76.023361,39.956454],[-76.083331,39.937552],[-76.06799,39.890062],[-76.020413,39.846609],[-76.004047,39.870592],[-75.991665,39.882798]]]},"properties":{"id":"17509","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.258122,40.229717],[-76.086955,40.177113],[-76.077307,40.184758],[-76.239885,40.260407],[-76.258122,40.229717]]]},"properties":{"id":"17578","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.337538,40.107173],[-76.248093,40.103698],[-76.248281,40.105677],[-76.231343,40.130301],[-76.258122,40.229717],[-76.239885,40.260407],[-76.247482,40.27061],[-76.336461,40.251284],[-76.429532,40.23899],[-76.34713,40.110307],[-76.337538,40.107173]],[[-76.302837,40.111963],[-76.305542,40.110517],[-76.30564,40.113823],[-76.302837,40.111963]]]},"properties":{"id":"17543","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.248093,40.103698],[-76.337538,40.107173],[-76.361213,40.097095],[-76.388598,40.092598],[-76.425997,40.078966],[-76.436936,40.06424],[-76.438533,40.051859],[-76.406451,40.04336],[-76.310866,40.055546],[-76.211258,40.061927],[-76.208333,40.066505],[-76.220839,40.088577],[-76.221789,40.088452],[-76.22777,40.08779],[-76.248093,40.103698]]]},"properties":{"id":"17601","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.112777,40.079182],[-76.147002,40.064418],[-76.141407,40.008071],[-76.073989,40.01599],[-76.033921,40.051971],[-76.04658,40.053235],[-76.057574,40.05827],[-76.112777,40.079182]]]},"properties":{"id":"17529","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.220839,40.088577],[-76.248281,40.105677],[-76.248093,40.103698],[-76.22777,40.08779],[-76.221789,40.088452],[-76.220839,40.088577]]],[[[-76.196546,40.031947],[-76.156344,40.062756],[-76.156906,40.063073],[-76.170256,40.061818],[-76.208333,40.066505],[-76.211258,40.061927],[-76.196002,40.039307],[-76.197602,40.034613],[-76.196546,40.031947]]]]},"properties":{"id":"17505","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.34713,40.110307],[-76.361213,40.097095],[-76.337538,40.107173],[-76.34713,40.110307]]]},"properties":{"id":"17520","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.425997,40.078966],[-76.388598,40.092598],[-76.433719,40.104021],[-76.425997,40.078966]]]},"properties":{"id":"17538","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.504967,41.097262],[-80.519093,41.097375],[-80.519026,41.090677],[-80.504967,41.097262]]]},"properties":{"id":"16140","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.667771,40.447888],[-76.535258,40.403512],[-76.536697,40.402508],[-76.518556,40.381135],[-76.403324,40.432639],[-76.456836,40.506094],[-76.535414,40.555157],[-76.67804,40.474717],[-76.667771,40.447888]]]},"properties":{"id":"17038","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.3548,40.364982],[-76.486949,40.337935],[-76.516067,40.258414],[-76.478391,40.226495],[-76.46738,40.233669],[-76.481416,40.239149],[-76.467283,40.256952],[-76.429532,40.23899],[-76.336461,40.251284],[-76.317441,40.296877],[-76.316398,40.301671],[-76.3548,40.364982]],[[-76.439027,40.278179],[-76.437078,40.277657],[-76.435156,40.278329],[-76.435976,40.275633],[-76.437687,40.276229],[-76.439027,40.278179]],[[-76.413743,40.272634],[-76.40255,40.284002],[-76.393346,40.275995],[-76.413743,40.272634]]]},"properties":{"id":"17042","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.467283,40.256952],[-76.481416,40.239149],[-76.46738,40.233669],[-76.467283,40.256952]]]},"properties":{"id":"17064","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.626473,40.584515],[-75.624336,40.579851],[-75.607046,40.551025],[-75.586025,40.550304],[-75.563315,40.547192],[-75.558617,40.549662],[-75.54145,40.554657],[-75.551515,40.56722],[-75.595884,40.59129],[-75.631332,40.597196],[-75.626473,40.584515]],[[-75.626473,40.584515],[-75.623541,40.585042],[-75.623191,40.584032],[-75.625905,40.58348],[-75.626473,40.584515]]]},"properties":{"id":"18106","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.673651,40.624321],[-75.708872,40.586412],[-75.70773,40.580071],[-75.624336,40.579851],[-75.626473,40.584515],[-75.631332,40.597196],[-75.673651,40.624321]]]},"properties":{"id":"18051","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.448329,40.5975],[-75.459749,40.624191],[-75.497969,40.62021],[-75.494888,40.602174],[-75.493839,40.60246],[-75.493496,40.600517],[-75.482433,40.591072],[-75.471466,40.597247],[-75.474156,40.600732],[-75.467638,40.604943],[-75.467383,40.597671],[-75.448329,40.5975]]]},"properties":{"id":"18102","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.494888,40.602174],[-75.493496,40.600517],[-75.493839,40.60246],[-75.494888,40.602174]]]},"properties":{"id":"18105","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.515628,40.702196],[-75.5295,40.65475],[-75.497969,40.62021],[-75.459749,40.624191],[-75.46389,40.641945],[-75.483628,40.666873],[-75.486137,40.672999],[-75.48922,40.675993],[-75.515628,40.702196]]]},"properties":{"id":"18052","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.849491,41.189033],[-75.928142,41.24097],[-75.985563,41.213901],[-75.986907,41.166696],[-75.849491,41.189033]]]},"properties":{"id":"18706","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.841278,41.297917],[-75.652922,41.258314],[-75.687244,41.339216],[-75.707206,41.342608],[-75.760143,41.331714],[-75.794438,41.340479],[-75.822139,41.30858],[-75.841278,41.297917]]]},"properties":{"id":"18640","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.065047,40.997352],[-76.060297,40.983796],[-76.045369,40.988132],[-76.065047,40.997352]]]},"properties":{"id":"18219","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.177489,41.194145],[-77.193519,41.096878],[-77.042125,41.167649],[-76.894088,41.230356],[-76.893808,41.230516],[-76.969918,41.245196],[-77.109882,41.224721],[-77.177489,41.194145]]]},"properties":{"id":"17702","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.575405,41.798007],[-78.564251,41.762649],[-78.551336,41.843758],[-78.591052,41.842854],[-78.63852,41.840718],[-78.575405,41.798007]]]},"properties":{"id":"16726","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.424065,41.80889],[-78.416952,41.823728],[-78.427839,41.819711],[-78.424065,41.80889]]]},"properties":{"id":"16730","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.264055,41.926205],[-78.364419,41.884178],[-78.338605,41.851268],[-78.222959,41.893322],[-78.264055,41.926205]]]},"properties":{"id":"16750","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.582627,40.628414],[-77.560126,40.643294],[-77.565349,40.650224],[-77.582627,40.628414]]]},"properties":{"id":"17009","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.834599,40.391228],[-77.827308,40.394412],[-77.830354,40.398413],[-77.838904,40.393071],[-77.834599,40.391228]]]},"properties":{"id":"17075","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.314382,41.219925],[-75.29591,41.242873],[-75.330896,41.240896],[-75.314382,41.219925]]],[[[-75.314382,41.219925],[-75.341217,41.135126],[-75.306507,41.106033],[-75.29563,41.090993],[-75.230503,41.126061],[-75.156507,41.150341],[-75.150591,41.172713],[-75.260762,41.159605],[-75.279488,41.172999],[-75.26062,41.160266],[-75.257236,41.188541],[-75.258458,41.189719],[-75.268912,41.187834],[-75.275806,41.182738],[-75.305257,41.201126],[-75.314382,41.219925]]]]},"properties":{"id":"18326","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.258458,41.189719],[-75.305257,41.201126],[-75.275806,41.182738],[-75.268912,41.187834],[-75.258458,41.189719]]]},"properties":{"id":"18323","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.36595,41.137778],[-75.341217,41.135126],[-75.314382,41.219925],[-75.330896,41.240896],[-75.359184,41.239206],[-75.372235,41.237959],[-75.462863,41.199387],[-75.432953,41.167646],[-75.36595,41.137778]]]},"properties":{"id":"18466","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.140167,40.989605],[-75.13896,40.954394],[-75.120506,40.968378],[-75.131525,40.989263],[-75.140167,40.989605]]]},"properties":{"id":"18327","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.346892,40.111097],[-75.326924,40.105214],[-75.298153,40.143159],[-75.32483,40.158473],[-75.346892,40.111097]]]},"properties":{"id":"19401","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.280317,40.092976],[-75.26324,40.055128],[-75.232952,40.083618],[-75.22361,40.092907],[-75.24075,40.108225],[-75.280317,40.092976]]]},"properties":{"id":"19444","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.53375,40.30645],[-75.539979,40.276424],[-75.500884,40.281639],[-75.488691,40.289098],[-75.466145,40.280199],[-75.454722,40.27814],[-75.472544,40.322448],[-75.518151,40.355848],[-75.540741,40.367955],[-75.571265,40.340296],[-75.56914,40.337885],[-75.566035,40.319421],[-75.53375,40.30645]]]},"properties":{"id":"18074","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.226779,40.207371],[-75.229022,40.21713],[-75.221769,40.224222],[-75.22665,40.228741],[-75.236858,40.213459],[-75.228353,40.20833],[-75.226779,40.207371]]]},"properties":{"id":"18936","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.32473,40.103055],[-75.341984,40.068692],[-75.339617,40.058947],[-75.306693,40.06512],[-75.264431,40.054094],[-75.26324,40.055128],[-75.280317,40.092976],[-75.324983,40.103268],[-75.32473,40.103055]]]},"properties":{"id":"19428","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.30103,40.058108],[-75.293566,40.033181],[-75.284568,40.021787],[-75.2792,40.022586],[-75.255098,40.046122],[-75.264431,40.054094],[-75.306693,40.06512],[-75.30103,40.058108]]]},"properties":{"id":"19035","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466145,40.280199],[-75.500884,40.281639],[-75.539979,40.276424],[-75.550629,40.266252],[-75.535359,40.252611],[-75.489976,40.224649],[-75.410711,40.223648],[-75.402951,40.22574],[-75.454722,40.27814],[-75.466145,40.280199]]]},"properties":{"id":"19473","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.143243,40.098345],[-75.171661,40.081848],[-75.154076,40.071732],[-75.131731,40.089333],[-75.143243,40.098345]]]},"properties":{"id":"19095","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.454196,40.133052],[-75.464796,40.138061],[-75.460149,40.129591],[-75.454196,40.133052]]]},"properties":{"id":"19456","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466145,40.280199],[-75.488691,40.289098],[-75.500884,40.281639],[-75.466145,40.280199]]]},"properties":{"id":"19492","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.723296,41.061447],[-76.857757,41.055625],[-76.864544,41.019795],[-76.866122,41.013234],[-76.8773,40.954579],[-76.864025,40.931942],[-76.792878,40.946583],[-76.723296,41.061447]],[[-76.790343,40.992616],[-76.785848,40.993376],[-76.783543,40.98889],[-76.788166,40.987172],[-76.790343,40.992616]],[[-76.85105,40.96548],[-76.863908,40.967753],[-76.858482,40.972528],[-76.85105,40.96548]]]},"properties":{"id":"17847","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.22804,40.822122],[-75.234029,40.899035],[-75.290074,40.86393],[-75.288011,40.808132],[-75.22804,40.822122]]]},"properties":{"id":"18072","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.097338,40.840294],[-75.16179,40.948395],[-75.234029,40.899035],[-75.22804,40.822122],[-75.22131,40.786041],[-75.19195,40.777493],[-75.181942,40.784832],[-75.174682,40.775669],[-75.174248,40.774859],[-75.097338,40.840294]]]},"properties":{"id":"18013","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.652581,40.867081],[-76.718785,40.922966],[-76.798747,40.878052],[-76.811489,40.851391],[-76.824039,40.836804],[-76.856979,40.746001],[-76.856846,40.743697],[-76.831157,40.743058],[-76.782151,40.767602],[-76.749814,40.776038],[-76.696437,40.812182],[-76.696523,40.820333],[-76.667892,40.82867],[-76.639758,40.834292],[-76.652581,40.867081]]]},"properties":{"id":"17801","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.45173,40.782756],[-76.418086,40.763737],[-76.410147,40.764874],[-76.380152,40.775511],[-76.390185,40.793254],[-76.392373,40.79755],[-76.400346,40.816528],[-76.411487,40.83349],[-76.490634,40.825198],[-76.488127,40.797132],[-76.467551,40.799971],[-76.470329,40.804991],[-76.459739,40.807696],[-76.458363,40.801317],[-76.45173,40.782756]]]},"properties":{"id":"17851","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.458363,40.801317],[-76.459739,40.807696],[-76.470329,40.804991],[-76.467551,40.799971],[-76.458363,40.801317]]]},"properties":{"id":"17832","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.593455,40.351205],[-77.586183,40.343418],[-77.580621,40.358556],[-77.593455,40.351205]]],[[[-77.65966,40.274536],[-77.670212,40.256087],[-77.595742,40.319542],[-77.65966,40.274536]]]]},"properties":{"id":"17071","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.044216,40.115747],[-75.020066,40.090171],[-75.015223,40.094626],[-74.97994,40.119742],[-74.980058,40.119883],[-75.015066,40.137992],[-75.044216,40.115747]]]},"properties":{"id":"19116","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.206968,40.011372],[-75.235298,39.99707],[-75.25,39.99018],[-75.237502,39.967854],[-75.212895,39.969382],[-75.193724,39.975285],[-75.193383,39.976648],[-75.192627,39.994771],[-75.19081,39.999899],[-75.206968,40.011372]]]},"properties":{"id":"19131","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.040594,40.036859],[-75.031914,40.015831],[-74.986573,40.038958],[-75.025427,40.065647],[-75.046794,40.04117],[-75.040594,40.036859]]]},"properties":{"id":"19136","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.21217,39.952874],[-75.205185,39.941116],[-75.183288,39.949512],[-75.179071,39.956122],[-75.193724,39.975285],[-75.212895,39.969382],[-75.21217,39.952874]]]},"properties":{"id":"19104","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.159442,39.971467],[-75.193383,39.976648],[-75.193724,39.975285],[-75.179071,39.956122],[-75.167233,39.958431],[-75.161959,39.959571],[-75.159442,39.971467]]]},"properties":{"id":"19130","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.249181,40.041951],[-75.206973,40.01477],[-75.196321,40.023928],[-75.197674,40.034404],[-75.214554,40.05046],[-75.232952,40.083618],[-75.26324,40.055128],[-75.264431,40.054094],[-75.255098,40.046122],[-75.249181,40.041951]]]},"properties":{"id":"19128","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.008259,41.349211],[-74.815223,41.440325],[-74.815827,41.44156],[-74.965966,41.476844],[-74.978467,41.433704],[-75.004928,41.368688],[-75.008259,41.349211]]]},"properties":{"id":"18458","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.049784,41.142527],[-75.021367,41.144732],[-75.045712,41.184021],[-75.061614,41.162349],[-75.049784,41.142527]]]},"properties":{"id":"18371","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.342005,41.253211],[-75.359184,41.239206],[-75.330896,41.240896],[-75.29591,41.242873],[-75.291018,41.254759],[-75.342005,41.253211]]]},"properties":{"id":"18460","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.690369,41.927021],[-77.682708,41.876907],[-77.575567,41.80951],[-77.533617,41.771706],[-77.42103,41.838828],[-77.38795,41.910171],[-77.503977,42.000068],[-77.65328,41.999445],[-77.690369,41.927021]]]},"properties":{"id":"16950","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.090534,41.87345],[-78.125419,41.736538],[-77.828518,41.651423],[-77.804037,41.72701],[-77.79789,41.82887],[-77.98997,41.97488],[-78.090534,41.87345]]]},"properties":{"id":"16915","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.14933,40.711097],[-76.209926,40.760299],[-76.268883,40.762045],[-76.393873,40.698454],[-76.396156,40.664411],[-76.323955,40.684881],[-76.282132,40.628931],[-76.246802,40.614439],[-76.19866,40.624656],[-76.201465,40.63173],[-76.18892,40.640527],[-76.18441,40.631414],[-76.128346,40.692253],[-76.12859,40.692753],[-76.14933,40.711097]],[[-76.238859,40.695545],[-76.234557,40.698028],[-76.232832,40.69546],[-76.2363,40.693963],[-76.238859,40.695545]],[[-76.279051,40.666534],[-76.278579,40.669945],[-76.283471,40.671523],[-76.278983,40.674668],[-76.275906,40.669464],[-76.279051,40.666534]],[[-76.249541,40.678412],[-76.243669,40.682211],[-76.238923,40.675892],[-76.243805,40.674933],[-76.249541,40.678412]],[[-76.265267,40.695515],[-76.25371,40.692301],[-76.262567,40.682957],[-76.271054,40.692439],[-76.265267,40.695515]],[[-76.168283,40.691315],[-76.178215,40.696919],[-76.160918,40.707643],[-76.155152,40.696267],[-76.168283,40.691315]],[[-76.212352,40.713184],[-76.187679,40.73249],[-76.176422,40.716677],[-76.212352,40.713184]]]},"properties":{"id":"17901","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.578661,40.640394],[-76.6797,40.644509],[-76.665469,40.635601],[-76.635832,40.63282],[-76.567824,40.636136],[-76.578661,40.640394]]]},"properties":{"id":"17968","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.382448,40.758437],[-76.401201,40.756308],[-76.400925,40.752016],[-76.389305,40.752279],[-76.382448,40.758437]]]},"properties":{"id":"17943","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.134101,40.904621],[-76.098862,40.918682],[-76.142344,40.913993],[-76.134101,40.904621]]]},"properties":{"id":"18242","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.962433,40.744992],[-76.927168,40.732928],[-76.856979,40.746001],[-76.824039,40.836804],[-76.839265,40.860663],[-76.815937,40.869208],[-76.811489,40.851391],[-76.798747,40.878052],[-76.832419,40.897684],[-76.935268,40.854973],[-76.9441,40.806793],[-76.904491,40.773307],[-76.962433,40.744992]]]},"properties":{"id":"17870","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.927168,40.732928],[-76.950172,40.67381],[-76.939749,40.638378],[-76.87663,40.691545],[-76.856846,40.743697],[-76.856979,40.746001],[-76.927168,40.732928]]]},"properties":{"id":"17864","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.811489,40.851391],[-76.815937,40.869208],[-76.839265,40.860663],[-76.824039,40.836804],[-76.811489,40.851391]]]},"properties":{"id":"17876","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.264232,40.065742],[-79.243757,40.005592],[-79.214039,40.035384],[-79.264232,40.065742]]]},"properties":{"id":"15502","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.895344,40.270027],[-78.941773,40.246267],[-78.90465,40.227525],[-78.884862,40.244631],[-78.895344,40.270027]]]},"properties":{"id":"15928","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.418113,41.417453],[-76.508017,41.449842],[-76.52755,41.453625],[-76.594682,41.397563],[-76.608058,41.368685],[-76.579381,41.362293],[-76.418113,41.417453]]]},"properties":{"id":"18626","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.973009,41.728638],[-75.941402,41.632354],[-75.923639,41.643941],[-75.899643,41.658975],[-75.83759,41.67901],[-75.8705,41.71715],[-75.87081,41.71731],[-75.89842,41.74494],[-75.89847,41.74634],[-75.90168,41.74668],[-75.90814,41.74908],[-75.91127,41.75439],[-75.913245,41.753964],[-75.929021,41.755815],[-75.973009,41.728638]]]},"properties":{"id":"18844","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.89847,41.74634],[-75.89842,41.74494],[-75.87081,41.71731],[-75.775495,41.813541],[-75.81832,41.88133],[-75.864791,41.958637],[-75.956755,41.916602],[-76.101246,41.83008],[-76.08669,41.753595],[-76.0862,41.70769],[-75.973009,41.728638],[-75.929021,41.755815],[-75.924602,41.760885],[-75.913245,41.753964],[-75.91127,41.75439],[-75.90168,41.74668],[-75.89847,41.74634]],[[-75.897482,41.804893],[-75.8872,41.804602],[-75.89454,41.78743],[-75.909975,41.79014],[-75.89698,41.79653],[-75.897482,41.804893]]]},"properties":{"id":"18801","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.897482,41.804893],[-75.89698,41.79653],[-75.909975,41.79014],[-75.89454,41.78743],[-75.8872,41.804602],[-75.897482,41.804893]]]},"properties":{"id":"18843","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.64077,41.76916],[-75.648355,41.720645],[-75.585453,41.778357],[-75.586332,41.789672],[-75.58772,41.78525],[-75.64077,41.76916]]]},"properties":{"id":"18842","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.366038,41.904456],[-77.351424,41.911171],[-77.439697,42.000071],[-77.503977,42.000068],[-77.38795,41.910171],[-77.366038,41.904456]]]},"properties":{"id":"16928","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.292243,41.926803],[-77.050831,41.961567],[-76.955525,42.001373],[-77.22887,41.999723],[-77.241195,41.972651],[-77.26874,41.961958],[-77.317516,41.925967],[-77.292243,41.926803]]]},"properties":{"id":"16929","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.439697,42.000071],[-77.351424,41.911171],[-77.317516,41.925967],[-77.26874,41.961958],[-77.330049,41.999847],[-77.439697,42.000071]]]},"properties":{"id":"16942","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.200398,40.88796],[-77.211369,40.88424],[-77.208341,40.874983],[-77.196424,40.877298],[-77.200398,40.88796]]]},"properties":{"id":"17835","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.884511,41.208719],[-79.868936,41.189656],[-79.860883,41.18975],[-79.884511,41.208719]]]},"properties":{"id":"16372","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.275876,41.873493],[-79.286729,41.78782],[-79.315092,41.741395],[-79.230713,41.792608],[-79.275876,41.873493]]]},"properties":{"id":"16329","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.053796,41.916653],[-78.951365,41.891568],[-78.961372,41.998636],[-79.20662,41.999065],[-79.26048,41.954925],[-79.053796,41.916653]]]},"properties":{"id":"16345","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.090387,40.253422],[-80.087859,40.298121],[-80.088806,40.298134],[-80.089795,40.302947],[-80.094945,40.305907],[-80.112766,40.301825],[-80.132114,40.30627],[-80.170238,40.328624],[-80.196639,40.313034],[-80.26359,40.288691],[-80.268486,40.279478],[-80.222277,40.248092],[-80.204985,40.251253],[-80.195882,40.255406],[-80.196031,40.248767],[-80.203938,40.248424],[-80.20704,40.238089],[-80.163397,40.214657],[-80.090387,40.253422]],[[-80.200509,40.290881],[-80.200937,40.293369],[-80.199595,40.293818],[-80.198994,40.291007],[-80.200509,40.290881]]]},"properties":{"id":"15317","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203938,40.248424],[-80.196031,40.248767],[-80.195882,40.255406],[-80.204985,40.251253],[-80.203938,40.248424]]]},"properties":{"id":"15363","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.923423,40.254896],[-79.927749,40.24401],[-79.914139,40.252518],[-79.923423,40.254896]]]},"properties":{"id":"15038","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.260643,40.327708],[-80.254951,40.326213],[-80.257417,40.332049],[-80.260643,40.327708]]]},"properties":{"id":"15361","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.477174,41.88628],[-75.418853,41.852303],[-75.414218,41.848438],[-75.376078,41.880831],[-75.373539,41.902212],[-75.395258,41.939364],[-75.391684,41.949711],[-75.477174,41.88628]]]},"properties":{"id":"18462","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.048693,41.656148],[-75.066708,41.705003],[-75.085001,41.688407],[-75.156225,41.675998],[-75.133684,41.632213],[-75.048693,41.656148]]]},"properties":{"id":"18443","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.387311,41.351293],[-75.390008,41.341214],[-75.374364,41.354214],[-75.387311,41.351293]]],[[[-75.433515,41.342225],[-75.419213,41.334616],[-75.408862,41.346889],[-75.415805,41.352218],[-75.433515,41.342225]]]]},"properties":{"id":"18463","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.555806,40.427127],[-79.60482,40.40203],[-79.589778,40.39014],[-79.555806,40.427127]]]},"properties":{"id":"15626","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.411057,40.253184],[-79.406968,40.250116],[-79.404127,40.253081],[-79.408828,40.255925],[-79.411057,40.253184]]]},"properties":{"id":"15693","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.732272,40.254696],[-79.684183,40.249679],[-79.693267,40.264686],[-79.716153,40.288348],[-79.733473,40.27658],[-79.732272,40.254696]]]},"properties":{"id":"15637","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.357874,40.324894],[-79.35359,40.318772],[-79.351627,40.323204],[-79.357874,40.324894]]]},"properties":{"id":"15661","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.768288,40.245946],[-79.774324,40.253284],[-79.779812,40.229675],[-79.768288,40.245946]]]},"properties":{"id":"15660","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.749591,40.00728],[-76.737054,40.000692],[-76.603546,39.942533],[-76.594712,39.939413],[-76.52383,39.95972],[-76.529283,39.967541],[-76.52658,39.973645],[-76.54004,40.051311],[-76.63303,40.057369],[-76.644508,40.058069],[-76.711984,40.019811],[-76.728724,40.022801],[-76.726127,40.032764],[-76.778493,40.102514],[-76.797821,40.11012],[-76.781706,40.077903],[-76.749591,40.00728]]]},"properties":{"id":"17406","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.726127,40.032764],[-76.709495,40.107814],[-76.778493,40.102514],[-76.726127,40.032764]]]},"properties":{"id":"17345","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.713879,39.870427],[-76.691349,39.857007],[-76.680132,39.907435],[-76.724959,39.979632],[-76.726281,39.974062],[-76.718142,39.953163],[-76.746036,39.949441],[-76.75874,39.931899],[-76.755404,39.907688],[-76.715654,39.897702],[-76.700597,39.892423],[-76.713879,39.870427]]]},"properties":{"id":"17403","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.055215,40.129061],[-77.148776,40.087593],[-77.137425,40.069945],[-77.042641,40.006982],[-77.000731,40.026991],[-76.932974,40.093432],[-76.933149,40.140305],[-76.986357,40.154892],[-77.009212,40.148917],[-77.055215,40.129061]]]},"properties":{"id":"17019","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.251491,40.556059],[-80.207659,40.552028],[-80.210117,40.553327],[-80.228579,40.573072],[-80.233263,40.569657],[-80.251491,40.556059]]]},"properties":{"id":"15046","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.76414,40.670497],[-79.751053,40.608855],[-79.733169,40.603135],[-79.688847,40.643842],[-79.692476,40.668867],[-79.692587,40.669732],[-79.714286,40.669708],[-79.76414,40.670497]]]},"properties":{"id":"15065","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.171791,40.458528],[-80.099135,40.49943],[-80.16148,40.523803],[-80.207659,40.552028],[-80.251491,40.556059],[-80.277715,40.536943],[-80.281392,40.498627],[-80.211457,40.449287],[-80.172161,40.451795],[-80.171791,40.458528]]]},"properties":{"id":"15108","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.207659,40.552028],[-80.16148,40.523803],[-80.120288,40.51314],[-80.085754,40.594143],[-80.13924,40.622667],[-80.18784,40.623147],[-80.216771,40.574348],[-80.210117,40.553327],[-80.207659,40.552028]]]},"properties":{"id":"15143","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.062072,40.39238],[-80.058064,40.391948],[-80.053642,40.385248],[-80.029035,40.384196],[-80.017414,40.421966],[-80.021096,40.425233],[-80.064301,40.393243],[-80.062072,40.39238]]]},"properties":{"id":"15216","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.773672,40.394888],[-79.802594,40.391419],[-79.798438,40.386451],[-79.807011,40.379364],[-79.811517,40.386687],[-79.818568,40.40058],[-79.83068,40.39952],[-79.846633,40.393702],[-79.853495,40.388332],[-79.836169,40.364992],[-79.826515,40.362056],[-79.785882,40.359481],[-79.771082,40.375419],[-79.769288,40.388434],[-79.773672,40.394888]]]},"properties":{"id":"15137","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.296443,40.425329],[-80.292251,40.371354],[-80.285033,40.365484],[-80.331077,40.345102],[-80.352286,40.315074],[-80.26359,40.288691],[-80.196639,40.313034],[-80.183462,40.332774],[-80.17729,40.339742],[-80.163055,40.360165],[-80.138159,40.35486],[-80.127415,40.360947],[-80.207228,40.380348],[-80.214911,40.380575],[-80.206683,40.385505],[-80.252403,40.434486],[-80.296443,40.425329]],[[-80.260643,40.327708],[-80.257417,40.332049],[-80.254951,40.326213],[-80.260643,40.327708]]]},"properties":{"id":"15057","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.960349,40.460466],[-79.943082,40.454566],[-79.938306,40.461947],[-79.931916,40.475217],[-79.960349,40.460466]]]},"properties":{"id":"15224","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.951054,40.487655],[-79.970767,40.470658],[-79.976016,40.463091],[-79.973025,40.455714],[-79.965944,40.459178],[-79.960349,40.460466],[-79.931916,40.475217],[-79.934338,40.49157],[-79.938355,40.490983],[-79.951054,40.487655]]]},"properties":{"id":"15201","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.873399,40.600424],[-79.860156,40.625502],[-79.866027,40.672264],[-79.87713,40.672309],[-80.010116,40.675779],[-80.036668,40.668254],[-80.01978,40.613611],[-79.873399,40.600424]],[[-79.874553,40.638147],[-79.870774,40.633737],[-79.881192,40.629662],[-79.88351,40.63488],[-79.874553,40.638147]],[[-79.936784,40.64588],[-79.934394,40.657625],[-79.925458,40.654975],[-79.936784,40.64588]]]},"properties":{"id":"15044","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.82046,40.526271],[-79.849451,40.507425],[-79.883772,40.483406],[-79.884165,40.481397],[-79.785317,40.491744],[-79.783127,40.49044],[-79.781223,40.490018],[-79.783348,40.494836],[-79.82046,40.526271]]]},"properties":{"id":"15147","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.888123,40.461331],[-79.917649,40.446338],[-79.899877,40.444715],[-79.885786,40.462391],[-79.888123,40.461331]]]},"properties":{"id":"15208","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.045179,40.493307],[-79.99098,40.497174],[-79.99017,40.506216],[-80.012833,40.500076],[-80.037069,40.507202],[-80.045179,40.493307]]]},"properties":{"id":"15214","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.017475,40.455869],[-80.018306,40.459398],[-80.021326,40.458834],[-80.020731,40.456753],[-80.017475,40.455869]]]},"properties":{"id":"15290","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.82046,40.526271],[-79.821799,40.536257],[-79.849451,40.507425],[-79.82046,40.526271]]]},"properties":{"id":"15139","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.868798,40.556014],[-79.850384,40.561006],[-79.864165,40.571364],[-79.869114,40.562531],[-79.868798,40.556014]]]},"properties":{"id":"15051","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.936784,40.64588],[-79.925458,40.654975],[-79.934394,40.657625],[-79.936784,40.64588]]]},"properties":{"id":"15007","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.131782,40.673847],[-80.148451,40.67429],[-80.225208,40.657657],[-80.243563,40.655362],[-80.232983,40.626249],[-80.18784,40.623147],[-80.13924,40.622667],[-80.130801,40.661794],[-80.131782,40.673847]]]},"properties":{"id":"15005","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.76414,40.670497],[-79.714286,40.669708],[-79.667588,40.757696],[-79.691926,40.773248],[-79.791935,40.748032],[-79.825406,40.671201],[-79.76414,40.670497]]]},"properties":{"id":"16055","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.826995,40.587254],[-79.828581,40.586417],[-79.827072,40.584484],[-79.825386,40.58544],[-79.826995,40.587254]]]},"properties":{"id":"15075","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.364175,40.901735],[-79.523808,40.84617],[-79.555845,40.888564],[-79.610338,40.867684],[-79.642369,40.767027],[-79.606002,40.754272],[-79.56517,40.751367],[-79.529054,40.779903],[-79.518028,40.793835],[-79.519541,40.785594],[-79.368541,40.716848],[-79.340353,40.699203],[-79.33094,40.722747],[-79.352257,40.784418],[-79.33434,40.798642],[-79.327126,40.81913],[-79.32215,40.82725],[-79.350936,40.899456],[-79.364175,40.901735]]]},"properties":{"id":"16201","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.437801,41.020473],[-79.451718,40.98167],[-79.364175,40.901735],[-79.350936,40.899456],[-79.228276,40.957469],[-79.28141,40.995453],[-79.296297,40.988702],[-79.310307,41.008004],[-79.326095,41.129407],[-79.363646,41.129729],[-79.446413,41.098103],[-79.437801,41.020473]],[[-79.346368,40.960386],[-79.343737,40.960221],[-79.341048,40.950577],[-79.344651,40.951761],[-79.346368,40.960386]],[[-79.384074,40.969459],[-79.374249,40.968889],[-79.359995,40.980958],[-79.358873,40.970733],[-79.376572,40.966855],[-79.384074,40.969459]]]},"properties":{"id":"16242","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.222337,40.788407],[-79.289289,40.749087],[-79.286813,40.748599],[-79.238642,40.751653],[-79.215235,40.776016],[-79.215015,40.784196],[-79.215002,40.784673],[-79.222337,40.788407]]]},"properties":{"id":"16250","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.571795,40.693992],[-79.593911,40.631457],[-79.586935,40.636737],[-79.582823,40.633169],[-79.58738,40.62707],[-79.607668,40.598276],[-79.567652,40.589951],[-79.565932,40.593403],[-79.564441,40.599346],[-79.556858,40.599165],[-79.551652,40.601887],[-79.460583,40.627305],[-79.571795,40.693992]]]},"properties":{"id":"15690","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.555845,40.888564],[-79.523808,40.84617],[-79.532242,40.936679],[-79.555845,40.888564]]]},"properties":{"id":"16210","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.567652,40.589951],[-79.607668,40.598276],[-79.647659,40.571642],[-79.666164,40.515363],[-79.599181,40.488912],[-79.555705,40.485843],[-79.523271,40.512796],[-79.458111,40.588534],[-79.443651,40.624006],[-79.460583,40.627305],[-79.551652,40.601887],[-79.567652,40.589951]]]},"properties":{"id":"15613","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.777575,40.89605],[-79.691524,40.883527],[-79.691295,40.894816],[-79.64889,40.98811],[-79.832765,40.995563],[-79.84632,40.936215],[-79.813357,40.911664],[-79.777575,40.89605]]]},"properties":{"id":"16025","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.346368,40.960386],[-79.344651,40.951761],[-79.341048,40.950577],[-79.343737,40.960221],[-79.346368,40.960386]]]},"properties":{"id":"16253","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.335754,40.815925],[-80.325525,40.826636],[-80.334679,40.833874],[-80.368584,40.819184],[-80.357348,40.814116],[-80.335754,40.815925]]],[[[-80.39031,40.915889],[-80.332502,40.834704],[-80.31853,40.843297],[-80.277508,40.897253],[-80.350704,40.938072],[-80.350281,40.928673],[-80.365955,40.922389],[-80.370441,40.937716],[-80.379151,40.957895],[-80.39031,40.915889]]]]},"properties":{"id":"16157","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.436988,40.911553],[-80.448715,40.843032],[-80.368584,40.819184],[-80.334679,40.833874],[-80.332502,40.834704],[-80.39031,40.915889],[-80.436988,40.911553]]]},"properties":{"id":"16141","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.432527,40.618456],[-80.428603,40.616852],[-80.404038,40.6272],[-80.432396,40.627252],[-80.444495,40.623769],[-80.445598,40.623648],[-80.432527,40.618456]]]},"properties":{"id":"15077","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.404038,40.6272],[-80.243563,40.655362],[-80.254711,40.675978],[-80.266081,40.691772],[-80.287584,40.695466],[-80.432396,40.627252],[-80.404038,40.6272]]]},"properties":{"id":"15061","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.579543,40.169405],[-78.511762,40.149804],[-78.487572,40.153981],[-78.466981,40.2072],[-78.57483,40.194374],[-78.579543,40.169405]]]},"properties":{"id":"16667","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.370595,40.158965],[-78.379372,40.181386],[-78.370696,40.158841],[-78.370595,40.158965]]]},"properties":{"id":"16659","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.827894,40.203476],[-75.861599,40.199134],[-75.855335,40.190479],[-75.827894,40.203476]]]},"properties":{"id":"19523","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.089212,40.51098],[-76.001127,40.514945],[-75.911886,40.477164],[-75.853325,40.501077],[-75.869737,40.520211],[-75.882604,40.517106],[-75.874647,40.525838],[-75.877609,40.538425],[-75.95604,40.603768],[-75.996233,40.629971],[-76.005799,40.598681],[-76.012226,40.577713],[-76.038516,40.582323],[-76.11729,40.558544],[-76.111213,40.513674],[-76.098756,40.51515],[-76.09804,40.508394],[-76.089212,40.51098]]]},"properties":{"id":"19526","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.796452,40.596034],[-75.72783,40.600704],[-75.841437,40.694121],[-75.994632,40.635534],[-75.996233,40.629971],[-75.95604,40.603768],[-75.796452,40.596034]]]},"properties":{"id":"19529","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.171346,40.413341],[-76.232764,40.448048],[-76.245258,40.445349],[-76.266393,40.392271],[-76.266294,40.391845],[-76.231916,40.366292],[-76.176567,40.336549],[-76.171346,40.413341]]]},"properties":{"id":"19567","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.769226,40.481599],[-75.853325,40.501077],[-75.911886,40.477164],[-75.915196,40.448971],[-75.903119,40.445677],[-75.888626,40.429493],[-75.853299,40.393429],[-75.711516,40.432007],[-75.756869,40.473795],[-75.769226,40.481599]]]},"properties":{"id":"19522","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.354613,40.437693],[-76.3548,40.364982],[-76.316398,40.301671],[-76.272238,40.308686],[-76.249943,40.343707],[-76.266294,40.391845],[-76.266393,40.392271],[-76.291443,40.417582],[-76.29371,40.413795],[-76.301023,40.414071],[-76.296659,40.420825],[-76.291458,40.417594],[-76.289923,40.457107],[-76.358745,40.473272],[-76.36468,40.471936],[-76.365946,40.471648],[-76.363308,40.444393],[-76.354613,40.437693]]]},"properties":{"id":"17067","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.739455,40.337858],[-75.685055,40.422479],[-75.704345,40.434906],[-75.711516,40.432007],[-75.853299,40.393429],[-75.859659,40.37977],[-75.805544,40.344593],[-75.804289,40.343707],[-75.739455,40.337858]]]},"properties":{"id":"19547","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.001127,40.514945],[-75.972005,40.472421],[-75.939647,40.443029],[-75.915196,40.448971],[-75.911886,40.477164],[-76.001127,40.514945]]]},"properties":{"id":"19555","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.796452,40.596034],[-75.95604,40.603768],[-75.877609,40.538425],[-75.796452,40.596034]]]},"properties":{"id":"19534","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.48024,40.495068],[-75.554904,40.484888],[-75.570985,40.459397],[-75.567351,40.456369],[-75.529694,40.446995],[-75.516584,40.438828],[-75.463276,40.456312],[-75.48024,40.495068]],[[-75.519955,40.484098],[-75.520788,40.485348],[-75.520216,40.485635],[-75.51923,40.484312],[-75.519955,40.484098]]]},"properties":{"id":"18092","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.424001,40.368917],[-78.537164,40.347154],[-78.539083,40.345766],[-78.431823,40.30913],[-78.412944,40.356525],[-78.424001,40.368917]]]},"properties":{"id":"16637","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.187155,41.813685],[-76.150721,41.765081],[-76.125747,41.770172],[-76.130696,41.836577],[-76.135117,41.884586],[-76.135671,41.891812],[-76.178333,41.883222],[-76.24242,41.857842],[-76.187155,41.813685]]]},"properties":{"id":"18829","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.442312,41.647843],[-76.630015,41.745928],[-76.637821,41.700239],[-76.682046,41.606051],[-76.442312,41.647843]]]},"properties":{"id":"18832","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.280092,40.557373],[-75.293461,40.534073],[-75.26049,40.530365],[-75.191228,40.585701],[-75.196803,40.60858],[-75.244633,40.597262],[-75.27436,40.569288],[-75.280092,40.557373]]]},"properties":{"id":"18077","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.958996,40.180896],[-75.024247,40.151373],[-75.015066,40.137992],[-74.980058,40.119883],[-74.953788,40.145856],[-74.958996,40.180896]]]},"properties":{"id":"19053","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.16998,40.524439],[-75.188491,40.471857],[-75.156098,40.417626],[-75.099482,40.475743],[-75.10746,40.4822],[-75.16998,40.524439]]]},"properties":{"id":"18942","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.979317,40.219292],[-75.008754,40.250997],[-75.032579,40.21724],[-74.979317,40.219292]]]},"properties":{"id":"18954","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.269394,40.342769],[-75.263659,40.34677],[-75.273591,40.349078],[-75.276138,40.342826],[-75.269394,40.342769]]]},"properties":{"id":"18962","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.200441,40.376243],[-75.214093,40.373054],[-75.204255,40.366097],[-75.197144,40.368104],[-75.200441,40.376243]]]},"properties":{"id":"18917","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.393324,40.437495],[-75.398689,40.438926],[-75.400328,40.43696],[-75.393324,40.437495]]]},"properties":{"id":"18935","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.980846,41.09916],[-79.938861,41.052333],[-79.893035,41.089675],[-79.833493,41.122397],[-79.814298,41.173134],[-79.867372,41.172759],[-79.980846,41.09916]]]},"properties":{"id":"16020","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.011182,40.824043],[-79.985893,40.74788],[-79.927553,40.816395],[-80.017802,40.872709],[-80.028422,40.865716],[-80.029255,40.821447],[-80.011182,40.824043]]]},"properties":{"id":"16053","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.90235,40.514521],[-78.903846,40.519469],[-78.910057,40.507266],[-78.91354,40.499641],[-78.90235,40.514521]]],[[[-78.90235,40.514521],[-78.87711,40.510357],[-78.891891,40.521412],[-78.90235,40.514521]]],[[[-78.863132,40.511585],[-78.855015,40.504255],[-78.772479,40.481937],[-78.773064,40.498035],[-78.748675,40.489688],[-78.753572,40.483619],[-78.7996,40.446703],[-78.788141,40.436953],[-78.746776,40.436551],[-78.654792,40.465419],[-78.654722,40.465666],[-78.670371,40.571911],[-78.781968,40.572254],[-78.879006,40.543065],[-78.880193,40.521885],[-78.863132,40.511585]],[[-78.789364,40.528134],[-78.799123,40.554284],[-78.778568,40.563973],[-78.752816,40.522527],[-78.789364,40.528134]]]]},"properties":{"id":"15931","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.751402,40.639522],[-78.755236,40.636245],[-78.748331,40.636349],[-78.751402,40.639522]]],[[[-78.794435,40.638489],[-78.777035,40.601416],[-78.768963,40.635107],[-78.78443,40.647233],[-78.794435,40.638489]]]]},"properties":{"id":"15775","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.711773,40.295535],[-78.649517,40.275042],[-78.620383,40.327025],[-78.660942,40.34617],[-78.746332,40.367271],[-78.763483,40.333361],[-78.758032,40.319515],[-78.747068,40.302572],[-78.725599,40.294946],[-78.717682,40.299344],[-78.711773,40.295535]],[[-78.699369,40.320739],[-78.693484,40.310532],[-78.697561,40.30984],[-78.709157,40.32266],[-78.699369,40.320739]]]},"properties":{"id":"15955","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.775054,40.340293],[-78.77334,40.337637],[-78.772699,40.337046],[-78.772015,40.336407],[-78.771486,40.336702],[-78.771943,40.338488],[-78.775054,40.340293]]],[[[-78.770715,40.320228],[-78.784139,40.338241],[-78.783952,40.317434],[-78.770715,40.320228]]],[[[-78.769948,40.379398],[-78.799244,40.365755],[-78.780544,40.34434],[-78.763483,40.333361],[-78.746332,40.367271],[-78.769948,40.379398]]]]},"properties":{"id":"15956","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.761131,40.59659],[-78.752716,40.604885],[-78.760411,40.604919],[-78.761131,40.59659]]]},"properties":{"id":"15737","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.096349,41.411767],[-78.214005,41.228367],[-78.093381,41.216931],[-77.988608,41.398334],[-78.096276,41.422048],[-78.096349,41.411767]]]},"properties":{"id":"15861","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.828518,41.651423],[-78.125419,41.736538],[-78.204317,41.736369],[-78.299733,41.677787],[-78.326103,41.617538],[-78.096276,41.422048],[-77.988608,41.398334],[-77.988661,41.421159],[-77.826161,41.476994],[-77.827816,41.617063],[-77.828518,41.651423]]]},"properties":{"id":"16720","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.877567,40.842569],[-75.905813,40.833363],[-75.893655,40.822806],[-75.877567,40.842569]]]},"properties":{"id":"18232","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.678217,40.821948],[-75.673491,40.815257],[-75.667553,40.818337],[-75.667227,40.831471],[-75.673809,40.829891],[-75.678217,40.821948]]]},"properties":{"id":"18244","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.893103,40.732003],[-77.879645,40.736062],[-77.879191,40.727422],[-77.894282,40.716203],[-77.809049,40.736996],[-77.752054,40.763068],[-77.756164,40.762458],[-77.816881,40.80367],[-77.787807,40.839003],[-77.825627,40.846705],[-77.856234,40.814729],[-77.852617,40.801241],[-77.872454,40.796768],[-77.90854,40.742664],[-77.893103,40.732003]]]},"properties":{"id":"16801","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.667819,40.735335],[-77.51134,40.793856],[-77.481135,40.882774],[-77.478819,40.912787],[-77.519269,40.921486],[-77.555095,40.949742],[-77.622272,40.912596],[-77.702251,40.727741],[-77.699645,40.721023],[-77.681334,40.729758],[-77.667819,40.735335]]]},"properties":{"id":"16875","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.463801,40.915702],[-77.457728,40.893487],[-77.407101,40.835194],[-77.36418,40.846937],[-77.315887,40.88267],[-77.275895,40.961321],[-77.463801,40.915702]]]},"properties":{"id":"16820","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.633067,41.076052],[-77.579952,41.046982],[-77.56712,41.065063],[-77.597235,41.071921],[-77.607838,41.073658],[-77.633067,41.076052]]]},"properties":{"id":"16826","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.432433,40.065082],[-75.423078,40.014381],[-75.404338,40.027364],[-75.432433,40.065082]]]},"properties":{"id":"19333","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.801089,39.962969],[-75.803775,39.966826],[-75.811558,39.964361],[-75.810584,39.961214],[-75.802679,39.960948],[-75.796969,39.956942],[-75.793167,39.962905],[-75.801089,39.962969]]]},"properties":{"id":"19358","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.40399,40.808286],[-78.41024,40.78087],[-78.374033,40.756652],[-78.30151,40.822937],[-78.379845,40.936275],[-78.403956,40.95943],[-78.43686,40.886172],[-78.40399,40.808286]],[[-78.357136,40.835224],[-78.355981,40.848502],[-78.340639,40.83644],[-78.357136,40.835224]]]},"properties":{"id":"16651","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.959042,40.995504],[-78.977651,41.000771],[-79.015101,41.039766],[-79.077314,41.033712],[-79.105893,41.023954],[-79.125664,41.011256],[-79.14421,41.006266],[-79.163541,40.99656],[-79.184696,40.966755],[-79.212546,40.96089],[-79.213719,40.951844],[-79.171964,40.910884],[-79.125724,40.910561],[-79.051159,40.924481],[-79.054958,40.909669],[-79.061976,40.876643],[-78.971681,40.862948],[-78.821317,40.906048],[-78.708162,40.993235],[-78.769587,41.018725],[-78.777842,41.021044],[-78.804041,41.018191],[-78.804142,41.031924],[-78.804199,41.040156],[-78.845372,41.026784],[-78.847435,41.014028],[-78.837326,41.022037],[-78.830155,41.014739],[-78.840784,41.006381],[-78.850444,41.00949],[-78.949403,41.023898],[-78.959042,40.995504]],[[-78.959042,40.995504],[-78.95621,40.984493],[-78.96671,40.982458],[-78.959042,40.995504]],[[-78.89089,40.96686],[-78.884201,40.97225],[-78.864478,40.975421],[-78.865413,40.968182],[-78.879829,40.964616],[-78.89089,40.96686]],[[-78.983965,40.950691],[-78.994823,40.964845],[-78.980576,40.969324],[-78.975355,40.956174],[-78.983965,40.950691]],[[-79.01564,40.995797],[-79.01896,40.977227],[-79.033328,40.979955],[-79.031372,40.993181],[-79.01564,40.995797]]]},"properties":{"id":"15767","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.295464,40.981931],[-78.259616,41.050836],[-78.28084,41.070278],[-78.373459,41.007381],[-78.355748,40.988627],[-78.318542,40.983696],[-78.30175,40.978855],[-78.295464,40.981931]]]},"properties":{"id":"16881","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.414968,41.240151],[-78.28084,41.070278],[-78.259616,41.050836],[-78.147327,41.057597],[-78.096621,41.073383],[-78.236831,41.230187],[-78.236817,41.230475],[-78.414968,41.240151]]]},"properties":{"id":"16836","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.465205,40.855087],[-78.52655,40.826141],[-78.505036,40.810518],[-78.482919,40.803731],[-78.406952,40.806175],[-78.40399,40.808286],[-78.43686,40.886172],[-78.465205,40.855087]]]},"properties":{"id":"16661","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.357136,40.835224],[-78.340639,40.83644],[-78.355981,40.848502],[-78.357136,40.835224]]]},"properties":{"id":"16620","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.790253,41.147449],[-78.869623,41.21232],[-78.804552,41.130956],[-78.790253,41.147449]]]},"properties":{"id":"15840","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.187392,40.962537],[-78.207923,40.97147],[-78.212502,40.960478],[-78.193325,40.95086],[-78.187392,40.962537]]]},"properties":{"id":"16821","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.424241,40.784138],[-78.446106,40.754513],[-78.425755,40.761828],[-78.424241,40.784138]]]},"properties":{"id":"16616","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.390185,40.793254],[-76.380152,40.775511],[-76.356232,40.78415],[-76.390185,40.793254]]]},"properties":{"id":"17945","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.933817,41.559942],[-80.028412,41.58192],[-80.186638,41.548995],[-80.226819,41.539623],[-80.23916,41.482842],[-80.235785,41.482792],[-80.125498,41.45071],[-80.09043,41.436514],[-79.999789,41.463844],[-79.924982,41.513503],[-79.902158,41.495423],[-79.933817,41.559942]]]},"properties":{"id":"16314","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.568283,41.846055],[-79.542615,41.893554],[-79.561873,41.998634],[-79.752657,41.998801],[-79.758719,41.960246],[-79.761307,41.850827],[-79.568283,41.846055]]]},"properties":{"id":"16407","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.720798,41.648745],[-79.726907,41.656391],[-79.728754,41.65572],[-79.725641,41.651321],[-79.720798,41.648745]]]},"properties":{"id":"16328","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.724672,41.527509],[-79.859512,41.584697],[-79.933817,41.559942],[-79.902158,41.495423],[-79.77987,41.511243],[-79.724672,41.527509]]]},"properties":{"id":"16317","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.506982,40.146662],[-77.390747,40.09285],[-77.390687,40.092612],[-77.374355,40.053474],[-77.274971,40.06598],[-77.322842,40.270721],[-77.432675,40.282319],[-77.541415,40.219283],[-77.506982,40.146662]]]},"properties":{"id":"17241","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.95,40.27739],[-77.06578,40.294273],[-77.077546,40.217804],[-76.96499,40.220929],[-76.95,40.27739]]]},"properties":{"id":"17050","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.878871,40.244],[-76.89377,40.234743],[-76.925795,40.202751],[-76.911898,40.18488],[-76.836965,40.178858],[-76.808205,40.20062],[-76.873248,40.237681],[-76.878871,40.244]]]},"properties":{"id":"17070","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.680792,40.353353],[-76.659646,40.426692],[-76.667771,40.447888],[-76.67804,40.474717],[-76.749258,40.444198],[-76.92604,40.361423],[-76.836737,40.307296],[-76.780643,40.298323],[-76.737607,40.319117],[-76.680792,40.353353]]]},"properties":{"id":"17112","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.780643,40.298323],[-76.836867,40.267318],[-76.848907,40.243196],[-76.796601,40.22599],[-76.736981,40.251248],[-76.737607,40.319117],[-76.780643,40.298323]]]},"properties":{"id":"17111","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.714129,40.132113],[-76.609337,40.092936],[-76.591064,40.098643],[-76.564921,40.128723],[-76.569944,40.128218],[-76.568673,40.131213],[-76.564637,40.130502],[-76.51775,40.169555],[-76.5386,40.205667],[-76.581355,40.226349],[-76.633662,40.243906],[-76.645612,40.238259],[-76.659579,40.229059],[-76.671518,40.226344],[-76.714129,40.132113]]]},"properties":{"id":"17022","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.639697,40.619836],[-76.659534,40.595266],[-76.656957,40.573478],[-76.685376,40.567132],[-76.68659,40.560732],[-76.577073,40.580677],[-76.609607,40.600932],[-76.622898,40.609324],[-76.639697,40.619836]]]},"properties":{"id":"17098","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.885881,40.265418],[-76.882227,40.261655],[-76.880514,40.26635],[-76.885881,40.265418]]]},"properties":{"id":"17120","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.109773,40.443526],[-77.130438,40.372587],[-77.12783,40.340591],[-76.933589,40.356045],[-77.027007,40.380892],[-76.971244,40.451969],[-76.973443,40.453815],[-76.968818,40.456083],[-76.968945,40.452835],[-76.953046,40.490572],[-77.109773,40.443526]]]},"properties":{"id":"17020","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.308225,39.940529],[-75.319393,39.91169],[-75.314207,39.904963],[-75.300476,39.904611],[-75.28905,39.916992],[-75.271517,39.926054],[-75.286027,39.93545],[-75.308225,39.940529]]]},"properties":{"id":"19018","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.477183,39.892772],[-75.473108,39.879968],[-75.468181,39.87755],[-75.451528,39.894845],[-75.477183,39.892772]]]},"properties":{"id":"19017","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.923397,42.139625],[-79.977924,42.158255],[-80.040279,42.135173],[-80.039909,42.12358],[-80.027778,42.102888],[-79.948605,42.075821],[-79.912334,42.057642],[-79.887507,42.088425],[-79.923397,42.139625]],[[-79.996749,42.123542],[-79.980711,42.123292],[-79.989552,42.114257],[-79.996749,42.123542]]]},"properties":{"id":"16510","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.07361,42.111919],[-80.12318,42.093633],[-80.11072,42.077728],[-80.062596,42.095615],[-80.072198,42.112415],[-80.07361,42.111919]]]},"properties":{"id":"16508","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.965674,41.88485],[-79.971249,41.876658],[-79.964277,41.874906],[-79.965674,41.88485]]]},"properties":{"id":"16427","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.87136,39.97076],[-79.87737,39.97295],[-79.87734,39.973048],[-79.89005,39.96507],[-79.903314,39.961285],[-79.90568,39.954],[-79.88238,39.93889],[-79.86111,39.932129],[-79.859779,39.945358],[-79.863838,39.954542],[-79.863404,39.955123],[-79.866268,39.966461],[-79.87136,39.97076]]]},"properties":{"id":"15475","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.87299,39.990778],[-79.879671,39.976798],[-79.871374,39.981866],[-79.87299,39.990778]]]},"properties":{"id":"15413","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.279758,39.920747],[-79.204101,39.850126],[-79.170706,39.807261],[-79.149823,39.852648],[-79.047351,39.934484],[-79.235403,39.999677],[-79.32524,39.999884],[-79.35975,39.96381],[-79.380637,39.925188],[-79.35604,39.92491],[-79.279758,39.920747]]]},"properties":{"id":"15557","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.405667,41.570011],[-79.441743,41.578769],[-79.470885,41.532709],[-79.405667,41.570011]]]},"properties":{"id":"16370","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.016178,41.752412],[-79.077089,41.736543],[-79.204994,41.626405],[-79.25582,41.545037],[-78.957418,41.579222],[-78.953962,41.687418],[-78.952022,41.735088],[-78.951632,41.751508],[-79.016178,41.752412]]]},"properties":{"id":"16347","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.578904,39.86928],[-77.534704,39.953955],[-77.592987,40.03563],[-77.641288,40.04],[-77.656855,40.048205],[-77.65818,40.04666],[-77.64097,40.014267],[-77.669123,39.958976],[-77.718456,39.984815],[-77.754157,40.000934],[-77.849674,39.961354],[-77.773618,39.87327],[-77.647165,39.810319],[-77.578904,39.86928]],[[-77.580475,39.974216],[-77.591138,39.966337],[-77.602953,39.965902],[-77.583458,39.976918],[-77.580475,39.974216]],[[-77.688768,39.855424],[-77.708963,39.855402],[-77.698713,39.865193],[-77.688768,39.855424]]]},"properties":{"id":"17202","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.724113,39.721356],[-77.724073,39.720894],[-77.715913,39.721037],[-77.724113,39.721356]]],[[[-77.714824,39.721086],[-77.48166,39.720009],[-77.461536,39.774268],[-77.462908,39.810111],[-77.52789,39.827678],[-77.559851,39.850493],[-77.578904,39.86928],[-77.647165,39.810319],[-77.715085,39.734419],[-77.714824,39.721086]],[[-77.525515,39.73631],[-77.524233,39.737649],[-77.523144,39.737397],[-77.525515,39.73631]],[[-77.580732,39.802845],[-77.57802,39.80073],[-77.576916,39.795922],[-77.581362,39.794282],[-77.582509,39.798362],[-77.580732,39.802845]],[[-77.620565,39.76767],[-77.626712,39.769244],[-77.620174,39.775575],[-77.616552,39.772789],[-77.620565,39.76767]]]]},"properties":{"id":"17268","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.684495,40.119873],[-77.672074,40.135527],[-77.669911,40.153714],[-77.706301,40.117974],[-77.684495,40.119873]]]},"properties":{"id":"17251","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.714824,39.721086],[-77.715085,39.734419],[-77.723067,39.727105],[-77.724443,39.725578],[-77.724113,39.721356],[-77.715913,39.721037],[-77.714824,39.721086]]]},"properties":{"id":"17263","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.580732,39.802845],[-77.582509,39.798362],[-77.581362,39.794282],[-77.576916,39.795922],[-77.57802,39.80073],[-77.580732,39.802845]]]},"properties":{"id":"17247","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.781443,40.186633],[-77.704023,40.177883],[-77.697307,40.185183],[-77.736135,40.215294],[-77.749971,40.224033],[-77.781443,40.186633]]]},"properties":{"id":"17220","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.206706,40.086427],[-78.186836,40.063701],[-78.113525,40.035538],[-78.077247,40.133037],[-78.078353,40.133244],[-78.084818,40.134207],[-78.158214,40.122383],[-78.17704,40.109548],[-78.206706,40.086427]]]},"properties":{"id":"16691","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.100131,39.764871],[-77.977255,39.86827],[-78.077469,39.841228],[-78.119372,39.766179],[-78.115314,39.7642],[-78.100131,39.764871]]]},"properties":{"id":"17212","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.044192,40.112451],[-78.064317,40.063862],[-78.072127,40.052496],[-78.024691,40.029831],[-77.988232,40.039471],[-77.963988,40.109608],[-77.940203,40.142467],[-78.046842,40.126574],[-78.044192,40.112451]]]},"properties":{"id":"17229","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.085832,39.932853],[-80.07089,39.944483],[-80.063115,39.937691],[-80.082765,39.926962],[-80.068747,39.885212],[-79.994298,39.931632],[-80.035529,39.942466],[-80.07895,39.962943],[-80.085832,39.932853]]]},"properties":{"id":"15344","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.415634,39.870584],[-80.439251,39.927688],[-80.519251,39.931938],[-80.519225,39.828341],[-80.433549,39.814368],[-80.402158,39.812779],[-80.415634,39.870584]]]},"properties":{"id":"15380","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.996818,40.680692],[-78.104681,40.666083],[-78.122085,40.597293],[-78.10938,40.613158],[-77.996818,40.680692]]]},"properties":{"id":"16683","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.841827,40.550035],[-77.877571,40.438496],[-77.816757,40.500555],[-77.817115,40.582892],[-77.841827,40.550035]]]},"properties":{"id":"17002","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.10142,40.320207],[-78.121146,40.286335],[-78.096653,40.221499],[-78.050162,40.313441],[-78.10142,40.320207]]]},"properties":{"id":"16685","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.118159,40.371381],[-79.159174,40.295017],[-79.058868,40.276298],[-79.033915,40.316491],[-78.995216,40.365926],[-79.048625,40.449224],[-79.088666,40.464594],[-79.133205,40.418494],[-79.118159,40.371381]]]},"properties":{"id":"15944","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.256345,40.558461],[-79.273847,40.566071],[-79.28853,40.562139],[-79.295442,40.557553],[-79.322037,40.552531],[-79.285016,40.539905],[-79.28422,40.543596],[-79.179194,40.498957],[-79.174761,40.504139],[-79.169159,40.503084],[-79.173859,40.499089],[-79.075406,40.487184],[-79.013875,40.466617],[-78.998835,40.468878],[-78.910057,40.507266],[-78.903846,40.519469],[-78.929163,40.547367],[-78.998126,40.551767],[-79.140432,40.557671],[-79.159088,40.553272],[-79.152185,40.564012],[-79.256345,40.558461]]]},"properties":{"id":"15748","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.196915,40.474691],[-79.186359,40.457246],[-79.18178,40.472295],[-79.196915,40.474691]]]},"properties":{"id":"15716","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.003953,40.735034],[-79.012237,40.720504],[-78.984578,40.731304],[-79.003953,40.735034]]]},"properties":{"id":"15734","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.168824,41.225773],[-79.126844,41.09859],[-79.103043,41.059059],[-79.09139,41.065353],[-79.084194,41.040149],[-79.077314,41.033712],[-79.015101,41.039766],[-78.956329,41.242356],[-78.933802,41.307214],[-78.968845,41.316893],[-79.168824,41.225773]],[[-79.041261,41.086027],[-79.04991,41.107736],[-79.024608,41.104466],[-79.041261,41.086027]]]},"properties":{"id":"15825","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.956329,41.242356],[-79.015101,41.039766],[-78.977651,41.000771],[-78.949403,41.023898],[-78.850444,41.00949],[-78.847435,41.014028],[-78.845372,41.026784],[-78.804349,41.062642],[-78.804552,41.130956],[-78.869623,41.21232],[-78.956329,41.242356]]]},"properties":{"id":"15851","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.89089,40.96686],[-78.879829,40.964616],[-78.865413,40.968182],[-78.864478,40.975421],[-78.884201,40.97225],[-78.89089,40.96686]]]},"properties":{"id":"15715","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.146782,41.022804],[-79.141909,41.021791],[-79.134295,41.021715],[-79.132665,41.02521],[-79.146782,41.022804]]]},"properties":{"id":"15784","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.51518,40.470084],[-77.521166,40.533067],[-77.551258,40.549523],[-77.676371,40.443689],[-77.654295,40.444042],[-77.51518,40.470084]]]},"properties":{"id":"17058","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.046069,40.624826],[-77.069827,40.524555],[-76.985424,40.526375],[-76.949414,40.628163],[-76.939749,40.638378],[-76.950172,40.67381],[-77.046069,40.624826]]]},"properties":{"id":"17045","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.304047,40.622191],[-77.312984,40.617549],[-77.312653,40.611853],[-77.304047,40.622191]]]},"properties":{"id":"17076","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.844949,41.493429],[-75.817249,41.448464],[-75.709567,41.520633],[-75.690699,41.539933],[-75.6684,41.530813],[-75.659954,41.531515],[-75.632233,41.579744],[-75.665062,41.613314],[-75.844949,41.493429]]]},"properties":{"id":"18414","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.843952,41.553867],[-75.86045,41.51606],[-75.85843,41.51589],[-75.844502,41.500078],[-75.844949,41.493429],[-75.665062,41.613314],[-75.651573,41.635646],[-75.652187,41.642196],[-75.843124,41.588967],[-75.86432,41.63805],[-75.899643,41.658975],[-75.923639,41.643941],[-75.843952,41.553867]],[[-75.707197,41.598323],[-75.715148,41.598152],[-75.713472,41.60915],[-75.701643,41.598917],[-75.707197,41.598323]]]},"properties":{"id":"18419","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.583695,41.475638],[-75.535823,41.433912],[-75.517954,41.449137],[-75.561302,41.480116],[-75.583695,41.475638]]]},"properties":{"id":"18434","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.213788,40.120193],[-76.145624,40.108194],[-76.086955,40.177113],[-76.258122,40.229717],[-76.231343,40.130301],[-76.224189,40.120651],[-76.216187,40.129434],[-76.213481,40.120857],[-76.213788,40.120193]],[[-76.213119,40.147556],[-76.211509,40.168815],[-76.186311,40.158981],[-76.213119,40.147556]]]},"properties":{"id":"17522","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.397128,39.994819],[-76.359071,39.962646],[-76.300788,39.953585],[-76.293668,39.9907],[-76.310866,40.055546],[-76.406451,40.04336],[-76.432606,40.023335],[-76.452015,40.0093],[-76.397128,39.994819]]]},"properties":{"id":"17603","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.196002,40.039307],[-76.199449,40.03934],[-76.197602,40.034613],[-76.196002,40.039307]]]},"properties":{"id":"17576","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.204292,39.994878],[-76.211101,39.96089],[-76.214307,39.944962],[-76.176929,39.917726],[-76.136785,39.944531],[-76.139821,39.964432],[-76.147654,39.974245],[-76.204292,39.994878]]]},"properties":{"id":"17579","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.058528,40.240568],[-76.062094,40.234592],[-76.060853,40.234202],[-76.059689,40.233855],[-76.053656,40.234524],[-76.044058,40.247237],[-76.058528,40.240568]]]},"properties":{"id":"19501","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.564637,40.130502],[-76.568673,40.131213],[-76.569944,40.128218],[-76.564921,40.128723],[-76.564637,40.130502]]]},"properties":{"id":"17570","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.502947,41.061536],[-80.518929,41.063027],[-80.519099,41.032796],[-80.502947,41.061536]]],[[[-80.502947,41.061536],[-80.404906,41.085847],[-80.419799,41.096401],[-80.403401,41.157478],[-80.406618,41.161133],[-80.504967,41.097262],[-80.519026,41.090677],[-80.518937,41.071753],[-80.502947,41.061536]]]]},"properties":{"id":"16143","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.617168,40.376412],[-76.516067,40.258414],[-76.486949,40.337935],[-76.518556,40.381135],[-76.536697,40.402508],[-76.536777,40.403101],[-76.535258,40.403512],[-76.667771,40.447888],[-76.659646,40.426692],[-76.612449,40.382254],[-76.617168,40.376412]]]},"properties":{"id":"17003","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.468447,40.537222],[-75.531681,40.543755],[-75.554904,40.484888],[-75.48024,40.495068],[-75.448838,40.512319],[-75.468447,40.537222]]]},"properties":{"id":"18049","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.494888,40.602174],[-75.497969,40.62021],[-75.5295,40.65475],[-75.57539,40.665307],[-75.589693,40.655545],[-75.595884,40.59129],[-75.551515,40.56722],[-75.482433,40.591072],[-75.493496,40.600517],[-75.494888,40.602174]]]},"properties":{"id":"18104","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.430127,40.643837],[-75.358334,40.6177],[-75.333955,40.622174],[-75.332554,40.631009],[-75.375401,40.685567],[-75.392532,40.697688],[-75.422251,40.691857],[-75.445286,40.66882],[-75.41677,40.679014],[-75.430127,40.643837]]]},"properties":{"id":"18017","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.391666,40.633812],[-75.395544,40.633733],[-75.395097,40.630475],[-75.390425,40.630498],[-75.391666,40.633812]]]},"properties":{"id":"18016","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.356488,40.545243],[-75.426061,40.570482],[-75.446163,40.56283],[-75.450102,40.560325],[-75.468447,40.537222],[-75.448838,40.512319],[-75.356488,40.545243]]]},"properties":{"id":"18034","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.663663,40.738451],[-75.660437,40.74102],[-75.65012,40.744667],[-75.663838,40.745351],[-75.663663,40.738451]]]},"properties":{"id":"18079","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.890988,41.245569],[-75.886919,41.238629],[-75.873315,41.246976],[-75.884291,41.248456],[-75.890988,41.245569]]]},"properties":{"id":"18701","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.255201,41.259228],[-76.19013,41.278866],[-76.113329,41.309005],[-76.115219,41.339965],[-76.110946,41.34609],[-76.188883,41.379097],[-76.206499,41.37862],[-76.2831,41.376517],[-76.255201,41.259228]]]},"properties":{"id":"18656","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.931789,41.011514],[-75.928188,40.989822],[-75.92312,41.011453],[-75.931789,41.011514]]]},"properties":{"id":"18221","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.158887,40.936943],[-76.13219,40.940313],[-76.162244,40.937822],[-76.158887,40.936943]]]},"properties":{"id":"18256","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.742163,41.331084],[-76.609629,41.232001],[-76.602961,41.239991],[-76.570816,41.285779],[-76.68392,41.389437],[-76.743284,41.401175],[-76.742163,41.331084]],[[-76.712501,41.285943],[-76.703952,41.285575],[-76.714582,41.276111],[-76.721513,41.282309],[-76.712501,41.285943]]]},"properties":{"id":"17737","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.358636,41.474069],[-77.536987,41.65545],[-77.600321,41.588422],[-77.599449,41.549521],[-77.599109,41.527539],[-77.599151,41.525226],[-77.517922,41.442855],[-77.516408,41.44294],[-77.409621,41.455701],[-77.358636,41.474069]]]},"properties":{"id":"17727","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.579381,41.362293],[-76.608058,41.368685],[-76.594682,41.397563],[-76.612732,41.449308],[-76.653403,41.448016],[-76.68392,41.389437],[-76.570816,41.285779],[-76.447597,41.275629],[-76.43927,41.307253],[-76.461886,41.347151],[-76.34592,41.398293],[-76.37327,41.435456],[-76.384748,41.439786],[-76.418113,41.417453],[-76.579381,41.362293]]]},"properties":{"id":"17758","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.338605,41.851268],[-78.377854,41.747274],[-78.378665,41.746536],[-78.299733,41.677787],[-78.204317,41.736369],[-78.147939,41.861338],[-78.222959,41.893322],[-78.338605,41.851268]]]},"properties":{"id":"16743","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.532689,41.909715],[-78.532657,41.903836],[-78.460431,41.907776],[-78.44286,41.934199],[-78.493658,41.947447],[-78.532715,41.914991],[-78.532689,41.909715]]]},"properties":{"id":"16745","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.302717,41.999383],[-78.491691,41.99983],[-78.44286,41.934199],[-78.460431,41.907776],[-78.364419,41.884178],[-78.264055,41.926205],[-78.302717,41.999383]]]},"properties":{"id":"16731","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519106,41.243108],[-80.485395,41.217348],[-80.486629,41.202845],[-80.489626,41.189506],[-80.374407,41.207221],[-80.366452,41.249826],[-80.415175,41.270806],[-80.423478,41.270706],[-80.519105,41.268003],[-80.519106,41.243108]]]},"properties":{"id":"16148","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.442669,41.286698],[-80.423478,41.270706],[-80.415175,41.270806],[-80.427168,41.290777],[-80.442669,41.286698]]]},"properties":{"id":"16113","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.230503,41.126061],[-75.29563,41.090993],[-75.313021,41.0885],[-75.309068,41.04495],[-75.282641,41.057083],[-75.219315,41.072377],[-75.230503,41.126061]]]},"properties":{"id":"18332","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.221573,40.091755],[-75.189117,40.073124],[-75.188461,40.07275],[-75.171661,40.081848],[-75.143243,40.098345],[-75.131173,40.109693],[-75.156232,40.129927],[-75.16859,40.127008],[-75.197833,40.106492],[-75.221573,40.091755]]]},"properties":{"id":"19038","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.456186,40.087776],[-75.341984,40.068692],[-75.32473,40.103055],[-75.347487,40.109508],[-75.424332,40.107906],[-75.456624,40.093854],[-75.456186,40.087776]]]},"properties":{"id":"19406","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.49727,40.169729],[-75.464796,40.138061],[-75.454196,40.133052],[-75.443449,40.13388],[-75.347009,40.200543],[-75.372225,40.214061],[-75.397411,40.222936],[-75.410711,40.223648],[-75.489976,40.224649],[-75.49727,40.169729]]]},"properties":{"id":"19426","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.470123,40.371618],[-75.471062,40.379392],[-75.492983,40.382394],[-75.482186,40.359273],[-75.470123,40.371618]]]},"properties":{"id":"18076","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.233459,40.183523],[-75.231452,40.181604],[-75.227816,40.185222],[-75.228645,40.185822],[-75.231358,40.183597],[-75.233459,40.183523]]]},"properties":{"id":"19477","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.666023,41.063402],[-76.676088,41.054766],[-76.662491,41.053625],[-76.666023,41.063402]]]},"properties":{"id":"17884","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.880963,41.158044],[-76.896114,41.13907],[-76.88961,41.100638],[-76.857757,41.055625],[-76.723296,41.061447],[-76.723904,41.084485],[-76.793052,41.118386],[-76.880963,41.158044]],[[-76.820376,41.067987],[-76.819051,41.077071],[-76.816224,41.074217],[-76.818203,41.068401],[-76.820376,41.067987]],[[-76.879506,41.106656],[-76.884747,41.108138],[-76.881717,41.112929],[-76.877667,41.111836],[-76.879506,41.106656]]]},"properties":{"id":"17777","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.375401,40.685567],[-75.332554,40.631009],[-75.282548,40.641047],[-75.310816,40.705899],[-75.375401,40.685567]]]},"properties":{"id":"18020","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.233386,40.702752],[-75.282207,40.641052],[-75.244633,40.597262],[-75.196803,40.60858],[-75.192883,40.715648],[-75.233386,40.702752]]]},"properties":{"id":"18042","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.100458,40.930257],[-75.100029,40.91684],[-75.087397,40.918728],[-75.100458,40.930257]]]},"properties":{"id":"18351","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.271338,40.764584],[-75.275833,40.755003],[-75.257638,40.747692],[-75.2571,40.747763],[-75.271338,40.764584]]]},"properties":{"id":"18083","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.647077,40.718113],[-76.707142,40.719286],[-76.703177,40.704994],[-76.673181,40.701388],[-76.644438,40.710553],[-76.647077,40.718113]]]},"properties":{"id":"17867","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.192627,39.994771],[-75.156275,39.986283],[-75.154938,39.986118],[-75.150712,40.001312],[-75.165758,40.003952],[-75.19081,39.999899],[-75.192627,39.994771]]]},"properties":{"id":"19132","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.154938,39.986118],[-75.132544,39.983166],[-75.130787,39.991397],[-75.12926,39.998539],[-75.150712,40.001312],[-75.154938,39.986118]]]},"properties":{"id":"19133","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.096733,40.068989],[-75.107033,40.04897],[-75.101572,40.038293],[-75.093532,40.035024],[-75.066045,40.051181],[-75.065724,40.070193],[-75.076363,40.087265],[-75.096733,40.068989]]]},"properties":{"id":"19111","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.166968,40.060413],[-75.175804,40.052241],[-75.154415,40.039493],[-75.150142,40.051578],[-75.146683,40.067547],[-75.154076,40.071732],[-75.166968,40.060413]]]},"properties":{"id":"19138","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.024909,41.539225],[-75.033859,41.540168],[-75.029007,41.483399],[-74.978467,41.433704],[-74.965966,41.476844],[-75.024909,41.539225]]]},"properties":{"id":"18435","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.194364,40.76914],[-76.096552,40.71791],[-76.077795,40.718278],[-76.066832,40.726195],[-76.073246,40.744359],[-76.08242,40.770394],[-76.082774,40.771401],[-76.194364,40.76914]]]},"properties":{"id":"17953","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.14933,40.711097],[-76.12859,40.692753],[-76.077795,40.718278],[-76.096552,40.71791],[-76.14933,40.711097]]]},"properties":{"id":"17930","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.254714,40.820558],[-76.275138,40.813257],[-76.26075,40.801893],[-76.254714,40.820558]]]},"properties":{"id":"17946","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.18441,40.631414],[-76.18892,40.640527],[-76.201465,40.63173],[-76.19866,40.624656],[-76.18441,40.631414]]]},"properties":{"id":"17929","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.249541,40.678412],[-76.243805,40.674933],[-76.238923,40.675892],[-76.243669,40.682211],[-76.249541,40.678412]]]},"properties":{"id":"17951","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.212352,40.713184],[-76.176422,40.716677],[-76.187679,40.73249],[-76.212352,40.713184]]]},"properties":{"id":"17970","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.227673,40.796844],[-76.198361,40.800905],[-76.199481,40.804629],[-76.251553,40.794628],[-76.251194,40.79356],[-76.227673,40.796844]]]},"properties":{"id":"17934","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.051233,40.099132],[-78.953476,40.0131],[-78.85749,40.00574],[-78.871998,40.027527],[-78.902036,40.017598],[-78.912075,40.019435],[-79.052086,40.133062],[-79.056995,40.143653],[-79.063784,40.138989],[-79.085195,40.134974],[-79.086252,40.132958],[-79.073725,40.113018],[-79.071906,40.11295],[-79.051233,40.099132]]]},"properties":{"id":"15541","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.073035,40.154868],[-79.056081,40.161789],[-79.077494,40.170175],[-79.073035,40.154868]]]},"properties":{"id":"15547","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.056645,40.144068],[-79.063784,40.138989],[-79.056995,40.143653],[-79.056645,40.144068]]],[[[-79.056645,40.144068],[-79.048205,40.146033],[-79.056081,40.161789],[-79.073035,40.154868],[-79.056645,40.144068]]],[[[-79.052086,40.133062],[-78.912075,40.019435],[-78.907002,40.019313],[-78.902036,40.017598],[-78.871998,40.027527],[-78.841837,40.120563],[-78.853045,40.138168],[-78.967716,40.140632],[-78.982759,40.152256],[-79.041497,40.147306],[-79.052086,40.133062]]]]},"properties":{"id":"15563","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.845405,39.722988],[-78.824562,39.722919],[-78.836182,39.738772],[-78.845405,39.722988]]]},"properties":{"id":"15564","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.874463,40.206623],[-78.898514,40.212109],[-78.892002,40.207128],[-78.874463,40.206623]]]},"properties":{"id":"15953","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.34592,41.398293],[-76.276784,41.391568],[-76.348884,41.460155],[-76.37327,41.435456],[-76.34592,41.398293]]]},"properties":{"id":"18628","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.612732,41.449308],[-76.594682,41.397563],[-76.52755,41.453625],[-76.612732,41.449308]]]},"properties":{"id":"17731","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.474101,41.768083],[-75.48039,41.76026],[-75.479189,41.735186],[-75.467344,41.72702],[-75.474101,41.768083]]]},"properties":{"id":"18430","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.533617,41.771706],[-77.490756,41.688867],[-77.216407,41.635807],[-77.170916,41.63677],[-77.124927,41.695978],[-77.177484,41.730493],[-77.181625,41.85586],[-77.211956,41.876176],[-77.42103,41.838828],[-77.533617,41.771706]]]},"properties":{"id":"16901","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.124927,41.695978],[-77.170916,41.63677],[-77.087803,41.638513],[-77.113289,41.696214],[-77.124927,41.695978]]]},"properties":{"id":"16911","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.972571,41.796193],[-76.995417,41.747827],[-76.952063,41.73691],[-76.949439,41.824036],[-76.972571,41.796193]]]},"properties":{"id":"16932","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.058756,41.039145],[-76.882053,41.024753],[-76.864544,41.019795],[-76.857757,41.055625],[-76.88961,41.100638],[-76.881685,41.090581],[-77.058088,41.085575],[-77.058756,41.039145]],[[-76.881685,41.090581],[-76.879168,41.089024],[-76.870385,41.07518],[-76.875044,41.075988],[-76.874849,41.080262],[-76.881685,41.090581]]]},"properties":{"id":"17856","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.118537,40.896169],[-77.24508,40.947403],[-77.315887,40.88267],[-77.356701,40.811365],[-77.32119,40.817187],[-77.243358,40.832087],[-77.155489,40.848858],[-77.052914,40.869329],[-77.12168,40.888036],[-77.126537,40.892194],[-77.118537,40.896169]],[[-77.200398,40.88796],[-77.196424,40.877298],[-77.208341,40.874983],[-77.211369,40.88424],[-77.200398,40.88796]],[[-77.166047,40.899759],[-77.146517,40.906346],[-77.146516,40.895192],[-77.166047,40.899759]]]},"properties":{"id":"17845","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.924982,41.513503],[-79.914849,41.397662],[-79.842234,41.323803],[-79.719969,41.345136],[-79.711293,41.349681],[-79.731434,41.40629],[-79.733269,41.406875],[-79.795579,41.414681],[-79.77987,41.511243],[-79.902158,41.495423],[-79.924982,41.513503]]]},"properties":{"id":"16323","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.731434,41.40629],[-79.603604,41.355624],[-79.47805,41.411114],[-79.511432,41.496927],[-79.659357,41.533564],[-79.724672,41.527509],[-79.77987,41.511243],[-79.795579,41.414681],[-79.732143,41.446512],[-79.733269,41.406875],[-79.731434,41.40629]],[[-79.693654,41.478045],[-79.682365,41.478188],[-79.67379,41.463826],[-79.691215,41.463734],[-79.693654,41.478045]]]},"properties":{"id":"16301","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.693654,41.478045],[-79.691215,41.463734],[-79.67379,41.463826],[-79.682365,41.478188],[-79.693654,41.478045]]]},"properties":{"id":"16344","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.445565,41.99843],[-79.527993,41.998555],[-79.492877,41.899709],[-79.479135,41.901906],[-79.475884,41.904822],[-79.451905,41.923199],[-79.391831,41.952661],[-79.445565,41.99843]]]},"properties":{"id":"16402","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.439183,41.818909],[-79.472383,41.844585],[-79.492488,41.813842],[-79.439183,41.818909]]]},"properties":{"id":"16416","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.857705,40.193175],[-79.864153,40.161815],[-79.863033,40.161975],[-79.861311,40.162514],[-79.857705,40.193175]]]},"properties":{"id":"15033","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.878094,40.125103],[-79.875324,40.122553],[-79.867384,40.113864],[-79.855704,40.10492],[-79.848901,40.112136],[-79.854928,40.117153],[-79.877961,40.12699],[-79.879161,40.125601],[-79.878094,40.125103]]]},"properties":{"id":"15432","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.977478,40.164638],[-79.968495,40.157286],[-79.968988,40.166887],[-79.977478,40.164638]]]},"properties":{"id":"15366","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.367172,40.45501],[-80.360613,40.382113],[-80.354257,40.379383],[-80.331077,40.345102],[-80.285033,40.365484],[-80.29837,40.362977],[-80.292251,40.371354],[-80.296443,40.425329],[-80.360873,40.477539],[-80.367172,40.45501]]]},"properties":{"id":"15019","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.405553,40.364418],[-80.411754,40.364392],[-80.412869,40.361776],[-80.407287,40.361669],[-80.405553,40.364418]]]},"properties":{"id":"15054","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.429732,41.508377],[-75.431269,41.503395],[-75.426893,41.50281],[-75.426936,41.507785],[-75.429732,41.508377]]]},"properties":{"id":"18459","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.625845,40.137182],[-79.583464,40.127743],[-79.588207,40.150306],[-79.591702,40.150672],[-79.610284,40.149517],[-79.625845,40.137182]]]},"properties":{"id":"15612","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.591203,40.244259],[-79.58836,40.227535],[-79.565139,40.227562],[-79.562996,40.228918],[-79.591203,40.244259]]]},"properties":{"id":"15697","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.335646,40.375589],[-79.345232,40.348334],[-79.314838,40.3396],[-79.305838,40.360503],[-79.335646,40.375589]]]},"properties":{"id":"15671","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.65158,40.335337],[-79.639394,40.325235],[-79.656419,40.316285],[-79.643765,40.296356],[-79.601246,40.318997],[-79.611505,40.325165],[-79.606424,40.328024],[-79.603065,40.321377],[-79.58997,40.313957],[-79.589778,40.39014],[-79.60482,40.40203],[-79.671487,40.401011],[-79.65824,40.39597],[-79.653152,40.351687],[-79.659682,40.342597],[-79.66028,40.3423],[-79.65158,40.335337]],[[-79.60907,40.37013],[-79.62177,40.35529],[-79.63316,40.37132],[-79.60907,40.37013]]]},"properties":{"id":"15644","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.556858,40.599165],[-79.564441,40.599346],[-79.565932,40.593403],[-79.556858,40.599165]]]},"properties":{"id":"15629","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.286171,40.165736],[-79.229019,40.097813],[-79.172565,40.125542],[-79.190802,40.139576],[-79.242318,40.194962],[-79.246126,40.205374],[-79.286171,40.165736]]]},"properties":{"id":"15677","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.49533,40.21981],[-79.491507,40.217995],[-79.488881,40.224787],[-79.496411,40.221993],[-79.49533,40.21981]]]},"properties":{"id":"15689","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.639394,40.325235],[-79.65158,40.335337],[-79.647766,40.329031],[-79.639394,40.325235]]]},"properties":{"id":"15675","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.509812,39.929149],[-76.52383,39.95972],[-76.594712,39.939413],[-76.509812,39.929149]]]},"properties":{"id":"17366","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.594712,39.939413],[-76.603546,39.942533],[-76.654684,39.930002],[-76.65589,39.854994],[-76.655382,39.854141],[-76.472309,39.937461],[-76.509812,39.929149],[-76.594712,39.939413]]]},"properties":{"id":"17356","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.529283,39.967541],[-76.516772,39.969184],[-76.521509,39.974734],[-76.52658,39.973645],[-76.529283,39.967541]]]},"properties":{"id":"17317","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.85769,39.947401],[-76.923121,39.948768],[-76.965788,39.918266],[-76.948531,39.894179],[-76.855276,39.906382],[-76.85769,39.947401]]]},"properties":{"id":"17364","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.803331,39.792914],[-76.866664,39.7204],[-76.757796,39.720902],[-76.703338,39.754578],[-76.692533,39.763983],[-76.674899,39.789371],[-76.646805,39.828763],[-76.655382,39.854141],[-76.65589,39.854994],[-76.68832,39.844574],[-76.814045,39.81524],[-76.803331,39.792914]]]},"properties":{"id":"17327","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.793848,39.999984],[-76.79889,40.000697],[-76.85769,39.947401],[-76.855276,39.906382],[-76.804616,39.881247],[-76.755404,39.907688],[-76.75874,39.931899],[-76.762604,39.976624],[-76.793848,39.999984]],[[-76.789282,39.901557],[-76.784825,39.903563],[-76.784053,39.898495],[-76.788855,39.897571],[-76.789282,39.901557]]]},"properties":{"id":"17408","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.315395,40.010223],[-77.184644,39.933264],[-77.144622,39.952299],[-77.154733,39.980424],[-77.315395,40.010223]],[[-77.246282,39.969997],[-77.247661,39.975471],[-77.255963,39.980746],[-77.255728,39.98803],[-77.242937,39.980998],[-77.246282,39.969997]]]},"properties":{"id":"17304","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.246282,39.969997],[-77.242937,39.980998],[-77.255728,39.98803],[-77.255963,39.980746],[-77.247661,39.975471],[-77.246282,39.969997]]]},"properties":{"id":"17306","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.037069,40.507202],[-80.062639,40.520041],[-80.108363,40.510521],[-80.067526,40.492271],[-80.053179,40.48217],[-80.045179,40.493307],[-80.037069,40.507202]]]},"properties":{"id":"15202","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.854501,40.38877],[-79.847944,40.356932],[-79.836169,40.364992],[-79.853495,40.388332],[-79.854501,40.38877]]]},"properties":{"id":"15110","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.74117,40.600666],[-79.751053,40.608855],[-79.76414,40.670497],[-79.825406,40.671201],[-79.866027,40.672264],[-79.860156,40.625502],[-79.837776,40.622753],[-79.826493,40.598898],[-79.811297,40.561932],[-79.79883,40.562275],[-79.775699,40.562828],[-79.764001,40.592735],[-79.74117,40.600666]]]},"properties":{"id":"15084","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.95872,40.409824],[-79.942391,40.372784],[-79.940651,40.374682],[-79.938594,40.394456],[-79.95872,40.409824]]],[[[-79.95595,40.356932],[-79.993999,40.375839],[-80.01152,40.351743],[-80.01676,40.349605],[-80.009341,40.32112],[-79.979327,40.318338],[-79.942203,40.321135],[-79.95595,40.356932]]]]},"properties":{"id":"15236","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.034277,40.373077],[-80.046257,40.352004],[-80.01676,40.349605],[-80.01152,40.351743],[-79.993999,40.375839],[-79.995076,40.384696],[-79.998568,40.389791],[-80.022866,40.380243],[-80.034277,40.373077]]]},"properties":{"id":"15234","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.869114,40.562531],[-79.864165,40.571364],[-79.850384,40.561006],[-79.868798,40.556014],[-79.821799,40.536257],[-79.794789,40.535785],[-79.798976,40.549119],[-79.811297,40.561932],[-79.826493,40.598898],[-79.84233,40.598512],[-79.837776,40.622753],[-79.860156,40.625502],[-79.873399,40.600424],[-79.879336,40.5872],[-79.869114,40.562531]],[[-79.826995,40.587254],[-79.825386,40.58544],[-79.827072,40.584484],[-79.828581,40.586417],[-79.826995,40.587254]]]},"properties":{"id":"15024","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.923423,40.254896],[-79.914139,40.252518],[-79.897369,40.267161],[-79.904198,40.271885],[-79.923423,40.254896]]],[[[-79.979327,40.318338],[-79.959829,40.279486],[-79.905468,40.274788],[-79.886293,40.276917],[-79.884144,40.311503],[-79.891884,40.33899],[-79.893858,40.339983],[-79.942203,40.321135],[-79.979327,40.318338]]]]},"properties":{"id":"15025","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.088416,40.629397],[-80.069356,40.632872],[-80.069778,40.642567],[-80.089752,40.640391],[-80.088416,40.629397]]]},"properties":{"id":"15015","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.938306,40.461947],[-79.920571,40.448319],[-79.917649,40.446338],[-79.888123,40.461331],[-79.884165,40.481397],[-79.883772,40.483406],[-79.90168,40.486218],[-79.934338,40.49157],[-79.931916,40.475217],[-79.938306,40.461947]]]},"properties":{"id":"15206","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.934033,40.437707],[-79.918572,40.410757],[-79.911536,40.414896],[-79.89899,40.436443],[-79.899877,40.444715],[-79.917649,40.446338],[-79.920571,40.448319],[-79.937986,40.446943],[-79.934033,40.437707]]]},"properties":{"id":"15217","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.840955,40.320239],[-79.881407,40.341406],[-79.874683,40.317126],[-79.840955,40.320239]]]},"properties":{"id":"15133","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.997548,40.425992],[-79.953974,40.418242],[-79.960296,40.428635],[-80.003958,40.43201],[-79.997548,40.425992]]]},"properties":{"id":"15203","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.993874,40.554004],[-79.988204,40.524112],[-79.9668,40.520052],[-79.938219,40.519269],[-79.922224,40.524485],[-79.915228,40.567494],[-79.993874,40.554004]]]},"properties":{"id":"15116","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.88587,40.748982],[-79.971173,40.744819],[-80.010116,40.675779],[-79.87713,40.672309],[-79.88587,40.748982]]]},"properties":{"id":"16059","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.116498,40.673827],[-80.097719,40.66072],[-80.0703,40.653452],[-80.101542,40.674004],[-80.116498,40.673827]]]},"properties":{"id":"15086","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.238642,40.751653],[-79.286813,40.748599],[-79.275272,40.743554],[-79.238642,40.751653]]],[[[-79.352257,40.784418],[-79.33094,40.722747],[-79.289289,40.749087],[-79.222337,40.788407],[-79.261435,40.792462],[-79.273461,40.784777],[-79.291006,40.789024],[-79.274268,40.801079],[-79.32215,40.82725],[-79.327126,40.81913],[-79.32096,40.80814],[-79.33434,40.798642],[-79.352257,40.784418]]]]},"properties":{"id":"16249","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.691524,40.883527],[-79.691968,40.81336],[-79.691926,40.773248],[-79.667588,40.757696],[-79.642369,40.767027],[-79.610338,40.867684],[-79.648346,40.902613],[-79.691295,40.894816],[-79.691524,40.883527]]]},"properties":{"id":"16262","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.421834,40.652751],[-79.443651,40.624006],[-79.458111,40.588534],[-79.370453,40.628622],[-79.421834,40.652751]]]},"properties":{"id":"15686","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.424751,40.558597],[-80.277715,40.536943],[-80.251491,40.556059],[-80.233263,40.569657],[-80.233982,40.582367],[-80.232983,40.626249],[-80.243563,40.655362],[-80.404038,40.6272],[-80.428603,40.616852],[-80.424751,40.558597]]]},"properties":{"id":"15001","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.225208,40.657657],[-80.254711,40.675978],[-80.243563,40.655362],[-80.225208,40.657657]]]},"properties":{"id":"15027","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.603614,40.048542],[-78.760833,39.979822],[-78.76021,39.952234],[-78.644315,39.876103],[-78.603614,40.048542]]]},"properties":{"id":"15550","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.431823,40.30913],[-78.4421,40.271574],[-78.395654,40.24012],[-78.36744,40.256727],[-78.359434,40.271058],[-78.345149,40.343633],[-78.412944,40.356525],[-78.431823,40.30913]]]},"properties":{"id":"16673","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.845405,39.722988],[-78.836182,39.738772],[-78.824562,39.722919],[-78.742523,39.722988],[-78.718144,39.7603],[-78.758486,39.878144],[-78.814882,39.81699],[-78.885721,39.722885],[-78.845405,39.722988]]]},"properties":{"id":"15545","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.967655,40.362634],[-75.921251,40.353285],[-75.899262,40.376156],[-75.893149,40.384401],[-75.898988,40.425643],[-75.903119,40.445677],[-75.915196,40.448971],[-75.939647,40.443029],[-75.990512,40.368356],[-75.967655,40.362634]]]},"properties":{"id":"19605","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.827894,40.203476],[-75.855335,40.190479],[-75.855852,40.182597],[-75.827894,40.203476]]],[[[-75.827894,40.203476],[-75.795954,40.212023],[-75.767298,40.260988],[-75.768624,40.262473],[-75.76612,40.263404],[-75.765253,40.259746],[-75.76839,40.258865],[-75.802204,40.326558],[-75.903672,40.317237],[-75.920472,40.310594],[-75.915842,40.267506],[-75.87495,40.21505],[-75.861599,40.199134],[-75.827894,40.203476]]]]},"properties":{"id":"19508","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.044058,40.247237],[-76.053656,40.234524],[-76.04649,40.223203],[-76.017977,40.206609],[-75.943437,40.182676],[-75.87495,40.21505],[-75.915842,40.267506],[-76.00256,40.295372],[-76.033501,40.253192],[-76.044058,40.247237]]]},"properties":{"id":"19540","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.062094,40.234592],[-76.04649,40.223203],[-76.053656,40.234524],[-76.059689,40.233855],[-76.060853,40.234202],[-76.062094,40.234592]]],[[[-76.073005,40.297349],[-76.138431,40.30745],[-76.160762,40.312167],[-76.058528,40.240568],[-76.044058,40.247237],[-76.033501,40.253192],[-76.073005,40.297349]]]]},"properties":{"id":"17569","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.66093,40.426444],[-75.685055,40.422479],[-75.739455,40.337858],[-75.732363,40.322484],[-75.733001,40.318473],[-75.728724,40.317453],[-75.682392,40.259493],[-75.636524,40.300655],[-75.618532,40.337862],[-75.624996,40.346423],[-75.66093,40.426444]]]},"properties":{"id":"19512","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.606803,40.54158],[-75.641524,40.489645],[-75.642485,40.482282],[-75.619061,40.457835],[-75.570985,40.459397],[-75.554904,40.484888],[-75.531681,40.543755],[-75.54145,40.554657],[-75.558617,40.549662],[-75.560065,40.547293],[-75.563315,40.547192],[-75.586025,40.550304],[-75.606803,40.54158]]]},"properties":{"id":"18062","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.947692,40.340587],[-75.95592,40.318995],[-75.920472,40.310594],[-75.939216,40.330948],[-75.947692,40.340587]]]},"properties":{"id":"19611","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.089212,40.51098],[-76.039864,40.430612],[-75.972005,40.472421],[-76.001127,40.514945],[-76.089212,40.51098]]]},"properties":{"id":"19541","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.641524,40.489645],[-75.617959,40.53394],[-75.682238,40.543638],[-75.686411,40.543006],[-75.69561,40.542085],[-75.700806,40.516368],[-75.691058,40.501978],[-75.705023,40.49585],[-75.712223,40.504532],[-75.736205,40.48765],[-75.748744,40.481249],[-75.756869,40.473795],[-75.711516,40.432007],[-75.704345,40.434906],[-75.642485,40.482282],[-75.641524,40.489645]]]},"properties":{"id":"19539","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.712223,40.504532],[-75.705023,40.49585],[-75.691058,40.501978],[-75.700806,40.516368],[-75.712223,40.504532]]]},"properties":{"id":"19562","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.291458,40.417594],[-76.296659,40.420825],[-76.301023,40.414071],[-76.29371,40.413795],[-76.291443,40.417582],[-76.291458,40.417594]]]},"properties":{"id":"19544","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.696782,40.241863],[-75.592657,40.225491],[-75.535359,40.252611],[-75.550629,40.266252],[-75.636524,40.300655],[-75.682392,40.259493],[-75.696782,40.241863]]]},"properties":{"id":"19464","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.733001,40.318473],[-75.732363,40.322484],[-75.739387,40.321632],[-75.733001,40.318473]]]},"properties":{"id":"19519","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.098277,40.690909],[-78.116521,40.739127],[-78.211229,40.775364],[-78.359912,40.732592],[-78.35043,40.724827],[-78.381743,40.679209],[-78.408629,40.644349],[-78.336167,40.593343],[-78.344779,40.607275],[-78.31618,40.606302],[-78.234459,40.562156],[-78.183176,40.595315],[-78.122085,40.597293],[-78.104681,40.666083],[-78.098277,40.690909]]]},"properties":{"id":"16686","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.421703,40.44986],[-78.397489,40.458976],[-78.381917,40.495261],[-78.427015,40.469922],[-78.426988,40.46492],[-78.424099,40.46215],[-78.426543,40.450856],[-78.421703,40.44986]]]},"properties":{"id":"16602","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.270066,40.530199],[-78.290803,40.418078],[-78.189163,40.395519],[-78.162955,40.428703],[-78.147157,40.451931],[-78.144267,40.491321],[-78.183176,40.595315],[-78.234459,40.562156],[-78.270066,40.530199]]]},"properties":{"id":"16693","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.302948,41.595305],[-76.396178,41.654315],[-76.442312,41.647843],[-76.682046,41.606051],[-76.694374,41.579883],[-76.677861,41.578541],[-76.554363,41.548294],[-76.276682,41.546298],[-76.302948,41.595305]]]},"properties":{"id":"18833","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.924419,41.837361],[-76.633344,41.810116],[-76.621336,41.843676],[-76.624103,41.845978],[-76.679528,41.917631],[-76.686859,41.941377],[-76.910488,41.899728],[-76.938208,41.871551],[-76.924419,41.837361]]]},"properties":{"id":"16914","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.050831,41.961567],[-77.014761,41.911686],[-76.938208,41.871551],[-76.910488,41.899728],[-76.868498,42.001872],[-76.955525,42.001373],[-77.050831,41.961567]]]},"properties":{"id":"16936","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.595619,41.781388],[-76.615382,41.785652],[-76.602969,41.776488],[-76.595619,41.781388]]]},"properties":{"id":"18814","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.276138,40.342826],[-75.273591,40.349078],[-75.263659,40.34677],[-75.269394,40.342769],[-75.250454,40.322714],[-75.244139,40.318949],[-75.185004,40.342788],[-75.171161,40.349552],[-75.181369,40.359386],[-75.186084,40.362274],[-75.197144,40.368104],[-75.204255,40.366097],[-75.214093,40.373054],[-75.200441,40.376243],[-75.181885,40.370534],[-75.167805,40.38439],[-75.156098,40.417626],[-75.188491,40.471857],[-75.217449,40.468775],[-75.308603,40.409464],[-75.276138,40.342826]]]},"properties":{"id":"18944","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.869547,40.081539],[-74.902554,40.104119],[-74.909103,40.070505],[-74.869547,40.081539]]]},"properties":{"id":"19021","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.024247,40.151373],[-75.071198,40.171032],[-75.07341,40.172298],[-75.100093,40.153291],[-75.103676,40.133395],[-75.096022,40.126429],[-75.07127,40.092532],[-75.044216,40.115747],[-75.015066,40.137992],[-75.024247,40.151373]],[[-75.067132,40.128408],[-75.067081,40.141419],[-75.047165,40.142585],[-75.067132,40.128408]]]},"properties":{"id":"19006","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.909334,40.306804],[-75.005817,40.295503],[-75.014795,40.286525],[-75.025791,40.252762],[-75.008754,40.250997],[-74.979317,40.219292],[-74.94441,40.211453],[-74.887551,40.224735],[-74.889142,40.265371],[-74.909334,40.306804]]]},"properties":{"id":"18940","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.104984,40.239447],[-75.116154,40.265533],[-75.160465,40.286432],[-75.158836,40.223583],[-75.152756,40.220028],[-75.139374,40.211853],[-75.139245,40.211775],[-75.137782,40.210886],[-75.104984,40.239447]]]},"properties":{"id":"18976","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.72584,41.054633],[-79.743955,41.053345],[-79.734919,41.045371],[-79.72584,41.054633]]]},"properties":{"id":"16022","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.918856,40.841513],[-79.912133,40.85013],[-79.910803,40.860412],[-79.918611,40.860685],[-79.923212,40.852257],[-79.91955,40.841619],[-79.918856,40.841513]]]},"properties":{"id":"16045","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.828942,41.059611],[-79.823917,41.046099],[-79.821547,41.060829],[-79.828942,41.059611]]]},"properties":{"id":"16048","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.877835,40.346902],[-78.912265,40.28991],[-78.895344,40.270027],[-78.884862,40.244631],[-78.811265,40.271003],[-78.812235,40.283141],[-78.799349,40.282789],[-78.797772,40.291029],[-78.783952,40.317434],[-78.784139,40.338241],[-78.780544,40.34434],[-78.799244,40.365755],[-78.860223,40.360384],[-78.876197,40.353502],[-78.877835,40.346902]]]},"properties":{"id":"15904","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.910057,40.507266],[-78.998835,40.468878],[-78.983631,40.465646],[-78.95238,40.433198],[-78.910135,40.435157],[-78.882592,40.447113],[-78.908138,40.473012],[-78.906803,40.484066],[-78.91354,40.499641],[-78.910057,40.507266]]]},"properties":{"id":"15961","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.80422,40.701978],[-78.792724,40.684588],[-78.769317,40.697939],[-78.80422,40.701978]]]},"properties":{"id":"15738","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.908138,40.473012],[-78.882592,40.447113],[-78.828094,40.448831],[-78.7996,40.446703],[-78.753572,40.483619],[-78.772479,40.481937],[-78.855015,40.504255],[-78.906803,40.484066],[-78.908138,40.473012]]]},"properties":{"id":"15943","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.517827,40.715597],[-78.589623,40.725423],[-78.633919,40.725159],[-78.531788,40.697317],[-78.517827,40.715597]]]},"properties":{"id":"16640","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.891891,40.521412],[-78.87711,40.510357],[-78.863132,40.511585],[-78.880193,40.521885],[-78.891891,40.521412]]]},"properties":{"id":"15922","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.561097,40.450174],[-78.562678,40.446294],[-78.557956,40.446508],[-78.561097,40.450174]]]},"properties":{"id":"16699","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.420172,41.515362],[-78.420028,41.405046],[-78.325216,41.404843],[-78.096349,41.411767],[-78.096276,41.422048],[-78.326103,41.617538],[-78.419299,41.616853],[-78.419118,41.602188],[-78.420172,41.515362]]]},"properties":{"id":"15834","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.635176,41.005107],[-75.567342,40.954654],[-75.492259,40.980629],[-75.481119,41.007574],[-75.546138,41.034291],[-75.667285,41.043388],[-75.635176,41.005107]]]},"properties":{"id":"18210","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.841437,40.694121],[-75.757807,40.735414],[-75.848177,40.792412],[-75.877256,40.765731],[-75.841437,40.694121]]]},"properties":{"id":"18211","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.915926,40.843129],[-77.939424,40.775485],[-77.972841,40.760439],[-77.90854,40.742664],[-77.872454,40.796768],[-77.856234,40.814729],[-77.825627,40.846705],[-77.915926,40.843129]]]},"properties":{"id":"16803","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.002903,41.047736],[-77.908073,41.047465],[-77.786249,41.068675],[-77.834934,41.140025],[-77.98683,41.061735],[-78.002903,41.047736]]]},"properties":{"id":"16829","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.379845,40.936275],[-78.30151,40.822937],[-78.264214,40.824763],[-78.330605,40.913143],[-78.374891,40.9468],[-78.379845,40.936275]]]},"properties":{"id":"16666","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.396452,39.968971],[-75.3682,40.000982],[-75.379965,40.009295],[-75.386632,40.012837],[-75.404338,40.027364],[-75.423078,40.014381],[-75.460384,39.998597],[-75.496978,39.973256],[-75.500632,39.965099],[-75.504542,39.957474],[-75.443494,39.957245],[-75.396452,39.968971]]]},"properties":{"id":"19073","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.743178,40.216721],[-75.738548,40.148545],[-75.673951,40.150684],[-75.595964,40.205319],[-75.580747,40.205212],[-75.592657,40.225491],[-75.696782,40.241863],[-75.743178,40.216721]]]},"properties":{"id":"19465","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.972407,39.98751],[-75.930684,39.914175],[-75.915339,39.924234],[-75.885892,39.959746],[-75.889579,39.965567],[-75.93487,40.006899],[-75.972407,39.98751]]]},"properties":{"id":"19365","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.734591,39.895352],[-75.741809,39.906177],[-75.789627,39.885318],[-75.783679,39.858064],[-75.766385,39.846445],[-75.736675,39.82866],[-75.706135,39.799724],[-75.673269,39.817195],[-75.629023,39.887283],[-75.73226,39.930389],[-75.734542,39.904663],[-75.734591,39.895352]]]},"properties":{"id":"19348","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.734591,39.895352],[-75.734542,39.904663],[-75.741809,39.906177],[-75.734591,39.895352]]]},"properties":{"id":"19375","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.583483,40.131429],[-75.585163,40.129724],[-75.582399,40.128127],[-75.581074,40.129278],[-75.583483,40.131429]]]},"properties":{"id":"19442","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.822711,40.054902],[-75.862165,40.058079],[-75.82053,40.053896],[-75.822711,40.054902]]]},"properties":{"id":"19316","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.437801,41.020473],[-79.446413,41.098103],[-79.548956,41.096404],[-79.581319,40.99482],[-79.587071,40.974036],[-79.551331,40.980456],[-79.451718,40.98167],[-79.437801,41.020473]]]},"properties":{"id":"16248","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.264862,41.030179],[-79.29002,41.018118],[-79.281519,41.010364],[-79.272201,41.018151],[-79.264862,41.030179]]]},"properties":{"id":"16230","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.20976,41.23708],[-79.168824,41.225773],[-78.968845,41.316893],[-78.881384,41.45395],[-78.958989,41.452691],[-79.093765,41.41649],[-79.130634,41.294986],[-79.209262,41.303381],[-79.230532,41.293189],[-79.20976,41.23708]]]},"properties":{"id":"15860","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.48465,40.948112],[-78.472069,40.956833],[-78.465025,40.950105],[-78.48111,40.938717],[-78.403956,40.95943],[-78.379845,40.936275],[-78.374891,40.9468],[-78.395359,40.991102],[-78.373459,41.007381],[-78.28084,41.070278],[-78.414968,41.240151],[-78.484712,41.24394],[-78.546936,41.08651],[-78.55331,41.052906],[-78.501205,40.945116],[-78.48465,40.948112]],[[-78.471215,40.991338],[-78.465508,40.998581],[-78.47302,41.003175],[-78.467204,41.008397],[-78.457435,41.004582],[-78.471215,40.991338]]]},"properties":{"id":"16830","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.30175,40.978855],[-78.318542,40.983696],[-78.301583,40.977026],[-78.30175,40.978855]]]},"properties":{"id":"16825","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.62384,40.92448],[-78.661379,40.864876],[-78.602315,40.815182],[-78.551301,40.876014],[-78.537465,40.903654],[-78.501205,40.945116],[-78.55331,41.052906],[-78.593167,41.052611],[-78.62384,40.92448]]]},"properties":{"id":"16833","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.537465,40.903654],[-78.551301,40.876014],[-78.52655,40.826141],[-78.465205,40.855087],[-78.537465,40.903654]]]},"properties":{"id":"16861","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.446106,40.754513],[-78.35043,40.724827],[-78.359912,40.732592],[-78.374033,40.756652],[-78.41024,40.78087],[-78.412111,40.785868],[-78.424241,40.784138],[-78.425755,40.761828],[-78.446106,40.754513]]]},"properties":{"id":"16680","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.792878,40.946583],[-76.718785,40.922966],[-76.652581,40.867081],[-76.584815,40.879902],[-76.551792,40.883406],[-76.512421,40.946102],[-76.617433,41.144597],[-76.634517,41.136462],[-76.723904,41.084485],[-76.723296,41.061447],[-76.792878,40.946583]],[[-76.608796,40.967125],[-76.606187,40.969584],[-76.602052,40.969762],[-76.601421,40.967437],[-76.605954,40.966302],[-76.608796,40.967125]],[[-76.666023,41.063402],[-76.662491,41.053625],[-76.676088,41.054766],[-76.666023,41.063402]],[[-76.648091,40.961267],[-76.632036,40.963682],[-76.634559,40.955238],[-76.648091,40.961267]]]},"properties":{"id":"17821","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.390185,40.793254],[-76.356232,40.78415],[-76.380152,40.775511],[-76.410147,40.764874],[-76.469523,40.743188],[-76.469642,40.693821],[-76.393873,40.698454],[-76.268883,40.762045],[-76.283571,40.784077],[-76.313843,40.799415],[-76.350076,40.806603],[-76.392373,40.79755],[-76.390185,40.793254]],[[-76.382448,40.758437],[-76.389305,40.752279],[-76.400925,40.752016],[-76.401201,40.756308],[-76.382448,40.758437]],[[-76.342715,40.754822],[-76.325549,40.753929],[-76.342074,40.746638],[-76.342715,40.754822]]]},"properties":{"id":"17921","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.315152,41.654175],[-80.303126,41.666473],[-80.301971,41.674026],[-80.318979,41.674421],[-80.315152,41.654175]]]},"properties":{"id":"16422","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.08223,41.761205],[-80.143924,41.812523],[-80.182868,41.779532],[-80.08223,41.761205]]]},"properties":{"id":"16440","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.240893,41.801502],[-80.519352,41.807798],[-80.519413,41.738442],[-80.318979,41.674421],[-80.301971,41.674026],[-80.244307,41.752599],[-80.240893,41.801502]]]},"properties":{"id":"16406","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.463079,41.360927],[-80.344159,41.322528],[-80.221146,41.368517],[-80.214853,41.381834],[-80.262698,41.483436],[-80.355409,41.484902],[-80.380183,41.480679],[-80.519119,41.440733],[-80.519112,41.347846],[-80.51614,41.347838],[-80.463079,41.360927]],[[-80.262698,41.483436],[-80.235785,41.482792],[-80.23916,41.482842],[-80.262698,41.483436]]]},"properties":{"id":"16125","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.596088,40.078767],[-77.592987,40.03563],[-77.534704,39.953955],[-77.470967,39.944274],[-77.375281,40.024742],[-77.374355,40.053474],[-77.390687,40.092612],[-77.399695,40.090947],[-77.425414,40.074123],[-77.434946,40.081894],[-77.42124,40.086246],[-77.394887,40.101346],[-77.390747,40.09285],[-77.506982,40.146662],[-77.596088,40.078767]]]},"properties":{"id":"17257","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.147901,40.09916],[-77.148776,40.087593],[-77.055215,40.129061],[-77.085809,40.167869],[-77.157409,40.12147],[-77.147901,40.09916]]]},"properties":{"id":"17007","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.914977,40.271422],[-76.89377,40.234743],[-76.878871,40.244],[-76.88886,40.249019],[-76.904577,40.260572],[-76.913911,40.271938],[-76.914977,40.271422]]]},"properties":{"id":"17043","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.08106,40.235272],[-77.085843,40.233595],[-77.085657,40.232024],[-77.080345,40.23267],[-77.08106,40.235272]]]},"properties":{"id":"17072","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.629132,40.092821],[-77.596703,40.078741],[-77.596088,40.078767],[-77.506982,40.146662],[-77.541415,40.219283],[-77.614665,40.198549],[-77.622229,40.192445],[-77.669911,40.153714],[-77.672074,40.135527],[-77.629132,40.092821]]]},"properties":{"id":"17240","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.359864,39.91277],[-75.386079,39.888085],[-75.378389,39.874882],[-75.356501,39.872136],[-75.350413,39.881246],[-75.358306,39.910871],[-75.359864,39.91277]]]},"properties":{"id":"19086","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.300476,39.904611],[-75.314207,39.904963],[-75.315365,39.898098],[-75.313855,39.892788],[-75.306464,39.896121],[-75.302167,39.898041],[-75.300476,39.904611]]]},"properties":{"id":"19043","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.957926,41.54817],[-78.914977,41.547726],[-78.923713,41.581788],[-78.957418,41.579222],[-78.957926,41.54817]]]},"properties":{"id":"16728","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.513398,41.293674],[-78.516073,41.330575],[-78.52776,41.320085],[-78.513398,41.293674]]]},"properties":{"id":"15827","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.679155,41.339542],[-78.6445,41.253622],[-78.639706,41.25326],[-78.548054,41.270925],[-78.547897,41.280846],[-78.513398,41.293674],[-78.52776,41.320085],[-78.516073,41.330575],[-78.420201,41.335654],[-78.420057,41.398014],[-78.648271,41.397243],[-78.679155,41.339542]]]},"properties":{"id":"15846","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.090693,42.126171],[-80.114437,42.11911],[-80.115408,42.110966],[-80.114681,42.108357],[-80.125955,42.097754],[-80.12318,42.093633],[-80.07361,42.111919],[-80.077365,42.117625],[-80.090693,42.126171]]]},"properties":{"id":"16502","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.062596,42.095615],[-80.11072,42.077728],[-80.14257,42.040541],[-80.122171,42.007918],[-79.948605,42.075821],[-80.027778,42.102888],[-80.062596,42.095615]]]},"properties":{"id":"16509","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125955,42.097754],[-80.225814,42.053959],[-80.158636,42.035047],[-80.14257,42.040541],[-80.11072,42.077728],[-80.12318,42.093633],[-80.125955,42.097754]]]},"properties":{"id":"16506","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.888057,40.043241],[-79.911661,40.046349],[-79.934395,40.044593],[-79.963051,40.042786],[-79.982442,40.015259],[-79.974622,40.01386],[-79.956682,39.997012],[-79.932991,40.003187],[-79.90783,39.9662],[-79.89005,39.96507],[-79.87734,39.973048],[-79.879671,39.976798],[-79.87299,39.990778],[-79.866117,40.033909],[-79.888057,40.043241]],[[-79.916138,40.014042],[-79.898499,40.012749],[-79.909658,40.002858],[-79.916138,40.014042]]]},"properties":{"id":"15417","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.610284,40.149517],[-79.591702,40.150672],[-79.604026,40.170744],[-79.610284,40.149517]]],[[[-79.582741,40.179707],[-79.588207,40.150306],[-79.583464,40.127743],[-79.56151,40.09075],[-79.52381,40.09302],[-79.50693,40.09282],[-79.459465,40.087847],[-79.441116,40.191164],[-79.43377,40.20171],[-79.480564,40.225968],[-79.491507,40.217995],[-79.49533,40.21981],[-79.549955,40.223173],[-79.550104,40.223077],[-79.582741,40.179707]],[[-79.48405,40.21409],[-79.48171,40.21419],[-79.48177,40.21376],[-79.48402,40.21367],[-79.48405,40.21409]]]]},"properties":{"id":"15666","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.51155,40.06035],[-79.421281,40.047937],[-79.40041,40.05303],[-79.395322,40.055011],[-79.39899,40.06773],[-79.459465,40.087847],[-79.50693,40.09282],[-79.503196,40.087512],[-79.51155,40.06035]]]},"properties":{"id":"15490","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.63533,39.9023],[-79.6823,39.90306],[-79.682091,39.818358],[-79.620342,39.864764],[-79.585019,39.887374],[-79.63533,39.9023]]]},"properties":{"id":"15445","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.76671,40.04855],[-79.75139,40.062532],[-79.770899,40.072169],[-79.775716,40.056172],[-79.76671,40.04855]]]},"properties":{"id":"15482","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.930539,39.93898],[-79.936521,39.951513],[-79.94531,39.94757],[-79.93206,39.9326],[-79.930539,39.93898]]]},"properties":{"id":"15447","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.92408,39.86849],[-79.92086,39.86968],[-79.91792,39.86889],[-79.9194,39.87156],[-79.92437,39.8692],[-79.92408,39.86849]]]},"properties":{"id":"15476","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.957418,41.579222],[-79.25582,41.545037],[-79.320159,41.432156],[-79.238227,41.431343],[-79.208226,41.367302],[-79.208413,41.355954],[-79.138479,41.342176],[-79.093765,41.41649],[-78.958989,41.452691],[-78.957926,41.54817],[-78.957418,41.579222]]]},"properties":{"id":"16239","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.315575,41.790786],[-79.4828,41.696144],[-79.493454,41.624716],[-79.441743,41.578769],[-79.405667,41.570011],[-79.404445,41.569963],[-79.403287,41.584264],[-79.361113,41.626416],[-79.210193,41.626422],[-79.210244,41.651556],[-79.245842,41.681061],[-79.252999,41.687035],[-79.315092,41.741395],[-79.286729,41.78782],[-79.315575,41.790786]]]},"properties":{"id":"16351","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.525515,39.73631],[-77.523144,39.737397],[-77.524233,39.737649],[-77.525515,39.73631]]]},"properties":{"id":"17250","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.580475,39.974216],[-77.583458,39.976918],[-77.602953,39.965902],[-77.591138,39.966337],[-77.580475,39.974216]]]},"properties":{"id":"17254","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.830436,40.112865],[-77.736215,40.101606],[-77.706301,40.117974],[-77.669911,40.153714],[-77.622229,40.192445],[-77.704023,40.177883],[-77.781443,40.186633],[-77.805177,40.153006],[-77.830436,40.112865]]]},"properties":{"id":"17262","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.084818,40.134207],[-78.132932,40.164159],[-78.151112,40.135785],[-78.158214,40.122383],[-78.084818,40.134207]]],[[[-78.077247,40.133037],[-78.113525,40.035538],[-78.11702,40.029671],[-78.072127,40.052496],[-78.064317,40.063862],[-78.044192,40.112451],[-78.046842,40.126574],[-78.077247,40.133037]]]]},"properties":{"id":"16689","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.084818,40.134207],[-78.078353,40.133244],[-78.042566,40.2263],[-78.036211,40.261693],[-78.090142,40.21418],[-78.148137,40.177302],[-78.132932,40.164159],[-78.084818,40.134207]]]},"properties":{"id":"16674","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.068691,40.56073],[-78.10938,40.613158],[-78.122085,40.597293],[-78.183176,40.595315],[-78.144267,40.491321],[-78.068691,40.56073]]]},"properties":{"id":"16611","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.125724,40.910561],[-79.171964,40.910884],[-79.196783,40.828145],[-79.131276,40.817469],[-79.08164,40.838796],[-79.061976,40.876643],[-79.054958,40.909669],[-79.081826,40.909956],[-79.125724,40.910561]]]},"properties":{"id":"16256","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.173859,40.499089],[-79.169159,40.503084],[-79.174761,40.504139],[-79.179194,40.498957],[-79.173859,40.499089]]]},"properties":{"id":"15731","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.152185,40.564012],[-79.159088,40.553272],[-79.140432,40.557671],[-79.152185,40.564012]]]},"properties":{"id":"15754","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.875605,40.636445],[-78.85892,40.644283],[-78.897995,40.636191],[-78.875605,40.636445]]]},"properties":{"id":"15710","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.934186,40.620448],[-78.901359,40.613372],[-78.902667,40.627442],[-78.908403,40.628765],[-78.934186,40.620448]]]},"properties":{"id":"15745","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.932966,40.738736],[-78.949838,40.709349],[-78.955042,40.69987],[-78.894719,40.672639],[-78.867129,40.732176],[-78.932966,40.738736]]]},"properties":{"id":"15729","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.204894,40.804677],[-79.215002,40.784673],[-79.215015,40.784196],[-79.190077,40.788894],[-79.189842,40.789883],[-79.204894,40.804677]]]},"properties":{"id":"16211","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.256345,40.558461],[-79.260641,40.585282],[-79.273847,40.566071],[-79.256345,40.558461]]]},"properties":{"id":"15713","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.903232,40.760989],[-78.877533,40.748205],[-78.882667,40.765607],[-78.903232,40.760989]]]},"properties":{"id":"15746","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.804349,41.062642],[-78.845372,41.026784],[-78.804199,41.040156],[-78.804349,41.062642]]]},"properties":{"id":"15865","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.054958,40.909669],[-79.051159,40.924481],[-79.081826,40.909956],[-79.054958,40.909669]]]},"properties":{"id":"15780","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.143301,40.642457],[-77.269737,40.691271],[-77.36069,40.669991],[-77.304047,40.622191],[-77.312653,40.611853],[-77.239735,40.611161],[-77.141376,40.638493],[-77.143301,40.642457]]]},"properties":{"id":"17049","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.468999,40.624746],[-77.551258,40.549523],[-77.521166,40.533067],[-77.486438,40.548731],[-77.379913,40.532833],[-77.302344,40.53453],[-77.239735,40.611161],[-77.312653,40.611853],[-77.312984,40.617549],[-77.304047,40.622191],[-77.36069,40.669991],[-77.468999,40.624746]]]},"properties":{"id":"17059","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.51518,40.470084],[-77.654295,40.444042],[-77.643989,40.420226],[-77.593455,40.351205],[-77.580621,40.358556],[-77.506152,40.384571],[-77.515369,40.402942],[-77.500236,40.420461],[-77.51518,40.470084]]]},"properties":{"id":"17035","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.636353,41.449725],[-75.638833,41.41848],[-75.583884,41.396221],[-75.543209,41.427616],[-75.614216,41.46367],[-75.636311,41.449764],[-75.636353,41.449725]]]},"properties":{"id":"18512","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.59242,41.493959],[-75.587283,41.490694],[-75.561302,41.480116],[-75.517954,41.449137],[-75.467679,41.533108],[-75.501526,41.531646],[-75.588674,41.520707],[-75.592566,41.494051],[-75.59242,41.493959]]]},"properties":{"id":"18403","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.130442,39.782676],[-76.228683,39.798471],[-76.262689,39.754288],[-76.239805,39.721335],[-76.135484,39.721147],[-76.130442,39.782676]]]},"properties":{"id":"17563","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.06799,39.890062],[-76.066845,39.807914],[-76.020413,39.846609],[-76.06799,39.890062]]]},"properties":{"id":"17536","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.405188,39.973182],[-76.392115,39.912749],[-76.387946,39.908654],[-76.295305,39.930644],[-76.300788,39.953585],[-76.359071,39.962646],[-76.405188,39.973182]]]},"properties":{"id":"17516","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.392115,39.912749],[-76.405188,39.973182],[-76.397128,39.994819],[-76.452015,40.0093],[-76.4972,39.989031],[-76.45355,39.937544],[-76.404929,39.915213],[-76.392115,39.912749]]]},"properties":{"id":"17582","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.359071,39.962646],[-76.397128,39.994819],[-76.405188,39.973182],[-76.359071,39.962646]]]},"properties":{"id":"17551","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.176929,39.917726],[-76.214307,39.944962],[-76.269869,39.920865],[-76.272566,39.898495],[-76.249842,39.88697],[-76.176929,39.917726]]]},"properties":{"id":"17560","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.248281,40.105677],[-76.220839,40.088577],[-76.208333,40.066505],[-76.170256,40.061818],[-76.156906,40.063073],[-76.156344,40.062756],[-76.147002,40.064418],[-76.112777,40.079182],[-76.145624,40.108194],[-76.213788,40.120193],[-76.213481,40.120857],[-76.224189,40.120651],[-76.231343,40.130301],[-76.248281,40.105677]]]},"properties":{"id":"17540","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.402911,41.036863],[-80.26672,41.060729],[-80.319006,41.094295],[-80.419799,41.096401],[-80.404906,41.085847],[-80.402911,41.036863]]]},"properties":{"id":"16105","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.403401,41.157478],[-80.419799,41.096401],[-80.319006,41.094295],[-80.256781,41.147114],[-80.267754,41.185482],[-80.3732,41.197581],[-80.406618,41.161133],[-80.403401,41.157478]]]},"properties":{"id":"16142","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519013,40.994109],[-80.519009,40.921601],[-80.487119,40.95466],[-80.483551,40.994611],[-80.49544,40.994402],[-80.519013,40.994109]]]},"properties":{"id":"16112","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.22586,41.157598],[-80.256781,41.147114],[-80.319006,41.094295],[-80.26672,41.060729],[-80.229054,41.051084],[-80.096861,41.069804],[-80.145447,41.112267],[-80.223693,41.151147],[-80.22586,41.157598]]]},"properties":{"id":"16156","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.502947,41.061536],[-80.518937,41.071753],[-80.518929,41.063027],[-80.502947,41.061536]]]},"properties":{"id":"16155","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.413743,40.272634],[-76.393346,40.275995],[-76.40255,40.284002],[-76.413743,40.272634]]]},"properties":{"id":"17016","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.557382,40.703692],[-75.544252,40.714577],[-75.555541,40.72092],[-75.608985,40.787387],[-75.609068,40.787523],[-75.691183,40.771216],[-75.69609,40.768898],[-75.69938,40.76612],[-75.650223,40.69347],[-75.557382,40.703692]],[[-75.663663,40.738451],[-75.663838,40.745351],[-75.65012,40.744667],[-75.660437,40.74102],[-75.663663,40.738451]]]},"properties":{"id":"18080","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.448329,40.5975],[-75.467383,40.597671],[-75.471466,40.597247],[-75.482433,40.591072],[-75.551515,40.56722],[-75.54145,40.554657],[-75.531681,40.543755],[-75.468447,40.537222],[-75.450102,40.560325],[-75.446163,40.56283],[-75.426061,40.570482],[-75.441851,40.599996],[-75.448329,40.5975]]]},"properties":{"id":"18103","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.07875,41.014346],[-76.008057,40.999844],[-75.959463,41.005384],[-75.956268,41.005315],[-75.931789,41.011514],[-75.92312,41.011453],[-75.914491,41.04277],[-75.927643,41.088504],[-76.015355,41.059123],[-76.053853,41.057881],[-76.141015,41.022957],[-76.08073,41.015286],[-76.07875,41.014346]]]},"properties":{"id":"18222","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.976635,40.987487],[-75.97559,40.980559],[-75.975554,40.980934],[-75.9749,40.987477],[-75.976635,40.987487]]]},"properties":{"id":"18239","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.207827,40.94974],[-76.188707,40.944737],[-76.18191,40.958922],[-76.184632,40.974075],[-76.207827,40.94974]]]},"properties":{"id":"18246","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.894088,41.230356],[-77.042125,41.167649],[-76.896114,41.13907],[-76.880963,41.158044],[-76.894088,41.230356]]]},"properties":{"id":"17752","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.879129,41.545674],[-76.853849,41.543731],[-76.798073,41.544528],[-76.813731,41.590034],[-77.015906,41.645436],[-77.04576,41.638846],[-77.100619,41.521761],[-77.049492,41.490897],[-77.013013,41.542227],[-76.879129,41.545674]]]},"properties":{"id":"17765","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.879129,41.545674],[-77.013013,41.542227],[-77.049492,41.490897],[-76.879129,41.545674]]]},"properties":{"id":"17763","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.536085,41.715677],[-78.419299,41.616853],[-78.326103,41.617538],[-78.299733,41.677787],[-78.378665,41.746536],[-78.355301,41.730811],[-78.365726,41.722021],[-78.38834,41.728979],[-78.377854,41.747274],[-78.338605,41.851268],[-78.364419,41.884178],[-78.460431,41.907776],[-78.532657,41.903836],[-78.532642,41.89994],[-78.549638,41.880173],[-78.56048,41.880135],[-78.551336,41.843758],[-78.564251,41.762649],[-78.604134,41.739009],[-78.619643,41.723728],[-78.536085,41.715677]],[[-78.424065,41.80889],[-78.427839,41.819711],[-78.416952,41.823728],[-78.424065,41.80889]]]},"properties":{"id":"16749","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.591307,41.762349],[-78.769848,41.760312],[-78.649178,41.688366],[-78.621277,41.723917],[-78.621233,41.723912],[-78.619643,41.723728],[-78.604134,41.739009],[-78.591307,41.762349]]],[[[-78.537526,41.68877],[-78.419118,41.602188],[-78.419299,41.616853],[-78.536085,41.715677],[-78.537526,41.68877]]]]},"properties":{"id":"16740","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.621277,41.723917],[-78.649178,41.688366],[-78.537526,41.68877],[-78.536085,41.715677],[-78.619643,41.723728],[-78.621233,41.723912],[-78.621277,41.723917]]]},"properties":{"id":"16733","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.510315,41.999856],[-78.535005,41.958916],[-78.493658,41.947447],[-78.44286,41.934199],[-78.491691,41.99983],[-78.510315,41.999856]]]},"properties":{"id":"16729","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.485395,41.217348],[-80.519106,41.243108],[-80.518893,41.219357],[-80.485395,41.217348]]]},"properties":{"id":"16146","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.463079,41.360927],[-80.51614,41.347838],[-80.442669,41.286698],[-80.427168,41.290777],[-80.328016,41.287596],[-80.344159,41.322528],[-80.463079,41.360927]]]},"properties":{"id":"16154","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.158689,41.312861],[-80.045011,41.249469],[-79.990864,41.250577],[-79.971583,41.252432],[-79.99978,41.33348],[-80.158539,41.388652],[-80.17862,41.369635],[-80.206974,41.33157],[-80.158689,41.312861]]]},"properties":{"id":"16153","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.485395,41.217348],[-80.518893,41.219357],[-80.518793,41.20993],[-80.505505,41.198831],[-80.486629,41.202845],[-80.485395,41.217348]]]},"properties":{"id":"16121","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.486629,41.202845],[-80.505505,41.198831],[-80.489626,41.189506],[-80.486629,41.202845]]]},"properties":{"id":"16161","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.999789,41.463844],[-80.09043,41.436514],[-80.05307,41.419684],[-79.999789,41.463844]]]},"properties":{"id":"16311","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.842234,41.323803],[-79.914849,41.397662],[-79.999779,41.388372],[-79.99978,41.33348],[-79.971583,41.252432],[-79.956396,41.232361],[-79.954897,41.230986],[-79.842234,41.323803]]]},"properties":{"id":"16342","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.257236,41.188541],[-75.26062,41.160266],[-75.260762,41.159605],[-75.150591,41.172713],[-75.140559,41.210437],[-75.129721,41.252101],[-75.190004,41.248782],[-75.195684,41.224768],[-75.241533,41.206373],[-75.239754,41.233609],[-75.19429,41.248544],[-75.247382,41.278591],[-75.283484,41.265563],[-75.291018,41.254759],[-75.29591,41.242873],[-75.314382,41.219925],[-75.305257,41.201126],[-75.258458,41.189719],[-75.257236,41.188541]]]},"properties":{"id":"18325","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.313021,41.0885],[-75.384663,41.096768],[-75.378453,41.111571],[-75.383109,41.112663],[-75.397564,41.106106],[-75.395507,41.070825],[-75.376215,41.06323],[-75.309068,41.04495],[-75.313021,41.0885]]]},"properties":{"id":"18355","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.376215,41.06323],[-75.303032,41.02372],[-75.275117,41.034731],[-75.282641,41.057083],[-75.309068,41.04495],[-75.376215,41.06323]]]},"properties":{"id":"18372","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.303032,41.02372],[-75.318609,41.011753],[-75.314462,41.004751],[-75.276306,41.005916],[-75.275117,41.034731],[-75.303032,41.02372]]]},"properties":{"id":"18321","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.354037,41.105721],[-75.384663,41.096768],[-75.313021,41.0885],[-75.29563,41.090993],[-75.306507,41.106033],[-75.354037,41.105721]]]},"properties":{"id":"18370","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.506038,41.077123],[-75.526716,41.076236],[-75.437519,41.013244],[-75.416087,41.022092],[-75.395507,41.070825],[-75.397564,41.106106],[-75.431122,41.103],[-75.506038,41.077123]]]},"properties":{"id":"18334","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.279488,41.172999],[-75.260762,41.159605],[-75.26062,41.160266],[-75.279488,41.172999]]]},"properties":{"id":"18342","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.19429,41.248544],[-75.239754,41.233609],[-75.241533,41.206373],[-75.195684,41.224768],[-75.190004,41.248782],[-75.19429,41.248544]]]},"properties":{"id":"18357","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.2792,40.022586],[-75.262735,40.00063],[-75.24541,40.017369],[-75.228149,40.026031],[-75.249181,40.041951],[-75.255098,40.046122],[-75.2792,40.022586]]]},"properties":{"id":"19072","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.347487,40.109508],[-75.32473,40.103055],[-75.324983,40.103268],[-75.326924,40.105214],[-75.346892,40.111097],[-75.347487,40.109508]]]},"properties":{"id":"19405","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.397411,40.222936],[-75.372225,40.214061],[-75.349838,40.261055],[-75.369879,40.300319],[-75.448903,40.325169],[-75.472544,40.322448],[-75.454722,40.27814],[-75.402951,40.22574],[-75.397411,40.222936]]]},"properties":{"id":"19438","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.139374,40.211853],[-75.152756,40.220028],[-75.15185,40.21206],[-75.139374,40.211853]]],[[[-75.154053,40.163486],[-75.143155,40.156923],[-75.127729,40.171641],[-75.139245,40.211775],[-75.139374,40.211853],[-75.162372,40.213873],[-75.154053,40.163486]]]]},"properties":{"id":"19044","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.154053,40.163486],[-75.186259,40.152561],[-75.170256,40.126897],[-75.16859,40.127008],[-75.156232,40.129927],[-75.146682,40.137413],[-75.143155,40.156923],[-75.154053,40.163486]]]},"properties":{"id":"19025","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.576866,40.333565],[-75.566035,40.319421],[-75.56914,40.337885],[-75.576866,40.333565]]]},"properties":{"id":"19435","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.545918,40.746108],[-75.555364,40.736354],[-75.545601,40.729207],[-75.54322,40.740905],[-75.545918,40.746108]]]},"properties":{"id":"18086","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.534874,40.752972],[-75.545918,40.746108],[-75.54322,40.740905],[-75.531446,40.744414],[-75.534874,40.752972]]]},"properties":{"id":"18035","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.639758,40.834292],[-76.696437,40.812182],[-76.749814,40.776038],[-76.720369,40.781702],[-76.629278,40.798835],[-76.585018,40.808978],[-76.560931,40.814128],[-76.524797,40.822995],[-76.584815,40.879902],[-76.652581,40.867081],[-76.639758,40.834292]]]},"properties":{"id":"17860","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.58275,40.702282],[-76.518149,40.674267],[-76.469642,40.693821],[-76.469523,40.743188],[-76.573553,40.731598],[-76.559983,40.721677],[-76.58275,40.702282]]]},"properties":{"id":"17964","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.545897,40.793702],[-76.484913,40.759423],[-76.488127,40.797132],[-76.490634,40.825198],[-76.524797,40.822995],[-76.560931,40.814128],[-76.585018,40.808978],[-76.629278,40.798835],[-76.625721,40.79107],[-76.622797,40.784762],[-76.620922,40.780753],[-76.545897,40.793702]]]},"properties":{"id":"17866","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.637433,40.707795],[-76.58275,40.702282],[-76.559983,40.721677],[-76.648144,40.719657],[-76.647077,40.718113],[-76.644438,40.710553],[-76.637433,40.707795]]]},"properties":{"id":"17836","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.648091,40.961267],[-76.634559,40.955238],[-76.632036,40.963682],[-76.648091,40.961267]]]},"properties":{"id":"17868","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.095191,39.998443],[-75.096184,40.000241],[-75.127287,40.007695],[-75.12926,39.998539],[-75.130787,39.991397],[-75.112815,39.968358],[-75.091452,39.974662],[-75.095191,39.998443]]]},"properties":{"id":"19134","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.250658,39.948445],[-75.236833,39.933768],[-75.219196,39.923409],[-75.212428,39.92523],[-75.206072,39.935371],[-75.205185,39.941116],[-75.21217,39.952874],[-75.249499,39.957189],[-75.250658,39.948445]]]},"properties":{"id":"19143","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.040594,40.036859],[-75.074944,40.026691],[-75.061339,40.012764],[-75.052225,40.00546],[-75.031914,40.015831],[-75.040594,40.036859]]]},"properties":{"id":"19135","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.168423,39.930465],[-75.206072,39.935371],[-75.212428,39.92523],[-75.195514,39.89184],[-75.174959,39.898488],[-75.168423,39.930465]]]},"properties":{"id":"19145","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.164338,39.949346],[-75.163137,39.949205],[-75.162965,39.949962],[-75.164174,39.950113],[-75.164338,39.949346]]]},"properties":{"id":"19109","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.299339,41.441362],[-75.200599,41.458443],[-75.186521,41.458879],[-75.183793,41.429569],[-75.192436,41.359792],[-75.129721,41.252101],[-75.140559,41.210437],[-75.015989,41.288219],[-75.008259,41.349211],[-75.004928,41.368688],[-75.047533,41.463895],[-75.053556,41.479276],[-75.035771,41.475308],[-75.029007,41.483399],[-75.033859,41.540168],[-75.164581,41.566737],[-75.208266,41.537011],[-75.199241,41.530571],[-75.221384,41.516276],[-75.30019,41.484231],[-75.299339,41.441362]]]},"properties":{"id":"18428","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.015989,41.288219],[-74.838762,41.276472],[-74.722389,41.347104],[-74.763971,41.409359],[-74.815223,41.440325],[-75.008259,41.349211],[-75.015989,41.288219]]]},"properties":{"id":"18337","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.048693,41.656148],[-75.133684,41.632213],[-75.164581,41.566737],[-75.033859,41.540168],[-75.024909,41.539225],[-75.048693,41.656148]]]},"properties":{"id":"18405","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.992025,40.908051],[-76.024516,40.903299],[-76.001013,40.899615],[-75.992025,40.908051]]]},"properties":{"id":"18231","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.24702,40.602019],[-76.242498,40.60925],[-76.239671,40.601914],[-76.245158,40.601993],[-76.24011,40.520529],[-76.203562,40.530527],[-76.155384,40.544955],[-76.189864,40.568029],[-76.191631,40.556063],[-76.208343,40.550251],[-76.204514,40.571519],[-76.110246,40.630273],[-76.125185,40.68576],[-76.128346,40.692253],[-76.18441,40.631414],[-76.19866,40.624656],[-76.246802,40.614439],[-76.24702,40.602019]]]},"properties":{"id":"17972","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.068869,40.696574],[-75.994632,40.635534],[-75.841437,40.694121],[-75.877256,40.765731],[-76.068869,40.696574]]]},"properties":{"id":"17960","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.038516,40.582323],[-76.012226,40.577713],[-76.005799,40.598681],[-76.02734,40.587168],[-76.038516,40.582323]]]},"properties":{"id":"19549","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.168283,40.691315],[-76.155152,40.696267],[-76.160918,40.707643],[-76.178215,40.696919],[-76.168283,40.691315]]]},"properties":{"id":"17965","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.9441,40.806793],[-76.935268,40.854973],[-77.012643,40.866039],[-77.052914,40.869329],[-77.155489,40.848858],[-77.112335,40.727897],[-77.060084,40.748021],[-76.985997,40.742937],[-76.904491,40.773307],[-76.9441,40.806793]],[[-77.084423,40.769115],[-77.087776,40.769816],[-77.08231,40.775915],[-77.082059,40.768442],[-77.084423,40.769115]],[[-77.057679,40.857385],[-77.05022,40.865741],[-77.04411,40.862916],[-77.057679,40.857385]]]},"properties":{"id":"17842","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.962433,40.744992],[-76.904491,40.773307],[-76.985997,40.742937],[-76.962433,40.744992]]]},"properties":{"id":"17827","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.912075,40.019435],[-78.902036,40.017598],[-78.907002,40.019313],[-78.912075,40.019435]]]},"properties":{"id":"15560","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.079347,40.09401],[-79.090298,40.101176],[-79.096633,40.091178],[-79.085068,40.090626],[-79.08525,40.094263],[-79.079347,40.09401]]]},"properties":{"id":"15561","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.067752,39.768382],[-79.062261,39.753262],[-79.054391,39.769432],[-79.067752,39.768382]]]},"properties":{"id":"15532","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.694374,41.579883],[-76.813731,41.590034],[-76.798073,41.544528],[-76.78823,41.517128],[-76.780467,41.495682],[-76.69373,41.535754],[-76.677861,41.578541],[-76.694374,41.579883]]]},"properties":{"id":"17768","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.010278,41.681757],[-76.95275,41.698077],[-76.952063,41.73691],[-76.995417,41.747827],[-77.177484,41.730493],[-77.124927,41.695978],[-77.113289,41.696214],[-77.016911,41.697773],[-77.010278,41.681757]]]},"properties":{"id":"16917","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.330049,41.999847],[-77.26874,41.961958],[-77.241195,41.972651],[-77.256888,41.999653],[-77.330049,41.999847]]]},"properties":{"id":"16920","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.053796,41.916653],[-79.26048,41.954925],[-79.301636,41.930684],[-79.30911,41.924817],[-79.283541,41.883355],[-79.276606,41.873886],[-79.275876,41.873493],[-79.230713,41.792608],[-79.315092,41.741395],[-79.252999,41.687035],[-79.245842,41.681061],[-79.210244,41.651556],[-78.949961,41.79621],[-78.951365,41.891568],[-79.053796,41.916653]]]},"properties":{"id":"16365","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.016178,41.752412],[-79.003064,41.79646],[-79.081788,41.776132],[-79.077089,41.736543],[-79.016178,41.752412]]]},"properties":{"id":"16352","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.183462,40.332774],[-80.196639,40.313034],[-80.170238,40.328624],[-80.183462,40.332774]]]},"properties":{"id":"15321","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.25457,40.07542],[-80.1887,40.002782],[-80.162894,40.003858],[-80.12073,40.064624],[-80.148661,40.10077],[-80.25457,40.07542]]]},"properties":{"id":"15311","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.200509,40.290881],[-80.198994,40.291007],[-80.199595,40.293818],[-80.200937,40.293369],[-80.200509,40.290881]]]},"properties":{"id":"15350","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.024417,40.063352],[-80.020381,40.057831],[-80.015772,40.061165],[-80.020994,40.072958],[-80.024417,40.063352]]]},"properties":{"id":"15313","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.04098,40.110641],[-80.05883,40.098748],[-80.070783,40.097839],[-80.136933,40.125638],[-80.143317,40.112948],[-80.148709,40.100801],[-80.148661,40.10077],[-80.12073,40.064624],[-80.055174,40.031534],[-80.024417,40.063352],[-80.020994,40.072958],[-80.015299,40.098267],[-80.031438,40.108997],[-80.04098,40.110641]]]},"properties":{"id":"15360","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.031438,40.108997],[-80.015299,40.098267],[-80.015118,40.098296],[-80.031438,40.108997]]]},"properties":{"id":"15331","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.362886,41.869126],[-75.30324,41.848076],[-75.277038,41.894578],[-75.337458,41.890063],[-75.362886,41.869126]]]},"properties":{"id":"18437","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.417379,41.822909],[-75.414218,41.848438],[-75.418853,41.852303],[-75.445292,41.82474],[-75.440254,41.814459],[-75.417379,41.822909]]]},"properties":{"id":"18454","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.103964,41.779135],[-75.176569,41.735144],[-75.165796,41.699165],[-75.162693,41.69853],[-75.085001,41.688407],[-75.066708,41.705003],[-75.103964,41.779135]]]},"properties":{"id":"18415","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.277038,41.894578],[-75.30324,41.848076],[-75.271237,41.797837],[-75.250935,41.762837],[-75.185575,41.720226],[-75.176569,41.735144],[-75.103964,41.779135],[-75.272581,41.893168],[-75.277038,41.894578]]]},"properties":{"id":"18417","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.387588,41.67061],[-75.382894,41.6612],[-75.363796,41.646781],[-75.361288,41.646252],[-75.376062,41.674465],[-75.387588,41.67061]]],[[[-75.348933,41.635317],[-75.343854,41.661046],[-75.35984,41.644048],[-75.348933,41.635317]]],[[[-75.348871,41.635169],[-75.337829,41.578332],[-75.334281,41.600657],[-75.348871,41.635169]]]]},"properties":{"id":"18456","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.379344,40.115918],[-79.379623,40.085339],[-79.350434,40.085378],[-79.349953,40.098034],[-79.379344,40.115918]]]},"properties":{"id":"15628","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.594947,40.173624],[-79.604026,40.170744],[-79.591702,40.150672],[-79.588207,40.150306],[-79.582741,40.179707],[-79.594947,40.173624]]]},"properties":{"id":"15688","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.615637,40.208717],[-79.594947,40.173624],[-79.582741,40.179707],[-79.550104,40.223077],[-79.565139,40.227562],[-79.58836,40.227535],[-79.615637,40.208717]]]},"properties":{"id":"15639","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.480564,40.225968],[-79.43377,40.20171],[-79.340436,40.189344],[-79.337563,40.190256],[-79.31767,40.30944],[-79.332141,40.317164],[-79.35359,40.318772],[-79.357874,40.324894],[-79.406058,40.358145],[-79.460666,40.354977],[-79.467372,40.3561],[-79.466443,40.25024],[-79.463489,40.233713],[-79.478868,40.233629],[-79.480564,40.225968]],[[-79.411057,40.253184],[-79.408828,40.255925],[-79.404127,40.253081],[-79.406968,40.250116],[-79.411057,40.253184]],[[-79.369237,40.281427],[-79.36079,40.281282],[-79.365026,40.27699],[-79.369237,40.281427]],[[-79.406259,40.264729],[-79.398884,40.265019],[-79.393047,40.267655],[-79.393271,40.262824],[-79.407392,40.261921],[-79.406259,40.264729]]]},"properties":{"id":"15650","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.65158,40.335337],[-79.66028,40.3423],[-79.671105,40.335848],[-79.67179,40.335609],[-79.67098,40.32978],[-79.647766,40.329031],[-79.65158,40.335337]]]},"properties":{"id":"15665","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.805015,40.241106],[-79.779812,40.229675],[-79.774324,40.253284],[-79.775139,40.287888],[-79.805015,40.241106]]]},"properties":{"id":"15083","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.122157,41.611102],[-76.214235,41.568033],[-76.220139,41.541285],[-76.206499,41.37862],[-76.188883,41.379097],[-76.053847,41.467952],[-76.058935,41.577848],[-76.122157,41.611102]]]},"properties":{"id":"18629","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.615191,39.721202],[-76.646805,39.828763],[-76.674899,39.789371],[-76.688492,39.758003],[-76.703338,39.754578],[-76.757796,39.720902],[-76.615191,39.721202]]]},"properties":{"id":"17349","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.762604,39.976624],[-76.75874,39.931899],[-76.746036,39.949441],[-76.726281,39.974062],[-76.724959,39.979632],[-76.737054,40.000692],[-76.749591,40.00728],[-76.781706,40.077903],[-76.803035,40.004952],[-76.799009,40.000845],[-76.79889,40.000697],[-76.793848,39.999984],[-76.762604,39.976624]]]},"properties":{"id":"17404","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.843334,39.817033],[-76.846052,39.815384],[-76.845357,39.813862],[-76.841192,39.817287],[-76.842145,39.81919],[-76.843752,39.81833],[-76.843334,39.817033]]]},"properties":{"id":"17311","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.396276,39.721246],[-76.239805,39.721335],[-76.262689,39.754288],[-76.306073,39.799122],[-76.411794,39.746448],[-76.396276,39.721246]]]},"properties":{"id":"17314","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.049143,39.934449],[-77.096854,39.947314],[-77.134084,39.936745],[-77.082361,39.824052],[-77.006255,39.865366],[-77.018969,39.909372],[-77.049143,39.934449]]]},"properties":{"id":"17350","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.144622,39.952299],[-77.134084,39.936745],[-77.096854,39.947314],[-77.042641,40.006982],[-77.137425,40.069945],[-77.154733,39.980424],[-77.144622,39.952299]]]},"properties":{"id":"17372","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.038637,39.719899],[-77.109173,39.806226],[-77.200217,39.719844],[-77.038637,39.719899]]]},"properties":{"id":"17340","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.066507,40.416706],[-80.05103,40.44922],[-80.083913,40.459466],[-80.171791,40.458528],[-80.172161,40.451795],[-80.159874,40.432655],[-80.066507,40.416706]]]},"properties":{"id":"15205","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.995076,40.384696],[-79.993999,40.375839],[-79.95595,40.356932],[-79.942391,40.372784],[-79.95872,40.409824],[-79.995076,40.384696]]]},"properties":{"id":"15227","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.934946,40.242058],[-79.970652,40.231398],[-79.938683,40.209814],[-79.924513,40.243288],[-79.934946,40.242058]]]},"properties":{"id":"15020","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.814993,40.401185],[-79.773672,40.394888],[-79.769288,40.388434],[-79.722151,40.409098],[-79.703834,40.443526],[-79.779423,40.460961],[-79.807406,40.425386],[-79.814993,40.401185]],[[-79.779394,40.41111],[-79.769731,40.404232],[-79.783102,40.400898],[-79.779394,40.41111]]]},"properties":{"id":"15146","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.802594,40.391419],[-79.773672,40.394888],[-79.814993,40.401185],[-79.818568,40.40058],[-79.811517,40.386687],[-79.802594,40.391419]]]},"properties":{"id":"15148","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.920571,40.448319],[-79.938306,40.461947],[-79.943082,40.454566],[-79.937986,40.446943],[-79.920571,40.448319]]]},"properties":{"id":"15232","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.138159,40.35486],[-80.163055,40.360165],[-80.140437,40.35289],[-80.138159,40.35486]]]},"properties":{"id":"15064","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.876562,40.402272],[-79.908844,40.376692],[-79.940651,40.374682],[-79.942391,40.372784],[-79.95595,40.356932],[-79.942203,40.321135],[-79.893858,40.339983],[-79.876676,40.354337],[-79.847944,40.356932],[-79.854501,40.38877],[-79.876562,40.402272]]]},"properties":{"id":"15122","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.018303,40.58526],[-79.993874,40.554004],[-79.915228,40.567494],[-79.879336,40.5872],[-79.873399,40.600424],[-80.01978,40.613611],[-80.018303,40.58526]]]},"properties":{"id":"15101","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.053642,40.385248],[-80.058064,40.391948],[-80.062072,40.39238],[-80.064301,40.393243],[-80.071555,40.385451],[-80.090413,40.381981],[-80.095184,40.363368],[-80.068558,40.362159],[-80.053642,40.385248]]]},"properties":{"id":"15243","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.923423,40.254896],[-79.904198,40.271885],[-79.905468,40.274788],[-79.959829,40.279486],[-80.038165,40.289194],[-80.054502,40.243865],[-80.036628,40.181437],[-79.998215,40.194909],[-79.981492,40.207395],[-79.970652,40.231398],[-79.934946,40.242058],[-79.927749,40.24401],[-79.923423,40.254896]]]},"properties":{"id":"15332","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.56517,40.751367],[-79.606002,40.754272],[-79.58734,40.7458],[-79.56517,40.751367]]]},"properties":{"id":"16212","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.691295,40.894816],[-79.648346,40.902613],[-79.587071,40.974036],[-79.581319,40.99482],[-79.609757,41.009165],[-79.64889,40.98811],[-79.691295,40.894816]]]},"properties":{"id":"16028","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.303268,40.716153],[-80.163789,40.772475],[-80.170789,40.772331],[-80.220547,40.791418],[-80.234919,40.783971],[-80.324728,40.723659],[-80.303268,40.716153]]]},"properties":{"id":"15066","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.335754,40.815925],[-80.357348,40.814116],[-80.48159,40.723089],[-80.460829,40.714943],[-80.456194,40.713373],[-80.324728,40.723659],[-80.234919,40.783971],[-80.312966,40.833079],[-80.325525,40.826636],[-80.335754,40.815925]]]},"properties":{"id":"15010","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.178163,40.874707],[-80.167206,40.811351],[-80.094854,40.803031],[-80.066206,40.859526],[-80.142256,40.919634],[-80.178163,40.874707]]]},"properties":{"id":"16037","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.432527,40.618456],[-80.445598,40.623648],[-80.518993,40.637447],[-80.519038,40.477363],[-80.448114,40.477717],[-80.432527,40.618456]]]},"properties":{"id":"15043","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.399437,40.102296],[-78.534927,39.856353],[-78.297664,39.912487],[-78.186836,40.063701],[-78.206706,40.086427],[-78.366963,40.109794],[-78.399437,40.102296]]]},"properties":{"id":"15537","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.186836,40.063701],[-78.297664,39.912487],[-78.269043,39.900166],[-78.127187,40.01767],[-78.11702,40.029671],[-78.113525,40.035538],[-78.186836,40.063701]]]},"properties":{"id":"15533","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.758486,39.878144],[-78.718144,39.7603],[-78.644315,39.876103],[-78.76021,39.952234],[-78.76918,39.953457],[-78.758486,39.878144]]]},"properties":{"id":"15534","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.49347,39.722864],[-78.380599,39.722599],[-78.32458,39.789522],[-78.49347,39.722864]]]},"properties":{"id":"17211","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.57483,40.194374],[-78.466981,40.2072],[-78.447895,40.244395],[-78.457181,40.258541],[-78.459962,40.260022],[-78.564616,40.284129],[-78.620383,40.327025],[-78.649517,40.275042],[-78.647953,40.270621],[-78.57483,40.194374]]]},"properties":{"id":"16655","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.587046,40.120487],[-78.572009,40.132486],[-78.604334,40.137155],[-78.610548,40.123967],[-78.587046,40.120487]]]},"properties":{"id":"15539","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.91513,40.338252],[-75.939216,40.330948],[-75.920472,40.310594],[-75.903672,40.317237],[-75.906863,40.345795],[-75.91513,40.338252]]]},"properties":{"id":"19602","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.171346,40.413341],[-76.176567,40.336549],[-76.201736,40.296437],[-76.160762,40.312167],[-76.138431,40.30745],[-76.052961,40.394192],[-76.171346,40.413341]]]},"properties":{"id":"19551","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.567351,40.456369],[-75.550324,40.434468],[-75.529694,40.446995],[-75.567351,40.456369]]]},"properties":{"id":"18056","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.893149,40.384401],[-75.899262,40.376156],[-75.894941,40.377442],[-75.893149,40.384401]]],[[[-75.893149,40.384401],[-75.859659,40.37977],[-75.853299,40.393429],[-75.888626,40.429493],[-75.898988,40.425643],[-75.893149,40.384401]]]]},"properties":{"id":"19560","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.736205,40.48765],[-75.754175,40.483348],[-75.748744,40.481249],[-75.736205,40.48765]]]},"properties":{"id":"19511","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.916501,40.12063],[-75.943437,40.182676],[-76.017977,40.206609],[-76.015092,40.1983],[-76.014784,40.197032],[-76.01748,40.196772],[-76.025997,40.18703],[-76.000767,40.085192],[-75.943024,40.027765],[-75.916501,40.12063]]]},"properties":{"id":"17555","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.617959,40.53394],[-75.641524,40.489645],[-75.606803,40.54158],[-75.617959,40.53394]]],[[[-75.704345,40.434906],[-75.685055,40.422479],[-75.66093,40.426444],[-75.659274,40.42662],[-75.619061,40.457835],[-75.642485,40.482282],[-75.704345,40.434906]]]]},"properties":{"id":"18011","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.57207,40.419773],[-75.593951,40.395483],[-75.563813,40.404684],[-75.57207,40.419773]]]},"properties":{"id":"19503","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.421703,40.44986],[-78.426543,40.450856],[-78.421426,40.449778],[-78.421703,40.44986]]],[[[-78.397489,40.458976],[-78.403243,40.448769],[-78.424001,40.368917],[-78.412944,40.356525],[-78.345149,40.343633],[-78.290803,40.418078],[-78.270066,40.530199],[-78.381917,40.495261],[-78.397489,40.458976]]]]},"properties":{"id":"16648","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.187155,41.813685],[-76.178047,41.758433],[-76.150721,41.765081],[-76.187155,41.813685]]]},"properties":{"id":"18845","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.617639,41.866755],[-76.624103,41.845978],[-76.621336,41.843676],[-76.617639,41.866755]]]},"properties":{"id":"18817","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.10746,40.4822],[-75.068668,40.481139],[-75.068143,40.462249],[-75.166635,40.564216],[-75.16998,40.524439],[-75.10746,40.4822]]]},"properties":{"id":"18972","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.137782,40.210886],[-75.07341,40.172298],[-75.071198,40.171032],[-75.032579,40.21724],[-75.008754,40.250997],[-75.025791,40.252762],[-75.033911,40.255602],[-75.104984,40.239447],[-75.137782,40.210886]]]},"properties":{"id":"18974","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.889465,40.128775],[-74.898451,40.132235],[-74.904197,40.11355],[-74.889465,40.128775]]],[[[-74.870788,40.171931],[-74.888232,40.132145],[-74.883817,40.128067],[-74.883701,40.127941],[-74.875866,40.126801],[-74.870981,40.171835],[-74.870788,40.171931]]]]},"properties":{"id":"19056","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.797834,40.19491],[-74.808795,40.181253],[-74.785508,40.18213],[-74.797834,40.19491]]],[[[-74.841735,40.165492],[-74.818026,40.193249],[-74.83084,40.195414],[-74.870788,40.171931],[-74.870981,40.171835],[-74.861671,40.159256],[-74.841735,40.165492]]]]},"properties":{"id":"19030","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.197144,40.368104],[-75.186084,40.362274],[-75.181885,40.370534],[-75.200441,40.376243],[-75.197144,40.368104]]],[[[-75.161542,40.347525],[-75.181369,40.359386],[-75.171161,40.349552],[-75.161542,40.347525]]]]},"properties":{"id":"18923","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.909334,40.306804],[-74.889142,40.265371],[-74.846702,40.259002],[-74.901391,40.315468],[-74.909334,40.306804]]]},"properties":{"id":"18977","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.014795,40.286525],[-75.035422,40.260535],[-75.033911,40.255602],[-75.025791,40.252762],[-75.014795,40.286525]]]},"properties":{"id":"18980","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.787239,41.146319],[-79.797754,41.146174],[-79.810849,41.135757],[-79.786775,41.129302],[-79.787239,41.146319]]]},"properties":{"id":"16030","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.791935,40.748032],[-79.691926,40.773248],[-79.691968,40.81336],[-79.767122,40.842005],[-79.815595,40.773533],[-79.806067,40.747556],[-79.794891,40.747863],[-79.791935,40.748032]]]},"properties":{"id":"16023","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.892485,40.340013],[-78.878249,40.387108],[-78.881197,40.39238],[-78.910135,40.435157],[-78.95238,40.433198],[-78.995216,40.365926],[-79.033915,40.316491],[-78.927822,40.323255],[-78.892485,40.340013]]]},"properties":{"id":"15906","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.587932,40.48394],[-78.6185,40.45567],[-78.556958,40.409136],[-78.542303,40.468851],[-78.565125,40.48831],[-78.567537,40.495447],[-78.587932,40.48394]],[[-78.561097,40.450174],[-78.557956,40.446508],[-78.562678,40.446294],[-78.561097,40.450174]]]},"properties":{"id":"16630","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.929163,40.547367],[-78.903846,40.519469],[-78.90235,40.514521],[-78.891891,40.521412],[-78.880193,40.521885],[-78.879006,40.543065],[-78.866087,40.592926],[-78.920606,40.56994],[-78.929163,40.547367]]]},"properties":{"id":"15957","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.517827,40.715597],[-78.531788,40.697317],[-78.52851,40.654775],[-78.491944,40.638842],[-78.381743,40.679209],[-78.35043,40.724827],[-78.446106,40.754513],[-78.517827,40.715597]]]},"properties":{"id":"16639","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.68964,40.637216],[-78.748331,40.636349],[-78.755236,40.636245],[-78.759691,40.636175],[-78.759822,40.61551],[-78.757728,40.614378],[-78.698527,40.629859],[-78.68964,40.637216]]]},"properties":{"id":"15773","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.636479,41.082601],[-75.667285,41.043388],[-75.546138,41.034291],[-75.636479,41.082601]]]},"properties":{"id":"18624","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.665619,41.210007],[-75.754187,41.174549],[-75.755086,41.174326],[-75.815817,41.15344],[-75.927643,41.088504],[-75.914491,41.04277],[-75.842555,41.038601],[-75.842987,41.033476],[-75.855562,40.992635],[-75.713118,40.988622],[-75.635176,41.005107],[-75.667285,41.043388],[-75.636479,41.082601],[-75.645595,41.142762],[-75.665619,41.210007]]]},"properties":{"id":"18661","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.80877,40.839845],[-75.562344,40.926169],[-75.567342,40.954654],[-75.635176,41.005107],[-75.713118,40.988622],[-75.761762,40.883699],[-75.812004,40.845217],[-75.80877,40.839845]]]},"properties":{"id":"18229","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.983192,40.90784],[-75.992025,40.908051],[-76.001013,40.899615],[-76.024516,40.903299],[-76.028511,40.902077],[-76.029113,40.901762],[-76.032784,40.870368],[-76.007079,40.864381],[-75.957873,40.878667],[-75.976288,40.908255],[-75.983192,40.90784]]]},"properties":{"id":"18237","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.657523,40.793308],[-75.661817,40.804305],[-75.671009,40.805085],[-75.657523,40.793308]]]},"properties":{"id":"18030","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.881895,40.908777],[-77.878881,40.905623],[-77.871046,40.906506],[-77.87928,40.912058],[-77.881895,40.908777]]]},"properties":{"id":"16835","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.597235,41.071921],[-77.56712,41.065063],[-77.664761,41.201585],[-77.898195,41.252752],[-77.862457,41.179325],[-77.633067,41.076052],[-77.607838,41.073658],[-77.597235,41.071921]]]},"properties":{"id":"16822","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.738548,40.148545],[-75.794771,40.103892],[-75.822711,40.054902],[-75.82053,40.053896],[-75.798733,40.046656],[-75.6996,40.102993],[-75.674178,40.150449],[-75.673951,40.150684],[-75.738548,40.148545]]]},"properties":{"id":"19343","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.916501,40.12063],[-75.943024,40.027765],[-75.938429,40.02722],[-75.862165,40.058079],[-75.822711,40.054902],[-75.794771,40.103892],[-75.861456,40.121194],[-75.916501,40.12063]]]},"properties":{"id":"19344","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.487409,40.057856],[-75.459194,40.032895],[-75.475776,40.018369],[-75.460384,39.998597],[-75.423078,40.014381],[-75.432433,40.065082],[-75.436369,40.070533],[-75.474378,40.06271],[-75.487409,40.057856]]]},"properties":{"id":"19312","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.589494,40.20226],[-75.583883,40.200058],[-75.580416,40.203855],[-75.580747,40.205212],[-75.595964,40.205319],[-75.589494,40.20226]]]},"properties":{"id":"19457","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.761336,40.005405],[-75.76993,39.996887],[-75.768402,39.995332],[-75.764416,39.992944],[-75.761336,40.005405]]]},"properties":{"id":"19372","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.20976,41.23708],[-79.223567,41.166541],[-79.126844,41.09859],[-79.168824,41.225773],[-79.20976,41.23708]]]},"properties":{"id":"15829","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.350416,41.374437],[-79.381431,41.339258],[-79.408211,41.344739],[-79.42292,41.338916],[-79.367982,41.259053],[-79.290519,41.284201],[-79.271275,41.290198],[-79.347923,41.374297],[-79.350416,41.374437]]]},"properties":{"id":"16235","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.238227,41.431343],[-79.240898,41.369323],[-79.208226,41.367302],[-79.238227,41.431343]]]},"properties":{"id":"16260","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.551078,41.299137],[-79.550543,41.283273],[-79.531798,41.274104],[-79.551078,41.299137]]],[[[-79.719969,41.345136],[-79.71432,41.319361],[-79.615895,41.290968],[-79.574521,41.282322],[-79.551078,41.299137],[-79.551945,41.321078],[-79.603604,41.355624],[-79.711293,41.349681],[-79.719969,41.345136]]]]},"properties":{"id":"16319","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.678522,41.154885],[-79.659702,41.148997],[-79.636938,41.1533],[-79.678522,41.154885]]]},"properties":{"id":"16054","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.551945,41.321078],[-79.445556,41.322516],[-79.438392,41.340384],[-79.455332,41.38648],[-79.47805,41.411114],[-79.603604,41.355624],[-79.551945,41.321078]]]},"properties":{"id":"16364","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.122452,41.008656],[-78.147327,41.057597],[-78.259616,41.050836],[-78.295464,40.981931],[-78.282882,40.957243],[-78.278539,40.956591],[-78.207355,40.928058],[-78.201461,40.931692],[-78.199898,40.932861],[-78.193325,40.95086],[-78.212502,40.960478],[-78.207923,40.97147],[-78.187392,40.962537],[-78.170937,40.959293],[-78.176186,40.986225],[-78.171963,41.006003],[-78.151222,41.002163],[-78.171349,40.986549],[-78.143036,40.982128],[-78.084503,40.968256],[-78.081169,40.99332],[-78.096482,41.00132],[-78.109211,40.994052],[-78.122452,41.008656]]]},"properties":{"id":"16858","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.147327,41.057597],[-78.122452,41.008656],[-78.096482,41.00132],[-78.081169,40.99332],[-78.0597,41.003571],[-78.059866,41.051911],[-78.096621,41.073383],[-78.147327,41.057597]]]},"properties":{"id":"16834","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.661379,40.864876],[-78.732426,40.785658],[-78.688189,40.724182],[-78.633919,40.725159],[-78.589623,40.725423],[-78.581015,40.755596],[-78.602315,40.815182],[-78.661379,40.864876]]]},"properties":{"id":"15753","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.171349,40.986549],[-78.151222,41.002163],[-78.171963,41.006003],[-78.176186,40.986225],[-78.171349,40.986549]]]},"properties":{"id":"16847","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.697952,41.074493],[-78.769587,41.018725],[-78.708162,40.993235],[-78.70753,40.993231],[-78.656279,41.053513],[-78.697952,41.074493]]]},"properties":{"id":"15848","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.48111,40.938717],[-78.465025,40.950105],[-78.472069,40.956833],[-78.48465,40.948112],[-78.48111,40.938717]]]},"properties":{"id":"16837","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.474629,41.060053],[-77.492875,41.059088],[-77.47502,41.041744],[-77.461861,41.053998],[-77.474629,41.060053]]]},"properties":{"id":"17750","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.305918,41.198152],[-76.385727,41.152251],[-76.293604,41.149213],[-76.269218,41.192985],[-76.246352,41.213937],[-76.259902,41.22112],[-76.271845,41.216263],[-76.305918,41.198152]]]},"properties":{"id":"17878","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.269218,41.192985],[-76.293604,41.149213],[-76.274553,41.124363],[-76.143774,41.138338],[-76.093064,41.153765],[-76.098684,41.179961],[-76.135405,41.216937],[-76.19013,41.278866],[-76.255201,41.259228],[-76.259902,41.22112],[-76.246352,41.213937],[-76.238385,41.194689],[-76.269218,41.192985]]]},"properties":{"id":"18655","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.950784,41.720022],[-79.854718,41.614448],[-79.851805,41.639106],[-79.911392,41.738776],[-79.950784,41.720022]]]},"properties":{"id":"16360","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.315576,41.559584],[-80.226819,41.539623],[-80.186638,41.548995],[-80.291759,41.665337],[-80.303126,41.666473],[-80.315152,41.654175],[-80.393931,41.60549],[-80.315576,41.559584]]]},"properties":{"id":"16316","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.157409,40.12147],[-77.195614,40.079418],[-77.147901,40.09916],[-77.157409,40.12147]]]},"properties":{"id":"17065","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.986357,40.154892],[-77.007646,40.155187],[-77.009212,40.148917],[-76.986357,40.154892]]]},"properties":{"id":"17027","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.390747,40.09285],[-77.394887,40.101346],[-77.42124,40.086246],[-77.434946,40.081894],[-77.425414,40.074123],[-77.399695,40.090947],[-77.390687,40.092612],[-77.390747,40.09285]]]},"properties":{"id":"17266","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.932147,40.354596],[-76.915595,40.315594],[-76.914112,40.274466],[-76.888665,40.288278],[-76.871392,40.280616],[-76.836737,40.307296],[-76.92604,40.361423],[-76.932147,40.354596]]]},"properties":{"id":"17110","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.581355,40.226349],[-76.5386,40.205667],[-76.547531,40.210094],[-76.556962,40.214863],[-76.581355,40.226349]]],[[[-76.581355,40.226349],[-76.639086,40.332155],[-76.649055,40.335935],[-76.633662,40.243906],[-76.581355,40.226349]]]]},"properties":{"id":"17033","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.880514,40.26635],[-76.882227,40.261655],[-76.885881,40.265418],[-76.904577,40.260572],[-76.88886,40.249019],[-76.874575,40.264518],[-76.878736,40.266499],[-76.880514,40.26635]]]},"properties":{"id":"17101","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.796601,40.22599],[-76.848907,40.243196],[-76.873248,40.237681],[-76.808205,40.20062],[-76.806121,40.200618],[-76.800102,40.208214],[-76.796427,40.213363],[-76.796601,40.22599]]]},"properties":{"id":"17113","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.685376,40.567132],[-76.656957,40.573478],[-76.659534,40.595266],[-76.685376,40.567132]]]},"properties":{"id":"17097","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.752125,40.619981],[-76.720353,40.592536],[-76.713018,40.622208],[-76.752125,40.619981]]]},"properties":{"id":"17030","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.949414,40.628163],[-76.879155,40.616737],[-76.810149,40.634914],[-76.80984,40.642047],[-76.87663,40.691545],[-76.939749,40.638378],[-76.949414,40.628163]]]},"properties":{"id":"17017","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.319393,39.91169],[-75.337642,39.905698],[-75.333957,39.900755],[-75.315365,39.898098],[-75.314207,39.904963],[-75.319393,39.91169]]]},"properties":{"id":"19070","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.337642,39.905698],[-75.358306,39.910871],[-75.350413,39.881246],[-75.339965,39.886156],[-75.333957,39.900755],[-75.337642,39.905698]]]},"properties":{"id":"19081","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.419172,39.877165],[-75.451528,39.894845],[-75.468181,39.87755],[-75.465732,39.86119],[-75.420447,39.835385],[-75.386377,39.85007],[-75.419172,39.877165]]]},"properties":{"id":"19014","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.289617,39.888737],[-75.288386,39.880858],[-75.277472,39.880699],[-75.281355,39.907475],[-75.285069,39.905804],[-75.289617,39.888737]]]},"properties":{"id":"19032","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.642298,41.518118],[-78.420172,41.515362],[-78.419118,41.602188],[-78.537526,41.68877],[-78.754468,41.553963],[-78.642298,41.518118]]]},"properties":{"id":"15870","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.548054,41.270925],[-78.548844,41.247548],[-78.52781,41.246239],[-78.508004,41.252961],[-78.547897,41.280846],[-78.548054,41.270925]]]},"properties":{"id":"15841","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519387,41.950137],[-80.519308,41.90721],[-80.428142,41.936846],[-80.423405,41.961463],[-80.519387,41.950137]]]},"properties":{"id":"16443","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.090693,42.126171],[-80.077365,42.117625],[-80.080836,42.129791],[-80.090693,42.126171]]]},"properties":{"id":"16501","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.080836,42.129791],[-80.077365,42.117625],[-80.07361,42.111919],[-80.072198,42.112415],[-80.039909,42.12358],[-80.040279,42.135173],[-80.053651,42.14156],[-80.080836,42.129791]]]},"properties":{"id":"16503","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.908304,42.030004],[-79.758719,41.960246],[-79.752657,41.998801],[-79.761732,42.094753],[-79.887507,42.088425],[-79.912334,42.057642],[-79.908304,42.030004]]]},"properties":{"id":"16442","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.996749,42.123542],[-79.989552,42.114257],[-79.980711,42.123292],[-79.996749,42.123542]]]},"properties":{"id":"16563","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.72465,39.97027],[-79.82066,39.95574],[-79.82237,39.90638],[-79.81045,39.86534],[-79.754345,39.837852],[-79.684258,39.815984],[-79.682091,39.818358],[-79.6823,39.90306],[-79.652273,39.95368],[-79.665085,39.960157],[-79.692131,39.958432],[-79.70389,39.96286],[-79.70058,39.97511],[-79.71554,39.9708],[-79.72465,39.97027]],[[-79.71644,39.92235],[-79.711036,39.920322],[-79.714663,39.914096],[-79.72279,39.919313],[-79.71644,39.92235]],[[-79.790553,39.896219],[-79.77478,39.89932],[-79.774498,39.890125],[-79.782996,39.885544],[-79.790553,39.896219]]]},"properties":{"id":"15401","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.849387,39.933681],[-79.82237,39.90638],[-79.82066,39.95574],[-79.811422,40.002196],[-79.86594,39.966302],[-79.85985,39.95574],[-79.849387,39.933681]]]},"properties":{"id":"15468","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.891231,39.866433],[-79.88101,39.85564],[-79.859262,39.859736],[-79.891231,39.866433]]]},"properties":{"id":"15454","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.988232,40.011906],[-79.953176,39.994676],[-79.956682,39.997012],[-79.974622,40.01386],[-79.988232,40.011906]]]},"properties":{"id":"15450","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.998014,39.983322],[-79.936297,39.935126],[-79.927312,39.926197],[-79.93206,39.9326],[-79.94531,39.94757],[-79.936521,39.951513],[-79.930539,39.93898],[-79.90568,39.954],[-79.903314,39.961285],[-79.90783,39.9662],[-79.932991,40.003187],[-79.953176,39.994676],[-79.988232,40.011906],[-79.992638,40.009449],[-79.992888,39.994333],[-79.998014,39.983322]]]},"properties":{"id":"15433","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.93468,39.764774],[-79.91628,39.720777],[-79.857353,39.720707],[-79.877812,39.75281],[-79.915926,39.785617],[-79.933756,39.773894],[-79.93468,39.764774]]]},"properties":{"id":"15474","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.916138,40.014042],[-79.909658,40.002858],[-79.898499,40.012749],[-79.916138,40.014042]]]},"properties":{"id":"15444","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.89005,39.96507],[-79.90783,39.9662],[-79.903314,39.961285],[-79.89005,39.96507]]]},"properties":{"id":"15463","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.403287,41.584264],[-79.386119,41.581655],[-79.361113,41.626416],[-79.403287,41.584264]]]},"properties":{"id":"16322","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.827142,39.872364],[-77.773618,39.87327],[-77.849674,39.961354],[-77.873082,39.931071],[-77.874315,39.886054],[-77.827142,39.872364]]]},"properties":{"id":"17252","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.704023,40.177883],[-77.622229,40.192445],[-77.614665,40.198549],[-77.646283,40.240663],[-77.697307,40.185183],[-77.704023,40.177883]]]},"properties":{"id":"17210","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.678723,39.779833],[-77.675024,39.78474],[-77.679918,39.787153],[-77.681455,39.784797],[-77.678092,39.783428],[-77.678723,39.779833]]]},"properties":{"id":"17256","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.182264,39.946363],[-78.176154,39.722385],[-78.140613,39.722293],[-78.119372,39.766179],[-78.077469,39.841228],[-78.090257,39.918963],[-78.158784,39.96861],[-78.182264,39.946363]]]},"properties":{"id":"17238","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.9676,39.800415],[-79.971312,39.817676],[-79.954476,39.816286],[-79.950117,39.804578],[-79.93468,39.764774],[-79.933756,39.773894],[-79.915967,39.80724],[-79.921716,39.831324],[-79.926888,39.853164],[-80.013391,39.811725],[-80.000286,39.756466],[-79.992381,39.764935],[-79.985589,39.77269],[-79.9676,39.800415]]]},"properties":{"id":"15338","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.085832,39.932853],[-80.082765,39.926962],[-80.063115,39.937691],[-80.07089,39.944483],[-80.085832,39.932853]]]},"properties":{"id":"15346","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.170843,39.785269],[-80.174176,39.721151],[-80.00637,39.721192],[-80.000286,39.756466],[-80.013391,39.811725],[-80.035321,39.814416],[-80.170843,39.785269]]]},"properties":{"id":"15349","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.010557,39.983342],[-80.035529,39.942466],[-79.994298,39.931632],[-79.936297,39.935126],[-79.998014,39.983322],[-80.010557,39.983342]],[[-79.968013,39.947958],[-79.966043,39.953277],[-79.958232,39.950956],[-79.959322,39.943435],[-79.965104,39.947537],[-79.968013,39.947958]]]},"properties":{"id":"15357","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.965104,39.947537],[-79.959322,39.943435],[-79.958232,39.950956],[-79.966043,39.953277],[-79.968013,39.947958],[-79.965104,39.947537]]]},"properties":{"id":"15325","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.992381,39.764935],[-79.987545,39.747032],[-79.974479,39.753306],[-79.985589,39.77269],[-79.992381,39.764935]]]},"properties":{"id":"15315","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.068691,40.56073],[-77.697353,40.681507],[-77.681334,40.729758],[-77.699645,40.721023],[-77.809049,40.736996],[-77.894282,40.716203],[-77.996818,40.680692],[-78.10938,40.613158],[-78.068691,40.56073]]]},"properties":{"id":"16669","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.931617,40.429904],[-77.888866,40.392178],[-77.894907,40.3989],[-77.877571,40.438496],[-77.841827,40.550035],[-77.931617,40.429904]]]},"properties":{"id":"17060","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.180339,40.675923],[-79.156441,40.672436],[-79.157465,40.678823],[-79.169313,40.681039],[-79.180067,40.676484],[-79.180339,40.675923]]]},"properties":{"id":"15739","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.302344,40.53453],[-77.379913,40.532833],[-77.486438,40.548731],[-77.521166,40.533067],[-77.51518,40.470084],[-77.500236,40.420461],[-77.375355,40.478175],[-77.268812,40.525769],[-77.302344,40.53453]]]},"properties":{"id":"17082","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.239735,40.611161],[-77.302344,40.53453],[-77.268812,40.525769],[-77.141376,40.638493],[-77.239735,40.611161]]]},"properties":{"id":"17094","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.950172,40.67381],[-76.927168,40.732928],[-76.962433,40.744992],[-76.985997,40.742937],[-77.060084,40.748021],[-77.077876,40.656852],[-77.046069,40.624826],[-76.950172,40.67381]]]},"properties":{"id":"17853","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.543209,41.427616],[-75.583884,41.396221],[-75.586075,41.39666],[-75.650887,41.36748],[-75.683242,41.338942],[-75.687244,41.339216],[-75.652922,41.258314],[-75.652418,41.249562],[-75.443337,41.299295],[-75.390008,41.341214],[-75.387311,41.351293],[-75.398403,41.367561],[-75.40038,41.395727],[-75.42404,41.405045],[-75.543209,41.427616]],[[-75.433515,41.342225],[-75.415805,41.352218],[-75.408862,41.346889],[-75.419213,41.334616],[-75.433515,41.342225]]]},"properties":{"id":"18444","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.707206,41.342608],[-75.687244,41.339216],[-75.683242,41.338942],[-75.707206,41.342608]]],[[[-75.760143,41.331714],[-75.707206,41.342608],[-75.740054,41.347918],[-75.760143,41.331714]]]]},"properties":{"id":"18641","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.306073,39.799122],[-76.262689,39.754288],[-76.228683,39.798471],[-76.236972,39.855087],[-76.312445,39.805425],[-76.306073,39.799122]]]},"properties":{"id":"17518","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.136785,39.944531],[-76.083331,39.937552],[-76.023361,39.956454],[-76.073989,40.01599],[-76.141407,40.008071],[-76.127712,40.002287],[-76.139821,39.964432],[-76.136785,39.944531]]]},"properties":{"id":"17562","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.056744,40.155277],[-76.000767,40.085192],[-76.025997,40.18703],[-76.060174,40.17745],[-76.058142,40.158494],[-76.052407,40.16292],[-76.044087,40.160163],[-76.04325,40.154357],[-76.056744,40.155277]]]},"properties":{"id":"17519","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.527989,40.064077],[-76.533874,40.050818],[-76.4972,39.989031],[-76.452015,40.0093],[-76.432606,40.023335],[-76.438533,40.051859],[-76.436936,40.06424],[-76.527989,40.064077]]]},"properties":{"id":"17512","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.406451,40.04336],[-76.438533,40.051859],[-76.432606,40.023335],[-76.406451,40.04336]]]},"properties":{"id":"17554","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.033921,40.051971],[-76.073989,40.01599],[-76.023361,39.956454],[-76.006523,39.966724],[-76.005192,40.040138],[-76.033921,40.051971]]]},"properties":{"id":"17535","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.214307,39.944962],[-76.211101,39.96089],[-76.293668,39.9907],[-76.300788,39.953585],[-76.295305,39.930644],[-76.269869,39.920865],[-76.214307,39.944962]]]},"properties":{"id":"17584","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.015092,40.1983],[-76.01748,40.196772],[-76.014784,40.197032],[-76.015092,40.1983]]]},"properties":{"id":"17507","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.26672,41.060729],[-80.402911,41.036863],[-80.41288,40.996523],[-80.379151,40.957895],[-80.370441,40.937716],[-80.350704,40.938072],[-80.277508,40.897253],[-80.217154,40.954102],[-80.200109,40.96791],[-80.17772,41.011342],[-80.229054,41.051084],[-80.26672,41.060729]]]},"properties":{"id":"16101","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519014,41.011842],[-80.49544,40.994402],[-80.483551,40.994611],[-80.41288,40.996523],[-80.402911,41.036863],[-80.404906,41.085847],[-80.502947,41.061536],[-80.519099,41.032796],[-80.519014,41.011842]]]},"properties":{"id":"16116","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.269218,41.192985],[-76.238385,41.194689],[-76.246352,41.213937],[-76.269218,41.192985]]]},"properties":{"id":"18622","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.822139,41.30858],[-75.860424,41.377495],[-75.922851,41.344423],[-75.912748,41.292685],[-75.906292,41.289434],[-75.898875,41.294529],[-75.849087,41.294549],[-75.841278,41.297917],[-75.822139,41.30858]]]},"properties":{"id":"18644","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.911214,41.250107],[-75.928442,41.288074],[-75.997078,41.249755],[-76.01274,41.228436],[-75.985563,41.213901],[-75.928142,41.24097],[-75.911214,41.250107]]]},"properties":{"id":"18651","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.94285,41.42115],[-76.007173,41.384453],[-76.115219,41.339965],[-76.113329,41.309005],[-76.039257,41.270879],[-75.922851,41.344423],[-75.860424,41.377495],[-75.901405,41.409563],[-75.94285,41.42115]]]},"properties":{"id":"18612","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.053817,41.153818],[-76.065553,41.201544],[-76.098684,41.179961],[-76.093064,41.153765],[-76.053817,41.153818]]]},"properties":{"id":"18617","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.053847,41.467952],[-76.188883,41.379097],[-76.110946,41.34609],[-76.007173,41.384453],[-76.00105,41.41291],[-76.053847,41.467952]]]},"properties":{"id":"18636","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.08073,41.015286],[-76.079653,41.010133],[-76.075824,41.003411],[-76.074808,41.003506],[-76.07875,41.014346],[-76.08073,41.015286]]]},"properties":{"id":"18251","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.100619,41.521761],[-77.193981,41.489829],[-77.291372,41.351643],[-77.193433,41.34201],[-76.9525,41.345519],[-76.827349,41.411808],[-76.772402,41.471686],[-76.780467,41.495682],[-76.78823,41.517128],[-76.853849,41.543731],[-76.879129,41.545674],[-77.049492,41.490897],[-77.100619,41.521761]]]},"properties":{"id":"17771","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.213375,41.545413],[-77.35865,41.461153],[-77.358691,41.429398],[-77.388764,41.400914],[-77.389844,41.40034],[-77.398411,41.343265],[-77.395839,41.277947],[-77.291372,41.351643],[-77.193981,41.489829],[-77.213375,41.545413]]]},"properties":{"id":"17776","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.216407,41.635807],[-77.490756,41.688867],[-77.536987,41.65545],[-77.358636,41.474069],[-77.35865,41.461153],[-77.213375,41.545413],[-77.216407,41.635807]]]},"properties":{"id":"16938","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.951365,41.891568],[-78.949961,41.79621],[-78.890979,41.806217],[-78.645315,41.873855],[-78.604294,41.877503],[-78.56048,41.880135],[-78.549638,41.880173],[-78.532642,41.89994],[-78.532657,41.903836],[-78.532689,41.909715],[-78.532715,41.914991],[-78.493658,41.947447],[-78.535005,41.958916],[-78.533114,41.999924],[-78.961372,41.998636],[-78.951365,41.891568]],[[-78.656245,41.90085],[-78.658555,41.910368],[-78.644569,41.910446],[-78.656245,41.90085]]]},"properties":{"id":"16701","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.604294,41.877503],[-78.591052,41.842854],[-78.551336,41.843758],[-78.56048,41.880135],[-78.604294,41.877503]]]},"properties":{"id":"16744","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.656245,41.90085],[-78.644569,41.910446],[-78.658555,41.910368],[-78.656245,41.90085]]]},"properties":{"id":"16725","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.415175,41.270806],[-80.366452,41.249826],[-80.328016,41.287596],[-80.427168,41.290777],[-80.415175,41.270806]]],[[[-80.442669,41.286698],[-80.51614,41.347838],[-80.519112,41.347846],[-80.519105,41.268003],[-80.423478,41.270706],[-80.442669,41.286698]]]]},"properties":{"id":"16150","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.201998,41.443906],[-80.210127,41.447106],[-80.211573,41.443333],[-80.203313,41.440462],[-80.201998,41.443906]]]},"properties":{"id":"16151","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.237631,40.792273],[-77.243358,40.832087],[-77.32119,40.817187],[-77.512352,40.690273],[-77.468999,40.624746],[-77.36069,40.669991],[-77.269737,40.691271],[-77.269444,40.691344],[-77.237631,40.792273]]]},"properties":{"id":"17841","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.676371,40.443689],[-77.704957,40.578875],[-77.816757,40.500555],[-77.877571,40.438496],[-77.894907,40.3989],[-77.838904,40.393071],[-77.830354,40.398413],[-77.827308,40.394412],[-77.834599,40.391228],[-77.76172,40.368733],[-77.676371,40.443689]]]},"properties":{"id":"17051","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.565349,40.650224],[-77.572256,40.662624],[-77.583533,40.654203],[-77.583889,40.653959],[-77.582627,40.628414],[-77.565349,40.650224]]]},"properties":{"id":"17099","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.646094,40.539869],[-77.6124,40.560715],[-77.625831,40.564622],[-77.646094,40.539869]]]},"properties":{"id":"17029","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.52129,41.145084],[-75.625955,41.130343],[-75.526716,41.076236],[-75.506038,41.077123],[-75.52129,41.145084]]]},"properties":{"id":"18347","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.341217,41.135126],[-75.36595,41.137778],[-75.383109,41.112663],[-75.378453,41.111571],[-75.354037,41.105721],[-75.306507,41.106033],[-75.341217,41.135126]]]},"properties":{"id":"18344","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.097857,41.053672],[-75.092666,41.068902],[-75.104538,41.065708],[-75.100517,41.052943],[-75.097857,41.053672]]]},"properties":{"id":"18335","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.131731,40.089333],[-75.098543,40.070166],[-75.096733,40.068989],[-75.076363,40.087265],[-75.07127,40.092532],[-75.096022,40.126429],[-75.131173,40.109693],[-75.143243,40.098345],[-75.131731,40.089333]]]},"properties":{"id":"19046","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.636524,40.300655],[-75.550629,40.266252],[-75.539979,40.276424],[-75.53375,40.30645],[-75.566035,40.319421],[-75.576866,40.333565],[-75.578391,40.33531],[-75.588439,40.354312],[-75.590845,40.356793],[-75.615089,40.342087],[-75.618532,40.337862],[-75.636524,40.300655]]]},"properties":{"id":"19525","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.255408,40.202095],[-75.245748,40.195646],[-75.236506,40.205346],[-75.255408,40.202095]]]},"properties":{"id":"19436","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.551359,40.804552],[-75.608985,40.787387],[-75.555541,40.72092],[-75.527204,40.734068],[-75.531446,40.744414],[-75.54322,40.740905],[-75.545601,40.729207],[-75.555364,40.736354],[-75.545918,40.746108],[-75.534874,40.752972],[-75.479864,40.763495],[-75.482322,40.770754],[-75.551359,40.804552]]]},"properties":{"id":"18088","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.479864,40.763495],[-75.422251,40.691857],[-75.392532,40.697688],[-75.36082,40.809622],[-75.373648,40.834291],[-75.378852,40.832527],[-75.443267,40.818276],[-75.482322,40.770754],[-75.479864,40.763495]]]},"properties":{"id":"18014","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.16179,40.948395],[-75.097338,40.840294],[-75.087397,40.918728],[-75.100029,40.91684],[-75.100458,40.930257],[-75.120506,40.968378],[-75.13896,40.954394],[-75.16179,40.948395]]]},"properties":{"id":"18343","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.644438,40.710553],[-76.673181,40.701388],[-76.703177,40.704994],[-76.707142,40.719286],[-76.647077,40.718113],[-76.648144,40.719657],[-76.631971,40.734395],[-76.782151,40.767602],[-76.831157,40.743058],[-76.69257,40.680643],[-76.637433,40.707795],[-76.644438,40.710553]]]},"properties":{"id":"17823","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.648144,40.719657],[-76.559983,40.721677],[-76.573553,40.731598],[-76.631971,40.734395],[-76.648144,40.719657]]],[[[-76.69257,40.680643],[-76.701624,40.658082],[-76.689876,40.651003],[-76.518149,40.674267],[-76.58275,40.702282],[-76.637433,40.707795],[-76.69257,40.680643]]]]},"properties":{"id":"17941","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.45173,40.782756],[-76.446909,40.759596],[-76.418086,40.763737],[-76.45173,40.782756]]]},"properties":{"id":"17840","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.85105,40.96548],[-76.858482,40.972528],[-76.863908,40.967753],[-76.85105,40.96548]]]},"properties":{"id":"17850","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.444809,40.297539],[-77.432675,40.282319],[-77.322842,40.270721],[-77.269957,40.278671],[-77.229166,40.283744],[-77.212907,40.363472],[-77.229131,40.378688],[-77.316651,40.361057],[-77.444809,40.297539]]]},"properties":{"id":"17040","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.015223,40.094626],[-74.977752,40.067904],[-74.97994,40.119742],[-75.015223,40.094626]]]},"properties":{"id":"19154","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.135133,39.969442],[-75.159442,39.971467],[-75.161959,39.959571],[-75.152046,39.958327],[-75.130404,39.958737],[-75.125421,39.96196],[-75.135133,39.969442]]]},"properties":{"id":"19123","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.066045,40.051181],[-75.046794,40.04117],[-75.025427,40.065647],[-75.035087,40.074617],[-75.065724,40.070193],[-75.066045,40.051181]]]},"properties":{"id":"19152","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.212895,39.969382],[-75.237502,39.967854],[-75.247688,39.962817],[-75.249499,39.957189],[-75.21217,39.952874],[-75.212895,39.969382]]]},"properties":{"id":"19139","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.183288,39.949512],[-75.168421,39.945591],[-75.167233,39.958431],[-75.179071,39.956122],[-75.183288,39.949512]]]},"properties":{"id":"19103","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.174959,39.898488],[-75.195514,39.89184],[-75.195236,39.877365],[-75.135429,39.893009],[-75.174959,39.898488]]]},"properties":{"id":"19112","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.291018,41.254759],[-75.283484,41.265563],[-75.351312,41.363721],[-75.374364,41.354214],[-75.390008,41.341214],[-75.443337,41.299295],[-75.372235,41.237959],[-75.359184,41.239206],[-75.342005,41.253211],[-75.291018,41.254759]]]},"properties":{"id":"18445","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.247382,41.278591],[-75.19429,41.248544],[-75.190004,41.248782],[-75.129721,41.252101],[-75.192436,41.359792],[-75.213509,41.387434],[-75.255926,41.386122],[-75.298342,41.367402],[-75.351312,41.363721],[-75.283484,41.265563],[-75.247382,41.278591]]]},"properties":{"id":"18426","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.047533,41.463895],[-75.004928,41.368688],[-74.978467,41.433704],[-75.029007,41.483399],[-75.035771,41.475308],[-75.047533,41.463895]]]},"properties":{"id":"18425","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.213509,41.387434],[-75.183793,41.429569],[-75.186521,41.458879],[-75.255926,41.386122],[-75.213509,41.387434]]]},"properties":{"id":"18451","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.763971,41.409359],[-74.74129,41.406317],[-74.815827,41.44156],[-74.815223,41.440325],[-74.763971,41.409359]]]},"properties":{"id":"18340","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.72779,41.959132],[-77.6917,41.94564],[-77.728696,41.998759],[-77.736812,41.998689],[-77.74486,41.9783],[-77.72779,41.959132]]]},"properties":{"id":"16937","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.682708,41.876907],[-77.71036,41.7749],[-77.604471,41.758265],[-77.575567,41.80951],[-77.682708,41.876907]]]},"properties":{"id":"16943","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.254714,40.820558],[-76.26075,40.801893],[-76.251553,40.794628],[-76.199481,40.804629],[-76.179496,40.847669],[-76.254714,40.820558]]]},"properties":{"id":"17976","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.251194,40.79356],[-76.250431,40.791719],[-76.227673,40.796844],[-76.251194,40.79356]]]},"properties":{"id":"17949","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.994632,40.635534],[-76.068869,40.696574],[-76.125185,40.68576],[-76.110246,40.630273],[-76.02734,40.587168],[-76.005799,40.598681],[-75.996233,40.629971],[-75.994632,40.635534]]]},"properties":{"id":"17961","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.323955,40.684881],[-76.335545,40.611029],[-76.282132,40.628931],[-76.323955,40.684881]]]},"properties":{"id":"17923","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.313843,40.799415],[-76.283571,40.784077],[-76.250431,40.791719],[-76.251194,40.79356],[-76.251553,40.794628],[-76.26075,40.801893],[-76.275138,40.813257],[-76.30717,40.801809],[-76.313843,40.799415]]]},"properties":{"id":"17935","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.08242,40.770394],[-76.073246,40.744359],[-76.062764,40.750723],[-76.08242,40.770394]]]},"properties":{"id":"17925","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.047351,39.934484],[-78.990395,39.867201],[-78.948497,39.848762],[-78.76918,39.953457],[-78.76021,39.952234],[-78.760833,39.979822],[-78.763017,40.036468],[-78.85749,40.00574],[-78.953476,40.0131],[-79.030883,39.968306],[-79.041993,39.963023],[-79.047351,39.934484]]]},"properties":{"id":"15530","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.829415,40.156002],[-78.853045,40.138168],[-78.841837,40.120563],[-78.763202,40.056745],[-78.72285,40.107803],[-78.721151,40.111858],[-78.829415,40.156002]]]},"properties":{"id":"15924","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.841837,40.120563],[-78.871998,40.027527],[-78.85749,40.00574],[-78.763017,40.036468],[-78.763202,40.056745],[-78.841837,40.120563]]]},"properties":{"id":"15926","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.982759,40.152256],[-78.967716,40.140632],[-78.89948,40.214819],[-78.897248,40.206521],[-78.892002,40.207128],[-78.898514,40.212109],[-78.90465,40.227525],[-78.941773,40.246267],[-79.001981,40.248061],[-78.982759,40.152256]],[[-78.974968,40.203541],[-78.98622,40.197874],[-78.99826,40.210787],[-78.981865,40.214731],[-78.974968,40.203541]]]},"properties":{"id":"15935","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.990395,39.867201],[-79.047351,39.934484],[-79.149823,39.852648],[-78.990395,39.867201]]]},"properties":{"id":"15542","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.974968,40.203541],[-78.981865,40.214731],[-78.99826,40.210787],[-78.98622,40.197874],[-78.974968,40.203541]]]},"properties":{"id":"15937","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.361345,41.999445],[-75.707788,41.99812],[-75.74122,41.96505],[-75.730997,41.908209],[-75.64852,41.80198],[-75.64077,41.76916],[-75.58772,41.78525],[-75.58282,41.82104],[-75.477174,41.88628],[-75.391684,41.949711],[-75.361345,41.999445]]]},"properties":{"id":"18847","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.71173,41.68462],[-75.8705,41.71715],[-75.83759,41.67901],[-75.71173,41.68462]]]},"properties":{"id":"18824","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.586332,41.789672],[-75.585453,41.778357],[-75.445292,41.82474],[-75.418853,41.852303],[-75.477174,41.88628],[-75.58282,41.82104],[-75.586332,41.789672]]]},"properties":{"id":"18465","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.972571,41.796193],[-76.949439,41.824036],[-76.924419,41.837361],[-76.938208,41.871551],[-77.014761,41.911686],[-77.181625,41.85586],[-77.177484,41.730493],[-76.995417,41.747827],[-76.972571,41.796193]]]},"properties":{"id":"16933","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.881685,41.090581],[-76.874849,41.080262],[-76.875044,41.075988],[-76.870385,41.07518],[-76.879168,41.089024],[-76.881685,41.090581]]]},"properties":{"id":"17887","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.166047,40.899759],[-77.146516,40.895192],[-77.146517,40.906346],[-77.166047,40.899759]]]},"properties":{"id":"17829","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.118537,40.896169],[-77.126537,40.892194],[-77.12168,40.888036],[-77.118537,40.896169]]]},"properties":{"id":"17880","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.031438,40.108997],[-80.015118,40.098296],[-80.012368,40.098121],[-79.976854,40.113675],[-79.984921,40.165247],[-79.998215,40.194909],[-80.036628,40.181437],[-80.04098,40.110641],[-80.031438,40.108997]]]},"properties":{"id":"15314","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.25457,40.07542],[-80.148661,40.10077],[-80.148709,40.100801],[-80.143317,40.112948],[-80.136933,40.125638],[-80.163397,40.214657],[-80.20704,40.238089],[-80.214969,40.222701],[-80.224884,40.211813],[-80.236278,40.221071],[-80.221141,40.223062],[-80.222277,40.248092],[-80.268486,40.279478],[-80.355712,40.252043],[-80.359361,40.204512],[-80.340805,40.092235],[-80.25457,40.07542]]]},"properties":{"id":"15301","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.934395,40.044593],[-79.911661,40.046349],[-79.894761,40.070265],[-79.893033,40.080345],[-79.879497,40.085001],[-79.855704,40.10492],[-79.867384,40.113864],[-79.976854,40.113675],[-80.012368,40.098121],[-79.934395,40.044593]]]},"properties":{"id":"15423","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.970652,40.231398],[-79.981492,40.207395],[-79.938683,40.209814],[-79.970652,40.231398]]]},"properties":{"id":"15067","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.087859,40.298121],[-80.090387,40.253422],[-80.054502,40.243865],[-80.038165,40.289194],[-80.07396,40.299837],[-80.087859,40.298121]]]},"properties":{"id":"15367","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.221384,41.516276],[-75.199241,41.530571],[-75.208266,41.537011],[-75.221384,41.516276]]]},"properties":{"id":"18473","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.617104,40.263668],[-79.591203,40.244259],[-79.562996,40.228918],[-79.549955,40.223173],[-79.49533,40.21981],[-79.496411,40.221993],[-79.488881,40.224787],[-79.491507,40.217995],[-79.480564,40.225968],[-79.478868,40.233629],[-79.466443,40.25024],[-79.467372,40.3561],[-79.476452,40.36212],[-79.516822,40.450009],[-79.546729,40.450201],[-79.555806,40.427127],[-79.589778,40.39014],[-79.58997,40.313957],[-79.601246,40.318997],[-79.643765,40.296356],[-79.648547,40.293419],[-79.664868,40.274454],[-79.657423,40.275135],[-79.617104,40.263668]],[[-79.482165,40.337086],[-79.472238,40.334409],[-79.474063,40.331355],[-79.479816,40.333677],[-79.482165,40.337086]],[[-79.504419,40.35124],[-79.492592,40.354001],[-79.492888,40.347197],[-79.505222,40.344293],[-79.504419,40.35124]],[[-79.504419,40.35124],[-79.514801,40.349379],[-79.533384,40.358092],[-79.524329,40.364011],[-79.522932,40.35598],[-79.504419,40.35124]]]},"properties":{"id":"15601","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.694369,40.184982],[-79.671089,40.106196],[-79.659357,40.097305],[-79.625845,40.137182],[-79.610284,40.149517],[-79.604026,40.170744],[-79.594947,40.173624],[-79.615637,40.208717],[-79.614958,40.213782],[-79.644127,40.227907],[-79.666634,40.236519],[-79.675118,40.240161],[-79.685775,40.233601],[-79.692782,40.205047],[-79.69353,40.190458],[-79.694369,40.184982]]]},"properties":{"id":"15679","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.90417,40.151967],[-79.859597,40.137965],[-79.858,40.14215],[-79.863033,40.161975],[-79.864153,40.161815],[-79.900346,40.162503],[-79.90417,40.151967]]]},"properties":{"id":"15062","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.222015,40.357548],[-79.159174,40.295017],[-79.118159,40.371381],[-79.152641,40.404656],[-79.192722,40.414862],[-79.217199,40.360768],[-79.222015,40.357548]]]},"properties":{"id":"15923","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.593911,40.631457],[-79.58738,40.62707],[-79.582823,40.633169],[-79.586935,40.636737],[-79.593911,40.631457]]]},"properties":{"id":"15641","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.66892,40.39264],[-79.671456,40.340639],[-79.67189,40.33718],[-79.671105,40.335848],[-79.66028,40.3423],[-79.659682,40.342597],[-79.653152,40.351687],[-79.65824,40.39597],[-79.66892,40.39264]]]},"properties":{"id":"15636","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.663139,40.269364],[-79.657423,40.275135],[-79.664868,40.274454],[-79.663139,40.269364]]]},"properties":{"id":"15617","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.58997,40.313957],[-79.603065,40.321377],[-79.606424,40.328024],[-79.611505,40.325165],[-79.601246,40.318997],[-79.58997,40.313957]]]},"properties":{"id":"15634","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.192722,40.414862],[-79.24755,40.384893],[-79.217199,40.360768],[-79.192722,40.414862]]]},"properties":{"id":"15779","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.844502,41.500078],[-75.85843,41.51589],[-75.86352,41.503758],[-75.844502,41.500078]]]},"properties":{"id":"18625","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.726281,39.974062],[-76.746036,39.949441],[-76.718142,39.953163],[-76.726281,39.974062]]]},"properties":{"id":"17401","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.472309,39.937461],[-76.655382,39.854141],[-76.646805,39.828763],[-76.531301,39.79309],[-76.505054,39.804215],[-76.498872,39.825462],[-76.404929,39.915213],[-76.45355,39.937544],[-76.472309,39.937461]]]},"properties":{"id":"17322","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.724959,39.979632],[-76.680132,39.907435],[-76.679141,39.907589],[-76.654684,39.930002],[-76.603546,39.942533],[-76.737054,40.000692],[-76.724959,39.979632]]]},"properties":{"id":"17402","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.856643,40.096962],[-76.932974,40.093432],[-77.000731,40.026991],[-76.953741,40.011325],[-76.874363,40.071101],[-76.849736,40.094528],[-76.856643,40.096962]]]},"properties":{"id":"17365","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.109173,39.806226],[-77.038637,39.719899],[-76.89793,39.720463],[-76.880368,39.777204],[-76.931142,39.868438],[-77.006255,39.865366],[-77.082361,39.824052],[-77.109173,39.806226]],[[-77.028893,39.799444],[-77.020689,39.813524],[-77.007952,39.805207],[-77.028893,39.799444]]]},"properties":{"id":"17331","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.028893,39.799444],[-77.007952,39.805207],[-77.020689,39.813524],[-77.028893,39.799444]]]},"properties":{"id":"17344","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.295236,40.48928],[-80.360873,40.477539],[-80.296443,40.425329],[-80.252403,40.434486],[-80.211457,40.449287],[-80.281392,40.498627],[-80.295236,40.48928]]]},"properties":{"id":"15126","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.009341,40.32112],[-80.038165,40.289194],[-79.959829,40.279486],[-79.979327,40.318338],[-80.009341,40.32112]]]},"properties":{"id":"15129","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.252403,40.434486],[-80.206683,40.385505],[-80.207228,40.380348],[-80.127415,40.360947],[-80.120495,40.372373],[-80.131428,40.396118],[-80.159874,40.432655],[-80.172161,40.451795],[-80.211457,40.449287],[-80.252403,40.434486]]]},"properties":{"id":"15071","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.993874,40.554004],[-80.018303,40.58526],[-80.085754,40.594143],[-80.120288,40.51314],[-80.108363,40.510521],[-80.062639,40.520041],[-80.012833,40.500076],[-79.99017,40.506216],[-79.988204,40.524112],[-79.993874,40.554004]]]},"properties":{"id":"15237","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.062639,40.520041],[-80.037069,40.507202],[-80.012833,40.500076],[-80.062639,40.520041]]]},"properties":{"id":"15229","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.206683,40.385505],[-80.214911,40.380575],[-80.207228,40.380348],[-80.206683,40.385505]]]},"properties":{"id":"15082","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.779394,40.41111],[-79.783102,40.400898],[-79.769731,40.404232],[-79.779394,40.41111]]]},"properties":{"id":"15140","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.800242,40.294746],[-79.787608,40.299211],[-79.785882,40.359481],[-79.826515,40.362056],[-79.816069,40.319611],[-79.796214,40.318319],[-79.790901,40.308575],[-79.801926,40.303531],[-79.800242,40.294746]]]},"properties":{"id":"15131","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.7022,40.503331],[-79.783348,40.494836],[-79.781223,40.490018],[-79.779423,40.460961],[-79.703834,40.443526],[-79.7022,40.503331]]]},"properties":{"id":"15239","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.099135,40.49943],[-80.171791,40.458528],[-80.083913,40.459466],[-80.051178,40.465599],[-80.048394,40.477106],[-80.053179,40.48217],[-80.067526,40.492271],[-80.099135,40.49943]]]},"properties":{"id":"15136","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.68947,40.399773],[-79.722151,40.409098],[-79.769288,40.388434],[-79.771082,40.375419],[-79.741942,40.360899],[-79.732726,40.37489],[-79.7232,40.36703],[-79.66892,40.39264],[-79.65824,40.39597],[-79.671487,40.401011],[-79.68947,40.399773]]]},"properties":{"id":"15085","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.874553,40.638147],[-79.88351,40.63488],[-79.881192,40.629662],[-79.870774,40.633737],[-79.874553,40.638147]]]},"properties":{"id":"15006","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.540021,40.760628],[-79.535999,40.758423],[-79.531451,40.761164],[-79.534864,40.763119],[-79.540021,40.760628]]]},"properties":{"id":"16228","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.593911,40.631457],[-79.571795,40.693992],[-79.582014,40.707402],[-79.692587,40.669732],[-79.692476,40.668867],[-79.686413,40.64385],[-79.647659,40.571642],[-79.607668,40.598276],[-79.58738,40.62707],[-79.593911,40.631457]]]},"properties":{"id":"15656","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.402635,40.699023],[-79.421834,40.652751],[-79.370453,40.628622],[-79.360728,40.622172],[-79.341507,40.584877],[-79.339356,40.581085],[-79.300638,40.581969],[-79.28853,40.562139],[-79.273847,40.566071],[-79.260641,40.585282],[-79.279664,40.692609],[-79.275272,40.743554],[-79.286813,40.748599],[-79.289289,40.749087],[-79.33094,40.722747],[-79.340353,40.699203],[-79.402635,40.699023]]]},"properties":{"id":"15774","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.402635,40.699023],[-79.340353,40.699203],[-79.368541,40.716848],[-79.402635,40.699023]]]},"properties":{"id":"15736","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.296297,40.988702],[-79.290616,41.010018],[-79.310307,41.008004],[-79.296297,40.988702]]]},"properties":{"id":"16245","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.287584,40.695466],[-80.266081,40.691772],[-80.189405,40.712811],[-80.151953,40.729843],[-80.152562,40.744099],[-80.163789,40.772475],[-80.303268,40.716153],[-80.287584,40.695466]]]},"properties":{"id":"15074","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.460829,40.714943],[-80.444495,40.623769],[-80.432396,40.627252],[-80.456194,40.713373],[-80.460829,40.714943]]]},"properties":{"id":"15052","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.246001,40.140194],[-78.17704,40.109548],[-78.158214,40.122383],[-78.151112,40.135785],[-78.15101,40.175072],[-78.181148,40.192381],[-78.201998,40.2043],[-78.223356,40.190763],[-78.242196,40.158539],[-78.230834,40.162174],[-78.227314,40.159926],[-78.240443,40.151212],[-78.246001,40.140194]]]},"properties":{"id":"16679","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.457181,40.258541],[-78.447895,40.244395],[-78.4421,40.271574],[-78.431823,40.30913],[-78.539083,40.345766],[-78.564616,40.284129],[-78.459962,40.260022],[-78.460407,40.27204],[-78.453714,40.269892],[-78.457181,40.258541]],[[-78.507503,40.259158],[-78.513544,40.262657],[-78.511348,40.265574],[-78.504366,40.264856],[-78.507503,40.259158]]]},"properties":{"id":"16625","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.903672,40.317237],[-75.802204,40.326558],[-75.804501,40.337006],[-75.805061,40.343414],[-75.805544,40.344593],[-75.859659,40.37977],[-75.893149,40.384401],[-75.894941,40.377442],[-75.906863,40.345795],[-75.903672,40.317237]]]},"properties":{"id":"19606","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.918973,40.340258],[-75.91513,40.338252],[-75.906863,40.345795],[-75.894941,40.377442],[-75.899262,40.376156],[-75.921251,40.353285],[-75.918973,40.340258]]]},"properties":{"id":"19604","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.005824,40.347441],[-75.992658,40.312644],[-75.984351,40.309464],[-76.005824,40.347441]]]},"properties":{"id":"19609","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.111213,40.513674],[-76.09804,40.508394],[-76.098756,40.51515],[-76.111213,40.513674]]]},"properties":{"id":"19554","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.855852,40.182597],[-75.861456,40.121194],[-75.794771,40.103892],[-75.738548,40.148545],[-75.743178,40.216721],[-75.795954,40.212023],[-75.827894,40.203476],[-75.855852,40.182597]]]},"properties":{"id":"19520","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.266294,40.391845],[-76.249943,40.343707],[-76.231916,40.366292],[-76.266294,40.391845]]],[[[-76.291443,40.417582],[-76.266393,40.392271],[-76.245258,40.445349],[-76.261449,40.46766],[-76.289923,40.457107],[-76.291458,40.417594],[-76.291443,40.417582]]]]},"properties":{"id":"17087","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.72783,40.600704],[-75.796452,40.596034],[-75.877609,40.538425],[-75.874647,40.525838],[-75.869737,40.520211],[-75.853325,40.501077],[-75.769226,40.481599],[-75.754175,40.483348],[-75.736205,40.48765],[-75.712223,40.504532],[-75.700806,40.516368],[-75.69561,40.542085],[-75.702371,40.540785],[-75.709317,40.552033],[-75.686411,40.543006],[-75.682238,40.543638],[-75.70773,40.580071],[-75.708872,40.586412],[-75.72783,40.600704]]]},"properties":{"id":"19530","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.57282,40.341646],[-75.588439,40.354312],[-75.578391,40.33531],[-75.57282,40.341646]]],[[[-75.624996,40.346423],[-75.615089,40.342087],[-75.590845,40.356793],[-75.659274,40.42662],[-75.66093,40.426444],[-75.624996,40.346423]]]]},"properties":{"id":"19505","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.238358,40.454879],[-76.261449,40.46766],[-76.245258,40.445349],[-76.232764,40.448048],[-76.238358,40.454879]]]},"properties":{"id":"19550","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.552277,40.426026],[-75.516584,40.438828],[-75.529694,40.446995],[-75.550324,40.434468],[-75.552277,40.426026]]]},"properties":{"id":"18070","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.686411,40.543006],[-75.709317,40.552033],[-75.702371,40.540785],[-75.69561,40.542085],[-75.686411,40.543006]]]},"properties":{"id":"19538","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.560658,40.553472],[-78.587932,40.48394],[-78.567537,40.495447],[-78.560658,40.553472]]],[[[-78.542303,40.468851],[-78.441375,40.476264],[-78.537116,40.50992],[-78.565125,40.48831],[-78.542303,40.468851]]]]},"properties":{"id":"16641","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.421426,40.449778],[-78.403243,40.448769],[-78.397489,40.458976],[-78.421703,40.44986],[-78.421426,40.449778]]],[[[-78.336167,40.593343],[-78.408629,40.644349],[-78.440167,40.638761],[-78.477526,40.550796],[-78.537116,40.50992],[-78.441375,40.476264],[-78.427015,40.469922],[-78.381917,40.495261],[-78.270066,40.530199],[-78.234459,40.562156],[-78.31618,40.606302],[-78.336167,40.593343]]]]},"properties":{"id":"16601","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.31618,40.606302],[-78.344779,40.607275],[-78.336167,40.593343],[-78.31618,40.606302]]]},"properties":{"id":"16617","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.38154,41.998481],[-76.638104,42.000738],[-76.686859,41.941377],[-76.679528,41.917631],[-76.637756,41.925344],[-76.380364,41.960418],[-76.38154,41.998481]]]},"properties":{"id":"18840","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.495903,41.849171],[-76.411822,41.871917],[-76.391356,41.91453],[-76.416194,41.916091],[-76.495903,41.849171]]],[[[-76.495903,41.849171],[-76.516189,41.880566],[-76.617639,41.866755],[-76.621336,41.843676],[-76.633344,41.810116],[-76.615382,41.785652],[-76.595619,41.781388],[-76.495903,41.849171]]]]},"properties":{"id":"18850","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.156674,41.998761],[-76.178333,41.883222],[-76.135671,41.891812],[-76.145519,41.998842],[-76.156674,41.998761]]]},"properties":{"id":"18851","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.861671,40.159256],[-74.817853,40.140628],[-74.841735,40.165492],[-74.861671,40.159256]]]},"properties":{"id":"19055","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.071198,40.171032],[-75.024247,40.151373],[-74.958996,40.180896],[-74.941784,40.20995],[-74.94441,40.211453],[-74.979317,40.219292],[-75.032579,40.21724],[-75.071198,40.171032]]]},"properties":{"id":"18966","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.387542,40.408487],[-75.376517,40.407926],[-75.370586,40.413667],[-75.385248,40.416133],[-75.387542,40.408487]]]},"properties":{"id":"18970","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.856351,40.8754],[-79.844262,40.886056],[-79.854425,40.88536],[-79.856351,40.8754]]]},"properties":{"id":"16029","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.094667,40.745128],[-80.058202,40.722349],[-79.978645,40.744806],[-79.985893,40.74788],[-80.011182,40.824043],[-80.010268,40.816467],[-80.028835,40.815776],[-80.029255,40.821447],[-80.028422,40.865716],[-80.066206,40.859526],[-80.094854,40.803031],[-80.094667,40.745128]],[[-80.040583,40.743471],[-80.032118,40.741578],[-80.035045,40.736245],[-80.040583,40.743471]]]},"properties":{"id":"16033","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.91955,40.841619],[-79.923212,40.852257],[-79.918611,40.860685],[-79.910803,40.860412],[-79.912133,40.85013],[-79.918856,40.841513],[-79.856351,40.8754],[-79.854425,40.88536],[-79.844262,40.886056],[-79.813357,40.911664],[-79.84632,40.936215],[-79.947862,41.005456],[-80.035321,40.956419],[-80.017802,40.872709],[-79.927553,40.816395],[-79.91955,40.841619]]]},"properties":{"id":"16001","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.71432,41.319361],[-79.860883,41.18975],[-79.868936,41.189656],[-79.867372,41.172759],[-79.814298,41.173134],[-79.797754,41.146174],[-79.787239,41.146319],[-79.684015,41.151224],[-79.678522,41.154885],[-79.636938,41.1533],[-79.55463,41.130119],[-79.540757,41.163218],[-79.615895,41.290968],[-79.71432,41.319361]]]},"properties":{"id":"16373","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.99578,41.098996],[-80.008409,41.111167],[-80.02189,41.112214],[-79.99578,41.098996]]]},"properties":{"id":"16035","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.895747,40.335939],[-78.892485,40.340013],[-78.927822,40.323255],[-78.914553,40.314246],[-78.895747,40.335939]]]},"properties":{"id":"15901","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.781968,40.572254],[-78.777035,40.601416],[-78.794435,40.638489],[-78.808588,40.633006],[-78.866087,40.592926],[-78.879006,40.543065],[-78.781968,40.572254]]]},"properties":{"id":"15762","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.615102,40.574341],[-78.596384,40.555943],[-78.606869,40.582686],[-78.615102,40.574341]]]},"properties":{"id":"16624","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.862823,40.367048],[-78.876197,40.353502],[-78.860223,40.360384],[-78.862823,40.367048]]]},"properties":{"id":"15945","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.78443,40.647233],[-78.808588,40.633006],[-78.794435,40.638489],[-78.78443,40.647233]]]},"properties":{"id":"15760","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.855562,40.992635],[-75.893883,40.994421],[-75.888499,40.952035],[-75.90369,40.926823],[-75.955694,40.909444],[-75.976288,40.908255],[-75.957873,40.878667],[-75.951537,40.873306],[-75.761762,40.883699],[-75.713118,40.988622],[-75.855562,40.992635]]]},"properties":{"id":"18255","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.955694,40.909444],[-75.964623,40.924949],[-75.983192,40.90784],[-75.976288,40.908255],[-75.955694,40.909444]]]},"properties":{"id":"18254","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.691183,40.771216],[-75.703661,40.787013],[-75.72545,40.778085],[-75.69609,40.768898],[-75.691183,40.771216]]]},"properties":{"id":"18212","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.14416,41.044338],[-77.514275,40.969562],[-77.540986,40.956601],[-77.519269,40.921486],[-77.478819,40.912787],[-77.463801,40.915702],[-77.275895,40.961321],[-77.221631,40.973282],[-77.14416,41.044338]]]},"properties":{"id":"16872","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.555095,40.949742],[-77.519269,40.921486],[-77.540986,40.956601],[-77.555095,40.949742]]]},"properties":{"id":"16852","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.078121,40.703813],[-77.972841,40.760439],[-77.939424,40.775485],[-78.116521,40.739127],[-78.098277,40.690909],[-78.078121,40.703813]]]},"properties":{"id":"16877","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.275895,40.961321],[-77.315887,40.88267],[-77.24508,40.947403],[-77.221631,40.973282],[-77.275895,40.961321]]]},"properties":{"id":"16882","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.478819,40.912787],[-77.481135,40.882774],[-77.457728,40.893487],[-77.463801,40.915702],[-77.478819,40.912787]]]},"properties":{"id":"16854","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.199898,40.932861],[-78.199591,40.93057],[-78.192382,40.920568],[-78.122957,40.94309],[-78.135649,40.966677],[-78.170937,40.959293],[-78.187392,40.962537],[-78.193325,40.95086],[-78.199898,40.932861]]]},"properties":{"id":"16860","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.000767,40.085192],[-76.005192,40.040138],[-76.006523,39.966724],[-75.987858,39.962302],[-75.972407,39.98751],[-75.93487,40.006899],[-75.938429,40.02722],[-75.943024,40.027765],[-76.000767,40.085192]]]},"properties":{"id":"17527","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.605189,40.168998],[-75.531824,40.170612],[-75.580416,40.203855],[-75.583883,40.200058],[-75.589494,40.20226],[-75.595964,40.205319],[-75.673951,40.150684],[-75.674178,40.150449],[-75.618521,40.144355],[-75.605189,40.168998]]]},"properties":{"id":"19475","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.680222,40.071019],[-75.638013,40.014984],[-75.584689,40.029602],[-75.618462,40.056072],[-75.680222,40.071019]]]},"properties":{"id":"19341","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.487409,40.057856],[-75.475776,40.018369],[-75.459194,40.032895],[-75.487409,40.057856]]]},"properties":{"id":"19301","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.874879,39.722545],[-75.93762,39.840659],[-76.004047,39.870592],[-76.020413,39.846609],[-76.066845,39.807914],[-76.080086,39.802505],[-75.96215,39.721792],[-75.875102,39.72194],[-75.874879,39.722545]]]},"properties":{"id":"19363","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.93762,39.840659],[-75.874879,39.722545],[-75.843064,39.781957],[-75.891264,39.825887],[-75.93762,39.840659]]]},"properties":{"id":"19352","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.472686,41.170208],[-79.363646,41.129729],[-79.326095,41.129407],[-79.314404,41.127238],[-79.27739,41.172498],[-79.290519,41.284201],[-79.367982,41.259053],[-79.472686,41.170208]]]},"properties":{"id":"16214","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.368126,41.37758],[-79.381431,41.339258],[-79.350416,41.374437],[-79.358589,41.376242],[-79.368126,41.37758]]]},"properties":{"id":"16332","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.25582,41.545037],[-79.204994,41.626405],[-79.210193,41.626422],[-79.361113,41.626416],[-79.386119,41.581655],[-79.404445,41.569963],[-79.405667,41.570011],[-79.470885,41.532709],[-79.511432,41.496927],[-79.47805,41.411114],[-79.455332,41.38648],[-79.408211,41.344739],[-79.381431,41.339258],[-79.368126,41.37758],[-79.358589,41.376242],[-79.328491,41.395224],[-79.320159,41.432156],[-79.25582,41.545037]]]},"properties":{"id":"16353","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.661379,40.864876],[-78.62384,40.92448],[-78.70753,40.993231],[-78.708162,40.993235],[-78.821317,40.906048],[-78.839423,40.848475],[-78.787889,40.824333],[-78.784552,40.806227],[-78.732426,40.785658],[-78.661379,40.864876]]]},"properties":{"id":"15757","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.656279,41.053513],[-78.70753,40.993231],[-78.62384,40.92448],[-78.593167,41.052611],[-78.656279,41.053513]]]},"properties":{"id":"16838","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.071128,41.187651],[-77.973012,41.173021],[-77.959438,41.217497],[-78.071128,41.187651]]]},"properties":{"id":"16871","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.777842,41.021044],[-78.804142,41.031924],[-78.804041,41.018191],[-78.777842,41.021044]]]},"properties":{"id":"15866","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.355748,40.988627],[-78.373459,41.007381],[-78.395359,40.991102],[-78.355748,40.988627]]]},"properties":{"id":"16855","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.324295,41.182487],[-77.324086,41.175858],[-77.307308,41.182696],[-77.315742,41.189926],[-77.327309,41.186687],[-77.324295,41.182487]]]},"properties":{"id":"17721","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.898195,41.252752],[-77.988661,41.421159],[-77.988608,41.398334],[-78.093381,41.216931],[-78.071128,41.187651],[-77.959438,41.217497],[-77.898195,41.252752]]]},"properties":{"id":"17778","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.826161,41.476994],[-77.727999,41.353182],[-77.598129,41.478576],[-77.599151,41.525226],[-77.599109,41.527539],[-77.599449,41.549521],[-77.600321,41.588422],[-77.827816,41.617063],[-77.826161,41.476994]]]},"properties":{"id":"17729","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.352218,41.154449],[-77.354747,41.146012],[-77.34467,41.14399],[-77.344057,41.15186],[-77.352218,41.154449]]]},"properties":{"id":"17748","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.425552,41.044748],[-76.444665,41.10603],[-76.59329,41.149172],[-76.617433,41.144597],[-76.512421,40.946102],[-76.273046,40.894242],[-76.207827,40.94974],[-76.184632,40.974075],[-76.219461,41.00487],[-76.319112,41.022595],[-76.303474,41.037905],[-76.369896,41.068541],[-76.425552,41.044748]]]},"properties":{"id":"17815","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.19838,41.062064],[-76.301433,41.03817],[-76.319112,41.022595],[-76.219461,41.00487],[-76.141015,41.022957],[-76.053853,41.057881],[-76.19838,41.062064]]]},"properties":{"id":"18635","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.266156,41.890543],[-80.268862,41.849472],[-80.235826,41.803248],[-80.221717,41.803111],[-80.053365,41.879791],[-80.092017,41.942007],[-80.184198,41.95907],[-80.244724,41.935519],[-80.266156,41.890543]],[[-80.115875,41.867091],[-80.127266,41.868144],[-80.123024,41.874171],[-80.115875,41.867091]]]},"properties":{"id":"16412","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519413,41.738442],[-80.519374,41.57847],[-80.420915,41.576308],[-80.393931,41.60549],[-80.315152,41.654175],[-80.318979,41.674421],[-80.519413,41.738442]]]},"properties":{"id":"16424","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.028412,41.58192],[-79.933817,41.559942],[-79.859512,41.584697],[-79.854718,41.614448],[-79.950784,41.720022],[-80.02534,41.698028],[-80.041,41.681206],[-80.028412,41.58192]]]},"properties":{"id":"16327","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.355409,41.484902],[-80.262698,41.483436],[-80.23916,41.482842],[-80.226819,41.539623],[-80.315576,41.559584],[-80.330366,41.549567],[-80.359231,41.505469],[-80.355409,41.484902]]]},"properties":{"id":"16111","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.269957,40.278671],[-77.13295,40.295077],[-77.229166,40.283744],[-77.269957,40.278671]]]},"properties":{"id":"17013","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.06578,40.294273],[-76.95,40.27739],[-76.914977,40.271422],[-76.913911,40.271938],[-76.914112,40.274466],[-76.915595,40.315594],[-77.063874,40.301967],[-77.06578,40.294273]],[[-76.929066,40.311331],[-76.926792,40.301825],[-76.940354,40.309056],[-76.929066,40.311331]]]},"properties":{"id":"17025","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.915595,40.315594],[-76.932147,40.354596],[-76.933589,40.356045],[-77.12783,40.340591],[-77.13295,40.295077],[-77.063874,40.301967],[-76.915595,40.315594]]]},"properties":{"id":"17053","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.649055,40.335935],[-76.66077,40.354777],[-76.680792,40.353353],[-76.737607,40.319117],[-76.736981,40.251248],[-76.671518,40.226344],[-76.659579,40.229059],[-76.645612,40.238259],[-76.633662,40.243906],[-76.649055,40.335935]]]},"properties":{"id":"17036","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.79571,40.633975],[-76.767815,40.552781],[-76.68659,40.560732],[-76.685376,40.567132],[-76.659534,40.595266],[-76.639697,40.619836],[-76.641566,40.621001],[-76.665469,40.635601],[-76.6797,40.644509],[-76.689876,40.651003],[-76.701624,40.658082],[-76.794708,40.640836],[-76.79571,40.633975]],[[-76.752125,40.619981],[-76.713018,40.622208],[-76.720353,40.592536],[-76.752125,40.619981]]]},"properties":{"id":"17048","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.364898,39.953513],[-75.359864,39.91277],[-75.358306,39.910871],[-75.337642,39.905698],[-75.319393,39.91169],[-75.308225,39.940529],[-75.334361,39.954511],[-75.335132,39.954973],[-75.364898,39.953513]]]},"properties":{"id":"19064","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.525513,39.921935],[-75.546771,39.881002],[-75.515095,39.889415],[-75.525513,39.921935]]]},"properties":{"id":"19373","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.379965,40.009295],[-75.3682,40.000982],[-75.344986,39.991885],[-75.293566,40.033181],[-75.30103,40.058108],[-75.379965,40.009295]]]},"properties":{"id":"19010","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.336489,39.875553],[-75.322055,39.859343],[-75.31347,39.866342],[-75.306709,39.875653],[-75.313855,39.892788],[-75.335937,39.877085],[-75.336489,39.875553]]]},"properties":{"id":"19078","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.54087,39.838892],[-75.463409,39.824008],[-75.465732,39.86119],[-75.468181,39.87755],[-75.473108,39.879968],[-75.538236,39.850343],[-75.54087,39.838892]]]},"properties":{"id":"19060","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.386079,39.888085],[-75.419172,39.877165],[-75.386377,39.85007],[-75.378389,39.874882],[-75.386079,39.888085]]]},"properties":{"id":"19015","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.225814,42.053959],[-80.241462,42.07928],[-80.300312,42.051443],[-80.286821,42.02888],[-80.203975,41.986533],[-80.158636,42.035047],[-80.225814,42.053959]]]},"properties":{"id":"16415","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125955,42.097754],[-80.114681,42.108357],[-80.115408,42.110966],[-80.114437,42.11911],[-80.118561,42.129849],[-80.0879,42.148598],[-80.075425,42.153151],[-80.241462,42.07928],[-80.225814,42.053959],[-80.125955,42.097754]]]},"properties":{"id":"16505","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.385723,42.022627],[-80.387962,41.988083],[-80.286821,42.02888],[-80.300312,42.051443],[-80.385723,42.022627]]]},"properties":{"id":"16423","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.115875,41.867091],[-80.123024,41.874171],[-80.127266,41.868144],[-80.115875,41.867091]]]},"properties":{"id":"16444","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.488405,39.926831],[-79.487258,39.869068],[-79.380637,39.925188],[-79.35975,39.96381],[-79.484294,39.977171],[-79.512973,39.967722],[-79.488405,39.926831]]]},"properties":{"id":"15464","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.52441,39.73764],[-79.702157,39.720977],[-79.533155,39.720856],[-79.52441,39.73764]]]},"properties":{"id":"15440","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.58193,40.08526],[-79.600846,40.081292],[-79.588,40.08071],[-79.58193,40.08526]]]},"properties":{"id":"15631","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.659357,41.533564],[-79.511432,41.496927],[-79.470885,41.532709],[-79.441743,41.578769],[-79.493454,41.624716],[-79.556811,41.62435],[-79.659357,41.533564]]]},"properties":{"id":"16341","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.519927,39.850208],[-77.503664,39.855429],[-77.490689,39.88026],[-77.523409,39.859655],[-77.527943,39.850284],[-77.519927,39.850208]]]},"properties":{"id":"17261","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736215,40.101606],[-77.830436,40.112865],[-77.844598,40.08935],[-77.863893,40.06147],[-77.753907,40.088498],[-77.736215,40.101606]]]},"properties":{"id":"17271","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.878652,40.052841],[-77.828794,40.035077],[-77.753907,40.088498],[-77.863893,40.06147],[-77.864489,40.061568],[-77.878652,40.052841]]]},"properties":{"id":"17221","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736135,40.215294],[-77.697307,40.185183],[-77.646283,40.240663],[-77.670212,40.256087],[-77.65966,40.274536],[-77.69581,40.258291],[-77.736135,40.215294]]]},"properties":{"id":"17219","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.69581,40.258291],[-77.70303,40.263576],[-77.749971,40.224033],[-77.736135,40.215294],[-77.69581,40.258291]]]},"properties":{"id":"17217","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.010463,39.98472],[-80.057765,40.008485],[-80.098001,39.972099],[-80.07895,39.962943],[-80.035529,39.942466],[-80.010557,39.983342],[-80.010463,39.98472]]]},"properties":{"id":"15322","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.258914,39.806274],[-80.325653,39.888837],[-80.332747,39.892234],[-80.358957,39.906316],[-80.415634,39.870584],[-80.402158,39.812779],[-80.27185,39.757367],[-80.258914,39.806274]]]},"properties":{"id":"15341","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.325653,39.888837],[-80.281063,39.874711],[-80.275687,39.878191],[-80.271687,39.882096],[-80.271394,39.881543],[-80.228876,39.980523],[-80.308827,40.00694],[-80.307974,39.961307],[-80.306245,39.959711],[-80.306501,39.955234],[-80.350875,39.918756],[-80.332747,39.892234],[-80.325653,39.888837]]]},"properties":{"id":"15364","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.271687,39.882096],[-80.275687,39.878191],[-80.281063,39.874711],[-80.271394,39.881543],[-80.271687,39.882096]]]},"properties":{"id":"15359","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.433549,39.814368],[-80.519225,39.828341],[-80.519495,39.744359],[-80.433549,39.814368]]]},"properties":{"id":"15310","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.162894,40.003858],[-80.098001,39.972099],[-80.057765,40.008485],[-80.055174,40.031534],[-80.12073,40.064624],[-80.162894,40.003858]]]},"properties":{"id":"15345","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.019993,40.329186],[-78.050162,40.313441],[-78.096653,40.221499],[-78.090142,40.21418],[-78.036211,40.261693],[-78.003491,40.329095],[-78.019993,40.329186]]]},"properties":{"id":"16623","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.013585,40.221594],[-78.000906,40.206046],[-78.004134,40.216614],[-78.013585,40.221594]]]},"properties":{"id":"17253","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.050162,40.313441],[-78.019993,40.329186],[-78.123935,40.342536],[-78.10142,40.320207],[-78.050162,40.313441]]]},"properties":{"id":"16622","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.08164,40.838796],[-78.94921,40.780669],[-78.90322,40.800748],[-78.903317,40.81594],[-78.925896,40.828862],[-78.971681,40.862948],[-79.061976,40.876643],[-79.08164,40.838796]]]},"properties":{"id":"15771","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.088666,40.464594],[-79.048625,40.449224],[-79.008744,40.459732],[-79.013875,40.466617],[-79.075406,40.487184],[-79.088666,40.464594]]]},"properties":{"id":"15920","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.998835,40.468878],[-79.013875,40.466617],[-79.008744,40.459732],[-78.983631,40.465646],[-78.998835,40.468878]]]},"properties":{"id":"15929","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.01564,40.995797],[-79.031372,40.993181],[-79.033328,40.979955],[-79.01896,40.977227],[-79.01564,40.995797]]]},"properties":{"id":"15764","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.163541,40.99656],[-79.190102,40.976628],[-79.184696,40.966755],[-79.163541,40.99656]]],[[[-79.14421,41.006266],[-79.125664,41.011256],[-79.134295,41.021715],[-79.141909,41.021791],[-79.14421,41.006266]]]]},"properties":{"id":"15770","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.190102,40.976628],[-79.212546,40.96089],[-79.184696,40.966755],[-79.190102,40.976628]]]},"properties":{"id":"15778","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.112335,40.727897],[-77.171779,40.709017],[-77.269444,40.691344],[-77.269737,40.691271],[-77.143301,40.642457],[-77.077876,40.656852],[-77.060084,40.748021],[-77.112335,40.727897]]]},"properties":{"id":"17086","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.077876,40.656852],[-77.143301,40.642457],[-77.141376,40.638493],[-77.268812,40.525769],[-77.375355,40.478175],[-77.342602,40.452065],[-77.301994,40.454783],[-77.287246,40.455376],[-77.069827,40.524555],[-77.046069,40.624826],[-77.077876,40.656852]]]},"properties":{"id":"17062","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.372235,41.237959],[-75.443337,41.299295],[-75.652418,41.249562],[-75.665619,41.210007],[-75.645595,41.142762],[-75.625955,41.130343],[-75.52129,41.145084],[-75.462863,41.199387],[-75.372235,41.237959]]]},"properties":{"id":"18424","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.816832,41.386707],[-75.822456,41.39944],[-75.831555,41.396906],[-75.816832,41.386707]]]},"properties":{"id":"18653","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.433719,40.104021],[-76.388598,40.092598],[-76.361213,40.097095],[-76.34713,40.110307],[-76.429532,40.23899],[-76.467283,40.256952],[-76.46738,40.233669],[-76.478391,40.226495],[-76.527229,40.218901],[-76.547531,40.210094],[-76.5386,40.205667],[-76.51775,40.169555],[-76.433719,40.104021]]]},"properties":{"id":"17545","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.433719,40.104021],[-76.51775,40.169555],[-76.564637,40.130502],[-76.564921,40.128723],[-76.591064,40.098643],[-76.527989,40.064077],[-76.436936,40.06424],[-76.425997,40.078966],[-76.433719,40.104021]]]},"properties":{"id":"17552","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.056744,40.155277],[-76.04325,40.154357],[-76.044087,40.160163],[-76.052407,40.16292],[-76.058142,40.158494],[-76.056744,40.155277]]]},"properties":{"id":"17581","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.374407,41.207221],[-80.489626,41.189506],[-80.505505,41.198831],[-80.518793,41.20993],[-80.519093,41.097375],[-80.504967,41.097262],[-80.406618,41.161133],[-80.3732,41.197581],[-80.374407,41.207221]]]},"properties":{"id":"16159","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.49544,40.994402],[-80.519014,41.011842],[-80.519013,40.994109],[-80.49544,40.994402]]]},"properties":{"id":"16132","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.58632,40.278537],[-76.585045,40.276322],[-76.58323,40.277599],[-76.58632,40.278537]]]},"properties":{"id":"17010","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.459489,40.663726],[-75.483628,40.666873],[-75.46389,40.641945],[-75.459489,40.663726]]]},"properties":{"id":"18032","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.348824,40.546586],[-75.333955,40.622174],[-75.358334,40.6177],[-75.419638,40.616097],[-75.441851,40.599996],[-75.426061,40.570482],[-75.356488,40.545243],[-75.348824,40.546586]]]},"properties":{"id":"18015","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.631332,40.597196],[-75.595884,40.59129],[-75.589693,40.655545],[-75.661928,40.64161],[-75.67016,40.636531],[-75.673651,40.624321],[-75.631332,40.597196]]]},"properties":{"id":"18069","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.519955,40.484098],[-75.51923,40.484312],[-75.520216,40.485635],[-75.520788,40.485348],[-75.519955,40.484098]]]},"properties":{"id":"18068","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.849087,41.294549],[-75.898875,41.294529],[-75.885129,41.280285],[-75.895547,41.280773],[-75.906292,41.289434],[-75.912748,41.292685],[-75.928442,41.288074],[-75.911214,41.250107],[-75.890988,41.245569],[-75.884291,41.248456],[-75.870717,41.261036],[-75.868243,41.284339],[-75.849087,41.294549]]]},"properties":{"id":"18704","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.922851,41.344423],[-76.039257,41.270879],[-75.997078,41.249755],[-75.928442,41.288074],[-75.912748,41.292685],[-75.922851,41.344423]]]},"properties":{"id":"18708","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.975698,40.97929],[-75.967467,40.983732],[-75.975554,40.980934],[-75.97559,40.980559],[-75.975698,40.97929]]]},"properties":{"id":"18225","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.822139,41.30858],[-75.794438,41.340479],[-75.795286,41.378127],[-75.816832,41.386707],[-75.831555,41.396906],[-75.831079,41.415459],[-75.888599,41.41267],[-75.901405,41.409563],[-75.860424,41.377495],[-75.822139,41.30858]]]},"properties":{"id":"18643","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.110059,41.274431],[-77.109882,41.224721],[-76.969918,41.245196],[-76.945359,41.324536],[-77.110059,41.274431]]],[[[-76.827349,41.411808],[-76.813162,41.335186],[-76.742163,41.331084],[-76.743284,41.401175],[-76.772402,41.471686],[-76.827349,41.411808]]]]},"properties":{"id":"17701","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125498,41.45071],[-80.235785,41.482792],[-80.262698,41.483436],[-80.214853,41.381834],[-80.203313,41.440462],[-80.211573,41.443333],[-80.210127,41.447106],[-80.201998,41.443906],[-80.164123,41.429394],[-80.125498,41.45071]]]},"properties":{"id":"16130","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.164123,41.429394],[-80.201998,41.443906],[-80.203313,41.440462],[-80.214853,41.381834],[-80.221146,41.368517],[-80.17862,41.369635],[-80.158539,41.388652],[-80.164123,41.429394]]]},"properties":{"id":"16114","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.378453,41.111571],[-75.384663,41.096768],[-75.354037,41.105721],[-75.378453,41.111571]]]},"properties":{"id":"18349","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.52129,41.145084],[-75.506038,41.077123],[-75.431122,41.103],[-75.432953,41.167646],[-75.462863,41.199387],[-75.52129,41.145084]]]},"properties":{"id":"18350","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.355702,40.9334],[-75.302465,40.925619],[-75.332498,40.932236],[-75.348999,40.935482],[-75.355702,40.9334]]]},"properties":{"id":"18354","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.24541,40.017369],[-75.235298,39.99707],[-75.206968,40.011372],[-75.208008,40.012997],[-75.228149,40.026031],[-75.24541,40.017369]]]},"properties":{"id":"19004","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.25,39.99018],[-75.235298,39.99707],[-75.24541,40.017369],[-75.262735,40.00063],[-75.251438,39.989504],[-75.25,39.99018]]]},"properties":{"id":"19066","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.214133,40.257422],[-75.23687,40.247812],[-75.243414,40.244971],[-75.293475,40.193337],[-75.279318,40.185719],[-75.255408,40.202095],[-75.236506,40.205346],[-75.228353,40.20833],[-75.236858,40.213459],[-75.22665,40.228741],[-75.221769,40.224222],[-75.229022,40.21713],[-75.226779,40.207371],[-75.177401,40.235047],[-75.214133,40.257422]]]},"properties":{"id":"19454","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.170256,40.126897],[-75.186259,40.152561],[-75.232347,40.131483],[-75.247666,40.122201],[-75.233766,40.112276],[-75.204271,40.120323],[-75.170256,40.126897]]]},"properties":{"id":"19034","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.188461,40.07275],[-75.166968,40.060413],[-75.154076,40.071732],[-75.171661,40.081848],[-75.188461,40.07275]]]},"properties":{"id":"19150","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.246675,40.736044],[-75.233386,40.702752],[-75.192883,40.715648],[-75.174248,40.774859],[-75.174682,40.775669],[-75.19195,40.777493],[-75.22131,40.786041],[-75.271338,40.764584],[-75.2571,40.747763],[-75.246675,40.736044]]]},"properties":{"id":"18040","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.879506,41.106656],[-76.877667,41.111836],[-76.881717,41.112929],[-76.884747,41.108138],[-76.879506,41.106656]]]},"properties":{"id":"17730","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.150712,40.001312],[-75.12926,39.998539],[-75.127287,40.007695],[-75.125988,40.013862],[-75.139367,40.023459],[-75.154245,40.025634],[-75.161627,40.019982],[-75.165758,40.003952],[-75.150712,40.001312]]]},"properties":{"id":"19140","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.095191,39.998443],[-75.091452,39.974662],[-75.052225,40.00546],[-75.061339,40.012764],[-75.096184,40.000241],[-75.095191,39.998443]]]},"properties":{"id":"19137","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.206072,39.935371],[-75.168423,39.930465],[-75.165153,39.945557],[-75.168421,39.945591],[-75.183288,39.949512],[-75.205185,39.941116],[-75.206072,39.935371]]]},"properties":{"id":"19146","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.020066,40.090171],[-75.044216,40.115747],[-75.07127,40.092532],[-75.076363,40.087265],[-75.065724,40.070193],[-75.035087,40.074617],[-75.020066,40.090171]]]},"properties":{"id":"19115","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.264433,39.882167],[-75.253515,39.876015],[-75.216015,39.871468],[-75.195236,39.877365],[-75.195514,39.89184],[-75.212428,39.92523],[-75.219196,39.923409],[-75.24694,39.916871],[-75.2525,39.906614],[-75.264433,39.882167]]]},"properties":{"id":"19153","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.193383,39.976648],[-75.159442,39.971467],[-75.156275,39.986283],[-75.192627,39.994771],[-75.193383,39.976648]]]},"properties":{"id":"19121","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.015989,41.288219],[-75.140559,41.210437],[-75.150591,41.172713],[-75.156507,41.150341],[-75.082677,41.141715],[-74.882311,41.180649],[-74.838762,41.276472],[-75.015989,41.288219]]]},"properties":{"id":"18328","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.728696,41.998759],[-77.6917,41.94564],[-77.690369,41.927021],[-77.65328,41.999445],[-77.728696,41.998759]]]},"properties":{"id":"16927","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.665469,40.635601],[-76.641566,40.621001],[-76.635832,40.63282],[-76.665469,40.635601]]],[[[-76.578661,40.640394],[-76.558953,40.646199],[-76.514338,40.648898],[-76.512458,40.642377],[-76.565686,40.635151],[-76.622898,40.609324],[-76.609607,40.600932],[-76.499563,40.622847],[-76.396156,40.664411],[-76.393873,40.698454],[-76.469642,40.693821],[-76.518149,40.674267],[-76.689876,40.651003],[-76.6797,40.644509],[-76.578661,40.640394]]]]},"properties":{"id":"17938","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.098097,40.862379],[-76.188954,40.877472],[-76.280024,40.887812],[-76.2991,40.831191],[-76.30717,40.801809],[-76.275138,40.813257],[-76.254714,40.820558],[-76.179496,40.847669],[-76.098097,40.862379]]]},"properties":{"id":"17967","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.209926,40.760299],[-76.14933,40.711097],[-76.096552,40.71791],[-76.194364,40.76914],[-76.209926,40.760299]]]},"properties":{"id":"17959","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.071595,40.839757],[-76.069462,40.846497],[-76.032784,40.870368],[-76.029113,40.901762],[-76.098097,40.862379],[-76.179496,40.847669],[-76.199481,40.804629],[-76.198361,40.800905],[-76.134464,40.810307],[-76.081389,40.825066],[-76.071595,40.839757]]]},"properties":{"id":"17948","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.043198,40.846843],[-76.007079,40.864381],[-76.032784,40.870368],[-76.069462,40.846497],[-76.043198,40.846843]]]},"properties":{"id":"18245","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.189864,40.568029],[-76.204514,40.571519],[-76.208343,40.550251],[-76.191631,40.556063],[-76.189864,40.568029]]]},"properties":{"id":"17979","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.279051,40.666534],[-76.275906,40.669464],[-76.278983,40.674668],[-76.283471,40.671523],[-76.278579,40.669945],[-76.279051,40.666534]]]},"properties":{"id":"17944","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.068558,40.776624],[-76.035817,40.761618],[-75.975009,40.811199],[-76.068558,40.776624]]]},"properties":{"id":"17982","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.051233,40.099132],[-79.071906,40.11295],[-79.073423,40.106309],[-79.051233,40.099132]]]},"properties":{"id":"15520","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.81832,41.88133],[-75.730997,41.908209],[-75.74122,41.96505],[-75.763536,41.997838],[-75.890619,41.998606],[-75.864791,41.958637],[-75.81832,41.88133]]]},"properties":{"id":"18822","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.81832,41.88133],[-75.775495,41.813541],[-75.70944,41.78293],[-75.702597,41.781953],[-75.702474,41.781778],[-75.68698,41.76452],[-75.676072,41.728619],[-75.64852,41.80198],[-75.730997,41.908209],[-75.81832,41.88133]]]},"properties":{"id":"18834","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.612861,41.663392],[-75.634025,41.672407],[-75.650713,41.642177],[-75.604694,41.647015],[-75.612861,41.663392]]]},"properties":{"id":"18441","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.58282,41.82104],[-75.58772,41.78525],[-75.586332,41.789672],[-75.58282,41.82104]]]},"properties":{"id":"18825","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.130696,41.836577],[-76.125747,41.770172],[-76.08669,41.753595],[-76.101246,41.83008],[-76.130696,41.836577]]]},"properties":{"id":"18828","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.36418,40.846937],[-77.356701,40.811365],[-77.315887,40.88267],[-77.36418,40.846937]]]},"properties":{"id":"17885","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.900346,40.162503],[-79.968495,40.157286],[-79.977478,40.164638],[-79.984921,40.165247],[-79.976854,40.113675],[-79.867384,40.113864],[-79.875324,40.122553],[-79.879161,40.125601],[-79.877961,40.12699],[-79.90417,40.151967],[-79.900346,40.162503]]]},"properties":{"id":"15022","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.132114,40.30627],[-80.112766,40.301825],[-80.116156,40.312324],[-80.132114,40.30627]]]},"properties":{"id":"15055","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.932991,40.003187],[-79.956682,39.997012],[-79.953176,39.994676],[-79.932991,40.003187]]]},"properties":{"id":"15429","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.359361,40.204512],[-80.500119,40.190146],[-80.460709,40.026928],[-80.356974,40.032863],[-80.340805,40.092235],[-80.359361,40.204512]]]},"properties":{"id":"15323","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519001,40.318146],[-80.518861,40.195897],[-80.500119,40.190146],[-80.359361,40.204512],[-80.355712,40.252043],[-80.35649,40.308594],[-80.519001,40.318146]],[[-80.417966,40.244108],[-80.426449,40.237836],[-80.428828,40.247444],[-80.417966,40.244108]]]},"properties":{"id":"15312","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.204985,40.251253],[-80.222277,40.248092],[-80.221141,40.223062],[-80.214969,40.222701],[-80.20704,40.238089],[-80.203938,40.248424],[-80.204985,40.251253]]]},"properties":{"id":"15342","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.292251,40.371354],[-80.29837,40.362977],[-80.285033,40.365484],[-80.292251,40.371354]]]},"properties":{"id":"15060","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.163397,40.214657],[-80.136933,40.125638],[-80.070783,40.097839],[-80.06729,40.108209],[-80.05883,40.098748],[-80.04098,40.110641],[-80.036628,40.181437],[-80.054502,40.243865],[-80.090387,40.253422],[-80.163397,40.214657]]]},"properties":{"id":"15330","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.87077,40.080211],[-79.854542,40.080062],[-79.842887,40.083593],[-79.848901,40.112136],[-79.855704,40.10492],[-79.879497,40.085001],[-79.87077,40.080211]]]},"properties":{"id":"15412","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.273766,40.275476],[-80.271717,40.276187],[-80.276708,40.28135],[-80.277263,40.27866],[-80.273766,40.275476]]]},"properties":{"id":"15378","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.42404,41.405045],[-75.40038,41.395727],[-75.400857,41.411185],[-75.42404,41.405045]]]},"properties":{"id":"18427","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.203402,40.179331],[-79.242318,40.194962],[-79.190802,40.139576],[-79.203402,40.179331]]],[[[-79.246126,40.205374],[-79.112212,40.211477],[-79.073699,40.264811],[-79.058868,40.276298],[-79.159174,40.295017],[-79.222015,40.357548],[-79.31767,40.30944],[-79.337563,40.190256],[-79.286171,40.165736],[-79.246126,40.205374]]]]},"properties":{"id":"15658","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.565139,40.227562],[-79.550104,40.223077],[-79.549955,40.223173],[-79.562996,40.228918],[-79.565139,40.227562]]]},"properties":{"id":"15616","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.675118,40.240161],[-79.666634,40.236519],[-79.677965,40.264668],[-79.692003,40.26439],[-79.693267,40.264686],[-79.684183,40.249679],[-79.675118,40.240161]]]},"properties":{"id":"15663","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.406259,40.264729],[-79.407392,40.261921],[-79.393271,40.262824],[-79.393047,40.267655],[-79.398884,40.265019],[-79.406259,40.264729]]]},"properties":{"id":"15638","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.504419,40.35124],[-79.522932,40.35598],[-79.524329,40.364011],[-79.533384,40.358092],[-79.514801,40.349379],[-79.504419,40.35124]]]},"properties":{"id":"15633","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.546729,40.450201],[-79.516822,40.450009],[-79.500199,40.454107],[-79.555659,40.465884],[-79.546729,40.450201]]]},"properties":{"id":"15684","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.112212,40.211477],[-79.246126,40.205374],[-79.242318,40.194962],[-79.203402,40.179331],[-79.112212,40.211477]]]},"properties":{"id":"15655","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.349953,40.098034],[-79.350434,40.085378],[-79.321052,40.07859],[-79.328468,40.095291],[-79.349953,40.098034]]]},"properties":{"id":"15646","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.67179,40.335609],[-79.68714,40.334206],[-79.68551,40.32737],[-79.67098,40.32978],[-79.67179,40.335609]]]},"properties":{"id":"15692","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.688207,40.293854],[-79.68429,40.29433],[-79.685251,40.296855],[-79.688207,40.293854]]]},"properties":{"id":"15691","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.52383,39.95972],[-76.509812,39.929149],[-76.472309,39.937461],[-76.45355,39.937544],[-76.4972,39.989031],[-76.533874,40.050818],[-76.54004,40.051311],[-76.52658,39.973645],[-76.521509,39.974734],[-76.516772,39.969184],[-76.529283,39.967541],[-76.52383,39.95972]]]},"properties":{"id":"17368","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.531301,39.79309],[-76.543379,39.721327],[-76.505355,39.72123],[-76.492981,39.721287],[-76.491736,39.721304],[-76.475785,39.807515],[-76.505054,39.804215],[-76.531301,39.79309]]]},"properties":{"id":"17352","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.498872,39.825462],[-76.380982,39.874492],[-76.387946,39.908654],[-76.392115,39.912749],[-76.404929,39.915213],[-76.498872,39.825462]]]},"properties":{"id":"17309","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.803331,39.792914],[-76.880368,39.777204],[-76.89793,39.720463],[-76.866664,39.7204],[-76.803331,39.792914]]]},"properties":{"id":"17329","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.646805,39.828763],[-76.615191,39.721202],[-76.543379,39.721327],[-76.531301,39.79309],[-76.646805,39.828763]]]},"properties":{"id":"17363","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.726127,40.032764],[-76.728724,40.022801],[-76.711984,40.019811],[-76.726127,40.032764]]]},"properties":{"id":"17318","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.688492,39.758003],[-76.674899,39.789371],[-76.692533,39.763983],[-76.688492,39.758003]]]},"properties":{"id":"17361","state_code":"42"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.692533,39.763983],[-76.703338,39.754578],[-76.688492,39.758003],[-76.692533,39.763983]]]},"properties":{"id":"17355","state_code":"42"}},    
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.393201,41.669592],[-71.500793,41.685015],[-71.532306,41.662275],[-71.546658,41.661413],[-71.546147,41.601891],[-71.522441,41.602599],[-71.408156,41.653811],[-71.390103,41.652293],[-71.393201,41.669592]]]},"properties":{"id":"02818","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.127862,41.562781],[-71.21319,41.644621],[-71.217083,41.623728],[-71.214618,41.544861],[-71.127862,41.562781]]]},"properties":{"id":"02878","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.332301,41.791201],[-71.380799,41.7963],[-71.378273,41.755297],[-71.365207,41.735649],[-71.31777,41.776113],[-71.332301,41.791201]]]},"properties":{"id":"02915","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.66675,41.931664],[-71.613315,41.932589],[-71.62587,41.952468],[-71.64586,41.954463],[-71.651266,41.958087],[-71.673561,41.943718],[-71.66675,41.931664]]]},"properties":{"id":"02839","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.531483,41.863863],[-71.575107,41.858599],[-71.55956,41.789635],[-71.495059,41.791258],[-71.463665,41.805046],[-71.472046,41.836515],[-71.495487,41.868669],[-71.531483,41.863863]]]},"properties":{"id":"02919","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.472046,41.836515],[-71.463665,41.805046],[-71.440252,41.803705],[-71.426254,41.817131],[-71.435738,41.82658],[-71.458076,41.842567],[-71.472046,41.836515]]]},"properties":{"id":"02909","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.594476,41.994473],[-71.583049,41.991886],[-71.5812,41.999592],[-71.594476,41.994473]]]},"properties":{"id":"02876","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.404053,41.824585],[-71.400146,41.82391],[-71.400536,41.827282],[-71.404716,41.826908],[-71.404053,41.824585]]]},"properties":{"id":"02912","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.764046,41.514973],[-71.792405,41.522251],[-71.796468,41.44735],[-71.760035,41.469465],[-71.764046,41.514973]]]},"properties":{"id":"02833","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.420033,41.548826],[-71.519242,41.538431],[-71.523241,41.517474],[-71.51603,41.498328],[-71.514573,41.495671],[-71.447047,41.48833],[-71.418889,41.492555],[-71.420033,41.548826]]]},"properties":{"id":"02874","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.416584,41.733149],[-71.364561,41.734928],[-71.365207,41.735649],[-71.378273,41.755297],[-71.412183,41.765058],[-71.440574,41.757912],[-71.443945,41.741196],[-71.416584,41.733149]]]},"properties":{"id":"02888","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.394296,41.897652],[-71.439636,41.943729],[-71.462977,41.957104],[-71.486991,41.961232],[-71.491992,41.9434],[-71.467504,41.872599],[-71.422153,41.878569],[-71.404507,41.880751],[-71.394296,41.897652]]]},"properties":{"id":"02865","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.475571,41.87163],[-71.495487,41.868669],[-71.472046,41.836515],[-71.458076,41.842567],[-71.448934,41.852266],[-71.475571,41.87163]]]},"properties":{"id":"02911","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.440461,41.793493],[-71.440574,41.757912],[-71.412183,41.765058],[-71.414495,41.778254],[-71.440461,41.793493]]]},"properties":{"id":"02910","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.644837,41.965989],[-71.62587,41.952468],[-71.613315,41.932589],[-71.590108,41.934098],[-71.606255,42.013371],[-71.717306,42.010724],[-71.673561,41.943718],[-71.651266,41.958087],[-71.644837,41.965989]]]},"properties":{"id":"02830","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.62587,41.952468],[-71.644837,41.965989],[-71.64586,41.954463],[-71.62587,41.952468]]]},"properties":{"id":"02826","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.730634,41.386384],[-71.729246,41.330744],[-71.626295,41.359421],[-71.595232,41.446288],[-71.615723,41.449673],[-71.627012,41.446694],[-71.629548,41.447329],[-71.651324,41.455257],[-71.672994,41.459309],[-71.723863,41.427954],[-71.730634,41.386384]]]},"properties":{"id":"02813","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.447047,41.48833],[-71.518456,41.372715],[-71.418889,41.492555],[-71.447047,41.48833]]]},"properties":{"id":"02882","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.518456,41.372715],[-71.447047,41.48833],[-71.514573,41.495671],[-71.546897,41.463591],[-71.595232,41.446288],[-71.626295,41.359421],[-71.518456,41.372715]]]},"properties":{"id":"02879","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.643974,41.56176],[-71.523241,41.517474],[-71.519242,41.538431],[-71.522441,41.602599],[-71.546147,41.601891],[-71.789247,41.596847],[-71.791416,41.545758],[-71.771286,41.548344],[-71.667839,41.55917],[-71.643974,41.56176]]]},"properties":{"id":"02822","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.689297,41.472125],[-71.667839,41.55917],[-71.771286,41.548344],[-71.764046,41.514973],[-71.760035,41.469465],[-71.730755,41.444242],[-71.690651,41.466446],[-71.689297,41.472125]]]},"properties":{"id":"02832","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.723863,41.427954],[-71.672994,41.459309],[-71.690651,41.466446],[-71.730755,41.444242],[-71.731339,41.439106],[-71.723863,41.427954]]]},"properties":{"id":"02894","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.667839,41.55917],[-71.689297,41.472125],[-71.659549,41.500392],[-71.643974,41.56176],[-71.667839,41.55917]]]},"properties":{"id":"02898","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.284404,41.762005],[-71.31777,41.776113],[-71.365207,41.735649],[-71.364561,41.734928],[-71.33097,41.686962],[-71.328976,41.679936],[-71.294039,41.71028],[-71.284404,41.762005]]]},"properties":{"id":"02806","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.294039,41.71028],[-71.210429,41.688009],[-71.284404,41.762005],[-71.294039,41.71028]]]},"properties":{"id":"02885","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.416584,41.733149],[-71.393201,41.669592],[-71.390103,41.652293],[-71.33097,41.686962],[-71.364561,41.734928],[-71.416584,41.733149]]]},"properties":{"id":"02889","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.727881,41.657676],[-71.671684,41.727367],[-71.789703,41.724563],[-71.787247,41.656065],[-71.727881,41.657676]]]},"properties":{"id":"02827","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.323662,41.499264],[-71.311779,41.521262],[-71.318384,41.525444],[-71.323662,41.499264]]]},"properties":{"id":"02841","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.127862,41.562781],[-71.214618,41.544861],[-71.169319,41.458391],[-71.119936,41.495079],[-71.127862,41.562781]]]},"properties":{"id":"02837","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.334397,41.8613],[-71.37491,41.894241],[-71.363734,41.858401],[-71.334397,41.8613]]]},"properties":{"id":"02861","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.789703,41.724563],[-71.671684,41.727367],[-71.644155,41.727721],[-71.631278,41.769256],[-71.679749,41.792596],[-71.728048,41.855946],[-71.79468,41.854409],[-71.789703,41.724563]]]},"properties":{"id":"02825","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.500793,41.685015],[-71.393201,41.669592],[-71.416584,41.733149],[-71.443945,41.741196],[-71.483858,41.731779],[-71.500793,41.685015]]]},"properties":{"id":"02886","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.787247,41.656065],[-71.789247,41.596847],[-71.546147,41.601891],[-71.546658,41.661413],[-71.727881,41.657676],[-71.787247,41.656065]]]},"properties":{"id":"02817","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.307224,41.565488],[-71.230396,41.512997],[-71.217083,41.623728],[-71.21319,41.644621],[-71.307224,41.565488]]]},"properties":{"id":"02871","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.797774,41.928553],[-71.66675,41.931664],[-71.673561,41.943718],[-71.717306,42.010724],[-71.797774,41.928553]]]},"properties":{"id":"02859","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.462977,41.957104],[-71.439636,41.943729],[-71.462307,41.95757],[-71.462977,41.957104]]]},"properties":{"id":"02802","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.519242,41.538431],[-71.420033,41.548826],[-71.408156,41.653811],[-71.522441,41.602599],[-71.519242,41.538431]]]},"properties":{"id":"02852","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.595232,41.446288],[-71.546897,41.463591],[-71.51603,41.498328],[-71.523241,41.517474],[-71.643974,41.56176],[-71.659549,41.500392],[-71.628288,41.464031],[-71.629548,41.447329],[-71.627012,41.446694],[-71.622221,41.454605],[-71.615723,41.449673],[-71.595232,41.446288]]]},"properties":{"id":"02892","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.210429,41.688009],[-71.294039,41.71028],[-71.328976,41.679936],[-71.210429,41.688009]]]},"properties":{"id":"02809","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.288347,41.486837],[-71.311779,41.521262],[-71.323662,41.499264],[-71.288347,41.486837]]]},"properties":{"id":"02840","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.311779,41.521262],[-71.288347,41.486837],[-71.230396,41.512997],[-71.307224,41.565488],[-71.318384,41.525444],[-71.311779,41.521262]]]},"properties":{"id":"02842","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.352616,41.655285],[-71.331728,41.626971],[-71.338834,41.584803],[-71.299235,41.606703],[-71.308741,41.629744],[-71.352616,41.655285]]]},"properties":{"id":"02872","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.400983,41.4723],[-71.354362,41.475355],[-71.356024,41.552796],[-71.387555,41.55725],[-71.400983,41.4723]]]},"properties":{"id":"02835","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.399437,41.856827],[-71.372635,41.8391],[-71.363734,41.858401],[-71.37491,41.894241],[-71.3817,41.893199],[-71.404507,41.880751],[-71.422153,41.878569],[-71.399437,41.856827]]]},"properties":{"id":"02860","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.613315,41.932589],[-71.66675,41.931664],[-71.797774,41.928553],[-71.79468,41.854409],[-71.728048,41.855946],[-71.57936,41.873102],[-71.578795,41.898106],[-71.590108,41.934098],[-71.613315,41.932589]]]},"properties":{"id":"02814","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.475571,41.87163],[-71.467504,41.872599],[-71.491992,41.9434],[-71.590108,41.934098],[-71.578795,41.898106],[-71.531483,41.863863],[-71.495487,41.868669],[-71.475571,41.87163]]]},"properties":{"id":"02917","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.415298,41.814495],[-71.390099,41.8052],[-71.385802,41.818893],[-71.404053,41.824585],[-71.404716,41.826908],[-71.408166,41.830387],[-71.41229,41.831468],[-71.435738,41.82658],[-71.426254,41.817131],[-71.415298,41.814495]]]},"properties":{"id":"02903","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.3817,41.893199],[-71.394296,41.897652],[-71.404507,41.880751],[-71.3817,41.893199]]]},"properties":{"id":"02863","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.426254,41.817131],[-71.440252,41.803705],[-71.440461,41.793493],[-71.414495,41.778254],[-71.415298,41.814495],[-71.426254,41.817131]]]},"properties":{"id":"02907","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.590694,41.198108],[-71.59928,41.145744],[-71.543734,41.16512],[-71.590694,41.198108]]]},"properties":{"id":"02807","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.628288,41.464031],[-71.651324,41.455257],[-71.629548,41.447329],[-71.628288,41.464031]]]},"properties":{"id":"02875","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.532306,41.662275],[-71.534286,41.730171],[-71.538397,41.730166],[-71.644155,41.727721],[-71.671684,41.727367],[-71.727881,41.657676],[-71.546658,41.661413],[-71.532306,41.662275]]]},"properties":{"id":"02816","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.372799,41.8381],[-71.344897,41.828],[-71.334397,41.8613],[-71.363734,41.858401],[-71.372635,41.8391],[-71.372799,41.8381]]]},"properties":{"id":"02916","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.534286,41.730171],[-71.493568,41.731525],[-71.495059,41.791258],[-71.55956,41.789635],[-71.557136,41.779373],[-71.538397,41.730166],[-71.534286,41.730171]]]},"properties":{"id":"02921","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.487364,41.979085],[-71.462307,41.95757],[-71.439636,41.943729],[-71.394296,41.897652],[-71.3817,41.893199],[-71.458104,42.017798],[-71.487364,41.979085]]]},"properties":{"id":"02864","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.404053,41.824585],[-71.385802,41.818893],[-71.372799,41.8381],[-71.372635,41.8391],[-71.399437,41.856827],[-71.408166,41.830387],[-71.404716,41.826908],[-71.400536,41.827282],[-71.400146,41.82391],[-71.404053,41.824585]]]},"properties":{"id":"02906","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.679749,41.792596],[-71.631278,41.769256],[-71.663983,41.791189],[-71.679749,41.792596]]]},"properties":{"id":"02815","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.546897,41.463591],[-71.514573,41.495671],[-71.51603,41.498328],[-71.546897,41.463591]]]},"properties":{"id":"02881","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.792405,41.522251],[-71.764046,41.514973],[-71.771286,41.548344],[-71.791416,41.545758],[-71.792405,41.522251]]]},"properties":{"id":"02873","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.628288,41.464031],[-71.659549,41.500392],[-71.689297,41.472125],[-71.690651,41.466446],[-71.672994,41.459309],[-71.651324,41.455257],[-71.628288,41.464031]]]},"properties":{"id":"02812","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.380799,41.7963],[-71.332301,41.791201],[-71.344897,41.828],[-71.372799,41.8381],[-71.385802,41.818893],[-71.390099,41.8052],[-71.380799,41.7963]]]},"properties":{"id":"02914","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.475571,41.87163],[-71.448934,41.852266],[-71.41229,41.831468],[-71.408166,41.830387],[-71.399437,41.856827],[-71.422153,41.878569],[-71.467504,41.872599],[-71.475571,41.87163]]]},"properties":{"id":"02904","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.495059,41.791258],[-71.493568,41.731525],[-71.483858,41.731779],[-71.443945,41.741196],[-71.440574,41.757912],[-71.440461,41.793493],[-71.440252,41.803705],[-71.463665,41.805046],[-71.495059,41.791258]]]},"properties":{"id":"02920","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.679749,41.792596],[-71.663983,41.791189],[-71.557136,41.779373],[-71.55956,41.789635],[-71.575107,41.858599],[-71.57936,41.873102],[-71.728048,41.855946],[-71.679749,41.792596]]]},"properties":{"id":"02857","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.487364,41.979085],[-71.529118,42.014996],[-71.606255,42.013371],[-71.590108,41.934098],[-71.491992,41.9434],[-71.486991,41.961232],[-71.487364,41.979085]],[[-71.594476,41.994473],[-71.5812,41.999592],[-71.583049,41.991886],[-71.594476,41.994473]]]},"properties":{"id":"02896","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.644837,41.965989],[-71.651266,41.958087],[-71.64586,41.954463],[-71.644837,41.965989]]]},"properties":{"id":"02858","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.458076,41.842567],[-71.435738,41.82658],[-71.41229,41.831468],[-71.448934,41.852266],[-71.458076,41.842567]]]},"properties":{"id":"02908","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.462307,41.95757],[-71.487364,41.979085],[-71.486991,41.961232],[-71.462977,41.957104],[-71.462307,41.95757]]]},"properties":{"id":"02838","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.797744,41.41691],[-71.729246,41.330744],[-71.730634,41.386384],[-71.779222,41.387277],[-71.797744,41.41691]]]},"properties":{"id":"02891","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.731339,41.439106],[-71.779222,41.387277],[-71.730634,41.386384],[-71.723863,41.427954],[-71.731339,41.439106]]]},"properties":{"id":"02808","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.730755,41.444242],[-71.760035,41.469465],[-71.796468,41.44735],[-71.797744,41.41691],[-71.779222,41.387277],[-71.731339,41.439106],[-71.730755,41.444242]]]},"properties":{"id":"02804","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.615723,41.449673],[-71.622221,41.454605],[-71.627012,41.446694],[-71.615723,41.449673]]]},"properties":{"id":"02836","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.534286,41.730171],[-71.532306,41.662275],[-71.500793,41.685015],[-71.483858,41.731779],[-71.493568,41.731525],[-71.534286,41.730171]]]},"properties":{"id":"02893","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.631278,41.769256],[-71.644155,41.727721],[-71.538397,41.730166],[-71.557136,41.779373],[-71.663983,41.791189],[-71.631278,41.769256]]]},"properties":{"id":"02831","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.458104,42.017798],[-71.529118,42.014996],[-71.487364,41.979085],[-71.458104,42.017798]]]},"properties":{"id":"02895","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.578795,41.898106],[-71.57936,41.873102],[-71.575107,41.858599],[-71.531483,41.863863],[-71.578795,41.898106]]]},"properties":{"id":"02828","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.415298,41.814495],[-71.414495,41.778254],[-71.412183,41.765058],[-71.378273,41.755297],[-71.380799,41.7963],[-71.390099,41.8052],[-71.415298,41.814495]]]},"properties":{"id":"02905","state_code":"44"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.012423,33.532096],[-81.925732,33.461835],[-81.89452,33.50522],[-81.891094,33.58261],[-82.012423,33.532096]]]},"properties":{"id":"29841","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.914282,33.435296],[-81.757035,33.198148],[-81.686613,33.251962],[-81.663031,33.27003],[-81.832125,33.382176],[-81.914282,33.435296]]]},"properties":{"id":"29831","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.413257,33.522454],[-81.395899,33.501503],[-81.288954,33.470379],[-81.22011,33.439493],[-81.209094,33.47373],[-81.236566,33.533343],[-81.33466,33.563632],[-81.346984,33.568476],[-81.413257,33.522454]]]},"properties":{"id":"29146","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.573563,33.513765],[-81.527221,33.393499],[-81.395899,33.501503],[-81.413257,33.522454],[-81.483506,33.555641],[-81.526447,33.564656],[-81.573563,33.513765]]]},"properties":{"id":"29856","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.425833,34.788707],[-82.482366,34.788536],[-82.597835,34.705075],[-82.594965,34.694756],[-82.581463,34.687595],[-82.339527,34.669074],[-82.325692,34.718112],[-82.425833,34.788707]]]},"properties":{"id":"29673","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.764446,34.805819],[-82.751198,34.765853],[-82.750979,34.757049],[-82.696271,34.695112],[-82.597835,34.705075],[-82.637879,34.793881],[-82.669422,34.844841],[-82.781155,34.822989],[-82.764446,34.805819]]]},"properties":{"id":"29657","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.133282,33.393722],[-81.22011,33.439493],[-81.288954,33.470379],[-81.357958,33.39509],[-81.351265,33.338963],[-81.357129,33.31165],[-81.220093,33.273972],[-81.133282,33.393722]]]},"properties":{"id":"29817","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.227804,33.160697],[-81.146467,33.077838],[-81.121606,33.127761],[-81.103929,33.219224],[-81.22082,33.259258],[-81.227804,33.160697]]]},"properties":{"id":"29843","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.712283,33.300756],[-80.887879,33.260539],[-80.956824,33.159331],[-80.943966,33.147488],[-80.69451,33.140755],[-80.67933,33.24034],[-80.712283,33.300756]]]},"properties":{"id":"29432","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.906025,33.060228],[-80.726686,33.067225],[-80.65004,33.090195],[-80.69451,33.140755],[-80.943966,33.147488],[-80.937157,33.102584],[-80.906025,33.060228]]]},"properties":{"id":"29481","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.831543,32.593388],[-80.553688,32.50133],[-80.507975,32.505207],[-80.675077,32.757594],[-80.816855,32.80533],[-80.870008,32.873111],[-80.879947,32.792059],[-80.970505,32.64342],[-80.927667,32.591546],[-80.923897,32.587757],[-80.847733,32.530057],[-80.831543,32.593388]]]},"properties":{"id":"29945","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.829594,32.147582],[-80.899642,32.111123],[-80.876877,32.07852],[-80.833602,32.113579],[-80.829594,32.147582]]]},"properties":{"id":"29915","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.656575,32.247125],[-80.78606,32.194911],[-80.829594,32.147582],[-80.833602,32.113579],[-80.649501,32.238375],[-80.656575,32.247125]]]},"properties":{"id":"29928","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.719593,32.456491],[-80.716265,32.455182],[-80.716207,32.459728],[-80.719611,32.459736],[-80.719593,32.456491]]]},"properties":{"id":"29904","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.223325,32.873272],[-80.17446,32.845651],[-80.182327,32.864072],[-80.149463,32.909841],[-80.223325,32.873272]]],[[[-80.08485,33.026673],[-80.033284,33.042284],[-80.125166,33.192867],[-80.163865,33.237027],[-80.209741,33.213178],[-80.252977,32.968646],[-80.132135,33.010484],[-80.08485,33.026673]]]]},"properties":{"id":"29483","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.086941,32.992181],[-80.08485,33.026673],[-80.132135,33.010484],[-80.113776,32.957524],[-80.075136,32.968569],[-80.086941,32.992181]]]},"properties":{"id":"29456","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937754,33.650064],[-81.008208,33.570532],[-81.003653,33.563347],[-80.775976,33.581322],[-80.937754,33.650064]]]},"properties":{"id":"29118","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.024953,32.835082],[-80.035297,32.771918],[-80.003957,32.765807],[-79.949475,32.773664],[-79.956846,32.779657],[-79.967208,32.827597],[-80.024953,32.835082]]]},"properties":{"id":"29407","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.849307,32.882389],[-79.917619,32.818888],[-79.91644,32.79617],[-79.917163,32.782515],[-79.809629,32.779216],[-79.801968,32.80793],[-79.849307,32.882389]]]},"properties":{"id":"29464","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.113776,32.957524],[-80.131958,32.900548],[-80.126468,32.901237],[-80.058969,32.93122],[-80.075136,32.968569],[-80.113776,32.957524]]]},"properties":{"id":"29420","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.60955,35.17432],[-81.833235,35.182621],[-81.81689,35.129226],[-81.72491,35.016528],[-81.576434,35.117765],[-81.60955,35.17432]]]},"properties":{"id":"29341","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.965262,34.837073],[-81.036275,34.801364],[-81.012598,34.778004],[-81.00538,34.77614],[-80.986598,34.740836],[-80.92615,34.767374],[-80.965262,34.837073]]]},"properties":{"id":"29712","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.965262,34.837073],[-80.92615,34.767374],[-80.877657,34.750259],[-80.876714,34.860199],[-80.965262,34.837073]]]},"properties":{"id":"29704","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.225883,34.812518],[-80.324974,34.682407],[-80.262026,34.627029],[-80.211597,34.662947],[-80.208434,34.8124],[-80.225883,34.812518]]]},"properties":{"id":"29727","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.208434,34.8124],[-80.211597,34.662947],[-80.175258,34.651153],[-80.184502,34.81194],[-80.208434,34.8124]]]},"properties":{"id":"29741","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.970116,34.469343],[-79.968373,34.330186],[-79.710058,34.333466],[-79.657764,34.30519],[-79.725012,34.417243],[-79.749784,34.450595],[-79.970116,34.469343]]]},"properties":{"id":"29540","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.23567,34.437703],[-79.391367,34.572905],[-79.395076,34.487481],[-79.23567,34.437703]]]},"properties":{"id":"29547","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.391367,34.572905],[-79.450034,34.621036],[-79.460615,34.602615],[-79.494293,34.543505],[-79.395076,34.487481],[-79.391367,34.572905]]]},"properties":{"id":"29567","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.316629,34.274263],[-79.2767,34.041673],[-79.220905,34.107744],[-79.21347,34.256148],[-79.316629,34.274263]]]},"properties":{"id":"29574","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.14603,33.65798],[-79.138823,33.398758],[-79.071423,33.496361],[-79.096787,33.600349],[-79.14603,33.65798]]]},"properties":{"id":"29585","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.273073,34.937149],[-82.229903,34.851723],[-82.227616,34.829382],[-82.169431,34.801759],[-82.131204,34.8606],[-82.174266,34.957522],[-82.209536,35.017754],[-82.218244,35.039126],[-82.232037,35.068795],[-82.308422,35.074852],[-82.273073,34.937149]]]},"properties":{"id":"29651","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.496251,34.889023],[-82.428664,34.877139],[-82.435926,34.91491],[-82.445815,34.919954],[-82.434471,34.938097],[-82.435577,34.941715],[-82.52387,34.95229],[-82.496251,34.889023]]]},"properties":{"id":"29617","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.425833,34.788707],[-82.325692,34.718112],[-82.325324,34.728797],[-82.378879,34.826974],[-82.423909,34.828725],[-82.425833,34.788707]]]},"properties":{"id":"29605","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.280335,34.790855],[-82.298226,34.762344],[-82.286417,34.754192],[-82.280335,34.790855]]]},"properties":{"id":"29662","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.273073,34.937149],[-82.294527,34.885364],[-82.229903,34.851723],[-82.273073,34.937149]]]},"properties":{"id":"29650","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.577454,35.144131],[-82.774966,35.064681],[-82.754324,35.019162],[-82.617442,35.021461],[-82.577454,35.144131]]]},"properties":{"id":"29635","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.577454,35.144131],[-82.617442,35.021461],[-82.613583,34.966426],[-82.525784,34.952584],[-82.451733,35.172373],[-82.577454,35.144131]],[[-82.492459,35.033456],[-82.492518,35.027228],[-82.495224,35.026957],[-82.496686,35.031805],[-82.492459,35.033456]]]},"properties":{"id":"29661","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.492459,35.033456],[-82.496686,35.031805],[-82.495224,35.026957],[-82.492518,35.027228],[-82.492459,35.033456]]]},"properties":{"id":"29683","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.989924,32.91038],[-81.019048,32.958781],[-81.198977,32.834522],[-81.212882,32.806469],[-81.124233,32.796836],[-80.989924,32.91038]]]},"properties":{"id":"29924","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.204145,32.676855],[-81.141349,32.658074],[-81.154095,32.684984],[-81.204145,32.676855]]]},"properties":{"id":"29921","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.25024,32.90644],[-81.219377,32.84348],[-81.198977,32.834522],[-81.019048,32.958781],[-81.053258,32.989564],[-81.082291,33.02663],[-81.25024,32.90644]]]},"properties":{"id":"29911","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.848933,33.749746],[-78.960323,33.695941],[-78.978024,33.654627],[-78.934724,33.642742],[-78.837482,33.725652],[-78.82792,33.759282],[-78.848933,33.749746]]]},"properties":{"id":"29577","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.167641,32.552106],[-81.26833,32.528561],[-81.143857,32.351543],[-81.157026,32.420661],[-81.09581,32.519637],[-81.167641,32.552106]]]},"properties":{"id":"29943","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.134541,34.270307],[-80.199583,34.351354],[-80.288692,34.364139],[-80.365151,34.32768],[-80.429939,34.26432],[-80.394374,34.163184],[-80.389358,34.159949],[-80.338097,34.134352],[-80.277048,34.093177],[-80.182701,34.053509],[-80.161239,34.0889],[-80.163658,34.104404],[-80.157104,34.129462],[-80.167729,34.16693],[-80.134541,34.270307]]]},"properties":{"id":"29010","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.114012,34.306769],[-82.025562,34.258446],[-82.020327,34.251927],[-82.025603,34.35123],[-82.052618,34.39569],[-82.153937,34.417177],[-82.169614,34.334786],[-82.114012,34.306769]]]},"properties":{"id":"29384","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.389358,34.159949],[-80.540598,33.994156],[-80.492573,33.993383],[-80.463777,33.989207],[-80.442371,33.977224],[-80.338097,34.134352],[-80.389358,34.159949]]]},"properties":{"id":"29040","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.157104,34.129462],[-80.163658,34.104404],[-80.161239,34.0889],[-80.157104,34.129462]]]},"properties":{"id":"29046","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.093345,33.956076],[-81.048109,33.991304],[-81.059715,34.005253],[-81.128259,34.025681],[-81.131103,34.023255],[-81.127131,33.999158],[-81.093345,33.956076]]]},"properties":{"id":"29169","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.202293,33.754176],[-82.278279,33.772509],[-82.240996,33.735826],[-82.202293,33.754176]]]},"properties":{"id":"29844","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.535727,33.943147],[-82.469923,34.007068],[-82.478937,34.027022],[-82.500771,34.071906],[-82.500851,34.071903],[-82.594631,34.013796],[-82.535727,33.943147]]]},"properties":{"id":"29840","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.243967,33.931518],[-82.250875,33.934789],[-82.251838,33.925009],[-82.24751,33.924053],[-82.243967,33.931518]]]},"properties":{"id":"29899","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.482032,33.966096],[-79.482632,33.937589],[-79.324317,33.799123],[-79.190264,33.705787],[-79.256842,33.87197],[-79.335063,33.964134],[-79.344232,33.999203],[-79.380938,34.036975],[-79.482032,33.966096]]]},"properties":{"id":"29546","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983604,34.051392],[-81.002433,34.055157],[-81.031235,34.022566],[-81.020674,34.005313],[-80.98699,34.008088],[-80.983604,34.051392]]]},"properties":{"id":"29204","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.904795,34.841358],[-81.910088,34.742541],[-81.89552,34.738403],[-81.815311,34.714499],[-81.780376,34.736758],[-81.790059,34.810918],[-81.901196,34.842657],[-81.904795,34.841358]]]},"properties":{"id":"29374","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.904795,34.841358],[-81.963723,34.907284],[-82.001429,34.81296],[-81.910088,34.742541],[-81.904795,34.841358]]]},"properties":{"id":"29376","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.830612,34.989085],[-81.831567,34.988435],[-81.825382,34.977226],[-81.824221,34.984249],[-81.830612,34.989085]]]},"properties":{"id":"29324","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.114276,34.862187],[-82.117885,34.860096],[-82.114735,34.85778],[-82.11257,34.860678],[-82.114276,34.862187]]]},"properties":{"id":"29375","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.935509,33.782055],[-79.807137,33.723879],[-79.944505,33.810214],[-79.935509,33.782055]]]},"properties":{"id":"29518","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.960292,33.494019],[-79.867503,33.433651],[-79.75134,33.463578],[-79.950015,33.518293],[-79.960292,33.494019]]]},"properties":{"id":"29564","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.282215,34.894754],[-81.2979,34.822018],[-81.141099,34.820573],[-81.175934,34.889765],[-81.282215,34.894754]]]},"properties":{"id":"29726","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.280376,34.443109],[-82.43189,34.34197],[-82.377545,34.29302],[-82.330907,34.280763],[-82.251722,34.361837],[-82.280376,34.443109]]]},"properties":{"id":"29638","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.41084,33.731044],[-81.561895,34.079622],[-81.618979,34.068121],[-81.689289,33.953333],[-81.645214,33.923669],[-81.613635,33.914608],[-81.53026,33.754428],[-81.516503,33.691631],[-81.41084,33.731044]]]},"properties":{"id":"29006","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.220093,33.273972],[-81.357129,33.31165],[-81.516319,33.382311],[-81.663031,33.27003],[-81.686613,33.251962],[-81.420963,33.082091],[-81.337806,33.108221],[-81.227804,33.160697],[-81.22082,33.259258],[-81.220093,33.273972]]]},"properties":{"id":"29812","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.787568,34.672734],[-82.850032,34.696446],[-82.850735,34.692848],[-82.853707,34.679673],[-82.838436,34.682486],[-82.836288,34.666782],[-82.851005,34.669027],[-82.822782,34.65446],[-82.787568,34.672734]]]},"properties":{"id":"29631","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.133282,33.393722],[-81.220093,33.273972],[-81.22082,33.259258],[-81.103929,33.219224],[-81.073945,33.368441],[-81.08241,33.375464],[-81.133282,33.393722]]]},"properties":{"id":"29042","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937157,33.102584],[-81.005489,33.026919],[-80.877054,32.950907],[-80.906025,33.060228],[-80.937157,33.102584]]]},"properties":{"id":"29082","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.712991,32.362079],[-80.658733,32.337925],[-80.659201,32.340166],[-80.678317,32.367949],[-80.688352,32.368295],[-80.712991,32.362079]]]},"properties":{"id":"29905","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.96385,32.31454],[-81.0011,32.244178],[-80.760335,32.299048],[-80.698045,32.295334],[-80.746528,32.347292],[-80.81869,32.463935],[-80.96385,32.31454]]]},"properties":{"id":"29909","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.782804,32.514746],[-80.831543,32.593388],[-80.847733,32.530057],[-80.819179,32.485942],[-80.782804,32.514746]]]},"properties":{"id":"29941","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.659201,32.340166],[-80.435572,32.46491],[-80.507975,32.505207],[-80.553688,32.50133],[-80.678291,32.502953],[-80.68788,32.456273],[-80.672583,32.400819],[-80.678317,32.367949],[-80.659201,32.340166]]]},"properties":{"id":"29907","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.040848,32.975001],[-80.025274,32.967574],[-79.950448,32.910174],[-79.948848,32.90811],[-79.912065,32.97419],[-79.922857,33.066042],[-80.033284,33.042284],[-80.08485,33.026673],[-80.086941,32.992181],[-80.040848,32.975001]]]},"properties":{"id":"29445","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.763345,33.294918],[-79.757428,33.233239],[-79.767605,33.177938],[-79.645208,33.120478],[-79.609053,33.133057],[-79.446699,33.213458],[-79.677014,33.304944],[-79.741153,33.322308],[-79.763345,33.294918]]]},"properties":{"id":"29453","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.389401,32.65282],[-80.356791,32.493748],[-80.389604,32.440501],[-80.384059,32.437751],[-80.193676,32.554213],[-80.209161,32.57937],[-80.271212,32.625076],[-80.389401,32.65282]]]},"properties":{"id":"29438","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.975751,32.639005],[-79.887536,32.689953],[-79.944912,32.670781],[-79.975751,32.639005]]]},"properties":{"id":"29439","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.171591,32.707913],[-80.271212,32.625076],[-80.209161,32.57937],[-80.171591,32.707913]]]},"properties":{"id":"29487","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.942625,34.667097],[-80.896294,34.635535],[-80.877657,34.750259],[-80.92615,34.767374],[-80.986598,34.740836],[-80.942625,34.667097]]]},"properties":{"id":"29714","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.986598,34.740836],[-81.00538,34.77614],[-81.009669,34.770962],[-81.012598,34.778004],[-81.036275,34.801364],[-81.037457,34.794273],[-81.074421,34.622421],[-80.976554,34.594257],[-80.942625,34.667097],[-80.986598,34.740836]]]},"properties":{"id":"29729","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.485734,34.717626],[-80.324974,34.682407],[-80.225883,34.812518],[-80.561673,34.817482],[-80.48976,34.720216],[-80.485734,34.717626]]]},"properties":{"id":"29728","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.395076,34.487481],[-79.494293,34.543505],[-79.525742,34.488194],[-79.345784,34.287332],[-79.330702,34.280814],[-79.245048,34.312897],[-79.218715,34.423268],[-79.23567,34.437703],[-79.395076,34.487481]]]},"properties":{"id":"29536","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.218715,34.423268],[-79.245048,34.312897],[-79.216896,34.288446],[-79.108868,34.33088],[-79.218715,34.423268]]]},"properties":{"id":"29563","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.108868,34.33088],[-79.216896,34.288446],[-79.21347,34.256148],[-79.220905,34.107744],[-79.062955,34.073836],[-79.027524,34.121776],[-78.988196,34.229645],[-79.108868,34.33088]]]},"properties":{"id":"29581","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.113728,33.82988],[-82.316289,33.812929],[-82.278279,33.772509],[-82.202293,33.754176],[-82.138789,33.801562],[-82.113728,33.82988]]]},"properties":{"id":"29845","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.844473,34.424769],[-81.073343,34.249115],[-80.880079,34.262116],[-80.807927,34.260613],[-80.698083,34.334183],[-80.783625,34.399453],[-80.844473,34.424769]]]},"properties":{"id":"29130","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.810866,34.001661],[-79.70489,34.018859],[-79.637951,33.984267],[-79.596551,34.081042],[-79.806355,34.155613],[-79.833067,34.149689],[-79.847477,33.982076],[-79.810866,34.001661]]]},"properties":{"id":"29541","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.071423,33.496361],[-78.982894,33.592848],[-79.009464,33.614197],[-79.095999,33.630033],[-79.096787,33.600349],[-79.071423,33.496361]]]},"properties":{"id":"29576","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.441221,33.592838],[-79.677014,33.304944],[-79.446699,33.213458],[-79.29193,33.108987],[-79.138823,33.398758],[-79.14603,33.65798],[-79.154175,33.661689],[-79.433288,33.621552],[-79.441221,33.592838]]]},"properties":{"id":"29440","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.355386,34.894383],[-82.357326,34.966407],[-82.435577,34.941715],[-82.434471,34.938097],[-82.435926,34.91491],[-82.428664,34.877139],[-82.421379,34.87031],[-82.38564,34.859118],[-82.366432,34.871524],[-82.36174,34.878142],[-82.355386,34.894383]]]},"properties":{"id":"29609","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.292994,35.196149],[-82.451733,35.172373],[-82.525784,34.952584],[-82.52387,34.95229],[-82.435577,34.941715],[-82.357326,34.966407],[-82.351168,35.104679],[-82.292994,35.196149]]]},"properties":{"id":"29690","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.292994,35.196149],[-82.351168,35.104679],[-82.308422,35.074852],[-82.232037,35.068795],[-82.075999,35.190372],[-82.292994,35.196149]]]},"properties":{"id":"29356","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.800279,33.806469],[-78.848933,33.749746],[-78.82792,33.759282],[-78.776904,33.789884],[-78.741498,33.818733],[-78.634227,33.930668],[-78.739944,34.020333],[-78.840461,33.90493],[-78.800279,33.806469]]]},"properties":{"id":"29568","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.970505,32.64342],[-81.030731,32.661782],[-81.096211,32.626773],[-81.09581,32.519637],[-81.157026,32.420661],[-80.96385,32.31454],[-80.81869,32.463935],[-80.819179,32.485942],[-80.847733,32.530057],[-80.923897,32.587757],[-80.929179,32.578629],[-80.938716,32.572705],[-80.927667,32.591546],[-80.970505,32.64342]]]},"properties":{"id":"29936","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.561673,34.817482],[-80.782042,34.935785],[-80.869156,34.945077],[-80.868214,34.941227],[-80.876714,34.860199],[-80.877657,34.750259],[-80.896294,34.635535],[-80.882346,34.584744],[-80.573991,34.683775],[-80.48976,34.720216],[-80.561673,34.817482]]]},"properties":{"id":"29720","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075063,34.693466],[-82.236275,34.549594],[-82.218151,34.493216],[-82.207744,34.476064],[-81.989088,34.574763],[-82.030685,34.653013],[-82.075063,34.693466]]]},"properties":{"id":"29645","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.785754,34.363272],[-81.893423,34.350318],[-81.869722,34.31411],[-81.851106,34.273934],[-81.791913,34.283984],[-81.785754,34.363272]]],[[[-81.785754,34.363272],[-81.601132,34.409276],[-81.737103,34.430544],[-81.783916,34.420921],[-81.824381,34.404571],[-81.785754,34.363272]]]]},"properties":{"id":"29355","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.268995,34.099652],[-81.1371,34.133342],[-81.178293,34.174552],[-81.247437,34.203113],[-81.249817,34.20074],[-81.268995,34.099652]]]},"properties":{"id":"29063","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.104088,34.089207],[-81.146917,34.03225],[-81.128259,34.025681],[-81.059715,34.005253],[-81.064715,34.020853],[-81.104088,34.089207]]]},"properties":{"id":"29210","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.598001,34.551106],[-79.725012,34.417243],[-79.657764,34.30519],[-79.633413,34.297994],[-79.528833,34.482744],[-79.598001,34.551106]]]},"properties":{"id":"29516","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.013562,34.7252],[-82.979923,34.836579],[-83.010337,34.873506],[-83.064337,34.861128],[-83.19728,34.757662],[-83.025603,34.711832],[-83.013562,34.7252]]]},"properties":{"id":"29691","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.944601,34.130774],[-80.874463,34.082455],[-80.826382,34.101515],[-80.888782,34.189908],[-80.950158,34.149047],[-80.944601,34.130774]]]},"properties":{"id":"29229","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.02516,33.994631],[-81.024882,33.997696],[-81.026293,33.997284],[-81.02516,33.994631]]]},"properties":{"id":"29225","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.764885,33.932988],[-81.728685,33.834183],[-81.645214,33.923669],[-81.689289,33.953333],[-81.764885,33.932988]]]},"properties":{"id":"29166","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.083864,34.912831],[-82.068121,34.891022],[-81.97002,34.909844],[-81.937165,34.953007],[-82.045716,34.962947],[-82.083864,34.912831]],[[-81.993634,34.953901],[-81.998269,34.959459],[-81.984745,34.960304],[-81.984202,34.953681],[-81.993634,34.953901]]]},"properties":{"id":"29301","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.105169,34.939505],[-82.111209,34.937735],[-82.107603,34.929382],[-82.105169,34.939505]]],[[[-82.105169,34.939505],[-82.094822,34.921954],[-82.083864,34.912831],[-82.045716,34.962947],[-82.075424,34.987834],[-82.150813,35.00474],[-82.105169,34.939505]]]]},"properties":{"id":"29385","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.081243,34.872897],[-82.001429,34.81296],[-81.963723,34.907284],[-81.97002,34.909844],[-82.068121,34.891022],[-82.081243,34.872897]]]},"properties":{"id":"29369","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969847,34.967571],[-81.966851,34.965335],[-81.964415,34.968275],[-81.969847,34.967571]]]},"properties":{"id":"29378","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.599934,33.978094],[-80.600952,34.039386],[-80.628137,33.947588],[-80.575563,33.952847],[-80.599934,33.978094]]]},"properties":{"id":"29062","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.815311,34.714499],[-81.787553,34.671595],[-81.702654,34.761553],[-81.780376,34.736758],[-81.815311,34.714499]]]},"properties":{"id":"29321","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.873447,33.662415],[-79.729125,33.563018],[-79.607779,33.609195],[-79.66418,33.73509],[-79.807137,33.723879],[-79.935509,33.782055],[-79.957514,33.72489],[-79.873447,33.662415]]]},"properties":{"id":"29556","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983397,34.987439],[-80.931059,35.103234],[-81.041489,35.044703],[-81.038025,35.027514],[-80.983397,34.987439]]]},"properties":{"id":"29708","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.036101,34.941435],[-81.031544,34.935569],[-81.029075,34.93943],[-81.031104,34.943244],[-81.036101,34.941435]]]},"properties":{"id":"29733","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.316301,34.573677],[-82.469081,34.353975],[-82.473287,34.32892],[-82.43189,34.34197],[-82.280376,34.443109],[-82.218151,34.493216],[-82.236275,34.549594],[-82.299371,34.578954],[-82.316301,34.573677]]]},"properties":{"id":"29654","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.377545,34.29302],[-82.43189,34.34197],[-82.473287,34.32892],[-82.460897,34.253777],[-82.377545,34.29302]]]},"properties":{"id":"29639","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.629086,34.389328],[-82.794796,34.340641],[-82.66769,34.116641],[-82.605756,34.175485],[-82.460897,34.253777],[-82.473287,34.32892],[-82.469081,34.353975],[-82.509281,34.357894],[-82.580733,34.359169],[-82.629086,34.389328]],[[-82.655987,34.205939],[-82.647682,34.216929],[-82.639653,34.213075],[-82.644928,34.203121],[-82.655987,34.205939]]]},"properties":{"id":"29655","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.251722,34.361837],[-82.169614,34.334786],[-82.153937,34.417177],[-82.207744,34.476064],[-82.218151,34.493216],[-82.280376,34.443109],[-82.251722,34.361837]]]},"properties":{"id":"29692","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.346984,33.568476],[-81.487343,33.58162],[-81.483506,33.555641],[-81.413257,33.522454],[-81.346984,33.568476]]],[[[-81.269666,33.693142],[-81.33466,33.563632],[-81.236566,33.533343],[-81.25249,33.595195],[-81.187271,33.652937],[-81.256888,33.685282],[-81.269666,33.693142]]]]},"properties":{"id":"29137","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.33466,33.563632],[-81.269666,33.693142],[-81.282836,33.694333],[-81.41084,33.731044],[-81.516503,33.691631],[-81.487343,33.58162],[-81.346984,33.568476],[-81.33466,33.563632]]]},"properties":{"id":"29164","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.516503,33.691631],[-81.53026,33.754428],[-81.595038,33.739499],[-81.724095,33.666824],[-81.526447,33.564656],[-81.483506,33.555641],[-81.487343,33.58162],[-81.516503,33.691631]]]},"properties":{"id":"29805","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.645214,33.923669],[-81.728685,33.834183],[-81.735702,33.74916],[-81.74518,33.69158],[-81.724095,33.666824],[-81.595038,33.739499],[-81.613635,33.914608],[-81.645214,33.923669]]]},"properties":{"id":"29129","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.863227,33.508679],[-81.870149,33.496899],[-81.798108,33.467844],[-81.767748,33.530418],[-81.778607,33.561675],[-81.849182,33.525433],[-81.832634,33.527448],[-81.828153,33.531339],[-81.817391,33.524024],[-81.828045,33.517636],[-81.863227,33.508679]]]},"properties":{"id":"29851","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.832634,33.527448],[-81.849182,33.525433],[-81.894491,33.505147],[-81.888006,33.496327],[-81.863227,33.508679],[-81.828045,33.517636],[-81.832634,33.527448]]]},"properties":{"id":"29834","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.496474,34.829946],[-82.637879,34.793881],[-82.597835,34.705075],[-82.482366,34.788536],[-82.496474,34.829946]]]},"properties":{"id":"29642","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.862378,34.602253],[-82.947844,34.521647],[-82.90223,34.486525],[-82.855486,34.441791],[-82.810774,34.504389],[-82.862378,34.602253]]]},"properties":{"id":"29689","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.005489,33.026919],[-80.937157,33.102584],[-80.943966,33.147488],[-80.956824,33.159331],[-81.121606,33.127761],[-81.146467,33.077838],[-81.143499,33.071726],[-81.082291,33.02663],[-81.053258,32.989564],[-81.005489,33.026919]]]},"properties":{"id":"29081","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.819179,32.485942],[-80.81869,32.463935],[-80.746528,32.347292],[-80.712991,32.362079],[-80.688352,32.368295],[-80.714661,32.377317],[-80.68788,32.456273],[-80.678291,32.502953],[-80.782804,32.514746],[-80.819179,32.485942]],[[-80.719593,32.456491],[-80.719611,32.459736],[-80.716207,32.459728],[-80.716265,32.455182],[-80.719593,32.456491]]]},"properties":{"id":"29906","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.782804,32.514746],[-80.678291,32.502953],[-80.553688,32.50133],[-80.831543,32.593388],[-80.782804,32.514746]]]},"properties":{"id":"29940","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.78606,32.194911],[-80.656575,32.247125],[-80.681397,32.286242],[-80.698045,32.295334],[-80.760335,32.299048],[-80.78606,32.194911]]]},"properties":{"id":"29926","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125166,33.192867],[-80.033284,33.042284],[-79.922857,33.066042],[-79.922546,33.067188],[-79.856654,33.196953],[-80.015234,33.274718],[-80.125166,33.192867]]]},"properties":{"id":"29461","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.950448,32.910174],[-80.025274,32.967574],[-80.040848,32.975001],[-79.950448,32.910174]]]},"properties":{"id":"29410","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.163865,33.237027],[-80.125166,33.192867],[-80.015234,33.274718],[-80.038318,33.302978],[-80.163865,33.237027]]]},"properties":{"id":"29469","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.400209,32.872475],[-80.329071,32.870586],[-80.265709,32.909688],[-80.252977,32.968646],[-80.209741,33.213178],[-80.276682,33.238581],[-80.344318,33.234228],[-80.33493,33.221066],[-80.42254,33.054829],[-80.400209,32.872475]]]},"properties":{"id":"29472","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.61811,33.4587],[-80.496273,33.558481],[-80.521295,33.599682],[-80.519417,33.610072],[-80.61811,33.4587]]]},"properties":{"id":"29047","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.130355,32.786565],[-80.274143,32.759455],[-80.429304,32.719662],[-80.389401,32.65282],[-80.271212,32.625076],[-80.171591,32.707913],[-80.130355,32.786565]]]},"properties":{"id":"29449","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.027285,35.159593],[-81.950816,35.075924],[-81.927293,35.036237],[-81.897696,35.038558],[-81.862801,35.051877],[-81.867329,35.077387],[-81.857322,35.086467],[-81.81689,35.129226],[-81.833235,35.182621],[-82.027537,35.189124],[-82.027285,35.159593]]]},"properties":{"id":"29323","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.07227,33.779638],[-80.209599,33.862375],[-80.270785,33.78676],[-80.06057,33.75664],[-80.07227,33.779638]]]},"properties":{"id":"29001","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.209599,33.862375],[-80.07227,33.779638],[-80.07113,33.869581],[-80.068033,33.900408],[-80.121997,33.911381],[-80.170296,33.89854],[-80.209599,33.862375]]]},"properties":{"id":"29051","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.737553,34.279585],[-79.761576,34.176524],[-79.568693,34.093939],[-79.535909,34.076085],[-79.54793,34.230913],[-79.633413,34.297994],[-79.657764,34.30519],[-79.710058,34.333466],[-79.737553,34.279585]]]},"properties":{"id":"29506","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.428631,34.253748],[-79.474982,34.332114],[-79.541876,34.247936],[-79.428631,34.253748]]]},"properties":{"id":"29592","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.316629,34.274263],[-79.21347,34.256148],[-79.216896,34.288446],[-79.245048,34.312897],[-79.330702,34.280814],[-79.316629,34.274263]]]},"properties":{"id":"29543","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.654871,33.253289],[-80.546092,33.288179],[-80.50279,33.334496],[-80.57413,33.375615],[-80.625558,33.436742],[-80.683214,33.442044],[-80.729177,33.40051],[-80.712283,33.300756],[-80.67933,33.24034],[-80.654871,33.253289]]]},"properties":{"id":"29018","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.159959,33.979259],[-82.285687,33.984779],[-82.469923,34.007068],[-82.535727,33.943147],[-82.316289,33.812929],[-82.113728,33.82988],[-82.060556,33.950248],[-82.159959,33.979259]],[[-82.243967,33.931518],[-82.24751,33.924053],[-82.251838,33.925009],[-82.250875,33.934789],[-82.243967,33.931518]]]},"properties":{"id":"29835","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.224845,33.691098],[-82.09705,33.727493],[-82.138789,33.801562],[-82.202293,33.754176],[-82.240996,33.735826],[-82.224845,33.691098]]]},"properties":{"id":"29838","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.294527,34.885364],[-82.355386,34.894383],[-82.36174,34.878142],[-82.359374,34.87837],[-82.254825,34.829701],[-82.227616,34.829382],[-82.229903,34.851723],[-82.294527,34.885364]]]},"properties":{"id":"29615","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.212882,32.806469],[-81.405054,32.744957],[-81.401211,32.679882],[-81.260751,32.710911],[-81.21428,32.674857],[-81.204145,32.676855],[-81.154095,32.684984],[-81.099419,32.712663],[-81.124233,32.796836],[-81.212882,32.806469]]]},"properties":{"id":"29918","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.256842,33.87197],[-79.190264,33.705787],[-79.154175,33.661689],[-79.14603,33.65798],[-79.096787,33.600349],[-79.095999,33.630033],[-79.042777,33.728437],[-79.151029,33.916648],[-79.256842,33.87197]]]},"properties":{"id":"29527","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.020929,33.98766],[-78.840461,33.90493],[-78.739944,34.020333],[-78.876152,34.135572],[-79.027524,34.121776],[-79.062955,34.073836],[-79.060712,34.016344],[-79.020929,33.98766]]]},"properties":{"id":"29569","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.011082,33.753232],[-79.042777,33.728437],[-79.095999,33.630033],[-79.009464,33.614197],[-78.978024,33.654627],[-78.960323,33.695941],[-79.011082,33.753232]]]},"properties":{"id":"29588","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.060712,34.016344],[-79.062955,34.073836],[-79.220905,34.107744],[-79.2767,34.041673],[-79.335063,33.964134],[-79.256842,33.87197],[-79.151029,33.916648],[-79.157333,33.925235],[-79.060712,34.016344]]]},"properties":{"id":"29544","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.394374,34.163184],[-80.605474,34.182094],[-80.607456,34.043262],[-80.600952,34.039386],[-80.599934,33.978094],[-80.540598,33.994156],[-80.389358,34.159949],[-80.394374,34.163184]]]},"properties":{"id":"29128","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.763205,34.462428],[-80.879227,34.457776],[-80.844473,34.424769],[-80.783625,34.399453],[-80.763205,34.462428]]]},"properties":{"id":"29074","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.783916,34.420921],[-81.828647,34.42866],[-81.824381,34.404571],[-81.783916,34.420921]]]},"properties":{"id":"29351","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.593918,34.648638],[-79.572715,34.624853],[-79.558929,34.640268],[-79.593918,34.648638]]]},"properties":{"id":"29594","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.850735,34.692848],[-83.021796,34.677863],[-82.966699,34.543425],[-82.947844,34.521647],[-82.862378,34.602253],[-82.840253,34.623212],[-82.822782,34.65446],[-82.851005,34.669027],[-82.853707,34.679673],[-82.850735,34.692848]]]},"properties":{"id":"29678","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.49117,33.424656],[-80.377394,33.393312],[-80.394811,33.447191],[-80.433515,33.475037],[-80.49117,33.424656]]]},"properties":{"id":"29163","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.613583,34.966426],[-82.669422,34.844841],[-82.637879,34.793881],[-82.496474,34.829946],[-82.496251,34.889023],[-82.52387,34.95229],[-82.525784,34.952584],[-82.613583,34.966426]]]},"properties":{"id":"29640","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.754324,35.019162],[-82.836082,34.89083],[-82.781155,34.822989],[-82.669422,34.844841],[-82.613583,34.966426],[-82.617442,35.021461],[-82.754324,35.019162]]]},"properties":{"id":"29671","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.774966,35.064681],[-82.897499,35.056021],[-82.904851,34.871327],[-82.836082,34.89083],[-82.754324,35.019162],[-82.774966,35.064681]]]},"properties":{"id":"29685","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.960463,34.09058],[-81.002433,34.055157],[-80.983604,34.051392],[-80.937733,34.041751],[-80.874463,34.082455],[-80.944601,34.130774],[-80.96247,34.096233],[-80.960463,34.09058]]]},"properties":{"id":"29223","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.96247,34.096233],[-80.972078,34.090607],[-80.960463,34.09058],[-80.96247,34.096233]]]},"properties":{"id":"29147","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.029925,33.993152],[-81.030515,33.994482],[-81.032059,33.993899],[-81.031562,33.992778],[-81.029925,33.993152]]]},"properties":{"id":"29202","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.209536,35.017754],[-82.150813,35.00474],[-82.075424,34.987834],[-82.024723,35.019926],[-81.950816,35.075924],[-82.027285,35.159593],[-82.218244,35.039126],[-82.209536,35.017754]],[[-82.004969,35.135829],[-81.998538,35.137957],[-81.998072,35.133957],[-82.004969,35.135829]]]},"properties":{"id":"29349","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.950816,35.075924],[-82.024723,35.019926],[-81.927293,35.036237],[-81.950816,35.075924]]]},"properties":{"id":"29316","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.738578,34.928337],[-81.750115,34.927401],[-81.759133,34.919194],[-81.746189,34.914783],[-81.738578,34.928337]]]},"properties":{"id":"29373","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.830612,34.989085],[-81.83464,34.9959],[-81.839281,34.998388],[-81.841532,34.992754],[-81.83401,34.991584],[-81.831567,34.988435],[-81.830612,34.989085]]]},"properties":{"id":"29329","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.492573,33.993383],[-80.540598,33.994156],[-80.599934,33.978094],[-80.575563,33.952847],[-80.463583,33.807132],[-80.396993,33.746864],[-80.366109,33.772399],[-80.42738,33.966995],[-80.442371,33.977224],[-80.463777,33.989207],[-80.446054,33.965392],[-80.490744,33.954368],[-80.492573,33.993383]]]},"properties":{"id":"29154","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.607779,33.609195],[-79.441221,33.592838],[-79.433288,33.621552],[-79.591918,33.736626],[-79.66418,33.73509],[-79.607779,33.609195]]]},"properties":{"id":"29580","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.380018,34.970359],[-81.488011,35.030336],[-81.488286,35.026868],[-81.463063,34.854969],[-81.380018,34.970359]]]},"properties":{"id":"29717","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.500771,34.071906],[-82.478937,34.027022],[-82.449426,34.060203],[-82.500771,34.071906]]],[[[-82.66769,34.116641],[-82.594631,34.013796],[-82.500851,34.071903],[-82.605756,34.175485],[-82.66769,34.116641]]]]},"properties":{"id":"29628","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.655987,34.205939],[-82.644928,34.203121],[-82.639653,34.213075],[-82.647682,34.216929],[-82.655987,34.205939]]]},"properties":{"id":"29659","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.599881,34.595067],[-82.509281,34.357894],[-82.469081,34.353975],[-82.316301,34.573677],[-82.449086,34.617686],[-82.599881,34.595067]]]},"properties":{"id":"29627","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.692526,33.404597],[-81.667842,33.426202],[-81.708814,33.433189],[-81.692526,33.404597]]]},"properties":{"id":"29809","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.794796,34.340641],[-82.629086,34.389328],[-82.668029,34.44571],[-82.841371,34.397129],[-82.794796,34.340641]]]},"properties":{"id":"29684","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.580733,34.359169],[-82.509281,34.357894],[-82.599881,34.595067],[-82.643084,34.65561],[-82.720754,34.581234],[-82.652029,34.504266],[-82.580733,34.359169]]]},"properties":{"id":"29621","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.143857,32.351543],[-80.876877,32.07852],[-80.899642,32.111123],[-81.0011,32.244178],[-80.96385,32.31454],[-81.157026,32.420661],[-81.143857,32.351543]]]},"properties":{"id":"29927","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937754,33.650064],[-80.775976,33.581322],[-80.764894,33.573265],[-80.596593,33.695137],[-80.59658,33.69549],[-80.620107,33.743237],[-80.645083,33.752965],[-80.713156,33.76865],[-80.834817,33.787388],[-80.967719,33.688917],[-80.937754,33.650064]]]},"properties":{"id":"29135","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.003957,32.765807],[-79.99003,32.622837],[-79.975751,32.639005],[-79.944912,32.670781],[-79.887536,32.689953],[-79.862512,32.740214],[-79.916785,32.763274],[-79.949475,32.773664],[-80.003957,32.765807]]]},"properties":{"id":"29412","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.366597,35.164891],[-81.60955,35.17432],[-81.576434,35.117765],[-81.488286,35.026868],[-81.488011,35.030336],[-81.382754,35.078074],[-81.350759,35.083232],[-81.347453,35.090113],[-81.366597,35.164891]]]},"properties":{"id":"29702","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.829981,34.531925],[-79.939358,34.580401],[-79.956698,34.486699],[-79.970116,34.469343],[-79.749784,34.450595],[-79.829981,34.531925]]]},"properties":{"id":"29593","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.455372,34.412292],[-80.365151,34.32768],[-80.288692,34.364139],[-80.358873,34.536364],[-80.40461,34.565298],[-80.455372,34.412292]]]},"properties":{"id":"29009","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.675077,32.757594],[-80.507975,32.505207],[-80.435572,32.46491],[-80.389604,32.440501],[-80.356791,32.493748],[-80.526628,32.760482],[-80.531934,32.812887],[-80.675077,32.757594]]]},"properties":{"id":"29446","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.726686,33.067225],[-80.906025,33.060228],[-80.877054,32.950907],[-80.870008,32.873111],[-80.816855,32.80533],[-80.726686,33.067225]],[[-80.851026,33.037619],[-80.834068,33.035029],[-80.842528,33.026371],[-80.851026,33.037619]]]},"properties":{"id":"29475","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.851026,33.037619],[-80.842528,33.026371],[-80.834068,33.035029],[-80.851026,33.037619]]]},"properties":{"id":"29493","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.134541,34.270307],[-80.167729,34.16693],[-80.072808,34.086013],[-80.060173,34.248574],[-80.107818,34.288747],[-80.118264,34.278554],[-80.134541,34.270307]]]},"properties":{"id":"29069","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.107818,34.288747],[-80.114151,34.285981],[-80.118264,34.278554],[-80.107818,34.288747]]]},"properties":{"id":"29079","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.345784,34.287332],[-79.525742,34.488194],[-79.528833,34.482744],[-79.633413,34.297994],[-79.54793,34.230913],[-79.541876,34.247936],[-79.474982,34.332114],[-79.428631,34.253748],[-79.345784,34.287332]]]},"properties":{"id":"29565","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.546092,33.288179],[-80.654871,33.253289],[-80.649678,33.090052],[-80.571198,33.057715],[-80.506412,33.058797],[-80.523005,33.222473],[-80.546092,33.288179]]]},"properties":{"id":"29477","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.873112,33.901754],[-81.871466,33.765416],[-81.735702,33.74916],[-81.728685,33.834183],[-81.764885,33.932988],[-81.873112,33.901754]]]},"properties":{"id":"29832","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.591918,33.736626],[-79.433288,33.621552],[-79.154175,33.661689],[-79.190264,33.705787],[-79.324317,33.799123],[-79.63488,33.808359],[-79.591918,33.736626]]]},"properties":{"id":"29554","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.351168,35.104679],[-82.357326,34.966407],[-82.355386,34.894383],[-82.294527,34.885364],[-82.273073,34.937149],[-82.308422,35.074852],[-82.351168,35.104679]]]},"properties":{"id":"29687","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.239519,34.70446],[-82.303386,34.62455],[-82.299371,34.578954],[-82.236275,34.549594],[-82.075063,34.693466],[-82.146792,34.785554],[-82.239519,34.70446]]]},"properties":{"id":"29644","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26793,32.860706],[-81.219377,32.84348],[-81.25024,32.90644],[-81.26793,32.860706]]]},"properties":{"id":"29923","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.776904,33.789884],[-78.82792,33.759282],[-78.837482,33.725652],[-78.735812,33.788805],[-78.776904,33.789884]]]},"properties":{"id":"29572","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.009464,33.614197],[-78.982894,33.592848],[-78.934724,33.642742],[-78.978024,33.654627],[-79.009464,33.614197]]]},"properties":{"id":"29575","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.060712,34.016344],[-79.157333,33.925235],[-79.020929,33.98766],[-79.060712,34.016344]]]},"properties":{"id":"29511","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.546472,34.399206],[-80.429939,34.26432],[-80.365151,34.32768],[-80.455372,34.412292],[-80.546472,34.399206]]]},"properties":{"id":"29032","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.573991,34.683775],[-80.882346,34.584744],[-80.880155,34.511581],[-80.879227,34.457776],[-80.763205,34.462428],[-80.660777,34.481793],[-80.573991,34.683775]]]},"properties":{"id":"29058","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.833317,34.592395],[-81.893847,34.597134],[-81.968001,34.425766],[-81.893423,34.350318],[-81.785754,34.363272],[-81.824381,34.404571],[-81.828647,34.42866],[-81.783916,34.420921],[-81.737103,34.430544],[-81.788749,34.574171],[-81.833317,34.592395]]]},"properties":{"id":"29325","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.989088,34.574763],[-82.207744,34.476064],[-82.153937,34.417177],[-82.052618,34.39569],[-81.968001,34.425766],[-81.893847,34.597134],[-81.989088,34.574763]]]},"properties":{"id":"29360","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.081243,34.872897],[-82.131204,34.8606],[-82.169431,34.801759],[-82.146792,34.785554],[-82.075063,34.693466],[-82.030685,34.653013],[-81.89552,34.738403],[-81.910088,34.742541],[-82.001429,34.81296],[-82.081243,34.872897]],[[-82.114276,34.862187],[-82.11257,34.860678],[-82.114735,34.85778],[-82.117885,34.860096],[-82.114276,34.862187]]]},"properties":{"id":"29388","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.89552,34.738403],[-82.030685,34.653013],[-81.989088,34.574763],[-81.893847,34.597134],[-81.833317,34.592395],[-81.787553,34.671595],[-81.815311,34.714499],[-81.89552,34.738403]],[[-81.861816,34.651311],[-81.829989,34.667631],[-81.840137,34.645803],[-81.861816,34.651311]]]},"properties":{"id":"29335","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.277048,34.093177],[-80.170296,33.89854],[-80.121997,33.911381],[-80.182701,34.053509],[-80.277048,34.093177]]]},"properties":{"id":"29104","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.42738,33.966995],[-80.274059,33.785868],[-80.270785,33.78676],[-80.209599,33.862375],[-80.170296,33.89854],[-80.277048,34.093177],[-80.338097,34.134352],[-80.442371,33.977224],[-80.42738,33.966995]]]},"properties":{"id":"29153","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.561895,34.079622],[-81.41084,33.731044],[-81.282836,33.694333],[-81.352178,33.800846],[-81.394083,34.071545],[-81.561895,34.079622]]]},"properties":{"id":"29070","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.2767,34.041673],[-79.316629,34.274263],[-79.330702,34.280814],[-79.345784,34.287332],[-79.428631,34.253748],[-79.541876,34.247936],[-79.54793,34.230913],[-79.535909,34.076085],[-79.482032,33.966096],[-79.380938,34.036975],[-79.35285,34.039001],[-79.344232,33.999203],[-79.335063,33.964134],[-79.2767,34.041673]]]},"properties":{"id":"29571","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.791913,34.283984],[-81.716236,34.175535],[-81.611198,34.123625],[-81.491834,34.298681],[-81.466896,34.423424],[-81.601132,34.409276],[-81.785754,34.363272],[-81.791913,34.283984]]]},"properties":{"id":"29108","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.466896,34.423424],[-81.491834,34.298681],[-81.436333,34.22975],[-81.333207,34.241523],[-81.321072,34.243731],[-81.345804,34.299575],[-81.411211,34.433252],[-81.466896,34.423424]]]},"properties":{"id":"29126","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.897499,35.056021],[-83.018656,35.025067],[-83.010337,34.873506],[-82.979923,34.836579],[-82.955771,34.835735],[-82.896741,34.802925],[-82.904851,34.871327],[-82.897499,35.056021]]]},"properties":{"id":"29676","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.907485,34.724945],[-82.91305,34.728509],[-82.918486,34.725649],[-82.913065,34.723151],[-82.907485,34.724945]]]},"properties":{"id":"29665","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.209094,33.47373],[-81.064042,33.452872],[-81.039468,33.453636],[-81.003653,33.563347],[-81.008208,33.570532],[-81.25249,33.595195],[-81.236566,33.533343],[-81.209094,33.47373]]]},"properties":{"id":"29107","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.861455,33.413609],[-80.963622,33.430267],[-80.885551,33.359714],[-80.861455,33.413609]]]},"properties":{"id":"29039","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937733,34.041751],[-80.983604,34.051392],[-80.98699,34.008088],[-80.967449,33.996001],[-80.959035,33.996019],[-80.937733,34.041751]]]},"properties":{"id":"29206","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.026293,33.997284],[-81.031106,33.9958],[-81.030515,33.994482],[-81.029925,33.993152],[-81.02516,33.994631],[-81.026293,33.997284]]],[[[-81.020674,34.005313],[-80.980116,33.962836],[-80.967449,33.996001],[-80.98699,34.008088],[-81.020674,34.005313]]]]},"properties":{"id":"29205","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.959035,33.996019],[-80.890333,33.985665],[-80.780466,34.003718],[-80.720569,34.066214],[-80.779658,34.102293],[-80.826382,34.101515],[-80.874463,34.082455],[-80.937733,34.041751],[-80.959035,33.996019]]]},"properties":{"id":"29207","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.492573,33.993383],[-80.490744,33.954368],[-80.446054,33.965392],[-80.463777,33.989207],[-80.492573,33.993383]]]},"properties":{"id":"29152","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.768164,34.853963],[-81.790059,34.810918],[-81.780376,34.736758],[-81.702654,34.761553],[-81.487074,34.816608],[-81.457257,34.83928],[-81.640742,34.880129],[-81.768164,34.853963]]]},"properties":{"id":"29353","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.038025,35.027514],[-81.168841,34.902818],[-81.036101,34.941435],[-81.031104,34.943244],[-80.983397,34.987439],[-81.038025,35.027514]]]},"properties":{"id":"29732","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.892017,33.626865],[-81.933554,33.693608],[-81.978806,33.673588],[-82.048959,33.56487],[-82.012423,33.532096],[-81.891094,33.58261],[-81.892017,33.626865]]]},"properties":{"id":"29860","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.516319,33.382311],[-81.357129,33.31165],[-81.351265,33.338963],[-81.378825,33.380538],[-81.357958,33.39509],[-81.288954,33.470379],[-81.395899,33.501503],[-81.527221,33.393499],[-81.516319,33.382311]]]},"properties":{"id":"29853","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.227804,33.160697],[-81.337806,33.108221],[-81.244723,33.049887],[-81.143499,33.071726],[-81.146467,33.077838],[-81.227804,33.160697]]]},"properties":{"id":"29849","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.68788,32.456273],[-80.714661,32.377317],[-80.672583,32.400819],[-80.68788,32.456273]]],[[[-80.658733,32.337925],[-80.712991,32.362079],[-80.746528,32.347292],[-80.698045,32.295334],[-80.681397,32.286242],[-80.658733,32.337925]]]]},"properties":{"id":"29902","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.672583,32.400819],[-80.714661,32.377317],[-80.688352,32.368295],[-80.678317,32.367949],[-80.672583,32.400819]]]},"properties":{"id":"29935","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.138001,33.890597],[-81.160623,33.880035],[-81.194365,33.796739],[-81.168968,33.757543],[-80.945779,33.835884],[-80.965945,33.847848],[-81.01233,33.88008],[-81.138001,33.890597]]]},"properties":{"id":"29053","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.024953,32.835082],[-80.05027,32.84749],[-80.126468,32.901237],[-80.131958,32.900548],[-80.149463,32.909841],[-80.182327,32.864072],[-80.035297,32.771918],[-80.024953,32.835082]]]},"properties":{"id":"29414","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.035297,32.771918],[-80.182327,32.864072],[-80.17446,32.845651],[-80.130355,32.786565],[-80.171591,32.707913],[-80.209161,32.57937],[-80.193676,32.554213],[-79.99003,32.622837],[-80.003957,32.765807],[-80.035297,32.771918]]]},"properties":{"id":"29455","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.967208,32.827597],[-79.931025,32.829007],[-79.962699,32.891034],[-79.998554,32.865453],[-80.05027,32.84749],[-80.024953,32.835082],[-79.967208,32.827597]]]},"properties":{"id":"29405","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.058969,32.93122],[-80.126468,32.901237],[-80.05027,32.84749],[-79.998554,32.865453],[-80.02453,32.886386],[-80.076721,32.896513],[-80.054851,32.926493],[-80.058969,32.93122]]]},"properties":{"id":"29418","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.725458,32.855365],[-79.802195,32.929916],[-79.849307,32.882389],[-79.801968,32.80793],[-79.725458,32.855365]]]},"properties":{"id":"29466","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.960216,32.79415],[-79.960116,32.798976],[-79.963392,32.797681],[-79.960216,32.79415]]]},"properties":{"id":"29409","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.939384,32.784714],[-79.93796,32.784033],[-79.936192,32.781575],[-79.93533,32.781906],[-79.937708,32.785308],[-79.939384,32.784714]]]},"properties":{"id":"29424","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.897696,35.038558],[-81.915075,34.973385],[-81.906101,34.978468],[-81.906195,34.967516],[-81.915084,34.965044],[-81.920434,34.962527],[-81.840474,34.942212],[-81.834951,34.949933],[-81.831712,34.942356],[-81.771389,34.928305],[-81.750115,34.927401],[-81.738578,34.928337],[-81.735855,34.937104],[-81.755661,35.006324],[-81.862801,35.051877],[-81.897696,35.038558]],[[-81.830612,34.989085],[-81.824221,34.984249],[-81.825382,34.977226],[-81.831567,34.988435],[-81.83401,34.991584],[-81.841532,34.992754],[-81.839281,34.998388],[-81.83464,34.9959],[-81.830612,34.989085]]]},"properties":{"id":"29307","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.976554,34.594257],[-81.074421,34.622421],[-81.300714,34.566181],[-81.297419,34.544547],[-81.262173,34.452628],[-80.96384,34.546426],[-80.976554,34.594257]]]},"properties":{"id":"29014","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.288692,34.364139],[-80.199583,34.351354],[-80.171474,34.541582],[-80.173018,34.547864],[-80.358873,34.536364],[-80.288692,34.364139]]]},"properties":{"id":"29101","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.011117,34.660272],[-80.175258,34.651153],[-80.211597,34.662947],[-80.262026,34.627029],[-80.173018,34.547864],[-80.171474,34.541582],[-79.956698,34.486699],[-79.939358,34.580401],[-80.011117,34.660272]]]},"properties":{"id":"29584","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.07113,33.869581],[-80.07227,33.779638],[-80.06057,33.75664],[-80.030207,33.696471],[-79.957514,33.72489],[-79.935509,33.782055],[-79.944505,33.810214],[-79.963783,33.843897],[-80.07113,33.869581]]]},"properties":{"id":"29111","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.974601,33.946527],[-80.068033,33.900408],[-80.07113,33.869581],[-79.963783,33.843897],[-79.966932,33.889506],[-79.974601,33.946527]]]},"properties":{"id":"29162","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.93003,34.012121],[-79.974601,33.946527],[-79.966932,33.889506],[-79.879235,33.92111],[-79.884056,33.989368],[-79.93003,34.012121]]]},"properties":{"id":"29114","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.816855,32.80533],[-80.675077,32.757594],[-80.531934,32.812887],[-80.571198,33.057715],[-80.649678,33.090052],[-80.65004,33.090195],[-80.726686,33.067225],[-80.816855,32.80533]]]},"properties":{"id":"29488","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.546092,33.288179],[-80.523005,33.222473],[-80.33493,33.221066],[-80.344318,33.234228],[-80.50279,33.334496],[-80.546092,33.288179]]]},"properties":{"id":"29448","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.523005,33.222473],[-80.506412,33.058797],[-80.504195,33.057622],[-80.42254,33.054829],[-80.33493,33.221066],[-80.523005,33.222473]]]},"properties":{"id":"29437","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.879227,34.457776],[-80.880155,34.511581],[-80.96384,34.546426],[-81.262173,34.452628],[-81.260729,34.324217],[-81.31316,34.23735],[-81.247437,34.203113],[-81.178293,34.174552],[-81.108407,34.165835],[-81.073343,34.249115],[-80.844473,34.424769],[-80.879227,34.457776]]]},"properties":{"id":"29180","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.345804,34.299575],[-81.321072,34.243731],[-81.316911,34.239458],[-81.31316,34.23735],[-81.260729,34.324217],[-81.345804,34.299575]]]},"properties":{"id":"29065","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.411211,34.433252],[-81.345804,34.299575],[-81.260729,34.324217],[-81.262173,34.452628],[-81.297419,34.544547],[-81.423241,34.494379],[-81.411211,34.433252]]]},"properties":{"id":"29015","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.596551,34.081042],[-79.637951,33.984267],[-79.656758,33.920134],[-79.656161,33.912639],[-79.545843,33.852263],[-79.482632,33.937589],[-79.482032,33.966096],[-79.535909,34.076085],[-79.568693,34.093939],[-79.596551,34.081042]]]},"properties":{"id":"29583","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.482632,33.937589],[-79.545843,33.852263],[-79.63488,33.808359],[-79.324317,33.799123],[-79.482632,33.937589]]]},"properties":{"id":"29555","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.656758,33.920134],[-79.70489,34.018859],[-79.810866,34.001661],[-79.656758,33.920134]]]},"properties":{"id":"29530","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075999,35.190372],[-82.232037,35.068795],[-82.218244,35.039126],[-82.027285,35.159593],[-82.027537,35.189124],[-82.075999,35.190372]]]},"properties":{"id":"29322","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.21428,32.674857],[-81.260751,32.710911],[-81.243659,32.665899],[-81.21428,32.674857]]]},"properties":{"id":"29939","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.141349,32.658074],[-81.167641,32.552106],[-81.09581,32.519637],[-81.096211,32.626773],[-81.099419,32.712663],[-81.154095,32.684984],[-81.141349,32.658074]]]},"properties":{"id":"29934","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.011082,33.753232],[-78.960323,33.695941],[-78.848933,33.749746],[-78.800279,33.806469],[-79.011082,33.753232]]]},"properties":{"id":"29579","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.776904,33.789884],[-78.735812,33.788805],[-78.574059,33.879537],[-78.741498,33.818733],[-78.776904,33.789884]]]},"properties":{"id":"29582","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.807927,34.260613],[-80.779658,34.102293],[-80.720569,34.066214],[-80.607456,34.043262],[-80.605474,34.182094],[-80.698083,34.334183],[-80.807927,34.260613]]]},"properties":{"id":"29078","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.62211,34.435548],[-80.621313,34.465669],[-80.580768,34.463386],[-80.584416,34.422442],[-80.546472,34.399206],[-80.455372,34.412292],[-80.40461,34.565298],[-80.485734,34.717626],[-80.48976,34.720216],[-80.573991,34.683775],[-80.660777,34.481793],[-80.62211,34.435548]]]},"properties":{"id":"29067","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.974601,33.946527],[-79.93003,34.012121],[-80.072808,34.086013],[-80.167729,34.16693],[-80.157104,34.129462],[-80.161239,34.0889],[-80.182701,34.053509],[-80.121997,33.911381],[-80.068033,33.900408],[-79.974601,33.946527]]]},"properties":{"id":"29080","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.093345,33.956076],[-81.127131,33.999158],[-81.160623,33.880035],[-81.138001,33.890597],[-81.098521,33.944063],[-81.093345,33.956076]]]},"properties":{"id":"29170","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.138001,33.890597],[-81.01233,33.88008],[-81.029214,33.916454],[-81.098521,33.944063],[-81.138001,33.890597]]]},"properties":{"id":"29172","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.394083,34.071545],[-81.352178,33.800846],[-81.329052,33.836847],[-81.325828,33.911355],[-81.331863,34.065149],[-81.394083,34.071545]]]},"properties":{"id":"29054","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.321072,34.243731],[-81.333207,34.241523],[-81.316911,34.239458],[-81.321072,34.243731]]]},"properties":{"id":"29122","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.979923,34.836579],[-83.013562,34.7252],[-82.955771,34.835735],[-82.979923,34.836579]]]},"properties":{"id":"29696","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.056189,35.014645],[-83.064337,34.861128],[-83.010337,34.873506],[-83.018656,35.025067],[-83.056189,35.014645]]]},"properties":{"id":"29686","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.496273,33.558481],[-80.61811,33.4587],[-80.625558,33.436742],[-80.57413,33.375615],[-80.49117,33.424656],[-80.433515,33.475037],[-80.496273,33.558481]]]},"properties":{"id":"29142","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.22011,33.439493],[-81.133282,33.393722],[-81.08241,33.375464],[-81.064042,33.452872],[-81.209094,33.47373],[-81.22011,33.439493]]]},"properties":{"id":"29113","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.073945,33.368441],[-80.887109,33.264183],[-80.885551,33.359714],[-80.963622,33.430267],[-81.039468,33.453636],[-81.064042,33.452872],[-81.08241,33.375464],[-81.073945,33.368441]]]},"properties":{"id":"29038","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.848977,33.49913],[-80.848517,33.496397],[-80.845061,33.497364],[-80.848108,33.502217],[-80.848977,33.49913]]]},"properties":{"id":"29117","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.781155,34.822989],[-82.836082,34.89083],[-82.904851,34.871327],[-82.896741,34.802925],[-82.88352,34.791788],[-82.764446,34.805819],[-82.781155,34.822989]]]},"properties":{"id":"29682","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.851005,34.669027],[-82.836288,34.666782],[-82.838436,34.682486],[-82.853707,34.679673],[-82.851005,34.669027]]]},"properties":{"id":"29634","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.029214,33.916454],[-81.01233,33.88008],[-80.965945,33.847848],[-80.890333,33.985665],[-80.959035,33.996019],[-80.967449,33.996001],[-80.980116,33.962836],[-81.017654,33.93237],[-81.029214,33.916454]]]},"properties":{"id":"29209","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.780466,34.003718],[-80.794318,33.870504],[-80.645083,33.752965],[-80.620107,33.743237],[-80.628137,33.947588],[-80.600952,34.039386],[-80.607456,34.043262],[-80.720569,34.066214],[-80.780466,34.003718]]]},"properties":{"id":"29044","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.794318,33.870504],[-80.780466,34.003718],[-80.890333,33.985665],[-80.965945,33.847848],[-80.945779,33.835884],[-80.834817,33.787388],[-80.713156,33.76865],[-80.794318,33.870504]]]},"properties":{"id":"29061","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.790059,34.810918],[-81.768164,34.853963],[-81.771389,34.928305],[-81.831712,34.942356],[-81.840474,34.942212],[-81.920434,34.962527],[-81.926573,34.959375],[-81.901196,34.842657],[-81.790059,34.810918]]]},"properties":{"id":"29302","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.107603,34.929382],[-82.094822,34.921954],[-82.105169,34.939505],[-82.107603,34.929382]]]},"properties":{"id":"29377","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.831712,34.942356],[-81.834951,34.949933],[-81.840474,34.942212],[-81.831712,34.942356]]]},"properties":{"id":"29346","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.915084,34.965044],[-81.906195,34.967516],[-81.906101,34.978468],[-81.915075,34.973385],[-81.915084,34.965044]]]},"properties":{"id":"29333","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.086538,34.063881],[-82.089387,34.02305],[-82.088272,34.026018],[-82.086538,34.063881]]],[[[-82.285687,33.984779],[-82.159959,33.979259],[-82.303349,34.130809],[-82.326947,34.06412],[-82.285687,33.984779]]]]},"properties":{"id":"29819","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778607,33.561675],[-81.806934,33.57855],[-81.827903,33.617811],[-81.892017,33.626865],[-81.891094,33.58261],[-81.89452,33.50522],[-81.894491,33.505147],[-81.849182,33.525433],[-81.778607,33.561675]]]},"properties":{"id":"29829","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.914282,33.435296],[-81.832125,33.382176],[-81.798108,33.467844],[-81.870149,33.496899],[-81.888006,33.496327],[-81.894491,33.505147],[-81.89452,33.50522],[-81.925732,33.461835],[-81.914282,33.435296]]]},"properties":{"id":"29842","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.757035,33.198148],[-81.4912,32.997678],[-81.420963,33.082091],[-81.686613,33.251962],[-81.757035,33.198148]]]},"properties":{"id":"29836","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.451692,32.871951],[-81.26793,32.860706],[-81.25024,32.90644],[-81.082291,33.02663],[-81.143499,33.071726],[-81.244723,33.049887],[-81.451692,32.871951]]]},"properties":{"id":"29827","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.482366,34.788536],[-82.425833,34.788707],[-82.423909,34.828725],[-82.421379,34.87031],[-82.428664,34.877139],[-82.496251,34.889023],[-82.496474,34.829946],[-82.482366,34.788536]]]},"properties":{"id":"29611","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.357958,33.39509],[-81.378825,33.380538],[-81.351265,33.338963],[-81.357958,33.39509]]]},"properties":{"id":"29826","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.760335,32.299048],[-81.0011,32.244178],[-80.899642,32.111123],[-80.829594,32.147582],[-80.78606,32.194911],[-80.760335,32.299048]]]},"properties":{"id":"29910","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.967719,33.688917],[-81.187271,33.652937],[-81.25249,33.595195],[-81.008208,33.570532],[-80.937754,33.650064],[-80.967719,33.688917]]]},"properties":{"id":"29112","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.609053,33.133057],[-79.542559,32.99291],[-79.29193,33.108987],[-79.446699,33.213458],[-79.609053,33.133057]]]},"properties":{"id":"29458","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.916785,32.763274],[-79.862512,32.740214],[-79.812189,32.775888],[-79.809629,32.779216],[-79.917163,32.782515],[-79.916785,32.763274]]]},"properties":{"id":"29482","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17446,32.845651],[-80.223325,32.873272],[-80.265709,32.909688],[-80.329071,32.870586],[-80.274143,32.759455],[-80.130355,32.786565],[-80.17446,32.845651]]]},"properties":{"id":"29470","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.91644,32.79617],[-79.917619,32.818888],[-79.931025,32.829007],[-79.967208,32.827597],[-79.956846,32.779657],[-79.939384,32.784714],[-79.937708,32.785308],[-79.91644,32.79617]],[[-79.960216,32.79415],[-79.963392,32.797681],[-79.960116,32.798976],[-79.960216,32.79415]]]},"properties":{"id":"29403","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.939384,32.784714],[-79.956846,32.779657],[-79.949475,32.773664],[-79.916785,32.763274],[-79.917163,32.782515],[-79.91644,32.79617],[-79.937708,32.785308],[-79.93533,32.781906],[-79.936192,32.781575],[-79.93796,32.784033],[-79.939384,32.784714]]]},"properties":{"id":"29401","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.073491,32.97838],[-80.069643,32.977108],[-80.068831,32.979417],[-80.072822,32.980401],[-80.073491,32.97838]]]},"properties":{"id":"29423","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.72491,35.016528],[-81.81689,35.129226],[-81.857322,35.086467],[-81.859386,35.080023],[-81.867329,35.077387],[-81.862801,35.051877],[-81.755661,35.006324],[-81.72491,35.016528]]]},"properties":{"id":"29330","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.300714,34.566181],[-81.074421,34.622421],[-81.037457,34.794273],[-81.141099,34.820573],[-81.2979,34.822018],[-81.454458,34.779916],[-81.478364,34.723688],[-81.454129,34.693681],[-81.300714,34.566181]]]},"properties":{"id":"29706","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.896294,34.635535],[-80.942625,34.667097],[-80.976554,34.594257],[-80.96384,34.546426],[-80.880155,34.511581],[-80.882346,34.584744],[-80.896294,34.635535]]]},"properties":{"id":"29055","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.570976,34.573646],[-81.423241,34.494379],[-81.297419,34.544547],[-81.300714,34.566181],[-81.454129,34.693681],[-81.570976,34.573646]]]},"properties":{"id":"29031","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.2979,34.822018],[-81.282215,34.894754],[-81.352318,34.963351],[-81.380018,34.970359],[-81.463063,34.854969],[-81.457257,34.83928],[-81.487074,34.816608],[-81.464998,34.800575],[-81.454458,34.779916],[-81.2979,34.822018]]]},"properties":{"id":"29742","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.013274,34.808403],[-80.011117,34.660272],[-79.939358,34.580401],[-79.829981,34.531925],[-79.777815,34.614293],[-79.927618,34.806555],[-80.013274,34.808403]]]},"properties":{"id":"29520","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.199583,34.351354],[-80.134541,34.270307],[-80.118264,34.278554],[-80.114151,34.285981],[-80.107818,34.288747],[-80.060173,34.248574],[-80.013145,34.244492],[-79.968373,34.330186],[-79.970116,34.469343],[-79.956698,34.486699],[-80.171474,34.541582],[-80.199583,34.351354]]]},"properties":{"id":"29550","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.571198,33.057715],[-80.531934,32.812887],[-80.526628,32.760482],[-80.418822,32.801555],[-80.402631,32.865873],[-80.504195,33.057622],[-80.506412,33.058797],[-80.571198,33.057715]]]},"properties":{"id":"29474","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.526628,32.760482],[-80.356791,32.493748],[-80.389401,32.65282],[-80.429304,32.719662],[-80.418822,32.801555],[-80.526628,32.760482]]]},"properties":{"id":"29452","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.504195,33.057622],[-80.402631,32.865873],[-80.400209,32.872475],[-80.42254,33.054829],[-80.504195,33.057622]]]},"properties":{"id":"29435","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.93003,34.012121],[-79.884056,33.989368],[-79.847477,33.982076],[-79.833067,34.149689],[-79.914085,34.189126],[-80.013145,34.244492],[-80.060173,34.248574],[-80.072808,34.086013],[-79.93003,34.012121]]]},"properties":{"id":"29161","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.689289,33.953333],[-81.618979,34.068121],[-81.611198,34.123625],[-81.716236,34.175535],[-81.737944,34.185663],[-81.884324,34.056228],[-81.969098,34.016165],[-81.941356,33.992556],[-81.873112,33.901754],[-81.764885,33.932988],[-81.689289,33.953333]]]},"properties":{"id":"29138","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.656758,33.920134],[-79.637951,33.984267],[-79.70489,34.018859],[-79.656758,33.920134]]],[[[-79.879235,33.92111],[-79.656161,33.912639],[-79.656758,33.920134],[-79.810866,34.001661],[-79.847477,33.982076],[-79.884056,33.989368],[-79.879235,33.92111]]]]},"properties":{"id":"29591","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.254825,34.829701],[-82.359374,34.87837],[-82.366432,34.871524],[-82.38564,34.859118],[-82.378879,34.826974],[-82.325324,34.728797],[-82.298226,34.762344],[-82.280335,34.790855],[-82.254825,34.829701]]]},"properties":{"id":"29607","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.325692,34.718112],[-82.339527,34.669074],[-82.303386,34.62455],[-82.239519,34.70446],[-82.286417,34.754192],[-82.298226,34.762344],[-82.325324,34.728797],[-82.325692,34.718112]]]},"properties":{"id":"29680","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.423909,34.828725],[-82.378879,34.826974],[-82.38564,34.859118],[-82.421379,34.87031],[-82.423909,34.828725]]]},"properties":{"id":"29601","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.434471,34.938097],[-82.445815,34.919954],[-82.435926,34.91491],[-82.434471,34.938097]]]},"properties":{"id":"29613","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.941356,33.992556],[-81.969098,34.016165],[-82.088272,34.026018],[-82.089387,34.02305],[-82.159959,33.979259],[-82.060556,33.950248],[-81.941356,33.992556]]],[[[-82.469923,34.007068],[-82.285687,33.984779],[-82.326947,34.06412],[-82.449426,34.060203],[-82.478937,34.027022],[-82.469923,34.007068]]]]},"properties":{"id":"29848","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.99207,34.24226],[-82.086538,34.063881],[-82.088272,34.026018],[-81.969098,34.016165],[-81.884324,34.056228],[-81.945019,34.203148],[-81.99207,34.24226]]]},"properties":{"id":"29666","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.879947,32.792059],[-81.030731,32.661782],[-80.970505,32.64342],[-80.879947,32.792059]]]},"properties":{"id":"29916","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.989924,32.91038],[-81.124233,32.796836],[-81.099419,32.712663],[-81.096211,32.626773],[-81.030731,32.661782],[-80.879947,32.792059],[-80.989924,32.91038]]]},"properties":{"id":"29944","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.741498,33.818733],[-78.574059,33.879537],[-78.634227,33.930668],[-78.741498,33.818733]]]},"properties":{"id":"29566","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.546472,34.399206],[-80.584416,34.422442],[-80.62211,34.435548],[-80.660777,34.481793],[-80.763205,34.462428],[-80.783625,34.399453],[-80.698083,34.334183],[-80.605474,34.182094],[-80.394374,34.163184],[-80.429939,34.26432],[-80.546472,34.399206]]]},"properties":{"id":"29020","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.906413,35.076666],[-80.869156,34.945077],[-80.782042,34.935785],[-80.906413,35.076666]]]},"properties":{"id":"29707","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.131103,34.023255],[-81.325828,33.911355],[-81.329052,33.836847],[-81.194365,33.796739],[-81.160623,33.880035],[-81.127131,33.999158],[-81.131103,34.023255]]]},"properties":{"id":"29073","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.194365,33.796739],[-81.329052,33.836847],[-81.352178,33.800846],[-81.282836,33.694333],[-81.269666,33.693142],[-81.256888,33.685282],[-81.168968,33.757543],[-81.194365,33.796739]]]},"properties":{"id":"29123","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.570807,34.617357],[-79.460615,34.602615],[-79.450034,34.621036],[-79.627842,34.765754],[-79.593918,34.648638],[-79.558929,34.640268],[-79.572715,34.624853],[-79.570807,34.617357]]]},"properties":{"id":"29570","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.737944,34.185663],[-81.851106,34.273934],[-81.869722,34.31411],[-81.89101,34.303411],[-81.945019,34.203148],[-81.884324,34.056228],[-81.737944,34.185663]]]},"properties":{"id":"29037","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.851106,34.273934],[-81.737944,34.185663],[-81.716236,34.175535],[-81.791913,34.283984],[-81.851106,34.273934]]]},"properties":{"id":"29145","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.966699,34.543425],[-83.021796,34.677863],[-83.025603,34.711832],[-83.19728,34.757662],[-83.232404,34.767132],[-83.353348,34.726621],[-83.092873,34.532891],[-82.966699,34.543425]]]},"properties":{"id":"29693","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.955771,34.835735],[-83.013562,34.7252],[-83.025603,34.711832],[-83.021796,34.677863],[-82.850735,34.692848],[-82.850032,34.696446],[-82.88352,34.791788],[-82.896741,34.802925],[-82.955771,34.835735]],[[-82.907485,34.724945],[-82.913065,34.723151],[-82.918486,34.725649],[-82.91305,34.728509],[-82.907485,34.724945]]]},"properties":{"id":"29672","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.056189,35.014645],[-83.306595,34.814076],[-83.232404,34.767132],[-83.19728,34.757662],[-83.064337,34.861128],[-83.056189,35.014645]]]},"properties":{"id":"29664","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.729177,33.40051],[-80.861455,33.413609],[-80.885551,33.359714],[-80.887109,33.264183],[-80.887879,33.260539],[-80.712283,33.300756],[-80.729177,33.40051]]]},"properties":{"id":"29133","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.003653,33.563347],[-81.039468,33.453636],[-80.963622,33.430267],[-80.861455,33.413609],[-80.729177,33.40051],[-80.683214,33.442044],[-80.764894,33.573265],[-80.775976,33.581322],[-81.003653,33.563347]],[[-80.848977,33.49913],[-80.848108,33.502217],[-80.845061,33.497364],[-80.848517,33.496397],[-80.848977,33.49913]]]},"properties":{"id":"29115","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.289268,33.279113],[-80.222267,33.443716],[-80.394811,33.447191],[-80.377394,33.393312],[-80.289268,33.279113]]]},"properties":{"id":"29048","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.950158,34.149047],[-81.108407,34.165835],[-81.178293,34.174552],[-81.1371,34.133342],[-81.104088,34.089207],[-81.064715,34.020853],[-81.031235,34.022566],[-81.002433,34.055157],[-80.960463,34.09058],[-80.972078,34.090607],[-80.96247,34.096233],[-80.944601,34.130774],[-80.950158,34.149047]]]},"properties":{"id":"29203","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.031235,34.022566],[-81.064715,34.020853],[-81.059715,34.005253],[-81.048109,33.991304],[-81.017654,33.93237],[-80.980116,33.962836],[-81.020674,34.005313],[-81.031235,34.022566]],[[-81.031106,33.9958],[-81.03067,33.998981],[-81.024882,33.997696],[-81.02516,33.994631],[-81.029925,33.993152],[-81.031562,33.992778],[-81.032059,33.993899],[-81.030515,33.994482],[-81.031106,33.9958]]]},"properties":{"id":"29201","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.794318,33.870504],[-80.713156,33.76865],[-80.645083,33.752965],[-80.794318,33.870504]]]},"properties":{"id":"29052","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075424,34.987834],[-82.045716,34.962947],[-81.937165,34.953007],[-81.926573,34.959375],[-81.920434,34.962527],[-81.915084,34.965044],[-81.915075,34.973385],[-81.897696,35.038558],[-81.927293,35.036237],[-82.024723,35.019926],[-82.075424,34.987834]]]},"properties":{"id":"29303","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.105169,34.939505],[-82.150813,35.00474],[-82.209536,35.017754],[-82.174266,34.957522],[-82.111209,34.937735],[-82.105169,34.939505]]]},"properties":{"id":"29365","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.463583,33.807132],[-80.575563,33.952847],[-80.628137,33.947588],[-80.620107,33.743237],[-80.59658,33.69549],[-80.463583,33.807132]]]},"properties":{"id":"29168","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.729125,33.563018],[-79.873447,33.662415],[-79.950015,33.518293],[-79.75134,33.463578],[-79.729125,33.563018]]]},"properties":{"id":"29590","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.350759,35.083232],[-81.382754,35.078074],[-81.362108,35.070795],[-81.350759,35.083232]]],[[[-81.347453,35.090113],[-81.334383,35.046279],[-81.356467,34.983451],[-81.057812,35.067059],[-81.366597,35.164891],[-81.347453,35.090113]]]]},"properties":{"id":"29710","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.334383,35.046279],[-81.347453,35.090113],[-81.350759,35.083232],[-81.362108,35.070795],[-81.334383,35.046279]]],[[[-81.352318,34.963351],[-81.282215,34.894754],[-81.175934,34.889765],[-81.168841,34.902818],[-81.038025,35.027514],[-81.041489,35.044703],[-81.057812,35.067059],[-81.356467,34.983451],[-81.352318,34.963351]]]]},"properties":{"id":"29745","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.303349,34.130809],[-82.239959,34.19026],[-82.247063,34.217167],[-82.330907,34.280763],[-82.377545,34.29302],[-82.460897,34.253777],[-82.605756,34.175485],[-82.500851,34.071903],[-82.500771,34.071906],[-82.449426,34.060203],[-82.326947,34.06412],[-82.303349,34.130809]]]},"properties":{"id":"29620","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.767748,33.530418],[-81.573563,33.513765],[-81.526447,33.564656],[-81.724095,33.666824],[-81.74518,33.69158],[-81.823975,33.620822],[-81.806934,33.57855],[-81.778607,33.561675],[-81.767748,33.530418]]]},"properties":{"id":"29801","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.806934,33.57855],[-81.823975,33.620822],[-81.827903,33.617811],[-81.806934,33.57855]]]},"properties":{"id":"29850","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.4912,32.997678],[-81.452405,32.871761],[-81.451692,32.871951],[-81.244723,33.049887],[-81.337806,33.108221],[-81.420963,33.082091],[-81.4912,32.997678]]]},"properties":{"id":"29810","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.683823,34.502045],[-82.810774,34.504389],[-82.855486,34.441791],[-82.841371,34.397129],[-82.668029,34.44571],[-82.683823,34.502045]]]},"properties":{"id":"29626","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.449086,34.617686],[-82.581463,34.687595],[-82.594965,34.694756],[-82.643084,34.65561],[-82.599881,34.595067],[-82.449086,34.617686]]]},"properties":{"id":"29697","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.862378,34.602253],[-82.810774,34.504389],[-82.683823,34.502045],[-82.652029,34.504266],[-82.720754,34.581234],[-82.840253,34.623212],[-82.862378,34.602253]]]},"properties":{"id":"29625","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.764446,34.805819],[-82.88352,34.791788],[-82.850032,34.696446],[-82.787568,34.672734],[-82.696271,34.695112],[-82.750979,34.757049],[-82.760353,34.760983],[-82.759863,34.769506],[-82.751198,34.765853],[-82.764446,34.805819]]]},"properties":{"id":"29630","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.038973,33.303455],[-80.208955,33.442662],[-80.222267,33.443716],[-80.289268,33.279113],[-80.276682,33.238581],[-80.209741,33.213178],[-80.163865,33.237027],[-80.038318,33.302978],[-80.038973,33.303455]]]},"properties":{"id":"29436","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.377394,33.393312],[-80.49117,33.424656],[-80.57413,33.375615],[-80.50279,33.334496],[-80.344318,33.234228],[-80.276682,33.238581],[-80.289268,33.279113],[-80.377394,33.393312]]]},"properties":{"id":"29059","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.208955,33.442662],[-80.038973,33.303455],[-79.970873,33.350687],[-79.960292,33.494019],[-80.101697,33.496891],[-80.18038,33.454723],[-80.208955,33.442662]]]},"properties":{"id":"29468","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.596593,33.695137],[-80.764894,33.573265],[-80.683214,33.442044],[-80.625558,33.436742],[-80.61811,33.4587],[-80.519417,33.610072],[-80.596593,33.695137]]]},"properties":{"id":"29030","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.945779,33.835884],[-81.168968,33.757543],[-81.256888,33.685282],[-81.187271,33.652937],[-80.967719,33.688917],[-80.834817,33.787388],[-80.945779,33.835884]]]},"properties":{"id":"29160","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.802195,32.929916],[-79.725458,32.855365],[-79.697991,32.848515],[-79.542559,32.99291],[-79.609053,33.133057],[-79.645208,33.120478],[-79.763446,32.956359],[-79.802195,32.929916]]]},"properties":{"id":"29429","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.697991,32.848515],[-79.725458,32.855365],[-79.801968,32.80793],[-79.809629,32.779216],[-79.812189,32.775888],[-79.697991,32.848515]]]},"properties":{"id":"29451","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.429304,32.719662],[-80.274143,32.759455],[-80.329071,32.870586],[-80.400209,32.872475],[-80.402631,32.865873],[-80.418822,32.801555],[-80.429304,32.719662]]]},"properties":{"id":"29426","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.735855,34.937104],[-81.640742,34.880129],[-81.457257,34.83928],[-81.463063,34.854969],[-81.488286,35.026868],[-81.576434,35.117765],[-81.72491,35.016528],[-81.755661,35.006324],[-81.735855,34.937104]]]},"properties":{"id":"29340","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.771389,34.928305],[-81.768164,34.853963],[-81.640742,34.880129],[-81.735855,34.937104],[-81.738578,34.928337],[-81.746189,34.914783],[-81.759133,34.919194],[-81.750115,34.927401],[-81.771389,34.928305]]]},"properties":{"id":"29372","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.031104,34.943244],[-81.029075,34.93943],[-81.031544,34.935569],[-81.036101,34.941435],[-81.168841,34.902818],[-81.175934,34.889765],[-81.141099,34.820573],[-81.037457,34.794273],[-81.036275,34.801364],[-80.965262,34.837073],[-80.876714,34.860199],[-80.868214,34.941227],[-80.983397,34.987439],[-81.031104,34.943244]]]},"properties":{"id":"29730","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.012598,34.778004],[-81.009669,34.770962],[-81.00538,34.77614],[-81.012598,34.778004]]]},"properties":{"id":"29724","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.367926,33.669128],[-80.18038,33.454723],[-80.101697,33.496891],[-80.030207,33.696471],[-80.06057,33.75664],[-80.270785,33.78676],[-80.274059,33.785868],[-80.366109,33.772399],[-80.396993,33.746864],[-80.367926,33.669128]]]},"properties":{"id":"29102","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.496273,33.558481],[-80.433515,33.475037],[-80.394811,33.447191],[-80.222267,33.443716],[-80.208955,33.442662],[-80.18038,33.454723],[-80.367926,33.669128],[-80.521295,33.599682],[-80.496273,33.558481]]]},"properties":{"id":"29148","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.463583,33.807132],[-80.59658,33.69549],[-80.596593,33.695137],[-80.519417,33.610072],[-80.521295,33.599682],[-80.367926,33.669128],[-80.396993,33.746864],[-80.463583,33.807132]]]},"properties":{"id":"29125","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.944505,33.810214],[-79.807137,33.723879],[-79.66418,33.73509],[-79.591918,33.736626],[-79.63488,33.808359],[-79.545843,33.852263],[-79.656161,33.912639],[-79.879235,33.92111],[-79.966932,33.889506],[-79.963783,33.843897],[-79.944505,33.810214]]]},"properties":{"id":"29560","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.053258,32.989564],[-81.019048,32.958781],[-80.989924,32.91038],[-80.879947,32.792059],[-80.870008,32.873111],[-80.877054,32.950907],[-81.005489,33.026919],[-81.053258,32.989564]]]},"properties":{"id":"29929","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.914085,34.189126],[-79.833067,34.149689],[-79.806355,34.155613],[-79.761576,34.176524],[-79.737553,34.279585],[-79.914085,34.189126]]]},"properties":{"id":"29501","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.048959,33.56487],[-81.978806,33.673588],[-82.09705,33.727493],[-82.224845,33.691098],[-82.048959,33.56487]]]},"properties":{"id":"29821","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.761576,34.176524],[-79.806355,34.155613],[-79.596551,34.081042],[-79.568693,34.093939],[-79.761576,34.176524]]]},"properties":{"id":"29505","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.677014,33.304944],[-79.441221,33.592838],[-79.607779,33.609195],[-79.729125,33.563018],[-79.75134,33.463578],[-79.867503,33.433651],[-79.741153,33.322308],[-79.677014,33.304944]]]},"properties":{"id":"29510","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.254825,34.829701],[-82.280335,34.790855],[-82.286417,34.754192],[-82.239519,34.70446],[-82.146792,34.785554],[-82.169431,34.801759],[-82.227616,34.829382],[-82.254825,34.829701]]]},"properties":{"id":"29681","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.880079,34.262116],[-80.888782,34.189908],[-80.826382,34.101515],[-80.779658,34.102293],[-80.807927,34.260613],[-80.880079,34.262116]]]},"properties":{"id":"29045","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.62211,34.435548],[-80.584416,34.422442],[-80.580768,34.463386],[-80.621313,34.465669],[-80.62211,34.435548]]]},"properties":{"id":"29175","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.968001,34.425766],[-82.052618,34.39569],[-82.025603,34.35123],[-81.89101,34.303411],[-81.869722,34.31411],[-81.893423,34.350318],[-81.968001,34.425766]]]},"properties":{"id":"29370","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.570976,34.573646],[-81.788749,34.574171],[-81.737103,34.430544],[-81.601132,34.409276],[-81.466896,34.423424],[-81.411211,34.433252],[-81.423241,34.494379],[-81.570976,34.573646]]]},"properties":{"id":"29178","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.331863,34.065149],[-81.325828,33.911355],[-81.131103,34.023255],[-81.128259,34.025681],[-81.146917,34.03225],[-81.314001,34.063032],[-81.331863,34.065149]]]},"properties":{"id":"29072","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.435842,34.129365],[-81.249817,34.20074],[-81.247437,34.203113],[-81.31316,34.23735],[-81.316911,34.239458],[-81.333207,34.241523],[-81.436333,34.22975],[-81.435842,34.129365]]]},"properties":{"id":"29075","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.314001,34.063032],[-81.146917,34.03225],[-81.104088,34.089207],[-81.1371,34.133342],[-81.268995,34.099652],[-81.314001,34.063032]]]},"properties":{"id":"29212","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.344232,33.999203],[-79.35285,34.039001],[-79.380938,34.036975],[-79.344232,33.999203]]]},"properties":{"id":"29519","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.627842,34.765754],[-79.762972,34.806056],[-79.777815,34.614293],[-79.829981,34.531925],[-79.749784,34.450595],[-79.725012,34.417243],[-79.598001,34.551106],[-79.570807,34.617357],[-79.572715,34.624853],[-79.593918,34.648638],[-79.627842,34.765754]]]},"properties":{"id":"29512","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.777815,34.614293],[-79.762972,34.806056],[-79.927618,34.806555],[-79.777815,34.614293]]]},"properties":{"id":"29596","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.867329,35.077387],[-81.859386,35.080023],[-81.857322,35.086467],[-81.867329,35.077387]]]},"properties":{"id":"29368","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.42738,33.966995],[-80.366109,33.772399],[-80.274059,33.785868],[-80.42738,33.966995]]]},"properties":{"id":"29150","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.702654,34.761553],[-81.787553,34.671595],[-81.833317,34.592395],[-81.788749,34.574171],[-81.570976,34.573646],[-81.454129,34.693681],[-81.478364,34.723688],[-81.464998,34.800575],[-81.487074,34.816608],[-81.702654,34.761553]]]},"properties":{"id":"29379","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.464998,34.800575],[-81.478364,34.723688],[-81.454458,34.779916],[-81.464998,34.800575]]]},"properties":{"id":"29364","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983397,34.987439],[-80.868214,34.941227],[-80.869156,34.945077],[-80.906413,35.076666],[-80.931059,35.103234],[-80.983397,34.987439]]]},"properties":{"id":"29715","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.169614,34.334786],[-82.251722,34.361837],[-82.330907,34.280763],[-82.247063,34.217167],[-82.114012,34.306769],[-82.169614,34.334786]]]},"properties":{"id":"29653","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.239959,34.19026],[-82.025562,34.258446],[-82.114012,34.306769],[-82.247063,34.217167],[-82.239959,34.19026]]]},"properties":{"id":"29649","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.303349,34.130809],[-82.159959,33.979259],[-82.089387,34.02305],[-82.086538,34.063881],[-81.99207,34.24226],[-82.020327,34.251927],[-82.025562,34.258446],[-82.239959,34.19026],[-82.303349,34.130809]]]},"properties":{"id":"29646","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.892017,33.626865],[-81.827903,33.617811],[-81.823975,33.620822],[-81.74518,33.69158],[-81.735702,33.74916],[-81.871466,33.765416],[-81.933554,33.693608],[-81.892017,33.626865]]]},"properties":{"id":"29847","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.832125,33.382176],[-81.663031,33.27003],[-81.516319,33.382311],[-81.527221,33.393499],[-81.573563,33.513765],[-81.767748,33.530418],[-81.798108,33.467844],[-81.832125,33.382176]],[[-81.692526,33.404597],[-81.708814,33.433189],[-81.667842,33.426202],[-81.692526,33.404597]]]},"properties":{"id":"29803","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.613635,33.914608],[-81.595038,33.739499],[-81.53026,33.754428],[-81.613635,33.914608]]]},"properties":{"id":"29105","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.863227,33.508679],[-81.888006,33.496327],[-81.870149,33.496899],[-81.863227,33.508679]]]},"properties":{"id":"29816","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.832634,33.527448],[-81.828045,33.517636],[-81.817391,33.524024],[-81.828153,33.531339],[-81.832634,33.527448]]]},"properties":{"id":"29828","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26793,32.860706],[-81.451692,32.871951],[-81.452405,32.871761],[-81.405054,32.744957],[-81.212882,32.806469],[-81.198977,32.834522],[-81.219377,32.84348],[-81.26793,32.860706]]]},"properties":{"id":"29932","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.683823,34.502045],[-82.668029,34.44571],[-82.629086,34.389328],[-82.580733,34.359169],[-82.652029,34.504266],[-82.683823,34.502045]]]},"properties":{"id":"29624","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.787568,34.672734],[-82.822782,34.65446],[-82.840253,34.623212],[-82.720754,34.581234],[-82.643084,34.65561],[-82.594965,34.694756],[-82.597835,34.705075],[-82.696271,34.695112],[-82.787568,34.672734]]]},"properties":{"id":"29670","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.449086,34.617686],[-82.316301,34.573677],[-82.299371,34.578954],[-82.303386,34.62455],[-82.339527,34.669074],[-82.581463,34.687595],[-82.449086,34.617686]]]},"properties":{"id":"29669","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.90223,34.486525],[-82.947844,34.521647],[-82.966699,34.543425],[-83.092873,34.532891],[-82.90223,34.486525]]]},"properties":{"id":"29643","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.073945,33.368441],[-81.103929,33.219224],[-81.121606,33.127761],[-80.956824,33.159331],[-80.887879,33.260539],[-80.887109,33.264183],[-81.073945,33.368441]]]},"properties":{"id":"29003","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.649501,32.238375],[-80.384059,32.437751],[-80.389604,32.440501],[-80.435572,32.46491],[-80.659201,32.340166],[-80.658733,32.337925],[-80.681397,32.286242],[-80.656575,32.247125],[-80.649501,32.238375]]]},"properties":{"id":"29920","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.856654,33.196953],[-79.757428,33.233239],[-79.763345,33.294918],[-79.970873,33.350687],[-80.038973,33.303455],[-80.038318,33.302978],[-80.015234,33.274718],[-79.856654,33.196953]]]},"properties":{"id":"29431","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.970873,33.350687],[-79.763345,33.294918],[-79.741153,33.322308],[-79.867503,33.433651],[-79.960292,33.494019],[-79.970873,33.350687]]]},"properties":{"id":"29479","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.962699,32.891034],[-79.931025,32.829007],[-79.917619,32.818888],[-79.849307,32.882389],[-79.802195,32.929916],[-79.763446,32.956359],[-79.912065,32.97419],[-79.948848,32.90811],[-79.962699,32.891034]]]},"properties":{"id":"29492","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.912065,32.97419],[-79.763446,32.956359],[-79.645208,33.120478],[-79.767605,33.177938],[-79.922546,33.067188],[-79.922857,33.066042],[-79.912065,32.97419]]]},"properties":{"id":"29450","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.757428,33.233239],[-79.856654,33.196953],[-79.922546,33.067188],[-79.767605,33.177938],[-79.757428,33.233239]]]},"properties":{"id":"29434","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.054851,32.926493],[-80.02453,32.886386],[-79.998554,32.865453],[-79.962699,32.891034],[-79.948848,32.90811],[-79.950448,32.910174],[-80.040848,32.975001],[-80.086941,32.992181],[-80.075136,32.968569],[-80.058969,32.93122],[-80.054851,32.926493]],[[-80.073491,32.97838],[-80.072822,32.980401],[-80.068831,32.979417],[-80.069643,32.977108],[-80.073491,32.97838]]]},"properties":{"id":"29406","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.132135,33.010484],[-80.252977,32.968646],[-80.265709,32.909688],[-80.223325,32.873272],[-80.149463,32.909841],[-80.131958,32.900548],[-80.113776,32.957524],[-80.132135,33.010484]]]},"properties":{"id":"29485","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.054851,32.926493],[-80.076721,32.896513],[-80.02453,32.886386],[-80.054851,32.926493]]]},"properties":{"id":"29404","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324974,34.682407],[-80.485734,34.717626],[-80.40461,34.565298],[-80.358873,34.536364],[-80.173018,34.547864],[-80.262026,34.627029],[-80.324974,34.682407]]]},"properties":{"id":"29718","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.013274,34.808403],[-80.184502,34.81194],[-80.175258,34.651153],[-80.011117,34.660272],[-80.013274,34.808403]]]},"properties":{"id":"29709","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.030207,33.696471],[-80.101697,33.496891],[-79.960292,33.494019],[-79.950015,33.518293],[-79.873447,33.662415],[-79.957514,33.72489],[-80.030207,33.696471]]]},"properties":{"id":"29056","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.968373,34.330186],[-80.013145,34.244492],[-79.914085,34.189126],[-79.737553,34.279585],[-79.710058,34.333466],[-79.968373,34.330186]]]},"properties":{"id":"29532","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.67933,33.24034],[-80.69451,33.140755],[-80.65004,33.090195],[-80.649678,33.090052],[-80.654871,33.253289],[-80.67933,33.24034]]]},"properties":{"id":"29471","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.941356,33.992556],[-82.060556,33.950248],[-82.113728,33.82988],[-82.138789,33.801562],[-82.09705,33.727493],[-81.978806,33.673588],[-81.933554,33.693608],[-81.871466,33.765416],[-81.873112,33.901754],[-81.941356,33.992556]]]},"properties":{"id":"29824","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.073343,34.249115],[-81.108407,34.165835],[-80.950158,34.149047],[-80.888782,34.189908],[-80.880079,34.262116],[-81.073343,34.249115]]]},"properties":{"id":"29016","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.366432,34.871524],[-82.359374,34.87837],[-82.36174,34.878142],[-82.366432,34.871524]]]},"properties":{"id":"29614","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26833,32.528561],[-81.167641,32.552106],[-81.141349,32.658074],[-81.204145,32.676855],[-81.21428,32.674857],[-81.243659,32.665899],[-81.260751,32.710911],[-81.401211,32.679882],[-81.26833,32.528561]]]},"properties":{"id":"29922","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.020929,33.98766],[-79.157333,33.925235],[-79.151029,33.916648],[-79.042777,33.728437],[-79.011082,33.753232],[-78.800279,33.806469],[-78.840461,33.90493],[-79.020929,33.98766]]]},"properties":{"id":"29526","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.988196,34.229645],[-79.027524,34.121776],[-78.876152,34.135572],[-78.988196,34.229645]]]},"properties":{"id":"29545","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.923897,32.587757],[-80.927667,32.591546],[-80.938716,32.572705],[-80.929179,32.578629],[-80.923897,32.587757]]]},"properties":{"id":"29912","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.89101,34.303411],[-82.025603,34.35123],[-82.020327,34.251927],[-81.99207,34.24226],[-81.945019,34.203148],[-81.89101,34.303411]]]},"properties":{"id":"29332","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.098521,33.944063],[-81.029214,33.916454],[-81.017654,33.93237],[-81.048109,33.991304],[-81.093345,33.956076],[-81.098521,33.944063]]]},"properties":{"id":"29033","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.249817,34.20074],[-81.435842,34.129365],[-81.394083,34.071545],[-81.331863,34.065149],[-81.314001,34.063032],[-81.268995,34.099652],[-81.249817,34.20074]]]},"properties":{"id":"29036","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.570807,34.617357],[-79.598001,34.551106],[-79.528833,34.482744],[-79.525742,34.488194],[-79.494293,34.543505],[-79.460615,34.602615],[-79.570807,34.617357]]]},"properties":{"id":"29525","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.491834,34.298681],[-81.611198,34.123625],[-81.618979,34.068121],[-81.561895,34.079622],[-81.394083,34.071545],[-81.435842,34.129365],[-81.436333,34.22975],[-81.491834,34.298681]]]},"properties":{"id":"29127","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.232404,34.767132],[-83.306595,34.814076],[-83.353348,34.726621],[-83.232404,34.767132]]]},"properties":{"id":"29658","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.751198,34.765853],[-82.759863,34.769506],[-82.760353,34.760983],[-82.750979,34.757049],[-82.751198,34.765853]]]},"properties":{"id":"29667","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.024882,33.997696],[-81.03067,33.998981],[-81.031106,33.9958],[-81.026293,33.997284],[-81.024882,33.997696]]]},"properties":{"id":"29208","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.937165,34.953007],[-81.97002,34.909844],[-81.963723,34.907284],[-81.904795,34.841358],[-81.901196,34.842657],[-81.926573,34.959375],[-81.937165,34.953007]]]},"properties":{"id":"29306","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.174266,34.957522],[-82.131204,34.8606],[-82.081243,34.872897],[-82.068121,34.891022],[-82.083864,34.912831],[-82.094822,34.921954],[-82.107603,34.929382],[-82.111209,34.937735],[-82.174266,34.957522]]]},"properties":{"id":"29334","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.861816,34.651311],[-81.840137,34.645803],[-81.829989,34.667631],[-81.861816,34.651311]]]},"properties":{"id":"29331","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.993634,34.953901],[-81.984202,34.953681],[-81.984745,34.960304],[-81.998269,34.959459],[-81.993634,34.953901]]]},"properties":{"id":"29320","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.004969,35.135829],[-81.998072,35.133957],[-81.998538,35.137957],[-82.004969,35.135829]]]},"properties":{"id":"29338","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488011,35.030336],[-81.380018,34.970359],[-81.352318,34.963351],[-81.356467,34.983451],[-81.334383,35.046279],[-81.362108,35.070795],[-81.382754,35.078074],[-81.488011,35.030336]]]},"properties":{"id":"29743","state_code":"45"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.578996,44.312702],[-98.584435,44.561205],[-98.645882,44.633888],[-98.745816,44.641968],[-98.904149,44.297972],[-98.943206,44.196495],[-98.925953,44.196575],[-98.66031,44.225741],[-98.578996,44.312702]]]},"properties":{"id":"57381","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.972455,44.426803],[-98.014039,44.195113],[-97.849492,44.195235],[-97.71419,44.194918],[-97.652675,44.544183],[-97.853028,44.544398],[-97.954182,44.499083],[-97.972455,44.426803]]]},"properties":{"id":"57353","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.457817,44.2474],[-98.498015,44.196696],[-98.412433,44.096742],[-98.233992,44.196638],[-98.397178,44.283675],[-98.437735,44.269276],[-98.457817,44.2474]]]},"properties":{"id":"57312","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.107628,43.096613],[-98.140445,42.860884],[-98.153107,42.838986],[-98.130229,42.82234],[-98.010672,42.923255],[-97.950417,43.095998],[-98.092563,43.125443],[-98.107628,43.096613]]]},"properties":{"id":"57315","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.567355,42.952115],[-97.635849,43.024703],[-97.694718,43.010431],[-97.793262,42.937296],[-97.80484,42.858732],[-97.526571,42.85115],[-97.567355,42.952115]]]},"properties":{"id":"57063","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.414709,45.618443],[-98.7223,45.533601],[-98.651334,45.359168],[-98.536102,45.329283],[-98.392123,45.328565],[-98.306943,45.357242],[-98.290665,45.400564],[-98.351074,45.545763],[-98.414709,45.618443]]]},"properties":{"id":"57401","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.214783,45.592593],[-99.113258,45.244996],[-99.082609,45.245105],[-98.82537,45.287998],[-98.651334,45.359168],[-98.7223,45.533601],[-98.841661,45.592263],[-99.214783,45.592593]]]},"properties":{"id":"57451","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.05604,44.581663],[-104.055408,44.258329],[-103.712536,44.415857],[-103.710281,44.415826],[-103.688797,44.517654],[-103.829323,44.546852],[-104.05604,44.581663]],[[-103.870825,44.503038],[-103.868805,44.49543],[-103.876457,44.495808],[-103.876419,44.499393],[-103.870825,44.503038]]]},"properties":{"id":"57783","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.107628,43.096613],[-98.226046,43.023054],[-98.140445,42.860884],[-98.107628,43.096613]]]},"properties":{"id":"57329","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.473726,45.035897],[-97.42244,45.1517],[-97.534941,45.108399],[-97.494048,45.035857],[-97.473726,45.035897]]]},"properties":{"id":"57272","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.741843,45.257704],[-101.48671,45.199305],[-101.215982,45.21059],[-101.141399,45.647022],[-101.203433,45.768319],[-101.362144,45.700803],[-101.676589,45.646454],[-101.741843,45.257704]]]},"properties":{"id":"57633","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.430079,45.594823],[-100.593716,45.559346],[-100.750734,45.384634],[-100.917986,45.28496],[-100.843055,45.255482],[-100.341275,45.471896],[-100.430079,45.594823]]]},"properties":{"id":"57601","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.182199,43.497983],[-97.877893,43.441111],[-97.825955,43.499665],[-97.866914,43.615663],[-98.166376,43.585706],[-98.182199,43.497983]]]},"properties":{"id":"57334","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.182199,43.497983],[-98.233258,43.497986],[-98.192386,43.456022],[-97.877893,43.441111],[-98.182199,43.497983]]]},"properties":{"id":"57331","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.979647,45.573922],[-97.981027,45.31285],[-97.981255,45.269347],[-97.755481,45.442526],[-97.877065,45.5443],[-97.979647,45.573922]]]},"properties":{"id":"57422","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.703306,44.630597],[-96.581826,44.601847],[-96.451574,44.645341],[-96.451493,44.68876],[-96.602188,44.717727],[-96.703306,44.630597]]]},"properties":{"id":"57218","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.400375,44.993969],[-101.499819,44.759379],[-101.380036,44.65549],[-101.136838,44.747126],[-100.988573,44.731859],[-100.717644,44.77093],[-100.404753,44.897695],[-100.511209,45.128664],[-100.843055,45.255482],[-101.015666,45.210816],[-101.215982,45.21059],[-101.48671,45.199305],[-101.486283,45.110982],[-101.400375,44.993969]]]},"properties":{"id":"57625","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.569504,43.498872],[-98.643897,43.361931],[-98.576148,43.34156],[-98.210098,43.398355],[-98.192386,43.456022],[-98.233258,43.497986],[-98.320595,43.498651],[-98.569504,43.498872]],[[-98.611171,43.427752],[-98.608893,43.431695],[-98.602745,43.430611],[-98.601609,43.427801],[-98.611171,43.427752]]]},"properties":{"id":"57328","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.903124,42.998246],[-98.856288,43.155371],[-98.995116,43.270207],[-99.056624,43.255792],[-99.099969,43.091459],[-99.080177,43.078298],[-99.080143,42.998403],[-98.903124,42.998246]]]},"properties":{"id":"57317","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.080177,43.078298],[-99.099969,43.091459],[-99.099916,43.082346],[-99.080177,43.078298]]]},"properties":{"id":"57571","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.067819,44.65899],[-97.329848,44.630227],[-97.310154,44.543453],[-97.269637,44.513818],[-97.269566,44.484859],[-97.22937,44.499479],[-97.189219,44.528594],[-97.06687,44.543103],[-97.063293,44.601772],[-97.067819,44.65899]]]},"properties":{"id":"57248","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.127007,44.803344],[-97.229018,44.804057],[-97.330244,44.70245],[-97.350085,44.673643],[-97.329848,44.630227],[-97.067819,44.65899],[-97.127007,44.803344]]]},"properties":{"id":"57241","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.947436,44.721749],[-98.904149,44.297972],[-98.745816,44.641968],[-98.866628,44.721959],[-98.947436,44.721749]]]},"properties":{"id":"57373","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.750659,43.849714],[-97.846691,43.673503],[-97.866914,43.615663],[-97.825955,43.499665],[-97.718346,43.499874],[-97.606601,43.665501],[-97.68873,43.813824],[-97.750659,43.849714]]]},"properties":{"id":"57311","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.87903,44.548377],[-100.149519,44.548324],[-99.906359,44.396551],[-99.87903,44.548377]]]},"properties":{"id":"57522","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.508469,43.664108],[-97.619531,43.443703],[-97.420921,43.429599],[-97.348055,43.528817],[-97.408392,43.61559],[-97.488766,43.664441],[-97.508469,43.664108]]]},"properties":{"id":"57319","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.22937,44.499479],[-97.195365,44.492233],[-97.189219,44.528594],[-97.22937,44.499479]]]},"properties":{"id":"57214","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.370115,44.194971],[-97.189923,44.195882],[-97.270923,44.282502],[-97.451325,44.281883],[-97.511624,44.194936],[-97.450651,44.194899],[-97.370115,44.194971]]]},"properties":{"id":"57051","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.008972,43.920621],[-96.888863,43.935295],[-96.888762,44.080546],[-97.029083,44.06559],[-97.008972,43.920621]]]},"properties":{"id":"57075","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.596992,43.877015],[-96.556839,43.905834],[-96.566869,43.906057],[-96.596992,43.877015]]],[[[-96.748458,43.979057],[-96.888589,43.874247],[-96.928825,43.848351],[-96.851357,43.775505],[-96.691185,43.761246],[-96.552017,43.847755],[-96.596992,43.877015],[-96.701602,43.949732],[-96.748458,43.979057]]]]},"properties":{"id":"57022","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.408392,43.61559],[-97.348055,43.528817],[-97.24808,43.528773],[-97.179307,43.543479],[-97.129463,43.557743],[-97.168007,43.63032],[-97.248195,43.666025],[-97.408392,43.61559]]]},"properties":{"id":"57012","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.501884,45.804794],[-97.518931,45.678091],[-97.351011,45.675126],[-97.289035,45.732334],[-97.501884,45.804794]]]},"properties":{"id":"57247","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.479715,45.935134],[-97.501884,45.804794],[-97.289035,45.732334],[-97.227801,45.804228],[-97.144965,45.935607],[-97.479715,45.935134]]]},"properties":{"id":"57270","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.270488,44.21171],[-103.185954,44.035051],[-103.13111,44.107625],[-103.110644,44.14081],[-103.122132,44.168847],[-103.270488,44.21171]]]},"properties":{"id":"57701","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.910254,44.074851],[-102.616942,43.9003],[-102.497655,43.963066],[-102.497865,43.994576],[-102.668243,44.060561],[-102.548103,44.284323],[-102.565268,44.370652],[-102.562704,44.517459],[-102.88605,44.51792],[-102.837717,44.350858],[-102.910254,44.074851]]]},"properties":{"id":"57761","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.110644,44.14081],[-103.070735,44.165436],[-103.122132,44.168847],[-103.110644,44.14081]]]},"properties":{"id":"57706","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.548103,44.284323],[-102.668243,44.060561],[-102.497865,43.994576],[-102.548103,44.284323]]]},"properties":{"id":"57767","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.691185,43.761246],[-96.851357,43.775505],[-96.851181,43.746628],[-96.867946,43.717636],[-96.78154,43.703213],[-96.691277,43.688747],[-96.691185,43.761246]]]},"properties":{"id":"57003","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.453144,44.08199],[-96.646177,44.195571],[-96.707967,44.195443],[-96.768999,44.152424],[-96.748702,44.022479],[-96.597,43.93523],[-96.566869,43.906057],[-96.556839,43.905834],[-96.53685,43.891084],[-96.4848,43.905583],[-96.45341,43.967166],[-96.453144,44.08199]]]},"properties":{"id":"57028","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.074716,44.371737],[-100.988573,44.731859],[-101.136838,44.747126],[-101.074716,44.371737]]]},"properties":{"id":"57537","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.807411,43.171163],[-101.069182,43.232039],[-101.2279,43.213512],[-101.227985,43.100106],[-100.866117,43.089285],[-100.807411,43.171163]]]},"properties":{"id":"57572","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.428973,43.111391],[-97.500346,43.103696],[-97.536607,42.966628],[-97.370002,42.995933],[-97.398833,43.063482],[-97.428973,43.111391]]]},"properties":{"id":"57067","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.977171,44.718742],[-98.119401,44.689963],[-98.17498,44.58884],[-98.099041,44.574094],[-97.954182,44.499083],[-97.853028,44.544398],[-97.83621,44.718089],[-97.856207,44.718243],[-97.977171,44.718742]]]},"properties":{"id":"57322","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.310976,43.227991],[-101.478662,42.997154],[-101.228109,42.998133],[-101.227985,43.100106],[-101.2279,43.213512],[-101.227896,43.231479],[-101.310976,43.227991]]]},"properties":{"id":"57574","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-101.228179,43.389185],[-101.395926,43.389205],[-101.310976,43.227991],[-101.227896,43.231479],[-101.228179,43.389185]]],[[[-101.228179,43.389185],[-101.038762,43.454245],[-101.042061,43.584844],[-101.228112,43.63713],[-101.2281,43.606495],[-101.228179,43.389185]]]]},"properties":{"id":"57560","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.027447,44.543476],[-97.016979,44.383724],[-96.910787,44.369757],[-96.75707,44.514074],[-96.757001,44.528525],[-96.804475,44.543756],[-97.027447,44.543476]]]},"properties":{"id":"57220","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.724845,45.765217],[-98.841661,45.592263],[-98.7223,45.533601],[-98.414709,45.618443],[-98.455609,45.705638],[-98.476472,45.705773],[-98.51763,45.735058],[-98.724845,45.765217]]]},"properties":{"id":"57481","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.285168,44.073927],[-99.378559,44.01309],[-99.36318,43.914776],[-99.44428,43.691673],[-99.464133,43.663849],[-99.297882,43.499856],[-99.151768,43.585837],[-99.284928,44.019569],[-99.285168,44.073927]]]},"properties":{"id":"57325","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.30018,44.19483],[-99.440421,44.193887],[-99.576571,44.192421],[-99.441508,44.047062],[-99.378559,44.01309],[-99.285168,44.073927],[-99.288596,44.194811],[-99.30018,44.19483]]]},"properties":{"id":"57339","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.106615,44.005924],[-98.926594,44.021413],[-98.925953,44.196575],[-98.943206,44.196495],[-99.185839,44.196477],[-99.288596,44.194811],[-99.285168,44.073927],[-99.284928,44.019569],[-99.106615,44.005924]]]},"properties":{"id":"57341","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.040594,45.433265],[-104.05604,44.581663],[-103.829323,44.546852],[-103.68789,44.604502],[-103.587867,44.640515],[-103.649493,44.86566],[-103.548657,45.212963],[-103.686718,45.213216],[-104.017624,45.432488],[-104.040594,45.433265]]]},"properties":{"id":"57717","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.499371,45.943653],[-100.342365,45.758165],[-100.32312,45.730915],[-100.278653,45.753607],[-100.217856,45.768037],[-100.152265,45.942394],[-100.499371,45.943653]]]},"properties":{"id":"57648","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.545273,43.278729],[-98.678024,43.074008],[-98.561172,43.059708],[-98.498445,43.077768],[-98.392044,43.212709],[-98.545273,43.278729]]]},"properties":{"id":"57356","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.977171,44.718742],[-97.856207,44.718243],[-97.861365,45.036577],[-97.982379,45.022807],[-97.977171,44.718742]]]},"properties":{"id":"57258","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.249586,42.855517],[-97.137083,42.779171],[-97.122278,42.880993],[-97.22001,42.945969],[-97.249586,42.855517]]]},"properties":{"id":"57031","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.934521,44.804383],[-96.925508,44.789882],[-96.743088,44.890007],[-96.882345,44.97687],[-96.923426,44.904606],[-96.913184,44.904568],[-96.914963,44.890045],[-96.934521,44.804383]]]},"properties":{"id":"57238","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.980244,45.646041],[-100.936309,45.472711],[-100.853678,45.4166],[-100.750734,45.384634],[-100.593716,45.559346],[-100.604101,45.559419],[-100.980244,45.646041]]]},"properties":{"id":"57657","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.63158,43.577365],[-103.51253,43.591866],[-103.610345,43.608721],[-103.63158,43.577365]]]},"properties":{"id":"57773","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.620722,45.558248],[-97.877065,45.5443],[-97.755481,45.442526],[-97.694326,45.442367],[-97.631684,45.500338],[-97.620722,45.558248]]]},"properties":{"id":"57468","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.755481,45.442526],[-97.981255,45.269347],[-97.862057,45.1811],[-97.694326,45.442367],[-97.755481,45.442526]]]},"properties":{"id":"57219","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.37545,45.45964],[-97.391976,45.180782],[-97.226281,45.151826],[-97.226066,45.209924],[-97.195932,45.33564],[-97.103354,45.399099],[-97.11977,45.493072],[-97.226742,45.47857],[-97.37545,45.45964]]]},"properties":{"id":"57273","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.364642,45.558733],[-97.597806,45.587703],[-97.620722,45.558248],[-97.631684,45.500338],[-97.406879,45.486174],[-97.364642,45.558733]]]},"properties":{"id":"57261","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.063293,44.601772],[-96.833633,44.678824],[-96.925508,44.789882],[-96.934521,44.804383],[-97.127007,44.803344],[-97.067819,44.65899],[-97.063293,44.601772]]]},"properties":{"id":"57223","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.690799,43.372619],[-97.698788,43.414788],[-97.718346,43.499874],[-97.825955,43.499665],[-97.877893,43.441111],[-98.192386,43.456022],[-98.210098,43.398355],[-98.189899,43.383748],[-98.070714,43.268092],[-97.837137,43.320883],[-97.690799,43.372619]]]},"properties":{"id":"57366","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.287816,43.289032],[-103.001034,43.216466],[-103.000845,43.32568],[-103.000871,43.390582],[-103.287778,43.303603],[-103.287816,43.289032]]]},"properties":{"id":"57782","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.977874,44.765168],[-98.987881,44.897058],[-99.311754,44.897227],[-99.311699,44.751009],[-98.977874,44.765168]]]},"properties":{"id":"57467","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.883948,45.150224],[-96.721791,45.135492],[-96.73981,45.326264],[-96.842292,45.31172],[-96.965459,45.223075],[-96.883948,45.150224]]]},"properties":{"id":"57269","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.000964,44.343832],[-102.019623,43.894631],[-102.019729,43.748043],[-101.741512,43.702375],[-101.44409,43.995139],[-101.407267,43.995136],[-101.500087,44.343461],[-101.930029,44.530901],[-102.001068,44.510926],[-102.000964,44.343832]]]},"properties":{"id":"57567","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.000964,44.343832],[-102.167766,44.196961],[-102.019623,43.894631],[-102.000964,44.343832]]]},"properties":{"id":"57775","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.866628,44.721959],[-98.745816,44.641968],[-98.645882,44.633888],[-98.402473,44.74866],[-98.825715,44.76553],[-98.866628,44.721959]]]},"properties":{"id":"57476","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.316003,45.945152],[-103.066512,45.866982],[-103.066431,45.945139],[-103.316003,45.945152]]]},"properties":{"id":"58653","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.420921,43.429599],[-97.313451,43.256094],[-97.259688,43.299647],[-97.221569,43.481078],[-97.24808,43.528773],[-97.348055,43.528817],[-97.420921,43.429599]]]},"properties":{"id":"57043","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.367207,44.168694],[-100.33897,43.716942],[-100.230845,43.713856],[-100.220024,43.691746],[-100.226768,44.093516],[-100.367207,44.168694]]]},"properties":{"id":"57576","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.471103,44.543922],[-97.633227,44.544183],[-97.652675,44.544183],[-97.71419,44.194918],[-97.571431,44.195124],[-97.511624,44.194936],[-97.451325,44.281883],[-97.451072,44.441114],[-97.471103,44.543922]]]},"properties":{"id":"57231","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.054588,44.141081],[-103.717005,44.140904],[-103.714615,44.396214],[-103.712536,44.415857],[-104.055408,44.258329],[-104.054588,44.141081]]]},"properties":{"id":"57754","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.574302,44.281052],[-103.452436,44.198341],[-103.452877,44.232014],[-103.574302,44.281052]]]},"properties":{"id":"57759","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.910278,43.500046],[-96.796782,43.47632],[-96.74849,43.501667],[-96.767579,43.536657],[-96.770969,43.543934],[-96.890358,43.543667],[-96.910278,43.500046]]]},"properties":{"id":"57106","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.97346,45.939574],[-98.973552,45.838722],[-98.84561,45.939145],[-98.97346,45.939574]]]},"properties":{"id":"58439","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.270488,44.21171],[-103.122132,44.168847],[-103.070735,44.165436],[-103.070562,44.233998],[-103.574305,44.298453],[-103.574302,44.281052],[-103.452877,44.232014],[-103.297914,44.18933],[-103.270488,44.21171]]]},"properties":{"id":"57769","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.281759,44.658551],[-102.562704,44.517459],[-102.565268,44.370652],[-102.361119,44.435145],[-102.281759,44.658551]]]},"properties":{"id":"57792","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.93278,43.389473],[-101.038762,43.454245],[-101.228179,43.389185],[-101.227896,43.231479],[-101.2279,43.213512],[-101.069182,43.232039],[-100.932285,43.277263],[-100.93278,43.389473]]]},"properties":{"id":"57566","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.656591,43.598875],[-96.671341,43.500313],[-96.599191,43.500456],[-96.598926,43.500457],[-96.651765,43.600087],[-96.656591,43.598875]]]},"properties":{"id":"57110","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.74849,43.501667],[-96.707659,43.50037],[-96.701686,43.536614],[-96.767579,43.536657],[-96.74849,43.501667]],[[-96.741223,43.52309],[-96.739995,43.527263],[-96.736254,43.527256],[-96.736233,43.522082],[-96.741223,43.52309]],[[-96.752773,43.533061],[-96.751248,43.529435],[-96.758715,43.529425],[-96.758697,43.533035],[-96.752773,43.533061]]]},"properties":{"id":"57105","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.851181,43.746628],[-96.89061,43.73217],[-96.883851,43.71767],[-96.867946,43.717636],[-96.851181,43.746628]]]},"properties":{"id":"57041","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.896426,45.55793],[-97.11977,45.493072],[-97.103354,45.399099],[-97.04589,45.413012],[-96.902781,45.514365],[-96.896426,45.55793]]]},"properties":{"id":"57257","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.843708,45.93582],[-96.813215,45.761464],[-96.794717,45.681364],[-96.81341,45.661125],[-96.583085,45.820024],[-96.843708,45.93582]]]},"properties":{"id":"57260","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.794717,45.681364],[-96.896426,45.55793],[-96.902781,45.514365],[-96.751717,45.499677],[-96.81341,45.661125],[-96.794717,45.681364]]]},"properties":{"id":"56219","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.475666,43.12971],[-102.377145,43.06101],[-102.26799,43.154433],[-102.365614,43.195198],[-102.475666,43.12971]]]},"properties":{"id":"57794","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.301707,44.748373],[-98.139683,44.690138],[-98.227621,44.995916],[-98.329869,44.996329],[-98.349965,44.952881],[-98.301707,44.748373]]]},"properties":{"id":"57440","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.652408,43.050653],[-99.6923,43.357042],[-99.870132,43.212619],[-99.652408,43.050653]]]},"properties":{"id":"57528","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.796675,43.711658],[-99.822378,43.514307],[-99.593334,43.414753],[-99.533893,43.458065],[-99.62344,43.629946],[-99.743346,43.748416],[-99.796675,43.711658]]]},"properties":{"id":"57534","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.357967,43.169544],[-97.081507,43.126756],[-97.00461,43.199685],[-97.032082,43.228043],[-97.299022,43.227426],[-97.399346,43.198343],[-97.357967,43.169544]]]},"properties":{"id":"57070","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.635849,43.024703],[-97.567355,42.952115],[-97.536607,42.966628],[-97.500346,43.103696],[-97.614791,43.16874],[-97.635849,43.024703]]]},"properties":{"id":"57040","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.451588,43.907833],[-98.41223,44.067472],[-98.4324,44.072053],[-98.432536,44.082256],[-98.412435,44.082314],[-98.412433,44.096742],[-98.498015,44.196696],[-98.66031,44.225741],[-98.925953,44.196575],[-98.926594,44.021413],[-98.807771,43.935223],[-98.622593,43.831316],[-98.451588,43.907833]]]},"properties":{"id":"57382","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.033256,44.412803],[-98.099041,44.574094],[-98.17498,44.58884],[-98.356842,44.494042],[-98.397137,44.312571],[-98.397178,44.283675],[-98.233992,44.196638],[-98.137248,44.195813],[-98.033256,44.412803]]]},"properties":{"id":"57350","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.033256,44.412803],[-98.137248,44.195813],[-98.014039,44.195113],[-97.972455,44.426803],[-98.033256,44.412803]]]},"properties":{"id":"57324","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.082486,42.999355],[-101.478662,42.997154],[-101.310976,43.227991],[-101.395926,43.389205],[-101.720487,43.389056],[-101.77081,43.348371],[-102.109511,43.260039],[-102.109545,43.216416],[-102.082486,42.999355]]]},"properties":{"id":"57551","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.724845,45.765217],[-98.51763,45.735058],[-98.476223,45.763657],[-98.476472,45.705773],[-98.455609,45.705638],[-98.264712,45.762885],[-98.257701,45.806585],[-98.332036,45.936208],[-98.724375,45.938731],[-98.724845,45.765217]]]},"properties":{"id":"57441","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.145183,45.327585],[-98.10406,45.341816],[-98.021956,45.341774],[-98.063098,45.312884],[-97.981027,45.31285],[-97.979647,45.573922],[-97.979592,45.588483],[-98.16604,45.617813],[-98.238317,45.537989],[-98.290665,45.400564],[-98.306943,45.357242],[-98.145183,45.327585]]]},"properties":{"id":"57445","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.476472,45.705773],[-98.476223,45.763657],[-98.51763,45.735058],[-98.476472,45.705773]]]},"properties":{"id":"57426","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.926594,44.021413],[-99.106615,44.005924],[-99.112084,43.600208],[-98.814457,43.600427],[-98.807771,43.935223],[-98.926594,44.021413]]]},"properties":{"id":"57355","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.112084,43.600208],[-99.151768,43.585837],[-99.297882,43.499856],[-99.297019,43.455641],[-98.995116,43.270207],[-98.856288,43.155371],[-98.824069,43.152286],[-98.680666,43.371062],[-98.705782,43.499319],[-98.814457,43.600427],[-99.112084,43.600208]]]},"properties":{"id":"57369","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.304814,45.940102],[-99.964939,45.941802],[-99.903066,45.723903],[-99.882466,45.694932],[-99.707705,45.593391],[-99.584875,45.593107],[-99.24221,45.708607],[-99.221743,45.80226],[-99.304814,45.940102]]]},"properties":{"id":"57437","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.152265,45.942394],[-100.217856,45.768037],[-99.903066,45.723903],[-99.964939,45.941802],[-100.152265,45.942394]]]},"properties":{"id":"57632","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.068906,45.59396],[-99.955782,45.318899],[-99.831965,45.340425],[-99.707705,45.593391],[-99.882466,45.694932],[-100.068906,45.59396]]]},"properties":{"id":"57452","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.680666,43.371062],[-98.824069,43.152286],[-98.678024,43.074008],[-98.545273,43.278729],[-98.576148,43.34156],[-98.643897,43.361931],[-98.680666,43.371062]]]},"properties":{"id":"57342","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.44483,42.993423],[-98.407471,42.99492],[-98.407389,43.00945],[-98.444965,43.007557],[-98.44483,42.993423]]]},"properties":{"id":"57361","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.042443,42.924296],[-96.944013,42.953496],[-97.080826,43.113183],[-97.160513,43.028744],[-97.042443,42.924296]]]},"properties":{"id":"57073","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.473726,45.035897],[-97.25345,44.967789],[-97.139511,45.093279],[-97.205487,45.151797],[-97.226281,45.151826],[-97.391976,45.180782],[-97.42244,45.1517],[-97.473726,45.035897]]]},"properties":{"id":"57235","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.329744,44.862137],[-97.491346,44.804035],[-97.330244,44.70245],[-97.229018,44.804057],[-97.329744,44.862137]]]},"properties":{"id":"57242","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.923426,44.904606],[-96.914963,44.890045],[-96.913184,44.904568],[-96.923426,44.904606]]]},"properties":{"id":"57245","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.338736,43.708939],[-103.001067,43.605677],[-102.869022,43.528975],[-102.810419,43.688132],[-102.708947,43.855551],[-102.891454,43.856314],[-103.18614,43.95756],[-103.29916,43.895146],[-103.370321,43.760246],[-103.338736,43.708939]]]},"properties":{"id":"57744","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.37545,45.45964],[-97.226742,45.47857],[-97.227089,45.558158],[-97.364642,45.558733],[-97.406879,45.486174],[-97.37545,45.45964]]]},"properties":{"id":"57239","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.122317,44.852785],[-99.878711,44.897515],[-99.756444,44.897425],[-99.816619,45.14433],[-100.058384,45.246467],[-100.229518,45.246795],[-100.260223,45.24681],[-100.511209,45.128664],[-100.404753,44.897695],[-100.380464,44.897684],[-100.122317,44.852785]]]},"properties":{"id":"57442","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.544436,45.332501],[-99.327573,45.245075],[-99.113258,45.244996],[-99.214783,45.592593],[-99.221169,45.592593],[-99.523079,45.506419],[-99.647124,45.347163],[-99.544436,45.332501]]]},"properties":{"id":"57471","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.721791,45.135492],[-96.883948,45.150224],[-96.882802,45.063772],[-96.741197,45.062875],[-96.720851,45.070929],[-96.721791,45.135492]]]},"properties":{"id":"57264","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.6923,43.357042],[-99.652408,43.050653],[-99.652422,42.997902],[-99.534049,42.998033],[-99.533893,43.458065],[-99.593334,43.414753],[-99.6923,43.357042]]]},"properties":{"id":"57529","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.856288,43.155371],[-98.903124,42.998246],[-98.645473,42.99849],[-98.559525,43.059213],[-98.561172,43.059708],[-98.678024,43.074008],[-98.824069,43.152286],[-98.856288,43.155371]]]},"properties":{"id":"57335","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.313451,43.256094],[-97.420921,43.429599],[-97.619531,43.443703],[-97.698788,43.414788],[-97.690799,43.372619],[-97.621173,43.33282],[-97.399346,43.198343],[-97.299022,43.227426],[-97.313451,43.256094]]]},"properties":{"id":"57029","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.756516,43.212937],[-97.674369,43.208341],[-97.621173,43.33282],[-97.690799,43.372619],[-97.837137,43.320883],[-97.756516,43.212937]]]},"properties":{"id":"57052","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.614791,43.16874],[-97.500346,43.103696],[-97.428973,43.111391],[-97.357967,43.169544],[-97.399346,43.198343],[-97.621173,43.33282],[-97.674369,43.208341],[-97.614791,43.16874]]]},"properties":{"id":"57045","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.33897,43.716942],[-100.367207,44.168694],[-100.655999,44.168359],[-100.547792,43.737878],[-100.471329,43.721533],[-100.33897,43.716942]]]},"properties":{"id":"57531","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.908724,44.138497],[-97.049363,44.109064],[-97.029083,44.06559],[-96.888762,44.080546],[-96.888648,44.138364],[-96.908724,44.138497]]]},"properties":{"id":"57057","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.707527,43.35908],[-96.71719,43.228719],[-96.687339,43.170703],[-96.52965,43.170576],[-96.491501,43.223374],[-96.589544,43.432202],[-96.707527,43.35908]]]},"properties":{"id":"57013","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.491501,43.223374],[-96.52965,43.170576],[-96.466689,43.17073],[-96.491501,43.223374]]]},"properties":{"id":"57027","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.529221,43.054575],[-96.497359,42.998276],[-96.510851,43.04977],[-96.529221,43.054575]]],[[[-96.726875,42.8807],[-96.776457,42.880803],[-96.76641,42.866211],[-96.707313,42.851697],[-96.726875,42.8807]]],[[[-96.726875,42.8807],[-96.54285,42.908928],[-96.502512,42.966787],[-96.498436,42.998081],[-96.529221,43.054575],[-96.6678,43.112792],[-96.726875,42.8807]]]]},"properties":{"id":"57001","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.743346,43.748416],[-99.62344,43.629946],[-99.464133,43.663849],[-99.44428,43.691673],[-99.504278,43.796315],[-99.36318,43.914776],[-99.378559,44.01309],[-99.441508,44.047062],[-99.765665,44.01345],[-99.743346,43.748416]]]},"properties":{"id":"57569","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.88605,44.51792],[-102.562704,44.517459],[-102.281759,44.658551],[-102.196567,44.718854],[-102.26853,44.820163],[-102.958684,44.819017],[-102.958752,44.748509],[-102.964125,44.604287],[-102.88605,44.51792]]]},"properties":{"id":"57787","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.483252,44.411697],[-103.481299,44.396484],[-103.453853,44.394416],[-103.453064,44.415608],[-103.483252,44.411697]]]},"properties":{"id":"57741","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.471786,43.390171],[-100.293676,43.390187],[-100.230845,43.713856],[-100.33897,43.716942],[-100.471329,43.721533],[-100.5931,43.390124],[-100.471786,43.390171]]]},"properties":{"id":"57585","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.850703,44.096328],[-97.711216,44.081612],[-97.571431,44.195124],[-97.71419,44.194918],[-97.849492,44.195235],[-97.850703,44.096328]]]},"properties":{"id":"57323","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.778091,43.659779],[-96.871002,43.645184],[-96.890358,43.543667],[-96.770969,43.543934],[-96.736764,43.616438],[-96.778091,43.659779]]]},"properties":{"id":"57107","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.871002,43.645184],[-96.778091,43.659779],[-96.78154,43.703213],[-96.867946,43.717636],[-96.883851,43.71767],[-96.871002,43.645184]]]},"properties":{"id":"57020","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.96977,44.082044],[-102.891454,43.856314],[-102.708947,43.855551],[-102.616942,43.9003],[-102.910254,44.074851],[-102.96977,44.082044]]]},"properties":{"id":"57725","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.058384,45.246467],[-99.816619,45.14433],[-99.695334,45.071309],[-99.708393,45.347277],[-99.831965,45.340425],[-99.955782,45.318899],[-100.05838,45.260949],[-100.058384,45.246467]]]},"properties":{"id":"57450","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.04589,45.413012],[-96.992946,45.32688],[-96.842364,45.326089],[-96.686254,45.413037],[-96.751717,45.499677],[-96.902781,45.514365],[-97.04589,45.413012]]]},"properties":{"id":"57279","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.002533,45.022903],[-98.320454,45.069025],[-98.329701,45.054396],[-98.329869,44.996329],[-98.227621,44.995916],[-98.002533,45.022903]]]},"properties":{"id":"57477","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.648063,42.560824],[-96.548789,42.520547],[-96.498997,42.560832],[-96.538026,42.649032],[-96.648063,42.560824]]]},"properties":{"id":"57038","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.726875,42.8807],[-96.707313,42.851697],[-96.595617,42.81539],[-96.538458,42.908771],[-96.54285,42.908928],[-96.726875,42.8807]]]},"properties":{"id":"51001","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.229518,45.246795],[-100.058384,45.246467],[-100.05838,45.260949],[-100.230043,45.313402],[-100.229518,45.246795]]]},"properties":{"id":"57420","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.814457,43.600427],[-98.705782,43.499319],[-98.67633,43.499102],[-98.594184,43.627776],[-98.622593,43.831316],[-98.807771,43.935223],[-98.814457,43.600427]]]},"properties":{"id":"57383","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.228561,43.238716],[-102.109545,43.216416],[-102.109511,43.260039],[-101.911007,43.389464],[-102.139158,43.700948],[-102.379842,43.687518],[-102.228561,43.238716]]]},"properties":{"id":"57752","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.77652,43.666004],[-101.781932,43.389175],[-101.77081,43.348371],[-101.720487,43.389056],[-101.384137,43.606483],[-101.77652,43.666004]]]},"properties":{"id":"57577","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.377145,43.06101],[-102.300217,42.999428],[-102.082486,42.999355],[-102.109545,43.216416],[-102.228561,43.238716],[-102.26799,43.154433],[-102.377145,43.06101]]]},"properties":{"id":"57716","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.130229,42.82234],[-97.80484,42.858732],[-97.793262,42.937296],[-98.010672,42.923255],[-98.130229,42.82234]]]},"properties":{"id":"57062","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.049265,44.195781],[-96.908724,44.138497],[-96.888648,44.138364],[-96.88903,44.167229],[-96.910787,44.369757],[-97.016979,44.383724],[-97.048993,44.239157],[-97.049013,44.246337],[-97.039024,44.245557],[-97.038963,44.239213],[-97.049265,44.195781]]]},"properties":{"id":"57071","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.038963,44.239213],[-97.039024,44.245557],[-97.049013,44.246337],[-97.048993,44.239157],[-97.038963,44.239213]]]},"properties":{"id":"57061","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.238317,45.537989],[-98.351074,45.545763],[-98.290665,45.400564],[-98.238317,45.537989]]]},"properties":{"id":"57427","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861365,45.036577],[-97.856207,44.718243],[-97.83621,44.718089],[-97.735083,44.718068],[-97.511672,44.804039],[-97.49454,44.949201],[-97.67745,45.007407],[-97.861444,45.065639],[-97.861365,45.036577]]]},"properties":{"id":"57225","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.865538,43.214351],[-96.904549,42.95347],[-96.845123,42.938961],[-96.776457,42.880803],[-96.726875,42.8807],[-96.6678,43.112792],[-96.687339,43.170703],[-96.71719,43.228719],[-96.821218,43.25783],[-96.865538,43.214351]]]},"properties":{"id":"57004","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.139511,45.093279],[-96.882796,45.049234],[-96.882802,45.063772],[-96.883948,45.150224],[-96.965459,45.223075],[-97.035069,45.240329],[-97.205487,45.151797],[-97.139511,45.093279]]]},"properties":{"id":"57263","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.72117,45.944337],[-101.718206,45.731961],[-101.639244,45.94426],[-101.72117,45.944337]]]},"properties":{"id":"57645","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.602188,44.717727],[-96.537015,44.904621],[-96.720424,44.93356],[-96.743088,44.890007],[-96.925508,44.789882],[-96.833633,44.678824],[-96.743836,44.630617],[-96.703306,44.630597],[-96.602188,44.717727]]]},"properties":{"id":"57226","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.486283,45.110982],[-101.501053,44.993795],[-101.400375,44.993969],[-101.486283,45.110982]]]},"properties":{"id":"57636","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.611171,43.427752],[-98.601609,43.427801],[-98.602745,43.430611],[-98.608893,43.431695],[-98.611171,43.427752]]]},"properties":{"id":"57364","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.82537,45.287998],[-99.082609,45.245105],[-98.908566,45.013007],[-98.798496,45.041274],[-98.79867,45.056289],[-98.836664,45.201208],[-98.82537,45.287998]]]},"properties":{"id":"57435","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.825715,44.76553],[-98.73711,44.98384],[-98.798496,45.041274],[-98.908566,45.013007],[-98.987881,44.897058],[-98.977874,44.765168],[-98.947436,44.721749],[-98.866628,44.721959],[-98.825715,44.76553]]]},"properties":{"id":"57470","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.103354,45.399099],[-97.195932,45.33564],[-97.164467,45.195571],[-97.226066,45.209924],[-97.226281,45.151826],[-97.205487,45.151797],[-97.035069,45.240329],[-96.992946,45.32688],[-97.04589,45.413012],[-97.103354,45.399099]]]},"properties":{"id":"57266","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.620365,45.107052],[-96.720851,45.070929],[-96.741197,45.062875],[-96.71948,44.976631],[-96.620365,45.107052]]]},"properties":{"id":"57246","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.606601,43.665501],[-97.718346,43.499874],[-97.698788,43.414788],[-97.619531,43.443703],[-97.508469,43.664108],[-97.606601,43.665501]]]},"properties":{"id":"57332","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.959869,45.945035],[-102.9906,45.734562],[-102.942448,45.732864],[-102.94207,45.945],[-102.959869,45.945035]]]},"properties":{"id":"58649","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.942448,45.732864],[-102.9906,45.734562],[-103.066429,45.776531],[-103.236859,45.732937],[-103.232712,45.666815],[-102.956636,45.328125],[-102.95729,45.28044],[-102.712669,45.256481],[-102.703791,45.559914],[-102.942448,45.732864]]]},"properties":{"id":"57649","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.228112,43.63713],[-101.44409,43.995139],[-101.741512,43.702375],[-101.77652,43.666004],[-101.384137,43.606483],[-101.2281,43.606495],[-101.228112,43.63713]]]},"properties":{"id":"57543","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.384137,43.606483],[-101.720487,43.389056],[-101.395926,43.389205],[-101.228179,43.389185],[-101.2281,43.606495],[-101.384137,43.606483]]]},"properties":{"id":"57547","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.451072,44.441114],[-97.269637,44.513818],[-97.310154,44.543453],[-97.471103,44.543922],[-97.451072,44.441114]]]},"properties":{"id":"57233","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.370115,44.194971],[-97.329272,44.00804],[-97.089762,44.123619],[-97.090032,44.195796],[-97.189923,44.195882],[-97.370115,44.194971]]]},"properties":{"id":"57054","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.450651,44.194899],[-97.430022,43.935491],[-97.369463,43.848526],[-97.30928,43.848481],[-97.248918,43.848319],[-97.329272,44.00804],[-97.370115,44.194971],[-97.450651,44.194899]]]},"properties":{"id":"57076","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.589462,44.14067],[-103.452543,44.183423],[-103.452436,44.198341],[-103.574302,44.281052],[-103.574305,44.298453],[-103.714615,44.396214],[-103.717005,44.140904],[-103.589462,44.14067]]]},"properties":{"id":"57732","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.707659,43.50037],[-96.74849,43.501667],[-96.796782,43.47632],[-96.796348,43.431921],[-96.594735,43.461692],[-96.599191,43.500456],[-96.671341,43.500313],[-96.707659,43.50037]]]},"properties":{"id":"57108","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.924788,43.432174],[-96.796348,43.431921],[-96.796782,43.47632],[-96.910278,43.500046],[-96.944611,43.499929],[-96.924788,43.432174]]]},"properties":{"id":"57064","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.821218,43.25783],[-96.71719,43.228719],[-96.707527,43.35908],[-96.767027,43.388196],[-96.821218,43.25783]]]},"properties":{"id":"57077","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.009994,43.693763],[-99.901954,43.700494],[-100.065911,44.194868],[-100.226768,44.093516],[-100.220024,43.691746],[-100.009994,43.693763]]]},"properties":{"id":"57568","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.941105,44.195127],[-99.765665,44.01345],[-99.441508,44.047062],[-99.576571,44.192421],[-99.663508,44.217517],[-99.938217,44.195195],[-99.941105,44.195127]]]},"properties":{"id":"57548","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.074716,44.371737],[-100.732129,44.168126],[-100.655999,44.168359],[-100.367207,44.168694],[-100.226768,44.093516],[-100.065911,44.194868],[-99.941105,44.195127],[-99.938217,44.195195],[-99.894217,44.237127],[-100.717644,44.77093],[-100.988573,44.731859],[-101.074716,44.371737]]]},"properties":{"id":"57532","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.221569,43.481078],[-97.259688,43.299647],[-97.02383,43.344292],[-97.024101,43.499789],[-97.049706,43.499798],[-97.129463,43.557743],[-97.179307,43.543479],[-97.221569,43.481078]]]},"properties":{"id":"57053","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.351011,45.675126],[-97.227089,45.558158],[-97.226742,45.47857],[-97.11977,45.493072],[-96.896426,45.55793],[-96.794717,45.681364],[-96.813215,45.761464],[-97.042088,45.848792],[-97.227801,45.804228],[-97.289035,45.732334],[-97.351011,45.675126]]]},"properties":{"id":"57262","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.452877,44.232014],[-103.452436,44.198341],[-103.452543,44.183423],[-103.297914,44.18933],[-103.452877,44.232014]]]},"properties":{"id":"57718","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.303276,44.433699],[-102.001068,44.510926],[-101.930029,44.530901],[-101.632079,44.586834],[-101.638861,44.646678],[-101.717148,44.691885],[-102.196567,44.718854],[-102.281759,44.658551],[-102.361119,44.435145],[-102.303276,44.433699]]]},"properties":{"id":"57748","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.548103,44.284323],[-102.497865,43.994576],[-102.497655,43.963066],[-102.303276,44.433699],[-102.361119,44.435145],[-102.565268,44.370652],[-102.548103,44.284323]]]},"properties":{"id":"57791","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.26853,44.820163],[-102.712732,45.176337],[-102.712669,45.256481],[-102.95729,45.28044],[-102.958684,44.819017],[-102.26853,44.820163]]]},"properties":{"id":"57758","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.712732,45.176337],[-102.26853,44.820163],[-102.196567,44.718854],[-101.717148,44.691885],[-101.999738,45.256326],[-102.467916,45.212833],[-102.712732,45.176337]]]},"properties":{"id":"57626","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.928825,43.848351],[-97.029152,43.848209],[-97.129089,43.847973],[-97.049408,43.776142],[-97.009636,43.76137],[-96.89061,43.73217],[-96.851181,43.746628],[-96.851357,43.775505],[-96.928825,43.848351]]]},"properties":{"id":"57018","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.778091,43.659779],[-96.736764,43.616438],[-96.691315,43.674278],[-96.691277,43.688747],[-96.78154,43.703213],[-96.778091,43.659779]]]},"properties":{"id":"57055","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.752773,43.533061],[-96.758697,43.533035],[-96.758715,43.529425],[-96.751248,43.529435],[-96.752773,43.533061]]]},"properties":{"id":"57117","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.741223,43.52309],[-96.736233,43.522082],[-96.736254,43.527256],[-96.739995,43.527263],[-96.741223,43.52309]]]},"properties":{"id":"57197","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.13111,44.107625],[-103.185954,44.035051],[-103.18614,43.95756],[-102.891454,43.856314],[-102.96977,44.082044],[-103.13111,44.107625]]]},"properties":{"id":"57703","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.634374,45.071308],[-99.695334,45.071309],[-99.816619,45.14433],[-99.756444,44.897425],[-99.649354,44.897484],[-99.634374,45.071308]]]},"properties":{"id":"57455","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.843708,45.93582],[-97.061247,45.935749],[-97.042088,45.848792],[-96.813215,45.761464],[-96.843708,45.93582]]]},"properties":{"id":"57255","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.596742,43.233857],[-102.475666,43.12971],[-102.365614,43.195198],[-102.539012,43.354588],[-102.598155,43.315969],[-102.596742,43.233857]]]},"properties":{"id":"57756","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.984578,43.329787],[-97.032082,43.228043],[-97.00461,43.199685],[-96.924522,43.243423],[-96.964214,43.32987],[-96.984578,43.329787]]]},"properties":{"id":"57021","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.502512,42.966787],[-96.54285,42.908928],[-96.538458,42.908771],[-96.502512,42.966787]]],[[[-96.529221,43.054575],[-96.498436,42.998081],[-96.497359,42.998276],[-96.529221,43.054575]]]]},"properties":{"id":"51023","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.172361,45.594406],[-100.230043,45.313402],[-100.05838,45.260949],[-99.955782,45.318899],[-100.068906,45.59396],[-100.172361,45.594406]]]},"properties":{"id":"57472","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.567355,42.952115],[-97.526571,42.85115],[-97.300812,42.864503],[-97.370002,42.995933],[-97.536607,42.966628],[-97.567355,42.952115]]]},"properties":{"id":"57078","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.397137,44.312571],[-98.437735,44.269276],[-98.397178,44.283675],[-98.397137,44.312571]]],[[[-98.578996,44.312702],[-98.66031,44.225741],[-98.498015,44.196696],[-98.457817,44.2474],[-98.578996,44.312702]]]]},"properties":{"id":"57379","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.06687,44.543103],[-97.189219,44.528594],[-97.195365,44.492233],[-97.22937,44.499479],[-97.269566,44.484859],[-97.270923,44.282502],[-97.189923,44.195882],[-97.090032,44.195796],[-97.049265,44.195781],[-97.038963,44.239213],[-97.048993,44.239157],[-97.016979,44.383724],[-97.027447,44.543476],[-97.06687,44.543103]]]},"properties":{"id":"57212","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.082827,45.791718],[-97.979238,45.762569],[-97.978904,45.806087],[-97.978778,45.935863],[-98.332036,45.936208],[-98.257701,45.806585],[-98.082827,45.791718]]]},"properties":{"id":"57446","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.548657,45.212963],[-103.649493,44.86566],[-103.461146,44.639967],[-102.958752,44.748509],[-102.958684,44.819017],[-102.95729,45.28044],[-102.956636,45.328125],[-103.160689,45.340691],[-103.313853,45.270731],[-103.548657,45.212963]]]},"properties":{"id":"57760","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.172361,45.594406],[-100.278653,45.753607],[-100.32312,45.730915],[-100.430079,45.594823],[-100.341275,45.471896],[-100.260223,45.24681],[-100.229518,45.246795],[-100.230043,45.313402],[-100.172361,45.594406]]]},"properties":{"id":"57631","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.83621,44.718089],[-97.853028,44.544398],[-97.652675,44.544183],[-97.633227,44.544183],[-97.491975,44.67403],[-97.735083,44.718068],[-97.83621,44.718089]]]},"properties":{"id":"57278","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.471103,44.543922],[-97.310154,44.543453],[-97.329848,44.630227],[-97.350085,44.673643],[-97.491975,44.67403],[-97.633227,44.544183],[-97.471103,44.543922]]]},"properties":{"id":"57221","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.079772,45.943553],[-101.110059,45.818528],[-100.97977,45.654199],[-100.980244,45.646041],[-100.604101,45.559419],[-100.342365,45.758165],[-100.499371,45.943653],[-101.079772,45.943553]],[[-100.825006,45.662928],[-100.821781,45.736106],[-100.730503,45.731718],[-100.825006,45.662928]]]},"properties":{"id":"57642","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.376215,45.55926],[-102.467916,45.212833],[-101.999738,45.256326],[-101.741843,45.257704],[-101.676589,45.646454],[-101.80447,45.683709],[-102.000957,45.695702],[-102.376215,45.55926]]]},"properties":{"id":"57644","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.141399,45.647022],[-101.215982,45.21059],[-101.015666,45.210816],[-100.917986,45.28496],[-100.750734,45.384634],[-100.853678,45.4166],[-100.936098,45.41464],[-100.936309,45.472711],[-100.980244,45.646041],[-100.97977,45.654199],[-101.141399,45.647022]]]},"properties":{"id":"57656","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.203433,45.768319],[-101.141399,45.647022],[-100.97977,45.654199],[-101.110059,45.818528],[-101.203433,45.768319]]]},"properties":{"id":"57621","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.451493,44.68876],[-96.451888,44.918999],[-96.537015,44.904621],[-96.602188,44.717727],[-96.451493,44.68876]]]},"properties":{"id":"57237","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.511209,45.128664],[-100.260223,45.24681],[-100.341275,45.471896],[-100.843055,45.255482],[-100.511209,45.128664]]]},"properties":{"id":"57652","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.643897,43.361931],[-98.569504,43.498872],[-98.67633,43.499102],[-98.705782,43.499319],[-98.680666,43.371062],[-98.643897,43.361931]]]},"properties":{"id":"57344","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.221743,45.80226],[-99.24221,45.708607],[-99.221169,45.592593],[-99.214783,45.592593],[-98.841661,45.592263],[-98.724845,45.765217],[-98.724375,45.938731],[-98.84561,45.939145],[-98.973552,45.838722],[-99.221743,45.80226]]]},"properties":{"id":"57456","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.79867,45.056289],[-98.534209,45.071503],[-98.548347,45.242699],[-98.836664,45.201208],[-98.79867,45.056289]]]},"properties":{"id":"57465","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.301707,44.748373],[-98.349965,44.952881],[-98.73711,44.98384],[-98.825715,44.76553],[-98.402473,44.74866],[-98.301707,44.748373]]]},"properties":{"id":"57469","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.965459,45.223075],[-96.842292,45.31172],[-96.842364,45.326089],[-96.992946,45.32688],[-97.035069,45.240329],[-96.965459,45.223075]]]},"properties":{"id":"57251","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.637153,45.34102],[-96.452094,45.14995],[-96.616296,45.407606],[-96.637153,45.34102]]]},"properties":{"id":"57216","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.056624,43.255792],[-99.257054,42.998093],[-99.080143,42.998403],[-99.080177,43.078298],[-99.099916,43.082346],[-99.099969,43.091459],[-99.056624,43.255792]]]},"properties":{"id":"57538","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.297019,43.455641],[-99.383435,42.998029],[-99.257054,42.998093],[-99.056624,43.255792],[-98.995116,43.270207],[-99.297019,43.455641]]]},"properties":{"id":"57523","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.559525,43.059213],[-98.645473,42.99849],[-98.49855,42.998559],[-98.546166,43.05285],[-98.559525,43.059213]]]},"properties":{"id":"68719","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.930029,44.530901],[-101.500087,44.343461],[-101.493801,44.601229],[-101.632079,44.586834],[-101.930029,44.530901]]]},"properties":{"id":"57553","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.927641,43.850519],[-97.846691,43.673503],[-97.750659,43.849714],[-97.750643,43.864135],[-97.767244,43.900345],[-97.851045,43.878761],[-97.927641,43.850519]]]},"properties":{"id":"57340","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.893985,45.945163],[-104.040594,45.433265],[-104.017624,45.432488],[-103.893985,45.945163]]]},"properties":{"id":"57724","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.660767,45.945233],[-103.58276,45.90676],[-103.577098,45.945331],[-103.660767,45.945233]]]},"properties":{"id":"58623","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.878711,44.897515],[-100.121691,44.794722],[-100.121966,44.823779],[-100.296544,44.794609],[-100.149519,44.548324],[-99.87903,44.548377],[-99.674804,44.765429],[-99.598126,44.897268],[-99.649354,44.897484],[-99.756444,44.897425],[-99.878711,44.897515]]]},"properties":{"id":"57564","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.829323,44.546852],[-103.688797,44.517654],[-103.68789,44.604502],[-103.829323,44.546852]]]},"properties":{"id":"57779","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.065911,44.194868],[-99.901954,43.700494],[-99.796675,43.711658],[-99.743346,43.748416],[-99.765665,44.01345],[-99.941105,44.195127],[-100.065911,44.194868]]]},"properties":{"id":"57544","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.589462,44.14067],[-103.495478,43.92677],[-103.29916,43.895146],[-103.18614,43.95756],[-103.185954,44.035051],[-103.270488,44.21171],[-103.297914,44.18933],[-103.452543,44.183423],[-103.589462,44.14067]]]},"properties":{"id":"57702","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.767244,43.900345],[-97.711216,44.081612],[-97.850703,44.096328],[-97.851045,43.878761],[-97.767244,43.900345]]]},"properties":{"id":"57337","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.736764,43.616438],[-96.770969,43.543934],[-96.767579,43.536657],[-96.701686,43.536614],[-96.656591,43.598875],[-96.651765,43.600087],[-96.631691,43.659687],[-96.691315,43.674278],[-96.736764,43.616438]]]},"properties":{"id":"57104","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.137248,44.195813],[-98.141408,43.96726],[-97.992023,43.850692],[-97.927641,43.850519],[-97.851045,43.878761],[-97.850703,44.096328],[-97.849492,44.195235],[-98.014039,44.195113],[-98.137248,44.195813]]]},"properties":{"id":"57314","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.869022,43.528975],[-102.539012,43.354588],[-102.365614,43.195198],[-102.26799,43.154433],[-102.228561,43.238716],[-102.379842,43.687518],[-102.810419,43.688132],[-102.869022,43.528975]]]},"properties":{"id":"57772","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.454536,45.24192],[-98.548347,45.242699],[-98.534209,45.071503],[-98.329701,45.054396],[-98.320454,45.069025],[-98.309434,45.112431],[-98.327182,45.241534],[-98.351404,45.241656],[-98.454536,45.24192]]]},"properties":{"id":"57461","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.932285,43.277263],[-100.748891,43.171366],[-100.748953,42.998185],[-100.609935,42.998192],[-100.313624,43.192242],[-100.381907,43.317579],[-100.471786,43.390171],[-100.5931,43.390124],[-100.93278,43.389473],[-100.932285,43.277263]]]},"properties":{"id":"57555","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.471786,43.390171],[-100.381907,43.317579],[-100.293676,43.390187],[-100.471786,43.390171]]]},"properties":{"id":"57563","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.024101,43.499789],[-97.02383,43.344292],[-96.984578,43.329787],[-96.964214,43.32987],[-96.924788,43.432174],[-96.944611,43.499929],[-97.024101,43.499789]]]},"properties":{"id":"57015","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.325284,43.834129],[-98.451588,43.907833],[-98.622593,43.831316],[-98.594184,43.627776],[-98.326028,43.666939],[-98.325284,43.834129]]]},"properties":{"id":"57368","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.569504,43.498872],[-98.320595,43.498651],[-98.326028,43.666939],[-98.594184,43.627776],[-98.67633,43.499102],[-98.569504,43.498872]]]},"properties":{"id":"57375","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.584435,44.561205],[-98.356842,44.494042],[-98.17498,44.58884],[-98.119401,44.689963],[-98.139683,44.690138],[-98.301707,44.748373],[-98.402473,44.74866],[-98.645882,44.633888],[-98.584435,44.561205]]]},"properties":{"id":"57348","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.099041,44.574094],[-98.033256,44.412803],[-97.972455,44.426803],[-97.954182,44.499083],[-98.099041,44.574094]]]},"properties":{"id":"57386","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.109511,43.260039],[-101.77081,43.348371],[-101.781932,43.389175],[-101.911007,43.389464],[-102.109511,43.260039]]]},"properties":{"id":"57714","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.694718,43.010431],[-97.930415,43.097029],[-97.950417,43.095998],[-98.010672,42.923255],[-97.793262,42.937296],[-97.694718,43.010431]]]},"properties":{"id":"57066","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.756516,43.212937],[-97.930415,43.097029],[-97.694718,43.010431],[-97.635849,43.024703],[-97.614791,43.16874],[-97.674369,43.208341],[-97.756516,43.212937]]]},"properties":{"id":"57059","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.75707,44.514074],[-96.727085,44.412592],[-96.607693,44.303763],[-96.452012,44.412776],[-96.451971,44.455899],[-96.645773,44.513816],[-96.757001,44.528525],[-96.75707,44.514074]]]},"properties":{"id":"57276","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.351074,45.545763],[-98.238317,45.537989],[-98.16604,45.617813],[-98.134577,45.661172],[-98.264712,45.762885],[-98.455609,45.705638],[-98.414709,45.618443],[-98.351074,45.545763]]]},"properties":{"id":"57433","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.836664,45.201208],[-98.548347,45.242699],[-98.454536,45.24192],[-98.536102,45.329283],[-98.651334,45.359168],[-98.82537,45.287998],[-98.836664,45.201208]]]},"properties":{"id":"57460","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.320454,45.069025],[-98.002533,45.022903],[-97.982379,45.022807],[-97.861365,45.036577],[-97.861444,45.065639],[-97.861596,45.10883],[-97.862057,45.1811],[-97.981255,45.269347],[-97.981027,45.31285],[-98.063098,45.312884],[-98.10406,45.341816],[-98.145183,45.327585],[-98.26762,45.241306],[-98.309434,45.112431],[-98.320454,45.069025]]]},"properties":{"id":"57434","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.278653,45.753607],[-100.172361,45.594406],[-100.068906,45.59396],[-99.882466,45.694932],[-99.903066,45.723903],[-100.217856,45.768037],[-100.278653,45.753607]]]},"properties":{"id":"57646","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861444,45.065639],[-97.67745,45.007407],[-97.494048,45.035857],[-97.534941,45.108399],[-97.861596,45.10883],[-97.861444,45.065639]]]},"properties":{"id":"57217","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.122278,42.880993],[-97.137083,42.779171],[-96.899588,42.73066],[-96.845123,42.938961],[-96.904549,42.95347],[-96.944013,42.953496],[-97.042443,42.924296],[-97.122278,42.880993]]]},"properties":{"id":"57069","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.160513,43.028744],[-97.338935,43.067671],[-97.22001,42.945969],[-97.122278,42.880993],[-97.042443,42.924296],[-97.160513,43.028744]]]},"properties":{"id":"57072","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.845123,42.938961],[-96.899588,42.73066],[-96.806213,42.704154],[-96.76641,42.866211],[-96.776457,42.880803],[-96.845123,42.938961]]]},"properties":{"id":"57010","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.338935,43.067671],[-97.160513,43.028744],[-97.080826,43.113183],[-97.081507,43.126756],[-97.357967,43.169544],[-97.428973,43.111391],[-97.398833,43.063482],[-97.338935,43.067671]]]},"properties":{"id":"57037","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.95712,45.944514],[-102.000957,45.695702],[-101.80447,45.683709],[-101.718206,45.731961],[-101.72117,45.944337],[-101.95712,45.944514]]]},"properties":{"id":"57634","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.559626,45.944961],[-102.456649,45.60965],[-102.376215,45.55926],[-102.000957,45.695702],[-101.95712,45.944514],[-102.559626,45.944961]]]},"properties":{"id":"57638","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.451839,45.943869],[-101.362144,45.700803],[-101.203433,45.768319],[-101.110059,45.818528],[-101.079772,45.943553],[-101.451839,45.943869]]]},"properties":{"id":"57641","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.001067,43.605677],[-103.338736,43.708939],[-103.36125,43.651205],[-103.001067,43.605677]]]},"properties":{"id":"57738","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.779676,43.477734],[-103.543346,43.129689],[-103.287816,43.289032],[-103.287778,43.303603],[-103.341769,43.452395],[-103.381848,43.525687],[-103.51253,43.591866],[-103.63158,43.577365],[-103.779676,43.477734]]]},"properties":{"id":"57747","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.917986,45.28496],[-101.015666,45.210816],[-100.843055,45.255482],[-100.917986,45.28496]]]},"properties":{"id":"57661","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.543351,43.000929],[-103.000896,43.000742],[-103.001034,43.216466],[-103.287816,43.289032],[-103.543346,43.129689],[-103.543351,43.000929]]]},"properties":{"id":"57763","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.634374,45.071308],[-99.649354,44.897484],[-99.598126,44.897268],[-99.389349,44.897273],[-99.450516,45.086007],[-99.573159,45.114702],[-99.634374,45.071308]]]},"properties":{"id":"57473","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.534209,45.071503],[-98.79867,45.056289],[-98.798496,45.041274],[-98.73711,44.98384],[-98.349965,44.952881],[-98.329869,44.996329],[-98.329701,45.054396],[-98.534209,45.071503]]]},"properties":{"id":"57424","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.383435,42.998029],[-99.297019,43.455641],[-99.297882,43.499856],[-99.464133,43.663849],[-99.62344,43.629946],[-99.533893,43.458065],[-99.534049,42.998033],[-99.383435,42.998029]]]},"properties":{"id":"57533","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.9906,45.734562],[-102.959869,45.945035],[-103.066431,45.945139],[-103.066512,45.866982],[-103.066429,45.776531],[-102.9906,45.734562]]]},"properties":{"id":"57650","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.906359,44.396551],[-100.149519,44.548324],[-100.296544,44.794609],[-100.380464,44.897684],[-100.404753,44.897695],[-100.717644,44.77093],[-99.894217,44.237127],[-99.906359,44.396551]]]},"properties":{"id":"57501","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.906359,44.396551],[-99.894217,44.237127],[-99.938217,44.195195],[-99.663508,44.217517],[-99.664379,44.279926],[-99.66408,44.321927],[-99.675522,44.678607],[-99.674804,44.765429],[-99.87903,44.548377],[-99.906359,44.396551]]]},"properties":{"id":"57536","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.440421,44.193887],[-99.664379,44.279926],[-99.663508,44.217517],[-99.576571,44.192421],[-99.440421,44.193887]]]},"properties":{"id":"57346","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.139158,43.700948],[-101.911007,43.389464],[-101.781932,43.389175],[-101.77652,43.666004],[-101.741512,43.702375],[-102.019729,43.748043],[-102.019079,43.71926],[-102.139158,43.700948]]]},"properties":{"id":"57750","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.412435,44.082314],[-98.432536,44.082256],[-98.4324,44.072053],[-98.41223,44.067472],[-98.412435,44.082314]]]},"properties":{"id":"57358","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.732129,44.168126],[-100.97428,43.994966],[-100.830167,43.768984],[-100.547792,43.737878],[-100.655999,44.168359],[-100.732129,44.168126]]]},"properties":{"id":"57559","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.451325,44.281883],[-97.270923,44.282502],[-97.269566,44.484859],[-97.269637,44.513818],[-97.451072,44.441114],[-97.451325,44.281883]]]},"properties":{"id":"57249","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.888589,43.874247],[-96.888863,43.935295],[-97.008972,43.920621],[-97.029152,43.848209],[-96.928825,43.848351],[-96.888589,43.874247]]]},"properties":{"id":"57016","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.870825,44.503038],[-103.876419,44.499393],[-103.876457,44.495808],[-103.868805,44.49543],[-103.870825,44.503038]]]},"properties":{"id":"57799","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.594735,43.461692],[-96.796348,43.431921],[-96.767027,43.388196],[-96.707527,43.35908],[-96.589544,43.432202],[-96.594735,43.461692]]]},"properties":{"id":"57032","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.767027,43.388196],[-96.796348,43.431921],[-96.924788,43.432174],[-96.964214,43.32987],[-96.924522,43.243423],[-96.865538,43.214351],[-96.821218,43.25783],[-96.767027,43.388196]]]},"properties":{"id":"57039","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.24808,43.528773],[-97.221569,43.481078],[-97.179307,43.543479],[-97.24808,43.528773]]]},"properties":{"id":"57047","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.501884,45.804794],[-97.479715,45.935134],[-97.978778,45.935863],[-97.978904,45.806087],[-97.855193,45.692296],[-97.557115,45.645589],[-97.518931,45.678091],[-97.501884,45.804794]]]},"properties":{"id":"57430","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.979238,45.762569],[-97.896497,45.689849],[-97.855193,45.692296],[-97.978904,45.806087],[-97.979238,45.762569]]]},"properties":{"id":"57421","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.070562,44.233998],[-103.070735,44.165436],[-103.110644,44.14081],[-103.13111,44.107625],[-102.96977,44.082044],[-102.910254,44.074851],[-102.837717,44.350858],[-103.070562,44.233998]]]},"properties":{"id":"57719","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.671341,43.500313],[-96.656591,43.598875],[-96.701686,43.536614],[-96.707659,43.50037],[-96.671341,43.500313]]]},"properties":{"id":"57103","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.009636,43.76137],[-97.049706,43.499798],[-97.024101,43.499789],[-96.944611,43.499929],[-96.910278,43.500046],[-96.890358,43.543667],[-96.871002,43.645184],[-96.883851,43.71767],[-96.89061,43.73217],[-97.009636,43.76137]]]},"properties":{"id":"57033","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.596992,43.877015],[-96.552017,43.847755],[-96.547226,43.84766],[-96.53685,43.891084],[-96.556839,43.905834],[-96.596992,43.877015]]],[[[-96.596992,43.877015],[-96.566869,43.906057],[-96.597,43.93523],[-96.701602,43.949732],[-96.596992,43.877015]]]]},"properties":{"id":"57065","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.001068,44.510926],[-102.303276,44.433699],[-102.497655,43.963066],[-102.019079,43.71926],[-102.019729,43.748043],[-102.019623,43.894631],[-102.167766,44.196961],[-102.000964,44.343832],[-102.001068,44.510926]]]},"properties":{"id":"57790","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.816854,43.889623],[-103.486331,43.856324],[-103.495478,43.92677],[-103.589462,44.14067],[-103.717005,44.140904],[-104.054588,44.141081],[-104.054839,43.972387],[-103.816854,43.889623]]]},"properties":{"id":"57745","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.497655,43.963066],[-102.616942,43.9003],[-102.708947,43.855551],[-102.810419,43.688132],[-102.379842,43.687518],[-102.139158,43.700948],[-102.019079,43.71926],[-102.497655,43.963066]]]},"properties":{"id":"57780","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.712732,45.176337],[-102.467916,45.212833],[-102.376215,45.55926],[-102.456649,45.60965],[-102.703791,45.559914],[-102.712669,45.256481],[-102.712732,45.176337]]]},"properties":{"id":"57620","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.119401,44.689963],[-97.977171,44.718742],[-97.982379,45.022807],[-98.002533,45.022903],[-98.227621,44.995916],[-98.139683,44.690138],[-98.119401,44.689963]]]},"properties":{"id":"57436","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.122317,44.852785],[-100.121691,44.794722],[-99.878711,44.897515],[-100.122317,44.852785]]],[[[-100.122317,44.852785],[-100.380464,44.897684],[-100.296544,44.794609],[-100.121966,44.823779],[-100.122317,44.852785]]]]},"properties":{"id":"57520","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.901954,43.700494],[-100.009994,43.693763],[-99.981148,43.644733],[-100.041024,43.543478],[-99.822378,43.514307],[-99.796675,43.711658],[-99.901954,43.700494]]]},"properties":{"id":"57541","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.233992,44.196638],[-98.412433,44.096742],[-98.412435,44.082314],[-98.41223,44.067472],[-98.451588,43.907833],[-98.141408,43.96726],[-98.137248,44.195813],[-98.233992,44.196638]]]},"properties":{"id":"57385","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.584435,44.561205],[-98.578996,44.312702],[-98.457817,44.2474],[-98.437735,44.269276],[-98.397137,44.312571],[-98.356842,44.494042],[-98.584435,44.561205]]]},"properties":{"id":"57384","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.756516,43.212937],[-97.837137,43.320883],[-98.070714,43.268092],[-98.090774,43.140785],[-98.092563,43.125443],[-97.950417,43.095998],[-97.930415,43.097029],[-97.756516,43.212937]]]},"properties":{"id":"57376","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.88903,44.167229],[-96.768999,44.152424],[-96.707967,44.195443],[-96.727085,44.412592],[-96.75707,44.514074],[-96.910787,44.369757],[-96.88903,44.167229]]]},"properties":{"id":"57006","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.607693,44.303763],[-96.646177,44.195571],[-96.453144,44.08199],[-96.452012,44.412776],[-96.607693,44.303763]]]},"properties":{"id":"57026","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.979592,45.588483],[-97.896497,45.689849],[-97.979238,45.762569],[-98.082827,45.791718],[-98.134577,45.661172],[-98.16604,45.617813],[-97.979592,45.588483]]]},"properties":{"id":"57432","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.145183,45.327585],[-98.306943,45.357242],[-98.392123,45.328565],[-98.351404,45.241656],[-98.327182,45.241534],[-98.26762,45.241306],[-98.145183,45.327585]]]},"properties":{"id":"57474","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.471437,44.621633],[-103.467455,44.546873],[-102.964125,44.604287],[-102.958752,44.748509],[-103.461146,44.639967],[-103.471437,44.621633]]]},"properties":{"id":"57788","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.649493,44.86566],[-103.587867,44.640515],[-103.471437,44.621633],[-103.461146,44.639967],[-103.649493,44.86566]]]},"properties":{"id":"57762","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.49855,42.998559],[-98.153107,42.838986],[-98.140445,42.860884],[-98.226046,43.023054],[-98.107628,43.096613],[-98.092563,43.125443],[-98.090774,43.140785],[-98.267817,43.1979],[-98.392044,43.212709],[-98.498445,43.077768],[-98.546166,43.05285],[-98.49855,42.998559]],[[-98.44483,42.993423],[-98.444965,43.007557],[-98.407389,43.00945],[-98.407471,42.99492],[-98.44483,42.993423]]]},"properties":{"id":"57380","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.210098,43.398355],[-98.576148,43.34156],[-98.545273,43.278729],[-98.392044,43.212709],[-98.267817,43.1979],[-98.189899,43.383748],[-98.210098,43.398355]]]},"properties":{"id":"57313","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.546166,43.05285],[-98.498445,43.077768],[-98.561172,43.059708],[-98.559525,43.059213],[-98.546166,43.05285]]]},"properties":{"id":"57367","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.735083,44.718068],[-97.491975,44.67403],[-97.350085,44.673643],[-97.330244,44.70245],[-97.491346,44.804035],[-97.511672,44.804039],[-97.735083,44.718068]]]},"properties":{"id":"57271","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.080826,43.113183],[-96.944013,42.953496],[-96.904549,42.95347],[-96.865538,43.214351],[-96.924522,43.243423],[-97.00461,43.199685],[-97.081507,43.126756],[-97.080826,43.113183]]]},"properties":{"id":"57014","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.342365,45.758165],[-100.604101,45.559419],[-100.593716,45.559346],[-100.430079,45.594823],[-100.32312,45.730915],[-100.342365,45.758165]]]},"properties":{"id":"57658","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.36125,43.651205],[-103.338736,43.708939],[-103.370321,43.760246],[-103.486331,43.856324],[-103.816854,43.889623],[-103.884068,43.751928],[-103.779676,43.477734],[-103.63158,43.577365],[-103.610345,43.608721],[-103.51253,43.591866],[-103.381848,43.525687],[-103.36125,43.651205]]]},"properties":{"id":"57730","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.992023,43.850692],[-98.187601,43.851072],[-98.166376,43.585706],[-97.866914,43.615663],[-97.846691,43.673503],[-97.927641,43.850519],[-97.992023,43.850692]]]},"properties":{"id":"57301","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.166376,43.585706],[-98.187601,43.851072],[-98.325284,43.834129],[-98.326028,43.666939],[-98.320595,43.498651],[-98.233258,43.497986],[-98.182199,43.497983],[-98.166376,43.585706]]]},"properties":{"id":"57363","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.833633,44.678824],[-97.063293,44.601772],[-97.06687,44.543103],[-97.027447,44.543476],[-96.804475,44.543756],[-96.743836,44.630617],[-96.833633,44.678824]]]},"properties":{"id":"57234","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.451888,44.918999],[-96.452224,45.080776],[-96.620365,45.107052],[-96.71948,44.976631],[-96.720424,44.93356],[-96.537015,44.904621],[-96.451888,44.918999]]]},"properties":{"id":"57259","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.882345,44.97687],[-96.743088,44.890007],[-96.720424,44.93356],[-96.71948,44.976631],[-96.741197,45.062875],[-96.882802,45.063772],[-96.882796,45.049234],[-96.882345,44.97687]]]},"properties":{"id":"57265","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.647124,45.347163],[-99.523079,45.506419],[-99.584875,45.593107],[-99.707705,45.593391],[-99.831965,45.340425],[-99.708393,45.347277],[-99.647124,45.347163]]]},"properties":{"id":"57428","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.287778,43.303603],[-103.000871,43.390582],[-103.341769,43.452395],[-103.287778,43.303603]]]},"properties":{"id":"57766","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.452094,45.14995],[-96.637153,45.34102],[-96.73981,45.326264],[-96.721791,45.135492],[-96.720851,45.070929],[-96.620365,45.107052],[-96.452224,45.080776],[-96.452094,45.14995]]]},"properties":{"id":"57252","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.616296,45.407606],[-96.686254,45.413037],[-96.842364,45.326089],[-96.842292,45.31172],[-96.73981,45.326264],[-96.637153,45.34102],[-96.616296,45.407606]]]},"properties":{"id":"57227","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.312579,44.634207],[-99.30018,44.19483],[-99.288596,44.194811],[-99.185839,44.196477],[-99.312579,44.634207]]]},"properties":{"id":"57371","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.68873,43.813824],[-97.529501,43.795069],[-97.369463,43.848526],[-97.430022,43.935491],[-97.750643,43.864135],[-97.750659,43.849714],[-97.68873,43.813824]]]},"properties":{"id":"57321","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.232712,45.666815],[-103.160689,45.340691],[-102.956636,45.328125],[-103.232712,45.666815]]]},"properties":{"id":"57651","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.58276,45.90676],[-103.236859,45.732937],[-103.066429,45.776531],[-103.066512,45.866982],[-103.316003,45.945152],[-103.577098,45.945331],[-103.58276,45.90676]]]},"properties":{"id":"57755","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.674804,44.765429],[-99.675522,44.678607],[-99.522718,44.48352],[-99.66408,44.321927],[-99.664379,44.279926],[-99.440421,44.193887],[-99.30018,44.19483],[-99.312579,44.634207],[-99.311699,44.751009],[-99.311754,44.897227],[-99.389349,44.897273],[-99.598126,44.897268],[-99.674804,44.765429]]]},"properties":{"id":"57345","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.228112,43.63713],[-101.042061,43.584844],[-100.992694,43.850148],[-101.064271,43.900923],[-101.407267,43.995136],[-101.44409,43.995139],[-101.228112,43.63713]]]},"properties":{"id":"57521","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.008972,43.920621],[-97.029083,44.06559],[-97.049363,44.109064],[-97.089762,44.123619],[-97.329272,44.00804],[-97.248918,43.848319],[-97.129089,43.847973],[-97.029152,43.848209],[-97.008972,43.920621]]]},"properties":{"id":"57042","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.248195,43.666025],[-97.168007,43.63032],[-97.049408,43.776142],[-97.129089,43.847973],[-97.248918,43.848319],[-97.30928,43.848481],[-97.248195,43.666025]]]},"properties":{"id":"57048","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.049408,43.776142],[-97.168007,43.63032],[-97.129463,43.557743],[-97.049706,43.499798],[-97.009636,43.76137],[-97.049408,43.776142]]]},"properties":{"id":"57035","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.304814,45.940102],[-99.221743,45.80226],[-98.973552,45.838722],[-98.97346,45.939574],[-99.304814,45.940102]]]},"properties":{"id":"57457","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.547792,43.737878],[-100.830167,43.768984],[-100.992694,43.850148],[-101.042061,43.584844],[-101.038762,43.454245],[-100.93278,43.389473],[-100.5931,43.390124],[-100.471329,43.721533],[-100.547792,43.737878]]]},"properties":{"id":"57579","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.597,43.93523],[-96.748702,44.022479],[-96.748458,43.979057],[-96.701602,43.949732],[-96.597,43.93523]]]},"properties":{"id":"57024","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.45341,43.967166],[-96.4848,43.905583],[-96.453379,43.913514],[-96.45341,43.967166]]]},"properties":{"id":"56164","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.53685,43.891084],[-96.547226,43.84766],[-96.452988,43.849507],[-96.453379,43.913514],[-96.4848,43.905583],[-96.53685,43.891084]]]},"properties":{"id":"56144","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.042088,45.848792],[-97.061247,45.935749],[-97.144965,45.935607],[-97.227801,45.804228],[-97.042088,45.848792]]]},"properties":{"id":"57224","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.313624,43.192242],[-100.609935,42.998192],[-100.41515,42.998964],[-100.313624,43.192242]]]},"properties":{"id":"69201","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.748891,43.171366],[-100.932285,43.277263],[-101.069182,43.232039],[-100.807411,43.171163],[-100.748891,43.171366]]]},"properties":{"id":"57570","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.981148,43.644733],[-100.080048,43.659168],[-100.126372,43.415419],[-100.007932,43.429727],[-100.041024,43.543478],[-99.981148,43.644733]]]},"properties":{"id":"57584","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.498997,42.560832],[-96.548789,42.520547],[-96.475578,42.480198],[-96.498997,42.560832]]]},"properties":{"id":"57049","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.249586,42.855517],[-97.22001,42.945969],[-97.338935,43.067671],[-97.398833,43.063482],[-97.370002,42.995933],[-97.300812,42.864503],[-97.249586,42.855517]]]},"properties":{"id":"57046","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.48671,45.199305],[-101.741843,45.257704],[-101.999738,45.256326],[-101.717148,44.691885],[-101.638861,44.646678],[-101.499819,44.759379],[-101.400375,44.993969],[-101.501053,44.993795],[-101.486283,45.110982],[-101.48671,45.199305]]]},"properties":{"id":"57623","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.992023,43.850692],[-98.141408,43.96726],[-98.451588,43.907833],[-98.325284,43.834129],[-98.187601,43.851072],[-97.992023,43.850692]]]},"properties":{"id":"57359","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.727085,44.412592],[-96.707967,44.195443],[-96.646177,44.195571],[-96.607693,44.303763],[-96.727085,44.412592]]]},"properties":{"id":"57002","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.743836,44.630617],[-96.804475,44.543756],[-96.757001,44.528525],[-96.645773,44.513816],[-96.581826,44.601847],[-96.703306,44.630597],[-96.743836,44.630617]]]},"properties":{"id":"57268","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.645773,44.513816],[-96.451971,44.455899],[-96.451574,44.645341],[-96.581826,44.601847],[-96.645773,44.513816]]]},"properties":{"id":"57213","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.454536,45.24192],[-98.351404,45.241656],[-98.392123,45.328565],[-98.536102,45.329283],[-98.454536,45.24192]]]},"properties":{"id":"57479","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.264712,45.762885],[-98.134577,45.661172],[-98.082827,45.791718],[-98.257701,45.806585],[-98.264712,45.762885]]]},"properties":{"id":"57449","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.063098,45.312884],[-98.021956,45.341774],[-98.10406,45.341816],[-98.063098,45.312884]]]},"properties":{"id":"57439","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.112084,43.600208],[-99.106615,44.005924],[-99.284928,44.019569],[-99.151768,43.585837],[-99.112084,43.600208]]]},"properties":{"id":"57370","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.688797,44.517654],[-103.710281,44.415826],[-103.467455,44.546873],[-103.471437,44.621633],[-103.587867,44.640515],[-103.68789,44.604502],[-103.688797,44.517654]]]},"properties":{"id":"57793","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.070714,43.268092],[-98.189899,43.383748],[-98.267817,43.1979],[-98.090774,43.140785],[-98.070714,43.268092]]]},"properties":{"id":"57330","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.391976,45.180782],[-97.37545,45.45964],[-97.406879,45.486174],[-97.631684,45.500338],[-97.694326,45.442367],[-97.862057,45.1811],[-97.861596,45.10883],[-97.534941,45.108399],[-97.42244,45.1517],[-97.391976,45.180782]]]},"properties":{"id":"57274","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.49454,44.949201],[-97.494048,45.035857],[-97.67745,45.007407],[-97.49454,44.949201]]]},"properties":{"id":"57236","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.329744,44.862137],[-97.25345,44.967789],[-97.473726,45.035897],[-97.494048,45.035857],[-97.49454,44.949201],[-97.511672,44.804039],[-97.491346,44.804035],[-97.329744,44.862137]]]},"properties":{"id":"57243","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.882796,45.049234],[-97.139511,45.093279],[-97.25345,44.967789],[-97.329744,44.862137],[-97.229018,44.804057],[-97.127007,44.803344],[-96.934521,44.804383],[-96.914963,44.890045],[-96.923426,44.904606],[-96.882345,44.97687],[-96.882796,45.049234]]]},"properties":{"id":"57201","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.718206,45.731961],[-101.80447,45.683709],[-101.676589,45.646454],[-101.362144,45.700803],[-101.451839,45.943869],[-101.639244,45.94426],[-101.718206,45.731961]]]},"properties":{"id":"57660","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.825006,45.662928],[-100.730503,45.731718],[-100.821781,45.736106],[-100.825006,45.662928]]]},"properties":{"id":"57639","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.381848,43.525687],[-103.341769,43.452395],[-103.000871,43.390582],[-103.000845,43.32568],[-102.598155,43.315969],[-102.539012,43.354588],[-102.869022,43.528975],[-103.001067,43.605677],[-103.36125,43.651205],[-103.381848,43.525687]]]},"properties":{"id":"57722","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.486331,43.856324],[-103.370321,43.760246],[-103.29916,43.895146],[-103.495478,43.92677],[-103.486331,43.856324]]]},"properties":{"id":"57751","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.543346,43.129689],[-103.779676,43.477734],[-103.884068,43.751928],[-104.055122,43.740398],[-103.543351,43.000929],[-103.543346,43.129689]]]},"properties":{"id":"57735","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.055122,43.740398],[-103.884068,43.751928],[-103.816854,43.889623],[-104.054839,43.972387],[-104.055122,43.740398]]]},"properties":{"id":"82701","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.877065,45.5443],[-97.620722,45.558248],[-97.597806,45.587703],[-97.557115,45.645589],[-97.855193,45.692296],[-97.896497,45.689849],[-97.979592,45.588483],[-97.979647,45.573922],[-97.877065,45.5443]]]},"properties":{"id":"57454","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.936309,45.472711],[-100.936098,45.41464],[-100.853678,45.4166],[-100.936309,45.472711]]]},"properties":{"id":"57630","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.708393,45.347277],[-99.695334,45.071309],[-99.634374,45.071308],[-99.573159,45.114702],[-99.544436,45.332501],[-99.647124,45.347163],[-99.708393,45.347277]]]},"properties":{"id":"57475","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.24221,45.708607],[-99.584875,45.593107],[-99.523079,45.506419],[-99.221169,45.592593],[-99.24221,45.708607]]]},"properties":{"id":"57448","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.450516,45.086007],[-99.327573,45.245075],[-99.544436,45.332501],[-99.573159,45.114702],[-99.450516,45.086007]]]},"properties":{"id":"57466","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.908566,45.013007],[-99.082609,45.245105],[-99.113258,45.244996],[-99.327573,45.245075],[-99.450516,45.086007],[-99.389349,44.897273],[-99.311754,44.897227],[-98.987881,44.897058],[-98.908566,45.013007]]]},"properties":{"id":"57438","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.226066,45.209924],[-97.164467,45.195571],[-97.195932,45.33564],[-97.226066,45.209924]]]},"properties":{"id":"57256","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.500087,44.343461],[-101.407267,43.995136],[-101.064271,43.900923],[-100.97428,43.994966],[-100.732129,44.168126],[-101.074716,44.371737],[-101.136838,44.747126],[-101.380036,44.65549],[-101.493801,44.601229],[-101.500087,44.343461]]]},"properties":{"id":"57552","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.904149,44.297972],[-98.947436,44.721749],[-98.977874,44.765168],[-99.311699,44.751009],[-99.312579,44.634207],[-99.185839,44.196477],[-98.943206,44.196495],[-98.904149,44.297972]]]},"properties":{"id":"57362","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.529501,43.795069],[-97.68873,43.813824],[-97.606601,43.665501],[-97.508469,43.664108],[-97.488766,43.664441],[-97.529501,43.795069]]]},"properties":{"id":"57374","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.017624,45.432488],[-103.686718,45.213216],[-103.313853,45.270731],[-103.160689,45.340691],[-103.232712,45.666815],[-103.236859,45.732937],[-103.58276,45.90676],[-103.660767,45.945233],[-103.893985,45.945163],[-104.017624,45.432488]]]},"properties":{"id":"57720","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.548657,45.212963],[-103.313853,45.270731],[-103.686718,45.213216],[-103.548657,45.212963]]]},"properties":{"id":"57776","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.675522,44.678607],[-99.66408,44.321927],[-99.522718,44.48352],[-99.675522,44.678607]]]},"properties":{"id":"57540","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.064271,43.900923],[-100.992694,43.850148],[-100.830167,43.768984],[-100.97428,43.994966],[-101.064271,43.900923]]]},"properties":{"id":"57562","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.090032,44.195796],[-97.089762,44.123619],[-97.049363,44.109064],[-96.908724,44.138497],[-97.049265,44.195781],[-97.090032,44.195796]]]},"properties":{"id":"57050","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.714615,44.396214],[-103.574305,44.298453],[-103.070562,44.233998],[-102.837717,44.350858],[-102.88605,44.51792],[-102.964125,44.604287],[-103.467455,44.546873],[-103.710281,44.415826],[-103.712536,44.415857],[-103.714615,44.396214]],[[-103.483252,44.411697],[-103.453064,44.415608],[-103.453853,44.394416],[-103.481299,44.396484],[-103.483252,44.411697]]]},"properties":{"id":"57785","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.6678,43.112792],[-96.529221,43.054575],[-96.510851,43.04977],[-96.466689,43.17073],[-96.52965,43.170576],[-96.687339,43.170703],[-96.6678,43.112792]]]},"properties":{"id":"57034","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.36318,43.914776],[-99.504278,43.796315],[-99.44428,43.691673],[-99.36318,43.914776]]]},"properties":{"id":"57365","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.488766,43.664441],[-97.408392,43.61559],[-97.248195,43.666025],[-97.30928,43.848481],[-97.369463,43.848526],[-97.529501,43.795069],[-97.488766,43.664441]]]},"properties":{"id":"57058","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.597806,45.587703],[-97.364642,45.558733],[-97.227089,45.558158],[-97.351011,45.675126],[-97.518931,45.678091],[-97.557115,45.645589],[-97.597806,45.587703]]]},"properties":{"id":"57232","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.009994,43.693763],[-100.220024,43.691746],[-100.230845,43.713856],[-100.293676,43.390187],[-100.381907,43.317579],[-100.313624,43.192242],[-100.41515,42.998964],[-99.652422,42.997902],[-99.652408,43.050653],[-99.870132,43.212619],[-99.6923,43.357042],[-99.593334,43.414753],[-99.822378,43.514307],[-100.041024,43.543478],[-100.007932,43.429727],[-100.126372,43.415419],[-100.080048,43.659168],[-99.981148,43.644733],[-100.009994,43.693763]]]},"properties":{"id":"57580","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.430022,43.935491],[-97.450651,44.194899],[-97.511624,44.194936],[-97.571431,44.195124],[-97.711216,44.081612],[-97.767244,43.900345],[-97.750643,43.864135],[-97.430022,43.935491]]]},"properties":{"id":"57349","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.53167,43.500421],[-96.531578,43.659161],[-96.631691,43.659687],[-96.651765,43.600087],[-96.598926,43.500457],[-96.53167,43.500421]]]},"properties":{"id":"57005","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.531578,43.659161],[-96.53167,43.500421],[-96.45345,43.645058],[-96.531578,43.659161]]]},"properties":{"id":"57068","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.691277,43.688747],[-96.691315,43.674278],[-96.631691,43.659687],[-96.531578,43.659161],[-96.45345,43.645058],[-96.452988,43.849507],[-96.547226,43.84766],[-96.552017,43.847755],[-96.691185,43.761246],[-96.691277,43.688747]]]},"properties":{"id":"57030","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.748702,44.022479],[-96.768999,44.152424],[-96.88903,44.167229],[-96.888648,44.138364],[-96.888762,44.080546],[-96.888863,43.935295],[-96.888589,43.874247],[-96.748458,43.979057],[-96.748702,44.022479]]]},"properties":{"id":"57017","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.942448,45.732864],[-102.703791,45.559914],[-102.456649,45.60965],[-102.559626,45.944961],[-102.94207,45.945],[-102.942448,45.732864]]]},"properties":{"id":"57640","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.475666,43.12971],[-102.596742,43.233857],[-102.789386,42.999979],[-102.300217,42.999428],[-102.377145,43.06101],[-102.475666,43.12971]]]},"properties":{"id":"57770","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.000896,43.000742],[-102.789386,42.999979],[-102.596742,43.233857],[-102.598155,43.315969],[-103.000845,43.32568],[-103.001034,43.216466],[-103.000896,43.000742]]]},"properties":{"id":"57764","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.327182,45.241534],[-98.309434,45.112431],[-98.26762,45.241306],[-98.327182,45.241534]]]},"properties":{"id":"57429","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.807411,43.171163],[-100.866117,43.089285],[-100.806908,42.998659],[-100.748953,42.998185],[-100.748891,43.171366],[-100.807411,43.171163]]]},"properties":{"id":"69212","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.227985,43.100106],[-101.228109,42.998133],[-100.806908,42.998659],[-100.866117,43.089285],[-101.227985,43.100106]]]},"properties":{"id":"69216","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.032082,43.228043],[-96.984578,43.329787],[-97.02383,43.344292],[-97.259688,43.299647],[-97.313451,43.256094],[-97.299022,43.227426],[-97.032082,43.228043]]]},"properties":{"id":"57036","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.806213,42.704154],[-96.648063,42.560824],[-96.538026,42.649032],[-96.595617,42.81539],[-96.707313,42.851697],[-96.76641,42.866211],[-96.806213,42.704154]]]},"properties":{"id":"57025","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.499819,44.759379],[-101.638861,44.646678],[-101.632079,44.586834],[-101.493801,44.601229],[-101.380036,44.65549],[-101.499819,44.759379]]]},"properties":{"id":"57622","state_code":"46"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.425491,36.123657],[-84.219688,36.122321],[-84.167506,36.185107],[-84.226366,36.2206],[-84.372731,36.216778],[-84.441034,36.163478],[-84.425491,36.123657]]]},"properties":{"id":"37710","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.77333,35.80884],[-84.980258,35.757071],[-85.061247,35.642482],[-84.866416,35.556849],[-84.866967,35.554066],[-84.782485,35.621027],[-84.723853,35.752896],[-84.77333,35.80884]]]},"properties":{"id":"37381","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.960124,35.828245],[-84.01174,35.79803],[-83.977059,35.757798],[-83.964424,35.805688],[-83.960124,35.828245]]]},"properties":{"id":"37701","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.857689,35.750542],[-83.901985,35.671564],[-83.873744,35.678058],[-83.701985,35.716017],[-83.734186,35.772657],[-83.801729,35.829756],[-83.857689,35.750542]]]},"properties":{"id":"37886","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.216903,36.335227],[-84.141758,36.260028],[-84.09196,36.222613],[-84.072698,36.251989],[-84.216903,36.335227]]]},"properties":{"id":"37757","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.599067,35.935652],[-88.440858,35.869168],[-88.334447,35.868639],[-88.309053,35.91731],[-88.309793,36.001587],[-88.323992,36.07033],[-88.353105,36.122208],[-88.53818,36.023853],[-88.565787,36.013258],[-88.593594,35.96586],[-88.599067,35.935652]]]},"properties":{"id":"38344","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.733057,36.034022],[-88.697833,35.942171],[-88.616849,35.934106],[-88.599067,35.935652],[-88.593594,35.96586],[-88.62689,35.97959],[-88.664663,36.065578],[-88.675695,36.063987],[-88.708417,36.070642],[-88.733057,36.034022]]]},"properties":{"id":"38220","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.212559,36.12029],[-88.361248,36.148417],[-88.353105,36.122208],[-88.323992,36.07033],[-88.285911,36.028155],[-88.260982,36.027604],[-88.21296,36.077621],[-88.212559,36.12029]]]},"properties":{"id":"38342","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.407513,36.378081],[-82.322718,36.259196],[-82.269894,36.239345],[-82.277824,36.347121],[-82.311914,36.39793],[-82.386163,36.392104],[-82.407513,36.378081]],[[-82.288686,36.29734],[-82.29505,36.301649],[-82.290949,36.304512],[-82.28616,36.301892],[-82.288686,36.29734]]]},"properties":{"id":"37601","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.579905,35.552084],[-88.637976,35.488582],[-88.811425,35.411069],[-88.877711,35.341223],[-88.880379,35.288646],[-88.819849,35.294699],[-88.769455,35.264321],[-88.688221,35.311767],[-88.497911,35.385026],[-88.515338,35.460292],[-88.520985,35.480835],[-88.579905,35.552084]]]},"properties":{"id":"38340","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.362021,35.395172],[-88.366169,35.362465],[-88.318289,35.346372],[-88.362021,35.395172]]]},"properties":{"id":"38359","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.679649,36.482212],[-83.624163,36.343206],[-83.531704,36.356986],[-83.361416,36.422287],[-83.305657,36.597972],[-83.426611,36.597364],[-83.645286,36.544277],[-83.690849,36.493845],[-83.679649,36.482212]]]},"properties":{"id":"37879","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.425458,36.50416],[-85.276281,36.626816],[-85.593358,36.616588],[-85.608891,36.481963],[-85.544426,36.445285],[-85.425458,36.50416]]]},"properties":{"id":"38551","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.297483,35.902981],[-83.176592,35.812715],[-83.126836,35.886559],[-83.098114,35.960368],[-83.080676,36.051099],[-83.17551,36.12667],[-83.231144,36.122808],[-83.258184,36.002895],[-83.297483,35.902981]]]},"properties":{"id":"37821","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.080676,36.051099],[-83.098114,35.960368],[-82.962862,35.972604],[-83.055249,36.087069],[-83.080676,36.051099]]]},"properties":{"id":"37843","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.23756,35.507667],[-86.19342,35.450167],[-86.158018,35.362607],[-86.038402,35.344043],[-86.025692,35.346237],[-85.900088,35.493746],[-86.08889,35.646823],[-86.120263,35.660929],[-86.192263,35.574756],[-86.23756,35.507667]]]},"properties":{"id":"37355","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.994718,35.315324],[-85.900764,35.322083],[-85.869478,35.380435],[-85.888242,35.456661],[-85.900088,35.493746],[-86.025692,35.346237],[-85.994718,35.315324]]]},"properties":{"id":"37342","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.870412,35.935063],[-89.065603,35.851514],[-89.080743,35.786435],[-88.990844,35.747078],[-88.968299,35.717485],[-88.83583,35.741397],[-88.820612,35.741085],[-88.807304,35.869997],[-88.870412,35.935063]]]},"properties":{"id":"38343","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.990844,35.747078],[-89.076477,35.770651],[-89.268053,35.754733],[-89.073463,35.572166],[-89.072082,35.613686],[-88.96702,35.662217],[-88.968299,35.717485],[-88.990844,35.747078]]]},"properties":{"id":"38006","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.812073,36.037937],[-84.913972,36.027263],[-84.859444,35.950534],[-84.812073,36.037937]]]},"properties":{"id":"38558","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.758647,36.13367],[-86.758369,36.081073],[-86.745253,36.047393],[-86.701649,36.009349],[-86.671364,36.077233],[-86.71924,36.116735],[-86.758647,36.13367]]]},"properties":{"id":"37211","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.678914,36.316487],[-86.747168,36.283743],[-86.751569,36.240678],[-86.712707,36.234989],[-86.663539,36.192792],[-86.63892,36.209603],[-86.668744,36.300577],[-86.678914,36.316487]]]},"properties":{"id":"37115","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.770807,36.174503],[-86.744085,36.162098],[-86.712976,36.167213],[-86.695162,36.200522],[-86.749856,36.198878],[-86.770807,36.174503]]]},"properties":{"id":"37206","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.784352,36.169637],[-86.78531,36.174862],[-86.785684,36.16927],[-86.784352,36.169637]]],[[[-86.777498,36.161456],[-86.780334,36.165811],[-86.78294,36.163725],[-86.777498,36.161456]]],[[[-86.780696,36.166357],[-86.783761,36.16874],[-86.783798,36.165037],[-86.780696,36.166357]]]]},"properties":{"id":"37219","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.780696,36.166357],[-86.783798,36.165037],[-86.78294,36.163725],[-86.780334,36.165811],[-86.780696,36.166357]]]},"properties":{"id":"37243","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.770807,36.174503],[-86.780591,36.175963],[-86.769523,36.160636],[-86.744085,36.162098],[-86.770807,36.174503]]]},"properties":{"id":"37213","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.284591,35.517424],[-88.246423,35.456843],[-88.215948,35.410184],[-88.197024,35.387174],[-88.181834,35.469219],[-88.239764,35.615469],[-88.291292,35.599025],[-88.284591,35.517424]]]},"properties":{"id":"38374","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.863316,36.033295],[-85.908551,35.941607],[-85.86367,35.955723],[-85.863316,36.033295]]]},"properties":{"id":"37059","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.533868,36.314204],[-87.376344,36.234828],[-87.297801,36.317491],[-87.33601,36.316826],[-87.494864,36.365569],[-87.537177,36.35808],[-87.564016,36.339567],[-87.533868,36.314204]],[[-87.476789,36.296444],[-87.470492,36.295962],[-87.46603,36.291471],[-87.474193,36.290464],[-87.476789,36.296444]]]},"properties":{"id":"37051","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.295428,36.128798],[-87.386435,36.198021],[-87.541735,36.215309],[-87.566798,36.177857],[-87.531688,35.944137],[-87.468182,35.932184],[-87.301078,36.001382],[-87.298398,36.098792],[-87.295428,36.128798]]]},"properties":{"id":"37055","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.178624,36.067518],[-87.298398,36.098792],[-87.301078,36.001382],[-87.211072,36.002561],[-87.182573,36.049726],[-87.178624,36.067518]]]},"properties":{"id":"37029","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.182573,36.049726],[-87.211072,36.002561],[-87.183373,35.911831],[-87.134043,35.910318],[-87.046006,36.011946],[-87.054114,36.045535],[-87.182573,36.049726]]]},"properties":{"id":"37062","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.157568,36.146426],[-89.192401,36.251334],[-89.267259,36.207417],[-89.157568,36.146426]]]},"properties":{"id":"38259","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.371806,35.146221],[-89.475586,35.070124],[-89.484462,34.993968],[-89.251811,34.994549],[-89.243338,35.060624],[-89.274685,35.087769],[-89.298332,35.114617],[-89.371806,35.146221]]]},"properties":{"id":"38057","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.24833,35.140249],[-89.111915,35.081508],[-89.063051,35.168787],[-89.151905,35.196779],[-89.191556,35.17751],[-89.24833,35.140249]]]},"properties":{"id":"38042","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.848124,36.133657],[-88.954511,36.110885],[-88.915248,36.081014],[-88.793187,35.993749],[-88.733057,36.034022],[-88.708417,36.070642],[-88.848124,36.133657]]]},"properties":{"id":"38316","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.224052,34.999377],[-87.094472,34.996673],[-87.140678,35.048706],[-87.151156,35.069162],[-87.223206,35.059201],[-87.224474,35.039106],[-87.224052,34.999377]]]},"properties":{"id":"38473","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.748276,35.206324],[-86.8473,35.218549],[-86.83119,35.163286],[-86.788448,35.16464],[-86.748276,35.206324]]]},"properties":{"id":"38459","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.865804,35.113175],[-86.777197,35.108987],[-86.788448,35.16464],[-86.83119,35.163286],[-86.865804,35.113175]]]},"properties":{"id":"38453","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.718702,36.123666],[-83.66746,36.080587],[-83.628063,36.113443],[-83.601383,36.153626],[-83.65185,36.236897],[-83.700205,36.207583],[-83.725223,36.15385],[-83.718702,36.123666]]]},"properties":{"id":"37709","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.100925,36.228401],[-83.036766,36.225169],[-82.922775,36.274249],[-82.929689,36.33332],[-83.094955,36.342574],[-83.100925,36.228401]]]},"properties":{"id":"37711","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.605983,36.443495],[-82.680677,36.4318],[-82.709122,36.406283],[-82.666069,36.346045],[-82.599925,36.353654],[-82.562352,36.434658],[-82.563048,36.435158],[-82.605983,36.443495]]]},"properties":{"id":"37656","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.34747,36.085334],[-83.258184,36.002895],[-83.231144,36.122808],[-83.341013,36.146017],[-83.34747,36.085334]]]},"properties":{"id":"37890","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.385727,36.170723],[-83.433432,36.209889],[-83.467097,36.174688],[-83.38209,36.09984],[-83.34747,36.085334],[-83.341013,36.146017],[-83.385727,36.170723]]]},"properties":{"id":"37877","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.309283,35.058514],[-85.307475,35.038576],[-85.294163,35.036125],[-85.278243,35.049491],[-85.288768,35.055787],[-85.309283,35.058514]]]},"properties":{"id":"37403","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.047546,35.349318],[-85.045776,35.251126],[-85.02356,35.245551],[-84.932726,35.365988],[-84.981884,35.437248],[-85.016343,35.409288],[-85.048312,35.358911],[-85.047546,35.349318]]]},"properties":{"id":"37308","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.365165,35.079496],[-88.331395,34.995552],[-88.202959,35.008028],[-88.248566,35.066756],[-88.242447,35.055833],[-88.230109,35.057448],[-88.227425,35.0503],[-88.237405,35.048034],[-88.24864,35.066732],[-88.316256,35.15971],[-88.317973,35.160014],[-88.363211,35.124113],[-88.365165,35.079496]]]},"properties":{"id":"38326","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.374265,35.127323],[-88.365165,35.079496],[-88.363211,35.124113],[-88.374265,35.127323]]]},"properties":{"id":"38376","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.361713,35.436668],[-88.362021,35.395172],[-88.318289,35.346372],[-88.251301,35.343938],[-88.241677,35.423263],[-88.246423,35.456843],[-88.284591,35.517424],[-88.361713,35.436668]]]},"properties":{"id":"38371","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.645313,36.564794],[-82.609176,36.594099],[-82.7796,36.593743],[-82.8296,36.460738],[-82.671862,36.531779],[-82.673753,36.53513],[-82.6791,36.577727],[-82.645313,36.564794]]]},"properties":{"id":"37642","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.342548,35.792205],[-89.470513,35.832574],[-89.353729,35.72643],[-89.342548,35.792205]]]},"properties":{"id":"38037","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.061456,36.444093],[-88.300823,36.5004],[-88.230822,36.394609],[-88.192961,36.35574],[-88.040219,36.424898],[-88.061456,36.444093]]]},"properties":{"id":"38222","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.509595,36.271267],[-88.526853,36.219607],[-88.527542,36.205867],[-88.376038,36.14878],[-88.323663,36.222432],[-88.509595,36.271267]]]},"properties":{"id":"38231","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.677729,35.627496],[-87.717559,35.483347],[-87.608406,35.426681],[-87.464955,35.422286],[-87.385963,35.505635],[-87.404428,35.633028],[-87.677729,35.627496]]]},"properties":{"id":"38462","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.564016,36.339567],[-87.568683,36.279507],[-87.533868,36.314204],[-87.564016,36.339567]]],[[[-87.564016,36.339567],[-87.537177,36.35808],[-87.602597,36.418979],[-87.703334,36.439938],[-87.716781,36.401482],[-87.733924,36.353944],[-87.564016,36.339567]]]]},"properties":{"id":"37050","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.687119,36.21743],[-87.770284,36.243089],[-87.835594,36.232375],[-87.949603,36.24316],[-87.937603,36.192503],[-87.963119,36.07232],[-87.93702,35.939943],[-87.939873,35.86712],[-87.96274,35.840921],[-87.818202,35.836779],[-87.743055,36.008753],[-87.687119,36.21743]]]},"properties":{"id":"37185","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.685827,35.937067],[-83.664906,35.814898],[-83.620318,35.78638],[-83.615747,35.791206],[-83.620397,35.806944],[-83.537392,35.805427],[-83.536272,35.743557],[-83.529396,35.743591],[-83.329433,35.778868],[-83.310782,35.895846],[-83.544057,35.96292],[-83.685827,35.937067]]]},"properties":{"id":"37876","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.755154,36.005256],[-83.865204,36.028397],[-83.893745,35.982965],[-83.890619,35.9615],[-83.694578,35.943887],[-83.693364,35.9447],[-83.689562,35.977833],[-83.755154,36.005256]]]},"properties":{"id":"37914","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.916526,35.971767],[-83.925062,35.966904],[-83.928978,35.964319],[-83.9201,35.955505],[-83.914612,35.958591],[-83.916526,35.971767]]]},"properties":{"id":"37902","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.916526,35.971767],[-83.914612,35.958591],[-83.890619,35.9615],[-83.893745,35.982965],[-83.916526,35.971767]]]},"properties":{"id":"37915","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.522815,35.003052],[-87.460806,35.002726],[-87.505789,35.066246],[-87.522815,35.003052]]]},"properties":{"id":"38481","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.303155,35.61718],[-84.227798,35.468309],[-84.038153,35.348202],[-84.006378,35.507696],[-84.050488,35.546055],[-84.094249,35.55402],[-84.195961,35.617935],[-84.262619,35.66423],[-84.290917,35.642814],[-84.303155,35.61718]]]},"properties":{"id":"37885","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.525644,35.087485],[-88.712658,35.080711],[-88.713851,34.995221],[-88.528497,34.995827],[-88.525644,35.087485]]]},"properties":{"id":"38367","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.170866,36.509337],[-86.11429,36.635482],[-86.411379,36.650924],[-86.384351,36.604427],[-86.218847,36.502377],[-86.170866,36.509337]]]},"properties":{"id":"37186","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.24968,36.397618],[-86.230096,36.349172],[-86.095461,36.341934],[-86.051499,36.452033],[-86.170866,36.509337],[-86.218847,36.502377],[-86.24968,36.397618]]]},"properties":{"id":"37074","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67615,35.647841],[-88.96702,35.662217],[-89.072082,35.613686],[-88.922895,35.495927],[-88.815773,35.502524],[-88.702482,35.532003],[-88.67615,35.647841]]]},"properties":{"id":"38301","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.490648,35.034669],[-85.510733,34.996291],[-85.497794,34.983199],[-85.474073,34.98302],[-85.490648,35.034669]]]},"properties":{"id":"37396","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.954084,36.328745],[-89.103726,36.342853],[-89.162624,36.289668],[-89.177379,36.248902],[-88.95977,36.223926],[-88.956614,36.27767],[-88.954084,36.328745]]]},"properties":{"id":"38253","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246903,36.298211],[-89.162624,36.289668],[-89.103726,36.342853],[-89.296404,36.442205],[-89.246903,36.298211]]]},"properties":{"id":"38260","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.375455,36.451695],[-85.412803,36.336915],[-85.375491,36.284206],[-85.225331,36.319487],[-85.235935,36.333873],[-85.221517,36.400401],[-85.297798,36.476566],[-85.375455,36.451695]]]},"properties":{"id":"38570","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.365904,35.140187],[-84.304838,35.121478],[-84.298119,35.166675],[-84.341284,35.176618],[-84.365904,35.140187]]]},"properties":{"id":"37333","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.729415,36.649001],[-86.981675,36.644355],[-86.991269,36.441895],[-86.983709,36.371452],[-86.920403,36.386734],[-86.712885,36.473557],[-86.717325,36.548554],[-86.736778,36.586395],[-86.729415,36.649001]]]},"properties":{"id":"37172","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.623751,36.563278],[-86.538605,36.460348],[-86.399452,36.480511],[-86.384351,36.604427],[-86.411379,36.650924],[-86.679071,36.650164],[-86.640655,36.600943],[-86.623751,36.563278]]]},"properties":{"id":"37148","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.455733,36.033335],[-86.567215,35.88319],[-86.56664,35.860579],[-86.400734,35.815715],[-86.378825,35.974205],[-86.364201,36.010172],[-86.43076,36.049897],[-86.455733,36.033335]]]},"properties":{"id":"37129","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.243081,36.002573],[-86.364201,36.010172],[-86.378825,35.974205],[-86.235291,35.875782],[-86.243081,36.002573]]]},"properties":{"id":"37085","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.596738,36.431581],[-84.481478,36.326562],[-84.449719,36.4047],[-84.459962,36.426565],[-84.596738,36.431581]]]},"properties":{"id":"37755","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.601276,35.793134],[-83.620397,35.806944],[-83.615747,35.791206],[-83.601276,35.793134]]],[[[-83.601276,35.793134],[-83.620318,35.78638],[-83.664906,35.814898],[-83.734186,35.772657],[-83.701985,35.716017],[-83.662814,35.690618],[-83.529396,35.743591],[-83.536272,35.743557],[-83.601276,35.793134]]]]},"properties":{"id":"37862","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.049295,35.16382],[-90.019465,35.155306],[-90.00885,35.154197],[-89.98565,35.161307],[-90.017952,35.186761],[-90.050919,35.185509],[-90.049295,35.16382]]]},"properties":{"id":"38107","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.044684,35.076666],[-90.188626,35.07573],[-90.043036,34.994964],[-90.044684,35.076666]]]},"properties":{"id":"38109","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.770649,35.5153],[-89.83901,35.514823],[-89.84389,35.495855],[-89.770649,35.5153]]],[[[-89.759317,35.465513],[-89.723391,35.396329],[-89.699911,35.364197],[-89.642796,35.387983],[-89.622638,35.447904],[-89.751571,35.536886],[-89.770649,35.5153],[-89.759317,35.465513]]]]},"properties":{"id":"38011","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.059127,35.127119],[-90.032388,35.124524],[-90.031007,35.135197],[-90.059127,35.127119]]]},"properties":{"id":"38126","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.84554,35.09934],[-89.886864,35.109987],[-89.892974,35.083347],[-89.828329,35.056243],[-89.793188,35.063749],[-89.84554,35.09934]]]},"properties":{"id":"38119","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.562352,36.434658],[-82.599925,36.353654],[-82.587992,36.320846],[-82.533736,36.288301],[-82.590488,36.156085],[-82.553596,36.128754],[-82.391561,36.19925],[-82.355761,36.242807],[-82.437679,36.338791],[-82.517134,36.425436],[-82.562352,36.434658]]]},"properties":{"id":"37659","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.054322,35.389277],[-90.068156,35.46678],[-90.168882,35.422206],[-90.074992,35.384152],[-90.054322,35.389277]]]},"properties":{"id":"72338","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.423804,35.567422],[-85.254062,35.765611],[-85.269359,35.793388],[-85.320371,35.80758],[-85.459845,35.799558],[-85.495565,35.768847],[-85.541493,35.586295],[-85.492257,35.54757],[-85.423804,35.567422]]]},"properties":{"id":"38585","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.590488,36.156085],[-82.533736,36.288301],[-82.587992,36.320846],[-82.590488,36.156085]]]},"properties":{"id":"37690","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.407513,36.378081],[-82.386163,36.392104],[-82.420713,36.446966],[-82.517134,36.425436],[-82.437679,36.338791],[-82.407513,36.378081]]]},"properties":{"id":"37615","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.374187,36.302772],[-82.372438,36.297675],[-82.362331,36.303672],[-82.367743,36.306839],[-82.374187,36.302772]]]},"properties":{"id":"37614","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.55038,36.3024],[-88.713066,36.21745],[-88.700743,36.164914],[-88.527542,36.205867],[-88.526853,36.219607],[-88.524857,36.267411],[-88.55038,36.3024]]]},"properties":{"id":"38229","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.956614,36.27767],[-88.77713,36.287181],[-88.719881,36.432314],[-88.755605,36.502654],[-88.788102,36.502709],[-88.950205,36.409248],[-88.954084,36.328745],[-88.956614,36.27767]]]},"properties":{"id":"38237","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.831154,35.96599],[-86.875333,36.041374],[-86.886642,36.043151],[-86.962677,35.997074],[-86.846992,35.944844],[-86.831154,35.96599]]]},"properties":{"id":"37069","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.127972,36.197109],[-84.167506,36.185107],[-84.219688,36.122321],[-84.28657,36.021588],[-84.15731,35.990883],[-84.134853,36.011428],[-84.094338,36.070904],[-84.032945,36.174197],[-84.062632,36.185875],[-84.079322,36.182761],[-84.127972,36.197109]]]},"properties":{"id":"37716","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.54108,35.600884],[-86.376813,35.563118],[-86.262997,35.590513],[-86.279328,35.645648],[-86.519121,35.680556],[-86.539532,35.68129],[-86.54108,35.600884]]]},"properties":{"id":"37020","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.257364,35.373014],[-86.324056,35.456861],[-86.328496,35.406045],[-86.257364,35.373014]]],[[[-86.313164,35.458294],[-86.23756,35.507667],[-86.192263,35.574756],[-86.262997,35.590513],[-86.376813,35.563118],[-86.313164,35.458294]]]]},"properties":{"id":"37183","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.939873,35.86712],[-88.083299,35.756226],[-88.01909,35.739903],[-87.981763,35.774973],[-87.96274,35.840921],[-87.939873,35.86712]]]},"properties":{"id":"38380","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.233631,35.233333],[-85.131509,35.19069],[-85.047546,35.349318],[-85.048312,35.358911],[-85.185003,35.374415],[-85.288633,35.32305],[-85.289701,35.266402],[-85.233631,35.233333]]]},"properties":{"id":"37379","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.901985,35.671564],[-84.050488,35.546055],[-84.006378,35.507696],[-83.981934,35.511638],[-83.873744,35.678058],[-83.901985,35.671564]]]},"properties":{"id":"37878","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.968828,35.226789],[-84.847811,35.166579],[-84.777361,35.242127],[-84.89742,35.26621],[-84.978813,35.225826],[-84.968828,35.226789]]]},"properties":{"id":"37312","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.932726,35.365988],[-85.02356,35.245551],[-84.98074,35.220424],[-84.978813,35.225826],[-84.89742,35.26621],[-84.851095,35.346489],[-84.860164,35.350074],[-84.932726,35.365988]]]},"properties":{"id":"37336","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.727818,35.120817],[-84.80785,34.987594],[-84.56824,34.988298],[-84.564451,35.030943],[-84.627031,35.077217],[-84.727818,35.120817]]]},"properties":{"id":"37362","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.968983,36.480026],[-83.955512,36.387661],[-83.954908,36.387623],[-83.842506,36.403916],[-83.679649,36.482212],[-83.690849,36.493845],[-83.750876,36.557718],[-83.943139,36.473564],[-83.968983,36.480026]]]},"properties":{"id":"37870","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.616849,35.934106],[-88.620926,35.810595],[-88.600001,35.789195],[-88.453295,35.769161],[-88.443103,35.822551],[-88.440858,35.869168],[-88.599067,35.935652],[-88.616849,35.934106]]]},"properties":{"id":"38321","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.288686,36.29734],[-82.28616,36.301892],[-82.290949,36.304512],[-82.29505,36.301649],[-82.288686,36.29734]]]},"properties":{"id":"37682","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.277824,36.347121],[-82.269894,36.239345],[-82.254955,36.224848],[-82.122699,36.322985],[-81.992968,36.433112],[-81.951482,36.473863],[-81.983843,36.508314],[-82.075348,36.458612],[-82.251005,36.419509],[-82.277824,36.347121]]]},"properties":{"id":"37643","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.044181,36.249141],[-82.226651,36.17805],[-82.222052,36.156911],[-81.970749,36.212329],[-82.044181,36.249141]]]},"properties":{"id":"37687","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.277824,36.347121],[-82.251005,36.419509],[-82.281994,36.423515],[-82.311914,36.39793],[-82.277824,36.347121]]]},"properties":{"id":"37694","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.073559,36.427653],[-87.120443,36.45546],[-87.1517,36.425453],[-87.174058,36.313433],[-87.153043,36.30535],[-87.082334,36.364508],[-87.073559,36.427653]]]},"properties":{"id":"37035","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.182573,36.049726],[-87.054114,36.045535],[-87.040532,36.049557],[-87.06106,36.146387],[-87.074167,36.16744],[-87.178624,36.067518],[-87.182573,36.049726]]]},"properties":{"id":"37082","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.361713,35.436668],[-88.483484,35.46769],[-88.515338,35.460292],[-88.497911,35.385026],[-88.453107,35.358172],[-88.38873,35.351782],[-88.366169,35.362465],[-88.362021,35.395172],[-88.361713,35.436668]]]},"properties":{"id":"38332","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.483484,35.46769],[-88.361713,35.436668],[-88.284591,35.517424],[-88.291292,35.599025],[-88.422582,35.530272],[-88.474868,35.498374],[-88.475773,35.497986],[-88.483484,35.46769]]]},"properties":{"id":"38368","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.910903,36.587075],[-83.912563,36.574841],[-83.896531,36.586631],[-83.910903,36.587075]]]},"properties":{"id":"37851","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.658539,36.600369],[-83.750876,36.557718],[-83.690849,36.493845],[-83.645286,36.544277],[-83.658539,36.600369]]]},"properties":{"id":"37724","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.276281,36.626816],[-85.425458,36.50416],[-85.375455,36.451695],[-85.297798,36.476566],[-85.277299,36.593053],[-85.276281,36.626816]]]},"properties":{"id":"38541","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.268053,35.754733],[-89.076477,35.770651],[-89.080743,35.786435],[-89.065603,35.851514],[-89.129701,35.899882],[-89.166936,35.884628],[-89.160617,35.881069],[-89.169032,35.867528],[-89.218451,35.821866],[-89.228978,35.809648],[-89.242294,35.799575],[-89.268053,35.754733]]]},"properties":{"id":"38001","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.206297,36.011814],[-89.275452,35.98522],[-89.330502,35.882129],[-89.242294,35.799575],[-89.228978,35.809648],[-89.246193,35.829135],[-89.224535,35.837403],[-89.218451,35.821866],[-89.169032,35.867528],[-89.166936,35.884628],[-89.129701,35.899882],[-89.206297,36.011814]],[[-89.235094,35.930537],[-89.242721,35.945214],[-89.227424,35.950166],[-89.227546,35.936374],[-89.235094,35.930537]]]},"properties":{"id":"38034","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.100781,35.95031],[-85.04114,35.77177],[-84.981428,35.791471],[-84.865874,35.847961],[-84.914267,35.925724],[-85.100781,35.95031]]]},"properties":{"id":"38555","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.79171,36.116334],[-86.802939,36.089357],[-86.758369,36.081073],[-86.758647,36.13367],[-86.759564,36.134898],[-86.788861,36.133874],[-86.79171,36.116334]]]},"properties":{"id":"37204","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.43076,36.049897],[-86.436029,36.233232],[-86.483229,36.316811],[-86.542494,36.31945],[-86.566293,36.273132],[-86.57931,36.204736],[-86.520883,36.07252],[-86.49548,36.039838],[-86.455733,36.033335],[-86.43076,36.049897]]]},"properties":{"id":"37122","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.181834,35.469219],[-88.000029,35.478904],[-87.987359,35.484177],[-88.035213,35.616679],[-88.18992,35.617591],[-88.233958,35.615672],[-88.239764,35.615469],[-88.181834,35.469219]]]},"properties":{"id":"38329","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246903,36.298211],[-89.405145,36.312075],[-89.419276,36.282891],[-89.381616,36.182481],[-89.267259,36.207417],[-89.192401,36.251334],[-89.177379,36.248902],[-89.162624,36.289668],[-89.246903,36.298211]]]},"properties":{"id":"38240","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.609164,35.919935],[-89.435038,35.996872],[-89.530971,36.067736],[-89.629453,36.185382],[-89.644838,35.904351],[-89.609164,35.919935]]]},"properties":{"id":"38030","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.090594,36.216709],[-85.039153,36.163263],[-84.937018,36.154866],[-84.918635,36.221318],[-84.969285,36.265696],[-85.047066,36.246514],[-85.090594,36.216709]]]},"properties":{"id":"38553","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.072524,35.277362],[-86.234053,35.24574],[-86.279538,35.271137],[-86.365923,35.232706],[-86.330681,35.193835],[-86.032771,35.096864],[-85.943849,35.125286],[-85.948474,35.131972],[-86.046286,35.188467],[-86.072524,35.277362]]]},"properties":{"id":"37398","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.381429,35.369189],[-87.464955,35.422286],[-87.608406,35.426681],[-87.617781,35.234512],[-87.492635,35.12819],[-87.425442,35.14789],[-87.186912,35.169584],[-87.175379,35.301162],[-87.381429,35.369189]]]},"properties":{"id":"38464","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.891554,35.052334],[-86.882771,35.054357],[-86.886156,35.058888],[-86.891554,35.052334]]]},"properties":{"id":"38455","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.280595,36.394689],[-83.255292,36.289092],[-83.234191,36.281792],[-83.182491,36.336192],[-83.096631,36.380751],[-83.192574,36.424516],[-83.280595,36.394689]]]},"properties":{"id":"37811","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.300318,35.024086],[-85.281125,34.984881],[-85.27302,34.984975],[-85.260102,35.02045],[-85.24914,35.049174],[-85.278243,35.049491],[-85.294163,35.036125],[-85.300318,35.024086]]]},"properties":{"id":"37404","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.260102,35.02045],[-85.27302,34.984975],[-85.175227,34.985976],[-85.20182,35.007618],[-85.260102,35.02045]]]},"properties":{"id":"37412","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.127148,36.537495],[-82.961741,36.593778],[-83.101524,36.594039],[-83.127148,36.537495]]]},"properties":{"id":"37765","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.786703,35.105419],[-88.858245,34.995136],[-88.713851,34.995221],[-88.712658,35.080711],[-88.744425,35.158398],[-88.786703,35.105419]]]},"properties":{"id":"38061","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.076807,35.431396],[-88.985173,35.40878],[-89.01257,35.467917],[-89.074766,35.530626],[-89.078847,35.432067],[-89.076807,35.431396]]]},"properties":{"id":"38392","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.241677,35.423263],[-88.251301,35.343938],[-88.203878,35.356595],[-88.196462,35.379561],[-88.197024,35.387174],[-88.215948,35.410184],[-88.241677,35.423263]]]},"properties":{"id":"38370","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.24864,35.066732],[-88.237405,35.048034],[-88.227425,35.0503],[-88.230109,35.057448],[-88.242447,35.055833],[-88.248566,35.066756],[-88.24864,35.066732]]]},"properties":{"id":"38365","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.516403,36.501583],[-88.522585,36.325743],[-88.435562,36.396378],[-88.469023,36.501144],[-88.516403,36.501583]]]},"properties":{"id":"38224","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.717337,35.834782],[-87.743122,35.663436],[-87.677729,35.627496],[-87.404428,35.633028],[-87.389849,35.641354],[-87.386063,35.794417],[-87.397499,35.858458],[-87.633221,35.822578],[-87.717337,35.834782]]]},"properties":{"id":"37033","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.990841,36.04342],[-88.019334,35.981686],[-87.93702,35.939943],[-87.963119,36.07232],[-87.990841,36.04342]]]},"properties":{"id":"37134","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.618923,36.13833],[-85.496431,36.344467],[-85.542338,36.353695],[-85.614536,36.247045],[-85.612534,36.188255],[-85.618923,36.13833]],[[-85.507736,36.174945],[-85.501449,36.176246],[-85.503555,36.1716],[-85.507736,36.174945]]]},"properties":{"id":"38501","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.65798,36.275042],[-85.803693,36.255669],[-85.800221,36.254776],[-85.786487,36.23011],[-85.780456,36.226402],[-85.742746,36.228276],[-85.65798,36.275042]]]},"properties":{"id":"38564","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.528262,36.162559],[-83.504105,36.039141],[-83.38209,36.09984],[-83.467097,36.174688],[-83.528262,36.162559]]]},"properties":{"id":"37760","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.738656,36.401205],[-81.793089,36.362462],[-81.717405,36.347779],[-81.738656,36.401205]]]},"properties":{"id":"37691","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.195349,35.834689],[-84.236546,35.872711],[-84.26046,35.783529],[-84.246416,35.728723],[-84.170372,35.735914],[-84.166267,35.805106],[-84.195349,35.834689]]]},"properties":{"id":"37772","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.466255,35.053163],[-86.370547,35.144251],[-86.369862,35.161194],[-86.478262,35.163436],[-86.466255,35.053163]]]},"properties":{"id":"37348","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.390592,35.842479],[-84.26046,35.783529],[-84.236546,35.872711],[-84.240811,35.8767],[-84.263383,35.89694],[-84.386735,35.886006],[-84.390592,35.842479]]]},"properties":{"id":"37771","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.815881,35.377774],[-84.858345,35.353144],[-84.860164,35.350074],[-84.851095,35.346489],[-84.804021,35.325783],[-84.815881,35.377774]]],[[[-84.798645,35.316068],[-84.66184,35.212852],[-84.625067,35.275539],[-84.798645,35.316068]]]]},"properties":{"id":"37309","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.472116,35.459609],[-84.374209,35.389475],[-84.227798,35.468309],[-84.303155,35.61718],[-84.495984,35.499671],[-84.472038,35.488222],[-84.472116,35.459609]]]},"properties":{"id":"37354","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.442059,35.198671],[-88.423064,35.121707],[-88.37374,35.140608],[-88.442059,35.198671]]]},"properties":{"id":"38379","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.595144,35.8508],[-86.727213,35.849973],[-86.74006,35.850847],[-86.774165,35.813944],[-86.817009,35.775162],[-86.837374,35.74659],[-86.782016,35.706595],[-86.76748,35.702365],[-86.66047,35.692544],[-86.613531,35.809965],[-86.602829,35.838601],[-86.595144,35.8508]]]},"properties":{"id":"37046","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.33601,36.316826],[-87.297801,36.317491],[-87.253922,36.373393],[-87.268939,36.402356],[-87.338164,36.391097],[-87.33601,36.316826]]]},"properties":{"id":"37171","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.620887,35.932199],[-84.569431,36.031867],[-84.740338,36.012574],[-84.726542,36.000072],[-84.620887,35.932199]]]},"properties":{"id":"37829","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.496431,36.344467],[-85.618923,36.13833],[-85.570742,36.058837],[-85.372232,36.085141],[-85.302666,36.239036],[-85.375491,36.284206],[-85.412803,36.336915],[-85.496431,36.344467]]]},"properties":{"id":"38506","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.121619,36.450335],[-85.221517,36.400401],[-85.235935,36.333873],[-85.180021,36.314611],[-85.137455,36.290453],[-85.121519,36.33999],[-85.117722,36.447049],[-85.121619,36.450335]]]},"properties":{"id":"38543","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.987359,35.484177],[-87.815016,35.476487],[-87.717559,35.483347],[-87.677729,35.627496],[-87.743122,35.663436],[-87.981763,35.774973],[-88.01909,35.739903],[-88.035213,35.616679],[-87.987359,35.484177]]]},"properties":{"id":"37096","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.391564,35.053799],[-84.400254,35.035641],[-84.368776,35.029053],[-84.391564,35.053799]]]},"properties":{"id":"37326","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.807415,36.131582],[-85.750963,36.146446],[-85.780456,36.226402],[-85.786487,36.23011],[-85.833092,36.194134],[-85.86244,36.146213],[-85.807415,36.131582]]]},"properties":{"id":"38548","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.386735,35.886006],[-84.438401,35.909706],[-84.595223,35.843771],[-84.614282,35.797585],[-84.551558,35.752099],[-84.502797,35.743099],[-84.390592,35.842479],[-84.386735,35.886006]]]},"properties":{"id":"37763","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.400734,35.815715],[-86.56664,35.860579],[-86.595144,35.8508],[-86.602829,35.838601],[-86.521674,35.72441],[-86.411475,35.73128],[-86.397123,35.809169],[-86.400734,35.815715]]]},"properties":{"id":"37128","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.481478,36.326562],[-84.471037,36.203778],[-84.458336,36.186542],[-84.441034,36.163478],[-84.372731,36.216778],[-84.331425,36.323991],[-84.449719,36.4047],[-84.481478,36.326562]]]},"properties":{"id":"37756","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.601276,35.793134],[-83.615747,35.791206],[-83.620318,35.78638],[-83.601276,35.793134]]],[[[-83.601276,35.793134],[-83.536272,35.743557],[-83.537392,35.805427],[-83.620397,35.806944],[-83.601276,35.793134]]]]},"properties":{"id":"37863","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.015448,35.117526],[-90.00963,35.072927],[-89.985583,35.076179],[-89.955955,35.080586],[-89.98035,35.115873],[-90.015448,35.117526]]]},"properties":{"id":"38114","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.964388,35.182964],[-90.017952,35.186761],[-89.98565,35.161307],[-89.953755,35.164105],[-89.91726,35.182293],[-89.964388,35.182964]]]},"properties":{"id":"38108","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.953755,35.164105],[-89.951738,35.139211],[-89.928213,35.142803],[-89.888165,35.148885],[-89.879538,35.156481],[-89.898562,35.17291],[-89.91726,35.182293],[-89.953755,35.164105]]]},"properties":{"id":"38122","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.709741,35.158839],[-89.832461,35.16662],[-89.796971,35.131967],[-89.800972,35.116909],[-89.786097,35.109352],[-89.743553,35.107269],[-89.709741,35.158839]]]},"properties":{"id":"38018","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.994672,35.070496],[-89.990756,35.071936],[-89.989891,35.064946],[-89.987212,35.063705],[-89.985583,35.076179],[-90.00963,35.072927],[-90.022572,35.065607],[-89.994672,35.070496]]]},"properties":{"id":"38132","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.846483,36.288167],[-85.905179,36.186246],[-85.909351,36.178833],[-85.86244,36.146213],[-85.833092,36.194134],[-85.800221,36.254776],[-85.803693,36.255669],[-85.846483,36.288167]]]},"properties":{"id":"38560","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.542494,36.31945],[-86.584675,36.416785],[-86.657848,36.430588],[-86.650783,36.415779],[-86.678914,36.316487],[-86.668744,36.300577],[-86.566293,36.273132],[-86.542494,36.31945]]]},"properties":{"id":"37075","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.491001,35.989035],[-82.5772,36.078387],[-82.602877,36.039833],[-82.491001,35.989035]]]},"properties":{"id":"37657","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.733054,36.345043],[-83.842506,36.403916],[-83.954908,36.387623],[-83.956513,36.381148],[-83.965566,36.355214],[-83.948128,36.279761],[-83.93182,36.281253],[-83.733054,36.345043]]]},"properties":{"id":"37866","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.639962,35.163022],[-87.620798,35.230767],[-87.935824,35.234328],[-87.934774,35.155519],[-87.924784,35.146813],[-87.714991,35.142672],[-87.639962,35.163022]]]},"properties":{"id":"38450","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.851904,35.005566],[-87.750344,35.00444],[-87.714991,35.142672],[-87.924784,35.146813],[-87.851904,35.005566]]]},"properties":{"id":"38452","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.719881,36.432314],[-88.77713,36.287181],[-88.776109,36.218949],[-88.713066,36.21745],[-88.55038,36.3024],[-88.582199,36.35017],[-88.664567,36.456805],[-88.719881,36.432314]]]},"properties":{"id":"38225","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.664567,36.456805],[-88.580798,36.502099],[-88.755605,36.502654],[-88.719881,36.432314],[-88.664567,36.456805]]]},"properties":{"id":"38226","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.848124,36.133657],[-88.708417,36.070642],[-88.675695,36.063987],[-88.700743,36.164914],[-88.713066,36.21745],[-88.776109,36.218949],[-88.9407,36.203893],[-88.914445,36.186869],[-88.848124,36.133657]]]},"properties":{"id":"38230","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.134853,36.011428],[-84.061974,35.995847],[-84.031996,36.008481],[-83.998867,36.028961],[-83.990775,36.049639],[-83.988843,36.152907],[-84.094338,36.070904],[-84.134853,36.011428]]]},"properties":{"id":"37849","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.213782,35.950119],[-84.123046,35.938237],[-84.05441,35.970311],[-84.061974,35.995847],[-84.134853,36.011428],[-84.15731,35.990883],[-84.213782,35.950119]]]},"properties":{"id":"37931","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.641548,35.580651],[-86.794697,35.66759],[-86.895101,35.510014],[-86.882026,35.407604],[-86.744763,35.324177],[-86.729647,35.360437],[-86.741018,35.449984],[-86.681496,35.396514],[-86.638547,35.428073],[-86.598139,35.542658],[-86.641548,35.580651]]]},"properties":{"id":"37091","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.996829,36.141715],[-87.990841,36.04342],[-87.963119,36.07232],[-87.937603,36.192503],[-87.996829,36.141715]]]},"properties":{"id":"38333","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.309793,36.001587],[-88.285911,36.028155],[-88.323992,36.07033],[-88.309793,36.001587]]],[[[-88.260982,36.027604],[-88.214235,35.994718],[-88.21296,36.077621],[-88.260982,36.027604]]]]},"properties":{"id":"38317","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.222077,35.500744],[-85.423804,35.567422],[-85.492257,35.54757],[-85.537985,35.445394],[-85.506528,35.357477],[-85.392276,35.26626],[-85.289701,35.266402],[-85.288633,35.32305],[-85.222077,35.500744]]]},"properties":{"id":"37327","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.227051,36.330143],[-84.331425,36.323991],[-84.372731,36.216778],[-84.226366,36.2206],[-84.141758,36.260028],[-84.216903,36.335227],[-84.227051,36.330143]]]},"properties":{"id":"37714","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.243081,36.002573],[-86.235291,35.875782],[-86.236234,35.8632],[-86.154888,35.876697],[-86.148161,35.922958],[-86.153443,35.982313],[-86.234962,36.004563],[-86.243081,36.002573]]]},"properties":{"id":"37118","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.916274,36.093234],[-86.044785,35.990662],[-86.014849,35.96106],[-85.925497,35.887154],[-85.908551,35.941607],[-85.863316,36.033295],[-85.886287,36.105416],[-85.916274,36.093234]]]},"properties":{"id":"37095","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.524857,36.267411],[-88.526853,36.219607],[-88.509595,36.271267],[-88.524857,36.267411]]],[[[-88.376038,36.14878],[-88.527542,36.205867],[-88.700743,36.164914],[-88.675695,36.063987],[-88.664663,36.065578],[-88.573866,36.017007],[-88.572582,36.016887],[-88.53818,36.023853],[-88.353105,36.122208],[-88.361248,36.148417],[-88.376038,36.14878]]]]},"properties":{"id":"38201","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.529047,35.227637],[-88.479539,35.341755],[-88.688221,35.311767],[-88.769455,35.264321],[-88.75486,35.201121],[-88.529047,35.227637]]]},"properties":{"id":"38315","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.985173,35.40878],[-88.877711,35.341223],[-88.811425,35.411069],[-88.815773,35.502524],[-88.922895,35.495927],[-89.01257,35.467917],[-88.985173,35.40878]]]},"properties":{"id":"38356","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.69393,36.618905],[-85.860043,36.623305],[-85.894979,36.454742],[-85.828026,36.411836],[-85.708926,36.548259],[-85.69393,36.618905]]]},"properties":{"id":"37150","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.898997,35.943963],[-82.962862,35.972604],[-83.098114,35.960368],[-83.126836,35.886559],[-83.025144,35.783553],[-82.898997,35.943963]]]},"properties":{"id":"37727","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.176592,35.812715],[-83.099399,35.775076],[-83.025144,35.783553],[-83.126836,35.886559],[-83.176592,35.812715]]]},"properties":{"id":"37753","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.076477,35.770651],[-88.990844,35.747078],[-89.080743,35.786435],[-89.076477,35.770651]]]},"properties":{"id":"38337","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.937018,36.154866],[-85.039153,36.163263],[-85.235247,36.020869],[-85.197841,35.987549],[-85.190385,35.981463],[-85.197972,35.977679],[-85.19954,35.971282],[-85.100781,35.95031],[-84.914267,35.925724],[-84.859444,35.950534],[-84.913972,36.027263],[-84.812073,36.037937],[-84.788693,36.056381],[-84.80546,36.090501],[-84.870888,36.136925],[-84.937018,36.154866]]]},"properties":{"id":"38571","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.100385,36.241535],[-85.207886,36.25802],[-85.302666,36.239036],[-85.372232,36.085141],[-85.235247,36.020869],[-85.039153,36.163263],[-85.090594,36.216709],[-85.100385,36.241535]]]},"properties":{"id":"38574","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.922643,36.111294],[-86.919977,36.093915],[-86.915231,36.110207],[-86.922643,36.111294]]],[[[-86.922643,36.111294],[-86.913962,36.117936],[-86.822196,36.147928],[-86.810387,36.159199],[-86.837765,36.168904],[-86.983613,36.209566],[-86.989551,36.177758],[-86.981107,36.111123],[-86.922643,36.111294]]]]},"properties":{"id":"37209","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.822196,36.147928],[-86.913962,36.117936],[-86.915231,36.110207],[-86.919977,36.093915],[-86.869489,36.076344],[-86.816625,36.12976],[-86.819184,36.133692],[-86.822196,36.147928]]]},"properties":{"id":"37205","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.520883,36.07252],[-86.57931,36.204736],[-86.63892,36.209603],[-86.663539,36.192792],[-86.610364,36.13206],[-86.546879,36.078406],[-86.520883,36.07252]]]},"properties":{"id":"37076","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.827591,36.191461],[-86.782977,36.191892],[-86.808715,36.202387],[-86.827591,36.191461]]]},"properties":{"id":"37228","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.749856,36.198878],[-86.695162,36.200522],[-86.712707,36.234989],[-86.751569,36.240678],[-86.749856,36.198878]]]},"properties":{"id":"37216","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.697251,36.144349],[-86.609517,36.115741],[-86.610364,36.13206],[-86.663539,36.192792],[-86.712707,36.234989],[-86.695162,36.200522],[-86.712976,36.167213],[-86.697251,36.144349]]]},"properties":{"id":"37214","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.476789,36.296444],[-87.474193,36.290464],[-87.46603,36.291471],[-87.470492,36.295962],[-87.476789,36.296444]]]},"properties":{"id":"37165","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.561292,35.17148],[-89.494043,35.143558],[-89.417695,35.181755],[-89.547383,35.292232],[-89.573398,35.292908],[-89.554307,35.259427],[-89.561292,35.17148]]]},"properties":{"id":"38060","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.494043,35.143558],[-89.475586,35.070124],[-89.371806,35.146221],[-89.417695,35.181755],[-89.494043,35.143558]]]},"properties":{"id":"38076","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.243338,35.060624],[-89.251811,34.994549],[-89.104247,34.994727],[-89.111915,35.081508],[-89.24833,35.140249],[-89.298332,35.114617],[-89.274685,35.087769],[-89.247965,35.098431],[-89.243338,35.060624]]]},"properties":{"id":"38039","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.318761,34.991169],[-86.152814,34.989926],[-86.305164,35.109796],[-86.317837,35.063234],[-86.318761,34.991169]]]},"properties":{"id":"37345","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.107065,35.13555],[-87.151156,35.069162],[-87.140678,35.048706],[-87.089895,34.99659],[-86.904915,34.992738],[-86.904164,35.116373],[-87.107065,35.13555]]]},"properties":{"id":"38477","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.646616,36.242059],[-83.694907,36.249633],[-83.700205,36.207583],[-83.65185,36.236897],[-83.646616,36.242059]]]},"properties":{"id":"37848","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.922775,36.274249],[-83.036766,36.225169],[-83.030016,36.215088],[-83.067443,36.096604],[-83.055448,36.090668],[-82.925602,36.245836],[-82.922775,36.274249]]]},"properties":{"id":"37818","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.666069,36.346045],[-82.590488,36.156085],[-82.587992,36.320846],[-82.599925,36.353654],[-82.666069,36.346045]]]},"properties":{"id":"37681","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.141172,35.150371],[-85.14712,35.107594],[-85.045776,35.251126],[-85.047546,35.349318],[-85.131509,35.19069],[-85.141172,35.150371]]]},"properties":{"id":"37341","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.094769,35.431084],[-85.185003,35.374415],[-85.048312,35.358911],[-85.016343,35.409288],[-85.094769,35.431084]]]},"properties":{"id":"37373","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.417028,35.234735],[-85.32659,35.111072],[-85.279693,35.186104],[-85.233631,35.233333],[-85.289701,35.266402],[-85.392276,35.26626],[-85.417028,35.234735]]]},"properties":{"id":"37377","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.880379,35.288646],[-89.039275,35.359975],[-89.151905,35.196779],[-89.063051,35.168787],[-88.980681,35.140572],[-88.858518,35.212698],[-88.819849,35.294699],[-88.880379,35.288646]]]},"properties":{"id":"38008","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.858518,35.212698],[-88.980681,35.140572],[-89.051488,34.99483],[-88.858245,34.995136],[-88.786703,35.105419],[-88.858518,35.212698]]]},"properties":{"id":"38052","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.374265,35.127323],[-88.363211,35.124113],[-88.317973,35.160014],[-88.257804,35.224595],[-88.289969,35.27127],[-88.38873,35.351782],[-88.453107,35.358172],[-88.479539,35.341755],[-88.529047,35.227637],[-88.442059,35.198671],[-88.37374,35.140608],[-88.374265,35.127323]]]},"properties":{"id":"38310","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.353729,35.72643],[-89.469993,35.595226],[-89.502118,35.580621],[-89.469862,35.546566],[-89.253441,35.523005],[-89.078847,35.432067],[-89.074766,35.530626],[-89.073463,35.572166],[-89.268053,35.754733],[-89.342548,35.792205],[-89.353729,35.72643]]]},"properties":{"id":"38012","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.56392,35.6925],[-88.546742,35.592147],[-88.474868,35.498374],[-88.422582,35.530272],[-88.56392,35.6925]]]},"properties":{"id":"38345","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.252119,35.731071],[-88.233958,35.615672],[-88.18992,35.617591],[-88.195804,35.731774],[-88.252119,35.731071]]]},"properties":{"id":"38328","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.687119,36.21743],[-87.743055,36.008753],[-87.685046,35.884371],[-87.636567,35.896609],[-87.531688,35.944137],[-87.566798,36.177857],[-87.687119,36.21743]]]},"properties":{"id":"37101","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.743055,36.008753],[-87.818202,35.836779],[-87.71676,35.83875],[-87.685046,35.884371],[-87.743055,36.008753]]]},"properties":{"id":"37078","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.803693,36.255669],[-85.65798,36.275042],[-85.614536,36.247045],[-85.542338,36.353695],[-85.544426,36.445285],[-85.608891,36.481963],[-85.609157,36.481862],[-85.787372,36.374675],[-85.829747,36.348543],[-85.846483,36.288167],[-85.803693,36.255669]]]},"properties":{"id":"38562","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.707982,36.524192],[-81.770529,36.612922],[-81.79679,36.573604],[-81.707982,36.524192]]]},"properties":{"id":"37680","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.988843,36.152907],[-83.990775,36.049639],[-83.865866,36.113714],[-83.867769,36.168112],[-83.939575,36.184183],[-83.941711,36.186378],[-83.988843,36.152907]]]},"properties":{"id":"37938","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.699988,35.101519],[-85.549263,35.020669],[-85.508313,35.074458],[-85.575695,35.103708],[-85.699988,35.101519]]]},"properties":{"id":"37347","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.177627,35.838738],[-87.20078,35.777853],[-87.178813,35.687028],[-87.09569,35.812471],[-87.177627,35.838738]]]},"properties":{"id":"38482","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.615044,36.5419],[-87.712813,36.561402],[-87.726383,36.561215],[-87.728397,36.560518],[-87.703334,36.439938],[-87.602597,36.418979],[-87.529594,36.458361],[-87.615044,36.5419]]]},"properties":{"id":"37079","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.390062,35.946987],[-84.421099,36.023627],[-84.4312,36.019943],[-84.429657,36.029121],[-84.509579,36.053133],[-84.569431,36.031867],[-84.620887,35.932199],[-84.595223,35.843771],[-84.438401,35.909706],[-84.390062,35.946987]]]},"properties":{"id":"37748","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.445245,36.095101],[-84.437346,36.085767],[-84.429563,36.097142],[-84.442179,36.102347],[-84.445245,36.095101]]]},"properties":{"id":"37845","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.700785,36.370824],[-84.731598,36.350655],[-84.711432,36.325171],[-84.711323,36.324179],[-84.700785,36.370824]]]},"properties":{"id":"37733","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.985767,36.502345],[-89.346066,36.503232],[-89.373495,36.388405],[-89.296404,36.442205],[-89.103726,36.342853],[-88.954084,36.328745],[-88.950205,36.409248],[-88.985767,36.502345]]]},"properties":{"id":"38261","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.137455,36.290453],[-85.180021,36.314611],[-85.194916,36.301253],[-85.207886,36.25802],[-85.100385,36.241535],[-85.137455,36.290453]]]},"properties":{"id":"38554","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.507736,36.174945],[-85.503555,36.1716],[-85.501449,36.176246],[-85.507736,36.174945]]]},"properties":{"id":"38505","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.712885,36.473557],[-86.920403,36.386734],[-86.89059,36.387065],[-86.689492,36.431003],[-86.712885,36.473557]]]},"properties":{"id":"37073","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.640655,36.600943],[-86.736778,36.586395],[-86.717325,36.548554],[-86.656388,36.510329],[-86.623751,36.563278],[-86.640655,36.600943]]]},"properties":{"id":"37049","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.736778,36.586395],[-86.640655,36.600943],[-86.679071,36.650164],[-86.729415,36.649001],[-86.736778,36.586395]]]},"properties":{"id":"37141","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.378825,35.974205],[-86.400734,35.815715],[-86.397123,35.809169],[-86.251784,35.826594],[-86.236234,35.8632],[-86.235291,35.875782],[-86.378825,35.974205]],[[-86.37092,35.843453],[-86.370385,35.855025],[-86.358016,35.852747],[-86.360461,35.845989],[-86.37092,35.843453]]]},"properties":{"id":"37130","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.733495,35.047136],[-89.731369,35.107531],[-89.743553,35.107269],[-89.786097,35.109352],[-89.739896,35.047213],[-89.733495,35.047136]]]},"properties":{"id":"38139","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.987212,35.063705],[-89.972197,34.99502],[-89.865146,34.994259],[-89.886162,35.034996],[-89.906984,35.078277],[-89.920774,35.078384],[-89.955955,35.080586],[-89.985583,35.076179],[-89.987212,35.063705]]]},"properties":{"id":"38118","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.311111,36.595609],[-82.283996,36.527892],[-82.075348,36.458612],[-81.983843,36.508314],[-81.826742,36.614215],[-82.311111,36.595609]]]},"properties":{"id":"37620","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.848932,35.40857],[-89.851855,35.39874],[-89.846746,35.399227],[-89.848932,35.40857]]],[[[-89.770649,35.5153],[-89.84389,35.495855],[-89.848976,35.409823],[-89.759317,35.465513],[-89.770649,35.5153]]]]},"properties":{"id":"38058","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.84389,35.495855],[-89.83901,35.514823],[-89.907412,35.523575],[-90.046598,35.412966],[-89.851855,35.39874],[-89.848932,35.40857],[-89.848976,35.409823],[-89.84389,35.495855]]]},"properties":{"id":"38023","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.682095,35.831254],[-85.587586,35.803348],[-85.581683,35.8692],[-85.651662,35.904993],[-85.682095,35.831254]]]},"properties":{"id":"38587","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.66047,35.692544],[-86.76748,35.702365],[-86.794697,35.66759],[-86.641548,35.580651],[-86.595953,35.669112],[-86.66047,35.692544]]]},"properties":{"id":"37034","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.595953,35.669112],[-86.641548,35.580651],[-86.598139,35.542658],[-86.54108,35.600884],[-86.539532,35.68129],[-86.599436,35.674206],[-86.595953,35.669112]]]},"properties":{"id":"37180","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.521674,35.72441],[-86.602829,35.838601],[-86.613531,35.809965],[-86.599436,35.674206],[-86.539532,35.68129],[-86.519121,35.680556],[-86.521674,35.72441]]]},"properties":{"id":"37153","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.866416,35.556849],[-85.087121,35.587647],[-85.160093,35.519838],[-85.094769,35.431084],[-85.016343,35.409288],[-84.981884,35.437248],[-84.866967,35.554066],[-84.866416,35.556849]]]},"properties":{"id":"37321","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.866416,35.556849],[-85.061247,35.642482],[-85.087121,35.587647],[-84.866416,35.556849]]]},"properties":{"id":"37332","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.952648,35.863696],[-84.000987,35.881795],[-84.02875,35.875082],[-84.14252,35.796954],[-84.065191,35.786694],[-84.01174,35.79803],[-83.960124,35.828245],[-83.952648,35.863696]]]},"properties":{"id":"37777","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.701985,35.716017],[-83.873744,35.678058],[-83.981934,35.511638],[-83.662957,35.569138],[-83.662814,35.690618],[-83.701985,35.716017]]]},"properties":{"id":"37882","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.977986,36.553446],[-83.973951,36.547369],[-83.975095,36.539681],[-83.960528,36.508311],[-83.912563,36.574841],[-83.910903,36.587075],[-84.020983,36.590088],[-84.039962,36.561103],[-83.977986,36.553446]]]},"properties":{"id":"37715","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.565787,36.013258],[-88.53818,36.023853],[-88.572582,36.016887],[-88.565787,36.013258]]],[[[-88.664663,36.065578],[-88.62689,35.97959],[-88.573866,36.017007],[-88.664663,36.065578]]]]},"properties":{"id":"38258","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.697833,35.942171],[-88.706811,35.791075],[-88.664742,35.790583],[-88.620926,35.810595],[-88.616849,35.934106],[-88.697833,35.942171]]]},"properties":{"id":"38348","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.309053,35.91731],[-88.216512,35.893774],[-88.215023,35.953049],[-88.214235,35.994718],[-88.260982,36.027604],[-88.285911,36.028155],[-88.309793,36.001587],[-88.309053,35.91731]]]},"properties":{"id":"38318","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.987462,36.644337],[-87.126,36.478102],[-87.120443,36.45546],[-87.073559,36.427653],[-86.991269,36.441895],[-86.981675,36.644355],[-86.987462,36.644337]]]},"properties":{"id":"37032","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.975095,36.539681],[-83.973951,36.547369],[-83.977986,36.553446],[-83.975095,36.539681]]]},"properties":{"id":"37730","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.101524,36.594039],[-83.305657,36.597972],[-83.361416,36.422287],[-83.153422,36.453245],[-83.087468,36.472022],[-83.127148,36.537495],[-83.101524,36.594039]]]},"properties":{"id":"37869","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.708926,36.548259],[-85.609157,36.481862],[-85.608891,36.481963],[-85.593358,36.616588],[-85.69393,36.618905],[-85.708926,36.548259]]]},"properties":{"id":"38575","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.470513,35.832574],[-89.342548,35.792205],[-89.268053,35.754733],[-89.242294,35.799575],[-89.330502,35.882129],[-89.435038,35.996872],[-89.609164,35.919935],[-89.470513,35.832574]]]},"properties":{"id":"38040","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.701649,36.009349],[-86.681889,35.989399],[-86.607743,35.981573],[-86.546879,36.078406],[-86.610364,36.13206],[-86.609517,36.115741],[-86.671364,36.077233],[-86.701649,36.009349]]]},"properties":{"id":"37013","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.777498,36.161456],[-86.774604,36.155792],[-86.769523,36.160636],[-86.780591,36.175963],[-86.78531,36.174862],[-86.784352,36.169637],[-86.783761,36.16874],[-86.780696,36.166357],[-86.780334,36.165811],[-86.777498,36.161456]]]},"properties":{"id":"37201","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.792323,36.159459],[-86.791186,36.158132],[-86.789513,36.158968],[-86.791758,36.161606],[-86.792323,36.159459]]]},"properties":{"id":"37246","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.376344,36.234828],[-87.533868,36.314204],[-87.568683,36.279507],[-87.541735,36.215309],[-87.386435,36.198021],[-87.376344,36.234828]]]},"properties":{"id":"37181","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.854425,35.276365],[-89.887458,35.274997],[-89.882234,35.259919],[-89.854425,35.276365]]],[[[-89.819049,35.239164],[-89.793457,35.237473],[-89.772488,35.204396],[-89.706644,35.194755],[-89.554307,35.259427],[-89.573398,35.292908],[-89.632305,35.310435],[-89.635762,35.316399],[-89.61584,35.329511],[-89.642796,35.387983],[-89.699911,35.364197],[-89.854425,35.276365],[-89.823275,35.241507],[-89.819049,35.239164]]]]},"properties":{"id":"38002","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.58259,34.995065],[-89.484462,34.993968],[-89.475586,35.070124],[-89.494043,35.143558],[-89.561292,35.17148],[-89.57174,35.158224],[-89.58259,34.995065]]]},"properties":{"id":"38066","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.860632,36.569107],[-85.070543,36.621248],[-85.044052,36.539535],[-84.860632,36.569107]]]},"properties":{"id":"38577","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.067137,34.989514],[-85.863935,34.988253],[-85.863638,35.087142],[-85.943849,35.125286],[-86.032771,35.096864],[-86.067137,34.989514]]]},"properties":{"id":"37376","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.370547,35.144251],[-86.466255,35.053163],[-86.522708,34.990716],[-86.439779,34.990984],[-86.317837,35.063234],[-86.305164,35.109796],[-86.370547,35.144251]]]},"properties":{"id":"37335","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.046286,35.188467],[-85.948474,35.131972],[-85.978947,35.216351],[-86.046286,35.188467]]]},"properties":{"id":"37318","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.870412,35.935063],[-88.807304,35.869997],[-88.706811,35.791075],[-88.697833,35.942171],[-88.733057,36.034022],[-88.793187,35.993749],[-88.870412,35.935063]]]},"properties":{"id":"38358","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.114078,36.136604],[-89.147632,36.029201],[-88.915248,36.081014],[-88.954511,36.110885],[-89.056689,36.119524],[-89.114078,36.136604]]]},"properties":{"id":"38330","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.128552,35.456004],[-87.151173,35.369245],[-87.140836,35.350704],[-86.911182,35.323004],[-86.907526,35.380296],[-87.128552,35.456004]]]},"properties":{"id":"38472","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.107065,35.13555],[-86.904164,35.116373],[-86.865804,35.113175],[-86.83119,35.163286],[-86.8473,35.218549],[-86.828301,35.263677],[-86.911182,35.323004],[-87.140836,35.350704],[-87.175379,35.301162],[-87.186912,35.169584],[-87.168248,35.149216],[-87.107065,35.13555]]]},"properties":{"id":"38478","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.140678,35.048706],[-87.094472,34.996673],[-87.089895,34.99659],[-87.140678,35.048706]]],[[[-87.168248,35.149216],[-87.223206,35.059201],[-87.151156,35.069162],[-87.107065,35.13555],[-87.168248,35.149216]]]]},"properties":{"id":"38460","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.911182,35.323004],[-86.828301,35.263677],[-86.744763,35.324177],[-86.882026,35.407604],[-86.907526,35.380296],[-86.911182,35.323004]]]},"properties":{"id":"37047","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.895101,35.510014],[-87.146385,35.494733],[-87.128552,35.456004],[-86.907526,35.380296],[-86.882026,35.407604],[-86.895101,35.510014]]]},"properties":{"id":"38451","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.036766,36.225169],[-83.100925,36.228401],[-83.124842,36.201287],[-83.166085,36.180894],[-83.125287,36.122525],[-83.030016,36.215088],[-83.036766,36.225169]]]},"properties":{"id":"37810","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.800448,35.494406],[-85.888242,35.456661],[-85.869478,35.380435],[-85.770083,35.351068],[-85.689436,35.429362],[-85.777877,35.470735],[-85.800448,35.494406]]]},"properties":{"id":"37301","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.182491,36.336192],[-83.124842,36.201287],[-83.100925,36.228401],[-83.094955,36.342574],[-83.096631,36.380751],[-83.182491,36.336192]]]},"properties":{"id":"37891","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.196442,35.10667],[-85.279693,35.186104],[-85.32659,35.111072],[-85.279015,35.081452],[-85.264903,35.093863],[-85.265662,35.106538],[-85.252595,35.099277],[-85.22913,35.106196],[-85.196442,35.10667]]]},"properties":{"id":"37415","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.281125,34.984881],[-85.300318,35.024086],[-85.304117,35.022953],[-85.305462,34.984475],[-85.281125,34.984881]]]},"properties":{"id":"37407","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.474073,34.98302],[-85.379718,34.983035],[-85.333135,35.01867],[-85.332801,35.020738],[-85.410937,35.118314],[-85.508313,35.074458],[-85.490648,35.034669],[-85.474073,34.98302]]]},"properties":{"id":"37419","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.053501,35.045058],[-85.05014,35.046962],[-85.048297,35.051808],[-85.051628,35.051611],[-85.053946,35.048022],[-85.053501,35.045058]]]},"properties":{"id":"37315","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.051488,34.99483],[-88.980681,35.140572],[-89.063051,35.168787],[-89.111915,35.081508],[-89.104247,34.994727],[-89.051488,34.99483]]]},"properties":{"id":"38067","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.015934,35.324174],[-88.065773,35.335802],[-87.983777,35.164197],[-87.934774,35.155519],[-87.935824,35.234328],[-88.015934,35.324174]]]},"properties":{"id":"38475","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.645313,36.564794],[-82.6791,36.577727],[-82.673753,36.53513],[-82.645313,36.564794]]]},"properties":{"id":"37645","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.509595,36.271267],[-88.323663,36.222432],[-88.226522,36.196042],[-88.192961,36.35574],[-88.230822,36.394609],[-88.435562,36.396378],[-88.522585,36.325743],[-88.582199,36.35017],[-88.55038,36.3024],[-88.524857,36.267411],[-88.509595,36.271267]]]},"properties":{"id":"38242","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.636567,35.896609],[-87.633221,35.822578],[-87.397499,35.858458],[-87.374011,35.928051],[-87.468182,35.932184],[-87.531688,35.944137],[-87.636567,35.896609]]]},"properties":{"id":"37137","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.183373,35.911831],[-87.229448,35.871022],[-87.285084,35.795491],[-87.20078,35.777853],[-87.177627,35.838738],[-87.134043,35.910318],[-87.183373,35.911831]]]},"properties":{"id":"38476","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.788694,36.046437],[-83.755154,36.005256],[-83.689562,35.977833],[-83.615896,36.017688],[-83.628063,36.113443],[-83.66746,36.080587],[-83.788694,36.046437]]]},"properties":{"id":"37871","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.992968,36.433112],[-81.862613,36.330508],[-81.793089,36.362462],[-81.738656,36.401205],[-81.707982,36.524192],[-81.79679,36.573604],[-81.951482,36.473863],[-81.992968,36.433112]]]},"properties":{"id":"37683","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.031996,36.008481],[-84.061974,35.995847],[-84.05441,35.970311],[-84.049268,35.963101],[-83.952412,35.962648],[-83.946435,35.958284],[-83.928978,35.964319],[-83.925062,35.966904],[-83.958443,36.000094],[-84.031996,36.008481]]]},"properties":{"id":"37921","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.058707,35.925256],[-84.042002,35.894089],[-84.02875,35.875082],[-84.000987,35.881795],[-83.946103,35.947046],[-83.946435,35.958284],[-83.952412,35.962648],[-84.058707,35.925256]]]},"properties":{"id":"37919","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.946435,35.958284],[-83.946103,35.947046],[-83.9201,35.955505],[-83.928978,35.964319],[-83.946435,35.958284]]]},"properties":{"id":"37916","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.475501,36.328468],[-89.475508,36.326319],[-89.473343,36.326234],[-89.473143,36.329735],[-89.475501,36.328468]]]},"properties":{"id":"38077","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.750344,35.00444],[-87.522815,35.003052],[-87.505789,35.066246],[-87.532309,35.083888],[-87.639962,35.163022],[-87.714991,35.142672],[-87.750344,35.00444]]]},"properties":{"id":"38463","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.602428,35.293911],[-84.815881,35.377774],[-84.804021,35.325783],[-84.798645,35.316068],[-84.625067,35.275539],[-84.609021,35.282443],[-84.602428,35.293911]]]},"properties":{"id":"37370","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.332139,36.445707],[-86.24968,36.397618],[-86.218847,36.502377],[-86.384351,36.604427],[-86.399452,36.480511],[-86.332139,36.445707]]]},"properties":{"id":"37022","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.506528,35.357477],[-85.632885,35.3037],[-85.645353,35.301997],[-85.575695,35.103708],[-85.508313,35.074458],[-85.410937,35.118314],[-85.417028,35.234735],[-85.392276,35.26626],[-85.506528,35.357477]]]},"properties":{"id":"37397","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.490648,35.034669],[-85.508313,35.074458],[-85.549263,35.020669],[-85.510733,34.996291],[-85.490648,35.034669]]]},"properties":{"id":"37340","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.502937,36.551885],[-87.466095,36.449621],[-87.339338,36.641637],[-87.436324,36.640846],[-87.427712,36.601971],[-87.465846,36.588613],[-87.502937,36.551885]]]},"properties":{"id":"37042","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.494864,36.365569],[-87.33601,36.316826],[-87.338164,36.391097],[-87.429746,36.421956],[-87.494864,36.365569]]]},"properties":{"id":"37052","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.711432,36.325171],[-84.731598,36.350655],[-84.739936,36.33664],[-84.711432,36.325171]]],[[[-84.624955,36.425983],[-84.700785,36.370824],[-84.711323,36.324179],[-84.612359,36.321552],[-84.608525,36.332894],[-84.599522,36.33113],[-84.600212,36.316676],[-84.541411,36.22957],[-84.471037,36.203778],[-84.481478,36.326562],[-84.596738,36.431581],[-84.624955,36.425983]]]]},"properties":{"id":"37852","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.209382,36.625599],[-85.2047,36.621404],[-85.190361,36.625277],[-85.209382,36.625599]]]},"properties":{"id":"42602","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537054,35.226439],[-84.66184,35.212852],[-84.701909,35.187903],[-84.709928,35.14405],[-84.625631,35.096888],[-84.605593,35.096306],[-84.537054,35.226439]]]},"properties":{"id":"37307","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.411475,35.73128],[-86.267357,35.727185],[-86.251784,35.826594],[-86.397123,35.809169],[-86.411475,35.73128]]]},"properties":{"id":"37127","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.529396,35.743591],[-83.662814,35.690618],[-83.662957,35.569138],[-83.255489,35.714974],[-83.329433,35.778868],[-83.529396,35.743591]]]},"properties":{"id":"37738","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.968761,35.254472],[-90.063259,35.210772],[-90.064414,35.178953],[-90.050919,35.185509],[-90.017952,35.186761],[-89.964388,35.182964],[-89.968761,35.254472]]]},"properties":{"id":"38127","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.894187,35.22134],[-89.834406,35.205482],[-89.793457,35.237473],[-89.819049,35.239164],[-89.819228,35.236575],[-89.825053,35.238575],[-89.823275,35.241507],[-89.854425,35.276365],[-89.882234,35.259919],[-89.894187,35.22134]]]},"properties":{"id":"38135","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.049295,35.16382],[-90.050919,35.185509],[-90.064414,35.178953],[-90.075977,35.127804],[-90.059127,35.127119],[-90.031007,35.135197],[-90.027612,35.142702],[-90.049295,35.16382]]]},"properties":{"id":"38103","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.831252,35.034976],[-89.886162,35.034996],[-89.865146,34.994259],[-89.83069,34.994498],[-89.831252,35.034976]]]},"properties":{"id":"38141","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.00328,36.30274],[-85.999816,36.374466],[-86.070425,36.338914],[-86.062294,36.271667],[-86.00328,36.30274]]]},"properties":{"id":"37151","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.562352,36.434658],[-82.517134,36.425436],[-82.420713,36.446966],[-82.40445,36.45237],[-82.476583,36.497827],[-82.563048,36.435158],[-82.562352,36.434658]]]},"properties":{"id":"37663","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.391561,36.19925],[-82.325764,36.119001],[-82.222052,36.156911],[-82.226651,36.17805],[-82.254955,36.224848],[-82.269894,36.239345],[-82.322718,36.259196],[-82.355761,36.242807],[-82.391561,36.19925]]]},"properties":{"id":"37692","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.167506,36.185107],[-84.127972,36.197109],[-84.087386,36.20471],[-84.074534,36.212136],[-84.09196,36.222613],[-84.141758,36.260028],[-84.226366,36.2206],[-84.167506,36.185107]]]},"properties":{"id":"37769","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.032945,36.174197],[-84.094338,36.070904],[-83.988843,36.152907],[-83.941711,36.186378],[-84.032945,36.174197]]]},"properties":{"id":"37754","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.324056,35.456861],[-86.257364,35.373014],[-86.19342,35.450167],[-86.23756,35.507667],[-86.313164,35.458294],[-86.324056,35.456861]]]},"properties":{"id":"37360","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.34804,35.342168],[-86.279538,35.271137],[-86.234053,35.24574],[-86.127025,35.324268],[-86.158018,35.362607],[-86.19342,35.450167],[-86.257364,35.373014],[-86.328496,35.406045],[-86.34804,35.342168]]]},"properties":{"id":"37388","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.613531,35.809965],[-86.66047,35.692544],[-86.595953,35.669112],[-86.599436,35.674206],[-86.613531,35.809965]]]},"properties":{"id":"37060","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.061247,35.642482],[-84.980258,35.757071],[-84.981428,35.791471],[-85.04114,35.77177],[-85.254062,35.765611],[-85.423804,35.567422],[-85.222077,35.500744],[-85.160093,35.519838],[-85.087121,35.587647],[-85.061247,35.642482]]]},"properties":{"id":"37367","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.980258,35.757071],[-84.77333,35.80884],[-84.818517,35.856203],[-84.865874,35.847961],[-84.981428,35.791471],[-84.980258,35.757071]]]},"properties":{"id":"37337","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.065191,35.786694],[-84.14252,35.796954],[-84.166267,35.805106],[-84.170372,35.735914],[-84.098498,35.721678],[-84.065191,35.786694]]]},"properties":{"id":"37737","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.969212,35.753437],[-84.094249,35.55402],[-84.050488,35.546055],[-83.901985,35.671564],[-83.857689,35.750542],[-83.969212,35.753437]]]},"properties":{"id":"37803","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.857164,35.841495],[-83.952648,35.863696],[-83.960124,35.828245],[-83.964424,35.805688],[-83.848944,35.835285],[-83.857164,35.841495]]]},"properties":{"id":"37853","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.987208,35.031004],[-84.968828,35.226789],[-84.978813,35.225826],[-84.98074,35.220424],[-85.036103,35.050844],[-84.987208,35.031004]]]},"properties":{"id":"37353","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.449719,36.4047],[-84.331425,36.323991],[-84.227051,36.330143],[-84.142097,36.548635],[-84.163917,36.536601],[-84.252826,36.529885],[-84.332789,36.502115],[-84.459962,36.426565],[-84.449719,36.4047]]]},"properties":{"id":"37847","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.154888,35.876697],[-86.128457,35.743673],[-86.018637,35.717672],[-85.967001,35.719757],[-85.897178,35.846822],[-85.925497,35.887154],[-86.014849,35.96106],[-86.148161,35.922958],[-86.154888,35.876697]]]},"properties":{"id":"37190","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.541493,35.586295],[-85.74967,35.803758],[-85.897178,35.846822],[-85.967001,35.719757],[-85.800448,35.494406],[-85.777877,35.470735],[-85.644493,35.437308],[-85.623652,35.45412],[-85.537985,35.445394],[-85.492257,35.54757],[-85.541493,35.586295]]]},"properties":{"id":"37110","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.128457,35.743673],[-86.154888,35.876697],[-86.236234,35.8632],[-86.251784,35.826594],[-86.267357,35.727185],[-86.195462,35.741432],[-86.128457,35.743673]]]},"properties":{"id":"37149","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.96702,35.662217],[-88.67615,35.647841],[-88.608804,35.709116],[-88.600001,35.789195],[-88.620926,35.810595],[-88.664742,35.790583],[-88.735085,35.738592],[-88.83583,35.741397],[-88.968299,35.717485],[-88.96702,35.662217]]]},"properties":{"id":"38305","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.322718,36.259196],[-82.407513,36.378081],[-82.437679,36.338791],[-82.355761,36.242807],[-82.322718,36.259196]],[[-82.374187,36.302772],[-82.367743,36.306839],[-82.362331,36.303672],[-82.372438,36.297675],[-82.374187,36.302772]]]},"properties":{"id":"37604","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.073559,36.427653],[-87.082334,36.364508],[-87.010912,36.362177],[-86.983709,36.371452],[-86.991269,36.441895],[-87.073559,36.427653]]]},"properties":{"id":"37146","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.688221,35.311767],[-88.479539,35.341755],[-88.453107,35.358172],[-88.497911,35.385026],[-88.688221,35.311767]]]},"properties":{"id":"38334","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.819849,35.294699],[-88.858518,35.212698],[-88.786703,35.105419],[-88.744425,35.158398],[-88.75486,35.201121],[-88.769455,35.264321],[-88.819849,35.294699]]]},"properties":{"id":"38044","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.624163,36.343206],[-83.679649,36.482212],[-83.842506,36.403916],[-83.733054,36.345043],[-83.667408,36.344381],[-83.624163,36.343206]]]},"properties":{"id":"37825","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.17551,36.12667],[-83.080676,36.051099],[-83.055249,36.087069],[-83.055448,36.090668],[-83.067443,36.096604],[-83.125287,36.122525],[-83.166085,36.180894],[-83.185014,36.176866],[-83.17551,36.12667]]]},"properties":{"id":"37713","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.176592,35.812715],[-83.297483,35.902981],[-83.310782,35.895846],[-83.329433,35.778868],[-83.255489,35.714974],[-83.099399,35.775076],[-83.176592,35.812715]]]},"properties":{"id":"37722","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.055249,36.087069],[-82.962862,35.972604],[-82.898997,35.943963],[-82.647096,36.05984],[-82.682826,36.090847],[-82.749687,36.140935],[-82.925602,36.245836],[-83.055448,36.090668],[-83.055249,36.087069]]]},"properties":{"id":"37743","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.197841,35.987549],[-85.197972,35.977679],[-85.190385,35.981463],[-85.197841,35.987549]]]},"properties":{"id":"38578","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.802939,36.089357],[-86.79171,36.116334],[-86.816625,36.12976],[-86.869489,36.076344],[-86.886642,36.043151],[-86.875333,36.041374],[-86.812735,36.065644],[-86.802939,36.089357]]]},"properties":{"id":"37215","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596866,35.95644],[-86.607743,35.981573],[-86.681889,35.989399],[-86.715379,35.953481],[-86.734428,35.897317],[-86.615132,35.88944],[-86.596866,35.95644]]]},"properties":{"id":"37135","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.863263,35.779173],[-86.837374,35.74659],[-86.817009,35.775162],[-86.863263,35.779173]]],[[[-87.09569,35.812471],[-87.032999,35.842853],[-86.774165,35.813944],[-86.74006,35.850847],[-86.846992,35.944844],[-86.962677,35.997074],[-87.046006,36.011946],[-87.134043,35.910318],[-87.177627,35.838738],[-87.09569,35.812471]]]]},"properties":{"id":"37064","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.97298,36.128555],[-86.059706,36.086024],[-86.05693,36.004414],[-86.044785,35.990662],[-85.916274,36.093234],[-85.97298,36.128555]]]},"properties":{"id":"37012","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.554307,35.259427],[-89.706644,35.194755],[-89.709741,35.158839],[-89.743553,35.107269],[-89.731369,35.107531],[-89.57174,35.158224],[-89.561292,35.17148],[-89.554307,35.259427]]]},"properties":{"id":"38028","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.733495,35.047136],[-89.751279,34.994746],[-89.58259,34.995065],[-89.57174,35.158224],[-89.731369,35.107531],[-89.733495,35.047136]]]},"properties":{"id":"38017","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.253441,35.523005],[-89.22041,35.397776],[-89.191556,35.17751],[-89.151905,35.196779],[-89.039275,35.359975],[-89.076807,35.431396],[-89.078847,35.432067],[-89.253441,35.523005]]]},"properties":{"id":"38075","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.305164,35.109796],[-86.152814,34.989926],[-86.067137,34.989514],[-86.032771,35.096864],[-86.330681,35.193835],[-86.369862,35.161194],[-86.370547,35.144251],[-86.305164,35.109796]]]},"properties":{"id":"37306","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.870412,35.935063],[-88.793187,35.993749],[-88.915248,36.081014],[-89.147632,36.029201],[-89.206297,36.011814],[-89.129701,35.899882],[-89.065603,35.851514],[-88.870412,35.935063]]]},"properties":{"id":"38382","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.904915,34.992738],[-86.72891,34.991955],[-86.734678,35.083744],[-86.777197,35.108987],[-86.865804,35.113175],[-86.904164,35.116373],[-86.904915,34.992738]]]},"properties":{"id":"38449","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.528262,36.162559],[-83.467097,36.174688],[-83.433432,36.209889],[-83.384751,36.265588],[-83.440097,36.335633],[-83.487854,36.322706],[-83.646616,36.242059],[-83.65185,36.236897],[-83.601383,36.153626],[-83.528262,36.162559]]]},"properties":{"id":"37861","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.711549,36.330433],[-82.682826,36.090847],[-82.647096,36.05984],[-82.602877,36.039833],[-82.5772,36.078387],[-82.553596,36.128754],[-82.590488,36.156085],[-82.666069,36.346045],[-82.709122,36.406283],[-82.721894,36.403904],[-82.711549,36.330433]]]},"properties":{"id":"37641","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.711549,36.330433],[-82.749687,36.140935],[-82.682826,36.090847],[-82.711549,36.330433]]]},"properties":{"id":"37616","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.045776,35.251126],[-85.14712,35.107594],[-85.159614,35.072505],[-85.118297,34.986531],[-85.064833,34.986648],[-85.036103,35.050844],[-84.98074,35.220424],[-85.02356,35.245551],[-85.045776,35.251126]],[[-85.053501,35.045058],[-85.053946,35.048022],[-85.051628,35.051611],[-85.048297,35.051808],[-85.05014,35.046962],[-85.053501,35.045058]]]},"properties":{"id":"37363","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.278243,35.049491],[-85.24914,35.049174],[-85.214074,35.04913],[-85.211001,35.068414],[-85.22913,35.106196],[-85.252595,35.099277],[-85.264903,35.093863],[-85.279015,35.081452],[-85.288768,35.055787],[-85.278243,35.049491]]]},"properties":{"id":"37406","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.333135,35.01867],[-85.379718,34.983035],[-85.364758,34.983375],[-85.347327,35.00633],[-85.338553,34.995567],[-85.344391,34.983665],[-85.327428,34.983976],[-85.320229,35.016922],[-85.333135,35.01867]]]},"properties":{"id":"37409","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.279693,35.186104],[-85.196442,35.10667],[-85.141172,35.150371],[-85.131509,35.19069],[-85.233631,35.233333],[-85.279693,35.186104]]]},"properties":{"id":"37343","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.344391,34.983665],[-85.338553,34.995567],[-85.347327,35.00633],[-85.364758,34.983375],[-85.344391,34.983665]]]},"properties":{"id":"37350","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.983777,35.164197],[-88.065773,35.335802],[-88.051905,35.410866],[-88.196462,35.379561],[-88.203878,35.356595],[-88.289969,35.27127],[-88.257804,35.224595],[-88.316256,35.15971],[-88.24864,35.066732],[-88.248566,35.066756],[-88.202959,35.008028],[-87.984916,35.005933],[-87.983777,35.164197]]]},"properties":{"id":"38372","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.087468,36.472022],[-83.153422,36.453245],[-83.192574,36.424516],[-83.096631,36.380751],[-83.094955,36.342574],[-82.929689,36.33332],[-82.721894,36.403904],[-82.709122,36.406283],[-82.680677,36.4318],[-82.671862,36.531779],[-82.8296,36.460738],[-82.942691,36.546294],[-83.087468,36.472022]]]},"properties":{"id":"37857","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.192961,36.35574],[-88.226522,36.196042],[-88.168821,36.165978],[-88.147987,36.1947],[-87.99092,36.360133],[-88.040219,36.424898],[-88.192961,36.35574]]]},"properties":{"id":"38256","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.397499,35.858458],[-87.386063,35.794417],[-87.298927,35.781492],[-87.285084,35.795491],[-87.229448,35.871022],[-87.374011,35.928051],[-87.397499,35.858458]]]},"properties":{"id":"37098","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.733924,36.353944],[-87.770284,36.243089],[-87.687119,36.21743],[-87.566798,36.177857],[-87.541735,36.215309],[-87.568683,36.279507],[-87.564016,36.339567],[-87.733924,36.353944]]]},"properties":{"id":"37061","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.828026,36.411836],[-85.894979,36.454742],[-85.967456,36.424557],[-85.999816,36.374466],[-86.00328,36.30274],[-85.829747,36.348543],[-85.787372,36.374675],[-85.828026,36.411836]]]},"properties":{"id":"37145","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.528262,36.162559],[-83.601383,36.153626],[-83.628063,36.113443],[-83.615896,36.017688],[-83.573082,35.994455],[-83.504105,36.039141],[-83.528262,36.162559]]]},"properties":{"id":"37820","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.725223,36.15385],[-83.847991,36.182702],[-83.867769,36.168112],[-83.865866,36.113714],[-83.827389,36.074494],[-83.77604,36.082511],[-83.718702,36.123666],[-83.725223,36.15385]]]},"properties":{"id":"37721","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.958448,36.000262],[-83.998867,36.028961],[-84.031996,36.008481],[-83.958443,36.000094],[-83.958448,36.000262]]]},"properties":{"id":"37912","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.263383,35.89694],[-84.240811,35.8767],[-84.145251,35.906238],[-84.117147,35.912414],[-84.123046,35.938237],[-84.213782,35.950119],[-84.263383,35.89694]]]},"properties":{"id":"37932","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.939575,36.184183],[-83.867769,36.168112],[-83.847991,36.182702],[-83.723161,36.282217],[-83.667408,36.344381],[-83.733054,36.345043],[-83.93182,36.281253],[-83.939575,36.184183]]]},"properties":{"id":"37807","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.330989,35.056091],[-87.425442,35.14789],[-87.492635,35.12819],[-87.532309,35.083888],[-87.505789,35.066246],[-87.460806,35.002726],[-87.357598,35.001736],[-87.330989,35.056091]]]},"properties":{"id":"38469","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.437996,35.26727],[-86.478262,35.163436],[-86.369862,35.161194],[-86.330681,35.193835],[-86.365923,35.232706],[-86.437996,35.26727]]]},"properties":{"id":"37359","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551558,35.752099],[-84.629744,35.640016],[-84.587318,35.618965],[-84.311795,35.641927],[-84.502797,35.743099],[-84.551558,35.752099]]]},"properties":{"id":"37846","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.533244,35.369928],[-84.454713,35.318518],[-84.436868,35.323484],[-84.374209,35.389475],[-84.472116,35.459609],[-84.533244,35.369928]]]},"properties":{"id":"37329","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.051499,36.452033],[-86.095461,36.341934],[-86.070425,36.338914],[-85.999816,36.374466],[-85.967456,36.424557],[-86.051499,36.452033]]]},"properties":{"id":"37057","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.863935,34.988253],[-85.497794,34.983199],[-85.510733,34.996291],[-85.549263,35.020669],[-85.699988,35.101519],[-85.743067,35.215516],[-85.773489,35.218736],[-85.86327,35.183717],[-85.863638,35.087142],[-85.863935,34.988253]]]},"properties":{"id":"37380","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.729647,35.360437],[-86.681496,35.396514],[-86.741018,35.449984],[-86.729647,35.360437]]]},"properties":{"id":"37019","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.863263,35.779173],[-87.000427,35.778679],[-86.782016,35.706595],[-86.837374,35.74659],[-86.863263,35.779173]]]},"properties":{"id":"37174","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537054,35.226439],[-84.605593,35.096306],[-84.348223,35.205725],[-84.436868,35.323484],[-84.454713,35.318518],[-84.495529,35.285472],[-84.537054,35.226439]]]},"properties":{"id":"37369","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.126,36.478102],[-86.987462,36.644337],[-87.159928,36.642096],[-87.126,36.478102]]]},"properties":{"id":"37010","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.80546,36.090501],[-84.458336,36.186542],[-84.471037,36.203778],[-84.541411,36.22957],[-84.721172,36.203009],[-84.870888,36.136925],[-84.80546,36.090501]]]},"properties":{"id":"37770","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.354491,36.374146],[-89.341822,36.367906],[-89.340481,36.38088],[-89.354491,36.374146]]]},"properties":{"id":"38254","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.121619,36.450335],[-85.277299,36.593053],[-85.297798,36.476566],[-85.221517,36.400401],[-85.121619,36.450335]]]},"properties":{"id":"38573","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.302666,36.239036],[-85.207886,36.25802],[-85.194916,36.301253],[-85.225331,36.319487],[-85.375491,36.284206],[-85.302666,36.239036]]]},"properties":{"id":"38580","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.391564,35.053799],[-84.368776,35.029053],[-84.311373,35.068561],[-84.304838,35.121478],[-84.365904,35.140187],[-84.398081,35.101356],[-84.391564,35.053799]]]},"properties":{"id":"37391","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.519121,35.680556],[-86.279328,35.645648],[-86.207147,35.70379],[-86.195462,35.741432],[-86.267357,35.727185],[-86.411475,35.73128],[-86.521674,35.72441],[-86.519121,35.680556]]]},"properties":{"id":"37037","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.37092,35.843453],[-86.360461,35.845989],[-86.358016,35.852747],[-86.370385,35.855025],[-86.37092,35.843453]]]},"properties":{"id":"37132","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.520921,36.596423],[-84.731839,36.524674],[-84.692648,36.435777],[-84.624955,36.425983],[-84.596738,36.431581],[-84.459962,36.426565],[-84.332789,36.502115],[-84.520921,36.596423]]]},"properties":{"id":"37841","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.612359,36.321552],[-84.600212,36.316676],[-84.599522,36.33113],[-84.608525,36.332894],[-84.612359,36.321552]]]},"properties":{"id":"37732","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.846746,35.399227],[-89.723391,35.396329],[-89.759317,35.465513],[-89.848976,35.409823],[-89.848932,35.40857],[-89.846746,35.399227]]]},"properties":{"id":"38004","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.970055,35.134817],[-90.00885,35.154197],[-90.019465,35.155306],[-90.027612,35.142702],[-90.031007,35.135197],[-90.032388,35.124524],[-90.015448,35.117526],[-89.98035,35.115873],[-89.970055,35.134817]]]},"properties":{"id":"38104","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.032388,35.124524],[-90.059127,35.127119],[-90.075977,35.127804],[-90.188626,35.07573],[-90.044684,35.076666],[-90.022572,35.065607],[-90.00963,35.072927],[-90.015448,35.117526],[-90.032388,35.124524]]]},"properties":{"id":"38106","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.886864,35.109987],[-89.84554,35.09934],[-89.800972,35.116909],[-89.796971,35.131967],[-89.879538,35.156481],[-89.888165,35.148885],[-89.886864,35.109987]]]},"properties":{"id":"38120","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.906984,35.078277],[-89.886162,35.034996],[-89.831252,35.034976],[-89.828329,35.056243],[-89.892974,35.083347],[-89.906984,35.078277]]]},"properties":{"id":"38115","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.234962,36.004563],[-86.153443,35.982313],[-86.05693,36.004414],[-86.059706,36.086024],[-86.039057,36.171381],[-86.099596,36.192886],[-86.177246,36.190797],[-86.234962,36.004563]]]},"properties":{"id":"37184","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.039057,36.171381],[-86.059706,36.086024],[-85.97298,36.128555],[-85.970575,36.178832],[-86.039057,36.171381]]]},"properties":{"id":"38547","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.833092,36.194134],[-85.786487,36.23011],[-85.800221,36.254776],[-85.833092,36.194134]]]},"properties":{"id":"38552","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.400659,36.567591],[-82.605983,36.443495],[-82.563048,36.435158],[-82.476583,36.497827],[-82.400659,36.567591]]]},"properties":{"id":"37664","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.567729,35.804462],[-85.495565,35.768847],[-85.459845,35.799558],[-85.523621,35.855009],[-85.567729,35.804462]]]},"properties":{"id":"38559","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.664567,36.456805],[-88.582199,36.35017],[-88.522585,36.325743],[-88.516403,36.501583],[-88.580798,36.502099],[-88.664567,36.456805]]]},"properties":{"id":"38241","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.77713,36.287181],[-88.956614,36.27767],[-88.95977,36.223926],[-88.9407,36.203893],[-88.776109,36.218949],[-88.77713,36.287181]]]},"properties":{"id":"38255","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.127972,36.197109],[-84.079322,36.182761],[-84.087386,36.20471],[-84.127972,36.197109]]],[[[-84.062632,36.185875],[-84.032945,36.174197],[-83.941711,36.186378],[-83.939575,36.184183],[-83.93182,36.281253],[-83.948128,36.279761],[-84.025774,36.294179],[-84.072698,36.251989],[-84.09196,36.222613],[-84.074534,36.212136],[-84.062632,36.185875]]]]},"properties":{"id":"37705","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.062632,36.185875],[-84.074534,36.212136],[-84.087386,36.20471],[-84.079322,36.182761],[-84.062632,36.185875]]]},"properties":{"id":"37828","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.376813,35.563118],[-86.54108,35.600884],[-86.598139,35.542658],[-86.638547,35.428073],[-86.525306,35.354718],[-86.421117,35.321612],[-86.34804,35.342168],[-86.328496,35.406045],[-86.324056,35.456861],[-86.313164,35.458294],[-86.376813,35.563118]]]},"properties":{"id":"37160","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.578745,34.991085],[-86.522708,34.990716],[-86.466255,35.053163],[-86.478262,35.163436],[-86.437996,35.26727],[-86.421117,35.321612],[-86.525306,35.354718],[-86.748276,35.206324],[-86.788448,35.16464],[-86.777197,35.108987],[-86.734678,35.083744],[-86.578745,34.991085]]]},"properties":{"id":"37334","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.147987,36.1947],[-87.996829,36.141715],[-87.937603,36.192503],[-87.949603,36.24316],[-87.98074,36.353307],[-87.99092,36.360133],[-88.147987,36.1947]]]},"properties":{"id":"38221","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.181131,35.777242],[-88.083299,35.756226],[-87.939873,35.86712],[-87.93702,35.939943],[-88.019334,35.981686],[-88.215023,35.953049],[-88.216512,35.893774],[-88.222217,35.817759],[-88.181131,35.777242]]]},"properties":{"id":"38341","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.065191,35.786694],[-84.098498,35.721678],[-84.195961,35.617935],[-84.094249,35.55402],[-83.969212,35.753437],[-83.977059,35.757798],[-84.01174,35.79803],[-84.065191,35.786694]]]},"properties":{"id":"37801","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.89742,35.26621],[-84.777361,35.242127],[-84.701909,35.187903],[-84.66184,35.212852],[-84.798645,35.316068],[-84.804021,35.325783],[-84.851095,35.346489],[-84.89742,35.26621]]]},"properties":{"id":"37310","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.039962,36.561103],[-84.132639,36.553724],[-83.968983,36.480026],[-83.943139,36.473564],[-83.960528,36.508311],[-83.975095,36.539681],[-83.977986,36.553446],[-84.039962,36.561103]]]},"properties":{"id":"37729","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.197615,36.591845],[-84.163917,36.536601],[-84.142097,36.548635],[-84.132639,36.553724],[-84.039962,36.561103],[-84.020983,36.590088],[-84.197615,36.591845]]]},"properties":{"id":"37762","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.148161,35.922958],[-86.014849,35.96106],[-86.044785,35.990662],[-86.05693,36.004414],[-86.153443,35.982313],[-86.148161,35.922958]]]},"properties":{"id":"37016","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.018637,35.717672],[-86.128457,35.743673],[-86.195462,35.741432],[-86.207147,35.70379],[-86.120263,35.660929],[-86.08889,35.646823],[-86.018637,35.717672]]]},"properties":{"id":"37026","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.334447,35.868639],[-88.440858,35.869168],[-88.443103,35.822551],[-88.287353,35.819376],[-88.334447,35.868639]]]},"properties":{"id":"38390","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.122699,36.322985],[-82.254955,36.224848],[-82.226651,36.17805],[-82.044181,36.249141],[-82.122699,36.322985]]]},"properties":{"id":"37658","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.1517,36.425453],[-87.227411,36.324265],[-87.174058,36.313433],[-87.1517,36.425453]]],[[[-87.178306,36.228262],[-87.074167,36.16744],[-87.06106,36.146387],[-86.989551,36.177758],[-86.983613,36.209566],[-86.893426,36.266963],[-87.010912,36.362177],[-87.082334,36.364508],[-87.153043,36.30535],[-87.178306,36.228262]]]]},"properties":{"id":"37015","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.297801,36.317491],[-87.376344,36.234828],[-87.386435,36.198021],[-87.295428,36.128798],[-87.178306,36.228262],[-87.153043,36.30535],[-87.174058,36.313433],[-87.227411,36.324265],[-87.253922,36.373393],[-87.297801,36.317491]]]},"properties":{"id":"37036","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.227411,36.324265],[-87.1517,36.425453],[-87.120443,36.45546],[-87.126,36.478102],[-87.159928,36.642096],[-87.172343,36.642044],[-87.268939,36.402356],[-87.253922,36.373393],[-87.227411,36.324265]]]},"properties":{"id":"37043","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.835629,36.338354],[-86.89059,36.387065],[-86.920403,36.386734],[-86.983709,36.371452],[-87.010912,36.362177],[-86.893426,36.266963],[-86.891442,36.262053],[-86.835629,36.338354]]]},"properties":{"id":"37080","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.475773,35.497986],[-88.520985,35.480835],[-88.515338,35.460292],[-88.483484,35.46769],[-88.475773,35.497986]]]},"properties":{"id":"38347","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.708926,36.548259],[-85.828026,36.411836],[-85.787372,36.374675],[-85.609157,36.481862],[-85.708926,36.548259]]]},"properties":{"id":"38588","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.072524,35.277362],[-85.994718,35.315324],[-86.025692,35.346237],[-86.038402,35.344043],[-86.127025,35.324268],[-86.234053,35.24574],[-86.072524,35.277362]]]},"properties":{"id":"37330","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.812073,36.037937],[-84.859444,35.950534],[-84.914267,35.925724],[-84.865874,35.847961],[-84.818517,35.856203],[-84.726542,36.000072],[-84.740338,36.012574],[-84.788693,36.056381],[-84.812073,36.037937]]]},"properties":{"id":"37723","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.810387,36.159199],[-86.785684,36.16927],[-86.78531,36.174862],[-86.780591,36.175963],[-86.782977,36.191892],[-86.827591,36.191461],[-86.837765,36.168904],[-86.810387,36.159199]]]},"properties":{"id":"37208","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.922643,36.111294],[-86.915231,36.110207],[-86.913962,36.117936],[-86.922643,36.111294]]],[[[-86.922643,36.111294],[-86.981107,36.111123],[-87.040532,36.049557],[-87.054114,36.045535],[-87.046006,36.011946],[-86.962677,35.997074],[-86.886642,36.043151],[-86.869489,36.076344],[-86.919977,36.093915],[-86.922643,36.111294]]]]},"properties":{"id":"37221","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.750963,36.146446],[-85.807415,36.131582],[-85.833459,36.064403],[-85.730346,36.044978],[-85.750963,36.146446]]]},"properties":{"id":"38582","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.682095,35.831254],[-85.74967,35.803758],[-85.541493,35.586295],[-85.495565,35.768847],[-85.567729,35.804462],[-85.587586,35.803348],[-85.682095,35.831254]]]},"properties":{"id":"38581","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.301078,36.001382],[-87.468182,35.932184],[-87.374011,35.928051],[-87.229448,35.871022],[-87.183373,35.911831],[-87.211072,36.002561],[-87.301078,36.001382]]]},"properties":{"id":"37025","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.381616,36.182481],[-89.419276,36.282891],[-89.511178,36.367844],[-89.629453,36.185382],[-89.530971,36.067736],[-89.512408,36.089138],[-89.508559,36.104423],[-89.431698,36.135713],[-89.426629,36.162713],[-89.405545,36.163472],[-89.381616,36.182481]]]},"properties":{"id":"38080","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.512408,36.089138],[-89.502632,36.08881],[-89.508559,36.104423],[-89.512408,36.089138]]]},"properties":{"id":"38047","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.547383,35.292232],[-89.417695,35.181755],[-89.371806,35.146221],[-89.298332,35.114617],[-89.24833,35.140249],[-89.191556,35.17751],[-89.22041,35.397776],[-89.411441,35.400588],[-89.547383,35.292232]]]},"properties":{"id":"38068","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.622638,35.447904],[-89.642796,35.387983],[-89.61584,35.329511],[-89.632305,35.310435],[-89.573398,35.292908],[-89.547383,35.292232],[-89.411441,35.400588],[-89.518489,35.474938],[-89.622638,35.447904]]]},"properties":{"id":"38049","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.117722,36.447049],[-85.121519,36.33999],[-85.013197,36.295274],[-84.969285,36.265696],[-84.918635,36.221318],[-84.791792,36.303325],[-84.786617,36.324877],[-84.692648,36.435777],[-84.731839,36.524674],[-84.860632,36.569107],[-85.044052,36.539535],[-85.117722,36.447049]]]},"properties":{"id":"38556","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.692648,36.435777],[-84.786617,36.324877],[-84.739936,36.33664],[-84.731598,36.350655],[-84.700785,36.370824],[-84.624955,36.425983],[-84.692648,36.435777]]]},"properties":{"id":"38504","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.918635,36.221318],[-84.937018,36.154866],[-84.870888,36.136925],[-84.721172,36.203009],[-84.791792,36.303325],[-84.918635,36.221318]]]},"properties":{"id":"37726","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.978947,35.216351],[-85.948474,35.131972],[-85.943849,35.125286],[-85.863638,35.087142],[-85.86327,35.183717],[-85.887876,35.249037],[-85.978947,35.216351]]]},"properties":{"id":"37375","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.439779,34.990984],[-86.318761,34.991169],[-86.317837,35.063234],[-86.439779,34.990984]]]},"properties":{"id":"37328","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.820612,35.741085],[-88.735085,35.738592],[-88.664742,35.790583],[-88.706811,35.791075],[-88.807304,35.869997],[-88.820612,35.741085]]]},"properties":{"id":"38355","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.146385,35.494733],[-87.224258,35.639377],[-87.256875,35.659508],[-87.322892,35.505092],[-87.1694,35.382032],[-87.151173,35.369245],[-87.128552,35.456004],[-87.146385,35.494733]]]},"properties":{"id":"38474","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.186912,35.169584],[-87.425442,35.14789],[-87.330989,35.056091],[-87.224474,35.039106],[-87.223206,35.059201],[-87.168248,35.149216],[-87.186912,35.169584]]]},"properties":{"id":"38468","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.385963,35.505635],[-87.464955,35.422286],[-87.381429,35.369189],[-87.1694,35.382032],[-87.322892,35.505092],[-87.385963,35.505635]]]},"properties":{"id":"38483","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.531704,36.356986],[-83.624163,36.343206],[-83.667408,36.344381],[-83.723161,36.282217],[-83.694907,36.249633],[-83.646616,36.242059],[-83.487854,36.322706],[-83.531704,36.356986]]]},"properties":{"id":"37888","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.925602,36.245836],[-82.749687,36.140935],[-82.711549,36.330433],[-82.721894,36.403904],[-82.929689,36.33332],[-82.922775,36.274249],[-82.925602,36.245836]]]},"properties":{"id":"37745","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.623652,35.45412],[-85.632885,35.3037],[-85.506528,35.357477],[-85.537985,35.445394],[-85.623652,35.45412]]]},"properties":{"id":"37365","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.623652,35.45412],[-85.644493,35.437308],[-85.658278,35.427023],[-85.660499,35.311808],[-85.655553,35.305536],[-85.645353,35.301997],[-85.632885,35.3037],[-85.623652,35.45412]]]},"properties":{"id":"37339","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.385727,36.170723],[-83.226197,36.24294],[-83.234191,36.281792],[-83.255292,36.289092],[-83.384751,36.265588],[-83.433432,36.209889],[-83.385727,36.170723]]]},"properties":{"id":"37814","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.211001,35.068414],[-85.159614,35.072505],[-85.14712,35.107594],[-85.141172,35.150371],[-85.196442,35.10667],[-85.22913,35.106196],[-85.211001,35.068414]]]},"properties":{"id":"37416","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.307475,35.038576],[-85.333135,35.01867],[-85.320229,35.016922],[-85.304117,35.022953],[-85.300318,35.024086],[-85.294163,35.036125],[-85.307475,35.038576]]]},"properties":{"id":"37408","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.320229,35.016922],[-85.327428,34.983976],[-85.305462,34.984475],[-85.304117,35.022953],[-85.320229,35.016922]]]},"properties":{"id":"37410","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.252595,35.099277],[-85.265662,35.106538],[-85.264903,35.093863],[-85.252595,35.099277]]]},"properties":{"id":"37351","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.127148,36.537495],[-83.087468,36.472022],[-82.942691,36.546294],[-82.830433,36.593761],[-82.961741,36.593778],[-83.127148,36.537495]]]},"properties":{"id":"37731","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.877711,35.341223],[-88.985173,35.40878],[-89.076807,35.431396],[-89.039275,35.359975],[-88.880379,35.288646],[-88.877711,35.341223]]]},"properties":{"id":"38381","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.289969,35.27127],[-88.203878,35.356595],[-88.251301,35.343938],[-88.318289,35.346372],[-88.366169,35.362465],[-88.38873,35.351782],[-88.289969,35.27127]]]},"properties":{"id":"38361","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.518489,35.474938],[-89.411441,35.400588],[-89.22041,35.397776],[-89.253441,35.523005],[-89.469862,35.546566],[-89.518489,35.474938]]]},"properties":{"id":"38069","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.300823,36.5004],[-88.469023,36.501144],[-88.435562,36.396378],[-88.230822,36.394609],[-88.300823,36.5004]]]},"properties":{"id":"38251","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.633221,35.822578],[-87.636567,35.896609],[-87.685046,35.884371],[-87.71676,35.83875],[-87.717337,35.834782],[-87.633221,35.822578]]]},"properties":{"id":"37140","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.386063,35.794417],[-87.389849,35.641354],[-87.298037,35.68948],[-87.298927,35.781492],[-87.386063,35.794417]]]},"properties":{"id":"38454","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.820652,36.370956],[-87.835594,36.232375],[-87.770284,36.243089],[-87.733924,36.353944],[-87.716781,36.401482],[-87.820652,36.370956]]]},"properties":{"id":"37178","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.544057,35.96292],[-83.310782,35.895846],[-83.297483,35.902981],[-83.258184,36.002895],[-83.34747,36.085334],[-83.38209,36.09984],[-83.504105,36.039141],[-83.573082,35.994455],[-83.544057,35.96292]]]},"properties":{"id":"37725","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.826742,36.614215],[-81.983843,36.508314],[-81.951482,36.473863],[-81.79679,36.573604],[-81.770529,36.612922],[-81.826742,36.614215]]]},"properties":{"id":"37688","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.827389,36.074494],[-83.886671,36.032272],[-83.865204,36.028397],[-83.755154,36.005256],[-83.788694,36.046437],[-83.77604,36.082511],[-83.827389,36.074494]]]},"properties":{"id":"37924","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.998867,36.028961],[-83.958448,36.000262],[-83.886671,36.032272],[-83.827389,36.074494],[-83.865866,36.113714],[-83.990775,36.049639],[-83.998867,36.028961]]]},"properties":{"id":"37918","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.615896,36.017688],[-83.689562,35.977833],[-83.693364,35.9447],[-83.685827,35.937067],[-83.544057,35.96292],[-83.573082,35.994455],[-83.615896,36.017688]]]},"properties":{"id":"37764","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.77604,36.082511],[-83.788694,36.046437],[-83.66746,36.080587],[-83.718702,36.123666],[-83.77604,36.082511]]]},"properties":{"id":"37806","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.958443,36.000094],[-83.925062,35.966904],[-83.916526,35.971767],[-83.893745,35.982965],[-83.865204,36.028397],[-83.886671,36.032272],[-83.958448,36.000262],[-83.958443,36.000094]]]},"properties":{"id":"37917","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.847991,36.182702],[-83.725223,36.15385],[-83.700205,36.207583],[-83.694907,36.249633],[-83.723161,36.282217],[-83.847991,36.182702]]]},"properties":{"id":"37779","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.952412,35.962648],[-84.049268,35.963101],[-84.058707,35.925256],[-83.952412,35.962648]]]},"properties":{"id":"37909","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.469993,35.595226],[-89.851917,35.643907],[-89.909797,35.537914],[-89.806343,35.621453],[-89.502118,35.580621],[-89.469993,35.595226]]]},"properties":{"id":"38041","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.492635,35.12819],[-87.617781,35.234512],[-87.620798,35.230767],[-87.639962,35.163022],[-87.532309,35.083888],[-87.492635,35.12819]]]},"properties":{"id":"38486","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.357598,35.001736],[-87.224052,34.999377],[-87.224474,35.039106],[-87.330989,35.056091],[-87.357598,35.001736]]]},"properties":{"id":"38457","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.404428,35.633028],[-87.385963,35.505635],[-87.322892,35.505092],[-87.256875,35.659508],[-87.298037,35.68948],[-87.389849,35.641354],[-87.404428,35.633028]]]},"properties":{"id":"38461","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.815016,35.476487],[-88.015934,35.324174],[-87.935824,35.234328],[-87.620798,35.230767],[-87.617781,35.234512],[-87.608406,35.426681],[-87.717559,35.483347],[-87.815016,35.476487]]]},"properties":{"id":"38485","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.734678,35.083744],[-86.72891,34.991955],[-86.578745,34.991085],[-86.734678,35.083744]]]},"properties":{"id":"38488","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.390592,35.842479],[-84.502797,35.743099],[-84.311795,35.641927],[-84.290917,35.642814],[-84.262619,35.66423],[-84.246416,35.728723],[-84.26046,35.783529],[-84.390592,35.842479]]]},"properties":{"id":"37774","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.682253,35.557373],[-84.472038,35.488222],[-84.495984,35.499671],[-84.587318,35.618965],[-84.629744,35.640016],[-84.676234,35.60197],[-84.682253,35.557373]]]},"properties":{"id":"37826","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537054,35.226439],[-84.495529,35.285472],[-84.609021,35.282443],[-84.625067,35.275539],[-84.66184,35.212852],[-84.537054,35.226439]]]},"properties":{"id":"37325","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.476481,35.078819],[-88.525644,35.087485],[-88.528497,34.995827],[-88.504633,34.995875],[-88.476481,35.078819]]]},"properties":{"id":"38339","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.922895,35.495927],[-89.072082,35.613686],[-89.073463,35.572166],[-89.074766,35.530626],[-89.01257,35.467917],[-88.922895,35.495927]]]},"properties":{"id":"38391","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.227798,35.468309],[-84.374209,35.389475],[-84.436868,35.323484],[-84.348223,35.205725],[-84.038153,35.348202],[-84.227798,35.468309]]]},"properties":{"id":"37385","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.429657,36.029121],[-84.4312,36.019943],[-84.421099,36.023627],[-84.429657,36.029121]]]},"properties":{"id":"37719","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.788102,36.502709],[-88.985767,36.502345],[-88.950205,36.409248],[-88.788102,36.502709]]]},"properties":{"id":"38257","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.311373,35.068561],[-84.368776,35.029053],[-84.400254,35.035641],[-84.391564,35.053799],[-84.398081,35.101356],[-84.564451,35.030943],[-84.56824,34.988298],[-84.311373,35.068561]]]},"properties":{"id":"37317","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.656388,36.510329],[-86.717325,36.548554],[-86.712885,36.473557],[-86.689492,36.431003],[-86.650783,36.415779],[-86.657848,36.430588],[-86.656388,36.510329]]]},"properties":{"id":"37188","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.063259,35.210772],[-89.968761,35.254472],[-89.887458,35.274997],[-89.854425,35.276365],[-89.699911,35.364197],[-89.723391,35.396329],[-89.846746,35.399227],[-89.851855,35.39874],[-90.046598,35.412966],[-90.054322,35.389277],[-90.074992,35.384152],[-90.063259,35.210772]],[[-89.865667,35.339201],[-89.870744,35.332623],[-89.880358,35.332893],[-89.877353,35.3405],[-89.865667,35.339201]]]},"properties":{"id":"38053","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.739896,35.047213],[-89.786097,35.109352],[-89.800972,35.116909],[-89.84554,35.09934],[-89.793188,35.063749],[-89.739896,35.047213]]]},"properties":{"id":"38138","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.793457,35.237473],[-89.834406,35.205482],[-89.836424,35.17534],[-89.772488,35.204396],[-89.793457,35.237473]]]},"properties":{"id":"38133","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.894187,35.22134],[-89.898562,35.17291],[-89.879538,35.156481],[-89.796971,35.131967],[-89.832461,35.16662],[-89.836424,35.17534],[-89.834406,35.205482],[-89.894187,35.22134]]]},"properties":{"id":"38134","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.920774,35.078384],[-89.928294,35.124227],[-89.930123,35.122752],[-89.934813,35.122886],[-89.933783,35.125382],[-89.929635,35.124507],[-89.928213,35.142803],[-89.951738,35.139211],[-89.970055,35.134817],[-89.98035,35.115873],[-89.955955,35.080586],[-89.920774,35.078384]]]},"properties":{"id":"38111","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.994672,35.070496],[-89.989891,35.064946],[-89.990756,35.071936],[-89.994672,35.070496]]]},"properties":{"id":"38131","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.039057,36.171381],[-85.970575,36.178832],[-85.909351,36.178833],[-85.905179,36.186246],[-86.073708,36.238623],[-86.099596,36.192886],[-86.039057,36.171381]]]},"properties":{"id":"38563","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.900121,36.624486],[-87.831703,36.582511],[-87.794729,36.62803],[-87.900121,36.624486]]]},"properties":{"id":"37028","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.476583,36.497827],[-82.40445,36.45237],[-82.318562,36.489704],[-82.283996,36.527892],[-82.311111,36.595609],[-82.423395,36.59501],[-82.400659,36.567591],[-82.476583,36.497827]]]},"properties":{"id":"37617","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.584675,36.416785],[-86.542494,36.31945],[-86.483229,36.316811],[-86.351025,36.352292],[-86.332139,36.445707],[-86.399452,36.480511],[-86.538605,36.460348],[-86.584675,36.416785]]]},"properties":{"id":"37066","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.751571,35.536886],[-89.806343,35.621453],[-89.909797,35.537914],[-89.907412,35.523575],[-89.83901,35.514823],[-89.770649,35.5153],[-89.751571,35.536886]]]},"properties":{"id":"38015","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.421099,36.023627],[-84.390062,35.946987],[-84.28657,36.021588],[-84.219688,36.122321],[-84.425491,36.123657],[-84.509579,36.053133],[-84.429657,36.029121],[-84.421099,36.023627]],[[-84.445245,36.095101],[-84.442179,36.102347],[-84.429563,36.097142],[-84.437346,36.085767],[-84.445245,36.095101]]]},"properties":{"id":"37840","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28657,36.021588],[-84.390062,35.946987],[-84.438401,35.909706],[-84.386735,35.886006],[-84.263383,35.89694],[-84.213782,35.950119],[-84.15731,35.990883],[-84.28657,36.021588]]]},"properties":{"id":"37830","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.207147,35.70379],[-86.279328,35.645648],[-86.262997,35.590513],[-86.192263,35.574756],[-86.120263,35.660929],[-86.207147,35.70379]]]},"properties":{"id":"37018","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.744763,35.324177],[-86.828301,35.263677],[-86.8473,35.218549],[-86.748276,35.206324],[-86.525306,35.354718],[-86.638547,35.428073],[-86.681496,35.396514],[-86.729647,35.360437],[-86.744763,35.324177]]]},"properties":{"id":"37144","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.254062,35.765611],[-85.04114,35.77177],[-85.100781,35.95031],[-85.19954,35.971282],[-85.223057,35.874285],[-85.269359,35.793388],[-85.254062,35.765611]]]},"properties":{"id":"38572","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.262619,35.66423],[-84.195961,35.617935],[-84.098498,35.721678],[-84.170372,35.735914],[-84.246416,35.728723],[-84.262619,35.66423]]]},"properties":{"id":"37742","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.93949,34.987913],[-84.80785,34.987594],[-84.727818,35.120817],[-84.709928,35.14405],[-84.701909,35.187903],[-84.777361,35.242127],[-84.847811,35.166579],[-84.93949,34.987913]]]},"properties":{"id":"37323","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.142097,36.548635],[-84.227051,36.330143],[-84.216903,36.335227],[-84.072698,36.251989],[-84.025774,36.294179],[-83.965566,36.355214],[-83.956513,36.381148],[-83.955512,36.387661],[-83.968983,36.480026],[-84.132639,36.553724],[-84.142097,36.548635]]]},"properties":{"id":"37766","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.163917,36.536601],[-84.197615,36.591845],[-84.261337,36.591784],[-84.252826,36.529885],[-84.163917,36.536601]]]},"properties":{"id":"37819","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.897178,35.846822],[-85.74967,35.803758],[-85.682095,35.831254],[-85.651662,35.904993],[-85.69318,36.017873],[-85.730346,36.044978],[-85.833459,36.064403],[-85.863316,36.033295],[-85.86367,35.955723],[-85.908551,35.941607],[-85.925497,35.887154],[-85.897178,35.846822]]]},"properties":{"id":"37166","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.593594,35.96586],[-88.565787,36.013258],[-88.572582,36.016887],[-88.573866,36.017007],[-88.62689,35.97959],[-88.593594,35.96586]]]},"properties":{"id":"38235","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.862613,36.330508],[-81.992968,36.433112],[-82.122699,36.322985],[-82.044181,36.249141],[-81.970749,36.212329],[-81.862613,36.330508]]]},"properties":{"id":"37640","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.06106,36.146387],[-87.040532,36.049557],[-86.981107,36.111123],[-86.989551,36.177758],[-87.06106,36.146387]]]},"properties":{"id":"37143","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.298398,36.098792],[-87.178624,36.067518],[-87.074167,36.16744],[-87.178306,36.228262],[-87.295428,36.128798],[-87.298398,36.098792]]]},"properties":{"id":"37187","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.658539,36.600369],[-83.645286,36.544277],[-83.426611,36.597364],[-83.658539,36.600369]]]},"properties":{"id":"37752","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.218451,35.821866],[-89.224535,35.837403],[-89.246193,35.829135],[-89.228978,35.809648],[-89.218451,35.821866]]]},"properties":{"id":"38050","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.166936,35.884628],[-89.169032,35.867528],[-89.160617,35.881069],[-89.166936,35.884628]]]},"properties":{"id":"38021","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.818517,35.856203],[-84.77333,35.80884],[-84.723853,35.752896],[-84.614282,35.797585],[-84.595223,35.843771],[-84.620887,35.932199],[-84.726542,36.000072],[-84.818517,35.856203]]]},"properties":{"id":"37854","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.459845,35.799558],[-85.320371,35.80758],[-85.223057,35.874285],[-85.19954,35.971282],[-85.197972,35.977679],[-85.197841,35.987549],[-85.235247,36.020869],[-85.372232,36.085141],[-85.570742,36.058837],[-85.69318,36.017873],[-85.651662,35.904993],[-85.581683,35.8692],[-85.523621,35.855009],[-85.459845,35.799558]]]},"properties":{"id":"38583","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.689492,36.431003],[-86.89059,36.387065],[-86.835629,36.338354],[-86.769862,36.292667],[-86.747168,36.283743],[-86.678914,36.316487],[-86.650783,36.415779],[-86.689492,36.431003]]]},"properties":{"id":"37072","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.831154,35.96599],[-86.715379,35.953481],[-86.681889,35.989399],[-86.701649,36.009349],[-86.745253,36.047393],[-86.812735,36.065644],[-86.875333,36.041374],[-86.831154,35.96599]]]},"properties":{"id":"37027","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.609517,36.115741],[-86.697251,36.144349],[-86.71924,36.116735],[-86.671364,36.077233],[-86.609517,36.115741]]]},"properties":{"id":"37217","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.769862,36.292667],[-86.828415,36.247596],[-86.808715,36.202387],[-86.782977,36.191892],[-86.780591,36.175963],[-86.770807,36.174503],[-86.749856,36.198878],[-86.751569,36.240678],[-86.747168,36.283743],[-86.769862,36.292667]]]},"properties":{"id":"37207","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.806328,36.144205],[-86.819184,36.133692],[-86.816625,36.12976],[-86.79171,36.116334],[-86.788861,36.133874],[-86.807208,36.145386],[-86.804918,36.1458],[-86.803842,36.143922],[-86.806328,36.144205]]]},"properties":{"id":"37212","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.807208,36.145386],[-86.806328,36.144205],[-86.803842,36.143922],[-86.804918,36.1458],[-86.807208,36.145386]]]},"properties":{"id":"37240","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.215948,35.410184],[-88.246423,35.456843],[-88.241677,35.423263],[-88.215948,35.410184]]],[[[-88.000029,35.478904],[-88.181834,35.469219],[-88.197024,35.387174],[-88.196462,35.379561],[-88.051905,35.410866],[-88.000029,35.478904]]]]},"properties":{"id":"38311","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.195804,35.731774],[-88.18992,35.617591],[-88.035213,35.616679],[-88.01909,35.739903],[-88.083299,35.756226],[-88.181131,35.777242],[-88.195804,35.731774]]]},"properties":{"id":"38363","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.750963,36.146446],[-85.730346,36.044978],[-85.69318,36.017873],[-85.570742,36.058837],[-85.618923,36.13833],[-85.612534,36.188255],[-85.742746,36.228276],[-85.780456,36.226402],[-85.750963,36.146446]]]},"properties":{"id":"38544","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.410828,36.159824],[-89.275452,35.98522],[-89.206297,36.011814],[-89.147632,36.029201],[-89.114078,36.136604],[-89.157568,36.146426],[-89.267259,36.207417],[-89.381616,36.182481],[-89.405545,36.163472],[-89.410828,36.159824]]]},"properties":{"id":"38059","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.235094,35.930537],[-89.227546,35.936374],[-89.227424,35.950166],[-89.242721,35.945214],[-89.235094,35.930537]]]},"properties":{"id":"38070","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.61584,35.329511],[-89.635762,35.316399],[-89.632305,35.310435],[-89.61584,35.329511]]]},"properties":{"id":"38036","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.914445,36.186869],[-89.056689,36.119524],[-88.954511,36.110885],[-88.848124,36.133657],[-88.914445,36.186869]]]},"properties":{"id":"38369","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.056689,36.119524],[-88.914445,36.186869],[-88.9407,36.203893],[-88.95977,36.223926],[-89.177379,36.248902],[-89.192401,36.251334],[-89.157568,36.146426],[-89.114078,36.136604],[-89.056689,36.119524]]]},"properties":{"id":"38233","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.153422,36.453245],[-83.361416,36.422287],[-83.531704,36.356986],[-83.487854,36.322706],[-83.440097,36.335633],[-83.280595,36.394689],[-83.192574,36.424516],[-83.153422,36.453245]]]},"properties":{"id":"37881","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.689436,35.429362],[-85.770083,35.351068],[-85.768292,35.316497],[-85.660499,35.311808],[-85.658278,35.427023],[-85.689436,35.429362]]]},"properties":{"id":"37313","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.644493,35.437308],[-85.777877,35.470735],[-85.689436,35.429362],[-85.658278,35.427023],[-85.644493,35.437308]]]},"properties":{"id":"37305","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.234191,36.281792],[-83.226197,36.24294],[-83.185014,36.176866],[-83.166085,36.180894],[-83.124842,36.201287],[-83.182491,36.336192],[-83.234191,36.281792]]]},"properties":{"id":"37860","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.417028,35.234735],[-85.410937,35.118314],[-85.332801,35.020738],[-85.309283,35.058514],[-85.288768,35.055787],[-85.279015,35.081452],[-85.32659,35.111072],[-85.417028,35.234735]]]},"properties":{"id":"37405","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.036103,35.050844],[-85.064833,34.986648],[-84.980434,34.987646],[-84.987208,35.031004],[-85.036103,35.050844]]]},"properties":{"id":"37302","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.118297,34.986531],[-85.159614,35.072505],[-85.211001,35.068414],[-85.214074,35.04913],[-85.20182,35.007618],[-85.175227,34.985976],[-85.118297,34.986531]]]},"properties":{"id":"37421","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.476481,35.078819],[-88.504633,34.995875],[-88.331395,34.995552],[-88.365165,35.079496],[-88.374265,35.127323],[-88.37374,35.140608],[-88.423064,35.121707],[-88.476481,35.078819]]]},"properties":{"id":"38357","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.257804,35.224595],[-88.317973,35.160014],[-88.316256,35.15971],[-88.257804,35.224595]]]},"properties":{"id":"38327","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.605983,36.443495],[-82.400659,36.567591],[-82.423395,36.59501],[-82.566462,36.594712],[-82.549927,36.567284],[-82.583942,36.578253],[-82.588107,36.594367],[-82.609176,36.594099],[-82.645313,36.564794],[-82.673753,36.53513],[-82.671862,36.531779],[-82.680677,36.4318],[-82.605983,36.443495]]]},"properties":{"id":"37660","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.222217,35.817759],[-88.287353,35.819376],[-88.443103,35.822551],[-88.453295,35.769161],[-88.252119,35.731071],[-88.195804,35.731774],[-88.181131,35.777242],[-88.222217,35.817759]]]},"properties":{"id":"38388","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.145251,35.906238],[-84.195349,35.834689],[-84.166267,35.805106],[-84.14252,35.796954],[-84.02875,35.875082],[-84.042002,35.894089],[-84.117147,35.912414],[-84.145251,35.906238]]]},"properties":{"id":"37922","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.511178,36.367844],[-89.419276,36.282891],[-89.405145,36.312075],[-89.373495,36.388405],[-89.346066,36.503232],[-89.511178,36.367844]],[[-89.475501,36.328468],[-89.473143,36.329735],[-89.473343,36.326234],[-89.475508,36.326319],[-89.475501,36.328468]]]},"properties":{"id":"38079","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.311795,35.641927],[-84.587318,35.618965],[-84.495984,35.499671],[-84.303155,35.61718],[-84.290917,35.642814],[-84.311795,35.641927]]]},"properties":{"id":"37874","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.815881,35.377774],[-84.602428,35.293911],[-84.533244,35.369928],[-84.472116,35.459609],[-84.472038,35.488222],[-84.682253,35.557373],[-84.858345,35.353144],[-84.815881,35.377774]]]},"properties":{"id":"37303","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.609021,35.282443],[-84.495529,35.285472],[-84.454713,35.318518],[-84.533244,35.369928],[-84.602428,35.293911],[-84.609021,35.282443]]]},"properties":{"id":"37331","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.782485,35.621027],[-84.866967,35.554066],[-84.981884,35.437248],[-84.932726,35.365988],[-84.860164,35.350074],[-84.858345,35.353144],[-84.682253,35.557373],[-84.676234,35.60197],[-84.782485,35.621027]]]},"properties":{"id":"37322","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.11429,36.635482],[-86.170866,36.509337],[-86.051499,36.452033],[-85.967456,36.424557],[-85.894979,36.454742],[-85.860043,36.623305],[-86.11429,36.635482]]]},"properties":{"id":"37083","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.224258,35.639377],[-87.146385,35.494733],[-86.895101,35.510014],[-86.794697,35.66759],[-86.76748,35.702365],[-86.782016,35.706595],[-87.000427,35.778679],[-87.032999,35.842853],[-87.09569,35.812471],[-87.178813,35.687028],[-87.224258,35.639377]]]},"properties":{"id":"38401","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.429746,36.421956],[-87.338164,36.391097],[-87.268939,36.402356],[-87.172343,36.642044],[-87.339338,36.641637],[-87.466095,36.449621],[-87.429746,36.421956]]]},"properties":{"id":"37040","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.602597,36.418979],[-87.537177,36.35808],[-87.494864,36.365569],[-87.429746,36.421956],[-87.466095,36.449621],[-87.529594,36.458361],[-87.602597,36.418979]]]},"properties":{"id":"37142","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.436324,36.640846],[-87.465846,36.588613],[-87.427712,36.601971],[-87.436324,36.640846]]]},"properties":{"id":"42223","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.437996,35.26727],[-86.365923,35.232706],[-86.279538,35.271137],[-86.34804,35.342168],[-86.421117,35.321612],[-86.437996,35.26727]]]},"properties":{"id":"37352","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.569431,36.031867],[-84.509579,36.053133],[-84.425491,36.123657],[-84.441034,36.163478],[-84.458336,36.186542],[-84.80546,36.090501],[-84.788693,36.056381],[-84.740338,36.012574],[-84.569431,36.031867]]]},"properties":{"id":"37887","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.296404,36.442205],[-89.373495,36.388405],[-89.405145,36.312075],[-89.246903,36.298211],[-89.296404,36.442205]],[[-89.354491,36.374146],[-89.340481,36.38088],[-89.341822,36.367906],[-89.354491,36.374146]]]},"properties":{"id":"38232","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.656388,36.510329],[-86.657848,36.430588],[-86.584675,36.416785],[-86.538605,36.460348],[-86.623751,36.563278],[-86.656388,36.510329]]]},"properties":{"id":"37048","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.261337,36.591784],[-84.520921,36.596423],[-84.332789,36.502115],[-84.252826,36.529885],[-84.261337,36.591784]]]},"properties":{"id":"37892","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.83069,34.994498],[-89.751279,34.994746],[-89.733495,35.047136],[-89.739896,35.047213],[-89.793188,35.063749],[-89.828329,35.056243],[-89.831252,35.034976],[-89.83069,34.994498]]]},"properties":{"id":"38125","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.019465,35.155306],[-90.049295,35.16382],[-90.027612,35.142702],[-90.019465,35.155306]]]},"properties":{"id":"38105","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.865667,35.339201],[-89.877353,35.3405],[-89.880358,35.332893],[-89.870744,35.332623],[-89.865667,35.339201]]]},"properties":{"id":"38054","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.951738,35.139211],[-89.953755,35.164105],[-89.98565,35.161307],[-90.00885,35.154197],[-89.970055,35.134817],[-89.951738,35.139211]]]},"properties":{"id":"38112","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.929635,35.124507],[-89.933783,35.125382],[-89.934813,35.122886],[-89.930123,35.122752],[-89.928294,35.124227],[-89.929635,35.124507]]]},"properties":{"id":"38152","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.928294,35.124227],[-89.920774,35.078384],[-89.906984,35.078277],[-89.892974,35.083347],[-89.886864,35.109987],[-89.888165,35.148885],[-89.928213,35.142803],[-89.929635,35.124507],[-89.928294,35.124227]]]},"properties":{"id":"38117","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.177246,36.190797],[-86.099596,36.192886],[-86.073708,36.238623],[-86.068948,36.262745],[-86.177246,36.190797]]],[[[-86.177246,36.190797],[-86.436029,36.233232],[-86.43076,36.049897],[-86.364201,36.010172],[-86.243081,36.002573],[-86.234962,36.004563],[-86.177246,36.190797]]]]},"properties":{"id":"37090","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.436029,36.233232],[-86.177246,36.190797],[-86.068948,36.262745],[-86.062294,36.271667],[-86.070425,36.338914],[-86.095461,36.341934],[-86.230096,36.349172],[-86.351025,36.352292],[-86.483229,36.316811],[-86.436029,36.233232]]]},"properties":{"id":"37087","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.829747,36.348543],[-86.00328,36.30274],[-86.062294,36.271667],[-86.068948,36.262745],[-86.073708,36.238623],[-85.905179,36.186246],[-85.846483,36.288167],[-85.829747,36.348543]]]},"properties":{"id":"37030","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.588107,36.594367],[-82.583942,36.578253],[-82.549927,36.567284],[-82.566462,36.594712],[-82.588107,36.594367]]]},"properties":{"id":"37665","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.751571,35.536886],[-89.622638,35.447904],[-89.518489,35.474938],[-89.469862,35.546566],[-89.502118,35.580621],[-89.806343,35.621453],[-89.751571,35.536886]]]},"properties":{"id":"38019","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.5772,36.078387],[-82.491001,35.989035],[-82.325764,36.119001],[-82.391561,36.19925],[-82.553596,36.128754],[-82.5772,36.078387]]]},"properties":{"id":"37650","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.857645,35.54142],[-85.861881,35.539395],[-85.860961,35.537446],[-85.856113,35.539143],[-85.857645,35.54142]]]},"properties":{"id":"37394","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.983777,35.164197],[-87.984916,35.005933],[-87.851904,35.005566],[-87.924784,35.146813],[-87.934774,35.155519],[-87.983777,35.164197]]]},"properties":{"id":"38471","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.587586,35.803348],[-85.567729,35.804462],[-85.523621,35.855009],[-85.581683,35.8692],[-85.587586,35.803348]]]},"properties":{"id":"38579","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.000427,35.778679],[-86.863263,35.779173],[-86.817009,35.775162],[-86.774165,35.813944],[-87.032999,35.842853],[-87.000427,35.778679]]]},"properties":{"id":"37179","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.212559,36.12029],[-88.21296,36.077621],[-88.214235,35.994718],[-88.215023,35.953049],[-88.019334,35.981686],[-87.990841,36.04342],[-87.996829,36.141715],[-88.147987,36.1947],[-88.168821,36.165978],[-88.212559,36.12029]]]},"properties":{"id":"38320","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.094769,35.431084],[-85.160093,35.519838],[-85.222077,35.500744],[-85.288633,35.32305],[-85.185003,35.374415],[-85.094769,35.431084]]]},"properties":{"id":"37338","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.964424,35.805688],[-83.977059,35.757798],[-83.969212,35.753437],[-83.857689,35.750542],[-83.801729,35.829756],[-83.848944,35.835285],[-83.964424,35.805688]]]},"properties":{"id":"37804","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.694578,35.943887],[-83.857164,35.841495],[-83.848944,35.835285],[-83.801729,35.829756],[-83.734186,35.772657],[-83.664906,35.814898],[-83.685827,35.937067],[-83.693364,35.9447],[-83.694578,35.943887]]]},"properties":{"id":"37865","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.952648,35.863696],[-83.857164,35.841495],[-83.694578,35.943887],[-83.890619,35.9615],[-83.914612,35.958591],[-83.9201,35.955505],[-83.946103,35.947046],[-84.000987,35.881795],[-83.952648,35.863696]]]},"properties":{"id":"37920","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.968828,35.226789],[-84.987208,35.031004],[-84.980434,34.987646],[-84.93949,34.987913],[-84.847811,35.166579],[-84.968828,35.226789]]]},"properties":{"id":"37311","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.08889,35.646823],[-85.900088,35.493746],[-85.888242,35.456661],[-85.800448,35.494406],[-85.967001,35.719757],[-86.018637,35.717672],[-86.08889,35.646823]],[[-85.857645,35.54142],[-85.856113,35.539143],[-85.860961,35.537446],[-85.861881,35.539395],[-85.857645,35.54142]]]},"properties":{"id":"37357","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.287353,35.819376],[-88.222217,35.817759],[-88.216512,35.893774],[-88.309053,35.91731],[-88.334447,35.868639],[-88.287353,35.819376]]]},"properties":{"id":"38387","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.811425,35.411069],[-88.637976,35.488582],[-88.702482,35.532003],[-88.815773,35.502524],[-88.811425,35.411069]]]},"properties":{"id":"38366","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67615,35.647841],[-88.702482,35.532003],[-88.637976,35.488582],[-88.579905,35.552084],[-88.546742,35.592147],[-88.56392,35.6925],[-88.608804,35.709116],[-88.67615,35.647841]]]},"properties":{"id":"38313","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.579905,35.552084],[-88.520985,35.480835],[-88.475773,35.497986],[-88.474868,35.498374],[-88.546742,35.592147],[-88.579905,35.552084]]]},"properties":{"id":"38352","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.544426,36.445285],[-85.542338,36.353695],[-85.496431,36.344467],[-85.412803,36.336915],[-85.375455,36.451695],[-85.425458,36.50416],[-85.544426,36.445285]]]},"properties":{"id":"38568","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.758369,36.081073],[-86.802939,36.089357],[-86.812735,36.065644],[-86.745253,36.047393],[-86.758369,36.081073]]]},"properties":{"id":"37220","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.808715,36.202387],[-86.828415,36.247596],[-86.891442,36.262053],[-86.893426,36.266963],[-86.983613,36.209566],[-86.837765,36.168904],[-86.827591,36.191461],[-86.808715,36.202387]]]},"properties":{"id":"37218","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.891442,36.262053],[-86.828415,36.247596],[-86.769862,36.292667],[-86.835629,36.338354],[-86.891442,36.262053]]]},"properties":{"id":"37189","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.785684,36.16927],[-86.810387,36.159199],[-86.822196,36.147928],[-86.819184,36.133692],[-86.806328,36.144205],[-86.807208,36.145386],[-86.788861,36.133874],[-86.759564,36.134898],[-86.774604,36.155792],[-86.777498,36.161456],[-86.78294,36.163725],[-86.783798,36.165037],[-86.783761,36.16874],[-86.784352,36.169637],[-86.785684,36.16927]],[[-86.792323,36.159459],[-86.791758,36.161606],[-86.789513,36.158968],[-86.791186,36.158132],[-86.792323,36.159459]]]},"properties":{"id":"37203","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.668744,36.300577],[-86.63892,36.209603],[-86.57931,36.204736],[-86.566293,36.273132],[-86.668744,36.300577]]]},"properties":{"id":"37138","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596866,35.95644],[-86.49548,36.039838],[-86.520883,36.07252],[-86.546879,36.078406],[-86.607743,35.981573],[-86.596866,35.95644]]]},"properties":{"id":"37086","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.774604,36.155792],[-86.759564,36.134898],[-86.758647,36.13367],[-86.71924,36.116735],[-86.697251,36.144349],[-86.712976,36.167213],[-86.744085,36.162098],[-86.769523,36.160636],[-86.774604,36.155792]]]},"properties":{"id":"37210","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.886287,36.105416],[-85.863316,36.033295],[-85.833459,36.064403],[-85.807415,36.131582],[-85.86244,36.146213],[-85.886287,36.105416]]]},"properties":{"id":"38569","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.97298,36.128555],[-85.916274,36.093234],[-85.886287,36.105416],[-85.86244,36.146213],[-85.909351,36.178833],[-85.970575,36.178832],[-85.97298,36.128555]]]},"properties":{"id":"38567","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.435038,35.996872],[-89.330502,35.882129],[-89.275452,35.98522],[-89.410828,36.159824],[-89.431698,36.135713],[-89.508559,36.104423],[-89.502632,36.08881],[-89.512408,36.089138],[-89.530971,36.067736],[-89.435038,35.996872]]]},"properties":{"id":"38024","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.405545,36.163472],[-89.426629,36.162713],[-89.431698,36.135713],[-89.410828,36.159824],[-89.405545,36.163472]]]},"properties":{"id":"38007","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.243338,35.060624],[-89.247965,35.098431],[-89.274685,35.087769],[-89.243338,35.060624]]]},"properties":{"id":"38046","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.137455,36.290453],[-85.100385,36.241535],[-85.090594,36.216709],[-85.047066,36.246514],[-85.013197,36.295274],[-85.121519,36.33999],[-85.137455,36.290453]]]},"properties":{"id":"38589","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.969285,36.265696],[-85.013197,36.295274],[-85.047066,36.246514],[-84.969285,36.265696]]]},"properties":{"id":"38565","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.209382,36.625599],[-85.276281,36.626816],[-85.277299,36.593053],[-85.121619,36.450335],[-85.117722,36.447049],[-85.044052,36.539535],[-85.070543,36.621248],[-85.190361,36.625277],[-85.2047,36.621404],[-85.209382,36.625599]]]},"properties":{"id":"38549","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.994718,35.315324],[-86.072524,35.277362],[-86.046286,35.188467],[-85.978947,35.216351],[-85.887876,35.249037],[-85.874992,35.259469],[-85.900764,35.322083],[-85.994718,35.315324]]]},"properties":{"id":"37324","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.381429,35.369189],[-87.175379,35.301162],[-87.140836,35.350704],[-87.151173,35.369245],[-87.1694,35.382032],[-87.381429,35.369189]]]},"properties":{"id":"38456","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.384751,36.265588],[-83.255292,36.289092],[-83.280595,36.394689],[-83.440097,36.335633],[-83.384751,36.265588]]]},"properties":{"id":"37708","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.067443,36.096604],[-83.030016,36.215088],[-83.125287,36.122525],[-83.067443,36.096604]]]},"properties":{"id":"37809","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.812389,35.276873],[-85.874992,35.259469],[-85.887876,35.249037],[-85.86327,35.183717],[-85.773489,35.218736],[-85.812389,35.276873]]]},"properties":{"id":"37356","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.900764,35.322083],[-85.874992,35.259469],[-85.812389,35.276873],[-85.768292,35.316497],[-85.770083,35.351068],[-85.869478,35.380435],[-85.900764,35.322083]]]},"properties":{"id":"37366","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.660499,35.311808],[-85.768292,35.316497],[-85.812389,35.276873],[-85.773489,35.218736],[-85.743067,35.215516],[-85.655553,35.305536],[-85.660499,35.311808]]]},"properties":{"id":"37387","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.341013,36.146017],[-83.231144,36.122808],[-83.17551,36.12667],[-83.185014,36.176866],[-83.226197,36.24294],[-83.385727,36.170723],[-83.341013,36.146017]]]},"properties":{"id":"37813","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.307475,35.038576],[-85.309283,35.058514],[-85.332801,35.020738],[-85.333135,35.01867],[-85.307475,35.038576]]]},"properties":{"id":"37402","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.24914,35.049174],[-85.260102,35.02045],[-85.20182,35.007618],[-85.214074,35.04913],[-85.24914,35.049174]]]},"properties":{"id":"37411","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.015934,35.324174],[-87.815016,35.476487],[-87.987359,35.484177],[-88.000029,35.478904],[-88.051905,35.410866],[-88.065773,35.335802],[-88.015934,35.324174]]]},"properties":{"id":"38425","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.8296,36.460738],[-82.7796,36.593743],[-82.830433,36.593761],[-82.942691,36.546294],[-82.8296,36.460738]]]},"properties":{"id":"37873","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.851917,35.643907],[-89.469993,35.595226],[-89.353729,35.72643],[-89.470513,35.832574],[-89.609164,35.919935],[-89.644838,35.904351],[-89.851917,35.643907]]]},"properties":{"id":"38063","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.56392,35.6925],[-88.422582,35.530272],[-88.291292,35.599025],[-88.239764,35.615469],[-88.233958,35.615672],[-88.252119,35.731071],[-88.453295,35.769161],[-88.600001,35.789195],[-88.608804,35.709116],[-88.56392,35.6925]]]},"properties":{"id":"38351","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.361248,36.148417],[-88.212559,36.12029],[-88.168821,36.165978],[-88.226522,36.196042],[-88.323663,36.222432],[-88.376038,36.14878],[-88.361248,36.148417]]]},"properties":{"id":"38236","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.298927,35.781492],[-87.298037,35.68948],[-87.256875,35.659508],[-87.224258,35.639377],[-87.178813,35.687028],[-87.20078,35.777853],[-87.285084,35.795491],[-87.298927,35.781492]]]},"properties":{"id":"38487","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.949603,36.24316],[-87.835594,36.232375],[-87.820652,36.370956],[-87.98074,36.353307],[-87.949603,36.24316]]]},"properties":{"id":"37175","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.65798,36.275042],[-85.742746,36.228276],[-85.612534,36.188255],[-85.614536,36.247045],[-85.65798,36.275042]]]},"properties":{"id":"38545","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.05441,35.970311],[-84.123046,35.938237],[-84.117147,35.912414],[-84.042002,35.894089],[-84.058707,35.925256],[-84.049268,35.963101],[-84.05441,35.970311]]]},"properties":{"id":"37923","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.236546,35.872711],[-84.195349,35.834689],[-84.145251,35.906238],[-84.240811,35.8767],[-84.236546,35.872711]]]},"properties":{"id":"37934","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.744425,35.158398],[-88.712658,35.080711],[-88.525644,35.087485],[-88.476481,35.078819],[-88.423064,35.121707],[-88.442059,35.198671],[-88.529047,35.227637],[-88.75486,35.201121],[-88.744425,35.158398]]]},"properties":{"id":"38375","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.83583,35.741397],[-88.735085,35.738592],[-88.820612,35.741085],[-88.83583,35.741397]]]},"properties":{"id":"38362","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.575695,35.103708],[-85.645353,35.301997],[-85.655553,35.305536],[-85.743067,35.215516],[-85.699988,35.101519],[-85.575695,35.103708]]]},"properties":{"id":"37374","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.629744,35.640016],[-84.551558,35.752099],[-84.614282,35.797585],[-84.723853,35.752896],[-84.782485,35.621027],[-84.676234,35.60197],[-84.629744,35.640016]]]},"properties":{"id":"37880","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.502937,36.551885],[-87.615044,36.5419],[-87.529594,36.458361],[-87.466095,36.449621],[-87.502937,36.551885]]]},"properties":{"id":"37191","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.791792,36.303325],[-84.721172,36.203009],[-84.541411,36.22957],[-84.600212,36.316676],[-84.612359,36.321552],[-84.711323,36.324179],[-84.711432,36.325171],[-84.739936,36.33664],[-84.786617,36.324877],[-84.791792,36.303325]]]},"properties":{"id":"37872","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.235935,36.333873],[-85.225331,36.319487],[-85.194916,36.301253],[-85.180021,36.314611],[-85.235935,36.333873]]]},"properties":{"id":"38542","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.981763,35.774973],[-87.743122,35.663436],[-87.717337,35.834782],[-87.71676,35.83875],[-87.818202,35.836779],[-87.96274,35.840921],[-87.981763,35.774973]]]},"properties":{"id":"37097","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.709928,35.14405],[-84.727818,35.120817],[-84.627031,35.077217],[-84.625631,35.096888],[-84.709928,35.14405]]]},"properties":{"id":"37361","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.56664,35.860579],[-86.567215,35.88319],[-86.615132,35.88944],[-86.734428,35.897317],[-86.727213,35.849973],[-86.595144,35.8508],[-86.56664,35.860579]]]},"properties":{"id":"37014","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.49548,36.039838],[-86.596866,35.95644],[-86.615132,35.88944],[-86.567215,35.88319],[-86.455733,36.033335],[-86.49548,36.039838]]]},"properties":{"id":"37167","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.836424,35.17534],[-89.832461,35.16662],[-89.709741,35.158839],[-89.706644,35.194755],[-89.772488,35.204396],[-89.836424,35.17534]]]},"properties":{"id":"38016","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.964388,35.182964],[-89.91726,35.182293],[-89.898562,35.17291],[-89.894187,35.22134],[-89.882234,35.259919],[-89.887458,35.274997],[-89.968761,35.254472],[-89.964388,35.182964]]]},"properties":{"id":"38128","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.022572,35.065607],[-90.044684,35.076666],[-90.043036,34.994964],[-89.972197,34.99502],[-89.987212,35.063705],[-89.989891,35.064946],[-89.994672,35.070496],[-90.022572,35.065607]]]},"properties":{"id":"38116","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.823275,35.241507],[-89.825053,35.238575],[-89.819228,35.236575],[-89.819049,35.239164],[-89.823275,35.241507]]]},"properties":{"id":"38029","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.831703,36.582511],[-87.728397,36.560518],[-87.726383,36.561215],[-87.716826,36.570204],[-87.794729,36.62803],[-87.831703,36.582511]]]},"properties":{"id":"37023","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.716826,36.570204],[-87.726383,36.561215],[-87.712813,36.561402],[-87.716826,36.570204]]],[[[-87.900121,36.624486],[-88.061456,36.444093],[-88.040219,36.424898],[-87.99092,36.360133],[-87.98074,36.353307],[-87.820652,36.370956],[-87.716781,36.401482],[-87.703334,36.439938],[-87.728397,36.560518],[-87.831703,36.582511],[-87.900121,36.624486]]]]},"properties":{"id":"37058","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075348,36.458612],[-82.283996,36.527892],[-82.318562,36.489704],[-82.281994,36.423515],[-82.251005,36.419509],[-82.075348,36.458612]]]},"properties":{"id":"37618","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.386163,36.392104],[-82.311914,36.39793],[-82.281994,36.423515],[-82.318562,36.489704],[-82.40445,36.45237],[-82.420713,36.446966],[-82.386163,36.392104]]]},"properties":{"id":"37686","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.332139,36.445707],[-86.351025,36.352292],[-86.230096,36.349172],[-86.24968,36.397618],[-86.332139,36.445707]]]},"properties":{"id":"37031","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.846992,35.944844],[-86.74006,35.850847],[-86.727213,35.849973],[-86.734428,35.897317],[-86.715379,35.953481],[-86.831154,35.96599],[-86.846992,35.944844]]]},"properties":{"id":"37067","state_code":"47"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.497146,31.860214],[-95.789679,31.648776],[-95.723079,31.63726],[-95.389659,31.654172],[-95.342483,31.718032],[-95.404014,31.802859],[-95.437678,31.897983],[-95.477668,31.870317],[-95.479515,31.857315],[-95.497146,31.860214]]]},"properties":{"id":"75801","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.389659,31.654172],[-95.723079,31.63726],[-95.739279,31.504056],[-95.389659,31.654172]]]},"properties":{"id":"75839","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.233476,27.89176],[-97.188173,27.824249],[-97.089666,27.966313],[-97.168713,27.996343],[-97.268895,27.926462],[-97.280673,27.916797],[-97.233476,27.89176]]]},"properties":{"id":"78336","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.423089,33.647355],[-98.523439,33.578359],[-98.607531,33.466983],[-98.538684,33.395846],[-98.420666,33.396195],[-98.421241,33.450961],[-98.423089,33.647355]]]},"properties":{"id":"76389","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.424432,33.882732],[-98.51917,33.835649],[-98.560961,33.873924],[-98.565961,33.917769],[-98.597063,33.907084],[-98.707449,33.834691],[-98.605881,33.710707],[-98.522636,33.666837],[-98.42311,33.681166],[-98.347112,33.868765],[-98.424432,33.882732]]]},"properties":{"id":"76310","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.771835,28.991364],[-98.802109,28.780896],[-98.800841,28.647487],[-98.656899,28.648364],[-98.620859,28.648352],[-98.710232,28.991337],[-98.771835,28.991364]]]},"properties":{"id":"78011","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.062381,29.084542],[-97.969705,29.126482],[-98.068436,29.265336],[-98.094442,29.252193],[-98.195094,29.271278],[-98.281905,29.255018],[-98.29246,29.251235],[-98.381446,29.144832],[-98.299106,28.958725],[-98.090461,29.063759],[-98.062381,29.084542]]]},"properties":{"id":"78114","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.804771,29.266128],[-98.804788,29.151788],[-98.711041,29.137331],[-98.734966,29.22671],[-98.804771,29.266128]]]},"properties":{"id":"78052","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-102.436338,33.964866],[-102.420306,34.072973],[-102.615394,34.125338],[-102.615478,33.972505],[-102.615472,33.842113],[-102.576416,33.84942],[-102.576091,33.8728],[-102.44571,33.949534],[-102.436338,33.964866]]],[[[-102.790841,33.873586],[-103.043617,34.00364],[-102.974339,33.824675],[-102.873018,33.824802],[-102.80968,33.824896],[-102.790841,33.873586]]]]},"properties":{"id":"79371","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.267289,30.282712],[-97.230921,30.196661],[-97.169728,30.317988],[-97.222095,30.41369],[-97.267289,30.282712]]]},"properties":{"id":"78650","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.284411,28.097478],[-97.531342,28.17787],[-97.660628,28.179415],[-97.677774,28.150444],[-97.705386,28.02983],[-97.507008,27.925511],[-97.457537,27.939924],[-97.284411,28.097478]]]},"properties":{"id":"78387","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.900175,28.565259],[-98.032567,28.566346],[-97.984916,28.485552],[-97.90203,28.547],[-97.900175,28.565259]]]},"properties":{"id":"78125","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.872606,31.057927],[-97.800815,30.902689],[-97.717422,30.931179],[-97.648384,31.004906],[-97.685536,31.075112],[-97.72021,31.09091],[-97.750406,31.110243],[-97.871907,31.087411],[-97.880614,31.08305],[-97.872606,31.057927]]]},"properties":{"id":"76542","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.871907,31.087411],[-97.753403,31.111298],[-97.707741,31.128033],[-97.62075,31.118497],[-97.750001,31.150244],[-97.871082,31.122676],[-97.878981,31.089467],[-97.880614,31.08305],[-97.871907,31.087411]]]},"properties":{"id":"76544","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.174055,31.133277],[-97.350547,31.189132],[-97.334578,31.058609],[-97.338143,30.996317],[-97.337475,30.979571],[-97.179687,31.007097],[-97.111556,31.045265],[-97.129281,31.070328],[-97.174055,31.133277]]]},"properties":{"id":"76501","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.982022,31.031201],[-97.111556,31.045265],[-97.179687,31.007097],[-97.119997,30.960964],[-97.075051,30.98375],[-96.982022,31.031201]]]},"properties":{"id":"76519","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.179687,31.007097],[-97.337475,30.979571],[-97.331357,30.956984],[-97.252942,30.860125],[-97.119997,30.960964],[-97.179687,31.007097]]]},"properties":{"id":"76569","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.365943,31.078756],[-97.364767,31.075379],[-97.361319,31.078371],[-97.365943,31.078756]]]},"properties":{"id":"76508","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.515395,29.55416],[-98.507819,29.493808],[-98.481807,29.491521],[-98.46917,29.51845],[-98.43585,29.546195],[-98.46732,29.555513],[-98.514332,29.579157],[-98.525684,29.572435],[-98.515395,29.55416]]]},"properties":{"id":"78216","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.467884,29.611377],[-98.508264,29.608741],[-98.514332,29.579157],[-98.46732,29.555513],[-98.443169,29.602648],[-98.467884,29.611377]]]},"properties":{"id":"78232","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.328882,29.455309],[-98.339515,29.506186],[-98.357636,29.491437],[-98.377831,29.47779],[-98.328882,29.455309]]]},"properties":{"id":"78244","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.490576,29.414332],[-98.496296,29.424122],[-98.528832,29.398243],[-98.512104,29.38791],[-98.499007,29.387923],[-98.490576,29.414332]]]},"properties":{"id":"78204","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.52215,29.362543],[-98.56794,29.320062],[-98.581752,29.26182],[-98.491365,29.26387],[-98.52215,29.362543]]]},"properties":{"id":"78224","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.430187,29.484786],[-98.472382,29.451691],[-98.473804,29.447166],[-98.441133,29.44089],[-98.428029,29.456401],[-98.430187,29.484786]]]},"properties":{"id":"78234","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.774024,30.041576],[-98.917725,29.781398],[-98.806552,29.690709],[-98.710267,29.65844],[-98.638597,29.678795],[-98.634673,29.677056],[-98.615228,29.770359],[-98.548449,29.824643],[-98.602699,29.931576],[-98.667725,30.037722],[-98.710953,30.100051],[-98.774024,30.041576]]]},"properties":{"id":"78006","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.011036,30.480387],[-98.267685,30.463753],[-98.130011,30.372683],[-98.020796,30.29065],[-97.993901,30.306929],[-98.004732,30.354856],[-98.003368,30.372991],[-98.011036,30.480387]]]},"properties":{"id":"78669","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.964014,30.705688],[-98.964016,30.705236],[-98.886371,30.704207],[-98.875226,30.580738],[-98.964304,30.586599],[-98.964232,30.498482],[-98.757521,30.498867],[-98.59167,30.499875],[-98.440983,30.501862],[-98.413089,30.581562],[-98.400608,30.599476],[-98.484271,30.735421],[-98.504337,30.796797],[-98.533328,30.833699],[-98.463118,30.921575],[-98.500955,30.921944],[-98.732318,30.920994],[-98.963864,30.795351],[-98.964014,30.705688]]]},"properties":{"id":"78643","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.27999,32.64585],[-101.174473,32.569494],[-101.174489,32.559998],[-101.025369,32.527913],[-100.985479,32.560581],[-101.174021,32.79208],[-101.262568,32.775376],[-101.27999,32.64585]]]},"properties":{"id":"79527","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.262568,32.775376],[-101.174021,32.79208],[-101.070237,32.932501],[-101.173378,32.963597],[-101.442447,32.961944],[-101.473143,32.961686],[-101.262568,32.775376]]]},"properties":{"id":"79517","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.567019,31.943332],[-97.408547,31.908039],[-97.486817,32.025486],[-97.618789,32.057403],[-97.707893,32.028776],[-97.567019,31.943332]]]},"properties":{"id":"76671","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.707893,32.028776],[-97.828902,31.990412],[-97.869036,31.844852],[-97.859166,31.815476],[-97.709676,31.804882],[-97.567019,31.943332],[-97.707893,32.028776]]]},"properties":{"id":"76665","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.54546,33.425032],[-94.623423,33.251807],[-94.415739,33.284017],[-94.426626,33.327686],[-94.54546,33.425032]]]},"properties":{"id":"75574","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.233385,33.552205],[-94.333826,33.557371],[-94.313528,33.464796],[-94.203438,33.459304],[-94.203389,33.465564],[-94.233385,33.552205]]]},"properties":{"id":"75561","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.426626,33.327686],[-94.415739,33.284017],[-94.409973,33.273348],[-94.259313,33.237046],[-94.210137,33.297044],[-94.215246,33.32085],[-94.263436,33.338355],[-94.249721,33.364178],[-94.262241,33.373821],[-94.390046,33.408242],[-94.426626,33.327686]]]},"properties":{"id":"75567","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.329357,29.5027],[-95.417038,29.540723],[-95.358604,29.410351],[-95.329357,29.5027]]]},"properties":{"id":"77578","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.590342,29.37967],[-95.735844,29.319156],[-95.739817,29.214897],[-95.596103,29.172419],[-95.561903,29.250582],[-95.577077,29.368087],[-95.590342,29.37967]]]},"properties":{"id":"77430","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.346935,30.684444],[-96.297323,30.691158],[-96.182458,30.617135],[-96.16837,30.822997],[-96.204498,30.924248],[-96.531552,30.823249],[-96.417995,30.722369],[-96.346935,30.684444]]]},"properties":{"id":"77808","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.136057,27.005504],[-98.422616,26.783535],[-98.40157,26.783404],[-98.32067,26.783081],[-98.193184,26.782415],[-98.127975,27.005673],[-98.136057,27.005504]]]},"properties":{"id":"78353","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.882648,31.843208],[-98.795728,31.775511],[-98.72577,31.785394],[-98.74171,31.916855],[-98.835405,31.947009],[-98.882648,31.843208]]]},"properties":{"id":"76432","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.877811,30.600776],[-96.94283,30.719237],[-97.048814,30.773792],[-97.156176,30.733405],[-97.051648,30.51156],[-96.963629,30.557169],[-96.877811,30.600776]]]},"properties":{"id":"76567","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.7622,29.932286],[-97.849178,29.916],[-97.867351,29.864183],[-97.803267,29.872754],[-97.7622,29.932286]]]},"properties":{"id":"78656","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.803267,29.872754],[-97.867351,29.864183],[-97.873567,29.763874],[-97.823411,29.75118],[-97.814341,29.784747],[-97.803267,29.872754]]]},"properties":{"id":"78655","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.378226,28.466238],[-96.455081,28.435918],[-96.497785,28.464204],[-96.530462,28.425771],[-96.459028,28.419797],[-96.378226,28.466238]]]},"properties":{"id":"77982","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.713461,32.750718],[-99.720282,32.678278],[-99.847646,32.539993],[-99.841792,32.520902],[-99.74172,32.449596],[-99.629828,32.429353],[-99.527338,32.514758],[-99.619573,32.746339],[-99.684479,32.801842],[-99.713461,32.750718]],[[-99.716539,32.459907],[-99.718446,32.463529],[-99.713236,32.463817],[-99.713647,32.459849],[-99.716539,32.459907]]]},"properties":{"id":"79601","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.198166,26.082529],[-97.398005,26.049123],[-97.299802,26.001924],[-97.161099,26.065961],[-97.198166,26.082529]]]},"properties":{"id":"78578","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861924,26.316484],[-97.796466,26.338291],[-97.812877,26.349806],[-97.78064,26.350099],[-97.780359,26.335937],[-97.777169,26.335462],[-97.575868,26.306249],[-97.531226,26.368216],[-97.890321,26.433308],[-97.922991,26.356649],[-97.861924,26.316484]]]},"properties":{"id":"78569","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.525184,34.746931],[-102.492683,34.746737],[-102.525123,34.72864],[-102.525266,34.53103],[-102.210767,34.688513],[-102.168641,34.79805],[-102.168437,34.853304],[-102.167584,34.883807],[-102.167508,34.92467],[-102.245165,34.971527],[-102.328937,35.151263],[-102.547401,35.101476],[-102.837784,35.183794],[-103.042714,34.984002],[-102.525184,34.746931]]]},"properties":{"id":"79045","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.467909,29.73297],[-94.355561,29.735042],[-94.271267,29.728571],[-94.261994,29.807095],[-94.357403,29.890085],[-94.442235,29.889538],[-94.534569,29.889506],[-94.577426,29.837425],[-94.467909,29.73297]]]},"properties":{"id":"77665","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.738857,29.887217],[-94.659943,29.88812],[-94.639028,29.888384],[-94.605284,30.054625],[-94.648605,30.133103],[-94.698087,30.308747],[-94.82503,30.21355],[-94.738857,29.887217]],[[-94.729177,30.159],[-94.737175,30.15913],[-94.742,30.17748],[-94.729565,30.166998],[-94.729177,30.159]]]},"properties":{"id":"77575","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.422174,32.043818],[-95.427834,32.023785],[-95.419688,32.049899],[-95.422174,32.043818]]]},"properties":{"id":"75759","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.072322,33.780502],[-97.978804,33.727526],[-97.886567,33.741025],[-97.943273,33.87899],[-97.979677,33.892208],[-98.072322,33.780502]]]},"properties":{"id":"76261","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.981041,33.623521],[-103.051767,33.627747],[-103.022309,33.535838],[-102.981041,33.623521]]]},"properties":{"id":"79314","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.374035,31.561697],[-99.409843,31.462006],[-99.374928,31.439288],[-99.374035,31.561697]]]},"properties":{"id":"76873","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.770371,33.056012],[-96.772039,33.071726],[-96.768021,33.112316],[-96.80543,33.097149],[-96.855742,33.081266],[-96.842504,33.05695],[-96.770371,33.056012]]]},"properties":{"id":"75024","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.815144,33.013329],[-96.813383,32.987136],[-96.768152,32.986535],[-96.756858,33.000422],[-96.786423,33.011775],[-96.815144,33.013329]]]},"properties":{"id":"75252","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.512037,33.290623],[-96.60467,33.285286],[-96.607833,33.3056],[-96.754137,33.284528],[-96.767642,33.216229],[-96.767702,33.203697],[-96.628625,33.229253],[-96.547912,33.191992],[-96.474734,33.247135],[-96.479012,33.27409],[-96.512037,33.290623]]]},"properties":{"id":"75071","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.60467,33.285286],[-96.579615,33.321058],[-96.512037,33.290623],[-96.479012,33.27409],[-96.402353,33.39808],[-96.429626,33.398426],[-96.656486,33.401331],[-96.607833,33.3056],[-96.60467,33.285286]]]},"properties":{"id":"75409","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.96443,33.100028],[-96.855742,33.081266],[-96.80543,33.097149],[-96.774074,33.21248],[-96.88026,33.21905],[-96.970945,33.1177],[-96.96443,33.100028]]]},"properties":{"id":"75034","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.774074,33.21248],[-96.80543,33.097149],[-96.768021,33.112316],[-96.732835,33.125168],[-96.767702,33.203697],[-96.767642,33.216229],[-96.774074,33.21248]]]},"properties":{"id":"75035","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.628625,33.229253],[-96.767702,33.203697],[-96.732835,33.125168],[-96.732663,33.125228],[-96.645626,33.157565],[-96.628625,33.229253]]]},"properties":{"id":"75070","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.822271,32.954169],[-96.768983,32.950985],[-96.768152,32.986535],[-96.813383,32.987136],[-96.829862,32.987402],[-96.822271,32.954169]]]},"properties":{"id":"75248","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.296199,33.075403],[-96.314009,33.078224],[-96.31225,33.061098],[-96.296452,33.062554],[-96.296199,33.075403]]]},"properties":{"id":"75164","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.626818,29.522545],[-96.706155,29.486696],[-96.64234,29.42002],[-96.603079,29.487626],[-96.620965,29.515928],[-96.626818,29.522545]]]},"properties":{"id":"77475","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.667722,32.297601],[-98.77518,32.320097],[-98.87732,32.143089],[-98.851601,32.113149],[-98.712113,32.106019],[-98.588784,32.241779],[-98.667722,32.297601]]]},"properties":{"id":"76454","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.444879,33.824048],[-97.486138,33.569368],[-97.395725,33.506208],[-97.382438,33.505096],[-97.322427,33.612119],[-97.320804,33.630533],[-97.298526,33.623487],[-97.261224,33.642358],[-97.314257,33.865089],[-97.444879,33.824048]]]},"properties":{"id":"76252","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.582231,33.433993],[-97.486972,33.448187],[-97.431582,33.486341],[-97.395725,33.506208],[-97.486138,33.569368],[-97.647003,33.58643],[-97.68387,33.537104],[-97.693063,33.531111],[-97.582231,33.433993]]]},"properties":{"id":"76239","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.673977,33.183948],[-97.611112,33.10921],[-97.539821,33.099622],[-97.394207,33.13177],[-97.392832,33.178361],[-97.389467,33.268249],[-97.34316,33.356749],[-97.343568,33.429095],[-97.37453,33.44804],[-97.420071,33.463825],[-97.486972,33.448187],[-97.582231,33.433993],[-97.678822,33.274769],[-97.687802,33.261495],[-97.673977,33.183948]]]},"properties":{"id":"76234","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.052656,33.816505],[-96.943359,33.576141],[-96.795303,33.560473],[-96.769122,33.62115],[-96.782907,33.628819],[-96.847368,33.67287],[-96.884505,33.783181],[-96.833786,33.873525],[-97.052656,33.816505]]]},"properties":{"id":"76273","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.545242,31.502252],[-97.503512,31.325982],[-97.474466,31.319234],[-97.47558,31.401975],[-97.489105,31.421339],[-97.532979,31.484592],[-97.545242,31.502252]]]},"properties":{"id":"76561","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.518541,33.780873],[-100.518375,33.745142],[-100.477184,33.744761],[-100.447748,33.781901],[-100.518691,33.83565],[-100.518541,33.780873]]],[[[-100.179264,34.1503],[-100.259277,34.19588],[-100.518042,34.212151],[-100.518378,33.910158],[-100.518528,33.875558],[-100.383409,33.835955],[-100.235652,33.835964],[-100.048134,33.922437],[-100.048094,33.93872],[-100.048093,33.938967],[-100.047428,34.132815],[-100.10253,34.204534],[-100.179264,34.1503]]]]},"properties":{"id":"79248","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.608878,34.083091],[-99.586584,34.386943],[-99.997501,34.560574],[-100.070765,34.22886],[-100.179264,34.1503],[-100.10253,34.204534],[-99.777548,34.096518],[-99.608878,34.083091]]]},"properties":{"id":"79252","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.366288,31.82383],[-102.478912,31.786451],[-102.662253,31.70014],[-102.160484,31.651608],[-102.28743,31.873929],[-102.319351,31.865545],[-102.366288,31.82383]]]},"properties":{"id":"79766","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.041391,33.869952],[-101.086669,33.87714],[-101.098612,33.948415],[-101.046824,33.931663],[-101.0414,33.905232],[-101.025223,33.954076],[-101.041416,34.139043],[-101.041424,34.14686],[-101.239863,34.157661],[-101.327432,34.186142],[-101.564464,34.021314],[-101.465883,33.831289],[-101.335668,33.768972],[-101.041158,33.833625],[-101.041391,33.869952]]]},"properties":{"id":"79235","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.650419,32.894489],[-96.602214,32.894013],[-96.592367,32.894002],[-96.59306,32.909525],[-96.582364,32.941634],[-96.607231,32.968815],[-96.648319,32.931171],[-96.650419,32.894489]]]},"properties":{"id":"75040","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.782507,32.789892],[-96.764208,32.795665],[-96.769486,32.800285],[-96.782507,32.789892]]]},"properties":{"id":"75246","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.764173,32.923871],[-96.769032,32.909357],[-96.769205,32.898713],[-96.721868,32.894548],[-96.697977,32.902268],[-96.705904,32.931232],[-96.748076,32.938314],[-96.751937,32.939974],[-96.764173,32.923871]]]},"properties":{"id":"75243","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.650419,32.894489],[-96.648319,32.931171],[-96.683007,32.931278],[-96.705904,32.931232],[-96.697977,32.902268],[-96.69188,32.894571],[-96.650419,32.894489]]]},"properties":{"id":"75042","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.665665,32.560721],[-96.635916,32.610868],[-96.709967,32.621071],[-96.710846,32.612558],[-96.665665,32.560721]]]},"properties":{"id":"75172","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.821303,32.686538],[-96.713051,32.696408],[-96.734043,32.723626],[-96.777533,32.749913],[-96.824371,32.732819],[-96.822896,32.687043],[-96.821303,32.686538]]]},"properties":{"id":"75216","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.908399,32.618789],[-96.899695,32.560279],[-96.822359,32.56711],[-96.822706,32.604247],[-96.823105,32.633232],[-96.857176,32.636467],[-96.884473,32.647512],[-96.908399,32.618789]]]},"properties":{"id":"75115","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.022064,32.837137],[-96.890595,32.830489],[-96.916124,32.856042],[-96.949071,32.866743],[-97.022064,32.837137]]]},"properties":{"id":"75062","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.856489,32.76634],[-96.822847,32.780782],[-96.835251,32.789718],[-96.898522,32.798192],[-96.926077,32.782076],[-96.927414,32.765583],[-96.856489,32.76634]]]},"properties":{"id":"75212","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.942971,32.647244],[-96.943239,32.626115],[-96.908399,32.618789],[-96.884473,32.647512],[-96.891392,32.647499],[-96.942971,32.647244]]]},"properties":{"id":"75137","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.926161,32.749636],[-96.966132,32.706977],[-96.881364,32.715818],[-96.857079,32.72533],[-96.856912,32.725429],[-96.856489,32.76634],[-96.927414,32.765583],[-96.926161,32.749636]]]},"properties":{"id":"75211","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.994569,32.815855],[-96.926077,32.782076],[-96.898522,32.798192],[-96.906129,32.814286],[-96.994569,32.815855]]]},"properties":{"id":"75060","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.85616,32.909652],[-96.928376,32.895688],[-96.93114,32.886088],[-96.809576,32.880068],[-96.814644,32.909585],[-96.85616,32.909652]]]},"properties":{"id":"75229","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.770399,32.865592],[-96.753803,32.859626],[-96.732682,32.855173],[-96.721868,32.894548],[-96.769205,32.898713],[-96.770252,32.880018],[-96.770399,32.865592]]]},"properties":{"id":"75231","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.328937,35.151263],[-102.245165,34.971527],[-102.167497,34.956567],[-102.099413,35.058493],[-102.114599,35.183222],[-102.166695,35.247041],[-102.349198,35.324396],[-102.328937,35.151263]]]},"properties":{"id":"79098","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.454087,33.434572],[-95.588183,33.470304],[-95.509007,33.349348],[-95.503096,33.34771],[-95.487551,33.350825],[-95.30664,33.378027],[-95.308637,33.380662],[-95.356094,33.390701],[-95.454087,33.434572]]]},"properties":{"id":"75450","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.090904,33.206798],[-97.096194,33.185628],[-97.03938,33.12992],[-97.002366,33.14251],[-97.019775,33.188336],[-97.092386,33.281836],[-97.11243,33.25395],[-97.090904,33.206798]]]},"properties":{"id":"76208","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.509897,29.110585],[-97.344477,28.925692],[-97.25058,28.905078],[-97.16055,28.97093],[-97.106827,29.010304],[-97.094734,29.019166],[-97.314204,29.29383],[-97.41916,29.253617],[-97.509897,29.110585]]]},"properties":{"id":"77954","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.652545,29.417058],[-97.550796,29.383948],[-97.527167,29.341809],[-97.450564,29.230813],[-97.41916,29.253617],[-97.314204,29.29383],[-97.289155,29.34871],[-97.229117,29.596102],[-97.394954,29.641134],[-97.557704,29.614725],[-97.683873,29.582812],[-97.739639,29.509381],[-97.747513,29.4989],[-97.652545,29.417058]]]},"properties":{"id":"78629","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.040376,33.683674],[-100.960929,33.661237],[-100.907658,33.804441],[-101.041158,33.833625],[-101.040376,33.683674]]]},"properties":{"id":"79243","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.617205,27.828533],[-98.626557,27.583157],[-98.377015,27.416873],[-98.361817,27.47734],[-98.299908,27.617603],[-98.617205,27.828533]]]},"properties":{"id":"78341","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.614471,32.671329],[-98.537909,32.378054],[-98.475372,32.388087],[-98.410743,32.567664],[-98.376334,32.738191],[-98.405527,32.794369],[-98.527871,32.858508],[-98.614471,32.671329]]]},"properties":{"id":"76475","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.142658,32.519252],[-97.086628,32.361104],[-97.012479,32.35652],[-97.014231,32.373009],[-97.086684,32.526015],[-97.142658,32.519252]]]},"properties":{"id":"76084","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.503661,31.883159],[-106.566255,31.907331],[-106.582678,31.908361],[-106.570487,31.839368],[-106.523316,31.806983],[-106.483442,31.803207],[-106.493867,31.863114],[-106.494943,31.868905],[-106.497217,31.883212],[-106.503661,31.883159]]]},"properties":{"id":"79912","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.410743,32.567664],[-98.475372,32.388087],[-98.47554,32.376289],[-98.424585,32.373551],[-98.333321,32.430159],[-98.410743,32.567664]]]},"properties":{"id":"76463","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.059384,33.521263],[-96.009308,33.488891],[-95.969757,33.717019],[-95.993386,33.67763],[-96.056714,33.537668],[-96.059384,33.521263]]]},"properties":{"id":"75492","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.916544,29.444625],[-95.949635,29.374609],[-95.949463,29.335318],[-95.847656,29.26259],[-95.735844,29.319156],[-95.590342,29.37967],[-95.806784,29.486315],[-95.916544,29.444625]]]},"properties":{"id":"77461","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.55598,29.498468],[-95.550622,29.438306],[-95.533027,29.458431],[-95.55598,29.498468]]]},"properties":{"id":"77481","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.58241,29.606242],[-95.582826,29.580582],[-95.555006,29.503881],[-95.55598,29.498468],[-95.533027,29.458431],[-95.509131,29.513209],[-95.505431,29.565054],[-95.541614,29.585491],[-95.58241,29.606242]]]},"properties":{"id":"77459","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.916544,29.444625],[-95.984723,29.540722],[-96.016068,29.43689],[-95.993309,29.450674],[-95.989921,29.429664],[-95.967389,29.38197],[-95.949635,29.374609],[-95.916544,29.444625]]]},"properties":{"id":"77417","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.597837,29.660596],[-95.571273,29.648707],[-95.559121,29.663425],[-95.559727,29.668053],[-95.558012,29.684649],[-95.614466,29.68437],[-95.616075,29.670411],[-95.619202,29.661986],[-95.597837,29.660596]]]},"properties":{"id":"77099","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.434487,33.188774],[-95.401513,33.134031],[-95.337909,33.082546],[-95.308075,33.102071],[-95.307884,33.255938],[-95.367453,33.226447],[-95.434487,33.188774]]]},"properties":{"id":"75478","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.138787,29.008589],[-98.967282,28.95442],[-98.913201,29.090628],[-99.064119,29.110817],[-99.138787,29.008589]]]},"properties":{"id":"78057","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.66383,33.021368],[-103.063844,33.037587],[-103.064701,32.879355],[-102.726149,32.842232],[-102.66383,33.021368]]]},"properties":{"id":"79323","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.957534,29.408034],[-95.04917,29.419155],[-95.041699,29.411575],[-94.951834,29.379156],[-94.957534,29.408034]]]},"properties":{"id":"77591","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.581636,29.491084],[-94.568063,29.478781],[-94.456045,29.522332],[-94.473626,29.542937],[-94.581636,29.491084]]]},"properties":{"id":"77617","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.417307,29.59255],[-94.473626,29.542937],[-94.456045,29.522332],[-94.409819,29.596658],[-94.417307,29.59255]]]},"properties":{"id":"77623","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.586132,31.945966],[-101.709813,31.888969],[-101.775886,31.830257],[-101.775863,31.689094],[-101.656675,31.609332],[-101.386499,31.59239],[-101.267122,31.650854],[-101.265974,31.830328],[-101.337832,31.906594],[-101.586132,31.945966]]]},"properties":{"id":"79739","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.878452,28.845735],[-96.903389,28.845128],[-96.923442,28.834639],[-96.940038,28.827065],[-96.931045,28.818983],[-96.983447,28.7612],[-97.017765,28.828066],[-97.138111,28.897201],[-97.16055,28.97093],[-97.25058,28.905078],[-97.236241,28.864889],[-97.236692,28.696852],[-97.172385,28.69113],[-97.182359,28.610602],[-97.160751,28.553475],[-97.043173,28.531593],[-96.946345,28.606868],[-96.847487,28.63684],[-96.791527,28.667603],[-96.768804,28.725363],[-96.878452,28.845735]]]},"properties":{"id":"77905","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.550796,29.383948],[-97.652545,29.417058],[-97.674106,29.418557],[-97.653957,29.340034],[-97.653193,29.339211],[-97.550796,29.383948]]]},"properties":{"id":"78614","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.550796,29.383948],[-97.653193,29.339211],[-97.527167,29.341809],[-97.550796,29.383948]]]},"properties":{"id":"78677","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.604203,33.687052],[-96.606528,33.553048],[-96.541391,33.484113],[-96.517217,33.488619],[-96.510066,33.516715],[-96.496661,33.52423],[-96.489784,33.575275],[-96.47339,33.676979],[-96.570721,33.695656],[-96.604203,33.687052]]]},"properties":{"id":"75090","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.782907,33.628819],[-96.769122,33.62115],[-96.764914,33.620613],[-96.782907,33.628819]]]},"properties":{"id":"76268","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.668196,32.503806],[-94.760648,32.525193],[-94.756952,32.480368],[-94.756736,32.479672],[-94.668196,32.503806]]]},"properties":{"id":"75601","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.924446,30.591991],[-95.849457,30.620022],[-95.886291,30.614777],[-95.924446,30.591991]]]},"properties":{"id":"77876","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.01022,33.938451],[-101.992846,33.997091],[-102.069884,34.002584],[-102.010299,33.944476],[-102.01022,33.938451]]]},"properties":{"id":"79021","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.865235,34.289773],[-101.999753,34.313041],[-101.987978,34.261416],[-101.865235,34.289773]]]},"properties":{"id":"79032","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.015491,34.313095],[-102.181679,34.273586],[-102.215334,34.130193],[-102.144627,34.064938],[-102.118922,34.02705],[-102.04067,34.159268],[-101.987978,34.261416],[-101.999753,34.313041],[-102.015491,34.313095]]]},"properties":{"id":"79064","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.817634,34.56889],[-100.945337,34.574534],[-100.945401,34.494635],[-100.945794,34.421939],[-100.937938,34.3128],[-100.897842,34.31297],[-100.60352,34.497016],[-100.652568,34.514255],[-100.668894,34.53327],[-100.817634,34.56889]]]},"properties":{"id":"79261","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.440011,36.499374],[-101.467322,36.124542],[-101.395642,36.055235],[-101.269585,36.037105],[-101.085735,36.055276],[-101.036979,36.057887],[-100.999904,36.243767],[-101.085434,36.330015],[-101.085156,36.499244],[-101.440011,36.499374]]]},"properties":{"id":"79081","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.623466,36.055405],[-101.623144,35.985166],[-101.395642,36.055235],[-101.467322,36.124542],[-101.623501,36.087381],[-101.623466,36.055405]]]},"properties":{"id":"79062","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.396213,30.246311],[-94.531124,30.171813],[-94.614838,30.163539],[-94.600843,30.124371],[-94.539858,30.111911],[-94.44491,30.113015],[-94.405697,30.099914],[-94.335638,30.106305],[-94.275451,30.170265],[-94.283697,30.196053],[-94.396213,30.246311]]]},"properties":{"id":"77659","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.396213,30.246311],[-94.571088,30.417247],[-94.626603,30.315936],[-94.531124,30.171813],[-94.396213,30.246311]]]},"properties":{"id":"77585","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.541399,30.170279],[-95.528227,30.088189],[-95.525514,30.085588],[-95.436545,30.08406],[-95.436873,30.110175],[-95.505125,30.145153],[-95.541399,30.170279]]]},"properties":{"id":"77389","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.032918,29.610385],[-95.084243,29.61311],[-95.091403,29.596823],[-95.069201,29.556797],[-95.045077,29.555614],[-95.018205,29.554597],[-94.994746,29.613719],[-95.032918,29.610385]]]},"properties":{"id":"77586","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.292022,29.797833],[-95.35128,29.773606],[-95.352129,29.772125],[-95.347678,29.766512],[-95.328867,29.759838],[-95.293669,29.74722],[-95.288746,29.799291],[-95.292022,29.797833]]]},"properties":{"id":"77020","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.28014,29.802473],[-95.218354,29.771169],[-95.212797,29.805205],[-95.229925,29.82456],[-95.261614,29.810916],[-95.28014,29.802473]]]},"properties":{"id":"77013","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.361526,29.751601],[-95.367436,29.744123],[-95.342067,29.731823],[-95.333109,29.743215],[-95.328867,29.759838],[-95.347678,29.766512],[-95.357715,29.754824],[-95.361526,29.751601]]]},"properties":{"id":"77003","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.351691,29.812858],[-95.391618,29.813876],[-95.387782,29.787622],[-95.366719,29.77393],[-95.352129,29.772125],[-95.35128,29.773606],[-95.351691,29.812858]]]},"properties":{"id":"77009","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.453334,29.897511],[-95.483258,29.937567],[-95.521692,29.936627],[-95.504096,29.899164],[-95.494914,29.889204],[-95.453334,29.897511]]]},"properties":{"id":"77086","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.212386,29.981848],[-95.195996,29.941986],[-95.131541,29.987667],[-95.130156,29.991986],[-95.132047,30.021156],[-95.176531,30.027324],[-95.213933,30.024088],[-95.212386,29.981848]]]},"properties":{"id":"77346","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.388369,29.965973],[-95.417761,29.965747],[-95.421866,29.962576],[-95.424577,29.939632],[-95.412403,29.931814],[-95.372894,29.902208],[-95.379616,29.932788],[-95.388369,29.965973]]]},"properties":{"id":"77060","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.40297,29.841162],[-95.461006,29.841009],[-95.440882,29.808874],[-95.391689,29.814064],[-95.40297,29.841162]]]},"properties":{"id":"77018","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.584667,29.890168],[-95.506297,29.834805],[-95.486772,29.865752],[-95.487134,29.866543],[-95.494914,29.889204],[-95.504096,29.899164],[-95.584504,29.897687],[-95.584667,29.890168]]]},"properties":{"id":"77040","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.718929,29.784688],[-95.705921,29.734703],[-95.653982,29.735466],[-95.646552,29.769702],[-95.644788,29.784716],[-95.718929,29.784688]]]},"properties":{"id":"77094","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.356286,29.648215],[-95.351907,29.585455],[-95.297409,29.596514],[-95.308757,29.634591],[-95.314894,29.654952],[-95.356286,29.648215]]]},"properties":{"id":"77048","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.351907,29.585455],[-95.356286,29.648215],[-95.38728,29.632212],[-95.419592,29.613628],[-95.431027,29.583509],[-95.351907,29.585455]]]},"properties":{"id":"77047","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.270786,30.043177],[-95.190143,30.083978],[-95.16216,30.084188],[-95.311081,30.15974],[-95.345326,30.145252],[-95.270786,30.043177]]]},"properties":{"id":"77365","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.42997,29.738337],[-95.433426,29.733402],[-95.429973,29.732624],[-95.42997,29.738337]]]},"properties":{"id":"77046","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.206699,32.359065],[-94.141167,32.37234],[-94.157956,32.376785],[-94.206699,32.359065]]]},"properties":{"id":"75642","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.471994,33.196182],[-99.597047,33.269044],[-99.725826,33.252596],[-99.807042,33.261634],[-99.807109,33.253493],[-99.836924,33.151746],[-99.829177,33.08143],[-99.680198,32.957514],[-99.620869,32.956962],[-99.519363,32.996864],[-99.471445,33.027427],[-99.471994,33.196182]]]},"properties":{"id":"79521","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.99085,33.335336],[-99.958178,33.282471],[-99.807109,33.253493],[-99.807042,33.261634],[-99.724583,33.320868],[-99.735792,33.362571],[-99.770413,33.361625],[-99.976265,33.335501],[-99.99085,33.335336]]]},"properties":{"id":"79544","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.836924,33.151746],[-99.989656,33.107849],[-99.989301,33.039074],[-99.951066,32.959866],[-99.829177,33.08143],[-99.836924,33.151746]]]},"properties":{"id":"79548","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.829177,33.08143],[-99.951066,32.959866],[-99.985726,32.938876],[-99.986579,32.866224],[-99.765066,32.838791],[-99.680198,32.957514],[-99.829177,33.08143]]]},"properties":{"id":"79553","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.28561,32.266895],[-96.150479,32.214127],[-96.121318,32.246993],[-96.167668,32.357996],[-96.194588,32.491726],[-96.30749,32.480286],[-96.452138,32.358631],[-96.383082,32.328851],[-96.28561,32.266895]]]},"properties":{"id":"75143","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.055269,32.338656],[-96.035846,32.231172],[-95.963457,32.228134],[-95.945384,32.272445],[-95.862526,32.406881],[-95.955035,32.40664],[-96.055269,32.338656]]]},"properties":{"id":"75124","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.890321,26.433308],[-97.972429,26.444233],[-98.061637,26.427451],[-98.021519,26.314447],[-97.976832,26.279148],[-97.97238,26.257557],[-97.944684,26.286872],[-97.922991,26.356649],[-97.890321,26.433308]]]},"properties":{"id":"78538","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.164966,26.082203],[-98.125689,26.240784],[-98.159155,26.241899],[-98.164966,26.082203]]]},"properties":{"id":"78589","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540521,26.253704],[-98.508671,26.225528],[-98.449305,26.223307],[-98.447233,26.228169],[-98.516745,26.286431],[-98.540521,26.253704]]]},"properties":{"id":"78560","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.159867,26.540613],[-98.111976,26.695474],[-98.193184,26.782415],[-98.32067,26.783081],[-98.367704,26.685771],[-98.43331,26.556885],[-98.447247,26.530636],[-98.159867,26.540613]]]},"properties":{"id":"78563","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.076301,32.109648],[-97.266643,32.11171],[-97.269643,32.110245],[-97.297196,32.059307],[-97.202591,31.898556],[-97.167123,31.905501],[-97.02322,31.96292],[-97.001841,32.015332],[-96.968481,32.045568],[-96.978855,32.087311],[-97.076301,32.109648]]]},"properties":{"id":"76645","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.886156,31.870389],[-96.977388,31.897736],[-96.994575,31.897427],[-96.977618,31.823289],[-96.907908,31.843012],[-96.886156,31.870389]]]},"properties":{"id":"76676","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.896209,32.073977],[-96.978855,32.087311],[-96.968481,32.045568],[-96.912841,32.008381],[-96.900832,32.000103],[-96.857387,32.016379],[-96.896209,32.073977]]]},"properties":{"id":"76666","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.002383,31.811081],[-97.201854,31.844393],[-97.224495,31.771608],[-97.128277,31.710756],[-97.025591,31.724321],[-97.013661,31.737105],[-97.005519,31.729729],[-96.996362,31.725243],[-96.973964,31.764759],[-97.002383,31.811081]]]},"properties":{"id":"76691","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.977388,31.897736],[-96.884941,31.976445],[-96.900832,32.000103],[-96.912841,32.008381],[-97.001841,32.015332],[-97.02322,31.96292],[-96.994575,31.897427],[-96.977388,31.897736]]]},"properties":{"id":"76631","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.38317,33.736431],[-102.328011,33.723919],[-102.309213,33.738993],[-102.38317,33.736431]]]},"properties":{"id":"79380","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.648062,32.641078],[-97.598953,32.776319],[-97.592148,32.826446],[-97.676799,32.816153],[-97.742174,32.756973],[-97.837383,32.728372],[-97.917306,32.716704],[-97.92541,32.615985],[-97.87582,32.557269],[-97.846529,32.503283],[-97.724928,32.545622],[-97.648062,32.641078]]]},"properties":{"id":"76087","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.520591,32.96136],[-95.467999,32.934891],[-95.414227,32.905071],[-95.38837,32.941567],[-95.421479,33.091185],[-95.520591,32.96136]]]},"properties":{"id":"75431","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.286013,31.160562],[-95.208339,31.213852],[-95.302884,31.221346],[-95.322976,31.188148],[-95.286013,31.160562]]]},"properties":{"id":"75856","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.594819,32.486463],[-101.764691,32.41982],[-101.69058,32.37634],[-101.594819,32.486463]]]},"properties":{"id":"79748","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.980257,32.000538],[-105.270984,31.969565],[-105.236231,31.881195],[-104.980257,32.000538]]]},"properties":{"id":"79837","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.951709,31.500203],[-101.093273,31.476179],[-101.109685,31.08075],[-100.962176,31.08249],[-100.688764,31.086576],[-100.689809,31.220219],[-100.691931,31.413446],[-100.951709,31.500203]]]},"properties":{"id":"76941","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.093273,31.476179],[-101.273775,31.150486],[-101.273836,31.14692],[-101.245487,31.079578],[-101.109685,31.08075],[-101.093273,31.476179]]]},"properties":{"id":"76930","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.436201,31.544347],[-100.433327,31.66732],[-100.441592,31.693073],[-100.675726,31.694343],[-100.674841,31.630815],[-100.634483,31.561627],[-100.693299,31.523956],[-100.755678,31.524723],[-100.951709,31.500203],[-100.691931,31.413446],[-100.456029,31.442973],[-100.436201,31.544347]]]},"properties":{"id":"76901","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.191517,33.004847],[-98.096623,32.945908],[-98.005096,32.885445],[-98.016398,32.928151],[-98.022227,32.937594],[-98.017801,32.961807],[-97.98986,32.96975],[-97.948784,33.033638],[-98.000385,33.095644],[-98.191517,33.004847]]]},"properties":{"id":"76486","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.590297,29.205697],[-96.383304,28.922588],[-96.310686,28.922589],[-96.310593,28.926315],[-96.287345,28.974817],[-96.496731,29.325849],[-96.640315,29.247804],[-96.590297,29.205697]]]},"properties":{"id":"77455","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.902633,31.118026],[-94.128992,31.099402],[-94.129632,31.09928],[-94.298196,31.036993],[-94.198037,30.853293],[-94.139803,30.779118],[-93.87123,30.768203],[-93.866219,30.930167],[-93.902633,31.118026]]]},"properties":{"id":"75951","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.027775,30.287615],[-94.117688,30.298402],[-94.117686,30.241779],[-94.115917,30.160347],[-94.091038,30.094736],[-94.075584,30.076642],[-94.031776,30.058812],[-93.915707,30.130568],[-93.922714,30.242572],[-94.027775,30.287615]]]},"properties":{"id":"77662","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.03058,30.026222],[-94.009113,29.956548],[-93.994251,29.945977],[-93.969387,29.961413],[-93.997834,30.013677],[-94.03058,30.026222]]]},"properties":{"id":"77627","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.944758,29.953104],[-93.917666,29.924751],[-93.890604,29.942832],[-93.918379,29.963188],[-93.944758,29.953104]]]},"properties":{"id":"77619","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.232719,27.33493],[-98.058523,27.346712],[-98.233072,27.461142],[-98.232719,27.33493]]]},"properties":{"id":"78375","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.220946,32.621771],[-97.215774,32.573811],[-97.18626,32.519154],[-97.177477,32.516768],[-97.142658,32.519252],[-97.086684,32.526015],[-97.065507,32.549075],[-97.05262,32.548907],[-97.061339,32.584981],[-97.078174,32.609085],[-97.117533,32.610247],[-97.192306,32.619315],[-97.220946,32.621771]]]},"properties":{"id":"76063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.146013,32.609552],[-100.219221,32.414046],[-100.171023,32.378747],[-100.149547,32.281181],[-100.038693,32.267038],[-99.978359,32.312355],[-99.958557,32.399895],[-99.878485,32.45893],[-99.841792,32.520902],[-99.847646,32.539993],[-99.971658,32.645975],[-100.072694,32.674621],[-100.146013,32.609552]]]},"properties":{"id":"79536","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.971658,32.645975],[-99.720282,32.678278],[-99.713461,32.750718],[-99.765066,32.838791],[-99.986579,32.866224],[-100.084045,32.711505],[-100.072694,32.674621],[-99.971658,32.645975]]]},"properties":{"id":"79501","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.80662,28.918828],[-97.653782,28.756962],[-97.575057,28.813006],[-97.720068,28.969507],[-97.740474,28.991377],[-97.80662,28.918828]]]},"properties":{"id":"78151","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.793723,26.958735],[-97.784282,26.845629],[-97.712634,26.836389],[-97.793723,26.958735]]]},"properties":{"id":"78338","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.840327,27.315769],[-97.985887,27.209308],[-97.727276,27.266019],[-97.702626,27.375927],[-97.840327,27.315769]]]},"properties":{"id":"78379","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.648727,33.638416],[-99.935024,33.651547],[-99.993072,33.580143],[-99.9926,33.536624],[-99.944781,33.496482],[-99.739928,33.510228],[-99.623985,33.546262],[-99.648727,33.638416]]]},"properties":{"id":"79505","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.202819,30.442759],[-97.222095,30.41369],[-97.169728,30.317988],[-97.060506,30.306138],[-96.927455,30.383879],[-96.86807,30.433791],[-96.963629,30.557169],[-97.051648,30.51156],[-97.155219,30.457344],[-97.202819,30.442759]]]},"properties":{"id":"78947","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442235,29.889538],[-94.44491,30.113015],[-94.539858,30.111911],[-94.605284,30.054625],[-94.639028,29.888384],[-94.534569,29.889506],[-94.442235,29.889538]]]},"properties":{"id":"77538","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.729177,30.159],[-94.729565,30.166998],[-94.742,30.17748],[-94.737175,30.15913],[-94.729177,30.159]]]},"properties":{"id":"77561","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.440597,28.445592],[-98.334347,28.266147],[-98.110389,28.451281],[-98.104609,28.452693],[-98.098637,28.442964],[-98.038584,28.438868],[-97.984916,28.485552],[-98.032567,28.566346],[-98.090516,28.668791],[-98.182686,28.670708],[-98.334526,28.529811],[-98.440597,28.445592]]]},"properties":{"id":"78071","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.93952,33.644263],[-101.889204,33.584635],[-101.870617,33.592313],[-101.853224,33.60671],[-101.840167,33.722535],[-101.84162,33.737373],[-101.854021,33.795299],[-101.957364,33.78077],[-101.93952,33.644263]]]},"properties":{"id":"79415","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.870521,33.563277],[-101.867251,33.577771],[-101.870601,33.577789],[-101.900462,33.57771],[-101.922457,33.563237],[-101.90514,33.562291],[-101.870521,33.563277]]]},"properties":{"id":"79410","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.87046,33.562346],[-101.870403,33.529365],[-101.853069,33.529351],[-101.845639,33.563283],[-101.87046,33.562346]]]},"properties":{"id":"79412","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.602505,31.392843],[-99.511477,31.424401],[-99.574234,31.49368],[-99.60185,31.49195],[-99.602505,31.392843]]]},"properties":{"id":"76836","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.57956,31.551735],[-97.545242,31.502252],[-97.532979,31.484592],[-97.328981,31.544361],[-97.294133,31.600807],[-97.344477,31.615965],[-97.57956,31.551735]]]},"properties":{"id":"76638","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.825813,29.090402],[-98.865404,29.273801],[-98.877933,29.283965],[-98.949636,29.29672],[-99.08582,29.202532],[-99.064119,29.110817],[-98.913201,29.090628],[-98.825813,29.090402]]]},"properties":{"id":"78016","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.252486,31.970816],[-102.28735,31.885472],[-102.28743,31.873929],[-102.160484,31.651608],[-102.061996,31.651593],[-101.775802,31.651319],[-101.775863,31.689094],[-101.775886,31.830257],[-101.775878,31.907452],[-101.932473,32.086857],[-101.995109,32.086772],[-102.057304,32.020398],[-102.107132,31.964514],[-102.154556,31.998341],[-102.252486,31.970816]]]},"properties":{"id":"79706","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.822727,31.584889],[-98.942737,31.458629],[-98.846241,31.410478],[-98.567566,31.57943],[-98.557538,31.672142],[-98.668441,31.700514],[-98.822727,31.584889]]]},"properties":{"id":"76864","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.66389,32.25901],[-100.738091,32.545586],[-100.783395,32.525427],[-100.664008,32.230204],[-100.66389,32.25901]]]},"properties":{"id":"79532","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.598878,30.50898],[-95.570066,30.36386],[-95.486692,30.391247],[-95.491265,30.508833],[-95.598878,30.50898]]]},"properties":{"id":"77318","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.846689,36.096479],[-101.900728,36.023989],[-101.822782,35.907725],[-101.811041,35.929563],[-101.664358,35.965106],[-101.623466,36.055405],[-101.623501,36.087381],[-101.794822,36.197525],[-101.846689,36.096479]]]},"properties":{"id":"79086","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.832496,35.689557],[-101.782406,35.689107],[-101.77848,35.719546],[-101.824381,35.736391],[-101.832496,35.689557]]]},"properties":{"id":"79105","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.518378,33.910158],[-100.666839,34.011891],[-100.995541,33.90472],[-100.905728,33.834277],[-100.518691,33.83565],[-100.518528,33.875558],[-100.518378,33.910158]]]},"properties":{"id":"79256","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.74362,30.974951],[-93.866219,30.930167],[-93.87123,30.768203],[-93.810009,30.694889],[-93.608414,30.746664],[-93.550742,30.921233],[-93.74362,30.974951]]]},"properties":{"id":"75966","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.547455,27.787462],[-97.469631,27.78567],[-97.484472,27.804981],[-97.560418,27.848449],[-97.547455,27.787462]]]},"properties":{"id":"78409","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.161735,27.674065],[-97.074859,27.797934],[-97.176163,27.763185],[-97.188668,27.694672],[-97.161735,27.674065]]]},"properties":{"id":"78373","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.02286,32.795803],[-98.005096,32.885445],[-98.096623,32.945908],[-98.211864,32.823808],[-98.172597,32.702819],[-98.063363,32.678113],[-98.02286,32.795803]]]},"properties":{"id":"76067","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.063363,32.678113],[-98.064369,32.637236],[-97.92541,32.615985],[-97.917306,32.716704],[-98.02286,32.795803],[-98.063363,32.678113]]]},"properties":{"id":"76066","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.042567,32.185042],[-94.042901,32.392283],[-94.141167,32.37234],[-94.206699,32.359065],[-94.347563,32.330682],[-94.30886,32.228321],[-94.042567,32.185042]]]},"properties":{"id":"75639","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.166107,31.893473],[-94.131552,32.002508],[-94.266678,32.066885],[-94.306478,31.959168],[-94.296247,31.849025],[-94.166107,31.893473]]]},"properties":{"id":"75974","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.800781,32.791922],[-97.744176,32.871061],[-97.789165,32.923944],[-97.98986,32.96975],[-98.017801,32.961807],[-98.016398,32.928151],[-98.005096,32.885445],[-98.02286,32.795803],[-97.917306,32.716704],[-97.837383,32.728372],[-97.800781,32.791922]]]},"properties":{"id":"76088","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.598953,32.776319],[-97.648062,32.641078],[-97.57594,32.59715],[-97.503949,32.722071],[-97.503784,32.727885],[-97.598953,32.776319]]]},"properties":{"id":"76008","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.017801,32.961807],[-98.022227,32.937594],[-98.016398,32.928151],[-98.017801,32.961807]]]},"properties":{"id":"76490","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.077148,31.302343],[-103.066533,30.998957],[-102.977119,31.199188],[-103.077148,31.302343]]]},"properties":{"id":"79730","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.868091,30.84907],[-94.880985,30.829165],[-94.870208,30.821947],[-94.868091,30.84907]]]},"properties":{"id":"77350","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.835609,35.18286],[-101.778776,35.192674],[-101.812898,35.193058],[-101.831886,35.192456],[-101.835609,35.18286]]]},"properties":{"id":"79103","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.901831,35.129209],[-101.920165,35.162473],[-101.941564,35.182969],[-102.046299,35.183179],[-102.114599,35.183222],[-102.099413,35.058493],[-101.919054,35.043264],[-101.906567,35.121726],[-101.901831,35.129209]]]},"properties":{"id":"79119","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.168437,34.853304],[-102.115669,34.883489],[-102.043987,34.883275],[-102.043787,34.912381],[-102.133219,34.914936],[-102.167584,34.883807],[-102.168437,34.853304]]]},"properties":{"id":"79091","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.049274,33.864576],[-95.193154,33.63402],[-95.201179,33.558182],[-95.154973,33.515655],[-94.935995,33.451827],[-94.900617,33.584963],[-94.791237,33.738896],[-95.049274,33.864576]]]},"properties":{"id":"75426","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.935995,33.451827],[-94.904571,33.38937],[-94.826286,33.378808],[-94.900617,33.584963],[-94.935995,33.451827]]]},"properties":{"id":"75550","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.71148,31.01984],[-103.927086,30.990189],[-103.79088,30.901978],[-103.40889,30.952018],[-103.71148,31.01984]]]},"properties":{"id":"79718","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.093993,31.871775],[-100.160667,31.919531],[-100.13622,31.856876],[-100.093993,31.871775]]]},"properties":{"id":"76865","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.761004,32.031615],[-94.929681,31.934835],[-94.927175,31.876113],[-94.826161,31.918177],[-94.761004,32.031615]]]},"properties":{"id":"75667","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.734013,32.318838],[-94.817964,32.283733],[-94.918474,32.177857],[-94.589438,32.12882],[-94.582581,32.14948],[-94.571272,32.219395],[-94.606221,32.36023],[-94.734013,32.318838]]]},"properties":{"id":"75652","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.686275,27.974934],[-97.669448,27.968688],[-97.67716,27.951143],[-97.63139,27.891706],[-97.558199,27.860182],[-97.506879,27.878156],[-97.507008,27.925511],[-97.705386,28.02983],[-97.686275,27.974934]]]},"properties":{"id":"78370","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.456129,32.325896],[-95.362228,32.34276],[-95.34941,32.3915],[-95.431137,32.468944],[-95.554859,32.454107],[-95.473561,32.374066],[-95.456129,32.325896]]]},"properties":{"id":"75704","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.362228,32.34276],[-95.456129,32.325896],[-95.453913,32.307108],[-95.411172,32.279024],[-95.337264,32.304867],[-95.34066,32.342672],[-95.362228,32.34276]]]},"properties":{"id":"75709","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.324485,32.180394],[-95.411172,32.279024],[-95.453913,32.307108],[-95.472914,32.145077],[-95.472796,32.145051],[-95.324485,32.180394]]]},"properties":{"id":"75762","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.701116,32.51686],[-95.594325,32.496568],[-95.580803,32.569904],[-95.594185,32.564816],[-95.698226,32.540647],[-95.701116,32.51686]]]},"properties":{"id":"75790","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.63843,26.266754],[-98.657471,26.301247],[-98.670886,26.260912],[-98.63843,26.266754]]]},"properties":{"id":"78548","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.096016,32.957037],[-99.095971,32.676454],[-99.05638,32.629487],[-98.843135,32.582056],[-98.760327,32.60986],[-98.690283,32.833752],[-98.677425,32.955937],[-98.879463,32.990105],[-98.951665,33.055525],[-99.096016,32.957037]]]},"properties":{"id":"76424","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.115545,32.644714],[-97.063594,32.643399],[-97.062762,32.677202],[-97.114215,32.682521],[-97.115085,32.678252],[-97.115545,32.644714]]]},"properties":{"id":"76018","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.231466,32.668113],[-97.306024,32.669581],[-97.306545,32.669587],[-97.331328,32.614019],[-97.32725,32.589892],[-97.215774,32.573811],[-97.220946,32.621771],[-97.231466,32.668113]]]},"properties":{"id":"76140","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503949,32.722071],[-97.429305,32.693196],[-97.424185,32.696424],[-97.411657,32.710397],[-97.414404,32.751291],[-97.435248,32.748818],[-97.503784,32.727885],[-97.503949,32.722071]]]},"properties":{"id":"76116","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.352452,32.736008],[-97.311065,32.705944],[-97.297943,32.711767],[-97.296161,32.743811],[-97.303184,32.748376],[-97.307087,32.74792],[-97.352452,32.736008]]]},"properties":{"id":"76104","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.245121,32.732639],[-97.237598,32.720684],[-97.20883,32.713174],[-97.20271,32.713348],[-97.18242,32.730759],[-97.21267,32.781365],[-97.238194,32.777901],[-97.255188,32.775029],[-97.245121,32.732639]]]},"properties":{"id":"76112","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.207474,32.822826],[-97.248965,32.807823],[-97.238194,32.777901],[-97.21267,32.781365],[-97.140166,32.793901],[-97.14017,32.803311],[-97.207474,32.822826]]]},"properties":{"id":"76118","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503396,32.875341],[-97.536908,32.825355],[-97.442761,32.788654],[-97.413277,32.790819],[-97.411349,32.813187],[-97.394216,32.822363],[-97.503396,32.875341]]]},"properties":{"id":"76135","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.716539,32.459907],[-99.713647,32.459849],[-99.713236,32.463817],[-99.718446,32.463529],[-99.716539,32.459907]]]},"properties":{"id":"79699","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.543515,33.036427],[-102.43417,33.043751],[-102.532855,33.06543],[-102.543515,33.036427]]]},"properties":{"id":"79378","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.953938,33.397534],[-99.170971,33.398303],[-98.952698,33.183523],[-98.691832,33.216901],[-98.953938,33.397534]]]},"properties":{"id":"76372","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.673946,30.480093],[-97.704685,30.478481],[-97.721149,30.455835],[-97.720718,30.453129],[-97.671862,30.424528],[-97.671594,30.424418],[-97.670706,30.469875],[-97.673946,30.480093]]]},"properties":{"id":"78728","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.846688,30.141121],[-97.853761,30.177403],[-97.880168,30.196449],[-97.908562,30.167742],[-97.846688,30.141121]]]},"properties":{"id":"78739","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.035242,30.962916],[-94.887049,31.09862],[-95.073322,31.228745],[-95.208339,31.213852],[-95.286013,31.160562],[-95.306442,31.06582],[-95.093466,30.914278],[-95.061113,30.941302],[-95.035242,30.962916]]]},"properties":{"id":"75845","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.615996,30.830061],[-94.58166,30.681598],[-94.392057,30.66144],[-94.19193,30.698576],[-94.128272,30.745705],[-94.139803,30.779118],[-94.198037,30.853293],[-94.522344,30.930482],[-94.615996,30.830061]],[[-94.430909,30.808734],[-94.441523,30.817178],[-94.429062,30.818219],[-94.430909,30.808734]]]},"properties":{"id":"75979","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.430909,30.808734],[-94.429062,30.818219],[-94.441523,30.817178],[-94.430909,30.808734]]]},"properties":{"id":"75942","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.024572,31.262649],[-102.023499,31.082866],[-101.862201,31.079771],[-101.978055,31.27998],[-102.024572,31.262649]]]},"properties":{"id":"79778","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.878452,28.845735],[-96.900176,28.846813],[-96.903389,28.845128],[-96.878452,28.845735]]]},"properties":{"id":"77988","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.138111,28.897201],[-97.106827,29.010304],[-97.16055,28.97093],[-97.138111,28.897201]]]},"properties":{"id":"77976","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.12092,27.581015],[-99.120547,27.66945],[-99.222875,27.613786],[-99.410338,27.546486],[-99.480337,27.53057],[-99.478921,27.489272],[-99.197428,27.472218],[-99.12092,27.581015]]]},"properties":{"id":"78043","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.944493,27.454387],[-98.911563,27.3504],[-98.798489,27.389964],[-98.799235,27.504338],[-98.800822,27.616923],[-98.944493,27.454387]]]},"properties":{"id":"78344","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.254372,27.85429],[-99.222875,27.613786],[-99.120547,27.66945],[-98.918468,27.74313],[-99.202351,27.862571],[-99.254372,27.85429]]]},"properties":{"id":"78044","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.176448,29.253359],[-96.042668,29.162704],[-96.09892,29.238295],[-96.176448,29.253359]]]},"properties":{"id":"77467","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.194607,29.343907],[-96.263753,29.345122],[-96.176448,29.253359],[-96.09892,29.238295],[-96.042668,29.162704],[-96.038643,29.106552],[-95.979926,29.138643],[-95.933701,29.175398],[-95.957154,29.203634],[-96.00288,29.177002],[-96.020123,29.246284],[-95.949463,29.335318],[-95.949635,29.374609],[-95.967389,29.38197],[-96.068586,29.400629],[-96.111638,29.413291],[-96.190289,29.460795],[-96.215003,29.434303],[-96.23139,29.395797],[-96.197676,29.351612],[-96.190899,29.347598],[-96.194607,29.343907]]]},"properties":{"id":"77488","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.020123,29.246284],[-96.00288,29.177002],[-95.957154,29.203634],[-96.020123,29.246284]]]},"properties":{"id":"77453","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.533796,33.90955],[-98.511859,33.890294],[-98.507366,33.877286],[-98.424432,33.882732],[-98.469964,33.932895],[-98.533796,33.90955]]]},"properties":{"id":"76301","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.424432,33.882732],[-98.507366,33.877286],[-98.51917,33.835649],[-98.424432,33.882732]]]},"properties":{"id":"76302","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.426613,34.312981],[-99.391334,34.321919],[-99.384895,34.350169],[-99.429073,34.345391],[-99.426613,34.312981]]]},"properties":{"id":"79247","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.828823,30.696252],[-97.651837,30.700951],[-97.626574,30.773841],[-97.689025,30.819481],[-97.87706,30.774885],[-97.828823,30.696252]]]},"properties":{"id":"78633","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.302585,30.747562],[-97.560137,30.75866],[-97.439031,30.644188],[-97.253197,30.693216],[-97.302585,30.747562]]]},"properties":{"id":"76530","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.152471,31.78439],[-102.925212,31.651391],[-102.798939,31.651783],[-102.798375,31.882956],[-103.289896,31.792177],[-103.167711,31.825397],[-103.152471,31.78439]]]},"properties":{"id":"79745","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.421071,33.263845],[-98.420856,33.349263],[-98.420666,33.396195],[-98.538684,33.395846],[-98.606315,33.222484],[-98.421071,33.263845]]]},"properties":{"id":"76460","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.411735,28.838022],[-99.41388,29.091335],[-99.55112,29.090378],[-99.731559,29.089252],[-99.733485,28.985008],[-99.724755,28.890614],[-99.569555,28.642503],[-99.409021,28.640566],[-99.411735,28.838022]]]},"properties":{"id":"78829","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.516103,32.523079],[-102.99628,32.523154],[-102.343811,32.087194],[-102.307876,32.087157],[-102.516103,32.523079]]]},"properties":{"id":"79714","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.605881,33.710707],[-98.707449,33.834691],[-98.784962,33.834333],[-98.952933,33.785211],[-98.953408,33.627058],[-98.791757,33.600974],[-98.605881,33.710707]]]},"properties":{"id":"76366","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.511859,33.890294],[-98.560961,33.873924],[-98.51917,33.835649],[-98.507366,33.877286],[-98.511859,33.890294]]]},"properties":{"id":"76308","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.500792,28.793739],[-98.467424,28.71498],[-98.393791,28.764528],[-98.500792,28.793739]]]},"properties":{"id":"78012","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.581752,29.26182],[-98.573927,29.171539],[-98.490395,29.107425],[-98.431298,29.122507],[-98.408391,29.234158],[-98.491365,29.26387],[-98.581752,29.26182]]]},"properties":{"id":"78264","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.734966,29.22671],[-98.711041,29.137331],[-98.627971,29.156571],[-98.688554,29.236058],[-98.734966,29.22671]]]},"properties":{"id":"78069","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.476137,29.806125],[-96.491168,29.768992],[-96.469358,29.801381],[-96.476137,29.806125]]],[[[-96.477994,29.82055],[-96.366433,29.916888],[-96.465286,29.983768],[-96.52622,30.006051],[-96.541971,30.011174],[-96.594908,29.835619],[-96.477994,29.82055]]]]},"properties":{"id":"78950","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.46523,29.98396],[-96.483338,30.03728],[-96.52622,30.006051],[-96.465286,29.983768],[-96.46523,29.98396]]]},"properties":{"id":"78944","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.993935,29.815405],[-98.986066,29.58878],[-98.862581,29.630834],[-98.806552,29.690709],[-98.917725,29.781398],[-98.989656,29.813495],[-98.993935,29.815405]]]},"properties":{"id":"78063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.710678,29.578188],[-99.725412,29.577199],[-99.718111,29.577411],[-99.710678,29.578188]]],[[[-99.678343,29.570868],[-99.412434,29.463269],[-99.355986,29.483572],[-99.378833,29.627425],[-99.41163,29.735809],[-99.473275,29.762577],[-99.603318,29.740258],[-99.60323,29.689592],[-99.636718,29.626676],[-99.678343,29.570868]]]]},"properties":{"id":"78884","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.805684,29.548014],[-98.667531,29.553482],[-98.667268,29.553739],[-98.64505,29.577562],[-98.643901,29.623088],[-98.644186,29.623235],[-98.710267,29.65844],[-98.806552,29.690709],[-98.862581,29.630834],[-98.805684,29.548014]]]},"properties":{"id":"78023","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.222735,30.112009],[-97.349324,29.96859],[-97.353285,29.954574],[-97.240729,29.892753],[-97.194235,29.896776],[-97.103313,29.923621],[-97.046202,29.984485],[-97.060427,30.042688],[-97.04821,30.137947],[-97.222735,30.112009]]]},"properties":{"id":"78957","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.230921,30.196661],[-97.222735,30.112009],[-97.04821,30.137947],[-97.024923,30.231757],[-97.060506,30.306138],[-97.169728,30.317988],[-97.230921,30.196661]]]},"properties":{"id":"78659","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.439647,30.414406],[-97.501431,30.208451],[-97.429769,30.190761],[-97.267289,30.282712],[-97.222095,30.41369],[-97.202819,30.442759],[-97.281784,30.488188],[-97.439647,30.414406]]]},"properties":{"id":"78621","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.005337,28.652044],[-98.002486,28.623478],[-97.98605,28.623596],[-97.986204,28.652606],[-98.005337,28.652044]]]},"properties":{"id":"78145","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.791872,28.552117],[-97.722331,28.55714],[-97.705933,28.567465],[-97.753645,28.643857],[-97.823418,28.55231],[-97.791872,28.552117]]]},"properties":{"id":"78162","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.823418,28.55231],[-97.845252,28.526079],[-97.815887,28.527386],[-97.791872,28.552117],[-97.823418,28.55231]]]},"properties":{"id":"78142","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.72021,31.09091],[-97.707741,31.128033],[-97.753403,31.111298],[-97.750406,31.110243],[-97.72021,31.09091]]]},"properties":{"id":"76541","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.707741,31.128033],[-97.72021,31.09091],[-97.685536,31.075112],[-97.641756,31.089451],[-97.623188,31.099381],[-97.62075,31.118497],[-97.707741,31.128033]]],[[[-97.62075,31.118497],[-97.637103,31.20267],[-97.634776,31.209749],[-97.750001,31.150244],[-97.62075,31.118497]]]]},"properties":{"id":"76543","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.617321,31.05695],[-97.623188,31.099381],[-97.641756,31.089451],[-97.617321,31.05695]]]},"properties":{"id":"76559","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.466519,31.164609],[-97.393451,30.993412],[-97.338143,30.996317],[-97.334578,31.058609],[-97.428088,31.201828],[-97.495441,31.206368],[-97.466519,31.164609]]]},"properties":{"id":"76502","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503512,31.325982],[-97.495441,31.206368],[-97.428088,31.201828],[-97.355164,31.19034],[-97.278113,31.279799],[-97.265881,31.336416],[-97.260917,31.369391],[-97.307083,31.413464],[-97.383026,31.397542],[-97.461556,31.381893],[-97.474466,31.319234],[-97.503512,31.325982]]]},"properties":{"id":"76557","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540503,29.602174],[-98.525684,29.572435],[-98.514332,29.579157],[-98.508264,29.608741],[-98.540503,29.602174]]]},"properties":{"id":"78248","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.384814,29.568369],[-98.351737,29.59184],[-98.394851,29.630601],[-98.443169,29.602648],[-98.46732,29.555513],[-98.43585,29.546195],[-98.384814,29.568369]]]},"properties":{"id":"78247","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.394577,29.521868],[-98.357636,29.491437],[-98.339515,29.506186],[-98.35294,29.530446],[-98.394577,29.521868]]]},"properties":{"id":"78239","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.643901,29.623088],[-98.630989,29.587723],[-98.598592,29.590911],[-98.630794,29.664197],[-98.644186,29.623235],[-98.643901,29.623088]]]},"properties":{"id":"78256","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.548904,29.400019],[-98.531057,29.396979],[-98.528832,29.398243],[-98.496296,29.424122],[-98.497006,29.430952],[-98.503978,29.4409],[-98.540291,29.446609],[-98.54412,29.43722],[-98.548904,29.400019]]]},"properties":{"id":"78207","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.443103,29.409422],[-98.476222,29.412011],[-98.490576,29.414332],[-98.499007,29.387923],[-98.477814,29.374153],[-98.427644,29.384286],[-98.427214,29.398484],[-98.443103,29.409422]]]},"properties":{"id":"78210","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.431965,29.513402],[-98.46917,29.51845],[-98.481807,29.491521],[-98.472488,29.46149],[-98.472382,29.451691],[-98.430187,29.484786],[-98.431965,29.513402]]]},"properties":{"id":"78209","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.52215,29.362543],[-98.491365,29.26387],[-98.408391,29.234158],[-98.406762,29.2437],[-98.431976,29.274941],[-98.516836,29.370027],[-98.52215,29.362543]]]},"properties":{"id":"78221","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.443068,29.419638],[-98.336051,29.41863],[-98.31805,29.456223],[-98.328882,29.455309],[-98.377831,29.47779],[-98.428029,29.456401],[-98.441133,29.44089],[-98.442597,29.435356],[-98.443068,29.419638]]]},"properties":{"id":"78219","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.561164,29.529641],[-98.595587,29.487055],[-98.592944,29.485045],[-98.567441,29.489211],[-98.55251,29.495588],[-98.549141,29.499824],[-98.561164,29.529641]]]},"properties":{"id":"78229","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.561878,29.578875],[-98.563844,29.599685],[-98.598592,29.590911],[-98.630989,29.587723],[-98.64505,29.577562],[-98.667268,29.553739],[-98.651838,29.544384],[-98.585245,29.549086],[-98.561878,29.578875]]]},"properties":{"id":"78249","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.546022,30.159567],[-98.252327,30.153801],[-98.206567,30.310812],[-98.590989,30.4593],[-98.590488,30.430145],[-98.588988,30.312159],[-98.587982,30.239484],[-98.546022,30.159567]]]},"properties":{"id":"78636","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.380188,29.279576],[-95.371094,29.301744],[-95.358604,29.410351],[-95.417038,29.540723],[-95.433512,29.544819],[-95.509131,29.513209],[-95.533027,29.458431],[-95.550622,29.438306],[-95.577077,29.368087],[-95.561903,29.250582],[-95.380188,29.279576]]]},"properties":{"id":"77583","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.371881,30.235371],[-103.469058,30.258105],[-103.217166,29.800188],[-102.695461,30.143974],[-102.799425,30.217247],[-103.070072,30.339562],[-103.371881,30.235371]]]},"properties":{"id":"79842","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.545073,34.747484],[-101.973244,34.646954],[-101.998358,34.559152],[-102.071241,34.465144],[-101.998229,34.441828],[-101.955238,34.413263],[-101.522682,34.397568],[-101.472067,34.44518],[-101.372196,34.652395],[-101.34545,34.748235],[-101.434887,34.747504],[-101.471562,34.747462],[-101.545073,34.747484]]]},"properties":{"id":"79088","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.456237,27.264398],[-98.523723,27.265115],[-98.535784,27.265158],[-98.136057,27.005504],[-98.127975,27.005673],[-98.058523,27.346712],[-98.232719,27.33493],[-98.232464,27.262487],[-98.456237,27.264398]]]},"properties":{"id":"78355","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.966133,31.80485],[-98.877298,31.642306],[-98.795728,31.775511],[-98.882648,31.843208],[-98.966133,31.80485]]]},"properties":{"id":"76802","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.562305,29.810132],[-97.674465,30.030523],[-97.700512,30.025953],[-97.7622,29.932286],[-97.803267,29.872754],[-97.814341,29.784747],[-97.800194,29.765932],[-97.780917,29.759258],[-97.774307,29.75772],[-97.562305,29.810132]]]},"properties":{"id":"78644","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.940681,30.012717],[-98.151587,29.934318],[-98.12127,29.912844],[-98.031715,29.783577],[-97.999271,29.752444],[-97.943257,29.737389],[-97.873567,29.763874],[-97.867351,29.864183],[-97.849178,29.916],[-97.940681,30.012717]]]},"properties":{"id":"78666","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.77334,29.741852],[-97.737095,29.711389],[-97.765018,29.747301],[-97.77334,29.741852]]]},"properties":{"id":"78661","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.182057,28.885932],[-96.315301,28.829111],[-96.41955,28.772254],[-96.453296,28.756286],[-96.322918,28.641875],[-96.019657,28.747058],[-96.069865,28.786599],[-96.060785,28.886419],[-96.105589,28.924223],[-96.131522,28.907191],[-96.118853,28.889694],[-96.167144,28.877278],[-96.177143,28.887471],[-96.182057,28.885932]]]},"properties":{"id":"77465","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.195278,32.406908],[-99.205926,32.295812],[-99.178773,32.29784],[-99.195278,32.406908]]]},"properties":{"id":"76469","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861732,26.234796],[-97.795877,26.19694],[-97.764051,26.296227],[-97.777169,26.335462],[-97.780359,26.335937],[-97.796466,26.338291],[-97.861924,26.316484],[-97.861732,26.234796]]]},"properties":{"id":"78593","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.49036,25.883358],[-97.510602,25.963258],[-97.525826,25.997553],[-97.569325,26.017735],[-97.618852,25.981919],[-97.49036,25.883358]]]},"properties":{"id":"78520","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.172947,32.961829],[-95.146789,32.902621],[-95.12613,33.034581],[-95.172947,32.961829]]]},"properties":{"id":"75451","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.114307,33.137718],[-94.196711,33.298561],[-94.210137,33.297044],[-94.259313,33.237046],[-94.337903,33.074202],[-94.247552,33.005384],[-94.042988,33.007908],[-94.043028,33.055322],[-94.114307,33.137718]]]},"properties":{"id":"75551","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.467909,29.73297],[-94.577426,29.837425],[-94.654351,29.84037],[-94.688269,29.771816],[-94.417307,29.59255],[-94.409819,29.596658],[-94.374014,29.692225],[-94.467909,29.73297]]]},"properties":{"id":"77514","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.049441,29.791077],[-94.916618,29.770271],[-94.966712,29.858391],[-95.002802,29.842386],[-95.049441,29.791077]]]},"properties":{"id":"77521","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.84757,29.87304],[-94.893322,29.866791],[-94.878994,29.850514],[-94.84757,29.87304]]]},"properties":{"id":"77580","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.978134,31.793514],[-95.039292,31.880865],[-95.142858,31.90656],[-95.135792,31.886633],[-95.147152,31.879591],[-95.151507,31.901644],[-95.404014,31.802859],[-95.342483,31.718032],[-95.285864,31.629686],[-94.968341,31.748306],[-94.978134,31.793514]]]},"properties":{"id":"75785","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927175,31.876113],[-94.929681,31.934835],[-94.985367,31.932311],[-95.039292,31.880865],[-94.978134,31.793514],[-94.944833,31.814431],[-94.929345,31.845445],[-94.927175,31.876113]]]},"properties":{"id":"75784","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.416567,34.448342],[-100.528518,34.474497],[-100.503373,34.314079],[-100.416567,34.448342]]]},"properties":{"id":"79259","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.236094,31.726617],[-100.430332,31.743654],[-100.417173,31.692771],[-100.382865,31.692546],[-100.298624,31.692694],[-100.236094,31.726617]]]},"properties":{"id":"76953","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.667145,32.036201],[-99.618558,32.070708],[-99.594265,32.080945],[-99.728863,32.081991],[-99.763023,32.081631],[-99.759481,32.050054],[-99.667145,32.036201]]]},"properties":{"id":"79519","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.709022,32.997207],[-96.632641,33.006027],[-96.641021,33.033443],[-96.685506,33.073791],[-96.686229,33.075364],[-96.690077,33.065954],[-96.704331,33.037459],[-96.708059,33.003867],[-96.709022,32.997207]]]},"properties":{"id":"75074","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.405925,32.986362],[-96.378695,32.957171],[-96.366809,32.945483],[-96.381087,32.933459],[-96.391568,32.927684],[-96.297322,32.841723],[-96.227911,32.887542],[-96.208308,32.990811],[-96.296452,33.062554],[-96.31225,33.061098],[-96.41869,33.021247],[-96.405925,32.986362]]]},"properties":{"id":"75189","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.999271,29.752444],[-98.031715,29.783577],[-98.151569,29.65721],[-98.057784,29.600011],[-98.049155,29.620409],[-97.999271,29.752444]]]},"properties":{"id":"78130","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.309995,29.716969],[-98.260737,29.629328],[-98.239738,29.625491],[-98.202153,29.636265],[-98.151569,29.65721],[-98.031715,29.783577],[-98.12127,29.912844],[-98.335841,29.810318],[-98.349253,29.804233],[-98.309995,29.716969]]]},"properties":{"id":"78132","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.943359,33.576141],[-97.03229,33.524818],[-96.999484,33.481392],[-96.807483,33.520107],[-96.795303,33.560473],[-96.943359,33.576141]]]},"properties":{"id":"76233","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.733548,31.476357],[-97.731372,31.473846],[-97.724481,31.473068],[-97.720559,31.474245],[-97.736931,31.475661],[-97.733548,31.476357]]]},"properties":{"id":"76596","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.387062,30.58233],[-101.373547,30.519356],[-101.288796,30.582354],[-101.33027,30.624078],[-101.387062,30.58233]]]},"properties":{"id":"79942","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.335668,33.768972],[-101.310904,33.516166],[-101.202182,33.479442],[-101.040376,33.683674],[-101.041158,33.833625],[-101.335668,33.768972]]]},"properties":{"id":"79322","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.461414,33.796345],[-101.613608,33.783635],[-101.630829,33.638521],[-101.622134,33.609494],[-101.558119,33.498721],[-101.514207,33.491496],[-101.461414,33.796345]]]},"properties":{"id":"79343","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.613736,31.200272],[-103.474925,31.052987],[-103.077148,31.302343],[-103.389197,31.426751],[-103.449613,31.462237],[-103.415029,31.484954],[-103.610887,31.651802],[-103.62639,31.668649],[-103.880498,31.399361],[-103.732244,31.187819],[-103.613736,31.200272]]]},"properties":{"id":"79772","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.69188,32.894571],[-96.676303,32.870985],[-96.667889,32.868031],[-96.648694,32.855858],[-96.602214,32.894013],[-96.650419,32.894489],[-96.69188,32.894571]]]},"properties":{"id":"75041","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.772401,32.849472],[-96.78961,32.81559],[-96.771553,32.802084],[-96.753803,32.859626],[-96.770399,32.865592],[-96.772401,32.849472]]]},"properties":{"id":"75206","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.906129,32.814286],[-96.898522,32.798192],[-96.835251,32.789718],[-96.849527,32.811366],[-96.873104,32.843867],[-96.890595,32.830489],[-96.906129,32.814286]]]},"properties":{"id":"75247","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.93114,32.886088],[-96.916124,32.856042],[-96.890595,32.830489],[-96.873104,32.843867],[-96.853551,32.859758],[-96.811515,32.865644],[-96.809576,32.880068],[-96.93114,32.886088]]]},"properties":{"id":"75220","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.685016,32.792372],[-96.63927,32.748607],[-96.59881,32.743946],[-96.57261,32.764955],[-96.592843,32.78994],[-96.668296,32.797221],[-96.685016,32.792372]]]},"properties":{"id":"75149","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.769781,32.778064],[-96.738078,32.755437],[-96.73096,32.76601],[-96.728461,32.777797],[-96.765309,32.783723],[-96.769781,32.778064]]]},"properties":{"id":"75210","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.990085,32.652057],[-96.998951,32.64392],[-97.038199,32.560026],[-97.05262,32.548907],[-97.065507,32.549075],[-96.91969,32.547835],[-96.899695,32.560279],[-96.908399,32.618789],[-96.943239,32.626115],[-96.979868,32.656216],[-96.990085,32.652057]]]},"properties":{"id":"75104","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.838963,32.987521],[-96.858915,32.953158],[-96.855914,32.933293],[-96.828281,32.94722],[-96.822271,32.954169],[-96.829862,32.987402],[-96.838963,32.987521]]]},"properties":{"id":"75001","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.780848,32.924073],[-96.769032,32.909357],[-96.764173,32.923871],[-96.780848,32.924073]]]},"properties":{"id":"75251","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.644218,33.486105],[-95.788216,33.486226],[-95.748324,33.434585],[-95.836887,33.362048],[-95.828072,33.345477],[-95.70517,33.304622],[-95.509007,33.349348],[-95.588183,33.470304],[-95.625674,33.485613],[-95.644218,33.486105]],[[-95.666156,33.456916],[-95.62664,33.428364],[-95.677185,33.41436],[-95.666156,33.456916]]]},"properties":{"id":"75432","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.666156,33.456916],[-95.677185,33.41436],[-95.62664,33.428364],[-95.666156,33.456916]]]},"properties":{"id":"75441","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.89489,33.02707],[-96.944635,33.026308],[-96.951273,33.005598],[-96.940741,33.005606],[-96.89489,33.02707]]],[[[-96.89399,33.02702],[-96.849233,33.025937],[-96.859299,33.048237],[-96.89399,33.02702]]]]},"properties":{"id":"75010","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.044523,33.048934],[-97.008544,33.046325],[-97.012013,33.087532],[-97.060992,33.103521],[-97.131438,33.059393],[-97.109686,33.054726],[-97.044523,33.048934]]]},"properties":{"id":"75077","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.040425,32.984533],[-97.109686,33.054726],[-97.131438,33.059393],[-97.182845,33.050891],[-97.151221,33.017725],[-97.08723,32.989761],[-97.040425,32.984533]]]},"properties":{"id":"75022","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.11243,33.25395],[-97.131864,33.240491],[-97.126654,33.214458],[-97.090904,33.206798],[-97.11243,33.25395]]]},"properties":{"id":"76209","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.25058,28.905078],[-97.344477,28.925692],[-97.355404,28.897621],[-97.236241,28.864889],[-97.25058,28.905078]]]},"properties":{"id":"77974","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.450564,29.230813],[-97.556513,29.152313],[-97.509897,29.110585],[-97.41916,29.253617],[-97.450564,29.230813]]]},"properties":{"id":"77994","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.617205,27.828533],[-98.555153,28.057212],[-98.728016,28.057416],[-98.783301,27.527663],[-98.726846,27.499261],[-98.626557,27.583157],[-98.617205,27.828533]]]},"properties":{"id":"78357","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.377015,27.416873],[-98.385503,27.263824],[-98.232464,27.262487],[-98.232719,27.33493],[-98.233072,27.461142],[-98.233131,27.483708],[-98.361817,27.47734],[-98.377015,27.416873]]]},"properties":{"id":"78349","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.56238,31.826674],[-102.478912,31.786451],[-102.366288,31.82383],[-102.388677,31.866507],[-102.56238,31.826674]]]},"properties":{"id":"79763","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.381243,31.868118],[-102.409775,31.96034],[-102.478372,31.952163],[-102.576537,31.868494],[-102.56238,31.826674],[-102.388677,31.866507],[-102.381243,31.868118]]]},"properties":{"id":"79764","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.219468,32.195587],[-97.048756,32.210641],[-97.000715,32.211871],[-96.998296,32.275382],[-97.086628,32.361104],[-97.267322,32.332411],[-97.319339,32.23198],[-97.318599,32.195354],[-97.219468,32.195587]]]},"properties":{"id":"76050","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.345255,31.752676],[-106.330527,31.798322],[-106.361254,31.831369],[-106.36636,31.828185],[-106.417618,31.789078],[-106.422121,31.777715],[-106.397765,31.781069],[-106.345255,31.752676]]]},"properties":{"id":"79925","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.440065,31.869645],[-106.370643,31.927172],[-106.497217,31.883212],[-106.494943,31.868905],[-106.440065,31.869645]]]},"properties":{"id":"79934","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.121489,31.254593],[-97.167205,31.18609],[-97.174055,31.133277],[-97.129281,31.070328],[-96.929432,31.216669],[-96.922147,31.251419],[-96.96227,31.275516],[-97.121489,31.254593]]]},"properties":{"id":"76656","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.045137,31.519037],[-97.102892,31.551761],[-97.12337,31.559257],[-97.148264,31.537302],[-97.151315,31.49245],[-97.168282,31.46431],[-97.194224,31.421833],[-97.037397,31.391434],[-97.015067,31.387348],[-97.017623,31.410927],[-97.045137,31.519037]],[[-97.117474,31.546255],[-97.121683,31.549781],[-97.120692,31.55065],[-97.116689,31.547302],[-97.117474,31.546255]]]},"properties":{"id":"76706","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.920217,31.538169],[-96.747466,31.419039],[-96.721962,31.406224],[-96.716289,31.408941],[-96.656514,31.6071],[-96.889086,31.647766],[-96.917921,31.585997],[-96.920217,31.538169]]]},"properties":{"id":"76664","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.258797,33.485004],[-96.243823,33.462355],[-96.214638,33.475529],[-96.258797,33.485004]]]},"properties":{"id":"75475","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.363749,33.406442],[-96.243823,33.462355],[-96.258797,33.485004],[-96.394773,33.398325],[-96.363749,33.406442]]]},"properties":{"id":"75490","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.088645,33.469279],[-95.950359,33.326298],[-95.859956,33.341859],[-95.859793,33.348596],[-95.859221,33.363708],[-95.857738,33.461722],[-96.009308,33.488891],[-96.059384,33.521263],[-96.088645,33.469279]]]},"properties":{"id":"75449","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.308314,33.715746],[-96.276387,33.657812],[-96.14702,33.696874],[-96.189177,33.755688],[-96.308314,33.715746]]]},"properties":{"id":"75476","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.056714,33.537668],[-95.993386,33.67763],[-96.086013,33.647986],[-96.056714,33.537668]]]},"properties":{"id":"75438","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.976563,29.664989],[-95.957787,29.688156],[-95.978254,29.689206],[-95.976563,29.664989]]]},"properties":{"id":"77476","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.776804,29.784947],[-95.775477,29.700738],[-95.708212,29.721851],[-95.705921,29.734703],[-95.718929,29.784688],[-95.776804,29.784947]]]},"properties":{"id":"77450","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.633823,29.620143],[-95.582826,29.580582],[-95.58241,29.606242],[-95.597837,29.660596],[-95.619202,29.661986],[-95.633823,29.620143]]]},"properties":{"id":"77478","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.125949,33.053024],[-95.241898,33.080408],[-95.172947,32.961829],[-95.12613,33.034581],[-95.125949,33.053024]]]},"properties":{"id":"75480","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.124914,33.166764],[-95.093083,33.129429],[-95.125573,33.11907],[-95.125769,33.088025],[-94.816993,33.070161],[-94.816276,33.094074],[-94.825296,33.136998],[-94.847403,33.165045],[-94.810269,33.28289],[-94.808783,33.363636],[-94.826286,33.378808],[-94.904571,33.38937],[-94.977108,33.389817],[-95.188865,33.288213],[-95.124914,33.166764]]]},"properties":{"id":"75455","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.146718,31.454635],[-96.028149,31.287547],[-95.882436,31.348963],[-96.017337,31.616382],[-96.123424,31.649359],[-96.099737,31.500665],[-96.146718,31.454635]]]},"properties":{"id":"75831","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.5323,31.755695],[-96.37421,31.716645],[-96.332444,31.74078],[-96.332297,31.762253],[-96.332269,31.771854],[-96.339927,31.873862],[-96.507597,31.797325],[-96.554122,31.80122],[-96.5323,31.755695]]]},"properties":{"id":"76693","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.643017,31.626617],[-96.337684,31.563247],[-96.37421,31.716645],[-96.5323,31.755695],[-96.545089,31.727368],[-96.639567,31.6362],[-96.643017,31.626617]]]},"properties":{"id":"76667","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.995117,29.498785],[-95.173023,29.464775],[-95.148221,29.428271],[-95.114687,29.429626],[-95.04917,29.419155],[-94.957534,29.408034],[-94.961227,29.420584],[-94.958845,29.506472],[-94.995117,29.498785]]]},"properties":{"id":"77539","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.820158,29.297767],[-94.844819,29.25589],[-94.809718,29.274707],[-94.820158,29.297767]]]},"properties":{"id":"77551","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.757521,30.498867],[-98.590488,30.430145],[-98.590989,30.4593],[-98.59167,30.499875],[-98.757521,30.498867]]]},"properties":{"id":"78675","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.130532,30.365546],[-99.201787,30.181006],[-99.016616,30.13729],[-98.774024,30.041576],[-98.710953,30.100051],[-98.648806,30.138864],[-98.588988,30.312159],[-98.590488,30.430145],[-98.757521,30.498867],[-98.964232,30.498482],[-99.070061,30.498446],[-99.130532,30.365546]]]},"properties":{"id":"78624","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.779225,33.733959],[-96.682795,33.731406],[-96.662559,33.841874],[-96.786841,33.865243],[-96.799912,33.784852],[-96.779225,33.733959]]]},"properties":{"id":"75076","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.86071,32.590446],[-94.986644,32.583304],[-95.017959,32.518669],[-94.986868,32.425234],[-94.897283,32.457143],[-94.877829,32.541018],[-94.876087,32.571092],[-94.86071,32.590446]]]},"properties":{"id":"75647","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.986644,32.583304],[-94.86071,32.590446],[-94.819323,32.591641],[-94.745629,32.667507],[-94.747948,32.740076],[-94.823047,32.823995],[-95.007146,32.668704],[-94.986644,32.583304]]]},"properties":{"id":"75645","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.525514,30.085588],[-95.476371,30.024371],[-95.429398,30.03563],[-95.436545,30.08406],[-95.525514,30.085588]]]},"properties":{"id":"77388","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.579683,29.933616],[-95.585769,29.952539],[-95.638855,29.928611],[-95.60365,29.903516],[-95.584667,29.890168],[-95.584504,29.897687],[-95.579683,29.933616]]]},"properties":{"id":"77065","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.11132,29.650786],[-95.032918,29.610385],[-94.994746,29.613719],[-94.978845,29.676731],[-95.082685,29.764138],[-95.093043,29.755652],[-95.11023,29.740137],[-95.110853,29.663923],[-95.11132,29.650786]]]},"properties":{"id":"77571","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.227966,29.669827],[-95.228088,29.690563],[-95.255267,29.712771],[-95.288732,29.697449],[-95.272673,29.680346],[-95.251593,29.652503],[-95.249168,29.652213],[-95.227966,29.669827]]]},"properties":{"id":"77017","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.11132,29.650786],[-95.084373,29.613209],[-95.084243,29.61311],[-95.032918,29.610385],[-95.11132,29.650786]]]},"properties":{"id":"77507","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.289038,29.851746],[-95.329394,29.819884],[-95.292022,29.797833],[-95.288746,29.799291],[-95.28014,29.802473],[-95.261614,29.810916],[-95.289038,29.851746]]]},"properties":{"id":"77028","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.439612,29.781512],[-95.387782,29.787622],[-95.391618,29.813876],[-95.391689,29.814064],[-95.440882,29.808874],[-95.450525,29.794468],[-95.438408,29.78396],[-95.439612,29.781512]]]},"properties":{"id":"77008","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.078824,29.824866],[-95.158302,29.805595],[-95.093043,29.755652],[-95.082685,29.764138],[-95.060823,29.792512],[-95.078824,29.824866]]]},"properties":{"id":"77530","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.545321,29.832239],[-95.544668,29.794282],[-95.493681,29.825762],[-95.50472,29.833667],[-95.545321,29.832239]]]},"properties":{"id":"77080","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.545321,29.832239],[-95.50472,29.833667],[-95.506297,29.834805],[-95.584667,29.890168],[-95.60365,29.903516],[-95.624407,29.872951],[-95.617275,29.835998],[-95.545321,29.832239]]]},"properties":{"id":"77041","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.295601,29.918636],[-95.240685,29.89864],[-95.195996,29.941986],[-95.212386,29.981848],[-95.299539,29.97282],[-95.307032,29.918431],[-95.295601,29.918636]]]},"properties":{"id":"77396","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.142735,29.631221],[-95.168207,29.5913],[-95.159308,29.581813],[-95.10361,29.583483],[-95.091403,29.596823],[-95.084243,29.61311],[-95.084373,29.613209],[-95.142735,29.631221]]]},"properties":{"id":"77059","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.240685,29.89864],[-95.295601,29.918636],[-95.30659,29.891185],[-95.257285,29.882542],[-95.240685,29.89864]]]},"properties":{"id":"77050","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.55752,29.718078],[-95.571445,29.716958],[-95.614704,29.713411],[-95.614466,29.68437],[-95.558012,29.684649],[-95.55752,29.718078]]]},"properties":{"id":"77072","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.340085,35.619464],[-100.147315,35.619379],[-100.000392,35.619115],[-100.000399,36.055677],[-100.330366,36.187278],[-100.366152,36.230719],[-100.384226,36.266933],[-100.384234,36.288879],[-100.546037,36.215326],[-100.546724,36.056536],[-100.539973,35.997839],[-100.541005,35.719682],[-100.504565,35.619408],[-100.340085,35.619464]]]},"properties":{"id":"79014","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.67183,32.359546],[-95.622993,32.213992],[-95.58388,32.218579],[-95.58891,32.368367],[-95.67183,32.359546]]]},"properties":{"id":"75756","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.692252,32.215316],[-95.722138,32.04588],[-95.601005,32.061483],[-95.517894,32.177594],[-95.58388,32.218579],[-95.622993,32.213992],[-95.692252,32.215316]]]},"properties":{"id":"75770","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.14693,26.299236],[-98.159867,26.540613],[-98.447247,26.530636],[-98.516227,26.397042],[-98.508585,26.395163],[-98.428729,26.378511],[-98.304369,26.344588],[-98.251571,26.314132],[-98.212117,26.308535],[-98.14693,26.299236]]]},"properties":{"id":"78541","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.14693,26.299236],[-98.212117,26.308535],[-98.195405,26.250407],[-98.175154,26.247623],[-98.14693,26.299236]]]},"properties":{"id":"78539","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.271953,26.244684],[-98.197822,26.235991],[-98.195405,26.250407],[-98.212117,26.308535],[-98.251571,26.314132],[-98.271953,26.244684]]]},"properties":{"id":"78504","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540521,26.253704],[-98.582701,26.265383],[-98.583199,26.26441],[-98.508671,26.225528],[-98.540521,26.253704]]]},"properties":{"id":"78565","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.367524,32.178421],[-97.476086,32.17346],[-97.486211,32.032528],[-97.297196,32.059307],[-97.269643,32.110245],[-97.367524,32.178421]]]},"properties":{"id":"76627","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.884941,31.976445],[-96.977388,31.897736],[-96.886156,31.870389],[-96.831597,31.947822],[-96.884941,31.976445]]]},"properties":{"id":"76660","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.968481,32.045568],[-97.001841,32.015332],[-96.912841,32.008381],[-96.968481,32.045568]]]},"properties":{"id":"76628","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.11845,33.389429],[-102.075929,33.389586],[-102.067603,33.389618],[-102.078989,33.519429],[-102.182741,33.499638],[-102.393119,33.388615],[-102.11845,33.389429]]]},"properties":{"id":"79358","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.70361,32.370365],[-97.846529,32.503283],[-97.87582,32.557269],[-97.923951,32.460643],[-97.78882,32.3134],[-97.743724,32.316936],[-97.727921,32.317124],[-97.684491,32.317749],[-97.70361,32.370365]]]},"properties":{"id":"76048","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.401513,33.134031],[-95.434487,33.188774],[-95.503096,33.34771],[-95.509007,33.349348],[-95.70517,33.304622],[-95.760046,33.287258],[-95.796727,33.253334],[-95.732642,33.126026],[-95.683141,32.979629],[-95.666916,32.972232],[-95.520591,32.96136],[-95.421479,33.091185],[-95.401513,33.134031]]]},"properties":{"id":"75482","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.198652,31.450804],[-95.302884,31.221346],[-95.208339,31.213852],[-95.073322,31.228745],[-94.95811,31.38693],[-94.959824,31.389017],[-95.048549,31.449087],[-95.110246,31.524385],[-95.198652,31.450804]],[[-95.139942,31.391697],[-95.11917,31.393898],[-95.1333,31.384917],[-95.139942,31.391697]]]},"properties":{"id":"75847","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.378863,32.106672],[-101.366862,32.091718],[-101.35133,32.091316],[-101.358151,32.109661],[-101.378863,32.106672]]]},"properties":{"id":"79733","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.21882,30.847036],[-105.55316,31.390702],[-105.972273,31.408312],[-105.997152,31.38723],[-105.21882,30.847036]]]},"properties":{"id":"79839","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.075799,32.563623],[-95.860224,32.594207],[-95.878486,32.78777],[-95.9866,32.873064],[-96.076619,32.823653],[-96.141278,32.658534],[-96.075799,32.563623]]]},"properties":{"id":"75169","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.487577,31.3137],[-100.402475,31.21964],[-100.384223,31.0881],[-100.190228,31.088436],[-100.114955,31.148856],[-100.114052,31.218703],[-100.177386,31.344625],[-100.296443,31.37134],[-100.307837,31.3699],[-100.314016,31.37184],[-100.406086,31.423626],[-100.409588,31.4237],[-100.456029,31.442973],[-100.691931,31.413446],[-100.689809,31.220219],[-100.682838,31.225261],[-100.623903,31.260573],[-100.487577,31.3137]]]},"properties":{"id":"76904","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.687802,33.261495],[-97.918902,33.237013],[-98.000385,33.095644],[-97.948784,33.033638],[-97.827156,33.066504],[-97.804057,33.073558],[-97.673977,33.183948],[-97.687802,33.261495]]]},"properties":{"id":"76426","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.577782,28.889414],[-96.578567,28.83381],[-96.577453,28.769812],[-96.45668,28.756234],[-96.470267,28.890956],[-96.577782,28.889414]]]},"properties":{"id":"77971","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.610377,28.721384],[-96.57462,28.705503],[-96.577453,28.769812],[-96.578567,28.83381],[-96.658044,28.820487],[-96.610377,28.721384]]]},"properties":{"id":"77991","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.882923,30.541128],[-93.714663,30.491601],[-93.727731,30.56658],[-93.803548,30.615649],[-93.882923,30.541128]]]},"properties":{"id":"75933","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.128992,31.099402],[-93.902633,31.118026],[-93.911126,31.158071],[-94.008602,31.186607],[-94.062581,31.114955],[-94.128992,31.099402]]]},"properties":{"id":"75931","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.765851,30.333105],[-93.714663,30.491601],[-93.882923,30.541128],[-94.078268,30.547211],[-94.071689,30.526033],[-94.084326,30.350313],[-94.027775,30.287615],[-93.922714,30.242572],[-93.794017,30.325867],[-93.765851,30.333105]]]},"properties":{"id":"77612","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.027775,30.287615],[-94.084326,30.350313],[-94.117688,30.298402],[-94.027775,30.287615]]]},"properties":{"id":"77615","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.899088,30.673546],[-104.723954,30.252389],[-104.574725,30.31977],[-104.560074,30.356868],[-104.410685,30.518498],[-104.344921,30.65712],[-104.649296,30.80983],[-104.899088,30.673546]]]},"properties":{"id":"79854","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.009113,29.956548],[-94.050867,29.882154],[-93.855977,29.968725],[-93.994251,29.945977],[-94.009113,29.956548]]]},"properties":{"id":"77640","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.033105,29.72855],[-94.067115,29.66989],[-93.832388,29.673021],[-93.907942,29.761072],[-94.033105,29.72855]]]},"properties":{"id":"77655","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.755673,27.661335],[-97.552093,27.636479],[-97.547534,27.749936],[-97.570243,27.783403],[-97.662385,27.875307],[-97.798517,27.995659],[-97.855666,27.949342],[-97.934039,27.867006],[-97.933703,27.804073],[-97.875024,27.711747],[-97.773343,27.681493],[-97.755673,27.661335]],[[-97.800999,27.806526],[-97.784655,27.809766],[-97.791778,27.796704],[-97.800999,27.806526]]]},"properties":{"id":"78380","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.296102,32.397311],[-97.267322,32.332411],[-97.086628,32.361104],[-97.142658,32.519252],[-97.177477,32.516768],[-97.197246,32.510036],[-97.267679,32.464781],[-97.29609,32.399358],[-97.296102,32.397311]]]},"properties":{"id":"76009","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.500576,32.494732],[-96.452138,32.358631],[-96.30749,32.480286],[-96.458505,32.522015],[-96.500576,32.494732]],[[-96.431048,32.473286],[-96.440613,32.454706],[-96.458715,32.465415],[-96.431048,32.473286]]]},"properties":{"id":"75158","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.03162,30.013687],[-98.989656,29.813495],[-98.917725,29.781398],[-98.774024,30.041576],[-99.016616,30.13729],[-99.03162,30.013687]]]},"properties":{"id":"78013","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.116306,30.571833],[-100.116696,30.372917],[-100.015097,30.519365],[-100.116306,30.571833]]]},"properties":{"id":"76874","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.477184,33.744761],[-99.991239,33.41621],[-99.9926,33.536624],[-99.993072,33.580143],[-99.995442,33.765233],[-99.996217,33.824469],[-100.235652,33.835964],[-100.383409,33.835955],[-100.447748,33.781901],[-100.477184,33.744761]]]},"properties":{"id":"79236","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.584291,33.830324],[-95.533013,33.740623],[-95.324465,33.840431],[-95.310497,33.877204],[-95.502479,33.875199],[-95.584291,33.830324]]]},"properties":{"id":"75473","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.170737,28.259617],[-99.27179,28.341473],[-99.293291,28.251212],[-99.170737,28.259617]]]},"properties":{"id":"78001","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.656514,31.6071],[-96.716289,31.408941],[-96.329518,31.388051],[-96.326635,31.437374],[-96.312847,31.526669],[-96.337684,31.563247],[-96.643017,31.626617],[-96.656514,31.6071]]]},"properties":{"id":"76642","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.782416,31.702128],[-96.639567,31.6362],[-96.545089,31.727368],[-96.576072,31.803046],[-96.69869,31.813191],[-96.80842,31.743982],[-96.782416,31.702128]]]},"properties":{"id":"76635","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.776534,33.781257],[-101.630829,33.638521],[-101.613608,33.783635],[-101.693056,33.829186],[-101.776534,33.781257]]]},"properties":{"id":"79329","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.867251,33.577771],[-101.845624,33.577848],[-101.829427,33.577959],[-101.853224,33.60671],[-101.870617,33.592313],[-101.870601,33.577789],[-101.867251,33.577771]]]},"properties":{"id":"79401","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.840167,33.722535],[-101.819055,33.723068],[-101.81921,33.737563],[-101.84162,33.737373],[-101.840167,33.722535]]]},"properties":{"id":"79350","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.602078,31.30342],[-99.603223,31.087296],[-99.484755,30.940605],[-99.188204,30.919129],[-99.187577,30.941066],[-99.133386,31.104637],[-99.306142,31.327857],[-99.602078,31.30342]]]},"properties":{"id":"76825","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.145721,31.575191],[-97.141349,31.596335],[-97.266988,31.694119],[-97.276357,31.602963],[-97.216246,31.575042],[-97.185854,31.547887],[-97.145721,31.575191]]]},"properties":{"id":"76708","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.005519,31.729729],[-97.013661,31.737105],[-97.025591,31.724321],[-97.005519,31.729729]]]},"properties":{"id":"76654","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.620859,28.648352],[-98.656899,28.648364],[-98.801226,28.314293],[-98.803325,28.05748],[-98.728016,28.057416],[-98.555153,28.057212],[-98.334323,28.0578],[-98.334347,28.266147],[-98.440597,28.445592],[-98.388966,28.524598],[-98.500128,28.648191],[-98.512745,28.648208],[-98.620859,28.648352]]]},"properties":{"id":"78072","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.079768,32.41299],[-102.177976,32.329018],[-101.903183,32.26391],[-101.924683,32.398308],[-101.938613,32.441848],[-101.977523,32.45194],[-102.079768,32.41299]]]},"properties":{"id":"79783","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.303996,30.499832],[-99.070061,30.498446],[-98.964232,30.498482],[-98.964304,30.586599],[-98.964016,30.705236],[-98.964014,30.705688],[-99.06979,30.883756],[-99.188204,30.919129],[-99.484755,30.940605],[-99.484616,30.86899],[-99.484366,30.823445],[-99.48424,30.599988],[-99.445892,30.499611],[-99.303996,30.499832]]]},"properties":{"id":"76856","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.06979,30.883756],[-98.964014,30.705688],[-98.963864,30.795351],[-99.062934,30.885682],[-99.06979,30.883756]]]},"properties":{"id":"76820","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.217931,28.921883],[-96.207276,29.017108],[-96.21843,29.01121],[-96.287345,28.974817],[-96.310593,28.926315],[-96.217931,28.921883]]]},"properties":{"id":"77458","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.068873,31.087766],[-100.018558,30.710453],[-99.66771,30.710609],[-99.546041,30.789782],[-99.484616,30.86899],[-99.484755,30.940605],[-99.603223,31.087296],[-99.649215,31.087],[-100.068873,31.087766]]]},"properties":{"id":"76859","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.093207,32.086861],[-102.287048,32.086991],[-102.28733,31.932816],[-102.287307,31.926143],[-102.252486,31.970816],[-102.154556,31.998341],[-102.124855,32.004661],[-102.120392,32.005687],[-102.093207,32.086861]]]},"properties":{"id":"79707","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.124855,32.004661],[-102.107132,31.964514],[-102.057304,32.020398],[-102.120392,32.005687],[-102.124855,32.004661]]]},"properties":{"id":"79701","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.048814,30.773792],[-97.075051,30.98375],[-97.119997,30.960964],[-97.252942,30.860125],[-97.289795,30.816186],[-97.270605,30.77555],[-97.156176,30.733405],[-97.048814,30.773792]]]},"properties":{"id":"76518","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.296553,30.78759],[-97.282582,30.78419],[-97.279051,30.788248],[-97.297095,30.797878],[-97.296553,30.78759]]]},"properties":{"id":"76523","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.943273,33.87899],[-97.886567,33.741025],[-97.772231,33.675041],[-97.66321,33.674338],[-97.572349,33.899263],[-97.943273,33.87899]]]},"properties":{"id":"76255","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.453951,30.193645],[-95.452247,30.178243],[-95.449495,30.156895],[-95.375509,30.164093],[-95.457585,30.244957],[-95.453951,30.193645]]]},"properties":{"id":"77385","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.690446,30.175226],[-95.628738,30.12777],[-95.558324,30.165523],[-95.522531,30.227566],[-95.544979,30.258085],[-95.804306,30.24557],[-95.804097,30.22646],[-95.690446,30.175226]]]},"properties":{"id":"77354","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.479943,30.191384],[-95.522531,30.227566],[-95.558324,30.165523],[-95.541399,30.170279],[-95.479943,30.191384]]]},"properties":{"id":"77382","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.629395,31.844044],[-94.541626,31.71098],[-94.405305,31.677098],[-94.414371,31.752001],[-94.581787,31.953137],[-94.606036,31.958026],[-94.629395,31.844044]]]},"properties":{"id":"75946","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.702902,32.169147],[-96.771568,32.135703],[-96.720507,32.04439],[-96.669921,32.156411],[-96.690827,32.174945],[-96.702902,32.169147]]]},"properties":{"id":"76626","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.669921,32.156411],[-96.720507,32.04439],[-96.718451,32.00674],[-96.604098,32.003172],[-96.669921,32.156411]]]},"properties":{"id":"75102","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.794017,30.325867],[-93.704578,30.289721],[-93.765851,30.333105],[-93.794017,30.325867]]]},"properties":{"id":"77614","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.42017,27.763438],[-97.460063,27.755986],[-97.427395,27.735454],[-97.42017,27.763438]]]},"properties":{"id":"78416","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.800999,27.806526],[-97.791778,27.796704],[-97.784655,27.809766],[-97.800999,27.806526]]]},"properties":{"id":"78339","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.041554,35.622487],[-103.006757,35.612866],[-102.971621,35.622925],[-103.041554,35.622487]]]},"properties":{"id":"88430","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.531326,32.883992],[-98.426553,33.007913],[-98.425741,33.066165],[-98.422423,33.22299],[-98.421071,33.263845],[-98.606315,33.222484],[-98.691832,33.216901],[-98.952698,33.183523],[-98.952606,33.16713],[-98.951665,33.055525],[-98.879463,32.990105],[-98.741417,33.056789],[-98.677425,32.955937],[-98.690283,32.833752],[-98.531326,32.883992]]]},"properties":{"id":"76450","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.581787,31.953137],[-94.414371,31.752001],[-94.296247,31.849025],[-94.306478,31.959168],[-94.480245,32.026851],[-94.581787,31.953137]]]},"properties":{"id":"75975","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.800781,32.791922],[-97.837383,32.728372],[-97.742174,32.756973],[-97.800781,32.791922]]]},"properties":{"id":"76086","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.598953,32.776319],[-97.503784,32.727885],[-97.435248,32.748818],[-97.443822,32.76557],[-97.442761,32.788654],[-97.536908,32.825355],[-97.592148,32.826446],[-97.598953,32.776319]]]},"properties":{"id":"76108","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.525767,34.426826],[-102.654716,34.399713],[-102.550266,34.383636],[-102.525767,34.426826]]]},"properties":{"id":"79053","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.135151,30.768471],[-95.061113,30.941302],[-95.093466,30.914278],[-95.20018,30.824566],[-95.190379,30.820723],[-95.138285,30.784661],[-95.135151,30.768471]]]},"properties":{"id":"77360","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.853586,30.545029],[-95.008766,30.613938],[-95.004959,30.580123],[-94.853586,30.545029]]]},"properties":{"id":"77335","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.805911,30.49102],[-94.814746,30.518875],[-94.827043,30.514951],[-94.818983,30.491921],[-94.805911,30.49102]]]},"properties":{"id":"77326","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.843064,35.214252],[-101.859523,35.208471],[-101.814229,35.210582],[-101.843064,35.214252]]]},"properties":{"id":"79101","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.574725,30.31977],[-104.200461,29.57881],[-104.18491,29.634696],[-103.798121,30.23292],[-103.80068,30.412527],[-104.404967,30.513949],[-104.560074,30.356868],[-104.574725,30.31977]]]},"properties":{"id":"79843","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.167584,34.883807],[-102.133219,34.914936],[-102.043787,34.912381],[-102.043987,34.883275],[-102.115669,34.883489],[-102.168437,34.853304],[-102.168641,34.79805],[-101.627618,34.88327],[-101.626376,34.929653],[-101.919054,35.043264],[-102.099413,35.058493],[-102.167497,34.956567],[-102.167508,34.92467],[-102.167584,34.883807]],[[-101.918028,34.982038],[-101.919308,34.982062],[-101.919296,34.984817],[-101.9183,34.984805],[-101.918028,34.982038]]]},"properties":{"id":"79015","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.720983,31.611497],[-99.718328,31.775219],[-99.854462,31.866371],[-100.093993,31.871775],[-100.13622,31.856876],[-100.144128,31.698846],[-100.075207,31.652745],[-99.829393,31.577275],[-99.721698,31.576759],[-99.720983,31.611497]]]},"properties":{"id":"76821","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.927828,31.591276],[-93.909889,31.451169],[-93.855763,31.410693],[-93.744284,31.516918],[-93.834918,31.586212],[-93.927828,31.591276]]]},"properties":{"id":"75959","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.166107,31.893473],[-94.296247,31.849025],[-94.414371,31.752001],[-94.405305,31.677098],[-94.3055,31.544033],[-94.081955,31.616215],[-93.943634,31.844638],[-94.166107,31.893473]]]},"properties":{"id":"75935","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.497473,30.988053],[-98.960683,31.043853],[-98.938047,30.963288],[-98.929987,30.951437],[-98.870281,30.921097],[-98.732318,30.920994],[-98.500955,30.921944],[-98.497473,30.988053]]]},"properties":{"id":"76832","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.337264,32.304867],[-95.411172,32.279024],[-95.324485,32.180394],[-95.252316,32.150461],[-95.261277,32.275038],[-95.273881,32.310964],[-95.337264,32.304867]]]},"properties":{"id":"75703","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.010252,33.268115],[-99.989656,33.107849],[-99.98968,33.265437],[-100.010252,33.268115]]]},"properties":{"id":"79540","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.424185,32.696424],[-97.381192,32.675067],[-97.366061,32.688891],[-97.364298,32.723214],[-97.411657,32.710397],[-97.424185,32.696424]]]},"properties":{"id":"76109","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.114573,32.73756],[-97.114648,32.706187],[-97.112095,32.706066],[-97.062961,32.70603],[-97.061123,32.706049],[-97.046015,32.739486],[-97.114573,32.73756]]]},"properties":{"id":"76010","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.355057,32.634811],[-97.41362,32.644793],[-97.355082,32.608198],[-97.355057,32.634811]]]},"properties":{"id":"76123","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.336387,32.784519],[-97.38367,32.785952],[-97.351224,32.765302],[-97.337405,32.759335],[-97.336387,32.784519]]]},"properties":{"id":"76164","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.18626,32.839267],[-97.207474,32.822826],[-97.14017,32.803311],[-97.140163,32.80353],[-97.155763,32.823439],[-97.166186,32.839555],[-97.18626,32.839267]]]},"properties":{"id":"76053","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.122839,32.837674],[-97.155763,32.823439],[-97.140163,32.80353],[-97.062681,32.802114],[-97.056617,32.813617],[-97.069641,32.837027],[-97.099829,32.837577],[-97.122839,32.837674]]]},"properties":{"id":"76040","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.317444,32.839495],[-97.347476,32.842585],[-97.394216,32.822363],[-97.411349,32.813187],[-97.38367,32.785952],[-97.336387,32.784519],[-97.320401,32.781256],[-97.314478,32.809976],[-97.317444,32.839495]]]},"properties":{"id":"76106","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.167792,32.860532],[-97.159453,32.840031],[-97.122839,32.837674],[-97.099829,32.837577],[-97.109051,32.866571],[-97.167792,32.860532]]]},"properties":{"id":"76021","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.845013,32.439031],[-99.828787,32.40927],[-99.812731,32.417063],[-99.845013,32.439031]]]},"properties":{"id":"79607","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.848733,32.438285],[-99.878485,32.45893],[-99.958557,32.399895],[-99.909058,32.399014],[-99.867523,32.412022],[-99.848733,32.438285]]]},"properties":{"id":"79563","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.387517,32.977414],[-99.269436,32.95707],[-99.255371,32.957081],[-99.197979,32.956833],[-98.952606,33.16713],[-98.952698,33.183523],[-99.170971,33.398303],[-99.472444,33.399023],[-99.471994,33.196182],[-99.471445,33.027427],[-99.471422,33.013972],[-99.387517,32.977414]]]},"properties":{"id":"76483","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.124914,33.166764],[-95.125573,33.11907],[-95.093083,33.129429],[-95.124914,33.166764]]]},"properties":{"id":"75493","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.739606,30.370436],[-97.708211,30.4179],[-97.769726,30.434202],[-97.774931,30.390387],[-97.744438,30.361299],[-97.739606,30.370436]]]},"properties":{"id":"78759","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.760657,30.267526],[-97.754495,30.264975],[-97.75091,30.279995],[-97.748003,30.307218],[-97.775884,30.313606],[-97.760657,30.267526]]]},"properties":{"id":"78703","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820854,30.233165],[-97.796557,30.234042],[-97.802487,30.244692],[-97.864235,30.299473],[-97.904676,30.303719],[-97.913383,30.273881],[-97.874816,30.233301],[-97.820854,30.233165]]]},"properties":{"id":"78735","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.68484,30.245782],[-97.65813,30.228095],[-97.541669,30.225253],[-97.560725,30.249029],[-97.665508,30.285658],[-97.68484,30.245782]]]},"properties":{"id":"78725","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.701711,30.282911],[-97.690258,30.24581],[-97.68484,30.245782],[-97.665508,30.285658],[-97.701711,30.282911]]]},"properties":{"id":"78721","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.993901,30.306929],[-97.913383,30.273881],[-97.904676,30.303719],[-97.90749,30.333131],[-97.934048,30.336451],[-98.004732,30.354856],[-97.993901,30.306929]]]},"properties":{"id":"78738","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.979554,30.505878],[-97.919979,30.432968],[-97.911087,30.422215],[-97.873045,30.438268],[-97.857931,30.448178],[-97.762756,30.549717],[-97.773758,30.588469],[-97.860148,30.639427],[-98.049886,30.624155],[-98.05479,30.615022],[-97.979554,30.505878]]]},"properties":{"id":"78641","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.306442,31.06582],[-95.616943,30.931083],[-95.528812,30.925795],[-95.398753,30.86595],[-95.389204,30.856107],[-95.20018,30.824566],[-95.093466,30.914278],[-95.306442,31.06582]]]},"properties":{"id":"75862","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.464355,30.527026],[-94.334503,30.526813],[-94.26409,30.526761],[-94.241127,30.615975],[-94.392057,30.66144],[-94.58166,30.681598],[-94.545717,30.526977],[-94.464355,30.527026]]]},"properties":{"id":"77664","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.017765,28.828066],[-96.983447,28.7612],[-96.931045,28.818983],[-97.017765,28.828066]]]},"properties":{"id":"77901","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.768804,28.725363],[-96.791527,28.667603],[-96.768564,28.725046],[-96.768804,28.725363]]]},"properties":{"id":"77977","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.400394,27.869925],[-100.045937,28.157455],[-100.026812,28.198636],[-100.113719,28.197819],[-100.178725,28.1972],[-100.212189,28.196837],[-99.512701,27.568811],[-99.432104,27.58908],[-99.400394,27.869925]]]},"properties":{"id":"78045","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.480337,27.53057],[-99.523232,27.530237],[-99.480219,27.485796],[-99.478921,27.489272],[-99.480337,27.53057]]]},"properties":{"id":"78040","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.432104,27.58908],[-99.512701,27.568811],[-99.523232,27.530237],[-99.480337,27.53057],[-99.410338,27.546486],[-99.432104,27.58908]]]},"properties":{"id":"78041","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.119129,29.063916],[-96.21843,29.01121],[-96.207276,29.017108],[-96.18505,29.02888],[-96.149189,29.047931],[-96.119129,29.063916]]]},"properties":{"id":"77432","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.000387,35.328146],[-100.085726,35.53783],[-100.147515,35.546174],[-100.303439,35.546474],[-100.431926,35.398054],[-100.000387,35.328146]]]},"properties":{"id":"79096","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.340085,35.619464],[-100.504565,35.619408],[-100.540158,35.619296],[-100.540101,35.601236],[-100.539517,35.532592],[-100.538889,35.404114],[-100.538802,35.388332],[-100.431926,35.398054],[-100.303439,35.546474],[-100.340085,35.619464]]]},"properties":{"id":"79061","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.085726,35.53783],[-100.000392,35.619115],[-100.147315,35.619379],[-100.147515,35.546174],[-100.085726,35.53783]]]},"properties":{"id":"79003","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.519283,33.995546],[-98.550712,33.917176],[-98.533796,33.90955],[-98.469964,33.932895],[-98.49511,33.956012],[-98.519385,33.979909],[-98.519283,33.995546]]]},"properties":{"id":"76306","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.796466,26.338291],[-97.780359,26.335937],[-97.78064,26.350099],[-97.812877,26.349806],[-97.796466,26.338291]]]},"properties":{"id":"78594","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.692854,30.571219],[-97.609439,30.571847],[-97.490343,30.626949],[-97.439031,30.644188],[-97.560137,30.75866],[-97.566033,30.772308],[-97.626574,30.773841],[-97.651837,30.700951],[-97.692854,30.571219]]]},"properties":{"id":"78626","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.53518,30.836143],[-97.66628,30.878545],[-97.689025,30.819481],[-97.626574,30.773841],[-97.566033,30.772308],[-97.514125,30.815455],[-97.513067,30.827711],[-97.53518,30.836143]]]},"properties":{"id":"76537","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.673977,33.183948],[-97.804057,33.073558],[-97.687486,33.017147],[-97.611112,33.10921],[-97.673977,33.183948]]]},"properties":{"id":"76073","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.270105,32.761006],[-95.414227,32.905071],[-95.467999,32.934891],[-95.549344,32.811826],[-95.534922,32.768895],[-95.270105,32.761006]]]},"properties":{"id":"75783","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.886266,31.988994],[-95.973162,31.919268],[-95.97029,31.877187],[-95.959317,31.782072],[-95.867028,31.728634],[-95.764609,31.825823],[-95.886266,31.988994]]]},"properties":{"id":"75861","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.91451,28.377003],[-96.946284,28.297059],[-96.911678,28.265182],[-96.795001,28.364682],[-96.91451,28.377003]]]},"properties":{"id":"77950","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.284411,28.097478],[-97.457537,27.939924],[-97.304544,27.929542],[-97.268895,27.926462],[-97.168713,27.996343],[-97.204009,28.080812],[-97.262853,28.087394],[-97.284411,28.097478]]]},"properties":{"id":"78390","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.434887,34.747504],[-101.625956,34.952702],[-101.626376,34.929653],[-101.627618,34.88327],[-101.493912,34.747509],[-101.471562,34.747462],[-101.434887,34.747504]]]},"properties":{"id":"79094","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.260625,29.900509],[-96.269662,29.689055],[-96.199451,29.656455],[-96.032711,29.727944],[-96.036276,29.771743],[-96.041399,29.789288],[-96.112353,29.889406],[-96.260625,29.900509]],[[-96.10873,29.79859],[-96.10406,29.79672],[-96.10632,29.79229],[-96.11132,29.79333],[-96.10873,29.79859]]]},"properties":{"id":"77474","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.366433,29.916888],[-96.477994,29.82055],[-96.476137,29.806125],[-96.469358,29.801381],[-96.373745,29.690415],[-96.269662,29.689055],[-96.260625,29.900509],[-96.366433,29.916888]]]},"properties":{"id":"78933","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.781024,34.437876],[-103.020112,34.611396],[-103.043266,34.606597],[-103.043557,34.274781],[-102.744483,34.422424],[-102.781024,34.437876]]]},"properties":{"id":"79325","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.41163,29.735809],[-99.378833,29.627425],[-99.262695,29.625704],[-99.215387,29.696669],[-99.41163,29.735809]]]},"properties":{"id":"78883","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.90203,28.547],[-97.845252,28.526079],[-97.823418,28.55231],[-97.753645,28.643857],[-97.77853,28.668027],[-97.900175,28.565259],[-97.90203,28.547]]]},"properties":{"id":"78146","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.753403,31.111298],[-97.871907,31.087411],[-97.750406,31.110243],[-97.753403,31.111298]]],[[[-97.854133,30.932189],[-97.800815,30.902689],[-97.872606,31.057927],[-97.912773,31.003684],[-97.854133,30.932189]]]]},"properties":{"id":"76549","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.350547,31.189132],[-97.174055,31.133277],[-97.167205,31.18609],[-97.278113,31.279799],[-97.355164,31.19034],[-97.350547,31.189132]]]},"properties":{"id":"76579","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.338143,30.996317],[-97.393451,30.993412],[-97.400978,30.979822],[-97.331357,30.956984],[-97.337475,30.979571],[-97.338143,30.996317]]]},"properties":{"id":"76554","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.282671,29.444655],[-98.18863,29.377907],[-98.138033,29.439391],[-98.195025,29.497203],[-98.282692,29.444676],[-98.282671,29.444655]]]},"properties":{"id":"78152","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.3365,29.312568],[-98.427644,29.384286],[-98.477814,29.374153],[-98.431976,29.274941],[-98.406762,29.2437],[-98.29246,29.251235],[-98.281905,29.255018],[-98.255615,29.296176],[-98.3365,29.312568]],[[-98.452139,29.341642],[-98.45301,29.35185],[-98.432906,29.350603],[-98.43777,29.341141],[-98.452139,29.341642]]]},"properties":{"id":"78223","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.805603,29.528496],[-98.705364,29.49429],[-98.667531,29.553482],[-98.805684,29.548014],[-98.805603,29.528496]]]},"properties":{"id":"78254","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.50783,29.488547],[-98.503978,29.4409],[-98.497006,29.430952],[-98.493798,29.436174],[-98.472488,29.46149],[-98.481807,29.491521],[-98.507819,29.493808],[-98.50783,29.488547]]]},"properties":{"id":"78212","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.522652,29.671769],[-98.51932,29.634865],[-98.450081,29.667407],[-98.44369,29.719436],[-98.521447,29.74512],[-98.522652,29.671769]]]},"properties":{"id":"78260","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.525684,29.572435],[-98.540503,29.602174],[-98.540614,29.602463],[-98.563844,29.599685],[-98.561878,29.578875],[-98.53495,29.557268],[-98.515395,29.55416],[-98.525684,29.572435]]]},"properties":{"id":"78231","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.450081,29.667407],[-98.51932,29.634865],[-98.540614,29.602463],[-98.540503,29.602174],[-98.508264,29.608741],[-98.467884,29.611377],[-98.450039,29.660913],[-98.450081,29.667407]]]},"properties":{"id":"78258","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.442597,29.435356],[-98.47572,29.436544],[-98.481729,29.427639],[-98.468072,29.419414],[-98.443068,29.419638],[-98.442597,29.435356]]]},"properties":{"id":"78202","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.643901,29.623088],[-98.64505,29.577562],[-98.630989,29.587723],[-98.643901,29.623088]]],[[[-98.710267,29.65844],[-98.644186,29.623235],[-98.630794,29.664197],[-98.638597,29.678795],[-98.710267,29.65844]]]]},"properties":{"id":"78255","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.615228,29.770359],[-98.634673,29.677056],[-98.611558,29.731034],[-98.611599,29.740063],[-98.615228,29.770359]]]},"properties":{"id":"78015","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.588988,30.312159],[-98.648806,30.138864],[-98.546045,30.153927],[-98.587982,30.239484],[-98.588988,30.312159]]]},"properties":{"id":"78671","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.845544,32.097109],[-97.828902,31.990412],[-97.707893,32.028776],[-97.618789,32.057403],[-97.676243,32.159225],[-97.845544,32.097109]]]},"properties":{"id":"76690","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.249721,33.364178],[-94.263436,33.338355],[-94.215246,33.32085],[-94.249721,33.364178]]]},"properties":{"id":"75573","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.261697,29.523774],[-95.351907,29.585455],[-95.431027,29.583509],[-95.442458,29.554424],[-95.433512,29.544819],[-95.417038,29.540723],[-95.329357,29.5027],[-95.261697,29.523774]]]},"properties":{"id":"77584","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.261697,29.523774],[-95.329357,29.5027],[-95.358604,29.410351],[-95.371094,29.301744],[-95.330037,29.28274],[-95.229731,29.263598],[-95.093211,29.256106],[-95.103356,29.271411],[-95.131855,29.314012],[-95.148221,29.428271],[-95.173023,29.464775],[-95.178389,29.4678],[-95.223826,29.51531],[-95.261697,29.523774]]]},"properties":{"id":"77511","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.476715,29.027101],[-95.421264,28.934819],[-95.436146,29.002555],[-95.476715,29.027101]]],[[[-95.388918,29.004752],[-95.304105,29.119269],[-95.385502,29.111204],[-95.388918,29.004752]]]]},"properties":{"id":"77541","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.735844,29.319156],[-95.847656,29.26259],[-95.841948,29.258707],[-95.772974,29.197351],[-95.739817,29.214897],[-95.735844,29.319156]]]},"properties":{"id":"77444","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.540182,30.456758],[-103.719468,30.469572],[-103.80068,30.412527],[-103.798121,30.23292],[-103.79504,29.662942],[-103.34281,29.459671],[-102.889446,29.210156],[-103.217166,29.800188],[-103.469058,30.258105],[-103.388102,30.345158],[-103.540182,30.456758]]]},"properties":{"id":"79830","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.129914,31.591969],[-99.04287,31.488903],[-99.027427,31.479951],[-98.942737,31.458629],[-98.822727,31.584889],[-98.877298,31.642306],[-98.966133,31.80485],[-99.034974,32.021323],[-99.094628,32.035105],[-99.197623,32.01194],[-99.222972,31.941519],[-99.248412,31.897303],[-99.20195,31.835052],[-99.129914,31.591969]]]},"properties":{"id":"76801","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.931917,32.270924],[-99.118404,32.124739],[-99.094628,32.035105],[-99.034974,32.021323],[-98.870799,32.053424],[-98.851601,32.113149],[-98.87732,32.143089],[-98.931917,32.270924]]]},"properties":{"id":"76471","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.470842,30.50953],[-96.418828,30.439632],[-96.377389,30.491203],[-96.470831,30.509585],[-96.470842,30.50953]]]},"properties":{"id":"77878","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.484271,30.735421],[-98.400608,30.599476],[-98.352717,30.688353],[-98.408603,30.71309],[-98.484271,30.735421]]]},"properties":{"id":"78639","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.939554,30.806541],[-98.049886,30.624155],[-97.860148,30.639427],[-97.828823,30.696252],[-97.87706,30.774885],[-97.939554,30.806541]]]},"properties":{"id":"78642","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.208033,29.613764],[-97.281528,29.817629],[-97.409464,29.759289],[-97.394954,29.641134],[-97.229117,29.596102],[-97.208033,29.613764]]]},"properties":{"id":"78959","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.737095,29.711389],[-97.683873,29.582812],[-97.557704,29.614725],[-97.5385,29.776808],[-97.562305,29.810132],[-97.774307,29.75772],[-97.765018,29.747301],[-97.737095,29.711389]]]},"properties":{"id":"78648","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.780917,29.759258],[-97.77334,29.741852],[-97.765018,29.747301],[-97.774307,29.75772],[-97.780917,29.759258]]]},"properties":{"id":"78622","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.643442,28.712105],[-96.57462,28.705503],[-96.610377,28.721384],[-96.643442,28.712105]]]},"properties":{"id":"77978","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.458486,32.514908],[-99.457291,32.080099],[-99.422906,32.080154],[-99.262815,32.080091],[-99.145744,32.217056],[-99.205926,32.295812],[-99.195278,32.406908],[-99.206622,32.448822],[-99.315033,32.515075],[-99.458486,32.514908]]]},"properties":{"id":"79504","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.510602,25.963258],[-97.299802,26.001924],[-97.398005,26.049123],[-97.478683,26.043792],[-97.525826,25.997553],[-97.510602,25.963258]]]},"properties":{"id":"78526","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.510602,25.963258],[-97.49036,25.883358],[-97.142984,26.066048],[-97.161099,26.065961],[-97.299802,26.001924],[-97.510602,25.963258]]]},"properties":{"id":"78521","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.142984,26.066048],[-97.283788,26.264989],[-97.198166,26.082529],[-97.161099,26.065961],[-97.142984,26.066048]]]},"properties":{"id":"78597","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.62289,35.677858],[-101.408652,35.567493],[-101.399048,35.598331],[-101.496257,35.624204],[-101.497726,35.63646],[-101.508498,35.649487],[-101.62289,35.677858]]]},"properties":{"id":"79036","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.328954,35.182009],[-101.086447,35.165303],[-100.992187,35.151213],[-100.888149,35.183089],[-100.913221,35.256217],[-101.085974,35.429806],[-101.085983,35.422457],[-101.20389,35.327311],[-101.328954,35.182009]]]},"properties":{"id":"79039","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.0459,33.272368],[-94.114307,33.137718],[-94.043028,33.055322],[-94.042987,33.271242],[-94.0459,33.272368]]]},"properties":{"id":"75556","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.146944,34.639888],[-102.196385,34.481351],[-102.071241,34.465144],[-101.998358,34.559152],[-101.998358,34.588488],[-102.146944,34.639888]]]},"properties":{"id":"79063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.196385,34.481351],[-102.252353,34.313188],[-102.181679,34.273586],[-102.015491,34.313095],[-101.998095,34.36292],[-101.998229,34.441828],[-102.071241,34.465144],[-102.196385,34.481351]]]},"properties":{"id":"79043","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.84757,29.87304],[-94.878994,29.850514],[-94.893322,29.866791],[-94.98146,29.884276],[-94.966712,29.858391],[-94.916618,29.770271],[-94.930807,29.673759],[-94.793405,29.832008],[-94.84757,29.87304]]]},"properties":{"id":"77523","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.654351,29.84037],[-94.577426,29.837425],[-94.534569,29.889506],[-94.639028,29.888384],[-94.659943,29.88812],[-94.654351,29.84037]]]},"properties":{"id":"77560","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.324485,32.180394],[-95.472796,32.145051],[-95.422174,32.043818],[-95.419688,32.049899],[-95.239852,32.092572],[-95.231829,32.140071],[-95.252316,32.150461],[-95.324485,32.180394]]]},"properties":{"id":"75757","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.151507,31.901644],[-95.147152,31.879591],[-95.135792,31.886633],[-95.142858,31.90656],[-95.151507,31.901644]]]},"properties":{"id":"75764","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.415895,34.747525],[-100.415794,34.560651],[-100.41595,34.517205],[-100.416567,34.448342],[-100.503373,34.314079],[-100.817326,34.266439],[-100.747203,34.209313],[-100.518042,34.212151],[-100.259277,34.19588],[-100.070765,34.22886],[-99.997501,34.560574],[-100.000381,34.616297],[-100.152814,34.747073],[-100.382295,34.747524],[-100.415895,34.747525]]]},"properties":{"id":"79201","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.409843,31.462006],[-99.374035,31.561697],[-99.42487,31.623491],[-99.529751,31.642919],[-99.543269,31.62837],[-99.574234,31.49368],[-99.511477,31.424401],[-99.409843,31.462006]]]},"properties":{"id":"76845","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.708059,33.003867],[-96.756858,33.000422],[-96.768152,32.986535],[-96.768983,32.950985],[-96.768987,32.939867],[-96.751937,32.939974],[-96.748076,32.938314],[-96.716221,32.975527],[-96.709022,32.997207],[-96.708059,33.003867]]]},"properties":{"id":"75080","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.8646,33.27718],[-96.754137,33.284528],[-96.607833,33.3056],[-96.656486,33.401331],[-96.834004,33.405504],[-96.89225,33.288395],[-96.8646,33.27718]]]},"properties":{"id":"75009","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.88026,33.21905],[-96.774074,33.21248],[-96.767642,33.216229],[-96.754137,33.284528],[-96.8646,33.27718],[-96.89805,33.21952],[-96.88026,33.21905]]]},"properties":{"id":"75078","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.402353,33.39808],[-96.479012,33.27409],[-96.474734,33.247135],[-96.439933,33.234179],[-96.295248,33.269923],[-96.295304,33.291409],[-96.363749,33.406442],[-96.394773,33.398325],[-96.402353,33.39808]]]},"properties":{"id":"75424","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.489784,33.575275],[-96.496661,33.52423],[-96.480432,33.528063],[-96.472,33.511674],[-96.510066,33.516715],[-96.517217,33.488619],[-96.429626,33.398426],[-96.402353,33.39808],[-96.394773,33.398325],[-96.258797,33.485004],[-96.265895,33.506866],[-96.306572,33.563863],[-96.382584,33.562796],[-96.489784,33.575275]]]},"properties":{"id":"75491","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.430431,29.573931],[-96.408171,29.515281],[-96.417608,29.580277],[-96.430431,29.573931]]]},"properties":{"id":"77412","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.706155,29.486696],[-96.809387,29.577134],[-96.832812,29.576863],[-96.984722,29.572656],[-97.064001,29.485004],[-97.065832,29.411409],[-97.065944,29.376124],[-97.061815,29.334841],[-96.847554,29.12968],[-96.658466,29.263876],[-96.64234,29.42002],[-96.706155,29.486696]]]},"properties":{"id":"77964","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.51269,32.205542],[-98.588784,32.241779],[-98.712113,32.106019],[-98.505427,32.016705],[-98.51269,32.205542]]]},"properties":{"id":"76444","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.177206,31.932988],[-98.301699,31.84993],[-98.227852,31.805234],[-98.162264,31.850037],[-98.177206,31.932988]]]},"properties":{"id":"76436","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.649215,31.087],[-99.738436,31.319237],[-99.794149,31.389004],[-100.112625,31.429528],[-100.112914,31.399287],[-100.112932,31.366042],[-100.114052,31.218703],[-100.114955,31.148856],[-100.115216,31.087994],[-100.068873,31.087766],[-99.649215,31.087]]]},"properties":{"id":"76837","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.277095,33.48052],[-97.37453,33.44804],[-97.343568,33.429095],[-97.254323,33.456261],[-97.277095,33.48052]]],[[[-97.277315,33.528813],[-97.03229,33.524818],[-96.943359,33.576141],[-97.052656,33.816505],[-97.314257,33.865089],[-97.261224,33.642358],[-97.217844,33.630609],[-97.283531,33.604526],[-97.298775,33.623392],[-97.322427,33.612119],[-97.382438,33.505096],[-97.277315,33.528813]]]]},"properties":{"id":"76240","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.03229,33.524818],[-97.277315,33.528813],[-97.277095,33.48052],[-97.254323,33.456261],[-97.0372,33.358074],[-96.983035,33.417677],[-96.999484,33.481392],[-97.03229,33.524818]]]},"properties":{"id":"76272","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.298526,33.623487],[-97.320804,33.630533],[-97.322427,33.612119],[-97.298775,33.623392],[-97.298526,33.623487]]]},"properties":{"id":"76253","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.733548,31.476357],[-97.736931,31.475661],[-97.737672,31.473753],[-97.735181,31.473942],[-97.733548,31.476357]]]},"properties":{"id":"76598","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.273775,31.150486],[-101.386499,31.59239],[-101.656675,31.609332],[-101.77551,31.406207],[-101.775654,31.363379],[-101.825001,31.079752],[-101.685966,30.855599],[-101.405736,31.008781],[-101.273836,31.14692],[-101.273775,31.150486]]]},"properties":{"id":"76932","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.461414,33.796345],[-101.514207,33.491496],[-101.429436,33.423478],[-101.310904,33.516166],[-101.335668,33.768972],[-101.465883,33.831289],[-101.461414,33.796345]]]},"properties":{"id":"79357","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.366476,36.500396],[-102.162867,36.430927],[-102.162463,36.500326],[-102.366476,36.500396]]]},"properties":{"id":"79051","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.78961,32.81559],[-96.799205,32.818945],[-96.805843,32.802048],[-96.790999,32.784067],[-96.782507,32.789892],[-96.769486,32.800285],[-96.771553,32.802084],[-96.78961,32.81559]]]},"properties":{"id":"75204","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.838963,32.987521],[-96.854689,32.98775],[-96.939858,32.988822],[-96.942255,32.988798],[-96.935245,32.937044],[-96.937284,32.921025],[-96.858915,32.953158],[-96.838963,32.987521]]]},"properties":{"id":"75006","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.768987,32.939867],[-96.768983,32.950985],[-96.822271,32.954169],[-96.828281,32.94722],[-96.82552,32.939518],[-96.768987,32.939867]]]},"properties":{"id":"75254","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.634854,32.843785],[-96.668296,32.797221],[-96.592843,32.78994],[-96.59583,32.833246],[-96.634854,32.843785]]]},"properties":{"id":"75150","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.931075,32.90943],[-96.971939,32.895636],[-96.949071,32.866743],[-96.916124,32.856042],[-96.93114,32.886088],[-96.928376,32.895688],[-96.931075,32.90943]]]},"properties":{"id":"75039","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.725103,32.858517],[-96.728879,32.804288],[-96.725816,32.804378],[-96.667889,32.868031],[-96.676303,32.870985],[-96.725103,32.858517]]]},"properties":{"id":"75218","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.822706,32.604247],[-96.822359,32.56711],[-96.718224,32.542035],[-96.665665,32.560721],[-96.710846,32.612558],[-96.822706,32.604247]]]},"properties":{"id":"75146","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.891414,32.661913],[-96.943226,32.662227],[-96.942971,32.647244],[-96.891392,32.647499],[-96.891414,32.661913]]]},"properties":{"id":"75116","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.037491,32.618456],[-97.061339,32.584981],[-97.05262,32.548907],[-97.038199,32.560026],[-97.037491,32.618456]]]},"properties":{"id":"75054","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.138294,32.896735],[-102.266679,32.851979],[-102.450834,32.842424],[-102.467994,32.784129],[-102.352364,32.70493],[-102.079768,32.41299],[-101.977523,32.45194],[-101.861856,32.524471],[-101.712991,32.604176],[-101.689682,32.68423],[-101.690162,32.751706],[-102.041663,32.960008],[-102.138294,32.896735]]]},"properties":{"id":"79331","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.837784,35.183794],[-102.547401,35.101476],[-102.606343,35.452164],[-102.679141,35.519666],[-102.712956,35.555638],[-102.837784,35.183794]]]},"properties":{"id":"79001","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.109686,33.054726],[-97.040425,32.984533],[-97.032387,32.984623],[-97.028593,32.989314],[-97.044523,33.048934],[-97.109686,33.054726]]]},"properties":{"id":"75028","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.298363,32.963177],[-97.260412,33.040956],[-97.31068,33.02431],[-97.348818,32.924293],[-97.317076,32.88693],[-97.306971,32.890777],[-97.298363,32.963177]]]},"properties":{"id":"76177","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.431476,32.992331],[-97.391991,32.924531],[-97.348818,32.924293],[-97.31068,33.02431],[-97.39179,33.03617],[-97.439496,33.011358],[-97.431476,32.992331]]]},"properties":{"id":"76052","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.518541,33.780873],[-100.879441,33.632649],[-100.626063,33.570871],[-100.518375,33.745142],[-100.518541,33.780873]]]},"properties":{"id":"79229","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.620341,28.573796],[-100.114336,28.648122],[-100.114269,28.629686],[-100.113719,28.197819],[-100.026812,28.198636],[-99.90212,28.199748],[-99.778725,28.336232],[-99.646495,28.500079],[-99.620341,28.573796]]]},"properties":{"id":"78834","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.575857,35.053818],[-100.721403,35.033504],[-100.741024,34.882783],[-100.793221,34.861851],[-100.718071,34.762142],[-100.669115,34.76247],[-100.539606,35.009863],[-100.575857,35.053818]]]},"properties":{"id":"79237","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.85127,26.785335],[-98.606486,26.783929],[-98.535784,27.265158],[-98.523723,27.265115],[-98.726846,27.499261],[-98.783301,27.527663],[-98.799235,27.504338],[-98.798489,27.389964],[-98.897769,27.26894],[-99.008709,27.269847],[-99.147137,27.228492],[-98.954301,26.835477],[-98.95423,26.785694],[-98.85127,26.785335]]]},"properties":{"id":"78361","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.28735,31.885472],[-102.252486,31.970816],[-102.287307,31.926143],[-102.28735,31.885472]]]},"properties":{"id":"79765","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.798375,31.882956],[-102.798939,31.651783],[-102.77396,31.651711],[-102.662253,31.70014],[-102.478912,31.786451],[-102.56238,31.826674],[-102.576537,31.868494],[-102.798522,31.964308],[-102.798375,31.882956]]]},"properties":{"id":"79759","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.798522,31.964308],[-102.576537,31.868494],[-102.478372,31.952163],[-102.798522,31.964308]]]},"properties":{"id":"79741","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.917708,29.946761],[-100.102967,29.76313],[-99.929926,29.831913],[-99.917708,29.946761]]]},"properties":{"id":"78828","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.91969,32.547835],[-97.065507,32.549075],[-97.086684,32.526015],[-97.014231,32.373009],[-96.890913,32.500496],[-96.91969,32.547835]]]},"properties":{"id":"76065","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.717535,32.376087],[-96.60449,32.483167],[-96.711786,32.489215],[-96.75753,32.481395],[-96.717535,32.376087]]]},"properties":{"id":"75152","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.698741,32.264525],[-96.699761,32.270547],[-96.710967,32.281137],[-96.715339,32.278984],[-96.703646,32.262105],[-96.698741,32.264525]]]},"properties":{"id":"75101","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.397765,31.781069],[-106.40412,31.744621],[-106.368294,31.709571],[-106.345255,31.752676],[-106.397765,31.781069]]]},"properties":{"id":"79915","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.620071,31.916634],[-106.582678,31.908361],[-106.566255,31.907331],[-106.55797,31.905646],[-106.563095,31.976734],[-106.614375,31.955991],[-106.620071,31.916634]]]},"properties":{"id":"79835","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.20825,31.538127],[-106.213225,31.473205],[-106.142793,31.430126],[-106.127145,31.476721],[-106.131252,31.519374],[-106.140002,31.530683],[-106.20825,31.538127]]]},"properties":{"id":"79838","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.92541,32.615985],[-98.064369,32.637236],[-98.22353,32.512597],[-98.126024,32.441975],[-98.033972,32.432651],[-97.923951,32.460643],[-97.87582,32.557269],[-97.92541,32.615985]]]},"properties":{"id":"76462","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.218956,32.216796],[-98.216997,32.213943],[-98.212911,32.215291],[-98.215227,32.218659],[-98.218956,32.216796]]]},"properties":{"id":"76402","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.382584,33.562796],[-96.306572,33.563863],[-96.277699,33.608807],[-96.276387,33.657812],[-96.308314,33.715746],[-96.379452,33.725764],[-96.37991,33.703327],[-96.382584,33.562796]]]},"properties":{"id":"75479","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.049408,33.836727],[-96.189177,33.755688],[-96.14702,33.696874],[-96.083149,33.67473],[-96.049408,33.836727]]]},"properties":{"id":"75447","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.145741,32.726536],[-100.309708,32.736853],[-100.309713,32.667879],[-100.273764,32.624663],[-100.146013,32.609552],[-100.072694,32.674621],[-100.084045,32.711505],[-100.145741,32.726536]]]},"properties":{"id":"79560","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.738091,32.545586],[-100.66389,32.25901],[-100.490262,32.27068],[-100.469946,32.357677],[-100.531925,32.583233],[-100.738091,32.545586]]]},"properties":{"id":"79545","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.775477,29.700738],[-95.776804,29.784947],[-95.8826,29.786303],[-95.901366,29.742121],[-95.864574,29.693168],[-95.775477,29.700738]]]},"properties":{"id":"77494","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.775477,29.700738],[-95.683972,29.601532],[-95.683419,29.601611],[-95.679968,29.67675],[-95.682941,29.707788],[-95.708212,29.721851],[-95.775477,29.700738]]]},"properties":{"id":"77407","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.933043,29.591324],[-95.968454,29.608822],[-95.977699,29.604279],[-95.933043,29.591324]]]},"properties":{"id":"77464","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.280546,33.376966],[-95.307863,33.259009],[-95.188865,33.288213],[-94.977108,33.389817],[-95.280546,33.376966]]]},"properties":{"id":"75487","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.337909,33.082546],[-95.38837,32.941567],[-95.414227,32.905071],[-95.270105,32.761006],[-95.203139,32.731513],[-95.197103,32.731046],[-95.137769,32.769827],[-95.134596,32.86856],[-95.146789,32.902621],[-95.172947,32.961829],[-95.241898,33.080408],[-95.308075,33.102071],[-95.337909,33.082546]]]},"properties":{"id":"75494","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.487551,33.350825],[-95.367453,33.226447],[-95.307884,33.255938],[-95.307863,33.259009],[-95.280546,33.376966],[-95.30664,33.378027],[-95.487551,33.350825]]]},"properties":{"id":"75481","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.148221,29.428271],[-95.131855,29.314012],[-95.114687,29.429626],[-95.148221,29.428271]]]},"properties":{"id":"77517","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.951834,29.379156],[-94.930844,29.337525],[-94.891747,29.356624],[-94.961227,29.420584],[-94.957534,29.408034],[-94.951834,29.379156]]]},"properties":{"id":"77590","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.931451,29.329288],[-95.050486,29.391458],[-95.103356,29.271411],[-95.093211,29.256106],[-94.931451,29.329288]]]},"properties":{"id":"77563","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.705933,28.567465],[-97.478587,28.431527],[-97.753645,28.643857],[-97.705933,28.567465]]]},"properties":{"id":"78107","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.289155,29.34871],[-97.065832,29.411409],[-97.064001,29.485004],[-97.182195,29.597734],[-97.208033,29.613764],[-97.229117,29.596102],[-97.289155,29.34871]]]},"properties":{"id":"77984","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.88688,29.377237],[-97.862446,29.271028],[-97.8406,29.257375],[-97.8088,29.162799],[-97.728443,29.221748],[-97.653957,29.340034],[-97.674106,29.418557],[-97.819936,29.403153],[-97.88688,29.377237]]]},"properties":{"id":"78140","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.756952,32.480368],[-94.781023,32.449665],[-94.734013,32.318838],[-94.606221,32.36023],[-94.596699,32.363219],[-94.591346,32.392402],[-94.603982,32.399435],[-94.756736,32.479672],[-94.756952,32.480368]]]},"properties":{"id":"75603","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.786464,34.748353],[-100.94522,34.629966],[-100.817634,34.56889],[-100.668894,34.53327],[-100.617499,34.714102],[-100.667565,34.748054],[-100.786464,34.748353]]]},"properties":{"id":"79239","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.36978,31.576764],[-98.288844,31.661309],[-98.399363,31.706121],[-98.416145,31.663624],[-98.352229,31.635457],[-98.36978,31.576764]]]},"properties":{"id":"76565","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.481228,29.964893],[-95.462757,29.941105],[-95.424577,29.939632],[-95.421866,29.962576],[-95.422915,29.965982],[-95.481228,29.964893]]]},"properties":{"id":"77067","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.481228,29.964893],[-95.501172,29.983657],[-95.536184,29.962094],[-95.530747,29.950558],[-95.521692,29.936627],[-95.483258,29.937567],[-95.462757,29.941105],[-95.481228,29.964893]]]},"properties":{"id":"77066","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.476371,30.024371],[-95.464393,30.00398],[-95.422915,29.965982],[-95.421866,29.962576],[-95.417761,29.965747],[-95.429398,30.03563],[-95.476371,30.024371]]]},"properties":{"id":"77090","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.142735,29.631221],[-95.17112,29.63057],[-95.207974,29.65098],[-95.249168,29.652213],[-95.251593,29.652503],[-95.253757,29.652167],[-95.222037,29.620428],[-95.181294,29.574477],[-95.168885,29.569464],[-95.168207,29.5913],[-95.142735,29.631221]]]},"properties":{"id":"77034","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.172712,29.691757],[-95.228088,29.690563],[-95.227966,29.669827],[-95.207833,29.664801],[-95.174906,29.665808],[-95.172712,29.691757]]]},"properties":{"id":"77502","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.207833,29.664801],[-95.207974,29.65098],[-95.17112,29.63057],[-95.172234,29.665319],[-95.174906,29.665808],[-95.207833,29.664801]]]},"properties":{"id":"77504","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.288746,29.799291],[-95.293669,29.74722],[-95.282016,29.741549],[-95.256613,29.726853],[-95.225191,29.746676],[-95.218354,29.771169],[-95.28014,29.802473],[-95.288746,29.799291]]]},"properties":{"id":"77029","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.332917,29.714372],[-95.326664,29.695466],[-95.290279,29.716811],[-95.294899,29.727945],[-95.333109,29.743215],[-95.342067,29.731823],[-95.332917,29.714372]]]},"properties":{"id":"77023","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.438408,29.78396],[-95.543969,29.78431],[-95.56517,29.784433],[-95.568921,29.761943],[-95.545305,29.752995],[-95.500857,29.760378],[-95.479711,29.773378],[-95.455722,29.759957],[-95.439612,29.781512],[-95.438408,29.78396]]]},"properties":{"id":"77024","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.363205,29.680767],[-95.356286,29.648215],[-95.314894,29.654952],[-95.326944,29.695307],[-95.363205,29.680767]]]},"properties":{"id":"77033","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.417761,29.965747],[-95.388369,29.965973],[-95.370443,29.998717],[-95.382415,30.040362],[-95.429398,30.03563],[-95.417761,29.965747]]]},"properties":{"id":"77073","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.253757,29.652167],[-95.308757,29.634591],[-95.297409,29.596514],[-95.286506,29.597588],[-95.222037,29.620428],[-95.253757,29.652167]]]},"properties":{"id":"77075","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.207974,29.65098],[-95.207833,29.664801],[-95.227966,29.669827],[-95.249168,29.652213],[-95.207974,29.65098]]]},"properties":{"id":"77587","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.168207,29.5913],[-95.168885,29.569464],[-95.160405,29.497649],[-95.095222,29.528923],[-95.128593,29.548957],[-95.159308,29.581813],[-95.168207,29.5913]]]},"properties":{"id":"77598","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.181294,29.574477],[-95.222037,29.620428],[-95.286506,29.597588],[-95.216422,29.55608],[-95.181294,29.574477]]]},"properties":{"id":"77089","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.501044,29.722678],[-95.500857,29.760378],[-95.545305,29.752995],[-95.539059,29.719588],[-95.501044,29.722678]]]},"properties":{"id":"77063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.190143,30.083978],[-95.176531,30.027324],[-95.132047,30.021156],[-95.155006,30.082097],[-95.16216,30.084188],[-95.190143,30.083978]]]},"properties":{"id":"77345","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.471422,33.013972],[-99.388955,32.956965],[-99.387517,32.977414],[-99.471422,33.013972]]],[[[-99.443286,32.710915],[-99.519363,32.996864],[-99.620869,32.956962],[-99.684479,32.801842],[-99.619573,32.746339],[-99.495184,32.720257],[-99.443286,32.710915]]]]},"properties":{"id":"79533","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.097852,30.079817],[-98.010418,30.046379],[-97.979005,30.060619],[-97.975999,30.128761],[-98.008778,30.153469],[-98.097852,30.079817]]]},"properties":{"id":"78619","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.955035,32.40664],[-96.075708,32.547542],[-96.194588,32.491726],[-96.167668,32.357996],[-96.055269,32.338656],[-95.955035,32.40664]]]},"properties":{"id":"75147","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.304369,26.344588],[-98.273194,26.237432],[-98.271953,26.244684],[-98.251571,26.314132],[-98.304369,26.344588]]]},"properties":{"id":"78573","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.159867,26.540613],[-98.14693,26.299236],[-98.175154,26.247623],[-98.159155,26.241899],[-98.125689,26.240784],[-98.084974,26.235103],[-98.037686,26.288989],[-98.037803,26.297208],[-98.021519,26.314447],[-98.061637,26.427451],[-97.996514,26.49191],[-97.963943,26.53364],[-98.111976,26.695474],[-98.159867,26.540613]]]},"properties":{"id":"78542","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.516745,26.286431],[-98.447233,26.228169],[-98.421902,26.287693],[-98.430428,26.332972],[-98.508585,26.395163],[-98.516227,26.397042],[-98.542558,26.344041],[-98.516745,26.286431]]]},"properties":{"id":"78576","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.884941,31.976445],[-96.831597,31.947822],[-96.828244,31.973545],[-96.857387,32.016379],[-96.900832,32.000103],[-96.884941,31.976445]]]},"properties":{"id":"76650","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.80842,31.743982],[-96.907908,31.843012],[-96.977618,31.823289],[-97.002383,31.811081],[-96.973964,31.764759],[-96.889086,31.647766],[-96.782416,31.702128],[-96.80842,31.743982]]]},"properties":{"id":"76673","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.048756,32.210641],[-97.219468,32.195587],[-97.266643,32.11171],[-97.076301,32.109648],[-97.048756,32.210641]]]},"properties":{"id":"76055","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.167123,31.905501],[-97.201854,31.844393],[-97.002383,31.811081],[-96.977618,31.823289],[-96.994575,31.897427],[-97.02322,31.96292],[-97.167123,31.905501]]]},"properties":{"id":"76621","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.367453,33.226447],[-95.487551,33.350825],[-95.503096,33.34771],[-95.434487,33.188774],[-95.367453,33.226447]]]},"properties":{"id":"75437","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.649199,32.897371],[-95.549344,32.811826],[-95.467999,32.934891],[-95.520591,32.96136],[-95.666916,32.972232],[-95.649199,32.897371]]]},"properties":{"id":"75497","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.994217,33.058791],[-96.016694,32.978973],[-95.970436,32.907908],[-95.858689,32.979534],[-95.862914,33.088727],[-95.994217,33.058791]]]},"properties":{"id":"75453","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.483614,31.397773],[-95.474315,31.402339],[-95.475255,31.432848],[-95.483614,31.397773]]]},"properties":{"id":"75849","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.272125,33.151635],[-96.296199,33.075403],[-96.296452,33.062554],[-96.208308,32.990811],[-96.148053,33.000693],[-96.210022,33.130516],[-96.272125,33.151635]]]},"properties":{"id":"75135","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.352166,33.243133],[-98.425741,33.066165],[-98.426553,33.007913],[-98.191517,33.004847],[-98.000385,33.095644],[-97.918902,33.237013],[-97.993077,33.348883],[-98.260333,33.446258],[-98.421241,33.450961],[-98.420666,33.396195],[-98.420856,33.349263],[-98.352166,33.243133]]]},"properties":{"id":"76458","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.590297,29.205697],[-96.577782,28.889414],[-96.470267,28.890956],[-96.395284,28.914837],[-96.383304,28.922588],[-96.590297,29.205697]]]},"properties":{"id":"77962","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.577782,28.889414],[-96.590297,29.205697],[-96.640315,29.247804],[-96.658466,29.263876],[-96.847554,29.12968],[-96.938587,29.063244],[-96.711851,28.845146],[-96.658044,28.820487],[-96.578567,28.83381],[-96.577782,28.889414]]]},"properties":{"id":"77957","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.927086,30.990189],[-104.179764,31.06342],[-104.211791,31.046126],[-104.344921,30.65712],[-104.410685,30.518498],[-104.404967,30.513949],[-103.80068,30.412527],[-103.719468,30.469572],[-103.79088,30.901978],[-103.927086,30.990189]]]},"properties":{"id":"79734","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.139017,30.055664],[-94.187803,30.007791],[-94.299765,30.000264],[-94.337204,30.000985],[-94.338767,29.922995],[-94.261994,29.807095],[-94.271267,29.728571],[-94.050867,29.882154],[-94.009113,29.956548],[-94.03058,30.026222],[-94.031776,30.058812],[-94.075584,30.076642],[-94.139017,30.055664]]]},"properties":{"id":"77705","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.335638,30.106305],[-94.299765,30.000264],[-94.187803,30.007791],[-94.211402,30.075557],[-94.180363,30.133241],[-94.157282,30.16226],[-94.154613,30.169949],[-94.275451,30.170265],[-94.335638,30.106305]]]},"properties":{"id":"77713","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.136017,30.085506],[-94.211402,30.075557],[-94.187803,30.007791],[-94.139017,30.055664],[-94.135282,30.075134],[-94.136017,30.085506]]]},"properties":{"id":"77707","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.091038,30.094736],[-94.120514,30.096035],[-94.135282,30.075134],[-94.139017,30.055664],[-94.075584,30.076642],[-94.091038,30.094736]]]},"properties":{"id":"77701","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.099617,28.101655],[-97.855666,27.949342],[-97.798517,27.995659],[-97.948718,28.198933],[-98.099617,28.101655]]]},"properties":{"id":"78383","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.079157,27.644533],[-98.068388,27.646583],[-98.068302,27.675136],[-98.080915,27.67512],[-98.079157,27.644533]]]},"properties":{"id":"78342","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.267679,32.464781],[-97.321128,32.446913],[-97.383601,32.423557],[-97.378971,32.266339],[-97.319339,32.23198],[-97.267322,32.332411],[-97.296102,32.397311],[-97.338685,32.38442],[-97.337669,32.402929],[-97.29609,32.399358],[-97.267679,32.464781]]]},"properties":{"id":"76031","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.727921,32.317124],[-97.705431,32.260502],[-97.630885,32.207403],[-97.615055,32.203581],[-97.684491,32.317749],[-97.727921,32.317124]]]},"properties":{"id":"76070","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.904646,28.942136],[-97.87442,28.948178],[-97.874937,28.972102],[-97.904646,28.942136]]]},"properties":{"id":"78144","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.602699,29.931576],[-98.548449,29.824643],[-98.485901,29.876046],[-98.602699,29.931576]]]},"properties":{"id":"78004","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.310282,30.017059],[-99.265489,30.134585],[-99.368067,30.103323],[-99.310282,30.017059]]]},"properties":{"id":"78025","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.133437,29.877056],[-98.993935,29.815405],[-98.989656,29.813495],[-99.03162,30.013687],[-99.179084,29.895259],[-99.133437,29.877056]]]},"properties":{"id":"78010","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.66771,30.710609],[-99.48424,30.599988],[-99.484366,30.823445],[-99.546041,30.789782],[-99.66771,30.710609]]]},"properties":{"id":"76854","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.376574,34.162374],[-102.257715,34.112296],[-102.215334,34.130193],[-102.181679,34.273586],[-102.252353,34.313188],[-102.365752,34.313123],[-102.388685,34.169957],[-102.376574,34.162374]]]},"properties":{"id":"79082","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.376574,34.162374],[-102.420306,34.072973],[-102.436338,33.964866],[-102.297649,34.011032],[-102.257715,34.112296],[-102.376574,34.162374]]]},"properties":{"id":"79312","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.090683,31.184763],[-96.097153,31.214575],[-96.317205,31.3255],[-96.330635,31.254289],[-96.278407,31.117083],[-96.201408,31.115383],[-96.090683,31.184763]]]},"properties":{"id":"77865","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.118061,30.294361],[-95.061519,30.390582],[-95.137063,30.485903],[-95.236819,30.546536],[-95.269829,30.526027],[-95.263338,30.384259],[-95.305665,30.344315],[-95.260726,30.254581],[-95.118061,30.294361]]]},"properties":{"id":"77328","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.137063,30.485903],[-95.061519,30.390582],[-94.849414,30.493553],[-94.853586,30.545029],[-95.004959,30.580123],[-95.137063,30.485903]]]},"properties":{"id":"77371","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.675719,36.499513],[-100.546037,36.215326],[-100.384234,36.288879],[-100.366264,36.361465],[-100.40543,36.499454],[-100.675719,36.499513]]]},"properties":{"id":"79005","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.907349,28.340588],[-98.038584,28.438868],[-98.098637,28.442964],[-98.110389,28.451281],[-98.334347,28.266147],[-98.334323,28.0578],[-98.235497,28.057966],[-98.099617,28.101655],[-97.948718,28.198933],[-97.907349,28.340588]]]},"properties":{"id":"78022","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.92991,30.921139],[-98.953363,30.921312],[-98.964612,30.921368],[-98.963864,30.795351],[-98.732318,30.920994],[-98.870281,30.921097],[-98.92991,30.921139]]]},"properties":{"id":"76885","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.870617,33.592313],[-101.889204,33.584635],[-101.898816,33.578752],[-101.900462,33.57771],[-101.870601,33.577789],[-101.870617,33.592313]]]},"properties":{"id":"79406","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.686718,33.538476],[-101.697252,33.527527],[-101.678039,33.523952],[-101.674689,33.536624],[-101.686718,33.538476]]]},"properties":{"id":"79366","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.077285,30.952223],[-96.204498,30.924248],[-96.16837,30.822997],[-96.013432,30.842661],[-96.077285,30.952223]]]},"properties":{"id":"77872","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.927762,31.092562],[-95.766221,30.894155],[-95.528812,30.925795],[-95.616943,30.931083],[-95.648538,31.070005],[-95.76441,31.094211],[-95.784059,31.094023],[-95.927762,31.092562]]]},"properties":{"id":"75852","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.865846,28.763025],[-95.892737,28.716407],[-95.866357,28.723406],[-95.865846,28.763025]]],[[[-95.979899,28.680205],[-95.914206,28.71081],[-95.972015,28.720698],[-95.979899,28.680205]]]]},"properties":{"id":"77457","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.866112,29.003136],[-95.874028,29.229702],[-95.899233,29.206659],[-95.951538,29.101312],[-95.866112,29.003136]]]},"properties":{"id":"77482","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.573536,28.845815],[-100.212189,28.196837],[-100.178725,28.1972],[-100.289021,28.397778],[-100.312016,28.527332],[-100.114269,28.629686],[-100.114336,28.648122],[-100.113285,28.778761],[-100.112364,28.919639],[-100.112241,28.943914],[-100.547094,28.986411],[-100.573536,28.845815]]]},"properties":{"id":"78852","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.865404,29.273801],[-98.825813,29.090402],[-98.804878,29.107015],[-98.804788,29.151788],[-98.804771,29.266128],[-98.804809,29.282154],[-98.865404,29.273801]]]},"properties":{"id":"78059","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.208056,29.230721],[-99.355986,29.483572],[-99.412434,29.463269],[-99.41314,29.177891],[-99.413862,29.093096],[-99.41388,29.091335],[-99.233818,29.090938],[-99.208056,29.230721]]]},"properties":{"id":"78850","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.154556,31.998341],[-102.107132,31.964514],[-102.124855,32.004661],[-102.154556,31.998341]]]},"properties":{"id":"79703","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.794665,33.381461],[-97.582231,33.433993],[-97.693063,33.531111],[-97.891411,33.433958],[-97.794665,33.381461]]]},"properties":{"id":"76270","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.832967,30.186513],[-95.735094,30.093721],[-95.647469,30.118107],[-95.645676,30.119221],[-95.690446,30.175226],[-95.804097,30.22646],[-95.839352,30.238044],[-95.832967,30.186513]]]},"properties":{"id":"77355","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.690446,30.175226],[-95.645676,30.119221],[-95.628738,30.12777],[-95.690446,30.175226]]]},"properties":{"id":"77362","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.989368,36.055395],[-102.02844,36.055155],[-102.018224,36.034296],[-101.99083,36.017599],[-101.989368,36.055395]]]},"properties":{"id":"79013","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.533961,31.513054],[-94.529231,31.503065],[-94.522486,31.514551],[-94.533961,31.513054]]]},"properties":{"id":"75978","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.750269,31.951552],[-96.69869,31.813191],[-96.576072,31.803046],[-96.564126,31.802034],[-96.726231,31.954839],[-96.750269,31.951552]]]},"properties":{"id":"76639","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.333727,31.943536],[-96.424247,31.939606],[-96.525752,31.944788],[-96.507597,31.797325],[-96.339927,31.873862],[-96.333727,31.943536]]]},"properties":{"id":"76681","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.451038,32.294418],[-96.521383,32.19751],[-96.469716,32.164074],[-96.418011,32.212072],[-96.451038,32.294418]]]},"properties":{"id":"75155","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.490262,32.27068],[-100.66389,32.25901],[-100.664008,32.230204],[-100.66411,32.158446],[-100.512418,32.152814],[-100.323609,32.198493],[-100.390548,32.34427],[-100.490262,32.27068]]]},"properties":{"id":"79535","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.999904,36.243767],[-101.036979,36.057887],[-100.679327,36.057046],[-100.546724,36.056536],[-100.546037,36.215326],[-100.675719,36.499513],[-101.085156,36.499244],[-101.085434,36.330015],[-101.002277,36.331306],[-100.964007,36.289946],[-100.999904,36.243767]]]},"properties":{"id":"79070","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.964007,36.289946],[-100.999854,36.289863],[-101.002277,36.331306],[-101.085434,36.330015],[-100.999904,36.243767],[-100.964007,36.289946]]]},"properties":{"id":"79093","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.596699,32.363219],[-94.606221,32.36023],[-94.571272,32.219395],[-94.404019,32.369839],[-94.458248,32.370243],[-94.579201,32.394925],[-94.596699,32.363219]]]},"properties":{"id":"75691","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.266678,32.066885],[-94.48043,32.038892],[-94.480245,32.026851],[-94.306478,31.959168],[-94.266678,32.066885]]]},"properties":{"id":"75643","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.800781,32.791922],[-97.742174,32.756973],[-97.676799,32.816153],[-97.594521,32.908567],[-97.744176,32.871061],[-97.800781,32.791922]]]},"properties":{"id":"76085","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503949,32.722071],[-97.57594,32.59715],[-97.503424,32.555439],[-97.447914,32.652119],[-97.429305,32.693196],[-97.503949,32.722071]]]},"properties":{"id":"76126","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.814229,35.210582],[-101.812898,35.193058],[-101.778776,35.192674],[-101.76006,35.203152],[-101.814229,35.210582]]]},"properties":{"id":"79104","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.932193,35.187246],[-101.941564,35.182969],[-101.920165,35.162473],[-101.920303,35.179755],[-101.932193,35.187246]]]},"properties":{"id":"79121","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.723954,30.252389],[-104.210746,29.477499],[-104.200461,29.57881],[-104.574725,30.31977],[-104.723954,30.252389]]]},"properties":{"id":"79845","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.906567,35.121726],[-101.835609,35.18286],[-101.901831,35.129209],[-101.906567,35.121726]]]},"properties":{"id":"79110","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.531342,28.17787],[-97.284411,28.097478],[-97.262853,28.087394],[-97.213518,28.184191],[-97.408493,28.344114],[-97.460637,28.273895],[-97.531342,28.17787]]]},"properties":{"id":"78393","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.262853,28.087394],[-97.204009,28.080812],[-97.125331,28.116256],[-97.213518,28.184191],[-97.262853,28.087394]]]},"properties":{"id":"78340","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.817964,32.283733],[-95.004372,32.350198],[-95.05436,32.317369],[-95.025413,32.215887],[-94.985145,32.15793],[-94.918474,32.177857],[-94.817964,32.283733]],[[-94.957585,32.259318],[-94.94503,32.248268],[-94.925998,32.253618],[-94.923042,32.236406],[-94.949233,32.248985],[-94.957585,32.259318]]]},"properties":{"id":"75684","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.606036,31.958026],[-94.648544,32.045687],[-94.761004,32.031615],[-94.826161,31.918177],[-94.733988,31.843806],[-94.681192,31.843943],[-94.629395,31.844044],[-94.606036,31.958026]]]},"properties":{"id":"75681","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.062581,31.114955],[-94.151608,31.367024],[-94.327501,31.382733],[-94.326616,31.224754],[-94.129632,31.09928],[-94.128992,31.099402],[-94.062581,31.114955]]]},"properties":{"id":"75929","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.116234,30.710366],[-100.187405,30.984922],[-100.203978,30.941488],[-100.448832,31.087906],[-100.50758,31.087552],[-100.593252,31.086989],[-100.688764,31.086576],[-100.962176,31.08249],[-100.960587,30.706071],[-100.116234,30.710366]]]},"properties":{"id":"76936","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.293797,32.373955],[-95.265652,32.349828],[-95.213546,32.353758],[-95.172184,32.361103],[-95.134667,32.411191],[-95.216472,32.490508],[-95.293797,32.373955]]]},"properties":{"id":"75708","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.017959,32.518669],[-95.15341,32.570115],[-95.206741,32.559773],[-95.211458,32.545631],[-95.216472,32.490508],[-95.134667,32.411191],[-95.031626,32.38398],[-94.986868,32.425234],[-95.017959,32.518669]]]},"properties":{"id":"75792","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.111383,32.355429],[-95.125003,32.232062],[-95.025413,32.215887],[-95.05436,32.317369],[-95.111383,32.355429]]]},"properties":{"id":"75750","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.483837,26.783741],[-98.516227,26.397042],[-98.447247,26.530636],[-98.43331,26.556885],[-98.485074,26.69712],[-98.40157,26.783404],[-98.422616,26.783535],[-98.483837,26.783741]]]},"properties":{"id":"78591","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.149196,32.706792],[-97.114648,32.706187],[-97.114573,32.73756],[-97.179713,32.731139],[-97.18242,32.730759],[-97.20271,32.713348],[-97.149196,32.706792]]]},"properties":{"id":"76013","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.114573,32.73756],[-97.046015,32.739486],[-97.06317,32.767131],[-97.114188,32.775677],[-97.114573,32.73756]]]},"properties":{"id":"76011","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.189004,32.646212],[-97.223359,32.667957],[-97.231466,32.668113],[-97.220946,32.621771],[-97.192306,32.619315],[-97.189004,32.646212]]]},"properties":{"id":"76060","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.413277,32.790819],[-97.443822,32.76557],[-97.435248,32.748818],[-97.414404,32.751291],[-97.351224,32.765302],[-97.38367,32.785952],[-97.411349,32.813187],[-97.413277,32.790819]]]},"properties":{"id":"76114","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.391991,32.924531],[-97.347476,32.842585],[-97.317444,32.839495],[-97.317076,32.88693],[-97.348818,32.924293],[-97.391991,32.924531]]]},"properties":{"id":"76131","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.099829,32.837577],[-97.069641,32.837027],[-97.06111,32.837835],[-97.099803,32.881352],[-97.109051,32.866571],[-97.099829,32.837577]]]},"properties":{"id":"76039","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.715455,32.261851],[-99.752083,32.321167],[-99.795831,32.302429],[-99.794823,32.255512],[-99.843323,32.23997],[-99.843491,32.31279],[-99.910832,32.300006],[-99.978359,32.312355],[-100.038693,32.267038],[-100.004023,32.197603],[-99.715455,32.261851]]]},"properties":{"id":"79562","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.175111,30.010612],[-101.760924,29.781802],[-101.758546,30.259866],[-102.064873,30.162548],[-102.175111,30.010612]]]},"properties":{"id":"78851","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.456029,31.442973],[-100.409588,31.4237],[-100.409377,31.441238],[-100.436201,31.544347],[-100.456029,31.442973]]]},"properties":{"id":"76903","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.17505,31.475077],[-100.159348,31.414799],[-100.112914,31.399287],[-100.112625,31.429528],[-100.112486,31.444325],[-100.17505,31.475077]]]},"properties":{"id":"76940","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.623903,31.260573],[-100.504014,31.248179],[-100.487577,31.3137],[-100.623903,31.260573]]]},"properties":{"id":"76939","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.314016,31.37184],[-100.307837,31.3699],[-100.296443,31.37134],[-100.314016,31.37184]]]},"properties":{"id":"76957","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.751207,30.215607],[-97.786254,30.166975],[-97.788605,30.161972],[-97.699872,30.145528],[-97.683288,30.22229],[-97.749367,30.215835],[-97.751207,30.215607]]]},"properties":{"id":"78744","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.919979,30.432968],[-97.979554,30.505878],[-98.011036,30.480387],[-98.003368,30.372991],[-97.919979,30.432968]]]},"properties":{"id":"78645","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.671594,30.424418],[-97.638453,30.403591],[-97.635098,30.401969],[-97.527116,30.474646],[-97.593392,30.49723],[-97.670706,30.469875],[-97.671594,30.424418]]]},"properties":{"id":"78660","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.60125,30.533533],[-97.692668,30.558173],[-97.673946,30.480093],[-97.670706,30.469875],[-97.593392,30.49723],[-97.60125,30.533533]]]},"properties":{"id":"78664","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.522344,30.930482],[-94.198037,30.853293],[-94.298196,31.036993],[-94.451127,31.003445],[-94.522344,30.930482]]]},"properties":{"id":"75938","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.392057,30.66144],[-94.241127,30.615975],[-94.234253,30.61416],[-94.19193,30.698576],[-94.392057,30.66144]]]},"properties":{"id":"77624","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.34595,32.609291],[-95.206741,32.559773],[-95.15341,32.570115],[-95.197103,32.731046],[-95.203139,32.731513],[-95.34595,32.609291]]]},"properties":{"id":"75765","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.67596,29.228602],[-99.676279,29.2288],[-99.682115,29.235033],[-99.692906,29.225469],[-99.67596,29.228602]]],[[[-99.810139,29.170333],[-99.815946,29.160548],[-99.822256,29.147609],[-99.779219,29.133892],[-99.810139,29.170333]]]]},"properties":{"id":"78802","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.101155,29.616961],[-100.69999,29.503788],[-100.699932,29.623897],[-100.700249,30.104739],[-100.999773,30.193829],[-101.115291,30.044083],[-101.101155,29.616961]]]},"properties":{"id":"78840","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.796753,29.336587],[-100.763638,29.345067],[-100.76518,29.379414],[-100.800521,29.366059],[-100.796753,29.336587]]]},"properties":{"id":"78843","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.763867,32.757904],[-95.829197,32.662388],[-95.828117,32.626345],[-95.748746,32.755272],[-95.763867,32.757904]]]},"properties":{"id":"75127","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.900176,28.846813],[-96.923442,28.834639],[-96.903389,28.845128],[-96.900176,28.846813]]],[[[-97.106827,29.010304],[-97.138111,28.897201],[-97.017765,28.828066],[-96.931045,28.818983],[-96.940038,28.827065],[-96.987136,29.020683],[-97.094734,29.019166],[-97.106827,29.010304]]]]},"properties":{"id":"77904","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.330296,30.828287],[-95.397247,30.793926],[-95.399306,30.746781],[-95.350967,30.746803],[-95.334407,30.782646],[-95.330296,30.828287]]]},"properties":{"id":"77334","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.281784,30.488188],[-97.202819,30.442759],[-97.155219,30.457344],[-97.264431,30.636587],[-97.292995,30.539916],[-97.281784,30.488188]]]},"properties":{"id":"76578","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.062381,29.084542],[-98.090461,29.063759],[-98.065125,29.065522],[-98.062381,29.084542]]]},"properties":{"id":"78147","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.449474,31.988952],[-95.601005,32.061483],[-95.722138,32.04588],[-95.759523,32.041034],[-95.764609,31.825823],[-95.867028,31.728634],[-95.789679,31.648776],[-95.497146,31.860214],[-95.477668,31.870317],[-95.437678,31.897983],[-95.449474,31.988952]]]},"properties":{"id":"75803","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.043264,28.069184],[-97.040648,28.073747],[-97.050091,28.076239],[-97.051569,28.070515],[-97.043264,28.069184]]]},"properties":{"id":"78358","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.953938,33.397534],[-98.861974,33.468041],[-98.791757,33.600974],[-98.953408,33.627058],[-98.953938,33.397534]]]},"properties":{"id":"76370","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.627618,34.88327],[-102.168641,34.79805],[-102.210767,34.688513],[-102.146944,34.639888],[-101.998358,34.588488],[-101.973244,34.646954],[-101.545073,34.747484],[-101.493912,34.747509],[-101.627618,34.88327]]]},"properties":{"id":"79042","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.710232,28.991337],[-98.620859,28.648352],[-98.512745,28.648208],[-98.467424,28.71498],[-98.500792,28.793739],[-98.555207,28.985277],[-98.710232,28.991337]]]},"properties":{"id":"78026","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.043617,34.00364],[-102.790841,33.873586],[-102.772375,33.873415],[-102.615478,33.972505],[-102.615394,34.125338],[-102.615435,34.212341],[-102.48854,34.391767],[-102.525767,34.426826],[-102.550266,34.383636],[-102.654716,34.399713],[-102.744483,34.422424],[-103.043557,34.274781],[-103.043617,34.00364]]]},"properties":{"id":"79347","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.674465,30.030523],[-97.562305,29.810132],[-97.5385,29.776808],[-97.454228,29.793672],[-97.462136,29.851313],[-97.514389,30.001325],[-97.568284,30.068118],[-97.646222,30.071034],[-97.655257,30.072907],[-97.674465,30.030523]]]},"properties":{"id":"78616","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.722331,28.55714],[-97.815887,28.527386],[-97.845252,28.526079],[-97.90203,28.547],[-97.984916,28.485552],[-98.038584,28.438868],[-97.907349,28.340588],[-97.853076,28.258682],[-97.460637,28.273895],[-97.408493,28.344114],[-97.375579,28.388684],[-97.478587,28.431527],[-97.705933,28.567465],[-97.722331,28.55714]]]},"properties":{"id":"78102","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.815887,28.527386],[-97.722331,28.55714],[-97.791872,28.552117],[-97.815887,28.527386]]]},"properties":{"id":"78104","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.351737,29.59184],[-98.384814,29.568369],[-98.39616,29.5209],[-98.394577,29.521868],[-98.35294,29.530446],[-98.32851,29.537378],[-98.333123,29.568118],[-98.344726,29.581588],[-98.351737,29.59184]]]},"properties":{"id":"78233","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.711148,29.435559],[-98.630089,29.453107],[-98.632285,29.482662],[-98.705364,29.49429],[-98.711148,29.435559]]]},"properties":{"id":"78251","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.587823,29.407249],[-98.599694,29.435024],[-98.638607,29.442567],[-98.657942,29.394541],[-98.646494,29.350447],[-98.635135,29.360789],[-98.626624,29.367031],[-98.602838,29.389664],[-98.587823,29.407249]]]},"properties":{"id":"78227","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.541736,29.381475],[-98.51257,29.380091],[-98.512104,29.38791],[-98.528832,29.398243],[-98.531057,29.396979],[-98.541736,29.381475]]]},"properties":{"id":"78225","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.561164,29.529641],[-98.549141,29.499824],[-98.53495,29.557268],[-98.561878,29.578875],[-98.585245,29.549086],[-98.561164,29.529641]]]},"properties":{"id":"78230","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.667531,29.553482],[-98.705364,29.49429],[-98.632285,29.482662],[-98.631242,29.511096],[-98.651838,29.544384],[-98.667268,29.553739],[-98.667531,29.553482]]]},"properties":{"id":"78250","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.531057,29.396979],[-98.548904,29.400019],[-98.587823,29.407249],[-98.602838,29.389664],[-98.601397,29.384834],[-98.585719,29.365568],[-98.571578,29.360618],[-98.541736,29.381475],[-98.531057,29.396979]]]},"properties":{"id":"78226","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.516836,29.370027],[-98.431976,29.274941],[-98.477814,29.374153],[-98.499007,29.387923],[-98.512104,29.38791],[-98.51257,29.380091],[-98.516836,29.370027]]]},"properties":{"id":"78214","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.29246,29.251235],[-98.406762,29.2437],[-98.408391,29.234158],[-98.431298,29.122507],[-98.381446,29.144832],[-98.29246,29.251235]]]},"properties":{"id":"78112","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.452139,29.341642],[-98.43777,29.341141],[-98.432906,29.350603],[-98.45301,29.35185],[-98.452139,29.341642]]]},"properties":{"id":"78235","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.267685,30.463753],[-98.320882,30.478013],[-98.440983,30.501862],[-98.59167,30.499875],[-98.590989,30.4593],[-98.206567,30.310812],[-98.130011,30.372683],[-98.267685,30.463753]]]},"properties":{"id":"78663","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.709676,31.804882],[-97.812165,31.738603],[-97.766431,31.672461],[-97.694657,31.66546],[-97.330334,31.74666],[-97.30167,31.757105],[-97.28206,31.843152],[-97.408547,31.908039],[-97.567019,31.943332],[-97.709676,31.804882]]]},"properties":{"id":"76634","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.277265,31.745492],[-97.30167,31.757105],[-97.330334,31.74666],[-97.344477,31.615965],[-97.294133,31.600807],[-97.276357,31.602963],[-97.266988,31.694119],[-97.277265,31.745492]]]},"properties":{"id":"76633","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.177206,31.932988],[-98.162264,31.850037],[-97.966132,31.771565],[-97.920188,31.759603],[-97.859166,31.815476],[-97.869036,31.844852],[-97.945975,32.122144],[-98.189343,32.079245],[-98.177206,31.932988]]]},"properties":{"id":"76457","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.104667,33.443438],[-94.043112,33.439806],[-94.233385,33.552205],[-94.203389,33.465564],[-94.130748,33.454233],[-94.104667,33.443438]]]},"properties":{"id":"75503","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.521089,29.089155],[-95.476715,29.027101],[-95.436146,29.002555],[-95.436137,29.004864],[-95.420629,29.070772],[-95.521089,29.089155]]]},"properties":{"id":"77566","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.369643,30.658141],[-96.346935,30.684444],[-96.417995,30.722369],[-96.381294,30.645632],[-96.369643,30.658141]]]},"properties":{"id":"77803","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.171406,34.312295],[-101.041344,34.177758],[-100.937938,34.3128],[-100.945794,34.421939],[-100.945576,34.491798],[-101.02388,34.525681],[-101.171406,34.312295]]]},"properties":{"id":"79255","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.86372,32.03678],[-98.835405,31.947009],[-98.74171,31.916855],[-98.86372,32.03678]]]},"properties":{"id":"76474","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.185978,31.376287],[-98.509981,31.106926],[-98.458341,31.005905],[-98.445782,30.921439],[-98.44862,30.910089],[-98.07846,30.903852],[-98.011854,30.923806],[-98.003866,30.967724],[-98.075389,31.294266],[-98.071762,31.307797],[-98.170774,31.450507],[-98.185978,31.376287]]]},"properties":{"id":"76550","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.700512,30.025953],[-97.979005,30.060619],[-98.010418,30.046379],[-97.940681,30.012717],[-97.849178,29.916],[-97.7622,29.932286],[-97.700512,30.025953]]]},"properties":{"id":"78640","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.618852,25.981919],[-97.569325,26.017735],[-97.533556,26.029373],[-97.467073,26.175815],[-97.599361,26.196548],[-97.673603,26.154566],[-97.786058,26.138025],[-97.824196,26.056119],[-97.618852,25.981919]],[[-97.75039,26.049086],[-97.74665,26.0548],[-97.72786,26.048294],[-97.74472,26.040773],[-97.75039,26.049086]]]},"properties":{"id":"78586","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.816993,33.070161],[-95.125769,33.088025],[-95.125949,33.053024],[-95.12613,33.034581],[-95.146789,32.902621],[-95.134596,32.86856],[-94.835605,32.861718],[-94.719942,32.904502],[-94.724788,32.910218],[-94.816993,33.070161]]]},"properties":{"id":"75686","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.623458,35.38747],[-101.586417,35.220661],[-101.578487,35.183069],[-101.328954,35.182009],[-101.20389,35.327311],[-101.310875,35.440129],[-101.399048,35.598331],[-101.408652,35.567493],[-101.623458,35.38747]]]},"properties":{"id":"79068","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.586417,35.220661],[-101.623458,35.38747],[-101.934985,35.620011],[-101.970252,35.620057],[-101.995239,35.500858],[-101.962551,35.484774],[-101.965868,35.344589],[-101.848859,35.250879],[-101.741875,35.203454],[-101.653695,35.207049],[-101.622993,35.206998],[-101.586417,35.220661]]]},"properties":{"id":"79108","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.707458,33.311943],[-94.71967,33.121094],[-94.620517,33.107988],[-94.551573,33.096804],[-94.538607,33.110653],[-94.467436,33.20104],[-94.409973,33.273348],[-94.415739,33.284017],[-94.623423,33.251807],[-94.707458,33.311943]]]},"properties":{"id":"75568","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.538607,33.110653],[-94.430767,33.14018],[-94.467436,33.20104],[-94.538607,33.110653]]]},"properties":{"id":"75566","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.816993,33.070161],[-94.724788,32.910218],[-94.664299,32.958677],[-94.620517,33.107988],[-94.71967,33.121094],[-94.816276,33.094074],[-94.816993,33.070161]]]},"properties":{"id":"75638","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.467909,29.73297],[-94.374014,29.692225],[-94.355561,29.735042],[-94.467909,29.73297]]]},"properties":{"id":"77661","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.486999,34.06288],[-98.558613,34.035307],[-98.597063,33.907084],[-98.565961,33.917769],[-98.550712,33.917176],[-98.519283,33.995546],[-98.493687,33.95998],[-98.49511,33.956012],[-98.469964,33.932895],[-98.424432,33.882732],[-98.347112,33.868765],[-98.22227,33.9591],[-98.295064,34.056471],[-98.278491,34.13086],[-98.486999,34.06288]]]},"properties":{"id":"76305","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.072322,33.780502],[-98.196932,33.482872],[-97.978804,33.727526],[-98.072322,33.780502]]]},"properties":{"id":"76228","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.608685,33.680132],[-102.727666,33.403546],[-102.726342,33.388275],[-102.611422,33.388472],[-102.603101,33.568671],[-102.608685,33.680132]]]},"properties":{"id":"79379","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.235288,32.057591],[-100.403046,32.013536],[-100.430332,31.743654],[-100.236094,31.726617],[-100.144128,31.698846],[-100.13622,31.856876],[-100.160667,31.919531],[-100.174707,31.937249],[-100.235288,32.057591]]]},"properties":{"id":"76933","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.235288,32.057591],[-100.150064,32.223641],[-100.224007,32.173038],[-100.323609,32.198493],[-100.512418,32.152814],[-100.484639,32.082932],[-100.403046,32.013536],[-100.235288,32.057591]]]},"properties":{"id":"79506","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.203049,31.599618],[-99.20195,31.835052],[-99.248412,31.897303],[-99.42487,31.623491],[-99.374035,31.561697],[-99.374928,31.439288],[-99.308231,31.415206],[-99.202768,31.467234],[-99.203049,31.599618]]]},"properties":{"id":"76878","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.667145,32.036201],[-99.759481,32.050054],[-99.760647,31.959609],[-99.70683,31.897696],[-99.667145,32.036201]]]},"properties":{"id":"79538","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.772039,33.071726],[-96.690077,33.065954],[-96.686229,33.075364],[-96.732663,33.125228],[-96.732835,33.125168],[-96.768021,33.112316],[-96.772039,33.071726]]]},"properties":{"id":"75025","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.843521,33.016492],[-96.854689,32.98775],[-96.838963,32.987521],[-96.829862,32.987402],[-96.813383,32.987136],[-96.815144,33.013329],[-96.843521,33.016492]]]},"properties":{"id":"75287","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.682679,32.973125],[-96.683007,32.931278],[-96.648319,32.931171],[-96.607231,32.968815],[-96.613377,32.984021],[-96.682679,32.973125]]]},"properties":{"id":"75044","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.502232,29.563636],[-96.620965,29.515928],[-96.603079,29.487626],[-96.502232,29.563636]]]},"properties":{"id":"77470","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.607328,29.693504],[-96.586856,29.719926],[-96.649188,29.717323],[-96.607328,29.693504]]]},"properties":{"id":"78943","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.26939,29.976175],[-98.151587,29.934318],[-98.291407,30.033614],[-98.312958,30.004166],[-98.26939,29.976175]]]},"properties":{"id":"78623","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.393881,31.867474],[-98.403888,31.730563],[-98.305607,31.765218],[-98.227852,31.805234],[-98.301699,31.84993],[-98.393881,31.867474]]]},"properties":{"id":"76455","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.738436,31.319237],[-99.649215,31.087],[-99.603223,31.087296],[-99.602078,31.30342],[-99.738436,31.319237]]]},"properties":{"id":"76858","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.112625,31.429528],[-99.794149,31.389004],[-99.677775,31.556364],[-99.721698,31.576759],[-99.829393,31.577275],[-100.111501,31.551682],[-100.112486,31.444325],[-100.112625,31.429528]]]},"properties":{"id":"76866","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.075207,31.652745],[-100.111501,31.551682],[-99.829393,31.577275],[-100.075207,31.652745]]]},"properties":{"id":"76875","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.261224,33.642358],[-97.298526,33.623487],[-97.298775,33.623392],[-97.283531,33.604526],[-97.217844,33.630609],[-97.261224,33.642358]]]},"properties":{"id":"76250","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.647003,33.58643],[-97.486138,33.569368],[-97.444879,33.824048],[-97.572349,33.899263],[-97.66321,33.674338],[-97.647003,33.58643]]]},"properties":{"id":"76265","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.994442,31.615248],[-98.058712,31.595051],[-97.997371,31.555782],[-97.994442,31.615248]]],[[[-98.096036,31.55244],[-98.332425,31.501569],[-98.290464,31.376208],[-98.185978,31.376287],[-98.170774,31.450507],[-97.938648,31.461894],[-98.096036,31.55244]]]]},"properties":{"id":"76525","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.236231,31.881195],[-104.91382,31.660707],[-104.734218,31.541125],[-104.980257,32.000538],[-105.236231,31.881195]]]},"properties":{"id":"79847","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.023751,36.50042],[-103.041149,36.144237],[-102.7877,36.20581],[-103.023751,36.50042]]]},"properties":{"id":"79087","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.858915,32.953158],[-96.937284,32.921025],[-96.931075,32.90943],[-96.928376,32.895688],[-96.85616,32.909652],[-96.855914,32.933293],[-96.858915,32.953158]]]},"properties":{"id":"75234","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.853551,32.859758],[-96.81799,32.822453],[-96.816246,32.826775],[-96.813168,32.851174],[-96.811515,32.865644],[-96.853551,32.859758]]]},"properties":{"id":"75209","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.63927,32.748607],[-96.637504,32.694308],[-96.58644,32.704186],[-96.59881,32.743946],[-96.63927,32.748607]]]},"properties":{"id":"75180","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.795785,32.77351],[-96.813671,32.79509],[-96.800502,32.769621],[-96.795785,32.77351]],[[-96.803049,32.780648],[-96.803271,32.781337],[-96.801487,32.781746],[-96.801192,32.781013],[-96.803049,32.780648]]]},"properties":{"id":"75202","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.59583,32.833246],[-96.592843,32.78994],[-96.57261,32.764955],[-96.519794,32.754534],[-96.519067,32.804647],[-96.51897,32.813616],[-96.518869,32.832728],[-96.59583,32.833246]]]},"properties":{"id":"75182","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.693019,32.674487],[-96.745437,32.618574],[-96.709967,32.621071],[-96.635916,32.610868],[-96.622198,32.617727],[-96.64546,32.636566],[-96.656934,32.637464],[-96.693019,32.674487]]]},"properties":{"id":"75141","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.856976,32.691232],[-96.857079,32.72533],[-96.881364,32.715818],[-96.891539,32.691459],[-96.857138,32.691234],[-96.856976,32.691232]]]},"properties":{"id":"75233","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.890595,32.830489],[-97.022064,32.837137],[-97.033781,32.837135],[-97.033794,32.836951],[-97.034085,32.81666],[-96.994569,32.815855],[-96.906129,32.814286],[-96.890595,32.830489]]]},"properties":{"id":"75061","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.855914,32.933293],[-96.85616,32.909652],[-96.814644,32.909585],[-96.818827,32.921478],[-96.82552,32.939518],[-96.828281,32.94722],[-96.855914,32.933293]]]},"properties":{"id":"75244","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.823122,32.642086],[-96.821303,32.686538],[-96.822896,32.687043],[-96.856976,32.691232],[-96.857138,32.691234],[-96.857176,32.636467],[-96.823105,32.633232],[-96.823122,32.642086]]]},"properties":{"id":"75232","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.803049,32.780648],[-96.801192,32.781013],[-96.801487,32.781746],[-96.803271,32.781337],[-96.803049,32.780648]]]},"properties":{"id":"75270","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.129382,32.913671],[-97.099803,32.881352],[-97.06111,32.837835],[-97.033794,32.836951],[-97.033781,32.837135],[-97.032937,32.904296],[-97.032587,32.955008],[-97.032387,32.984623],[-97.040425,32.984533],[-97.08723,32.989761],[-97.129382,32.913671]]]},"properties":{"id":"76051","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.606343,35.452164],[-102.547401,35.101476],[-102.328937,35.151263],[-102.349198,35.324396],[-102.606343,35.452164]]]},"properties":{"id":"79092","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.857738,33.461722],[-95.859221,33.363708],[-95.836887,33.362048],[-95.748324,33.434585],[-95.793064,33.482218],[-95.810031,33.471576],[-95.857738,33.461722]]]},"properties":{"id":"75469","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.859793,33.348596],[-95.828072,33.345477],[-95.836887,33.362048],[-95.859221,33.363708],[-95.859793,33.348596]]],[[[-95.859956,33.341859],[-95.950359,33.326298],[-96.032118,33.243131],[-95.977795,33.209984],[-95.861778,33.21933],[-95.796727,33.253334],[-95.760046,33.287258],[-95.859956,33.341859]]]]},"properties":{"id":"75428","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.250588,32.963515],[-97.203106,32.942473],[-97.151221,33.017725],[-97.182845,33.050891],[-97.229576,33.090947],[-97.260412,33.040956],[-97.298363,32.963177],[-97.250588,32.963515]]]},"properties":{"id":"76262","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.092386,33.281836],[-97.019775,33.188336],[-96.89805,33.21952],[-96.8646,33.27718],[-96.89225,33.288395],[-97.036074,33.357867],[-97.104088,33.282588],[-97.092386,33.281836]]]},"properties":{"id":"76227","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.847554,29.12968],[-97.061815,29.334841],[-97.08907,29.357975],[-97.065944,29.376124],[-97.065832,29.411409],[-97.289155,29.34871],[-97.314204,29.29383],[-97.094734,29.019166],[-96.987136,29.020683],[-96.938587,29.063244],[-96.847554,29.12968]]]},"properties":{"id":"77995","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.575057,28.813006],[-97.604998,28.980678],[-97.720068,28.969507],[-97.575057,28.813006]]]},"properties":{"id":"78141","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.821283,35.183242],[-100.721403,35.033504],[-100.575857,35.053818],[-100.539049,35.145407],[-100.485443,35.182959],[-100.485166,35.21207],[-100.538802,35.388332],[-100.538889,35.404114],[-100.61065,35.403492],[-100.773924,35.406212],[-100.815638,35.3332],[-100.821283,35.183242]]]},"properties":{"id":"79057","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.929926,29.831913],[-100.102967,29.76313],[-100.306415,29.623608],[-100.112098,29.623263],[-99.890935,29.624282],[-99.846068,29.77108],[-99.929926,29.831913]]]},"properties":{"id":"78833","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.717535,32.376087],[-96.75753,32.481395],[-96.824629,32.494203],[-96.867271,32.288147],[-96.808843,32.239236],[-96.778852,32.247836],[-96.717535,32.376087]]]},"properties":{"id":"75165","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.076301,32.109648],[-96.978855,32.087311],[-96.896209,32.073977],[-96.874044,32.084917],[-97.000715,32.211871],[-97.048756,32.210641],[-97.076301,32.109648]]]},"properties":{"id":"76670","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.563095,31.976734],[-106.618486,32.000495],[-106.614375,31.955991],[-106.563095,31.976734]]]},"properties":{"id":"79821","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.582678,31.908361],[-106.620071,31.916634],[-106.605746,31.846239],[-106.570487,31.839368],[-106.582678,31.908361]]]},"properties":{"id":"79932","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.127145,31.476721],[-106.142793,31.430126],[-105.997152,31.38723],[-105.972273,31.408312],[-106.127145,31.476721]]]},"properties":{"id":"79853","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.295672,31.601037],[-106.213225,31.473205],[-106.20825,31.538127],[-106.2592,31.609476],[-106.295672,31.601037]]]},"properties":{"id":"79849","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.421654,31.826249],[-106.421464,31.808306],[-106.404508,31.819826],[-106.421654,31.826249]]]},"properties":{"id":"79916","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.126024,32.441975],[-97.91777,32.181282],[-97.868907,32.272506],[-98.033972,32.432651],[-98.126024,32.441975]]]},"properties":{"id":"76433","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.982022,31.031201],[-96.773712,31.01634],[-96.765334,31.045141],[-96.829121,31.105871],[-96.929432,31.216669],[-97.129281,31.070328],[-97.111556,31.045265],[-96.982022,31.031201]]]},"properties":{"id":"76570","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.829121,31.105871],[-96.751092,31.178097],[-96.922147,31.251419],[-96.929432,31.216669],[-96.829121,31.105871]]]},"properties":{"id":"76680","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.413251,31.310821],[-96.734637,31.385605],[-96.714568,31.245622],[-96.474982,31.195421],[-96.413251,31.310821]]]},"properties":{"id":"76653","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.751092,31.178097],[-96.829121,31.105871],[-96.765334,31.045141],[-96.609409,31.079483],[-96.474982,31.195421],[-96.714568,31.245622],[-96.751092,31.178097]]]},"properties":{"id":"76629","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.265881,31.336416],[-97.278113,31.279799],[-97.167205,31.18609],[-97.121489,31.254593],[-97.14919,31.315445],[-97.161411,31.324988],[-97.265881,31.336416]]]},"properties":{"id":"76524","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.038097,31.391129],[-96.99558,31.309177],[-97.015067,31.387348],[-97.037397,31.391434],[-97.038097,31.391129]]]},"properties":{"id":"76685","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.969757,33.717019],[-95.854479,33.823731],[-95.846536,33.839855],[-96.049408,33.836727],[-96.083149,33.67473],[-96.086013,33.647986],[-95.993386,33.67763],[-95.969757,33.717019]]]},"properties":{"id":"75488","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.020961,29.850986],[-97.103313,29.923621],[-97.194235,29.896776],[-97.054572,29.734827],[-96.98371,29.777247],[-97.020961,29.850986]]]},"properties":{"id":"78949","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.309713,32.667879],[-100.309708,32.736853],[-100.246847,32.836498],[-100.294345,32.866065],[-100.543778,32.738712],[-100.533866,32.674051],[-100.309713,32.667879]]]},"properties":{"id":"79543","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.564856,34.312493],[-101.564464,34.021314],[-101.327432,34.186142],[-101.258248,34.259127],[-101.239863,34.157661],[-101.041424,34.14686],[-101.041344,34.177758],[-101.171406,34.312295],[-101.47158,34.31229],[-101.513813,34.312256],[-101.564856,34.312493]]]},"properties":{"id":"79241","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.040123,33.924668],[-100.048094,33.93872],[-100.048134,33.922437],[-100.040123,33.924668]]],[[[-100.040123,33.924668],[-99.996217,33.824469],[-99.995442,33.765233],[-99.935024,33.651547],[-99.648727,33.638416],[-99.47565,33.834005],[-99.475256,33.913989],[-99.504396,34.076287],[-99.608878,34.083091],[-99.777548,34.096518],[-100.047428,34.132815],[-100.048093,33.938967],[-100.040123,33.924668]]]]},"properties":{"id":"79227","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.505431,29.565054],[-95.509131,29.513209],[-95.433512,29.544819],[-95.442458,29.554424],[-95.504924,29.567618],[-95.505431,29.565054]]]},"properties":{"id":"77545","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.597837,29.660596],[-95.58241,29.606242],[-95.541614,29.585491],[-95.534012,29.639819],[-95.536895,29.642253],[-95.571273,29.648707],[-95.597837,29.660596]]]},"properties":{"id":"77477","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.016068,29.43689],[-95.989921,29.429664],[-95.993309,29.450674],[-96.016068,29.43689]]]},"properties":{"id":"77451","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.017337,31.616382],[-95.882436,31.348963],[-95.693768,31.387123],[-95.739279,31.504056],[-95.723079,31.63726],[-95.789679,31.648776],[-95.867028,31.728634],[-95.959317,31.782072],[-96.017337,31.616382]]]},"properties":{"id":"75855","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.252754,31.746031],[-96.120491,31.980805],[-96.199298,31.974034],[-96.333727,31.943536],[-96.339927,31.873862],[-96.332269,31.771854],[-96.330645,31.766448],[-96.332297,31.762253],[-96.332444,31.74078],[-96.252754,31.746031]]]},"properties":{"id":"75859","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.37421,31.716645],[-96.337684,31.563247],[-96.312847,31.526669],[-96.099737,31.500665],[-96.123424,31.649359],[-96.252754,31.746031],[-96.332444,31.74078],[-96.37421,31.716645]]]},"properties":{"id":"75860","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.208056,29.230721],[-99.233818,29.090938],[-99.138787,29.008589],[-99.064119,29.110817],[-99.08582,29.202532],[-99.208056,29.230721]]]},"properties":{"id":"78886","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.40502,32.958716],[-102.468062,32.875388],[-102.450834,32.842424],[-102.266679,32.851979],[-102.207563,32.895725],[-102.208373,32.94407],[-102.31303,32.958858],[-102.40502,32.958716]]]},"properties":{"id":"79342","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.891747,29.356624],[-94.930844,29.337525],[-94.907075,29.308666],[-94.891747,29.356624]]],[[[-94.844819,29.25589],[-94.820158,29.297767],[-94.810749,29.303653],[-94.885745,29.29757],[-95.091167,29.103986],[-94.844819,29.25589]]]]},"properties":{"id":"77554","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.011494,29.526174],[-94.995117,29.498785],[-94.958845,29.506472],[-95.001689,29.525334],[-95.011494,29.526174]]]},"properties":{"id":"77518","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.820158,29.297767],[-94.809718,29.274707],[-94.810749,29.303653],[-94.820158,29.297767]]]},"properties":{"id":"77550","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.045077,29.555614],[-95.011494,29.526174],[-95.001689,29.525334],[-95.018205,29.554597],[-95.045077,29.555614]]]},"properties":{"id":"77565","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.496111,30.288359],[-99.229754,30.134897],[-99.201787,30.181006],[-99.130532,30.365546],[-99.303996,30.499832],[-99.445892,30.499611],[-99.496111,30.288359]]]},"properties":{"id":"78631","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.265489,30.134585],[-99.310282,30.017059],[-99.346808,29.943393],[-99.179084,29.895259],[-99.03162,30.013687],[-99.016616,30.13729],[-99.201787,30.181006],[-99.229754,30.134897],[-99.265489,30.134585]]]},"properties":{"id":"78028","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.653782,28.756962],[-97.77853,28.668027],[-97.753645,28.643857],[-97.478587,28.431527],[-97.375579,28.388684],[-97.160751,28.553475],[-97.182359,28.610602],[-97.236692,28.696852],[-97.236241,28.864889],[-97.355404,28.897621],[-97.36786,28.865336],[-97.3822,28.836562],[-97.454746,28.847577],[-97.575057,28.813006],[-97.653782,28.756962]]]},"properties":{"id":"77963","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.556513,29.152313],[-97.450564,29.230813],[-97.527167,29.341809],[-97.653193,29.339211],[-97.653957,29.340034],[-97.728443,29.221748],[-97.613132,29.109765],[-97.556513,29.152313]]]},"properties":{"id":"78159","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.819936,29.403153],[-97.674106,29.418557],[-97.652545,29.417058],[-97.747513,29.4989],[-97.819936,29.403153]]]},"properties":{"id":"78122","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.496661,33.52423],[-96.510066,33.516715],[-96.472,33.511674],[-96.480432,33.528063],[-96.496661,33.52423]]]},"properties":{"id":"75489","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.668196,32.503806],[-94.756736,32.479672],[-94.603982,32.399435],[-94.609261,32.510897],[-94.668196,32.503806]]]},"properties":{"id":"75602","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.18176,30.607611],[-96.144808,30.510739],[-95.825783,30.44237],[-95.813355,30.448462],[-95.81684,30.492638],[-95.944074,30.584537],[-96.027946,30.66139],[-96.18176,30.607611]]]},"properties":{"id":"77830","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.049155,29.620409],[-98.057784,29.600011],[-98.070682,29.588194],[-98.023767,29.597679],[-98.049155,29.620409]]]},"properties":{"id":"78123","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.739639,29.509381],[-97.943257,29.737389],[-97.999271,29.752444],[-98.049155,29.620409],[-98.023767,29.597679],[-98.070682,29.588194],[-98.121075,29.449048],[-98.001958,29.378877],[-97.88688,29.377237],[-97.819936,29.403153],[-97.747513,29.4989],[-97.739639,29.509381]]]},"properties":{"id":"78155","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.41595,34.517205],[-100.437493,34.571655],[-100.503953,34.587486],[-100.652568,34.514255],[-100.60352,34.497016],[-100.528518,34.474497],[-100.416567,34.448342],[-100.41595,34.517205]]]},"properties":{"id":"79233","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.504396,34.076287],[-99.426613,34.312981],[-99.429073,34.345391],[-99.409409,34.372977],[-99.586584,34.386943],[-99.608878,34.083091],[-99.504396,34.076287]]]},"properties":{"id":"79225","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.645269,30.490519],[-94.722093,30.490256],[-94.691601,30.376515],[-94.645269,30.490519]]]},"properties":{"id":"77376","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.382415,30.040362],[-95.310994,30.032887],[-95.436597,30.110418],[-95.436873,30.110175],[-95.436545,30.08406],[-95.429398,30.03563],[-95.382415,30.040362]]]},"properties":{"id":"77373","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.093868,29.864805],[-95.078824,29.824866],[-95.060823,29.792512],[-95.049441,29.791077],[-95.002802,29.842386],[-95.093868,29.864805]]]},"properties":{"id":"77562","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.132047,30.021156],[-95.130156,29.991986],[-95.035692,30.005064],[-95.072603,30.103054],[-95.086027,30.139272],[-95.155006,30.082097],[-95.132047,30.021156]]]},"properties":{"id":"77336","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.351691,29.812858],[-95.35128,29.773606],[-95.292022,29.797833],[-95.329394,29.819884],[-95.335349,29.819891],[-95.352373,29.819732],[-95.351691,29.812858]]]},"properties":{"id":"77026","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.387782,29.787622],[-95.439612,29.781512],[-95.455722,29.759957],[-95.433809,29.757672],[-95.373205,29.76116],[-95.366164,29.767392],[-95.362277,29.765225],[-95.366719,29.77393],[-95.387782,29.787622]]]},"properties":{"id":"77007","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.36653,29.876477],[-95.408133,29.869872],[-95.403014,29.846522],[-95.360334,29.851757],[-95.36653,29.876477]]]},"properties":{"id":"77076","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.38212,29.713013],[-95.390258,29.724958],[-95.423018,29.706129],[-95.414363,29.694449],[-95.386583,29.700145],[-95.38212,29.713013]]]},"properties":{"id":"77030","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.47642,29.725724],[-95.479711,29.773378],[-95.500857,29.760378],[-95.501044,29.722678],[-95.491836,29.72343],[-95.47642,29.725724]]]},"properties":{"id":"77057","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.190143,30.083978],[-95.270786,30.043177],[-95.26377,30.032434],[-95.213933,30.024088],[-95.176531,30.027324],[-95.190143,30.083978]]]},"properties":{"id":"77339","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.617232,29.790612],[-95.56517,29.784433],[-95.543969,29.78431],[-95.544668,29.794282],[-95.545321,29.832239],[-95.617275,29.835998],[-95.617232,29.790612]]]},"properties":{"id":"77043","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.463734,29.647365],[-95.464938,29.609437],[-95.419592,29.613628],[-95.38728,29.632212],[-95.396328,29.670542],[-95.43084,29.660462],[-95.431041,29.660414],[-95.441728,29.655958],[-95.463734,29.647365]]]},"properties":{"id":"77045","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.499288,29.716841],[-95.559727,29.668053],[-95.559121,29.663425],[-95.529793,29.67336],[-95.508641,29.676172],[-95.492658,29.689774],[-95.499288,29.716841]]]},"properties":{"id":"77074","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.508641,29.676172],[-95.508673,29.656011],[-95.446686,29.673226],[-95.447054,29.689957],[-95.484455,29.69103],[-95.492658,29.689774],[-95.508641,29.676172]]]},"properties":{"id":"77096","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.520145,32.624195],[-94.631623,32.638704],[-94.637602,32.638971],[-94.609261,32.510897],[-94.603982,32.399435],[-94.591346,32.392402],[-94.579201,32.394925],[-94.458248,32.370243],[-94.443485,32.39415],[-94.520145,32.624195]]]},"properties":{"id":"75650","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.590265,32.776471],[-94.747948,32.740076],[-94.745629,32.667507],[-94.637602,32.638971],[-94.631623,32.638704],[-94.576132,32.728879],[-94.590265,32.776471]]]},"properties":{"id":"75640","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.955983,30.118228],[-97.806843,30.112482],[-97.797133,30.136926],[-97.846688,30.141121],[-97.908562,30.167742],[-97.955983,30.118228]]]},"properties":{"id":"78652","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.197822,26.235991],[-98.204311,26.19706],[-98.213417,26.143567],[-98.204959,26.066418],[-98.170214,26.066315],[-98.164966,26.082203],[-98.159155,26.241899],[-98.175154,26.247623],[-98.195405,26.250407],[-98.197822,26.235991]]]},"properties":{"id":"78577","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.421902,26.287693],[-98.275612,26.223062],[-98.273194,26.237432],[-98.304369,26.344588],[-98.428729,26.378511],[-98.430428,26.332972],[-98.421902,26.287693]]]},"properties":{"id":"78574","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.037803,26.297208],[-98.012257,26.293095],[-98.009028,26.278611],[-97.976832,26.279148],[-98.021519,26.314447],[-98.037803,26.297208]]]},"properties":{"id":"78543","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.213417,26.143567],[-98.204311,26.19706],[-98.263695,26.19461],[-98.258124,26.127715],[-98.213417,26.143567]]]},"properties":{"id":"78503","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.996514,26.49191],[-98.061637,26.427451],[-97.972429,26.444233],[-97.996514,26.49191]]]},"properties":{"id":"78549","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.44571,33.949534],[-102.576091,33.8728],[-102.576416,33.84942],[-102.59571,33.825097],[-102.38317,33.736431],[-102.309213,33.738993],[-102.244512,33.761554],[-102.183642,33.911547],[-102.119364,33.997254],[-102.118922,34.02705],[-102.144627,34.064938],[-102.297649,34.011032],[-102.436338,33.964866],[-102.44571,33.949534]]]},"properties":{"id":"79339","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.183642,33.911547],[-102.244512,33.761554],[-102.196978,33.689967],[-102.174419,33.689753],[-102.085733,33.824675],[-102.085912,33.850701],[-102.086791,33.924961],[-102.110237,33.939498],[-102.183642,33.911547]]]},"properties":{"id":"79313","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.898816,33.578752],[-102.061672,33.606636],[-102.174873,33.653171],[-102.181772,33.560438],[-102.182741,33.499638],[-102.078989,33.519429],[-101.991705,33.521102],[-101.93937,33.553089],[-101.922457,33.563237],[-101.900462,33.57771],[-101.898816,33.578752]]]},"properties":{"id":"79407","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.38837,32.941567],[-95.337909,33.082546],[-95.401513,33.134031],[-95.421479,33.091185],[-95.38837,32.941567]]]},"properties":{"id":"75471","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.732642,33.126026],[-95.770141,32.98838],[-95.768809,32.979937],[-95.683141,32.979629],[-95.732642,33.126026]]]},"properties":{"id":"75420","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.9866,32.873064],[-95.878486,32.78777],[-95.799059,32.773815],[-95.768809,32.979937],[-95.770141,32.98838],[-95.858689,32.979534],[-95.970436,32.907908],[-95.9866,32.873064]]]},"properties":{"id":"75472","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.322976,31.188148],[-95.648538,31.070005],[-95.616943,30.931083],[-95.306442,31.06582],[-95.286013,31.160562],[-95.322976,31.188148]]]},"properties":{"id":"75851","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.861778,33.21933],[-95.977795,33.209984],[-95.998931,33.12966],[-95.994217,33.058791],[-95.862914,33.088727],[-95.861778,33.21933]]]},"properties":{"id":"75422","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.26549,32.660535],[-96.187079,32.84039],[-96.227911,32.887542],[-96.297322,32.841723],[-96.380833,32.813744],[-96.403961,32.670149],[-96.382637,32.619685],[-96.26549,32.660535]]]},"properties":{"id":"75160","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.422423,33.22299],[-98.425741,33.066165],[-98.352166,33.243133],[-98.422423,33.22299]]]},"properties":{"id":"76427","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.395284,28.914837],[-96.41955,28.772254],[-96.315301,28.829111],[-96.310686,28.922589],[-96.383304,28.922588],[-96.395284,28.914837]]]},"properties":{"id":"77961","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.41955,28.772254],[-96.395284,28.914837],[-96.470267,28.890956],[-96.45668,28.756234],[-96.453296,28.756286],[-96.41955,28.772254]]]},"properties":{"id":"77970","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.969387,29.961413],[-93.994251,29.945977],[-93.855977,29.968725],[-93.856354,29.969921],[-93.871738,29.98098],[-93.896146,29.984619],[-93.918379,29.963188],[-93.890604,29.942832],[-93.917666,29.924751],[-93.944758,29.953104],[-93.969387,29.961413]]]},"properties":{"id":"77642","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.145185,30.117224],[-94.180363,30.133241],[-94.211402,30.075557],[-94.136017,30.085506],[-94.136313,30.092563],[-94.145185,30.117224]]]},"properties":{"id":"77706","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.357403,29.890085],[-94.261994,29.807095],[-94.338767,29.922995],[-94.357403,29.890085]]]},"properties":{"id":"77622","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.901453,29.040286],[-98.061505,28.879314],[-97.904646,28.942136],[-97.874937,28.972102],[-97.868446,29.031389],[-97.901453,29.040286]]]},"properties":{"id":"78117","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.632326,33.399057],[-100.859554,33.39813],[-100.547496,33.348212],[-100.632326,33.399057]]]},"properties":{"id":"79518","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.48424,30.599988],[-99.66771,30.710609],[-100.018558,30.710453],[-100.116234,30.710366],[-100.116306,30.571833],[-100.015097,30.519365],[-100.116696,30.372917],[-100.116461,30.290296],[-99.754142,30.290698],[-99.496111,30.288359],[-99.445892,30.499611],[-99.48424,30.599988]]]},"properties":{"id":"76849","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.253864,27.696737],[-97.262391,27.684059],[-97.27761,27.700275],[-97.306917,27.709513],[-97.308187,27.709883],[-97.319666,27.665513],[-97.343264,27.633329],[-97.161735,27.674065],[-97.188668,27.694672],[-97.253864,27.696737]]]},"properties":{"id":"78418","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.788216,33.486226],[-95.644218,33.486105],[-95.65978,33.587902],[-95.771808,33.607674],[-95.815875,33.52706],[-95.810031,33.471576],[-95.793064,33.482218],[-95.788216,33.486226]]]},"properties":{"id":"75477","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.927455,30.383879],[-97.060506,30.306138],[-97.024923,30.231757],[-96.858067,30.326767],[-96.927455,30.383879]]]},"properties":{"id":"78948","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.022932,31.204675],[-95.784059,31.094023],[-95.76441,31.094211],[-95.676377,31.36883],[-95.693768,31.387123],[-95.882436,31.348963],[-96.028149,31.287547],[-96.022932,31.204675]]]},"properties":{"id":"75833","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.243085,30.22994],[-95.097823,30.170616],[-95.118061,30.294361],[-95.260726,30.254581],[-95.243085,30.22994]]]},"properties":{"id":"77372","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.576072,31.803046],[-96.545089,31.727368],[-96.5323,31.755695],[-96.554122,31.80122],[-96.564126,31.802034],[-96.576072,31.803046]]]},"properties":{"id":"76686","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.784978,28.170721],[-97.853076,28.258682],[-97.907349,28.340588],[-97.948718,28.198933],[-97.798517,27.995659],[-97.662385,27.875307],[-97.63139,27.891706],[-97.67716,27.951143],[-97.686275,27.974934],[-97.705386,28.02983],[-97.677774,28.150444],[-97.784978,28.170721]]]},"properties":{"id":"78368","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.964304,30.586599],[-98.875226,30.580738],[-98.886371,30.704207],[-98.964016,30.705236],[-98.964304,30.586599]]]},"properties":{"id":"76831","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.610887,31.651802],[-103.582964,31.896087],[-103.732863,31.881591],[-103.62639,31.668649],[-103.610887,31.651802]]]},"properties":{"id":"79754","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.93937,33.553089],[-101.909823,33.531802],[-101.90514,33.562291],[-101.922457,33.563237],[-101.93937,33.553089]]]},"properties":{"id":"79414","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.991705,33.521102],[-101.964024,33.390825],[-101.877001,33.39167],[-101.907037,33.531046],[-101.909823,33.531802],[-101.93937,33.553089],[-101.991705,33.521102]]]},"properties":{"id":"79424","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.025591,31.724321],[-97.128277,31.710756],[-97.029804,31.659006],[-96.996362,31.725243],[-97.005519,31.729729],[-97.025591,31.724321]]]},"properties":{"id":"76640","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.176119,31.503632],[-97.151315,31.49245],[-97.148264,31.537302],[-97.176119,31.503632]]]},"properties":{"id":"76711","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.440597,28.445592],[-98.334526,28.529811],[-98.388966,28.524598],[-98.440597,28.445592]]]},"properties":{"id":"78007","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.953363,30.921312],[-98.92991,30.921139],[-98.934801,30.936226],[-98.953363,30.921312]]],[[[-99.016615,31.050004],[-98.938047,30.963288],[-98.960683,31.043853],[-99.016615,31.050004]]],[[[-98.964612,30.921368],[-99.062934,30.885682],[-98.963864,30.795351],[-98.964612,30.921368]]]]},"properties":{"id":"76869","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.38447,30.293612],[-95.45681,30.265385],[-95.457585,30.244957],[-95.375509,30.164093],[-95.345326,30.145252],[-95.311081,30.15974],[-95.272326,30.203057],[-95.38447,30.293612]]]},"properties":{"id":"77302","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.397798,30.325086],[-95.38447,30.293612],[-95.272326,30.203057],[-95.243085,30.22994],[-95.260726,30.254581],[-95.305665,30.344315],[-95.397798,30.325086]]]},"properties":{"id":"77306","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.583173,30.351645],[-95.493642,30.267896],[-95.457585,30.244957],[-95.45681,30.265385],[-95.476842,30.333958],[-95.486692,30.391247],[-95.570066,30.36386],[-95.583173,30.351645]]]},"properties":{"id":"77304","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.681192,31.843943],[-94.657696,31.618499],[-94.650397,31.616945],[-94.652386,31.622942],[-94.642504,31.623125],[-94.641582,31.617643],[-94.541626,31.71098],[-94.629395,31.844044],[-94.681192,31.843943]]]},"properties":{"id":"75965","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.726231,31.954839],[-96.564126,31.802034],[-96.554122,31.80122],[-96.507597,31.797325],[-96.525752,31.944788],[-96.604098,32.003172],[-96.718451,32.00674],[-96.726231,31.954839]]]},"properties":{"id":"76679","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.74362,30.974951],[-93.550742,30.921233],[-93.600308,31.176158],[-93.662078,31.172607],[-93.74362,30.974951]]]},"properties":{"id":"75932","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.975094,31.346092],[-93.911126,31.158071],[-93.662078,31.172607],[-93.600308,31.176158],[-93.744284,31.516918],[-93.855763,31.410693],[-93.975094,31.346092]]]},"properties":{"id":"75948","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.662385,27.875307],[-97.570243,27.783403],[-97.547455,27.787462],[-97.560418,27.848449],[-97.558199,27.860182],[-97.63139,27.891706],[-97.662385,27.875307]]]},"properties":{"id":"78410","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.404442,27.712303],[-97.421692,27.652085],[-97.375878,27.698266],[-97.404442,27.712303]]]},"properties":{"id":"78413","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.095291,27.874284],[-97.085009,27.864561],[-97.082886,27.866212],[-97.095291,27.874284]]]},"properties":{"id":"78335","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.755673,27.661335],[-97.773343,27.681493],[-97.778213,27.667608],[-97.755673,27.661335]]]},"properties":{"id":"78351","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.933703,27.804073],[-97.939125,27.685737],[-97.875024,27.711747],[-97.933703,27.804073]]]},"properties":{"id":"78330","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.744544,30.019281],[-93.871738,29.98098],[-93.856354,29.969921],[-93.744544,30.019281]]]},"properties":{"id":"77611","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.690283,32.833752],[-98.760327,32.60986],[-98.614471,32.671329],[-98.527871,32.858508],[-98.531326,32.883992],[-98.690283,32.833752]]]},"properties":{"id":"76429","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.166107,31.893473],[-93.943634,31.844638],[-93.87897,31.844767],[-94.041849,31.997335],[-94.131552,32.002508],[-94.166107,31.893473]]]},"properties":{"id":"75954","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.744176,32.871061],[-97.594521,32.908567],[-97.603261,32.979636],[-97.687486,33.017147],[-97.804057,33.073558],[-97.827156,33.066504],[-97.789165,32.923944],[-97.744176,32.871061]]]},"properties":{"id":"76082","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.909497,31.335993],[-102.496072,31.018508],[-102.370682,31.018222],[-102.767365,31.293803],[-102.909497,31.335993]]]},"properties":{"id":"79743","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.812898,35.193058],[-101.814229,35.210582],[-101.859523,35.208471],[-101.884025,35.191532],[-101.831886,35.192456],[-101.812898,35.193058]]]},"properties":{"id":"79102","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.884025,35.191532],[-101.920303,35.179755],[-101.920165,35.162473],[-101.901831,35.129209],[-101.835609,35.18286],[-101.831886,35.192456],[-101.884025,35.191532]]]},"properties":{"id":"79109","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.549344,32.811826],[-95.649199,32.897371],[-95.713514,32.743865],[-95.615599,32.707879],[-95.534922,32.768895],[-95.549344,32.811826]]]},"properties":{"id":"75410","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.154973,33.515655],[-95.285431,33.507393],[-95.309285,33.495112],[-95.308637,33.380662],[-95.30664,33.378027],[-95.280546,33.376966],[-94.977108,33.389817],[-94.904571,33.38937],[-94.935995,33.451827],[-95.154973,33.515655]]]},"properties":{"id":"75417","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.391568,32.927684],[-96.491817,32.888833],[-96.51855,32.867156],[-96.518869,32.832728],[-96.51897,32.813616],[-96.487187,32.813262],[-96.380833,32.813744],[-96.297322,32.841723],[-96.391568,32.927684]]]},"properties":{"id":"75032","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.957585,32.259318],[-94.949233,32.248985],[-94.923042,32.236406],[-94.925998,32.253618],[-94.94503,32.248268],[-94.957585,32.259318]]]},"properties":{"id":"75682","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.909889,31.451169],[-94.151608,31.367024],[-94.062581,31.114955],[-94.008602,31.186607],[-93.975094,31.346092],[-93.855763,31.410693],[-93.909889,31.451169]]]},"properties":{"id":"75930","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.162867,36.430927],[-102.163015,36.055249],[-102.02844,36.055155],[-101.989368,36.055395],[-101.846689,36.096479],[-101.794822,36.197525],[-101.801768,36.328307],[-101.895008,36.500352],[-102.162463,36.500326],[-102.162867,36.430927]]]},"properties":{"id":"79084","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.261277,32.275038],[-95.16995,32.218489],[-95.125003,32.232062],[-95.111383,32.355429],[-95.172184,32.361103],[-95.213546,32.353758],[-95.257949,32.344566],[-95.267221,32.341802],[-95.273881,32.310964],[-95.261277,32.275038]]]},"properties":{"id":"75707","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.743724,32.316936],[-97.705431,32.260502],[-97.727921,32.317124],[-97.743724,32.316936]]]},"properties":{"id":"76077","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.606486,26.783929],[-98.85127,26.785335],[-98.953028,26.390022],[-98.657471,26.301247],[-98.63843,26.266754],[-98.583199,26.26441],[-98.582701,26.265383],[-98.542558,26.344041],[-98.516227,26.397042],[-98.483837,26.783741],[-98.606486,26.783929]]]},"properties":{"id":"78582","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.677425,32.955937],[-98.741417,33.056789],[-98.879463,32.990105],[-98.677425,32.955937]]]},"properties":{"id":"76481","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.99085,33.335336],[-100.44097,33.343216],[-100.517484,33.171804],[-100.517553,33.107391],[-100.279984,32.961129],[-100.118907,32.959834],[-99.989301,33.039074],[-99.989656,33.107849],[-100.010252,33.268115],[-99.99085,33.335336]]]},"properties":{"id":"79502","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.114215,32.682521],[-97.062762,32.677202],[-97.062961,32.70603],[-97.112095,32.706066],[-97.114215,32.682521]]]},"properties":{"id":"76014","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.063594,32.643399],[-97.115545,32.644714],[-97.117533,32.610247],[-97.078174,32.609085],[-97.063594,32.643399]]]},"properties":{"id":"76002","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.114188,32.775677],[-97.14011,32.788387],[-97.179713,32.731139],[-97.114573,32.73756],[-97.114188,32.775677]]]},"properties":{"id":"76012","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.149196,32.706792],[-97.149495,32.674394],[-97.115085,32.678252],[-97.114215,32.682521],[-97.112095,32.706066],[-97.114648,32.706187],[-97.149196,32.706792]]]},"properties":{"id":"76015","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.186095,32.861959],[-97.18626,32.839267],[-97.166186,32.839555],[-97.159453,32.840031],[-97.167792,32.860532],[-97.18596,32.889243],[-97.186095,32.861959]]]},"properties":{"id":"76054","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.207474,32.822826],[-97.18626,32.839267],[-97.186095,32.861959],[-97.237996,32.860414],[-97.260407,32.839658],[-97.248965,32.807823],[-97.207474,32.822826]]]},"properties":{"id":"76180","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.290546,32.80977],[-97.27053,32.766174],[-97.255188,32.775029],[-97.238194,32.777901],[-97.248965,32.807823],[-97.260407,32.839658],[-97.269461,32.839399],[-97.290546,32.80977]]]},"properties":{"id":"76117","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.314478,32.809976],[-97.320401,32.781256],[-97.307087,32.74792],[-97.303184,32.748376],[-97.27053,32.766174],[-97.290546,32.80977],[-97.314478,32.809976]]]},"properties":{"id":"76111","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.237598,32.720684],[-97.245121,32.732639],[-97.296161,32.743811],[-97.297943,32.711767],[-97.237598,32.720684]]]},"properties":{"id":"76105","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512607,32.993935],[-97.431476,32.992331],[-97.439496,33.011358],[-97.520147,33.008815],[-97.512607,32.993935]]]},"properties":{"id":"76071","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.296161,32.743811],[-97.245121,32.732639],[-97.255188,32.775029],[-97.27053,32.766174],[-97.303184,32.748376],[-97.296161,32.743811]]]},"properties":{"id":"76103","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.337405,32.759335],[-97.358787,32.732802],[-97.352452,32.736008],[-97.307087,32.74792],[-97.320401,32.781256],[-97.336387,32.784519],[-97.337405,32.759335]]]},"properties":{"id":"76102","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.443822,32.76557],[-97.413277,32.790819],[-97.442761,32.788654],[-97.443822,32.76557]]]},"properties":{"id":"76127","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.845013,32.439031],[-99.812731,32.417063],[-99.751131,32.396205],[-99.745958,32.449824],[-99.845853,32.438891],[-99.845013,32.439031]]]},"properties":{"id":"79605","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.532855,33.06543],[-102.43417,33.043751],[-102.40502,32.958716],[-102.31303,32.958858],[-102.147151,32.95932],[-102.079058,32.959676],[-102.171297,33.2901],[-102.534105,33.270494],[-102.594489,33.271221],[-102.594718,33.167253],[-102.532855,33.06543]]]},"properties":{"id":"79316","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.810269,33.28289],[-94.847403,33.165045],[-94.825296,33.136998],[-94.810269,33.28289]]]},"properties":{"id":"75558","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.177898,31.385497],[-100.112932,31.366042],[-100.112914,31.399287],[-100.159348,31.414799],[-100.177898,31.385497]]]},"properties":{"id":"76937","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.846688,30.141121],[-97.797133,30.136926],[-97.788605,30.161972],[-97.786254,30.166975],[-97.842968,30.194284],[-97.853761,30.177403],[-97.846688,30.141121]]]},"properties":{"id":"78748","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.754495,30.264975],[-97.760657,30.267526],[-97.802487,30.244692],[-97.796557,30.234042],[-97.751207,30.215607],[-97.749367,30.215835],[-97.735994,30.250611],[-97.754495,30.264975]]]},"properties":{"id":"78704","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.737571,30.313464],[-97.72013,30.294284],[-97.710448,30.30959],[-97.70693,30.322181],[-97.726336,30.325943],[-97.737571,30.313464]]]},"properties":{"id":"78751","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.712787,30.347273],[-97.739606,30.370436],[-97.744438,30.361299],[-97.749749,30.334495],[-97.727922,30.329055],[-97.712787,30.347273]]]},"properties":{"id":"78757","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.762756,30.549717],[-97.857931,30.448178],[-97.826451,30.455687],[-97.798795,30.472983],[-97.745444,30.51918],[-97.762756,30.549717]]]},"properties":{"id":"78613","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.798795,30.472983],[-97.826451,30.455687],[-97.844422,30.393782],[-97.791352,30.359289],[-97.774931,30.390387],[-97.769726,30.434202],[-97.769209,30.434563],[-97.79526,30.465615],[-97.798795,30.472983]]]},"properties":{"id":"78750","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.934048,30.336451],[-97.90749,30.333131],[-97.87987,30.344291],[-97.854068,30.403691],[-97.873045,30.438268],[-97.911087,30.422215],[-97.934048,30.336451]]]},"properties":{"id":"78732","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.913383,30.273881],[-97.993901,30.306929],[-98.020796,30.29065],[-98.011253,30.242288],[-97.888305,30.229195],[-97.874816,30.233301],[-97.913383,30.273881]]]},"properties":{"id":"78736","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.197103,32.731046],[-95.15341,32.570115],[-95.017959,32.518669],[-94.986644,32.583304],[-95.007146,32.668704],[-95.137769,32.769827],[-95.197103,32.731046]]]},"properties":{"id":"75755","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.057491,31.369874],[-102.212364,31.085209],[-102.079756,31.083543],[-102.024572,31.262649],[-101.978055,31.27998],[-102.028383,31.377549],[-102.057491,31.369874]]]},"properties":{"id":"79752","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.41314,29.177891],[-99.412434,29.463269],[-99.678343,29.570868],[-99.713333,29.536448],[-99.664989,29.444994],[-99.644369,29.413677],[-99.534933,29.261283],[-99.484065,29.130809],[-99.41314,29.177891]]]},"properties":{"id":"78881","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.036276,29.771743],[-95.990769,29.812546],[-96.041399,29.789288],[-96.036276,29.771743]]]},"properties":{"id":"77466","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.950301,30.236905],[-96.093165,30.225187],[-96.157153,30.190318],[-96.146052,30.070224],[-96.113319,29.915301],[-96.031444,29.940625],[-95.992586,30.071485],[-96.010718,30.07667],[-96.021355,30.106211],[-95.983761,30.101642],[-95.950301,30.236905]]]},"properties":{"id":"77445","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.242965,30.212284],[-96.157153,30.190318],[-96.093165,30.225187],[-96.285753,30.364689],[-96.242965,30.212284]]]},"properties":{"id":"77880","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.016391,27.459742],[-99.008709,27.269847],[-98.897769,27.26894],[-98.911563,27.3504],[-98.944493,27.454387],[-99.016391,27.459742]]]},"properties":{"id":"78369","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.149189,29.047931],[-96.18505,29.02888],[-96.174601,29.034411],[-96.149189,29.047931]]],[[[-96.496731,29.325849],[-96.287345,28.974817],[-96.21843,29.01121],[-96.119129,29.063916],[-96.073432,29.088058],[-96.038643,29.106552],[-96.042668,29.162704],[-96.176448,29.253359],[-96.263753,29.345122],[-96.362455,29.39969],[-96.375814,29.393271],[-96.385345,29.388082],[-96.496731,29.325849]]]]},"properties":{"id":"77437","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.761466,34.089355],[-98.784962,33.834333],[-98.707449,33.834691],[-98.597063,33.907084],[-98.558613,34.035307],[-98.761466,34.089355]]]},"properties":{"id":"76367","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.511859,33.890294],[-98.533796,33.90955],[-98.550712,33.917176],[-98.565961,33.917769],[-98.560961,33.873924],[-98.511859,33.890294]]]},"properties":{"id":"76309","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.761466,34.089355],[-98.558613,34.035307],[-98.486999,34.06288],[-98.750765,34.124215],[-98.761466,34.089355]]]},"properties":{"id":"76354","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.132236,34.053392],[-99.0681,34.126946],[-99.089224,34.186809],[-99.132236,34.053392]]]},"properties":{"id":"76373","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.637563,26.497522],[-97.663994,26.511895],[-97.671897,26.497219],[-97.637563,26.497522]]]},"properties":{"id":"78590","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.253197,30.693216],[-97.439031,30.644188],[-97.490343,30.626949],[-97.501448,30.477578],[-97.292995,30.539916],[-97.264431,30.636587],[-97.253197,30.693216]]]},"properties":{"id":"76574","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514125,30.815455],[-97.483262,30.816379],[-97.513067,30.827711],[-97.514125,30.815455]]]},"properties":{"id":"76573","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.004112,29.299293],[-98.094442,29.252193],[-98.068436,29.265336],[-98.004112,29.299293]]]},"properties":{"id":"78161","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-102.784324,33.314582],[-102.629884,33.313901],[-102.611422,33.388472],[-102.726342,33.388275],[-102.784324,33.314582]]],[[[-102.81851,33.30128],[-103.063844,33.037587],[-102.66383,33.021368],[-102.663436,33.109719],[-102.81851,33.30128]]]]},"properties":{"id":"79355","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.112364,28.919639],[-99.914149,28.829345],[-99.724755,28.890614],[-99.733485,28.985008],[-100.112241,28.943914],[-100.112364,28.919639]]]},"properties":{"id":"78872","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.389659,31.654172],[-95.739279,31.504056],[-95.693768,31.387123],[-95.676377,31.36883],[-95.483614,31.397773],[-95.475255,31.432848],[-95.198652,31.450804],[-95.110246,31.524385],[-95.285864,31.629686],[-95.342483,31.718032],[-95.389659,31.654172]]]},"properties":{"id":"75844","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.74538,31.262068],[-94.730975,31.462395],[-94.799357,31.483246],[-94.959824,31.389017],[-94.95811,31.38693],[-94.862679,31.23236],[-94.74538,31.262068]]]},"properties":{"id":"75904","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.168713,27.996343],[-97.089666,27.966313],[-96.911678,28.265182],[-96.946284,28.297059],[-97.125331,28.116256],[-97.204009,28.080812],[-97.168713,27.996343]],[[-97.043264,28.069184],[-97.051569,28.070515],[-97.050091,28.076239],[-97.040648,28.073747],[-97.043264,28.069184]]]},"properties":{"id":"78382","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.625956,34.952702],[-101.434887,34.747504],[-101.34545,34.748235],[-101.265321,34.748281],[-101.086615,35.116022],[-101.086447,35.165303],[-101.328954,35.182009],[-101.578487,35.183069],[-101.622941,35.183117],[-101.625956,34.952702]]]},"properties":{"id":"79019","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.500415,29.089525],[-98.487079,29.095712],[-98.467355,29.049747],[-98.498728,29.059485],[-98.555207,28.985277],[-98.500792,28.793739],[-98.393791,28.764528],[-98.322485,28.795134],[-98.299106,28.958725],[-98.381446,29.144832],[-98.431298,29.122507],[-98.490395,29.107425],[-98.500415,29.089525]]]},"properties":{"id":"78064","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.913201,29.090628],[-98.967282,28.95442],[-98.802109,28.780896],[-98.771835,28.991364],[-98.804878,29.107015],[-98.825813,29.090402],[-98.913201,29.090628]]]},"properties":{"id":"78005","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.157153,30.190318],[-96.242965,30.212284],[-96.33059,30.058809],[-96.146052,30.070224],[-96.157153,30.190318]]]},"properties":{"id":"77426","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.807216,30.000608],[-96.731033,29.797116],[-96.672926,29.843098],[-96.594908,29.835619],[-96.541971,30.011174],[-96.558802,30.038709],[-96.567642,30.046892],[-96.807216,30.000608]]]},"properties":{"id":"78940","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.133437,29.877056],[-99.215387,29.696669],[-99.262695,29.625704],[-99.082244,29.549643],[-98.986066,29.58878],[-98.993935,29.815405],[-99.133437,29.877056]]]},"properties":{"id":"78003","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.281528,29.817629],[-97.208033,29.613764],[-97.182195,29.597734],[-97.042669,29.629912],[-97.054572,29.734827],[-97.194235,29.896776],[-97.240729,29.892753],[-97.281528,29.817629]]]},"properties":{"id":"78941","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.472444,33.399023],[-99.170971,33.398303],[-98.953938,33.397534],[-98.953408,33.627058],[-98.952933,33.785211],[-98.953088,33.834],[-99.47565,33.834005],[-99.648727,33.638416],[-99.623985,33.546262],[-99.47352,33.550766],[-99.472963,33.463801],[-99.472444,33.399023]]]},"properties":{"id":"76380","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.784978,28.170721],[-97.677774,28.150444],[-97.660628,28.179415],[-97.784978,28.170721]]]},"properties":{"id":"78391","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.641756,31.089451],[-97.685536,31.075112],[-97.648384,31.004906],[-97.594558,31.025037],[-97.617321,31.05695],[-97.641756,31.089451]]]},"properties":{"id":"76548","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.594558,31.025037],[-97.648384,31.004906],[-97.717422,30.931179],[-97.66628,30.878545],[-97.53518,30.836143],[-97.509483,30.871363],[-97.445175,30.931133],[-97.594558,31.025037]]]},"properties":{"id":"76571","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.282671,29.444655],[-98.255615,29.296176],[-98.281905,29.255018],[-98.195094,29.271278],[-98.18863,29.377907],[-98.282671,29.444655]]]},"properties":{"id":"78101","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.427214,29.398484],[-98.360799,29.414361],[-98.336051,29.41863],[-98.443068,29.419638],[-98.443103,29.409422],[-98.427214,29.398484]]]},"properties":{"id":"78220","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.339515,29.506186],[-98.328882,29.455309],[-98.31805,29.456223],[-98.282692,29.444676],[-98.195025,29.497203],[-98.195543,29.497684],[-98.280432,29.51099],[-98.32851,29.537378],[-98.35294,29.530446],[-98.339515,29.506186]]]},"properties":{"id":"78109","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.595587,29.487055],[-98.631242,29.511096],[-98.632285,29.482662],[-98.630089,29.453107],[-98.638607,29.442567],[-98.599694,29.435024],[-98.592944,29.485045],[-98.595587,29.487055]]]},"properties":{"id":"78238","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.630089,29.453107],[-98.711148,29.435559],[-98.804965,29.41314],[-98.804752,29.365679],[-98.797972,29.367631],[-98.688326,29.384988],[-98.657942,29.394541],[-98.638607,29.442567],[-98.630089,29.453107]]]},"properties":{"id":"78245","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.705364,29.49429],[-98.805603,29.528496],[-98.835332,29.510708],[-98.883782,29.440004],[-98.804965,29.41314],[-98.711148,29.435559],[-98.705364,29.49429]]]},"properties":{"id":"78253","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.592944,29.485045],[-98.599694,29.435024],[-98.54412,29.43722],[-98.540291,29.446609],[-98.567441,29.489211],[-98.592944,29.485045]]]},"properties":{"id":"78228","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.51257,29.380091],[-98.541736,29.381475],[-98.571578,29.360618],[-98.614378,29.320197],[-98.56794,29.320062],[-98.52215,29.362543],[-98.516836,29.370027],[-98.51257,29.380091]]]},"properties":{"id":"78211","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.634673,29.677056],[-98.638597,29.678795],[-98.630794,29.664197],[-98.598592,29.590911],[-98.563844,29.599685],[-98.540614,29.602463],[-98.51932,29.634865],[-98.522652,29.671769],[-98.611558,29.731034],[-98.634673,29.677056]]]},"properties":{"id":"78257","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.336051,29.41863],[-98.360799,29.414361],[-98.3365,29.312568],[-98.255615,29.296176],[-98.282671,29.444655],[-98.282692,29.444676],[-98.31805,29.456223],[-98.336051,29.41863]]]},"properties":{"id":"78263","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.195025,29.497203],[-98.138033,29.439391],[-98.121075,29.449048],[-98.070682,29.588194],[-98.057784,29.600011],[-98.151569,29.65721],[-98.202153,29.636265],[-98.186313,29.501417],[-98.195543,29.497684],[-98.195025,29.497203]]]},"properties":{"id":"78124","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.587982,30.239484],[-98.546045,30.153927],[-98.546022,30.159567],[-98.587982,30.239484]]]},"properties":{"id":"78635","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.206567,30.310812],[-98.252327,30.153801],[-98.259241,30.136542],[-98.097852,30.079817],[-98.008778,30.153469],[-98.011253,30.242288],[-98.020796,30.29065],[-98.130011,30.372683],[-98.206567,30.310812]]]},"properties":{"id":"78620","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.203438,33.459304],[-94.262241,33.373821],[-94.249721,33.364178],[-94.215246,33.32085],[-94.210137,33.297044],[-94.196711,33.298561],[-94.0459,33.272368],[-94.042987,33.271242],[-94.043112,33.439806],[-94.104667,33.443438],[-94.10502,33.432941],[-94.142803,33.433374],[-94.130748,33.454233],[-94.203389,33.465564],[-94.203438,33.459304]]]},"properties":{"id":"75501","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.330037,29.28274],[-95.221362,29.226611],[-95.229731,29.263598],[-95.330037,29.28274]]]},"properties":{"id":"77577","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.380188,29.279576],[-95.281201,29.161938],[-95.221362,29.226611],[-95.330037,29.28274],[-95.371094,29.301744],[-95.380188,29.279576]]]},"properties":{"id":"77534","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.420629,29.070772],[-95.436137,29.004864],[-95.388918,29.004752],[-95.385502,29.111204],[-95.420629,29.070772]]]},"properties":{"id":"77531","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.874028,29.229702],[-95.866112,29.003136],[-95.702192,28.969311],[-95.666027,29.101009],[-95.772974,29.197351],[-95.841948,29.258707],[-95.874028,29.229702]]]},"properties":{"id":"77480","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.369643,30.658141],[-96.338278,30.63065],[-96.313108,30.644332],[-96.297323,30.691158],[-96.346935,30.684444],[-96.369643,30.658141]]]},"properties":{"id":"77802","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.313108,30.644332],[-96.352587,30.619776],[-96.375803,30.626889],[-96.423096,30.587587],[-96.423429,30.559088],[-96.284259,30.446782],[-96.144808,30.510739],[-96.18176,30.607611],[-96.182458,30.617135],[-96.297323,30.691158],[-96.313108,30.644332]]]},"properties":{"id":"77845","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.417995,30.722369],[-96.531552,30.823249],[-96.526532,30.7581],[-96.550549,30.702982],[-96.566983,30.69567],[-96.423096,30.587587],[-96.375803,30.626889],[-96.381294,30.645632],[-96.417995,30.722369]]]},"properties":{"id":"77807","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.531552,30.823249],[-96.204498,30.924248],[-96.24102,30.973737],[-96.619246,30.966053],[-96.696693,30.909038],[-96.679176,30.844048],[-96.624663,30.734357],[-96.566983,30.69567],[-96.550549,30.702982],[-96.577432,30.756071],[-96.526532,30.7581],[-96.531552,30.823249]]]},"properties":{"id":"77859","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.34281,29.459671],[-103.523947,29.122254],[-102.889446,29.210156],[-103.34281,29.459671]]]},"properties":{"id":"79834","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.066533,30.998957],[-103.17017,30.477828],[-103.037807,30.385162],[-102.524082,30.283645],[-102.440233,30.283859],[-102.224685,30.893584],[-102.221106,31.034408],[-102.370682,31.018222],[-102.496072,31.018508],[-102.977119,31.199188],[-103.066533,30.998957]]]},"properties":{"id":"79735","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.20195,31.835052],[-99.203049,31.599618],[-99.129914,31.591969],[-99.20195,31.835052]]]},"properties":{"id":"76823","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.624663,30.734357],[-96.713845,30.72326],[-96.877811,30.600776],[-96.963629,30.557169],[-96.86807,30.433791],[-96.76105,30.344326],[-96.470842,30.50953],[-96.470831,30.509585],[-96.423429,30.559088],[-96.423096,30.587587],[-96.566983,30.69567],[-96.624663,30.734357]]]},"properties":{"id":"77836","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.267685,30.463753],[-98.011036,30.480387],[-97.979554,30.505878],[-98.05479,30.615022],[-98.117241,30.612604],[-98.352717,30.688353],[-98.400608,30.599476],[-98.413089,30.581562],[-98.320882,30.478013],[-98.267685,30.463753]]]},"properties":{"id":"78654","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.5385,29.776808],[-97.557704,29.614725],[-97.394954,29.641134],[-97.409464,29.759289],[-97.454228,29.793672],[-97.5385,29.776808]]]},"properties":{"id":"78632","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.791527,28.667603],[-96.847487,28.63684],[-96.913421,28.597456],[-96.755703,28.439786],[-96.352288,28.509531],[-96.322918,28.641875],[-96.453296,28.756286],[-96.45668,28.756234],[-96.577453,28.769812],[-96.57462,28.705503],[-96.643442,28.712105],[-96.69933,28.778099],[-96.768564,28.725046],[-96.791527,28.667603]]]},"properties":{"id":"77979","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.750588,28.417052],[-96.459028,28.419797],[-96.530462,28.425771],[-96.497785,28.464204],[-96.455081,28.435918],[-96.378226,28.466238],[-96.361009,28.470727],[-96.352288,28.509531],[-96.755703,28.439786],[-96.750588,28.417052]]]},"properties":{"id":"77983","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.727968,26.248428],[-97.673603,26.154566],[-97.599361,26.196548],[-97.528102,26.299273],[-97.575868,26.306249],[-97.777169,26.335462],[-97.764051,26.296227],[-97.735578,26.260343],[-97.727968,26.248428]]]},"properties":{"id":"78550","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.314222,35.799037],[-101.326208,35.650023],[-101.086017,35.67834],[-101.086146,35.742717],[-101.085921,35.915805],[-101.085954,35.942304],[-101.314222,35.799037]]]},"properties":{"id":"79080","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.430767,33.14018],[-94.538607,33.110653],[-94.551573,33.096804],[-94.482072,32.966707],[-94.430736,32.880091],[-94.281412,32.924509],[-94.228376,32.979412],[-94.247552,33.005384],[-94.337903,33.074202],[-94.430767,33.14018]]]},"properties":{"id":"75563","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.218636,32.880605],[-94.043093,32.829676],[-94.043101,32.910083],[-94.082723,32.954631],[-94.043127,32.937721],[-94.042988,33.007908],[-94.247552,33.005384],[-94.228376,32.979412],[-94.218636,32.880605]]]},"properties":{"id":"75555","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.551573,33.096804],[-94.620517,33.107988],[-94.664299,32.958677],[-94.626842,32.879501],[-94.482072,32.966707],[-94.551573,33.096804]]]},"properties":{"id":"75656","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.73915,29.887212],[-94.688269,29.771816],[-94.654351,29.84037],[-94.659943,29.88812],[-94.738857,29.887217],[-94.73915,29.887212]]]},"properties":{"id":"77597","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.918474,32.177857],[-94.985145,32.15793],[-94.985461,32.004556],[-94.985367,31.932311],[-94.929681,31.934835],[-94.761004,32.031615],[-94.648544,32.045687],[-94.596679,32.102391],[-94.589438,32.12882],[-94.918474,32.177857]]]},"properties":{"id":"75654","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.484639,32.082932],[-100.833761,31.954441],[-100.82537,31.696155],[-100.716247,31.694752],[-100.675726,31.694343],[-100.441592,31.693073],[-100.417173,31.692771],[-100.430332,31.743654],[-100.403046,32.013536],[-100.484639,32.082932]],[[-100.733936,32.015372],[-100.703596,32.074215],[-100.668342,32.07089],[-100.688745,32.010328],[-100.733936,32.015372]]]},"properties":{"id":"76945","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.733936,32.015372],[-100.688745,32.010328],[-100.668342,32.07089],[-100.703596,32.074215],[-100.733936,32.015372]]]},"properties":{"id":"76949","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.422906,32.080154],[-99.222972,31.941519],[-99.197623,32.01194],[-99.262815,32.080091],[-99.422906,32.080154]]]},"properties":{"id":"76828","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.594653,33.040664],[-96.641021,33.033443],[-96.632641,33.006027],[-96.613368,32.987017],[-96.595901,32.999831],[-96.594653,33.040664]]]},"properties":{"id":"75094","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.704331,33.037459],[-96.769884,33.041303],[-96.786423,33.011775],[-96.756858,33.000422],[-96.708059,33.003867],[-96.704331,33.037459]]]},"properties":{"id":"75075","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.595901,32.999831],[-96.613368,32.987017],[-96.613377,32.984021],[-96.607231,32.968815],[-96.582364,32.941634],[-96.559654,32.947622],[-96.595901,32.999831]]]},"properties":{"id":"75048","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.429626,33.398426],[-96.517217,33.488619],[-96.541391,33.484113],[-96.627186,33.465587],[-96.656486,33.401331],[-96.429626,33.398426]]]},"properties":{"id":"75495","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.314009,33.078224],[-96.461228,33.097738],[-96.474231,33.043064],[-96.41869,33.021247],[-96.31225,33.061098],[-96.314009,33.078224]]]},"properties":{"id":"75173","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.404923,34.834634],[-100.382295,34.747524],[-100.152814,34.747073],[-100.000382,34.81248],[-100.113617,35.08402],[-100.380128,35.019774],[-100.404923,34.834634]]]},"properties":{"id":"79095","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.199451,29.656455],[-96.269662,29.689055],[-96.373745,29.690415],[-96.417608,29.580277],[-96.408171,29.515281],[-96.362455,29.39969],[-96.263753,29.345122],[-96.194607,29.343907],[-96.197676,29.351612],[-96.23139,29.395797],[-96.251463,29.425996],[-96.215003,29.434303],[-96.190289,29.460795],[-96.198732,29.468084],[-96.25328,29.563675],[-96.191546,29.619369],[-96.199451,29.656455]]]},"properties":{"id":"77434","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.430431,29.573931],[-96.455773,29.571281],[-96.468052,29.56851],[-96.502232,29.563636],[-96.603079,29.487626],[-96.64234,29.42002],[-96.658466,29.263876],[-96.640315,29.247804],[-96.496731,29.325849],[-96.385345,29.388082],[-96.393249,29.402291],[-96.383517,29.406377],[-96.375814,29.393271],[-96.362455,29.39969],[-96.408171,29.515281],[-96.430431,29.573931]]]},"properties":{"id":"77442","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.826292,29.762574],[-96.834139,29.596794],[-96.809387,29.577134],[-96.706155,29.486696],[-96.626818,29.522545],[-96.607328,29.693504],[-96.649188,29.717323],[-96.654412,29.721972],[-96.72798,29.79217],[-96.826292,29.762574]]]},"properties":{"id":"78962","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.72798,29.79217],[-96.654412,29.721972],[-96.672926,29.843098],[-96.731033,29.797116],[-96.72798,29.79217]]]},"properties":{"id":"78938","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.111638,29.413291],[-96.07172,29.432113],[-96.068586,29.400629],[-95.967389,29.38197],[-95.989921,29.429664],[-96.016068,29.43689],[-95.984723,29.540722],[-95.989241,29.54024],[-96.191546,29.619369],[-96.25328,29.563675],[-96.172271,29.519058],[-96.198732,29.468084],[-96.190289,29.460795],[-96.111638,29.413291]]]},"properties":{"id":"77435","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.305607,31.765218],[-98.403888,31.730563],[-98.398958,31.717459],[-98.305607,31.765218]]]},"properties":{"id":"76452","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.677775,31.556364],[-99.794149,31.389004],[-99.738436,31.319237],[-99.602078,31.30342],[-99.602505,31.392843],[-99.60185,31.49195],[-99.677775,31.556364]]]},"properties":{"id":"76862","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.920188,31.759603],[-97.966132,31.771565],[-97.994442,31.615248],[-97.997371,31.555782],[-97.962183,31.549229],[-97.766431,31.672461],[-97.812165,31.738603],[-97.920188,31.759603]]]},"properties":{"id":"76538","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.878981,31.089467],[-97.871082,31.122676],[-97.903329,31.267225],[-98.071762,31.307797],[-98.075389,31.294266],[-97.878981,31.089467]]]},"properties":{"id":"76522","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.944002,31.424865],[-102.940098,31.407781],[-102.860349,31.379533],[-102.77396,31.651711],[-102.798939,31.651783],[-102.925212,31.651391],[-102.962416,31.585851],[-102.956011,31.572064],[-102.97113,31.491089],[-102.944002,31.424865]]]},"properties":{"id":"79756","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.405736,31.008781],[-101.685966,30.855599],[-101.761925,30.721099],[-101.689037,30.37383],[-101.7584,30.288044],[-101.521296,30.034775],[-101.115291,30.044083],[-100.999773,30.193829],[-100.960587,30.706071],[-100.962176,31.08249],[-101.109685,31.08075],[-101.245487,31.079578],[-101.405736,31.008781]],[[-101.387062,30.58233],[-101.33027,30.624078],[-101.288796,30.582354],[-101.373547,30.519356],[-101.387062,30.58233]]]},"properties":{"id":"76943","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.221106,31.034408],[-102.224685,30.893584],[-102.059576,30.801211],[-101.761925,30.721099],[-101.685966,30.855599],[-101.825001,31.079752],[-101.862201,31.079771],[-102.023499,31.082866],[-102.079756,31.083543],[-102.212364,31.085209],[-102.221106,31.034408]]]},"properties":{"id":"79744","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.534029,31.999228],[-104.476145,31.846848],[-104.345181,31.870484],[-104.341767,32.00012],[-104.534029,31.999228]]]},"properties":{"id":"88220","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.023751,36.50042],[-102.7877,36.20581],[-103.041149,36.144237],[-103.041554,35.622487],[-102.971621,35.622925],[-102.763671,35.69571],[-102.588645,35.754813],[-102.496601,35.790731],[-102.162734,35.953239],[-102.163015,36.055249],[-102.162867,36.430927],[-102.366476,36.500396],[-103.023751,36.50042]]]},"properties":{"id":"79022","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.648694,32.855858],[-96.667889,32.868031],[-96.725816,32.804378],[-96.729189,32.791893],[-96.685016,32.792372],[-96.668296,32.797221],[-96.634854,32.843785],[-96.648694,32.855858]]]},"properties":{"id":"75228","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.73096,32.76601],[-96.63927,32.748607],[-96.685016,32.792372],[-96.729189,32.791893],[-96.728461,32.777797],[-96.73096,32.76601]]]},"properties":{"id":"75227","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.768987,32.939867],[-96.82552,32.939518],[-96.818827,32.921478],[-96.780848,32.924073],[-96.764173,32.923871],[-96.751937,32.939974],[-96.768987,32.939867]]]},"properties":{"id":"75240","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.942255,32.988798],[-97.028593,32.989314],[-97.032387,32.984623],[-97.032587,32.955008],[-96.935245,32.937044],[-96.942255,32.988798]]]},"properties":{"id":"75019","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.856489,32.76634],[-96.856912,32.725429],[-96.824371,32.732819],[-96.817685,32.770207],[-96.822847,32.780782],[-96.856489,32.76634]]]},"properties":{"id":"75208","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.59306,32.909525],[-96.505552,32.921712],[-96.504821,32.921871],[-96.559654,32.947622],[-96.582364,32.941634],[-96.59306,32.909525]]]},"properties":{"id":"75089","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.784058,32.777448],[-96.790999,32.784067],[-96.805843,32.802048],[-96.814921,32.798434],[-96.813671,32.79509],[-96.795785,32.77351],[-96.784058,32.777448]]]},"properties":{"id":"75201","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.078174,32.609085],[-97.061339,32.584981],[-97.037491,32.618456],[-96.998951,32.64392],[-96.990085,32.652057],[-96.966132,32.706977],[-97.061123,32.706049],[-97.062961,32.70603],[-97.062762,32.677202],[-97.063594,32.643399],[-97.078174,32.609085]]]},"properties":{"id":"75052","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.728879,32.804288],[-96.725103,32.858517],[-96.732682,32.855173],[-96.753803,32.859626],[-96.771553,32.802084],[-96.769486,32.800285],[-96.764208,32.795665],[-96.761652,32.793428],[-96.728879,32.804288]]]},"properties":{"id":"75214","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.204675,33.299225],[-97.215323,33.207286],[-97.178723,33.176081],[-97.159268,33.17936],[-97.16148,33.20839],[-97.131864,33.240491],[-97.11243,33.25395],[-97.092386,33.281836],[-97.104088,33.282588],[-97.204675,33.299225]]]},"properties":{"id":"76207","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.720068,28.969507],[-97.604998,28.980678],[-97.575057,28.813006],[-97.454746,28.847577],[-97.36786,28.865336],[-97.355404,28.897621],[-97.344477,28.925692],[-97.509897,29.110585],[-97.556513,29.152313],[-97.613132,29.109765],[-97.73758,29.019474],[-97.740474,28.991377],[-97.720068,28.969507]]]},"properties":{"id":"78164","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.381243,31.868118],[-102.319351,31.865545],[-102.28743,31.873929],[-102.28735,31.885472],[-102.287307,31.926143],[-102.28733,31.932816],[-102.42105,31.995418],[-102.409775,31.96034],[-102.381243,31.868118]]]},"properties":{"id":"79762","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.102967,29.76313],[-99.917708,29.946761],[-99.754142,30.290698],[-100.116461,30.290296],[-100.700249,30.104739],[-100.699932,29.623897],[-100.306415,29.623608],[-100.102967,29.76313]]]},"properties":{"id":"78880","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.012479,32.35652],[-96.958531,32.27208],[-96.881827,32.231887],[-96.867271,32.288147],[-96.824629,32.494203],[-96.890913,32.500496],[-97.014231,32.373009],[-97.012479,32.35652]]]},"properties":{"id":"75167","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.605746,31.846239],[-106.544942,31.804548],[-106.542072,31.803976],[-106.539837,31.801111],[-106.499074,31.755056],[-106.505443,31.762216],[-106.523316,31.806983],[-106.570487,31.839368],[-106.605746,31.846239]]]},"properties":{"id":"79922","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.2335,31.754994],[-106.281921,31.82258],[-106.26767,31.760763],[-106.2335,31.754994]]]},"properties":{"id":"79938","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.2592,31.609476],[-106.193551,31.600223],[-106.284117,31.701351],[-106.337926,31.671211],[-106.295672,31.601037],[-106.2592,31.609476]]]},"properties":{"id":"79927","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.193551,31.600223],[-106.2592,31.609476],[-106.20825,31.538127],[-106.140002,31.530683],[-106.193551,31.600223]]]},"properties":{"id":"79836","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.566255,31.907331],[-106.503661,31.883159],[-106.55797,31.905646],[-106.566255,31.907331]]]},"properties":{"id":"79911","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.23273,32.512634],[-98.270273,32.644021],[-98.376334,32.738191],[-98.410743,32.567664],[-98.333321,32.430159],[-98.23273,32.512634]]]},"properties":{"id":"76453","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.265881,31.336416],[-97.161411,31.324988],[-97.260917,31.369391],[-97.265881,31.336416]]]},"properties":{"id":"76630","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.21334,31.455174],[-97.312051,31.418459],[-97.307083,31.413464],[-97.260917,31.369391],[-97.161411,31.324988],[-97.14919,31.315445],[-97.038097,31.391129],[-97.037397,31.391434],[-97.194224,31.421833],[-97.21334,31.455174]]]},"properties":{"id":"76655","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.017623,31.410927],[-96.747466,31.419039],[-96.920217,31.538169],[-97.045137,31.519037],[-97.017623,31.410927]]]},"properties":{"id":"76682","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.295248,33.269923],[-96.206263,33.20479],[-96.101276,33.241712],[-96.177579,33.376046],[-96.295304,33.291409],[-96.295248,33.269923]]]},"properties":{"id":"75423","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.786506,30.321639],[-96.862452,30.065031],[-96.816127,30.014499],[-96.760522,30.086129],[-96.682961,30.258851],[-96.640883,30.296786],[-96.76105,30.344326],[-96.786506,30.321639]]]},"properties":{"id":"78946","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.682961,30.258851],[-96.760522,30.086129],[-96.633272,30.098402],[-96.682961,30.258851]]]},"properties":{"id":"78932","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.531925,32.583233],[-100.469946,32.357677],[-100.390548,32.34427],[-100.323609,32.198493],[-100.224007,32.173038],[-100.171023,32.378747],[-100.219221,32.414046],[-100.273764,32.624663],[-100.309713,32.667879],[-100.533866,32.674051],[-100.531925,32.583233]]]},"properties":{"id":"79556","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.239863,34.157661],[-101.258248,34.259127],[-101.327432,34.186142],[-101.239863,34.157661]]]},"properties":{"id":"79258","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.124914,33.166764],[-95.188865,33.288213],[-95.307863,33.259009],[-95.307884,33.255938],[-95.308075,33.102071],[-95.241898,33.080408],[-95.125949,33.053024],[-95.125769,33.088025],[-95.125573,33.11907],[-95.124914,33.166764]]]},"properties":{"id":"75457","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.41388,29.091335],[-99.411735,28.838022],[-98.848695,28.647201],[-98.800841,28.647487],[-98.802109,28.780896],[-98.967282,28.95442],[-99.138787,29.008589],[-99.233818,29.090938],[-99.41388,29.091335]]]},"properties":{"id":"78061","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.145523,33.130229],[-101.422693,32.985875],[-101.442447,32.961944],[-101.173378,32.963597],[-101.03839,33.000412],[-101.038659,33.110781],[-101.145523,33.130229]]]},"properties":{"id":"79330","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.437213,33.395572],[-101.558119,33.498721],[-101.622134,33.609494],[-101.688571,33.545619],[-101.686718,33.538476],[-101.674689,33.536624],[-101.678039,33.523952],[-101.697252,33.527527],[-101.79686,33.476283],[-101.790139,33.392888],[-101.573516,33.330815],[-101.437213,33.395572]]]},"properties":{"id":"79364","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.532088,33.822824],[-96.570721,33.695656],[-96.47339,33.676979],[-96.37991,33.703327],[-96.379452,33.725764],[-96.532088,33.822824]]]},"properties":{"id":"75021","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.846241,31.410478],[-98.57981,31.265386],[-98.290464,31.376208],[-98.332425,31.501569],[-98.422094,31.627378],[-98.483715,31.58751],[-98.567566,31.57943],[-98.846241,31.410478]]]},"properties":{"id":"76844","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.794822,36.197525],[-101.623501,36.087381],[-101.467322,36.124542],[-101.440011,36.499374],[-101.688199,36.499523],[-101.801768,36.328307],[-101.794822,36.197525]]]},"properties":{"id":"79040","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.464355,30.527026],[-94.40172,30.482805],[-94.334503,30.526813],[-94.464355,30.527026]]]},"properties":{"id":"77663","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.580972,30.490829],[-94.645269,30.490519],[-94.691601,30.376515],[-94.687652,30.365384],[-94.67528,30.331209],[-94.626603,30.315936],[-94.571088,30.417247],[-94.580972,30.490829]]]},"properties":{"id":"77374","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.585769,29.952539],[-95.579683,29.933616],[-95.530747,29.950558],[-95.536184,29.962094],[-95.553531,29.992682],[-95.570625,30.022776],[-95.590658,30.013058],[-95.605416,29.998162],[-95.585769,29.952539]]]},"properties":{"id":"77070","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.528227,30.088189],[-95.541399,30.170279],[-95.558324,30.165523],[-95.628738,30.12777],[-95.645676,30.119221],[-95.647469,30.118107],[-95.590658,30.013058],[-95.570625,30.022776],[-95.528227,30.088189]]]},"properties":{"id":"77375","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.676126,29.878961],[-95.624407,29.872951],[-95.60365,29.903516],[-95.638855,29.928611],[-95.67347,29.953075],[-95.676126,29.878961]]]},"properties":{"id":"77095","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.697593,30.043965],[-95.777055,30.037074],[-95.808336,29.950124],[-95.773835,29.875155],[-95.683296,29.879941],[-95.676126,29.878961],[-95.67347,29.953075],[-95.697593,30.043965]]]},"properties":{"id":"77433","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.172964,29.746931],[-95.172712,29.691757],[-95.174906,29.665808],[-95.172234,29.665319],[-95.13512,29.66922],[-95.146064,29.736096],[-95.172964,29.746931]]]},"properties":{"id":"77503","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.512028,30.006695],[-95.553531,29.992682],[-95.536184,29.962094],[-95.501172,29.983657],[-95.496427,29.988955],[-95.512028,30.006695]]]},"properties":{"id":"77069","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.146064,29.736096],[-95.13512,29.66922],[-95.110853,29.663923],[-95.11023,29.740137],[-95.146064,29.736096]]]},"properties":{"id":"77536","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.412618,29.898146],[-95.412403,29.931814],[-95.424577,29.939632],[-95.462757,29.941105],[-95.483258,29.937567],[-95.453334,29.897511],[-95.412618,29.898146]]]},"properties":{"id":"77038","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.916618,29.770271],[-95.049441,29.791077],[-95.060823,29.792512],[-95.082685,29.764138],[-94.978845,29.676731],[-94.930807,29.673759],[-94.916618,29.770271]]]},"properties":{"id":"77520","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.095375,29.880235],[-95.229925,29.82456],[-95.212797,29.805205],[-95.158302,29.805595],[-95.078824,29.824866],[-95.093868,29.864805],[-95.095375,29.880235]]]},"properties":{"id":"77049","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.290279,29.716811],[-95.326664,29.695466],[-95.326944,29.695307],[-95.314894,29.654952],[-95.272673,29.680346],[-95.288732,29.697449],[-95.290279,29.716811]]]},"properties":{"id":"77087","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.372894,29.902208],[-95.370123,29.892615],[-95.30659,29.891185],[-95.295601,29.918636],[-95.307032,29.918431],[-95.379616,29.932788],[-95.372894,29.902208]]]},"properties":{"id":"77039","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.461006,29.841009],[-95.40297,29.841162],[-95.403014,29.846522],[-95.408133,29.869872],[-95.409698,29.871413],[-95.487134,29.866543],[-95.486772,29.865752],[-95.461006,29.841009]]]},"properties":{"id":"77091","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.402086,29.743765],[-95.430029,29.742068],[-95.42997,29.738337],[-95.429973,29.732624],[-95.427966,29.728586],[-95.402073,29.72665],[-95.402086,29.743765]]]},"properties":{"id":"77098","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.372894,29.902208],[-95.412403,29.931814],[-95.412618,29.898146],[-95.409698,29.871413],[-95.408133,29.869872],[-95.36653,29.876477],[-95.370123,29.892615],[-95.372894,29.902208]]]},"properties":{"id":"77037","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.10361,29.583483],[-95.159308,29.581813],[-95.128593,29.548957],[-95.10361,29.583483]]]},"properties":{"id":"77062","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.646552,29.769702],[-95.589615,29.759794],[-95.568921,29.761943],[-95.56517,29.784433],[-95.617232,29.790612],[-95.644788,29.784716],[-95.646552,29.769702]]]},"properties":{"id":"77079","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.508673,29.656011],[-95.508641,29.676172],[-95.529793,29.67336],[-95.536895,29.642253],[-95.534012,29.639819],[-95.518385,29.626588],[-95.516131,29.627437],[-95.508673,29.656011]]]},"properties":{"id":"77071","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.362277,29.765225],[-95.366164,29.767392],[-95.366568,29.764648],[-95.362277,29.765225]]]},"properties":{"id":"77201","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.011253,30.242288],[-98.008778,30.153469],[-97.975999,30.128761],[-97.955983,30.118228],[-97.908562,30.167742],[-97.880168,30.196449],[-97.888305,30.229195],[-98.011253,30.242288]]]},"properties":{"id":"78737","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.930253,26.087188],[-97.97238,26.257557],[-97.976832,26.279148],[-98.009028,26.278611],[-98.03447,26.051167],[-97.950087,26.061925],[-97.930253,26.087188]]]},"properties":{"id":"78596","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.476086,32.17346],[-97.367524,32.178421],[-97.318599,32.195354],[-97.319339,32.23198],[-97.378971,32.266339],[-97.48749,32.171102],[-97.476086,32.17346]]]},"properties":{"id":"76093","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.684491,32.317749],[-97.615055,32.203581],[-97.48749,32.171102],[-97.378971,32.266339],[-97.383601,32.423557],[-97.476071,32.423701],[-97.604823,32.355924],[-97.70361,32.370365],[-97.684491,32.317749]]]},"properties":{"id":"76033","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.208308,32.990811],[-96.227911,32.887542],[-96.187079,32.84039],[-96.076619,32.823653],[-95.9866,32.873064],[-95.970436,32.907908],[-96.016694,32.978973],[-96.148053,33.000693],[-96.208308,32.990811]]]},"properties":{"id":"75474","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.948784,33.033638],[-97.98986,32.96975],[-97.789165,32.923944],[-97.827156,33.066504],[-97.948784,33.033638]]]},"properties":{"id":"76487","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.405697,30.099914],[-94.337204,30.000985],[-94.299765,30.000264],[-94.335638,30.106305],[-94.405697,30.099914]]]},"properties":{"id":"77613","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.429254,32.514913],[-97.321128,32.446913],[-97.267679,32.464781],[-97.197246,32.510036],[-97.18626,32.519154],[-97.215774,32.573811],[-97.32725,32.589892],[-97.429254,32.514913]]]},"properties":{"id":"76028","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.29609,32.399358],[-97.337669,32.402929],[-97.338685,32.38442],[-97.296102,32.397311],[-97.29609,32.399358]]]},"properties":{"id":"76059","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.447914,32.652119],[-97.503424,32.555439],[-97.429254,32.514913],[-97.32725,32.589892],[-97.331328,32.614019],[-97.355082,32.608198],[-97.41362,32.644793],[-97.447914,32.652119]]]},"properties":{"id":"76036","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.090536,28.710842],[-97.80662,28.918828],[-97.740474,28.991377],[-97.73758,29.019474],[-97.868446,29.031389],[-97.874937,28.972102],[-97.87442,28.948178],[-97.904646,28.942136],[-98.061505,28.879314],[-98.160493,28.85264],[-98.090536,28.710842]]]},"properties":{"id":"78118","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.868446,29.031389],[-97.73758,29.019474],[-97.613132,29.109765],[-97.728443,29.221748],[-97.8088,29.162799],[-97.880642,29.110146],[-97.901453,29.040286],[-97.868446,29.031389]]]},"properties":{"id":"78116","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.531226,26.368216],[-97.963943,26.53364],[-97.996514,26.49191],[-97.972429,26.444233],[-97.890321,26.433308],[-97.531226,26.368216]],[[-97.919615,26.464815],[-97.927407,26.467089],[-97.924484,26.490035],[-97.912962,26.496862],[-97.919615,26.464815]],[[-97.637563,26.497522],[-97.671897,26.497219],[-97.663994,26.511895],[-97.637563,26.497522]]]},"properties":{"id":"78580","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.985887,27.209308],[-97.985736,27.086451],[-97.677612,27.152892],[-97.727276,27.266019],[-97.985887,27.209308]]]},"properties":{"id":"78385","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.038788,33.397214],[-101.038636,33.177985],[-101.038542,33.126459],[-100.517484,33.171804],[-100.44097,33.343216],[-100.547496,33.348212],[-100.859554,33.39813],[-101.038788,33.397214]]]},"properties":{"id":"79528","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.174021,32.79208],[-100.985479,32.560581],[-100.946798,32.527018],[-100.922325,32.526692],[-100.658208,32.796936],[-100.517553,33.107391],[-100.517484,33.171804],[-101.038542,33.126459],[-101.038659,33.110781],[-101.03839,33.000412],[-101.070237,32.932501],[-101.174021,32.79208]]]},"properties":{"id":"79549","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.69999,29.503788],[-100.112049,29.338818],[-100.112098,29.623263],[-100.306415,29.623608],[-100.699932,29.623897],[-100.69999,29.503788]]]},"properties":{"id":"78832","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.553575,33.398768],[-99.47352,33.550766],[-99.623985,33.546262],[-99.739928,33.510228],[-99.702458,33.398446],[-99.553575,33.398768]]]},"properties":{"id":"76371","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.47352,33.550766],[-99.553575,33.398768],[-99.534473,33.398804],[-99.472963,33.463801],[-99.47352,33.550766]]]},"properties":{"id":"76363","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.533013,33.740623],[-95.584291,33.830324],[-95.655512,33.81082],[-95.619932,33.681463],[-95.65978,33.587902],[-95.644218,33.486105],[-95.625674,33.485613],[-95.533013,33.740623]]]},"properties":{"id":"75460","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.584291,33.830324],[-95.502479,33.875199],[-95.756154,33.851126],[-95.655512,33.81082],[-95.584291,33.830324]]]},"properties":{"id":"75411","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.655512,33.81082],[-95.756154,33.851126],[-95.846536,33.839855],[-95.854479,33.823731],[-95.790299,33.683183],[-95.619932,33.681463],[-95.655512,33.81082]]]},"properties":{"id":"75486","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.042669,29.629912],[-97.182195,29.597734],[-97.064001,29.485004],[-96.984722,29.572656],[-97.042669,29.629912]]]},"properties":{"id":"77975","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.858067,30.326767],[-96.786506,30.321639],[-96.76105,30.344326],[-96.86807,30.433791],[-96.927455,30.383879],[-96.858067,30.326767]]]},"properties":{"id":"77853","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.782416,31.702128],[-96.889086,31.647766],[-96.656514,31.6071],[-96.643017,31.626617],[-96.639567,31.6362],[-96.782416,31.702128]]]},"properties":{"id":"76678","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.275962,36.245299],[-100.366152,36.230719],[-100.330366,36.187278],[-100.182733,36.261082],[-100.275962,36.245299]]]},"properties":{"id":"79056","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.388966,28.524598],[-98.334526,28.529811],[-98.182686,28.670708],[-98.500128,28.648191],[-98.388966,28.524598]]]},"properties":{"id":"78075","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.463118,30.921575],[-98.533328,30.833699],[-98.421026,30.845025],[-98.44862,30.910089],[-98.445782,30.921439],[-98.463118,30.921575]]]},"properties":{"id":"78672","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.907037,33.531046],[-101.877001,33.39167],[-101.790139,33.392888],[-101.79686,33.476283],[-101.853069,33.529351],[-101.870403,33.529365],[-101.907037,33.531046]]]},"properties":{"id":"79423","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.840167,33.722535],[-101.853224,33.60671],[-101.829427,33.577959],[-101.688571,33.545619],[-101.622134,33.609494],[-101.630829,33.638521],[-101.776534,33.781257],[-101.854021,33.795299],[-101.84162,33.737373],[-101.81921,33.737563],[-101.819055,33.723068],[-101.840167,33.722535]]]},"properties":{"id":"79403","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.991705,33.521102],[-102.078989,33.519429],[-102.067603,33.389618],[-101.964024,33.390825],[-101.991705,33.521102]]]},"properties":{"id":"79382","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.829427,33.577959],[-101.845624,33.577848],[-101.845639,33.563283],[-101.853069,33.529351],[-101.79686,33.476283],[-101.697252,33.527527],[-101.686718,33.538476],[-101.688571,33.545619],[-101.829427,33.577959]]]},"properties":{"id":"79404","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.187577,30.941066],[-99.188204,30.919129],[-99.06979,30.883756],[-99.062934,30.885682],[-98.964612,30.921368],[-98.953363,30.921312],[-98.934801,30.936226],[-98.929987,30.951437],[-98.938047,30.963288],[-99.016615,31.050004],[-99.092049,31.045715],[-99.187577,30.941066]]]},"properties":{"id":"76842","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.029804,31.659006],[-96.917921,31.585997],[-96.889086,31.647766],[-96.973964,31.764759],[-96.996362,31.725243],[-97.029804,31.659006]]]},"properties":{"id":"76624","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.153192,31.543004],[-97.185854,31.547887],[-97.216246,31.575042],[-97.176119,31.503632],[-97.148264,31.537302],[-97.153192,31.543004]]]},"properties":{"id":"76710","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.019657,28.747058],[-96.322918,28.641875],[-96.352288,28.509531],[-96.361009,28.470727],[-95.979899,28.680205],[-95.972015,28.720698],[-96.016946,28.748611],[-96.019657,28.747058]]]},"properties":{"id":"77428","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.933701,29.175398],[-95.979926,29.138643],[-95.951538,29.101312],[-95.899233,29.206659],[-95.933701,29.175398]]]},"properties":{"id":"77468","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.177143,28.887471],[-96.167144,28.877278],[-96.118853,28.889694],[-96.131522,28.907191],[-96.177143,28.887471]]]},"properties":{"id":"77440","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.804965,29.41314],[-98.883782,29.440004],[-98.923306,29.404591],[-98.949636,29.29672],[-98.877933,29.283965],[-98.804752,29.365679],[-98.804965,29.41314]]]},"properties":{"id":"78009","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.986066,29.58878],[-99.082244,29.549643],[-98.946442,29.442068],[-98.835332,29.510708],[-98.805603,29.528496],[-98.805684,29.548014],[-98.862581,29.630834],[-98.986066,29.58878]]]},"properties":{"id":"78056","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.483715,31.58751],[-98.557538,31.672142],[-98.567566,31.57943],[-98.483715,31.58751]]]},"properties":{"id":"76870","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.176674,32.399095],[-101.120712,32.337027],[-101.17509,32.480466],[-101.175824,32.438967],[-101.176674,32.399095]]]},"properties":{"id":"79565","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.505125,30.145153],[-95.436873,30.110175],[-95.436597,30.110418],[-95.449495,30.156895],[-95.452247,30.178243],[-95.505125,30.145153]]]},"properties":{"id":"77380","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.825296,33.136998],[-94.816276,33.094074],[-94.71967,33.121094],[-94.707458,33.311943],[-94.746925,33.328938],[-94.808783,33.363636],[-94.810269,33.28289],[-94.825296,33.136998]]]},"properties":{"id":"75571","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.489672,31.398929],[-94.429145,31.419591],[-94.304277,31.504403],[-94.3055,31.544033],[-94.405305,31.677098],[-94.541626,31.71098],[-94.641582,31.617643],[-94.650397,31.616945],[-94.657696,31.618499],[-94.638995,31.443676],[-94.569151,31.433006],[-94.489672,31.398929]],[[-94.533961,31.513054],[-94.522486,31.514551],[-94.529231,31.503065],[-94.533961,31.513054]]]},"properties":{"id":"75961","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927175,31.876113],[-94.929345,31.845445],[-94.905427,31.822496],[-94.944833,31.814431],[-94.978134,31.793514],[-94.968341,31.748306],[-94.959754,31.727566],[-94.817017,31.685333],[-94.733988,31.843806],[-94.826161,31.918177],[-94.927175,31.876113]]]},"properties":{"id":"75760","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.641582,31.617643],[-94.642504,31.623125],[-94.652386,31.622942],[-94.650397,31.616945],[-94.641582,31.617643]]]},"properties":{"id":"75962","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.959754,31.727566],[-94.891503,31.577384],[-94.817017,31.685333],[-94.959754,31.727566]]]},"properties":{"id":"75943","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.399106,32.188463],[-96.321725,32.090358],[-96.378796,32.19999],[-96.399106,32.188463]]]},"properties":{"id":"75153","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.418011,32.212072],[-96.399106,32.188463],[-96.378796,32.19999],[-96.28561,32.266895],[-96.383082,32.328851],[-96.451038,32.294418],[-96.418011,32.212072]]]},"properties":{"id":"75105","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.902633,31.118026],[-93.866219,30.930167],[-93.74362,30.974951],[-93.662078,31.172607],[-93.911126,31.158071],[-93.902633,31.118026]]]},"properties":{"id":"75977","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.704578,30.289721],[-93.794017,30.325867],[-93.922714,30.242572],[-93.915707,30.130568],[-93.701393,30.127406],[-93.704578,30.289721]]]},"properties":{"id":"77632","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.374578,27.697628],[-97.319666,27.665513],[-97.308187,27.709883],[-97.364804,27.737864],[-97.374578,27.697628]]]},"properties":{"id":"78412","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.42017,27.763438],[-97.427395,27.735454],[-97.503845,27.748958],[-97.547534,27.749936],[-97.552093,27.636479],[-97.50272,27.560386],[-97.405057,27.637614],[-97.421692,27.652085],[-97.404442,27.712303],[-97.39759,27.753614],[-97.419305,27.764888],[-97.42017,27.763438]]]},"properties":{"id":"78415","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.375878,27.698266],[-97.421692,27.652085],[-97.405057,27.637614],[-97.343264,27.633329],[-97.319666,27.665513],[-97.374578,27.697628],[-97.375878,27.698266]]]},"properties":{"id":"78414","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.461236,27.785737],[-97.469772,27.764117],[-97.460063,27.755986],[-97.42017,27.763438],[-97.419305,27.764888],[-97.410931,27.783542],[-97.409409,27.789022],[-97.461236,27.785737]]]},"properties":{"id":"78405","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.932193,35.187246],[-101.920303,35.179755],[-101.884025,35.191532],[-101.859523,35.208471],[-101.843064,35.214252],[-101.866622,35.228573],[-101.932193,35.187246]]]},"properties":{"id":"79106","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.742197,35.2007],[-101.653695,35.207049],[-101.741875,35.203454],[-101.742197,35.2007]]]},"properties":{"id":"79111","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.918028,34.982038],[-101.9183,34.984805],[-101.919296,34.984817],[-101.919308,34.982062],[-101.918028,34.982038]]]},"properties":{"id":"79016","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.61947,29.709761],[-99.745329,29.625228],[-99.725412,29.577199],[-99.710678,29.578188],[-99.69453,29.580066],[-99.636718,29.626676],[-99.60323,29.689592],[-99.61947,29.709761]]]},"properties":{"id":"78879","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.175065,33.947527],[-95.193154,33.63402],[-95.049274,33.864576],[-95.175065,33.947527]]]},"properties":{"id":"75412","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.933281,31.777252],[-103.912248,31.902424],[-104.033609,31.898631],[-104.040303,31.822719],[-103.933281,31.777252]]]},"properties":{"id":"79770","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.609409,31.079483],[-96.765334,31.045141],[-96.773712,31.01634],[-96.696693,30.909038],[-96.619246,30.966053],[-96.609409,31.079483]]]},"properties":{"id":"77837","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.911126,31.158071],[-93.975094,31.346092],[-94.008602,31.186607],[-93.911126,31.158071]]]},"properties":{"id":"75968","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.138285,30.784661],[-95.23409,30.717157],[-95.269615,30.652044],[-95.236819,30.546536],[-95.137063,30.485903],[-95.004959,30.580123],[-95.008766,30.613938],[-95.135151,30.768471],[-95.138285,30.784661]]]},"properties":{"id":"77331","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.283604,30.773019],[-95.23409,30.717157],[-95.138285,30.784661],[-95.190379,30.820723],[-95.283604,30.773019]]]},"properties":{"id":"77364","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.23409,30.717157],[-95.283604,30.773019],[-95.330296,30.828287],[-95.334407,30.782646],[-95.338625,30.737826],[-95.345124,30.646554],[-95.269615,30.652044],[-95.23409,30.717157]]]},"properties":{"id":"77359","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.463118,30.921575],[-98.445782,30.921439],[-98.458341,31.005905],[-98.497473,30.988053],[-98.500955,30.921944],[-98.463118,30.921575]]]},"properties":{"id":"76824","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.269436,32.95707],[-99.387517,32.977414],[-99.388955,32.956965],[-99.269436,32.95707]]],[[[-99.255371,32.957081],[-99.443286,32.710915],[-99.495184,32.720257],[-99.300548,32.554572],[-99.095971,32.676454],[-99.096016,32.957037],[-99.197979,32.956833],[-99.255371,32.957081]]]]},"properties":{"id":"76430","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.943634,31.844638],[-94.081955,31.616215],[-93.927828,31.591276],[-93.834918,31.586212],[-93.87897,31.844767],[-93.943634,31.844638]]]},"properties":{"id":"75973","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.895008,36.500352],[-101.801768,36.328307],[-101.688199,36.499523],[-101.895008,36.500352]]]},"properties":{"id":"73949","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.34941,32.3915],[-95.293797,32.373955],[-95.216472,32.490508],[-95.211458,32.545631],[-95.431137,32.468944],[-95.34941,32.3915]]]},"properties":{"id":"75706","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.108364,26.546681],[-99.167367,26.559382],[-99.096912,26.464589],[-99.108364,26.546681]]]},"properties":{"id":"78585","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.149196,32.706792],[-97.20271,32.713348],[-97.20883,32.713174],[-97.231466,32.668113],[-97.223359,32.667957],[-97.149495,32.674394],[-97.149196,32.706792]]]},"properties":{"id":"76016","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.115545,32.644714],[-97.189004,32.646212],[-97.192306,32.619315],[-97.117533,32.610247],[-97.115545,32.644714]]]},"properties":{"id":"76001","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.034085,32.81666],[-97.033794,32.836951],[-97.06111,32.837835],[-97.069641,32.837027],[-97.056617,32.813617],[-97.034085,32.81666]]]},"properties":{"id":"76155","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.258987,32.894914],[-97.269461,32.839399],[-97.260407,32.839658],[-97.237996,32.860414],[-97.238531,32.892414],[-97.258987,32.894914]]]},"properties":{"id":"76148","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.843491,32.31279],[-99.843323,32.23997],[-99.794823,32.255512],[-99.795831,32.302429],[-99.843491,32.31279]]]},"properties":{"id":"79508","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.769726,30.434202],[-97.708211,30.4179],[-97.678176,30.407802],[-97.671862,30.424528],[-97.720718,30.453129],[-97.769209,30.434563],[-97.769726,30.434202]]]},"properties":{"id":"78727","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.796919,30.350015],[-97.836362,30.324636],[-97.864235,30.299473],[-97.802487,30.244692],[-97.760657,30.267526],[-97.775884,30.313606],[-97.796919,30.350015]]]},"properties":{"id":"78746","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.65813,30.228095],[-97.68484,30.245782],[-97.690258,30.24581],[-97.69128,30.245176],[-97.676704,30.222237],[-97.669026,30.218525],[-97.65813,30.228095]]]},"properties":{"id":"78742","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.004732,30.354856],[-97.934048,30.336451],[-97.911087,30.422215],[-97.919979,30.432968],[-98.003368,30.372991],[-98.004732,30.354856]]]},"properties":{"id":"78734","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.007146,32.668704],[-94.823047,32.823995],[-94.835605,32.861718],[-95.134596,32.86856],[-95.137769,32.769827],[-95.007146,32.668704]]]},"properties":{"id":"75644","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.878486,32.78777],[-95.860224,32.594207],[-95.829197,32.662388],[-95.763867,32.757904],[-95.799059,32.773815],[-95.878486,32.78777]]]},"properties":{"id":"75117","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.338625,30.737826],[-95.763474,30.679953],[-95.764177,30.636594],[-95.699179,30.561716],[-95.663832,30.543214],[-95.345124,30.646554],[-95.338625,30.737826]]]},"properties":{"id":"77340","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.016391,27.459742],[-98.944493,27.454387],[-98.800822,27.616923],[-98.918468,27.74313],[-99.120547,27.66945],[-99.12092,27.581015],[-99.016391,27.459742]]]},"properties":{"id":"78371","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.020123,29.246284],[-95.957154,29.203634],[-95.933701,29.175398],[-95.899233,29.206659],[-95.874028,29.229702],[-95.841948,29.258707],[-95.847656,29.26259],[-95.949463,29.335318],[-96.020123,29.246284]]]},"properties":{"id":"77420","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.111638,29.413291],[-96.068586,29.400629],[-96.07172,29.432113],[-96.111638,29.413291]]]},"properties":{"id":"77448","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.215003,29.434303],[-96.251463,29.425996],[-96.23139,29.395797],[-96.215003,29.434303]]]},"properties":{"id":"77436","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.519385,33.979909],[-98.49511,33.956012],[-98.493687,33.95998],[-98.519385,33.979909]]]},"properties":{"id":"76311","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.954301,26.835477],[-99.147137,27.228492],[-99.185406,27.21993],[-99.385401,26.981377],[-99.200522,26.656443],[-99.016449,26.725161],[-98.954301,26.835477]]]},"properties":{"id":"78076","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.730975,31.462395],[-94.74538,31.262068],[-94.689117,31.097795],[-94.605193,31.077669],[-94.569151,31.433006],[-94.638995,31.443676],[-94.730975,31.462395]]]},"properties":{"id":"75901","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.990697,31.481238],[-95.048549,31.449087],[-94.959824,31.389017],[-94.799357,31.483246],[-94.865857,31.526916],[-94.990697,31.481238]]]},"properties":{"id":"75969","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.35709,31.246889],[-94.45782,31.033326],[-94.451127,31.003445],[-94.298196,31.036993],[-94.129632,31.09928],[-94.326616,31.224754],[-94.35709,31.246889]]]},"properties":{"id":"75980","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.467424,28.71498],[-98.512745,28.648208],[-98.500128,28.648191],[-98.182686,28.670708],[-98.090516,28.668791],[-98.090536,28.710842],[-98.160493,28.85264],[-98.322485,28.795134],[-98.393791,28.764528],[-98.467424,28.71498]]]},"properties":{"id":"78008","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.498728,29.059485],[-98.467355,29.049747],[-98.487079,29.095712],[-98.500415,29.089525],[-98.498728,29.059485]]]},"properties":{"id":"78050","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.465286,29.983768],[-96.366433,29.916888],[-96.260625,29.900509],[-96.112353,29.889406],[-96.113319,29.915301],[-96.146052,30.070224],[-96.33059,30.058809],[-96.4044,30.028629],[-96.46523,29.98396],[-96.465286,29.983768]]]},"properties":{"id":"77418","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.558802,30.038709],[-96.541971,30.011174],[-96.52622,30.006051],[-96.483338,30.03728],[-96.4044,30.028629],[-96.33059,30.058809],[-96.242965,30.212284],[-96.285753,30.364689],[-96.299173,30.379152],[-96.544484,30.318563],[-96.54963,30.04739],[-96.558802,30.038709]]]},"properties":{"id":"77833","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.483338,30.03728],[-96.46523,29.98396],[-96.4044,30.028629],[-96.483338,30.03728]]]},"properties":{"id":"78931","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.603318,29.740258],[-99.61947,29.709761],[-99.60323,29.689592],[-99.603318,29.740258]]],[[[-99.603318,29.740258],[-99.473275,29.762577],[-99.498983,29.906691],[-99.602769,29.888101],[-99.603318,29.740258]]]]},"properties":{"id":"78885","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.467255,30.003547],[-97.429769,30.190761],[-97.501431,30.208451],[-97.528888,30.203382],[-97.568284,30.068118],[-97.514389,30.001325],[-97.467255,30.003547]]]},"properties":{"id":"78612","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.862452,30.065031],[-97.060427,30.042688],[-97.046202,29.984485],[-97.020961,29.850986],[-96.98371,29.777247],[-96.826292,29.762574],[-96.72798,29.79217],[-96.731033,29.797116],[-96.807216,30.000608],[-96.816127,30.014499],[-96.862452,30.065031]]]},"properties":{"id":"78945","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.903329,31.267225],[-97.634776,31.209749],[-97.637103,31.20267],[-97.466519,31.164609],[-97.495441,31.206368],[-97.503512,31.325982],[-97.545242,31.502252],[-97.57956,31.551735],[-97.694657,31.66546],[-97.766431,31.672461],[-97.962183,31.549229],[-97.938648,31.461894],[-98.170774,31.450507],[-98.071762,31.307797],[-97.903329,31.267225]],[[-97.715857,31.484686],[-97.711441,31.49235],[-97.701072,31.490108],[-97.704448,31.481129],[-97.715857,31.484686]],[[-97.736931,31.475661],[-97.720559,31.474245],[-97.724481,31.473068],[-97.731372,31.473846],[-97.733548,31.476357],[-97.735181,31.473942],[-97.733055,31.460505],[-97.742157,31.462319],[-97.737672,31.473753],[-97.736931,31.475661]]]},"properties":{"id":"76528","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.289795,30.816186],[-97.509483,30.871363],[-97.53518,30.836143],[-97.513067,30.827711],[-97.483262,30.816379],[-97.514125,30.815455],[-97.566033,30.772308],[-97.560137,30.75866],[-97.302585,30.747562],[-97.270605,30.77555],[-97.289795,30.816186]],[[-97.296553,30.78759],[-97.297095,30.797878],[-97.279051,30.788248],[-97.282582,30.78419],[-97.296553,30.78759]]]},"properties":{"id":"76511","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.360799,29.414361],[-98.427214,29.398484],[-98.427644,29.384286],[-98.3365,29.312568],[-98.360799,29.414361]]]},"properties":{"id":"78222","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.55251,29.495588],[-98.50783,29.488547],[-98.507819,29.493808],[-98.515395,29.55416],[-98.53495,29.557268],[-98.549141,29.499824],[-98.55251,29.495588]]]},"properties":{"id":"78213","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.239738,29.625491],[-98.186313,29.501417],[-98.202153,29.636265],[-98.239738,29.625491]]]},"properties":{"id":"78108","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.186313,29.501417],[-98.239738,29.625491],[-98.260737,29.629328],[-98.344726,29.581588],[-98.333123,29.568118],[-98.280432,29.51099],[-98.195543,29.497684],[-98.186313,29.501417]]]},"properties":{"id":"78154","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.39616,29.5209],[-98.431965,29.513402],[-98.430187,29.484786],[-98.428029,29.456401],[-98.377831,29.47779],[-98.357636,29.491437],[-98.394577,29.521868],[-98.39616,29.5209]]]},"properties":{"id":"78218","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.667944,29.292869],[-98.786888,29.318878],[-98.804809,29.282154],[-98.804771,29.266128],[-98.734966,29.22671],[-98.688554,29.236058],[-98.667944,29.292869]]]},"properties":{"id":"78002","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.599694,29.435024],[-98.587823,29.407249],[-98.548904,29.400019],[-98.54412,29.43722],[-98.599694,29.435024]]]},"properties":{"id":"78237","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.493798,29.436174],[-98.481729,29.427639],[-98.47572,29.436544],[-98.473804,29.447166],[-98.472382,29.451691],[-98.472488,29.46149],[-98.493798,29.436174]]]},"properties":{"id":"78215","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.631242,29.511096],[-98.595587,29.487055],[-98.561164,29.529641],[-98.585245,29.549086],[-98.651838,29.544384],[-98.631242,29.511096]]]},"properties":{"id":"78240","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.396294,29.644073],[-98.450039,29.660913],[-98.467884,29.611377],[-98.443169,29.602648],[-98.394851,29.630601],[-98.396294,29.644073]]]},"properties":{"id":"78259","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.602838,29.389664],[-98.626624,29.367031],[-98.604074,29.371309],[-98.601397,29.384834],[-98.602838,29.389664]]],[[[-98.688326,29.384988],[-98.646494,29.350447],[-98.657942,29.394541],[-98.688326,29.384988]]]]},"properties":{"id":"78236","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.47572,29.436544],[-98.442597,29.435356],[-98.441133,29.44089],[-98.473804,29.447166],[-98.47572,29.436544]]]},"properties":{"id":"78208","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.260737,29.629328],[-98.309995,29.716969],[-98.346283,29.726703],[-98.396294,29.644073],[-98.394851,29.630601],[-98.351737,29.59184],[-98.344726,29.581588],[-98.260737,29.629328]]]},"properties":{"id":"78266","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.291407,30.033614],[-98.259241,30.136542],[-98.252327,30.153801],[-98.546022,30.159567],[-98.546045,30.153927],[-98.648806,30.138864],[-98.710953,30.100051],[-98.667725,30.037722],[-98.469404,30.00216],[-98.312958,30.004166],[-98.291407,30.033614]]]},"properties":{"id":"78606","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.197849,32.207797],[-101.176674,32.399095],[-101.175824,32.438967],[-101.174697,32.493291],[-101.174489,32.559998],[-101.174473,32.569494],[-101.255431,32.62061],[-101.197849,32.207797]]]},"properties":{"id":"79511","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.812165,31.738603],[-97.709676,31.804882],[-97.859166,31.815476],[-97.920188,31.759603],[-97.812165,31.738603]]]},"properties":{"id":"76637","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.412527,33.568941],[-94.746096,33.703016],[-94.746925,33.328938],[-94.707458,33.311943],[-94.623423,33.251807],[-94.54546,33.425032],[-94.412527,33.568941]]]},"properties":{"id":"75559","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.900617,33.584963],[-94.826286,33.378808],[-94.808783,33.363636],[-94.746925,33.328938],[-94.746096,33.703016],[-94.791237,33.738896],[-94.900617,33.584963]]]},"properties":{"id":"75554","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.130748,33.454233],[-94.142803,33.433374],[-94.10502,33.432941],[-94.104667,33.443438],[-94.130748,33.454233]]]},"properties":{"id":"75569","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.223826,29.51531],[-95.216422,29.55608],[-95.286506,29.597588],[-95.297409,29.596514],[-95.351907,29.585455],[-95.261697,29.523774],[-95.223826,29.51531]]]},"properties":{"id":"77581","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.352587,30.619776],[-96.313108,30.644332],[-96.338278,30.63065],[-96.352587,30.619776]]]},"properties":{"id":"77840","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.093165,30.225187],[-95.950301,30.236905],[-95.915225,30.253861],[-95.825783,30.44237],[-96.144808,30.510739],[-96.284259,30.446782],[-96.299173,30.379152],[-96.285753,30.364689],[-96.093165,30.225187]]]},"properties":{"id":"77868","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.34281,29.459671],[-103.79504,29.662942],[-103.793416,29.538086],[-103.792703,29.262499],[-103.523947,29.122254],[-103.34281,29.459671]]]},"properties":{"id":"79852","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.795728,31.775511],[-98.877298,31.642306],[-98.822727,31.584889],[-98.668441,31.700514],[-98.72577,31.785394],[-98.795728,31.775511]]]},"properties":{"id":"76890","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.262815,32.080091],[-99.197623,32.01194],[-99.094628,32.035105],[-99.118404,32.124739],[-99.145744,32.217056],[-99.262815,32.080091]]]},"properties":{"id":"76443","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.049886,30.624155],[-97.939554,30.806541],[-97.921476,30.883362],[-98.011854,30.923806],[-98.07846,30.903852],[-98.117241,30.612604],[-98.05479,30.615022],[-98.049886,30.624155]]]},"properties":{"id":"78605","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.352717,30.688353],[-98.117241,30.612604],[-98.07846,30.903852],[-98.44862,30.910089],[-98.421026,30.845025],[-98.404948,30.815745],[-98.408603,30.71309],[-98.352717,30.688353]]]},"properties":{"id":"78611","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.457291,32.080099],[-99.458486,32.514908],[-99.527338,32.514758],[-99.629828,32.429353],[-99.630729,32.228109],[-99.594265,32.080945],[-99.618558,32.070708],[-99.457291,32.080099]]]},"properties":{"id":"79510","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.629828,32.429353],[-99.74172,32.449596],[-99.745958,32.449824],[-99.751131,32.396205],[-99.752083,32.321167],[-99.715455,32.261851],[-99.630729,32.228109],[-99.629828,32.429353]]]},"properties":{"id":"79602","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.75039,26.049086],[-97.74472,26.040773],[-97.72786,26.048294],[-97.74665,26.0548],[-97.75039,26.049086]]]},"properties":{"id":"78567","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.828222,26.051621],[-97.861875,26.084383],[-97.861822,26.069921],[-97.828222,26.051621]]]},"properties":{"id":"78592","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.735578,26.260343],[-97.734157,26.248508],[-97.727968,26.248428],[-97.735578,26.260343]]]},"properties":{"id":"78535","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.700131,32.85036],[-94.587859,32.781361],[-94.430736,32.880091],[-94.482072,32.966707],[-94.626842,32.879501],[-94.700131,32.85036]]]},"properties":{"id":"75630","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043127,32.937721],[-94.082723,32.954631],[-94.043101,32.910083],[-94.043127,32.937721]]]},"properties":{"id":"75565","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.84757,29.87304],[-94.793405,29.832008],[-94.73915,29.887212],[-94.738857,29.887217],[-94.82503,30.21355],[-95.072603,30.103054],[-95.035692,30.005064],[-94.98146,29.884276],[-94.893322,29.866791],[-94.84757,29.87304]]]},"properties":{"id":"77535","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.285864,31.629686],[-95.110246,31.524385],[-95.048549,31.449087],[-94.990697,31.481238],[-94.891503,31.577384],[-94.959754,31.727566],[-94.968341,31.748306],[-95.285864,31.629686]]]},"properties":{"id":"75925","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.978804,33.727526],[-98.196932,33.482872],[-98.260333,33.446258],[-97.993077,33.348883],[-97.891411,33.433958],[-97.693063,33.531111],[-97.68387,33.537104],[-97.772231,33.675041],[-97.886567,33.741025],[-97.978804,33.727526]]]},"properties":{"id":"76230","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.595901,32.999831],[-96.559654,32.947622],[-96.504821,32.921871],[-96.489875,32.981878],[-96.482266,33.044373],[-96.533657,33.078055],[-96.594653,33.040664],[-96.595901,32.999831]]]},"properties":{"id":"75098","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.532396,33.111697],[-96.659682,33.129865],[-96.685506,33.073791],[-96.641021,33.033443],[-96.594653,33.040664],[-96.533657,33.078055],[-96.532396,33.111697]]]},"properties":{"id":"75002","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.60467,33.285286],[-96.512037,33.290623],[-96.579615,33.321058],[-96.60467,33.285286]]]},"properties":{"id":"75454","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.482266,33.044373],[-96.489875,32.981878],[-96.405925,32.986362],[-96.41869,33.021247],[-96.474231,33.043064],[-96.482266,33.044373]]]},"properties":{"id":"75166","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.505552,32.921712],[-96.491817,32.888833],[-96.391568,32.927684],[-96.381087,32.933459],[-96.378695,32.957171],[-96.405925,32.986362],[-96.489875,32.981878],[-96.504821,32.921871],[-96.505552,32.921712]]]},"properties":{"id":"75087","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.380128,35.019774],[-100.113617,35.08402],[-100.000387,35.328146],[-100.431926,35.398054],[-100.538802,35.388332],[-100.485166,35.21207],[-100.450264,35.154472],[-100.395536,35.03275],[-100.380128,35.019774]]]},"properties":{"id":"79079","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.395536,35.03275],[-100.469855,34.834483],[-100.404923,34.834634],[-100.380128,35.019774],[-100.395536,35.03275]]]},"properties":{"id":"79251","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.983035,33.417677],[-96.827179,33.438352],[-96.792698,33.519847],[-96.807483,33.520107],[-96.999484,33.481392],[-96.983035,33.417677]]]},"properties":{"id":"76271","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.47558,31.401975],[-97.474466,31.319234],[-97.461556,31.381893],[-97.47558,31.401975]]],[[[-97.489105,31.421339],[-97.383026,31.397542],[-97.307083,31.413464],[-97.312051,31.418459],[-97.328981,31.544361],[-97.532979,31.484592],[-97.489105,31.421339]]]]},"properties":{"id":"76657","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.737672,31.473753],[-97.742157,31.462319],[-97.733055,31.460505],[-97.735181,31.473942],[-97.737672,31.473753]]]},"properties":{"id":"76599","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.634854,32.843785],[-96.59583,32.833246],[-96.518869,32.832728],[-96.51855,32.867156],[-96.592367,32.894002],[-96.602214,32.894013],[-96.648694,32.855858],[-96.634854,32.843785]]]},"properties":{"id":"75043","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.81799,32.822453],[-96.828903,32.805805],[-96.814921,32.798434],[-96.805843,32.802048],[-96.799205,32.818945],[-96.816246,32.826775],[-96.81799,32.822453]]]},"properties":{"id":"75219","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.63927,32.748607],[-96.73096,32.76601],[-96.738078,32.755437],[-96.734043,32.723626],[-96.713051,32.696408],[-96.693019,32.674487],[-96.656934,32.637464],[-96.637504,32.694308],[-96.63927,32.748607]]]},"properties":{"id":"75217","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.770252,32.880018],[-96.809576,32.880068],[-96.811515,32.865644],[-96.813168,32.851174],[-96.772401,32.849472],[-96.770399,32.865592],[-96.770252,32.880018]]]},"properties":{"id":"75225","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.676303,32.870985],[-96.69188,32.894571],[-96.697977,32.902268],[-96.721868,32.894548],[-96.732682,32.855173],[-96.725103,32.858517],[-96.676303,32.870985]]]},"properties":{"id":"75238","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.971939,32.895636],[-97.032937,32.904296],[-97.033781,32.837135],[-97.022064,32.837137],[-96.949071,32.866743],[-96.971939,32.895636]]]},"properties":{"id":"75038","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.061123,32.706049],[-96.966132,32.706977],[-96.926161,32.749636],[-97.046015,32.739486],[-97.061123,32.706049]]]},"properties":{"id":"75051","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.06317,32.767131],[-97.046015,32.739486],[-96.926161,32.749636],[-96.927414,32.765583],[-96.926077,32.782076],[-96.994569,32.815855],[-97.034085,32.81666],[-97.056617,32.813617],[-97.062681,32.802114],[-97.06317,32.767131]]]},"properties":{"id":"75050","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.008544,33.046325],[-97.044523,33.048934],[-97.028593,32.989314],[-96.942255,32.988798],[-96.939858,32.988822],[-96.940741,33.005606],[-96.951273,33.005598],[-97.008544,33.046325]]]},"properties":{"id":"75067","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.971939,32.895636],[-96.931075,32.90943],[-96.937284,32.921025],[-96.935245,32.937044],[-97.032587,32.955008],[-97.032937,32.904296],[-96.971939,32.895636]]]},"properties":{"id":"75063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.854689,32.98775],[-96.843521,33.016492],[-96.849233,33.025937],[-96.89399,33.02702],[-96.89489,33.02707],[-96.940741,33.005606],[-96.939858,32.988822],[-96.854689,32.98775]]]},"properties":{"id":"75007","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.784058,32.777448],[-96.769781,32.778064],[-96.765309,32.783723],[-96.761652,32.793428],[-96.764208,32.795665],[-96.782507,32.789892],[-96.790999,32.784067],[-96.784058,32.777448]]]},"properties":{"id":"75226","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.207563,32.895725],[-102.138294,32.896735],[-102.041663,32.960008],[-102.067365,32.959784],[-102.079058,32.959676],[-102.147151,32.95932],[-102.208373,32.94407],[-102.207563,32.895725]]]},"properties":{"id":"79377","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.245165,34.971527],[-102.167508,34.92467],[-102.167497,34.956567],[-102.245165,34.971527]]]},"properties":{"id":"79025","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.020112,34.611396],[-102.833731,34.594127],[-102.781024,34.437876],[-102.744483,34.422424],[-102.654716,34.399713],[-102.525767,34.426826],[-102.525266,34.53103],[-102.525123,34.72864],[-102.525184,34.746931],[-103.042714,34.984002],[-103.043266,34.606597],[-103.020112,34.611396]]]},"properties":{"id":"79035","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.70517,33.304622],[-95.828072,33.345477],[-95.859793,33.348596],[-95.859956,33.341859],[-95.760046,33.287258],[-95.70517,33.304622]]]},"properties":{"id":"75448","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.842504,33.05695],[-96.855742,33.081266],[-96.96443,33.100028],[-96.964239,33.083002],[-96.944635,33.026308],[-96.89489,33.02707],[-96.89399,33.02702],[-96.859299,33.048237],[-96.842504,33.05695]]]},"properties":{"id":"75056","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.964239,33.083002],[-97.012013,33.087532],[-97.008544,33.046325],[-96.951273,33.005598],[-96.944635,33.026308],[-96.964239,33.083002]]]},"properties":{"id":"75057","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.392832,33.178361],[-97.26646,33.14165],[-97.178723,33.176081],[-97.215323,33.207286],[-97.389467,33.268249],[-97.392832,33.178361]]]},"properties":{"id":"76259","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.827179,33.438352],[-96.983035,33.417677],[-97.0372,33.358074],[-97.036074,33.357867],[-96.89225,33.288395],[-96.834004,33.405504],[-96.827179,33.438352]]]},"properties":{"id":"76258","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.879441,33.632649],[-100.518541,33.780873],[-100.518691,33.83565],[-100.905728,33.834277],[-100.907658,33.804441],[-100.960929,33.661237],[-100.879441,33.632649]]]},"properties":{"id":"79220","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.778725,28.336232],[-99.90212,28.199748],[-99.821034,28.200323],[-99.614245,28.202091],[-99.529749,28.360836],[-99.519383,28.422275],[-99.64661,28.409624],[-99.778725,28.336232]]]},"properties":{"id":"78836","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.646495,28.500079],[-99.64661,28.409624],[-99.519383,28.422275],[-99.395926,28.426299],[-99.395798,28.622642],[-99.409021,28.640566],[-99.569555,28.642503],[-99.620341,28.573796],[-99.646495,28.500079]]]},"properties":{"id":"78830","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.299908,27.617603],[-98.361817,27.47734],[-98.233131,27.483708],[-97.942146,27.635932],[-97.939125,27.685737],[-97.933703,27.804073],[-97.934039,27.867006],[-98.235173,27.950802],[-98.299908,27.617603]],[[-98.079157,27.644533],[-98.080915,27.67512],[-98.068302,27.675136],[-98.068388,27.646583],[-98.079157,27.644533]]]},"properties":{"id":"78332","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.617205,27.828533],[-98.299908,27.617603],[-98.235173,27.950802],[-98.235497,28.057966],[-98.334323,28.0578],[-98.555153,28.057212],[-98.617205,27.828533]]]},"properties":{"id":"78384","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.760163,32.44498],[-98.718452,32.437644],[-98.721429,32.407163],[-98.628627,32.315324],[-98.537909,32.378054],[-98.614471,32.671329],[-98.760327,32.60986],[-98.843135,32.582056],[-98.8524,32.51523],[-98.760163,32.44498]]]},"properties":{"id":"76470","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.867271,32.288147],[-96.881827,32.231887],[-96.808843,32.239236],[-96.867271,32.288147]]]},"properties":{"id":"76041","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.32771,31.741819],[-106.284291,31.701504],[-106.26767,31.760763],[-106.281921,31.82258],[-106.330248,31.850078],[-106.361254,31.831369],[-106.330527,31.798322],[-106.32771,31.741819]]]},"properties":{"id":"79936","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.473482,31.771973],[-106.505443,31.762216],[-106.499074,31.755056],[-106.467556,31.75921],[-106.473482,31.771973]]]},"properties":{"id":"79901","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.439021,31.820931],[-106.493867,31.863114],[-106.483442,31.803207],[-106.473455,31.776737],[-106.443126,31.801254],[-106.439021,31.820931]],[[-106.451079,31.825386],[-106.450723,31.824918],[-106.452377,31.823987],[-106.452754,31.824501],[-106.451079,31.825386]]]},"properties":{"id":"79930","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.404508,31.819826],[-106.421464,31.808306],[-106.421654,31.826249],[-106.430787,31.832298],[-106.439021,31.820931],[-106.443126,31.801254],[-106.417618,31.789078],[-106.36636,31.828185],[-106.404508,31.819826]]]},"properties":{"id":"79906","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.131252,31.519374],[-106.2335,31.754994],[-106.26767,31.760763],[-106.284291,31.701504],[-106.284117,31.701351],[-106.193551,31.600223],[-106.140002,31.530683],[-106.131252,31.519374]]]},"properties":{"id":"79928","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.451079,31.825386],[-106.452754,31.824501],[-106.452377,31.823987],[-106.450723,31.824918],[-106.451079,31.825386]]]},"properties":{"id":"79920","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.424585,32.373551],[-98.189343,32.079245],[-97.945975,32.122144],[-97.904938,32.158473],[-97.91777,32.181282],[-98.126024,32.441975],[-98.22353,32.512597],[-98.23273,32.512634],[-98.333321,32.430159],[-98.424585,32.373551]],[[-98.218956,32.216796],[-98.215227,32.218659],[-98.212911,32.215291],[-98.216997,32.213943],[-98.218956,32.216796]]]},"properties":{"id":"76401","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.038097,31.391129],[-97.14919,31.315445],[-97.121489,31.254593],[-96.96227,31.275516],[-96.99558,31.309177],[-97.038097,31.391129]]]},"properties":{"id":"76632","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.734637,31.385605],[-96.413251,31.310821],[-96.330635,31.254289],[-96.317205,31.3255],[-96.329518,31.388051],[-96.716289,31.408941],[-96.721962,31.406224],[-96.734637,31.385605]]]},"properties":{"id":"76687","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.277699,33.608807],[-96.265895,33.506866],[-96.258797,33.485004],[-96.214638,33.475529],[-96.200871,33.45564],[-96.169072,33.442684],[-96.115617,33.466491],[-96.088645,33.469279],[-96.059384,33.521263],[-96.056714,33.537668],[-96.086013,33.647986],[-96.083149,33.67473],[-96.14702,33.696874],[-96.276387,33.657812],[-96.277699,33.608807]]]},"properties":{"id":"75418","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.200871,33.45564],[-96.169326,33.42752],[-96.161441,33.430686],[-96.169072,33.442684],[-96.200871,33.45564]]]},"properties":{"id":"75413","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.265895,33.506866],[-96.277699,33.608807],[-96.306572,33.563863],[-96.265895,33.506866]]]},"properties":{"id":"75439","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.976563,29.664989],[-95.968454,29.608822],[-95.933043,29.591324],[-95.886874,29.592953],[-95.864574,29.693168],[-95.901366,29.742121],[-95.988706,29.724896],[-95.978254,29.689206],[-95.957787,29.688156],[-95.976563,29.664989]]]},"properties":{"id":"77441","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.041399,29.789288],[-95.990769,29.812546],[-96.036276,29.771743],[-96.032711,29.727944],[-95.988706,29.724896],[-95.901366,29.742121],[-95.8826,29.786303],[-95.868966,29.908828],[-96.031444,29.940625],[-96.113319,29.915301],[-96.112353,29.889406],[-96.041399,29.789288]]]},"properties":{"id":"77423","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.534012,29.639819],[-95.541614,29.585491],[-95.505431,29.565054],[-95.504924,29.567618],[-95.476704,29.604968],[-95.518385,29.626588],[-95.534012,29.639819]]]},"properties":{"id":"77489","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.679968,29.67675],[-95.683419,29.601611],[-95.633823,29.620143],[-95.619202,29.661986],[-95.616075,29.670411],[-95.679968,29.67675]]]},"properties":{"id":"77498","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.332297,31.762253],[-96.330645,31.766448],[-96.332269,31.771854],[-96.332297,31.762253]]]},"properties":{"id":"75848","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.66383,33.021368],[-102.726149,32.842232],[-102.536875,32.813129],[-102.468062,32.875388],[-102.40502,32.958716],[-102.43417,33.043751],[-102.543515,33.036427],[-102.594567,33.096808],[-102.663436,33.109719],[-102.66383,33.021368]]]},"properties":{"id":"79359","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.114687,29.429626],[-95.131855,29.314012],[-95.103356,29.271411],[-95.050486,29.391458],[-95.041699,29.411575],[-95.04917,29.419155],[-95.114687,29.429626]]]},"properties":{"id":"77510","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.930844,29.337525],[-94.951834,29.379156],[-95.041699,29.411575],[-95.050486,29.391458],[-94.931451,29.329288],[-94.907075,29.308666],[-94.930844,29.337525]]]},"properties":{"id":"77568","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.236692,28.696852],[-97.182359,28.610602],[-97.172385,28.69113],[-97.236692,28.696852]]]},"properties":{"id":"77960","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.086017,35.67834],[-101.085989,35.532067],[-101.085956,35.488713],[-101.085974,35.429806],[-100.913221,35.256217],[-100.842698,35.398362],[-100.72802,35.488725],[-100.61065,35.403492],[-100.538889,35.404114],[-100.539517,35.532592],[-100.575563,35.546542],[-100.929777,35.708447],[-101.086146,35.742717],[-101.086017,35.67834]]]},"properties":{"id":"79065","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.929777,35.708447],[-100.575563,35.546542],[-100.540101,35.601236],[-100.540158,35.619296],[-100.541005,35.719682],[-100.539973,35.997839],[-100.679327,36.057046],[-101.036979,36.057887],[-101.085735,36.055276],[-101.085954,35.942304],[-101.085921,35.915805],[-100.929777,35.708447]]]},"properties":{"id":"79059","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.834004,33.405504],[-96.656486,33.401331],[-96.627186,33.465587],[-96.792698,33.519847],[-96.827179,33.438352],[-96.834004,33.405504]]]},"properties":{"id":"75058","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.489784,33.575275],[-96.382584,33.562796],[-96.37991,33.703327],[-96.47339,33.676979],[-96.489784,33.575275]]]},"properties":{"id":"75414","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.847368,33.67287],[-96.779225,33.733959],[-96.799912,33.784852],[-96.884505,33.783181],[-96.847368,33.67287]]]},"properties":{"id":"76264","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.760648,32.525193],[-94.668196,32.503806],[-94.609261,32.510897],[-94.637602,32.638971],[-94.745629,32.667507],[-94.819323,32.591641],[-94.760648,32.525193]]]},"properties":{"id":"75605","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.821646,30.867767],[-96.013432,30.842661],[-96.027946,30.66139],[-95.944074,30.584537],[-95.924446,30.591991],[-95.886291,30.614777],[-95.849457,30.620022],[-95.764177,30.636594],[-95.763474,30.679953],[-95.821646,30.867767]]]},"properties":{"id":"77831","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.839352,30.238044],[-95.804097,30.22646],[-95.804306,30.24557],[-95.804707,30.342618],[-95.813355,30.448462],[-95.825783,30.44237],[-95.915225,30.253861],[-95.839352,30.238044]]]},"properties":{"id":"77363","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.094442,29.252193],[-98.004112,29.299293],[-98.001958,29.378877],[-98.121075,29.449048],[-98.138033,29.439391],[-98.18863,29.377907],[-98.195094,29.271278],[-98.094442,29.252193]]]},"properties":{"id":"78121","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.814341,29.784747],[-97.823411,29.75118],[-97.800194,29.765932],[-97.814341,29.784747]]]},"properties":{"id":"78670","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.04067,34.159268],[-101.731503,33.977694],[-101.730857,33.963144],[-101.564464,34.021314],[-101.564856,34.312493],[-101.591488,34.3126],[-101.662751,34.312798],[-101.865235,34.289773],[-101.987978,34.261416],[-102.04067,34.159268]]]},"properties":{"id":"79072","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.054876,33.948565],[-102.086791,33.924961],[-102.085912,33.850701],[-102.055714,33.824959],[-101.957364,33.78077],[-101.854021,33.795299],[-101.776534,33.781257],[-101.693056,33.829186],[-101.766252,33.919788],[-101.782859,33.919753],[-102.009905,33.913774],[-102.054876,33.948565]]]},"properties":{"id":"79311","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.093868,29.864805],[-95.002802,29.842386],[-94.966712,29.858391],[-94.98146,29.884276],[-95.035692,30.005064],[-95.130156,29.991986],[-95.131541,29.987667],[-95.095375,29.880235],[-95.093868,29.864805]]]},"properties":{"id":"77532","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.311081,30.15974],[-95.16216,30.084188],[-95.155006,30.082097],[-95.086027,30.139272],[-95.097823,30.170616],[-95.243085,30.22994],[-95.272326,30.203057],[-95.311081,30.15974]]]},"properties":{"id":"77357","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.293669,29.74722],[-95.328867,29.759838],[-95.333109,29.743215],[-95.294899,29.727945],[-95.282016,29.741549],[-95.293669,29.74722]]]},"properties":{"id":"77011","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.294899,29.727945],[-95.290279,29.716811],[-95.288732,29.697449],[-95.255267,29.712771],[-95.243021,29.719087],[-95.256613,29.726853],[-95.282016,29.741549],[-95.294899,29.727945]]]},"properties":{"id":"77012","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.357715,29.754824],[-95.347678,29.766512],[-95.352129,29.772125],[-95.366719,29.77393],[-95.362277,29.765225],[-95.366568,29.764648],[-95.366164,29.767392],[-95.373205,29.76116],[-95.378753,29.752093],[-95.384544,29.732243],[-95.367436,29.744123],[-95.361526,29.751601],[-95.363492,29.753891],[-95.361144,29.756885],[-95.357715,29.754824]]]},"properties":{"id":"77002","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.257285,29.882542],[-95.30659,29.891185],[-95.335349,29.819891],[-95.329394,29.819884],[-95.289038,29.851746],[-95.257285,29.882542]]]},"properties":{"id":"77016","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.40297,29.841162],[-95.391689,29.814064],[-95.391618,29.813876],[-95.351691,29.812858],[-95.352373,29.819732],[-95.360334,29.851757],[-95.403014,29.846522],[-95.40297,29.841162]]]},"properties":{"id":"77022","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.402073,29.72665],[-95.390253,29.726079],[-95.384544,29.732243],[-95.378753,29.752093],[-95.402086,29.743765],[-95.402073,29.72665]]]},"properties":{"id":"77006","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.225191,29.746676],[-95.256613,29.726853],[-95.243021,29.719087],[-95.240725,29.719336],[-95.225191,29.746676]]]},"properties":{"id":"77547","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.486772,29.865752],[-95.506297,29.834805],[-95.50472,29.833667],[-95.493681,29.825762],[-95.450525,29.794468],[-95.440882,29.808874],[-95.461006,29.841009],[-95.486772,29.865752]]]},"properties":{"id":"77092","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.379616,29.932788],[-95.307032,29.918431],[-95.299539,29.97282],[-95.370443,29.998717],[-95.388369,29.965973],[-95.379616,29.932788]]]},"properties":{"id":"77032","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.559727,29.668053],[-95.499288,29.716841],[-95.491836,29.72343],[-95.501044,29.722678],[-95.539059,29.719588],[-95.55752,29.718078],[-95.558012,29.684649],[-95.559727,29.668053]]]},"properties":{"id":"77036","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.332917,29.714372],[-95.38212,29.713013],[-95.386583,29.700145],[-95.381778,29.675439],[-95.363205,29.680767],[-95.326944,29.695307],[-95.326664,29.695466],[-95.332917,29.714372]]]},"properties":{"id":"77021","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.251593,29.652503],[-95.272673,29.680346],[-95.314894,29.654952],[-95.308757,29.634591],[-95.253757,29.652167],[-95.251593,29.652503]]]},"properties":{"id":"77061","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.357715,29.754824],[-95.361144,29.756885],[-95.363492,29.753891],[-95.361526,29.751601],[-95.357715,29.754824]]]},"properties":{"id":"77010","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.589615,29.759794],[-95.576165,29.736616],[-95.571445,29.716958],[-95.55752,29.718078],[-95.539059,29.719588],[-95.545305,29.752995],[-95.568921,29.761943],[-95.589615,29.759794]]]},"properties":{"id":"77042","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.536895,29.642253],[-95.529793,29.67336],[-95.559121,29.663425],[-95.571273,29.648707],[-95.536895,29.642253]]]},"properties":{"id":"77031","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.217503,32.546404],[-94.042947,32.574683],[-94.043147,32.69303],[-94.295635,32.672584],[-94.217503,32.546404]]]},"properties":{"id":"75661","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.485804,35.772106],[-102.588645,35.754813],[-102.763671,35.69571],[-102.712956,35.555638],[-102.679141,35.519666],[-102.018382,35.690701],[-102.015621,35.690689],[-102.163014,35.764969],[-102.1669,35.806002],[-102.485804,35.772106]]]},"properties":{"id":"79018","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.739928,33.510228],[-99.944781,33.496482],[-99.991239,33.41621],[-99.99098,33.397404],[-99.763031,33.39829],[-99.735792,33.362571],[-99.702458,33.398446],[-99.739928,33.510228]]]},"properties":{"id":"79529","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.945384,32.272445],[-95.788508,32.037349],[-95.759523,32.041034],[-95.722138,32.04588],[-95.692252,32.215316],[-95.777287,32.356305],[-95.81274,32.416933],[-95.862526,32.406881],[-95.945384,32.272445]]]},"properties":{"id":"75752","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.777287,32.356305],[-95.692252,32.215316],[-95.622993,32.213992],[-95.67183,32.359546],[-95.777287,32.356305]]]},"properties":{"id":"75778","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.58388,32.218579],[-95.517894,32.177594],[-95.472914,32.145077],[-95.453913,32.307108],[-95.456129,32.325896],[-95.473561,32.374066],[-95.58891,32.368367],[-95.58388,32.218579]]]},"properties":{"id":"75758","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.197822,26.235991],[-98.271953,26.244684],[-98.273194,26.237432],[-98.275612,26.223062],[-98.263695,26.19461],[-98.204311,26.19706],[-98.197822,26.235991]]]},"properties":{"id":"78501","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.930253,26.087188],[-97.950087,26.061925],[-97.919678,26.05797],[-97.930253,26.087188]]]},"properties":{"id":"78579","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.084974,26.235103],[-98.129986,26.074271],[-98.03447,26.051167],[-98.009028,26.278611],[-98.012257,26.293095],[-98.037686,26.288989],[-98.084974,26.235103]]]},"properties":{"id":"78537","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.922991,26.356649],[-97.944684,26.286872],[-97.861775,26.230051],[-97.861732,26.234796],[-97.861924,26.316484],[-97.922991,26.356649]]]},"properties":{"id":"78562","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.542558,26.344041],[-98.582701,26.265383],[-98.540521,26.253704],[-98.516745,26.286431],[-98.542558,26.344041]]]},"properties":{"id":"78595","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.907908,31.843012],[-96.80842,31.743982],[-96.69869,31.813191],[-96.750269,31.951552],[-96.828244,31.973545],[-96.831597,31.947822],[-96.886156,31.870389],[-96.907908,31.843012]]]},"properties":{"id":"76648","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.297196,32.059307],[-97.486211,32.032528],[-97.486817,32.025486],[-97.408547,31.908039],[-97.28206,31.843152],[-97.202591,31.898556],[-97.297196,32.059307]]]},"properties":{"id":"76692","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.608685,33.680132],[-102.603101,33.568671],[-102.594836,33.388489],[-102.543775,33.38853],[-102.524818,33.43203],[-102.452824,33.463207],[-102.393119,33.388615],[-102.182741,33.499638],[-102.181772,33.560438],[-102.196978,33.689967],[-102.244512,33.761554],[-102.309213,33.738993],[-102.328011,33.723919],[-102.38317,33.736431],[-102.59571,33.825097],[-102.615447,33.825121],[-102.61483,33.810823],[-102.540087,33.787011],[-102.612245,33.757045],[-102.610359,33.714192],[-102.608685,33.680132]]]},"properties":{"id":"79336","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.452824,33.463207],[-102.524818,33.43203],[-102.470039,33.395618],[-102.452824,33.463207]]]},"properties":{"id":"79372","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.085733,33.824675],[-102.174419,33.689753],[-102.174873,33.653171],[-102.061672,33.606636],[-101.93952,33.644263],[-101.957364,33.78077],[-102.055714,33.824959],[-102.085733,33.824675]]]},"properties":{"id":"79363","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.846529,32.503283],[-97.70361,32.370365],[-97.604823,32.355924],[-97.616248,32.479928],[-97.724928,32.545622],[-97.846529,32.503283]]]},"properties":{"id":"76049","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.78882,32.3134],[-97.923951,32.460643],[-98.033972,32.432651],[-97.868907,32.272506],[-97.78882,32.3134]]]},"properties":{"id":"76476","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.861778,33.21933],[-95.862914,33.088727],[-95.858689,32.979534],[-95.770141,32.98838],[-95.732642,33.126026],[-95.796727,33.253334],[-95.861778,33.21933]]]},"properties":{"id":"75433","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.483614,31.397773],[-95.676377,31.36883],[-95.76441,31.094211],[-95.648538,31.070005],[-95.322976,31.188148],[-95.302884,31.221346],[-95.198652,31.450804],[-95.475255,31.432848],[-95.474315,31.402339],[-95.483614,31.397773]]]},"properties":{"id":"75835","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.139942,31.391697],[-95.1333,31.384917],[-95.11917,31.393898],[-95.139942,31.391697]]]},"properties":{"id":"75858","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.157282,30.16226],[-94.145185,30.117224],[-94.136313,30.092563],[-94.120514,30.096035],[-94.091038,30.094736],[-94.115917,30.160347],[-94.154613,30.169949],[-94.157282,30.16226]]]},"properties":{"id":"77703","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.720282,32.678278],[-99.971658,32.645975],[-99.847646,32.539993],[-99.720282,32.678278]]]},"properties":{"id":"79525","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.458505,32.522015],[-96.30749,32.480286],[-96.194588,32.491726],[-96.075708,32.547542],[-96.075799,32.563623],[-96.141278,32.658534],[-96.26549,32.660535],[-96.382637,32.619685],[-96.458505,32.522015]]]},"properties":{"id":"75142","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.076619,32.823653],[-96.187079,32.84039],[-96.26549,32.660535],[-96.141278,32.658534],[-96.076619,32.823653]]]},"properties":{"id":"75161","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.380833,32.813744],[-96.487187,32.813262],[-96.519067,32.804647],[-96.519794,32.754534],[-96.520969,32.693765],[-96.488664,32.653225],[-96.403961,32.670149],[-96.380833,32.813744]]]},"properties":{"id":"75126","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.840678,27.558358],[-97.840327,27.315769],[-97.702626,27.375927],[-97.831426,27.558402],[-97.840678,27.558358]]]},"properties":{"id":"78363","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.334575,33.805712],[-95.404838,33.589756],[-95.347837,33.585331],[-95.310457,33.540534],[-95.334575,33.805712]]]},"properties":{"id":"75416","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.061815,29.334841],[-97.065944,29.376124],[-97.08907,29.357975],[-97.061815,29.334841]]]},"properties":{"id":"77987","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.278407,31.117083],[-96.24102,30.973737],[-96.204498,30.924248],[-96.077285,30.952223],[-95.988775,31.086167],[-96.029949,31.188854],[-96.097153,31.214575],[-96.090683,31.184763],[-96.107864,31.126311],[-96.201408,31.115383],[-96.278407,31.117083]]]},"properties":{"id":"77871","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.819748,30.491966],[-94.802506,30.42392],[-94.809146,30.455551],[-94.801135,30.490689],[-94.805911,30.49102],[-94.818983,30.491921],[-94.819748,30.491966]]]},"properties":{"id":"77368","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.421026,30.845025],[-98.533328,30.833699],[-98.504337,30.796797],[-98.404948,30.815745],[-98.421026,30.845025]]]},"properties":{"id":"78607","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.964024,33.390825],[-101.648274,33.232815],[-101.573516,33.330815],[-101.790139,33.392888],[-101.877001,33.39167],[-101.964024,33.390825]]]},"properties":{"id":"79381","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.075956,33.374246],[-102.11845,33.389429],[-102.393119,33.388615],[-102.543775,33.38853],[-102.594836,33.388489],[-102.534105,33.270494],[-102.171297,33.2901],[-102.075921,33.294094],[-102.075956,33.374246]]]},"properties":{"id":"79345","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.091804,31.133643],[-99.027427,31.479951],[-99.04287,31.488903],[-99.202768,31.467234],[-99.308231,31.415206],[-99.306142,31.327857],[-99.133386,31.104637],[-99.091804,31.133643]]]},"properties":{"id":"76872","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.133386,31.104637],[-99.187577,30.941066],[-99.092049,31.045715],[-99.09198,31.072872],[-99.091804,31.133643],[-99.133386,31.104637]]]},"properties":{"id":"76887","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.409843,31.462006],[-99.511477,31.424401],[-99.602505,31.392843],[-99.602078,31.30342],[-99.306142,31.327857],[-99.308231,31.415206],[-99.374928,31.439288],[-99.409843,31.462006]]]},"properties":{"id":"76852","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.12337,31.559257],[-97.102892,31.551761],[-97.141349,31.596335],[-97.145721,31.575191],[-97.133934,31.564911],[-97.12337,31.559257]]]},"properties":{"id":"76704","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.312051,31.418459],[-97.21334,31.455174],[-97.168282,31.46431],[-97.151315,31.49245],[-97.176119,31.503632],[-97.216246,31.575042],[-97.276357,31.602963],[-97.294133,31.600807],[-97.328981,31.544361],[-97.312051,31.418459]]]},"properties":{"id":"76712","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.145721,31.575191],[-97.185854,31.547887],[-97.153192,31.543004],[-97.133934,31.564911],[-97.145721,31.575191]]]},"properties":{"id":"76707","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.988775,31.086167],[-96.077285,30.952223],[-96.013432,30.842661],[-95.821646,30.867767],[-95.766221,30.894155],[-95.927762,31.092562],[-95.988775,31.086167]]]},"properties":{"id":"77864","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.719942,32.904502],[-94.835605,32.861718],[-94.823047,32.823995],[-94.747948,32.740076],[-94.590265,32.776471],[-94.587859,32.781361],[-94.700131,32.85036],[-94.719942,32.904502]]]},"properties":{"id":"75683","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.903183,32.26391],[-101.92109,32.38734],[-101.924683,32.398308],[-101.903183,32.26391]]]},"properties":{"id":"79749","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.073432,29.088058],[-96.119129,29.063916],[-96.149189,29.047931],[-96.174601,29.034411],[-96.105589,28.924223],[-96.060785,28.886419],[-96.073432,29.088058]]]},"properties":{"id":"77456","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.207276,29.017108],[-96.217931,28.921883],[-96.182057,28.885932],[-96.177143,28.887471],[-96.131522,28.907191],[-96.105589,28.924223],[-96.174601,29.034411],[-96.18505,29.02888],[-96.207276,29.017108]]],[[[-96.073432,29.088058],[-96.060785,28.886419],[-96.069865,28.786599],[-96.016946,28.748611],[-95.972015,28.720698],[-95.914206,28.71081],[-95.892737,28.716407],[-95.865846,28.763025],[-95.766958,28.749125],[-95.506993,28.824493],[-95.702192,28.969311],[-95.866112,29.003136],[-95.951538,29.101312],[-95.979926,29.138643],[-96.038643,29.106552],[-96.073432,29.088058]],[[-95.736053,28.938409],[-95.721834,28.954748],[-95.715316,28.946377],[-95.736053,28.938409]]]]},"properties":{"id":"77414","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.835332,29.510708],[-98.946442,29.442068],[-98.923306,29.404591],[-98.883782,29.440004],[-98.835332,29.510708]]]},"properties":{"id":"78066","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.187405,30.984922],[-100.116234,30.710366],[-100.018558,30.710453],[-100.068873,31.087766],[-100.115216,31.087994],[-100.190228,31.088436],[-100.206615,31.008558],[-100.187405,30.984922]]]},"properties":{"id":"76841","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.68387,33.537104],[-97.647003,33.58643],[-97.66321,33.674338],[-97.772231,33.675041],[-97.68387,33.537104]]]},"properties":{"id":"76251","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.486692,30.391247],[-95.263338,30.384259],[-95.269829,30.526027],[-95.491265,30.508833],[-95.486692,30.391247]]]},"properties":{"id":"77378","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.583173,30.351645],[-95.804707,30.342618],[-95.804306,30.24557],[-95.544979,30.258085],[-95.493642,30.267896],[-95.583173,30.351645]]]},"properties":{"id":"77316","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.489672,31.398929],[-94.35709,31.246889],[-94.326616,31.224754],[-94.327501,31.382733],[-94.429145,31.419591],[-94.489672,31.398929]]]},"properties":{"id":"75944","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.304277,31.504403],[-94.429145,31.419591],[-94.327501,31.382733],[-94.304277,31.504403]]]},"properties":{"id":"75937","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.896209,32.073977],[-96.857387,32.016379],[-96.828244,31.973545],[-96.750269,31.951552],[-96.726231,31.954839],[-96.718451,32.00674],[-96.720507,32.04439],[-96.771568,32.135703],[-96.874044,32.084917],[-96.896209,32.073977]]]},"properties":{"id":"76641","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.378796,32.19999],[-96.321725,32.090358],[-96.199298,31.974034],[-96.120491,31.980805],[-96.05478,32.012526],[-96.069346,32.085346],[-96.150479,32.214127],[-96.28561,32.266895],[-96.378796,32.19999]]]},"properties":{"id":"75144","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.414425,27.797939],[-97.475656,27.804312],[-97.484472,27.804981],[-97.469631,27.78567],[-97.461236,27.785737],[-97.409409,27.789022],[-97.414425,27.797939]]]},"properties":{"id":"78408","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.39759,27.753614],[-97.404442,27.712303],[-97.375878,27.698266],[-97.374578,27.697628],[-97.364804,27.737864],[-97.383594,27.766436],[-97.39759,27.753614]]]},"properties":{"id":"78411","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.964007,36.289946],[-101.002277,36.331306],[-100.999854,36.289863],[-100.964007,36.289946]]]},"properties":{"id":"79033","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.064369,32.637236],[-98.063363,32.678113],[-98.172597,32.702819],[-98.270273,32.644021],[-98.23273,32.512634],[-98.22353,32.512597],[-98.064369,32.637236]]]},"properties":{"id":"76472","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.48043,32.038892],[-94.266678,32.066885],[-94.131552,32.002508],[-94.041849,31.997335],[-94.042567,32.185042],[-94.30886,32.228321],[-94.582581,32.14948],[-94.589438,32.12882],[-94.596679,32.102391],[-94.48043,32.038892]]]},"properties":{"id":"75633","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.695461,30.143974],[-102.175111,30.010612],[-102.064873,30.162548],[-102.016149,30.489891],[-102.05482,30.498196],[-102.440233,30.283859],[-102.524082,30.283645],[-102.799425,30.217247],[-102.695461,30.143974]]]},"properties":{"id":"79848","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.95643,30.934669],[-94.791386,30.901114],[-94.739625,30.916317],[-94.95643,30.934669]]]},"properties":{"id":"75960","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.704628,30.899495],[-94.739625,30.916317],[-94.791386,30.901114],[-94.704628,30.899495]]]},"properties":{"id":"75934","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.887049,31.09862],[-95.035242,30.962916],[-94.95643,30.934669],[-94.739625,30.916317],[-94.704628,30.899495],[-94.68799,30.90117],[-94.561943,31.058952],[-94.605193,31.077669],[-94.689117,31.097795],[-94.842947,31.146578],[-94.887049,31.09862]]]},"properties":{"id":"75939","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.932193,35.187246],[-101.866622,35.228573],[-101.848859,35.250879],[-101.965868,35.344589],[-102.005428,35.35509],[-102.050333,35.275582],[-102.046299,35.183179],[-101.941564,35.182969],[-101.932193,35.187246]]]},"properties":{"id":"79124","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.745329,29.625228],[-99.61947,29.709761],[-99.603318,29.740258],[-99.602769,29.888101],[-99.694712,29.943492],[-99.846068,29.77108],[-99.890935,29.624282],[-99.794475,29.624558],[-99.745329,29.625228]]]},"properties":{"id":"78873","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.880498,31.399361],[-104.009822,31.387101],[-104.006931,31.274858],[-103.806948,31.262571],[-103.732244,31.187819],[-103.880498,31.399361]]]},"properties":{"id":"79785","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.378695,32.957171],[-96.381087,32.933459],[-96.366809,32.945483],[-96.378695,32.957171]]]},"properties":{"id":"75132","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.17505,31.475077],[-100.112486,31.444325],[-100.111501,31.551682],[-100.075207,31.652745],[-100.144128,31.698846],[-100.236094,31.726617],[-100.298624,31.692694],[-100.17505,31.475077]]]},"properties":{"id":"76861","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.235288,32.057591],[-100.174707,31.937249],[-100.021741,32.082206],[-100.004023,32.197603],[-100.038693,32.267038],[-100.149547,32.281181],[-100.150064,32.223641],[-100.235288,32.057591]]]},"properties":{"id":"79566","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.268895,27.926462],[-97.304544,27.929542],[-97.280673,27.916797],[-97.268895,27.926462]]]},"properties":{"id":"78359","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.239388,27.857775],[-97.188173,27.824249],[-97.233476,27.89176],[-97.239388,27.857775]]]},"properties":{"id":"78362","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.92991,30.921139],[-98.870281,30.921097],[-98.929987,30.951437],[-98.934801,30.936226],[-98.92991,30.921139]]],[[[-99.016615,31.050004],[-98.960683,31.043853],[-98.497473,30.988053],[-98.458341,31.005905],[-98.509981,31.106926],[-98.57981,31.265386],[-98.846241,31.410478],[-99.09198,31.072872],[-99.092049,31.045715],[-99.016615,31.050004]]]]},"properties":{"id":"76877","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.682838,31.225261],[-100.593252,31.086989],[-100.50758,31.087552],[-100.402475,31.21964],[-100.487577,31.3137],[-100.504014,31.248179],[-100.623903,31.260573],[-100.682838,31.225261]]],[[[-100.203978,30.941488],[-100.206615,31.008558],[-100.190228,31.088436],[-100.384223,31.0881],[-100.448832,31.087906],[-100.203978,30.941488]]]]},"properties":{"id":"76935","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.261277,32.275038],[-95.252316,32.150461],[-95.231829,32.140071],[-95.16995,32.218489],[-95.261277,32.275038]]]},"properties":{"id":"75791","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.34595,32.609291],[-95.580803,32.569904],[-95.594325,32.496568],[-95.554859,32.454107],[-95.431137,32.468944],[-95.211458,32.545631],[-95.206741,32.559773],[-95.34595,32.609291]]]},"properties":{"id":"75771","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.270105,32.761006],[-95.534922,32.768895],[-95.615599,32.707879],[-95.594185,32.564816],[-95.580803,32.569904],[-95.34595,32.609291],[-95.203139,32.731513],[-95.270105,32.761006]]]},"properties":{"id":"75773","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.058415,26.644189],[-99.169416,26.571682],[-99.167367,26.559382],[-99.108364,26.546681],[-99.058415,26.644189]]]},"properties":{"id":"78545","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.265974,31.830328],[-101.267122,31.650854],[-101.078027,31.559357],[-100.863778,31.615601],[-100.82537,31.696155],[-100.833761,31.954441],[-101.01892,32.08673],[-101.035263,32.086734],[-101.264714,32.036985],[-101.264976,31.988211],[-101.265974,31.830328]]]},"properties":{"id":"76951","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.149495,32.674394],[-97.223359,32.667957],[-97.189004,32.646212],[-97.115545,32.644714],[-97.115085,32.678252],[-97.149495,32.674394]]]},"properties":{"id":"76017","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.306545,32.669587],[-97.35259,32.667257],[-97.355057,32.634811],[-97.355082,32.608198],[-97.331328,32.614019],[-97.306545,32.669587]]]},"properties":{"id":"76134","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.167792,32.860532],[-97.109051,32.866571],[-97.099803,32.881352],[-97.129382,32.913671],[-97.176724,32.922513],[-97.187986,32.911661],[-97.18596,32.889243],[-97.167792,32.860532]]]},"properties":{"id":"76034","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.155763,32.823439],[-97.122839,32.837674],[-97.159453,32.840031],[-97.166186,32.839555],[-97.155763,32.823439]]]},"properties":{"id":"76022","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.179713,32.731139],[-97.14011,32.788387],[-97.140166,32.793901],[-97.21267,32.781365],[-97.18242,32.730759],[-97.179713,32.731139]]]},"properties":{"id":"76120","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.364918,32.707053],[-97.360475,32.707076],[-97.360436,32.711713],[-97.365432,32.711675],[-97.364918,32.707053]]]},"properties":{"id":"76129","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.952606,33.16713],[-99.197979,32.956833],[-99.096016,32.957037],[-98.951665,33.055525],[-98.952606,33.16713]]]},"properties":{"id":"76491","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.409377,31.441238],[-100.409588,31.4237],[-100.406086,31.423626],[-100.409377,31.441238]]]},"properties":{"id":"76908","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.638453,30.403591],[-97.671594,30.424418],[-97.671862,30.424528],[-97.678176,30.407802],[-97.712439,30.347805],[-97.686345,30.332598],[-97.638453,30.403591]]]},"properties":{"id":"78753","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.638453,30.403591],[-97.686345,30.332598],[-97.673329,30.325684],[-97.601778,30.336577],[-97.635098,30.401969],[-97.638453,30.403591]]]},"properties":{"id":"78754","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.727922,30.329055],[-97.749749,30.334495],[-97.748003,30.307218],[-97.737571,30.313464],[-97.726336,30.325943],[-97.727922,30.329055]]]},"properties":{"id":"78756","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.738107,30.280688],[-97.727401,30.283078],[-97.72013,30.294284],[-97.737571,30.313464],[-97.748003,30.307218],[-97.75091,30.279995],[-97.738107,30.280688]]]},"properties":{"id":"78705","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.864235,30.299473],[-97.836362,30.324636],[-97.87987,30.344291],[-97.90749,30.333131],[-97.904676,30.303719],[-97.864235,30.299473]]]},"properties":{"id":"78733","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.693192,30.07569],[-97.655257,30.072907],[-97.646222,30.071034],[-97.684407,30.129505],[-97.693192,30.07569]]],[[[-97.680805,30.135111],[-97.669026,30.218525],[-97.676704,30.222237],[-97.683288,30.22229],[-97.699872,30.145528],[-97.680805,30.135111]]]]},"properties":{"id":"78719","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.844422,30.393782],[-97.826451,30.455687],[-97.857931,30.448178],[-97.873045,30.438268],[-97.854068,30.403691],[-97.844422,30.393782]]]},"properties":{"id":"78726","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.87987,30.344291],[-97.836362,30.324636],[-97.796919,30.350015],[-97.791352,30.359289],[-97.844422,30.393782],[-97.854068,30.403691],[-97.87987,30.344291]]]},"properties":{"id":"78730","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.593392,30.49723],[-97.527116,30.474646],[-97.501448,30.477578],[-97.490343,30.626949],[-97.609439,30.571847],[-97.60125,30.533533],[-97.593392,30.49723]]]},"properties":{"id":"78634","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.234253,30.61416],[-94.083343,30.593577],[-94.128272,30.745705],[-94.19193,30.698576],[-94.234253,30.61416]]]},"properties":{"id":"77660","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.636718,29.626676],[-99.69453,29.580066],[-99.678343,29.570868],[-99.636718,29.626676]]],[[[-99.41314,29.177891],[-99.484065,29.130809],[-99.413862,29.093096],[-99.41314,29.177891]]],[[[-100.112049,29.338818],[-99.91636,29.150169],[-99.892036,29.138334],[-99.822256,29.147609],[-99.815946,29.160548],[-99.810139,29.170333],[-99.779219,29.133892],[-99.731559,29.089252],[-99.55112,29.090378],[-99.579166,29.198302],[-99.657391,29.189065],[-99.692906,29.225469],[-99.682115,29.235033],[-99.681948,29.235356],[-99.681212,29.236674],[-99.644369,29.413677],[-99.664989,29.444994],[-99.794475,29.624558],[-99.890935,29.624282],[-100.112098,29.623263],[-100.112049,29.338818]]]]},"properties":{"id":"78801","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.794475,29.624558],[-99.664989,29.444994],[-99.713333,29.536448],[-99.718111,29.577411],[-99.725412,29.577199],[-99.745329,29.625228],[-99.794475,29.624558]]]},"properties":{"id":"78838","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.534933,29.261283],[-99.644369,29.413677],[-99.681212,29.236674],[-99.674121,29.220956],[-99.657391,29.189065],[-99.579166,29.198302],[-99.534933,29.261283]]]},"properties":{"id":"78870","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.847487,28.63684],[-96.946345,28.606868],[-96.930367,28.586728],[-96.913421,28.597456],[-96.847487,28.63684]]]},"properties":{"id":"77951","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.398753,30.86595],[-95.407336,30.856306],[-95.398659,30.851633],[-95.389204,30.856107],[-95.398753,30.86595]]]},"properties":{"id":"77367","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.389197,31.426751],[-103.415029,31.484954],[-103.449613,31.462237],[-103.389197,31.426751]]]},"properties":{"id":"79719","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.197428,27.472218],[-99.478921,27.489272],[-99.480219,27.485796],[-99.453794,27.265058],[-99.354862,27.29961],[-99.197428,27.472218]]]},"properties":{"id":"78046","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.25328,29.563675],[-96.198732,29.468084],[-96.172271,29.519058],[-96.25328,29.563675]]]},"properties":{"id":"77454","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.197676,29.351612],[-96.194607,29.343907],[-96.190899,29.347598],[-96.197676,29.351612]]]},"properties":{"id":"77443","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.798795,30.472983],[-97.79526,30.465615],[-97.721149,30.455835],[-97.704685,30.478481],[-97.745444,30.51918],[-97.798795,30.472983]]]},"properties":{"id":"78717","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.001958,29.378877],[-98.004112,29.299293],[-98.068436,29.265336],[-97.969705,29.126482],[-97.880642,29.110146],[-97.8088,29.162799],[-97.8406,29.257375],[-97.875041,29.259644],[-97.862446,29.271028],[-97.88688,29.377237],[-98.001958,29.378877]]]},"properties":{"id":"78160","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.289896,31.792177],[-103.152471,31.78439],[-103.167711,31.825397],[-103.289896,31.792177]]]},"properties":{"id":"79789","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.794665,33.381461],[-97.678822,33.274769],[-97.582231,33.433993],[-97.794665,33.381461]]]},"properties":{"id":"76225","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.663436,33.109719],[-102.594567,33.096808],[-102.594718,33.167253],[-102.594489,33.271221],[-102.629884,33.313901],[-102.784324,33.314582],[-102.81851,33.30128],[-102.663436,33.109719]]]},"properties":{"id":"79376","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.453794,27.265058],[-99.385401,26.981377],[-99.185406,27.21993],[-99.354862,27.29961],[-99.453794,27.265058]]]},"properties":{"id":"78067","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.973162,31.919268],[-96.029428,31.957779],[-95.97029,31.877187],[-95.973162,31.919268]]]},"properties":{"id":"75832","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.601005,32.061483],[-95.449474,31.988952],[-95.427834,32.023785],[-95.422174,32.043818],[-95.472796,32.145051],[-95.472914,32.145077],[-95.517894,32.177594],[-95.601005,32.061483]]]},"properties":{"id":"75763","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.886266,31.988994],[-95.764609,31.825823],[-95.759523,32.041034],[-95.788508,32.037349],[-95.886266,31.988994]]]},"properties":{"id":"75853","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.497146,31.860214],[-95.479515,31.857315],[-95.477668,31.870317],[-95.497146,31.860214]]]},"properties":{"id":"75779","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.569151,31.433006],[-94.605193,31.077669],[-94.561943,31.058952],[-94.45782,31.033326],[-94.35709,31.246889],[-94.489672,31.398929],[-94.569151,31.433006]]]},"properties":{"id":"75949","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.523439,33.578359],[-98.423089,33.647355],[-98.42311,33.681166],[-98.522636,33.666837],[-98.523439,33.578359]]]},"properties":{"id":"76379","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.691832,33.216901],[-98.606315,33.222484],[-98.538684,33.395846],[-98.607531,33.466983],[-98.861974,33.468041],[-98.953938,33.397534],[-98.691832,33.216901]]]},"properties":{"id":"76374","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.784962,33.834333],[-98.761466,34.089355],[-98.750765,34.124215],[-98.952395,34.212497],[-98.953056,34.150151],[-98.953028,34.106528],[-98.962994,34.029717],[-98.953088,33.834],[-98.952933,33.785211],[-98.784962,33.834333]]]},"properties":{"id":"76360","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.614518,29.32032],[-98.667944,29.292869],[-98.688554,29.236058],[-98.627971,29.156571],[-98.573927,29.171539],[-98.581752,29.26182],[-98.56794,29.320062],[-98.614378,29.320197],[-98.614518,29.32032]]]},"properties":{"id":"78073","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.567642,30.046892],[-96.558802,30.038709],[-96.54963,30.04739],[-96.567642,30.046892]]],[[[-96.633272,30.098402],[-96.760522,30.086129],[-96.816127,30.014499],[-96.807216,30.000608],[-96.567642,30.046892],[-96.633272,30.098402]]]]},"properties":{"id":"78954","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.988706,29.724896],[-96.032711,29.727944],[-96.199451,29.656455],[-96.191546,29.619369],[-95.989241,29.54024],[-95.977699,29.604279],[-95.968454,29.608822],[-95.976563,29.664989],[-95.978254,29.689206],[-95.988706,29.724896]]]},"properties":{"id":"77485","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.10873,29.79859],[-96.11132,29.79333],[-96.10632,29.79229],[-96.10406,29.79672],[-96.10873,29.79859]]]},"properties":{"id":"77473","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.349324,29.96859],[-97.222735,30.112009],[-97.230921,30.196661],[-97.267289,30.282712],[-97.429769,30.190761],[-97.467255,30.003547],[-97.349324,29.96859]]]},"properties":{"id":"78602","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.669026,30.218525],[-97.680805,30.135111],[-97.684407,30.129505],[-97.646222,30.071034],[-97.568284,30.068118],[-97.528888,30.203382],[-97.541669,30.225253],[-97.65813,30.228095],[-97.669026,30.218525]]]},"properties":{"id":"78617","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.353285,29.954574],[-97.462136,29.851313],[-97.454228,29.793672],[-97.409464,29.759289],[-97.281528,29.817629],[-97.240729,29.892753],[-97.353285,29.954574]]]},"properties":{"id":"78953","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514389,30.001325],[-97.462136,29.851313],[-97.353285,29.954574],[-97.349324,29.96859],[-97.467255,30.003547],[-97.514389,30.001325]]]},"properties":{"id":"78662","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.460637,28.273895],[-97.853076,28.258682],[-97.784978,28.170721],[-97.660628,28.179415],[-97.531342,28.17787],[-97.460637,28.273895]]]},"properties":{"id":"78389","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.900175,28.565259],[-97.77853,28.668027],[-97.653782,28.756962],[-97.80662,28.918828],[-98.090536,28.710842],[-98.090516,28.668791],[-98.032567,28.566346],[-97.900175,28.565259]],[[-98.005337,28.652044],[-97.986204,28.652606],[-97.98605,28.623596],[-98.002486,28.623478],[-98.005337,28.652044]]]},"properties":{"id":"78119","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.878981,31.089467],[-98.075389,31.294266],[-98.003866,30.967724],[-97.912773,31.003684],[-97.872606,31.057927],[-97.880614,31.08305],[-97.878981,31.089467]]]},"properties":{"id":"76539","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.46917,29.51845],[-98.431965,29.513402],[-98.39616,29.5209],[-98.384814,29.568369],[-98.43585,29.546195],[-98.46917,29.51845]]]},"properties":{"id":"78217","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.786888,29.318878],[-98.667944,29.292869],[-98.614518,29.32032],[-98.635135,29.360789],[-98.646494,29.350447],[-98.688326,29.384988],[-98.797972,29.367631],[-98.786888,29.318878]]]},"properties":{"id":"78252","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.503978,29.4409],[-98.50783,29.488547],[-98.55251,29.495588],[-98.567441,29.489211],[-98.540291,29.446609],[-98.503978,29.4409]]]},"properties":{"id":"78201","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.476222,29.412011],[-98.443103,29.409422],[-98.443068,29.419638],[-98.468072,29.419414],[-98.476222,29.412011]]]},"properties":{"id":"78203","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.481729,29.427639],[-98.493798,29.436174],[-98.497006,29.430952],[-98.496296,29.424122],[-98.490576,29.414332],[-98.476222,29.412011],[-98.468072,29.419414],[-98.481729,29.427639]]]},"properties":{"id":"78205","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.450039,29.660913],[-98.396294,29.644073],[-98.346283,29.726703],[-98.44369,29.719436],[-98.450081,29.667407],[-98.450039,29.660913]]]},"properties":{"id":"78261","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.604074,29.371309],[-98.585719,29.365568],[-98.601397,29.384834],[-98.604074,29.371309]]]},"properties":{"id":"78243","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.804752,29.365679],[-98.877933,29.283965],[-98.865404,29.273801],[-98.804809,29.282154],[-98.786888,29.318878],[-98.797972,29.367631],[-98.804752,29.365679]]]},"properties":{"id":"78039","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.259241,30.136542],[-98.291407,30.033614],[-98.151587,29.934318],[-97.940681,30.012717],[-98.010418,30.046379],[-98.097852,30.079817],[-98.259241,30.136542]]]},"properties":{"id":"78676","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.312958,30.004166],[-98.469404,30.00216],[-98.485901,29.876046],[-98.548449,29.824643],[-98.349253,29.804233],[-98.335841,29.810318],[-98.26939,29.976175],[-98.312958,30.004166]]]},"properties":{"id":"78070","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.69058,32.37634],[-101.586132,31.945966],[-101.337832,31.906594],[-101.264976,31.988211],[-101.264714,32.036985],[-101.378863,32.106672],[-101.358151,32.109661],[-101.197849,32.207797],[-101.255431,32.62061],[-101.460297,32.620838],[-101.657571,32.594287],[-101.594819,32.486463],[-101.69058,32.37634]]]},"properties":{"id":"79720","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.041663,32.960008],[-101.690162,32.751706],[-101.541841,32.961193],[-101.58866,32.961276],[-102.015619,33.036499],[-102.067365,32.959784],[-102.041663,32.960008]]]},"properties":{"id":"79351","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.330334,31.74666],[-97.694657,31.66546],[-97.57956,31.551735],[-97.344477,31.615965],[-97.330334,31.74666]]]},"properties":{"id":"76689","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.618789,32.057403],[-97.486817,32.025486],[-97.486211,32.032528],[-97.476086,32.17346],[-97.48749,32.171102],[-97.615055,32.203581],[-97.630885,32.207403],[-97.676243,32.159225],[-97.618789,32.057403]]]},"properties":{"id":"76652","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.828902,31.990412],[-97.845544,32.097109],[-97.904938,32.158473],[-97.945975,32.122144],[-97.869036,31.844852],[-97.828902,31.990412]]]},"properties":{"id":"76649","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.868907,32.272506],[-97.91777,32.181282],[-97.904938,32.158473],[-97.845544,32.097109],[-97.676243,32.159225],[-97.630885,32.207403],[-97.705431,32.260502],[-97.743724,32.316936],[-97.78882,32.3134],[-97.868907,32.272506]]]},"properties":{"id":"76043","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.420629,29.070772],[-95.385502,29.111204],[-95.304105,29.119269],[-95.281201,29.161938],[-95.380188,29.279576],[-95.561903,29.250582],[-95.596103,29.172419],[-95.615212,29.100003],[-95.521089,29.089155],[-95.420629,29.070772]]]},"properties":{"id":"77515","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.702192,28.969311],[-95.506993,28.824493],[-95.421264,28.934819],[-95.476715,29.027101],[-95.521089,29.089155],[-95.615212,29.100003],[-95.666027,29.101009],[-95.702192,28.969311]]]},"properties":{"id":"77422","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.540182,30.456758],[-103.388102,30.345158],[-103.371881,30.235371],[-103.070072,30.339562],[-103.037807,30.385162],[-103.17017,30.477828],[-103.540182,30.456758]]]},"properties":{"id":"79831","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.119232,34.598876],[-101.372196,34.652395],[-101.472067,34.44518],[-101.471965,34.402316],[-101.471932,34.376027],[-101.47158,34.31229],[-101.171406,34.312295],[-101.02388,34.525681],[-101.119232,34.598876]]]},"properties":{"id":"79257","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.203049,31.599618],[-99.202768,31.467234],[-99.04287,31.488903],[-99.129914,31.591969],[-99.203049,31.599618]]]},"properties":{"id":"76827","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.470842,30.50953],[-96.76105,30.344326],[-96.640883,30.296786],[-96.544484,30.318563],[-96.299173,30.379152],[-96.284259,30.446782],[-96.423429,30.559088],[-96.470831,30.509585],[-96.377389,30.491203],[-96.418828,30.439632],[-96.470842,30.50953]]]},"properties":{"id":"77879","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.320882,30.478013],[-98.413089,30.581562],[-98.440983,30.501862],[-98.320882,30.478013]]]},"properties":{"id":"78657","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.806843,30.112482],[-97.955983,30.118228],[-97.975999,30.128761],[-97.979005,30.060619],[-97.700512,30.025953],[-97.674465,30.030523],[-97.655257,30.072907],[-97.693192,30.07569],[-97.806843,30.112482]]]},"properties":{"id":"78610","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.983406,28.499973],[-96.91451,28.377003],[-96.795001,28.364682],[-96.750588,28.417052],[-96.755703,28.439786],[-96.913421,28.597456],[-96.930367,28.586728],[-96.983406,28.499973]]]},"properties":{"id":"77990","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.824196,26.056119],[-97.786058,26.138025],[-97.795877,26.19694],[-97.861732,26.234796],[-97.861775,26.230051],[-97.861875,26.084383],[-97.828222,26.051621],[-97.824196,26.056119]]]},"properties":{"id":"78559","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.786058,26.138025],[-97.673603,26.154566],[-97.727968,26.248428],[-97.734157,26.248508],[-97.735578,26.260343],[-97.764051,26.296227],[-97.795877,26.19694],[-97.786058,26.138025]]]},"properties":{"id":"78552","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.085989,35.532067],[-101.310875,35.440129],[-101.20389,35.327311],[-101.085983,35.422457],[-101.085956,35.488713],[-101.085989,35.532067]]]},"properties":{"id":"79097","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.218636,32.880605],[-94.228376,32.979412],[-94.281412,32.924509],[-94.218636,32.880605]]]},"properties":{"id":"75562","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.525767,34.426826],[-102.48854,34.391767],[-102.365752,34.313123],[-102.252353,34.313188],[-102.196385,34.481351],[-102.146944,34.639888],[-102.210767,34.688513],[-102.525266,34.53103],[-102.525767,34.426826]]]},"properties":{"id":"79027","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.525123,34.72864],[-102.492683,34.746737],[-102.525184,34.746931],[-102.525123,34.72864]]]},"properties":{"id":"79085","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.998095,34.36292],[-102.015491,34.313095],[-101.999753,34.313041],[-101.865235,34.289773],[-101.662751,34.312798],[-101.591488,34.3126],[-101.564856,34.312493],[-101.513813,34.312256],[-101.471932,34.376027],[-101.471965,34.402316],[-101.522682,34.397568],[-101.955238,34.413263],[-101.998095,34.36292]]]},"properties":{"id":"79052","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.891503,31.577384],[-94.990697,31.481238],[-94.865857,31.526916],[-94.891503,31.577384]]]},"properties":{"id":"75976","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.295064,34.056471],[-98.22227,33.9591],[-98.186856,34.003643],[-98.295064,34.056471]]]},"properties":{"id":"76377","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.70683,31.897696],[-99.534214,31.807159],[-99.529751,31.642919],[-99.42487,31.623491],[-99.248412,31.897303],[-99.222972,31.941519],[-99.422906,32.080154],[-99.457291,32.080099],[-99.618558,32.070708],[-99.667145,32.036201],[-99.70683,31.897696]]]},"properties":{"id":"76834","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.718721,31.744491],[-99.613069,31.73383],[-99.534214,31.807159],[-99.70683,31.897696],[-99.760647,31.959609],[-99.854462,31.866371],[-99.718328,31.775219],[-99.718721,31.744491]]]},"properties":{"id":"76882","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.477994,29.82055],[-96.594908,29.835619],[-96.672926,29.843098],[-96.654412,29.721972],[-96.649188,29.717323],[-96.586856,29.719926],[-96.607328,29.693504],[-96.626818,29.522545],[-96.620965,29.515928],[-96.502232,29.563636],[-96.468052,29.56851],[-96.455773,29.571281],[-96.430431,29.573931],[-96.417608,29.580277],[-96.491168,29.768992],[-96.476137,29.806125],[-96.477994,29.82055]]]},"properties":{"id":"78934","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.521447,29.74512],[-98.44369,29.719436],[-98.346283,29.726703],[-98.309995,29.716969],[-98.349253,29.804233],[-98.548449,29.824643],[-98.615228,29.770359],[-98.611599,29.740063],[-98.521447,29.74512]]]},"properties":{"id":"78163","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.47554,32.376289],[-98.51269,32.205542],[-98.505427,32.016705],[-98.393881,31.867474],[-98.301699,31.84993],[-98.177206,31.932988],[-98.189343,32.079245],[-98.424585,32.373551],[-98.47554,32.376289]]]},"properties":{"id":"76446","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.0372,33.358074],[-97.254323,33.456261],[-97.343568,33.429095],[-97.34316,33.356749],[-97.204675,33.299225],[-97.104088,33.282588],[-97.036074,33.357867],[-97.0372,33.358074]]]},"properties":{"id":"76266","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.277315,33.528813],[-97.382438,33.505096],[-97.395725,33.506208],[-97.431582,33.486341],[-97.420071,33.463825],[-97.37453,33.44804],[-97.277095,33.48052],[-97.277315,33.528813]]]},"properties":{"id":"76238","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.096036,31.55244],[-97.938648,31.461894],[-97.962183,31.549229],[-97.997371,31.555782],[-98.058712,31.595051],[-98.096036,31.55244]]]},"properties":{"id":"76566","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.715857,31.484686],[-97.704448,31.481129],[-97.701072,31.490108],[-97.711441,31.49235],[-97.715857,31.484686]]]},"properties":{"id":"76597","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.512501,31.608618],[-102.678016,31.379984],[-102.313762,31.401978],[-102.512501,31.608618]]]},"properties":{"id":"79731","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.016149,30.489891],[-101.689037,30.37383],[-101.761925,30.721099],[-102.059576,30.801211],[-102.05482,30.498196],[-102.016149,30.489891]]]},"properties":{"id":"79781","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.202182,33.479442],[-101.038788,33.397214],[-100.859554,33.39813],[-100.632326,33.399057],[-100.626063,33.570871],[-100.879441,33.632649],[-100.960929,33.661237],[-101.040376,33.683674],[-101.202182,33.479442]]]},"properties":{"id":"79370","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.145523,33.130229],[-101.038636,33.177985],[-101.038788,33.397214],[-101.202182,33.479442],[-101.310904,33.516166],[-101.429436,33.423478],[-101.437213,33.395572],[-101.573516,33.330815],[-101.648274,33.232815],[-101.595924,33.106674],[-101.422693,32.985875],[-101.145523,33.130229]]]},"properties":{"id":"79356","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.766252,33.919788],[-101.693056,33.829186],[-101.613608,33.783635],[-101.461414,33.796345],[-101.465883,33.831289],[-101.564464,34.021314],[-101.730857,33.963144],[-101.766252,33.919788]]]},"properties":{"id":"79250","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.91382,31.660707],[-104.998504,31.095219],[-105.168382,30.897719],[-104.899088,30.673546],[-104.649296,30.80983],[-104.211791,31.046126],[-104.179764,31.06342],[-104.734218,31.541125],[-104.91382,31.660707]]]},"properties":{"id":"79855","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.59881,32.743946],[-96.58644,32.704186],[-96.555516,32.691876],[-96.520969,32.693765],[-96.519794,32.754534],[-96.57261,32.764955],[-96.59881,32.743946]]]},"properties":{"id":"75181","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.824371,32.732819],[-96.777533,32.749913],[-96.791491,32.753273],[-96.817685,32.770207],[-96.824371,32.732819]]]},"properties":{"id":"75203","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.822706,32.604247],[-96.710846,32.612558],[-96.709967,32.621071],[-96.745437,32.618574],[-96.823122,32.642086],[-96.823105,32.633232],[-96.822706,32.604247]]]},"properties":{"id":"75134","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.979868,32.656216],[-96.943226,32.662227],[-96.891414,32.661913],[-96.891539,32.691459],[-96.881364,32.715818],[-96.966132,32.706977],[-96.990085,32.652057],[-96.979868,32.656216]]]},"properties":{"id":"75236","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.822896,32.687043],[-96.824371,32.732819],[-96.856912,32.725429],[-96.857079,32.72533],[-96.856976,32.691232],[-96.822896,32.687043]]]},"properties":{"id":"75224","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.800502,32.769621],[-96.813671,32.79509],[-96.814921,32.798434],[-96.828903,32.805805],[-96.849527,32.811366],[-96.835251,32.789718],[-96.822847,32.780782],[-96.817685,32.770207],[-96.791491,32.753273],[-96.800502,32.769621]]]},"properties":{"id":"75207","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.491817,32.888833],[-96.505552,32.921712],[-96.59306,32.909525],[-96.592367,32.894002],[-96.51855,32.867156],[-96.491817,32.888833]]]},"properties":{"id":"75088","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.126654,33.214458],[-97.16148,33.20839],[-97.159268,33.17936],[-97.152567,33.168075],[-97.096194,33.185628],[-97.090904,33.206798],[-97.126654,33.214458]]]},"properties":{"id":"76205","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.260412,33.040956],[-97.229576,33.090947],[-97.26646,33.14165],[-97.392832,33.178361],[-97.394207,33.13177],[-97.39179,33.03617],[-97.31068,33.02431],[-97.260412,33.040956]]]},"properties":{"id":"76247","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.229576,33.090947],[-97.182845,33.050891],[-97.131438,33.059393],[-97.060992,33.103521],[-97.072841,33.117849],[-97.152567,33.168075],[-97.159268,33.17936],[-97.178723,33.176081],[-97.26646,33.14165],[-97.229576,33.090947]]]},"properties":{"id":"76226","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.89805,33.21952],[-97.019775,33.188336],[-97.002366,33.14251],[-96.970945,33.1177],[-96.88026,33.21905],[-96.89805,33.21952]]]},"properties":{"id":"75068","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.16148,33.20839],[-97.126654,33.214458],[-97.131864,33.240491],[-97.16148,33.20839]]]},"properties":{"id":"76201","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.394207,33.13177],[-97.539821,33.099622],[-97.526932,33.006934],[-97.520147,33.008815],[-97.439496,33.011358],[-97.39179,33.03617],[-97.394207,33.13177]]]},"properties":{"id":"76078","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.64661,28.409624],[-99.646495,28.500079],[-99.778725,28.336232],[-99.64661,28.409624]]]},"properties":{"id":"78827","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.114336,28.648122],[-99.620341,28.573796],[-99.569555,28.642503],[-99.724755,28.890614],[-99.914149,28.829345],[-100.113285,28.778761],[-100.114336,28.648122]]]},"properties":{"id":"78839","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.741024,34.882783],[-100.794444,34.910643],[-100.793221,34.861851],[-100.741024,34.882783]]]},"properties":{"id":"79240","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.77518,32.320097],[-98.667722,32.297601],[-98.628627,32.315324],[-98.721429,32.407163],[-98.760163,32.44498],[-98.8524,32.51523],[-98.885921,32.314882],[-98.77518,32.320097]]]},"properties":{"id":"76448","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.721429,32.407163],[-98.718452,32.437644],[-98.760163,32.44498],[-98.721429,32.407163]]]},"properties":{"id":"76466","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.388677,31.866507],[-102.366288,31.82383],[-102.319351,31.865545],[-102.381243,31.868118],[-102.388677,31.866507]]]},"properties":{"id":"79761","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.28733,31.932816],[-102.287048,32.086991],[-102.307876,32.087157],[-102.343811,32.087194],[-102.42105,31.995418],[-102.28733,31.932816]]]},"properties":{"id":"79758","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.960587,30.706071],[-100.999773,30.193829],[-100.700249,30.104739],[-100.116461,30.290296],[-100.116696,30.372917],[-100.116306,30.571833],[-100.116234,30.710366],[-100.960587,30.706071]]]},"properties":{"id":"76950","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.000715,32.211871],[-96.874044,32.084917],[-96.771568,32.135703],[-96.702902,32.169147],[-96.751751,32.198957],[-96.781822,32.193334],[-96.792274,32.20458],[-96.761661,32.22251],[-96.778852,32.247836],[-96.808843,32.239236],[-96.881827,32.231887],[-96.958531,32.27208],[-96.998296,32.275382],[-97.000715,32.211871]]]},"properties":{"id":"76651","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.086628,32.361104],[-96.998296,32.275382],[-96.958531,32.27208],[-97.012479,32.35652],[-97.086628,32.361104]]]},"properties":{"id":"76064","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.345255,31.752676],[-106.368294,31.709571],[-106.337926,31.671211],[-106.284117,31.701351],[-106.284291,31.701504],[-106.32771,31.741819],[-106.345255,31.752676]]]},"properties":{"id":"79907","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.473482,31.771973],[-106.467556,31.75921],[-106.40412,31.744621],[-106.397765,31.781069],[-106.422121,31.777715],[-106.473482,31.771973]]]},"properties":{"id":"79905","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.493867,31.863114],[-106.439021,31.820931],[-106.430787,31.832298],[-106.406896,31.870017],[-106.440065,31.869645],[-106.494943,31.868905],[-106.493867,31.863114]]]},"properties":{"id":"79904","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.370643,31.927172],[-106.440065,31.869645],[-106.406896,31.870017],[-106.388574,31.898914],[-106.370643,31.927172]]]},"properties":{"id":"79924","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.330527,31.798322],[-106.345255,31.752676],[-106.32771,31.741819],[-106.330527,31.798322]]]},"properties":{"id":"79935","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.544942,31.804548],[-106.539837,31.801111],[-106.542072,31.803976],[-106.544942,31.804548]]]},"properties":{"id":"88063","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.36636,31.828185],[-106.361254,31.831369],[-106.330248,31.850078],[-106.388574,31.898914],[-106.406896,31.870017],[-106.430787,31.832298],[-106.421654,31.826249],[-106.404508,31.819826],[-106.36636,31.828185]]]},"properties":{"id":"79908","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.177579,33.376046],[-96.101276,33.241712],[-96.032118,33.243131],[-95.950359,33.326298],[-96.088645,33.469279],[-96.115617,33.466491],[-96.161441,33.430686],[-96.169326,33.42752],[-96.177579,33.376046]]]},"properties":{"id":"75496","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.054572,29.734827],[-97.042669,29.629912],[-96.984722,29.572656],[-96.832812,29.576863],[-96.834139,29.596794],[-96.826292,29.762574],[-96.98371,29.777247],[-97.054572,29.734827]]]},"properties":{"id":"78956","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.103313,29.923621],[-97.020961,29.850986],[-97.046202,29.984485],[-97.103313,29.923621]]]},"properties":{"id":"78963","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.517553,33.107391],[-100.658208,32.796936],[-100.543778,32.738712],[-100.294345,32.866065],[-100.279984,32.961129],[-100.517553,33.107391]]]},"properties":{"id":"79546","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.0414,33.905232],[-101.046824,33.931663],[-101.098612,33.948415],[-101.086669,33.87714],[-101.041391,33.869952],[-101.0414,33.905232]]]},"properties":{"id":"79231","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.790604,29.59545],[-95.806784,29.486315],[-95.590342,29.37967],[-95.577077,29.368087],[-95.550622,29.438306],[-95.55598,29.498468],[-95.555006,29.503881],[-95.708453,29.574825],[-95.790604,29.59545]]]},"properties":{"id":"77469","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.806784,29.486315],[-95.790604,29.59545],[-95.886874,29.592953],[-95.933043,29.591324],[-95.977699,29.604279],[-95.989241,29.54024],[-95.984723,29.540722],[-95.916544,29.444625],[-95.806784,29.486315]]]},"properties":{"id":"77471","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.682941,29.707788],[-95.679968,29.67675],[-95.616075,29.670411],[-95.614466,29.68437],[-95.614704,29.713411],[-95.682941,29.707788]]]},"properties":{"id":"77083","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.123424,31.649359],[-96.017337,31.616382],[-95.959317,31.782072],[-95.97029,31.877187],[-96.029428,31.957779],[-96.052786,32.005895],[-96.05478,32.012526],[-96.120491,31.980805],[-96.252754,31.746031],[-96.123424,31.649359]]]},"properties":{"id":"75840","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.536875,32.813129],[-102.726149,32.842232],[-103.064701,32.879355],[-102.99628,32.523154],[-102.516103,32.523079],[-102.352364,32.70493],[-102.467994,32.784129],[-102.536875,32.813129]]]},"properties":{"id":"79360","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.216422,29.55608],[-95.223826,29.51531],[-95.178389,29.4678],[-95.160405,29.497649],[-95.168885,29.569464],[-95.181294,29.574477],[-95.216422,29.55608]]]},"properties":{"id":"77546","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.769122,33.62115],[-96.795303,33.560473],[-96.807483,33.520107],[-96.792698,33.519847],[-96.627186,33.465587],[-96.541391,33.484113],[-96.606528,33.553048],[-96.764914,33.620613],[-96.769122,33.62115]]]},"properties":{"id":"75459","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.532088,33.822824],[-96.662559,33.841874],[-96.682795,33.731406],[-96.604203,33.687052],[-96.570721,33.695656],[-96.532088,33.822824]]]},"properties":{"id":"75020","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.897283,32.457143],[-94.986868,32.425234],[-95.031626,32.38398],[-95.004372,32.350198],[-94.817964,32.283733],[-94.734013,32.318838],[-94.781023,32.449665],[-94.897283,32.457143]]]},"properties":{"id":"75662","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.876087,32.571092],[-94.839737,32.556982],[-94.829603,32.503621],[-94.88292,32.511576],[-94.877829,32.541018],[-94.897283,32.457143],[-94.781023,32.449665],[-94.756952,32.480368],[-94.760648,32.525193],[-94.819323,32.591641],[-94.86071,32.590446],[-94.876087,32.571092]]]},"properties":{"id":"75604","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.591346,32.392402],[-94.596699,32.363219],[-94.579201,32.394925],[-94.591346,32.392402]]]},"properties":{"id":"75641","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.877829,32.541018],[-94.88292,32.511576],[-94.829603,32.503621],[-94.839737,32.556982],[-94.876087,32.571092],[-94.877829,32.541018]]]},"properties":{"id":"75693","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.872965,29.91983],[-95.832967,30.186513],[-95.839352,30.238044],[-95.915225,30.253861],[-95.950301,30.236905],[-95.983761,30.101642],[-95.992586,30.071485],[-96.031444,29.940625],[-95.868966,29.908828],[-95.872965,29.91983]]]},"properties":{"id":"77484","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.398958,31.717459],[-98.422094,31.627378],[-98.332425,31.501569],[-98.096036,31.55244],[-98.058712,31.595051],[-97.994442,31.615248],[-97.966132,31.771565],[-98.162264,31.850037],[-98.227852,31.805234],[-98.305607,31.765218],[-98.398958,31.717459]],[[-98.36978,31.576764],[-98.352229,31.635457],[-98.416145,31.663624],[-98.399363,31.706121],[-98.288844,31.661309],[-98.36978,31.576764]]]},"properties":{"id":"76531","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.251827,30.526711],[-94.192046,30.285576],[-94.117686,30.241779],[-94.117688,30.298402],[-94.084326,30.350313],[-94.071689,30.526033],[-94.251827,30.526711]]]},"properties":{"id":"77656","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.192046,30.285576],[-94.283697,30.196053],[-94.275451,30.170265],[-94.154613,30.169949],[-94.115917,30.160347],[-94.117686,30.241779],[-94.192046,30.285576]]]},"properties":{"id":"77657","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.722093,30.490256],[-94.783964,30.489478],[-94.809146,30.455551],[-94.802506,30.42392],[-94.687652,30.365384],[-94.691601,30.376515],[-94.722093,30.490256]]]},"properties":{"id":"77369","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.697593,30.043965],[-95.67347,29.953075],[-95.638855,29.928611],[-95.585769,29.952539],[-95.605416,29.998162],[-95.697593,30.043965]]]},"properties":{"id":"77429","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.735094,30.093721],[-95.777055,30.037074],[-95.697593,30.043965],[-95.605416,29.998162],[-95.590658,30.013058],[-95.647469,30.118107],[-95.735094,30.093721]]]},"properties":{"id":"77377","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.476371,30.024371],[-95.525514,30.085588],[-95.528227,30.088189],[-95.570625,30.022776],[-95.553531,29.992682],[-95.512028,30.006695],[-95.476371,30.024371]]]},"properties":{"id":"77379","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.530747,29.950558],[-95.579683,29.933616],[-95.584504,29.897687],[-95.504096,29.899164],[-95.521692,29.936627],[-95.530747,29.950558]]]},"properties":{"id":"77064","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.095222,29.528923],[-95.069201,29.556797],[-95.091403,29.596823],[-95.10361,29.583483],[-95.128593,29.548957],[-95.095222,29.528923]]]},"properties":{"id":"77058","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.384544,29.732243],[-95.390253,29.726079],[-95.390258,29.724958],[-95.38212,29.713013],[-95.332917,29.714372],[-95.342067,29.731823],[-95.367436,29.744123],[-95.384544,29.732243]]]},"properties":{"id":"77004","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.378753,29.752093],[-95.373205,29.76116],[-95.433809,29.757672],[-95.430029,29.742068],[-95.402086,29.743765],[-95.378753,29.752093]]]},"properties":{"id":"77019","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.36653,29.876477],[-95.360334,29.851757],[-95.352373,29.819732],[-95.335349,29.819891],[-95.30659,29.891185],[-95.370123,29.892615],[-95.36653,29.876477]]]},"properties":{"id":"77093","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.433809,29.757672],[-95.455722,29.759957],[-95.460397,29.72634],[-95.44787,29.72727],[-95.427966,29.728586],[-95.429973,29.732624],[-95.433426,29.733402],[-95.42997,29.738337],[-95.430029,29.742068],[-95.433809,29.757672]]]},"properties":{"id":"77027","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.487134,29.866543],[-95.409698,29.871413],[-95.412618,29.898146],[-95.453334,29.897511],[-95.494914,29.889204],[-95.487134,29.866543]]]},"properties":{"id":"77088","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.479711,29.773378],[-95.47642,29.725724],[-95.460397,29.72634],[-95.455722,29.759957],[-95.479711,29.773378]]]},"properties":{"id":"77056","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.484455,29.69103],[-95.4478,29.723862],[-95.44787,29.72727],[-95.460397,29.72634],[-95.47642,29.725724],[-95.491836,29.72343],[-95.499288,29.716841],[-95.492658,29.689774],[-95.484455,29.69103]]]},"properties":{"id":"77081","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.356286,29.648215],[-95.363205,29.680767],[-95.381778,29.675439],[-95.396328,29.670542],[-95.38728,29.632212],[-95.356286,29.648215]]]},"properties":{"id":"77051","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.299539,29.97282],[-95.212386,29.981848],[-95.213933,30.024088],[-95.26377,30.032434],[-95.310994,30.032887],[-95.382415,30.040362],[-95.370443,29.998717],[-95.299539,29.97282]]]},"properties":{"id":"77338","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.576165,29.736616],[-95.589615,29.759794],[-95.646552,29.769702],[-95.653982,29.735466],[-95.576165,29.736616]]]},"properties":{"id":"77077","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.576165,29.736616],[-95.653982,29.735466],[-95.705921,29.734703],[-95.708212,29.721851],[-95.682941,29.707788],[-95.614704,29.713411],[-95.571445,29.716958],[-95.576165,29.736616]]]},"properties":{"id":"77082","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.683296,29.879941],[-95.773835,29.875155],[-95.776804,29.784947],[-95.718929,29.784688],[-95.683296,29.879941]]]},"properties":{"id":"77449","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.446686,29.673226],[-95.441859,29.656317],[-95.431041,29.660414],[-95.43084,29.660462],[-95.414363,29.694449],[-95.423018,29.706129],[-95.44739,29.705918],[-95.447054,29.689957],[-95.446686,29.673226]]]},"properties":{"id":"77025","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.342723,32.668985],[-94.443485,32.39415],[-94.458248,32.370243],[-94.404019,32.369839],[-94.347563,32.330682],[-94.206699,32.359065],[-94.157956,32.376785],[-94.217503,32.546404],[-94.295635,32.672584],[-94.342723,32.668985]]]},"properties":{"id":"75672","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.162734,35.953239],[-102.496601,35.790731],[-102.485804,35.772106],[-102.1669,35.806002],[-102.162895,35.805938],[-102.162734,35.953239]]]},"properties":{"id":"79044","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.976265,33.335501],[-99.770413,33.361625],[-99.763031,33.39829],[-99.99098,33.397404],[-99.976265,33.335501]]]},"properties":{"id":"79539","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.958178,33.282471],[-99.98968,33.265437],[-99.989656,33.107849],[-99.836924,33.151746],[-99.807109,33.253493],[-99.958178,33.282471]]]},"properties":{"id":"79547","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.553575,33.398768],[-99.702458,33.398446],[-99.735792,33.362571],[-99.724583,33.320868],[-99.725826,33.252596],[-99.597047,33.269044],[-99.534473,33.398804],[-99.553575,33.398768]]]},"properties":{"id":"76388","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.508585,26.395163],[-98.430428,26.332972],[-98.428729,26.378511],[-98.508585,26.395163]]],[[[-98.275612,26.223062],[-98.421902,26.287693],[-98.447233,26.228169],[-98.449305,26.223307],[-98.270243,26.108072],[-98.258124,26.127715],[-98.263695,26.19461],[-98.275612,26.223062]]]]},"properties":{"id":"78572","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.930253,26.087188],[-97.919678,26.05797],[-97.861822,26.069921],[-97.861875,26.084383],[-97.861775,26.230051],[-97.944684,26.286872],[-97.97238,26.257557],[-97.930253,26.087188]]]},"properties":{"id":"78570","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.125689,26.240784],[-98.164966,26.082203],[-98.170214,26.066315],[-98.129986,26.074271],[-98.084974,26.235103],[-98.125689,26.240784]]]},"properties":{"id":"78516","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.037686,26.288989],[-98.012257,26.293095],[-98.037803,26.297208],[-98.037686,26.288989]]]},"properties":{"id":"78558","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.367524,32.178421],[-97.269643,32.110245],[-97.266643,32.11171],[-97.219468,32.195587],[-97.318599,32.195354],[-97.367524,32.178421]]]},"properties":{"id":"76636","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.28206,31.843152],[-97.30167,31.757105],[-97.277265,31.745492],[-97.224495,31.771608],[-97.201854,31.844393],[-97.167123,31.905501],[-97.202591,31.898556],[-97.28206,31.843152]]]},"properties":{"id":"76622","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.61483,33.810823],[-102.612245,33.757045],[-102.540087,33.787011],[-102.61483,33.810823]]]},"properties":{"id":"79353","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.648062,32.641078],[-97.724928,32.545622],[-97.616248,32.479928],[-97.503356,32.545392],[-97.503424,32.555439],[-97.57594,32.59715],[-97.648062,32.641078]]]},"properties":{"id":"76035","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.55316,31.390702],[-105.21882,30.847036],[-105.168382,30.897719],[-104.998504,31.095219],[-105.55316,31.390702]]]},"properties":{"id":"79851","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.210022,33.130516],[-95.998931,33.12966],[-95.977795,33.209984],[-96.032118,33.243131],[-96.101276,33.241712],[-96.206263,33.20479],[-96.272125,33.151635],[-96.210022,33.130516]]]},"properties":{"id":"75401","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.210022,33.130516],[-96.148053,33.000693],[-96.016694,32.978973],[-95.994217,33.058791],[-95.998931,33.12966],[-96.210022,33.130516]]]},"properties":{"id":"75402","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.557274,35.751746],[-101.62289,35.677858],[-101.508498,35.649487],[-101.509997,35.721759],[-101.557274,35.751746]]]},"properties":{"id":"79078","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.811041,35.929563],[-101.557274,35.751746],[-101.509997,35.721759],[-101.417946,35.740987],[-101.378939,35.803251],[-101.269585,36.037105],[-101.395642,36.055235],[-101.623144,35.985166],[-101.664358,35.965106],[-101.811041,35.929563]]]},"properties":{"id":"79083","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.352166,33.243133],[-98.420856,33.349263],[-98.421071,33.263845],[-98.422423,33.22299],[-98.352166,33.243133]]]},"properties":{"id":"76459","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.078268,30.547211],[-93.882923,30.541128],[-93.803548,30.615649],[-93.810009,30.694889],[-93.87123,30.768203],[-94.139803,30.779118],[-94.128272,30.745705],[-94.083343,30.593577],[-94.078268,30.547211]]]},"properties":{"id":"75956","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.918379,29.963188],[-93.896146,29.984619],[-93.997834,30.013677],[-93.969387,29.961413],[-93.944758,29.953104],[-93.918379,29.963188]]]},"properties":{"id":"77651","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.157282,30.16226],[-94.180363,30.133241],[-94.145185,30.117224],[-94.157282,30.16226]]]},"properties":{"id":"77708","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.120514,30.096035],[-94.136313,30.092563],[-94.136017,30.085506],[-94.135282,30.075134],[-94.120514,30.096035]]]},"properties":{"id":"77702","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.476071,32.423701],[-97.503356,32.545392],[-97.616248,32.479928],[-97.604823,32.355924],[-97.476071,32.423701]]]},"properties":{"id":"76044","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503356,32.545392],[-97.476071,32.423701],[-97.383601,32.423557],[-97.321128,32.446913],[-97.429254,32.514913],[-97.503424,32.555439],[-97.503356,32.545392]]]},"properties":{"id":"76058","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.18626,32.519154],[-97.197246,32.510036],[-97.177477,32.516768],[-97.18626,32.519154]]]},"properties":{"id":"76061","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.680198,32.957514],[-99.765066,32.838791],[-99.713461,32.750718],[-99.684479,32.801842],[-99.620869,32.956962],[-99.680198,32.957514]]]},"properties":{"id":"79503","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.403961,32.670149],[-96.488664,32.653225],[-96.501023,32.497149],[-96.500576,32.494732],[-96.458505,32.522015],[-96.382637,32.619685],[-96.403961,32.670149]]]},"properties":{"id":"75114","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.667725,30.037722],[-98.602699,29.931576],[-98.485901,29.876046],[-98.469404,30.00216],[-98.667725,30.037722]]]},"properties":{"id":"78027","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.368067,30.103323],[-99.694712,29.943492],[-99.602769,29.888101],[-99.498983,29.906691],[-99.346808,29.943393],[-99.310282,30.017059],[-99.368067,30.103323]]]},"properties":{"id":"78024","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.771808,33.607674],[-95.65978,33.587902],[-95.619932,33.681463],[-95.790299,33.683183],[-95.79616,33.628634],[-95.771808,33.607674]]]},"properties":{"id":"75421","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.334575,33.805712],[-95.310457,33.540534],[-95.309285,33.495112],[-95.285431,33.507393],[-95.201179,33.558182],[-95.193154,33.63402],[-95.175065,33.947527],[-95.310497,33.877204],[-95.324465,33.840431],[-95.334575,33.805712]]]},"properties":{"id":"75436","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.509981,31.106926],[-98.185978,31.376287],[-98.290464,31.376208],[-98.57981,31.265386],[-98.509981,31.106926]]]},"properties":{"id":"76853","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.801226,28.314293],[-98.656899,28.648364],[-98.800841,28.647487],[-98.848695,28.647201],[-98.874835,28.64695],[-98.801226,28.314293]]]},"properties":{"id":"78021","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.96627,28.051402],[-99.325337,28.154631],[-99.614245,28.202091],[-99.821034,28.200323],[-100.045937,28.157455],[-99.400394,27.869925],[-99.254372,27.85429],[-99.202351,27.862571],[-98.803325,28.05748],[-98.96627,28.051402]]]},"properties":{"id":"78019","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.201408,31.115383],[-96.107864,31.126311],[-96.090683,31.184763],[-96.201408,31.115383]]]},"properties":{"id":"77855","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.648605,30.133103],[-94.605284,30.054625],[-94.539858,30.111911],[-94.600843,30.124371],[-94.648605,30.133103]]]},"properties":{"id":"77533","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.783964,30.489478],[-94.801135,30.490689],[-94.809146,30.455551],[-94.783964,30.489478]]],[[[-95.061519,30.390582],[-95.118061,30.294361],[-95.097823,30.170616],[-95.086027,30.139272],[-95.072603,30.103054],[-94.82503,30.21355],[-94.698087,30.308747],[-94.665835,30.305062],[-94.67528,30.331209],[-94.687652,30.365384],[-94.802506,30.42392],[-94.819748,30.491966],[-94.849414,30.493553],[-95.061519,30.390582]]]]},"properties":{"id":"77327","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.253333,36.499797],[-100.366264,36.361465],[-100.384234,36.288879],[-100.384226,36.266933],[-100.275962,36.245299],[-100.182733,36.261082],[-100.000402,36.273467],[-100.253333,36.499797]]]},"properties":{"id":"79034","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.40543,36.499454],[-100.366264,36.361465],[-100.253333,36.499797],[-100.40543,36.499454]]]},"properties":{"id":"79024","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.098637,28.442964],[-98.104609,28.452693],[-98.110389,28.451281],[-98.098637,28.442964]]]},"properties":{"id":"78060","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.484271,30.735421],[-98.408603,30.71309],[-98.404948,30.815745],[-98.504337,30.796797],[-98.484271,30.735421]]]},"properties":{"id":"78609","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.061672,33.606636],[-101.898816,33.578752],[-101.889204,33.584635],[-101.93952,33.644263],[-102.061672,33.606636]]]},"properties":{"id":"79416","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.87046,33.562346],[-101.845639,33.563283],[-101.845624,33.577848],[-101.867251,33.577771],[-101.870521,33.563277],[-101.87046,33.562346]]]},"properties":{"id":"79411","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.648274,33.232815],[-101.964024,33.390825],[-102.067603,33.389618],[-102.075929,33.389586],[-102.075956,33.374246],[-102.075921,33.294094],[-102.015619,33.036499],[-101.58866,32.961276],[-101.595924,33.106674],[-101.648274,33.232815]]]},"properties":{"id":"79373","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.027427,31.479951],[-99.091804,31.133643],[-99.09198,31.072872],[-98.846241,31.410478],[-98.942737,31.458629],[-99.027427,31.479951]]]},"properties":{"id":"76871","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.128277,31.710756],[-97.224495,31.771608],[-97.277265,31.745492],[-97.266988,31.694119],[-97.141349,31.596335],[-97.102892,31.551761],[-97.045137,31.519037],[-96.920217,31.538169],[-96.917921,31.585997],[-97.029804,31.659006],[-97.128277,31.710756]]]},"properties":{"id":"76705","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.168282,31.46431],[-97.21334,31.455174],[-97.194224,31.421833],[-97.168282,31.46431]]]},"properties":{"id":"76643","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.117474,31.546255],[-97.116689,31.547302],[-97.120692,31.55065],[-97.121683,31.549781],[-97.117474,31.546255]]]},"properties":{"id":"76798","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.315301,28.829111],[-96.182057,28.885932],[-96.217931,28.921883],[-96.310593,28.926315],[-96.310686,28.922589],[-96.315301,28.829111]]]},"properties":{"id":"77419","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.736053,28.938409],[-95.715316,28.946377],[-95.721834,28.954748],[-95.736053,28.938409]]]},"properties":{"id":"77415","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.484616,30.86899],[-99.546041,30.789782],[-99.484366,30.823445],[-99.484616,30.86899]]]},"properties":{"id":"76848","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.264431,30.636587],[-97.155219,30.457344],[-97.051648,30.51156],[-97.156176,30.733405],[-97.270605,30.77555],[-97.302585,30.747562],[-97.253197,30.693216],[-97.264431,30.636587]]]},"properties":{"id":"76577","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.773712,31.01634],[-96.982022,31.031201],[-97.075051,30.98375],[-97.048814,30.773792],[-96.94283,30.719237],[-96.825736,30.785978],[-96.679176,30.844048],[-96.696693,30.909038],[-96.773712,31.01634]]]},"properties":{"id":"76520","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.877811,30.600776],[-96.713845,30.72326],[-96.825736,30.785978],[-96.94283,30.719237],[-96.877811,30.600776]]]},"properties":{"id":"76556","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.449495,30.156895],[-95.436597,30.110418],[-95.310994,30.032887],[-95.26377,30.032434],[-95.270786,30.043177],[-95.345326,30.145252],[-95.375509,30.164093],[-95.449495,30.156895]]]},"properties":{"id":"77386","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.479943,30.191384],[-95.541399,30.170279],[-95.505125,30.145153],[-95.452247,30.178243],[-95.453951,30.193645],[-95.479943,30.191384]]]},"properties":{"id":"77381","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.163015,36.055249],[-102.162734,35.953239],[-102.162895,35.805938],[-102.163014,35.764969],[-102.015621,35.690689],[-101.939417,35.667034],[-101.822782,35.907725],[-101.900728,36.023989],[-101.989368,36.055395],[-101.99083,36.017599],[-102.018224,36.034296],[-102.02844,36.055155],[-102.163015,36.055249]]]},"properties":{"id":"79029","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.817326,34.266439],[-100.897842,34.31297],[-100.937938,34.3128],[-101.041344,34.177758],[-101.041424,34.14686],[-101.041416,34.139043],[-100.914151,34.134139],[-100.828746,34.170502],[-100.747203,34.209313],[-100.817326,34.266439]]]},"properties":{"id":"79234","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.638995,31.443676],[-94.657696,31.618499],[-94.681192,31.843943],[-94.733988,31.843806],[-94.817017,31.685333],[-94.891503,31.577384],[-94.865857,31.526916],[-94.799357,31.483246],[-94.730975,31.462395],[-94.638995,31.443676]]]},"properties":{"id":"75964","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.469716,32.164074],[-96.424247,31.939606],[-96.333727,31.943536],[-96.199298,31.974034],[-96.321725,32.090358],[-96.399106,32.188463],[-96.418011,32.212072],[-96.469716,32.164074]]]},"properties":{"id":"75109","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.608414,30.746664],[-93.810009,30.694889],[-93.803548,30.615649],[-93.727731,30.56658],[-93.608414,30.746664]]]},"properties":{"id":"75928","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.224007,32.173038],[-100.150064,32.223641],[-100.149547,32.281181],[-100.171023,32.378747],[-100.224007,32.173038]]]},"properties":{"id":"79537","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.475656,27.804312],[-97.414425,27.797939],[-97.402955,27.8151],[-97.436431,27.824559],[-97.475656,27.804312]]]},"properties":{"id":"78407","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.436431,27.824559],[-97.402955,27.8151],[-97.394896,27.813323],[-97.436431,27.824559]]]},"properties":{"id":"78402","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.410931,27.783542],[-97.419305,27.764888],[-97.39759,27.753614],[-97.383594,27.766436],[-97.393934,27.783768],[-97.410931,27.783542]]]},"properties":{"id":"78404","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.306917,27.709513],[-97.27761,27.700275],[-97.262391,27.684059],[-97.253864,27.696737],[-97.306917,27.709513]]]},"properties":{"id":"78419","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.409409,27.789022],[-97.410931,27.783542],[-97.393934,27.783768],[-97.394896,27.813323],[-97.402955,27.8151],[-97.414425,27.797939],[-97.409409,27.789022]]]},"properties":{"id":"78401","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.503845,27.748958],[-97.427395,27.735454],[-97.460063,27.755986],[-97.469772,27.764117],[-97.503845,27.748958]]]},"properties":{"id":"78417","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.552093,27.636479],[-97.755673,27.661335],[-97.778213,27.667608],[-97.773343,27.681493],[-97.875024,27.711747],[-97.939125,27.685737],[-97.942146,27.635932],[-97.840678,27.558358],[-97.831426,27.558402],[-97.50272,27.560386],[-97.552093,27.636479]]]},"properties":{"id":"78343","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.165458,35.351308],[-102.050333,35.275582],[-102.005428,35.35509],[-101.962551,35.484774],[-101.995239,35.500858],[-102.165458,35.351308]]]},"properties":{"id":"79010","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.426553,33.007913],[-98.531326,32.883992],[-98.527871,32.858508],[-98.405527,32.794369],[-98.211864,32.823808],[-98.096623,32.945908],[-98.191517,33.004847],[-98.426553,33.007913]]]},"properties":{"id":"76449","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.571272,32.219395],[-94.582581,32.14948],[-94.30886,32.228321],[-94.347563,32.330682],[-94.404019,32.369839],[-94.571272,32.219395]]]},"properties":{"id":"75631","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.536908,32.825355],[-97.503396,32.875341],[-97.512607,32.993935],[-97.520147,33.008815],[-97.526932,33.006934],[-97.603261,32.979636],[-97.594521,32.908567],[-97.676799,32.816153],[-97.592148,32.826446],[-97.536908,32.825355]]]},"properties":{"id":"76020","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.008766,30.613938],[-94.853586,30.545029],[-94.849414,30.493553],[-94.819748,30.491966],[-94.818983,30.491921],[-94.827043,30.514951],[-94.814746,30.518875],[-94.805911,30.49102],[-94.801135,30.490689],[-94.783964,30.489478],[-94.722093,30.490256],[-94.645269,30.490519],[-94.580972,30.490829],[-94.545717,30.526977],[-94.58166,30.681598],[-94.615996,30.830061],[-94.68799,30.90117],[-94.704628,30.899495],[-94.791386,30.901114],[-94.95643,30.934669],[-95.035242,30.962916],[-95.061113,30.941302],[-95.135151,30.768471],[-95.008766,30.613938]],[[-94.868091,30.84907],[-94.870208,30.821947],[-94.880985,30.829165],[-94.868091,30.84907]]]},"properties":{"id":"77351","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.68799,30.90117],[-94.615996,30.830061],[-94.522344,30.930482],[-94.451127,31.003445],[-94.45782,31.033326],[-94.561943,31.058952],[-94.68799,30.90117]]]},"properties":{"id":"75936","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.741875,35.203454],[-101.848859,35.250879],[-101.866622,35.228573],[-101.843064,35.214252],[-101.814229,35.210582],[-101.76006,35.203152],[-101.742197,35.2007],[-101.741875,35.203454]]]},"properties":{"id":"79107","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.050333,35.275582],[-102.165458,35.351308],[-102.166695,35.247041],[-102.114599,35.183222],[-102.046299,35.183179],[-102.050333,35.275582]]]},"properties":{"id":"79012","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.799059,32.773815],[-95.763867,32.757904],[-95.748746,32.755272],[-95.713514,32.743865],[-95.649199,32.897371],[-95.666916,32.972232],[-95.683141,32.979629],[-95.768809,32.979937],[-95.799059,32.773815]]]},"properties":{"id":"75440","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.613736,31.200272],[-103.71148,31.01984],[-103.40889,30.952018],[-103.474925,31.052987],[-103.613736,31.200272]]]},"properties":{"id":"79780","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.408493,28.344114],[-97.213518,28.184191],[-97.125331,28.116256],[-96.946284,28.297059],[-96.91451,28.377003],[-96.983406,28.499973],[-97.043173,28.531593],[-97.160751,28.553475],[-97.375579,28.388684],[-97.408493,28.344114]]]},"properties":{"id":"78377","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.474982,31.195421],[-96.609409,31.079483],[-96.619246,30.966053],[-96.24102,30.973737],[-96.278407,31.117083],[-96.330635,31.254289],[-96.413251,31.310821],[-96.474982,31.195421]]]},"properties":{"id":"77856","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.550549,30.702982],[-96.526532,30.7581],[-96.577432,30.756071],[-96.550549,30.702982]]]},"properties":{"id":"77867","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.151608,31.367024],[-93.909889,31.451169],[-93.927828,31.591276],[-94.081955,31.616215],[-94.3055,31.544033],[-94.304277,31.504403],[-94.327501,31.382733],[-94.151608,31.367024]]]},"properties":{"id":"75972","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.763474,30.679953],[-95.338625,30.737826],[-95.334407,30.782646],[-95.350967,30.746803],[-95.399306,30.746781],[-95.397247,30.793926],[-95.330296,30.828287],[-95.283604,30.773019],[-95.190379,30.820723],[-95.20018,30.824566],[-95.389204,30.856107],[-95.398659,30.851633],[-95.407336,30.856306],[-95.398753,30.86595],[-95.528812,30.925795],[-95.766221,30.894155],[-95.821646,30.867767],[-95.763474,30.679953]],[[-95.554089,30.738443],[-95.56122,30.741762],[-95.54896,30.741887],[-95.54955,30.737879],[-95.554089,30.738443]]]},"properties":{"id":"77320","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.506879,27.878156],[-97.239388,27.857775],[-97.233476,27.89176],[-97.280673,27.916797],[-97.304544,27.929542],[-97.457537,27.939924],[-97.507008,27.925511],[-97.506879,27.878156]]]},"properties":{"id":"78374","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.67716,27.951143],[-97.669448,27.968688],[-97.686275,27.974934],[-97.67716,27.951143]]]},"properties":{"id":"78352","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.265652,32.349828],[-95.257949,32.344566],[-95.213546,32.353758],[-95.265652,32.349828]]],[[[-95.031626,32.38398],[-95.134667,32.411191],[-95.172184,32.361103],[-95.111383,32.355429],[-95.05436,32.317369],[-95.004372,32.350198],[-95.031626,32.38398]]]]},"properties":{"id":"75705","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.953028,26.390022],[-98.85127,26.785335],[-98.95423,26.785694],[-98.994299,26.707767],[-99.058415,26.644189],[-99.108364,26.546681],[-99.096912,26.464589],[-98.953028,26.390022]]]},"properties":{"id":"78584","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.485074,26.69712],[-98.43331,26.556885],[-98.367704,26.685771],[-98.485074,26.69712]]]},"properties":{"id":"78536","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.447914,32.652119],[-97.41362,32.644793],[-97.381192,32.675067],[-97.424185,32.696424],[-97.429305,32.693196],[-97.447914,32.652119]]]},"properties":{"id":"76132","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.356136,32.687877],[-97.35259,32.667257],[-97.306545,32.669587],[-97.306024,32.669581],[-97.31294,32.700013],[-97.356136,32.687877]]]},"properties":{"id":"76115","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.358787,32.732802],[-97.337405,32.759335],[-97.351224,32.765302],[-97.414404,32.751291],[-97.411657,32.710397],[-97.364298,32.723214],[-97.358787,32.732802]]]},"properties":{"id":"76107","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.238531,32.892414],[-97.237996,32.860414],[-97.186095,32.861959],[-97.18596,32.889243],[-97.187986,32.911661],[-97.238531,32.892414]]]},"properties":{"id":"76182","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.317076,32.88693],[-97.317444,32.839495],[-97.314478,32.809976],[-97.290546,32.80977],[-97.269461,32.839399],[-97.258987,32.894914],[-97.306971,32.890777],[-97.317076,32.88693]]]},"properties":{"id":"76137","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.250588,32.963515],[-97.298363,32.963177],[-97.306971,32.890777],[-97.258987,32.894914],[-97.250588,32.963515]]]},"properties":{"id":"76244","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.841792,32.520902],[-99.878485,32.45893],[-99.848733,32.438285],[-99.845853,32.438891],[-99.745958,32.449824],[-99.74172,32.449596],[-99.841792,32.520902]]],[[[-99.978359,32.312355],[-99.910832,32.300006],[-99.909058,32.399014],[-99.958557,32.399895],[-99.978359,32.312355]]]]},"properties":{"id":"79603","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.436201,31.544347],[-100.409377,31.441238],[-100.406086,31.423626],[-100.314016,31.37184],[-100.296443,31.37134],[-100.177386,31.344625],[-100.177898,31.385497],[-100.159348,31.414799],[-100.17505,31.475077],[-100.298624,31.692694],[-100.382865,31.692546],[-100.433327,31.66732],[-100.436201,31.544347]]]},"properties":{"id":"76905","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.177386,31.344625],[-100.114052,31.218703],[-100.112932,31.366042],[-100.177898,31.385497],[-100.177386,31.344625]]]},"properties":{"id":"76955","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.739606,30.370436],[-97.712787,30.347273],[-97.712439,30.347805],[-97.678176,30.407802],[-97.708211,30.4179],[-97.739606,30.370436]]]},"properties":{"id":"78758","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.880168,30.196449],[-97.853761,30.177403],[-97.842968,30.194284],[-97.820854,30.233165],[-97.874816,30.233301],[-97.888305,30.229195],[-97.880168,30.196449]]]},"properties":{"id":"78749","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.797133,30.136926],[-97.806843,30.112482],[-97.693192,30.07569],[-97.684407,30.129505],[-97.680805,30.135111],[-97.699872,30.145528],[-97.788605,30.161972],[-97.797133,30.136926]]]},"properties":{"id":"78747","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.541669,30.225253],[-97.528888,30.203382],[-97.501431,30.208451],[-97.439647,30.414406],[-97.501448,30.477578],[-97.527116,30.474646],[-97.635098,30.401969],[-97.601778,30.336577],[-97.560725,30.249029],[-97.541669,30.225253]]]},"properties":{"id":"78653","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.712787,30.347273],[-97.727922,30.329055],[-97.726336,30.325943],[-97.70693,30.322181],[-97.673329,30.325684],[-97.686345,30.332598],[-97.712439,30.347805],[-97.712787,30.347273]]]},"properties":{"id":"78752","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.775884,30.313606],[-97.748003,30.307218],[-97.749749,30.334495],[-97.744438,30.361299],[-97.774931,30.390387],[-97.791352,30.359289],[-97.796919,30.350015],[-97.775884,30.313606]]]},"properties":{"id":"78731","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820854,30.233165],[-97.842968,30.194284],[-97.786254,30.166975],[-97.751207,30.215607],[-97.796557,30.234042],[-97.820854,30.233165]]]},"properties":{"id":"78745","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.673329,30.325684],[-97.70693,30.322181],[-97.710448,30.30959],[-97.703945,30.282572],[-97.701711,30.282911],[-97.665508,30.285658],[-97.673329,30.325684]]]},"properties":{"id":"78723","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.665508,30.285658],[-97.560725,30.249029],[-97.601778,30.336577],[-97.673329,30.325684],[-97.665508,30.285658]]]},"properties":{"id":"78724","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.727401,30.283078],[-97.730048,30.278506],[-97.703945,30.282572],[-97.710448,30.30959],[-97.72013,30.294284],[-97.727401,30.283078]]]},"properties":{"id":"78722","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.061996,31.651593],[-102.057491,31.369874],[-102.028383,31.377549],[-101.775654,31.363379],[-101.77551,31.406207],[-101.775802,31.651319],[-102.061996,31.651593]]]},"properties":{"id":"79755","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.455204,29.772459],[-101.101155,29.616961],[-101.115291,30.044083],[-101.521296,30.034775],[-101.455204,29.772459]]]},"properties":{"id":"78837","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.828117,32.626345],[-95.698226,32.540647],[-95.594185,32.564816],[-95.615599,32.707879],[-95.713514,32.743865],[-95.748746,32.755272],[-95.828117,32.626345]]]},"properties":{"id":"75140","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.594325,32.496568],[-95.701116,32.51686],[-95.81274,32.416933],[-95.777287,32.356305],[-95.67183,32.359546],[-95.58891,32.368367],[-95.473561,32.374066],[-95.554859,32.454107],[-95.594325,32.496568]]]},"properties":{"id":"75754","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.043173,28.531593],[-96.983406,28.499973],[-96.930367,28.586728],[-96.946345,28.606868],[-97.043173,28.531593]]]},"properties":{"id":"77973","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.554089,30.738443],[-95.54955,30.737879],[-95.54896,30.741887],[-95.56122,30.741762],[-95.554089,30.738443]]]},"properties":{"id":"77342","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.983761,30.101642],[-96.021355,30.106211],[-96.010718,30.07667],[-95.992586,30.071485],[-95.983761,30.101642]]]},"properties":{"id":"77446","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.860349,31.379533],[-102.940098,31.407781],[-102.909497,31.335993],[-102.767365,31.293803],[-102.860349,31.379533]]]},"properties":{"id":"79742","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.919615,26.464815],[-97.912962,26.496862],[-97.924484,26.490035],[-97.927407,26.467089],[-97.919615,26.464815]]]},"properties":{"id":"78561","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.745444,30.51918],[-97.704685,30.478481],[-97.673946,30.480093],[-97.692668,30.558173],[-97.773758,30.588469],[-97.762756,30.549717],[-97.745444,30.51918]]]},"properties":{"id":"78681","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.862446,29.271028],[-97.875041,29.259644],[-97.8406,29.257375],[-97.862446,29.271028]]]},"properties":{"id":"78143","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.016449,26.725161],[-99.200522,26.656443],[-99.169416,26.571682],[-99.058415,26.644189],[-98.994299,26.707767],[-99.016449,26.725161]]]},"properties":{"id":"78564","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.788508,32.037349],[-95.945384,32.272445],[-95.963457,32.228134],[-96.052786,32.005895],[-96.029428,31.957779],[-95.973162,31.919268],[-95.886266,31.988994],[-95.788508,32.037349]]]},"properties":{"id":"75751","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.862679,31.23236],[-94.842947,31.146578],[-94.689117,31.097795],[-94.74538,31.262068],[-94.862679,31.23236]]]},"properties":{"id":"75941","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.522636,33.666837],[-98.605881,33.710707],[-98.791757,33.600974],[-98.861974,33.468041],[-98.607531,33.466983],[-98.523439,33.578359],[-98.522636,33.666837]]]},"properties":{"id":"76351","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.086615,35.116022],[-101.265321,34.748281],[-101.119232,34.598876],[-101.02388,34.525681],[-100.945576,34.491798],[-100.945401,34.494635],[-100.945337,34.574534],[-100.94522,34.629966],[-100.786464,34.748353],[-100.718071,34.762142],[-100.793221,34.861851],[-100.794444,34.910643],[-100.741024,34.882783],[-100.721403,35.033504],[-100.821283,35.183242],[-100.888149,35.183089],[-100.992187,35.151213],[-101.086615,35.116022]]]},"properties":{"id":"79226","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.062381,29.084542],[-98.065125,29.065522],[-98.090461,29.063759],[-98.299106,28.958725],[-98.322485,28.795134],[-98.160493,28.85264],[-98.061505,28.879314],[-97.901453,29.040286],[-97.880642,29.110146],[-97.969705,29.126482],[-98.062381,29.084542]]]},"properties":{"id":"78113","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.627971,29.156571],[-98.711041,29.137331],[-98.804788,29.151788],[-98.804878,29.107015],[-98.771835,28.991364],[-98.710232,28.991337],[-98.555207,28.985277],[-98.498728,29.059485],[-98.500415,29.089525],[-98.490395,29.107425],[-98.573927,29.171539],[-98.627971,29.156571]]]},"properties":{"id":"78065","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.54963,30.04739],[-96.544484,30.318563],[-96.640883,30.296786],[-96.682961,30.258851],[-96.633272,30.098402],[-96.567642,30.046892],[-96.54963,30.04739]]]},"properties":{"id":"77835","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.615472,33.842113],[-102.615478,33.972505],[-102.772375,33.873415],[-102.675093,33.824972],[-102.615447,33.825121],[-102.615472,33.842113]]]},"properties":{"id":"79324","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.675093,33.824972],[-102.772375,33.873415],[-102.790841,33.873586],[-102.80968,33.824896],[-102.881689,33.824686],[-102.899058,33.824672],[-103.051767,33.627747],[-102.981041,33.623521],[-102.727666,33.403546],[-102.608685,33.680132],[-102.610359,33.714192],[-102.675093,33.824972]]]},"properties":{"id":"79346","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.873018,33.824802],[-102.974339,33.824675],[-102.899058,33.824672],[-102.881689,33.824686],[-102.873018,33.824802]]]},"properties":{"id":"79344","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.498983,29.906691],[-99.473275,29.762577],[-99.41163,29.735809],[-99.215387,29.696669],[-99.133437,29.877056],[-99.179084,29.895259],[-99.346808,29.943393],[-99.498983,29.906691]]]},"properties":{"id":"78055","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.060427,30.042688],[-96.862452,30.065031],[-96.786506,30.321639],[-96.858067,30.326767],[-97.024923,30.231757],[-97.04821,30.137947],[-97.060427,30.042688]]]},"properties":{"id":"78942","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.623188,31.099381],[-97.617321,31.05695],[-97.594558,31.025037],[-97.445175,30.931133],[-97.400978,30.979822],[-97.393451,30.993412],[-97.466519,31.164609],[-97.637103,31.20267],[-97.62075,31.118497],[-97.623188,31.099381]]]},"properties":{"id":"76513","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.509483,30.871363],[-97.289795,30.816186],[-97.252942,30.860125],[-97.331357,30.956984],[-97.400978,30.979822],[-97.445175,30.931133],[-97.509483,30.871363]]]},"properties":{"id":"76534","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.428088,31.201828],[-97.334578,31.058609],[-97.350547,31.189132],[-97.355164,31.19034],[-97.428088,31.201828]],[[-97.365943,31.078756],[-97.361319,31.078371],[-97.364767,31.075379],[-97.365943,31.078756]]]},"properties":{"id":"76504","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.939554,30.806541],[-97.87706,30.774885],[-97.689025,30.819481],[-97.66628,30.878545],[-97.717422,30.931179],[-97.800815,30.902689],[-97.854133,30.932189],[-97.921476,30.883362],[-97.939554,30.806541]]]},"properties":{"id":"76527","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.333123,29.568118],[-98.32851,29.537378],[-98.280432,29.51099],[-98.333123,29.568118]]]},"properties":{"id":"78148","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.635135,29.360789],[-98.614518,29.32032],[-98.614378,29.320197],[-98.571578,29.360618],[-98.585719,29.365568],[-98.604074,29.371309],[-98.626624,29.367031],[-98.635135,29.360789]]]},"properties":{"id":"78242","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.274839,29.52866],[-98.280599,29.525762],[-98.27739,29.524041],[-98.272756,29.526353],[-98.274839,29.52866]]]},"properties":{"id":"78150","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.541841,32.961193],[-101.690162,32.751706],[-101.689682,32.68423],[-101.689137,32.598982],[-101.657571,32.594287],[-101.460297,32.620838],[-101.27999,32.64585],[-101.262568,32.775376],[-101.473143,32.961686],[-101.541841,32.961193]]]},"properties":{"id":"79738","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.689137,32.598982],[-101.712991,32.604176],[-101.861856,32.524471],[-101.938613,32.441848],[-101.924683,32.398308],[-101.92109,32.38734],[-101.764691,32.41982],[-101.594819,32.486463],[-101.657571,32.594287],[-101.689137,32.598982]]]},"properties":{"id":"79713","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.313528,33.464796],[-94.333826,33.557371],[-94.412527,33.568941],[-94.54546,33.425032],[-94.426626,33.327686],[-94.390046,33.408242],[-94.313528,33.464796]]]},"properties":{"id":"75570","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.596103,29.172419],[-95.739817,29.214897],[-95.772974,29.197351],[-95.666027,29.101009],[-95.615212,29.100003],[-95.596103,29.172419]]]},"properties":{"id":"77486","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.369643,30.658141],[-96.381294,30.645632],[-96.375803,30.626889],[-96.352587,30.619776],[-96.338278,30.63065],[-96.369643,30.658141]]]},"properties":{"id":"77801","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.835405,31.947009],[-98.86372,32.03678],[-98.870799,32.053424],[-99.034974,32.021323],[-98.966133,31.80485],[-98.882648,31.843208],[-98.835405,31.947009]]]},"properties":{"id":"76857","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.912773,31.003684],[-98.003866,30.967724],[-98.011854,30.923806],[-97.921476,30.883362],[-97.854133,30.932189],[-97.912773,31.003684]]]},"properties":{"id":"78608","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.826721,32.095428],[-99.728863,32.081991],[-99.594265,32.080945],[-99.630729,32.228109],[-99.715455,32.261851],[-100.004023,32.197603],[-100.021741,32.082206],[-99.826721,32.095428]]]},"properties":{"id":"79541","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.05638,32.629487],[-99.206622,32.448822],[-99.195278,32.406908],[-99.178773,32.29784],[-99.205926,32.295812],[-99.145744,32.217056],[-99.118404,32.124739],[-98.931917,32.270924],[-98.885921,32.314882],[-98.8524,32.51523],[-98.843135,32.582056],[-99.05638,32.629487]]]},"properties":{"id":"76437","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.315033,32.515075],[-99.206622,32.448822],[-99.05638,32.629487],[-99.095971,32.676454],[-99.300548,32.554572],[-99.315033,32.515075]]]},"properties":{"id":"76464","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.467073,26.175815],[-97.533556,26.029373],[-97.478683,26.043792],[-97.398005,26.049123],[-97.198166,26.082529],[-97.283788,26.264989],[-97.40534,26.251732],[-97.467073,26.175815]]]},"properties":{"id":"78566","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.599361,26.196548],[-97.467073,26.175815],[-97.40534,26.251732],[-97.528102,26.299273],[-97.599361,26.196548]]]},"properties":{"id":"78583","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.478683,26.043792],[-97.533556,26.029373],[-97.569325,26.017735],[-97.525826,25.997553],[-97.478683,26.043792]]]},"properties":{"id":"78575","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.342723,32.668985],[-94.295635,32.672584],[-94.043147,32.69303],[-94.043093,32.829676],[-94.218636,32.880605],[-94.281412,32.924509],[-94.430736,32.880091],[-94.587859,32.781361],[-94.590265,32.776471],[-94.576132,32.728879],[-94.39261,32.694381],[-94.342723,32.668985]]]},"properties":{"id":"75657","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.467436,33.20104],[-94.430767,33.14018],[-94.337903,33.074202],[-94.259313,33.237046],[-94.409973,33.273348],[-94.467436,33.20104]]]},"properties":{"id":"75560","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.196711,33.298561],[-94.114307,33.137718],[-94.0459,33.272368],[-94.196711,33.298561]]]},"properties":{"id":"75572","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.365752,34.313123],[-102.48854,34.391767],[-102.615435,34.212341],[-102.388685,34.169957],[-102.365752,34.313123]]]},"properties":{"id":"79031","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.097064,31.988319],[-95.239852,32.092572],[-95.419688,32.049899],[-95.427834,32.023785],[-95.449474,31.988952],[-95.437678,31.897983],[-95.404014,31.802859],[-95.151507,31.901644],[-95.142858,31.90656],[-95.039292,31.880865],[-94.985367,31.932311],[-94.985461,32.004556],[-95.086985,31.997541],[-95.097064,31.988319]]]},"properties":{"id":"75766","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.239852,32.092572],[-95.097064,31.988319],[-95.100075,32.005261],[-95.086985,31.997541],[-94.985461,32.004556],[-94.985145,32.15793],[-95.025413,32.215887],[-95.125003,32.232062],[-95.16995,32.218489],[-95.231829,32.140071],[-95.239852,32.092572]]]},"properties":{"id":"75789","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.097064,31.988319],[-95.086985,31.997541],[-95.100075,32.005261],[-95.097064,31.988319]]]},"properties":{"id":"75780","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.000381,34.616297],[-100.000382,34.81248],[-100.152814,34.747073],[-100.000381,34.616297]]]},"properties":{"id":"79230","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.347112,33.868765],[-98.42311,33.681166],[-98.423089,33.647355],[-98.421241,33.450961],[-98.260333,33.446258],[-98.196932,33.482872],[-98.072322,33.780502],[-97.979677,33.892208],[-98.102521,34.038966],[-98.186856,34.003643],[-98.22227,33.9591],[-98.347112,33.868765]]]},"properties":{"id":"76365","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.278491,34.13086],[-98.295064,34.056471],[-98.186856,34.003643],[-98.102521,34.038966],[-98.278491,34.13086]]]},"properties":{"id":"76357","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.677775,31.556364],[-99.60185,31.49195],[-99.574234,31.49368],[-99.543269,31.62837],[-99.613069,31.73383],[-99.718721,31.744491],[-99.720983,31.611497],[-99.721698,31.576759],[-99.677775,31.556364]]]},"properties":{"id":"76888","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.534214,31.807159],[-99.613069,31.73383],[-99.543269,31.62837],[-99.529751,31.642919],[-99.534214,31.807159]]]},"properties":{"id":"76884","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.769884,33.041303],[-96.704331,33.037459],[-96.690077,33.065954],[-96.772039,33.071726],[-96.770371,33.056012],[-96.769884,33.041303]]]},"properties":{"id":"75023","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.732663,33.125228],[-96.686229,33.075364],[-96.685506,33.073791],[-96.659682,33.129865],[-96.645626,33.157565],[-96.732663,33.125228]]]},"properties":{"id":"75013","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.859299,33.048237],[-96.849233,33.025937],[-96.843521,33.016492],[-96.815144,33.013329],[-96.786423,33.011775],[-96.769884,33.041303],[-96.770371,33.056012],[-96.842504,33.05695],[-96.859299,33.048237]]]},"properties":{"id":"75093","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.632641,33.006027],[-96.709022,32.997207],[-96.716221,32.975527],[-96.682679,32.973125],[-96.613377,32.984021],[-96.613368,32.987017],[-96.632641,33.006027]]]},"properties":{"id":"75082","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.659682,33.129865],[-96.532396,33.111697],[-96.547912,33.191992],[-96.628625,33.229253],[-96.645626,33.157565],[-96.659682,33.129865]]]},"properties":{"id":"75069","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.474734,33.247135],[-96.547912,33.191992],[-96.532396,33.111697],[-96.533657,33.078055],[-96.482266,33.044373],[-96.474231,33.043064],[-96.461228,33.097738],[-96.439933,33.234179],[-96.474734,33.247135]]]},"properties":{"id":"75407","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.206263,33.20479],[-96.295248,33.269923],[-96.439933,33.234179],[-96.461228,33.097738],[-96.314009,33.078224],[-96.296199,33.075403],[-96.272125,33.151635],[-96.206263,33.20479]]]},"properties":{"id":"75442","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.115617,33.466491],[-96.169072,33.442684],[-96.161441,33.430686],[-96.115617,33.466491]]],[[[-96.363749,33.406442],[-96.295304,33.291409],[-96.177579,33.376046],[-96.169326,33.42752],[-96.200871,33.45564],[-96.214638,33.475529],[-96.243823,33.462355],[-96.363749,33.406442]]]]},"properties":{"id":"75452","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.539606,35.009863],[-100.669115,34.76247],[-100.667565,34.748054],[-100.617499,34.714102],[-100.503953,34.587486],[-100.437493,34.571655],[-100.415794,34.560651],[-100.415895,34.747525],[-100.469855,34.834483],[-100.395536,35.03275],[-100.450264,35.154472],[-100.485443,35.182959],[-100.539049,35.145407],[-100.539606,35.009863]]]},"properties":{"id":"79245","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.491168,29.768992],[-96.417608,29.580277],[-96.373745,29.690415],[-96.469358,29.801381],[-96.491168,29.768992]]]},"properties":{"id":"78935","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.809387,29.577134],[-96.834139,29.596794],[-96.832812,29.576863],[-96.809387,29.577134]]]},"properties":{"id":"78951","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.375814,29.393271],[-96.383517,29.406377],[-96.393249,29.402291],[-96.385345,29.388082],[-96.375814,29.393271]]]},"properties":{"id":"77460","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.151587,29.934318],[-98.26939,29.976175],[-98.335841,29.810318],[-98.12127,29.912844],[-98.151587,29.934318]]]},"properties":{"id":"78133","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.393881,31.867474],[-98.505427,32.016705],[-98.712113,32.106019],[-98.851601,32.113149],[-98.870799,32.053424],[-98.86372,32.03678],[-98.74171,31.916855],[-98.72577,31.785394],[-98.668441,31.700514],[-98.557538,31.672142],[-98.483715,31.58751],[-98.422094,31.627378],[-98.398958,31.717459],[-98.403888,31.730563],[-98.393881,31.867474]]]},"properties":{"id":"76442","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.667722,32.297601],[-98.588784,32.241779],[-98.51269,32.205542],[-98.47554,32.376289],[-98.475372,32.388087],[-98.537909,32.378054],[-98.628627,32.315324],[-98.667722,32.297601]]]},"properties":{"id":"76445","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.431582,33.486341],[-97.486972,33.448187],[-97.420071,33.463825],[-97.431582,33.486341]]]},"properties":{"id":"76263","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.799205,32.818945],[-96.78961,32.81559],[-96.772401,32.849472],[-96.813168,32.851174],[-96.816246,32.826775],[-96.799205,32.818945]]]},"properties":{"id":"75205","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.780848,32.924073],[-96.818827,32.921478],[-96.814644,32.909585],[-96.809576,32.880068],[-96.770252,32.880018],[-96.769205,32.898713],[-96.769032,32.909357],[-96.780848,32.924073]]]},"properties":{"id":"75230","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.58644,32.704186],[-96.637504,32.694308],[-96.656934,32.637464],[-96.64546,32.636566],[-96.555516,32.691876],[-96.58644,32.704186]]]},"properties":{"id":"75253","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.873104,32.843867],[-96.849527,32.811366],[-96.828903,32.805805],[-96.81799,32.822453],[-96.853551,32.859758],[-96.873104,32.843867]],[[-96.840602,32.813486],[-96.841139,32.814878],[-96.839475,32.813587],[-96.840602,32.813486]]]},"properties":{"id":"75235","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.555516,32.691876],[-96.64546,32.636566],[-96.622198,32.617727],[-96.529987,32.545282],[-96.501023,32.497149],[-96.488664,32.653225],[-96.520969,32.693765],[-96.555516,32.691876]]]},"properties":{"id":"75159","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.716221,32.975527],[-96.748076,32.938314],[-96.705904,32.931232],[-96.683007,32.931278],[-96.682679,32.973125],[-96.716221,32.975527]]]},"properties":{"id":"75081","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.943226,32.662227],[-96.979868,32.656216],[-96.943239,32.626115],[-96.942971,32.647244],[-96.943226,32.662227]]]},"properties":{"id":"75249","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.821303,32.686538],[-96.823122,32.642086],[-96.745437,32.618574],[-96.693019,32.674487],[-96.713051,32.696408],[-96.821303,32.686538]]]},"properties":{"id":"75241","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.800502,32.769621],[-96.791491,32.753273],[-96.777533,32.749913],[-96.734043,32.723626],[-96.738078,32.755437],[-96.769781,32.778064],[-96.784058,32.777448],[-96.795785,32.77351],[-96.800502,32.769621]]]},"properties":{"id":"75215","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.857138,32.691234],[-96.891539,32.691459],[-96.891414,32.661913],[-96.891392,32.647499],[-96.884473,32.647512],[-96.857176,32.636467],[-96.857138,32.691234]]]},"properties":{"id":"75237","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.728879,32.804288],[-96.761652,32.793428],[-96.765309,32.783723],[-96.728461,32.777797],[-96.729189,32.791893],[-96.725816,32.804378],[-96.728879,32.804288]]]},"properties":{"id":"75223","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.91969,32.547835],[-96.890913,32.500496],[-96.824629,32.494203],[-96.75753,32.481395],[-96.711786,32.489215],[-96.718224,32.542035],[-96.822359,32.56711],[-96.899695,32.560279],[-96.91969,32.547835]]]},"properties":{"id":"75154","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.718224,32.542035],[-96.711786,32.489215],[-96.60449,32.483167],[-96.529987,32.545282],[-96.622198,32.617727],[-96.635916,32.610868],[-96.665665,32.560721],[-96.718224,32.542035]]]},"properties":{"id":"75125","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.840602,32.813486],[-96.839475,32.813587],[-96.841139,32.814878],[-96.840602,32.813486]]]},"properties":{"id":"75390","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.793064,33.482218],[-95.748324,33.434585],[-95.788216,33.486226],[-95.793064,33.482218]]]},"properties":{"id":"75415","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.096194,33.185628],[-97.152567,33.168075],[-97.072841,33.117849],[-97.03938,33.12992],[-97.096194,33.185628]]]},"properties":{"id":"76210","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.03938,33.12992],[-97.072841,33.117849],[-97.060992,33.103521],[-97.012013,33.087532],[-96.964239,33.083002],[-96.96443,33.100028],[-96.970945,33.1177],[-97.002366,33.14251],[-97.03938,33.12992]]]},"properties":{"id":"75065","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.204675,33.299225],[-97.34316,33.356749],[-97.389467,33.268249],[-97.215323,33.207286],[-97.204675,33.299225]]]},"properties":{"id":"76249","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.129382,32.913671],[-97.08723,32.989761],[-97.151221,33.017725],[-97.203106,32.942473],[-97.176724,32.922513],[-97.129382,32.913671]]]},"properties":{"id":"76092","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.293291,28.251212],[-99.27179,28.341473],[-99.170737,28.259617],[-98.96627,28.051402],[-98.803325,28.05748],[-98.801226,28.314293],[-98.874835,28.64695],[-99.395798,28.622642],[-99.395926,28.426299],[-99.529749,28.360836],[-99.614245,28.202091],[-99.325337,28.154631],[-99.293291,28.251212]]]},"properties":{"id":"78014","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.523723,27.265115],[-98.456237,27.264398],[-98.385503,27.263824],[-98.377015,27.416873],[-98.626557,27.583157],[-98.726846,27.499261],[-98.523723,27.265115]]]},"properties":{"id":"78376","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.87732,32.143089],[-98.77518,32.320097],[-98.885921,32.314882],[-98.931917,32.270924],[-98.87732,32.143089]]]},"properties":{"id":"76435","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.60449,32.483167],[-96.717535,32.376087],[-96.778852,32.247836],[-96.761661,32.22251],[-96.751751,32.198957],[-96.702902,32.169147],[-96.690827,32.174945],[-96.521383,32.19751],[-96.451038,32.294418],[-96.383082,32.328851],[-96.452138,32.358631],[-96.500576,32.494732],[-96.501023,32.497149],[-96.529987,32.545282],[-96.60449,32.483167]],[[-96.698741,32.264525],[-96.703646,32.262105],[-96.715339,32.278984],[-96.710967,32.281137],[-96.699761,32.270547],[-96.698741,32.264525]]]},"properties":{"id":"75119","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.761661,32.22251],[-96.792274,32.20458],[-96.781822,32.193334],[-96.751751,32.198957],[-96.761661,32.22251]]]},"properties":{"id":"76623","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.483442,31.803207],[-106.523316,31.806983],[-106.505443,31.762216],[-106.473482,31.771973],[-106.473455,31.776737],[-106.483442,31.803207]]]},"properties":{"id":"79902","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.443126,31.801254],[-106.473455,31.776737],[-106.473482,31.771973],[-106.422121,31.777715],[-106.417618,31.789078],[-106.443126,31.801254]]]},"properties":{"id":"79903","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.922147,31.251419],[-96.751092,31.178097],[-96.714568,31.245622],[-96.734637,31.385605],[-96.721962,31.406224],[-96.747466,31.419039],[-97.017623,31.410927],[-97.015067,31.387348],[-96.99558,31.309177],[-96.96227,31.275516],[-96.922147,31.251419]]]},"properties":{"id":"76661","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.854479,33.823731],[-95.969757,33.717019],[-96.009308,33.488891],[-95.857738,33.461722],[-95.810031,33.471576],[-95.815875,33.52706],[-95.79616,33.628634],[-95.790299,33.683183],[-95.854479,33.823731]]]},"properties":{"id":"75446","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.273764,32.624663],[-100.219221,32.414046],[-100.146013,32.609552],[-100.273764,32.624663]]]},"properties":{"id":"79561","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.309708,32.736853],[-100.145741,32.726536],[-100.246847,32.836498],[-100.309708,32.736853]]]},"properties":{"id":"79534","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.543778,32.738712],[-100.658208,32.796936],[-100.922325,32.526692],[-100.783395,32.525427],[-100.738091,32.545586],[-100.531925,32.583233],[-100.533866,32.674051],[-100.543778,32.738712]]]},"properties":{"id":"79526","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.118907,32.959834],[-100.279984,32.961129],[-100.294345,32.866065],[-100.246847,32.836498],[-100.145741,32.726536],[-100.084045,32.711505],[-99.986579,32.866224],[-99.985726,32.938876],[-100.118907,32.959834]]]},"properties":{"id":"79520","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.475256,33.913989],[-99.132236,34.053392],[-99.089224,34.186809],[-99.082196,34.210598],[-99.409409,34.372977],[-99.429073,34.345391],[-99.384895,34.350169],[-99.391334,34.321919],[-99.426613,34.312981],[-99.504396,34.076287],[-99.475256,33.913989]]]},"properties":{"id":"76384","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.864574,29.693168],[-95.886874,29.592953],[-95.790604,29.59545],[-95.708453,29.574825],[-95.683972,29.601532],[-95.775477,29.700738],[-95.864574,29.693168]]]},"properties":{"id":"77406","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.773835,29.875155],[-95.808336,29.950124],[-95.872965,29.91983],[-95.868966,29.908828],[-95.8826,29.786303],[-95.776804,29.784947],[-95.773835,29.875155]]]},"properties":{"id":"77493","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.419592,29.613628],[-95.464938,29.609437],[-95.476704,29.604968],[-95.504924,29.567618],[-95.442458,29.554424],[-95.431027,29.583509],[-95.419592,29.613628]]]},"properties":{"id":"77053","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.708453,29.574825],[-95.555006,29.503881],[-95.582826,29.580582],[-95.633823,29.620143],[-95.683419,29.601611],[-95.683972,29.601532],[-95.708453,29.574825]]]},"properties":{"id":"77479","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.463734,29.647365],[-95.516131,29.627437],[-95.518385,29.626588],[-95.476704,29.604968],[-95.464938,29.609437],[-95.463734,29.647365]]]},"properties":{"id":"77085","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.326635,31.437374],[-96.146718,31.454635],[-96.099737,31.500665],[-96.312847,31.526669],[-96.326635,31.437374]]]},"properties":{"id":"75838","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.848695,28.647201],[-99.411735,28.838022],[-99.409021,28.640566],[-99.395798,28.622642],[-98.874835,28.64695],[-98.848695,28.647201]]]},"properties":{"id":"78017","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.011494,29.526174],[-95.045077,29.555614],[-95.069201,29.556797],[-95.095222,29.528923],[-95.160405,29.497649],[-95.178389,29.4678],[-95.173023,29.464775],[-94.995117,29.498785],[-95.011494,29.526174]]]},"properties":{"id":"77573","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.581636,29.491084],[-94.686466,29.451493],[-94.776951,29.376545],[-94.728511,29.367821],[-94.693607,29.415082],[-94.568063,29.478781],[-94.581636,29.491084]]]},"properties":{"id":"77650","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.303996,30.499832],[-99.130532,30.365546],[-99.070061,30.498446],[-99.303996,30.499832]]]},"properties":{"id":"78618","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.929926,29.831913],[-99.846068,29.77108],[-99.694712,29.943492],[-99.368067,30.103323],[-99.265489,30.134585],[-99.229754,30.134897],[-99.496111,30.288359],[-99.754142,30.290698],[-99.917708,29.946761],[-99.929926,29.831913]],[[-99.345354,30.158306],[-99.345523,30.159827],[-99.34433,30.159433],[-99.345354,30.158306]]]},"properties":{"id":"78058","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.177976,32.329018],[-102.039697,32.086794],[-102.031634,32.086802],[-101.932473,32.086857],[-101.775878,31.907452],[-101.709813,31.888969],[-101.586132,31.945966],[-101.69058,32.37634],[-101.764691,32.41982],[-101.92109,32.38734],[-101.903183,32.26391],[-102.177976,32.329018]]]},"properties":{"id":"79782","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.454746,28.847577],[-97.3822,28.836562],[-97.36786,28.865336],[-97.454746,28.847577]]]},"properties":{"id":"77993","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.72802,35.488725],[-100.842698,35.398362],[-100.815638,35.3332],[-100.773924,35.406212],[-100.72802,35.488725]]]},"properties":{"id":"79054","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.779225,33.733959],[-96.847368,33.67287],[-96.782907,33.628819],[-96.764914,33.620613],[-96.606528,33.553048],[-96.604203,33.687052],[-96.682795,33.731406],[-96.779225,33.733959]]]},"properties":{"id":"75092","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.833786,33.873525],[-96.884505,33.783181],[-96.799912,33.784852],[-96.786841,33.865243],[-96.833786,33.873525]]]},"properties":{"id":"76245","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.16837,30.822997],[-96.182458,30.617135],[-96.18176,30.607611],[-96.027946,30.66139],[-96.013432,30.842661],[-96.16837,30.822997]]]},"properties":{"id":"77861","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.849457,30.620022],[-95.924446,30.591991],[-95.944074,30.584537],[-95.81684,30.492638],[-95.699179,30.561716],[-95.764177,30.636594],[-95.849457,30.620022]]]},"properties":{"id":"77873","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.804707,30.342618],[-95.583173,30.351645],[-95.570066,30.36386],[-95.598878,30.50898],[-95.663832,30.543214],[-95.699179,30.561716],[-95.81684,30.492638],[-95.813355,30.448462],[-95.804707,30.342618]]]},"properties":{"id":"77356","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.77334,29.741852],[-97.780917,29.759258],[-97.800194,29.765932],[-97.823411,29.75118],[-97.873567,29.763874],[-97.943257,29.737389],[-97.739639,29.509381],[-97.683873,29.582812],[-97.737095,29.711389],[-97.77334,29.741852]]]},"properties":{"id":"78638","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.782859,33.919753],[-101.731503,33.977694],[-102.04067,34.159268],[-102.118922,34.02705],[-102.119364,33.997254],[-102.110237,33.939498],[-102.086791,33.924961],[-102.054876,33.948565],[-102.010299,33.944476],[-102.069884,34.002584],[-101.992846,33.997091],[-102.01022,33.938451],[-102.009905,33.913774],[-101.782859,33.919753]]]},"properties":{"id":"79041","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.571088,30.417247],[-94.396213,30.246311],[-94.283697,30.196053],[-94.192046,30.285576],[-94.251827,30.526711],[-94.26409,30.526761],[-94.334503,30.526813],[-94.40172,30.482805],[-94.464355,30.527026],[-94.545717,30.526977],[-94.580972,30.490829],[-94.571088,30.417247]]]},"properties":{"id":"77625","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.531124,30.171813],[-94.626603,30.315936],[-94.67528,30.331209],[-94.665835,30.305062],[-94.614838,30.163539],[-94.531124,30.171813]]]},"properties":{"id":"77519","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.698087,30.308747],[-94.648605,30.133103],[-94.600843,30.124371],[-94.614838,30.163539],[-94.665835,30.305062],[-94.698087,30.308747]]]},"properties":{"id":"77564","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.512028,30.006695],[-95.496427,29.988955],[-95.464393,30.00398],[-95.476371,30.024371],[-95.512028,30.006695]]]},"properties":{"id":"77068","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.832967,30.186513],[-95.872965,29.91983],[-95.808336,29.950124],[-95.777055,30.037074],[-95.735094,30.093721],[-95.832967,30.186513]]]},"properties":{"id":"77447","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.501172,29.983657],[-95.481228,29.964893],[-95.422915,29.965982],[-95.464393,30.00398],[-95.496427,29.988955],[-95.501172,29.983657]]]},"properties":{"id":"77014","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.255267,29.712771],[-95.228088,29.690563],[-95.172712,29.691757],[-95.172964,29.746931],[-95.240725,29.719336],[-95.243021,29.719087],[-95.255267,29.712771]]]},"properties":{"id":"77506","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.172234,29.665319],[-95.17112,29.63057],[-95.142735,29.631221],[-95.084373,29.613209],[-95.11132,29.650786],[-95.110853,29.663923],[-95.13512,29.66922],[-95.172234,29.665319]]]},"properties":{"id":"77505","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.093043,29.755652],[-95.158302,29.805595],[-95.212797,29.805205],[-95.218354,29.771169],[-95.225191,29.746676],[-95.240725,29.719336],[-95.172964,29.746931],[-95.146064,29.736096],[-95.11023,29.740137],[-95.093043,29.755652]]]},"properties":{"id":"77015","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.683296,29.879941],[-95.718929,29.784688],[-95.644788,29.784716],[-95.617232,29.790612],[-95.617275,29.835998],[-95.624407,29.872951],[-95.676126,29.878961],[-95.683296,29.879941]]]},"properties":{"id":"77084","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.289038,29.851746],[-95.261614,29.810916],[-95.229925,29.82456],[-95.240685,29.89864],[-95.257285,29.882542],[-95.289038,29.851746]]]},"properties":{"id":"77078","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.431041,29.660414],[-95.441859,29.656317],[-95.441728,29.655958],[-95.431041,29.660414]]],[[[-95.414363,29.694449],[-95.43084,29.660462],[-95.396328,29.670542],[-95.381778,29.675439],[-95.386583,29.700145],[-95.414363,29.694449]]]]},"properties":{"id":"77054","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.4478,29.723862],[-95.484455,29.69103],[-95.447054,29.689957],[-95.44739,29.705918],[-95.4478,29.723862]]]},"properties":{"id":"77401","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.44787,29.72727],[-95.4478,29.723862],[-95.44739,29.705918],[-95.423018,29.706129],[-95.390258,29.724958],[-95.390253,29.726079],[-95.402073,29.72665],[-95.427966,29.728586],[-95.44787,29.72727]]]},"properties":{"id":"77005","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.543969,29.78431],[-95.438408,29.78396],[-95.450525,29.794468],[-95.493681,29.825762],[-95.544668,29.794282],[-95.543969,29.78431]]]},"properties":{"id":"77055","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.229925,29.82456],[-95.095375,29.880235],[-95.131541,29.987667],[-95.195996,29.941986],[-95.240685,29.89864],[-95.229925,29.82456]]]},"properties":{"id":"77044","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.446686,29.673226],[-95.508673,29.656011],[-95.516131,29.627437],[-95.463734,29.647365],[-95.441728,29.655958],[-95.441859,29.656317],[-95.446686,29.673226]]]},"properties":{"id":"77035","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.520145,32.624195],[-94.443485,32.39415],[-94.342723,32.668985],[-94.39261,32.694381],[-94.520145,32.624195]]]},"properties":{"id":"75670","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.042947,32.574683],[-94.217503,32.546404],[-94.157956,32.376785],[-94.141167,32.37234],[-94.042901,32.392283],[-94.042947,32.574683]]]},"properties":{"id":"75692","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.39261,32.694381],[-94.576132,32.728879],[-94.631623,32.638704],[-94.520145,32.624195],[-94.39261,32.694381]]]},"properties":{"id":"75651","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.147315,35.619379],[-100.340085,35.619464],[-100.303439,35.546474],[-100.147515,35.546174],[-100.147315,35.619379]]]},"properties":{"id":"79011","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.150479,32.214127],[-96.069346,32.085346],[-96.077135,32.199802],[-96.121318,32.246993],[-96.150479,32.214127]]]},"properties":{"id":"75163","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.055269,32.338656],[-96.167668,32.357996],[-96.121318,32.246993],[-96.077135,32.199802],[-96.035846,32.231172],[-96.055269,32.338656]]]},"properties":{"id":"75156","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.077135,32.199802],[-96.069346,32.085346],[-96.05478,32.012526],[-96.052786,32.005895],[-95.963457,32.228134],[-96.035846,32.231172],[-96.077135,32.199802]]]},"properties":{"id":"75148","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.204959,26.066418],[-98.213417,26.143567],[-98.258124,26.127715],[-98.270243,26.108072],[-98.204959,26.066418]]]},"properties":{"id":"78557","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.181772,33.560438],[-102.174873,33.653171],[-102.174419,33.689753],[-102.196978,33.689967],[-102.181772,33.560438]]]},"properties":{"id":"79367","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.417946,35.740987],[-101.497726,35.63646],[-101.496257,35.624204],[-101.326208,35.650023],[-101.314222,35.799037],[-101.378939,35.803251],[-101.417946,35.740987]]]},"properties":{"id":"79007","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.678822,33.274769],[-97.794665,33.381461],[-97.891411,33.433958],[-97.993077,33.348883],[-97.918902,33.237013],[-97.687802,33.261495],[-97.678822,33.274769]]]},"properties":{"id":"76431","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.658044,28.820487],[-96.711851,28.845146],[-96.69933,28.778099],[-96.643442,28.712105],[-96.610377,28.721384],[-96.658044,28.820487]]]},"properties":{"id":"77969","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.44491,30.113015],[-94.442235,29.889538],[-94.357403,29.890085],[-94.338767,29.922995],[-94.337204,30.000985],[-94.405697,30.099914],[-94.44491,30.113015]]]},"properties":{"id":"77629","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.855666,27.949342],[-98.099617,28.101655],[-98.235497,28.057966],[-98.235173,27.950802],[-97.934039,27.867006],[-97.855666,27.949342]]]},"properties":{"id":"78372","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.431048,32.473286],[-96.458715,32.465415],[-96.440613,32.454706],[-96.431048,32.473286]]]},"properties":{"id":"75157","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.345354,30.158306],[-99.34433,30.159433],[-99.345523,30.159827],[-99.345354,30.158306]]]},"properties":{"id":"78029","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.285431,33.507393],[-95.154973,33.515655],[-95.201179,33.558182],[-95.285431,33.507393]]],[[[-95.356094,33.390701],[-95.308637,33.380662],[-95.309285,33.495112],[-95.310457,33.540534],[-95.347837,33.585331],[-95.356094,33.390701]]]]},"properties":{"id":"75435","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.533013,33.740623],[-95.625674,33.485613],[-95.588183,33.470304],[-95.454087,33.434572],[-95.404838,33.589756],[-95.334575,33.805712],[-95.324465,33.840431],[-95.533013,33.740623]]]},"properties":{"id":"75462","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.454087,33.434572],[-95.356094,33.390701],[-95.347837,33.585331],[-95.404838,33.589756],[-95.454087,33.434572]]]},"properties":{"id":"75468","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.79616,33.628634],[-95.815875,33.52706],[-95.771808,33.607674],[-95.79616,33.628634]]]},"properties":{"id":"75470","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.110237,33.939498],[-102.119364,33.997254],[-102.183642,33.911547],[-102.110237,33.939498]]]},"properties":{"id":"79369","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.215334,34.130193],[-102.257715,34.112296],[-102.297649,34.011032],[-102.144627,34.064938],[-102.215334,34.130193]]]},"properties":{"id":"79326","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.784059,31.094023],[-96.022932,31.204675],[-96.029949,31.188854],[-95.988775,31.086167],[-95.927762,31.092562],[-95.784059,31.094023]]]},"properties":{"id":"75850","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.317205,31.3255],[-96.097153,31.214575],[-96.029949,31.188854],[-96.022932,31.204675],[-96.028149,31.287547],[-96.146718,31.454635],[-96.326635,31.437374],[-96.329518,31.388051],[-96.317205,31.3255]]]},"properties":{"id":"75846","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.330366,36.187278],[-100.000399,36.055677],[-100.000402,36.273467],[-100.182733,36.261082],[-100.330366,36.187278]]]},"properties":{"id":"79046","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.870403,33.529365],[-101.87046,33.562346],[-101.870521,33.563277],[-101.90514,33.562291],[-101.909823,33.531802],[-101.907037,33.531046],[-101.870403,33.529365]]]},"properties":{"id":"79413","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.148264,31.537302],[-97.12337,31.559257],[-97.133934,31.564911],[-97.153192,31.543004],[-97.148264,31.537302]]]},"properties":{"id":"76701","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.626842,32.879501],[-94.664299,32.958677],[-94.724788,32.910218],[-94.719942,32.904502],[-94.700131,32.85036],[-94.626842,32.879501]]]},"properties":{"id":"75668","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.766958,28.749125],[-95.865846,28.763025],[-95.866357,28.723406],[-95.766958,28.749125]]]},"properties":{"id":"77483","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.289021,28.397778],[-100.240766,28.474473],[-100.312016,28.527332],[-100.289021,28.397778]]]},"properties":{"id":"78860","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.547094,28.986411],[-100.631611,28.902839],[-100.573536,28.845815],[-100.547094,28.986411]]]},"properties":{"id":"78877","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.208056,29.230721],[-99.08582,29.202532],[-98.949636,29.29672],[-98.923306,29.404591],[-98.946442,29.442068],[-99.082244,29.549643],[-99.262695,29.625704],[-99.378833,29.627425],[-99.355986,29.483572],[-99.208056,29.230721]]]},"properties":{"id":"78861","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.093207,32.086861],[-102.120392,32.005687],[-102.057304,32.020398],[-101.995109,32.086772],[-102.031634,32.086802],[-102.039697,32.086794],[-102.093207,32.086861]]]},"properties":{"id":"79705","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.825736,30.785978],[-96.713845,30.72326],[-96.624663,30.734357],[-96.679176,30.844048],[-96.825736,30.785978]]]},"properties":{"id":"77857","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.120712,32.337027],[-101.035263,32.086734],[-101.01892,32.08673],[-100.66411,32.158446],[-100.664008,32.230204],[-100.783395,32.525427],[-100.922325,32.526692],[-100.946798,32.527018],[-101.025369,32.527913],[-101.174489,32.559998],[-101.174697,32.493291],[-101.17509,32.480466],[-101.120712,32.337027]]]},"properties":{"id":"79512","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.45681,30.265385],[-95.38447,30.293612],[-95.397798,30.325086],[-95.476842,30.333958],[-95.45681,30.265385]]]},"properties":{"id":"77301","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.476842,30.333958],[-95.397798,30.325086],[-95.305665,30.344315],[-95.263338,30.384259],[-95.486692,30.391247],[-95.476842,30.333958]]]},"properties":{"id":"77303","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.544979,30.258085],[-95.522531,30.227566],[-95.479943,30.191384],[-95.453951,30.193645],[-95.457585,30.244957],[-95.493642,30.267896],[-95.544979,30.258085]]]},"properties":{"id":"77384","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.345124,30.646554],[-95.663832,30.543214],[-95.598878,30.50898],[-95.491265,30.508833],[-95.269829,30.526027],[-95.236819,30.546536],[-95.269615,30.652044],[-95.345124,30.646554]]]},"properties":{"id":"77358","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.970252,35.620057],[-101.934985,35.620011],[-101.939417,35.667034],[-102.015621,35.690689],[-102.018382,35.690701],[-101.970252,35.620057]]]},"properties":{"id":"79058","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.914151,34.134139],[-101.025223,33.954076],[-101.0414,33.905232],[-100.995541,33.90472],[-100.666839,34.011891],[-100.828746,34.170502],[-100.914151,34.134139]]]},"properties":{"id":"79244","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.944833,31.814431],[-94.905427,31.822496],[-94.929345,31.845445],[-94.944833,31.814431]]]},"properties":{"id":"75788","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.669921,32.156411],[-96.604098,32.003172],[-96.525752,31.944788],[-96.424247,31.939606],[-96.469716,32.164074],[-96.521383,32.19751],[-96.690827,32.174945],[-96.669921,32.156411]]]},"properties":{"id":"75110","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.469631,27.78567],[-97.547455,27.787462],[-97.570243,27.783403],[-97.547534,27.749936],[-97.503845,27.748958],[-97.469772,27.764117],[-97.461236,27.785737],[-97.469631,27.78567]]]},"properties":{"id":"78406","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.701393,30.127406],[-93.915707,30.130568],[-94.031776,30.058812],[-94.03058,30.026222],[-93.997834,30.013677],[-93.896146,29.984619],[-93.871738,29.98098],[-93.744544,30.019281],[-93.701393,30.127406]]]},"properties":{"id":"77630","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.376334,32.738191],[-98.270273,32.644021],[-98.172597,32.702819],[-98.211864,32.823808],[-98.405527,32.794369],[-98.376334,32.738191]]]},"properties":{"id":"76484","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.648544,32.045687],[-94.606036,31.958026],[-94.581787,31.953137],[-94.480245,32.026851],[-94.48043,32.038892],[-94.596679,32.102391],[-94.648544,32.045687]]]},"properties":{"id":"75669","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.687486,33.017147],[-97.603261,32.979636],[-97.526932,33.006934],[-97.539821,33.099622],[-97.611112,33.10921],[-97.687486,33.017147]]]},"properties":{"id":"76023","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.020112,34.611396],[-102.781024,34.437876],[-102.833731,34.594127],[-103.020112,34.611396]]]},"properties":{"id":"79009","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.835609,35.18286],[-101.906567,35.121726],[-101.919054,35.043264],[-101.626376,34.929653],[-101.625956,34.952702],[-101.622941,35.183117],[-101.622993,35.206998],[-101.653695,35.207049],[-101.742197,35.2007],[-101.76006,35.203152],[-101.778776,35.192674],[-101.835609,35.18286]]]},"properties":{"id":"79118","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.200461,29.57881],[-104.210746,29.477499],[-103.792703,29.262499],[-103.793416,29.538086],[-104.18491,29.634696],[-104.200461,29.57881]]]},"properties":{"id":"79846","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.093993,31.871775],[-99.854462,31.866371],[-99.760647,31.959609],[-99.759481,32.050054],[-99.763023,32.081631],[-99.826721,32.095428],[-100.021741,32.082206],[-100.174707,31.937249],[-100.160667,31.919531],[-100.093993,31.871775]]]},"properties":{"id":"79567","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.34941,32.3915],[-95.362228,32.34276],[-95.34066,32.342672],[-95.267221,32.341802],[-95.257949,32.344566],[-95.265652,32.349828],[-95.293797,32.373955],[-95.34941,32.3915]]]},"properties":{"id":"75702","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.267221,32.341802],[-95.34066,32.342672],[-95.337264,32.304867],[-95.273881,32.310964],[-95.267221,32.341802]]]},"properties":{"id":"75701","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.485074,26.69712],[-98.367704,26.685771],[-98.32067,26.783081],[-98.40157,26.783404],[-98.485074,26.69712]]]},"properties":{"id":"78588","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.366061,32.688891],[-97.356136,32.687877],[-97.31294,32.700013],[-97.311065,32.705944],[-97.352452,32.736008],[-97.358787,32.732802],[-97.364298,32.723214],[-97.366061,32.688891]]]},"properties":{"id":"76110","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.140166,32.793901],[-97.14011,32.788387],[-97.114188,32.775677],[-97.06317,32.767131],[-97.062681,32.802114],[-97.140163,32.80353],[-97.14017,32.803311],[-97.140166,32.793901]]]},"properties":{"id":"76006","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.41362,32.644793],[-97.355057,32.634811],[-97.35259,32.667257],[-97.356136,32.687877],[-97.366061,32.688891],[-97.381192,32.675067],[-97.41362,32.644793]]]},"properties":{"id":"76133","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.347476,32.842585],[-97.391991,32.924531],[-97.431476,32.992331],[-97.512607,32.993935],[-97.503396,32.875341],[-97.394216,32.822363],[-97.347476,32.842585]]]},"properties":{"id":"76179","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.250588,32.963515],[-97.258987,32.894914],[-97.238531,32.892414],[-97.187986,32.911661],[-97.176724,32.922513],[-97.203106,32.942473],[-97.250588,32.963515]]]},"properties":{"id":"76248","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.237598,32.720684],[-97.297943,32.711767],[-97.311065,32.705944],[-97.31294,32.700013],[-97.306024,32.669581],[-97.231466,32.668113],[-97.20883,32.713174],[-97.237598,32.720684]]]},"properties":{"id":"76119","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.751131,32.396205],[-99.812731,32.417063],[-99.828787,32.40927],[-99.867523,32.412022],[-99.909058,32.399014],[-99.910832,32.300006],[-99.843491,32.31279],[-99.795831,32.302429],[-99.752083,32.321167],[-99.751131,32.396205]]]},"properties":{"id":"79606","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.728863,32.081991],[-99.826721,32.095428],[-99.763023,32.081631],[-99.728863,32.081991]]]},"properties":{"id":"79530","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.674841,31.630815],[-100.688204,31.594717],[-100.634483,31.561627],[-100.674841,31.630815]]]},"properties":{"id":"76934","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.863778,31.615601],[-101.078027,31.559357],[-100.755678,31.524723],[-100.693299,31.523956],[-100.688204,31.594717],[-100.674841,31.630815],[-100.675726,31.694343],[-100.716247,31.694752],[-100.863778,31.615601]]]},"properties":{"id":"76958","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.735994,30.250611],[-97.749367,30.215835],[-97.683288,30.22229],[-97.676704,30.222237],[-97.69128,30.245176],[-97.735994,30.250611]]]},"properties":{"id":"78741","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.721149,30.455835],[-97.79526,30.465615],[-97.769209,30.434563],[-97.720718,30.453129],[-97.721149,30.455835]]]},"properties":{"id":"78729","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.738107,30.280688],[-97.730048,30.278506],[-97.727401,30.283078],[-97.738107,30.280688]]]},"properties":{"id":"78712","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.75091,30.279995],[-97.754495,30.264975],[-97.735994,30.250611],[-97.730048,30.278506],[-97.738107,30.280688],[-97.75091,30.279995]]]},"properties":{"id":"78701","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.735994,30.250611],[-97.69128,30.245176],[-97.690258,30.24581],[-97.701711,30.282911],[-97.703945,30.282572],[-97.730048,30.278506],[-97.735994,30.250611]]]},"properties":{"id":"78702","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.501448,30.477578],[-97.439647,30.414406],[-97.281784,30.488188],[-97.292995,30.539916],[-97.501448,30.477578]]]},"properties":{"id":"78615","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.073322,31.228745],[-94.887049,31.09862],[-94.842947,31.146578],[-94.862679,31.23236],[-94.95811,31.38693],[-95.073322,31.228745]]]},"properties":{"id":"75926","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.251827,30.526711],[-94.071689,30.526033],[-94.078268,30.547211],[-94.083343,30.593577],[-94.234253,30.61416],[-94.241127,30.615975],[-94.26409,30.526761],[-94.251827,30.526711]]]},"properties":{"id":"77616","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.455204,29.772459],[-101.521296,30.034775],[-101.7584,30.288044],[-101.758546,30.259866],[-101.760924,29.781802],[-101.455204,29.772459]]]},"properties":{"id":"78871","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.075708,32.547542],[-95.955035,32.40664],[-95.862526,32.406881],[-95.81274,32.416933],[-95.701116,32.51686],[-95.698226,32.540647],[-95.828117,32.626345],[-95.829197,32.662388],[-95.860224,32.594207],[-96.075799,32.563623],[-96.075708,32.547542]]]},"properties":{"id":"75103","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.878452,28.845735],[-96.768804,28.725363],[-96.768564,28.725046],[-96.69933,28.778099],[-96.711851,28.845146],[-96.938587,29.063244],[-96.987136,29.020683],[-96.940038,28.827065],[-96.923442,28.834639],[-96.900176,28.846813],[-96.878452,28.845735]]]},"properties":{"id":"77968","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.97113,31.491089],[-103.120333,31.532577],[-103.268378,31.490618],[-103.139205,31.407544],[-103.092473,31.466273],[-102.944002,31.424865],[-102.97113,31.491089]]]},"properties":{"id":"79777","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.962416,31.585851],[-102.998187,31.574936],[-102.994125,31.552857],[-102.956011,31.572064],[-102.962416,31.585851]]]},"properties":{"id":"79788","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-99.0681,34.126946],[-98.953056,34.150151],[-98.952395,34.212497],[-99.082196,34.210598],[-99.089224,34.186809],[-99.0681,34.126946]]],[[[-98.953028,34.106528],[-99.044312,34.086837],[-99.086843,34.010642],[-98.962994,34.029717],[-98.953028,34.106528]]]]},"properties":{"id":"76364","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.434298,26.590219],[-97.450665,26.526529],[-97.418385,26.526911],[-97.434298,26.590219]]]},"properties":{"id":"78598","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.609439,30.571847],[-97.692854,30.571219],[-97.692668,30.558173],[-97.60125,30.533533],[-97.609439,30.571847]]]},"properties":{"id":"78665","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.651837,30.700951],[-97.828823,30.696252],[-97.860148,30.639427],[-97.773758,30.588469],[-97.692668,30.558173],[-97.692854,30.571219],[-97.651837,30.700951]]]},"properties":{"id":"78628","state_code":"48"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.198496,42.001164],[-112.313026,41.981577],[-112.360388,41.898931],[-112.220397,41.900125],[-112.198496,42.001164]]]},"properties":{"id":"84331","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.6208,41.821929],[-113.179159,41.891614],[-113.548112,41.887439],[-113.620586,41.864228],[-113.6208,41.821929]]]},"properties":{"id":"84329","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.160788,41.78478],[-112.149377,41.784955],[-112.148899,41.770394],[-112.140507,41.772011],[-112.137213,41.785265],[-112.145831,41.792285],[-112.160397,41.792089],[-112.160788,41.78478]]]},"properties":{"id":"84334","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.083055,41.336942],[-112.028246,41.336582],[-112.031516,41.439369],[-112.056534,41.445422],[-112.083055,41.336942]]]},"properties":{"id":"84340","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.591212,41.835195],[-112.588918,41.718659],[-112.402771,41.639086],[-112.353985,41.661064],[-112.3586,41.815214],[-112.54439,41.89659],[-112.591212,41.835195]]]},"properties":{"id":"84316","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.943663,41.940797],[-111.977155,41.917492],[-111.949993,41.888457],[-111.908744,41.896871],[-111.896508,41.913948],[-111.943663,41.940797]]]},"properties":{"id":"84338","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.750755,39.568169],[-110.778071,39.528248],[-110.714165,39.531251],[-110.750755,39.568169]]]},"properties":{"id":"84542","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.1612,40.714693],[-109.048638,40.835112],[-109.258231,40.858107],[-109.1612,40.714693]]]},"properties":{"id":"84023","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.987759,41.104665],[-111.956074,41.116583],[-111.991356,41.148681],[-112.021651,41.152508],[-112.023275,41.152444],[-111.987759,41.104665]]]},"properties":{"id":"84056","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.900804,40.919038],[-111.962695,40.849189],[-111.954409,40.848988],[-111.899366,40.861342],[-111.891162,40.906611],[-111.900804,40.919038]]]},"properties":{"id":"84087","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.900804,40.919038],[-111.891162,40.906611],[-111.862891,40.906665],[-111.875067,40.952538],[-111.900878,40.952086],[-111.900804,40.919038]]]},"properties":{"id":"84014","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.190125,40.149318],[-109.870159,40.100216],[-109.859392,40.215279],[-109.916035,40.258663],[-110.143042,40.243996],[-110.142971,40.21865],[-110.190125,40.149318]]]},"properties":{"id":"84052","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.32861,40.334154],[-110.24975,40.289887],[-110.247427,40.403103],[-110.313421,40.357655],[-110.32861,40.334154]]]},"properties":{"id":"84001","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.827603,40.363667],[-110.709556,40.258757],[-110.505612,40.325348],[-110.537223,40.449928],[-110.827603,40.363667]]]},"properties":{"id":"84072","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.136771,39.022796],[-111.295661,38.869302],[-111.195065,38.835401],[-111.136771,39.022796]]]},"properties":{"id":"84522","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.759818,39.152418],[-110.025402,38.499981],[-109.878844,38.684416],[-109.865019,38.993666],[-109.759818,39.152418]]]},"properties":{"id":"84525","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.096963,37.659075],[-112.23196,37.536564],[-112.204737,37.536591],[-112.129073,37.573563],[-112.133239,37.613756],[-112.096963,37.659075]]]},"properties":{"id":"84764","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.736766,38.026316],[-112.753819,37.783402],[-112.688965,37.728358],[-112.679954,37.805726],[-112.736766,38.026316]]]},"properties":{"id":"84760","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.092569,39.929262],[-112.109719,39.970529],[-112.146713,39.942131],[-112.100999,39.903291],[-112.070933,39.910529],[-112.092569,39.929262]]]},"properties":{"id":"84628","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.563126,37.49175],[-112.53826,37.426922],[-112.460291,37.542761],[-112.487418,37.542688],[-112.563126,37.49175]]]},"properties":{"id":"84710","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.402039,38.823288],[-112.286107,39.063119],[-112.362375,39.081374],[-112.4213,38.885952],[-112.402039,38.823288]]]},"properties":{"id":"84631","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.192331,38.230509],[-112.211582,38.349823],[-112.211898,38.350378],[-112.280042,38.225546],[-112.207143,38.20841],[-112.192331,38.230509]]]},"properties":{"id":"84740","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.847263,40.768052],[-111.876784,40.769352],[-111.876775,40.749786],[-111.85247,40.751699],[-111.852353,40.75849],[-111.847263,40.768052]]]},"properties":{"id":"84102","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.026462,40.669628],[-111.977011,40.667549],[-111.96807,40.728156],[-112.024501,40.724736],[-112.026462,40.669628]]]},"properties":{"id":"84120","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.913317,40.452563],[-111.848904,40.458163],[-111.804519,40.46546],[-111.822322,40.485053],[-111.852762,40.529667],[-111.872005,40.54431],[-111.894573,40.544312],[-111.917536,40.537041],[-111.913317,40.452563]]]},"properties":{"id":"84020","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.938749,40.635244],[-111.921408,40.627719],[-111.903962,40.630974],[-111.902693,40.6867],[-111.908655,40.687007],[-111.93884,40.667653],[-111.938749,40.635244]]]},"properties":{"id":"84123","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.912399,40.595887],[-111.870862,40.608718],[-111.853397,40.610549],[-111.84796,40.615156],[-111.8659,40.630055],[-111.903962,40.630974],[-111.921408,40.627719],[-111.921291,40.609459],[-111.912399,40.595887]]]},"properties":{"id":"84047","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.799655,40.649338],[-111.834032,40.687088],[-111.865518,40.687057],[-111.865542,40.674305],[-111.80595,40.660118],[-111.799655,40.649338]]]},"properties":{"id":"84124","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.365616,38.305454],[-109.042319,38.102719],[-109.059962,38.499987],[-109.365616,38.305454]]]},"properties":{"id":"84530","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.605994,39.592624],[-111.628477,39.656241],[-111.710503,39.644571],[-111.711319,39.596642],[-111.605994,39.592624]]]},"properties":{"id":"84632","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.545932,39.310308],[-111.643412,39.365034],[-111.70271,39.235074],[-111.643873,39.245774],[-111.545932,39.310308]]]},"properties":{"id":"84642","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.974785,38.875974],[-111.954644,38.872619],[-111.888839,38.938158],[-111.922068,38.961217],[-111.974785,38.875974]]]},"properties":{"id":"84620","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.07169,38.715878],[-112.011347,38.623811],[-111.986761,38.624073],[-111.942076,38.682198],[-111.976463,38.733094],[-112.04701,38.70996],[-112.07169,38.715878]]]},"properties":{"id":"84711","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.965519,38.769091],[-112.04701,38.70996],[-111.976463,38.733094],[-111.965519,38.769091]]]},"properties":{"id":"84730","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.51093,40.809735],[-111.641022,40.799084],[-111.623353,40.753511],[-111.596807,40.659998],[-111.564771,40.622155],[-111.436752,40.680564],[-111.431968,40.684513],[-111.429413,40.702867],[-111.404989,40.738076],[-111.51093,40.809735]]]},"properties":{"id":"84098","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.897896,40.713028],[-111.317958,40.700972],[-111.429413,40.702867],[-111.431968,40.684513],[-111.191889,40.455974],[-110.901931,40.67817],[-110.897896,40.713028]]]},"properties":{"id":"84036","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.367225,40.566842],[-112.400374,40.492497],[-112.209485,40.433068],[-112.173208,40.466923],[-112.176555,40.597461],[-112.188292,40.614271],[-112.364919,40.649776],[-112.367225,40.566842]]]},"properties":{"id":"84074","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.407837,40.012364],[-112.42138,40.118279],[-112.459396,40.012181],[-112.407837,40.012364]]]},"properties":{"id":"84080","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.850781,40.541369],[-109.897302,40.424998],[-109.794767,40.471538],[-109.850781,40.541369]]]},"properties":{"id":"84076","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.775568,37.212123],[-113.636045,37.17545],[-113.629962,37.309384],[-113.677917,37.315635],[-113.775568,37.212123]]]},"properties":{"id":"84783","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.263577,37.273582],[-113.287413,37.202795],[-113.233222,37.199649],[-113.263577,37.273582]]]},"properties":{"id":"84745","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.636045,37.17545],[-113.775568,37.212123],[-113.777307,37.196267],[-113.71544,37.128085],[-113.643161,37.157786],[-113.636045,37.17545]]]},"properties":{"id":"84738","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.712598,37.604686],[-113.628003,37.477746],[-113.622416,37.487852],[-113.601631,37.604793],[-113.712598,37.604686]]]},"properties":{"id":"84725","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.064561,38.152281],[-110.180347,38.255657],[-110.649548,38.499981],[-110.916219,38.475606],[-111.064561,38.152281]]]},"properties":{"id":"84734","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.943068,41.285934],[-111.962576,41.36494],[-112.028246,41.336582],[-111.943068,41.285934]]]},"properties":{"id":"84414","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.228312,41.608399],[-112.102793,41.535409],[-112.117218,41.586103],[-112.162455,41.608835],[-112.228312,41.608399]]],[[[-112.402771,41.639086],[-112.588918,41.718659],[-112.709121,41.632832],[-112.496246,41.216636],[-112.37579,41.271701],[-112.402771,41.639086]]]]},"properties":{"id":"84307","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.509251,42.000838],[-112.591212,41.835195],[-112.54439,41.89659],[-112.509251,42.000838]]]},"properties":{"id":"84336","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.078276,41.669592],[-112.085573,41.742434],[-112.122506,41.755499],[-112.131076,41.728727],[-112.097475,41.683268],[-112.078276,41.669592]]]},"properties":{"id":"84309","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.80845,41.64185],[-111.893215,41.652188],[-111.871935,41.62651],[-111.716248,41.583216],[-111.634469,41.663306],[-111.803671,41.666032],[-111.80845,41.64185]]]},"properties":{"id":"84319","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.977155,41.917492],[-111.943663,41.940797],[-111.933595,41.998634],[-112.02684,41.998219],[-111.998589,41.917376],[-111.977155,41.917492]]]},"properties":{"id":"84308","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.758738,41.936316],[-111.896508,41.913948],[-111.908744,41.896871],[-111.757759,41.868863],[-111.758738,41.936316]]]},"properties":{"id":"84333","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.836628,39.679141],[-111.131196,39.606409],[-111.123043,39.581619],[-110.917725,39.620383],[-110.812788,39.648058],[-110.817466,39.673759],[-110.832283,39.670392],[-110.836628,39.679141]]]},"properties":{"id":"84526","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.000708,40.920315],[-109.784273,40.842226],[-109.604015,40.998367],[-110.000708,40.997352],[-110.000708,40.920315]]]},"properties":{"id":"84046","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.89358,41.107846],[-111.991356,41.148681],[-111.956074,41.116583],[-111.941433,41.054528],[-111.901589,41.059885],[-111.89358,41.107846]]]},"properties":{"id":"84040","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.377205,40.337874],[-110.464036,40.349677],[-110.487397,40.329984],[-110.377205,40.337874]]]},"properties":{"id":"84073","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.815329,37.791768],[-112.878591,37.694942],[-112.758015,37.672261],[-112.815329,37.791768]]]},"properties":{"id":"84719","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.900279,37.267737],[-112.82429,37.34683],[-112.90107,37.316627],[-112.900279,37.267737]]],[[[-112.683991,37.216062],[-112.900108,37.223818],[-112.899922,37.18235],[-112.683991,37.216062]]]]},"properties":{"id":"84755","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.216264,38.149902],[-112.207143,38.20841],[-112.280042,38.225546],[-112.293227,38.149861],[-112.216264,38.149902]]]},"properties":{"id":"84723","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.986761,38.624073],[-111.916217,38.462638],[-111.868236,38.491311],[-111.832848,38.502074],[-111.844818,38.602883],[-111.942076,38.682198],[-111.986761,38.624073]]]},"properties":{"id":"84744","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.449012,41.86712],[-111.253001,41.843234],[-111.308422,42.001228],[-111.449012,41.86712]]]},"properties":{"id":"84038","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.007252,40.63132],[-111.938749,40.635244],[-111.93884,40.667653],[-111.977011,40.667549],[-112.026462,40.669628],[-112.091877,40.677036],[-112.06229,40.641467],[-112.007252,40.63132]]]},"properties":{"id":"84118","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.80595,40.660118],[-111.865916,40.649573],[-111.8659,40.630055],[-111.84796,40.615156],[-111.792154,40.586009],[-111.59393,40.577071],[-111.553105,40.609362],[-111.564771,40.622155],[-111.596807,40.659998],[-111.799655,40.649338],[-111.80595,40.660118]]]},"properties":{"id":"84121","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.992816,40.506148],[-111.921376,40.44138],[-111.913317,40.452563],[-111.917536,40.537041],[-111.984073,40.536989],[-111.992816,40.506148]]]},"properties":{"id":"84065","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.902535,40.771545],[-111.902533,40.769342],[-111.899674,40.769346],[-111.899675,40.771545],[-111.902535,40.771545]]]},"properties":{"id":"84180","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.407217,37.255559],[-109.191694,37.430741],[-109.256231,37.600597],[-109.696928,37.835217],[-109.488585,37.282802],[-109.407217,37.255559]]]},"properties":{"id":"84511","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.68508,39.516354],[-111.61971,39.475415],[-111.604127,39.497597],[-111.68508,39.516354]]]},"properties":{"id":"84667","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.502964,38.593418],[-112.424484,38.681493],[-112.466527,38.673818],[-112.502964,38.593418]]],[[[-112.18071,38.657432],[-112.249362,38.60627],[-112.220303,38.582908],[-112.167399,38.608009],[-112.181458,38.654239],[-112.18071,38.657432]]]]},"properties":{"id":"84739","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.276029,40.808508],[-111.404989,40.738076],[-111.429413,40.702867],[-111.317958,40.700972],[-111.276029,40.808508]]]},"properties":{"id":"84061","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.505078,40.447813],[-112.601844,40.426687],[-112.573199,40.336782],[-112.400718,40.385411],[-112.484863,40.469818],[-112.505078,40.447813]]]},"properties":{"id":"84069","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.711963,40.54266],[-109.892619,40.622608],[-109.850781,40.541369],[-109.794767,40.471538],[-109.897302,40.424998],[-109.938074,40.452735],[-109.916371,40.403942],[-109.855373,40.328184],[-109.805981,40.343033],[-109.711963,40.54266]]]},"properties":{"id":"84039","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.610743,40.121534],[-111.543527,40.148737],[-111.536234,40.080675],[-111.576802,40.071349],[-111.407071,40.131333],[-111.542404,40.25772],[-111.616974,40.243339],[-111.647853,40.193407],[-111.646734,40.184057],[-111.610743,40.121534]]]},"properties":{"id":"84663","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.756925,39.888811],[-111.805521,40.003172],[-111.881284,40.028823],[-111.831581,39.938207],[-111.756925,39.888811]]]},"properties":{"id":"84655","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.954716,40.347309],[-111.898139,40.355582],[-111.925198,40.420358],[-111.951857,40.384375],[-111.954716,40.347309]]]},"properties":{"id":"84045","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.706925,40.065104],[-111.616315,39.977497],[-111.600493,39.994155],[-111.706925,40.065104]]]},"properties":{"id":"84653","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.367305,37.258764],[-113.322158,37.234186],[-113.287413,37.202795],[-113.263577,37.273582],[-113.189131,37.367262],[-113.254453,37.371754],[-113.367305,37.258764]]]},"properties":{"id":"84774","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.01608,37.000224],[-112.928196,37.000313],[-113.070716,37.101002],[-113.01608,37.000224]]]},"properties":{"id":"84784","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.542177,38.488656],[-111.606555,38.360227],[-111.560691,38.356558],[-111.46246,38.398549],[-111.542177,38.488656]]]},"properties":{"id":"84749","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.083145,41.152343],[-112.023275,41.152444],[-112.021651,41.152508],[-112.022859,41.197127],[-112.083611,41.176159],[-112.083145,41.152343]]]},"properties":{"id":"84067","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.160788,41.78478],[-112.226936,41.740156],[-112.131076,41.728727],[-112.122506,41.755499],[-112.10244,41.808159],[-112.14608,41.839113],[-112.160788,41.78478]],[[-112.160788,41.78478],[-112.160397,41.792089],[-112.145831,41.792285],[-112.137213,41.785265],[-112.140507,41.772011],[-112.148899,41.770394],[-112.149377,41.784955],[-112.160788,41.78478]]]},"properties":{"id":"84312","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.889733,41.469706],[-113.82559,41.676306],[-114.039648,41.884815],[-114.040562,41.368212],[-113.889733,41.469706]]]},"properties":{"id":"84313","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.040562,41.368212],[-113.960093,40.741519],[-113.401169,41.030195],[-113.889733,41.469706],[-114.040562,41.368212]]]},"properties":{"id":"84083","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.968745,41.544195],[-111.969381,41.488399],[-111.962156,41.427608],[-111.953734,41.429213],[-111.953719,41.550407],[-111.968745,41.544195]]]},"properties":{"id":"84324","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.803582,41.677714],[-111.794532,41.715083],[-111.830011,41.717347],[-111.823319,41.693641],[-111.803582,41.677714]]]},"properties":{"id":"84332","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.892619,40.622608],[-109.711963,40.54266],[-109.483808,40.315819],[-109.048778,40.580559],[-109.1612,40.714693],[-109.258231,40.858107],[-109.913855,40.782465],[-109.892619,40.622608]]]},"properties":{"id":"84078","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.24975,40.289887],[-110.142971,40.21865],[-110.143042,40.243996],[-110.18508,40.348793],[-110.209078,40.436714],[-110.247427,40.403103],[-110.24975,40.289887]]]},"properties":{"id":"84007","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.029432,39.237241],[-111.053074,39.212823],[-110.996623,39.224283],[-110.994689,39.315609],[-111.029432,39.237241]]]},"properties":{"id":"84513","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.117883,39.154826],[-111.06569,39.088579],[-111.037941,39.16776],[-111.117883,39.154826]]]},"properties":{"id":"84516","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.17612,37.960176],[-110.901198,37.175115],[-110.697112,37.148196],[-110.216116,37.307709],[-110.214782,37.310638],[-109.972421,37.708357],[-110.17612,37.960176]]]},"properties":{"id":"84533","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.214261,37.505252],[-113.216913,37.572683],[-113.304942,37.529053],[-113.214261,37.505252]]]},"properties":{"id":"84742","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.458582,37.794318],[-113.601631,37.604793],[-113.622416,37.487852],[-113.606025,37.411804],[-113.561991,37.412826],[-113.454797,37.529267],[-113.488504,37.639237],[-113.458582,37.794318]]]},"properties":{"id":"84756","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.116145,39.253983],[-112.07727,39.156076],[-112.078361,39.033837],[-112.041053,39.038662],[-112.04036,39.10365],[-112.116145,39.253983]]]},"properties":{"id":"84656","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.708518,39.330893],[-112.410029,39.18359],[-112.40992,39.202442],[-112.488326,39.409889],[-112.42685,39.553857],[-112.724411,39.476492],[-112.689769,39.374836],[-112.708518,39.330893]]]},"properties":{"id":"84624","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.358504,39.456783],[-112.488326,39.409889],[-112.40992,39.202442],[-112.316117,39.202884],[-112.192274,39.443043],[-112.358504,39.456783]]]},"properties":{"id":"84649","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.856305,41.139118],[-111.738575,40.861055],[-111.641022,40.799084],[-111.51093,40.809735],[-111.526707,40.928987],[-111.529791,41.052875],[-111.528956,41.229836],[-111.845044,41.198723],[-111.856305,41.139118]]]},"properties":{"id":"84050","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.916217,38.462638],[-111.940077,38.437212],[-111.898078,38.418301],[-111.868236,38.491311],[-111.916217,38.462638]]]},"properties":{"id":"84732","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.507264,41.999518],[-111.449012,41.86712],[-111.308422,42.001228],[-111.507264,41.999518]]]},"properties":{"id":"84028","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.007252,40.63132],[-112.06229,40.641467],[-112.06661,40.62892],[-112.063482,40.565982],[-112.004817,40.581095],[-112.005358,40.609451],[-112.007252,40.63132]]]},"properties":{"id":"84081","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.962695,40.849189],[-112.056706,40.770866],[-111.908252,40.769343],[-111.905395,40.771545],[-111.808026,40.822036],[-111.815768,40.839145],[-111.838568,40.831245],[-111.89097,40.822045],[-111.954409,40.848988],[-111.962695,40.849189]]]},"properties":{"id":"84116","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.891074,40.741612],[-111.908458,40.741601],[-111.908519,40.728543],[-111.908655,40.687007],[-111.902693,40.6867],[-111.876987,40.687062],[-111.876796,40.725434],[-111.87677,40.741624],[-111.891074,40.741612]]]},"properties":{"id":"84115","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.891097,40.769346],[-111.893957,40.769346],[-111.891094,40.767174],[-111.891097,40.769346]]]},"properties":{"id":"84144","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.063279,40.740241],[-112.196263,40.629308],[-112.06661,40.62892],[-112.06229,40.641467],[-112.091877,40.677036],[-112.063056,40.740241],[-112.063279,40.740241]]]},"properties":{"id":"84044","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.776987,39.10841],[-111.660601,39.161293],[-111.699013,39.149292],[-111.783401,39.129506],[-111.776987,39.10841]]]},"properties":{"id":"84643","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.680832,38.754814],[-111.815805,39.033067],[-111.828957,39.033106],[-111.903469,39.007],[-111.922068,38.961217],[-111.888839,38.938158],[-111.698066,38.718057],[-111.680832,38.754814]]]},"properties":{"id":"84654","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.903469,39.007],[-111.828957,39.033106],[-111.850139,39.045466],[-111.903469,39.007]]]},"properties":{"id":"84652","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.460895,41.030236],[-111.438937,40.968894],[-111.431606,40.974942],[-111.460895,41.030236]]]},"properties":{"id":"84024","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.483808,40.315819],[-109.163073,40.264457],[-109.048778,40.580559],[-109.483808,40.315819]]]},"properties":{"id":"84035","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.892619,40.622608],[-109.913855,40.782465],[-109.97762,40.491554],[-109.938074,40.452735],[-109.897302,40.424998],[-109.850781,40.541369],[-109.892619,40.622608]]]},"properties":{"id":"84085","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.802963,40.099585],[-111.706925,40.065104],[-111.600493,39.994155],[-111.494147,39.993933],[-111.407071,40.131333],[-111.576802,40.071349],[-111.610743,40.121534],[-111.646734,40.184057],[-111.661012,40.185058],[-111.802963,40.099585]]]},"properties":{"id":"84660","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.678528,40.35197],[-111.764249,40.354038],[-111.772832,40.334308],[-111.764466,40.326488],[-111.737603,40.32627],[-111.686684,40.333811],[-111.66306,40.33381],[-111.678528,40.35197]]]},"properties":{"id":"84042","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.681298,40.260619],[-111.686552,40.296114],[-111.737603,40.32627],[-111.764466,40.326488],[-111.736919,40.260392],[-111.700682,40.2562],[-111.681298,40.260619]]]},"properties":{"id":"84058","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.804519,40.46546],[-111.681314,40.532986],[-111.822322,40.485053],[-111.804519,40.46546]]]},"properties":{"id":"84004","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.605586,37.066033],[-113.513776,37.036096],[-113.530613,37.125855],[-113.605586,37.066033]]]},"properties":{"id":"84790","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.367305,37.258764],[-113.39496,37.206466],[-113.322158,37.234186],[-113.367305,37.258764]]]},"properties":{"id":"84746","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.125614,37.143088],[-113.070716,37.101002],[-113.091668,37.171655],[-113.125614,37.143088]]]},"properties":{"id":"84763","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.695036,38.250635],[-112.616136,38.26632],[-112.565605,38.337646],[-112.652199,38.412853],[-112.652021,38.301191],[-112.695063,38.27397],[-112.695036,38.250635]]]},"properties":{"id":"84713","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.695063,38.27397],[-112.794866,38.29978],[-112.962552,38.279478],[-112.788912,38.240903],[-112.695036,38.250635],[-112.695063,38.27397]]]},"properties":{"id":"84731","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.122506,41.755499],[-112.085573,41.742434],[-112.027851,41.771429],[-112.010467,41.788259],[-112.043488,41.837501],[-112.046905,41.846095],[-112.081512,41.807844],[-112.10244,41.808159],[-112.122506,41.755499]]]},"properties":{"id":"84306","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.3586,41.815214],[-112.509251,42.000838],[-112.54439,41.89659],[-112.3586,41.815214]]],[[[-112.3586,41.815214],[-112.353985,41.661064],[-112.228312,41.608399],[-112.162455,41.608835],[-112.128396,41.635641],[-112.097475,41.683268],[-112.131076,41.728727],[-112.226936,41.740156],[-112.3586,41.815214]]]]},"properties":{"id":"84337","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.716248,41.583216],[-111.871935,41.62651],[-111.953719,41.550407],[-111.716248,41.583216]]]},"properties":{"id":"84328","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.893215,41.652188],[-111.80845,41.64185],[-111.823319,41.693641],[-111.830011,41.717347],[-111.794532,41.715083],[-111.803582,41.677714],[-111.803671,41.666032],[-111.634469,41.663306],[-111.775477,41.768003],[-111.848481,41.777932],[-111.952537,41.746422],[-111.942889,41.711077],[-111.893215,41.652188]]]},"properties":{"id":"84321","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.817466,39.673759],[-110.836628,39.679141],[-110.832283,39.670392],[-110.817466,39.673759]]]},"properties":{"id":"84529","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.487397,40.329984],[-110.464036,40.349677],[-110.377205,40.337874],[-110.32861,40.334154],[-110.313421,40.357655],[-110.365775,40.435204],[-110.510158,40.463666],[-110.537223,40.449928],[-110.505612,40.325348],[-110.487397,40.329984]]]},"properties":{"id":"84051","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.117883,39.154826],[-111.136771,39.022796],[-111.06569,39.088579],[-111.117883,39.154826]]]},"properties":{"id":"84523","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.268982,37.717488],[-112.126177,38.14957],[-112.216264,38.149902],[-112.293227,38.149861],[-112.679954,37.805726],[-112.688965,37.728358],[-112.685832,37.648864],[-112.629197,37.626282],[-112.557615,37.610463],[-112.268982,37.717488]]]},"properties":{"id":"84759","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.349892,37.757296],[-111.306088,37.983255],[-111.583079,37.967493],[-111.349892,37.757296]]]},"properties":{"id":"84716","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.019277,38.145786],[-111.968968,37.992898],[-112.020633,37.805552],[-111.89508,37.75196],[-111.865166,37.890778],[-111.897373,38.044071],[-112.026668,38.166118],[-112.019277,38.145786]]]},"properties":{"id":"84712","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.051293,39.194258],[-109.759818,39.152418],[-109.865019,38.993666],[-109.199866,39.101802],[-109.051293,39.194258]]]},"properties":{"id":"84540","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.199866,39.101802],[-109.390066,38.847821],[-109.058815,38.749411],[-109.051293,39.194258],[-109.199866,39.101802]]]},"properties":{"id":"84515","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.970181,39.591281],[-111.953709,39.376261],[-111.713224,39.460107],[-111.715473,39.602118],[-111.970181,39.591281]]]},"properties":{"id":"84639","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.724411,39.476492],[-112.708518,39.330893],[-112.689769,39.374836],[-112.724411,39.476492]]]},"properties":{"id":"84635","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.31228,39.553988],[-112.358504,39.456783],[-112.192274,39.443043],[-112.31228,39.553988]]]},"properties":{"id":"84638","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.876784,40.769352],[-111.847263,40.768052],[-111.827584,40.766712],[-111.808026,40.822036],[-111.905395,40.771545],[-111.902535,40.771545],[-111.899675,40.771545],[-111.893957,40.769346],[-111.891097,40.769346],[-111.876784,40.769352]]]},"properties":{"id":"84103","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.908458,40.741601],[-111.908252,40.769343],[-112.056706,40.770866],[-112.063279,40.740241],[-112.063056,40.740241],[-112.024501,40.724736],[-111.96807,40.728156],[-111.908519,40.728543],[-111.908458,40.741601]]]},"properties":{"id":"84104","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.407217,37.255559],[-109.442967,37.054952],[-109.045412,37.132088],[-109.043787,37.470104],[-109.191694,37.430741],[-109.407217,37.255559]]]},"properties":{"id":"84534","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.68508,39.516354],[-111.604127,39.497597],[-111.525851,39.49009],[-111.542011,39.521012],[-111.545624,39.54978],[-111.605994,39.592624],[-111.711319,39.596642],[-111.68508,39.516354]]]},"properties":{"id":"84646","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.850139,39.045466],[-111.828957,39.033106],[-111.815805,39.033067],[-111.792291,39.0551],[-111.840813,39.074906],[-111.850139,39.045466]]]},"properties":{"id":"84621","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.958567,38.801763],[-111.954644,38.872619],[-111.974785,38.875974],[-112.054306,38.813002],[-111.958567,38.801763]]]},"properties":{"id":"84657","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.054306,38.813002],[-112.127857,38.716598],[-112.07169,38.715878],[-112.04701,38.70996],[-111.965519,38.769091],[-111.958567,38.801763],[-112.054306,38.813002]]],[[[-111.698066,38.718057],[-111.844818,38.602883],[-111.832848,38.502074],[-111.680832,38.754814],[-111.698066,38.718057]]]]},"properties":{"id":"84701","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.484863,40.469818],[-112.400718,40.385411],[-112.209485,40.433068],[-112.400374,40.492497],[-112.484863,40.469818]]]},"properties":{"id":"84071","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.570961,40.5327],[-111.764249,40.354038],[-111.678528,40.35197],[-111.587958,40.473679],[-111.570961,40.5327]]]},"properties":{"id":"84062","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.043741,40.261528],[-111.954716,40.347309],[-111.951857,40.384375],[-112.043838,40.298324],[-112.043741,40.261528]]]},"properties":{"id":"84005","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.658627,40.24434],[-111.700682,40.2562],[-111.736919,40.260392],[-111.661012,40.185058],[-111.646734,40.184057],[-111.647853,40.193407],[-111.658627,40.24434]]]},"properties":{"id":"84601","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.091668,37.171655],[-113.070716,37.101002],[-112.928196,37.000313],[-112.899366,37.000384],[-112.899922,37.18235],[-112.900108,37.223818],[-112.900279,37.267737],[-112.90107,37.316627],[-112.901067,37.475974],[-113.091668,37.171655]]]},"properties":{"id":"84767","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.724814,37.338215],[-113.677917,37.315635],[-113.629962,37.309384],[-113.612626,37.307773],[-113.610828,37.393281],[-113.647188,37.391042],[-113.724814,37.338215]]]},"properties":{"id":"84782","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.924723,41.236868],[-112.121349,41.215279],[-112.122831,41.202083],[-112.083611,41.176159],[-112.022859,41.197127],[-111.972438,41.199035],[-111.84032,41.250762],[-111.842769,41.255011],[-111.924723,41.236868]]]},"properties":{"id":"84401","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.121864,41.633546],[-112.070862,41.640573],[-112.078276,41.669592],[-112.097475,41.683268],[-112.128396,41.635641],[-112.121864,41.633546]]]},"properties":{"id":"84314","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.998589,41.917376],[-112.02684,41.998219],[-112.163956,41.996708],[-112.084593,41.902094],[-112.046991,41.85077],[-111.998589,41.917376]]]},"properties":{"id":"84305","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.991356,41.148681],[-111.89358,41.107846],[-111.894663,41.136009],[-111.972438,41.199035],[-112.022859,41.197127],[-112.021651,41.152508],[-111.991356,41.148681]]]},"properties":{"id":"84405","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.583079,37.967493],[-111.813139,37.682848],[-111.638809,37.539577],[-111.349892,37.757296],[-111.583079,37.967493]]]},"properties":{"id":"84726","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.957732,37.535957],[-112.014639,37.569726],[-112.040425,37.546846],[-111.957732,37.535957]]]},"properties":{"id":"84736","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.304942,37.529053],[-113.216913,37.572683],[-113.214261,37.505252],[-113.180847,37.48103],[-112.900994,37.499738],[-112.901462,37.519654],[-112.973668,37.649522],[-113.198807,37.715845],[-113.488504,37.639237],[-113.454797,37.529267],[-113.436139,37.529297],[-113.328455,37.529123],[-113.304942,37.529053]]]},"properties":{"id":"84720","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.736766,38.026316],[-112.963035,37.792614],[-112.911771,37.779862],[-112.878591,37.694942],[-112.815329,37.791768],[-112.753819,37.783402],[-112.736766,38.026316]]]},"properties":{"id":"84761","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.83735,39.784814],[-111.766357,39.810564],[-111.756925,39.888811],[-111.831581,39.938207],[-111.916746,39.883021],[-111.964061,39.82741],[-111.83735,39.784814]]]},"properties":{"id":"84645","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.615886,37.292668],[-112.53826,37.426922],[-112.563126,37.49175],[-112.713683,37.365927],[-112.615886,37.292668]]]},"properties":{"id":"84729","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.316117,39.202884],[-112.40992,39.202442],[-112.410029,39.18359],[-112.362375,39.081374],[-112.286107,39.063119],[-112.316117,39.202884]]]},"properties":{"id":"84636","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.216264,38.149902],[-112.126177,38.14957],[-112.019277,38.145786],[-112.026668,38.166118],[-112.192331,38.230509],[-112.207143,38.20841],[-112.216264,38.149902]]]},"properties":{"id":"84743","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.876796,40.725434],[-111.84227,40.72565],[-111.85247,40.751699],[-111.876775,40.749786],[-111.87677,40.741624],[-111.876796,40.725434]]]},"properties":{"id":"84105","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.853397,40.610549],[-111.870862,40.608718],[-111.872005,40.54431],[-111.852762,40.529667],[-111.853228,40.580441],[-111.853397,40.610549]]]},"properties":{"id":"84094","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.876796,40.725434],[-111.876987,40.687062],[-111.865518,40.687057],[-111.834032,40.687088],[-111.833657,40.725497],[-111.84227,40.72565],[-111.876796,40.725434]]]},"properties":{"id":"84106","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.85247,40.751699],[-111.84227,40.72565],[-111.833657,40.725497],[-111.623353,40.753511],[-111.641022,40.799084],[-111.738575,40.861055],[-111.815768,40.839145],[-111.808026,40.822036],[-111.827584,40.766712],[-111.830387,40.761921],[-111.834345,40.754294],[-111.843172,40.75851],[-111.852353,40.75849],[-111.85247,40.751699]]]},"properties":{"id":"84108","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.834032,40.687088],[-111.799655,40.649338],[-111.596807,40.659998],[-111.623353,40.753511],[-111.833657,40.725497],[-111.834032,40.687088]]]},"properties":{"id":"84109","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.697112,37.148196],[-110.622632,37.003431],[-110.151096,36.998431],[-110.216116,37.307709],[-110.697112,37.148196]]]},"properties":{"id":"84536","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.495338,36.999105],[-109.045412,37.132088],[-109.442967,37.054952],[-109.495338,36.999105]]]},"properties":{"id":"86514","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.496098,39.368592],[-111.5737,39.399464],[-111.582341,39.406172],[-111.643412,39.365034],[-111.545932,39.310308],[-111.496098,39.368592]]]},"properties":{"id":"84627","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.799988,39.18857],[-111.70531,39.38017],[-111.877568,39.381264],[-111.916243,39.180582],[-111.799988,39.18857]]]},"properties":{"id":"84630","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.676293,39.812367],[-111.479263,39.637466],[-111.312874,39.606349],[-111.494147,39.993933],[-111.600493,39.994155],[-111.616315,39.977497],[-111.676293,39.812367]]]},"properties":{"id":"84629","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.147118,38.694734],[-112.181458,38.654239],[-112.167399,38.608009],[-112.011347,38.623811],[-112.07169,38.715878],[-112.127857,38.716598],[-112.147118,38.694734]]]},"properties":{"id":"84754","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.436752,40.680564],[-111.564771,40.622155],[-111.553105,40.609362],[-111.450672,40.636729],[-111.436752,40.680564]]]},"properties":{"id":"84060","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.276029,40.808508],[-111.317958,40.700972],[-110.897896,40.713028],[-110.865206,40.723152],[-110.875593,40.915674],[-111.276029,40.808508]]]},"properties":{"id":"84055","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.855373,40.328184],[-109.916035,40.258663],[-109.859392,40.215279],[-109.764804,40.269263],[-109.805981,40.343033],[-109.855373,40.328184]]]},"properties":{"id":"84026","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.530613,37.125855],[-113.513776,37.036096],[-113.43535,37.112996],[-113.441502,37.171324],[-113.537926,37.159444],[-113.530613,37.125855]]]},"properties":{"id":"84780","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.84032,41.250762],[-111.972438,41.199035],[-111.894663,41.136009],[-111.856305,41.139118],[-111.845044,41.198723],[-111.84032,41.250762]]]},"properties":{"id":"84403","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.943068,41.285934],[-111.924723,41.236868],[-111.842769,41.255011],[-111.725131,41.386556],[-111.953734,41.429213],[-111.962156,41.427608],[-111.962576,41.36494],[-111.943068,41.285934]]]},"properties":{"id":"84310","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.794866,38.29978],[-112.99151,38.280251],[-112.962552,38.279478],[-112.794866,38.29978]]]},"properties":{"id":"84751","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.99151,38.280251],[-112.788912,38.240903],[-112.962552,38.279478],[-112.99151,38.280251]]]},"properties":{"id":"84752","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.081512,41.807844],[-112.14608,41.839113],[-112.10244,41.808159],[-112.081512,41.807844]]]},"properties":{"id":"84311","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.620586,41.864228],[-113.656676,41.967115],[-113.737313,41.940961],[-113.726059,41.839446],[-113.6208,41.821929],[-113.620586,41.864228]]]},"properties":{"id":"83312","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.220397,41.900125],[-112.084593,41.902094],[-112.163956,41.996708],[-112.198496,42.001164],[-112.220397,41.900125]]]},"properties":{"id":"84330","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.896508,41.913948],[-111.758738,41.936316],[-111.933595,41.998634],[-111.943663,41.940797],[-111.896508,41.913948]]]},"properties":{"id":"84320","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.035681,41.076823],[-112.117135,41.09644],[-112.035784,41.059396],[-112.035681,41.076823]]]},"properties":{"id":"84075","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.899366,40.861342],[-111.89097,40.822045],[-111.838568,40.831245],[-111.862891,40.906665],[-111.891162,40.906611],[-111.899366,40.861342]]]},"properties":{"id":"84010","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.89097,40.822045],[-111.899366,40.861342],[-111.954409,40.848988],[-111.89097,40.822045]]]},"properties":{"id":"84054","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.122831,41.202083],[-112.130033,41.136763],[-112.083145,41.152343],[-112.083611,41.176159],[-112.122831,41.202083]]]},"properties":{"id":"84315","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.510158,40.463666],[-110.734577,40.74698],[-110.865206,40.723152],[-110.897896,40.713028],[-110.901931,40.67817],[-110.896623,40.384899],[-110.827603,40.363667],[-110.537223,40.449928],[-110.510158,40.463666]]]},"properties":{"id":"84031","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.709556,40.258757],[-110.891048,39.899142],[-110.190125,40.149318],[-110.142971,40.21865],[-110.24975,40.289887],[-110.32861,40.334154],[-110.377205,40.337874],[-110.487397,40.329984],[-110.505612,40.325348],[-110.709556,40.258757]]]},"properties":{"id":"84021","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.133239,37.613756],[-112.063895,37.594928],[-112.062215,37.593722],[-112.096963,37.659075],[-112.133239,37.613756]]]},"properties":{"id":"84776","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.560565,37.542925],[-112.629197,37.626282],[-112.685832,37.648864],[-112.901462,37.519654],[-112.900994,37.499738],[-112.901067,37.475974],[-112.90107,37.316627],[-112.82429,37.34683],[-112.713683,37.365927],[-112.563126,37.49175],[-112.487418,37.542688],[-112.560565,37.542925]]]},"properties":{"id":"84762","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.975544,39.807024],[-111.970181,39.591281],[-111.715473,39.602118],[-111.83735,39.784814],[-111.964061,39.82741],[-111.975544,39.807024]]]},"properties":{"id":"84648","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.748799,40.419233],[-112.758633,40.419282],[-112.747436,40.39123],[-112.748799,40.419233]]],[[[-112.748799,40.419233],[-112.725669,40.346641],[-112.573199,40.336782],[-112.601844,40.426687],[-112.628236,40.468741],[-112.653442,40.743985],[-112.748799,40.419233]]]]},"properties":{"id":"84022","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.42056,41.36137],[-111.528956,41.229836],[-111.529791,41.052875],[-111.487569,41.061451],[-111.42056,41.36137]]]},"properties":{"id":"84018","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.830387,40.761921],[-111.827584,40.766712],[-111.847263,40.768052],[-111.852353,40.75849],[-111.843172,40.75851],[-111.830387,40.761921]]]},"properties":{"id":"84112","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.853228,40.580441],[-111.852762,40.529667],[-111.822322,40.485053],[-111.681314,40.532986],[-111.59393,40.577071],[-111.792154,40.586009],[-111.853228,40.580441]]]},"properties":{"id":"84092","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.920116,40.579753],[-112.004817,40.581095],[-112.063482,40.565982],[-112.062875,40.561858],[-111.984073,40.536989],[-111.917536,40.537041],[-111.894573,40.544312],[-111.920116,40.579753]]]},"properties":{"id":"84095","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.96807,40.728156],[-111.977011,40.667549],[-111.93884,40.667653],[-111.908655,40.687007],[-111.908519,40.728543],[-111.96807,40.728156]]]},"properties":{"id":"84119","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.005358,40.609451],[-111.921291,40.609459],[-111.921408,40.627719],[-111.938749,40.635244],[-112.007252,40.63132],[-112.005358,40.609451]]]},"properties":{"id":"84084","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.984073,40.536989],[-112.062875,40.561858],[-112.176555,40.597461],[-112.173208,40.466923],[-111.992816,40.506148],[-111.984073,40.536989]]]},"properties":{"id":"84096","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.876775,40.749786],[-111.876784,40.769352],[-111.891097,40.769346],[-111.891094,40.767174],[-111.891074,40.741612],[-111.87677,40.741624],[-111.876775,40.749786]]]},"properties":{"id":"84111","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.176555,40.597461],[-112.062875,40.561858],[-112.063482,40.565982],[-112.06661,40.62892],[-112.196263,40.629308],[-112.188292,40.614271],[-112.176555,40.597461]]]},"properties":{"id":"84006","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.151096,36.998431],[-109.709741,36.998427],[-109.645311,37.268223],[-110.214782,37.310638],[-110.216116,37.307709],[-110.151096,36.998431]]]},"properties":{"id":"84531","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.901198,37.175115],[-110.622632,37.003431],[-110.697112,37.148196],[-110.901198,37.175115]]]},"properties":{"id":"86044","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.70271,39.235074],[-111.711474,39.206012],[-111.699013,39.149292],[-111.660601,39.161293],[-111.643873,39.245774],[-111.70271,39.235074]]]},"properties":{"id":"84665","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.542011,39.521012],[-111.357118,39.430309],[-111.312874,39.606349],[-111.479263,39.637466],[-111.545624,39.54978],[-111.542011,39.521012]]]},"properties":{"id":"84647","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.872181,40.997284],[-111.431606,40.974942],[-111.438937,40.968894],[-111.526707,40.928987],[-111.51093,40.809735],[-111.404989,40.738076],[-111.276029,40.808508],[-110.875593,40.915674],[-110.872181,40.997284]]]},"properties":{"id":"84017","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.529791,41.052875],[-111.526707,40.928987],[-111.438937,40.968894],[-111.460895,41.030236],[-111.487569,41.061451],[-111.529791,41.052875]]]},"properties":{"id":"84033","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.610743,40.121534],[-111.576802,40.071349],[-111.536234,40.080675],[-111.543527,40.148737],[-111.610743,40.121534]]]},"properties":{"id":"84664","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.848904,40.458163],[-111.913317,40.452563],[-111.921376,40.44138],[-111.925198,40.420358],[-111.898139,40.355582],[-111.835246,40.355862],[-111.848904,40.458163]]]},"properties":{"id":"84043","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.681298,40.260619],[-111.66306,40.33381],[-111.686684,40.333811],[-111.686552,40.296114],[-111.681298,40.260619]]]},"properties":{"id":"84097","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.553105,40.609362],[-111.59393,40.577071],[-111.570961,40.5327],[-111.587958,40.473679],[-111.531409,40.405039],[-111.478032,40.443995],[-111.450672,40.636729],[-111.553105,40.609362]]]},"properties":{"id":"84049","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.311834,40.286464],[-111.191889,40.455974],[-111.431968,40.684513],[-111.436752,40.680564],[-111.450672,40.636729],[-111.478032,40.443995],[-111.311834,40.286464]]]},"properties":{"id":"84032","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.39496,37.206466],[-113.441502,37.171324],[-113.43535,37.112996],[-113.01608,37.000224],[-113.070716,37.101002],[-113.125614,37.143088],[-113.233222,37.199649],[-113.287413,37.202795],[-113.322158,37.234186],[-113.39496,37.206466]]]},"properties":{"id":"84737","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.84032,41.250762],[-111.845044,41.198723],[-111.528956,41.229836],[-111.42056,41.36137],[-111.725131,41.386556],[-111.842769,41.255011],[-111.84032,41.250762]]]},"properties":{"id":"84317","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.893215,41.652188],[-111.942889,41.711077],[-112.018053,41.662557],[-112.005863,41.616079],[-111.968745,41.544195],[-111.953719,41.550407],[-111.871935,41.62651],[-111.893215,41.652188]]]},"properties":{"id":"84339","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.787729,41.80723],[-111.818935,41.823956],[-111.836961,41.799328],[-111.787729,41.80723]]]},"properties":{"id":"84318","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.952537,41.746422],[-111.955336,41.786667],[-112.010467,41.788259],[-112.027851,41.771429],[-112.018053,41.662557],[-111.942889,41.711077],[-111.952537,41.746422]]]},"properties":{"id":"84325","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.823319,41.693641],[-111.80845,41.64185],[-111.803671,41.666032],[-111.803582,41.677714],[-111.823319,41.693641]]]},"properties":{"id":"84326","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.857133,39.469451],[-110.673123,39.469367],[-110.812788,39.648058],[-110.917725,39.620383],[-110.857133,39.469451]],[[-110.750755,39.568169],[-110.714165,39.531251],[-110.778071,39.528248],[-110.750755,39.568169]]]},"properties":{"id":"84501","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.415582,39.552785],[-110.400609,39.549015],[-110.294793,39.56287],[-110.30772,39.614638],[-110.354401,39.635218],[-110.373755,39.560612],[-110.415582,39.552785]]]},"properties":{"id":"84539","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.415582,39.552785],[-110.401445,39.509121],[-110.373206,39.520441],[-110.400609,39.549015],[-110.415582,39.552785]]]},"properties":{"id":"84520","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.956074,41.116583],[-111.987759,41.104665],[-112.035681,41.076823],[-112.035784,41.059396],[-112.006778,41.034117],[-111.941433,41.054528],[-111.956074,41.116583]]]},"properties":{"id":"84041","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.896623,40.384899],[-110.891048,39.899142],[-110.709556,40.258757],[-110.827603,40.363667],[-110.896623,40.384899]]]},"properties":{"id":"84027","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.18508,40.348793],[-110.143042,40.243996],[-109.916035,40.258663],[-109.855373,40.328184],[-109.916371,40.403942],[-110.105246,40.433195],[-110.18508,40.348793]]]},"properties":{"id":"84066","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.105246,40.433195],[-109.916371,40.403942],[-109.938074,40.452735],[-109.97762,40.491554],[-110.105246,40.433195]]]},"properties":{"id":"84053","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.917772,39.34491],[-110.77559,39.356389],[-110.857133,39.469451],[-110.998813,39.469814],[-110.917772,39.34491]]]},"properties":{"id":"84518","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.160948,39.365975],[-110.994689,39.315609],[-110.996623,39.224283],[-110.917772,39.34491],[-110.998813,39.469814],[-111.123043,39.581619],[-111.131196,39.606409],[-111.282693,39.46728],[-111.160948,39.365975]]]},"properties":{"id":"84528","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.268982,37.717488],[-112.557615,37.610463],[-112.560565,37.542925],[-112.487418,37.542688],[-112.460291,37.542761],[-112.436174,37.542842],[-112.268982,37.717488]]]},"properties":{"id":"84735","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.025402,38.499981],[-109.88572,38.189202],[-109.365616,38.305454],[-109.059962,38.499987],[-109.058815,38.749411],[-109.390066,38.847821],[-109.878844,38.684416],[-110.025402,38.499981]]]},"properties":{"id":"84532","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.53826,37.426922],[-112.615886,37.292668],[-112.683991,37.216062],[-112.899922,37.18235],[-112.899366,37.000384],[-112.049611,37.530835],[-112.204737,37.536591],[-112.23196,37.536564],[-112.436174,37.542842],[-112.460291,37.542761],[-112.53826,37.426922]]]},"properties":{"id":"84741","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.900108,37.223818],[-112.683991,37.216062],[-112.615886,37.292668],[-112.713683,37.365927],[-112.82429,37.34683],[-112.900279,37.267737],[-112.900108,37.223818]]]},"properties":{"id":"84758","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.211582,38.349823],[-112.258715,38.512035],[-112.289781,38.511196],[-112.211898,38.350378],[-112.211582,38.349823]]]},"properties":{"id":"84750","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.200456,41.589471],[-111.045794,41.552617],[-111.106009,41.787687],[-111.218828,41.734824],[-111.200456,41.589471]]]},"properties":{"id":"84064","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.200456,41.589471],[-111.165115,41.424599],[-111.045888,41.473638],[-111.045794,41.552617],[-111.200456,41.589471]]]},"properties":{"id":"84086","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.865916,40.649573],[-111.80595,40.660118],[-111.865542,40.674305],[-111.865916,40.649573]]]},"properties":{"id":"84117","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.063056,40.740241],[-112.091877,40.677036],[-112.026462,40.669628],[-112.024501,40.724736],[-112.063056,40.740241]]]},"properties":{"id":"84128","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.902693,40.6867],[-111.903962,40.630974],[-111.8659,40.630055],[-111.865916,40.649573],[-111.865542,40.674305],[-111.865518,40.687057],[-111.876987,40.687062],[-111.902693,40.6867]]]},"properties":{"id":"84107","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.843172,40.75851],[-111.834345,40.754294],[-111.830387,40.761921],[-111.843172,40.75851]]]},"properties":{"id":"84113","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.442967,37.054952],[-109.407217,37.255559],[-109.488585,37.282802],[-109.645311,37.268223],[-109.709741,36.998427],[-109.495338,36.999105],[-109.442967,37.054952]]]},"properties":{"id":"84512","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.525851,39.49009],[-111.604127,39.497597],[-111.61971,39.475415],[-111.582341,39.406172],[-111.5737,39.399464],[-111.525851,39.49009]]]},"properties":{"id":"84623","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.249362,38.60627],[-112.289781,38.511196],[-112.258715,38.512035],[-112.220303,38.582908],[-112.249362,38.60627]]]},"properties":{"id":"84766","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.875593,40.915674],[-110.865206,40.723152],[-110.734577,40.74698],[-110.000708,40.920315],[-110.000708,40.997352],[-110.872181,40.997284],[-110.875593,40.915674]]]},"properties":{"id":"82930","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.725669,40.346641],[-112.748799,40.419233],[-112.747436,40.39123],[-112.725669,40.346641]]],[[[-112.364919,40.649776],[-112.628236,40.468741],[-112.601844,40.426687],[-112.505078,40.447813],[-112.367225,40.566842],[-112.364919,40.649776]]],[[[-112.748799,40.419233],[-112.653442,40.743985],[-112.758633,40.419282],[-112.748799,40.419233]]]]},"properties":{"id":"84029","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.942963,40.025026],[-112.092569,39.929262],[-112.070933,39.910529],[-111.975544,39.807024],[-111.964061,39.82741],[-111.916746,39.883021],[-111.942963,40.025026]]]},"properties":{"id":"84626","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.686684,40.333811],[-111.737603,40.32627],[-111.686552,40.296114],[-111.686684,40.333811]]]},"properties":{"id":"84057","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.616315,39.977497],[-111.706925,40.065104],[-111.802963,40.099585],[-111.805521,40.003172],[-111.756925,39.888811],[-111.766357,39.810564],[-111.676293,39.812367],[-111.616315,39.977497]]]},"properties":{"id":"84651","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.043838,40.298324],[-112.078536,40.362645],[-112.156387,40.375652],[-112.114558,40.284544],[-112.043741,40.261528],[-112.043838,40.298324]]]},"properties":{"id":"84013","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.628003,37.477746],[-113.647188,37.391042],[-113.610828,37.393281],[-113.606025,37.411804],[-113.622416,37.487852],[-113.628003,37.477746]]]},"properties":{"id":"84722","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.775568,37.212123],[-113.677917,37.315635],[-113.724814,37.338215],[-113.777307,37.196267],[-113.775568,37.212123]]]},"properties":{"id":"84733","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.083055,41.336942],[-112.121349,41.215279],[-111.924723,41.236868],[-111.943068,41.285934],[-112.028246,41.336582],[-112.083055,41.336942]]]},"properties":{"id":"84404","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.867285,37.891926],[-113.862313,38.085298],[-114.050263,38.001328],[-113.843973,37.776587],[-113.867285,37.891926]]]},"properties":{"id":"84753","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.102793,41.535409],[-112.056534,41.445422],[-112.031516,41.439369],[-111.969381,41.488399],[-111.968745,41.544195],[-112.005863,41.616079],[-112.070862,41.640573],[-112.121864,41.633546],[-112.117218,41.586103],[-112.102793,41.535409]]]},"properties":{"id":"84302","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.162455,41.608835],[-112.117218,41.586103],[-112.121864,41.633546],[-112.128396,41.635641],[-112.162455,41.608835]]]},"properties":{"id":"84301","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.179159,41.891614],[-113.205047,41.996445],[-113.56381,41.992015],[-113.548112,41.887439],[-113.179159,41.891614]]]},"properties":{"id":"83342","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.848481,41.777932],[-111.775477,41.768003],[-111.766116,41.818623],[-111.787729,41.80723],[-111.836961,41.799328],[-111.848481,41.777932]]]},"properties":{"id":"84341","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.949993,41.888457],[-111.99107,41.833142],[-111.955336,41.786667],[-111.952537,41.746422],[-111.848481,41.777932],[-111.836961,41.799328],[-111.818935,41.823956],[-111.787729,41.80723],[-111.766116,41.818623],[-111.757759,41.868863],[-111.908744,41.896871],[-111.949993,41.888457]]]},"properties":{"id":"84335","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.998589,41.917376],[-112.046991,41.85077],[-112.046905,41.846095],[-112.043488,41.837501],[-111.99107,41.833142],[-111.949993,41.888457],[-111.977155,41.917492],[-111.998589,41.917376]]]},"properties":{"id":"84327","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.043488,41.837501],[-112.010467,41.788259],[-111.955336,41.786667],[-111.99107,41.833142],[-112.043488,41.837501]]]},"properties":{"id":"84304","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.035681,41.076823],[-111.987759,41.104665],[-112.023275,41.152444],[-112.083145,41.152343],[-112.130033,41.136763],[-112.117135,41.09644],[-112.035681,41.076823]]]},"properties":{"id":"84015","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.941433,41.054528],[-112.006778,41.034117],[-111.943501,40.994823],[-111.896146,41.016858],[-111.901589,41.059885],[-111.941433,41.054528]]]},"properties":{"id":"84037","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.896146,41.016858],[-111.943501,40.994823],[-111.900878,40.952086],[-111.875067,40.952538],[-111.896146,41.016858]]]},"properties":{"id":"84025","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.365775,40.435204],[-110.313421,40.357655],[-110.247427,40.403103],[-110.209078,40.436714],[-110.365775,40.435204]]]},"properties":{"id":"84002","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.857133,39.469451],[-110.77559,39.356389],[-110.673123,39.469367],[-110.857133,39.469451]]]},"properties":{"id":"84521","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.029432,39.237241],[-111.160948,39.365975],[-111.282693,39.46728],[-111.053074,39.212823],[-111.029432,39.237241]]]},"properties":{"id":"84537","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.204737,37.536591],[-112.049611,37.530835],[-111.957732,37.535957],[-112.040425,37.546846],[-112.062215,37.593722],[-112.063895,37.594928],[-112.129073,37.573563],[-112.204737,37.536591]]]},"properties":{"id":"84718","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.51117,38.307249],[-111.171701,38.152528],[-111.064561,38.152281],[-110.916219,38.475606],[-111.46246,38.398549],[-111.560691,38.356558],[-111.51117,38.307249]]]},"properties":{"id":"84775","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.911771,37.779862],[-112.963035,37.792614],[-112.936653,37.741673],[-112.911771,37.779862]]]},"properties":{"id":"84772","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.712598,37.604686],[-113.601631,37.604793],[-113.458582,37.794318],[-113.439674,37.96916],[-113.480613,37.96905],[-113.867285,37.891926],[-113.843973,37.776587],[-113.712598,37.604686]]]},"properties":{"id":"84714","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-113.439674,37.96916],[-113.256103,38.14871],[-113.416818,38.148666],[-113.480613,37.96905],[-113.439674,37.96916]]],[[[-113.198807,37.715845],[-112.973668,37.649522],[-112.936653,37.741673],[-112.963035,37.792614],[-113.198807,37.715845]]]]},"properties":{"id":"84721","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.328455,37.529123],[-113.254453,37.371754],[-113.189131,37.367262],[-113.180847,37.48103],[-113.214261,37.505252],[-113.304942,37.529053],[-113.328455,37.529123]]]},"properties":{"id":"84757","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.047337,39.724798],[-113.701286,39.905414],[-113.701267,40.341426],[-114.046432,40.179036],[-114.047337,39.724798]]]},"properties":{"id":"84034","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.402039,38.823288],[-112.4213,38.885952],[-112.502964,38.593418],[-112.466527,38.673818],[-112.424484,38.681493],[-112.402039,38.823288]]]},"properties":{"id":"84637","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.939239,39.147662],[-113.766245,39.342886],[-114.047277,39.461335],[-113.939239,39.147662]]]},"properties":{"id":"84728","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.31228,39.553988],[-112.42685,39.553857],[-112.488326,39.409889],[-112.358504,39.456783],[-112.31228,39.553988]]]},"properties":{"id":"84640","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.792154,40.586009],[-111.84796,40.615156],[-111.853397,40.610549],[-111.853228,40.580441],[-111.792154,40.586009]]]},"properties":{"id":"84093","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.872005,40.54431],[-111.870862,40.608718],[-111.912399,40.595887],[-111.920116,40.579753],[-111.894573,40.544312],[-111.872005,40.54431]]]},"properties":{"id":"84070","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.005358,40.609451],[-112.004817,40.581095],[-111.920116,40.579753],[-111.912399,40.595887],[-111.921291,40.609459],[-112.005358,40.609451]]]},"properties":{"id":"84088","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.908252,40.769343],[-111.908458,40.741601],[-111.891074,40.741612],[-111.891094,40.767174],[-111.893957,40.769346],[-111.899675,40.771545],[-111.899674,40.769346],[-111.902533,40.769342],[-111.902535,40.771545],[-111.905395,40.771545],[-111.908252,40.769343]]]},"properties":{"id":"84101","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.696928,37.835217],[-109.256231,37.600597],[-109.041832,37.770832],[-109.042319,38.102719],[-109.365616,38.305454],[-109.88572,38.189202],[-110.17612,37.960176],[-109.972421,37.708357],[-109.696928,37.835217]]]},"properties":{"id":"84535","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.256231,37.600597],[-109.191694,37.430741],[-109.043787,37.470104],[-109.041832,37.770832],[-109.256231,37.600597]]]},"properties":{"id":"81324","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.916243,39.180582],[-111.867202,39.105105],[-111.783401,39.129506],[-111.699013,39.149292],[-111.711474,39.206012],[-111.799988,39.18857],[-111.916243,39.180582]]]},"properties":{"id":"84634","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.783401,39.129506],[-111.867202,39.105105],[-111.840813,39.074906],[-111.792291,39.0551],[-111.776987,39.10841],[-111.783401,39.129506]]]},"properties":{"id":"84622","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.357118,39.430309],[-111.542011,39.521012],[-111.525851,39.49009],[-111.5737,39.399464],[-111.496098,39.368592],[-111.357118,39.430309]]]},"properties":{"id":"84662","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.181458,38.654239],[-112.147118,38.694734],[-112.18071,38.657432],[-112.181458,38.654239]]]},"properties":{"id":"84724","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.870159,40.100216],[-109.764804,40.269263],[-109.859392,40.215279],[-109.870159,40.100216]]]},"properties":{"id":"84063","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.658627,40.24434],[-111.647853,40.193407],[-111.616974,40.243339],[-111.658627,40.24434]]]},"properties":{"id":"84606","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.658627,40.24434],[-111.616974,40.243339],[-111.542404,40.25772],[-111.457193,40.300741],[-111.531409,40.405039],[-111.587958,40.473679],[-111.678528,40.35197],[-111.66306,40.33381],[-111.681298,40.260619],[-111.700682,40.2562],[-111.658627,40.24434]]]},"properties":{"id":"84604","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.681314,40.532986],[-111.804519,40.46546],[-111.848904,40.458163],[-111.835246,40.355862],[-111.772832,40.334308],[-111.764249,40.354038],[-111.570961,40.5327],[-111.59393,40.577071],[-111.681314,40.532986]]]},"properties":{"id":"84003","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.942963,40.025026],[-111.916746,39.883021],[-111.831581,39.938207],[-111.881284,40.028823],[-111.942963,40.025026]]]},"properties":{"id":"84633","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.531409,40.405039],[-111.457193,40.300741],[-111.311834,40.286464],[-111.478032,40.443995],[-111.531409,40.405039]]]},"properties":{"id":"84082","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.643161,37.157786],[-113.647139,37.107352],[-113.605586,37.066033],[-113.530613,37.125855],[-113.537926,37.159444],[-113.612626,37.307773],[-113.629962,37.309384],[-113.636045,37.17545],[-113.643161,37.157786]]]},"properties":{"id":"84770","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.900994,37.499738],[-113.180847,37.48103],[-113.189131,37.367262],[-113.263577,37.273582],[-113.233222,37.199649],[-113.125614,37.143088],[-113.091668,37.171655],[-112.901067,37.475974],[-112.900994,37.499738]]]},"properties":{"id":"84779","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.643161,37.157786],[-113.71544,37.128085],[-113.647139,37.107352],[-113.643161,37.157786]]]},"properties":{"id":"84765","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.454797,37.529267],[-113.561991,37.412826],[-113.436139,37.529297],[-113.454797,37.529267]]]},"properties":{"id":"84781","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.506837,38.270976],[-111.171701,38.152528],[-111.51117,38.307249],[-111.506837,38.270976]]]},"properties":{"id":"84773","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.606555,38.360227],[-111.542177,38.488656],[-111.607919,38.359798],[-111.606555,38.360227]]]},"properties":{"id":"84747","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.51117,38.307249],[-111.560691,38.356558],[-111.606555,38.360227],[-111.607919,38.359798],[-111.506837,38.270976],[-111.51117,38.307249]]]},"properties":{"id":"84715","state_code":"49"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.058211,43.962829],[-73.147893,43.866169],[-73.216556,43.823265],[-73.234318,43.764192],[-73.193667,43.718213],[-73.140419,43.725698],[-73.118141,43.709194],[-73.053399,43.720158],[-72.993584,43.813189],[-72.958853,43.826542],[-72.980158,43.882587],[-72.979716,43.922414],[-73.059174,43.970614],[-73.058211,43.962829]]]},"properties":{"id":"05733","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.17929,43.908372],[-73.247873,43.920459],[-73.222688,43.858072],[-73.216556,43.823265],[-73.147893,43.866169],[-73.17929,43.908372]]]},"properties":{"id":"05778","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.263024,44.034756],[-73.232682,44.092503],[-73.179623,44.18445],[-73.180288,44.187351],[-73.324405,44.225579],[-73.399043,44.027012],[-73.263024,44.034756]]]},"properties":{"id":"05491","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.255363,44.230567],[-73.180288,44.187351],[-73.179623,44.18445],[-73.163131,44.208052],[-73.124445,44.258067],[-73.145082,44.273403],[-73.270542,44.260121],[-73.255363,44.230567]]]},"properties":{"id":"05473","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.912193,43.965554],[-72.81633,43.937805],[-72.791336,43.961855],[-72.754731,44.011175],[-72.821553,44.052634],[-72.909019,44.072587],[-72.908083,44.035474],[-72.912193,43.965554]]]},"properties":{"id":"05747","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.258112,43.947228],[-73.374272,43.902103],[-73.375881,43.831492],[-73.222688,43.858072],[-73.247873,43.920459],[-73.258112,43.947228]]]},"properties":{"id":"05770","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.080579,43.119233],[-73.140484,43.025853],[-73.002789,43.023007],[-73.00054,43.061951],[-72.997126,43.116994],[-73.003734,43.117147],[-73.080579,43.119233]]]},"properties":{"id":"05252","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.128019,43.213817],[-73.041069,43.211973],[-72.994975,43.305202],[-73.122704,43.308682],[-73.128019,43.213817]]]},"properties":{"id":"05251","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.278306,42.837558],[-73.281557,42.76614],[-73.23946,42.816589],[-73.278306,42.837558]]]},"properties":{"id":"05260","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.043061,44.156596],[-72.059042,44.255718],[-72.064639,44.257155],[-72.144837,44.270186],[-72.153455,44.215137],[-72.087328,44.169132],[-72.043061,44.156596]]]},"properties":{"id":"05042","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.202901,44.624073],[-72.065884,44.623599],[-72.088864,44.683714],[-72.146599,44.703495],[-72.202901,44.624073]]]},"properties":{"id":"05866","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.18067,44.511763],[-72.28197,44.550055],[-72.322581,44.565036],[-72.22289,44.462212],[-72.18067,44.511763]]]},"properties":{"id":"05836","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.971119,44.550303],[-72.838696,44.459242],[-72.838638,44.459229],[-72.809349,44.547921],[-72.858425,44.579742],[-72.936079,44.634953],[-72.971119,44.550303]]]},"properties":{"id":"05489","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.169971,44.494324],[-73.162273,44.490017],[-73.157398,44.490126],[-73.165782,44.499005],[-73.169971,44.494324]]]},"properties":{"id":"05439","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.218104,44.422006],[-73.280465,44.348648],[-73.165262,44.362091],[-73.125939,44.365891],[-73.154482,44.405378],[-73.218104,44.422006]]]},"properties":{"id":"05482","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.038028,44.651157],[-73.097327,44.570491],[-72.971119,44.550303],[-72.936079,44.634953],[-72.966616,44.639903],[-73.038028,44.651157]]]},"properties":{"id":"05494","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.50536,45.013299],[-71.530403,44.999364],[-71.504669,45.008315],[-71.480552,45.002334],[-71.476348,45.009426],[-71.50536,45.013299]]]},"properties":{"id":"05902","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.675841,44.794213],[-72.593494,44.790616],[-72.578161,44.781578],[-72.532648,44.833512],[-72.500934,44.926202],[-72.535709,44.925777],[-72.655669,44.907221],[-72.678374,44.837632],[-72.675841,44.794213]]]},"properties":{"id":"05471","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.198774,44.773544],[-73.211156,44.774485],[-73.20838,44.76801],[-73.198774,44.773544]]]},"properties":{"id":"05481","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.317308,44.983909],[-73.259346,44.937175],[-73.212064,45.013171],[-73.317308,44.983909]]]},"properties":{"id":"05440","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.744603,44.786457],[-72.704251,44.718356],[-72.663025,44.703195],[-72.593494,44.790616],[-72.675841,44.794213],[-72.744603,44.786457]]]},"properties":{"id":"05442","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.204442,44.039138],[-72.167977,44.054683],[-72.197961,44.099393],[-72.223432,44.09731],[-72.258675,44.077933],[-72.204442,44.039138]]]},"properties":{"id":"05040","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.509922,43.966072],[-72.5682,43.981948],[-72.549897,43.91077],[-72.509922,43.966072]]]},"properties":{"id":"05041","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.342642,44.956193],[-72.410829,44.905804],[-72.402766,44.841408],[-72.371706,44.841684],[-72.342642,44.956193]]]},"properties":{"id":"05868","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.980972,44.86836],[-72.034867,44.81825],[-71.974318,44.786519],[-71.920161,44.836942],[-71.980972,44.86836]]]},"properties":{"id":"05833","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.93382,43.574704],[-72.991198,43.572058],[-73.002454,43.571665],[-72.981341,43.507819],[-72.92962,43.48457],[-72.92847,43.484565],[-72.93382,43.574704]]]},"properties":{"id":"05759","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.255662,44.359582],[-72.352624,44.420601],[-72.410095,44.41875],[-72.412395,44.340704],[-72.308938,44.305594],[-72.255662,44.359582]]]},"properties":{"id":"05658","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.742738,43.023312],[-72.605429,43.072133],[-72.623539,43.105797],[-72.682546,43.132528],[-72.725841,43.074136],[-72.734146,43.049987],[-72.742738,43.023312]]]},"properties":{"id":"05353","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.821828,42.827011],[-72.926024,42.829915],[-72.930258,42.739293],[-72.816355,42.736749],[-72.821828,42.827011]]]},"properties":{"id":"05361","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.487453,43.561609],[-72.532858,43.534086],[-72.514295,43.477994],[-72.455084,43.435505],[-72.439903,43.41017],[-72.440926,43.409076],[-72.424007,43.401828],[-72.401552,43.403689],[-72.396095,43.519894],[-72.468265,43.566445],[-72.487453,43.561609]]]},"properties":{"id":"05089","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.782812,43.804338],[-72.820043,43.716834],[-72.700945,43.682831],[-72.694415,43.694051],[-72.765979,43.823189],[-72.782812,43.804338]]]},"properties":{"id":"05772","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.398444,43.618923],[-72.389449,43.6531],[-72.454934,43.629143],[-72.398854,43.619004],[-72.398444,43.618923]]]},"properties":{"id":"05059","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.531779,43.714507],[-72.462704,43.704718],[-72.431351,43.71388],[-72.477709,43.742098],[-72.491322,43.74596],[-72.531779,43.714507]]]},"properties":{"id":"05053","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.058211,43.962829],[-73.17929,43.908372],[-73.147893,43.866169],[-73.058211,43.962829]]]},"properties":{"id":"05769","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.075913,44.283015],[-72.952167,44.161271],[-72.92207,44.222232],[-73.020378,44.303744],[-73.075913,44.283015]]]},"properties":{"id":"05487","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.066256,43.14036],[-73.054834,43.16802],[-73.082327,43.159024],[-73.066256,43.14036]]]},"properties":{"id":"05254","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.957584,44.489985],[-72.083851,44.510398],[-72.097081,44.357907],[-72.004663,44.323878],[-71.837656,44.347801],[-71.957584,44.489985]]]},"properties":{"id":"05819","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.018928,44.538348],[-72.009776,44.534503],[-72.008152,44.542436],[-72.018928,44.538348]]]},"properties":{"id":"05850","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.165782,44.499005],[-73.201992,44.492504],[-73.176383,44.484801],[-73.170984,44.489199],[-73.169971,44.494324],[-73.165782,44.499005]]]},"properties":{"id":"05404","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.683469,44.425377],[-71.723131,44.410901],[-71.703155,44.413276],[-71.683469,44.425377]]]},"properties":{"id":"05904","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.97835,44.906945],[-72.975737,44.901183],[-72.970842,44.89615],[-72.968549,44.898396],[-72.976009,44.906761],[-72.973177,44.909937],[-72.97835,44.906945]]]},"properties":{"id":"05485","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.522388,44.658001],[-72.476651,44.641841],[-72.423089,44.715163],[-72.417315,44.722893],[-72.527688,44.762314],[-72.557868,44.671657],[-72.522388,44.658001]]]},"properties":{"id":"05653","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.538126,44.062387],[-72.509922,43.966072],[-72.394865,43.929106],[-72.382189,44.008176],[-72.475571,44.041269],[-72.538126,44.062387]]]},"properties":{"id":"05038","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.113952,44.987433],[-71.975205,44.932557],[-71.921204,44.932148],[-71.897608,45.00835],[-72.141935,45.005936],[-72.113952,44.987433]]]},"properties":{"id":"05830","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.329241,45.004727],[-72.55436,45.008271],[-72.535709,44.925777],[-72.500934,44.926202],[-72.410829,44.905804],[-72.342642,44.956193],[-72.329241,45.004727]]]},"properties":{"id":"05859","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.30976,44.67299],[-72.25729,44.746247],[-72.338435,44.703405],[-72.30976,44.67299]]],[[[-72.36785,44.715726],[-72.36528,44.794469],[-72.417315,44.722893],[-72.423089,44.715163],[-72.392055,44.703131],[-72.36785,44.715726]]]]},"properties":{"id":"05820","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.36785,44.715726],[-72.338435,44.703405],[-72.25729,44.746247],[-72.241339,44.770379],[-72.226364,44.791923],[-72.239229,44.849402],[-72.277715,44.863273],[-72.308968,44.875157],[-72.343955,44.842086],[-72.36528,44.794469],[-72.36785,44.715726]]]},"properties":{"id":"05845","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.36528,44.794469],[-72.343955,44.842086],[-72.371706,44.841684],[-72.402766,44.841408],[-72.532648,44.833512],[-72.578161,44.781578],[-72.527688,44.762314],[-72.417315,44.722893],[-72.36528,44.794469]]]},"properties":{"id":"05847","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.342642,44.956193],[-72.371706,44.841684],[-72.343955,44.842086],[-72.308968,44.875157],[-72.24674,45.004642],[-72.329241,45.004727],[-72.342642,44.956193]]]},"properties":{"id":"05857","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.032081,43.610794],[-73.002454,43.571665],[-72.991198,43.572058],[-73.000491,43.663467],[-73.032081,43.610794]]]},"properties":{"id":"05736","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.05526,43.664857],[-73.022629,43.667492],[-73.053399,43.720158],[-73.118141,43.709194],[-73.096561,43.635471],[-73.05526,43.664857]]]},"properties":{"id":"05744","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.518571,44.241475],[-72.480371,44.23383],[-72.460989,44.312467],[-72.503627,44.328404],[-72.518571,44.241475]]]},"properties":{"id":"05651","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.872478,43.053549],[-72.734146,43.049987],[-72.725841,43.074136],[-72.774476,43.14181],[-72.793613,43.146974],[-72.866438,43.146308],[-72.868737,43.11317],[-72.872478,43.053549]]]},"properties":{"id":"05343","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.878744,43.113671],[-73.00054,43.061951],[-73.002789,43.023007],[-73.008063,42.930087],[-72.921376,42.926792],[-72.87956,42.990313],[-72.872478,43.053549],[-72.868737,43.11317],[-72.878744,43.113671]]]},"properties":{"id":"05360","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.853417,42.989426],[-72.797349,42.922145],[-72.774838,42.924046],[-72.754795,42.98724],[-72.853417,42.989426]]]},"properties":{"id":"05341","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.558217,43.147269],[-72.563046,43.128216],[-72.515576,43.127174],[-72.553859,43.14723],[-72.558217,43.147269]]]},"properties":{"id":"05154","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.734146,43.049987],[-72.872478,43.053549],[-72.87956,42.990313],[-72.853417,42.989426],[-72.754795,42.98724],[-72.742738,43.023312],[-72.734146,43.049987]]]},"properties":{"id":"05355","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.727184,43.468717],[-72.759799,43.354144],[-72.763433,43.290755],[-72.661331,43.350779],[-72.661126,43.453756],[-72.727184,43.468717]]]},"properties":{"id":"05149","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.617718,43.88589],[-72.723047,43.888775],[-72.765979,43.823189],[-72.694415,43.694051],[-72.619709,43.729003],[-72.595665,43.751815],[-72.590157,43.878035],[-72.617718,43.88589]]]},"properties":{"id":"05032","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.401552,43.403689],[-72.424007,43.401828],[-72.41128,43.385911],[-72.401552,43.403689]]],[[[-72.439903,43.41017],[-72.455084,43.435505],[-72.530174,43.444085],[-72.538771,43.380975],[-72.545447,43.345247],[-72.502005,43.342868],[-72.440926,43.409076],[-72.439903,43.41017]]]]},"properties":{"id":"05151","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.650099,43.540088],[-72.758005,43.587517],[-72.772829,43.591565],[-72.774982,43.579375],[-72.771543,43.477862],[-72.727184,43.468717],[-72.661126,43.453756],[-72.660643,43.456996],[-72.650099,43.540088]]]},"properties":{"id":"05056","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.64754,43.561169],[-72.634371,43.557945],[-72.6158,43.584961],[-72.631806,43.610745],[-72.64754,43.561169]]]},"properties":{"id":"05034","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.255363,44.230567],[-73.324405,44.225579],[-73.180288,44.187351],[-73.255363,44.230567]]]},"properties":{"id":"05456","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.125939,44.365891],[-73.165262,44.362091],[-73.145082,44.273403],[-73.124445,44.258067],[-73.075913,44.283015],[-73.020378,44.303744],[-73.032655,44.365071],[-73.082148,44.370705],[-73.125939,44.365891]]]},"properties":{"id":"05461","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.22197,42.933019],[-73.275961,42.901225],[-73.278306,42.837558],[-73.23946,42.816589],[-73.137302,42.8355],[-73.015111,42.832074],[-73.01195,42.859108],[-73.008063,42.930087],[-73.22197,42.933019]]]},"properties":{"id":"05201","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.997126,43.116994],[-72.878744,43.113671],[-72.868737,43.11317],[-72.866438,43.146308],[-72.861994,43.205095],[-72.978471,43.20959],[-73.001787,43.210826],[-73.003734,43.117147],[-72.997126,43.116994]]]},"properties":{"id":"05340","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.018645,42.741059],[-72.930258,42.739293],[-72.926024,42.829915],[-73.01195,42.859108],[-73.015111,42.832074],[-73.018645,42.741059]]]},"properties":{"id":"05350","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.270041,43.030716],[-73.275961,42.901225],[-73.22197,42.933019],[-73.220833,43.029012],[-73.270041,43.030716]]]},"properties":{"id":"05257","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.861994,43.205095],[-72.866438,43.146308],[-72.793613,43.146974],[-72.849875,43.227849],[-72.861994,43.205095]]],[[[-73.00054,43.061951],[-72.878744,43.113671],[-72.997126,43.116994],[-73.00054,43.061951]]]]},"properties":{"id":"05155","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.153455,44.215137],[-72.17026,44.192053],[-72.187323,44.133353],[-72.116714,44.176928],[-72.087328,44.169132],[-72.153455,44.215137]]]},"properties":{"id":"05069","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.151982,44.501151],[-72.15289,44.374681],[-72.097081,44.357907],[-72.083851,44.510398],[-72.137758,44.518619],[-72.151982,44.501151]]]},"properties":{"id":"05828","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.995493,44.605392],[-72.007182,44.703113],[-72.04952,44.71764],[-72.088864,44.683714],[-72.065884,44.623599],[-71.995493,44.605392]]]},"properties":{"id":"05867","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.24191,44.492198],[-73.214232,44.509261],[-73.275873,44.529847],[-73.24191,44.492198]]]},"properties":{"id":"05408","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.190251,44.472612],[-73.196727,44.469849],[-73.191882,44.466013],[-73.190251,44.472612]]]},"properties":{"id":"05405","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.169971,44.494324],[-73.170984,44.489199],[-73.162273,44.490017],[-73.169971,44.494324]]],[[[-73.275873,44.529847],[-73.214232,44.509261],[-73.201992,44.492504],[-73.165782,44.499005],[-73.157398,44.490126],[-73.137101,44.500911],[-73.097327,44.570491],[-73.232517,44.61398],[-73.275873,44.529847]]]]},"properties":{"id":"05446","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.974318,44.786519],[-71.939936,44.769032],[-71.882797,44.740071],[-71.858382,44.72766],[-71.765841,44.818999],[-71.874433,44.876382],[-71.920161,44.836942],[-71.974318,44.786519]]]},"properties":{"id":"05846","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.663025,44.703195],[-72.597332,44.678964],[-72.557868,44.671657],[-72.527688,44.762314],[-72.578161,44.781578],[-72.593494,44.790616],[-72.663025,44.703195]]]},"properties":{"id":"05652","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.167977,44.054683],[-72.204442,44.039138],[-72.233014,43.992127],[-72.09902,43.957454],[-72.078571,44.029626],[-72.167977,44.054683]]]},"properties":{"id":"05033","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.462555,44.121429],[-72.475571,44.041269],[-72.382189,44.008176],[-72.348089,44.096112],[-72.39858,44.131453],[-72.410421,44.13366],[-72.444433,44.129202],[-72.462555,44.121429]]]},"properties":{"id":"05675","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.590157,43.878035],[-72.595665,43.751815],[-72.551804,43.725327],[-72.531779,43.714507],[-72.491322,43.74596],[-72.460132,43.838291],[-72.546989,43.869362],[-72.590157,43.878035]]]},"properties":{"id":"05068","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.022629,43.667492],[-73.05526,43.664857],[-73.032081,43.610794],[-73.000491,43.663467],[-73.003142,43.666941],[-73.022629,43.667492]]]},"properties":{"id":"05765","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.858574,43.694867],[-72.955738,43.691261],[-73.003142,43.666941],[-73.000491,43.663467],[-72.991198,43.572058],[-72.93382,43.574704],[-72.774982,43.579375],[-72.772829,43.591565],[-72.858574,43.694867]]]},"properties":{"id":"05701","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.858574,43.694867],[-72.772829,43.591565],[-72.758005,43.587517],[-72.715015,43.658973],[-72.700945,43.682831],[-72.820043,43.716834],[-72.837188,43.719878],[-72.858574,43.694867]]]},"properties":{"id":"05751","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.480371,44.23383],[-72.368353,44.204057],[-72.317621,44.298384],[-72.308938,44.305594],[-72.412395,44.340704],[-72.467277,44.341789],[-72.470273,44.3298],[-72.460989,44.312467],[-72.480371,44.23383]]]},"properties":{"id":"05667","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.654078,44.16724],[-72.65308,44.165762],[-72.651629,44.167767],[-72.652957,44.168728],[-72.654078,44.16724]]]},"properties":{"id":"05664","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.802553,42.826467],[-72.771817,42.78671],[-72.686173,42.733187],[-72.565867,42.729992],[-72.545579,42.823935],[-72.520849,42.961687],[-72.608453,42.968221],[-72.641106,42.947214],[-72.673663,42.92233],[-72.774838,42.924046],[-72.797349,42.922145],[-72.798595,42.922173],[-72.802553,42.826467]]]},"properties":{"id":"05301","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.774476,43.14181],[-72.725841,43.074136],[-72.682546,43.132528],[-72.678631,43.198822],[-72.73562,43.21955],[-72.774476,43.14181]]]},"properties":{"id":"05359","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.337451,43.809705],[-72.404399,43.74507],[-72.305099,43.692414],[-72.205195,43.770955],[-72.258696,43.786859],[-72.337451,43.809705]]]},"properties":{"id":"05055","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.618738,43.452963],[-72.660643,43.456996],[-72.661126,43.453756],[-72.661331,43.350779],[-72.654126,43.350383],[-72.618738,43.452963]]]},"properties":{"id":"05153","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.64754,43.561169],[-72.631806,43.610745],[-72.715015,43.658973],[-72.758005,43.587517],[-72.650099,43.540088],[-72.64754,43.561169]]]},"properties":{"id":"05035","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.234318,43.764192],[-73.216556,43.823265],[-73.222688,43.858072],[-73.375881,43.831492],[-73.355034,43.753764],[-73.234318,43.764192]]]},"properties":{"id":"05760","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.059174,43.970614],[-72.979716,43.922414],[-72.912193,43.965554],[-72.908083,44.035474],[-73.064657,44.040797],[-73.059174,43.970614]]]},"properties":{"id":"05766","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.754731,44.011175],[-72.649139,44.076717],[-72.770121,44.126433],[-72.821553,44.052634],[-72.754731,44.011175]]]},"properties":{"id":"05669","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.103206,43.970061],[-73.102978,43.972889],[-73.108209,43.972152],[-73.103206,43.970061]]]},"properties":{"id":"05740","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.018645,42.741059],[-73.015111,42.832074],[-73.137302,42.8355],[-73.142485,42.743588],[-73.018645,42.741059]]]},"properties":{"id":"05352","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.23946,42.816589],[-73.281557,42.76614],[-73.142485,42.743588],[-73.137302,42.8355],[-73.23946,42.816589]]]},"properties":{"id":"05261","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.882797,44.740071],[-71.910282,44.647038],[-71.842732,44.611165],[-71.704198,44.62355],[-71.858382,44.72766],[-71.882797,44.740071]]]},"properties":{"id":"05837","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.915008,44.383121],[-72.804995,44.451676],[-72.838638,44.459229],[-72.838696,44.459242],[-72.915008,44.383121]]],[[[-73.082148,44.370705],[-73.032655,44.365071],[-72.953599,44.345781],[-72.931586,44.388311],[-73.022742,44.434135],[-73.082148,44.370705]]]]},"properties":{"id":"05477","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.931586,44.388311],[-72.915008,44.383121],[-72.838696,44.459242],[-72.971119,44.550303],[-73.031962,44.468035],[-73.022742,44.434135],[-72.931586,44.388311]]]},"properties":{"id":"05465","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.191882,44.466013],[-73.231455,44.447178],[-73.218104,44.422006],[-73.154482,44.405378],[-73.135877,44.475022],[-73.137101,44.500911],[-73.157398,44.490126],[-73.162273,44.490017],[-73.170984,44.489199],[-73.176383,44.484801],[-73.190251,44.472612],[-73.191882,44.466013]]]},"properties":{"id":"05403","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.154482,44.405378],[-73.125939,44.365891],[-73.082148,44.370705],[-73.022742,44.434135],[-73.031962,44.468035],[-73.135877,44.475022],[-73.154482,44.405378]]]},"properties":{"id":"05495","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.003333,44.919895],[-72.993433,45.014876],[-73.015651,45.015097],[-73.052625,44.933298],[-73.049303,44.911638],[-73.003333,44.919895]]]},"properties":{"id":"05459","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.941301,44.901928],[-72.914116,44.849591],[-72.909289,44.8085],[-72.83998,44.812453],[-72.678374,44.837632],[-72.655669,44.907221],[-72.692463,44.928195],[-72.703151,44.927633],[-72.708997,44.934024],[-72.72773,45.015678],[-72.832619,45.016421],[-72.941301,44.901928]]]},"properties":{"id":"05450","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.83998,44.812453],[-72.909289,44.8085],[-72.973514,44.755427],[-72.939817,44.673041],[-72.904334,44.700099],[-72.833765,44.734506],[-72.83998,44.812453]]]},"properties":{"id":"05448","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.939817,44.673041],[-72.966616,44.639903],[-72.936079,44.634953],[-72.858425,44.579742],[-72.904334,44.700099],[-72.939817,44.673041]]]},"properties":{"id":"05444","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.768196,44.56298],[-72.596807,44.479292],[-72.546407,44.451934],[-72.518462,44.525801],[-72.53364,44.536821],[-72.525556,44.542491],[-72.524735,44.573301],[-72.650529,44.603608],[-72.747468,44.594847],[-72.768196,44.56298]]]},"properties":{"id":"05661","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.596807,44.479292],[-72.768196,44.56298],[-72.809349,44.547921],[-72.838638,44.459229],[-72.804995,44.451676],[-72.739512,44.43293],[-72.654398,44.398224],[-72.596807,44.479292]]]},"properties":{"id":"05672","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.518462,44.525801],[-72.546407,44.451934],[-72.490548,44.425693],[-72.442695,44.494174],[-72.374786,44.584125],[-72.368151,44.592287],[-72.476651,44.641841],[-72.522388,44.658001],[-72.524735,44.573301],[-72.525556,44.542491],[-72.518462,44.525801]]]},"properties":{"id":"05680","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.525556,44.542491],[-72.53364,44.536821],[-72.518462,44.525801],[-72.525556,44.542491]]]},"properties":{"id":"05657","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.256717,43.883313],[-72.224938,43.818095],[-72.167541,43.870998],[-72.09902,43.957454],[-72.233014,43.992127],[-72.241471,43.979465],[-72.265289,43.937232],[-72.260061,43.909147],[-72.256717,43.883313]]]},"properties":{"id":"05045","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.39858,44.131453],[-72.30498,44.183149],[-72.341923,44.195338],[-72.410421,44.13366],[-72.39858,44.131453]]]},"properties":{"id":"05649","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.282972,44.184887],[-72.223432,44.09731],[-72.197961,44.099393],[-72.187323,44.133353],[-72.17026,44.192053],[-72.282972,44.184887]]]},"properties":{"id":"05076","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.617718,43.88589],[-72.590157,43.878035],[-72.546989,43.869362],[-72.549897,43.91077],[-72.5682,43.981948],[-72.624815,43.997174],[-72.617718,43.88589]]]},"properties":{"id":"05061","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.078857,44.920492],[-72.076383,44.903866],[-72.120107,44.864724],[-72.034867,44.81825],[-71.980972,44.86836],[-72.078857,44.920492]]]},"properties":{"id":"05872","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.392055,44.703131],[-72.423089,44.715163],[-72.476651,44.641841],[-72.368151,44.592287],[-72.315377,44.650692],[-72.392055,44.703131]]]},"properties":{"id":"05826","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.053956,43.400738],[-72.882891,43.301316],[-72.867902,43.300742],[-72.853439,43.371076],[-72.876357,43.39346],[-72.92962,43.48457],[-72.981341,43.507819],[-73.077388,43.483027],[-73.113909,43.431159],[-73.117999,43.411542],[-73.053956,43.400738]]]},"properties":{"id":"05773","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.234318,43.764192],[-73.355034,43.753764],[-73.245637,43.539549],[-73.243247,43.587451],[-73.184685,43.707295],[-73.193667,43.718213],[-73.234318,43.764192]]]},"properties":{"id":"05743","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.241657,43.313915],[-73.217994,43.393485],[-73.250847,43.408703],[-73.254633,43.314675],[-73.241657,43.313915]]]},"properties":{"id":"05775","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.205721,43.649904],[-73.231122,43.611478],[-73.190765,43.623291],[-73.175088,43.64602],[-73.205721,43.649904]]],[[[-73.140419,43.725698],[-73.193667,43.718213],[-73.184685,43.707295],[-73.140419,43.725698]]]]},"properties":{"id":"05732","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.993584,43.813189],[-72.955738,43.691261],[-72.858574,43.694867],[-72.837188,43.719878],[-72.873366,43.839633],[-72.958853,43.826542],[-72.993584,43.813189]]]},"properties":{"id":"05737","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.490548,44.425693],[-72.546407,44.451934],[-72.596807,44.479292],[-72.654398,44.398224],[-72.534968,44.33989],[-72.490548,44.425693]]]},"properties":{"id":"05682","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.558217,43.147269],[-72.557664,43.162116],[-72.557326,43.196195],[-72.678631,43.198822],[-72.682546,43.132528],[-72.623539,43.105797],[-72.56534,43.11612],[-72.563046,43.128216],[-72.558217,43.147269]]]},"properties":{"id":"05146","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.461625,43.05909],[-72.515576,43.127174],[-72.563046,43.128216],[-72.56534,43.11612],[-72.575148,43.071099],[-72.608453,42.968221],[-72.520849,42.961687],[-72.461625,43.05909]]]},"properties":{"id":"05346","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.623539,43.105797],[-72.605429,43.072133],[-72.575148,43.071099],[-72.56534,43.11612],[-72.623539,43.105797]]],[[[-72.538771,43.380975],[-72.654126,43.350383],[-72.661331,43.350779],[-72.763433,43.290755],[-72.755812,43.239805],[-72.73562,43.21955],[-72.678631,43.198822],[-72.557326,43.196195],[-72.549912,43.235809],[-72.546454,43.317589],[-72.545447,43.345247],[-72.538771,43.380975]]]]},"properties":{"id":"05143","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.565867,42.729992],[-72.458436,42.72685],[-72.545579,42.823935],[-72.565867,42.729992]]]},"properties":{"id":"05354","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.686173,42.733187],[-72.771817,42.78671],[-72.721365,42.734101],[-72.686173,42.733187]]]},"properties":{"id":"05358","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.551804,43.725327],[-72.595665,43.751815],[-72.619709,43.729003],[-72.572909,43.688666],[-72.551804,43.725327]]]},"properties":{"id":"05031","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.660643,43.456996],[-72.618738,43.452963],[-72.530174,43.444085],[-72.514295,43.477994],[-72.532858,43.534086],[-72.634371,43.557945],[-72.64754,43.561169],[-72.650099,43.540088],[-72.660643,43.456996]]]},"properties":{"id":"05062","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.179623,44.18445],[-73.232682,44.092503],[-73.096003,44.054441],[-73.163131,44.208052],[-73.179623,44.18445]]]},"properties":{"id":"05472","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.041069,43.211973],[-73.128019,43.213817],[-73.080579,43.119233],[-73.003734,43.117147],[-73.001787,43.210826],[-73.041069,43.211973]],[[-73.066256,43.14036],[-73.082327,43.159024],[-73.054834,43.16802],[-73.066256,43.14036]]]},"properties":{"id":"05255","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.180875,44.368676],[-72.182781,44.288593],[-72.144837,44.270186],[-72.064639,44.257155],[-72.057338,44.287278],[-72.004663,44.323878],[-72.097081,44.357907],[-72.15289,44.374681],[-72.180875,44.368676]]]},"properties":{"id":"05821","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.065884,44.623599],[-72.202901,44.624073],[-72.218115,44.602258],[-72.137758,44.518619],[-72.083851,44.510398],[-71.957584,44.489985],[-71.912329,44.517152],[-71.979416,44.586919],[-71.995493,44.605392],[-72.065884,44.623599]],[[-72.018928,44.538348],[-72.008152,44.542436],[-72.009776,44.534503],[-72.018928,44.538348]]]},"properties":{"id":"05851","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.979416,44.586919],[-71.912329,44.517152],[-71.884925,44.524489],[-71.842732,44.611165],[-71.910282,44.647038],[-71.979416,44.586919]]]},"properties":{"id":"05832","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.782994,44.444047],[-71.884925,44.524489],[-71.912329,44.517152],[-71.957584,44.489985],[-71.837656,44.347801],[-71.738375,44.407958],[-71.782994,44.444047]]]},"properties":{"id":"05824","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.057338,44.287278],[-72.064639,44.257155],[-72.059042,44.255718],[-72.057338,44.287278]]]},"properties":{"id":"05050","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.165262,44.362091],[-73.280465,44.348648],[-73.270542,44.260121],[-73.145082,44.273403],[-73.165262,44.362091]]]},"properties":{"id":"05445","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.50536,45.013299],[-71.698115,45.011403],[-71.70104,45.008213],[-71.663453,44.879162],[-71.495857,44.909138],[-71.530403,44.999364],[-71.50536,45.013299]]]},"properties":{"id":"05903","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.015651,45.015097],[-73.151931,44.810573],[-73.052625,44.933298],[-73.015651,45.015097]]]},"properties":{"id":"05488","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.655669,44.907221],[-72.535709,44.925777],[-72.55436,45.008271],[-72.72773,45.015678],[-72.708997,44.934024],[-72.692463,44.928195],[-72.655669,44.907221]]]},"properties":{"id":"05476","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.199667,44.730263],[-73.121739,44.700201],[-73.07499,44.771446],[-72.99322,44.833013],[-73.049303,44.911638],[-73.052625,44.933298],[-73.151931,44.810573],[-73.199667,44.730263]]]},"properties":{"id":"05478","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.650529,44.603608],[-72.524735,44.573301],[-72.522388,44.658001],[-72.557868,44.671657],[-72.597332,44.678964],[-72.650529,44.603608]]]},"properties":{"id":"05655","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.704251,44.718356],[-72.744603,44.786457],[-72.786134,44.727723],[-72.771286,44.682971],[-72.704251,44.718356]]]},"properties":{"id":"05492","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.754731,44.011175],[-72.791336,43.961855],[-72.723047,43.888775],[-72.617718,43.88589],[-72.624815,43.997174],[-72.642513,44.07621],[-72.649139,44.076717],[-72.754731,44.011175]]]},"properties":{"id":"05060","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.087328,44.169132],[-72.116714,44.176928],[-72.041995,44.14291],[-72.043061,44.156596],[-72.087328,44.169132]]]},"properties":{"id":"05081","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.614961,44.083997],[-72.538126,44.062387],[-72.475571,44.041269],[-72.462555,44.121429],[-72.507036,44.147945],[-72.583809,44.159587],[-72.589333,44.160282],[-72.614961,44.083997]]]},"properties":{"id":"05679","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.394865,43.929106],[-72.292588,43.904119],[-72.284487,43.902244],[-72.278567,43.914167],[-72.265289,43.937232],[-72.241471,43.979465],[-72.382189,44.008176],[-72.394865,43.929106]]]},"properties":{"id":"05079","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.258675,44.077933],[-72.223432,44.09731],[-72.282972,44.184887],[-72.30498,44.183149],[-72.39858,44.131453],[-72.348089,44.096112],[-72.258675,44.077933]]]},"properties":{"id":"05086","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.113952,44.987433],[-72.076383,44.903866],[-72.078857,44.920492],[-71.975205,44.932557],[-72.113952,44.987433]]]},"properties":{"id":"05829","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.277715,44.863273],[-72.239229,44.849402],[-72.218024,44.867808],[-72.277715,44.863273]]]},"properties":{"id":"05825","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.243247,43.587451],[-73.115427,43.584749],[-73.096561,43.635471],[-73.118141,43.709194],[-73.140419,43.725698],[-73.184685,43.707295],[-73.243247,43.587451]],[[-73.205721,43.649904],[-73.175088,43.64602],[-73.190765,43.623291],[-73.231122,43.611478],[-73.205721,43.649904]]]},"properties":{"id":"05735","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.870026,43.480818],[-72.840331,43.40866],[-72.791819,43.36687],[-72.759799,43.354144],[-72.727184,43.468717],[-72.771543,43.477862],[-72.870026,43.480818]]]},"properties":{"id":"05758","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.870026,43.480818],[-72.771543,43.477862],[-72.774982,43.579375],[-72.93382,43.574704],[-72.92847,43.484565],[-72.870026,43.480818]]]},"properties":{"id":"05738","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.503627,44.328404],[-72.460989,44.312467],[-72.470273,44.3298],[-72.503627,44.328404]]],[[[-72.410095,44.41875],[-72.47475,44.419444],[-72.490887,44.361097],[-72.467277,44.341789],[-72.412395,44.340704],[-72.410095,44.41875]]]]},"properties":{"id":"05650","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.490548,44.425693],[-72.534968,44.33989],[-72.5084,44.330151],[-72.490887,44.361097],[-72.47475,44.419444],[-72.490548,44.425693]]]},"properties":{"id":"05648","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.909019,44.072587],[-72.821553,44.052634],[-72.770121,44.126433],[-72.782261,44.129784],[-72.934285,44.163619],[-72.909019,44.072587]]]},"properties":{"id":"05674","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.47475,44.419444],[-72.410095,44.41875],[-72.352624,44.420601],[-72.321277,44.462572],[-72.442695,44.494174],[-72.490548,44.425693],[-72.47475,44.419444]]]},"properties":{"id":"05681","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.534968,44.33989],[-72.654398,44.398224],[-72.668307,44.366316],[-72.70194,44.296637],[-72.667605,44.216754],[-72.589333,44.160282],[-72.583809,44.159587],[-72.518571,44.241475],[-72.503627,44.328404],[-72.5084,44.330151],[-72.534968,44.33989]]]},"properties":{"id":"05602","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.821828,42.827011],[-72.816355,42.736749],[-72.721365,42.734101],[-72.771817,42.78671],[-72.802553,42.826467],[-72.821828,42.827011]]]},"properties":{"id":"05342","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.572909,43.688666],[-72.619709,43.729003],[-72.694415,43.694051],[-72.700945,43.682831],[-72.715015,43.658973],[-72.631806,43.610745],[-72.6158,43.584961],[-72.487453,43.561609],[-72.468265,43.566445],[-72.46644,43.631093],[-72.465886,43.658741],[-72.572909,43.688666]]]},"properties":{"id":"05091","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.399043,44.027012],[-73.374272,43.902103],[-73.258112,43.947228],[-73.263024,44.034756],[-73.399043,44.027012]]]},"properties":{"id":"05734","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.81633,43.937805],[-72.912193,43.965554],[-72.979716,43.922414],[-72.980158,43.882587],[-72.81633,43.937805]]]},"properties":{"id":"05748","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.994975,43.305202],[-73.041069,43.211973],[-73.001787,43.210826],[-72.978471,43.20959],[-72.976152,43.291641],[-72.994975,43.305202]]]},"properties":{"id":"05253","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.135877,44.475022],[-73.031962,44.468035],[-72.971119,44.550303],[-73.097327,44.570491],[-73.137101,44.500911],[-73.135877,44.475022]]]},"properties":{"id":"05452","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.874433,44.876382],[-71.765841,44.818999],[-71.663453,44.879162],[-71.766942,44.932953],[-71.70104,45.008213],[-71.698115,45.011403],[-71.897608,45.00835],[-71.921204,44.932148],[-71.874433,44.876382]]]},"properties":{"id":"05907","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.83998,44.812453],[-72.833765,44.734506],[-72.786134,44.727723],[-72.744603,44.786457],[-72.675841,44.794213],[-72.678374,44.837632],[-72.83998,44.812453]]]},"properties":{"id":"05441","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.708997,44.934024],[-72.703151,44.927633],[-72.692463,44.928195],[-72.708997,44.934024]]]},"properties":{"id":"05447","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.704251,44.718356],[-72.771286,44.682971],[-72.747468,44.594847],[-72.650529,44.603608],[-72.597332,44.678964],[-72.663025,44.703195],[-72.704251,44.718356]]]},"properties":{"id":"05656","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.5682,43.981948],[-72.509922,43.966072],[-72.538126,44.062387],[-72.614961,44.083997],[-72.642513,44.07621],[-72.624815,43.997174],[-72.5682,43.981948]]]},"properties":{"id":"05036","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.460132,43.838291],[-72.394865,43.929106],[-72.509922,43.966072],[-72.549897,43.91077],[-72.546989,43.869362],[-72.460132,43.838291]]]},"properties":{"id":"05077","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.337451,43.809705],[-72.258696,43.786859],[-72.224938,43.818095],[-72.256717,43.883313],[-72.284487,43.902244],[-72.292588,43.904119],[-72.324807,43.861648],[-72.337451,43.809705]]]},"properties":{"id":"05075","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.224938,43.818095],[-72.258696,43.786859],[-72.205195,43.770955],[-72.167541,43.870998],[-72.224938,43.818095]]]},"properties":{"id":"05043","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.204442,44.039138],[-72.258675,44.077933],[-72.348089,44.096112],[-72.382189,44.008176],[-72.241471,43.979465],[-72.233014,43.992127],[-72.204442,44.039138]]]},"properties":{"id":"05039","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.278567,43.914167],[-72.260061,43.909147],[-72.265289,43.937232],[-72.278567,43.914167]]]},"properties":{"id":"05083","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.770121,44.126433],[-72.649139,44.076717],[-72.642513,44.07621],[-72.614961,44.083997],[-72.589333,44.160282],[-72.667605,44.216754],[-72.73341,44.210761],[-72.782261,44.129784],[-72.770121,44.126433]],[[-72.654078,44.16724],[-72.652957,44.168728],[-72.651629,44.167767],[-72.65308,44.165762],[-72.654078,44.16724]]]},"properties":{"id":"05663","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.241339,44.770379],[-72.193649,44.721173],[-72.146599,44.703495],[-72.088864,44.683714],[-72.04952,44.71764],[-72.226364,44.791923],[-72.241339,44.770379]]]},"properties":{"id":"05822","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.002454,43.571665],[-73.032081,43.610794],[-73.05526,43.664857],[-73.096561,43.635471],[-73.115427,43.584749],[-73.120181,43.535835],[-73.077388,43.483027],[-72.981341,43.507819],[-73.002454,43.571665]]]},"properties":{"id":"05777","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.993584,43.813189],[-73.053399,43.720158],[-73.022629,43.667492],[-73.003142,43.666941],[-72.955738,43.691261],[-72.993584,43.813189]]]},"properties":{"id":"05763","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.820043,43.716834],[-72.782812,43.804338],[-72.873366,43.839633],[-72.837188,43.719878],[-72.820043,43.716834]]]},"properties":{"id":"05762","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.739512,44.43293],[-72.668307,44.366316],[-72.654398,44.398224],[-72.739512,44.43293]]]},"properties":{"id":"05677","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.871312,44.267695],[-72.73341,44.210761],[-72.667605,44.216754],[-72.70194,44.296637],[-72.871312,44.267695]]]},"properties":{"id":"05660","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.469779,44.15103],[-72.473617,44.164322],[-72.485922,44.152392],[-72.469779,44.15103]]]},"properties":{"id":"05678","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.515576,43.127174],[-72.437755,43.124897],[-72.434971,43.233306],[-72.549912,43.235809],[-72.557326,43.196195],[-72.557664,43.162116],[-72.553859,43.14723],[-72.515576,43.127174]]]},"properties":{"id":"05101","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.921376,42.926792],[-72.798595,42.922173],[-72.797349,42.922145],[-72.853417,42.989426],[-72.87956,42.990313],[-72.921376,42.926792]]]},"properties":{"id":"05356","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.557664,43.162116],[-72.558217,43.147269],[-72.553859,43.14723],[-72.557664,43.162116]]]},"properties":{"id":"05141","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.641106,42.947214],[-72.673106,42.945761],[-72.673663,42.92233],[-72.641106,42.947214]]]},"properties":{"id":"05362","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.530174,43.444085],[-72.455084,43.435505],[-72.514295,43.477994],[-72.530174,43.444085]]]},"properties":{"id":"05037","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.572909,43.688666],[-72.465886,43.658741],[-72.462704,43.704718],[-72.531779,43.714507],[-72.551804,43.725327],[-72.572909,43.688666]]]},"properties":{"id":"05067","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.431351,43.71388],[-72.426372,43.721826],[-72.477709,43.742098],[-72.431351,43.71388]]]},"properties":{"id":"05084","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.618738,43.452963],[-72.654126,43.350383],[-72.538771,43.380975],[-72.530174,43.444085],[-72.618738,43.452963]]]},"properties":{"id":"05142","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.952167,44.161271],[-73.075913,44.283015],[-73.124445,44.258067],[-73.163131,44.208052],[-73.096003,44.054441],[-73.064657,44.040797],[-72.908083,44.035474],[-72.909019,44.072587],[-72.934285,44.163619],[-72.952167,44.161271]]]},"properties":{"id":"05443","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.220833,43.029012],[-73.140484,43.025853],[-73.080579,43.119233],[-73.128019,43.213817],[-73.259159,43.216848],[-73.270041,43.030716],[-73.220833,43.029012]]]},"properties":{"id":"05250","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.926024,42.829915],[-72.821828,42.827011],[-72.802553,42.826467],[-72.798595,42.922173],[-72.921376,42.926792],[-73.008063,42.930087],[-73.01195,42.859108],[-72.926024,42.829915]]]},"properties":{"id":"05363","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.862393,43.300577],[-72.849875,43.227849],[-72.793613,43.146974],[-72.774476,43.14181],[-72.73562,43.21955],[-72.755812,43.239805],[-72.862393,43.300577]]]},"properties":{"id":"05148","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.15289,44.374681],[-72.151982,44.501151],[-72.18067,44.511763],[-72.22289,44.462212],[-72.268733,44.442354],[-72.255662,44.359582],[-72.180875,44.368676],[-72.15289,44.374681]]]},"properties":{"id":"05873","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.256033,44.631388],[-73.232517,44.61398],[-73.097327,44.570491],[-73.038028,44.651157],[-73.121739,44.700201],[-73.199667,44.730263],[-73.255805,44.63176],[-73.256033,44.631388]]]},"properties":{"id":"05468","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.884925,44.524489],[-71.782994,44.444047],[-71.707095,44.538842],[-71.704198,44.62355],[-71.842732,44.611165],[-71.884925,44.524489]]]},"properties":{"id":"05858","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.99322,44.833013],[-73.07499,44.771446],[-72.973514,44.755427],[-72.909289,44.8085],[-72.914116,44.849591],[-72.99322,44.833013]]]},"properties":{"id":"05455","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.003333,44.919895],[-73.049303,44.911638],[-72.99322,44.833013],[-72.914116,44.849591],[-72.941301,44.901928],[-72.973177,44.909937],[-72.976009,44.906761],[-72.968549,44.898396],[-72.970842,44.89615],[-72.975737,44.901183],[-72.97835,44.906945],[-73.003333,44.919895]]]},"properties":{"id":"05483","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.904334,44.700099],[-72.858425,44.579742],[-72.809349,44.547921],[-72.768196,44.56298],[-72.747468,44.594847],[-72.771286,44.682971],[-72.786134,44.727723],[-72.833765,44.734506],[-72.904334,44.700099]]]},"properties":{"id":"05464","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.993433,45.014876],[-73.003333,44.919895],[-72.97835,44.906945],[-72.973177,44.909937],[-72.941301,44.901928],[-72.832619,45.016421],[-72.993433,45.014876]]]},"properties":{"id":"05457","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.274429,44.829268],[-73.231562,44.90112],[-73.267303,44.896154],[-73.274429,44.829268]]]},"properties":{"id":"05474","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.366022,44.848907],[-73.328126,44.850776],[-73.324507,44.904582],[-73.34414,44.906615],[-73.366022,44.848907]]]},"properties":{"id":"05463","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.292588,43.904119],[-72.394865,43.929106],[-72.460132,43.838291],[-72.435626,43.832037],[-72.324807,43.861648],[-72.292588,43.904119]]]},"properties":{"id":"05072","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.485922,44.152392],[-72.473617,44.164322],[-72.469779,44.15103],[-72.444433,44.129202],[-72.410421,44.13366],[-72.341923,44.195338],[-72.368353,44.204057],[-72.480371,44.23383],[-72.518571,44.241475],[-72.583809,44.159587],[-72.507036,44.147945],[-72.485922,44.152392]]]},"properties":{"id":"05641","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.485922,44.152392],[-72.507036,44.147945],[-72.462555,44.121429],[-72.444433,44.129202],[-72.469779,44.15103],[-72.485922,44.152392]]]},"properties":{"id":"05654","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.226364,44.791923],[-72.04952,44.71764],[-72.007182,44.703113],[-71.939936,44.769032],[-71.974318,44.786519],[-72.034867,44.81825],[-72.120107,44.864724],[-72.218024,44.867808],[-72.239229,44.849402],[-72.226364,44.791923]]]},"properties":{"id":"05860","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.076383,44.903866],[-72.113952,44.987433],[-72.141935,45.005936],[-72.24674,45.004642],[-72.308968,44.875157],[-72.277715,44.863273],[-72.218024,44.867808],[-72.120107,44.864724],[-72.076383,44.903866]]]},"properties":{"id":"05855","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.392055,44.703131],[-72.315377,44.650692],[-72.297253,44.65781],[-72.30976,44.67299],[-72.338435,44.703405],[-72.36785,44.715726],[-72.392055,44.703131]]]},"properties":{"id":"05827","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.30976,44.67299],[-72.297253,44.65781],[-72.278298,44.651403],[-72.193649,44.721173],[-72.241339,44.770379],[-72.25729,44.746247],[-72.30976,44.67299]]]},"properties":{"id":"05875","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.410829,44.905804],[-72.500934,44.926202],[-72.532648,44.833512],[-72.402766,44.841408],[-72.410829,44.905804]]]},"properties":{"id":"05874","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.193649,44.721173],[-72.278298,44.651403],[-72.202901,44.624073],[-72.146599,44.703495],[-72.193649,44.721173]]]},"properties":{"id":"05839","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.374786,44.584125],[-72.322581,44.565036],[-72.28197,44.550055],[-72.218115,44.602258],[-72.202901,44.624073],[-72.278298,44.651403],[-72.297253,44.65781],[-72.315377,44.650692],[-72.368151,44.592287],[-72.374786,44.584125]]]},"properties":{"id":"05841","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.241657,43.313915],[-73.122704,43.308682],[-73.053956,43.400738],[-73.117999,43.411542],[-73.217994,43.393485],[-73.241657,43.313915]]]},"properties":{"id":"05761","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.245637,43.539549],[-73.249694,43.438101],[-73.17967,43.465943],[-73.120181,43.535835],[-73.115427,43.584749],[-73.243247,43.587451],[-73.245637,43.539549]]]},"properties":{"id":"05764","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.122704,43.308682],[-72.994975,43.305202],[-72.976152,43.291641],[-72.882891,43.301316],[-73.053956,43.400738],[-73.122704,43.308682]]]},"properties":{"id":"05739","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.17967,43.465943],[-73.249694,43.438101],[-73.250847,43.408703],[-73.217994,43.393485],[-73.117999,43.411542],[-73.113909,43.431159],[-73.17967,43.465943]]]},"properties":{"id":"05774","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.873366,43.839633],[-72.782812,43.804338],[-72.765979,43.823189],[-72.723047,43.888775],[-72.791336,43.961855],[-72.81633,43.937805],[-72.980158,43.882587],[-72.958853,43.826542],[-72.873366,43.839633]]]},"properties":{"id":"05767","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.934285,44.163619],[-72.782261,44.129784],[-72.73341,44.210761],[-72.871312,44.267695],[-72.89598,44.276284],[-72.92207,44.222232],[-72.952167,44.161271],[-72.934285,44.163619]]]},"properties":{"id":"05673","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.352624,44.420601],[-72.255662,44.359582],[-72.268733,44.442354],[-72.321277,44.462572],[-72.352624,44.420601]]]},"properties":{"id":"05647","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.545447,43.345247],[-72.546454,43.317589],[-72.502005,43.342868],[-72.545447,43.345247]]]},"properties":{"id":"05150","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.426372,43.721826],[-72.431351,43.71388],[-72.462704,43.704718],[-72.465886,43.658741],[-72.46644,43.631093],[-72.454934,43.629143],[-72.389449,43.6531],[-72.398444,43.618923],[-72.328208,43.606302],[-72.305099,43.692414],[-72.404399,43.74507],[-72.426372,43.721826]]]},"properties":{"id":"05001","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.17929,43.908372],[-73.058211,43.962829],[-73.059174,43.970614],[-73.064657,44.040797],[-73.096003,44.054441],[-73.232682,44.092503],[-73.263024,44.034756],[-73.258112,43.947228],[-73.247873,43.920459],[-73.17929,43.908372]],[[-73.103206,43.970061],[-73.108209,43.972152],[-73.102978,43.972889],[-73.103206,43.970061]]]},"properties":{"id":"05753","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.882891,43.301316],[-72.976152,43.291641],[-72.978471,43.20959],[-72.861994,43.205095],[-72.849875,43.227849],[-72.862393,43.300577],[-72.867902,43.300742],[-72.882891,43.301316]]]},"properties":{"id":"05152","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.254633,43.314675],[-73.259159,43.216848],[-73.128019,43.213817],[-73.122704,43.308682],[-73.241657,43.313915],[-73.254633,43.314675]]]},"properties":{"id":"05776","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.22197,42.933019],[-73.008063,42.930087],[-73.002789,43.023007],[-73.140484,43.025853],[-73.220833,43.029012],[-73.22197,42.933019]]]},"properties":{"id":"05262","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.255662,44.359582],[-72.308938,44.305594],[-72.317621,44.298384],[-72.182781,44.288593],[-72.180875,44.368676],[-72.255662,44.359582]]]},"properties":{"id":"05862","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.28197,44.550055],[-72.18067,44.511763],[-72.151982,44.501151],[-72.137758,44.518619],[-72.218115,44.602258],[-72.28197,44.550055]]]},"properties":{"id":"05842","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.007182,44.703113],[-71.995493,44.605392],[-71.979416,44.586919],[-71.910282,44.647038],[-71.882797,44.740071],[-71.939936,44.769032],[-72.007182,44.703113]]]},"properties":{"id":"05871","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.442695,44.494174],[-72.321277,44.462572],[-72.268733,44.442354],[-72.22289,44.462212],[-72.322581,44.565036],[-72.374786,44.584125],[-72.442695,44.494174]]]},"properties":{"id":"05843","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.182781,44.288593],[-72.317621,44.298384],[-72.368353,44.204057],[-72.341923,44.195338],[-72.30498,44.183149],[-72.282972,44.184887],[-72.17026,44.192053],[-72.153455,44.215137],[-72.144837,44.270186],[-72.182781,44.288593]]]},"properties":{"id":"05046","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.668307,44.366316],[-72.739512,44.43293],[-72.804995,44.451676],[-72.915008,44.383121],[-72.931586,44.388311],[-72.953599,44.345781],[-72.89598,44.276284],[-72.871312,44.267695],[-72.70194,44.296637],[-72.668307,44.366316]]]},"properties":{"id":"05676","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.214232,44.509261],[-73.24191,44.492198],[-73.231455,44.447178],[-73.191882,44.466013],[-73.196727,44.469849],[-73.190251,44.472612],[-73.176383,44.484801],[-73.201992,44.492504],[-73.214232,44.509261]]]},"properties":{"id":"05401","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.89598,44.276284],[-72.953599,44.345781],[-73.032655,44.365071],[-73.020378,44.303744],[-72.92207,44.222232],[-72.89598,44.276284]]]},"properties":{"id":"05462","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.70104,45.008213],[-71.766942,44.932953],[-71.663453,44.879162],[-71.70104,45.008213]]]},"properties":{"id":"05901","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.765841,44.818999],[-71.858382,44.72766],[-71.704198,44.62355],[-71.707095,44.538842],[-71.605406,44.484524],[-71.495857,44.909138],[-71.663453,44.879162],[-71.765841,44.818999]]]},"properties":{"id":"05905","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.782994,44.444047],[-71.738375,44.407958],[-71.723131,44.410901],[-71.683469,44.425377],[-71.605406,44.484524],[-71.707095,44.538842],[-71.782994,44.444047]]]},"properties":{"id":"05906","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.973514,44.755427],[-73.07499,44.771446],[-73.121739,44.700201],[-73.038028,44.651157],[-72.966616,44.639903],[-72.939817,44.673041],[-72.973514,44.755427]]]},"properties":{"id":"05454","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.255805,44.63176],[-73.295109,44.681926],[-73.349457,44.68812],[-73.256033,44.631388],[-73.255805,44.63176]]]},"properties":{"id":"05486","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.349457,44.68812],[-73.295109,44.681926],[-73.27262,44.755638],[-73.33026,44.741593],[-73.349457,44.68812]]]},"properties":{"id":"05458","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.167977,44.054683],[-72.078571,44.029626],[-72.041995,44.14291],[-72.116714,44.176928],[-72.187323,44.133353],[-72.197961,44.099393],[-72.167977,44.054683]]]},"properties":{"id":"05051","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.260061,43.909147],[-72.278567,43.914167],[-72.284487,43.902244],[-72.256717,43.883313],[-72.260061,43.909147]]]},"properties":{"id":"05058","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.324807,43.861648],[-72.435626,43.832037],[-72.337451,43.809705],[-72.324807,43.861648]]]},"properties":{"id":"05070","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.920161,44.836942],[-71.874433,44.876382],[-71.921204,44.932148],[-71.975205,44.932557],[-72.078857,44.920492],[-71.980972,44.86836],[-71.920161,44.836942]]]},"properties":{"id":"05853","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.840331,43.40866],[-72.870026,43.480818],[-72.92847,43.484565],[-72.92962,43.48457],[-72.876357,43.39346],[-72.840331,43.40866]]]},"properties":{"id":"05742","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.17967,43.465943],[-73.113909,43.431159],[-73.077388,43.483027],[-73.120181,43.535835],[-73.17967,43.465943]]]},"properties":{"id":"05757","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.791819,43.36687],[-72.840331,43.40866],[-72.876357,43.39346],[-72.853439,43.371076],[-72.791819,43.36687]]]},"properties":{"id":"05730","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.490887,44.361097],[-72.5084,44.330151],[-72.503627,44.328404],[-72.470273,44.3298],[-72.467277,44.341789],[-72.490887,44.361097]]]},"properties":{"id":"05640","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.605429,43.072133],[-72.742738,43.023312],[-72.754795,42.98724],[-72.774838,42.924046],[-72.673663,42.92233],[-72.673106,42.945761],[-72.641106,42.947214],[-72.608453,42.968221],[-72.575148,43.071099],[-72.605429,43.072133]]]},"properties":{"id":"05345","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.515576,43.127174],[-72.461625,43.05909],[-72.437755,43.124897],[-72.515576,43.127174]]]},"properties":{"id":"05158","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.440926,43.409076],[-72.502005,43.342868],[-72.546454,43.317589],[-72.549912,43.235809],[-72.434971,43.233306],[-72.41128,43.385911],[-72.424007,43.401828],[-72.440926,43.409076]]]},"properties":{"id":"05156","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.862393,43.300577],[-72.755812,43.239805],[-72.763433,43.290755],[-72.759799,43.354144],[-72.791819,43.36687],[-72.853439,43.371076],[-72.867902,43.300742],[-72.862393,43.300577]]]},"properties":{"id":"05161","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.487453,43.561609],[-72.6158,43.584961],[-72.634371,43.557945],[-72.532858,43.534086],[-72.487453,43.561609]]]},"properties":{"id":"05071","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.468265,43.566445],[-72.396095,43.519894],[-72.36409,43.583565],[-72.398854,43.619004],[-72.454934,43.629143],[-72.46644,43.631093],[-72.468265,43.566445]]]},"properties":{"id":"05048","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.398854,43.619004],[-72.36409,43.583565],[-72.328208,43.606302],[-72.398444,43.618923],[-72.398854,43.619004]]]},"properties":{"id":"05052","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.460132,43.838291],[-72.491322,43.74596],[-72.477709,43.742098],[-72.426372,43.721826],[-72.404399,43.74507],[-72.337451,43.809705],[-72.435626,43.832037],[-72.460132,43.838291]]]},"properties":{"id":"05065","state_code":"50"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.807599,37.622336],[-75.754468,37.617881],[-75.804164,37.636281],[-75.807599,37.622336]]]},"properties":{"id":"23401","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.697025,37.718415],[-75.697543,37.699738],[-75.648897,37.65331],[-75.614622,37.581589],[-75.611825,37.580621],[-75.585966,37.67549],[-75.64502,37.750036],[-75.697025,37.718415]]]},"properties":{"id":"23301","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.715496,37.794464],[-75.700485,37.77933],[-75.64502,37.750036],[-75.585966,37.67549],[-75.537212,37.763906],[-75.567701,37.79604],[-75.594283,37.807978],[-75.715496,37.794464]]]},"properties":{"id":"23421","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.703111,37.895767],[-75.600194,37.851344],[-75.596747,37.858458],[-75.646562,37.896451],[-75.700526,37.896245],[-75.703111,37.895767]]]},"properties":{"id":"23409","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.517965,37.881166],[-75.532485,37.875016],[-75.530922,37.853234],[-75.51483,37.837801],[-75.493309,37.877792],[-75.517965,37.881166]]]},"properties":{"id":"23302","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.608586,37.927432],[-75.6477,37.946963],[-75.648214,37.946862],[-75.643412,37.916687],[-75.532485,37.875016],[-75.517965,37.881166],[-75.54947,37.922179],[-75.608586,37.927432]]]},"properties":{"id":"23442","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.594283,37.807978],[-75.567701,37.79604],[-75.587916,37.819709],[-75.594283,37.807978]]]},"properties":{"id":"23414","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.316955,38.268702],[-78.407173,38.18934],[-78.364341,38.128146],[-78.29543,38.121969],[-78.272749,38.206797],[-78.25054,38.233768],[-78.316955,38.268702]]]},"properties":{"id":"22923","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.813807,37.83099],[-78.748592,37.797611],[-78.697786,37.859533],[-78.751136,37.89818],[-78.845531,37.91216],[-78.913139,37.869382],[-78.901255,37.842104],[-78.813807,37.83099]]]},"properties":{"id":"22938","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.663174,38.277923],[-78.61242,38.122525],[-78.54306,38.151216],[-78.554517,38.241182],[-78.622763,38.301904],[-78.641931,38.291435],[-78.663174,38.277923]]]},"properties":{"id":"22940","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.885666,37.792127],[-79.857554,37.79425],[-79.852933,37.80693],[-79.885666,37.792127]]]},"properties":{"id":"24457","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.217181,37.397238],[-78.361145,37.235276],[-78.301282,37.22241],[-78.231234,37.29622],[-78.188474,37.349265],[-78.207182,37.406499],[-78.217181,37.397238]]]},"properties":{"id":"23966","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.159102,38.88321],[-77.153929,38.878963],[-77.143637,38.870877],[-77.120672,38.882843],[-77.11869,38.885362],[-77.157228,38.892804],[-77.159102,38.88321]]]},"properties":{"id":"22205","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.165942,38.898224],[-77.159102,38.88321],[-77.157228,38.892804],[-77.156343,38.905677],[-77.159681,38.903147],[-77.165942,38.898224]]]},"properties":{"id":"22213","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.020802,38.304904],[-78.88088,38.240966],[-78.854182,38.27838],[-78.983243,38.330624],[-79.020802,38.304904]]]},"properties":{"id":"24486","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.983243,38.330624],[-78.942994,38.377353],[-78.948221,38.397372],[-79.132871,38.426908],[-79.033305,38.292322],[-79.020802,38.304904],[-78.983243,38.330624]]]},"properties":{"id":"22812","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.662527,37.308837],[-79.657559,37.421192],[-79.725062,37.341872],[-79.72411,37.314885],[-79.707955,37.277548],[-79.662527,37.308837]]]},"properties":{"id":"24174","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.74858,37.43214],[-79.725062,37.341872],[-79.657559,37.421192],[-79.634201,37.45705],[-79.74858,37.43214]]]},"properties":{"id":"24122","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.70488,37.263188],[-79.704645,37.151523],[-79.715333,37.109363],[-79.654818,37.042284],[-79.614298,37.034154],[-79.594059,37.042238],[-79.51818,37.168405],[-79.662527,37.308837],[-79.707955,37.277548],[-79.70488,37.263188]]]},"properties":{"id":"24121","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.097041,37.235981],[-81.300422,37.05923],[-81.2279,37.045941],[-81.107104,37.025101],[-80.854627,37.147482],[-80.904154,37.256645],[-81.099234,37.240215],[-81.097041,37.235981]]]},"properties":{"id":"24315","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.964833,36.617199],[-78.106162,36.779685],[-78.149421,36.651897],[-78.01498,36.59337],[-77.977288,36.610538],[-77.964833,36.617199]]]},"properties":{"id":"23950","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.06084,37.236567],[-82.043805,37.185852],[-81.990627,37.128676],[-81.982971,37.122486],[-81.945783,37.133818],[-81.929129,37.256023],[-81.945694,37.279355],[-82.06084,37.236567]]]},"properties":{"id":"24631","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.135895,37.101105],[-82.173785,37.120588],[-82.132191,37.09428],[-82.135895,37.101105]]]},"properties":{"id":"24239","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.639702,37.497839],[-78.591789,37.642042],[-78.590241,37.67161],[-78.692163,37.686015],[-78.719279,37.635386],[-78.683412,37.537131],[-78.639702,37.497839]]]},"properties":{"id":"23921","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.506031,38.14611],[-77.560328,38.065347],[-77.387239,38.034622],[-77.339616,38.20678],[-77.506031,38.14611]]]},"properties":{"id":"22580","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.447126,38.284614],[-77.511659,38.279985],[-77.506043,38.166615],[-77.506031,38.14611],[-77.339616,38.20678],[-77.30028,38.22025],[-77.294402,38.227029],[-77.326692,38.245136],[-77.447126,38.284614]]]},"properties":{"id":"22408","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.774345,36.8691],[-80.969116,36.869064],[-80.983746,36.863112],[-80.917969,36.801499],[-80.841012,36.778861],[-80.774345,36.8691]]]},"properties":{"id":"24312","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.028819,36.671091],[-80.962667,36.750719],[-81.089523,36.765117],[-81.092945,36.700113],[-81.028819,36.671091]]]},"properties":{"id":"24330","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.537867,37.214993],[-78.690023,37.188286],[-78.551,37.176612],[-78.537867,37.214993]]]},"properties":{"id":"23934","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.613919,37.025077],[-78.527241,37.175354],[-78.551,37.176612],[-78.690023,37.188286],[-78.73337,37.17211],[-78.737082,37.057846],[-78.669177,37.006569],[-78.613919,37.025077]]]},"properties":{"id":"23923","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.516377,37.460519],[-77.502177,37.409963],[-77.427682,37.436569],[-77.421672,37.498989],[-77.516377,37.460519]]]},"properties":{"id":"23234","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.957891,39.046348],[-77.989483,38.997106],[-77.948984,38.984309],[-77.890193,39.06095],[-77.957891,39.046348]]]},"properties":{"id":"20130","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.339795,38.874704],[-78.170932,38.823837],[-78.151423,38.848482],[-78.131175,38.861697],[-78.105838,38.837869],[-78.079677,38.849005],[-78.004167,38.979479],[-78.083488,39.014981],[-78.167205,39.017703],[-78.213488,38.997897],[-78.233411,38.964031],[-78.303423,38.94609],[-78.339795,38.874704]]]},"properties":{"id":"22630","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.920731,38.669631],[-77.906185,38.695827],[-77.985485,38.782106],[-78.02814,38.759658],[-78.079222,38.721993],[-78.091637,38.692667],[-78.047467,38.624093],[-77.939514,38.636622],[-77.920731,38.669631]]]},"properties":{"id":"20106","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.29079,36.91767],[-77.268296,36.977472],[-77.479341,37.007321],[-77.619239,36.948311],[-77.600962,36.887117],[-77.354138,36.834849],[-77.283853,36.907296],[-77.279738,36.913412],[-77.29079,36.91767]]]},"properties":{"id":"23882","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.735773,37.744032],[-76.668182,37.778386],[-76.694832,37.80285],[-76.697517,37.804049],[-76.735773,37.744032]]]},"properties":{"id":"22504","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.113448,38.122132],[-77.072546,38.088769],[-77.057368,38.076904],[-77.042959,38.106969],[-77.061695,38.161631],[-77.115997,38.149931],[-77.113448,38.122132]]]},"properties":{"id":"22509","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.135202,37.866101],[-77.075533,37.76606],[-77.017698,37.773369],[-76.969851,37.82867],[-77.097441,37.907299],[-77.135202,37.866101]]]},"properties":{"id":"23148","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.374265,38.953287],[-77.311952,38.947944],[-77.318069,38.975192],[-77.365334,38.966224],[-77.374265,38.953287]]]},"properties":{"id":"20190","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.217394,38.910169],[-77.264362,38.951351],[-77.315141,38.977778],[-77.318069,38.975192],[-77.311952,38.947944],[-77.310808,38.935455],[-77.273821,38.910262],[-77.228351,38.893238],[-77.216046,38.90346],[-77.217394,38.910169]]]},"properties":{"id":"22182","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.226167,38.859604],[-77.174704,38.846474],[-77.159099,38.87475],[-77.198938,38.876031],[-77.222581,38.877356],[-77.227069,38.876798],[-77.226167,38.859604]]]},"properties":{"id":"22042","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.273821,38.910262],[-77.310808,38.935455],[-77.317183,38.921858],[-77.283295,38.875],[-77.267269,38.878592],[-77.273821,38.910262]]]},"properties":{"id":"22181","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.360657,38.857349],[-77.357549,38.852042],[-77.350648,38.855935],[-77.360657,38.857349]]]},"properties":{"id":"22035","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.868958,38.937698],[-77.96721,38.833173],[-77.985485,38.782106],[-77.906185,38.695827],[-77.827382,38.826981],[-77.810464,38.952629],[-77.820461,38.98087],[-77.868958,38.937698]]]},"properties":{"id":"20115","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.812672,38.76322],[-77.802897,38.638462],[-77.773138,38.641536],[-77.717519,38.657844],[-77.680325,38.652919],[-77.69564,38.724779],[-77.656583,38.746147],[-77.692049,38.796722],[-77.770865,38.792721],[-77.812672,38.76322]]]},"properties":{"id":"20187","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.69564,38.724779],[-77.559458,38.597343],[-77.454367,38.631775],[-77.491154,38.687157],[-77.600636,38.759523],[-77.656583,38.746147],[-77.69564,38.724779]]]},"properties":{"id":"20181","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.004167,38.979479],[-78.079677,38.849005],[-77.997146,38.848036],[-77.997846,38.907381],[-77.949717,38.981982],[-77.948984,38.984309],[-77.989483,38.997106],[-78.004167,38.979479]]]},"properties":{"id":"22642","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.354098,37.075578],[-80.517417,37.0367],[-80.505454,36.994349],[-80.376805,37.005517],[-80.354098,37.075578]]]},"properties":{"id":"24149","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.517865,36.973955],[-80.505454,36.994349],[-80.517417,37.0367],[-80.526016,37.191585],[-80.575116,37.18389],[-80.63598,37.164765],[-80.668099,37.051464],[-80.666153,37.02888],[-80.56511,36.965138],[-80.517865,36.973955]],[[-80.549451,37.139824],[-80.547123,37.138537],[-80.551131,37.134011],[-80.553166,37.139699],[-80.549451,37.139824]]]},"properties":{"id":"24141","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.354098,37.075578],[-80.376805,37.005517],[-80.284465,36.995675],[-80.264485,37.084919],[-80.327182,37.091591],[-80.354098,37.075578]]]},"properties":{"id":"24138","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.706917,36.920399],[-79.744158,36.860355],[-79.717862,36.818754],[-79.674075,36.829238],[-79.632829,36.852698],[-79.594267,36.934121],[-79.53298,37.039393],[-79.594059,37.042238],[-79.614298,37.034154],[-79.699789,36.965299],[-79.706917,36.920399]]]},"properties":{"id":"24137","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.128119,37.122912],[-80.142821,36.980637],[-80.139539,36.970786],[-79.981156,36.965611],[-80.026286,37.030357],[-80.08049,37.133845],[-80.128119,37.122912]]]},"properties":{"id":"24067","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.139539,36.970786],[-80.235455,36.87213],[-80.196724,36.814506],[-80.08364,36.807968],[-80.041493,36.794365],[-79.92347,36.878801],[-79.981156,36.965611],[-80.139539,36.970786]]]},"properties":{"id":"24088","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.522715,36.580615],[-81.203933,36.571929],[-81.300371,36.648542],[-81.525935,36.655883],[-81.522715,36.580615]]]},"properties":{"id":"24363","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.452918,38.475508],[-78.559814,38.331235],[-78.474119,38.216661],[-78.450921,38.209556],[-78.358845,38.290023],[-78.365806,38.317504],[-78.429241,38.367883],[-78.381407,38.422092],[-78.452918,38.475508]]]},"properties":{"id":"22973","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.216423,37.688885],[-77.247623,37.730744],[-77.402783,37.707812],[-77.451017,37.679981],[-77.430433,37.652586],[-77.407189,37.612524],[-77.406257,37.611851],[-77.216423,37.688885]]]},"properties":{"id":"23116","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.576589,37.624936],[-77.633783,37.558623],[-77.59611,37.55572],[-77.570641,37.557957],[-77.542233,37.570748],[-77.545752,37.576074],[-77.53203,37.582571],[-77.537864,37.612705],[-77.56924,37.632363],[-77.576589,37.624936]]]},"properties":{"id":"23229","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.537864,37.612705],[-77.521525,37.606391],[-77.517241,37.607111],[-77.530417,37.642213],[-77.56413,37.637826],[-77.56924,37.632363],[-77.537864,37.612705]]]},"properties":{"id":"23294","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.92661,36.696556],[-79.920138,36.75014],[-79.932707,36.73088],[-79.92661,36.696556]]]},"properties":{"id":"24078","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.937529,36.729247],[-79.936412,36.73934],[-79.951606,36.740127],[-79.950516,36.728156],[-79.94062,36.722355],[-79.937529,36.729247]]]},"properties":{"id":"24168","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.998636,36.754986],[-77.050714,36.675497],[-77.021914,36.544307],[-76.915897,36.552093],[-76.855654,36.674438],[-76.886467,36.777964],[-76.964624,36.781206],[-76.998636,36.754986]]]},"properties":{"id":"23851","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.94897,37.741611],[-76.952077,37.688116],[-76.916151,37.686477],[-76.921427,37.740434],[-76.94897,37.741611]]]},"properties":{"id":"23161","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.014598,38.359561],[-77.047132,38.352698],[-77.02961,38.31302],[-77.014598,38.359561]]]},"properties":{"id":"22448","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.473518,38.016202],[-76.520823,37.941668],[-76.547446,37.914561],[-76.507828,37.838814],[-76.367102,37.770132],[-76.327057,37.792617],[-76.311888,37.801917],[-76.310126,37.789728],[-76.306744,37.792803],[-76.297905,37.817904],[-76.330796,37.849917],[-76.363362,37.84673],[-76.365948,37.875771],[-76.333569,37.880911],[-76.255963,37.89026],[-76.300083,37.9268],[-76.473518,38.016202]]]},"properties":{"id":"22473","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.308288,36.597953],[-83.287038,36.714281],[-83.414829,36.670208],[-83.405614,36.597385],[-83.308288,36.597953]]]},"properties":{"id":"24281","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.541478,38.938],[-77.552925,38.886838],[-77.545954,38.849079],[-77.53576,38.847474],[-77.496855,38.887171],[-77.440383,38.944407],[-77.541478,38.938]]]},"properties":{"id":"20152","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.766685,39.043086],[-77.80021,39.188821],[-77.819648,39.153935],[-77.818691,39.027503],[-77.811999,39.02291],[-77.766685,39.043086]]]},"properties":{"id":"20141","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.422666,36.905566],[-78.28956,36.827792],[-78.27528,36.819929],[-78.301787,36.879386],[-78.31262,36.91016],[-78.407462,36.910651],[-78.422666,36.905566]]],[[[-78.229944,37.096868],[-78.285992,37.044606],[-78.317073,37.016553],[-78.27214,36.964955],[-78.260168,36.919755],[-78.094836,37.021621],[-78.122552,37.035865],[-78.197281,37.077927],[-78.229944,37.096868]]]]},"properties":{"id":"23974","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.231978,38.53255],[-78.300105,38.57054],[-78.323208,38.497865],[-78.284977,38.475922],[-78.261711,38.477168],[-78.231978,38.53255]]]},"properties":{"id":"22719","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.64506,37.613945],[-76.644289,37.684756],[-76.666675,37.69929],[-76.733303,37.68878],[-76.680392,37.617085],[-76.654076,37.603174],[-76.64506,37.613945]]]},"properties":{"id":"23032","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.406817,37.549572],[-76.452358,37.565464],[-76.503304,37.571808],[-76.508767,37.569782],[-76.514015,37.548613],[-76.449119,37.518037],[-76.419897,37.510297],[-76.393632,37.530841],[-76.406817,37.549572]]]},"properties":{"id":"23071","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.830121,37.663143],[-78.791825,37.667224],[-78.741126,37.725193],[-78.748592,37.797611],[-78.813807,37.83099],[-78.873044,37.722356],[-78.830121,37.663143]]]},"properties":{"id":"22971","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.18087,37.58812],[-77.228504,37.536398],[-77.177324,37.4906],[-77.141791,37.476285],[-77.085765,37.504106],[-77.18087,37.58812]]]},"properties":{"id":"23141","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.333569,37.880911],[-76.330796,37.849917],[-76.297905,37.817904],[-76.236663,37.889572],[-76.255963,37.89026],[-76.333569,37.880911]]]},"properties":{"id":"22539","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.399584,36.754867],[-79.568416,36.749812],[-79.614617,36.739648],[-79.597349,36.696133],[-79.593841,36.679317],[-79.399584,36.754867]]]},"properties":{"id":"24549","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.26093,36.765007],[-79.399193,36.754947],[-79.322858,36.682721],[-79.26093,36.765007]]]},"properties":{"id":"24527","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.396691,37.301589],[-78.398894,37.299381],[-78.394012,37.299752],[-78.396691,37.301589]]]},"properties":{"id":"23909","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.56875,38.786878],[-77.530283,38.738285],[-77.482204,38.784176],[-77.468868,38.796012],[-77.503335,38.824538],[-77.53576,38.847474],[-77.545954,38.849079],[-77.548888,38.82558],[-77.56875,38.786878]]]},"properties":{"id":"20109","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.245387,38.672129],[-77.293102,38.628172],[-77.307986,38.60654],[-77.314362,38.597439],[-77.250607,38.578432],[-77.225503,38.605617],[-77.245387,38.672129]]]},"properties":{"id":"22191","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.849683,36.992054],[-80.821721,36.923142],[-80.789371,36.932965],[-80.715647,36.992735],[-80.849683,36.992054]]]},"properties":{"id":"24324","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.981403,37.233183],[-79.951504,37.194371],[-79.911571,37.170254],[-79.847082,37.225468],[-79.904639,37.266275],[-79.938336,37.259025],[-79.95224,37.256785],[-79.981403,37.233183]]]},"properties":{"id":"24014","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.426182,37.678093],[-79.432487,37.616361],[-79.329734,37.688123],[-79.303545,37.740751],[-79.291274,37.759067],[-79.311154,37.814732],[-79.347906,37.825242],[-79.426182,37.678093]]]},"properties":{"id":"24416","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.072655,38.745225],[-78.937555,38.75963],[-79.067573,38.752043],[-79.072655,38.745225]]]},"properties":{"id":"22820","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.77153,37.115264],[-81.754555,37.079833],[-81.626893,37.110779],[-81.600204,37.122295],[-81.625186,37.148927],[-81.72704,37.136606],[-81.77153,37.115264]]],[[[-81.831588,37.072692],[-81.85339,37.076391],[-81.896027,37.008146],[-81.912388,36.97996],[-81.963404,36.930925],[-81.946985,36.864815],[-81.780539,36.958614],[-81.761871,36.994551],[-81.755271,37.076816],[-81.831588,37.072692]]]]},"properties":{"id":"24609","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.566621,36.594709],[-82.542024,36.603815],[-82.560102,36.637089],[-82.609254,36.59406],[-82.566621,36.594709]]]},"properties":{"id":"24290","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.46227,36.673438],[-82.459727,36.594586],[-82.406018,36.595012],[-82.32476,36.685612],[-82.322313,36.71647],[-82.332089,36.709355],[-82.46227,36.673438]]]},"properties":{"id":"24258","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.381591,36.828083],[-82.504915,36.777127],[-82.46227,36.673438],[-82.332089,36.709355],[-82.344758,36.748507],[-82.381591,36.828083]]]},"properties":{"id":"24271","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.567015,38.728847],[-78.546907,38.738022],[-78.386682,38.830152],[-78.339795,38.874704],[-78.303423,38.94609],[-78.395431,38.915862],[-78.436708,38.876451],[-78.519428,38.789121],[-78.567015,38.728847]]]},"properties":{"id":"22652","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.315415,36.723342],[-77.361258,36.569947],[-77.296276,36.628864],[-77.288004,36.65669],[-77.315415,36.723342]]]},"properties":{"id":"23844","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.179908,36.829457],[-77.334701,36.757149],[-77.377992,36.735392],[-77.315415,36.723342],[-77.288004,36.65669],[-77.132171,36.632256],[-77.113475,36.655218],[-77.179908,36.829457]]]},"properties":{"id":"23829","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.968529,37.037233],[-77.069918,36.884566],[-76.977375,36.870399],[-76.849684,36.996211],[-76.913527,37.030462],[-76.968529,37.037233]]]},"properties":{"id":"23888","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.447126,38.284614],[-77.326692,38.245136],[-77.321403,38.345226],[-77.435499,38.397622],[-77.502254,38.325855],[-77.447126,38.284614]]]},"properties":{"id":"22405","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.220261,37.040587],[-77.193483,37.04394],[-77.21299,37.064772],[-77.220261,37.040587]]]},"properties":{"id":"23891","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.459331,37.03277],[-81.709072,36.976858],[-81.698788,36.959598],[-81.658339,36.970983],[-81.65313,36.950987],[-81.677919,36.933997],[-81.476481,36.988066],[-81.459331,37.03277]]]},"properties":{"id":"24377","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.393162,37.232061],[-81.279139,37.271564],[-81.307365,37.290693],[-81.402363,37.238756],[-81.393162,37.232061]]]},"properties":{"id":"24613","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.397531,37.304005],[-81.410733,37.28236],[-81.381224,37.285403],[-81.397531,37.304005]]]},"properties":{"id":"24606","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.878078,36.615327],[-81.6469,36.611918],[-81.605835,36.638717],[-81.645107,36.684607],[-81.705063,36.665879],[-81.790803,36.701308],[-81.853921,36.662434],[-81.878078,36.615327]]]},"properties":{"id":"24236","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.644459,38.030062],[-76.706663,38.026285],[-76.664588,37.983831],[-76.64894,38.019116],[-76.644459,38.030062]]]},"properties":{"id":"22529","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.563899,36.940046],[-82.675419,37.060949],[-82.72502,37.042419],[-82.73787,37.043856],[-82.72851,36.919146],[-82.633961,36.906397],[-82.563899,36.940046]]]},"properties":{"id":"24273","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.159914,36.84935],[-81.223084,36.913724],[-81.189718,36.818849],[-81.16019,36.825148],[-81.159914,36.84935]]]},"properties":{"id":"24323","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.396008,37.210022],[-76.417653,37.183727],[-76.394113,37.1688],[-76.396008,37.210022]]]},"properties":{"id":"23696","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.458516,37.100661],[-76.435519,37.094882],[-76.39447,37.106248],[-76.412885,37.155808],[-76.506101,37.154129],[-76.458516,37.100661]]]},"properties":{"id":"23693","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.065553,36.68555],[-76.098455,36.71675],[-76.29665,36.734343],[-76.376634,36.550596],[-76.122354,36.550552],[-76.065553,36.68555]]]},"properties":{"id":"23322","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.449733,37.043236],[-76.417505,37.013249],[-76.397199,37.025538],[-76.35656,37.063425],[-76.393401,37.108321],[-76.39447,37.106248],[-76.435519,37.094882],[-76.449733,37.043236]]]},"properties":{"id":"23666","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.53593,37.023699],[-76.459134,37.096403],[-76.536874,37.074291],[-76.543994,37.074022],[-76.597084,37.066803],[-76.53593,37.023699]]]},"properties":{"id":"23606","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.323905,36.964563],[-76.265837,36.931594],[-76.253237,36.930283],[-76.21476,36.937547],[-76.298956,36.968563],[-76.299365,36.968552],[-76.323905,36.964563]]]},"properties":{"id":"23503","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.287981,36.860919],[-76.302202,36.873959],[-76.317211,36.860657],[-76.313533,36.858533],[-76.287981,36.860919]]]},"properties":{"id":"23507","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.46832,37.919533],[-75.497558,37.923499],[-75.474407,37.907892],[-75.46832,37.919533]]]},"properties":{"id":"23337","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.827074,37.667021],[-75.722995,37.680846],[-75.707514,37.702607],[-75.700348,37.720396],[-75.700485,37.77933],[-75.715496,37.794464],[-75.793532,37.902493],[-75.865506,37.673999],[-75.842912,37.67303],[-75.827074,37.667021]]]},"properties":{"id":"23417","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.700526,37.896245],[-75.667805,37.950091],[-75.801209,37.912191],[-75.703111,37.895767],[-75.700526,37.896245]]]},"properties":{"id":"23427","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.722995,37.680846],[-75.67787,37.627078],[-75.670105,37.620232],[-75.648897,37.65331],[-75.697543,37.699738],[-75.707514,37.702607],[-75.722995,37.680846]]]},"properties":{"id":"23418","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.865506,37.673999],[-75.902768,37.630577],[-75.847821,37.630702],[-75.840403,37.653562],[-75.842912,37.67303],[-75.865506,37.673999]]]},"properties":{"id":"23358","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.364341,38.128146],[-78.396745,38.001459],[-78.270371,38.014644],[-78.29543,38.121969],[-78.364341,38.128146]]]},"properties":{"id":"22947","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.623086,37.139521],[-77.589159,37.246285],[-77.599025,37.25279],[-77.741188,37.267895],[-77.742003,37.255164],[-77.668407,37.122068],[-77.626795,37.117378],[-77.623086,37.139521]]]},"properties":{"id":"23833","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.944814,38.157032],[-79.031262,38.096373],[-78.999269,38.075527],[-78.944814,38.157032]]]},"properties":{"id":"22939","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.295744,38.213253],[-79.338242,38.141197],[-79.318412,38.104263],[-79.300495,38.112242],[-79.272744,38.09476],[-79.204531,38.076215],[-79.167369,38.205764],[-79.295744,38.213253]]]},"properties":{"id":"24479","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.300495,38.112242],[-79.318412,38.104263],[-79.299093,38.10769],[-79.300495,38.112242]]]},"properties":{"id":"24411","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.926902,38.208836],[-78.90641,38.197501],[-78.905637,38.198159],[-78.908508,38.226474],[-78.88088,38.240966],[-79.020802,38.304904],[-79.033305,38.292322],[-79.046324,38.252582],[-79.03027,38.237918],[-78.999427,38.240568],[-78.926902,38.208836]]]},"properties":{"id":"24467","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.908508,38.226474],[-78.905637,38.198159],[-78.882815,38.208893],[-78.908508,38.226474]]],[[[-78.90641,38.197501],[-78.915551,38.181678],[-78.879172,38.164436],[-78.90641,38.197501]]],[[[-78.999427,38.240568],[-78.94103,38.197013],[-78.926902,38.208836],[-78.999427,38.240568]]]]},"properties":{"id":"24437","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.247149,37.974879],[-79.208936,37.953334],[-79.236332,37.98404],[-79.247149,37.974879]]]},"properties":{"id":"24476","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.648063,38.212532],[-79.76623,38.050693],[-79.512158,38.180419],[-79.427987,38.23047],[-79.405996,38.283923],[-79.48083,38.283002],[-79.648063,38.212532]]]},"properties":{"id":"24487","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.379448,37.2568],[-79.355604,37.480676],[-79.355847,37.482479],[-79.431542,37.454099],[-79.439032,37.357841],[-79.420546,37.356118],[-79.415716,37.333944],[-79.397622,37.268802],[-79.379448,37.2568]]]},"properties":{"id":"24556","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.355604,37.480676],[-79.379448,37.2568],[-79.360766,37.246657],[-79.281848,37.302089],[-79.244174,37.387322],[-79.328086,37.475857],[-79.355604,37.480676]]]},"properties":{"id":"24551","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.51818,37.168405],[-79.594059,37.042238],[-79.53298,37.039393],[-79.473648,37.063389],[-79.367612,37.177397],[-79.360766,37.246657],[-79.379448,37.2568],[-79.397622,37.268802],[-79.51818,37.168405]]]},"properties":{"id":"24104","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.012185,37.50305],[-79.817331,37.406256],[-79.757345,37.42478],[-79.787055,37.601075],[-79.987845,37.570764],[-80.012185,37.50305]]]},"properties":{"id":"24090","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.864789,37.354618],[-79.974025,37.308779],[-79.952116,37.288553],[-79.935977,37.272447],[-79.91783,37.27954],[-79.820231,37.331043],[-79.835553,37.355448],[-79.864789,37.354618]]]},"properties":{"id":"24012","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.909406,37.37871],[-79.949611,37.397658],[-79.949736,37.385898],[-79.909406,37.37871]]]},"properties":{"id":"24083","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.957294,36.947938],[-78.01811,36.87415],[-78.02178,36.83805],[-77.92246,36.81295],[-77.876248,36.805843],[-77.833472,36.880551],[-77.822072,36.909691],[-77.928484,36.978096],[-77.957294,36.947938]]]},"properties":{"id":"23821","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.12345,37.386525],[-82.190651,37.316887],[-82.210392,37.263791],[-82.207251,37.222935],[-82.06084,37.236567],[-81.945694,37.279355],[-81.853685,37.294147],[-81.920981,37.355751],[-82.12345,37.386525]]]},"properties":{"id":"24614","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.920981,37.355751],[-82.150072,37.410028],[-82.12345,37.386525],[-81.920981,37.355751]]]},"properties":{"id":"24620","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.173785,37.120588],[-82.135895,37.101105],[-82.071141,37.109594],[-82.043805,37.185852],[-82.06084,37.236567],[-82.207251,37.222935],[-82.222179,37.189364],[-82.218082,37.182935],[-82.180423,37.136555],[-82.173785,37.120588]]]},"properties":{"id":"24656","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.896027,37.008146],[-81.982971,37.122486],[-81.990627,37.128676],[-82.071141,37.109594],[-82.135895,37.101105],[-82.132191,37.09428],[-82.119178,37.040536],[-82.079418,36.967759],[-81.936294,36.984279],[-81.912388,36.97996],[-81.896027,37.008146]]]},"properties":{"id":"24260","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.982971,37.122486],[-81.896027,37.008146],[-81.85339,37.076391],[-81.945783,37.133818],[-81.982971,37.122486]]]},"properties":{"id":"24649","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.270779,37.118835],[-82.285395,37.157386],[-82.305396,37.12202],[-82.270779,37.118835]]],[[[-82.218082,37.182935],[-82.212204,37.134615],[-82.180423,37.136555],[-82.218082,37.182935]]],[[[-82.248113,37.261708],[-82.31462,37.295782],[-82.384386,37.259618],[-82.326826,37.198029],[-82.28457,37.176244],[-82.222179,37.189364],[-82.207251,37.222935],[-82.210392,37.263791],[-82.248113,37.261708]]]]},"properties":{"id":"24256","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.239748,37.690495],[-78.324226,37.714565],[-78.385566,37.631498],[-78.264188,37.599717],[-78.2193,37.62697],[-78.239748,37.690495]]]},"properties":{"id":"23123","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.146522,37.209397],[-79.144752,37.168346],[-79.215907,37.118921],[-79.179557,37.071101],[-79.060247,37.047211],[-78.972067,37.050132],[-78.949437,37.195226],[-78.986887,37.21594],[-79.146522,37.209397]]]},"properties":{"id":"24554","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.188295,38.174583],[-77.294402,38.227029],[-77.30028,38.22025],[-77.199728,38.1634],[-77.188295,38.174583]]]},"properties":{"id":"22538","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.199728,38.1634],[-77.143743,38.111787],[-77.113448,38.122132],[-77.115997,38.149931],[-77.188295,38.174583],[-77.199728,38.1634]]]},"properties":{"id":"22535","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.576594,37.922584],[-77.549005,37.907789],[-77.548941,37.907996],[-77.576594,37.922584]]],[[[-77.576594,37.922584],[-77.540878,37.919865],[-77.369389,37.83452],[-77.288875,37.841789],[-77.243162,37.900241],[-77.390625,38.026566],[-77.387239,38.034622],[-77.560328,38.065347],[-77.593119,38.034447],[-77.576594,37.922584]]]]},"properties":{"id":"22546","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.288875,37.841789],[-77.369389,37.83452],[-77.410765,37.801133],[-77.402783,37.707812],[-77.247623,37.730744],[-77.254284,37.787895],[-77.288875,37.841789]]]},"properties":{"id":"23069","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.78444,36.6495],[-80.932179,36.776928],[-80.962667,36.750719],[-81.028819,36.671091],[-81.10481,36.568001],[-80.815775,36.560166],[-80.769348,36.603334],[-80.78444,36.6495]]]},"properties":{"id":"24333","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.723584,37.265708],[-76.882047,37.271683],[-76.883156,37.223057],[-76.785476,37.201044],[-76.641581,37.153827],[-76.610984,37.167114],[-76.600701,37.17521],[-76.594715,37.228135],[-76.616211,37.302872],[-76.724133,37.274867],[-76.722804,37.270061],[-76.717652,37.269161],[-76.717646,37.265876],[-76.723584,37.265708]]]},"properties":{"id":"23185","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.648879,36.988964],[-78.563575,36.876814],[-78.534026,36.850403],[-78.422666,36.905566],[-78.407462,36.910651],[-78.613919,37.025077],[-78.669177,37.006569],[-78.648879,36.988964]]]},"properties":{"id":"23937","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.729389,37.373718],[-77.679956,37.362207],[-77.709242,37.399787],[-77.729389,37.373718]]],[[[-77.502177,37.409963],[-77.516377,37.460519],[-77.522737,37.467839],[-77.55551,37.445748],[-77.583081,37.430665],[-77.673538,37.359035],[-77.512686,37.369954],[-77.489819,37.376183],[-77.502177,37.409963]]]]},"properties":{"id":"23832","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.417353,37.243821],[-77.420891,37.234585],[-77.41651,37.233157],[-77.417353,37.243821]]]},"properties":{"id":"23806","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.400445,37.316756],[-77.408587,37.377776],[-77.489819,37.376183],[-77.512686,37.369954],[-77.466232,37.317206],[-77.400445,37.316756]]]},"properties":{"id":"23831","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.660776,38.41864],[-77.750431,38.426626],[-77.804068,38.421823],[-77.757499,38.385931],[-77.703189,38.360598],[-77.685897,38.359614],[-77.618727,38.367835],[-77.634835,38.409713],[-77.660776,38.41864]]]},"properties":{"id":"22736","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.368424,36.997703],[-82.356826,36.889908],[-82.305585,36.963216],[-82.315086,36.978746],[-82.368424,36.997703]]]},"properties":{"id":"24283","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.753654,37.078143],[-77.588587,37.011266],[-77.626795,37.117378],[-77.668407,37.122068],[-77.753654,37.078143]]]},"properties":{"id":"23840","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.697517,37.804049],[-76.720381,37.815664],[-76.728213,37.822236],[-76.876319,37.782147],[-76.903838,37.795022],[-76.919242,37.762887],[-76.751072,37.728605],[-76.735773,37.744032],[-76.697517,37.804049]]]},"properties":{"id":"22437","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.08897,38.013644],[-77.070046,37.974743],[-76.905303,38.002002],[-76.936959,38.077074],[-77.042959,38.106969],[-77.057368,38.076904],[-77.08897,38.013644]]]},"properties":{"id":"22438","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.132859,38.741068],[-77.127601,38.741014],[-77.08142,38.730457],[-77.058141,38.752059],[-77.073368,38.784155],[-77.08454,38.785906],[-77.121508,38.767131],[-77.132859,38.741068]]]},"properties":{"id":"22306","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.252269,38.736456],[-77.203953,38.725094],[-77.208216,38.761896],[-77.263606,38.772549],[-77.276986,38.755538],[-77.252269,38.736456]]]},"properties":{"id":"22153","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.121508,38.767131],[-77.165059,38.767439],[-77.183347,38.74521],[-77.170809,38.733561],[-77.132859,38.741068],[-77.121508,38.767131]]]},"properties":{"id":"22315","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.324187,38.812665],[-77.294858,38.84415],[-77.29785,38.870924],[-77.307456,38.875201],[-77.305744,38.877114],[-77.336415,38.864009],[-77.402944,38.852024],[-77.395414,38.840767],[-77.365928,38.794607],[-77.324187,38.812665]],[[-77.360657,38.857349],[-77.350648,38.855935],[-77.357549,38.852042],[-77.360657,38.857349]]]},"properties":{"id":"22030","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.325525,38.800705],[-77.266092,38.811525],[-77.251483,38.838551],[-77.294858,38.84415],[-77.324187,38.812665],[-77.325525,38.800705]]]},"properties":{"id":"22032","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.08142,38.730457],[-77.074186,38.712824],[-77.041514,38.761038],[-77.058141,38.752059],[-77.08142,38.730457]]]},"properties":{"id":"22308","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.136462,38.837124],[-77.128337,38.821109],[-77.107754,38.832088],[-77.105073,38.8391],[-77.110731,38.843457],[-77.136462,38.837124]]]},"properties":{"id":"22311","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.216046,38.90346],[-77.228351,38.893238],[-77.22818,38.883549],[-77.219203,38.887542],[-77.216046,38.90346]]]},"properties":{"id":"22027","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.675143,38.465902],[-77.761389,38.50909],[-77.75253,38.427448],[-77.750431,38.426626],[-77.660776,38.41864],[-77.675143,38.465902]]]},"properties":{"id":"22742","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.264485,37.084919],[-80.284465,36.995675],[-80.190895,37.011475],[-80.214079,37.07444],[-80.264485,37.084919]]]},"properties":{"id":"24072","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.170773,37.935407],[-78.156008,37.825924],[-78.039318,37.822654],[-78.025868,37.827812],[-78.170773,37.935407]]]},"properties":{"id":"23084","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.654818,37.042284],[-79.735591,37.036176],[-79.699789,36.965299],[-79.614298,37.034154],[-79.654818,37.042284]]]},"properties":{"id":"24176","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.128037,36.729482],[-80.05467,36.623689],[-80.020906,36.636712],[-80.01763,36.691772],[-79.94062,36.722355],[-79.950516,36.728156],[-79.951606,36.740127],[-79.936412,36.73934],[-79.937529,36.729247],[-79.932707,36.73088],[-79.920138,36.75014],[-79.855806,36.830339],[-79.929711,36.832724],[-80.041493,36.794365],[-80.08364,36.807968],[-80.128037,36.729482]]]},"properties":{"id":"24055","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.677828,37.330292],[-80.639981,37.292294],[-80.615719,37.286234],[-80.602562,37.266232],[-80.522711,37.412014],[-80.677828,37.330292]]]},"properties":{"id":"24136","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.863003,37.411514],[-80.85881,37.357199],[-80.821588,37.372409],[-80.863003,37.411514]]]},"properties":{"id":"24093","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.357058,37.332606],[-76.586197,37.302679],[-76.529503,37.263761],[-76.488136,37.238448],[-76.468919,37.233871],[-76.383021,37.247755],[-76.307308,37.282058],[-76.357058,37.332606]]]},"properties":{"id":"23072","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.549022,37.658599],[-76.64506,37.613945],[-76.654076,37.603174],[-76.647892,37.492231],[-76.514015,37.548613],[-76.508767,37.569782],[-76.530404,37.632147],[-76.549022,37.658599]]]},"properties":{"id":"23149","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.749237,37.772625],[-77.779636,37.773906],[-77.815524,37.767026],[-77.857243,37.756403],[-77.871323,37.758379],[-77.860249,37.750949],[-77.88986,37.664315],[-77.854765,37.643219],[-77.829856,37.632436],[-77.787869,37.704864],[-77.782295,37.710454],[-77.749237,37.772625]]]},"properties":{"id":"23102","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.854765,37.643219],[-77.823547,37.618983],[-77.829856,37.632436],[-77.854765,37.643219]]]},"properties":{"id":"23160","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.683865,36.835703],[-78.839736,36.823963],[-78.794484,36.698992],[-78.69033,36.681437],[-78.556218,36.635869],[-78.549377,36.626152],[-78.549858,36.628972],[-78.648541,36.697909],[-78.66705,36.810404],[-78.683865,36.835703]]]},"properties":{"id":"24589","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.956517,36.840576],[-78.856814,36.857737],[-78.887462,36.881861],[-78.956517,36.840576]]]},"properties":{"id":"24539","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.127736,36.915151],[-79.26897,36.854386],[-79.228604,36.77043],[-79.207982,36.771807],[-79.093856,36.84447],[-79.127736,36.915151]]]},"properties":{"id":"24565","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.880722,36.542042],[-78.703881,36.541992],[-78.709457,36.620528],[-78.69033,36.681437],[-78.794484,36.698992],[-78.825076,36.693435],[-78.880694,36.614745],[-78.880722,36.542042]]]},"properties":{"id":"24598","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.402783,37.707812],[-77.410765,37.801133],[-77.571868,37.804092],[-77.589938,37.778497],[-77.451017,37.679981],[-77.402783,37.707812]]]},"properties":{"id":"23005","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.732785,38.095861],[-77.789248,38.075944],[-77.815524,37.767026],[-77.779636,37.773906],[-77.715994,37.905277],[-77.685849,38.012076],[-77.732785,38.095861]]]},"properties":{"id":"23024","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.396556,38.434609],[-79.476396,38.457159],[-79.51491,38.3933],[-79.417022,38.407362],[-79.396556,38.434609]]]},"properties":{"id":"24433","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.417022,38.407362],[-79.48083,38.283002],[-79.405996,38.283923],[-79.313392,38.411521],[-79.312276,38.411876],[-79.396556,38.434609],[-79.417022,38.407362]]]},"properties":{"id":"24442","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.964624,36.781206],[-76.886467,36.777964],[-76.825955,36.784352],[-76.819642,36.892781],[-76.954443,36.798575],[-76.964624,36.781206]]]},"properties":{"id":"23898","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.724133,37.274867],[-76.616211,37.302872],[-76.658651,37.380139],[-76.712305,37.431309],[-76.741984,37.467076],[-76.776893,37.433923],[-76.872304,37.338444],[-76.874206,37.340167],[-76.882047,37.271683],[-76.723584,37.265708],[-76.724133,37.274867]]]},"properties":{"id":"23188","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.969851,37.82867],[-77.017698,37.773369],[-76.94897,37.741611],[-76.921427,37.740434],[-76.919242,37.762887],[-76.903838,37.795022],[-76.942655,37.796917],[-76.941511,37.80335],[-76.945952,37.820117],[-76.969851,37.82867]]]},"properties":{"id":"23023","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.792184,37.517594],[-76.782319,37.501597],[-76.719447,37.607915],[-76.754818,37.638127],[-76.821577,37.605795],[-76.792184,37.517594]]]},"properties":{"id":"23110","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.02961,38.31302],[-77.047132,38.352698],[-77.014598,38.359561],[-77.286202,38.347024],[-77.321403,38.345226],[-77.326692,38.245136],[-77.294402,38.227029],[-77.188295,38.174583],[-77.115997,38.149931],[-77.061695,38.161631],[-76.99767,38.278046],[-77.02961,38.31302]]]},"properties":{"id":"22485","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.970441,37.694294],[-77.081215,37.763702],[-77.154132,37.729358],[-77.137637,37.666964],[-77.123675,37.626881],[-76.958905,37.574009],[-76.970441,37.694294]]]},"properties":{"id":"23086","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.216423,37.688885],[-77.137637,37.666964],[-77.154132,37.729358],[-77.254284,37.787895],[-77.247623,37.730744],[-77.216423,37.688885]]]},"properties":{"id":"23106","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.075533,37.76606],[-77.135202,37.866101],[-77.181418,37.891654],[-77.243162,37.900241],[-77.288875,37.841789],[-77.254284,37.787895],[-77.154132,37.729358],[-77.081215,37.763702],[-77.075533,37.76606]]]},"properties":{"id":"23009","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.413909,37.679395],[-76.421512,37.730904],[-76.475899,37.631436],[-76.443813,37.628423],[-76.435666,37.654022],[-76.413909,37.679395]]]},"properties":{"id":"22576","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.85349,36.775811],[-82.627071,36.879469],[-82.633961,36.906397],[-82.72851,36.919146],[-82.844747,36.84696],[-82.91357,36.811866],[-82.85349,36.775811]],[[-82.737187,36.873439],[-82.740123,36.863906],[-82.753276,36.86197],[-82.747775,36.870877],[-82.737187,36.873439]]]},"properties":{"id":"24219","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.467012,38.993544],[-77.530888,39.043563],[-77.557484,38.979111],[-77.535431,38.952708],[-77.467012,38.993544]]]},"properties":{"id":"20148","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.401564,39.030176],[-77.402509,38.982709],[-77.371303,39.014584],[-77.365028,39.036218],[-77.401564,39.030176]]]},"properties":{"id":"20164","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.67871,39.324342],[-77.65963,39.216508],[-77.604792,39.22711],[-77.546471,39.272228],[-77.67871,39.324342]]]},"properties":{"id":"20180","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.090639,36.794149],[-78.27528,36.819929],[-78.28956,36.827792],[-78.289962,36.782913],[-78.234334,36.635796],[-78.149421,36.651897],[-78.106162,36.779685],[-78.090639,36.794149]]]},"properties":{"id":"23970","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.141136,38.349739],[-78.172933,38.360592],[-78.201216,38.326106],[-78.171554,38.317993],[-78.141136,38.349739]]],[[[-78.261711,38.477168],[-78.253183,38.46769],[-78.279751,38.450518],[-78.284977,38.475922],[-78.323208,38.497865],[-78.381407,38.422092],[-78.429241,38.367883],[-78.377821,38.348356],[-78.365806,38.317504],[-78.358845,38.290023],[-78.270723,38.345654],[-78.240872,38.336645],[-78.185901,38.369125],[-78.183358,38.382783],[-78.221133,38.424222],[-78.222455,38.447721],[-78.237282,38.476148],[-78.212192,38.51468],[-78.231978,38.53255],[-78.261711,38.477168]]]]},"properties":{"id":"22727","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.171554,38.317993],[-78.201216,38.326106],[-78.207377,38.31337],[-78.171554,38.317993]]]},"properties":{"id":"22732","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.419897,37.510297],[-76.446041,37.475021],[-76.406162,37.459111],[-76.370402,37.459452],[-76.353796,37.482171],[-76.345013,37.48727],[-76.349604,37.525536],[-76.356933,37.527725],[-76.393632,37.530841],[-76.419897,37.510297]]]},"properties":{"id":"23035","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.247012,37.439982],[-76.283772,37.419193],[-76.283785,37.417617],[-76.245594,37.42106],[-76.247012,37.439982]]]},"properties":{"id":"23045","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.470685,36.674975],[-78.549858,36.628972],[-78.549377,36.626152],[-78.313001,36.543917],[-78.046203,36.544156],[-78.26416,36.618159],[-78.316735,36.72988],[-78.452228,36.735848],[-78.470685,36.674975]]]},"properties":{"id":"23917","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.452358,37.565464],[-76.400406,37.612086],[-76.443813,37.628423],[-76.475899,37.631436],[-76.500681,37.626509],[-76.503304,37.571808],[-76.452358,37.565464]]]},"properties":{"id":"23169","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.515179,37.199821],[-80.327276,37.214169],[-80.251628,37.300675],[-80.251963,37.301048],[-80.327898,37.366792],[-80.606768,37.24496],[-80.606874,37.244911],[-80.601675,37.202283],[-80.58007,37.185147],[-80.515179,37.199821]]]},"properties":{"id":"24060","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.327276,37.214169],[-80.515179,37.199821],[-80.526016,37.191585],[-80.517417,37.0367],[-80.354098,37.075578],[-80.327182,37.091591],[-80.304199,37.202231],[-80.327276,37.214169]]]},"properties":{"id":"24073","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.062283,37.91752],[-79.157423,37.890995],[-79.146789,37.811387],[-79.141939,37.813264],[-79.012833,37.88505],[-79.062283,37.91752]]]},"properties":{"id":"24464","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.86363,37.454073],[-75.900542,37.449082],[-75.842237,37.428259],[-75.829765,37.436615],[-75.83354,37.448292],[-75.86363,37.454073]]]},"properties":{"id":"23307","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.86363,37.454073],[-75.83354,37.448292],[-75.84854,37.470666],[-75.86363,37.454073]]]},"properties":{"id":"23408","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.935166,37.480465],[-75.923099,37.457737],[-75.897173,37.488618],[-75.935166,37.480465]]]},"properties":{"id":"23354","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.399584,36.754867],[-79.593841,36.679317],[-79.350019,36.549291],[-79.320182,36.679543],[-79.322858,36.682721],[-79.399193,36.754947],[-79.399584,36.754867]]]},"properties":{"id":"24540","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.211377,36.57166],[-79.261752,36.692252],[-79.320182,36.679543],[-79.350019,36.549291],[-79.342686,36.541366],[-79.218638,36.541639],[-79.211377,36.57166]]]},"properties":{"id":"24586","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.320295,37.184949],[-77.344562,37.051654],[-77.278014,37.011654],[-77.220261,37.040587],[-77.21299,37.064772],[-77.093059,37.120512],[-77.08364,37.274488],[-77.157639,37.225922],[-77.320295,37.184949]]]},"properties":{"id":"23842","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.482204,38.784176],[-77.530283,38.738285],[-77.506097,38.705681],[-77.450725,38.732177],[-77.482204,38.784176]]]},"properties":{"id":"20110","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.591289,38.880859],[-77.548888,38.82558],[-77.545954,38.849079],[-77.552925,38.886838],[-77.58062,38.89099],[-77.591289,38.880859]]]},"properties":{"id":"20143","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.50639,37.71714],[-79.426182,37.678093],[-79.347906,37.825242],[-79.367148,37.874929],[-79.499812,37.929262],[-79.61323,37.856986],[-79.674006,37.763052],[-79.676014,37.657103],[-79.638945,37.634737],[-79.50639,37.71714]]]},"properties":{"id":"24450","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.88998,38.593477],[-78.82648,38.540818],[-78.685431,38.518765],[-78.667112,38.557877],[-78.706133,38.5808],[-78.800176,38.714201],[-78.816348,38.76921],[-78.814882,38.778435],[-78.826351,38.778605],[-78.868479,38.762423],[-78.900406,38.610089],[-78.88998,38.593477]]]},"properties":{"id":"22815","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.092947,38.664432],[-79.019337,38.568075],[-78.967908,38.545417],[-78.900406,38.610089],[-78.868479,38.762423],[-78.869276,38.762991],[-78.891402,38.778447],[-78.937555,38.75963],[-79.072655,38.745225],[-79.092947,38.664432]]]},"properties":{"id":"22830","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.119178,37.040536],[-82.148718,37.041691],[-82.219635,36.983158],[-82.194281,36.926209],[-82.079418,36.967759],[-82.119178,37.040536]]]},"properties":{"id":"24225","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.693132,36.728377],[-82.52093,36.771489],[-82.607735,36.801899],[-82.693132,36.728377]]]},"properties":{"id":"24250","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.243382,36.595651],[-82.179637,36.626833],[-82.14607,36.594724],[-82.087375,36.594685],[-82.039853,36.678063],[-82.18433,36.750191],[-82.32476,36.685612],[-82.406018,36.595012],[-82.243382,36.595651]]]},"properties":{"id":"24202","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.632038,38.904685],[-78.519428,38.789121],[-78.436708,38.876451],[-78.579064,38.95455],[-78.632038,38.904685]]]},"properties":{"id":"22664","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.655263,38.720843],[-78.785866,38.716076],[-78.763884,38.709537],[-78.655263,38.720843]]]},"properties":{"id":"22847","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.273457,36.79469],[-81.408631,36.810815],[-81.496072,36.733488],[-81.468498,36.715207],[-81.309408,36.752512],[-81.26162,36.76312],[-81.273457,36.79469]]]},"properties":{"id":"24375","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.050714,36.675497],[-77.113475,36.655218],[-77.132171,36.632256],[-77.1388,36.544031],[-77.021914,36.544307],[-77.050714,36.675497]]]},"properties":{"id":"23874","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.315265,38.478678],[-77.370708,38.520688],[-77.371622,38.521547],[-77.392135,38.485915],[-77.477255,38.422424],[-77.435499,38.397622],[-77.321403,38.345226],[-77.286202,38.347024],[-77.315265,38.478678]]]},"properties":{"id":"22554","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.560836,38.527082],[-77.599287,38.495499],[-77.477255,38.422424],[-77.392135,38.485915],[-77.437522,38.487676],[-77.560836,38.527082]]]},"properties":{"id":"22556","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.83484,37.098118],[-81.835072,37.085606],[-81.831588,37.072692],[-81.755271,37.076816],[-81.754555,37.079833],[-81.77153,37.115264],[-81.826846,37.142436],[-81.83484,37.098118]]]},"properties":{"id":"24641","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.561281,37.206633],[-81.53026,37.196999],[-81.522752,37.201982],[-81.521397,37.226902],[-81.561281,37.206633]]]},"properties":{"id":"24604","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.737187,36.873439],[-82.747775,36.870877],[-82.753276,36.86197],[-82.740123,36.863906],[-82.737187,36.873439]]]},"properties":{"id":"24246","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.545178,37.198273],[-76.468919,37.233871],[-76.488136,37.238448],[-76.497751,37.240731],[-76.529628,37.240301],[-76.550761,37.233303],[-76.569862,37.218793],[-76.545178,37.198273]]]},"properties":{"id":"23690","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.529628,37.240301],[-76.547231,37.243642],[-76.550761,37.233303],[-76.529628,37.240301]]]},"properties":{"id":"23691","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.091249,38.80195],[-77.056773,38.792573],[-77.041362,38.785415],[-77.048025,38.841259],[-77.04899,38.84121],[-77.049634,38.82042],[-77.071537,38.811707],[-77.089551,38.817416],[-77.091249,38.80195]]]},"properties":{"id":"22314","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.420368,36.866348],[-76.463398,36.77046],[-76.466768,36.746249],[-76.384826,36.795931],[-76.383466,36.837548],[-76.420368,36.866348]]]},"properties":{"id":"23321","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.257475,36.820757],[-76.289962,36.822048],[-76.292828,36.797154],[-76.295307,36.775138],[-76.25165,36.787026],[-76.257475,36.820757]]]},"properties":{"id":"23324","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.450754,37.021231],[-76.486198,36.988604],[-76.445301,36.941891],[-76.434789,36.935283],[-76.399567,36.930947],[-76.384419,36.951059],[-76.38758,36.989617],[-76.411284,37.003574],[-76.450754,37.021231]]]},"properties":{"id":"23607","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.184598,36.908658],[-76.215069,36.883064],[-76.247893,36.863289],[-76.236644,36.838083],[-76.22606,36.839947],[-76.194674,36.830837],[-76.188443,36.874108],[-76.184598,36.908658]]]},"properties":{"id":"23502","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.278112,36.920695],[-76.294208,36.919577],[-76.292342,36.930395],[-76.322006,36.899632],[-76.279601,36.88871],[-76.277962,36.887847],[-76.256485,36.904534],[-76.252708,36.91589],[-76.253237,36.930283],[-76.265837,36.931594],[-76.278112,36.920695]]]},"properties":{"id":"23505","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.143253,36.757045],[-76.098455,36.71675],[-76.065553,36.68555],[-75.928707,36.689145],[-75.949391,36.760764],[-76.04407,36.780022],[-76.126927,36.798298],[-76.128426,36.799148],[-76.143253,36.757045]]]},"properties":{"id":"23456","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.090755,36.901447],[-75.963325,36.804948],[-75.993052,36.915147],[-76.057922,36.917052],[-76.091592,36.908369],[-76.090755,36.901447]]]},"properties":{"id":"23451","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.177092,36.930054],[-76.184598,36.908658],[-76.188443,36.874108],[-76.126357,36.853948],[-76.090355,36.898547],[-76.090755,36.901447],[-76.091592,36.908369],[-76.177092,36.930054]]]},"properties":{"id":"23455","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.746557,37.597986],[-75.791748,37.502544],[-75.809184,37.465743],[-75.611825,37.580621],[-75.614622,37.581589],[-75.693228,37.597319],[-75.698752,37.599686],[-75.746557,37.597986]]]},"properties":{"id":"23423","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.428885,38.010889],[-75.442,38.001099],[-75.424089,37.952913],[-75.41176,37.946333],[-75.323157,38.020155],[-75.428885,38.010889]]]},"properties":{"id":"23356","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.700348,37.720396],[-75.707514,37.702607],[-75.697543,37.699738],[-75.697025,37.718415],[-75.700348,37.720396]]]},"properties":{"id":"23441","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.567701,37.79604],[-75.537212,37.763906],[-75.509284,37.823462],[-75.564161,37.850402],[-75.571796,37.844651],[-75.587916,37.819709],[-75.567701,37.79604]]],[[[-75.587916,37.819709],[-75.600194,37.851344],[-75.703111,37.895767],[-75.801209,37.912191],[-75.793532,37.902493],[-75.715496,37.794464],[-75.594283,37.807978],[-75.587916,37.819709]]]]},"properties":{"id":"23308","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.992428,37.8191],[-75.971705,37.830928],[-75.992354,37.848752],[-76.001116,37.834947],[-75.992428,37.8191]]]},"properties":{"id":"23440","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.600194,37.851344],[-75.587916,37.819709],[-75.571796,37.844651],[-75.596747,37.858458],[-75.600194,37.851344]]],[[[-75.530922,37.853234],[-75.564161,37.850402],[-75.509284,37.823462],[-75.51483,37.837801],[-75.530922,37.853234]]]]},"properties":{"id":"23407","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.554832,37.835393],[-78.618952,37.752004],[-78.590241,37.67161],[-78.540894,37.65109],[-78.507881,37.677435],[-78.385096,37.733746],[-78.388198,37.912882],[-78.555286,37.86502],[-78.554832,37.835393]]]},"properties":{"id":"24590","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.586762,37.904636],[-78.618732,37.882518],[-78.684847,37.755517],[-78.618952,37.752004],[-78.554832,37.835393],[-78.564763,37.871553],[-78.575834,37.90923],[-78.586762,37.904636]]]},"properties":{"id":"22937","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.770891,38.05522],[-78.80043,38.028648],[-78.753078,38.023894],[-78.770891,38.05522]]]},"properties":{"id":"22943","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.52665,38.026687],[-78.514211,38.028806],[-78.51333,38.037137],[-78.522172,38.037781],[-78.52665,38.026687]]]},"properties":{"id":"22904","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.110631,37.501937],[-79.223724,37.506374],[-79.13972,37.419081],[-79.123282,37.399792],[-79.113672,37.397877],[-79.061809,37.393156],[-78.941469,37.496405],[-78.953716,37.500963],[-79.110631,37.501937]]]},"properties":{"id":"24572","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.00804,37.714258],[-79.02268,37.704425],[-78.996631,37.701671],[-79.00804,37.714258]]],[[[-79.146789,37.811387],[-79.174508,37.770793],[-79.067163,37.713492],[-79.058971,37.713516],[-79.006501,37.720363],[-78.999003,37.718853],[-78.974494,37.741941],[-78.920779,37.7933],[-78.901255,37.842104],[-78.913139,37.869382],[-78.985157,37.901996],[-78.992212,37.892053],[-79.141939,37.813264],[-79.146789,37.811387]]]]},"properties":{"id":"22967","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.329734,37.688123],[-79.290367,37.702651],[-79.303545,37.740751],[-79.329734,37.688123]]],[[[-79.291274,37.759067],[-79.174508,37.770793],[-79.146789,37.811387],[-79.157423,37.890995],[-79.233042,37.882966],[-79.311154,37.814732],[-79.291274,37.759067]]]]},"properties":{"id":"24483","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.752115,37.220803],[-78.856501,37.212443],[-78.895173,37.185865],[-78.832534,37.15896],[-78.751778,37.168765],[-78.752115,37.220803]]]},"properties":{"id":"23963","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.899053,37.948862],[-78.857456,38.03263],[-78.949678,38.028743],[-79.052591,37.941579],[-79.062283,37.91752],[-79.012833,37.88505],[-78.992212,37.892053],[-78.985157,37.901996],[-78.899053,37.948862]]]},"properties":{"id":"22952","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.942994,38.377353],[-78.983243,38.330624],[-78.854182,38.27838],[-78.836482,38.30398],[-78.847746,38.346532],[-78.942994,38.377353]]]},"properties":{"id":"22841","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.761677,38.274868],[-78.836482,38.30398],[-78.854182,38.27838],[-78.88088,38.240966],[-78.908508,38.226474],[-78.882815,38.208893],[-78.772125,38.153356],[-78.749354,38.206621],[-78.761677,38.274868]]]},"properties":{"id":"24441","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.820231,37.331043],[-79.72411,37.314885],[-79.725062,37.341872],[-79.74858,37.43214],[-79.757345,37.42478],[-79.817331,37.406256],[-79.835553,37.355448],[-79.820231,37.331043]]]},"properties":{"id":"24064","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.476481,36.988066],[-81.4806,36.921428],[-81.379427,36.952417],[-81.33922,36.974917],[-81.2279,37.045941],[-81.300422,37.05923],[-81.306946,37.077047],[-81.459331,37.03277],[-81.476481,36.988066]]]},"properties":{"id":"24318","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.909406,37.37871],[-79.914467,37.371288],[-79.901187,37.364588],[-79.895175,37.369572],[-79.909406,37.37871]]]},"properties":{"id":"24077","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.002927,37.591382],[-79.980646,37.623044],[-79.994953,37.629457],[-80.016047,37.60886],[-80.002927,37.591382]]]},"properties":{"id":"24130","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.876248,36.805843],[-77.739702,36.865125],[-77.833472,36.880551],[-77.876248,36.805843]]]},"properties":{"id":"23843","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.886917,36.643198],[-77.889182,36.622345],[-77.868454,36.622233],[-77.866611,36.63018],[-77.886917,36.643198]]],[[[-77.905956,36.612972],[-77.947656,36.588139],[-77.946511,36.580898],[-77.934029,36.563194],[-77.905956,36.612972]]],[[[-78.106162,36.779685],[-77.964833,36.617199],[-77.878273,36.700472],[-77.92246,36.81295],[-78.02178,36.83805],[-78.090639,36.794149],[-78.106162,36.779685]]]]},"properties":{"id":"23920","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.853685,37.294147],[-81.945694,37.279355],[-81.929129,37.256023],[-81.904865,37.241935],[-81.859782,37.253393],[-81.833785,37.283702],[-81.853685,37.294147]]]},"properties":{"id":"24634","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990627,37.128676],[-82.043805,37.185852],[-82.071141,37.109594],[-81.990627,37.128676]]]},"properties":{"id":"24646","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.180423,37.136555],[-82.212204,37.134615],[-82.213512,37.067935],[-82.148718,37.041691],[-82.119178,37.040536],[-82.132191,37.09428],[-82.173785,37.120588],[-82.180423,37.136555]]]},"properties":{"id":"24217","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.31462,37.295782],[-82.248113,37.261708],[-82.219004,37.310014],[-82.274737,37.323534],[-82.31462,37.295782]]]},"properties":{"id":"24607","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.770925,37.031685],[-78.832534,37.15896],[-78.895173,37.185865],[-78.949437,37.195226],[-78.972067,37.050132],[-78.794862,36.955988],[-78.754075,37.008093],[-78.770925,37.031685]]]},"properties":{"id":"24528","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.390625,38.026566],[-77.243162,37.900241],[-77.181418,37.891654],[-77.107206,37.955292],[-77.070046,37.974743],[-77.08897,38.013644],[-77.124857,38.03748],[-77.138682,38.048118],[-77.390625,38.026566]]]},"properties":{"id":"22514","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.576894,36.66136],[-80.483384,36.642977],[-80.513153,36.672444],[-80.560649,36.671456],[-80.576894,36.66136]]],[[[-80.785064,36.892134],[-80.774345,36.8691],[-80.841012,36.778861],[-80.767372,36.679978],[-80.570534,36.676412],[-80.602141,36.742215],[-80.66384,36.883028],[-80.770991,36.912601],[-80.785064,36.892134]]]]},"properties":{"id":"24343","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.616817,36.64844],[-80.739535,36.619028],[-80.714167,36.56228],[-80.612028,36.557833],[-80.616817,36.64844]]]},"properties":{"id":"24317","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.56511,36.965138],[-80.666153,37.02888],[-80.715647,36.992735],[-80.789371,36.932965],[-80.770991,36.912601],[-80.66384,36.883028],[-80.663457,36.883467],[-80.605475,36.94235],[-80.56511,36.965138]]]},"properties":{"id":"24347","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.613919,37.025077],[-78.407462,36.910651],[-78.31262,36.91016],[-78.27214,36.964955],[-78.317073,37.016553],[-78.482416,37.145419],[-78.527241,37.175354],[-78.613919,37.025077]]]},"properties":{"id":"23947","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.860941,39.153456],[-77.960133,39.078927],[-77.957891,39.046348],[-77.890193,39.06095],[-77.818691,39.027503],[-77.819648,39.153935],[-77.860941,39.153456]]]},"properties":{"id":"20135","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.860941,39.153456],[-78.033191,39.264612],[-78.045316,39.236465],[-78.073162,39.178388],[-78.090231,39.147638],[-77.960133,39.078927],[-77.860941,39.153456]]]},"properties":{"id":"22611","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.820304,38.5289],[-77.878899,38.467937],[-77.840751,38.437217],[-77.804068,38.421823],[-77.750431,38.426626],[-77.75253,38.427448],[-77.820304,38.5289]]]},"properties":{"id":"22718","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.855148,37.418363],[-77.762624,37.484492],[-77.732376,37.506209],[-77.720084,37.587424],[-77.822397,37.610059],[-77.823547,37.618983],[-77.854765,37.643219],[-77.88986,37.664315],[-78.072256,37.657357],[-78.098973,37.583523],[-78.149154,37.45279],[-77.855148,37.418363]]]},"properties":{"id":"23139","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.619239,36.948311],[-77.479341,37.007321],[-77.459384,37.065414],[-77.491985,37.111972],[-77.549128,37.139878],[-77.623086,37.139521],[-77.626795,37.117378],[-77.588587,37.011266],[-77.619239,36.948311]]]},"properties":{"id":"23841","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.479341,37.007321],[-77.268296,36.977472],[-77.278014,37.011654],[-77.344562,37.051654],[-77.459384,37.065414],[-77.479341,37.007321]]]},"properties":{"id":"23830","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.936426,37.827703],[-76.945952,37.820117],[-76.941511,37.80335],[-76.936426,37.827703]]]},"properties":{"id":"23115","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.143743,38.111787],[-77.138682,38.048118],[-77.124857,38.03748],[-77.072546,38.088769],[-77.113448,38.122132],[-77.143743,38.111787]]]},"properties":{"id":"22436","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.08142,38.730457],[-77.127601,38.741014],[-77.122632,38.685256],[-77.074186,38.712824],[-77.08142,38.730457]]]},"properties":{"id":"22309","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.315141,38.977778],[-77.264362,38.951351],[-77.234875,38.975976],[-77.328318,39.057678],[-77.365028,39.036218],[-77.371303,39.014584],[-77.341465,38.998325],[-77.315141,38.977778]]]},"properties":{"id":"22066","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.284465,36.995675],[-80.376805,37.005517],[-80.505454,36.994349],[-80.517865,36.973955],[-80.415949,36.799123],[-80.344626,36.801483],[-80.235455,36.87213],[-80.139539,36.970786],[-80.142821,36.980637],[-80.190895,37.011475],[-80.284465,36.995675]]]},"properties":{"id":"24091","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.137415,39.184839],[-78.198858,39.191046],[-78.170885,39.143814],[-78.137415,39.184839]]]},"properties":{"id":"22601","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.462894,37.496706],[-76.446041,37.475021],[-76.419897,37.510297],[-76.449119,37.518037],[-76.462894,37.496706]]]},"properties":{"id":"23050","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.769379,37.70721],[-77.675127,37.677394],[-77.644102,37.66922],[-77.649458,37.755387],[-77.749237,37.772625],[-77.782295,37.710454],[-77.769379,37.70721]]]},"properties":{"id":"23146","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.923118,37.757809],[-77.860249,37.750949],[-77.871323,37.758379],[-77.923118,37.757809]]],[[[-77.942208,37.762179],[-77.989476,37.807236],[-77.998232,37.774874],[-77.942208,37.762179]]]]},"properties":{"id":"23153","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.645107,36.684607],[-81.605835,36.638717],[-81.525935,36.655883],[-81.300371,36.648542],[-81.281997,36.687146],[-81.309408,36.752512],[-81.468498,36.715207],[-81.645107,36.684607]]]},"properties":{"id":"24378","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.633581,36.874931],[-77.377992,36.735392],[-77.334701,36.757149],[-77.354138,36.834849],[-77.600962,36.887117],[-77.633581,36.874931]]]},"properties":{"id":"23867","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.3828,37.595322],[-77.424702,37.550769],[-77.429549,37.528785],[-77.416537,37.517155],[-77.341991,37.536472],[-77.32401,37.569165],[-77.3828,37.595322]]]},"properties":{"id":"23223","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.94062,36.722355],[-80.01763,36.691772],[-79.92661,36.696556],[-79.932707,36.73088],[-79.937529,36.729247],[-79.94062,36.722355]]]},"properties":{"id":"24089","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.742255,36.939026],[-76.819642,36.892781],[-76.825955,36.784352],[-76.741229,36.7575],[-76.593968,36.870203],[-76.742255,36.939026]]]},"properties":{"id":"23487","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.98499,36.593729],[-82.8283,36.59375],[-82.693132,36.728377],[-82.607735,36.801899],[-82.618809,36.817645],[-82.627071,36.879469],[-82.85349,36.775811],[-82.933,36.735697],[-82.965579,36.696764],[-82.899797,36.701271],[-82.98499,36.593729]]]},"properties":{"id":"24244","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.009242,36.847408],[-83.031152,36.785349],[-82.985332,36.792352],[-82.91357,36.811866],[-82.844747,36.84696],[-82.878523,36.893346],[-83.009242,36.847408]]]},"properties":{"id":"24265","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.260168,36.919755],[-78.301787,36.879386],[-78.27528,36.819929],[-78.090639,36.794149],[-78.02178,36.83805],[-78.01811,36.87415],[-78.030236,36.951702],[-78.094836,37.021621],[-78.260168,36.919755]]]},"properties":{"id":"23944","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.131911,38.351021],[-78.102304,38.32784],[-78.115004,38.383172],[-78.149198,38.380914],[-78.131911,38.351021]]]},"properties":{"id":"22948","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.33832,38.627282],[-78.386778,38.556518],[-78.438994,38.516948],[-78.452918,38.475508],[-78.381407,38.422092],[-78.323208,38.497865],[-78.300105,38.57054],[-78.33832,38.627282]]]},"properties":{"id":"22743","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.134036,38.28178],[-78.112864,38.288262],[-78.130171,38.295083],[-78.134036,38.28178]]]},"properties":{"id":"22989","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.348119,37.41425],[-76.355898,37.402545],[-76.374667,37.349583],[-76.360537,37.335305],[-76.341754,37.374588],[-76.348119,37.41425]]],[[[-76.370402,37.459452],[-76.406162,37.459111],[-76.386998,37.421101],[-76.375834,37.42034],[-76.3582,37.419794],[-76.370402,37.459452]]]]},"properties":{"id":"23056","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.306305,37.462256],[-76.283772,37.419193],[-76.247012,37.439982],[-76.254963,37.466844],[-76.306305,37.462256]]]},"properties":{"id":"23119","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.375834,37.42034],[-76.355898,37.402545],[-76.348119,37.41425],[-76.3582,37.419794],[-76.375834,37.42034]]]},"properties":{"id":"23025","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.549858,36.628972],[-78.470685,36.674975],[-78.571191,36.728893],[-78.648541,36.697909],[-78.549858,36.628972]]],[[[-78.556218,36.635869],[-78.596047,36.541203],[-78.313001,36.543917],[-78.549377,36.626152],[-78.556218,36.635869]]]]},"properties":{"id":"23927","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.666675,37.69929],[-76.592528,37.742938],[-76.668182,37.778386],[-76.735773,37.744032],[-76.751072,37.728605],[-76.73896,37.694777],[-76.733303,37.68878],[-76.666675,37.69929]]]},"properties":{"id":"23079","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.644289,37.684756],[-76.55442,37.671364],[-76.592528,37.742938],[-76.666675,37.69929],[-76.644289,37.684756]]]},"properties":{"id":"23180","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.829884,37.649283],[-78.780107,37.638071],[-78.719279,37.635386],[-78.791825,37.667224],[-78.830121,37.663143],[-78.829884,37.649283]]]},"properties":{"id":"24581","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.935414,37.550406],[-75.961642,37.47136],[-75.935166,37.480465],[-75.897173,37.488618],[-75.804009,37.504026],[-75.804341,37.514737],[-75.813836,37.542726],[-75.935414,37.550406]]]},"properties":{"id":"23350","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.804341,37.514737],[-75.804009,37.504026],[-75.801996,37.504743],[-75.804341,37.514737]]]},"properties":{"id":"23486","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.985311,37.318626],[-75.919816,37.380349],[-75.983378,37.398484],[-76.023891,37.28344],[-75.999655,37.313973],[-75.985311,37.318626]]]},"properties":{"id":"23347","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.927449,38.300445],[-77.84255,38.257214],[-77.853932,38.347284],[-77.876807,38.338917],[-77.927449,38.300445]]]},"properties":{"id":"22542","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.341877,36.78777],[-80.196724,36.814506],[-80.235455,36.87213],[-80.344626,36.801483],[-80.341877,36.78777]]]},"properties":{"id":"24185","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.101894,36.620135],[-80.144901,36.630382],[-80.096685,36.592573],[-80.101894,36.620135]]]},"properties":{"id":"24082","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.45962,37.242134],[-78.455429,37.240658],[-78.449272,37.245288],[-78.454327,37.248886],[-78.45962,37.242134]]]},"properties":{"id":"23943","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.301104,38.54952],[-77.359969,38.530287],[-77.370708,38.520688],[-77.315265,38.478678],[-77.281803,38.529119],[-77.301104,38.54952]]],[[[-77.371622,38.521547],[-77.437522,38.487676],[-77.392135,38.485915],[-77.371622,38.521547]]]]},"properties":{"id":"22134","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.014253,37.270984],[-80.140462,37.200188],[-80.070075,37.161972],[-79.951504,37.194371],[-79.981403,37.233183],[-79.997931,37.266092],[-80.014253,37.270984]]]},"properties":{"id":"24018","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.942104,37.351965],[-79.939928,37.360566],[-79.945616,37.362997],[-79.942104,37.351965]]]},"properties":{"id":"24020","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.997931,37.266092],[-79.981403,37.233183],[-79.95224,37.256785],[-79.992574,37.273289],[-79.997931,37.266092]]]},"properties":{"id":"24015","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.800912,38.344473],[-78.746576,38.319422],[-78.72968,38.449093],[-78.769775,38.396212],[-78.800912,38.344473]]]},"properties":{"id":"22840","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.971691,38.465737],[-79.174112,38.566643],[-79.224341,38.477844],[-79.132871,38.426908],[-78.948221,38.397372],[-78.971172,38.465641],[-78.971691,38.465737]]]},"properties":{"id":"22821","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.694659,38.488604],[-78.811469,38.409782],[-78.769775,38.396212],[-78.72968,38.449093],[-78.694659,38.488604]]]},"properties":{"id":"22832","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.419235,38.925259],[-78.459509,38.945169],[-78.453011,38.982228],[-78.456162,39.009638],[-78.579064,38.95455],[-78.436708,38.876451],[-78.395431,38.915862],[-78.419235,38.925259]]]},"properties":{"id":"22644","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.408631,36.810815],[-81.273457,36.79469],[-81.189718,36.818849],[-81.223084,36.913724],[-81.33922,36.974917],[-81.379427,36.952417],[-81.409204,36.814927],[-81.408631,36.810815]]]},"properties":{"id":"24368","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.361258,36.569947],[-77.296875,36.544815],[-77.203548,36.544753],[-77.296276,36.628864],[-77.361258,36.569947]]]},"properties":{"id":"23828","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.889729,38.175675],[-77.789248,38.075944],[-77.732785,38.095861],[-77.62765,38.047087],[-77.593119,38.034447],[-77.560328,38.065347],[-77.506031,38.14611],[-77.506043,38.166615],[-77.561756,38.203858],[-77.713494,38.2841],[-77.834095,38.22949],[-77.889729,38.175675]]]},"properties":{"id":"22551","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.968529,37.037233],[-76.913527,37.030462],[-76.842112,37.124542],[-76.949254,37.110823],[-76.968529,37.037233]]]},"properties":{"id":"23839","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.625186,37.148927],[-81.600204,37.122295],[-81.367107,37.166589],[-81.295146,37.21165],[-81.393162,37.232061],[-81.402363,37.238756],[-81.486628,37.226632],[-81.522752,37.201982],[-81.53026,37.196999],[-81.625186,37.148927]]]},"properties":{"id":"24630","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.663329,37.202141],[-81.655533,37.178015],[-81.633831,37.191613],[-81.636632,37.203321],[-81.663329,37.202141]]]},"properties":{"id":"24601","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.039853,36.678063],[-82.087375,36.594685],[-81.878078,36.615327],[-81.853921,36.662434],[-81.897159,36.738797],[-82.039853,36.678063]]]},"properties":{"id":"24211","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.322313,36.71647],[-82.32476,36.685612],[-82.18433,36.750191],[-82.195504,36.772003],[-82.322313,36.71647]]]},"properties":{"id":"24270","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.99767,38.278046],[-77.061695,38.161631],[-77.042959,38.106969],[-76.936959,38.077074],[-76.93792,38.084903],[-76.905181,38.192774],[-76.99767,38.278046]]]},"properties":{"id":"22443","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.16019,36.825148],[-81.130219,36.767296],[-81.089176,36.76723],[-81.140222,36.843295],[-81.159914,36.84935],[-81.16019,36.825148]]]},"properties":{"id":"24322","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.506101,37.154129],[-76.412885,37.155808],[-76.400438,37.162947],[-76.394113,37.1688],[-76.417653,37.183727],[-76.396008,37.210022],[-76.383021,37.247755],[-76.468919,37.233871],[-76.545178,37.198273],[-76.525873,37.177045],[-76.506101,37.154129]]]},"properties":{"id":"23692","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.243382,36.595651],[-82.14607,36.594724],[-82.179637,36.626833],[-82.243382,36.595651]]]},"properties":{"id":"24201","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.219272,36.810797],[-76.25165,36.787026],[-76.295307,36.775138],[-76.29665,36.734343],[-76.098455,36.71675],[-76.143253,36.757045],[-76.219272,36.810797]]]},"properties":{"id":"23320","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.376634,36.550596],[-76.29665,36.734343],[-76.295307,36.775138],[-76.292828,36.797154],[-76.311061,36.796209],[-76.342065,36.791032],[-76.384826,36.795931],[-76.466768,36.746249],[-76.491497,36.550641],[-76.376634,36.550596]]]},"properties":{"id":"23323","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.304153,37.023595],[-76.303863,37.05332],[-76.328635,37.015494],[-76.322644,37.011369],[-76.304153,37.023595]]]},"properties":{"id":"23663","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.569243,37.123572],[-76.569011,37.17463],[-76.600701,37.17521],[-76.610984,37.167114],[-76.543994,37.074022],[-76.536874,37.074291],[-76.569243,37.123572]]]},"properties":{"id":"23604","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.284752,36.863276],[-76.274962,36.839847],[-76.243765,36.836862],[-76.236644,36.838083],[-76.247893,36.863289],[-76.277962,36.887847],[-76.279601,36.88871],[-76.286392,36.873414],[-76.284752,36.863276]]]},"properties":{"id":"23504","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.284752,36.863276],[-76.286392,36.873414],[-76.302202,36.873959],[-76.287981,36.860919],[-76.284752,36.863276]]]},"properties":{"id":"23517","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.334252,36.822094],[-76.366065,36.838248],[-76.383466,36.837548],[-76.384826,36.795931],[-76.342065,36.791032],[-76.33733,36.815829],[-76.334252,36.822094]]]},"properties":{"id":"23701","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.493902,37.572731],[-77.530272,37.559501],[-77.48388,37.531755],[-77.470292,37.562505],[-77.493902,37.572731]]]},"properties":{"id":"23221","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.022428,37.308156],[-80.014253,37.270984],[-79.997931,37.266092],[-79.992574,37.273289],[-79.952116,37.288553],[-79.974025,37.308779],[-80.022428,37.308156]]]},"properties":{"id":"24017","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.593968,36.870203],[-76.741229,36.7575],[-76.75581,36.746046],[-76.672206,36.634649],[-76.600603,36.550742],[-76.491497,36.550641],[-76.466768,36.746249],[-76.463398,36.77046],[-76.550117,36.837083],[-76.593968,36.870203]]]},"properties":{"id":"23434","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.537863,36.912395],[-76.593968,36.870203],[-76.550117,36.837083],[-76.506178,36.876907],[-76.521099,36.90633],[-76.537863,36.912395]]]},"properties":{"id":"23432","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.502845,36.913313],[-76.492473,36.886379],[-76.434789,36.935283],[-76.445301,36.941891],[-76.502845,36.913313]]]},"properties":{"id":"23433","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.856006,36.551235],[-76.600603,36.550742],[-76.672206,36.634649],[-76.856006,36.551235]]]},"properties":{"id":"23438","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.961898,36.776357],[-75.965282,36.775954],[-75.964093,36.772743],[-75.960842,36.773512],[-75.961898,36.776357]]]},"properties":{"id":"23461","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.032633,36.807376],[-76.027019,36.803307],[-76.022086,36.806942],[-76.030141,36.812778],[-76.032633,36.807376]]]},"properties":{"id":"23460","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.847821,37.630702],[-75.807599,37.622336],[-75.804164,37.636281],[-75.829352,37.656616],[-75.840403,37.653562],[-75.847821,37.630702]]]},"properties":{"id":"23422","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.532485,37.875016],[-75.643412,37.916687],[-75.646562,37.896451],[-75.596747,37.858458],[-75.571796,37.844651],[-75.564161,37.850402],[-75.530922,37.853234],[-75.532485,37.875016]]]},"properties":{"id":"23359","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.570583,37.943884],[-75.608586,37.927432],[-75.54947,37.922179],[-75.5239,37.931565],[-75.570583,37.943884]]]},"properties":{"id":"23416","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.461235,38.101011],[-78.440628,38.176751],[-78.450921,38.209556],[-78.474119,38.216661],[-78.554517,38.241182],[-78.54306,38.151216],[-78.461235,38.101011]]]},"properties":{"id":"22936","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.708,37.980957],[-78.586762,37.904636],[-78.575834,37.90923],[-78.474844,38.04283],[-78.662663,38.062232],[-78.742443,38.012067],[-78.708,37.980957]],[[-78.52665,38.026687],[-78.522172,38.037781],[-78.51333,38.037137],[-78.514211,38.028806],[-78.52665,38.026687]]]},"properties":{"id":"22903","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.654397,37.882041],[-78.741058,37.945082],[-78.751136,37.89818],[-78.697786,37.859533],[-78.654397,37.882041]]]},"properties":{"id":"22931","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.564763,37.871553],[-78.554832,37.835393],[-78.555286,37.86502],[-78.564763,37.871553]]]},"properties":{"id":"22946","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.674006,37.763052],[-79.61323,37.856986],[-79.650757,37.871566],[-79.858027,37.88831],[-79.887623,37.869932],[-79.885666,37.792127],[-79.852933,37.80693],[-79.823687,37.810605],[-79.822212,37.796808],[-79.790627,37.794114],[-79.795929,37.803222],[-79.785476,37.79943],[-79.784271,37.792422],[-79.674006,37.763052]]]},"properties":{"id":"24422","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.220984,37.627767],[-80.154692,37.601099],[-80.020519,37.647459],[-79.822212,37.796808],[-79.823687,37.810605],[-79.857554,37.79425],[-79.885666,37.792127],[-79.887623,37.869932],[-79.962284,37.953968],[-80.056056,37.951959],[-80.220984,37.627767]]]},"properties":{"id":"24426","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.152273,37.273227],[-78.047064,37.253915],[-78.188474,37.349265],[-78.231234,37.29622],[-78.152273,37.273227]]]},"properties":{"id":"23083","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.067163,37.713492],[-79.027333,37.702478],[-79.058971,37.713516],[-79.067163,37.713492]]],[[[-78.996631,37.701671],[-78.894111,37.623139],[-78.829884,37.649283],[-78.830121,37.663143],[-78.873044,37.722356],[-78.920779,37.7933],[-78.974494,37.741941],[-78.999003,37.718853],[-79.006501,37.720363],[-79.00804,37.714258],[-78.996631,37.701671]]]]},"properties":{"id":"22922","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.1597,37.418764],[-79.123282,37.399792],[-79.13972,37.419081],[-79.1597,37.418764]]],[[[-79.085707,37.332884],[-79.014973,37.295072],[-79.061809,37.393156],[-79.113672,37.397877],[-79.085707,37.332884]]]]},"properties":{"id":"24504","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.120672,38.882843],[-77.143637,38.870877],[-77.13756,38.866142],[-77.097538,38.871435],[-77.120672,38.882843]]]},"properties":{"id":"22203","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.487484,38.090497],[-79.447295,38.075024],[-79.362578,38.132871],[-79.427987,38.23047],[-79.512158,38.180419],[-79.487484,38.090497]]]},"properties":{"id":"24432","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.418832,37.969577],[-79.344226,38.000045],[-79.247149,37.974879],[-79.236332,37.98404],[-79.19352,38.037082],[-79.204531,38.076215],[-79.272744,38.09476],[-79.333099,38.041722],[-79.418832,37.969577]]]},"properties":{"id":"24459","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.854375,38.06576],[-79.798655,38.040123],[-79.816368,38.061959],[-79.854375,38.06576]]]},"properties":{"id":"24412","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.367612,37.177397],[-79.473648,37.063389],[-79.465146,37.060325],[-79.371723,37.113551],[-79.321913,37.120921],[-79.280223,37.185364],[-79.367612,37.177397]]]},"properties":{"id":"24571","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.280223,37.185364],[-79.144752,37.168346],[-79.146522,37.209397],[-79.219769,37.264195],[-79.238085,37.312326],[-79.281848,37.302089],[-79.360766,37.246657],[-79.367612,37.177397],[-79.280223,37.185364]]]},"properties":{"id":"24550","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.097041,37.235981],[-81.249045,37.222698],[-81.295146,37.21165],[-81.367107,37.166589],[-81.306946,37.077047],[-81.300422,37.05923],[-81.097041,37.235981]]]},"properties":{"id":"24314","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.912674,37.073349],[-80.668099,37.051464],[-80.63598,37.164765],[-80.613593,37.201541],[-80.699651,37.210578],[-80.854627,37.147482],[-81.107104,37.025101],[-80.912674,37.073349]]]},"properties":{"id":"24084","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.787055,37.601075],[-79.757345,37.42478],[-79.74858,37.43214],[-79.634201,37.45705],[-79.498553,37.532887],[-79.577929,37.598011],[-79.638945,37.634737],[-79.676014,37.657103],[-79.787055,37.601075]]]},"properties":{"id":"24066","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.895175,37.369572],[-79.864789,37.354618],[-79.835553,37.355448],[-79.817331,37.406256],[-80.012185,37.50305],[-80.073985,37.422305],[-80.090241,37.380346],[-79.949611,37.397658],[-79.909406,37.37871],[-79.895175,37.369572]]]},"properties":{"id":"24175","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.01811,36.87415],[-77.957294,36.947938],[-78.030236,36.951702],[-78.01811,36.87415]]]},"properties":{"id":"23938","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.219004,37.310014],[-82.248113,37.261708],[-82.210392,37.263791],[-82.190651,37.316887],[-82.219004,37.310014]]]},"properties":{"id":"24628","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.385096,37.733746],[-78.507881,37.677435],[-78.385566,37.631498],[-78.324226,37.714565],[-78.332344,37.715543],[-78.385096,37.733746]]]},"properties":{"id":"23004","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.280223,37.185364],[-79.321913,37.120921],[-79.215907,37.118921],[-79.144752,37.168346],[-79.280223,37.185364]]]},"properties":{"id":"24517","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.339616,38.20678],[-77.387239,38.034622],[-77.390625,38.026566],[-77.138682,38.048118],[-77.143743,38.111787],[-77.199728,38.1634],[-77.30028,38.22025],[-77.339616,38.20678]]]},"properties":{"id":"22427","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.576594,37.922584],[-77.548941,37.907996],[-77.540878,37.919865],[-77.576594,37.922584]]],[[[-77.576594,37.922584],[-77.593119,38.034447],[-77.62765,38.047087],[-77.685849,38.012076],[-77.715994,37.905277],[-77.59544,37.826267],[-77.549005,37.907789],[-77.576594,37.922584]]]]},"properties":{"id":"23015","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.591775,36.875992],[-80.496146,36.750472],[-80.415949,36.799123],[-80.517865,36.973955],[-80.56511,36.965138],[-80.605475,36.94235],[-80.591775,36.875992]]]},"properties":{"id":"24380","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.610414,36.851801],[-78.589325,36.821467],[-78.534026,36.850403],[-78.563575,36.876814],[-78.610414,36.851801]]]},"properties":{"id":"23976","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.466232,37.317206],[-77.512686,37.369954],[-77.673538,37.359035],[-77.679956,37.362207],[-77.729389,37.373718],[-77.813419,37.33673],[-77.741188,37.267895],[-77.599025,37.25279],[-77.456693,37.287904],[-77.466232,37.317206]]]},"properties":{"id":"23838","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.621883,37.462605],[-77.708946,37.497973],[-77.732376,37.506209],[-77.762624,37.484492],[-77.709242,37.399787],[-77.679956,37.362207],[-77.673538,37.359035],[-77.583081,37.430665],[-77.621883,37.462605]]]},"properties":{"id":"23112","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.083488,39.014981],[-78.004167,38.979479],[-77.989483,38.997106],[-77.957891,39.046348],[-77.960133,39.078927],[-78.090231,39.147638],[-78.108685,39.097489],[-78.083488,39.014981]],[[-78.0448,39.078384],[-78.03665,39.056563],[-78.059578,39.068543],[-78.0448,39.078384]]]},"properties":{"id":"22620","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.073985,37.422305],[-80.291445,37.388829],[-80.327898,37.366792],[-80.251963,37.301048],[-80.090241,37.380346],[-80.073985,37.422305]]]},"properties":{"id":"24070","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.820304,38.5289],[-77.75253,38.427448],[-77.761389,38.50909],[-77.837246,38.594599],[-77.875507,38.58306],[-77.857556,38.551829],[-77.820304,38.5289]]]},"properties":{"id":"22734","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.841937,38.37745],[-77.757499,38.385931],[-77.804068,38.421823],[-77.840751,38.437217],[-77.869733,38.402105],[-77.841937,38.37745]]]},"properties":{"id":"22726","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.102304,38.32784],[-77.993713,38.24124],[-77.967207,38.304975],[-77.976344,38.3552],[-78.048796,38.395084],[-78.115004,38.383172],[-78.102304,38.32784]]]},"properties":{"id":"22733","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.920731,38.669631],[-77.939514,38.636622],[-77.910684,38.561701],[-77.857556,38.551829],[-77.875507,38.58306],[-77.920731,38.669631]]]},"properties":{"id":"22724","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.920731,38.669631],[-77.875507,38.58306],[-77.837246,38.594599],[-77.802897,38.638462],[-77.812672,38.76322],[-77.827382,38.826981],[-77.906185,38.695827],[-77.920731,38.669631]]]},"properties":{"id":"20186","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.878899,38.467937],[-77.869733,38.402105],[-77.840751,38.437217],[-77.878899,38.467937]]]},"properties":{"id":"22741","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.366049,37.056124],[-82.368424,36.997703],[-82.315086,36.978746],[-82.310236,37.09651],[-82.360846,37.086875],[-82.366049,37.056124]]]},"properties":{"id":"24272","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.553844,37.202854],[-82.540314,37.120067],[-82.399298,37.096717],[-82.386922,37.121889],[-82.326826,37.198029],[-82.384386,37.259618],[-82.553844,37.202854]]]},"properties":{"id":"24228","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.513435,37.085148],[-82.563899,36.940046],[-82.633961,36.906397],[-82.627071,36.879469],[-82.618809,36.817645],[-82.406633,36.876356],[-82.356826,36.889908],[-82.368424,36.997703],[-82.366049,37.056124],[-82.399298,37.096717],[-82.540314,37.120067],[-82.513435,37.085148]]]},"properties":{"id":"24230","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.330988,37.196401],[-77.491985,37.111972],[-77.459384,37.065414],[-77.344562,37.051654],[-77.320295,37.184949],[-77.330988,37.196401]]]},"properties":{"id":"23805","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.306793,38.707403],[-77.263331,38.686558],[-77.254822,38.681167],[-77.245387,38.672129],[-77.225503,38.605617],[-77.134732,38.660006],[-77.170809,38.733561],[-77.183347,38.74521],[-77.203953,38.725094],[-77.252269,38.736456],[-77.306793,38.707403]]]},"properties":{"id":"22079","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.208216,38.761896],[-77.203953,38.725094],[-77.183347,38.74521],[-77.165059,38.767439],[-77.156461,38.780007],[-77.174621,38.791811],[-77.218782,38.792764],[-77.208216,38.761896]]]},"properties":{"id":"22150","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.395414,38.840767],[-77.415394,38.839104],[-77.43629,38.793207],[-77.380451,38.722516],[-77.375044,38.71305],[-77.359103,38.723668],[-77.365928,38.794607],[-77.395414,38.840767]]]},"properties":{"id":"20124","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.371303,39.014584],[-77.402509,38.982709],[-77.422422,38.962604],[-77.388809,38.953398],[-77.374265,38.953287],[-77.365334,38.966224],[-77.341465,38.998325],[-77.371303,39.014584]]]},"properties":{"id":"20170","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.165942,38.898224],[-77.198938,38.876031],[-77.159099,38.87475],[-77.153929,38.878963],[-77.159102,38.88321],[-77.165942,38.898224]]]},"properties":{"id":"22046","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.174621,38.791811],[-77.156461,38.780007],[-77.134491,38.798603],[-77.128337,38.821109],[-77.136462,38.837124],[-77.157683,38.836104],[-77.176092,38.811897],[-77.174621,38.791811]]]},"properties":{"id":"22312","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.056773,38.792573],[-77.073368,38.784155],[-77.058141,38.752059],[-77.041514,38.761038],[-77.041362,38.785415],[-77.056773,38.792573]]]},"properties":{"id":"22307","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.113365,38.847382],[-77.119302,38.851915],[-77.110731,38.843457],[-77.113365,38.847382]]],[[[-77.107754,38.832088],[-77.089551,38.817416],[-77.071537,38.811707],[-77.066443,38.830101],[-77.080252,38.843312],[-77.105073,38.8391],[-77.107754,38.832088]]]]},"properties":{"id":"22302","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.089551,38.817416],[-77.107754,38.832088],[-77.128337,38.821109],[-77.134491,38.798603],[-77.111983,38.801569],[-77.091249,38.80195],[-77.089551,38.817416]]]},"properties":{"id":"22304","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.307456,38.875201],[-77.30252,38.872264],[-77.300801,38.875172],[-77.305744,38.877114],[-77.307456,38.875201]]]},"properties":{"id":"22185","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.289847,37.76694],[-78.192186,37.735197],[-78.208965,37.796144],[-78.289847,37.76694]]]},"properties":{"id":"23055","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.833634,37.139112],[-79.911571,37.170254],[-79.951504,37.194371],[-80.070075,37.161972],[-80.08049,37.133845],[-80.026286,37.030357],[-79.923143,37.087866],[-79.833634,37.139112]]]},"properties":{"id":"24065","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.715333,37.109363],[-79.833634,37.139112],[-79.923143,37.087866],[-79.802661,37.071425],[-79.735591,37.036176],[-79.654818,37.042284],[-79.715333,37.109363]]]},"properties":{"id":"24184","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.245477,39.402777],[-78.279964,39.30143],[-78.29059,39.23332],[-78.198858,39.191046],[-78.137415,39.184839],[-78.083679,39.181877],[-78.128245,39.235339],[-78.108306,39.312187],[-78.245477,39.402777]]]},"properties":{"id":"22603","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.488136,37.238448],[-76.529503,37.263761],[-76.497751,37.240731],[-76.488136,37.238448]]]},"properties":{"id":"23062","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.780573,37.66829],[-77.769379,37.70721],[-77.782295,37.710454],[-77.787869,37.704864],[-77.780573,37.66829]]]},"properties":{"id":"23129","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.920144,38.117061],[-77.982102,37.844007],[-77.857243,37.756403],[-77.815524,37.767026],[-77.789248,38.075944],[-77.889729,38.175675],[-77.902361,38.164325],[-77.920144,38.117061]]]},"properties":{"id":"23117","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.856814,36.857737],[-78.956517,36.840576],[-79.032985,36.821696],[-79.121202,36.745481],[-78.825076,36.693435],[-78.794484,36.698992],[-78.839736,36.823963],[-78.840943,36.860536],[-78.856814,36.857737]]]},"properties":{"id":"24558","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.683865,36.835703],[-78.754075,37.008093],[-78.794862,36.955988],[-78.840943,36.860536],[-78.839736,36.823963],[-78.683865,36.835703]]]},"properties":{"id":"24534","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.121202,36.745481],[-79.032985,36.821696],[-79.093856,36.84447],[-79.207982,36.771807],[-79.171374,36.736711],[-79.121202,36.745481]]]},"properties":{"id":"24597","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.261752,36.692252],[-79.211377,36.57166],[-79.112144,36.614485],[-79.178165,36.708904],[-79.261752,36.692252]]]},"properties":{"id":"24594","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.880694,36.614745],[-79.112144,36.614485],[-79.211377,36.57166],[-79.218638,36.541639],[-78.880722,36.542042],[-78.880694,36.614745]]]},"properties":{"id":"24520","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.32401,37.569165],[-77.341991,37.536472],[-77.342475,37.534765],[-77.29528,37.560448],[-77.32401,37.569165]]]},"properties":{"id":"23075","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.340424,37.498954],[-77.3001,37.489267],[-77.304251,37.511506],[-77.340424,37.498954]]]},"properties":{"id":"23250","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.674075,36.829238],[-79.614617,36.739648],[-79.568416,36.749812],[-79.632829,36.852698],[-79.674075,36.829238]]]},"properties":{"id":"24530","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.511306,38.516996],[-79.669128,38.510952],[-79.795921,38.265552],[-79.58244,38.330306],[-79.51491,38.3933],[-79.476396,38.457159],[-79.511306,38.516996]]]},"properties":{"id":"24465","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.669128,38.510952],[-79.511306,38.516996],[-79.649075,38.591515],[-79.669128,38.510952]]]},"properties":{"id":"24413","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.977375,36.870399],[-76.954443,36.798575],[-76.819642,36.892781],[-76.742255,36.939026],[-76.791086,37.022492],[-76.849684,36.996211],[-76.977375,36.870399]]]},"properties":{"id":"23866","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.77467,37.691642],[-76.754818,37.638127],[-76.719447,37.607915],[-76.680392,37.617085],[-76.733303,37.68878],[-76.73896,37.694777],[-76.77467,37.691642]]]},"properties":{"id":"23108","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.654076,37.603174],[-76.680392,37.617085],[-76.719447,37.607915],[-76.782319,37.501597],[-76.741984,37.467076],[-76.712305,37.431309],[-76.647892,37.492231],[-76.654076,37.603174]]]},"properties":{"id":"23156","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.864325,37.504541],[-76.792184,37.517594],[-76.821577,37.605795],[-76.878953,37.656459],[-76.916151,37.686477],[-76.952077,37.688116],[-76.970441,37.694294],[-76.958905,37.574009],[-76.973653,37.541181],[-76.864325,37.504541]]]},"properties":{"id":"23181","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.592585,39.182928],[-77.612018,39.141953],[-77.612379,39.140792],[-77.511209,39.085911],[-77.464105,39.07945],[-77.546471,39.272228],[-77.604792,39.22711],[-77.592585,39.182928]]]},"properties":{"id":"20176","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.65963,39.216508],[-77.660654,39.177222],[-77.611477,39.17858],[-77.592585,39.182928],[-77.604792,39.22711],[-77.65963,39.216508]]]},"properties":{"id":"20197","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.365028,39.036218],[-77.328318,39.057678],[-77.443625,39.07122],[-77.432464,39.046727],[-77.401564,39.030176],[-77.365028,39.036218]]]},"properties":{"id":"20165","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.221133,38.424222],[-78.183358,38.382783],[-78.140141,38.437106],[-78.221133,38.424222]]]},"properties":{"id":"22715","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.345013,37.48727],[-76.317006,37.496341],[-76.349604,37.525536],[-76.345013,37.48727]]]},"properties":{"id":"23068","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.012833,37.88505],[-79.141939,37.813264],[-78.992212,37.892053],[-79.012833,37.88505]]]},"properties":{"id":"22976","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.333569,37.880911],[-76.365948,37.875771],[-76.363362,37.84673],[-76.330796,37.849917],[-76.333569,37.880911]]]},"properties":{"id":"22432","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.047064,37.253915],[-78.152273,37.273227],[-78.197281,37.077927],[-78.122552,37.035865],[-77.993706,37.219677],[-78.047064,37.253915]]]},"properties":{"id":"23930","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.231234,37.29622],[-78.301282,37.22241],[-78.229944,37.096868],[-78.197281,37.077927],[-78.152273,37.273227],[-78.231234,37.29622]]]},"properties":{"id":"23922","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.84255,38.257214],[-77.927449,38.300445],[-77.967207,38.304975],[-77.993713,38.24124],[-77.902361,38.164325],[-77.889729,38.175675],[-77.834095,38.22949],[-77.84255,38.257214]]]},"properties":{"id":"22567","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.739229,38.300758],[-78.641931,38.291435],[-78.622763,38.301904],[-78.559814,38.331235],[-78.452918,38.475508],[-78.438994,38.516948],[-78.554022,38.520788],[-78.682121,38.506104],[-78.694659,38.488604],[-78.72968,38.449093],[-78.746576,38.319422],[-78.739229,38.300758]]]},"properties":{"id":"22827","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.333362,38.569633],[-77.301104,38.54952],[-77.281803,38.529119],[-77.250607,38.578432],[-77.314362,38.597439],[-77.333362,38.569633]]]},"properties":{"id":"22026","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.359853,38.681165],[-77.293102,38.628172],[-77.245387,38.672129],[-77.254822,38.681167],[-77.260616,38.677551],[-77.263331,38.686558],[-77.306793,38.707403],[-77.359103,38.723668],[-77.375044,38.71305],[-77.359853,38.681165]]]},"properties":{"id":"22192","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.770991,36.912601],[-80.789371,36.932965],[-80.821721,36.923142],[-80.785064,36.892134],[-80.770991,36.912601]]]},"properties":{"id":"24313","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.524486,37.631699],[-79.50639,37.71714],[-79.638945,37.634737],[-79.577929,37.598011],[-79.524486,37.631699]]]},"properties":{"id":"24578","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.706133,38.5808],[-78.763884,38.709537],[-78.785866,38.716076],[-78.800176,38.714201],[-78.706133,38.5808]]]},"properties":{"id":"22853","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.967908,38.545417],[-78.896663,38.545875],[-78.88998,38.593477],[-78.900406,38.610089],[-78.967908,38.545417]]]},"properties":{"id":"22850","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.219635,36.983158],[-82.305585,36.963216],[-82.356826,36.889908],[-82.406633,36.876356],[-82.381591,36.828083],[-82.344758,36.748507],[-82.194281,36.926209],[-82.219635,36.983158]]]},"properties":{"id":"24224","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.740895,36.830708],[-81.705063,36.665879],[-81.645107,36.684607],[-81.468498,36.715207],[-81.496072,36.733488],[-81.694968,36.854453],[-81.740895,36.830708]]]},"properties":{"id":"24319","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.685897,38.359614],[-77.561756,38.203858],[-77.506043,38.166615],[-77.511659,38.279985],[-77.530289,38.309172],[-77.618727,38.367835],[-77.685897,38.359614]]]},"properties":{"id":"22407","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.835072,37.085606],[-81.83484,37.098118],[-81.844917,37.097261],[-81.835072,37.085606]]]},"properties":{"id":"24612","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.677919,36.933997],[-81.65313,36.950987],[-81.658339,36.970983],[-81.698788,36.959598],[-81.677919,36.933997]]]},"properties":{"id":"24316","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18433,36.750191],[-82.039853,36.678063],[-81.897159,36.738797],[-81.904351,36.831534],[-81.946985,36.864815],[-82.195504,36.772003],[-82.18433,36.750191]]]},"properties":{"id":"24210","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.790803,36.701308],[-81.791068,36.8602],[-81.904351,36.831534],[-81.897159,36.738797],[-81.853921,36.662434],[-81.790803,36.701308]]]},"properties":{"id":"24361","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.675419,37.060949],[-82.563899,36.940046],[-82.513435,37.085148],[-82.675419,37.060949]]]},"properties":{"id":"24293","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.322644,37.011369],[-76.299365,36.968552],[-76.298956,36.968563],[-76.288698,37.034504],[-76.304153,37.023595],[-76.322644,37.011369]]]},"properties":{"id":"23651","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.525873,37.177045],[-76.569011,37.17463],[-76.569243,37.123572],[-76.506101,37.154129],[-76.525873,37.177045]]]},"properties":{"id":"23608","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.284752,36.863276],[-76.287981,36.860919],[-76.313533,36.858533],[-76.306663,36.854047],[-76.29796,36.846412],[-76.295863,36.843847],[-76.274962,36.839847],[-76.284752,36.863276]]]},"properties":{"id":"23510","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.247893,36.863289],[-76.256485,36.904534],[-76.277962,36.887847],[-76.247893,36.863289]]]},"properties":{"id":"23509","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.94087,37.266921],[-79.938336,37.259025],[-79.904639,37.266275],[-79.91783,37.27954],[-79.935977,37.272447],[-79.94087,37.266921]]]},"properties":{"id":"24013","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.06147,36.815692],[-76.101808,36.805339],[-76.126927,36.798298],[-76.04407,36.780022],[-76.06147,36.815692]]]},"properties":{"id":"23453","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.057922,36.917052],[-75.993052,36.915147],[-76.038691,36.931196],[-76.057922,36.917052]]]},"properties":{"id":"23459","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.442,38.001099],[-75.440476,37.946786],[-75.424089,37.952913],[-75.442,38.001099]]]},"properties":{"id":"23395","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.857456,38.03263],[-78.899053,37.948862],[-78.845531,37.91216],[-78.751136,37.89818],[-78.741058,37.945082],[-78.708,37.980957],[-78.742443,38.012067],[-78.753078,38.023894],[-78.80043,38.028648],[-78.770891,38.05522],[-78.793153,38.073244],[-78.857456,38.03263]]]},"properties":{"id":"22920","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.741058,37.945082],[-78.654397,37.882041],[-78.618732,37.882518],[-78.586762,37.904636],[-78.708,37.980957],[-78.741058,37.945082]]]},"properties":{"id":"22959","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.61242,38.122525],[-78.663174,38.277923],[-78.749354,38.206621],[-78.772125,38.153356],[-78.774172,38.149539],[-78.793153,38.073244],[-78.770891,38.05522],[-78.753078,38.023894],[-78.742443,38.012067],[-78.662663,38.062232],[-78.61242,38.122525]]]},"properties":{"id":"22932","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.358845,38.290023],[-78.450921,38.209556],[-78.440628,38.176751],[-78.407173,38.18934],[-78.316955,38.268702],[-78.358845,38.290023]]]},"properties":{"id":"22968","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.192059,37.951246],[-78.219522,37.970743],[-78.270371,38.014644],[-78.396745,38.001459],[-78.40006,38.006921],[-78.351257,37.955882],[-78.192059,37.951246]]]},"properties":{"id":"22974","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.822212,37.796808],[-80.020519,37.647459],[-80.016047,37.60886],[-79.994953,37.629457],[-79.980646,37.623044],[-80.002927,37.591382],[-79.987845,37.570764],[-79.787055,37.601075],[-79.676014,37.657103],[-79.674006,37.763052],[-79.784271,37.792422],[-79.790627,37.794114],[-79.822212,37.796808]]]},"properties":{"id":"24085","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.616226,37.368058],[-78.491743,37.269625],[-78.594436,37.397974],[-78.616226,37.368058]]]},"properties":{"id":"23960","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.905543,37.401116],[-78.967117,37.340722],[-78.884004,37.246571],[-78.905543,37.401116]]]},"properties":{"id":"24593","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.080981,38.887124],[-77.083292,38.901655],[-77.11869,38.885362],[-77.120672,38.882843],[-77.097538,38.871435],[-77.083305,38.877096],[-77.080981,38.887124]]]},"properties":{"id":"22201","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.083292,38.901655],[-77.080981,38.887124],[-77.070469,38.901104],[-77.082643,38.90228],[-77.083292,38.901655]]]},"properties":{"id":"22209","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.140571,38.01921],[-79.123704,37.958703],[-79.052591,37.941579],[-78.949678,38.028743],[-79.017917,38.062063],[-79.140571,38.01921]]]},"properties":{"id":"24477","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.944814,38.157032],[-78.999269,38.075527],[-79.017917,38.062063],[-78.949678,38.028743],[-78.857456,38.03263],[-78.793153,38.073244],[-78.774172,38.149539],[-78.879172,38.164436],[-78.915551,38.181678],[-78.944814,38.157032]]]},"properties":{"id":"22980","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.03027,38.237918],[-78.94103,38.197013],[-78.999427,38.240568],[-79.03027,38.237918]]]},"properties":{"id":"24482","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.093135,38.2738],[-79.244581,38.3358],[-79.295744,38.213253],[-79.167369,38.205764],[-79.093135,38.2738]]]},"properties":{"id":"24421","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.447295,38.075024],[-79.333099,38.041722],[-79.272744,38.09476],[-79.300495,38.112242],[-79.299093,38.10769],[-79.318412,38.104263],[-79.338242,38.141197],[-79.362578,38.132871],[-79.447295,38.075024]]]},"properties":{"id":"24430","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.236332,37.98404],[-79.208936,37.953334],[-79.123704,37.958703],[-79.140571,38.01921],[-79.19352,38.037082],[-79.236332,37.98404]]]},"properties":{"id":"24440","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.164038,37.32912],[-79.233256,37.395743],[-79.244174,37.387322],[-79.281848,37.302089],[-79.238085,37.312326],[-79.169191,37.323213],[-79.164038,37.32912]]]},"properties":{"id":"24502","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.099234,37.240215],[-80.904154,37.256645],[-80.703805,37.361634],[-80.713545,37.3915],[-80.783523,37.393053],[-80.821588,37.372409],[-80.85881,37.357199],[-80.980146,37.292743],[-81.099234,37.240215]]]},"properties":{"id":"24124","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.739702,36.865125],[-77.876248,36.805843],[-77.92246,36.81295],[-77.878273,36.700472],[-77.871954,36.647603],[-77.866611,36.63018],[-77.868454,36.622233],[-77.853423,36.578626],[-77.744203,36.579913],[-77.719977,36.710541],[-77.720545,36.842492],[-77.739702,36.865125]]]},"properties":{"id":"23868","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.871954,36.647603],[-77.886917,36.643198],[-77.866611,36.63018],[-77.871954,36.647603]]],[[[-77.905956,36.612972],[-77.934029,36.563194],[-77.930646,36.544573],[-77.867983,36.544878],[-77.853423,36.578626],[-77.868454,36.622233],[-77.889182,36.622345],[-77.905956,36.612972]]]]},"properties":{"id":"23857","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.859782,37.253393],[-81.854387,37.174994],[-81.826846,37.142436],[-81.77153,37.115264],[-81.72704,37.136606],[-81.733298,37.238128],[-81.833785,37.283702],[-81.859782,37.253393]]]},"properties":{"id":"24622","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.551,37.176612],[-78.527241,37.175354],[-78.482416,37.145419],[-78.385798,37.202967],[-78.361145,37.235276],[-78.217181,37.397238],[-78.393102,37.439735],[-78.61534,37.394165],[-78.594436,37.397974],[-78.491743,37.269625],[-78.537867,37.214993],[-78.551,37.176612]],[[-78.396691,37.301589],[-78.394012,37.299752],[-78.398894,37.299381],[-78.396691,37.301589]],[[-78.45962,37.242134],[-78.454327,37.248886],[-78.449272,37.245288],[-78.455429,37.240658],[-78.45962,37.242134]]]},"properties":{"id":"23901","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.264188,37.599717],[-78.393102,37.439735],[-78.217181,37.397238],[-78.207182,37.406499],[-78.149154,37.45279],[-78.098973,37.583523],[-78.188224,37.630299],[-78.2193,37.62697],[-78.264188,37.599717]]]},"properties":{"id":"23040","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983746,36.863112],[-81.140222,36.843295],[-81.089176,36.76723],[-81.089523,36.765117],[-80.962667,36.750719],[-80.932179,36.776928],[-80.917969,36.801499],[-80.983746,36.863112]]]},"properties":{"id":"24350","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.570534,36.676412],[-80.767372,36.679978],[-80.78444,36.6495],[-80.769348,36.603334],[-80.739535,36.619028],[-80.616817,36.64844],[-80.576894,36.66136],[-80.560649,36.671456],[-80.570534,36.676412]]]},"properties":{"id":"24328","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.496146,36.750472],[-80.591775,36.875992],[-80.663457,36.883467],[-80.66384,36.883028],[-80.602141,36.742215],[-80.496146,36.750472]]]},"properties":{"id":"24325","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.496146,36.750472],[-80.513153,36.672444],[-80.483384,36.642977],[-80.410985,36.64762],[-80.369444,36.669975],[-80.341877,36.78777],[-80.344626,36.801483],[-80.415949,36.799123],[-80.496146,36.750472]]]},"properties":{"id":"24120","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.483384,36.642977],[-80.576894,36.66136],[-80.616817,36.64844],[-80.612028,36.557833],[-80.53466,36.554717],[-80.410985,36.64762],[-80.483384,36.642977]]]},"properties":{"id":"24053","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.534026,36.850403],[-78.589325,36.821467],[-78.572476,36.792184],[-78.452228,36.735848],[-78.316735,36.72988],[-78.289962,36.782913],[-78.28956,36.827792],[-78.422666,36.905566],[-78.534026,36.850403]]]},"properties":{"id":"23924","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.611493,37.505078],[-77.55551,37.445748],[-77.522737,37.467839],[-77.525168,37.473128],[-77.538597,37.559416],[-77.570641,37.557957],[-77.59611,37.55572],[-77.611493,37.505078]]]},"properties":{"id":"23235","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.633783,37.558623],[-77.707944,37.589175],[-77.720084,37.587424],[-77.732376,37.506209],[-77.708946,37.497973],[-77.629143,37.492493],[-77.611493,37.505078],[-77.59611,37.55572],[-77.633783,37.558623]]]},"properties":{"id":"23113","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.427682,37.436569],[-77.502177,37.409963],[-77.489819,37.376183],[-77.408587,37.377776],[-77.386308,37.386622],[-77.427682,37.436569]]]},"properties":{"id":"23237","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.156098,39.108465],[-78.167205,39.017703],[-78.083488,39.014981],[-78.108685,39.097489],[-78.156098,39.108465]]]},"properties":{"id":"22663","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.154692,37.601099],[-80.220984,37.627767],[-80.282892,37.532783],[-80.154692,37.601099]]]},"properties":{"id":"24131","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.957878,38.558198],[-77.878899,38.467937],[-77.820304,38.5289],[-77.857556,38.551829],[-77.910684,38.561701],[-77.957878,38.558198]]]},"properties":{"id":"22714","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.180429,38.52682],[-78.212192,38.51468],[-78.237282,38.476148],[-78.209636,38.466771],[-78.222455,38.447721],[-78.221133,38.424222],[-78.140141,38.437106],[-78.11571,38.49338],[-78.180429,38.52682]]]},"properties":{"id":"22735","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.360846,37.086875],[-82.310236,37.09651],[-82.305396,37.12202],[-82.285395,37.157386],[-82.28457,37.176244],[-82.326826,37.198029],[-82.386922,37.121889],[-82.360846,37.086875]]]},"properties":{"id":"24226","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.829029,37.097579],[-77.775838,37.081904],[-77.795926,37.192592],[-77.829029,37.097579]]]},"properties":{"id":"23894","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.942655,37.796917],[-76.876319,37.782147],[-76.728213,37.822236],[-76.810924,37.913467],[-76.936426,37.827703],[-76.941511,37.80335],[-76.942655,37.796917]]]},"properties":{"id":"22454","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.496855,38.887171],[-77.405266,38.860214],[-77.417272,38.904122],[-77.429278,38.955744],[-77.440383,38.944407],[-77.496855,38.887171]]]},"properties":{"id":"20151","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.222581,38.877356],[-77.198938,38.876031],[-77.165942,38.898224],[-77.159681,38.903147],[-77.196544,38.923308],[-77.217394,38.910169],[-77.216046,38.90346],[-77.219203,38.887542],[-77.222581,38.877356]]]},"properties":{"id":"22043","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.08454,38.785906],[-77.111983,38.801569],[-77.134491,38.798603],[-77.156461,38.780007],[-77.165059,38.767439],[-77.121508,38.767131],[-77.08454,38.785906]]]},"properties":{"id":"22310","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.325525,38.800705],[-77.276986,38.755538],[-77.263606,38.772549],[-77.247791,38.795932],[-77.256497,38.81078],[-77.266092,38.811525],[-77.325525,38.800705]]]},"properties":{"id":"22015","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.119302,38.851915],[-77.131849,38.861727],[-77.174704,38.846474],[-77.157683,38.836104],[-77.136462,38.837124],[-77.110731,38.843457],[-77.119302,38.851915]]]},"properties":{"id":"22041","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.29785,38.870924],[-77.294858,38.84415],[-77.251483,38.838551],[-77.226167,38.859604],[-77.227069,38.876798],[-77.22818,38.883549],[-77.267269,38.878592],[-77.283295,38.875],[-77.29785,38.870924]]]},"properties":{"id":"22031","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.811999,39.02291],[-77.820461,38.98087],[-77.810464,38.952629],[-77.671815,38.946576],[-77.6483,39.03522],[-77.696743,39.042003],[-77.766685,39.043086],[-77.811999,39.02291]],[[-77.736773,38.96852],[-77.736329,38.967773],[-77.738193,38.967089],[-77.738573,38.967817],[-77.736773,38.96852]]]},"properties":{"id":"20117","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.680325,38.652919],[-77.717519,38.657844],[-77.684227,38.635555],[-77.680325,38.652919]]]},"properties":{"id":"20139","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.559458,38.597343],[-77.69564,38.724779],[-77.680325,38.652919],[-77.684227,38.635555],[-77.560836,38.527082],[-77.559458,38.597343]]]},"properties":{"id":"20119","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.289847,37.76694],[-78.332344,37.715543],[-78.324226,37.714565],[-78.239748,37.690495],[-78.192186,37.735197],[-78.289847,37.76694]]]},"properties":{"id":"23022","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.385096,37.733746],[-78.332344,37.715543],[-78.289847,37.76694],[-78.208965,37.796144],[-78.156008,37.825924],[-78.170773,37.935407],[-78.192059,37.951246],[-78.351257,37.955882],[-78.388198,37.912882],[-78.385096,37.733746]]]},"properties":{"id":"22963","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.170773,37.935407],[-78.025868,37.827812],[-77.999776,37.825433],[-77.982102,37.844007],[-77.920144,38.117061],[-78.054815,38.139125],[-78.219522,37.970743],[-78.192059,37.951246],[-78.170773,37.935407]]]},"properties":{"id":"23093","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.855806,36.830339],[-79.920138,36.75014],[-79.92661,36.696556],[-80.01763,36.691772],[-80.020906,36.636712],[-79.956643,36.62509],[-79.831433,36.646321],[-79.717862,36.818754],[-79.744158,36.860355],[-79.855806,36.830339]]]},"properties":{"id":"24112","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.198858,39.191046],[-78.29059,39.23332],[-78.403907,39.167738],[-78.437732,39.13683],[-78.393085,39.100243],[-78.325774,39.077805],[-78.304671,39.086277],[-78.156098,39.108465],[-78.108685,39.097489],[-78.090231,39.147638],[-78.073162,39.178388],[-78.083679,39.181877],[-78.137415,39.184839],[-78.170885,39.143814],[-78.198858,39.191046]]]},"properties":{"id":"22602","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.677449,38.927908],[-78.632038,38.904685],[-78.579064,38.95455],[-78.456162,39.009638],[-78.431499,39.065769],[-78.394156,39.092748],[-78.393085,39.100243],[-78.437732,39.13683],[-78.677449,38.927908]]]},"properties":{"id":"22654","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.304671,39.086277],[-78.213488,38.997897],[-78.167205,39.017703],[-78.156098,39.108465],[-78.304671,39.086277]]]},"properties":{"id":"22655","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.703805,37.361634],[-80.904154,37.256645],[-80.854627,37.147482],[-80.699651,37.210578],[-80.606874,37.244911],[-80.606768,37.24496],[-80.602562,37.266232],[-80.615719,37.286234],[-80.6181,37.257631],[-80.670453,37.268039],[-80.639981,37.292294],[-80.677828,37.330292],[-80.703805,37.361634]],[[-80.711806,37.266771],[-80.73931,37.228266],[-80.764714,37.252791],[-80.711806,37.266771]]]},"properties":{"id":"24134","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.713545,37.3915],[-80.703805,37.361634],[-80.677828,37.330292],[-80.522711,37.412014],[-80.496805,37.446215],[-80.713545,37.3915]]]},"properties":{"id":"24150","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.615719,37.286234],[-80.639981,37.292294],[-80.670453,37.268039],[-80.6181,37.257631],[-80.615719,37.286234]]]},"properties":{"id":"24086","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.449119,37.518037],[-76.514015,37.548613],[-76.647892,37.492231],[-76.712305,37.431309],[-76.658651,37.380139],[-76.586197,37.302679],[-76.357058,37.332606],[-76.360537,37.335305],[-76.374667,37.349583],[-76.394742,37.37631],[-76.462894,37.496706],[-76.449119,37.518037]]]},"properties":{"id":"23061","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.769379,37.70721],[-77.780573,37.66829],[-77.822397,37.610059],[-77.720084,37.587424],[-77.707944,37.589175],[-77.650862,37.624212],[-77.675127,37.677394],[-77.769379,37.70721]]]},"properties":{"id":"23103","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.942208,37.762179],[-77.923118,37.757809],[-77.871323,37.758379],[-77.857243,37.756403],[-77.982102,37.844007],[-77.999776,37.825433],[-77.989476,37.807236],[-77.942208,37.762179]]]},"properties":{"id":"23065","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.6469,36.611918],[-81.522715,36.580615],[-81.525935,36.655883],[-81.605835,36.638717],[-81.6469,36.611918]]]},"properties":{"id":"24292","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.825076,36.693435],[-79.121202,36.745481],[-79.171374,36.736711],[-79.178165,36.708904],[-79.112144,36.614485],[-78.880694,36.614745],[-78.825076,36.693435]]]},"properties":{"id":"24592","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.216423,37.688885],[-77.406257,37.611851],[-77.3828,37.595322],[-77.32401,37.569165],[-77.29528,37.560448],[-77.228504,37.536398],[-77.18087,37.58812],[-77.123675,37.626881],[-77.137637,37.666964],[-77.216423,37.688885]]]},"properties":{"id":"23111","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.470292,37.562505],[-77.462824,37.575815],[-77.479463,37.589326],[-77.517241,37.607111],[-77.521525,37.606391],[-77.493902,37.572731],[-77.470292,37.562505]]]},"properties":{"id":"23230","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.988154,36.542333],[-79.714855,36.541938],[-79.831433,36.646321],[-79.956643,36.62509],[-79.988154,36.542333]]]},"properties":{"id":"24148","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.020906,36.636712],[-80.05467,36.623689],[-80.101894,36.620135],[-80.096685,36.592573],[-80.125703,36.542686],[-79.988154,36.542333],[-79.956643,36.62509],[-80.020906,36.636712]]]},"properties":{"id":"24165","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.51491,38.3933],[-79.58244,38.330306],[-79.648063,38.212532],[-79.48083,38.283002],[-79.417022,38.407362],[-79.51491,38.3933]]]},"properties":{"id":"24458","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.886467,36.777964],[-76.855654,36.674438],[-76.75581,36.746046],[-76.741229,36.7575],[-76.825955,36.784352],[-76.886467,36.777964]]]},"properties":{"id":"23315","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.486198,36.988604],[-76.53593,37.023699],[-76.574013,37.001166],[-76.562379,36.994328],[-76.537863,36.912395],[-76.521099,36.90633],[-76.502845,36.913313],[-76.445301,36.941891],[-76.486198,36.988604]]]},"properties":{"id":"23314","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.574013,37.001166],[-76.572938,36.993961],[-76.562379,36.994328],[-76.574013,37.001166]]]},"properties":{"id":"23304","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.861303,37.445522],[-76.872304,37.338444],[-76.776893,37.433923],[-76.861303,37.445522]]]},"properties":{"id":"23168","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.872304,37.338444],[-76.861303,37.445522],[-76.864325,37.504541],[-76.973653,37.541181],[-76.981754,37.489688],[-76.975988,37.41886],[-76.874206,37.340167],[-76.872304,37.338444]]]},"properties":{"id":"23089","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.017698,37.773369],[-77.075533,37.76606],[-77.081215,37.763702],[-76.970441,37.694294],[-76.952077,37.688116],[-76.94897,37.741611],[-77.017698,37.773369]]]},"properties":{"id":"23177","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.391538,37.676213],[-76.435666,37.654022],[-76.443813,37.628423],[-76.400406,37.612086],[-76.388641,37.607319],[-76.328283,37.592109],[-76.322858,37.681857],[-76.391538,37.676213]]]},"properties":{"id":"22578","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.367102,37.770132],[-76.421512,37.730904],[-76.413909,37.679395],[-76.391538,37.676213],[-76.322858,37.681857],[-76.306744,37.792803],[-76.310126,37.789728],[-76.327057,37.792617],[-76.367102,37.770132]]]},"properties":{"id":"22482","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.031152,36.785349],[-83.099068,36.812781],[-83.13512,36.742629],[-82.965579,36.696764],[-82.933,36.735697],[-82.985332,36.792352],[-83.031152,36.785349]]]},"properties":{"id":"24277","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.612379,39.140792],[-77.682452,39.091989],[-77.696743,39.042003],[-77.6483,39.03522],[-77.557484,38.979111],[-77.530888,39.043563],[-77.511209,39.085911],[-77.612379,39.140792]]]},"properties":{"id":"20175","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.482416,37.145419],[-78.317073,37.016553],[-78.285992,37.044606],[-78.385798,37.202967],[-78.482416,37.145419]]]},"properties":{"id":"23954","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.267714,38.335388],[-78.23016,38.285213],[-78.207377,38.31337],[-78.201216,38.326106],[-78.172933,38.360592],[-78.185901,38.369125],[-78.240872,38.336645],[-78.267714,38.335388]]]},"properties":{"id":"22709","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.25005,37.368455],[-76.309309,37.402137],[-76.341754,37.374588],[-76.360537,37.335305],[-76.357058,37.332606],[-76.307308,37.282058],[-76.291037,37.294591],[-76.292668,37.348248],[-76.326576,37.353267],[-76.312534,37.37147],[-76.289335,37.363455],[-76.266609,37.338934],[-76.25005,37.368455]]]},"properties":{"id":"23138","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.353796,37.482171],[-76.306305,37.462256],[-76.254963,37.466844],[-76.309166,37.488337],[-76.312028,37.487588],[-76.317006,37.496341],[-76.345013,37.48727],[-76.353796,37.482171]]]},"properties":{"id":"23076","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.374667,37.349583],[-76.355898,37.402545],[-76.375834,37.42034],[-76.386998,37.421101],[-76.394742,37.37631],[-76.374667,37.349583]]]},"properties":{"id":"23021","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.309166,37.488337],[-76.309003,37.492457],[-76.29567,37.494216],[-76.301794,37.500298],[-76.312018,37.492721],[-76.312028,37.487588],[-76.309166,37.488337]]]},"properties":{"id":"23064","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.387693,37.570845],[-76.35694,37.527987],[-76.328283,37.592109],[-76.388641,37.607319],[-76.387693,37.570845]]]},"properties":{"id":"23043","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.406817,37.549572],[-76.393632,37.530841],[-76.356933,37.527725],[-76.35694,37.527987],[-76.387693,37.570845],[-76.406817,37.549572]]]},"properties":{"id":"23070","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.503304,37.571808],[-76.500681,37.626509],[-76.530404,37.632147],[-76.508767,37.569782],[-76.503304,37.571808]]]},"properties":{"id":"23092","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.999655,37.313973],[-75.99974,37.301247],[-75.983737,37.295112],[-75.985311,37.318626],[-75.999655,37.313973]]]},"properties":{"id":"23316","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.537078,38.032448],[-76.631933,37.977089],[-76.648082,37.968396],[-76.619596,37.959176],[-76.547446,37.914561],[-76.520823,37.941668],[-76.537078,38.032448]]]},"properties":{"id":"22435","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.386778,38.556518],[-78.612102,38.643052],[-78.641536,38.604939],[-78.554022,38.520788],[-78.438994,38.516948],[-78.386778,38.556518]]]},"properties":{"id":"22851","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.350133,38.807098],[-78.313752,38.73719],[-78.284877,38.759229],[-78.350133,38.807098]]]},"properties":{"id":"22650","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.128037,36.729482],[-80.144901,36.630382],[-80.101894,36.620135],[-80.05467,36.623689],[-80.128037,36.729482]]]},"properties":{"id":"24133","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.263331,38.686558],[-77.260616,38.677551],[-77.254822,38.681167],[-77.263331,38.686558]]]},"properties":{"id":"22125","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.668099,37.051464],[-80.912674,37.073349],[-80.898057,37.03878],[-80.849683,36.992054],[-80.715647,36.992735],[-80.666153,37.02888],[-80.668099,37.051464]]]},"properties":{"id":"24301","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.613593,37.201541],[-80.601675,37.202283],[-80.606874,37.244911],[-80.699651,37.210578],[-80.613593,37.201541]]]},"properties":{"id":"24132","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.050597,38.803096],[-78.200764,38.776665],[-78.255862,38.758257],[-78.25986,38.734974],[-78.079222,38.721993],[-78.02814,38.759658],[-78.050597,38.803096]]]},"properties":{"id":"22627","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.050597,38.803096],[-78.105838,38.837869],[-78.131175,38.861697],[-78.125096,38.842008],[-78.151423,38.848482],[-78.170932,38.823837],[-78.200764,38.776665],[-78.050597,38.803096]]]},"properties":{"id":"22640","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.507828,37.838814],[-76.547446,37.914561],[-76.619596,37.959176],[-76.679914,37.825344],[-76.694832,37.80285],[-76.668182,37.778386],[-76.507828,37.838814]]]},"properties":{"id":"22460","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.524486,37.631699],[-79.436959,37.617008],[-79.436926,37.617004],[-79.432487,37.616361],[-79.426182,37.678093],[-79.50639,37.71714],[-79.524486,37.631699]]]},"properties":{"id":"24555","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.318625,37.919484],[-79.367148,37.874929],[-79.347906,37.825242],[-79.311154,37.814732],[-79.233042,37.882966],[-79.315,37.929382],[-79.318625,37.919484]]]},"properties":{"id":"24435","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.746576,38.319422],[-78.800912,38.344473],[-78.80564,38.344846],[-78.847746,38.346532],[-78.836482,38.30398],[-78.761677,38.274868],[-78.739229,38.300758],[-78.746576,38.319422]]]},"properties":{"id":"24471","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.942994,38.377353],[-78.847746,38.346532],[-78.80564,38.344846],[-78.82437,38.405686],[-78.971172,38.465641],[-78.948221,38.397372],[-78.942994,38.377353]],[[-78.872211,38.44009],[-78.867766,38.429438],[-78.876834,38.431937],[-78.872211,38.44009]]]},"properties":{"id":"22801","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.737732,38.830643],[-78.73868,38.927362],[-78.869276,38.762991],[-78.868479,38.762423],[-78.826351,38.778605],[-78.825816,38.790319],[-78.808462,38.80042],[-78.799719,38.790684],[-78.814882,38.778435],[-78.816348,38.76921],[-78.737732,38.830643]]]},"properties":{"id":"22810","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.694968,36.854453],[-81.496072,36.733488],[-81.408631,36.810815],[-81.409204,36.814927],[-81.535874,36.906915],[-81.694968,36.854453]]]},"properties":{"id":"24354","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.113475,36.655218],[-77.050714,36.675497],[-76.998636,36.754986],[-77.097824,36.869979],[-77.189501,36.859016],[-77.179908,36.829457],[-77.113475,36.655218]]]},"properties":{"id":"23837","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.561756,38.203858],[-77.685897,38.359614],[-77.703189,38.360598],[-77.713494,38.2841],[-77.561756,38.203858]]]},"properties":{"id":"22553","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.62765,38.047087],[-77.732785,38.095861],[-77.685849,38.012076],[-77.62765,38.047087]]]},"properties":{"id":"22534","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.268296,36.977472],[-77.29079,36.91767],[-77.269687,36.91969],[-77.270695,36.914502],[-77.279738,36.913412],[-77.283853,36.907296],[-77.189501,36.859016],[-77.097824,36.869979],[-77.069918,36.884566],[-76.968529,37.037233],[-76.949254,37.110823],[-77.093059,37.120512],[-77.21299,37.064772],[-77.193483,37.04394],[-77.220261,37.040587],[-77.278014,37.011654],[-77.268296,36.977472]]]},"properties":{"id":"23890","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.521397,37.226902],[-81.522752,37.201982],[-81.486628,37.226632],[-81.507161,37.234374],[-81.521397,37.226902]]],[[[-81.636632,37.203321],[-81.633831,37.191613],[-81.655533,37.178015],[-81.663329,37.202141],[-81.733298,37.238128],[-81.72704,37.136606],[-81.625186,37.148927],[-81.53026,37.196999],[-81.561281,37.206633],[-81.636632,37.203321]]]]},"properties":{"id":"24602","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.383485,37.318661],[-81.351051,37.287419],[-81.317863,37.297805],[-81.383485,37.318661]]]},"properties":{"id":"24635","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.64894,38.019116],[-76.631933,37.977089],[-76.537078,38.032448],[-76.516369,38.034724],[-76.595895,38.10644],[-76.644459,38.030062],[-76.64894,38.019116]]]},"properties":{"id":"22488","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.898057,37.03878],[-80.969116,36.869064],[-80.774345,36.8691],[-80.785064,36.892134],[-80.821721,36.923142],[-80.849683,36.992054],[-80.898057,37.03878]]]},"properties":{"id":"24360","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.066443,38.830101],[-77.049634,38.82042],[-77.04899,38.84121],[-77.064807,38.844884],[-77.080252,38.843312],[-77.066443,38.830101]]]},"properties":{"id":"22305","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.257475,36.820757],[-76.25165,36.787026],[-76.219272,36.810797],[-76.22606,36.839947],[-76.236644,36.838083],[-76.243765,36.836862],[-76.257475,36.820757]]]},"properties":{"id":"23325","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.511659,38.279985],[-77.447126,38.284614],[-77.502254,38.325855],[-77.530289,38.309172],[-77.511659,38.279985]]]},"properties":{"id":"22401","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.303863,37.05332],[-76.304153,37.023595],[-76.288698,37.034504],[-76.29293,37.114303],[-76.311744,37.102],[-76.303863,37.05332]]]},"properties":{"id":"23664","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.872211,38.44009],[-78.876834,38.431937],[-78.867766,38.429438],[-78.872211,38.44009]]]},"properties":{"id":"22807","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.243765,36.836862],[-76.274962,36.839847],[-76.295863,36.843847],[-76.289962,36.822048],[-76.257475,36.820757],[-76.243765,36.836862]]]},"properties":{"id":"23523","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.311061,36.796209],[-76.33733,36.815829],[-76.342065,36.791032],[-76.311061,36.796209]]]},"properties":{"id":"23702","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.435632,37.555513],[-77.44955,37.532557],[-77.429549,37.528785],[-77.424702,37.550769],[-77.435632,37.555513]]]},"properties":{"id":"23219","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.935977,37.272447],[-79.952116,37.288553],[-79.992574,37.273289],[-79.95224,37.256785],[-79.938336,37.259025],[-79.94087,37.266921],[-79.944336,37.273441],[-79.935977,37.272447]]]},"properties":{"id":"24016","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.420368,36.866348],[-76.399567,36.930947],[-76.434789,36.935283],[-76.492473,36.886379],[-76.506178,36.876907],[-76.550117,36.837083],[-76.463398,36.77046],[-76.420368,36.866348]]]},"properties":{"id":"23435","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.521099,36.90633],[-76.506178,36.876907],[-76.492473,36.886379],[-76.502845,36.913313],[-76.521099,36.90633]]]},"properties":{"id":"23436","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.41176,37.946333],[-75.358644,37.864731],[-75.246204,38.022366],[-75.323157,38.020155],[-75.41176,37.946333]]]},"properties":{"id":"23336","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.807599,37.622336],[-75.847821,37.630702],[-75.902768,37.630577],[-75.926963,37.588033],[-75.813836,37.542726],[-75.804341,37.514737],[-75.801996,37.504743],[-75.791748,37.502544],[-75.746557,37.597986],[-75.754468,37.617881],[-75.807599,37.622336]]]},"properties":{"id":"23420","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.840403,37.653562],[-75.829352,37.656616],[-75.827074,37.667021],[-75.842912,37.67303],[-75.840403,37.653562]]]},"properties":{"id":"23389","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.440628,38.176751],[-78.461235,38.101011],[-78.458489,38.034533],[-78.40006,38.006921],[-78.396745,38.001459],[-78.364341,38.128146],[-78.407173,38.18934],[-78.440628,38.176751]]]},"properties":{"id":"22911","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.474844,38.04283],[-78.575834,37.90923],[-78.564763,37.871553],[-78.555286,37.86502],[-78.388198,37.912882],[-78.351257,37.955882],[-78.40006,38.006921],[-78.458489,38.034533],[-78.474844,38.04283]]]},"properties":{"id":"22902","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.823687,37.810605],[-79.852933,37.80693],[-79.857554,37.79425],[-79.823687,37.810605]]]},"properties":{"id":"24474","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.993706,37.219677],[-77.812675,37.197734],[-77.742003,37.255164],[-77.741188,37.267895],[-77.813419,37.33673],[-77.855148,37.418363],[-78.149154,37.45279],[-78.207182,37.406499],[-78.188474,37.349265],[-78.047064,37.253915],[-77.993706,37.219677]]]},"properties":{"id":"23002","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.668407,37.122068],[-77.742003,37.255164],[-77.812675,37.197734],[-77.795926,37.192592],[-77.775838,37.081904],[-77.753654,37.078143],[-77.668407,37.122068]]]},"properties":{"id":"23850","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.223724,37.506374],[-79.110631,37.501937],[-79.290367,37.702651],[-79.329734,37.688123],[-79.432487,37.616361],[-79.436926,37.617004],[-79.329551,37.512896],[-79.294519,37.499568],[-79.223724,37.506374]]]},"properties":{"id":"24574","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.080981,38.887124],[-77.083305,38.877096],[-77.076769,38.867942],[-77.073787,38.870879],[-77.07057,38.868899],[-77.061183,38.869658],[-77.057152,38.880314],[-77.070469,38.901104],[-77.080981,38.887124]]]},"properties":{"id":"22211","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.131849,38.861727],[-77.119302,38.851915],[-77.113365,38.847382],[-77.069977,38.859535],[-77.061183,38.869658],[-77.07057,38.868899],[-77.076769,38.867942],[-77.083305,38.877096],[-77.097538,38.871435],[-77.13756,38.866142],[-77.131849,38.861727]]]},"properties":{"id":"22204","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.082643,38.90228],[-77.116436,38.929571],[-77.156343,38.905677],[-77.157228,38.892804],[-77.11869,38.885362],[-77.083292,38.901655],[-77.082643,38.90228]]]},"properties":{"id":"22207","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.313392,38.411521],[-79.244581,38.3358],[-79.093135,38.2738],[-79.046324,38.252582],[-79.033305,38.292322],[-79.132871,38.426908],[-79.224341,38.477844],[-79.312276,38.411876],[-79.313392,38.411521]]]},"properties":{"id":"22843","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.123704,37.958703],[-79.208936,37.953334],[-79.247149,37.974879],[-79.344226,38.000045],[-79.319125,37.928658],[-79.315,37.929382],[-79.233042,37.882966],[-79.157423,37.890995],[-79.062283,37.91752],[-79.052591,37.941579],[-79.123704,37.958703]]]},"properties":{"id":"24472","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.333099,38.041722],[-79.447295,38.075024],[-79.487484,38.090497],[-79.650757,37.871566],[-79.61323,37.856986],[-79.499812,37.929262],[-79.418832,37.969577],[-79.333099,38.041722]]]},"properties":{"id":"24439","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.295744,38.213253],[-79.244581,38.3358],[-79.313392,38.411521],[-79.405996,38.283923],[-79.427987,38.23047],[-79.362578,38.132871],[-79.338242,38.141197],[-79.295744,38.213253]]]},"properties":{"id":"24485","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.795921,38.265552],[-80.056056,37.951959],[-79.962284,37.953968],[-79.854375,38.06576],[-79.816368,38.061959],[-79.798655,38.040123],[-79.775212,38.038179],[-79.76623,38.050693],[-79.648063,38.212532],[-79.58244,38.330306],[-79.795921,38.265552]]]},"properties":{"id":"24484","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.820231,37.331043],[-79.91783,37.27954],[-79.904639,37.266275],[-79.847082,37.225468],[-79.809042,37.256607],[-79.70488,37.263188],[-79.707955,37.277548],[-79.72411,37.314885],[-79.820231,37.331043]]]},"properties":{"id":"24179","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.662527,37.308837],[-79.51818,37.168405],[-79.397622,37.268802],[-79.415716,37.333944],[-79.439032,37.357841],[-79.431542,37.454099],[-79.49384,37.537791],[-79.498553,37.532887],[-79.634201,37.45705],[-79.657559,37.421192],[-79.662527,37.308837]]]},"properties":{"id":"24523","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.329551,37.512896],[-79.355847,37.482479],[-79.355604,37.480676],[-79.328086,37.475857],[-79.294519,37.499568],[-79.329551,37.512896]]]},"properties":{"id":"24536","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.439032,37.357841],[-79.415716,37.333944],[-79.420546,37.356118],[-79.439032,37.357841]]]},"properties":{"id":"24570","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.099234,37.240215],[-80.980146,37.292743],[-81.225104,37.234874],[-81.249045,37.222698],[-81.097041,37.235981],[-81.099234,37.240215]]]},"properties":{"id":"24366","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.022428,37.308156],[-79.949736,37.385898],[-79.949611,37.397658],[-80.090241,37.380346],[-80.251963,37.301048],[-80.251628,37.300675],[-80.183533,37.175929],[-80.140462,37.200188],[-80.014253,37.270984],[-80.022428,37.308156]]]},"properties":{"id":"24153","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.853423,36.578626],[-77.867983,36.544878],[-77.767117,36.545422],[-77.744203,36.579913],[-77.853423,36.578626]]]},"properties":{"id":"23887","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.889813,36.988818],[-77.859011,37.085657],[-77.829029,37.097579],[-77.795926,37.192592],[-77.812675,37.197734],[-77.993706,37.219677],[-78.122552,37.035865],[-78.094836,37.021621],[-78.030236,36.951702],[-77.957294,36.947938],[-77.928484,36.978096],[-77.889813,36.988818]]]},"properties":{"id":"23824","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.934029,36.563194],[-77.946511,36.580898],[-77.970969,36.544492],[-77.930646,36.544573],[-77.934029,36.563194]]],[[[-77.964833,36.617199],[-77.977288,36.610538],[-77.947656,36.588139],[-77.905956,36.612972],[-77.889182,36.622345],[-77.886917,36.643198],[-77.871954,36.647603],[-77.878273,36.700472],[-77.964833,36.617199]]]]},"properties":{"id":"23893","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.234334,36.635796],[-78.26416,36.618159],[-78.046203,36.544156],[-78.032588,36.544198],[-78.01498,36.59337],[-78.149421,36.651897],[-78.234334,36.635796]]]},"properties":{"id":"23919","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.859782,37.253393],[-81.904865,37.241935],[-81.854387,37.174994],[-81.859782,37.253393]]]},"properties":{"id":"24657","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.12345,37.386525],[-82.150072,37.410028],[-82.274737,37.323534],[-82.219004,37.310014],[-82.190651,37.316887],[-82.12345,37.386525]]]},"properties":{"id":"24603","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.164038,37.32912],[-79.169191,37.323213],[-79.238085,37.312326],[-79.219769,37.264195],[-79.085707,37.332884],[-79.113672,37.397877],[-79.123282,37.399792],[-79.1597,37.418764],[-79.233256,37.395743],[-79.164038,37.32912]]]},"properties":{"id":"24501","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.769348,36.603334],[-80.815775,36.560166],[-80.714167,36.56228],[-80.739535,36.619028],[-80.769348,36.603334]]]},"properties":{"id":"24351","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.341991,37.536472],[-77.416537,37.517155],[-77.421672,37.498989],[-77.427682,37.436569],[-77.386308,37.386622],[-77.277085,37.352231],[-77.244185,37.369493],[-77.197726,37.45382],[-77.3001,37.489267],[-77.340424,37.498954],[-77.342475,37.534765],[-77.341991,37.536472]]]},"properties":{"id":"23231","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.44955,37.532557],[-77.525168,37.473128],[-77.522737,37.467839],[-77.516377,37.460519],[-77.421672,37.498989],[-77.416537,37.517155],[-77.429549,37.528785],[-77.44955,37.532557]]]},"properties":{"id":"23224","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.525168,37.473128],[-77.44955,37.532557],[-77.48388,37.531755],[-77.530272,37.559501],[-77.538597,37.559416],[-77.525168,37.473128]]]},"properties":{"id":"23225","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.0448,39.078384],[-78.059578,39.068543],[-78.03665,39.056563],[-78.0448,39.078384]]]},"properties":{"id":"22646","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.048796,38.395084],[-77.976344,38.3552],[-77.990027,38.381574],[-78.048796,38.395084]]]},"properties":{"id":"22729","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.039318,37.822654],[-78.156008,37.825924],[-78.208965,37.796144],[-78.192186,37.735197],[-78.239748,37.690495],[-78.2193,37.62697],[-78.188224,37.630299],[-78.118494,37.724255],[-78.039318,37.822654]]]},"properties":{"id":"23038","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.222179,37.189364],[-82.28457,37.176244],[-82.285395,37.157386],[-82.270779,37.118835],[-82.213512,37.067935],[-82.212204,37.134615],[-82.218082,37.182935],[-82.222179,37.189364]]]},"properties":{"id":"24220","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.859011,37.085657],[-77.841382,36.995941],[-77.744943,36.958291],[-77.658036,36.894514],[-77.633581,36.874931],[-77.600962,36.887117],[-77.619239,36.948311],[-77.588587,37.011266],[-77.753654,37.078143],[-77.775838,37.081904],[-77.829029,37.097579],[-77.859011,37.085657]]]},"properties":{"id":"23872","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.072546,38.088769],[-77.124857,38.03748],[-77.08897,38.013644],[-77.057368,38.076904],[-77.072546,38.088769]]]},"properties":{"id":"22476","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.317183,38.921858],[-77.360653,38.908241],[-77.370799,38.886366],[-77.336415,38.864009],[-77.305744,38.877114],[-77.300801,38.875172],[-77.30252,38.872264],[-77.307456,38.875201],[-77.29785,38.870924],[-77.283295,38.875],[-77.317183,38.921858]]]},"properties":{"id":"22124","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.417272,38.904122],[-77.405266,38.860214],[-77.402944,38.852024],[-77.336415,38.864009],[-77.370799,38.886366],[-77.417272,38.904122]]]},"properties":{"id":"22033","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.311952,38.947944],[-77.374265,38.953287],[-77.388809,38.953398],[-77.360653,38.908241],[-77.317183,38.921858],[-77.310808,38.935455],[-77.311952,38.947944]]]},"properties":{"id":"20191","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.22818,38.883549],[-77.227069,38.876798],[-77.222581,38.877356],[-77.219203,38.887542],[-77.22818,38.883549]]],[[[-77.22818,38.883549],[-77.228351,38.893238],[-77.273821,38.910262],[-77.267269,38.878592],[-77.22818,38.883549]]]]},"properties":{"id":"22180","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.422422,38.962604],[-77.429278,38.955744],[-77.417272,38.904122],[-77.370799,38.886366],[-77.360653,38.908241],[-77.388809,38.953398],[-77.422422,38.962604]]]},"properties":{"id":"20171","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.226167,38.859604],[-77.251483,38.838551],[-77.266092,38.811525],[-77.256497,38.81078],[-77.176092,38.811897],[-77.157683,38.836104],[-77.174704,38.846474],[-77.226167,38.859604]]]},"properties":{"id":"22003","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.264362,38.951351],[-77.217394,38.910169],[-77.196544,38.923308],[-77.202689,38.967701],[-77.234875,38.975976],[-77.264362,38.951351]]]},"properties":{"id":"22102","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.315141,38.977778],[-77.341465,38.998325],[-77.365334,38.966224],[-77.318069,38.975192],[-77.315141,38.977778]]]},"properties":{"id":"20194","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.276986,38.755538],[-77.325525,38.800705],[-77.324187,38.812665],[-77.365928,38.794607],[-77.359103,38.723668],[-77.306793,38.707403],[-77.252269,38.736456],[-77.276986,38.755538]]]},"properties":{"id":"22039","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.770865,38.792721],[-77.692049,38.796722],[-77.673946,38.808482],[-77.701434,38.855803],[-77.770865,38.792721]]]},"properties":{"id":"20137","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.827382,38.826981],[-77.812672,38.76322],[-77.770865,38.792721],[-77.701434,38.855803],[-77.655081,38.942635],[-77.671815,38.946576],[-77.810464,38.952629],[-77.827382,38.826981]]]},"properties":{"id":"20198","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.997846,38.907381],[-77.995106,38.857843],[-77.96721,38.833173],[-77.868958,38.937698],[-77.949717,38.981982],[-77.997846,38.907381]]]},"properties":{"id":"20144","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.773138,38.641536],[-77.678444,38.509851],[-77.601707,38.497087],[-77.599287,38.495499],[-77.560836,38.527082],[-77.684227,38.635555],[-77.717519,38.657844],[-77.773138,38.641536]]]},"properties":{"id":"22728","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.675143,38.465902],[-77.660776,38.41864],[-77.634835,38.409713],[-77.601707,38.497087],[-77.678444,38.509851],[-77.675143,38.465902]]]},"properties":{"id":"22720","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.601707,38.497087],[-77.634835,38.409713],[-77.618727,38.367835],[-77.530289,38.309172],[-77.502254,38.325855],[-77.435499,38.397622],[-77.477255,38.422424],[-77.599287,38.495499],[-77.601707,38.497087]]]},"properties":{"id":"22406","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.699789,36.965299],[-79.735591,37.036176],[-79.802661,37.071425],[-79.706917,36.920399],[-79.699789,36.965299]]]},"properties":{"id":"24092","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.026286,37.030357],[-79.981156,36.965611],[-79.92347,36.878801],[-79.929711,36.832724],[-79.855806,36.830339],[-79.744158,36.860355],[-79.706917,36.920399],[-79.802661,37.071425],[-79.923143,37.087866],[-80.026286,37.030357]]]},"properties":{"id":"24151","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.92347,36.878801],[-80.041493,36.794365],[-79.929711,36.832724],[-79.92347,36.878801]]]},"properties":{"id":"24102","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.070075,37.161972],[-80.140462,37.200188],[-80.183533,37.175929],[-80.182205,37.165478],[-80.178032,37.113435],[-80.128119,37.122912],[-80.08049,37.133845],[-80.070075,37.161972]]]},"properties":{"id":"24059","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.108306,39.312187],[-78.128245,39.235339],[-78.045316,39.236465],[-78.033191,39.264612],[-78.108306,39.312187]]]},"properties":{"id":"22624","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.711806,37.266771],[-80.764714,37.252791],[-80.73931,37.228266],[-80.711806,37.266771]]]},"properties":{"id":"24167","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.863003,37.411514],[-80.821588,37.372409],[-80.783523,37.393053],[-80.863003,37.411514]]]},"properties":{"id":"24147","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.462894,37.496706],[-76.394742,37.37631],[-76.386998,37.421101],[-76.406162,37.459111],[-76.446041,37.475021],[-76.462894,37.496706]]]},"properties":{"id":"23128","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.707944,37.589175],[-77.633783,37.558623],[-77.576589,37.624936],[-77.650862,37.624212],[-77.707944,37.589175]]]},"properties":{"id":"23238","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.779636,37.773906],[-77.749237,37.772625],[-77.649458,37.755387],[-77.589938,37.778497],[-77.571868,37.804092],[-77.59544,37.826267],[-77.715994,37.905277],[-77.779636,37.773906]]]},"properties":{"id":"23192","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.530417,37.642213],[-77.517241,37.607111],[-77.479463,37.589326],[-77.459603,37.637978],[-77.530417,37.642213]]]},"properties":{"id":"23228","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.459603,37.637978],[-77.479463,37.589326],[-77.462824,37.575815],[-77.447118,37.572188],[-77.407189,37.612524],[-77.430433,37.652586],[-77.450631,37.657961],[-77.459603,37.637978]]]},"properties":{"id":"23227","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.493902,37.572731],[-77.521525,37.606391],[-77.537864,37.612705],[-77.53203,37.582571],[-77.542233,37.570748],[-77.570641,37.557957],[-77.538597,37.559416],[-77.530272,37.559501],[-77.493902,37.572731]]]},"properties":{"id":"23226","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.717862,36.818754],[-79.831433,36.646321],[-79.714855,36.541938],[-79.662207,36.541791],[-79.618052,36.644877],[-79.597349,36.696133],[-79.614617,36.739648],[-79.674075,36.829238],[-79.717862,36.818754]]]},"properties":{"id":"24054","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.754818,37.638127],[-76.77467,37.691642],[-76.878953,37.656459],[-76.821577,37.605795],[-76.754818,37.638127]]]},"properties":{"id":"23091","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.507828,37.838814],[-76.668182,37.778386],[-76.592528,37.742938],[-76.55442,37.671364],[-76.549022,37.658599],[-76.530404,37.632147],[-76.500681,37.626509],[-76.475899,37.631436],[-76.421512,37.730904],[-76.367102,37.770132],[-76.507828,37.838814]]]},"properties":{"id":"22503","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.435666,37.654022],[-76.391538,37.676213],[-76.413909,37.679395],[-76.435666,37.654022]]]},"properties":{"id":"22480","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.985332,36.792352],[-82.933,36.735697],[-82.85349,36.775811],[-82.91357,36.811866],[-82.985332,36.792352]]]},"properties":{"id":"24243","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.099068,36.812781],[-83.031152,36.785349],[-83.009242,36.847408],[-83.099068,36.812781]]]},"properties":{"id":"24282","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736773,38.96852],[-77.738573,38.967817],[-77.738193,38.967089],[-77.736329,38.967773],[-77.736773,38.96852]]]},"properties":{"id":"20118","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.385798,37.202967],[-78.285992,37.044606],[-78.229944,37.096868],[-78.301282,37.22241],[-78.361145,37.235276],[-78.385798,37.202967]]]},"properties":{"id":"23942","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.316955,38.268702],[-78.25054,38.233768],[-78.220051,38.231569],[-78.23016,38.285213],[-78.267714,38.335388],[-78.270723,38.345654],[-78.358845,38.290023],[-78.316955,38.268702]]]},"properties":{"id":"22738","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.054815,38.139125],[-77.920144,38.117061],[-77.902361,38.164325],[-77.993713,38.24124],[-78.102304,38.32784],[-78.131911,38.351021],[-78.141136,38.349739],[-78.171554,38.317993],[-78.207377,38.31337],[-78.23016,38.285213],[-78.220051,38.231569],[-78.168284,38.207642],[-78.054815,38.139125]],[[-78.134036,38.28178],[-78.130171,38.295083],[-78.112864,38.288262],[-78.134036,38.28178]]]},"properties":{"id":"22960","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.365806,38.317504],[-78.377821,38.348356],[-78.429241,38.367883],[-78.365806,38.317504]]]},"properties":{"id":"22723","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.245594,37.42106],[-76.283785,37.417617],[-76.309309,37.402137],[-76.25005,37.368455],[-76.245594,37.42106]]]},"properties":{"id":"23130","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.703881,36.541992],[-78.637021,36.541476],[-78.709457,36.620528],[-78.703881,36.541992]]]},"properties":{"id":"24580","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.387693,37.570845],[-76.388641,37.607319],[-76.400406,37.612086],[-76.452358,37.565464],[-76.406817,37.549572],[-76.387693,37.570845]]]},"properties":{"id":"23176","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.304199,37.202231],[-80.182205,37.165478],[-80.183533,37.175929],[-80.251628,37.300675],[-80.327276,37.214169],[-80.304199,37.202231]]]},"properties":{"id":"24087","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.02268,37.704425],[-79.00804,37.714258],[-79.006501,37.720363],[-79.058971,37.713516],[-79.027333,37.702478],[-79.02268,37.704425]]]},"properties":{"id":"22964","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.920779,37.7933],[-78.873044,37.722356],[-78.813807,37.83099],[-78.901255,37.842104],[-78.920779,37.7933]]]},"properties":{"id":"22949","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.310126,37.789728],[-76.311888,37.801917],[-76.327057,37.792617],[-76.310126,37.789728]]]},"properties":{"id":"22579","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.841937,38.37745],[-77.841619,38.371081],[-77.853932,38.347284],[-77.84255,38.257214],[-77.834095,38.22949],[-77.713494,38.2841],[-77.703189,38.360598],[-77.757499,38.385931],[-77.841937,38.37745]]]},"properties":{"id":"22508","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.841619,38.371081],[-77.876807,38.338917],[-77.853932,38.347284],[-77.841619,38.371081]]]},"properties":{"id":"22433","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.554022,38.520788],[-78.641536,38.604939],[-78.667112,38.557877],[-78.685431,38.518765],[-78.682121,38.506104],[-78.554022,38.520788]]]},"properties":{"id":"22849","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.568416,36.749812],[-79.399584,36.754867],[-79.399193,36.754947],[-79.26093,36.765007],[-79.228604,36.77043],[-79.26897,36.854386],[-79.487448,36.944714],[-79.517778,36.948888],[-79.594267,36.934121],[-79.632829,36.852698],[-79.568416,36.749812]]]},"properties":{"id":"24531","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.1954,37.008329],[-79.179557,37.071101],[-79.215907,37.118921],[-79.321913,37.120921],[-79.371723,37.113551],[-79.1954,37.008329]]]},"properties":{"id":"24563","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.517803,36.540784],[-79.618052,36.644877],[-79.662207,36.541791],[-79.517803,36.540784]]]},"properties":{"id":"24069","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.356262,37.284757],[-77.376728,37.244993],[-77.365648,37.241076],[-77.350004,37.257648],[-77.356262,37.284757]]],[[[-77.344401,37.213849],[-77.330988,37.196401],[-77.320295,37.184949],[-77.157639,37.225922],[-77.331126,37.25753],[-77.344401,37.213849]]]]},"properties":{"id":"23875","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.357017,38.589825],[-77.333362,38.569633],[-77.314362,38.597439],[-77.307986,38.60654],[-77.37237,38.617671],[-77.357017,38.589825]]]},"properties":{"id":"22025","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.37237,38.617671],[-77.307986,38.60654],[-77.293102,38.628172],[-77.359853,38.681165],[-77.37237,38.617671]]]},"properties":{"id":"22193","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.701434,38.855803],[-77.673946,38.808482],[-77.591289,38.880859],[-77.58062,38.89099],[-77.655081,38.942635],[-77.701434,38.855803]]]},"properties":{"id":"20169","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.613593,37.201541],[-80.63598,37.164765],[-80.575116,37.18389],[-80.58007,37.185147],[-80.601675,37.202283],[-80.613593,37.201541]]]},"properties":{"id":"24058","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.170415,38.673136],[-78.130483,38.654626],[-78.091637,38.692667],[-78.079222,38.721993],[-78.25986,38.734974],[-78.170415,38.673136]]]},"properties":{"id":"22747","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.151423,38.848482],[-78.125096,38.842008],[-78.131175,38.861697],[-78.151423,38.848482]]]},"properties":{"id":"22623","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.971172,38.465641],[-78.82437,38.405686],[-78.811469,38.409782],[-78.694659,38.488604],[-78.682121,38.506104],[-78.685431,38.518765],[-78.82648,38.540818],[-78.896663,38.545875],[-78.967908,38.545417],[-79.019337,38.568075],[-78.971691,38.465737],[-78.971172,38.465641]]]},"properties":{"id":"22802","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.896663,38.545875],[-78.82648,38.540818],[-78.88998,38.593477],[-78.896663,38.545875]]]},"properties":{"id":"22834","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.912388,36.97996],[-81.936294,36.984279],[-81.963404,36.930925],[-81.912388,36.97996]]]},"properties":{"id":"24280","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.607735,36.801899],[-82.52093,36.771489],[-82.504915,36.777127],[-82.381591,36.828083],[-82.406633,36.876356],[-82.618809,36.817645],[-82.607735,36.801899]]]},"properties":{"id":"24245","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.655263,38.720843],[-78.612485,38.710133],[-78.737732,38.830643],[-78.816348,38.76921],[-78.800176,38.714201],[-78.785866,38.716076],[-78.655263,38.720843]]]},"properties":{"id":"22842","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.519428,38.789121],[-78.632038,38.904685],[-78.677449,38.927908],[-78.73868,38.927362],[-78.737732,38.830643],[-78.612485,38.710133],[-78.567015,38.728847],[-78.519428,38.789121]]]},"properties":{"id":"22824","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.325774,39.077805],[-78.393085,39.100243],[-78.394156,39.092748],[-78.35335,39.080388],[-78.422901,39.05808],[-78.431499,39.065769],[-78.456162,39.009638],[-78.453011,38.982228],[-78.419235,38.925259],[-78.395431,38.915862],[-78.303423,38.94609],[-78.233411,38.964031],[-78.325774,39.077805]]]},"properties":{"id":"22657","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.453011,38.982228],[-78.459509,38.945169],[-78.419235,38.925259],[-78.453011,38.982228]]]},"properties":{"id":"22660","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.814882,38.778435],[-78.799719,38.790684],[-78.808462,38.80042],[-78.825816,38.790319],[-78.826351,38.778605],[-78.814882,38.778435]]]},"properties":{"id":"22845","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.535874,36.906915],[-81.409204,36.814927],[-81.379427,36.952417],[-81.4806,36.921428],[-81.535874,36.906915]]]},"properties":{"id":"24311","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.780539,36.958614],[-81.946985,36.864815],[-81.904351,36.831534],[-81.791068,36.8602],[-81.740895,36.830708],[-81.694968,36.854453],[-81.535874,36.906915],[-81.4806,36.921428],[-81.476481,36.988066],[-81.677919,36.933997],[-81.698788,36.959598],[-81.709072,36.976858],[-81.780539,36.958614]]]},"properties":{"id":"24370","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.842112,37.124542],[-76.913527,37.030462],[-76.849684,36.996211],[-76.791086,37.022492],[-76.763403,37.034273],[-76.837535,37.13046],[-76.842112,37.124542]]]},"properties":{"id":"23846","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.279738,36.913412],[-77.270695,36.914502],[-77.269687,36.91969],[-77.29079,36.91767],[-77.279738,36.913412]]]},"properties":{"id":"23884","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.755271,37.076816],[-81.761871,36.994551],[-81.626893,37.110779],[-81.754555,37.079833],[-81.755271,37.076816]]]},"properties":{"id":"24637","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.170932,38.823837],[-78.339795,38.874704],[-78.386682,38.830152],[-78.350133,38.807098],[-78.284877,38.759229],[-78.255862,38.758257],[-78.200764,38.776665],[-78.170932,38.823837]]]},"properties":{"id":"22610","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.905181,38.192774],[-76.93792,38.084903],[-76.720764,38.048312],[-76.656995,38.155514],[-76.905181,38.192774]]]},"properties":{"id":"22520","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.664588,37.983831],[-76.648082,37.968396],[-76.631933,37.977089],[-76.64894,38.019116],[-76.664588,37.983831]]],[[[-76.644459,38.030062],[-76.595895,38.10644],[-76.656995,38.155514],[-76.720764,38.048312],[-76.706663,38.026285],[-76.644459,38.030062]]]]},"properties":{"id":"22469","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.73787,37.043856],[-82.878523,36.893346],[-82.844747,36.84696],[-82.72851,36.919146],[-82.73787,37.043856]]]},"properties":{"id":"24216","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.071537,38.811707],[-77.049634,38.82042],[-77.066443,38.830101],[-77.071537,38.811707]]]},"properties":{"id":"22301","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.411284,37.003574],[-76.38758,36.989617],[-76.356815,37.003113],[-76.397199,37.025538],[-76.417505,37.013249],[-76.411284,37.003574]]]},"properties":{"id":"23661","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.412885,37.155808],[-76.39447,37.106248],[-76.393401,37.108321],[-76.317364,37.103044],[-76.311744,37.102],[-76.29293,37.114303],[-76.400438,37.162947],[-76.412885,37.155808]]]},"properties":{"id":"23662","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.194674,36.830837],[-76.22606,36.839947],[-76.219272,36.810797],[-76.143253,36.757045],[-76.128426,36.799148],[-76.194674,36.830837]]]},"properties":{"id":"23464","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.122354,36.550552],[-75.928707,36.689145],[-76.065553,36.68555],[-76.122354,36.550552]]]},"properties":{"id":"23457","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.935414,37.550406],[-75.813836,37.542726],[-75.926963,37.588033],[-75.941571,37.549202],[-75.935414,37.550406]]]},"properties":{"id":"23306","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.746557,37.597986],[-75.698752,37.599686],[-75.67787,37.627078],[-75.722995,37.680846],[-75.827074,37.667021],[-75.829352,37.656616],[-75.804164,37.636281],[-75.754468,37.617881],[-75.746557,37.597986]]]},"properties":{"id":"23410","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.428885,38.010889],[-75.648145,37.965372],[-75.570583,37.943884],[-75.5239,37.931565],[-75.497558,37.923499],[-75.46832,37.919533],[-75.440476,37.946786],[-75.442,38.001099],[-75.428885,38.010889]]]},"properties":{"id":"23415","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.54947,37.922179],[-75.517965,37.881166],[-75.493309,37.877792],[-75.474407,37.907892],[-75.497558,37.923499],[-75.5239,37.931565],[-75.54947,37.922179]]]},"properties":{"id":"23303","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.6477,37.946963],[-75.608586,37.927432],[-75.570583,37.943884],[-75.648145,37.965372],[-75.6477,37.946963]]]},"properties":{"id":"23488","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.29543,38.121969],[-78.270371,38.014644],[-78.219522,37.970743],[-78.054815,38.139125],[-78.168284,38.207642],[-78.272749,38.206797],[-78.29543,38.121969]]]},"properties":{"id":"22942","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.662663,38.062232],[-78.474844,38.04283],[-78.458489,38.034533],[-78.461235,38.101011],[-78.54306,38.151216],[-78.61242,38.122525],[-78.662663,38.062232]]]},"properties":{"id":"22901","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.622763,38.301904],[-78.554517,38.241182],[-78.474119,38.216661],[-78.559814,38.331235],[-78.622763,38.301904]]]},"properties":{"id":"22935","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.962284,37.953968],[-79.887623,37.869932],[-79.858027,37.88831],[-79.775212,38.038179],[-79.798655,38.040123],[-79.854375,38.06576],[-79.962284,37.953968]]]},"properties":{"id":"24445","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.784271,37.792422],[-79.785476,37.79943],[-79.795929,37.803222],[-79.790627,37.794114],[-79.784271,37.792422]]]},"properties":{"id":"24448","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.491743,37.269625],[-78.616226,37.368058],[-78.752115,37.220803],[-78.751778,37.168765],[-78.73337,37.17211],[-78.690023,37.188286],[-78.537867,37.214993],[-78.491743,37.269625]]]},"properties":{"id":"23958","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.061809,37.393156],[-79.014973,37.295072],[-78.986887,37.21594],[-78.949437,37.195226],[-78.895173,37.185865],[-78.856501,37.212443],[-78.884004,37.246571],[-78.967117,37.340722],[-78.905543,37.401116],[-78.904703,37.497799],[-78.941469,37.496405],[-79.061809,37.393156]]]},"properties":{"id":"24538","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.105073,38.8391],[-77.080252,38.843312],[-77.064807,38.844884],[-77.069977,38.859535],[-77.113365,38.847382],[-77.110731,38.843457],[-77.105073,38.8391]]]},"properties":{"id":"22206","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.196544,38.923308],[-77.159681,38.903147],[-77.156343,38.905677],[-77.116436,38.929571],[-77.202689,38.967701],[-77.196544,38.923308]]]},"properties":{"id":"22101","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.07057,38.868899],[-77.073787,38.870879],[-77.076769,38.867942],[-77.07057,38.868899]]]},"properties":{"id":"22214","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.94103,38.197013],[-79.03027,38.237918],[-79.046324,38.252582],[-79.093135,38.2738],[-79.167369,38.205764],[-79.204531,38.076215],[-79.19352,38.037082],[-79.140571,38.01921],[-79.017917,38.062063],[-78.999269,38.075527],[-79.031262,38.096373],[-78.944814,38.157032],[-78.915551,38.181678],[-78.90641,38.197501],[-78.926902,38.208836],[-78.94103,38.197013]]]},"properties":{"id":"24401","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.772125,38.153356],[-78.882815,38.208893],[-78.905637,38.198159],[-78.90641,38.197501],[-78.879172,38.164436],[-78.774172,38.149539],[-78.772125,38.153356]]]},"properties":{"id":"24431","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.899053,37.948862],[-78.985157,37.901996],[-78.913139,37.869382],[-78.845531,37.91216],[-78.899053,37.948862]]]},"properties":{"id":"22958","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.53298,37.039393],[-79.517778,36.948888],[-79.487448,36.944714],[-79.465146,37.060325],[-79.473648,37.063389],[-79.53298,37.039393]]]},"properties":{"id":"24139","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.328086,37.475857],[-79.244174,37.387322],[-79.233256,37.395743],[-79.1597,37.418764],[-79.13972,37.419081],[-79.223724,37.506374],[-79.294519,37.499568],[-79.328086,37.475857]]]},"properties":{"id":"24503","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.022428,37.308156],[-79.974025,37.308779],[-79.864789,37.354618],[-79.895175,37.369572],[-79.901187,37.364588],[-79.914467,37.371288],[-79.909406,37.37871],[-79.949736,37.385898],[-80.022428,37.308156]],[[-79.942104,37.351965],[-79.945616,37.362997],[-79.939928,37.360566],[-79.942104,37.351965]]]},"properties":{"id":"24019","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.720545,36.842492],[-77.719977,36.710541],[-77.657867,36.875922],[-77.720545,36.842492]]]},"properties":{"id":"23856","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.728383,37.475833],[-78.61534,37.394165],[-78.393102,37.439735],[-78.264188,37.599717],[-78.385566,37.631498],[-78.507881,37.677435],[-78.540894,37.65109],[-78.591789,37.642042],[-78.639702,37.497839],[-78.728383,37.475833]]]},"properties":{"id":"23936","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.719279,37.635386],[-78.692163,37.686015],[-78.703758,37.712506],[-78.741126,37.725193],[-78.791825,37.667224],[-78.719279,37.635386]]],[[[-78.780107,37.638071],[-78.683412,37.537131],[-78.719279,37.635386],[-78.780107,37.638071]]]]},"properties":{"id":"24599","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.571868,37.804092],[-77.410765,37.801133],[-77.369389,37.83452],[-77.540878,37.919865],[-77.548941,37.907996],[-77.549005,37.907789],[-77.59544,37.826267],[-77.571868,37.804092]]]},"properties":{"id":"23047","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.605475,36.94235],[-80.663457,36.883467],[-80.591775,36.875992],[-80.605475,36.94235]]]},"properties":{"id":"24105","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.141791,37.476285],[-76.975988,37.41886],[-76.981754,37.489688],[-77.085765,37.504106],[-77.141791,37.476285]]]},"properties":{"id":"23140","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.648879,36.988964],[-78.648978,36.872127],[-78.610414,36.851801],[-78.563575,36.876814],[-78.648879,36.988964]]]},"properties":{"id":"23967","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.648978,36.872127],[-78.66705,36.810404],[-78.648541,36.697909],[-78.571191,36.728893],[-78.572476,36.792184],[-78.589325,36.821467],[-78.610414,36.851801],[-78.648978,36.872127]]]},"properties":{"id":"23964","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.754075,37.008093],[-78.683865,36.835703],[-78.66705,36.810404],[-78.648978,36.872127],[-78.648879,36.988964],[-78.669177,37.006569],[-78.737082,37.057846],[-78.770925,37.031685],[-78.754075,37.008093]]]},"properties":{"id":"23962","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.491985,37.111972],[-77.330988,37.196401],[-77.344401,37.213849],[-77.365648,37.241076],[-77.376728,37.244993],[-77.403671,37.237346],[-77.41651,37.233157],[-77.420891,37.234585],[-77.417353,37.243821],[-77.456693,37.287904],[-77.599025,37.25279],[-77.589159,37.246285],[-77.549128,37.139878],[-77.491985,37.111972]]]},"properties":{"id":"23803","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.855148,37.418363],[-77.813419,37.33673],[-77.729389,37.373718],[-77.709242,37.399787],[-77.762624,37.484492],[-77.855148,37.418363]]]},"properties":{"id":"23120","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.154692,37.601099],[-80.282892,37.532783],[-80.475601,37.422949],[-80.291445,37.388829],[-80.073985,37.422305],[-80.012185,37.50305],[-79.987845,37.570764],[-80.002927,37.591382],[-80.016047,37.60886],[-80.020519,37.647459],[-80.154692,37.601099]]]},"properties":{"id":"24127","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.522711,37.412014],[-80.602562,37.266232],[-80.606768,37.24496],[-80.327898,37.366792],[-80.291445,37.388829],[-80.475601,37.422949],[-80.496805,37.446215],[-80.522711,37.412014]]]},"properties":{"id":"24128","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.115004,38.383172],[-78.048796,38.395084],[-77.990027,38.381574],[-77.976344,38.3552],[-77.967207,38.304975],[-77.927449,38.300445],[-77.876807,38.338917],[-77.841619,38.371081],[-77.841937,38.37745],[-77.869733,38.402105],[-77.878899,38.467937],[-77.957878,38.558198],[-78.058565,38.554685],[-78.11571,38.49338],[-78.140141,38.437106],[-78.183358,38.382783],[-78.149198,38.380914],[-78.115004,38.383172]]]},"properties":{"id":"22701","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.047467,38.624093],[-78.095284,38.591852],[-78.058565,38.554685],[-77.957878,38.558198],[-77.910684,38.561701],[-77.939514,38.636622],[-78.047467,38.624093]]]},"properties":{"id":"22737","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.300105,38.57054],[-78.231978,38.53255],[-78.212192,38.51468],[-78.180429,38.52682],[-78.194881,38.569222],[-78.170415,38.673136],[-78.25986,38.734974],[-78.255862,38.758257],[-78.284877,38.759229],[-78.313752,38.73719],[-78.33832,38.627282],[-78.300105,38.57054]]]},"properties":{"id":"22740","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.194881,38.569222],[-78.180429,38.52682],[-78.11571,38.49338],[-78.058565,38.554685],[-78.095284,38.591852],[-78.158711,38.584933],[-78.194881,38.569222]]]},"properties":{"id":"22713","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.118494,37.724255],[-78.188224,37.630299],[-78.098973,37.583523],[-78.072256,37.657357],[-78.118494,37.724255]]]},"properties":{"id":"23027","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.310236,37.09651],[-82.315086,36.978746],[-82.305585,36.963216],[-82.219635,36.983158],[-82.148718,37.041691],[-82.213512,37.067935],[-82.270779,37.118835],[-82.305396,37.12202],[-82.310236,37.09651]]]},"properties":{"id":"24237","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.589159,37.246285],[-77.623086,37.139521],[-77.549128,37.139878],[-77.589159,37.246285]]]},"properties":{"id":"23885","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.111983,38.801569],[-77.08454,38.785906],[-77.073368,38.784155],[-77.056773,38.792573],[-77.091249,38.80195],[-77.111983,38.801569]]]},"properties":{"id":"22303","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.132859,38.741068],[-77.170809,38.733561],[-77.134732,38.660006],[-77.122632,38.685256],[-77.127601,38.741014],[-77.132859,38.741068]]]},"properties":{"id":"22060","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.890193,39.06095],[-77.948984,38.984309],[-77.949717,38.981982],[-77.868958,38.937698],[-77.820461,38.98087],[-77.811999,39.02291],[-77.818691,39.027503],[-77.890193,39.06095]]]},"properties":{"id":"20184","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.995106,38.857843],[-77.997846,38.907381],[-77.997146,38.848036],[-77.995106,38.857843]]]},"properties":{"id":"22643","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.182205,37.165478],[-80.304199,37.202231],[-80.327182,37.091591],[-80.264485,37.084919],[-80.214079,37.07444],[-80.178032,37.113435],[-80.182205,37.165478]]]},"properties":{"id":"24162","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.325774,39.077805],[-78.233411,38.964031],[-78.213488,38.997897],[-78.304671,39.086277],[-78.325774,39.077805]]]},"properties":{"id":"22645","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.56413,37.637826],[-77.611843,37.657606],[-77.644102,37.66922],[-77.675127,37.677394],[-77.650862,37.624212],[-77.576589,37.624936],[-77.56924,37.632363],[-77.56413,37.637826]]]},"properties":{"id":"23233","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.787869,37.704864],[-77.829856,37.632436],[-77.823547,37.618983],[-77.822397,37.610059],[-77.780573,37.66829],[-77.787869,37.704864]]]},"properties":{"id":"23039","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.130219,36.767296],[-81.26162,36.76312],[-81.309408,36.752512],[-81.281997,36.687146],[-81.092945,36.700113],[-81.089523,36.765117],[-81.089176,36.76723],[-81.130219,36.767296]]]},"properties":{"id":"24326","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.568839,36.545157],[-77.53636,36.642948],[-77.609268,36.6393],[-77.649253,36.545268],[-77.568839,36.545157]]]},"properties":{"id":"23879","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.060247,37.047211],[-79.098299,36.977892],[-79.127736,36.915151],[-79.093856,36.84447],[-79.032985,36.821696],[-78.956517,36.840576],[-78.887462,36.881861],[-78.856814,36.857737],[-78.840943,36.860536],[-78.794862,36.955988],[-78.972067,37.050132],[-79.060247,37.047211]]]},"properties":{"id":"24577","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.611843,37.657606],[-77.450631,37.657961],[-77.430433,37.652586],[-77.451017,37.679981],[-77.589938,37.778497],[-77.649458,37.755387],[-77.644102,37.66922],[-77.611843,37.657606]]]},"properties":{"id":"23059","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.611843,37.657606],[-77.56413,37.637826],[-77.530417,37.642213],[-77.459603,37.637978],[-77.450631,37.657961],[-77.611843,37.657606]]]},"properties":{"id":"23060","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.562379,36.994328],[-76.572938,36.993961],[-76.574013,37.001166],[-76.53593,37.023699],[-76.597084,37.066803],[-76.641581,37.153827],[-76.763403,37.034273],[-76.791086,37.022492],[-76.742255,36.939026],[-76.593968,36.870203],[-76.537863,36.912395],[-76.562379,36.994328]]]},"properties":{"id":"23430","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.181418,37.891654],[-77.135202,37.866101],[-77.097441,37.907299],[-77.107206,37.955292],[-77.181418,37.891654]]]},"properties":{"id":"23126","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.308288,36.597953],[-83.174533,36.593895],[-82.899797,36.701271],[-82.965579,36.696764],[-83.13512,36.742629],[-83.287038,36.714281],[-83.308288,36.597953]]]},"properties":{"id":"24263","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.80021,39.188821],[-77.766685,39.043086],[-77.696743,39.042003],[-77.682452,39.091989],[-77.660654,39.177222],[-77.65963,39.216508],[-77.67871,39.324342],[-77.80021,39.188821]]]},"properties":{"id":"20132","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.655081,38.942635],[-77.58062,38.89099],[-77.552925,38.886838],[-77.541478,38.938],[-77.535431,38.952708],[-77.557484,38.979111],[-77.6483,39.03522],[-77.671815,38.946576],[-77.655081,38.942635]]]},"properties":{"id":"20105","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.27214,36.964955],[-78.31262,36.91016],[-78.301787,36.879386],[-78.260168,36.919755],[-78.27214,36.964955]]]},"properties":{"id":"23952","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.240872,38.336645],[-78.270723,38.345654],[-78.267714,38.335388],[-78.240872,38.336645]]]},"properties":{"id":"22731","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.612102,38.643052],[-78.386778,38.556518],[-78.33832,38.627282],[-78.313752,38.73719],[-78.350133,38.807098],[-78.386682,38.830152],[-78.546907,38.738022],[-78.612102,38.643052]]]},"properties":{"id":"22835","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.284977,38.475922],[-78.279751,38.450518],[-78.253183,38.46769],[-78.261711,38.477168],[-78.284977,38.475922]]]},"properties":{"id":"22711","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.222455,38.447721],[-78.209636,38.466771],[-78.237282,38.476148],[-78.222455,38.447721]]]},"properties":{"id":"22722","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.183358,38.382783],[-78.185901,38.369125],[-78.172933,38.360592],[-78.141136,38.349739],[-78.131911,38.351021],[-78.149198,38.380914],[-78.183358,38.382783]]]},"properties":{"id":"22730","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.370402,37.459452],[-76.3582,37.419794],[-76.348119,37.41425],[-76.341754,37.374588],[-76.309309,37.402137],[-76.283785,37.417617],[-76.283772,37.419193],[-76.306305,37.462256],[-76.353796,37.482171],[-76.370402,37.459452]]]},"properties":{"id":"23109","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.292668,37.348248],[-76.289335,37.363455],[-76.312534,37.37147],[-76.326576,37.353267],[-76.292668,37.348248]]]},"properties":{"id":"23163","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.571191,36.728893],[-78.470685,36.674975],[-78.452228,36.735848],[-78.572476,36.792184],[-78.571191,36.728893]]]},"properties":{"id":"23968","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.289962,36.782913],[-78.316735,36.72988],[-78.26416,36.618159],[-78.234334,36.635796],[-78.289962,36.782913]]]},"properties":{"id":"23915","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.709457,36.620528],[-78.637021,36.541476],[-78.596047,36.541203],[-78.556218,36.635869],[-78.69033,36.681437],[-78.709457,36.620528]]]},"properties":{"id":"24529","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.64506,37.613945],[-76.549022,37.658599],[-76.55442,37.671364],[-76.644289,37.684756],[-76.64506,37.613945]]]},"properties":{"id":"23175","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.085765,37.504106],[-76.981754,37.489688],[-76.973653,37.541181],[-76.958905,37.574009],[-77.123675,37.626881],[-77.18087,37.58812],[-77.085765,37.504106]]]},"properties":{"id":"23124","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.919816,37.380349],[-75.881897,37.350351],[-75.842237,37.428259],[-75.900542,37.449082],[-75.923099,37.457737],[-75.935166,37.480465],[-75.961642,37.47136],[-75.964884,37.470564],[-75.983378,37.398484],[-75.919816,37.380349]]]},"properties":{"id":"23405","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.897173,37.488618],[-75.923099,37.457737],[-75.900542,37.449082],[-75.86363,37.454073],[-75.84854,37.470666],[-75.83354,37.448292],[-75.829765,37.436615],[-75.809184,37.465743],[-75.791748,37.502544],[-75.801996,37.504743],[-75.804009,37.504026],[-75.897173,37.488618]]]},"properties":{"id":"23413","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.236663,37.889572],[-76.300083,37.9268],[-76.255963,37.89026],[-76.236663,37.889572]]]},"properties":{"id":"22530","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.537078,38.032448],[-76.520823,37.941668],[-76.473518,38.016202],[-76.516369,38.034724],[-76.537078,38.032448]]]},"properties":{"id":"22511","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.272749,38.206797],[-78.168284,38.207642],[-78.220051,38.231569],[-78.25054,38.233768],[-78.272749,38.206797]]]},"properties":{"id":"22972","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.261752,36.692252],[-79.178165,36.708904],[-79.171374,36.736711],[-79.207982,36.771807],[-79.228604,36.77043],[-79.26093,36.765007],[-79.322858,36.682721],[-79.320182,36.679543],[-79.261752,36.692252]]]},"properties":{"id":"24566","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.365648,37.241076],[-77.344401,37.213849],[-77.331126,37.25753],[-77.350004,37.257648],[-77.365648,37.241076]]]},"properties":{"id":"23801","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.331126,37.25753],[-77.157639,37.225922],[-77.08364,37.274488],[-77.095107,37.312468],[-77.270675,37.320756],[-77.295286,37.312426],[-77.356262,37.284757],[-77.350004,37.257648],[-77.331126,37.25753]]]},"properties":{"id":"23860","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.482204,38.784176],[-77.450725,38.732177],[-77.380451,38.722516],[-77.43629,38.793207],[-77.468868,38.796012],[-77.482204,38.784176]]]},"properties":{"id":"20111","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.170415,38.673136],[-78.194881,38.569222],[-78.158711,38.584933],[-78.130483,38.654626],[-78.170415,38.673136]]]},"properties":{"id":"22749","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.905303,38.002002],[-76.810924,37.913467],[-76.728213,37.822236],[-76.720381,37.815664],[-76.679914,37.825344],[-76.619596,37.959176],[-76.648082,37.968396],[-76.664588,37.983831],[-76.706663,38.026285],[-76.720764,38.048312],[-76.93792,38.084903],[-76.936959,38.077074],[-76.905303,38.002002]]]},"properties":{"id":"22572","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.694832,37.80285],[-76.679914,37.825344],[-76.720381,37.815664],[-76.697517,37.804049],[-76.694832,37.80285]]]},"properties":{"id":"22548","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.319125,37.928658],[-79.318625,37.919484],[-79.315,37.929382],[-79.319125,37.928658]]]},"properties":{"id":"24415","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.174112,38.566643],[-78.971691,38.465737],[-79.019337,38.568075],[-79.092947,38.664432],[-79.174112,38.566643]]]},"properties":{"id":"22831","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.459727,36.594586],[-82.46227,36.673438],[-82.504915,36.777127],[-82.52093,36.771489],[-82.693132,36.728377],[-82.8283,36.59375],[-82.609254,36.59406],[-82.560102,36.637089],[-82.542024,36.603815],[-82.566621,36.594709],[-82.459727,36.594586]]]},"properties":{"id":"24251","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.977375,36.870399],[-77.069918,36.884566],[-77.097824,36.869979],[-76.998636,36.754986],[-76.964624,36.781206],[-76.954443,36.798575],[-76.977375,36.870399]]]},"properties":{"id":"23878","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.837535,37.13046],[-76.763403,37.034273],[-76.641581,37.153827],[-76.785476,37.201044],[-76.837535,37.13046]]]},"properties":{"id":"23883","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.225104,37.234874],[-81.279139,37.271564],[-81.393162,37.232061],[-81.295146,37.21165],[-81.249045,37.222698],[-81.225104,37.234874]]],[[[-81.410733,37.28236],[-81.507161,37.234374],[-81.486628,37.226632],[-81.402363,37.238756],[-81.307365,37.290693],[-81.317863,37.297805],[-81.351051,37.287419],[-81.383485,37.318661],[-81.397531,37.304005],[-81.381224,37.285403],[-81.410733,37.28236]]]]},"properties":{"id":"24605","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.791068,36.8602],[-81.790803,36.701308],[-81.705063,36.665879],[-81.740895,36.830708],[-81.791068,36.8602]]]},"properties":{"id":"24340","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.35656,37.063425],[-76.317364,37.103044],[-76.393401,37.108321],[-76.35656,37.063425]]]},"properties":{"id":"23665","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.506101,37.154129],[-76.569243,37.123572],[-76.536874,37.074291],[-76.459134,37.096403],[-76.458516,37.100661],[-76.506101,37.154129]]]},"properties":{"id":"23602","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.35656,37.063425],[-76.397199,37.025538],[-76.356815,37.003113],[-76.328635,37.015494],[-76.303863,37.05332],[-76.311744,37.102],[-76.317364,37.103044],[-76.35656,37.063425]]]},"properties":{"id":"23669","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.265837,36.931594],[-76.323905,36.964563],[-76.341825,36.924772],[-76.32848,36.893219],[-76.322006,36.899632],[-76.292342,36.930395],[-76.278112,36.920695],[-76.265837,36.931594]]]},"properties":{"id":"23511","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.302202,36.873959],[-76.286392,36.873414],[-76.279601,36.88871],[-76.322006,36.899632],[-76.32848,36.893219],[-76.330784,36.868403],[-76.317211,36.860657],[-76.302202,36.873959]]]},"properties":{"id":"23508","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.334252,36.822094],[-76.33733,36.815829],[-76.311061,36.796209],[-76.292828,36.797154],[-76.289962,36.822048],[-76.295863,36.843847],[-76.29796,36.846412],[-76.313995,36.850047],[-76.334252,36.822094]],[[-76.300083,36.813869],[-76.300686,36.809749],[-76.310088,36.812032],[-76.310878,36.814742],[-76.300083,36.813869]]]},"properties":{"id":"23704","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.399567,36.930947],[-76.420368,36.866348],[-76.383466,36.837548],[-76.366065,36.838248],[-76.330784,36.868403],[-76.32848,36.893219],[-76.341825,36.924772],[-76.384419,36.951059],[-76.399567,36.930947]]]},"properties":{"id":"23703","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.313995,36.850047],[-76.29796,36.846412],[-76.306663,36.854047],[-76.313995,36.850047]]]},"properties":{"id":"23708","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.300083,36.813869],[-76.310878,36.814742],[-76.310088,36.812032],[-76.300686,36.809749],[-76.300083,36.813869]]]},"properties":{"id":"23709","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.549451,37.139824],[-80.553166,37.139699],[-80.551131,37.134011],[-80.547123,37.138537],[-80.549451,37.139824]]]},"properties":{"id":"24142","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.542233,37.570748],[-77.53203,37.582571],[-77.545752,37.576074],[-77.542233,37.570748]]]},"properties":{"id":"23173","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.935977,37.272447],[-79.944336,37.273441],[-79.94087,37.266921],[-79.935977,37.272447]]]},"properties":{"id":"24011","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.67787,37.627078],[-75.698752,37.599686],[-75.693228,37.597319],[-75.670105,37.620232],[-75.67787,37.627078]]]},"properties":{"id":"23480","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.700485,37.77933],[-75.700348,37.720396],[-75.697025,37.718415],[-75.64502,37.750036],[-75.700485,37.77933]]]},"properties":{"id":"23357","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.667805,37.950091],[-75.700526,37.896245],[-75.646562,37.896451],[-75.643412,37.916687],[-75.648214,37.946862],[-75.667805,37.950091]]]},"properties":{"id":"23426","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.590241,37.67161],[-78.591789,37.642042],[-78.540894,37.65109],[-78.590241,37.67161]]],[[[-78.590241,37.67161],[-78.618952,37.752004],[-78.684847,37.755517],[-78.703758,37.712506],[-78.692163,37.686015],[-78.590241,37.67161]]]]},"properties":{"id":"24562","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.697786,37.859533],[-78.748592,37.797611],[-78.741126,37.725193],[-78.703758,37.712506],[-78.684847,37.755517],[-78.618732,37.882518],[-78.654397,37.882041],[-78.697786,37.859533]]]},"properties":{"id":"22969","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.174508,37.770793],[-79.291274,37.759067],[-79.303545,37.740751],[-79.290367,37.702651],[-79.110631,37.501937],[-78.953716,37.500963],[-78.894111,37.623139],[-78.996631,37.701671],[-79.02268,37.704425],[-79.027333,37.702478],[-79.067163,37.713492],[-79.174508,37.770793]],[[-79.091599,37.56392],[-79.067323,37.553099],[-79.096521,37.549051],[-79.091599,37.56392]]]},"properties":{"id":"24521","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.894111,37.623139],[-78.953716,37.500963],[-78.941469,37.496405],[-78.904703,37.497799],[-78.728383,37.475833],[-78.639702,37.497839],[-78.683412,37.537131],[-78.780107,37.638071],[-78.829884,37.649283],[-78.894111,37.623139]]]},"properties":{"id":"24553","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.49384,37.537791],[-79.431542,37.454099],[-79.355847,37.482479],[-79.329551,37.512896],[-79.436926,37.617004],[-79.436959,37.617008],[-79.49384,37.537791]]]},"properties":{"id":"24526","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.091599,37.56392],[-79.096521,37.549051],[-79.067323,37.553099],[-79.091599,37.56392]]]},"properties":{"id":"24595","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.905543,37.401116],[-78.884004,37.246571],[-78.856501,37.212443],[-78.752115,37.220803],[-78.616226,37.368058],[-78.594436,37.397974],[-78.61534,37.394165],[-78.728383,37.475833],[-78.904703,37.497799],[-78.905543,37.401116]]]},"properties":{"id":"24522","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.04899,38.84121],[-77.048025,38.841259],[-77.057152,38.880314],[-77.061183,38.869658],[-77.069977,38.859535],[-77.064807,38.844884],[-77.04899,38.84121]]]},"properties":{"id":"22202","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.775212,38.038179],[-79.858027,37.88831],[-79.650757,37.871566],[-79.487484,38.090497],[-79.512158,38.180419],[-79.76623,38.050693],[-79.775212,38.038179]]]},"properties":{"id":"24460","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.809042,37.256607],[-79.704645,37.151523],[-79.70488,37.263188],[-79.809042,37.256607]]]},"properties":{"id":"24095","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.833634,37.139112],[-79.715333,37.109363],[-79.704645,37.151523],[-79.809042,37.256607],[-79.847082,37.225468],[-79.911571,37.170254],[-79.833634,37.139112]]]},"properties":{"id":"24101","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.01498,36.59337],[-78.032588,36.544198],[-77.970969,36.544492],[-77.946511,36.580898],[-77.947656,36.588139],[-77.977288,36.610538],[-78.01498,36.59337]]]},"properties":{"id":"23845","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.739702,36.865125],[-77.720545,36.842492],[-77.657867,36.875922],[-77.658036,36.894514],[-77.744943,36.958291],[-77.822072,36.909691],[-77.833472,36.880551],[-77.739702,36.865125]]]},"properties":{"id":"23889","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.928484,36.978096],[-77.822072,36.909691],[-77.744943,36.958291],[-77.841382,36.995941],[-77.889813,36.988818],[-77.928484,36.978096]]]},"properties":{"id":"23876","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.568839,36.545157],[-77.296875,36.544815],[-77.361258,36.569947],[-77.315415,36.723342],[-77.377992,36.735392],[-77.633581,36.874931],[-77.658036,36.894514],[-77.657867,36.875922],[-77.719977,36.710541],[-77.744203,36.579913],[-77.767117,36.545422],[-77.649253,36.545268],[-77.609268,36.6393],[-77.53636,36.642948],[-77.568839,36.545157]]]},"properties":{"id":"23847","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.854387,37.174994],[-81.904865,37.241935],[-81.929129,37.256023],[-81.945783,37.133818],[-81.85339,37.076391],[-81.831588,37.072692],[-81.835072,37.085606],[-81.844917,37.097261],[-81.83484,37.098118],[-81.826846,37.142436],[-81.854387,37.174994]]]},"properties":{"id":"24639","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.085707,37.332884],[-79.219769,37.264195],[-79.146522,37.209397],[-78.986887,37.21594],[-79.014973,37.295072],[-79.085707,37.332884]]]},"properties":{"id":"24588","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.179557,37.071101],[-79.1954,37.008329],[-79.098299,36.977892],[-79.060247,37.047211],[-79.179557,37.071101]]]},"properties":{"id":"24569","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.932179,36.776928],[-80.78444,36.6495],[-80.767372,36.679978],[-80.841012,36.778861],[-80.917969,36.801499],[-80.932179,36.776928]]]},"properties":{"id":"24381","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.513153,36.672444],[-80.496146,36.750472],[-80.602141,36.742215],[-80.570534,36.676412],[-80.560649,36.671456],[-80.513153,36.672444]]]},"properties":{"id":"24352","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.095107,37.312468],[-76.972439,37.248501],[-76.933664,37.22901],[-76.883156,37.223057],[-76.882047,37.271683],[-76.874206,37.340167],[-76.975988,37.41886],[-77.141791,37.476285],[-77.177324,37.4906],[-77.197726,37.45382],[-77.244185,37.369493],[-77.270675,37.320756],[-77.095107,37.312468]]]},"properties":{"id":"23030","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.832534,37.15896],[-78.770925,37.031685],[-78.737082,37.057846],[-78.73337,37.17211],[-78.751778,37.168765],[-78.832534,37.15896]]]},"properties":{"id":"23959","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.629143,37.492493],[-77.708946,37.497973],[-77.621883,37.462605],[-77.629143,37.492493]]]},"properties":{"id":"23114","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.629143,37.492493],[-77.621883,37.462605],[-77.583081,37.430665],[-77.55551,37.445748],[-77.611493,37.505078],[-77.629143,37.492493]]]},"properties":{"id":"23236","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.403671,37.237346],[-77.400445,37.316756],[-77.466232,37.317206],[-77.456693,37.287904],[-77.417353,37.243821],[-77.41651,37.233157],[-77.403671,37.237346]]]},"properties":{"id":"23834","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.277085,37.352231],[-77.386308,37.386622],[-77.408587,37.377776],[-77.400445,37.316756],[-77.403671,37.237346],[-77.376728,37.244993],[-77.356262,37.284757],[-77.295286,37.312426],[-77.277085,37.352231]]]},"properties":{"id":"23836","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.386922,37.121889],[-82.399298,37.096717],[-82.366049,37.056124],[-82.360846,37.086875],[-82.386922,37.121889]]]},"properties":{"id":"24269","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.097441,37.907299],[-76.969851,37.82867],[-76.945952,37.820117],[-76.936426,37.827703],[-76.810924,37.913467],[-76.905303,38.002002],[-77.070046,37.974743],[-77.107206,37.955292],[-77.097441,37.907299]]]},"properties":{"id":"22560","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.405266,38.860214],[-77.496855,38.887171],[-77.53576,38.847474],[-77.503335,38.824538],[-77.415394,38.839104],[-77.395414,38.840767],[-77.402944,38.852024],[-77.405266,38.860214]]]},"properties":{"id":"20120","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.503335,38.824538],[-77.468868,38.796012],[-77.43629,38.793207],[-77.415394,38.839104],[-77.503335,38.824538]]]},"properties":{"id":"20121","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.218782,38.792764],[-77.174621,38.791811],[-77.176092,38.811897],[-77.256497,38.81078],[-77.247791,38.795932],[-77.218782,38.792764]]]},"properties":{"id":"22151","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.131849,38.861727],[-77.13756,38.866142],[-77.143637,38.870877],[-77.153929,38.878963],[-77.159099,38.87475],[-77.174704,38.846474],[-77.131849,38.861727]]]},"properties":{"id":"22044","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.263606,38.772549],[-77.208216,38.761896],[-77.218782,38.792764],[-77.247791,38.795932],[-77.263606,38.772549]]]},"properties":{"id":"22152","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.678444,38.509851],[-77.773138,38.641536],[-77.802897,38.638462],[-77.837246,38.594599],[-77.761389,38.50909],[-77.675143,38.465902],[-77.678444,38.509851]]]},"properties":{"id":"22712","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.079677,38.849005],[-78.105838,38.837869],[-78.050597,38.803096],[-78.02814,38.759658],[-77.985485,38.782106],[-77.96721,38.833173],[-77.995106,38.857843],[-77.997146,38.848036],[-78.079677,38.849005]]]},"properties":{"id":"22639","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.142821,36.980637],[-80.128119,37.122912],[-80.178032,37.113435],[-80.214079,37.07444],[-80.190895,37.011475],[-80.142821,36.980637]]]},"properties":{"id":"24079","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.351717,39.33145],[-78.403907,39.167738],[-78.29059,39.23332],[-78.279964,39.30143],[-78.351717,39.33145]]]},"properties":{"id":"22637","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.245477,39.402777],[-78.351717,39.33145],[-78.279964,39.30143],[-78.245477,39.402777]]]},"properties":{"id":"22625","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.128245,39.235339],[-78.083679,39.181877],[-78.073162,39.178388],[-78.045316,39.236465],[-78.128245,39.235339]]]},"properties":{"id":"22656","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.118494,37.724255],[-78.072256,37.657357],[-77.88986,37.664315],[-77.860249,37.750949],[-77.923118,37.757809],[-77.942208,37.762179],[-77.998232,37.774874],[-77.989476,37.807236],[-77.999776,37.825433],[-78.025868,37.827812],[-78.039318,37.822654],[-78.118494,37.724255]]]},"properties":{"id":"23063","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.203933,36.571929],[-81.10481,36.568001],[-81.028819,36.671091],[-81.092945,36.700113],[-81.281997,36.687146],[-81.300371,36.648542],[-81.203933,36.571929]]]},"properties":{"id":"24348","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.465146,37.060325],[-79.487448,36.944714],[-79.26897,36.854386],[-79.127736,36.915151],[-79.098299,36.977892],[-79.1954,37.008329],[-79.371723,37.113551],[-79.465146,37.060325]]]},"properties":{"id":"24557","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.447118,37.572188],[-77.435632,37.555513],[-77.424702,37.550769],[-77.3828,37.595322],[-77.406257,37.611851],[-77.407189,37.612524],[-77.447118,37.572188]]]},"properties":{"id":"23222","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.3001,37.489267],[-77.197726,37.45382],[-77.177324,37.4906],[-77.228504,37.536398],[-77.29528,37.560448],[-77.342475,37.534765],[-77.340424,37.498954],[-77.304251,37.511506],[-77.3001,37.489267]]]},"properties":{"id":"23150","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.916151,37.686477],[-76.878953,37.656459],[-76.77467,37.691642],[-76.73896,37.694777],[-76.751072,37.728605],[-76.919242,37.762887],[-76.921427,37.740434],[-76.916151,37.686477]]]},"properties":{"id":"23085","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.98499,36.593729],[-82.899797,36.701271],[-83.174533,36.593895],[-82.98499,36.593729]]]},"properties":{"id":"24221","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.405614,36.597385],[-83.414829,36.670208],[-83.532404,36.664689],[-83.62204,36.59798],[-83.405614,36.597385]]]},"properties":{"id":"24248","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.440383,38.944407],[-77.429278,38.955744],[-77.422422,38.962604],[-77.402509,38.982709],[-77.401564,39.030176],[-77.432464,39.046727],[-77.467012,38.993544],[-77.535431,38.952708],[-77.541478,38.938],[-77.440383,38.944407]]]},"properties":{"id":"20166","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.432464,39.046727],[-77.443625,39.07122],[-77.464105,39.07945],[-77.511209,39.085911],[-77.530888,39.043563],[-77.467012,38.993544],[-77.432464,39.046727]]]},"properties":{"id":"20147","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.660654,39.177222],[-77.682452,39.091989],[-77.612379,39.140792],[-77.612018,39.141953],[-77.611477,39.17858],[-77.660654,39.177222]]]},"properties":{"id":"20158","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.611477,39.17858],[-77.612018,39.141953],[-77.592585,39.182928],[-77.611477,39.17858]]]},"properties":{"id":"20129","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.292668,37.348248],[-76.291037,37.294591],[-76.266609,37.338934],[-76.289335,37.363455],[-76.292668,37.348248]]]},"properties":{"id":"23125","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.861303,37.445522],[-76.776893,37.433923],[-76.741984,37.467076],[-76.782319,37.501597],[-76.792184,37.517594],[-76.864325,37.504541],[-76.861303,37.445522]]]},"properties":{"id":"23011","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.881897,37.350351],[-75.919816,37.380349],[-75.985311,37.318626],[-75.983737,37.295112],[-75.99974,37.301247],[-75.999655,37.313973],[-76.023891,37.28344],[-75.935632,37.18952],[-75.956674,37.197595],[-75.937743,37.213509],[-75.881897,37.350351]]]},"properties":{"id":"23310","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.961642,37.47136],[-75.935414,37.550406],[-75.941571,37.549202],[-75.964884,37.470564],[-75.961642,37.47136]]]},"properties":{"id":"23398","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.937743,37.213509],[-75.956674,37.197595],[-75.935632,37.18952],[-75.937743,37.213509]]]},"properties":{"id":"23313","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.341877,36.78777],[-80.369444,36.669975],[-80.407811,36.54911],[-80.125703,36.542686],[-80.096685,36.592573],[-80.144901,36.630382],[-80.128037,36.729482],[-80.08364,36.807968],[-80.196724,36.814506],[-80.341877,36.78777]]]},"properties":{"id":"24171","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.53466,36.554717],[-80.407811,36.54911],[-80.369444,36.669975],[-80.410985,36.64762],[-80.53466,36.554717]]]},"properties":{"id":"24076","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.53298,37.039393],[-79.594267,36.934121],[-79.517778,36.948888],[-79.53298,37.039393]]]},"properties":{"id":"24161","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.618052,36.644877],[-79.517803,36.540784],[-79.342686,36.541366],[-79.350019,36.549291],[-79.593841,36.679317],[-79.597349,36.696133],[-79.618052,36.644877]]]},"properties":{"id":"24541","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.093059,37.120512],[-76.949254,37.110823],[-76.842112,37.124542],[-76.837535,37.13046],[-76.785476,37.201044],[-76.883156,37.223057],[-76.933664,37.22901],[-76.968183,37.215177],[-76.972439,37.248501],[-77.095107,37.312468],[-77.08364,37.274488],[-77.093059,37.120512]]]},"properties":{"id":"23881","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.692049,38.796722],[-77.656583,38.746147],[-77.600636,38.759523],[-77.56875,38.786878],[-77.548888,38.82558],[-77.591289,38.880859],[-77.673946,38.808482],[-77.692049,38.796722]]]},"properties":{"id":"20155","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.450725,38.732177],[-77.506097,38.705681],[-77.491154,38.687157],[-77.454367,38.631775],[-77.428634,38.587171],[-77.357017,38.589825],[-77.37237,38.617671],[-77.359853,38.681165],[-77.375044,38.71305],[-77.380451,38.722516],[-77.450725,38.732177]]]},"properties":{"id":"20112","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.600636,38.759523],[-77.491154,38.687157],[-77.506097,38.705681],[-77.530283,38.738285],[-77.56875,38.786878],[-77.600636,38.759523]]]},"properties":{"id":"20136","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.301104,38.54952],[-77.333362,38.569633],[-77.357017,38.589825],[-77.428634,38.587171],[-77.359969,38.530287],[-77.301104,38.54952]]]},"properties":{"id":"22172","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.130483,38.654626],[-78.158711,38.584933],[-78.095284,38.591852],[-78.047467,38.624093],[-78.091637,38.692667],[-78.130483,38.654626]]]},"properties":{"id":"22716","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.499812,37.929262],[-79.367148,37.874929],[-79.318625,37.919484],[-79.319125,37.928658],[-79.344226,38.000045],[-79.418832,37.969577],[-79.499812,37.929262]]]},"properties":{"id":"24473","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.577929,37.598011],[-79.498553,37.532887],[-79.49384,37.537791],[-79.436959,37.617008],[-79.524486,37.631699],[-79.577929,37.598011]]]},"properties":{"id":"24579","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.82437,38.405686],[-78.80564,38.344846],[-78.800912,38.344473],[-78.769775,38.396212],[-78.811469,38.409782],[-78.82437,38.405686]]]},"properties":{"id":"22846","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.891402,38.778447],[-79.067573,38.752043],[-78.937555,38.75963],[-78.891402,38.778447]]]},"properties":{"id":"22811","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.763884,38.709537],[-78.706133,38.5808],[-78.667112,38.557877],[-78.641536,38.604939],[-78.612102,38.643052],[-78.546907,38.738022],[-78.567015,38.728847],[-78.612485,38.710133],[-78.655263,38.720843],[-78.763884,38.709537]]]},"properties":{"id":"22844","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.194281,36.926209],[-82.344758,36.748507],[-82.332089,36.709355],[-82.322313,36.71647],[-82.195504,36.772003],[-81.946985,36.864815],[-81.963404,36.930925],[-81.936294,36.984279],[-82.079418,36.967759],[-82.194281,36.926209]]]},"properties":{"id":"24266","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.431499,39.065769],[-78.422901,39.05808],[-78.35335,39.080388],[-78.394156,39.092748],[-78.431499,39.065769]]]},"properties":{"id":"22641","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.132171,36.632256],[-77.288004,36.65669],[-77.296276,36.628864],[-77.203548,36.544753],[-77.1388,36.544031],[-77.132171,36.632256]]]},"properties":{"id":"23827","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.972439,37.248501],[-76.968183,37.215177],[-76.933664,37.22901],[-76.972439,37.248501]]]},"properties":{"id":"23899","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.189501,36.859016],[-77.283853,36.907296],[-77.354138,36.834849],[-77.334701,36.757149],[-77.179908,36.829457],[-77.189501,36.859016]]]},"properties":{"id":"23897","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.306946,37.077047],[-81.367107,37.166589],[-81.600204,37.122295],[-81.626893,37.110779],[-81.761871,36.994551],[-81.780539,36.958614],[-81.709072,36.976858],[-81.459331,37.03277],[-81.306946,37.077047]]]},"properties":{"id":"24651","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.675419,37.060949],[-82.513435,37.085148],[-82.540314,37.120067],[-82.553844,37.202854],[-82.72502,37.042419],[-82.675419,37.060949]]]},"properties":{"id":"24279","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.223084,36.913724],[-81.159914,36.84935],[-81.140222,36.843295],[-80.983746,36.863112],[-80.969116,36.869064],[-80.898057,37.03878],[-80.912674,37.073349],[-81.107104,37.025101],[-81.2279,37.045941],[-81.33922,36.974917],[-81.223084,36.913724]]]},"properties":{"id":"24382","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.189718,36.818849],[-81.273457,36.79469],[-81.26162,36.76312],[-81.130219,36.767296],[-81.16019,36.825148],[-81.189718,36.818849]]]},"properties":{"id":"24374","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.569011,37.17463],[-76.525873,37.177045],[-76.545178,37.198273],[-76.569862,37.218793],[-76.594715,37.228135],[-76.600701,37.17521],[-76.569011,37.17463]]]},"properties":{"id":"23603","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.449733,37.043236],[-76.450754,37.021231],[-76.411284,37.003574],[-76.417505,37.013249],[-76.449733,37.043236]]]},"properties":{"id":"23605","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.435519,37.094882],[-76.458516,37.100661],[-76.459134,37.096403],[-76.53593,37.023699],[-76.486198,36.988604],[-76.450754,37.021231],[-76.449733,37.043236],[-76.435519,37.094882]]]},"properties":{"id":"23601","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.252708,36.91589],[-76.215069,36.883064],[-76.184598,36.908658],[-76.177092,36.930054],[-76.21476,36.937547],[-76.253237,36.930283],[-76.252708,36.91589]]]},"properties":{"id":"23518","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.215069,36.883064],[-76.252708,36.91589],[-76.256485,36.904534],[-76.247893,36.863289],[-76.215069,36.883064]]]},"properties":{"id":"23513","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.292342,36.930395],[-76.294208,36.919577],[-76.278112,36.920695],[-76.292342,36.930395]]]},"properties":{"id":"23551","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.366065,36.838248],[-76.334252,36.822094],[-76.313995,36.850047],[-76.306663,36.854047],[-76.313533,36.858533],[-76.317211,36.860657],[-76.330784,36.868403],[-76.366065,36.838248]]]},"properties":{"id":"23707","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.470292,37.562505],[-77.48388,37.531755],[-77.44955,37.532557],[-77.435632,37.555513],[-77.447118,37.572188],[-77.462824,37.575815],[-77.470292,37.562505]]]},"properties":{"id":"23220","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.855654,36.674438],[-76.915897,36.552093],[-76.856006,36.551235],[-76.672206,36.634649],[-76.75581,36.746046],[-76.855654,36.674438]]]},"properties":{"id":"23437","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.126357,36.853948],[-76.101808,36.805339],[-76.06147,36.815692],[-76.090355,36.898547],[-76.126357,36.853948]]]},"properties":{"id":"23452","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.101808,36.805339],[-76.126357,36.853948],[-76.188443,36.874108],[-76.194674,36.830837],[-76.128426,36.799148],[-76.126927,36.798298],[-76.101808,36.805339]]]},"properties":{"id":"23462","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.04407,36.780022],[-75.949391,36.760764],[-75.963325,36.804948],[-76.090755,36.901447],[-76.090355,36.898547],[-76.06147,36.815692],[-76.04407,36.780022]],[[-75.961898,36.776357],[-75.960842,36.773512],[-75.964093,36.772743],[-75.965282,36.775954],[-75.961898,36.776357]],[[-76.032633,36.807376],[-76.030141,36.812778],[-76.022086,36.806942],[-76.027019,36.803307],[-76.032633,36.807376]]]},"properties":{"id":"23454","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.723584,37.265708],[-76.717646,37.265876],[-76.717652,37.269161],[-76.722804,37.270061],[-76.724133,37.274867],[-76.723584,37.265708]]]},"properties":{"id":"23187","state_code":"51"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.816913,48.148379],[-120.441951,47.637557],[-120.237548,47.627959],[-120.184962,47.761541],[-120.816913,48.148379]],[[-120.350303,47.709061],[-120.402576,47.717659],[-120.368038,47.738399],[-120.350303,47.709061]]]},"properties":{"id":"98822","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.552441,47.578561],[-120.572796,47.545925],[-120.550491,47.559379],[-120.552441,47.578561]]]},"properties":{"id":"98821","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.547561,48.354362],[-124.680133,48.275161],[-124.622882,48.274863],[-124.547561,48.354362]]]},"properties":{"id":"98357","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.691561,45.670843],[-122.664842,45.631559],[-122.636501,45.671618],[-122.691561,45.670843]]]},"properties":{"id":"98663","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.45684,45.638823],[-122.56314,45.642057],[-122.559399,45.621029],[-122.475948,45.621288],[-122.45684,45.638823]]]},"properties":{"id":"98684","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.948282,46.970986],[-119.605293,47.030723],[-119.619775,47.103775],[-119.747393,47.439222],[-120.004691,47.309249],[-120.047153,47.225527],[-120.016897,47.043523],[-120.002143,46.99732],[-119.986617,46.971802],[-119.948282,46.970986]],[[-119.866367,47.073316],[-119.857689,47.085108],[-119.850817,47.074143],[-119.866367,47.073316]]]},"properties":{"id":"98848","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.83655,48.826978],[-118.895872,48.65372],[-118.86954,48.481881],[-118.84395,48.438947],[-118.844447,48.396494],[-118.575246,48.353798],[-118.286175,48.481348],[-118.435269,48.740904],[-118.739707,48.828339],[-118.83655,48.826978]]]},"properties":{"id":"99166","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.533353,47.470013],[-119.45035,47.25731],[-119.277142,47.39539],[-119.276846,47.4275],[-119.276155,47.499756],[-119.27683,47.507877],[-119.533353,47.470013]]]},"properties":{"id":"98851","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.054957,47.864815],[-118.977967,47.553366],[-118.849542,47.53752],[-118.840666,47.903229],[-118.974008,47.906376],[-119.054957,47.864815]]]},"properties":{"id":"99103","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.605293,47.030723],[-119.948282,46.970986],[-119.816261,46.831395],[-119.369125,46.795592],[-119.557649,46.962996],[-119.605293,47.030723]]]},"properties":{"id":"99357","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.839616,47.24418],[-124.090107,46.931703],[-124.126286,46.920832],[-124.070779,46.861828],[-123.915181,46.792641],[-123.852235,46.791598],[-123.764279,46.957582],[-123.75466,47.315616],[-123.792447,47.328736],[-123.839616,47.24418]]]},"properties":{"id":"98520","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.527299,48.063682],[-122.484646,47.99105],[-122.355838,47.993911],[-122.527299,48.063682]]]},"properties":{"id":"98260","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.405747,48.252177],[-122.540701,48.250735],[-122.54694,48.182602],[-122.397998,48.223847],[-122.405747,48.252177]]]},"properties":{"id":"98282","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.727499,48.307583],[-122.703202,48.307865],[-122.671147,48.369872],[-122.727499,48.307583]]]},"properties":{"id":"98278","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.759131,48.053008],[-122.872975,48.047299],[-122.949166,47.992635],[-122.821747,47.925902],[-122.79169,48.042009],[-122.759131,48.053008]]]},"properties":{"id":"98368","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.205589,47.343414],[-122.165537,47.34893],[-122.165415,47.385006],[-122.231109,47.386651],[-122.229173,47.353769],[-122.205589,47.343414]]]},"properties":{"id":"98030","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.329706,47.350222],[-122.334138,47.340407],[-122.309417,47.357942],[-122.284362,47.422778],[-122.307993,47.422758],[-122.335212,47.423273],[-122.343594,47.408505],[-122.329706,47.350222]]]},"properties":{"id":"98198","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.296731,47.357844],[-122.297974,47.262055],[-122.293736,47.257251],[-122.262259,47.257554],[-122.230694,47.271931],[-122.232422,47.353714],[-122.296731,47.357844]]]},"properties":{"id":"98001","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.315606,47.618776],[-122.263535,47.62432],[-122.255735,47.64712],[-122.304599,47.647402],[-122.310437,47.64712],[-122.315606,47.618776]]]},"properties":{"id":"98112","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.185248,47.474086],[-122.186581,47.423075],[-122.161506,47.41927],[-122.083319,47.391131],[-122.07672,47.45992],[-122.168493,47.477703],[-122.180483,47.477433],[-122.185248,47.474086]]]},"properties":{"id":"98058","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.153403,47.660559],[-122.132354,47.632695],[-122.07922,47.627534],[-122.08758,47.657362],[-122.083764,47.709446],[-122.0876,47.718426],[-122.151867,47.718612],[-122.164425,47.704132],[-122.15875,47.660674],[-122.153403,47.660559]]]},"properties":{"id":"98052","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.168493,47.477703],[-122.16556,47.540015],[-122.202007,47.546025],[-122.225394,47.521368],[-122.216608,47.501321],[-122.180483,47.477433],[-122.168493,47.477703]]]},"properties":{"id":"98056","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.114329,47.795557],[-122.0876,47.718426],[-122.083764,47.709446],[-122.014039,47.714138],[-122.00774,47.775323],[-122.018295,47.77539],[-122.114329,47.795557]]]},"properties":{"id":"98077","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.335847,47.263808],[-122.334138,47.340407],[-122.329706,47.350222],[-122.418293,47.320631],[-122.335847,47.263808]]]},"properties":{"id":"98023","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.232422,47.353714],[-122.230694,47.271931],[-122.229982,47.265887],[-122.205589,47.343414],[-122.229173,47.353769],[-122.232422,47.353714]]]},"properties":{"id":"98002","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.336029,47.255916],[-122.326469,47.237864],[-122.293902,47.242744],[-122.293736,47.257251],[-122.297974,47.262055],[-122.33486,47.257594],[-122.336029,47.255916]]]},"properties":{"id":"98354","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.52658,47.405127],[-122.433432,47.409252],[-122.430723,47.469634],[-122.485512,47.484558],[-122.52658,47.405127]]]},"properties":{"id":"98070","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.843017,47.616123],[-121.908721,47.529516],[-121.884511,47.504578],[-121.822725,47.447451],[-121.698711,47.635639],[-121.71538,47.688926],[-121.791637,47.693596],[-121.843017,47.616123]]]},"properties":{"id":"98065","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.332911,47.606086],[-122.332258,47.605372],[-122.331148,47.605837],[-122.3318,47.606551],[-122.332911,47.606086]]]},"properties":{"id":"98164","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.509646,47.545959],[-122.50107,47.529405],[-122.480241,47.528732],[-122.47723,47.545818],[-122.509646,47.545959]]]},"properties":{"id":"98353","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.678446,46.687717],[-120.614424,46.699327],[-120.91539,46.911262],[-121.379671,47.086765],[-121.494188,46.909053],[-121.456356,46.783744],[-121.412449,46.578643],[-120.887776,46.616724],[-120.887869,46.621243],[-120.779082,46.745434],[-120.678446,46.687717]],[[-121.321181,46.856229],[-121.319469,46.861255],[-121.285551,46.886715],[-121.294721,46.867374],[-121.306282,46.868158],[-121.321181,46.856229]]]},"properties":{"id":"98937","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.522392,45.724677],[-121.351267,45.704235],[-121.331634,45.811947],[-121.384813,45.89108],[-121.453458,45.916821],[-121.608583,45.84264],[-121.606903,45.782568],[-121.522392,45.724677]]]},"properties":{"id":"98672","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.942464,45.667703],[-121.063179,45.825074],[-121.051617,45.670811],[-120.942464,45.667703]]]},"properties":{"id":"98613","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.295416,46.649937],[-122.316801,46.762959],[-122.481081,46.763123],[-122.59428,46.560975],[-122.468861,46.593939],[-122.295416,46.649937]]]},"properties":{"id":"98533","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.625727,46.546571],[-122.588152,46.503275],[-122.368615,46.521527],[-122.468861,46.593939],[-122.59428,46.560975],[-122.625727,46.546571]]]},"properties":{"id":"98585","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.127212,46.75394],[-121.850792,46.729178],[-121.456356,46.783744],[-121.494188,46.909053],[-121.701319,46.996009],[-121.917349,46.858091],[-122.162829,46.796754],[-122.127212,46.75394]]]},"properties":{"id":"98304","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.910225,47.794705],[-117.733926,47.787114],[-117.699981,47.86061],[-117.766486,47.943198],[-117.922912,48.002405],[-117.948713,47.827316],[-117.910225,47.794705]]]},"properties":{"id":"99013","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.937917,47.660673],[-117.821827,47.579842],[-117.711572,47.70129],[-117.717429,47.78078],[-117.733926,47.787114],[-117.910225,47.794705],[-117.937917,47.660673]]]},"properties":{"id":"99029","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.362915,48.624655],[-120.737616,48.840278],[-120.654756,48.52299],[-120.362915,48.624655]]]},"properties":{"id":"98833","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.238086,48.095419],[-120.142485,48.053726],[-119.965521,48.156552],[-120.238086,48.095419]]]},"properties":{"id":"98834","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.956096,48.557858],[-119.704822,48.613359],[-119.687972,48.69983],[-119.90619,48.704825],[-119.956096,48.557858]]]},"properties":{"id":"98819","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.963706,46.562129],[-123.939009,46.598946],[-124.001277,46.647019],[-123.963706,46.562129]]]},"properties":{"id":"98527","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.047086,46.325872],[-124.067854,46.338786],[-124.069215,46.330325],[-124.047086,46.325872]]]},"properties":{"id":"98644","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.086886,46.717195],[-124.060256,46.464883],[-124.006075,46.458489],[-124.006657,46.461513],[-123.963706,46.562129],[-124.001277,46.647019],[-124.001278,46.676645],[-124.020413,46.690231],[-124.086886,46.717195]],[[-124.030507,46.54235],[-124.031021,46.549113],[-124.028522,46.549123],[-124.030507,46.54235]]]},"properties":{"id":"98640","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.64377,46.355872],[-123.52987,46.31333],[-123.460297,46.383693],[-123.467207,46.441442],[-123.64377,46.355872]]]},"properties":{"id":"98621","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.500523,48.442983],[-117.500068,48.326762],[-117.175499,48.397413],[-117.035335,48.438225],[-117.033708,48.655338],[-117.278594,48.641425],[-117.499722,48.652887],[-117.500523,48.442983]]]},"properties":{"id":"99119","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.326469,47.237864],[-122.341697,47.213934],[-122.357194,47.180901],[-122.357663,47.170026],[-122.293489,47.169796],[-122.293902,47.242744],[-122.326469,47.237864]]]},"properties":{"id":"98371","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.551529,47.269154],[-122.503797,47.254812],[-122.468328,47.255443],[-122.46943,47.273336],[-122.544138,47.27792],[-122.551529,47.269154]],[[-122.484106,47.26459],[-122.479444,47.265463],[-122.477871,47.259904],[-122.484122,47.259939],[-122.484106,47.26459]]]},"properties":{"id":"98406","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.101905,47.1355],[-122.085589,47.133581],[-122.080598,47.140981],[-122.098938,47.140996],[-122.101905,47.1355]]]},"properties":{"id":"98385","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.574964,47.325621],[-122.687544,47.334318],[-122.706467,47.318284],[-122.681141,47.271318],[-122.563107,47.250568],[-122.551529,47.269154],[-122.544138,47.27792],[-122.560339,47.316119],[-122.574964,47.325621]]]},"properties":{"id":"98335","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.972355,47.134601],[-122.041396,47.109787],[-122.063107,47.079504],[-121.972355,47.134601]]]},"properties":{"id":"98396","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.851876,47.251956],[-122.731408,47.206768],[-122.675341,47.251319],[-122.681141,47.271318],[-122.706467,47.318284],[-122.763259,47.344052],[-122.806499,47.34348],[-122.80443,47.335667],[-122.814816,47.306816],[-122.851876,47.251956]]]},"properties":{"id":"98349","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.442466,47.143353],[-122.444038,47.145738],[-122.447713,47.145725],[-122.44776,47.144119],[-122.442466,47.143353]]]},"properties":{"id":"98447","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.84254,48.55719],[-122.80583,48.479689],[-122.786026,48.528884],[-122.84254,48.55719]]],[[[-122.554197,48.444572],[-122.51507,48.454939],[-122.514837,48.468839],[-122.655248,48.404678],[-122.626281,48.409415],[-122.554197,48.444572]]]]},"properties":{"id":"98221","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.876291,48.584741],[-122.957997,48.631863],[-122.96495,48.597664],[-122.876291,48.584741]]]},"properties":{"id":"98280","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.789494,48.681359],[-122.842426,48.585443],[-122.801548,48.589672],[-122.789494,48.681359]]]},"properties":{"id":"98279","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.049978,48.673148],[-123.001295,48.690763],[-123.001298,48.720103],[-123.047394,48.717835],[-123.049978,48.673148]]]},"properties":{"id":"98297","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.678912,48.146037],[-121.444071,48.026164],[-120.96335,48.135629],[-120.906211,48.163848],[-120.963184,48.210733],[-121.001409,48.295548],[-121.556195,48.383858],[-121.616582,48.384516],[-121.636412,48.298531],[-121.678912,48.146037]]]},"properties":{"id":"98241","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.482971,48.497923],[-122.444409,48.493947],[-122.345646,48.615901],[-122.488593,48.645355],[-122.482971,48.497923]]]},"properties":{"id":"98232","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.071937,48.524466],[-122.072028,48.515723],[-122.066073,48.518259],[-122.065947,48.521209],[-122.071937,48.524466]]]},"properties":{"id":"98263","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.416536,47.613716],[-117.377603,47.641767],[-117.411383,47.642697],[-117.429468,47.628238],[-117.416536,47.613716]]]},"properties":{"id":"99203","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.152969,47.93766],[-117.349664,48.006874],[-117.387489,47.889619],[-117.24138,47.861398],[-117.152969,47.93766]]]},"properties":{"id":"99003","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.376085,47.708215],[-117.41124,47.707898],[-117.412774,47.672802],[-117.411138,47.671776],[-117.346716,47.673479],[-117.346855,47.676227],[-117.376085,47.708215]]]},"properties":{"id":"99207","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.133391,47.763676],[-117.079146,47.686186],[-117.041732,47.6977],[-117.042574,47.874453],[-117.145206,47.81614],[-117.133391,47.763676]]]},"properties":{"id":"99025","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.649207,47.961281],[-117.55363,48.079263],[-117.62907,48.019136],[-117.649207,47.961281]]]},"properties":{"id":"99110","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.499722,48.652887],[-117.498574,48.836892],[-117.4295,49.000483],[-117.630944,49.000644],[-117.878903,48.802223],[-117.989732,48.655148],[-118.063161,48.510189],[-118.056126,48.350889],[-118.031413,48.350901],[-117.771574,48.414489],[-117.500523,48.442983],[-117.499722,48.652887]]]},"properties":{"id":"99114","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.771574,48.414489],[-117.97523,48.223329],[-117.985217,48.13043],[-117.929379,48.108745],[-117.607149,48.169473],[-117.502157,48.200408],[-117.502145,48.209101],[-117.500068,48.326762],[-117.500523,48.442983],[-117.771574,48.414489]]]},"properties":{"id":"99109","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039813,46.425425],[-117.039695,46.587732],[-117.252749,46.543071],[-117.237872,46.470056],[-117.039813,46.425425]]]},"properties":{"id":"99179","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.17172,47.18195],[-117.184585,47.064647],[-117.039886,47.019595],[-117.039872,47.181761],[-117.17172,47.18195]]]},"properties":{"id":"99128","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.614424,46.699327],[-120.678446,46.687717],[-120.68536,46.680314],[-120.690766,46.648694],[-120.809408,46.664528],[-120.887869,46.621243],[-120.887776,46.616724],[-120.562046,46.574662],[-120.556897,46.623595],[-120.55695,46.626281],[-120.614424,46.699327]]]},"properties":{"id":"98908","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.557649,46.962996],[-119.369125,46.795592],[-119.368939,46.678204],[-119.127985,46.664771],[-118.785808,46.764406],[-118.89803,46.9114],[-119.266996,46.985405],[-119.557649,46.962996]]]},"properties":{"id":"99344","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.666013,48.451759],[-120.622994,48.224291],[-120.238086,48.095419],[-120.383719,48.221995],[-120.666013,48.451759]]]},"properties":{"id":"98852","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.411189,47.48628],[-120.45321,47.479286],[-120.425137,47.447081],[-120.411189,47.48628]]]},"properties":{"id":"98836","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.350303,47.709061],[-120.368038,47.738399],[-120.402576,47.717659],[-120.350303,47.709061]]]},"properties":{"id":"98811","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.978422,47.800702],[-120.020455,47.779327],[-119.992679,47.78315],[-119.978422,47.800702]]]},"properties":{"id":"98817","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.630985,47.895802],[-123.696662,47.88108],[-124.33298,47.971083],[-124.622304,48.037964],[-124.679219,48.01843],[-124.639639,47.920328],[-124.59023,47.912151],[-124.59018,47.893392],[-124.630985,47.895802]]]},"properties":{"id":"98331","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.646992,46.022475],[-122.812215,45.954459],[-122.785515,45.850536],[-122.711319,45.872971],[-122.556388,45.925405],[-122.548716,45.964327],[-122.646992,46.022475]]]},"properties":{"id":"98674","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.56314,45.642057],[-122.45684,45.638823],[-122.393454,45.694262],[-122.552698,45.707961],[-122.56314,45.642057]]]},"properties":{"id":"98682","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.166724,46.136708],[-117.992905,46.062729],[-118.08607,46.45272],[-118.18412,46.44847],[-118.294079,46.250821],[-118.14989,46.167848],[-118.1323,46.157852],[-118.166724,46.136708]]]},"properties":{"id":"99361","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.024405,46.315862],[-122.989756,46.387452],[-123.046811,46.387992],[-123.138897,46.386881],[-123.080988,46.323639],[-123.024405,46.315862]]]},"properties":{"id":"98581","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.735289,46.227713],[-122.302237,46.385709],[-122.580065,46.387654],[-122.702614,46.387265],[-122.725507,46.330104],[-122.774657,46.29253],[-122.785643,46.246397],[-122.735289,46.227713]]]},"properties":{"id":"98649","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.395179,48.863891],[-118.579125,49.00029],[-118.836903,49.000312],[-118.83655,48.826978],[-118.739707,48.828339],[-118.424386,48.758985],[-118.400985,48.80178],[-118.395179,48.863891]]]},"properties":{"id":"99118","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.435269,48.740904],[-118.286175,48.481348],[-118.202444,48.418722],[-118.063161,48.510189],[-117.989732,48.655148],[-118.067778,48.662558],[-118.068988,48.662334],[-117.98957,48.815463],[-117.931927,48.999913],[-118.196824,49.000297],[-118.201601,48.914974],[-118.400985,48.80178],[-118.424386,48.758985],[-118.435269,48.740904]]]},"properties":{"id":"99141","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.227012,46.500546],[-118.922036,46.502752],[-119.007146,46.546773],[-119.227012,46.500546]]]},"properties":{"id":"99330","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.65159,46.735852],[-118.649747,46.591629],[-118.537174,46.561583],[-118.228928,46.593606],[-118.228924,46.664127],[-118.505473,46.736762],[-118.65159,46.735852]]]},"properties":{"id":"99335","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.986617,46.971802],[-119.964956,46.863626],[-119.816261,46.831395],[-119.948282,46.970986],[-119.986617,46.971802]]]},"properties":{"id":"99321","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.866367,47.073316],[-119.850817,47.074143],[-119.857689,47.085108],[-119.866367,47.073316]]]},"properties":{"id":"98824","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.695944,47.517734],[-123.799426,47.517847],[-123.967306,47.369203],[-123.792447,47.328736],[-123.75466,47.315616],[-123.695944,47.517734]]]},"properties":{"id":"98575","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.980706,47.784906],[-122.847441,47.771481],[-122.736135,47.804945],[-122.736079,47.890519],[-122.821747,47.925902],[-122.949166,47.992635],[-123.037561,47.866898],[-122.980706,47.784906]]]},"properties":{"id":"98376","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.360539,47.65292],[-122.357363,47.618608],[-122.334304,47.618529],[-122.328298,47.61848],[-122.337538,47.63972],[-122.354463,47.649932],[-122.360539,47.65292]]]},"properties":{"id":"98109","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.358076,47.701472],[-122.357441,47.675997],[-122.354463,47.649932],[-122.337538,47.63972],[-122.326537,47.65032],[-122.328225,47.671567],[-122.329963,47.701439],[-122.358076,47.701472]]]},"properties":{"id":"98103","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357363,47.618608],[-122.369903,47.618764],[-122.351255,47.60901],[-122.334304,47.618529],[-122.357363,47.618608]]]},"properties":{"id":"98121","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.227282,47.776637],[-122.245212,47.731771],[-122.175385,47.736695],[-122.173598,47.776204],[-122.227282,47.776637]]]},"properties":{"id":"98011","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.782086,47.276641],[-121.932913,47.317762],[-121.996029,47.280117],[-122.114421,47.228242],[-122.115651,47.220837],[-121.948011,47.142662],[-121.701319,46.996009],[-121.494188,46.909053],[-121.379671,47.086765],[-121.30257,47.140555],[-121.339553,47.276928],[-121.782086,47.276641]]]},"properties":{"id":"98022","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.334138,47.340407],[-122.335847,47.263808],[-122.33486,47.257594],[-122.297974,47.262055],[-122.296731,47.357844],[-122.309417,47.357942],[-122.334138,47.340407]]]},"properties":{"id":"98003","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.334674,47.606336],[-122.332911,47.606086],[-122.333564,47.606799],[-122.334674,47.606336]]]},"properties":{"id":"98154","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.334479,47.604445],[-122.335131,47.605158],[-122.33624,47.604693],[-122.335588,47.60398],[-122.334479,47.604445]]]},"properties":{"id":"98174","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.58514,47.59851],[-122.684555,47.610816],[-122.646463,47.583662],[-122.607123,47.561773],[-122.570447,47.587866],[-122.58514,47.59851]]]},"properties":{"id":"98310","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.5658,47.712368],[-122.545204,47.737272],[-122.545768,47.741853],[-122.519996,47.765738],[-122.592254,47.836556],[-122.593565,47.846997],[-122.584017,47.850687],[-122.58719,47.838077],[-122.578417,47.861908],[-122.710025,47.773793],[-122.692483,47.70117],[-122.660845,47.666588],[-122.598363,47.652888],[-122.5658,47.712368]],[[-122.628479,47.699074],[-122.614205,47.70187],[-122.61832,47.691474],[-122.628479,47.699074]]]},"properties":{"id":"98370","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.935052,47.459189],[-120.962006,47.232918],[-120.99452,47.218993],[-121.033236,47.223067],[-121.104254,47.248381],[-121.132859,47.248587],[-121.205845,47.127003],[-120.807966,47.087091],[-120.738052,47.123472],[-120.570336,47.22889],[-120.531525,47.315331],[-120.925698,47.449657],[-120.935052,47.459189]],[[-120.949436,47.192077],[-120.949432,47.184445],[-120.974151,47.178442],[-120.977627,47.183644],[-120.949436,47.192077]]]},"properties":{"id":"98922","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.419365,46.981107],[-120.413388,46.971588],[-120.401699,46.979637],[-120.425279,46.993352],[-120.428849,46.983937],[-120.419365,46.981107]]]},"properties":{"id":"98934","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.450581,46.73754],[-120.55695,46.626281],[-120.556897,46.623595],[-120.497432,46.577848],[-120.470213,46.557148],[-120.255737,46.577173],[-120.366606,46.830756],[-120.481609,46.868841],[-120.450581,46.73754]]]},"properties":{"id":"98901","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.431582,45.957151],[-119.998473,45.90964],[-119.990051,46.040976],[-120.340445,46.041333],[-120.490127,45.990245],[-120.431582,45.957151]]]},"properties":{"id":"99322","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.11009,45.657476],[-121.199886,45.647929],[-121.140551,45.61124],[-121.11009,45.657476]]]},"properties":{"id":"98617","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.164969,46.464497],[-121.731436,46.388101],[-121.732755,46.614802],[-122.000734,46.606904],[-122.164969,46.464497]]]},"properties":{"id":"98377","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.19877,46.617596],[-122.295416,46.649937],[-122.468861,46.593939],[-122.368615,46.521527],[-122.244894,46.468313],[-122.185379,46.499983],[-122.19877,46.617596]]]},"properties":{"id":"98356","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.80886,46.525008],[-123.068042,46.519472],[-123.052443,46.431191],[-122.928243,46.411561],[-122.813093,46.51781],[-122.80886,46.525008]]]},"properties":{"id":"98596","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.299481,46.475032],[-123.3656,46.620069],[-123.359007,46.512659],[-123.299481,46.475032]]]},"properties":{"id":"98572","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.740506,46.518252],[-122.781159,46.546749],[-122.80886,46.525008],[-122.813093,46.51781],[-122.740506,46.518252]]],[[[-122.646726,46.539364],[-122.706462,46.489776],[-122.687913,46.499014],[-122.646726,46.539364]]]]},"properties":{"id":"98542","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.422412,47.313361],[-118.251514,47.300078],[-118.078447,47.402763],[-118.094928,47.465309],[-118.571213,47.502964],[-118.464115,47.435674],[-118.437005,47.377109],[-118.335685,47.435216],[-118.336907,47.347871],[-118.444553,47.364774],[-118.443148,47.33429],[-118.422412,47.313361]]]},"properties":{"id":"99134","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.464115,47.435674],[-118.443148,47.33429],[-118.444553,47.364774],[-118.437005,47.377109],[-118.464115,47.435674]]]},"properties":{"id":"99144","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.305783,47.338868],[-123.506033,47.359203],[-123.506011,47.300094],[-123.407897,47.238137],[-123.305783,47.338868]]]},"properties":{"id":"98560","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.362915,48.624655],[-120.654756,48.52299],[-120.653876,48.482468],[-119.950447,48.540643],[-119.956096,48.557858],[-119.90619,48.704825],[-119.91495,48.714956],[-120.362915,48.624655]]]},"properties":{"id":"98862","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.620461,48.558866],[-119.704822,48.613359],[-119.956096,48.557858],[-119.950447,48.540643],[-119.890154,48.387806],[-119.725441,48.248099],[-119.516156,48.077789],[-119.473841,48.078385],[-119.243867,48.106689],[-119.114067,48.145278],[-119.119279,48.17153],[-119.548857,48.466179],[-119.620461,48.558866]]]},"properties":{"id":"98840","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.368853,46.711246],[-123.852235,46.791598],[-123.915181,46.792641],[-123.934631,46.705522],[-123.538835,46.467983],[-123.519235,46.467829],[-123.359007,46.512659],[-123.3656,46.620069],[-123.368853,46.711246]]]},"properties":{"id":"98577","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.935921,46.705364],[-124.020413,46.690231],[-124.001278,46.676645],[-123.935921,46.705364]]]},"properties":{"id":"98590","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.957672,46.34867],[-123.871869,46.234949],[-123.684244,46.250956],[-123.64377,46.355872],[-123.467207,46.441442],[-123.519235,46.467829],[-123.538835,46.467983],[-123.907121,46.448994],[-123.957672,46.34867]]]},"properties":{"id":"98638","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.030507,46.54235],[-124.028522,46.549123],[-124.031021,46.549113],[-124.030507,46.54235]]]},"properties":{"id":"98641","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.389074,48.819617],[-117.393455,48.871916],[-117.420234,48.848116],[-117.413577,48.826254],[-117.389074,48.819617]]]},"properties":{"id":"99152","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.582113,47.224312],[-122.50524,47.213799],[-122.505269,47.242901],[-122.565437,47.24682],[-122.582113,47.224312]]]},"properties":{"id":"98466","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.326469,47.237864],[-122.336029,47.255916],[-122.357984,47.258683],[-122.399685,47.24097],[-122.3921,47.23613],[-122.341697,47.213934],[-122.326469,47.237864]]]},"properties":{"id":"98424","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.447729,47.263764],[-122.4442,47.266723],[-122.464567,47.276103],[-122.46943,47.273336],[-122.468328,47.255443],[-122.447729,47.263764]]]},"properties":{"id":"98403","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357473,47.125351],[-122.356865,47.092828],[-122.30986,47.081909],[-122.293799,47.081873],[-122.293081,47.125653],[-122.357473,47.125351]]]},"properties":{"id":"98375","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.950555,48.453562],[-122.80583,48.479689],[-122.84254,48.55719],[-122.876291,48.576057],[-122.951914,48.528609],[-122.950555,48.453562]]]},"properties":{"id":"98261","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.001612,48.667053],[-123.067501,48.622578],[-123.03185,48.580709],[-122.96495,48.597664],[-122.957997,48.631863],[-123.001612,48.667053]]]},"properties":{"id":"98243","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.920221,48.64482],[-121.877352,48.471586],[-121.616582,48.384516],[-121.556195,48.383858],[-121.53268,48.642454],[-121.563549,48.78721],[-121.585436,48.787615],[-121.920221,48.64482]]]},"properties":{"id":"98237","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.346726,48.34159],[-122.348063,48.339157],[-122.344639,48.33131],[-122.340573,48.34107],[-122.346726,48.34159]]]},"properties":{"id":"98238","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.260699,47.922499],[-122.291664,47.956435],[-122.332038,47.900066],[-122.293235,47.875086],[-122.283895,47.888379],[-122.260699,47.922499]]]},"properties":{"id":"98275","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.122519,48.021683],[-122.058964,47.978847],[-122.017765,48.058193],[-122.042914,48.094129],[-122.111395,48.071956],[-122.122519,48.021683]]]},"properties":{"id":"98258","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.047575,47.831301],[-121.804003,47.950006],[-121.722838,47.970072],[-122.017765,48.058193],[-122.058964,47.978847],[-122.171935,47.941836],[-122.168463,47.928309],[-122.047575,47.831301]]]},"properties":{"id":"98290","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.412774,47.672802],[-117.41124,47.707898],[-117.492151,47.700789],[-117.452447,47.680245],[-117.412774,47.672802]]]},"properties":{"id":"99205","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.62541,47.643006],[-117.673796,47.632166],[-117.650423,47.594676],[-117.625639,47.628368],[-117.62541,47.643006]]]},"properties":{"id":"99011","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.377603,47.641767],[-117.416536,47.613716],[-117.368384,47.535548],[-117.297707,47.594328],[-117.293548,47.620745],[-117.346872,47.642796],[-117.377603,47.641767]]]},"properties":{"id":"99223","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.110624,47.580184],[-117.224285,47.579886],[-117.222805,47.573051],[-117.155885,47.536471],[-117.110624,47.580184]]]},"properties":{"id":"99023","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.040949,47.584717],[-117.085625,47.579908],[-117.110624,47.580184],[-117.155885,47.536471],[-117.238411,47.478063],[-117.039792,47.393023],[-117.040949,47.584717]]]},"properties":{"id":"99030","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.607149,48.169473],[-117.736081,48.061737],[-117.62907,48.019136],[-117.55363,48.079263],[-117.502166,48.194706],[-117.502157,48.200408],[-117.607149,48.169473]]]},"properties":{"id":"99148","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.021398,48.090737],[-118.233035,48.125234],[-118.253853,48.124829],[-118.340925,47.894395],[-118.148648,47.886618],[-118.080416,48.002031],[-118.021398,48.090737]]]},"properties":{"id":"99129","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.839174,47.045365],[-122.909525,47.059456],[-122.90638,47.021096],[-122.866355,46.900326],[-122.809998,46.920193],[-122.833628,46.996262],[-122.839174,47.045365]]]},"properties":{"id":"98501","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.764598,47.053955],[-122.833724,47.045817],[-122.839174,47.045365],[-122.833628,46.996262],[-122.764598,47.053955]]]},"properties":{"id":"98503","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.68907,48.935595],[-122.726467,48.854624],[-122.703448,48.778994],[-122.510194,48.887869],[-122.574382,48.93532],[-122.68907,48.935595]]]},"properties":{"id":"98248","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.552061,48.964768],[-122.68907,48.935595],[-122.574382,48.93532],[-122.552061,48.964768]]]},"properties":{"id":"98240","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.35754,47.0059],[-117.35433,47.00588],[-117.35687,47.012],[-117.36215,47.014],[-117.36267,47.01038],[-117.35892,47.00953],[-117.35754,47.0059]]]},"properties":{"id":"99174","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.473071,46.531592],[-120.290272,46.508748],[-120.096513,46.48147],[-120.03163,46.476379],[-119.874057,46.628585],[-119.910396,46.644669],[-120.255737,46.577173],[-120.470213,46.557148],[-120.473071,46.531592]]]},"properties":{"id":"98936","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.315321,46.430656],[-120.321238,46.434238],[-120.324431,46.432846],[-120.318381,46.428947],[-120.315321,46.430656]]]},"properties":{"id":"98921","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.223318,46.062501],[-119.327108,46.085199],[-119.455638,46.042379],[-119.456259,45.915589],[-119.204341,45.929285],[-119.223318,46.062501]]]},"properties":{"id":"99346","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.441951,47.637557],[-120.816913,48.148379],[-120.906211,48.163848],[-120.96335,48.135629],[-121.11901,47.779933],[-121.113606,47.597135],[-120.935052,47.459189],[-120.925698,47.449657],[-120.517636,47.610502],[-120.455684,47.620153],[-120.441951,47.637557]]]},"properties":{"id":"98826","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.093314,48.019497],[-120.199948,47.965857],[-120.198711,47.888014],[-120.098481,47.854563],[-120.066389,47.966457],[-120.093314,48.019497]]]},"properties":{"id":"98831","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.33298,47.971083],[-123.696662,47.88108],[-123.467657,47.866918],[-123.43792,48.122738],[-123.947112,48.166178],[-124.25317,48.110337],[-124.33298,47.971083]]]},"properties":{"id":"98363","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.594419,45.640413],[-122.602233,45.678824],[-122.636501,45.671618],[-122.664842,45.631559],[-122.675332,45.618162],[-122.607706,45.608139],[-122.594419,45.640413]]]},"properties":{"id":"98661","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.594419,45.640413],[-122.607706,45.608139],[-122.550325,45.597269],[-122.559399,45.621029],[-122.56314,45.642057],[-122.594419,45.640413]]]},"properties":{"id":"98664","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.262119,46.058484],[-122.548716,45.964327],[-122.556388,45.925405],[-122.511736,45.901484],[-122.245069,45.909809],[-122.245633,46.053932],[-122.262119,46.058484]]]},"properties":{"id":"98601","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.37299,45.757271],[-122.57713,45.729526],[-122.552698,45.707961],[-122.393454,45.694262],[-122.346402,45.715959],[-122.37299,45.757271]]]},"properties":{"id":"98606","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.989756,46.387452],[-123.024405,46.315862],[-122.924827,46.200699],[-122.785643,46.246397],[-122.774657,46.29253],[-122.824849,46.337817],[-122.761253,46.372413],[-122.725507,46.330104],[-122.702614,46.387265],[-122.932242,46.387385],[-122.989756,46.387452]]]},"properties":{"id":"98611","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.725507,46.330104],[-122.761253,46.372413],[-122.824849,46.337817],[-122.774657,46.29253],[-122.725507,46.330104]]]},"properties":{"id":"98645","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.196824,49.000297],[-118.364708,49.000195],[-118.376413,48.923363],[-118.201601,48.914974],[-118.196824,49.000297]]]},"properties":{"id":"99146","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.816261,46.831395],[-119.964956,46.863626],[-119.973017,46.737171],[-119.910396,46.644669],[-119.874057,46.628585],[-119.45322,46.679243],[-119.368939,46.678204],[-119.369125,46.795592],[-119.816261,46.831395]]]},"properties":{"id":"99349","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.981147,47.144958],[-118.784953,47.508804],[-118.849542,47.53752],[-118.977967,47.553366],[-119.001881,47.504128],[-119.212853,47.393766],[-119.00208,47.144842],[-118.981147,47.144958]]]},"properties":{"id":"98832","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.276846,47.4275],[-119.23392,47.471749],[-119.276155,47.499756],[-119.276846,47.4275]]]},"properties":{"id":"98853","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.764279,46.957582],[-123.852235,46.791598],[-123.368853,46.711246],[-123.369679,46.734784],[-123.458042,46.897906],[-123.499233,46.908757],[-123.764279,46.957582]]]},"properties":{"id":"98537","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.75466,47.315616],[-123.764279,46.957582],[-123.499233,46.908757],[-123.493218,46.999612],[-123.494951,47.001256],[-123.506011,47.300094],[-123.506033,47.359203],[-123.504346,47.50421],[-123.695944,47.517734],[-123.75466,47.315616]]]},"properties":{"id":"98563","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.156035,46.914055],[-124.120588,46.858626],[-124.070779,46.861828],[-124.126286,46.920832],[-124.156035,46.914055]]]},"properties":{"id":"98595","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.179035,47.244917],[-124.223194,47.244694],[-124.218241,47.244635],[-124.179035,47.244917]]]},"properties":{"id":"98587","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.724765,48.025062],[-122.669868,48.017217],[-122.698465,48.103102],[-122.744496,48.075504],[-122.734679,48.034349],[-122.724765,48.025062]]]},"properties":{"id":"98358","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.218945,47.238198],[-122.114421,47.228242],[-121.996029,47.280117],[-122.054019,47.326447],[-122.165537,47.34893],[-122.205589,47.343414],[-122.229982,47.265887],[-122.229495,47.257556],[-122.218945,47.238198]]]},"properties":{"id":"98092","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.335212,47.423273],[-122.333869,47.46869],[-122.333825,47.481278],[-122.366704,47.481109],[-122.343594,47.408505],[-122.335212,47.423273]]]},"properties":{"id":"98166","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.28704,47.777242],[-122.327304,47.777652],[-122.329939,47.77762],[-122.328881,47.734093],[-122.269808,47.733477],[-122.269937,47.73412],[-122.270957,47.777019],[-122.28704,47.777242]]]},"properties":{"id":"98155","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.367216,47.513916],[-122.337485,47.517258],[-122.342737,47.55622],[-122.359978,47.584699],[-122.367216,47.513916]]]},"properties":{"id":"98106","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.312384,47.470094],[-122.251569,47.474808],[-122.283739,47.510063],[-122.291325,47.511627],[-122.334005,47.513865],[-122.333825,47.481278],[-122.333869,47.46869],[-122.312384,47.470094]]]},"properties":{"id":"98168","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.312787,47.465676],[-122.322317,47.438935],[-122.305043,47.434605],[-122.312787,47.465676]]]},"properties":{"id":"98158","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.090633,47.602261],[-121.979785,47.601265],[-122.08758,47.657362],[-122.07922,47.627534],[-122.090633,47.602261]]]},"properties":{"id":"98074","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.212091,47.657464],[-122.185547,47.646409],[-122.15875,47.660674],[-122.164425,47.704132],[-122.231835,47.69012],[-122.226235,47.66692],[-122.212091,47.657464]]]},"properties":{"id":"98033","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.151867,47.718612],[-122.0876,47.718426],[-122.114329,47.795557],[-122.157929,47.818436],[-122.164693,47.808534],[-122.173598,47.776204],[-122.175385,47.736695],[-122.151867,47.718612]]]},"properties":{"id":"98072","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.151067,47.579296],[-122.153403,47.660559],[-122.15875,47.660674],[-122.185547,47.646409],[-122.18762,47.579527],[-122.151067,47.579296]]]},"properties":{"id":"98005","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.168493,47.477703],[-122.07672,47.45992],[-122.058277,47.466114],[-122.108789,47.540555],[-122.16556,47.540015],[-122.168493,47.477703]]]},"properties":{"id":"98059","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.133977,47.578308],[-122.121361,47.574139],[-122.098001,47.587777],[-122.090633,47.602261],[-122.07922,47.627534],[-122.132354,47.632695],[-122.133977,47.578308]]]},"properties":{"id":"98008","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.083764,47.709446],[-122.08758,47.657362],[-121.979785,47.601265],[-121.970676,47.594577],[-121.952948,47.609807],[-122.003794,47.705216],[-122.014039,47.714138],[-122.083764,47.709446]]]},"properties":{"id":"98053","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.055651,47.56578],[-121.971748,47.558397],[-121.973468,47.584419],[-122.055651,47.56578]]]},"properties":{"id":"98029","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.534903,47.546934],[-121.231607,47.562534],[-121.301263,47.779638],[-121.491749,47.777853],[-121.666777,47.776922],[-121.666876,47.776921],[-121.71538,47.688926],[-121.698711,47.635639],[-121.534903,47.546934]]]},"properties":{"id":"98224","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.426301,47.390137],[-121.132859,47.248587],[-121.104254,47.248381],[-121.146932,47.593463],[-121.231607,47.562534],[-121.534903,47.546934],[-121.426301,47.390137]]]},"properties":{"id":"98068","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.775987,47.658067],[-122.864777,47.521487],[-122.801013,47.484004],[-122.682825,47.527548],[-122.649854,47.546772],[-122.651743,47.558691],[-122.646463,47.583662],[-122.684555,47.610816],[-122.690757,47.633516],[-122.775987,47.658067]]]},"properties":{"id":"98312","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.660845,47.666588],[-122.690757,47.633516],[-122.684555,47.610816],[-122.58514,47.59851],[-122.598363,47.652888],[-122.660845,47.666588]]]},"properties":{"id":"98311","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.864777,47.521487],[-122.950653,47.52247],[-123.044805,47.374319],[-123.05659,47.356923],[-122.994084,47.346953],[-122.904682,47.360385],[-122.804462,47.353482],[-122.80205,47.358817],[-122.801451,47.404016],[-122.801013,47.484004],[-122.864777,47.521487]]]},"properties":{"id":"98528","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.578417,47.861908],[-122.58719,47.838077],[-122.592254,47.836556],[-122.519996,47.765738],[-122.497773,47.74069],[-122.521871,47.914507],[-122.58144,47.880676],[-122.578417,47.861908]]]},"properties":{"id":"98346","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.109096,45.898036],[-121.175319,45.823026],[-121.175312,45.821571],[-121.063179,45.825074],[-121.092343,45.914016],[-121.109096,45.898036]]]},"properties":{"id":"98628","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.588152,46.503275],[-122.588331,46.503105],[-122.580065,46.387654],[-122.302237,46.385709],[-122.244894,46.468313],[-122.368615,46.521527],[-122.588152,46.503275]]]},"properties":{"id":"98564","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.928243,46.411561],[-123.052443,46.431191],[-123.046811,46.387992],[-122.989756,46.387452],[-122.932242,46.387385],[-122.928243,46.411561]]]},"properties":{"id":"98593","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.937917,47.660673],[-118.094928,47.465309],[-118.078447,47.402763],[-117.822877,47.420558],[-117.784654,47.519272],[-117.821827,47.579842],[-117.937917,47.660673]]]},"properties":{"id":"99008","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.444553,47.364774],[-118.336907,47.347871],[-118.335685,47.435216],[-118.437005,47.377109],[-118.444553,47.364774]]]},"properties":{"id":"99154","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.202066,47.084895],[-122.905659,47.155614],[-122.836557,47.187627],[-122.821426,47.194211],[-122.851876,47.251956],[-122.814816,47.306816],[-122.994084,47.346953],[-123.117474,47.337209],[-123.144849,47.393809],[-123.305783,47.338868],[-123.407897,47.238137],[-123.26569,47.09914],[-123.202066,47.084895]]]},"properties":{"id":"98584","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.904682,47.360385],[-122.80443,47.335667],[-122.806499,47.34348],[-122.804462,47.353482],[-122.904682,47.360385]]]},"properties":{"id":"98524","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.954966,49.000273],[-119.516887,49.000276],[-119.559737,48.831302],[-119.014073,48.81719],[-118.954966,49.000273]]]},"properties":{"id":"98844","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.88625,48.279034],[-119.725441,48.248099],[-119.890154,48.387806],[-119.88625,48.279034]]]},"properties":{"id":"98829","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.548857,48.466179],[-119.196372,48.482224],[-119.620461,48.558866],[-119.548857,48.466179]]]},"properties":{"id":"98849","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.96323,46.263303],[-123.957672,46.34867],[-123.907121,46.448994],[-124.006657,46.461513],[-124.006075,46.458489],[-124.047086,46.325872],[-124.069215,46.330325],[-123.96323,46.263303]]]},"properties":{"id":"98624","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.462573,47.214728],[-122.431312,47.2152],[-122.431212,47.233391],[-122.432747,47.233235],[-122.440946,47.231413],[-122.462573,47.214728]]]},"properties":{"id":"98418","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.593093,47.159442],[-122.576129,47.186416],[-122.622739,47.188819],[-122.632239,47.143319],[-122.593093,47.159442]]],[[[-122.711036,47.195957],[-122.623839,47.193919],[-122.622139,47.209219],[-122.675341,47.251319],[-122.731408,47.206768],[-122.711036,47.195957]]]]},"properties":{"id":"98388","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.687544,47.334318],[-122.574964,47.325621],[-122.550841,47.403219],[-122.624683,47.403424],[-122.630553,47.403518],[-122.687544,47.334318]]]},"properties":{"id":"98332","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.488593,48.645355],[-122.345646,48.615901],[-122.332399,48.620039],[-122.31126,48.72063],[-122.46158,48.763016],[-122.503968,48.687329],[-122.488593,48.645355]]]},"properties":{"id":"98229","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.606903,45.782568],[-121.61956,45.707382],[-121.522392,45.724677],[-121.606903,45.782568]]]},"properties":{"id":"98651","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.058964,47.978847],[-122.122519,48.021683],[-122.184084,48.049806],[-122.241221,48.027462],[-122.230867,48.016862],[-122.18674,47.959408],[-122.188026,47.948017],[-122.171935,47.941836],[-122.058964,47.978847]]]},"properties":{"id":"98205","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.239273,47.877815],[-122.227958,47.863769],[-122.165973,47.877758],[-122.168463,47.928309],[-122.171935,47.941836],[-122.188026,47.948017],[-122.233727,47.923414],[-122.239273,47.877815]]]},"properties":{"id":"98208","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.157929,47.818436],[-122.114329,47.795557],[-122.018295,47.77539],[-122.047575,47.831301],[-122.168463,47.928309],[-122.165973,47.877758],[-122.172874,47.863373],[-122.158421,47.856027],[-122.157929,47.818436]]]},"properties":{"id":"98296","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.140546,48.091959],[-122.184084,48.049806],[-122.122519,48.021683],[-122.111395,48.071956],[-122.140546,48.091959]]]},"properties":{"id":"98270","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.017765,48.058193],[-121.722838,47.970072],[-121.685484,47.974633],[-121.50347,47.984894],[-121.444071,48.026164],[-121.678912,48.146037],[-122.042914,48.094129],[-122.017765,48.058193]]]},"properties":{"id":"98252","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.264342,48.139937],[-122.140546,48.091959],[-122.111395,48.071956],[-122.042914,48.094129],[-121.678912,48.146037],[-121.636412,48.298531],[-121.935609,48.297696],[-122.203917,48.297325],[-122.264342,48.139937]]]},"properties":{"id":"98223","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.226931,47.982487],[-122.216702,47.989817],[-122.221546,47.994183],[-122.226931,47.982487]]]},"properties":{"id":"98207","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.62541,47.643006],[-117.603866,47.658105],[-117.711572,47.70129],[-117.821827,47.579842],[-117.784654,47.519272],[-117.607503,47.594664],[-117.604061,47.613868],[-117.625639,47.628368],[-117.650423,47.594676],[-117.673796,47.632166],[-117.62541,47.643006]]]},"properties":{"id":"99022","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.411138,47.671776],[-117.411252,47.652553],[-117.411383,47.642697],[-117.377603,47.641767],[-117.346872,47.642796],[-117.346716,47.673479],[-117.411138,47.671776]]]},"properties":{"id":"99202","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.37837,47.772539],[-117.451711,47.772274],[-117.424431,47.73679],[-117.395344,47.736791],[-117.37837,47.772539]]]},"properties":{"id":"99218","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.289409,47.34731],[-117.261726,47.32913],[-117.226096,47.344656],[-117.262096,47.352564],[-117.292236,47.367508],[-117.289409,47.34731]]]},"properties":{"id":"99039","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.268422,47.289019],[-117.20443,47.25957],[-117.17211,47.25958],[-117.039896,47.310542],[-117.03987,47.332709],[-117.268422,47.289019]]]},"properties":{"id":"99018","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.079146,47.686186],[-117.133391,47.763676],[-117.175425,47.715044],[-117.175261,47.678829],[-117.143235,47.680638],[-117.079146,47.686186]]]},"properties":{"id":"99027","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.238411,47.478063],[-117.155885,47.536471],[-117.222805,47.573051],[-117.297707,47.594328],[-117.368384,47.535548],[-117.3682,47.522103],[-117.328234,47.488884],[-117.238411,47.478063]]]},"properties":{"id":"99036","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.143235,47.680638],[-117.085625,47.579908],[-117.040949,47.584717],[-117.041732,47.6977],[-117.079146,47.686186],[-117.143235,47.680638]]]},"properties":{"id":"99019","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.717429,47.78078],[-117.510954,47.72996],[-117.535597,47.830619],[-117.70861,47.874843],[-117.699981,47.86061],[-117.733926,47.787114],[-117.717429,47.78078]]]},"properties":{"id":"99026","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.031413,48.350901],[-118.072746,48.248132],[-117.97523,48.223329],[-117.771574,48.414489],[-118.031413,48.350901]]]},"properties":{"id":"99101","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.98957,48.815463],[-118.068988,48.662334],[-118.070922,48.664103],[-118.060391,48.666354],[-118.052467,48.665651],[-118.067778,48.662558],[-117.989732,48.655148],[-117.878903,48.802223],[-117.98957,48.815463]]]},"properties":{"id":"99126","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.202444,48.418722],[-118.183028,48.374894],[-118.056126,48.350889],[-118.063161,48.510189],[-118.202444,48.418722]]]},"properties":{"id":"99167","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.97523,48.223329],[-118.072746,48.248132],[-118.233035,48.125234],[-118.021398,48.090737],[-117.985217,48.13043],[-117.97523,48.223329]]]},"properties":{"id":"99137","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.661615,46.938078],[-122.509498,46.76275],[-122.481081,46.763123],[-122.316801,46.762959],[-122.202721,46.762628],[-122.421362,46.84972],[-122.550527,46.930762],[-122.56439,46.936916],[-122.637924,46.98127],[-122.661615,46.938078]]]},"properties":{"id":"98597","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.509498,46.76275],[-122.661615,46.938078],[-122.780985,46.916271],[-122.634951,46.762634],[-122.509498,46.76275]]]},"properties":{"id":"98576","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.372524,46.054353],[-118.409455,46.049299],[-118.404372,46.027521],[-118.372964,46.031164],[-118.372524,46.054353]]]},"properties":{"id":"99324","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.804554,46.12569],[-118.988846,46.153383],[-118.987129,45.999855],[-118.788357,46.000924],[-118.804554,46.12569]]]},"properties":{"id":"99363","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.222227,48.912522],[-122.13694,49.002092],[-122.220901,48.920773],[-122.222227,48.912522]]]},"properties":{"id":"98266","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039413,46.701825],[-117.435601,46.661901],[-117.252749,46.543071],[-117.039695,46.587732],[-117.039413,46.701825]]]},"properties":{"id":"99113","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.350359,46.432961],[-120.222225,46.370509],[-120.146432,46.409921],[-120.096513,46.48147],[-120.290272,46.508748],[-120.350359,46.432961]],[[-120.315321,46.430656],[-120.318381,46.428947],[-120.324431,46.432846],[-120.321238,46.434238],[-120.315321,46.430656]]]},"properties":{"id":"98953","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.266996,46.985405],[-118.89803,46.9114],[-118.93988,47.02798],[-118.98117,47.13036],[-118.981147,47.144958],[-119.00208,47.144842],[-119.266996,46.985405]]]},"properties":{"id":"98857","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.127985,46.664771],[-119.007146,46.546773],[-118.922036,46.502752],[-118.649747,46.591629],[-118.65159,46.735852],[-118.785808,46.764406],[-119.127985,46.664771]]]},"properties":{"id":"99326","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.867995,45.847196],[-119.868135,45.835962],[-119.456259,45.915589],[-119.455638,46.042379],[-119.867995,45.847196]]]},"properties":{"id":"99345","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.037561,47.866898],[-122.949166,47.992635],[-122.872975,48.047299],[-123.220755,48.125418],[-123.231207,47.961388],[-123.037561,47.866898]]]},"properties":{"id":"98382","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.272019,48.254294],[-124.622882,48.274863],[-124.680133,48.275161],[-124.679219,48.01843],[-124.622304,48.037964],[-124.25317,48.110337],[-123.947112,48.166178],[-124.272019,48.254294]]]},"properties":{"id":"98326","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.770646,45.78184],[-122.734909,45.733896],[-122.657629,45.734828],[-122.620488,45.76538],[-122.631127,45.82758],[-122.711319,45.872971],[-122.785515,45.850536],[-122.770646,45.78184]]]},"properties":{"id":"98642","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.599406,45.693327],[-122.661222,45.69301],[-122.706391,45.693523],[-122.691561,45.670843],[-122.636501,45.671618],[-122.602233,45.678824],[-122.599406,45.693327]]]},"properties":{"id":"98665","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.711319,45.872971],[-122.631127,45.82758],[-122.529817,45.874708],[-122.511736,45.901484],[-122.556388,45.925405],[-122.711319,45.872971]]]},"properties":{"id":"98629","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.657629,45.734828],[-122.734909,45.733896],[-122.706391,45.693523],[-122.661222,45.69301],[-122.657629,45.734828]]]},"properties":{"id":"98685","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.250881,45.664765],[-122.383569,45.575543],[-122.100197,45.584215],[-122.139642,45.658388],[-122.249618,45.673191],[-122.250881,45.664765]]]},"properties":{"id":"98671","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.785643,46.246397],[-122.924827,46.200699],[-122.86864,46.077153],[-122.649823,46.141233],[-122.735289,46.227713],[-122.785643,46.246397]]]},"properties":{"id":"98626","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.216344,46.221986],[-123.213054,46.172541],[-122.881327,46.045974],[-122.86864,46.077153],[-122.924827,46.200699],[-123.024405,46.315862],[-123.080988,46.323639],[-123.216344,46.221986]]]},"properties":{"id":"98632","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.061666,47.345671],[-120.185636,47.373005],[-120.09447,47.262151],[-120.047153,47.225527],[-120.004691,47.309249],[-120.061666,47.345671]]]},"properties":{"id":"98850","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.201601,48.914974],[-118.376413,48.923363],[-118.395179,48.863891],[-118.400985,48.80178],[-118.201601,48.914974]]]},"properties":{"id":"99160","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.021416,47.232095],[-123.839616,47.24418],[-123.792447,47.328736],[-123.967306,47.369203],[-124.011106,47.358942],[-124.082917,47.274339],[-124.021416,47.232095]]]},"properties":{"id":"98552","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.195824,47.172853],[-124.194227,47.236779],[-124.218241,47.244635],[-124.223194,47.244694],[-124.20009,47.172846],[-124.195824,47.172853]]]},"properties":{"id":"98571","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.179035,47.244917],[-124.218241,47.244635],[-124.194227,47.236779],[-124.179035,47.244917]]]},"properties":{"id":"98562","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.327352,46.958724],[-123.32765,46.95737],[-123.325616,46.95776],[-123.327352,46.958724]]]},"properties":{"id":"98559","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.671147,48.369872],[-122.703202,48.307865],[-122.727499,48.307583],[-122.768716,48.253784],[-122.722922,48.230919],[-122.626281,48.409415],[-122.655248,48.404678],[-122.671147,48.369872]]]},"properties":{"id":"98277","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.79169,48.042009],[-122.73548,47.984],[-122.711543,47.98404],[-122.724765,48.025062],[-122.734679,48.034349],[-122.759131,48.053008],[-122.79169,48.042009]]]},"properties":{"id":"98339","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.39122,47.663839],[-122.389747,47.625656],[-122.369903,47.618764],[-122.357363,47.618608],[-122.360539,47.65292],[-122.39122,47.663839]]]},"properties":{"id":"98119","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.358076,47.701472],[-122.358667,47.777766],[-122.398825,47.777938],[-122.398596,47.703899],[-122.358076,47.701472]]]},"properties":{"id":"98177","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.315606,47.618776],[-122.310437,47.64712],[-122.312412,47.647196],[-122.326537,47.65032],[-122.337538,47.63972],[-122.328298,47.61848],[-122.327855,47.618495],[-122.315606,47.618776]]]},"properties":{"id":"98102","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.389747,47.625656],[-122.39122,47.663839],[-122.414259,47.675691],[-122.419548,47.678899],[-122.389747,47.625656]]]},"properties":{"id":"98199","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.292075,47.570186],[-122.291325,47.511627],[-122.283739,47.510063],[-122.23807,47.521323],[-122.262134,47.575921],[-122.292075,47.570186]]]},"properties":{"id":"98118","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.328225,47.671567],[-122.231435,47.66412],[-122.226235,47.66692],[-122.231835,47.69012],[-122.254136,47.69782],[-122.329289,47.701432],[-122.329963,47.701439],[-122.328225,47.671567]]]},"properties":{"id":"98115","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.342737,47.55622],[-122.320091,47.572433],[-122.319253,47.595823],[-122.344837,47.59922],[-122.360105,47.591537],[-122.359978,47.584699],[-122.342737,47.55622]]]},"properties":{"id":"98134","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.083319,47.391131],[-122.161506,47.41927],[-122.165415,47.385006],[-122.165537,47.34893],[-122.054019,47.326447],[-122.053873,47.34357],[-122.083319,47.391131]]]},"properties":{"id":"98042","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.153403,47.660559],[-122.151067,47.579296],[-122.133977,47.578308],[-122.132354,47.632695],[-122.153403,47.660559]]]},"properties":{"id":"98007","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.255735,47.64712],[-122.263535,47.62432],[-122.265735,47.61362],[-122.220434,47.596821],[-122.212091,47.657464],[-122.226235,47.66692],[-122.231435,47.66412],[-122.255735,47.64712]]]},"properties":{"id":"98039","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.932913,47.317762],[-121.998996,47.343517],[-122.053873,47.34357],[-122.054019,47.326447],[-121.996029,47.280117],[-121.932913,47.317762]]]},"properties":{"id":"98010","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.959087,47.558306],[-121.956867,47.533327],[-121.918005,47.543386],[-121.959087,47.558306]]]},"properties":{"id":"98050","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.113606,47.597135],[-121.11901,47.779933],[-121.248503,47.779363],[-121.301263,47.779638],[-121.231607,47.562534],[-121.146932,47.593463],[-121.113606,47.597135]]]},"properties":{"id":"98288","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.519009,47.47423],[-122.624683,47.403424],[-122.550841,47.403219],[-122.519009,47.47423]]]},"properties":{"id":"98359","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.775987,47.658067],[-122.690757,47.633516],[-122.660845,47.666588],[-122.692483,47.70117],[-122.753174,47.721045],[-122.782516,47.662135],[-122.775987,47.658067]]]},"properties":{"id":"98383","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.628479,47.699074],[-122.61832,47.691474],[-122.614205,47.70187],[-122.628479,47.699074]]]},"properties":{"id":"98345","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.545768,47.741853],[-122.497773,47.74069],[-122.519996,47.765738],[-122.545768,47.741853]]]},"properties":{"id":"98342","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.738052,47.123472],[-120.807966,47.087091],[-120.756968,47.074061],[-120.738052,47.123472]]]},"properties":{"id":"98946","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.740009,45.786037],[-121.675996,45.70307],[-121.61956,45.707382],[-121.606903,45.782568],[-121.608583,45.84264],[-121.608576,45.85822],[-121.740009,45.786037]]]},"properties":{"id":"98605","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.998473,45.90964],[-120.431582,45.957151],[-120.553307,45.733664],[-119.868135,45.835962],[-119.867995,45.847196],[-119.998473,45.90964]]]},"properties":{"id":"99356","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.125926,45.986993],[-121.425512,46.044525],[-121.453458,45.916821],[-121.384813,45.89108],[-121.196297,45.899483],[-121.109096,45.898036],[-121.092343,45.914016],[-121.125926,45.986993]]]},"properties":{"id":"98619","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.781159,46.546749],[-122.647742,46.762733],[-122.699114,46.763537],[-123.193367,46.7341],[-123.330516,46.736258],[-123.369679,46.734784],[-123.368853,46.711246],[-123.3656,46.620069],[-123.299481,46.475032],[-123.241568,46.446153],[-123.068042,46.519472],[-122.80886,46.525008],[-122.781159,46.546749]],[[-122.9139,46.583543],[-122.892643,46.583471],[-122.904282,46.572666],[-122.9139,46.583543]]]},"properties":{"id":"98532","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.185379,46.499983],[-122.164969,46.464497],[-122.000734,46.606904],[-122.19877,46.617596],[-122.185379,46.499983]]]},"properties":{"id":"98336","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.029743,46.740938],[-123.030101,46.736495],[-123.023133,46.737791],[-123.029743,46.740938]]]},"properties":{"id":"98544","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.90619,48.704825],[-119.687972,48.69983],[-119.559737,48.831302],[-119.516887,49.000276],[-119.91495,48.714956],[-119.90619,48.704825]]]},"properties":{"id":"98827","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.890154,48.387806],[-119.950447,48.540643],[-120.653876,48.482468],[-120.666013,48.451759],[-120.383719,48.221995],[-119.957011,48.279078],[-119.88625,48.279034],[-119.890154,48.387806]]]},"properties":{"id":"98856","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.001277,46.647019],[-123.939009,46.598946],[-123.963706,46.562129],[-124.006657,46.461513],[-123.907121,46.448994],[-123.538835,46.467983],[-123.934631,46.705522],[-123.935921,46.705364],[-124.001278,46.676645],[-124.001277,46.647019]]]},"properties":{"id":"98586","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.957672,46.34867],[-123.96323,46.263303],[-123.871869,46.234949],[-123.957672,46.34867]]]},"properties":{"id":"98614","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.499722,48.652887],[-117.278594,48.641425],[-117.389074,48.819617],[-117.413577,48.826254],[-117.498574,48.836892],[-117.499722,48.652887]]]},"properties":{"id":"99139","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.503797,47.254812],[-122.551529,47.269154],[-122.563107,47.250568],[-122.565437,47.24682],[-122.505269,47.242901],[-122.505196,47.243632],[-122.503797,47.254812]]]},"properties":{"id":"98465","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.437727,47.268046],[-122.432747,47.233235],[-122.431212,47.233391],[-122.399685,47.24097],[-122.357984,47.258683],[-122.410858,47.287583],[-122.437727,47.268046]]]},"properties":{"id":"98421","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.123196,47.138452],[-122.226241,47.140316],[-122.245576,47.083533],[-122.235923,46.953981],[-122.103565,46.963673],[-122.066277,47.076524],[-122.101905,47.1355],[-122.098938,47.140996],[-122.123196,47.138452]]]},"properties":{"id":"98360","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229495,47.257556],[-122.258632,47.249855],[-122.215443,47.169722],[-122.218945,47.238198],[-122.229495,47.257556]]]},"properties":{"id":"98390","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357663,47.170026],[-122.357194,47.180901],[-122.38914,47.180874],[-122.388704,47.082129],[-122.356865,47.092828],[-122.357473,47.125351],[-122.357663,47.170026]]]},"properties":{"id":"98446","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.493824,47.150069],[-122.504563,47.139172],[-122.504668,47.122708],[-122.487208,47.122997],[-122.493931,47.133132],[-122.493824,47.150069]]]},"properties":{"id":"98438","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.7595,47.141216],[-122.703672,47.120204],[-122.67044,47.121619],[-122.632239,47.143319],[-122.622739,47.188819],[-122.623839,47.193919],[-122.711036,47.195957],[-122.7595,47.141216]]]},"properties":{"id":"98303","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.96495,48.597664],[-123.03185,48.580709],[-122.951914,48.528609],[-122.876291,48.576057],[-122.876291,48.584741],[-122.96495,48.597664]]]},"properties":{"id":"98286","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.067501,48.622578],[-122.950555,48.453562],[-122.951914,48.528609],[-123.03185,48.580709],[-123.067501,48.622578]]]},"properties":{"id":"98250","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.990013,48.517684],[-121.978874,48.516449],[-121.989909,48.529231],[-122.001146,48.522264],[-121.990013,48.517684]]]},"properties":{"id":"98255","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.877352,48.471586],[-121.920221,48.64482],[-122.120937,48.646141],[-122.268316,48.707759],[-122.31126,48.72063],[-122.332399,48.620039],[-122.291697,48.475072],[-122.234392,48.464186],[-122.232512,48.460996],[-122.234102,48.46073],[-122.191973,48.428043],[-121.965416,48.399953],[-121.877352,48.471586]],[[-122.071937,48.524466],[-122.065947,48.521209],[-122.066073,48.518259],[-122.072028,48.515723],[-122.071937,48.524466]],[[-121.990013,48.517684],[-122.001146,48.522264],[-121.989909,48.529231],[-121.978874,48.516449],[-121.990013,48.517684]]]},"properties":{"id":"98284","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.368688,48.123366],[-122.264342,48.139937],[-122.203917,48.297325],[-122.303439,48.297596],[-122.378443,48.29759],[-122.405747,48.252177],[-122.397998,48.223847],[-122.368688,48.123366]]]},"properties":{"id":"98292","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.242292,47.97087],[-122.18674,47.959408],[-122.230867,48.016862],[-122.242292,47.97087]],[[-122.226931,47.982487],[-122.221546,47.994183],[-122.216702,47.989817],[-122.226931,47.982487]]]},"properties":{"id":"98201","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.804003,47.950006],[-122.047575,47.831301],[-122.018295,47.77539],[-122.00774,47.775323],[-121.849653,47.777223],[-121.804003,47.950006]]]},"properties":{"id":"98272","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.18674,47.959408],[-122.242292,47.97087],[-122.291664,47.956435],[-122.260699,47.922499],[-122.233727,47.923414],[-122.188026,47.948017],[-122.18674,47.959408]]]},"properties":{"id":"98203","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.376085,47.708215],[-117.346855,47.676227],[-117.282862,47.704376],[-117.240483,47.700375],[-117.175425,47.715044],[-117.133391,47.763676],[-117.145206,47.81614],[-117.379228,47.736703],[-117.376085,47.708215]]]},"properties":{"id":"99217","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.603866,47.658105],[-117.62541,47.643006],[-117.625639,47.628368],[-117.604061,47.613868],[-117.603866,47.658105]]]},"properties":{"id":"99001","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.24138,47.861398],[-117.361455,47.806866],[-117.37837,47.772539],[-117.395344,47.736791],[-117.379228,47.736703],[-117.145206,47.81614],[-117.042574,47.874453],[-117.042313,47.958364],[-117.107567,47.958853],[-117.152969,47.93766],[-117.24138,47.861398]]]},"properties":{"id":"99021","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.498821,47.56416],[-117.496298,47.558571],[-117.493276,47.566733],[-117.498821,47.56416]]]},"properties":{"id":"99020","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.630944,49.000644],[-117.931927,48.999913],[-117.98957,48.815463],[-117.878903,48.802223],[-117.630944,49.000644]]]},"properties":{"id":"99157","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.781228,46.768485],[-122.799959,46.789877],[-122.879887,46.799823],[-122.781228,46.768485]]]},"properties":{"id":"98530","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.46158,48.763016],[-122.572876,48.777203],[-122.503968,48.687329],[-122.46158,48.763016]]]},"properties":{"id":"98225","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.364588,47.137625],[-117.461078,47.149893],[-117.46712,47.00978],[-117.3237,47.049714],[-117.364588,47.137625]]]},"properties":{"id":"99176","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.24965,46.79254],[-117.25405,46.79205],[-117.25788,46.78907],[-117.251917,46.787919],[-117.24965,46.79254]]]},"properties":{"id":"99102","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.565274,46.356939],[-120.732196,46.420607],[-120.667731,46.498646],[-120.921741,46.492821],[-120.502106,46.300676],[-120.565274,46.356939]]]},"properties":{"id":"98952","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.732196,46.420607],[-120.544042,46.368075],[-120.350359,46.432961],[-120.290272,46.508748],[-120.473071,46.531592],[-120.667731,46.498646],[-120.732196,46.420607]],[[-120.463469,46.498867],[-120.458255,46.492202],[-120.469737,46.491938],[-120.463469,46.498867]]]},"properties":{"id":"98951","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.146432,46.409921],[-120.113628,46.316983],[-120.03163,46.476379],[-120.096513,46.48147],[-120.146432,46.409921]]]},"properties":{"id":"98938","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.321181,46.856229],[-121.306282,46.868158],[-121.294721,46.867374],[-121.285551,46.886715],[-121.319469,46.861255],[-121.321181,46.856229]]]},"properties":{"id":"98929","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.041705,46.773539],[-118.16863,46.79066],[-118.22417,46.691249],[-118.027476,46.586055],[-118.041705,46.773539]]]},"properties":{"id":"99333","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.480141,45.997813],[-117.09029,45.997531],[-117.419457,46.135289],[-117.480141,45.997813]]]},"properties":{"id":"99401","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.245565,46.20836],[-119.16317,46.194584],[-119.09751,46.215049],[-119.212805,46.241229],[-119.245565,46.20836]]]},"properties":{"id":"99336","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.16317,46.194584],[-119.245565,46.20836],[-119.347601,46.228292],[-119.348105,46.139439],[-119.327108,46.085199],[-119.223318,46.062501],[-119.16317,46.194584]]]},"properties":{"id":"99338","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.572796,47.545925],[-120.450275,47.295632],[-120.425137,47.447081],[-120.45321,47.479286],[-120.411189,47.48628],[-120.455684,47.620153],[-120.517636,47.610502],[-120.552441,47.578561],[-120.550491,47.559379],[-120.572796,47.545925]]]},"properties":{"id":"98815","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.517636,47.610502],[-120.925698,47.449657],[-120.531525,47.315331],[-120.457923,47.298587],[-120.450275,47.295632],[-120.572796,47.545925],[-120.552441,47.578561],[-120.517636,47.610502]]]},"properties":{"id":"98847","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.622882,48.274863],[-124.272019,48.254294],[-124.547561,48.354362],[-124.622882,48.274863]]]},"properties":{"id":"98381","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.646992,46.022475],[-122.548716,45.964327],[-122.262119,46.058484],[-122.245771,46.142899],[-122.600348,46.048218],[-122.646992,46.022475]]]},"properties":{"id":"98603","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.052211,47.391542],[-120.15492,47.654735],[-120.244504,47.579148],[-120.248114,47.381044],[-120.185636,47.373005],[-120.061666,47.345671],[-120.052211,47.391542]]]},"properties":{"id":"98802","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.45035,47.25731],[-119.533353,47.470013],[-119.705484,47.570013],[-119.854357,47.495422],[-119.747393,47.439222],[-119.619775,47.103775],[-119.45035,47.25731]]]},"properties":{"id":"98823","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.854357,47.495422],[-120.052211,47.391542],[-120.061666,47.345671],[-120.004691,47.309249],[-119.747393,47.439222],[-119.854357,47.495422]]]},"properties":{"id":"98845","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.506011,47.300094],[-123.494951,47.001256],[-123.481618,47.002678],[-123.481645,46.999332],[-123.493218,46.999612],[-123.499233,46.908757],[-123.458042,46.897906],[-123.159279,46.938982],[-123.200041,47.014792],[-123.26569,47.09914],[-123.407897,47.238137],[-123.506011,47.300094]],[[-123.327352,46.958724],[-123.325616,46.95776],[-123.32765,46.95737],[-123.327352,46.958724]]]},"properties":{"id":"98541","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.159279,46.938982],[-123.458042,46.897906],[-123.369679,46.734784],[-123.330516,46.736258],[-123.16082,46.847251],[-123.159279,46.938982]]]},"properties":{"id":"98568","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.194227,47.236779],[-124.195824,47.172853],[-124.096214,47.136761],[-124.021416,47.232095],[-124.082917,47.274339],[-124.179035,47.244917],[-124.194227,47.236779]]]},"properties":{"id":"98536","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.604624,48.05639],[-122.530673,48.036831],[-122.566602,48.154847],[-122.609885,48.109052],[-122.604624,48.05639]]]},"properties":{"id":"98253","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.23807,47.521323],[-122.283739,47.510063],[-122.251569,47.474808],[-122.250906,47.474913],[-122.216608,47.501321],[-122.225394,47.521368],[-122.23807,47.521323]]]},"properties":{"id":"98178","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.216608,47.501321],[-122.250906,47.474913],[-122.24143,47.441201],[-122.217459,47.441169],[-122.185248,47.474086],[-122.180483,47.477433],[-122.216608,47.501321]]]},"properties":{"id":"98057","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.337485,47.517258],[-122.367216,47.513916],[-122.381858,47.513751],[-122.399833,47.512442],[-122.366704,47.481109],[-122.333825,47.481278],[-122.334005,47.513865],[-122.337485,47.517258]]]},"properties":{"id":"98146","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.329289,47.701432],[-122.254136,47.69782],[-122.269808,47.733477],[-122.328881,47.734093],[-122.329289,47.701432]]]},"properties":{"id":"98125","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.333869,47.46869],[-122.335212,47.423273],[-122.307993,47.422758],[-122.305043,47.434605],[-122.322317,47.438935],[-122.312787,47.465676],[-122.312384,47.470094],[-122.333869,47.46869]]]},"properties":{"id":"98148","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.186581,47.423075],[-122.220274,47.4302],[-122.231109,47.386651],[-122.165415,47.385006],[-122.161506,47.41927],[-122.186581,47.423075]]]},"properties":{"id":"98031","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.296731,47.357844],[-122.232422,47.353714],[-122.229173,47.353769],[-122.231109,47.386651],[-122.220274,47.4302],[-122.217459,47.441169],[-122.24143,47.441201],[-122.284362,47.422778],[-122.309417,47.357942],[-122.296731,47.357844]]]},"properties":{"id":"98032","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.358667,47.777766],[-122.358076,47.701472],[-122.329963,47.701439],[-122.329289,47.701432],[-122.328881,47.734093],[-122.329939,47.77762],[-122.356209,47.777766],[-122.358667,47.777766]]]},"properties":{"id":"98133","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.186581,47.423075],[-122.185248,47.474086],[-122.217459,47.441169],[-122.220274,47.4302],[-122.186581,47.423075]]]},"properties":{"id":"98055","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.334304,47.618529],[-122.351255,47.60901],[-122.345442,47.603174],[-122.334674,47.606336],[-122.333564,47.606799],[-122.323115,47.612157],[-122.327855,47.618495],[-122.328298,47.61848],[-122.334304,47.618529]]]},"properties":{"id":"98101","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.843017,47.616123],[-121.952948,47.609807],[-121.970676,47.594577],[-121.973468,47.584419],[-121.971748,47.558397],[-121.959087,47.558306],[-121.918005,47.543386],[-121.908721,47.529516],[-121.843017,47.616123]]]},"properties":{"id":"98024","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.022873,47.520255],[-122.950653,47.52247],[-122.864777,47.521487],[-122.775987,47.658067],[-122.782516,47.662135],[-123.022873,47.520255]]]},"properties":{"id":"98380","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.548673,47.725658],[-122.5658,47.712368],[-122.598363,47.652888],[-122.58514,47.59851],[-122.570447,47.587866],[-122.543918,47.585847],[-122.548673,47.725658]]]},"properties":{"id":"98110","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.692483,47.70117],[-122.710025,47.773793],[-122.753174,47.721045],[-122.692483,47.70117]]]},"properties":{"id":"98315","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.481609,46.868841],[-120.366606,46.830756],[-120.048832,46.941844],[-120.002143,46.99732],[-120.016897,47.043523],[-120.570336,47.22889],[-120.738052,47.123472],[-120.756968,47.074061],[-120.810069,46.911068],[-120.633572,46.869298],[-120.481609,46.868841]],[[-120.419365,46.981107],[-120.428849,46.983937],[-120.425279,46.993352],[-120.401699,46.979637],[-120.413388,46.971588],[-120.419365,46.981107]]]},"properties":{"id":"98926","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.949436,47.192077],[-120.977627,47.183644],[-120.974151,47.178442],[-120.949432,47.184445],[-120.949436,47.192077]]]},"properties":{"id":"98943","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.048832,46.941844],[-119.973017,46.737171],[-119.964956,46.863626],[-119.986617,46.971802],[-120.002143,46.99732],[-120.048832,46.941844]]]},"properties":{"id":"98950","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.9139,46.583543],[-122.904282,46.572666],[-122.892643,46.583471],[-122.9139,46.583543]]]},"properties":{"id":"98565","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.647742,46.762733],[-122.781159,46.546749],[-122.740506,46.518252],[-122.706462,46.489776],[-122.646726,46.539364],[-122.625727,46.546571],[-122.59428,46.560975],[-122.481081,46.763123],[-122.509498,46.76275],[-122.634951,46.762634],[-122.647742,46.762733]]]},"properties":{"id":"98570","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.533211,47.875549],[-118.34175,47.815125],[-118.380439,47.831061],[-118.465791,47.882175],[-118.533211,47.875549]]]},"properties":{"id":"99147","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.80443,47.335667],[-122.904682,47.360385],[-122.994084,47.346953],[-122.814816,47.306816],[-122.80443,47.335667]]]},"properties":{"id":"98546","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.704822,48.613359],[-119.620461,48.558866],[-119.196372,48.482224],[-118.86954,48.481881],[-118.895872,48.65372],[-119.014073,48.81719],[-119.559737,48.831302],[-119.687972,48.69983],[-119.704822,48.613359]]]},"properties":{"id":"98855","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.956736,47.995726],[-118.951064,47.99571],[-118.947469,48.004356],[-118.952761,48.004339],[-118.956736,47.995726]]]},"properties":{"id":"99124","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.067854,46.338786],[-124.047086,46.325872],[-124.006075,46.458489],[-124.060256,46.464883],[-124.067854,46.338786]]]},"properties":{"id":"98631","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.502145,48.209101],[-117.175499,48.397413],[-117.500068,48.326762],[-117.502145,48.209101]]]},"properties":{"id":"99180","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.349664,48.006874],[-117.152969,47.93766],[-117.107567,47.958853],[-117.405461,48.075249],[-117.349664,48.006874]]]},"properties":{"id":"99009","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.349664,48.006874],[-117.405461,48.075249],[-117.502166,48.194706],[-117.55363,48.079263],[-117.649207,47.961281],[-117.714636,47.934244],[-117.70861,47.874843],[-117.535597,47.830619],[-117.481632,47.860211],[-117.387489,47.889619],[-117.349664,48.006874]]]},"properties":{"id":"99006","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.443634,47.235987],[-122.440946,47.231413],[-122.432747,47.233235],[-122.437727,47.268046],[-122.4442,47.266723],[-122.447729,47.263764],[-122.443634,47.235987]]]},"properties":{"id":"98402","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.447729,47.263764],[-122.468328,47.255443],[-122.503797,47.254812],[-122.505196,47.243632],[-122.443634,47.235987],[-122.447729,47.263764]]]},"properties":{"id":"98405","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.50524,47.213799],[-122.582113,47.224312],[-122.622139,47.209219],[-122.623839,47.193919],[-122.622739,47.188819],[-122.576129,47.186416],[-122.526342,47.19771],[-122.505233,47.194336],[-122.50524,47.213799]]]},"properties":{"id":"98467","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.556885,47.118417],[-122.500523,47.116822],[-122.463986,47.10031],[-122.469227,47.132325],[-122.467953,47.154169],[-122.493824,47.150069],[-122.493931,47.133132],[-122.487208,47.122997],[-122.504668,47.122708],[-122.504563,47.139172],[-122.525103,47.136485],[-122.557013,47.118508],[-122.556885,47.118417]]]},"properties":{"id":"98439","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357984,47.258683],[-122.336029,47.255916],[-122.33486,47.257594],[-122.335847,47.263808],[-122.418293,47.320631],[-122.410858,47.287583],[-122.357984,47.258683]]]},"properties":{"id":"98422","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.544138,47.27792],[-122.46943,47.273336],[-122.464567,47.276103],[-122.560339,47.316119],[-122.544138,47.27792]]]},"properties":{"id":"98407","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.526342,47.19771],[-122.525103,47.136485],[-122.504563,47.139172],[-122.493824,47.150069],[-122.467953,47.154169],[-122.463794,47.184692],[-122.505233,47.194336],[-122.526342,47.19771]]]},"properties":{"id":"98499","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.636819,47.089595],[-122.500523,47.116822],[-122.556885,47.118417],[-122.570354,47.12494],[-122.593093,47.159442],[-122.632239,47.143319],[-122.67044,47.121619],[-122.636819,47.089595]]]},"properties":{"id":"98433","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.570354,47.12494],[-122.556885,47.118417],[-122.557013,47.118508],[-122.570354,47.12494]]]},"properties":{"id":"98430","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.192152,46.762315],[-122.127212,46.75394],[-122.162829,46.796754],[-122.192152,46.762315]]]},"properties":{"id":"98330","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.51507,48.454939],[-122.492062,48.361929],[-122.378443,48.29759],[-122.303439,48.297596],[-122.191973,48.428043],[-122.234102,48.46073],[-122.234392,48.464186],[-122.291697,48.475072],[-122.444409,48.493947],[-122.482971,48.497923],[-122.514837,48.468839],[-122.51507,48.454939]],[[-122.346726,48.34159],[-122.340573,48.34107],[-122.344639,48.33131],[-122.348063,48.339157],[-122.346726,48.34159]]]},"properties":{"id":"98273","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.001409,48.295548],[-121.306742,48.641294],[-121.53268,48.642454],[-121.556195,48.383858],[-121.001409,48.295548]]]},"properties":{"id":"98267","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.51507,48.454939],[-122.554197,48.444572],[-122.492062,48.361929],[-122.51507,48.454939]]]},"properties":{"id":"98257","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.139642,45.658388],[-121.793406,45.76767],[-121.997213,46.040235],[-122.245633,46.053932],[-122.245069,45.909809],[-122.249618,45.673191],[-122.139642,45.658388]]]},"properties":{"id":"98610","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.243201,47.828961],[-122.332419,47.830276],[-122.330141,47.799627],[-122.28704,47.777242],[-122.270957,47.777019],[-122.267046,47.777019],[-122.229699,47.809486],[-122.243201,47.828961]]]},"properties":{"id":"98036","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.363975,47.836605],[-122.356209,47.777766],[-122.329939,47.77762],[-122.327304,47.777652],[-122.330141,47.799627],[-122.332419,47.830276],[-122.296201,47.847313],[-122.293235,47.875086],[-122.332038,47.900066],[-122.363975,47.836605]]]},"properties":{"id":"98026","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.666777,47.776922],[-121.685484,47.974633],[-121.722838,47.970072],[-121.804003,47.950006],[-121.849653,47.777223],[-121.666876,47.776921],[-121.666777,47.776922]]]},"properties":{"id":"98294","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.685484,47.974633],[-121.666777,47.776922],[-121.491749,47.777853],[-121.339034,47.887149],[-121.50347,47.984894],[-121.685484,47.974633]]]},"properties":{"id":"98251","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.196631,47.669115],[-117.189158,47.596796],[-117.224285,47.579886],[-117.110624,47.580184],[-117.085625,47.579908],[-117.143235,47.680638],[-117.175261,47.678829],[-117.196631,47.669115]]]},"properties":{"id":"99016","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.240483,47.700375],[-117.218188,47.627857],[-117.196631,47.669115],[-117.175261,47.678829],[-117.175425,47.715044],[-117.240483,47.700375]]]},"properties":{"id":"99216","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.361455,47.806866],[-117.24138,47.861398],[-117.387489,47.889619],[-117.481632,47.860211],[-117.361455,47.806866]]]},"properties":{"id":"99005","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.218188,47.627857],[-117.240483,47.700375],[-117.282862,47.704376],[-117.293548,47.620745],[-117.297707,47.594328],[-117.222805,47.573051],[-117.224285,47.579886],[-117.189158,47.596796],[-117.218188,47.627857]]]},"properties":{"id":"99206","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.90638,47.021096],[-123.200957,47.056622],[-123.200041,47.014792],[-123.159279,46.938982],[-123.16082,46.847251],[-122.962076,46.89318],[-122.866355,46.900326],[-122.90638,47.021096]]]},"properties":{"id":"98512","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.788357,46.000924],[-118.439021,46.000833],[-118.58038,46.221695],[-118.679241,46.238498],[-118.804554,46.12569],[-118.788357,46.000924]]]},"properties":{"id":"99360","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.585436,48.787615],[-122.136538,49.002233],[-122.13694,49.002092],[-122.222227,48.912522],[-122.270058,48.829352],[-122.257237,48.730768],[-122.120937,48.646141],[-121.920221,48.64482],[-121.585436,48.787615]]]},"properties":{"id":"98244","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.120937,48.646141],[-122.257237,48.730768],[-122.268316,48.707759],[-122.120937,48.646141]]]},"properties":{"id":"98220","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.661342,48.697685],[-122.70306,48.743602],[-122.718971,48.717069],[-122.661342,48.697685]]]},"properties":{"id":"98262","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.035393,49.002154],[-123.090546,49.001976],[-123.084423,48.973557],[-123.010779,48.978962],[-123.035393,49.002154]]]},"properties":{"id":"98281","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.20443,47.25957],[-117.364588,47.137625],[-117.3237,47.049714],[-117.2682,47.02792],[-117.184585,47.064647],[-117.17172,47.18195],[-117.17211,47.25958],[-117.20443,47.25957]]]},"properties":{"id":"99158","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.15,46.72953],[-117.14921,46.73306],[-117.15394,46.73218],[-117.15,46.72953]]]},"properties":{"id":"99164","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.251514,47.300078],[-118.422412,47.313361],[-118.98117,47.13036],[-118.93988,47.02798],[-118.399005,46.897735],[-118.221293,46.957573],[-117.959029,46.931258],[-117.959674,47.072624],[-117.960143,47.260449],[-118.251514,47.300078]]]},"properties":{"id":"99169","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.221293,46.957573],[-118.399005,46.897735],[-118.505473,46.736762],[-118.228924,46.664127],[-118.22417,46.691249],[-118.16863,46.79066],[-118.221293,46.957573]]]},"properties":{"id":"99371","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.16317,46.194584],[-119.223318,46.062501],[-119.204341,45.929285],[-118.987129,45.999855],[-118.988846,46.153383],[-119.041418,46.192856],[-119.09751,46.215049],[-119.16317,46.194584]]]},"properties":{"id":"99337","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.368653,46.264695],[-119.333106,46.297537],[-119.393781,46.367462],[-119.435582,46.373451],[-119.368653,46.264695]]]},"properties":{"id":"99353","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.425137,47.447081],[-120.450275,47.295632],[-120.457923,47.298587],[-120.259367,47.261448],[-120.248114,47.381044],[-120.244504,47.579148],[-120.237548,47.627959],[-120.441951,47.637557],[-120.455684,47.620153],[-120.411189,47.48628],[-120.425137,47.447081]]]},"properties":{"id":"98801","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.550325,45.597269],[-122.47577,45.578681],[-122.475948,45.621288],[-122.559399,45.621029],[-122.550325,45.597269]]]},"properties":{"id":"98683","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.250881,45.664765],[-122.346402,45.715959],[-122.393454,45.694262],[-122.45684,45.638823],[-122.475948,45.621288],[-122.47577,45.578681],[-122.383569,45.575543],[-122.250881,45.664765]]]},"properties":{"id":"98607","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.08607,46.45272],[-117.992905,46.062729],[-117.996911,46.000787],[-117.603163,45.998887],[-117.851844,46.624772],[-117.980054,46.587769],[-118.08607,46.45272]]]},"properties":{"id":"99328","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.212195,47.83582],[-119.599334,47.684942],[-119.705484,47.570013],[-119.533353,47.470013],[-119.27683,47.507877],[-119.149447,47.524221],[-119.23277,47.73862],[-119.212195,47.83582]]]},"properties":{"id":"99115","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.244504,47.579148],[-120.15492,47.654735],[-119.920757,47.859243],[-119.978422,47.800702],[-119.992679,47.78315],[-120.020455,47.779327],[-120.184962,47.761541],[-120.237548,47.627959],[-120.244504,47.579148]]]},"properties":{"id":"98843","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.473841,48.078385],[-119.77257,47.978668],[-119.870423,47.960442],[-119.870307,47.960328],[-119.863386,47.937031],[-119.599334,47.684942],[-119.212195,47.83582],[-119.190619,47.902548],[-119.243867,48.106689],[-119.473841,48.078385]]]},"properties":{"id":"98830","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.424386,48.758985],[-118.739707,48.828339],[-118.435269,48.740904],[-118.424386,48.758985]]]},"properties":{"id":"99150","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.127985,46.664771],[-119.368939,46.678204],[-119.45322,46.679243],[-119.263394,46.476291],[-119.227012,46.500546],[-119.007146,46.546773],[-119.127985,46.664771]]]},"properties":{"id":"99343","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.090107,46.931703],[-123.839616,47.24418],[-124.021416,47.232095],[-124.096214,47.136761],[-124.158716,47.065729],[-124.090107,46.931703]]]},"properties":{"id":"98550","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.399949,47.590775],[-122.381464,47.557458],[-122.381858,47.513751],[-122.367216,47.513916],[-122.359978,47.584699],[-122.360105,47.591537],[-122.399949,47.590775]]]},"properties":{"id":"98126","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.304599,47.647402],[-122.255735,47.64712],[-122.231435,47.66412],[-122.328225,47.671567],[-122.326537,47.65032],[-122.312412,47.647196],[-122.304599,47.647402]]]},"properties":{"id":"98105","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.381464,47.557458],[-122.399949,47.590775],[-122.409757,47.560272],[-122.381464,47.557458]]]},"properties":{"id":"98116","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.414259,47.675691],[-122.39122,47.663839],[-122.360539,47.65292],[-122.354463,47.649932],[-122.357441,47.675997],[-122.414259,47.675691]]]},"properties":{"id":"98107","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.320091,47.572433],[-122.342737,47.55622],[-122.337485,47.517258],[-122.334005,47.513865],[-122.291325,47.511627],[-122.292075,47.570186],[-122.320091,47.572433]]]},"properties":{"id":"98108","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.220434,47.596821],[-122.199091,47.574044],[-122.18762,47.579527],[-122.185547,47.646409],[-122.212091,47.657464],[-122.220434,47.596821]]]},"properties":{"id":"98004","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.083319,47.391131],[-122.053873,47.34357],[-121.998996,47.343517],[-121.853337,47.391094],[-121.822725,47.447451],[-121.884511,47.504578],[-122.058277,47.466114],[-122.07672,47.45992],[-122.083319,47.391131]]]},"properties":{"id":"98038","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.055651,47.56578],[-121.973468,47.584419],[-121.970676,47.594577],[-121.979785,47.601265],[-122.090633,47.602261],[-122.098001,47.587777],[-122.055651,47.56578]]]},"properties":{"id":"98075","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.791637,47.693596],[-122.003794,47.705216],[-121.952948,47.609807],[-121.843017,47.616123],[-121.791637,47.693596]]]},"properties":{"id":"98014","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.497575,47.503032],[-122.682825,47.527548],[-122.801013,47.484004],[-122.801451,47.404016],[-122.630553,47.403518],[-122.624683,47.403424],[-122.519009,47.47423],[-122.497575,47.503032]]]},"properties":{"id":"98367","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.618485,47.553081],[-122.651743,47.558691],[-122.649854,47.546772],[-122.618485,47.553081]]]},"properties":{"id":"98314","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.58719,47.838077],[-122.584017,47.850687],[-122.593565,47.846997],[-122.592254,47.836556],[-122.58719,47.838077]]]},"properties":{"id":"98364","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.146932,47.593463],[-121.104254,47.248381],[-121.033236,47.223067],[-120.962006,47.232918],[-120.935052,47.459189],[-121.113606,47.597135],[-121.146932,47.593463]]]},"properties":{"id":"98940","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.033236,47.223067],[-120.99452,47.218993],[-120.962006,47.232918],[-121.033236,47.223067]]]},"properties":{"id":"98941","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.384813,45.89108],[-121.331634,45.811947],[-121.175312,45.821571],[-121.175319,45.823026],[-121.196297,45.899483],[-121.384813,45.89108]]]},"properties":{"id":"98602","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.580065,46.387654],[-122.588331,46.503105],[-122.687913,46.499014],[-122.706462,46.489776],[-122.740506,46.518252],[-122.813093,46.51781],[-122.928243,46.411561],[-122.932242,46.387385],[-122.702614,46.387265],[-122.580065,46.387654]]]},"properties":{"id":"98591","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.732755,46.614802],[-121.850792,46.729178],[-122.127212,46.75394],[-122.192152,46.762315],[-122.202721,46.762628],[-122.316801,46.762959],[-122.295416,46.649937],[-122.19877,46.617596],[-122.000734,46.606904],[-121.732755,46.614802]]]},"properties":{"id":"98355","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.910225,47.794705],[-117.948713,47.827316],[-118.148648,47.886618],[-118.340925,47.894395],[-118.380439,47.831061],[-118.34175,47.815125],[-118.592993,47.580392],[-118.571213,47.502964],[-118.094928,47.465309],[-117.937917,47.660673],[-117.910225,47.794705]]]},"properties":{"id":"99122","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.53686,47.876827],[-118.635493,47.580881],[-118.592993,47.580392],[-118.34175,47.815125],[-118.533211,47.875549],[-118.53686,47.876827]]]},"properties":{"id":"99117","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.022873,47.520255],[-123.044805,47.374319],[-122.950653,47.52247],[-123.022873,47.520255]]]},"properties":{"id":"98588","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.994084,47.346953],[-123.05659,47.356923],[-123.117474,47.337209],[-122.994084,47.346953]]]},"properties":{"id":"98592","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.196372,48.482224],[-119.548857,48.466179],[-119.119279,48.17153],[-118.84395,48.438947],[-118.86954,48.481881],[-119.196372,48.482224]]]},"properties":{"id":"98841","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.033708,48.655338],[-117.4295,49.000483],[-117.498574,48.836892],[-117.413577,48.826254],[-117.420234,48.848116],[-117.393455,48.871916],[-117.389074,48.819617],[-117.278594,48.641425],[-117.033708,48.655338]]]},"properties":{"id":"99153","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.570354,47.12494],[-122.557013,47.118508],[-122.525103,47.136485],[-122.526342,47.19771],[-122.576129,47.186416],[-122.593093,47.159442],[-122.570354,47.12494]]]},"properties":{"id":"98498","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.462573,47.214728],[-122.463794,47.184692],[-122.431405,47.18094],[-122.431312,47.2152],[-122.462573,47.214728]]]},"properties":{"id":"98408","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.550527,46.930762],[-122.550458,46.933955],[-122.56439,46.936916],[-122.550527,46.930762]]]},"properties":{"id":"98558","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.563107,47.250568],[-122.681141,47.271318],[-122.675341,47.251319],[-122.622139,47.209219],[-122.582113,47.224312],[-122.565437,47.24682],[-122.563107,47.250568]]]},"properties":{"id":"98333","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.103565,46.963673],[-121.917349,46.858091],[-121.701319,46.996009],[-121.948011,47.142662],[-121.972355,47.134601],[-122.063107,47.079504],[-122.066277,47.076524],[-122.103565,46.963673]]]},"properties":{"id":"98323","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.444409,48.493947],[-122.291697,48.475072],[-122.332399,48.620039],[-122.345646,48.615901],[-122.444409,48.493947]]]},"properties":{"id":"98233","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.53268,48.642454],[-121.306742,48.641294],[-121.563549,48.78721],[-121.53268,48.642454]]]},"properties":{"id":"98283","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.793406,45.76767],[-122.139642,45.658388],[-122.100197,45.584215],[-121.675996,45.70307],[-121.740009,45.786037],[-121.793406,45.76767]],[[-122.022724,45.644113],[-122.024257,45.686267],[-121.96012,45.694153],[-121.950923,45.651146],[-122.022724,45.644113]]]},"properties":{"id":"98648","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.165973,47.877758],[-122.227958,47.863769],[-122.235879,47.849433],[-122.243201,47.828961],[-122.229699,47.809486],[-122.164693,47.808534],[-122.157929,47.818436],[-122.158421,47.856027],[-122.172874,47.863373],[-122.165973,47.877758]]]},"properties":{"id":"98012","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.164693,47.808534],[-122.229699,47.809486],[-122.267046,47.777019],[-122.227282,47.776637],[-122.173598,47.776204],[-122.164693,47.808534]]]},"properties":{"id":"98021","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.184084,48.049806],[-122.140546,48.091959],[-122.264342,48.139937],[-122.368688,48.123366],[-122.241221,48.027462],[-122.184084,48.049806]]]},"properties":{"id":"98271","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.607503,47.594664],[-117.784654,47.519272],[-117.822877,47.420558],[-117.823695,47.260378],[-117.728111,47.222827],[-117.572984,47.259786],[-117.45639,47.368771],[-117.440142,47.491439],[-117.493276,47.566733],[-117.496298,47.558571],[-117.498821,47.56416],[-117.607503,47.594664]]]},"properties":{"id":"99004","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.493276,47.566733],[-117.440142,47.491439],[-117.3682,47.522103],[-117.368384,47.535548],[-117.416536,47.613716],[-117.429468,47.628238],[-117.443891,47.649596],[-117.452447,47.680245],[-117.492151,47.700789],[-117.510954,47.72996],[-117.717429,47.78078],[-117.711572,47.70129],[-117.603866,47.658105],[-117.604061,47.613868],[-117.607503,47.594664],[-117.498821,47.56416],[-117.493276,47.566733]]]},"properties":{"id":"99224","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.196631,47.669115],[-117.218188,47.627857],[-117.189158,47.596796],[-117.196631,47.669115]]]},"properties":{"id":"99037","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.067778,48.662558],[-118.052467,48.665651],[-118.060391,48.666354],[-118.070922,48.664103],[-118.068988,48.662334],[-118.067778,48.662558]]]},"properties":{"id":"99151","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.922912,48.002405],[-118.080416,48.002031],[-118.148648,47.886618],[-117.948713,47.827316],[-117.922912,48.002405]]]},"properties":{"id":"99040","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.200957,47.056622],[-122.90638,47.021096],[-122.909525,47.059456],[-122.905659,47.155614],[-123.202066,47.084895],[-123.200957,47.056622]]]},"properties":{"id":"98502","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.866355,46.900326],[-122.962076,46.89318],[-122.922313,46.806998],[-122.879887,46.799823],[-122.799959,46.789877],[-122.781228,46.768485],[-122.699114,46.763537],[-122.647742,46.762733],[-122.634951,46.762634],[-122.780985,46.916271],[-122.809998,46.920193],[-122.866355,46.900326]]]},"properties":{"id":"98589","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.340114,46.291982],[-123.436394,46.238197],[-123.213054,46.172541],[-123.216344,46.221986],[-123.340114,46.291982]]]},"properties":{"id":"98612","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.58038,46.221695],[-118.439021,46.000833],[-117.996911,46.000787],[-117.992905,46.062729],[-118.166724,46.136708],[-118.14989,46.167848],[-118.294079,46.250821],[-118.58038,46.221695]],[[-118.372524,46.054353],[-118.372964,46.031164],[-118.404372,46.027521],[-118.409455,46.049299],[-118.372524,46.054353]]]},"properties":{"id":"99362","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.166724,46.136708],[-118.1323,46.157852],[-118.14989,46.167848],[-118.166724,46.136708]]]},"properties":{"id":"99329","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.510194,48.887869],[-122.703448,48.778994],[-122.572876,48.777203],[-122.46158,48.763016],[-122.31126,48.72063],[-122.268316,48.707759],[-122.257237,48.730768],[-122.270058,48.829352],[-122.442577,48.869841],[-122.510194,48.887869]]]},"properties":{"id":"98226","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.550712,49.002445],[-122.769754,48.994955],[-122.726467,48.854624],[-122.68907,48.935595],[-122.552061,48.964768],[-122.550712,49.002445]]]},"properties":{"id":"98230","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.18108,46.83458],[-117.27059,46.91275],[-117.2682,47.02792],[-117.3237,47.049714],[-117.46712,47.00978],[-117.504098,46.952904],[-117.588111,46.856103],[-117.727504,46.700558],[-117.468145,46.70002],[-117.18108,46.83458]],[[-117.35754,47.0059],[-117.35892,47.00953],[-117.36267,47.01038],[-117.36215,47.014],[-117.35687,47.012],[-117.35433,47.00588],[-117.35754,47.0059]]]},"properties":{"id":"99111","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.18108,46.83458],[-117.468145,46.70002],[-117.435601,46.661901],[-117.039413,46.701825],[-117.03981,46.82594],[-117.18108,46.83458]],[[-117.15,46.72953],[-117.15394,46.73218],[-117.14921,46.73306],[-117.15,46.72953]],[[-117.24965,46.79254],[-117.251917,46.787919],[-117.25788,46.78907],[-117.25405,46.79205],[-117.24965,46.79254]]]},"properties":{"id":"99163","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.809408,46.664528],[-120.690766,46.648694],[-120.68536,46.680314],[-120.809408,46.664528]]]},"properties":{"id":"98923","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.732196,46.420607],[-120.565274,46.356939],[-120.544042,46.368075],[-120.732196,46.420607]]]},"properties":{"id":"98933","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.556897,46.623595],[-120.562046,46.574662],[-120.497432,46.577848],[-120.556897,46.623595]]]},"properties":{"id":"98902","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.809408,46.664528],[-120.68536,46.680314],[-120.678446,46.687717],[-120.779082,46.745434],[-120.887869,46.621243],[-120.809408,46.664528]]]},"properties":{"id":"98947","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.990051,46.040976],[-119.87699,46.201353],[-119.999397,46.258265],[-120.106674,46.258998],[-120.126102,46.255034],[-120.366946,46.105392],[-120.340445,46.041333],[-119.990051,46.040976]]]},"properties":{"id":"98935","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.16863,46.79066],[-118.041705,46.773539],[-117.997884,46.823396],[-117.959029,46.931258],[-118.221293,46.957573],[-118.16863,46.79066]]]},"properties":{"id":"99105","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.237872,46.470056],[-117.419662,46.277545],[-117.060086,46.34845],[-117.039813,46.425425],[-117.237872,46.470056]]]},"properties":{"id":"99403","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.419457,46.135289],[-117.09029,45.997531],[-117.060086,46.34845],[-117.419662,46.277545],[-117.419457,46.135289]]]},"properties":{"id":"99402","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.348105,46.139439],[-119.618195,46.389532],[-119.873508,46.331353],[-119.87699,46.201353],[-119.990051,46.040976],[-119.998473,45.90964],[-119.867995,45.847196],[-119.455638,46.042379],[-119.327108,46.085199],[-119.348105,46.139439]]]},"properties":{"id":"99350","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.622304,48.037964],[-124.33298,47.971083],[-124.25317,48.110337],[-124.622304,48.037964]]]},"properties":{"id":"98305","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.43792,48.122738],[-123.467657,47.866918],[-123.231207,47.961388],[-123.220755,48.125418],[-123.43792,48.122738]]]},"properties":{"id":"98362","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.620488,45.76538],[-122.599639,45.747743],[-122.57713,45.729526],[-122.37299,45.757271],[-122.529817,45.874708],[-122.631127,45.82758],[-122.620488,45.76538]]]},"properties":{"id":"98604","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.419662,46.277545],[-117.237872,46.470056],[-117.252749,46.543071],[-117.435601,46.661901],[-117.468145,46.70002],[-117.727504,46.700558],[-117.851844,46.624772],[-117.603163,45.998887],[-117.480141,45.997813],[-117.419457,46.135289],[-117.419662,46.277545]]]},"properties":{"id":"99347","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.294079,46.250821],[-118.18412,46.44847],[-118.228928,46.593606],[-118.537174,46.561583],[-118.793513,46.29057],[-118.679241,46.238498],[-118.58038,46.221695],[-118.294079,46.250821]]]},"properties":{"id":"99348","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.649823,46.141233],[-122.86864,46.077153],[-122.881327,46.045974],[-122.812215,45.954459],[-122.646992,46.022475],[-122.600348,46.048218],[-122.649823,46.141233]]]},"properties":{"id":"98625","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.516156,48.077789],[-119.77257,47.978668],[-119.473841,48.078385],[-119.516156,48.077789]]]},"properties":{"id":"98813","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.88625,48.279034],[-119.957011,48.279078],[-119.965521,48.156552],[-119.870423,47.960442],[-119.77257,47.978668],[-119.516156,48.077789],[-119.725441,48.248099],[-119.88625,48.279034]]]},"properties":{"id":"98812","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.190619,47.902548],[-118.974008,47.906376],[-118.840666,47.903229],[-118.745376,47.903222],[-118.851972,47.957686],[-119.013551,48.06335],[-119.114067,48.145278],[-119.243867,48.106689],[-119.190619,47.902548]]]},"properties":{"id":"99133","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.84574,48.050069],[-119.013551,48.06335],[-118.851972,47.957686],[-118.84574,48.050069]],[[-118.956736,47.995726],[-118.952761,48.004339],[-118.947469,48.004356],[-118.951064,47.99571],[-118.956736,47.995726]]]},"properties":{"id":"99116","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.575246,48.353798],[-118.465791,47.882175],[-118.380439,47.831061],[-118.340925,47.894395],[-118.253853,48.124829],[-118.183028,48.374894],[-118.202444,48.418722],[-118.286175,48.481348],[-118.575246,48.353798]]]},"properties":{"id":"99138","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.00208,47.144842],[-119.212853,47.393766],[-119.277142,47.39539],[-119.45035,47.25731],[-119.619775,47.103775],[-119.605293,47.030723],[-119.557649,46.962996],[-119.266996,46.985405],[-119.00208,47.144842]]]},"properties":{"id":"98837","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.158716,47.065729],[-124.180988,47.093003],[-124.156035,46.914055],[-124.126286,46.920832],[-124.090107,46.931703],[-124.158716,47.065729]]]},"properties":{"id":"98569","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.48816,47.979695],[-122.355838,47.993911],[-122.484646,47.99105],[-122.48816,47.979695]]]},"properties":{"id":"98236","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.530673,48.036831],[-122.604624,48.05639],[-122.48816,47.979695],[-122.484646,47.99105],[-122.527299,48.063682],[-122.530673,48.036831]]]},"properties":{"id":"98249","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.722922,48.230919],[-122.768716,48.253784],[-122.609885,48.109052],[-122.566602,48.154847],[-122.722922,48.230919]]]},"properties":{"id":"98239","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.847441,47.771481],[-122.980706,47.784906],[-123.025381,47.606571],[-122.982394,47.606147],[-122.847441,47.771481]]]},"properties":{"id":"98320","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.73548,47.984],[-122.736079,47.890519],[-122.736135,47.804945],[-122.711543,47.98404],[-122.73548,47.984]]]},"properties":{"id":"98365","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.73548,47.984],[-122.79169,48.042009],[-122.821747,47.925902],[-122.736079,47.890519],[-122.73548,47.984]]]},"properties":{"id":"98325","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.334674,47.606336],[-122.345442,47.603174],[-122.344837,47.59922],[-122.319253,47.595823],[-122.316967,47.600851],[-122.323115,47.612157],[-122.333564,47.606799],[-122.332911,47.606086],[-122.334674,47.606336]],[[-122.334479,47.604445],[-122.335588,47.60398],[-122.33624,47.604693],[-122.335131,47.605158],[-122.334479,47.604445]],[[-122.332911,47.606086],[-122.3318,47.606551],[-122.331148,47.605837],[-122.332258,47.605372],[-122.332911,47.606086]]]},"properties":{"id":"98104","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.262134,47.575921],[-122.23807,47.521323],[-122.225394,47.521368],[-122.202007,47.546025],[-122.199091,47.574044],[-122.220434,47.596821],[-122.265735,47.61362],[-122.267635,47.601521],[-122.262134,47.575921]]]},"properties":{"id":"98040","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.426301,47.390137],[-121.534903,47.546934],[-121.698711,47.635639],[-121.822725,47.447451],[-121.853337,47.391094],[-121.782086,47.276641],[-121.339553,47.276928],[-121.426301,47.390137]]]},"properties":{"id":"98045","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.932913,47.317762],[-121.782086,47.276641],[-121.853337,47.391094],[-121.998996,47.343517],[-121.932913,47.317762]]]},"properties":{"id":"98051","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.230694,47.271931],[-122.262259,47.257554],[-122.258632,47.249855],[-122.229495,47.257556],[-122.229982,47.265887],[-122.230694,47.271931]]]},"properties":{"id":"98047","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.651743,47.558691],[-122.618485,47.553081],[-122.607123,47.561773],[-122.646463,47.583662],[-122.651743,47.558691]]]},"properties":{"id":"98337","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.545204,47.737272],[-122.5658,47.712368],[-122.548673,47.725658],[-122.545204,47.737272]]]},"properties":{"id":"98392","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.630553,47.403518],[-122.801451,47.404016],[-122.80205,47.358817],[-122.763259,47.344052],[-122.706467,47.318284],[-122.687544,47.334318],[-122.630553,47.403518]]]},"properties":{"id":"98329","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.339553,47.276928],[-121.30257,47.140555],[-121.205845,47.127003],[-121.132859,47.248587],[-121.426301,47.390137],[-121.339553,47.276928]]]},"properties":{"id":"98925","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.051617,45.670811],[-121.11009,45.657476],[-121.140551,45.61124],[-120.885674,45.650038],[-120.942464,45.667703],[-121.051617,45.670811]]]},"properties":{"id":"98673","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.922313,46.806998],[-123.193367,46.7341],[-122.699114,46.763537],[-122.781228,46.768485],[-122.879887,46.799823],[-122.922313,46.806998]],[[-123.029743,46.740938],[-123.023133,46.737791],[-123.030101,46.736495],[-123.029743,46.740938]]]},"properties":{"id":"98531","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.962076,46.89318],[-123.16082,46.847251],[-123.330516,46.736258],[-123.193367,46.7341],[-122.922313,46.806998],[-122.962076,46.89318]]]},"properties":{"id":"98579","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.687913,46.499014],[-122.588331,46.503105],[-122.588152,46.503275],[-122.625727,46.546571],[-122.646726,46.539364],[-122.687913,46.499014]]]},"properties":{"id":"98582","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.635493,47.580881],[-118.53686,47.876827],[-118.745376,47.903222],[-118.840666,47.903229],[-118.849542,47.53752],[-118.784953,47.508804],[-118.635493,47.580881]]]},"properties":{"id":"99185","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.144849,47.393809],[-123.109789,47.461791],[-123.130889,47.470849],[-123.504346,47.50421],[-123.506033,47.359203],[-123.305783,47.338868],[-123.144849,47.393809]]]},"properties":{"id":"98548","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.982394,47.606147],[-123.025381,47.606571],[-123.130889,47.470849],[-123.109789,47.461791],[-122.982394,47.606147]]]},"properties":{"id":"98555","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.83655,48.826978],[-118.836903,49.000312],[-118.954966,49.000273],[-119.014073,48.81719],[-118.895872,48.65372],[-118.83655,48.826978]]]},"properties":{"id":"98859","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.957011,48.279078],[-120.383719,48.221995],[-120.238086,48.095419],[-119.965521,48.156552],[-119.957011,48.279078]]]},"properties":{"id":"98814","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.965521,48.156552],[-120.142485,48.053726],[-120.093314,48.019497],[-120.066389,47.966457],[-119.870307,47.960328],[-119.870423,47.960442],[-119.965521,48.156552]]]},"properties":{"id":"98846","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.357663,47.170026],[-122.357473,47.125351],[-122.293081,47.125653],[-122.293489,47.169796],[-122.357663,47.170026]]]},"properties":{"id":"98373","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.293489,47.169796],[-122.215443,47.169722],[-122.258632,47.249855],[-122.262259,47.257554],[-122.293736,47.257251],[-122.293902,47.242744],[-122.293489,47.169796]]]},"properties":{"id":"98372","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.245576,47.083533],[-122.226241,47.140316],[-122.215443,47.169722],[-122.293489,47.169796],[-122.293081,47.125653],[-122.293799,47.081873],[-122.245576,47.083533]]]},"properties":{"id":"98374","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.463986,47.10031],[-122.38782,46.997485],[-122.30986,47.081909],[-122.356865,47.092828],[-122.388704,47.082129],[-122.434803,47.116016],[-122.469227,47.132325],[-122.463986,47.10031]]]},"properties":{"id":"98387","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.101905,47.1355],[-122.066277,47.076524],[-122.063107,47.079504],[-122.041396,47.109787],[-121.972355,47.134601],[-121.948011,47.142662],[-122.115651,47.220837],[-122.123196,47.138452],[-122.098938,47.140996],[-122.080598,47.140981],[-122.085589,47.133581],[-122.101905,47.1355]]]},"properties":{"id":"98321","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.703845,47.071705],[-122.637924,46.98127],[-122.56439,46.936916],[-122.550458,46.933955],[-122.550527,46.930762],[-122.421362,46.84972],[-122.399891,46.962197],[-122.38782,46.997485],[-122.463986,47.10031],[-122.500523,47.116822],[-122.636819,47.089595],[-122.703845,47.071705]]]},"properties":{"id":"98580","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.245576,47.083533],[-122.293799,47.081873],[-122.30986,47.081909],[-122.38782,46.997485],[-122.399891,46.962197],[-122.235923,46.953981],[-122.245576,47.083533]]]},"properties":{"id":"98338","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.636819,47.089595],[-122.67044,47.121619],[-122.703672,47.120204],[-122.704026,47.071879],[-122.703845,47.071705],[-122.636819,47.089595]]]},"properties":{"id":"98327","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.731408,47.206768],[-122.851876,47.251956],[-122.821426,47.194211],[-122.7595,47.141216],[-122.711036,47.195957],[-122.731408,47.206768]]]},"properties":{"id":"98351","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.786026,48.528884],[-122.801548,48.589672],[-122.842426,48.585443],[-122.876291,48.584741],[-122.876291,48.576057],[-122.84254,48.55719],[-122.786026,48.528884]]]},"properties":{"id":"98222","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.022724,45.644113],[-121.950923,45.651146],[-121.96012,45.694153],[-122.024257,45.686267],[-122.022724,45.644113]]]},"properties":{"id":"98639","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.398825,47.777938],[-122.358667,47.777766],[-122.356209,47.777766],[-122.363975,47.836605],[-122.398825,47.777938]]]},"properties":{"id":"98020","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.339034,47.887149],[-121.491749,47.777853],[-121.301263,47.779638],[-121.248503,47.779363],[-121.339034,47.887149]]]},"properties":{"id":"98256","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.282862,47.704376],[-117.346855,47.676227],[-117.346716,47.673479],[-117.346872,47.642796],[-117.293548,47.620745],[-117.282862,47.704376]]]},"properties":{"id":"99212","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.395344,47.736791],[-117.424431,47.73679],[-117.451711,47.772274],[-117.37837,47.772539],[-117.361455,47.806866],[-117.481632,47.860211],[-117.535597,47.830619],[-117.510954,47.72996],[-117.492151,47.700789],[-117.41124,47.707898],[-117.376085,47.708215],[-117.379228,47.736703],[-117.395344,47.736791]]]},"properties":{"id":"99208","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.411252,47.652553],[-117.443891,47.649596],[-117.429468,47.628238],[-117.411383,47.642697],[-117.411252,47.652553]]]},"properties":{"id":"99204","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.328234,47.488884],[-117.292236,47.367508],[-117.262096,47.352564],[-117.226096,47.344656],[-117.261726,47.32913],[-117.289409,47.34731],[-117.305306,47.31799],[-117.268422,47.289019],[-117.03987,47.332709],[-117.039792,47.393023],[-117.238411,47.478063],[-117.328234,47.488884]]]},"properties":{"id":"99012","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.440142,47.491439],[-117.45639,47.368771],[-117.305306,47.31799],[-117.289409,47.34731],[-117.292236,47.367508],[-117.328234,47.488884],[-117.3682,47.522103],[-117.440142,47.491439]]]},"properties":{"id":"99031","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.233035,48.125234],[-118.072746,48.248132],[-118.031413,48.350901],[-118.056126,48.350889],[-118.183028,48.374894],[-118.253853,48.124829],[-118.233035,48.125234]]]},"properties":{"id":"99131","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.836557,47.187627],[-122.833724,47.045817],[-122.764598,47.053955],[-122.704026,47.071879],[-122.703672,47.120204],[-122.7595,47.141216],[-122.821426,47.194211],[-122.836557,47.187627]]]},"properties":{"id":"98516","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.764598,47.053955],[-122.833628,46.996262],[-122.809998,46.920193],[-122.780985,46.916271],[-122.661615,46.938078],[-122.637924,46.98127],[-122.703845,47.071705],[-122.704026,47.071879],[-122.764598,47.053955]]]},"properties":{"id":"98513","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.64377,46.355872],[-123.684244,46.250956],[-123.552338,46.258619],[-123.52987,46.31333],[-123.64377,46.355872]]]},"properties":{"id":"98643","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.988846,46.153383],[-118.804554,46.12569],[-118.679241,46.238498],[-118.793513,46.29057],[-119.041418,46.192856],[-118.988846,46.153383]]]},"properties":{"id":"99323","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.330978,48.98198],[-122.442577,48.869841],[-122.270058,48.829352],[-122.222227,48.912522],[-122.220901,48.920773],[-122.330978,48.98198]],[[-122.324645,48.92016],[-122.329079,48.932249],[-122.321795,48.931603],[-122.324645,48.92016]]]},"properties":{"id":"98247","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.136538,49.002233],[-122.339083,49.002449],[-122.330978,48.98198],[-122.220901,48.920773],[-122.13694,49.002092],[-122.136538,49.002233]]]},"properties":{"id":"98295","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.324645,48.92016],[-122.321795,48.931603],[-122.329079,48.932249],[-122.324645,48.92016]]]},"properties":{"id":"98276","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.823695,47.260378],[-117.960143,47.260449],[-117.959674,47.072624],[-117.914286,47.088181],[-117.728111,47.222827],[-117.823695,47.260378]]]},"properties":{"id":"99017","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.504098,46.952904],[-117.914286,47.088181],[-117.959674,47.072624],[-117.959029,46.931258],[-117.997884,46.823396],[-117.588111,46.856103],[-117.504098,46.952904]]]},"properties":{"id":"99125","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.727504,46.700558],[-117.588111,46.856103],[-117.997884,46.823396],[-118.041705,46.773539],[-118.027476,46.586055],[-117.980054,46.587769],[-117.851844,46.624772],[-117.727504,46.700558]],[[-118.00021,46.6245],[-118.01569,46.68212],[-117.957494,46.691142],[-117.91966,46.65865],[-118.00021,46.6245]]]},"properties":{"id":"99143","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.469709,47.202175],[-117.451596,47.227285],[-117.478337,47.236337],[-117.469709,47.202175]]]},"properties":{"id":"99149","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.633572,46.869298],[-120.810069,46.911068],[-120.91539,46.911262],[-120.614424,46.699327],[-120.55695,46.626281],[-120.450581,46.73754],[-120.633572,46.869298]]]},"properties":{"id":"98942","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.222225,46.370509],[-120.126102,46.255034],[-120.106674,46.258998],[-120.113628,46.316983],[-120.146432,46.409921],[-120.222225,46.370509]]]},"properties":{"id":"98932","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.463469,46.498867],[-120.469737,46.491938],[-120.458255,46.492202],[-120.463469,46.498867]]]},"properties":{"id":"98939","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.784953,47.508804],[-118.981147,47.144958],[-118.98117,47.13036],[-118.422412,47.313361],[-118.443148,47.33429],[-118.464115,47.435674],[-118.571213,47.502964],[-118.592993,47.580392],[-118.635493,47.580881],[-118.784953,47.508804]]]},"properties":{"id":"99159","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.251514,47.300078],[-117.960143,47.260449],[-117.823695,47.260378],[-117.822877,47.420558],[-118.078447,47.402763],[-118.251514,47.300078]]]},"properties":{"id":"99032","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.89803,46.9114],[-118.785808,46.764406],[-118.65159,46.735852],[-118.505473,46.736762],[-118.399005,46.897735],[-118.93988,47.02798],[-118.89803,46.9114]]]},"properties":{"id":"99341","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.618195,46.389532],[-119.348105,46.139439],[-119.347601,46.228292],[-119.368653,46.264695],[-119.435582,46.373451],[-119.618195,46.389532]]]},"properties":{"id":"99320","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.347601,46.228292],[-119.245565,46.20836],[-119.212805,46.241229],[-119.261728,46.351357],[-119.266009,46.351251],[-119.333106,46.297537],[-119.368653,46.264695],[-119.347601,46.228292]]]},"properties":{"id":"99352","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.333106,46.297537],[-119.266009,46.351251],[-119.393781,46.367462],[-119.333106,46.297537]]]},"properties":{"id":"99354","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.873508,46.331353],[-119.999397,46.258265],[-119.87699,46.201353],[-119.873508,46.331353]]]},"properties":{"id":"98930","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.03163,46.476379],[-120.113628,46.316983],[-120.106674,46.258998],[-119.999397,46.258265],[-119.873508,46.331353],[-119.618195,46.389532],[-119.435582,46.373451],[-119.393781,46.367462],[-119.266009,46.351251],[-119.261728,46.351357],[-119.263394,46.476291],[-119.45322,46.679243],[-119.874057,46.628585],[-120.03163,46.476379]]]},"properties":{"id":"98944","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.066389,47.966457],[-120.098481,47.854563],[-120.198711,47.888014],[-120.199948,47.965857],[-120.093314,48.019497],[-120.142485,48.053726],[-120.238086,48.095419],[-120.622994,48.224291],[-120.963184,48.210733],[-120.906211,48.163848],[-120.816913,48.148379],[-120.184962,47.761541],[-120.020455,47.779327],[-119.978422,47.800702],[-119.920757,47.859243],[-119.863386,47.937031],[-119.870307,47.960328],[-120.066389,47.966457]]]},"properties":{"id":"98816","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.248114,47.381044],[-120.259367,47.261448],[-120.09447,47.262151],[-120.185636,47.373005],[-120.248114,47.381044]]]},"properties":{"id":"98828","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.630985,47.895802],[-124.59018,47.893392],[-124.59023,47.912151],[-124.639639,47.920328],[-124.630985,47.895802]]]},"properties":{"id":"98350","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.664842,45.631559],[-122.691561,45.670843],[-122.706391,45.693523],[-122.734909,45.733896],[-122.770646,45.78184],[-122.675332,45.618162],[-122.664842,45.631559]]]},"properties":{"id":"98660","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.602233,45.678824],[-122.594419,45.640413],[-122.56314,45.642057],[-122.552698,45.707961],[-122.57713,45.729526],[-122.599639,45.747743],[-122.599406,45.693327],[-122.602233,45.678824]]]},"properties":{"id":"98662","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.620488,45.76538],[-122.657629,45.734828],[-122.661222,45.69301],[-122.599406,45.693327],[-122.599639,45.747743],[-122.620488,45.76538]]]},"properties":{"id":"98686","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.245069,45.909809],[-122.511736,45.901484],[-122.529817,45.874708],[-122.37299,45.757271],[-122.346402,45.715959],[-122.250881,45.664765],[-122.249618,45.673191],[-122.245069,45.909809]]]},"properties":{"id":"98675","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.22417,46.691249],[-118.228924,46.664127],[-118.228928,46.593606],[-118.18412,46.44847],[-118.08607,46.45272],[-117.980054,46.587769],[-118.027476,46.586055],[-118.22417,46.691249]]]},"properties":{"id":"99359","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.245771,46.142899],[-122.262119,46.058484],[-122.245633,46.053932],[-121.997213,46.040235],[-122.245771,46.142899]]]},"properties":{"id":"98616","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.15492,47.654735],[-120.052211,47.391542],[-119.854357,47.495422],[-119.705484,47.570013],[-119.599334,47.684942],[-119.863386,47.937031],[-119.920757,47.859243],[-120.15492,47.654735]]]},"properties":{"id":"98858","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.579125,49.00029],[-118.395179,48.863891],[-118.376413,48.923363],[-118.364708,49.000195],[-118.579125,49.00029]]]},"properties":{"id":"99121","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.84574,48.050069],[-118.851972,47.957686],[-118.745376,47.903222],[-118.53686,47.876827],[-118.533211,47.875549],[-118.465791,47.882175],[-118.575246,48.353798],[-118.844447,48.396494],[-118.84574,48.050069]]]},"properties":{"id":"99140","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.013551,48.06335],[-118.84574,48.050069],[-118.844447,48.396494],[-118.84395,48.438947],[-119.119279,48.17153],[-119.114067,48.145278],[-119.013551,48.06335]]]},"properties":{"id":"99155","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.649747,46.591629],[-118.922036,46.502752],[-119.227012,46.500546],[-119.263394,46.476291],[-119.261728,46.351357],[-119.212805,46.241229],[-119.09751,46.215049],[-119.041418,46.192856],[-118.793513,46.29057],[-118.537174,46.561583],[-118.649747,46.591629]]]},"properties":{"id":"99301","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.977967,47.553366],[-119.054957,47.864815],[-119.23277,47.73862],[-119.149447,47.524221],[-119.001881,47.504128],[-118.977967,47.553366]]]},"properties":{"id":"99135","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.974008,47.906376],[-119.190619,47.902548],[-119.212195,47.83582],[-119.23277,47.73862],[-119.054957,47.864815],[-118.974008,47.906376]]]},"properties":{"id":"99123","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.276155,47.499756],[-119.23392,47.471749],[-119.276846,47.4275],[-119.277142,47.39539],[-119.212853,47.393766],[-119.001881,47.504128],[-119.149447,47.524221],[-119.27683,47.507877],[-119.276155,47.499756]]]},"properties":{"id":"98860","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.493218,46.999612],[-123.481645,46.999332],[-123.481618,47.002678],[-123.494951,47.001256],[-123.493218,46.999612]]]},"properties":{"id":"98583","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.799426,47.517847],[-124.011106,47.358942],[-123.967306,47.369203],[-123.799426,47.517847]]]},"properties":{"id":"98526","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.934631,46.705522],[-123.915181,46.792641],[-124.070779,46.861828],[-124.120588,46.858626],[-124.086886,46.717195],[-124.020413,46.690231],[-123.935921,46.705364],[-123.934631,46.705522]]]},"properties":{"id":"98547","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.26569,47.09914],[-123.200041,47.014792],[-123.200957,47.056622],[-123.202066,47.084895],[-123.26569,47.09914]]]},"properties":{"id":"98557","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.158716,47.065729],[-124.096214,47.136761],[-124.195824,47.172853],[-124.20009,47.172846],[-124.180988,47.093003],[-124.158716,47.065729]]]},"properties":{"id":"98535","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.399833,47.512442],[-122.381858,47.513751],[-122.381464,47.557458],[-122.409757,47.560272],[-122.399833,47.512442]]]},"properties":{"id":"98136","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.307993,47.422758],[-122.284362,47.422778],[-122.24143,47.441201],[-122.250906,47.474913],[-122.251569,47.474808],[-122.312384,47.470094],[-122.312787,47.465676],[-122.305043,47.434605],[-122.307993,47.422758]]]},"properties":{"id":"98188","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.414259,47.675691],[-122.357441,47.675997],[-122.358076,47.701472],[-122.398596,47.703899],[-122.419548,47.678899],[-122.414259,47.675691]]]},"properties":{"id":"98117","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.292075,47.570186],[-122.262134,47.575921],[-122.267635,47.601521],[-122.316967,47.600851],[-122.319253,47.595823],[-122.320091,47.572433],[-122.292075,47.570186]]]},"properties":{"id":"98144","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.263535,47.62432],[-122.315606,47.618776],[-122.327855,47.618495],[-122.323115,47.612157],[-122.316967,47.600851],[-122.267635,47.601521],[-122.265735,47.61362],[-122.263535,47.62432]]]},"properties":{"id":"98122","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.270957,47.777019],[-122.269937,47.73412],[-122.245212,47.731771],[-122.227282,47.776637],[-122.267046,47.777019],[-122.270957,47.777019]]]},"properties":{"id":"98028","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.231835,47.69012],[-122.164425,47.704132],[-122.151867,47.718612],[-122.175385,47.736695],[-122.245212,47.731771],[-122.269937,47.73412],[-122.269808,47.733477],[-122.254136,47.69782],[-122.231835,47.69012]]]},"properties":{"id":"98034","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.121361,47.574139],[-122.133977,47.578308],[-122.151067,47.579296],[-122.18762,47.579527],[-122.199091,47.574044],[-122.202007,47.546025],[-122.16556,47.540015],[-122.108789,47.540555],[-122.121361,47.574139]]]},"properties":{"id":"98006","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.971748,47.558397],[-122.055651,47.56578],[-122.098001,47.587777],[-122.121361,47.574139],[-122.108789,47.540555],[-122.058277,47.466114],[-121.884511,47.504578],[-121.908721,47.529516],[-121.918005,47.543386],[-121.956867,47.533327],[-121.959087,47.558306],[-121.971748,47.558397]]]},"properties":{"id":"98027","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.003794,47.705216],[-121.791637,47.693596],[-121.71538,47.688926],[-121.666876,47.776921],[-121.849653,47.777223],[-122.00774,47.775323],[-122.014039,47.714138],[-122.003794,47.705216]]]},"properties":{"id":"98019","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.304599,47.647402],[-122.312412,47.647196],[-122.310437,47.64712],[-122.304599,47.647402]]]},"properties":{"id":"98195","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.682825,47.527548],[-122.497575,47.503032],[-122.543918,47.585847],[-122.570447,47.587866],[-122.607123,47.561773],[-122.618485,47.553081],[-122.649854,47.546772],[-122.682825,47.527548]]]},"properties":{"id":"98366","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.521871,47.914507],[-122.588094,47.925225],[-122.58144,47.880676],[-122.521871,47.914507]]]},"properties":{"id":"98340","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.063179,45.825074],[-120.942464,45.667703],[-120.885674,45.650038],[-120.553307,45.733664],[-120.431582,45.957151],[-120.490127,45.990245],[-121.125926,45.986993],[-121.092343,45.914016],[-121.063179,45.825074]]]},"properties":{"id":"98620","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.331634,45.811947],[-121.351267,45.704235],[-121.199886,45.647929],[-121.11009,45.657476],[-121.051617,45.670811],[-121.063179,45.825074],[-121.175312,45.821571],[-121.331634,45.811947]]]},"properties":{"id":"98635","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.109096,45.898036],[-121.196297,45.899483],[-121.175319,45.823026],[-121.109096,45.898036]]]},"properties":{"id":"98670","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.608583,45.84264],[-121.453458,45.916821],[-121.425512,46.044525],[-121.608576,45.85822],[-121.608583,45.84264]]]},"properties":{"id":"98650","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.850792,46.729178],[-121.732755,46.614802],[-121.731436,46.388101],[-121.423242,46.492743],[-121.412449,46.578643],[-121.456356,46.783744],[-121.850792,46.729178]]]},"properties":{"id":"98361","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.068042,46.519472],[-123.241568,46.446153],[-123.138897,46.386881],[-123.046811,46.387992],[-123.052443,46.431191],[-123.068042,46.519472]]]},"properties":{"id":"98538","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.175499,48.397413],[-117.502145,48.209101],[-117.502157,48.200408],[-117.502166,48.194706],[-117.405461,48.075249],[-117.107567,47.958853],[-117.042313,47.958364],[-117.035335,48.438225],[-117.175499,48.397413]]]},"properties":{"id":"99156","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.505269,47.242901],[-122.50524,47.213799],[-122.505233,47.194336],[-122.463794,47.184692],[-122.462573,47.214728],[-122.440946,47.231413],[-122.443634,47.235987],[-122.505196,47.243632],[-122.505269,47.242901]]]},"properties":{"id":"98409","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.463794,47.184692],[-122.467953,47.154169],[-122.469227,47.132325],[-122.434803,47.116016],[-122.431405,47.18094],[-122.463794,47.184692]],[[-122.442466,47.143353],[-122.44776,47.144119],[-122.447713,47.145725],[-122.444038,47.145738],[-122.442466,47.143353]]]},"properties":{"id":"98444","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.431405,47.18094],[-122.38914,47.180874],[-122.3921,47.23613],[-122.399685,47.24097],[-122.431212,47.233391],[-122.431312,47.2152],[-122.431405,47.18094]]]},"properties":{"id":"98404","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.226241,47.140316],[-122.123196,47.138452],[-122.115651,47.220837],[-122.114421,47.228242],[-122.218945,47.238198],[-122.215443,47.169722],[-122.226241,47.140316]]]},"properties":{"id":"98391","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.434803,47.116016],[-122.388704,47.082129],[-122.38914,47.180874],[-122.431405,47.18094],[-122.434803,47.116016]]]},"properties":{"id":"98445","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.3921,47.23613],[-122.38914,47.180874],[-122.357194,47.180901],[-122.341697,47.213934],[-122.3921,47.23613]]]},"properties":{"id":"98443","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.399891,46.962197],[-122.421362,46.84972],[-122.202721,46.762628],[-122.192152,46.762315],[-122.162829,46.796754],[-121.917349,46.858091],[-122.103565,46.963673],[-122.235923,46.953981],[-122.399891,46.962197]]]},"properties":{"id":"98328","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.484106,47.26459],[-122.484122,47.259939],[-122.477871,47.259904],[-122.479444,47.265463],[-122.484106,47.26459]]]},"properties":{"id":"98416","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.806499,47.34348],[-122.763259,47.344052],[-122.80205,47.358817],[-122.804462,47.353482],[-122.806499,47.34348]]]},"properties":{"id":"98394","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.957997,48.631863],[-122.876291,48.584741],[-122.842426,48.585443],[-122.789494,48.681359],[-123.001612,48.667053],[-122.957997,48.631863]]]},"properties":{"id":"98245","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.191973,48.428043],[-122.303439,48.297596],[-122.203917,48.297325],[-121.935609,48.297696],[-121.965416,48.399953],[-122.191973,48.428043]]]},"properties":{"id":"98274","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.234102,48.46073],[-122.232512,48.460996],[-122.234392,48.464186],[-122.234102,48.46073]]]},"properties":{"id":"98235","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.239273,47.877815],[-122.283895,47.888379],[-122.293235,47.875086],[-122.296201,47.847313],[-122.235879,47.849433],[-122.227958,47.863769],[-122.239273,47.877815]]]},"properties":{"id":"98087","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.327304,47.777652],[-122.28704,47.777242],[-122.330141,47.799627],[-122.327304,47.777652]]]},"properties":{"id":"98043","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.283895,47.888379],[-122.239273,47.877815],[-122.233727,47.923414],[-122.260699,47.922499],[-122.283895,47.888379]]]},"properties":{"id":"98204","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.296201,47.847313],[-122.332419,47.830276],[-122.243201,47.828961],[-122.235879,47.849433],[-122.296201,47.847313]]]},"properties":{"id":"98037","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.305306,47.31799],[-117.45639,47.368771],[-117.572984,47.259786],[-117.461078,47.149893],[-117.364588,47.137625],[-117.20443,47.25957],[-117.268422,47.289019],[-117.305306,47.31799]],[[-117.469709,47.202175],[-117.478337,47.236337],[-117.451596,47.227285],[-117.469709,47.202175]]]},"properties":{"id":"99170","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.452447,47.680245],[-117.443891,47.649596],[-117.411252,47.652553],[-117.411138,47.671776],[-117.412774,47.672802],[-117.452447,47.680245]]]},"properties":{"id":"99201","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039872,47.181761],[-117.039896,47.310542],[-117.17211,47.25958],[-117.17172,47.18195],[-117.039872,47.181761]]]},"properties":{"id":"99033","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.736081,48.061737],[-117.607149,48.169473],[-117.929379,48.108745],[-117.736081,48.061737]]]},"properties":{"id":"99181","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.736081,48.061737],[-117.929379,48.108745],[-117.985217,48.13043],[-118.021398,48.090737],[-118.080416,48.002031],[-117.922912,48.002405],[-117.766486,47.943198],[-117.714636,47.934244],[-117.649207,47.961281],[-117.62907,48.019136],[-117.736081,48.061737]]]},"properties":{"id":"99173","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.714636,47.934244],[-117.766486,47.943198],[-117.699981,47.86061],[-117.70861,47.874843],[-117.714636,47.934244]]]},"properties":{"id":"99034","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.909525,47.059456],[-122.839174,47.045365],[-122.833724,47.045817],[-122.836557,47.187627],[-122.905659,47.155614],[-122.909525,47.059456]]]},"properties":{"id":"98506","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.52987,46.31333],[-123.552338,46.258619],[-123.436394,46.238197],[-123.340114,46.291982],[-123.460297,46.383693],[-123.52987,46.31333]]]},"properties":{"id":"98647","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.442577,48.869841],[-122.330978,48.98198],[-122.339083,49.002449],[-122.550712,49.002445],[-122.552061,48.964768],[-122.574382,48.93532],[-122.510194,48.887869],[-122.442577,48.869841]]]},"properties":{"id":"98264","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.03981,46.82594],[-117.039601,46.961092],[-117.27059,46.91275],[-117.18108,46.83458],[-117.03981,46.82594]]]},"properties":{"id":"99161","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.914286,47.088181],[-117.504098,46.952904],[-117.46712,47.00978],[-117.461078,47.149893],[-117.572984,47.259786],[-117.728111,47.222827],[-117.914286,47.088181]]]},"properties":{"id":"99171","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.2682,47.02792],[-117.27059,46.91275],[-117.039601,46.961092],[-117.039886,47.019595],[-117.184585,47.064647],[-117.2682,47.02792]]]},"properties":{"id":"99130","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.00021,46.6245],[-117.91966,46.65865],[-117.957494,46.691142],[-118.01569,46.68212],[-118.00021,46.6245]]]},"properties":{"id":"99136","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.887776,46.616724],[-121.412449,46.578643],[-121.423242,46.492743],[-120.921741,46.492821],[-120.667731,46.498646],[-120.473071,46.531592],[-120.470213,46.557148],[-120.497432,46.577848],[-120.562046,46.574662],[-120.887776,46.616724]]]},"properties":{"id":"98903","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.502106,46.300676],[-120.366946,46.105392],[-120.126102,46.255034],[-120.222225,46.370509],[-120.350359,46.432961],[-120.544042,46.368075],[-120.565274,46.356939],[-120.502106,46.300676]]]},"properties":{"id":"98948","state_code":"53"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.961025,39.268944],[-79.826658,39.195154],[-79.802563,39.233045],[-79.785372,39.269703],[-79.850883,39.256796],[-79.940175,39.29722],[-79.961025,39.268944]]]},"properties":{"id":"26405","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.059658,39.407137],[-78.066682,39.454589],[-78.17138,39.353],[-78.123808,39.322677],[-78.085053,39.350641],[-78.059658,39.407137]]]},"properties":{"id":"25420","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.91944,39.549145],[-77.924045,39.530364],[-77.91157,39.545865],[-77.91944,39.549145]]],[[[-77.91157,39.545865],[-77.883115,39.550247],[-77.936431,39.594261],[-77.91157,39.545865]]]]},"properties":{"id":"25419","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.709669,38.774889],[-80.682053,38.763836],[-80.604114,38.788213],[-80.609386,38.824221],[-80.676484,38.925307],[-80.738411,38.925309],[-80.717441,38.869862],[-80.709669,38.774889]]]},"properties":{"id":"26335","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.005493,38.69594],[-80.948935,38.689587],[-80.902548,38.763773],[-80.872963,38.780204],[-80.973407,38.784628],[-81.022205,38.729972],[-81.022128,38.713481],[-81.005493,38.69594]]]},"properties":{"id":"26636","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.610256,40.358546],[-80.571758,40.309971],[-80.519043,40.289845],[-80.518022,40.377056],[-80.611694,40.376556],[-80.610256,40.358546]]]},"properties":{"id":"26035","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519104,40.159672],[-80.51911,40.244436],[-80.582223,40.173867],[-80.532221,40.162671],[-80.519104,40.159672]]]},"properties":{"id":"26032","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.584322,40.171228],[-80.532221,40.162671],[-80.582223,40.173867],[-80.584322,40.171228]]],[[[-80.681811,40.185563],[-80.733803,40.033409],[-80.685688,39.993316],[-80.699623,39.961793],[-80.599093,39.958519],[-80.591231,39.962541],[-80.531233,40.017484],[-80.584944,40.130209],[-80.585745,40.146048],[-80.585222,40.174595],[-80.635657,40.201942],[-80.681811,40.185563]]]]},"properties":{"id":"26003","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.451791,38.426107],[-82.454342,38.335927],[-82.344172,38.305352],[-82.343866,38.305286],[-82.333591,38.382283],[-82.419971,38.413803],[-82.425075,38.432883],[-82.451791,38.426107]]]},"properties":{"id":"25701","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.150991,38.553713],[-81.211565,38.580983],[-81.210986,38.582263],[-81.276973,38.539071],[-81.233363,38.503922],[-81.195199,38.432597],[-81.172645,38.436485],[-81.173771,38.468449],[-81.13386,38.507103],[-81.150991,38.553713]]]},"properties":{"id":"25164","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.176381,37.98615],[-81.122926,37.981507],[-81.108238,37.988033],[-81.08467,37.974882],[-81.083472,37.963689],[-81.027494,37.959737],[-81.021383,38.00055],[-81.037119,38.049932],[-81.055541,38.062187],[-81.06432,38.071416],[-81.081007,38.069763],[-81.119448,38.117355],[-81.150143,38.133438],[-81.190094,38.094808],[-81.231898,38.050867],[-81.261696,38.043655],[-81.239357,38.017852],[-81.201347,38.006225],[-81.176381,37.98615]]]},"properties":{"id":"25840","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.201347,38.006225],[-81.239357,38.017852],[-81.236897,38.015438],[-81.201347,38.006225]]],[[[-81.176381,37.98615],[-81.158685,37.942867],[-81.156277,37.928983],[-81.119016,37.879132],[-81.073249,37.875865],[-81.083472,37.963689],[-81.08467,37.974882],[-81.122926,37.981507],[-81.176381,37.98615]]]]},"properties":{"id":"25901","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.37021,38.157641],[-81.337845,38.140878],[-81.296024,38.153119],[-81.295361,38.153918],[-81.29694,38.156704],[-81.314961,38.180529],[-81.317303,38.181418],[-81.354785,38.185708],[-81.357326,38.175989],[-81.37021,38.157641]]]},"properties":{"id":"25136","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.274235,38.130864],[-81.265254,38.118816],[-81.237814,38.121281],[-81.23759,38.122619],[-81.263396,38.130217],[-81.274235,38.130864]]]},"properties":{"id":"25057","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.157828,37.929923],[-81.156277,37.928983],[-81.158685,37.942867],[-81.157828,37.929923]]]},"properties":{"id":"25855","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.07819,39.385846],[-79.132033,39.417834],[-79.271695,39.327999],[-79.154569,39.283863],[-79.07819,39.385846]]]},"properties":{"id":"26717","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.154569,39.283863],[-79.168922,39.193025],[-79.102228,39.19422],[-78.999115,39.358879],[-79.07819,39.385846],[-79.154569,39.283863]]]},"properties":{"id":"26743","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.426021,37.777204],[-80.544638,37.755107],[-80.555086,37.702801],[-80.456457,37.68493],[-80.431259,37.694624],[-80.426021,37.777204]]]},"properties":{"id":"24970","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.536097,38.083271],[-80.449758,37.948894],[-80.417999,37.939047],[-80.324236,37.897036],[-80.334499,37.891368],[-80.18798,38.039194],[-80.360048,38.225845],[-80.436514,38.267334],[-80.536253,38.091682],[-80.536097,38.083271]]]},"properties":{"id":"24966","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.72525,39.216768],[-78.648057,39.33854],[-78.703782,39.307401],[-78.72525,39.216768]]]},"properties":{"id":"26761","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.753345,39.525499],[-78.790949,39.413085],[-78.621013,39.43055],[-78.610194,39.440044],[-78.58066,39.490067],[-78.657073,39.535141],[-78.711797,39.559847],[-78.753345,39.525499]]]},"properties":{"id":"26763","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.415906,39.301915],[-78.407823,39.270454],[-78.348243,39.336833],[-78.415906,39.301915]]],[[[-78.499864,39.335049],[-78.580818,39.199819],[-78.620625,39.158741],[-78.57955,39.161039],[-78.457398,39.227721],[-78.415587,39.255608],[-78.416495,39.261336],[-78.42464,39.298489],[-78.417491,39.402991],[-78.492652,39.363966],[-78.499864,39.335049]]]]},"properties":{"id":"26711","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.992043,39.220982],[-78.883007,39.194176],[-78.882618,39.194061],[-78.858058,39.313249],[-78.992043,39.220982]]]},"properties":{"id":"26852","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.531215,39.163345],[-78.487483,39.162954],[-78.457398,39.227721],[-78.57955,39.161039],[-78.531215,39.163345]]]},"properties":{"id":"26865","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.415906,39.301915],[-78.348243,39.336833],[-78.35229,39.39981],[-78.417491,39.402991],[-78.42464,39.298489],[-78.415906,39.301915]]]},"properties":{"id":"26817","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.363535,39.627079],[-80.4493,39.597633],[-80.482273,39.588346],[-80.472688,39.485738],[-80.451581,39.450787],[-80.399718,39.431453],[-80.313269,39.437627],[-80.311006,39.477604],[-80.31404,39.482416],[-80.306332,39.51281],[-80.292554,39.524797],[-80.28557,39.505803],[-80.26659,39.547457],[-80.30865,39.611528],[-80.363535,39.627079]]]},"properties":{"id":"26582","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.747254,39.095379],[-81.840321,38.934109],[-81.804194,38.894395],[-81.770828,38.862672],[-81.719327,38.88289],[-81.598446,39.010692],[-81.597059,39.0326],[-81.658091,39.06734],[-81.747254,39.095379]]]},"properties":{"id":"26164","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.630938,38.828758],[-81.568177,38.793682],[-81.521832,38.822148],[-81.473231,38.927261],[-81.532992,39.003468],[-81.630938,38.828758]]]},"properties":{"id":"25252","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.568177,38.793682],[-81.587233,38.717592],[-81.536239,38.713435],[-81.48545,38.758277],[-81.481707,38.79505],[-81.521832,38.822148],[-81.568177,38.793682]]]},"properties":{"id":"25244","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.522166,38.612746],[-81.706578,38.618011],[-81.751262,38.587351],[-81.552969,38.47836],[-81.508412,38.584525],[-81.521914,38.612251],[-81.522166,38.612746]]]},"properties":{"id":"25320","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.812345,38.310607],[-81.696916,38.264927],[-81.687417,38.276999],[-81.699599,38.34832],[-81.71847,38.361892],[-81.751235,38.356661],[-81.809148,38.336808],[-81.812345,38.310607]]]},"properties":{"id":"25309","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.855295,38.24532],[-81.833923,38.216453],[-81.83347,38.20957],[-81.807502,38.180065],[-81.800907,38.208861],[-81.695914,38.225149],[-81.696916,38.264927],[-81.812345,38.310607],[-81.862792,38.27123],[-81.855295,38.24532]]]},"properties":{"id":"25003","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.392358,38.210488],[-81.377855,38.208638],[-81.378208,38.209165],[-81.392358,38.210488]]]},"properties":{"id":"25162","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991535,38.275424],[-82.058503,38.220777],[-82.033206,38.158446],[-81.94324,38.182148],[-81.935452,38.25391],[-81.947033,38.30237],[-81.991535,38.275424]]]},"properties":{"id":"25521","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.926829,37.901748],[-81.877682,37.86104],[-81.856231,37.865964],[-81.880768,37.919177],[-81.848214,37.947997],[-81.901845,37.961202],[-81.934487,37.947413],[-81.932724,37.906109],[-81.926829,37.901748]]]},"properties":{"id":"25121","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.04575,37.871095],[-82.047242,37.858482],[-82.047064,37.83525],[-82.039549,37.833977],[-82.030464,37.835524],[-82.04575,37.871095]]]},"properties":{"id":"25637","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.88522,37.778894],[-81.865541,37.751215],[-81.863036,37.777611],[-81.88522,37.778894]]]},"properties":{"id":"25628","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.663929,37.274007],[-81.617135,37.240084],[-81.602517,37.287315],[-81.6051,37.301483],[-81.62033,37.339513],[-81.628197,37.351056],[-81.667525,37.281691],[-81.663929,37.274007]]]},"properties":{"id":"24826","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.927681,37.512113],[-81.954248,37.446477],[-81.913066,37.505094],[-81.927681,37.512113]]]},"properties":{"id":"24862","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.31404,39.482416],[-80.304606,39.477894],[-80.282952,39.484027],[-80.261528,39.474645],[-80.239581,39.492085],[-80.207378,39.548032],[-80.26659,39.547457],[-80.28557,39.505803],[-80.306332,39.51281],[-80.31404,39.482416]],[[-80.262391,39.499736],[-80.246646,39.496338],[-80.259327,39.481799],[-80.267927,39.48695],[-80.262391,39.499736]]]},"properties":{"id":"26571","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.282952,39.484027],[-80.279745,39.478751],[-80.261528,39.474645],[-80.282952,39.484027]]]},"properties":{"id":"26563","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.858185,39.695497],[-80.682668,39.721306],[-80.705027,39.826927],[-80.812489,39.854309],[-80.858185,39.695497]]]},"properties":{"id":"26055","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.016352,38.848821],[-82.050937,38.921911],[-82.113547,38.930256],[-82.147495,38.837495],[-82.03424,38.793604],[-82.016352,38.848821]]]},"properties":{"id":"25550","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.078082,38.579642],[-82.126304,38.63806],[-82.1844,38.657629],[-82.174191,38.611059],[-82.078082,38.579642]]]},"properties":{"id":"25503","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.147667,37.405951],[-81.165239,37.402946],[-81.164579,37.389936],[-81.147667,37.405951]]]},"properties":{"id":"24731","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.325337,37.304206],[-81.307631,37.290878],[-81.315873,37.312423],[-81.325337,37.304206]]]},"properties":{"id":"24738","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.244749,37.456751],[-81.27282,37.468742],[-81.269883,37.431677],[-81.236132,37.436708],[-81.244749,37.456751]]]},"properties":{"id":"24729","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.253717,37.353919],[-81.252021,37.351187],[-81.245643,37.351351],[-81.247408,37.354977],[-81.253717,37.353919]]]},"properties":{"id":"24737","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.377282,37.802878],[-82.175927,37.644639],[-82.173871,37.643651],[-82.205399,37.797995],[-82.300758,37.840722],[-82.377282,37.802878]]]},"properties":{"id":"25661","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.999077,37.649915],[-82.070673,37.702042],[-82.102011,37.769615],[-82.102727,37.793455],[-82.122636,37.804615],[-82.205399,37.797995],[-82.173871,37.643651],[-82.171678,37.643178],[-82.073844,37.624684],[-81.994354,37.632328],[-81.999024,37.649593],[-81.999077,37.649915]],[[-82.111575,37.697511],[-82.094865,37.684676],[-82.114112,37.670023],[-82.135015,37.680239],[-82.130385,37.704847],[-82.111575,37.697511]]]},"properties":{"id":"25670","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.434056,37.893907],[-82.377282,37.802878],[-82.300758,37.840722],[-82.263471,37.857309],[-82.303098,37.903318],[-82.325067,37.928192],[-82.346715,37.921175],[-82.434056,37.893907]]]},"properties":{"id":"25674","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.994075,37.632448],[-81.916969,37.604904],[-81.855739,37.56151],[-81.8297,37.59217],[-81.829267,37.595276],[-81.841758,37.593128],[-81.834664,37.611379],[-81.828222,37.597989],[-81.808279,37.609254],[-81.91673,37.680503],[-81.953532,37.675126],[-81.999024,37.649593],[-81.994354,37.632328],[-81.994075,37.632448]]]},"properties":{"id":"25621","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.348504,39.721366],[-80.36673,39.631778],[-80.363535,39.627079],[-80.30865,39.611528],[-80.257565,39.703125],[-80.241307,39.721118],[-80.348504,39.721366]]]},"properties":{"id":"26590","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.456457,37.68493],[-80.468938,37.623867],[-80.437983,37.653507],[-80.431259,37.694624],[-80.456457,37.68493]]]},"properties":{"id":"24974","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.819025,37.490174],[-80.843724,37.512177],[-80.85868,37.428908],[-80.631855,37.432233],[-80.710043,37.458316],[-80.819025,37.490174]]]},"properties":{"id":"24963","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.944073,38.193702],[-80.908878,38.130776],[-80.870222,38.157],[-80.900515,38.200719],[-80.944073,38.193702]]]},"properties":{"id":"26678","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.71368,38.377482],[-80.698272,38.337442],[-80.680863,38.34015],[-80.71368,38.377482]]]},"properties":{"id":"26660","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.584944,40.130209],[-80.519006,40.03632],[-80.519104,40.159672],[-80.532221,40.162671],[-80.584322,40.171228],[-80.585745,40.146048],[-80.584944,40.130209]]]},"properties":{"id":"26060","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.031463,38.394401],[-80.064326,38.417673],[-80.222397,38.331136],[-80.149725,38.142092],[-80.160456,38.037974],[-79.959844,38.063697],[-79.802942,38.292748],[-80.003567,38.283513],[-80.031463,38.394401]]]},"properties":{"id":"24954","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.031463,38.394401],[-79.953062,38.412208],[-79.930683,38.513429],[-80.028576,38.45917],[-80.064326,38.417673],[-80.031463,38.394401]]]},"properties":{"id":"26209","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.842644,38.437907],[-79.719394,38.397156],[-79.698988,38.474988],[-79.773654,38.532282],[-79.871589,38.453354],[-79.842644,38.437907]]]},"properties":{"id":"24915","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.719394,38.397156],[-79.842644,38.437907],[-79.867167,38.403699],[-79.796162,38.323734],[-79.719394,38.397156]]]},"properties":{"id":"24944","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.785372,39.269703],[-79.687662,39.426817],[-79.791378,39.452087],[-79.855753,39.338456],[-79.850883,39.256796],[-79.785372,39.269703]]]},"properties":{"id":"26444","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.912561,38.525867],[-81.871767,38.538198],[-81.840511,38.516881],[-81.840164,38.516811],[-81.799688,38.584948],[-81.816398,38.597934],[-81.834418,38.599978],[-81.975297,38.562664],[-81.957106,38.545827],[-81.915596,38.556923],[-81.912561,38.525867]]]},"properties":{"id":"25168","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.287851,37.709037],[-81.383895,37.687488],[-81.365994,37.661684],[-81.287851,37.709037]]]},"properties":{"id":"25920","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.368808,37.933689],[-81.300467,37.929851],[-81.295328,37.939697],[-81.299428,37.94561],[-81.37283,37.971309],[-81.368808,37.933689]]]},"properties":{"id":"25008","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.745167,38.814432],[-79.83202,38.688848],[-79.810183,38.680212],[-79.72619,38.678983],[-79.655456,38.687473],[-79.64066,38.716558],[-79.667475,38.792121],[-79.728281,38.854703],[-79.745167,38.814432]]]},"properties":{"id":"26268","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.728281,38.854703],[-79.667475,38.792121],[-79.561757,38.987132],[-79.642222,39.005211],[-79.728281,38.854703]]]},"properties":{"id":"26254","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.911739,37.61269],[-80.971577,37.653431],[-80.96988,37.583389],[-80.917673,37.611406],[-80.911739,37.61269]]]},"properties":{"id":"25978","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.96988,37.583389],[-80.971577,37.653431],[-80.979526,37.708688],[-81.006322,37.676891],[-81.037862,37.598318],[-81.018043,37.536783],[-81.017854,37.536655],[-80.979003,37.570179],[-80.96988,37.583389]]]},"properties":{"id":"25969","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.508339,38.974979],[-79.357666,38.964509],[-79.344926,38.967738],[-79.295506,39.188904],[-79.352009,39.208206],[-79.447573,39.184207],[-79.48571,39.139134],[-79.532028,39.105087],[-79.537156,39.099448],[-79.544488,38.983189],[-79.508339,38.974979]]]},"properties":{"id":"26260","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.641187,39.078095],[-79.642222,39.005211],[-79.561757,38.987132],[-79.544488,38.983189],[-79.537156,39.099448],[-79.641187,39.078095]]]},"properties":{"id":"26271","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.982361,39.488482],[-81.013904,39.399398],[-81.009035,39.360437],[-80.908292,39.390939],[-80.735608,39.491132],[-80.736117,39.499811],[-80.764232,39.52495],[-80.885267,39.536041],[-80.982361,39.488482]]]},"properties":{"id":"26149","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.389503,38.862876],[-80.399935,38.813817],[-80.407273,38.778768],[-80.364106,38.777999],[-80.367246,38.733013],[-80.345874,38.722192],[-80.280059,38.694867],[-80.236526,38.743769],[-80.231861,38.75811],[-80.389503,38.862876]]]},"properties":{"id":"26234","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.231861,38.75811],[-80.236526,38.743769],[-80.235959,38.743776],[-80.202466,38.7445],[-80.231861,38.75811]]]},"properties":{"id":"26236","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.274507,37.972778],[-82.202,38.032972],[-82.388132,38.14071],[-82.465985,38.025549],[-82.4212,37.941045],[-82.355246,37.943957],[-82.274507,37.972778]]]},"properties":{"id":"25511","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.471009,37.98652],[-82.434056,37.893907],[-82.346715,37.921175],[-82.355246,37.943957],[-82.4212,37.941045],[-82.471009,37.98652]]]},"properties":{"id":"25669","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.492099,38.170786],[-82.465985,38.025549],[-82.388132,38.14071],[-82.398496,38.170051],[-82.492099,38.170786]]]},"properties":{"id":"25517","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.494365,38.275241],[-82.507458,38.266381],[-82.501658,38.215427],[-82.492099,38.170786],[-82.398496,38.170051],[-82.329715,38.298234],[-82.343866,38.305286],[-82.344172,38.305352],[-82.494365,38.275241]]]},"properties":{"id":"25570","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.201464,38.486162],[-80.255256,38.496523],[-80.286948,38.465043],[-80.21468,38.456616],[-80.201464,38.486162]]]},"properties":{"id":"26298","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.162455,39.030608],[-81.21552,39.04348],[-81.206281,39.005753],[-81.179209,39.016228],[-81.162455,39.030608]]]},"properties":{"id":"26138","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.465503,39.16285],[-81.301652,39.126955],[-81.268014,39.254563],[-81.40966,39.272852],[-81.425154,39.241471],[-81.442105,39.182515],[-81.465503,39.16285]]]},"properties":{"id":"26180","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.563151,37.641145],[-81.549559,37.621789],[-81.504469,37.66647],[-81.513131,37.671638],[-81.563151,37.641145]]]},"properties":{"id":"24880","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.8297,37.59217],[-81.855739,37.56151],[-81.855939,37.54891],[-81.829916,37.535649],[-81.8297,37.59217]]]},"properties":{"id":"24869","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.421715,37.490251],[-81.342545,37.487861],[-81.332977,37.495593],[-81.315448,37.481389],[-81.246407,37.501941],[-81.222121,37.510416],[-81.277476,37.540625],[-81.326102,37.536218],[-81.388536,37.541983],[-81.407285,37.56208],[-81.434698,37.580791],[-81.461398,37.571765],[-81.464092,37.539185],[-81.421715,37.490251]]]},"properties":{"id":"24726","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.911791,39.127306],[-80.047425,39.061744],[-80.072283,38.968564],[-80.03965,38.953199],[-79.961594,38.939141],[-79.940601,38.939375],[-79.887869,39.000154],[-79.911791,39.127306]],[[-79.982857,38.961428],[-79.967599,38.975749],[-79.919991,38.988653],[-79.932183,38.968146],[-79.982857,38.961428]]]},"properties":{"id":"26250","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.998077,39.429594],[-77.962954,39.367357],[-77.885254,39.41694],[-77.946163,39.452116],[-77.98942,39.443345],[-77.998077,39.429594]]]},"properties":{"id":"25405","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.085053,39.350641],[-77.988462,39.319777],[-77.962954,39.367357],[-77.998077,39.429594],[-78.059658,39.407137],[-78.085053,39.350641]]]},"properties":{"id":"25428","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.888565,38.165842],[-81.871554,38.113753],[-81.839007,38.099779],[-81.757583,38.109332],[-81.807502,38.180065],[-81.83347,38.20957],[-81.858842,38.189824],[-81.890528,38.167156],[-81.888565,38.165842]]]},"properties":{"id":"25529","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.911016,37.988174],[-81.901845,37.961202],[-81.848214,37.947997],[-81.831799,37.94761],[-81.849373,37.979505],[-81.911016,37.988174]]]},"properties":{"id":"25108","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.712073,38.167381],[-81.70914,38.148508],[-81.706652,38.158539],[-81.712073,38.167381]]]},"properties":{"id":"25051","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.902548,38.763773],[-80.79306,38.624302],[-80.724949,38.725949],[-80.812061,38.814793],[-80.829993,38.804324],[-80.872963,38.780204],[-80.902548,38.763773]]]},"properties":{"id":"26624","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.948935,38.689587],[-80.799022,38.574949],[-80.79306,38.624302],[-80.902548,38.763773],[-80.948935,38.689587]]]},"properties":{"id":"26623","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.502913,38.655966],[-80.663593,38.721642],[-80.724949,38.725949],[-80.79306,38.624302],[-80.799022,38.574949],[-80.785295,38.557587],[-80.72213,38.533982],[-80.704484,38.578346],[-80.673261,38.54865],[-80.685665,38.532096],[-80.650786,38.52659],[-80.568791,38.595352],[-80.523619,38.633098],[-80.502913,38.655966]]]},"properties":{"id":"26601","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.685665,38.532096],[-80.673261,38.54865],[-80.704484,38.578346],[-80.72213,38.533982],[-80.685665,38.532096]]]},"properties":{"id":"26629","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.676484,38.925307],[-80.609386,38.824221],[-80.497023,38.845441],[-80.547185,38.957876],[-80.655033,38.950435],[-80.676484,38.925307]]]},"properties":{"id":"26412","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.442944,38.72714],[-80.393063,38.727571],[-80.409103,38.770511],[-80.407273,38.778768],[-80.399935,38.813817],[-80.453071,38.825203],[-80.479748,38.815186],[-80.505968,38.741742],[-80.457824,38.738273],[-80.442944,38.72714]]]},"properties":{"id":"26376","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.709669,38.774889],[-80.717441,38.869862],[-80.766431,38.841361],[-80.709669,38.774889]]]},"properties":{"id":"26615","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.419971,38.413803],[-82.333591,38.382283],[-82.29908,38.422292],[-82.397585,38.423445],[-82.419971,38.413803]]]},"properties":{"id":"25705","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.425075,38.432883],[-82.419971,38.413803],[-82.397585,38.423445],[-82.412266,38.436983],[-82.425075,38.432883]]]},"properties":{"id":"25703","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.186487,38.361763],[-81.133637,38.309632],[-81.14392,38.277973],[-81.096176,38.286927],[-81.040789,38.353282],[-81.140906,38.397109],[-81.172645,38.436485],[-81.195199,38.432597],[-81.194364,38.428163],[-81.186487,38.361763]]]},"properties":{"id":"25111","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.99652,38.056316],[-81.037119,38.049932],[-81.021383,38.00055],[-80.953124,38.031759],[-80.99652,38.056316]]]},"properties":{"id":"25942","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.261598,37.932831],[-81.166407,37.913417],[-81.157828,37.929923],[-81.158685,37.942867],[-81.176381,37.98615],[-81.201347,38.006225],[-81.236897,38.015438],[-81.303453,38.015494],[-81.35104,38.088762],[-81.299428,37.94561],[-81.295328,37.939697],[-81.261598,37.932831]]]},"properties":{"id":"25917","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.023824,38.101411],[-81.013444,38.203781],[-81.06511,38.211807],[-81.074603,38.196093],[-81.119448,38.117355],[-81.081007,38.069763],[-81.023824,38.101411]]]},"properties":{"id":"25938","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.263396,38.130217],[-81.23759,38.122619],[-81.215211,38.136621],[-81.217091,38.13708],[-81.274419,38.134719],[-81.273139,38.133338],[-81.263396,38.130217]]]},"properties":{"id":"25040","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.191802,38.228495],[-81.154835,38.172786],[-81.074603,38.196093],[-81.06511,38.211807],[-81.096176,38.286927],[-81.14392,38.277973],[-81.191802,38.228495]]]},"properties":{"id":"26690","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.119016,37.879132],[-81.133324,37.847295],[-81.133279,37.847212],[-81.095395,37.842784],[-81.092643,37.85078],[-81.071035,37.863599],[-81.073249,37.875865],[-81.119016,37.879132]]],[[[-80.935568,37.888655],[-80.980117,37.924683],[-81.067291,37.86987],[-81.041405,37.85386],[-80.935568,37.888655]]]]},"properties":{"id":"25864","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.897754,39.05646],[-80.937226,39.043892],[-80.946762,38.981053],[-80.76475,38.989905],[-80.772601,39.012223],[-80.786653,39.105392],[-80.81297,39.109401],[-80.897754,39.05646]]]},"properties":{"id":"26342","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.357623,39.276287],[-79.486875,39.205896],[-79.487175,39.194906],[-79.447573,39.184207],[-79.352009,39.208206],[-79.333247,39.248828],[-79.357623,39.276287]]]},"properties":{"id":"26707","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.292566,39.298913],[-79.357623,39.276287],[-79.333247,39.248828],[-79.292566,39.298913]]]},"properties":{"id":"26720","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.700045,38.051075],[-80.744041,38.022507],[-80.771739,38.051351],[-80.794168,38.062504],[-80.802122,38.06046],[-80.708089,37.971478],[-80.689733,38.016297],[-80.700045,38.051075]]]},"properties":{"id":"25958","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.755962,37.669912],[-80.725431,37.682814],[-80.709685,37.643136],[-80.688361,37.592113],[-80.586484,37.655292],[-80.555086,37.702801],[-80.544638,37.755107],[-80.535575,37.813661],[-80.619044,37.823285],[-80.655102,37.86897],[-80.704412,37.89457],[-80.707007,37.884582],[-80.731202,37.796045],[-80.779928,37.73565],[-80.755962,37.669912]]]},"properties":{"id":"24910","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.66831,39.133535],[-78.660633,39.203346],[-78.733971,39.205463],[-78.809988,39.173888],[-78.842916,39.126227],[-78.726479,39.067503],[-78.66831,39.133535]]]},"properties":{"id":"26755","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.58066,39.490067],[-78.610194,39.440044],[-78.548626,39.468625],[-78.523342,39.524832],[-78.587606,39.527721],[-78.58066,39.490067]]]},"properties":{"id":"25431","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.354847,39.196241],[-80.404116,39.19897],[-80.416639,39.210665],[-80.447262,39.213124],[-80.534663,39.194821],[-80.300209,39.103857],[-80.227173,39.112792],[-80.224843,39.142696],[-80.224379,39.17135],[-80.354847,39.196241]]]},"properties":{"id":"26385","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439414,39.30617],[-80.456864,39.281261],[-80.427205,39.289306],[-80.439414,39.30617]]]},"properties":{"id":"26422","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.404116,39.19897],[-80.387873,39.209799],[-80.410662,39.215501],[-80.416639,39.210665],[-80.404116,39.19897]]]},"properties":{"id":"26451","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.792129,38.73413],[-81.665646,38.689803],[-81.587233,38.717592],[-81.568177,38.793682],[-81.630938,38.828758],[-81.719327,38.88289],[-81.770828,38.862672],[-81.798402,38.835697],[-81.800536,38.743914],[-81.792129,38.73413]]]},"properties":{"id":"25271","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.809845,38.676074],[-81.816398,38.597934],[-81.799688,38.584948],[-81.755652,38.581769],[-81.751262,38.587351],[-81.706578,38.618011],[-81.70928,38.650071],[-81.809845,38.676074]]]},"properties":{"id":"25124","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.479716,38.195586],[-81.448548,38.137893],[-81.408532,38.135491],[-81.41276,38.163965],[-81.467194,38.191932],[-81.479716,38.195586]]]},"properties":{"id":"25061","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.44903,38.137663],[-81.491504,38.067888],[-81.465314,38.006293],[-81.456632,37.987307],[-81.380829,37.969109],[-81.408532,38.135491],[-81.448548,38.137893],[-81.44903,38.137663]]]},"properties":{"id":"25075","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.809148,38.336808],[-81.898087,38.319289],[-81.862792,38.27123],[-81.812345,38.310607],[-81.809148,38.336808]]]},"properties":{"id":"25202","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.544729,38.137575],[-81.527396,38.212644],[-81.578425,38.193279],[-81.602763,38.173779],[-81.584026,38.149094],[-81.544729,38.137575]]]},"properties":{"id":"25214","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.60059,38.257296],[-81.687417,38.276999],[-81.696916,38.264927],[-81.695914,38.225149],[-81.636616,38.176953],[-81.602763,38.173779],[-81.578425,38.193279],[-81.60059,38.257296]]]},"properties":{"id":"25107","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.855295,38.24532],[-81.925969,38.252142],[-81.915604,38.202527],[-81.890528,38.167156],[-81.858842,38.189824],[-81.833923,38.216453],[-81.855295,38.24532]]]},"properties":{"id":"25567","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.833923,38.216453],[-81.858842,38.189824],[-81.83347,38.20957],[-81.833923,38.216453]]],[[[-81.925969,38.252142],[-81.855295,38.24532],[-81.862792,38.27123],[-81.898087,38.319289],[-81.915197,38.325597],[-81.947033,38.30237],[-81.935452,38.25391],[-81.925969,38.252142]]]]},"properties":{"id":"25564","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.033206,38.158446],[-82.032221,38.134222],[-81.888565,38.165842],[-81.890528,38.167156],[-81.915604,38.202527],[-81.94324,38.182148],[-82.033206,38.158446]]]},"properties":{"id":"25501","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.856231,37.865964],[-81.793593,37.798585],[-81.757639,37.807507],[-81.724069,37.813282],[-81.77317,37.875408],[-81.880768,37.919177],[-81.856231,37.865964]]]},"properties":{"id":"25022","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.757639,37.807507],[-81.773216,37.764475],[-81.748119,37.761988],[-81.607532,37.788709],[-81.724069,37.813282],[-81.757639,37.807507]]]},"properties":{"id":"25630","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.866461,37.7499],[-81.847653,37.706389],[-81.833001,37.706722],[-81.802703,37.74776],[-81.866461,37.7499]]]},"properties":{"id":"25634","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.91673,37.680503],[-81.808279,37.609254],[-81.808278,37.609253],[-81.79413,37.669459],[-81.712249,37.699085],[-81.833001,37.706722],[-81.847653,37.706389],[-81.817751,37.683422],[-81.881272,37.677754],[-81.888111,37.693243],[-81.91673,37.680503]]]},"properties":{"id":"25650","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.6051,37.301483],[-81.498883,37.258429],[-81.476732,37.299393],[-81.478239,37.299761],[-81.524669,37.30262],[-81.497263,37.349856],[-81.502965,37.358281],[-81.519604,37.388463],[-81.566998,37.391912],[-81.62033,37.339513],[-81.6051,37.301483]]]},"properties":{"id":"24836","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.732262,37.294521],[-81.667525,37.281691],[-81.628197,37.351056],[-81.676994,37.372586],[-81.753235,37.343008],[-81.732262,37.294521]]]},"properties":{"id":"24892","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.844968,37.390507],[-81.795125,37.367334],[-81.732645,37.375586],[-81.725801,37.376494],[-81.844968,37.390507]]]},"properties":{"id":"24811","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.702209,37.468683],[-81.706791,37.452931],[-81.706043,37.40688],[-81.663897,37.41316],[-81.651218,37.446119],[-81.700169,37.472627],[-81.702209,37.468683]]]},"properties":{"id":"24881","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.550745,37.212752],[-81.569819,37.212355],[-81.561281,37.206633],[-81.556762,37.207348],[-81.550745,37.212752]]]},"properties":{"id":"24604","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.740153,39.99392],[-80.692274,39.991737],[-80.685688,39.993316],[-80.733803,40.033409],[-80.740153,39.99392]]]},"properties":{"id":"26031","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.055127,38.474547],[-82.045037,38.322358],[-81.991535,38.275424],[-81.947033,38.30237],[-81.915197,38.325597],[-81.897948,38.399695],[-81.923283,38.47872],[-82.012188,38.521546],[-82.057842,38.513492],[-82.055127,38.474547]]]},"properties":{"id":"25526","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.147207,37.554397],[-81.046395,37.503603],[-81.018043,37.536783],[-81.037862,37.598318],[-81.069663,37.605298],[-81.169714,37.586709],[-81.147207,37.554397]]]},"properties":{"id":"25841","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.236132,37.436708],[-81.206902,37.423132],[-81.18095,37.45507],[-81.221142,37.468707],[-81.244749,37.456751],[-81.236132,37.436708]]]},"properties":{"id":"24733","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.111575,37.697511],[-82.130385,37.704847],[-82.135015,37.680239],[-82.111575,37.697511]]]},"properties":{"id":"25690","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.932542,39.627255],[-79.983083,39.644185],[-80.010226,39.65497],[-79.986283,39.670812],[-80.019322,39.721234],[-80.083792,39.72122],[-80.124744,39.605265],[-80.080944,39.550943],[-80.013443,39.554512],[-79.932542,39.627255]]]},"properties":{"id":"26501","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.194719,39.721189],[-80.241307,39.721118],[-80.257565,39.703125],[-80.194719,39.721189]]]},"properties":{"id":"26521","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.986283,39.670812],[-80.010226,39.65497],[-79.993521,39.660605],[-79.986283,39.670812]]]},"properties":{"id":"26543","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.756675,37.600419],[-80.819025,37.490174],[-80.710043,37.458316],[-80.697107,37.490916],[-80.691966,37.588039],[-80.689727,37.589643],[-80.756675,37.600419]]]},"properties":{"id":"24918","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.598573,38.166763],[-80.637301,38.267549],[-80.665353,38.230166],[-80.598573,38.166763]]]},"properties":{"id":"26202","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.74583,38.291642],[-80.693055,38.263909],[-80.783646,38.231451],[-80.788873,38.220055],[-80.71351,38.174009],[-80.665353,38.230166],[-80.637301,38.267549],[-80.648089,38.284537],[-80.714934,38.300953],[-80.74583,38.291642]]]},"properties":{"id":"26681","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.626774,38.664214],[-79.649075,38.591515],[-79.521788,38.534323],[-79.431995,38.6834],[-79.572299,38.711974],[-79.626774,38.664214]]]},"properties":{"id":"26804","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.37126,39.341924],[-81.239477,39.268328],[-81.10067,39.324273],[-81.02831,39.34797],[-81.00886,39.359042],[-81.040776,39.399334],[-81.111552,39.460604],[-81.121533,39.457775],[-81.257144,39.387373],[-81.286195,39.350521],[-81.33016,39.356394],[-81.37126,39.341924]]]},"properties":{"id":"26170","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.33016,39.356394],[-81.286195,39.350521],[-81.257144,39.387373],[-81.33016,39.356394]]]},"properties":{"id":"26134","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.748561,39.594692],[-79.531109,39.569011],[-79.478375,39.588948],[-79.763774,39.720776],[-79.793919,39.617794],[-79.748561,39.594692]]]},"properties":{"id":"26525","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.684595,39.270583],[-79.558335,39.231799],[-79.486473,39.318122],[-79.483998,39.373024],[-79.601285,39.364406],[-79.684595,39.270583]]]},"properties":{"id":"26705","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.012188,38.521546],[-81.923283,38.47872],[-81.842891,38.445255],[-81.826993,38.453194],[-81.842008,38.502045],[-81.846676,38.513835],[-81.871767,38.538198],[-81.912561,38.525867],[-81.957106,38.545827],[-81.975297,38.562664],[-81.997628,38.578399],[-82.012188,38.521546]]]},"properties":{"id":"25213","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.210548,37.774265],[-81.215611,37.768798],[-81.206665,37.768023],[-81.201757,37.772615],[-81.210548,37.774265]]]},"properties":{"id":"25871","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.350898,37.773436],[-81.407457,37.796725],[-81.3717,37.765084],[-81.350898,37.773436]]]},"properties":{"id":"25839","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.421011,37.898],[-81.458003,37.85953],[-81.418271,37.892241],[-81.421011,37.898]]]},"properties":{"id":"25062","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.453325,37.837081],[-81.435082,37.805249],[-81.416395,37.801362],[-81.400796,37.83271],[-81.453325,37.837081]]]},"properties":{"id":"25007","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.220623,37.644798],[-81.188284,37.613621],[-81.142787,37.622841],[-81.218058,37.714867],[-81.239587,37.705806],[-81.234984,37.697823],[-81.227864,37.659433],[-81.220623,37.644798]]]},"properties":{"id":"25823","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.427713,37.749257],[-81.419464,37.737752],[-81.3717,37.765084],[-81.407457,37.796725],[-81.414649,37.798218],[-81.45567,37.779994],[-81.462889,37.785708],[-81.427713,37.749257]]]},"properties":{"id":"25817","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.979003,37.570179],[-80.917673,37.611406],[-80.96988,37.583389],[-80.979003,37.570179]]],[[[-80.971577,37.653431],[-80.911739,37.61269],[-80.884096,37.539879],[-80.760971,37.613895],[-80.755962,37.669912],[-80.779928,37.73565],[-80.893707,37.769589],[-80.979526,37.708688],[-80.971577,37.653431]]]]},"properties":{"id":"25951","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.933699,38.866097],[-79.745167,38.814432],[-79.728281,38.854703],[-79.642222,39.005211],[-79.716742,39.021931],[-79.828727,39.002026],[-79.887869,39.000154],[-79.940601,38.939375],[-79.933699,38.866097]]]},"properties":{"id":"26241","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.945737,38.876724],[-80.028913,38.891673],[-80.109045,38.896849],[-80.105007,38.875375],[-80.117448,38.774941],[-79.965863,38.786421],[-79.942625,38.810459],[-79.933699,38.866097],[-79.945737,38.876724]]]},"properties":{"id":"26278","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.965863,38.786421],[-80.117448,38.774941],[-80.124729,38.750243],[-80.133072,38.715773],[-80.124795,38.627025],[-80.119724,38.627797],[-79.873959,38.705485],[-79.924982,38.761334],[-79.965863,38.786421]]]},"properties":{"id":"26280","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.828727,39.002026],[-79.716742,39.021931],[-79.721171,39.043988],[-79.828727,39.002026]]]},"properties":{"id":"26276","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.954866,39.187879],[-80.940335,39.132558],[-80.920856,39.151862],[-80.885153,39.227699],[-80.954866,39.187879]]]},"properties":{"id":"26421","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.897754,39.05646],[-80.938628,39.096762],[-80.980614,39.098167],[-81.131485,39.080192],[-81.135991,39.045057],[-80.937226,39.043892],[-80.897754,39.05646]]]},"properties":{"id":"26178","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.194766,39.160998],[-81.102558,39.216609],[-81.10067,39.324273],[-81.239477,39.268328],[-81.239509,39.268313],[-81.194766,39.160998]]]},"properties":{"id":"26337","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.825092,39.115803],[-79.721171,39.043988],[-79.716742,39.021931],[-79.642222,39.005211],[-79.641187,39.078095],[-79.576748,39.14759],[-79.512292,39.217656],[-79.558335,39.231799],[-79.684595,39.270583],[-79.802563,39.233045],[-79.826658,39.195154],[-79.825092,39.115803]]]},"properties":{"id":"26287","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.389503,38.862876],[-80.231861,38.75811],[-80.202466,38.7445],[-80.213936,38.819543],[-80.20842,38.901619],[-80.361902,38.904667],[-80.367768,38.894552],[-80.387573,38.872033],[-80.389503,38.862876]]]},"properties":{"id":"26218","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.606751,38.182503],[-82.501658,38.215427],[-82.507458,38.266381],[-82.571391,38.309182],[-82.579477,38.285353],[-82.606751,38.182503]]]},"properties":{"id":"25555","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.573315,38.402148],[-82.565787,38.390468],[-82.550109,38.399199],[-82.573315,38.402148]]]},"properties":{"id":"25507","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.394446,38.584661],[-80.494868,38.670641],[-80.502913,38.655966],[-80.523619,38.633098],[-80.394446,38.584661]]]},"properties":{"id":"26217","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.618629,39.450375],[-80.544521,39.42912],[-80.508343,39.459136],[-80.557884,39.489439],[-80.618629,39.450375]]]},"properties":{"id":"26348","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.490437,39.101562],[-81.605423,39.112435],[-81.658091,39.06734],[-81.597059,39.0326],[-81.490437,39.101562]]]},"properties":{"id":"26169","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.612491,37.600882],[-81.65948,37.546017],[-81.656283,37.52921],[-81.609189,37.509967],[-81.598321,37.583098],[-81.60508,37.581778],[-81.603446,37.586639],[-81.604635,37.589315],[-81.603495,37.594079],[-81.611201,37.597809],[-81.612491,37.600882]]]},"properties":{"id":"24834","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.483904,37.689108],[-81.491987,37.687666],[-81.518381,37.675898],[-81.513131,37.671638],[-81.504469,37.66647],[-81.491472,37.666337],[-81.483904,37.689108]]]},"properties":{"id":"25916","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.08568,39.671181],[-78.228766,39.391233],[-78.17138,39.353],[-78.066682,39.454589],[-77.91944,39.549145],[-77.91157,39.545865],[-77.936431,39.594261],[-78.08568,39.671181]]]},"properties":{"id":"25427","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.98942,39.443345],[-77.946163,39.452116],[-77.964581,39.494319],[-77.98942,39.443345]]]},"properties":{"id":"25401","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.597381,37.950242],[-81.564427,37.920007],[-81.497009,37.950109],[-81.456632,37.987307],[-81.465314,38.006293],[-81.560703,38.004591],[-81.597381,37.950242]]]},"properties":{"id":"25209","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.807502,38.180065],[-81.757583,38.109332],[-81.752475,38.106493],[-81.735579,38.133669],[-81.757422,38.156731],[-81.800907,38.208861],[-81.807502,38.180065]]]},"properties":{"id":"25169","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.664664,37.967227],[-81.668573,37.936372],[-81.620009,37.897653],[-81.5599,37.878795],[-81.564427,37.920007],[-81.597381,37.950242],[-81.664664,37.967227]]]},"properties":{"id":"25204","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.785723,38.011932],[-81.747739,37.991986],[-81.744626,37.99364],[-81.81683,38.041873],[-81.785723,38.011932]]]},"properties":{"id":"25205","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.889542,38.007825],[-81.87293,38.01023],[-81.874065,38.033586],[-81.893561,38.016573],[-81.889542,38.007825]]]},"properties":{"id":"25203","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.276861,38.14089],[-82.256658,38.132098],[-82.182701,38.144149],[-82.12412,38.183563],[-82.082139,38.13698],[-82.070449,38.13064],[-82.032221,38.134222],[-82.033206,38.158446],[-82.058503,38.220777],[-82.119662,38.25719],[-82.216797,38.309361],[-82.276212,38.303868],[-82.303919,38.296931],[-82.276861,38.14089]]]},"properties":{"id":"25506","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.490437,39.101562],[-81.597059,39.0326],[-81.598446,39.010692],[-81.538742,39.00851],[-81.324681,38.964591],[-81.226504,38.94072],[-81.225724,38.953775],[-81.208424,38.990345],[-81.206281,39.005753],[-81.21552,39.04348],[-81.237739,39.094798],[-81.301652,39.126955],[-81.465503,39.16285],[-81.490437,39.101562]]]},"properties":{"id":"26143","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.233363,38.503922],[-81.247457,38.382248],[-81.194364,38.428163],[-81.195199,38.432597],[-81.233363,38.503922]]]},"properties":{"id":"25030","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.538161,39.413807],[-80.449076,39.330046],[-80.439648,39.372892],[-80.451581,39.450787],[-80.472688,39.485738],[-80.508343,39.459136],[-80.544521,39.42912],[-80.538161,39.413807]]]},"properties":{"id":"26448","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.908878,38.130776],[-80.937268,38.107147],[-80.918451,38.06742],[-80.802122,38.06046],[-80.794168,38.062504],[-80.865953,38.130654],[-80.870222,38.157],[-80.908878,38.130776]]]},"properties":{"id":"26680","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.030345,38.072767],[-80.99652,38.056316],[-80.953124,38.031759],[-80.929421,38.051674],[-80.918451,38.06742],[-80.937268,38.107147],[-81.030345,38.072767]]]},"properties":{"id":"25868","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.980117,37.924683],[-80.935568,37.888655],[-80.851736,37.908098],[-80.929421,38.051674],[-80.953124,38.031759],[-81.021383,38.00055],[-81.027494,37.959737],[-80.980117,37.924683]]]},"properties":{"id":"25831","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.193434,38.229264],[-81.230362,38.210818],[-81.232144,38.210422],[-81.247602,38.16817],[-81.233571,38.145366],[-81.233344,38.145411],[-81.205829,38.160176],[-81.150143,38.133438],[-81.154835,38.172786],[-81.191802,38.228495],[-81.193434,38.229264]]]},"properties":{"id":"25085","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.37021,38.157641],[-81.405012,38.178391],[-81.41276,38.163965],[-81.408532,38.135491],[-81.380829,37.969109],[-81.37283,37.971309],[-81.299428,37.94561],[-81.35104,38.088762],[-81.346402,38.106539],[-81.337845,38.140878],[-81.37021,38.157641]]]},"properties":{"id":"25083","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.193434,38.229264],[-81.189393,38.254893],[-81.231434,38.263716],[-81.243637,38.251626],[-81.230362,38.210818],[-81.193434,38.229264]]]},"properties":{"id":"25059","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.946762,38.981053],[-81.00486,38.960583],[-81.008558,38.900403],[-80.878762,38.87356],[-80.786389,38.836346],[-80.757412,38.91559],[-80.76475,38.989905],[-80.946762,38.981053]]]},"properties":{"id":"26351","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.655033,38.950435],[-80.670636,39.000402],[-80.725741,39.064114],[-80.772601,39.012223],[-80.76475,38.989905],[-80.757412,38.91559],[-80.738411,38.925309],[-80.676484,38.925307],[-80.655033,38.950435]]]},"properties":{"id":"26384","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.168922,39.193025],[-79.154569,39.283863],[-79.259129,39.139815],[-79.168922,39.193025]]],[[[-78.999115,39.358879],[-78.833672,39.441557],[-78.812304,39.543667],[-78.84771,39.562268],[-79.021693,39.466473],[-79.054031,39.476562],[-79.132033,39.417834],[-79.07819,39.385846],[-78.999115,39.358879]]]]},"properties":{"id":"26726","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.091701,39.120621],[-79.17512,39.029239],[-79.248021,38.889494],[-79.134296,38.81334],[-79.081504,39.026976],[-79.036187,39.134174],[-79.091701,39.120621]]]},"properties":{"id":"26847","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.704412,37.89457],[-80.655102,37.86897],[-80.677339,37.942935],[-80.694456,37.951777],[-80.704412,37.89457]]]},"properties":{"id":"24977","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.536253,38.091682],[-80.599759,38.085762],[-80.689733,38.016297],[-80.708089,37.971478],[-80.694456,37.951777],[-80.677339,37.942935],[-80.56504,37.996665],[-80.536097,38.083271],[-80.536253,38.091682]]]},"properties":{"id":"25984","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.56504,37.996665],[-80.677339,37.942935],[-80.655102,37.86897],[-80.619044,37.823285],[-80.579664,37.887459],[-80.507784,37.901283],[-80.56504,37.996665]]]},"properties":{"id":"24931","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.56504,37.996665],[-80.507784,37.901283],[-80.449758,37.948894],[-80.536097,38.083271],[-80.56504,37.996665]]]},"properties":{"id":"24991","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.384433,37.789604],[-80.348049,37.707103],[-80.336316,37.687568],[-80.296138,37.691783],[-79.959844,38.063697],[-80.160456,38.037974],[-80.18798,38.039194],[-80.334499,37.891368],[-80.384433,37.789604]]]},"properties":{"id":"24986","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.714351,38.14549],[-80.865953,38.130654],[-80.794168,38.062504],[-80.771739,38.051351],[-80.700045,38.051075],[-80.689733,38.016297],[-80.599759,38.085762],[-80.630361,38.132804],[-80.714351,38.14549]]]},"properties":{"id":"25981","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.660633,39.203346],[-78.66831,39.133535],[-78.620625,39.158741],[-78.580818,39.199819],[-78.660633,39.203346]]]},"properties":{"id":"26714","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.518906,40.564294],[-80.588955,40.620056],[-80.581529,40.609561],[-80.518906,40.564294]]]},"properties":{"id":"26034","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439414,39.30617],[-80.427205,39.289306],[-80.456864,39.281261],[-80.447262,39.213124],[-80.416639,39.210665],[-80.410662,39.215501],[-80.387873,39.209799],[-80.404116,39.19897],[-80.354847,39.196241],[-80.306841,39.238684],[-80.288939,39.244685],[-80.298692,39.257135],[-80.281556,39.259572],[-80.294414,39.317198],[-80.322908,39.33208],[-80.325555,39.349767],[-80.320984,39.351722],[-80.322841,39.37665],[-80.32457,39.376069],[-80.332639,39.376227],[-80.439648,39.372892],[-80.449076,39.330046],[-80.439414,39.30617]]]},"properties":{"id":"26301","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.354847,39.196241],[-80.224379,39.17135],[-80.20385,39.196349],[-80.306841,39.238684],[-80.354847,39.196241]]]},"properties":{"id":"26408","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.342459,39.380382],[-80.32457,39.376069],[-80.322841,39.37665],[-80.314169,39.364223],[-80.304714,39.355188],[-80.192858,39.393074],[-80.259408,39.424646],[-80.297104,39.428272],[-80.313269,39.437627],[-80.399718,39.431453],[-80.342459,39.380382]]]},"properties":{"id":"26431","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.860203,39.152981],[-77.828806,39.132773],[-77.806049,39.171737],[-77.860203,39.152981]]]},"properties":{"id":"20135","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.525454,38.21381],[-81.572379,38.261735],[-81.578528,38.26177],[-81.60059,38.257296],[-81.578425,38.193279],[-81.527396,38.212644],[-81.525454,38.21381]]]},"properties":{"id":"25315","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.629492,38.340929],[-81.626995,38.358308],[-81.645906,38.353726],[-81.629492,38.340929]]]},"properties":{"id":"25301","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.751235,38.356661],[-81.78945,38.381101],[-81.850797,38.421932],[-81.897948,38.399695],[-81.915197,38.325597],[-81.898087,38.319289],[-81.809148,38.336808],[-81.751235,38.356661]]]},"properties":{"id":"25177","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.620634,39.023662],[-80.624993,39.142096],[-80.701313,39.090325],[-80.620634,39.023662]]]},"properties":{"id":"26338","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.361902,38.904667],[-80.338285,39.018191],[-80.43656,38.973803],[-80.367768,38.894552],[-80.361902,38.904667]]]},"properties":{"id":"26372","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.888111,37.693243],[-81.881272,37.677754],[-81.817751,37.683422],[-81.847653,37.706389],[-81.888111,37.693243]]]},"properties":{"id":"25611","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.880768,37.919177],[-81.77317,37.875408],[-81.759145,37.885911],[-81.819741,37.947326],[-81.831799,37.94761],[-81.848214,37.947997],[-81.880768,37.919177]]]},"properties":{"id":"25183","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991253,37.773771],[-81.97087,37.799405],[-82.001699,37.783219],[-81.991253,37.773771]]]},"properties":{"id":"25647","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.987587,37.827883],[-81.998976,37.846247],[-82.013016,37.820442],[-81.97785,37.817559],[-81.987587,37.827883]]]},"properties":{"id":"25653","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.070673,37.702042],[-81.999077,37.649915],[-82.003505,37.729071],[-82.070673,37.702042]]]},"properties":{"id":"25644","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.910577,37.810257],[-81.893759,37.779233],[-81.88522,37.778894],[-81.863036,37.777611],[-81.839957,37.788393],[-81.910577,37.810257]]]},"properties":{"id":"25654","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.362961,37.338087],[-81.387231,37.361368],[-81.419019,37.370774],[-81.420696,37.372263],[-81.456834,37.330924],[-81.397531,37.304005],[-81.362961,37.338087]]]},"properties":{"id":"24808","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.390762,37.389396],[-81.417586,37.407927],[-81.418217,37.384509],[-81.390762,37.389396]]]},"properties":{"id":"24831","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.732645,37.375586],[-81.795125,37.367334],[-81.761149,37.350769],[-81.732645,37.375586]]],[[[-81.790202,37.284088],[-81.741683,37.254867],[-81.736173,37.267973],[-81.732262,37.294521],[-81.753235,37.343008],[-81.7627,37.349098],[-81.778155,37.311405],[-81.790202,37.284088]]]]},"properties":{"id":"24879","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.879656,37.331921],[-81.790202,37.284088],[-81.778155,37.311405],[-81.834058,37.348385],[-81.879656,37.331921]]]},"properties":{"id":"24850","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.700169,37.472627],[-81.690365,37.503768],[-81.73595,37.495585],[-81.708316,37.465389],[-81.702209,37.468683],[-81.700169,37.472627]]]},"properties":{"id":"24843","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.146549,39.34077],[-80.047362,39.246077],[-79.961025,39.268944],[-79.940175,39.29722],[-79.903901,39.39185],[-79.945005,39.454719],[-80.159471,39.374795],[-80.146549,39.34077]]]},"properties":{"id":"26354","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.740352,39.975243],[-80.76208,39.93446],[-80.699623,39.961793],[-80.685688,39.993316],[-80.692274,39.991737],[-80.740352,39.975243]]]},"properties":{"id":"26038","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.705027,39.826927],[-80.682668,39.721306],[-80.661712,39.721304],[-80.637542,39.888525],[-80.705027,39.826927]]]},"properties":{"id":"26039","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.591231,39.962541],[-80.519112,39.962126],[-80.51912,40.01641],[-80.531233,40.017484],[-80.591231,39.962541]]]},"properties":{"id":"26036","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.149182,38.835697],[-82.186667,38.668583],[-82.107405,38.687151],[-82.109062,38.705884],[-82.149182,38.835697]]]},"properties":{"id":"25515","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.03424,38.793604],[-82.109062,38.705884],[-82.107405,38.687151],[-82.05659,38.692761],[-81.955204,38.734628],[-82.03424,38.793604]]]},"properties":{"id":"25187","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.126304,38.63806],[-82.05659,38.692761],[-82.107405,38.687151],[-82.186667,38.668583],[-82.1844,38.657629],[-82.126304,38.63806]]]},"properties":{"id":"25502","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.016352,38.848821],[-81.917424,38.866426],[-81.908645,38.87846],[-81.923954,38.974041],[-81.982027,38.989061],[-82.015865,39.004818],[-82.023281,39.006553],[-82.025245,38.988096],[-82.050937,38.921911],[-82.016352,38.848821]]]},"properties":{"id":"25253","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.812304,39.543667],[-78.833672,39.441557],[-78.791405,39.412585],[-78.790949,39.413085],[-78.753345,39.525499],[-78.812304,39.543667]]]},"properties":{"id":"26719","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.812304,39.543667],[-78.753345,39.525499],[-78.711797,39.559847],[-78.773512,39.605487],[-78.777052,39.627721],[-78.84771,39.562268],[-78.812304,39.543667]]]},"properties":{"id":"26753","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.191913,37.959027],[-82.303098,37.903318],[-82.263471,37.857309],[-82.145314,37.836967],[-82.11222,37.859418],[-82.120161,37.892392],[-82.191913,37.959027]]]},"properties":{"id":"25671","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.829267,37.595276],[-81.828222,37.597989],[-81.834664,37.611379],[-81.841758,37.593128],[-81.829267,37.595276]]]},"properties":{"id":"24851","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.019322,39.721234],[-79.986283,39.670812],[-79.993521,39.660605],[-79.992845,39.658012],[-79.972637,39.681185],[-79.91628,39.720777],[-80.019322,39.721234]]],[[[-80.188511,39.721214],[-80.161052,39.637831],[-80.124744,39.605265],[-80.083792,39.72122],[-80.188511,39.721214]]]]},"properties":{"id":"26541","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.691966,37.588039],[-80.697107,37.490916],[-80.65826,37.529744],[-80.691966,37.588039]]]},"properties":{"id":"24945","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.336316,37.687568],[-80.437912,37.510316],[-80.328243,37.564022],[-80.296138,37.691783],[-80.336316,37.687568]]]},"properties":{"id":"24941","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.853912,38.162127],[-80.855427,38.152914],[-80.827816,38.157985],[-80.837859,38.169616],[-80.853912,38.162127]]]},"properties":{"id":"26684","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.476396,38.457159],[-79.221005,38.481722],[-79.341668,38.542196],[-79.476396,38.457159]]]},"properties":{"id":"26815","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.637028,39.475755],[-79.531109,39.569011],[-79.748561,39.594692],[-79.713158,39.552472],[-79.637028,39.475755]]]},"properties":{"id":"26519","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.601285,39.364406],[-79.483998,39.373024],[-79.478375,39.588948],[-79.531109,39.569011],[-79.637028,39.475755],[-79.683837,39.424084],[-79.601285,39.364406]]]},"properties":{"id":"26764","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.152804,37.676691],[-81.123247,37.748739],[-81.056139,37.739751],[-81.012771,37.830219],[-81.049797,37.857612],[-81.092643,37.85078],[-81.095395,37.842784],[-81.100674,37.832767],[-81.208251,37.743262],[-81.209966,37.740641],[-81.214524,37.734552],[-81.218058,37.714867],[-81.142787,37.622841],[-81.124456,37.642052],[-81.152804,37.676691]]]},"properties":{"id":"25813","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.208251,37.743262],[-81.209959,37.741181],[-81.209966,37.740641],[-81.208251,37.743262]]]},"properties":{"id":"25873","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.301652,39.126955],[-81.237739,39.094798],[-81.197283,39.12156],[-81.194766,39.160998],[-81.239509,39.268313],[-81.268014,39.254563],[-81.301652,39.126955]]]},"properties":{"id":"26161","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.276973,38.539071],[-81.210986,38.582263],[-81.26191,38.574712],[-81.276973,38.539071]]],[[[-81.281053,38.603048],[-81.261114,38.576463],[-81.285663,38.628885],[-81.281053,38.603048]]]]},"properties":{"id":"25005","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.606751,38.182503],[-82.471009,37.98652],[-82.4212,37.941045],[-82.465985,38.025549],[-82.492099,38.170786],[-82.501658,38.215427],[-82.606751,38.182503]]]},"properties":{"id":"25514","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.306391,37.94364],[-82.274507,37.972778],[-82.355246,37.943957],[-82.346715,37.921175],[-82.325067,37.928192],[-82.306391,37.94364]]]},"properties":{"id":"25699","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.538407,38.522528],[-80.568791,38.595352],[-80.650786,38.52659],[-80.625991,38.510567],[-80.538407,38.522528]]]},"properties":{"id":"26203","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.58755,39.721764],[-80.662386,39.610844],[-80.596869,39.622485],[-80.528104,39.615542],[-80.484745,39.660295],[-80.47643,39.721266],[-80.519342,39.721403],[-80.58755,39.721764]]]},"properties":{"id":"26581","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.764232,39.52495],[-80.736117,39.499811],[-80.69349,39.601601],[-80.764232,39.52495]]]},"properties":{"id":"26167","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.546843,39.296544],[-81.565047,39.293874],[-81.663222,39.276436],[-81.612043,39.174777],[-81.510173,39.204956],[-81.546843,39.296544]]]},"properties":{"id":"26101","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.616484,39.162656],[-81.746185,39.151068],[-81.747254,39.095379],[-81.658091,39.06734],[-81.605423,39.112435],[-81.616484,39.162656]]]},"properties":{"id":"26133","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.381359,39.342472],[-81.526116,39.360919],[-81.462711,39.334063],[-81.426223,39.331892],[-81.381359,39.342472]]]},"properties":{"id":"26187","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.584923,37.686398],[-81.581202,37.643789],[-81.563151,37.641145],[-81.513131,37.671638],[-81.518381,37.675898],[-81.555438,37.702483],[-81.584923,37.686398]]]},"properties":{"id":"24849","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.609189,37.509967],[-81.573419,37.489577],[-81.464092,37.539185],[-81.461398,37.571765],[-81.463699,37.610933],[-81.456899,37.643061],[-81.491472,37.666337],[-81.504469,37.66647],[-81.549559,37.621789],[-81.603838,37.589634],[-81.603519,37.582277],[-81.598321,37.583098],[-81.609189,37.509967]]]},"properties":{"id":"24874","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.456899,37.643061],[-81.463699,37.610933],[-81.422076,37.653437],[-81.447919,37.656141],[-81.456899,37.643061]]]},"properties":{"id":"25876","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.699996,37.57927],[-81.65948,37.546017],[-81.612491,37.600882],[-81.610441,37.595066],[-81.603303,37.595334],[-81.603838,37.589634],[-81.549559,37.621789],[-81.563151,37.641145],[-81.581202,37.643789],[-81.623404,37.636851],[-81.699996,37.57927]]]},"properties":{"id":"24818","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.625063,37.673438],[-81.670534,37.697535],[-81.694876,37.679806],[-81.688715,37.667915],[-81.631291,37.660389],[-81.625063,37.673438]]]},"properties":{"id":"24857","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.463699,37.610933],[-81.461398,37.571765],[-81.434698,37.580791],[-81.423457,37.587842],[-81.422076,37.653437],[-81.463699,37.610933]]]},"properties":{"id":"24867","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.795235,37.50971],[-81.824937,37.530781],[-81.829037,37.51371],[-81.795235,37.50971]]]},"properties":{"id":"24845","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.911791,39.127306],[-79.887869,39.000154],[-79.828727,39.002026],[-79.721171,39.043988],[-79.825092,39.115803],[-79.911791,39.127306]]]},"properties":{"id":"26283","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.743627,37.989043],[-81.743187,37.965284],[-81.701865,37.940491],[-81.668573,37.936372],[-81.664664,37.967227],[-81.675604,38.016912],[-81.686126,38.025617],[-81.744626,37.99364],[-81.747739,37.991986],[-81.743627,37.989043]]]},"properties":{"id":"25206","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.817475,37.953537],[-81.743627,37.989043],[-81.747739,37.991986],[-81.785723,38.011932],[-81.849373,37.979505],[-81.831799,37.94761],[-81.819741,37.947326],[-81.817475,37.953537]]]},"properties":{"id":"25114","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.759145,37.885911],[-81.721775,37.915481],[-81.724954,37.932058],[-81.728935,37.935157],[-81.817475,37.953537],[-81.819741,37.947326],[-81.759145,37.885911]]]},"properties":{"id":"25047","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.812061,38.814793],[-80.724949,38.725949],[-80.663593,38.721642],[-80.669952,38.724203],[-80.682053,38.763836],[-80.709669,38.774889],[-80.766431,38.841361],[-80.777701,38.834782],[-80.812061,38.814793]]]},"properties":{"id":"26619","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.799022,38.574949],[-80.948935,38.689587],[-81.005493,38.69594],[-81.005498,38.641284],[-81.030322,38.60446],[-80.96864,38.534162],[-80.872948,38.476484],[-80.864583,38.476759],[-80.83555,38.53116],[-80.785295,38.557587],[-80.799022,38.574949]]]},"properties":{"id":"25063","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.573315,38.402148],[-82.550109,38.399199],[-82.565787,38.390468],[-82.571391,38.309182],[-82.507458,38.266381],[-82.494365,38.275241],[-82.454342,38.335927],[-82.451791,38.426107],[-82.573133,38.405611],[-82.573315,38.402148]]]},"properties":{"id":"25704","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.181823,38.512575],[-82.190204,38.408489],[-82.172723,38.362347],[-82.164651,38.336506],[-82.115002,38.348182],[-82.055127,38.474547],[-82.057842,38.513492],[-82.092676,38.563266],[-82.181823,38.512575]]]},"properties":{"id":"25541","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.111718,38.815743],[-81.237815,38.868602],[-81.203806,38.774514],[-81.043794,38.774426],[-81.043607,38.798479],[-81.034454,38.862452],[-81.067834,38.840146],[-81.111718,38.815743]]]},"properties":{"id":"25234","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.140906,38.397109],[-81.017992,38.36271],[-80.875551,38.439146],[-80.872948,38.476484],[-80.96864,38.534162],[-81.08796,38.53359],[-81.093101,38.529787],[-81.173771,38.468449],[-81.172645,38.436485],[-81.140906,38.397109]]]},"properties":{"id":"25043","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.061639,38.635686],[-81.08371,38.611982],[-81.130978,38.565822],[-81.08796,38.53359],[-80.96864,38.534162],[-81.030322,38.60446],[-81.061639,38.635686]]]},"properties":{"id":"25113","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.08796,38.53359],[-81.130978,38.565822],[-81.150991,38.553713],[-81.13386,38.507103],[-81.093101,38.529787],[-81.08796,38.53359]]]},"properties":{"id":"25285","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.872948,38.476484],[-80.875551,38.439146],[-80.842907,38.461221],[-80.835918,38.465973],[-80.864583,38.476759],[-80.872948,38.476484]]]},"properties":{"id":"25211","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.046865,39.268239],[-80.954866,39.187879],[-80.885153,39.227699],[-80.843422,39.350887],[-80.908292,39.390939],[-81.009035,39.360437],[-81.00886,39.359042],[-81.02831,39.34797],[-81.046865,39.268239]]]},"properties":{"id":"26415","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.672713,39.429958],[-80.634849,39.389849],[-80.538161,39.413807],[-80.544521,39.42912],[-80.618629,39.450375],[-80.672713,39.429958]]]},"properties":{"id":"26339","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.071035,37.863599],[-81.092643,37.85078],[-81.049797,37.857612],[-81.041405,37.85386],[-81.067291,37.86987],[-81.071035,37.863599]]]},"properties":{"id":"25907","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.346402,38.106539],[-81.290672,38.055144],[-81.257546,38.109922],[-81.265254,38.118816],[-81.274235,38.130864],[-81.296024,38.153119],[-81.337845,38.140878],[-81.346402,38.106539]]]},"properties":{"id":"25118","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.290672,38.055144],[-81.346402,38.106539],[-81.35104,38.088762],[-81.303453,38.015494],[-81.290672,38.055144]]]},"properties":{"id":"25161","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.261598,37.932831],[-81.267148,37.887151],[-81.133324,37.847295],[-81.119016,37.879132],[-81.166407,37.913417],[-81.261598,37.932831]],[[-81.197711,37.858609],[-81.193662,37.869161],[-81.187732,37.858986],[-81.197711,37.858609]]]},"properties":{"id":"25880","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.247602,38.16817],[-81.29694,38.156704],[-81.295361,38.153918],[-81.273139,38.133338],[-81.274419,38.134719],[-81.233571,38.145366],[-81.247602,38.16817]]]},"properties":{"id":"25031","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.023824,38.101411],[-81.081007,38.069763],[-81.06432,38.071416],[-81.023824,38.101411]]]},"properties":{"id":"25862","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.122926,37.981507],[-81.08467,37.974882],[-81.108238,37.988033],[-81.122926,37.981507]]]},"properties":{"id":"25879","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.757412,38.91559],[-80.786389,38.836346],[-80.777701,38.834782],[-80.766431,38.841361],[-80.717441,38.869862],[-80.738411,38.925309],[-80.757412,38.91559]]]},"properties":{"id":"26430","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.389842,37.874495],[-80.439051,37.893111],[-80.412117,37.856656],[-80.389842,37.874495]]]},"properties":{"id":"24957","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324236,37.897036],[-80.417999,37.939047],[-80.439051,37.893111],[-80.389842,37.874495],[-80.324236,37.897036]]]},"properties":{"id":"24938","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.535575,37.813661],[-80.579664,37.887459],[-80.619044,37.823285],[-80.535575,37.813661]]]},"properties":{"id":"24916","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.614029,38.299969],[-80.648089,38.284537],[-80.637301,38.267549],[-80.598573,38.166763],[-80.630361,38.132804],[-80.599759,38.085762],[-80.536253,38.091682],[-80.436514,38.267334],[-80.496536,38.299397],[-80.614029,38.299969]]]},"properties":{"id":"26261","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.771739,38.051351],[-80.744041,38.022507],[-80.700045,38.051075],[-80.771739,38.051351]]]},"properties":{"id":"25972","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.657073,39.535141],[-78.58066,39.490067],[-78.587606,39.527721],[-78.657073,39.535141]]]},"properties":{"id":"26722","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.434143,39.553034],[-78.345603,39.456732],[-78.347036,39.465979],[-78.28274,39.62065],[-78.434143,39.553034]]]},"properties":{"id":"25422","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.081504,39.026976],[-79.134296,38.81334],[-79.111181,38.797778],[-79.057253,38.761413],[-78.994837,38.849509],[-78.916707,38.975229],[-78.842916,39.126227],[-78.809988,39.173888],[-78.882618,39.194061],[-78.883007,39.194176],[-79.006223,39.112824],[-79.081504,39.026976]]]},"properties":{"id":"26836","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.842916,39.126227],[-78.916707,38.975229],[-78.804474,38.953893],[-78.723572,39.055042],[-78.726479,39.067503],[-78.842916,39.126227]]]},"properties":{"id":"26801","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.32457,39.376069],[-80.342459,39.380382],[-80.332639,39.376227],[-80.32457,39.376069]]]},"properties":{"id":"26366","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.596126,39.167069],[-80.326721,39.075342],[-80.300209,39.103857],[-80.534663,39.194821],[-80.59604,39.167198],[-80.596126,39.167069]]]},"properties":{"id":"26378","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.849694,39.354231],[-77.901761,39.318532],[-77.835488,39.325917],[-77.835189,39.327108],[-77.849694,39.354231]]]},"properties":{"id":"25438","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.029652,39.262414],[-77.935929,39.201901],[-77.949575,39.253491],[-78.029652,39.262414]]]},"properties":{"id":"25446","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.472536,38.356596],[-81.422198,38.340132],[-81.394504,38.546794],[-81.508412,38.584525],[-81.552969,38.47836],[-81.531016,38.430695],[-81.557979,38.395297],[-81.472536,38.356596]]]},"properties":{"id":"25071","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.754303,38.455751],[-81.708101,38.372045],[-81.706809,38.369803],[-81.661045,38.36204],[-81.531016,38.430695],[-81.552969,38.47836],[-81.751262,38.587351],[-81.755652,38.581769],[-81.754303,38.455751]]]},"properties":{"id":"25312","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.375278,38.198955],[-81.357326,38.175989],[-81.354785,38.185708],[-81.375278,38.198955]]]},"properties":{"id":"25102","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.699599,38.34832],[-81.645946,38.353696],[-81.661045,38.36204],[-81.706809,38.369803],[-81.71847,38.361892],[-81.699599,38.34832]]]},"properties":{"id":"25303","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.645946,38.353696],[-81.699599,38.34832],[-81.687417,38.276999],[-81.60059,38.257296],[-81.578528,38.26177],[-81.622733,38.337197],[-81.629492,38.340929],[-81.645906,38.353726],[-81.645946,38.353696]]]},"properties":{"id":"25314","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.78945,38.381101],[-81.751235,38.356661],[-81.71847,38.361892],[-81.706809,38.369803],[-81.708101,38.372045],[-81.781928,38.394443],[-81.78945,38.381101]],[[-81.767891,38.377814],[-81.769422,38.378598],[-81.766357,38.382712],[-81.764887,38.382015],[-81.767891,38.377814]]]},"properties":{"id":"25064","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.41276,38.163965],[-81.405012,38.178391],[-81.419948,38.202871],[-81.429587,38.215101],[-81.437807,38.221522],[-81.458988,38.209261],[-81.467194,38.191932],[-81.41276,38.163965]]]},"properties":{"id":"25067","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.497023,38.845441],[-80.479748,38.815186],[-80.453071,38.825203],[-80.387573,38.872033],[-80.367768,38.894552],[-80.43656,38.973803],[-80.547185,38.957876],[-80.497023,38.845441]]]},"properties":{"id":"26447","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.082139,38.13698],[-82.12412,38.183563],[-82.182701,38.144149],[-82.082139,38.13698]]]},"properties":{"id":"25540","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.119662,38.25719],[-82.113438,38.339876],[-82.115002,38.348182],[-82.164651,38.336506],[-82.216797,38.309361],[-82.119662,38.25719]]]},"properties":{"id":"25571","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.05713,38.011308],[-82.038262,37.996799],[-82.023317,38.01815],[-82.05713,38.011308]]]},"properties":{"id":"25505","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.866461,37.7499],[-81.802703,37.74776],[-81.782512,37.759862],[-81.839957,37.788393],[-81.863036,37.777611],[-81.865541,37.751215],[-81.866461,37.7499]]]},"properties":{"id":"25606","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.910577,37.810257],[-81.962824,37.808352],[-81.961606,37.797648],[-81.95514,37.675362],[-81.893759,37.779233],[-81.910577,37.810257]]]},"properties":{"id":"25632","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.034416,37.831446],[-82.030464,37.835524],[-82.039549,37.833977],[-82.034416,37.831446]]],[[[-82.145314,37.836967],[-82.122636,37.804615],[-82.102727,37.793455],[-82.04751,37.835928],[-82.11222,37.859418],[-82.145314,37.836967]]]]},"properties":{"id":"25625","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.470803,37.380255],[-81.420696,37.372263],[-81.419019,37.370774],[-81.418217,37.384509],[-81.417586,37.407927],[-81.390762,37.389396],[-81.374386,37.371703],[-81.34123,37.373377],[-81.311201,37.424509],[-81.341613,37.434436],[-81.452071,37.477254],[-81.454597,37.477819],[-81.47291,37.411129],[-81.470803,37.380255]]]},"properties":{"id":"24868","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.470803,37.380255],[-81.47291,37.411129],[-81.519604,37.388463],[-81.502965,37.358281],[-81.470803,37.380255]]]},"properties":{"id":"24888","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.706791,37.452931],[-81.702209,37.468683],[-81.708316,37.465389],[-81.706791,37.452931]]]},"properties":{"id":"24816","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.499096,37.257952],[-81.397531,37.304005],[-81.456834,37.330924],[-81.476732,37.299393],[-81.498883,37.258429],[-81.499096,37.257952]]]},"properties":{"id":"24848","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.175428,39.500081],[-80.163935,39.494854],[-80.157349,39.500933],[-80.165546,39.506691],[-80.175428,39.500081]]]},"properties":{"id":"26559","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.257565,39.703125],[-80.30865,39.611528],[-80.26659,39.547457],[-80.207378,39.548032],[-80.19373,39.562437],[-80.195361,39.563236],[-80.197893,39.610839],[-80.161052,39.637831],[-80.188511,39.721214],[-80.194719,39.721189],[-80.257565,39.703125]]]},"properties":{"id":"26570","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.311006,39.477604],[-80.304606,39.477894],[-80.31404,39.482416],[-80.311006,39.477604]]]},"properties":{"id":"26572","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.092676,38.563266],[-82.057842,38.513492],[-82.012188,38.521546],[-81.997628,38.578399],[-81.957338,38.656644],[-81.955204,38.734628],[-82.05659,38.692761],[-82.126304,38.63806],[-82.078082,38.579642],[-82.092676,38.563266]]]},"properties":{"id":"25082","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.005195,37.449184],[-80.914537,37.466988],[-81.017854,37.536655],[-81.018043,37.536783],[-81.046395,37.503603],[-81.0371,37.494485],[-81.005195,37.449184]]]},"properties":{"id":"25971","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.301652,37.327689],[-81.297264,37.326578],[-81.297144,37.335113],[-81.301779,37.335075],[-81.300735,37.33148],[-81.304746,37.330007],[-81.301652,37.327689]]]},"properties":{"id":"24724","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.221142,37.468707],[-81.18095,37.45507],[-81.121786,37.444671],[-81.123122,37.46996],[-81.21375,37.509401],[-81.222121,37.510416],[-81.246407,37.501941],[-81.221142,37.468707]]]},"properties":{"id":"24714","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.921697,37.573811],[-81.867083,37.540979],[-81.855939,37.54891],[-81.855739,37.56151],[-81.916969,37.604904],[-81.921697,37.573811]]]},"properties":{"id":"25608","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.135015,37.680239],[-82.114112,37.670023],[-82.094865,37.684676],[-82.111575,37.697511],[-82.135015,37.680239]]]},"properties":{"id":"25696","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.932542,39.627255],[-79.972637,39.681185],[-79.992845,39.658012],[-79.983083,39.644185],[-79.932542,39.627255]]]},"properties":{"id":"26505","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.992845,39.658012],[-79.993521,39.660605],[-80.010226,39.65497],[-79.983083,39.644185],[-79.992845,39.658012]]]},"properties":{"id":"26534","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.793919,39.617794],[-79.843365,39.554126],[-79.777771,39.529148],[-79.713158,39.552472],[-79.748561,39.594692],[-79.793919,39.617794]]]},"properties":{"id":"26542","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.65826,37.529744],[-80.565714,37.466936],[-80.511391,37.481672],[-80.437912,37.510316],[-80.336316,37.687568],[-80.348049,37.707103],[-80.437983,37.653507],[-80.468938,37.623867],[-80.586484,37.655292],[-80.688361,37.592113],[-80.689727,37.589643],[-80.691966,37.588039],[-80.65826,37.529744]]]},"properties":{"id":"24983","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.370629,38.750186],[-79.489368,38.888381],[-79.529104,38.822978],[-79.572299,38.711974],[-79.431995,38.6834],[-79.370629,38.750186]]]},"properties":{"id":"26814","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.279973,38.910142],[-79.349867,38.957509],[-79.467728,38.89201],[-79.489368,38.888381],[-79.370629,38.750186],[-79.279973,38.910142]]]},"properties":{"id":"26884","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.426223,39.331892],[-81.40966,39.272852],[-81.268014,39.254563],[-81.239509,39.268313],[-81.239477,39.268328],[-81.37126,39.341924],[-81.381359,39.342472],[-81.426223,39.331892]]]},"properties":{"id":"26184","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.834418,38.599978],[-81.957338,38.656644],[-81.997628,38.578399],[-81.975297,38.562664],[-81.834418,38.599978]]]},"properties":{"id":"25033","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.284951,37.762165],[-81.209959,37.741181],[-81.208251,37.743262],[-81.100674,37.832767],[-81.133279,37.847212],[-81.133324,37.847295],[-81.267148,37.887151],[-81.295182,37.889368],[-81.371219,37.829053],[-81.321799,37.769903],[-81.292343,37.759337],[-81.290441,37.760211],[-81.283268,37.782516],[-81.263297,37.77157],[-81.284951,37.762165]],[[-81.164251,37.760546],[-81.165778,37.759758],[-81.167678,37.756738],[-81.168956,37.757619],[-81.167709,37.759583],[-81.164251,37.760546]],[[-81.210548,37.774265],[-81.201757,37.772615],[-81.206665,37.768023],[-81.215611,37.768798],[-81.210548,37.774265]]]},"properties":{"id":"25801","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.321589,37.597551],[-81.277303,37.541116],[-81.242579,37.571774],[-81.254281,37.57579],[-81.32032,37.597156],[-81.321589,37.597551]]]},"properties":{"id":"25811","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.069663,37.605298],[-81.124456,37.642052],[-81.142787,37.622841],[-81.188284,37.613621],[-81.169714,37.586709],[-81.069663,37.605298]]]},"properties":{"id":"25843","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.350898,37.773436],[-81.3717,37.765084],[-81.419464,37.737752],[-81.396525,37.705695],[-81.383895,37.687488],[-81.287851,37.709037],[-81.276857,37.723121],[-81.284622,37.735622],[-81.329407,37.767023],[-81.350898,37.773436]]]},"properties":{"id":"25865","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.284951,37.762165],[-81.263297,37.77157],[-81.283268,37.782516],[-81.290441,37.760211],[-81.284951,37.762165]]]},"properties":{"id":"25836","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.416395,37.801362],[-81.435082,37.805249],[-81.45567,37.779994],[-81.414649,37.798218],[-81.416395,37.801362]]]},"properties":{"id":"25180","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.529104,38.822978],[-79.64066,38.716558],[-79.655456,38.687473],[-79.626774,38.664214],[-79.572299,38.711974],[-79.529104,38.822978]]]},"properties":{"id":"26296","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.467728,38.89201],[-79.508339,38.974979],[-79.544488,38.983189],[-79.561757,38.987132],[-79.667475,38.792121],[-79.64066,38.716558],[-79.529104,38.822978],[-79.489368,38.888381],[-79.467728,38.89201]]]},"properties":{"id":"26270","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.028913,38.891673],[-79.945737,38.876724],[-79.961594,38.939141],[-80.03965,38.953199],[-80.028913,38.891673]]]},"properties":{"id":"26257","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.961594,38.939141],[-79.945737,38.876724],[-79.933699,38.866097],[-79.940601,38.939375],[-79.961594,38.939141]]]},"properties":{"id":"26285","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.197625,38.67948],[-81.211565,38.580983],[-81.150991,38.553713],[-81.130978,38.565822],[-81.08371,38.611982],[-81.154128,38.644304],[-81.197625,38.67948]]]},"properties":{"id":"25266","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.889847,37.797262],[-80.900955,37.781024],[-80.893707,37.769589],[-80.779928,37.73565],[-80.731202,37.796045],[-80.889847,37.797262]]]},"properties":{"id":"25985","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.409103,38.770511],[-80.393063,38.727571],[-80.367246,38.733013],[-80.364106,38.777999],[-80.409103,38.770511]]]},"properties":{"id":"26228","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.511474,38.442677],[-80.491959,38.39971],[-80.489732,38.442507],[-80.511474,38.442677]]]},"properties":{"id":"26266","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.511474,38.442677],[-80.538407,38.522528],[-80.625991,38.510567],[-80.672634,38.493406],[-80.647682,38.43927],[-80.612582,38.418828],[-80.396904,38.349086],[-80.491959,38.39971],[-80.511474,38.442677]]]},"properties":{"id":"26206","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.596869,39.622485],[-80.662386,39.610844],[-80.69349,39.601601],[-80.736117,39.499811],[-80.735608,39.491132],[-80.713742,39.475319],[-80.586472,39.561873],[-80.582474,39.568178],[-80.596869,39.622485]]]},"properties":{"id":"26419","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.746185,39.151068],[-81.616484,39.162656],[-81.612043,39.174777],[-81.663222,39.276436],[-81.746185,39.151068]]]},"properties":{"id":"26181","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.581202,37.643789],[-81.584923,37.686398],[-81.625063,37.673438],[-81.631291,37.660389],[-81.623404,37.636851],[-81.581202,37.643789]]]},"properties":{"id":"24860","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.422076,37.653437],[-81.423457,37.587842],[-81.407285,37.56208],[-81.388536,37.541983],[-81.368044,37.549768],[-81.356086,37.5713],[-81.356844,37.571142],[-81.356603,37.588038],[-81.344005,37.630054],[-81.365994,37.661684],[-81.383895,37.687488],[-81.396525,37.705695],[-81.421862,37.697759],[-81.447919,37.656141],[-81.422076,37.653437]],[[-81.392666,37.626712],[-81.391638,37.633477],[-81.385994,37.631038],[-81.392666,37.626712]]]},"properties":{"id":"25882","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.356086,37.5713],[-81.328912,37.608266],[-81.344005,37.630054],[-81.356603,37.588038],[-81.354119,37.577771],[-81.356844,37.571142],[-81.356086,37.5713]]]},"properties":{"id":"25810","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.458431,37.72731],[-81.531144,37.718173],[-81.491987,37.687666],[-81.483904,37.689108],[-81.483769,37.691259],[-81.458431,37.72731]]]},"properties":{"id":"25913","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.407285,37.56208],[-81.423457,37.587842],[-81.434698,37.580791],[-81.407285,37.56208]]]},"properties":{"id":"24847","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.100881,39.192966],[-80.20385,39.196349],[-80.224379,39.17135],[-80.224843,39.142696],[-80.086085,39.089803],[-80.047425,39.061744],[-79.911791,39.127306],[-79.825092,39.115803],[-79.826658,39.195154],[-79.961025,39.268944],[-80.047362,39.246077],[-80.129153,39.239876],[-80.118088,39.237985],[-80.100881,39.192966]]]},"properties":{"id":"26416","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.982857,38.961428],[-79.932183,38.968146],[-79.919991,38.988653],[-79.967599,38.975749],[-79.982857,38.961428]]]},"properties":{"id":"26275","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.066682,39.454589],[-78.059658,39.407137],[-77.998077,39.429594],[-77.98942,39.443345],[-77.964581,39.494319],[-77.924045,39.530364],[-77.91944,39.549145],[-78.066682,39.454589]]]},"properties":{"id":"25403","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.712073,38.167381],[-81.757422,38.156731],[-81.735579,38.133669],[-81.70914,38.148508],[-81.712073,38.167381]]]},"properties":{"id":"25142","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.81683,38.041873],[-81.744626,37.99364],[-81.686126,38.025617],[-81.710668,38.050684],[-81.803458,38.0834],[-81.81683,38.041873]]]},"properties":{"id":"25130","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.817475,37.953537],[-81.728935,37.935157],[-81.743187,37.965284],[-81.743627,37.989043],[-81.817475,37.953537]]]},"properties":{"id":"25149","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.743187,37.965284],[-81.728935,37.935157],[-81.724954,37.932058],[-81.701865,37.940491],[-81.743187,37.965284]]]},"properties":{"id":"25028","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.671057,38.158444],[-81.659322,38.134859],[-81.627692,38.131095],[-81.636967,38.171494],[-81.671057,38.158444]]]},"properties":{"id":"25165","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.571758,40.309971],[-80.610256,40.358546],[-80.602057,40.310514],[-80.571758,40.309971]]]},"properties":{"id":"26037","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.333591,38.382283],[-82.343866,38.305286],[-82.329715,38.298234],[-82.303919,38.296931],[-82.276212,38.303868],[-82.172723,38.362347],[-82.190204,38.408489],[-82.27375,38.448591],[-82.29908,38.422292],[-82.333591,38.382283]]]},"properties":{"id":"25504","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.946762,38.981053],[-80.937226,39.043892],[-81.135991,39.045057],[-81.162455,39.030608],[-81.179209,39.016228],[-81.198736,38.998748],[-81.060617,38.968974],[-81.00486,38.960583],[-80.946762,38.981053]]]},"properties":{"id":"26137","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.225724,38.953775],[-81.162692,38.945524],[-81.060617,38.968974],[-81.198736,38.998748],[-81.208424,38.990345],[-81.225724,38.953775]]]},"properties":{"id":"26136","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.124168,38.858019],[-81.111718,38.815743],[-81.067834,38.840146],[-81.124168,38.858019]]]},"properties":{"id":"25261","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.022205,38.729972],[-80.973407,38.784628],[-81.043607,38.798479],[-81.043794,38.774426],[-81.022205,38.729972]]]},"properties":{"id":"26638","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.13386,38.507103],[-81.173771,38.468449],[-81.093101,38.529787],[-81.13386,38.507103]]]},"properties":{"id":"25133","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.017992,38.36271],[-81.140906,38.397109],[-81.040789,38.353282],[-81.017992,38.36271]]]},"properties":{"id":"25019","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.634849,39.389849],[-80.669516,39.257256],[-80.59604,39.167198],[-80.534663,39.194821],[-80.447262,39.213124],[-80.456864,39.281261],[-80.439414,39.30617],[-80.449076,39.330046],[-80.538161,39.413807],[-80.634849,39.389849]]]},"properties":{"id":"26426","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.885153,39.227699],[-80.920856,39.151862],[-80.847999,39.128356],[-80.813792,39.110849],[-80.669516,39.257256],[-80.634849,39.389849],[-80.672713,39.429958],[-80.746981,39.400317],[-80.843422,39.350887],[-80.885153,39.227699]],[[-80.725508,39.301691],[-80.70901,39.308901],[-80.69821,39.30237],[-80.726237,39.284436],[-80.725508,39.301691]]]},"properties":{"id":"26456","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.257546,38.109922],[-81.258853,38.078898],[-81.231898,38.050867],[-81.190094,38.094808],[-81.237814,38.121281],[-81.265254,38.118816],[-81.257546,38.109922]]]},"properties":{"id":"25173","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.189393,38.254893],[-81.193434,38.229264],[-81.191802,38.228495],[-81.14392,38.277973],[-81.133637,38.309632],[-81.189393,38.254893]]]},"properties":{"id":"26656","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.258853,38.078898],[-81.257546,38.109922],[-81.290672,38.055144],[-81.303453,38.015494],[-81.236897,38.015438],[-81.239357,38.017852],[-81.261696,38.043655],[-81.258853,38.078898]]]},"properties":{"id":"25119","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.243637,38.251626],[-81.312445,38.183096],[-81.232144,38.210422],[-81.230362,38.210818],[-81.243637,38.251626]],[[-81.239575,38.233455],[-81.240003,38.236601],[-81.235903,38.236655],[-81.235432,38.234437],[-81.239575,38.233455]]]},"properties":{"id":"25036","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.239575,38.233455],[-81.235432,38.234437],[-81.235903,38.236655],[-81.240003,38.236601],[-81.239575,38.233455]]]},"properties":{"id":"25185","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.426021,37.777204],[-80.384433,37.789604],[-80.334499,37.891368],[-80.324236,37.897036],[-80.389842,37.874495],[-80.412117,37.856656],[-80.439051,37.893111],[-80.417999,37.939047],[-80.449758,37.948894],[-80.507784,37.901283],[-80.579664,37.887459],[-80.535575,37.813661],[-80.544638,37.755107],[-80.426021,37.777204]]]},"properties":{"id":"24901","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.586484,37.655292],[-80.468938,37.623867],[-80.456457,37.68493],[-80.555086,37.702801],[-80.586484,37.655292]]]},"properties":{"id":"24976","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.791405,39.412585],[-78.858058,39.313249],[-78.882618,39.194061],[-78.809988,39.173888],[-78.733971,39.205463],[-78.72525,39.216768],[-78.703782,39.307401],[-78.648057,39.33854],[-78.591856,39.398038],[-78.621013,39.43055],[-78.790949,39.413085],[-78.791405,39.412585]]]},"properties":{"id":"26757","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.588955,40.620056],[-80.638211,40.544641],[-80.581529,40.609561],[-80.588955,40.620056]]]},"properties":{"id":"26050","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.95564,39.458569],[-80.013443,39.554512],[-80.080944,39.550943],[-80.091978,39.519642],[-80.097909,39.515091],[-80.111557,39.530358],[-80.19373,39.562437],[-80.207378,39.548032],[-80.239581,39.492085],[-80.265124,39.427485],[-80.259408,39.424646],[-80.192858,39.393074],[-80.159471,39.374795],[-79.945005,39.454719],[-79.95564,39.458569]],[[-80.175428,39.500081],[-80.165546,39.506691],[-80.157349,39.500933],[-80.163935,39.494854],[-80.175428,39.500081]]]},"properties":{"id":"26554","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.322841,39.37665],[-80.320984,39.351722],[-80.314169,39.364223],[-80.322841,39.37665]]]},"properties":{"id":"26361","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.809845,38.676074],[-81.70928,38.650071],[-81.665646,38.689803],[-81.792129,38.73413],[-81.809845,38.676074]]]},"properties":{"id":"25245","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.935929,39.201901],[-77.860894,39.153427],[-77.835488,39.325917],[-77.901761,39.318532],[-77.922808,39.303941],[-77.949576,39.254884],[-77.949575,39.253491],[-77.935929,39.201901]]]},"properties":{"id":"25414","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.849694,39.354231],[-77.835189,39.327108],[-77.808819,39.397553],[-77.870093,39.398377],[-77.849694,39.354231]]]},"properties":{"id":"25442","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.581144,38.328108],[-81.493406,38.333013],[-81.48893,38.338995],[-81.472536,38.356596],[-81.557979,38.395297],[-81.626995,38.358308],[-81.629492,38.340929],[-81.622733,38.337197],[-81.581144,38.328108]],[[-81.60996,38.337592],[-81.611046,38.335255],[-81.61646,38.336478],[-81.613206,38.340821],[-81.60996,38.337592]]]},"properties":{"id":"25311","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.544729,38.137575],[-81.521885,38.11444],[-81.512706,38.126716],[-81.479716,38.195586],[-81.467194,38.191932],[-81.458988,38.209261],[-81.525454,38.21381],[-81.527396,38.212644],[-81.544729,38.137575]]]},"properties":{"id":"25035","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.572368,38.269575],[-81.581144,38.328108],[-81.622733,38.337197],[-81.578528,38.26177],[-81.572379,38.261735],[-81.572368,38.269575]]]},"properties":{"id":"25304","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.448548,38.137893],[-81.479716,38.195586],[-81.512706,38.126716],[-81.44903,38.137663],[-81.448548,38.137893]]]},"properties":{"id":"25134","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.409103,38.770511],[-80.364106,38.777999],[-80.407273,38.778768],[-80.409103,38.770511]]],[[[-80.387573,38.872033],[-80.453071,38.825203],[-80.399935,38.813817],[-80.389503,38.862876],[-80.387573,38.872033]]]]},"properties":{"id":"26343","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.113438,38.339876],[-82.119662,38.25719],[-82.058503,38.220777],[-81.991535,38.275424],[-82.045037,38.322358],[-82.113438,38.339876]]]},"properties":{"id":"25523","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.935452,38.25391],[-81.94324,38.182148],[-81.915604,38.202527],[-81.925969,38.252142],[-81.935452,38.25391]]]},"properties":{"id":"25573","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.276861,38.14089],[-82.388132,38.14071],[-82.202,38.032972],[-82.194425,38.045177],[-82.256658,38.132098],[-82.276861,38.14089]]]},"properties":{"id":"25534","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.888111,37.693243],[-81.847653,37.706389],[-81.866461,37.7499],[-81.865541,37.751215],[-81.88522,37.778894],[-81.893759,37.779233],[-81.95514,37.675362],[-81.953532,37.675126],[-81.91673,37.680503],[-81.888111,37.693243]]]},"properties":{"id":"25635","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.070673,37.702042],[-82.003505,37.729071],[-81.999077,37.649915],[-81.999024,37.649593],[-81.953532,37.675126],[-81.95514,37.675362],[-81.961606,37.797648],[-81.97087,37.799405],[-81.991253,37.773771],[-82.102011,37.769615],[-82.070673,37.702042]]]},"properties":{"id":"25638","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.102727,37.793455],[-82.102011,37.769615],[-81.991253,37.773771],[-82.001699,37.783219],[-82.034416,37.831446],[-82.039549,37.833977],[-82.047064,37.83525],[-82.04751,37.835928],[-82.102727,37.793455]]]},"properties":{"id":"25652","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.833001,37.706722],[-81.712249,37.699085],[-81.748119,37.761988],[-81.773216,37.764475],[-81.782512,37.759862],[-81.802703,37.74776],[-81.833001,37.706722]]]},"properties":{"id":"25617","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990827,37.892342],[-81.961676,37.86489],[-81.926829,37.901748],[-81.932724,37.906109],[-81.969358,37.91457],[-81.990827,37.892342]]]},"properties":{"id":"25639","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.454597,37.477819],[-81.566998,37.391912],[-81.519604,37.388463],[-81.47291,37.411129],[-81.454597,37.477819]]]},"properties":{"id":"24853","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.741683,37.254867],[-81.617354,37.204146],[-81.617135,37.240084],[-81.663929,37.274007],[-81.736173,37.267973],[-81.741683,37.254867]]]},"properties":{"id":"24815","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.374386,37.371703],[-81.387231,37.361368],[-81.362961,37.338087],[-81.362156,37.337687],[-81.34123,37.373377],[-81.374386,37.371703]]]},"properties":{"id":"24861","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.844968,37.390507],[-81.725801,37.376494],[-81.706043,37.40688],[-81.706791,37.452931],[-81.708316,37.465389],[-81.73595,37.495585],[-81.795235,37.50971],[-81.829037,37.51371],[-81.824937,37.530781],[-81.829916,37.535649],[-81.855939,37.54891],[-81.867083,37.540979],[-81.904403,37.509777],[-81.882546,37.40343],[-81.84813,37.391434],[-81.844968,37.390507]]]},"properties":{"id":"24844","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.954248,37.446477],[-81.929926,37.366622],[-81.882546,37.40343],[-81.904403,37.509777],[-81.913066,37.505094],[-81.954248,37.446477]]]},"properties":{"id":"24872","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.569819,37.212355],[-81.617135,37.240084],[-81.617354,37.204146],[-81.561281,37.206633],[-81.569819,37.212355]]],[[[-81.6051,37.301483],[-81.602517,37.287315],[-81.550745,37.212752],[-81.556762,37.207348],[-81.499096,37.257952],[-81.498883,37.258429],[-81.6051,37.301483]]]]},"properties":{"id":"24884","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.617135,37.240084],[-81.569819,37.212355],[-81.550745,37.212752],[-81.602517,37.287315],[-81.617135,37.240084]]]},"properties":{"id":"24866","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.603846,37.456431],[-81.651218,37.446119],[-81.663897,37.41316],[-81.603846,37.456431]]]},"properties":{"id":"24878","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.58755,39.721764],[-80.519342,39.721403],[-80.519112,39.962126],[-80.591231,39.962541],[-80.599093,39.958519],[-80.637542,39.888525],[-80.661712,39.721304],[-80.58755,39.721764]]]},"properties":{"id":"26033","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.812489,39.854309],[-80.705027,39.826927],[-80.637542,39.888525],[-80.599093,39.958519],[-80.699623,39.961793],[-80.76208,39.93446],[-80.812489,39.854309]]]},"properties":{"id":"26041","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.692274,39.991737],[-80.740153,39.99392],[-80.740352,39.975243],[-80.692274,39.991737]]]},"properties":{"id":"26040","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.58755,39.721764],[-80.661712,39.721304],[-80.682668,39.721306],[-80.858185,39.695497],[-80.943782,39.606926],[-80.916244,39.592842],[-80.885267,39.536041],[-80.764232,39.52495],[-80.69349,39.601601],[-80.662386,39.610844],[-80.58755,39.721764]]]},"properties":{"id":"26155","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.149182,38.835697],[-82.109062,38.705884],[-82.03424,38.793604],[-82.147495,38.837495],[-82.149182,38.835697]]]},"properties":{"id":"25106","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.982027,38.989061],[-81.923954,38.974041],[-81.974172,38.992435],[-81.982027,38.989061]]]},"properties":{"id":"25265","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.234012,37.359473],[-81.052886,37.288607],[-80.859457,37.429491],[-80.914537,37.466988],[-81.005195,37.449184],[-81.061581,37.477679],[-81.0736,37.479872],[-81.121057,37.439927],[-81.147667,37.405951],[-81.164579,37.389936],[-81.234012,37.359473]]]},"properties":{"id":"24740","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.315873,37.312423],[-81.307631,37.290878],[-81.052886,37.288607],[-81.234012,37.359473],[-81.247408,37.354977],[-81.245643,37.351351],[-81.252021,37.351187],[-81.253717,37.353919],[-81.299912,37.350432],[-81.304746,37.330007],[-81.300735,37.33148],[-81.301779,37.335075],[-81.297144,37.335113],[-81.297264,37.326578],[-81.301652,37.327689],[-81.315873,37.312423]]]},"properties":{"id":"24701","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.236132,37.436708],[-81.269883,37.431677],[-81.27282,37.468742],[-81.244749,37.456751],[-81.221142,37.468707],[-81.246407,37.501941],[-81.315448,37.481389],[-81.34122,37.488065],[-81.341613,37.434436],[-81.311201,37.424509],[-81.206902,37.423132],[-81.236132,37.436708]]]},"properties":{"id":"24736","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.175973,37.637964],[-82.132652,37.593424],[-82.12865,37.61361],[-82.137596,37.639476],[-82.145723,37.640004],[-82.175973,37.637964]]],[[[-81.994354,37.632328],[-82.073844,37.624684],[-82.0958,37.604565],[-82.066297,37.546053],[-82.042321,37.548225],[-81.994075,37.632448],[-81.994354,37.632328]]]]},"properties":{"id":"25678","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.066297,37.546053],[-82.0958,37.604565],[-82.099008,37.613514],[-82.12865,37.61361],[-82.132652,37.593424],[-82.066297,37.546053]]]},"properties":{"id":"25672","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.714351,38.14549],[-80.630361,38.132804],[-80.598573,38.166763],[-80.665353,38.230166],[-80.71351,38.174009],[-80.714351,38.14549]]]},"properties":{"id":"26676","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.783646,38.231451],[-80.693055,38.263909],[-80.74583,38.291642],[-80.783646,38.231451]]]},"properties":{"id":"26662","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519006,40.03632],[-80.584944,40.130209],[-80.531233,40.017484],[-80.51912,40.01641],[-80.519006,40.03632]]]},"properties":{"id":"26059","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.040776,39.399334],[-81.00886,39.359042],[-81.009035,39.360437],[-81.013904,39.399398],[-81.040776,39.399334]]],[[[-81.10067,39.324273],[-81.102558,39.216609],[-81.046865,39.268239],[-81.02831,39.34797],[-81.10067,39.324273]]]]},"properties":{"id":"26346","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.111552,39.460604],[-81.040776,39.399334],[-81.013904,39.399398],[-80.982361,39.488482],[-81.111552,39.460604]]]},"properties":{"id":"26146","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.224917,38.332229],[-80.149725,38.142092],[-80.222397,38.331136],[-80.224917,38.332229]]]},"properties":{"id":"24924","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.003567,38.283513],[-79.867167,38.403699],[-79.842644,38.437907],[-79.871589,38.453354],[-79.953062,38.412208],[-80.031463,38.394401],[-80.003567,38.283513]]]},"properties":{"id":"24927","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.149725,38.142092],[-80.224917,38.332229],[-80.274012,38.370987],[-80.358001,38.258757],[-80.360048,38.225845],[-80.18798,38.039194],[-80.160456,38.037974],[-80.149725,38.142092]]]},"properties":{"id":"24946","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.791378,39.452087],[-79.687662,39.426817],[-79.683837,39.424084],[-79.637028,39.475755],[-79.713158,39.552472],[-79.777771,39.529148],[-79.798659,39.466344],[-79.791378,39.452087]]]},"properties":{"id":"26537","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.798659,39.466344],[-79.777771,39.529148],[-79.843365,39.554126],[-79.86715,39.523518],[-79.840775,39.502235],[-79.820164,39.510975],[-79.807772,39.497589],[-79.825289,39.482607],[-79.827832,39.479415],[-79.798659,39.466344]]]},"properties":{"id":"26547","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.840775,39.502235],[-79.825289,39.482607],[-79.807772,39.497589],[-79.820164,39.510975],[-79.840775,39.502235]]]},"properties":{"id":"26520","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.912561,38.525867],[-81.915596,38.556923],[-81.957106,38.545827],[-81.912561,38.525867]]]},"properties":{"id":"25070","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.056139,37.739751],[-81.123247,37.748739],[-81.152804,37.676691],[-81.142949,37.703283],[-81.056139,37.739751]]]},"properties":{"id":"25832","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.124456,37.642052],[-81.069663,37.605298],[-81.037862,37.598318],[-81.006322,37.676891],[-81.051861,37.686399],[-81.082889,37.671271],[-81.142949,37.703283],[-81.152804,37.676691],[-81.124456,37.642052]]]},"properties":{"id":"25825","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.254281,37.57579],[-81.316899,37.606768],[-81.316158,37.612849],[-81.32032,37.597156],[-81.254281,37.57579]]]},"properties":{"id":"25915","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.453325,37.837081],[-81.400796,37.83271],[-81.372486,37.862073],[-81.390278,37.887178],[-81.418271,37.892241],[-81.458003,37.85953],[-81.453325,37.837081]]]},"properties":{"id":"25174","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.250757,37.722386],[-81.239587,37.705806],[-81.218058,37.714867],[-81.214524,37.734552],[-81.250757,37.722386]]]},"properties":{"id":"25878","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.056139,37.739751],[-81.142949,37.703283],[-81.082889,37.671271],[-81.069456,37.696031],[-81.051861,37.686399],[-81.006322,37.676891],[-80.979526,37.708688],[-80.893707,37.769589],[-80.900955,37.781024],[-80.92728,37.810204],[-81.012771,37.830219],[-81.056139,37.739751]]]},"properties":{"id":"25918","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.292343,37.759337],[-81.321799,37.769903],[-81.329407,37.767023],[-81.284622,37.735622],[-81.283331,37.735676],[-81.292343,37.759337]]]},"properties":{"id":"25932","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.43473,37.941417],[-81.437511,37.943741],[-81.438238,37.943241],[-81.43473,37.941417]]]},"properties":{"id":"25048","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.924982,38.761334],[-79.873959,38.705485],[-79.83202,38.688848],[-79.745167,38.814432],[-79.933699,38.866097],[-79.942625,38.810459],[-79.913026,38.785706],[-79.91468,38.804596],[-79.897019,38.798025],[-79.899314,38.791108],[-79.924982,38.761334]]]},"properties":{"id":"26253","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.508339,38.974979],[-79.467728,38.89201],[-79.349867,38.957509],[-79.357666,38.964509],[-79.508339,38.974979]]]},"properties":{"id":"26263","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.102558,39.216609],[-81.194766,39.160998],[-81.197283,39.12156],[-81.131485,39.080192],[-80.980614,39.098167],[-80.940335,39.132558],[-80.954866,39.187879],[-81.046865,39.268239],[-81.102558,39.216609]]]},"properties":{"id":"26362","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.521914,38.612251],[-81.371367,38.665728],[-81.48545,38.758277],[-81.536239,38.713435],[-81.522166,38.612746],[-81.521914,38.612251]]]},"properties":{"id":"25243","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.171222,38.714723],[-81.339109,38.668355],[-81.308396,38.612349],[-81.281053,38.603048],[-81.285663,38.628885],[-81.197625,38.67948],[-81.154128,38.644304],[-81.171307,38.714739],[-81.171222,38.714723]]]},"properties":{"id":"25259","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.760971,37.613895],[-80.884096,37.539879],[-80.843724,37.512177],[-80.819025,37.490174],[-80.756675,37.600419],[-80.760971,37.613895]]]},"properties":{"id":"24935","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.171659,39.284316],[-80.187648,39.267143],[-80.170631,39.247314],[-80.171659,39.284316]]]},"properties":{"id":"26424","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.086957,39.268532],[-80.088778,39.266463],[-80.094093,39.267714],[-80.09747,39.266757],[-80.087929,39.262392],[-80.086957,39.268532]]]},"properties":{"id":"26435","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.570186,39.150163],[-79.532028,39.105087],[-79.48571,39.139134],[-79.570186,39.150163]]]},"properties":{"id":"26292","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.735608,39.491132],[-80.908292,39.390939],[-80.843422,39.350887],[-80.746981,39.400317],[-80.713742,39.475319],[-80.735608,39.491132]]]},"properties":{"id":"26320","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.586472,39.561873],[-80.713742,39.475319],[-80.746981,39.400317],[-80.672713,39.429958],[-80.618629,39.450375],[-80.557884,39.489439],[-80.586472,39.561873]]]},"properties":{"id":"26377","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.394446,38.584661],[-80.523619,38.633098],[-80.568791,38.595352],[-80.538407,38.522528],[-80.511474,38.442677],[-80.489732,38.442507],[-80.491959,38.39971],[-80.396904,38.349086],[-80.273923,38.371017],[-80.245518,38.388457],[-80.21468,38.456616],[-80.286948,38.465043],[-80.255256,38.496523],[-80.201464,38.486162],[-80.209289,38.570171],[-80.234991,38.615419],[-80.394446,38.584661]]]},"properties":{"id":"26288","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.345874,38.722192],[-80.442944,38.72714],[-80.457824,38.738273],[-80.494868,38.670641],[-80.394446,38.584661],[-80.234991,38.615419],[-80.280059,38.694867],[-80.345874,38.722192]]]},"properties":{"id":"26222","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.442944,38.72714],[-80.345874,38.722192],[-80.367246,38.733013],[-80.393063,38.727571],[-80.442944,38.72714]]]},"properties":{"id":"26215","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.484745,39.660295],[-80.418321,39.642078],[-80.421388,39.721189],[-80.47643,39.721266],[-80.484745,39.660295]]]},"properties":{"id":"26575","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.473231,38.927261],[-81.376105,38.90718],[-81.347442,38.896922],[-81.324681,38.964591],[-81.538742,39.00851],[-81.532992,39.003468],[-81.473231,38.927261]]]},"properties":{"id":"26160","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.179209,39.016228],[-81.206281,39.005753],[-81.208424,38.990345],[-81.198736,38.998748],[-81.179209,39.016228]]]},"properties":{"id":"26152","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.526116,39.360919],[-81.565047,39.293874],[-81.546843,39.296544],[-81.462711,39.334063],[-81.526116,39.360919]]]},"properties":{"id":"26105","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.510173,39.204956],[-81.612043,39.174777],[-81.616484,39.162656],[-81.605423,39.112435],[-81.490437,39.101562],[-81.465503,39.16285],[-81.442105,39.182515],[-81.507928,39.203907],[-81.510173,39.204956]]]},"properties":{"id":"26150","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.670534,37.697535],[-81.625063,37.673438],[-81.584923,37.686398],[-81.555438,37.702483],[-81.531144,37.718173],[-81.458431,37.72731],[-81.427713,37.749257],[-81.462889,37.785708],[-81.514228,37.791211],[-81.539357,37.783404],[-81.583175,37.752657],[-81.670534,37.697535]]]},"properties":{"id":"24870","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.733529,37.608362],[-81.699996,37.57927],[-81.623404,37.636851],[-81.631291,37.660389],[-81.688715,37.667915],[-81.708537,37.622505],[-81.733529,37.608362]]]},"properties":{"id":"24822","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.421862,37.697759],[-81.483769,37.691259],[-81.483904,37.689108],[-81.491472,37.666337],[-81.456899,37.643061],[-81.447919,37.656141],[-81.421862,37.697759]]]},"properties":{"id":"25875","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.392666,37.626712],[-81.385994,37.631038],[-81.391638,37.633477],[-81.392666,37.626712]]]},"properties":{"id":"25870","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.79413,37.669459],[-81.708537,37.622505],[-81.688715,37.667915],[-81.694876,37.679806],[-81.712249,37.699085],[-81.79413,37.669459]]]},"properties":{"id":"24823","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.361902,38.904667],[-80.20842,38.901619],[-80.105007,38.875375],[-80.109045,38.896849],[-80.072283,38.968564],[-80.047425,39.061744],[-80.086085,39.089803],[-80.227173,39.112792],[-80.300209,39.103857],[-80.326721,39.075342],[-80.338285,39.018191],[-80.361902,38.904667]]]},"properties":{"id":"26201","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.072283,38.968564],[-80.109045,38.896849],[-80.028913,38.891673],[-80.03965,38.953199],[-80.072283,38.968564]]]},"properties":{"id":"26267","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.129153,39.239876],[-80.143659,39.238856],[-80.141241,39.23253],[-80.117527,39.23253],[-80.118088,39.237985],[-80.129153,39.239876]]]},"properties":{"id":"26349","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.988462,39.319777],[-78.085053,39.350641],[-78.123808,39.322677],[-78.033191,39.264612],[-77.988462,39.319777]]]},"properties":{"id":"25413","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.491504,38.067888],[-81.534556,38.075481],[-81.538284,38.074777],[-81.569973,38.063825],[-81.54484,38.042503],[-81.563301,38.013052],[-81.560703,38.004591],[-81.465314,38.006293],[-81.491504,38.067888]]]},"properties":{"id":"25193","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.752475,38.106493],[-81.722799,38.066246],[-81.659322,38.134859],[-81.671057,38.158444],[-81.706652,38.158539],[-81.70914,38.148508],[-81.735579,38.133669],[-81.752475,38.106493]]]},"properties":{"id":"25154","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.803458,38.0834],[-81.839007,38.099779],[-81.871554,38.113753],[-81.959386,38.093348],[-81.905482,38.029448],[-81.893561,38.016573],[-81.874065,38.033586],[-81.87293,38.01023],[-81.889542,38.007825],[-81.911016,37.988174],[-81.849373,37.979505],[-81.785723,38.011932],[-81.81683,38.041873],[-81.803458,38.0834]]]},"properties":{"id":"25053","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.800907,38.208861],[-81.757422,38.156731],[-81.712073,38.167381],[-81.706652,38.158539],[-81.671057,38.158444],[-81.636967,38.171494],[-81.636616,38.176953],[-81.695914,38.225149],[-81.800907,38.208861]]]},"properties":{"id":"25009","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.05713,38.011308],[-82.112845,37.894178],[-82.047242,37.858482],[-82.04575,37.871095],[-82.009119,37.916795],[-81.934487,37.947413],[-81.901845,37.961202],[-81.911016,37.988174],[-81.889542,38.007825],[-81.893561,38.016573],[-81.905482,38.029448],[-82.023317,38.01815],[-82.038262,37.996799],[-82.05713,38.011308]]]},"properties":{"id":"25508","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.636967,38.171494],[-81.627692,38.131095],[-81.584026,38.149094],[-81.602763,38.173779],[-81.636616,38.176953],[-81.636967,38.171494]]]},"properties":{"id":"25024","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.663593,38.721642],[-80.502913,38.655966],[-80.494868,38.670641],[-80.457824,38.738273],[-80.505968,38.741742],[-80.527574,38.743214],[-80.669952,38.724203],[-80.663593,38.721642]]]},"properties":{"id":"26621","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.276212,38.303868],[-82.216797,38.309361],[-82.164651,38.336506],[-82.172723,38.362347],[-82.276212,38.303868]]]},"properties":{"id":"25559","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.233043,38.525184],[-82.218967,38.591683],[-82.316164,38.462319],[-82.280528,38.468402],[-82.233043,38.525184]]]},"properties":{"id":"25537","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.171307,38.714739],[-81.022128,38.713481],[-81.022205,38.729972],[-81.043794,38.774426],[-81.203806,38.774514],[-81.171222,38.714723],[-81.171307,38.714739]]]},"properties":{"id":"25268","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.835918,38.465973],[-80.83555,38.53116],[-80.864583,38.476759],[-80.835918,38.465973]]]},"properties":{"id":"26617","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.786653,39.105392],[-80.708421,39.091714],[-80.701313,39.090325],[-80.624993,39.142096],[-80.596126,39.167069],[-80.59604,39.167198],[-80.669516,39.257256],[-80.813792,39.110849],[-80.81297,39.109401],[-80.786653,39.105392]]]},"properties":{"id":"26411","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.725508,39.301691],[-80.726237,39.284436],[-80.69821,39.30237],[-80.70901,39.308901],[-80.725508,39.301691]]]},"properties":{"id":"26436","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.929421,38.051674],[-80.851736,37.908098],[-80.707007,37.884582],[-80.704412,37.89457],[-80.694456,37.951777],[-80.708089,37.971478],[-80.802122,38.06046],[-80.918451,38.06742],[-80.929421,38.051674]]]},"properties":{"id":"25962","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.232144,38.210422],[-81.312445,38.183096],[-81.314961,38.180529],[-81.29694,38.156704],[-81.247602,38.16817],[-81.232144,38.210422]]]},"properties":{"id":"25186","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.119448,38.117355],[-81.074603,38.196093],[-81.154835,38.172786],[-81.150143,38.133438],[-81.119448,38.117355]]]},"properties":{"id":"25812","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.237814,38.121281],[-81.190094,38.094808],[-81.150143,38.133438],[-81.205829,38.160176],[-81.215211,38.136621],[-81.23759,38.122619],[-81.237814,38.121281]]]},"properties":{"id":"25115","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.274419,38.134719],[-81.217091,38.13708],[-81.233344,38.145411],[-81.233571,38.145366],[-81.274419,38.134719]]]},"properties":{"id":"25002","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.980117,37.924683],[-81.027494,37.959737],[-81.083472,37.963689],[-81.073249,37.875865],[-81.071035,37.863599],[-81.067291,37.86987],[-80.980117,37.924683]]]},"properties":{"id":"25936","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.17512,39.029239],[-79.091701,39.120621],[-79.100402,39.189335],[-79.102228,39.19422],[-79.168922,39.193025],[-79.259129,39.139815],[-79.295506,39.188904],[-79.344926,38.967738],[-79.17512,39.029239]]]},"properties":{"id":"26833","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.348049,37.707103],[-80.384433,37.789604],[-80.426021,37.777204],[-80.431259,37.694624],[-80.437983,37.653507],[-80.348049,37.707103]]]},"properties":{"id":"24925","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.492652,39.363966],[-78.523729,39.470291],[-78.554693,39.404251],[-78.499864,39.335049],[-78.492652,39.363966]]]},"properties":{"id":"25444","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.579888,40.531964],[-80.580141,40.529981],[-80.571649,40.529427],[-80.57812,40.535073],[-80.579888,40.531964]]]},"properties":{"id":"26056","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.804474,38.953893],[-78.73868,38.927362],[-78.717076,38.936028],[-78.723572,39.055042],[-78.804474,38.953893]]]},"properties":{"id":"26810","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.081504,39.026976],[-79.006223,39.112824],[-79.036187,39.134174],[-79.081504,39.026976]]]},"properties":{"id":"26818","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324213,39.348008],[-80.322908,39.33208],[-80.294414,39.317198],[-80.304714,39.355188],[-80.314169,39.364223],[-80.320984,39.351722],[-80.325555,39.349767],[-80.324213,39.348008]],[[-80.324213,39.348008],[-80.32053,39.348588],[-80.3201,39.346809],[-80.324213,39.348008]]]},"properties":{"id":"26404","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.265124,39.427485],[-80.239581,39.492085],[-80.261528,39.474645],[-80.279745,39.478751],[-80.282952,39.484027],[-80.304606,39.477894],[-80.311006,39.477604],[-80.313269,39.437627],[-80.297104,39.428272],[-80.265124,39.427485]]]},"properties":{"id":"26591","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324213,39.348008],[-80.3201,39.346809],[-80.32053,39.348588],[-80.324213,39.348008]]]},"properties":{"id":"26438","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.853223,38.805568],[-81.798402,38.835697],[-81.770828,38.862672],[-81.804194,38.894395],[-81.864266,38.881259],[-81.908721,38.826877],[-81.853223,38.805568]]]},"properties":{"id":"25239","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.598446,39.010692],[-81.719327,38.88289],[-81.630938,38.828758],[-81.532992,39.003468],[-81.538742,39.00851],[-81.598446,39.010692]]]},"properties":{"id":"25275","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.393452,38.281905],[-81.351686,38.352834],[-81.386493,38.328567],[-81.427172,38.266427],[-81.393452,38.281905]]]},"properties":{"id":"25025","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.44903,38.137663],[-81.512706,38.126716],[-81.521885,38.11444],[-81.534556,38.075481],[-81.491504,38.067888],[-81.44903,38.137663]]]},"properties":{"id":"25054","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.310458,38.32627],[-81.301403,38.272595],[-81.297807,38.259857],[-81.24862,38.268986],[-81.310458,38.32627]]]},"properties":{"id":"25160","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.767891,38.377814],[-81.764887,38.382015],[-81.766357,38.382712],[-81.769422,38.378598],[-81.767891,38.377814]]]},"properties":{"id":"25112","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.256658,38.132098],[-82.194425,38.045177],[-82.054702,38.108708],[-82.070449,38.13064],[-82.082139,38.13698],[-82.182701,38.144149],[-82.256658,38.132098]]]},"properties":{"id":"25557","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.773216,37.764475],[-81.757639,37.807507],[-81.793593,37.798585],[-81.839957,37.788393],[-81.782512,37.759862],[-81.773216,37.764475]]]},"properties":{"id":"25607","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.961676,37.86489],[-81.990827,37.892342],[-81.97234,37.916437],[-82.009119,37.916795],[-82.04575,37.871095],[-82.030464,37.835524],[-82.034416,37.831446],[-82.001699,37.783219],[-81.97087,37.799405],[-81.961606,37.797648],[-81.962824,37.808352],[-81.97785,37.817559],[-82.013016,37.820442],[-81.998976,37.846247],[-81.987587,37.827883],[-81.973923,37.84395],[-81.961676,37.86489]]]},"properties":{"id":"25601","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.009119,37.916795],[-81.97234,37.916437],[-81.969358,37.91457],[-81.932724,37.906109],[-81.934487,37.947413],[-82.009119,37.916795]]]},"properties":{"id":"25547","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.973923,37.84395],[-81.877682,37.86104],[-81.926829,37.901748],[-81.961676,37.86489],[-81.973923,37.84395]]]},"properties":{"id":"25076","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.470803,37.380255],[-81.502965,37.358281],[-81.497263,37.349856],[-81.478239,37.299761],[-81.476732,37.299393],[-81.456834,37.330924],[-81.420696,37.372263],[-81.470803,37.380255]]]},"properties":{"id":"24871","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.834058,37.348385],[-81.778155,37.311405],[-81.7627,37.349098],[-81.761149,37.350769],[-81.795125,37.367334],[-81.844968,37.390507],[-81.84813,37.391434],[-81.834058,37.348385]]]},"properties":{"id":"24817","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.921697,37.573811],[-81.966127,37.544059],[-81.927681,37.512113],[-81.913066,37.505094],[-81.904403,37.509777],[-81.867083,37.540979],[-81.921697,37.573811]]]},"properties":{"id":"24846","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.28557,39.505803],[-80.292554,39.524797],[-80.306332,39.51281],[-80.28557,39.505803]]]},"properties":{"id":"26587","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.528104,39.615542],[-80.4493,39.597633],[-80.363535,39.627079],[-80.36673,39.631778],[-80.418321,39.642078],[-80.484745,39.660295],[-80.528104,39.615542]]]},"properties":{"id":"26585","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.091978,39.519642],[-80.111557,39.530358],[-80.097909,39.515091],[-80.091978,39.519642]]]},"properties":{"id":"26586","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.482273,39.588346],[-80.582474,39.568178],[-80.586472,39.561873],[-80.557884,39.489439],[-80.508343,39.459136],[-80.472688,39.485738],[-80.482273,39.588346]]]},"properties":{"id":"26437","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.015865,39.004818],[-81.99715,39.014324],[-82.023281,39.006553],[-82.015865,39.004818]]],[[[-82.113547,38.930256],[-82.050937,38.921911],[-82.025245,38.988096],[-82.050228,38.996047],[-82.113547,38.930256]]]]},"properties":{"id":"25287","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.206902,37.423132],[-81.311201,37.424509],[-81.34123,37.373377],[-81.299912,37.350432],[-81.253717,37.353919],[-81.247408,37.354977],[-81.234012,37.359473],[-81.164579,37.389936],[-81.165239,37.402946],[-81.147667,37.405951],[-81.121057,37.439927],[-81.121786,37.444671],[-81.18095,37.45507],[-81.206902,37.423132]]]},"properties":{"id":"24747","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.054031,39.476562],[-79.021693,39.466473],[-79.036912,39.476499],[-79.054031,39.476562]]]},"properties":{"id":"26750","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.191913,37.959027],[-82.306391,37.94364],[-82.325067,37.928192],[-82.303098,37.903318],[-82.191913,37.959027]]]},"properties":{"id":"25666","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.932542,39.627255],[-80.013443,39.554512],[-79.95564,39.458569],[-79.86715,39.523518],[-79.843365,39.554126],[-79.793919,39.617794],[-79.763774,39.720776],[-79.91628,39.720777],[-79.972637,39.681185],[-79.932542,39.627255]]]},"properties":{"id":"26508","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.437912,37.510316],[-80.511391,37.481672],[-80.475601,37.422949],[-80.328243,37.564022],[-80.437912,37.510316]]]},"properties":{"id":"24984","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.698272,38.337442],[-80.714934,38.300953],[-80.648089,38.284537],[-80.614029,38.299969],[-80.641101,38.383764],[-80.680863,38.34015],[-80.698272,38.337442]]]},"properties":{"id":"26205","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.71368,38.377482],[-80.680863,38.34015],[-80.641101,38.383764],[-80.612582,38.418828],[-80.647682,38.43927],[-80.71368,38.377482]]]},"properties":{"id":"26691","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.796162,38.323734],[-79.867167,38.403699],[-80.003567,38.283513],[-79.802942,38.292748],[-79.796162,38.323734]]]},"properties":{"id":"24934","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.842008,38.502045],[-81.840164,38.516811],[-81.840511,38.516881],[-81.846676,38.513835],[-81.842008,38.502045]]]},"properties":{"id":"25011","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.842891,38.445255],[-81.923283,38.47872],[-81.897948,38.399695],[-81.850797,38.421932],[-81.842891,38.445255]]]},"properties":{"id":"25560","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.871767,38.538198],[-81.846676,38.513835],[-81.840511,38.516881],[-81.871767,38.538198]]]},"properties":{"id":"25109","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.287851,37.709037],[-81.365994,37.661684],[-81.344005,37.630054],[-81.328912,37.608266],[-81.321589,37.597551],[-81.32032,37.597156],[-81.316158,37.612849],[-81.314046,37.656483],[-81.293114,37.676363],[-81.234984,37.697823],[-81.239587,37.705806],[-81.250757,37.722386],[-81.273342,37.72875],[-81.276857,37.723121],[-81.287851,37.709037]]]},"properties":{"id":"25921","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.421011,37.898],[-81.497009,37.950109],[-81.564427,37.920007],[-81.5599,37.878795],[-81.514228,37.791211],[-81.462889,37.785708],[-81.45567,37.779994],[-81.435082,37.805249],[-81.453325,37.837081],[-81.458003,37.85953],[-81.421011,37.898]]]},"properties":{"id":"25140","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.242579,37.571774],[-81.220623,37.644798],[-81.227864,37.659433],[-81.293114,37.676363],[-81.314046,37.656483],[-81.309474,37.64022],[-81.316899,37.606768],[-81.254281,37.57579],[-81.242579,37.571774]]]},"properties":{"id":"25857","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.051861,37.686399],[-81.069456,37.696031],[-81.082889,37.671271],[-81.051861,37.686399]]]},"properties":{"id":"25989","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.280059,38.694867],[-80.234991,38.615419],[-80.209289,38.570171],[-80.124795,38.627025],[-80.133072,38.715773],[-80.235959,38.743776],[-80.236526,38.743769],[-80.280059,38.694867]]]},"properties":{"id":"26230","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.116319,38.473851],[-80.028576,38.45917],[-79.930683,38.513429],[-79.926327,38.515841],[-80.091782,38.570203],[-80.116319,38.473851]]]},"properties":{"id":"26294","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.091782,38.570203],[-79.926327,38.515841],[-79.810183,38.680212],[-79.83202,38.688848],[-79.873959,38.705485],[-80.119724,38.627797],[-80.091782,38.570203]]]},"properties":{"id":"26273","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.116319,38.473851],[-80.091782,38.570203],[-80.119724,38.627797],[-80.124795,38.627025],[-80.209289,38.570171],[-80.201464,38.486162],[-80.21468,38.456616],[-80.245518,38.388457],[-80.116319,38.473851]]]},"properties":{"id":"26282","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.376105,38.90718],[-81.473231,38.927261],[-81.521832,38.822148],[-81.481707,38.79505],[-81.376105,38.90718]]]},"properties":{"id":"25270","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.948902,39.603483],[-80.916244,39.592842],[-80.943782,39.606926],[-80.948902,39.603483]]]},"properties":{"id":"26159","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.573315,38.402148],[-82.573133,38.405611],[-82.579477,38.285353],[-82.571391,38.309182],[-82.565787,38.390468],[-82.573315,38.402148]]]},"properties":{"id":"25530","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.795235,37.50971],[-81.73595,37.495585],[-81.690365,37.503768],[-81.656283,37.52921],[-81.65948,37.546017],[-81.699996,37.57927],[-81.733529,37.608362],[-81.808278,37.609253],[-81.808279,37.609254],[-81.828222,37.597989],[-81.829267,37.595276],[-81.8297,37.59217],[-81.829916,37.535649],[-81.824937,37.530781],[-81.795235,37.50971]]]},"properties":{"id":"24839","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.388536,37.541983],[-81.326102,37.536218],[-81.368044,37.549768],[-81.388536,37.541983]]],[[[-81.452071,37.477254],[-81.341613,37.434436],[-81.34122,37.488065],[-81.342545,37.487861],[-81.421715,37.490251],[-81.452071,37.477254]]]]},"properties":{"id":"24716","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.603838,37.589634],[-81.603303,37.595334],[-81.610441,37.595066],[-81.612491,37.600882],[-81.611201,37.597809],[-81.603495,37.594079],[-81.604635,37.589315],[-81.603446,37.586639],[-81.60508,37.581778],[-81.598321,37.583098],[-81.603519,37.582277],[-81.603838,37.589634]]]},"properties":{"id":"24898","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.315448,37.481389],[-81.332977,37.495593],[-81.342545,37.487861],[-81.34122,37.488065],[-81.315448,37.481389]]]},"properties":{"id":"24719","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.356603,37.588038],[-81.356844,37.571142],[-81.354119,37.577771],[-81.356603,37.588038]]]},"properties":{"id":"25826","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.867299,39.390279],[-79.903901,39.39185],[-79.940175,39.29722],[-79.850883,39.256796],[-79.855753,39.338456],[-79.867299,39.390279]]]},"properties":{"id":"26440","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.964581,39.494319],[-77.946163,39.452116],[-77.885254,39.41694],[-77.875233,39.424475],[-77.847746,39.501304],[-77.883115,39.550247],[-77.91157,39.545865],[-77.924045,39.530364],[-77.964581,39.494319]]]},"properties":{"id":"25404","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.870093,39.398377],[-77.808819,39.397553],[-77.737521,39.396287],[-77.847746,39.501304],[-77.875233,39.424475],[-77.870093,39.398377]]]},"properties":{"id":"25443","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.686126,38.025617],[-81.675604,38.016912],[-81.597381,37.950242],[-81.560703,38.004591],[-81.563301,38.013052],[-81.569973,38.063825],[-81.538284,38.074777],[-81.627692,38.131095],[-81.659322,38.134859],[-81.722799,38.066246],[-81.710668,38.050684],[-81.686126,38.025617]]]},"properties":{"id":"25181","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.569973,38.063825],[-81.563301,38.013052],[-81.54484,38.042503],[-81.569973,38.063825]]]},"properties":{"id":"25148","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.620009,37.897653],[-81.721775,37.915481],[-81.759145,37.885911],[-81.77317,37.875408],[-81.724069,37.813282],[-81.607532,37.788709],[-81.565794,37.767642],[-81.539357,37.783404],[-81.514228,37.791211],[-81.5599,37.878795],[-81.620009,37.897653]]]},"properties":{"id":"25208","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.675604,38.016912],[-81.664664,37.967227],[-81.597381,37.950242],[-81.675604,38.016912]]]},"properties":{"id":"25093","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.584026,38.149094],[-81.627692,38.131095],[-81.538284,38.074777],[-81.534556,38.075481],[-81.521885,38.11444],[-81.544729,38.137575],[-81.584026,38.149094]]]},"properties":{"id":"25049","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.479748,38.815186],[-80.497023,38.845441],[-80.609386,38.824221],[-80.604114,38.788213],[-80.527574,38.743214],[-80.505968,38.741742],[-80.479748,38.815186]]]},"properties":{"id":"26631","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.653171,40.243182],[-80.660711,40.211797],[-80.635657,40.201942],[-80.585222,40.174595],[-80.582223,40.173867],[-80.51911,40.244436],[-80.519043,40.289845],[-80.571758,40.309971],[-80.602057,40.310514],[-80.653171,40.243182]]]},"properties":{"id":"26070","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.664683,40.212394],[-80.660711,40.211797],[-80.653171,40.243182],[-80.664683,40.212394]]]},"properties":{"id":"26030","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.611694,40.376556],[-80.518022,40.377056],[-80.518406,40.468426],[-80.602579,40.45169],[-80.611694,40.376556]]]},"properties":{"id":"26062","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.27375,38.448591],[-82.190204,38.408489],[-82.181823,38.512575],[-82.233043,38.525184],[-82.280528,38.468402],[-82.27375,38.448591]]]},"properties":{"id":"25545","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.218967,38.591683],[-82.233043,38.525184],[-82.181823,38.512575],[-82.092676,38.563266],[-82.078082,38.579642],[-82.174191,38.611059],[-82.218967,38.591683]]]},"properties":{"id":"25520","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.878762,38.87356],[-81.008558,38.900403],[-81.034454,38.862452],[-81.043607,38.798479],[-80.973407,38.784628],[-80.872963,38.780204],[-80.829993,38.804324],[-80.878762,38.87356]]]},"properties":{"id":"25267","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.249188,38.35002],[-81.186487,38.361763],[-81.194364,38.428163],[-81.247457,38.382248],[-81.249188,38.35002]]]},"properties":{"id":"25088","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.231434,38.263716],[-81.189393,38.254893],[-81.133637,38.309632],[-81.186487,38.361763],[-81.249188,38.35002],[-81.231434,38.263716]]]},"properties":{"id":"25125","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.708421,39.091714],[-80.786653,39.105392],[-80.772601,39.012223],[-80.725741,39.064114],[-80.708421,39.091714]]]},"properties":{"id":"26443","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.851736,37.908098],[-80.935568,37.888655],[-81.041405,37.85386],[-81.049797,37.857612],[-81.012771,37.830219],[-80.92728,37.810204],[-80.889847,37.797262],[-80.731202,37.796045],[-80.707007,37.884582],[-80.851736,37.908098]]]},"properties":{"id":"25976","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.273139,38.133338],[-81.295361,38.153918],[-81.296024,38.153119],[-81.274235,38.130864],[-81.263396,38.130217],[-81.273139,38.133338]]]},"properties":{"id":"25139","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.100402,39.189335],[-79.091701,39.120621],[-79.036187,39.134174],[-79.01675,39.174848],[-79.100402,39.189335]]]},"properties":{"id":"26731","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.531215,39.163345],[-78.57955,39.161039],[-78.620625,39.158741],[-78.66831,39.133535],[-78.726479,39.067503],[-78.723572,39.055042],[-78.717076,38.936028],[-78.508132,39.08863],[-78.531215,39.163345]]]},"properties":{"id":"26851","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.325555,39.349767],[-80.322908,39.33208],[-80.324213,39.348008],[-80.325555,39.349767]]]},"properties":{"id":"26369","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.798402,38.835697],[-81.853223,38.805568],[-81.800536,38.743914],[-81.798402,38.835697]]]},"properties":{"id":"25241","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.792263,39.300197],[-77.784086,39.294444],[-77.781588,39.30402],[-77.789088,39.308423],[-77.792263,39.300197]]]},"properties":{"id":"25432","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.557979,38.395297],[-81.531016,38.430695],[-81.661045,38.36204],[-81.645946,38.353696],[-81.645906,38.353726],[-81.626995,38.358308],[-81.557979,38.395297]]]},"properties":{"id":"25302","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.572368,38.269575],[-81.4467,38.262373],[-81.427172,38.266427],[-81.386493,38.328567],[-81.422198,38.340132],[-81.472536,38.356596],[-81.48893,38.338995],[-81.489653,38.333669],[-81.493406,38.333013],[-81.581144,38.328108],[-81.572368,38.269575]]]},"properties":{"id":"25306","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.801766,38.439029],[-81.781928,38.394443],[-81.708101,38.372045],[-81.754303,38.455751],[-81.801766,38.439029]]]},"properties":{"id":"25313","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.416149,38.211291],[-81.378208,38.209165],[-81.377855,38.208638],[-81.375696,38.202835],[-81.374588,38.199975],[-81.317303,38.181418],[-81.314961,38.180529],[-81.312445,38.183096],[-81.243637,38.251626],[-81.231434,38.263716],[-81.24862,38.268986],[-81.297807,38.259857],[-81.416149,38.211291]]]},"properties":{"id":"25110","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.405012,38.178391],[-81.37021,38.157641],[-81.357326,38.175989],[-81.375278,38.198955],[-81.375696,38.202835],[-81.377855,38.208638],[-81.392358,38.210488],[-81.419948,38.202871],[-81.405012,38.178391]]]},"properties":{"id":"25103","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.374588,38.199975],[-81.375278,38.198955],[-81.354785,38.185708],[-81.317303,38.181418],[-81.374588,38.199975]]]},"properties":{"id":"25156","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.351686,38.352834],[-81.393452,38.281905],[-81.301403,38.272595],[-81.310458,38.32627],[-81.351686,38.352834]]]},"properties":{"id":"25132","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.624993,39.142096],[-80.620634,39.023662],[-80.670636,39.000402],[-80.655033,38.950435],[-80.547185,38.957876],[-80.43656,38.973803],[-80.338285,39.018191],[-80.326721,39.075342],[-80.596126,39.167069],[-80.624993,39.142096]]]},"properties":{"id":"26452","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.700169,37.472627],[-81.651218,37.446119],[-81.603846,37.456431],[-81.573419,37.489577],[-81.609189,37.509967],[-81.656283,37.52921],[-81.690365,37.503768],[-81.700169,37.472627]]]},"properties":{"id":"24828","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.732262,37.294521],[-81.736173,37.267973],[-81.663929,37.274007],[-81.667525,37.281691],[-81.732262,37.294521]]]},"properties":{"id":"24894","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.19373,39.562437],[-80.111557,39.530358],[-80.091978,39.519642],[-80.080944,39.550943],[-80.124744,39.605265],[-80.161052,39.637831],[-80.197893,39.610839],[-80.174593,39.58828],[-80.195361,39.563236],[-80.19373,39.562437]],[[-80.152932,39.548814],[-80.150106,39.544178],[-80.143191,39.544808],[-80.143928,39.539133],[-80.152044,39.541685],[-80.152932,39.548814]]]},"properties":{"id":"26588","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.982027,38.989061],[-81.974172,38.992435],[-81.999861,39.026278],[-81.99715,39.014324],[-82.015865,39.004818],[-81.982027,38.989061]]]},"properties":{"id":"25247","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.050228,38.996047],[-82.025245,38.988096],[-82.023281,39.006553],[-81.99715,39.014324],[-81.999861,39.026278],[-82.050228,38.996047]]]},"properties":{"id":"25260","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.979003,37.570179],[-81.017854,37.536655],[-80.914537,37.466988],[-80.859457,37.429491],[-80.85868,37.428908],[-80.843724,37.512177],[-80.884096,37.539879],[-80.911739,37.61269],[-80.917673,37.611406],[-80.979003,37.570179]]]},"properties":{"id":"25979","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.299912,37.350432],[-81.34123,37.373377],[-81.362156,37.337687],[-81.325337,37.304206],[-81.315873,37.312423],[-81.301652,37.327689],[-81.304746,37.330007],[-81.299912,37.350432]]]},"properties":{"id":"24715","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.188284,37.613621],[-81.220623,37.644798],[-81.242579,37.571774],[-81.277303,37.541116],[-81.277476,37.540625],[-81.222121,37.510416],[-81.21375,37.509401],[-81.147207,37.554397],[-81.169714,37.586709],[-81.188284,37.613621]]]},"properties":{"id":"25902","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.005195,37.449184],[-81.0371,37.494485],[-81.061581,37.477679],[-81.005195,37.449184]]]},"properties":{"id":"24712","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.777052,39.627721],[-78.773512,39.605487],[-78.733574,39.613775],[-78.748894,39.62656],[-78.777052,39.627721]]]},"properties":{"id":"26767","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.0958,37.604565],[-82.073844,37.624684],[-82.171678,37.643178],[-82.145723,37.640004],[-82.137596,37.639476],[-82.099008,37.613514],[-82.0958,37.604565]]]},"properties":{"id":"25692","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.175973,37.637964],[-82.145723,37.640004],[-82.171678,37.643178],[-82.173871,37.643651],[-82.175927,37.644639],[-82.175973,37.637964]]],[[[-82.137596,37.639476],[-82.12865,37.61361],[-82.099008,37.613514],[-82.137596,37.639476]]]]},"properties":{"id":"25688","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.95564,39.458569],[-79.945005,39.454719],[-79.903901,39.39185],[-79.867299,39.390279],[-79.827832,39.479415],[-79.825289,39.482607],[-79.840775,39.502235],[-79.86715,39.523518],[-79.95564,39.458569]]]},"properties":{"id":"26374","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.582474,39.568178],[-80.482273,39.588346],[-80.4493,39.597633],[-80.528104,39.615542],[-80.596869,39.622485],[-80.582474,39.568178]]],[[[-80.348504,39.721366],[-80.421388,39.721189],[-80.418321,39.642078],[-80.36673,39.631778],[-80.348504,39.721366]]]]},"properties":{"id":"26562","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.710043,37.458316],[-80.631855,37.432233],[-80.565714,37.466936],[-80.65826,37.529744],[-80.697107,37.490916],[-80.710043,37.458316]]]},"properties":{"id":"24951","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.865953,38.130654],[-80.714351,38.14549],[-80.71351,38.174009],[-80.788873,38.220055],[-80.900515,38.200719],[-80.870222,38.157],[-80.865953,38.130654]],[[-80.853912,38.162127],[-80.837859,38.169616],[-80.827816,38.157985],[-80.855427,38.152914],[-80.853912,38.162127]]]},"properties":{"id":"26679","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.264212,38.899924],[-79.170784,38.744528],[-79.111181,38.797778],[-79.134296,38.81334],[-79.248021,38.889494],[-79.264212,38.899924]]]},"properties":{"id":"26866","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.512292,39.217656],[-79.487175,39.194906],[-79.486875,39.205896],[-79.486473,39.318122],[-79.558335,39.231799],[-79.512292,39.217656]]]},"properties":{"id":"26716","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.826993,38.453194],[-81.801766,38.439029],[-81.754303,38.455751],[-81.755652,38.581769],[-81.799688,38.584948],[-81.840164,38.516811],[-81.842008,38.502045],[-81.826993,38.453194]]]},"properties":{"id":"25159","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.164251,37.760546],[-81.167709,37.759583],[-81.168956,37.757619],[-81.167678,37.756738],[-81.165778,37.759758],[-81.164251,37.760546]]]},"properties":{"id":"25911","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.407457,37.796725],[-81.350898,37.773436],[-81.329407,37.767023],[-81.321799,37.769903],[-81.371219,37.829053],[-81.372486,37.862073],[-81.400796,37.83271],[-81.416395,37.801362],[-81.414649,37.798218],[-81.407457,37.796725]]]},"properties":{"id":"25844","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.209959,37.741181],[-81.284951,37.762165],[-81.290441,37.760211],[-81.292343,37.759337],[-81.283331,37.735676],[-81.273342,37.72875],[-81.250757,37.722386],[-81.214524,37.734552],[-81.209966,37.740641],[-81.209959,37.741181]]]},"properties":{"id":"25827","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.100674,37.832767],[-81.095395,37.842784],[-81.133279,37.847212],[-81.100674,37.832767]]]},"properties":{"id":"25906","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.497009,37.950109],[-81.421011,37.898],[-81.418271,37.892241],[-81.390278,37.887178],[-81.368808,37.933689],[-81.37283,37.971309],[-81.380829,37.969109],[-81.456632,37.987307],[-81.497009,37.950109]],[[-81.43473,37.941417],[-81.438238,37.943241],[-81.437511,37.943741],[-81.43473,37.941417]]]},"properties":{"id":"25060","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.965863,38.786421],[-79.924982,38.761334],[-79.899314,38.791108],[-79.913026,38.785706],[-79.942625,38.810459],[-79.965863,38.786421]]]},"properties":{"id":"26293","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.940335,39.132558],[-80.980614,39.098167],[-80.938628,39.096762],[-80.847999,39.128356],[-80.920856,39.151862],[-80.940335,39.132558]]]},"properties":{"id":"26327","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.21552,39.04348],[-81.162455,39.030608],[-81.135991,39.045057],[-81.131485,39.080192],[-81.197283,39.12156],[-81.237739,39.094798],[-81.21552,39.04348]]]},"properties":{"id":"26148","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.709685,37.643136],[-80.725431,37.682814],[-80.739611,37.664052],[-80.709685,37.643136]]]},"properties":{"id":"24962","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.948902,39.603483],[-81.121533,39.457775],[-81.111552,39.460604],[-80.982361,39.488482],[-80.885267,39.536041],[-80.916244,39.592842],[-80.948902,39.603483]]]},"properties":{"id":"26175","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.213936,38.819543],[-80.124729,38.750243],[-80.117448,38.774941],[-80.105007,38.875375],[-80.20842,38.901619],[-80.213936,38.819543]]]},"properties":{"id":"26237","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.388132,38.14071],[-82.276861,38.14089],[-82.303919,38.296931],[-82.329715,38.298234],[-82.398496,38.170051],[-82.388132,38.14071]]]},"properties":{"id":"25512","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.494365,38.275241],[-82.344172,38.305352],[-82.454342,38.335927],[-82.494365,38.275241]]]},"properties":{"id":"25535","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.462711,39.334063],[-81.546843,39.296544],[-81.510173,39.204956],[-81.507928,39.203907],[-81.425154,39.241471],[-81.40966,39.272852],[-81.426223,39.331892],[-81.462711,39.334063]]]},"properties":{"id":"26104","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.425154,39.241471],[-81.507928,39.203907],[-81.442105,39.182515],[-81.425154,39.241471]]]},"properties":{"id":"26142","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.808278,37.609253],[-81.733529,37.608362],[-81.708537,37.622505],[-81.79413,37.669459],[-81.808278,37.609253]]]},"properties":{"id":"24882","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.368044,37.549768],[-81.326102,37.536218],[-81.277476,37.540625],[-81.277303,37.541116],[-81.321589,37.597551],[-81.328912,37.608266],[-81.356086,37.5713],[-81.368044,37.549768]]]},"properties":{"id":"25928","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.491987,37.687666],[-81.531144,37.718173],[-81.555438,37.702483],[-81.518381,37.675898],[-81.491987,37.687666]]]},"properties":{"id":"25845","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.419464,37.737752],[-81.427713,37.749257],[-81.458431,37.72731],[-81.483769,37.691259],[-81.421862,37.697759],[-81.396525,37.705695],[-81.419464,37.737752]]]},"properties":{"id":"25848","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.583175,37.752657],[-81.539357,37.783404],[-81.565794,37.767642],[-81.583175,37.752657]]]},"properties":{"id":"24854","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.607532,37.788709],[-81.748119,37.761988],[-81.712249,37.699085],[-81.694876,37.679806],[-81.670534,37.697535],[-81.583175,37.752657],[-81.565794,37.767642],[-81.607532,37.788709]]]},"properties":{"id":"24827","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.227173,39.112792],[-80.086085,39.089803],[-80.224843,39.142696],[-80.227173,39.112792]]]},"properties":{"id":"26238","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.118088,39.237985],[-80.117527,39.23253],[-80.141241,39.23253],[-80.143659,39.238856],[-80.129153,39.239876],[-80.047362,39.246077],[-80.146549,39.34077],[-80.171659,39.284316],[-80.170631,39.247314],[-80.100881,39.192966],[-80.118088,39.237985]],[[-80.086957,39.268532],[-80.087929,39.262392],[-80.09747,39.266757],[-80.094093,39.267714],[-80.088778,39.266463],[-80.086957,39.268532]]]},"properties":{"id":"26347","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.20385,39.196349],[-80.100881,39.192966],[-80.170631,39.247314],[-80.187648,39.267143],[-80.171659,39.284316],[-80.146549,39.34077],[-80.159471,39.374795],[-80.192858,39.393074],[-80.304714,39.355188],[-80.294414,39.317198],[-80.281556,39.259572],[-80.288939,39.244685],[-80.306841,39.238684],[-80.20385,39.196349]]]},"properties":{"id":"26330","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.029652,39.262414],[-77.949575,39.253491],[-77.949576,39.254884],[-77.922808,39.303941],[-77.901761,39.318532],[-77.849694,39.354231],[-77.870093,39.398377],[-77.875233,39.424475],[-77.885254,39.41694],[-77.962954,39.367357],[-77.988462,39.319777],[-78.033191,39.264612],[-78.029652,39.262414]]]},"properties":{"id":"25430","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.839007,38.099779],[-81.803458,38.0834],[-81.710668,38.050684],[-81.722799,38.066246],[-81.752475,38.106493],[-81.757583,38.109332],[-81.839007,38.099779]]]},"properties":{"id":"25081","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.721775,37.915481],[-81.620009,37.897653],[-81.668573,37.936372],[-81.701865,37.940491],[-81.724954,37.932058],[-81.721775,37.915481]]]},"properties":{"id":"25021","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.032221,38.134222],[-82.070449,38.13064],[-82.054702,38.108708],[-81.959386,38.093348],[-81.871554,38.113753],[-81.888565,38.165842],[-82.032221,38.134222]]]},"properties":{"id":"25565","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.604114,38.788213],[-80.682053,38.763836],[-80.669952,38.724203],[-80.527574,38.743214],[-80.604114,38.788213]]]},"properties":{"id":"26627","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.635657,40.201942],[-80.660711,40.211797],[-80.664683,40.212394],[-80.681811,40.185563],[-80.635657,40.201942]]]},"properties":{"id":"26075","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.113438,38.339876],[-82.045037,38.322358],[-82.055127,38.474547],[-82.115002,38.348182],[-82.113438,38.339876]]]},"properties":{"id":"25510","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.412266,38.436983],[-82.397585,38.423445],[-82.29908,38.422292],[-82.27375,38.448591],[-82.280528,38.468402],[-82.316164,38.462319],[-82.412266,38.436983]]]},"properties":{"id":"25702","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.226504,38.94072],[-81.154669,38.929791],[-81.162692,38.945524],[-81.225724,38.953775],[-81.226504,38.94072]]],[[[-81.237815,38.868602],[-81.111718,38.815743],[-81.124168,38.858019],[-81.16133,38.893133],[-81.238945,38.880245],[-81.237815,38.868602]]]]},"properties":{"id":"26151","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.226504,38.94072],[-81.324681,38.964591],[-81.347442,38.896922],[-81.238945,38.880245],[-81.16133,38.893133],[-81.154669,38.929791],[-81.226504,38.94072]]]},"properties":{"id":"26141","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.08371,38.611982],[-81.061639,38.635686],[-81.005498,38.641284],[-81.005493,38.69594],[-81.022128,38.713481],[-81.171307,38.714739],[-81.154128,38.644304],[-81.08371,38.611982]]]},"properties":{"id":"25235","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.008558,38.900403],[-81.00486,38.960583],[-81.060617,38.968974],[-81.162692,38.945524],[-81.154669,38.929791],[-81.16133,38.893133],[-81.124168,38.858019],[-81.067834,38.840146],[-81.034454,38.862452],[-81.008558,38.900403]]]},"properties":{"id":"26147","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.394504,38.546794],[-81.422198,38.340132],[-81.386493,38.328567],[-81.351686,38.352834],[-81.310458,38.32627],[-81.24862,38.268986],[-81.231434,38.263716],[-81.249188,38.35002],[-81.247457,38.382248],[-81.233363,38.503922],[-81.276973,38.539071],[-81.26191,38.574712],[-81.261114,38.576463],[-81.281053,38.603048],[-81.308396,38.612349],[-81.394504,38.546794]]]},"properties":{"id":"25045","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.061639,38.635686],[-81.030322,38.60446],[-81.005498,38.641284],[-81.061639,38.635686]]]},"properties":{"id":"25141","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.013444,38.203781],[-81.023824,38.101411],[-81.06432,38.071416],[-81.055541,38.062187],[-81.030345,38.072767],[-80.937268,38.107147],[-80.908878,38.130776],[-80.944073,38.193702],[-81.013444,38.203781]]]},"properties":{"id":"25854","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.267148,37.887151],[-81.261598,37.932831],[-81.295328,37.939697],[-81.300467,37.929851],[-81.295182,37.889368],[-81.267148,37.887151]]]},"properties":{"id":"25904","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.258853,38.078898],[-81.261696,38.043655],[-81.231898,38.050867],[-81.258853,38.078898]]]},"properties":{"id":"25152","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.233344,38.145411],[-81.217091,38.13708],[-81.215211,38.136621],[-81.205829,38.160176],[-81.233344,38.145411]]]},"properties":{"id":"25090","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.166407,37.913417],[-81.119016,37.879132],[-81.156277,37.928983],[-81.157828,37.929923],[-81.166407,37.913417]]]},"properties":{"id":"25846","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.030345,38.072767],[-81.055541,38.062187],[-81.037119,38.049932],[-80.99652,38.056316],[-81.030345,38.072767]]]},"properties":{"id":"25837","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.878762,38.87356],[-80.829993,38.804324],[-80.812061,38.814793],[-80.777701,38.834782],[-80.786389,38.836346],[-80.878762,38.87356]]]},"properties":{"id":"26611","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.292566,39.298913],[-79.333247,39.248828],[-79.352009,39.208206],[-79.295506,39.188904],[-79.259129,39.139815],[-79.154569,39.283863],[-79.271695,39.327999],[-79.292566,39.298913]]]},"properties":{"id":"26739","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.17512,39.029239],[-79.344926,38.967738],[-79.357666,38.964509],[-79.349867,38.957509],[-79.279973,38.910142],[-79.264212,38.899924],[-79.248021,38.889494],[-79.17512,39.029239]]]},"properties":{"id":"26855","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.100402,39.189335],[-79.01675,39.174848],[-78.992043,39.220982],[-78.858058,39.313249],[-78.791405,39.412585],[-78.833672,39.441557],[-78.999115,39.358879],[-79.102228,39.19422],[-79.100402,39.189335]]]},"properties":{"id":"26710","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.648057,39.33854],[-78.72525,39.216768],[-78.733971,39.205463],[-78.660633,39.203346],[-78.580818,39.199819],[-78.499864,39.335049],[-78.554693,39.404251],[-78.591856,39.398038],[-78.648057,39.33854]]]},"properties":{"id":"26704","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.531215,39.163345],[-78.508132,39.08863],[-78.427705,39.185975],[-78.487483,39.162954],[-78.531215,39.163345]]]},"properties":{"id":"26823","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.42464,39.298489],[-78.416495,39.261336],[-78.407823,39.270454],[-78.415906,39.301915],[-78.42464,39.298489]]],[[[-78.427705,39.185975],[-78.415587,39.255608],[-78.457398,39.227721],[-78.487483,39.162954],[-78.427705,39.185975]]]]},"properties":{"id":"26808","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.523729,39.470291],[-78.548626,39.468625],[-78.610194,39.440044],[-78.621013,39.43055],[-78.591856,39.398038],[-78.554693,39.404251],[-78.523729,39.470291]]]},"properties":{"id":"25437","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.523729,39.470291],[-78.492652,39.363966],[-78.417491,39.402991],[-78.35229,39.39981],[-78.345603,39.456732],[-78.434143,39.553034],[-78.523342,39.524832],[-78.548626,39.468625],[-78.523729,39.470291]]]},"properties":{"id":"25434","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.581529,40.609561],[-80.638211,40.544641],[-80.602579,40.45169],[-80.518406,40.468426],[-80.518906,40.564294],[-80.581529,40.609561]],[[-80.579888,40.531964],[-80.57812,40.535073],[-80.571649,40.529427],[-80.580141,40.529981],[-80.579888,40.531964]]]},"properties":{"id":"26047","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.006223,39.112824],[-78.883007,39.194176],[-78.992043,39.220982],[-79.01675,39.174848],[-79.036187,39.134174],[-79.006223,39.112824]]]},"properties":{"id":"26845","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.916707,38.975229],[-78.994837,38.849509],[-78.73868,38.927362],[-78.804474,38.953893],[-78.916707,38.975229]]]},"properties":{"id":"26812","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.281556,39.259572],[-80.298692,39.257135],[-80.288939,39.244685],[-80.281556,39.259572]]]},"properties":{"id":"26323","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439648,39.372892],[-80.332639,39.376227],[-80.342459,39.380382],[-80.399718,39.431453],[-80.451581,39.450787],[-80.439648,39.372892]]]},"properties":{"id":"26386","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.297104,39.428272],[-80.259408,39.424646],[-80.265124,39.427485],[-80.297104,39.428272]]]},"properties":{"id":"26568","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.868554,38.884844],[-81.864266,38.881259],[-81.804194,38.894395],[-81.840321,38.934109],[-81.868554,38.884844]]]},"properties":{"id":"25262","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.864266,38.881259],[-81.868554,38.884844],[-81.908645,38.87846],[-81.917424,38.866426],[-81.908721,38.826877],[-81.864266,38.881259]]]},"properties":{"id":"25264","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.70928,38.650071],[-81.706578,38.618011],[-81.522166,38.612746],[-81.536239,38.713435],[-81.587233,38.717592],[-81.665646,38.689803],[-81.70928,38.650071]]]},"properties":{"id":"25248","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.835488,39.325917],[-77.860894,39.153427],[-77.860203,39.152981],[-77.806049,39.171737],[-77.737521,39.396287],[-77.808819,39.397553],[-77.835189,39.327108],[-77.835488,39.325917]],[[-77.792263,39.300197],[-77.789088,39.308423],[-77.781588,39.30402],[-77.784086,39.294444],[-77.792263,39.300197]]]},"properties":{"id":"25425","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.4467,38.262373],[-81.572368,38.269575],[-81.572379,38.261735],[-81.525454,38.21381],[-81.458988,38.209261],[-81.437807,38.221522],[-81.4467,38.262373]]]},"properties":{"id":"25015","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.60996,38.337592],[-81.613206,38.340821],[-81.61646,38.336478],[-81.611046,38.335255],[-81.60996,38.337592]]]},"properties":{"id":"25305","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.378208,38.209165],[-81.416149,38.211291],[-81.429587,38.215101],[-81.419948,38.202871],[-81.392358,38.210488],[-81.378208,38.209165]]]},"properties":{"id":"25086","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.781928,38.394443],[-81.801766,38.439029],[-81.826993,38.453194],[-81.842891,38.445255],[-81.850797,38.421932],[-81.78945,38.381101],[-81.781928,38.394443]]]},"properties":{"id":"25143","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.437807,38.221522],[-81.429587,38.215101],[-81.416149,38.211291],[-81.297807,38.259857],[-81.301403,38.272595],[-81.393452,38.281905],[-81.427172,38.266427],[-81.4467,38.262373],[-81.437807,38.221522]]]},"properties":{"id":"25039","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.374588,38.199975],[-81.375696,38.202835],[-81.375278,38.198955],[-81.374588,38.199975]]]},"properties":{"id":"25126","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.493406,38.333013],[-81.489653,38.333669],[-81.48893,38.338995],[-81.493406,38.333013]]]},"properties":{"id":"25201","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.725741,39.064114],[-80.670636,39.000402],[-80.620634,39.023662],[-80.701313,39.090325],[-80.708421,39.091714],[-80.725741,39.064114]]]},"properties":{"id":"26321","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.959386,38.093348],[-82.054702,38.108708],[-82.194425,38.045177],[-82.202,38.032972],[-82.274507,37.972778],[-82.306391,37.94364],[-82.191913,37.959027],[-82.120161,37.892392],[-82.112845,37.894178],[-82.05713,38.011308],[-82.023317,38.01815],[-81.905482,38.029448],[-81.959386,38.093348]]]},"properties":{"id":"25524","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.11222,37.859418],[-82.04751,37.835928],[-82.047064,37.83525],[-82.047242,37.858482],[-82.112845,37.894178],[-82.120161,37.892392],[-82.11222,37.859418]]]},"properties":{"id":"25649","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.962824,37.808352],[-81.910577,37.810257],[-81.839957,37.788393],[-81.793593,37.798585],[-81.856231,37.865964],[-81.877682,37.86104],[-81.973923,37.84395],[-81.987587,37.827883],[-81.97785,37.817559],[-81.962824,37.808352]]]},"properties":{"id":"25646","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.97234,37.916437],[-81.990827,37.892342],[-81.969358,37.91457],[-81.97234,37.916437]]]},"properties":{"id":"25624","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.566998,37.391912],[-81.454597,37.477819],[-81.452071,37.477254],[-81.421715,37.490251],[-81.464092,37.539185],[-81.573419,37.489577],[-81.603846,37.456431],[-81.663897,37.41316],[-81.706043,37.40688],[-81.676994,37.372586],[-81.628197,37.351056],[-81.62033,37.339513],[-81.566998,37.391912]]]},"properties":{"id":"24801","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.497263,37.349856],[-81.524669,37.30262],[-81.478239,37.299761],[-81.497263,37.349856]]]},"properties":{"id":"24830","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.390762,37.389396],[-81.418217,37.384509],[-81.419019,37.370774],[-81.387231,37.361368],[-81.374386,37.371703],[-81.390762,37.389396]]]},"properties":{"id":"24887","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.753235,37.343008],[-81.676994,37.372586],[-81.706043,37.40688],[-81.725801,37.376494],[-81.732645,37.375586],[-81.761149,37.350769],[-81.7627,37.349098],[-81.753235,37.343008]]]},"properties":{"id":"24813","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.929926,37.366622],[-81.879656,37.331921],[-81.834058,37.348385],[-81.84813,37.391434],[-81.882546,37.40343],[-81.929926,37.366622]]]},"properties":{"id":"24873","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.195361,39.563236],[-80.174593,39.58828],[-80.197893,39.610839],[-80.195361,39.563236]]]},"properties":{"id":"26574","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.152932,39.548814],[-80.152044,39.541685],[-80.143928,39.539133],[-80.143191,39.544808],[-80.150106,39.544178],[-80.152932,39.548814]]]},"properties":{"id":"26560","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.262391,39.499736],[-80.267927,39.48695],[-80.259327,39.481799],[-80.246646,39.496338],[-80.262391,39.499736]]]},"properties":{"id":"26576","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.957338,38.656644],[-81.834418,38.599978],[-81.816398,38.597934],[-81.809845,38.676074],[-81.792129,38.73413],[-81.800536,38.743914],[-81.853223,38.805568],[-81.908721,38.826877],[-81.917424,38.866426],[-82.016352,38.848821],[-82.03424,38.793604],[-81.955204,38.734628],[-81.957338,38.656644]]]},"properties":{"id":"25123","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.046395,37.503603],[-81.147207,37.554397],[-81.21375,37.509401],[-81.123122,37.46996],[-81.0736,37.479872],[-81.061581,37.477679],[-81.0371,37.494485],[-81.046395,37.503603]]]},"properties":{"id":"25820","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.0736,37.479872],[-81.123122,37.46996],[-81.121786,37.444671],[-81.121057,37.439927],[-81.0736,37.479872]]]},"properties":{"id":"25922","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.300758,37.840722],[-82.205399,37.797995],[-82.122636,37.804615],[-82.145314,37.836967],[-82.263471,37.857309],[-82.300758,37.840722]]]},"properties":{"id":"25676","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.994075,37.632448],[-82.042321,37.548225],[-81.966127,37.544059],[-81.921697,37.573811],[-81.916969,37.604904],[-81.994075,37.632448]]]},"properties":{"id":"25651","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.760971,37.613895],[-80.756675,37.600419],[-80.689727,37.589643],[-80.688361,37.592113],[-80.709685,37.643136],[-80.739611,37.664052],[-80.725431,37.682814],[-80.755962,37.669912],[-80.760971,37.613895]]]},"properties":{"id":"24981","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.347036,39.465979],[-78.228766,39.391233],[-78.08568,39.671181],[-78.28274,39.62065],[-78.347036,39.465979]]]},"properties":{"id":"25411","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.875551,38.439146],[-81.017992,38.36271],[-81.040789,38.353282],[-81.096176,38.286927],[-81.06511,38.211807],[-81.013444,38.203781],[-80.944073,38.193702],[-80.900515,38.200719],[-80.788873,38.220055],[-80.783646,38.231451],[-80.74583,38.291642],[-80.714934,38.300953],[-80.698272,38.337442],[-80.71368,38.377482],[-80.647682,38.43927],[-80.672634,38.493406],[-80.842907,38.461221],[-80.875551,38.439146]]]},"properties":{"id":"26651","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.496536,38.299397],[-80.358001,38.258757],[-80.274012,38.370987],[-80.273923,38.371017],[-80.396904,38.349086],[-80.612582,38.418828],[-80.641101,38.383764],[-80.614029,38.299969],[-80.496536,38.299397]]]},"properties":{"id":"26208","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.842907,38.461221],[-80.672634,38.493406],[-80.625991,38.510567],[-80.650786,38.52659],[-80.685665,38.532096],[-80.72213,38.533982],[-80.785295,38.557587],[-80.83555,38.53116],[-80.835918,38.465973],[-80.842907,38.461221]]]},"properties":{"id":"26610","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.585222,40.174595],[-80.585745,40.146048],[-80.584322,40.171228],[-80.582223,40.173867],[-80.585222,40.174595]]]},"properties":{"id":"26074","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.170784,38.744528],[-79.341668,38.542196],[-79.221005,38.481722],[-79.057253,38.761413],[-79.111181,38.797778],[-79.170784,38.744528]]]},"properties":{"id":"26802","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.521788,38.534323],[-79.476396,38.457159],[-79.341668,38.542196],[-79.170784,38.744528],[-79.264212,38.899924],[-79.279973,38.910142],[-79.370629,38.750186],[-79.431995,38.6834],[-79.521788,38.534323]]]},"properties":{"id":"26807","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.116319,38.473851],[-80.245518,38.388457],[-80.273923,38.371017],[-80.274012,38.370987],[-80.224917,38.332229],[-80.222397,38.331136],[-80.064326,38.417673],[-80.028576,38.45917],[-80.116319,38.473851]]]},"properties":{"id":"26291","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.773654,38.532282],[-79.72619,38.678983],[-79.810183,38.680212],[-79.926327,38.515841],[-79.930683,38.513429],[-79.953062,38.412208],[-79.871589,38.453354],[-79.773654,38.532282]]]},"properties":{"id":"26264","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.72619,38.678983],[-79.773654,38.532282],[-79.698988,38.474988],[-79.649075,38.591515],[-79.626774,38.664214],[-79.655456,38.687473],[-79.72619,38.678983]]]},"properties":{"id":"24920","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.687662,39.426817],[-79.785372,39.269703],[-79.802563,39.233045],[-79.684595,39.270583],[-79.601285,39.364406],[-79.683837,39.424084],[-79.687662,39.426817]]]},"properties":{"id":"26425","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.798659,39.466344],[-79.827832,39.479415],[-79.867299,39.390279],[-79.855753,39.338456],[-79.791378,39.452087],[-79.798659,39.466344]]]},"properties":{"id":"26410","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.283331,37.735676],[-81.284622,37.735622],[-81.276857,37.723121],[-81.273342,37.72875],[-81.283331,37.735676]]]},"properties":{"id":"25849","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.314046,37.656483],[-81.316158,37.612849],[-81.316899,37.606768],[-81.309474,37.64022],[-81.314046,37.656483]]]},"properties":{"id":"25853","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.227864,37.659433],[-81.234984,37.697823],[-81.293114,37.676363],[-81.227864,37.659433]]]},"properties":{"id":"25908","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.295182,37.889368],[-81.300467,37.929851],[-81.368808,37.933689],[-81.390278,37.887178],[-81.372486,37.862073],[-81.371219,37.829053],[-81.295182,37.889368]]]},"properties":{"id":"25044","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.197711,37.858609],[-81.187732,37.858986],[-81.193662,37.869161],[-81.197711,37.858609]]]},"properties":{"id":"25818","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.899314,38.791108],[-79.897019,38.798025],[-79.91468,38.804596],[-79.913026,38.785706],[-79.899314,38.791108]]]},"properties":{"id":"26259","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.124729,38.750243],[-80.213936,38.819543],[-80.202466,38.7445],[-80.235959,38.743776],[-80.133072,38.715773],[-80.124729,38.750243]]]},"properties":{"id":"26224","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.897754,39.05646],[-80.81297,39.109401],[-80.813792,39.110849],[-80.847999,39.128356],[-80.938628,39.096762],[-80.897754,39.05646]]]},"properties":{"id":"26325","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.48545,38.758277],[-81.371367,38.665728],[-81.339109,38.668355],[-81.171222,38.714723],[-81.203806,38.774514],[-81.237815,38.868602],[-81.238945,38.880245],[-81.347442,38.896922],[-81.376105,38.90718],[-81.481707,38.79505],[-81.48545,38.758277]]]},"properties":{"id":"25276","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.394504,38.546794],[-81.308396,38.612349],[-81.339109,38.668355],[-81.371367,38.665728],[-81.521914,38.612251],[-81.508412,38.584525],[-81.394504,38.546794]]]},"properties":{"id":"25286","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.285663,38.628885],[-81.261114,38.576463],[-81.26191,38.574712],[-81.210986,38.582263],[-81.211565,38.580983],[-81.197625,38.67948],[-81.285663,38.628885]]]},"properties":{"id":"25251","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.92728,37.810204],[-80.900955,37.781024],[-80.889847,37.797262],[-80.92728,37.810204]]]},"properties":{"id":"25977","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.576748,39.14759],[-79.641187,39.078095],[-79.537156,39.099448],[-79.532028,39.105087],[-79.570186,39.150163],[-79.576748,39.14759]]]},"properties":{"id":"26269","state_code":"54"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.81558,43.570684],[-89.659529,43.567414],[-89.599985,43.642596],[-89.615621,43.673412],[-89.71705,43.783614],[-89.827715,43.825573],[-89.911225,43.818274],[-89.913192,43.818251],[-89.849755,43.762889],[-89.917322,43.592501],[-89.81558,43.570684]]]},"properties":{"id":"53965","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.42035,44.155852],[-89.65749,44.213849],[-89.67776,44.213826],[-89.698282,44.213643],[-89.777522,44.140902],[-89.778007,44.111952],[-89.717633,44.08624],[-89.405588,44.054561],[-89.42035,44.155852]]]},"properties":{"id":"54943","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.65749,44.213849],[-89.42035,44.155852],[-89.376462,44.17389],[-89.457143,44.305661],[-89.65749,44.213849]]]},"properties":{"id":"54966","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.88352,46.114002],[-90.55196,46.262305],[-90.54898,46.32932],[-90.62651,46.49201],[-90.737722,46.457582],[-90.695486,46.414334],[-90.68293,46.34217],[-90.821946,46.343662],[-90.86551,46.25785],[-90.88352,46.114002]]]},"properties":{"id":"54546","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.341173,46.414459],[-90.311321,46.533243],[-90.548185,46.587048],[-90.62651,46.49201],[-90.54898,46.32932],[-90.383212,46.41364],[-90.341173,46.414459]]]},"properties":{"id":"54559","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.972888,45.28433],[-91.972562,45.309067],[-92.084452,45.209233],[-92.08532,45.194177],[-92.091452,45.185344],[-92.034095,45.173432],[-91.891149,45.231524],[-91.972888,45.28433]]]},"properties":{"id":"54762","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.296449,45.325756],[-91.368988,45.333939],[-91.443866,45.291686],[-91.788821,45.221801],[-91.810053,45.208174],[-91.722964,45.143218],[-91.666402,45.120823],[-91.318157,45.190816],[-91.319573,45.23574],[-91.296449,45.325756]],[[-91.684608,45.170026],[-91.684929,45.16795],[-91.686707,45.170263],[-91.684608,45.170026]]]},"properties":{"id":"54757","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.786122,45.60637],[-91.772573,45.602921],[-91.775143,45.61025],[-91.786122,45.60637]]]},"properties":{"id":"54841","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.418664,46.244722],[-91.424597,46.156774],[-91.035947,46.154894],[-90.92475,46.243523],[-90.925593,46.300254],[-91.089852,46.30875],[-91.184026,46.301083],[-91.418664,46.244722]]]},"properties":{"id":"54821","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.931853,44.534724],[-88.004777,44.539457],[-88.011303,44.521692],[-88.006744,44.475109],[-87.931853,44.534724]]]},"properties":{"id":"54302","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.793714,44.638212],[-87.80581,44.501761],[-87.785697,44.458347],[-87.704666,44.407234],[-87.617331,44.515568],[-87.651118,44.686441],[-87.718328,44.704406],[-87.793714,44.638212]]]},"properties":{"id":"54217","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.518876,45.81833],[-92.527602,45.902119],[-92.552234,45.951585],[-92.754434,45.556698],[-92.672356,45.643244],[-92.615955,45.643036],[-92.59541,45.689251],[-92.528198,45.72868],[-92.518876,45.81833]]]},"properties":{"id":"54840","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.292995,46.332161],[-92.552234,45.951585],[-92.527602,45.902119],[-92.104281,45.9581],[-92.023803,46.009939],[-92.050408,46.071176],[-92.049636,46.157597],[-92.050268,46.332551],[-92.292995,46.332161]]]},"properties":{"id":"54830","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.154275,44.212459],[-88.158108,44.212461],[-88.154329,44.209747],[-88.154275,44.212459]]],[[[-88.154275,44.212459],[-88.142292,44.206919],[-88.132214,44.212214],[-88.154275,44.212459]]]]},"properties":{"id":"54123","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.560739,43.395006],[-89.422674,43.352398],[-89.285521,43.310486],[-89.246631,43.311084],[-89.363725,43.455528],[-89.41578,43.504466],[-89.555452,43.399336],[-89.560739,43.395006]]]},"properties":{"id":"53955","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.81558,43.570684],[-89.916836,43.511066],[-89.856822,43.489477],[-89.76851,43.390722],[-89.718827,43.391097],[-89.582076,43.475856],[-89.659529,43.567414],[-89.81558,43.570684]]]},"properties":{"id":"53913","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.075528,43.330186],[-90.954961,43.287723],[-90.945883,43.3212],[-90.925085,43.324818],[-90.896896,43.35808],[-90.947455,43.423899],[-91.057765,43.450241],[-91.141255,43.367196],[-91.075528,43.330186]]]},"properties":{"id":"54628","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.945883,43.3212],[-90.933224,43.317584],[-90.925085,43.324818],[-90.945883,43.3212]]]},"properties":{"id":"54645","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.097769,42.952317],[-88.98434,42.919832],[-88.934364,43.015562],[-88.98801,43.055496],[-89.011037,43.057284],[-89.121217,42.992285],[-89.097769,42.952317]]]},"properties":{"id":"53523","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.530765,43.060384],[-89.502755,43.060698],[-89.492521,43.082669],[-89.53731,43.060306],[-89.530765,43.060384]]]},"properties":{"id":"53717","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.934808,42.846674],[-90.050532,42.79577],[-89.997885,42.775418],[-89.755483,42.770391],[-89.747147,42.782303],[-89.720218,42.857489],[-89.838185,42.855624],[-89.934808,42.846674]]]},"properties":{"id":"53516","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.479961,43.54399],[-88.449082,43.44254],[-88.401033,43.442841],[-88.400608,43.536911],[-88.479961,43.54399]]]},"properties":{"id":"53091","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.083943,45.144625],[-87.153179,45.130068],[-87.234872,45.066077],[-87.191941,44.985664],[-87.17481,44.986424],[-87.083943,45.144625]]]},"properties":{"id":"54202","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.1915,45.156361],[-87.276073,45.077543],[-87.234872,45.066077],[-87.153179,45.130068],[-87.153216,45.141877],[-87.1915,45.156361]]]},"properties":{"id":"54212","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.156594,45.165221],[-92.136275,45.002679],[-92.093008,45.002531],[-92.091452,45.185344],[-92.08532,45.194177],[-92.156594,45.165221]]]},"properties":{"id":"54734","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.973331,45.159556],[-92.034095,45.173432],[-92.091452,45.185344],[-92.093008,45.002531],[-92.014879,44.960354],[-91.894353,44.984025],[-91.894492,45.003627],[-91.973331,45.159556]]]},"properties":{"id":"54725","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.155527,44.977104],[-92.136033,44.897691],[-92.014879,44.960354],[-92.093008,45.002531],[-92.136275,45.002679],[-92.155527,44.977104]]]},"properties":{"id":"54749","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.684608,45.170026],[-91.686707,45.170263],[-91.684929,45.16795],[-91.684608,45.170026]]]},"properties":{"id":"54765","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.160379,43.586708],[-88.301151,43.529024],[-88.321874,43.477973],[-88.063495,43.527285],[-88.055527,43.542364],[-88.109669,43.58618],[-88.160379,43.586708]]]},"properties":{"id":"53040","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.544222,43.814038],[-88.613907,43.894492],[-88.630359,43.894626],[-88.660784,43.869448],[-88.700135,43.831825],[-88.606172,43.76355],[-88.544222,43.814038]]]},"properties":{"id":"54932","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.860647,43.74423],[-88.86262,43.7351],[-88.845464,43.735054],[-88.845453,43.740736],[-88.860647,43.74423]]]},"properties":{"id":"53931","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.306175,45.366718],[-88.059893,45.368938],[-88.11747,45.462967],[-88.150198,45.54442],[-88.313077,45.57126],[-88.426085,45.548139],[-88.425991,45.491903],[-88.428102,45.37701],[-88.306175,45.366718]]]},"properties":{"id":"54104","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.406595,42.55792],[-90.661424,42.567566],[-90.362757,42.507084],[-90.367919,42.51391],[-90.406595,42.55792]]]},"properties":{"id":"53811","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.477638,42.944329],[-90.546712,42.880968],[-90.530342,42.864052],[-90.427362,42.849533],[-90.351541,42.892102],[-90.329627,42.915483],[-90.477638,42.944329]]]},"properties":{"id":"53554","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.982265,42.938636],[-90.95245,42.938263],[-90.952607,42.952622],[-90.982265,42.938636]]]},"properties":{"id":"53817","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.837587,42.50571],[-89.734177,42.505253],[-89.808221,42.621079],[-89.855888,42.610724],[-89.837587,42.50571]]]},"properties":{"id":"53522","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.12776,43.770245],[-89.25177,43.86813],[-89.36528,43.93762],[-89.48267,43.81672],[-89.47688,43.78856],[-89.434484,43.768748],[-89.481919,43.758666],[-89.48293,43.75907],[-89.409213,43.642677],[-89.369447,43.642627],[-89.2964,43.67204],[-89.271881,43.730575],[-89.12776,43.770245]]]},"properties":{"id":"53949","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.956308,42.956861],[-89.956065,42.925956],[-89.83838,42.915325],[-89.838485,42.923717],[-89.897635,43.112779],[-89.942875,43.074591],[-89.956308,42.956861]]]},"properties":{"id":"53507","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.33776,46.23769],[-90.363112,46.155022],[-90.35262,46.154924],[-90.303072,46.154849],[-90.255502,46.276329],[-90.299287,46.341459],[-90.33776,46.23769]]],[[[-90.237415,46.290593],[-90.120572,46.337039],[-90.311321,46.533243],[-90.341173,46.414459],[-90.29859,46.40787],[-90.22729,46.43222],[-90.22152,46.42849],[-90.20625,46.42807],[-90.217144,46.418982],[-90.237415,46.290593]]]]},"properties":{"id":"54534","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.972764,44.070775],[-90.947547,44.147332],[-91.034267,44.272009],[-91.112819,44.190461],[-91.151843,44.079656],[-91.151839,44.079278],[-90.972764,44.070775]]]},"properties":{"id":"54642","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.357727,44.278909],[-90.317893,44.259861],[-90.31766,44.291998],[-90.357727,44.278909]]]},"properties":{"id":"54641","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.178768,43.722056],[-90.122758,43.656422],[-90.03099,43.647876],[-89.849755,43.762889],[-89.913192,43.818251],[-89.979051,43.905612],[-90.000127,43.905832],[-90.208966,43.81852],[-90.178768,43.722056]]]},"properties":{"id":"53948","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.159287,42.639142],[-88.178097,42.55659],[-88.157404,42.561186],[-88.142038,42.552295],[-88.170379,42.541412],[-88.079162,42.529107],[-88.07934,42.611949],[-88.099715,42.626286],[-88.159287,42.639142]]]},"properties":{"id":"53168","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.225474,42.74614],[-88.253733,42.770341],[-88.406433,42.756351],[-88.413611,42.645915],[-88.34851,42.573825],[-88.280728,42.541043],[-88.188335,42.522074],[-88.175391,42.524995],[-88.170379,42.541412],[-88.178097,42.55659],[-88.159287,42.639142],[-88.145332,42.733583],[-88.22216,42.743331],[-88.222154,42.735459],[-88.22944,42.738522],[-88.225474,42.74614]]]},"properties":{"id":"53105","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.031038,43.862723],[-91.136424,43.870034],[-91.209859,43.867144],[-91.257841,43.818235],[-91.200736,43.738992],[-91.130422,43.740965],[-91.03121,43.81237],[-91.031038,43.862723]]]},"properties":{"id":"54601","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.03121,43.81237],[-90.941992,43.764788],[-90.910189,43.916374],[-90.916518,44.049285],[-91.032133,43.975967],[-91.031038,43.862723],[-91.03121,43.81237]]]},"properties":{"id":"54614","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.88689,45.117639],[-88.88419,45.117637],[-88.887368,45.130149],[-88.88689,45.117639]]],[[[-88.887368,45.130149],[-88.866058,45.169039],[-88.90747,45.168157],[-88.887368,45.130149]]]]},"properties":{"id":"54430","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.979947,45.380558],[-89.776334,45.323229],[-89.548537,45.465036],[-89.487334,45.535562],[-89.641327,45.569642],[-89.757845,45.672832],[-89.925219,45.712128],[-89.979947,45.380558]]]},"properties":{"id":"54487","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.932525,44.00805],[-87.922364,43.996089],[-87.922398,44.01526],[-87.932525,44.00805]]]},"properties":{"id":"54232","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.721292,44.202024],[-87.712612,44.19328],[-87.707053,44.199428],[-87.721292,44.202024]]]},"properties":{"id":"54214","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.392394,45.118941],[-89.663132,45.027383],[-89.654239,45.028379],[-89.642017,45.020892],[-89.632822,44.918668],[-89.473521,44.930394],[-89.437441,45.00192],[-89.345603,45.08779],[-89.392394,45.118941]]]},"properties":{"id":"54403","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.25521,44.871053],[-89.34633,44.746236],[-89.144903,44.886972],[-89.25521,44.871053]]]},"properties":{"id":"54427","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.885339,45.032803],[-89.865589,44.819522],[-89.727373,44.85777],[-89.79836,45.032563],[-89.885339,45.032803]]]},"properties":{"id":"54448","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.059893,45.368938],[-87.842392,45.257082],[-87.70998,45.24854],[-87.834765,45.452286],[-88.11747,45.462967],[-88.059893,45.368938]]]},"properties":{"id":"54177","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.843065,45.135027],[-87.656594,44.971254],[-87.740921,45.20002],[-87.843065,45.135027]]]},"properties":{"id":"54143","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.574925,45.039223],[-88.735704,44.940998],[-88.735591,44.921653],[-88.736194,44.856376],[-88.540147,44.855735],[-88.515572,44.902576],[-88.574925,45.039223]]]},"properties":{"id":"54135","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.9692,42.926896],[-87.949414,42.929536],[-87.91789,42.930179],[-87.919228,42.98161],[-87.96748,42.98118],[-87.968955,42.9401],[-87.9692,42.926896]]]},"properties":{"id":"53221","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.881392,42.930032],[-87.839971,42.893159],[-87.838962,42.930213],[-87.881392,42.930032]]]},"properties":{"id":"53172","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.016432,43.031805],[-87.982388,43.036613],[-87.988053,43.060704],[-88.007444,43.068045],[-88.017452,43.067402],[-88.016432,43.031805]]]},"properties":{"id":"53213","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.902376,43.025129],[-87.889266,43.025621],[-87.872693,43.057719],[-87.893645,43.060166],[-87.911528,43.048645],[-87.910809,43.032064],[-87.902376,43.025129]]]},"properties":{"id":"53202","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.757845,45.672832],[-89.641327,45.569642],[-89.575081,45.703489],[-89.655221,45.761136],[-89.757845,45.672832]]]},"properties":{"id":"54529","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.818093,44.502622],[-88.912552,44.416873],[-88.860169,44.294771],[-88.739055,44.289338],[-88.615522,44.388964],[-88.636136,44.502462],[-88.818093,44.502622]]]},"properties":{"id":"54961","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.298679,44.249138],[-88.293259,44.274866],[-88.321806,44.277787],[-88.324182,44.260907],[-88.298679,44.249138]]]},"properties":{"id":"54113","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.355345,44.27693],[-88.324182,44.260907],[-88.321806,44.277787],[-88.324506,44.279387],[-88.355345,44.27693]]]},"properties":{"id":"54136","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.43407,44.193581],[-88.464314,44.193883],[-88.466429,44.226027],[-88.557424,44.243709],[-88.592535,44.271194],[-88.624128,44.243683],[-88.584278,44.170235],[-88.52092,44.112415],[-88.469551,44.117794],[-88.43407,44.193581]]]},"properties":{"id":"54956","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.920444,43.378541],[-87.86295,43.381054],[-87.804149,43.45643],[-87.920532,43.455844],[-87.940255,43.419818],[-87.920444,43.378541]]]},"properties":{"id":"53074","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.466614,44.734689],[-92.467305,44.733554],[-92.463544,44.733762],[-92.465988,44.735624],[-92.466614,44.734689]]]},"properties":{"id":"54010","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.284261,45.504721],[-92.367286,45.497953],[-92.468381,45.489602],[-92.463631,45.409278],[-92.459965,45.397978],[-92.299313,45.432508],[-92.262035,45.467751],[-92.284261,45.504721]]]},"properties":{"id":"54810","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.672356,45.643244],[-92.610663,45.540744],[-92.615955,45.643036],[-92.672356,45.643244]]]},"properties":{"id":"54006","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.506958,45.246012],[-92.610473,45.224795],[-92.467607,45.180335],[-92.447495,45.234407],[-92.506958,45.246012]]]},"properties":{"id":"54026","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.518522,44.497267],[-89.625549,44.452764],[-89.607246,44.359291],[-89.406002,44.363311],[-89.406508,44.38488],[-89.428536,44.422051],[-89.518522,44.497267]]]},"properties":{"id":"54467","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.864591,44.511705],[-89.803002,44.423562],[-89.726331,44.447702],[-89.747115,44.525779],[-89.844545,44.526016],[-89.864591,44.511705]]]},"properties":{"id":"54475","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.223745,44.681365],[-89.223531,44.592082],[-89.238598,44.503948],[-89.090247,44.486153],[-89.036564,44.590717],[-89.077106,44.61615],[-89.184526,44.681003],[-89.223745,44.681365]]]},"properties":{"id":"54945","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246836,44.262291],[-89.351331,44.155821],[-89.136523,44.105086],[-89.087449,44.148597],[-89.103511,44.221039],[-89.246836,44.262291]]]},"properties":{"id":"54984","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.836911,42.688047],[-87.805194,42.72665],[-87.836025,42.784108],[-87.836911,42.688047]]]},"properties":{"id":"53405","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.253733,42.770341],[-88.225474,42.74614],[-88.22216,42.743331],[-88.145332,42.733583],[-88.111108,42.757249],[-88.115044,42.814901],[-88.11457,42.822217],[-88.188222,42.842838],[-88.228837,42.84193],[-88.306927,42.821574],[-88.253733,42.770341]]]},"properties":{"id":"53185","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.058597,42.60218],[-89.072085,42.613586],[-89.070658,42.608921],[-89.058597,42.60218]]]},"properties":{"id":"53501","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.197505,45.592318],[-91.218435,45.346474],[-91.193847,45.345174],[-91.02833,45.42272],[-90.960816,45.554939],[-90.8697,45.631132],[-91.02925,45.6389],[-91.197505,45.592318]]]},"properties":{"id":"54848","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.730185,45.429584],[-90.819483,45.638465],[-90.8697,45.631132],[-90.960816,45.554939],[-90.931171,45.431586],[-90.730185,45.429584]]]},"properties":{"id":"54526","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.193847,45.345174],[-91.038398,45.291961],[-90.98041,45.38662],[-91.02833,45.42272],[-91.193847,45.345174]]]},"properties":{"id":"54731","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.801789,45.05692],[-92.700663,45.066173],[-92.745751,45.123314],[-92.801789,45.05692]]]},"properties":{"id":"54082","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.258371,44.893099],[-92.196399,44.973782],[-92.298659,45.033282],[-92.298501,44.859305],[-92.258371,44.893099]]]},"properties":{"id":"54028","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.916836,43.511066],[-89.81558,43.570684],[-89.917322,43.592501],[-90.028061,43.641622],[-90.137703,43.467575],[-89.99406,43.446601],[-89.916836,43.511066]]]},"properties":{"id":"53959","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.773753,44.729553],[-88.605447,44.653734],[-88.515472,44.677824],[-88.487318,44.801844],[-88.480403,44.855364],[-88.540147,44.855735],[-88.736194,44.856376],[-88.868426,44.796561],[-88.797929,44.759639],[-88.773753,44.729553]]]},"properties":{"id":"54166","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.109669,43.58618],[-87.941358,43.615856],[-87.956169,43.630907],[-88.160035,43.617277],[-88.160379,43.586708],[-88.109669,43.58618]]]},"properties":{"id":"53001","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.487819,44.157908],[-91.558933,44.140793],[-91.540399,44.088211],[-91.487819,44.157908]]]},"properties":{"id":"54625","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.07954,46.13352],[-89.180277,46.111918],[-89.181526,45.982398],[-88.933573,45.990835],[-88.932563,46.073589],[-89.07954,46.13352]]]},"properties":{"id":"54554","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.554689,46.227881],[-89.929158,46.299748],[-89.928944,46.192122],[-89.755393,46.159054],[-89.616916,46.160436],[-89.554689,46.227881]]]},"properties":{"id":"54557","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.53285,42.563697],[-88.540847,42.600207],[-88.57054,42.582698],[-88.566627,42.574619],[-88.53285,42.563697]]]},"properties":{"id":"53191","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.023803,46.009939],[-91.743054,45.888185],[-91.798872,46.033058],[-92.050408,46.071176],[-92.023803,46.009939]]]},"properties":{"id":"54888","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.338113,43.192932],[-88.262188,43.18008],[-88.183135,43.192594],[-88.162017,43.192268],[-88.182183,43.221621],[-88.310884,43.220396],[-88.338113,43.192932]]]},"properties":{"id":"53017","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.183519,43.160519],[-88.147945,43.155641],[-88.184089,43.139025],[-88.185671,43.104704],[-88.16518,43.10463],[-88.125553,43.10466],[-88.077457,43.104709],[-88.065576,43.119095],[-88.065085,43.133544],[-88.063353,43.192117],[-88.162017,43.192268],[-88.183135,43.192594],[-88.183519,43.160519]]]},"properties":{"id":"53051","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.416828,42.945129],[-88.424197,42.915124],[-88.385632,42.945739],[-88.405244,42.952192],[-88.416828,42.945129]]]},"properties":{"id":"53153","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.066725,43.067199],[-88.066143,43.098409],[-88.077457,43.104709],[-88.125553,43.10466],[-88.128188,43.016862],[-88.12697,43.016885],[-88.067252,43.016399],[-88.066989,43.029651],[-88.066916,43.036513],[-88.106825,43.035928],[-88.104861,43.059968],[-88.066917,43.059409],[-88.066725,43.067199]]]},"properties":{"id":"53005","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.36753,42.991684],[-88.366568,43.017971],[-88.393747,43.01164],[-88.393935,42.995478],[-88.390892,42.98512],[-88.36753,42.991684]]]},"properties":{"id":"53183","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.183519,43.160519],[-88.184089,43.139025],[-88.147945,43.155641],[-88.183519,43.160519]]]},"properties":{"id":"53046","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.23199,43.061225],[-88.230487,43.060036],[-88.228714,43.0617],[-88.231223,43.061808],[-88.23199,43.061225]]]},"properties":{"id":"53702","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.607246,44.359291],[-89.67776,44.213826],[-89.65749,44.213849],[-89.457143,44.305661],[-89.406002,44.363311],[-89.607246,44.359291]]]},"properties":{"id":"54921","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.849974,44.345407],[-89.698282,44.213643],[-89.67776,44.213826],[-89.607246,44.359291],[-89.625549,44.452764],[-89.726331,44.447702],[-89.803002,44.423562],[-89.858668,44.36569],[-89.849974,44.345407]]]},"properties":{"id":"54494","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.737722,46.457582],[-90.90456,46.42094],[-90.925593,46.300254],[-90.92475,46.243523],[-90.86551,46.25785],[-90.821946,46.343662],[-90.695486,46.414334],[-90.737722,46.457582]]]},"properties":{"id":"54855","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.652861,46.608639],[-90.648868,46.635641],[-90.687715,46.620984],[-90.652861,46.608639]]]},"properties":{"id":"54861","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.891149,45.231524],[-92.034095,45.173432],[-91.973331,45.159556],[-91.773641,45.121329],[-91.722964,45.143218],[-91.810053,45.208174],[-91.891149,45.231524]]]},"properties":{"id":"54763","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.154888,45.639742],[-91.90816,45.610089],[-91.875676,45.638839],[-91.908912,45.674053],[-92.155238,45.706533],[-92.154888,45.639742]]]},"properties":{"id":"54813","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.551395,46.755816],[-91.238434,46.704951],[-91.177455,46.701018],[-91.1381,46.877364],[-91.551306,46.7581],[-91.551444,46.755674],[-91.551395,46.755816]]]},"properties":{"id":"54844","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.082912,46.753523],[-91.048776,46.630731],[-90.936819,46.611867],[-90.869988,46.763347],[-91.029403,46.760509],[-91.082912,46.753523]]]},"properties":{"id":"54891","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.509122,46.748293],[-91.4478,46.460034],[-91.30393,46.578712],[-91.287121,46.684705],[-91.509122,46.748293]]]},"properties":{"id":"54847","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.125422,44.241088],[-88.00254,44.24089],[-87.888037,44.268384],[-87.887999,44.284034],[-87.948313,44.327885],[-88.146345,44.335621],[-88.172103,44.313289],[-88.125422,44.241088]]]},"properties":{"id":"54126","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.131098,44.676928],[-88.216197,44.589992],[-88.189941,44.574455],[-88.149944,44.487664],[-88.102353,44.474129],[-88.109517,44.523605],[-88.031435,44.563246],[-88.014804,44.596092],[-88.067188,44.677388],[-88.131098,44.676928]]]},"properties":{"id":"54313","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.033337,44.467831],[-88.031925,44.469854],[-88.039591,44.472794],[-88.041731,44.470864],[-88.033337,44.467831]]]},"properties":{"id":"54307","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.319573,45.23574],[-91.027381,45.162682],[-90.941037,45.236827],[-91.038398,45.291961],[-91.193847,45.345174],[-91.218435,45.346474],[-91.296449,45.325756],[-91.319573,45.23574]]]},"properties":{"id":"54745","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.286728,44.99501],[-91.267085,44.84674],[-91.125516,44.857046],[-91.034126,44.857249],[-91.085712,45.031092],[-91.206787,45.08847],[-91.286728,44.99501]]]},"properties":{"id":"54727","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.206787,45.08847],[-91.296169,45.134431],[-91.318245,45.052582],[-91.286728,44.99501],[-91.206787,45.08847]]]},"properties":{"id":"54748","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.681797,45.119023],[-90.719222,45.031293],[-90.703651,44.915108],[-90.641878,44.885872],[-90.557098,45.074635],[-90.679255,45.159139],[-90.681797,45.119023]]]},"properties":{"id":"54498","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.921732,44.71245],[-90.872342,44.595563],[-90.804399,44.593528],[-90.690168,44.698293],[-90.788436,44.779571],[-90.921437,44.759042],[-90.921732,44.71245]]]},"properties":{"id":"54493","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.41751,43.294111],[-89.39895,43.275731],[-89.363022,43.275511],[-89.344491,43.281174],[-89.285521,43.310486],[-89.422674,43.352398],[-89.41751,43.294111]]]},"properties":{"id":"53911","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.879581,43.073558],[-91.037004,43.128225],[-91.155859,43.143268],[-91.131235,42.999513],[-90.982256,42.938492],[-90.982265,42.938636],[-90.952607,42.952622],[-90.922755,42.952639],[-90.940588,43.039781],[-90.879581,43.073558]]]},"properties":{"id":"53821","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.397376,43.074902],[-89.408995,43.07012],[-89.404028,43.056739],[-89.377865,43.055823],[-89.389408,43.063917],[-89.397376,43.074902]]]},"properties":{"id":"53715","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.284599,43.182448],[-89.235648,43.107382],[-89.152907,43.141774],[-89.127022,43.253607],[-89.207598,43.282474],[-89.2316,43.282328],[-89.284599,43.182448]]]},"properties":{"id":"53590","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.153366,43.130572],[-89.011028,43.064268],[-89.058171,43.239574],[-89.127022,43.253607],[-89.152907,43.141774],[-89.153366,43.130572]]]},"properties":{"id":"53559","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.152907,43.141774],[-89.235648,43.107382],[-89.246264,43.051507],[-89.23687,43.014864],[-89.150371,43.006635],[-89.153366,43.130572],[-89.152907,43.141774]]]},"properties":{"id":"53527","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246264,43.051507],[-89.31001,43.045325],[-89.312477,43.042319],[-89.321944,43.035054],[-89.340109,42.98991],[-89.298188,42.961289],[-89.23687,43.014864],[-89.246264,43.051507]]]},"properties":{"id":"53558","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.285628,43.104179],[-89.33429,43.089845],[-89.326199,43.083602],[-89.283781,43.084518],[-89.285628,43.104179]]]},"properties":{"id":"53714","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.561432,43.16924],[-89.44184,43.118837],[-89.413196,43.147046],[-89.362056,43.176734],[-89.45156,43.250271],[-89.561792,43.191047],[-89.561432,43.16924]]]},"properties":{"id":"53597","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.935271,43.285124],[-89.058171,43.239574],[-89.011028,43.064268],[-89.011037,43.057284],[-88.98801,43.055496],[-88.891011,43.159049],[-88.873803,43.197986],[-88.873121,43.225971],[-88.935271,43.285124]]]},"properties":{"id":"53594","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.704842,43.313844],[-88.720409,43.306698],[-88.704649,43.30658],[-88.704842,43.313844]]]},"properties":{"id":"53016","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.126515,43.674357],[-89.046921,43.647326],[-88.926464,43.61921],[-88.896267,43.619296],[-88.845383,43.683855],[-88.885295,43.757124],[-88.956312,43.817372],[-89.067584,43.789057],[-89.12776,43.770245],[-89.271881,43.730575],[-89.12624,43.696576],[-89.126447,43.683219],[-89.136589,43.683099],[-89.126515,43.674357]],[[-89.146585,43.741659],[-89.146815,43.748774],[-89.134081,43.749586],[-89.136657,43.74438],[-89.146585,43.741659]]]},"properties":{"id":"53946","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.941774,45.334414],[-86.872045,45.331824],[-86.837638,45.394173],[-86.927428,45.410469],[-86.941774,45.334414]]]},"properties":{"id":"54246","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.551282,46.157045],[-92.049636,46.157597],[-92.050408,46.071176],[-91.798872,46.033058],[-91.635727,46.066008],[-91.551282,46.157045]]]},"properties":{"id":"54859","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.136216,44.874096],[-92.115474,44.81491],[-92.014355,44.771563],[-91.887172,44.693811],[-91.869179,44.703615],[-91.7437,44.767729],[-91.788187,44.943929],[-91.894353,44.984025],[-92.014879,44.960354],[-92.136033,44.897691],[-92.136216,44.874096]]]},"properties":{"id":"54751","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.256628,44.685353],[-92.135153,44.698864],[-92.032696,44.698462],[-92.014355,44.771563],[-92.115474,44.81491],[-92.317663,44.76641],[-92.322509,44.705016],[-92.256628,44.685353]]]},"properties":{"id":"54740","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.134906,44.586381],[-92.045473,44.509976],[-91.999916,44.683908],[-92.032696,44.698462],[-92.135153,44.698864],[-92.134906,44.586381]]]},"properties":{"id":"54721","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.544222,43.814038],[-88.606172,43.76355],[-88.613667,43.734328],[-88.479325,43.675485],[-88.440913,43.631384],[-88.401041,43.630933],[-88.380569,43.660233],[-88.266194,43.738057],[-88.32162,43.81882],[-88.350207,43.890929],[-88.389131,43.819312],[-88.391499,43.762258],[-88.442676,43.733366],[-88.469697,43.806555],[-88.461623,43.862021],[-88.544222,43.814038]]]},"properties":{"id":"54937","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.613907,43.894492],[-88.544222,43.814038],[-88.461623,43.862021],[-88.46757,43.893415],[-88.583718,43.908907],[-88.613907,43.894492]]]},"properties":{"id":"54979","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.677826,45.378682],[-88.55398,45.377672],[-88.428102,45.37701],[-88.425991,45.491903],[-88.515516,45.483523],[-88.799917,45.475164],[-88.812465,45.434691],[-88.802563,45.37804],[-88.677826,45.378682]]]},"properties":{"id":"54566","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.800152,45.622507],[-89.046781,45.637517],[-89.046688,45.549791],[-89.046315,45.464762],[-88.987675,45.465065],[-88.925214,45.410913],[-88.812465,45.434691],[-88.799917,45.475164],[-88.800152,45.622507]]]},"properties":{"id":"54520","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.812465,45.434691],[-88.925214,45.410913],[-88.994473,45.348849],[-88.926483,45.334332],[-88.802563,45.37804],[-88.812465,45.434691]]]},"properties":{"id":"54465","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.530342,42.864052],[-90.630133,42.769922],[-90.573449,42.656499],[-90.372894,42.704721],[-90.363159,42.806006],[-90.355506,42.813513],[-90.427362,42.849533],[-90.530342,42.864052]]]},"properties":{"id":"53818","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.881325,42.799201],[-90.881563,42.814616],[-90.899761,42.814864],[-90.89308,42.79632],[-90.881325,42.799201]]]},"properties":{"id":"53802","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.808221,42.621079],[-89.734177,42.505253],[-89.522271,42.501972],[-89.485379,42.629832],[-89.486077,42.679118],[-89.486113,42.68277],[-89.728705,42.708664],[-89.808221,42.621079]]]},"properties":{"id":"53566","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.956312,43.817372],[-88.940341,43.895416],[-89.010931,43.89382],[-89.067584,43.789057],[-88.956312,43.817372]]]},"properties":{"id":"54941","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.19182,43.294245],[-90.193814,43.164464],[-90.172141,43.097884],[-90.034424,43.057258],[-90.034841,43.075851],[-89.994771,43.195354],[-89.968827,43.264748],[-90.19182,43.294245]]]},"properties":{"id":"53588","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.427362,42.849533],[-90.355506,42.813513],[-90.324292,42.872026],[-90.351541,42.892102],[-90.427362,42.849533]]]},"properties":{"id":"53580","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.956308,42.956861],[-89.942875,43.074591],[-90.034841,43.075851],[-90.034424,43.057258],[-89.956308,42.956861]]]},"properties":{"id":"53582","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.54898,46.32932],[-90.55196,46.262305],[-90.363112,46.155022],[-90.33776,46.23769],[-90.383212,46.41364],[-90.54898,46.32932]]]},"properties":{"id":"54565","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.22729,46.43222],[-90.217144,46.418982],[-90.20625,46.42807],[-90.22152,46.42849],[-90.22729,46.43222]]]},"properties":{"id":"54525","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.299287,46.341459],[-90.255502,46.276329],[-90.237415,46.290593],[-90.217144,46.418982],[-90.22729,46.43222],[-90.29859,46.40787],[-90.299287,46.341459]]]},"properties":{"id":"54550","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.00883,44.294436],[-91.121329,44.397675],[-91.152532,44.212231],[-91.112819,44.190461],[-91.034267,44.272009],[-91.00883,44.294436]]]},"properties":{"id":"54659","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.641717,44.18916],[-90.641843,44.185435],[-90.634792,44.185858],[-90.641717,44.18916]]]},"properties":{"id":"54643","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.151839,44.079278],[-91.111634,43.970917],[-91.032133,43.975967],[-90.916518,44.049285],[-90.972764,44.070775],[-91.151839,44.079278]]]},"properties":{"id":"54644","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.541955,43.007074],[-88.620402,43.085082],[-88.65862,43.059753],[-88.583884,42.963402],[-88.541955,43.007074]]]},"properties":{"id":"53178","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.651087,42.895374],[-88.576053,42.842695],[-88.541535,42.842996],[-88.542058,42.92704],[-88.584253,42.962697],[-88.612532,42.930658],[-88.651087,42.895374]]]},"properties":{"id":"53156","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.258851,43.828478],[-90.208966,43.81852],[-90.000127,43.905832],[-90.211294,43.981253],[-90.255788,43.878501],[-90.258851,43.828478]]]},"properties":{"id":"53950","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.910189,43.916374],[-90.941992,43.764788],[-90.910882,43.768833],[-90.863102,43.812873],[-90.910189,43.916374]]]},"properties":{"id":"54653","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.958071,42.638991],[-89.955531,42.505673],[-89.837587,42.50571],[-89.855888,42.610724],[-89.865524,42.648994],[-89.864953,42.649745],[-89.958071,42.638991]]]},"properties":{"id":"53587","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59125,45.293911],[-89.425321,45.206075],[-89.332894,45.202681],[-89.29991,45.377534],[-89.302079,45.409367],[-89.301535,45.469351],[-89.364049,45.469886],[-89.487334,45.535562],[-89.548537,45.465036],[-89.59125,45.293911]]]},"properties":{"id":"54435","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.302079,45.409367],[-89.200138,45.379352],[-89.049537,45.378897],[-88.987675,45.465065],[-89.046315,45.464762],[-89.301535,45.469351],[-89.302079,45.409367]]]},"properties":{"id":"54428","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.662079,45.11738],[-88.559339,45.116826],[-88.638493,45.19328],[-88.677826,45.378682],[-88.802563,45.37804],[-88.926483,45.334332],[-88.926785,45.302806],[-88.866058,45.169039],[-88.887368,45.130149],[-88.88419,45.117637],[-88.662079,45.11738]]]},"properties":{"id":"54491","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.856955,44.073521],[-87.821813,44.00807],[-87.681409,43.999961],[-87.620082,44.122451],[-87.711431,44.218485],[-87.771329,44.145139],[-87.856955,44.073521]],[[-87.721292,44.202024],[-87.707053,44.199428],[-87.712612,44.19328],[-87.721292,44.202024]]]},"properties":{"id":"54220","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.489821,44.649492],[-89.34508,44.569283],[-89.223531,44.592082],[-89.223745,44.681365],[-89.345917,44.68138],[-89.49033,44.743802],[-89.489821,44.649492]]]},"properties":{"id":"54473","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.644055,44.867785],[-89.727373,44.85777],[-89.865589,44.819522],[-89.925673,44.771683],[-89.865274,44.713484],[-89.844931,44.684945],[-89.681828,44.68502],[-89.50883,44.648347],[-89.489821,44.649492],[-89.49033,44.743802],[-89.491084,44.844716],[-89.599963,44.858211],[-89.644055,44.867785]]]},"properties":{"id":"54455","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.642017,45.020892],[-89.654239,45.028379],[-89.663132,45.027383],[-89.642017,45.020892]]]},"properties":{"id":"54417","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.924124,44.993021],[-88.163146,45.010105],[-88.204435,45.003765],[-88.285517,44.968871],[-88.245095,44.945892],[-88.082452,44.849872],[-87.924124,44.993021]]]},"properties":{"id":"54139","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.480403,44.855364],[-88.299042,44.853527],[-88.245095,44.945892],[-88.285517,44.968871],[-88.515572,44.902576],[-88.540147,44.855735],[-88.480403,44.855364]]]},"properties":{"id":"54124","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.96748,42.98118],[-87.919228,42.98161],[-87.918698,43.006473],[-87.947783,43.028359],[-87.955245,43.025975],[-87.967707,43.010146],[-87.968034,42.981179],[-87.96748,42.98118]]]},"properties":{"id":"53215","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.028974,42.94993],[-88.028563,42.930509],[-87.9692,42.926896],[-87.968955,42.9401],[-88.018263,42.954217],[-88.028974,42.94993]]]},"properties":{"id":"53129","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.91789,42.930179],[-87.949414,42.929536],[-87.951992,42.843547],[-87.885584,42.842666],[-87.819554,42.842282],[-87.839971,42.893159],[-87.881392,42.930032],[-87.887676,42.930034],[-87.91789,42.930179]]]},"properties":{"id":"53154","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.428102,45.37701],[-88.55398,45.377672],[-88.55911,45.2878],[-88.431789,45.260713],[-88.306175,45.366718],[-88.428102,45.37701]]]},"properties":{"id":"54138","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.190534,44.768056],[-88.262622,44.795718],[-88.273701,44.79556],[-88.333707,44.765637],[-88.181341,44.74588],[-88.18677,44.764823],[-88.190534,44.768056]]]},"properties":{"id":"54137","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.557424,44.243709],[-88.466429,44.226027],[-88.436532,44.244308],[-88.415363,44.25311],[-88.415476,44.298715],[-88.49638,44.273021],[-88.557424,44.243709]]]},"properties":{"id":"54914","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.392548,44.414814],[-88.496442,44.359074],[-88.516645,44.330184],[-88.49638,44.273021],[-88.415476,44.298715],[-88.333135,44.298023],[-88.323031,44.297968],[-88.351963,44.400921],[-88.392548,44.414814]]]},"properties":{"id":"54913","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.941358,43.615856],[-88.109669,43.58618],[-88.055527,43.542364],[-87.953494,43.528205],[-87.901002,43.543406],[-87.920866,43.610898],[-87.941358,43.615856]]]},"properties":{"id":"53075","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.683317,45.210045],[-92.645794,45.078685],[-92.619745,45.03573],[-92.517473,45.021786],[-92.436226,45.064912],[-92.375196,45.071381],[-92.32245,45.136401],[-92.467607,45.180335],[-92.610473,45.224795],[-92.683317,45.210045]]]},"properties":{"id":"54017","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.153676,45.817038],[-90.187189,45.896658],[-90.490625,45.851567],[-90.195789,45.823336],[-90.153676,45.817038]]]},"properties":{"id":"54524","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.678038,45.73578],[-90.678749,45.638264],[-90.664581,45.638331],[-90.554573,45.638137],[-90.4191,45.54916],[-90.167475,45.601411],[-90.1034,45.69397],[-90.195789,45.823336],[-90.490625,45.851567],[-90.678038,45.73578]]]},"properties":{"id":"54555","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.819483,45.638465],[-90.730185,45.429584],[-90.658417,45.377637],[-90.664581,45.638331],[-90.678749,45.638264],[-90.819483,45.638465]]]},"properties":{"id":"54530","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.656676,45.349121],[-90.292009,45.287212],[-90.167,45.38142],[-90.55554,45.377386],[-90.658417,45.377637],[-90.656676,45.349121]]]},"properties":{"id":"54490","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.836041,42.784542],[-87.790328,42.729517],[-87.765897,42.733843],[-87.819554,42.842282],[-87.885584,42.842666],[-87.872549,42.813846],[-87.864158,42.777495],[-87.836041,42.784542]]]},"properties":{"id":"53402","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.385632,42.945739],[-88.424197,42.915124],[-88.449093,42.842623],[-88.306927,42.821574],[-88.228837,42.84193],[-88.224711,42.912126],[-88.385632,42.945739]]]},"properties":{"id":"53149","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.173109,45.855488],[-91.173099,45.823559],[-91.04926,45.725379],[-91.02925,45.6389],[-90.8697,45.631132],[-90.819483,45.638465],[-90.678749,45.638264],[-90.678038,45.73578],[-90.676968,45.981554],[-90.860847,45.980526],[-90.92278,45.981028],[-91.132029,45.898346],[-91.173109,45.855488]]]},"properties":{"id":"54896","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.436226,45.064912],[-92.415472,44.862305],[-92.409169,44.862281],[-92.298501,44.859305],[-92.298659,45.033282],[-92.375196,45.071381],[-92.436226,45.064912]]]},"properties":{"id":"54002","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.496937,44.934861],[-92.415472,44.862305],[-92.436226,45.064912],[-92.517473,45.021786],[-92.496937,44.934861]]]},"properties":{"id":"54015","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.619745,45.03573],[-92.598871,44.927144],[-92.496937,44.934861],[-92.517473,45.021786],[-92.619745,45.03573]]]},"properties":{"id":"54023","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.012114,44.821922],[-89.184526,44.681003],[-89.077106,44.61615],[-88.941089,44.708558],[-88.918799,44.740771],[-88.945267,44.782473],[-89.012114,44.821922]]]},"properties":{"id":"54486","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.918799,44.740771],[-88.941089,44.708558],[-88.853133,44.75857],[-88.918799,44.740771]]]},"properties":{"id":"54928","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.935408,44.568172],[-88.901971,44.534949],[-88.889922,44.534944],[-88.773753,44.729553],[-88.797929,44.759639],[-88.853133,44.75857],[-88.941089,44.708558],[-89.077106,44.61615],[-89.036564,44.590717],[-88.935408,44.568172]]]},"properties":{"id":"54950","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.05243,45.008143],[-89.02943,44.994688],[-89.031638,45.014748],[-89.05243,45.008143]]]},"properties":{"id":"54450","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.981432,44.942841],[-89.062413,44.903528],[-89.012114,44.821922],[-88.945267,44.782473],[-88.889179,44.811459],[-88.828287,44.943161],[-88.854306,44.943028],[-88.981432,44.942841]]]},"properties":{"id":"54416","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.779687,43.759994],[-87.691887,43.760556],[-87.721489,43.892273],[-87.846493,43.862854],[-87.847504,43.848344],[-87.800115,43.761252],[-87.779687,43.759994]]]},"properties":{"id":"53083","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.367047,44.186594],[-91.445755,44.155536],[-91.352076,44.00231],[-91.343234,44.014172],[-91.286491,44.06055],[-91.367047,44.186594]]]},"properties":{"id":"54630","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.566627,42.574619],[-88.526215,42.530906],[-88.53285,42.563697],[-88.566627,42.574619]]]},"properties":{"id":"53125","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.166616,43.017146],[-88.128188,43.016862],[-88.125553,43.10466],[-88.16518,43.10463],[-88.185876,43.075144],[-88.166616,43.017146]]]},"properties":{"id":"53045","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.756877,43.982289],[-88.774652,43.975292],[-88.766092,43.975135],[-88.756877,43.982289]]]},"properties":{"id":"54980","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.911225,43.818274],[-89.727458,43.95241],[-89.658084,43.970466],[-89.717633,44.08624],[-89.778007,44.111952],[-89.961465,43.966333],[-89.979051,43.905612],[-89.913192,43.818251],[-89.911225,43.818274]]]},"properties":{"id":"53934","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.76994,46.79033],[-90.657026,46.788874],[-90.656978,46.84358],[-90.76994,46.79033]]]},"properties":{"id":"54850","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.418496,45.766639],[-91.656961,45.753382],[-91.703218,45.624701],[-91.609574,45.591362],[-91.601454,45.595199],[-91.594533,45.595132],[-91.593332,45.58917],[-91.539486,45.568195],[-91.41947,45.552474],[-91.404785,45.638044],[-91.352824,45.759237],[-91.379087,45.771357],[-91.418496,45.766639]]]},"properties":{"id":"54817","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.386899,44.588388],[-88.216197,44.589992],[-88.131098,44.676928],[-88.181341,44.74588],[-88.333707,44.765637],[-88.366135,44.764165],[-88.446604,44.589184],[-88.386899,44.588388]]]},"properties":{"id":"54162","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.618302,44.334477],[-91.372933,44.29687],[-91.369434,44.327086],[-91.374205,44.428317],[-91.412728,44.456341],[-91.529,44.494257],[-91.677008,44.399271],[-91.700504,44.349364],[-91.618302,44.334477]]]},"properties":{"id":"54747","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.155238,45.706533],[-91.908912,45.674053],[-91.825512,45.766916],[-92.128511,45.815687],[-92.216248,45.790861],[-92.211934,45.773173],[-92.155238,45.706533]]]},"properties":{"id":"54871","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.24799,44.184185],[-88.254459,44.169949],[-88.305522,44.148162],[-88.327667,44.080201],[-88.311952,44.07874],[-88.286486,44.072993],[-88.04979,44.080923],[-88.089115,44.116729],[-88.108809,44.11699],[-88.111589,44.124446],[-88.094558,44.122313],[-88.154329,44.209747],[-88.158108,44.212461],[-88.183696,44.226909],[-88.223214,44.212782],[-88.24799,44.184185]]]},"properties":{"id":"54129","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.311952,44.07874],[-88.311852,44.060304],[-88.286486,44.072993],[-88.311952,44.07874]]]},"properties":{"id":"53088","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.350207,43.890929],[-88.32162,43.81882],[-88.24225,43.849204],[-88.212803,43.891757],[-88.222204,43.935154],[-88.31819,43.954054],[-88.350207,43.890929]]]},"properties":{"id":"53049","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.296169,45.134431],[-91.206787,45.08847],[-91.085712,45.031092],[-91.027436,45.150895],[-91.027381,45.162682],[-91.319573,45.23574],[-91.318157,45.190816],[-91.296169,45.134431]]]},"properties":{"id":"54732","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.458235,44.875521],[-90.495697,45.088852],[-90.557098,45.074635],[-90.641878,44.885872],[-90.536562,44.835311],[-90.495942,44.849796],[-90.456358,44.871461],[-90.458235,44.875521]]]},"properties":{"id":"54460","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.921732,44.71245],[-91.119749,44.596286],[-91.044342,44.509832],[-91.025406,44.516653],[-90.980773,44.517507],[-90.872342,44.595563],[-90.921732,44.71245]]]},"properties":{"id":"54741","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.939426,43.537067],[-88.926464,43.61921],[-89.046921,43.647326],[-89.067108,43.595956],[-89.062372,43.581447],[-89.116244,43.478727],[-88.976391,43.444591],[-88.939426,43.537067]]]},"properties":{"id":"53956","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.409213,43.642677],[-89.56355,43.65014],[-89.599985,43.642596],[-89.659529,43.567414],[-89.582076,43.475856],[-89.555452,43.399336],[-89.41578,43.504466],[-89.369447,43.642627],[-89.409213,43.642677]]]},"properties":{"id":"53901","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.896896,43.35808],[-90.666179,43.291585],[-90.595682,43.343023],[-90.59555,43.350393],[-90.642519,43.416882],[-90.683159,43.431587],[-90.827936,43.438589],[-90.947455,43.423899],[-90.896896,43.35808]]]},"properties":{"id":"54655","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.819689,43.162361],[-90.808856,43.099705],[-90.705984,43.037643],[-90.533803,43.075116],[-90.547467,43.155189],[-90.666179,43.291585],[-90.81965,43.206923],[-90.819689,43.162361]]]},"properties":{"id":"53805","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.595682,43.343023],[-90.666179,43.291585],[-90.547467,43.155189],[-90.595682,43.343023]]]},"properties":{"id":"53518","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.969144,43.255359],[-90.955282,43.264649],[-90.965189,43.275727],[-90.969144,43.255359]]]},"properties":{"id":"54654","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485625,42.906908],[-89.290297,42.859148],[-89.298188,42.961289],[-89.340109,42.98991],[-89.462871,42.979537],[-89.48549,42.948283],[-89.485625,42.906908]]]},"properties":{"id":"53575","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.337942,43.052607],[-89.312477,43.042319],[-89.31001,43.045325],[-89.283781,43.084518],[-89.326199,43.083602],[-89.337942,43.052607]]]},"properties":{"id":"53716","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.620486,43.220747],[-89.838135,43.206057],[-89.837995,43.137273],[-89.838034,43.127629],[-89.661325,43.166129],[-89.620312,43.20586],[-89.620486,43.220747]]]},"properties":{"id":"53560","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.345007,43.19453],[-89.324214,43.186286],[-89.323529,43.223184],[-89.361236,43.215412],[-89.345007,43.19453]]]},"properties":{"id":"53598","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.657235,43.720061],[-88.845383,43.683855],[-88.896267,43.619296],[-88.775895,43.560235],[-88.644616,43.568551],[-88.644567,43.596874],[-88.60162,43.632658],[-88.644159,43.719957],[-88.657235,43.720061]]]},"properties":{"id":"53963","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.701481,43.458891],[-88.626838,43.383332],[-88.617479,43.38223],[-88.561469,43.457814],[-88.644257,43.509323],[-88.701481,43.458891]]]},"properties":{"id":"53032","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.423171,43.075892],[-88.427468,43.105826],[-88.443455,43.114816],[-88.423816,43.12082],[-88.400677,43.153403],[-88.41408,43.194414],[-88.435189,43.22437],[-88.478088,43.251068],[-88.549456,43.239449],[-88.516799,43.212645],[-88.495102,43.201216],[-88.502494,43.19933],[-88.513842,43.207021],[-88.618138,43.107919],[-88.620402,43.085082],[-88.541955,43.007074],[-88.393935,42.995478],[-88.393747,43.01164],[-88.423171,43.075892]]]},"properties":{"id":"53066","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.60162,43.632658],[-88.479325,43.675485],[-88.613667,43.734328],[-88.644159,43.719957],[-88.60162,43.632658]]]},"properties":{"id":"53065","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.873121,43.225971],[-88.821002,43.328967],[-88.821199,43.357982],[-88.906498,43.3578],[-88.935271,43.285124],[-88.873121,43.225971]]]},"properties":{"id":"53579","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.829371,46.690114],[-91.860594,46.535639],[-91.859389,46.535607],[-91.721041,46.560474],[-91.829371,46.690114]]]},"properties":{"id":"54864","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.973331,45.159556],[-91.894492,45.003627],[-91.773641,45.121329],[-91.973331,45.159556]]]},"properties":{"id":"54772","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.034126,44.857249],[-91.125516,44.857046],[-91.29077,44.679933],[-91.119749,44.596286],[-90.921732,44.71245],[-90.921437,44.759042],[-90.983404,44.77068],[-91.034126,44.857249]]]},"properties":{"id":"54722","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.125516,44.857046],[-91.267085,44.84674],[-91.272677,44.823383],[-91.374508,44.810616],[-91.427768,44.700277],[-91.387189,44.683354],[-91.346638,44.679444],[-91.29077,44.679933],[-91.125516,44.857046]]]},"properties":{"id":"54742","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.92538,45.860624],[-88.6755,45.797302],[-88.45583,45.84686],[-88.55937,46.014102],[-88.932563,46.073589],[-88.933573,45.990835],[-88.966766,45.962521],[-88.92538,45.860624]]]},"properties":{"id":"54542","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.32162,43.81882],[-88.266194,43.738057],[-88.220172,43.717868],[-88.212941,43.717777],[-88.24225,43.849204],[-88.32162,43.81882]]]},"properties":{"id":"53057","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.902711,42.795467],[-91.069879,42.77145],[-90.828174,42.660771],[-90.82754,42.774466],[-90.843858,42.783774],[-90.881325,42.799201],[-90.89308,42.79632],[-90.902711,42.795467]]]},"properties":{"id":"53806","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.116961,43.983099],[-89.063528,43.907645],[-89.010931,43.89382],[-88.940341,43.895416],[-88.805275,43.982709],[-88.832778,43.997858],[-88.845434,43.99799],[-88.8458,44.002466],[-88.885822,44.096863],[-88.8857,44.111737],[-88.886191,44.155871],[-88.929048,44.121405],[-88.959142,44.126637],[-88.986213,44.119708],[-89.025049,44.055492],[-89.116961,43.983099]]]},"properties":{"id":"54923","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.003133,42.915351],[-90.260987,42.927286],[-90.324292,42.872026],[-90.355506,42.813513],[-90.363159,42.806006],[-90.249657,42.730415],[-90.050532,42.79577],[-89.934808,42.846674],[-90.003133,42.915351]]]},"properties":{"id":"53565","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.042833,45.887816],[-89.928548,46.068824],[-89.92881,46.112743],[-90.231065,46.082585],[-90.676968,45.981554],[-90.678038,45.73578],[-90.490625,45.851567],[-90.187189,45.896658],[-90.153676,45.817038],[-90.044853,45.817653],[-90.042833,45.887816]]]},"properties":{"id":"54552","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.928548,46.068824],[-89.804313,46.069608],[-89.755393,46.159054],[-89.928944,46.192122],[-89.92881,46.112743],[-89.928548,46.068824]]]},"properties":{"id":"54545","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.848158,43.086861],[-88.894426,43.007622],[-88.660317,42.90189],[-88.651087,42.895374],[-88.612532,42.930658],[-88.744951,43.030198],[-88.848158,43.086861]]]},"properties":{"id":"53549","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.998404,42.729608],[-88.856518,42.710791],[-88.855749,42.844579],[-89.00011,42.847256],[-89.030787,42.784407],[-88.998404,42.729608]]]},"properties":{"id":"53563","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.178768,43.722056],[-90.290935,43.678991],[-90.330136,43.554031],[-90.2521,43.554484],[-90.122758,43.656422],[-90.178768,43.722056]]]},"properties":{"id":"53968","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.534252,43.55282],[-90.539429,43.466352],[-90.360488,43.480155],[-90.330136,43.554031],[-90.290935,43.678991],[-90.401375,43.709013],[-90.478499,43.704156],[-90.535617,43.669302],[-90.534252,43.55282]]]},"properties":{"id":"54634","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.401375,43.709013],[-90.285332,43.846557],[-90.373885,43.871757],[-90.389358,43.87237],[-90.47517,43.776723],[-90.478499,43.704156],[-90.401375,43.709013]]]},"properties":{"id":"54638","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.846964,42.538867],[-87.949839,42.49459],[-87.803218,42.492566],[-87.806961,42.556163],[-87.846964,42.538867]]]},"properties":{"id":"53158","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.894892,42.668949],[-87.869193,42.66857],[-87.876621,42.686424],[-87.894892,42.668949]]],[[[-87.863945,42.668487],[-87.841091,42.664279],[-87.812129,42.626895],[-87.765897,42.733843],[-87.790328,42.729517],[-87.805194,42.72665],[-87.836911,42.688047],[-87.863945,42.668487]]]]},"properties":{"id":"53403","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.159287,42.639142],[-88.099715,42.626286],[-88.111108,42.757249],[-88.145332,42.733583],[-88.159287,42.639142]]]},"properties":{"id":"53139","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.136424,43.870034],[-91.031038,43.862723],[-91.032133,43.975967],[-91.111634,43.970917],[-91.173939,43.938816],[-91.136424,43.870034]]]},"properties":{"id":"54669","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.352076,44.00231],[-91.445755,44.155536],[-91.487819,44.157908],[-91.540399,44.088211],[-91.559004,44.025315],[-91.352076,44.00231]]]},"properties":{"id":"54661","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.345603,45.08779],[-89.177942,45.029383],[-89.05243,45.008143],[-89.031638,45.014748],[-89.02943,44.994688],[-88.981726,45.000124],[-88.958091,45.117985],[-89.144933,45.204902],[-89.332894,45.202681],[-89.425321,45.206075],[-89.392394,45.118941],[-89.345603,45.08779]]]},"properties":{"id":"54409","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59125,45.293911],[-89.776334,45.323229],[-89.979947,45.380558],[-90.042269,45.381417],[-90.045419,45.207938],[-89.747526,45.061675],[-89.663132,45.027383],[-89.392394,45.118941],[-89.425321,45.206075],[-89.59125,45.293911]]]},"properties":{"id":"54452","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.99276,44.095757],[-87.976942,44.074604],[-87.977569,44.091676],[-87.99276,44.095757]]]},"properties":{"id":"54207","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.885339,45.032803],[-90.161936,44.934445],[-90.139311,44.887532],[-89.956609,44.777314],[-89.925673,44.771683],[-89.865589,44.819522],[-89.885339,45.032803]]]},"properties":{"id":"54426","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.644055,44.867785],[-89.599963,44.858211],[-89.624021,44.901468],[-89.644055,44.867785]]]},"properties":{"id":"54474","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.376269,45.04755],[-90.197514,45.03364],[-90.197571,45.091258],[-90.376269,45.04755]]]},"properties":{"id":"54480","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.844931,44.684945],[-89.865274,44.713484],[-89.96455,44.592976],[-89.905036,44.526145],[-89.864591,44.511705],[-89.844545,44.526016],[-89.844931,44.684945]]]},"properties":{"id":"54454","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.006475,43.09721],[-87.966991,43.09685],[-87.965978,43.133932],[-88.015615,43.134106],[-88.015654,43.134105],[-88.017395,43.09709],[-88.006475,43.09721]]]},"properties":{"id":"53218","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.946396,43.089789],[-87.946768,43.075221],[-87.946267,43.060585],[-87.919873,43.063944],[-87.919598,43.089325],[-87.946396,43.089789]]]},"properties":{"id":"53206","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.910809,43.032064],[-87.918206,43.029512],[-87.947783,43.028359],[-87.918698,43.006473],[-87.902376,43.025129],[-87.910809,43.032064]]]},"properties":{"id":"53204","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.015654,43.134105],[-88.065085,43.133544],[-88.065576,43.119095],[-88.066025,43.104221],[-88.017395,43.09709],[-88.015654,43.134105]]]},"properties":{"id":"53225","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.549399,43.870822],[-90.573192,43.805215],[-90.47517,43.776723],[-90.389358,43.87237],[-90.549399,43.870822]]]},"properties":{"id":"54670","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.114092,44.835669],[-87.949999,44.779027],[-87.872245,44.833456],[-87.762639,44.962968],[-87.902925,44.99323],[-87.924124,44.993021],[-88.082452,44.849872],[-88.114092,44.835669]]]},"properties":{"id":"54153","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.559339,45.116826],[-88.368933,45.114658],[-88.279961,45.20061],[-88.431789,45.260713],[-88.55911,45.2878],[-88.638493,45.19328],[-88.559339,45.116826]]]},"properties":{"id":"54149","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.204435,45.003765],[-88.163146,45.010105],[-88.186266,45.024799],[-88.202788,45.025201],[-88.204435,45.003765]]],[[[-88.245095,44.945892],[-88.299042,44.853527],[-88.270384,44.7994],[-88.262622,44.795718],[-88.190534,44.768056],[-88.114092,44.835669],[-88.082452,44.849872],[-88.245095,44.945892]]]]},"properties":{"id":"54154","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.673597,45.813582],[-89.655221,45.761136],[-89.575081,45.703489],[-89.474597,45.82654],[-89.482171,45.879652],[-89.601902,45.884362],[-89.673437,45.836849],[-89.673597,45.813582]]]},"properties":{"id":"54539","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.804313,46.069608],[-89.757317,45.913748],[-89.673437,45.836849],[-89.601902,45.884362],[-89.570223,45.982319],[-89.616424,45.98521],[-89.804313,46.069608]]]},"properties":{"id":"54568","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.040857,43.459528],[-87.940255,43.419818],[-87.920532,43.455844],[-87.953494,43.528205],[-88.055527,43.542364],[-88.063495,43.527285],[-88.040857,43.459528]]]},"properties":{"id":"53021","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.37563,44.645188],[-92.375649,44.55874],[-92.316169,44.541009],[-92.200575,44.568467],[-92.256628,44.685353],[-92.322509,44.705016],[-92.37563,44.645188]]]},"properties":{"id":"54750","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497172,44.630202],[-92.497224,44.566952],[-92.375649,44.55874],[-92.37563,44.645188],[-92.497172,44.630202]]]},"properties":{"id":"54723","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.299313,45.432508],[-92.459965,45.397978],[-92.510409,45.382062],[-92.500218,45.309912],[-92.506958,45.246012],[-92.447495,45.234407],[-92.356631,45.238139],[-92.285688,45.302704],[-92.228704,45.380977],[-92.299313,45.432508]]]},"properties":{"id":"54001","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.457143,44.305661],[-89.376462,44.17389],[-89.351331,44.155821],[-89.246836,44.262291],[-89.244316,44.334357],[-89.406508,44.38488],[-89.406002,44.363311],[-89.457143,44.305661]]]},"properties":{"id":"54909","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.311337,44.494159],[-89.299441,44.483319],[-89.299373,44.497291],[-89.311337,44.494159]]]},"properties":{"id":"54458","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.22216,42.743331],[-88.225474,42.74614],[-88.22944,42.738522],[-88.222154,42.735459],[-88.22216,42.743331]]]},"properties":{"id":"53167","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.406433,42.756351],[-88.253733,42.770341],[-88.306927,42.821574],[-88.449093,42.842623],[-88.541535,42.842996],[-88.553821,42.825258],[-88.55772,42.800062],[-88.406433,42.756351]]]},"properties":{"id":"53120","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.360488,43.480155],[-90.539429,43.466352],[-90.642519,43.416882],[-90.59555,43.350393],[-90.330989,43.234231],[-90.229325,43.293097],[-90.191872,43.391756],[-90.360488,43.480155]]]},"properties":{"id":"53581","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.19182,43.294245],[-90.191806,43.295858],[-90.229325,43.293097],[-90.330989,43.234231],[-90.331048,43.209044],[-90.193814,43.164464],[-90.19182,43.294245]],[[-90.291489,43.234911],[-90.272276,43.238832],[-90.272044,43.222284],[-90.291554,43.223263],[-90.291489,43.234911]]]},"properties":{"id":"53556","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.191806,43.295858],[-90.068844,43.346636],[-90.123977,43.453348],[-90.191788,43.457984],[-90.191872,43.391756],[-90.229325,43.293097],[-90.191806,43.295858]]]},"properties":{"id":"53937","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.926664,42.582879],[-89.07132,42.613591],[-89.072085,42.613586],[-89.058597,42.60218],[-89.129929,42.620716],[-89.247989,42.599829],[-89.262669,42.49819],[-88.979149,42.495765],[-88.926664,42.582879]]]},"properties":{"id":"53511","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.815491,42.624612],[-88.77653,42.520908],[-88.678243,42.579273],[-88.776683,42.652746],[-88.815491,42.624612]]]},"properties":{"id":"53114","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.352824,45.759237],[-91.351581,45.812794],[-91.375855,45.782251],[-91.379087,45.771357],[-91.352824,45.759237]]],[[[-91.404785,45.638044],[-91.197505,45.592318],[-91.02925,45.6389],[-91.04926,45.725379],[-91.173906,45.725604],[-91.296616,45.761182],[-91.352824,45.759237],[-91.404785,45.638044]]]]},"properties":{"id":"54835","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.764767,44.905431],[-92.598871,44.927144],[-92.619745,45.03573],[-92.645794,45.078685],[-92.700663,45.066173],[-92.801789,45.05692],[-92.764767,44.905431]]]},"properties":{"id":"54016","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.262622,44.795718],[-88.270384,44.7994],[-88.273701,44.79556],[-88.262622,44.795718]]]},"properties":{"id":"54127","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.367022,46.115828],[-89.181526,45.982398],[-89.180277,46.111918],[-89.367022,46.115828]]]},"properties":{"id":"54519","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.481826,42.511819],[-88.481931,42.516585],[-88.501002,42.516547],[-88.497602,42.512271],[-88.481826,42.511819]]]},"properties":{"id":"53195","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.55407,45.929115],[-91.635727,46.066008],[-91.798872,46.033058],[-91.743054,45.888185],[-91.661686,45.838957],[-91.55407,45.929115]]]},"properties":{"id":"54875","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.344908,43.049264],[-88.205843,43.065452],[-88.185876,43.075144],[-88.16518,43.10463],[-88.185671,43.104704],[-88.308025,43.119545],[-88.363397,43.061689],[-88.344908,43.049264]],[[-88.23199,43.061225],[-88.231223,43.061808],[-88.228714,43.0617],[-88.230487,43.060036],[-88.23199,43.061225]]]},"properties":{"id":"53072","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.249358,43.002962],[-88.186984,42.999787],[-88.166616,43.017146],[-88.185876,43.075144],[-88.205843,43.065452],[-88.249358,43.002962]]]},"properties":{"id":"53186","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.186984,42.999787],[-88.249358,43.002962],[-88.36753,42.991684],[-88.390892,42.98512],[-88.405244,42.952192],[-88.385632,42.945739],[-88.224711,42.912126],[-88.188994,42.92738],[-88.186984,42.999787]]]},"properties":{"id":"53189","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.427468,43.105826],[-88.423816,43.12082],[-88.443455,43.114816],[-88.427468,43.105826]]]},"properties":{"id":"53069","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.712586,44.664867],[-88.690366,44.672741],[-88.707181,44.678913],[-88.712586,44.664867]]]},"properties":{"id":"54933","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.849974,44.345407],[-89.895774,44.351079],[-90.04475,44.248933],[-89.971269,44.160567],[-89.777522,44.140902],[-89.698282,44.213643],[-89.849974,44.345407]]]},"properties":{"id":"54457","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.205537,46.588574],[-90.90456,46.42094],[-90.737722,46.457582],[-90.62651,46.49201],[-90.548185,46.587048],[-90.936819,46.611867],[-91.048776,46.630731],[-91.205537,46.588574]]]},"properties":{"id":"54806","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.788821,45.221801],[-91.443866,45.291686],[-91.52088,45.40763],[-91.554928,45.408053],[-91.787156,45.351181],[-91.808073,45.329653],[-91.788821,45.221801]]]},"properties":{"id":"54728","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.262035,45.467751],[-92.299313,45.432508],[-92.228704,45.380977],[-92.058929,45.359874],[-92.07922,45.452902],[-92.262035,45.467751]]]},"properties":{"id":"54889","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.972888,45.28433],[-91.891149,45.231524],[-91.810053,45.208174],[-91.788821,45.221801],[-91.808073,45.329653],[-91.972888,45.28433]]]},"properties":{"id":"54733","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.084452,45.209233],[-92.285688,45.302704],[-92.356631,45.238139],[-92.239544,45.165474],[-92.156594,45.165221],[-92.08532,45.194177],[-92.084452,45.209233]]]},"properties":{"id":"54005","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.551444,46.755674],[-91.658473,46.546197],[-91.647497,46.427348],[-91.489461,46.459958],[-91.4478,46.460034],[-91.509122,46.748293],[-91.551395,46.755816],[-91.551444,46.755674]]]},"properties":{"id":"54820","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.031435,44.563246],[-88.109517,44.523605],[-88.021243,44.508493],[-88.011303,44.521692],[-88.004777,44.539457],[-88.031435,44.563246]]]},"properties":{"id":"54303","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.840624,44.553576],[-92.045473,44.509976],[-92.083572,44.407336],[-92.03233,44.386431],[-91.85208,44.476129],[-91.840624,44.553576]]]},"properties":{"id":"54756","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.662604,44.27205],[-91.833108,44.180509],[-91.559004,44.025315],[-91.540399,44.088211],[-91.558933,44.140793],[-91.662604,44.27205]]]},"properties":{"id":"54629","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.518876,45.81833],[-92.278202,45.761707],[-92.211934,45.773173],[-92.216248,45.790861],[-92.154272,45.816032],[-92.104281,45.9581],[-92.527602,45.902119],[-92.518876,45.81833]]]},"properties":{"id":"54893","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.528198,45.72868],[-92.278202,45.761707],[-92.518876,45.81833],[-92.528198,45.72868]]]},"properties":{"id":"54872","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.154272,45.816032],[-92.216248,45.790861],[-92.128511,45.815687],[-92.154272,45.816032]]]},"properties":{"id":"54845","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.24799,44.184185],[-88.30428,44.177789],[-88.297075,44.1577],[-88.254459,44.169949],[-88.24799,44.184185]]]},"properties":{"id":"54169","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.922364,43.996089],[-87.932525,44.00805],[-87.998156,44.030026],[-88.042349,44.00176],[-88.082269,43.906279],[-87.909787,43.904485],[-87.905046,43.935414],[-87.883872,43.963883],[-87.922364,43.996089]]]},"properties":{"id":"53042","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.094558,44.122313],[-88.111589,44.124446],[-88.108809,44.11699],[-88.089115,44.116729],[-88.094558,44.122313]]]},"properties":{"id":"54160","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.982714,45.119331],[-91.027436,45.150895],[-91.085712,45.031092],[-91.034126,44.857249],[-90.983404,44.77068],[-90.982714,45.119331]]]},"properties":{"id":"54726","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.788187,44.943929],[-91.59133,44.943667],[-91.559848,45.008691],[-91.666402,45.120823],[-91.722964,45.143218],[-91.773641,45.121329],[-91.894492,45.003627],[-91.894353,44.984025],[-91.788187,44.943929]]]},"properties":{"id":"54730","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.198495,44.945364],[-90.396704,44.944447],[-90.458235,44.875521],[-90.456358,44.871461],[-90.198308,44.85879],[-90.139311,44.887532],[-90.161936,44.934445],[-90.198495,44.945364]]]},"properties":{"id":"54421","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.217689,45.003293],[-90.197514,45.03364],[-90.376269,45.04755],[-90.433891,45.059948],[-90.397655,44.958964],[-90.217689,45.003293]]]},"properties":{"id":"54425","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.369447,43.642627],[-89.41578,43.504466],[-89.363725,43.455528],[-89.305484,43.48477],[-89.309738,43.500181],[-89.27933,43.495271],[-89.246599,43.507808],[-89.200554,43.606755],[-89.2964,43.67204],[-89.369447,43.642627]]]},"properties":{"id":"53954","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.362056,43.176734],[-89.312266,43.164614],[-89.304697,43.171417],[-89.284599,43.182448],[-89.2316,43.282328],[-89.246631,43.311084],[-89.285521,43.310486],[-89.344491,43.281174],[-89.344425,43.27444],[-89.363022,43.275511],[-89.39895,43.275731],[-89.45156,43.250271],[-89.362056,43.176734]],[[-89.345007,43.19453],[-89.361236,43.215412],[-89.323529,43.223184],[-89.324214,43.186286],[-89.345007,43.19453]]]},"properties":{"id":"53532","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.81965,43.206923],[-90.666179,43.291585],[-90.896896,43.35808],[-90.925085,43.324818],[-90.933224,43.317584],[-90.945883,43.3212],[-90.954961,43.287723],[-90.965189,43.275727],[-90.955282,43.264649],[-90.969144,43.255359],[-90.952063,43.233485],[-90.81965,43.206923]]]},"properties":{"id":"54631","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.075528,43.330186],[-91.155859,43.143268],[-91.037004,43.128225],[-90.968264,43.179385],[-90.952063,43.233485],[-90.969144,43.255359],[-90.965189,43.275727],[-90.954961,43.287723],[-91.075528,43.330186]]]},"properties":{"id":"54626","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.952063,43.233485],[-90.968264,43.179385],[-90.819689,43.162361],[-90.81965,43.206923],[-90.952063,43.233485]]]},"properties":{"id":"54657","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.808856,43.099705],[-90.819689,43.162361],[-90.968264,43.179385],[-91.037004,43.128225],[-90.879581,43.073558],[-90.808856,43.099705]]]},"properties":{"id":"53826","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.502755,43.060698],[-89.530765,43.060384],[-89.459207,43.001135],[-89.448343,43.025311],[-89.484981,43.054779],[-89.502755,43.060698]]]},"properties":{"id":"53719","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.973535,43.435113],[-88.906498,43.3578],[-88.821199,43.357982],[-88.812864,43.357887],[-88.733887,43.458874],[-88.783496,43.545717],[-88.939426,43.537067],[-88.976391,43.444591],[-88.973535,43.435113]]]},"properties":{"id":"53916","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.926464,43.61921],[-88.939426,43.537067],[-88.783496,43.545717],[-88.775895,43.560235],[-88.896267,43.619296],[-88.926464,43.61921]]]},"properties":{"id":"53933","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.034303,45.213009],[-87.116594,45.241317],[-87.153392,45.185374],[-87.153216,45.141877],[-87.153179,45.130068],[-87.083943,45.144625],[-87.034303,45.213009]]]},"properties":{"id":"54234","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.562726,44.545112],[-87.439562,44.588076],[-87.37033,44.675581],[-87.417717,44.690023],[-87.580096,44.660879],[-87.562726,44.545112]]]},"properties":{"id":"54201","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.860594,46.535639],[-91.829371,46.690114],[-91.93031,46.684574],[-92.01529,46.706469],[-92.044632,46.417861],[-91.856404,46.503283],[-91.860594,46.535639]]]},"properties":{"id":"54874","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.387189,44.683354],[-91.412728,44.456341],[-91.374205,44.428317],[-91.335702,44.471092],[-91.346638,44.679444],[-91.387189,44.683354]]]},"properties":{"id":"54770","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.256005,45.777135],[-89.046541,45.664937],[-88.92538,45.860624],[-88.966766,45.962521],[-89.256005,45.777135]]]},"properties":{"id":"54562","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.661424,42.567566],[-90.406595,42.55792],[-90.308192,42.59539],[-90.30003,42.653502],[-90.308273,42.669719],[-90.372894,42.704721],[-90.573449,42.656499],[-90.602745,42.631739],[-90.587656,42.634667],[-90.583295,42.620046],[-90.602655,42.619785],[-90.602664,42.629781],[-90.661527,42.567999],[-90.661424,42.567566]]]},"properties":{"id":"53807","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.467996,43.031387],[-90.394262,42.995979],[-90.309954,42.99686],[-90.25569,43.0892],[-90.370111,43.118679],[-90.467996,43.031387]]]},"properties":{"id":"53543","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485379,42.629832],[-89.409952,42.500656],[-89.262669,42.49819],[-89.247989,42.599829],[-89.248882,42.657515],[-89.249289,42.700847],[-89.367611,42.686602],[-89.486077,42.679118],[-89.485379,42.629832]]]},"properties":{"id":"53520","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.728705,42.708664],[-89.486113,42.68277],[-89.516386,42.780983],[-89.583184,42.790459],[-89.747147,42.782303],[-89.755483,42.770391],[-89.728705,42.708664]]]},"properties":{"id":"53570","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.755483,42.770391],[-89.997885,42.775418],[-89.965481,42.639226],[-89.958071,42.638991],[-89.864953,42.649745],[-89.859799,42.6514],[-89.852445,42.647577],[-89.864236,42.64394],[-89.865524,42.648994],[-89.855888,42.610724],[-89.808221,42.621079],[-89.728705,42.708664],[-89.755483,42.770391]]]},"properties":{"id":"53504","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.142826,44.047097],[-89.36527,43.95609],[-89.36528,43.93762],[-89.25177,43.86813],[-89.063528,43.907645],[-89.116961,43.983099],[-89.142826,44.047097]]]},"properties":{"id":"54960","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.146585,43.741659],[-89.136657,43.74438],[-89.134081,43.749586],[-89.146815,43.748774],[-89.146585,43.741659]]]},"properties":{"id":"53947","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.12624,43.696576],[-89.136589,43.683099],[-89.126447,43.683219],[-89.12624,43.696576]]]},"properties":{"id":"53939","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.994771,43.195354],[-90.034841,43.075851],[-89.942875,43.074591],[-89.897635,43.112779],[-89.837995,43.137273],[-89.838135,43.206057],[-89.994771,43.195354]]]},"properties":{"id":"53503","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.383212,46.41364],[-90.33776,46.23769],[-90.299287,46.341459],[-90.29859,46.40787],[-90.341173,46.414459],[-90.383212,46.41364]]]},"properties":{"id":"54536","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.367047,44.186594],[-91.286491,44.06055],[-91.151843,44.079656],[-91.112819,44.190461],[-91.152532,44.212231],[-91.329874,44.247285],[-91.367047,44.186594]]]},"properties":{"id":"54627","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.393935,42.995478],[-88.541955,43.007074],[-88.583884,42.963402],[-88.584253,42.962697],[-88.542058,42.92704],[-88.416828,42.945129],[-88.405244,42.952192],[-88.390892,42.98512],[-88.393935,42.995478]]]},"properties":{"id":"53118","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.971269,44.160567],[-90.04475,44.248933],[-90.048093,44.248941],[-90.182037,44.249135],[-90.191753,44.082696],[-90.211294,43.981253],[-90.000127,43.905832],[-89.979051,43.905612],[-89.961465,43.966333],[-89.971269,44.160567]]]},"properties":{"id":"54646","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.2521,43.554484],[-90.191788,43.457984],[-90.123977,43.453348],[-90.137703,43.467575],[-90.028061,43.641622],[-90.03099,43.647876],[-90.122758,43.656422],[-90.2521,43.554484]]]},"properties":{"id":"53941","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.188335,42.522074],[-88.198576,42.495141],[-88.181443,42.495303],[-88.175391,42.524995],[-88.188335,42.522074]]]},"properties":{"id":"53192","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.876621,42.686424],[-87.910368,42.739182],[-87.971833,42.742271],[-87.952878,42.65492],[-87.894892,42.668949],[-87.876621,42.686424]]]},"properties":{"id":"53177","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.941992,43.764788],[-91.03121,43.81237],[-91.130422,43.740965],[-91.108783,43.72294],[-90.998116,43.652025],[-90.955266,43.751673],[-90.910882,43.768833],[-90.941992,43.764788]]]},"properties":{"id":"54623","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.965481,42.639226],[-89.997885,42.775418],[-90.050532,42.79577],[-90.249657,42.730415],[-90.308273,42.669719],[-90.30003,42.653502],[-90.113081,42.591782],[-89.965481,42.639226]]]},"properties":{"id":"53530","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.308192,42.59539],[-90.406595,42.55792],[-90.367919,42.51391],[-90.308192,42.59539]]]},"properties":{"id":"53803","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.200138,45.379352],[-89.29991,45.377534],[-89.332894,45.202681],[-89.144933,45.204902],[-88.926785,45.302806],[-88.926483,45.334332],[-88.994473,45.348849],[-89.049537,45.378897],[-89.200138,45.379352]]]},"properties":{"id":"54424","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.200138,45.379352],[-89.302079,45.409367],[-89.29991,45.377534],[-89.200138,45.379352]]]},"properties":{"id":"54485","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.177942,45.029383],[-89.345603,45.08779],[-89.437441,45.00192],[-89.352953,44.976477],[-89.177942,45.029383]]]},"properties":{"id":"54408","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.641327,45.569642],[-89.487334,45.535562],[-89.364049,45.469886],[-89.046688,45.549791],[-89.046781,45.637517],[-89.046541,45.664937],[-89.256005,45.777135],[-89.474597,45.82654],[-89.575081,45.703489],[-89.641327,45.569642]]]},"properties":{"id":"54501","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.791832,44.240183],[-87.771329,44.145139],[-87.711431,44.218485],[-87.720425,44.254869],[-87.791832,44.240183]]]},"properties":{"id":"54247","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.352953,44.976477],[-89.437441,45.00192],[-89.473521,44.930394],[-89.422478,44.880097],[-89.346559,44.914129],[-89.352953,44.976477]]]},"properties":{"id":"54471","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.177942,45.029383],[-89.352953,44.976477],[-89.346559,44.914129],[-89.25521,44.871053],[-89.144903,44.886972],[-89.062413,44.903528],[-88.981432,44.942841],[-88.981726,45.000124],[-89.02943,44.994688],[-89.05243,45.008143],[-89.177942,45.029383]]]},"properties":{"id":"54414","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.279961,45.20061],[-87.953291,45.136583],[-87.842392,45.257082],[-88.059893,45.368938],[-88.306175,45.366718],[-88.431789,45.260713],[-88.279961,45.20061]]]},"properties":{"id":"54114","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.163146,45.010105],[-87.924124,44.993021],[-87.902925,44.99323],[-87.922087,45.063502],[-88.186266,45.024799],[-88.163146,45.010105]]]},"properties":{"id":"54112","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.947773,43.045874],[-87.924376,43.04575],[-87.919873,43.063944],[-87.946267,43.060585],[-87.947511,43.060598],[-87.947773,43.045874]]]},"properties":{"id":"53205","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.017452,43.067402],[-88.066725,43.067199],[-88.066917,43.059409],[-88.066916,43.036513],[-88.066989,43.029651],[-88.016432,43.031805],[-88.017452,43.067402]]]},"properties":{"id":"53226","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.756081,43.841812],[-90.649082,43.752945],[-90.573192,43.805215],[-90.549399,43.870822],[-90.616793,43.928105],[-90.650827,43.938768],[-90.756081,43.841812]]]},"properties":{"id":"54648","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.373885,43.871757],[-90.342404,44.017131],[-90.620054,44.072516],[-90.616793,43.928105],[-90.549399,43.870822],[-90.389358,43.87237],[-90.373885,43.871757]]]},"properties":{"id":"54660","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.062092,44.732804],[-88.18677,44.764823],[-88.181341,44.74588],[-88.131098,44.676928],[-88.067188,44.677388],[-88.062092,44.732804]]]},"properties":{"id":"54171","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.971712,44.199104],[-88.996968,44.213585],[-89.001799,44.199063],[-88.971712,44.199104]]],[[[-88.971712,44.199104],[-88.986543,44.184631],[-88.929048,44.121405],[-88.886191,44.155871],[-88.799592,44.178852],[-88.670384,44.24332],[-88.678471,44.269633],[-88.688484,44.276301],[-88.739055,44.289338],[-88.860169,44.294771],[-88.971712,44.199104]]]]},"properties":{"id":"54940","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.487318,44.801844],[-88.515472,44.677824],[-88.46834,44.573994],[-88.446604,44.589184],[-88.366135,44.764165],[-88.487318,44.801844]]]},"properties":{"id":"54107","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.003814,43.192581],[-87.904187,43.250206],[-87.898887,43.280405],[-87.942368,43.280033],[-88.063292,43.278881],[-88.063353,43.192117],[-88.013677,43.192537],[-88.003814,43.192581]]]},"properties":{"id":"53097","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.134906,44.586381],[-92.155334,44.539478],[-92.217142,44.439132],[-92.083572,44.407336],[-92.045473,44.509976],[-92.134906,44.586381]]]},"properties":{"id":"54759","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.619149,44.731914],[-92.497172,44.630202],[-92.37563,44.645188],[-92.322509,44.705016],[-92.317663,44.76641],[-92.338608,44.766671],[-92.538221,44.775969],[-92.619149,44.731914]],[[-92.466614,44.734689],[-92.465988,44.735624],[-92.463544,44.733762],[-92.467305,44.733554],[-92.466614,44.734689]]]},"properties":{"id":"54011","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.598871,44.927144],[-92.764767,44.905431],[-92.78277,44.800954],[-92.619149,44.731914],[-92.538221,44.775969],[-92.441341,44.832458],[-92.409169,44.862281],[-92.415472,44.862305],[-92.496937,44.934861],[-92.598871,44.927144]]]},"properties":{"id":"54022","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.538221,44.775969],[-92.338608,44.766671],[-92.441341,44.832458],[-92.538221,44.775969]]]},"properties":{"id":"54003","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.78277,44.800954],[-92.625067,44.642167],[-92.619149,44.731914],[-92.78277,44.800954]]]},"properties":{"id":"54021","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.406508,44.38488],[-89.244316,44.334357],[-89.223668,44.417443],[-89.264141,44.475779],[-89.38586,44.471678],[-89.428536,44.422051],[-89.406508,44.38488]]]},"properties":{"id":"54406","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.664581,45.638331],[-90.658417,45.377637],[-90.55554,45.377386],[-90.555636,45.420571],[-90.554573,45.638137],[-90.664581,45.638331]]]},"properties":{"id":"54537","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.910368,42.739182],[-87.876621,42.686424],[-87.869193,42.66857],[-87.863945,42.668487],[-87.836911,42.688047],[-87.836025,42.784108],[-87.836041,42.784542],[-87.864158,42.777495],[-87.910368,42.739182]]]},"properties":{"id":"53406","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.926664,42.582879],[-88.979149,42.495765],[-88.801023,42.492121],[-88.77653,42.520908],[-88.815491,42.624612],[-88.859757,42.610718],[-88.926664,42.582879]]]},"properties":{"id":"53525","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.347485,45.877843],[-91.55407,45.929115],[-91.661686,45.838957],[-91.663425,45.811384],[-91.656961,45.753382],[-91.418496,45.766639],[-91.375855,45.782251],[-91.351581,45.812794],[-91.347485,45.877843]]]},"properties":{"id":"54876","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.55407,45.929115],[-91.347485,45.877843],[-91.132029,45.898346],[-90.92278,45.981028],[-90.924634,46.068],[-91.035947,46.154894],[-91.424597,46.156774],[-91.551282,46.157045],[-91.635727,46.066008],[-91.55407,45.929115]]]},"properties":{"id":"54843","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.799976,43.726348],[-87.800115,43.761252],[-87.847504,43.848344],[-87.875846,43.848339],[-87.920886,43.696425],[-87.889774,43.638245],[-87.784819,43.659463],[-87.799976,43.726348]]]},"properties":{"id":"53085","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.020163,43.688998],[-87.956169,43.630907],[-87.941358,43.615856],[-87.920866,43.610898],[-87.889713,43.623512],[-87.889774,43.638245],[-87.920886,43.696425],[-88.020163,43.688998]],[[-87.916447,43.639965],[-87.914855,43.639981],[-87.914807,43.638313],[-87.916407,43.638306],[-87.916447,43.639965]]]},"properties":{"id":"53093","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.108783,43.72294],[-91.152764,43.629968],[-91.104201,43.60379],[-91.012669,43.60929],[-90.998116,43.652025],[-91.108783,43.72294]]]},"properties":{"id":"54621","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.804313,46.069608],[-89.616424,45.98521],[-89.554517,46.076471],[-89.554829,46.120158],[-89.616916,46.160436],[-89.755393,46.159054],[-89.804313,46.069608]]]},"properties":{"id":"54512","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.554689,46.227881],[-89.616916,46.160436],[-89.554829,46.120158],[-89.476627,46.112398],[-89.367022,46.115828],[-89.180277,46.111918],[-89.07954,46.13352],[-89.554689,46.227881]]]},"properties":{"id":"54540","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.554517,46.076471],[-89.616424,45.98521],[-89.570223,45.982319],[-89.42714,45.973672],[-89.501045,46.027091],[-89.554517,46.076471]]]},"properties":{"id":"54560","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.554517,46.076471],[-89.501045,46.027091],[-89.476627,46.112398],[-89.554829,46.120158],[-89.554517,46.076471]]]},"properties":{"id":"54561","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.65853,42.557511],[-88.66834,42.494568],[-88.54048,42.494965],[-88.526215,42.530906],[-88.566627,42.574619],[-88.57054,42.582698],[-88.65853,42.557511]]]},"properties":{"id":"53184","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.224711,42.912126],[-88.228837,42.84193],[-88.188222,42.842838],[-88.188887,42.922858],[-88.188994,42.92738],[-88.224711,42.912126]]]},"properties":{"id":"53103","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.065576,43.119095],[-88.077457,43.104709],[-88.066143,43.098409],[-88.066025,43.104221],[-88.065576,43.119095]]]},"properties":{"id":"53007","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.136523,44.105086],[-89.142826,44.047097],[-89.116961,43.983099],[-89.025049,44.055492],[-89.087449,44.148597],[-89.136523,44.105086]]]},"properties":{"id":"54970","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.159296,44.540484],[-90.079894,44.469949],[-89.905231,44.511666],[-89.905036,44.526145],[-89.96455,44.592976],[-90.079668,44.61162],[-90.159296,44.540484]]]},"properties":{"id":"54410","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.858668,44.36569],[-89.895774,44.351079],[-89.849974,44.345407],[-89.858668,44.36569]]]},"properties":{"id":"54469","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.657687,43.948365],[-89.39545,43.96943],[-89.405588,44.054561],[-89.717633,44.08624],[-89.658084,43.970466],[-89.657687,43.948365]]]},"properties":{"id":"54930","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59732,43.8862],[-89.48267,43.81672],[-89.36528,43.93762],[-89.36527,43.95609],[-89.39545,43.96943],[-89.657687,43.948365],[-89.59732,43.8862]]]},"properties":{"id":"53964","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.35262,46.154924],[-90.860847,45.980526],[-90.676968,45.981554],[-90.231065,46.082585],[-90.303072,46.154849],[-90.35262,46.154924]]]},"properties":{"id":"54514","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.875676,45.638839],[-91.703218,45.624701],[-91.656961,45.753382],[-91.663425,45.811384],[-91.825512,45.766916],[-91.908912,45.674053],[-91.875676,45.638839]]]},"properties":{"id":"54870","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.037344,46.900895],[-91.1381,46.877364],[-91.177455,46.701018],[-91.082912,46.753523],[-91.029403,46.760509],[-91.037344,46.900895]]]},"properties":{"id":"54827","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.037344,46.900895],[-91.029403,46.760509],[-90.869988,46.763347],[-91.037344,46.900895]]]},"properties":{"id":"54814","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.067188,44.677388],[-88.014804,44.596092],[-87.993747,44.677542],[-88.067188,44.677388]]]},"properties":{"id":"54173","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.869179,44.703615],[-91.887172,44.693811],[-91.999916,44.683908],[-92.045473,44.509976],[-91.840624,44.553576],[-91.869179,44.703615]]]},"properties":{"id":"54736","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.017856,44.099085],[-88.04979,44.080923],[-88.286486,44.072993],[-88.311852,44.060304],[-88.311952,44.07874],[-88.327667,44.080201],[-88.31819,43.954054],[-88.222204,43.935154],[-88.042349,44.00176],[-87.998156,44.030026],[-87.95772,44.066234],[-87.976942,44.074604],[-87.99276,44.095757],[-88.017856,44.099085]]]},"properties":{"id":"53014","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.041033,43.815394],[-87.875846,43.848339],[-87.847504,43.848344],[-87.846493,43.862854],[-87.909787,43.904485],[-88.082269,43.906279],[-88.162274,43.891511],[-88.100122,43.840975],[-88.041033,43.815394]]]},"properties":{"id":"53020","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.690168,44.698293],[-90.804399,44.593528],[-90.801852,44.509573],[-90.558746,44.42221],[-90.497221,44.422613],[-90.496586,44.654471],[-90.557687,44.662089],[-90.690168,44.698293]]]},"properties":{"id":"54456","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.417586,44.661299],[-90.315757,44.512775],[-90.336003,44.685064],[-90.417586,44.661299]]]},"properties":{"id":"54420","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.495697,45.088852],[-90.458235,44.875521],[-90.396704,44.944447],[-90.397655,44.958964],[-90.433891,45.059948],[-90.495697,45.088852]]]},"properties":{"id":"54422","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.536562,44.835311],[-90.557687,44.662089],[-90.496586,44.654471],[-90.455761,44.683383],[-90.428549,44.821051],[-90.495942,44.849796],[-90.536562,44.835311]]]},"properties":{"id":"54446","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.160159,43.42383],[-89.207598,43.282474],[-89.127022,43.253607],[-89.058171,43.239574],[-88.935271,43.285124],[-88.906498,43.3578],[-88.973535,43.435113],[-89.125896,43.431337],[-89.145515,43.427521],[-89.160159,43.42383]]]},"properties":{"id":"53925","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.422674,43.352398],[-89.560739,43.395006],[-89.684306,43.355406],[-89.659547,43.292939],[-89.600672,43.242212],[-89.41751,43.294111],[-89.422674,43.352398]]]},"properties":{"id":"53555","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.116244,43.478727],[-89.126484,43.475026],[-89.125896,43.431337],[-88.973535,43.435113],[-88.976391,43.444591],[-89.116244,43.478727]]]},"properties":{"id":"53932","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.897807,43.316114],[-89.659547,43.292939],[-89.684306,43.355406],[-89.718827,43.391097],[-89.76851,43.390722],[-89.897807,43.316114]]]},"properties":{"id":"53578","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.145515,43.427521],[-89.160767,43.430411],[-89.160159,43.42383],[-89.145515,43.427521]]]},"properties":{"id":"53928","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.304697,43.171417],[-89.312266,43.164614],[-89.285628,43.104179],[-89.283781,43.084518],[-89.31001,43.045325],[-89.246264,43.051507],[-89.235648,43.107382],[-89.284599,43.182448],[-89.304697,43.171417]]]},"properties":{"id":"53718","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.53731,43.060306],[-89.492521,43.082669],[-89.482449,43.094649],[-89.44184,43.118837],[-89.561432,43.16924],[-89.593197,43.083595],[-89.53731,43.060306]]]},"properties":{"id":"53562","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.645864,43.046207],[-89.650542,42.911145],[-89.48549,42.948283],[-89.462871,42.979537],[-89.459207,43.001135],[-89.530765,43.060384],[-89.53731,43.060306],[-89.593197,43.083595],[-89.645864,43.046207]]]},"properties":{"id":"53593","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.41751,43.294111],[-89.600672,43.242212],[-89.620486,43.220747],[-89.620312,43.20586],[-89.561792,43.191047],[-89.45156,43.250271],[-89.39895,43.275731],[-89.41751,43.294111]]]},"properties":{"id":"53529","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.98434,42.919832],[-89.097769,42.952317],[-89.186465,42.846353],[-89.171396,42.788258],[-89.114577,42.759845],[-89.030787,42.784407],[-89.00011,42.847256],[-88.98434,42.919832]]]},"properties":{"id":"53534","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.425115,43.07652],[-89.413905,43.067802],[-89.408995,43.07012],[-89.397376,43.074902],[-89.397281,43.077004],[-89.42097,43.078222],[-89.425115,43.07652]]]},"properties":{"id":"53706","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.661325,43.166129],[-89.838034,43.127629],[-89.795488,43.064191],[-89.720307,43.075665],[-89.661325,43.166129]]]},"properties":{"id":"53515","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.478088,43.251068],[-88.513579,43.34079],[-88.592615,43.346953],[-88.578059,43.297307],[-88.565534,43.240697],[-88.549456,43.239449],[-88.478088,43.251068]]]},"properties":{"id":"53059","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.565534,43.240697],[-88.585466,43.196867],[-88.618138,43.107919],[-88.513842,43.207021],[-88.516799,43.212645],[-88.549456,43.239449],[-88.565534,43.240697]]]},"properties":{"id":"53036","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.417717,44.690023],[-87.37033,44.675581],[-87.17481,44.986424],[-87.191941,44.985664],[-87.36255,44.983578],[-87.629283,44.819872],[-87.539931,44.747777],[-87.417717,44.690023]]]},"properties":{"id":"54235","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.580096,44.660879],[-87.417717,44.690023],[-87.539931,44.747777],[-87.64151,44.690287],[-87.580096,44.660879]]]},"properties":{"id":"54213","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.718328,44.704406],[-87.651118,44.686441],[-87.64151,44.690287],[-87.539931,44.747777],[-87.629283,44.819872],[-87.718328,44.704406]]]},"properties":{"id":"54204","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.1915,45.156361],[-87.153216,45.141877],[-87.153392,45.185374],[-87.1915,45.156361]]]},"properties":{"id":"54211","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.826233,46.474121],[-91.647497,46.427348],[-91.658473,46.546197],[-91.721041,46.560474],[-91.859389,46.535607],[-91.826233,46.474121]]]},"properties":{"id":"54849","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.161957,45.717039],[-87.799403,45.625612],[-88.121832,45.831761],[-88.161957,45.717039]]]},"properties":{"id":"54151","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.380569,43.660233],[-88.220172,43.717868],[-88.266194,43.738057],[-88.380569,43.660233]]]},"properties":{"id":"53019","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.766092,43.975135],[-88.660784,43.869448],[-88.630359,43.894626],[-88.704662,43.982125],[-88.756877,43.982289],[-88.766092,43.975135]]]},"properties":{"id":"54964","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.799917,45.475164],[-88.515516,45.483523],[-88.494334,45.568814],[-88.612396,45.636502],[-88.800152,45.622507],[-88.799917,45.475164]]]},"properties":{"id":"54541","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.400506,45.701005],[-88.572731,45.722666],[-88.612396,45.636502],[-88.494334,45.568814],[-88.426085,45.548139],[-88.400506,45.701005]]]},"properties":{"id":"54103","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.89308,42.79632],[-90.899761,42.814864],[-90.881563,42.814616],[-90.881325,42.799201],[-90.843858,42.783774],[-90.844564,42.903035],[-90.922755,42.952639],[-90.952607,42.952622],[-90.95245,42.938263],[-90.982265,42.938636],[-90.982256,42.938492],[-91.001197,42.872752],[-90.902711,42.795467],[-90.89308,42.79632]]]},"properties":{"id":"53804","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.131235,42.999513],[-91.092985,42.843536],[-91.001197,42.872752],[-90.982256,42.938492],[-91.131235,42.999513]]]},"properties":{"id":"53801","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.069879,42.77145],[-90.902711,42.795467],[-91.001197,42.872752],[-91.092985,42.843536],[-91.069879,42.77145]]]},"properties":{"id":"53810","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.595682,43.343023],[-90.547467,43.155189],[-90.533803,43.075116],[-90.521812,43.045483],[-90.467996,43.031387],[-90.370111,43.118679],[-90.331048,43.209044],[-90.330989,43.234231],[-90.59555,43.350393],[-90.595682,43.343023]]]},"properties":{"id":"53573","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.768809,43.020348],[-90.940588,43.039781],[-90.922755,42.952639],[-90.844564,42.903035],[-90.774627,42.955883],[-90.768809,43.020348]]]},"properties":{"id":"53816","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.894426,43.007622],[-88.848158,43.086861],[-88.850016,43.099543],[-88.891011,43.159049],[-88.98801,43.055496],[-88.934364,43.015562],[-88.894426,43.007622]]]},"properties":{"id":"53551","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.232253,44.111371],[-90.342404,44.017131],[-90.373885,43.871757],[-90.285332,43.846557],[-90.258851,43.828478],[-90.255788,43.878501],[-90.211294,43.981253],[-90.191753,44.082696],[-90.232253,44.111371]],[[-90.255788,43.878501],[-90.269489,43.8745],[-90.277024,43.879593],[-90.261909,43.881569],[-90.255788,43.878501]]]},"properties":{"id":"54618","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.02505,42.495113],[-87.949839,42.49459],[-87.846964,42.538867],[-87.845515,42.578233],[-87.845572,42.580851],[-87.953389,42.582309],[-88.02505,42.495113]]]},"properties":{"id":"53142","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.111108,42.757249],[-88.099715,42.626286],[-88.07934,42.611949],[-88.031821,42.604586],[-87.952878,42.65492],[-87.971833,42.742271],[-88.115044,42.814901],[-88.111108,42.757249]]]},"properties":{"id":"53182","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.617331,44.515568],[-87.704666,44.407234],[-87.636771,44.327506],[-87.603526,44.327496],[-87.539203,44.327509],[-87.439562,44.588076],[-87.562726,44.545112],[-87.617331,44.515568]]]},"properties":{"id":"54216","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.955266,43.751673],[-90.69437,43.656023],[-90.655468,43.682743],[-90.649082,43.752945],[-90.756081,43.841812],[-90.863102,43.812873],[-90.910882,43.768833],[-90.955266,43.751673]]]},"properties":{"id":"54619","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.337694,43.962511],[-91.173939,43.938816],[-91.111634,43.970917],[-91.151839,44.079278],[-91.151843,44.079656],[-91.286491,44.06055],[-91.343234,44.014172],[-91.337694,43.962511]]]},"properties":{"id":"54636","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.724421,44.000364],[-90.672508,44.118682],[-90.947547,44.147332],[-90.972764,44.070775],[-90.916518,44.049285],[-90.910189,43.916374],[-90.863102,43.812873],[-90.756081,43.841812],[-90.650827,43.938768],[-90.721751,44.000385],[-90.724421,44.000364]]]},"properties":{"id":"54656","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.363159,42.806006],[-90.372894,42.704721],[-90.308273,42.669719],[-90.249657,42.730415],[-90.363159,42.806006]]]},"properties":{"id":"53510","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.987675,45.465065],[-89.049537,45.378897],[-88.994473,45.348849],[-88.925214,45.410913],[-88.987675,45.465065]]]},"properties":{"id":"54462","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.747526,45.061675],[-90.045419,45.207938],[-90.085573,45.184991],[-90.197571,45.091258],[-90.197514,45.03364],[-90.217689,45.003293],[-90.198495,44.945364],[-90.161936,44.934445],[-89.885339,45.032803],[-89.79836,45.032563],[-89.747526,45.061675]]]},"properties":{"id":"54411","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.240235,45.648258],[-88.313077,45.57126],[-88.150198,45.54442],[-88.058093,45.56226],[-88.240235,45.648258]]]},"properties":{"id":"54119","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.48293,43.75907],[-89.5631,43.69356],[-89.56355,43.65014],[-89.409213,43.642677],[-89.48293,43.75907]]]},"properties":{"id":"53930","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.368933,45.114658],[-88.559339,45.116826],[-88.662079,45.11738],[-88.574925,45.039223],[-88.515572,44.902576],[-88.285517,44.968871],[-88.204435,45.003765],[-88.202788,45.025201],[-88.368933,45.114658]]]},"properties":{"id":"54174","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.988053,43.060704],[-87.947511,43.060598],[-87.946267,43.060585],[-87.946768,43.075221],[-88.006698,43.075323],[-88.007444,43.068045],[-87.988053,43.060704]]]},"properties":{"id":"53210","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.947511,43.060598],[-87.988053,43.060704],[-87.982388,43.036613],[-87.955245,43.025975],[-87.947783,43.028359],[-87.947773,43.045874],[-87.947511,43.060598]]]},"properties":{"id":"53208","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.967707,43.010146],[-88.017129,43.010062],[-88.017693,42.981013],[-87.968034,42.981179],[-87.967707,43.010146]]]},"properties":{"id":"53219","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.965978,43.133932],[-87.964668,43.192434],[-88.003814,43.192581],[-88.013677,43.192537],[-88.015615,43.134106],[-87.965978,43.133932]]]},"properties":{"id":"53223","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.967707,43.010146],[-87.955245,43.025975],[-87.982388,43.036613],[-88.016432,43.031805],[-88.066989,43.029651],[-88.067252,43.016399],[-88.067462,43.010014],[-88.017129,43.010062],[-87.967707,43.010146]],[[-87.969505,43.0207],[-87.980263,43.01926],[-87.982463,43.024283],[-87.969379,43.024424],[-87.969505,43.0207]]]},"properties":{"id":"53214","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.066725,43.067199],[-88.017452,43.067402],[-88.007444,43.068045],[-88.006698,43.075323],[-88.006475,43.09721],[-88.017395,43.09709],[-88.066025,43.104221],[-88.066143,43.098409],[-88.066725,43.067199]]]},"properties":{"id":"53222","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.017693,42.981013],[-88.017129,43.010062],[-88.067462,43.010014],[-88.06862,42.98096],[-88.017693,42.981013]]]},"properties":{"id":"53227","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.114092,44.835669],[-88.190534,44.768056],[-88.18677,44.764823],[-88.062092,44.732804],[-87.949999,44.779027],[-88.114092,44.835669]]]},"properties":{"id":"54101","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.480403,44.855364],[-88.487318,44.801844],[-88.366135,44.764165],[-88.333707,44.765637],[-88.273701,44.79556],[-88.270384,44.7994],[-88.299042,44.853527],[-88.480403,44.855364]]]},"properties":{"id":"54111","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.670384,44.24332],[-88.624128,44.243683],[-88.592535,44.271194],[-88.516645,44.330184],[-88.496442,44.359074],[-88.506359,44.38415],[-88.615522,44.388964],[-88.739055,44.289338],[-88.688484,44.276301],[-88.68043,44.273008],[-88.678181,44.280628],[-88.673848,44.280541],[-88.673789,44.273038],[-88.678471,44.269633],[-88.670384,44.24332]]]},"properties":{"id":"54944","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.333135,44.298023],[-88.324506,44.279387],[-88.321806,44.277787],[-88.293259,44.274866],[-88.323031,44.297968],[-88.333135,44.298023]]]},"properties":{"id":"54140","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.953494,43.528205],[-87.920532,43.455844],[-87.804149,43.45643],[-87.792096,43.508552],[-87.901002,43.543406],[-87.953494,43.528205]]]},"properties":{"id":"53004","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.040827,43.432433],[-88.037666,43.432458],[-88.040701,43.454481],[-88.040827,43.432433]]],[[[-88.234832,43.339933],[-88.223087,43.338838],[-88.122252,43.335534],[-88.040126,43.367313],[-88.020774,43.417413],[-88.040827,43.432433],[-88.301699,43.427552],[-88.301161,43.405624],[-88.277641,43.369318],[-88.234832,43.339933]]]]},"properties":{"id":"53095","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497172,44.630202],[-92.619149,44.731914],[-92.625067,44.642167],[-92.497224,44.566952],[-92.497172,44.630202]]]},"properties":{"id":"54014","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.754434,45.556698],[-92.650474,45.401921],[-92.510409,45.382062],[-92.459965,45.397978],[-92.463631,45.409278],[-92.592833,45.526779],[-92.610663,45.540744],[-92.672356,45.643244],[-92.754434,45.556698]]]},"properties":{"id":"54024","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.758008,45.209566],[-92.683317,45.210045],[-92.610473,45.224795],[-92.506958,45.246012],[-92.500218,45.309912],[-92.698959,45.33953],[-92.758008,45.209566]]]},"properties":{"id":"54020","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.223668,44.417443],[-89.244316,44.334357],[-89.246836,44.262291],[-89.103511,44.221039],[-89.022153,44.242917],[-89.011213,44.402893],[-89.102669,44.417541],[-89.223668,44.417443]],[[-89.144873,44.336491],[-89.143058,44.336215],[-89.143565,44.335592],[-89.144873,44.336491]]]},"properties":{"id":"54981","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.166102,45.468047],[-90.167475,45.601411],[-90.4191,45.54916],[-90.430833,45.449232],[-90.166102,45.468047]]]},"properties":{"id":"54556","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.554573,45.638137],[-90.555636,45.420571],[-90.430833,45.449232],[-90.4191,45.54916],[-90.554573,45.638137]]]},"properties":{"id":"54515","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.805194,42.72665],[-87.790328,42.729517],[-87.836041,42.784542],[-87.836025,42.784108],[-87.805194,42.72665]]]},"properties":{"id":"53404","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.951992,42.843547],[-88.001299,42.843596],[-87.872549,42.813846],[-87.885584,42.842666],[-87.951992,42.843547]]]},"properties":{"id":"53108","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.188887,42.922858],[-88.188222,42.842838],[-88.11457,42.822217],[-88.069924,42.843323],[-88.069489,42.930115],[-88.069585,42.933227],[-88.138646,42.929979],[-88.188887,42.922858]]]},"properties":{"id":"53150","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.01481,42.671611],[-89.114577,42.759845],[-89.171396,42.788258],[-89.249289,42.700847],[-89.248882,42.657515],[-89.129929,42.620716],[-89.058597,42.60218],[-89.070658,42.608921],[-89.072085,42.613586],[-89.07132,42.613591],[-89.01481,42.671611]],[[-89.214907,42.665091],[-89.219078,42.679404],[-89.19961,42.679402],[-89.214907,42.665091]]]},"properties":{"id":"53548","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.801023,42.492121],[-88.66834,42.494568],[-88.65853,42.557511],[-88.678243,42.579273],[-88.77653,42.520908],[-88.801023,42.492121]]]},"properties":{"id":"53585","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.828287,44.943161],[-88.889179,44.811459],[-88.868426,44.796561],[-88.736194,44.856376],[-88.735591,44.921653],[-88.828287,44.943161]]]},"properties":{"id":"54128","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.889713,43.623512],[-87.782315,43.542981],[-87.733243,43.629343],[-87.784819,43.659463],[-87.889774,43.638245],[-87.889713,43.623512]]]},"properties":{"id":"53070","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.799976,43.726348],[-87.779687,43.759994],[-87.800115,43.761252],[-87.799976,43.726348]]]},"properties":{"id":"53044","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.53285,42.563697],[-88.526215,42.530906],[-88.54048,42.494965],[-88.413114,42.494618],[-88.34851,42.573825],[-88.413611,42.645915],[-88.540976,42.626443],[-88.540847,42.600207],[-88.53285,42.563697]],[[-88.481826,42.511819],[-88.497602,42.512271],[-88.501002,42.516547],[-88.481931,42.516585],[-88.481826,42.511819]]]},"properties":{"id":"53147","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.234832,43.339933],[-88.225097,43.337535],[-88.223087,43.338838],[-88.234832,43.339933]]],[[[-88.234832,43.339933],[-88.277641,43.369318],[-88.300431,43.281351],[-88.210285,43.295357],[-88.225097,43.337535],[-88.234832,43.339933]]]]},"properties":{"id":"53086","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.210285,43.295357],[-88.123088,43.272164],[-88.083287,43.279247],[-88.122252,43.335534],[-88.223087,43.338838],[-88.225097,43.337535],[-88.210285,43.295357]]]},"properties":{"id":"53037","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.162017,43.192268],[-88.063353,43.192117],[-88.063292,43.278881],[-88.083287,43.279247],[-88.123088,43.272164],[-88.191237,43.250775],[-88.182183,43.221621],[-88.162017,43.192268]]]},"properties":{"id":"53022","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.38537,43.076546],[-88.400677,43.153403],[-88.423816,43.12082],[-88.427468,43.105826],[-88.423171,43.075892],[-88.38537,43.076546]]]},"properties":{"id":"53058","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.067252,43.016399],[-88.12697,43.016885],[-88.138646,42.929979],[-88.069585,42.933227],[-88.069288,42.952627],[-88.06862,42.98096],[-88.067462,43.010014],[-88.067252,43.016399]]]},"properties":{"id":"53151","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.066917,43.059409],[-88.104861,43.059968],[-88.106825,43.035928],[-88.066916,43.036513],[-88.066917,43.059409]]]},"properties":{"id":"53122","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.423171,43.075892],[-88.393747,43.01164],[-88.366568,43.017971],[-88.344908,43.049264],[-88.363397,43.061689],[-88.38537,43.076546],[-88.423171,43.075892]]]},"properties":{"id":"53018","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.581519,44.045632],[-88.583718,43.908907],[-88.46757,43.893415],[-88.520014,44.003684],[-88.581519,44.045632]]]},"properties":{"id":"54902","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.895774,44.351079],[-89.858668,44.36569],[-89.803002,44.423562],[-89.864591,44.511705],[-89.905036,44.526145],[-89.905231,44.511666],[-90.054339,44.394741],[-90.116415,44.336616],[-90.048093,44.248941],[-90.04475,44.248933],[-89.895774,44.351079]]]},"properties":{"id":"54495","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.827715,43.825573],[-89.727458,43.95241],[-89.911225,43.818274],[-89.827715,43.825573]]]},"properties":{"id":"53910","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.5631,43.69356],[-89.48293,43.75907],[-89.481919,43.758666],[-89.47688,43.78856],[-89.48267,43.81672],[-89.59732,43.8862],[-89.71705,43.783614],[-89.615621,43.673412],[-89.5631,43.69356]]]},"properties":{"id":"53952","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.821946,46.343662],[-90.68293,46.34217],[-90.695486,46.414334],[-90.821946,46.343662]]]},"properties":{"id":"54846","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.770133,45.437557],[-91.554928,45.408053],[-91.52088,45.40763],[-91.539486,45.568195],[-91.593332,45.58917],[-91.609574,45.591362],[-91.703218,45.624701],[-91.875676,45.638839],[-91.90816,45.610089],[-91.891803,45.4992],[-91.770133,45.437557]],[[-91.786122,45.60637],[-91.775143,45.61025],[-91.772573,45.602921],[-91.786122,45.60637]]]},"properties":{"id":"54868","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.554928,45.408053],[-91.770133,45.437557],[-91.787156,45.351181],[-91.554928,45.408053]]]},"properties":{"id":"54822","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.05037,46.374384],[-91.551282,46.157045],[-91.424597,46.156774],[-91.418664,46.244722],[-91.428201,46.408799],[-91.489461,46.459958],[-91.647497,46.427348],[-91.826233,46.474121],[-91.856404,46.503283],[-92.044632,46.417861],[-92.05037,46.374384]]]},"properties":{"id":"54873","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.149944,44.487664],[-88.261128,44.473078],[-88.278537,44.414981],[-88.162178,44.342461],[-88.146345,44.335621],[-87.948313,44.327885],[-87.927746,44.400619],[-88.012602,44.447192],[-88.055603,44.464791],[-88.102353,44.474129],[-88.149944,44.487664]]]},"properties":{"id":"54115","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.216197,44.589992],[-88.386899,44.588388],[-88.392548,44.414814],[-88.351963,44.400921],[-88.278537,44.414981],[-88.261128,44.473078],[-88.189941,44.574455],[-88.216197,44.589992]]]},"properties":{"id":"54165","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.662604,44.27205],[-91.618302,44.334477],[-91.700504,44.349364],[-91.919714,44.324487],[-91.833108,44.180509],[-91.662604,44.27205]]]},"properties":{"id":"54622","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.372933,44.29687],[-91.618302,44.334477],[-91.662604,44.27205],[-91.558933,44.140793],[-91.487819,44.157908],[-91.445755,44.155536],[-91.367047,44.186594],[-91.329874,44.247285],[-91.372933,44.29687]]]},"properties":{"id":"54612","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.268847,45.586515],[-92.59541,45.689251],[-92.615955,45.643036],[-92.610663,45.540744],[-92.592833,45.526779],[-92.545007,45.526741],[-92.367286,45.497953],[-92.284261,45.504721],[-92.248809,45.546746],[-92.268847,45.586515]]]},"properties":{"id":"54853","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.278202,45.761707],[-92.528198,45.72868],[-92.59541,45.689251],[-92.268847,45.586515],[-92.154888,45.639742],[-92.155238,45.706533],[-92.211934,45.773173],[-92.278202,45.761707]]]},"properties":{"id":"54837","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.305522,44.148162],[-88.254459,44.169949],[-88.297075,44.1577],[-88.305522,44.148162]]],[[[-88.30428,44.177789],[-88.24799,44.184185],[-88.223214,44.212782],[-88.303154,44.221257],[-88.444156,44.24011],[-88.464314,44.193883],[-88.43407,44.193581],[-88.30428,44.177789]]]]},"properties":{"id":"54952","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.444156,44.24011],[-88.303154,44.221257],[-88.298679,44.249138],[-88.324182,44.260907],[-88.355345,44.27693],[-88.415363,44.25311],[-88.436532,44.244308],[-88.444156,44.24011]]]},"properties":{"id":"54915","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.569727,44.900852],[-91.272677,44.823383],[-91.267085,44.84674],[-91.286728,44.99501],[-91.318245,45.052582],[-91.559848,45.008691],[-91.59133,44.943667],[-91.569727,44.900852]]]},"properties":{"id":"54729","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.868654,44.389825],[-90.690368,44.377797],[-90.558746,44.42221],[-90.801852,44.509573],[-90.892463,44.494809],[-90.868654,44.389825]]]},"properties":{"id":"54754","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.891197,45.119335],[-90.921437,44.759042],[-90.788436,44.779571],[-90.703651,44.915108],[-90.719222,45.031293],[-90.819967,45.086652],[-90.891197,45.119335]]]},"properties":{"id":"54771","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.396704,44.944447],[-90.198495,44.945364],[-90.217689,45.003293],[-90.397655,44.958964],[-90.396704,44.944447]]]},"properties":{"id":"54405","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.079668,44.61162],[-90.060676,44.697923],[-90.19885,44.771739],[-90.336003,44.685064],[-90.315757,44.512775],[-90.315784,44.495098],[-90.159296,44.540484],[-90.079668,44.61162]],[[-90.094616,44.640455],[-90.109476,44.637007],[-90.114866,44.644505],[-90.104854,44.646227],[-90.094616,44.640455]]]},"properties":{"id":"54449","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.872342,44.595563],[-90.980773,44.517507],[-90.892463,44.494809],[-90.801852,44.509573],[-90.804399,44.593528],[-90.872342,44.595563]]]},"properties":{"id":"54746","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.968827,43.264748],[-89.994771,43.195354],[-89.838135,43.206057],[-89.620486,43.220747],[-89.600672,43.242212],[-89.659547,43.292939],[-89.897807,43.316114],[-89.949599,43.329656],[-89.968827,43.264748]]]},"properties":{"id":"53583","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.12624,43.696576],[-89.271881,43.730575],[-89.2964,43.67204],[-89.200554,43.606755],[-89.126515,43.674357],[-89.136589,43.683099],[-89.12624,43.696576]]]},"properties":{"id":"53926","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.044951,43.52407],[-91.224319,43.518964],[-91.141255,43.367196],[-91.057765,43.450241],[-91.044951,43.52407]]]},"properties":{"id":"54624","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.428964,43.076553],[-89.428993,43.067976],[-89.413905,43.067802],[-89.425115,43.07652],[-89.429027,43.077339],[-89.428964,43.076553]]]},"properties":{"id":"53726","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.637273,43.313368],[-88.626838,43.383332],[-88.701481,43.458891],[-88.733887,43.458874],[-88.812864,43.357887],[-88.773681,43.321874],[-88.720409,43.306698],[-88.704842,43.313844],[-88.637273,43.313368]]]},"properties":{"id":"53039","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.873803,43.197986],[-88.585466,43.196867],[-88.565534,43.240697],[-88.578059,43.297307],[-88.637273,43.313368],[-88.704842,43.313844],[-88.704649,43.30658],[-88.720409,43.306698],[-88.773681,43.321874],[-88.821002,43.328967],[-88.873121,43.225971],[-88.873803,43.197986]],[[-88.63593,43.255321],[-88.636033,43.260301],[-88.621886,43.257006],[-88.63593,43.255321]]]},"properties":{"id":"53098","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.644567,43.596874],[-88.521315,43.572886],[-88.440913,43.631384],[-88.479325,43.675485],[-88.60162,43.632658],[-88.644567,43.596874]]]},"properties":{"id":"53006","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.821002,43.328967],[-88.773681,43.321874],[-88.812864,43.357887],[-88.821199,43.357982],[-88.821002,43.328967]]]},"properties":{"id":"53557","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.116594,45.241317],[-87.034303,45.213009],[-86.970236,45.279962],[-87.068453,45.295197],[-87.116594,45.241317]]]},"properties":{"id":"54210","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.291855,46.532778],[-92.05037,46.374384],[-92.044632,46.417861],[-92.01529,46.706469],[-92.291855,46.532778]]]},"properties":{"id":"54880","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.860594,46.535639],[-91.856404,46.503283],[-91.826233,46.474121],[-91.859389,46.535607],[-91.860594,46.535639]]]},"properties":{"id":"54842","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.375196,45.071381],[-92.298659,45.033282],[-92.196399,44.973782],[-92.155527,44.977104],[-92.136275,45.002679],[-92.156594,45.165221],[-92.239544,45.165474],[-92.32245,45.136401],[-92.375196,45.071381]]]},"properties":{"id":"54013","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.119749,44.596286],[-91.29077,44.679933],[-91.346638,44.679444],[-91.335702,44.471092],[-91.210948,44.425958],[-91.199999,44.424844],[-91.174841,44.414708],[-91.164813,44.418752],[-91.044342,44.509832],[-91.119749,44.596286]]]},"properties":{"id":"54758","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.529,44.494257],[-91.412728,44.456341],[-91.387189,44.683354],[-91.427768,44.700277],[-91.544219,44.683484],[-91.529,44.494257]]]},"properties":{"id":"54738","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.32529,45.721308],[-88.161957,45.717039],[-88.121832,45.831761],[-88.55937,46.014102],[-88.45583,45.84686],[-88.32529,45.721308]]]},"properties":{"id":"54121","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.940341,43.895416],[-88.956312,43.817372],[-88.885295,43.757124],[-88.725109,43.807738],[-88.700135,43.831825],[-88.660784,43.869448],[-88.766092,43.975135],[-88.774652,43.975292],[-88.805275,43.982709],[-88.940341,43.895416]]]},"properties":{"id":"54971","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.401041,43.630933],[-88.391047,43.543456],[-88.301151,43.529024],[-88.160379,43.586708],[-88.160035,43.617277],[-88.160702,43.690117],[-88.212941,43.717777],[-88.220172,43.717868],[-88.380569,43.660233],[-88.401041,43.630933]]]},"properties":{"id":"53010","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.885295,43.757124],[-88.845383,43.683855],[-88.657235,43.720061],[-88.725109,43.807738],[-88.885295,43.757124]],[[-88.860647,43.74423],[-88.845453,43.740736],[-88.845464,43.735054],[-88.86262,43.7351],[-88.860647,43.74423]]]},"properties":{"id":"53919","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.92538,45.860624],[-89.046541,45.664937],[-89.046781,45.637517],[-88.800152,45.622507],[-88.612396,45.636502],[-88.572731,45.722666],[-88.6755,45.797302],[-88.92538,45.860624]]]},"properties":{"id":"54511","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.828174,42.660771],[-90.661527,42.567999],[-90.602664,42.629781],[-90.602745,42.631739],[-90.573449,42.656499],[-90.630133,42.769922],[-90.82754,42.774466],[-90.828174,42.660771]]]},"properties":{"id":"53820","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485379,42.629832],[-89.522271,42.501972],[-89.409952,42.500656],[-89.485379,42.629832]]]},"properties":{"id":"53550","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.25177,43.86813],[-89.12776,43.770245],[-89.067584,43.789057],[-89.010931,43.89382],[-89.063528,43.907645],[-89.25177,43.86813]]]},"properties":{"id":"54968","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.172141,43.097884],[-90.25569,43.0892],[-90.309954,42.99686],[-90.317405,42.967534],[-90.301174,42.945671],[-90.260987,42.927286],[-90.003133,42.915351],[-89.956065,42.925956],[-89.956308,42.956861],[-90.034424,43.057258],[-90.172141,43.097884]]]},"properties":{"id":"53533","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.329627,42.915483],[-90.351541,42.892102],[-90.324292,42.872026],[-90.260987,42.927286],[-90.301174,42.945671],[-90.329627,42.915483]]]},"properties":{"id":"53553","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.928944,46.192122],[-89.929158,46.299748],[-90.120572,46.337039],[-90.237415,46.290593],[-90.255502,46.276329],[-90.303072,46.154849],[-90.231065,46.082585],[-89.92881,46.112743],[-89.928944,46.192122]]]},"properties":{"id":"54547","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.804313,46.069608],[-89.928548,46.068824],[-90.042833,45.887816],[-89.931455,45.830381],[-89.757317,45.913748],[-89.804313,46.069608]]]},"properties":{"id":"54538","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.174841,44.414708],[-91.369434,44.327086],[-91.372933,44.29687],[-91.329874,44.247285],[-91.152532,44.212231],[-91.121329,44.397675],[-91.164813,44.418752],[-91.174841,44.414708]]]},"properties":{"id":"54616","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.660317,42.90189],[-88.894426,43.007622],[-88.934364,43.015562],[-88.98434,42.919832],[-89.00011,42.847256],[-88.855749,42.844579],[-88.660317,42.90189]]]},"properties":{"id":"53538","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.660317,42.90189],[-88.855749,42.844579],[-88.856518,42.710791],[-88.841534,42.695927],[-88.777817,42.707795],[-88.661693,42.752093],[-88.55772,42.800062],[-88.553821,42.825258],[-88.576053,42.842695],[-88.651087,42.895374],[-88.660317,42.90189]]]},"properties":{"id":"53190","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.701666,43.071995],[-88.850016,43.099543],[-88.848158,43.086861],[-88.744951,43.030198],[-88.701666,43.071995]]]},"properties":{"id":"53038","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.255788,43.878501],[-90.261909,43.881569],[-90.277024,43.879593],[-90.269489,43.8745],[-90.255788,43.878501]]]},"properties":{"id":"54637","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.806961,42.556163],[-87.812531,42.580595],[-87.845515,42.578233],[-87.846964,42.538867],[-87.806961,42.556163]]]},"properties":{"id":"53143","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.031821,42.604586],[-87.953389,42.582309],[-87.845572,42.580851],[-87.841091,42.664279],[-87.863945,42.668487],[-87.869193,42.66857],[-87.894892,42.668949],[-87.952878,42.65492],[-88.031821,42.604586]]]},"properties":{"id":"53144","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.079162,42.529107],[-88.07772,42.49561],[-88.02505,42.495113],[-87.953389,42.582309],[-88.031821,42.604586],[-88.07934,42.611949],[-88.079162,42.529107]]]},"properties":{"id":"53104","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.241483,43.869755],[-91.209859,43.867144],[-91.136424,43.870034],[-91.173939,43.938816],[-91.337694,43.962511],[-91.241483,43.869755]]]},"properties":{"id":"54650","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.677826,45.378682],[-88.638493,45.19328],[-88.55911,45.2878],[-88.55398,45.377672],[-88.677826,45.378682]]]},"properties":{"id":"54175","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.608584,44.313044],[-87.715068,44.276822],[-87.720425,44.254869],[-87.711431,44.218485],[-87.620082,44.122451],[-87.539203,44.327509],[-87.603526,44.327496],[-87.608584,44.313044]]]},"properties":{"id":"54241","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.846493,43.862854],[-87.721489,43.892273],[-87.688832,43.979686],[-87.905046,43.935414],[-87.909787,43.904485],[-87.846493,43.862854]]]},"properties":{"id":"53015","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.49033,44.743802],[-89.345917,44.68138],[-89.34633,44.746236],[-89.25521,44.871053],[-89.346559,44.914129],[-89.422478,44.880097],[-89.491084,44.844716],[-89.49033,44.743802]]]},"properties":{"id":"54440","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.956609,44.777314],[-90.139311,44.887532],[-90.198308,44.85879],[-90.219125,44.821864],[-90.19885,44.771739],[-90.060676,44.697923],[-89.956609,44.777314]]]},"properties":{"id":"54484","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925673,44.771683],[-89.956609,44.777314],[-90.060676,44.697923],[-90.079668,44.61162],[-89.96455,44.592976],[-89.865274,44.713484],[-89.925673,44.771683]]]},"properties":{"id":"54412","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.79836,45.032563],[-89.727373,44.85777],[-89.644055,44.867785],[-89.624021,44.901468],[-89.632822,44.918668],[-89.642017,45.020892],[-89.663132,45.027383],[-89.747526,45.061675],[-89.79836,45.032563]]]},"properties":{"id":"54401","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.368933,45.114658],[-88.202788,45.025201],[-88.186266,45.024799],[-87.922087,45.063502],[-87.93211,45.11964],[-87.953291,45.136583],[-88.279961,45.20061],[-88.368933,45.114658]]]},"properties":{"id":"54161","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.150198,45.54442],[-88.11747,45.462967],[-87.834765,45.452286],[-87.803446,45.538344],[-88.058093,45.56226],[-88.150198,45.54442]]]},"properties":{"id":"54102","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.142826,44.047097],[-89.136523,44.105086],[-89.351331,44.155821],[-89.376462,44.17389],[-89.42035,44.155852],[-89.405588,44.054561],[-89.39545,43.96943],[-89.36527,43.95609],[-89.142826,44.047097]]]},"properties":{"id":"54982","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.968955,42.9401],[-87.96748,42.98118],[-87.968034,42.981179],[-88.017693,42.981013],[-88.018263,42.954217],[-87.968955,42.9401]]]},"properties":{"id":"53220","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.959456,43.192405],[-87.918093,43.103674],[-87.906884,43.098441],[-87.870536,43.099271],[-87.886186,43.192308],[-87.959456,43.192405]]]},"properties":{"id":"53217","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.573192,43.805215],[-90.649082,43.752945],[-90.655468,43.682743],[-90.535617,43.669302],[-90.478499,43.704156],[-90.47517,43.776723],[-90.573192,43.805215]]]},"properties":{"id":"54651","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.949999,44.779027],[-88.062092,44.732804],[-88.067188,44.677388],[-87.993747,44.677542],[-87.872245,44.833456],[-87.949999,44.779027]]]},"properties":{"id":"54141","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.482171,45.879652],[-89.42714,45.973672],[-89.570223,45.982319],[-89.601902,45.884362],[-89.482171,45.879652]]]},"properties":{"id":"54558","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.782315,43.542981],[-87.889713,43.623512],[-87.920866,43.610898],[-87.901002,43.543406],[-87.792096,43.508552],[-87.782315,43.542981]]]},"properties":{"id":"53013","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.040827,43.432433],[-88.020774,43.417413],[-88.037666,43.432458],[-88.040827,43.432433]]],[[[-88.040827,43.432433],[-88.040701,43.454481],[-88.040857,43.459528],[-88.063495,43.527285],[-88.321874,43.477973],[-88.301699,43.427552],[-88.040827,43.432433]]]]},"properties":{"id":"53090","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.356631,45.238139],[-92.447495,45.234407],[-92.467607,45.180335],[-92.32245,45.136401],[-92.239544,45.165474],[-92.356631,45.238139]]]},"properties":{"id":"54007","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.592833,45.526779],[-92.463631,45.409278],[-92.468381,45.489602],[-92.545007,45.526741],[-92.592833,45.526779]]]},"properties":{"id":"54824","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.545007,45.526741],[-92.468381,45.489602],[-92.367286,45.497953],[-92.545007,45.526741]]]},"properties":{"id":"54858","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.63869,44.598857],[-89.518522,44.497267],[-89.428536,44.422051],[-89.38586,44.471678],[-89.385884,44.479844],[-89.50883,44.648347],[-89.681828,44.68502],[-89.63869,44.598857]]]},"properties":{"id":"54482","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.38586,44.471678],[-89.264141,44.475779],[-89.238598,44.503948],[-89.223531,44.592082],[-89.34508,44.569283],[-89.385884,44.479844],[-89.38586,44.471678]],[[-89.311337,44.494159],[-89.299373,44.497291],[-89.299441,44.483319],[-89.311337,44.494159]]]},"properties":{"id":"54407","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.430833,45.449232],[-90.555636,45.420571],[-90.55554,45.377386],[-90.167,45.38142],[-90.042273,45.381866],[-90.042882,45.481751],[-90.166102,45.468047],[-90.430833,45.449232]]]},"properties":{"id":"54459","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.167,45.38142],[-90.292009,45.287212],[-90.085573,45.184991],[-90.045419,45.207938],[-90.042269,45.381417],[-90.042273,45.381866],[-90.167,45.38142]]]},"properties":{"id":"54470","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.910368,42.739182],[-87.864158,42.777495],[-87.872549,42.813846],[-88.001299,42.843596],[-88.069924,42.843323],[-88.11457,42.822217],[-88.115044,42.814901],[-87.971833,42.742271],[-87.910368,42.739182]]]},"properties":{"id":"53126","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.74687,43.57268],[-90.534252,43.55282],[-90.535617,43.669302],[-90.655468,43.682743],[-90.69437,43.656023],[-90.849206,43.624675],[-90.74687,43.57268]]]},"properties":{"id":"54639","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.291489,43.234911],[-90.291554,43.223263],[-90.272044,43.222284],[-90.272276,43.238832],[-90.291489,43.234911]]]},"properties":{"id":"53540","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.247989,42.599829],[-89.129929,42.620716],[-89.248882,42.657515],[-89.247989,42.599829]]]},"properties":{"id":"53576","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.214907,42.665091],[-89.19961,42.679402],[-89.219078,42.679404],[-89.214907,42.665091]]]},"properties":{"id":"53537","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.52088,45.40763],[-91.443866,45.291686],[-91.368988,45.333939],[-91.41947,45.552474],[-91.539486,45.568195],[-91.52088,45.40763]]]},"properties":{"id":"54895","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.368988,45.333939],[-91.296449,45.325756],[-91.218435,45.346474],[-91.197505,45.592318],[-91.404785,45.638044],[-91.41947,45.552474],[-91.368988,45.333939]]]},"properties":{"id":"54819","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.683317,45.210045],[-92.758008,45.209566],[-92.745751,45.123314],[-92.700663,45.066173],[-92.645794,45.078685],[-92.683317,45.210045]]]},"properties":{"id":"54025","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.949434,43.416627],[-89.856822,43.489477],[-89.916836,43.511066],[-89.99406,43.446601],[-89.949434,43.416627]]]},"properties":{"id":"53961","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.123977,43.453348],[-90.068844,43.346636],[-89.951821,43.331223],[-89.949434,43.416627],[-89.99406,43.446601],[-90.137703,43.467575],[-90.123977,43.453348]]]},"properties":{"id":"53943","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.296616,45.761182],[-91.173906,45.725604],[-91.173099,45.823559],[-91.173109,45.855488],[-91.296616,45.761182]]]},"properties":{"id":"54867","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.691887,43.760556],[-87.779687,43.759994],[-87.799976,43.726348],[-87.784819,43.659463],[-87.733243,43.629343],[-87.691887,43.760556]]]},"properties":{"id":"53081","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.369434,44.327086],[-91.174841,44.414708],[-91.199999,44.424844],[-91.199976,44.422484],[-91.213326,44.422715],[-91.210948,44.425958],[-91.335702,44.471092],[-91.374205,44.428317],[-91.369434,44.327086]]]},"properties":{"id":"54773","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.199999,44.424844],[-91.210948,44.425958],[-91.213326,44.422715],[-91.199976,44.422484],[-91.199999,44.424844]]]},"properties":{"id":"54760","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.827936,43.438589],[-90.683159,43.431587],[-90.730807,43.495204],[-90.827936,43.438589]]]},"properties":{"id":"54652","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.661693,42.752093],[-88.777817,42.707795],[-88.776683,42.652746],[-88.678243,42.579273],[-88.65853,42.557511],[-88.57054,42.582698],[-88.540847,42.600207],[-88.540976,42.626443],[-88.661693,42.752093]]]},"properties":{"id":"53115","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.366568,43.017971],[-88.36753,42.991684],[-88.249358,43.002962],[-88.205843,43.065452],[-88.344908,43.049264],[-88.366568,43.017971]]]},"properties":{"id":"53188","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.144873,44.336491],[-89.143565,44.335592],[-89.143058,44.336215],[-89.144873,44.336491]]]},"properties":{"id":"54946","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.959142,44.126637],[-88.966155,44.141487],[-89.006664,44.141223],[-88.986213,44.119708],[-88.959142,44.126637]]]},"properties":{"id":"54967","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.710619,44.155037],[-88.8857,44.111737],[-88.885822,44.096863],[-88.711007,44.100322],[-88.66065,44.101698],[-88.653457,44.107098],[-88.644541,44.169748],[-88.710619,44.155037]]]},"properties":{"id":"54986","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.52092,44.112415],[-88.581519,44.045632],[-88.520014,44.003684],[-88.469551,44.117794],[-88.52092,44.112415]],[[-88.522326,44.074562],[-88.521256,44.077836],[-88.514234,44.075827],[-88.51699,44.072942],[-88.522326,44.074562]]]},"properties":{"id":"54901","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.522326,44.074562],[-88.51699,44.072942],[-88.514234,44.075827],[-88.521256,44.077836],[-88.522326,44.074562]]]},"properties":{"id":"54985","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.116415,44.336616],[-90.199176,44.249108],[-90.182037,44.249135],[-90.048093,44.248941],[-90.116415,44.336616]]]},"properties":{"id":"54413","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.054339,44.394741],[-89.905231,44.511666],[-90.079894,44.469949],[-90.054339,44.394741]]]},"properties":{"id":"54489","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.777522,44.140902],[-89.971269,44.160567],[-89.961465,43.966333],[-89.778007,44.111952],[-89.777522,44.140902]]]},"properties":{"id":"54613","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.92475,46.243523],[-91.035947,46.154894],[-90.924634,46.068],[-90.88352,46.114002],[-90.86551,46.25785],[-90.92475,46.243523]]]},"properties":{"id":"54517","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.248809,45.546746],[-92.022053,45.466543],[-91.972088,45.455541],[-91.891803,45.4992],[-91.90816,45.610089],[-92.154888,45.639742],[-92.268847,45.586515],[-92.248809,45.546746]]]},"properties":{"id":"54829","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.770133,45.437557],[-91.891803,45.4992],[-91.972088,45.455541],[-91.997136,45.347509],[-91.972562,45.309067],[-91.972888,45.28433],[-91.808073,45.329653],[-91.787156,45.351181],[-91.770133,45.437557]]]},"properties":{"id":"54812","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.509122,46.748293],[-91.287121,46.684705],[-91.238434,46.704951],[-91.551395,46.755816],[-91.509122,46.748293]]]},"properties":{"id":"54865","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.551282,46.157045],[-92.05037,46.374384],[-92.050268,46.332551],[-92.049636,46.157597],[-91.551282,46.157045]]]},"properties":{"id":"54838","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.351963,44.400921],[-88.323031,44.297968],[-88.293259,44.274866],[-88.298679,44.249138],[-88.303154,44.221257],[-88.223214,44.212782],[-88.183696,44.226909],[-88.125422,44.241088],[-88.172103,44.313289],[-88.162178,44.342461],[-88.278537,44.414981],[-88.351963,44.400921]]]},"properties":{"id":"54130","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.931853,44.534724],[-88.006744,44.475109],[-88.012602,44.447192],[-87.927746,44.400619],[-87.785697,44.458347],[-87.80581,44.501761],[-87.810586,44.635531],[-87.931853,44.534724]]]},"properties":{"id":"54311","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.109517,44.523605],[-88.102353,44.474129],[-88.055603,44.464791],[-88.021243,44.508493],[-88.109517,44.523605]]]},"properties":{"id":"54304","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.149944,44.487664],[-88.189941,44.574455],[-88.261128,44.473078],[-88.149944,44.487664]]]},"properties":{"id":"54155","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.677008,44.399271],[-91.85208,44.476129],[-92.03233,44.386431],[-91.919714,44.324487],[-91.700504,44.349364],[-91.677008,44.399271]]]},"properties":{"id":"54610","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.128511,45.815687],[-91.825512,45.766916],[-91.663425,45.811384],[-91.661686,45.838957],[-91.743054,45.888185],[-92.023803,46.009939],[-92.104281,45.9581],[-92.154272,45.816032],[-92.128511,45.815687]]]},"properties":{"id":"54801","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.082269,43.906279],[-88.042349,44.00176],[-88.222204,43.935154],[-88.212803,43.891757],[-88.162274,43.891511],[-88.082269,43.906279]]]},"properties":{"id":"53061","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.296169,45.134431],[-91.318157,45.190816],[-91.666402,45.120823],[-91.559848,45.008691],[-91.318245,45.052582],[-91.296169,45.134431]]]},"properties":{"id":"54724","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.982714,45.119331],[-90.983404,44.77068],[-90.921437,44.759042],[-90.891197,45.119335],[-90.982714,45.119331]]]},"properties":{"id":"54768","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.788187,44.943929],[-91.7437,44.767729],[-91.675237,44.760448],[-91.649859,44.780598],[-91.569727,44.900852],[-91.59133,44.943667],[-91.788187,44.943929]]]},"properties":{"id":"54739","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.496586,44.654471],[-90.497221,44.422613],[-90.315784,44.495098],[-90.315757,44.512775],[-90.417586,44.661299],[-90.455761,44.683383],[-90.496586,44.654471]]]},"properties":{"id":"54436","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.788436,44.779571],[-90.690168,44.698293],[-90.557687,44.662089],[-90.536562,44.835311],[-90.641878,44.885872],[-90.703651,44.915108],[-90.788436,44.779571]]]},"properties":{"id":"54437","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246599,43.507808],[-89.126484,43.475026],[-89.116244,43.478727],[-89.062372,43.581447],[-89.077045,43.581293],[-89.077168,43.595822],[-89.067108,43.595956],[-89.046921,43.647326],[-89.126515,43.674357],[-89.200554,43.606755],[-89.246599,43.507808]]]},"properties":{"id":"53923","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.062372,43.581447],[-89.067108,43.595956],[-89.077168,43.595822],[-89.077045,43.581293],[-89.062372,43.581447]]]},"properties":{"id":"53935","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.448343,43.025311],[-89.404028,43.056739],[-89.408995,43.07012],[-89.413905,43.067802],[-89.428993,43.067976],[-89.484981,43.054779],[-89.448343,43.025311]]],[[[-89.448343,43.025311],[-89.459207,43.001135],[-89.462871,42.979537],[-89.340109,42.98991],[-89.321944,43.035054],[-89.448343,43.025311]]]]},"properties":{"id":"53711","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.795488,43.064191],[-89.838485,42.923717],[-89.83838,42.915325],[-89.838185,42.855624],[-89.720218,42.857489],[-89.698675,42.857481],[-89.650542,42.911145],[-89.645864,43.046207],[-89.720307,43.075665],[-89.795488,43.064191]]]},"properties":{"id":"53572","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.312266,43.164614],[-89.362056,43.176734],[-89.413196,43.147046],[-89.370259,43.095001],[-89.353703,43.085527],[-89.33429,43.089845],[-89.285628,43.104179],[-89.312266,43.164614]]]},"properties":{"id":"53704","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.720218,42.857489],[-89.747147,42.782303],[-89.583184,42.790459],[-89.698675,42.857481],[-89.720218,42.857489]]]},"properties":{"id":"53574","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.363022,43.275511],[-89.344425,43.27444],[-89.344491,43.281174],[-89.363022,43.275511]]]},"properties":{"id":"53571","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.644616,43.568551],[-88.644257,43.509323],[-88.561469,43.457814],[-88.480263,43.399513],[-88.460225,43.399419],[-88.449082,43.44254],[-88.479961,43.54399],[-88.521315,43.572886],[-88.644567,43.596874],[-88.644616,43.568551]]]},"properties":{"id":"53050","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.440913,43.631384],[-88.521315,43.572886],[-88.479961,43.54399],[-88.400608,43.536911],[-88.391047,43.543456],[-88.401041,43.630933],[-88.440913,43.631384]]]},"properties":{"id":"53048","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.513579,43.34079],[-88.478088,43.251068],[-88.435189,43.22437],[-88.460225,43.399419],[-88.480263,43.399513],[-88.513579,43.34079]]]},"properties":{"id":"53078","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.783496,43.545717],[-88.733887,43.458874],[-88.701481,43.458891],[-88.644257,43.509323],[-88.644616,43.568551],[-88.775895,43.560235],[-88.783496,43.545717]]]},"properties":{"id":"53922","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.887172,44.693811],[-92.014355,44.771563],[-92.032696,44.698462],[-91.999916,44.683908],[-91.887172,44.693811]]]},"properties":{"id":"54737","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.427768,44.700277],[-91.374508,44.810616],[-91.383101,44.811497],[-91.415282,44.785616],[-91.468437,44.816018],[-91.649859,44.780598],[-91.675237,44.760448],[-91.544219,44.683484],[-91.427768,44.700277]]]},"properties":{"id":"54701","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.468437,44.816018],[-91.415282,44.785616],[-91.383101,44.811497],[-91.468437,44.816018]]]},"properties":{"id":"54720","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.956169,43.630907],[-88.020163,43.688998],[-88.160834,43.703438],[-88.160702,43.690117],[-88.160035,43.617277],[-87.956169,43.630907]]]},"properties":{"id":"53011","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.768809,43.020348],[-90.774627,42.955883],[-90.665339,42.90563],[-90.477638,42.944329],[-90.521812,43.045483],[-90.533803,43.075116],[-90.705984,43.037643],[-90.768809,43.020348]]]},"properties":{"id":"53809","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.477638,42.944329],[-90.665339,42.90563],[-90.546712,42.880968],[-90.477638,42.944329]]]},"properties":{"id":"53825","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.808856,43.099705],[-90.879581,43.073558],[-90.940588,43.039781],[-90.768809,43.020348],[-90.705984,43.037643],[-90.808856,43.099705]]]},"properties":{"id":"53827","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.516386,42.780983],[-89.486113,42.68277],[-89.486077,42.679118],[-89.367611,42.686602],[-89.369173,42.784667],[-89.471521,42.792333],[-89.516386,42.780983]]]},"properties":{"id":"53502","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.172141,43.097884],[-90.193814,43.164464],[-90.331048,43.209044],[-90.370111,43.118679],[-90.25569,43.0892],[-90.172141,43.097884]]]},"properties":{"id":"53506","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.044342,44.509832],[-91.164813,44.418752],[-91.121329,44.397675],[-91.00883,44.294436],[-90.92363,44.360918],[-91.025406,44.516653],[-91.044342,44.509832]]]},"properties":{"id":"54635","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.980773,44.517507],[-91.025406,44.516653],[-90.92363,44.360918],[-90.868654,44.389825],[-90.892463,44.494809],[-90.980773,44.517507]]]},"properties":{"id":"54611","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.620054,44.072516],[-90.342404,44.017131],[-90.232253,44.111371],[-90.312035,44.248758],[-90.317893,44.259861],[-90.357727,44.278909],[-90.591759,44.335594],[-90.625742,44.160028],[-90.620054,44.072516]]]},"properties":{"id":"54666","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.585466,43.196867],[-88.873803,43.197986],[-88.891011,43.159049],[-88.850016,43.099543],[-88.701666,43.071995],[-88.65862,43.059753],[-88.620402,43.085082],[-88.618138,43.107919],[-88.585466,43.196867]]]},"properties":{"id":"53094","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.285332,43.846557],[-90.401375,43.709013],[-90.290935,43.678991],[-90.178768,43.722056],[-90.208966,43.81852],[-90.258851,43.828478],[-90.285332,43.846557]]]},"properties":{"id":"53929","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.304692,42.494618],[-88.198576,42.495141],[-88.188335,42.522074],[-88.280728,42.541043],[-88.304692,42.494618]]]},"properties":{"id":"53181","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.07772,42.49561],[-88.079162,42.529107],[-88.170379,42.541412],[-88.175391,42.524995],[-88.181443,42.495303],[-88.07772,42.49561]]]},"properties":{"id":"53179","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.178097,42.55659],[-88.170379,42.541412],[-88.142038,42.552295],[-88.157404,42.561186],[-88.178097,42.55659]]]},"properties":{"id":"53170","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.955266,43.751673],[-90.998116,43.652025],[-91.012669,43.60929],[-90.849206,43.624675],[-90.69437,43.656023],[-90.955266,43.751673]]]},"properties":{"id":"54667","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.130422,43.740965],[-91.200736,43.738992],[-91.224918,43.593186],[-91.152764,43.629968],[-91.108783,43.72294],[-91.130422,43.740965]]]},"properties":{"id":"54658","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.548537,45.465036],[-89.776334,45.323229],[-89.59125,45.293911],[-89.548537,45.465036]]]},"properties":{"id":"54442","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925219,45.712128],[-89.905928,45.768079],[-89.931455,45.830381],[-90.042833,45.887816],[-90.044853,45.817653],[-90.042849,45.662371],[-90.042882,45.481751],[-90.042273,45.381866],[-90.042269,45.381417],[-89.979947,45.380558],[-89.925219,45.712128]]]},"properties":{"id":"54564","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.681409,43.999961],[-87.821813,44.00807],[-87.883872,43.963883],[-87.905046,43.935414],[-87.688832,43.979686],[-87.681409,43.999961]]]},"properties":{"id":"53063","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.144903,44.886972],[-89.34633,44.746236],[-89.345917,44.68138],[-89.223745,44.681365],[-89.184526,44.681003],[-89.012114,44.821922],[-89.062413,44.903528],[-89.144903,44.886972]]]},"properties":{"id":"54499","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.679319,45.291242],[-90.679255,45.159139],[-90.557098,45.074635],[-90.495697,45.088852],[-90.433891,45.059948],[-90.376269,45.04755],[-90.197571,45.091258],[-90.085573,45.184991],[-90.292009,45.287212],[-90.656676,45.349121],[-90.679319,45.291242]]]},"properties":{"id":"54451","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.844931,44.684945],[-89.844545,44.526016],[-89.747115,44.525779],[-89.63869,44.598857],[-89.681828,44.68502],[-89.844931,44.684945]]]},"properties":{"id":"54443","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.656594,44.971254],[-87.843065,45.135027],[-87.93211,45.11964],[-87.922087,45.063502],[-87.902925,44.99323],[-87.762639,44.962968],[-87.656594,44.971254]]]},"properties":{"id":"54157","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.735704,44.940998],[-88.854306,44.943028],[-88.828287,44.943161],[-88.735591,44.921653],[-88.735704,44.940998]]]},"properties":{"id":"54150","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.919228,42.98161],[-87.91789,42.930179],[-87.887676,42.930034],[-87.88319,42.959128],[-87.853251,42.986464],[-87.889266,43.025621],[-87.902376,43.025129],[-87.918698,43.006473],[-87.919228,42.98161]]]},"properties":{"id":"53207","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.063353,43.192117],[-88.065085,43.133544],[-88.015654,43.134105],[-88.015615,43.134106],[-88.013677,43.192537],[-88.063353,43.192117]]]},"properties":{"id":"53224","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.920164,43.045731],[-87.918206,43.029512],[-87.910809,43.032064],[-87.911528,43.048645],[-87.920164,43.045731]]]},"properties":{"id":"53203","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.919873,43.063944],[-87.924376,43.04575],[-87.920164,43.045731],[-87.911528,43.048645],[-87.893645,43.060166],[-87.906884,43.098441],[-87.918093,43.103674],[-87.919598,43.089325],[-87.919873,43.063944]]]},"properties":{"id":"53212","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.88319,42.959128],[-87.841527,42.96629],[-87.853251,42.986464],[-87.88319,42.959128]]]},"properties":{"id":"53235","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.069489,42.930115],[-88.069924,42.843323],[-88.001299,42.843596],[-87.951992,42.843547],[-87.949414,42.929536],[-87.9692,42.926896],[-88.028563,42.930509],[-88.069489,42.930115]]]},"properties":{"id":"53132","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.301535,45.469351],[-89.046315,45.464762],[-89.046688,45.549791],[-89.364049,45.469886],[-89.301535,45.469351]]]},"properties":{"id":"54463","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.516645,44.330184],[-88.592535,44.271194],[-88.557424,44.243709],[-88.49638,44.273021],[-88.516645,44.330184]]]},"properties":{"id":"54942","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.636136,44.502462],[-88.615522,44.388964],[-88.506359,44.38415],[-88.46834,44.573994],[-88.515472,44.677824],[-88.605447,44.653734],[-88.616312,44.574824],[-88.636136,44.502462]]]},"properties":{"id":"54170","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.678471,44.269633],[-88.673789,44.273038],[-88.673848,44.280541],[-88.678181,44.280628],[-88.68043,44.273008],[-88.688484,44.276301],[-88.678471,44.269633]]]},"properties":{"id":"54931","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.773753,44.729553],[-88.889922,44.534944],[-88.616312,44.574824],[-88.605447,44.653734],[-88.773753,44.729553]],[[-88.712586,44.664867],[-88.707181,44.678913],[-88.690366,44.672741],[-88.712586,44.664867]]]},"properties":{"id":"54929","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.998745,43.351605],[-87.942368,43.280033],[-87.898887,43.280405],[-87.86295,43.381054],[-87.920444,43.378541],[-87.998745,43.351605]]]},"properties":{"id":"53024","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.942368,43.280033],[-87.998745,43.351605],[-88.040126,43.367313],[-88.122252,43.335534],[-88.083287,43.279247],[-88.063292,43.278881],[-87.942368,43.280033]]]},"properties":{"id":"53012","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.920444,43.378541],[-87.940255,43.419818],[-88.040857,43.459528],[-88.040701,43.454481],[-88.037666,43.432458],[-88.020774,43.417413],[-88.040126,43.367313],[-87.998745,43.351605],[-87.920444,43.378541]]]},"properties":{"id":"53080","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.256628,44.685353],[-92.200575,44.568467],[-92.155334,44.539478],[-92.134906,44.586381],[-92.135153,44.698864],[-92.256628,44.685353]]]},"properties":{"id":"54761","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.698959,45.33953],[-92.500218,45.309912],[-92.510409,45.382062],[-92.650474,45.401921],[-92.698959,45.33953]]]},"properties":{"id":"54009","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.518522,44.497267],[-89.63869,44.598857],[-89.747115,44.525779],[-89.726331,44.447702],[-89.625549,44.452764],[-89.518522,44.497267]]]},"properties":{"id":"54481","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.191788,43.457984],[-90.2521,43.554484],[-90.330136,43.554031],[-90.360488,43.480155],[-90.191872,43.391756],[-90.191788,43.457984]]]},"properties":{"id":"53924","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.030787,42.784407],[-89.114577,42.759845],[-89.01481,42.671611],[-88.998404,42.729608],[-89.030787,42.784407]]]},"properties":{"id":"53545","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.01481,42.671611],[-89.07132,42.613591],[-88.926664,42.582879],[-88.859757,42.610718],[-88.841534,42.695927],[-88.856518,42.710791],[-88.998404,42.729608],[-89.01481,42.671611]]]},"properties":{"id":"53546","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.841534,42.695927],[-88.859757,42.610718],[-88.815491,42.624612],[-88.776683,42.652746],[-88.777817,42.707795],[-88.841534,42.695927]]]},"properties":{"id":"53505","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.960816,45.554939],[-91.02833,45.42272],[-90.98041,45.38662],[-90.931171,45.431586],[-90.960816,45.554939]]]},"properties":{"id":"54563","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.155527,44.977104],[-92.196399,44.973782],[-92.258371,44.893099],[-92.136216,44.874096],[-92.136033,44.897691],[-92.155527,44.977104]]]},"properties":{"id":"54027","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.856822,43.489477],[-89.949434,43.416627],[-89.951821,43.331223],[-89.949599,43.329656],[-89.897807,43.316114],[-89.76851,43.390722],[-89.856822,43.489477]]]},"properties":{"id":"53951","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.041033,43.815394],[-88.160616,43.71761],[-88.160834,43.703438],[-88.020163,43.688998],[-87.920886,43.696425],[-87.875846,43.848339],[-88.041033,43.815394]]]},"properties":{"id":"53073","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.160616,43.71761],[-88.041033,43.815394],[-88.100122,43.840975],[-88.160616,43.71761]]]},"properties":{"id":"53023","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.849206,43.624675],[-91.012669,43.60929],[-91.104201,43.60379],[-91.044951,43.52407],[-91.057765,43.450241],[-90.947455,43.423899],[-90.827936,43.438589],[-90.730807,43.495204],[-90.74687,43.57268],[-90.849206,43.624675]]]},"properties":{"id":"54665","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.661693,42.752093],[-88.540976,42.626443],[-88.413611,42.645915],[-88.406433,42.756351],[-88.55772,42.800062],[-88.661693,42.752093]]]},"properties":{"id":"53121","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.541535,42.842996],[-88.576053,42.842695],[-88.553821,42.825258],[-88.541535,42.842996]]],[[[-88.541535,42.842996],[-88.449093,42.842623],[-88.424197,42.915124],[-88.416828,42.945129],[-88.542058,42.92704],[-88.541535,42.842996]]]]},"properties":{"id":"53119","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.301151,43.529024],[-88.391047,43.543456],[-88.400608,43.536911],[-88.401033,43.442841],[-88.301161,43.405624],[-88.301699,43.427552],[-88.321874,43.477973],[-88.301151,43.529024]]]},"properties":{"id":"53002","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.400677,43.153403],[-88.38537,43.076546],[-88.363397,43.061689],[-88.308025,43.119545],[-88.262188,43.18008],[-88.338113,43.192932],[-88.41408,43.194414],[-88.400677,43.153403]]]},"properties":{"id":"53029","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.191237,43.250775],[-88.123088,43.272164],[-88.210285,43.295357],[-88.300431,43.281351],[-88.289964,43.266922],[-88.191237,43.250775]]]},"properties":{"id":"53076","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.912552,44.416873],[-88.818093,44.502622],[-88.901971,44.534949],[-88.935408,44.568172],[-88.981092,44.411396],[-88.912552,44.416873]]]},"properties":{"id":"54949","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.653457,44.107098],[-88.66065,44.101698],[-88.643539,44.097442],[-88.653457,44.107098]]]},"properties":{"id":"54927","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.704662,43.982125],[-88.630359,43.894626],[-88.613907,43.894492],[-88.583718,43.908907],[-88.581519,44.045632],[-88.52092,44.112415],[-88.584278,44.170235],[-88.644541,44.169748],[-88.653457,44.107098],[-88.643539,44.097442],[-88.658693,44.052557],[-88.704662,43.982125]]]},"properties":{"id":"54904","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.670384,44.24332],[-88.799592,44.178852],[-88.710619,44.155037],[-88.644541,44.169748],[-88.584278,44.170235],[-88.624128,44.243683],[-88.670384,44.24332]]]},"properties":{"id":"54947","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.832778,43.997858],[-88.8458,44.002466],[-88.845434,43.99799],[-88.832778,43.997858]]]},"properties":{"id":"54934","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.827715,43.825573],[-89.71705,43.783614],[-89.59732,43.8862],[-89.657687,43.948365],[-89.658084,43.970466],[-89.727458,43.95241],[-89.827715,43.825573]]]},"properties":{"id":"53936","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.5631,43.69356],[-89.615621,43.673412],[-89.599985,43.642596],[-89.56355,43.65014],[-89.5631,43.69356]]]},"properties":{"id":"53920","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.88352,46.114002],[-90.924634,46.068],[-90.92278,45.981028],[-90.860847,45.980526],[-90.35262,46.154924],[-90.363112,46.155022],[-90.55196,46.262305],[-90.88352,46.114002]]]},"properties":{"id":"54527","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.235692,46.360535],[-91.089852,46.30875],[-90.925593,46.300254],[-90.90456,46.42094],[-91.205537,46.588574],[-91.30393,46.578712],[-91.4478,46.460034],[-91.489461,46.459958],[-91.428201,46.408799],[-91.235692,46.360535]]]},"properties":{"id":"54856","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.262035,45.467751],[-92.07922,45.452902],[-92.022053,45.466543],[-92.248809,45.546746],[-92.284261,45.504721],[-92.262035,45.467751]]]},"properties":{"id":"54826","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.058929,45.359874],[-91.997136,45.347509],[-91.972088,45.455541],[-92.022053,45.466543],[-92.07922,45.452902],[-92.058929,45.359874]]]},"properties":{"id":"54805","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.228704,45.380977],[-92.285688,45.302704],[-92.084452,45.209233],[-91.972562,45.309067],[-91.997136,45.347509],[-92.058929,45.359874],[-92.228704,45.380977]]]},"properties":{"id":"54004","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.609574,45.591362],[-91.593332,45.58917],[-91.594533,45.595132],[-91.601454,45.595199],[-91.609574,45.591362]]]},"properties":{"id":"54857","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.428201,46.408799],[-91.418664,46.244722],[-91.184026,46.301083],[-91.235692,46.360535],[-91.428201,46.408799]]]},"properties":{"id":"54832","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.184026,46.301083],[-91.089852,46.30875],[-91.235692,46.360535],[-91.184026,46.301083]]]},"properties":{"id":"54839","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.055603,44.464791],[-88.012602,44.447192],[-88.006744,44.475109],[-88.011303,44.521692],[-88.021243,44.508493],[-88.055603,44.464791]],[[-88.033337,44.467831],[-88.041731,44.470864],[-88.039591,44.472794],[-88.031925,44.469854],[-88.033337,44.467831]]]},"properties":{"id":"54301","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.154275,44.212459],[-88.154329,44.209747],[-88.094558,44.122313],[-88.089115,44.116729],[-88.04979,44.080923],[-88.017856,44.099085],[-88.00254,44.24089],[-88.125422,44.241088],[-88.183696,44.226909],[-88.158108,44.212461],[-88.154275,44.212459]],[[-88.154275,44.212459],[-88.132214,44.212214],[-88.142292,44.206919],[-88.154275,44.212459]]]},"properties":{"id":"54110","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.810586,44.635531],[-87.80581,44.501761],[-87.793714,44.638212],[-87.810586,44.635531]]]},"properties":{"id":"54229","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.636771,44.327506],[-87.608584,44.313044],[-87.603526,44.327496],[-87.636771,44.327506]]],[[[-87.636771,44.327506],[-87.704666,44.407234],[-87.785697,44.458347],[-87.927746,44.400619],[-87.948313,44.327885],[-87.887999,44.284034],[-87.755524,44.309582],[-87.636771,44.327506]]]]},"properties":{"id":"54208","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.172103,44.313289],[-88.146345,44.335621],[-88.162178,44.342461],[-88.172103,44.313289]]]},"properties":{"id":"54180","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.869179,44.703615],[-91.840624,44.553576],[-91.85208,44.476129],[-91.677008,44.399271],[-91.529,44.494257],[-91.544219,44.683484],[-91.675237,44.760448],[-91.7437,44.767729],[-91.869179,44.703615]]]},"properties":{"id":"54755","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.272677,44.823383],[-91.569727,44.900852],[-91.649859,44.780598],[-91.468437,44.816018],[-91.383101,44.811497],[-91.374508,44.810616],[-91.272677,44.823383]]]},"properties":{"id":"54703","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.679319,45.291242],[-90.941037,45.236827],[-91.027381,45.162682],[-91.027436,45.150895],[-90.982714,45.119331],[-90.891197,45.119335],[-90.819967,45.086652],[-90.681797,45.119023],[-90.679255,45.159139],[-90.679319,45.291242]]]},"properties":{"id":"54433","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.941037,45.236827],[-90.679319,45.291242],[-90.656676,45.349121],[-90.658417,45.377637],[-90.730185,45.429584],[-90.931171,45.431586],[-90.98041,45.38662],[-91.038398,45.291961],[-90.941037,45.236827]]]},"properties":{"id":"54766","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.428549,44.821051],[-90.455761,44.683383],[-90.417586,44.661299],[-90.336003,44.685064],[-90.19885,44.771739],[-90.219125,44.821864],[-90.428549,44.821051]]]},"properties":{"id":"54479","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.198308,44.85879],[-90.456358,44.871461],[-90.495942,44.849796],[-90.428549,44.821051],[-90.219125,44.821864],[-90.198308,44.85879]]]},"properties":{"id":"54488","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.315784,44.495098],[-90.497221,44.422613],[-90.558746,44.42221],[-90.690368,44.377797],[-90.591759,44.335594],[-90.357727,44.278909],[-90.31766,44.291998],[-90.317893,44.259861],[-90.312035,44.248758],[-90.199176,44.249108],[-90.116415,44.336616],[-90.054339,44.394741],[-90.079894,44.469949],[-90.159296,44.540484],[-90.315784,44.495098]]]},"properties":{"id":"54466","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.718827,43.391097],[-89.684306,43.355406],[-89.560739,43.395006],[-89.555452,43.399336],[-89.582076,43.475856],[-89.718827,43.391097]]]},"properties":{"id":"53561","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.126484,43.475026],[-89.246599,43.507808],[-89.27933,43.495271],[-89.305484,43.48477],[-89.363725,43.455528],[-89.246631,43.311084],[-89.2316,43.282328],[-89.207598,43.282474],[-89.160159,43.42383],[-89.160767,43.430411],[-89.145515,43.427521],[-89.125896,43.431337],[-89.126484,43.475026]]]},"properties":{"id":"53960","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.27933,43.495271],[-89.309738,43.500181],[-89.305484,43.48477],[-89.27933,43.495271]]]},"properties":{"id":"53969","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.42097,43.078222],[-89.482449,43.094649],[-89.492521,43.082669],[-89.502755,43.060698],[-89.484981,43.054779],[-89.428993,43.067976],[-89.428964,43.076553],[-89.432129,43.07656],[-89.431922,43.078764],[-89.429027,43.077339],[-89.425115,43.07652],[-89.42097,43.078222]]]},"properties":{"id":"53705","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.290297,42.859148],[-89.485625,42.906908],[-89.471521,42.792333],[-89.369173,42.784667],[-89.269879,42.842469],[-89.290297,42.859148]]]},"properties":{"id":"53521","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.153366,43.130572],[-89.150371,43.006635],[-89.121217,42.992285],[-89.011037,43.057284],[-89.011028,43.064268],[-89.153366,43.130572]]]},"properties":{"id":"53531","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.312477,43.042319],[-89.337942,43.052607],[-89.377865,43.055823],[-89.404028,43.056739],[-89.448343,43.025311],[-89.321944,43.035054],[-89.312477,43.042319]]]},"properties":{"id":"53713","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.897635,43.112779],[-89.838485,42.923717],[-89.795488,43.064191],[-89.838034,43.127629],[-89.837995,43.137273],[-89.897635,43.112779]]]},"properties":{"id":"53517","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.298188,42.961289],[-89.290297,42.859148],[-89.269879,42.842469],[-89.186465,42.846353],[-89.097769,42.952317],[-89.121217,42.992285],[-89.150371,43.006635],[-89.23687,43.014864],[-89.298188,42.961289]]]},"properties":{"id":"53589","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.429027,43.077339],[-89.431922,43.078764],[-89.432129,43.07656],[-89.428964,43.076553],[-89.429027,43.077339]]]},"properties":{"id":"53792","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.698675,42.857481],[-89.583184,42.790459],[-89.516386,42.780983],[-89.471521,42.792333],[-89.485625,42.906908],[-89.48549,42.948283],[-89.650542,42.911145],[-89.698675,42.857481]]]},"properties":{"id":"53508","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.661325,43.166129],[-89.720307,43.075665],[-89.645864,43.046207],[-89.593197,43.083595],[-89.561432,43.16924],[-89.561792,43.191047],[-89.620312,43.20586],[-89.661325,43.166129]]]},"properties":{"id":"53528","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.370259,43.095001],[-89.397281,43.077004],[-89.397376,43.074902],[-89.389408,43.063917],[-89.353703,43.085527],[-89.370259,43.095001]]]},"properties":{"id":"53703","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.956065,42.925956],[-90.003133,42.915351],[-89.934808,42.846674],[-89.838185,42.855624],[-89.83838,42.915325],[-89.956065,42.925956]]]},"properties":{"id":"53544","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.626838,43.383332],[-88.637273,43.313368],[-88.578059,43.297307],[-88.592615,43.346953],[-88.617479,43.38223],[-88.626838,43.383332]]]},"properties":{"id":"53034","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.561469,43.457814],[-88.617479,43.38223],[-88.592615,43.346953],[-88.513579,43.34079],[-88.480263,43.399513],[-88.561469,43.457814]]]},"properties":{"id":"53035","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.300431,43.281351],[-88.277641,43.369318],[-88.301161,43.405624],[-88.401033,43.442841],[-88.449082,43.44254],[-88.460225,43.399419],[-88.435189,43.22437],[-88.41408,43.194414],[-88.338113,43.192932],[-88.310884,43.220396],[-88.289964,43.266922],[-88.300431,43.281351]]]},"properties":{"id":"53027","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.516799,43.212645],[-88.513842,43.207021],[-88.502494,43.19933],[-88.495102,43.201216],[-88.516799,43.212645]]]},"properties":{"id":"53003","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.63593,43.255321],[-88.621886,43.257006],[-88.636033,43.260301],[-88.63593,43.255321]]]},"properties":{"id":"53047","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.276073,45.077543],[-87.36255,44.983578],[-87.191941,44.985664],[-87.234872,45.066077],[-87.276073,45.077543]]]},"properties":{"id":"54209","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.651118,44.686441],[-87.617331,44.515568],[-87.562726,44.545112],[-87.580096,44.660879],[-87.64151,44.690287],[-87.651118,44.686441]]]},"properties":{"id":"54205","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.658473,46.546197],[-91.551444,46.755674],[-91.551306,46.7581],[-91.93031,46.684574],[-91.829371,46.690114],[-91.721041,46.560474],[-91.658473,46.546197]]]},"properties":{"id":"54854","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.291855,46.532778],[-92.292995,46.332161],[-92.050268,46.332551],[-92.05037,46.374384],[-92.291855,46.532778]]]},"properties":{"id":"54836","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.136216,44.874096],[-92.258371,44.893099],[-92.298501,44.859305],[-92.409169,44.862281],[-92.441341,44.832458],[-92.338608,44.766671],[-92.317663,44.76641],[-92.115474,44.81491],[-92.136216,44.874096]]]},"properties":{"id":"54767","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.572731,45.722666],[-88.400506,45.701005],[-88.318891,45.667145],[-88.32529,45.721308],[-88.45583,45.84686],[-88.6755,45.797302],[-88.572731,45.722666]]]},"properties":{"id":"54120","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.469697,43.806555],[-88.442676,43.733366],[-88.391499,43.762258],[-88.389131,43.819312],[-88.469697,43.806555]]]},"properties":{"id":"54935","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.725109,43.807738],[-88.657235,43.720061],[-88.644159,43.719957],[-88.613667,43.734328],[-88.606172,43.76355],[-88.700135,43.831825],[-88.725109,43.807738]]]},"properties":{"id":"54974","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.212941,43.717777],[-88.160702,43.690117],[-88.160834,43.703438],[-88.160616,43.71761],[-88.100122,43.840975],[-88.162274,43.891511],[-88.212803,43.891757],[-88.24225,43.849204],[-88.212941,43.717777]]]},"properties":{"id":"53079","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.494334,45.568814],[-88.515516,45.483523],[-88.425991,45.491903],[-88.426085,45.548139],[-88.494334,45.568814]]],[[[-88.426085,45.548139],[-88.313077,45.57126],[-88.240235,45.648258],[-88.318891,45.667145],[-88.400506,45.701005],[-88.426085,45.548139]]]]},"properties":{"id":"54125","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.42714,45.973672],[-89.482171,45.879652],[-89.474597,45.82654],[-89.256005,45.777135],[-88.966766,45.962521],[-88.933573,45.990835],[-89.181526,45.982398],[-89.367022,46.115828],[-89.476627,46.112398],[-89.501045,46.027091],[-89.42714,45.973672]]]},"properties":{"id":"54521","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.774627,42.955883],[-90.844564,42.903035],[-90.843858,42.783774],[-90.82754,42.774466],[-90.630133,42.769922],[-90.530342,42.864052],[-90.546712,42.880968],[-90.665339,42.90563],[-90.774627,42.955883]]]},"properties":{"id":"53813","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.602664,42.629781],[-90.602655,42.619785],[-90.583295,42.620046],[-90.587656,42.634667],[-90.602745,42.631739],[-90.602664,42.629781]]]},"properties":{"id":"53808","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.521812,43.045483],[-90.477638,42.944329],[-90.329627,42.915483],[-90.301174,42.945671],[-90.317405,42.967534],[-90.394262,42.995979],[-90.467996,43.031387],[-90.521812,43.045483]]]},"properties":{"id":"53569","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.394262,42.995979],[-90.317405,42.967534],[-90.309954,42.99686],[-90.394262,42.995979]]]},"properties":{"id":"53526","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.947547,44.147332],[-90.672508,44.118682],[-90.625742,44.160028],[-90.591759,44.335594],[-90.690368,44.377797],[-90.868654,44.389825],[-90.92363,44.360918],[-91.00883,44.294436],[-91.034267,44.272009],[-90.947547,44.147332]],[[-90.641717,44.18916],[-90.634792,44.185858],[-90.641843,44.185435],[-90.641717,44.18916]]]},"properties":{"id":"54615","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.744951,43.030198],[-88.612532,42.930658],[-88.584253,42.962697],[-88.583884,42.963402],[-88.65862,43.059753],[-88.701666,43.071995],[-88.744951,43.030198]]]},"properties":{"id":"53137","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.849755,43.762889],[-90.03099,43.647876],[-90.028061,43.641622],[-89.917322,43.592501],[-89.849755,43.762889]]]},"properties":{"id":"53944","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.841091,42.664279],[-87.845572,42.580851],[-87.845515,42.578233],[-87.812531,42.580595],[-87.812129,42.626895],[-87.841091,42.664279]]]},"properties":{"id":"53140","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.34851,42.573825],[-88.413114,42.494618],[-88.304692,42.494618],[-88.280728,42.541043],[-88.34851,42.573825]]]},"properties":{"id":"53128","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.257841,43.818235],[-91.209859,43.867144],[-91.241483,43.869755],[-91.257841,43.818235]]]},"properties":{"id":"54603","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.113081,42.591782],[-90.132393,42.508099],[-89.955531,42.505673],[-89.958071,42.638991],[-89.965481,42.639226],[-90.113081,42.591782]]]},"properties":{"id":"53541","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362757,42.507084],[-90.132393,42.508099],[-90.113081,42.591782],[-90.30003,42.653502],[-90.308192,42.59539],[-90.367919,42.51391],[-90.362757,42.507084]]]},"properties":{"id":"53586","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.865524,42.648994],[-89.864236,42.64394],[-89.852445,42.647577],[-89.859799,42.6514],[-89.864953,42.649745],[-89.865524,42.648994]]]},"properties":{"id":"53599","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.144933,45.204902],[-88.958091,45.117985],[-88.88689,45.117639],[-88.887368,45.130149],[-88.90747,45.168157],[-88.866058,45.169039],[-88.926785,45.302806],[-89.144933,45.204902]]]},"properties":{"id":"54418","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.00254,44.24089],[-88.017856,44.099085],[-87.99276,44.095757],[-87.977569,44.091676],[-87.976942,44.074604],[-87.95772,44.066234],[-87.856955,44.073521],[-87.771329,44.145139],[-87.791832,44.240183],[-87.888037,44.268384],[-88.00254,44.24089]]]},"properties":{"id":"54230","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.715068,44.276822],[-87.608584,44.313044],[-87.636771,44.327506],[-87.755524,44.309582],[-87.715068,44.276822]]]},"properties":{"id":"54228","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.856955,44.073521],[-87.95772,44.066234],[-87.998156,44.030026],[-87.932525,44.00805],[-87.922398,44.01526],[-87.922364,43.996089],[-87.883872,43.963883],[-87.821813,44.00807],[-87.856955,44.073521]]]},"properties":{"id":"54245","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.888037,44.268384],[-87.791832,44.240183],[-87.720425,44.254869],[-87.715068,44.276822],[-87.755524,44.309582],[-87.887999,44.284034],[-87.888037,44.268384]]]},"properties":{"id":"54227","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.624021,44.901468],[-89.599963,44.858211],[-89.491084,44.844716],[-89.422478,44.880097],[-89.473521,44.930394],[-89.632822,44.918668],[-89.624021,44.901468]]]},"properties":{"id":"54476","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.318891,45.667145],[-88.240235,45.648258],[-88.058093,45.56226],[-87.803446,45.538344],[-87.799403,45.625612],[-88.161957,45.717039],[-88.32529,45.721308],[-88.318891,45.667145]]]},"properties":{"id":"54156","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.842392,45.257082],[-87.953291,45.136583],[-87.93211,45.11964],[-87.843065,45.135027],[-87.740921,45.20002],[-87.70998,45.24854],[-87.842392,45.257082]]]},"properties":{"id":"54159","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.47688,43.78856],[-89.481919,43.758666],[-89.434484,43.768748],[-89.47688,43.78856]]]},"properties":{"id":"53953","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.017693,42.981013],[-88.06862,42.98096],[-88.069288,42.952627],[-88.028974,42.94993],[-88.018263,42.954217],[-88.017693,42.981013]]]},"properties":{"id":"53228","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.069489,42.930115],[-88.028563,42.930509],[-88.028974,42.94993],[-88.069288,42.952627],[-88.069585,42.933227],[-88.069489,42.930115]]]},"properties":{"id":"53130","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.918093,43.103674],[-87.959456,43.192405],[-87.964668,43.192434],[-87.965978,43.133932],[-87.966991,43.09685],[-87.946396,43.089789],[-87.919598,43.089325],[-87.918093,43.103674]]]},"properties":{"id":"53209","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.966991,43.09685],[-88.006475,43.09721],[-88.006698,43.075323],[-87.946768,43.075221],[-87.946396,43.089789],[-87.966991,43.09685]]]},"properties":{"id":"53216","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.947773,43.045874],[-87.947783,43.028359],[-87.918206,43.029512],[-87.920164,43.045731],[-87.924376,43.04575],[-87.947773,43.045874]]]},"properties":{"id":"53233","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.893645,43.060166],[-87.872693,43.057719],[-87.870536,43.099271],[-87.906884,43.098441],[-87.893645,43.060166]]]},"properties":{"id":"53211","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.841527,42.96629],[-87.88319,42.959128],[-87.887676,42.930034],[-87.881392,42.930032],[-87.838962,42.930213],[-87.841527,42.96629]]]},"properties":{"id":"53110","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.969505,43.0207],[-87.969379,43.024424],[-87.982463,43.024283],[-87.980263,43.01926],[-87.969505,43.0207]]]},"properties":{"id":"53295","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925219,45.712128],[-89.757845,45.672832],[-89.655221,45.761136],[-89.673597,45.813582],[-89.905928,45.768079],[-89.925219,45.712128]]]},"properties":{"id":"54531","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.673437,45.836849],[-89.757317,45.913748],[-89.931455,45.830381],[-89.905928,45.768079],[-89.673597,45.813582],[-89.673437,45.836849]]]},"properties":{"id":"54548","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.415476,44.298715],[-88.415363,44.25311],[-88.355345,44.27693],[-88.324506,44.279387],[-88.333135,44.298023],[-88.415476,44.298715]]]},"properties":{"id":"54911","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.616312,44.574824],[-88.889922,44.534944],[-88.901971,44.534949],[-88.818093,44.502622],[-88.636136,44.502462],[-88.616312,44.574824]]]},"properties":{"id":"54922","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.392548,44.414814],[-88.386899,44.588388],[-88.446604,44.589184],[-88.46834,44.573994],[-88.506359,44.38415],[-88.496442,44.359074],[-88.392548,44.414814]]]},"properties":{"id":"54106","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.959456,43.192405],[-87.886186,43.192308],[-87.904187,43.250206],[-88.003814,43.192581],[-87.964668,43.192434],[-87.959456,43.192405]]]},"properties":{"id":"53092","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.200575,44.568467],[-92.316169,44.541009],[-92.217142,44.439132],[-92.155334,44.539478],[-92.200575,44.568467]]]},"properties":{"id":"54769","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.50883,44.648347],[-89.385884,44.479844],[-89.34508,44.569283],[-89.489821,44.649492],[-89.50883,44.648347]]]},"properties":{"id":"54423","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.090247,44.486153],[-89.238598,44.503948],[-89.264141,44.475779],[-89.223668,44.417443],[-89.102669,44.417541],[-89.090247,44.486153]]]},"properties":{"id":"54977","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.167475,45.601411],[-90.166102,45.468047],[-90.042882,45.481751],[-90.042849,45.662371],[-90.1034,45.69397],[-90.167475,45.601411]]]},"properties":{"id":"54513","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.534252,43.55282],[-90.74687,43.57268],[-90.730807,43.495204],[-90.683159,43.431587],[-90.642519,43.416882],[-90.539429,43.466352],[-90.534252,43.55282]]]},"properties":{"id":"54664","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.369173,42.784667],[-89.367611,42.686602],[-89.249289,42.700847],[-89.171396,42.788258],[-89.186465,42.846353],[-89.269879,42.842469],[-89.369173,42.784667]]]},"properties":{"id":"53536","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.068844,43.346636],[-90.191806,43.295858],[-90.19182,43.294245],[-89.968827,43.264748],[-89.949599,43.329656],[-89.951821,43.331223],[-90.068844,43.346636]]]},"properties":{"id":"53577","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.375855,45.782251],[-91.418496,45.766639],[-91.379087,45.771357],[-91.375855,45.782251]]],[[[-91.347485,45.877843],[-91.351581,45.812794],[-91.352824,45.759237],[-91.296616,45.761182],[-91.173109,45.855488],[-91.132029,45.898346],[-91.347485,45.877843]]]]},"properties":{"id":"54828","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.173099,45.823559],[-91.173906,45.725604],[-91.04926,45.725379],[-91.173099,45.823559]]]},"properties":{"id":"54862","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.945267,44.782473],[-88.918799,44.740771],[-88.853133,44.75857],[-88.797929,44.759639],[-88.868426,44.796561],[-88.889179,44.811459],[-88.945267,44.782473]]]},"properties":{"id":"54948","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.916447,43.639965],[-87.916407,43.638306],[-87.914807,43.638313],[-87.914855,43.639981],[-87.916447,43.639965]]]},"properties":{"id":"53031","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.819967,45.086652],[-90.719222,45.031293],[-90.681797,45.119023],[-90.819967,45.086652]]]},"properties":{"id":"54447","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.152764,43.629968],[-91.224918,43.593186],[-91.224319,43.518964],[-91.044951,43.52407],[-91.104201,43.60379],[-91.152764,43.629968]]]},"properties":{"id":"54632","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.289964,43.266922],[-88.310884,43.220396],[-88.182183,43.221621],[-88.191237,43.250775],[-88.289964,43.266922]]]},"properties":{"id":"53033","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.166616,43.017146],[-88.186984,42.999787],[-88.188994,42.92738],[-88.188887,42.922858],[-88.138646,42.929979],[-88.12697,43.016885],[-88.128188,43.016862],[-88.166616,43.017146]]]},"properties":{"id":"53146","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.183519,43.160519],[-88.183135,43.192594],[-88.262188,43.18008],[-88.308025,43.119545],[-88.185671,43.104704],[-88.184089,43.139025],[-88.183519,43.160519]]]},"properties":{"id":"53089","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.102669,44.417541],[-89.011213,44.402893],[-88.981092,44.411396],[-88.935408,44.568172],[-89.036564,44.590717],[-89.090247,44.486153],[-89.102669,44.417541]]]},"properties":{"id":"54962","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.971712,44.199104],[-89.001799,44.199063],[-88.986543,44.184631],[-88.971712,44.199104]]],[[[-88.971712,44.199104],[-88.860169,44.294771],[-88.912552,44.416873],[-88.981092,44.411396],[-89.011213,44.402893],[-89.022153,44.242917],[-88.996968,44.213585],[-88.971712,44.199104]]]]},"properties":{"id":"54983","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.087449,44.148597],[-89.025049,44.055492],[-88.986213,44.119708],[-89.006664,44.141223],[-88.966155,44.141487],[-88.959142,44.126637],[-88.929048,44.121405],[-88.986543,44.184631],[-89.001799,44.199063],[-88.996968,44.213585],[-89.022153,44.242917],[-89.103511,44.221039],[-89.087449,44.148597]]]},"properties":{"id":"54965","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.885822,44.096863],[-88.8458,44.002466],[-88.832778,43.997858],[-88.805275,43.982709],[-88.774652,43.975292],[-88.756877,43.982289],[-88.704662,43.982125],[-88.658693,44.052557],[-88.711007,44.100322],[-88.885822,44.096863]]]},"properties":{"id":"54963","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.094616,44.640455],[-90.104854,44.646227],[-90.114866,44.644505],[-90.109476,44.637007],[-90.094616,44.640455]]]},"properties":{"id":"54441","state_code":"55"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.278926,41.145984],[-105.309622,41.10639],[-105.278758,41.060001],[-105.278894,41.126401],[-105.278926,41.145984]]]},"properties":{"id":"82052","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.085391,44.614126],[-105.076608,45.000381],[-105.50495,45.000332],[-105.52541,44.79359],[-105.325916,44.612278],[-105.085391,44.614126]]]},"properties":{"id":"82731","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.74672,41.30792],[-107.502076,41.122641],[-107.170746,41.081111],[-107.74672,41.30792]]],[[[-106.48158,41.22478],[-106.981835,41.161626],[-106.323025,41.171615],[-106.322904,41.239575],[-106.48158,41.22478]]]]},"properties":{"id":"82325","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.052988,42.823869],[-104.0548,43.41872],[-104.55012,43.39645],[-104.597827,42.900745],[-104.545128,42.609202],[-104.365989,42.461665],[-104.20229,42.612007],[-104.052988,42.823869]]]},"properties":{"id":"82225","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.19685,43.8477],[-108.204283,43.834888],[-108.174938,43.818968],[-108.19685,43.8477]]]},"properties":{"id":"82430","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.350819,41.794455],[-110.401805,41.747629],[-110.119761,41.717733],[-110.184947,41.836187],[-110.350819,41.794455]]]},"properties":{"id":"83124","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.046439,41.885951],[-110.918122,41.904147],[-110.838169,42.448045],[-111.04712,42.400677],[-111.046439,41.885951]]]},"properties":{"id":"83114","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.23783,43.36792],[-106.178906,43.37093],[-106.18751,43.42754],[-106.24095,43.430304],[-106.23783,43.36792]]]},"properties":{"id":"82635","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.509223,44.477367],[-109.350836,44.486253],[-109.38129,44.541914],[-109.509223,44.477367]]]},"properties":{"id":"82450","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.99649,44.559079],[-106.893577,44.558051],[-106.893629,44.594686],[-106.97649,44.592025],[-106.99649,44.559079]]]},"properties":{"id":"82842","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-110.355804,43.2912],[-110.072164,43.146532],[-110.12394,43.378096],[-110.355804,43.2912]]],[[[-109.554535,43.018999],[-110.02237,43.065174],[-109.973795,42.767048],[-109.928104,42.754175],[-109.525866,43.01043],[-109.554535,43.018999]]]]},"properties":{"id":"82941","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.928104,42.754175],[-109.855856,42.605219],[-109.473881,42.263428],[-109.07,42.48933],[-109.071059,42.509873],[-109.458171,42.976801],[-109.525866,43.01043],[-109.928104,42.754175]]]},"properties":{"id":"82923","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.554535,43.018999],[-110.05157,43.464783],[-110.193849,43.534065],[-110.162371,43.414245],[-110.12394,43.378096],[-110.072164,43.146532],[-110.07124,43.131004],[-110.02237,43.065174],[-109.554535,43.018999]]]},"properties":{"id":"82925","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.973795,42.767048],[-110.02237,43.065174],[-110.07124,43.131004],[-110.588768,42.999329],[-110.587955,42.842911],[-110.589948,42.694446],[-109.973795,42.767048]]]},"properties":{"id":"83115","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.543697,40.998396],[-108.37865,41.63371],[-108.4864,41.64093],[-108.738612,41.677853],[-108.931939,41.714666],[-109.117044,41.626937],[-109.303941,41.742092],[-109.552285,41.052028],[-109.543697,40.998396]]]},"properties":{"id":"82901","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.79244,43.575227],[-110.769503,43.621246],[-110.884642,43.614151],[-110.79244,43.575227]]]},"properties":{"id":"83025","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.428401,42.132035],[-106.500929,42.006768],[-106.315947,41.807746],[-106.194087,41.723838],[-105.92775,41.911708],[-105.758496,42.127548],[-105.614304,42.340193],[-105.614557,42.360655],[-105.768741,42.342237],[-106.073403,42.433236],[-106.075852,42.433218],[-106.428401,42.132035]]]},"properties":{"id":"82329","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.758496,42.127548],[-105.526901,41.857227],[-105.519119,42.289529],[-105.614304,42.340193],[-105.758496,42.127548]]]},"properties":{"id":"82058","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.732724,41.4683],[-105.278438,41.533972],[-105.278569,41.59015],[-105.653039,41.723651],[-105.865084,41.454338],[-105.732724,41.4683]]]},"properties":{"id":"82051","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.592502,44.842597],[-108.522045,44.784637],[-108.476957,44.729106],[-108.202641,44.696582],[-107.996674,44.534438],[-107.817331,44.780529],[-107.79631,44.780203],[-107.853379,44.913796],[-107.911752,45.001305],[-108.499092,44.9998],[-108.539093,44.878664],[-108.592265,44.853882],[-108.592502,44.842597]]]},"properties":{"id":"82431","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.034927,44.452915],[-108.346231,44.473122],[-108.365981,44.463448],[-108.267857,44.338528],[-108.077046,44.238117],[-107.969809,44.301814],[-108.034927,44.452915]]]},"properties":{"id":"82410","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.370553,44.46472],[-108.372251,44.342446],[-108.267857,44.338528],[-108.365981,44.463448],[-108.370553,44.46472]]]},"properties":{"id":"82434","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.472229,44.487022],[-108.370553,44.46472],[-108.365981,44.463448],[-108.346231,44.473122],[-108.392583,44.525622],[-108.472229,44.487022]]]},"properties":{"id":"82422","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.50495,45.000332],[-105.900101,45.000901],[-105.79242,44.70793],[-105.52541,44.79359],[-105.50495,45.000332]]]},"properties":{"id":"82725","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.315947,41.807746],[-106.264635,41.594717],[-106.194087,41.723838],[-106.315947,41.807746]]]},"properties":{"id":"82324","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.921246,41.714113],[-106.730005,41.698147],[-106.723935,41.684356],[-106.858258,41.976716],[-106.921246,41.714113]]]},"properties":{"id":"82335","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.597549,43.869666],[-104.395157,44.18139],[-104.686699,44.427224],[-104.9824,44.177461],[-104.944067,43.81477],[-104.597549,43.869666]]]},"properties":{"id":"82730","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.72875,44.51055],[-104.686699,44.427224],[-104.395157,44.18139],[-104.323395,44.104054],[-104.054487,44.180381],[-104.055503,44.309339],[-104.172559,44.56965],[-104.45944,44.49854],[-104.61679,44.49817],[-104.72875,44.51055]]]},"properties":{"id":"82729","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.365956,44.998151],[-104.347845,44.88072],[-104.055829,44.751422],[-104.365956,44.998151]]]},"properties":{"id":"57717","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.72875,44.51055],[-104.61679,44.49817],[-104.762438,44.549618],[-104.72875,44.51055]]]},"properties":{"id":"82714","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.517909,42.031418],[-104.349378,41.958481],[-104.325023,42.017608],[-104.517909,42.031418]]]},"properties":{"id":"82243","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.653689,41.852262],[-105.118917,41.656408],[-104.653338,41.653007],[-104.478539,41.740631],[-104.653689,41.852262]]]},"properties":{"id":"82210","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.893629,44.594686],[-106.893577,44.558051],[-106.578269,44.556805],[-106.656834,44.68575],[-106.74169,44.73112],[-106.893629,44.594686]]]},"properties":{"id":"82832","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.877301,41.13359],[-104.854024,41.153881],[-104.874679,41.163958],[-104.877301,41.13359]]]},"properties":{"id":"82005","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.350819,41.794455],[-110.185772,42.177952],[-110.421203,42.276126],[-110.541736,42.286522],[-110.401805,41.747629],[-110.350819,41.794455]]]},"properties":{"id":"83101","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.954288,42.630857],[-111.04432,42.657038],[-110.954332,42.598211],[-110.954288,42.630857]]]},"properties":{"id":"83119","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.327389,43.493088],[-106.23783,43.36792],[-106.24095,43.430304],[-106.18767,43.493969],[-106.327389,43.493088]]]},"properties":{"id":"82643","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.052988,42.823869],[-104.20229,42.612007],[-104.052838,42.611767],[-104.052988,42.823869]]]},"properties":{"id":"82242","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.447197,44.164042],[-108.947185,44.621871],[-109.361837,45.004725],[-110.002215,45.003442],[-110.053357,44.132628],[-110.053179,44.008022],[-109.535124,43.954423],[-109.447197,44.164042]],[[-109.509223,44.477367],[-109.38129,44.541914],[-109.350836,44.486253],[-109.509223,44.477367]]]},"properties":{"id":"82414","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.002215,45.003442],[-111.049091,44.132368],[-110.770649,44.132263],[-110.053357,44.132628],[-110.002215,45.003442]]]},"properties":{"id":"82190","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.193849,43.534065],[-110.601169,43.744749],[-110.733307,43.574103],[-110.162371,43.414245],[-110.193849,43.534065]]]},"properties":{"id":"83011","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.908131,42.322935],[-105.28391,42.431402],[-105.519119,42.289529],[-105.526901,41.857227],[-105.653039,41.723651],[-105.278569,41.59015],[-105.118917,41.656408],[-104.653689,41.852262],[-104.653486,42.060573],[-104.653774,42.089462],[-104.654463,42.189243],[-104.908131,42.322935]]]},"properties":{"id":"82201","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.539581,44.945188],[-108.53988,45.000177],[-108.628079,45.000374],[-108.63868,44.97286],[-108.539581,44.945188]]]},"properties":{"id":"82423","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.573511,44.384823],[-108.372251,44.342446],[-108.370553,44.46472],[-108.472229,44.487022],[-108.573464,44.429038],[-108.573511,44.384823]]]},"properties":{"id":"82411","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.877461,44.66122],[-107.672483,44.681562],[-107.79631,44.780203],[-107.817331,44.780529],[-107.877461,44.66122]]]},"properties":{"id":"82441","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.913132,43.914148],[-108.19685,43.8477],[-108.174938,43.818968],[-108.074274,43.818695],[-107.805949,44.168055],[-108.030513,44.168221],[-108.678605,44.079714],[-108.913132,43.914148]]]},"properties":{"id":"82401","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.632101,43.772156],[-105.68553,43.68986],[-105.46941,43.67662],[-105.51454,43.74835],[-105.632101,43.772156]]]},"properties":{"id":"82732","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.79242,44.70793],[-105.900101,45.000901],[-106.024814,44.993579],[-106.184509,44.785307],[-106.20497,44.691406],[-106.285225,44.56219],[-106.016555,44.256248],[-105.79242,44.70793]]]},"properties":{"id":"82831","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.232689,43.144244],[-107.315395,43.080495],[-107.322438,43.066655],[-107.542341,42.528499],[-107.522923,42.434356],[-107.199036,42.741278],[-106.98727,43.03199],[-107.232689,43.144244]]],[[[-106.764785,43.038328],[-106.91893,42.952957],[-106.98868,42.75546],[-106.717197,42.565244],[-106.075968,42.655212],[-106.076824,42.766517],[-106.1441,42.76214],[-106.357644,42.84515],[-106.364183,42.836964],[-106.37486,42.84147],[-106.394284,42.838215],[-106.381816,42.850455],[-106.36508,42.84651],[-106.700809,43.379875],[-106.982896,43.380283],[-106.764785,43.038328]]]]},"properties":{"id":"82604","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.267732,43.486227],[-109.051895,43.409459],[-108.696356,43.185312],[-108.330348,43.335721],[-108.328447,43.45974],[-108.725533,43.57559],[-109.233656,43.762132],[-109.267732,43.486227]]]},"properties":{"id":"82523","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.330348,43.335721],[-107.988095,43.043928],[-107.887563,43.155511],[-107.808877,43.471457],[-108.046162,43.470835],[-108.171998,43.459734],[-108.328447,43.45974],[-108.330348,43.335721]]]},"properties":{"id":"82649","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.43115,43.32211],[-107.429931,43.501869],[-107.455015,43.50179],[-107.719251,43.471853],[-107.763312,43.47173],[-107.808877,43.471457],[-107.887563,43.155511],[-107.623423,42.965257],[-107.501921,42.957435],[-107.43115,43.32211]]]},"properties":{"id":"82642","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.19685,43.8477],[-108.913132,43.914148],[-109.312949,43.813293],[-109.233656,43.762132],[-108.725533,43.57559],[-108.171998,43.459734],[-108.046162,43.470835],[-107.763312,43.47173],[-107.719251,43.471853],[-108.074274,43.818695],[-108.174938,43.818968],[-108.204283,43.834888],[-108.19685,43.8477]]]},"properties":{"id":"82443","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.849797,43.033495],[-106.91893,42.952957],[-106.764785,43.038328],[-106.849797,43.033495]]]},"properties":{"id":"82646","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.656834,44.68575],[-106.578269,44.556805],[-106.285225,44.56219],[-106.20497,44.691406],[-106.2857,44.79397],[-106.184509,44.785307],[-106.024814,44.993579],[-106.431928,44.994352],[-106.606591,44.798008],[-106.656834,44.68575]]]},"properties":{"id":"82835","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.072164,43.146532],[-110.355804,43.2912],[-110.578656,43.235224],[-110.588768,42.999329],[-110.07124,43.131004],[-110.072164,43.146532]]]},"properties":{"id":"82922","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.894147,41.551122],[-109.80552,41.543079],[-109.812981,41.584022],[-109.894147,41.551122]]]},"properties":{"id":"82929","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.681351,41.504813],[-109.494124,41.5102],[-109.587533,41.654401],[-109.681351,41.504813]]]},"properties":{"id":"82935","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.994812,41.620819],[-110.048119,41.566319],[-109.96288,41.57779],[-109.994812,41.620819]]]},"properties":{"id":"82934","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.822732,40.997008],[-105.964615,41.128117],[-106.127888,40.997332],[-105.822732,40.997008]]]},"properties":{"id":"82063","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.34427,40.997948],[-105.537681,41.083364],[-105.722083,40.996912],[-105.34427,40.997948]]]},"properties":{"id":"82084","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.732724,41.4683],[-105.457379,41.316595],[-105.27745,41.398902],[-105.278479,41.473959],[-105.278438,41.533972],[-105.732724,41.4683]],[[-105.588153,41.356851],[-105.58709,41.357093],[-105.587132,41.356442],[-105.588153,41.356851]]]},"properties":{"id":"82072","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.628079,45.000374],[-109.361837,45.004725],[-108.947185,44.621871],[-108.476957,44.729106],[-108.522045,44.784637],[-108.592409,44.739014],[-108.592502,44.842597],[-108.592265,44.853882],[-108.63868,44.97286],[-108.628079,45.000374]],[[-108.87696,44.71062],[-108.864491,44.726218],[-108.858346,44.71824],[-108.87696,44.71062]]]},"properties":{"id":"82435","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.63868,44.97286],[-108.592265,44.853882],[-108.539093,44.878664],[-108.539581,44.945188],[-108.63868,44.97286]]]},"properties":{"id":"82421","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.74672,41.30792],[-107.554789,41.002277],[-107.498197,41.097529],[-107.502076,41.122641],[-107.74672,41.30792]]]},"properties":{"id":"82321","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.139829,41.782195],[-107.208994,41.745926],[-106.921246,41.714113],[-106.858258,41.976716],[-106.883184,42.17194],[-106.979765,42.183785],[-107.139829,41.782195]]]},"properties":{"id":"82334","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.428401,42.132035],[-106.912864,42.433195],[-106.939421,42.251293],[-106.979765,42.183785],[-106.883184,42.17194],[-106.500929,42.006768],[-106.428401,42.132035]]]},"properties":{"id":"82327","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.795749,43.029024],[-109.001749,43.213943],[-109.077457,43.016877],[-108.795749,43.029024]]]},"properties":{"id":"82514","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.546997,42.93279],[-108.552042,42.883632],[-108.509318,42.92108],[-108.546997,42.93279]]]},"properties":{"id":"82515","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.15949,41.84192],[-104.323772,41.724736],[-104.052681,41.709839],[-104.052812,41.809288],[-104.15949,41.84192]]]},"properties":{"id":"82217","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.662988,43.380157],[-106.359716,43.49301],[-106.018698,43.648864],[-106.010196,43.996416],[-107.113671,43.802342],[-107.110734,43.500286],[-106.982896,43.380283],[-106.700809,43.379875],[-106.662988,43.380157]]]},"properties":{"id":"82639","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.278894,41.126401],[-105.099044,41.098422],[-104.877301,41.13359],[-104.874679,41.163958],[-104.715803,41.145152],[-104.598189,41.158134],[-104.560381,41.158027],[-104.501946,41.317177],[-104.382577,41.389808],[-104.34614,41.441811],[-104.592495,41.564686],[-104.653338,41.653007],[-105.118917,41.656408],[-105.278569,41.59015],[-105.278438,41.533972],[-105.278479,41.473959],[-105.056252,41.648393],[-105.058875,41.276918],[-105.280675,41.321448],[-105.279861,41.190717],[-105.278926,41.145984],[-105.278894,41.126401]]]},"properties":{"id":"82009","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.27745,41.398902],[-105.280675,41.321448],[-105.058875,41.276918],[-105.056252,41.648393],[-105.278479,41.473959],[-105.27745,41.398902]]]},"properties":{"id":"82061","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.252754,41.360287],[-104.052438,41.490784],[-104.05254,41.564274],[-104.18924,41.564186],[-104.34614,41.441811],[-104.382577,41.389808],[-104.252754,41.360287]]]},"properties":{"id":"82050","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.982569,42.99688],[-111.04396,42.96426],[-111.043831,42.857355],[-110.985908,42.851954],[-110.941311,42.847221],[-110.923898,43.065059],[-110.972051,43.095629],[-110.982569,42.99688]]]},"properties":{"id":"83127","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.972051,43.095629],[-111.044015,43.090616],[-111.044106,43.07768],[-110.982569,42.99688],[-110.972051,43.095629]]]},"properties":{"id":"83118","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.155156,42.270928],[-110.421203,42.276126],[-110.185772,42.177952],[-110.155156,42.270928]]]},"properties":{"id":"83123","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.36508,42.84651],[-106.357644,42.84515],[-106.1441,42.76214],[-106.279702,42.860924],[-106.215494,43.00046],[-106.073188,43.252418],[-106.07321,43.254735],[-106.662988,43.380157],[-106.700809,43.379875],[-106.36508,42.84651]]]},"properties":{"id":"82601","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.80957,43.178398],[-104.779137,42.922464],[-104.597827,42.900745],[-104.55012,43.39645],[-104.858059,43.393977],[-104.80957,43.178398]]]},"properties":{"id":"82222","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.908131,42.322935],[-104.654463,42.189243],[-104.655011,42.364088],[-104.872188,42.326229],[-104.908131,42.322935]]]},"properties":{"id":"82214","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.184509,44.785307],[-106.2857,44.79397],[-106.20497,44.691406],[-106.184509,44.785307]]]},"properties":{"id":"82837","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.421203,42.276126],[-110.155156,42.270928],[-109.855856,42.605219],[-109.928104,42.754175],[-109.973795,42.767048],[-110.589948,42.694446],[-110.541736,42.286522],[-110.421203,42.276126]]]},"properties":{"id":"83113","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.769503,43.621246],[-110.733307,43.574103],[-110.601169,43.744749],[-110.514972,43.776702],[-110.870546,43.805934],[-110.942234,43.613112],[-110.884642,43.614151],[-110.769503,43.621246]]]},"properties":{"id":"83012","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.514972,43.776702],[-110.051419,43.702856],[-110.053179,44.008022],[-110.053357,44.132628],[-110.770649,44.132263],[-110.870546,43.805934],[-110.514972,43.776702]]]},"properties":{"id":"83013","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.374851,41.35291],[-110.385796,41.287056],[-110.20177,41.259174],[-110.374851,41.35291]]]},"properties":{"id":"82937","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.264635,41.594717],[-106.322742,41.382647],[-106.09539,41.395003],[-106.071399,41.53072],[-105.92775,41.911708],[-106.194087,41.723838],[-106.264635,41.594717]]]},"properties":{"id":"82083","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.588153,41.356851],[-105.587132,41.356442],[-105.58709,41.357093],[-105.588153,41.356851]]]},"properties":{"id":"82073","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.498197,41.097529],[-107.554789,41.002277],[-107.478678,41.002671],[-107.498197,41.097529]]]},"properties":{"id":"82323","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.279702,42.860924],[-106.07283,42.839794],[-106.073729,42.966963],[-106.215494,43.00046],[-106.279702,42.860924]]]},"properties":{"id":"82636","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.762438,44.549618],[-104.61679,44.49817],[-104.45944,44.49854],[-104.475189,44.560029],[-104.539091,44.792314],[-104.4014,44.84232],[-104.347845,44.88072],[-104.365956,44.998151],[-104.783934,44.999336],[-104.762438,44.549618]]]},"properties":{"id":"82720","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.475189,44.560029],[-104.4014,44.84232],[-104.539091,44.792314],[-104.475189,44.560029]]]},"properties":{"id":"82711","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.4014,44.84232],[-104.475189,44.560029],[-104.45944,44.49854],[-104.172559,44.56965],[-104.056004,44.592001],[-104.055829,44.751422],[-104.347845,44.88072],[-104.4014,44.84232]]]},"properties":{"id":"82710","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.606197,42.972106],[-108.588678,43.026978],[-108.695936,43.017709],[-108.606197,42.972106]]]},"properties":{"id":"82510","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.267732,43.486227],[-109.459109,43.064584],[-109.044621,43.212936],[-109.051895,43.409459],[-109.267732,43.486227]]]},"properties":{"id":"82512","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.305921,42.262131],[-104.653774,42.089462],[-104.653486,42.060573],[-104.517909,42.031418],[-104.325023,42.017608],[-104.305921,42.262131]]]},"properties":{"id":"82223","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.654463,42.189243],[-104.653774,42.089462],[-104.305921,42.262131],[-104.356505,42.414349],[-104.655178,42.522298],[-104.655111,42.475703],[-104.655011,42.364088],[-104.654463,42.189243]]]},"properties":{"id":"82212","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.501946,41.317177],[-104.560381,41.158027],[-104.464835,41.187503],[-104.501946,41.317177]]]},"properties":{"id":"82060","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.252754,41.360287],[-104.253954,41.131867],[-104.162579,41.001569],[-104.052438,41.490784],[-104.252754,41.360287]]]},"properties":{"id":"82082","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.985908,42.851954],[-110.967132,42.793963],[-110.923976,42.793153],[-110.941311,42.847221],[-110.985908,42.851954]]]},"properties":{"id":"83122","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.573464,44.429038],[-109.447197,44.164042],[-109.535124,43.954423],[-109.312949,43.813293],[-108.913132,43.914148],[-108.678605,44.079714],[-108.573511,44.384823],[-108.573464,44.429038]]]},"properties":{"id":"82433","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.872188,42.326229],[-104.655011,42.364088],[-104.655111,42.475703],[-104.872188,42.326229]]]},"properties":{"id":"82215","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.296437,44.559101],[-106.99649,44.559079],[-106.97649,44.592025],[-107.197007,44.689564],[-107.296437,44.559101]]]},"properties":{"id":"82833","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.292176,44.907944],[-107.183447,44.833165],[-107.090178,44.997024],[-107.314749,45.00126],[-107.292176,44.907944]]]},"properties":{"id":"82839","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.183447,44.833165],[-107.239135,44.833278],[-107.222712,44.7328],[-107.183447,44.833165]]]},"properties":{"id":"82844","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-110.601169,43.744749],[-110.193849,43.534065],[-110.05157,43.464783],[-110.051419,43.702856],[-110.514972,43.776702],[-110.601169,43.744749]]],[[[-110.79244,43.575227],[-110.814041,43.302338],[-110.578656,43.235224],[-110.355804,43.2912],[-110.12394,43.378096],[-110.162371,43.414245],[-110.733307,43.574103],[-110.769503,43.621246],[-110.79244,43.575227]]]]},"properties":{"id":"83001","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.303941,41.742092],[-109.117044,41.626937],[-109.19938,41.779313],[-109.303941,41.742092]]]},"properties":{"id":"82943","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.049091,44.132368],[-111.045513,43.583067],[-110.942234,43.613112],[-110.870546,43.805934],[-110.770649,44.132263],[-111.049091,44.132368]]]},"properties":{"id":"83414","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.79244,43.575227],[-110.884642,43.614151],[-110.942234,43.613112],[-111.045513,43.583067],[-111.044617,43.31572],[-110.814041,43.302338],[-110.79244,43.575227]]]},"properties":{"id":"83014","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.322742,41.382647],[-106.322588,41.289355],[-106.09539,41.395003],[-106.322742,41.382647]]]},"properties":{"id":"82055","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.719437,42.431145],[-105.768741,42.342237],[-105.614557,42.360655],[-105.719437,42.431145]]],[[[-105.76722,42.431614],[-106.073619,43.157145],[-106.073729,42.966963],[-106.07283,42.839794],[-106.076824,42.766517],[-106.075968,42.655212],[-106.075852,42.433218],[-106.073403,42.433236],[-105.76722,42.431614]]]]},"properties":{"id":"82637","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.522045,44.784637],[-108.592502,44.842597],[-108.592409,44.739014],[-108.522045,44.784637]]]},"properties":{"id":"82412","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.346231,44.473122],[-108.034927,44.452915],[-107.54303,44.434006],[-107.254772,44.428991],[-107.251445,44.440349],[-107.369621,44.559837],[-107.672483,44.681562],[-107.877461,44.66122],[-107.996674,44.534438],[-108.202641,44.696582],[-108.392583,44.525622],[-108.346231,44.473122]]]},"properties":{"id":"82426","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.010196,43.996416],[-106.018698,43.648864],[-106.017366,43.494969],[-105.446849,43.496703],[-105.08168,43.600432],[-105.080645,43.749063],[-105.196231,43.982362],[-105.37814,44.29413],[-106.016552,44.216588],[-106.010196,43.996416]],[[-105.632101,43.772156],[-105.51454,43.74835],[-105.46941,43.67662],[-105.68553,43.68986],[-105.632101,43.772156]]]},"properties":{"id":"82718","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.391105,42.204741],[-107.522923,42.434356],[-107.542341,42.528499],[-107.699123,42.39924],[-107.391105,42.204741]]],[[[-107.139829,41.782195],[-107.821095,41.709126],[-107.758424,41.710191],[-107.228505,41.344905],[-107.264613,41.43111],[-107.208994,41.745926],[-107.139829,41.782195]]]]},"properties":{"id":"82301","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.170746,41.081111],[-107.502076,41.122641],[-107.498197,41.097529],[-107.478678,41.002671],[-107.170746,41.081111]]]},"properties":{"id":"82332","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.085391,44.614126],[-105.079281,44.176182],[-104.9824,44.177461],[-104.686699,44.427224],[-104.72875,44.51055],[-104.762438,44.549618],[-104.783934,44.999336],[-105.076608,45.000381],[-105.085391,44.614126]]]},"properties":{"id":"82721","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.509318,42.92108],[-107.501921,42.957435],[-107.623423,42.965257],[-107.988095,43.043928],[-108.330348,43.335721],[-108.696356,43.185312],[-108.588678,43.026978],[-108.606197,42.972106],[-108.546997,42.93279],[-108.509318,42.92108]]]},"properties":{"id":"82501","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.459109,43.064584],[-109.458171,42.976801],[-109.071059,42.509873],[-107.699123,42.39924],[-107.542341,42.528499],[-108.552042,42.883632],[-108.546997,42.93279],[-108.606197,42.972106],[-108.695936,43.017709],[-108.795749,43.029024],[-109.077457,43.016877],[-109.001749,43.213943],[-109.044621,43.212936],[-109.459109,43.064584]]]},"properties":{"id":"82520","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.20229,42.612007],[-104.365989,42.461665],[-104.356505,42.414349],[-104.305921,42.262131],[-104.325023,42.017608],[-104.349378,41.958481],[-104.15949,41.84192],[-104.052812,41.809288],[-104.052838,42.611767],[-104.20229,42.612007]]]},"properties":{"id":"82240","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.874679,41.163958],[-104.854024,41.153881],[-104.877301,41.13359],[-105.099044,41.098422],[-105.079192,41.022481],[-104.715803,41.145152],[-104.874679,41.163958]]]},"properties":{"id":"82001","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.56069,41.137469],[-104.253954,41.131867],[-104.252754,41.360287],[-104.382577,41.389808],[-104.501946,41.317177],[-104.464835,41.187503],[-104.560381,41.158027],[-104.598189,41.158134],[-104.56069,41.137469]]]},"properties":{"id":"82053","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.253954,41.131867],[-104.56069,41.137469],[-104.599526,41.001488],[-104.162579,41.001569],[-104.253954,41.131867]]]},"properties":{"id":"82054","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.87696,44.71062],[-108.858346,44.71824],[-108.864491,44.726218],[-108.87696,44.71062]]]},"properties":{"id":"82440","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.97433,42.608863],[-105.28391,42.431402],[-104.908131,42.322935],[-104.872188,42.326229],[-104.655111,42.475703],[-104.655178,42.522298],[-104.655639,42.609468],[-104.892437,42.608939],[-104.97433,42.608863]]]},"properties":{"id":"82213","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.222712,44.7328],[-107.197007,44.689564],[-106.97649,44.592025],[-106.893629,44.594686],[-106.74169,44.73112],[-106.606591,44.798008],[-106.431928,44.994352],[-107.090178,44.997024],[-107.183447,44.833165],[-107.222712,44.7328]]]},"properties":{"id":"82801","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.183447,44.833165],[-107.292176,44.907944],[-107.853379,44.913796],[-107.79631,44.780203],[-107.672483,44.681562],[-107.369621,44.559837],[-107.296437,44.559101],[-107.197007,44.689564],[-107.222712,44.7328],[-107.239135,44.833278],[-107.183447,44.833165]]]},"properties":{"id":"82836","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.606591,44.798008],[-106.74169,44.73112],[-106.656834,44.68575],[-106.606591,44.798008]]]},"properties":{"id":"82845","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.473881,42.263428],[-109.043864,42.263684],[-109.07,42.48933],[-109.473881,42.263428]]]},"properties":{"id":"82932","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.19938,41.779313],[-109.117044,41.626937],[-108.931939,41.714666],[-108.93217,41.76854],[-109.19938,41.779313]]]},"properties":{"id":"82945","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.570635,42.245444],[-107.573606,42.229431],[-107.547673,42.229342],[-107.547946,42.2421],[-107.570635,42.245444]]]},"properties":{"id":"82322","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.609815,40.996271],[-110.337999,40.995339],[-110.316234,41.071549],[-110.349762,41.069095],[-110.418017,41.19994],[-110.525428,41.23111],[-110.660905,41.213304],[-110.609815,40.996271]]]},"properties":{"id":"82944","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.395157,44.18139],[-104.597549,43.869666],[-104.323395,44.104054],[-104.395157,44.18139]]]},"properties":{"id":"82723","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.53988,45.000177],[-108.539581,44.945188],[-108.539093,44.878664],[-108.499092,44.9998],[-108.53988,45.000177]]]},"properties":{"id":"82420","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.54303,44.434006],[-107.745237,44.273836],[-107.678946,44.166402],[-107.665323,44.166498],[-107.254772,44.428991],[-107.54303,44.434006]]]},"properties":{"id":"82428","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.079281,44.176182],[-105.085391,44.614126],[-105.325916,44.612278],[-105.37814,44.29413],[-105.196231,43.982362],[-105.079281,44.176182]]]},"properties":{"id":"82727","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.391105,42.204741],[-106.939421,42.251293],[-106.912864,42.433195],[-106.717197,42.565244],[-106.98868,42.75546],[-107.199036,42.741278],[-107.522923,42.434356],[-107.391105,42.204741]]]},"properties":{"id":"82620","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.001749,43.213943],[-108.795749,43.029024],[-108.695936,43.017709],[-108.588678,43.026978],[-108.696356,43.185312],[-109.051895,43.409459],[-109.044621,43.212936],[-109.001749,43.213943]]]},"properties":{"id":"82516","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.459109,43.064584],[-109.267732,43.486227],[-109.233656,43.762132],[-109.312949,43.813293],[-109.535124,43.954423],[-110.053179,44.008022],[-110.051419,43.702856],[-110.05157,43.464783],[-109.554535,43.018999],[-109.525866,43.01043],[-109.458171,42.976801],[-109.459109,43.064584]]]},"properties":{"id":"82513","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.365989,42.461665],[-104.545128,42.609202],[-104.655639,42.609468],[-104.655178,42.522298],[-104.356505,42.414349],[-104.365989,42.461665]]]},"properties":{"id":"82219","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.113671,43.802342],[-106.010196,43.996416],[-106.016552,44.216588],[-106.016555,44.256248],[-106.285225,44.56219],[-106.578269,44.556805],[-106.893577,44.558051],[-106.99649,44.559079],[-107.296437,44.559101],[-107.369621,44.559837],[-107.251445,44.440349],[-107.146808,44.165666],[-107.127794,43.844901],[-107.113671,43.802342]]]},"properties":{"id":"82834","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.099044,41.098422],[-105.278894,41.126401],[-105.278758,41.060001],[-105.27686,40.998172],[-105.077014,40.998333],[-105.079192,41.022481],[-105.099044,41.098422]]]},"properties":{"id":"82059","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.592495,41.564686],[-104.34614,41.441811],[-104.18924,41.564186],[-104.592495,41.564686]]]},"properties":{"id":"82081","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.923898,43.065059],[-110.70269,42.86486],[-110.587955,42.842911],[-110.588768,42.999329],[-110.578656,43.235224],[-110.814041,43.302338],[-111.044617,43.31572],[-111.044015,43.090616],[-110.972051,43.095629],[-110.923898,43.065059]]]},"properties":{"id":"83128","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.775027,42.665503],[-110.954288,42.630857],[-110.954332,42.598211],[-110.775027,42.665503]]]},"properties":{"id":"83126","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.541918,41.811976],[-110.53476,41.81098],[-110.53241,41.8161],[-110.54222,41.815964],[-110.541918,41.811976]]]},"properties":{"id":"83121","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.529792,41.749083],[-110.528637,41.789235],[-110.54601,41.7733],[-110.529792,41.749083]]]},"properties":{"id":"83116","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.70269,42.86486],[-110.923898,43.065059],[-110.941311,42.847221],[-110.923976,42.793153],[-110.804863,42.808873],[-110.70269,42.86486]]]},"properties":{"id":"83112","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.98727,43.03199],[-106.849797,43.033495],[-106.764785,43.038328],[-106.982896,43.380283],[-107.110734,43.500286],[-107.298452,43.501629],[-107.429931,43.501869],[-107.43115,43.32211],[-107.3688,43.25165],[-107.232689,43.144244],[-106.98727,43.03199]]]},"properties":{"id":"82648","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.36508,42.84651],[-106.381816,42.850455],[-106.394284,42.838215],[-106.37486,42.84147],[-106.364183,42.836964],[-106.357644,42.84515],[-106.36508,42.84651]]]},"properties":{"id":"82644","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.232689,43.144244],[-107.3688,43.25165],[-107.396327,43.134135],[-107.315395,43.080495],[-107.232689,43.144244]]]},"properties":{"id":"82630","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.396327,43.134135],[-107.322438,43.066655],[-107.315395,43.080495],[-107.396327,43.134135]]]},"properties":{"id":"82638","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.552285,41.052028],[-109.466122,41.180188],[-109.533132,41.239475],[-109.463954,41.331028],[-109.617164,41.34303],[-109.63904,41.22575],[-109.533293,41.108421],[-109.543697,40.998396],[-109.552285,41.052028]]]},"properties":{"id":"82938","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.660905,41.213304],[-111.046723,40.997959],[-110.609815,40.996271],[-110.660905,41.213304]]]},"properties":{"id":"82930","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.418017,41.19994],[-110.349762,41.069095],[-110.20177,41.259174],[-110.385796,41.287056],[-110.418017,41.19994]]]},"properties":{"id":"82939","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.337999,40.995339],[-110.048477,40.997647],[-110.04865,41.081223],[-110.316234,41.071549],[-110.337999,40.995339]]]},"properties":{"id":"82936","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.684021,44.166401],[-107.455015,43.50179],[-107.429931,43.501869],[-107.298452,43.501629],[-107.127794,43.844901],[-107.146808,44.165666],[-107.665323,44.166498],[-107.678946,44.166402],[-107.684021,44.166401]]]},"properties":{"id":"82442","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.127795,44.122545],[-104.138208,44.122587],[-104.139081,44.115281],[-104.129392,44.116075],[-104.127795,44.122545]]]},"properties":{"id":"82715","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.457379,41.316595],[-105.732724,41.4683],[-105.865084,41.454338],[-106.071399,41.53072],[-106.09539,41.395003],[-106.322588,41.289355],[-106.322904,41.239575],[-106.323025,41.171615],[-106.127888,40.997332],[-105.964615,41.128117],[-105.822732,40.997008],[-105.722083,40.996912],[-105.537681,41.083364],[-105.34427,40.997948],[-105.27686,40.998172],[-105.278758,41.060001],[-105.309622,41.10639],[-105.278926,41.145984],[-105.279861,41.190717],[-105.457379,41.316595]]]},"properties":{"id":"82070","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.030513,44.168221],[-107.805949,44.168055],[-107.684021,44.166401],[-107.678946,44.166402],[-107.745237,44.273836],[-107.969809,44.301814],[-108.077046,44.238117],[-108.030513,44.168221]]]},"properties":{"id":"82432","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.52541,44.79359],[-105.79242,44.70793],[-106.016555,44.256248],[-106.016552,44.216588],[-105.37814,44.29413],[-105.325916,44.612278],[-105.52541,44.79359]]]},"properties":{"id":"82716","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.730005,41.698147],[-107.264613,41.43111],[-107.228505,41.344905],[-106.981835,41.161626],[-106.48158,41.22478],[-106.723935,41.684356],[-106.730005,41.698147]]]},"properties":{"id":"82331","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.80957,43.178398],[-104.858059,43.393977],[-105.079797,43.498445],[-105.446849,43.496703],[-106.017366,43.494969],[-106.078068,43.494471],[-106.07321,43.254735],[-106.073188,43.252418],[-106.073619,43.157145],[-105.76722,42.431614],[-105.719437,42.431145],[-105.614557,42.360655],[-105.614304,42.340193],[-105.519119,42.289529],[-105.28391,42.431402],[-104.97433,42.608863],[-104.898332,43.102496],[-104.80957,43.178398]]]},"properties":{"id":"82633","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.898332,43.102496],[-104.97433,42.608863],[-104.892437,42.608939],[-104.898052,42.949261],[-104.898332,43.102496]]]},"properties":{"id":"82229","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.056004,44.592001],[-104.172559,44.56965],[-104.055503,44.309339],[-104.056004,44.592001]]]},"properties":{"id":"82712","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.18924,41.564186],[-104.05254,41.564274],[-104.052681,41.709839],[-104.323772,41.724736],[-104.478539,41.740631],[-104.653338,41.653007],[-104.592495,41.564686],[-104.18924,41.564186]]]},"properties":{"id":"82221","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.478539,41.740631],[-104.323772,41.724736],[-104.15949,41.84192],[-104.349378,41.958481],[-104.517909,42.031418],[-104.653486,42.060573],[-104.653689,41.852262],[-104.478539,41.740631]]]},"properties":{"id":"82244","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.018698,43.648864],[-106.359716,43.49301],[-106.327389,43.493088],[-106.18767,43.493969],[-106.078068,43.494471],[-106.017366,43.494969],[-106.018698,43.648864]]]},"properties":{"id":"82640","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.715803,41.145152],[-105.079192,41.022481],[-105.077014,40.998333],[-104.599526,41.001488],[-104.56069,41.137469],[-104.598189,41.158134],[-104.715803,41.145152]]]},"properties":{"id":"82007","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.04396,42.96426],[-110.982569,42.99688],[-111.044106,43.07768],[-111.04396,42.96426]]]},"properties":{"id":"83120","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.043697,42.785359],[-110.967132,42.793963],[-110.985908,42.851954],[-111.043831,42.857355],[-111.043697,42.785359]]]},"properties":{"id":"83111","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.967132,42.793963],[-111.043697,42.785359],[-111.04432,42.657038],[-110.954288,42.630857],[-110.775027,42.665503],[-110.804863,42.808873],[-110.923976,42.793153],[-110.967132,42.793963]]]},"properties":{"id":"83110","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.07283,42.839794],[-106.279702,42.860924],[-106.1441,42.76214],[-106.076824,42.766517],[-106.07283,42.839794]]]},"properties":{"id":"82609","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.898052,42.949261],[-104.892437,42.608939],[-104.655639,42.609468],[-104.545128,42.609202],[-104.597827,42.900745],[-104.779137,42.922464],[-104.898052,42.949261]]]},"properties":{"id":"82227","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.779137,42.922464],[-104.80957,43.178398],[-104.898332,43.102496],[-104.898052,42.949261],[-104.779137,42.922464]]]},"properties":{"id":"82224","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.079797,43.498445],[-104.858059,43.393977],[-104.55012,43.39645],[-104.0548,43.41872],[-104.054487,44.180381],[-104.323395,44.104054],[-104.597549,43.869666],[-104.944067,43.81477],[-105.080645,43.749063],[-105.08168,43.600432],[-105.079797,43.498445]],[[-104.127795,44.122545],[-104.129392,44.116075],[-104.139081,44.115281],[-104.138208,44.122587],[-104.127795,44.122545]]]},"properties":{"id":"82701","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.853379,44.913796],[-107.292176,44.907944],[-107.314749,45.00126],[-107.911752,45.001305],[-107.853379,44.913796]]]},"properties":{"id":"82838","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.821095,41.709126],[-108.4864,41.64093],[-108.37865,41.63371],[-107.758424,41.710191],[-107.821095,41.709126]]]},"properties":{"id":"82336","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.931939,41.714666],[-108.738612,41.677853],[-108.93217,41.76854],[-108.931939,41.714666]]]},"properties":{"id":"82942","state_code":"56"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.374851,41.35291],[-110.525428,41.23111],[-110.418017,41.19994],[-110.385796,41.287056],[-110.374851,41.35291]]]},"properties":{"id":"82933","state_code":"56"}}
    ]};
    export default map;