import { COMBINATION_FIELD, FormatTypes, VECTOR_ANALYSIS, drillParam} from "./constants";
const VARIANCEPERC = "variance_perc";
const REV_PERC = "perc_";
const description = "Description";
var exportToExcel = function(exportOpts, table, startDate, endDate) {
    var vector = exportOpts.vector;
    var dataset = exportOpts.dataset;
    var fileNameOpp = exportOpts.fileName;
    var sheets = exportOpts.sheets;
    table.modules["download"].__proto__.getFieldValue = function (field, data) {
        var column = this.columnsByField[field];

        if (column) {
            var value = column.getFieldValue(data);
            var formatterType = column.definition.format_type;
            if(value && ["money", "percentage", "number", "numeric"].includes(formatterType)) {
                value = isNaN(Number(value)) ? value : Number(value);
            } else {
                value = value;
            }

            return value;
        }

        return false;
    };
  
    var docProc = function(workbook){
        let dataStartRow =  exportOpts.dataStartRow !== undefined ? exportOpts.dataStartRow : 1;
        return excelDocProcessing(workbook, table.getColumns(), dataStartRow, exportOpts)
    };

    let fileName = vector ;
    fileName += startDate && endDate ? "_" + (startDate !== endDate ? startDate + "_" + endDate : startDate) : dataset ?  vector !== ""  ? "_" + dataset : dataset :"";

    if(fileNameOpp){
        fileName = fileNameOpp;
    }
    // let excelTable = table;
    // excelTable.deleteColumn("Drill");
    // excelTable.deleteColumn("Check");
    // let dataObj = excelTable.getData();
    // var visibleCols = table.getColumns().filter(col => col.isVisible() === true && [FormatTypes.TEXT, FormatTypes.QUADRANT].includes(col.getDefinition().format_type));
    // var firstColPlaceholder = visibleCols[0].getField();
    
    // let params = {"Report": "TestBassem", date: "test", date2: "test2", date3: "test3"};
    // let val = new Object({[firstColPlaceholder]: " "});
    // dataObj.push(val);//Just adds an empty line
    // dataObj.push(val);
    // dataObj.push(val);
    // Object.keys(params).forEach(key => {
    //     val = new Object({[firstColPlaceholder]: key+": " + params[key]});
    //     dataObj.push(val);
    // })
    // excelTable.replaceData(dataObj);
    // excelTable.download("xlsx", fileName+".xlsx", {sheets:sheets, documentProcessing:docProc});
    // if(exportOpts.isRange){
        // table.download("xlsx", fileName+".xlsx", {sheets:sheets});
    // }else{
        table.download("xlsx", fileName+".xlsx", {sheets:sheets, documentProcessing:docProc});
    // }
}

var tabulatorExport = function(dataObj, table, params) {
    //data - active table data array
    //skip first 2 columns that have no data fields (drill and checkbox)
    var visibleCols = table.getColumns().filter(col => col.isVisible() === true && [FormatTypes.TEXT, FormatTypes.QUADRANT].includes(col.getDefinition().format_type));
    var firstColPlaceholder = visibleCols[0].getField();
    
    dataObj.data.push("");//Just adds an empty line
    dataObj.data.push("");
    dataObj.data.push("");

    Object.keys(params).forEach(key => {
        let val = new Object({[firstColPlaceholder]: key+": " + params[key]});
        dataObj.data.push(val);
    })

    //return data for download
    return dataObj;
}

var genCol = function(count){
	var ret = "";
	var cs = [0];
	var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	while(count > 0) {
		cs[0]+=1;
		if(cs[0] >= alphabet.length) {
			cs[0] = 0;
			var i = 1;
			while(true) {
				if (cs[i] === undefined)
					cs[i] = 0;
				else
					cs[i] += 1;
				if(cs[i] >= alphabet.length) {
					cs[i] = 0;
					i++;
				} else {
					break;
				}
			}
		}
		count -=1;
	}
	for(var i = cs.length - 1 ; i >=0; i--){
		ret += alphabet[cs[i]];
	}
	return ret;
};

var excelDocProcessing = function(workbook, cols, dataStartRow, exportOpts){
    //workbook - sheetJS workbook object
    //set some properties on the workbook file
    let skipColumn = exportOpts.skipColumn;
    let data = exportOpts.data;
    let dataSize = exportOpts.dataSize;
    let isProfitStack = exportOpts.isProfitStack;
    let excelDetailsCols = exportOpts.excelDetailsCols;
    let colsWidth = exportOpts.colsWidth;
    let excludeFormating = exportOpts.excludeFormating;
    let isRange = exportOpts.isRange;

    let is_profit_stack = isProfitStack === undefined ? true :isProfitStack;
    let nameColField = "name"; //all profit stacks contains a column called name and cannot be changed so far
    for(var sheetIndex in workbook.SheetNames) {
        var sheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
        sheet['!cols'] = colsWidth; //CHANGE
        sheet['!merges'] = [];
        // var count = skipColumn ? skipColumn : sheetIndex > 0 ? 1 : 0; // second sheet is for drilling, first column in drilling is vector
        let count  = 0;
        let columns = cols;

        // when we are drilling, we should take into consideration the main list columns when exporting the drill params
        if(workbook.SheetNames[sheetIndex].includes(drillParam)) {
          columns = exportOpts.sheets[workbook.SheetNames[sheetIndex]].getColumns();
        }

        if(skipColumn !== undefined){
            count = skipColumn;
            if( sheetIndex > 0 && ["Drill Parameters"].includes(workbook.SheetNames[sheetIndex])){ //For Drilling
                count = 1;
            }
        } else {
            count = 0;
        }

        if([description].includes(workbook.SheetNames[sheetIndex]) && excelDetailsCols){
            columns = excelDetailsCols;
        }
        var nameCellAddr = "";
        for(var i_col = 0; i_col < columns.length; i_col++) {
            var col = columns[i_col];
            var format_type = col.getDefinition().format_type;
            let colField = col.getField();
            if(!colField || colField.length <= 0) {
                continue;
            }
            if(!format_type || format_type.length <= 0) {
                continue;
            }
            if(!col.isVisible()) {
                continue;
            }
            if(colField === "check"){
                continue;
            }

            if(colField.includes(COMBINATION_FIELD)) {
                continue;
            }
            var cellAddr = genCol(count);
            if(colField === nameColField){
                nameCellAddr = cellAddr;
            }
            
			var format = window._format[format_type+"_XLS"];
			if(format_type.indexOf(":") >= 0 && format === undefined){
                format_type = format_type.split(":")[0];
				format = window._format[format_type+"_XLS"];
			}
            
            if([description].includes(workbook.SheetNames[sheetIndex]) && cellAddr){
                continue;
            }

            let bool = !colField.includes(VARIANCEPERC) && !colField.includes(REV_PERC) && format_type !== FormatTypes.TEXT && format_type !== FormatTypes.QUADRANT ;
            for (var i = dataStartRow; true; i++) {
                if (sheet[cellAddr + (i + 1)] !== undefined) {
                  // sheet[cellAddr + (i + 1)].v = $("<div>"+formatValHTML(sheet[cellAddr + (i + 1)].v, format_type, true)+"</div>").text();
                  if (format_type !== FormatTypes.TEXT && format_type !== FormatTypes.QUADRANT && !excludeFormating) {
                    sheet[cellAddr + (i + 1)].t = 'n';
          
                  }
                    var sheetIndexRow;
                    if(is_profit_stack || isRange){
                        // formatting for attribute and percentage rows is based on the format type of each row and not of column format 
                        sheetIndexRow =  data ? data.filter(e=>e[nameColField] === sheet[nameCellAddr + (i + 1)].v) : [];
                        var format_type = bool && sheetIndexRow.length > 0 && sheetIndexRow[0][colField+"_format"] ? sheetIndexRow[0][colField+"_format"] : format_type;
                        format = window._format[format_type+"_XLS"]; 
                    }
                    sheet[cellAddr + (i + 1)].z = format;
					if(format_type === FormatTypes.PERCENTAGE) { // only for percentage divide value by 100, because excel multiples it by 100
						sheet[cellAddr + (i + 1)].v = typeof  sheet[cellAddr + (i + 1)].v === "string" && sheet[cellAddr + (i + 1)].v.match(/[a-zA-Z]/g) !== null ? sheet[cellAddr + (i + 1)].v :sheet[cellAddr + (i + 1)].v / 100;
                    }
                   
                } else if(dataSize){
                    if(i > dataSize){
                        break;
                    }
                } else if(!data || i > data.length) {
                    break;
                }
            }
            count++;
        }
    }
    
    workbook.Props = {
        CreatedDate: new Date()
    };

    return workbook;
};

export {exportToExcel, tabulatorExport, excelDocProcessing}