import React, { Component } from 'react';
import { API_URL, PS_MAPPING, PSL_RETURN_NAMES, CONFIG_QUADS, costtype, FormatTypes, DROPDOWN_TYPE } from '../../class/constants.js';
import { copyObjectValues, getTranslationFile, tryParse, findOptionByKeyValue, capitalizeFirstLetter, findOptionByKey } from '../../class/utils.js';
import Popup from 'react-popup';
import Tabulator from "tabulator-tables"; //import Tabulator library
import "react-table/react-table.css";
import '../../styles/dataModeling.css';
import '../../styles/configureQuadrants.css';
import { getProfitStackLines } from '../stageData/CommonRequests';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { compareBasicStrings } from '../../class/string';
import { convertPxToViewport } from '../../class/formatting';
import Container from '../../components/manageColumns/Container.js';
import Select from 'react-select';
import { addElements, getPathToEmbeddedChild, getEmbeddedChild, updateAllElements } from '../../class/array.js';
import { toggleLoader} from '../../class/common.js'
import DropDown from '../../newComponents/DropDown.js';
const $ = require('jquery');
const lang = getTranslationFile();
const _sandbox = 'SANDBOX';
const _treeChildren = 'children';
const _label = "label";
const _checked = "checked";
const _revenue = "revenue";
const _profit = "profit";

class ConfigureQuadrants extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.fetchAPI = fetchAPI.bind(this);
        this.saveAndGoToBuild = this.saveAndGoToBuild.bind(this);
    }

    saveAndGoToBuild(redirectToBuild) {        
        this.saveQuadrantConfiguration();
        if (redirectToBuild) {
            setTimeout(() => {
                this.props.goToBuild();
            }, 1500);
        }
    }

    straightenPsLines(data, straightArray = []) {
        data.forEach(line=>{
            line = copyObjectValues(line);
            straightArray.push(line);

            if(line[PS_MAPPING.FIELDS.CHILDREN]) {
                this.straightenPsLines(line[PS_MAPPING.FIELDS.CHILDREN], straightArray);
            }
            delete line[PS_MAPPING.FIELDS.CHILDREN];
        });

        return straightArray;
    }

    getFirstRevenueChild(data) {
        let firstChild = {};
        data.forEach(line=>{    //no need for recursion bc revenue is always a first level parent
            if(line[PS_MAPPING.FIELDS.RETURN_NAME] === PSL_RETURN_NAMES.NET_REVENUE) {
                firstChild = line[PS_MAPPING.FIELDS.CHILDREN][0];   //always exists cz cannot delete first child in PSS mapping
            }
        });

        return firstChild;
    }

    onChangeScenario=(option)=> {
        this.props.resetMultipleRequestsData(["getCostFunction", "getVectorList", "getQuadrantConfiguration", "getProfitStackLines"])
        this.initComp();
     
    }

    initComp() {
        this.getProfitStackLines();
    }

    onChange = (currentNode, selectedNodes) => {
         let profitStackTreeStructure = copyObjectValues(this.state.profitStackTreeStructure);
         let revenueValue = false;
         profitStackTreeStructure =  updateAllElements(profitStackTreeStructure,_treeChildren,_checked,false)
         if(selectedNodes.length > 0) {
             let selectedNode =  selectedNodes[0];
             let obj = getEmbeddedChild(profitStackTreeStructure, _treeChildren, PS_MAPPING.FIELDS.RETURN_NAME, selectedNode[PS_MAPPING.FIELDS.RETURN_NAME]);
             obj[_checked] = true;
             revenueValue = Number(obj[PS_MAPPING.FIELDS.PSS_ID])
         }
         this.setState({
            profitStackTreeStructure:profitStackTreeStructure,
            revenueValue:revenueValue
         },function(){
            if(this.props.scenarioStatus === _sandbox) {
                this.props.setIsChanged(this.state.profitValue && this.state.revenueValue, false , this.state.revenueValue , this.state.profitValue);
            }
         })
        
    }

    handleElementChange = (e) => {
        if(e !==null){
           this.setState({
               profitValue:e.value
           },function(){
                if(this.props.scenarioStatus === _sandbox) {
                    this.props.setIsChanged(this.state.revenueValue && this.state.profitValue, false , this.state.revenueValue, this.state.profitValue);
                }
           })
        }
    }

    formatTreeStructure(data){
        let profitStackTreeStructure = addElements(data,_treeChildren,_label,PS_MAPPING.FIELDS.NAME);
        let index = profitStackTreeStructure.findIndex(e=>e[PS_MAPPING.FIELDS.RETURN_NAME] ===  PSL_RETURN_NAMES.GROSS_PROFIT);
        if(index > -1){
            profitStackTreeStructure.splice(index,profitStackTreeStructure.length - index);
        }
        return profitStackTreeStructure;
    }
    
    getProfitStackLines(switchAfter) {
        var _this = this;
        var callback = (data) => {
            data = tryParse(data.data);
            let plainLines = this.straightenPsLines(data);  
            let profitStackTreeStructure = this.formatTreeStructure(copyObjectValues(data));
           
               //non-hierarchical
            let linesForRevenue = [];
            let linesForProfit = [];
            let startWithProfit = false;

            plainLines.forEach(line=>{
                if(line[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.ATTRIBUTE) {
                    return;
                }

                if(line[PS_MAPPING.FIELDS.RETURN_NAME] === PSL_RETURN_NAMES.GROSS_PROFIT) {
                    startWithProfit = true;
                }

                if((line[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated) && line[PS_MAPPING.FIELDS.PERCENTAGE_MACHINE_NAME] !== FormatTypes.PERCENTAGE){
                    linesForProfit.push(line);
                }

                if(!startWithProfit) {
                    linesForRevenue.push(line);
                }
            })
            
            this.setState({
                psLines: data,
                profitStackTreeStructure:profitStackTreeStructure,
                linesForRevenue: linesForRevenue,
                linesForProfit: this.formatProfitOptions(linesForProfit),
            }, function() {
                _this.props.setTabsData("getProfitStackLines", data)
                _this.getQuadrantConfiguration(switchAfter);
            });

            this.getProfitStackLinesFetched = false;
        }

        let data = _this.props.getTabsData("getProfitStackLines");
        if(data){
            let plainLines = this.straightenPsLines(data);      //non-hierarchical
            let profitStackTreeStructure = this.formatTreeStructure(copyObjectValues(data));
            let linesForRevenue = [];
            let linesForProfit = [];
            let startWithProfit = false;

            plainLines.forEach(line=>{
                if(line[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.ATTRIBUTE) {
                    return;
                }

                if(line[PS_MAPPING.FIELDS.RETURN_NAME] === PSL_RETURN_NAMES.GROSS_PROFIT) {
                    startWithProfit = true;
                }

                if((line[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated) && line[PS_MAPPING.FIELDS.PERCENTAGE_MACHINE_NAME] !== FormatTypes.PERCENTAGE){
                    linesForProfit.push(line);
                }

                if(!startWithProfit) {
                    linesForRevenue.push(line);
                }
            })
            _this.setState({
                psLines: data,
                profitStackTreeStructure:profitStackTreeStructure,
                linesForRevenue: linesForRevenue,
                linesForProfit: _this.formatProfitOptions(linesForProfit)
            }, function() {
                if(!_this.getQuadrantConfigurationFetched){
                    _this.getQuadrantConfiguration(); 
                }         
            });
        }else{
            getProfitStackLines(this.props.scenarioId, "true", callback,true);
            this.getProfitStackLinesFetched = true;
        }
    }

    formatData(data, whenReceived) {
        let returnData = [];
        if(whenReceived) {
            //setting default values here, so on clicking save without changing dropdowns, default values would be saved
            let revenueRow = findOptionByKeyValue(this.state.linesForRevenue, PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.GROSS_REVENUE);   //revenue ret name is dynamic
            if(!revenueRow) {   //did not find grossrevenue return name
                let revenueRetName = this.getFirstRevenueChild(this.state.psLines)[PS_MAPPING.FIELDS.RETURN_NAME];
                revenueRow = findOptionByKeyValue(this.state.linesForRevenue, PS_MAPPING.FIELDS.RETURN_NAME, revenueRetName);
            }
            let profitRow = findOptionByKeyValue(this.state.linesForProfit, PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.NET_PROFIT);    //profit ret name is static
            let defaultRevenueId = revenueRow ? revenueRow[PS_MAPPING.FIELDS.PSS_ID] : "";
            let defaultProfitId = profitRow ? profitRow[PS_MAPPING.FIELDS.PSS_ID] : "";

            returnData.push({[CONFIG_QUADS.FIELDS.CONFIG_LINE]: capitalizeFirstLetter(PSL_RETURN_NAMES.REVENUE), [CONFIG_QUADS.FIELDS.PS_LINE]: data[0] ? data[0][PSL_RETURN_NAMES.REVENUE] : defaultRevenueId});   //data[0] is the saved line
            returnData.push({[CONFIG_QUADS.FIELDS.CONFIG_LINE]: capitalizeFirstLetter(PSL_RETURN_NAMES.PROFIT), [CONFIG_QUADS.FIELDS.PS_LINE]: data[0] ? data[0][PSL_RETURN_NAMES.PROFIT] : defaultProfitId});
        } else {
            let tempRow = {};
            let revenueRow = findOptionByKeyValue(data, [CONFIG_QUADS.FIELDS.CONFIG_LINE], capitalizeFirstLetter(PSL_RETURN_NAMES.REVENUE));
            let profitRow = findOptionByKeyValue(data, [CONFIG_QUADS.FIELDS.CONFIG_LINE], capitalizeFirstLetter(PSL_RETURN_NAMES.PROFIT));
            tempRow[PSL_RETURN_NAMES.REVENUE] = Number(revenueRow[CONFIG_QUADS.FIELDS.PS_LINE]);
            tempRow[PSL_RETURN_NAMES.PROFIT] = Number(profitRow[CONFIG_QUADS.FIELDS.PS_LINE]);

            returnData.push(tempRow);
        }

        return returnData;
    }

    formatProfitOptions(options){
        let results = []
        options.map((item) => {
            let value = Number(item[PS_MAPPING.FIELDS.PSS_ID]);
            let label = item[PS_MAPPING.FIELDS.NAME];
            let obj = {value:value,label:label}
            results.push(obj);
        })
        return results;
    }

    
    getQuadrantConfiguration(switchAfter) {
        var _this = this;
        var query = {
            action: "getQuadrantConfiguration",
            scenario_id: this.props.scenarioId
        }
    
        var onThenCallback = (data)=>{
            if (data && data.data) {
                let profitStackTreeStructure = copyObjectValues(_this.state.profitStackTreeStructure);
                let gross_revenue =  getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.GROSS_REVENUE);
                let net_revenue = getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.NET_REVENUE);
                let net_profit = getEmbeddedChild(_this.state.psLines,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.NET_PROFIT);
                let profit = data.data.length > 0 ? data.data[0][_profit] : "" ;
                let revenue = data.data.length > 0 ? data.data[0][_revenue] : "" ;
                let obj = getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.PSS_ID,revenue.toString()) || [];
                obj[_checked] = true;
                _this.setState({
                    profitValue:profit,
                    profitStackTreeStructure:profitStackTreeStructure,
                    originalProfitValue:profit,
                    originalRevenueValue:revenue,
                    originalProfitStackTreeStructure:copyObjectValues(profitStackTreeStructure),
                    revenueValue:revenue,
                    data:data,
                }, function(){
                    _this.props.setTabsData("getQuadrantConfiguration", data);
                    _this.getQuadrantConfigurationFetched = false;
                    toggleLoader(false, 'onChangeScenario');
                    if(switchAfter){
                        _this.props.changeTab(_this.props.getNextTab());
                    }
                })
            }
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getQuadrantConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.showCustomizedLoader]:true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
    
        let data = _this.props.getTabsData("getQuadrantConfiguration");
        if (data && data.data) {
            let profitStackTreeStructure = copyObjectValues(_this.state.profitStackTreeStructure);
            let gross_revenue =  getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.GROSS_REVENUE);
            let net_revenue = getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.NET_REVENUE);
            let net_profit = getEmbeddedChild(_this.state.psLines,_treeChildren,PS_MAPPING.FIELDS.RETURN_NAME, PSL_RETURN_NAMES.NET_PROFIT);
            let revenue = data.data.length > 0 ? data.data[0][_revenue] : "" ;
            let profit = data.data.length > 0 ? data.data[0][_profit] : "" ;
            let obj = getEmbeddedChild(profitStackTreeStructure,_treeChildren,PS_MAPPING.FIELDS.PSS_ID,revenue.toString()) || [];
            obj[_checked] = true;
            _this.setState({
                profitValue:profit,
                profitStackTreeStructure:profitStackTreeStructure,
                originalProfitValue:profit,
                originalRevenueValue:revenue,
                originalProfitStackTreeStructure:copyObjectValues(profitStackTreeStructure),
                revenueValue:revenue,
            });
        }else{
            this.fetchAPI(fetchOptions);
            _this.getQuadrantConfigurationFetched = true;
        }
    }

    validateRevenue=()=>{
        if(!this.state.revenueValue){
            this.props.setValidation(capitalizeFirstLetter(lang.configure_quadrant_revenue_validation))
            return false;
        }
        return true;
    }

    saveQuadrantConfiguration(switchAfter,openModalAfter) {
        var _this = this;
        if(switchAfter){
            toggleLoader(true, 'saveQuadrantConfiguration');
        }
        var query = {
            action: "saveQuadrantConfiguration",
            scenario_id: this.props.scenarioId,
            quadrantsData:[{revenue:this.state.revenueValue,profit:this.state.profitValue}]
        }
        
        var onThenCallback = (data)=>{
            if (data) {
                let profitStackTreeStructure = copyObjectValues(_this.state.profitStackTreeStructure)
                let profitValue = _this.state.profitValue;
                let revenueValue = _this.state.revenueValue;

                if(data.err) {
                    _this.props.showSavePopUp(false);
                } else {
                    _this.props.launchToast(true);
                    _this.props.setIsChanged(false, false, true, true);
                    _this.setState({
                        originalProfitStackTreeStructure:profitStackTreeStructure,
                        originalProfitValue:profitValue,
                        originalRevenueValue:revenueValue
                    },function(){
                        // _this.getProfitStackLines(switchAfter);
                        if(switchAfter){
                            _this.props.changeTab(_this.props.getNextTab());
                        }
                        if(openModalAfter){
                            _this.props.drawerToggleClickHandler()
                        }
                        toggleLoader(false, 'saveQuadrantConfiguration',false,true);
                    })
                }
            }
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveQuadrantConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: switchAfter ? false : true,
            [FETCHAPI_PARAMS.showCustomizedLoader]:true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
    
        this.fetchAPI(fetchOptions);
    }

    // launchToast(param) {
    //     this.setState({
    //         message: param? lang.saved : lang.not_saved,
    //         isError: param? false : true,
    //     },function(){
    //         $("#toastConfigureQuadrants").addClass("show");
    //         setTimeout(function(){
    //             $("#toastConfigureQuadrants").removeClass("show");
    //         }, 4000);
    //     })
    // }
  
   
    componentDidMount() {
        this.initComp();
        
        if(this.props.scenarioStatus !== _sandbox) {
            $(".md-step-oval").addClass('disabled')
        }else{
            $(".md-step-oval").removeClass('disabled')
        }
    }

    render() {
        let style = {
            paddingTop:"0.001vw",
            paddingBottom:"1vw"
        }
        return (
           
                <div>
                    {/* <div id="toastConfigureQuadrants" className="toast toast-success">
                        <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                    </div> */}
                    <div>
                        {/* <img src="/images/configure_quadrants.png" style={style} width={"695.63px"} height={"472px"}/> */}
                        <div className='dropDownsDiv'>
                            <label className="padding-bottom-label">{lang.configure_quadrant_revenue}</label>
                            <div className="revenueDiv">
                                <Container texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                                    data={this.state.profitStackTreeStructure} onChange={this.onChange}  mode={"radioSelect"} className={"dropdown-tree"}
                                />
                            </div>
                            <label className="padding-bottom-label">{lang.configure_quadrant_profit}</label>
                            <div>
                                <DropDown className="select-width uk-display-inline-block uk-cursor-pointer input__dropdown"
                                    value={findOptionByKey(this.state.linesForProfit,this.state.profitValue)}
                                    onChange={(e)=>this.handleElementChange(e)}
                                    options= {this.state.linesForProfit}
                                    type={DROPDOWN_TYPE.INPUT}
                                />
                            </div>
                        </div>
                    </div>
                </div>
         
        );
        
    } 
}
export default ConfigureQuadrants;