import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
    useChatInteract,
    useChatMessages,
    useAudio,
    useChatSession,
    sessionState
} from "@chainlit/react-client";
import { useRecoilValue } from "recoil";
import "./ChatBotWrapper.css"; // Make sure to create this CSS file
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";
import { ReactComponent as LogoSVG } from '../../styles/images/icons/profit-assist-title.svg';
import { ReactComponent as StopRecording } from "../../styles/images/icons/stop-solid.svg";
import { ReactComponent as CommentsIcon } from "../../styles/images/icons/comment.svg";
import { ReactComponent as SendIcon } from "../../styles/images/icons/send-icon.svg";
import { ReactComponent as MicrophoneIcon } from "../../styles/images/icons/microphone.svg";
import { ReactComponent as CloseIcon } from "../../styles/images/icons/chatobot-close.svg";
import { ReactComponent as SpinnerIcon } from "../../styles/images/icons/spinner-solid.svg";
import {CHATBOT_PROMISE_ERROR} from '../../class/constants'

const userEnv = {};

const TypingIndicator = () => (
    <div className="typing-indicator">
        <div className="typing-indicator-dot"></div>
        <div className="typing-indicator-dot"></div>
        <div className="typing-indicator-dot"></div>
    </div>
);

const Spinner = () => (
    <div className="spinner">
        <SpinnerIcon className="spinner-icon" />
    </div>
);

function CustomChatBot() {
    const { connect } = useChatSession();
    const session = useRecoilValue(sessionState);
    const [inputValue, setInputValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isAiResponding, setIsAiResponding] = useState(false);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const { sendMessage, isLoading } = useChatInteract();
    const { messages } = useChatMessages();
    const { startRecording, stopRecording, isRecording, transcribedText, isProcessing } = useAudio();
    const messagesEndRef = useRef(null);
    const [pendingTranscription, setPendingTranscription] = useState("");
    const lastMessageRef = useRef(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const getSessionId = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getSessionId" };
            let onThenCallback = (data) => {
                if (data?.sessionId) {
                    resolve(data.sessionId); // Resolve the promise with the sessionId
                } else {
                    reject(CHATBOT_PROMISE_ERROR);
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getSessionId",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-session-id",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    const getChatbotVectorsInfo = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getChatbotVectorsInfo" };
            let onThenCallback = (data) => {
                console.log("Response data: ", data);
                if (data?.chatbotVectorsInfo) {
                    resolve(data.chatbotVectorsInfo); // Resolve the promise
                } else {
                    reject(CHATBOT_PROMISE_ERROR);
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getChatbotVectorsInfo",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-chat-bot-vectors-info",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (session?.socket?.connected) {
                return;
            }

            try {
                const sessionId = await getSessionId();
                const chatbotVectorsInfoArray = await getChatbotVectorsInfo();
                const chatbotVectorsInfo = { data: chatbotVectorsInfoArray };

                const response = await fetch("https://pi-gemin-kpcrta6mtq-uc.a.run.app/custom-auth", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        chatbotVectorsInfo: chatbotVectorsInfo,
                        sessionId: sessionId,
                        url: baseUrl + "/chat-bot-get-vector",
                        authority: baseUrl,
                        origin: window.location.origin
                    }),
                });
                const data = await response.json();
                connect({
                    userEnv,
                    accessToken: `Bearer ${data.token}`,
                });
            } catch (error) { }
        };

        fetchData();
    }, [connect]);

    const handleSendMessage = useCallback((content) => {
        if (content.trim()) {
            const message = {
                name: "User",
                type: "user_message",
                output: content,
            };
            sendMessage(message, []);
            setIsAiResponding(true);
        }
    }, [sendMessage]);

    const handleStartRecording = () => {
        startRecording();
        setPendingTranscription("");
        setIsTranscribing(false);
    };

    const handleStopRecording = () => {
        stopRecording();
        setIsTranscribing(true);
    };

    const scrollToBottom = useCallback(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (transcribedText) {
            setPendingTranscription(prev => (prev + " " + transcribedText).trim());
        }
    }, [transcribedText]);

    useEffect(() => {
        if (!isProcessing && pendingTranscription) {
            const timer = setTimeout(() => {
                handleSendMessage(pendingTranscription);
                setPendingTranscription("");
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isProcessing, pendingTranscription, handleSendMessage]);

    useEffect(() => {
        setIsAiResponding(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (messages?.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.type !== "user_message" && lastMessage.output !== lastMessageRef.current) {
                setIsAiResponding(false);
                lastMessageRef.current = lastMessage.output;
            }
        }
    }, [messages]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, isAiResponding]);

    useEffect(() => {
        if (isTranscribing) {
            const timer = setTimeout(() => {
                setIsTranscribing(false);
                setIsAiResponding(true);  // Start showing TypingIndicator after transcribing
            }, 3500);
            return () => clearTimeout(timer);
        }
    }, [isTranscribing]);

    useEffect(() => {
        // Scroll to bottom whenever messages, isRecording, or isTranscribing change
        scrollToBottom();
    }, [messages, isRecording, isTranscribing, scrollToBottom]);

    const renderMessage = (message) => {
        if (!message.output.trim()) return null;
        const isUser = message.type === "user_message";
        const messageClass = isUser ? "message user-message" : "message ai-message";
        return (
            <div key={message.id} className={`message-container ${isUser ? 'user-container' : 'ai-container'}`}>
                <div className={messageClass}>
                    <ReactMarkdown
                        className="message-content"
                        remarkPlugins={[remarkGfm]}
                        components={{
                            table: ({ node, ...props }) => <table className="markdown-table" {...props} />,
                        }}
                    >
                        {message.output}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    return (
        <>
            <button
                className="chat-toggle-button"
                onClick={() => setIsOpen(true)}
            >
                <CommentsIcon className="chat-icon" />
            </button>
            {isOpen && (
                <div className="chat-window">
                    <div className="chat-header">
                        <div className="logo-container">
                            <LogoSVG className="chat-logo" />
                        </div>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="chat-bot-close-button"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="chat-messages">
                        <div className="message-list">
                            {messages.map(renderMessage)}
                            {isTranscribing && (
                                <div className="message-container user-container">
                                    <div className="message transcribing-message">
                                        <Spinner />
                                        <span>Transcribing...</span>
                                    </div>
                                </div>
                            )}
                            {isAiResponding && !isTranscribing && (
                                <div className="message-container ai-container">
                                    <TypingIndicator />
                                </div>
                            )}
                            {isRecording && (
                                <div className="message-container user-container">
                                    <div className="message recording-message">
                                        <Spinner />
                                        <span>Recording...</span>
                                    </div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                    <div className="chat-input-area">
                        <input
                            className="chat-input"
                            id="message-input"
                            placeholder="Ask me anything..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage(inputValue);
                                    setInputValue("");
                                }
                            }}
                        />
                        <button
                            onClick={() => {
                                handleSendMessage(inputValue);
                                setInputValue("");
                            }}
                            className="chat-bot-send-button"
                            disabled={isAiResponding || !inputValue.trim()}
                        >
                            <SendIcon />
                        </button>
                        <button
                            onClick={isRecording ? handleStopRecording : handleStartRecording}
                            className={`chat-bot-record-button ${isRecording ? 'recording' : ''}`}
                            disabled={isAiResponding}
                        >
                            {isRecording ? <StopRecording /> : <MicrophoneIcon />}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default CustomChatBot;