import React, { Component } from 'react';
import Popup from 'react-popup';

import { logout, toggleLoader, setLocalStorageValueByParameter, getLocalStorageValueByParameter } from '../../class/common.js';
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, CLIENT_ID_STORAGE, costtype, DEFINE_FIELDS, DIALOG_SIZE, DROPDOWN_TYPE, ENGINE_FILTER, SIZES } from '../../class/constants.js';
import { convertPxToViewport } from '../../class/formatting';
import { alertAndLogError } from '../../class/jqueries.js';
import { findOptionByKey, getTranslationFile, tryParse, getSectionId } from '../../class/utils.js';
import { CustomSelect } from '../../form/elements.js';
import '../../styles/common.css';
import '../../styles/header.css';
import Levers from '../bridgeLevers/Levers.js';
import { getProfitStackLines } from '../stageData/CommonRequests.js';
import SessionTimeout from '../../SessionTimeout';
import Button from '../../newComponents/Button';
import DropDown from '../../newComponents/DropDown';
import Modal from '../../newComponents/Modal';


const baseUrl = process.env.REACT_APP_BASE_URL;

const $ = require("jquery");
const MESSAGES = getTranslationFile();
const path = API_URL.PROFIT_VECTORS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;

class BridgeConfigurationsManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
            title: ALL_WIDGETS.TITLES.SYSTEM.CACHE_MANAGER
        }
        this.getCustomViews = this.getCustomViews.bind(this);
        this.saveBridgeConfigurations = this.saveBridgeConfigurations.bind(this);
        this.getBridgeConfigurations = this.getBridgeConfigurations.bind(this);
        this.getFields = this.getFields.bind(this);
        this.logout = logout.bind(this);
    }

    componentDidMount() {
        this.getUserSettings();
        this.getCustomViews();
        this.getProfitStackFields();
        this.getFields();
    }

    getUserSettings=(idToken)=> {
        var sectionId = getSectionId("data", "1");
        
        let query = {
            action: "getUserSettings",
            idToken: idToken,
            section: sectionId,
            url: window.location.href,
            client_id: getLocalStorageValueByParameter(CLIENT_ID_STORAGE)
        }

        setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());

        fetch(`${baseUrl}${API_URL.USER_SETTINGS}`, { mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(query) })
            .then((response) => {
                if (response.status === 403) {
                    this.logout();
                }
                return response.json()
            })
            .then((settingsData) => {
                let data = settingsData.settings;

                this.setState({
                    user: data.user,
                    dbDataSet: data.dbDataSet,
                    tablePrefix: data.tablePrefix,
                    machine_name: data.machineName,
                    project_id: data.projectId,
                    clientId: data.clientId,
                    clientName: data.name,
                    costCenter: data.costCenter,
                    session_timeout_ui: data.sessionTimeoutUI
                });

    		}).catch((error)=>{
    			alertAndLogError(error);
    		});
    }
    

    getProfitStackFields = () => {
        const _this = this;
        var callback = (data) => {
            if (data) {
                var pss = tryParse(data.data);
                var calculatedLines = pss.filter(e=>e.costtype === 'calculated' && e.format_type_id !== "5");
                _this.setState({
                    calculatedLines:calculatedLines
                })    
            }
        }
        getProfitStackLines("", "true", callback);
    }

    getFields(timePeriod) {
        toggleLoader(true, "getFields");
        let fields = [];
        var dataBody = { 
            action: "getFields",
            scenario_id: "",
            timePeriod: timePeriod !== undefined ? timePeriod : ""
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                return response.json();
            }).then((data)=>{
                
                if(data.error || data.result === 'ERROR'){
                  this.setInfoDialogOpen(true, 'Something went wrong. Please try again')
                } else if(data.ILEFields) {
                    // fields = [{label:'CountLines', value: 'CountLines', type:"numeric"}];
                    for(var elt in data.ILEFields) {
                        if (fields.filter(e=>e.value === data.ILEFields[elt].field).length === 0) { //if already exist do not add
                            fields.push({label:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace("PRIMARYKEY","INVOICELINEKEY"), value:data.ILEFields[elt][DEFINE_FIELDS.DATA_FIELD].replace(/ /g,'').replace("PRIMARYKEY","INVOICELINEKEY"),type:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase(),[_fieldDataType]:data.ILEFields[elt][DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase()})
                        }
                    }
                } 
                if (data.calculatedFields) {
                    for(var elt in data.calculatedFields) {
                        if (fields.filter(e=>e.value === data.calculatedFields[elt]["column_name"].replace("_cc","")).length === 0) {
                            fields.push({label:data.calculatedFields[elt]["column_name"].replace("_cc",""), value:data.calculatedFields[elt]["column_name"], type:"numeric", [_fieldDataType]:"numeric"})
                        } 
                    }
                } 
                fields = fields.filter(e=>e.type === 'numeric'); //Get all numeric types
                fields = fields.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i) //Remove Duplicates
                this.setState({
                    fields: fields
                });
            })
        .catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
            toggleLoader(false, "getFields");
        });
    }


    getCustomViews() {
        toggleLoader(true, 'loadData');
        var query = {
            action: "listProfitStackViews",
            fromBridgeConf: true
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
              var comp = this;
        fetch(`${baseUrl}${API_URL.MANAGE_STACKS}`, {
            body: JSON.stringify(query),
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        })
            .then(function (response) {
                if (response.status === 403) {
                    logout();
                }
                return response.json();
            })
            .then(function (data) {

                var tempState = {};
                
                tempState.views_data = data.data;
                tempState.optionsListViews = [];

                for (var i = 0; i < tempState.views_data.length; i++) {
                    tempState.optionsListViews.push({
                        value: tempState.views_data[i].custom_stack_id,
                        label: tempState.views_data[i].name,
                        description: tempState.views_data[i].description,
                        groups: tempState.views_data[i].groups.filter(e=> e.parent_cost_key === "201" && ![costtype.calculated, costtype.attribute].includes(e.costtype)).sort((a, b) => (a.rank > b.rank) ? 1 : -1)// only the groups that are in the first level must be shown
                    });


                }
                comp.setState(tempState, function () {
                    comp.getBridgeConfigurations();
                    toggleLoader(false, 'loadData');
                });


            });
    }

    onChangeCustomViews(option) {
        if (option !== null) {
            this.setState({
                custom_stack_id: option.value,
                custom_view_groups: option.groups,
                field_revenue: "",
            }, function () {
                this.leversRef.setLevers([]);
            });
        }
    }

    onChange(e,name) {
        if ([null, undefined].includes(e)) {
            return;
        }

        let tempState = {};
        switch(name){
            case MESSAGES.bridge_configurations.price_revenue:
                tempState.field_revenue = e.value;
            break;

            case MESSAGES.bridge_configurations.transactional_cogs:
                tempState.field_cogs = e.value;
            break;

            case MESSAGES.bridge_configurations.quantity_shipped:
                tempState.field_quantity_shipped = e.value;
            break;
        }

        this.setState(tempState);
      
    }


   
    resetInputs=()=>{
        this.setState({
            field_revenue:"",
            field_quantity_shipped:"",
            field_cogs:"",
            custom_stack_id:"",
            report_id:undefined
        },function(){
            if (this.leversRef) {
                this.leversRef.setLevers([]);
            }
        })
    }

    onChangeCalculated=(e) =>{
        if (e !== null) {
            this.setState({
                bridge_field: e.value
            },function(){
                var dataPerSelection = this.state.dataPerSelection ? this.state.dataPerSelection[this.state.bridge_field]:"";
                
                if(dataPerSelection !== "" && dataPerSelection){
                    for(var prop in dataPerSelection){
                        if(typeof dataPerSelection[prop] === "string" && !isNaN(dataPerSelection[prop])){
                            dataPerSelection[prop] = Number(dataPerSelection[prop]);
                        } 
                    }
                    let groups = this.state.optionsListViews.filter(e => e.value === dataPerSelection.custom_stack_id)[0]? this.state.optionsListViews.filter(e => e.value === dataPerSelection.custom_stack_id)[0][MESSAGES.bridge_configurations.groups]:[];
                    dataPerSelection[MESSAGES.bridge_configurations.custom_view_groups] = groups;
                    this.setState(dataPerSelection,function(){
                        this.leversRef.setLevers(this.state.levers);
                    });
                } else {
                    this.resetInputs();
                }
                
            });
           
        }
    }


    validationOnEmptyFields() {
        if (this.state.custom_stack_id === "" || this.state.field_revenue === "" || this.state.field_cogs === "" || this.state.field_quantity_shipped === ""  || this.state.custom_stack_id === undefined || this.state.field_cogs === undefined || this.state.field_revenue === undefined || this.state.field_quantity_shipped === undefined) {
          this.setInfoDialogOpen(true, MESSAGES.bridge_configurations.validation_text);
          return false;
        }
        var levers = this.leversRef.state.leversRef.filter(e=>e.leverObj);
        for (var e in levers) {
            if (levers[e].leverObj.lever_value === "" && levers[e].state.row_status !== "deleted") {
              this.setInfoDialogOpen(true, MESSAGES.bridge_configurations.validation_text)
              return false;
            }
        }
        return true;
    }

    saveBridgeConfigurations() {
        if (this.validationOnEmptyFields()) {
            // toggleLoader(true, 'saveBridgeConfigurations');
            var custom_stack_id = this.state.custom_stack_id;
            var field_value = this.state.field_revenue;
            var report_name = $("#reportName").val();
            var quantity_shipped = this.state.field_quantity_shipped;
            var bridge_field = this.state.bridge_field;
            var field_cogs = this.state.field_cogs;
            var levers = JSON.stringify(this.leversRef.getLeversData());
          
           
            var query = {
                action: "saveBridgeConfigurations",
                bridgeCustomViewId: custom_stack_id,
                bridgeRevField: field_value,
                reportName: report_name,
                bridgeQCField: quantity_shipped,
                levers: levers,
                scenario_id: 0,
                bridge_field:bridge_field,
                bridgeCogsField: field_cogs,
                reportId :this.state.report_id ? this.state.report_id : 0
               
            }
          setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
                  fetch(`${baseUrl}${path}`, {
                mode: 'cors',
                credentials: 'include',
                method: "POST",
                body: JSON.stringify(query)
            })
                .then((response) => {
                    if (response.status === 403) {
                        this.logout();
                    }
                    return response.json()
                })
                .then((data) => {
                    
                    this.setState({
                        message:MESSAGES.bridge_configurations.save_success
                    },function(){
                        this.getBridgeConfigurations(bridge_field)
                        this.launchToast()
                    })
                })
                .catch((error) => {
                    // alertAndLogError(error);
                }).then(() => {
                toggleLoader(false, 'saveBridgeConfigurations');
            });
        }

    }

    deleteConfiguration=() =>{
        toggleLoader(true, 'deleteConfiguration');
        var query = {
            action: "deleteConfiguration",
            reportId :this.state.report_id
            
        }
        if(!this.state.report_id){
            toggleLoader(false, 'deleteConfiguration');
            return;
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
              fetch(`${baseUrl}${path}`, {
            mode: 'cors',
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(query)
        })
            .then((response) => {
                if (response.status === 403) {
                    this.logout();
                }
                return response.json()
            })
            .then((data) => {
               
                this.setState({
                    message:MESSAGES.bridge_configurations.delete_success
                },function(){
                    this.resetInputs();
                    this.getBridgeConfigurations();
                    this.launchToast()
                })
            })
            .catch((error) => {
                // alertAndLogError(error);
            }).then(() => {
            toggleLoader(false, 'deleteConfiguration');
        });

        this.setOpenDeleteConfirmationDialog(false);
    }

    getBridgeConfigurations(bridge_field) {
        if(!bridge_field)
            toggleLoader(true, 'getBridgeConfigurations');
        var obj = this;

        var query = {
            action: "getBridgeConfigurations",
            scenario_id: 0
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
              fetch(`${baseUrl}${path}`, {mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(query)})
            .then((response) => {
                if (response.status === 403) {
                    obj.logout();
                }
                return response.json()
            })
            .then((data) => {
                    var data = data.data;
                    var custom_stack_id = "";
                    let custom_view_groups = "";
                    let field_quantity_shipped ="";
                    let bridge_f = "";
                    let field_revenue = "";
                    let field_cogs = "";
                    let report_name = "";
                    let report_id =  "";
                    let levers = "";
                    var object = {};
                    for (var e in data){
                        var calculated_line = data[e][MESSAGES.bridge_configurations.bridge_field];
                        object[calculated_line] = data[e];
                    }
                    if(data.length > 0){
                        custom_stack_id = Number(data[0].custom_stack_id);
                        custom_view_groups = obj.state.optionsListViews.filter(e => e.value === custom_stack_id)[0]? obj.state.optionsListViews.filter(e => e.value === custom_stack_id)[0][MESSAGES.bridge_configurations.groups]:[];
                        field_quantity_shipped = data[0][MESSAGES.bridge_configurations.field_quantity_shipped]
                        bridge_f = data[0][MESSAGES.bridge_configurations.bridge_field]
                        field_revenue = Number(data[0][MESSAGES.bridge_configurations.field_revenue])
                        field_cogs = Number(data[0][MESSAGES.bridge_configurations.field_cogs])
                        report_name = data[0][MESSAGES.bridge_configurations.report_name]
                        report_id =  data[0][MESSAGES.bridge_configurations.report_id]
                        levers = data[0].levers
                    }
                    obj.setState({
                        data:data,
                        custom_stack_id: custom_stack_id,
                        field_quantity_shipped: field_quantity_shipped,
                        bridge_field: bridge_f,
                        field_revenue:field_revenue,
                        report_name: report_name,
                        report_id:  report_id,
                        custom_view_groups: custom_view_groups,
                        levers:levers,
                        dataPerSelection: object,
                        field_cogs:field_cogs
                        
                    }, function () {
                        obj.leversRef.setLevers(obj.state.levers);
                        if(bridge_field){
                            this.onChangeCalculated({value:bridge_field})
                        }
                    })
            })
            .catch((error) => {
                // alertAndLogError(error);
            }).then(() => {
                if(!bridge_field)
                    toggleLoader(false, 'getBridgeConfigurations');
        });
    }
    launchToast() {
        $("#toastBridgeConfiguration").addClass("show");
        setTimeout(function(){
            $("#toastBridgeConfiguration").removeClass("show");
        }, 4000);
    }

    confirmDeleteConfiguration = () => {
        this.setOpenDeleteConfirmationDialog(true) 
    }

    deleteConfirmationDialogContent = () => {
        return (
            <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                <div className="fs-16">{MESSAGES.bridge_configurations.confirm_delete_configuration}</div>
            </div>
        )
    }
    deleteConfirmationActions = () => {
        return (
            <>
                <Button
                    id="delete-configuration-btn"
                    label={MESSAGES.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.deleteConfiguration}
                />
                <Button
                    label={MESSAGES.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setOpenDeleteConfirmationDialog(false)}
                />
            </>
        )
    }
    setOpenDeleteConfirmationDialog = (isOpen) => {
        let _this = this;
        _this.setState({
            openDeleteConfirmationDialog: isOpen
        })
    }

    
    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={MESSAGES.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }



    render() {
        
        const optionLabel = ({label, value}) => (
            
            <div className={"option-padding uk-flex-between uk-display-flex"}>
                {label} {this.state.dataPerSelection && this.state.dataPerSelection[value] ? <i className="fa-lg fa-lg fal fa-check greenText uk-margin-xsmall-top uk-padding-xsmall-left"></i> :  
                <i className="fa-lg fal fa-times uk-text-primary uk-margin-xsmall-top uk-padding-xsmall-left"></i> 
                
            }

            </div>
        );
        var groups = this.state.custom_view_groups;
        var calculatedLines = this.state.calculatedLines;
        let fields = this.state.fields;
        var calculatedLinesOptions = [];
        for (var e in calculatedLines) {
            calculatedLinesOptions.push({value: calculatedLines[e].returnName, label: calculatedLines[e].name});
        }
        var groupsOptions = [];
        for (var e in groups) {
            groupsOptions.push({value: groups[e].custom_stack_line_id, label: groups[e].name});
        }
       
        return (
            <div className="uk-padding-large">
                {this.state.session_timeout_ui ? 
                    <SessionTimeout isAuthenticated={this.state.user && this.state.machine_name !== ""} logout={this.logout} session_timeout_ui={this.state.session_timeout_ui} session_timeout_api={this.state.session_timeout_api}/>
                : ""}
                <div id="toastBridgeConfiguration" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (this.state.isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{this.state.message}</div>
                </div>
                 <div className={"uk-flex uk-flex-middle uk-margin-default-bottom uk-margin-small-top"} style={{float:"right",top:"-"+convertPxToViewport(30),position:"relative"}}>
                    <p className="fs-14 uk-margin-default-right"><em>{MESSAGES.bridge_configurations.save_changes_text}</em></p>
                    <Button 
                        label={MESSAGES.bridge_configurations.delete}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-margin-default-right"
                        onBtnClick={this.confirmDeleteConfiguration}
                    />
                    <Button 
                        label= {MESSAGES.bridge_configurations.save_changes}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={this.saveBridgeConfigurations}
                    />
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <label className="uk-text-xmedium width-170 uk-margin-small-right"
                           htmlFor="select-set-attribute">{MESSAGES.bridge_configurations.calculated_line}</label>
                    <DropDown
                        id="select_custom_view"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(calculatedLinesOptions, this.state.bridge_field)}
                        options={calculatedLinesOptions}
                        onChange={(option) => this.onChangeCalculated(option)}
                        formatOptionLabel={optionLabel}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <label className="uk-text-xmedium width-170 uk-margin-small-right"
                           htmlFor="select-set-attribute">{MESSAGES.bridge_configurations.stacks}</label>
                    <DropDown
                        id="select_custom_view"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(this.state.optionsListViews, this.state.custom_stack_id)}
                        options={this.state.optionsListViews}
                        onChange={(option) => this.onChangeCustomViews(option)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <label className="uk-text-xmedium width-170 uk-margin-small-right"
                           htmlFor="select-set-attribute">{MESSAGES.bridge_configurations.price_revenue}</label>
                    <DropDown
                        id="select_custom_view"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(groupsOptions, this.state.field_revenue)}
                        options={groupsOptions}
                        onChange={(option) => this.onChange(option,MESSAGES.bridge_configurations.price_revenue)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-default-bottom">
                    <label className="uk-text-xmedium width-170 uk-margin-small-right"
                           htmlFor="select-set-attribute">{MESSAGES.bridge_configurations.transactional_cogs}</label>
                    <DropDown
                        id="select_custom_view"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(groupsOptions, this.state.field_cogs)}
                        options={groupsOptions}
                        onChange={(option) => this.onChange(option,MESSAGES.bridge_configurations.transactional_cogs)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-medium-bottom">
                    <label className="uk-text-xmedium width-170 uk-margin-small-right"
                           htmlFor="select-set-attribute">{MESSAGES.bridge_configurations.quantity_shipped}</label>
                    <DropDown
                        id="select_custom_view"
                        className="select-dataSet width-200 input__dropdown"
                        value={findOptionByKey(fields, this.state.field_quantity_shipped)}
                        options={fields}
                        onChange={(option) => this.onChange(option,MESSAGES.bridge_configurations.quantity_shipped)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                <div>
                    <Levers ref={el => this.leversRef = el} groups={this.state.custom_view_groups}/>
                </div>
      
               <Modal
                  id={"delete-configuration-confirmation-dialog"}
                  title={MESSAGES.bridge_configurations.delete_configuration}
                  openDialog={this.state.openDeleteConfirmationDialog}
                  bodyContent={this.deleteConfirmationDialogContent}
                  dialogActions={this.deleteConfirmationActions}
                  closeClick={() => this.setOpenDeleteConfirmationDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
               />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </div>

        )
    }
}

export default BridgeConfigurationsManager;