import "./styles/login.css";
import React, {useState} from 'react';
import {termsAndConditions} from "./files/terms-and-conditions.mjs";
import {privacyPolicy} from "./files/privacy-policy.mjs";
import DialogContent from "@mui/material/DialogContent";

import { BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES } from "./class/constants";
import Button from "./newComponents/Button";
import Modal from "./newComponents/Modal";
import {lang} from "./language/messages_en";

const LogoPI = (props) =>{
	const [openPrivacy, setOpenPrivacy] = useState(false);
	const [openTerms, setOpenTerms] = useState(false);

	const handleOpenPrivacy = () => {
		setOpenPrivacy(true);
	}

	const handleOpenTerms = () => {
		setOpenTerms(true);
	}

	const handleClosePrivacy = () => {
		setOpenPrivacy(false);
	}

	const handleCloseTerms=()=>{
		setOpenTerms(false);
	}

  const privacyContent = () => {
    return (
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
      </DialogContent>
    );
  }

  const termsContent = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
    );
  }

  const termsDialogActions = () => {
    return (
      <Button
        label={"Ok"}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={handleCloseTerms}
      />
    )
  }

  const privacyDialogActions = () => {
    return (
      <Button
        label={"Ok"}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={handleClosePrivacy}
      />
    )
  }
	const currentYear =  new Date().getFullYear() ;
	const unifiedURL = "app.profitisle.com";
	const localhostURL = "localhost"; 
	const isUnifiedURL = window.location.href.includes(unifiedURL); // change unifiedURL to localhostURL to access the new login screen locally
	return (
		<div className="header-logo">
			{props.skipLogo?
				""
				:
				<div id="logo-img">
					<p>Powered By</p>
					<img className="img-responsive" src={"/images/logo.png"} alt={process.env.REACT_APP_CLIENT_NAME}/>
				</div>
			}
			<div id="footer-rights">
				<div id="rights-reserved">{props.landingFooter? `© ${currentYear}${lang.footer_text.landing_text}` : `© ${currentYear} ${lang.footer_text.login_text}`}</div>
				<div> | </div>
				<div className="footer-links">
					<a id="privacy" onClick={handleOpenPrivacy}>Privacy</a>
					<div> | </div>
					<a id="terms" onClick={handleOpenTerms}>Terms of Service</a>
          <Modal
            id={"privacy-dialog"}
            openDialog={openPrivacy}
            closeClick={handleClosePrivacy}
            bodyContent={privacyContent}
            dialogActions={privacyDialogActions}
            size={DIALOG_SIZE.LARGE}
          />
          <Modal
            id={"terms-dialog"}
            openDialog={openTerms}
            closeClick={handleCloseTerms}
            bodyContent={termsContent}
            dialogActions={termsDialogActions}
            size={DIALOG_SIZE.LARGE}
          />
				</div>
			</div>
		</div>
    );
}

export default LogoPI