import Tabulator from "tabulator-tables"; //import Tabulator library
import React, { Component } from 'react';
import { copyObjectValues, getTranslationFile, parseBoolean, findOptionByKey, getSectionExists, logoutIfUnauthenticated, tryParse, generateActionToken, deepCompareObjects } from '../../class/utils.js';
import { alertAndLogError, isOdd } from '../../class/jqueries';
import { INFO_MESSAGE, VECTOR_MAPPING, STAGING_SECTIONS, VECTOR_MAPPING_VIEW_MORE, VECTOR_MAPPING_VIEW_MORE_SUMMARY, API_URL, SYSTEM_CONFIG, FormatTypes, TRANSACTION_STATUS_ENUM, TOTAL_COMPANY, SCENARIO_STATUS, ALL_WIDGETS, PS_MAPPING, ROW_STATUS, EXCLUDED_LINES_FIELDS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, DIALOG_SIZE, MENU_ITEM } from '../../class/constants';
import { cleanUpSingleTabulatorColumn, toggleLoader ,getReadableFilterTextVectors, setLocalStorageValueByParameter,translateTabulatorFilter, translateTabulatorSorter} from '../../class/common.js';
import IdentifierMapping from './GLAccountList.js';
import { CustomSelect, Loader } from '../../form/elements.js';
import { lang } from '../../language/messages_en';

import { convertPxToViewport, convertViewportToPx, formatValString } from '../../class/formatting';
import Button from "../../newComponents/Button.js";
import DropDown from "../../newComponents/DropDown.js";
import { getTableButton, getTableIcon, getTablePlaceholder } from "../../newComponents/tabulatorComponents.js";
import Modal from "../../newComponents/Modal.js";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils.js";
import { formatValHTML } from "../../class/format.js";

const $ = require('jquery');


const baseUrl = process.env.REACT_APP_BASE_URL;
const MESSAGES = getTranslationFile();
var clickedRow = null;
const titles = [TRANSACTION_STATUS_ENUM.STAGED,TRANSACTION_STATUS_ENUM.INVALID, TRANSACTION_STATUS_ENUM.NOT_STAGED];
const path = "/DataModeling";
const AmCharts = require('@amcharts/amcharts3-react');

var VECTORS_MAPPING_COLUMNS = [
    {
        title: "",
        field: VECTOR_MAPPING.FIELDS.MAP,
        width: convertViewportToPx(130),
        tooltip: true, 
        dontFilter: true
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_ID,
        field: VECTOR_MAPPING.FIELDS.VECTOR_ID,
        visible: false
    }, 
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_COLUMN_NAME,
        field: VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME,
        width: convertViewportToPx(280), 
        tooltip: true, 
        dontFilter: true
   },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_KEY_FIELD,
        field: VECTOR_MAPPING.FIELDS.VECTOR_KEY_FIELD,
        visible: false,
        width: convertViewportToPx(290)
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_NUMBER_VALUE,
        field: VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE,
        visible: false,
        width: convertViewportToPx(290)
    },
    { 
        title: VECTOR_MAPPING.TITLES.VECTOR_NAME_VALUE,
        field: VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE,
        visible: false,
        width: convertViewportToPx(290)
    },
    { 
        title: VECTOR_MAPPING.TITLES.VECTOR_KEY_FIELD,
        field: VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE,
        visible: false,
        width: convertViewportToPx(290)
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_KEY_VALUE_TRANSLATED,
        field: VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE_TRANSLATED,
        visible: false,
        width: convertViewportToPx(290)
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_NUMBER_VALUE_TRANSLATED,
        field: VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE_TRANSLATED,
        visible: false,
        width: convertViewportToPx(290)
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_KEY_VALUE_DISPLAY,
        field: VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE_DISPLAY,
        tooltip: false, 
        dontFilter: true,
        width: convertViewportToPx(290)
    },
    {
        title: VECTOR_MAPPING.TITLES.VECTOR_NUMBER_VALUE_DISPLAY,
        field: VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE_DISPLAY,
        tooltip: false, 
        dontFilter: true,
        width: convertViewportToPx(290)
    },
    { 
        title: VECTOR_MAPPING.TITLES.VECTOR_NAME_VALUE_DISPLAY,
        field: VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE_DISPLAY,
        tooltip: false, 
        dontFilter: true,
        width: convertViewportToPx(290)
    },
    { 
        title: VECTOR_MAPPING.TITLES.VECTOR_NAME_VALUE_TRANSLATED, 
        field: VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE_TRANSLATED,
        visible: false,
        width: convertViewportToPx(290)
    },
    { 
        title: VECTOR_MAPPING.TITLES.ENTITY_COUNT,
        field: VECTOR_MAPPING.FIELDS.ENTITY_COUNT,
        format_type: FormatTypes.NUMERIC,
        tooltip: true, 
        dontFilter: true,
        width: convertViewportToPx(170)
    },
    { 
        title: VECTOR_MAPPING.TITLES.DETAILS,
        field: VECTOR_MAPPING.FIELDS.DETAILS,
        headerSort: false,
        width: convertViewportToPx(80)
    },
    { 
        title: VECTOR_MAPPING.TITLES.ROW_STATUS,
        field: VECTOR_MAPPING.FIELDS.ROW_STATUS,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.IS_DEFAULT,
        field: VECTOR_MAPPING.FIELDS.IS_DEFAULT,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.STATUS,
        field: VECTOR_MAPPING.FIELDS.STATUS,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.PERIOD,
        field: VECTOR_MAPPING.FIELDS.PERIOD,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.IS_USED,
        field: VECTOR_MAPPING.FIELDS.IS_USED,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.RULE,
        field: VECTOR_MAPPING.FIELDS.RULE,
        visible: false
    },
    { 
        title: VECTOR_MAPPING.TITLES.DISABLED,
        field: VECTOR_MAPPING.FIELDS.DISABLED,
        visible: false
    },
    { 
        title: '', 
        field: VECTOR_MAPPING.FIELDS.DELETE,
        headerSort: false, 
        tooltip: true, 
        dontFilter: true,
        align:"right"
    }
];
/**
 * Vector view more Mapping screen
 * @author [Lara Jannoun]
 */
var VECTORS_MAPPING_VIEW_MORE_COLUMNS = [
    {
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.VECTOR_KEY,
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.VECTOR_KEY,
        minWidth: 200,
        format_type: FormatTypes.TEXT
    },
    {
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.VECTOR_NUMBER,
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.VECTOR_NUMBER,
        minWidth: 200,
        format_type: FormatTypes.TEXT
    },
    {
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.VECTOR_COLUMN_NAME,
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.VECTOR_COLUMN_NAME,
        minWidth: 200,
        format_type: FormatTypes.TEXT
    },
    { 
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.TOTAL_LINE, 
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.TOTAL_LINE,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC,
    },
    { 
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.TOTAL_REVENUE, 
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.TOTAL_REVENUE,
        minWidth: 200,
        format_type: FormatTypes.AMOUNT
    },
    { 
        title: VECTOR_MAPPING_VIEW_MORE.TITLES.TOTAL_COGS, 
        field: VECTOR_MAPPING_VIEW_MORE.FIELDS.TOTAL_COGS,
        minWidth: 200,
        format_type: FormatTypes.AMOUNT
    }    
];


/**
 * Vector Summary Mapping screen
 * @author [Lara Jannoun]
 */
var VECTORS_MAPPING_VIEW_MORE_SUMMARY_COLUMNS = [
    {
        title: VECTOR_MAPPING_VIEW_MORE_SUMMARY.TITLES.VECTOR_KEY_COUNT,
        field: VECTOR_MAPPING_VIEW_MORE_SUMMARY.FIELDS.VECTOR_KEY_COUNT,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC
    },
    {
        title: VECTOR_MAPPING_VIEW_MORE_SUMMARY.TITLES.VECTOR_NUMBER_COUNT,
        field: VECTOR_MAPPING_VIEW_MORE_SUMMARY.FIELDS.VECTOR_NUMBER_COUNT,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC
    },
    {
        title: VECTOR_MAPPING_VIEW_MORE_SUMMARY.TITLES.VECTOR_COLUMN_NAME_COUNT,
        field: VECTOR_MAPPING_VIEW_MORE_SUMMARY.FIELDS.VECTOR_COLUMN_NAME_COUNT,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC
    },
    { 
        title: VECTOR_MAPPING_VIEW_MORE_SUMMARY.TITLES.TOTAL_REVENUE_COUNT, 
        field: VECTOR_MAPPING_VIEW_MORE_SUMMARY.FIELDS.TOTAL_REVENUE_COUNT,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC
    },
    { 
        title: VECTOR_MAPPING_VIEW_MORE_SUMMARY.TITLES.TOTAL_COGS_COUNT, 
        field: VECTOR_MAPPING_VIEW_MORE_SUMMARY.FIELDS.TOTAL_COGS_COUNT,
        minWidth: 200,
        format_type: FormatTypes.NUMERIC
    }    
];

/**
 * Vector Mapping screen
 * @author [Sarah Farjallah]
 */
class VectorMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPeriod: this.props.selectedPeriod,
            vectors:[],
            newRow: false,
            changed: false,
            rowEdited: false
        };

        this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);
        this.addNewRow = this.addNewRow.bind(this);
        this.redrawTable = false;
        this.getVectors = this.getVectors.bind(this);
        this.hideVectorMapping = this.hideVectorMapping.bind(this);
        this.showVectorMapping = this.showVectorMapping.bind(this);
        this.getVectorDetail = this.getVectorDetail.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.setVectorMapping = this.setVectorMapping.bind(this);
        this.getTranslatedIdentifier = this.getTranslatedIdentifier.bind(this);
        this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
        this.setChosenTimePeriod = this.setChosenTimePeriod.bind(this);
        this.humanTranslate = this.humanTranslate.bind(this);
        this.cancelVector = this.cancelVector.bind(this);
        this.compareObjects = this.compareObjects.bind(this);
        this.editTitleFormatter = this.editTitleFormatter.bind(this);
        this.isRowEdited = this.isRowEdited.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.maximizeVectorMappingWindow = this.maximizeVectorMappingWindow.bind(this);
        this.minimizeVectorMappingWindow = this.minimizeVectorMappingWindow.bind(this);
        this.getChartValues = this.getChartValues.bind(this);
        this.generateStatus = this.generateStatus.bind(this);
        this.setDefaultTimePeirod = this.setDefaultTimePeirod.bind(this);
        this.checkScenarioAccess = this.checkScenarioAccess.bind(this);
        this.createNewScenario = this.createNewScenario.bind(this);
        this.checkEmptyFields = this.checkEmptyFields.bind(this);
        this.getVectorPeriodStatus = this.getVectorPeriodStatus.bind(this);
        this.showModal = this.showModal.bind(this);
        this.vectorMappingObj = {};
        this.isLoadingEntityCount = false;
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }
    
    setChosenTimePeriod(period) {
        var obj = this;
        this.setState({
            selectedPeriod: period
        }, function (){
            if(obj.isLoadingEntityCount) {
                obj.abortVectorsEntityCountRequest();
            } else {
                obj.resetEntityCounts();
            }
        });
    }

    abortVectorsEntityCountRequest = () => {
        //cancel request
        this.controller.abort()
        this.isLoadingEntityCount = false;
        this.resetEntityCounts();
        //Re initialize controller after canceling
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }
   

    getVectorsEntityCount = () => {
        let _this = this;
        _this.isLoadingEntityCount = true;
        //We need to set columns here for the smaller loader to show
        _this.tabulator.setColumns(this.getTabulatorColumns(VECTORS_MAPPING_COLUMNS))
         $(".add-vector-plus-top").removeClass("uk-hidden");
        let query = {
          action: "getVectorsEntityCount",
          period: this.state.selectedPeriod.value,
          vectors: this.tabulator.getData()
        }
        let onThenCallback = (data) => {
          if(data.data) {
            let vectorsData = data.data;
            _this.isLoadingEntityCount = false;
            _this.tabulator.replaceData(vectorsData);
          }
        }
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getVectorsEntityCount",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: false,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING, 
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.signal]: _this.signal,
          [FETCHAPI_PARAMS.periods]:_this.state.selectedPeriod.value
        };
        fetchAPI(fetchOptions);
    }

    resetEntityCounts = () => {
        let data = this.tabulator.getData();
        data.forEach(item => {
            item.count = 0;
            item.entity_count_computed = false;
        })
        this.tabulator.replaceData(data);
    }

    humanTranslate(value) {
        if (value !== undefined) {
            if(value.includes("CASE")) {
                return value.replace(/[t][_]\d+./g, "").replaceAll("  "," ").replaceAll("CAST","").replaceAll("AS STRING","").replaceAll("CONCAT","").replace(/IFNULL/g,'').replace('"UNK"','').replace('0','').replace(/[,\(\)'"]+/g,"");
            }
            return value.replace(/[t][_]\d+./g, "").replaceAll("CAST","").replaceAll("AS STRING","").replaceAll("CONCAT","").replace(/IFNULL/g,'').replace('"UNK"','').replace('0','').replace(/[,\(\)'"]+/g,"");
        } else{
            return "";
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        var shouldUpdate = true;
        return shouldUpdate;
    }

    getTabulatorColumns(columns) {
        let obj = this;
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
          if (!col.formatter && !col.format_type) {
            col.formatter = obj.getColumnFormatter(col.field, col.formatter);
            if (col.field === VECTOR_MAPPING.FIELDS.MAP) {
              col.titleFormatter = obj.addNewRowTitleFormatter;
              col.titleFormatterParams = { funkName: obj.addNewRow, obj: obj.state };
              col.headerSort = false;
            } else if ([VECTOR_MAPPING.FIELDS.DETAILS, VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME].includes(col.field)) {
              col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, INFO_MESSAGE); // MESSAGES[columnFieldMessage]); // TO DO
            } 
          } else if(col.format_type) {
            col.formatter = obj.getColumnFormatter(col.field, col.formatter);
          } else {
            col.formatterParams = { defaultValue: "" }
            col = cleanUpSingleTabulatorColumn(col);
          }
        });
       
        return columns;
    }

    
    getVectors(period, mount) {
        var obj = this;
        toggleLoader(true, 'getVectors');
        var query = {
            action: "getVectors",
            scenario_id: this.props.scenarioId,
            vectors: this.tabulator !== undefined ? JSON.stringify(obj.tabulator.getData()) : "",
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success:function(response, result, xhr){
                response = JSON.parse(response);
                var tempState = {};

                if (response.data) {
                    $(".add-vector-plus-top").removeClass("uk-hidden");
                    tempState.vectors = response.data;
                    if (mount !== undefined) {
                        obj.setState({
                            originalVectors : copyObjectValues(response.data)
                        })
                    }
                    obj.tabulator.setData(response.data);
                }
                if(response.connectors) {
                    tempState.connectors = response.connectors.map(function(item) {
                        return { label: item[SYSTEM_CONFIG.SETTING_NAME], value: item[SYSTEM_CONFIG.SETTING_VALUE], type: VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR}
                    });
                }
                obj.setState(tempState);
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, 'getVectors');
            }
        });

    }

    getVectorDetail = (cell, timePeriod,moreDetailsFilter=[],moreDetailsSorter=[]) => {
        let _this = this;
        let nf = new Intl.NumberFormat();
        let emptyObject = [{}];
        if (typeof timePeriod === 'object') {
            timePeriod = timePeriod.value;
        }
        var query = {
            action: "getVectorDetail",
            scenario_id: this.props.scenarioId,
            timePeriod: timePeriod ? timePeriod : "",
            vectorFields: cell && cell !== null && cell.getRow().getData() ? cell.getRow().getData() : clickedRow,
            moreDetailsFilter: JSON.stringify(moreDetailsFilter),
            moreDetailsSorter: JSON.stringify(moreDetailsSorter)
        }
        let onThenCallback = (data) => {
          if(data.data) {
                _this.setState({
                    vectorDetail: data.data,
                    vectorDetailSummary: data.dataSummary,
                    entityCount:nf.format(data.data.length)
                },()=>{
                    _this.drawViewMoreTables();
                })
          } else {
            _this.setState({
                vectorDetail: emptyObject,
                vectorDetailSummary: emptyObject
            })
          }
        }

        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getVectorDetail",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING, 
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.periods]: timePeriod
        };

        fetchAPI(fetchOptions);
    }

    saveVectors() {
        var obj = this;
        toggleLoader(true, 'saveVectors');
        var query = {
            action: "saveVectors",
            scenario_id: this.props.scenarioId,
            vectors: JSON.stringify(this.tabulator.getData())
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (response, result, xhr) {
                toggleLoader(false, 'saveVectors');
                obj.props.setSaveDialogOpen(lang.vectors_saved)
            },
            error: function(error) {
                alertAndLogError(error);
                toggleLoader(false, 'saveVectors');
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, 'saveVectors');
                obj.props.goBackToStaging(true);
            }
        });
    }

    createNewScenario(){
        var obj = this;
        obj.setOpenNoAccessDialog(false)
        toggleLoader(true, "createNewScenario");
        let actionToken = generateActionToken(10)
        var obj = this;
        var query = {
            action: "createNewScenario",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            scenarioSetting: JSON.stringify(this.tabulator.getData()),
            scenarioSettingName: "vectors",
            actionToken:actionToken
        }
        console.log("Create New Scenario Vectors Action Token: " + actionToken);
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl+path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                obj.setInfoDialogOpen(true, "A new scenario has been added to your sandbox!");
                return;
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
                obj.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
                toggleLoader(false, "createNewScenario");
            }
        });
    }

    checkScenarioAccess() {
        var obj = this;
        var isUserAllowed = getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.UPDATE_VECTOR)
        // if (!obj.state.changed && !obj.state.showGLAccounts) {
        //     obj.cancelVector();
        //     obj.props.goBackToStaging(true);
        //     return;
        // } else 

        if (!obj.state.changed && obj.state.showGLAccounts) {
            obj.setOpenConfirmBackDialog(true);
            return;
        } else if (!isUserAllowed && obj.props.scenarioStatus.toUpperCase() !== SCENARIO_STATUS.SANDBOX) {
          obj.setOpenNoAccessDialog(true)  
          return
        } else if (obj.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX) {
            obj.createNewScenario();
        } else if (obj.props.scenarioStatus === SCENARIO_STATUS.SANDBOX || isUserAllowed) {
            obj.saveVectors();
        }
    }

    onDeleteRow() {
        var obj = this;
        this.hideVectorMapping();
        if (this.state.newRow) {
            this.state.vectors.splice( this.state.vectors.length -1, 1);
            this.tabulator.replaceData(this.state.vectors); 
            this.setState({
                newRow: false
            })
        }
        var vectors = copyObjectValues(this.tabulator.getData());

        if(this.vectorMappingObj && parseBoolean(this.vectorMappingObj[VECTOR_MAPPING.FIELDS.IS_USED]) === true) {
          obj.setInfoDialogOpen(true, this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] + MESSAGES.vector_map_delete_used_vector_err);
            return;
        }

        if(this.vectorMappingObj && this.vectorMappingObj[VECTOR_MAPPING.FIELDS.ROW_STATUS] !== ROW_STATUS.VALUES.NEW) {
            this.vectorMappingObj[VECTOR_MAPPING.FIELDS.DELETE] = "true";
            this.vectorMappingObj[VECTOR_MAPPING.FIELDS.ROW_STATUS] = ROW_STATUS.VALUES.DELETED;
            this.setState({
                changed: true
            })
            vectors[this.mappedVectorIndex] = this.vectorMappingObj;
            this.state.vectors[this.mappedVectorIndex] = this.vectorMappingObj;
        } else {
            vectors.splice(this.mappedVectorIndex, 1);
            // this.state.vectors.splice(this.mappedVectorIndex, 1);
            this.tabulator.replaceData(vectors);
            var newVectorsCount = this.state.vectors.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] !== obj.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME]).filter(e=>e[VECTOR_MAPPING.FIELDS.ROW_STATUS] === VECTOR_MAPPING.FIELDS.ROW_STATUS.NEW).length;
            if (newVectorsCount === 0) {
                this.setState({
                    changed:false,
                    vectors: vectors
                })
            }
        }
        
        this.tabulator.setFilter(VECTOR_MAPPING.FIELDS.DELETE, "in", ["false", ""]);
        $("#load_entity_count").removeClass("disabled uk-disabled")
        if(this.isLoadingEntityCount){
            this.abortVectorsEntityCountRequest();
        }
    }
        
    addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        p.appendChild(el);

        div.appendChild(p);

        return div;
    }

    getColumnFormatter(colField) {
        var columnFormatter;
        var obj = this;

        switch (colField) {
            case VECTOR_MAPPING.FIELDS.MAP:
                columnFormatter = function(cell) {
                    var p = document.createElement('p');
                    var icon = getTableIcon(["fal", "fa-sliders-h", "fa-lg"])
                    icon.id = "map";
                    var row = cell.getRow().getData();
                    // if(cell.getRow().getData()[VECTOR_MAPPING.FIELDS.DISABLED] === "true"){
                    //     p.setAttribute("uk-tooltip","Vector is used in metric");
                    //     icon.classList.add("disabled");
                    // } 
                    // if (row[VECTOR_MAPPING.FIELDS.IS_DEFAULT] !== 'true' && cell.getRow().getData()[VECTOR_MAPPING.FIELDS.DISABLED] === "false") {
                    if (row[VECTOR_MAPPING.FIELDS.IS_DEFAULT] !== 'true') {
                        icon.onclick = ()=>{
                            if (!obj.state.newRow) {
                                obj.vectorMappingObj = cell.getRow().getData();
                                obj.state.newRow = false;
                                obj.mappedVectorIndex = cell.getRow().getPosition();
                                obj.showVectorMapping(cell.getRow().getData());
                            }
                        }
                    } else if (parseBoolean(row[VECTOR_MAPPING.FIELDS.IS_DEFAULT])){
                        icon.classList.add("disabled");
                    }
                    p.appendChild(icon);
                    return icon;
                }
            break;
            case VECTOR_MAPPING.FIELDS.DELETE:
            columnFormatter = function (cell) {
                var p = document.createElement("p");
                if(cell.getRow().getData()[VECTOR_MAPPING.FIELDS.DELETE] === "true" ) {
                    $(cell.getRow().getElement()).css({"display": "none"});
                } else {
                    var el = getTableIcon(["fal","fa-trash-alt", "fa-lg"]);
                    el.title = "Delete";
                    el.id = "delete-icon";
                    if(cell.getRow().getData()[VECTOR_MAPPING.FIELDS.DISABLED] === "true"){
                        p.setAttribute("uk-tooltip","Vector is used in metric");
                        $(el).attr("disabled", "true");
                        el.classList.add("disabled");
                    } else if (cell.getRow().getData()[VECTOR_MAPPING.FIELDS.IS_DEFAULT] === "true") {
                        $(el).attr("disabled", "true");
                        el.classList.add("disabled");
                        el.setAttribute("uk-tooltip", "Default vectors can't be deleted");
                    } else {
                        // if(cell.getRow().getData() && parseBoolean(cell.getRow().getData()[VECTOR_MAPPING.FIELDS.IS_USED]) !== true) {
                        //     $(el).attr("data-toggle", "modal");
                        //     $(el).attr("data-target", "#confirmDelete");
                        // }

                        el.onclick = () => {
                            obj.vectorMappingObj = cell.getRow().getData();
                            obj.mappedVectorIndex = cell.getRow().getPosition();
                            obj.checkBeforeDeletion();
                            obj.forceUpdate();
                        };
                    }
                    p.appendChild(el);
                    return p;
                }
            }
            break;
            case VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE_DISPLAY:
            columnFormatter = function (cell) {
                var div = document.createElement('div');
                var rowData = cell.getRow().getData();
                var allTypeColumns = obj.props.allTypeColumns;
                var jsonObj = "";
                var readableText = "";
                var name =  rowData[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME];
                var number_subtype_id = rowData[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID];
                if(rowData[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE]){
                    jsonObj = tryParse(rowData[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE]);
                    
                    if(jsonObj && jsonObj["result"]) {
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,undefined,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    } else {
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,number_subtype_id,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    }  
                }
            }
            break;
            case VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE_DISPLAY:
            columnFormatter = function (cell, formatterParams) {
                var div = document.createElement('div');
                var rowData = cell.getRow().getData();
                var jsonObj = "";
                var readableText = "";
                var allTypeColumns = obj.props.allTypeColumns;
                var name =  rowData[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME]
             var name_subtype_id = rowData[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID]
                if(rowData[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE]){
                    jsonObj = tryParse(rowData[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE]);
                    
                    if(jsonObj && jsonObj["result"]) {
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,undefined,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    } else {
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,name_subtype_id,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    }  
                }
            }
            break;
            case VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE_DISPLAY:
            columnFormatter = function (cell, formatterParams) {
                var div = document.createElement('div');
                var rowData = cell.getRow().getData();
                var allTypeColumns = obj.props.allTypeColumns;
                var jsonObj = "";
                var readableText = "";
                var name =  rowData[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME];
                var key_subtype_id = rowData[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID];
                if(rowData[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE]){
                    jsonObj = tryParse(rowData[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE]);
                   
                    if(jsonObj && jsonObj["result"]){
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,undefined,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    } else {
                        readableText = getReadableFilterTextVectors(jsonObj,allTypeColumns,key_subtype_id,name,obj.props.scenarioId);
                        div.textContent = readableText;
                        div.title = readableText;
                        return div;
                    }
                }
            }
            break;
            case VECTOR_MAPPING.FIELDS.DETAILS:
                columnFormatter = function (cell, formatterParams) {
                    var div = document.createElement("div");
                    var name = obj.humanTranslate(cell.getRow().getData()[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE_DISPLAY]);
                    if(!name.toUpperCase().includes(TOTAL_COMPANY)) {
                        div.classList.add("text-link");
                        div.innerHTML = "More";
                        div.onclick = () => {
                            clickedRow = cell.getRow().getData();
                            var timePeriod = obj.state.selectedPeriod;
                            obj.getVectorDetail(cell, timePeriod);
                            obj.setOpenViewMoreDialog(true);

                        }
                    }
                    return div;

            }
            break;
            case VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME:
            columnFormatter = function (cell, formatterParams) {
                var div = document.createElement("div");
                var p = document.createElement("p");
                p.innerHTML = typeof cell === "string" ? cell : cell.getValue() ? cell.getValue() : "-";
        
                var el = document.createElement("i");
                p.setAttribute("uk-tooltip",cell.getValue() && cell.getRow().getData()[VECTOR_MAPPING.FIELDS.VECTOR_DESCRIPTION] !== ""?
                    "title: Name: " +cell.getValue()+" <br> Description: "+cell.getRow().getData()[VECTOR_MAPPING.FIELDS.VECTOR_DESCRIPTION]+";pos:bottom" : "title: Name: " +cell.getValue()+ "<br> No Description");
                p.classList.add("underline-onhover");
                p.appendChild(el);
                div.appendChild(p);
                return div;
            }
            break;
            case VECTOR_MAPPING.FIELDS.ENTITY_COUNT:
                columnFormatter = function (cell, formatterParams) {
                    let entityCount = cell.getRow().getData()[VECTOR_MAPPING.FIELDS.ENTITY_COUNT] || "";
                    let entityCountComputed =  cell.getRow().getData()[VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED];
                    let p = document.createElement("p");
                    if(obj.isLoadingEntityCount && !parseBoolean(entityCountComputed)) {
                        let img = document.createElement("i");
                        img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        img.id = "small_loader";
                        p.appendChild(img);
                    } else {
                        entityCount = entityCount === 0 ? "-" : entityCount.toString();
                        p.innerHTML = formatValHTML(entityCount, FormatTypes.NUMERIC);
                        p.title = formatValString(entityCount, FormatTypes.NUMERIC);
                    }
                    return p;
                }
                break;
            default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                }
            break;
                 
        }
        return columnFormatter;
    }

    deleteRowUsedDialogContent = () => {
      let linesUsingAttribute = this.props?.usedAttributes?.filter(attr=>attr[PS_MAPPING.FIELDS.ATTRIBUTE] === this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME]);    

      if (linesUsingAttribute?.length > 0) {
          return (
              <div>
                  <h4>{`${this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME]} ${MESSAGES.used_ps_no_delete_1_attr}`}</h4>
                  <ul>
                      {linesUsingAttribute.map((line) => <li key={line.name}><h4 className="uk-margin-left">{"- " + line.name}</h4></li>)}
                  </ul>
                  <h4>{lang.used_ps_no_delete_2}</h4>
              </div>
          )
      }
    }
    deleteRowUsedDialogActions = () => {
      return (
        <Button
          label={MESSAGES.modal.buttons.ok}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenDeleteRowUsedDialog(false)}
        />
      )
    }
    setOpenDeleteRowUsedDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openDeleteRowUsedDialog: isOpen
      })
    }

    checkBeforeDeletion() {
        let _this = this;
        let linesUsingAttribute = this.props?.usedAttributes?.filter(attr=>attr[PS_MAPPING.FIELDS.ATTRIBUTE] === _this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME]);
        if(linesUsingAttribute.length > 0) {
            this.setOpenDeleteRowUsedDialog(true)
        } else if (this.vectorMappingObj && parseBoolean(this.vectorMappingObj[VECTOR_MAPPING.FIELDS.IS_USED]) === true) {
            _this.setInfoDialogOpen(true, this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] + MESSAGES.vector_map_delete_used_vector_err);
            return;
        } else {
           _this.setOpenDeleteRowDialog(true)
        }
    }

    deleteRow() {
      this.setOpenDeleteRowDialog(false)  
      this.onDeleteRow();
        if (this.state.showGLAccounts) {
            this.hideVectorMapping();
        }
    }

  initializeViewMoreTables = () => {
    let _this = this;

    var options = {
      layout: "fitColumns",      //fit columns to width of table
      data: [],
      tooltipsHeader: true,
      responsiveLayout: false,  //hide columns that dont fit on the table
      tooltips: true,            //show tool tips on cells
      addRowPos: "top",          //when adding a new row, add it to the top of the table
      history: true,             //allow undo and redo actions on the table
      pagination: false,          //paginate the data
      movableColumns: false,     //allow column order to be changed
      selectable: false,
      height: "100%",
      resizableColumns: false,
      autoResize: true,
      virtualDomBuffer: 4000,
      width: "100%",
      placeholder: MESSAGES.no_data_available,
      reactiveData: true,      //tabulator listens to any change in the data array and updates the table
    }

    var viewMoreVectors = copyObjectValues(options);
    var viewMoreColumns = copyObjectValues(VECTORS_MAPPING_VIEW_MORE_COLUMNS);
    viewMoreColumns.forEach(viewMoreColumn => {
        if (!viewMoreColumn.formatter && !viewMoreColumn.format_type) {
            viewMoreColumn.formatter = _this.getColumnFormatter(viewMoreColumn.field, viewMoreColumn.formatter);
        } else {
            viewMoreColumn.formatterParams = { defaultValue: "" }
            viewMoreColumn = cleanUpSingleTabulatorColumn(viewMoreColumn);
        }
        viewMoreColumn.titleFormatter = _this.editTitleFormatter;
    });
    viewMoreVectors.columns = viewMoreColumns;

    var viewMoreSummaryVectors = copyObjectValues(options);
    var viewMoreSummaryColumns = copyObjectValues(VECTORS_MAPPING_VIEW_MORE_SUMMARY_COLUMNS);
    viewMoreSummaryColumns.forEach(viewMoreSummaryColumn => {
        if (!viewMoreSummaryColumn.formatter && !viewMoreSummaryColumn.format_type) {
            viewMoreSummaryColumn.formatter = _this.getColumnFormatter(viewMoreSummaryColumn.field, viewMoreSummaryColumn.formatter);
        } else {
            viewMoreSummaryColumn.formatterParams = { defaultValue: "" }
            viewMoreSummaryColumn = cleanUpSingleTabulatorColumn(viewMoreSummaryColumn);
        }
        viewMoreSummaryColumn.titleFormatter = _this.editTitleFormatter;
    });
    viewMoreSummaryVectors.columns = viewMoreSummaryColumns;


    _this.viewMoreVectorDetailTable = new Tabulator("#viewMoreVectorDetail", viewMoreVectors);
    _this.viewMoreSummaryVectorDetail = new Tabulator("#viewMoreSummaryVectorDetail", viewMoreSummaryVectors);

  }
  drawViewMoreTables = () => {
    let _this = this;

    var viewMoreColumns = copyObjectValues(VECTORS_MAPPING_VIEW_MORE_COLUMNS);
    viewMoreColumns.forEach(viewMoreColumn => {
      if (!viewMoreColumn.formatter && !viewMoreColumn.format_type) {
        viewMoreColumn.formatter = _this.getColumnFormatter(viewMoreColumn.field, viewMoreColumn.formatter);
      } else {
        viewMoreColumn.formatterParams = { defaultValue: "" }
        viewMoreColumn = cleanUpSingleTabulatorColumn(viewMoreColumn, { id: this.viewMoreVectorDetailTable.element.id });
      }
    });
    _this.viewMoreVectorDetailTable.setColumns(viewMoreColumns);
    _this.viewMoreVectorDetailTable.setData(_this.state.vectorDetail);		//set data for view more table	
    if (_this.viewMoreVectorDetailTable.getSorters().length > 0) {
      _this.viewMoreVectorDetailTable.setSort(_this.viewMoreVectorDetailTable.getSorters()[0].field, _this.viewMoreVectorDetailTable.getSorters()[0].dir);
    } else {
      _this.viewMoreVectorDetailTable.setSort(EXCLUDED_LINES_FIELDS.TOTAL_REVENUE, "desc");
    }
    _this.viewMoreVectorDetailTable.clearFilter(true);
    _this.viewMoreSummaryVectorDetail.setHeight(convertPxToViewport(100));
    _this.viewMoreSummaryVectorDetail.setData(_this.state.vectorDetailSummary);		//set data for view more summary table
  }

  componentDidUpdate(prevProps, prevState) {
    var obj = this;

    if (!obj.viewMoreVectorDetailTable && $("#viewMoreVectorDetail").length > 0 && obj.state.openViewMoreDialog) {
      obj.initializeViewMoreTables();
    }

    // if ((obj.state.vectorDetailSummary?.length || prevState.vectorDetailSummary?.length) && !deepCompareObjects(obj.state.vectorDetailSummary, prevState.vectorDetailSummary)
    //   || (obj.state.vectorDetail?.length || prevState.vectorDetail?.length) && !deepCompareObjects(obj.state.vectorDetail, prevState.vectorDetail)) {
    //   obj.drawViewMoreTables();
    // }


    if (this.tabulator.getData().length > 0) {
      $("#header-next").removeClass("uk-hidden");
    } else {
      $("#header-next").addClass("uk-hidden");
    }
    if (prevProps.selectedPeriod.value !== this.props.selectedPeriod.value) {
      prevProps = this.props;
    }
  }

    isRowEdited() {
        var tabData = this.tabulator.getData();
        if (this.state.originalVectors.length !== this.state.vectors.length) {
            return false;
        }
        for (var e in this.state.originalVectors) {
            if (!this.compareObjects(tabData[e], this.state.originalVectors[e])) {
                return false;
            }
        }
        return true;
    }

    componentWillUnmount() {
        $("#periodList-header").addClass("uk-hidden");
        // $("#header-buttons").addClass("uk-hidden");
        $("#scenarioList-header").removeClass("disabled");
        $("#header-next").removeClass("uk-hidden");
        $("#header-next").unbind('click');
        $("#header-back").unbind('click');
    }

    onBackClick = () => {
        var obj = this;
         obj.props.goBackToStaging(false, obj.state.changed || obj.state.showGLAccounts);
    }

    componentDidMount() {
      var obj = this;
      obj.getVectors(obj.props.selectedPeriod, true);
      //showing the period drop down list in header
      $("#periodList-header").removeClass("uk-hidden");
      $("#periodList-header").removeClass("disabled");
      $("#header-next").unbind('click');
      $("#header-back").unbind('click');
      //disabling the scenario drop down list
      $("#scenarioList-header").addClass("disabled");

      //adding the back and apply buttons to header
      // $("#header-buttons").removeClass("uk-hidden");
      $("#header-title p").text(MESSAGES.vectors);
      $("#header-title-icon").removeClass("uk-hidden");

      // $("#header-back").click(function (e) {
      //   if (obj.state.changed || obj.state.showGLAccounts) {
      //     obj.setOpenConfirmBackDialog(true);
      //   } else {
      //     obj.props.goBackToStaging(false);
      //   }
      // });

      // $("#header-next").click(function (e) {
      //   obj.props.showScenarioPopUp();
      // });

      var options = {
        layout: "fitColumns",      //fit columns to width of table
        data: [],
        tooltipsHeader: true,
        responsiveLayout: false,  //hide columns that dont fit on the table
        tooltips: true,            //show tool tips on cells
        addRowPos: "top",          //when adding a new row, add it to the top of the table
        history: true,             //allow undo and redo actions on the table
        pagination: false,          //paginate the data
        movableColumns: false,     //allow column order to be changed
        selectable: false,
        height: "100%",
        resizableColumns: false,
        autoResize: true,
        virtualDomBuffer: 10000,
        width: "100%",
        placeholder: MESSAGES.no_data_available,
        reactiveData: true,      //tabulator listens to any change in the data array and updates the table
      }
      var vectors = copyObjectValues(options);
      var tableColumns = copyObjectValues(VECTORS_MAPPING_COLUMNS);
      tableColumns.forEach(col => {
        if (!col.formatter && !col.format_type) {
          col.formatter = obj.getColumnFormatter(col.field, col.formatter);
          if (col.field === VECTOR_MAPPING.FIELDS.MAP) {
            col.titleFormatter = obj.addNewRowTitleFormatter;
            col.titleFormatterParams = { funkName: obj.addNewRow, obj: obj.state };
            col.headerSort = false;
          } else if ([VECTOR_MAPPING.FIELDS.DETAILS, VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME].includes(col.field)) {
            col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, INFO_MESSAGE); // MESSAGES[columnFieldMessage]); // TO DO
          }
        } else {
          col.formatterParams = { defaultValue: "" }
          col = cleanUpSingleTabulatorColumn(col);
        }

      });
      vectors.columns = tableColumns;
      vectors.placeholder = getTablePlaceholder("Add vectors", "vectors-add-row")

      //creating all tabulator tables
      this.tabulator = new Tabulator("#vectors", vectors);

      //Add a tabulator row on "Add Row" button click
      $("#vectors-add-row").click(function () {
        $(".add-vector-plus-top").removeClass("uk-hidden");
        obj.addNewRow();
      });
    }

    addNewRowTitleFormatter(cell, params){
        let btn = getTableButton(
            "Add vector",
            ["uk-text-normal", "uk-text-nowrap"],
            ["uk-button-icon", "transparent-bg", "add-vector-plus-top", "uk-hidden"],
            [
              "far",
              "fa-plus-circle",
              "fa-lg",
              "uk-margin-small-right"
            ],
            "left",
            "Add new vector"
        );
       
        btn.onclick = params.funkName;
        return btn;
    }

        
    editTitleFormatter(cell, params){
        var div = document.createElement("div");
        var p = document.createElement("p");
        var nameVal = clickedRow && clickedRow !== null ? clickedRow[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] : "";
        p.innerHTML = cell.getValue().replace("Vector ", nameVal);
        div.appendChild(p);
        return div;
    }

    maximizeVectorMappingWindow() {
        $('.vector-mapping-parent').css('width', '100%'); 
    }

    minimizeVectorMappingWindow() {
         $('.vector-mapping-parent').css('width', '48%'); 
    }


    hideVectorMapping(callback) {
        var obj = this;
        $('#GLAccountsModal').hide('slide', {direction:'right'},'fast', function(){
            obj.setState({
                showGLAccounts: false,
            }, function() {
                if(callback) {
                    callback();
                }
            });
        });
    }

    getVectorPeriodStatus(vectorMappingObj){
        var _this = this;
        var staged = false;
        Object.keys(JSON.parse("["+vectorMappingObj["status"]+"]")[0]).forEach(function eachKey(key){
            if (key === _this.props.selectedPeriod.value) {
                if (JSON.parse("["+vectorMappingObj["status"]+"]")[0][key] === 'STAGED' || JSON.parse("["+vectorMappingObj  ["status"]+"]")[0][key] === 'INVALID'){
                    staged = true;
                }
            }
        });
        return staged;
    }

    showVectorMapping(row) {
        var obj = this;
        var staged = false;
        $("#load_entity_count").addClass("disabled uk-disabled")
        var data = this.tabulator.getData();
        var isUsed = false;
        if(this.vectorMappingObj && parseBoolean(this.vectorMappingObj[VECTOR_MAPPING.FIELDS.IS_USED]) === true) {
            isUsed = true;
            obj.setInfoDialogOpen(true, this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] + MESSAGES.vector_map_edit_used_vector_err);
        }
        for (var e in data) {
            if (row && data[e]) {
                if (data[e]["vector_name"] === row["vector_name"] && data[e]["status"]) {
                    Object.keys(JSON.parse("["+data[e]["status"]+"]")[0]).forEach(function eachKey(key){
                        if (key === obj.props.selectedPeriod.value) {
                            if (JSON.parse("["+data[e]["status"]+"]")[0][key] === 'STAGED' || JSON.parse("["+data[e]["status"]+"]")[0][key] === 'INVALID'){
                                staged = true;
                            }
                        }
                    });
                } 
            }
        }


        //hide the Modal first and then reshow it in case it was opened for another vector
        if($('#GLAccountsModal').is(":visible")) {
            this.hideVectorMapping(this.showModal);
        } else {
            this.showModal(staged,isUsed);
        }
        this.minimizeVectorMappingWindow()
    }

    showModal(staged, isUsed) {      //inner function because it is only used here
        var obj = this;
        obj.setState({
            showGLAccounts: true,
            selectedRowStatus: staged,
            isAdvanced: obj.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE] && obj.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE].includes("result") ? true : false,
            isUsedInConfigure :isUsed
        });
        
        $('#GLAccountsModal').show('slide', {direction:'right'}, 1000);
    }

    generateStatus(status) {
        var obj = {};
        var periods = this.props.periods;
        for (var e in periods) {
            var key = periods[e].value;
            obj[key] = status;
        }    
        return obj;
    }

    addNewRow() {
        let tempState = {};
        if (this.state.newRow) {
            this.state.vectors.splice( this.state.vectors.length -1, 1);
            this.tabulator.replaceData(this.state.vectors); 
            tempState.newRow = false;
        }
        //Copy entity count and entity count computed flag from tabulator data
        let tabulatorData = this.tabulator.getData();
        this.state.vectors.forEach(v=> {
            if( tabulatorData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] === v[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME]).length ) {
                v[VECTOR_MAPPING.FIELDS.ENTITY_COUNT] = tabulatorData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] === v[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME])[0][VECTOR_MAPPING.FIELDS.ENTITY_COUNT]
                v[VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED] = tabulatorData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] === v[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME])[0][VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED]
            }
        })
        var vectors = [{vector_name:"",name:"-",key:'-',number:'-', is_default:"false", delete: "false", [VECTOR_MAPPING.FIELDS.ROW_STATUS]: ROW_STATUS.VALUES.NEW, period_name:this.props.selectedPeriod.value, disabled:"false"}];
        this.tabulator.setData(this.state.vectors.concat(vectors));
        tempState.vectors = this.state.vectors.concat(vectors);
        this.vectorMappingObj =  vectors[vectors.length - 1];
        this.mappedVectorIndex = this.tabulator.getData().length - 1;
        tempState.newRow = true
        tempState.changed = true
        this.showVectorMapping();
        this.tabulator.setFilter(VECTOR_MAPPING.FIELDS.DELETE, "in", ["false", ""]);
        this.setState(tempState);
    }

    onChangeTimePeriod(e) {
        var obj = this;
        this.setState({
            selectedPeriod: e.value
        }, function (){
            obj.viewMoreVectorDetailTable.clearFilter(true);
            obj.viewMoreVectorDetailTable.setSort(EXCLUDED_LINES_FIELDS.TOTAL_REVENUE, "desc");
            obj.getVectorDetail(null, e.value);
        });
    }

    setDefaultTimePeirod() {
        this.setState({
            selectedPeriod: this.state.selectedPeriod
        });
        this.setOpenViewMoreDialog(false);
    }

    getTranslatedIdentifier(identifier) {
        var obj = this;
        toggleLoader(true, 'getTranslatedIdentifier');

        var query = {
            action:"getTranslatedIdentifier",
            identifier: identifier
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + path,
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (data) {
                if (data && data !== "") {
                    //Copy entity count and entity count computed flag from tabulator data
                    let tabulatorData = obj.tabulator.getData();
                    obj.state.vectors.forEach(v=> {
                        v[VECTOR_MAPPING.FIELDS.ENTITY_COUNT] = tabulatorData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] === v[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME])[0][VECTOR_MAPPING.FIELDS.ENTITY_COUNT]
                        v[VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED] = tabulatorData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] === v[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME])[0][VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED]
                    })
                    obj.state.vectors[obj.mappedVectorIndex] = JSON.parse(data).row[0];
                    // Reset Entity Count for this vector your adding or editing
                    obj.state.vectors[obj.mappedVectorIndex][VECTOR_MAPPING.FIELDS.ENTITY_COUNT] = 0;
                    obj.state.vectors[obj.mappedVectorIndex][VECTOR_MAPPING.FIELDS.ENTITY_COUNT_COMPUTED] = false;
                    if (obj.state.newRow) {
                        obj.setState({ changed: true });
                        // obj.state.changed = true
                    }
                    obj.tabulator.replaceData(obj.state.vectors);
                    obj.state.newRow = false;
                    obj.forceUpdate();
                    $("#load_entity_count").removeClass("disabled uk-disabled")
                    obj.maximizeVectorMappingWindow()
                    if(obj.isLoadingEntityCount) {
                        obj.abortVectorsEntityCountRequest()
                    }
                    // obj.getVectors(obj.props.selectedPeriod);
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function() {
                toggleLoader(false, 'getTranslatedIdentifier');
            }
        });
    }

    compareObjects(newMappedVectorObj, originalMappedVectorObj) {
        if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_FIELD] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_FIELD]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE]){
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME]) {
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_DESCRIPTION] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_DESCRIPTION]){
            return false;
        } else if (newMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_TYPE] !== originalMappedVectorObj[VECTOR_MAPPING.FIELDS.VECTOR_TYPE]){
            return false;
        } else {
            return true;
        }
    }

    checkEmptyFields(vector) {
        if (!vector[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE][0] || vector[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE][0] === '' ||
            !vector[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE][0] || vector[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE][0] === '' ||
            !vector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE][0] || vector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE][0] === '') {
            return true;
        } else if (!isOdd(vector[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE]) || 
                    !isOdd(vector[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE]) || 
                    !isOdd(vector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE])){
            return true;
        } else {
            return false;
        }
    }

    
    setVectorMapping(newMappedVector) {
        let _this = this;
        if (!this.compareObjects(this.state.vectors[this.mappedVectorIndex], newMappedVector)){
            if (newMappedVector[VECTOR_MAPPING.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.NEW) {
                var obj = this.generateStatus('NOT_STAGED');
                newMappedVector["status"] = obj;
            } else {
                if (this.getVectorPeriodStatus(newMappedVector)) {
                    var obj = this.generateStatus('INVALID');
                    newMappedVector["status"] = obj;
                }else{
                    var obj = this.generateStatus('NOT_STAGED');
                    newMappedVector["status"] = obj;
                }
            }
            this.setState({
                changed: true
            })
        }
        
        let newMappedVectorKeyValueStr = typeof newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE] !== "string" ? JSON.stringify(newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE]) : newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE];
        if(newMappedVectorKeyValueStr.includes("result")){ // IF vector is advanced we need to set key_subtype_id,name_subtype_id & number_subtype_id to undefined because they are for basic vectors only
            newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID] = newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID] = newMappedVector[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID] = undefined;
        }
        
        newMappedVector[VECTOR_MAPPING.FIELDS.IS_USED] = false;
        this.state.vectors[this.mappedVectorIndex] = newMappedVector;   //change clicked row's data
        // this.tabulator.replaceData(this.state.vectors);     //replace table data
        this.tabulator.updateOrAddData([newMappedVector])
        this.hideVectorMapping();   //hide VectorMapping comp
        var vectorRow = [];
        vectorRow.push(newMappedVector);
        
        this.getTranslatedIdentifier(JSON.stringify(vectorRow));
    }

    getChartValues (data) {
        var obj = this;
        var stagedCount = 0;
        var notStagedCount = 0;
        var invalidCount = 0;
        if (data) {
            for (var e in data) {
                if(data[e][VECTOR_MAPPING.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.DELETED) {
                    continue;
                }
                var statusObj = tryParse(data[e][VECTOR_MAPPING.FIELDS.STATUS]);
                if (statusObj) {
                    Object.keys(statusObj).forEach(function eachKey(key){
                        if (key === obj.props.selectedPeriod.value) {
                            if (statusObj[key] === 'STAGED'){
                                stagedCount++;
                            } else if (statusObj[key] === 'NOT_STAGED'){
                                notStagedCount++;
                            } else{
                                invalidCount++;
                            }
                        }
                    });
                } else {
                    if(data[e][VECTOR_MAPPING.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.NEW) {
                        notStagedCount++;
                    } else if(data[e][VECTOR_MAPPING.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.EDITED){
                        invalidCount++;
                    }
                }
            }
        }
        return [stagedCount, invalidCount, notStagedCount];
    }

    cancelVector() {
        $("#load_entity_count").removeClass("disabled uk-disabled")
        if (this.state.newRow) {
            this.state.vectors.splice(this.mappedVectorIndex, 1);
            this.tabulator.replaceData(this.state.vectors);     //replace table data
        }
        this.setState({
            newRow: false
        },()=>{this.maximizeVectorMappingWindow()})
    }

    getVectorsDetail = () => {
        this.getVectorDetail(null,this.state.selectedPeriod,translateTabulatorFilter(this.viewMoreVectorDetailTable.getFilters(true)),translateTabulatorSorter(this.viewMoreVectorDetailTable.getSorters()));
    }
 
    deleteRowDialogActions = () => {
      return (
        <>
          <Button
            label={"Yes"}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={this.deleteRow}
          />
          <Button
            label={MESSAGES.modal.buttons.no}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.setOpenDeleteRowDialog(false)}
          />
        </>
      )
    }

    setOpenDeleteRowDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openDeleteRowDialog: isOpen
      })
    }

    setOpenNoAccessDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openNoAccessDialog: isOpen
      })
    }

  noAccessDialogActions = () => {
    return (
      <>
        <Button
          label={"Yes"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.createNewScenario()}
        />
        <Button
          label={MESSAGES.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenNoAccessDialog(false)}
        />
      </>
    )
  }

  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg
    })
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    )
  }

  viewMoreDialogActions = () => {
    return (
      <Button 
      id="close-btn"
      label={"Close"}
      variant={BUTTON_VARIANT.PRIMARY}
      size={SIZES.DEFAULT}
      type={BUTTON_TYPE.DEFAULT}
      onBtnClick={this.setDefaultTimePeirod}
  />
    )
  }

  setOpenViewMoreDialog = (isOpen) => {
    let _this = this;
   
    if(!isOpen) { // when closing the view more dialog, set this.table to undefined so it recreates a table when opening view more dialog
      _this.viewMoreSummaryVectorDetail = undefined;
      _this.viewMoreVectorDetailTable = undefined;
    }

    _this.setState({
      openViewMoreDialog: isOpen
    })
  }

  viewMoreDialogContent = () => {
    return (
      <>
        <div className="uk-background-default uk-border">
          <div className="mapping-header justify-content-between uk-padding-large-left-right">
            <div className="uk-display-flex align-items-center">
              <span className="fs-14 uk-text-bold">{(clickedRow ? clickedRow[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] : "") + " Details"}</span>
              <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
              <span className="fs-14 text-grey uk-margin-medium-left">{"Entity Count: " + this.state.entityCount}</span>
            </div>
            <div className="uk-display-flex align-items-center">
              <Button
                id="apply"
                label={"Apply Filter"}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className={"uk-margin-right"}
                onBtnClick={this.getVectorsDetail}
              />
              <label className="uk-margin-default-right">Period</label>
              <DropDown
                id="select-periods"
                className="width-150 input__dropdown"
                name="periods"
                placeholder="Period"
                value={findOptionByKey(this.props.periods || [], this.state.selectedPeriod && this.state.selectedPeriod.value ? this.state.selectedPeriod.value : this.state.selectedPeriod)}
                options={this.props.periods || []}
                onChange={this.onChangeTimePeriod}
                type={DROPDOWN_TYPE.INPUT}
              />
            </div>
          </div>
          <div id="viewMoreVectorDetail" />
        </div>
        <div className="uk-margin-top uk-border">
          <div className="mapping-header">
            <span className="fs-14 uk-text-bold">Totals</span>
          </div>
          <div id="viewMoreSummaryVectorDetail" />
        </div>
      </>
    )
  }

    render() {        

        return (
            <div style={{height:"100%"}}>
                <div id="vector-mapping-parent" className="uk-display-inline-flex uk-width-1-1" style={{maxHeight:"100%", justifyContent:"space-between"}}>
                    <div className="vector-mapping-parent">
                        <div className="mapping-header">
                            <span className="uk-text-bold uk-text-xmedium">Vectors Mapping</span>
                            <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
                        </div>
                        <div className="profitStackMapping vector_mapping_table" style={{height:"95%"}}>
                            <div id="vectors" ref="vectors" />
                        </div>
                    </div>   
                    <div id="GLAccountsModal" className="GL-accounts-modal" style={{position:"relative"}}>
                        {this.state.showGLAccounts ?
                            <React.Fragment>
                                <Loader fullContainer={false} />
                                <IdentifierMapping 
                                    isAdvanced = {this.state.isAdvanced} dataColumns = {this.props.dataColumns}
                                    scenarioId={this.props.scenarioId} hideGLAccounts={this.hideVectorMapping}
                                    stagingReport={STAGING_SECTIONS.VECTOR_MAPPING} isUsedInConfigure={this.state.isUsedInConfigure}
                                    setVectorMapping={this.setVectorMapping} vectorMappingObj={this.vectorMappingObj} connectors={this.state.connectors}
                                    cancelVector={this.cancelVector} newRow={this.state.newRow} 
                                    selectedRowStatus={this.state.selectedRowStatus}
                                    vectors={this.state.vectors}
                                    disableHeaderButtons={this.props.disableHeaderButtons}
                                />            
                            </React.Fragment>
                            : ""}
                    </div>
                </div>           
                <Modal
                  id={"view-more-vector-details-dialog"}
                  openDialog={this.state.openViewMoreDialog}
                  bodyContent={this.viewMoreDialogContent}
                  dialogActions={this.viewMoreDialogActions}
                  closeClick={this.setDefaultTimePeirod}
                  size={DIALOG_SIZE.XLARGE}
                />
                <Modal
                  id={"delete-row-used-in-calculated-dialog"}
                  openDialog={this.state.openDeleteRowUsedDialog}
                  bodyContent={this.deleteRowUsedDialogContent}
                  dialogActions={this.deleteRowUsedDialogActions}
                  closeClick={() => this.setOpenDeleteRowUsedDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"delete-row-dialog"}
                  openDialog={this.state.openDeleteRowDialog}
                  bodyContent={() => <h4>{lang.calc_cols.delete_confirmation + this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME]}  ?</h4>}
                  dialogActions={this.deleteRowDialogActions}
                  closeClick={() => this.setOpenDeleteRowDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />

                <Modal
                  id={"no-access-dialog"}
                  openDialog={this.state.openNoAccessDialog}
                  bodyContent={() => <h4>{MESSAGES.new_scenario_message}</h4>}
                  dialogActions={this.noAccessDialogActions}
                  closeClick={() => this.setOpenNoAccessDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </div>  
        );
    }
}

export default VectorMapping;