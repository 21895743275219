import React, { Component } from 'react';
import { getTranslationFile, copyObjectValues, findOptionByKeyValue, excludeOptions, parseBoolean } from '../../class/utils';
import { FormatTypes, PS_MAPPING, ROW_NUMBER, RAND, costtype, STAGING_SECTIONS ,ALL_WIDGETS, DROPDOWN_TYPE, DIALOG_SIZE, BUTTON_VARIANT, SIZES, BUTTON_TYPE, GLACCOUNTS_FIELDS} from '../../class/constants';
import {addRowNumberToOptions, hidePopup, replaceSpecialCharacters} from '../../class/jqueries';
import { formatValString } from '../../class/format';
import { RoundSlider, CustomSelect } from '../../form/elements.js';
import { findIndexOfValue, prune, addElements, addElement, getEmbeddedChild, updateAllElements,getEmbeddedChildren, addChildByParentId } from '../../class/array';
import { getNumericValue } from '../../class/number';
import Popup from 'react-popup';
import { getLeafNodes } from '../../class/common';
import Container from '../../components/manageColumns/Container';
import { convertPxToViewport } from '../../class/formatting';
import Input from '../../newComponents/Input';
import DropDown from '../../newComponents/DropDown';
import Modal from '../../newComponents/Modal';
import Button from '../../newComponents/Button';

const $ = require('jquery');
const lang = getTranslationFile();

const _pslName = PS_MAPPING.EXCEPTION_FIELDS.PSL_NAME;
const _entity = PS_MAPPING.EXCEPTION_FIELDS.ENTITY;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _column = PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _name = PS_MAPPING.FIELDS.NAME;
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _disabled = "disabled";
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _classList = "hideMe disableMe"
const _checked = "checked";
const _label = "label";
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _level = "level"
const _className = "className";
const _matchedSuffix = lang.pss_map_exception.suffixes.matched;
const _transaction = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
const _ancillary = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
// const _variance = lang.pss_map_exception.suffixes.unmatched;
const _variance = lang.pss_map_exception.suffixes.variance;
class PSLExceptionMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchedLeaves: [],
            unmatchedLeaves: [],
            options: [],
            treeOptions: []
            
        };
        this.treeOptions=[];
        this.splitPercentage = this.splitPercentage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetPSOptions = this.resetPSOptions.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    

    onChange = (rowNumber,currentNode) => {
       
        let treeOptions = this.state.treeOptions &&   this.state.treeOptions > 0 ? this.state.treeOptions : this.treeOptions;
        let selectedTreeOption = treeOptions[rowNumber];
        // selectedTreeOption = updateAllElements(selectedTreeOption,_children,_checked, false);
        let obj = getEmbeddedChild(selectedTreeOption,_children,_id,currentNode[_id]);
        let checkedItems = getEmbeddedChildren(selectedTreeOption,_children,_checked,true);
        if(checkedItems!== null && checkedItems.length > 0){
            for(let e in checkedItems){
                checkedItems[e][_checked] = false; 
            }
        }
        obj[_checked] = currentNode[_checked];
        if(!obj[_checked]){
            delete obj[_checked];
            obj[_disabled] = false;
        }
        currentNode[ROW_NUMBER] = rowNumber;
        this.setState({
            treeOptions : treeOptions,
            isChanged:true,
        },function(){
            if(currentNode[_checked])
                this.props.handleChangeAttribute(_pslName,currentNode);
        })
    }

    extractRowNumberToDelete(prevPropsExceptionData,propsExceptionData){
        for(let e in propsExceptionData){
            if(prevPropsExceptionData[e][_pslName] !== propsExceptionData[e][_pslName]){
                return Number(e);
            }
        }
        return prevPropsExceptionData.length - 1;
    }
    
    componentDidUpdate(prevProps){
       let tempState = {};
       if(this.state.treeOptions.length > 0 && this.state.options && prevProps.exceptionData && prevProps.exceptionData.length < this.props.exceptionData.length){
            let treeOptions = copyObjectValues(this.state.treeOptions);
            let index = !this.props.isNone ? this.props.exceptionData.length - 2 : this.props.exceptionData.length - 1;
            let leadingPssId = this.props.leadingPssID;
            if(treeOptions[index]){
                let leadingline = getEmbeddedChild(treeOptions[index],_children,_id,leadingPssId);
                if(leadingline && leadingline!==null){
                    leadingline[_disabled] = true;
                }
            }
            treeOptions.splice(index,0,this.createTreeStructure(this.state.options[index],this.props.exceptionData[index],index));
            updateAllElements(treeOptions[index],_children,_checked,false);
            tempState.treeOptions = treeOptions;
            this.setState(tempState);
       }
       if(this.state.treeOptions.length > 0 && prevProps.exceptionData && prevProps.exceptionData.length > this.props.exceptionData.length){
            let treeOptions = copyObjectValues(this.state.treeOptions);
            let index = this.extractRowNumberToDelete(prevProps.exceptionData,this.props.exceptionData);
            treeOptions.splice(index,1);
            tempState.treeOptions = treeOptions;
            this.setState(tempState);
       }
       if(this.state.treeOptions && this.state.isChanged) {
            let mappedLines = this.props.mappedLines;
            let treeOptions = copyObjectValues(this.state.treeOptions)
            this.updatePSLStatus(mappedLines,treeOptions);
            tempState.treeOptions = treeOptions;
            tempState.isChanged = false;
            this.setState(tempState);
       }
    }

    getAllCheckedElements(treeOptions){
        let result = [];
        for(let e in treeOptions){
            let obj = getEmbeddedChild(treeOptions[e],_children,_checked,true);
            if(obj !== null){
                result.push(obj);
            }
        }
        return result;
    }

    getAllDisabledElements(treeOptions){
        let result = [];
        for(let e in treeOptions){
            let elements = getEmbeddedChildren(treeOptions[e],_children,_disabled,true);
            if(elements.length !== 0){
               
                result = result.concat(elements);
            }
        }
        const filteredArr = result.reduce((acc, current) => {
            const x = acc.find(item => item.pssId === current.pssId);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        return filteredArr;
    }

    static getDerivedStateFromProps(props, state) {
        let tempState = {};

        //setting up list of psl options
        if(props.exceptionPSLOptions && !state.options.length) {
            tempState.matchedLeaves = [];
            tempState.unmatchedLeaves = [];

            tempState.options = [];
            tempState.treeOptions = [];
            let allPSLNames = [];
            props.exceptionData.map(row=>{
                if(row[_name]) {
                    let name = row[_name].replace(_variance,"").replace(_matchedSuffix,"");
                    allPSLNames.push(name);
                } else {
                    allPSLNames.push("");   //newly added row with no ps line selected
                }
            });
            let leadingLine = props.exceptionData.filter(e=>e[_id] === props.leadingPssID)[0];
            // let leadingReturnName = leadingLine ? leadingLine[_returnName].replace(replaceSpecialCharacters(_variance),"").replace(replaceSpecialCharacters(_matchedSuffix),"") : "";
            let leadingName = leadingLine ? leadingLine[_name].replace(_variance,"").replace(_matchedSuffix,"") : "";
            
            let unmatchedLine = findOptionByKeyValue(props.exceptionData, _isMatched, false);
            // let hasUnmatchedOption = unmatchedLine ? !!findOptionByKeyValue(props.exceptionPSLOptions, _name, unmatchedLine[_name]) : false;
            let unmatchedIndex = unmatchedLine ? findIndexOfValue(props.exceptionPSLOptions, _name, unmatchedLine[_name]) : -1;
        
            props.exceptionData.map((row, i)=>{
                let includedOptions = [];
                let isRowMatched = row[_isMatched];

                props.exceptionPSLOptions.map((opt, k)=>{
                    opt = copyObjectValues(opt);
                    let retNameIndex = allPSLNames.indexOf(opt[_name].replace(_variance,"").replace(_matchedSuffix,""));
                    let isCopyOfLeadingLine = opt[_name] && leadingName && opt[_name].includes(leadingName);
                    let isOptMatched = parseBoolean(opt[_isMatched]);
                    if(retNameIndex === -1 || (retNameIndex > -1 && i === retNameIndex && (isOptMatched !== undefined ? isOptMatched === isRowMatched : true)) 
                        || (isRowMatched === false && isCopyOfLeadingLine && (unmatchedIndex > 0 ? k === unmatchedIndex : true))) {  //isOptMatched !== undefined shows if first mapping or edit mapping before save
                        //if this return name is not selected in any dropdown, or selected in the 
                        //dropdown for which we are currently building the options
                        opt[_isMatched] = props.isOther ? isRowMatched : opt[_isMatched];
                        //if selected, or unmatched version of leading line, use row data, else add opt as is
                        let useRow = i === retNameIndex || retNameIndex > -1 && isRowMatched === false && isCopyOfLeadingLine;
                        let tempObj = useRow ? row : opt;

                        opt.label = tempObj[_name];
                        opt[_name] = opt.label;     //filling name to find option in dropdown value in ExceptionMappingRow render
                        opt.value = tempObj[_returnName] ? tempObj[_returnName] : replaceSpecialCharacters(tempObj[_name])?.toLowerCase();
                        opt[_returnName] = opt.value;
                        includedOptions.push(opt);
                    }
                });
                
                tempState.options[i] = includedOptions;
                if(state.treeOptions[i]){
                    tempState.treeOptions[i] = state.treeOptions[i];
                }
               
            });
        }

        return tempState;
    }

    splitPercentage() {
            this.props.splitPercentage();
    }


    handleChange(attr, option) {
        let amount = 0;
        if(_percentage === attr) {
            amount = 100;
        } else {
            this.props.exceptionData.map(row => amount += row[_isMatched] !== false ? row[_amount] : 0);
        }
        if(Math.abs(parseFloat(getNumericValue($(option.currentTarget).val() || 0))) > Math.abs(amount)) {
            option.target.value = option.target.defaultValue;
            this.setInfoDialogOpen(true,lang.inputted_amount_limit);
            return false;
        }

        if(attr === _calcCol) {
            this.resetCalculatedColOptions();
        } else {
            this.resetPSOptions();
        }
        this.props.handleChangeAttribute(attr, option);
    }
    
    /**
     * This function is called from ExceptionDriver to empty the list of options,
     * so they can be updated in getDerivedStateFromProps(), usually when updating name
     * of matched and unmatched
     */
    resetPSOptions() {
        this.setState({
            matchedLeaves: [],
            unmatchedLeaves: [],
            options: []
        });
    }

    createTreeStructure = (options,row,index) => {
        
        let originalProfitStackFields = copyObjectValues(this.props.profitStackFields);
        if(!options)
            return originalProfitStackFields;
        originalProfitStackFields = addElements(originalProfitStackFields,_children, _label,_name);
        
        this.removeParentRadioButton(originalProfitStackFields);
        let onEditObj = {};
        
        originalProfitStackFields = originalProfitStackFields.filter(e=>![costtype.attribute,costtype.calculated].includes(e[_costType]))
        
        onEditObj = getEmbeddedChild(originalProfitStackFields,_children,_id,row[_id]);
        
        
        if(onEditObj!==null){
            onEditObj[_checked] = true;
            
        }
        
        if(row[_isMatched] || this.props.isNone){
            prune(originalProfitStackFields,_children,_name,_variance);
        } else if(row[_isMatched] === false){
            prune(originalProfitStackFields,_children,_name,_variance);
            var leadingLine = getEmbeddedChild(originalProfitStackFields,_children,_id,this.props.leadingPssID);
            let unMatchedObj = {
                ...leadingLine,
                [PS_MAPPING.FIELDS.COST_KEY]:"",
                [PS_MAPPING.FIELDS.NAME_IN_FACT]:"",
                [PS_MAPPING.FIELDS.FORMULA_IN_FACT]:"",
                [_id]:"",
                "id":"",
                [_label]: row[_pslName] === _variance ? leadingLine.name !== ''  ? leadingLine.name + _variance : $('#name').val() + _variance : row[_pslName],
                [_name]: row[_pslName] === _variance ? leadingLine.name !== ''  ? leadingLine.name + _variance : $('#name').val() + _variance : row[_pslName],
                [_returnName]:row[_returnName]
            };
            if(unMatchedObj[_level] === 1){
                originalProfitStackFields.push(unMatchedObj);
            } else {
                addChildByParentId(originalProfitStackFields,PS_MAPPING.FIELDS.COST_KEY,unMatchedObj[PS_MAPPING.FIELDS.PARENT_COST_KEY],[unMatchedObj]);

            }
            let embededdedUnmatchedObj = getEmbeddedChild(originalProfitStackFields,_children,_name,unMatchedObj[_name]);
            if(embededdedUnmatchedObj!== null){
                embededdedUnmatchedObj[_checked] =  true;
            }
            
        }
        let leafNodesNames = getLeafNodes(originalProfitStackFields).map(e=>e[_name]);
        let mandatoryNames = options.map(e => e[_name].replace(_matchedSuffix,""));
        let namesToBeExcluded = leafNodesNames.filter(x => !mandatoryNames.includes(x));
        
        for(let e in namesToBeExcluded){
            let obj = getEmbeddedChild(originalProfitStackFields,_children,_name,namesToBeExcluded[e]);
            let checkline = this.props.mappedLines.filter(x=> x.pssId === obj.pssId) 
             //Removed some of the conditions because of wrong logic
            if((checkline && checkline[0] && (checkline[0].pssLeadingCostKey === checkline[0].costKey) && checkline[0].fieldsCombination.length === 0 && !checkline[0].is_matched)){
                obj[_disabled] = false;
            }else{
                obj[_disabled] = true; 
            }
           
        }
        if(this.treeOptions && this.treeOptions.length > 0 && index && this.treeOptions[index] && this.treeOptions[index].length > 0) {
            let mappedLines = this.props.mappedLines;
            this.updatePSLStatus(mappedLines,this.treeOptions);
            this.copyElements(originalProfitStackFields,this.treeOptions[index])
        } 
        
        return originalProfitStackFields
    }

    updatePSLStatus = (mappedLines,treeOptions) => {
        let filteredMappedLines = mappedLines.filter(e=>e[PS_MAPPING.FIELDS.MAPPING_EXCEPTION]!== "NONE")
        let checkedElementsIds = [];
        let disabledElementsIds = [];
        let removeDisabled = [];
        let checkedElements =  this.getAllCheckedElements(treeOptions);
        if(checkedElements.length > 0){
            checkedElementsIds = checkedElements.map(e=>e[_id]);
            if(mappedLines){
                let mappedLinesIds = filteredMappedLines.map(e=>e[_id]);
                checkedElementsIds = checkedElementsIds.concat(mappedLinesIds);
                checkedElementsIds = [...new Set(checkedElementsIds)];
            }
        } 
        let disabledElements = this.getAllDisabledElements(treeOptions);
        let filterDisabledElements = disabledElements.filter(e=>e[_id]!==this.props.leadingPssID);
        disabledElementsIds = filterDisabledElements.map(e=>e[_id]);
        removeDisabled = checkedElements.length > 0 ? disabledElementsIds.filter(elementId => elementId && !checkedElementsIds.includes(elementId))  :disabledElementsIds;
        for(var e = 1; e < treeOptions.length; e++) {
            for(var i in removeDisabled){
                let id = removeDisabled[i];
                let obj = getEmbeddedChild(treeOptions[e],_children,_id,id);
                obj[_disabled] = false;
            }
            for(var i in checkedElementsIds){
                let id = checkedElementsIds[i];
                let obj = getEmbeddedChild(treeOptions[e],_children,_id,id);
                if(obj && !obj[_checked]){
                    obj[_disabled] = true;
                }
            }
           
        }
    }

     removeParentRadioButton = (originalProfitStackFields) => {
        for(let e in originalProfitStackFields){
            let obj = originalProfitStackFields[e];
            if(obj.children){
                obj[_className] = _classList;
                this.removeParentRadioButton(obj.children);
            }
        }
    }

    copyElements = (originalProfitStackFields , tempFields )=> {
        for(let e in tempFields){
            let disabled = tempFields[e].disabled || false;
            let checked = tempFields[e].checked || false;
            let obj = getEmbeddedChild(originalProfitStackFields,_children,_id,tempFields[e][_id]);
            if(obj && obj !== null) {
                obj[_disabled]= disabled;
                obj[_checked] = checked;
                if( obj[_disabled] && obj[_checked]){
                    obj[_disabled] = false;
                }
            }
            if(tempFields[e][_children] && tempFields[e][_children].length > 0){
                this.copyElements(originalProfitStackFields, tempFields[e][_children])
            } 
        }
    }
    
    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }
    // Do not show Entity label when fully or partailly mapped to transaction
    isOnlyTransaction = () => {
        let _this = this
        let linesMappedToTransaction = _this.props.exceptionData?.filter(e=> e.mappingException !== _ancillary && e[_isMatched])?.length;
        return [linesMappedToTransaction].includes( _this.props.exceptionData?.length -1 ); //Exclude variance line 

    }

    render() {
        var _this = this;
        var leadingLine = this.props.exceptionData.filter(row=>row[_id] === _this.props.leadingPssID)[0];
        let isSplitEnabled = this.props.isOther ? leadingLine && leadingLine[_column] ? true : false : true;
        let numberOfMatchedExceptions = this.props.exceptionData.filter(e=>e[_isMatched] === true).length;
        var exceptionData = [];
        var showEntityInput = (this.props.isMultipleOther && numberOfMatchedExceptions > 1 && !_this.isOnlyTransaction())
       
        var comp = "";
        _this.props.exceptionData.map((row, i)=>{
            let isTransaction = row.mappingException === _transaction;
            if(row[_name] === _variance){
                row[_name] = this.props.exceptionData.filter(e=>e.pssId === row.pssLeadingId).length > 0 ? this.props.exceptionData.filter(e=>e.pssId === row.pssLeadingId)[0][_name] + _variance : row[_name];
                row[_label] = row[_name];
            }
            _this.treeOptions[i] =  _this.state.treeOptions[i] ? _this.state.treeOptions[i]: _this.createTreeStructure(_this.state.options[i],row,i);
            if(_this.props.isNone || (row[_isMatched] && (row[_column] || _this.props.isSplitPerc || isTransaction || numberOfMatchedExceptions === 1 || !exceptionData.length))) {
               
                exceptionData.push(
                    <ExceptionMappingRow row={row} key={"exc_mapping_row_" + row[RAND]}
                        exceptionPSLOptions={_this.state.options[i]}
                        exceptionTreePSLOptions={_this.treeOptions[i]}
                        rowNumber={i}
                        handleChange={_this.handleChange}
                        isNone={_this.props.isNone}
                        isOther={_this.props.isOther}
                        isSplitPerc={_this.props.isSplitPerc}
                        isMultipleOther={_this.props.isMultipleOther}
                        mappedPssID={_this.props.mappedPssID}
                        deleteRow={_this.props.deleteRow}
                        numberOfMatchedExceptions={numberOfMatchedExceptions}
                        isSplitEnabled={isSplitEnabled}
                        onChange = {_this.onChange}
                        stagingReport = {_this.props.stagingReport}
                    />
                );
            } else if(row[_isMatched] === false){
                comp = (
                    <React.Fragment key={"exc_mapping_row_" + row[RAND]}>
                        <hr className="uk-hr"></hr>
                        <ExceptionMappingRow row={row}
                            exceptionPSLOptions={_this.state.options[i]}
                            exceptionTreePSLOptions={_this.treeOptions[i]}
                            rowNumber={i}
                            handleChange={_this.handleChange}
                            isNone={_this.props.isNone}
                            isOther={_this.props.isOther}
                            isSplitPerc={_this.props.isSplitPerc}
                            isMultipleOther={_this.props.isMultipleOther}
                            mappedPssID={_this.props.mappedPssID}
                            deleteRow={_this.props.deleteRow}
                            numberOfMatchedExceptions={numberOfMatchedExceptions}
                            isSplitEnabled={isSplitEnabled}
                            onChange = {_this.onChange}
                            stagingReport = {_this.props.stagingReport}
                        />
                    </React.Fragment>
                );
            }
        });
        exceptionData.push(comp);   //add unmatched in the end

        return (
            <React.Fragment>
                {!this.props.isMultipleOther ?
                    <div className="uk-margin-default-top">
                        <button className={"uk-button-icon uk-margin-xmedium-bottom"+(isSplitEnabled?"":" disabled")} onClick={this.splitPercentage} disabled={!isSplitEnabled}>
                            <i className="fa-lg far fa-percentage uk-margin-small-right" aria-hidden="true"></i>{lang.pss_map_exception.button.split_perc}
                        </button>
                        <i className="fa-lg fas fa-info-circle text-blue uk-margin-xsmall-left" uk-tooltip={lang.profit_stack_split_perc}></i>
                    </div>
                :""}

                {exceptionData.length ?
                    <div id="exception-titles" className="uk-display-flex justify-content-between uk-margin-xmedium-top">
                        <div className="uk-display-flex">
                            <div className="uk-display-inline-flex form-min-width uk-flex-middle">
                                <span className="fs-14">{lang.pss_map_exception.titles.psl_name}</span>
                            </div>
                        </div>
                        {showEntityInput ?
                                <div className="fs-14 uk-display-inline-flex uk-margin-small-left">
                                    <span className="fs-14" style={{marginRight:"6.2vw"}}>{lang.pss_map_exception.titles.entity}</span>
                                </div>
                            :""}
                        <div className="uk-display-flex">
                            <div className="uk-display-inline-flex width-100">
                                <span className="fs-14">{lang.pss_map_exception.titles.percentage}</span>
                            </div>
                            <div className="uk-display-inline-flex uk-margin-small-left width-125">
                                <span className="fs-14">{lang.pss_map_exception.titles.parsed_amount}</span>
                            </div>
                            <div className={"trash-empty-space"} />
                        </div>
                    </div>
                :""}

                <div id="exception-data-rows-frag" className="uk-margin-large-bottom">
                    { exceptionData }
                </div>
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </React.Fragment>
        );
    }
}

class ExceptionMappingRow extends Component {
    constructor(props) {
        super(props);

        this.updatePercentage = this.updatePercentage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.sliderRef) {
            this.sliderRef.setValue(this.props.row[_percentage]);
        }
    }

    updatePercentage(newPerc, fromMount) {
        let option = {
            value: newPerc,
            [ROW_NUMBER]: this.props.rowNumber
        }

        if(!fromMount) {
            this.props.handleChange(_percentage, option);
        }
    }

    render() {
        var _this = this;
        var row = this.props.row;
        var rowNumber = this.props.rowNumber;
        var name = row[_name];
        var percentage = row[_percentage];
        var amount = row[_amount];
        var mappingException = row.mappingException;
        var isMatched = row[_isMatched];
        var entity = row[_entity];
        var isTransaction = mappingException === _transaction;
        var classList = "uk-margin-small-left";
        var isMappedRow = row[PS_MAPPING.FIELDS.PSS_ID]!==undefined && row[PS_MAPPING.FIELDS.PSS_ID]!=="" && row[PS_MAPPING.FIELDS.PSS_ID] === this.props.mappedPssID;
        var isInputReadOnly = !this.props.isSplitPerc || isMappedRow || isMatched === false || !this.props.isSplitEnabled;  
        var showEntityInput = (isTransaction && isMatched) || this.props.isMultipleOther && isMatched && this.props.numberOfMatchedExceptions > 1;
        var styleForEntityInput = !isTransaction && isMappedRow && this.props.isMultipleOther? {marginLeft:"6.1vw"} : {};
        var receiveMsg = isMatched === false ? lang.pss_map_exception.text.will_receive_unmatched : isTransaction ? lang.pss_map_exception.text.will_receive : lang.pss_map_exception.text.will_receive_remaining;
        var style = this.props.stagingReport  === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW ? {marginRight: convertPxToViewport(70)} : {marginRight: convertPxToViewport(70)};
        const optionLabel = ({ value, label }) => (
            <div title={label} className="uk-text-overflow-ellipsis">{label}</div>
        ); 
       
        return (
            <div>
                <div className="uk-display-flex justify-content-between">
                    <div className="uk-display-flex split-gl-minimum-width">
                        <div className="uk-margin-xsmall-top uk-margin-xsmall-bottom">
                            {/* show input when split and is first row, OR is ancillary with only 1 row matched, and 1 unmatched */}
                            {(!this.props.isSplitPerc && !this.props.isMultipleOther && isMatched) || isMappedRow ?
                                <Input className="form-control form-min-width" readOnly value={name}/>
                            : this.props.stagingReport  === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || this.props.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU ?
                            <Container texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                                data={this.props.exceptionTreePSLOptions} onChange={(option)=>this.props.onChange(rowNumber,option)} mode={"radioSelect"} className={"dropdown-tree radio-drop-down"}
                            /> 
                            :
                                <DropDown
                                    className="uk-display-inline-block width-150 uk-width-full input__dropdown"
                                    name="ancillary-columns"
                                    value={findOptionByKeyValue(this.props.exceptionPSLOptions, _name, name)}
                                    options={addRowNumberToOptions(this.props.exceptionPSLOptions, this.props.rowNumber)}
                                    onChange={(option)=>this.props.handleChange(_pslName, option)}
                                    formatOptionLabel={optionLabel}
                                    menuPlacement="top"
                                    type={DROPDOWN_TYPE.INPUT}
                                />
                            }
                        </div>
                        {showEntityInput  && !! entity && entity.replace(")","").replace("(","").trim() !== ""?
                            <div className={classList + " uk-margin-xsmall-top uk-margin-xsmall-bottom uk-flex-middle" + (isTransaction ? " visibility-none":"")}>
                                {!isTransaction &&
                                    <Input className="form-control width-100" title= {entity} readOnly value={entity} style = {styleForEntityInput}/>
                                }       
                                
                            </div>
                        :""}
                        
                        {this.props.isSplitPerc && !isMappedRow && isMatched !== false ?
                            <div id="timeline_slider" style={style} className={"uk-display-inline-flex uk-flex-middle uk-margin-small-left width-150" + (this.props.isSplitEnabled ? "":" disabled")}>
                                <RoundSlider ref={el=>this.sliderRef=el} setValueCallback={(val, fromMount)=>this.updatePercentage(val, fromMount)} init={{value: this.props.row[_percentage]}}/>
                            </div> 
                        
                            :
                             <div className={"uk-display-inline-flex uk-flex-middle uk-margin-xsmall-left width-"+ (showEntityInput ? "125" : "150")}>
                                <span className={"text-grey fs-12"}>{receiveMsg}</span>
                            </div>
                        }

                    </div>
                    <div className="uk-display-flex uk-flex-middle">
                        {/* set perc and amnt keys for rendering on value change without having to use handleChange */}
                        <div className="uk-display-inline-flex uk-margin-small-right uk-margin-small-left uk-margin-xsmall-top uk-margin-xsmall-bottom" key={"perc_"+ (percentage || this.props.rowNumber)}>
                            <Input className="form-control width-100" rownumber={this.props.rowNumber} readOnly={isInputReadOnly} defaultValue={formatValString(percentage, FormatTypes.PERCENTAGE)}
                            onBlur={isInputReadOnly ? ()=>{} : (e)=>this.props.handleChange(_percentage, e)}/>
                        </div>
                        <div className="fs-14 uk-display-inline-flex uk-margin-xsmall-top-bottom" key={"amnt_"+ (amount || this.props.rowNumber)}>
                            <Input className="form-control width-125" rownumber={this.props.rowNumber} readOnly={isInputReadOnly} defaultValue={formatValString(amount, FormatTypes.AMOUNT)}
                            onBlur={isInputReadOnly ? ()=>{} : (e)=>this.props.handleChange(_amount, e)}/>
                        </div>

                        {((this.props.isSplitPerc && isMatched !== false) || isMatched) && !isMappedRow ?
                            <div className="uk-display-inline-flex uk-margin-small-left uk-margin-small-right uk-button-icon transparent-bg " rownumber={this.props.rowNumber} onClick={this.props.deleteRow}>
                                <i className="fal fa-trash-alt fa-lg"></i>
                            </div>
                        :
                            <div className={"trash-empty-space"} />
                        }
                    </div>
                </div>
            </div>

        );
    }
}


export default PSLExceptionMapping;