import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { generate } from "shortid";
import Button from "../newComponents/Button";
import { lang } from "../language/messages_en";
import CheckableList from "./CheckableList";
import SearchBar from "./SearchBar";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../class/constants";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const SearchableDropdown = ({
  sectionsData,
  handleCheckboxChange,
  headerInfoText, // info under searchBar
  extraInfo, // to show number next to name in checkable list
  primaryButtonProps, // footer button props
  searchCriterias, // ie: either search based on label, value or label and value (should be sent as an array)
  isApplyBtnDisabled,
  selectAllText,
  headerInfoTextSize,
  selectAllClick,
  checkAll,
  clearClick,
  basicFilterCountLimit,
  searchPlaceholder,
  isSelectAllDisabled,
  isClearBtnDisabled,
  scroll,
  limitReached,
  filterFinal,
  filterObject,
  isSearchable=true,
  hasHeader=true,
  removeClearBtn=false
}, ref) => {
  useImperativeHandle(ref, () => ({
    setLimitReached: (isReached) => {
      checkableListRef.current?.setLimitReached(isReached);
     }
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const isLimitReached = useRef(limitReached);
  const checkableListRef = useRef();

  /**
   * Setting limitReached in a ref instead of state to avoid re-rendering the component to avoid having scrolling issues.
   */
  const setLimitReached = (isReached) => {
    isLimitReached.current = isReached;
  }

  /**
   * Returns filtered items based on search term and search criterias
   * @param {*} items
   * @param {*} searchTerm
   * @returns
   */
  function filterItemsBasedOnSearch(items, searchTerm) {
    if (searchTerm === "") {
      return items;
    }

    return items.filter((item) => {
      if (Array.isArray(searchCriterias)) {
        return searchCriterias.some((criteria) =>
          item[criteria].toLowerCase().includes(searchTerm.toLowerCase()) && !item["isGroupTitle"]
        );
      }
    });
  }

  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100
  return (
    <>
      {isSearchable && (
        <SearchBar
          onInputChange={setSearchTerm}
          placeholderText={searchPlaceholder}
        />
      )}
    
    {hasHeader &&
      <Box /*classes={{ root:"select-clear-container" }}*/ sx={{ flexGrow: 1}}>
        <Grid
          container
          spacing={2}
          className={"number-text-container"}
        >
          <Grid item xs={selectAllText ? 6 : 9} className={"number-text-selection"} id={selectAllText ? "filter-selected-applied" : "filter-selected"}>
            <Typography
              sx={{ fontSize: headerInfoTextSize, fontWeight: "bold", fontFamily: "'Open Sans', Arial, sans-serif", letterSpacing: "normal", }}
            >
              {headerInfoText}
            </Typography>
          </Grid>

          {selectAllText ? (
            <>
              <Grid item xs={3} className={"clear-select-button select-all" + (zoom > 109 ? " select-all-no-padding" : "")} >
              <Button 
                  label={lang.select_all_basic_filter}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.SMALL}
                  type={BUTTON_TYPE.DEFAULT}
                  disabled={isSelectAllDisabled}
                  onBtnClick={selectAllClick}
                />  

              </Grid>
              <Grid item xs={3} className="clear-select-button clear-button clear-basic-filter">
                <Button 
                  label={lang.modal.buttons.clear}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.SMALL}
                  type={BUTTON_TYPE.DEFAULT}
                  disabled={isApplyBtnDisabled}
                  onBtnClick={clearClick}
                />
              </Grid>
            </>
          ) : (
            <>
              {!removeClearBtn &&
                <Grid item xs={3} >
                  <Button 
                    label={lang.modal.buttons.clear}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.SMALL}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={isClearBtnDisabled}
                    onBtnClick={clearClick}
                  />   
                </Grid>
              }
            </>
          )}
        </Grid>
      </Box>
      }
      {sectionsData.map((items) => {
        let sectionDiv = items.sectionTitle && (
          <Typography
            sx={{
              fontSize: "0.8vw",
              textTransform: "uppercase",
              fontWeight: "bold",
              lineHeight: 3,
              fontFamily: "'Open Sans', Arial, sans-serif",
              letterSpacing: "normal",
              pl: 2,
            }}
          >
            {items.sectionTitle}
          </Typography>
        );

        return (
          <div key={generate()} className="list-div">
            {sectionDiv}
            <Divider />
            <CheckableList
              ref={checkableListRef}
              itemsList={filterItemsBasedOnSearch(items.itemsList, searchTerm)}
              extraInfo={extraInfo}
              handleCheckboxChange={handleCheckboxChange}
              checkAll={checkAll}
              basicFilterCountLimit={basicFilterCountLimit}
              limitReached={isLimitReached.current}
              scrollProps={scroll}
              filterFinal={filterFinal}
              filterObject={filterObject}
              setLimitReached={setLimitReached}
            />
          </div>
        );
      })}
      <Divider />
      <div className={"apply-btn"} style={{padding: "0.2vw"}}>
      <Button 
        id={primaryButtonProps.id}
        label={primaryButtonProps.text}
        variant={primaryButtonProps.variant}
        size={primaryButtonProps?.size || SIZES.LARGE}
        type={BUTTON_TYPE.DEFAULT}
        className={primaryButtonProps.className}
        disabled={isApplyBtnDisabled}
        style={primaryButtonProps.style}
        onBtnClick={primaryButtonProps.clickEvent}
        uk-toggle={primaryButtonProps.ukToggle}
        leftIcon={primaryButtonProps.icon}
      />
      </div>
      
    </>
  );
};

export default forwardRef(SearchableDropdown);
