import { FormatTypes, TABULATOR_BUTTON } from "../class/constants";
import {lang} from "../language/messages_en";

/**
 * Returns a html button element to be used based on type.
 * @param {String} type It could be edged, rounded or icon button
 * @param {*} classList Additional classes to be added
 * @returns
 */
const renderButton = (type = TABULATOR_BUTTON.EDGED, classList) => {
  let btn = document.createElement("button");
  classList?.forEach((className) => btn.classList.add(className));
  btn.classList.add(`table-btn-${type}`);
  btn.setAttribute("type", "button");
  return btn;
};

/**
 * Returns a html button element to be used in tabulator with/witout icon.
 * Example: <button><i className="icon-name" /><span>btn label</span></button>
 * @param {String} btnLabel button label
 * @param {Array} btnLabelClasses classes for the button span
 * @param {Array} btnClasses classes for the button
 * @param {Array} iconClasses the classes for the icon
 * @param {String} iconPosition "left" or "right"
 * @param {String} btnTitle tooltip for the button
 * @returns
 */
const getTableButton = (
  btnLabel,
  btnLabelClasses,
  btnClasses,
  iconClasses,
  iconPosition,
  btnTitle = "",
  labelId = "",
  variant = TABULATOR_BUTTON.EDGED
) => {
  let btn = renderButton(variant, btnClasses);
  btn.title = btnTitle;

  let span = document.createElement("span");
  btnLabelClasses?.forEach((className) => span.classList.add(className));
  span.innerHTML = btnLabel;
  span.id = labelId;

  let icon = document.createElement("i");
  iconClasses?.forEach((className) => icon.classList.add(className));
  if (iconPosition === "left") {
    btn.appendChild(icon);
  }

  btn.appendChild(span);

  if (iconPosition === "right") {
    btn.appendChild(icon);
  }
  return btn;
};

/**
 * Returns an icon.
 * Example: <i className="icon-name uk-cursor-pointer" />
 * @param {Array} iconClasses
 * @param {String} iconId
 * @returns
 */
const getTableIcon = (iconClasses) => {
  let icon = document.createElement("i");
  iconClasses?.forEach((className) => icon.classList.add(className));
  icon.classList.add("uk-cursor-pointer", `table-btn-${TABULATOR_BUTTON.ICON}`);
  return icon;
};

/**
 * Returns an icon button.
 * Example: <button><i className="icon-name" /></button
 * @param {Array} iconClasses
 * @param {Array} btnCLasses
 * @param {*} iconId
 * @returns
 */
const getTableIconButton = (iconClasses, btnCLasses, iconId) => {
  let btn = renderButton(TABULATOR_BUTTON.ICON, btnCLasses);
  let icon = document.createElement("i");
  icon.id = iconId;
  iconClasses?.forEach((className) => icon.classList.add(className));
  btn.appendChild(icon);
  return btn;
};

/**
 * Returns an icon button for expand/collapse.
 * @param {Boolean} isExpand if the button is an expand icon or not
 * @param {Array<String>} classNames Class names array for the button
 * @param {Array<String>} iconClasses Class names array for the icon
 * @returns
 */
const getExpandCollapseButtons = (isExpand, classNames, iconClasses) => {
  let icon = isExpand ? "right" : "down";
  let btn = renderButton(TABULATOR_BUTTON.ICON, classNames);

  let iconSpan = document.createElement("i");
  iconSpan.classList.add("far", `fa-chevron-${icon}`, "fs-16");
  iconClasses?.forEach((className) => iconSpan.classList.add(className));

  btn.appendChild(iconSpan);
  btn.title = isExpand ? "Expand" : "Collapse";

  return btn;
};

const getTablePlaceholder = (btnLabel, btnId) => {
  let btn = document.createElement("button");
  btn.classList.add("placeholder-btn");

  let icon = document.createElement("i");
  icon.id = btnId;
  icon.classList.add("far", "fa-plus-circle", "uk-heading-primary", "uk-add");

  let label = document.createElement("h4");
  label.innerText = btnLabel;

  btn.appendChild(icon);
  btn.appendChild(label);

  return btn;
};

/**
 * Return header group buttons
 * @param {*} isExpand (true or false) This is used to set the icon 
 * @param {*} params 
 * @returns 
 */
const getHeaderGrpButtons = (isExpand, params, onclick, element, onLinkclick) => {
  let btn = renderButton(TABULATOR_BUTTON.NO_BORDER, [
    "inset-parent",
    "collapsed-table-group",
  ]);

  let i = document.createElement("i");
  i.id = params.funkName;
  i.classList.add("group-expand-but");
  if(!isExpand) {
    i.classList.add("inset");
  }

  btn.appendChild(i);

  let span = document.createElement(element);
  span.innerHTML = params.title;
  if(element === "a") {
    span.classList.add('blue-link');
    span.onclick = onLinkclick;
  }

  btn.appendChild(span);

  btn.title = params.title;
  btn.onclick = onclick;

  return btn;
};


const getColumnFilterButton = (params, toggle, mainDivClassName) => {
  let div = document.createElement("div");
  div.classList.add(mainDivClassName || "filter-toggle-div");

  let alignment = ""
  if(params.align) {
		alignment = params.align && params.alignment ? params.alignment : 
      ((params.format_type === FormatTypes.TEXT || params.format_type === FormatTypes.QUADRANT) ? "left" : "right");
  }
  
  let titleSpanTooltip = (params.hideIcon && params.tooltipMessage ? params.tooltipMessage.replaceAll('"', '') : "title:");
  let titleSpan = document.createElement("span");
  titleSpan.setAttribute("uk-tooltip", titleSpanTooltip);
  titleSpan.innerText = params.title;
  if(params.hideIcon && params.tooltipMessage) {
    titleSpan.classList.add("fs-14");
    
  }
  if(params.addClass) {
    titleSpan.classList.add(params.addClass);
  }
  if(params.bold) {
    titleSpan.classList.add("uk-text-bolder");
  }
  if(params.title === "α") {
    titleSpan.classList.add("uk-text-bolder", "alpha_text");
  }
  if(params.title === "β") {
    titleSpan.classList.add("uk-text-bolder", "beta_text");
  }
  if(params.symbolColor?.includes("uk-text-bolder")) {
    titleSpan.classList.add("uk-text-bolder");
  }
  
  let spanInfoIcon = document.createElement("span");
  let infoIcon = document.createElement("i");
  infoIcon.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
  infoIcon.setAttribute("uk-tooltip", params.tooltipMessage);
  spanInfoIcon.appendChild(infoIcon);

  if(alignment === "left") {
    titleSpan.classList.add("title-align-left")
    div.appendChild(titleSpan);
  }
  if(params.tooltipMessage && !params.hideIcon) {
    div.appendChild(spanInfoIcon);
  }

  if(!params.hideFilter) {
    let btn = renderButton(TABULATOR_BUTTON.ICON, [
      "filter-toggle-but",
      params.field 
    ]);
    btn.id = "filter-dropdown-button-" + params.field;
    btn.setAttribute("uk-toggle", toggle);
    btn.onclick = function(event) {event.stopPropagation();}
    btn.onmouseover = function(event) {event.stopPropagation()}

    div.appendChild(btn)
  }
  
  if(alignment !== "left") {
    div.appendChild(titleSpan);
  }
  return div;
};

/**
 * Returns with a legend dislayed in a specified column and with a filter column if needed
 * @param {*} params 
 * @param {*} toggle 
 * @returns 
 */
const getColumnLegend = (params, toggle) => {
  let mainDiv = document.createElement("div");
  let div = document.createElement("div");
  let legendDiv = document.createElement("div");

  if(params.alphaClassName) {
    params.alphaClassName?.split(" ")?.forEach((className) => legendDiv.classList.add(className));
  }

  div.classList.add("uk-width-1-1");
  legendDiv.classList.add("range-container");

  let alphaDiv = document.createElement("div");
  let betaDiv = document.createElement("div");

  alphaDiv.classList.add("alpha", "fs-14", "alpha_text", "uk-margin-default-right");
  betaDiv.classList.add("beta", "fs-14", "beta_text");

  alphaDiv.innerHTML = params.alphaLegend;
  betaDiv.innerHTML = params.betaLegend;

  legendDiv.appendChild(alphaDiv);
  legendDiv.appendChild(betaDiv);

  div.appendChild(legendDiv);
  div.appendChild(getColumnFilterButton(params, toggle, "vector-name"))
  mainDiv.appendChild(div);

  return mainDiv;
}

// Function to create the input element
const createInput = () => {
  const input = document.createElement("input");
  input.type = "text";
  input.placeholder = lang.manage_columns.text.search_placeholder; // Add a placeholder
  return input;
};

// Function to create the apply button
const createApplyButton = (filterParams, input, highlightCountSpan) => {
  const applyButton = document.createElement("button");
  applyButton.textContent = lang.modal.buttons.apply;
  applyButton.classList.add("apply_button_table_search");
  applyButton.classList.add("btn-disabled");

  applyButton.addEventListener("click", function () {
    filterParams.func(input.value);
    if (filterParams.getResultCount() > 0) {
      highlightCountSpan.style.display = "block";
      highlightCountSpan.textContent = filterParams.getResultCount();
      applyButton.classList.add("btn-disabled");
    } else if (input.value.length > 0 && filterParams.getResultCount() === 0) {
      applyButton.textContent = lang.no_results
      applyButton.classList.add("no_results_found");
      highlightCountSpan.style.display = "none";
      filterParams.collapseOnClearSearch(true);
    } else {
      applyButton.classList.remove("btn-disabled");
      highlightCountSpan.style.display = "none";
    }
  });

  return applyButton;
};

// Function to create the magnifying glass icon
const createSearchIcon = (input, inputContainer, clearButton, applyButton, filterParams, highlightCountSpan) => {
  const searchIcon = document.createElement("i");
  searchIcon.classList.add("fas", "fa-search", "search-icon");

  // Add event listener to the input
  input.addEventListener("input", function () {
    if (input.value.length > 0) {
      applyButton.textContent = lang.modal.buttons.apply;
      searchIcon.style.display = "none";
      clearButton.style.display = "block";
      inputContainer.insertBefore(clearButton, input);
      applyButton.classList.remove("btn-disabled");
      applyButton.classList.remove("no_results_found");
    } else {
      applyButton.textContent = lang.modal.buttons.apply;
      clearButton.style.display = "none";
      searchIcon.style.display = "block";
      inputContainer.insertBefore(searchIcon, input);
      applyButton.classList.add("btn-disabled");
      applyButton.classList.remove("no_results_found");
      if (highlightCountSpan.style.display === "block") {
        filterParams.func("");
        filterParams.collapseOnClearSearch(true);
      }
      highlightCountSpan.style.display = "none";
    }
  });

  return searchIcon;
};

// Function to create the clear button
const createClearButton = (input, filterParams, highlightCountSpan, applyButton) => {
  const clearButton = document.createElement("button");
  clearButton.innerHTML = '<i class="fas fa-times"></i>'; // X icon
  clearButton.classList.add("clear_button_table_search");
  clearButton.addEventListener("click", function () {
    applyButton.textContent = lang.modal.buttons.apply;
    filterParams.func("");
    input.value = "";
    filterParams.collapseOnClearSearch(true);
    $(".table_search_filter_icon_input .search-icon").css("display","block");
    $(".table_search_filter_icon_input .clear_button_table_search").css("display","none");
    highlightCountSpan.style.display = "none";
    applyButton.classList.remove("no_results_found");
    applyButton.classList.add("btn-disabled");
  });
  return clearButton;
};

const createHighlightSpan = () => {
  const highlightCountSpan = document.createElement("span");
  highlightCountSpan.classList.add("highlight_count_result");

  return highlightCountSpan
}

const customFilterEditor = (cell, onRendered, success, cancel, filterParams) => {
  const container = document.createElement("div");
  container.classList.add("table_search_filter");

  const inputContainer = document.createElement("div");
  inputContainer.classList.add("table_search_filter_icon_input");

  const input = createInput();
  inputContainer.appendChild(input);

  const highlightCountSpan = createHighlightSpan()

  const applyButton = createApplyButton(filterParams, input, highlightCountSpan);
  inputContainer.appendChild(highlightCountSpan);
  inputContainer.appendChild(applyButton);

  const clearButton = createClearButton(input, filterParams, highlightCountSpan, applyButton);
  const searchIcon = createSearchIcon(input, inputContainer, clearButton, applyButton, filterParams, highlightCountSpan);

  inputContainer.appendChild(searchIcon);
  container.appendChild(inputContainer);

  return container;
};


export {
  getColumnFilterButton, 
  getColumnLegend,
  getExpandCollapseButtons, 
  getHeaderGrpButtons, 
  getTableButton,
  getTableIcon,
  getTableIconButton, 
  getTablePlaceholder, 
  renderButton,
  customFilterEditor
};

