import 'jqueryui';
import React, { Component } from "react";
import Popup from 'react-popup';
import { fetchDatasets } from "../../class/commonRequests.js";
import { ENGINE_FILTER, STAGING_SECTIONS, FormatTypes, API_URL, Formats, CONFIGURE_SECTIONS, MANAGE_ACCESS, VECTOR_STAGING_ATTRIBUTES, FILTER, DROPDOWN_TYPE, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE} from "../../class/constants.js";
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from "../../class/networkUtils";
import { getNumericValue } from "../../class/number.js";
import { buildNewObject, copyObjectValues, findOptionByKey, findOptionByKeyValue, getTranslationFile, deepCompareObjects, tryParse } from '../../class/utils.js';
import { validateFilterRow, arrangeFileNames } from "./FilterHelperFunctions.js";
import { formatValString } from '../../class/format';
import AdvancedFilterDialog from './AdvancedFilterDialog.js';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { alertAndLogError } from '../../class/jqueries'


import '../../styles/common.css';
import "../../styles/filter.css";
import { findIndexOfValue } from '../../class/array.js';
import { convertPxToViewport } from '../../class/formatting.js';
import { setLocalStorageValueByParameter } from '../../class/common.js';
import Input from '../../newComponents/Input.js';
import DropDown from '../../newComponents/DropDown.js';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';
const $ = require('jquery');
const lang = getTranslationFile();
const INFO_MESSAGE = "To be defined later";


const _ct = lang.ui_filter.dropdowns.functions.contains.value;
const _nct = lang.ui_filter.dropdowns.functions.not_contains.value;
const _empty = lang.ui_filter.dropdowns.functions.empty.value;
const _nempty = lang.ui_filter.dropdowns.functions.not_empty.value;

const _file = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _field = ENGINE_FILTER.KEYS.FIELD;
const _fieldOptions = ENGINE_FILTER.KEYS.FIELD_OPTIONS;
const _values = ENGINE_FILTER.KEYS.VALUES;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _dataFileType = ENGINE_FILTER.KEYS.DATA_FILE_TYPE;
const _parenthesisBefore = ENGINE_FILTER.KEYS.PARENTHESIS_BEFORE;
const _parenthesisAfter = ENGINE_FILTER.KEYS.PARENTHESIS_AFTER;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;
const _AND = lang.ui_filter.dropdowns.buttons.AND;
const _OR = lang.ui_filter.dropdowns.buttons.OR;
const _value = ENGINE_FILTER.KEYS.VALUE;
const _rowPosition = ENGINE_FILTER.KEYS.ROW_POSITION;

const filter_funcs = lang.ui_filter.dropdowns.engine_filter_functions;

//sample filter object with same parameters
//for both vector and profit stack filter types
//-- Do not copy, call getEmptyFilterSample()
const sampleFilterObj = {
    [_file]: "",
    [_column]: "",
    [_dataFileType]: "",
    [_function]:"",
    [_field]: "",
    [_values]: [],
    [_valueOptions]: [],
    [_fileOptions]: [],
    [_functionOptions]: [],
    [_columnOptions]: [],
    [_logicalOperator]: "",
    [_parenthesisBefore]: "(",
    [_parenthesisAfter]: ")",
    [_fieldDataType]: "",
    [_rawFileSubtypeId]: "",
    [_field]:""
};

const sampleRowTotal = {
    total_lines: "-", total_invoices: "-", total_revenue: "-", total_cogs: "-"
}

/**
 * 
 * @author [Lara Jannoun]
 */
class EngineFilterDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            fileTypeOptions: this.props.fileTypeOptions && this.props.fileTypeOptions.length > 0 ? this.props.fileTypeOptions : [],
            filterRefs: this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ? [] : this.getNewFilterRefsArray(), //initialized this way to start with at least one row
            selectedOptions: null,
            initialFilter: this.getEmptyFilterSample(), //this array holds the value of the applied filter (on apply), it's used to revert changes on Cancel
            filterWasEdited: false,

            /* this state starts empty, when filter is changed from outside (from display or clicking on quad count...)
             * we save the new filter inside it because we cannot save it in the reference array and read from it in
             * renderEngineFilterDialog where the state.filterRefs is automatically filled on render
             */
            outsideFilter: [],
            useOutsideFilter: false,
            advancedData: [],
            rowTotals: this.props.rowTotals ? this.props.rowTotals : [] //[sampleRowTotal],
        };

        this.fetchAPI = fetchAPI.bind(this);
        this.fetchDatasets = fetchDatasets.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
        this.onChangeFilterRow = this.onChangeFilterRow.bind(this);
        this.revertAdvancedFilter = this.revertAdvancedFilter.bind(this);
        this.submitAdvancedFilter = this.submitAdvancedFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.setFilterTo = this.setFilterTo.bind(this);
        this.setFilterFromOutside = this.setFilterFromOutside.bind(this);
        this.renderFilterDialog = this.renderFilterDialog.bind(this);
        this.getTextFilter = this.getTextFilter.bind(this);
        this.getAdvancedFilterData = this.getAdvancedFilterData.bind(this);
        this.reRenderFilter = this.reRenderFilter.bind(this);
        this.renderFilterModal = this.renderFilterModal.bind(this);
        this.clearLogicalOperator = this.clearLogicalOperator.bind(this);
        this.updateAdvancedData = this.updateAdvancedData.bind(this);
        this.resetParenthesisInRefs = this.resetParenthesisInRefs.bind(this);
        this.getFilterValidationStatus = this.getFilterValidationStatus.bind(this);
        this.getExclusionData = this.getExclusionData.bind(this);
        this.hideAdvancedFilter = this.hideAdvancedFilter.bind(this);
        this.advancedFilterShowHide = this.advancedFilterShowHide.bind(this);
        this.showAdvancedFilter = this.showAdvancedFilter.bind(this);
        this.refresh = this.refresh.bind(this);

        this.lastUpdated = Date.now();
        this.filterDisplayEditable = true;  //used to hide the clear all, (X), and saved filters dropdown when the filter should not be changeable
        this.advancedFilter = "";

        this.cookieActive = this.props.useCookies !== undefined ? this.props.useCookies : true;
        this.componentId = this.props.compNumber !== undefined ? "#engineFilter_" + this.props.compNumber : "#engineFilter";
    }

    /**
     * Returns a copy of the empty filter sample
     */
    getEmptyFilterSample(sampleType = "array") {
        if(sampleType === "array") {
            return [copyObjectValues(sampleFilterObj)];
        } else {
            return copyObjectValues(sampleFilterObj);
        }
    }

    /**
     * Adds a new empty filter row
     */
    addNewFilter(newFilter, attribute, e, callback) {
        if(this.props.setIndexFilter) {
            this.props.setIndexFilter(false);
        }
        var tempRefArray = this.state.filterRefs;
        var comp = this;
        if(comp.state.rowTotals && newFilter) {
            comp.state.rowTotals.push(sampleRowTotal);
        }
        if (newFilter) {
            tempRefArray.push(this.getNewFilterRefObject());
        }

        this.updateAdvancedData([]);    //reset advanced data
        this.setState({
            filterRefs: tempRefArray,
            rowTotals: comp.state.rowTotals,
            filterWasEdited: true,
            nextLogicalOperator: attribute === _logicalOperator ?  e.value : "" //to put operator on the next row and not previous one
        }, function() {
            comp.resetParenthesisInRefs();
            if (callback) {
                callback();
            }
        });
    }

    getExclusionData(callback) {
        this.props.getExclusionData(this.state.filterRefs, callback)
    }

    clearLogicalOperator() {
        this.state.nextLogicalOperator = "";
    }

    onChangeFilterRow(rowIndex, attribute, value) {
        this.state.filterWasEdited = true;
        this.state.filterRefs[rowIndex].lastUpdated = Date.now().valueOf();
    }

    resetInitialFilter() {
        this.state.initialFilter = this.getEmptyFilterSample();
    }

    getNewFilterRefObject(){
        return { ref: React.createRef(), lastUpdated: Date.now().valueOf(), rand: Math.random() };
    }

    getNewFilterRefsArray() {
        return [this.getNewFilterRefObject()];
    }

    setFilterTo(filter, callback) {
        var _this = this;
        this.setState({
            outsideFilter: filter,
            useOutsideFilter: true
        }, function() {
            this.state.outsideFilter = [];  //reset outsideFilter after rerender
            this.state.useOutsideFilter = false;
            if(callback && typeof callback === "function") {
                callback();
            }
            _this.updateAdvancedData([]);
        })
    }
    
    /**
     * This function is called when the filter is changed from an outside component
     * @param {*} filter 
     * @param {*} applyFilter -- send as false to just update the filter without resending it back to the parent
     */
    setFilterFromOutside(filter, callback){
        if(typeof filter === "string") {
            filter = tryParse(filter);
        }
        if(!Array.isArray(filter)) {
            return;
        }

        if(filter.length === 0) {
            filter = this.getEmptyFilterSample();
        } else {
            filter[0][_logicalOperator] = "";
        }
        this.setFilterTo(filter, callback);
    }

    /**
     * This function returns an object containing:
     * - filter array containing the valid filters
     * - array of integers containing the indices of invalid filters in state.filterRefs
     */
    getFilterValidationStatus() {
        var comp = this;
        var filter = [];
        var invalidFiltersIndices = [];
        if (this.state.filterRefs && this.state.filterRefs.length > 0) {
            this.state.filterRefs.map((ref, index) => {
                let filterRow = ref.ref && ref.ref.current && ref.ref.current.filterObj ? ref.ref.current.filterObj : "";
                if (filterRow !== "" && validateFilterRow(filterRow, comp.props.stagingReport)) {
                    filter.push(filterRow);
                } else {
                    invalidFiltersIndices.push(index);
                }
            });
        }

        return { filter: filter, invalidFiltersIndices: invalidFiltersIndices};
    }

    extractCleanFilter() {
        var filter = [];
        this.state.filterRefs.map((tempFilter) => {
            if(tempFilter.ref.current) {
                tempFilter = copyObjectValues(tempFilter.ref.current.filterObj);
                delete tempFilter[_valueOptions];
                filter.push(tempFilter);
            }
        });
        return filter;
    }

    reRenderFilter() {
        this.showAdvancedFilter();
    }


    switchOperatorInAdvancedFilter(e) {
        var val = e.currentTarget.innerHTML.replace(/ /g, "") === _AND ? _OR : _AND;
        $("#"+e.currentTarget.id).html(val);
    }

    componentDidMount() {
        $(document).on("click", ".remove-cursor", function () {
            $(this).remove(); 
        })
    }

    getAdvancedFilterData() {
        var advancedData = [];
        var comp = this;

        if(!this.state.advancedData.length){
            if (comp.props.useOutsideFilter && comp.props.outsideFilter && comp.props.outsideFilter.length > 0) {
                comp.props.outsideFilter.map((tempFilter, index) => {
                    var operator = "";
                    var parBefore = "";
                    var parAfter = "";
                    if (tempFilter) {
                        operator = tempFilter[_logicalOperator] || "";
                        parBefore = tempFilter[_parenthesisBefore];
                        parAfter = tempFilter[_parenthesisAfter];
                    }else {
                        operator = comp.state.nextLogicalOperator || "";
                    }
    
                    advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
                });
            
            } else if (comp.state.useOutsideFilter && this.state.outsideFilter && (this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING || this.props.stagingReport === CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS) && this.state.outsideFilter[0][_column]) {
                this.state.outsideFilter.map((tempFilter, index) => {
                    var operator = "";
                    var parBefore = "";
                    var parAfter = "";
                    if (tempFilter) {
                        operator = tempFilter[_logicalOperator];
                        parBefore = tempFilter[_parenthesisBefore];
                        parAfter = tempFilter[_parenthesisAfter];
                    } else {
                        operator = this.state.nextLogicalOperator || "";
                    }
        
                    advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
                });
                this.updateAdvancedData(advancedData);
             } else {
                this.state.filterRefs.map((tempFilter, index) => {
                    var operator = "";
                    var parBefore = "";
                    var parAfter = "";
                    if (tempFilter.ref.current) {
                        operator = tempFilter.ref.current.filterObj[_logicalOperator] || "";
                        parBefore = tempFilter.ref.current.filterObj[_parenthesisBefore] || "";
                        parAfter = tempFilter.ref.current.filterObj[_parenthesisAfter] || "";

                        advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});

                    } else {
                        operator = comp.state.nextLogicalOperator || "";
                    }
    
                });
            }
            
            this.updateAdvancedData(advancedData);
        } else if (this.state.outsideFilter && (this.props.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING || this.props.stagingReport === CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS) && this.state.advancedData.length < this.state.filterRefs.length) {
            this.state.outsideFilter.map((tempFilter, index) => {
                var operator = "";
                var parBefore = "";
                var parAfter = "";
                if (tempFilter) {
                    operator = tempFilter[_logicalOperator];
                    parBefore = tempFilter[_parenthesisBefore];
                    parAfter = tempFilter[_parenthesisAfter];
                } else {
                    operator = this.state.nextLogicalOperator || "";
                }
    
                advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
            });
            this.updateAdvancedData(advancedData);
         } else if (this.props.outsideFilter && this.props.useOutsideFilter && this.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING) {
            this.props.outsideFilter.map((tempFilter, index) => {
                var operator = "";
                var parBefore = "";
                var parAfter = "";
                if (tempFilter) {
                    operator = tempFilter[_logicalOperator] || "";
                    parBefore = tempFilter[_parenthesisBefore] || "";
                    parAfter = tempFilter[_parenthesisAfter] || "";
                }else {
                    operator = this.state.nextLogicalOperator || "";
                }
    
                advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
            });
            this.updateAdvancedData(advancedData);
         } else {
             return this.state.advancedData;
        }

        return advancedData;
    }

    /**
     * This function is used to update the advanced data in a single place
     * It's used to update the value or reset the state when filter rows are changed
     * @param {*} advancedData 
     * @param {*} silent 
     */
    updateAdvancedData(advancedData, silent=true) {
    // updateAdvancedData(index, text) {
        // let tempObj = {[PAR_OBJ.keys.type]: PAR_OBJ.values.par, [PAR_OBJ.keys.value]: text};
        // let advancedData = copyObjectValues(this.state.advancedData);
        // advancedData.splice(index, 0, tempObj);

        if(silent) {
            this.state.advancedData = advancedData;
        } else {
            this.setState({
                advancedData: advancedData
            });
        }
    }

    getTextFilter() {
        var filterText = "";
        this.getAdvancedFilterData().map(e=>{
            filterText += " "+ e[_logicalOperator] +" "+ e[_parenthesisBefore] +" "+ e["index"] +" "+ e[_parenthesisAfter];
        });
        return filterText;
    }

    hideAdvancedFilter() {
        $(this.componentId+" #advanced-filter-draggable").addClass("uk-hidden").removeClass("uk-display-flex");
        $(this.componentId+" #advanced-filter").addClass("uk-display-flex").removeClass("uk-hidden");
    }

    advancedFilterShowHide(hideOrNotText, hideOrNotFilter) {
        $(this.componentId+" #advanced-text").addClass(hideOrNotText).removeClass(hideOrNotFilter);
        if(hideOrNotFilter === 'uk-hidden'){
            $(this.componentId+" #advanced-filter").addClass(hideOrNotFilter).removeClass("uk-display-flex");
        }
        else{
            $(this.componentId+" #advanced-filter").addClass(hideOrNotFilter).removeClass(hideOrNotText);
        }
    }

    showAdvancedFilter() {
        $(this.componentId+" #advanced-filter-draggable").removeClass("uk-hidden").addClass("uk-display-flex");
        $(this.componentId+" #advanced-filter").removeClass("uk-display-flex").addClass("uk-hidden");
    }

    submitAdvancedFilter() {
        var draggedText = $(this.componentId+" #advanced-text-right").text();
        // "( (  1 )  ) AND ( ( 2 ) OR ( 3 ) )"
        draggedText = draggedText.toUpperCase().replace(/ /g," ");

        var validation = null;
        try {
            eval ("validation = "+ draggedText.replace(/AND|OR/g, "+") +";");
        } catch(err) {
            validation = null;
        }

        if(validation === null) {
          this.setInfoDialogOpen(true, lang.advanced_filter_validation_message)
            return;
        }

        draggedText = draggedText.split(" ");
        var advancedData = [];
        let parBefore = "";
        let parAfter = "";
        let index = "";
        let operator = "";
        for(var word in draggedText) {
            word = draggedText[word];
            if(word.match(/[\(]/i)) {
                parBefore += word;
            } else if(word.match(/[\)]/i)) {
                parAfter += word;
            } else if(word.match(/[0-9]/i)) {
                index = word;
            } else if (word.match(/[A-Z]/i)) {
                advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index, [_logicalOperator]: operator});
                operator = word;
                parBefore = parAfter = "";
            }
        }

        //to push the last row that was filled
        advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index, [_logicalOperator]: operator});

        this.state.filterWasEdited = true;
        this.hideAdvancedFilter();
        //update the advanced data state

        let advancedDataBeforeUpdate = this.getAdvancedFilterData();
        this.updateAdvancedData(advancedData);
        let advancedDataAfterUpdate = this.getAdvancedFilterData();

        this.updateParenthesisInRefs();

        //sending request to refetch exclusions on submitting advanced filter
        if(!deepCompareObjects(advancedDataBeforeUpdate, advancedDataAfterUpdate) && typeof this.props.onChangeAdvancedFilter === "function") {
            this.setState({
                rowPosition:undefined
            },()=> {
                this.props.resetRowTotals();
            })
        }
    }

    /**
     * This function updates the saved parenthesis in the refs
     */
    updateParenthesisInRefs() {
        var comp = this;
        this.state.filterRefs.map((ref, i) => {
            if(ref.ref.current) {
                let filterObj = ref.ref.current.filterObj;
                let objAdvancedData = findOptionByKeyValue(comp.state.advancedData, "index", i+1);  //read the advanced data for this index

                //update the advanced data in the refs
                filterObj[_parenthesisBefore] = objAdvancedData[_parenthesisBefore];
                filterObj[_parenthesisAfter] = objAdvancedData[_parenthesisAfter];
                filterObj[_logicalOperator] = objAdvancedData[_logicalOperator];
            }
        });

        //empty advanced data after updating refs
        this.updateAdvancedData([]);
        this.forceUpdate();
    }

    resetParenthesisInRefs(silent=false) {
        var comp = this;
        this.state.filterRefs.map((tempFilter, index) => {
            var operator = "";
            if (tempFilter.ref.current) {
                operator = tempFilter.ref.current.filterObj[_logicalOperator];
            } else {
                operator = comp.state.nextLogicalOperator || "";
            }

            tempFilter.ref.current.filterObj[_parenthesisBefore] = "";  //reset parenthesis
            tempFilter.ref.current.filterObj[_parenthesisAfter] = "";  //reset parenthesis

            comp.state.filterRefs[0].ref.current.filterObj[_parenthesisBefore] = "(";
            comp.state.filterRefs[comp.state.filterRefs.length-1].ref.current.filterObj[_parenthesisAfter] = ")";

            if(operator === _OR && index > 0) {
                comp.state.filterRefs[index-1].ref.current.filterObj[_parenthesisAfter] = ")";
                tempFilter.ref.current.filterObj[_parenthesisBefore] = "(";
            }
        });

        this.updateAdvancedData([], silent);
    }

    revertAdvancedFilter() {
        $(this.componentId+" #advanced-filter-draggable .advanced-filter-rect").find('.draggedDiv').each(function (i) {          
            $(this).remove();
        });

        this.hideAdvancedFilter();
        this.updateAdvancedData([], false);
    }

    removeFilter(rowIndex, silent = false, refreshData = false) {
        var comp = this;
        if(rowIndex === 0 && this.state.filterRefs.length === 1 && this.props.stagingReport !== STAGING_SECTIONS.MAPPING_TIE_OFF) {
            this.state.filterRefs = this.getNewFilterRefsArray();
        } else {
            if(rowIndex === 0 && this.state.filterRefs[rowIndex+1] && this.state.filterRefs[rowIndex+1].ref.current) {
                this.state.filterRefs[rowIndex + 1].ref.current.filterObj[_logicalOperator] = "";
                this.state.rowTotals = [];
            }
            this.state.filterRefs.splice(rowIndex, 1); //to remove the row from the list
            this.resetParenthesisInRefs(); //for the exclusion data refresh

            if (this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
                comp.props.checkExclusionsValidity();
                if (this.state.filterRefs.length > 0) {
                    let advancedFilterText = comp.props.getAdvancedFilterText(this.state.filterRefs)
                    comp.props.resetRowTotals(advancedFilterText);  
                    comp.setState({
                        rowPosition:rowIndex+1
                    })
                } else {
                    comp.props.showExclusionOverlay(true); 
                    comp.props.resetTotals();
                }    
            } else if (this.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING){
                this.props.removeRow(rowIndex, this.props.rowIndex)
            }
        }
        this.resetParenthesisInRefs();

        this.state.filterWasEdited = true;


        if(!silent) {
            this.forceUpdate();
        }
    }

    static getDerivedStateFromProps(props, state) {
        var advancedData = [];
        var readFilterFromNextProps = true;
        if(props.outsideFilter  && props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING){
            for(var i in props.outsideFilter){
                if(props.outsideFilter[i][_logicalOperator] ===undefined || props.outsideFilter[i][_parenthesisBefore] ===undefined ||  props.outsideFilter[i][_parenthesisAfter]===undefined){
                    readFilterFromNextProps = false;
                }
            }
            if(readFilterFromNextProps){
                props.outsideFilter.map((tempFilter, index) => {
                    var operator = "";
                    var parBefore = "";
                    var parAfter = "";
                    if (tempFilter) {
                        operator = tempFilter[_logicalOperator];
                        parBefore = tempFilter[_parenthesisBefore];
                        parAfter = tempFilter[_parenthesisAfter];
                    }else {
                        operator = this.state.nextLogicalOperator || "";
                    }
        
                    advancedData.push({[_parenthesisBefore]: parBefore, [_parenthesisAfter]: parAfter, index: index+1, [_logicalOperator]: operator});
                });
                return { advancedData: advancedData }
            }
        }
        return null;
    }

    getSnapshotBeforeUpdate(props) {
        var comp = this;

        $('#rows-parent').sortable({
            handle: ".uk-grab",
            start: () => {
                var emptyRows = []
                if (comp.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING || comp.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING){
                    emptyRows = comp.state.filterRefs.filter(elem => elem.ref.current.filterObj.fileType === "" || elem.ref.current.filterObj.fileType === undefined);
                }
                else {
                    emptyRows = comp.state.filterRefs.filter(elem => elem.ref.current.filterObj.column === "" || elem.ref.current.filterObj.column === undefined);
                }
                 
                if (emptyRows.length === comp.state.filterRefs.length){
                    $("#advanced-filter").addClass("uk-hidden").removeClass("uk-display-flex");
                } else {
                    comp.hideAdvancedFilter();
                    comp.state.filterWasEdited = true;
                }
            },
            stop: function (e, ui) {
                var operators = [];
                var orders = [];
    
                var children = $(this).children();
                children.map((index) => {
                    var child = children[index];
                    child.id.split('-').pop() !== "" ? operators.push(child.id.split('-').pop()) : "";
                    $(child).attr("datarowindex") ? orders.push($(child).attr("datarowindex").split('-').pop()) : "";
                });
    
                let ar = [];
                let filters = [];
                while (orders.length) {
                    let cur_order = orders.shift();
                    var element = comp.state.filterRefs[cur_order - 1];
                    element.ref.current.filterObj.logical_operator === "" ? element.ref.current.filterObj.logical_operator = _AND : "";
                    filters.push(element.ref.current.filterObj);
                    ar.push(element)
                }
                
                console.log("ended drag")
                ar.length > 0 && ar[0] ? ar[0].ref.current.filterObj.logical_operator = "" : "";
                comp.state.filterRefs = ar;
                comp.resetParenthesisInRefs(false);
                if (typeof comp.props.updateConditionFilter === "function") {
                    comp.props.updateConditionFilter(filters);
                }   
                if (comp.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
                    comp.setState({
                        rowPosition:undefined
                    },()=> {
                        comp.props.resetRowTotals();
                    }) //refetching the totals
                }
            }
        });
        
        if (Array.isArray(props.fileTypeOptions) && props.fileTypeOptions.length > 0) {
            return props.fileTypeOptions;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {     
        if (snapshot !== null) {
            this.state.fileTypeOptions = snapshot;
        }
        this.state.rowPosition = undefined;
    }

    refresh() {
        this.forceUpdate();
    }

    /**
     * Renders all the filter rows in the filter modal
     */
    renderFilterDialog() {
        var comp = this;
        var filterRows = [];

        var useOutsideFilter = comp.state.useOutsideFilter;
        var loopedOverArr = this.props.useOutsideFilter ? this.props.outsideFilter : useOutsideFilter ? comp.state.outsideFilter : comp.state.filterRefs;
        //if state.filterRefs contains less rows than outsideFilter, fill it the same amount
        if (useOutsideFilter || this.props.useOutsideFilter &&  this.state.filterRefs && loopedOverArr){
            if (this.state.filterRefs.length < loopedOverArr.length) {
                for (var i = this.state.filterRefs.length; i < loopedOverArr.length; i++) {
                    this.state.filterRefs.push(this.getNewFilterRefObject());
                }
            } else {
                this.state.filterRefs.length = loopedOverArr.length;    //if useOutsideFilter, remove all the extra rows in filterRefs
            }
        }

        for (var i in loopedOverArr) {
            i = Number(i);
            var filterRowObj = this.props.useOutsideFilter ? this.props.outsideFilter[i] 
            : useOutsideFilter ? this.state.outsideFilter[i] : this.getEmptyFilterSample("object");    //current filterObj
            var tempRef = this.state.filterRefs[i] ? this.state.filterRefs[i].ref : React.createRef();
            if((useOutsideFilter || this.props.useOutsideFilter) && this.state.filterRefs[i] && tempRef.current) {
                //if useOutsideFilter, update the filterObj in the ref if the row has been previously rendered
                //(it could be not previously rendered if outsideFilter.length > filterRefs.length)
                this.state.filterRefs[i].ref.current.filterObj = filterRowObj;
            } else if (this.state.filterRefs[i] && tempRef && tempRef.current) {
                filterRowObj = tempRef.current.filterObj;
            }

            filterRows.push(
                <FilterDialogRow key={"filterRow_" + i + "_" +  this.state.filterRefs[i].rand} ref={tempRef}
                    onChangeFilterRow={this.onChangeFilterRow}
                    componentId={this.componentId}
                    stagingReport={this.props.stagingReport}
                    showFileDropdown={this.props.showFileDropdown}
                    addNewFilter={this.addNewFilter} totalRows={loopedOverArr.length}
                    rowIndex={i} removeFilter={this.removeFilter}
                    filterRowObj={filterRowObj} getEmptyFilterSample={this.getEmptyFilterSample}
                    renderFilterModal={this.renderFilterModal}
                    nextLogicalOperator={!tempRef.current ? this.state.nextLogicalOperator : tempRef.current.filterObj[_logicalOperator]}
                    clearLogicalOperator={this.clearLogicalOperator}
                    onDropDownChange={this.props.onDropDownChange}
                    valueOptions={this.props.valueOptions}
                    fileTypeOptions={this.props.fileTypeOptions}
                    field_data_type={this.props.field_data_type}
                    file_type={this.props.file_type}
                    value={this.props.value}
                    conditionIndex={this.props.rowIndex}
                    updateRowTotals={this.props.updateRowTotals}
                    getExclusionData={this.getExclusionData}
                    showExclusionOverlay={this.props.showExclusionOverlay}
                    prepareExclusion={this.props.prepareExclusion}
                    isLastRow={i === loopedOverArr.length - 1}
                    rowTotals={this.props.rowTotals}
                    resetTotals={this.props.resetTotals}  //mapping and tie off
                    resetRowTotals={this.props.resetRowTotals}
                    getValues={this.props.getValues}  //mapping and tie off
                    refresh={this.refresh}
                    resetParenthesisInRefs={this.resetParenthesisInRefs}
                    label={this.props.label}
                    loadEntitiesFunc={this.props.loadEntitiesFunc}
                    removeFilterRowInCondition={this.props.removeFilterRowInCondition}
                    scenario_id={this.props.scenario_id}
                    vectorOptions={this.props.vectorOptions}
                    hiddenVectors={this.props.hiddenVectors}
                    periodName={this.props.periodName}
                    checkExclusionsValidity={this.props.checkExclusionsValidity}
                    getAdvancedFilterText={this.props.getAdvancedFilterText}
                    filterDialRef={this.props.filterDialRef}
                />
            );
        }
        
        return filterRows;
    }

    dragRow(ev) {
        ev.dataTransfer.setData("text/html", ev.target.id);
    }

    allowDropRow(ev) {
        ev.preventDefault();
    }
      
    dropRow(ev) {
        ev.preventDefault();

        /* If you use DOM manipulation functions, their default behaviour it not to 
        copy but to alter and move elements. By appending a ".cloneNode(true)", 
        you will not move the original element, but create a copy. */
        var data = ev.dataTransfer.getData("text/html");

        var nodeCopy = document.getElementById(data) ? document.getElementById(data) : null;
        if (nodeCopy !== null) {
            // nodeCopy.id = "draggedDiv"; /* We cannot use the same ID */
            nodeCopy.classList.add("draggedDiv");
            ev.target.append(nodeCopy);
        }

    }

    renderFilterTotals() {
        var comp = this;
        var filterTotals = [];
        var loopedOverArr = comp.props.rowTotals ? comp.props.rowTotals : comp.state.rowTotals ;

        var index = 0;
        for (var i in loopedOverArr) {
            i = Number(i);
            var completeObj = comp.state.filterRefs[i] && comp.state.filterRefs[i].ref.current !== null && comp.state.filterRefs[i].ref.current.filterObj ? comp.state.filterRefs[i].ref.current.filterObj : null;
            var refresh = completeObj !== null && ((completeObj[_values] && completeObj[_values].length > 0 && ![_empty, _nempty].includes(completeObj[_function]) || [_empty, _nempty].includes(completeObj[_function])) )  ? true : false;

            var filterObj = loopedOverArr[i];
            // var height = filterObj && filterObj.count && Number(filterObj.count) !== 1 ? 83*Number(filterObj.count) + 'px' : '83px';
            // var inputHeight = filterObj && filterObj.count !== '1' ? (38 * Number(filterObj.count))+(25*(Number(filterObj.count)-1)) + 'px': 38+ 'px'; //45 is the margin+operator

            index++;
            if(comp.state.rowPosition && index === comp.state.rowPosition){
                index++;
            }
            var inputHeight = $("[datarowindex='filter-row-"+(index)+"']")?.height();
            if(filterObj && Number(filterObj.count) > 1) {
                var top = $("[datarowindex='filter-row-"+(index)+"']")?.offset()?.top;
                index += Number(filterObj.count) - 1;
                if(filterObj.count>1) {
                    inputHeight = $("[datarowindex='filter-row-"+(index)+"']")?.offset()?.top - top + $("[datarowindex='filter-row-"+(index)+"']")?.height();
                }    
            }

            filterTotals.push(
                <React.Fragment key={"filterTotal-" + i}>
                    {this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ?
                        <div className="totals-container" style={{height: inputHeight, marginBottom: convertPxToViewport(25)}} >
                            <div className="width-150">
                                <Input type="text" id={"total-lines-input-exclusion-"+i} className="form-control border-none width-150 total-input-exclusion uk-text-center input-not-allowed" value={filterObj && refresh ? filterObj.total_lines : "-"} disabled={true}></Input>
                            </div>
                            <div className="width-150">
                                <Input type="text" id={"total-invoices-input-exclusion-"+i} className="form-control border-none width-150 total-input-exclusion uk-text-center input-not-allowed" value={filterObj && refresh ? filterObj.total_invoices : "-"} disabled={true}></Input>
                            </div>
                            <div className="width-150">
                                <Input type="text" id={"total-revenue-input-exclusion-"+i} className="form-control border-none width-150 total-input-exclusion uk-text-center input-not-allowed" value={filterObj && refresh ? formatValString(filterObj.total_revenue, FormatTypes.AMOUNT) : "-"} disabled={true}></Input>
                            </div>
                            <div className="width-150">
                                <Input type="text" id={"total-cogs-input-exclusion-"+i} className="form-control border-none width-150 total-input-exclusion uk-text-center input-not-allowed" value={filterObj && refresh ? formatValString(filterObj.total_cogs, FormatTypes.AMOUNT) : "-"} disabled={true}></Input>
                            </div>
                        </div>
                    : ""}
                </React.Fragment>

            );
        }

        var whole = <div>
            {filterTotals}
        </div>;
        return whole;
    }

    renderFilterModal() {
        var filterDialog = this.renderFilterDialog(); //displays selected filters
        var filterTotals = this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ? this.renderFilterTotals() : ""; //displays totals

        return (
            <div id={this.componentId.replace("#","")} className="filterDialogComponent"> 
                <div id="filter-table" className="engine-filter uk-padding-small-top uk-display-flex uk-flex-column uk-flex-between">
                    <div className={"filter_condition engine_filter_container"}>
                        <div className="filter-condition-titles uk-display-box uk-margin-small-bottom">
                            <div className="width-40 uk-margin-left"></div>    {/**To reserve space over index icon */}
                            { [STAGING_SECTIONS.CONFIGURE_TIE_OUT,STAGING_SECTIONS.METRICS_MAPPING, STAGING_SECTIONS.MAPPING_TIE_OFF, STAGING_SECTIONS.VECTOR_MAPPING, STAGING_SECTIONS.CALCULATED_COLUMNS, MANAGE_ACCESS.NAME].includes(this.props.stagingReport) ?
                            <div className="width-150 uk-margin-xmedium-right">
                                <span className="fs-14">{this.props.stagingReport === MANAGE_ACCESS.NAME ? lang.ui_filter.col_names.typeManage :lang.ui_filter.col_names.type}</span>
                                {![STAGING_SECTIONS.CONFIGURE_TIE_OUT].includes(this.props.stagingReport) && <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />}
                            </div>
                            : ""}
                            {this.props.label ? 
                            <div className="width-150 uk-margin-xmedium-right">
                                <span className="fs-14">{lang.ui_filter.col_names.calcField}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                            </div>
                            :<div className="width-150 uk-margin-xmedium-right">
                                <span className="fs-14">{lang.ui_filter.col_names.field}</span>
                                {![STAGING_SECTIONS.CONFIGURE_TIE_OUT].includes(this.props.stagingReport) && <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />}
                            </div>}
                            {this.props.stagingReport === MANAGE_ACCESS.NAME ?
                            <div className="width-150 uk-margin-xmedium-right">
                                <span className="fs-14">{lang.ui_filter.col_names.fieldVector}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                            </div>
                            : ""}
                            <div className="width-150 uk-margin-xmedium-right">
                                <span className="fs-14">{lang.ui_filter.col_names.function}</span>
                                {![STAGING_SECTIONS.CONFIGURE_TIE_OUT].includes(this.props.stagingReport) && <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />}
                            </div>
                            <div className="width-250 uk-margin-xmedium-right">
                                <span className="fs-14">{lang.ui_filter.col_names.value}</span>
                                {![STAGING_SECTIONS.CONFIGURE_TIE_OUT].includes(this.props.stagingReport) && <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />}
                            </div>
                            { this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ?
                                <div className="uk-display-inline-flex">
                                    <div className="uk-margin-large-left uk-margin-large-right">
                                        <span className="uk-margin-small-left fs-14">{lang.ui_filter.col_names.total_lines}</span>
                                        <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                                    </div>
                                    <div className="uk-margin-large-right uk-margin-left">
                                        <span className="fs-14">{lang.ui_filter.col_names.total_invoices}</span>
                                        <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                                    </div>
                                    <div className="uk-margin-large-right uk-margin-left">
                                        <span className="fs-14">{lang.ui_filter.col_names.total_revenue}</span>
                                        <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                                    </div>
                                    <div>
                                        <span className="fs-14">{lang.ui_filter.col_names.total_cogs}</span>
                                        <i className="fs-12 fal fa-info-circle uk-margin-small-left" uk-tooltip={INFO_MESSAGE} />
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <div className="uk-display-inline-flex">
                            <div id="first-column">
                                <div id="rows-parent">
                                    {filterDialog}
                                </div>
                            </div>
                            <div id="second-column" className="uk-display-inline-flex">
                                {filterTotals}
                            </div>
                        </div>
                    </div>
                    {this.props.hideAdvancedFilter?"":
                    <AdvancedFilterDialog ref={el => this.advancedFilterDialog = el} getTextFilter={this.getTextFilter} getFilterValidationStatus={this.getFilterValidationStatus}
                    advancedFilterShowHide={this.advancedFilterShowHide} reRenderFilter={this.reRenderFilter} switchOperatorInAdvancedFilter={this.switchOperatorInAdvancedFilter}
                    submitAdvancedFilter={this.submitAdvancedFilter} revertAdvancedFilter={this.revertAdvancedFilter} stagingReport={this.props.stagingReport}/>}
                {/* {this.advancedFilter} */}
                </div>
            </div>
        )
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg || this.state.infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false)}
        />
      )
    }
    
    render() { 
        var filterModal = this.renderFilterModal();
        return (
            <div>
                <Modal
                  id={"info-dialog-engine-filter"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                {filterModal}
            </div>
        )
    }
}

class Operators extends Component {
        constructor(props) {
        super(props);
            this.state = {
            
        }

    }

    render() {
        var comp = this;
        var row =
        <div key={"op-"+Math.random()} className="d-flex uk-flex-left mrgl60" style={{marginTop:"0.55vw" , marginBottom:"0.521vw"}}>
            <button className="subtle--btn-secondary uk-margin-default-right" value={_AND} onClick={() => comp.props.handleChange({ value: _AND }, _logicalOperator, true)}>
                <i className={"far fa-plus-circle fa-lg uk-padding-xsmall-right"} />{_AND}
            </button>
            <button className="subtle--btn-secondary justify-content-start" value={_OR} onClick={() => comp.props.handleChange({ value: _OR }, _logicalOperator, true)}>
                <i className={"far fa-plus-circle fa-lg uk-padding-xsmall-right"} />{_OR}
            </button>
        </div>
        return (row);
    }
    
}
class FilterDialogRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueOptions: [],
            fileTypeOptions: [],
            updateOptions: false,
            entitiesRndId: Math.floor(Math.random() * 100)
        }

        this.sampleFilterObj = {
            [_file]: "",
            [_column]: "",
            [_function]: "",
            [_values]: [],
            [_valueOptions]: [],
            [_fileOptions]: this.props.fileTypeOptions && this.props.fileTypeOptions.length > 0 ? this.props.fileTypeOptions : [],
            [_functionOptions]: [],
            [_columnOptions]: [],
            [_logicalOperator]: "",
            [_parenthesisBefore]: "(",
            [_parenthesisAfter]: ")",
            [_fieldDataType]: "",
            [_field]:""
        }

        this.filterObj = copyObjectValues(this.sampleFilterObj);

        this.fetchAPI = fetchAPI.bind(this);
        this.drawFilterEntities = this.drawFilterEntities.bind(this);
        this.switchOperator = this.switchOperator.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.toggleEntitiesMenu = this.toggleEntitiesMenu.bind(this);
        this.noOptionsMessage = this.noOptionsMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadEntitiesOnInputChange = this.loadEntitiesOnInputChange.bind(this);
        this.fetchTotals = this.fetchTotals.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.resetEntities = this.resetEntities.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getColumnValues = this.getColumnValues.bind(this);
        this.getAttrValues = this.getAttrValues.bind(this);

        this.isLoading = false;
        this.isNumeric = false;

        this.filterObj = copyObjectValues(props.filterRowObj);
    }

    refresh(callback) {
        this.setState(this.state, function() {
            if(callback && typeof callback === "function") {
                callback();
            }
        });
    }
    
    toggleEntitiesMenu(toggle) {
        var _this = this;
        if (toggle === undefined) {
            toggle = !this.state.menuIsOpen;    //if toggle not sent, revert menuIsOpen
        }

        if (toggle !== this.state.menuIsOpen) {  //if true while already opened, or false while already closed, do not rerender
            this.setState({
                menuIsOpen: toggle
            }, function() {
                if(_this.selectRef) {
                    _this.selectRef.focus();
                }
            });
        }
    }

    drawFilterEntities(data, inputValue, callback) {
        data = data || [];
        var tempFunction = this.filterObj[_function];
        var keys = ["label", "value", "action"];
        var inputValObj = [inputValue, inputValue, "set-value"];
        inputValue = inputValue === undefined ? "" : inputValue;
        var optionsDisabled = false;

        var options = [];
        //to put entity into dropdown when data is received and disable others, if function is "contains"
        if ([_ct, _nct].includes(tempFunction)) {
            keys.push("isDisabled");  //the new key should be added AFTER adding the typed word to the options
            optionsDisabled = true;
        }

        //filter the quadrant values regarding the typed text
        if(inputValue !== "") {
            data = data.filter(v => v.label.toLowerCase().includes(inputValue.toLowerCase()));
        }

        data.map(function (item, key) {
            //if item has a label use it, else use the value
            var values = [item.label || item.value, item.value, "input-value", optionsDisabled];
            options.push(buildNewObject(keys, values));
        });

        this.isLoading = false;
        let optionsSet = new Set(options.map(e => JSON.stringify(e)));
        options = Array.from(optionsSet).map(e => JSON.parse(e));

        let inputOption = buildNewObject(keys, inputValObj);
        if (inputValue !== "") {
            let optionsExistsIndex = findIndexOfValue(options, "value", inputOption.value);
            if(optionsExistsIndex === -1 || [_ct, _nct].includes(tempFunction) || inputOption.value.includes(",")) {
                options.unshift(inputOption);   //if option doesn't exist, add it in the beginning
            } else {
                options.splice(optionsExistsIndex, 1, inputOption); //if option exists, replace it
            }
        }

        this.filterObj[_valueOptions] = options;
        if (callback && typeof callback === "function") {
            callback(options);
        } else {
            this.setState(this.state);
        }
    }


    disableEnableValues(data) {
        data = data || [];
        var tempFunction = this.filterObj[_function];
        var keys = ["label", "value", "action"];
        var optionsDisabled = false;

        var options = [];
        //to put entity into dropdown when data is received and disable others, if function is "contains"
        if ([_ct, _nct].indexOf(tempFunction) > -1) {
            keys.push("isDisabled");  //the new key should be added AFTER adding the typed word to the options
            optionsDisabled = true;
        }

        data.map(function (item, key) {
            //if item has a label use it, else use the value
            var values = [item.label || item.value, item.value, item.action, optionsDisabled];
            options.push(buildNewObject(keys, values));
        });

        return options;
    }

    handleMultiChange = selectedOptions => {
        var arrayOfValues = [];
        if (selectedOptions && typeof selectedOptions === "object" && !Array.isArray(selectedOptions)) {
            selectedOptions = [selectedOptions];
        }
        if (selectedOptions && selectedOptions.length) {
            selectedOptions.map(labelValObj => {
                if (labelValObj.action === "set-value" && labelValObj.value.includes(",")) {
                    let tempValues = labelValObj.value.split(",");
                    tempValues.map(stringVal => {
                        if (stringVal === "") {
                            return;
                        }
                        arrayOfValues.push({ label: stringVal.trim(), value: stringVal.trim(), name: labelValObj.name, type: labelValObj.type });
                    });
                } else if (labelValObj.tagName === "INPUT") {
                    arrayOfValues.push(labelValObj.value);
                } else {
                    arrayOfValues.push(labelValObj);
                }
            });
        } else {
            this.filterObj[_valueOptions] = [];
            this.state.updateOptions = true;
            this.loadEntitiesOnInputChange();
        }
        var uniq = new Set(arrayOfValues.map(e => JSON.stringify(e)));
        arrayOfValues = Array.from(uniq).map(e => JSON.parse(e));
        this.state.menuIsOpen = true;
        this.filterObj[_values] = arrayOfValues || [];  //when deleting all options, they're received as null
    };

    resetEntities(oldType, attr) {
        if (oldType && oldType !== this.filterObj[_file] && attr === _file) {
            this.filterObj[_valueOptions] = [];
            this.filterObj[_functionOptions] = [];
            this.filterObj[_columnOptions] = [];
            this.filterObj[_column] = "";
            this.filterObj[_field] = "";
            this.filterObj[_function] = "";
            this.filterObj[_values] = [];
            if (this.props.resetTotals) 
                this.props.resetTotals(); //mapping and tie off
        }

        if (oldType && oldType !== this.filterObj[_column] && attr === _column) {
            this.filterObj[_valueOptions] = [];
            this.filterObj[_functionOptions] = [];
            this.filterObj[_function] = "";
            this.filterObj[_values] = [];
            if (this.props.resetTotals) 
                this.props.resetTotals(); //mapping and tie off
        }

        if (oldType && oldType !== this.filterObj[_function] && attr === _function) {
            this.filterObj[_values] = [];
            if (this.props.resetTotals) 
                this.props.resetTotals(); //mapping and tie off
        }
    }

    getColumnValues(subtypeId, fieldDataType, value, index, functionValue, rowIndex, inputValue, type,callback,vectorId,filterAttribute) {
        var obj = this;
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
        this.isLoading = true;

        var dataBody = { 
            action: "getColumnValues",
            rawFileSubTypeId: subtypeId,
            field_dataType: fieldDataType,
            attribute: value,
            type:type,
            input_value : inputValue ? inputValue : "",
            scenario_id: this.props.scenario_id,
        };
       
        dataBody["filterAttribute"] = filterAttribute;
        dataBody["timePeriod"] = this.props.periodName;
        dataBody[VECTOR_STAGING_ATTRIBUTES.ID] = vectorId;
        
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = API_URL.DATA_MODELING;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{    
                if(response.status === 403) {
                }
                return response.json()})
            .then((data)=>{
                if(data.data !== null){
                    var values=[];
                    data.data.map(function(item, index){
                        if (![_nempty, _empty].includes(functionValue)) {
                            if (item.attribute !== "" && item.attribute !== undefined) {
                                values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                            }
                        } else {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    });
                    if(callback === undefined) {
                        obj.filter[index][rowIndex]["value_options"] =  values;
                        obj.filterObj[_valueOptions] = values;
                        if(Number(index)+1 === obj.filter.length && Number(rowIndex)+1 === obj.filter[index].length && obj.state.didMount) {
                            obj.state.didMount = false;
                        }
                        obj.setState({
                            ["values_"+index]: values,
                            ["function_"+index]: functionValue
                        });
                    } else {
                        obj.filterObj[_valueOptions] = values;
                        callback(values);
                    }
                }
            }).catch((error)=>{
                alertAndLogError(error);
            });
    }

    getAttrValues(filterObj, attr, dataFileType, fieldDataType, rowIndex, input_value, callback) {
        var obj = this;
        var selectedColumn = filterObj[_column];
        var isDisabled = [_ct, _nct].indexOf(filterObj[_function]) > -1 ? true : false;
        this.isLoading = true;
        
        if (rowIndex) {
            $(".value-loader-" + rowIndex).each(function () {
                $(this).removeClass("uk-hidden");
            });
        }
        var dataBody = { 
            action: "getAttributeValues",
            scenario_id: this.props.scenarioId,
            attribute: attr !== null ? attr : selectedColumn,
            fileType: dataFileType ? dataFileType : "", 
            isDefaultField: false,
            rawFileSubTypeId: filterObj[_rawFileSubtypeId] ? filterObj[_rawFileSubtypeId]: null, //rawfilesubtypeid
            field_dataType: fieldDataType ? fieldDataType : "", //STRING OR NUMERIC
            input_value: input_value ? input_value : "",
        };

        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = "/DataModeling";
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, { mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(dataBody) })
            .then((response) => {
                if (response.status === 403) {
                }
                return response.json()
            })
            .then((data) => {
                var values = [];

                if (data.data !== null) {
                    data.data.map(function (item) {
                        values.push({ label: item.attribute, value: item.attribute, action: "input-value", isDisabled: isDisabled })
                    });

                    obj.filterObj[_valueOptions] = values;
                    if (callback && typeof callback === "function") {
                        callback(values);
                    }
                }
            }).catch((error) => {
                alertAndLogError(error);
            }).then(()=>{
                if (rowIndex) {
                    $(".value-loader-" + rowIndex).each(function () {
                        $(this).addClass("uk-hidden");
                    });
                }
            });
    }

    fetchEntities(filterObj, functionValue, inputValue, callback){
        var column = filterObj[_column];
        var field = filterObj[_field];
        var query = { 
            action: "fetchEntities",
            scenario_id: this.props.scenario_id,
            field: field,
            column: column,
            inputValue: inputValue
        };
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
        let onThenCallback = (data) => {
            if(data.data) {
                var values=[];
                data.data.map(function(item, index){
                    if (![_nempty, _empty].includes(functionValue)) {
                        if (item.attribute !== "" && item.attribute !== undefined) {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    } else {
                        values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                    }
                });
                if(callback) {
                    callback(values);
                }
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchEntities",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }

    loadEntitiesOnInputChange(value, callback) {
        var comp = this;
        value = value ? value : "";
        clearTimeout(this.entitiesTimeout);
        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
        this.entitiesTimeout = setTimeout(function () {
            if (comp.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
                    comp.getAttrValues(comp.filterObj, comp.filterObj[_column], comp.filterObj[_dataFileType], comp.filterObj[_fieldDataType], comp.props.rowIndex, value, function (data) {
                        comp.drawFilterEntities(data, value, callback);
                });
            } else if(comp.props.stagingReport !== CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS && comp.props.stagingReport !== MANAGE_ACCESS.NAME) {
                let columnOptions = copyObjectValues(comp.filterObj[_columnOptions]);
                let vectorName = comp.filterObj[_column];
                let vectorNameModified =  comp.filterObj[_column].replace(FILTER.VALUES.FIELD.KEY,"").replace(FILTER.VALUES.FIELD.NAME,"").replace(FILTER.VALUES.FIELD.NUMBER,"");
                let filterAttribute =  FILTER.VALUES.FIELD.KEY;
                let vectorId = "0";
                let hiddenVectors = comp.props.hiddenVectors;
                if(comp.props.vectorOptions){
                    vectorId = comp.props.vectorOptions.filter(e=>e.value === vectorNameModified).length > 0 ? comp.props.vectorOptions.filter(e=>e.value === vectorNameModified)[0][VECTOR_STAGING_ATTRIBUTES.ID]:hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === vectorNameModified).length > 0 ? hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === vectorNameModified)[0][VECTOR_STAGING_ATTRIBUTES.ID]: "0";
                }
                if(vectorName.endsWith(FILTER.VALUES.FIELD.KEY)){
                    filterAttribute = FILTER.VALUES.FIELD.KEY;
                } else if(vectorName.endsWith(FILTER.VALUES.FIELD.NAME)){
                    filterAttribute = FILTER.VALUES.FIELD.NAME;
                } else {
                    filterAttribute = FILTER.VALUES.FIELD.NUMBER;
                }
                 let val = !isJson(comp.filterObj[_column]) && comp.filterObj[_rawFileSubtypeId] === 'vectors' ? columnOptions.filter(e=>e.value === comp.filterObj[_column])[0].usedValue : comp.filterObj[_column];
                let type = comp.filterObj.fileType;
                let raw_file_subtype_id = !isNaN(comp.filterObj[_rawFileSubtypeId]) && comp.filterObj[_rawFileSubtypeId] !== 'vectors' ? comp.filterObj[_rawFileSubtypeId] :  columnOptions.filter(e=>e.value === comp.filterObj[_column])[0][_rawFileSubtypeId]
                comp.getColumnValues(raw_file_subtype_id,  comp.filterObj[_fieldDataType], val, comp.filterObj[_rowPosition], comp.filterObj[_function], comp.props.conditionIndex, value, type ,function (data) {
                    comp.drawFilterEntities(data, value, callback);
                },vectorId,filterAttribute);
            }else if(comp.props.stagingReport === MANAGE_ACCESS.NAME) {
                comp.fetchEntities(comp.filterObj, comp.filterObj[_function], value,function(data){
                    comp.drawFilterEntities(data, value, callback);
                });

            }

            if(typeof comp.props.loadEntitiesFunc === "function") {
                comp.isLoading = true;
                if(!value) {
                    comp.setState(comp.state);  //this happens when deleting a selected value, show the loading message
                }
                comp.props.loadEntitiesFunc(comp.filterObj, undefined, value, function (data) {
                    comp.drawFilterEntities(data, value, callback);
                });
            }

        }, 500);     
    }

        
    handleChange(e, attribute, newFilter) {
        var comp = this;
        var oldVal = comp.filterObj[attribute];

        if(attribute !== _logicalOperator && e && oldVal && (e.length > 0 && oldVal.length === e.length || oldVal === e.value)) {    //if choosing same old value, do nothing
            return;
        }

        if (attribute !== _values || this.isNumeric) {
            if(attribute !== _logicalOperator && !newFilter){
                this.filterObj[attribute] = e.value;    //replace the object attribute value by the new one
            }
        }
    
        //to hide
        $(this.props.componentId+" #advanced-text").addClass("uk-hidden");
        $(this.props.componentId+" #advanced-filter-draggable").addClass("uk-hidden");
    
        //to show inline
        $(this.props.componentId+" #advanced-filter").removeClass("uk-hidden");
        $(this.props.componentId+" #advanced-filter").addClass("uk-display-flex");

        //removing the highlight on the type dropdownlist
        $(this.props.componentId+" #filterVector").removeClass("highlight-select");
        
        //run specific behaviors according to some conditions
        switch (attribute) {
            case _file:
                //also fill the filter's raw file subtype id
                this.isNumeric = false;
                this.filterObj[_rawFileSubtypeId] = e[_rawFileSubtypeId];
                this.filterObj[_dataFileType] = e[_dataFileType];
                this.state.updateOptions = true;
                break;

            case _column:
                //if in calculated, set isLoading true cz request to fetch values is sent on column change not function change
                // this.isLoading = this.props.stagingReport === STAGING_SECTIONS.CALCULATED_COLUMNS ? true : this.isLoading;
                this.filterObj[_fieldDataType] = e[_fieldDataType];
                this.state.updateOptions = true;
                this.isLoading = true;
                break;

            case _field:
                this.isLoading = this.props.stagingReport === STAGING_SECTIONS.CALCULATED_COLUMNS ? true : this.isLoading;
                this.state.updateOptions = true;
                break; 

            case _function:
                this.state.updateOptions = true;
                //if in calculated, do not set isLoading true cz request to fetch values is sent on column change not function change
                // this.isLoading = this.props.stagingReport === STAGING_SECTIONS.CALCULATED_COLUMNS ? this.isLoading : false;
                this.isNumeric = [filter_funcs.not_starts_with.value, filter_funcs.not_ends_with.value, filter_funcs.starts_with.value, filter_funcs.ends_with.value,
                    filter_funcs.greater.value, filter_funcs.less.value].includes(this.filterObj[_function])
                    || ([filter_funcs.equalsNUM.value, filter_funcs.not_equalsNUM.value].includes(this.filterObj[_function]) && (this.filterObj[_fieldDataType].toUpperCase() === Formats.Numeric));
                break;
            case _values:
                if(!this.isNumeric) {
                    this.handleMultiChange(e);
                }
                break;
            case _logicalOperator:
                if(!newFilter) {
                    this.filterObj[attribute] = e.value;
                }
                comp.props.onDropDownChange(comp.filterObj, attribute, comp.props.rowIndex, comp.props.conditionIndex, e);
                let callback = ()=>{};
                callback = function(){
                    if([STAGING_SECTIONS.PROFIT_STACK_MAPPING, CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS].includes(comp.props.stagingReport)) {
                        comp.resetEntities(oldVal, attribute);
                        if (comp.props.onDropDownChange && !(attribute === _function && comp.isNumeric)) {
                            comp.props.onDropDownChange(comp.filterObj, attribute, comp.props.rowIndex, comp.props.conditionIndex, e);
                        }
                    }
                }
                if (this.props.addNewFilter) {
                    this.props.addNewFilter(newFilter, attribute, e, callback);
                }
                break;
        }

        if ((attribute !== _logicalOperator
            && (attribute !== _values || comp.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING))
            || (attribute === _logicalOperator && this.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING)) {
            this.resetEntities(oldVal, attribute);
            if (this.props.onDropDownChange && !(attribute === _function && this.isNumeric) || this.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING || this.props.stagingReport=== MANAGE_ACCESS.NAME) {
                this.props.onDropDownChange(this.filterObj, attribute, this.props.rowIndex, this.props.conditionIndex, e);
            }
        }
        if (comp.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
            comp.setState({
                rowPosition:undefined
            },()=> {
                comp.props.resetRowTotals();
                comp.props.checkExclusionsValidity(comp.props.filterDialRef);
               
            })
           
        }

        comp.setState(comp.state); //to update the dropdowns

    }

    switchOperator() {
        var _this = this;
        this.filterObj[_logicalOperator] = this.filterObj[_logicalOperator] === _AND ? _OR : _AND;
        this.handleChange({ value: this.filterObj[_logicalOperator] }, _logicalOperator, false);
        this.props.resetParenthesisInRefs();    //reset advanced data
        this.setState(this.state, function(){
            if (_this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
                _this.setState({
                    rowPosition:undefined
                },()=> {
                    _this.props.resetRowTotals();
                })
               
            }
        });
    }

    handleInputChange(e) {
        let floatValue = $(e.currentTarget).val();
        if(this.filterObj[_fieldDataType] === Formats.Numeric) {
            floatValue = getNumericValue($(e.currentTarget).val());    //remove all non numeric characters
            $(e.currentTarget).val(floatValue);
        }
        this.filterObj[_values] = [{ value: floatValue, label: floatValue, action: "input-value" }]
        this.filterObj[_value] = [{value:floatValue, label:floatValue, action:"input-value"}]
    }

    removeFilter() {
        if(this.props.rowIndex === 0 && this.props.totalRows === 1 && this.props.stagingReport !== STAGING_SECTIONS.MAPPING_TIE_OFF) {
            this.filterObj = copyObjectValues(this.sampleFilterObj);
            if([STAGING_SECTIONS.PROFIT_STACK_MAPPING,CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS].includes(this.props.stagingReport)) {
                this.filterObj[_columnOptions] = this.props.filterRowObj[_columnOptions];
                this.filterObj[_file] = this.props.filterRowObj[_file];
            }
            this.props.refresh();
        } else {
            this.props.removeFilter(this.props.rowIndex, false, false);
        }

        if (this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING && this.props.removeFilterRowInCondition) {
            this.props.removeFilterRowInCondition(this.props.rowIndex)
        }
    }

    loadingMessage(obj) {
        return lang.filter_text.fetching_results;
    }

    noOptionsMessage(obj) {
        return this.isLoading ? lang.filter_text.fetching_results : lang.filter_text.no_results_found;
    }

    fetchTotals(onBlur, e) {
        if(e){
            if(e.which !== 13) {return;}
            else {
                //we blur explicitly because if user presses enter and then clicks outisde, the
                //onBlur event is fired and this function is called twice, instead we blur and
                //return, and let it be called only from onBlur event.
                e.target.blur();
                return;
            }
        }

        if (onBlur && (this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF && this.filterObj[_values] && this.filterObj[_values].length > 0)) {
            this.setState({
                rowPosition:undefined
            },()=> {
                this.props.resetRowTotals();
            })
        } else if(!onBlur && !e){
            if(this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF) {
                if(this.filterObj[_values] && this.filterObj[_values].length > 0) {
                    this.setState({
                        rowPosition:undefined
                    },()=> {
                        this.props.resetRowTotals();
                    })
                }
            } 
            if (!this.isNumeric) {
                this.toggleEntitiesMenu(false);
            }
        }
    }

    componentDidMount() {
        this.filterObj = copyObjectValues(this.props.filterRowObj);
        if (this.props.nextLogicalOperator) {
            this.filterObj[_logicalOperator] = this.props.nextLogicalOperator;
            this.props.clearLogicalOperator();
        }

        var comp = this;
        $(".filterDialogComponent").on("mousedown", function (e) {
            if (comp.state.menuIsOpen && e.target.nodeName !== "INPUT" && (!$(e.target).parents("[id^=values_"+comp.state.entitiesRndId+"]").length ||  $(e.target).parents("[class$=IndicatorsContainer]").length)) {
                if (comp.filterObj[_values] && !comp.filterObj[_values].length && [_ct, _nct].includes(comp.filterObj[_function]) && comp.filterObj[_valueOptions] && comp.filterObj[_valueOptions].length && !comp.filterObj[_valueOptions][0]["isDisabled"]) {
                    comp.handleChange(comp.filterObj[_values].concat(comp.filterObj[_valueOptions][0]), _values);
                }
                comp.fetchTotals(false);
            }
        });
    }
    
    handleSelectRef(select) {
        this.selectRef = select;
        if (select) {
            select.setState({
                isOpen: this.state.menuIsOpen
            })
        }
    }
    refresh = () => {
        this.forceUpdate();
    }

    render() {

        var files = this.props.stagingReport === MANAGE_ACCESS.NAME ? this.props.fileTypeOptions :arrangeFileNames(this.props.fileTypeOptions);

        const fileOptionLabel = ({ value, label, isDisabled  }) => (
            <div title={label} className={(isDisabled ? "option-group-header option-padding" : "") + " uk-text-overflow-ellipsis"}>
                {label}
            </div>
        );

        const optionLabel = ({ value, label}) => (
            <div title={label} className={"uk-text-overflow-ellipsis"}>
                {label}
            </div>
        );

        const columnOptionLabel = ({ data_file_type, name, label, isDisabled,value }) => (
            <div title={label?label:value} className={(isDisabled ? "option-group-header option-padding" : "") + " uk-text-overflow-ellipsis"}>
                {(label?label:value) + (name && name !== "" ? " (" + name + ")" : "")}
            </div>
        );
        const customStyles = {
            menu: styles => ({
                ...styles,
                width: convertPxToViewport(220)
            }),
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9),
            }),
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(33),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
                padding: '0 '+ convertPxToViewport(6)
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(33),
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            clearIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    color: "#C6CCD7",
                    lineHeight: convertPxToViewport(12)
                };
            }
        }
        var valueOptions = this.filterObj[_valueOptions] !== undefined && this.filterObj[_valueOptions].length > 0  && this.filterObj[_function]!== undefined && this.filterObj[_function] !== ""? this.disableEnableValues(this.filterObj[_valueOptions]) : [];
        valueOptions = valueOptions.filter(v=>v.value !== "");
        this.isNumeric = [filter_funcs.not_starts_with.value, filter_funcs.not_ends_with.value, filter_funcs.starts_with.value, filter_funcs.ends_with.value,
            filter_funcs.greater.value, filter_funcs.less.value].includes(this.filterObj[_function])
            || ([filter_funcs.equalsNUM.value, filter_funcs.not_equalsNUM.value].includes(this.filterObj[_function]) && this.filterObj[_fieldDataType].toUpperCase() === Formats.Numeric);

        var filterRow =
            <React.Fragment>
                { this.props.rowIndex  !== 0 ? 
                    <div id={"operator-"+this.filterObj[_logicalOperator]} className="uk-margin-default-left">
                        <button id={"filter-operator-" + (this.props.rowIndex + 1)} className="border-button width-fit-cont" onClick={this.switchOperator}>
                            {this.filterObj[_logicalOperator]}</button>
                        {this.filterObj && this.filterObj[_logicalOperator] === _OR ?
                            <div className="filter-separator"></div> //remove the after parenthesis condition
                        :""}   
                    </div>
                : ""}
                <div className="filter-container uk-display-flex" datarowindex={"filter-row-" + (this.props.rowIndex + 1)} key={"filterRow_" + Math.random()} >
                    <div className="width-40 uk-grab uk-display-flex align-items-center">
                        <div className="border-circle uk-margin-small-right-left">
                            <span>{this.props.rowIndex + 1}</span>
                        </div>
                    </div>
                    <div className="div-filter-container uk-margin-remove-bottom">
                        { this.props.showFileDropdown ?
                            <div className="width-150 uk-margin-small-right-left uk-height-fit">
                                <Select
                                    className={(this.filterObj[_file] ? "" : "input-warning")}
                                    classNamePrefix={"engine-filter-dialog"}
                                    name="fileType"
                                    placeholder={this.props.stagingReport === MANAGE_ACCESS.NAME ? "Type" : "File Name"}
                                    onChange={(e) => this.handleChange(e, _file, false)}
                                    options={files}
                                    value={findOptionByKey(this.props.fileTypeOptions, this.filterObj[_file])}
                                    formatOptionLabel={fileOptionLabel}
                                    isDisabled={this.props.fileTypeOptions && this.props.fileTypeOptions.length === 0 ? true : false}
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    maxMenuHeight={convertPxToViewport(190)}
                                />
                                {/* <DropDown // to remove comment and comment <Select
                                    className={(this.filterObj[_file] ? "" : "input-warning")}
                                    classNamePrefix={"engine-filter-dialog"}
                                    name="fileType"
                                    placeholder={this.props.stagingReport === MANAGE_ACCESS.NAME ? "Type" : "File Name"}
                                    onChange={(e) => this.handleChange(e, _file, false)}
                                    options={files}
                                    value={findOptionByKey(this.props.fileTypeOptions, this.filterObj[_file])}
                                    formatOptionLabel={fileOptionLabel}
                                    disabled={this.props.fileTypeOptions && this.props.fileTypeOptions.length === 0 ? true : false}
                                    menuPlacement="auto"
                                    maxMenuHeight={convertPxToViewport(190)}
                                    type = {DROPDOWN_TYPE.INPUT}
                                /> */}
                            </div> :""
                        }
                        <div className="width-150 uk-margin-small-right-left uk-height-fit">
                            <Select
                                className={(this.filterObj[_column] ? "" : "input-warning")}
                                classNamePrefix={"engine-filter-dialog"}
                                name="Column"
                                placeholder="Column"
                                onChange={(e)=>this.handleChange(e, _column, false)}
                                options={this.filterObj[_columnOptions]? this.filterObj[_columnOptions].filter(el=> el && el.label !== 'CountLines' && el.label !== 'Count'):""}
                                value={!this.filterObj[_column] ? "" : findOptionByKey(this.filterObj[_columnOptions], this.filterObj[_column])}
                                formatOptionLabel={columnOptionLabel}
                                isDisabled={(!this.filterObj[_file] && this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING) || !this.filterObj[_columnOptions] || this.filterObj[_columnOptions].length === 0 ? true : false}
                                styles={customStyles}
                                menuPlacement="auto"
                                maxMenuHeight={convertPxToViewport(190)}
                            />
                            {/* <DropDown //To remove comment and comment <Select
                                className={(this.filterObj[_column] ? "" : "input-warning")}
                                classNamePrefix={"engine-filter-dialog"}
                                name="Column"
                                placeholder="Column"
                                onChange={(e)=>this.handleChange(e, _column, false)}
                                options={this.filterObj[_columnOptions]? this.filterObj[_columnOptions].filter(el=> el && el.label !== 'CountLines' && el.label !== 'Count'):""}
                                value={!this.filterObj[_column] ? "" : findOptionByKey(this.filterObj[_columnOptions], this.filterObj[_column])}
                                formatOptionLabel={columnOptionLabel}
                                disabled={(!this.filterObj[_file] && this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING) || !this.filterObj[_columnOptions] || this.filterObj[_columnOptions].length === 0 ? true : false}
                                // styles={customStyles}
                                menuPlacement="auto"
                                maxMenuHeight={convertPxToViewport(190)}
                                type={DROPDOWN_TYPE.INPUT}
                            /> */}
                        </div>
                        {this.props.stagingReport === MANAGE_ACCESS.NAME ? 
                            <div className="width-150 uk-margin-small-right-left uk-height-fit">
                                <Select
                                    className={(this.filterObj[_field] ? "" : "input-warning")}
                                    classNamePrefix={"engine-filter-dialog"}
                                    name="Field"
                                    placeholder="Field"
                                    onChange={(e)=>this.handleChange(e, _field, false)}
                                    options={this.filterObj[_fieldOptions]}
                                    value={findOptionByKey(this.filterObj[_fieldOptions], this.filterObj[_field])}
                                    // formatOptionLabel={fieldOptionLabel}
                                    isDisabled={(!this.filterObj[_column] && this.props.stagingReport === MANAGE_ACCESS.NAME )?true : false}
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    maxMenuHeight={convertPxToViewport(190)}
                                />
                                {/* <DropDown //To remove comment and comment <Select
                                    className={(this.filterObj[_field] ? "" : "input-warning")}
                                    classNamePrefix={"engine-filter-dialog"}
                                    name="Field"
                                    placeholder="Field"
                                    onChange={(e)=>this.handleChange(e, _field, false)}
                                    options={this.filterObj[_fieldOptions]}
                                    value={findOptionByKey(this.filterObj[_fieldOptions], this.filterObj[_field])}
                                    formatOptionLabel={fieldOptionLabel}
                                    disabled={(!this.filterObj[_column] && this.props.stagingReport === MANAGE_ACCESS.NAME )?true : false}
                                    menuPlacement="auto"
                                    maxMenuHeight={convertPxToViewport(190)}
                                    type={DROPDOWN_TYPE.INPUT}
                                /> */}
                            </div>
                        :""}
                        <div className="width-150 uk-margin-small-right-left uk-height-fit">
                            <Select
                                className={(this.filterObj[_function] ? "" : "input-warning")}
                                classNamePrefix={"engine-filter-dialog"}
                                name="function"
                                placeholder="Function"
                                onChange={(e)=>this.handleChange(e, _function, false)}
                                options={this.filterObj[_functionOptions]}
                                value={findOptionByKey(this.filterObj[_functionOptions], this.filterObj[_function])}
                                formatOptionLabel={optionLabel}
                                isDisabled={!this.filterObj[_functionOptions] || this.filterObj[_functionOptions].length === 0 ? true : false}
                                styles={customStyles}
                                menuPlacement="auto"
                                maxMenuHeight={convertPxToViewport(190)}
                            />
                            {/* <DropDown //To remove comment and comment <Select
                                className={(this.filterObj[_function] ? "" : "input-warning")}
                                classNamePrefix={"engine-filter-dialog"}
                                name="function"
                                placeholder="Function"
                                onChange={(e)=>this.handleChange(e, _function, false)}
                                options={this.filterObj[_functionOptions]}
                                value={findOptionByKey(this.filterObj[_functionOptions], this.filterObj[_function])}
                                formatOptionLabel={optionLabel}
                                disabled={!this.filterObj[_functionOptions] || this.filterObj[_functionOptions].length === 0 ? true : false}
                                // styles={customStyles}
                                menuPlacement="auto"
                                maxMenuHeight={convertPxToViewport(190)}
                                type={DROPDOWN_TYPE.INPUT}
                            /> */}
                        </div> 
                        <div className="width-250 uk-margin-small-right-left uk-height-fit">
                        {this.isNumeric ?
                            <Input ref={r => {this.selectRef = r}} className={"form-control width-250 "}
                            onChange={(e)=>this.handleInputChange(e)}
                            onBlur={(e) => { 
                                this.handleChange(e.currentTarget, _values, false);
                                this.fetchTotals(true);
                            }}
                            onKeyPress={(e)=>this.fetchTotals(true, e)}
                            defaultValue={typeof this.filterObj[_values] === 'object' ? this.filterObj[_values][0] ? this.filterObj[_values][0].value : this.filterObj[_values] : this.filterObj[_values]}/> :
                                <AsyncSelect
                                    id={"values_" + this.state.entitiesRndId}
                                    className={(this.filterObj[_values] ? "" : "input-warning")}
                                    ref={(r) => {this.handleSelectRef(r)}}
                                    classNamePrefix="multiSelectNoWrap"
                                    name="value"
                                    placeholder={[_empty, _nempty].indexOf(this.filterObj[_function]) > -1 ? "" : "Value"} //no placeholder when the function chsen is empty or not empty
                                    onChange={(e) => this.handleChange(e, _values, false)}
                                    onMenuOpen={() => this.toggleEntitiesMenu(true)}
                                    onFocus={() => this.toggleEntitiesMenu(this.state.menuIsOpen)}
                                    tabSelectsValue={true}
                                    isLoading={this.isLoading}
                                    loadingMessage={this.loadingMessage}
                                    noOptionsMessage={this.noOptionsMessage}
                                    options={valueOptions}
                                    defaultOptions={valueOptions}
                                    value={this.filterObj[_values]}
                                    isMulti
                                    isSearchable
                                    closeMenuOnSelect={false}
                                    isDisabled={this.isLoading || [undefined, "", _empty, _nempty].includes(this.filterObj[_function])}
                                    formatOptionLabel={optionLabel}
                                    menuIsOpen={this.state.menuIsOpen}
                                    loadOptions={this.loadEntitiesOnInputChange}
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    maxMenuHeight={convertPxToViewport(190)}
                                />
                                // <DropDown //To remove comment and comment <AsyncSelect
                                //     id={"values_" + this.state.entitiesRndId}
                                //     className={(this.filterObj[_values] ? "" : "input-warning")}
                                //     classNamePrefix="multiSelectNoWrap"
                                //     name="value"
                                //     placeholder={[_empty, _nempty].indexOf(this.filterObj[_function]) > -1 ? "" : "Value"} //no placeholder when the function chsen is empty or not empty
                                //     onChange={(e) => this.handleChange(e, _values, false)}
                                //     onFocus={() => this.toggleEntitiesMenu(this.state.menuIsOpen)}
                                //     tabSelectsValue={true}
                                //     isLoading={this.isLoading}
                                //     loadingMessage={this.loadingMessage}
                                //     noOptionsMessage={this.noOptionsMessage}
                                //     options={valueOptions}
                                //     defaultOptions={valueOptions}
                                //     value={this.filterObj[_values]}
                                //     isMulti
                                //     isSearchable
                                //     closeMenuOnSelect={false}
                                //     disabled={this.isLoading || [undefined, "", _empty, _nempty].includes(this.filterObj[_function])}
                                //     formatOptionLabel={optionLabel}
                                //     menuIsOpen={this.state.menuIsOpen}
                                //     loadOptions={this.loadEntitiesOnInputChange}
                                //     menuPlacement="auto"
                                //     maxMenuHeight={convertPxToViewport(190)}
                                //     type={DROPDOWN_TYPE.INPUT}
                                //     isAsync={true}
                                // />
                            }
                        </div>
                        <img src='/images/FhHRx.gif' width={convertPxToViewport(15)} height={convertPxToViewport(15)} className={"value-loader-" + this.props.rowIndex + " uk-hidden uk-margin-xsmall-left"} />
                        <Button 
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className={this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ? "mapping-and-tie-off-delete-button uk-margin-xsmall-left align-items-center uk-display-flex uk-button-icon transparent-bg" : "" + "align-items-center uk-display-flex uk-button-icon transparent-bg" }
                            leftIcon={<i className="fa-lg fal fa-trash-alt"/>}
                            onBtnClick={() => this.removeFilter()}
                        />
                     
                    </div>
                </div>

                {this.props.isLastRow ?
                    <Operators handleChange={this.handleChange} />
                :""}
            </React.Fragment>
        return (filterRow);
    }
}

export default EngineFilterDialog;