import { Checkbox, DialogActions, FormControlLabel } from "@mui/material";
import PeriodsContainer from "../../periodsContainer/PeriodsContainer";

import NewBanner from "../../NewBanner";
import { ReactComponent as ErrorRoundedIcon } from "../../../styles/images/icons/errorRounded.svg";
import { ReactComponent as ErrorIcon } from "../../../styles/images/icons/error.svg";

import { formatDateMMDDYYY, getTranslationFile } from "../../../class/utils.js";
import Modal from "../../../newComponents/Modal.js";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../../class/networkUtils.js";
import Button from "../../../newComponents/Button.js";
import { forwardRef, useImperativeHandle, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES } from "../../../class/constants.js";
import { updatePeriodsStatus, updatePeriodsStatusState } from "../../../actions/scenariosActions.js";

const lang = getTranslationFile();

const Introduction = forwardRef((props,ref) => { 
      
  const {
    currPeriod,
    previousPeriod,
    startDate,
    endDate,
    bannerError,
    onSaveDate,
    isEndDateEditable,
    setChangesSaved,
    openChangesNotSaved,
    setOpenChangesNotSaved,
    customStepperRef,
    renderMonthlyBuildBanner,
    getMonthlyBuildPeriod,
    dispatch,
    publishedScenarioId,
    showRollbackButton
  } = props;

  const [openConfirmRevert, setOpenConfirmRevert] = useState(false);
  const [rollbackCheckbox, setRollbackCheckbox] = useState(false);
  const [cantEditCheckbox, setCantEditCheckbox] = useState(false);
  const [proceedError, setProceedError] = useState(false);

  const showPeriodsSetup = isEndDateEditable !== "";

    useImperativeHandle(ref, () => ({
        setOpenConfirmRevert: () => {
            return setOpenConfirmRevertParam(true);
        },
        allCheckboxesChecked : () =>{
            return allCheckboxesChecked();
        }
    }));

    const setOpenConfirmRevertParam = (param) => {
        setOpenConfirmRevert(param);
    }

  const confirmRevertModalBody = () => {
    return <h4>{lang.monthly_build.rollback.body.replace("[previousPeriod]", previousPeriod)}</h4>;
  };

  const confirmRevertModalActions = () => {
    return (
      <>
        <Button
          label={lang.monthly_build.rollback.rollback_btn}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={revertPublish}
        />

        <Button
          label={lang.monthly_build.rollback.dismiss_btn}
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => setOpenConfirmRevert(false)}
        />
      </>
    );
  };

  const allCheckboxesChecked = () => {
    let canProceed = true;
  
    // Check if rollback checkbox is required
    if (showRollbackButton) {
      canProceed = rollbackCheckbox;
    }
  
    // Check if 'i understand' checkbox is required
    if (showPeriodsSetup && isEndDateEditable === "true") {
      canProceed = canProceed && cantEditCheckbox;
    }
  
    setProceedError(!canProceed); // Show error message if user can't proceed
    return canProceed; // Return whether the user can proceed or not
  };

  /**
   * send request to revert publish
   */

  const revertPublish = () => {
    let query = {
      action: "revertMonthlyBuild", //It's still required by networkUtils.js
    };
    let onThenCallback = (data) => {
        if (data.success) {

            dispatch(updatePeriodsStatus([],publishedScenarioId));
            dispatch(updatePeriodsStatusState([],publishedScenarioId+"_"+12));

            getMonthlyBuildPeriod();
        }
    };

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "revertMonthlyBuild",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/revert-monthly-build",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    };
    setOpenConfirmRevert(false);
    setProceedError(false);
    fetchAPI(fetchOptions);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="h1-text large-padding-bottom">
        {lang.monthly_build.intro.update_model_with_period}
      </span>

      <span className="p-text large-padding-bottom">
        {lang.monthly_build.intro.monthly_build_description}
      </span>

      {showPeriodsSetup && (
        <PeriodsContainer
          currPeriod={currPeriod}
          previousPeriod={previousPeriod}
          startDate={formatDateMMDDYYY(startDate)}
          endDate={formatDateMMDDYYY(endDate)}
          isEndDateEditable={isEndDateEditable === "true"}
          onSaveDate={onSaveDate}
          setChangesSaved={setChangesSaved}
          openChangesNotSaved={openChangesNotSaved}
          setOpenChangesNotSaved={setOpenChangesNotSaved}
          customStepperRef={customStepperRef}
        />
      )}

      {showPeriodsSetup && (
        <>
          <span className="h2-text small-padding-bottom">
            {lang.monthly_build.intro.before_you_start}
          </span>
          <ul
            className="p-text medium-padding-bottom"
            style={{ listStyleType: "disc", marginLeft: "1.3vw" }}
          >
            <li>
              {lang.monthly_build.intro.make_sure_suggested_periods_are_ready.replace(
                "[period]",
                currPeriod
              )}
            </li>
            <li>{lang.monthly_build.intro.ensure_pl_available}</li>
          </ul>
        </>
      )}
      
     {showPeriodsSetup && isEndDateEditable === "true" && (
        <div style={{ display: "flex", flexDirection: "column" }} >
          <div style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
            <FormControlLabel
              sx={{marginRight: 0}}
              control={
                <Checkbox
                  disableRipple
                  checked={cantEditCheckbox}
                  size="medium"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={() => setCantEditCheckbox(!cantEditCheckbox)}
                />
              }/>
            <div className="p-text">
              {lang.monthly_build.intro.understand_checkbox_label}
            </div>
          </div>
        </div>
     )} 

     {/* checkbox of rollback */}
     {showRollbackButton && bannerError == "" && <>
            <div className="medium-margin-bottom" style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
                <FormControlLabel
                sx={{marginRight: 0}}
                control={
                    <Checkbox
                    disableRipple
                    checked={rollbackCheckbox}
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={() => setRollbackCheckbox(!rollbackCheckbox)}
                    />
                }/>
                <div className="p-text">
                {lang.monthly_build.intro.rollback_checkbox_label}
                </div>
            </div>
        </>}

        {proceedError && bannerError == "" && (
                <div className="error-text " style={{ paddingLeft: 0 }}>
                    <ErrorIcon />
                    <span style={{ marginLeft: 5 }}>
                        {lang.monthly_build.intro.checkbox_unchecked_error_msg.replace("[S]", showPeriodsSetup && isEndDateEditable === "true" && showRollbackButton ? "s" : "")}
                    </span>
                </div>
            )}

      {bannerError !== "" && (
        <NewBanner
          id={"intro-banner-error"}
          label={bannerError}
          icon={<ErrorRoundedIcon />}
          bannerClassName={"banner-error large-margin-bottom"}
          labelClassName={"banner-text red-text"}
        />
      )}
      {renderMonthlyBuildBanner()}
      <Modal //modal appears when the user clicks on rollback publish button
        key={"confirm-revert"}
        id={"confirm-revert"}
        title={lang.monthly_build.rollback.title.replace("%P%", previousPeriod)}
        openDialog={openConfirmRevert}
        bodyContent={confirmRevertModalBody}
        dialogActions={confirmRevertModalActions}
        closeClick={() => setOpenConfirmRevert(false)}
        size={DIALOG_SIZE.LARGE}
      />
    </div>
  );
})

export default Introduction;
