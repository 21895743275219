import { forwardRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as AutomatedFile } from "../../../styles/images/icons/automated.svg";
import { ReactComponent as CalendarsIcon } from "../../../styles/images/icons/calendars.svg";
import { ReactComponent as RequiredFileIcon } from "../../../styles/images/icons/required_file.svg";
import NewBanner from "../../NewBanner.js";
import TrelloBoard from "../../trelloBoard/TrelloBoard.js";
import FilterByCategory from "../FilterByCategory";

import { useEffect, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES, UPLOAD_SECTIONS } from "../../../class/constants";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../../class/networkUtils";
import { formatDateMMDDYYY, getTranslationFile, tryParse } from "../../../class/utils.js";
import Button from "../../../newComponents/Button";
import Modal from '../../../newComponents/Modal.js';
import { uploadRejectedMessage } from "../UploadHelpers";
const lang = getTranslationFile();

const UploadFiles = ({ period, startDate, endDate, user, machineName, isProceedBtnPressed, goToStep3, ignoreRequiredUploadAndProceed, renderMonthlyBuildBanner }, ref) => {
  const trelloBoardRef = useRef();
  const filterByCategoryRef = useRef();
  const [wrongNamingTemplates, setWrongNamingTemplates] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [show, toggleErrorsDialog] = useState(false);
  const [namingTemplate, setDialogNamingTemplate] = useState(false);
  const [messages, setMessages] = useState("");
  const [ignoreDialogVisibility, setIgnoreDialogVisibility] = useState(false);
  const [proceedDialogOpen, setProceedDialogOpen] = useState(false);
  const [rawFileId, setRawFileId] =  useState("");
 

  
  useEffect(() => {
    getBoardData();
  }, []);
  

  let bannerText = lang.monthly_build.upload_files.period_banner
  .replace("[start_date]", formatDateMMDDYYY(startDate))
  .replace("[end_date]", formatDateMMDDYYY(endDate))
  .split("[period]");

  useImperativeHandle(ref, () => ({
    proceedBtnPressed: () => {
      trelloBoardRef.current?.proceedBtnPressed();
    },
    canUserProceed: () => {
      return canUserProceed();
    },
    resetFilter: () => {
        filterByCategoryRef?.current?.resetFilter();
    },
    getBoardData: (callback) => {
        getBoardData(false, callback);
    }
  }));

  /**
   * Checks that all conditions are met to allow the user proceed to the next step
   */
  const canUserProceed = () => {
    let boardData = trelloBoardRef.current?.getBoardData();
    let hasNotUploadedRequiredFiles = false;
    let notUploadedTrxFile = [];
    let crossWalkHighlightedFiles = [];
    let filesHaveErrors = false;
    boardData.lanes.find(f => f.id === lang.monthly_build.upload_files.status.not_uploaded.value)
            ?.cards?.map((f) => {
                if(f.section == 'transaction_data'){
                    notUploadedTrxFile.push(f);
                } else if (f.required){
                    hasNotUploadedRequiredFiles = true;
                } else if (f.section === UPLOAD_SECTIONS.FIELDS.CROSSWALK && f.has_previous_crosswalk && f.has_previous_links &&  f.laneId === lang.monthly_build.upload_files.status.not_uploaded.value && !f.is_crosswalk_ignored){
                    crossWalkHighlightedFiles.push(f);
                } else if (f.is_rejected) {
                    filesHaveErrors = true;
                }
            }  ); 
    let hasPendingFiles = boardData.lanes.find(f => f.id === lang.monthly_build.upload_files.status.pending.value)?.cards?.length > 0;
    let hasWrongNamingFiles = wrongNamingTemplates.length > 0;
    let canUserProceed = !hasPendingFiles && !hasWrongNamingFiles && !filesHaveErrors && !crossWalkHighlightedFiles?.length && !notUploadedTrxFile.length

    if(canUserProceed && hasNotUploadedRequiredFiles) {
        trelloBoardRef?.current?.highlightRequiredFiles();
        if(hasNotUploadedRequiredFiles){
            setProceedDialogOpen(true);
        } else {
            return canUserProceed;
        }
    } else {
       trelloBoardRef?.current?.highlightRequiredFiles();
        return canUserProceed;
    }
    
  }

  const renderErrorBannerBody = () => {
    return <ul style={{ listStyleType: "disc", marginLeft: "1.3vw" }}>
      {wrongNamingTemplates?.map(file => {
        return <li key={file?.file_name} className="p-text">{file?.file_name}</li>
      })}
    </ul>
  }

  const getBoardData = (fromTimeInterval, callback) => {
    var query = {
      action: "getDatasourcesBoardData", //It's still required by networkUtils.js
      period: period,
    };
    let onThenCallback = (data) => {
      if (data) {
        setData(data);
        setWrongNamingTemplates(data.errors?.wrongNamingTemplates);
        if(!filterData){
            let filterByData = [{itemsList:[]}]
            data.filterData?.forEach(e=>{
                let obj = {};
                obj.value = e;
                obj.label = e;
                filterByData[0].itemsList.push(obj);
            });
            setFilterData(filterByData);
        }
        if(callback){
            goToStep3(callback);
        }
        
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getBoardData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: !fromTimeInterval,
      [FETCHAPI_PARAMS.path]: "/get-data-sources-board-data",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.periods]: period
    };
    fetchAPI(fetchOptions);
  };

  const ignoreCrosswalkFile = (rawFileId) => {
    var query = {
      action: "ignoreCrosswalkFile", //It's still required by networkUtils.js
      rawFileId: rawFileId,
    };
    let onThenCallback = () => {
      let boardData = data.data.filter(e=>e.has_previous_crosswalk === rawFileId)[0];
      boardData.is_crosswalk_ignored = true; //To remove highlight
      setData(data)
      setIgnoreDialogVisibility(false)
      launchExportToast()
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "ignoreCrosswalkFile",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/ignore-crosswalk-file",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    };
    fetchAPI(fetchOptions);
  };


  const errorsBodyContent = () => {
    if(data?.data?.length > 0 && namingTemplate){
        const section = data.data.filter(e => e.naming_template == namingTemplate)[0].display_section;
        const subsection = data.data.filter(e => e.naming_template == namingTemplate)[0].display_subsection;
    return (
      <div className="uk-border-rounded">
        <div
          className="h2-text uk-margin-small-left uk-text-bold"
          style={{ fontWeight: 400 }}
        >
          {subsection + lang.monthly_build.upload_files.see_errors_dialog_title}
        </div>
        <div className="p-text uk-margin-small-left uk-margin-small-top">
            {messages?
                <ul className="uk-list uk-list-disc">
                    {messages?.map((message, index) => (
                        <li key={index}>{message}</li>
                    ))}
                </ul>
        :""}
        </div>
        <div className="p-text uk-margin-small-left uk-margin-small-top uk-text-bold">
          {section.toLowerCase() === UPLOAD_SECTIONS.FIELDS.CROSSWALK.toLowerCase() ? lang.monthly_build.upload_files.see_errors_dialog_with_failed_crosswalk : lang.monthly_build.upload_files.see_errors_dialog_ending}
        </div>
      </div>
    );
  };
}
const ignoreBodyContent = () => {
  return (
    <div className="uk-border-rounded text ">
      <div className="h2-text uk-margin-small-left uk-text-bold"  style={{ fontWeight: 400 }}>
          {lang.monthly_build.upload_files.ignore_crosswalk_dialog_title}
      </div>
      <div className="p-text uk-margin-small-left uk-margin-small-top">
         {lang.monthly_build.upload_files.ignore_message_info}
      </div>
    </div>
  );
};

const proceedDialogContent = () => {
    return (
      <div className="uk-border-rounded text ">
        <div className="h2-text uk-margin-small-left uk-text-bold"  style={{ fontWeight: 400 }}>
            {lang.monthly_build.upload_files.proceed_dialog.title}
        </div>
        <div className="p-text uk-margin-small-left uk-margin-small-top">
           {lang.monthly_build.upload_files.proceed_dialog.body}
        </div>
      </div>
    );
  };


  const errorsDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => toggleErrorsDialog(false)}
      />
    );
  }

  const prepareErrorsDialog = (namingTemplate) => {
    setDialogNamingTemplate(namingTemplate);
    setErrorMessage(namingTemplate);
    toggleErrorsDialog(true);
  }
  const prepareIgnoreDialog = (previousCWRawFileId) => {
      setRawFileId(previousCWRawFileId)
      setIgnoreDialogVisibility(true);
  }
  
  const ignoreDialogActions = () => {
    return (
        <>         
            <Button  
                id={"confirm-action-dialog"}
                label={lang.monthly_build.upload_files.confirm}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => { ignoreCrosswalkFile(rawFileId) }}
            />
            <Button  
                id={"cancel-action-dialog"}
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="uk-modal-close"
                onBtnClick={()=>setIgnoreDialogVisibility(false)}
            />
        </>
    );
}
  
const proceedDialogActions = () => {
    return (
        <>         
            <Button  
                id={"confirm-action-dialog"}
                label={lang.monthly_build.upload_files.proceed_dialog.action_proceed}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={ () => proceedAndIgnoreRequired() }
            />
            <Button  
                id={"cancel-action-dialog"}
                label={lang.monthly_build.upload_files.proceed_dialog.action_cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="uk-modal-close"
                onBtnClick={ () => setProceedDialogOpen(false) }
            />
        </>
    );
}
  
    const proceedAndIgnoreRequired = () => {
        setProceedDialogOpen(false);
        ignoreRequiredUploadAndProceed()
    }

  const setErrorMessage = (namingTemplate) => {
    var response = tryParse(data.data.filter(e => e.naming_template === namingTemplate)[0].response);
    var isRejected = tryParse(data.data.filter(e => e.naming_template === namingTemplate)[0].is_rejected);
    var automationFailure = tryParse(data.data.filter(e => e.naming_template === namingTemplate)[0].is_automation_failure);
    var isAutomated = tryParse(data.data.filter(e => e.naming_template === namingTemplate)[0].is_automated);
    var validations = response?.validation;
    var isBeforeMonthlyBuild = validations?.filter(e => e.validation_status === "initial").length === 0;
    // the word "initial" was added while implementing this page, so to check if the files were uploaded before, we should check if it is found at least once
    var messages = [];
    if (!isBeforeMonthlyBuild && !isRejected ){
       
        for (var validation in validations){
            var elem = validations[validation].validation_code;
            if(["warning","fail"].includes(validations[validation].validation_status)){
                messages.push(lang.monthly_build.failed_upload_messages[elem]);
            }
        }
    }
    if(messages?.length === 0 || isRejected){ // if files are rejected, it has no response, so we get the invalidation message
        var message = uploadRejectedMessage(data?.errors?.uploadedTemplates?.filter(e => e.naming_template === namingTemplate)[0])
        if(message){
            messages.push(message);
        }
    }
    if( isAutomated && automationFailure) {
        messages.push(lang.monthly_build.rejected_upload_messages.automation_error);
    }
    setMessages(messages);
  }
   
  const launchExportToast=()=>{
    $("#toastIgnoreToast").addClass("show");
    setTimeout(function(){
        $("#toastIgnoreToast").removeClass("show");
    }, 4000);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div id="toastIgnoreToast" className={"toast toast-success"}>
            <div id="desc"><i className={"fa-lg fas uk-margin-small-right fa-check-circle greenText"} aria-hidden="true"></i><span>{lang.monthly_build.upload_files.crosswalk_ignored_toast_message}</span></div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "38.9vw"}}>
        <span className="p-text small-padding-bottom">
          {lang.monthly_build.upload_files.step_2}
        </span>
        <span className="h1-text small-padding-bottom">
          {lang.monthly_build.upload_files.upload_title}
        </span>

        <NewBanner
          label={
            <span>
              {bannerText[0]}
              <b>{period}</b>
              {bannerText[1]}
            </span>
          }
          bannerClassName={"banner-info banner-upload"}
          icon={<CalendarsIcon />}
          labelClassName={"banner-text yellow-text"}
        />

        {renderMonthlyBuildBanner()}

        <div className={"medium-margin-bottom"} style={{display: "flex", flexDirection: "row", placeContent: "space-between", width: "38vw"}}>
          <FilterByCategory ref={filterByCategoryRef} sectionsData={filterData} applyFilter={setAppliedFilter} />
          <Button
            id={"refresh-btn"}
            label={lang.monthly_build.upload_files.refresh_btn}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            leftIcon={<a uk-icon="refresh" />}
            onBtnClick={() => getBoardData()}
          />
        </div>
        <TrelloBoard
          ref={trelloBoardRef}
          className="medium-margin-bottom"
          currPeriod={period}
          user={user}
          machineName={machineName}
          isProceedBtnPressed={isProceedBtnPressed}
          getBoardData={getBoardData}
          data={data}
          appliedFilter={appliedFilter}
          toggleErrorsDialog={prepareErrorsDialog}
          toggleIgnoreDialog={prepareIgnoreDialog}
        />
        <div style={{
          display: "flex",
          flexDirection: "row"
        }} >
            <span className="medium-padding-bottom uk-margin-small-right">
            <RequiredFileIcon />
            <span className={"info-text"} style={{ paddingLeft: 6 }}>
                {lang.monthly_build.upload_files.required_file}
            </span>
            </span>
            <span className="medium-padding-bottom">
            <AutomatedFile />
            <span className={"info-text"} style={{ paddingLeft: 6 }}>
                {lang.monthly_build.upload_files.automated_file}
            </span>
            </span>
        </div>
        {wrongNamingTemplates?.length > 0 && 
          <NewBanner
            id="naming-template-did-not-match-banner"
            label={lang.monthly_build.upload_files.files_did_not_match_any_file_template}
            bannerClassName={"banner-error medium-margin-bottom banner-body"}
            labelClassName={"banner-text banner-title small-padding-bottom"}
            body={renderErrorBannerBody()}
          />
        }

        <span className="p-text medium-padding-bottom">
          {lang.monthly_build.upload_files.to_proceed_text}
        </span>
      </div>
      <Modal
        id={"see-errors-dialog"}
        openDialog={show}
        dialogActions={errorsDialogActions}
        bodyContent={errorsBodyContent}
        closeClick={() => toggleErrorsDialog(false)}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"ignore-dialog"}
        openDialog={ignoreDialogVisibility}
        closeClick={() => { setIgnoreDialogVisibility(false) }}
        bodyContent={ignoreBodyContent}
        dialogActions={ignoreDialogActions}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"proceed-dialog"}
        openDialog={proceedDialogOpen}
        closeClick={() => setProceedDialogOpen(false) }
        bodyContent={proceedDialogContent}
        dialogActions={proceedDialogActions}
        size={DIALOG_SIZE.MEDIUM}
      />

    </div>
  );
};

export default forwardRef(UploadFiles);
