import React, {useEffect, useState} from 'react';
import './Chatbot.css';
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";



const baseUrl = process.env.REACT_APP_BASE_URL;
const Chatbot = (props) => {
    const { agentId, projectId, agentLocation } = props;
    const [sessionId, setSessionId] = useState("");
    useEffect(() => {
        // Add the event listener
        window.addEventListener('df-audio-input-recorded', handleAudioInputRecorded);
        getSessionId();
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('df-audio-input-recorded', handleAudioInputRecorded);
        };
    }, []);


    useEffect(() => {
        // Set query parameters
        const queryParameters = {
            parameters: {
                url: baseUrl + "/chat-bot-get-vector",
                sessionId: sessionId,
                authority: baseUrl,
                origin: window.location.origin,
            }
        };
        const messenger = document.querySelector('df-messenger');
        messenger.setQueryParameters(queryParameters);
    }, [sessionId]);


    useEffect(() => {
        const handleRequestSent = (event) => {

            console.log('Request', event.detail.data.requestBody);
            //logout();

        };
        window.addEventListener('df-request-sent', handleRequestSent);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('df-request-sent', handleRequestSent);
        };
    });
    const getSessionId = () => {
        let query = { action: "getSessionId" };
        let onThenCallback = (data) => {
            if (data) {
                setSessionId(data.sessionId);
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getSessionId",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/get-session-id",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        fetchAPI(fetchOptions);
    };

    // Event listener for audio input
    const handleAudioInputRecorded = async (event) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            // Reset the audio state held in the chat.
            const messengerChat = document.querySelector('df-messenger-chat');
            messengerChat && messengerChat.setInput('');
            const audioSource = fileReader.result;
            const base64Data = audioSource.split(',')[1];
            const messenger = document.querySelector('df-messenger');
            const interceptor = (event) => {
                messenger.renderCustomText(
                    event.detail.raw.queryResult.transcript, false
                );
                messenger.removeEventListener('df-response-received', interceptor);
            };
            messenger.addEventListener('df-response-received', interceptor);
            await messenger.sendRequest('audio', base64Data);
        };
        fileReader.onerror = () => {
            console.log('Reading audio data failed');
        };
        fileReader.readAsDataURL(event.detail.data);
    };

    return (
        <df-messenger
            project-id={projectId}
            agent-id={agentId}
            location={agentLocation}
            intent="welcome"
            language-code="en"
        >
            <df-messenger-chat-bubble chat-title="Profit Isle Chatbot" enable-audio-input="true"></df-messenger-chat-bubble>
        </df-messenger>
    );
};

export default Chatbot;