import { getValidPeriods } from "../templateLayout/functions/periodFunctions";
import { getLocalStorageValueByParameter } from "./common";
import { FY_VALUES } from "./constants";
import { findOptionByKey, getTranslationFile } from "./utils";

function getActualYear(date) {
    return Number(getYearFromPeriodDefinition(date));
}

function getMonthName(monthIndex) {
    var monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    return monthNames[monthIndex];
}

function getPeriodTextFromMonth(date) {
    let month = date;
    if(date instanceof Date) {
        month = getMonthFromPeriodDefinition(date);
    }
	return "P" + (month+1 > 9 ? month+1 : "0" + (month+1));
}

function getPeriodFromDate(date){
    let period = getActualYear(date) + getPeriodTextFromMonth(date);
    return period;
}

function getPreviousYearPeriods(startPeriod, endPeriod, getAsPeriods = false) {
    let previousStart = new Date(startPeriod);
    previousStart.setYear(getActualYear(startPeriod) - 1);
    let previousEnd = new Date(endPeriod);
    previousEnd.setYear(getActualYear(endPeriod) - 1);

    return {
        start: getAsPeriods ? getPeriodFromDate(previousStart) : previousStart,
        end: getAsPeriods ? getPeriodFromDate(previousEnd) : previousEnd
    }
}

/**
 * get the period based on the definition of the period in modeling settings screen
 */
function getMonthFromPeriodDefinition (date){
    let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")):"";
    let actualPeriod = periods? periods.find(e=>date>=new Date(e.start_date) && date <=new Date(e.end_date)):"";
    return periods && actualPeriod? Number(actualPeriod.value.split("P")[1])-1: date.getMonth(); // -1 is added because getMonth() return 0 for Jan (the index of the month from 0-11) but Number(actualPeriod.value.split("P")[1]) will return the number of the month (1-12)
}

/**
 * get the year based on the definition of the period in modeling settings screen
 */
function getYearFromPeriodDefinition (date){
    let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")):"";
    let actualPeriod = periods? periods.find(e=>date>=new Date(e.start_date) && date <=new Date(e.end_date)):"";
    return periods && actualPeriod? Number(actualPeriod.value.split("P")[0]): date.getFullYear();
}

function monthDiff(d1, d2) {
    var months;
    months = (getYearFromPeriodDefinition(d2) - getYearFromPeriodDefinition(d1)) * 12;
    months -= getMonthFromPeriodDefinition(d1);
    months += getMonthFromPeriodDefinition(d2);
    return months <= 0 ? 0 : months;
}

function getPreviousAdjacentPeriods(startDate, endDate, getAsPeriods = false) {
    let deductMonths = monthDiff(startDate, endDate) + 1;
    let previousEnd = new Date(getYearFromPeriodDefinition(startDate), getMonthFromPeriodDefinition(startDate) - 1, startDate.getDate());
    let previousStart = new Date(getYearFromPeriodDefinition(startDate), getMonthFromPeriodDefinition(startDate) - deductMonths, startDate.getDate());

    return {
        start: getAsPeriods ? getPeriodFromDate(previousStart) : previousStart,
        end: getAsPeriods ? getPeriodFromDate(previousEnd) : previousEnd
    }
}

function getPeriodsObjectNew (periodsStatusState, clientPeriodsState, datasetState) {
    let periods = [];
    let periods_yoy = [];
    let periods_pa = [];
    let quarter = "";
    let quarterPre = "";
    let segmentPeriod = "";
    let months = FY_VALUES.M3;
    let startDate = periodsStatusState && clientPeriodsState ? getValidPeriods(periodsStatusState, clientPeriodsState).startDate : "";
    let endDate = periodsStatusState && clientPeriodsState ? getValidPeriods(periodsStatusState, clientPeriodsState).endDate : "";
  
    if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
        let periodsCount = monthDiff(startDate, endDate) + 1;
        periods = generatePeriods(startDate, periodsCount);
        periods_yoy =  generatePeriods(startDate, periodsCount,false);
        periods_pa = generatePeriods(startDate, periodsCount,true);
    }

    if (!!clientPeriodsState?.periods && isValidDate(startDate) && isValidDate(endDate)) {
        let firstQuarter = getQuarterFromDate(startDate);
        let endQuarter = getQuarterFromDate(endDate);

        let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
        let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);

        let generatedRange = getGeneratedQuarterRange(periodsStatusState.datasetOptions, firstQuarter, endQuarter);
        let generatedRangePre = getGeneratedQuarterRange(periodsStatusState.datasetOptions, firstPreQuarter, endPreQuarter);

        let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
        let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));

        quarter = fullQuarter.quarter;
        quarterPre = fullQuarterPre.quarter;
        months = fullQuarter.months;

        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
        segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
    }
    return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
}

function getPeriodsObject () {
    const _this = this;
    let periods = [];
    let periods_yoy = [];
    let periods_pa = [];
    let quarter = "";
    let quarterPre = "";
    let segmentPeriod = "";
    let months = FY_VALUES.M3;
    let startDate = this.getValidPeriods().startDate;
    let endDate = this.getValidPeriods().endDate;
  
    if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
        let periodsCount = monthDiff(startDate, endDate) + 1;
        periods = generatePeriods(startDate, periodsCount);
        periods_yoy =  generatePeriods(startDate, periodsCount,false);
        periods_pa = generatePeriods(startDate, periodsCount,true);
    }

    if (!!_this.state.periods && isValidDate(startDate) && isValidDate(endDate)) {
        let firstQuarter = getQuarterFromDate(startDate);
        let endQuarter = getQuarterFromDate(endDate);

        let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
        let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);

        let generatedRange = getGeneratedQuarterRange(this.state.datasetOptions, firstQuarter, endQuarter);
        let generatedRangePre = getGeneratedQuarterRange(this.state.datasetOptions, firstPreQuarter, endPreQuarter);

        let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
        let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));

        quarter = fullQuarter.quarter;
        quarterPre = fullQuarterPre.quarter;
        months = fullQuarter.months;

        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = _this.headerRef?.state?.actuallyBuiltPeriods?.map(m => m.label) || _this.state?.actuallyBuiltPeriods?.map(m => m.label);
        segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
    }
    return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
}

/**
 * returns the number of quarters that exist between
 * startQuarter and endQuarter
 * @param {*} startQuarter 
 * @param {*} endQuarter 
 */
function getQuarterDifference(startQuarter, endQuarter) {
    let year1 = Number(startQuarter.split("Q")[0]);
    let quarter1 = Number(startQuarter.split("Q")[1]);
    let year2 = Number(endQuarter.split("Q")[0]);
    let quarter2 = Number(endQuarter.split("Q")[1]);

    //ex: from 2018Q2 to 2020Q1
    let diffYears = year2 - year1;  //diffYears is 2
    if(quarter2 - quarter1 < 0) {   //1 - 2 = -1
        diffYears--;                //diffYears becomes 1
        return (4 - Math.abs(quarter2 - quarter1)) + diffYears * 4; // 4 - |-1| + 1 * 4 = 3 + 4 = 7
    } else {
        return (quarter2 - quarter1) + diffYears * 4;
    }
}

function getPeriodDifference(startPeriod, endPeriod) {
    let startYear = Number(startPeriod.split("P")[0]);
    let startPeriodNumber = Number(startPeriod.split("P")[1]);
    let endYear = Number(endPeriod.split("P")[0]);
    let endPeriodNumber = Number(endPeriod.split("P")[1]);
    
    // -1 cz one year is already being included in the below calculation "12 - startPeriodNumber" so that year should not be accounted for twice
    let diffYears = endYear - startYear - 1;

    if(startYear === endYear) {
        return endPeriodNumber - startPeriodNumber;
    } else {
        //calculate: beginning of the year in which endPeriod is in
        //end of year in which startPeriod is in
        //+1 to include startPeriodNumber
        //+ difference in years if more than 1 year is involved (2020 and 2017)
        return endPeriodNumber + (12 - startPeriodNumber + 1) + (diffYears * 12)
    }
}

const getFullQuarterFromStartEndQuarters = (startQuarter, endQuarter)=>{
    let startYear = Number(startQuarter.split("Q")[0]);
    let startQuarterNumber = Number(startQuarter.split("Q")[1]);
    let endYear = Number(endQuarter.split("Q")[0]);
    let endQuarterNumber = Number(endQuarter.split("Q")[1]);
    let counter;

    if (startYear === endYear) {
        counter = (endQuarterNumber - startQuarterNumber) + 1;
    } else {
        //4 - startQuarterNumber to get the difference from the previous year, +1 to include the start quarter
        //ex: if start quarter is Q2, i want 4-2 to return 3 for Q2, Q3 and Q4
        counter = (4 - startQuarterNumber) + 1 + endQuarterNumber;
    }
    let monthsValue = counter === 1 ? "" : counter === 2 ? FY_VALUES.M6 : counter === 3 ? FY_VALUES.M9 : counter === 4 ? FY_VALUES.FY : "" ;
    let fullQuarter = endYear + "Q" + endQuarterNumber + monthsValue;
    return fullQuarter;
}

function generatePeriods(startDate, periodCount, reverse) {
    let arr = [];
    let month = getMonthFromPeriodDefinition(startDate);
    let year = getYearFromPeriodDefinition(startDate);

    if(reverse !== undefined) {// pa or yoy
        if(reverse){// previous adjacent
            if(month - periodCount>0){// if after the substraction, the new month will still is the same year
                month -= periodCount;
            }else{// if after the substraction, the new month is in a different year
                year--; // go back one year 
                month += (12-periodCount); // add (12-count) months
            }
        }else{// year over year
            year--;
        }
    }

    for(let i=1; i <= periodCount; i++) {
        month++;
        if(month > 12) {
            month = month - 12;
            year = year + 1;
        }
        let prepend = month < 10 ? "P0" : "P";
        arr.push(year + prepend + month);
    }
    return arr;
}

function  generateQuarter(startQuarter, startYear, endQuarter, endYear, set) {
    var arr = [];

    if (!set) {
        return getFullQuarterFromStartEndQuarters(startYear+"Q"+startQuarter, endYear+"Q"+endQuarter);
    } else {
        if (startYear === endYear) {
            for (let i=startQuarter; i<= endQuarter; i++) {
                arr.push(startYear+"Q"+i);
            }
        }
        else{
            for (let i=startQuarter; i<= 4; i++) {
                arr.push(startYear+"Q"+i);
            }
            for (let i=1; i<= endQuarter; i++) {
                arr.push(endYear+"Q"+i);
            }
        }
        return arr;
    }
}

/**
 * this function returns the periods of a given quarter
 * ex: 2021Q2 ===> [2021P04,2021P05,2021P06]
 * with the skip parameter 2021Q2, 2021P04 ===> [2021P05,2021P06]
 * @param {*} quarter 
 * @returns 
 */
const getOtherPeriodsFromQuarter = (quarter, skipPeriod) => {
    let year = Number(quarter.split("Q")[0]);
    let quarterNumber = Number(quarter.split("Q")[1]);
    if(quarterNumber<=0 || quarter>4){
        return"";
    }
    let periods = [];
    for(let i = 1; i<=3; i++){
        let period = i +(3*(quarterNumber-1));
        if(period<10){
            period= "0"+period;
        }
        let periodName = year + "P" +period;
        if(skipPeriod !== periodName){
            periods.push(periodName);
        }
    }
    return periods;
}

const getPreviousQuarter = (quarter) => {
    let year = Number(quarter.split("Q")[0]);
    let quarterNumber = Number(quarter.split("Q")[1]);

    if(quarterNumber === 1) {
        year--;
        quarterNumber = 4;
    } else {
        quarterNumber--;
    }

    return year + "Q" + quarterNumber;
}

const getPreviousYear = (quarter)=>{
    let year = Number(quarter.split("Q")[0]);
    let finalQuarter = (year-1).toString() + "Q" + quarter.split("Q")[1];
    return finalQuarter;
}

const getPeriodQuarter = (period) => {
    let year = period.split("P")[0];
    let periodNumber = period.split("P")[1];
    let quarterNumber = Math.ceil(Number(periodNumber) / 3);

    return year + "Q" + quarterNumber;
}

const getQuarterFromDate = (date) => {
    return getPeriodQuarter(getPeriodFromDate(date));
}

function getGeneratedQuarterRange(allGeneratedQuarters, startQuarter, endQuarter) {
    //if the start quarter is NOT generated, return N/A
    let startRange = startQuarter;

    //if the end quarter is NOT generated but start quarter is, try to find the last generated quarter between the two
    let tempEndQuarter = endQuarter;
    let endRange = findOptionByKey(allGeneratedQuarters, tempEndQuarter);
    while(!endRange && tempEndQuarter > startQuarter) {
        tempEndQuarter = getPreviousQuarter(tempEndQuarter);
        endRange = findOptionByKey(allGeneratedQuarters, tempEndQuarter);
    }
    if(!endRange) {
        endRange = startRange;
    }

    startRange = typeof startRange === "object" ? startRange.value : startRange;
    endRange = typeof endRange === "object" ? endRange.value : endRange;
    return [startRange, endRange];
}

function isValidDate(d) {
   return d instanceof Date && !isNaN(d);
}

/**
 * this function extracts the value of the quarter and the value of months from
 * a fullQuarter value (ex: 2020Q1M6 -> {quarter: 2020Q1, months: M6})
 */
const extractFromFullQuarter = (quarterWithMonths) => {
    let tempObj = {}
    if(!quarterWithMonths.includes("M") && !quarterWithMonths.includes(FY_VALUES.FY)) {
        tempObj.quarter = quarterWithMonths;
        tempObj.months = FY_VALUES.M3;
    } else if(quarterWithMonths.includes(FY_VALUES.FY)) {
        tempObj.quarter = quarterWithMonths.split(FY_VALUES.FY)[0];
        tempObj.months = FY_VALUES.FY;
    } else {
        tempObj.quarter = quarterWithMonths.split("M")[0];
        tempObj.months = "M" + quarterWithMonths.split("M")[1];
    }

    return tempObj;
}

/**
 * Returns an array of periods that comes before the selected period
 * @param {*} builtPeriods 
 * @param {*} selectedPeriod 
 * @returns 
 */
const getPreviousBuiltPeriods = (builtPeriods, selectedPeriod) => {
  const periodIndex = builtPeriods.indexOf(selectedPeriod);
  if (periodIndex === -1 || periodIndex === builtPeriods.length - 1) {
    return [];
  }

  const previousPeriods = builtPeriods.slice(periodIndex + 1, builtPeriods.length);
  return previousPeriods;
}

/**
 * Returns an array of periods that come before the selected period, limited to the last {x} periods
 * @param {*} builtPeriods 
 * @param {*} selectedPeriod 
 * @returns 
 */
const getPreviousXPeriods = (periods, selectedPeriod, x, includesSelectedPeriod) => {
    const periodIndex = periods?.indexOf(selectedPeriod);
    if (!periods || periodIndex === -1 || periodIndex === periods.length - 1) {
      return [];
    }
    let previousPeriods = periods.slice(periodIndex + (includesSelectedPeriod?0:1), periods.length);
    previousPeriods = previousPeriods.slice(0, x);
    return previousPeriods;
  }

const getPastTwelveBuiltPeriods = (builtPeriods) => {
    let startPeriod = "";
    if (!builtPeriods || builtPeriods?.length === 0) {
        return null;
    }
    if (builtPeriods.length > 12) {                     // if built periods > 12 select index 11 as a start date
        startPeriod = builtPeriods[11];
    } else {                                            // if built periods < 12 select the last index as a start date
        startPeriod = builtPeriods[builtPeriods.length - 1];
    }
    return new Date(startPeriod.start_date);
}

/**
 * Returns the period that comes after the selected period
 * @param {*} builtPeriods 
 * @param {*} selectedPeriod 
 * @returns 
 */
const getNearestBuiltPeriod = (builtPeriods, selectedPeriod) => {
  let periodIndex = builtPeriods.indexOf(selectedPeriod);
  return builtPeriods[periodIndex - 1]; // we are going backwards because the periods are sorted desc
}

/**
 * Checks if the last selected period has 11 (this number is based on rollingNumb) previous built periods.
 * If not, it finds the next nearest built period that has 11 previous built periods.
 * @param {*} builtPeriods 
 * @param {*} lastSelectedPeriod 
 * @param {*} rollingNumb Number of rolling (12 or 3)
 * @returns the last selected built periods with 11 previous periods.
 */
const getLastBuiltPeriodForSegments = (builtPeriods, lastSelectedPeriod, rollingNumb) => {
  if (!builtPeriods || builtPeriods.length === 0) { return undefined; }

  if(builtPeriods.length < rollingNumb) { return builtPeriods[0]; }

  let previousPeriods = getPreviousBuiltPeriods(builtPeriods, lastSelectedPeriod);
  if (previousPeriods.length >= (rollingNumb - 1)) { // if previous periods are greater or equal than 11 and not 12, because the lastSelectedPeriod is included in the rollingNumb
    return lastSelectedPeriod;
  }

  let nextBuiltPeriod = getNearestBuiltPeriod(builtPeriods, lastSelectedPeriod);
  if (!nextBuiltPeriod) { return undefined };
  return getLastBuiltPeriodForSegments(builtPeriods, nextBuiltPeriod, rollingNumb);
}


/**
 * get the current date using the format : YYYYMMdd_hhmmssSSSa
 * @returns 
 */
function formatCurrentDate() {
	const now = new Date();
	// Get the meridiem (AM/PM)
	const meridiem = now.getHours() >= 12 ? 'PM' : 'AM';
	// Combine date, time, milliseconds, and meridiem
	const formattedDate = `${now.getFullYear().toString()+(now.getMonth()+1).toString().padStart(2, '0')+now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2,'0')+now.getMinutes().toString().padStart(2,'0')+now.getSeconds().toString().padStart(2,'0')+now.getMilliseconds().toString().padStart(2,'0')}${meridiem}`;
	return formattedDate;
}


/**
 * return the selected period or the set of selected periods (first_last) to be added in the excel file name
 * @param {*} periodsStr 
 * @returns 
 */
function getSelectedPeriodString (periodsStr) {
	if(!periodsStr || typeof periodsStr !== "string"){
		return "";
	}
	let periodsArr = periodsStr.split(",").filter(e=>e.trim());
	if(periodsArr?.length === 1){
		return periodsArr
	}else {
		return periodsArr[0] + "_" + periodsArr[periodsArr.length -1]
	}
}

export {getActualYear, getMonthName, getPeriodTextFromMonth, getPreviousYearPeriods, getPreviousAdjacentPeriods, getQuarterDifference, monthDiff,
    getFullQuarterFromStartEndQuarters, getPeriodFromDate, getPeriodDifference, generatePeriods, getQuarterFromDate, getGeneratedQuarterRange, extractFromFullQuarter, getPeriodQuarter,
     getPreviousYear, getPreviousQuarter, generateQuarter, isValidDate, getOtherPeriodsFromQuarter, getMonthFromPeriodDefinition, getYearFromPeriodDefinition, getPeriodsObject,
     getLastBuiltPeriodForSegments, getPreviousBuiltPeriods, getPeriodsObjectNew, getPastTwelveBuiltPeriods, formatCurrentDate, getSelectedPeriodString, getPreviousXPeriods};