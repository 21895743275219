import { Suspense, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useAuthenticated } from "../CustomHooks";
import ErrorBoundary from "../../ErrorBoundary";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import ManageCostCenters from "../../sections/ManageCostCenters";
import { Loader } from "../../form/elements";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { ReportHeader } from "../ReportHeader";
import { lang } from "../../language/messages_en";
import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES } from "../../class/constants";
import { logout } from "../../class/common";
import { getClientPeriods } from "../api/api";
import { getEngineScenarios } from "../api/apiDataModeling";
import { renderTitle } from "../functions/componentFunctions";

/**
 * @author Sandra Abdelkhalek
 * @description This component holds the the header of the Manage Cost Center & Manage Cost Center's component
 * where the header contains the report title and the save button with a reset button in case any change was made
 * and the body carrying the Manage cost center's
 * @param {*} props
 * @returns Report Header, Unsaved changes' dialog, Reset dialog & Manage cost center's body
 */
const ManageCostCentersWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;
  const { checkingForUnsavedChanges } = props;

  const profitFormat = ALL_WIDGETS.MANAGE_COST_CENTERS;
  const reportTitle = ALL_WIDGETS.TITLES.MANAGE_COST_CENTERS;

  /** References & react utility */
  const reportRef = useRef();
  const params = useParams();

  /** Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [scenariosState, setScenariosState] = useState([]);
  const [headerElements, setHeaderElements] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [divIdState, setDivIdState] = useState();
  const [destinationClientIdState, setDestinationClientIdState] = useState();
  const [isFileUploading, setIsFileUploading] = useState(false);

  /** Dialogs States */
  const [isResetDialogOpen, setResetDialogOpen] = useState(false);
  const [isCheckUnsavedChangesDialogOpen, setCheckUnsavedChangesDialogOpen] = useState(false);

  /**
   * @function useEffect()
   * @description on screen initialization if isAuthenticated is false user gets logged out
   * isAuthenticated is a boolean read from a custom hook named useAuthenticated
   **/
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /**
   * @function useEffect()
   * @description On component mount, fetch client periods & engine scenarios
   */
  useEffect(() => {
    getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
    getEngineScenarios(userAllowedMenuLinks, reportTitle, scenariosState, undefined, false, setScenariosState, true);
  }, []);

  /**
   * @function useEffect()
   * @description When clientPeriods are fetched and whenever the user makes a change, we rerender the header elements
   */
  useEffect(() => {
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, [hasUnsavedChanges, clientPeriodsState, isFileUploading]);

  /**
   * @function saveBtnClick()
   * @description This function is the onClick function of the save button.
   */
  const saveBtnClick = () => {
    reportRef.current?.saveChanges();
  };

  /**
   * @function getHeaderElements()
   * @description This function renders reportTile, save button and reset button in case the user makes any change
   * @returns array of components to be rendered in {@link ReportHeader} component
   */
  const getHeaderElements = () => {
    let headerElements = [];

    headerElements.push(
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div className="manage-cc-title" style={{ display: "flex", columnGap: "0.45vw", alignItems: "center" }}>
          {renderTitle(reportTitle)}
          <i className="fs-12 fal fa-info-circle uk-cursor-pointer cc-info-icon" uk-tooltip={lang.manage_cost_centers.tooltip_title} />
        </div>
        <div style={{ display: "flex", alignItems: "center", columnGap: "0.42vw" }}>
          {hasUnsavedChanges && (
            <div key={"reset-div"} style={{ display: "flex", columnGap: "0.42vw", alignItems: "center" }}>
              <span className="fs-14 danger-red">{lang.manage_columns.text.changes_not_saved}</span>
              <Button
                id="manage-cc-reset-btn"
                label={lang.modal.buttons.reset}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                uk-tooltip={"title:" + lang.discard_unsaved_changes + "; pos: bottom"}
                onBtnClick={() => setResetDialogOpen(true)}
              />
            </div>
          )}

            <span
                key={"save-btn"}
                uk-tooltip={!hasUnsavedChanges ? "title: Changes already saved; pos: bottom" : null}
                style={{ display: "flex", flexDirection: "row", columnGap: "8px"}}
            >
                {isFileUploading &&
                    <div>
                        <img src='/images/3dots.gif' className="height-30"/>
                    </div>
                }

                <div>
                    <Button
                        id="manage-cc-save-btn"
                        label={lang.navigation.btns.save}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        disabled={!hasUnsavedChanges}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={saveBtnClick}
                    />
                </div>

            </span>
        </div>
      </div>
    );

    return headerElements;
  };

  /**
   * @function discardChangesOnReset()
   * @description This function discards all changes made and closes the reset dialog
   */
  const discardChangesOnReset = () => {
    reportRef.current?.discardAllChanges();
    setResetDialogOpen(false);
    setHasUnsavedChanges(false);
  };

  /**
   * @function cancelAndCloseResetDialog()
   * @description This function cancels the reset action and closes the reset dialog
   */
  const cancelAndCloseResetDialog = () => {
    reportRef.current?.cancelReset();
    setResetDialogOpen(false);
  };

  /**
   * @function resetDialogActions()
   * @description This function renders the 'Reset' dialog's buttons
   * @returns dialog's buttons
   */
  const resetDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.discard}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={discardChangesOnReset}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={cancelAndCloseResetDialog}
        />
      </>
    );
  };

  /**
   * @function useEffect()
   * @description This function checks if the user has unsaved changes when he chooses to change report or to switch client.
   * In case he doesn't, he will be rerdirected to his chosen report, else a dialog opens to let him save/discard his changes before proceeding.
   */
  useEffect(() => {
    if (checkingForUnsavedChanges) {
      setDivIdState(props.divId);
      setDestinationClientIdState(props.destinationClientId);
      if (hasUnsavedChanges) {
        setCheckUnsavedChangesDialogOpen(true);
      } else {
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
      }
    }
  }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

  /**
   * @function closeUnsavedChangesDialog()
   * @description This function closes the unsaved changes' dialog
   */
  const closeUnsavedChangesDialog = () => {
    setCheckUnsavedChangesDialogOpen(false);
    setDestinationClientIdState(undefined);
    setDivIdState(undefined);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(false, false, false);
  };

  /**
   * @function onButtonNo()
   * @description This function is the on click function of the button 'No' in the 'Unsaved changes' dialog.
   * That's when the user decides to proceed without saving his changes.
   * It discards all changes and proceed in changing report/client
   */
  const onButtonNo = () => {
    setCheckUnsavedChangesDialogOpen(false);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
  };

  /**
   * @function onButtonYes()
   * @description This function is the on click function of the button 'Yes' in the 'Unsaved changes' dialog.
   * That's when the user decides to save his changes before proceeding.
   * It triggers the save changes fucntion and when the changes are saved successfully, the user will be redirected to his chosen report
   */
  const onButtonYes = () => {
    let cb = () => {
      props.setCheckingForUnsavedChanges(false);
      props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    };
    setCheckUnsavedChangesDialogOpen(false);
    reportRef?.current?.saveChanges(cb);
  };

  /**
   * @function unsavedChangesDialogActions()
   * @description This function renders the 'Unsaved changes' dialog's buttons
   * @returns dialog's buttons
   */
  const unsavedChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonYes}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonNo}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeUnsavedChangesDialog}
        />
      </>
    );
  };

  const setIsFileUploadingParam = (param) => {
    setIsFileUploading(param);
  }

  const showSkeleton = !(Object.keys(clientPeriodsState).length && headerElements?.length > 0);

  return (
    <ErrorBoundary>
      <Suspense fallback={<p id="loading">Loading...</p>}>
        {showSkeleton && <LoaderSkeleton />}
        <div id={"main-component-container"} className={"main-component-container"} style={{ "--banner-height": 0 }}>
          <div className={"header-banner-div-hidden"} />
          <Modal
            id="discardChangesModal-reset-btn"
            openDialog={isResetDialogOpen}
            bodyContent={() => <h3>This action will discard your unsaved changes.</h3>}
            dialogActions={resetDialogActions}
            closeClick={() => setResetDialogOpen(false)}
            size={DIALOG_SIZE.MEDIUM}
          />
          <Modal
            id={"manage-cost-center-discard-changes-dialog"}
            openDialog={isCheckUnsavedChangesDialogOpen}
            closeClick={closeUnsavedChangesDialog}
            bodyContent={() => <span className="fs-16">{lang.dashboards.messages.want_to_save_first}</span>}
            dialogActions={unsavedChangesDialogActions}
            size={DIALOG_SIZE.MEDIUM}
          />
          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            {
              <ManageCostCenters
                ref={reportRef}
                user={userSettings.user}
                userAllowedMenuLinks={userAllowedMenuLinks}
                periods={clientPeriodsState.allPeriods}
                scenarioId={scenariosState.scenario?.client_model_scenario_id}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setIsFileUploading={setIsFileUploadingParam}
              />
            }
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export { ManageCostCentersWrapper };
