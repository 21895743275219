import React, { Component } from 'react';
import { copyObjectValues, getTranslationFile, tryParse } from '../../class/utils.js';
import { INFO_MESSAGE, METRICS_MAPPING, ENGINE_FILTER, RAW_ITEMS, UPLOAD_SECTIONS, STAGING_SECTIONS_TITLES, STAGING_SECTIONS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, UPLOAD_FIELDS } from '../../class/constants';
import ValueColumnRow from './ValueColumnRow';
import ControlButtons from './ControlButtons';
import MetricColumn from './MetricColumn';
import { lang } from '../../language/messages_en';
import Popup from 'react-popup';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';

const MESSAGES = getTranslationFile();

const _fileType = ENGINE_FILTER.KEYS.FILE;
const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;

const sampleMetricObj = {
    [RAW_ITEMS.SUBTYPE_ID]: "",
    [RAW_ITEMS.SUBTYPE_NAME] : "",
    [RAW_ITEMS.FIELD_NAME]: ""
};

/**
 * Metrics Configuration on mapping each line
 * 
 * @author [Lara Jannoun]
 */
class MetricConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metricConfigurationRows: this.getNewMetricConfRefsArray(),  //initialized this way to start with at least one row
            initialFilter: this.getEmptyMetricSample(),
            arrayMessage: [],
            messageModal: "",
            duplicateRows: [],
            unusedRows: [],
            usedObject: { used: false, filter: [], conditions: [{filter: [], result: [], condNumber: []}], result: [] }
        };
        this.showAdvancedFilter = this.showAdvancedFilter.bind(this);
        this.addValueColumnRow = this.addValueColumnRow.bind(this);
        this.removeValueColumnRow = this.removeValueColumnRow.bind(this);
        this.filterFiles = this.filterFiles.bind(this);
        this.displayRows = this.displayRows.bind(this);
        this.updateMetricObjects = this.updateMetricObjects.bind(this);
        this.checkIfRowUsed = this.checkIfRowUsed.bind(this);
        this.checkIfJoinExists = this.checkIfJoinExists.bind(this);
        this.updateJoin = this.updateJoin.bind(this);
        this.getNewMetricConfRefsArray = this.getNewMetricConfRefsArray.bind(this);
        this.resetInitialMetricConf = this.resetInitialMetricConf.bind(this);
        this.getNewMetricRefObject = this.getNewMetricRefObject.bind(this);
        this.getEmptyMetricSample = this.getEmptyMetricSample.bind(this);
        this.setMetricConfiguartion = this.setMetricConfiguartion.bind(this);
        this.handleMetricRows = this.handleMetricRows.bind(this);
        this.removeFromObject = this.removeFromObject.bind(this);
        this.deleteRowAfterCheck = this.deleteRowAfterCheck.bind(this);
        this.returnConditionsInPopUp = this.returnConditionsInPopUp.bind(this);
        this.returnFiltersInpopup = this.returnFiltersInpopup.bind(this);
        this.setConfigurationObject = this.setConfigurationObject.bind(this);
        this.handleChangeOnUsedRow = this.handleChangeOnUsedRow.bind(this);
        this.checkIfRowExistsMultipleTimes = this.checkIfRowExistsMultipleTimes.bind(this);
        this.handleRowChange = this.handleRowChange.bind(this);

    this.valueColumnRows = [];
        this.lastUpdated = Date.now();

    }

    handleMetricRows(usedAndNotUsedRows) {
        var unusedRows = usedAndNotUsedRows.notUsedRowsArray;
        // var duplicateRows = usedAndNotUsedRows.duplicateRows;

        if (unusedRows.length > 0) {
            var message = "";
            var arrayMessage = [];
            for (var i = 0; i < unusedRows.length; i++) {
                var file =  unusedRows[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME] ?  unusedRows[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME] : 'CalculatedColumns';
                if(unusedRows[i][RAW_ITEMS.FIELD_NAME]=== undefined){
                    this.setInfoDialogOpen(true, MESSAGES.mandatory_vectors)
                    return;
                }else{
                    arrayMessage.push("Column "+ unusedRows[i].rowNumber + " (" + file + " - " + unusedRows[i][RAW_ITEMS.FIELD_NAME].replace("_cc","") + ")");
                }
            }

            if (unusedRows.length === 1) {
                message = lang.unused_columns_will_be_deleted_single;
            } else if (unusedRows.length > 1) {
                message = lang.unused_columns_will_be_deleted_many;
            }

            this.setState({
                messageModal: message,
                unusedRows: unusedRows,
                arrayMessage: arrayMessage
            }, function () {
              this.setOpenHandleMetricsRowDialog(true)
            })
        }

        // if (duplicateRows.length > 0 && unusedRows.length === 0) {
        //     var message = "";
        //     var arrayMessage = [];
        //     for (var i = 0; i < duplicateRows.length; i++) {
        //         arrayMessage.push("Column "+ " (" + duplicateRows[i][RAW_ITEMS.SUBTYPE_NAME] + "-" + duplicateRows[i][RAW_ITEMS.FIELD_NAME] + ")");
        //     }

        //     if (duplicateRows.length === 1) {
        //         message = lang.metrics_duplicate_value_columns;
        //     }

        //     this.setState({
        //         messageModal: message,
        //         duplicateRows: duplicateRows,
        //         arrayMessage: arrayMessage
        //     }, function () {
        //         window._profitIsleOpenModal('handleMetricRows');
        //     })
        // }
    }

    removeFromObject() {
        var _this = this;
        var metricConfigurationRows = this.state.metricConfigurationRows;
        // var rows = this.state.duplicateRows.length > 0 ? this.state.duplicateRows : this.state.unusedRows.length > 0 ? this.state.unusedRows : [];
        var rows = this.state.unusedRows.length > 0 ? this.state.unusedRows : [];

        for (var i = 0; i < rows.length; i++) {
            for (var j = 0; j < metricConfigurationRows.length; j++) {
                var row = metricConfigurationRows[j].current.state.metricConfigurationObject;
                if (rows[i][RAW_ITEMS.SUBTYPE_NAME] === row[RAW_ITEMS.SUBTYPE_NAME] && 
                    rows[i][RAW_ITEMS.FIELD_NAME] === row[RAW_ITEMS.FIELD_NAME]) {
                    metricConfigurationRows.splice(j, 1);
                    if (_this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration.length === 1) {
                        _this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration = this.getEmptyMetricSample();
                    } else {
                        _this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration.splice(j, 1);
                    }
                }
            }
        }

        this.setState({
            metricConfigurationRows: metricConfigurationRows
        }, function () {
            _this.props.saveMetricMapping();
            _this.setOpenHandleMetricsRowDialog(false)
        })
    }

    resetInitialMetricConf() {
        this.state.initialMetricConf = this.getEmptyMetricSample();
    }

    getNewMetricConfRefsArray() {
        return [this.getNewMetricRefObject()];
    }

    getNewMetricRefObject(){
        return { ref: React.createRef(), lastUpdated: Date.now().valueOf() };
    }
        /**
     * Returns a copy of the empty filter sample
     */
    getEmptyMetricSample(sampleType = "array") {
        if(sampleType === "array") {
            return [copyObjectValues(sampleMetricObj)];
        } else {
            return copyObjectValues(sampleMetricObj);
        }
    }

    /**
     * Adds a new empty metric conf object row
     */
    addValueColumnRow(newFilter) {
        var _this = this;
        var tempRefArray = this.state.metricConfigurationRows;
        if (newFilter) {
            tempRefArray.push(this.getNewMetricRefObject());
        }

        this.setState({
            metricConfigurationRows: tempRefArray,
        }, function () {
           if ( _this.ValueColumnRowRef.setDraggable) 
                _this.ValueColumnRowRef.setDraggable(); 
        });
    }

    //TODO: the below method should be rewritten in a cleaner way
    checkIfRowUsed(data, filter, rows, rowIndex, onDelete) {
        var deletedRow = rows[rowIndex] && rows[rowIndex].current && rows[rowIndex].current !== null ? rows[rowIndex].current.state.metricConfigurationObject : "";
        var filterRows =  filter !== "" && typeof (filter) === "string" ? tryParse(filter).filter : filter ? filter.filter : [];

        var conditions = data.conditions && data.conditions.length > 0 ? data.conditions : [];
        var result = data.result && data.result.length > 0 ? data.result : [];

        var usedObject = { used: false, filter: [], conditions: [], result: [], usedInformula: false };

        if (deletedRow !== "") {
            //checking if the row data exists if any of the condition formulas
            for (var i = 0; i < conditions.length; i++) {
                var formula = conditions[i].result ? conditions[i].result : [];
                var conditionRows = conditions[i].filters ? conditions[i].filters : [];
                var obj = { filter: [], result: [], condNumber: [] };

                for (var j = 0; j < formula.length; j++) {
                    var subtypeName = formula[j][RAW_ITEMS.SUBTYPE_NAME];
                    var colIndex = formula[j].colIndex;

                    if (deletedRow[RAW_ITEMS.SUBTYPE_NAME] === subtypeName
                        && colIndex && Number(colIndex) === (rowIndex+1)){
                        obj.result.push(j + 1);
                        usedObject.used = true;
                        usedObject.usedInformula = true;
                    }
                }

                for (var j = 0; j < conditionRows.length; j++) {
                    var subtypeName = conditionRows[j]["fileType"];
                    if (deletedRow[RAW_ITEMS.SUBTYPE_NAME] === subtypeName
                        && [UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(deletedRow[RAW_ITEMS.TYPE_NAME])
                        && rows.filter(e => e.current.state.metricConfigurationObject.file_type === deletedRow[RAW_ITEMS.SUBTYPE_NAME]).length === 1) {
                        obj.filter.push("Row " + (j + 1));
                        usedObject.used = true;
                    }
                }

                if(usedObject.used === true){
                    obj.condNumber.push(i + 1);
                    usedObject.conditions.push(obj);
                }
            }

            //checking if the row data exists in the final formula (formula if false: if available)
            for (var i = 0; i < result.length; i++) {
                var subtypeName = result[i][RAW_ITEMS.SUBTYPE_NAME];
                var colIndex = result[i].colIndex;
                if (deletedRow[RAW_ITEMS.SUBTYPE_NAME] === subtypeName
                    && colIndex && Number(colIndex) === (rowIndex + 1)){
                    usedObject.result.push(i+1);
                    usedObject.used = true;
                    usedObject.usedInformula = true;
                }
            }

            //checking if the row data exists in the metric configuration filter
            for (var i = 0; i < filterRows.length; i++) {
                if (filterRows[i]["fileType"] === deletedRow[RAW_ITEMS.SUBTYPE_NAME]
                    && rows.filter(e => e.current.state.metricConfigurationObject.file_type === deletedRow[RAW_ITEMS.SUBTYPE_NAME]).length === 1
                    && [UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(deletedRow[RAW_ITEMS.TYPE_NAME])
                 ) {
                    usedObject.filter.push(i+1);
                    usedObject.used = true;
                }
            }
        }

        this.setState({
            usedObject: usedObject,
            hasConditions: conditions.length > 0
        });
        
        return usedObject;
    }

    checkIfRowExistsMultipleTimes(rowIndex, data) {
        var rows = this.state.metricConfigurationRows;
        var duplicates = rows.filter(e => e.current && e.current !== null && e.current.state && e.current.state.metricConfigurationObject
                && e.current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_ID] === data[RAW_ITEMS.SUBTYPE_ID] && e.current.state.metricConfigurationObject[RAW_ITEMS.FIELD_NAME] === data.value).length;   
        
        //if we need to check if exists multiple times it should be > 1, whereas to check if it exists its enough to use >0
        if (duplicates > 0) {
            return true;
        }

        return false;
    }

    handleChangeOnUsedRow(rowIndex,onChangeValueColumn = false) {
        var data = this.props.getConditionFormula();
        var ifRowUsed = this.checkIfRowUsed(data, this.props.mappedMetric[METRICS_MAPPING.FIELDS.FILTER], this.state.metricConfigurationRows, rowIndex);
        // var ifRowExistsMultipleTimes = !this.checkIfRowExistsMultipleTimes(rowIndex);
        // if (!ifRowExistsMultipleTimes) {
        //     return false;
        // } else
        if((onChangeValueColumn && ifRowUsed.usedInformula) || (!onChangeValueColumn && ifRowUsed.used)) { //If you changed a value column while it is used in a formula or you did a change other than changing the vale column and it was used.
           if(onChangeValueColumn && ifRowUsed.usedInformula) {
                let usedObject = ifRowUsed;
                ifRowUsed.filter = [];
                this.setState({
                    usedObject:usedObject
                },()=>{
                    this.setOpenDeleteValueColumnDialog(true)
                })
           } else {
                this.setOpenDeleteValueColumnDialog(true)
           }
           return true;
        }

        return false;
    }

    removeValueColumnRow(rowIndex) {
        var returnAnswer = this.handleChangeOnUsedRow(rowIndex);
        if (!returnAnswer) {
            this.deleteRowAfterCheck(rowIndex);
        }
        this.props.checkForCostCenter();
    }

    deleteRowAfterCheck(rowIndex) {
        var metricConfigurationRows = this.state.metricConfigurationRows;
        if (rowIndex === 0 && metricConfigurationRows.length === 1) {
            metricConfigurationRows = this.getNewMetricConfRefsArray();
            this.ValueColumnRowRef.setConfigurationObject(this.getEmptyMetricSample("object"));
        } else {
            metricConfigurationRows.splice(rowIndex, 1); //to remove the row from the list
        }
        var obj = this;
        this.state.filterWasEdited = true;
        this.setState({
            metricConfigurationRows: metricConfigurationRows
        },function(){
            this.props.updateFiles(metricConfigurationRows, this.state.fileNames, obj.props.getMetricDataValidation);
            this.props.getMetricDataValidation();
        })
    }

    showAdvancedFilter(e) {
        this.props.showAdvancedFilter(e, this.state.metricConfigurationRows);
    }

    updateMetricObjects(obj, rowIndex, callback, event, type, disableBranch) {
        var _this = this;
        var metricConfigurationRows = _this.state.metricConfigurationRows;
        if (metricConfigurationRows[rowIndex].current && metricConfigurationRows[rowIndex].current !== null && metricConfigurationRows[rowIndex].current.state.metricConfigurationObject) {
            metricConfigurationRows[rowIndex].current.state.metricConfigurationObject = obj;
        } else {
            if (metricConfigurationRows[rowIndex].current && metricConfigurationRows[rowIndex].current !== null) {
               metricConfigurationRows[rowIndex].current.state.metricConfigurationObject = obj;
            } else {
                metricConfigurationRows[rowIndex] = this.getNewMetricRefObject();
                if ( metricConfigurationRows[rowIndex].current && metricConfigurationRows[rowIndex].current !== null) {
                    metricConfigurationRows[rowIndex].current.state.metricConfigurationObject = obj;
                } else {
                    metricConfigurationRows[rowIndex] = { current: { state: { metricConfigurationObject: obj } } };
                }
            }
        }
        var type =  metricConfigurationRows[rowIndex].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME];
        for (var e in metricConfigurationRows) {
            if (metricConfigurationRows[e].current) {
                if (metricConfigurationRows[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] === type) {
                    metricConfigurationRows[e].current.state.metricConfigurationObject["vector-column"] =
                    metricConfigurationRows.filter(row=>row.current && row.current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] === type)[0].current.state.metricConfigurationObject["vector-column"];
                }
            }
        }

        this.setState({
            metricConfigurationRows: metricConfigurationRows,
            index:rowIndex,
        }, function (){
            this.props.updateFiles(metricConfigurationRows, _this.state.fileNames, callback);
            if (!event) {
                this.props.checkForCostCenter(disableBranch);
            }
            if([UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES,UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(obj.data_file_type)){
                this.props.getMetricDataValidation(type);
            }
            
        });
    }

    checkIfJoinExists(joinsVariables, object) {
        for (var i = 0; i < joinsVariables.length; i++){
            if (object[RAW_ITEMS.SUBTYPE_NAME] === joinsVariables[i][RAW_ITEMS.SUBTYPE_NAME]) {
                return true
            }
        }

        return false;
    }

    updateJoin(index, value) {
        var tempArr = this.state.metricConfigurationRows;
        if (tempArr.length && tempArr[index].current.state) {
            tempArr[index].current.state.metricConfigurationObject["vector-column"] = value;
        }
        var type =  tempArr[index].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME];
        for (var e in tempArr) {
            if (tempArr[e].current) {
                if (tempArr[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] === type) {
                    tempArr[e].current.state.metricConfigurationObject["vector-column"] = value;
                }
            }
        }
        this.forceUpdate();
        this.props.getMetricDataValidation();
    }

    setMetricConfiguartion(metricProps) {
        var tempArr = [];
        for (var i in metricProps) {
            tempArr.push(this.getNewMetricRefObject());
        }
        for (var i in metricProps) {
            tempArr[i] = { current: { state: { metricConfigurationObject: metricProps[i] } } };
            tempArr[i].setConfigurationObject = this.ValueColumnRowRef.setConfigurationObject;
            tempArr[i].current.setDraggable = this.ValueColumnRowRef.setDraggable;
        }
        return tempArr;
    }

    displayJoins() {
        var joins = [];
        var joinsVariables = [];
        
        var loopedOverArr = this.state.metricConfigurationRows;
        for (var i in loopedOverArr) {
            i = Number(i);
            var ifJoinExists = loopedOverArr[i].current && loopedOverArr[i].current.state && loopedOverArr[i].current.state.metricConfigurationObject
                ? this.checkIfJoinExists(joinsVariables, loopedOverArr[i].current.state.metricConfigurationObject) : false;

            if (!ifJoinExists && loopedOverArr[i].current && loopedOverArr[i].current.state && loopedOverArr[i].current.state.metricConfigurationObject && loopedOverArr[i].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] && loopedOverArr[i].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] !== "") {
                var stringOutput = loopedOverArr[i].current.state.metricConfigurationObject.stringOutput;

                joinsVariables.push({ [RAW_ITEMS.SUBTYPE_NAME]: loopedOverArr[i].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.FIELD_NAME]: loopedOverArr[i].current.state.metricConfigurationObject[RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_DISPLAY_NAME]: loopedOverArr[i].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_DISPLAY_NAME]})
                joins.push(<MetricColumn key={i} showLoader={this.props.showLoader}  metricValidation={this.props.metricValidation} metricColumnObject={loopedOverArr[i].current.state.metricConfigurationObject} stringOutput={stringOutput}
                    metricConfiguration={loopedOverArr[i].current.state.metricConfigurationObject} rowIndex={i} mappedMetric={this.props.mappedMetric}
                    updateJoin={this.updateJoin} fileTypes={this.props.allTypeColumns} />)
            }
        }

        return joins;
    }

    displayRows() {
        var _this = this;
        var valueColumnRows = [];
        var loopedOverArr = this.state.metricConfigurationRows;

        for (var i in loopedOverArr) {
            i = Number(i);
            var metricObject = this.state.metricConfigurationRows[i] ||  _this.getEmptyMetricSample();
            valueColumnRows.push(<ValueColumnRow key={i} ref={el => this.ValueColumnRowRef = el} handleRowChange={this.handleRowChange} checkIfRowExistsMultipleTimes={this.checkIfRowExistsMultipleTimes} getEmptyMetricSample={this.getEmptyMetricSample} handleChangeOnUsedRow={this.handleChangeOnUsedRow} checkForCostCenter={this.props.checkForCenter} setConfigurationObject={this.setConfigurationObject} updateMetricObjects={this.updateMetricObjects} metricConfigurationObject={metricObject}
                metricConfigurationRows={this.state.metricConfigurationRows} rowIndex={i} addValueColumnRow={this.addValueColumnRow} calculatedCols={this.props.calculatedCols}
                removeValueColumnRow={this.removeValueColumnRow} fileNames={this.state.fileNames} allTypeColumns={this.props.allTypeColumns} costCenter={this.props.costCenter} mappedMetric={this.props.mappedMetric} metricObject={metricObject} getMetricDataValidation={this.props.getMetricDataValidation}
                setOpenConfirmToggleOffDialog={this.setOpenConfirmToggleOffDialog}
                />)
              
        }

        this.valueColumnRows = valueColumnRows;
        return valueColumnRows;
    }

    setConfigurationObject(object) {
        let _this = this;
        var tempArr = this.state.metricConfigurationRows;
        tempArr[this.state.index].current.state.metricConfigurationObject = object;
        this.setState({
            metricConfigurationRows: tempArr
        },function(){
            _this.props.checkForCostCenter();
            _this.setOpenConfirmToggleOffDialog(false)
        })
    }

    handleRowChange(disableBranch) {
        this.props.updateConfigFileCC(false);
        this.props.checkForCostCenter(disableBranch);
        this.forceUpdate();
        this.setOpenConfirmToggleOffDialog(false)
    }

    filterFiles(type, arr) {
        var output = [];
        if (type === RAW_ITEMS.SUBTYPE_NAME) {
            for(var i in arr) {
                if(output.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === arr[i][RAW_ITEMS.SUBTYPE_NAME]).length === 0){
                    output.push({value:arr[i][RAW_ITEMS.SUBTYPE_NAME], label:arr[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME], [RAW_ITEMS.SUBTYPE_ID]:arr[i][RAW_ITEMS.SUBTYPE_ID],
                         [RAW_ITEMS.SUBTYPE_NAME]: arr[i][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.TYPE_NAME]: arr[i][RAW_ITEMS.TYPE_NAME],[RAW_ITEMS.SUBTYPE_DISPLAY_NAME]: arr[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME] });
                }
            }
            output.push({label: STAGING_SECTIONS_TITLES.CALCULATED, value: _calcCols, 
                    [RAW_ITEMS.SUBTYPE_ID]: _calcCols, [RAW_ITEMS.SUBTYPE_NAME]: _calcCols
            });
        }
        this.setState({
            fileNames: output,
        });

        return output;
    }

    componentDidMount() {    
        var _this = this;
        this.setState({
            metricConfigurationRows: this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION] && this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION].length > 0 ? this.setMetricConfiguartion(this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration) : this.getNewMetricConfRefsArray(),
        }, function () {
            var allTypeColumns = this.props.allTypeColumns;
            _this.filterFiles(RAW_ITEMS.SUBTYPE_NAME, allTypeColumns);
            if (_this.ValueColumnRowRef)  
                _this.ValueColumnRowRef.setDraggable();
            if(this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION] && this.props.mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION].length > 0){
                this.props.updateFiles(this.state.metricConfigurationRows, this.state.fileNames);
                this.props.getMetricDataValidation(undefined, true);
            }    
        });
        if (this.props.toggleDisabled && this.props.hasRowCC === "false") {
            this.props.checkForCostCenter(true) //disable toggle button
        }
    }

    returnConditionsInPopUp() {
        var array = [];
        for (var i = 0; i < this.state.usedObject.conditions.length; i++){
            if (this.state.usedObject.conditions[i].filter.length > 0) {
                array.push(<span className="uk-display-block" key={i + "-popup-cond"}>
                {"Condition " + this.state.usedObject.conditions[i].condNumber[0] + ": "}
                {"" + this.state.usedObject.conditions[i].filter.join()}</span>);   
            }

            if (this.state.usedObject.conditions[i].result.length > 0) {
                array.push(<span className="uk-display-block" key={i + "-popup-cond"}>
                {"Condition " + this.state.usedObject.conditions[i].condNumber[0] + ": "}
                    {"Formula value if true"}</span>);  
            }
        }

        return array;
    }

    returnFiltersInpopup() {
        var array = [];
        var length = this.state.usedObject.filter.length;
            array.push(<span className="uk-display-block" key={"popup-filter"}>
                {("Filter"+ (length > 1 ? "s" : "") +": " + this.state.usedObject.filter.join())}</span>);
        return array;
    }
    
  setOpenDeleteValueColumnDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openDeleteValueColumnDialog: isOpen
    })
  }

  deleteValueColumnDialogActions = () => {
    return (
      <Button  
        label={MESSAGES.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenDeleteValueColumnDialog(false)}
    />
    )
  }
  deleteValueColumnDialogContent = () => {
    let conditionsInpopup = this.returnConditionsInPopUp();
    let filtersInpopup = this.returnFiltersInpopup();

    return (
      <h4>
        {MESSAGES.metrics_mapping_metric_configuration_delete_used_row}
        {
          this.state.usedObject.filter.length > 0 ?
            <div className="uk-margin-small">
              {filtersInpopup}
            </div>
            : ""
        }
        {
          this.state.usedObject.conditions.length > 0 ?
            <div className="uk-margin-small">
              <h4>{"Case Condition: "}</h4>
              {conditionsInpopup}
            </div>
            : ""
        }
        {
          this.state.usedObject.result.length > 0 ?
            <div className="uk-margin-small">
              <span className="uk-display-block">{this.state.hasConditions ? MESSAGES.formula.formula_drop.formula_false : MESSAGES.formula.formula_drop.formula}</span>
            </div>
            : ""
        }
        <span className="uk-display-block">{MESSAGES.proceed_message}</span>
      </h4>
    )
  }

  setOpenHandleMetricsRowDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openHandleMetricsRowDialog: isOpen
    })
  }

  handleMetricsRowDialogActions = () => {
    return (
      <>
        <Button
          id="submit-format-data-btn"
          label={MESSAGES.modal.buttons.continue}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.removeFromObject()}
        />
        <Button
          label={MESSAGES.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenHandleMetricsRowDialog(false)}
        />
      </>
    )
  }
  setOpenConfirmToggleOffDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openConfirmToggleOffDialog: isOpen
    })
  }

  confirmToggleOffDialogActions = () => {
    return (
      <>
        <Button
          label={"Continue"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.handleRowChange}
        />
        <Button
          label={MESSAGES.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenConfirmToggleOffDialog(false)}
        />
      </>
    )
  }
  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg
    })
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    )
  }

    render() {
        var valueColumnRows = this.displayRows();
        var joins = this.displayJoins();
        var object = { [RAW_ITEMS.SUBTYPE_ID]: "", [RAW_ITEMS.SUBTYPE_NAME]: "", [RAW_ITEMS.FIELD_NAME]: "" };
        var filter = this.props.mappedMetric[METRICS_MAPPING.FIELDS.FILTER];
        var definedFilter =  filter !== "" && typeof (filter) === "string" ? tryParse(filter).filter : filter ? filter.filter : [];

        var filterTextLinkColor = definedFilter.length > 0 ? "red" : this.props.mappedMetric[_fileType] === "" ? "uk-disabled text-grey" : "";
        var filterStatement = definedFilter.length > 0 ? MESSAGES.pss_map_exception.button.edit_filter : MESSAGES.pss_map_exception.button.add_filter;
        var filterIcon = definedFilter.length > 0 ? "fal fa-edit" : "fa-lg far fa-plus-circle";
        

        return (
            <div id="metric-configuration-container" className="w100">
                <div className="uk-display-flex uk-flex-middle uk-margin-default-bottom uk-margin-xmedium-top">
                    <span className="fs-14 uk-text-bold">{MESSAGES.metrics_mapping_configuration_title}</span>
                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={INFO_MESSAGE} />
                </div>
                <div className="uk-display-inline-flex w100">
                    <div id="value-columns-container" className="w100 value-columns-container uk-border uk-border-rounded uk-background-default padding-bottom-100px">
                        <div className="mapping-header uk-flex-between uk-padding-small-left-right">
                            <div className="uk-display-flex uk-flex-middle">
                                <span className="fs-14 uk-text-bold">{MESSAGES.metrics_mapping_value_columns_title}</span>
                                <i className="fal fa-info-circle uk-margin-xsmall-left fs-12" uk-tooltip={INFO_MESSAGE} />
                            </div>
                            <button className={"uk-button-icon"} onClick={(e)=>this.showAdvancedFilter(e)}>
                                <i className={(filterIcon) + (" uk-margin-small-right")} />
                                <span className={filterTextLinkColor}>{filterStatement}</span>
                            </button>
                        </div>
                        <div className="uk-margin-small-left">
                            <Button 
                                label={MESSAGES.calc_cols.mapping.columns_container.add_column}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"uk-margin-small-top uk-margin-small-bottom"}
                                leftIcon={<i className="fa-lg far fa-plus-circle" aria-hidden="true" />}
                                onBtnClick={this.addValueColumnRow}
                            />
                            <div className="uk-margin-xmedium-left">
                                <div className="uk-display-inline-flex uk-flex-middle input-width-200 mrgl35 dropdown-margin-right">
                                    <span className="fs-14">{MESSAGES.metrics_mapping_file_name_title}</span>
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={"TBD"} />
                                </div>
                                <div className="uk-display-inline-flex uk-flex-middle input-width-200">
                                    <span className="fs-14">{MESSAGES.metrics_mapping_value_column_title}</span>
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={"TBD"} />
                                </div>
                            </div>
                            {valueColumnRows}
                        </div>
                    </div>
                    <div className="control-buttons-container">
                        <ControlButtons metricConfiguration={true} appendToFormula={this.props.appendToFormula} /*isAdvanced={this.isAdvanced}*/ />
                    </div>
                </div>
                <div id="metricJoins" className="uk-display-grid">
                    {joins}
                </div>
              
               
                <Modal
                  id={"delete-value-column-row-dialog"}
                  openDialog={this.state.openDeleteValueColumnDialog}
                  bodyContent={this.deleteValueColumnDialogContent}
                  dialogActions={this.deleteValueColumnDialogActions}
                  closeClick={() => this.setOpenDeleteValueColumnDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"handle-metrics-rows-dialog"}
                  openDialog={this.state.openHandleMetricsRowDialog}
                  bodyContent={() => <h4>{this.state.arrayMessage && this.state.arrayMessage.length > 0 ? this.state.arrayMessage.join(',') + this.state.messageModal : ""}</h4>}
                  dialogActions={this.handleMetricsRowDialogActions}
                  closeClick={() => this.setOpenHandleMetricsRowDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"confirm-toggle-off-dialog"}
                  openDialog={this.state.openConfirmToggleOffDialog}
                  bodyContent={() => <h4>{MESSAGES.toggle_off.replace(new RegExp('\\[Branch]', 'g'), this.props.costCenter)}</h4>}
                  dialogActions={this.confirmToggleOffDialogActions}
                  closeClick={() => this.setOpenConfirmToggleOffDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
          </div>
        );
    }

}

export default MetricConfiguration;