
import React, { Component } from "react";
import { PROFILE_COLUMN, MANAGE_COLUMNS, ALL_REPORTS, ALL_WIDGETS, COLUMN_PROFILE, SELECTED_VECTOR_VIEWS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DASHBOARDS, PSL_RETURN_NAMES} from '../class/constants';
import { lang } from "../language/messages_en";
import Button from "../newComponents/Button";
import SwitchToggle from "../newComponents/SwitchToggle";
/**
 * Dynamic list with toggles buttons
 * @author [Sarah Farjallah]
 */
// const _profileName = COLUMN_PROFILE.NAME;
const _returnName = PROFILE_COLUMN.RETURN_NAME;
const _selectedVectorType = MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME;
// const _creatorName = COLUMN_PROFILE.CREATOR_NAME;
// const _visibility = COLUMN_PROFILE.VISIBILITY;
// const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;

const _name = SELECTED_VECTOR_VIEWS.NAME;
const _number = SELECTED_VECTOR_VIEWS.NUMBER;
const _quadrant = SELECTED_VECTOR_VIEWS.QUADRANT;
const _quadrantTier = SELECTED_VECTOR_VIEWS.QUADRANT_TIER;
const segmentRolling = {"quadrant": "3", "quadrant_tier": "12"}
class DropDownContainer extends Component {
    constructor(props) {
        super(props);
        this.state={
            checkedItems:[],
            elements:[]
        }
        this.toggleElement = this.toggleElement.bind(this);
        // this.getData = this.getData.bind(this);
        this.onDeleteColumn = this.onDeleteColumn.bind(this);
    }

    componentDidMount() {
        this.setState({
            viewOptions: this.returnViewOptions()
        })
    }

    // TODO @georges
    // toggleElement(viewId, returnName, checked, type, machineName, visibility, name, groupName, 
    //     column_group, column_group_line, column_type,column_type_line, formula){
    //     this.props.updateCheckedItems(viewId, returnName, checked, type, machineName, visibility, name, groupName,
    //          column_group, column_group_line, column_type, column_type_line, formula);
    // }
    toggleElement(viewId, viewMachineName, groupName, isChecked, columnData, viewOrder){
        if(this.validateCheckedItems(this.props.checkedItems, viewMachineName, columnData[_returnName], isChecked)) {
            this.props.updateCheckedItems(viewId, viewMachineName, groupName, isChecked, columnData, viewOrder);
        }
    }

    editColumn(){
        // TODO @georges
        // let data = this.props.profileColumnData;
        // let columnName = data.columnName;
        // let customGroup = data.customGroup;
        // let description = data.description;
        // let visibility = data.visibility;
        // let formatType = data.formatType;
        // let columnFieldId = data.columnFieldId;
        // let value = data.value;
        // let column_type =data.column_type;
        // let column_type_line = data.column_type_line;
        // let column_group_line = data.column_group_line;
        // let column_group = data.column_group;
        // let formatId = this.props.formatId;
        // let formula =this.props.formula;
        // this.props.editColumn(columnFieldId,columnName,customGroup,description,formatType,visibility,value,formatId,formula,column_type,column_type_line,column_group_line,column_group);
        this.props.editColumn(this.props.groupName, this.props.profileColumnData);
    }

    onDeleteColumn() {
        this.props.onDeleteColumn(this.props.groupName, this.props.profileColumnData);
    }

    // getData() {
    //     const _this = this;

    //     let creatorName = this.props.profileColumnData[_creatorName];
    //     let visibility = this.props.profileColumnData[_visibility];
    //     let displayName = this.props.profileColumnData[COLUMN_PROFILE.DISPLAY_NAME];
    //     let colReturnName = this.props.profileColumnData[_returnName];
    //     let columnFieldId = this.props.profileColumnData[PROFILE_COLUMN.COLUMN_FIELD_ID];
    //     // this.props.isColumnInList(creatorName, visibility, displayName, value, columnFieldId, this.props.groupName, false);
    //     let colsWithSameName = this.props.getColumnsWithSameName(creatorName, visibility, displayName, colReturnName);


    //     let itemsText = [];
    //         itemsText.push(<div key="a" className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall uk-margin-large-right-left">
    //                 <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
    //                 <div className="fs-16">{lang.manage_columns.text.column_in_saved_list}</div>
    //             </div>
    //         );

    //         let usedProfiles = [];
    //         // if(result) {
    //             var prfs = [];
    //             colsWithSameName.forEach(prf => {
    //                 let title = prf[_visibility] === _visibilityOptions.COMPANY ? "By Company" : "You";
    //                 let icon = prf[_visibility] === _visibilityOptions.COMPANY ? "fa-users" : "fa-user";
    //                 if (!prfs.includes(prf[_profileName]+"-"+prf[_creatorName])) {
    //                     usedProfiles.push(<React.Fragment><span>{usedProfiles.length > 0 ? ", " : ""}</span><i title={title} className={"fa-lg fal "+icon+" uk-margin-small-right"}></i><span>{prf[_profileName] + " by " + prf[_creatorName]}</span></React.Fragment>);
    //                     prfs.push(prf[_profileName] +" - "+ prf[_creatorName]);
    //                 }
    //             });
    //         // }

    //         itemsText.push(
    //             <div key="b" className="uk-flex-center uk-padding-large fs-14">
    //                 {usedProfiles.length ? 
    //                     <div className="uk-margin-default-bottom">
    //                         <span>{lang.manage_columns.text.column_saved_in}</span>
    //                         {usedProfiles}
    //                     </div>
    //                     : ""
    //                 }
    //                 <div className="uk-margin-default-bottom">
    //                     <span className="text-grey col-md-3">{lang.manage_columns.titles.name}</span>
    //                     <span>{displayName}</span>
    //                 </div>
    //                 <div className="uk-margin-default-bottom">
    //                     <span className="text-grey col-md-3">{lang.manage_columns.titles.created_by}</span>
    //                     <span>{creatorName}</span>
    //                 </div>
    //                 <div className="uk-margin-default-bottom">
    //                     <span className="text-grey col-md-3">{lang.manage_columns.titles.visibility}</span>
    //                     <span>{visibility === _visibilityOptions.COMPANY ? 
    //                         <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"></i>
    //                         :   visibility === _visibilityOptions.USER ?
    //                             <i title="You" className="fa-lg fal fa-user uk-margin-small-right"></i> 
    //                             :""
    //                         }</span>
    //                 </div>
    //             </div>
    //         );

    //         _this.props.modalRef.setElementsList(itemsText);
    //         _this.props.modalRef.showModal(lang.manage_columns.titles.delete_column, "", 
    //             lang.modal.buttons.delete, ()=>{
    //                 setTimeout(()=>{
    //                     // _this.deleteField(columnFieldId, customGroup);
    //                     _this.props.modalRef.setElementsList([]);
    //                 }, 200);        //to give the modal some time to hide before showing the next one so they don't collide
    //             }, lang.modal.buttons.cancel, ()=>{});
    // }

    validateCheckedItems(checkedItems, returnName, type, isChecked){
        let allowUncheck = true;
        if(isChecked) {
            return true;
        }
        
        if(type === _selectedVectorType){
            if([ALL_WIDGETS.FIELDS.LIST,ALL_REPORTS.LIST_TOP, ALL_REPORTS.HEATMAP, ALL_REPORTS.CONTOUR_MAP].includes(this.props.profitFormat)){
                let selected_vectors = checkedItems.filter(e=>e[_returnName] === _selectedVectorType && [_name, _number, _quadrant, _quadrantTier].includes(e[PROFILE_COLUMN.MACHINE_NAME]));
                if(selected_vectors.length < 2) {
                    if(returnName === _name || returnName === _number || returnName === _quadrantTier || returnName === _quadrant){
                        allowUncheck = false;
                    }
                }
            } 
            if([DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS].includes(this.props.profitFormat)){
                let selected_vectors = checkedItems.filter(e=>e[_returnName] === _selectedVectorType && [_name, _number].includes(e[PROFILE_COLUMN.MACHINE_NAME]));
                if(selected_vectors.length < 2) {
                    if(returnName === _name || returnName === _number){
                        allowUncheck = false;
                    }
                }
            }
            // if([ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS].includes(this.props.profitFormat)){
            //   if(returnName === _name || returnName === _number || returnName === _quadrantTier){
            //     allowUncheck = false;
            //   }
            // }
            // ** no longer needed 
            // else if (this.props.profitFormat ===  ALL_REPORTS.LIST_QUADRANT && returnName === _quadrant){
            //     allowUncheck = false;
            // } else if (this.props.profitFormat ===  ALL_REPORTS.LIST_QUADRANT_TIER && returnName === _quadrantTier) {
            //     allowUncheck = false;
            // }
        }
        return allowUncheck;
    }

  /**
   * Removes entity count, count per selection and count per total from the 3dots options of selected vector.
   * Removes  count per selection and count per total from the 3dots options of other vectors
   * @returns filtered view options
   */
  returnViewOptions = () => {
        let columnData = this.props?.profileColumnData;
        let viewOptions = this.props?.viewOptions;

        if (columnData?.type === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME) {
            return viewOptions?.filter((option) => ![PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION, PSL_RETURN_NAMES.COUNT_PER_TOTAL].includes(option?.machine_name));
        } else if (columnData?.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME) {
            return viewOptions?.filter((option) => ![PSL_RETURN_NAMES.COUNT_PER_SELECTION, PSL_RETURN_NAMES.COUNT_PER_TOTAL].includes(option?.machine_name));
        }

        return viewOptions;
  };

    /**
     * Disable toggles in Compare scenarios
     * @param {*} toggleObj 
     * @returns 
     */
    shouldToggleBeDisabled = (toggleObj) => {
        let columnData = this.props.profileColumnData;
        // disable entity count if name/number on
        if(toggleObj.machine_name === PSL_RETURN_NAMES.ENTITY_COUNT) {
            let nameNumbSelections = this.props.checkedItems.filter(f => columnData.column_return_name === f.column_return_name && [PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER].includes(f.machine_name));
            return nameNumbSelections.length > 0;
        }

        if(this.isSegmentOption(toggleObj)) {
            return !this.props.isVectorGenerated;
        }

      if([ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS].includes(this.props.profitFormat)) {
        if (
          [
            PROFILE_COLUMN.VIEW_OPTIONS.PER_SELECTION,
            PROFILE_COLUMN.VIEW_OPTIONS.AVERAGE,
            PROFILE_COLUMN.VIEW_OPTIONS.PER_TOTAL,
            PROFILE_COLUMN.VIEW_OPTIONS.MAXIMUM,
            PROFILE_COLUMN.VIEW_OPTIONS.MINIMUM,
            PROFILE_COLUMN.VIEW_OPTIONS.MEDIAN,
            PROFILE_COLUMN.VIEW_OPTIONS.RANGE,
          ].includes(toggleObj.machine_name)
        ) {
          return true;
        }
      }
      return false;
    }

    /**
     * This function checks if the 3 dots dropdown belongs to a vector then we should check the options limit.
     * @returns boolean
     */
    isVectorColumn = () => {
        let columnData = this.props?.profileColumnData;
        return [MANAGE_COLUMNS.VECTOR_MACHINE_NAME, MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME].includes(columnData?.type);
    }

    isSegmentOption = (option) => {
        return [PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER].includes(option.machine_name);
    }

    renderLists(lists) {
        if(!lists || lists.length === 0) {
            return;
        }

        const isVectorColumn = this.isVectorColumn();
        let elementsList = [];
        const propData = this.props.profileColumnData;
        const _this = this;
        elementsList = lists.map((item, index) => { 
            let isSegmentOption = this.isSegmentOption(item);
            let isChecked = _this.props.checkedItems.filter(e=>e[PROFILE_COLUMN.VIEW_OPTION_ID] === item[PROFILE_COLUMN.VIEW_OPTION_ID] && e[_returnName] === propData[_returnName]).length > 0;
            let isDisabled = this.shouldToggleBeDisabled(item);
            return (
              <>
                <div key={item[PROFILE_COLUMN.MACHINE_NAME] + "_" + propData[_returnName] + "_" + index} className={(isVectorColumn ? "toggle-option-div no-hover" : "toggle-option-div-old uk-button-icon justify-content-between no-hover") + (isDisabled ? " toggle-option-disabled" : "")}>
                    <span className={isVectorColumn ? "toggle-option-title" : ""}>
                        {item[PROFILE_COLUMN.DISPLAY_NAME]}
                        {isVectorColumn && isSegmentOption ? " (" + segmentRolling[item.machine_name] +")" : ""}
                        {isVectorColumn && isSegmentOption && isDisabled ? 
                           <i className="fas fa-info-circle uk-margin-xsmall-left" uk-tooltip={"Profit Segmentation not applicable on the selected vector"}  />
                        : ""}
                    </span>
                    <div className="uk-flex uk-flex-middle">
                        {item[PROFILE_COLUMN.TOOLTIP] && item[PROFILE_COLUMN.TOOLTIP] !== "" ? (<i className="fal fa-info-circle uk-margin-xsmall-right uk-margin-xsmall-left" uk-tooltip={item.tooltip} />) : ""}
                        <SwitchToggle
                            tooltip={!isChecked && this.props.disableToggles ? lang.column_selection_exceeded : "title:"}
                            isDisabled={(!isChecked && this.props.disableToggles) || isDisabled}
                            id={item[PROFILE_COLUMN.VIEW_OPTION_ID] + "_" + propData[_returnName]}
                            name="FY"
                            checked={isChecked}
                            onChange={(evt) => {
                                _this.toggleElement(
                                item[PROFILE_COLUMN.VIEW_OPTION_ID],
                                item[PROFILE_COLUMN.MACHINE_NAME],
                                _this.props.groupName,
                                evt.currentTarget.checked,
                                propData,
                                item[PROFILE_COLUMN.VIEW_ORDER]
                                );
                            }}
                        />
                    </div>
                </div>
                {/* Add a divider after displaying 2 elemnts and dont add one if it's the last element in the list. Add it only for vector options */}
                {isVectorColumn && (index + 1) % 2 == 0 && index != lists.length - 1 ? <hr className="options-hr" /> : ""}  
              </>
            );
        });
        return elementsList;
    }

    render() {
        const columnData = this.props.profileColumnData;
        const isVectorColumn = this.isVectorColumn();

        return (
            <div uk-dropdown="mode: click; " className={(isVectorColumn ? "manage-columns-dropdown-container " : "") + "uk-dropdown uk-dropdown-bottom-right "} id={columnData[_returnName]}>
                { isVectorColumn && this.state?.viewOptions?.length > 2 && <span className="select-limit-manage-columns">{lang.select_up_to_3_items}</span> }
                { this.renderLists(this.state?.viewOptions) }
                {![MANAGE_COLUMNS.PSL_LINE, MANAGE_COLUMNS.LINE_ATTRIBUTE, MANAGE_COLUMNS.VECTOR_TYPE].includes(columnData[PROFILE_COLUMN.LINE_TYPE])
                    && ((columnData[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY && this.props.access.edit_company_cols)
                    ||(columnData[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER && this.props.access.edit_user_cols)) ?
                    <div>
                        <Button  
                            label={lang.modal.buttons.edit}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="justify-content-start"
                            style={{width: "100%", padding: "0 20px"}}
                            onBtnClick={()=>this.editColumn()}
                            leftIcon={<i className="fal fa-edit fa-lg" aria-hidden="true"/>}
                        />
                        <Button  
                            label={lang.modal.buttons.delete}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="justify-content-start"
                            style={{width: "100%", padding: "0 20px"}}
                            onBtnClick={()=>this.onDeleteColumn()}
                            leftIcon={<i className="fal fa-trash-alt fa-lg" aria-hidden="true"/>}
                        />
                    </div>
                : ""}
            </div>
        );
    }
}

export default DropDownContainer
