import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, MANAGE_COST_CENTER, SIZES } from "../../../../class/constants";
import Input from "../../../../newComponents/Input";
import Button from "../../../../newComponents/Button";
import { lang } from "../../../../language/messages_en";
import RadioButton from "../../../../newComponents/RadioButton";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../../../class/networkUtils";
import Modal from "../../../../newComponents/Modal";

const AddNewStateModal = forwardRef((props, ref) => {

    const [addNewStateDialog, setAddNewStateDialog] = useState(false);
    const [type, setType] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [stateObj, setStateObj] = useState({});

    const {costCenterStates, addToCostCenterStates} = props;

    const _name = lang.monthly_build.cost_centers.modal.name;
    const _description = lang.monthly_build.cost_centers.modal.description;
    const _revenue_generating = MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE;
    const _non_revenue_generating = MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE;

    useImperativeHandle(ref, () => ({
        openAddNewStateDialog:(param)=>{
            return openAddNewStateDialog(param);
        }
    }));

    useEffect(() => {
        setType(_non_revenue_generating);
    }, []);


    const openAddNewStateDialog = (param) => {
        setAddNewStateDialog(param);
        if(!param){
            setType(_non_revenue_generating);
            setErrorMessage('');
            setStateObj({});
        }
    }

    const addNewStateDialogActions = () => {
        return (
            <>
                <Button
                  id={"add-new-state-popup-btn"}
                  label={lang.manage_cost_centers.add_state}
                  className={"uk-padding-small-right uk-text-medium"}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={validateNewState}
                />
               <Button 
                  label={lang.modal.buttons.cancel}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-padding-small-right uk-text-medium"
                  onBtnClick={() => openAddNewStateDialog(false)}
              />
              {errorMessage &&
              <span className='red uk-display-block uk-margin-left'>
                <i className='fal fa-times-circle uk-margin-xsmall-right'></i>
                {errorMessage}
                </span>
                }
              
            </>
        )
    }

    const validateNewState = () => {
        let valid = true;
        let message;
        if(!stateObj[_name]) {
            valid = false;
            message = lang.manage_cost_centers.empty_name;
        } else if (stateObj[_name] && costCenterStates.filter(e=>e["name"].trim().toLowerCase() === stateObj[_name].trim().toLowerCase()).length > 0) {
            valid = false;
            message = lang.manage_cost_centers.duplicate;
        }
        
        if(valid){
            saveNewState()
        } else (
            setErrorMessage(message)
        )
    }

    const setnewStateValues = (event, field) => {
        let stateObjCurr = stateObj;
        stateObjCurr[field] = event.currentTarget.value;
        setStateObj(stateObjCurr);
    }

    const addNewStateDialogContent = () => {
        return (
            <div className="med-gap-flex">
                <div className="small-gap-flex">
                    <div className="uk-text-bolder fs-14">
                        <span>{_name}</span>
                        <span>{lang.tags.required}</span>
                    </div>
                    <div className="uk-width-1-2">
                        <Input
                            id={"new-cost-center-name"}
                            placeholder = {"Cost Center Name"}
                            onBlur={(e)=> setnewStateValues(e,_name)}
                            notFullWidth={true}
                        />
                    </div>
                    
                </div>

                <div className="small-gap-flex">
                    <div>
                        <span className="uk-text-bolder fs-14">{MANAGE_COST_CENTER.TITLES.TYPE}</span>
                    </div>
                    <div className="uk-display-flex radio-button-flex">
                        <div className="uk-display-flex">
                            <RadioButton
                                className="radioButton uk-margin-large-right"
                                name={_non_revenue_generating}
                                value={_non_revenue_generating}s
                                checked={(type == _non_revenue_generating)}
                                onChange={() =>  setType(_non_revenue_generating)}
                                label={lang.manage_cost_centers.non_revenue_generating}
                            />
                        </div>
                        <div className="uk-display-flex">
                            <RadioButton
                                className="radioButton uk-margin-small-right"
                                name={_revenue_generating}
                                value={_revenue_generating}
                                checked={(type == _revenue_generating)}
                                onChange={() => setType(_revenue_generating)}
                                label={lang.manage_cost_centers.revenue_generating}
                            />
                        </div>
                    </div>
                </div>

                <div className="small-gap-flex fs-14">
                    <div>
                        <span className="uk-text-bolder">{_description}</span>
                        <span className="disabled">{lang.tags.optional}</span>
                    </div>
                    <div>
                    <textarea onBlur={(e)=>{setnewStateValues(e,_description)}} rows="4" placeholder={lang.manage_cost_centers.description} className=" text-area input-default-no-height"/>
                    </div>
                </div>
            </div>
        )
    }

    /**
     *function saves newState to the database  
     */
     const saveNewState = () => {
        let newState = {
            name: stateObj[_name],
            is_revenue_generating: type ===_revenue_generating,
            description: stateObj[_description],
            display_name: stateObj[_name]
        };
        var query = {
            action: "saveCostCenterState",
            cost_center_state:JSON.stringify(newState)
        }
          let onThenCallback = (res)=>{
            addToCostCenterStates(newState);
            openAddNewStateDialog(false);
          }
          let fetchOptions = {
              [FETCHAPI_PARAMS.funcName]: "saveCostCenterState",
              [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
              [FETCHAPI_PARAMS.showLoader]: true,
              [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
              [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
              [FETCHAPI_PARAMS.query]: query,
              [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
          }
          fetchAPI(fetchOptions);
      }

    return (
        <>
             <Modal 
                id="add-new-state-modal"
                openDialog={addNewStateDialog}
                title={lang.monthly_build.cost_centers.modal.title}
                bodyContent={addNewStateDialogContent}
                dialogActions={addNewStateDialogActions}
                closeClick={()=> openAddNewStateDialog(false)}
                size={DIALOG_SIZE.LARGE}
              />
        </>
    );
});

export default AddNewStateModal;

