var map = {"type":"FeatureCollection", "features": [
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.643645,30.450824],[-87.610613,30.355604],[-87.575881,30.29868],[-87.50581,30.315086],[-87.492045,30.330872],[-87.468306,30.480095],[-87.513394,30.512044],[-87.561601,30.486582],[-87.643645,30.450824]]]},"properties":{"id":"36530","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.763603,31.297253],[-87.815348,31.21716],[-87.683984,31.15979],[-87.650894,31.248782],[-87.763603,31.297253]]]},"properties":{"id":"36550","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.732234,30.517446],[-87.733738,30.567236],[-87.798664,30.545541],[-87.802903,30.483204],[-87.732234,30.517446]]]},"properties":{"id":"36576","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.609933,31.931994],[-85.210101,31.897714],[-85.33837,32.147152],[-85.493064,32.187266],[-85.636238,32.0156],[-85.610877,31.934573],[-85.609933,31.931994]]]},"properties":{"id":"36053","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.518347,31.666601],[-85.475051,31.620493],[-85.447263,31.672448],[-85.467993,31.707552],[-85.518347,31.666601]]],[[[-85.694933,31.704077],[-85.528298,31.619219],[-85.532008,31.673098],[-85.694933,31.704077]]]]},"properties":{"id":"36017","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.447263,31.672448],[-85.475051,31.620493],[-85.416124,31.574015],[-85.37464,31.567159],[-85.329488,31.676715],[-85.365319,31.706094],[-85.447263,31.672448]]]},"properties":{"id":"36317","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.289752,33.063231],[-87.07657,33.041885],[-87.028439,33.140909],[-87.025614,33.179147],[-87.025976,33.231607],[-87.044829,33.246667],[-87.121517,33.221744],[-87.127912,33.22007],[-87.202884,33.130654],[-87.302904,33.105449],[-87.3121,33.088168],[-87.289752,33.063231]]]},"properties":{"id":"35184","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.128139,33.304067],[-87.170768,33.228963],[-87.12528,33.22421],[-87.121517,33.221744],[-87.044829,33.246667],[-87.050227,33.370585],[-87.086546,33.340991],[-87.128139,33.304067]]]},"properties":{"id":"35111","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.279702,34.212999],[-86.384782,34.134305],[-86.303516,34.099073],[-86.008586,34.199436],[-85.99949,34.208558],[-86.076145,34.238999],[-86.094341,34.270482],[-86.107797,34.277313],[-86.162996,34.234487],[-86.279702,34.212999]]]},"properties":{"id":"35957","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.998977,32.23507],[-86.016688,32.274409],[-86.122572,32.182941],[-85.997118,32.079698],[-85.998977,32.23507]]]},"properties":{"id":"36052","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.708274,31.962641],[-86.529231,31.937919],[-86.415043,31.96483],[-86.405935,32.032727],[-86.686342,32.057347],[-86.735792,32.070709],[-86.708274,31.962641]]]},"properties":{"id":"36032","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.955751,33.565755],[-85.825126,33.519402],[-85.743627,33.564163],[-85.737379,33.599823],[-85.827954,33.623267],[-85.94124,33.59752],[-85.955751,33.565755]]]},"properties":{"id":"36203","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.38429,33.068953],[-85.324674,32.951107],[-85.210733,33.000124],[-85.232378,33.108077],[-85.38429,33.068953]]]},"properties":{"id":"36855","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.178422,32.854602],[-85.210733,33.000124],[-85.324674,32.951107],[-85.313426,32.804203],[-85.250354,32.779397],[-85.178422,32.854602]]]},"properties":{"id":"36863","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.46326,34.29155],[-85.681238,34.288235],[-85.744086,34.284851],[-85.764947,34.266997],[-85.687792,34.202606],[-85.444671,34.1999],[-85.445487,34.203882],[-85.454191,34.217932],[-85.453818,34.24516],[-85.46326,34.29155]]]},"properties":{"id":"35959","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.880777,32.735284],[-86.868787,32.751531],[-86.878249,32.766522],[-86.897076,32.734623],[-86.915194,32.721496],[-86.880777,32.735284]]]},"properties":{"id":"36790","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.46549,31.729454],[-88.453179,31.505388],[-88.185346,31.508962],[-88.16439,31.61381],[-88.267431,31.696276],[-88.46549,31.729454]]]},"properties":{"id":"36558","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.07349,31.990182],[-87.947382,31.89855],[-87.824798,31.841116],[-87.735737,31.809985],[-87.697745,31.814515],[-87.576982,31.770798],[-87.667104,31.909181],[-87.704227,32.083615],[-87.704374,32.079683],[-87.805297,31.990456],[-88.053341,32.078065],[-88.107857,32.000149],[-88.07349,31.990182]]]},"properties":{"id":"36784","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.697745,31.814515],[-87.735737,31.809985],[-87.719307,31.776324],[-87.697745,31.814515]]]},"properties":{"id":"36446","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.667104,31.909181],[-87.576982,31.770798],[-87.59993,31.744404],[-87.566803,31.693623],[-87.439824,31.743253],[-87.50093,31.829251],[-87.527755,31.874517],[-87.667104,31.909181]]]},"properties":{"id":"36751","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.763722,32.97708],[-85.647885,32.965142],[-85.592989,33.016146],[-85.755797,33.124256],[-85.794589,33.079762],[-85.763722,32.97708]]]},"properties":{"id":"36256","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.84526,31.418625],[-86.011967,31.282791],[-85.980144,31.248379],[-85.958238,31.227996],[-85.798639,31.196373],[-85.792908,31.196374],[-85.745752,31.321272],[-85.762607,31.356373],[-85.84526,31.418625]]]},"properties":{"id":"36330","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.180096,31.670469],[-86.150314,31.537601],[-86.049899,31.547759],[-86.053974,31.664522],[-86.180096,31.670469]]]},"properties":{"id":"36034","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.182117,32.944374],[-86.165338,32.827598],[-86.106533,32.846231],[-85.985196,33.035148],[-86.182117,32.944374]]]},"properties":{"id":"35089","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.277462,33.022999],[-86.436935,32.93088],[-86.437346,32.921742],[-86.277462,33.022999]]]},"properties":{"id":"35183","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.371151,32.750627],[-86.361913,32.727199],[-86.294432,32.640057],[-86.213697,32.70712],[-86.250267,32.753537],[-86.374974,32.75358],[-86.371151,32.750627]]]},"properties":{"id":"36080","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.353942,31.009553],[-86.34547,31.014232],[-86.351496,31.02018],[-86.358713,31.013425],[-86.353942,31.009553]]]},"properties":{"id":"36455","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.539428,31.356514],[-86.539892,31.364147],[-86.554468,31.371647],[-86.557334,31.358144],[-86.540436,31.356472],[-86.539428,31.356514]]]},"properties":{"id":"36476","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.352405,32.109654],[-86.405935,32.032727],[-86.415043,31.96483],[-86.374315,31.904274],[-86.256933,31.918428],[-86.256727,32.036458],[-86.328493,32.10821],[-86.352405,32.109654]]]},"properties":{"id":"36046","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.092741,34.31232],[-86.789026,34.30745],[-86.764384,34.397017],[-86.777748,34.415545],[-87.020041,34.370382],[-87.092741,34.31232]]]},"properties":{"id":"35622","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.92369,33.872224],[-86.965678,33.990304],[-86.991599,34.046547],[-87.110645,33.956473],[-87.070666,33.821578],[-87.070567,33.821364],[-86.963358,33.858221],[-86.92369,33.872224]]]},"properties":{"id":"35033","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.092741,34.31232],[-87.020041,34.370382],[-87.030091,34.496146],[-87.106994,34.499161],[-87.274794,34.29932],[-87.26955,34.299302],[-87.109911,34.299299],[-87.092741,34.31232]]]},"properties":{"id":"35619","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.969397,34.633958],[-86.055289,34.556762],[-86.091866,34.484507],[-86.012252,34.480695],[-85.910665,34.543935],[-85.969397,34.633958]]]},"properties":{"id":"35771","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.018466,34.390876],[-86.012252,34.480695],[-86.091866,34.484507],[-86.154938,34.450003],[-86.156714,34.434634],[-86.033105,34.393601],[-86.018466,34.390876]]]},"properties":{"id":"35975","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.636108,34.05979],[-87.8036,34.003854],[-87.897443,33.827682],[-87.753553,33.849906],[-87.636042,33.968508],[-87.636118,34.002203],[-87.636108,34.05979]],[[-87.719298,33.897712],[-87.735507,33.90571],[-87.732269,33.913723],[-87.719328,33.90301],[-87.719298,33.897712]]]},"properties":{"id":"35594","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.751867,34.451896],[-87.92881,34.429132],[-87.873504,34.401032],[-87.73275,34.396402],[-87.751867,34.451896]]]},"properties":{"id":"35585","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.645352,31.1798],[-85.62879,30.995899],[-85.40498,30.998265],[-85.404902,31.069331],[-85.496118,31.158313],[-85.561771,31.196278],[-85.645352,31.1798]]]},"properties":{"id":"36375","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.780268,33.017197],[-87.774254,32.927697],[-87.743853,32.935336],[-87.712009,33.015624],[-87.760281,33.024375],[-87.780268,33.017197]]]},"properties":{"id":"35469","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.816325,32.819437],[-87.870382,32.762625],[-87.825864,32.767608],[-87.816325,32.819437]]],[[[-87.816828,32.832858],[-87.655531,32.786371],[-87.633175,32.833121],[-87.743853,32.935336],[-87.774254,32.927697],[-87.816828,32.832858]]]]},"properties":{"id":"35441","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.643563,32.509712],[-87.70845,32.356504],[-87.641419,32.317574],[-87.628492,32.320983],[-87.557595,32.481606],[-87.643563,32.509712]]]},"properties":{"id":"36738","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.057877,31.606952],[-85.130918,31.45727],[-85.079818,31.411732],[-85.057877,31.606952]]]},"properties":{"id":"36373","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.066257,34.781451],[-85.983724,34.699067],[-85.951049,34.656131],[-85.864004,34.744526],[-85.901593,34.766413],[-86.066257,34.781451]]]},"properties":{"id":"35752","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.901593,34.766413],[-86.00777,34.882846],[-86.066257,34.781451],[-85.901593,34.766413]]]},"properties":{"id":"35746","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.195611,34.794782],[-86.280191,34.774318],[-86.270009,34.725019],[-86.171718,34.775816],[-86.195611,34.794782]]]},"properties":{"id":"35774","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.716153,34.886661],[-85.644691,34.8441],[-85.583145,34.860371],[-85.700824,34.986333],[-85.718707,34.899574],[-85.716153,34.886661]]]},"properties":{"id":"35958","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.230183,33.538843],[-87.092912,33.345892],[-87.086546,33.340991],[-87.050227,33.370585],[-86.990093,33.400709],[-86.965074,33.444616],[-86.97412,33.470737],[-87.014794,33.48585],[-87.117155,33.560062],[-87.186516,33.556951],[-87.230183,33.538843]]]},"properties":{"id":"35023","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.822733,33.504598],[-86.834543,33.498945],[-86.832595,33.480141],[-86.788923,33.496949],[-86.783112,33.499265],[-86.773471,33.506301],[-86.780258,33.512676],[-86.818161,33.506971],[-86.822733,33.504598]]]},"properties":{"id":"35205","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.884791,33.478206],[-86.917591,33.420828],[-86.905156,33.40616],[-86.868876,33.409808],[-86.841728,33.433242],[-86.832595,33.480141],[-86.834543,33.498945],[-86.854596,33.501556],[-86.880814,33.483217],[-86.884791,33.478206]]]},"properties":{"id":"35211","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.778998,33.540159],[-86.794972,33.530768],[-86.791266,33.525095],[-86.780258,33.512676],[-86.773471,33.506301],[-86.738554,33.527645],[-86.778998,33.540159]]]},"properties":{"id":"35222","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.894533,33.526558],[-86.91181,33.500891],[-86.904845,33.491703],[-86.878911,33.515143],[-86.879185,33.517279],[-86.894533,33.526558]]]},"properties":{"id":"35218","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.752386,33.676861],[-86.733008,33.701341],[-86.735326,33.762499],[-86.751962,33.767243],[-86.814278,33.759399],[-86.752386,33.676861]]]},"properties":{"id":"35116","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.928294,33.651502],[-86.915968,33.635381],[-86.916694,33.639443],[-86.928294,33.651502]]]},"properties":{"id":"35036","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.070415,33.776901],[-87.110789,33.735542],[-87.042515,33.638177],[-86.983242,33.709428],[-86.964923,33.711153],[-86.94732,33.794267],[-87.022168,33.772717],[-87.046532,33.728942],[-87.070415,33.776901]]]},"properties":{"id":"35062","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.01128,34.053057],[-87.98202,33.867595],[-87.963517,34.022502],[-88.01128,34.053057]]]},"properties":{"id":"35544","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.216913,33.983745],[-88.262394,33.6324],[-88.238202,33.653393],[-87.950838,33.823146],[-87.950851,33.823598],[-87.98202,33.867595],[-88.01128,34.053057],[-88.056813,34.040898],[-88.216913,33.983745]]]},"properties":{"id":"35586","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.986612,34.886745],[-87.873118,35.005546],[-88.097888,34.892202],[-87.986612,34.886745]]]},"properties":{"id":"35677","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.90688,34.906156],[-87.143788,34.915696],[-87.228775,34.8872],[-87.187725,34.831377],[-86.947543,34.823262],[-86.90688,34.906156]]]},"properties":{"id":"35614","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.444062,34.470228],[-87.433545,34.403119],[-87.374208,34.30083],[-87.274794,34.29932],[-87.106994,34.499161],[-87.112991,34.526298],[-87.21318,34.52685],[-87.243926,34.616866],[-87.335641,34.598999],[-87.444062,34.470228]]]},"properties":{"id":"35650","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.529778,34.566085],[-87.530649,34.367248],[-87.433545,34.403119],[-87.444062,34.470228],[-87.529778,34.566085]]]},"properties":{"id":"35651","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.158713,32.514809],[-85.056847,32.509271],[-85.015303,32.531149],[-85.097571,32.634507],[-85.158713,32.514809]]]},"properties":{"id":"36877","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.485243,32.606537],[-85.494694,32.602361],[-85.483888,32.595761],[-85.485243,32.606537]]]},"properties":{"id":"36849","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.58025,32.599497],[-85.587676,32.603341],[-85.593769,32.60456],[-85.59774,32.603852],[-85.58025,32.599497]]]},"properties":{"id":"36865","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.947543,34.823262],[-86.936403,34.730816],[-86.877541,34.729973],[-86.823202,34.752084],[-86.802426,34.835353],[-86.834355,34.925896],[-86.90688,34.906156],[-86.947543,34.823262]]]},"properties":{"id":"35613","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.947543,34.823262],[-87.187725,34.831377],[-87.260676,34.758626],[-87.105073,34.686037],[-87.046129,34.650467],[-86.936403,34.730816],[-86.947543,34.823262]]]},"properties":{"id":"35611","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.07301,34.996359],[-87.193977,34.998673],[-87.143788,34.915696],[-87.07301,34.996359]]]},"properties":{"id":"35647","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.864878,34.67141],[-86.878957,34.6715],[-86.871328,34.656857],[-86.864878,34.67141]]]},"properties":{"id":"35615","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.592393,34.749321],[-86.59992,34.748179],[-86.596408,34.730009],[-86.596536,34.690064],[-86.526162,34.700275],[-86.529542,34.747802],[-86.592393,34.749321]]]},"properties":{"id":"35801","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.711327,34.692953],[-86.716788,34.66967],[-86.722513,34.624004],[-86.723833,34.581011],[-86.636195,34.555198],[-86.586504,34.582747],[-86.589298,34.628888],[-86.610269,34.667858],[-86.669305,34.709319],[-86.711327,34.692953]]]},"properties":{"id":"35808","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.641419,32.317574],[-87.700903,32.241543],[-87.659638,32.136009],[-87.581081,32.132641],[-87.504247,32.268876],[-87.507857,32.307335],[-87.628492,32.320983],[-87.641419,32.317574]]]},"properties":{"id":"36783","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.70845,32.356504],[-87.826726,32.430982],[-87.902771,32.290129],[-87.875013,32.295698],[-87.782519,32.137273],[-87.775296,32.13762],[-87.700903,32.241543],[-87.641419,32.317574],[-87.70845,32.356504]]]},"properties":{"id":"36748","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.089467,31.063388],[-88.32459,30.9356],[-88.252598,30.856279],[-88.246627,30.85251],[-88.176519,30.881439],[-88.061525,30.911504],[-88.034766,30.968456],[-88.049496,31.007396],[-88.089467,31.063388]]]},"properties":{"id":"36521","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.566421,31.659982],[-87.499358,31.565206],[-87.379505,31.626518],[-87.309911,31.68911],[-87.36675,31.737609],[-87.439824,31.743253],[-87.566803,31.693623],[-87.566421,31.659982]]]},"properties":{"id":"36444","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.221572,32.34847],[-86.176236,32.294645],[-86.064183,32.409063],[-86.086113,32.421505],[-86.192284,32.43613],[-86.229364,32.4212],[-86.213337,32.363296],[-86.221572,32.34847]]]},"properties":{"id":"36117","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229364,32.4212],[-86.192284,32.43613],[-86.278069,32.486035],[-86.309131,32.464117],[-86.315758,32.435873],[-86.288287,32.400062],[-86.265472,32.40916],[-86.229364,32.4212]]]},"properties":{"id":"36110","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.489249,32.655791],[-87.443122,32.568723],[-87.385694,32.533201],[-87.16407,32.487322],[-87.04746,32.6972],[-87.098183,32.836008],[-87.211556,32.835175],[-87.377925,32.874615],[-87.4212,32.874508],[-87.421292,32.862836],[-87.489249,32.655791]]]},"properties":{"id":"36756","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.274619,33.534008],[-88.300507,33.320847],[-88.121464,33.365997],[-88.12271,33.469403],[-88.274619,33.534008]]]},"properties":{"id":"35461","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.551613,33.587507],[-86.463499,33.578583],[-86.462422,33.578612],[-86.509728,33.63726],[-86.551613,33.587507]]]},"properties":{"id":"35004","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.417162,33.593136],[-86.398292,33.592235],[-86.395595,33.595685],[-86.417162,33.593136]]]},"properties":{"id":"35052","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.579695,33.325315],[-86.503127,33.179144],[-86.483958,33.201992],[-86.433337,33.274064],[-86.551975,33.335577],[-86.560198,33.335773],[-86.579695,33.325315]]]},"properties":{"id":"35186","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.143278,32.453213],[-88.132634,32.412305],[-88.041467,32.414774],[-87.85342,32.532086],[-87.879373,32.62193],[-88.057391,32.593486],[-88.228394,32.671632],[-88.377187,32.676166],[-88.386028,32.600487],[-88.299726,32.554771],[-88.143278,32.453213]]]},"properties":{"id":"35470","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.460915,33.253103],[-87.473383,33.183667],[-87.47232,33.14575],[-87.3121,33.088168],[-87.302904,33.105449],[-87.300039,33.190128],[-87.394202,33.287016],[-87.460915,33.253103]]]},"properties":{"id":"35453","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.525984,33.187213],[-87.589245,33.0856],[-87.624612,33.045354],[-87.539426,33.031705],[-87.47232,33.14575],[-87.473383,33.183667],[-87.525984,33.187213]]]},"properties":{"id":"35405","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.34506,34.002598],[-87.40096,33.869003],[-87.285739,33.848696],[-87.216735,33.993907],[-87.245152,34.02758],[-87.34506,34.002598]]]},"properties":{"id":"35503","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.515791,34.070144],[-87.636118,34.002203],[-87.636042,33.968508],[-87.566821,33.940758],[-87.405128,33.864305],[-87.40096,33.869003],[-87.34506,34.002598],[-87.505325,34.083306],[-87.515791,34.070144]]]},"properties":{"id":"35578","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.967058,31.434821],[-88.018881,31.475639],[-88.09943,31.47954],[-88.169361,31.397327],[-88.146934,31.304298],[-88.008538,31.337575],[-87.967058,31.434821]]]},"properties":{"id":"36585","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.449335,31.433165],[-88.439295,31.258225],[-88.326391,31.266139],[-88.313198,31.379508],[-88.449335,31.433165]]]},"properties":{"id":"36539","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.249989,32.05892],[-87.321431,32.125144],[-87.332226,32.07217],[-87.249989,32.05892]]]},"properties":{"id":"36723","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.374208,34.30083],[-87.368899,34.218082],[-87.260328,34.066863],[-87.204846,34.148685],[-87.26955,34.299302],[-87.274794,34.29932],[-87.374208,34.30083]]]},"properties":{"id":"35572","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.451605,32.542354],[-86.411172,32.409937],[-86.373071,32.429837],[-86.368979,32.430636],[-86.412073,32.493105],[-86.451605,32.542354]]]},"properties":{"id":"36066","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.70683,30.691408],[-87.79008,30.726068],[-87.819535,30.658225],[-87.798528,30.549242],[-87.798664,30.545541],[-87.733738,30.567236],[-87.665212,30.696879],[-87.70683,30.691408]]]},"properties":{"id":"36551","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.610613,30.355604],[-87.643645,30.450824],[-87.817471,30.435698],[-87.858127,30.375554],[-87.761772,30.285431],[-87.755258,30.288494],[-87.771584,30.310884],[-87.72027,30.330105],[-87.726995,30.304414],[-87.610613,30.355604]],[[-87.784705,30.396128],[-87.78059,30.405637],[-87.767939,30.399968],[-87.768934,30.392095],[-87.784705,30.396128]]]},"properties":{"id":"36535","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.610613,30.355604],[-87.726995,30.304414],[-87.755258,30.288494],[-87.761772,30.285431],[-87.668034,30.245533],[-87.575881,30.29868],[-87.610613,30.355604]]]},"properties":{"id":"36542","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.528298,31.619219],[-85.572552,31.558397],[-85.471776,31.500598],[-85.416124,31.574015],[-85.475051,31.620493],[-85.518347,31.666601],[-85.532008,31.673098],[-85.528298,31.619219]]]},"properties":{"id":"36374","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.028439,33.140909],[-86.876297,33.003059],[-86.85568,33.001981],[-86.790919,33.027918],[-86.793497,33.166153],[-86.861107,33.190855],[-86.920243,33.220411],[-87.025614,33.179147],[-87.028439,33.140909]],[[-86.884371,33.078806],[-86.886544,33.079197],[-86.886875,33.082547],[-86.884371,33.078806]]]},"properties":{"id":"35115","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.751962,33.767243],[-86.735326,33.762499],[-86.711204,33.798586],[-86.725964,33.862644],[-86.934556,33.816374],[-86.94732,33.794267],[-86.964923,33.711153],[-86.953509,33.710977],[-86.825085,33.770258],[-86.751962,33.767243]]]},"properties":{"id":"35180","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.432686,34.383416],[-86.493673,34.223882],[-86.426595,34.209552],[-86.356749,34.234489],[-86.25773,34.301851],[-86.156714,34.434634],[-86.154938,34.450003],[-86.221765,34.443146],[-86.261121,34.466074],[-86.421261,34.439602],[-86.432686,34.383416]]]},"properties":{"id":"35976","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.529231,31.937919],[-86.441945,31.765067],[-86.396249,31.776128],[-86.372334,31.837518],[-86.374315,31.904274],[-86.415043,31.96483],[-86.529231,31.937919]]]},"properties":{"id":"36042","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.858689,33.738686],[-85.786053,33.754903],[-85.847134,33.772851],[-85.858689,33.738686]]]},"properties":{"id":"36277","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.572089,33.003664],[-85.554876,32.842288],[-85.43414,32.730303],[-85.378963,32.764001],[-85.313426,32.804203],[-85.324674,32.951107],[-85.38429,33.068953],[-85.51734,33.034292],[-85.572089,33.003664]]]},"properties":{"id":"36862","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.647885,32.965142],[-85.813849,32.637501],[-85.68703,32.618848],[-85.626769,32.669235],[-85.554876,32.842288],[-85.572089,33.003664],[-85.592989,33.016146],[-85.647885,32.965142]]]},"properties":{"id":"36850","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.500369,32.70832],[-85.421721,32.608143],[-85.304186,32.716137],[-85.378963,32.764001],[-85.43414,32.730303],[-85.500369,32.70832]]]},"properties":{"id":"36801","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.453818,34.24516],[-85.454191,34.217932],[-85.445487,34.203882],[-85.453818,34.24516]]]},"properties":{"id":"30165","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.954604,33.942975],[-85.780658,33.962326],[-85.768884,34.07043],[-85.789466,34.086297],[-85.841365,34.110695],[-85.954604,33.942975]]]},"properties":{"id":"35903","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.790919,33.027918],[-86.85568,33.001981],[-86.856986,32.901693],[-86.802529,32.893312],[-86.699627,32.924982],[-86.664901,32.942197],[-86.610143,33.070002],[-86.790919,33.027918]]]},"properties":{"id":"35085","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.700868,31.749016],[-87.697743,31.562338],[-87.621428,31.514639],[-87.566421,31.659982],[-87.566803,31.693623],[-87.59993,31.744404],[-87.700868,31.749016]]]},"properties":{"id":"36482","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.342453,33.25128],[-86.209805,33.091386],[-86.173837,33.128369],[-86.153727,33.195361],[-86.120581,33.205778],[-86.227529,33.225544],[-86.223048,33.236539],[-86.274939,33.270165],[-86.333197,33.259258],[-86.338792,33.264286],[-86.342453,33.25128]]]},"properties":{"id":"35150","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.002124,31.221474],[-85.951924,31.101626],[-85.905003,31.134199],[-85.958238,31.227996],[-85.980144,31.248379],[-86.002124,31.221474]]]},"properties":{"id":"36318","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.770569,34.572226],[-87.61856,34.594865],[-87.581997,34.711406],[-87.679453,34.745039],[-87.726244,34.749285],[-87.855931,34.757851],[-87.988324,34.602469],[-87.770569,34.572226]]]},"properties":{"id":"35674","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.61856,34.594865],[-87.524458,34.566932],[-87.45654,34.756822],[-87.581997,34.711406],[-87.61856,34.594865]]]},"properties":{"id":"35646","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.70113,31.315663],[-86.763961,31.261293],[-86.763512,31.181133],[-86.331466,31.248196],[-86.292911,31.398953],[-86.480534,31.425496],[-86.499349,31.438185],[-86.514455,31.438051],[-86.539428,31.356514],[-86.540436,31.356472],[-86.70113,31.315663]]]},"properties":{"id":"36421","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.339969,31.260608],[-87.334055,31.065999],[-87.164084,30.999017],[-86.831933,30.997378],[-86.814499,31.068824],[-86.763512,31.181133],[-86.763961,31.261293],[-86.871022,31.261902],[-87.163413,31.306994],[-87.181987,31.299646],[-87.207538,31.279942],[-87.339969,31.260608]]]},"properties":{"id":"36426","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.763512,31.181133],[-86.814499,31.068824],[-86.53737,31.010091],[-86.360804,31.190822],[-86.331466,31.248196],[-86.763512,31.181133]]]},"properties":{"id":"36420","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.180096,31.670469],[-86.053974,31.664522],[-86.069986,31.967264],[-86.183084,31.832048],[-86.191343,31.666245],[-86.180096,31.670469]]]},"properties":{"id":"36035","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.226123,31.889924],[-86.256933,31.918428],[-86.374315,31.904274],[-86.372334,31.837518],[-86.226123,31.889924]]]},"properties":{"id":"36041","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.789026,34.30745],[-87.092741,34.31232],[-87.109911,34.299299],[-87.099009,34.213723],[-86.878911,34.230695],[-86.751396,34.306928],[-86.789026,34.30745]]]},"properties":{"id":"35179","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.549859,34.247839],[-86.581328,34.367154],[-86.607808,34.37025],[-86.583148,34.261641],[-86.549859,34.247839]]]},"properties":{"id":"35087","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.204846,34.148685],[-87.260328,34.066863],[-87.245152,34.02758],[-87.216735,33.993907],[-87.151036,33.993225],[-87.133001,34.067845],[-87.102319,34.15658],[-87.110941,34.172554],[-87.204846,34.148685]]]},"properties":{"id":"35541","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.612124,31.351157],[-85.710866,31.195179],[-85.645352,31.1798],[-85.561771,31.196278],[-85.539387,31.238787],[-85.539525,31.253043],[-85.561571,31.292988],[-85.552627,31.346322],[-85.558989,31.35759],[-85.612124,31.351157]]]},"properties":{"id":"36352","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.105364,33.960261],[-85.93375,34.154928],[-86.002757,34.199324],[-86.150543,34.095738],[-86.177013,34.048044],[-86.189766,33.983454],[-86.166253,33.944447],[-86.105364,33.960261]]]},"properties":{"id":"35954","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.945333,33.657989],[-87.946433,33.659025],[-87.946305,33.657795],[-87.945333,33.657989]]],[[[-87.945333,33.657989],[-87.932171,33.635329],[-87.793808,33.448549],[-87.773844,33.45169],[-87.717687,33.52019],[-87.666633,33.577316],[-87.715006,33.827217],[-87.753553,33.849906],[-87.897443,33.827682],[-87.950851,33.823598],[-87.950838,33.823146],[-87.98986,33.708446],[-87.946466,33.660608],[-87.945333,33.657989]]]]},"properties":{"id":"35555","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.566821,33.940758],[-87.620545,33.841353],[-87.649976,33.737477],[-87.481098,33.720895],[-87.459986,33.738634],[-87.420997,33.840719],[-87.405128,33.864305],[-87.566821,33.940758]]]},"properties":{"id":"35549","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.831024,31.108784],[-85.830349,31.048079],[-85.692207,30.995573],[-85.62879,30.995899],[-85.645352,31.1798],[-85.710866,31.195179],[-85.784623,31.159448],[-85.831024,31.108784]]]},"properties":{"id":"36344","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.79247,32.660626],[-87.879373,32.62193],[-87.85342,32.532086],[-87.812559,32.52456],[-87.757921,32.578893],[-87.75001,32.637448],[-87.79247,32.660626]]]},"properties":{"id":"36740","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.70845,32.356504],[-87.643563,32.509712],[-87.65378,32.523304],[-87.757921,32.578893],[-87.812559,32.52456],[-87.826726,32.430982],[-87.70845,32.356504]]]},"properties":{"id":"36742","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.404902,31.069331],[-85.40498,30.998265],[-85.292519,30.999886],[-85.200511,31.058496],[-85.309693,31.101234],[-85.404902,31.069331]]]},"properties":{"id":"36320","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.860921,34.988243],[-86.067137,34.989514],[-86.00777,34.882846],[-85.901593,34.766413],[-85.864004,34.744526],[-85.741787,34.774866],[-85.716153,34.886661],[-85.718707,34.899574],[-85.860921,34.988243]]]},"properties":{"id":"35772","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.765577,33.473216],[-86.788923,33.496949],[-86.832595,33.480141],[-86.841728,33.433242],[-86.812944,33.447256],[-86.765577,33.473216]],[[-86.790255,33.46707],[-86.78338,33.469219],[-86.782147,33.466721],[-86.79369,33.461529],[-86.795104,33.4662],[-86.790255,33.46707]]]},"properties":{"id":"35209","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.652598,33.511473],[-86.617812,33.544584],[-86.625854,33.601577],[-86.670026,33.582246],[-86.716497,33.538825],[-86.730845,33.531153],[-86.701184,33.51504],[-86.693526,33.492295],[-86.652598,33.511473]]]},"properties":{"id":"35210","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.860817,33.53405],[-86.854285,33.588821],[-86.872214,33.595117],[-86.916694,33.639443],[-86.915968,33.635381],[-86.928909,33.613953],[-86.942674,33.570016],[-86.923543,33.54768],[-86.894533,33.526558],[-86.879185,33.517279],[-86.860817,33.53405]]]},"properties":{"id":"35214","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.936297,33.494912],[-86.951402,33.457937],[-86.929693,33.478538],[-86.91181,33.500891],[-86.894533,33.526558],[-86.923543,33.54768],[-86.966746,33.553626],[-86.96714,33.551747],[-86.992698,33.547162],[-86.986391,33.538376],[-86.999082,33.500027],[-86.936297,33.494912]]]},"properties":{"id":"35224","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.904845,33.491703],[-86.904231,33.478316],[-86.880814,33.483217],[-86.854596,33.501556],[-86.856051,33.512065],[-86.871388,33.514282],[-86.878911,33.515143],[-86.904845,33.491703]]]},"properties":{"id":"35208","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.128139,33.304067],[-87.086546,33.340991],[-87.092912,33.345892],[-87.341698,33.470501],[-87.383543,33.430923],[-87.394202,33.287016],[-87.300039,33.190128],[-87.128139,33.304067]]]},"properties":{"id":"35444","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.790255,33.46707],[-86.795104,33.4662],[-86.79369,33.461529],[-86.782147,33.466721],[-86.78338,33.469219],[-86.790255,33.46707]]]},"properties":{"id":"35229","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.900072,33.307949],[-87.025976,33.231607],[-87.025614,33.179147],[-86.920243,33.220411],[-86.839732,33.255567],[-86.824372,33.27176],[-86.830597,33.332015],[-86.900072,33.307949]]]},"properties":{"id":"35080","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.303621,35.000981],[-87.492952,35.003053],[-87.418231,34.900358],[-87.331794,34.909618],[-87.303621,35.000981]]]},"properties":{"id":"35648","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.056847,32.509271],[-85.158713,32.514809],[-85.2126,32.452154],[-85.030229,32.475166],[-85.056847,32.509271]]]},"properties":{"id":"36870","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.824563,32.637137],[-85.797458,32.513303],[-85.598052,32.509112],[-85.585674,32.514113],[-85.68703,32.618848],[-85.813849,32.637501],[-85.824563,32.637137]]]},"properties":{"id":"36866","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.489666,32.133967],[-86.352405,32.109654],[-86.328493,32.10821],[-86.333133,32.128831],[-86.356583,32.270262],[-86.484536,32.284333],[-86.534114,32.187274],[-86.489666,32.133967]]]},"properties":{"id":"36043","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.535081,34.84475],[-86.584002,34.840893],[-86.645779,34.822396],[-86.660526,34.814287],[-86.651031,34.771515],[-86.59992,34.748179],[-86.592393,34.749321],[-86.535081,34.84475]]]},"properties":{"id":"35810","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.526162,34.700275],[-86.596536,34.690064],[-86.610269,34.667858],[-86.589298,34.628888],[-86.518815,34.650535],[-86.511269,34.668059],[-86.526162,34.700275]]]},"properties":{"id":"35802","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.703339,34.992123],[-86.606917,34.880655],[-86.539936,34.902747],[-86.439203,34.991031],[-86.703339,34.992123]]]},"properties":{"id":"35750","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.648264,34.751987],[-86.649062,34.756959],[-86.661714,34.757038],[-86.65502,34.751197],[-86.648264,34.751987]]]},"properties":{"id":"35896","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.775296,32.13762],[-87.782519,32.137273],[-87.779151,32.115792],[-87.775296,32.13762]]],[[[-87.775296,32.13762],[-87.704374,32.079683],[-87.704227,32.083615],[-87.661484,32.12999],[-87.659638,32.136009],[-87.700903,32.241543],[-87.775296,32.13762]]]]},"properties":{"id":"36754","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.581081,32.132641],[-87.507021,32.085189],[-87.473687,32.00369],[-87.39974,32.125144],[-87.425418,32.248106],[-87.504247,32.268876],[-87.581081,32.132641]]]},"properties":{"id":"36728","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.779151,32.115792],[-87.805297,31.990456],[-87.704374,32.079683],[-87.775296,32.13762],[-87.779151,32.115792]]]},"properties":{"id":"36736","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.557595,32.481606],[-87.628492,32.320983],[-87.507857,32.307335],[-87.422859,32.375577],[-87.385694,32.533201],[-87.443122,32.568723],[-87.557595,32.481606]]]},"properties":{"id":"36786","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.99622,30.983957],[-88.034766,30.968456],[-88.061525,30.911504],[-88.061537,30.908515],[-87.964686,30.9136],[-87.99622,30.983957]]]},"properties":{"id":"36505","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.077733,30.700927],[-88.055573,30.690044],[-88.050157,30.706605],[-88.066375,30.717868],[-88.077733,30.700927]]],[[[-88.047797,30.712122],[-88.053059,30.683855],[-88.056946,30.668485],[-88.052812,30.654582],[-88.042291,30.733232],[-88.047797,30.712122]]]]},"properties":{"id":"36603","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.130802,30.676089],[-88.190702,30.67454],[-88.190831,30.639218],[-88.126431,30.661539],[-88.130802,30.676089]]]},"properties":{"id":"36609","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.058317,31.144083],[-87.972869,31.162694],[-87.946588,31.19293],[-87.938092,31.22068],[-88.008334,31.187338],[-88.058317,31.144083]]]},"properties":{"id":"36513","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.081683,30.724855],[-88.081624,30.723641],[-88.080823,30.724608],[-88.081683,30.724855]]]},"properties":{"id":"36616","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.499358,31.565206],[-87.566421,31.659982],[-87.621428,31.514639],[-87.564058,31.479435],[-87.499358,31.565206]]]},"properties":{"id":"36470","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.262643,32.352382],[-86.286252,32.372166],[-86.299732,32.351892],[-86.299615,32.344582],[-86.262643,32.352382]]],[[[-86.262643,32.352382],[-86.23494,32.328324],[-86.221572,32.34847],[-86.213337,32.363296],[-86.268362,32.365951],[-86.262643,32.352382]]]]},"properties":{"id":"36106","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.684157,34.502314],[-86.749308,34.559081],[-86.800302,34.552623],[-86.855981,34.499113],[-86.777748,34.415545],[-86.764384,34.397017],[-86.653888,34.369274],[-86.607808,34.37025],[-86.581328,34.367154],[-86.57853,34.387617],[-86.622012,34.473782],[-86.684157,34.502314]]]},"properties":{"id":"35670","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.995245,32.453122],[-85.030229,32.475166],[-85.2126,32.452154],[-85.220644,32.450774],[-85.244918,32.420036],[-85.054031,32.342387],[-85.003097,32.346707],[-84.995245,32.453122]]]},"properties":{"id":"36869","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.186779,33.65732],[-86.295994,33.685047],[-86.332396,33.668565],[-86.395595,33.595685],[-86.398292,33.592235],[-86.242318,33.590058],[-86.186779,33.65732]]]},"properties":{"id":"35125","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.477446,33.356924],[-86.551975,33.335577],[-86.433337,33.274064],[-86.361003,33.30717],[-86.367509,33.335692],[-86.477446,33.356924]]]},"properties":{"id":"35078","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.824372,33.27176],[-86.730476,33.254131],[-86.718792,33.261006],[-86.7104,33.328397],[-86.738898,33.377228],[-86.830597,33.332015],[-86.824372,33.27176]]]},"properties":{"id":"35124","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.556222,33.47034],[-86.443636,33.502336],[-86.48186,33.512245],[-86.556222,33.47034]]]},"properties":{"id":"35176","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.299726,32.554771],[-88.212871,32.357833],[-87.930661,32.310574],[-88.041467,32.414774],[-88.132634,32.412305],[-88.174157,32.389761],[-88.175288,32.433048],[-88.143278,32.453213],[-88.299726,32.554771]]]},"properties":{"id":"36925","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.212669,33.261439],[-86.303069,33.46316],[-86.310629,33.452551],[-86.367509,33.335692],[-86.361003,33.30717],[-86.274939,33.270165],[-86.223048,33.236539],[-86.212669,33.261439]]]},"properties":{"id":"35014","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.638134,33.233939],[-87.551761,33.236891],[-87.534872,33.315514],[-87.641491,33.293746],[-87.638134,33.233939]]]},"properties":{"id":"35473","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.532166,33.223221],[-87.525984,33.187213],[-87.473383,33.183667],[-87.460915,33.253103],[-87.532166,33.223221]]]},"properties":{"id":"35404","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.460915,33.253103],[-87.394202,33.287016],[-87.383543,33.430923],[-87.534872,33.315514],[-87.551761,33.236891],[-87.564641,33.217263],[-87.532166,33.223221],[-87.460915,33.253103]]]},"properties":{"id":"35406","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.362284,33.809759],[-87.459986,33.738634],[-87.481098,33.720895],[-87.40176,33.536412],[-87.33065,33.491646],[-87.25474,33.553341],[-87.32648,33.772054],[-87.362284,33.809759]]]},"properties":{"id":"35579","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.420997,33.840719],[-87.459986,33.738634],[-87.362284,33.809759],[-87.420997,33.840719]]]},"properties":{"id":"35587","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.16439,31.61381],[-88.185346,31.508962],[-88.09943,31.47954],[-88.018881,31.475639],[-88.020322,31.553427],[-88.069482,31.592733],[-88.16439,31.61381]]]},"properties":{"id":"36569","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.179187,31.228798],[-88.100416,31.144109],[-88.058317,31.144083],[-88.008334,31.187338],[-87.94091,31.238699],[-87.955848,31.284605],[-88.008538,31.337575],[-88.146934,31.304298],[-88.182921,31.244883],[-88.179187,31.228798]]]},"properties":{"id":"36553","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.321431,32.125144],[-87.249989,32.05892],[-87.260971,32.123952],[-87.251999,32.142648],[-87.425418,32.248106],[-87.39974,32.125144],[-87.321431,32.125144]]]},"properties":{"id":"36720","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.100413,31.984813],[-87.10582,31.927267],[-87.069638,31.938698],[-87.100413,31.984813]]]},"properties":{"id":"36766","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.596397,34.087043],[-87.613998,34.117553],[-87.636443,34.092548],[-87.596397,34.087043]]]},"properties":{"id":"35577","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.829741,32.479407],[-86.782467,32.375137],[-86.588261,32.367749],[-86.750038,32.554643],[-86.798105,32.559106],[-86.829741,32.479407]]]},"properties":{"id":"36003","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.750038,32.554643],[-86.625963,32.684779],[-86.632171,32.730479],[-86.757271,32.707445],[-86.781806,32.670267],[-86.791045,32.630983],[-86.798105,32.559106],[-86.750038,32.554643]]]},"properties":{"id":"36006","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.575881,30.29868],[-87.668034,30.245533],[-87.50581,30.315086],[-87.575881,30.29868]]]},"properties":{"id":"36561","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.784705,30.396128],[-87.768934,30.392095],[-87.767939,30.399968],[-87.78059,30.405637],[-87.784705,30.396128]]]},"properties":{"id":"36555","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.70572,31.708803],[-85.844173,31.542215],[-85.868412,31.520974],[-85.818818,31.508776],[-85.814676,31.50932],[-85.572552,31.558397],[-85.528298,31.619219],[-85.694933,31.704077],[-85.70572,31.708803]]]},"properties":{"id":"36311","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.377925,32.874615],[-87.211556,32.835175],[-87.289213,33.031126],[-87.318327,33.013328],[-87.377925,32.874615]]]},"properties":{"id":"35034","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.945585,32.837721],[-86.856986,32.901693],[-86.85568,33.001981],[-86.876297,33.003059],[-86.969551,32.959552],[-86.970682,32.900546],[-86.945585,32.837721]]]},"properties":{"id":"36792","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.318327,33.013328],[-87.289213,33.031126],[-87.289752,33.063231],[-87.3121,33.088168],[-87.47232,33.14575],[-87.539426,33.031705],[-87.421936,33.003379],[-87.318327,33.013328]]]},"properties":{"id":"35456","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.915194,32.721496],[-86.897076,32.734623],[-86.878249,32.766522],[-86.868787,32.751531],[-86.880777,32.735284],[-86.781806,32.670267],[-86.757271,32.707445],[-86.779016,32.824343],[-86.802529,32.893312],[-86.856986,32.901693],[-86.945585,32.837721],[-86.963805,32.772622],[-86.94087,32.735298],[-86.915194,32.721496]]]},"properties":{"id":"36750","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.433683,34.059858],[-86.540903,34.019811],[-86.582871,33.882403],[-86.53272,33.83106],[-86.31776,33.902058],[-86.316884,33.922188],[-86.321312,33.950002],[-86.433683,34.059858]],[[-86.519245,33.902354],[-86.516311,33.902166],[-86.516646,33.900188],[-86.519282,33.900214],[-86.519245,33.902354]]]},"properties":{"id":"35121","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.656917,33.894818],[-86.584507,33.885225],[-86.582871,33.882403],[-86.540903,34.019811],[-86.59756,34.003964],[-86.673015,33.900319],[-86.656917,33.894818]]]},"properties":{"id":"35049","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.725964,33.862644],[-86.673015,33.900319],[-86.59756,34.003964],[-86.706017,34.031306],[-86.736688,34.012006],[-86.744089,34.007807],[-86.92369,33.872224],[-86.963358,33.858221],[-86.934556,33.816374],[-86.725964,33.862644]]]},"properties":{"id":"35079","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.52499,32.349252],[-85.709301,32.233444],[-85.781104,32.233269],[-85.854967,32.231996],[-85.896429,32.06259],[-85.779756,31.97322],[-85.636238,32.0156],[-85.493064,32.187266],[-85.52499,32.349252]]]},"properties":{"id":"36089","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.886679,31.961647],[-86.90668,31.81507],[-86.809708,31.815575],[-86.851173,31.962177],[-86.886679,31.961647]]]},"properties":{"id":"36030","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.827954,33.623267],[-85.737379,33.599823],[-85.638586,33.649158],[-85.63838,33.730165],[-85.694152,33.80298],[-85.722233,33.751833],[-85.725311,33.748032],[-85.811064,33.681203],[-85.827954,33.623267]]]},"properties":{"id":"36207","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.885533,33.735079],[-85.884484,33.720339],[-85.819029,33.698542],[-85.817946,33.701388],[-85.79379,33.738852],[-85.725311,33.748032],[-85.722233,33.751833],[-85.786053,33.754903],[-85.858689,33.738686],[-85.885533,33.735079]]]},"properties":{"id":"36206","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.91621,33.797068],[-85.915378,33.866172],[-85.987542,33.879205],[-86.065272,33.842198],[-86.065043,33.803723],[-86.145562,33.679098],[-86.017874,33.700055],[-85.961678,33.753821],[-85.91621,33.797068]]]},"properties":{"id":"36271","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.64954,34.524735],[-85.627512,34.39592],[-85.504824,34.485762],[-85.546412,34.680287],[-85.64954,34.524735]]]},"properties":{"id":"35984","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.63645,33.099176],[-86.740139,33.190302],[-86.793497,33.166153],[-86.790919,33.027918],[-86.610143,33.070002],[-86.63645,33.099176]]]},"properties":{"id":"35040","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.296145,31.993258],[-88.328465,32.001877],[-88.401143,31.991286],[-88.296145,31.993258]]]},"properties":{"id":"36915","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.697743,31.562338],[-87.955797,31.735169],[-88.080207,31.677064],[-88.069482,31.592733],[-88.020322,31.553427],[-87.915253,31.451478],[-87.955848,31.284605],[-87.94091,31.238699],[-87.938092,31.22068],[-87.946588,31.19293],[-87.765152,31.297346],[-87.691958,31.405062],[-87.564058,31.479435],[-87.621428,31.514639],[-87.697743,31.562338]]]},"properties":{"id":"36545","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.857396,33.469575],[-85.609105,33.411575],[-85.623645,33.495373],[-85.743627,33.564163],[-85.825126,33.519402],[-85.87635,33.469504],[-85.857396,33.469575]]]},"properties":{"id":"36258","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.920687,33.332186],[-85.955128,33.208934],[-85.936401,33.18963],[-85.841259,33.104456],[-85.840717,33.104455],[-85.732434,33.234851],[-85.920687,33.332186]]]},"properties":{"id":"36251","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.955128,33.208934],[-85.959293,33.195397],[-85.936401,33.18963],[-85.955128,33.208934]]]},"properties":{"id":"36267","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.153727,33.195361],[-86.173837,33.128369],[-86.129876,33.153905],[-86.153727,33.195361]]]},"properties":{"id":"35082","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.638586,33.649158],[-85.737379,33.599823],[-85.743627,33.564163],[-85.623645,33.495373],[-85.529213,33.457938],[-85.434569,33.467865],[-85.437729,33.493779],[-85.377951,33.638099],[-85.398633,33.685027],[-85.561283,33.73964],[-85.638586,33.649158]]]},"properties":{"id":"36264","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.376433,33.85065],[-85.398633,33.685027],[-85.377951,33.638099],[-85.332276,33.623541],[-85.376433,33.85065]]]},"properties":{"id":"36269","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.292911,31.398953],[-86.331466,31.248196],[-86.360804,31.190822],[-86.230476,31.140472],[-86.14228,31.33797],[-86.195397,31.431309],[-86.229225,31.441521],[-86.292911,31.398953]]]},"properties":{"id":"36467","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.925126,31.488098],[-86.011967,31.282791],[-85.84526,31.418625],[-85.818818,31.508776],[-85.868412,31.520974],[-85.925126,31.488098]]]},"properties":{"id":"36351","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.707209,31.385241],[-85.612124,31.351157],[-85.558989,31.35759],[-85.463324,31.474179],[-85.471776,31.500598],[-85.572552,31.558397],[-85.814676,31.50932],[-85.707209,31.385241]]]},"properties":{"id":"36360","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.006817,34.57715],[-88.035558,34.488648],[-87.948682,34.447187],[-87.92881,34.429132],[-87.751867,34.451896],[-87.770569,34.572226],[-87.988324,34.602469],[-88.006817,34.57715]]]},"properties":{"id":"35653","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.163413,31.306994],[-86.871022,31.261902],[-87.108021,31.433511],[-87.163413,31.306994]]]},"properties":{"id":"36432","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.757304,31.431435],[-87.001619,31.716428],[-87.023797,31.71273],[-87.158857,31.674144],[-87.160096,31.553688],[-87.165662,31.542897],[-87.108021,31.433511],[-86.871022,31.261902],[-86.763961,31.261293],[-86.70113,31.315663],[-86.757304,31.431435]]]},"properties":{"id":"36401","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.165662,31.542897],[-87.353471,31.356026],[-87.378673,31.324916],[-87.339969,31.260608],[-87.207538,31.279942],[-87.212135,31.330159],[-87.209517,31.34635],[-87.189841,31.328036],[-87.181987,31.299646],[-87.163413,31.306994],[-87.108021,31.433511],[-87.165662,31.542897]]]},"properties":{"id":"36475","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.160096,31.553688],[-87.158857,31.674144],[-87.309911,31.68911],[-87.379505,31.626518],[-87.160096,31.553688]]]},"properties":{"id":"36471","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.106533,32.846231],[-85.972233,32.745837],[-85.921124,32.707124],[-85.907668,32.698717],[-85.885534,32.81833],[-85.787274,32.96495],[-85.763722,32.97708],[-85.794589,33.079762],[-85.840717,33.104455],[-85.841259,33.104456],[-85.985196,33.035148],[-86.106533,32.846231]]]},"properties":{"id":"35010","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.996059,32.010371],[-86.256727,32.036458],[-86.256933,31.918428],[-86.226123,31.889924],[-86.183084,31.832048],[-86.069986,31.967264],[-86.038834,31.967297],[-85.996059,32.010371]]]},"properties":{"id":"36036","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.878719,34.207513],[-86.672666,34.178235],[-86.645016,34.245606],[-86.686771,34.293089],[-86.751396,34.306928],[-86.878911,34.230695],[-86.878719,34.207513]]]},"properties":{"id":"35058","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.672666,34.178235],[-86.878719,34.207513],[-86.866138,34.101469],[-86.647871,34.108474],[-86.619706,34.120796],[-86.672666,34.178235]]]},"properties":{"id":"35055","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.12698,32.340843],[-87.16407,32.487322],[-87.385694,32.533201],[-87.422859,32.375577],[-87.295311,32.285959],[-87.12698,32.340843]]]},"properties":{"id":"36759","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.985678,34.3769],[-85.977831,34.343018],[-85.98059,34.332459],[-85.977873,34.330936],[-85.925266,34.304707],[-85.886417,34.412955],[-85.985678,34.3769]]]},"properties":{"id":"35963","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.790018,34.39335],[-85.787402,34.393191],[-85.691678,34.518515],[-85.745787,34.594805],[-85.776926,34.580055],[-85.772396,34.536004],[-85.850479,34.433361],[-85.775483,34.41885],[-85.790018,34.39335]]]},"properties":{"id":"35968","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.156714,34.434634],[-86.25773,34.301851],[-86.162996,34.234487],[-86.107797,34.277313],[-86.094341,34.270482],[-86.076145,34.238999],[-86.047355,34.374453],[-86.033105,34.393601],[-86.156714,34.434634]]]},"properties":{"id":"35951","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.717687,33.52019],[-87.40176,33.536412],[-87.481098,33.720895],[-87.649976,33.737477],[-87.666633,33.577316],[-87.717687,33.52019]]]},"properties":{"id":"35546","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.769783,34.277637],[-87.886433,34.333505],[-87.924963,34.297084],[-87.806598,34.189084],[-87.769783,34.277637]]]},"properties":{"id":"35564","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.624612,33.045354],[-87.589245,33.0856],[-87.70256,33.157611],[-87.711256,33.098321],[-87.760281,33.024375],[-87.712009,33.015624],[-87.624612,33.045354]]]},"properties":{"id":"35463","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.184019,31.437404],[-85.229359,31.309375],[-85.200926,31.2717],[-85.2063,31.263075],[-85.154774,31.213564],[-85.107544,31.189841],[-85.079818,31.411732],[-85.130918,31.45727],[-85.184019,31.437404]]]},"properties":{"id":"36319","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.084648,34.989594],[-86.311274,34.991098],[-86.271679,34.923961],[-86.196174,34.861141],[-86.084648,34.989594]]]},"properties":{"id":"35745","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.313112,34.629071],[-86.270009,34.725019],[-86.280191,34.774318],[-86.340002,34.78431],[-86.313112,34.629071]]]},"properties":{"id":"35764","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.905156,33.40616],[-86.990093,33.400709],[-87.050227,33.370585],[-87.044829,33.246667],[-87.025976,33.231607],[-86.900072,33.307949],[-86.87107,33.363352],[-86.868876,33.409808],[-86.905156,33.40616]]]},"properties":{"id":"35022","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.91181,33.500891],[-86.929693,33.478538],[-86.904231,33.478316],[-86.904845,33.491703],[-86.91181,33.500891]]]},"properties":{"id":"35064","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.788923,33.496949],[-86.765577,33.473216],[-86.756726,33.46237],[-86.709823,33.48557],[-86.693526,33.492295],[-86.701184,33.51504],[-86.783112,33.499265],[-86.788923,33.496949]]]},"properties":{"id":"35223","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.854596,33.501556],[-86.834543,33.498945],[-86.822733,33.504598],[-86.817538,33.523693],[-86.82751,33.545357],[-86.860817,33.53405],[-86.879185,33.517279],[-86.878911,33.515143],[-86.871388,33.514282],[-86.855162,33.520183],[-86.856051,33.512065],[-86.854596,33.501556]]]},"properties":{"id":"35204","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.701184,33.51504],[-86.730845,33.531153],[-86.738554,33.527645],[-86.773471,33.506301],[-86.783112,33.499265],[-86.701184,33.51504]]]},"properties":{"id":"35213","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.771626,33.643336],[-86.765924,33.65324],[-86.758023,33.66231],[-86.752386,33.676861],[-86.814278,33.759399],[-86.825085,33.770258],[-86.953509,33.710977],[-86.948084,33.708485],[-86.86356,33.629749],[-86.795956,33.631436],[-86.771767,33.643143],[-86.771626,33.643336]]]},"properties":{"id":"35071","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.778998,33.540159],[-86.783238,33.552622],[-86.786942,33.565073],[-86.82751,33.545357],[-86.817538,33.523693],[-86.794972,33.530768],[-86.778998,33.540159]]]},"properties":{"id":"35234","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.758023,33.66231],[-86.765924,33.65324],[-86.771626,33.643336],[-86.758023,33.66231]]]},"properties":{"id":"35119","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.341698,33.470501],[-87.092912,33.345892],[-87.230183,33.538843],[-87.25474,33.553341],[-87.33065,33.491646],[-87.341698,33.470501]]]},"properties":{"id":"35006","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.983242,33.709428],[-86.965299,33.703434],[-86.948084,33.708485],[-86.953509,33.710977],[-86.964923,33.711153],[-86.983242,33.709428]]]},"properties":{"id":"35139","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.950838,33.823146],[-88.238202,33.653393],[-88.035869,33.682238],[-87.98986,33.708446],[-87.950838,33.823146]]]},"properties":{"id":"35592","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.19848,34.128546],[-88.056813,34.040898],[-88.01128,34.053057],[-87.963517,34.022502],[-87.840861,34.085255],[-87.766919,34.120609],[-87.763076,34.136489],[-87.806598,34.189084],[-87.924963,34.297084],[-88.025469,34.303769],[-88.187305,34.21062],[-88.19848,34.128546]]]},"properties":{"id":"35570","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.795876,34.748973],[-86.713531,34.815691],[-86.660526,34.814287],[-86.645779,34.822396],[-86.802426,34.835353],[-86.823202,34.752084],[-86.795876,34.748973]]]},"properties":{"id":"35749","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.529542,34.747802],[-86.526162,34.700275],[-86.511269,34.668059],[-86.443664,34.703305],[-86.43109,34.756945],[-86.529542,34.747802]]]},"properties":{"id":"35741","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.550166,34.545963],[-86.468831,34.475652],[-86.462611,34.478409],[-86.452986,34.548559],[-86.518815,34.650535],[-86.589298,34.628888],[-86.586504,34.582747],[-86.550166,34.545963]]]},"properties":{"id":"35803","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.8036,34.003854],[-87.636108,34.05979],[-87.63636,34.080475],[-87.766919,34.120609],[-87.840861,34.085255],[-87.8036,34.003854]]]},"properties":{"id":"35548","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.176519,30.881439],[-88.246627,30.85251],[-88.217246,30.773667],[-88.132544,30.74895],[-88.101002,30.767656],[-88.100768,30.787712],[-88.176519,30.881439]]]},"properties":{"id":"36613","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.080862,30.887071],[-88.032789,30.858977],[-87.964686,30.9136],[-88.061537,30.908515],[-88.080862,30.887071]]]},"properties":{"id":"36525","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.238914,30.426953],[-88.288701,30.411487],[-88.292841,30.387776],[-88.271884,30.373883],[-88.225535,30.40712],[-88.238914,30.426953]]]},"properties":{"id":"36509","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.061525,30.911504],[-88.176519,30.881439],[-88.100768,30.787712],[-88.057301,30.77881],[-88.030449,30.84079],[-88.080862,30.887071],[-88.061537,30.908515],[-88.061525,30.911504]]]},"properties":{"id":"36571","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.32459,30.9356],[-88.424225,30.967032],[-88.408013,30.636947],[-88.329077,30.718481],[-88.252598,30.856279],[-88.32459,30.9356]]]},"properties":{"id":"36587","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.049496,31.007396],[-88.034766,30.968456],[-87.99622,30.983957],[-88.020229,31.016781],[-88.049496,31.007396]]]},"properties":{"id":"36512","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.18445,30.694763],[-88.179337,30.6992],[-88.190967,30.701265],[-88.190394,30.693796],[-88.18445,30.694763]]]},"properties":{"id":"36688","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.033665,31.829669],[-87.119296,31.828903],[-87.078449,31.785332],[-87.033665,31.829669]]]},"properties":{"id":"36753","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.352279,31.412916],[-87.344072,31.420294],[-87.353476,31.435094],[-87.352279,31.412916]]]},"properties":{"id":"36439","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.286252,32.372166],[-86.262643,32.352382],[-86.268362,32.365951],[-86.265472,32.40916],[-86.288287,32.400062],[-86.286252,32.372166]]]},"properties":{"id":"36107","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.062401,34.588207],[-86.923305,34.586692],[-87.046129,34.650467],[-87.105073,34.686037],[-87.105424,34.652728],[-87.062401,34.588207]]]},"properties":{"id":"35601","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.304439,33.482884],[-85.437729,33.493779],[-85.434569,33.467865],[-85.296492,33.440624],[-85.304439,33.482884]]]},"properties":{"id":"36263","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.493386,33.674865],[-86.472304,33.679755],[-86.492973,33.672009],[-86.509728,33.63726],[-86.462422,33.578612],[-86.417162,33.593136],[-86.395595,33.595685],[-86.332396,33.668565],[-86.350095,33.710497],[-86.498986,33.727604],[-86.493386,33.674865]]]},"properties":{"id":"35120","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.967058,31.434821],[-88.008538,31.337575],[-87.955848,31.284605],[-87.915253,31.451478],[-87.967058,31.434821]]]},"properties":{"id":"36581","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.16439,31.61381],[-88.069482,31.592733],[-88.080207,31.677064],[-88.088288,31.699303],[-88.267431,31.696276],[-88.16439,31.61381]]]},"properties":{"id":"36538","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.322224,31.201681],[-88.326391,31.266139],[-88.439295,31.258225],[-88.432007,31.114298],[-88.322224,31.201681]]]},"properties":{"id":"36584","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.34506,34.002598],[-87.245152,34.02758],[-87.260328,34.066863],[-87.368899,34.218082],[-87.505325,34.083306],[-87.34506,34.002598]]]},"properties":{"id":"35553","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.63636,34.080475],[-87.636108,34.05979],[-87.636118,34.002203],[-87.515791,34.070144],[-87.596397,34.087043],[-87.636443,34.092548],[-87.63636,34.080475]]]},"properties":{"id":"35575","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.94087,32.735298],[-86.995144,32.621122],[-86.951183,32.569338],[-86.900401,32.570194],[-86.791045,32.630983],[-86.781806,32.670267],[-86.880777,32.735284],[-86.915194,32.721496],[-86.94087,32.735298]]]},"properties":{"id":"36758","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.560218,32.762079],[-86.632171,32.730479],[-86.625963,32.684779],[-86.585523,32.65446],[-86.371151,32.750627],[-86.374974,32.75358],[-86.460581,32.832051],[-86.560218,32.762079]]]},"properties":{"id":"36091","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.665212,30.696879],[-87.733738,30.567236],[-87.732234,30.517446],[-87.561601,30.486582],[-87.513394,30.512044],[-87.440373,30.533855],[-87.531037,30.743252],[-87.665212,30.696879]]]},"properties":{"id":"36567","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.468306,30.480095],[-87.492045,30.330872],[-87.434794,30.481888],[-87.468306,30.480095]]]},"properties":{"id":"36549","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.802903,30.483204],[-87.817471,30.435698],[-87.643645,30.450824],[-87.561601,30.486582],[-87.732234,30.517446],[-87.802903,30.483204]]]},"properties":{"id":"36580","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.918197,30.472508],[-87.924737,30.479702],[-87.932395,30.483622],[-87.934649,30.486237],[-87.937272,30.483036],[-87.927841,30.479251],[-87.922196,30.474598],[-87.918197,30.472508]]]},"properties":{"id":"36564","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.098183,32.836008],[-87.04746,32.6972],[-87.017762,32.729532],[-86.963805,32.772622],[-86.945585,32.837721],[-86.970682,32.900546],[-87.098183,32.836008]]]},"properties":{"id":"36793","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.679242,33.805274],[-86.571061,33.765179],[-86.53272,33.83106],[-86.582871,33.882403],[-86.584507,33.885225],[-86.647837,33.871695],[-86.679242,33.805274]]]},"properties":{"id":"35133","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.998977,32.23507],[-85.997118,32.079698],[-85.966389,32.054446],[-85.896429,32.06259],[-85.854967,32.231996],[-85.91703,32.313612],[-85.954314,32.302891],[-85.998977,32.23507]]]},"properties":{"id":"36029","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.817946,33.701388],[-85.771529,33.718242],[-85.79379,33.738852],[-85.817946,33.701388]]]},"properties":{"id":"36205","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.554876,32.842288],[-85.626769,32.669235],[-85.520431,32.632133],[-85.500369,32.70832],[-85.43414,32.730303],[-85.554876,32.842288]]]},"properties":{"id":"36879","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.560218,32.762079],[-86.664901,32.942197],[-86.699627,32.924982],[-86.779016,32.824343],[-86.757271,32.707445],[-86.632171,32.730479],[-86.560218,32.762079]]]},"properties":{"id":"35045","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.779016,32.824343],[-86.699627,32.924982],[-86.802529,32.893312],[-86.779016,32.824343]]]},"properties":{"id":"35171","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.0152,32.237586],[-88.135973,32.213834],[-88.105441,32.195304],[-88.016846,32.153413],[-88.014454,32.233503],[-88.0152,32.237586]]]},"properties":{"id":"36916","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.212871,32.357833],[-88.344405,32.308157],[-88.168031,32.229841],[-88.135973,32.213834],[-88.0152,32.237586],[-87.9297,32.292516],[-87.930661,32.310574],[-88.212871,32.357833]]]},"properties":{"id":"36922","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.468883,31.930324],[-88.442639,31.993453],[-88.460686,31.995426],[-88.468883,31.930324]]]},"properties":{"id":"36913","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.947382,31.89855],[-88.07349,31.990182],[-88.138271,31.875578],[-87.947382,31.89855]]]},"properties":{"id":"36727","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.947382,31.89855],[-88.138271,31.875578],[-88.158366,31.853511],[-88.179302,31.821297],[-88.088288,31.699303],[-88.080207,31.677064],[-87.955797,31.735169],[-87.824798,31.841116],[-87.947382,31.89855]]]},"properties":{"id":"36524","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.609105,33.411575],[-85.857396,33.469575],[-85.920687,33.332186],[-85.732434,33.234851],[-85.653306,33.188983],[-85.612279,33.24758],[-85.609105,33.411575]]]},"properties":{"id":"36266","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.840717,33.104455],[-85.794589,33.079762],[-85.755797,33.124256],[-85.653306,33.188983],[-85.732434,33.234851],[-85.840717,33.104455]]]},"properties":{"id":"36255","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.861592,31.744108],[-85.9461,31.619027],[-85.844173,31.542215],[-85.70572,31.708803],[-85.702413,31.739095],[-85.794441,31.784163],[-85.861592,31.744108]]]},"properties":{"id":"36010","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.037824,31.550217],[-85.925126,31.488098],[-85.868412,31.520974],[-85.844173,31.542215],[-85.9461,31.619027],[-86.037824,31.550217]]]},"properties":{"id":"36346","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.61856,34.594865],[-87.770569,34.572226],[-87.751867,34.451896],[-87.73275,34.396402],[-87.576982,34.372728],[-87.530649,34.367248],[-87.529778,34.566085],[-87.524458,34.566932],[-87.61856,34.594865]]]},"properties":{"id":"35654","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.499349,31.438185],[-86.480534,31.425496],[-86.480384,31.453252],[-86.497485,31.452791],[-86.499349,31.438185]]]},"properties":{"id":"36038","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.151036,33.993225],[-87.110645,33.956473],[-86.991599,34.046547],[-86.975651,34.094004],[-87.133001,34.067845],[-87.151036,33.993225]]]},"properties":{"id":"35053","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.645016,34.245606],[-86.583148,34.261641],[-86.607808,34.37025],[-86.653888,34.369274],[-86.686771,34.293089],[-86.645016,34.245606]]]},"properties":{"id":"35019","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.539525,31.253043],[-85.42599,31.325651],[-85.426661,31.418436],[-85.463324,31.474179],[-85.558989,31.35759],[-85.552627,31.346322],[-85.518601,31.307772],[-85.561571,31.292988],[-85.539525,31.253043]]]},"properties":{"id":"36350","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.102123,32.212837],[-86.907981,32.156686],[-86.908333,32.203384],[-87.092978,32.318407],[-87.102123,32.212837]]]},"properties":{"id":"36775","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.118474,32.047249],[-86.886679,31.961647],[-86.851173,31.962177],[-86.708274,31.962641],[-86.735792,32.070709],[-86.853893,32.118392],[-86.907981,32.156686],[-87.102123,32.212837],[-87.232764,32.16453],[-87.251999,32.142648],[-87.260971,32.123952],[-87.118474,32.047249]]]},"properties":{"id":"36761","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.812997,34.579893],[-85.776926,34.580055],[-85.745787,34.594805],[-85.592812,34.696898],[-85.600303,34.694953],[-85.705727,34.709535],[-85.85917,34.647282],[-85.869953,34.623719],[-85.812997,34.579893]]]},"properties":{"id":"35978","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.018466,34.390876],[-85.985678,34.3769],[-85.886417,34.412955],[-85.875225,34.42643],[-85.881114,34.562315],[-85.910665,34.543935],[-86.012252,34.480695],[-86.018466,34.390876]]]},"properties":{"id":"35971","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.583145,34.860371],[-85.644691,34.8441],[-85.57562,34.821151],[-85.583145,34.860371]]]},"properties":{"id":"35979","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.189766,33.983454],[-86.177013,34.048044],[-86.321312,33.950002],[-86.316884,33.922188],[-86.189766,33.983454]]]},"properties":{"id":"35972","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.93375,34.154928],[-86.105364,33.960261],[-86.025602,33.981316],[-85.869112,34.140929],[-85.93375,34.154928]]]},"properties":{"id":"35904","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.008586,34.199436],[-86.303516,34.099073],[-86.150543,34.095738],[-86.002757,34.199324],[-86.008586,34.199436]]]},"properties":{"id":"35956","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.006817,34.57715],[-88.139988,34.581703],[-88.173632,34.321054],[-88.035558,34.488648],[-88.006817,34.57715]]]},"properties":{"id":"35582","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.156553,32.824903],[-88.178343,32.828535],[-88.21967,32.833683],[-88.21524,32.791812],[-88.156553,32.824903]]]},"properties":{"id":"35464","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.816325,32.819437],[-87.825864,32.767608],[-87.79247,32.660626],[-87.75001,32.637448],[-87.655531,32.786371],[-87.816828,32.832858],[-87.816325,32.819437]]]},"properties":{"id":"36776","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.100527,31.165994],[-85.200128,31.063098],[-85.200511,31.058496],[-85.292519,30.999886],[-85.002499,31.000685],[-85.100527,31.165994]]]},"properties":{"id":"36343","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.983724,34.699067],[-86.221992,34.622645],[-86.208953,34.590765],[-86.261121,34.466074],[-86.221765,34.443146],[-86.055289,34.556762],[-85.969397,34.633958],[-85.940952,34.657272],[-85.951049,34.656131],[-85.983724,34.699067]]]},"properties":{"id":"35769","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.990093,33.400709],[-86.905156,33.40616],[-86.917591,33.420828],[-86.915317,33.440759],[-86.951402,33.457937],[-86.965074,33.444616],[-86.990093,33.400709]]]},"properties":{"id":"35020","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.812944,33.447256],[-86.806612,33.384634],[-86.757808,33.400478],[-86.752563,33.412219],[-86.756726,33.46237],[-86.765577,33.473216],[-86.812944,33.447256]]]},"properties":{"id":"35216","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.756726,33.46237],[-86.752563,33.412219],[-86.709823,33.48557],[-86.756726,33.46237]]]},"properties":{"id":"35243","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.551266,33.742164],[-86.615863,33.675773],[-86.625854,33.601577],[-86.617812,33.544584],[-86.551613,33.587507],[-86.509728,33.63726],[-86.492973,33.672009],[-86.493386,33.674865],[-86.498986,33.727604],[-86.551266,33.742164]]]},"properties":{"id":"35173","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.806612,33.384634],[-86.87107,33.363352],[-86.900072,33.307949],[-86.830597,33.332015],[-86.738898,33.377228],[-86.757808,33.400478],[-86.806612,33.384634]]]},"properties":{"id":"35244","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.757808,33.400478],[-86.738898,33.377228],[-86.7104,33.328397],[-86.657931,33.363124],[-86.57531,33.456011],[-86.652598,33.511473],[-86.693526,33.492295],[-86.709823,33.48557],[-86.752563,33.412219],[-86.757808,33.400478]]]},"properties":{"id":"35242","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.463499,33.578583],[-86.551613,33.587507],[-86.617812,33.544584],[-86.652598,33.511473],[-86.57531,33.456011],[-86.556222,33.47034],[-86.48186,33.512245],[-86.463499,33.578583]]]},"properties":{"id":"35094","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.070415,33.776901],[-87.046532,33.728942],[-87.022168,33.772717],[-87.070415,33.776901]]]},"properties":{"id":"35148","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.577478,35.003542],[-87.553626,34.826886],[-87.42651,34.800022],[-87.414982,34.800494],[-87.418231,34.900358],[-87.492952,35.003053],[-87.577478,35.003542]]]},"properties":{"id":"35645","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.283392,34.751947],[-87.311707,34.730636],[-87.243926,34.616866],[-87.21318,34.52685],[-87.105424,34.652728],[-87.105073,34.686037],[-87.260676,34.758626],[-87.283392,34.751947]]]},"properties":{"id":"35643","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.936403,34.730816],[-87.046129,34.650467],[-86.923305,34.586692],[-86.906675,34.579758],[-86.884997,34.600595],[-86.867034,34.632374],[-86.871328,34.656857],[-86.878957,34.6715],[-86.864878,34.67141],[-86.877541,34.729973],[-86.936403,34.730816]]]},"properties":{"id":"35671","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.904915,34.992738],[-86.805906,34.938383],[-86.713276,34.992093],[-86.904915,34.992738]]]},"properties":{"id":"35739","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.730681,32.403879],[-85.71114,32.394219],[-85.704745,32.43329],[-85.728498,32.436054],[-85.730681,32.403879]]]},"properties":{"id":"36088","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.121152,30.594734],[-88.258642,30.602773],[-88.277275,30.591561],[-88.270606,30.520713],[-88.238914,30.426953],[-88.225535,30.40712],[-88.102264,30.452086],[-88.080299,30.524269],[-88.113856,30.591771],[-88.121152,30.594734]],[[-88.17475,30.531286],[-88.174826,30.532329],[-88.174018,30.53222],[-88.17398,30.53135],[-88.17475,30.531286]]]},"properties":{"id":"36582","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.258642,30.602773],[-88.121152,30.594734],[-88.190834,30.610176],[-88.258642,30.602773]]]},"properties":{"id":"36619","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.277275,30.591561],[-88.404431,30.592874],[-88.406201,30.589708],[-88.292841,30.387776],[-88.288701,30.411487],[-88.259506,30.508751],[-88.291549,30.508714],[-88.270606,30.520713],[-88.277275,30.591561]]]},"properties":{"id":"36541","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.030449,30.84079],[-88.032789,30.858977],[-88.080862,30.887071],[-88.030449,30.84079]]]},"properties":{"id":"36572","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.270606,30.520713],[-88.291549,30.508714],[-88.259506,30.508751],[-88.270606,30.520713]]]},"properties":{"id":"36568","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.499358,31.565206],[-87.564058,31.479435],[-87.577002,31.432096],[-87.448637,31.314891],[-87.378673,31.324916],[-87.353471,31.356026],[-87.352279,31.412916],[-87.353476,31.435094],[-87.499358,31.565206]]]},"properties":{"id":"36445","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.162832,31.828416],[-87.289402,31.831278],[-87.36675,31.737609],[-87.309911,31.68911],[-87.158857,31.674144],[-87.023797,31.71273],[-87.033665,31.829669],[-87.078449,31.785332],[-87.119296,31.828903],[-87.162832,31.828416]]]},"properties":{"id":"36425","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229364,32.4212],[-86.265472,32.40916],[-86.268362,32.365951],[-86.213337,32.363296],[-86.229364,32.4212]],[[-86.245496,32.402207],[-86.257275,32.40322],[-86.253279,32.411288],[-86.239653,32.411817],[-86.245496,32.402207]]]},"properties":{"id":"36109","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.312436,32.351708],[-86.349974,32.372486],[-86.371486,32.365931],[-86.376565,32.393571],[-86.361851,32.398323],[-86.373071,32.429837],[-86.411172,32.409937],[-86.496774,32.344437],[-86.484536,32.284333],[-86.356583,32.270262],[-86.312436,32.351708]]]},"properties":{"id":"36108","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.064183,32.409063],[-86.176236,32.294645],[-86.152209,32.236548],[-86.122572,32.182941],[-86.016688,32.274409],[-85.991367,32.336081],[-86.023012,32.419978],[-86.064183,32.409063]]]},"properties":{"id":"36064","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.342842,32.384777],[-86.348975,32.388319],[-86.35254,32.374473],[-86.342842,32.384777]]]},"properties":{"id":"36112","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.016195,32.264755],[-85.008167,32.205421],[-85.005824,32.261893],[-85.016195,32.264755]]]},"properties":{"id":"36859","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.054031,32.342387],[-85.244918,32.420036],[-85.303581,32.382103],[-85.216049,32.210341],[-85.016195,32.264755],[-85.005824,32.261893],[-85.054031,32.342387]]]},"properties":{"id":"36875","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.41306,33.502396],[-86.310629,33.452551],[-86.303069,33.46316],[-86.280896,33.5108],[-86.413226,33.502395],[-86.41306,33.502396]]]},"properties":{"id":"35054","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.740139,33.190302],[-86.730476,33.254131],[-86.824372,33.27176],[-86.839732,33.255567],[-86.861107,33.190855],[-86.793497,33.166153],[-86.740139,33.190302]]]},"properties":{"id":"35007","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.657931,33.363124],[-86.560198,33.335773],[-86.551975,33.335577],[-86.477446,33.356924],[-86.41306,33.502396],[-86.413226,33.502395],[-86.443636,33.502336],[-86.556222,33.47034],[-86.57531,33.456011],[-86.657931,33.363124]]]},"properties":{"id":"35147","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.171852,32.99586],[-88.293836,32.992953],[-88.231612,32.860939],[-88.197093,32.935778],[-88.171852,32.99586]]]},"properties":{"id":"35477","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.132634,32.412305],[-88.143278,32.453213],[-88.175288,32.433048],[-88.174157,32.389761],[-88.132634,32.412305]]]},"properties":{"id":"36901","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.638134,33.233939],[-87.641491,33.293746],[-87.730173,33.363329],[-87.629377,33.211958],[-87.626471,33.215595],[-87.638134,33.233939]]]},"properties":{"id":"35452","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.216735,33.993907],[-87.285739,33.848696],[-87.143336,33.807598],[-87.087359,33.795786],[-87.083345,33.817012],[-87.070666,33.821578],[-87.110645,33.956473],[-87.151036,33.993225],[-87.216735,33.993907]]]},"properties":{"id":"35504","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.449335,31.433165],[-88.313198,31.379508],[-88.255789,31.357146],[-88.169361,31.397327],[-88.09943,31.47954],[-88.185346,31.508962],[-88.453179,31.505388],[-88.449335,31.433165]]]},"properties":{"id":"36518","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.791045,32.630983],[-86.900401,32.570194],[-86.829741,32.479407],[-86.798105,32.559106],[-86.791045,32.630983]]]},"properties":{"id":"36749","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.524429,32.64316],[-86.451605,32.542354],[-86.412073,32.493105],[-86.364389,32.534928],[-86.322544,32.575921],[-86.294432,32.640057],[-86.361913,32.727199],[-86.524429,32.64316]]]},"properties":{"id":"36022","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.84816,30.736704],[-87.923161,30.750787],[-87.913605,30.654404],[-87.819535,30.658225],[-87.79008,30.726068],[-87.84816,30.736704]]]},"properties":{"id":"36527","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.70683,30.691408],[-87.765606,30.789338],[-87.84816,30.736704],[-87.79008,30.726068],[-87.70683,30.691408]]]},"properties":{"id":"36578","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.900958,30.573218],[-87.902895,30.571836],[-87.902755,30.571397],[-87.900958,30.573218]]],[[[-87.900958,30.573218],[-87.89943,30.56656],[-87.9064,30.549174],[-87.858127,30.375554],[-87.817471,30.435698],[-87.802903,30.483204],[-87.798664,30.545541],[-87.798528,30.549242],[-87.900958,30.573218]]]]},"properties":{"id":"36532","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.726995,30.304414],[-87.72027,30.330105],[-87.771584,30.310884],[-87.755258,30.288494],[-87.726995,30.304414]]]},"properties":{"id":"36511","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.170768,33.228963],[-87.202884,33.130654],[-87.127912,33.22007],[-87.12528,33.22421],[-87.170768,33.228963]]]},"properties":{"id":"35188","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.12528,33.22421],[-87.127912,33.22007],[-87.121517,33.221744],[-87.12528,33.22421]]]},"properties":{"id":"35074","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.44191,34.090504],[-86.426595,34.209552],[-86.493673,34.223882],[-86.540213,34.224874],[-86.619706,34.120796],[-86.647871,34.108474],[-86.706017,34.031306],[-86.59756,34.003964],[-86.540903,34.019811],[-86.433683,34.059858],[-86.44191,34.090504]]]},"properties":{"id":"35031","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.656917,33.894818],[-86.647837,33.871695],[-86.584507,33.885225],[-86.656917,33.894818]]]},"properties":{"id":"35097","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.519245,33.902354],[-86.519282,33.900214],[-86.516646,33.900188],[-86.516311,33.902166],[-86.519245,33.902354]]]},"properties":{"id":"35013","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.038834,31.967297],[-85.861592,31.744108],[-85.794441,31.784163],[-85.779756,31.97322],[-85.896429,32.06259],[-85.966389,32.054446],[-85.996059,32.010371],[-86.038834,31.967297]],[[-85.951878,31.797254],[-85.96112,31.799276],[-85.957929,31.807368],[-85.949202,31.807265],[-85.951878,31.797254]]]},"properties":{"id":"36081","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.809708,31.815575],[-86.573335,31.586921],[-86.499214,31.525717],[-86.490882,31.587862],[-86.448398,31.684063],[-86.441945,31.765067],[-86.529231,31.937919],[-86.708274,31.962641],[-86.851173,31.962177],[-86.809708,31.815575]]]},"properties":{"id":"36037","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.871322,33.791739],[-85.91621,33.797068],[-85.961678,33.753821],[-85.885533,33.735079],[-85.858689,33.738686],[-85.847134,33.772851],[-85.871322,33.791739]]]},"properties":{"id":"36250","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.871322,33.791739],[-85.821581,33.949777],[-85.915378,33.866172],[-85.91621,33.797068],[-85.871322,33.791739]]]},"properties":{"id":"36279","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.178422,32.854602],[-85.250354,32.779397],[-85.219099,32.707351],[-85.212834,32.671377],[-85.115237,32.687334],[-85.178422,32.854602]]]},"properties":{"id":"36854","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.768884,34.07043],[-85.419219,34.067055],[-85.444671,34.1999],[-85.687792,34.202606],[-85.789466,34.086297],[-85.768884,34.07043]]]},"properties":{"id":"35960","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.744086,34.284851],[-85.787402,34.393191],[-85.790018,34.39335],[-85.775483,34.41885],[-85.850479,34.433361],[-85.875225,34.42643],[-85.886417,34.412955],[-85.925266,34.304707],[-85.99949,34.208558],[-86.008586,34.199436],[-86.002757,34.199324],[-85.93375,34.154928],[-85.869112,34.140929],[-85.84244,34.16091],[-85.764947,34.266997],[-85.744086,34.284851]]]},"properties":{"id":"35961","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.170109,32.184376],[-88.436972,32.1835],[-88.460686,31.995426],[-88.442639,31.993453],[-88.401143,31.991286],[-88.328465,32.001877],[-88.280052,32.022157],[-88.27794,32.02487],[-88.107857,32.000149],[-88.053341,32.078065],[-88.016846,32.153413],[-88.105441,32.195304],[-88.170109,32.184376]]]},"properties":{"id":"36904","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.328465,32.001877],[-88.296145,31.993258],[-88.280052,32.022157],[-88.328465,32.001877]]],[[[-88.27794,32.02487],[-88.158366,31.853511],[-88.138271,31.875578],[-88.07349,31.990182],[-88.107857,32.000149],[-88.27794,32.02487]]]]},"properties":{"id":"36921","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.436972,32.1835],[-88.170109,32.184376],[-88.168031,32.229841],[-88.344405,32.308157],[-88.421453,32.30868],[-88.436972,32.1835]]]},"properties":{"id":"36912","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.168031,32.229841],[-88.170109,32.184376],[-88.105441,32.195304],[-88.135973,32.213834],[-88.168031,32.229841]]]},"properties":{"id":"36910","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.564058,31.479435],[-87.691958,31.405062],[-87.577002,31.432096],[-87.564058,31.479435]]]},"properties":{"id":"36540","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.212669,33.261439],[-86.227529,33.225544],[-86.120581,33.205778],[-85.959293,33.195397],[-85.955128,33.208934],[-85.920687,33.332186],[-85.857396,33.469575],[-85.87635,33.469504],[-86.041512,33.544334],[-86.076756,33.553041],[-86.192673,33.55314],[-86.280896,33.5108],[-86.303069,33.46316],[-86.212669,33.261439]]]},"properties":{"id":"35160","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.726244,34.749285],[-87.679453,34.745039],[-87.667886,34.780665],[-87.693598,34.774163],[-87.726244,34.749285]]]},"properties":{"id":"35660","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.542488,30.99427],[-86.187246,30.994021],[-86.185532,31.090407],[-86.230476,31.140472],[-86.360804,31.190822],[-86.53737,31.010091],[-86.542488,30.99427]],[[-86.353942,31.009553],[-86.358713,31.013425],[-86.351496,31.02018],[-86.34547,31.014232],[-86.353942,31.009553]]]},"properties":{"id":"36442","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.499214,31.525717],[-86.514455,31.438051],[-86.499349,31.438185],[-86.497485,31.452791],[-86.480384,31.453252],[-86.480534,31.425496],[-86.292911,31.398953],[-86.229225,31.441521],[-86.490882,31.587862],[-86.499214,31.525717]]]},"properties":{"id":"36028","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.866138,34.101469],[-86.878719,34.207513],[-86.878911,34.230695],[-87.099009,34.213723],[-87.110941,34.172554],[-87.102319,34.15658],[-86.975651,34.094004],[-86.991599,34.046547],[-86.965678,33.990304],[-86.866138,34.101469]]]},"properties":{"id":"35057","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.686771,34.293089],[-86.653888,34.369274],[-86.764384,34.397017],[-86.789026,34.30745],[-86.751396,34.306928],[-86.686771,34.293089]]]},"properties":{"id":"35621","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.42599,31.325651],[-85.539525,31.253043],[-85.539387,31.238787],[-85.43209,31.228527],[-85.318006,31.194919],[-85.316039,31.195902],[-85.314683,31.240442],[-85.288876,31.299753],[-85.42599,31.325651]]]},"properties":{"id":"36303","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.98549,34.335521],[-85.977831,34.343018],[-85.985678,34.3769],[-86.018466,34.390876],[-86.033105,34.393601],[-86.047355,34.374453],[-85.98549,34.335521]]]},"properties":{"id":"35974","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.881114,34.562315],[-85.875225,34.42643],[-85.850479,34.433361],[-85.772396,34.536004],[-85.853556,34.578365],[-85.854424,34.57974],[-85.881114,34.562315]]]},"properties":{"id":"35986","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.600303,34.694953],[-85.704718,34.728896],[-85.705727,34.709535],[-85.600303,34.694953]]]},"properties":{"id":"35981","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.294432,32.640057],[-86.322544,32.575921],[-86.281497,32.521352],[-86.08828,32.580513],[-86.136839,32.752671],[-86.213697,32.70712],[-86.294432,32.640057]]]},"properties":{"id":"36092","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.336551,32.519367],[-86.309131,32.464117],[-86.278069,32.486035],[-86.300096,32.508011],[-86.336551,32.519367]]]},"properties":{"id":"36020","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.363884,30.997896],[-87.164084,30.999017],[-87.334055,31.065999],[-87.363884,30.997896]]]},"properties":{"id":"36441","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.025667,33.942659],[-86.025602,33.981316],[-86.105364,33.960261],[-86.166253,33.944447],[-86.138499,33.898386],[-86.06812,33.865686],[-86.025667,33.942659]]]},"properties":{"id":"35906","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.3051,34.06767],[-86.301848,34.067216],[-86.30311,34.070823],[-86.3051,34.06767]]]},"properties":{"id":"35990","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.719298,33.897712],[-87.719328,33.90301],[-87.732269,33.913723],[-87.735507,33.90571],[-87.719298,33.897712]]]},"properties":{"id":"35559","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.685641,34.278355],[-87.576982,34.372728],[-87.73275,34.396402],[-87.873504,34.401032],[-87.886433,34.333505],[-87.769783,34.277637],[-87.685641,34.278355]]]},"properties":{"id":"35581","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.873504,34.401032],[-87.92881,34.429132],[-87.948682,34.447187],[-88.025469,34.303769],[-87.924963,34.297084],[-87.886433,34.333505],[-87.873504,34.401032]]]},"properties":{"id":"35571","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.636443,34.092548],[-87.613998,34.117553],[-87.596397,34.087043],[-87.515791,34.070144],[-87.505325,34.083306],[-87.368899,34.218082],[-87.374208,34.30083],[-87.433545,34.403119],[-87.530649,34.367248],[-87.576982,34.372728],[-87.685641,34.278355],[-87.763076,34.136489],[-87.766919,34.120609],[-87.63636,34.080475],[-87.636443,34.092548]]]},"properties":{"id":"35565","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.798639,31.196373],[-85.784859,31.159636],[-85.792908,31.196374],[-85.798639,31.196373]]]},"properties":{"id":"36313","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.830349,31.048079],[-85.831024,31.108784],[-85.905003,31.134199],[-85.951924,31.101626],[-85.965668,30.993041],[-85.831123,30.99412],[-85.830349,31.048079]]]},"properties":{"id":"36340","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.879373,32.62193],[-87.79247,32.660626],[-87.825864,32.767608],[-87.870382,32.762625],[-88.15869,32.860809],[-88.178343,32.828535],[-88.156553,32.824903],[-88.057391,32.593486],[-87.879373,32.62193]]]},"properties":{"id":"35443","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.760281,33.024375],[-87.711256,33.098321],[-87.823028,33.164152],[-87.932645,33.113864],[-87.780268,33.017197],[-87.760281,33.024375]]]},"properties":{"id":"35480","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.091866,34.484507],[-86.055289,34.556762],[-86.221765,34.443146],[-86.154938,34.450003],[-86.091866,34.484507]]]},"properties":{"id":"35755","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.280191,34.774318],[-86.195611,34.794782],[-86.202727,34.811853],[-86.340139,34.853272],[-86.327563,34.831717],[-86.340002,34.78431],[-86.280191,34.774318]]]},"properties":{"id":"35751","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.271679,34.923961],[-86.340139,34.853272],[-86.202727,34.811853],[-86.196174,34.861141],[-86.271679,34.923961]]]},"properties":{"id":"35766","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.917591,33.420828],[-86.884791,33.478206],[-86.915317,33.440759],[-86.917591,33.420828]]]},"properties":{"id":"35221","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.744048,33.573646],[-86.716497,33.538825],[-86.670026,33.582246],[-86.696817,33.590981],[-86.724608,33.59042],[-86.744048,33.573646]]]},"properties":{"id":"35206","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.97412,33.470737],[-86.965074,33.444616],[-86.951402,33.457937],[-86.936297,33.494912],[-86.97412,33.470737]]]},"properties":{"id":"35061","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.014794,33.48585],[-86.97412,33.470737],[-86.936297,33.494912],[-86.999082,33.500027],[-87.014794,33.48585]]]},"properties":{"id":"35127","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.856051,33.512065],[-86.855162,33.520183],[-86.871388,33.514282],[-86.856051,33.512065]]]},"properties":{"id":"35254","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.12271,33.469403],[-87.997299,33.5103],[-88.035869,33.682238],[-88.238202,33.653393],[-88.262394,33.6324],[-88.274619,33.534008],[-88.12271,33.469403]]]},"properties":{"id":"35576","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.056813,34.040898],[-88.19848,34.128546],[-88.216913,33.983745],[-88.056813,34.040898]]]},"properties":{"id":"35552","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.483888,32.595761],[-85.585674,32.514113],[-85.598052,32.509112],[-85.461281,32.429124],[-85.421721,32.608143],[-85.500369,32.70832],[-85.520431,32.632133],[-85.485243,32.606537],[-85.483888,32.595761]]]},"properties":{"id":"36830","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.68703,32.618848],[-85.585674,32.514113],[-85.483888,32.595761],[-85.494694,32.602361],[-85.485243,32.606537],[-85.520431,32.632133],[-85.626769,32.669235],[-85.68703,32.618848]],[[-85.58025,32.599497],[-85.59774,32.603852],[-85.593769,32.60456],[-85.587676,32.603341],[-85.58025,32.599497]]]},"properties":{"id":"36832","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.864878,34.67141],[-86.871328,34.656857],[-86.867034,34.632374],[-86.85744,34.612492],[-86.884997,34.600595],[-86.906675,34.579758],[-86.800302,34.552623],[-86.749308,34.559081],[-86.723833,34.581011],[-86.722513,34.624004],[-86.787,34.661464],[-86.786108,34.74783],[-86.795876,34.748973],[-86.823202,34.752084],[-86.877541,34.729973],[-86.864878,34.67141]]]},"properties":{"id":"35756","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.766053,32.308425],[-86.853893,32.118392],[-86.735792,32.070709],[-86.686342,32.057347],[-86.489666,32.133967],[-86.534114,32.187274],[-86.766053,32.308425]]]},"properties":{"id":"36040","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.821376,32.320394],[-85.897787,32.449646],[-86.023012,32.419978],[-85.991367,32.336081],[-85.954314,32.302891],[-85.91703,32.313612],[-85.821376,32.320394]]]},"properties":{"id":"36075","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596408,34.730009],[-86.59992,34.748179],[-86.651031,34.771515],[-86.648264,34.751987],[-86.65502,34.751197],[-86.660588,34.738767],[-86.596408,34.730009]]]},"properties":{"id":"35816","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.787,34.661464],[-86.722513,34.624004],[-86.716788,34.66967],[-86.787,34.661464]]]},"properties":{"id":"35824","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.826726,32.430982],[-87.812559,32.52456],[-87.85342,32.532086],[-88.041467,32.414774],[-87.930661,32.310574],[-87.9297,32.292516],[-87.902771,32.290129],[-87.826726,32.430982]]]},"properties":{"id":"36732","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.0152,32.237586],[-88.014454,32.233503],[-87.875013,32.295698],[-87.902771,32.290129],[-87.9297,32.292516],[-88.0152,32.237586]]]},"properties":{"id":"36763","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.356749,34.234489],[-86.279702,34.212999],[-86.162996,34.234487],[-86.25773,34.301851],[-86.356749,34.234489]]]},"properties":{"id":"35950","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.190831,30.639218],[-88.190702,30.67454],[-88.404431,30.592874],[-88.277275,30.591561],[-88.258642,30.602773],[-88.190834,30.610176],[-88.190831,30.639218]]]},"properties":{"id":"36695","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.089467,31.063388],[-88.100416,31.144109],[-88.179187,31.228798],[-88.322224,31.201681],[-88.432007,31.114298],[-88.424225,30.967032],[-88.32459,30.9356],[-88.089467,31.063388]]]},"properties":{"id":"36522","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.238914,30.426953],[-88.270606,30.520713],[-88.259506,30.508751],[-88.288701,30.411487],[-88.238914,30.426953]]]},"properties":{"id":"36544","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.299237,32.327042],[-86.299615,32.344582],[-86.299732,32.351892],[-86.312436,32.351708],[-86.356583,32.270262],[-86.333133,32.128831],[-86.221945,32.193765],[-86.299237,32.327042]]]},"properties":{"id":"36105","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.23494,32.328324],[-86.299237,32.327042],[-86.221945,32.193765],[-86.152209,32.236548],[-86.176236,32.294645],[-86.221572,32.34847],[-86.23494,32.328324]]]},"properties":{"id":"36116","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.991367,32.336081],[-86.016688,32.274409],[-85.998977,32.23507],[-85.954314,32.302891],[-85.991367,32.336081]]]},"properties":{"id":"36013","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.121464,33.365997],[-88.007112,33.30708],[-87.946907,33.457721],[-87.997299,33.5103],[-88.12271,33.469403],[-88.121464,33.365997]]]},"properties":{"id":"35481","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.01722,32.173405],[-85.008167,32.205421],[-85.016195,32.264755],[-85.216049,32.210341],[-85.2892,32.246223],[-85.265398,32.153525],[-85.049685,32.140906],[-85.01722,32.173405]]]},"properties":{"id":"36871","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.310629,33.452551],[-86.41306,33.502396],[-86.477446,33.356924],[-86.367509,33.335692],[-86.310629,33.452551]]]},"properties":{"id":"35178","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.920243,33.220411],[-86.861107,33.190855],[-86.839732,33.255567],[-86.920243,33.220411]]]},"properties":{"id":"35114","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.740139,33.190302],[-86.63645,33.099176],[-86.481504,33.145159],[-86.503127,33.179144],[-86.579695,33.325315],[-86.718792,33.261006],[-86.730476,33.254131],[-86.740139,33.190302]]]},"properties":{"id":"35051","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.610143,33.070002],[-86.517344,33.020566],[-86.481504,33.145159],[-86.63645,33.099176],[-86.610143,33.070002]]]},"properties":{"id":"35143","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.128139,33.304067],[-87.300039,33.190128],[-87.302904,33.105449],[-87.202884,33.130654],[-87.170768,33.228963],[-87.128139,33.304067]]]},"properties":{"id":"35490","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.32648,33.772054],[-87.25474,33.553341],[-87.230183,33.538843],[-87.186516,33.556951],[-87.175693,33.662923],[-87.224182,33.778331],[-87.32648,33.772054]]]},"properties":{"id":"35580","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.94091,31.238699],[-88.008334,31.187338],[-87.938092,31.22068],[-87.94091,31.238699]]]},"properties":{"id":"36556","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.750038,32.554643],[-86.588261,32.367749],[-86.496774,32.344437],[-86.411172,32.409937],[-86.451605,32.542354],[-86.524429,32.64316],[-86.585523,32.65446],[-86.625963,32.684779],[-86.750038,32.554643]]]},"properties":{"id":"36067","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.951183,32.569338],[-86.90642,32.323337],[-86.810802,32.307574],[-86.782467,32.375137],[-86.829741,32.479407],[-86.900401,32.570194],[-86.951183,32.569338]]]},"properties":{"id":"36703","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.900958,30.573218],[-87.902755,30.571397],[-87.89943,30.56656],[-87.900958,30.573218]]],[[[-87.900958,30.573218],[-87.798528,30.549242],[-87.819535,30.658225],[-87.913605,30.654404],[-87.916946,30.579453],[-87.902895,30.571836],[-87.900958,30.573218]]]]},"properties":{"id":"36526","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.598829,30.997455],[-87.681044,31.101951],[-87.73965,31.075196],[-87.593481,30.976075],[-87.598829,30.997455]]]},"properties":{"id":"36562","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.87886,30.959985],[-87.73965,31.075196],[-87.681044,31.101951],[-87.683984,31.15979],[-87.815348,31.21716],[-87.87886,30.959985]]]},"properties":{"id":"36579","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.681044,31.101951],[-87.598829,30.997455],[-87.363884,30.997896],[-87.334055,31.065999],[-87.339969,31.260608],[-87.378673,31.324916],[-87.448637,31.314891],[-87.650894,31.248782],[-87.683984,31.15979],[-87.681044,31.101951]],[[-87.462826,31.215137],[-87.464403,31.223838],[-87.456093,31.220144],[-87.462826,31.215137]]]},"properties":{"id":"36502","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.876297,33.003059],[-87.028439,33.140909],[-87.07657,33.041885],[-86.969551,32.959552],[-86.876297,33.003059]]]},"properties":{"id":"35035","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.432686,34.383416],[-86.57853,34.387617],[-86.581328,34.367154],[-86.549859,34.247839],[-86.540213,34.224874],[-86.493673,34.223882],[-86.432686,34.383416]]]},"properties":{"id":"35016","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.384782,34.134305],[-86.44191,34.090504],[-86.433683,34.059858],[-86.321312,33.950002],[-86.177013,34.048044],[-86.150543,34.095738],[-86.303516,34.099073],[-86.384782,34.134305]],[[-86.3051,34.06767],[-86.30311,34.070823],[-86.301848,34.067216],[-86.3051,34.06767]]]},"properties":{"id":"35952","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.498986,33.727604],[-86.350095,33.710497],[-86.299466,33.856035],[-86.31776,33.902058],[-86.53272,33.83106],[-86.571061,33.765179],[-86.551266,33.742164],[-86.498986,33.727604]]]},"properties":{"id":"35146","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.52499,32.349252],[-85.493064,32.187266],[-85.33837,32.147152],[-85.265398,32.153525],[-85.2892,32.246223],[-85.416489,32.409959],[-85.504521,32.347586],[-85.52499,32.349252]]]},"properties":{"id":"36860","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.994715,31.719221],[-86.642246,31.523753],[-86.573335,31.586921],[-86.809708,31.815575],[-86.90668,31.81507],[-86.994715,31.719221]]]},"properties":{"id":"36033","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.448398,31.684063],[-86.344583,31.658612],[-86.396249,31.776128],[-86.441945,31.765067],[-86.448398,31.684063]]]},"properties":{"id":"36071","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.972297,33.617116],[-85.94124,33.59752],[-85.827954,33.623267],[-85.811064,33.681203],[-85.819029,33.698542],[-85.884484,33.720339],[-85.972297,33.617116]]]},"properties":{"id":"36201","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.192673,33.55314],[-86.076756,33.553041],[-86.013042,33.62838],[-86.017874,33.700055],[-86.145562,33.679098],[-86.172437,33.659831],[-86.220976,33.587155],[-86.192673,33.55314]]]},"properties":{"id":"35096","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.915378,33.866172],[-85.821581,33.949777],[-85.744469,33.936102],[-85.780658,33.962326],[-85.954604,33.942975],[-85.985348,33.927077],[-85.987542,33.879205],[-85.915378,33.866172]]]},"properties":{"id":"35905","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.592989,33.016146],[-85.572089,33.003664],[-85.51734,33.034292],[-85.46441,33.236241],[-85.612279,33.24758],[-85.653306,33.188983],[-85.755797,33.124256],[-85.592989,33.016146]]]},"properties":{"id":"36276","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.46441,33.236241],[-85.51734,33.034292],[-85.38429,33.068953],[-85.232378,33.108077],[-85.262513,33.265604],[-85.409396,33.281256],[-85.46441,33.236241]]]},"properties":{"id":"36274","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.691678,34.518515],[-85.787402,34.393191],[-85.744086,34.284851],[-85.681238,34.288235],[-85.627512,34.39592],[-85.64954,34.524735],[-85.691678,34.518515]]]},"properties":{"id":"35967","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.561283,33.73964],[-85.398633,33.685027],[-85.376433,33.85065],[-85.384708,33.892042],[-85.555667,33.770693],[-85.561283,33.73964]]]},"properties":{"id":"36262","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.14228,31.33797],[-86.115197,31.272064],[-86.002124,31.221474],[-85.980144,31.248379],[-86.011967,31.282791],[-85.925126,31.488098],[-86.037824,31.550217],[-86.049899,31.547759],[-86.150314,31.537601],[-86.195397,31.431309],[-86.14228,31.33797]]]},"properties":{"id":"36323","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.745752,31.321272],[-85.792908,31.196374],[-85.784859,31.159636],[-85.784623,31.159448],[-85.710866,31.195179],[-85.612124,31.351157],[-85.745752,31.321272]]]},"properties":{"id":"36322","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.212135,31.330159],[-87.207538,31.279942],[-87.181987,31.299646],[-87.189841,31.328036],[-87.212135,31.330159]]]},"properties":{"id":"36473","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.277462,33.022999],[-86.437346,32.921742],[-86.460581,32.832051],[-86.374974,32.75358],[-86.250267,32.753537],[-86.165338,32.827598],[-86.182117,32.944374],[-86.22387,33.03654],[-86.277462,33.022999]]]},"properties":{"id":"35136","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.133001,34.067845],[-86.975651,34.094004],[-87.102319,34.15658],[-87.133001,34.067845]]]},"properties":{"id":"35098","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.109911,34.299299],[-87.26955,34.299302],[-87.204846,34.148685],[-87.110941,34.172554],[-87.099009,34.213723],[-87.109911,34.299299]]]},"properties":{"id":"35540","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.612124,31.351157],[-85.707209,31.385241],[-85.762607,31.356373],[-85.745752,31.321272],[-85.612124,31.351157]]]},"properties":{"id":"36362","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.232764,32.16453],[-87.295311,32.285959],[-87.422859,32.375577],[-87.507857,32.307335],[-87.504247,32.268876],[-87.425418,32.248106],[-87.251999,32.142648],[-87.232764,32.16453]]]},"properties":{"id":"36773","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.853556,34.578365],[-85.772396,34.536004],[-85.776926,34.580055],[-85.812997,34.579893],[-85.853556,34.578365]]]},"properties":{"id":"35988","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.716153,34.886661],[-85.741787,34.774866],[-85.704718,34.728896],[-85.600303,34.694953],[-85.592812,34.696898],[-85.558071,34.734425],[-85.57562,34.821151],[-85.644691,34.8441],[-85.716153,34.886661]]]},"properties":{"id":"35966","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.985348,33.927077],[-86.025667,33.942659],[-86.06812,33.865686],[-86.065272,33.842198],[-85.987542,33.879205],[-85.985348,33.927077]]]},"properties":{"id":"35907","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.946907,33.457721],[-87.831001,33.42784],[-87.793808,33.448549],[-87.932171,33.635329],[-87.946305,33.657795],[-87.946433,33.659025],[-87.946466,33.660608],[-87.98986,33.708446],[-88.035869,33.682238],[-87.997299,33.5103],[-87.946907,33.457721]]]},"properties":{"id":"35574","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.840861,34.085255],[-87.963517,34.022502],[-87.98202,33.867595],[-87.950851,33.823598],[-87.897443,33.827682],[-87.8036,34.003854],[-87.840861,34.085255]]]},"properties":{"id":"35563","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.43209,31.228527],[-85.496118,31.158313],[-85.404902,31.069331],[-85.309693,31.101234],[-85.316936,31.18915],[-85.316001,31.193325],[-85.318006,31.194919],[-85.43209,31.228527]]]},"properties":{"id":"36301","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.197093,32.935778],[-88.231612,32.860939],[-88.21967,32.833683],[-88.178343,32.828535],[-88.15869,32.860809],[-88.197093,32.935778]]],[[[-88.076646,33.192449],[-88.319589,33.164588],[-88.340432,32.991199],[-88.293836,32.992953],[-88.171852,32.99586],[-88.026971,33.063602],[-88.076646,33.192449]]]]},"properties":{"id":"35442","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.743853,32.935336],[-87.633175,32.833121],[-87.421292,32.862836],[-87.4212,32.874508],[-87.421936,33.003379],[-87.539426,33.031705],[-87.624612,33.045354],[-87.712009,33.015624],[-87.743853,32.935336]]]},"properties":{"id":"35474","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.443122,32.568723],[-87.489249,32.655791],[-87.65378,32.523304],[-87.643563,32.509712],[-87.557595,32.481606],[-87.443122,32.568723]]]},"properties":{"id":"36765","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.316001,31.193325],[-85.316039,31.195902],[-85.318006,31.194919],[-85.316001,31.193325]]],[[[-85.226203,31.271617],[-85.200926,31.2717],[-85.229359,31.309375],[-85.226203,31.271617]]],[[[-85.309693,31.101234],[-85.200511,31.058496],[-85.200128,31.063098],[-85.154774,31.213564],[-85.2063,31.263075],[-85.281744,31.204787],[-85.316936,31.18915],[-85.309693,31.101234]]]]},"properties":{"id":"36312","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.969397,34.633958],[-85.910665,34.543935],[-85.881114,34.562315],[-85.854424,34.57974],[-85.869953,34.623719],[-85.85917,34.647282],[-85.940952,34.657272],[-85.969397,34.633958]]]},"properties":{"id":"35744","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.175693,33.662923],[-87.186516,33.556951],[-87.117155,33.560062],[-87.068535,33.590759],[-87.043099,33.637861],[-87.042515,33.638177],[-87.110789,33.735542],[-87.175693,33.662923]]]},"properties":{"id":"35130","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.818161,33.506971],[-86.780258,33.512676],[-86.791266,33.525095],[-86.818161,33.506971]]]},"properties":{"id":"35233","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.643334,33.655347],[-86.696817,33.590981],[-86.670026,33.582246],[-86.625854,33.601577],[-86.615863,33.675773],[-86.643334,33.655347]]]},"properties":{"id":"35235","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.043099,33.637861],[-86.928909,33.613953],[-86.915968,33.635381],[-86.928294,33.651502],[-86.965299,33.703434],[-86.983242,33.709428],[-87.042515,33.638177],[-87.043099,33.637861]]]},"properties":{"id":"35073","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.942674,33.570016],[-86.971412,33.564767],[-86.966746,33.553626],[-86.923543,33.54768],[-86.942674,33.570016]]]},"properties":{"id":"35060","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.986612,34.886745],[-87.855931,34.757851],[-87.726244,34.749285],[-87.693598,34.774163],[-87.672766,34.883895],[-87.671645,35.003598],[-87.873118,35.005546],[-87.986612,34.886745]]]},"properties":{"id":"35633","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.212834,32.671377],[-85.220644,32.450774],[-85.2126,32.452154],[-85.158713,32.514809],[-85.097571,32.634507],[-85.115237,32.687334],[-85.212834,32.671377]]]},"properties":{"id":"36874","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.686342,32.057347],[-86.405935,32.032727],[-86.352405,32.109654],[-86.489666,32.133967],[-86.686342,32.057347]]]},"properties":{"id":"36047","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.709576,32.268628],[-85.781104,32.233269],[-85.709301,32.233444],[-85.709576,32.268628]]]},"properties":{"id":"36031","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596408,34.730009],[-86.660588,34.738767],[-86.669305,34.709319],[-86.610269,34.667858],[-86.596536,34.690064],[-86.596408,34.730009]]]},"properties":{"id":"35805","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.667104,31.909181],[-87.527755,31.874517],[-87.381223,31.913581],[-87.473687,32.00369],[-87.507021,32.085189],[-87.661484,32.12999],[-87.704227,32.083615],[-87.667104,31.909181]]]},"properties":{"id":"36769","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.769783,34.277637],[-87.806598,34.189084],[-87.763076,34.136489],[-87.685641,34.278355],[-87.769783,34.277637]]]},"properties":{"id":"35543","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.311702,34.542291],[-86.462611,34.478409],[-86.468831,34.475652],[-86.421261,34.439602],[-86.261121,34.466074],[-86.208953,34.590765],[-86.311702,34.542291]]]},"properties":{"id":"35747","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.550166,34.545963],[-86.622012,34.473782],[-86.57853,34.387617],[-86.432686,34.383416],[-86.421261,34.439602],[-86.468831,34.475652],[-86.550166,34.545963]]]},"properties":{"id":"35175","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.126506,30.68817],[-88.122634,30.706577],[-88.225483,30.712315],[-88.329077,30.718481],[-88.408013,30.636947],[-88.406201,30.589708],[-88.404431,30.592874],[-88.190702,30.67454],[-88.130802,30.676089],[-88.126506,30.68817]],[[-88.18445,30.694763],[-88.190394,30.693796],[-88.190967,30.701265],[-88.179337,30.6992],[-88.18445,30.694763]]]},"properties":{"id":"36608","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.118984,30.641366],[-88.113856,30.591771],[-88.080299,30.524269],[-88.055001,30.607905],[-88.077198,30.62919],[-88.050518,30.647148],[-88.052812,30.654582],[-88.056946,30.668485],[-88.079893,30.671621],[-88.118984,30.641366]]]},"properties":{"id":"36605","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.055573,30.690044],[-88.077733,30.700927],[-88.078647,30.699997],[-88.080805,30.686661],[-88.079893,30.671621],[-88.056946,30.668485],[-88.053059,30.683855],[-88.055573,30.690044]]]},"properties":{"id":"36604","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.190834,30.610176],[-88.121152,30.594734],[-88.113856,30.591771],[-88.118984,30.641366],[-88.126431,30.661539],[-88.190831,30.639218],[-88.190834,30.610176]]]},"properties":{"id":"36693","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.225483,30.712315],[-88.122634,30.706577],[-88.121571,30.711653],[-88.119145,30.740456],[-88.132544,30.74895],[-88.217246,30.773667],[-88.225483,30.712315]]]},"properties":{"id":"36618","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.050157,30.706605],[-88.055573,30.690044],[-88.053059,30.683855],[-88.047797,30.712122],[-88.050157,30.706605]]]},"properties":{"id":"36602","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.217246,30.773667],[-88.246627,30.85251],[-88.252598,30.856279],[-88.329077,30.718481],[-88.225483,30.712315],[-88.217246,30.773667]]]},"properties":{"id":"36575","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.17475,30.531286],[-88.17398,30.53135],[-88.174018,30.53222],[-88.174826,30.532329],[-88.17475,30.531286]]]},"properties":{"id":"36590","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.289402,31.831278],[-87.484116,31.829564],[-87.50093,31.829251],[-87.439824,31.743253],[-87.36675,31.737609],[-87.289402,31.831278]]]},"properties":{"id":"36481","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.288287,32.400062],[-86.315758,32.435873],[-86.368979,32.430636],[-86.373071,32.429837],[-86.361851,32.398323],[-86.349974,32.372486],[-86.312436,32.351708],[-86.299732,32.351892],[-86.286252,32.372166],[-86.288287,32.400062]]]},"properties":{"id":"36104","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.030091,34.496146],[-87.020041,34.370382],[-86.777748,34.415545],[-86.855981,34.499113],[-87.030091,34.496146]]]},"properties":{"id":"35640","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.007112,33.30708],[-88.121464,33.365997],[-88.300507,33.320847],[-88.319589,33.164588],[-88.076646,33.192449],[-88.007112,33.30708]]]},"properties":{"id":"35447","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.776909,33.324869],[-87.831001,33.42784],[-87.946907,33.457721],[-88.007112,33.30708],[-88.076646,33.192449],[-88.026971,33.063602],[-87.932645,33.113864],[-87.823028,33.164152],[-87.776981,33.257101],[-87.776909,33.324869]]]},"properties":{"id":"35466","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.216049,32.210341],[-85.303581,32.382103],[-85.416489,32.409959],[-85.2892,32.246223],[-85.216049,32.210341]]]},"properties":{"id":"36858","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.005824,32.261893],[-85.008167,32.205421],[-85.01722,32.173405],[-84.977317,32.295434],[-84.999908,32.321371],[-85.003097,32.346707],[-85.054031,32.342387],[-85.005824,32.261893]]]},"properties":{"id":"36856","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.189766,33.983454],[-86.316884,33.922188],[-86.31776,33.902058],[-86.299466,33.856035],[-86.138499,33.898386],[-86.166253,33.944447],[-86.189766,33.983454]]]},"properties":{"id":"35987","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.718792,33.261006],[-86.579695,33.325315],[-86.560198,33.335773],[-86.657931,33.363124],[-86.7104,33.328397],[-86.718792,33.261006]]]},"properties":{"id":"35043","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.483958,33.201992],[-86.342453,33.25128],[-86.338792,33.264286],[-86.332588,33.264851],[-86.333197,33.259258],[-86.274939,33.270165],[-86.361003,33.30717],[-86.433337,33.274064],[-86.483958,33.201992]]]},"properties":{"id":"35044","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.551761,33.236891],[-87.638134,33.233939],[-87.626471,33.215595],[-87.564641,33.217263],[-87.551761,33.236891]]]},"properties":{"id":"35476","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.626471,33.215595],[-87.629377,33.211958],[-87.70256,33.157611],[-87.589245,33.0856],[-87.525984,33.187213],[-87.532166,33.223221],[-87.564641,33.217263],[-87.626471,33.215595]]]},"properties":{"id":"35401","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.768595,33.390886],[-87.773844,33.45169],[-87.793808,33.448549],[-87.831001,33.42784],[-87.776909,33.324869],[-87.758036,33.293996],[-87.768595,33.390886]]]},"properties":{"id":"35458","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.110789,33.735542],[-87.070415,33.776901],[-87.087359,33.795786],[-87.143336,33.807598],[-87.224182,33.778331],[-87.175693,33.662923],[-87.110789,33.735542]]]},"properties":{"id":"35550","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.362284,33.809759],[-87.32648,33.772054],[-87.224182,33.778331],[-87.143336,33.807598],[-87.285739,33.848696],[-87.40096,33.869003],[-87.405128,33.864305],[-87.420997,33.840719],[-87.362284,33.809759]]]},"properties":{"id":"35501","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.169361,31.397327],[-88.255789,31.357146],[-88.182921,31.244883],[-88.146934,31.304298],[-88.169361,31.397327]]]},"properties":{"id":"36583","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.289402,31.831278],[-87.162832,31.828416],[-87.100413,31.984813],[-87.118474,32.047249],[-87.260971,32.123952],[-87.249989,32.05892],[-87.332226,32.07217],[-87.321431,32.125144],[-87.39974,32.125144],[-87.473687,32.00369],[-87.381223,31.913581],[-87.484116,31.829564],[-87.289402,31.831278]]]},"properties":{"id":"36726","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.585523,32.65446],[-86.524429,32.64316],[-86.361913,32.727199],[-86.371151,32.750627],[-86.585523,32.65446]]]},"properties":{"id":"36051","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.89943,30.56656],[-87.902755,30.571397],[-87.902895,30.571836],[-87.916946,30.579453],[-87.9064,30.549174],[-87.89943,30.56656]]]},"properties":{"id":"36559","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.210101,31.897714],[-85.365319,31.706094],[-85.329488,31.676715],[-85.127329,31.762563],[-85.049685,32.140906],[-85.265398,32.153525],[-85.33837,32.147152],[-85.210101,31.897714]]]},"properties":{"id":"36027","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.609933,31.931994],[-85.467993,31.707552],[-85.447263,31.672448],[-85.365319,31.706094],[-85.210101,31.897714],[-85.609933,31.931994]]]},"properties":{"id":"36016","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.694933,31.704077],[-85.532008,31.673098],[-85.518347,31.666601],[-85.467993,31.707552],[-85.609933,31.931994],[-85.610877,31.934573],[-85.702413,31.739095],[-85.70572,31.708803],[-85.694933,31.704077]]]},"properties":{"id":"36048","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.969551,32.959552],[-87.07657,33.041885],[-87.289752,33.063231],[-87.289213,33.031126],[-87.211556,32.835175],[-87.098183,32.836008],[-86.970682,32.900546],[-86.969551,32.959552]]]},"properties":{"id":"35042","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.725964,33.862644],[-86.711204,33.798586],[-86.679242,33.805274],[-86.647837,33.871695],[-86.656917,33.894818],[-86.673015,33.900319],[-86.725964,33.862644]]]},"properties":{"id":"35172","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.356749,34.234489],[-86.426595,34.209552],[-86.44191,34.090504],[-86.384782,34.134305],[-86.279702,34.212999],[-86.356749,34.234489]]]},"properties":{"id":"35980","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.070567,33.821364],[-87.083345,33.817012],[-87.087359,33.795786],[-87.070415,33.776901],[-87.022168,33.772717],[-86.94732,33.794267],[-86.934556,33.816374],[-86.963358,33.858221],[-87.070567,33.821364]]]},"properties":{"id":"35063","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.757304,31.431435],[-86.642246,31.523753],[-86.994715,31.719221],[-87.001619,31.716428],[-86.757304,31.431435]]]},"properties":{"id":"36456","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.642246,31.523753],[-86.757304,31.431435],[-86.70113,31.315663],[-86.540436,31.356472],[-86.557334,31.358144],[-86.554468,31.371647],[-86.539892,31.364147],[-86.539428,31.356514],[-86.514455,31.438051],[-86.499214,31.525717],[-86.573335,31.586921],[-86.642246,31.523753]]]},"properties":{"id":"36474","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.076756,33.553041],[-86.041512,33.544334],[-85.955751,33.565755],[-85.94124,33.59752],[-85.972297,33.617116],[-86.013042,33.62838],[-86.076756,33.553041]]]},"properties":{"id":"36260","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.219099,32.707351],[-85.250354,32.779397],[-85.313426,32.804203],[-85.378963,32.764001],[-85.304186,32.716137],[-85.219099,32.707351]]]},"properties":{"id":"36852","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.610143,33.070002],[-86.664901,32.942197],[-86.560218,32.762079],[-86.460581,32.832051],[-86.437346,32.921742],[-86.436935,32.93088],[-86.517344,33.020566],[-86.610143,33.070002]]]},"properties":{"id":"35046","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.46549,31.729454],[-88.267431,31.696276],[-88.088288,31.699303],[-88.179302,31.821297],[-88.471214,31.851385],[-88.46549,31.729454]]]},"properties":{"id":"36919","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.955797,31.735169],[-87.697743,31.562338],[-87.700868,31.749016],[-87.719307,31.776324],[-87.735737,31.809985],[-87.824798,31.841116],[-87.955797,31.735169]]]},"properties":{"id":"36451","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.697745,31.814515],[-87.719307,31.776324],[-87.700868,31.749016],[-87.59993,31.744404],[-87.576982,31.770798],[-87.697745,31.814515]]]},"properties":{"id":"36436","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.041512,33.544334],[-85.87635,33.469504],[-85.825126,33.519402],[-85.955751,33.565755],[-86.041512,33.544334]]]},"properties":{"id":"36268","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.173837,33.128369],[-86.209805,33.091386],[-86.22387,33.03654],[-86.182117,32.944374],[-85.985196,33.035148],[-85.841259,33.104456],[-85.936401,33.18963],[-85.959293,33.195397],[-86.120581,33.205778],[-86.153727,33.195361],[-86.129876,33.153905],[-86.173837,33.128369]]]},"properties":{"id":"35072","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.14228,31.33797],[-86.230476,31.140472],[-86.185532,31.090407],[-86.115197,31.272064],[-86.14228,31.33797]]]},"properties":{"id":"36453","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.490882,31.587862],[-86.229225,31.441521],[-86.195397,31.431309],[-86.150314,31.537601],[-86.180096,31.670469],[-86.191343,31.666245],[-86.344583,31.658612],[-86.448398,31.684063],[-86.490882,31.587862]]]},"properties":{"id":"36009","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.581997,34.711406],[-87.45654,34.756822],[-87.42651,34.800022],[-87.553626,34.826886],[-87.603505,34.800133],[-87.667886,34.780665],[-87.679453,34.745039],[-87.581997,34.711406]]]},"properties":{"id":"35661","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.189841,31.328036],[-87.209517,31.34635],[-87.212135,31.330159],[-87.189841,31.328036]]]},"properties":{"id":"36454","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.53737,31.010091],[-86.814499,31.068824],[-86.831933,30.997378],[-86.542488,30.99427],[-86.53737,31.010091]]]},"properties":{"id":"36483","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.736688,34.012006],[-86.763227,34.022691],[-86.763072,33.99741],[-86.744089,34.007807],[-86.736688,34.012006]]]},"properties":{"id":"35070","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.426661,31.418436],[-85.305094,31.507335],[-85.37464,31.567159],[-85.416124,31.574015],[-85.471776,31.500598],[-85.463324,31.474179],[-85.426661,31.418436]]]},"properties":{"id":"36353","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.90642,32.323337],[-86.951183,32.569338],[-86.995144,32.621122],[-87.017762,32.729532],[-87.04746,32.6972],[-87.16407,32.487322],[-87.12698,32.340843],[-87.092978,32.318407],[-86.908333,32.203384],[-86.90642,32.323337]]]},"properties":{"id":"36701","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.295311,32.285959],[-87.232764,32.16453],[-87.102123,32.212837],[-87.092978,32.318407],[-87.12698,32.340843],[-87.295311,32.285959]]]},"properties":{"id":"36767","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.98549,34.335521],[-85.98059,34.332459],[-85.977831,34.343018],[-85.98549,34.335521]]],[[[-85.98549,34.335521],[-86.047355,34.374453],[-86.076145,34.238999],[-85.99949,34.208558],[-85.925266,34.304707],[-85.977873,34.330936],[-85.98549,34.335521]]]]},"properties":{"id":"35962","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.136839,32.752671],[-86.08828,32.580513],[-86.030912,32.535417],[-85.921124,32.707124],[-85.972233,32.745837],[-86.136839,32.752671]]]},"properties":{"id":"36024","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.921124,32.707124],[-86.030912,32.535417],[-86.086113,32.421505],[-86.064183,32.409063],[-86.023012,32.419978],[-85.897787,32.449646],[-85.797458,32.513303],[-85.824563,32.637137],[-85.907668,32.698717],[-85.921124,32.707124]]]},"properties":{"id":"36078","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.412073,32.493105],[-86.368979,32.430636],[-86.315758,32.435873],[-86.309131,32.464117],[-86.336551,32.519367],[-86.364389,32.534928],[-86.412073,32.493105]]]},"properties":{"id":"36054","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.945333,33.657989],[-87.946466,33.660608],[-87.946433,33.659025],[-87.945333,33.657989]]],[[[-87.945333,33.657989],[-87.946305,33.657795],[-87.932171,33.635329],[-87.945333,33.657989]]]]},"properties":{"id":"35545","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.035558,34.488648],[-88.173632,34.321054],[-88.187305,34.21062],[-88.025469,34.303769],[-87.948682,34.447187],[-88.035558,34.488648]]]},"properties":{"id":"35593","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.43209,31.228527],[-85.539387,31.238787],[-85.561771,31.196278],[-85.496118,31.158313],[-85.43209,31.228527]]]},"properties":{"id":"36305","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.154774,31.213564],[-85.200128,31.063098],[-85.100527,31.165994],[-85.107544,31.189841],[-85.154774,31.213564]]]},"properties":{"id":"36370","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.221992,34.622645],[-85.983724,34.699067],[-86.066257,34.781451],[-86.00777,34.882846],[-86.067137,34.989514],[-86.084648,34.989594],[-86.196174,34.861141],[-86.202727,34.811853],[-86.195611,34.794782],[-86.171718,34.775816],[-86.221992,34.622645]]]},"properties":{"id":"35768","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.433773,34.800933],[-86.43109,34.756945],[-86.443664,34.703305],[-86.395841,34.60862],[-86.34512,34.623106],[-86.313112,34.629071],[-86.340002,34.78431],[-86.327563,34.831717],[-86.433773,34.800933]]]},"properties":{"id":"35748","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.904231,33.478316],[-86.929693,33.478538],[-86.951402,33.457937],[-86.915317,33.440759],[-86.884791,33.478206],[-86.880814,33.483217],[-86.904231,33.478316]]]},"properties":{"id":"35228","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.696817,33.590981],[-86.643334,33.655347],[-86.733008,33.701341],[-86.752386,33.676861],[-86.758023,33.66231],[-86.771626,33.643336],[-86.771767,33.643143],[-86.724608,33.59042],[-86.696817,33.590981]]]},"properties":{"id":"35215","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.716497,33.538825],[-86.744048,33.573646],[-86.783238,33.552622],[-86.778998,33.540159],[-86.738554,33.527645],[-86.730845,33.531153],[-86.716497,33.538825]]]},"properties":{"id":"35212","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.817538,33.523693],[-86.822733,33.504598],[-86.818161,33.506971],[-86.791266,33.525095],[-86.794972,33.530768],[-86.817538,33.523693]]]},"properties":{"id":"35203","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.971412,33.564767],[-86.96714,33.551747],[-86.966746,33.553626],[-86.971412,33.564767]]],[[[-86.971412,33.564767],[-86.942674,33.570016],[-86.928909,33.613953],[-87.043099,33.637861],[-87.068535,33.590759],[-86.986391,33.538376],[-86.992698,33.547162],[-86.971412,33.564767]]]]},"properties":{"id":"35005","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.96714,33.551747],[-86.971412,33.564767],[-86.992698,33.547162],[-86.96714,33.551747]]],[[[-87.117155,33.560062],[-87.014794,33.48585],[-86.999082,33.500027],[-86.986391,33.538376],[-87.068535,33.590759],[-87.117155,33.560062]]]]},"properties":{"id":"35118","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.854285,33.588821],[-86.860817,33.53405],[-86.82751,33.545357],[-86.786942,33.565073],[-86.790081,33.585332],[-86.854285,33.588821]]]},"properties":{"id":"35207","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.916694,33.639443],[-86.872214,33.595117],[-86.86356,33.629749],[-86.948084,33.708485],[-86.965299,33.703434],[-86.928294,33.651502],[-86.916694,33.639443]]]},"properties":{"id":"35117","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.868876,33.409808],[-86.87107,33.363352],[-86.806612,33.384634],[-86.812944,33.447256],[-86.841728,33.433242],[-86.868876,33.409808]]]},"properties":{"id":"35226","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.603505,34.800133],[-87.672766,34.883895],[-87.693598,34.774163],[-87.667886,34.780665],[-87.603505,34.800133]]]},"properties":{"id":"35630","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.577478,35.003542],[-87.671645,35.003598],[-87.672766,34.883895],[-87.603505,34.800133],[-87.553626,34.826886],[-87.577478,35.003542]]]},"properties":{"id":"35634","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.331794,34.909618],[-87.228775,34.8872],[-87.143788,34.915696],[-87.193977,34.998673],[-87.303621,35.000981],[-87.331794,34.909618]]]},"properties":{"id":"35610","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.923305,34.586692],[-87.062401,34.588207],[-87.112991,34.526298],[-87.106994,34.499161],[-87.030091,34.496146],[-86.855981,34.499113],[-86.800302,34.552623],[-86.906675,34.579758],[-86.923305,34.586692]]]},"properties":{"id":"35603","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.304186,32.716137],[-85.421721,32.608143],[-85.461281,32.429124],[-85.504521,32.347586],[-85.416489,32.409959],[-85.303581,32.382103],[-85.244918,32.420036],[-85.220644,32.450774],[-85.212834,32.671377],[-85.219099,32.707351],[-85.304186,32.716137]]]},"properties":{"id":"36804","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.07301,34.996359],[-87.143788,34.915696],[-86.90688,34.906156],[-86.834355,34.925896],[-86.805906,34.938383],[-86.904915,34.992738],[-87.07301,34.996359]]]},"properties":{"id":"35620","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.720047,34.760026],[-86.711327,34.692953],[-86.669305,34.709319],[-86.660588,34.738767],[-86.65502,34.751197],[-86.661714,34.757038],[-86.649062,34.756959],[-86.648264,34.751987],[-86.651031,34.771515],[-86.660526,34.814287],[-86.713531,34.815691],[-86.720047,34.760026]]]},"properties":{"id":"35806","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.720047,34.760026],[-86.786108,34.74783],[-86.787,34.661464],[-86.716788,34.66967],[-86.711327,34.692953],[-86.720047,34.760026]]]},"properties":{"id":"35758","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.535081,34.84475],[-86.592393,34.749321],[-86.529542,34.747802],[-86.43109,34.756945],[-86.433773,34.800933],[-86.500031,34.880717],[-86.535081,34.84475]]]},"properties":{"id":"35811","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.443664,34.703305],[-86.511269,34.668059],[-86.518815,34.650535],[-86.452986,34.548559],[-86.395841,34.60862],[-86.443664,34.703305]]]},"properties":{"id":"35763","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.311274,34.991098],[-86.439203,34.991031],[-86.539936,34.902747],[-86.500031,34.880717],[-86.433773,34.800933],[-86.327563,34.831717],[-86.340139,34.853272],[-86.271679,34.923961],[-86.311274,34.991098]]]},"properties":{"id":"35761","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.584002,34.840893],[-86.535081,34.84475],[-86.500031,34.880717],[-86.539936,34.902747],[-86.606917,34.880655],[-86.584002,34.840893]]]},"properties":{"id":"35759","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.875013,32.295698],[-88.014454,32.233503],[-88.016846,32.153413],[-88.053341,32.078065],[-87.805297,31.990456],[-87.779151,32.115792],[-87.782519,32.137273],[-87.875013,32.295698]]]},"properties":{"id":"36782","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.661484,32.12999],[-87.507021,32.085189],[-87.581081,32.132641],[-87.659638,32.136009],[-87.661484,32.12999]]]},"properties":{"id":"36722","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.622012,34.473782],[-86.550166,34.545963],[-86.586504,34.582747],[-86.636195,34.555198],[-86.684157,34.502314],[-86.622012,34.473782]]]},"properties":{"id":"35754","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.093234,30.756608],[-88.05023,30.758671],[-88.057301,30.77881],[-88.100768,30.787712],[-88.101002,30.767656],[-88.093234,30.756608]]]},"properties":{"id":"36611","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.126506,30.68817],[-88.080805,30.686661],[-88.078647,30.699997],[-88.121571,30.711653],[-88.122634,30.706577],[-88.126506,30.68817]]]},"properties":{"id":"36607","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.271884,30.373883],[-88.139724,30.312374],[-88.134584,30.312421],[-88.102264,30.452086],[-88.225535,30.40712],[-88.271884,30.373883]]]},"properties":{"id":"36523","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.100413,31.984813],[-87.162832,31.828416],[-87.119296,31.828903],[-87.033665,31.829669],[-87.023797,31.71273],[-87.001619,31.716428],[-86.994715,31.719221],[-86.90668,31.81507],[-86.886679,31.961647],[-87.118474,32.047249],[-87.100413,31.984813]],[[-87.100413,31.984813],[-87.069638,31.938698],[-87.10582,31.927267],[-87.100413,31.984813]]]},"properties":{"id":"36768","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.636195,34.555198],[-86.723833,34.581011],[-86.749308,34.559081],[-86.684157,34.502314],[-86.636195,34.555198]]]},"properties":{"id":"35775","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.951878,31.797254],[-85.949202,31.807265],[-85.957929,31.807368],[-85.96112,31.799276],[-85.951878,31.797254]]]},"properties":{"id":"36082","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.884371,33.078806],[-86.886875,33.082547],[-86.886544,33.079197],[-86.884371,33.078806]]]},"properties":{"id":"35187","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.421453,32.30868],[-88.344405,32.308157],[-88.212871,32.357833],[-88.299726,32.554771],[-88.386028,32.600487],[-88.421453,32.30868]]]},"properties":{"id":"36907","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.227529,33.225544],[-86.212669,33.261439],[-86.223048,33.236539],[-86.227529,33.225544]]]},"properties":{"id":"35149","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.333197,33.259258],[-86.332588,33.264851],[-86.338792,33.264286],[-86.333197,33.259258]]]},"properties":{"id":"35032","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.885534,32.81833],[-85.771295,32.885714],[-85.787274,32.96495],[-85.885534,32.81833]]]},"properties":{"id":"36861","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.40176,33.536412],[-87.717687,33.52019],[-87.773844,33.45169],[-87.768595,33.390886],[-87.730173,33.363329],[-87.641491,33.293746],[-87.534872,33.315514],[-87.383543,33.430923],[-87.341698,33.470501],[-87.33065,33.491646],[-87.40176,33.536412]]]},"properties":{"id":"35475","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.776909,33.324869],[-87.776981,33.257101],[-87.758036,33.293996],[-87.776909,33.324869]]]},"properties":{"id":"35457","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.322224,31.201681],[-88.179187,31.228798],[-88.182921,31.244883],[-88.255789,31.357146],[-88.313198,31.379508],[-88.326391,31.266139],[-88.322224,31.201681]]]},"properties":{"id":"36529","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.665212,30.696879],[-87.531037,30.743252],[-87.593481,30.976075],[-87.73965,31.075196],[-87.87886,30.959985],[-87.923161,30.750787],[-87.84816,30.736704],[-87.765606,30.789338],[-87.70683,30.691408],[-87.665212,30.696879]]]},"properties":{"id":"36507","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.448637,31.314891],[-87.577002,31.432096],[-87.691958,31.405062],[-87.765152,31.297346],[-87.763603,31.297253],[-87.650894,31.248782],[-87.448637,31.314891]]]},"properties":{"id":"36480","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.440373,30.533855],[-87.513394,30.512044],[-87.468306,30.480095],[-87.434794,30.481888],[-87.440373,30.533855]]]},"properties":{"id":"36574","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.779756,31.97322],[-85.794441,31.784163],[-85.702413,31.739095],[-85.610877,31.934573],[-85.636238,32.0156],[-85.779756,31.97322]]]},"properties":{"id":"36005","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.733008,33.701341],[-86.643334,33.655347],[-86.615863,33.675773],[-86.551266,33.742164],[-86.571061,33.765179],[-86.679242,33.805274],[-86.711204,33.798586],[-86.735326,33.762499],[-86.733008,33.701341]]]},"properties":{"id":"35126","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.786053,33.754903],[-85.722233,33.751833],[-85.694152,33.80298],[-85.744469,33.936102],[-85.821581,33.949777],[-85.871322,33.791739],[-85.847134,33.772851],[-85.786053,33.754903]]]},"properties":{"id":"36265","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.768884,34.07043],[-85.780658,33.962326],[-85.744469,33.936102],[-85.694152,33.80298],[-85.63838,33.730165],[-85.555667,33.770693],[-85.384708,33.892042],[-85.419219,34.067055],[-85.768884,34.07043]]]},"properties":{"id":"36272","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.84244,34.16091],[-85.841365,34.110695],[-85.789466,34.086297],[-85.687792,34.202606],[-85.764947,34.266997],[-85.84244,34.16091]]]},"properties":{"id":"35983","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.681238,34.288235],[-85.46326,34.29155],[-85.504824,34.485762],[-85.627512,34.39592],[-85.681238,34.288235]]]},"properties":{"id":"35973","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.025602,33.981316],[-86.025667,33.942659],[-85.985348,33.927077],[-85.954604,33.942975],[-85.841365,34.110695],[-85.84244,34.16091],[-85.869112,34.140929],[-86.025602,33.981316]]]},"properties":{"id":"35901","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.296145,31.993258],[-88.401143,31.991286],[-88.442639,31.993453],[-88.468883,31.930324],[-88.471214,31.851385],[-88.179302,31.821297],[-88.158366,31.853511],[-88.27794,32.02487],[-88.280052,32.022157],[-88.296145,31.993258]]]},"properties":{"id":"36908","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.377951,33.638099],[-85.437729,33.493779],[-85.304439,33.482884],[-85.332276,33.623541],[-85.377951,33.638099]]]},"properties":{"id":"36273","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.187246,30.994021],[-85.965668,30.993041],[-85.951924,31.101626],[-86.002124,31.221474],[-86.115197,31.272064],[-86.185532,31.090407],[-86.187246,30.994021]]]},"properties":{"id":"36477","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.069986,31.967264],[-86.053974,31.664522],[-86.049899,31.547759],[-86.037824,31.550217],[-85.9461,31.619027],[-85.861592,31.744108],[-86.038834,31.967297],[-86.069986,31.967264]]]},"properties":{"id":"36079","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.798639,31.196373],[-85.958238,31.227996],[-85.905003,31.134199],[-85.831024,31.108784],[-85.784623,31.159448],[-85.784859,31.159636],[-85.798639,31.196373]]]},"properties":{"id":"36316","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.139988,34.581703],[-88.006817,34.57715],[-87.988324,34.602469],[-87.855931,34.757851],[-87.986612,34.886745],[-88.097888,34.892202],[-88.102781,34.849395],[-88.085009,34.807035],[-88.111937,34.778526],[-88.139988,34.581703]]]},"properties":{"id":"35616","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.102781,34.849395],[-88.111937,34.778526],[-88.085009,34.807035],[-88.102781,34.849395]]]},"properties":{"id":"38852","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.311707,34.730636],[-87.283392,34.751947],[-87.414982,34.800494],[-87.42651,34.800022],[-87.45654,34.756822],[-87.524458,34.566932],[-87.529778,34.566085],[-87.444062,34.470228],[-87.335641,34.598999],[-87.311707,34.730636]]]},"properties":{"id":"35672","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.499358,31.565206],[-87.353476,31.435094],[-87.344072,31.420294],[-87.352279,31.412916],[-87.353471,31.356026],[-87.165662,31.542897],[-87.160096,31.553688],[-87.379505,31.626518],[-87.499358,31.565206]]]},"properties":{"id":"36460","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.209805,33.091386],[-86.342453,33.25128],[-86.483958,33.201992],[-86.503127,33.179144],[-86.481504,33.145159],[-86.517344,33.020566],[-86.436935,32.93088],[-86.277462,33.022999],[-86.22387,33.03654],[-86.209805,33.091386]]]},"properties":{"id":"35151","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.972233,32.745837],[-86.106533,32.846231],[-86.165338,32.827598],[-86.250267,32.753537],[-86.213697,32.70712],[-86.136839,32.752671],[-85.972233,32.745837]]]},"properties":{"id":"36026","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.344583,31.658612],[-86.191343,31.666245],[-86.183084,31.832048],[-86.226123,31.889924],[-86.372334,31.837518],[-86.396249,31.776128],[-86.344583,31.658612]]]},"properties":{"id":"36049","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.647871,34.108474],[-86.866138,34.101469],[-86.965678,33.990304],[-86.92369,33.872224],[-86.744089,34.007807],[-86.763072,33.99741],[-86.763227,34.022691],[-86.736688,34.012006],[-86.706017,34.031306],[-86.647871,34.108474]]]},"properties":{"id":"35077","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.645016,34.245606],[-86.672666,34.178235],[-86.619706,34.120796],[-86.540213,34.224874],[-86.549859,34.247839],[-86.583148,34.261641],[-86.645016,34.245606]]]},"properties":{"id":"35083","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.552627,31.346322],[-85.561571,31.292988],[-85.518601,31.307772],[-85.552627,31.346322]]]},"properties":{"id":"36371","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.288876,31.299753],[-85.226203,31.271617],[-85.229359,31.309375],[-85.184019,31.437404],[-85.305094,31.507335],[-85.426661,31.418436],[-85.42599,31.325651],[-85.288876,31.299753]]]},"properties":{"id":"36345","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.810802,32.307574],[-86.90642,32.323337],[-86.908333,32.203384],[-86.907981,32.156686],[-86.853893,32.118392],[-86.766053,32.308425],[-86.810802,32.307574]]]},"properties":{"id":"36785","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.745787,34.594805],[-85.691678,34.518515],[-85.64954,34.524735],[-85.546412,34.680287],[-85.558071,34.734425],[-85.592812,34.696898],[-85.745787,34.594805]]]},"properties":{"id":"35989","state":"01"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.812997,34.579893],[-85.869953,34.623719],[-85.854424,34.57974],[-85.853556,34.578365],[-85.812997,34.579893]]],[[[-85.85917,34.647282],[-85.705727,34.709535],[-85.704718,34.728896],[-85.741787,34.774866],[-85.864004,34.744526],[-85.951049,34.656131],[-85.940952,34.657272],[-85.85917,34.647282]]]]},"properties":{"id":"35765","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.08828,32.580513],[-86.281497,32.521352],[-86.300096,32.508011],[-86.278069,32.486035],[-86.192284,32.43613],[-86.086113,32.421505],[-86.030912,32.535417],[-86.08828,32.580513]]]},"properties":{"id":"36093","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.364389,32.534928],[-86.336551,32.519367],[-86.300096,32.508011],[-86.281497,32.521352],[-86.322544,32.575921],[-86.364389,32.534928]]]},"properties":{"id":"36025","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.462826,31.215137],[-87.456093,31.220144],[-87.464403,31.223838],[-87.462826,31.215137]]]},"properties":{"id":"36543","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.715006,33.827217],[-87.666633,33.577316],[-87.649976,33.737477],[-87.620545,33.841353],[-87.715006,33.827217]]]},"properties":{"id":"35542","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.636042,33.968508],[-87.753553,33.849906],[-87.715006,33.827217],[-87.620545,33.841353],[-87.566821,33.940758],[-87.636042,33.968508]]]},"properties":{"id":"35554","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.692207,30.995573],[-85.830349,31.048079],[-85.831123,30.99412],[-85.692207,30.995573]]]},"properties":{"id":"36314","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.15869,32.860809],[-87.870382,32.762625],[-87.816325,32.819437],[-87.816828,32.832858],[-87.774254,32.927697],[-87.780268,33.017197],[-87.932645,33.113864],[-88.026971,33.063602],[-88.171852,32.99586],[-88.197093,32.935778],[-88.15869,32.860809]]]},"properties":{"id":"35462","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.489249,32.655791],[-87.421292,32.862836],[-87.633175,32.833121],[-87.655531,32.786371],[-87.75001,32.637448],[-87.757921,32.578893],[-87.65378,32.523304],[-87.489249,32.655791]]]},"properties":{"id":"36744","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.37464,31.567159],[-85.305094,31.507335],[-85.184019,31.437404],[-85.130918,31.45727],[-85.057877,31.606952],[-85.127329,31.762563],[-85.329488,31.676715],[-85.37464,31.567159]]]},"properties":{"id":"36310","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.226203,31.271617],[-85.288876,31.299753],[-85.314683,31.240442],[-85.281744,31.204787],[-85.2063,31.263075],[-85.200926,31.2717],[-85.226203,31.271617]]]},"properties":{"id":"36376","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.316039,31.195902],[-85.316001,31.193325],[-85.316936,31.18915],[-85.281744,31.204787],[-85.314683,31.240442],[-85.316039,31.195902]]]},"properties":{"id":"36321","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.221992,34.622645],[-86.171718,34.775816],[-86.270009,34.725019],[-86.313112,34.629071],[-86.34512,34.623106],[-86.311702,34.542291],[-86.208953,34.590765],[-86.221992,34.622645]]]},"properties":{"id":"35776","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.700824,34.986333],[-85.860921,34.988243],[-85.718707,34.899574],[-85.700824,34.986333]]]},"properties":{"id":"35740","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.786942,33.565073],[-86.783238,33.552622],[-86.744048,33.573646],[-86.724608,33.59042],[-86.771767,33.643143],[-86.795956,33.631436],[-86.790081,33.585332],[-86.786942,33.565073]]]},"properties":{"id":"35217","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.854285,33.588821],[-86.790081,33.585332],[-86.795956,33.631436],[-86.86356,33.629749],[-86.872214,33.595117],[-86.854285,33.588821]]]},"properties":{"id":"35068","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.751962,33.767243],[-86.825085,33.770258],[-86.814278,33.759399],[-86.751962,33.767243]]]},"properties":{"id":"35091","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.228775,34.8872],[-87.331794,34.909618],[-87.418231,34.900358],[-87.414982,34.800494],[-87.283392,34.751947],[-87.260676,34.758626],[-87.187725,34.831377],[-87.228775,34.8872]]]},"properties":{"id":"35652","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.105424,34.652728],[-87.21318,34.52685],[-87.112991,34.526298],[-87.062401,34.588207],[-87.105424,34.652728]]]},"properties":{"id":"35673","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.311707,34.730636],[-87.335641,34.598999],[-87.243926,34.616866],[-87.311707,34.730636]]]},"properties":{"id":"35618","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.030229,32.475166],[-84.995245,32.453122],[-85.015303,32.531149],[-85.056847,32.509271],[-85.030229,32.475166]]]},"properties":{"id":"36867","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.884997,34.600595],[-86.85744,34.612492],[-86.867034,34.632374],[-86.884997,34.600595]]]},"properties":{"id":"35649","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.802426,34.835353],[-86.645779,34.822396],[-86.584002,34.840893],[-86.606917,34.880655],[-86.703339,34.992123],[-86.713276,34.992093],[-86.805906,34.938383],[-86.834355,34.925896],[-86.802426,34.835353]]]},"properties":{"id":"35773","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.786108,34.74783],[-86.720047,34.760026],[-86.713531,34.815691],[-86.795876,34.748973],[-86.786108,34.74783]]]},"properties":{"id":"35757","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.766053,32.308425],[-86.534114,32.187274],[-86.484536,32.284333],[-86.496774,32.344437],[-86.588261,32.367749],[-86.782467,32.375137],[-86.810802,32.307574],[-86.766053,32.308425]]]},"properties":{"id":"36752","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.598052,32.509112],[-85.797458,32.513303],[-85.897787,32.449646],[-85.821376,32.320394],[-85.709576,32.268628],[-85.709301,32.233444],[-85.52499,32.349252],[-85.504521,32.347586],[-85.461281,32.429124],[-85.598052,32.509112]],[[-85.730681,32.403879],[-85.728498,32.436054],[-85.704745,32.43329],[-85.71114,32.394219],[-85.730681,32.403879]]]},"properties":{"id":"36083","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.821376,32.320394],[-85.91703,32.313612],[-85.854967,32.231996],[-85.781104,32.233269],[-85.709576,32.268628],[-85.821376,32.320394]]]},"properties":{"id":"36039","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.34512,34.623106],[-86.395841,34.60862],[-86.452986,34.548559],[-86.462611,34.478409],[-86.311702,34.542291],[-86.34512,34.623106]]]},"properties":{"id":"35760","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.080805,30.686661],[-88.126506,30.68817],[-88.130802,30.676089],[-88.126431,30.661539],[-88.118984,30.641366],[-88.079893,30.671621],[-88.080805,30.686661]]]},"properties":{"id":"36606","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.110967,30.738448],[-88.093234,30.756608],[-88.101002,30.767656],[-88.132544,30.74895],[-88.119145,30.740456],[-88.110967,30.738448]]]},"properties":{"id":"36612","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.093234,30.756608],[-88.110967,30.738448],[-88.066375,30.717868],[-88.050157,30.706605],[-88.047797,30.712122],[-88.042291,30.733232],[-88.05023,30.758671],[-88.093234,30.756608]],[[-88.081683,30.724855],[-88.080823,30.724608],[-88.081624,30.723641],[-88.081683,30.724855]]]},"properties":{"id":"36610","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.100416,31.144109],[-88.089467,31.063388],[-88.049496,31.007396],[-88.020229,31.016781],[-87.972869,31.162694],[-88.058317,31.144083],[-88.100416,31.144109]]]},"properties":{"id":"36560","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.050518,30.647148],[-88.077198,30.62919],[-88.055001,30.607905],[-88.050518,30.647148]]]},"properties":{"id":"36615","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.139724,30.312374],[-88.143546,30.262239],[-88.097183,30.207922],[-88.063228,30.251409],[-88.134584,30.312421],[-88.139724,30.312374]]]},"properties":{"id":"36528","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.066375,30.717868],[-88.110967,30.738448],[-88.119145,30.740456],[-88.121571,30.711653],[-88.078647,30.699997],[-88.077733,30.700927],[-88.066375,30.717868]]]},"properties":{"id":"36617","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.245496,32.402207],[-86.239653,32.411817],[-86.253279,32.411288],[-86.257275,32.40322],[-86.245496,32.402207]]]},"properties":{"id":"36115","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.997118,32.079698],[-86.122572,32.182941],[-86.152209,32.236548],[-86.221945,32.193765],[-86.333133,32.128831],[-86.328493,32.10821],[-86.256727,32.036458],[-85.996059,32.010371],[-85.966389,32.054446],[-85.997118,32.079698]]]},"properties":{"id":"36069","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.349974,32.372486],[-86.361851,32.398323],[-86.376565,32.393571],[-86.371486,32.365931],[-86.349974,32.372486]]]},"properties":{"id":"36113","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.262643,32.352382],[-86.299615,32.344582],[-86.299237,32.327042],[-86.23494,32.328324],[-86.262643,32.352382]]]},"properties":{"id":"36111","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.612279,33.24758],[-85.46441,33.236241],[-85.409396,33.281256],[-85.529213,33.457938],[-85.623645,33.495373],[-85.609105,33.411575],[-85.612279,33.24758]]]},"properties":{"id":"36278","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.529213,33.457938],[-85.409396,33.281256],[-85.262513,33.265604],[-85.296492,33.440624],[-85.434569,33.467865],[-85.529213,33.457938]]]},"properties":{"id":"36280","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.999908,32.321371],[-84.977317,32.295434],[-84.955785,32.306772],[-84.999908,32.321371]]]},"properties":{"id":"31905","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.242318,33.590058],[-86.398292,33.592235],[-86.417162,33.593136],[-86.462422,33.578612],[-86.463499,33.578583],[-86.48186,33.512245],[-86.443636,33.502336],[-86.413226,33.502395],[-86.280896,33.5108],[-86.192673,33.55314],[-86.220976,33.587155],[-86.242318,33.590058]]]},"properties":{"id":"35128","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.295994,33.685047],[-86.186779,33.65732],[-86.172437,33.659831],[-86.145562,33.679098],[-86.065043,33.803723],[-86.295994,33.685047]]]},"properties":{"id":"35131","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.138499,33.898386],[-86.299466,33.856035],[-86.350095,33.710497],[-86.332396,33.668565],[-86.295994,33.685047],[-86.065043,33.803723],[-86.065272,33.842198],[-86.06812,33.865686],[-86.138499,33.898386]]]},"properties":{"id":"35953","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.242318,33.590058],[-86.220976,33.587155],[-86.172437,33.659831],[-86.186779,33.65732],[-86.242318,33.590058]]]},"properties":{"id":"35135","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.492973,33.672009],[-86.472304,33.679755],[-86.493386,33.674865],[-86.492973,33.672009]]]},"properties":{"id":"35112","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.156553,32.824903],[-88.21524,32.791812],[-88.228394,32.671632],[-88.057391,32.593486],[-88.156553,32.824903]]]},"properties":{"id":"35460","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.21524,32.791812],[-88.21967,32.833683],[-88.231612,32.860939],[-88.293836,32.992953],[-88.340432,32.991199],[-88.377187,32.676166],[-88.228394,32.671632],[-88.21524,32.791812]]]},"properties":{"id":"35459","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.787274,32.96495],[-85.771295,32.885714],[-85.885534,32.81833],[-85.907668,32.698717],[-85.824563,32.637137],[-85.813849,32.637501],[-85.647885,32.965142],[-85.763722,32.97708],[-85.787274,32.96495]]]},"properties":{"id":"36853","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.629377,33.211958],[-87.730173,33.363329],[-87.768595,33.390886],[-87.758036,33.293996],[-87.776981,33.257101],[-87.823028,33.164152],[-87.711256,33.098321],[-87.70256,33.157611],[-87.629377,33.211958]]]},"properties":{"id":"35446","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.083345,33.817012],[-87.070567,33.821364],[-87.070666,33.821578],[-87.083345,33.817012]]]},"properties":{"id":"35584","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.018881,31.475639],[-87.967058,31.434821],[-87.915253,31.451478],[-88.020322,31.553427],[-88.018881,31.475639]]]},"properties":{"id":"36548","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.484116,31.829564],[-87.381223,31.913581],[-87.527755,31.874517],[-87.50093,31.829251],[-87.484116,31.829564]]]},"properties":{"id":"36435","state":"01"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-170.287655,57.126766],[-170.161647,57.229656],[-170.42041,57.19176],[-170.287655,57.126766]]]},"properties":{"id":"99660","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.502891,53.9145],[-166.641971,53.801479],[-166.478432,53.801403],[-166.371869,53.890449],[-166.502891,53.9145]],[[-166.538953,53.889403],[-166.530888,53.889176],[-166.537445,53.879961],[-166.544807,53.883648],[-166.538953,53.889403]]]},"properties":{"id":"99685","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.963611,61.200582],[-149.97712,61.124071],[-149.908258,61.140196],[-149.916803,61.172562],[-149.963611,61.200582]]]},"properties":{"id":"99502","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.190367,61.745689],[-159.023498,60.7319],[-157.667894,60.911643],[-155.9611,60.907602],[-154.507388,60.907659],[-153.436195,60.908538],[-153.002181,61.425686],[-153.001262,62.295331],[-158.178961,61.744981],[-158.190367,61.745689]]]},"properties":{"id":"99557","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.613931,60.345792],[-162.616835,60.498907],[-162.850665,60.412405],[-162.613931,60.345792]]]},"properties":{"id":"99680","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.149285,60.568576],[-165.003825,60.542172],[-165.005013,60.561662],[-165.055971,60.580018],[-165.149285,60.568576]]]},"properties":{"id":"99637","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.364772,61.527311],[-160.344887,61.525626],[-159.382295,61.635895],[-159.934324,61.945466],[-160.364772,61.527311]]]},"properties":{"id":"99607","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.161055,61.733674],[-157.615567,61.850393],[-157.540195,61.920226],[-157.626204,61.96851],[-157.780444,61.85735],[-157.404367,61.7855],[-157.392704,61.728554],[-157.182614,61.731312],[-157.161055,61.733674]]]},"properties":{"id":"99656","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.100336,59.302124],[-157.159216,58.938939],[-156.777671,58.732192],[-156.831227,58.709969],[-156.886244,58.609778],[-154.40939,59.25464],[-154.292078,59.429774],[-154.627736,59.429234],[-154.849585,59.435008],[-156.100336,59.302124]]]},"properties":{"id":"99613","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.228189,64.35885],[-149.144702,64.290095],[-149.289255,64.228762],[-148.681931,63.880371],[-148.229405,63.805998],[-148.645515,64.63499],[-148.644794,64.772808],[-149.117602,64.86398],[-149.133625,64.622047],[-149.228189,64.35885]]]},"properties":{"id":"99760","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.144702,64.290095],[-149.13118,64.300281],[-149.185063,64.301478],[-149.18684,64.29478],[-149.144702,64.290095]]]},"properties":{"id":"99704","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.745901,58.873949],[-158.518996,58.848564],[-158.50327,58.784221],[-158.548993,59.228794],[-158.707496,59.276361],[-157.526505,59.32665],[-157.526036,59.390312],[-157.443597,59.363227],[-157.318082,59.432954],[-157.354455,59.518593],[-157.186061,59.494719],[-157.667894,60.911643],[-159.023498,60.7319],[-159.851637,59.978089],[-159.626772,59.383452],[-158.745901,58.873949]]]},"properties":{"id":"99576","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.518996,58.848564],[-158.564376,58.808606],[-158.50327,58.784221],[-158.518996,58.848564]]]},"properties":{"id":"99569","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.564603,64.852042],[-147.655641,64.816594],[-147.572232,64.811116],[-147.564603,64.852042]]]},"properties":{"id":"99703","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-136.364351,58.211663],[-135.906742,58.001266],[-136.164427,58.220517],[-136.280966,58.222182],[-136.364351,58.211663]]]},"properties":{"id":"99832","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.974871,60.47723],[-149.684896,60.425],[-149.759431,60.6192],[-149.998328,60.587298],[-150.589726,60.525021],[-150.590562,60.475356],[-150.602747,60.467687],[-150.504147,60.468176],[-150.194288,60.436005],[-149.974871,60.47723]]]},"properties":{"id":"99572","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.279906,60.469369],[-151.165292,60.2325],[-150.985966,60.29051],[-150.602747,60.467687],[-150.590562,60.475356],[-150.960116,60.55133],[-151.279906,60.469369]]]},"properties":{"id":"99669","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.40939,59.25464],[-150.972764,61.235438],[-153.002181,61.425686],[-153.436195,60.908538],[-153.65846,59.994343],[-153.657551,59.965164],[-154.292078,59.429774],[-154.40939,59.25464]]]},"properties":{"id":"99682","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-152.518397,57.919273],[-152.361672,57.884468],[-152.322202,57.913612],[-152.484689,57.956164],[-152.518397,57.919273]]]},"properties":{"id":"99644","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.657261,62.958017],[-150.161733,62.18101],[-149.429115,62.122735],[-149.170311,62.958965],[-149.657261,62.958017]]]},"properties":{"id":"99676","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.721494,61.635047],[-149.858801,61.621821],[-149.7414,61.591585],[-149.721494,61.635047]]]},"properties":{"id":"99694","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.723555,64.787113],[-161.934377,65.042979],[-163.071482,65.317007],[-163.492234,64.7862],[-162.723555,64.787113]]]},"properties":{"id":"99739","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-167.978284,65.559653],[-167.815086,65.762028],[-168.093541,65.686227],[-167.978284,65.559653]]]},"properties":{"id":"99783","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.024776,67.558338],[-160.452625,66.965494],[-160.43716,66.989859],[-160.41317,66.989859],[-160.419866,66.974483],[-160.192482,67.565333],[-163.01089,67.521098],[-163.024776,67.558338]]]},"properties":{"id":"99752","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.050096,57.024477],[-134.277925,56.622132],[-133.86737,56.783331],[-134.050096,57.024477]]]},"properties":{"id":"99830","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-135.389297,59.356239],[-135.027456,59.563692],[-135.722551,59.729629],[-135.389297,59.356239]]]},"properties":{"id":"99840","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.605969,61.523681],[-165.584235,61.52083],[-165.569954,61.535385],[-165.606064,61.535608],[-165.605969,61.523681]]]},"properties":{"id":"99563","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.093211,61.529882],[-166.167922,61.548762],[-166.137096,61.502084],[-166.093211,61.529882]]]},"properties":{"id":"99604","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-139.519988,59.686673],[-139.85679,59.536193],[-139.396504,59.376942],[-139.519988,59.686673]]]},"properties":{"id":"99689","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-145.748118,68.055296],[-145.512482,68.10828],[-145.506842,68.133035],[-145.748118,68.055296]]]},"properties":{"id":"99722","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.463872,64.597459],[-153.760261,65.034369],[-154.60548,65.834225],[-155.976295,64.623474],[-154.463872,64.597459]]]},"properties":{"id":"99768","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.150838,65.515758],[-150.17881,65.503888],[-150.169966,65.485037],[-150.117129,65.502468],[-150.150838,65.515758]]]},"properties":{"id":"99767","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.737894,66.634307],[-141.002694,68.498391],[-145.133752,68.502099],[-145.506842,68.133035],[-145.512482,68.10828],[-145.196611,67.358456],[-145.277845,66.520929],[-144.076456,65.908982],[-143.066433,65.912418],[-142.14487,66.121408],[-143.728086,66.662098],[-143.737894,66.634307]]]},"properties":{"id":"99740","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.858728,61.175429],[-149.8564,61.144735],[-149.856401,61.118868],[-149.683181,61.103095],[-149.718832,61.180934],[-149.778351,61.180799],[-149.86149,61.183953],[-149.858728,61.175429]]]},"properties":{"id":"99507","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.538413,61.412496],[-148.947349,61.048816],[-148.828264,61.07069],[-148.477833,61.079058],[-148.460007,61.426972],[-148.482842,61.427307],[-149.237576,61.480596],[-149.538413,61.412496]]]},"properties":{"id":"99567","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.730823,61.016044],[-149.184091,60.943171],[-149.162376,60.99491],[-149.562267,61.059359],[-149.730823,61.016044]]]},"properties":{"id":"99540","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.887356,61.21461],[-149.885312,61.214609],[-149.883283,61.215593],[-149.887353,61.215594],[-149.887356,61.21461]]]},"properties":{"id":"99513","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.182614,61.731312],[-157.197063,61.68486],[-157.071943,61.675486],[-157.161055,61.733674],[-157.182614,61.731312]]]},"properties":{"id":"99668","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.974485,61.09694],[-160.924191,61.093794],[-160.923332,61.114626],[-160.974485,61.09694]]]},"properties":{"id":"99679","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.178961,61.744981],[-157.962394,61.796502],[-158.048245,61.946805],[-158.317691,61.825414],[-158.190367,61.745689],[-158.178961,61.744981]]]},"properties":{"id":"99575","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.548993,59.228794],[-158.538325,59.287163],[-158.707496,59.276361],[-158.548993,59.228794]]]},"properties":{"id":"99555","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-146.942897,64.601097],[-147.026422,64.654586],[-147.061395,64.599862],[-146.942897,64.601097]]]},"properties":{"id":"99702","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.459763,60.944036],[-151.282997,60.46933],[-151.279906,60.469369],[-150.960116,60.55133],[-150.459763,60.944036]]]},"properties":{"id":"99611","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.615697,59.477602],[-151.896097,59.389249],[-151.548762,59.303811],[-151.615697,59.477602]]]},"properties":{"id":"99663","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-153.215445,57.217704],[-153.28483,57.248625],[-153.455034,57.228183],[-153.36874,57.16812],[-153.215445,57.217704]]]},"properties":{"id":"99643","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-152.959169,57.810648],[-152.797556,57.899539],[-153.192918,57.92387],[-153.04009,57.81811],[-152.959169,57.810648]]]},"properties":{"id":"99550","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.798936,56.233374],[-158.691589,56.232721],[-158.7023,56.262404],[-158.798936,56.233374]]]},"properties":{"id":"99548","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.946211,70.154258],[-151.024301,70.305776],[-152.317422,70.105246],[-152.634002,69.65854],[-151.901019,69.808987],[-151.567171,70.063891],[-150.946211,70.154258]]]},"properties":{"id":"99789","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.01089,67.521098],[-162.932287,67.620248],[-163.030697,67.601856],[-163.024776,67.558338],[-163.01089,67.521098]]]},"properties":{"id":"99761","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.72535,66.075303],[-162.759731,66.09601],[-162.788524,66.089556],[-162.767585,66.078094],[-162.72535,66.075303]]]},"properties":{"id":"99736","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-133.128372,55.605085],[-133.032304,55.535599],[-132.855587,55.544782],[-132.944181,55.633446],[-133.120079,55.610552],[-133.128372,55.605085]]]},"properties":{"id":"99925","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-133.10045,55.260551],[-132.802339,55.193795],[-132.89029,55.351063],[-133.217848,55.28078],[-133.10045,55.260551]]]},"properties":{"id":"99922","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-141.752619,64.20248],[-142.527686,64.037089],[-142.047931,63.902182],[-141.913327,64.052424],[-141.410007,64.167504],[-141.752619,64.20248]]]},"properties":{"id":"99732","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-144.836117,61.21082],[-141.001857,60.391688],[-141.002607,61.745387],[-145.295237,61.966767],[-144.868202,61.494176],[-144.836117,61.21082]]]},"properties":{"id":"99566","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-145.277845,66.520929],[-147.885853,65.494796],[-147.904433,65.331245],[-146.698065,65.320072],[-144.00057,64.980555],[-142.782738,65.313173],[-144.059012,65.632561],[-144.109494,65.690836],[-144.376012,65.693034],[-144.263039,65.887836],[-144.076456,65.908982],[-145.277845,66.520929]]]},"properties":{"id":"99730","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.533958,62.658294],[-159.548656,62.635701],[-159.50896,62.635465],[-159.505322,62.658667],[-159.533958,62.658294]]]},"properties":{"id":"99665","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.785857,54.13208],[-165.865773,54.15183],[-165.863869,54.105532],[-165.785857,54.13208]]]},"properties":{"id":"99553","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.534171,55.242476],[-160.587184,55.376301],[-160.840277,55.341045],[-160.853366,55.206268],[-160.534171,55.242476]]]},"properties":{"id":"99661","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-176.656247,51.85149],[-176.629703,51.800809],[-176.579065,51.826423],[-176.656247,51.85149]]]},"properties":{"id":"99546","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.055971,60.580018],[-165.094257,60.654202],[-165.231666,60.616921],[-165.149285,60.568576],[-165.055971,60.580018]]]},"properties":{"id":"99681","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.235126,60.911971],[-161.215257,60.908409],[-161.216662,60.920827],[-161.234389,60.920894],[-161.235126,60.911971]]]},"properties":{"id":"99552","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.916297,59.751108],[-161.906032,59.735032],[-161.867296,59.735139],[-161.866803,59.758646],[-161.916297,59.751108]]]},"properties":{"id":"99655","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.653484,64.979434],[-147.731768,64.863496],[-147.526886,64.860753],[-147.108605,64.81328],[-146.300233,64.566678],[-146.263164,64.55191],[-144.00057,64.980555],[-146.698065,65.320072],[-148.653484,64.979434]]]},"properties":{"id":"99712","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-135.906742,58.001266],[-135.443036,58.07797],[-135.436046,58.13463],[-136.164427,58.220517],[-135.906742,58.001266]]]},"properties":{"id":"99825","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-135.443036,58.07797],[-135.379789,58.08858],[-135.436046,58.13463],[-135.443036,58.07797]]]},"properties":{"id":"99829","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-135.210538,57.778277],[-135.139304,57.775445],[-135.06965,57.803698],[-135.260956,57.803734],[-135.210538,57.778277]]]},"properties":{"id":"99841","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.310876,60.199702],[-151.382317,60.297638],[-151.546585,60.128634],[-151.310876,60.199702]]]},"properties":{"id":"99568","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.097063,59.957539],[-151.780285,59.698871],[-151.615697,59.477602],[-151.548762,59.303811],[-151.896097,59.389249],[-150.70598,59.429826],[-150.70496,59.680845],[-151.097063,59.957539]]]},"properties":{"id":"99603","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.691081,56.896515],[-158.50983,56.885689],[-158.510561,56.999464],[-158.672279,56.999222],[-158.691081,56.896515]]]},"properties":{"id":"99549","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.161733,62.18101],[-150.881148,62.177073],[-150.486195,61.587338],[-150.024851,61.590951],[-149.858801,61.621821],[-149.721494,61.635047],[-149.390824,61.728334],[-149.194474,61.857106],[-149.429115,62.122735],[-150.161733,62.18101]]]},"properties":{"id":"99688","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.7414,61.591585],[-149.858801,61.621821],[-150.024851,61.590951],[-150.167002,61.451871],[-149.7414,61.591585]]]},"properties":{"id":"99652","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.222587,63.440883],[-162.016475,63.482603],[-162.165948,63.527135],[-162.222587,63.440883]]]},"properties":{"id":"99659","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.696819,71.323453],[-156.924498,71.230292],[-156.728739,71.230397],[-156.696819,71.323453]]]},"properties":{"id":"99723","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.081815,69.721037],[-162.833014,69.699259],[-162.654193,69.764294],[-162.913823,69.758779],[-163.081815,69.721037]]]},"properties":{"id":"99759","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-164.583204,67.743405],[-164.588539,67.741313],[-164.499581,67.713092],[-164.507167,67.71875],[-164.583204,67.743405]]]},"properties":{"id":"99750","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-131.649827,55.133616],[-131.380161,54.992227],[-131.293888,55.181496],[-131.649827,55.133616]]]},"properties":{"id":"99926","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-133.064271,56.220399],[-133.195432,56.332863],[-133.621072,56.35831],[-133.473937,56.174745],[-133.064271,56.220399]]]},"properties":{"id":"99927","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-142.10875,62.902786],[-141.002186,62.977563],[-141.002256,64.038089],[-142.537585,63.333848],[-142.097757,63.156007],[-142.10875,62.902786]]]},"properties":{"id":"99764","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.40287,63.40983],[-143.598524,63.381221],[-143.31107,63.373919],[-143.40287,63.40983]]]},"properties":{"id":"99776","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-145.390122,62.038186],[-146.9587,61.910941],[-147.710953,61.427271],[-147.744303,61.427403],[-144.868202,61.494176],[-145.295237,61.966767],[-145.390122,62.038186]]]},"properties":{"id":"99573","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.879833,62.926665],[-145.462742,62.884438],[-145.497445,62.809705],[-145.502704,62.278435],[-145.411618,62.26467],[-143.332947,62.973128],[-143.879833,62.926665]]]},"properties":{"id":"99586","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.118498,61.862833],[-162.307368,61.965318],[-162.388935,61.891091],[-162.118498,61.862833]]]},"properties":{"id":"99585","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.466791,62.080573],[-163.268229,62.028335],[-163.116978,62.103864],[-163.466791,62.080573]]]},"properties":{"id":"99658","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.90739,61.938815],[-162.883228,61.933189],[-162.856978,61.952156],[-162.871731,61.957728],[-162.90739,61.938815]]]},"properties":{"id":"99650","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-164.587296,62.767756],[-164.467446,62.764498],[-164.466868,62.795024],[-164.60293,62.79558],[-164.587296,62.767756]]]},"properties":{"id":"99581","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-145.748118,68.055296],[-145.196611,67.358456],[-145.512482,68.10828],[-145.748118,68.055296]]]},"properties":{"id":"99781","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-152.641043,66.566685],[-152.997208,66.60746],[-152.963737,66.546453],[-152.794181,66.524367],[-152.641043,66.566685]]]},"properties":{"id":"99720","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.039455,64.740943],[-158.192595,64.74662],[-158.132077,64.709295],[-158.039455,64.740943]]]},"properties":{"id":"99765","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.538953,53.889403],[-166.544807,53.883648],[-166.537445,53.879961],[-166.530888,53.889176],[-166.538953,53.889403]]]},"properties":{"id":"99692","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-174.229073,52.194337],[-174.182857,52.232762],[-174.23505,52.27252],[-174.229073,52.194337]]]},"properties":{"id":"99547","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.916803,61.172562],[-149.912788,61.203013],[-149.924756,61.208334],[-149.963611,61.200582],[-149.916803,61.172562]]]},"properties":{"id":"99517","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.792998,60.688308],[-161.675943,60.831309],[-161.914984,60.83261],[-161.792998,60.688308]]]},"properties":{"id":"99559","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-164.041891,59.940699],[-164.120516,59.941497],[-164.09732,59.898863],[-164.024625,59.905141],[-164.041891,59.940699]]]},"properties":{"id":"99614","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.144702,64.290095],[-149.228189,64.35885],[-149.231664,64.358736],[-149.289255,64.228762],[-149.144702,64.290095]]]},"properties":{"id":"99744","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.527011,63.220827],[-149.657261,62.958017],[-149.170311,62.958965],[-148.633802,63.43463],[-148.804485,63.45664],[-149.367786,63.332659],[-149.527011,63.220827]]]},"properties":{"id":"99729","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.564603,64.852042],[-147.572232,64.811116],[-147.151039,64.632606],[-147.107102,64.637234],[-147.108605,64.81328],[-147.526886,64.860753],[-147.564603,64.852042]]]},"properties":{"id":"99705","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-136.486609,59.261108],[-135.492209,58.500294],[-135.389297,59.356239],[-135.722551,59.729629],[-136.486609,59.261108]]]},"properties":{"id":"99827","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.392904,58.215842],[-133.176444,58.150151],[-134.250526,58.858046],[-135.045921,58.531978],[-134.392904,58.215842]],[[-134.410732,58.28573],[-134.371047,58.272381],[-134.406187,58.268842],[-134.410732,58.28573]]]},"properties":{"id":"99801","state":"02"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-151.382317,60.297638],[-151.310876,60.199702],[-151.212629,60.177374],[-151.165292,60.2325],[-151.279906,60.469369],[-151.282997,60.46933],[-151.382317,60.297638]]],[[[-150.218793,59.900853],[-149.974871,60.47723],[-150.194288,60.436005],[-150.504147,60.468176],[-150.602747,60.467687],[-150.985966,60.29051],[-150.218793,59.900853]]]]},"properties":{"id":"99610","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.387941,59.788594],[-153.657551,59.965164],[-153.65846,59.994343],[-154.260936,59.984474],[-154.387941,59.788594]]]},"properties":{"id":"99647","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.270883,55.891614],[-159.163204,55.886036],[-159.072788,55.915599],[-159.132771,55.937074],[-159.189187,55.896118],[-159.270883,55.891614]]]},"properties":{"id":"99648","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.406246,60.162963],[-154.225886,60.196432],[-154.289275,60.273521],[-154.406246,60.162963]]]},"properties":{"id":"99653","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.606965,57.495608],[-157.151227,57.460722],[-157.224501,57.593843],[-157.606965,57.495608]]]},"properties":{"id":"99649","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.468339,55.89895],[-159.486128,55.936751],[-159.516898,55.923819],[-159.514566,55.90264],[-159.468339,55.89895]]]},"properties":{"id":"99695","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.721494,61.635047],[-149.7414,61.591585],[-150.167002,61.451871],[-149.251555,61.550692],[-149.390824,61.728334],[-149.721494,61.635047]]]},"properties":{"id":"99654","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.77611,63.86829],[-160.042262,63.93642],[-159.960914,64.401237],[-160.907196,64.033511],[-160.77611,63.86829]]]},"properties":{"id":"99684","state":"02"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-163.387893,64.683141],[-162.723555,64.787113],[-163.492234,64.7862],[-163.425375,64.689092],[-163.387893,64.683141]]],[[[-165.371428,64.491964],[-164.303148,64.659262],[-164.401526,64.976286],[-165.744383,64.792232],[-165.371428,64.491964]]]]},"properties":{"id":"99762","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.425375,64.689092],[-163.427017,64.682704],[-163.388999,64.67248],[-163.387893,64.683141],[-163.425375,64.689092]]]},"properties":{"id":"99784","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-171.732682,63.715696],[-171.622914,63.743297],[-171.727492,63.807398],[-171.810934,63.741196],[-171.732682,63.715696]]]},"properties":{"id":"99742","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.598357,70.136039],[-143.735363,70.129696],[-143.676347,70.099786],[-143.598357,70.136039]]]},"properties":{"id":"99747","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.983266,66.582603],[-159.135778,66.601015],[-158.872415,66.950716],[-159.995813,66.996858],[-159.983266,66.582603]]]},"properties":{"id":"99770","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.043241,66.823035],[-161.020299,66.828228],[-161.040943,66.839819],[-161.0559,66.830816],[-161.043241,66.823035]]]},"properties":{"id":"99763","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-133.120079,55.610552],[-132.944181,55.633446],[-132.855587,55.544782],[-133.032304,55.535599],[-133.128372,55.605085],[-132.89029,55.351063],[-132.802339,55.193795],[-132.627446,55.685505],[-132.641288,55.687298],[-133.064271,56.220399],[-133.473937,56.174745],[-133.120079,55.610552]]]},"properties":{"id":"99921","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.666242,56.254033],[-135.002636,57.544828],[-135.688471,57.321445],[-134.666576,56.169603],[-134.666242,56.254033]]]},"properties":{"id":"99835","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-146.207773,64.367294],[-146.347411,64.2801],[-144.12244,63.456472],[-143.598524,63.381221],[-143.40287,63.40983],[-143.469472,64.195755],[-144.471264,64.109366],[-144.629893,63.976333],[-146.207773,64.367294]]]},"properties":{"id":"99737","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.913731,60.823377],[-146.728155,60.911573],[-146.633502,60.913103],[-146.65533,60.858983],[-146.166944,60.723554],[-144.848374,61.131211],[-144.836117,61.21082],[-144.868202,61.494176],[-147.744303,61.427403],[-148.460007,61.426972],[-148.477833,61.079058],[-148.74645,60.779275],[-147.913731,60.823377]]]},"properties":{"id":"99686","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-132.518274,56.586213],[-131.982187,56.07574],[-131.905314,55.974801],[-131.087456,56.407375],[-132.119034,56.891264],[-132.518274,56.586213]]]},"properties":{"id":"99929","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-152.026245,67.359614],[-152.945792,67.547023],[-153.02846,67.108216],[-151.401051,66.9203],[-152.026245,67.359614]]]},"properties":{"id":"99726","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-153.430394,63.677267],[-152.047388,64.000221],[-152.921105,63.992941],[-153.430394,63.677267]]]},"properties":{"id":"99757","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-169.679812,56.576846],[-169.576521,56.537722],[-169.471435,56.600348],[-169.679812,56.576846]]]},"properties":{"id":"99591","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.786256,61.224617],[-149.867962,61.201596],[-149.86149,61.183953],[-149.778351,61.180799],[-149.786256,61.224617]]]},"properties":{"id":"99508","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.912788,61.203013],[-149.867962,61.201596],[-149.786256,61.224617],[-149.778423,61.224765],[-149.880492,61.246127],[-149.924756,61.208334],[-149.912788,61.203013]],[[-149.887356,61.21461],[-149.887353,61.215594],[-149.883283,61.215593],[-149.885312,61.214609],[-149.887356,61.21461]]]},"properties":{"id":"99501","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.016948,60.219978],[-162.053625,60.221007],[-162.053604,60.210795],[-162.012313,60.211567],[-162.016948,60.219978]]]},"properties":{"id":"99578","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.063981,58.717502],[-156.831227,58.709969],[-156.777671,58.732192],[-157.063981,58.717502]]]},"properties":{"id":"99633","state":"02"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-147.731768,64.863496],[-147.741283,64.798793],[-147.655641,64.816594],[-147.564603,64.852042],[-147.526886,64.860753],[-147.731768,64.863496]]],[[[-149.506561,68.001996],[-148.856372,67.357198],[-147.684299,67.756397],[-149.506561,68.001996]]]]},"properties":{"id":"99701","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-133.848458,57.444342],[-134.622539,57.571974],[-134.515105,57.026415],[-133.848458,57.444342]]]},"properties":{"id":"99820","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.410732,58.28573],[-134.406187,58.268842],[-134.371047,58.272381],[-134.410732,58.28573]]]},"properties":{"id":"99824","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.759431,60.6192],[-149.684896,60.425],[-149.027754,60.733788],[-149.038335,60.83639],[-149.759431,60.6192]]]},"properties":{"id":"99631","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.546585,60.128634],[-151.74684,59.934177],[-151.103948,60.040288],[-151.212629,60.177374],[-151.310876,60.199702],[-151.546585,60.128634]]]},"properties":{"id":"99639","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.521031,61.626817],[-148.987248,61.867983],[-149.194474,61.857106],[-149.390824,61.728334],[-149.251555,61.550692],[-149.237576,61.480596],[-148.482842,61.427307],[-148.5155,61.439889],[-148.521031,61.626817]]]},"properties":{"id":"99645","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-167.978284,65.559653],[-164.246706,65.78244],[-164.400726,66.581109],[-167.815086,65.762028],[-167.978284,65.559653]]]},"properties":{"id":"99772","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.514961,70.374006],[-149.916533,70.561402],[-148.949994,70.266474],[-148.379987,70.188889],[-148.514961,70.374006]]]},"properties":{"id":"99734","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.626419,66.325837],[-156.821509,66.933117],[-156.883637,66.90105],[-157.051592,66.379382],[-156.626419,66.325837]]]},"properties":{"id":"99786","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.821509,66.933117],[-156.927581,66.933245],[-156.883637,66.90105],[-156.821509,66.933117]]]},"properties":{"id":"99751","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.050096,57.024477],[-133.86737,56.783331],[-134.277925,56.622132],[-132.518274,56.586213],[-132.119034,56.891264],[-134.050096,57.024477]]]},"properties":{"id":"99833","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-132.627446,55.685505],[-132.482109,55.565657],[-132.417978,55.668164],[-132.641288,55.687298],[-132.627446,55.685505]]]},"properties":{"id":"99919","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-130.02025,55.964362],[-130.024712,56.027668],[-130.072008,56.024295],[-130.02025,55.964362]]]},"properties":{"id":"99923","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.685594,61.865185],[-161.647003,61.53308],[-160.860557,61.715909],[-160.913548,62.053648],[-161.685594,61.865185]]]},"properties":{"id":"99657","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-164.657214,62.641287],[-164.513715,62.724384],[-164.718467,62.710528],[-164.657214,62.641287]]]},"properties":{"id":"99554","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.748541,64.71621],[-156.729884,64.759771],[-156.997924,64.75952],[-156.986863,64.71634],[-156.748541,64.71621]]]},"properties":{"id":"99741","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.408308,63.985999],[-154.63773,63.085718],[-154.566636,63.109494],[-153.430394,63.677267],[-152.921105,63.992941],[-154.408308,63.985999]]]},"properties":{"id":"99627","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.117602,64.86398],[-153.760261,65.034369],[-154.463872,64.597459],[-154.408308,63.985999],[-152.921105,63.992941],[-152.047388,64.000221],[-151.427096,64.008078],[-149.667993,64.357813],[-149.133625,64.622047],[-149.117602,64.86398]]]},"properties":{"id":"99756","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.227577,66.440934],[-147.461859,66.363012],[-147.254619,66.360273],[-147.227577,66.440934]]]},"properties":{"id":"99724","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.70432,55.20454],[-162.7996,55.26193],[-162.852191,55.195419],[-162.70432,55.20454]]]},"properties":{"id":"99571","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.33057,55.049237],[-162.248143,55.028814],[-162.247343,55.090093],[-162.338364,55.090299],[-162.33057,55.049237]]]},"properties":{"id":"99612","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-168.860439,52.943422],[-168.954976,52.911791],[-168.985809,52.858913],[-168.866411,52.904055],[-168.860439,52.943422]]]},"properties":{"id":"99638","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.947349,61.048816],[-149.538413,61.412496],[-149.584325,61.319888],[-149.538767,61.258795],[-149.161113,61.061272],[-148.947349,61.048816]]]},"properties":{"id":"99577","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.880492,61.246127],[-149.778423,61.224765],[-149.708707,61.233272],[-149.689575,61.240078],[-149.748275,61.245099],[-149.880492,61.246127]]]},"properties":{"id":"99506","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.778351,61.180799],[-149.718832,61.180934],[-149.708707,61.233272],[-149.778423,61.224765],[-149.786256,61.224617],[-149.778351,61.180799]]]},"properties":{"id":"99504","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.038335,60.83639],[-149.027754,60.733788],[-148.836082,60.733824],[-148.828264,61.07069],[-148.947349,61.048816],[-149.161113,61.061272],[-149.162376,60.99491],[-149.184091,60.943171],[-149.077554,60.879744],[-149.038335,60.83639]]]},"properties":{"id":"99587","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.408349,60.776677],[-161.329839,60.791366],[-161.365431,60.818519],[-161.42778,60.812023],[-161.408349,60.776677]]]},"properties":{"id":"99621","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.979733,60.702458],[-162.030516,60.690769],[-162.030446,60.66681],[-161.972886,60.679905],[-161.979733,60.702458]]]},"properties":{"id":"99634","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.185129,59.890564],[-163.268921,59.888902],[-163.236297,59.836594],[-163.143746,59.847328],[-163.185129,59.890564]]]},"properties":{"id":"99622","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-164.279854,60.159804],[-164.299939,60.138892],[-164.206957,60.138879],[-164.207614,60.167858],[-164.279854,60.159804]]]},"properties":{"id":"99561","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.745901,58.873949],[-159.626772,59.383452],[-159.629335,59.241481],[-158.745901,58.873949]]]},"properties":{"id":"99628","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.526505,59.32665],[-157.443597,59.363227],[-157.526036,59.390312],[-157.526505,59.32665]]]},"properties":{"id":"99580","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.038335,60.83639],[-149.077554,60.879744],[-149.998328,60.587298],[-149.759431,60.6192],[-149.038335,60.83639]]]},"properties":{"id":"99605","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-153.982446,57.649121],[-153.36874,57.16812],[-153.455034,57.228183],[-153.28483,57.248625],[-153.215445,57.217704],[-152.959169,57.810648],[-153.04009,57.81811],[-153.873872,57.644955],[-153.821168,57.594137],[-153.970493,57.567402],[-153.982446,57.649121]]]},"properties":{"id":"99615","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.159216,58.938939],[-156.100336,59.302124],[-157.244151,59.246208],[-157.159216,58.938939]]]},"properties":{"id":"99625","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.464207,56.279502],[-158.420059,56.333968],[-158.508421,56.353455],[-158.61949,56.290307],[-158.464207,56.279502]]]},"properties":{"id":"99565","state":"02"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-149.527011,63.220827],[-149.723451,62.95734],[-149.657261,62.958017],[-149.527011,63.220827]]],[[[-148.656376,60.665371],[-147.913731,60.823377],[-148.74645,60.779275],[-148.745578,60.733881],[-148.656376,60.665371]]]]},"properties":{"id":"99693","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.900001,65.138385],[-166.418401,64.896794],[-166.234357,65.002575],[-166.476747,65.223578],[-166.900001,65.138385]]]},"properties":{"id":"99778","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.728376,68.136679],[-151.650102,68.150381],[-151.701097,68.176251],[-151.769027,68.158947],[-151.728376,68.136679]]]},"properties":{"id":"99721","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.570171,68.331711],[-163.157277,68.70163],[-163.685158,68.911966],[-166.227433,68.875798],[-166.570171,68.331711]]]},"properties":{"id":"99766","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.024301,70.305776],[-150.946211,70.154258],[-148.949994,70.266474],[-149.916533,70.561402],[-151.024301,70.305776]]]},"properties":{"id":"99510","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.1346,66.88672],[-157.209443,66.903658],[-157.210662,66.858965],[-157.1346,66.88672]]]},"properties":{"id":"99773","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-134.666576,56.169603],[-134.648482,56.244027],[-134.666242,56.254033],[-134.666576,56.169603]]]},"properties":{"id":"99836","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-132.764747,55.960517],[-132.69247,55.88069],[-132.616279,55.909384],[-132.764747,55.960517]]]},"properties":{"id":"99918","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-142.782738,65.313173],[-143.469472,64.195755],[-141.752619,64.20248],[-141.410007,64.167504],[-142.14487,66.121408],[-143.066433,65.912418],[-144.059012,65.632561],[-142.782738,65.313173]]]},"properties":{"id":"99738","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-146.166944,60.723554],[-145.108166,60.431091],[-144.848374,61.131211],[-146.166944,60.723554]]]},"properties":{"id":"99574","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.342635,62.352715],[-165.162288,62.35456],[-164.943667,62.522312],[-165.091992,62.524147],[-165.342635,62.352715]]]},"properties":{"id":"99666","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-131.982187,56.07574],[-132.033082,55.694964],[-131.905314,55.974801],[-131.982187,56.07574]]]},"properties":{"id":"99903","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-152.073599,65.16982],[-151.981295,65.176773],[-151.921985,65.251982],[-152.005602,65.272413],[-152.073599,65.16982]]]},"properties":{"id":"99777","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.63773,63.085718],[-153.840696,62.54048],[-152.787761,62.89515],[-154.566636,63.109494],[-154.63773,63.085718]]]},"properties":{"id":"99691","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.280799,66.045438],[-154.216466,66.027438],[-154.216979,66.082488],[-154.280799,66.045438]]]},"properties":{"id":"99745","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.632116,64.916865],[-157.73382,64.918491],[-157.73599,64.874585],[-157.632116,64.916865]]]},"properties":{"id":"99754","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.413337,54.857118],[-163.396215,54.89509],[-163.436043,54.93557],[-163.436067,54.841246],[-163.384645,54.819046],[-163.384771,54.753432],[-163.333334,54.755614],[-163.376063,54.792212],[-163.375911,54.824806],[-163.413337,54.857118]]]},"properties":{"id":"99583","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.856401,61.118868],[-149.830816,61.075928],[-149.730823,61.016044],[-149.562267,61.059359],[-149.683181,61.103095],[-149.856401,61.118868]]]},"properties":{"id":"99516","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.344887,61.525626],[-160.364772,61.527311],[-160.378357,61.520527],[-160.365633,61.507172],[-160.344887,61.525626]]]},"properties":{"id":"99626","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.472504,60.909265],[-162.524566,60.923932],[-162.595311,60.84582],[-162.489076,60.845412],[-162.489123,60.875391],[-162.472504,60.909265]]]},"properties":{"id":"99609","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.409072,60.894284],[-161.401232,60.916043],[-161.438797,60.909678],[-161.409072,60.894284]]]},"properties":{"id":"99551","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.186061,59.494719],[-157.354455,59.518593],[-157.318082,59.432954],[-157.186061,59.494719]]]},"properties":{"id":"99636","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.731768,64.863496],[-148.653484,64.979434],[-148.644794,64.772808],[-148.645515,64.63499],[-147.741283,64.798793],[-147.731768,64.863496]],[[-147.831131,64.857991],[-147.822904,64.860944],[-147.818533,64.855113],[-147.831131,64.857991]]]},"properties":{"id":"99709","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.831131,64.857991],[-147.818533,64.855113],[-147.822904,64.860944],[-147.831131,64.857991]]]},"properties":{"id":"99775","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-136.486609,59.261108],[-137.946483,58.801795],[-136.516491,58.306855],[-135.492209,58.500294],[-136.486609,59.261108]]]},"properties":{"id":"99826","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.459763,60.944036],[-150.960116,60.55133],[-150.590562,60.475356],[-150.589726,60.525021],[-150.459763,60.944036]]]},"properties":{"id":"99672","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-132.033082,55.694964],[-131.087456,56.407375],[-131.905314,55.974801],[-132.033082,55.694964]]]},"properties":{"id":"99901","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.866196,59.937175],[-154.406246,60.162963],[-154.289275,60.273521],[-154.507388,60.907659],[-155.9611,60.907602],[-154.892572,59.71121],[-154.866196,59.937175]]]},"properties":{"id":"99640","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.420059,56.333968],[-158.464207,56.279502],[-158.364986,56.271893],[-158.345066,56.317901],[-158.420059,56.333968]]]},"properties":{"id":"99564","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.263083,58.199693],[-157.475278,58.244897],[-157.539317,58.155571],[-157.358806,58.154684],[-157.263083,58.199693]]]},"properties":{"id":"99579","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.987248,61.867983],[-148.521031,61.626817],[-148.5155,61.439889],[-148.482842,61.427307],[-148.460007,61.426972],[-147.744303,61.427403],[-147.710953,61.427271],[-147.393092,61.819077],[-147.610534,61.910363],[-148.987248,61.867983]]]},"properties":{"id":"99674","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-170.547435,63.667255],[-170.489661,63.677785],[-170.400959,63.677553],[-170.45872,63.698768],[-170.535407,63.684024],[-170.547435,63.667255]]]},"properties":{"id":"99769","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.133396,64.951404],[-161.199328,64.951352],[-161.199474,64.920559],[-161.135001,64.931503],[-161.133396,64.951404]]]},"properties":{"id":"99753","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.48687,65.331439],[-166.460642,65.350638],[-166.529843,65.351202],[-166.48687,65.331439]]]},"properties":{"id":"99785","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.514961,70.374006],[-148.379987,70.188889],[-145.133752,68.502099],[-141.002694,68.498391],[-148.514961,70.374006]]]},"properties":{"id":"99519","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.028385,70.64442],[-159.857377,70.611219],[-159.825343,70.666015],[-159.931388,70.685572],[-160.028385,70.64442]]]},"properties":{"id":"99782","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.31107,63.373919],[-143.598524,63.381221],[-144.12244,63.456472],[-142.10875,62.902786],[-142.097757,63.156007],[-143.31107,63.373919]]]},"properties":{"id":"99780","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.760995,62.104154],[-163.550279,62.088483],[-163.643177,62.143869],[-163.760995,62.104154]]]},"properties":{"id":"99632","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.995601,61.8023],[-166.068117,61.77655],[-165.927977,61.781856],[-165.995601,61.8023]]]},"properties":{"id":"99662","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-163.510821,63.038936],[-163.570076,63.037619],[-163.586537,63.017005],[-163.528706,63.017146],[-163.510821,63.038936]]]},"properties":{"id":"99620","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.107222,62.92715],[-160.152136,62.870319],[-160.074432,62.870117],[-160.107222,62.92715]]]},"properties":{"id":"99590","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.885853,65.494796],[-148.609286,65.50799],[-148.166516,65.211067],[-147.904433,65.331245],[-147.885853,65.494796]]]},"properties":{"id":"99790","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.419411,65.155643],[-149.362378,65.136538],[-149.366597,65.165132],[-149.419411,65.155643]]]},"properties":{"id":"99758","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.538767,61.258795],[-149.584325,61.319888],[-149.748275,61.245099],[-149.689575,61.240078],[-149.538767,61.258795]]]},"properties":{"id":"99505","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.858728,61.175429],[-149.916803,61.172562],[-149.908258,61.140196],[-149.8564,61.144735],[-149.858728,61.175429]]]},"properties":{"id":"99518","state":"02"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-149.867962,61.201596],[-149.912788,61.203013],[-149.916803,61.172562],[-149.858728,61.175429],[-149.86149,61.183953],[-149.867962,61.201596]]],[[[-158.218162,62.078244],[-158.250952,62.019826],[-158.107099,62.018161],[-158.113233,62.067567],[-158.218162,62.078244]]]]},"properties":{"id":"99503","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.8564,61.144735],[-149.908258,61.140196],[-149.97712,61.124071],[-149.830816,61.075928],[-149.856401,61.118868],[-149.8564,61.144735]]]},"properties":{"id":"99515","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.489123,60.875391],[-162.434267,60.871638],[-162.472504,60.909265],[-162.489123,60.875391]]]},"properties":{"id":"99641","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-166.242202,60.387707],[-166.350698,60.359872],[-166.216998,60.358862],[-166.242202,60.387707]]]},"properties":{"id":"99630","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-165.003825,60.542172],[-164.989236,60.432591],[-164.621175,60.432584],[-164.632071,60.530027],[-165.005013,60.561662],[-165.003825,60.542172]]]},"properties":{"id":"99690","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.592624,59.11831],[-161.542444,59.116589],[-161.541282,59.139383],[-161.583838,59.13938],[-161.592624,59.11831]]]},"properties":{"id":"99589","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.698943,58.973479],[-161.656342,59.053119],[-161.822002,59.016262],[-161.698943,58.973479]]]},"properties":{"id":"99651","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.831227,58.709969],[-157.063981,58.717502],[-156.886244,58.609778],[-156.831227,58.709969]]]},"properties":{"id":"99670","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.681931,63.880371],[-149.289255,64.228762],[-149.231664,64.358736],[-149.667993,64.357813],[-151.427096,64.008078],[-149.367786,63.332659],[-148.804485,63.45664],[-148.681931,63.880371]]]},"properties":{"id":"99743","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-148.681931,63.880371],[-148.804485,63.45664],[-148.633802,63.43463],[-148.229405,63.805998],[-148.681931,63.880371]]]},"properties":{"id":"99755","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.851637,59.978089],[-159.629335,59.241481],[-159.626772,59.383452],[-159.851637,59.978089]]]},"properties":{"id":"99678","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-147.151039,64.632606],[-146.347411,64.2801],[-146.207773,64.367294],[-146.263164,64.55191],[-146.300233,64.566678],[-146.942897,64.601097],[-147.061395,64.599862],[-147.107102,64.637234],[-147.151039,64.632606]]]},"properties":{"id":"99714","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.684896,60.425],[-149.974871,60.47723],[-150.218793,59.900853],[-150.70496,59.680845],[-150.70598,59.429826],[-148.705944,59.939464],[-148.665172,60.455593],[-148.656376,60.665371],[-148.745578,60.733881],[-148.836082,60.733824],[-149.027754,60.733788],[-149.684896,60.425]]]},"properties":{"id":"99664","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-151.74684,59.934177],[-151.780285,59.698871],[-151.097063,59.957539],[-151.103948,60.040288],[-151.74684,59.934177]]]},"properties":{"id":"99556","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-153.982446,57.649121],[-153.970493,57.567402],[-153.821168,57.594137],[-153.873872,57.644955],[-153.982446,57.649121]]]},"properties":{"id":"99624","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.866196,59.937175],[-154.892572,59.71121],[-154.387941,59.788594],[-154.260936,59.984474],[-154.866196,59.937175]]]},"properties":{"id":"99606","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-145.502704,62.278435],[-147.610534,61.910363],[-147.393092,61.819077],[-146.9587,61.910941],[-145.390122,62.038186],[-145.411618,62.26467],[-145.502704,62.278435]]]},"properties":{"id":"99588","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.881148,62.177073],[-150.161733,62.18101],[-149.657261,62.958017],[-149.723451,62.95734],[-152.888316,62.296245],[-150.881148,62.177073]]]},"properties":{"id":"99683","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-150.881148,62.177073],[-152.888316,62.296245],[-153.001262,62.295331],[-153.002181,61.425686],[-150.972764,61.235438],[-150.486195,61.587338],[-150.881148,62.177073]]]},"properties":{"id":"99667","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-162.165948,63.527135],[-162.311329,63.538534],[-162.222587,63.440883],[-162.165948,63.527135]]]},"properties":{"id":"99671","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.152238,64.328724],[-161.197261,64.356873],[-161.197802,64.36579],[-161.220802,64.365399],[-161.152238,64.328724]]]},"properties":{"id":"99771","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.255059,70.597344],[-157.309737,70.646133],[-156.779557,70.258503],[-156.255059,70.597344]]]},"properties":{"id":"99791","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.452625,66.965494],[-160.419866,66.974483],[-160.41317,66.989859],[-160.43716,66.989859],[-160.452625,66.965494]]]},"properties":{"id":"99749","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-161.14863,65.982677],[-161.149593,65.969714],[-161.130112,65.969735],[-161.123055,65.979041],[-161.14863,65.982677]]]},"properties":{"id":"99727","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-144.471264,64.109366],[-144.840545,64.039141],[-144.629893,63.976333],[-144.471264,64.109366]]]},"properties":{"id":"99706","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-146.728155,60.911573],[-146.65533,60.858983],[-146.633502,60.913103],[-146.728155,60.911573]]]},"properties":{"id":"99677","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-143.728086,66.662098],[-143.766972,66.674515],[-143.843444,66.637808],[-143.737894,66.634307],[-143.728086,66.662098]]]},"properties":{"id":"99788","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.820683,64.304058],[-158.713201,64.282937],[-158.724709,64.334295],[-158.820683,64.304058]]]},"properties":{"id":"99748","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.772893,62.148923],[-159.749307,62.189614],[-159.911531,62.224611],[-159.931433,62.148678],[-159.772893,62.148923]]]},"properties":{"id":"99602","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.632116,64.916865],[-156.626419,66.325837],[-157.051592,66.379382],[-158.192595,64.74662],[-158.039455,64.740943],[-157.73599,64.874585],[-157.73382,64.918491],[-157.632116,64.916865]]]},"properties":{"id":"99746","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-149.16237,66.023829],[-149.067661,65.992269],[-149.005109,66.034203],[-149.156705,66.049727],[-149.16237,66.023829]]]},"properties":{"id":"99774","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-144.109494,65.690836],[-144.076456,65.908982],[-144.263039,65.887836],[-144.376012,65.693034],[-144.109494,65.690836]]]},"properties":{"id":"99733","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-160.206578,62.656232],[-160.244914,62.605936],[-160.197437,62.60589],[-160.177441,62.641264],[-160.206578,62.656232]]]},"properties":{"id":"99558","state":"02"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.284549,35.468979],[-109.380348,35.339091],[-109.122969,35.229586],[-109.125101,35.45361],[-109.284549,35.468979]]]},"properties":{"id":"86506","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.84582,35.924421],[-109.937138,36.346945],[-110.107966,35.938678],[-109.8548,35.922379],[-109.84582,35.924421]]]},"properties":{"id":"86520","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-109.460454,36.257648],[-109.207546,36.273996],[-109.406825,36.353653],[-109.460454,36.257648]]],[[[-109.222228,36.254855],[-109.136838,36.130621],[-109.045994,36.199914],[-109.045688,36.465628],[-109.104062,36.464578],[-109.222228,36.254855]]]]},"properties":{"id":"86556","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.405593,36.611798],[-109.733362,36.924633],[-109.793742,36.732091],[-109.72434,36.598884],[-109.587591,36.57542],[-109.405593,36.611798]]]},"properties":{"id":"86545","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.978826,31.927176],[-110.271393,31.779805],[-110.179511,31.642701],[-110.053332,31.529075],[-109.851929,31.557056],[-109.898952,31.62307],[-109.937848,31.872995],[-109.947822,31.870728],[-109.978826,31.927176]]]},"properties":{"id":"85638","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.163507,32.161535],[-110.451221,32.513807],[-110.662222,32.513905],[-110.684082,32.513793],[-110.552484,32.313237],[-110.555051,31.948026],[-110.451996,31.744567],[-110.35029,31.781562],[-110.147242,31.981299],[-110.162024,31.999054],[-110.2898,31.984171],[-110.262789,32.021071],[-110.163507,32.161535]]]},"properties":{"id":"85602","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.162024,31.999054],[-110.262789,32.021071],[-110.2898,31.984171],[-110.162024,31.999054]]]},"properties":{"id":"85627","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.61714,35.713938],[-112.735398,35.089333],[-112.365616,34.991749],[-112.334682,34.997241],[-112.456523,35.389623],[-112.61714,35.713938]]]},"properties":{"id":"86320","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.21279,35.290205],[-111.242979,35.264192],[-111.205707,35.156209],[-111.21279,35.290205]]]},"properties":{"id":"86003","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.494778,33.999817],[-111.405369,33.881935],[-111.171596,33.993028],[-110.84757,34.265644],[-111.071962,34.378459],[-111.387579,34.387514],[-111.665099,34.286469],[-111.494778,33.999817]]]},"properties":{"id":"85541","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.804632,32.901027],[-109.801526,32.856612],[-109.765545,32.878209],[-109.804632,32.901027]]]},"properties":{"id":"85531","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.195779,33.848454],[-112.20354,33.7519],[-112.124452,33.746866],[-112.048005,33.799043],[-112.021568,33.799128],[-112.03059,33.871651],[-112.195779,33.848454]]]},"properties":{"id":"85086","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.223178,33.681733],[-112.289782,33.681814],[-112.25936,33.638169],[-112.254846,33.624254],[-112.202486,33.625059],[-112.202724,33.635837],[-112.223176,33.681688],[-112.223178,33.681733]]]},"properties":{"id":"85382","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.13447,33.653557],[-112.114346,33.654699],[-112.074174,33.654735],[-112.07222,33.654754],[-112.065723,33.701988],[-112.114563,33.699875],[-112.134877,33.698349],[-112.134764,33.683837],[-112.13447,33.653557]]]},"properties":{"id":"85027","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.972028,33.305259],[-112.050239,33.342734],[-112.100457,33.333046],[-112.148004,33.304646],[-112.085142,33.291034],[-111.972028,33.305259]]]},"properties":{"id":"85048","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.342063,33.623562],[-112.350515,33.580157],[-112.350443,33.571255],[-112.311497,33.565435],[-112.304798,33.606949],[-112.308252,33.627763],[-112.342063,33.623562]]]},"properties":{"id":"85335","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.86047,33.411288],[-111.859099,33.35705],[-111.841829,33.361313],[-111.824848,33.378853],[-111.82295,33.41422],[-111.86047,33.411288]]]},"properties":{"id":"85210","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.151586,33.555036],[-112.151445,33.536183],[-112.137477,33.5385],[-112.112216,33.538618],[-112.116834,33.581974],[-112.151518,33.581922],[-112.151586,33.555036]]]},"properties":{"id":"85051","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.073774,33.480249],[-112.073751,33.538314],[-112.091126,33.537902],[-112.091378,33.480483],[-112.082987,33.479433],[-112.07502,33.480261],[-112.073774,33.480249]]]},"properties":{"id":"85013","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.75339,33.451872],[-111.753696,33.415521],[-111.751497,33.415528],[-111.684516,33.415675],[-111.68413,33.451669],[-111.75339,33.451872]]]},"properties":{"id":"85205","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.301451,33.638454],[-112.308252,33.627763],[-112.304798,33.606949],[-112.300129,33.565506],[-112.26334,33.594967],[-112.254846,33.624254],[-112.25936,33.638169],[-112.301451,33.638454]]]},"properties":{"id":"85351","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.384614,33.551508],[-112.35815,33.529797],[-112.358913,33.551305],[-112.384614,33.551508]]]},"properties":{"id":"85309","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.516781,34.096911],[-112.755117,33.868324],[-112.447075,33.810984],[-112.278162,33.827864],[-112.211925,33.968618],[-112.207842,33.969859],[-112.295734,34.128479],[-112.413852,34.157428],[-112.516781,34.096911]]]},"properties":{"id":"85342","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.678834,35.50172],[-114.280925,35.48695],[-114.168657,35.454211],[-114.11961,35.707879],[-114.683048,35.67642],[-114.678834,35.50172]]]},"properties":{"id":"86441","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.483129,34.986014],[-114.416281,34.807896],[-114.244313,34.805796],[-114.483129,34.986014]]]},"properties":{"id":"86433","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.258158,34.734598],[-114.188038,34.549878],[-113.982917,34.256251],[-113.791006,34.234764],[-113.601241,34.23248],[-113.419638,34.298725],[-113.333092,34.47846],[-113.813264,35.02159],[-114.146656,34.944827],[-114.192429,34.772417],[-114.258158,34.734598]]]},"properties":{"id":"86438","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.953405,37.000251],[-113.079881,36.999985],[-113.078182,36.937217],[-112.953405,37.000251]]]},"properties":{"id":"86021","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.040942,34.198204],[-110.004531,34.089537],[-109.948155,34.085327],[-109.84894,34.223007],[-110.040942,34.198204]]]},"properties":{"id":"85929","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.657712,35.779473],[-110.4735,35.740584],[-110.487521,35.914909],[-110.657712,35.779473]]]},"properties":{"id":"86043","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.968253,32.17782],[-110.991509,32.172524],[-110.992608,32.133784],[-110.986669,32.133992],[-110.896144,32.163057],[-110.908528,32.185175],[-110.961345,32.177884],[-110.968253,32.17782]]]},"properties":{"id":"85714","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.965828,32.222991],[-110.961085,32.28902],[-111.012033,32.302944],[-111.045689,32.315552],[-111.02952,32.294221],[-110.982171,32.229365],[-110.965828,32.222991]]]},"properties":{"id":"85705","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.055952,31.870093],[-111.034985,31.768745],[-110.943135,31.726757],[-110.807079,31.731746],[-110.806928,31.818323],[-111.055952,31.870093]]]},"properties":{"id":"85614","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.055952,31.870093],[-110.806928,31.818323],[-110.757064,31.818039],[-110.858821,32.002618],[-110.961326,32.030321],[-111.057025,32.000747],[-111.216336,32.000362],[-111.225814,31.783921],[-111.164857,31.808447],[-111.055952,31.870093]]]},"properties":{"id":"85629","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.594958,31.769453],[-111.623115,31.507912],[-111.366573,31.426075],[-111.366613,31.521291],[-111.330359,31.685608],[-111.335147,31.769634],[-111.594958,31.769453]]]},"properties":{"id":"85633","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.164857,31.808447],[-111.034985,31.768745],[-111.055952,31.870093],[-111.164857,31.808447]]]},"properties":{"id":"85622","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.141004,32.408821],[-111.302165,32.548264],[-111.361559,32.502826],[-111.474272,32.307731],[-111.278656,32.219348],[-111.141004,32.408821]],[[-111.156896,32.413795],[-111.154526,32.417259],[-111.150161,32.413879],[-111.156896,32.413795]]]},"properties":{"id":"85653","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.857928,32.192134],[-110.789712,32.191795],[-110.78974,32.235435],[-110.857919,32.235808],[-110.857925,32.234283],[-110.857928,32.192134]]]},"properties":{"id":"85710","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.992608,32.133784],[-110.961326,32.030321],[-110.858821,32.002618],[-110.842803,32.04508],[-110.833051,32.130678],[-110.865447,32.159863],[-110.890844,32.162977],[-110.986669,32.133992],[-110.992608,32.133784]]]},"properties":{"id":"85756","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.476986,33.340427],[-111.250969,33.269653],[-111.26895,33.280521],[-111.26976,33.465538],[-111.442165,33.465898],[-111.476986,33.340427]]]},"properties":{"id":"85118","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.516633,33.118252],[-111.43034,33.176473],[-111.48775,33.19118],[-111.582683,33.218888],[-111.57726,33.118167],[-111.516633,33.118252]]]},"properties":{"id":"85143","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.670754,32.639406],[-110.646343,32.750494],[-110.892844,32.864157],[-111.075481,32.700812],[-110.785444,32.513539],[-110.684082,32.513793],[-110.662222,32.513905],[-110.670754,32.639406]]]},"properties":{"id":"85623","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.448621,32.730027],[-110.670754,32.639406],[-110.662222,32.513905],[-110.451221,32.513807],[-110.448621,32.730027]]]},"properties":{"id":"85631","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.792289,32.996516],[-111.685998,32.833284],[-111.686007,32.833554],[-111.747985,32.99662],[-111.792289,32.996516]]]},"properties":{"id":"85122","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.032331,32.830886],[-111.91231,32.973447],[-112.031913,32.850171],[-112.032331,32.830886]]]},"properties":{"id":"85172","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.818899,34.712911],[-111.685588,34.803403],[-111.725973,34.804181],[-111.873055,34.798743],[-111.818899,34.712911]]]},"properties":{"id":"86351","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.965378,34.746654],[-111.939283,34.662188],[-111.853535,34.690632],[-111.818899,34.712911],[-111.873055,34.798743],[-111.965378,34.746654]]]},"properties":{"id":"86325","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.939283,34.662188],[-111.96193,34.632706],[-111.992433,34.514346],[-111.703748,34.352688],[-111.656443,34.387306],[-111.555124,34.472196],[-111.555046,34.500965],[-111.553274,34.610104],[-111.853535,34.690632],[-111.939283,34.662188]]]},"properties":{"id":"86322","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.133455,34.455891],[-112.357498,34.467775],[-112.386884,34.366034],[-112.375222,34.245024],[-112.199398,34.239738],[-111.820392,34.155734],[-111.703748,34.352688],[-111.992433,34.514346],[-112.133455,34.455891]]]},"properties":{"id":"86333","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.737936,32.727932],[-113.978989,32.69419],[-113.95298,32.664538],[-113.737936,32.727932]]]},"properties":{"id":"85352","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.812214,32.523213],[-114.786856,32.534281],[-114.792197,32.566785],[-114.812214,32.523213]]]},"properties":{"id":"85336","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.183558,34.027315],[-109.372791,33.980395],[-109.398523,33.881372],[-109.393656,33.872727],[-109.046558,33.908025],[-109.183558,34.027315]]]},"properties":{"id":"85932","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.414869,34.082893],[-109.326163,34.121183],[-109.265702,34.11849],[-109.254363,34.071605],[-109.357355,34.05843],[-109.372791,33.980395],[-109.183558,34.027315],[-109.267255,34.299691],[-109.494968,34.388009],[-109.533936,34.279101],[-109.554582,34.260498],[-109.544217,34.125177],[-109.414869,34.082893]]]},"properties":{"id":"85938","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.848276,34.448967],[-109.837356,34.285908],[-109.533936,34.279101],[-109.494968,34.388009],[-109.544971,34.835869],[-109.851479,34.795914],[-109.850724,34.696423],[-109.848248,34.460321],[-109.848276,34.448967]]]},"properties":{"id":"85924","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.053165,31.515293],[-110.003089,31.333166],[-109.957582,31.333518],[-109.949774,31.340085],[-109.918396,31.333776],[-109.792725,31.333987],[-109.698254,31.437678],[-109.851929,31.557056],[-110.053332,31.529075],[-110.053165,31.515293]]]},"properties":{"id":"85603","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.918396,31.333776],[-109.949774,31.340085],[-109.957582,31.333518],[-109.918396,31.333776]]]},"properties":{"id":"85620","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.141319,31.539747],[-110.053165,31.515293],[-110.053332,31.529075],[-110.179511,31.642701],[-110.189673,31.625093],[-110.311684,31.565499],[-110.306535,31.553885],[-110.289855,31.527444],[-110.141319,31.539747]]]},"properties":{"id":"85635","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.333817,31.614008],[-110.447826,31.585127],[-110.417475,31.497495],[-110.306535,31.553885],[-110.311684,31.565499],[-110.333817,31.614008]]]},"properties":{"id":"85613","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.333817,31.614008],[-110.189673,31.625093],[-110.179511,31.642701],[-110.271393,31.779805],[-110.35029,31.781562],[-110.451996,31.744567],[-110.447826,31.585127],[-110.333817,31.614008]]]},"properties":{"id":"85616","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.313256,36.416899],[-111.141917,35.678928],[-111.227634,35.495176],[-111.000701,35.647968],[-110.860933,35.915763],[-110.750698,36.165944],[-110.750698,36.298426],[-110.750698,36.303667],[-111.239533,36.434211],[-111.313256,36.416899]]]},"properties":{"id":"86045","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.456523,35.389623],[-112.334682,34.997241],[-112.139366,34.97403],[-112.008176,34.975791],[-111.912106,34.976277],[-111.911827,35.105309],[-112.048418,35.402084],[-111.904178,35.606472],[-111.74665,35.745425],[-111.726347,35.865199],[-111.726199,35.897508],[-112.456523,35.389623]]]},"properties":{"id":"86046","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.328779,36.064505],[-111.760428,36.007317],[-111.762829,36.198516],[-111.799638,36.193768],[-112.315222,36.189215],[-112.328779,36.064505]]]},"properties":{"id":"86023","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.12473,36.397614],[-112.629727,36.392322],[-112.315222,36.189215],[-111.799638,36.193768],[-112.162774,36.732648],[-112.185234,36.613158],[-112.12473,36.397614]]]},"properties":{"id":"86052","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.661464,35.175504],[-111.648225,35.173372],[-111.652879,35.181987],[-111.661464,35.175504]]]},"properties":{"id":"86011","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.028898,33.712371],[-110.758273,33.751604],[-110.783111,34.299861],[-110.84757,34.265644],[-111.171596,33.993028],[-111.113392,33.734826],[-111.028898,33.712371]]]},"properties":{"id":"85554","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.448414,32.899963],[-109.912583,32.70389],[-109.805022,32.85311],[-109.801526,32.856612],[-109.804632,32.901027],[-109.778989,32.903356],[-109.939987,33.253447],[-109.937318,33.030684],[-109.897611,32.990631],[-109.916265,32.961747],[-110.056294,32.970878],[-110.449408,32.988177],[-110.448414,32.899963]]]},"properties":{"id":"85543","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.765545,32.878209],[-109.801526,32.856612],[-109.805022,32.85311],[-109.765545,32.878209]]]},"properties":{"id":"85552","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.450696,33.551166],[-114.267822,33.416232],[-113.99348,33.464655],[-113.767267,33.570392],[-114.09845,33.781824],[-114.216863,33.994751],[-114.256694,34.011221],[-114.413906,33.629732],[-114.450696,33.551166]]]},"properties":{"id":"85346","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.033111,33.204102],[-113.023224,32.89707],[-112.203317,32.836211],[-112.203736,32.941736],[-112.547494,33.13096],[-112.704063,33.15849],[-113.033111,33.204102]]]},"properties":{"id":"85337","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.744419,33.53799],[-112.735807,33.364454],[-112.729254,33.362755],[-112.668149,33.363706],[-112.659566,33.332306],[-112.708742,33.312358],[-112.704063,33.15849],[-112.547494,33.13096],[-112.443919,33.275827],[-112.461481,33.461789],[-112.744419,33.53799]]]},"properties":{"id":"85326","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.893247,33.378466],[-111.978379,33.381813],[-111.978438,33.376618],[-111.971739,33.349086],[-111.894356,33.349347],[-111.894544,33.356735],[-111.893247,33.378466]]]},"properties":{"id":"85283","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.939301,33.463984],[-111.890994,33.444781],[-111.891836,33.47312],[-111.957217,33.480061],[-111.960961,33.478209],[-111.939301,33.463984]]]},"properties":{"id":"85257","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.207286,33.559969],[-112.211618,33.560365],[-112.211135,33.559874],[-112.207286,33.559969]]],[[[-112.151445,33.536183],[-112.151586,33.555036],[-112.202949,33.552782],[-112.203165,33.509355],[-112.18636,33.509569],[-112.151537,33.50945],[-112.151445,33.536183]]]]},"properties":{"id":"85301","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.787364,33.64014],[-111.770065,33.567948],[-111.708372,33.567492],[-111.69855,33.640491],[-111.787364,33.64014]]]},"properties":{"id":"85268","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.114346,33.654699],[-112.116543,33.610849],[-112.074506,33.607589],[-112.074174,33.654735],[-112.114346,33.654699]]]},"properties":{"id":"85023","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.151791,33.639321],[-112.202724,33.635837],[-112.202486,33.625059],[-112.202622,33.610607],[-112.151174,33.610727],[-112.151791,33.639321]]]},"properties":{"id":"85306","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.687671,33.365018],[-111.584005,33.361793],[-111.584293,33.378649],[-111.680451,33.393334],[-111.691205,33.379354],[-111.687671,33.365018]]]},"properties":{"id":"85209","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.461481,33.461789],[-112.443919,33.275827],[-112.336557,33.362773],[-112.341289,33.457999],[-112.461481,33.461789]]]},"properties":{"id":"85338","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.454999,33.638461],[-112.409617,33.580732],[-112.409394,33.624238],[-112.454999,33.638461]]]},"properties":{"id":"85388","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.047635,33.48021],[-112.065073,33.480207],[-112.065143,33.451358],[-112.030157,33.451352],[-112.030197,33.477218],[-112.047635,33.48021]]]},"properties":{"id":"85006","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.112216,33.538618],[-112.137477,33.5385],[-112.135141,33.480465],[-112.113007,33.480576],[-112.112216,33.538618]]]},"properties":{"id":"85017","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.972389,33.326673],[-111.971739,33.349086],[-111.978438,33.376618],[-112.050239,33.342734],[-111.972028,33.305259],[-111.972389,33.326673]]]},"properties":{"id":"85044","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.085142,33.291034],[-112.148004,33.304646],[-112.133789,33.29026],[-112.085142,33.291034]]]},"properties":{"id":"85045","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.735329,33.587964],[-113.334181,33.281253],[-112.938756,33.286434],[-112.735807,33.364454],[-112.744419,33.53799],[-112.735329,33.587964]]]},"properties":{"id":"85354","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.565387,32.91785],[-113.616936,32.766958],[-113.023224,32.89707],[-113.033111,33.204102],[-112.938756,33.286434],[-113.334181,33.281253],[-113.565387,32.91785]]]},"properties":{"id":"85333","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.840913,33.159475],[-111.791669,33.169015],[-111.832803,33.204118],[-111.836853,33.247681],[-111.893276,33.261387],[-111.909163,33.204777],[-111.840913,33.159475]]]},"properties":{"id":"85248","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.747816,33.210146],[-111.686052,33.266399],[-111.772666,33.263061],[-111.772635,33.24859],[-111.747816,33.210146]]]},"properties":{"id":"85298","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.978017,33.421902],[-112.064894,33.42129],[-112.073303,33.421944],[-112.08225,33.419137],[-112.082168,33.417476],[-112.073307,33.392248],[-111.978336,33.391115],[-111.978126,33.415346],[-111.978017,33.421902]]]},"properties":{"id":"85040","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.891382,33.85728],[-111.960816,33.799176],[-111.891177,33.813834],[-111.891382,33.85728]]]},"properties":{"id":"85377","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.323827,35.790753],[-114.11961,35.707879],[-113.942169,35.624312],[-113.86278,35.730859],[-113.903532,36.092838],[-114.043944,36.19335],[-114.16311,36.026618],[-114.323827,35.790753]]]},"properties":{"id":"86444","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.29648,34.494155],[-114.382229,34.531828],[-114.326717,34.437856],[-114.29648,34.494155]]]},"properties":{"id":"86403","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.416281,34.807896],[-114.565345,34.786229],[-114.43887,34.622316],[-114.258158,34.734598],[-114.192429,34.772417],[-114.244313,34.805796],[-114.416281,34.807896]]]},"properties":{"id":"86436","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.515909,35.400775],[-113.71119,35.412292],[-113.682007,35.328559],[-113.515909,35.400775]]]},"properties":{"id":"86437","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.624419,36.128142],[-110.718228,35.833952],[-110.750693,35.76851],[-110.750693,35.653007],[-110.657712,35.779473],[-110.487521,35.914909],[-110.478939,35.925243],[-110.358169,36.04317],[-110.637416,36.29847],[-110.624419,36.128142]]]},"properties":{"id":"86039","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.353004,34.000047],[-110.070832,34.000046],[-110.004531,34.089537],[-110.040942,34.198204],[-110.202647,34.250975],[-110.316483,34.238074],[-110.424875,34.30586],[-110.627047,34.288221],[-110.669657,34.276702],[-110.353004,34.000047]]]},"properties":{"id":"85911","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.964926,32.510823],[-111.029439,32.45159],[-111.012879,32.423839],[-110.932242,32.453505],[-110.964926,32.510823]]]},"properties":{"id":"85755","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.875057,32.337435],[-110.875051,32.269576],[-110.825542,32.261475],[-110.787637,32.336315],[-110.875057,32.337435]]]},"properties":{"id":"85750","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.90968,32.236189],[-110.914279,32.271215],[-110.93725,32.28172],[-110.935154,32.207089],[-110.910864,32.207009],[-110.90968,32.236189]]]},"properties":{"id":"85716","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.914279,32.271215],[-110.90968,32.236189],[-110.857925,32.234283],[-110.857919,32.235808],[-110.875051,32.269576],[-110.914279,32.271215]]]},"properties":{"id":"85712","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.515272,32.851281],[-111.686007,32.833554],[-111.685998,32.833284],[-111.654155,32.762981],[-111.688804,32.622794],[-111.744045,32.506695],[-111.744019,32.506695],[-111.415077,32.541466],[-111.335953,32.588554],[-111.314289,32.613078],[-111.431002,32.67609],[-111.516224,32.690786],[-111.515801,32.734118],[-111.425993,32.7339],[-111.426417,32.850293],[-111.436584,32.850419],[-111.515272,32.851281]]]},"properties":{"id":"85131","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.795747,33.005271],[-110.944707,33.247328],[-110.973029,33.336744],[-111.055621,33.311235],[-111.062322,33.206769],[-111.182484,33.094647],[-110.962696,33.016942],[-110.787374,32.984583],[-110.793981,33.002302],[-110.795747,33.005271]]]},"properties":{"id":"85137","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.057883,33.313298],[-111.26895,33.280521],[-111.250969,33.269653],[-111.062322,33.206769],[-111.055621,33.311235],[-111.057883,33.313298]]]},"properties":{"id":"85173","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.850189,31.553329],[-110.760998,31.333634],[-110.460172,31.332827],[-110.46017,31.353781],[-110.642458,31.60417],[-110.807079,31.731746],[-110.943135,31.726757],[-110.96273,31.686949],[-110.850189,31.553329]]]},"properties":{"id":"85624","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.201687,34.655856],[-112.354894,34.715623],[-112.398411,34.68174],[-112.369267,34.571679],[-112.326142,34.553446],[-112.201687,34.655856]]]},"properties":{"id":"86314","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.476583,34.542197],[-112.619464,34.542641],[-112.557343,34.483022],[-112.386884,34.366034],[-112.357498,34.467775],[-112.2835,34.504231],[-112.326142,34.553446],[-112.369267,34.571679],[-112.476583,34.542197]]]},"properties":{"id":"86303","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.2835,34.504231],[-112.133455,34.455891],[-111.992433,34.514346],[-111.96193,34.632706],[-112.118275,34.657636],[-112.168361,34.692901],[-112.201687,34.655856],[-112.326142,34.553446],[-112.2835,34.504231]]]},"properties":{"id":"86327","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.935744,34.610554],[-112.8513,34.498599],[-112.557343,34.483022],[-112.619464,34.542641],[-112.935744,34.610554]]]},"properties":{"id":"86338","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.173175,34.785207],[-112.0754,34.740412],[-111.970261,34.753458],[-112.008176,34.975791],[-112.139366,34.97403],[-112.295956,34.802473],[-112.173175,34.785207]]]},"properties":{"id":"86324","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.405593,36.611798],[-109.587591,36.57542],[-109.461931,36.406266],[-109.343546,36.515013],[-109.405593,36.611798]]]},"properties":{"id":"86547","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.000677,36.890514],[-109.872966,36.520831],[-109.793742,36.732091],[-109.733362,36.924633],[-109.794204,36.998235],[-109.834989,36.998306],[-110.000677,36.890514]]]},"properties":{"id":"86535","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.094535,35.704523],[-109.046271,35.616378],[-109.0462,35.720576],[-109.094535,35.704523]]]},"properties":{"id":"86515","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.302053,36.000002],[-110.107966,35.938678],[-109.937138,36.346945],[-109.988712,36.36421],[-110.456599,36.414008],[-110.750698,36.303667],[-110.750698,36.298426],[-110.637416,36.29847],[-110.358169,36.04317],[-110.302053,36.000002]]]},"properties":{"id":"86510","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.231522,33.726987],[-110.000653,33.783811],[-109.950346,33.760617],[-109.495726,33.889585],[-109.544266,34.030131],[-109.859072,34.040503],[-109.910479,34.028891],[-110.070832,34.000046],[-110.353004,34.000047],[-110.538383,33.823324],[-110.525942,33.814424],[-110.231522,33.726987]]]},"properties":{"id":"85941","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.526318,34.951601],[-109.843696,35.170841],[-109.851479,34.795914],[-109.544971,34.835869],[-109.526475,34.908263],[-109.526318,34.951601]]]},"properties":{"id":"86028","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.556047,31.359344],[-109.562335,31.364171],[-109.579455,31.352264],[-109.570079,31.351507],[-109.556047,31.359344]]]},"properties":{"id":"85626","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.426115,31.806429],[-109.937848,31.872995],[-109.898952,31.62307],[-109.54452,31.565225],[-109.426115,31.806429]]]},"properties":{"id":"85610","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.185234,36.613158],[-112.953405,37.000251],[-113.078182,36.937217],[-113.057208,36.851871],[-112.12473,36.397614],[-112.185234,36.613158]]]},"properties":{"id":"86022","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.702061,37.0034],[-110.526111,36.594081],[-110.467579,36.998001],[-110.702061,37.0034]]]},"properties":{"id":"86054","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.750679,34.322469],[-110.750685,34.63665],[-110.829658,34.638056],[-111.071962,34.378459],[-110.84757,34.265644],[-110.783111,34.299861],[-110.750679,34.294513],[-110.750679,34.322469]]]},"properties":{"id":"85931","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.332878,35.141646],[-112.732578,35.010557],[-112.735398,35.089333],[-112.61714,35.713938],[-113.07271,35.686865],[-113.191181,35.606132],[-113.25408,35.526391],[-113.333793,35.466246],[-113.333282,35.325763],[-113.332878,35.141646]]]},"properties":{"id":"86337","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.844186,33.41951],[-110.973029,33.336744],[-110.944707,33.247328],[-110.706212,33.103211],[-110.740057,33.23912],[-110.525942,33.814424],[-110.538383,33.823324],[-110.758273,33.751604],[-111.028898,33.712371],[-110.844186,33.41951]]]},"properties":{"id":"85501","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.231522,33.726987],[-110.525942,33.814424],[-110.740057,33.23912],[-110.520846,33.315486],[-110.443544,33.367552],[-110.231522,33.726987]]]},"properties":{"id":"85550","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.09845,33.781824],[-113.767267,33.570392],[-113.333996,33.638638],[-113.333962,33.72642],[-113.765788,33.811852],[-114.09845,33.781824]]]},"properties":{"id":"85348","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.211925,33.968618],[-112.195779,33.848454],[-112.03059,33.871651],[-111.995696,33.900934],[-112.145635,34.046165],[-112.207842,33.969859],[-112.211925,33.968618]]]},"properties":{"id":"85087","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.223176,33.681688],[-112.134764,33.683837],[-112.134877,33.698349],[-112.203134,33.712285],[-112.223178,33.681733],[-112.223176,33.681688]]]},"properties":{"id":"85310","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.238146,33.462964],[-112.236982,33.400146],[-112.168812,33.407742],[-112.151863,33.461789],[-112.238146,33.462964]]]},"properties":{"id":"85043","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.461481,33.461789],[-112.341289,33.457999],[-112.341042,33.457997],[-112.357983,33.485768],[-112.461482,33.46209],[-112.461481,33.461789]]]},"properties":{"id":"85395","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.86047,33.411288],[-111.88836,33.407522],[-111.893247,33.378466],[-111.894544,33.356735],[-111.859099,33.35705],[-111.86047,33.411288]]]},"properties":{"id":"85202","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.237792,33.552175],[-112.237699,33.508802],[-112.203165,33.509355],[-112.202949,33.552782],[-112.211135,33.559874],[-112.211618,33.560365],[-112.237792,33.552175]]]},"properties":{"id":"85303","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.151791,33.639321],[-112.151174,33.610727],[-112.116543,33.610849],[-112.114346,33.654699],[-112.13447,33.653557],[-112.151791,33.639321]]]},"properties":{"id":"85053","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.030157,33.451352],[-111.961125,33.445312],[-111.939301,33.463984],[-111.960961,33.478209],[-112.015074,33.480471],[-112.030197,33.477218],[-112.030157,33.451352]]]},"properties":{"id":"85008","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.312825,33.493133],[-112.272537,33.464733],[-112.237989,33.465335],[-112.237824,33.47981],[-112.237699,33.508802],[-112.275972,33.510686],[-112.306577,33.507539],[-112.312825,33.493133]]]},"properties":{"id":"85037","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.787477,33.484278],[-111.784189,33.415404],[-111.753696,33.415521],[-111.75339,33.451872],[-111.76079,33.484921],[-111.787477,33.484278]]]},"properties":{"id":"85213","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.925388,33.638742],[-111.968725,33.698729],[-111.958855,33.650651],[-111.925388,33.638742]]]},"properties":{"id":"85054","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.333627,34.122249],[-113.333962,33.72642],[-113.333996,33.638638],[-113.127359,33.638559],[-113.048225,33.999819],[-113.333627,34.122249]]]},"properties":{"id":"85320","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.735807,33.364454],[-112.938756,33.286434],[-113.033111,33.204102],[-112.704063,33.15849],[-112.708742,33.312358],[-112.729254,33.362755],[-112.735807,33.364454]]]},"properties":{"id":"85322","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.836853,33.247681],[-111.832803,33.204118],[-111.780846,33.204689],[-111.750504,33.204751],[-111.747816,33.210146],[-111.772635,33.24859],[-111.836853,33.247681]]]},"properties":{"id":"85249","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.311497,33.565435],[-112.350443,33.571255],[-112.358913,33.551305],[-112.35815,33.529797],[-112.306577,33.507539],[-112.275972,33.510686],[-112.274461,33.551322],[-112.307179,33.565372],[-112.311497,33.565435]]]},"properties":{"id":"85307","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.635177,34.963648],[-114.565345,34.786229],[-114.416281,34.807896],[-114.483129,34.986014],[-114.635177,34.963648]]]},"properties":{"id":"86440","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.993609,34.438174],[-110.193837,34.460162],[-110.105476,34.382129],[-110.006135,34.392572],[-109.993609,34.438174]]]},"properties":{"id":"85939","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.84894,34.223007],[-109.948155,34.085327],[-109.910479,34.028891],[-109.859072,34.040503],[-109.849387,34.125945],[-109.849243,34.187544],[-109.84894,34.223007]]]},"properties":{"id":"85935","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.254707,35.189571],[-110.323436,34.893327],[-110.486507,34.665652],[-110.378469,34.577422],[-110.264858,34.577947],[-110.055991,34.78571],[-109.905653,34.718346],[-109.850724,34.696423],[-109.851479,34.795914],[-109.843696,35.170841],[-110.19169,35.25794],[-110.254707,35.189571]],[[-110.090323,34.915348],[-110.039507,35.04071],[-109.968633,35.040653],[-109.993592,34.953333],[-110.090323,34.915348]]]},"properties":{"id":"86025","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.991509,32.172524],[-111.071286,32.171347],[-111.217214,32.103731],[-111.216959,32.075179],[-111.057025,32.000747],[-110.961326,32.030321],[-110.992608,32.133784],[-110.991509,32.172524]]]},"properties":{"id":"85746","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.96273,31.686949],[-110.943135,31.726757],[-111.034985,31.768745],[-111.164857,31.808447],[-111.225814,31.783921],[-111.335147,31.769634],[-111.330359,31.685608],[-111.164096,31.565326],[-110.96273,31.686949]]]},"properties":{"id":"85645","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.961147,32.380901],[-110.961085,32.28902],[-110.93725,32.28172],[-110.914279,32.271215],[-110.875051,32.269576],[-110.875057,32.337435],[-110.893127,32.413417],[-110.961147,32.380901]]]},"properties":{"id":"85718","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.875051,32.269576],[-110.857919,32.235808],[-110.78974,32.235435],[-110.781295,32.239232],[-110.825542,32.261475],[-110.875051,32.269576]]]},"properties":{"id":"85715","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.632055,31.788934],[-110.555051,31.948026],[-110.552484,32.313237],[-110.652319,32.261439],[-110.669949,32.243943],[-110.669837,32.235075],[-110.737409,32.162955],[-110.842803,32.04508],[-110.858821,32.002618],[-110.757064,31.818039],[-110.632055,31.788934]]]},"properties":{"id":"85641","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.089727,32.358921],[-111.012789,32.369168],[-111.005065,32.378476],[-111.012879,32.423839],[-111.029439,32.45159],[-111.140258,32.408872],[-111.089727,32.358921]]]},"properties":{"id":"85742","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.685998,32.833284],[-111.792289,32.996516],[-111.902879,33.012979],[-111.91231,32.973447],[-112.032331,32.830886],[-112.032359,32.830322],[-111.749383,32.719016],[-111.654155,32.762981],[-111.685998,32.833284]]]},"properties":{"id":"85193","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.747985,32.99662],[-111.686007,32.833554],[-111.515272,32.851281],[-111.670546,32.967385],[-111.747985,32.99662]]]},"properties":{"id":"85194","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.431002,32.67609],[-111.425993,32.7339],[-111.515801,32.734118],[-111.516224,32.690786],[-111.431002,32.67609]]]},"properties":{"id":"85141","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.386884,34.366034],[-112.557343,34.483022],[-112.8513,34.498599],[-112.890396,34.371195],[-112.707149,34.205982],[-112.621009,34.13566],[-112.516781,34.096911],[-112.413852,34.157428],[-112.375222,34.245024],[-112.386884,34.366034]]]},"properties":{"id":"86332","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.295734,34.128479],[-112.207842,33.969859],[-112.145635,34.046165],[-111.874652,34.016516],[-111.874238,34.016466],[-111.820392,34.155734],[-112.199398,34.239738],[-112.295734,34.128479]]]},"properties":{"id":"85324","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.616936,32.766958],[-113.565387,32.91785],[-114.16136,32.779888],[-113.978989,32.69419],[-113.737936,32.727932],[-113.616936,32.766958]]]},"properties":{"id":"85347","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.651472,35.887764],[-109.84582,35.924421],[-109.8548,35.922379],[-110.000674,35.662657],[-109.843728,35.238579],[-109.396934,35.5654],[-109.343571,35.895802],[-109.651472,35.887764]]]},"properties":{"id":"86505","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.406825,36.353653],[-109.207546,36.273996],[-109.222228,36.254855],[-109.104062,36.464578],[-109.290815,36.556646],[-109.343546,36.515013],[-109.461931,36.406266],[-109.406825,36.353653]]]},"properties":{"id":"86507","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.414869,34.082893],[-109.544217,34.125177],[-109.544263,34.074933],[-109.544266,34.030131],[-109.495726,33.889585],[-109.398523,33.881372],[-109.372791,33.980395],[-109.357355,34.05843],[-109.414869,34.082893]]]},"properties":{"id":"85927","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.762548,31.963781],[-109.947822,31.870728],[-109.937848,31.872995],[-109.426115,31.806429],[-109.336832,31.817573],[-109.762548,31.963781]]]},"properties":{"id":"85625","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.54452,31.565225],[-109.898952,31.62307],[-109.851929,31.557056],[-109.698254,31.437678],[-109.54452,31.565225]]]},"properties":{"id":"85617","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.762548,31.963781],[-110.02597,32.107432],[-109.976614,32.021341],[-109.978826,31.927176],[-109.947822,31.870728],[-109.762548,31.963781]]]},"properties":{"id":"85606","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.141917,35.678928],[-111.313256,36.416899],[-111.357965,36.419279],[-111.638344,36.460818],[-111.762829,36.198516],[-111.760428,36.007317],[-111.726199,35.897508],[-111.726347,35.865199],[-111.74657,35.693272],[-111.634339,35.657557],[-111.141917,35.678928]]]},"properties":{"id":"86020","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.239533,36.434211],[-111.060064,37.002421],[-111.411676,37.001499],[-111.357965,36.419279],[-111.313256,36.416899],[-111.239533,36.434211]]]},"properties":{"id":"86053","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.129011,34.979214],[-110.829658,34.638056],[-110.750685,34.63665],[-110.698583,34.636981],[-110.486828,34.797963],[-110.412153,34.903762],[-110.254707,35.189571],[-110.19169,35.25794],[-110.221005,35.573317],[-110.278613,35.605665],[-110.750693,35.519819],[-111.182,35.15613],[-111.1659,35.05068],[-111.129011,34.979214]]]},"properties":{"id":"86047","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.91186,35.251694],[-111.789108,35.218358],[-111.911813,35.25313],[-111.91186,35.251694]]]},"properties":{"id":"86015","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.315222,36.189215],[-112.629727,36.392322],[-112.982347,36.231741],[-113.334024,35.803031],[-113.334051,35.591797],[-113.191181,35.606132],[-113.07271,35.686865],[-112.328779,36.064505],[-112.315222,36.189215]]]},"properties":{"id":"86435","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.113392,33.734826],[-111.222291,33.602505],[-111.433306,33.546473],[-111.480144,33.46586],[-111.442165,33.465898],[-111.26976,33.465538],[-110.994298,33.379396],[-110.844186,33.41951],[-111.028898,33.712371],[-111.113392,33.734826]]]},"properties":{"id":"85545","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.892844,32.864157],[-110.646343,32.750494],[-110.448402,32.851509],[-110.448414,32.899963],[-110.449408,32.988177],[-110.44941,32.990169],[-110.706212,33.103211],[-110.944707,33.247328],[-110.795747,33.005271],[-110.785441,33.004669],[-110.785951,32.997664],[-110.793981,33.002302],[-110.787374,32.984583],[-110.904398,32.8808],[-110.892844,32.864157]]]},"properties":{"id":"85192","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.793981,33.002302],[-110.785951,32.997664],[-110.785441,33.004669],[-110.795747,33.005271],[-110.793981,33.002302]]]},"properties":{"id":"85135","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.916265,32.961747],[-109.897611,32.990631],[-109.937318,33.030684],[-109.916265,32.961747]]]},"properties":{"id":"85535","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.960816,33.799176],[-111.960744,33.731804],[-111.890608,33.726734],[-111.891177,33.813834],[-111.960816,33.799176]]]},"properties":{"id":"85266","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.048005,33.799043],[-112.124452,33.746866],[-112.114563,33.699875],[-112.065723,33.701988],[-112.048005,33.799043]]]},"properties":{"id":"85085","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.893382,33.327708],[-111.893596,33.291148],[-111.85885,33.291525],[-111.859099,33.35705],[-111.894544,33.356735],[-111.894356,33.349347],[-111.893382,33.327708]]]},"properties":{"id":"85224","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.890994,33.444781],[-111.888462,33.411136],[-111.88836,33.407522],[-111.86047,33.411288],[-111.82295,33.41422],[-111.822943,33.415079],[-111.82221,33.469744],[-111.890994,33.444781]]]},"properties":{"id":"85201","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.116543,33.610849],[-112.151174,33.610727],[-112.151518,33.581922],[-112.116834,33.581974],[-112.073776,33.582551],[-112.065124,33.605275],[-112.074506,33.607589],[-112.116543,33.610849]]]},"properties":{"id":"85029","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.384614,33.551508],[-112.470065,33.537129],[-112.51336,33.514415],[-112.461482,33.46209],[-112.357983,33.485768],[-112.312825,33.493133],[-112.306577,33.507539],[-112.35815,33.529797],[-112.384614,33.551508]]]},"properties":{"id":"85340","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.684516,33.415675],[-111.751497,33.415528],[-111.753956,33.379159],[-111.691205,33.379354],[-111.680451,33.393334],[-111.684539,33.411626],[-111.684516,33.415675]]]},"properties":{"id":"85206","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.202709,33.596216],[-112.26334,33.594967],[-112.300129,33.565506],[-112.307179,33.565372],[-112.274461,33.551322],[-112.237792,33.552175],[-112.211618,33.560365],[-112.207286,33.559969],[-112.202801,33.583949],[-112.202709,33.596216]]]},"properties":{"id":"85345","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.717519,33.726778],[-111.890608,33.726734],[-111.960744,33.731804],[-111.977496,33.726576],[-111.968725,33.698729],[-111.925388,33.638742],[-111.907665,33.634847],[-111.854105,33.604375],[-111.787364,33.64014],[-111.69855,33.640491],[-111.683404,33.709754],[-111.717519,33.726778]]]},"properties":{"id":"85255","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.341042,33.457997],[-112.272537,33.46144],[-112.272537,33.464733],[-112.312825,33.493133],[-112.357983,33.485768],[-112.341042,33.457997]]]},"properties":{"id":"85392","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.008376,33.726493],[-112.030857,33.655041],[-111.977414,33.655213],[-111.958855,33.650651],[-111.968725,33.698729],[-111.977496,33.726576],[-112.008376,33.726493]]]},"properties":{"id":"85050","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.039018,33.595655],[-112.037999,33.553479],[-112.012728,33.553725],[-111.995233,33.544615],[-111.977887,33.582704],[-111.977823,33.595542],[-112.030476,33.596745],[-112.039018,33.595655]]]},"properties":{"id":"85028","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.891382,33.85728],[-111.891177,33.813834],[-111.890608,33.726734],[-111.717519,33.726778],[-111.666941,33.800884],[-111.874238,34.016466],[-111.874652,34.016516],[-111.879665,34.009133],[-111.891382,33.85728]]]},"properties":{"id":"85262","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.558748,33.696925],[-112.439181,33.782212],[-112.447075,33.810984],[-112.755117,33.868324],[-112.762931,33.732535],[-112.6039,33.689598],[-112.599482,33.690309],[-112.558748,33.696925]]]},"properties":{"id":"85361","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.691205,33.379354],[-111.753956,33.379159],[-111.788354,33.379012],[-111.791276,33.349995],[-111.695881,33.350527],[-111.687671,33.365018],[-111.691205,33.379354]]]},"properties":{"id":"85234","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.309112,33.379883],[-112.272537,33.46144],[-112.341042,33.457997],[-112.341289,33.457999],[-112.336557,33.362773],[-112.30615,33.362626],[-112.309112,33.379883]]]},"properties":{"id":"85323","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.772666,33.263061],[-111.789674,33.277363],[-111.789646,33.291887],[-111.85885,33.291525],[-111.893596,33.291148],[-111.893276,33.261387],[-111.836853,33.247681],[-111.772635,33.24859],[-111.772666,33.263061]]]},"properties":{"id":"85286","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.151445,33.536183],[-112.151537,33.50945],[-112.151647,33.480486],[-112.135141,33.480465],[-112.137477,33.5385],[-112.151445,33.536183]]]},"properties":{"id":"85019","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.789674,33.277363],[-111.772666,33.263061],[-111.686052,33.266399],[-111.686385,33.28846],[-111.789674,33.277363]]]},"properties":{"id":"85297","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.08683,35.214296],[-114.146656,34.944827],[-113.813264,35.02159],[-113.332967,34.854056],[-113.332878,35.141646],[-113.333282,35.325763],[-113.858904,35.525201],[-114.08683,35.214296]]]},"properties":{"id":"86401","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.382229,34.531828],[-114.29648,34.494155],[-114.188038,34.549878],[-114.258158,34.734598],[-114.43887,34.622316],[-114.382229,34.531828]]]},"properties":{"id":"86404","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.378619,34.531839],[-110.193837,34.460162],[-109.993609,34.438174],[-109.848248,34.460321],[-109.850724,34.696423],[-109.905653,34.718346],[-110.02891,34.726481],[-110.264858,34.577947],[-110.378469,34.577422],[-110.378619,34.531839]]]},"properties":{"id":"85937","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.090323,34.915348],[-109.993592,34.953333],[-109.968633,35.040653],[-110.039507,35.04071],[-110.090323,34.915348]]]},"properties":{"id":"86029","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.217699,32.114941],[-111.548022,31.930873],[-111.594958,31.769453],[-111.335147,31.769634],[-111.225814,31.783921],[-111.216336,32.000362],[-111.216959,32.075179],[-111.217214,32.103731],[-111.217699,32.114941]]]},"properties":{"id":"85736","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.964926,32.510823],[-110.932242,32.453505],[-110.876321,32.45451],[-110.785444,32.513539],[-111.075481,32.700812],[-111.142477,32.75764],[-111.206396,32.700011],[-110.964926,32.510823]]]},"properties":{"id":"85739","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.857928,32.192134],[-110.857925,32.234283],[-110.90968,32.236189],[-110.910864,32.207009],[-110.909304,32.196204],[-110.875113,32.192284],[-110.857928,32.192134]]]},"properties":{"id":"85711","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.875113,32.192284],[-110.857953,32.172055],[-110.857928,32.192134],[-110.875113,32.192284]]]},"properties":{"id":"85708","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.875057,32.337435],[-110.787637,32.336315],[-110.652319,32.261439],[-110.552484,32.313237],[-110.684082,32.513793],[-110.785444,32.513539],[-110.876321,32.45451],[-110.893127,32.413417],[-110.875057,32.337435]]]},"properties":{"id":"85619","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.330359,31.685608],[-111.366613,31.521291],[-111.163725,31.521826],[-111.164097,31.564776],[-111.164096,31.565326],[-111.330359,31.685608]]]},"properties":{"id":"85601","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.156896,32.413795],[-111.150161,32.413879],[-111.154526,32.417259],[-111.156896,32.413795]]]},"properties":{"id":"85654","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.583195,33.277383],[-111.582683,33.218888],[-111.48775,33.19118],[-111.563376,33.320252],[-111.583782,33.336641],[-111.583195,33.277383]]]},"properties":{"id":"85140","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.646343,32.750494],[-110.670754,32.639406],[-110.448621,32.730027],[-110.448402,32.851509],[-110.646343,32.750494]]]},"properties":{"id":"85618","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.357498,34.467775],[-112.133455,34.455891],[-112.2835,34.504231],[-112.357498,34.467775]]]},"properties":{"id":"86329","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.45293,34.551666],[-112.450327,34.552655],[-112.448099,34.556871],[-112.454997,34.554587],[-112.45293,34.551666]]]},"properties":{"id":"86313","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.32816,32.619911],[-113.95298,32.664538],[-113.978989,32.69419],[-114.16136,32.779888],[-114.261668,32.765219],[-114.342531,32.678114],[-114.32816,32.619911]]]},"properties":{"id":"85356","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.786856,32.534281],[-114.812214,32.523213],[-114.718091,32.465714],[-114.786856,32.534281]]]},"properties":{"id":"85349","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.239752,35.889805],[-109.094535,35.704523],[-109.0462,35.720576],[-109.046231,35.806201],[-109.083484,36.0018],[-109.045954,36.010344],[-109.045994,36.199914],[-109.136838,36.130621],[-109.221911,35.960881],[-109.239752,35.889805]]]},"properties":{"id":"86504","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.460454,36.257648],[-109.798724,36.514273],[-109.878175,36.465878],[-109.988712,36.36421],[-109.937138,36.346945],[-109.84582,35.924421],[-109.651472,35.887764],[-109.221911,35.960881],[-109.136838,36.130621],[-109.222228,36.254855],[-109.207546,36.273996],[-109.460454,36.257648]]]},"properties":{"id":"86503","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.794204,36.998235],[-109.733362,36.924633],[-109.405593,36.611798],[-109.343546,36.515013],[-109.290815,36.556646],[-109.045349,36.782393],[-109.794204,36.998235]]]},"properties":{"id":"86514","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.104062,36.464578],[-109.045688,36.465628],[-109.045349,36.782393],[-109.290815,36.556646],[-109.104062,36.464578]]]},"properties":{"id":"86544","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.147242,31.981299],[-110.35029,31.781562],[-110.271393,31.779805],[-109.978826,31.927176],[-109.976614,32.021341],[-110.147242,31.981299]]]},"properties":{"id":"85630","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.572931,37.00167],[-111.638344,36.460818],[-111.357965,36.419279],[-111.411676,37.001499],[-111.572931,37.00167]]]},"properties":{"id":"86040","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.904178,35.606472],[-111.959265,35.420259],[-111.911813,35.25313],[-111.789108,35.218358],[-111.735895,35.02955],[-111.723158,35.044384],[-111.567811,34.921625],[-111.476517,34.981174],[-111.393222,35.004527],[-111.129011,34.979214],[-111.1659,35.05068],[-111.634339,35.657557],[-111.74657,35.693272],[-111.74665,35.745425],[-111.904178,35.606472]],[[-111.661464,35.175504],[-111.652879,35.181987],[-111.648225,35.173372],[-111.661464,35.175504]]]},"properties":{"id":"86001","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.725973,34.804181],[-111.723158,35.044384],[-111.735895,35.02955],[-111.912106,34.976277],[-112.008176,34.975791],[-111.970261,34.753458],[-111.965378,34.746654],[-111.873055,34.798743],[-111.725973,34.804181]]]},"properties":{"id":"86336","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.524246,32.648168],[-109.562992,32.771487],[-109.661711,32.683698],[-109.524246,32.648168]]]},"properties":{"id":"85551","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.524908,33.638849],[-114.708929,33.384538],[-114.588453,33.420969],[-114.450696,33.551166],[-114.413906,33.629732],[-114.476947,33.636609],[-114.524908,33.638849]]]},"properties":{"id":"85334","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.708929,33.384538],[-114.516454,33.027617],[-114.514246,33.027634],[-114.588453,33.420969],[-114.708929,33.384538]]]},"properties":{"id":"85328","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.765788,33.811852],[-113.333962,33.72642],[-113.333627,34.122249],[-113.419638,34.298725],[-113.601241,34.23248],[-113.765788,33.811852]]]},"properties":{"id":"85357","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.223176,33.681688],[-112.202724,33.635837],[-112.151791,33.639321],[-112.13447,33.653557],[-112.134764,33.683837],[-112.223176,33.681688]]]},"properties":{"id":"85308","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.186379,33.480545],[-112.151671,33.478039],[-112.151647,33.480486],[-112.151537,33.50945],[-112.18636,33.509569],[-112.186379,33.480545]]]},"properties":{"id":"85031","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.82221,33.469744],[-111.822943,33.415079],[-111.784189,33.415404],[-111.787477,33.484278],[-111.787475,33.489078],[-111.82221,33.469744]]]},"properties":{"id":"85203","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.030476,33.596745],[-111.977823,33.595542],[-111.977414,33.655213],[-112.030857,33.655041],[-112.035238,33.651376],[-112.030476,33.596745]]]},"properties":{"id":"85032","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.237699,33.508802],[-112.237824,33.47981],[-112.186379,33.480545],[-112.18636,33.509569],[-112.203165,33.509355],[-112.237699,33.508802]]]},"properties":{"id":"85033","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.957217,33.480061],[-111.891836,33.47312],[-111.888058,33.509391],[-111.92607,33.509548],[-111.95074,33.504499],[-111.957217,33.480061]]]},"properties":{"id":"85251","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.112216,33.538618],[-112.113007,33.480576],[-112.099969,33.480614],[-112.091378,33.480483],[-112.091126,33.537902],[-112.112216,33.538618]]]},"properties":{"id":"85015","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.065055,33.538272],[-112.073751,33.538314],[-112.073774,33.480249],[-112.065073,33.480207],[-112.065055,33.538272]]]},"properties":{"id":"85012","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.402718,33.679715],[-112.46033,33.638445],[-112.454999,33.638461],[-112.409394,33.624238],[-112.342063,33.623562],[-112.308252,33.627763],[-112.301451,33.638454],[-112.325033,33.655436],[-112.402718,33.679715]]]},"properties":{"id":"85374","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.289782,33.681814],[-112.325018,33.704318],[-112.325033,33.655436],[-112.301451,33.638454],[-112.25936,33.638169],[-112.289782,33.681814]]]},"properties":{"id":"85373","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.237792,33.552175],[-112.274461,33.551322],[-112.275972,33.510686],[-112.237699,33.508802],[-112.237792,33.552175]]]},"properties":{"id":"85305","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.304798,33.606949],[-112.311497,33.565435],[-112.307179,33.565372],[-112.300129,33.565506],[-112.304798,33.606949]]]},"properties":{"id":"85363","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.116834,33.581974],[-112.112216,33.538618],[-112.091126,33.537902],[-112.073751,33.538314],[-112.073776,33.582551],[-112.116834,33.581974]]]},"properties":{"id":"85021","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.10046,33.344858],[-112.168812,33.407742],[-112.236982,33.400146],[-112.309112,33.379883],[-112.30615,33.362626],[-112.202045,33.117804],[-112.036472,33.08702],[-111.995359,33.204596],[-112.133789,33.29026],[-112.148004,33.304646],[-112.100457,33.333046],[-112.10046,33.344858]]]},"properties":{"id":"85339","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.359601,33.711256],[-112.402718,33.679715],[-112.325033,33.655436],[-112.325018,33.704318],[-112.359601,33.711256]]]},"properties":{"id":"85375","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.07502,33.480261],[-112.073303,33.421944],[-112.064894,33.42129],[-112.065143,33.451358],[-112.065073,33.480207],[-112.073774,33.480249],[-112.07502,33.480261]]]},"properties":{"id":"85004","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.031913,32.850171],[-112.036472,33.08702],[-112.202045,33.117804],[-112.203736,32.941736],[-112.203317,32.836211],[-112.032359,32.830322],[-112.032331,32.830886],[-112.031913,32.850171]]]},"properties":{"id":"85139","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.057208,36.851871],[-113.078182,36.937217],[-113.079881,36.999985],[-114.043944,36.19335],[-113.903532,36.092838],[-113.334024,35.803031],[-112.982347,36.231741],[-113.057208,36.851871]]]},"properties":{"id":"86432","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.333092,34.47846],[-113.333028,34.78858],[-113.332967,34.854056],[-113.813264,35.02159],[-113.333092,34.47846]]]},"properties":{"id":"85360","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.486548,34.409548],[-110.627047,34.288221],[-110.424875,34.30586],[-110.391592,34.387413],[-110.486548,34.409548]]]},"properties":{"id":"85933","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.34764,36.998199],[-110.245019,36.976303],[-110.089974,36.998511],[-110.34764,36.998199]]]},"properties":{"id":"84536","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.363927,35.810297],[-110.300478,35.941634],[-110.478939,35.925243],[-110.487521,35.914909],[-110.4735,35.740584],[-110.363927,35.810297]]]},"properties":{"id":"86042","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.012789,32.369168],[-111.089727,32.358921],[-111.045689,32.315552],[-111.012033,32.302944],[-111.012789,32.369168]]]},"properties":{"id":"85741","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.217699,32.114941],[-111.217214,32.103731],[-111.071286,32.171347],[-111.217699,32.114941]]]},"properties":{"id":"85757","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.141004,32.408821],[-111.278656,32.219348],[-111.21799,32.204526],[-111.132117,32.220662],[-111.132125,32.22154],[-111.02952,32.294221],[-111.045689,32.315552],[-111.089727,32.358921],[-111.140258,32.408872],[-111.141004,32.408821]]]},"properties":{"id":"85743","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.737409,32.162955],[-110.833051,32.130678],[-110.842803,32.04508],[-110.737409,32.162955]]]},"properties":{"id":"85747","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.857953,32.172055],[-110.875113,32.192284],[-110.909304,32.196204],[-110.908528,32.185175],[-110.896144,32.163057],[-110.890844,32.162977],[-110.865447,32.159863],[-110.857953,32.172055]]]},"properties":{"id":"85707","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.961345,32.177884],[-110.961386,32.181261],[-110.966691,32.182953],[-110.968253,32.17782],[-110.961345,32.177884]]]},"properties":{"id":"85723","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.749383,32.719016],[-111.688804,32.622794],[-111.654155,32.762981],[-111.749383,32.719016]]]},"properties":{"id":"85123","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.895832,31.48576],[-111.163725,31.521826],[-111.366613,31.521291],[-111.366573,31.426075],[-110.760998,31.333634],[-110.850189,31.553329],[-110.895832,31.48576]]]},"properties":{"id":"85621","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.163725,31.521826],[-110.895832,31.48576],[-111.037579,31.577607],[-111.063362,31.561549],[-111.164097,31.564776],[-111.163725,31.521826]]]},"properties":{"id":"85648","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.732578,35.010557],[-113.332878,35.141646],[-113.332967,34.854056],[-113.333028,34.78858],[-113.319453,34.586819],[-112.935744,34.610554],[-112.619464,34.542641],[-112.476583,34.542197],[-112.435825,34.655504],[-112.522384,34.851702],[-112.732578,35.010557]]]},"properties":{"id":"86305","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.435825,34.655504],[-112.476583,34.542197],[-112.369267,34.571679],[-112.398411,34.68174],[-112.435825,34.655504]],[[-112.45293,34.551666],[-112.454997,34.554587],[-112.448099,34.556871],[-112.450327,34.552655],[-112.45293,34.551666]]]},"properties":{"id":"86301","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.913101,34.369015],[-112.707149,34.205982],[-112.890396,34.371195],[-112.913101,34.369015]]]},"properties":{"id":"85362","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.754614,32.654542],[-114.526856,32.757094],[-114.71562,32.728208],[-114.763998,32.643364],[-114.754614,32.654542]]]},"properties":{"id":"85364","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.284549,35.468979],[-109.125101,35.45361],[-109.046682,35.428677],[-109.046271,35.616378],[-109.094535,35.704523],[-109.239752,35.889805],[-109.343571,35.895802],[-109.396934,35.5654],[-109.284549,35.468979]]]},"properties":{"id":"86511","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.380348,35.339091],[-109.526318,34.951601],[-109.526475,34.908263],[-109.046006,34.904161],[-109.046181,35.214429],[-109.122969,35.229586],[-109.380348,35.339091]]]},"properties":{"id":"86512","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.798724,36.514273],[-109.460454,36.257648],[-109.406825,36.353653],[-109.461931,36.406266],[-109.587591,36.57542],[-109.72434,36.598884],[-109.798724,36.514273]]]},"properties":{"id":"86538","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.000677,36.997968],[-110.000677,36.890514],[-109.834989,36.998306],[-110.000677,36.997968]]]},"properties":{"id":"84531","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.107966,35.938678],[-110.302053,36.000002],[-110.300478,35.941634],[-110.363927,35.810297],[-110.278613,35.605665],[-110.221005,35.573317],[-110.000674,35.662657],[-109.8548,35.922379],[-110.107966,35.938678]]]},"properties":{"id":"86034","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045954,36.010344],[-109.083484,36.0018],[-109.046231,35.806201],[-109.045954,36.010344]]]},"properties":{"id":"87328","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.357355,34.05843],[-109.254363,34.071605],[-109.265702,34.11849],[-109.326163,34.121183],[-109.414869,34.082893],[-109.357355,34.05843]]]},"properties":{"id":"85925","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.393656,33.872727],[-109.183848,33.778175],[-109.046943,33.778233],[-109.046558,33.908025],[-109.393656,33.872727]]]},"properties":{"id":"85920","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.671065,32.426929],[-109.369318,32.132139],[-109.330064,32.426009],[-109.663185,32.426902],[-109.671065,32.426929]]]},"properties":{"id":"85605","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.596399,31.466935],[-109.596044,31.449043],[-109.57364,31.452982],[-109.572877,31.465864],[-109.596399,31.466935]]]},"properties":{"id":"85608","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.642458,31.60417],[-110.46017,31.353781],[-110.385922,31.465079],[-110.417475,31.497495],[-110.447826,31.585127],[-110.451996,31.744567],[-110.555051,31.948026],[-110.632055,31.788934],[-110.642458,31.60417]]]},"properties":{"id":"85611","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.182,35.15613],[-110.750693,35.519819],[-110.750693,35.653007],[-110.750693,35.76851],[-110.750694,35.794833],[-110.854074,35.849953],[-111.000701,35.647968],[-111.227634,35.495176],[-111.21279,35.290205],[-111.205707,35.156209],[-111.182,35.15613]]]},"properties":{"id":"86035","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.239533,36.434211],[-110.750698,36.303667],[-110.456599,36.414008],[-110.526111,36.594081],[-110.702061,37.0034],[-111.060064,37.002421],[-111.239533,36.434211]]]},"properties":{"id":"86044","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.048418,35.402084],[-111.911827,35.105309],[-111.91186,35.251694],[-111.911813,35.25313],[-111.959265,35.420259],[-112.048418,35.402084]]]},"properties":{"id":"86018","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.405369,33.881935],[-111.296109,33.661929],[-111.222291,33.602505],[-111.113392,33.734826],[-111.171596,33.993028],[-111.405369,33.881935]]]},"properties":{"id":"85553","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.844186,33.41951],[-110.994298,33.379396],[-111.057883,33.313298],[-111.055621,33.311235],[-110.973029,33.336744],[-110.844186,33.41951]]]},"properties":{"id":"85539","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.765545,32.878209],[-109.805022,32.85311],[-109.912583,32.70389],[-109.671065,32.426929],[-109.663185,32.426902],[-109.661711,32.683698],[-109.562992,32.771487],[-109.524246,32.648168],[-109.233008,32.718491],[-109.33806,32.849995],[-109.412853,32.958576],[-109.778989,32.903356],[-109.804632,32.901027],[-109.765545,32.878209]]]},"properties":{"id":"85546","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.487466,33.066561],[-109.237848,32.935037],[-109.047208,33.107377],[-109.047122,33.240446],[-109.256122,33.671328],[-109.487466,33.066561]]]},"properties":{"id":"85533","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.256694,34.011221],[-114.476947,33.636609],[-114.413906,33.629732],[-114.256694,34.011221]]]},"properties":{"id":"85371","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.203134,33.712285],[-112.134877,33.698349],[-112.114563,33.699875],[-112.124452,33.746866],[-112.20354,33.7519],[-112.203134,33.712285]]]},"properties":{"id":"85083","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.960816,33.799176],[-111.891382,33.85728],[-111.879665,34.009133],[-111.995696,33.900934],[-112.03059,33.871651],[-112.021568,33.799128],[-112.008376,33.726493],[-111.977496,33.726576],[-111.960744,33.731804],[-111.960816,33.799176]]]},"properties":{"id":"85331","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.890994,33.444781],[-111.939301,33.463984],[-111.961125,33.445312],[-111.978017,33.421902],[-111.978126,33.415346],[-111.888462,33.411136],[-111.890994,33.444781]]]},"properties":{"id":"85281","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.099495,33.410853],[-112.099969,33.480614],[-112.113007,33.480576],[-112.135141,33.480465],[-112.151647,33.480486],[-112.151671,33.478039],[-112.151863,33.461789],[-112.168812,33.407742],[-112.099495,33.410853]]]},"properties":{"id":"85009","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.047475,33.538252],[-112.047635,33.48021],[-112.030197,33.477218],[-112.015074,33.480471],[-112.012893,33.518251],[-112.012944,33.531865],[-112.012728,33.553725],[-112.037999,33.553479],[-112.047475,33.538252]]]},"properties":{"id":"85016","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.824848,33.378853],[-111.788354,33.379012],[-111.753956,33.379159],[-111.751497,33.415528],[-111.753696,33.415521],[-111.784189,33.415404],[-111.822943,33.415079],[-111.82295,33.41422],[-111.824848,33.378853]]]},"properties":{"id":"85204","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.26334,33.594967],[-112.202709,33.596216],[-112.202622,33.610607],[-112.202486,33.625059],[-112.254846,33.624254],[-112.26334,33.594967]]]},"properties":{"id":"85381","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.065055,33.538272],[-112.065073,33.480207],[-112.047635,33.48021],[-112.047475,33.538252],[-112.065055,33.538272]]]},"properties":{"id":"85014","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.073776,33.582551],[-112.073751,33.538314],[-112.065055,33.538272],[-112.047475,33.538252],[-112.037999,33.553479],[-112.039018,33.595655],[-112.065124,33.605275],[-112.073776,33.582551]]]},"properties":{"id":"85020","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.921699,33.580939],[-111.856543,33.582494],[-111.854105,33.604375],[-111.907665,33.634847],[-111.926008,33.582171],[-111.921699,33.580939]]]},"properties":{"id":"85260","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.238146,33.462964],[-112.151863,33.461789],[-112.151671,33.478039],[-112.186379,33.480545],[-112.237824,33.47981],[-112.237989,33.465335],[-112.238146,33.462964]]]},"properties":{"id":"85035","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.693935,33.517183],[-111.76079,33.484921],[-111.75339,33.451872],[-111.68413,33.451669],[-111.580626,33.465902],[-111.538228,33.465816],[-111.480144,33.46586],[-111.433306,33.546473],[-111.693935,33.517183]]]},"properties":{"id":"85215","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.099969,33.480614],[-112.099495,33.410853],[-112.082168,33.417476],[-112.08225,33.419137],[-112.082987,33.479433],[-112.091378,33.480483],[-112.099969,33.480614]]]},"properties":{"id":"85007","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.082987,33.479433],[-112.08225,33.419137],[-112.073303,33.421944],[-112.07502,33.480261],[-112.082987,33.479433]]]},"properties":{"id":"85003","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.580626,33.465902],[-111.615345,33.415097],[-111.584293,33.378649],[-111.584005,33.361793],[-111.583782,33.336641],[-111.563376,33.320252],[-111.544559,33.35073],[-111.538228,33.465816],[-111.580626,33.465902]]]},"properties":{"id":"85120","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.891836,33.47312],[-111.890994,33.444781],[-111.82221,33.469744],[-111.787475,33.489078],[-111.821685,33.56789],[-111.849246,33.567955],[-111.856578,33.567971],[-111.888058,33.509391],[-111.891836,33.47312]]]},"properties":{"id":"85256","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.296109,33.661929],[-111.683404,33.709754],[-111.69855,33.640491],[-111.708372,33.567492],[-111.693935,33.517183],[-111.433306,33.546473],[-111.222291,33.602505],[-111.296109,33.661929]]]},"properties":{"id":"85264","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.686392,33.288682],[-111.686925,33.321674],[-111.789772,33.320931],[-111.789759,33.313436],[-111.789646,33.291887],[-111.789674,33.277363],[-111.686385,33.28846],[-111.686392,33.288682]]]},"properties":{"id":"85295","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.73729,36.016179],[-114.323827,35.790753],[-114.16311,36.026618],[-114.73729,36.016179]]]},"properties":{"id":"86443","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.678834,35.50172],[-114.591445,35.276766],[-114.505923,35.074289],[-114.502343,35.036945],[-114.483129,34.986014],[-114.244313,34.805796],[-114.192429,34.772417],[-114.146656,34.944827],[-114.08683,35.214296],[-114.167001,35.29578],[-114.280925,35.48695],[-114.678834,35.50172]]]},"properties":{"id":"86413","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.280925,35.48695],[-114.167001,35.29578],[-114.168657,35.454211],[-114.280925,35.48695]]]},"properties":{"id":"86431","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.591445,35.276766],[-114.582006,35.126566],[-114.505923,35.074289],[-114.591445,35.276766]]]},"properties":{"id":"86429","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.502343,35.036945],[-114.617491,35.051703],[-114.635177,34.963648],[-114.483129,34.986014],[-114.502343,35.036945]]]},"properties":{"id":"86426","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.698583,34.636981],[-110.750679,34.322469],[-110.750679,34.294513],[-110.669657,34.276702],[-110.627047,34.288221],[-110.486548,34.409548],[-110.378619,34.531839],[-110.378469,34.577422],[-110.486507,34.665652],[-110.486828,34.797963],[-110.698583,34.636981]]]},"properties":{"id":"85928","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.848248,34.460321],[-109.993609,34.438174],[-110.006135,34.392572],[-109.848276,34.448967],[-109.848248,34.460321]]]},"properties":{"id":"85912","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.905653,34.718346],[-110.055991,34.78571],[-110.02891,34.726481],[-109.905653,34.718346]]]},"properties":{"id":"85942","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.986669,32.133992],[-110.890844,32.162977],[-110.896144,32.163057],[-110.986669,32.133992]]]},"properties":{"id":"85706","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.132125,32.22154],[-110.977742,32.206751],[-110.982171,32.229365],[-111.02952,32.294221],[-111.132125,32.22154]]]},"properties":{"id":"85745","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.947963,32.206903],[-110.960902,32.206851],[-110.977742,32.206751],[-111.132125,32.22154],[-111.132117,32.220662],[-111.071286,32.171347],[-110.991509,32.172524],[-110.968253,32.17782],[-110.966691,32.182953],[-110.961386,32.181261],[-110.961345,32.177884],[-110.908528,32.185175],[-110.909304,32.196204],[-110.910864,32.207009],[-110.935154,32.207089],[-110.947963,32.206903]],[[-110.947963,32.206903],[-110.943658,32.205616],[-110.944908,32.202248],[-110.947963,32.206903]]]},"properties":{"id":"85713","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.789712,32.191795],[-110.857928,32.192134],[-110.857953,32.172055],[-110.865447,32.159863],[-110.833051,32.130678],[-110.737409,32.162955],[-110.669837,32.235075],[-110.789712,32.191795]]]},"properties":{"id":"85730","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.946783,32.241617],[-110.946768,32.238664],[-110.943986,32.239645],[-110.946783,32.241617]]]},"properties":{"id":"85724","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.791669,33.169015],[-111.840913,33.159475],[-111.824557,33.102393],[-111.671055,33.047901],[-111.661044,33.117826],[-111.750504,33.204751],[-111.780846,33.204689],[-111.791669,33.169015]]]},"properties":{"id":"85147","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.063362,31.561549],[-111.037579,31.577607],[-111.043775,31.594875],[-111.070369,31.597127],[-111.063362,31.561549]]]},"properties":{"id":"85640","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.522384,34.851702],[-112.334787,34.879251],[-112.365616,34.991749],[-112.735398,35.089333],[-112.732578,35.010557],[-112.522384,34.851702]]]},"properties":{"id":"86334","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.284549,35.468979],[-109.396934,35.5654],[-109.843728,35.238579],[-109.843696,35.170841],[-109.526318,34.951601],[-109.380348,35.339091],[-109.284549,35.468979]]]},"properties":{"id":"86502","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.467579,36.998001],[-110.526111,36.594081],[-110.456599,36.414008],[-109.988712,36.36421],[-109.878175,36.465878],[-109.872966,36.520831],[-110.000677,36.890514],[-110.000677,36.997968],[-110.089974,36.998511],[-110.245019,36.976303],[-110.34764,36.998199],[-110.467579,36.998001]]]},"properties":{"id":"86033","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.837356,34.285908],[-109.848276,34.448967],[-110.006135,34.392572],[-110.105476,34.382129],[-110.207324,34.387678],[-110.202647,34.250975],[-110.040942,34.198204],[-109.84894,34.223007],[-109.849243,34.187544],[-109.753436,34.203037],[-109.837356,34.285908]]]},"properties":{"id":"85901","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.544263,34.074933],[-109.849387,34.125945],[-109.859072,34.040503],[-109.544266,34.030131],[-109.544263,34.074933]]]},"properties":{"id":"85930","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.494968,34.388009],[-109.267255,34.299691],[-109.046006,34.904161],[-109.526475,34.908263],[-109.544971,34.835869],[-109.494968,34.388009]]]},"properties":{"id":"85936","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.291973,31.850892],[-109.049336,31.552518],[-109.047612,32.426377],[-109.113987,32.426177],[-109.330064,32.426009],[-109.369318,32.132139],[-109.291973,31.850892]]]},"properties":{"id":"85632","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.448621,32.730027],[-110.451221,32.513807],[-110.163507,32.161535],[-110.02597,32.107432],[-109.762548,31.963781],[-109.336832,31.817573],[-109.291973,31.850892],[-109.369318,32.132139],[-109.671065,32.426929],[-109.912583,32.70389],[-110.448414,32.899963],[-110.448402,32.851509],[-110.448621,32.730027]]]},"properties":{"id":"85643","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.54452,31.565225],[-109.698254,31.437678],[-109.792725,31.333987],[-109.049336,31.552518],[-109.291973,31.850892],[-109.336832,31.817573],[-109.426115,31.806429],[-109.54452,31.565225]],[[-109.556047,31.359344],[-109.570079,31.351507],[-109.579455,31.352264],[-109.562335,31.364171],[-109.556047,31.359344]],[[-109.596399,31.466935],[-109.572877,31.465864],[-109.57364,31.452982],[-109.596044,31.449043],[-109.596399,31.466935]]]},"properties":{"id":"85607","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.162774,36.732648],[-111.799638,36.193768],[-111.762829,36.198516],[-111.638344,36.460818],[-111.572931,37.00167],[-112.162774,36.732648]]]},"properties":{"id":"86036","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.552007,34.776857],[-111.553274,34.610104],[-111.555046,34.500965],[-111.372063,34.856308],[-111.476517,34.981174],[-111.567811,34.921625],[-111.552007,34.776857]]]},"properties":{"id":"86024","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.685588,34.803403],[-111.552007,34.776857],[-111.567811,34.921625],[-111.723158,35.044384],[-111.725973,34.804181],[-111.685588,34.803403]]]},"properties":{"id":"86017","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.74657,35.693272],[-111.726347,35.865199],[-111.74665,35.745425],[-111.74657,35.693272]]]},"properties":{"id":"86016","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.387579,34.387514],[-111.555124,34.472196],[-111.656443,34.387306],[-111.665099,34.286469],[-111.387579,34.387514]]]},"properties":{"id":"85544","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.520846,33.315486],[-110.449021,33.194526],[-110.080134,33.294068],[-110.070488,33.312914],[-110.443544,33.367552],[-110.520846,33.315486]]]},"properties":{"id":"85542","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.080134,33.294068],[-110.056294,32.970878],[-109.916265,32.961747],[-109.937318,33.030684],[-109.939987,33.253447],[-110.070488,33.312914],[-110.080134,33.294068]]]},"properties":{"id":"85536","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.237848,32.935037],[-109.487466,33.066561],[-109.412853,32.958576],[-109.33806,32.849995],[-109.237848,32.935037]]]},"properties":{"id":"85540","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.256122,33.671328],[-109.047122,33.240446],[-109.046943,33.778233],[-109.183848,33.778175],[-109.256122,33.671328]]]},"properties":{"id":"85922","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.33806,32.849995],[-109.233008,32.718491],[-109.113987,32.426177],[-109.047612,32.426377],[-109.047208,33.107377],[-109.237848,32.935037],[-109.33806,32.849995]]]},"properties":{"id":"85534","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.048005,33.799043],[-112.065723,33.701988],[-112.07222,33.654754],[-112.035238,33.651376],[-112.030857,33.655041],[-112.008376,33.726493],[-112.021568,33.799128],[-112.048005,33.799043]]]},"properties":{"id":"85024","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.4181,31.757436],[-112.203719,32.50676],[-112.817503,31.880067],[-112.753464,31.868091],[-112.718578,31.849793],[-112.4181,31.757436]]]},"properties":{"id":"85321","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.971739,33.349086],[-111.972389,33.326673],[-111.893382,33.327708],[-111.894356,33.349347],[-111.971739,33.349086]]]},"properties":{"id":"85284","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-112.012944,33.531865],[-111.995233,33.544615],[-112.012728,33.553725],[-112.012944,33.531865]]],[[[-112.012893,33.518251],[-112.015074,33.480471],[-111.960961,33.478209],[-111.957217,33.480061],[-111.95074,33.504499],[-112.012893,33.518251]]]]},"properties":{"id":"85018","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.236982,33.400146],[-112.238146,33.462964],[-112.237989,33.465335],[-112.272537,33.464733],[-112.272537,33.46144],[-112.309112,33.379883],[-112.236982,33.400146]]]},"properties":{"id":"85353","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.202801,33.583949],[-112.207286,33.559969],[-112.211135,33.559874],[-112.202949,33.552782],[-112.151586,33.555036],[-112.151518,33.581922],[-112.202801,33.583949]]]},"properties":{"id":"85302","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.926008,33.582171],[-111.907665,33.634847],[-111.925388,33.638742],[-111.958855,33.650651],[-111.977414,33.655213],[-111.977823,33.595542],[-111.977887,33.582704],[-111.926008,33.582171]]]},"properties":{"id":"85254","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.977887,33.582704],[-111.995233,33.544615],[-112.012944,33.531865],[-112.012893,33.518251],[-111.95074,33.504499],[-111.92607,33.509548],[-111.916625,33.538575],[-111.921699,33.580939],[-111.926008,33.582171],[-111.977887,33.582704]]]},"properties":{"id":"85253","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.065143,33.451358],[-112.064894,33.42129],[-111.978017,33.421902],[-111.961125,33.445312],[-112.030157,33.451352],[-112.065143,33.451358]]]},"properties":{"id":"85034","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.10046,33.344858],[-112.073307,33.392248],[-112.082168,33.417476],[-112.099495,33.410853],[-112.168812,33.407742],[-112.10046,33.344858]]]},"properties":{"id":"85041","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.972028,33.305259],[-112.085142,33.291034],[-112.133789,33.29026],[-111.995359,33.204596],[-111.909163,33.204777],[-111.893276,33.261387],[-111.893596,33.291148],[-111.893382,33.327708],[-111.972389,33.326673],[-111.972028,33.305259]]]},"properties":{"id":"85226","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.755117,33.868324],[-112.516781,34.096911],[-112.621009,34.13566],[-113.037334,33.999831],[-113.048225,33.999819],[-113.127359,33.638559],[-112.762931,33.732535],[-112.755117,33.868324]]]},"properties":{"id":"85390","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.791276,33.349995],[-111.789772,33.320931],[-111.686925,33.321674],[-111.695881,33.350527],[-111.791276,33.349995]]]},"properties":{"id":"85296","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.788354,33.379012],[-111.824848,33.378853],[-111.841829,33.361313],[-111.789759,33.313436],[-111.789772,33.320931],[-111.791276,33.349995],[-111.788354,33.379012]]]},"properties":{"id":"85233","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.854105,33.604375],[-111.856543,33.582494],[-111.849246,33.567955],[-111.821685,33.56789],[-111.770065,33.567948],[-111.787364,33.64014],[-111.854105,33.604375]]]},"properties":{"id":"85259","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.859099,33.35705],[-111.85885,33.291525],[-111.789646,33.291887],[-111.789759,33.313436],[-111.841829,33.361313],[-111.859099,33.35705]]]},"properties":{"id":"85225","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.978379,33.381813],[-111.893247,33.378466],[-111.88836,33.407522],[-111.888462,33.411136],[-111.978126,33.415346],[-111.978336,33.391115],[-111.978379,33.381813]]]},"properties":{"id":"85282","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.71119,35.412292],[-113.515909,35.400775],[-113.333793,35.466246],[-113.25408,35.526391],[-113.334051,35.591797],[-113.334024,35.803031],[-113.903532,36.092838],[-113.86278,35.730859],[-113.71119,35.412292]]]},"properties":{"id":"86434","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.333793,35.466246],[-113.515909,35.400775],[-113.682007,35.328559],[-113.71119,35.412292],[-113.86278,35.730859],[-113.942169,35.624312],[-113.858904,35.525201],[-113.333282,35.325763],[-113.333793,35.466246]]]},"properties":{"id":"86411","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.168657,35.454211],[-114.167001,35.29578],[-114.08683,35.214296],[-113.858904,35.525201],[-113.942169,35.624312],[-114.11961,35.707879],[-114.168657,35.454211]]]},"properties":{"id":"86409","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.617491,35.051703],[-114.502343,35.036945],[-114.505923,35.074289],[-114.582006,35.126566],[-114.617491,35.051703]]]},"properties":{"id":"86442","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.138282,34.30323],[-113.982917,34.256251],[-114.188038,34.549878],[-114.29648,34.494155],[-114.326717,34.437856],[-114.138282,34.30323]]]},"properties":{"id":"86406","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.11961,35.707879],[-114.323827,35.790753],[-114.73729,36.016179],[-114.683048,35.67642],[-114.11961,35.707879]]]},"properties":{"id":"86445","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.22486,34.387662],[-110.391592,34.387413],[-110.424875,34.30586],[-110.316483,34.238074],[-110.22486,34.387662]]]},"properties":{"id":"85923","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.22486,34.387662],[-110.316483,34.238074],[-110.202647,34.250975],[-110.207324,34.387678],[-110.22486,34.387662]]]},"properties":{"id":"85934","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.950346,33.760617],[-110.000653,33.783811],[-110.000653,33.746975],[-109.950346,33.760617]]]},"properties":{"id":"85926","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.323436,34.893327],[-110.254707,35.189571],[-110.412153,34.903762],[-110.323436,34.893327]]]},"properties":{"id":"86032","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.132117,32.220662],[-111.21799,32.204526],[-111.548022,31.930873],[-111.217699,32.114941],[-111.071286,32.171347],[-111.132117,32.220662]]]},"properties":{"id":"85735","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.825542,32.261475],[-110.781295,32.239232],[-110.669949,32.243943],[-110.652319,32.261439],[-110.787637,32.336315],[-110.825542,32.261475]]]},"properties":{"id":"85749","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.302165,32.548264],[-111.141004,32.408821],[-111.140258,32.408872],[-111.029439,32.45159],[-110.964926,32.510823],[-111.206396,32.700011],[-111.314289,32.613078],[-111.335953,32.588554],[-111.302165,32.548264]]]},"properties":{"id":"85658","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.982171,32.229365],[-110.977742,32.206751],[-110.960902,32.206851],[-110.965828,32.222991],[-110.982171,32.229365]]]},"properties":{"id":"85701","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.718578,31.849793],[-112.753464,31.868091],[-112.817503,31.880067],[-112.718578,31.849793]]]},"properties":{"id":"85341","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.436584,32.850419],[-111.516633,33.118252],[-111.57726,33.118167],[-111.661044,33.117826],[-111.671055,33.047901],[-111.670546,32.967385],[-111.515272,32.851281],[-111.436584,32.850419]]]},"properties":{"id":"85128","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.91231,32.973447],[-111.902879,33.012979],[-111.926917,33.076237],[-112.036472,33.08702],[-112.031913,32.850171],[-111.91231,32.973447]]]},"properties":{"id":"85138","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.036472,33.08702],[-111.926917,33.076237],[-111.824557,33.102393],[-111.840913,33.159475],[-111.909163,33.204777],[-111.995359,33.204596],[-112.036472,33.08702]]]},"properties":{"id":"85121","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.037579,31.577607],[-110.895832,31.48576],[-110.850189,31.553329],[-110.96273,31.686949],[-111.164096,31.565326],[-111.164097,31.564776],[-111.063362,31.561549],[-111.070369,31.597127],[-111.043775,31.594875],[-111.037579,31.577607]]]},"properties":{"id":"85646","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.522384,34.851702],[-112.435825,34.655504],[-112.398411,34.68174],[-112.354894,34.715623],[-112.200959,34.779024],[-112.173175,34.785207],[-112.295956,34.802473],[-112.334787,34.879251],[-112.522384,34.851702]]]},"properties":{"id":"86323","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.707149,34.205982],[-112.913101,34.369015],[-113.168263,34.370523],[-113.037334,33.999831],[-112.621009,34.13566],[-112.707149,34.205982]]]},"properties":{"id":"85332","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.199398,34.239738],[-112.375222,34.245024],[-112.413852,34.157428],[-112.295734,34.128479],[-112.199398,34.239738]]]},"properties":{"id":"86343","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.970261,34.753458],[-112.0754,34.740412],[-112.118275,34.657636],[-111.96193,34.632706],[-111.939283,34.662188],[-111.965378,34.746654],[-111.970261,34.753458]]]},"properties":{"id":"86326","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.200959,34.779024],[-112.354894,34.715623],[-112.201687,34.655856],[-112.168361,34.692901],[-112.200959,34.779024]]]},"properties":{"id":"86315","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.460025,32.626747],[-114.32816,32.619911],[-114.342531,32.678114],[-114.460025,32.626747]]]},"properties":{"id":"85367","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.614061,32.495375],[-114.754614,32.654542],[-114.763998,32.643364],[-114.792197,32.566785],[-114.786856,32.534281],[-114.718091,32.465714],[-114.614061,32.495375]]]},"properties":{"id":"85350","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.125101,35.45361],[-109.122969,35.229586],[-109.046181,35.214429],[-109.046682,35.428677],[-109.125101,35.45361]]]},"properties":{"id":"86508","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.651472,35.887764],[-109.343571,35.895802],[-109.239752,35.889805],[-109.221911,35.960881],[-109.651472,35.887764]]]},"properties":{"id":"86540","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.837356,34.285908],[-109.753436,34.203037],[-109.554582,34.260498],[-109.533936,34.279101],[-109.837356,34.285908]]]},"properties":{"id":"85940","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.141319,31.539747],[-110.289855,31.527444],[-110.291052,31.48777],[-110.141319,31.539747]]]},"properties":{"id":"85650","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.141319,31.539747],[-110.291052,31.48777],[-110.385922,31.465079],[-110.46017,31.353781],[-110.460172,31.332827],[-110.003089,31.333166],[-110.053165,31.515293],[-110.141319,31.539747]]]},"properties":{"id":"85615","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.163507,32.161535],[-110.262789,32.021071],[-110.162024,31.999054],[-110.147242,31.981299],[-109.976614,32.021341],[-110.02597,32.107432],[-110.163507,32.161535]]]},"properties":{"id":"85609","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.21279,35.290205],[-111.227634,35.495176],[-111.141917,35.678928],[-111.634339,35.657557],[-111.1659,35.05068],[-111.182,35.15613],[-111.205707,35.156209],[-111.242979,35.264192],[-111.21279,35.290205]]]},"properties":{"id":"86004","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.476517,34.981174],[-111.372063,34.856308],[-111.393222,35.004527],[-111.476517,34.981174]]]},"properties":{"id":"86038","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.750698,36.165944],[-110.860933,35.915763],[-110.854074,35.849953],[-110.750694,35.794833],[-110.718228,35.833952],[-110.624419,36.128142],[-110.750698,36.165944]]]},"properties":{"id":"86030","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.449408,32.988177],[-110.056294,32.970878],[-110.080134,33.294068],[-110.449021,33.194526],[-110.44941,32.990169],[-110.449408,32.988177]]]},"properties":{"id":"85530","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.138282,34.30323],[-114.524908,33.638849],[-114.476947,33.636609],[-114.256694,34.011221],[-114.216863,33.994751],[-113.791006,34.234764],[-113.982917,34.256251],[-114.138282,34.30323]]]},"properties":{"id":"85344","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.216863,33.994751],[-114.09845,33.781824],[-113.765788,33.811852],[-113.601241,34.23248],[-113.791006,34.234764],[-114.216863,33.994751]]]},"properties":{"id":"85325","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.289782,33.681814],[-112.223178,33.681733],[-112.203134,33.712285],[-112.20354,33.7519],[-112.195779,33.848454],[-112.211925,33.968618],[-112.278162,33.827864],[-112.360188,33.753148],[-112.359601,33.711256],[-112.325018,33.704318],[-112.289782,33.681814]]]},"properties":{"id":"85383","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.409617,33.580732],[-112.350515,33.580157],[-112.342063,33.623562],[-112.409394,33.624238],[-112.409617,33.580732]]]},"properties":{"id":"85379","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.92607,33.509548],[-111.888058,33.509391],[-111.856578,33.567971],[-111.916625,33.538575],[-111.92607,33.509548]]]},"properties":{"id":"85250","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.849246,33.567955],[-111.856543,33.582494],[-111.921699,33.580939],[-111.916625,33.538575],[-111.856578,33.567971],[-111.849246,33.567955]]]},"properties":{"id":"85258","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.151174,33.610727],[-112.202622,33.610607],[-112.202709,33.596216],[-112.202801,33.583949],[-112.151518,33.581922],[-112.151174,33.610727]]]},"properties":{"id":"85304","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.065124,33.605275],[-112.039018,33.595655],[-112.030476,33.596745],[-112.035238,33.651376],[-112.07222,33.654754],[-112.074174,33.654735],[-112.074506,33.607589],[-112.065124,33.605275]]]},"properties":{"id":"85022","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.584293,33.378649],[-111.615345,33.415097],[-111.684539,33.411626],[-111.680451,33.393334],[-111.584293,33.378649]]]},"properties":{"id":"85208","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.684539,33.411626],[-111.615345,33.415097],[-111.580626,33.465902],[-111.68413,33.451669],[-111.684516,33.415675],[-111.684539,33.411626]]]},"properties":{"id":"85207","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.686925,33.321674],[-111.686392,33.288682],[-111.583195,33.277383],[-111.583782,33.336641],[-111.584005,33.361793],[-111.687671,33.365018],[-111.695881,33.350527],[-111.686925,33.321674]]]},"properties":{"id":"85212","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.470065,33.537129],[-112.384614,33.551508],[-112.358913,33.551305],[-112.350443,33.571255],[-112.350515,33.580157],[-112.409617,33.580732],[-112.454999,33.638461],[-112.46033,33.638445],[-112.478714,33.638449],[-112.470065,33.537129]]]},"properties":{"id":"85355","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.073307,33.392248],[-112.10046,33.344858],[-112.100457,33.333046],[-112.050239,33.342734],[-111.978438,33.376618],[-111.978379,33.381813],[-111.978336,33.391115],[-112.073307,33.392248]]]},"properties":{"id":"85042","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.717519,33.726778],[-111.683404,33.709754],[-111.296109,33.661929],[-111.405369,33.881935],[-111.494778,33.999817],[-111.666941,33.800884],[-111.717519,33.726778]]]},"properties":{"id":"85263","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.439181,33.782212],[-112.558748,33.696925],[-112.478714,33.638449],[-112.46033,33.638445],[-112.402718,33.679715],[-112.359601,33.711256],[-112.360188,33.753148],[-112.439181,33.782212]]]},"properties":{"id":"85387","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.51336,33.514415],[-112.599482,33.690309],[-112.6039,33.689598],[-112.735329,33.587964],[-112.744419,33.53799],[-112.461481,33.461789],[-112.461482,33.46209],[-112.51336,33.514415]]]},"properties":{"id":"85396","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.686052,33.266399],[-111.747816,33.210146],[-111.750504,33.204751],[-111.661044,33.117826],[-111.57726,33.118167],[-111.582683,33.218888],[-111.583195,33.277383],[-111.686392,33.288682],[-111.686385,33.28846],[-111.686052,33.266399]]]},"properties":{"id":"85142","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.729254,33.362755],[-112.708742,33.312358],[-112.659566,33.332306],[-112.668149,33.363706],[-112.729254,33.362755]]]},"properties":{"id":"85343","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.221005,35.573317],[-110.19169,35.25794],[-109.843696,35.170841],[-109.843728,35.238579],[-110.000674,35.662657],[-110.221005,35.573317]]]},"properties":{"id":"86031","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.548022,31.930873],[-111.21799,32.204526],[-111.278656,32.219348],[-111.474272,32.307731],[-111.744019,32.506695],[-111.744045,32.506695],[-112.203719,32.50676],[-112.4181,31.757436],[-111.623115,31.507912],[-111.594958,31.769453],[-111.548022,31.930873]]]},"properties":{"id":"85634","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.961085,32.28902],[-110.965828,32.222991],[-110.960902,32.206851],[-110.947963,32.206903],[-110.935154,32.207089],[-110.93725,32.28172],[-110.961085,32.28902]],[[-110.946783,32.241617],[-110.943986,32.239645],[-110.946768,32.238664],[-110.946783,32.241617]]]},"properties":{"id":"85719","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.005065,32.378476],[-111.012789,32.369168],[-111.012033,32.302944],[-110.961085,32.28902],[-110.961147,32.380901],[-111.005065,32.378476]]]},"properties":{"id":"85704","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.012879,32.423839],[-111.005065,32.378476],[-110.961147,32.380901],[-110.893127,32.413417],[-110.876321,32.45451],[-110.932242,32.453505],[-111.012879,32.423839]]]},"properties":{"id":"85737","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.789712,32.191795],[-110.669837,32.235075],[-110.669949,32.243943],[-110.781295,32.239232],[-110.78974,32.235435],[-110.789712,32.191795]]]},"properties":{"id":"85748","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.806928,31.818323],[-110.807079,31.731746],[-110.642458,31.60417],[-110.632055,31.788934],[-110.757064,31.818039],[-110.806928,31.818323]]]},"properties":{"id":"85637","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.947963,32.206903],[-110.944908,32.202248],[-110.943658,32.205616],[-110.947963,32.206903]]]},"properties":{"id":"85726","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.538228,33.465816],[-111.544559,33.35073],[-111.476986,33.340427],[-111.442165,33.465898],[-111.480144,33.46586],[-111.538228,33.465816]]]},"properties":{"id":"85119","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.43034,33.176473],[-111.516633,33.118252],[-111.436584,32.850419],[-111.426417,32.850293],[-111.142477,32.75764],[-111.075481,32.700812],[-110.892844,32.864157],[-110.904398,32.8808],[-110.962696,33.016942],[-111.182484,33.094647],[-111.43034,33.176473]]]},"properties":{"id":"85132","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.335953,32.588554],[-111.415077,32.541466],[-111.361559,32.502826],[-111.302165,32.548264],[-111.335953,32.588554]]]},"properties":{"id":"85145","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.319453,34.586819],[-113.168263,34.370523],[-112.913101,34.369015],[-112.890396,34.371195],[-112.8513,34.498599],[-112.935744,34.610554],[-113.319453,34.586819]]]},"properties":{"id":"86321","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.685588,34.803403],[-111.818899,34.712911],[-111.853535,34.690632],[-111.553274,34.610104],[-111.552007,34.776857],[-111.685588,34.803403]]]},"properties":{"id":"86335","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.0754,34.740412],[-112.173175,34.785207],[-112.200959,34.779024],[-112.168361,34.692901],[-112.118275,34.657636],[-112.0754,34.740412]]]},"properties":{"id":"86331","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.526856,32.757094],[-114.754614,32.654542],[-114.614061,32.495375],[-114.460025,32.626747],[-114.342531,32.678114],[-114.261668,32.765219],[-113.99348,33.464655],[-114.267822,33.416232],[-114.514246,33.027634],[-114.516454,33.027617],[-114.526856,32.757094]]]},"properties":{"id":"85365","state_code":"04"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.308949,34.430678],[-91.183449,34.398676],[-91.130069,34.383325],[-91.215306,34.472844],[-91.321083,34.454347],[-91.308949,34.430678]]]},"properties":{"id":"72038","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.44464,33.169898],[-91.536336,33.235154],[-91.635668,33.226263],[-91.697305,33.102688],[-91.439697,33.087394],[-91.44464,33.169898]]]},"properties":{"id":"71661","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.499636,36.405446],[-92.523786,36.356681],[-92.44918,36.38025],[-92.499636,36.405446]]]},"properties":{"id":"72651","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.523786,36.356681],[-92.499636,36.405446],[-92.549599,36.386921],[-92.594891,36.355946],[-92.53477,36.350916],[-92.523786,36.356681]]]},"properties":{"id":"72642","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.194852,36.266537],[-94.216248,36.306765],[-94.242714,36.243989],[-94.208767,36.245598],[-94.194852,36.266537]]]},"properties":{"id":"72718","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.147714,36.131361],[-93.942924,36.149259],[-93.943919,36.236178],[-94.000199,36.230122],[-94.148973,36.228952],[-94.153297,36.133343],[-94.147714,36.131361]]]},"properties":{"id":"72764","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.292199,36.431341],[-94.347594,36.444566],[-94.356895,36.402881],[-94.292199,36.431341]]]},"properties":{"id":"72739","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.797305,36.237069],[-93.943919,36.236178],[-93.942924,36.149259],[-93.937454,36.108379],[-93.932767,36.028778],[-93.848965,36.059025],[-93.797305,36.237069]]]},"properties":{"id":"72738","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.882495,36.028076],[-92.86446,36.056807],[-92.887897,36.058186],[-92.887518,36.070248],[-92.768637,36.112715],[-92.77791,36.14424],[-92.838799,36.215779],[-92.998073,36.116229],[-92.882495,36.028076]]]},"properties":{"id":"72633","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.72975,36.424272],[-92.797343,36.473038],[-92.888332,36.490867],[-92.893723,36.452035],[-92.888215,36.333197],[-92.861872,36.274752],[-92.856821,36.299532],[-92.822818,36.29114],[-92.847119,36.241848],[-92.838799,36.215779],[-92.77791,36.14424],[-92.697954,36.106371],[-92.581538,36.065388],[-92.518875,36.06425],[-92.616363,36.378139],[-92.58889,36.397896],[-92.638798,36.453618],[-92.637816,36.461777],[-92.72975,36.424272]],[[-92.700808,36.246447],[-92.681601,36.260067],[-92.683284,36.245558],[-92.700808,36.246447]]]},"properties":{"id":"72687","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.682923,36.449423],[-93.866836,36.376659],[-93.867189,36.365184],[-93.762045,36.304677],[-93.663605,36.28174],[-93.682923,36.449423]]]},"properties":{"id":"72632","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.440588,36.456275],[-93.444504,36.124293],[-93.480178,36.08524],[-93.445687,36.080058],[-93.382558,36.122927],[-93.296199,36.381503],[-93.295759,36.457146],[-93.440588,36.456275]]]},"properties":{"id":"72638","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.355873,33.16834],[-91.160775,33.141399],[-91.230897,33.5614],[-91.242415,33.561452],[-91.245217,33.537856],[-91.45737,33.388974],[-91.459363,33.302729],[-91.355873,33.16834]]]},"properties":{"id":"71653","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.373406,33.95707],[-93.298395,33.862378],[-93.19864,34.03642],[-93.292679,34.153158],[-93.448513,34.15184],[-93.442315,34.100779],[-93.421499,34.042026],[-93.402998,34.007119],[-93.373406,33.95707]]]},"properties":{"id":"71962","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.022874,34.273941],[-93.187209,34.247772],[-93.292679,34.153158],[-93.19864,34.03642],[-92.921979,33.819165],[-92.896495,33.806162],[-92.83754,34.083209],[-92.838423,34.153824],[-92.856126,34.154513],[-93.022874,34.273941]],[[-93.052327,34.124549],[-93.053198,34.126217],[-93.050738,34.126423],[-93.052327,34.124549]],[[-93.057049,34.125469],[-93.06107,34.125539],[-93.06101,34.129479],[-93.057204,34.12942],[-93.057049,34.125469]]]},"properties":{"id":"71923","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.118449,35.52265],[-92.06142,35.401438],[-91.938911,35.359534],[-91.866852,35.396731],[-91.880824,35.455465],[-91.892378,35.512083],[-91.92795,35.536382],[-92.034731,35.538096],[-92.107422,35.526209],[-92.118449,35.52265]]]},"properties":{"id":"72543","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.159091,33.850716],[-92.038555,33.70446],[-91.975974,33.791787],[-92.00942,34.013165],[-92.233376,34.062312],[-92.336765,34.059633],[-92.159091,33.850716]]]},"properties":{"id":"71665","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.592015,33.219456],[-93.598691,33.111192],[-93.610862,33.019115],[-93.392917,33.0183],[-93.359203,33.088524],[-93.392873,33.206522],[-93.592015,33.219456]]]},"properties":{"id":"71861","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.63104,35.731684],[-92.703364,35.676984],[-92.743585,35.570774],[-92.60248,35.458455],[-92.607026,35.437967],[-92.506672,35.438219],[-92.361037,35.533449],[-92.338395,35.74126],[-92.347647,35.771799],[-92.470401,35.789075],[-92.534228,35.675645],[-92.63104,35.731684]]]},"properties":{"id":"72031","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.90028,35.023],[-92.909008,35.084951],[-92.978902,35.118847],[-93.080019,35.08219],[-93.0552,34.99737],[-92.90028,35.023]]]},"properties":{"id":"72025","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.470649,35.943442],[-90.517403,35.878071],[-90.535423,35.79356],[-90.527371,35.706677],[-90.463505,35.705009],[-90.39238,35.778043],[-90.35661,35.876231],[-90.386245,35.984187],[-90.470649,35.943442]]]},"properties":{"id":"72437","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.700673,36.18007],[-90.742663,36.052171],[-90.709195,35.991345],[-90.62281,35.965922],[-90.470649,35.943442],[-90.386245,35.984187],[-90.37789,35.995683],[-90.235569,36.141707],[-90.34165,36.146346],[-90.491597,36.199482],[-90.520479,36.261444],[-90.606731,36.265273],[-90.700673,36.18007]]]},"properties":{"id":"72450","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.47475,35.650702],[-94.462197,35.574894],[-94.404696,35.611746],[-94.441479,35.639767],[-94.47475,35.650702]]]},"properties":{"id":"72955","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.356895,35.62682],[-94.357409,35.560346],[-94.27434,35.508465],[-94.234093,35.568848],[-94.253432,35.608358],[-94.356895,35.62682]]]},"properties":{"id":"72952","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.310083,35.001041],[-90.401972,35.061206],[-90.534606,35.0003],[-90.60565,34.992273],[-90.619817,34.906427],[-90.53434,34.90024],[-90.407991,34.83297],[-90.310083,35.001041]]]},"properties":{"id":"72348","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.102023,35.429039],[-91.040218,35.287935],[-90.932287,35.323439],[-90.89477,35.44309],[-91.039096,35.486882],[-91.07409,35.49419],[-91.102023,35.429039]]]},"properties":{"id":"72347","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.04267,35.148189],[-90.734123,35.141584],[-90.689415,35.105973],[-90.636971,35.146949],[-90.65038,35.360423],[-90.932287,35.323439],[-91.040218,35.287935],[-91.041925,35.192824],[-91.04267,35.148189]]]},"properties":{"id":"72396","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.354773,33.873456],[-91.26463,33.77575],[-91.286897,33.737363],[-91.2416,33.6958],[-91.224858,33.688226],[-91.051492,33.777443],[-91.037733,33.810846],[-91.027415,33.907002],[-91.137038,33.953187],[-91.352533,33.956535],[-91.354773,33.873456]]]},"properties":{"id":"71674","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.560971,35.035417],[-92.41887,34.989468],[-92.514945,35.130447],[-92.554685,35.113277],[-92.560971,35.035417]],[[-92.460844,35.081212],[-92.454123,35.081158],[-92.454325,35.075731],[-92.460919,35.07754],[-92.460844,35.081212]]]},"properties":{"id":"72034","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.460844,35.081212],[-92.460919,35.07754],[-92.454325,35.075731],[-92.454123,35.081158],[-92.460844,35.081212]]]},"properties":{"id":"72035","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.968019,35.469291],[-93.915266,35.377287],[-93.856999,35.379261],[-93.809525,35.337522],[-93.756458,35.369129],[-93.749295,35.364572],[-93.705473,35.500342],[-93.67353,35.52002],[-93.64512,35.56435],[-93.601204,35.719191],[-93.695981,35.768649],[-93.715566,35.769244],[-93.85804,35.773108],[-93.861142,35.773185],[-93.931001,35.666846],[-93.968019,35.469291]]]},"properties":{"id":"72949","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.51298,35.261771],[-93.640729,35.337865],[-93.648623,35.370427],[-93.756458,35.369129],[-93.809525,35.337522],[-93.918701,35.267895],[-93.919034,35.258331],[-93.662979,35.148225],[-93.511234,35.165943],[-93.483889,35.191747],[-93.51298,35.261771]]]},"properties":{"id":"72855","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.70881,34.473562],[-92.669369,34.387239],[-92.591334,34.297634],[-92.609969,34.383863],[-92.70881,34.473562]]]},"properties":{"id":"72167","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.291754,34.255111],[-92.394041,34.104489],[-92.336765,34.059633],[-92.233376,34.062312],[-92.257209,34.17768],[-92.291754,34.255111]]]},"properties":{"id":"72057","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.697312,33.698535],[-93.817109,33.776913],[-93.830861,33.769932],[-93.960726,33.694577],[-93.915575,33.632895],[-93.825884,33.609853],[-93.733843,33.497248],[-93.697312,33.698535]]]},"properties":{"id":"71838","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.473022,33.774469],[-93.482468,33.497435],[-93.469493,33.493003],[-93.372618,33.642434],[-93.473022,33.774469]]]},"properties":{"id":"71835","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.044402,34.333779],[-92.856126,34.154513],[-92.838423,34.153824],[-92.671772,34.294587],[-92.669369,34.387239],[-92.70881,34.473562],[-92.735254,34.473844],[-92.769575,34.54298],[-92.879894,34.505431],[-92.92612,34.44859],[-93.11375,34.35307],[-93.044402,34.333779]]]},"properties":{"id":"72104","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.242574,34.192542],[-94.238663,34.144781],[-94.066655,33.999235],[-93.878919,34.137413],[-93.89368,34.185672],[-94.031285,34.275803],[-94.103247,34.238443],[-94.242574,34.192542]]]},"properties":{"id":"71833","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.244023,34.258991],[-94.127633,34.250976],[-94.16071,34.297311],[-94.254898,34.346195],[-94.281614,34.350182],[-94.46637,34.332686],[-94.467993,34.272835],[-94.244023,34.258991]]]},"properties":{"id":"71973","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.624945,36.027876],[-91.709573,35.976039],[-91.710552,35.941759],[-91.503346,35.862847],[-91.432772,35.85432],[-91.427543,35.855778],[-91.408604,35.93213],[-91.39167,35.979927],[-91.467484,36.018164],[-91.516444,36.025072],[-91.624945,36.027876]]]},"properties":{"id":"72521","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.701093,35.727122],[-91.659341,35.758196],[-91.689664,35.7521],[-91.701093,35.727122]]]},"properties":{"id":"72527","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.440956,35.639903],[-91.369391,35.547804],[-91.349952,35.44497],[-91.250339,35.35755],[-91.252698,35.429872],[-91.217282,35.429508],[-91.18836,35.37143],[-91.122915,35.436881],[-91.102023,35.429039],[-91.07409,35.49419],[-91.09194,35.58994],[-91.091957,35.613304],[-91.06985,35.64952],[-91.05526,35.64933],[-91.036967,35.708418],[-91.036762,35.723012],[-91.19635,35.81464],[-91.248007,35.831243],[-91.30064,35.766817],[-91.440956,35.639903]]]},"properties":{"id":"72112","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.709573,35.976039],[-91.624945,36.027876],[-91.744135,36.078534],[-91.786875,36.045181],[-91.78774,36.022346],[-91.709573,35.976039]]]},"properties":{"id":"72577","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.840841,36.075538],[-91.890386,36.119776],[-92.066933,36.069165],[-92.097615,36.014755],[-91.948024,35.924218],[-91.866859,35.961596],[-91.78774,36.022346],[-91.786875,36.045181],[-91.840841,36.075538]]]},"properties":{"id":"72556","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.09194,35.58994],[-91.07409,35.49419],[-91.039096,35.486882],[-91.039022,35.520378],[-91.09194,35.58994]]]},"properties":{"id":"72005","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.749867,34.402339],[-91.8251,34.308984],[-91.710096,34.29438],[-91.749867,34.402339]]]},"properties":{"id":"72175","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.67353,35.52002],[-93.550801,35.400219],[-93.434761,35.427504],[-93.382922,35.582124],[-93.545184,35.590493],[-93.64512,35.56435],[-93.67353,35.52002]]]},"properties":{"id":"72830","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.517341,35.81692],[-93.545184,35.590493],[-93.382922,35.582124],[-93.36721,35.58818],[-93.306448,35.779017],[-93.445382,35.829458],[-93.517341,35.81692]]]},"properties":{"id":"72854","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.046391,34.911926],[-91.108917,34.867127],[-91.103287,34.73488],[-91.104029,34.710326],[-91.10408,34.704934],[-91.050116,34.704044],[-90.90785,34.877],[-91.046391,34.911926]]]},"properties":{"id":"72368","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.445614,34.080147],[-91.606935,34.112271],[-91.608803,34.112273],[-91.709735,34.034405],[-91.657349,33.922003],[-91.421715,34.014472],[-91.445614,34.080147]]]},"properties":{"id":"71643","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.640729,35.337865],[-93.51298,35.261771],[-93.52695,35.305469],[-93.640729,35.337865]]]},"properties":{"id":"72865","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963303,34.668518],[-91.83208,34.635016],[-91.803272,34.954339],[-91.838959,34.940514],[-91.941293,34.922802],[-92.022194,34.861045],[-92.051042,34.817679],[-91.963303,34.668518]]]},"properties":{"id":"72086","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.941293,34.922802],[-91.838959,34.940514],[-91.985108,34.999984],[-91.941293,34.922802]]],[[[-92.120386,35.044385],[-92.003,34.996872],[-91.950873,35.045278],[-92.014875,35.075471],[-92.119719,35.066493],[-92.120386,35.044385]]]]},"properties":{"id":"72007","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.029493,34.624477],[-91.963303,34.668518],[-92.051042,34.817679],[-92.075601,34.842878],[-92.156994,34.667548],[-92.159442,34.562317],[-92.02954,34.621403],[-92.029493,34.624477]]]},"properties":{"id":"72142","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.932767,36.028778],[-93.85869,35.976658],[-93.848965,36.059025],[-93.932767,36.028778]]]},"properties":{"id":"72773","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.616363,36.378139],[-92.518875,36.06425],[-92.411502,36.062756],[-92.410819,36.084252],[-92.425948,36.17833],[-92.474487,36.256876],[-92.520581,36.268824],[-92.534812,36.329314],[-92.53477,36.350916],[-92.594891,36.355946],[-92.616363,36.378139]]]},"properties":{"id":"72634","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.716373,36.460884],[-92.797343,36.473038],[-92.72975,36.424272],[-92.716373,36.460884]]]},"properties":{"id":"72668","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.716373,36.460884],[-92.72975,36.424272],[-92.637816,36.461777],[-92.630626,36.498155],[-92.735102,36.497992],[-92.716373,36.460884]]]},"properties":{"id":"65761","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.778204,33.361594],[-93.702603,33.251402],[-93.70366,33.357322],[-93.778204,33.361594]]]},"properties":{"id":"71839","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.214741,35.481227],[-90.215505,35.437609],[-90.17478,35.437074],[-90.214741,35.481227]]]},"properties":{"id":"72338","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.249967,35.736217],[-90.213142,35.728302],[-90.177825,35.733571],[-90.249967,35.736217]]]},"properties":{"id":"72428","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.690193,34.646352],[-93.57361,34.616302],[-93.560289,34.677524],[-93.720372,34.674255],[-93.690193,34.646352]]]},"properties":{"id":"71969","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.052878,36.039533],[-93.106122,36.035977],[-93.065659,35.959125],[-93.05521,35.958667],[-93.036199,35.987225],[-93.052878,36.039533]]]},"properties":{"id":"72640","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.242162,35.89708],[-93.265529,35.978101],[-93.339505,35.907071],[-93.242162,35.89708]]]},"properties":{"id":"72666","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.746536,34.911178],[-92.79258,35.03406],[-92.857389,35.033672],[-92.90028,35.023],[-93.0552,34.99737],[-93.160236,34.975991],[-93.035869,34.858525],[-92.817713,34.847244],[-92.67846,34.852213],[-92.709859,34.885034],[-92.746536,34.911178]]]},"properties":{"id":"72126","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.0552,34.99737],[-93.080019,35.08219],[-93.175571,35.0594],[-93.392002,35.00572],[-93.358192,34.975464],[-93.160236,34.975991],[-93.0552,34.99737]]]},"properties":{"id":"72853","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.602402,34.592529],[-90.658003,34.64174],[-90.666518,34.641779],[-90.768242,34.555073],[-90.769131,34.534038],[-90.719465,34.4853],[-90.602402,34.592529]]]},"properties":{"id":"72390","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.874295,34.643483],[-90.857964,34.482245],[-90.769131,34.534038],[-90.768242,34.555073],[-90.83866,34.64313],[-90.874295,34.643483]]]},"properties":{"id":"72374","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.450957,34.830693],[-94.459995,34.549885],[-94.168332,34.401876],[-94.170442,34.39728],[-94.163565,34.397137],[-93.934882,34.393754],[-93.934689,34.435721],[-93.933008,34.521468],[-93.930509,34.665608],[-94.130483,34.810481],[-94.450957,34.830693]]]},"properties":{"id":"71953","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.390905,34.763478],[-92.390971,34.742983],[-92.302021,34.743341],[-92.301934,34.743506],[-92.293371,34.754301],[-92.321835,34.768293],[-92.358456,34.762287],[-92.390905,34.763478]]]},"properties":{"id":"72205","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.301934,34.743506],[-92.258349,34.748391],[-92.290164,34.756489],[-92.293371,34.754301],[-92.301934,34.743506]]]},"properties":{"id":"72201","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.309151,34.783791],[-92.290164,34.756489],[-92.258349,34.748391],[-92.208547,34.743591],[-92.236904,34.777549],[-92.277675,34.785497],[-92.309151,34.783791]]]},"properties":{"id":"72114","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.521835,34.581276],[-92.516964,34.453882],[-92.395088,34.49564],[-92.403779,34.508132],[-92.411678,34.571017],[-92.486535,34.569827],[-92.521835,34.581276]]]},"properties":{"id":"72011","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.04373,34.79829],[-93.877996,34.687265],[-93.710297,34.745296],[-93.709837,34.768658],[-93.823107,34.876695],[-94.04373,34.79829]]]},"properties":{"id":"72950","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.292515,36.374746],[-91.459421,36.164292],[-91.45079,36.15046],[-91.351214,36.190637],[-91.292515,36.374746]]]},"properties":{"id":"72482","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.532515,33.345488],[-92.548211,33.321344],[-92.522856,33.316502],[-92.532515,33.345488]]]},"properties":{"id":"71724","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.135249,33.090688],[-92.34486,33.01065],[-92.069105,33.008163],[-92.135249,33.090688]]]},"properties":{"id":"71747","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497764,33.012229],[-92.34486,33.01065],[-92.135249,33.090688],[-92.137527,33.162956],[-92.32648,33.264174],[-92.497764,33.012229]]]},"properties":{"id":"71765","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.317199,36.050787],[-94.232733,35.99929],[-94.22433,36.04695],[-94.317199,36.050787]]]},"properties":{"id":"72730","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.216228,35.945854],[-94.372643,35.757187],[-94.341566,35.75654],[-94.269178,35.754381],[-94.064016,35.892568],[-94.052567,35.926293],[-94.216228,35.945854]]]},"properties":{"id":"72774","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.441686,35.265495],[-91.634191,35.442972],[-91.589671,35.264107],[-91.554606,35.206825],[-91.436001,35.181873],[-91.427813,35.193684],[-91.441686,35.265495]],[[-91.515701,35.360809],[-91.511323,35.364914],[-91.498594,35.366703],[-91.508987,35.354275],[-91.515701,35.360809]]]},"properties":{"id":"72010","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.101746,35.274639],[-92.080437,35.171715],[-91.967529,35.19128],[-91.97625,35.311966],[-92.101746,35.274639]]]},"properties":{"id":"72136","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.276372,35.18248],[-91.334892,35.131428],[-91.299171,35.093398],[-91.276372,35.18248]]]},"properties":{"id":"72059","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626861,34.927509],[-93.482794,34.899582],[-93.502731,34.946999],[-93.638455,34.947539],[-93.626861,34.927509]]]},"properties":{"id":"72828","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.511234,35.165943],[-93.531268,35.078806],[-93.296632,35.142325],[-93.416553,35.214757],[-93.483889,35.191747],[-93.511234,35.165943]]]},"properties":{"id":"72824","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.441031,34.107992],[-91.445614,34.080147],[-91.421715,34.014472],[-91.329405,34.004538],[-91.269584,34.155578],[-91.441031,34.107992]]]},"properties":{"id":"72055","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.81535,33.42751],[-91.890448,33.454148],[-91.990167,33.398214],[-91.95995,33.378264],[-91.81535,33.42751]]]},"properties":{"id":"71642","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.411502,36.062756],[-92.414324,35.975865],[-92.40243,35.975681],[-92.354284,35.974773],[-92.305882,36.034451],[-92.3058,36.041856],[-92.371051,36.078064],[-92.410819,36.084252],[-92.411502,36.062756]]]},"properties":{"id":"72617","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.32486,36.377341],[-94.281476,36.387413],[-94.253673,36.350731],[-94.33123,36.352024],[-94.344912,36.334076],[-94.367586,36.312527],[-94.297741,36.216762],[-94.242714,36.243989],[-94.216248,36.306765],[-94.183634,36.335049],[-94.141242,36.410544],[-94.162355,36.472168],[-94.233386,36.418902],[-94.292199,36.431341],[-94.356895,36.402881],[-94.32486,36.377341]]]},"properties":{"id":"72712","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.041823,36.072967],[-93.052878,36.039533],[-93.036199,35.987225],[-92.940932,35.990069],[-92.882495,36.028076],[-92.998073,36.116229],[-93.041823,36.072967]]]},"properties":{"id":"72685","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.402815,33.504478],[-92.39569,33.48386],[-92.38647,33.49657],[-92.402815,33.504478]]]},"properties":{"id":"71745","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.398908,33.695264],[-92.513273,33.800214],[-92.62451,33.69626],[-92.52239,33.62789],[-92.398908,33.695264]]]},"properties":{"id":"71766","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.701869,33.649668],[-92.7221,33.651358],[-92.71572,33.62854],[-92.701869,33.649668]]]},"properties":{"id":"71711","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.05099,33.52889],[-92.913929,33.434943],[-92.664097,33.434745],[-92.650375,33.594602],[-92.71572,33.62854],[-92.7221,33.651358],[-92.757456,33.792466],[-92.863841,33.791421],[-93.05099,33.52889]]]},"properties":{"id":"71701","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.100931,35.708956],[-92.105527,35.616217],[-92.024136,35.613566],[-92.023227,35.707529],[-92.100931,35.708956]]]},"properties":{"id":"72130","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.709894,35.414285],[-91.711416,35.533219],[-91.793958,35.534114],[-91.880824,35.455465],[-91.866852,35.396731],[-91.709894,35.414285]]]},"properties":{"id":"72121","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.316967,35.550872],[-92.301262,35.61706],[-92.219285,35.589277],[-92.24052,35.71219],[-92.268242,35.727069],[-92.338395,35.74126],[-92.361037,35.533449],[-92.316967,35.550872]]]},"properties":{"id":"72153","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.844712,35.088061],[-92.709585,35.072171],[-92.641628,35.108971],[-92.61827,35.235761],[-92.681397,35.242242],[-92.732708,35.263423],[-92.782593,35.223767],[-92.896109,35.170678],[-92.978902,35.118847],[-92.909008,35.084951],[-92.844712,35.088061]]]},"properties":{"id":"72110","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.659822,35.327364],[-92.732708,35.263423],[-92.681397,35.242242],[-92.626566,35.293576],[-92.659822,35.327364]]]},"properties":{"id":"72156","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.709585,35.072171],[-92.844712,35.088061],[-92.857389,35.033672],[-92.79258,35.03406],[-92.709585,35.072171]]]},"properties":{"id":"72125","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.356895,35.62682],[-94.253432,35.608358],[-94.205941,35.752849],[-94.269178,35.754381],[-94.341566,35.75654],[-94.359575,35.647799],[-94.356895,35.62682]]]},"properties":{"id":"72934","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.131447,35.508255],[-94.13348,35.475198],[-94.097142,35.481626],[-94.131447,35.508255]]]},"properties":{"id":"72935","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.04267,35.148189],[-91.043801,35.07344],[-90.893196,35.038645],[-90.836795,35.060557],[-90.83476,35.07392],[-90.815529,35.065943],[-90.734123,35.141584],[-91.04267,35.148189]]]},"properties":{"id":"72326","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.403456,35.14814],[-90.466819,35.14496],[-90.601138,35.17517],[-90.627889,35.146738],[-90.534606,35.0003],[-90.401972,35.061206],[-90.403456,35.14814]]]},"properties":{"id":"72346","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.657349,33.922003],[-91.557648,33.783384],[-91.524692,33.781213],[-91.4772,33.780496],[-91.480093,33.802391],[-91.53712,33.83275],[-91.52838,33.86465],[-91.470193,33.846043],[-91.354773,33.873456],[-91.352533,33.956535],[-91.329405,34.004538],[-91.421715,34.014472],[-91.657349,33.922003]]]},"properties":{"id":"71639","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.452254,33.680333],[-91.453438,33.563703],[-91.280523,33.539198],[-91.452254,33.680333]]]},"properties":{"id":"71654","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.17904,35.304469],[-94.028547,35.213826],[-93.955051,35.226584],[-93.954862,35.229962],[-93.883405,35.34973],[-93.856999,35.379261],[-93.915266,35.377287],[-94.075855,35.423035],[-94.17904,35.304469]]]},"properties":{"id":"72933","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.883405,35.34973],[-93.918701,35.267895],[-93.809525,35.337522],[-93.856999,35.379261],[-93.883405,35.34973]]]},"properties":{"id":"72951","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.858181,36.498805],[-91.890872,36.407259],[-92.015949,36.288891],[-92.017674,36.257976],[-91.961259,36.256874],[-91.861767,36.255813],[-91.788218,36.254631],[-91.736886,36.253922],[-91.740981,36.275578],[-91.754455,36.349468],[-91.742251,36.443397],[-91.75927,36.499208],[-91.858181,36.498805]]]},"properties":{"id":"72576","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.084689,34.403132],[-92.250935,34.418308],[-92.210146,34.3473],[-92.139524,34.375402],[-92.068096,34.367094],[-92.084689,34.403132]]]},"properties":{"id":"72079","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.827616,33.851977],[-93.830861,33.769932],[-93.817109,33.776913],[-93.679149,33.8315],[-93.635244,33.858618],[-93.720544,33.913266],[-93.827616,33.851977]]]},"properties":{"id":"71855","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.679149,33.8315],[-93.817109,33.776913],[-93.697312,33.698535],[-93.679149,33.8315]]]},"properties":{"id":"71862","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.768668,34.137715],[-93.878919,34.137413],[-94.066655,33.999235],[-94.053627,33.900683],[-93.827616,33.851977],[-93.720544,33.913266],[-93.562022,33.958031],[-93.65021,33.999312],[-93.768668,34.137715]]]},"properties":{"id":"71852","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.880507,34.299854],[-94.031285,34.275803],[-93.89368,34.185672],[-93.810431,34.241631],[-93.808875,34.254331],[-93.880507,34.299854]]]},"properties":{"id":"71959","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.827616,33.851977],[-94.053627,33.900683],[-93.963707,33.775122],[-93.830861,33.769932],[-93.827616,33.851977]]]},"properties":{"id":"71851","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.724126,35.658846],[-91.60602,35.614777],[-91.579479,35.617239],[-91.480384,35.643485],[-91.440956,35.639903],[-91.459758,35.752276],[-91.493399,35.80254],[-91.467378,35.840218],[-91.503346,35.862847],[-91.710552,35.941759],[-91.849448,35.867411],[-91.854368,35.834008],[-91.836081,35.748855],[-91.659341,35.758196],[-91.701093,35.727122],[-91.724126,35.658846]],[[-91.483788,35.703856],[-91.476055,35.70301],[-91.476103,35.696335],[-91.483393,35.696431],[-91.483788,35.703856]],[[-91.752429,35.865007],[-91.792912,35.898274],[-91.759542,35.900099],[-91.752429,35.865007]]]},"properties":{"id":"72501","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.634191,35.442972],[-91.441686,35.265495],[-91.411863,35.265072],[-91.349952,35.44497],[-91.369391,35.547804],[-91.416996,35.569805],[-91.49344,35.581137],[-91.533428,35.572044],[-91.627051,35.531814],[-91.634191,35.442972]]]},"properties":{"id":"72020","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.416996,35.569805],[-91.47329,35.590764],[-91.49344,35.581137],[-91.416996,35.569805]]]},"properties":{"id":"72165","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.35723,35.890621],[-91.223081,35.890403],[-91.309717,35.924803],[-91.35723,35.890621]]]},"properties":{"id":"72572","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.894724,35.823877],[-91.854368,35.834008],[-91.849448,35.867411],[-91.872643,35.881118],[-91.948024,35.924218],[-92.097615,36.014755],[-92.3058,36.041856],[-92.305882,36.034451],[-92.215581,35.909621],[-92.275157,35.844789],[-92.268242,35.727069],[-92.24052,35.71219],[-92.100931,35.708956],[-92.023227,35.707529],[-91.881715,35.705681],[-91.88061,35.733778],[-91.924195,35.789109],[-91.975612,35.836965],[-91.894724,35.823877]]]},"properties":{"id":"72560","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.769314,36.107157],[-91.714491,36.141409],[-91.744231,36.182711],[-91.783541,36.196253],[-91.818948,36.19658],[-91.804378,36.115303],[-91.769314,36.107157]]]},"properties":{"id":"72536","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.122915,35.436881],[-91.119438,35.42743],[-91.102023,35.429039],[-91.122915,35.436881]]]},"properties":{"id":"72014","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.838337,33.148692],[-93.80493,33.019357],[-93.610862,33.019115],[-93.598691,33.111192],[-93.726249,33.181989],[-93.838337,33.148692]]]},"properties":{"id":"71826","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.893196,35.038645],[-91.043801,35.07344],[-91.044885,35.023164],[-91.04587,34.96972],[-91.014134,34.973652],[-91.013285,34.910181],[-91.046391,34.911926],[-90.90785,34.877],[-90.809743,34.860401],[-90.841121,34.906282],[-90.893196,35.038645]]]},"properties":{"id":"72372","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.479813,33.856711],[-94.306867,33.824336],[-94.304174,33.824116],[-94.387182,33.919256],[-94.477299,33.940932],[-94.479813,33.856711]]]},"properties":{"id":"71866","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.148911,33.791462],[-94.093161,33.786965],[-93.958828,33.750994],[-93.963707,33.775122],[-94.053627,33.900683],[-94.066655,33.999235],[-94.238663,34.144781],[-94.206331,34.104653],[-94.222602,33.971314],[-94.231637,33.801663],[-94.181932,33.788779],[-94.148911,33.791462]]]},"properties":{"id":"71846","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.874044,34.534629],[-91.873886,34.539646],[-91.878141,34.539074],[-91.878312,34.53469],[-91.874044,34.534629]]]},"properties":{"id":"72037","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.73762,35.857705],[-93.659925,35.870176],[-93.559015,35.888061],[-93.557362,35.952919],[-93.73723,35.883334],[-93.73762,35.857705]]]},"properties":{"id":"72776","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.832535,35.823342],[-93.715566,35.769244],[-93.695981,35.768649],[-93.659925,35.870176],[-93.73762,35.857705],[-93.832535,35.823342]]]},"properties":{"id":"72760","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.141364,35.915985],[-90.288947,35.99641],[-90.286742,35.845026],[-90.141364,35.915985]]]},"properties":{"id":"72438","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.044263,35.861413],[-90.038484,35.867335],[-90.039088,35.877521],[-90.044517,35.884952],[-90.044263,35.861413]]]},"properties":{"id":"72426","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.157553,34.713379],[-91.17646,34.680003],[-91.104118,34.701322],[-91.10408,34.704934],[-91.104029,34.710326],[-91.157553,34.713379]]],[[[-91.36856,34.912735],[-91.370598,34.824276],[-91.298244,34.73816],[-91.157068,34.726134],[-91.103287,34.73488],[-91.108917,34.867127],[-91.11631,35.03117],[-91.184737,35.003159],[-91.36856,34.912735]]]]},"properties":{"id":"72021","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.11631,35.03117],[-91.108917,34.867127],[-91.046391,34.911926],[-91.04587,34.96972],[-91.044885,35.023164],[-91.062851,35.023258],[-91.11631,35.03117]]]},"properties":{"id":"72392","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.104118,34.701322],[-91.050953,34.674677],[-91.050116,34.704044],[-91.10408,34.704934],[-91.104118,34.701322]]],[[[-91.103287,34.73488],[-91.157068,34.726134],[-91.157553,34.713379],[-91.104029,34.710326],[-91.103287,34.73488]]]]},"properties":{"id":"72108","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.059076,34.263306],[-91.001036,34.263263],[-90.950218,34.340801],[-91.002294,34.350644],[-91.037511,34.336482],[-91.056276,34.336605],[-91.065059,34.263592],[-91.059076,34.263306]]]},"properties":{"id":"72353","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.65021,33.999312],[-93.562022,33.958031],[-93.526033,33.943637],[-93.373406,33.95707],[-93.402998,34.007119],[-93.437686,34.019752],[-93.421499,34.042026],[-93.442315,34.100779],[-93.539674,34.117813],[-93.65021,33.999312]]]},"properties":{"id":"71940","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.875752,35.536246],[-91.039022,35.520378],[-91.039096,35.486882],[-90.89477,35.44309],[-90.82382,35.44575],[-90.875752,35.536246]]]},"properties":{"id":"72429","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.270634,34.435221],[-94.281614,34.350182],[-94.254898,34.346195],[-94.163565,34.397137],[-94.170442,34.39728],[-94.270634,34.435221]]]},"properties":{"id":"71972","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.244023,34.258991],[-94.467993,34.272835],[-94.368372,34.190485],[-94.244068,34.213],[-94.244023,34.258991]]]},"properties":{"id":"71944","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.951729,35.253773],[-93.07796,35.178786],[-93.080891,35.166197],[-93.038198,35.157629],[-92.951729,35.253773]]]},"properties":{"id":"72858","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.506745,35.090207],[-91.69664,35.062278],[-91.690622,35.05906],[-91.506745,35.090207]]]},"properties":{"id":"72060","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.417057,34.649236],[-92.354904,34.639406],[-92.282661,34.707677],[-92.334977,34.705557],[-92.404538,34.676478],[-92.444562,34.640822],[-92.444571,34.640625],[-92.417057,34.649236]]]},"properties":{"id":"72209","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.544341,34.953319],[-92.709859,34.885034],[-92.67846,34.852213],[-92.652557,34.813204],[-92.444877,34.846919],[-92.544341,34.953319]]]},"properties":{"id":"72135","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.722566,35.984983],[-92.717202,35.98479],[-92.710556,35.992195],[-92.718472,35.99476],[-92.722566,35.984983]]]},"properties":{"id":"72636","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.45079,36.15046],[-91.516444,36.025072],[-91.467484,36.018164],[-91.403557,36.120169],[-91.351214,36.190637],[-91.45079,36.15046]]]},"properties":{"id":"72569","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.268242,35.727069],[-92.275157,35.844789],[-92.367467,35.853126],[-92.347647,35.771799],[-92.338395,35.74126],[-92.268242,35.727069]]]},"properties":{"id":"72051","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.980135,33.320874],[-92.94745,33.245977],[-92.832927,33.2759],[-92.884004,33.374702],[-92.908267,33.375676],[-92.980135,33.320874]]]},"properties":{"id":"71758","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.825112,35.360571],[-91.825093,35.369063],[-91.833891,35.369076],[-91.831972,35.358132],[-91.825112,35.360571]]]},"properties":{"id":"72085","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.183449,34.398676],[-91.15483,34.35442],[-91.1227,34.375732],[-91.130069,34.383325],[-91.183449,34.398676]]]},"properties":{"id":"72140","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.379428,36.497412],[-92.311157,36.404414],[-92.259457,36.498048],[-92.379428,36.497412]]]},"properties":{"id":"72623","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.23103,36.352067],[-92.232124,36.265836],[-92.15699,36.261248],[-92.108851,36.260051],[-92.052913,36.302813],[-92.073009,36.390329],[-92.153192,36.383641],[-92.23103,36.352067]]]},"properties":{"id":"72531","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.153297,36.133343],[-94.148973,36.228952],[-94.208767,36.245598],[-94.242714,36.243989],[-94.297741,36.216762],[-94.331891,36.21468],[-94.33488,36.180757],[-94.153297,36.133343]]]},"properties":{"id":"72762","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.33123,36.352024],[-94.253673,36.350731],[-94.281476,36.387413],[-94.32486,36.377341],[-94.33123,36.352024]]]},"properties":{"id":"72719","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.605455,36.421938],[-94.561312,36.35879],[-94.344912,36.334076],[-94.33123,36.352024],[-94.32486,36.377341],[-94.356895,36.402881],[-94.347594,36.444566],[-94.373945,36.476475],[-94.613834,36.476273],[-94.605455,36.421938]]]},"properties":{"id":"72736","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.579211,36.258379],[-94.331891,36.21468],[-94.297741,36.216762],[-94.367586,36.312527],[-94.590638,36.334859],[-94.591644,36.334868],[-94.579211,36.258379]]]},"properties":{"id":"72734","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.605455,36.421938],[-94.591644,36.334868],[-94.590638,36.334859],[-94.561312,36.35879],[-94.605455,36.421938]]]},"properties":{"id":"72747","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.383813,36.499449],[-94.613834,36.476273],[-94.373945,36.476475],[-94.383813,36.499449]]]},"properties":{"id":"72768","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.196606,33.471786],[-92.007869,33.529404],[-91.969501,33.791817],[-91.975974,33.791787],[-92.038555,33.70446],[-92.184267,33.705286],[-92.196606,33.471786]]]},"properties":{"id":"71671","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.890448,33.454148],[-91.896249,33.791657],[-91.969501,33.791817],[-92.007869,33.529404],[-91.990167,33.398214],[-91.890448,33.454148]]]},"properties":{"id":"71675","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.445687,36.080058],[-93.402694,35.974216],[-93.341244,36.050296],[-93.25018,36.065105],[-93.266314,36.076641],[-93.299902,36.178483],[-93.382558,36.122927],[-93.445687,36.080058]]]},"properties":{"id":"72624","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.578643,33.67362],[-91.645619,33.508098],[-91.587221,33.453055],[-91.641433,33.392195],[-91.45737,33.388974],[-91.245217,33.537856],[-91.280523,33.539198],[-91.453438,33.563703],[-91.452254,33.680333],[-91.578643,33.67362]]]},"properties":{"id":"71638","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.828297,35.566409],[-91.859685,35.705313],[-91.881715,35.705681],[-92.023227,35.707529],[-92.024136,35.613566],[-92.107422,35.526209],[-92.034731,35.538096],[-91.970392,35.564359],[-91.958266,35.584909],[-91.934663,35.565248],[-91.92795,35.536382],[-91.892378,35.512083],[-91.839004,35.538984],[-91.828297,35.566409]]]},"properties":{"id":"72530","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.934663,35.565248],[-91.958266,35.584909],[-91.970392,35.564359],[-91.934663,35.565248]]]},"properties":{"id":"72546","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.892378,35.512083],[-91.880824,35.455465],[-91.793958,35.534114],[-91.839004,35.538984],[-91.892378,35.512083]]]},"properties":{"id":"72179","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.896249,33.791657],[-91.592587,33.786401],[-91.557648,33.783384],[-91.657349,33.922003],[-91.709735,34.034405],[-91.808581,34.094501],[-91.870491,34.093245],[-92.00942,34.013165],[-91.975974,33.791787],[-91.969501,33.791817],[-91.896249,33.791657]]]},"properties":{"id":"71667","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.118278,33.402205],[-92.980135,33.320874],[-92.908267,33.375676],[-92.913929,33.434943],[-93.05099,33.52889],[-93.113,33.540322],[-93.219824,33.483734],[-93.239067,33.441902],[-93.118278,33.402205]]]},"properties":{"id":"71764","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.985063,33.143531],[-93.359203,33.088524],[-93.392917,33.0183],[-92.988707,33.017433],[-92.985063,33.143531]]]},"properties":{"id":"71740","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.253479,33.334949],[-93.118278,33.402205],[-93.239067,33.441902],[-93.253479,33.334949]]]},"properties":{"id":"71752","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.775258,35.386665],[-92.852658,35.35692],[-92.782593,35.223767],[-92.732708,35.263423],[-92.659822,35.327364],[-92.676565,35.361641],[-92.775258,35.386665]]]},"properties":{"id":"72063","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.857389,35.033672],[-92.844712,35.088061],[-92.909008,35.084951],[-92.90028,35.023],[-92.857389,35.033672]]]},"properties":{"id":"72001","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.35661,35.876231],[-90.39238,35.778043],[-90.305298,35.816578],[-90.35661,35.876231]]]},"properties":{"id":"72414","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.036967,35.708418],[-90.908152,35.706519],[-90.909439,35.847191],[-90.998713,35.884254],[-91.032499,35.88507],[-91.036028,35.752356],[-91.036762,35.723012],[-91.036967,35.708418]]]},"properties":{"id":"72421","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.675697,35.842239],[-90.67717,35.842735],[-90.677338,35.838326],[-90.675476,35.840748],[-90.675697,35.842239]]]},"properties":{"id":"72467","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.13077,35.574913],[-94.131447,35.508255],[-94.097142,35.481626],[-94.026961,35.460882],[-93.968019,35.469291],[-93.931001,35.666846],[-94.020353,35.661492],[-94.13077,35.574913]]]},"properties":{"id":"72947","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.205941,35.752849],[-94.020353,35.661492],[-93.931001,35.666846],[-93.861142,35.773185],[-93.961516,35.806639],[-94.064016,35.892568],[-94.269178,35.754381],[-94.205941,35.752849]]]},"properties":{"id":"72959","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.404696,35.611746],[-94.357409,35.560346],[-94.356895,35.62682],[-94.359575,35.647799],[-94.441479,35.639767],[-94.404696,35.611746]]]},"properties":{"id":"72932","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.2416,33.6958],[-91.286897,33.737363],[-91.29327,33.78089],[-91.26463,33.77575],[-91.354773,33.873456],[-91.470193,33.846043],[-91.480093,33.802391],[-91.4772,33.780496],[-91.473324,33.751917],[-91.520478,33.753184],[-91.524692,33.781213],[-91.557648,33.783384],[-91.592587,33.786401],[-91.578643,33.67362],[-91.452254,33.680333],[-91.280523,33.539198],[-91.245217,33.537856],[-91.242415,33.561452],[-91.2416,33.6958]]]},"properties":{"id":"71670","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.242415,33.561452],[-91.230897,33.5614],[-91.224858,33.688226],[-91.2416,33.6958],[-91.242415,33.561452]]]},"properties":{"id":"71630","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.26463,33.77575],[-91.29327,33.78089],[-91.286897,33.737363],[-91.26463,33.77575]]]},"properties":{"id":"71666","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.524692,33.781213],[-91.520478,33.753184],[-91.473324,33.751917],[-91.4772,33.780496],[-91.524692,33.781213]]]},"properties":{"id":"71677","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.513882,35.253259],[-92.316535,35.353565],[-92.277863,35.407512],[-92.481342,35.371446],[-92.483346,35.325528],[-92.513882,35.253259]]]},"properties":{"id":"72039","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.316535,35.353565],[-92.513882,35.253259],[-92.529512,35.195564],[-92.519167,35.18404],[-92.499985,35.169575],[-92.463024,35.160583],[-92.416799,35.156762],[-92.306639,35.15207],[-92.278828,35.190295],[-92.203869,35.274385],[-92.204359,35.274905],[-92.215523,35.299547],[-92.28547,35.306701],[-92.304555,35.307063],[-92.303866,35.323061],[-92.316535,35.353565]]]},"properties":{"id":"72058","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.022194,34.861045],[-92.174748,35.013404],[-92.256979,35.003269],[-92.263508,34.993799],[-92.158395,34.8408],[-92.075601,34.842878],[-92.051042,34.817679],[-92.022194,34.861045]],[[-92.146359,34.900357],[-92.136108,34.898476],[-92.137482,34.896237],[-92.14467,34.896412],[-92.146359,34.900357]]]},"properties":{"id":"72076","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.003,34.996872],[-92.120386,35.044385],[-92.174748,35.013404],[-92.022194,34.861045],[-91.941293,34.922802],[-91.985108,34.999984],[-92.003,34.996872]]]},"properties":{"id":"72023","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.883405,35.34973],[-93.954862,35.229962],[-93.919034,35.258331],[-93.918701,35.267895],[-93.883405,35.34973]]]},"properties":{"id":"72928","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.673093,36.362896],[-91.754455,36.349468],[-91.740981,36.275578],[-91.646399,36.336239],[-91.673093,36.362896]]]},"properties":{"id":"72539","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.59155,34.379219],[-93.408124,34.339527],[-93.311692,34.352462],[-93.287767,34.357745],[-93.308508,34.450018],[-93.406164,34.468848],[-93.588494,34.423305],[-93.59155,34.379219]]]},"properties":{"id":"71933","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.14369,34.49151],[-92.298497,34.494347],[-92.250935,34.418308],[-92.084689,34.403132],[-92.112396,34.445572],[-92.14369,34.49151]]]},"properties":{"id":"72132","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.503346,35.862847],[-91.467378,35.840218],[-91.43646,35.828349],[-91.432772,35.85432],[-91.503346,35.862847]]],[[[-91.493399,35.80254],[-91.459758,35.752276],[-91.467778,35.808834],[-91.493399,35.80254]]],[[[-91.408604,35.93213],[-91.427543,35.855778],[-91.416004,35.831617],[-91.386696,35.835982],[-91.35723,35.890621],[-91.408604,35.93213]]]]},"properties":{"id":"72579","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.516444,36.025072],[-91.45079,36.15046],[-91.459421,36.164292],[-91.572343,36.162668],[-91.714491,36.141409],[-91.769314,36.107157],[-91.744135,36.078534],[-91.624945,36.027876],[-91.516444,36.025072]]]},"properties":{"id":"72532","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.158951,36.208631],[-92.122896,36.216472],[-92.118626,36.240092],[-92.157943,36.238256],[-92.158951,36.208631]]]},"properties":{"id":"72528","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.19635,35.81464],[-91.036762,35.723012],[-91.036028,35.752356],[-91.19635,35.81464]]]},"properties":{"id":"72473","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.198556,35.890024],[-91.248007,35.831243],[-91.19635,35.81464],[-91.036028,35.752356],[-91.032499,35.88507],[-91.198556,35.890024]]]},"properties":{"id":"72471","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.738385,34.587552],[-91.83208,34.635016],[-91.963303,34.668518],[-92.029493,34.624477],[-92.000351,34.602764],[-92.029743,34.56351],[-92.02954,34.621403],[-92.159442,34.562317],[-92.143608,34.511185],[-92.14369,34.49151],[-92.112396,34.445572],[-91.866224,34.486083],[-91.814708,34.484268],[-91.738385,34.587552]],[[-91.874044,34.534629],[-91.878312,34.53469],[-91.878141,34.539074],[-91.873886,34.539646],[-91.874044,34.534629]]]},"properties":{"id":"72046","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.050714,34.324144],[-91.913889,34.282908],[-91.912616,34.266924],[-91.897534,34.411114],[-92.084689,34.403132],[-92.068096,34.367094],[-92.050714,34.324144]]]},"properties":{"id":"72152","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.601204,35.719191],[-93.64512,35.56435],[-93.545184,35.590493],[-93.517341,35.81692],[-93.601204,35.719191]]]},"properties":{"id":"72852","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.226129,35.442453],[-93.222746,35.556002],[-93.36721,35.58818],[-93.382922,35.582124],[-93.434761,35.427504],[-93.426368,35.412063],[-93.330322,35.394873],[-93.226129,35.442453]]]},"properties":{"id":"72846","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.35723,35.890621],[-91.309717,35.924803],[-91.223081,35.890403],[-91.198556,35.890024],[-91.126943,35.999079],[-91.132018,36.00795],[-91.132328,36.007957],[-91.208846,36.008052],[-91.281683,35.98612],[-91.39167,35.979927],[-91.408604,35.93213],[-91.35723,35.890621]]]},"properties":{"id":"72469","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.403557,36.120169],[-91.467484,36.018164],[-91.39167,35.979927],[-91.281683,35.98612],[-91.247009,36.022395],[-91.208846,36.008052],[-91.132328,36.007957],[-91.20796,36.09538],[-91.24399,36.119454],[-91.2756,36.11738],[-91.297714,36.132726],[-91.403557,36.120169]]]},"properties":{"id":"72466","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.031528,35.969151],[-91.126943,35.999079],[-91.198556,35.890024],[-91.032499,35.88507],[-90.998713,35.884254],[-91.02302,35.97236],[-91.031528,35.969151]]]},"properties":{"id":"72410","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.619817,34.906427],[-90.706558,34.867928],[-90.809743,34.860401],[-90.90785,34.877],[-91.050116,34.704044],[-91.050953,34.674677],[-91.015877,34.645099],[-90.874295,34.643483],[-90.83866,34.64313],[-90.666518,34.641779],[-90.658003,34.64174],[-90.537602,34.756939],[-90.53434,34.90024],[-90.619817,34.906427]],[[-90.9003,34.72097],[-90.90004,34.73127],[-90.88243,34.73108],[-90.88365,34.71643],[-90.9003,34.72097]]]},"properties":{"id":"72360","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.706558,34.867928],[-90.841121,34.906282],[-90.809743,34.860401],[-90.706558,34.867928]]]},"properties":{"id":"72341","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.706733,35.130966],[-93.711441,35.130887],[-93.711792,35.115255],[-93.707261,35.113327],[-93.706752,35.128953],[-93.706733,35.130966]]]},"properties":{"id":"72826","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.052567,35.926293],[-93.961516,35.806639],[-93.861142,35.773185],[-93.85804,35.773108],[-93.832535,35.823342],[-93.80287,35.91688],[-93.85869,35.976658],[-93.932767,36.028778],[-93.937454,36.108379],[-94.007069,36.092345],[-94.052567,35.926293]]]},"properties":{"id":"72727","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.832535,35.823342],[-93.85804,35.773108],[-93.715566,35.769244],[-93.832535,35.823342]]],[[[-93.832535,35.823342],[-93.73762,35.857705],[-93.73723,35.883334],[-93.80287,35.91688],[-93.832535,35.823342]]]]},"properties":{"id":"72721","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.637816,36.461777],[-92.638798,36.453618],[-92.601242,36.498208],[-92.630626,36.498155],[-92.637816,36.461777]]]},"properties":{"id":"65729","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.778204,33.361594],[-93.999845,33.242159],[-93.838337,33.148692],[-93.726249,33.181989],[-93.702603,33.251402],[-93.778204,33.361594]]]},"properties":{"id":"71837","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043035,33.074435],[-94.035564,33.076793],[-94.043026,33.108368],[-94.043035,33.074435]]]},"properties":{"id":"75556","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.249033,35.539563],[-90.133174,35.582152],[-90.160158,35.611089],[-90.142771,35.647882],[-90.201474,35.640652],[-90.286888,35.539811],[-90.249033,35.539563]]]},"properties":{"id":"72330","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.113,33.540322],[-93.107829,33.679201],[-93.213082,33.731945],[-93.372618,33.642434],[-93.469493,33.493003],[-93.46461,33.44699],[-93.324252,33.516728],[-93.219824,33.483734],[-93.113,33.540322]]]},"properties":{"id":"71858","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.417683,35.932016],[-93.445382,35.829458],[-93.306448,35.779017],[-93.164452,35.728831],[-93.192221,35.870882],[-93.242162,35.89708],[-93.339505,35.907071],[-93.385393,35.907758],[-93.417683,35.932016]]]},"properties":{"id":"72628","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.940932,35.990069],[-93.036199,35.987225],[-93.05521,35.958667],[-93.133923,35.903089],[-93.158227,35.884688],[-92.949757,35.805296],[-92.945719,35.842098],[-92.940932,35.990069]]]},"properties":{"id":"72655","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.358192,34.975464],[-93.482759,34.899242],[-93.709211,34.801953],[-93.709837,34.768658],[-93.710297,34.745296],[-93.533982,34.743885],[-93.499101,34.743877],[-93.287785,34.773193],[-93.075178,34.771892],[-93.035869,34.858525],[-93.160236,34.975991],[-93.358192,34.975464]]]},"properties":{"id":"72857","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.634696,35.449051],[-90.610867,35.443588],[-90.437313,35.587467],[-90.398587,35.672665],[-90.397716,35.701519],[-90.463505,35.705009],[-90.527371,35.706677],[-90.64111,35.704747],[-90.634696,35.449051]]]},"properties":{"id":"72472","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.190523,35.366915],[-92.993451,35.40755],[-93.076728,35.701096],[-93.135562,35.714595],[-93.222746,35.556002],[-93.226129,35.442453],[-93.190523,35.366915]]]},"properties":{"id":"72837","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.373836,34.710083],[-91.411135,34.810702],[-91.45623,34.83976],[-91.457056,34.839562],[-91.555187,34.677012],[-91.447297,34.632698],[-91.373836,34.710083]]]},"properties":{"id":"72041","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.236904,34.777549],[-92.208547,34.743591],[-92.185586,34.729947],[-92.156994,34.667548],[-92.075601,34.842878],[-92.158395,34.8408],[-92.211122,34.805256],[-92.236904,34.777549]]]},"properties":{"id":"72117","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.840972,36.339425],[-90.806214,36.28817],[-90.750605,36.33194],[-90.749838,36.360841],[-90.804517,36.356276],[-90.840972,36.339425]]]},"properties":{"id":"72413","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.735254,34.473844],[-92.523104,34.609366],[-92.50185,34.639684],[-92.671877,34.735898],[-92.846795,34.681686],[-92.855809,34.675109],[-92.769575,34.54298],[-92.735254,34.473844]]]},"properties":{"id":"72019","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.705146,35.006454],[-94.126997,35.003261],[-94.337055,34.948372],[-94.447774,34.933937],[-94.450957,34.830693],[-94.130483,34.810481],[-94.04373,34.79829],[-93.823107,34.876695],[-93.707276,34.905082],[-93.706703,34.932035],[-93.705707,34.983833],[-93.705146,35.006454]]]},"properties":{"id":"72958","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.877996,34.687265],[-94.04373,34.79829],[-94.130483,34.810481],[-93.930509,34.665608],[-93.894805,34.665447],[-93.877996,34.687265]]]},"properties":{"id":"72926","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.286878,35.073275],[-94.337055,34.948372],[-94.126997,35.003261],[-94.140571,35.099678],[-94.286878,35.073275]]]},"properties":{"id":"72944","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.411502,36.062756],[-92.518875,36.06425],[-92.581538,36.065388],[-92.589571,36.020531],[-92.414324,35.975865],[-92.411502,36.062756]]]},"properties":{"id":"72639","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.398595,35.385922],[-94.423008,35.422347],[-94.431667,35.39768],[-94.432399,35.364334],[-94.430972,35.328378],[-94.400709,35.331003],[-94.398595,35.385922]]]},"properties":{"id":"72901","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.354882,35.223256],[-94.332275,35.133046],[-94.158982,35.143889],[-94.038866,35.181115],[-94.309546,35.266],[-94.354882,35.223256]]]},"properties":{"id":"72936","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.430972,35.328378],[-94.432399,35.364334],[-94.43362,35.328485],[-94.430972,35.328378]]],[[[-94.434941,35.291693],[-94.437835,35.222853],[-94.354882,35.223256],[-94.309546,35.266],[-94.326626,35.291356],[-94.323666,35.322748],[-94.387363,35.32727],[-94.434941,35.291693]]]]},"properties":{"id":"72916","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.894724,35.823877],[-91.975612,35.836965],[-91.924195,35.789109],[-91.894724,35.823877]]]},"properties":{"id":"72567","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.362061,35.920806],[-92.245994,35.913189],[-92.354284,35.974773],[-92.40243,35.975681],[-92.362061,35.920806]]]},"properties":{"id":"72663","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.88061,35.733778],[-91.836081,35.748855],[-91.854368,35.834008],[-91.894724,35.823877],[-91.924195,35.789109],[-91.88061,35.733778]]]},"properties":{"id":"72555","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.63104,35.731684],[-92.534228,35.675645],[-92.470401,35.789075],[-92.63104,35.731684]]]},"properties":{"id":"72629","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.743585,35.570774],[-92.767505,35.461367],[-92.60248,35.458455],[-92.743585,35.570774]]]},"properties":{"id":"72141","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.232733,35.99929],[-94.317199,36.050787],[-94.350593,36.058851],[-94.373671,35.921638],[-94.391562,35.757324],[-94.372643,35.757187],[-94.216228,35.945854],[-94.232733,35.99929]]]},"properties":{"id":"72753","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.064016,35.892568],[-93.961516,35.806639],[-94.052567,35.926293],[-94.064016,35.892568]]],[[[-94.052567,35.926293],[-94.007069,36.092345],[-94.200647,36.07826],[-94.22433,36.04695],[-94.232733,35.99929],[-94.216228,35.945854],[-94.052567,35.926293]]]]},"properties":{"id":"72701","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.426483,36.056874],[-94.523178,35.936488],[-94.505724,35.833843],[-94.495779,35.835513],[-94.373671,35.921638],[-94.350593,36.058851],[-94.395809,36.09949],[-94.398997,36.099554],[-94.426483,36.056874]]]},"properties":{"id":"72744","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.444398,35.859991],[-94.411472,35.871434],[-94.456831,35.872433],[-94.444398,35.859991]]]},"properties":{"id":"72749","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.7029,34.580347],[-91.678037,34.48192],[-91.592535,34.228761],[-91.601362,34.131987],[-91.606935,34.112271],[-91.445614,34.080147],[-91.441031,34.107992],[-91.47409,34.279486],[-91.36229,34.49008],[-91.351505,34.562922],[-91.37782,34.56348],[-91.466255,34.572681],[-91.465675,34.602158],[-91.412519,34.597924],[-91.447297,34.632698],[-91.555187,34.677012],[-91.648314,34.612762],[-91.7029,34.580347]]]},"properties":{"id":"72160","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.697305,33.102688],[-91.716519,33.007329],[-91.459284,33.005909],[-91.439697,33.087394],[-91.697305,33.102688]]]},"properties":{"id":"71676","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.248274,36.245807],[-92.425948,36.17833],[-92.410819,36.084252],[-92.371051,36.078064],[-92.248274,36.245807]]]},"properties":{"id":"72658","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.183634,36.335049],[-94.041729,36.282131],[-94.000199,36.230122],[-93.943919,36.236178],[-93.797305,36.237069],[-93.762045,36.304677],[-93.867189,36.365184],[-94.065307,36.440879],[-94.141242,36.410544],[-94.183634,36.335049]]]},"properties":{"id":"72756","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.232678,36.353235],[-93.299902,36.178483],[-93.266314,36.076641],[-93.041823,36.072967],[-92.998073,36.116229],[-92.838799,36.215779],[-92.847119,36.241848],[-92.861872,36.274752],[-92.888215,36.333197],[-93.08801,36.39327],[-93.232678,36.353235]]]},"properties":{"id":"72601","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.299902,36.178483],[-93.232678,36.353235],[-93.296199,36.381503],[-93.382558,36.122927],[-93.299902,36.178483]]]},"properties":{"id":"72611","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.296199,36.381503],[-93.232678,36.353235],[-93.08801,36.39327],[-93.013739,36.498092],[-93.296117,36.498251],[-93.295759,36.457146],[-93.296199,36.381503]]]},"properties":{"id":"72662","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.513273,33.800214],[-92.398908,33.695264],[-92.323487,33.637289],[-92.330839,33.707806],[-92.333281,33.792441],[-92.473914,34.006227],[-92.59581,33.92628],[-92.513273,33.800214]]]},"properties":{"id":"71742","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.663605,36.28174],[-93.444504,36.124293],[-93.440588,36.456275],[-93.439837,36.498579],[-93.679907,36.499051],[-93.682923,36.449423],[-93.663605,36.28174]]]},"properties":{"id":"72616","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.311692,34.352462],[-93.408124,34.339527],[-93.671559,34.286507],[-93.606878,34.236225],[-93.448513,34.15184],[-93.292679,34.153158],[-93.187209,34.247772],[-93.311692,34.352462]]]},"properties":{"id":"71921","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.125114,33.834594],[-93.127902,33.828886],[-93.121867,33.828938],[-93.125114,33.834594]]]},"properties":{"id":"71772","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.057049,34.125469],[-93.057204,34.12942],[-93.06101,34.129479],[-93.06107,34.125539],[-93.057049,34.125469]]]},"properties":{"id":"71999","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.230619,36.342118],[-90.41711,36.365533],[-90.471157,36.348117],[-90.476179,36.289088],[-90.407338,36.259427],[-90.34165,36.146346],[-90.235569,36.141707],[-90.071444,36.293162],[-90.10406,36.316329],[-90.230619,36.342118]]]},"properties":{"id":"72461","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.392901,36.438949],[-90.370793,36.498332],[-90.584285,36.498575],[-90.749018,36.412874],[-90.748881,36.397022],[-90.721033,36.397516],[-90.722393,36.386427],[-90.749207,36.382597],[-90.749838,36.360841],[-90.750605,36.33194],[-90.696159,36.33304],[-90.471157,36.348117],[-90.41711,36.365533],[-90.314075,36.393869],[-90.384721,36.4345],[-90.391736,36.434488],[-90.392901,36.438949]]]},"properties":{"id":"72422","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.392901,36.438949],[-90.391736,36.434488],[-90.384721,36.4345],[-90.392901,36.438949]]]},"properties":{"id":"72441","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.659822,35.327364],[-92.626566,35.293576],[-92.483346,35.325528],[-92.481342,35.371446],[-92.506672,35.438219],[-92.607026,35.437967],[-92.676565,35.361641],[-92.659822,35.327364]]]},"properties":{"id":"72027","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.62281,35.965922],[-90.517403,35.878071],[-90.470649,35.943442],[-90.62281,35.965922]]]},"properties":{"id":"72417","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.49313,35.759167],[-94.47475,35.650702],[-94.441479,35.639767],[-94.359575,35.647799],[-94.341566,35.75654],[-94.372643,35.757187],[-94.391562,35.757324],[-94.431663,35.758042],[-94.49313,35.759167]]]},"properties":{"id":"72948","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.208691,35.309967],[-90.271024,35.343464],[-90.230022,35.294576],[-90.208691,35.309967]]]},"properties":{"id":"72325","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.83754,34.083209],[-92.7343,34.021661],[-92.426456,34.125359],[-92.586212,34.263381],[-92.671772,34.294587],[-92.838423,34.153824],[-92.83754,34.083209]]]},"properties":{"id":"72084","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.303866,35.323061],[-92.304555,35.307063],[-92.28547,35.306701],[-92.303866,35.323061]]]},"properties":{"id":"72061","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.158395,34.8408],[-92.263508,34.993799],[-92.344991,34.96014],[-92.34583,34.95901],[-92.319936,34.875089],[-92.32085,34.841972],[-92.300515,34.839395],[-92.284002,34.828955],[-92.259458,34.840953],[-92.245991,34.832442],[-92.211122,34.805256],[-92.158395,34.8408]]]},"properties":{"id":"72120","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.075855,35.423035],[-93.915266,35.377287],[-93.968019,35.469291],[-94.026961,35.460882],[-94.074895,35.445403],[-94.075855,35.423035]]]},"properties":{"id":"72930","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.629825,36.313461],[-91.53203,36.33467],[-91.565866,36.257435],[-91.572343,36.162668],[-91.459421,36.164292],[-91.292515,36.374746],[-91.349135,36.420863],[-91.622879,36.340435],[-91.629825,36.313461]]]},"properties":{"id":"72542","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.915754,36.498633],[-92.042833,36.498624],[-92.073009,36.390329],[-92.052913,36.302813],[-92.015949,36.288891],[-91.890872,36.407259],[-91.915754,36.498633]]]},"properties":{"id":"72583","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.915754,36.498633],[-91.890872,36.407259],[-91.858181,36.498805],[-91.915754,36.498633]]]},"properties":{"id":"72578","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.875902,34.620425],[-92.856014,34.667658],[-92.891793,34.686428],[-92.96949,34.701246],[-93.111829,34.630446],[-93.11952,34.570973],[-93.066194,34.552249],[-92.875902,34.620425]]]},"properties":{"id":"71909","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.308508,34.450018],[-93.287767,34.357745],[-93.247539,34.366005],[-93.219671,34.472825],[-93.308508,34.450018]]]},"properties":{"id":"71964","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.523104,34.609366],[-92.735254,34.473844],[-92.70881,34.473562],[-92.609969,34.383863],[-92.53787,34.401323],[-92.516964,34.453882],[-92.521835,34.581276],[-92.523104,34.609366]]]},"properties":{"id":"72015","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.823253,36.069553],[-90.742663,36.052171],[-90.700673,36.18007],[-90.760219,36.149051],[-90.823253,36.069553]]]},"properties":{"id":"72412","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.722867,33.481963],[-93.535832,33.477414],[-93.482468,33.497435],[-93.473022,33.774469],[-93.512994,33.840849],[-93.617116,33.869855],[-93.635244,33.858618],[-93.679149,33.8315],[-93.697312,33.698535],[-93.733843,33.497248],[-93.722867,33.481963]]]},"properties":{"id":"71801","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.187209,34.247772],[-93.022874,34.273941],[-93.044402,34.333779],[-93.11375,34.35307],[-93.247539,34.366005],[-93.287767,34.357745],[-93.311692,34.352462],[-93.187209,34.247772]]]},"properties":{"id":"71929","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.459758,35.752276],[-91.440956,35.639903],[-91.30064,35.766817],[-91.386696,35.835982],[-91.416004,35.831617],[-91.434341,35.825353],[-91.467778,35.808834],[-91.459758,35.752276]]]},"properties":{"id":"72562","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.467378,35.840218],[-91.493399,35.80254],[-91.467778,35.808834],[-91.434341,35.825353],[-91.43646,35.828349],[-91.467378,35.840218]]]},"properties":{"id":"72522","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.483788,35.703856],[-91.483393,35.696431],[-91.476103,35.696335],[-91.476055,35.70301],[-91.483788,35.703856]]]},"properties":{"id":"72553","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.890386,36.119776],[-91.840841,36.075538],[-91.804378,36.115303],[-91.818948,36.19658],[-91.836532,36.204341],[-91.888841,36.160796],[-91.890386,36.119776]]]},"properties":{"id":"72584","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.324252,33.516728],[-93.46461,33.44699],[-93.425866,33.308939],[-93.324252,33.516728]]]},"properties":{"id":"71827","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.021524,35.984225],[-91.031528,35.969151],[-91.02302,35.97236],[-91.021524,35.984225]]]},"properties":{"id":"72445","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.867415,34.410731],[-90.719465,34.4853],[-90.769131,34.534038],[-90.857964,34.482245],[-90.874483,34.459999],[-90.867415,34.410731]]],[[[-90.768242,34.555073],[-90.666518,34.641779],[-90.83866,34.64313],[-90.768242,34.555073]]]]},"properties":{"id":"72355","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.145585,33.741261],[-94.155423,33.748579],[-94.155459,33.734071],[-94.145585,33.741261]]]},"properties":{"id":"71865","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.416553,35.214757],[-93.296632,35.142325],[-93.175571,35.0594],[-93.080019,35.08219],[-92.978902,35.118847],[-92.896109,35.170678],[-93.027647,35.159793],[-93.080891,35.166197],[-93.07796,35.178786],[-93.234656,35.273806],[-93.278293,35.317402],[-93.385671,35.214039],[-93.416553,35.214757]]]},"properties":{"id":"72834","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.557362,35.952919],[-93.559015,35.888061],[-93.431881,35.937769],[-93.402694,35.974216],[-93.445687,36.080058],[-93.480178,36.08524],[-93.557362,35.952919]]]},"properties":{"id":"72742","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.700808,36.246447],[-92.683284,36.245558],[-92.681601,36.260067],[-92.700808,36.246447]]]},"properties":{"id":"72677","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.141364,35.915985],[-90.286742,35.845026],[-90.286727,35.830511],[-90.286736,35.699914],[-90.286537,35.69496],[-90.249967,35.736217],[-90.177825,35.733571],[-90.143876,35.742125],[-90.074476,35.784835],[-90.081683,35.8871],[-90.141364,35.915985]]]},"properties":{"id":"72442","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.398587,35.672665],[-90.353236,35.671454],[-90.341146,35.700513],[-90.397716,35.701519],[-90.398587,35.672665]]],[[[-90.393971,35.599854],[-90.322657,35.547799],[-90.286888,35.539811],[-90.201474,35.640652],[-90.286281,35.656074],[-90.393971,35.599854]]]]},"properties":{"id":"72354","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.17646,34.680003],[-91.291958,34.649721],[-91.262,34.527309],[-91.215306,34.472844],[-91.130069,34.383325],[-91.1227,34.375732],[-91.056276,34.336605],[-91.037511,34.336482],[-91.008022,34.469588],[-91.054774,34.47088],[-91.053558,34.529816],[-91.015877,34.645099],[-91.050953,34.674677],[-91.104118,34.701322],[-91.17646,34.680003]]]},"properties":{"id":"72069","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.412519,34.597924],[-91.37782,34.56348],[-91.351505,34.562922],[-91.291483,34.561761],[-91.315076,34.649908],[-91.322792,34.699131],[-91.373836,34.710083],[-91.447297,34.632698],[-91.412519,34.597924]]]},"properties":{"id":"72134","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.291958,34.649721],[-91.17646,34.680003],[-91.157553,34.713379],[-91.157068,34.726134],[-91.298244,34.73816],[-91.322792,34.699131],[-91.315076,34.649908],[-91.291958,34.649721]]]},"properties":{"id":"72029","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.720372,34.674255],[-93.788416,34.680912],[-93.7263,34.616912],[-93.690193,34.646352],[-93.720372,34.674255]]]},"properties":{"id":"71965","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.691749,34.200314],[-93.768668,34.137715],[-93.65021,33.999312],[-93.539674,34.117813],[-93.602376,34.211307],[-93.691749,34.200314]]]},"properties":{"id":"71958","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.6949,35.705019],[-90.875752,35.536246],[-90.82382,35.44575],[-90.634696,35.449051],[-90.64111,35.704747],[-90.643808,35.704809],[-90.6949,35.705019]]]},"properties":{"id":"72432","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.471831,34.132309],[-94.206331,34.104653],[-94.238663,34.144781],[-94.242574,34.192542],[-94.244068,34.213],[-94.368372,34.190485],[-94.471831,34.132309]]]},"properties":{"id":"71841","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.390905,34.763478],[-92.358456,34.762287],[-92.363695,34.790928],[-92.362441,34.792383],[-92.376135,34.799713],[-92.381135,34.797241],[-92.39074,34.765487],[-92.390905,34.763478]]]},"properties":{"id":"72227","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.265835,36.402157],[-91.126199,36.341559],[-91.082817,36.150966],[-91.073465,36.114894],[-90.845514,36.193622],[-90.806615,36.266865],[-90.806214,36.28817],[-90.840972,36.339425],[-90.97243,36.397925],[-91.074668,36.432246],[-91.111587,36.419543],[-91.172371,36.497515],[-91.324551,36.497404],[-91.265835,36.402157]]]},"properties":{"id":"72455","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.714864,35.039303],[-90.73356,35.03202],[-90.717502,35.009278],[-90.714864,35.039303]]]},"properties":{"id":"72359","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.887518,36.070248],[-92.887897,36.058186],[-92.86446,36.056807],[-92.887518,36.070248]]]},"properties":{"id":"72669","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.351728,35.089248],[-94.359289,35.074413],[-94.344236,35.073967],[-94.351728,35.089248]]],[[[-94.357804,35.099655],[-94.341022,35.074002],[-94.286878,35.073275],[-94.140571,35.099678],[-94.158982,35.143889],[-94.332275,35.133046],[-94.357804,35.099655]]]]},"properties":{"id":"72940","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.206331,34.104653],[-94.471831,34.132309],[-94.477299,33.940932],[-94.387182,33.919256],[-94.222602,33.971314],[-94.206331,34.104653]]]},"properties":{"id":"71832","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.647877,33.367623],[-92.832927,33.2759],[-92.94745,33.245977],[-92.945856,33.158607],[-92.524954,33.012574],[-92.497764,33.012229],[-92.32648,33.264174],[-92.350432,33.298941],[-92.522856,33.316502],[-92.548211,33.321344],[-92.532515,33.345488],[-92.567156,33.3672],[-92.647877,33.367623]],[[-92.617286,33.343862],[-92.632873,33.298277],[-92.673834,33.309028],[-92.617286,33.343862]]]},"properties":{"id":"71730","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.617286,33.343862],[-92.673834,33.309028],[-92.632873,33.298277],[-92.617286,33.343862]]]},"properties":{"id":"71759","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.505724,35.833843],[-94.49313,35.759167],[-94.431663,35.758042],[-94.495779,35.835513],[-94.505724,35.833843]]]},"properties":{"id":"72729","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.709894,35.414285],[-91.67294,35.253843],[-91.642857,35.25723],[-91.613002,35.26258],[-91.589671,35.264107],[-91.634191,35.442972],[-91.627051,35.531814],[-91.711416,35.533219],[-91.709894,35.414285]]]},"properties":{"id":"72081","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.907646,35.204453],[-91.751073,35.059521],[-91.817108,35.215082],[-91.907646,35.204453]]]},"properties":{"id":"72102","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.967529,35.19128],[-92.080437,35.171715],[-92.11731,35.13847],[-92.119719,35.066493],[-92.014875,35.075471],[-91.963545,35.184721],[-91.967529,35.19128]]]},"properties":{"id":"72045","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626861,34.927509],[-93.708327,34.847824],[-93.709211,34.801953],[-93.482759,34.899242],[-93.482794,34.899582],[-93.626861,34.927509]]]},"properties":{"id":"72827","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.167702,34.094275],[-91.189497,34.176908],[-91.269584,34.155578],[-91.329405,34.004538],[-91.352533,33.956535],[-91.137038,33.953187],[-91.167702,34.094275]]]},"properties":{"id":"72166","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.189497,34.176908],[-91.167702,34.094275],[-91.117905,34.119105],[-91.065059,34.263592],[-91.056276,34.336605],[-91.1227,34.375732],[-91.15483,34.35442],[-91.189497,34.176908]]]},"properties":{"id":"72048","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.291483,34.561761],[-91.351505,34.562922],[-91.36229,34.49008],[-91.321083,34.454347],[-91.215306,34.472844],[-91.262,34.527309],[-91.291483,34.561761]]]},"properties":{"id":"72026","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.45737,33.388974],[-91.641433,33.392195],[-91.635668,33.226263],[-91.536336,33.235154],[-91.459363,33.302729],[-91.45737,33.388974]]]},"properties":{"id":"71658","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.069105,33.008163],[-91.86985,33.007633],[-92.036501,33.268501],[-92.126599,33.209342],[-92.137527,33.162956],[-92.135249,33.090688],[-92.069105,33.008163]]]},"properties":{"id":"71635","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.013739,36.498092],[-93.08801,36.39327],[-92.888215,36.333197],[-92.893723,36.452035],[-92.916392,36.497835],[-93.013739,36.498092]]]},"properties":{"id":"72644","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.184267,33.705286],[-92.330839,33.707806],[-92.323487,33.637289],[-92.340018,33.431367],[-92.262162,33.435892],[-92.196606,33.471786],[-92.184267,33.705286]]]},"properties":{"id":"71631","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.866836,36.376659],[-93.682923,36.449423],[-93.679907,36.499051],[-93.866758,36.498735],[-93.866836,36.376659]]]},"properties":{"id":"72631","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.245906,36.497809],[-90.314075,36.393869],[-90.41711,36.365533],[-90.230619,36.342118],[-90.16943,36.3818],[-90.10406,36.316329],[-90.071444,36.293162],[-90.245906,36.497809]],[[-90.145135,36.455496],[-90.146969,36.460959],[-90.141803,36.461542],[-90.145135,36.455496]]]},"properties":{"id":"72454","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.606731,36.265273],[-90.520479,36.261444],[-90.476179,36.289088],[-90.471157,36.348117],[-90.696159,36.33304],[-90.606731,36.265273]]]},"properties":{"id":"72435","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.304565,35.555364],[-92.248817,35.523533],[-92.118449,35.52265],[-92.107422,35.526209],[-92.024136,35.613566],[-92.105527,35.616217],[-92.223474,35.588353],[-92.304565,35.555364]]]},"properties":{"id":"72067","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.101746,35.274639],[-91.97625,35.311966],[-91.938911,35.359534],[-92.06142,35.401438],[-92.11235,35.32947],[-92.101746,35.274639]]]},"properties":{"id":"72137","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.839004,35.538984],[-91.793958,35.534114],[-91.711416,35.533219],[-91.60602,35.614777],[-91.724126,35.658846],[-91.74653,35.647318],[-91.828297,35.566409],[-91.839004,35.538984]]]},"properties":{"id":"72534","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.333281,33.792441],[-92.159091,33.850716],[-92.336765,34.059633],[-92.473914,34.006227],[-92.333281,33.792441]]]},"properties":{"id":"71652","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.61827,35.235761],[-92.529512,35.195564],[-92.513882,35.253259],[-92.483346,35.325528],[-92.626566,35.293576],[-92.681397,35.242242],[-92.61827,35.235761]]]},"properties":{"id":"72157","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.766227,35.848138],[-90.709195,35.991345],[-90.742663,36.052171],[-90.823253,36.069553],[-90.872785,35.88087],[-90.860213,35.872637],[-90.766227,35.848138]]]},"properties":{"id":"72416","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.341146,35.700513],[-90.30626,35.700363],[-90.286736,35.699914],[-90.286727,35.830511],[-90.305298,35.816578],[-90.39238,35.778043],[-90.463505,35.705009],[-90.397716,35.701519],[-90.341146,35.700513]]]},"properties":{"id":"72419","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.592401,35.77949],[-90.643808,35.704809],[-90.64111,35.704747],[-90.527371,35.706677],[-90.535423,35.79356],[-90.592401,35.77949]]]},"properties":{"id":"72411","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.35661,35.876231],[-90.305298,35.816578],[-90.286727,35.830511],[-90.286742,35.845026],[-90.288947,35.99641],[-90.37789,35.995683],[-90.386245,35.984187],[-90.35661,35.876231]]]},"properties":{"id":"72447","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.998713,35.884254],[-90.909439,35.847191],[-90.860213,35.872637],[-90.872785,35.88087],[-90.998713,35.884254]]]},"properties":{"id":"72427","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.215505,35.437609],[-90.214741,35.481227],[-90.249033,35.539563],[-90.286888,35.539811],[-90.322657,35.547799],[-90.502429,35.441726],[-90.502481,35.439298],[-90.386945,35.366849],[-90.301995,35.438726],[-90.223734,35.437731],[-90.215505,35.437609]]]},"properties":{"id":"72386","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.255433,35.13216],[-90.172917,35.118662],[-90.184547,35.177457],[-90.255433,35.13216]]]},"properties":{"id":"72301","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.292778,35.147411],[-90.230022,35.294576],[-90.271024,35.343464],[-90.301929,35.336494],[-90.466819,35.14496],[-90.403456,35.14814],[-90.292778,35.147411]]]},"properties":{"id":"72327","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.669369,34.387239],[-92.671772,34.294587],[-92.586212,34.263381],[-92.583321,34.281624],[-92.591334,34.297634],[-92.669369,34.387239]]]},"properties":{"id":"72128","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.840841,36.075538],[-91.786875,36.045181],[-91.744135,36.078534],[-91.769314,36.107157],[-91.804378,36.115303],[-91.840841,36.075538]]]},"properties":{"id":"72573","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.063364,36.153253],[-92.046933,36.133646],[-91.962766,36.176085],[-92.005324,36.228491],[-92.063364,36.153253]]]},"properties":{"id":"72585","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.948024,35.924218],[-91.872643,35.881118],[-91.866859,35.961596],[-91.948024,35.924218]]]},"properties":{"id":"72540","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.330322,35.394873],[-93.426368,35.412063],[-93.418904,35.327128],[-93.329423,35.341553],[-93.330322,35.394873]]]},"properties":{"id":"72845","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.445382,35.829458],[-93.417683,35.932016],[-93.431881,35.937769],[-93.559015,35.888061],[-93.659925,35.870176],[-93.695981,35.768649],[-93.601204,35.719191],[-93.517341,35.81692],[-93.445382,35.829458]]]},"properties":{"id":"72752","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.06382,36.08878],[-91.021524,35.984225],[-91.02302,35.97236],[-90.998713,35.884254],[-90.872785,35.88087],[-90.823253,36.069553],[-90.760219,36.149051],[-90.809816,36.149567],[-90.839335,36.193547],[-90.845514,36.193622],[-91.073465,36.114894],[-91.087832,36.094272],[-91.07269,36.09261],[-91.06382,36.08878]]]},"properties":{"id":"72476","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.021524,35.984225],[-91.06382,36.08878],[-91.07284,36.08932],[-91.07269,36.09261],[-91.087832,36.094272],[-91.117475,36.078876],[-91.132018,36.00795],[-91.126943,35.999079],[-91.031528,35.969151],[-91.021524,35.984225]]]},"properties":{"id":"72433","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.815529,35.065943],[-90.836795,35.060557],[-90.893196,35.038645],[-90.841121,34.906282],[-90.706558,34.867928],[-90.619817,34.906427],[-90.60565,34.992273],[-90.717502,35.009278],[-90.73356,35.03202],[-90.714864,35.039303],[-90.689415,35.105973],[-90.734123,35.141584],[-90.815529,35.065943]]]},"properties":{"id":"72335","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.306867,33.824336],[-94.278356,33.763709],[-94.181932,33.788779],[-94.231637,33.801663],[-94.304174,33.824116],[-94.306867,33.824336]]]},"properties":{"id":"71820","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.640729,35.337865],[-93.52695,35.305469],[-93.428385,35.320502],[-93.418904,35.327128],[-93.426368,35.412063],[-93.434761,35.427504],[-93.550801,35.400219],[-93.614202,35.384146],[-93.648623,35.370427],[-93.640729,35.337865]]]},"properties":{"id":"72863","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.693774,34.916236],[-91.736786,34.989826],[-91.803272,34.954339],[-91.83208,34.635016],[-91.738385,34.587552],[-91.7029,34.580347],[-91.648314,34.612762],[-91.693774,34.916236]]]},"properties":{"id":"72024","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.638798,36.453618],[-92.58889,36.397896],[-92.583471,36.396438],[-92.529128,36.498599],[-92.601242,36.498208],[-92.638798,36.453618]]]},"properties":{"id":"72661","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.249033,35.539563],[-90.214741,35.481227],[-90.17478,35.437074],[-90.140257,35.436636],[-90.030438,35.558889],[-90.106677,35.590647],[-90.133174,35.582152],[-90.249033,35.539563]]]},"properties":{"id":"72350","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.690193,34.646352],[-93.7263,34.616912],[-93.810279,34.542169],[-93.405284,34.514001],[-93.403555,34.601992],[-93.403503,34.610417],[-93.57361,34.616302],[-93.690193,34.646352]]]},"properties":{"id":"71957","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.588494,34.423305],[-93.934689,34.435721],[-93.934882,34.393754],[-93.935371,34.350097],[-93.918731,34.34975],[-93.839536,34.34785],[-93.59155,34.379219],[-93.588494,34.423305]]]},"properties":{"id":"71935","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.041823,36.072967],[-93.266314,36.076641],[-93.25018,36.065105],[-93.106122,36.035977],[-93.052878,36.039533],[-93.041823,36.072967]]]},"properties":{"id":"72648","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.884004,33.374702],[-92.647877,33.367623],[-92.567156,33.3672],[-92.664097,33.434745],[-92.913929,33.434943],[-92.908267,33.375676],[-92.884004,33.374702]]]},"properties":{"id":"71751","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.867415,34.410731],[-90.821298,34.365211],[-90.76448,34.366847],[-90.602402,34.592529],[-90.719465,34.4853],[-90.867415,34.410731]]]},"properties":{"id":"72342","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.992088,34.148109],[-91.044284,34.17094],[-91.061136,34.119364],[-90.984629,34.118885],[-90.992088,34.148109]]],[[[-90.992088,34.148109],[-90.958326,34.118808],[-90.95517,34.118833],[-90.833564,34.263229],[-91.001036,34.263263],[-91.059076,34.263306],[-91.044193,34.192217],[-90.992088,34.148109]]]]},"properties":{"id":"72367","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.16558,35.287917],[-93.165331,35.266322],[-93.120934,35.260289],[-93.120096,35.296355],[-93.16558,35.287917]]]},"properties":{"id":"72801","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.45623,34.83976],[-91.411135,34.810702],[-91.370598,34.824276],[-91.36856,34.912735],[-91.367172,34.978298],[-91.45623,34.83976]]]},"properties":{"id":"72017","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.693774,34.916236],[-91.648314,34.612762],[-91.555187,34.677012],[-91.457056,34.839562],[-91.693774,34.916236]]]},"properties":{"id":"72064","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.412519,34.597924],[-91.465675,34.602158],[-91.466255,34.572681],[-91.37782,34.56348],[-91.412519,34.597924]]]},"properties":{"id":"72170","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.354904,34.639406],[-92.417057,34.649236],[-92.411678,34.571017],[-92.403779,34.508132],[-92.356186,34.546771],[-92.354904,34.639406]]]},"properties":{"id":"72103","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.39074,34.765487],[-92.443904,34.774492],[-92.43805,34.740799],[-92.40137,34.72123],[-92.390971,34.742983],[-92.390905,34.763478],[-92.39074,34.765487]]]},"properties":{"id":"72211","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.436108,34.842218],[-92.376182,34.799845],[-92.413931,34.931611],[-92.436108,34.842218]]]},"properties":{"id":"72113","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.381135,34.797241],[-92.443904,34.774492],[-92.39074,34.765487],[-92.381135,34.797241]]]},"properties":{"id":"72212","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.749838,36.360841],[-90.749207,36.382597],[-90.748881,36.397022],[-90.749018,36.412874],[-90.761287,36.412679],[-90.804517,36.356276],[-90.749838,36.360841]]]},"properties":{"id":"72462","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.521835,34.581276],[-92.486535,34.569827],[-92.444571,34.640625],[-92.444562,34.640822],[-92.50185,34.639684],[-92.523104,34.609366],[-92.521835,34.581276]]]},"properties":{"id":"72022","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.823107,34.876695],[-93.709837,34.768658],[-93.709211,34.801953],[-93.708327,34.847824],[-93.707276,34.905082],[-93.823107,34.876695]]]},"properties":{"id":"72841","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.951315,35.724663],[-92.868502,35.72339],[-92.803155,35.751413],[-92.950951,35.740389],[-92.951315,35.724663]]]},"properties":{"id":"72686","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.434941,35.291693],[-94.387363,35.32727],[-94.400709,35.331003],[-94.430972,35.328378],[-94.43362,35.328485],[-94.434941,35.291693]]]},"properties":{"id":"72908","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.222602,33.971314],[-94.387182,33.919256],[-94.304174,33.824116],[-94.231637,33.801663],[-94.222602,33.971314]]]},"properties":{"id":"71842","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.354284,35.974773],[-92.245994,35.913189],[-92.215581,35.909621],[-92.305882,36.034451],[-92.354284,35.974773]]]},"properties":{"id":"72533","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.67294,35.253843],[-91.670084,35.223696],[-91.642857,35.25723],[-91.67294,35.253843]]]},"properties":{"id":"72082","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.72776,35.248634],[-91.72467,35.248604],[-91.724663,35.249546],[-91.727745,35.249592],[-91.72776,35.248634]]]},"properties":{"id":"72149","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.241156,35.258863],[-91.243907,35.256428],[-91.243925,35.2506],[-91.235031,35.250549],[-91.241156,35.258863]]]},"properties":{"id":"72123","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.529128,36.498599],[-92.583471,36.396438],[-92.549599,36.386921],[-92.499636,36.405446],[-92.44918,36.38025],[-92.474487,36.256876],[-92.425948,36.17833],[-92.248274,36.245807],[-92.232124,36.265836],[-92.23103,36.352067],[-92.248471,36.386363],[-92.311157,36.404414],[-92.379428,36.497412],[-92.529128,36.498599]]]},"properties":{"id":"72653","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.534812,36.329314],[-92.520581,36.268824],[-92.52124,36.29839],[-92.534812,36.329314]]]},"properties":{"id":"72626","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.590638,36.334859],[-94.367586,36.312527],[-94.344912,36.334076],[-94.561312,36.35879],[-94.590638,36.334859]]]},"properties":{"id":"72722","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.065307,36.440879],[-93.867189,36.365184],[-93.866836,36.376659],[-93.866758,36.498735],[-94.094474,36.498683],[-94.065307,36.440879]]]},"properties":{"id":"72732","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.916392,36.497835],[-92.893723,36.452035],[-92.888332,36.490867],[-92.912047,36.497846],[-92.916392,36.497835]]]},"properties":{"id":"72630","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.340018,33.431367],[-92.350432,33.298941],[-92.32648,33.264174],[-92.137527,33.162956],[-92.126599,33.209342],[-92.262162,33.435892],[-92.340018,33.431367]]]},"properties":{"id":"71651","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.762045,36.304677],[-93.797305,36.237069],[-93.848965,36.059025],[-93.85869,35.976658],[-93.80287,35.91688],[-93.73723,35.883334],[-93.557362,35.952919],[-93.480178,36.08524],[-93.444504,36.124293],[-93.663605,36.28174],[-93.762045,36.304677]]]},"properties":{"id":"72740","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.052327,34.124549],[-93.050738,34.126423],[-93.053198,34.126217],[-93.052327,34.124549]]]},"properties":{"id":"71998","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.584285,36.498575],[-90.784424,36.498534],[-90.761287,36.412679],[-90.749018,36.412874],[-90.584285,36.498575]]]},"properties":{"id":"72470","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.806615,36.266865],[-90.606731,36.265273],[-90.696159,36.33304],[-90.750605,36.33194],[-90.806214,36.28817],[-90.806615,36.266865]]]},"properties":{"id":"72453","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.118449,35.52265],[-92.248817,35.523533],[-92.277863,35.407512],[-92.316535,35.353565],[-92.303866,35.323061],[-92.28547,35.306701],[-92.215523,35.299547],[-92.183563,35.315931],[-92.11235,35.32947],[-92.06142,35.401438],[-92.118449,35.52265]]]},"properties":{"id":"72131","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.970392,35.564359],[-92.034731,35.538096],[-91.92795,35.536382],[-91.934663,35.565248],[-91.970392,35.564359]]]},"properties":{"id":"72581","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.219285,35.589277],[-92.301262,35.61706],[-92.316967,35.550872],[-92.304565,35.555364],[-92.223474,35.588353],[-92.219285,35.589277]]]},"properties":{"id":"72088","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.324252,33.516728],[-93.425866,33.308939],[-93.436389,33.248787],[-93.253479,33.334949],[-93.239067,33.441902],[-93.219824,33.483734],[-93.324252,33.516728]]]},"properties":{"id":"71770","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.743585,35.570774],[-92.703364,35.676984],[-92.810817,35.679196],[-92.85096,35.44586],[-92.852658,35.35692],[-92.775258,35.386665],[-92.767505,35.461367],[-92.743585,35.570774]]]},"properties":{"id":"72080","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.766227,35.848138],[-90.592401,35.77949],[-90.535423,35.79356],[-90.517403,35.878071],[-90.62281,35.965922],[-90.709195,35.991345],[-90.766227,35.848138]],[[-90.675697,35.842239],[-90.675476,35.840748],[-90.677338,35.838326],[-90.67717,35.842735],[-90.675697,35.842239]]]},"properties":{"id":"72401","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.908152,35.706519],[-90.6949,35.705019],[-90.643808,35.704809],[-90.592401,35.77949],[-90.766227,35.848138],[-90.860213,35.872637],[-90.909439,35.847191],[-90.908152,35.706519]]]},"properties":{"id":"72404","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.27434,35.508465],[-94.357409,35.560346],[-94.404696,35.611746],[-94.462197,35.574894],[-94.431667,35.39768],[-94.423008,35.422347],[-94.342112,35.37859],[-94.305366,35.353308],[-94.297266,35.350945],[-94.289982,35.356045],[-94.27434,35.508465]]]},"properties":{"id":"72956","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.301995,35.438726],[-90.282679,35.39864],[-90.223734,35.437731],[-90.301995,35.438726]]]},"properties":{"id":"72339","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.230022,35.294576],[-90.292778,35.147411],[-90.255433,35.13216],[-90.184547,35.177457],[-90.15385,35.302512],[-90.208691,35.309967],[-90.230022,35.294576]]]},"properties":{"id":"72364","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.306833,35.101161],[-90.306871,35.103893],[-90.311229,35.103947],[-90.311286,35.100298],[-90.306833,35.101161]]]},"properties":{"id":"72332","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.426456,34.125359],[-92.7343,34.021661],[-92.684365,33.93101],[-92.59581,33.92628],[-92.473914,34.006227],[-92.336765,34.059633],[-92.394041,34.104489],[-92.426456,34.125359]]]},"properties":{"id":"71725","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.992088,34.148109],[-91.044193,34.192217],[-91.044284,34.17094],[-90.992088,34.148109]]],[[[-90.992088,34.148109],[-90.984629,34.118885],[-90.958326,34.118808],[-90.992088,34.148109]]]]},"properties":{"id":"72328","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.896249,33.791657],[-91.890448,33.454148],[-91.81535,33.42751],[-91.746558,33.436886],[-91.645619,33.508098],[-91.578643,33.67362],[-91.592587,33.786401],[-91.896249,33.791657]]]},"properties":{"id":"71655","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.41887,34.989468],[-92.344991,34.96014],[-92.263508,34.993799],[-92.256979,35.003269],[-92.306639,35.15207],[-92.416799,35.156762],[-92.429788,35.15385],[-92.463024,35.160583],[-92.499985,35.169575],[-92.514945,35.130447],[-92.41887,34.989468]]]},"properties":{"id":"72032","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.41887,34.989468],[-92.560971,35.035417],[-92.544341,34.953319],[-92.444877,34.846919],[-92.436108,34.842218],[-92.413931,34.931611],[-92.388751,34.930734],[-92.34583,34.95901],[-92.344991,34.96014],[-92.41887,34.989468]]]},"properties":{"id":"72106","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.463024,35.160583],[-92.429788,35.15385],[-92.416799,35.156762],[-92.463024,35.160583]]]},"properties":{"id":"72181","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.622879,36.340435],[-91.349135,36.420863],[-91.407138,36.497081],[-91.75927,36.499208],[-91.742251,36.443397],[-91.673093,36.362896],[-91.646399,36.336239],[-91.622879,36.340435]]]},"properties":{"id":"72554","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.891793,34.686428],[-92.969403,34.714176],[-92.96949,34.701246],[-92.891793,34.686428]]],[[[-92.769575,34.54298],[-92.855809,34.675109],[-92.856014,34.667658],[-92.875902,34.620425],[-92.879894,34.505431],[-92.769575,34.54298]]]]},"properties":{"id":"72087","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.403555,34.601992],[-93.405284,34.514001],[-93.406164,34.468848],[-93.308508,34.450018],[-93.219671,34.472825],[-93.189444,34.533209],[-93.222693,34.604236],[-93.403555,34.601992]]]},"properties":{"id":"71968","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.189444,34.533209],[-93.11952,34.570973],[-93.111829,34.630446],[-93.222693,34.604236],[-93.189444,34.533209]]]},"properties":{"id":"71956","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.395088,34.49564],[-92.516964,34.453882],[-92.53787,34.401323],[-92.583321,34.281624],[-92.586212,34.263381],[-92.426456,34.125359],[-92.394041,34.104489],[-92.291754,34.255111],[-92.210146,34.3473],[-92.250935,34.418308],[-92.298497,34.494347],[-92.395088,34.49564]]]},"properties":{"id":"72150","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.583321,34.281624],[-92.53787,34.401323],[-92.609969,34.383863],[-92.591334,34.297634],[-92.583321,34.281624]]]},"properties":{"id":"72129","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.562022,33.958031],[-93.720544,33.913266],[-93.635244,33.858618],[-93.617116,33.869855],[-93.525615,33.933484],[-93.526033,33.943637],[-93.562022,33.958031]]]},"properties":{"id":"71847","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.963707,33.775122],[-93.958828,33.750994],[-93.960849,33.695179],[-93.960726,33.694577],[-93.830861,33.769932],[-93.963707,33.775122]]]},"properties":{"id":"71859","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.279466,36.143487],[-91.297714,36.132726],[-91.2756,36.11738],[-91.279466,36.143487]]],[[[-91.082817,36.150966],[-91.24399,36.119454],[-91.20796,36.09538],[-91.117475,36.078876],[-91.087832,36.094272],[-91.073465,36.114894],[-91.082817,36.150966]]]]},"properties":{"id":"72415","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.479813,33.856711],[-94.327593,33.551554],[-94.278356,33.763709],[-94.306867,33.824336],[-94.479813,33.856711]]]},"properties":{"id":"71836","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.278356,33.763709],[-94.327593,33.551554],[-94.044958,33.55171],[-93.915575,33.632895],[-93.960726,33.694577],[-93.960849,33.695179],[-94.147918,33.790927],[-94.148911,33.791462],[-94.181932,33.788779],[-94.278356,33.763709]],[[-94.145585,33.741261],[-94.155459,33.734071],[-94.155423,33.748579],[-94.145585,33.741261]]]},"properties":{"id":"71822","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.140571,35.099678],[-94.126997,35.003261],[-93.705146,35.006454],[-93.707604,35.101464],[-93.955051,35.226584],[-94.028547,35.213826],[-94.038866,35.181115],[-94.158982,35.143889],[-94.140571,35.099678]]]},"properties":{"id":"72927","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.418904,35.327128],[-93.428385,35.320502],[-93.385671,35.214039],[-93.278293,35.317402],[-93.329423,35.341553],[-93.418904,35.327128]]]},"properties":{"id":"72835","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.814133,34.484234],[-91.678037,34.48192],[-91.7029,34.580347],[-91.738385,34.587552],[-91.814708,34.484268],[-91.814133,34.484234]]]},"properties":{"id":"72072","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.985108,34.999984],[-91.838959,34.940514],[-91.803272,34.954339],[-91.736786,34.989826],[-91.690622,35.05906],[-91.69664,35.062278],[-91.740825,35.050672],[-91.950873,35.045278],[-92.003,34.996872],[-91.985108,34.999984]]]},"properties":{"id":"72176","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.616363,36.378139],[-92.594891,36.355946],[-92.549599,36.386921],[-92.583471,36.396438],[-92.58889,36.397896],[-92.616363,36.378139]]]},"properties":{"id":"72619","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.847119,36.241848],[-92.822818,36.29114],[-92.856821,36.299532],[-92.861872,36.274752],[-92.847119,36.241848]]]},"properties":{"id":"72672","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.825884,33.609853],[-94.04345,33.552253],[-94.042987,33.271242],[-93.999845,33.242159],[-93.778204,33.361594],[-93.70366,33.357322],[-93.722867,33.481963],[-93.733843,33.497248],[-93.825884,33.609853]]]},"properties":{"id":"71854","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.80493,33.019357],[-93.838337,33.148692],[-93.999845,33.242159],[-94.042987,33.271242],[-94.043026,33.108368],[-94.035564,33.076793],[-94.043035,33.074435],[-93.80493,33.019357]]]},"properties":{"id":"71834","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.025257,35.603984],[-90.014692,35.565625],[-89.949729,35.654006],[-90.025257,35.603984]]]},"properties":{"id":"72329","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.025257,35.603984],[-90.080692,35.654863],[-90.128085,35.663926],[-90.142771,35.647882],[-90.160158,35.611089],[-90.106677,35.590647],[-90.030438,35.558889],[-90.014692,35.565625],[-90.025257,35.603984]]]},"properties":{"id":"72395","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.971346,35.813984],[-89.927022,35.814832],[-89.920943,35.828389],[-89.971346,35.813984]]]},"properties":{"id":"72321","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.36856,34.912735],[-91.184737,35.003159],[-91.177818,35.061185],[-91.299171,35.093398],[-91.363754,35.051186],[-91.367172,34.978298],[-91.36856,34.912735]]]},"properties":{"id":"72036","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.810279,34.542169],[-93.933008,34.521468],[-93.934689,34.435721],[-93.588494,34.423305],[-93.406164,34.468848],[-93.405284,34.514001],[-93.810279,34.542169]]]},"properties":{"id":"71960","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.105827,33.740719],[-93.213082,33.731945],[-93.107829,33.679201],[-93.105827,33.740719]]]},"properties":{"id":"71722","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.065659,35.959125],[-93.133923,35.903089],[-93.05521,35.958667],[-93.065659,35.959125]]]},"properties":{"id":"72683","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.385393,35.907758],[-93.341244,36.050296],[-93.402694,35.974216],[-93.431881,35.937769],[-93.417683,35.932016],[-93.385393,35.907758]]]},"properties":{"id":"72670","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.107829,33.679201],[-93.113,33.540322],[-93.05099,33.52889],[-92.863841,33.791421],[-92.896495,33.806162],[-92.921979,33.819165],[-93.10429,33.777011],[-93.105827,33.740719],[-93.107829,33.679201]]]},"properties":{"id":"71726","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.647877,33.367623],[-92.884004,33.374702],[-92.832927,33.2759],[-92.647877,33.367623]]]},"properties":{"id":"71762","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.621844,35.077694],[-92.746536,34.911178],[-92.709859,34.885034],[-92.544341,34.953319],[-92.560971,35.035417],[-92.554685,35.113277],[-92.575393,35.107987],[-92.621844,35.077694]]]},"properties":{"id":"72016","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.76448,34.366847],[-90.821298,34.365211],[-90.950218,34.340801],[-91.001036,34.263263],[-90.833564,34.263229],[-90.76448,34.366847]]]},"properties":{"id":"72333","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.053558,34.529816],[-91.054774,34.47088],[-91.008022,34.469588],[-91.053558,34.529816]]]},"properties":{"id":"72383","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.341146,35.700513],[-90.353236,35.671454],[-90.30626,35.700363],[-90.341146,35.700513]]]},"properties":{"id":"72377","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.263891,34.822972],[-92.245991,34.832442],[-92.259458,34.840953],[-92.263891,34.822972]]],[[[-92.388751,34.930734],[-92.413931,34.931611],[-92.376182,34.799845],[-92.376135,34.799713],[-92.362441,34.792383],[-92.309151,34.783791],[-92.277675,34.785497],[-92.265634,34.818442],[-92.32085,34.841972],[-92.319936,34.875089],[-92.388751,34.930734]]]]},"properties":{"id":"72118","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.653379,34.764495],[-92.43805,34.740799],[-92.443904,34.774492],[-92.381135,34.797241],[-92.376135,34.799713],[-92.376182,34.799845],[-92.436108,34.842218],[-92.444877,34.846919],[-92.652557,34.813204],[-92.653379,34.764495]]]},"properties":{"id":"72223","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.321835,34.768293],[-92.363695,34.790928],[-92.358456,34.762287],[-92.321835,34.768293]]]},"properties":{"id":"72207","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.652557,34.813204],[-92.67846,34.852213],[-92.817713,34.847244],[-92.846795,34.681686],[-92.671877,34.735898],[-92.654002,34.753963],[-92.653379,34.764495],[-92.652557,34.813204]]]},"properties":{"id":"72122","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.300515,34.839395],[-92.300591,34.837865],[-92.284002,34.828955],[-92.300515,34.839395]]]},"properties":{"id":"72119","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.97243,36.397925],[-90.840972,36.339425],[-90.804517,36.356276],[-90.761287,36.412679],[-90.784424,36.498534],[-90.943977,36.498461],[-90.97243,36.397925]]]},"properties":{"id":"72444","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.111587,36.419543],[-91.074668,36.432246],[-90.97243,36.397925],[-90.943977,36.498461],[-91.172371,36.497515],[-91.111587,36.419543]]]},"properties":{"id":"72478","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.839335,36.193547],[-90.809816,36.149567],[-90.808693,36.193536],[-90.839335,36.193547]]]},"properties":{"id":"72449","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.705707,34.983833],[-93.706703,34.932035],[-93.638455,34.947539],[-93.502731,34.946999],[-93.392002,35.00572],[-93.175571,35.0594],[-93.296632,35.142325],[-93.531268,35.078806],[-93.705707,34.983833]]]},"properties":{"id":"72833","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.414765,35.883872],[-92.793178,35.722243],[-92.810179,35.706968],[-92.810817,35.679196],[-92.703364,35.676984],[-92.63104,35.731684],[-92.470401,35.789075],[-92.347647,35.771799],[-92.367467,35.853126],[-92.414765,35.883872]]]},"properties":{"id":"72645","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.17904,35.304469],[-94.075855,35.423035],[-94.074895,35.445403],[-94.142437,35.45024],[-94.233765,35.354072],[-94.289729,35.320067],[-94.289672,35.320033],[-94.17904,35.304469]]]},"properties":{"id":"72941","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.423008,35.422347],[-94.398595,35.385922],[-94.342112,35.37859],[-94.423008,35.422347]]]},"properties":{"id":"72904","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.373671,35.921638],[-94.495779,35.835513],[-94.431663,35.758042],[-94.391562,35.757324],[-94.373671,35.921638]],[[-94.444398,35.859991],[-94.456831,35.872433],[-94.411472,35.871434],[-94.444398,35.859991]]]},"properties":{"id":"72717","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.515701,35.360809],[-91.508987,35.354275],[-91.498594,35.366703],[-91.511323,35.364914],[-91.515701,35.360809]]]},"properties":{"id":"72139","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.465114,35.089454],[-91.363754,35.051186],[-91.299171,35.093398],[-91.334892,35.131428],[-91.276372,35.18248],[-91.243907,35.256428],[-91.241156,35.258863],[-91.250339,35.35755],[-91.349952,35.44497],[-91.411863,35.265072],[-91.427813,35.193684],[-91.436001,35.181873],[-91.465114,35.089454]]]},"properties":{"id":"72006","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.708327,34.847824],[-93.626861,34.927509],[-93.638455,34.947539],[-93.706703,34.932035],[-93.707276,34.905082],[-93.708327,34.847824]]]},"properties":{"id":"72838","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.183449,34.398676],[-91.308949,34.430678],[-91.47409,34.279486],[-91.441031,34.107992],[-91.269584,34.155578],[-91.189497,34.176908],[-91.15483,34.35442],[-91.183449,34.398676]]]},"properties":{"id":"72042","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.749867,34.402339],[-91.710096,34.29438],[-91.592535,34.228761],[-91.678037,34.48192],[-91.814133,34.484234],[-91.749867,34.402339]]]},"properties":{"id":"72073","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.81535,33.42751],[-91.95995,33.378264],[-92.036501,33.268501],[-91.86985,33.007633],[-91.716519,33.007329],[-91.697305,33.102688],[-91.635668,33.226263],[-91.641433,33.392195],[-91.587221,33.453055],[-91.746558,33.436886],[-91.81535,33.42751]]]},"properties":{"id":"71646","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.150295,36.49861],[-92.259457,36.498048],[-92.311157,36.404414],[-92.248471,36.386363],[-92.151121,36.478272],[-92.150295,36.49861]]]},"properties":{"id":"72537","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.44918,36.38025],[-92.523786,36.356681],[-92.53477,36.350916],[-92.534812,36.329314],[-92.52124,36.29839],[-92.520581,36.268824],[-92.474487,36.256876],[-92.44918,36.38025]]]},"properties":{"id":"72635","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.216248,36.306765],[-94.194852,36.266537],[-94.041729,36.282131],[-94.183634,36.335049],[-94.216248,36.306765]]]},"properties":{"id":"72758","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.347594,36.444566],[-94.292199,36.431341],[-94.233386,36.418902],[-94.276117,36.499267],[-94.383813,36.499449],[-94.373945,36.476475],[-94.347594,36.444566]]]},"properties":{"id":"72715","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.579211,36.258379],[-94.547739,36.077424],[-94.46201,36.084898],[-94.398997,36.099554],[-94.395809,36.09949],[-94.33488,36.180757],[-94.331891,36.21468],[-94.579211,36.258379]]]},"properties":{"id":"72761","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.276117,36.499267],[-94.233386,36.418902],[-94.162355,36.472168],[-94.181413,36.498815],[-94.276117,36.499267]]]},"properties":{"id":"72714","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.912047,36.497846],[-92.888332,36.490867],[-92.797343,36.473038],[-92.716373,36.460884],[-92.735102,36.497992],[-92.912047,36.497846]]]},"properties":{"id":"65733","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.196606,33.471786],[-92.262162,33.435892],[-92.126599,33.209342],[-92.036501,33.268501],[-91.95995,33.378264],[-91.990167,33.398214],[-92.007869,33.529404],[-92.196606,33.471786]]]},"properties":{"id":"71647","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.440588,36.456275],[-93.295759,36.457146],[-93.296117,36.498251],[-93.439837,36.498579],[-93.440588,36.456275]]]},"properties":{"id":"72660","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.230619,36.342118],[-90.10406,36.316329],[-90.16943,36.3818],[-90.230619,36.342118]]]},"properties":{"id":"72430","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.749207,36.382597],[-90.722393,36.386427],[-90.721033,36.397516],[-90.748881,36.397022],[-90.749207,36.382597]]]},"properties":{"id":"72424","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.223474,35.588353],[-92.105527,35.616217],[-92.100931,35.708956],[-92.24052,35.71219],[-92.219285,35.589277],[-92.223474,35.588353]]]},"properties":{"id":"72044","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.659341,35.758196],[-91.836081,35.748855],[-91.88061,35.733778],[-91.881715,35.705681],[-91.859685,35.705313],[-91.74653,35.647318],[-91.724126,35.658846],[-91.701093,35.727122],[-91.689664,35.7521],[-91.659341,35.758196]]]},"properties":{"id":"72550","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.859685,35.705313],[-91.828297,35.566409],[-91.74653,35.647318],[-91.859685,35.705313]]]},"properties":{"id":"72523","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.709894,35.414285],[-91.866852,35.396731],[-91.938911,35.359534],[-91.97625,35.311966],[-91.967529,35.19128],[-91.963545,35.184721],[-91.907646,35.204453],[-91.817108,35.215082],[-91.751073,35.059521],[-91.740825,35.050672],[-91.69664,35.062278],[-91.506745,35.090207],[-91.465114,35.089454],[-91.436001,35.181873],[-91.554606,35.206825],[-91.613002,35.26258],[-91.642857,35.25723],[-91.670084,35.223696],[-91.67294,35.253843],[-91.709894,35.414285]],[[-91.72776,35.248634],[-91.727745,35.249592],[-91.724663,35.249546],[-91.72467,35.248604],[-91.72776,35.248634]],[[-91.825112,35.360571],[-91.831972,35.358132],[-91.833891,35.369076],[-91.825093,35.369063],[-91.825112,35.360571]],[[-91.720906,35.198392],[-91.706264,35.202053],[-91.710556,35.187622],[-91.720906,35.198392]]]},"properties":{"id":"72143","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.253479,33.334949],[-93.436389,33.248787],[-93.392873,33.206522],[-93.359203,33.088524],[-92.985063,33.143531],[-92.945856,33.158607],[-92.94745,33.245977],[-92.980135,33.320874],[-93.118278,33.402205],[-93.253479,33.334949]]]},"properties":{"id":"71753","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.535832,33.477414],[-93.592015,33.219456],[-93.392873,33.206522],[-93.436389,33.248787],[-93.425866,33.308939],[-93.46461,33.44699],[-93.469493,33.493003],[-93.482468,33.497435],[-93.535832,33.477414]]]},"properties":{"id":"71860","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.782593,35.223767],[-92.852658,35.35692],[-92.85096,35.44586],[-92.904828,35.413174],[-92.951729,35.253773],[-93.038198,35.157629],[-93.027647,35.159793],[-92.896109,35.170678],[-92.782593,35.223767]]]},"properties":{"id":"72823","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.61827,35.235761],[-92.641628,35.108971],[-92.621844,35.077694],[-92.575393,35.107987],[-92.519167,35.18404],[-92.529512,35.195564],[-92.61827,35.235761]]]},"properties":{"id":"72127","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.767505,35.461367],[-92.775258,35.386665],[-92.676565,35.361641],[-92.607026,35.437967],[-92.60248,35.458455],[-92.767505,35.461367]]]},"properties":{"id":"72030","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.361037,35.533449],[-92.506672,35.438219],[-92.481342,35.371446],[-92.277863,35.407512],[-92.248817,35.523533],[-92.304565,35.555364],[-92.316967,35.550872],[-92.361037,35.533449]]]},"properties":{"id":"72013","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.13077,35.574913],[-94.234093,35.568848],[-94.27434,35.508465],[-94.289982,35.356045],[-94.233765,35.354072],[-94.142437,35.45024],[-94.13348,35.475198],[-94.131447,35.508255],[-94.13077,35.574913]]]},"properties":{"id":"72921","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.13077,35.574913],[-94.020353,35.661492],[-94.205941,35.752849],[-94.253432,35.608358],[-94.234093,35.568848],[-94.13077,35.574913]]]},"properties":{"id":"72946","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.634696,35.449051],[-90.82382,35.44575],[-90.89477,35.44309],[-90.932287,35.323439],[-90.65038,35.360423],[-90.610867,35.443588],[-90.634696,35.449051]]]},"properties":{"id":"72324","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.65038,35.360423],[-90.636971,35.146949],[-90.627889,35.146738],[-90.601138,35.17517],[-90.502481,35.439298],[-90.502429,35.441726],[-90.610867,35.443588],[-90.65038,35.360423]]]},"properties":{"id":"72373","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.470193,33.846043],[-91.52838,33.86465],[-91.53712,33.83275],[-91.480093,33.802391],[-91.470193,33.846043]]]},"properties":{"id":"71662","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.183563,35.315931],[-92.215523,35.299547],[-92.204359,35.274905],[-92.183563,35.315931]]],[[[-92.203869,35.274385],[-92.278828,35.190295],[-92.192858,35.177575],[-92.203869,35.274385]]]]},"properties":{"id":"72047","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.705473,35.500342],[-93.749295,35.364572],[-93.710076,35.375242],[-93.66341,35.448394],[-93.705473,35.500342]]]},"properties":{"id":"72821","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.143608,34.511185],[-92.356186,34.546771],[-92.403779,34.508132],[-92.395088,34.49564],[-92.298497,34.494347],[-92.14369,34.49151],[-92.143608,34.511185]]]},"properties":{"id":"72065","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.606731,36.265273],[-90.806615,36.266865],[-90.845514,36.193622],[-90.839335,36.193547],[-90.808693,36.193536],[-90.809816,36.149567],[-90.760219,36.149051],[-90.700673,36.18007],[-90.606731,36.265273]]]},"properties":{"id":"72425","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.022874,34.273941],[-92.856126,34.154513],[-93.044402,34.333779],[-93.022874,34.273941]]]},"properties":{"id":"71941","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.59155,34.379219],[-93.839536,34.34785],[-93.848849,34.311804],[-93.85011,34.309109],[-93.795086,34.274226],[-93.671559,34.286507],[-93.408124,34.339527],[-93.59155,34.379219]]]},"properties":{"id":"71943","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.103247,34.238443],[-94.031285,34.275803],[-93.880507,34.299854],[-93.85011,34.309109],[-93.848849,34.311804],[-93.918731,34.34975],[-93.935371,34.350097],[-94.16071,34.297311],[-94.127633,34.250976],[-94.103247,34.238443]]]},"properties":{"id":"71971","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.533428,35.572044],[-91.49344,35.581137],[-91.47329,35.590764],[-91.416996,35.569805],[-91.369391,35.547804],[-91.440956,35.639903],[-91.480384,35.643485],[-91.543668,35.616507],[-91.533428,35.572044]]]},"properties":{"id":"72564","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.752429,35.865007],[-91.759542,35.900099],[-91.792912,35.898274],[-91.752429,35.865007]]]},"properties":{"id":"72526","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.066933,36.069165],[-91.890386,36.119776],[-91.888841,36.160796],[-91.962766,36.176085],[-92.046933,36.133646],[-92.066933,36.069165]]]},"properties":{"id":"72517","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.836532,36.204341],[-91.818948,36.19658],[-91.783541,36.196253],[-91.788218,36.254631],[-91.861767,36.255813],[-91.836532,36.204341]]]},"properties":{"id":"72587","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.18836,35.37143],[-91.217282,35.429508],[-91.252698,35.429872],[-91.250339,35.35755],[-91.18836,35.37143]]]},"properties":{"id":"72169","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.299171,35.093398],[-91.177818,35.061185],[-91.041925,35.192824],[-91.040218,35.287935],[-91.102023,35.429039],[-91.119438,35.42743],[-91.122915,35.436881],[-91.18836,35.37143],[-91.250339,35.35755],[-91.241156,35.258863],[-91.235031,35.250549],[-91.243925,35.2506],[-91.243907,35.256428],[-91.276372,35.18248],[-91.299171,35.093398]]]},"properties":{"id":"72101","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.05526,35.64933],[-91.06985,35.64952],[-91.091957,35.613304],[-91.05526,35.64933]]]},"properties":{"id":"72431","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.866224,34.486083],[-91.897534,34.411114],[-91.912616,34.266924],[-91.771939,34.171628],[-91.727313,34.173738],[-91.601362,34.131987],[-91.592535,34.228761],[-91.710096,34.29438],[-91.8251,34.308984],[-91.749867,34.402339],[-91.814133,34.484234],[-91.814708,34.484268],[-91.866224,34.486083]]]},"properties":{"id":"72004","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.084689,34.403132],[-91.897534,34.411114],[-91.866224,34.486083],[-92.112396,34.445572],[-92.084689,34.403132]]]},"properties":{"id":"72168","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.550801,35.400219],[-93.67353,35.52002],[-93.705473,35.500342],[-93.66341,35.448394],[-93.639146,35.385997],[-93.614202,35.384146],[-93.550801,35.400219]]]},"properties":{"id":"72840","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.306448,35.779017],[-93.36721,35.58818],[-93.222746,35.556002],[-93.135562,35.714595],[-93.164452,35.728831],[-93.306448,35.779017]]]},"properties":{"id":"72839","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.66341,35.448394],[-93.710076,35.375242],[-93.639146,35.385997],[-93.66341,35.448394]]]},"properties":{"id":"72832","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.20796,36.09538],[-91.132328,36.007957],[-91.132018,36.00795],[-91.117475,36.078876],[-91.20796,36.09538]]]},"properties":{"id":"72458","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.082817,36.150966],[-91.126199,36.341559],[-91.209094,36.265651],[-91.279466,36.143487],[-91.2756,36.11738],[-91.24399,36.119454],[-91.082817,36.150966]]]},"properties":{"id":"72434","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.07269,36.09261],[-91.07284,36.08932],[-91.06382,36.08878],[-91.07269,36.09261]]]},"properties":{"id":"72457","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.265835,36.402157],[-91.324551,36.497404],[-91.407138,36.497081],[-91.349135,36.420863],[-91.292515,36.374746],[-91.351214,36.190637],[-91.403557,36.120169],[-91.297714,36.132726],[-91.279466,36.143487],[-91.209094,36.265651],[-91.265835,36.402157]]]},"properties":{"id":"72459","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.9003,34.72097],[-90.88365,34.71643],[-90.88243,34.73108],[-90.90004,34.73127],[-90.9003,34.72097]]]},"properties":{"id":"72311","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.915575,33.632895],[-94.044958,33.55171],[-94.04345,33.552253],[-93.825884,33.609853],[-93.915575,33.632895]]]},"properties":{"id":"71853","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.52695,35.305469],[-93.51298,35.261771],[-93.483889,35.191747],[-93.416553,35.214757],[-93.385671,35.214039],[-93.428385,35.320502],[-93.52695,35.305469]]]},"properties":{"id":"72851","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.940932,35.990069],[-92.945719,35.842098],[-92.589571,36.020531],[-92.581538,36.065388],[-92.697954,36.106371],[-92.768637,36.112715],[-92.887518,36.070248],[-92.86446,36.056807],[-92.882495,36.028076],[-92.940932,35.990069]],[[-92.722566,35.984983],[-92.718472,35.99476],[-92.710556,35.992195],[-92.717202,35.98479],[-92.722566,35.984983]]]},"properties":{"id":"72675","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.768637,36.112715],[-92.697954,36.106371],[-92.77791,36.14424],[-92.768637,36.112715]]]},"properties":{"id":"72682","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.080692,35.654863],[-90.025257,35.603984],[-89.949729,35.654006],[-89.94459,35.741183],[-90.010698,35.756159],[-90.072789,35.705623],[-90.080692,35.654863]]],[[[-90.177825,35.733571],[-90.213142,35.728302],[-90.249967,35.736217],[-90.286537,35.69496],[-90.286281,35.656074],[-90.201474,35.640652],[-90.142771,35.647882],[-90.128085,35.663926],[-90.089933,35.705825],[-90.143876,35.742125],[-90.177825,35.733571]]]]},"properties":{"id":"72370","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.080692,35.654863],[-90.072789,35.705623],[-90.089933,35.705825],[-90.128085,35.663926],[-90.080692,35.654863]]]},"properties":{"id":"72351","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.709585,35.072171],[-92.79258,35.03406],[-92.746536,34.911178],[-92.621844,35.077694],[-92.641628,35.108971],[-92.709585,35.072171]]]},"properties":{"id":"72070","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.950218,34.340801],[-90.821298,34.365211],[-90.867415,34.410731],[-90.874483,34.459999],[-91.002294,34.350644],[-90.950218,34.340801]]]},"properties":{"id":"72389","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.322657,35.547799],[-90.393971,35.599854],[-90.437313,35.587467],[-90.610867,35.443588],[-90.502429,35.441726],[-90.322657,35.547799]]]},"properties":{"id":"72365","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.168332,34.401876],[-94.459995,34.549885],[-94.46233,34.462924],[-94.270634,34.435221],[-94.168332,34.401876]]]},"properties":{"id":"71945","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.993451,35.40755],[-93.190523,35.366915],[-93.234656,35.273806],[-93.07796,35.178786],[-92.951729,35.253773],[-92.904828,35.413174],[-92.993451,35.40755]],[[-93.16558,35.287917],[-93.120096,35.296355],[-93.120934,35.260289],[-93.165331,35.266322],[-93.16558,35.287917]]]},"properties":{"id":"72802","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.076728,35.701096],[-92.993451,35.40755],[-92.904828,35.413174],[-92.85096,35.44586],[-92.810817,35.679196],[-92.810179,35.706968],[-92.868502,35.72339],[-92.951315,35.724663],[-93.076728,35.701096]]]},"properties":{"id":"72843","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.868502,35.72339],[-92.810179,35.706968],[-92.793178,35.722243],[-92.803155,35.751413],[-92.868502,35.72339]]]},"properties":{"id":"72679","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.693774,34.916236],[-91.457056,34.839562],[-91.45623,34.83976],[-91.367172,34.978298],[-91.363754,35.051186],[-91.465114,35.089454],[-91.506745,35.090207],[-91.690622,35.05906],[-91.736786,34.989826],[-91.693774,34.916236]]]},"properties":{"id":"72040","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.293371,34.754301],[-92.290164,34.756489],[-92.309151,34.783791],[-92.362441,34.792383],[-92.363695,34.790928],[-92.321835,34.768293],[-92.293371,34.754301]]],[[[-92.302522,34.725286],[-92.185586,34.729947],[-92.208547,34.743591],[-92.258349,34.748391],[-92.301934,34.743506],[-92.302021,34.743341],[-92.302522,34.725286]]]]},"properties":{"id":"72202","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.356186,34.546771],[-92.143608,34.511185],[-92.159442,34.562317],[-92.156994,34.667548],[-92.185586,34.729947],[-92.302522,34.725286],[-92.308573,34.716601],[-92.282661,34.707677],[-92.354904,34.639406],[-92.356186,34.546771]]]},"properties":{"id":"72206","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.265835,36.402157],[-91.209094,36.265651],[-91.126199,36.341559],[-91.265835,36.402157]]]},"properties":{"id":"72460","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.398595,35.385922],[-94.400709,35.331003],[-94.387363,35.32727],[-94.323666,35.322748],[-94.305366,35.353308],[-94.342112,35.37859],[-94.398595,35.385922]]]},"properties":{"id":"72903","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.323666,35.322748],[-94.326626,35.291356],[-94.289672,35.320033],[-94.289729,35.320067],[-94.297266,35.350945],[-94.305366,35.353308],[-94.323666,35.322748]]]},"properties":{"id":"72923","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.988707,33.017433],[-92.524954,33.012574],[-92.945856,33.158607],[-92.985063,33.143531],[-92.988707,33.017433]]]},"properties":{"id":"71749","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.547739,36.077424],[-94.523178,35.936488],[-94.426483,36.056874],[-94.46201,36.084898],[-94.547739,36.077424]]]},"properties":{"id":"72769","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963545,35.184721],[-92.014875,35.075471],[-91.950873,35.045278],[-91.740825,35.050672],[-91.751073,35.059521],[-91.907646,35.204453],[-91.963545,35.184721]]]},"properties":{"id":"72012","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.041925,35.192824],[-91.177818,35.061185],[-91.184737,35.003159],[-91.11631,35.03117],[-91.062851,35.023258],[-91.044885,35.023164],[-91.043801,35.07344],[-91.04267,35.148189],[-91.041925,35.192824]]]},"properties":{"id":"72074","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.482794,34.899582],[-93.482759,34.899242],[-93.358192,34.975464],[-93.392002,35.00572],[-93.502731,34.946999],[-93.482794,34.899582]]]},"properties":{"id":"72860","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.707604,35.101464],[-93.705146,35.006454],[-93.705707,34.983833],[-93.531268,35.078806],[-93.511234,35.165943],[-93.662979,35.148225],[-93.706733,35.130966],[-93.706752,35.128953],[-93.707604,35.101464]]]},"properties":{"id":"72842","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.47409,34.279486],[-91.308949,34.430678],[-91.321083,34.454347],[-91.36229,34.49008],[-91.47409,34.279486]]]},"properties":{"id":"72003","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.459363,33.302729],[-91.536336,33.235154],[-91.44464,33.169898],[-91.355873,33.16834],[-91.459363,33.302729]]]},"properties":{"id":"71663","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.248274,36.245807],[-92.371051,36.078064],[-92.3058,36.041856],[-92.097615,36.014755],[-92.066933,36.069165],[-92.046933,36.133646],[-92.063364,36.153253],[-92.158951,36.208631],[-92.157943,36.238256],[-92.15699,36.261248],[-92.232124,36.265836],[-92.248274,36.245807]]]},"properties":{"id":"72519","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.153192,36.383641],[-92.151121,36.478272],[-92.248471,36.386363],[-92.23103,36.352067],[-92.153192,36.383641]]]},"properties":{"id":"72544","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.042833,36.498624],[-92.150295,36.49861],[-92.151121,36.478272],[-92.153192,36.383641],[-92.073009,36.390329],[-92.042833,36.498624]]]},"properties":{"id":"72538","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.041729,36.282131],[-94.194852,36.266537],[-94.208767,36.245598],[-94.148973,36.228952],[-94.000199,36.230122],[-94.041729,36.282131]]]},"properties":{"id":"72745","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.181413,36.498815],[-94.162355,36.472168],[-94.141242,36.410544],[-94.065307,36.440879],[-94.094474,36.498683],[-94.181413,36.498815]]]},"properties":{"id":"72751","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.330839,33.707806],[-92.184267,33.705286],[-92.038555,33.70446],[-92.159091,33.850716],[-92.333281,33.792441],[-92.330839,33.707806]]]},"properties":{"id":"71660","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.398908,33.695264],[-92.52239,33.62789],[-92.650375,33.594602],[-92.664097,33.434745],[-92.567156,33.3672],[-92.532515,33.345488],[-92.522856,33.316502],[-92.350432,33.298941],[-92.340018,33.431367],[-92.323487,33.637289],[-92.398908,33.695264]],[[-92.402815,33.504478],[-92.38647,33.49657],[-92.39569,33.48386],[-92.402815,33.504478]]]},"properties":{"id":"71744","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.684365,33.93101],[-92.757456,33.792466],[-92.7221,33.651358],[-92.701869,33.649668],[-92.62451,33.69626],[-92.513273,33.800214],[-92.59581,33.92628],[-92.684365,33.93101]]]},"properties":{"id":"71720","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.439697,33.087394],[-91.459284,33.005909],[-91.160775,33.141399],[-91.355873,33.16834],[-91.44464,33.169898],[-91.439697,33.087394]]]},"properties":{"id":"71640","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.19864,34.03642],[-93.298395,33.862378],[-93.10429,33.777011],[-92.921979,33.819165],[-93.19864,34.03642]],[[-93.125114,33.834594],[-93.121867,33.828938],[-93.127902,33.828886],[-93.125114,33.834594]]]},"properties":{"id":"71743","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.314075,36.393869],[-90.245906,36.497809],[-90.370793,36.498332],[-90.392901,36.438949],[-90.384721,36.4345],[-90.314075,36.393869]]]},"properties":{"id":"72456","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.145135,36.455496],[-90.141803,36.461542],[-90.146969,36.460959],[-90.145135,36.455496]]]},"properties":{"id":"72464","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.476179,36.289088],[-90.520479,36.261444],[-90.491597,36.199482],[-90.407338,36.259427],[-90.476179,36.289088]]]},"properties":{"id":"72436","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.870491,34.093245],[-92.044496,34.22378],[-92.257209,34.17768],[-92.233376,34.062312],[-92.00942,34.013165],[-91.870491,34.093245]]]},"properties":{"id":"71603","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.519167,35.18404],[-92.575393,35.107987],[-92.554685,35.113277],[-92.514945,35.130447],[-92.499985,35.169575],[-92.519167,35.18404]]]},"properties":{"id":"72107","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.09194,35.58994],[-91.039022,35.520378],[-90.875752,35.536246],[-90.6949,35.705019],[-90.908152,35.706519],[-91.036967,35.708418],[-91.05526,35.64933],[-91.091957,35.613304],[-91.09194,35.58994]]]},"properties":{"id":"72479","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.401972,35.061206],[-90.310083,35.001041],[-90.172917,35.118662],[-90.255433,35.13216],[-90.292778,35.147411],[-90.403456,35.14814],[-90.401972,35.061206]],[[-90.306833,35.101161],[-90.311286,35.100298],[-90.311229,35.103947],[-90.306871,35.103893],[-90.306833,35.101161]]]},"properties":{"id":"72376","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.502481,35.439298],[-90.601138,35.17517],[-90.466819,35.14496],[-90.301929,35.336494],[-90.386945,35.366849],[-90.502481,35.439298]]]},"properties":{"id":"72331","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.208691,35.309967],[-90.15385,35.302512],[-90.140257,35.436636],[-90.17478,35.437074],[-90.215505,35.437609],[-90.223734,35.437731],[-90.282679,35.39864],[-90.301995,35.438726],[-90.386945,35.366849],[-90.301929,35.336494],[-90.271024,35.343464],[-90.208691,35.309967]]]},"properties":{"id":"72384","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.83754,34.083209],[-92.896495,33.806162],[-92.863841,33.791421],[-92.757456,33.792466],[-92.684365,33.93101],[-92.7343,34.021661],[-92.83754,34.083209]]]},"properties":{"id":"71763","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.037733,33.810846],[-91.051492,33.777443],[-91.000106,33.769165],[-90.991747,33.792597],[-91.037733,33.810846]]]},"properties":{"id":"38769","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.137038,33.953187],[-91.027415,33.907002],[-90.95517,34.118833],[-90.958326,34.118808],[-90.984629,34.118885],[-91.061136,34.119364],[-91.117905,34.119105],[-91.167702,34.094275],[-91.137038,33.953187]]]},"properties":{"id":"72379","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.192858,35.177575],[-92.11731,35.13847],[-92.080437,35.171715],[-92.101746,35.274639],[-92.11235,35.32947],[-92.183563,35.315931],[-92.204359,35.274905],[-92.203869,35.274385],[-92.192858,35.177575]]]},"properties":{"id":"72111","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.306639,35.15207],[-92.256979,35.003269],[-92.174748,35.013404],[-92.120386,35.044385],[-92.119719,35.066493],[-92.11731,35.13847],[-92.192858,35.177575],[-92.278828,35.190295],[-92.306639,35.15207]]]},"properties":{"id":"72173","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.646399,36.336239],[-91.740981,36.275578],[-91.736886,36.253922],[-91.744231,36.182711],[-91.714491,36.141409],[-91.572343,36.162668],[-91.565866,36.257435],[-91.629825,36.313461],[-91.622879,36.340435],[-91.646399,36.336239]]]},"properties":{"id":"72513","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.565866,36.257435],[-91.53203,36.33467],[-91.629825,36.313461],[-91.565866,36.257435]]]},"properties":{"id":"72529","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.015949,36.288891],[-92.052913,36.302813],[-92.108851,36.260051],[-92.017674,36.257976],[-92.015949,36.288891]]]},"properties":{"id":"72515","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.673093,36.362896],[-91.742251,36.443397],[-91.754455,36.349468],[-91.673093,36.362896]]]},"properties":{"id":"72520","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.744231,36.182711],[-91.736886,36.253922],[-91.788218,36.254631],[-91.783541,36.196253],[-91.744231,36.182711]]]},"properties":{"id":"72512","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.11952,34.570973],[-93.189444,34.533209],[-93.219671,34.472825],[-93.247539,34.366005],[-93.11375,34.35307],[-92.92612,34.44859],[-93.066194,34.552249],[-93.11952,34.570973]]]},"properties":{"id":"71913","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.066194,34.552249],[-92.92612,34.44859],[-92.879894,34.505431],[-92.875902,34.620425],[-93.066194,34.552249]]]},"properties":{"id":"71901","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.075178,34.771892],[-93.287785,34.773193],[-93.394289,34.743393],[-93.403503,34.610417],[-93.403555,34.601992],[-93.222693,34.604236],[-93.111829,34.630446],[-92.96949,34.701246],[-92.969403,34.714176],[-93.075178,34.771892]]]},"properties":{"id":"71949","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.050714,34.324144],[-92.139524,34.375402],[-92.210146,34.3473],[-92.291754,34.255111],[-92.257209,34.17768],[-92.044496,34.22378],[-91.913889,34.282908],[-92.050714,34.324144]]]},"properties":{"id":"71602","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.407338,36.259427],[-90.491597,36.199482],[-90.34165,36.146346],[-90.407338,36.259427]]]},"properties":{"id":"72443","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.512994,33.840849],[-93.473022,33.774469],[-93.372618,33.642434],[-93.213082,33.731945],[-93.105827,33.740719],[-93.10429,33.777011],[-93.298395,33.862378],[-93.373406,33.95707],[-93.526033,33.943637],[-93.525615,33.933484],[-93.512994,33.840849]]]},"properties":{"id":"71857","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.525615,33.933484],[-93.617116,33.869855],[-93.512994,33.840849],[-93.525615,33.933484]]]},"properties":{"id":"71825","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.711416,35.533219],[-91.627051,35.531814],[-91.533428,35.572044],[-91.543668,35.616507],[-91.579479,35.617239],[-91.60602,35.614777],[-91.711416,35.533219]]]},"properties":{"id":"72568","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.432772,35.85432],[-91.43646,35.828349],[-91.434341,35.825353],[-91.416004,35.831617],[-91.427543,35.855778],[-91.432772,35.85432]]],[[[-91.248007,35.831243],[-91.198556,35.890024],[-91.223081,35.890403],[-91.35723,35.890621],[-91.386696,35.835982],[-91.30064,35.766817],[-91.248007,35.831243]]]]},"properties":{"id":"72524","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.480384,35.643485],[-91.579479,35.617239],[-91.543668,35.616507],[-91.480384,35.643485]]]},"properties":{"id":"72571","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.005324,36.228491],[-91.962766,36.176085],[-91.888841,36.160796],[-91.836532,36.204341],[-91.861767,36.255813],[-91.961259,36.256874],[-92.005324,36.228491]]]},"properties":{"id":"72565","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.78774,36.022346],[-91.866859,35.961596],[-91.872643,35.881118],[-91.849448,35.867411],[-91.710552,35.941759],[-91.709573,35.976039],[-91.78774,36.022346]]]},"properties":{"id":"72561","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.158951,36.208631],[-92.063364,36.153253],[-92.005324,36.228491],[-91.961259,36.256874],[-92.017674,36.257976],[-92.108851,36.260051],[-92.15699,36.261248],[-92.157943,36.238256],[-92.118626,36.240092],[-92.122896,36.216472],[-92.158951,36.208631]]]},"properties":{"id":"72566","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.044496,34.22378],[-91.870491,34.093245],[-91.808581,34.094501],[-91.780077,34.13005],[-91.802409,34.154802],[-91.771939,34.171628],[-91.912616,34.266924],[-91.913889,34.282908],[-92.044496,34.22378]]]},"properties":{"id":"71601","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.802409,34.154802],[-91.766351,34.146903],[-91.780077,34.13005],[-91.808581,34.094501],[-91.709735,34.034405],[-91.608803,34.112273],[-91.727313,34.173738],[-91.771939,34.171628],[-91.802409,34.154802]]]},"properties":{"id":"71644","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.802409,34.154802],[-91.780077,34.13005],[-91.766351,34.146903],[-91.802409,34.154802]]]},"properties":{"id":"71659","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.234656,35.273806],[-93.190523,35.366915],[-93.226129,35.442453],[-93.330322,35.394873],[-93.329423,35.341553],[-93.278293,35.317402],[-93.234656,35.273806]]]},"properties":{"id":"72847","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.726249,33.181989],[-93.598691,33.111192],[-93.592015,33.219456],[-93.535832,33.477414],[-93.722867,33.481963],[-93.70366,33.357322],[-93.702603,33.251402],[-93.726249,33.181989]]]},"properties":{"id":"71845","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.208846,36.008052],[-91.247009,36.022395],[-91.281683,35.98612],[-91.208846,36.008052]]]},"properties":{"id":"72440","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.407991,34.83297],[-90.53434,34.90024],[-90.537602,34.756939],[-90.407991,34.83297]]]},"properties":{"id":"72320","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.707604,35.101464],[-93.706752,35.128953],[-93.707261,35.113327],[-93.711792,35.115255],[-93.711441,35.130887],[-93.706733,35.130966],[-93.662979,35.148225],[-93.919034,35.258331],[-93.954862,35.229962],[-93.955051,35.226584],[-93.707604,35.101464]]]},"properties":{"id":"72943","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.02954,34.621403],[-92.029743,34.56351],[-92.000351,34.602764],[-92.029493,34.624477],[-92.02954,34.621403]]]},"properties":{"id":"72083","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.942924,36.149259],[-94.147714,36.131361],[-94.200647,36.07826],[-94.007069,36.092345],[-93.937454,36.108379],[-93.942924,36.149259]]]},"properties":{"id":"72703","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.081683,35.8871],[-90.074476,35.784835],[-89.994316,35.785172],[-89.971346,35.813984],[-89.920943,35.828389],[-89.701938,35.82384],[-90.081683,35.8871]],[[-90.044263,35.861413],[-90.044517,35.884952],[-90.039088,35.877521],[-90.038484,35.867335],[-90.044263,35.861413]]]},"properties":{"id":"72315","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.994316,35.785172],[-90.010698,35.756159],[-89.94459,35.741183],[-89.701938,35.82384],[-89.920943,35.828389],[-89.927022,35.814832],[-89.971346,35.813984],[-89.994316,35.785172]]]},"properties":{"id":"72358","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.015877,34.645099],[-91.053558,34.529816],[-91.008022,34.469588],[-91.037511,34.336482],[-91.002294,34.350644],[-90.874483,34.459999],[-90.857964,34.482245],[-90.874295,34.643483],[-91.015877,34.645099]]]},"properties":{"id":"72366","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.57361,34.616302],[-93.403503,34.610417],[-93.394289,34.743393],[-93.499101,34.743877],[-93.533982,34.743885],[-93.560289,34.677524],[-93.57361,34.616302]]]},"properties":{"id":"71970","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.933008,34.521468],[-93.810279,34.542169],[-93.7263,34.616912],[-93.788416,34.680912],[-93.894805,34.665447],[-93.930509,34.665608],[-93.933008,34.521468]]]},"properties":{"id":"71961","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.341244,36.050296],[-93.385393,35.907758],[-93.339505,35.907071],[-93.265529,35.978101],[-93.242162,35.89708],[-93.192221,35.870882],[-93.158227,35.884688],[-93.133923,35.903089],[-93.065659,35.959125],[-93.106122,36.035977],[-93.25018,36.065105],[-93.341244,36.050296]]]},"properties":{"id":"72641","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.192221,35.870882],[-93.164452,35.728831],[-93.135562,35.714595],[-93.076728,35.701096],[-92.951315,35.724663],[-92.950951,35.740389],[-92.949757,35.805296],[-93.158227,35.884688],[-93.192221,35.870882]]]},"properties":{"id":"72856","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.839536,34.34785],[-93.918731,34.34975],[-93.848849,34.311804],[-93.839536,34.34785]]],[[[-93.85011,34.309109],[-93.880507,34.299854],[-93.808875,34.254331],[-93.795086,34.274226],[-93.85011,34.309109]]]]},"properties":{"id":"71952","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.810431,34.241631],[-93.691749,34.200314],[-93.602376,34.211307],[-93.606878,34.236225],[-93.671559,34.286507],[-93.795086,34.274226],[-93.808875,34.254331],[-93.810431,34.241631]]]},"properties":{"id":"71950","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.421499,34.042026],[-93.437686,34.019752],[-93.402998,34.007119],[-93.421499,34.042026]]]},"properties":{"id":"71922","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.270634,34.435221],[-94.46233,34.462924],[-94.46637,34.332686],[-94.281614,34.350182],[-94.270634,34.435221]]]},"properties":{"id":"71937","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.40137,34.72123],[-92.404538,34.676478],[-92.334977,34.705557],[-92.308573,34.716601],[-92.302522,34.725286],[-92.302021,34.743341],[-92.390971,34.742983],[-92.40137,34.72123]]]},"properties":{"id":"72204","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.245991,34.832442],[-92.263891,34.822972],[-92.265634,34.818442],[-92.277675,34.785497],[-92.236904,34.777549],[-92.211122,34.805256],[-92.245991,34.832442]]]},"properties":{"id":"72116","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.444571,34.640625],[-92.486535,34.569827],[-92.411678,34.571017],[-92.417057,34.649236],[-92.444571,34.640625]]],[[[-92.444562,34.640822],[-92.654002,34.753963],[-92.671877,34.735898],[-92.50185,34.639684],[-92.444562,34.640822]]]]},"properties":{"id":"72002","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.654002,34.753963],[-92.444562,34.640822],[-92.404538,34.676478],[-92.40137,34.72123],[-92.43805,34.740799],[-92.653379,34.764495],[-92.654002,34.753963]]]},"properties":{"id":"72210","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.146359,34.900357],[-92.14467,34.896412],[-92.137482,34.896237],[-92.136108,34.898476],[-92.146359,34.900357]]]},"properties":{"id":"72099","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.714864,35.039303],[-90.717502,35.009278],[-90.60565,34.992273],[-90.534606,35.0003],[-90.627889,35.146738],[-90.636971,35.146949],[-90.689415,35.105973],[-90.714864,35.039303]]]},"properties":{"id":"72394","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.836795,35.060557],[-90.815529,35.065943],[-90.83476,35.07392],[-90.836795,35.060557]]]},"properties":{"id":"72322","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.046391,34.911926],[-91.013285,34.910181],[-91.014134,34.973652],[-91.04587,34.96972],[-91.046391,34.911926]]]},"properties":{"id":"72340","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.589571,36.020531],[-92.945719,35.842098],[-92.949757,35.805296],[-92.950951,35.740389],[-92.803155,35.751413],[-92.793178,35.722243],[-92.414765,35.883872],[-92.362061,35.920806],[-92.40243,35.975681],[-92.414324,35.975865],[-92.589571,36.020531]]]},"properties":{"id":"72650","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.444645,35.019336],[-94.447774,34.933937],[-94.337055,34.948372],[-94.286878,35.073275],[-94.341022,35.074002],[-94.344236,35.073967],[-94.359289,35.074413],[-94.444645,35.019336]]]},"properties":{"id":"72938","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.344236,35.073967],[-94.341022,35.074002],[-94.357804,35.099655],[-94.351728,35.089248],[-94.344236,35.073967]]]},"properties":{"id":"72945","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.332275,35.133046],[-94.354882,35.223256],[-94.437835,35.222853],[-94.444645,35.019336],[-94.359289,35.074413],[-94.351728,35.089248],[-94.357804,35.099655],[-94.332275,35.133046]]]},"properties":{"id":"72937","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.093161,33.786965],[-94.148911,33.791462],[-94.147918,33.790927],[-94.093161,33.786965]]]},"properties":{"id":"71823","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.245994,35.913189],[-92.362061,35.920806],[-92.414765,35.883872],[-92.367467,35.853126],[-92.275157,35.844789],[-92.215581,35.909621],[-92.245994,35.913189]]]},"properties":{"id":"72680","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.46201,36.084898],[-94.426483,36.056874],[-94.398997,36.099554],[-94.46201,36.084898]]],[[[-94.153297,36.133343],[-94.33488,36.180757],[-94.395809,36.09949],[-94.350593,36.058851],[-94.317199,36.050787],[-94.22433,36.04695],[-94.200647,36.07826],[-94.147714,36.131361],[-94.153297,36.133343]]]]},"properties":{"id":"72704","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.720906,35.198392],[-91.710556,35.187622],[-91.706264,35.202053],[-91.720906,35.198392]]]},"properties":{"id":"72068","state_code":"05"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.223531,37.795772],[-122.236488,37.771696],[-122.223779,37.754428],[-122.197348,37.771623],[-122.194594,37.773522],[-122.20984,37.788334],[-122.223531,37.795772]]]},"properties":{"id":"94601","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.340281,37.800628],[-122.259468,37.752594],[-122.225895,37.749694],[-122.223779,37.754428],[-122.236488,37.771696],[-122.26838,37.788028],[-122.340281,37.800628]]]},"properties":{"id":"94501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.038506,37.563408],[-122.067333,37.540231],[-121.99332,37.499453],[-122.00236,37.529774],[-122.038506,37.563408]]]},"properties":{"id":"94560","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.013906,37.631621],[-122.073893,37.616684],[-122.089615,37.562107],[-122.02536,37.578493],[-121.951652,37.597529],[-122.013906,37.631621]]]},"properties":{"id":"94587","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.130142,37.690901],[-122.161705,37.667748],[-122.162676,37.667297],[-122.126254,37.664274],[-122.111838,37.688721],[-122.130142,37.690901]]]},"properties":{"id":"94580","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.55696,37.799106],[-121.632197,37.889288],[-121.663303,37.812452],[-121.697169,37.782692],[-121.670362,37.789427],[-121.55696,37.798837],[-121.55696,37.799106]]]},"properties":{"id":"94514","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.281596,37.880632],[-122.277787,37.847601],[-122.27127,37.846562],[-122.266128,37.852009],[-122.267167,37.860342],[-122.273265,37.873325],[-122.275673,37.884755],[-122.281596,37.880632]]]},"properties":{"id":"94703","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.827919,38.43844],[-120.846422,38.424995],[-120.825423,38.414855],[-120.8093,38.417984],[-120.827919,38.43844]]]},"properties":{"id":"95601","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.943563,38.527524],[-121.027507,38.508292],[-121.027272,38.458034],[-120.890504,38.406585],[-120.843368,38.446215],[-120.790877,38.493054],[-120.745867,38.54093],[-120.747251,38.548446],[-120.820936,38.559469],[-120.943563,38.527524]]]},"properties":{"id":"95669","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.440497,39.352008],[-121.569302,39.308357],[-121.62376,39.295621],[-121.618132,39.182237],[-121.597676,39.127792],[-121.513979,39.065158],[-121.434496,39.071664],[-121.321935,39.153064],[-121.369167,39.218701],[-121.372014,39.367675],[-121.440497,39.352008]]]},"properties":{"id":"95901","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.875168,39.503862],[-121.870261,39.463984],[-121.79909,39.464563],[-121.875168,39.503862]]]},"properties":{"id":"95974","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.36612,39.382053],[-121.440497,39.352008],[-121.372014,39.367675],[-121.36612,39.382053]]],[[[-121.293863,39.47474],[-121.363493,39.403297],[-121.30357,39.449816],[-121.293863,39.47474]]]]},"properties":{"id":"95914","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.633279,38.25934],[-120.463617,38.209314],[-120.396751,38.271273],[-120.400778,38.304598],[-120.493532,38.289305],[-120.633279,38.25934]]]},"properties":{"id":"95246","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.649951,39.384839],[-122.479527,39.158038],[-122.396594,39.219089],[-122.256982,39.384842],[-122.417767,39.383975],[-122.649951,39.384839]]]},"properties":{"id":"95979","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.081405,37.897273],[-122.087997,37.92526],[-122.101692,37.954978],[-122.162717,37.925778],[-122.164546,37.92129],[-122.142116,37.870876],[-122.063055,37.837134],[-122.061569,37.842983],[-122.081405,37.897273]]]},"properties":{"id":"94549","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.081405,37.897273],[-122.061569,37.842983],[-122.049104,37.879319],[-122.070614,37.896476],[-122.081405,37.897273]]]},"properties":{"id":"94595","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.86668,37.764212],[-121.895966,37.83823],[-121.921069,37.843025],[-121.967251,37.835309],[-121.948725,37.795786],[-121.871569,37.749456],[-121.86668,37.764212]]]},"properties":{"id":"94506","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.331237,38.006425],[-122.347422,37.949778],[-122.332723,37.951366],[-122.315381,37.956928],[-122.315559,37.985641],[-122.326382,38.009847],[-122.331237,38.006425]]]},"properties":{"id":"94806","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.347422,37.949778],[-122.331237,38.006425],[-122.403227,37.967771],[-122.359328,37.891137],[-122.347422,37.949778]]]},"properties":{"id":"94801","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.315559,37.985641],[-122.315381,37.956928],[-122.303189,37.939123],[-122.264178,37.921471],[-122.260523,37.920817],[-122.243152,37.971105],[-122.315559,37.985641]]]},"properties":{"id":"94803","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.048508,38.031525],[-122.177621,38.015077],[-122.190379,38.015621],[-122.216197,38.007198],[-122.252417,38.012961],[-122.221421,37.984524],[-122.162717,37.925778],[-122.101692,37.954978],[-122.065643,37.980839],[-122.048508,38.031525]]]},"properties":{"id":"94553","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.303189,37.939123],[-122.323509,37.930978],[-122.30618,37.898225],[-122.288053,37.899312],[-122.28128,37.917248],[-122.264178,37.921471],[-122.303189,37.939123]]]},"properties":{"id":"94530","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.276059,38.04349],[-122.252417,38.012961],[-122.216197,38.007198],[-122.234091,38.044255],[-122.269942,38.058313],[-122.276059,38.04349]]]},"properties":{"id":"94572","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.22995,41.936558],[-124.031359,41.910859],[-124.01592,41.990072],[-124.21776,41.998445],[-124.22995,41.936558]]]},"properties":{"id":"95567","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.443053,36.40537],[-120.211584,36.429117],[-120.23438,36.516079],[-120.252576,36.508929],[-120.433925,36.574397],[-120.485307,36.531169],[-120.443053,36.40537]]]},"properties":{"id":"93608","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.727336,36.794136],[-119.690602,36.801232],[-119.729551,36.837591],[-119.733319,36.808538],[-119.727336,36.794136]]]},"properties":{"id":"93612","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.206055,36.669496],[-120.093683,36.563591],[-120.052428,36.59645],[-120.00607,36.742354],[-120.014572,36.800452],[-120.021344,36.802077],[-120.021349,36.807711],[-120.0246,36.816523],[-120.273649,36.774175],[-120.341065,36.732891],[-120.332802,36.719628],[-120.206055,36.669496]]]},"properties":{"id":"93630","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.021349,36.807711],[-120.021344,36.802077],[-120.014572,36.800452],[-120.021349,36.807711]]]},"properties":{"id":"93606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.304635,36.718719],[-119.340663,36.603085],[-119.304965,36.588202],[-119.214393,36.659838],[-119.304635,36.718719]]]},"properties":{"id":"93646","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.066905,37.004753],[-119.236331,37.203634],[-119.254222,37.197434],[-119.248158,37.211062],[-119.327013,37.29244],[-119.332198,37.275005],[-119.324496,37.262761],[-119.333848,37.226213],[-119.360678,37.169613],[-119.066905,37.004753]]]},"properties":{"id":"93664","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.736317,36.758617],[-119.736258,36.779496],[-119.790525,36.779623],[-119.791374,36.765051],[-119.790543,36.757702],[-119.772486,36.757689],[-119.736317,36.758617]]]},"properties":{"id":"93703","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.085518,36.284301],[-120.21104,36.269169],[-120.134846,36.111727],[-120.098157,36.074646],[-119.977051,36.168163],[-119.958946,36.196083],[-120.085518,36.284301]]]},"properties":{"id":"93234","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.645929,36.667907],[-119.610566,36.634396],[-119.574844,36.619647],[-119.546984,36.663164],[-119.636979,36.685154],[-119.645929,36.667907]]]},"properties":{"id":"93616","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.111674,40.805427],[-124.193618,40.784971],[-124.222983,40.753037],[-124.223241,40.72355],[-124.21935,40.725682],[-124.216672,40.730912],[-124.214231,40.73115],[-124.21565,40.722634],[-124.219751,40.722563],[-124.143679,40.69165],[-123.990937,40.618465],[-124.053576,40.816505],[-124.111674,40.805427]]]},"properties":{"id":"95503","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.683597,40.239785],[-123.72379,40.332988],[-123.913454,40.321527],[-123.913603,40.30931],[-123.761224,40.232245],[-123.706986,40.197174],[-123.683597,40.239785]]]},"properties":{"id":"95554","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.891718,40.11047],[-123.784701,40.165438],[-123.810051,40.217616],[-123.906972,40.157439],[-123.891718,40.11047]]]},"properties":{"id":"95560","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.111674,40.805427],[-124.053576,40.816505],[-124.004419,40.792456],[-124.082495,40.831192],[-124.111674,40.805427]]]},"properties":{"id":"95524","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.448245,32.824888],[-115.685744,32.832925],[-115.690422,32.796582],[-115.715991,32.791063],[-115.774125,32.776482],[-115.690918,32.715775],[-115.542509,32.709185],[-115.447986,32.752021],[-115.448245,32.824888]]]},"properties":{"id":"92243","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.2541,36.997976],[-118.368795,36.89767],[-118.36933,36.750334],[-118.169339,36.741466],[-118.2541,36.997976]]]},"properties":{"id":"93526","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.437121,36.133412],[-118.195464,35.790689],[-118.008043,35.789161],[-118.012512,35.968909],[-118.040205,36.032394],[-118.59062,36.335262],[-118.437121,36.133412]]]},"properties":{"id":"93527","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.002918,35.372314],[-119.002936,35.368427],[-118.967502,35.37135],[-118.967602,35.412735],[-119.005283,35.401867],[-119.002918,35.372314]]]},"properties":{"id":"93305","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.081234,35.642913],[-119.241114,35.616423],[-119.246295,35.558341],[-119.145529,35.484981],[-119.09886,35.434289],[-119.091762,35.363872],[-119.044604,35.379006],[-119.005283,35.401867],[-118.967602,35.412735],[-118.637798,35.606525],[-118.687809,35.688843],[-118.849405,35.668559],[-118.927458,35.666446],[-119.081234,35.642913]]]},"properties":{"id":"93308","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.092152,35.318242],[-119.115737,35.35612],[-119.129463,35.35339],[-119.25242,35.30672],[-119.295969,35.100586],[-119.364661,35.084402],[-119.113803,35.065084],[-119.092152,35.318242]]]},"properties":{"id":"93311","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.867589,35.351979],[-118.852132,35.325512],[-118.799448,35.32581],[-118.867589,35.351979]]],[[[-118.713944,35.442737],[-118.833892,35.389804],[-118.829473,35.380308],[-118.713944,35.442737]]]]},"properties":{"id":"93220","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.627413,35.26362],[-118.645734,35.263645],[-118.647276,35.26063],[-118.627413,35.26362]]],[[[-118.623333,35.267613],[-118.570294,35.267903],[-118.270334,35.371076],[-118.412606,35.425149],[-118.521315,35.588412],[-118.623333,35.267613]]]]},"properties":{"id":"93518","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.711172,35.449445],[-119.641988,35.263667],[-119.589646,35.267431],[-119.733807,35.49904],[-119.711172,35.449445]]]},"properties":{"id":"93251","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.75714,35.790164],[-118.829066,35.790439],[-118.862228,35.738029],[-118.849405,35.668559],[-118.687809,35.688843],[-118.589178,35.789743],[-118.75714,35.790164]]]},"properties":{"id":"93226","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.959054,36.313566],[-119.874302,36.189423],[-119.74439,36.189244],[-119.715293,36.307308],[-119.718406,36.313629],[-119.769368,36.38648],[-119.809694,36.386571],[-119.959229,36.386318],[-119.959054,36.313566]]]},"properties":{"id":"93245","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-119.977051,36.168163],[-119.985656,36.09426],[-119.958946,36.196083],[-119.977051,36.168163]]],[[[-119.874302,36.189423],[-119.744311,36.142041],[-119.74439,36.189244],[-119.874302,36.189423]]]]},"properties":{"id":"93266","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.715293,36.307308],[-119.70202,36.305729],[-119.702996,36.321864],[-119.718406,36.313629],[-119.715293,36.307308]]]},"properties":{"id":"93202","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.006036,39.235979],[-123.06524,39.173673],[-123.027813,39.151723],[-122.886533,39.118209],[-122.885713,39.118398],[-122.844961,39.139592],[-122.758873,39.218851],[-123.019697,39.317608],[-123.006036,39.235979]],[[-122.998919,39.20462],[-122.961809,39.196596],[-122.940539,39.177372],[-122.966029,39.164909],[-122.998919,39.20462]]]},"properties":{"id":"95485","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.596235,40.764164],[-120.642466,40.294739],[-120.488375,40.353922],[-120.521289,40.379282],[-120.46903,40.524009],[-120.482318,40.660728],[-120.479082,40.764288],[-120.596235,40.764164]]]},"properties":{"id":"96130","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.204582,40.042737],[-120.12667,39.945259],[-120.108321,39.828391],[-119.999084,39.904257],[-119.996968,40.158842],[-120.068483,40.159559],[-120.076897,40.145319],[-120.142382,40.12926],[-120.213565,40.144237],[-120.269007,40.144985],[-120.301733,40.114843],[-120.204582,40.042737]]]},"properties":{"id":"96109","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.166353,40.782107],[-120.479082,40.764288],[-120.482318,40.660728],[-120.291161,40.659053],[-120.206091,40.726991],[-120.166353,40.782107]]]},"properties":{"id":"96123","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.344121,34.216998],[-118.350369,34.206672],[-118.33826,34.189865],[-118.322926,34.186833],[-118.315043,34.18961],[-118.309359,34.221529],[-118.344121,34.216998]]]},"properties":{"id":"91504","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.361049,34.193974],[-118.42247,34.193937],[-118.414179,34.179404],[-118.39503,34.179405],[-118.358803,34.179077],[-118.361049,34.193974]]]},"properties":{"id":"91606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.127095,34.00721],[-118.121307,33.97299],[-118.083622,34.020136],[-118.072859,34.030958],[-118.100272,34.044762],[-118.130099,34.033578],[-118.14416,34.033463],[-118.127095,34.00721]]]},"properties":{"id":"90640","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.107463,34.071545],[-118.100272,34.044762],[-118.072859,34.030958],[-118.068922,34.062835],[-118.056761,34.085103],[-118.073262,34.090412],[-118.107463,34.071545]]]},"properties":{"id":"91770","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.14065,34.762686],[-118.129926,34.645864],[-118.054303,34.646],[-117.991743,34.660588],[-117.667112,34.643012],[-117.667206,34.763718],[-118.14065,34.762686]]]},"properties":{"id":"93535","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.602128,34.139446],[-118.648604,34.078506],[-118.577505,34.070578],[-118.565316,34.130383],[-118.602128,34.139446]]]},"properties":{"id":"90290","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.914669,34.047315],[-117.89104,34.003416],[-117.87279,34.002645],[-117.888949,34.043079],[-117.914669,34.047315]]]},"properties":{"id":"91792","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.888949,34.043079],[-117.87279,34.002645],[-117.857926,33.959138],[-117.821365,34.031071],[-117.826084,34.062406],[-117.862794,34.051975],[-117.888949,34.043079]]]},"properties":{"id":"91789","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.916563,34.084655],[-117.916851,34.050287],[-117.914669,34.047315],[-117.888949,34.043079],[-117.862794,34.051975],[-117.872395,34.074913],[-117.903308,34.079027],[-117.916563,34.084655]]]},"properties":{"id":"91791","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.903308,34.079027],[-117.872395,34.074913],[-117.872464,34.0942],[-117.903308,34.079027]]]},"properties":{"id":"91723","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.837641,34.106611],[-117.826964,34.064219],[-117.783878,34.081026],[-117.793861,34.165149],[-117.820296,34.126829],[-117.837641,34.106611]]]},"properties":{"id":"91773","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.881342,34.130944],[-117.822736,34.179606],[-117.749696,34.222855],[-117.650454,34.338963],[-117.650459,34.339025],[-117.820295,34.345263],[-117.979035,34.338068],[-118.051325,34.24536],[-117.924796,34.16531],[-117.933649,34.129383],[-117.925363,34.106965],[-117.881448,34.114055],[-117.881342,34.130944]]]},"properties":{"id":"91702","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.822736,34.179606],[-117.881342,34.130944],[-117.820296,34.126829],[-117.793861,34.165149],[-117.822736,34.179606]]]},"properties":{"id":"91741","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.821365,34.031071],[-117.857926,33.959138],[-117.868374,33.945936],[-117.841825,33.946839],[-117.783287,33.946411],[-117.767745,34.019605],[-117.801335,34.042687],[-117.821365,34.031071]]]},"properties":{"id":"91765","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.347454,33.931724],[-118.317753,33.930901],[-118.31772,33.945463],[-118.343928,33.952713],[-118.343915,33.945968],[-118.347454,33.931724]]]},"properties":{"id":"90303","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.197518,34.064659],[-118.226747,34.062246],[-118.229988,34.047908],[-118.227695,34.038436],[-118.206036,34.034909],[-118.192638,34.063153],[-118.197518,34.064659]]]},"properties":{"id":"90033","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.155292,34.098576],[-118.177996,34.101921],[-118.19064,34.096707],[-118.197518,34.064659],[-118.192638,34.063153],[-118.169256,34.061489],[-118.165293,34.062298],[-118.160544,34.08889],[-118.155292,34.098576]]]},"properties":{"id":"90032","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.313476,33.887061],[-118.300379,33.91741],[-118.314247,33.91645],[-118.333567,33.894661],[-118.334864,33.887423],[-118.326488,33.882821],[-118.313476,33.887061]]]},"properties":{"id":"90249","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.122351,33.779311],[-118.121256,33.775319],[-118.115271,33.775316],[-118.122351,33.779311]]]},"properties":{"id":"90822","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.656665,34.419931],[-118.569579,34.387329],[-118.539286,34.395138],[-118.539045,34.427982],[-118.57907,34.460992],[-118.656665,34.419931]]]},"properties":{"id":"91355","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.537838,34.460191],[-118.539045,34.427982],[-118.539286,34.395138],[-118.485404,34.404437],[-118.435969,34.472246],[-118.537838,34.460191]]]},"properties":{"id":"91350","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.289695,34.394764],[-118.420263,34.454322],[-118.464898,34.405523],[-118.397893,34.353672],[-118.304399,34.36203],[-118.25269,34.387872],[-118.289695,34.394764]]]},"properties":{"id":"91387","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.146251,34.181539],[-118.099342,34.174642],[-118.10042,34.190442],[-118.165852,34.198895],[-118.146251,34.181539]]]},"properties":{"id":"91001","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.413776,34.156634],[-118.414903,34.130982],[-118.398129,34.120193],[-118.366056,34.128968],[-118.362006,34.136994],[-118.361267,34.142686],[-118.388183,34.150367],[-118.413776,34.156634]]]},"properties":{"id":"91604","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.451878,34.249935],[-118.468737,34.221411],[-118.466122,34.215094],[-118.426702,34.207345],[-118.422619,34.221661],[-118.420324,34.22564],[-118.450538,34.250619],[-118.451878,34.249935]]]},"properties":{"id":"91402","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.145945,34.122738],[-118.168053,34.123923],[-118.177996,34.101921],[-118.155292,34.098576],[-118.135296,34.10645],[-118.142213,34.11959],[-118.145945,34.122738]]]},"properties":{"id":"91030","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.135296,34.10645],[-118.121095,34.105275],[-118.090987,34.12684],[-118.113085,34.136861],[-118.142213,34.11959],[-118.135296,34.10645]]]},"properties":{"id":"91108","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300193,33.989109],[-118.300165,34.018177],[-118.31709,34.017254],[-118.317619,34.00369],[-118.317131,33.989102],[-118.300193,33.989109]]]},"properties":{"id":"90062","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.428775,33.93097],[-118.425965,33.96588],[-118.432009,33.974636],[-118.432259,33.975016],[-118.464085,33.958798],[-118.437121,33.916393],[-118.428775,33.93097]]]},"properties":{"id":"90293","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.445338,33.994206],[-118.471863,33.976519],[-118.464085,33.958798],[-118.432259,33.975016],[-118.445338,33.994206]]]},"properties":{"id":"90292","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.283204,33.989139],[-118.282594,33.939181],[-118.265158,33.938348],[-118.265158,33.939292],[-118.265118,33.96013],[-118.265185,33.989227],[-118.273928,33.989191],[-118.283204,33.989139]]]},"properties":{"id":"90003","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.357888,33.997112],[-118.369448,33.998072],[-118.386404,33.97709],[-118.370231,33.979929],[-118.358544,33.981667],[-118.357888,33.997112]]]},"properties":{"id":"90056","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.309135,33.776486],[-118.302001,33.760511],[-118.297492,33.78208],[-118.309486,33.779053],[-118.309135,33.776486]]],[[[-118.328345,33.709506],[-118.301,33.757721],[-118.335337,33.71421],[-118.328345,33.709506]]]]},"properties":{"id":"90732","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.081812,33.83126],[-118.064955,33.824018],[-118.061691,33.833317],[-118.081812,33.83126]]]},"properties":{"id":"90716","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.146647,33.869003],[-118.151294,33.879807],[-118.187183,33.885404],[-118.206591,33.846567],[-118.204215,33.839699],[-118.167643,33.850934],[-118.146647,33.869003]]]},"properties":{"id":"90805","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.51226,34.018217],[-118.483766,34.04143],[-118.496873,34.04807],[-118.521865,34.024999],[-118.51226,34.018217]]]},"properties":{"id":"90402","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.291638,34.061793],[-118.289914,34.063691],[-118.291644,34.063683],[-118.291638,34.061793]]],[[[-118.291638,34.061793],[-118.292914,34.063604],[-118.295365,34.063595],[-118.291638,34.061793]]],[[[-118.337054,34.060564],[-118.321412,34.063581],[-118.337149,34.064928],[-118.337054,34.060564]]],[[[-118.296637,34.063589],[-118.319959,34.063547],[-118.298716,34.059733],[-118.296637,34.063589]]]]},"properties":{"id":"90010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.454211,34.05848],[-118.459995,34.051672],[-118.454106,34.058346],[-118.454211,34.05848]]]},"properties":{"id":"90073","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.322382,34.249631],[-118.316871,34.286083],[-118.375225,34.256954],[-118.322382,34.249631]]]},"properties":{"id":"91040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.483766,34.04143],[-118.51226,34.018217],[-118.505885,34.013035],[-118.490271,34.025633],[-118.47096,34.041276],[-118.474465,34.044279],[-118.483766,34.04143]]]},"properties":{"id":"90403","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.206412,34.376765],[-118.178914,34.296044],[-118.051325,34.24536],[-117.979035,34.338068],[-118.000107,34.369574],[-118.022683,34.470113],[-118.054303,34.646],[-118.129926,34.645864],[-118.129963,34.645863],[-118.111998,34.524229],[-118.206412,34.376765]]]},"properties":{"id":"93550","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.579061,34.401732],[-117.655213,34.396953],[-117.650459,34.339025],[-117.602381,34.339182],[-117.579061,34.401732]]]},"properties":{"id":"92397","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.020174,34.097117],[-118.030543,34.063318],[-118.021641,34.041689],[-118.010372,34.050149],[-118.007111,34.059795],[-118.007342,34.105566],[-118.015284,34.102989],[-118.020174,34.097117]]]},"properties":{"id":"91732","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.056761,34.085103],[-118.068922,34.062835],[-118.030543,34.063318],[-118.020174,34.097117],[-118.02591,34.095858],[-118.056761,34.085103]]]},"properties":{"id":"91731","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.603717,38.076063],[-122.606192,38.058021],[-122.48749,38.04735],[-122.482661,38.099174],[-122.546108,38.08008],[-122.603717,38.076063]]]},"properties":{"id":"94949","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.509538,37.951875],[-122.513577,37.943869],[-122.502151,37.943858],[-122.501739,37.947825],[-122.509538,37.951875]]],[[[-122.519248,37.939362],[-122.553027,37.926011],[-122.539618,37.922123],[-122.519248,37.939362]]]]},"properties":{"id":"94939","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.17539,37.497318],[-120.278451,37.419639],[-120.266307,37.40107],[-120.176277,37.260561],[-120.001719,37.370121],[-120.17539,37.497318]]]},"properties":{"id":"95306","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.341029,39.977291],[-123.391409,39.977289],[-123.401718,39.800967],[-123.390064,39.791832],[-123.122633,39.683298],[-123.232814,39.977497],[-123.341029,39.977291]]]},"properties":{"id":"95428","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.122633,39.683298],[-123.390064,39.791832],[-123.349073,39.678803],[-123.26915,39.697917],[-123.122633,39.683298]]]},"properties":{"id":"95429","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.245985,38.932853],[-123.26871,38.808369],[-123.104207,38.740195],[-122.749839,38.766059],[-122.71395,38.751461],[-122.754314,38.805797],[-122.817363,38.846079],[-122.948892,38.901175],[-123.245985,38.932853]]]},"properties":{"id":"95425","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.056281,39.023209],[-123.286978,39.002337],[-123.315109,38.990342],[-123.245985,38.932853],[-122.948892,38.901175],[-122.949674,38.91586],[-122.986642,38.996261],[-123.056281,39.023209]]]},"properties":{"id":"95449","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.803675,39.269622],[-123.674514,39.254792],[-123.813344,39.300905],[-123.803675,39.269622]]]},"properties":{"id":"95456","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.712169,39.091487],[-123.611491,39.025254],[-123.63667,39.156788],[-123.713413,39.102335],[-123.712169,39.091487]]]},"properties":{"id":"95432","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.549311,37.382354],[-120.668249,37.374816],[-120.712363,37.248933],[-120.70117,37.22991],[-120.542866,37.321497],[-120.549311,37.382354]]]},"properties":{"id":"95301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.651605,41.90229],[-121.506599,41.73169],[-121.215219,41.758612],[-121.286474,41.997501],[-121.59585,41.998953],[-121.651605,41.90229]]]},"properties":{"id":"96134","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.198605,41.184407],[-121.034768,41.184368],[-120.973597,41.300019],[-121.025157,41.336258],[-121.169734,41.36305],[-121.198605,41.184407]]]},"properties":{"id":"96054","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.362028,38.436744],[-119.54971,38.338831],[-119.612994,38.25947],[-119.598421,38.181697],[-119.322997,38.051571],[-119.21554,38.167767],[-119.362028,38.436744]]]},"properties":{"id":"93517","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.923791,37.530259],[-117.934852,37.464632],[-117.832726,37.464929],[-117.923791,37.530259]]]},"properties":{"id":"89010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.678988,36.682652],[-121.652524,36.686866],[-121.609699,36.754754],[-121.582248,36.824437],[-121.5971,36.836849],[-121.62856,36.828697],[-121.717696,36.816595],[-121.760597,36.755979],[-121.678988,36.682652]]]},"properties":{"id":"93907","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.926784,36.600616],[-121.94369,36.618983],[-121.933901,36.5569],[-121.928953,36.558926],[-121.912971,36.574834],[-121.926784,36.600616]]]},"properties":{"id":"93953","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.521684,38.692016],[-122.487954,38.606672],[-122.428996,38.61244],[-122.31465,38.611904],[-122.344794,38.839423],[-122.395056,38.864245],[-122.415789,38.768002],[-122.521684,38.692016]]]},"properties":{"id":"94567","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.380697,38.381358],[-122.354136,38.393348],[-122.357362,38.414061],[-122.371418,38.407414],[-122.367504,38.398215],[-122.385536,38.390423],[-122.380697,38.381358]]]},"properties":{"id":"94599","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.480735,38.548424],[-122.472355,38.544982],[-122.471946,38.5547],[-122.482607,38.553113],[-122.480735,38.548424]]]},"properties":{"id":"94576","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.392054,39.325142],[-120.480529,39.316119],[-120.44373,39.316168],[-120.435359,39.316193],[-120.392054,39.325142]]],[[[-120.349257,39.31634],[-120.311045,39.316399],[-120.3108,39.303526],[-120.356245,39.316296],[-120.383129,39.287334],[-120.283898,39.225965],[-120.1988,39.221911],[-120.14315,39.229937],[-120.005903,39.380839],[-120.066755,39.374713],[-120.305992,39.327445],[-120.349257,39.31634]]]]},"properties":{"id":"96161","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.369167,39.218701],[-121.321935,39.153064],[-121.321994,39.127558],[-121.322401,39.107083],[-121.279715,39.098596],[-121.229385,39.160784],[-121.270729,39.23777],[-121.369167,39.218701]]]},"properties":{"id":"95977","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.906241,33.790641],[-117.941417,33.803154],[-117.958865,33.802942],[-117.958414,33.774131],[-117.95505,33.774044],[-117.897433,33.774467],[-117.906241,33.790641]]]},"properties":{"id":"92840","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.984457,33.802885],[-118.011048,33.799187],[-118.011085,33.791998],[-118.007408,33.77433],[-117.992781,33.77379],[-117.958414,33.774131],[-117.958865,33.802942],[-117.984457,33.802885]]]},"properties":{"id":"92841","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.0419,33.74439],[-118.023718,33.700951],[-117.98904,33.70121],[-117.98035,33.70121],[-117.971945,33.719835],[-118.041926,33.75026],[-118.0419,33.74439]]]},"properties":{"id":"92647","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.572979,33.453305],[-117.66625,33.46716],[-117.65608,33.45903],[-117.580659,33.44595],[-117.57848,33.453927],[-117.572979,33.453305]]]},"properties":{"id":"92673","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.843018,33.846896],[-117.870228,33.816584],[-117.833883,33.839025],[-117.843018,33.846896]]]},"properties":{"id":"92865","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.857359,33.777057],[-117.830866,33.773261],[-117.830873,33.787818],[-117.856348,33.795124],[-117.857359,33.777057]]]},"properties":{"id":"92866","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.683317,33.46431],[-117.6873,33.455081],[-117.634508,33.427856],[-117.65608,33.45903],[-117.66625,33.46716],[-117.683317,33.46431]]]},"properties":{"id":"92624","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.083528,33.728969],[-118.100815,33.726902],[-118.087323,33.722763],[-118.07844,33.72415],[-118.083528,33.728969]]]},"properties":{"id":"90743","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.865408,39.188408],[-120.848223,39.184171],[-120.839223,39.215459],[-120.857827,39.197443],[-120.865408,39.188408]]]},"properties":{"id":"95714","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.908646,39.168485],[-120.836284,39.15175],[-120.848223,39.184171],[-120.865408,39.188408],[-120.908646,39.168485]]]},"properties":{"id":"95717","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.122137,39.208006],[-120.085077,39.218065],[-120.063827,39.23585],[-120.064082,39.236909],[-120.075531,39.239925],[-120.122137,39.208006]]]},"properties":{"id":"96140","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.219803,40.027266],[-121.151267,39.969516],[-121.105482,40.01912],[-121.219803,40.027266]]]},"properties":{"id":"95915","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.219803,40.027266],[-121.105482,40.01912],[-121.02696,40.021758],[-120.999631,40.038874],[-121.219803,40.027266]]]},"properties":{"id":"95984","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.17953,40.223741],[-121.293649,40.328921],[-121.284798,40.445707],[-121.377967,40.389581],[-121.17953,40.223741]]]},"properties":{"id":"96020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.624543,40.200363],[-120.602978,40.285581],[-120.642466,40.294739],[-120.634285,40.210515],[-120.624543,40.200363]]],[[[-120.936698,40.206473],[-120.943511,40.103721],[-120.89945,40.107089],[-120.735297,40.117986],[-120.928327,40.200052],[-120.936698,40.206473]]]]},"properties":{"id":"95947","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.364749,39.708495],[-120.420356,39.602281],[-120.248291,39.550341],[-120.083832,39.511202],[-120.050836,39.548302],[-120.033387,39.576763],[-120.201171,39.707901],[-120.239737,39.802029],[-120.364749,39.708495]]]},"properties":{"id":"96118","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.492984,33.815881],[-116.47703,33.759296],[-116.440755,33.836344],[-116.44095,33.859368],[-116.493477,33.867515],[-116.492984,33.815881]]]},"properties":{"id":"92234","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.596142,33.94106],[-116.523964,33.885735],[-116.505761,33.874782],[-116.444094,33.971899],[-116.58422,33.980544],[-116.596142,33.94106]]]},"properties":{"id":"92240","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.884762,33.761671],[-116.958689,33.758512],[-116.962889,33.632379],[-116.963246,33.514071],[-116.771413,33.591226],[-116.741791,33.598921],[-116.809121,33.730042],[-116.884762,33.761671]]]},"properties":{"id":"92544","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.009245,33.656308],[-116.962889,33.632379],[-116.958689,33.758512],[-116.973389,33.772952],[-116.989277,33.772859],[-117.00592,33.685577],[-117.009245,33.656308]]]},"properties":{"id":"92543","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.492984,33.815881],[-116.571445,33.829602],[-116.512448,33.652124],[-116.443046,33.642025],[-116.460047,33.713622],[-116.47703,33.759296],[-116.492984,33.815881]]]},"properties":{"id":"92264","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.719918,33.887988],[-116.632448,33.896315],[-116.608286,33.90125],[-116.596142,33.94106],[-116.58422,33.980544],[-116.591476,34.032618],[-116.716364,34.033793],[-116.737249,33.932892],[-116.719918,33.887988]]]},"properties":{"id":"92282","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.624432,33.705157],[-115.30927,33.736647],[-115.415573,33.851592],[-115.564182,33.844694],[-115.624432,33.705157]]]},"properties":{"id":"92239","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.266715,33.73801],[-116.233892,33.671036],[-116.194694,33.719966],[-116.266715,33.73801]]]},"properties":{"id":"92201","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.173059,33.986014],[-117.050635,33.933768],[-117.081185,33.970632],[-117.064693,34.003646],[-117.124773,34.027919],[-117.243693,34.063053],[-117.243636,34.038705],[-117.173059,33.986014]]]},"properties":{"id":"92373","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.356335,33.728883],[-116.372951,33.670234],[-116.321466,33.670679],[-116.303886,33.72901],[-116.356335,33.728883]]]},"properties":{"id":"92210","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.336161,34.019378],[-117.321519,34.042213],[-117.305605,34.019292],[-117.311485,34.008519],[-117.210146,33.961215],[-117.173059,33.986014],[-117.243636,34.038705],[-117.27804,34.051901],[-117.306544,34.07989],[-117.348526,34.085045],[-117.365907,34.068885],[-117.367372,34.038234],[-117.370274,34.019444],[-117.339737,34.019426],[-117.336161,34.019378]]]},"properties":{"id":"92324","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.064693,34.003646],[-116.990247,33.990775],[-116.930157,34.004914],[-116.906145,34.034101],[-116.929886,34.06796],[-116.981875,34.082955],[-117.034747,34.091975],[-117.035966,34.091854],[-117.104315,34.040333],[-117.124773,34.027919],[-117.064693,34.003646]]]},"properties":{"id":"92399","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.311151,38.722923],[-121.397388,38.728906],[-121.401483,38.70639],[-121.401318,38.704508],[-121.363645,38.703274],[-121.334819,38.70341],[-121.311151,38.722923]]]},"properties":{"id":"95843","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.508266,38.580505],[-121.511566,38.575005],[-121.508522,38.574534],[-121.506275,38.586288],[-121.508266,38.580505]]]},"properties":{"id":"95814","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.378663,38.609673],[-121.409265,38.560434],[-121.359211,38.57175],[-121.346287,38.577748],[-121.355784,38.610128],[-121.378663,38.609673]]]},"properties":{"id":"95864","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.292451,38.535831],[-121.242743,38.525563],[-121.266454,38.568074],[-121.298375,38.571271],[-121.292451,38.535831]]]},"properties":{"id":"95655","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.407582,38.525073],[-121.47972,38.524835],[-121.475265,38.510328],[-121.435862,38.510507],[-121.4022,38.523931],[-121.407582,38.525073]]]},"properties":{"id":"95824","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.294247,36.720418],[-120.931131,36.74999],[-121.148544,36.853789],[-121.294247,36.720418]]]},"properties":{"id":"95075","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.400799,34.591907],[-117.399406,34.517667],[-117.322754,34.507828],[-117.287868,34.557113],[-117.314522,34.568598],[-117.337948,34.586373],[-117.400799,34.591907]]]},"properties":{"id":"92394","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.189069,34.621525],[-116.089666,34.99796],[-116.38961,34.77775],[-116.189069,34.621525]]]},"properties":{"id":"92338","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.335518,34.121375],[-117.348526,34.085045],[-117.306544,34.07989],[-117.294153,34.092149],[-117.300644,34.10499],[-117.28516,34.104577],[-117.234945,34.106763],[-117.236471,34.123219],[-117.285178,34.121343],[-117.302548,34.121332],[-117.335518,34.121375]]]},"properties":{"id":"92410","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.524096,34.06741],[-117.524313,34.033469],[-117.506891,34.033485],[-117.414178,34.034306],[-117.424893,34.067494],[-117.524091,34.067692],[-117.524096,34.06741]]]},"properties":{"id":"92337","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.305605,34.019292],[-117.321519,34.042213],[-117.336161,34.019378],[-117.305605,34.019292]]]},"properties":{"id":"92313","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.312248,34.254294],[-117.324608,34.237219],[-117.321878,34.223407],[-117.312704,34.223276],[-117.251292,34.228317],[-117.243287,34.2514],[-117.312248,34.254294]]]},"properties":{"id":"92325","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.253304,32.979939],[-117.208479,32.978154],[-117.211475,32.98534],[-117.217101,32.986519],[-117.238733,32.99594],[-117.253304,32.979939]]],[[[-117.253304,32.979939],[-117.274807,32.980492],[-117.264967,32.939539],[-117.261181,32.938828],[-117.241325,32.932349],[-117.253304,32.979939]]]]},"properties":{"id":"92014","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.988089,32.779118],[-116.945055,32.757161],[-116.944587,32.794917],[-116.961717,32.82941],[-117.005597,32.807276],[-117.00588,32.794306],[-116.988089,32.779118]]]},"properties":{"id":"92020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.177881,32.847326],[-117.189612,32.798033],[-117.18321,32.767112],[-117.162955,32.781225],[-117.133995,32.840851],[-117.177881,32.847326]]]},"properties":{"id":"92111","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.18321,32.767112],[-117.19633,32.759503],[-117.161579,32.758762],[-117.101038,32.779036],[-117.101482,32.79035],[-117.114217,32.788233],[-117.162955,32.781225],[-117.18321,32.767112]]]},"properties":{"id":"92108","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.264967,32.939539],[-117.262041,32.800212],[-117.234931,32.83802],[-117.214044,32.875076],[-117.261181,32.938828],[-117.264967,32.939539]]]},"properties":{"id":"92037","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.142597,32.722716],[-117.117798,32.717129],[-117.114163,32.757154],[-117.143776,32.75688],[-117.147529,32.741169],[-117.142667,32.726709],[-117.142597,32.722716]]]},"properties":{"id":"92104","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.005248,32.592388],[-117.100651,32.590654],[-117.105697,32.587551],[-117.124689,32.534175],[-117.062631,32.539733],[-117.06321,32.568085],[-117.018625,32.562038],[-117.011457,32.543857],[-116.935953,32.599246],[-116.965626,32.59032],[-117.005248,32.592388]]]},"properties":{"id":"92154","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.172053,32.973859],[-117.131386,33.042784],[-117.187061,33.061086],[-117.241909,33.021221],[-117.241087,33.010753],[-117.238733,32.99594],[-117.217101,32.986519],[-117.213677,32.993488],[-117.205758,32.994439],[-117.211475,32.98534],[-117.208479,32.978154],[-117.172053,32.973859]]]},"properties":{"id":"92067","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.274807,32.980492],[-117.253304,32.979939],[-117.238733,32.99594],[-117.241087,33.010753],[-117.243409,33.011322],[-117.257118,33.011662],[-117.281483,33.007102],[-117.274807,32.980492]]]},"properties":{"id":"92075","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.19633,32.759503],[-117.182322,32.742536],[-117.147529,32.741169],[-117.143776,32.75688],[-117.161579,32.758762],[-117.19633,32.759503]]]},"properties":{"id":"92103","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.792305,32.784509],[-116.910146,32.732031],[-116.911083,32.714528],[-116.777654,32.656892],[-116.675148,32.668717],[-116.672424,32.709041],[-116.792305,32.784509]]]},"properties":{"id":"91935","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.964334,32.744947],[-116.9775,32.715068],[-116.911083,32.714528],[-116.910146,32.732031],[-116.944869,32.739567],[-116.964334,32.744947]]]},"properties":{"id":"91978","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.360819,32.705156],[-116.470006,32.718691],[-116.479466,32.733326],[-116.567757,32.743678],[-116.550753,32.657786],[-116.53535,32.584052],[-116.4137,32.594045],[-116.360819,32.705156]]]},"properties":{"id":"91906","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.41198,32.815089],[-116.492888,32.871757],[-116.479648,32.852309],[-116.417715,32.815041],[-116.41198,32.815089]]]},"properties":{"id":"91948","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.123407,33.132885],[-117.113684,33.128589],[-117.174519,33.227209],[-117.205093,33.161781],[-117.207111,33.157806],[-117.123407,33.132885]]]},"properties":{"id":"92069","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.326909,33.181191],[-117.266764,33.129532],[-117.231258,33.130865],[-117.256269,33.153309],[-117.322427,33.182125],[-117.326909,33.181191]]]},"properties":{"id":"92010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.331152,33.223785],[-117.26917,33.300931],[-117.265824,33.329206],[-117.349313,33.325171],[-117.424921,33.256933],[-117.404868,33.203758],[-117.331152,33.223785]]]},"properties":{"id":"92058","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.424845,37.748633],[-122.454002,37.758785],[-122.457536,37.763566],[-122.463361,37.753039],[-122.459174,37.747286],[-122.444298,37.730708],[-122.426722,37.736372],[-122.424845,37.748633]]]},"properties":{"id":"94131","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.407851,37.737755],[-122.393782,37.708231],[-122.393226,37.708282],[-122.377981,37.753629],[-122.380014,37.753358],[-122.403784,37.749433],[-122.407851,37.737755]]]},"properties":{"id":"94124","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.415459,37.732164],[-122.428294,37.70841],[-122.40529,37.708264],[-122.393782,37.708231],[-122.407851,37.737755],[-122.415459,37.732164]]]},"properties":{"id":"94134","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.414826,37.794988],[-122.411886,37.788808],[-122.404583,37.786731],[-122.403431,37.787642],[-122.404613,37.793565],[-122.404412,37.796302],[-122.414826,37.794988]]]},"properties":{"id":"94108","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.366507,37.807641],[-122.362981,37.822527],[-122.379381,37.826827],[-122.366507,37.807641]]]},"properties":{"id":"94130","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.448142,38.255008],[-121.519519,38.203162],[-121.501407,38.116219],[-121.417281,38.222212],[-121.447774,38.2551],[-121.448142,38.255008]]]},"properties":{"id":"95686","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.417281,38.222212],[-121.501407,38.116219],[-121.503089,38.072586],[-121.377444,38.072744],[-121.310597,38.05758],[-121.287182,38.057463],[-121.290139,38.151431],[-121.300397,38.151251],[-121.32207,38.189182],[-121.395592,38.221023],[-121.417281,38.222212]]]},"properties":{"id":"95242","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.318562,37.979854],[-121.306682,37.979842],[-121.309881,37.984381],[-121.318562,37.979854]]]},"properties":{"id":"95211","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.290139,38.151431],[-121.287182,38.057463],[-121.277326,38.057416],[-121.13245,38.074264],[-121.015593,38.160575],[-121.096767,38.186118],[-121.192852,38.148189],[-121.290139,38.151431]]]},"properties":{"id":"95240","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.11708,37.826994],[-121.068848,37.739528],[-120.96506,37.743771],[-120.926173,37.738408],[-120.943584,37.856762],[-121.04601,37.892949],[-121.145587,37.855715],[-121.11708,37.826994]]]},"properties":{"id":"95320","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.841996,35.340806],[-120.805338,35.240698],[-120.74429,35.20597],[-120.719403,35.176164],[-120.700319,35.179748],[-120.646819,35.350146],[-120.695002,35.382945],[-120.841996,35.340806]],[[-120.66304,35.302356],[-120.659157,35.302666],[-120.658584,35.29963],[-120.661454,35.298774],[-120.66304,35.302356]]]},"properties":{"id":"93405","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.606811,35.129491],[-120.640144,35.128383],[-120.636974,35.121719],[-120.604764,35.106563],[-120.606811,35.129491]]]},"properties":{"id":"93433","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.907311,35.544536],[-121.053254,35.499994],[-120.876523,35.412595],[-120.801785,35.464437],[-120.767909,35.483152],[-120.907311,35.544536]]]},"properties":{"id":"93430","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.261965,37.521139],[-122.303476,37.493798],[-122.24424,37.49407],[-122.245796,37.504955],[-122.248664,37.516433],[-122.261965,37.521139]]]},"properties":{"id":"94070","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.261965,37.521139],[-122.248664,37.516433],[-122.249364,37.549193],[-122.265697,37.534076],[-122.261965,37.521139]]]},"properties":{"id":"94065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.395944,37.345387],[-122.368144,37.496047],[-122.46514,37.554941],[-122.488993,37.533661],[-122.497067,37.495452],[-122.404891,37.311671],[-122.395944,37.345387]]]},"properties":{"id":"94019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.643568,34.48709],[-119.953593,34.585955],[-120.01081,34.546874],[-119.825906,34.467872],[-119.789614,34.451349],[-119.755933,34.42237],[-119.740162,34.413336],[-119.710752,34.435956],[-119.688786,34.449074],[-119.643568,34.48709]]]},"properties":{"id":"93105","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.688566,34.411805],[-119.740162,34.413336],[-119.755933,34.42237],[-119.755733,34.404741],[-119.684856,34.407092],[-119.688566,34.411805]]]},"properties":{"id":"93109","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.809383,37.285245],[-121.802498,37.255721],[-121.786095,37.245854],[-121.768706,37.235438],[-121.751149,37.226434],[-121.747404,37.229619],[-121.677359,37.252336],[-121.771625,37.299159],[-121.809383,37.285245]]]},"properties":{"id":"95138","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.185458,37.323737],[-122.18876,37.320916],[-122.192513,37.318795],[-122.151457,37.230023],[-122.056491,37.127954],[-122.060417,37.125026],[-121.996761,37.113511],[-121.953521,37.076235],[-121.946418,37.0685],[-121.848221,37.068195],[-121.84102,37.065063],[-121.809076,37.069301],[-121.817205,37.142667],[-121.913404,37.172141],[-121.935842,37.195357],[-122.0288,37.217522],[-122.138483,37.267513],[-122.143823,37.31359],[-122.185458,37.323737]]]},"properties":{"id":"95033","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.903732,37.226181],[-121.913404,37.172141],[-121.817205,37.142667],[-121.778225,37.208188],[-121.800888,37.225944],[-121.874794,37.243757],[-121.903732,37.226181]]]},"properties":{"id":"95120","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.810146,37.260264],[-121.843866,37.285738],[-121.877846,37.278496],[-121.869352,37.255838],[-121.810146,37.260264]]]},"properties":{"id":"95136","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.810146,37.260264],[-121.869352,37.255838],[-121.874794,37.243757],[-121.800888,37.225944],[-121.786095,37.245854],[-121.802498,37.255721],[-121.810146,37.260264]]]},"properties":{"id":"95123","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.189879,37.42883],[-122.16763,37.44363],[-122.171706,37.44678],[-122.189879,37.42883]]],[[[-122.140956,37.395681],[-122.151789,37.415062],[-122.172439,37.392201],[-122.184078,37.334842],[-122.140956,37.395681]]]]},"properties":{"id":"94304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.848221,37.068195],[-121.946418,37.0685],[-121.971059,36.983241],[-121.965518,36.982722],[-121.937038,36.983889],[-121.848221,37.068195]]]},"properties":{"id":"95073","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.24266,37.190035],[-122.292952,37.107352],[-122.189802,36.999782],[-122.207158,37.157156],[-122.24266,37.190035]]]},"properties":{"id":"95017","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.179325,41.046532],[-122.224919,40.901577],[-122.103427,40.919893],[-121.990868,40.959029],[-121.840666,40.958579],[-121.807389,41.183285],[-122.07282,41.183097],[-122.179325,41.046532]]]},"properties":{"id":"96011","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.411088,40.677734],[-122.354936,40.650697],[-122.344693,40.693156],[-122.393463,40.709755],[-122.411088,40.677734]]]},"properties":{"id":"96019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.179325,41.046532],[-122.451888,41.114698],[-122.515982,41.085577],[-122.605186,40.949276],[-122.61527,40.881068],[-122.409781,40.740994],[-122.224919,40.901577],[-122.179325,41.046532]]]},"properties":{"id":"96051","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.873573,40.707468],[-121.877187,40.756939],[-121.968608,40.75543],[-122.103329,40.675943],[-122.178332,40.579964],[-122.008382,40.640626],[-121.873573,40.707468]]]},"properties":{"id":"96069","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.709416,40.720016],[-122.544635,40.695965],[-122.419181,40.718588],[-122.409781,40.740994],[-122.61527,40.881068],[-122.709416,40.720016]]]},"properties":{"id":"96033","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.172224,41.265471],[-122.518725,41.209228],[-122.498376,41.182675],[-122.19603,41.18444],[-122.172224,41.265471]]]},"properties":{"id":"96025","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.047433,40.426978],[-122.04746,40.419909],[-121.993297,40.403821],[-121.938834,40.394885],[-121.962609,40.41029],[-121.985607,40.438933],[-122.047433,40.426978]]],[[[-121.879191,40.394775],[-121.935125,40.290173],[-121.640781,40.342959],[-121.667445,40.347041],[-121.666318,40.352227],[-121.810178,40.374134],[-121.879191,40.394775]]]]},"properties":{"id":"96075","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.833116,39.526421],[-120.731746,39.507131],[-120.717977,39.633643],[-120.718395,39.706674],[-120.768113,39.717919],[-120.906543,39.567301],[-120.904345,39.563778],[-120.833116,39.526421]]]},"properties":{"id":"95936","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.313868,41.802438],[-123.390792,41.784465],[-123.3546,41.741646],[-123.277369,41.741508],[-123.313868,41.802438]]]},"properties":{"id":"96049","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.896863,38.366164],[-121.896956,38.416453],[-122.023883,38.325081],[-121.933753,38.3013],[-121.914826,38.27921],[-121.842475,38.271398],[-121.842059,38.278465],[-121.896852,38.364768],[-121.908653,38.348411],[-121.923047,38.35131],[-121.917203,38.366119],[-121.896863,38.366164]]]},"properties":{"id":"95687","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.268872,38.126181],[-122.232079,38.06182],[-122.225885,38.061018],[-122.229825,38.12315],[-122.268872,38.126181]]]},"properties":{"id":"94590","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.749839,38.766059],[-123.104207,38.740195],[-123.075604,38.655619],[-122.749839,38.766059]]]},"properties":{"id":"95441","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.749839,38.766059],[-123.075604,38.655619],[-123.056505,38.576131],[-122.954027,38.540238],[-122.858644,38.502387],[-122.786482,38.540333],[-122.736093,38.554882],[-122.686568,38.583769],[-122.696683,38.72101],[-122.71395,38.751461],[-122.749839,38.766059]]]},"properties":{"id":"95448","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.102639,38.414093],[-122.98358,38.355432],[-122.914728,38.364487],[-123.000281,38.449437],[-123.048786,38.454327],[-123.049083,38.454198],[-123.050004,38.453927],[-123.129003,38.450789],[-123.102639,38.414093]]]},"properties":{"id":"95465","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.690334,38.452662],[-122.673036,38.42308],[-122.630276,38.420214],[-122.690334,38.452662]]]},"properties":{"id":"95405","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.715536,38.431305],[-122.794997,38.419752],[-122.74128,38.352148],[-122.685803,38.3722],[-122.715536,38.431305]]]},"properties":{"id":"95407","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.453522,38.382392],[-122.56236,38.339269],[-122.566694,38.329113],[-122.429199,38.124858],[-122.406786,38.155632],[-122.378987,38.250714],[-122.453522,38.382392]]]},"properties":{"id":"95476","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.577437,38.344143],[-122.66686,38.338581],[-122.666688,38.314308],[-122.708336,38.303632],[-122.678864,38.278785],[-122.577437,38.344143]]]},"properties":{"id":"94951","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.533746,38.768405],[-123.403019,38.64943],[-123.36458,38.651753],[-123.498956,38.778127],[-123.533746,38.768405]]]},"properties":{"id":"95497","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.009967,37.64607],[-120.974553,37.648365],[-120.976071,37.688884],[-121.053657,37.683127],[-121.054023,37.682917],[-121.031171,37.663862],[-121.009967,37.64607]]]},"properties":{"id":"95350","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.926173,37.738408],[-120.96506,37.743771],[-120.973832,37.735137],[-120.921914,37.719051],[-120.926173,37.738408]]]},"properties":{"id":"95367","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.08056,37.49382],[-121.138182,37.39765],[-120.98324,37.376436],[-120.958922,37.409504],[-120.985552,37.507479],[-121.012614,37.507665],[-121.08056,37.49382]]]},"properties":{"id":"95313","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.904271,37.638259],[-120.900872,37.62803],[-120.893078,37.6312],[-120.893265,37.638362],[-120.904271,37.638259]]]},"properties":{"id":"95319","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.753663,38.952919],[-121.880975,39.158027],[-121.909402,39.190059],[-121.928332,39.198729],[-121.939172,39.136145],[-121.833602,39.040483],[-121.753663,38.952919]]]},"properties":{"id":"95957","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.130731,40.10115],[-122.11927,40.028545],[-122.117411,40.017714],[-122.067182,39.955624],[-122.011697,40.037691],[-122.089878,40.135776],[-122.130731,40.10115]]]},"properties":{"id":"96055","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.64292,39.943721],[-122.562324,39.799774],[-122.478638,39.799523],[-122.478382,39.865931],[-122.490214,39.886897],[-122.534219,39.959802],[-122.64292,39.943721]]]},"properties":{"id":"96074","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.544356,40.451552],[-123.543886,40.238847],[-123.543933,40.218565],[-123.341029,39.977291],[-123.232814,39.977497],[-123.065426,40.286971],[-123.065432,40.291883],[-123.103712,40.310902],[-123.313832,40.368457],[-123.460291,40.454218],[-123.54452,40.5925],[-123.544266,40.498684],[-123.544356,40.451552]]]},"properties":{"id":"95552","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.469061,40.935553],[-123.566281,40.836579],[-123.543956,40.732938],[-123.368315,40.696601],[-123.358809,40.700562],[-123.423272,41.058157],[-123.469061,40.935553]]]},"properties":{"id":"95527","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.881549,40.702039],[-122.733769,40.635184],[-122.745312,40.694226],[-122.861052,40.837632],[-122.888151,40.804776],[-122.881549,40.702039]]]},"properties":{"id":"96052","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.358809,40.700562],[-123.111623,40.703602],[-123.08654,41.05795],[-123.408291,41.179944],[-123.423272,41.058157],[-123.358809,40.700562]]]},"properties":{"id":"96010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.238964,38.020366],[-120.267285,37.962759],[-120.268532,37.891243],[-120.153226,37.883891],[-120.182613,37.995676],[-120.238964,38.020366]]]},"properties":{"id":"95379","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.323265,38.078801],[-120.246828,38.019811],[-120.238964,38.020366],[-120.182613,37.995676],[-120.186277,38.042703],[-120.168424,38.093471],[-120.151646,38.114121],[-120.323706,38.173583],[-120.323265,38.078801]]]},"properties":{"id":"95383","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.096113,34.19656],[-119.036993,34.13047],[-119.00381,34.148502],[-118.929848,34.21431],[-118.865911,34.246112],[-118.950532,34.267403],[-119.00771,34.245764],[-119.096113,34.19656]]]},"properties":{"id":"93012","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.978397,34.329717],[-119.133719,34.272878],[-119.126589,34.267995],[-119.00771,34.245764],[-118.950532,34.267403],[-118.955226,34.335681],[-118.978397,34.329717]]]},"properties":{"id":"93066","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.236036,34.197729],[-119.119938,34.221917],[-119.126589,34.267995],[-119.133719,34.272878],[-119.138979,34.276671],[-119.189719,34.242315],[-119.216442,34.235113],[-119.266891,34.234556],[-119.250144,34.196848],[-119.236036,34.197729]]]},"properties":{"id":"93036","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.195243,34.287953],[-119.189719,34.242315],[-119.138979,34.276671],[-119.165914,34.300326],[-119.195243,34.287953]]]},"properties":{"id":"93004","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.296371,34.390095],[-119.290738,34.417475],[-119.336984,34.417572],[-119.296371,34.390095]]]},"properties":{"id":"93022","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.82832,38.529443],[-121.768525,38.530186],[-121.730298,38.58902],[-121.767176,38.602731],[-121.877062,38.561285],[-121.82832,38.529443]]]},"properties":{"id":"95616","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.583457,38.578599],[-121.58307,38.545304],[-121.584462,38.473894],[-121.558069,38.501876],[-121.525566,38.523106],[-121.512618,38.541666],[-121.511566,38.575005],[-121.508266,38.580505],[-121.54789,38.598774],[-121.562835,38.627106],[-121.576068,38.648604],[-121.595049,38.749738],[-121.620653,38.784004],[-121.630921,38.776128],[-121.664176,38.765249],[-121.661607,38.762627],[-121.643077,38.673213],[-121.583457,38.578599]]]},"properties":{"id":"95691","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.175702,38.815771],[-122.229458,38.878036],[-122.287998,38.839931],[-122.258334,38.785532],[-122.175702,38.815771]]]},"properties":{"id":"95637","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.94592,38.777235],[-121.774517,38.79288],[-121.909957,38.867114],[-122.043422,38.831715],[-122.043328,38.81558],[-121.94592,38.777235]]]},"properties":{"id":"95698","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.237749,39.381329],[-121.190719,39.330372],[-121.136847,39.368074],[-121.141449,39.392532],[-121.220879,39.448016],[-121.237749,39.381329]]]},"properties":{"id":"95935","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.227116,37.879764],[-122.246668,37.885255],[-122.246348,37.880976],[-122.245269,37.872799],[-122.267167,37.860342],[-122.266128,37.852009],[-122.257767,37.853081],[-122.219317,37.858475],[-122.213559,37.857912],[-122.227116,37.879764]]]},"properties":{"id":"94705","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.20984,37.788334],[-122.178199,37.804014],[-122.215174,37.812276],[-122.234679,37.799827],[-122.223531,37.795772],[-122.20984,37.788334]]]},"properties":{"id":"94602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.072432,38.677804],[-120.03337,38.696962],[-120.06762,38.706015],[-120.077187,38.701026],[-120.072432,38.677804]]]},"properties":{"id":"95646","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.620761,38.43226],[-120.440136,38.5288],[-120.565168,38.508146],[-120.698191,38.473383],[-120.644053,38.439649],[-120.620761,38.43226]]]},"properties":{"id":"95689","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.669755,39.751908],[-121.757851,39.676477],[-121.712468,39.639222],[-121.571138,39.813844],[-121.669755,39.751908]]]},"properties":{"id":"95969","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.4676,39.555765],[-121.297091,39.614138],[-121.219159,39.725653],[-121.259842,39.754177],[-121.402187,39.696175],[-121.4676,39.555765]]]},"properties":{"id":"95916","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.508001,39.879276],[-121.559157,39.841073],[-121.557076,39.841216],[-121.508001,39.879276]]]},"properties":{"id":"95978","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.908269,39.303878],[-121.63015,39.305668],[-121.645979,39.365828],[-121.892734,39.361871],[-121.908269,39.303878]]]},"properties":{"id":"95948","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.747344,37.916054],[-120.652673,37.831422],[-120.566991,37.899733],[-120.690846,38.047462],[-120.747344,37.916054]]]},"properties":{"id":"95228","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.532239,38.36558],[-120.493532,38.289305],[-120.400778,38.304598],[-120.421721,38.355124],[-120.532239,38.36558]]]},"properties":{"id":"95248","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.404088,38.194688],[-120.376203,38.142649],[-120.331733,38.190313],[-120.392904,38.200832],[-120.404088,38.194688]]]},"properties":{"id":"95233","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.987602,38.207456],[-120.90579,38.197558],[-120.891386,38.219605],[-120.889057,38.227188],[-120.995497,38.225405],[-120.987602,38.207456]]],[[[-120.860231,38.20675],[-120.926878,38.194812],[-120.971186,38.167979],[-120.926449,38.077421],[-120.736415,38.053692],[-120.794049,38.165288],[-120.735404,38.242654],[-120.750046,38.297681],[-120.850053,38.257254],[-120.860231,38.20675]]]]},"properties":{"id":"95252","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.547117,38.369094],[-120.631047,38.340741],[-120.750046,38.297681],[-120.735404,38.242654],[-120.633279,38.25934],[-120.493532,38.289305],[-120.532239,38.36558],[-120.547117,38.369094]]]},"properties":{"id":"95245","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.631047,38.340741],[-120.547117,38.369094],[-120.610552,38.371981],[-120.631047,38.340741]]]},"properties":{"id":"95232","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.060475,39.195587],[-121.940929,39.133544],[-121.939172,39.136145],[-121.928332,39.198729],[-121.908269,39.303878],[-121.892734,39.361871],[-121.890013,39.383864],[-122.007833,39.390094],[-122.083972,39.330622],[-122.116084,39.217913],[-122.060475,39.195587]]]},"properties":{"id":"95932","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.909957,38.867114],[-121.774517,38.79288],[-121.670096,38.758968],[-121.664176,38.765249],[-121.630921,38.776128],[-121.753663,38.952919],[-121.833602,39.040483],[-121.91441,38.925351],[-121.915313,38.925348],[-121.909957,38.867114]]]},"properties":{"id":"95645","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.070614,37.896476],[-122.049104,37.879319],[-122.007152,37.882483],[-122.05489,37.908127],[-122.070614,37.896476]]]},"properties":{"id":"94596","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.004809,37.770571],[-121.97247,37.728528],[-121.917372,37.728955],[-121.964458,37.781461],[-122.037503,37.809702],[-122.049916,37.816342],[-122.004809,37.770571]]]},"properties":{"id":"94583","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.347422,37.949778],[-122.359328,37.891137],[-122.311845,37.897514],[-122.309339,37.89786],[-122.30618,37.898225],[-122.323509,37.930978],[-122.332723,37.951366],[-122.347422,37.949778]],[[-122.320298,37.904443],[-122.319032,37.906025],[-122.314081,37.900047],[-122.316652,37.89964],[-122.320298,37.904443]]]},"properties":{"id":"94804","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.177621,38.015077],[-122.2017,38.053628],[-122.206125,38.046181],[-122.190379,38.015621],[-122.177621,38.015077]]]},"properties":{"id":"94569","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.867011,38.892254],[-120.902107,38.953155],[-120.9434,38.935671],[-120.937,38.847574],[-120.907987,38.855577],[-120.867011,38.892254]]]},"properties":{"id":"95635","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.076995,38.965765],[-120.139106,39.074332],[-120.152976,39.045807],[-120.150317,38.932128],[-120.076995,38.965765]]]},"properties":{"id":"96142","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.279689,36.602762],[-120.252576,36.508929],[-120.23438,36.516079],[-120.169925,36.531309],[-120.04343,36.504459],[-120.063803,36.531654],[-120.093683,36.563591],[-120.206055,36.669496],[-120.279689,36.602762]]]},"properties":{"id":"93660","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.729551,36.837591],[-119.690602,36.801232],[-119.655024,36.787611],[-119.729717,36.852152],[-119.729551,36.837591]]]},"properties":{"id":"93611","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.813455,36.750368],[-119.844277,36.746414],[-119.880228,36.750044],[-120.00607,36.742354],[-120.052428,36.59645],[-120.025315,36.553785],[-119.953621,36.546855],[-119.917057,36.582828],[-119.916913,36.604577],[-119.889866,36.604793],[-119.899011,36.582955],[-119.786065,36.553339],[-119.772434,36.713051],[-119.805633,36.744274],[-119.813455,36.750368]]]},"properties":{"id":"93706","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.313657,36.530541],[-119.304965,36.588202],[-119.340663,36.603085],[-119.484525,36.53571],[-119.39484,36.443978],[-119.324336,36.487054],[-119.313657,36.530541]],[[-119.340592,36.547309],[-119.331488,36.552446],[-119.330735,36.540147],[-119.340592,36.547309]]]},"properties":{"id":"93618","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.500614,36.995166],[-120.69163,36.924207],[-120.774306,36.857352],[-120.595501,36.604702],[-120.531695,36.720141],[-120.486462,36.734394],[-120.372628,36.788104],[-120.318853,36.865572],[-120.457208,36.965746],[-120.500614,36.995166]]]},"properties":{"id":"93622","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.814253,36.76482],[-119.840311,36.764674],[-119.844277,36.746414],[-119.813455,36.750368],[-119.790543,36.757702],[-119.791374,36.765051],[-119.814253,36.76482]]]},"properties":{"id":"93728","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.772486,36.757689],[-119.790543,36.757702],[-119.813455,36.750368],[-119.805633,36.744274],[-119.772432,36.743168],[-119.772486,36.757689]]]},"properties":{"id":"93701","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.69163,36.924207],[-120.500614,36.995166],[-120.49374,37.082865],[-120.62032,37.157023],[-120.664558,37.185283],[-120.766552,37.186143],[-120.69163,36.924207]],[[-120.656366,36.967232],[-120.637726,36.96741],[-120.653699,36.952823],[-120.656366,36.967232]]]},"properties":{"id":"93620","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.959229,36.386318],[-119.809694,36.386571],[-119.79339,36.47445],[-119.953621,36.546855],[-120.025315,36.553785],[-120.063803,36.531654],[-120.04343,36.504459],[-120.031298,36.433722],[-119.959229,36.386318]]]},"properties":{"id":"93656","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.79339,36.47445],[-119.809694,36.386571],[-119.769368,36.38648],[-119.609057,36.433987],[-119.609765,36.461348],[-119.755212,36.489321],[-119.761621,36.489338],[-119.79339,36.47445]]]},"properties":{"id":"93242","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.104205,39.623452],[-122.037285,39.481197],[-121.994801,39.457316],[-121.992528,39.533792],[-121.997493,39.667025],[-122.008387,39.708974],[-122.104205,39.623452]]]},"properties":{"id":"95943","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.66959,41.194976],[-123.7494,40.991891],[-123.653438,41.013057],[-123.587246,41.191776],[-123.66959,41.194976]]]},"properties":{"id":"95546","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.075941,40.525843],[-124.046488,40.572881],[-124.094173,40.572317],[-124.075941,40.525843]]]},"properties":{"id":"95547","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-124.024363,40.897707],[-123.98674,40.85713],[-123.929986,40.895488],[-123.948588,40.905112],[-124.024363,40.897707]]],[[[-123.77328,40.930051],[-123.900633,40.919938],[-123.74094,40.787987],[-123.698977,40.816086],[-123.77328,40.930051]]]]},"properties":{"id":"95525","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.116375,41.025133],[-124.0858,41.0087],[-124.08616,41.185972],[-124.065202,41.46474],[-124.116375,41.025133]]]},"properties":{"id":"95570","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.689383,33.245186],[-114.657806,33.412958],[-114.739185,33.433711],[-115.235077,33.428499],[-114.856134,33.219425],[-114.689383,33.245186]]]},"properties":{"id":"92266","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.368795,36.89767],[-118.2541,36.997976],[-118.304303,37.187637],[-118.530932,37.11118],[-118.368795,36.89767]]]},"properties":{"id":"93513","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.93748,36.749953],[-117.037562,36.323971],[-116.522599,36.484955],[-116.93748,36.749953]]]},"properties":{"id":"92328","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.867589,35.351979],[-118.829473,35.380308],[-118.833892,35.389804],[-118.967502,35.37135],[-119.002936,35.368427],[-119.030652,35.295818],[-118.95817,35.02656],[-118.952646,35.009186],[-118.945395,34.986008],[-118.852132,35.325512],[-118.867589,35.351979]],[[-118.932139,35.256951],[-118.905269,35.267026],[-118.896293,35.237889],[-118.923337,35.237832],[-118.932139,35.256951]]]},"properties":{"id":"93307","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.647488,35.3618],[-117.631312,35.344139],[-117.631894,35.376333],[-117.668041,35.390609],[-117.647488,35.3618]]]},"properties":{"id":"93528","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.658543,34.996839],[-117.561779,34.993367],[-117.531486,35.012991],[-117.632827,35.013331],[-117.658543,34.996839]]]},"properties":{"id":"93516","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.521993,35.199978],[-118.647276,35.26063],[-118.645734,35.263645],[-118.708018,35.089611],[-118.68522,35.063556],[-118.363181,35.0487],[-118.255211,35.15449],[-118.521993,35.199978]]]},"properties":{"id":"93561","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.641988,35.263667],[-119.642047,35.229127],[-119.554356,35.238117],[-119.589646,35.267431],[-119.641988,35.263667]]]},"properties":{"id":"93224","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.41325,35.789979],[-120.408349,35.530615],[-120.354864,35.49356],[-120.086674,35.526554],[-120.255648,35.852572],[-120.41325,35.789979]]]},"properties":{"id":"93461","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.368544,35.305434],[-119.368728,35.296762],[-119.358342,35.296628],[-119.349286,35.299839],[-119.368544,35.305434]]]},"properties":{"id":"93276","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.537694,35.934784],[-119.555725,35.78955],[-119.427821,35.790092],[-119.394259,35.876196],[-119.426729,35.920192],[-119.537694,35.934784]]]},"properties":{"id":"93201","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.543655,38.826236],[-122.575938,38.800058],[-122.538194,38.802003],[-122.543655,38.826236]]]},"properties":{"id":"95467","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.724198,38.861317],[-122.754314,38.805797],[-122.71395,38.751461],[-122.696683,38.72101],[-122.521684,38.692016],[-122.415789,38.768002],[-122.543655,38.826236],[-122.538194,38.802003],[-122.575938,38.800058],[-122.697791,38.896698],[-122.724198,38.861317]]]},"properties":{"id":"95461","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.81612,39.018342],[-122.886533,39.118209],[-123.027813,39.151723],[-122.986642,38.996261],[-122.949674,38.91586],[-122.81612,39.018342]],[[-122.866168,39.004248],[-122.872961,39.004306],[-122.871315,39.011215],[-122.866314,39.011205],[-122.866168,39.004248]]]},"properties":{"id":"95453","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.642218,41.07841],[-120.347743,40.955809],[-120.620688,41.184469],[-120.641635,41.184014],[-120.642243,41.184015],[-120.642218,41.07841]]]},"properties":{"id":"96119","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.076897,40.145319],[-120.213565,40.144237],[-120.142382,40.12926],[-120.076897,40.145319]]]},"properties":{"id":"96113","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.620778,41.183576],[-121.320178,40.901539],[-121.146572,40.977316],[-121.213909,41.059696],[-121.322635,41.183866],[-121.620778,41.183576]]]},"properties":{"id":"96056","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.588481,34.208309],[-118.588445,34.188346],[-118.579702,34.188376],[-118.570953,34.185695],[-118.562228,34.19026],[-118.562289,34.219108],[-118.571048,34.231844],[-118.579785,34.231839],[-118.588481,34.208309]]]},"properties":{"id":"91306","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.122915,34.069775],[-118.130099,34.033578],[-118.100272,34.044762],[-118.107463,34.071545],[-118.108189,34.071654],[-118.122915,34.069775]]]},"properties":{"id":"91755","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.087806,33.880338],[-118.090901,33.861313],[-118.072241,33.854512],[-118.074895,33.880383],[-118.087806,33.880338]]]},"properties":{"id":"90701","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.14427,33.960924],[-118.158604,33.951894],[-118.161267,33.943933],[-118.111518,33.920123],[-118.089953,33.93167],[-118.09847,33.944137],[-118.14427,33.960924]]]},"properties":{"id":"90241","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.317753,33.930901],[-118.347454,33.931724],[-118.368387,33.930946],[-118.374342,33.923661],[-118.378377,33.923654],[-118.378701,33.901905],[-118.378703,33.901794],[-118.333567,33.894661],[-118.314247,33.91645],[-118.317753,33.930901]]]},"properties":{"id":"90250","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.416319,34.067079],[-118.389672,34.06705],[-118.390295,34.072082],[-118.389725,34.080759],[-118.392114,34.106261],[-118.398129,34.120193],[-118.414903,34.130982],[-118.442158,34.130898],[-118.425974,34.080835],[-118.416319,34.067079]]]},"properties":{"id":"90210","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.337348,34.068911],[-118.284334,34.068528],[-118.284036,34.072629],[-118.284211,34.083625],[-118.309145,34.083509],[-118.338583,34.083457],[-118.337348,34.068911]]]},"properties":{"id":"90004","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.24524,34.04226],[-118.227695,34.038436],[-118.229988,34.047908],[-118.250157,34.052347],[-118.253932,34.050176],[-118.24524,34.04226]]]},"properties":{"id":"90013","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.370302,34.08368],[-118.392114,34.106261],[-118.389725,34.080759],[-118.370302,34.08368]]]},"properties":{"id":"90069","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.770257,34.56321],[-118.699613,34.419733],[-118.6929,34.406088],[-118.656665,34.419931],[-118.57907,34.460992],[-118.554719,34.503916],[-118.752972,34.649295],[-118.820915,34.665851],[-118.770257,34.56321]]]},"properties":{"id":"91384","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.548577,33.375028],[-118.327993,33.248083],[-118.359393,33.464922],[-118.548577,33.375028]]]},"properties":{"id":"90704","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.226772,33.937096],[-118.265158,33.939292],[-118.265158,33.938348],[-118.263149,33.902376],[-118.262304,33.902415],[-118.259672,33.902526],[-118.228805,33.922685],[-118.226772,33.937096]]]},"properties":{"id":"90059","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.387741,34.158603],[-118.351812,34.158271],[-118.358803,34.179077],[-118.39503,34.179405],[-118.387741,34.158603]]]},"properties":{"id":"91601","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.422619,34.221661],[-118.370312,34.201197],[-118.350369,34.206672],[-118.344121,34.216998],[-118.290771,34.234821],[-118.322382,34.249631],[-118.375225,34.256954],[-118.387341,34.256672],[-118.420324,34.22564],[-118.422619,34.221661]]]},"properties":{"id":"91352","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.547357,34.324675],[-118.670593,34.360692],[-118.638429,34.254468],[-118.579785,34.231839],[-118.571048,34.231844],[-118.560076,34.25726],[-118.540943,34.298794],[-118.547357,34.324675]]]},"properties":{"id":"91311","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.345665,34.142367],[-118.362006,34.136994],[-118.366056,34.128968],[-118.34484,34.104012],[-118.309282,34.105381],[-118.312615,34.154477],[-118.325341,34.153514],[-118.345166,34.142747],[-118.345665,34.142367]]]},"properties":{"id":"90068","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.296128,33.781299],[-118.240387,33.767706],[-118.239988,33.768007],[-118.227512,33.790368],[-118.23751,33.80048],[-118.286737,33.797818],[-118.296128,33.781299]]]},"properties":{"id":"90744","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.223361,34.017379],[-118.174402,34.001574],[-118.173987,34.017399],[-118.173889,34.03335],[-118.206036,34.034909],[-118.227695,34.038436],[-118.223361,34.017379]]]},"properties":{"id":"90023","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.372627,34.018471],[-118.3329,34.018232],[-118.329738,34.039872],[-118.369801,34.040019],[-118.369577,34.03508],[-118.372627,34.018471]]]},"properties":{"id":"90016","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.125075,33.847055],[-118.093309,33.831712],[-118.098939,33.845875],[-118.108429,33.865587],[-118.12514,33.869035],[-118.125075,33.847055]]]},"properties":{"id":"90713","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.242772,34.020595],[-118.243139,33.989443],[-118.237907,33.989333],[-118.204156,33.988957],[-118.169859,33.986646],[-118.175455,33.998347],[-118.174402,34.001574],[-118.223361,34.017379],[-118.242772,34.020595]]]},"properties":{"id":"90058","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.067784,34.148867],[-118.030768,34.107182],[-118.02591,34.095858],[-118.020174,34.097117],[-118.015284,34.102989],[-118.004542,34.111102],[-118.003747,34.117366],[-118.017593,34.179453],[-118.030139,34.179402],[-118.067958,34.156821],[-118.067784,34.148867]]]},"properties":{"id":"91006","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.036519,33.917],[-118.028888,33.882629],[-118.028679,33.873219],[-118.011398,33.873314],[-117.98535,33.895603],[-117.976586,33.906225],[-117.976495,33.917378],[-118.033851,33.916999],[-118.036519,33.917]]]},"properties":{"id":"90638","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.063773,34.004281],[-118.088827,33.966758],[-118.050863,33.95887],[-118.049305,33.979071],[-118.063773,34.004281]]]},"properties":{"id":"90606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.007342,34.105566],[-118.004542,34.111102],[-118.015284,34.102989],[-118.007342,34.105566]]],[[[-118.017593,34.179453],[-118.003747,34.117366],[-117.99718,34.117683],[-117.97538,34.143658],[-117.977525,34.165107],[-118.017593,34.179453]]]]},"properties":{"id":"91016","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.97538,34.143658],[-117.95972,34.165159],[-117.977525,34.165107],[-117.97538,34.143658]]]},"properties":{"id":"91008","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.724548,34.165396],[-117.677706,34.165173],[-117.655967,34.164615],[-117.650454,34.338963],[-117.749696,34.222855],[-117.724548,34.165396]]]},"properties":{"id":"91759","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.049305,33.979071],[-118.050863,33.95887],[-118.047142,33.95572],[-117.98782,33.974515],[-118.002474,33.989289],[-118.049305,33.979071]]]},"properties":{"id":"90602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.579702,34.188376],[-118.579648,34.181275],[-118.570953,34.185695],[-118.579702,34.188376]]]},"properties":{"id":"91371","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.656226,37.417818],[-119.745957,37.349967],[-119.760973,37.303879],[-119.581979,37.28766],[-119.562184,37.286148],[-119.57911,37.323616],[-119.615286,37.481244],[-119.656226,37.417818]]]},"properties":{"id":"93644","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.855955,37.192029],[-119.781078,37.039347],[-119.747466,37.098233],[-119.596797,37.243364],[-119.581979,37.28766],[-119.760973,37.303879],[-119.855955,37.192029]]]},"properties":{"id":"93614","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.659678,37.941658],[-122.628711,37.885352],[-122.626534,37.886724],[-122.612657,37.910475],[-122.655949,37.938335],[-122.659678,37.941658]]]},"properties":{"id":"94970","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.690172,38.024481],[-122.692252,38.004566],[-122.67971,38.003531],[-122.690172,38.024481]]]},"properties":{"id":"94933","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.125316,39.255459],[-123.184993,39.420734],[-123.387665,39.33982],[-123.421655,39.288741],[-123.125316,39.255459]]]},"properties":{"id":"95470","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.488909,39.180838],[-123.429046,39.158243],[-123.533961,39.295446],[-123.658695,39.288182],[-123.674197,39.254623],[-123.591731,39.174183],[-123.488909,39.180838]]]},"properties":{"id":"95427","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.813344,39.300905],[-123.674514,39.254792],[-123.674197,39.254623],[-123.658695,39.288182],[-123.787054,39.360209],[-123.830285,39.367617],[-123.813344,39.300905]]]},"properties":{"id":"95460","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.541967,39.760166],[-123.631406,39.754662],[-123.700054,39.683955],[-123.672944,39.597869],[-123.615727,39.594575],[-123.580454,39.625635],[-123.541967,39.760166]]]},"properties":{"id":"95417","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.488909,39.180838],[-123.591731,39.174183],[-123.583127,39.161111],[-123.488909,39.180838]]]},"properties":{"id":"95463","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.360732,41.907871],[-120.46739,41.651883],[-120.274022,41.652982],[-120.274066,41.718617],[-120.354155,41.857332],[-120.360732,41.907871]]]},"properties":{"id":"96108","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.602991,37.856898],[-118.435178,37.901289],[-118.546091,37.981379],[-118.602991,37.856898]]]},"properties":{"id":"93512","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.785869,36.376859],[-121.769676,36.548424],[-121.912971,36.574834],[-121.928953,36.558926],[-121.936626,36.545814],[-121.894808,36.3023],[-121.785869,36.376859]]]},"properties":{"id":"93923","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.678988,36.682652],[-121.621791,36.658662],[-121.648425,36.684703],[-121.652524,36.686866],[-121.678988,36.682652]]]},"properties":{"id":"93901","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.878437,36.59803],[-121.87735,36.593689],[-121.868416,36.596769],[-121.874224,36.600058],[-121.878437,36.59803]]]},"properties":{"id":"93943","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.290676,38.155204],[-122.21466,38.15492],[-122.201893,38.168299],[-122.204167,38.209699],[-122.306755,38.209041],[-122.294885,38.155218],[-122.290676,38.155204]]]},"properties":{"id":"94503","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.412175,38.46459],[-122.31465,38.611904],[-122.428996,38.61244],[-122.488549,38.571781],[-122.569473,38.529659],[-122.546393,38.521208],[-122.510492,38.467209],[-122.479973,38.446391],[-122.433105,38.453343],[-122.412175,38.46459]],[[-122.480735,38.548424],[-122.482607,38.553113],[-122.471946,38.5547],[-122.472355,38.544982],[-122.480735,38.548424]]]},"properties":{"id":"94574","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.488549,38.571781],[-122.428996,38.61244],[-122.487954,38.606672],[-122.488549,38.571781]]]},"properties":{"id":"94508","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.992781,33.77379],[-117.954893,33.752224],[-117.95505,33.774044],[-117.958414,33.774131],[-117.992781,33.77379]]]},"properties":{"id":"92844","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.941985,33.745019],[-117.907224,33.752682],[-117.894732,33.774478],[-117.897433,33.774467],[-117.95505,33.774044],[-117.954893,33.752224],[-117.945978,33.732208],[-117.94185,33.733298],[-117.941985,33.745019]]]},"properties":{"id":"92843","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.75851,33.78217],[-117.795023,33.719649],[-117.78222,33.710154],[-117.717286,33.736985],[-117.747294,33.784917],[-117.75851,33.78217]]]},"properties":{"id":"92602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.060127,33.699282],[-118.023718,33.700951],[-118.0419,33.74439],[-118.083528,33.728969],[-118.07844,33.72415],[-118.073057,33.710736],[-118.060127,33.699282]]]},"properties":{"id":"92649","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.747973,33.597976],[-117.716087,33.597294],[-117.712747,33.620211],[-117.714735,33.622359],[-117.746969,33.621193],[-117.758781,33.626908],[-117.760606,33.595732],[-117.747973,33.597976]]]},"properties":{"id":"92637","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.686633,33.487293],[-117.733257,33.49814],[-117.730208,33.479596],[-117.6873,33.455081],[-117.683317,33.46431],[-117.686633,33.487293]]]},"properties":{"id":"92629","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.67276,33.54737],[-117.663299,33.538109],[-117.633159,33.575009],[-117.623632,33.664713],[-117.63763,33.66192],[-117.641853,33.660801],[-117.67276,33.54737]]]},"properties":{"id":"92692","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.86763,33.69178],[-117.86722,33.68671],[-117.857229,33.682685],[-117.794998,33.663279],[-117.788718,33.669538],[-117.810182,33.682152],[-117.848915,33.700178],[-117.861366,33.698577],[-117.86763,33.69178]]]},"properties":{"id":"92614","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.883053,33.860035],[-117.837065,33.864745],[-117.817732,33.869269],[-117.842386,33.909894],[-117.855,33.9063],[-117.863877,33.907404],[-117.876891,33.89636],[-117.883053,33.860035]]]},"properties":{"id":"92870","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.689708,33.670974],[-117.640465,33.663513],[-117.637035,33.698441],[-117.717373,33.736179],[-117.72024,33.717746],[-117.689708,33.670974]]]},"properties":{"id":"92610","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.456158,33.68916],[-117.460901,33.598031],[-117.3036,33.597693],[-117.291923,33.654054],[-117.363201,33.718316],[-117.393486,33.72988],[-117.456158,33.68916]]]},"properties":{"id":"92530","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.414625,38.996014],[-121.45726,38.92604],[-121.414342,38.841512],[-121.346712,38.824532],[-121.242152,38.839205],[-121.217458,38.862613],[-121.203291,38.874955],[-121.185924,38.934013],[-121.192281,39.015532],[-121.279533,39.034618],[-121.321631,39.043753],[-121.322163,38.955247],[-121.408953,39.001159],[-121.414625,38.996014]]]},"properties":{"id":"95648","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.307892,38.797208],[-121.267802,38.794284],[-121.230485,38.822467],[-121.242152,38.839205],[-121.346712,38.824532],[-121.307892,38.797208]]]},"properties":{"id":"95765","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.247222,38.770927],[-121.217839,38.770384],[-121.206934,38.774198],[-121.230485,38.822467],[-121.267802,38.794284],[-121.247222,38.770927]]]},"properties":{"id":"95677","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.267802,38.794284],[-121.307892,38.797208],[-121.30883,38.72279],[-121.293849,38.721403],[-121.247222,38.770927],[-121.267802,38.794284]]]},"properties":{"id":"95678","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.975244,39.034502],[-120.974155,39.044685],[-120.981003,39.04367],[-120.982492,39.037822],[-120.976156,39.03248],[-120.975244,39.034502]]]},"properties":{"id":"95736","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.421087,39.892886],[-120.540254,39.859806],[-120.568929,39.761231],[-120.485563,39.756278],[-120.372754,39.864461],[-120.421087,39.892886]]]},"properties":{"id":"96122","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.210594,39.889504],[-120.228171,39.88351],[-120.239737,39.802029],[-120.201171,39.707901],[-120.148107,39.847343],[-120.210594,39.889504]]]},"properties":{"id":"96135","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.085893,40.172134],[-121.122153,40.137515],[-121.111618,40.107452],[-121.078226,40.112226],[-121.097974,40.148006],[-121.060961,40.171078],[-121.085893,40.172134]]]},"properties":{"id":"95923","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.219159,39.725653],[-121.022391,39.947247],[-121.049629,39.958367],[-121.259842,39.754177],[-121.219159,39.725653]]]},"properties":{"id":"95956","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.505635,33.971539],[-117.466055,33.964413],[-117.427644,33.968482],[-117.370274,34.019444],[-117.367372,34.038234],[-117.414178,34.034306],[-117.506891,34.033485],[-117.505635,33.971539]]]},"properties":{"id":"92509","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.565233,33.882454],[-117.567929,33.852487],[-117.506258,33.869004],[-117.501299,33.894249],[-117.51406,33.910063],[-117.560039,33.898605],[-117.565233,33.882454]]]},"properties":{"id":"92879","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.581956,33.770685],[-117.569175,33.76461],[-117.450178,33.810417],[-117.440409,33.825485],[-117.506258,33.869004],[-117.567929,33.852487],[-117.581956,33.770685]]]},"properties":{"id":"92881","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.209711,33.608973],[-117.24034,33.654337],[-117.291923,33.654054],[-117.3036,33.597693],[-117.209711,33.608973]]]},"properties":{"id":"92595","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.100191,33.551865],[-117.17254,33.536523],[-117.168241,33.530065],[-117.151535,33.500743],[-117.031722,33.568577],[-117.100191,33.551865]]]},"properties":{"id":"92591","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.930157,34.004914],[-116.990247,33.990775],[-117.081185,33.970632],[-117.050635,33.933768],[-116.990775,33.88337],[-116.929209,33.886444],[-116.930157,34.004914]]]},"properties":{"id":"92223","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.280017,33.69742],[-117.228683,33.685023],[-117.226976,33.703446],[-117.228084,33.722818],[-117.280017,33.69742]]]},"properties":{"id":"92587","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.345984,33.48254],[-117.265824,33.329206],[-117.26917,33.300931],[-117.262154,33.286283],[-117.152863,33.314196],[-117.140008,33.314375],[-117.133006,33.342922],[-117.094558,33.430505],[-117.143979,33.447295],[-117.322403,33.475031],[-117.345984,33.48254]]]},"properties":{"id":"92028","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.512618,38.541666],[-121.484323,38.539613],[-121.475758,38.541064],[-121.473988,38.562831],[-121.482674,38.565148],[-121.508522,38.574534],[-121.511566,38.575005],[-121.512618,38.541666]]]},"properties":{"id":"95818","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.477105,38.669996],[-121.421372,38.658115],[-121.401365,38.690542],[-121.401318,38.704508],[-121.401483,38.70639],[-121.540133,38.700054],[-121.540012,38.685505],[-121.477105,38.669996]]]},"properties":{"id":"95673","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.395592,38.221023],[-121.088288,38.223739],[-121.016369,38.274402],[-121.027084,38.300252],[-121.220417,38.33588],[-121.343617,38.357622],[-121.343672,38.35772],[-121.447774,38.2551],[-121.417281,38.222212],[-121.395592,38.221023]]]},"properties":{"id":"95632","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.566358,38.239671],[-121.602004,38.248076],[-121.602763,38.230219],[-121.566358,38.239671]]]},"properties":{"id":"95680","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.363645,38.703274],[-121.363247,38.658272],[-121.326862,38.691852],[-121.334819,38.70341],[-121.363645,38.703274]]]},"properties":{"id":"95842","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.375284,38.647397],[-121.345933,38.645476],[-121.328001,38.673216],[-121.326862,38.691852],[-121.363247,38.658272],[-121.375284,38.647397]]]},"properties":{"id":"95841","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.483916,38.408739],[-121.486884,38.422933],[-121.514121,38.388742],[-121.521153,38.367408],[-121.501371,38.369421],[-121.483916,38.408739]]]},"properties":{"id":"95639","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.255396,34.470945],[-117.346019,34.470462],[-117.390663,34.437414],[-117.415919,34.325079],[-117.241476,34.350822],[-117.255396,34.470945]]]},"properties":{"id":"92345","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.06824,34.221391],[-117.034516,34.225455],[-117.033769,34.251293],[-117.086248,34.247289],[-117.094568,34.211915],[-117.06824,34.221391]]]},"properties":{"id":"92341","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.767745,34.019605],[-117.783287,33.946411],[-117.748097,33.921432],[-117.655335,33.921397],[-117.655377,33.924441],[-117.687255,33.971937],[-117.745378,34.020018],[-117.767745,34.019605]]]},"properties":{"id":"91709","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.649598,34.063365],[-117.654503,34.021857],[-117.650819,34.004799],[-117.575469,33.983079],[-117.558377,33.98842],[-117.524313,34.033469],[-117.524096,34.06741],[-117.649598,34.063365]]]},"properties":{"id":"91761","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.651096,34.087421],[-117.649598,34.063365],[-117.524096,34.06741],[-117.524091,34.067692],[-117.524027,34.07718],[-117.628572,34.092558],[-117.651096,34.087421]]]},"properties":{"id":"91764","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.61235,34.16508],[-117.545267,34.136485],[-117.561274,34.165291],[-117.61235,34.16508]]]},"properties":{"id":"91737","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.65056,33.968551],[-117.610482,33.968822],[-117.600066,33.975316],[-117.650627,33.983058],[-117.65056,33.968551]]],[[[-117.687255,33.971937],[-117.655377,33.924441],[-117.610913,33.960084],[-117.65056,33.968551],[-117.687255,33.971937]]]]},"properties":{"id":"91708","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.243693,34.063053],[-117.124773,34.027919],[-117.104315,34.040333],[-117.13891,34.09036],[-117.20878,34.099332],[-117.243642,34.071135],[-117.243693,34.063053]]]},"properties":{"id":"92374","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.510212,34.299769],[-117.569118,34.325941],[-117.558727,34.219084],[-117.465952,34.220108],[-117.510212,34.299769]]]},"properties":{"id":"92358","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.306544,34.07989],[-117.27804,34.051901],[-117.243642,34.071135],[-117.20878,34.099332],[-117.234945,34.106763],[-117.28516,34.104577],[-117.294153,34.092149],[-117.306544,34.07989]]]},"properties":{"id":"92408","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.43144,34.136467],[-117.405293,34.106735],[-117.40522,34.092216],[-117.365907,34.068885],[-117.348526,34.085045],[-117.335518,34.121375],[-117.347943,34.136018],[-117.43144,34.136467]]]},"properties":{"id":"92376","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.682494,34.293432],[-116.646999,34.206591],[-116.563272,34.118286],[-116.51116,34.118355],[-116.509818,34.296109],[-116.682494,34.293432]]]},"properties":{"id":"92268","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.956189,32.94165],[-116.962261,32.872948],[-116.938108,32.824251],[-116.794318,32.883445],[-116.789906,32.88839],[-116.787617,32.949266],[-116.958254,32.962865],[-116.956189,32.94165]]]},"properties":{"id":"92040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.234931,32.83802],[-117.262041,32.800212],[-117.255851,32.753713],[-117.224222,32.75771],[-117.207123,32.788536],[-117.234649,32.838039],[-117.234931,32.83802]]]},"properties":{"id":"92109","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.143776,32.75688],[-117.114163,32.757154],[-117.100783,32.757076],[-117.101038,32.779036],[-117.161579,32.758762],[-117.143776,32.75688]]]},"properties":{"id":"92116","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.207123,32.788536],[-117.189612,32.798033],[-117.177881,32.847326],[-117.234649,32.838039],[-117.207123,32.788536]]]},"properties":{"id":"92117","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.097071,32.613765],[-117.016251,32.623535],[-117.005055,32.648147],[-117.071364,32.654997],[-117.102416,32.646648],[-117.097071,32.613765]]]},"properties":{"id":"91910","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.067014,33.177844],[-117.140008,33.314375],[-117.152863,33.314196],[-117.158495,33.266675],[-117.174519,33.227209],[-117.113684,33.128589],[-117.104917,33.127044],[-117.070452,33.136083],[-117.067014,33.177844]]]},"properties":{"id":"92026","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.244044,32.663544],[-116.226679,32.609052],[-116.10618,32.618592],[-116.105699,32.696877],[-116.244044,32.663544]]]},"properties":{"id":"91934","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.238859,33.256619],[-117.158495,33.266675],[-117.152863,33.314196],[-117.262154,33.286283],[-117.238859,33.256619]]]},"properties":{"id":"92003","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.147574,32.705251],[-117.085,32.7041],[-117.08493,32.718046],[-117.117798,32.717129],[-117.142597,32.722716],[-117.148336,32.721102],[-117.147574,32.705251]]]},"properties":{"id":"92102","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.288516,33.18381],[-117.257399,33.235125],[-117.26134,33.241097],[-117.333422,33.208619],[-117.322427,33.182125],[-117.256269,33.153309],[-117.288516,33.18381]]]},"properties":{"id":"92056","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.404868,33.203758],[-117.360624,33.163783],[-117.326909,33.181191],[-117.322427,33.182125],[-117.333422,33.208619],[-117.331152,33.223785],[-117.404868,33.203758]]]},"properties":{"id":"92054","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.184275,32.740481],[-117.209555,32.742539],[-117.208701,32.734341],[-117.184275,32.740481]]]},"properties":{"id":"92140","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.823856,33.284536],[-116.710012,33.264395],[-116.857095,33.426574],[-116.927965,33.427362],[-116.935037,33.427159],[-116.928714,33.326765],[-116.823856,33.284536]]]},"properties":{"id":"92060","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.57848,33.453927],[-117.424921,33.256933],[-117.349313,33.325171],[-117.509582,33.471662],[-117.572979,33.453305],[-117.57848,33.453927]]]},"properties":{"id":"92055","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.428426,37.770452],[-122.454002,37.758785],[-122.424845,37.748633],[-122.426382,37.764646],[-122.426309,37.769603],[-122.428426,37.770452]]]},"properties":{"id":"94114","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.40509,37.764628],[-122.426382,37.764646],[-122.424845,37.748633],[-122.426722,37.736372],[-122.415459,37.732164],[-122.407851,37.737755],[-122.403784,37.749433],[-122.40509,37.764628]]]},"properties":{"id":"94110","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.453293,37.757959],[-121.410225,37.761501],[-121.416381,37.710437],[-121.556788,37.565102],[-121.471925,37.481783],[-121.330051,37.59383],[-121.258171,37.653495],[-121.242018,37.664585],[-121.300428,37.779704],[-121.413801,37.80341],[-121.55208,37.822497],[-121.55696,37.799106],[-121.55696,37.798837],[-121.531466,37.742581],[-121.453293,37.757959]]]},"properties":{"id":"95304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.277476,37.951724],[-121.27586,37.945489],[-121.22995,37.921597],[-121.25754,38.004798],[-121.281607,37.994453],[-121.286611,37.968739],[-121.277476,37.951724]]]},"properties":{"id":"95205","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.943584,37.856762],[-120.926173,37.738408],[-120.921914,37.719051],[-120.804291,37.682035],[-120.45857,37.68711],[-120.517052,37.731224],[-120.57114,37.772038],[-120.652673,37.831422],[-120.747344,37.916054],[-120.943584,37.856762]]]},"properties":{"id":"95361","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.604764,35.106563],[-120.591507,35.04743],[-120.417631,35.081869],[-120.247518,35.137464],[-120.407906,35.266133],[-120.552972,35.312017],[-120.629586,35.165451],[-120.606811,35.129491],[-120.604764,35.106563]]]},"properties":{"id":"93420","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.053254,35.499994],[-120.907311,35.544536],[-120.912039,35.577789],[-121.062072,35.678697],[-121.129764,35.594057],[-121.053254,35.499994]]]},"properties":{"id":"93428","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.374915,37.60409],[-122.412623,37.589156],[-122.361134,37.531361],[-122.329963,37.540999],[-122.332418,37.565238],[-122.333032,37.587885],[-122.374915,37.60409]]]},"properties":{"id":"94010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.459982,37.666647],[-122.469236,37.708232],[-122.498233,37.708132],[-122.495093,37.664368],[-122.471883,37.64917],[-122.459982,37.666647]]]},"properties":{"id":"94015","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.497067,37.495452],[-122.488993,37.533661],[-122.518982,37.53737],[-122.497067,37.495452]]]},"properties":{"id":"94038","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.242473,37.215133],[-122.151457,37.230023],[-122.192513,37.318795],[-122.300418,37.361982],[-122.305681,37.283843],[-122.294583,37.278333],[-122.260709,37.248561],[-122.242473,37.215133]]]},"properties":{"id":"94020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.710752,34.435956],[-119.688566,34.411805],[-119.684856,34.407092],[-119.659066,34.416442],[-119.688786,34.449074],[-119.710752,34.435956]]]},"properties":{"id":"93103","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.584472,34.428691],[-119.593483,34.418468],[-119.476822,34.372834],[-119.392332,34.41387],[-119.442354,34.49095],[-119.596274,34.475796],[-119.584472,34.428691]]]},"properties":{"id":"93013","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.915769,34.719026],[-120.103843,34.650323],[-120.12566,34.538875],[-120.01081,34.546874],[-119.953593,34.585955],[-119.915769,34.719026]]]},"properties":{"id":"93460","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.0478,37.38633],[-122.004415,37.376705],[-121.98697,37.37778],[-121.986976,37.388448],[-122.037361,37.400906],[-122.0478,37.38633]]]},"properties":{"id":"94085","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.733748,37.333474],[-121.820407,37.341242],[-121.80792,37.313816],[-121.791769,37.31404],[-121.733748,37.333474]]]},"properties":{"id":"95148","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.846169,37.36794],[-121.827644,37.350835],[-121.820407,37.341242],[-121.733748,37.333474],[-121.732563,37.339345],[-121.791975,37.403516],[-121.846235,37.389068],[-121.846169,37.36794]]]},"properties":{"id":"95127","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.786095,37.245854],[-121.800888,37.225944],[-121.778225,37.208188],[-121.770862,37.217377],[-121.768706,37.235438],[-121.786095,37.245854]]]},"properties":{"id":"95119","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.0624,37.33754],[-122.09525,37.332277],[-122.143823,37.31359],[-122.138483,37.267513],[-122.032264,37.293965],[-121.99986,37.32294],[-122.00254,37.3378],[-122.0624,37.33754]]]},"properties":{"id":"95014","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.086207,37.450275],[-122.10203,37.41552],[-122.10831,37.40808],[-122.091312,37.400534],[-122.0578,37.38752],[-122.0478,37.38633],[-122.037361,37.400906],[-122.041135,37.427012],[-122.059936,37.445624],[-122.086207,37.450275]]]},"properties":{"id":"94043","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.99986,37.32294],[-122.032264,37.293965],[-121.994554,37.289476],[-121.979518,37.300418],[-121.96836,37.32312],[-121.99986,37.32294]]]},"properties":{"id":"95129","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.932957,37.337815],[-121.950212,37.328381],[-121.949918,37.295545],[-121.931669,37.297222],[-121.932957,37.337815]]]},"properties":{"id":"95128","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.96836,37.32312],[-121.979518,37.300418],[-121.96452,37.301166],[-121.949918,37.295545],[-121.950212,37.328381],[-121.96418,37.3233],[-121.96836,37.32312]]]},"properties":{"id":"95117","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.100985,37.108953],[-122.1165,37.103782],[-122.094585,37.099451],[-122.100985,37.108953]]]},"properties":{"id":"95007","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.368025,40.585818],[-122.258214,40.560991],[-122.23455,40.614912],[-122.058561,40.772268],[-122.103427,40.919893],[-122.224919,40.901577],[-122.409781,40.740994],[-122.419181,40.718588],[-122.368025,40.585818]],[[-122.411088,40.677734],[-122.393463,40.709755],[-122.344693,40.693156],[-122.354936,40.650697],[-122.411088,40.677734]]]},"properties":{"id":"96003","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.472868,39.5746],[-120.522787,39.657528],[-120.717977,39.633643],[-120.731746,39.507131],[-120.731443,39.470257],[-120.713744,39.482788],[-120.592447,39.522242],[-120.472868,39.5746]]]},"properties":{"id":"96125","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.493081,39.706608],[-120.522787,39.657528],[-120.472868,39.5746],[-120.420356,39.602281],[-120.364749,39.708495],[-120.487992,39.706581],[-120.493081,39.706608]]]},"properties":{"id":"96124","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.313868,41.802438],[-123.277369,41.741508],[-123.274949,41.695958],[-123.160286,41.696068],[-123.151988,41.829165],[-123.102896,42.006827],[-123.408277,42.000393],[-123.313868,41.802438]]]},"properties":{"id":"96086","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.363488,41.388759],[-122.542454,41.349863],[-122.518725,41.209228],[-122.172224,41.265471],[-122.210486,41.377749],[-122.363488,41.388759]]]},"properties":{"id":"96067","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.736093,38.554882],[-122.786482,38.540333],[-122.793726,38.502624],[-122.760082,38.49877],[-122.788291,38.477041],[-122.789028,38.472766],[-122.717158,38.461007],[-122.736093,38.554882]]]},"properties":{"id":"95403","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.690334,38.452662],[-122.630276,38.420214],[-122.600663,38.402487],[-122.510492,38.467209],[-122.546393,38.521208],[-122.690334,38.452662]]]},"properties":{"id":"95409","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.793726,38.502624],[-122.788291,38.477041],[-122.760082,38.49877],[-122.793726,38.502624]]]},"properties":{"id":"95439","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.520393,38.347356],[-122.518983,38.345656],[-122.51657,38.348673],[-122.518637,38.348592],[-122.520393,38.347356]]]},"properties":{"id":"95431","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.023327,38.47491],[-123.024829,38.475448],[-123.025093,38.473057],[-123.023099,38.47383],[-123.023327,38.47491]]]},"properties":{"id":"95486","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.901395,37.551249],[-120.835578,37.543863],[-120.767356,37.594699],[-120.782708,37.62507],[-120.80167,37.61882],[-120.938345,37.616182],[-120.926838,37.60931],[-120.901848,37.56564],[-120.901395,37.551249]]]},"properties":{"id":"95326","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.974553,37.648365],[-121.009967,37.64607],[-120.938724,37.616718],[-120.939067,37.654242],[-120.974553,37.648365]]]},"properties":{"id":"95354","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.130731,40.10115],[-122.275861,40.029412],[-122.144356,40.011556],[-122.11927,40.028545],[-122.130731,40.10115]]]},"properties":{"id":"96035","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.102413,35.796744],[-119.125565,35.834235],[-119.177595,35.790578],[-119.102413,35.796744]]]},"properties":{"id":"93261","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.313657,36.530541],[-119.324336,36.487054],[-119.286615,36.460174],[-119.240831,36.507008],[-119.313657,36.530541]]]},"properties":{"id":"93615","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.648106,36.096749],[-118.482386,35.993028],[-118.437121,36.133412],[-118.59062,36.335262],[-118.855601,36.321584],[-118.913329,36.225601],[-118.893831,36.114406],[-118.648106,36.096749]]]},"properties":{"id":"93265","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.490472,36.45236],[-119.474634,36.444477],[-119.474515,36.458963],[-119.490472,36.45236]]]},"properties":{"id":"93673","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.181251,36.224906],[-118.966453,36.225614],[-118.913329,36.225601],[-118.855601,36.321584],[-118.965812,36.351551],[-119.062308,36.368779],[-119.134627,36.36028],[-119.198175,36.312224],[-119.207238,36.290549],[-119.181416,36.254013],[-119.181251,36.224906]]]},"properties":{"id":"93221","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.198175,36.312224],[-119.215885,36.319636],[-119.207238,36.290549],[-119.198175,36.312224]]]},"properties":{"id":"93223","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.143098,36.051245],[-119.143787,36.058565],[-119.154555,36.058482],[-119.161061,36.051261],[-119.143098,36.051245]]]},"properties":{"id":"93258","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.00771,34.245764],[-119.126589,34.267995],[-119.119938,34.221917],[-119.109727,34.196673],[-119.096113,34.19656],[-119.00771,34.245764]]]},"properties":{"id":"93010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.975167,34.422487],[-118.978397,34.329717],[-118.955226,34.335681],[-118.825995,34.358696],[-118.785719,34.409956],[-118.87595,34.470803],[-118.975167,34.422487]]]},"properties":{"id":"93015","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.109727,34.196673],[-119.19485,34.190078],[-119.194975,34.180753],[-119.194982,34.175684],[-119.194485,34.170636],[-119.179049,34.130183],[-119.161281,34.117931],[-119.06796,34.093202],[-119.036993,34.13047],[-119.096113,34.19656],[-119.109727,34.196673]]]},"properties":{"id":"93033","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.236036,34.197729],[-119.19485,34.190078],[-119.109727,34.196673],[-119.119938,34.221917],[-119.236036,34.197729]]]},"properties":{"id":"93030","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.671476,34.241484],[-118.715495,34.262109],[-118.719319,34.226449],[-118.672975,34.238706],[-118.671476,34.241484]]]},"properties":{"id":"93064","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.202462,38.688976],[-122.175702,38.815771],[-122.258334,38.785532],[-122.202462,38.688976]]]},"properties":{"id":"95606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.508668,38.596803],[-121.54789,38.598774],[-121.508266,38.580505],[-121.506275,38.586288],[-121.508668,38.596803]]]},"properties":{"id":"95605","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.220879,39.448016],[-121.141449,39.392532],[-121.134368,39.537903],[-121.208215,39.527196],[-121.243399,39.483381],[-121.220879,39.448016]]]},"properties":{"id":"95925","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.165823,37.753588],[-122.148725,37.740859],[-122.127391,37.729686],[-122.119812,37.731633],[-122.178401,37.783822],[-122.187944,37.777823],[-122.194594,37.773522],[-122.197348,37.771623],[-122.165823,37.753588]]]},"properties":{"id":"94605","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.225895,37.749694],[-122.259468,37.752594],[-122.256542,37.721242],[-122.225895,37.749694]]]},"properties":{"id":"94502","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.266329,37.874188],[-122.255593,37.880194],[-122.266162,37.886083],[-122.275673,37.884755],[-122.273265,37.873325],[-122.266329,37.874188]]]},"properties":{"id":"94709","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.02536,37.578493],[-122.038506,37.563408],[-122.00236,37.529774],[-121.958779,37.559523],[-121.932146,37.573315],[-121.932767,37.591732],[-121.951652,37.597529],[-122.02536,37.578493]]]},"properties":{"id":"94536","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.99332,37.499453],[-121.974706,37.46074],[-121.925548,37.454389],[-121.917097,37.458473],[-121.958779,37.559523],[-122.00236,37.529774],[-121.99332,37.499453]]]},"properties":{"id":"94538","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.126254,37.664274],[-122.10161,37.656422],[-122.074749,37.666626],[-122.041348,37.680986],[-122.059972,37.69359],[-122.104167,37.695511],[-122.111838,37.688721],[-122.126254,37.664274]]]},"properties":{"id":"94541","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.104167,37.695511],[-122.059972,37.69359],[-122.077715,37.748209],[-122.119812,37.731633],[-122.127391,37.729686],[-122.122274,37.727313],[-122.104167,37.695511]]]},"properties":{"id":"94546","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.632197,37.889288],[-121.55696,37.799106],[-121.55208,37.822497],[-121.560386,37.858316],[-121.565594,37.918193],[-121.632197,37.889288]]]},"properties":{"id":"94505","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.878949,38.407386],[-120.890504,38.406585],[-121.027272,38.458034],[-121.02724,38.397686],[-121.027084,38.300252],[-121.016369,38.274402],[-120.995497,38.225405],[-120.889057,38.227188],[-120.850053,38.257254],[-120.819757,38.397314],[-120.878949,38.407386]]]},"properties":{"id":"95640","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.698191,38.473383],[-120.565168,38.508146],[-120.737147,38.546327],[-120.745867,38.54093],[-120.790877,38.493054],[-120.698191,38.473383]]]},"properties":{"id":"95629","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.892734,39.361871],[-121.645979,39.365828],[-121.79909,39.464563],[-121.870261,39.463984],[-121.890013,39.383864],[-121.892734,39.361871]]]},"properties":{"id":"95917","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.485283,38.093381],[-120.571644,38.125862],[-120.794049,38.165288],[-120.736415,38.053692],[-120.690846,38.047462],[-120.566991,37.899733],[-120.523796,37.949054],[-120.453965,38.038208],[-120.485283,38.093381]]]},"properties":{"id":"95222","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.850053,38.257254],[-120.889057,38.227188],[-120.891386,38.219605],[-120.860231,38.20675],[-120.850053,38.257254]]]},"properties":{"id":"95226","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.05489,37.908127],[-122.007152,37.882483],[-121.972347,37.861161],[-121.933485,37.85235],[-121.977171,37.914723],[-121.996991,37.942412],[-122.04688,37.934066],[-122.05489,37.908127]]]},"properties":{"id":"94598","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.065643,37.980839],[-122.101692,37.954978],[-122.087997,37.92526],[-122.052568,37.940471],[-122.051013,37.947977],[-122.065643,37.980839]]]},"properties":{"id":"94523","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.052568,37.940471],[-122.087997,37.92526],[-122.081405,37.897273],[-122.070614,37.896476],[-122.05489,37.908127],[-122.04688,37.934066],[-122.052568,37.940471]]]},"properties":{"id":"94597","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.358637,38.021338],[-121.377444,38.072744],[-121.503089,38.072586],[-121.570104,38.096798],[-121.580022,38.094414],[-121.572923,38.074239],[-121.372072,37.968358],[-121.368712,37.967449],[-121.341132,37.979434],[-121.358637,38.021338]]]},"properties":{"id":"95219","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.221421,37.984524],[-122.252417,38.012961],[-122.276059,38.04349],[-122.302178,38.017986],[-122.249797,37.993052],[-122.221421,37.984524]]]},"properties":{"id":"94547","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.320298,37.904443],[-122.316652,37.89964],[-122.314081,37.900047],[-122.319032,37.906025],[-122.320298,37.904443]]]},"properties":{"id":"94850","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.433925,36.574397],[-120.531695,36.720141],[-120.595501,36.604702],[-120.485307,36.531169],[-120.433925,36.574397]]],[[[-120.486462,36.734394],[-120.379137,36.625386],[-120.320493,36.705028],[-120.341065,36.732891],[-120.273649,36.774175],[-120.277536,36.768747],[-120.372628,36.788104],[-120.486462,36.734394]]]]},"properties":{"id":"93640","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.917057,36.582828],[-119.953621,36.546855],[-119.79339,36.47445],[-119.761621,36.489338],[-119.786065,36.553339],[-119.899011,36.582955],[-119.917057,36.582828]]]},"properties":{"id":"93609","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.729717,36.852152],[-119.655024,36.787611],[-119.622568,36.780662],[-119.421901,36.909123],[-119.467308,36.967592],[-119.544427,37.048208],[-119.549413,37.09402],[-119.730259,36.934337],[-119.729889,36.88142],[-119.729717,36.852152]]]},"properties":{"id":"93619","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.236331,37.203634],[-119.066905,37.004753],[-119.121526,36.902773],[-119.131131,36.87108],[-119.09576,36.857618],[-118.591086,37.136589],[-118.775014,37.463052],[-119.022363,37.585737],[-119.327013,37.29244],[-119.248158,37.211062],[-119.236331,37.203634]]]},"properties":{"id":"93634","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.772432,36.743168],[-119.805633,36.744274],[-119.772434,36.713051],[-119.772674,36.721354],[-119.772432,36.743168]]]},"properties":{"id":"93721","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.088295,36.657514],[-119.110094,36.725514],[-119.249282,36.824959],[-119.299003,36.723251],[-119.304635,36.718719],[-119.214393,36.659838],[-119.088295,36.657514]]]},"properties":{"id":"93675","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.05548,36.754098],[-119.110094,36.725514],[-119.088295,36.657514],[-119.014425,36.657353],[-118.984458,36.670587],[-118.982838,36.72762],[-118.982441,36.741646],[-118.983177,36.752621],[-119.05548,36.754098]]]},"properties":{"id":"93641","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.982838,36.72762],[-118.937992,36.741277],[-118.982441,36.741646],[-118.982838,36.72762]]],[[[-118.670375,36.791596],[-118.602087,36.790626],[-118.613893,36.833058],[-118.699871,36.836656],[-118.670375,36.791596]]]]},"properties":{"id":"93633","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.442784,39.637082],[-122.391531,39.581303],[-122.399106,39.649319],[-122.442784,39.637082]]],[[[-122.562324,39.799774],[-122.625546,39.711787],[-122.477822,39.701338],[-122.283505,39.611465],[-122.104205,39.623452],[-122.008387,39.708974],[-121.996739,39.751055],[-122.057905,39.822906],[-122.337648,39.813178],[-122.478638,39.799523],[-122.562324,39.799774]]]]},"properties":{"id":"95963","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.996739,39.751055],[-122.008387,39.708974],[-121.997493,39.667025],[-121.946615,39.725893],[-121.996739,39.751055]]]},"properties":{"id":"95951","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.202769,40.61448],[-124.312869,40.642357],[-124.400301,40.419662],[-124.199446,40.419622],[-124.19589,40.455921],[-124.151758,40.511112],[-124.202769,40.61448]]]},"properties":{"id":"95536","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.079619,40.339069],[-124.127419,40.201413],[-124.04279,40.20985],[-123.976195,40.260483],[-124.033728,40.313744],[-124.079619,40.339069]]]},"properties":{"id":"95545","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.400301,40.419662],[-124.176367,40.127521],[-124.119834,40.172734],[-124.127419,40.201413],[-124.079619,40.339069],[-124.199446,40.419622],[-124.400301,40.419662]]]},"properties":{"id":"95558","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.720795,33.201744],[-115.352445,33.152865],[-114.979415,33.022347],[-114.856134,33.219425],[-115.235077,33.428499],[-115.931822,33.426499],[-115.888232,33.394376],[-115.720795,33.201744]]]},"properties":{"id":"92257","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.457322,32.916501],[-115.697175,32.963132],[-115.869794,32.857807],[-115.774125,32.776482],[-115.715991,32.791063],[-115.685744,32.832925],[-115.448245,32.824888],[-115.457322,32.916501]]]},"properties":{"id":"92251","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.413689,32.708273],[-115.363022,32.674096],[-114.980946,32.70094],[-114.967334,32.756367],[-115.457322,32.916501],[-115.448245,32.824888],[-115.447986,32.752021],[-115.413689,32.708273]]]},"properties":{"id":"92250","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.551643,32.789161],[-114.552101,32.774518],[-114.534013,32.774682],[-114.534008,32.789177],[-114.551643,32.789161]]]},"properties":{"id":"92222","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.012512,35.968909],[-117.835075,36.10298],[-117.901814,36.353188],[-118.127601,36.280348],[-118.040205,36.032394],[-118.012512,35.968909]]]},"properties":{"id":"93549","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.667033,36.316556],[-117.556382,36.231454],[-117.545824,36.340027],[-117.667033,36.316556]]]},"properties":{"id":"93522","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.852132,35.325512],[-118.945395,34.986008],[-118.940947,34.972293],[-118.68522,35.063556],[-118.708018,35.089611],[-118.799448,35.32581],[-118.852132,35.325512]]]},"properties":{"id":"93203","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.916864,34.920532],[-117.772737,34.998755],[-117.895566,34.940003],[-117.916864,34.920532]]]},"properties":{"id":"93523","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.039076,35.18412],[-117.971015,35.058239],[-117.914543,35.140707],[-118.003548,35.184161],[-118.039076,35.18412]]]},"properties":{"id":"93505","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.448018,35.500528],[-119.331654,35.441942],[-119.145529,35.484981],[-119.246295,35.558341],[-119.448018,35.500528]]]},"properties":{"id":"93263","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-119.05555,35.743662],[-118.980684,35.790663],[-118.981935,35.790735],[-119.053437,35.790748],[-119.05555,35.743662]]],[[[-118.862228,35.738029],[-118.927458,35.666446],[-118.849405,35.668559],[-118.862228,35.738029]]]]},"properties":{"id":"93287","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.844961,39.139592],[-122.805531,39.099863],[-122.737005,39.02848],[-122.74533,39.068526],[-122.651074,39.142142],[-122.758873,39.218851],[-122.844961,39.139592]]]},"properties":{"id":"95458","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.220268,34.20503],[-118.239973,34.210082],[-118.271548,34.20801],[-118.233064,34.165664],[-118.207998,34.188166],[-118.220268,34.20503]]]},"properties":{"id":"91208","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.448717,34.189684],[-118.422521,34.193937],[-118.426702,34.207345],[-118.466122,34.215094],[-118.466187,34.193917],[-118.448717,34.189684]]]},"properties":{"id":"91405","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.502667,34.221208],[-118.518596,34.208401],[-118.518481,34.186613],[-118.497798,34.178794],[-118.469438,34.162771],[-118.469485,34.164823],[-118.466187,34.193917],[-118.466122,34.215094],[-118.468737,34.221411],[-118.502667,34.221208]]]},"properties":{"id":"91406","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.122915,34.069775],[-118.165293,34.062298],[-118.169256,34.061489],[-118.169273,34.050737],[-118.14416,34.033463],[-118.130099,34.033578],[-118.122915,34.069775]]]},"properties":{"id":"91754","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.537358,34.130637],[-118.527286,34.180246],[-118.562222,34.184061],[-118.562201,34.173325],[-118.564017,34.131147],[-118.537358,34.130637]]]},"properties":{"id":"91356","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.690753,34.818095],[-118.660972,34.783174],[-118.329637,34.662693],[-118.16555,34.645748],[-118.170998,34.791439],[-118.660886,34.818174],[-118.690753,34.818095]]]},"properties":{"id":"93536","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.588481,34.208309],[-118.614688,34.208265],[-118.614625,34.186451],[-118.588445,34.188346],[-118.588481,34.208309]]]},"properties":{"id":"91303","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.325341,34.153514],[-118.33826,34.189865],[-118.350369,34.206672],[-118.370312,34.201197],[-118.361049,34.193974],[-118.358803,34.179077],[-118.351812,34.158271],[-118.345166,34.142747],[-118.325341,34.153514]]]},"properties":{"id":"91505","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.832818,34.099141],[-118.704017,34.080931],[-118.710884,34.168526],[-118.744934,34.168005],[-118.788749,34.168213],[-118.792895,34.146059],[-118.832818,34.099141]]]},"properties":{"id":"91301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.860951,34.17612],[-118.792895,34.146059],[-118.788749,34.168213],[-118.770507,34.202816],[-118.769935,34.212248],[-118.828464,34.249932],[-118.840025,34.241557],[-118.860951,34.17612]]]},"properties":{"id":"91362","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.060871,34.084049],[-118.567251,34.038401],[-118.577505,34.070578],[-118.648604,34.078506],[-118.704017,34.080931],[-118.832818,34.099141],[-118.97081,34.139827],[-119.00381,34.148502],[-119.036993,34.13047],[-119.06796,34.093202],[-119.060871,34.084049]]]},"properties":{"id":"90265","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.221037,33.90336],[-118.220399,33.846572],[-118.206591,33.846567],[-118.187183,33.885404],[-118.186257,33.904797],[-118.215669,33.912452],[-118.221037,33.90336]]]},"properties":{"id":"90221","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.142734,33.902455],[-118.109848,33.909815],[-118.111518,33.920123],[-118.161267,33.943933],[-118.156237,33.917044],[-118.142734,33.902455]]]},"properties":{"id":"90242","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.916851,34.050287],[-117.916563,34.084655],[-117.941969,34.085893],[-117.965925,34.062562],[-117.963791,34.052635],[-117.916851,34.050287]]]},"properties":{"id":"91790","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.925363,34.106965],[-117.933649,34.129383],[-117.99718,34.117683],[-118.003747,34.117366],[-118.004542,34.111102],[-118.007342,34.105566],[-118.007111,34.059795],[-117.965925,34.062562],[-117.941969,34.085893],[-117.925363,34.106965]]]},"properties":{"id":"91706","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.756134,34.088734],[-117.75019,34.058655],[-117.719481,34.059731],[-117.711067,34.079536],[-117.748541,34.1126],[-117.756134,34.088734]]]},"properties":{"id":"91767","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.820296,34.126829],[-117.881342,34.130944],[-117.881448,34.114055],[-117.872656,34.106135],[-117.837641,34.106611],[-117.820296,34.126829]]]},"properties":{"id":"91740","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.36985,33.963824],[-118.370037,33.94542],[-118.343915,33.945968],[-118.343928,33.952713],[-118.34405,33.97116],[-118.36985,33.963824]]]},"properties":{"id":"90301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.353589,33.858295],[-118.328682,33.847274],[-118.30915,33.858348],[-118.309123,33.87281],[-118.313476,33.887061],[-118.326488,33.882821],[-118.331254,33.881368],[-118.352347,33.874215],[-118.353589,33.858295]]]},"properties":{"id":"90504","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167664,33.768428],[-118.1227,33.775322],[-118.167646,33.775346],[-118.167646,33.774919],[-118.167664,33.768428]]]},"properties":{"id":"90814","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.648604,34.078506],[-118.602128,34.139446],[-118.64127,34.157924],[-118.668153,34.17675],[-118.710884,34.168526],[-118.704017,34.080931],[-118.648604,34.078506]]]},"properties":{"id":"91302","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.445241,34.073811],[-118.447501,34.066281],[-118.439176,34.067505],[-118.439421,34.078246],[-118.445241,34.073811]]]},"properties":{"id":"90095","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.246484,34.071778],[-118.238003,34.069003],[-118.236507,34.07471],[-118.245418,34.075124],[-118.246484,34.071778]]]},"properties":{"id":"90090","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.308999,34.039939],[-118.300349,34.039969],[-118.284102,34.039855],[-118.274313,34.051362],[-118.275461,34.05188],[-118.284238,34.055845],[-118.309064,34.055846],[-118.308999,34.039939]]]},"properties":{"id":"90006","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.217322,34.11758],[-118.227751,34.135601],[-118.253306,34.124322],[-118.254135,34.11886],[-118.227601,34.08304],[-118.227235,34.081218],[-118.200556,34.101324],[-118.217322,34.11758]]]},"properties":{"id":"90065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.253306,34.124322],[-118.227751,34.135601],[-118.228774,34.146032],[-118.254946,34.146273],[-118.253306,34.124322]]]},"properties":{"id":"91205","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.276332,34.154721],[-118.275401,34.153166],[-118.272095,34.146749],[-118.256379,34.146453],[-118.25506,34.146272],[-118.254946,34.146273],[-118.255148,34.158701],[-118.255147,34.158783],[-118.276332,34.154721]]]},"properties":{"id":"91203","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.468737,34.221411],[-118.451878,34.249935],[-118.472121,34.257323],[-118.502369,34.253888],[-118.502667,34.221208],[-118.468737,34.221411]]]},"properties":{"id":"91343","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300165,34.018177],[-118.300193,33.989109],[-118.283204,33.989139],[-118.273928,33.989191],[-118.274009,34.013057],[-118.300165,34.018177]]]},"properties":{"id":"90037","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167643,33.850934],[-118.154103,33.827552],[-118.125075,33.847055],[-118.12514,33.869035],[-118.146647,33.869003],[-118.167643,33.850934]]]},"properties":{"id":"90712","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.041727,33.781167],[-118.093201,33.786015],[-118.097404,33.77419],[-118.118946,33.740577],[-118.100815,33.726902],[-118.083528,33.728969],[-118.0419,33.74439],[-118.041926,33.75026],[-118.042931,33.775032],[-118.041727,33.781167]]]},"properties":{"id":"90740","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.370497,34.04141],[-118.400538,34.045875],[-118.429918,34.026861],[-118.418235,34.012109],[-118.41692,34.012887],[-118.369577,34.03508],[-118.369801,34.040019],[-118.370497,34.04141]]]},"properties":{"id":"90034","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.409086,33.981247],[-118.432009,33.974636],[-118.425965,33.96588],[-118.397635,33.982093],[-118.409086,33.981247]]]},"properties":{"id":"90094","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.255897,34.056087],[-118.253932,34.050176],[-118.250157,34.052347],[-118.255897,34.056087]]]},"properties":{"id":"90071","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.859977,34.470912],[-117.655235,34.397222],[-117.660052,34.498629],[-117.659992,34.557782],[-117.667034,34.558008],[-117.889527,34.557965],[-117.895552,34.557944],[-117.859977,34.470912]]]},"properties":{"id":"93544","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.540943,34.298794],[-118.560076,34.25726],[-118.536096,34.257266],[-118.527426,34.257266],[-118.523323,34.257339],[-118.540943,34.298794]]]},"properties":{"id":"91326","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.378701,33.901905],[-118.430009,33.902813],[-118.414124,33.875214],[-118.391371,33.872812],[-118.378721,33.894646],[-118.378703,33.901794],[-118.378701,33.901905]]]},"properties":{"id":"90266","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.072859,34.030958],[-118.083622,34.020136],[-118.054653,34.019901],[-118.021641,34.041689],[-118.030543,34.063318],[-118.068922,34.062835],[-118.072859,34.030958]]]},"properties":{"id":"91733","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.540474,37.963827],[-122.509538,37.951875],[-122.501739,37.947825],[-122.483654,37.944928],[-122.490108,37.938358],[-122.502151,37.943858],[-122.513577,37.943869],[-122.5029,37.941778],[-122.484025,37.935861],[-122.485361,38.01716],[-122.557589,37.993322],[-122.550898,37.972319],[-122.540474,37.963827]]]},"properties":{"id":"94901","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.539618,37.922123],[-122.502985,37.909325],[-122.471143,37.920344],[-122.484025,37.935861],[-122.5029,37.941778],[-122.519248,37.939362],[-122.539618,37.922123]]]},"properties":{"id":"94925","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.550898,37.972319],[-122.557589,37.993322],[-122.61277,38.022221],[-122.580449,37.969432],[-122.556889,37.96227],[-122.552986,37.965543],[-122.550898,37.972319]]]},"properties":{"id":"94960","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.960979,38.124925],[-122.862251,38.112335],[-122.937912,38.201372],[-122.978001,38.240167],[-122.960979,38.124925]]]},"properties":{"id":"94937","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.580449,37.969432],[-122.61277,38.022221],[-122.615592,38.020222],[-122.616562,38.019646],[-122.634249,37.994647],[-122.655949,37.938335],[-122.612657,37.910475],[-122.595243,37.925665],[-122.58076,37.96581],[-122.580449,37.969432]]]},"properties":{"id":"94930","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.912817,38.232749],[-122.942723,38.248776],[-122.945073,38.250755],[-122.967349,38.249643],[-122.972127,38.249664],[-122.978001,38.240167],[-122.937912,38.201372],[-122.912817,38.232749]]]},"properties":{"id":"94971","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.919785,38.317222],[-122.971077,38.309942],[-123.002787,38.29571],[-122.972127,38.249664],[-122.967349,38.249643],[-122.973905,38.26606],[-122.945073,38.250755],[-122.942723,38.248776],[-122.919785,38.317222]]]},"properties":{"id":"94972","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.764178,39.905991],[-123.631406,39.754662],[-123.541967,39.760166],[-123.670114,39.909894],[-123.72514,39.935014],[-123.764178,39.905991]]]},"properties":{"id":"95585","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.787054,39.360209],[-123.78078,39.36509],[-123.826295,39.376885],[-123.830285,39.367617],[-123.787054,39.360209]]]},"properties":{"id":"95420","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.997354,37.013834],[-120.774306,36.857352],[-120.69163,36.924207],[-120.766552,37.186143],[-120.827435,37.279864],[-120.930182,37.310029],[-120.943852,37.29178],[-120.997354,37.013834]]]},"properties":{"id":"93635","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.449499,37.401094],[-120.437468,37.461502],[-120.538869,37.468471],[-120.549311,37.382354],[-120.542866,37.321497],[-120.510042,37.307875],[-120.449499,37.401094]]]},"properties":{"id":"95348","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.721721,37.448273],[-120.831432,37.433097],[-120.805765,37.387557],[-120.731439,37.405701],[-120.721721,37.448273]]]},"properties":{"id":"95315","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.641635,41.184014],[-120.620688,41.184469],[-120.208865,41.184096],[-120.207412,41.344431],[-120.650852,41.226349],[-120.641635,41.184014]]]},"properties":{"id":"96116","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.362028,38.436744],[-119.457868,38.604668],[-119.516766,38.602247],[-119.540843,38.641627],[-119.54971,38.338831],[-119.362028,38.436744]]]},"properties":{"id":"96107","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.936626,36.545814],[-121.928953,36.558926],[-121.933901,36.5569],[-121.936626,36.545814]]]},"properties":{"id":"93921","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.406993,36.656035],[-121.498118,36.514278],[-121.32353,36.602671],[-121.319415,36.606174],[-121.406993,36.656035]]]},"properties":{"id":"93925","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.609699,36.754754],[-121.652524,36.686866],[-121.648425,36.684703],[-121.598243,36.719528],[-121.609699,36.754754]]]},"properties":{"id":"93906","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.597475,36.900496],[-121.62856,36.828697],[-121.5971,36.836849],[-121.597475,36.900496]]]},"properties":{"id":"95004","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.769676,36.548424],[-121.785869,36.376859],[-121.549561,36.233382],[-121.497921,36.413597],[-121.56372,36.496799],[-121.758368,36.554865],[-121.769676,36.548424]]]},"properties":{"id":"93924","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.730075,35.755765],[-120.41325,35.789979],[-120.255648,35.852572],[-120.315068,35.907186],[-120.477816,36.003283],[-120.790518,35.923377],[-120.731594,35.791118],[-120.730075,35.755765]]]},"properties":{"id":"93451","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.919932,33.945958],[-117.924336,33.924613],[-117.863877,33.907404],[-117.855,33.9063],[-117.841825,33.946839],[-117.868374,33.945936],[-117.919932,33.945958]]]},"properties":{"id":"92821","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.077412,33.8104],[-118.093201,33.786015],[-118.041727,33.781167],[-118.03711,33.792097],[-118.063162,33.81961],[-118.077412,33.8104]]]},"properties":{"id":"90720","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.011085,33.791998],[-118.03711,33.792097],[-118.041727,33.781167],[-118.042931,33.775032],[-118.007408,33.77433],[-118.011085,33.791998]]]},"properties":{"id":"92845","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.843018,33.846896],[-117.833883,33.839025],[-117.74374,33.824201],[-117.734423,33.840761],[-117.744652,33.870569],[-117.778975,33.864726],[-117.817732,33.869269],[-117.837065,33.864745],[-117.843018,33.846896]]]},"properties":{"id":"92807","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.95755,33.906465],[-117.942,33.88123],[-117.924447,33.883266],[-117.876891,33.89636],[-117.863877,33.907404],[-117.924336,33.924613],[-117.95755,33.906465]]]},"properties":{"id":"92835","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.8548,33.73361],[-117.843893,33.739588],[-117.83729,33.74204],[-117.855929,33.759952],[-117.876434,33.75577],[-117.87648,33.7345],[-117.8548,33.73361]]]},"properties":{"id":"92701","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.953665,33.64321],[-117.960457,33.625511],[-117.929442,33.601759],[-117.90269,33.608521],[-117.909447,33.627675],[-117.953665,33.64321]]]},"properties":{"id":"92663","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.673749,33.870831],[-117.735464,33.913251],[-117.778975,33.864726],[-117.744652,33.870569],[-117.711622,33.867013],[-117.672838,33.86991],[-117.673749,33.870831]]]},"properties":{"id":"92887","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.798847,33.813123],[-117.798424,33.831105],[-117.82418,33.823342],[-117.81533,33.802116],[-117.798847,33.813123]]]},"properties":{"id":"92861","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.90269,33.608521],[-117.88219,33.600422],[-117.889794,33.609207],[-117.90269,33.608521]]]},"properties":{"id":"92662","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.030095,39.036433],[-121.017831,38.969809],[-120.993931,39.013748],[-121.021776,39.039165],[-121.030095,39.036433]]]},"properties":{"id":"95722","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.217458,38.862613],[-121.137766,38.836559],[-121.203291,38.874955],[-121.217458,38.862613]]]},"properties":{"id":"95663","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.416694,38.771557],[-121.494358,38.751231],[-121.540273,38.736405],[-121.540133,38.700054],[-121.401483,38.70639],[-121.397388,38.728906],[-121.416694,38.771557]]]},"properties":{"id":"95626","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.943511,40.103721],[-120.984105,40.041915],[-120.97162,40.041759],[-120.85797,40.038725],[-120.89945,40.107089],[-120.943511,40.103721]]]},"properties":{"id":"95934","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.015806,39.60532],[-121.124932,39.563078],[-121.129142,39.541638],[-121.015806,39.60532]]]},"properties":{"id":"95981","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.372754,39.864461],[-120.485563,39.756278],[-120.487992,39.706581],[-120.364749,39.708495],[-120.239737,39.802029],[-120.228171,39.88351],[-120.372754,39.864461]]]},"properties":{"id":"96129","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.009494,33.857049],[-117.003606,33.833634],[-116.973389,33.772952],[-116.958689,33.758512],[-116.884762,33.761671],[-117.009494,33.857049]]]},"properties":{"id":"92583","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.301228,33.803855],[-116.396713,33.810363],[-116.388418,33.772574],[-116.356335,33.728883],[-116.303886,33.72901],[-116.303864,33.736466],[-116.283244,33.794816],[-116.301228,33.803855]]]},"properties":{"id":"92211","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.565233,33.882454],[-117.662117,33.857499],[-117.63665,33.829975],[-117.581956,33.770685],[-117.567929,33.852487],[-117.565233,33.882454]]]},"properties":{"id":"92882","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.180335,33.706739],[-117.226976,33.703446],[-117.228683,33.685023],[-117.24034,33.654337],[-117.209711,33.608973],[-117.171013,33.627039],[-117.136739,33.626878],[-117.145198,33.702141],[-117.180335,33.706739]]]},"properties":{"id":"92584","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.376397,33.969054],[-117.318274,33.927107],[-117.288592,33.928237],[-117.27893,33.942724],[-117.311485,34.008519],[-117.305605,34.019292],[-117.336161,34.019378],[-117.339737,34.019426],[-117.376397,33.969054]]]},"properties":{"id":"92507","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.343672,38.35772],[-121.362104,38.376308],[-121.483916,38.408739],[-121.501371,38.369421],[-121.498306,38.276785],[-121.448142,38.255008],[-121.447774,38.2551],[-121.343672,38.35772]]]},"properties":{"id":"95757","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.505073,38.481269],[-121.525566,38.523106],[-121.558069,38.501876],[-121.504139,38.466563],[-121.505073,38.481269]]]},"properties":{"id":"95831","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.475265,38.510328],[-121.466354,38.481526],[-121.465296,38.459788],[-121.411296,38.458132],[-121.435862,38.510507],[-121.475265,38.510328]]]},"properties":{"id":"95823","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.211127,38.4547],[-121.343617,38.357622],[-121.220417,38.33588],[-121.048772,38.409808],[-121.211127,38.4547]]]},"properties":{"id":"95693","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.359211,38.57175],[-121.334934,38.529004],[-121.297884,38.519113],[-121.292451,38.535831],[-121.298375,38.571271],[-121.328421,38.599925],[-121.346287,38.577748],[-121.359211,38.57175]]]},"properties":{"id":"95827","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.540012,38.685505],[-121.565586,38.685553],[-121.56012,38.656339],[-121.474322,38.655347],[-121.477105,38.669996],[-121.540012,38.685505]]]},"properties":{"id":"95835","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.67422,35.27182],[-116.70098,35.273568],[-116.70055,35.2449],[-116.67283,35.25954],[-116.67422,35.27182]]]},"properties":{"id":"92310","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.693545,34.121627],[-117.623451,34.121565],[-117.620136,34.1216],[-117.634222,34.164956],[-117.655967,34.164615],[-117.677706,34.165173],[-117.693545,34.121627]]]},"properties":{"id":"91784","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.424893,34.067494],[-117.40522,34.092216],[-117.405293,34.106735],[-117.514099,34.106357],[-117.523822,34.089848],[-117.524027,34.07718],[-117.524091,34.067692],[-117.424893,34.067494]]]},"properties":{"id":"92335","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.285028,34.164901],[-117.312704,34.223276],[-117.321878,34.223407],[-117.465176,34.304597],[-117.510212,34.299769],[-117.465952,34.220108],[-117.456668,34.208074],[-117.429453,34.187496],[-117.347943,34.136018],[-117.319111,34.144497],[-117.285028,34.164901]]]},"properties":{"id":"92407","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.27804,34.051901],[-117.243636,34.038705],[-117.243693,34.063053],[-117.243642,34.071135],[-117.27804,34.051901]]]},"properties":{"id":"92354","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.460306,34.075951],[-116.51116,34.118355],[-116.563272,34.118286],[-116.717848,34.122175],[-116.719663,34.033824],[-116.716364,34.033793],[-116.591476,34.032618],[-116.460306,34.075951]]]},"properties":{"id":"92256","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.040705,32.783359],[-117.062761,32.821798],[-117.101482,32.79035],[-117.101038,32.779036],[-117.048602,32.774257],[-117.040705,32.783359]]]},"properties":{"id":"92120","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.241325,32.932349],[-117.17741,32.926873],[-117.166861,32.93243],[-117.146312,32.976272],[-117.172053,32.973859],[-117.208479,32.978154],[-117.253304,32.979939],[-117.241325,32.932349]]]},"properties":{"id":"92130","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.100651,32.590654],[-117.005248,32.592388],[-117.016251,32.623535],[-117.097071,32.613765],[-117.100651,32.590654]]]},"properties":{"id":"91911","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.069907,33.059509],[-117.104917,33.127044],[-117.113684,33.128589],[-117.123407,33.132885],[-117.203704,33.087699],[-117.199861,33.080563],[-117.187061,33.061086],[-117.131386,33.042784],[-117.069907,33.059509]]]},"properties":{"id":"92029","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.518865,32.863898],[-116.565225,32.865405],[-116.595948,32.82269],[-116.599921,32.797749],[-116.560086,32.823243],[-116.518865,32.863898]]]},"properties":{"id":"91931","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-116.470006,32.718691],[-116.360819,32.705156],[-116.365909,32.770965],[-116.41198,32.815089],[-116.417715,32.815041],[-116.470006,32.718691]]],[[[-116.518865,32.863898],[-116.560086,32.823243],[-116.567757,32.743678],[-116.479466,32.733326],[-116.479648,32.852309],[-116.492888,32.871757],[-116.518865,32.863898]]]]},"properties":{"id":"91962","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.550753,32.657786],[-116.673167,32.668695],[-116.643345,32.59786],[-116.592128,32.579271],[-116.53535,32.584052],[-116.550753,32.657786]]]},"properties":{"id":"91963","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.397812,37.784667],[-122.40509,37.764628],[-122.403784,37.749433],[-122.380014,37.753358],[-122.381681,37.783529],[-122.385181,37.791528],[-122.397812,37.784667]]]},"properties":{"id":"94107","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.404412,37.796302],[-122.398482,37.807228],[-122.421076,37.812889],[-122.414826,37.794988],[-122.404412,37.796302]]]},"properties":{"id":"94133","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.453293,37.757959],[-121.416381,37.710437],[-121.410225,37.761501],[-121.453293,37.757959]]]},"properties":{"id":"95376","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.358637,38.021338],[-121.341132,37.979434],[-121.318562,37.979854],[-121.309881,37.984381],[-121.29135,37.993217],[-121.323174,38.02107],[-121.358637,38.021338]]]},"properties":{"id":"95207","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.29135,37.993217],[-121.309881,37.984381],[-121.306682,37.979842],[-121.318562,37.979854],[-121.341132,37.979434],[-121.368712,37.967449],[-121.298755,37.965621],[-121.286611,37.968739],[-121.281607,37.994453],[-121.29135,37.993217]]]},"properties":{"id":"95204","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.258171,37.653495],[-121.330051,37.59383],[-121.20598,37.580145],[-121.179052,37.598313],[-121.258171,37.653495]]]},"properties":{"id":"95385","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.298755,37.965621],[-121.277476,37.951724],[-121.286611,37.968739],[-121.298755,37.965621]]]},"properties":{"id":"95202","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.560386,37.858316],[-121.522272,37.939737],[-121.581378,37.98276],[-121.565594,37.918193],[-121.560386,37.858316]]]},"properties":{"id":"95234","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.739825,35.411562],[-120.604316,35.446008],[-120.607261,35.484963],[-120.767909,35.483152],[-120.801785,35.464437],[-120.739825,35.411562]]]},"properties":{"id":"93422","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.567296,34.945866],[-119.69399,34.949748],[-119.668323,34.827442],[-119.556358,34.820153],[-119.567296,34.945866]]]},"properties":{"id":"93254","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229626,37.482088],[-122.224967,37.443965],[-122.211847,37.469246],[-122.229626,37.482088]]]},"properties":{"id":"94061","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229133,37.424295],[-122.209011,37.467173],[-122.211847,37.469246],[-122.224967,37.443965],[-122.229133,37.424295]]]},"properties":{"id":"94027","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.464052,37.638872],[-122.403661,37.635538],[-122.391719,37.643952],[-122.387308,37.671774],[-122.429383,37.682133],[-122.459982,37.666647],[-122.471883,37.64917],[-122.464052,37.638872]]]},"properties":{"id":"94080","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.403661,37.635538],[-122.464052,37.638872],[-122.451598,37.618134],[-122.424608,37.600336],[-122.400163,37.612646],[-122.403661,37.635538]]]},"properties":{"id":"94066","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.517347,37.586797],[-122.459786,37.566037],[-122.451598,37.618134],[-122.464052,37.638872],[-122.471883,37.64917],[-122.495093,37.664368],[-122.517347,37.586797]]]},"properties":{"id":"94044","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.429383,37.682133],[-122.40529,37.708264],[-122.428294,37.70841],[-122.468939,37.708232],[-122.469236,37.708232],[-122.459982,37.666647],[-122.429383,37.682133]]]},"properties":{"id":"94014","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.253964,34.686532],[-120.331358,34.714095],[-120.435583,34.722264],[-120.477197,34.743464],[-120.483954,34.726972],[-120.417229,34.452265],[-120.253434,34.521261],[-120.253964,34.686532]]]},"properties":{"id":"93436","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.688786,34.449074],[-119.659066,34.416442],[-119.612172,34.420995],[-119.584472,34.428691],[-119.596274,34.475796],[-119.643568,34.48709],[-119.688786,34.449074]]]},"properties":{"id":"93108","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.477197,34.743464],[-120.600255,34.770757],[-120.59694,34.704943],[-120.483954,34.726972],[-120.477197,34.743464]]]},"properties":{"id":"93437","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.809076,37.069301],[-121.749039,37.060624],[-121.621875,37.058721],[-121.62818,37.10839],[-121.549279,37.118158],[-121.48106,37.118201],[-121.30833,37.168029],[-121.454138,37.273979],[-121.566553,37.296239],[-121.620026,37.290336],[-121.677359,37.252336],[-121.747404,37.229619],[-121.758672,37.217763],[-121.770862,37.217377],[-121.778225,37.208188],[-121.817205,37.142667],[-121.809076,37.069301]]]},"properties":{"id":"95037","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.990655,37.269106],[-121.94861,37.25786],[-121.922455,37.282627],[-121.931669,37.297222],[-121.949918,37.295545],[-121.96452,37.301166],[-121.990655,37.269106]]]},"properties":{"id":"95008","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.967936,37.418922],[-121.937901,37.380569],[-121.915198,37.39611],[-121.925548,37.454389],[-121.974706,37.46074],[-121.967936,37.418922]]]},"properties":{"id":"95134","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.770862,37.217377],[-121.758672,37.217763],[-121.751149,37.226434],[-121.768706,37.235438],[-121.770862,37.217377]]]},"properties":{"id":"95139","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.013275,36.988546],[-121.971059,36.983241],[-121.946418,37.0685],[-121.953521,37.076235],[-122.005264,37.042893],[-122.013275,36.988546]]]},"properties":{"id":"95065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.506084,40.74653],[-121.322279,40.621899],[-121.320992,40.826202],[-121.402251,40.857062],[-121.567154,40.884467],[-121.576342,40.863753],[-121.506084,40.74653]]]},"properties":{"id":"96040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.556666,40.976608],[-121.718263,41.183387],[-121.807389,41.183285],[-121.840666,40.958579],[-121.766084,40.869723],[-121.576342,40.863753],[-121.567154,40.884467],[-121.556666,40.976608]]]},"properties":{"id":"96013","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.23455,40.614912],[-122.258214,40.560991],[-122.267271,40.489465],[-122.220432,40.507335],[-122.178332,40.579964],[-122.103329,40.675943],[-122.23455,40.614912]]]},"properties":{"id":"96073","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.103427,40.919893],[-122.058561,40.772268],[-121.968608,40.75543],[-121.877187,40.756939],[-121.845324,40.81243],[-121.990868,40.959029],[-122.103427,40.919893]]]},"properties":{"id":"96084","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.718263,41.183387],[-121.556666,40.976608],[-121.402251,40.857062],[-121.320992,40.826202],[-121.320178,40.901539],[-121.620778,41.183576],[-121.644635,41.183452],[-121.718263,41.183387]]]},"properties":{"id":"96028","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.027774,39.451992],[-120.937938,39.45168],[-120.833116,39.526421],[-120.904345,39.563778],[-121.011567,39.516456],[-121.0344,39.514436],[-121.027774,39.451992]]]},"properties":{"id":"95944","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.199155,41.803248],[-122.178378,41.751849],[-122.082646,41.610111],[-121.702482,41.869257],[-122.199155,41.803248]]]},"properties":{"id":"96058","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.503224,41.577487],[-122.575356,41.563283],[-122.640838,41.557357],[-122.659702,41.472422],[-122.698076,41.276755],[-122.572326,41.368025],[-122.479311,41.538494],[-122.503224,41.577487]]]},"properties":{"id":"96034","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.503224,41.577487],[-122.479311,41.538494],[-122.178378,41.751849],[-122.199155,41.803248],[-122.186265,42.007456],[-122.284858,42.007698],[-122.573983,41.814305],[-122.535227,41.646193],[-122.503224,41.577487]]]},"properties":{"id":"96064","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.199155,41.803248],[-121.702482,41.869257],[-121.651605,41.90229],[-121.59585,41.998953],[-122.186265,42.007456],[-122.199155,41.803248]]]},"properties":{"id":"96023","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.284858,42.007698],[-122.661394,42.00476],[-122.616173,41.883195],[-122.573983,41.814305],[-122.284858,42.007698]]]},"properties":{"id":"96044","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.160286,41.696068],[-122.876185,41.747159],[-123.151988,41.829165],[-123.160286,41.696068]]]},"properties":{"id":"96085","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.896863,38.366164],[-121.917203,38.366119],[-121.923047,38.35131],[-121.908653,38.348411],[-121.896852,38.364768],[-121.896863,38.366164]]]},"properties":{"id":"95625","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.793726,38.502624],[-122.786482,38.540333],[-122.858644,38.502387],[-122.842372,38.484576],[-122.789028,38.472766],[-122.788291,38.477041],[-122.793726,38.502624]]]},"properties":{"id":"95492","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.685803,38.3722],[-122.74128,38.352148],[-122.666688,38.314308],[-122.66686,38.338581],[-122.685803,38.3722]]]},"properties":{"id":"94928","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.510492,38.467209],[-122.600663,38.402487],[-122.565363,38.38672],[-122.497475,38.424306],[-122.479973,38.446391],[-122.510492,38.467209]]]},"properties":{"id":"95452","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.20598,37.580145],[-121.465589,37.435929],[-121.412549,37.389435],[-121.138182,37.39765],[-121.08056,37.49382],[-121.179052,37.598313],[-121.20598,37.580145]]]},"properties":{"id":"95363","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.907336,37.547541],[-120.901848,37.56564],[-120.926838,37.60931],[-120.977786,37.609273],[-121.012614,37.507665],[-120.985552,37.507479],[-120.907336,37.547541]]]},"properties":{"id":"95307","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.976071,37.688884],[-120.974553,37.648365],[-120.939067,37.654242],[-120.976175,37.696903],[-120.976071,37.688884]]]},"properties":{"id":"95355","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.110248,37.730128],[-121.054023,37.682917],[-121.053657,37.683127],[-121.094848,37.733328],[-121.110248,37.730128]]]},"properties":{"id":"95368","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.670772,38.964872],[-121.612793,38.885887],[-121.57703,38.91967],[-121.597676,39.127792],[-121.618132,39.182237],[-121.635161,39.181946],[-121.670772,38.964872]]]},"properties":{"id":"95991","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.768911,39.113217],[-121.670772,38.964872],[-121.635161,39.181946],[-121.728635,39.167027],[-121.768911,39.113217]]]},"properties":{"id":"95993","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.11927,40.028545],[-122.144356,40.011556],[-122.117411,40.017714],[-122.11927,40.028545]]]},"properties":{"id":"96090","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.474607,36.269025],[-119.287681,36.27077],[-119.288903,36.326903],[-119.475364,36.327597],[-119.474607,36.269025]]]},"properties":{"id":"93277","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.286615,36.460174],[-119.288903,36.326903],[-119.287681,36.27077],[-119.181416,36.254013],[-119.207238,36.290549],[-119.215885,36.319636],[-119.198175,36.312224],[-119.134627,36.36028],[-119.17863,36.5002],[-119.240831,36.507008],[-119.286615,36.460174]],[[-119.225064,36.370792],[-119.224452,36.399472],[-119.206604,36.399409],[-119.225064,36.370792]]]},"properties":{"id":"93292","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.062308,36.368779],[-119.059487,36.577421],[-119.17863,36.5002],[-119.134627,36.36028],[-119.062308,36.368779]]]},"properties":{"id":"93286","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.991149,35.90637],[-119.125947,35.891803],[-119.107825,35.858589],[-119.053437,35.790748],[-118.981935,35.790735],[-118.991149,35.90637]]]},"properties":{"id":"93218","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.589178,35.789743],[-118.593953,35.841994],[-118.732385,35.861842],[-118.75714,35.790164],[-118.589178,35.789743]]]},"properties":{"id":"93260","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.475471,36.065387],[-119.375519,36.007593],[-119.214629,36.015041],[-119.196722,36.080166],[-119.475471,36.065387]]]},"properties":{"id":"93272","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.340592,36.547309],[-119.330735,36.540147],[-119.331488,36.552446],[-119.340592,36.547309]]]},"properties":{"id":"93666","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.022483,38.150336],[-119.598421,38.181697],[-119.612994,38.25947],[-119.639205,38.32688],[-119.652476,38.336117],[-119.747655,38.312671],[-119.919874,38.32723],[-120.016559,38.210007],[-120.037704,38.170099],[-120.022483,38.150336]]]},"properties":{"id":"95364","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.699613,34.419733],[-118.770257,34.56321],[-118.87595,34.470803],[-118.785719,34.409956],[-118.699613,34.419733]]]},"properties":{"id":"93040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.990735,34.422505],[-119.174243,34.391774],[-119.165914,34.300326],[-119.138979,34.276671],[-119.133719,34.272878],[-118.978397,34.329717],[-118.975167,34.422487],[-118.990735,34.422505]]]},"properties":{"id":"93060","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.828464,34.249932],[-118.769935,34.212248],[-118.719319,34.226449],[-118.715495,34.262109],[-118.719659,34.31922],[-118.811858,34.290852],[-118.828464,34.249932]]]},"properties":{"id":"93065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.788749,34.168213],[-118.744934,34.168005],[-118.770507,34.202816],[-118.788749,34.168213]]]},"properties":{"id":"91377","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.805295,38.734581],[-121.818683,38.735443],[-121.806934,38.726654],[-121.805295,38.734581]]]},"properties":{"id":"95697","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.273265,37.873325],[-122.267167,37.860342],[-122.245269,37.872799],[-122.266329,37.874188],[-122.273265,37.873325]]]},"properties":{"id":"94704","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.041348,37.680986],[-122.012226,37.632891],[-122.013906,37.631621],[-121.951652,37.597529],[-121.932767,37.591732],[-121.963473,37.672006],[-121.977662,37.698542],[-121.97247,37.728528],[-122.004809,37.770571],[-122.106302,37.802194],[-122.077715,37.748209],[-122.059972,37.69359],[-122.041348,37.680986]]]},"properties":{"id":"94552","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.130142,37.690901],[-122.111838,37.688721],[-122.104167,37.695511],[-122.122274,37.727313],[-122.159817,37.705648],[-122.130142,37.690901]]]},"properties":{"id":"94578","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.819614,37.674599],[-121.913669,37.632732],[-121.804003,37.627936],[-121.819614,37.674599]]]},"properties":{"id":"94566","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.932146,37.573315],[-121.861923,37.503102],[-121.770614,37.598469],[-121.804003,37.627936],[-121.913669,37.632732],[-121.963473,37.672006],[-121.932767,37.591732],[-121.932146,37.573315]]]},"properties":{"id":"94586","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.165823,37.753588],[-122.201813,37.713091],[-122.148725,37.740859],[-122.165823,37.753588]]]},"properties":{"id":"94603","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.274296,37.817431],[-122.259574,37.798552],[-122.249578,37.807558],[-122.260751,37.813733],[-122.267861,37.816237],[-122.273752,37.819135],[-122.274296,37.817431]]]},"properties":{"id":"94612","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.288053,37.899312],[-122.30618,37.898225],[-122.309339,37.89786],[-122.295896,37.880417],[-122.283139,37.882063],[-122.288053,37.899312]]]},"properties":{"id":"94706","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.819757,38.397314],[-120.850053,38.257254],[-120.750046,38.297681],[-120.631047,38.340741],[-120.702125,38.40971],[-120.819757,38.397314]]]},"properties":{"id":"95642","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.905994,39.536693],[-121.856498,39.533582],[-121.712468,39.639222],[-121.757851,39.676477],[-121.905994,39.536693]]]},"properties":{"id":"95938","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.208215,39.527196],[-121.134368,39.537903],[-121.129142,39.541638],[-121.124932,39.563078],[-121.298599,39.556275],[-121.304273,39.549728],[-121.208215,39.527196]]]},"properties":{"id":"95930","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.485283,38.093381],[-120.424118,38.075893],[-120.376203,38.142649],[-120.404088,38.194688],[-120.439893,38.192603],[-120.571644,38.125862],[-120.485283,38.093381]]]},"properties":{"id":"95247","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.891386,38.219605],[-120.90579,38.197558],[-120.926878,38.194812],[-120.860231,38.20675],[-120.891386,38.219605]]]},"properties":{"id":"95225","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.747344,37.916054],[-120.690846,38.047462],[-120.736415,38.053692],[-120.926449,38.077421],[-121.038495,37.938568],[-121.04601,37.892949],[-120.943584,37.856762],[-120.747344,37.916054]]]},"properties":{"id":"95230","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.060475,39.195587],[-122.261474,38.924059],[-122.093042,38.925651],[-121.915313,38.925348],[-121.91441,38.925351],[-121.940929,39.133544],[-122.060475,39.195587]]]},"properties":{"id":"95912","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.315559,37.985641],[-122.243152,37.971105],[-122.249797,37.993052],[-122.302178,38.017986],[-122.326382,38.009847],[-122.315559,37.985641]]]},"properties":{"id":"94564","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.161334,37.840213],[-122.172277,37.845057],[-122.151337,37.825103],[-122.161334,37.840213]]]},"properties":{"id":"94516","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.22995,41.936558],[-123.958799,41.67617],[-123.824653,41.876771],[-123.967738,41.749952],[-124.007959,41.848991],[-124.031359,41.910859],[-124.22995,41.936558]]]},"properties":{"id":"95531","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.370998,38.544613],[-120.42063,38.680058],[-120.524497,38.724374],[-120.596498,38.687488],[-120.778529,38.592363],[-120.820936,38.559469],[-120.747251,38.548446],[-120.737147,38.546327],[-120.565168,38.508146],[-120.440136,38.5288],[-120.370998,38.544613]]]},"properties":{"id":"95684","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.890658,38.801481],[-120.744221,38.872565],[-120.867011,38.892254],[-120.907987,38.855577],[-120.890658,38.801481]]]},"properties":{"id":"95633","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.890658,38.801481],[-120.907987,38.855577],[-120.937,38.847574],[-120.969035,38.828067],[-120.980881,38.795243],[-120.890658,38.801481]]]},"properties":{"id":"95651","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.11554,38.772089],[-120.12207,38.668616],[-120.077187,38.701026],[-120.06762,38.706015],[-120.032697,38.729776],[-120.04084,38.823465],[-120.116192,38.803103],[-120.11554,38.772089]]]},"properties":{"id":"96155","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.610566,36.634396],[-119.709429,36.583637],[-119.755212,36.489321],[-119.609765,36.461348],[-119.530275,36.575828],[-119.574844,36.619647],[-119.610566,36.634396]]]},"properties":{"id":"93662","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.249282,36.824959],[-119.110094,36.725514],[-119.05548,36.754098],[-119.09576,36.857618],[-119.131131,36.87108],[-119.250998,36.88487],[-119.249282,36.824959]]]},"properties":{"id":"93621","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.299003,36.723251],[-119.499523,36.653188],[-119.502594,36.575563],[-119.484525,36.53571],[-119.340663,36.603085],[-119.304635,36.718719],[-119.299003,36.723251]]]},"properties":{"id":"93654","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.790429,36.808501],[-119.733319,36.808538],[-119.729551,36.837591],[-119.790226,36.837252],[-119.790262,36.833965],[-119.790429,36.808501]]]},"properties":{"id":"93710","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.727452,36.721837],[-119.663953,36.70018],[-119.622568,36.780662],[-119.655024,36.787611],[-119.690602,36.801232],[-119.727336,36.794136],[-119.736258,36.779496],[-119.736317,36.758617],[-119.727452,36.721837]]]},"properties":{"id":"93727","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.729889,36.88142],[-119.730259,36.934337],[-119.747201,36.938428],[-119.788734,36.892559],[-119.729889,36.88142]]]},"properties":{"id":"93730","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.283505,39.611465],[-122.391531,39.581303],[-122.442784,39.637082],[-122.417767,39.383975],[-122.256982,39.384842],[-122.120101,39.414544],[-122.037285,39.481197],[-122.104205,39.623452],[-122.283505,39.611465]]]},"properties":{"id":"95988","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.08616,41.185972],[-123.997522,41.403823],[-124.065202,41.46474],[-124.08616,41.185972]]]},"properties":{"id":"95555","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.0858,41.0087],[-124.116375,41.025133],[-124.132808,40.956031],[-124.024363,40.897707],[-123.948588,40.905112],[-124.0858,41.0087]]]},"properties":{"id":"95519","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-124.119834,40.172734],[-124.04279,40.20985],[-124.127419,40.201413],[-124.119834,40.172734]]],[[[-123.891718,40.11047],[-123.841201,40.001753],[-123.813669,40.001865],[-123.772664,40.002056],[-123.707339,40.001795],[-123.544458,40.001923],[-123.544197,40.110988],[-123.70551,40.146495],[-123.784701,40.165438],[-123.891718,40.11047]]]]},"properties":{"id":"95542","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.033728,40.313744],[-123.976195,40.260483],[-123.906972,40.157439],[-123.810051,40.217616],[-123.761224,40.232245],[-123.913603,40.30931],[-123.923853,40.316932],[-124.033728,40.313744]]]},"properties":{"id":"95553","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.985029,40.431187],[-123.893603,40.40847],[-124.087893,40.511964],[-124.094008,40.505796],[-124.062675,40.419624],[-123.985029,40.431187]]]},"properties":{"id":"95565","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.690918,32.715775],[-115.662226,32.652337],[-115.363022,32.674096],[-115.413689,32.708273],[-115.542509,32.709185],[-115.690918,32.715775]]]},"properties":{"id":"92231","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.290038,35.902962],[-116.101862,35.890822],[-116.063714,36.094524],[-116.290038,35.902962]]]},"properties":{"id":"92389","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.509907,35.665029],[-116.468378,35.570377],[-116.335567,35.555853],[-116.285111,35.622874],[-116.509907,35.665029]]]},"properties":{"id":"89019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.129825,36.162745],[-116.108531,36.167138],[-116.189653,36.229846],[-116.138954,36.160858],[-116.129825,36.162745]]]},"properties":{"id":"89060","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.967502,35.37135],[-118.833892,35.389804],[-118.713944,35.442737],[-118.637798,35.606525],[-118.967602,35.412735],[-118.967502,35.37135]]]},"properties":{"id":"93306","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.09886,35.434289],[-119.129463,35.35339],[-119.115737,35.35612],[-119.091762,35.363872],[-119.09886,35.434289]]]},"properties":{"id":"93312","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.440253,35.66349],[-118.446275,35.782968],[-118.588581,35.652548],[-118.440253,35.66349]]]},"properties":{"id":"93285","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.329948,35.746728],[-119.241114,35.616423],[-119.081234,35.642913],[-119.080437,35.645865],[-119.329948,35.746728]]]},"properties":{"id":"93250","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.316694,35.790323],[-118.486626,35.9683],[-118.566223,35.963657],[-118.548524,35.853555],[-118.446275,35.782968],[-118.440253,35.66349],[-118.3802,35.667781],[-118.316694,35.790323]]]},"properties":{"id":"93238","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.949868,34.897757],[-119.122174,34.853665],[-119.155084,34.81338],[-118.847789,34.721368],[-118.949868,34.897757]]]},"properties":{"id":"93225","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.122174,34.853665],[-119.277501,34.872229],[-119.155084,34.81338],[-119.122174,34.853665]]]},"properties":{"id":"93222","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.644355,36.108873],[-119.537694,35.934784],[-119.426729,35.920192],[-119.375519,36.007593],[-119.475471,36.065387],[-119.53638,36.137893],[-119.644355,36.108873]]]},"properties":{"id":"93212","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.182053,36.010641],[-120.094348,35.907379],[-120.054747,36.000942],[-120.154911,36.031359],[-120.182053,36.010641]]]},"properties":{"id":"93204","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.754314,38.805797],[-122.724198,38.861317],[-122.817363,38.846079],[-122.754314,38.805797]]]},"properties":{"id":"95426","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.263935,41.184211],[-121.213909,41.059696],[-121.146572,40.977316],[-120.851303,41.063882],[-121.034768,41.184368],[-121.198605,41.184407],[-121.263935,41.184211]]]},"properties":{"id":"96009","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.281482,40.431955],[-120.206091,40.726991],[-120.291161,40.659053],[-120.46903,40.524009],[-120.521289,40.379282],[-120.323872,40.35185],[-120.281482,40.431955]]]},"properties":{"id":"96117","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.973597,41.300019],[-121.034768,41.184368],[-120.851303,41.063882],[-120.749806,41.051749],[-120.642218,41.07841],[-120.642243,41.184015],[-120.806354,41.269516],[-120.820167,41.309536],[-120.973597,41.300019]]]},"properties":{"id":"96006","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.315043,34.18961],[-118.297511,34.177311],[-118.27996,34.190456],[-118.288282,34.217792],[-118.291225,34.221666],[-118.309359,34.221529],[-118.315043,34.18961]]]},"properties":{"id":"91501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.536079,34.220727],[-118.562289,34.219108],[-118.562228,34.19026],[-118.562222,34.184061],[-118.527286,34.180246],[-118.518481,34.186613],[-118.518596,34.208401],[-118.536079,34.220727]]]},"properties":{"id":"91335","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.160544,34.08889],[-118.122915,34.069775],[-118.108189,34.071654],[-118.112989,34.101707],[-118.121095,34.105275],[-118.135296,34.10645],[-118.155292,34.098576],[-118.160544,34.08889]]]},"properties":{"id":"91801","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.122915,34.069775],[-118.160544,34.08889],[-118.165293,34.062298],[-118.122915,34.069775]]]},"properties":{"id":"91803","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.30807,34.161224],[-118.278337,34.153205],[-118.275401,34.153166],[-118.276332,34.154721],[-118.276954,34.180209],[-118.27996,34.190456],[-118.297511,34.177311],[-118.304679,34.16442],[-118.30807,34.161224]]]},"properties":{"id":"91201","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.297511,34.177311],[-118.315043,34.18961],[-118.322926,34.186833],[-118.304679,34.16442],[-118.297511,34.177311]]]},"properties":{"id":"91502","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.422521,34.193937],[-118.448717,34.189684],[-118.4487,34.164855],[-118.412785,34.164869],[-118.414179,34.179404],[-118.42247,34.193937],[-118.422521,34.193937]]]},"properties":{"id":"91401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.518481,34.186613],[-118.527286,34.180246],[-118.537358,34.130637],[-118.518977,34.129031],[-118.51848,34.128944],[-118.497798,34.178794],[-118.518481,34.186613]]]},"properties":{"id":"91316","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.304679,34.16442],[-118.322926,34.186833],[-118.33826,34.189865],[-118.325341,34.153514],[-118.312615,34.154477],[-118.30807,34.161224],[-118.304679,34.16442]]]},"properties":{"id":"91506","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.299427,33.858315],[-118.298169,33.812984],[-118.287508,33.808959],[-118.284917,33.846239],[-118.299427,33.858315]]]},"properties":{"id":"90502","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.17667,33.918165],[-118.226507,33.938196],[-118.226772,33.937096],[-118.228805,33.922685],[-118.215669,33.912452],[-118.186257,33.904797],[-118.17667,33.918165]]]},"properties":{"id":"90262","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.074895,33.880383],[-118.072241,33.854512],[-118.090901,33.861313],[-118.087806,33.880338],[-118.107997,33.887708],[-118.108429,33.865587],[-118.098939,33.845875],[-118.058918,33.846121],[-118.028581,33.866246],[-118.028679,33.873219],[-118.028888,33.882629],[-118.055385,33.887789],[-118.074895,33.880383]]]},"properties":{"id":"90703","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.94985,34.011138],[-117.89104,34.003416],[-117.914669,34.047315],[-117.916851,34.050287],[-117.963791,34.052635],[-117.958214,34.01979],[-117.94985,34.011138]]]},"properties":{"id":"91744","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.378429,33.929008],[-118.378377,33.923654],[-118.374342,33.923661],[-118.378429,33.929008]]],[[[-118.370037,33.94542],[-118.368387,33.930946],[-118.347454,33.931724],[-118.343915,33.945968],[-118.370037,33.94542]]]]},"properties":{"id":"90304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167646,33.774919],[-118.239988,33.768007],[-118.240387,33.767706],[-118.244627,33.710767],[-118.169788,33.74847],[-118.167664,33.768428],[-118.167646,33.774919]],[[-118.20184,33.767322],[-118.204159,33.769518],[-118.199364,33.769285],[-118.20184,33.767322]]]},"properties":{"id":"90802","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.149426,33.789836],[-118.122351,33.779311],[-118.115271,33.775316],[-118.097404,33.77419],[-118.093201,33.786015],[-118.077412,33.8104],[-118.139138,33.812658],[-118.151346,33.804427],[-118.149426,33.789836]]]},"properties":{"id":"90815","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.933649,34.129383],[-117.924796,34.16531],[-117.95972,34.165159],[-117.97538,34.143658],[-117.99718,34.117683],[-117.933649,34.129383]]]},"properties":{"id":"91010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.370231,33.979929],[-118.36985,33.963824],[-118.34405,33.97116],[-118.33521,33.974447],[-118.358544,33.981667],[-118.370231,33.979929]]]},"properties":{"id":"90302","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.229988,34.047908],[-118.226747,34.062246],[-118.227235,34.081218],[-118.227601,34.08304],[-118.227876,34.083155],[-118.232118,34.085961],[-118.245418,34.075124],[-118.236507,34.07471],[-118.238003,34.069003],[-118.246484,34.071778],[-118.256045,34.056183],[-118.255897,34.056087],[-118.250157,34.052347],[-118.229988,34.047908]]]},"properties":{"id":"90012","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.256865,34.045163],[-118.254943,34.041599],[-118.254093,34.040736],[-118.25033,34.037303],[-118.24524,34.04226],[-118.253932,34.050176],[-118.256865,34.045163]]]},"properties":{"id":"90014","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.485404,34.404437],[-118.539286,34.395138],[-118.569579,34.387329],[-118.545017,34.329934],[-118.508341,34.33389],[-118.397893,34.353672],[-118.464898,34.405523],[-118.485404,34.404437]]]},"properties":{"id":"91321","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.17667,33.918165],[-118.156237,33.917044],[-118.161267,33.943933],[-118.158604,33.951894],[-118.192782,33.961622],[-118.229647,33.9612],[-118.226507,33.938196],[-118.17667,33.918165]]]},"properties":{"id":"90280","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.113178,34.157207],[-118.099342,34.174642],[-118.146251,34.181539],[-118.14605,34.157834],[-118.13246,34.157759],[-118.113178,34.157207]]]},"properties":{"id":"91104","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.168053,34.123923],[-118.145945,34.122738],[-118.1466,34.135795],[-118.14599,34.145816],[-118.183985,34.159065],[-118.183878,34.149081],[-118.186097,34.133732],[-118.168053,34.123923]]]},"properties":{"id":"91105","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.239986,34.21145],[-118.231373,34.218977],[-118.238903,34.267084],[-118.272473,34.232527],[-118.291225,34.221666],[-118.288282,34.217792],[-118.271548,34.20801],[-118.239973,34.210082],[-118.239978,34.210339],[-118.24242,34.211737],[-118.241705,34.212512],[-118.239986,34.21145]]]},"properties":{"id":"91214","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.255147,34.158783],[-118.276954,34.180209],[-118.276332,34.154721],[-118.255147,34.158783]]]},"properties":{"id":"91202","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.588481,34.208309],[-118.579785,34.231839],[-118.638429,34.254468],[-118.671476,34.241484],[-118.672975,34.238706],[-118.667773,34.223426],[-118.614688,34.208265],[-118.588481,34.208309]]]},"properties":{"id":"91304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.186097,34.133732],[-118.217322,34.11758],[-118.200556,34.101324],[-118.19064,34.096707],[-118.177996,34.101921],[-118.168053,34.123923],[-118.186097,34.133732]]]},"properties":{"id":"90042","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.186097,34.133732],[-118.183878,34.149081],[-118.228774,34.146032],[-118.227751,34.135601],[-118.217322,34.11758],[-118.186097,34.133732]]]},"properties":{"id":"90041","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.445338,33.994206],[-118.455804,34.009636],[-118.486455,33.993572],[-118.471863,33.976519],[-118.445338,33.994206]]]},"properties":{"id":"90291","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.453,34.027961],[-118.443945,34.017093],[-118.429918,34.026861],[-118.400538,34.045875],[-118.406643,34.052303],[-118.4162,34.053176],[-118.46146,34.03328],[-118.453,34.027961]]]},"properties":{"id":"90064","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.4162,34.053176],[-118.406643,34.052303],[-118.406023,34.052666],[-118.414344,34.064324],[-118.418614,34.061389],[-118.4162,34.053176]]]},"properties":{"id":"90067","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300165,34.018177],[-118.300349,34.039969],[-118.308999,34.039939],[-118.329738,34.039872],[-118.3329,34.018232],[-118.31709,34.017254],[-118.300165,34.018177]]]},"properties":{"id":"90018","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.450538,34.250619],[-118.443266,34.266618],[-118.455848,34.280274],[-118.46798,34.291621],[-118.472121,34.257323],[-118.451878,34.249935],[-118.450538,34.250619]]]},"properties":{"id":"91345","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.428775,33.93097],[-118.437121,33.916393],[-118.430009,33.902813],[-118.378701,33.901905],[-118.378377,33.923654],[-118.378429,33.929008],[-118.428775,33.93097]]]},"properties":{"id":"90245","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.237907,33.989333],[-118.230013,33.961768],[-118.229647,33.9612],[-118.192782,33.961622],[-118.189753,33.981957],[-118.204156,33.988957],[-118.237907,33.989333]]]},"properties":{"id":"90255","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.169859,33.986646],[-118.204156,33.988957],[-118.189753,33.981957],[-118.167058,33.97951],[-118.169347,33.986619],[-118.169859,33.986646]]]},"properties":{"id":"90270","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.017303,33.80993],[-118.035093,33.839068],[-118.058918,33.846121],[-118.061691,33.833317],[-118.064955,33.824018],[-118.063162,33.81961],[-118.03711,33.792097],[-118.011085,33.791998],[-118.011048,33.799187],[-118.010701,33.809992],[-118.017303,33.80993]]]},"properties":{"id":"90630","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.362006,34.136994],[-118.345665,34.142367],[-118.361267,34.142686],[-118.362006,34.136994]]]},"properties":{"id":"91608","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.028581,33.866246],[-118.058918,33.846121],[-118.035093,33.839068],[-118.028581,33.866246]]]},"properties":{"id":"90623","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.781078,37.039347],[-119.855955,37.192029],[-119.90844,37.069158],[-120.005865,36.909377],[-119.932338,36.843323],[-119.866335,36.847998],[-119.792635,36.876264],[-119.788734,36.892559],[-119.747201,36.938428],[-119.781078,37.039347]]]},"properties":{"id":"93636","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.615286,37.481244],[-119.57911,37.323616],[-119.519661,37.287897],[-119.383748,37.257252],[-119.324496,37.262761],[-119.332198,37.275005],[-119.58422,37.494696],[-119.615286,37.481244]]]},"properties":{"id":"93604","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.802749,37.384483],[-120.001719,37.370121],[-120.176277,37.260561],[-120.058885,37.191627],[-120.020309,37.119376],[-119.90844,37.069158],[-119.855955,37.192029],[-119.760973,37.303879],[-119.745957,37.349967],[-119.802749,37.384483]]]},"properties":{"id":"93653","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.656226,37.417818],[-119.615286,37.481244],[-119.58422,37.494696],[-119.583588,37.560178],[-119.658297,37.53382],[-119.698083,37.506301],[-119.688205,37.44117],[-119.656226,37.417818]]]},"properties":{"id":"93623","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.552986,37.965543],[-122.540474,37.963827],[-122.550898,37.972319],[-122.552986,37.965543]]],[[[-122.58076,37.96581],[-122.556889,37.96227],[-122.580449,37.969432],[-122.58076,37.96581]]]]},"properties":{"id":"94957","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.967349,38.249643],[-122.945073,38.250755],[-122.973905,38.26606],[-122.967349,38.249643]]]},"properties":{"id":"94929","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.628711,37.885352],[-122.659678,37.941658],[-122.715795,37.990815],[-122.741886,37.984926],[-122.742936,37.921288],[-122.634053,37.883072],[-122.628711,37.885352]]]},"properties":{"id":"94924","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.772693,38.067944],[-122.741886,37.984926],[-122.715795,37.990815],[-122.720282,37.995985],[-122.758947,38.0499],[-122.772693,38.067944]]]},"properties":{"id":"94950","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.860841,37.765018],[-120.244254,37.789658],[-120.245298,37.750543],[-120.294547,37.648865],[-120.288578,37.649628],[-120.070088,37.597737],[-119.925854,37.642539],[-119.901415,37.669499],[-119.853345,37.73857],[-119.860841,37.765018]]]},"properties":{"id":"95311","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.615727,39.594575],[-123.556224,39.35603],[-123.55479,39.351546],[-123.387665,39.33982],[-123.184993,39.420734],[-123.08184,39.530704],[-123.26915,39.697917],[-123.349073,39.678803],[-123.580454,39.625635],[-123.615727,39.594575]]]},"properties":{"id":"95490","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.006036,39.235979],[-123.125316,39.255459],[-123.421655,39.288741],[-123.533961,39.295446],[-123.429046,39.158243],[-123.42615,39.159896],[-123.289241,39.050453],[-123.286978,39.002337],[-123.056281,39.023209],[-123.06524,39.173673],[-123.006036,39.235979]]]},"properties":{"id":"95482","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.771709,39.55335],[-123.709799,39.572079],[-123.672944,39.597869],[-123.700054,39.683955],[-123.828451,39.842155],[-123.838637,39.736734],[-123.771709,39.55335]]]},"properties":{"id":"95488","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.674514,39.254792],[-123.803675,39.269622],[-123.713413,39.102335],[-123.63667,39.156788],[-123.583127,39.161111],[-123.591731,39.174183],[-123.674197,39.254623],[-123.674514,39.254792]]]},"properties":{"id":"95410","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.667711,37.419354],[-120.667662,37.42302],[-120.631391,37.443775],[-120.599068,37.551052],[-120.739623,37.496022],[-120.721721,37.448273],[-120.731439,37.405701],[-120.667711,37.419354]]]},"properties":{"id":"95303","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.269666,37.378011],[-120.306311,37.345816],[-120.33385,37.295022],[-120.269666,37.378011]]]},"properties":{"id":"95365","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.119476,37.875866],[-119.147109,37.845095],[-119.080166,37.760057],[-119.119476,37.875866]]]},"properties":{"id":"93529","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.540843,38.641627],[-119.516766,38.602247],[-119.457868,38.604668],[-119.540843,38.641627]]]},"properties":{"id":"96133","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.74206,36.830255],[-121.717696,36.816595],[-121.62856,36.828697],[-121.597475,36.900496],[-121.581354,36.899152],[-121.749039,37.060624],[-121.809076,37.069301],[-121.84102,37.065063],[-121.869941,36.933889],[-121.81467,36.849517],[-121.74206,36.830255]],[[-121.766281,36.936367],[-121.783799,36.930906],[-121.787878,36.944957],[-121.766281,36.936367]]]},"properties":{"id":"95076","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.002724,35.968807],[-120.790518,35.923377],[-120.477816,36.003283],[-120.725068,36.175584],[-120.869908,36.16574],[-120.865371,36.125645],[-121.023467,36.12194],[-121.026993,36.117532],[-121.002724,35.968807]]]},"properties":{"id":"93450","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.239194,36.003227],[-121.217862,36.042392],[-121.257013,36.088109],[-121.281717,36.034913],[-121.239194,36.003227]]]},"properties":{"id":"93928","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.900965,36.620259],[-121.94369,36.618983],[-121.926784,36.600616],[-121.900965,36.620259]]]},"properties":{"id":"93950","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.406786,38.155632],[-122.294885,38.155218],[-122.306755,38.209041],[-122.211081,38.252215],[-122.201067,38.271974],[-122.378987,38.250714],[-122.406786,38.155632]]]},"properties":{"id":"94559","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.229385,39.160784],[-121.136513,39.209434],[-121.172577,39.244302],[-121.173007,39.288946],[-121.193325,39.330255],[-121.205602,39.312435],[-121.270729,39.23777],[-121.229385,39.160784]]]},"properties":{"id":"95946","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.629248,39.344687],[-120.630686,39.315272],[-120.759289,39.188122],[-120.711292,39.189259],[-120.5678,39.315559],[-120.555314,39.31561],[-120.629248,39.344687]]]},"properties":{"id":"95715","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.750212,39.361788],[-120.799293,39.363897],[-120.785531,39.321123],[-120.750212,39.361788]]]},"properties":{"id":"95986","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.041926,33.75026],[-117.971945,33.719835],[-117.945978,33.732208],[-117.954893,33.752224],[-117.992781,33.77379],[-118.007408,33.77433],[-118.042931,33.775032],[-118.041926,33.75026]],[[-117.976612,33.7448],[-117.983696,33.74471],[-117.98841,33.752023],[-117.976621,33.752095],[-117.976612,33.7448]]]},"properties":{"id":"92683","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.86722,33.68671],[-117.86763,33.69178],[-117.8833,33.69411],[-117.930693,33.701862],[-117.939512,33.687083],[-117.946994,33.666689],[-117.883152,33.665486],[-117.878401,33.664082],[-117.86722,33.68671]]]},"properties":{"id":"92626","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.623632,33.664713],[-117.603946,33.566304],[-117.590802,33.550537],[-117.575282,33.562165],[-117.555296,33.641916],[-117.563931,33.684573],[-117.637035,33.698441],[-117.640465,33.663513],[-117.63763,33.66192],[-117.623632,33.664713]]]},"properties":{"id":"92679","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.942,33.88123],[-117.94179,33.854343],[-117.911291,33.863732],[-117.924447,33.883266],[-117.942,33.88123]]]},"properties":{"id":"92832","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.79761,33.74005],[-117.797981,33.721677],[-117.795023,33.719649],[-117.75851,33.78217],[-117.76169,33.77661],[-117.79761,33.74005]]]},"properties":{"id":"92782","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.283898,39.225965],[-120.242188,39.147922],[-120.1988,39.221911],[-120.283898,39.225965]]]},"properties":{"id":"96146","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.188571,38.714308],[-121.170375,38.713004],[-121.122499,38.782232],[-121.206934,38.774198],[-121.217839,38.770384],[-121.2222,38.716561],[-121.188571,38.714308]]]},"properties":{"id":"95746","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.075531,39.239925],[-120.064082,39.236909],[-120.075869,39.251787],[-120.076247,39.251398],[-120.075531,39.239925]]]},"properties":{"id":"95604","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.634285,40.210515],[-120.735297,40.117986],[-120.89945,40.107089],[-120.85797,40.038725],[-120.624543,40.200363],[-120.634285,40.210515]]]},"properties":{"id":"95983","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.600066,33.975316],[-117.610482,33.968822],[-117.610913,33.960084],[-117.655377,33.924441],[-117.655335,33.921397],[-117.673749,33.870831],[-117.672838,33.86991],[-117.662117,33.857499],[-117.565233,33.882454],[-117.560039,33.898605],[-117.558302,33.945908],[-117.558377,33.98842],[-117.575469,33.983079],[-117.600066,33.975316]]]},"properties":{"id":"92880","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.209711,33.608973],[-117.3036,33.597693],[-117.460901,33.598031],[-117.345984,33.48254],[-117.322403,33.475031],[-117.168241,33.530065],[-117.17254,33.536523],[-117.171013,33.627039],[-117.209711,33.608973]]]},"properties":{"id":"92562","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.617687,33.427354],[-116.518168,33.528947],[-116.741791,33.598921],[-116.771413,33.591226],[-116.681777,33.42722],[-116.617687,33.427354]]]},"properties":{"id":"92539","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.558302,33.945908],[-117.523437,33.964842],[-117.505635,33.971539],[-117.506891,34.033485],[-117.524313,34.033469],[-117.558377,33.98842],[-117.558302,33.945908]]]},"properties":{"id":"91752","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.223042,33.728524],[-117.180335,33.706739],[-117.145198,33.702141],[-117.134941,33.721464],[-117.132167,33.735926],[-117.133741,33.784321],[-117.173753,33.786697],[-117.199842,33.773297],[-117.223042,33.728524]]]},"properties":{"id":"92585","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.287357,33.916792],[-117.243573,33.902774],[-117.208891,33.902826],[-117.208911,33.93932],[-117.27893,33.942724],[-117.288592,33.928237],[-117.287357,33.916792]]]},"properties":{"id":"92553","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.466055,33.964413],[-117.505635,33.971539],[-117.523437,33.964842],[-117.51406,33.910063],[-117.501299,33.894249],[-117.465357,33.962459],[-117.466055,33.964413]]]},"properties":{"id":"92505","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.342549,33.858041],[-117.313862,33.865746],[-117.287357,33.916792],[-117.288592,33.928237],[-117.318274,33.927107],[-117.357236,33.891544],[-117.342549,33.858041]]]},"properties":{"id":"92508","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.404045,38.639788],[-121.421372,38.658115],[-121.477105,38.669996],[-121.474322,38.655347],[-121.469519,38.629945],[-121.467588,38.61974],[-121.413082,38.631453],[-121.406793,38.637239],[-121.404045,38.639788]]]},"properties":{"id":"95838","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.424189,38.551612],[-121.459061,38.55816],[-121.473988,38.562831],[-121.475758,38.541064],[-121.424189,38.551612]]]},"properties":{"id":"95817","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.420564,38.582105],[-121.41993,38.610926],[-121.413082,38.631453],[-121.467588,38.61974],[-121.505005,38.60029],[-121.447198,38.58773],[-121.420564,38.582105]]]},"properties":{"id":"95815","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.462649,38.582345],[-121.482674,38.565148],[-121.473988,38.562831],[-121.459061,38.55816],[-121.462649,38.582345]]]},"properties":{"id":"95816","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.519519,38.203162],[-121.666985,38.177036],[-121.570104,38.096798],[-121.503089,38.072586],[-121.501407,38.116219],[-121.519519,38.203162]]]},"properties":{"id":"95641","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.371749,38.452569],[-121.41065,38.456781],[-121.362104,38.376308],[-121.343672,38.35772],[-121.343617,38.357622],[-121.211127,38.4547],[-121.241463,38.505559],[-121.242456,38.505756],[-121.29755,38.452701],[-121.371749,38.452569]]]},"properties":{"id":"95624","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.655814,38.313419],[-121.842059,38.278465],[-121.842475,38.271398],[-121.806186,38.183597],[-121.786561,38.147883],[-121.821875,38.116458],[-121.861597,38.069887],[-121.862462,38.06603],[-121.827667,38.02792],[-121.751535,38.024277],[-121.732565,38.02902],[-121.580022,38.094414],[-121.570104,38.096798],[-121.666985,38.177036],[-121.630825,38.291798],[-121.655814,38.313419]]]},"properties":{"id":"94571","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.266454,38.568074],[-121.242743,38.525563],[-121.242456,38.505756],[-121.241463,38.505559],[-121.142662,38.617013],[-121.206725,38.637463],[-121.266454,38.568074]]]},"properties":{"id":"95742","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.312707,38.671133],[-121.292791,38.663612],[-121.290403,38.721501],[-121.293849,38.721403],[-121.30883,38.72279],[-121.311151,38.722923],[-121.334819,38.70341],[-121.326862,38.691852],[-121.328001,38.673216],[-121.312707,38.671133]]]},"properties":{"id":"95621","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.378663,38.609673],[-121.41993,38.610926],[-121.420564,38.582105],[-121.419995,38.562441],[-121.409265,38.560434],[-121.378663,38.609673]]]},"properties":{"id":"95825","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.483002,36.76505],[-121.512065,36.94804],[-121.581354,36.899152],[-121.597475,36.900496],[-121.5971,36.836849],[-121.582248,36.824437],[-121.483002,36.76505]]]},"properties":{"id":"95045","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.771122,34.33988],[-116.665821,34.380636],[-117.100497,34.71144],[-117.054295,34.450962],[-117.076531,34.367446],[-116.771122,34.33988]]]},"properties":{"id":"92356","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.628572,34.092558],[-117.524027,34.07718],[-117.523822,34.089848],[-117.545411,34.121508],[-117.620136,34.1216],[-117.623451,34.121565],[-117.628572,34.092558]]]},"properties":{"id":"91730","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.650627,33.983058],[-117.600066,33.975316],[-117.575469,33.983079],[-117.650819,34.004799],[-117.650627,33.983058]]],[[[-117.680888,34.087374],[-117.689527,34.04797],[-117.654503,34.021857],[-117.649598,34.063365],[-117.651096,34.087421],[-117.680888,34.087374]]]]},"properties":{"id":"91762","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.680888,34.087374],[-117.70429,34.095055],[-117.711067,34.079536],[-117.719481,34.059731],[-117.706907,34.046672],[-117.689527,34.04797],[-117.680888,34.087374]]]},"properties":{"id":"91763","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.514099,34.106357],[-117.456668,34.208074],[-117.465952,34.220108],[-117.558727,34.219084],[-117.561274,34.165291],[-117.545267,34.136485],[-117.545411,34.121508],[-117.523822,34.089848],[-117.514099,34.106357]]]},"properties":{"id":"91739","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.920055,34.279588],[-116.96512,34.254567],[-116.962591,34.250539],[-116.925499,34.26138],[-116.920055,34.279588]]]},"properties":{"id":"92333","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.582762,34.779025],[-114.628276,34.863596],[-114.633432,35.001773],[-114.702737,35.058119],[-114.756731,34.890232],[-114.582762,34.779025]]]},"properties":{"id":"92363","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.302548,34.121332],[-117.319111,34.144497],[-117.347943,34.136018],[-117.335518,34.121375],[-117.302548,34.121332]]]},"properties":{"id":"92411","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.424893,34.067494],[-117.414178,34.034306],[-117.367372,34.038234],[-117.365907,34.068885],[-117.40522,34.092216],[-117.424893,34.067494]]]},"properties":{"id":"92316","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.243287,34.2514],[-117.251292,34.228317],[-117.242667,34.225167],[-117.215984,34.230691],[-117.238173,34.251424],[-117.243287,34.2514]]]},"properties":{"id":"92391","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.324608,34.237219],[-117.312248,34.254294],[-117.312288,34.26613],[-117.347011,34.26656],[-117.329616,34.25136],[-117.324608,34.237219]]]},"properties":{"id":"92322","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.234649,32.838039],[-117.177881,32.847326],[-117.133995,32.840851],[-117.120988,32.841551],[-117.120147,32.84187],[-117.107508,32.875229],[-117.159788,32.87991],[-117.214044,32.875076],[-117.234931,32.83802],[-117.234649,32.838039]]]},"properties":{"id":"92122","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.17741,32.926873],[-117.159788,32.87991],[-117.107508,32.875229],[-117.111914,32.935417],[-117.166861,32.93243],[-117.17741,32.926873]]]},"properties":{"id":"92126","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.049138,32.754755],[-116.988089,32.779118],[-117.00588,32.794306],[-117.040705,32.783359],[-117.048602,32.774257],[-117.049138,32.754755]]]},"properties":{"id":"91942","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.057359,32.663915],[-117.064344,32.688864],[-117.085132,32.692365],[-117.124747,32.679291],[-117.102416,32.646648],[-117.071364,32.654997],[-117.057359,32.663915]]]},"properties":{"id":"91950","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.257118,33.011662],[-117.292284,33.03098],[-117.281483,33.007102],[-117.257118,33.011662]]]},"properties":{"id":"92007","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.643345,32.59786],[-116.627471,32.576382],[-116.592128,32.579271],[-116.643345,32.59786]]]},"properties":{"id":"91980","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.448024,37.793122],[-122.476625,37.786796],[-122.477363,37.766062],[-122.444967,37.776958],[-122.447679,37.791703],[-122.448024,37.793122]]]},"properties":{"id":"94118","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.463361,37.753039],[-122.509833,37.763969],[-122.510341,37.764001],[-122.506717,37.73547],[-122.471569,37.734707],[-122.459174,37.747286],[-122.463361,37.753039]]]},"properties":{"id":"94116","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.430183,37.795854],[-122.43235,37.805976],[-122.448127,37.806896],[-122.448024,37.793122],[-122.447679,37.791703],[-122.430183,37.795854]]]},"properties":{"id":"94123","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.323174,38.02107],[-121.310597,38.05758],[-121.377444,38.072744],[-121.358637,38.021338],[-121.323174,38.02107]]]},"properties":{"id":"95209","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.323007,37.864768],[-121.262989,37.826441],[-121.243092,37.879161],[-121.323007,37.864768]]]},"properties":{"id":"95231","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.2533,37.816824],[-121.300428,37.779704],[-121.242018,37.664585],[-121.206405,37.694791],[-121.179284,37.774773],[-121.2533,37.816824]]]},"properties":{"id":"95337","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.179284,37.774773],[-121.206405,37.694791],[-121.110248,37.730128],[-121.094848,37.733328],[-121.068848,37.739528],[-121.11708,37.826994],[-121.179284,37.774773]]]},"properties":{"id":"95366","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.636974,35.121719],[-120.649616,34.974841],[-120.602199,34.972749],[-120.591507,35.04743],[-120.604764,35.106563],[-120.636974,35.121719]]]},"properties":{"id":"93445","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.354864,35.49356],[-120.604316,35.446008],[-120.739825,35.411562],[-120.695002,35.382945],[-120.646819,35.350146],[-120.552972,35.312017],[-120.407906,35.266133],[-120.086674,35.526554],[-120.354864,35.49356]]]},"properties":{"id":"93453","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.767909,35.483152],[-120.607261,35.484963],[-120.558254,35.534821],[-120.912039,35.577789],[-120.907311,35.544536],[-120.767909,35.483152]]]},"properties":{"id":"93465","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229626,37.482088],[-122.211847,37.469246],[-122.209011,37.467173],[-122.178182,37.498268],[-122.245796,37.504955],[-122.24424,37.49407],[-122.229626,37.482088]]]},"properties":{"id":"94063","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.329963,37.540999],[-122.302729,37.560357],[-122.332418,37.565238],[-122.329963,37.540999]]],[[[-122.329963,37.540999],[-122.361134,37.531361],[-122.342104,37.509035],[-122.339141,37.507196],[-122.330197,37.512587],[-122.329963,37.540999]]]]},"properties":{"id":"94402","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.429383,37.682133],[-122.387308,37.671774],[-122.393226,37.708282],[-122.393782,37.708231],[-122.40529,37.708264],[-122.429383,37.682133]]]},"properties":{"id":"94005","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.543132,34.900795],[-120.549608,34.829385],[-120.528462,34.835734],[-120.543132,34.900795]]]},"properties":{"id":"93429","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.892193,37.323887],[-121.870558,37.313033],[-121.884891,37.328588],[-121.892767,37.328814],[-121.893293,37.339833],[-121.919394,37.372169],[-121.941638,37.376737],[-121.94575,37.37352],[-121.931845,37.351074],[-121.892193,37.323887]]]},"properties":{"id":"95110","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.0288,37.217522],[-121.995491,37.250381],[-121.990655,37.269106],[-121.994554,37.289476],[-122.032264,37.293965],[-122.138483,37.267513],[-122.0288,37.217522]]]},"properties":{"id":"95070","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.915198,37.39611],[-121.937901,37.380569],[-121.941638,37.376737],[-121.919394,37.372169],[-121.880698,37.368007],[-121.862912,37.384459],[-121.887876,37.406952],[-121.915198,37.39611]]]},"properties":{"id":"95131","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.98697,37.37778],[-121.96589,37.3743],[-121.94575,37.37352],[-121.941638,37.376737],[-121.937901,37.380569],[-121.967936,37.418922],[-121.986838,37.412059],[-121.986976,37.388448],[-121.98697,37.37778]]]},"properties":{"id":"95054","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.037361,37.400906],[-121.986976,37.388448],[-121.986838,37.412059],[-122.041135,37.427012],[-122.037361,37.400906]]]},"properties":{"id":"94089","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.747404,37.229619],[-121.751149,37.226434],[-121.758672,37.217763],[-121.747404,37.229619]]]},"properties":{"id":"95013","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.056491,37.127954],[-122.100985,37.108953],[-122.094585,37.099451],[-122.1165,37.103782],[-122.13882,37.10427],[-122.106761,37.070536],[-122.060417,37.125026],[-122.056491,37.127954]]]},"properties":{"id":"95005","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.207158,37.157156],[-122.13882,37.10427],[-122.1165,37.103782],[-122.100985,37.108953],[-122.056491,37.127954],[-122.151457,37.230023],[-122.242473,37.215133],[-122.24266,37.190035],[-122.207158,37.157156]]]},"properties":{"id":"95006","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.055834,37.012883],[-122.005264,37.042893],[-121.953521,37.076235],[-121.996761,37.113511],[-122.055834,37.012883]]]},"properties":{"id":"95066","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.451888,41.114698],[-122.179325,41.046532],[-122.07282,41.183097],[-122.19603,41.18444],[-122.498376,41.182675],[-122.451888,41.114698]]]},"properties":{"id":"96017","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.733578,40.484083],[-121.8017,40.575187],[-121.943904,40.561824],[-122.106242,40.525376],[-122.047433,40.426978],[-121.985607,40.438933],[-121.733578,40.484083]]]},"properties":{"id":"96088","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.193557,40.392311],[-122.443277,40.412288],[-122.709274,40.347991],[-122.710322,40.303059],[-122.69997,40.273673],[-122.182755,40.346732],[-122.193557,40.392311]]]},"properties":{"id":"96022","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.889435,41.238806],[-122.764174,41.470552],[-122.904654,41.543568],[-122.96456,41.528627],[-123.240893,41.49045],[-123.282973,41.468439],[-123.428997,41.374229],[-122.889435,41.238806]]]},"properties":{"id":"96027","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.535227,41.646193],[-122.575356,41.563283],[-122.503224,41.577487],[-122.535227,41.646193]]]},"properties":{"id":"96038","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229825,38.12315],[-122.225885,38.061018],[-122.225178,38.061006],[-122.194805,38.056533],[-122.162843,38.101196],[-122.201893,38.168299],[-122.21466,38.15492],[-122.229825,38.12315]]]},"properties":{"id":"94591","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.111264,38.477108],[-121.942,38.453278],[-121.82832,38.529443],[-121.877062,38.561285],[-121.951938,38.665792],[-121.969796,38.670963],[-122.067053,38.648935],[-122.164944,38.642462],[-122.111264,38.477108]]]},"properties":{"id":"95694","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.317492,38.558665],[-123.129003,38.450789],[-123.050004,38.453927],[-123.317492,38.558665]]]},"properties":{"id":"95450","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.95459,38.534102],[-122.98714,38.526693],[-122.961612,38.510461],[-122.95459,38.534102]]]},"properties":{"id":"95471","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.048786,38.454327],[-123.046589,38.459067],[-123.047211,38.464806],[-123.053035,38.461489],[-123.049083,38.454198],[-123.048786,38.454327]]]},"properties":{"id":"95430","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.835578,37.543863],[-120.901395,37.551249],[-120.907336,37.547541],[-120.790691,37.514477],[-120.835578,37.543863]]]},"properties":{"id":"95382","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.835578,37.543863],[-120.790691,37.514477],[-120.739623,37.496022],[-120.599068,37.551052],[-120.521517,37.581487],[-120.572885,37.584908],[-120.767356,37.594699],[-120.835578,37.543863]]]},"properties":{"id":"95316","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.804291,37.682035],[-120.80167,37.61882],[-120.782708,37.62507],[-120.618329,37.621108],[-120.45857,37.68711],[-120.804291,37.682035]]]},"properties":{"id":"95386","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.572885,37.584908],[-120.618329,37.621108],[-120.782708,37.62507],[-120.767356,37.594699],[-120.572885,37.584908]]]},"properties":{"id":"95323","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.494358,38.751231],[-121.487734,38.915743],[-121.573831,38.930196],[-121.57703,38.91967],[-121.612793,38.885887],[-121.620653,38.784004],[-121.595049,38.749738],[-121.540523,38.736402],[-121.540273,38.736405],[-121.494358,38.751231]]]},"properties":{"id":"95659","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.640781,40.342959],[-121.588236,40.338664],[-121.568719,40.353515],[-121.667445,40.347041],[-121.640781,40.342959]]]},"properties":{"id":"96063","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.588236,40.338664],[-121.560323,40.275431],[-121.474958,40.33596],[-121.568719,40.353515],[-121.588236,40.338664]]]},"properties":{"id":"96061","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.368315,40.696601],[-123.390646,40.477338],[-123.313832,40.368457],[-123.103712,40.310902],[-123.112755,40.397747],[-122.972625,40.502012],[-123.024553,40.612086],[-123.111623,40.703602],[-123.358809,40.700562],[-123.368315,40.696601]]]},"properties":{"id":"96041","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.390646,40.477338],[-123.368315,40.696601],[-123.543956,40.732938],[-123.54452,40.5925],[-123.460291,40.454218],[-123.390646,40.477338]]]},"properties":{"id":"96046","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.475033,36.390478],[-119.475364,36.327597],[-119.288903,36.326903],[-119.286615,36.460174],[-119.324336,36.487054],[-119.39484,36.443978],[-119.475033,36.390478]]]},"properties":{"id":"93291","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.561112,36.570099],[-118.784933,36.561217],[-118.836072,36.651128],[-118.899006,36.620628],[-118.98112,36.566644],[-118.965812,36.351551],[-118.855601,36.321584],[-118.59062,36.335262],[-118.561112,36.570099]]]},"properties":{"id":"93271","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.059487,36.577421],[-119.062308,36.368779],[-118.965812,36.351551],[-118.98112,36.566644],[-119.010491,36.596678],[-119.059487,36.577421]]]},"properties":{"id":"93244","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.010491,36.596678],[-118.98112,36.566644],[-118.899006,36.620628],[-118.984458,36.670587],[-119.014425,36.657353],[-119.010491,36.596678]]]},"properties":{"id":"93603","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.836072,36.651128],[-118.784933,36.561217],[-118.561112,36.570099],[-118.836072,36.651128]]]},"properties":{"id":"93262","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.189719,34.242315],[-119.195243,34.287953],[-119.216442,34.235113],[-119.189719,34.242315]]]},"properties":{"id":"93003","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-119.179049,34.130183],[-119.194485,34.170636],[-119.203003,34.168487],[-119.20831,34.175735],[-119.194982,34.175684],[-119.194975,34.180753],[-119.218609,34.14426],[-119.179049,34.130183]]],[[[-119.06796,34.093202],[-119.161281,34.117931],[-119.060871,34.084049],[-119.06796,34.093202]]]]},"properties":{"id":"93041","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.425938,33.231432],[-119.52929,33.288838],[-119.547107,33.231226],[-119.425938,33.231432]]]},"properties":{"id":"93042","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.969796,38.670963],[-121.951938,38.665792],[-121.956119,38.703547],[-121.969796,38.670963]]]},"properties":{"id":"95653","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.309723,39.405462],[-121.205602,39.312435],[-121.193325,39.330255],[-121.190719,39.330372],[-121.237749,39.381329],[-121.309723,39.405462]]]},"properties":{"id":"95962","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.27127,37.846562],[-122.273752,37.819135],[-122.267861,37.816237],[-122.253016,37.833557],[-122.257767,37.853081],[-122.266128,37.852009],[-122.27127,37.846562]]]},"properties":{"id":"94609","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.234679,37.799827],[-122.249578,37.807558],[-122.259574,37.798552],[-122.26838,37.788028],[-122.236488,37.771696],[-122.223531,37.795772],[-122.234679,37.799827]]]},"properties":{"id":"94606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.295896,37.880417],[-122.289561,37.849907],[-122.277787,37.847601],[-122.281596,37.880632],[-122.283139,37.882063],[-122.295896,37.880417]]]},"properties":{"id":"94702","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.917097,37.458473],[-121.855762,37.484537],[-121.861923,37.503102],[-121.932146,37.573315],[-121.958779,37.559523],[-121.917097,37.458473]]]},"properties":{"id":"94539","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.824574,37.838995],[-121.895966,37.83823],[-121.86668,37.764212],[-121.824574,37.838995]]],[[[-121.844992,37.701321],[-121.977662,37.698542],[-121.963473,37.672006],[-121.913669,37.632732],[-121.819614,37.674599],[-121.844992,37.701321]]]]},"properties":{"id":"94588","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.870733,37.740086],[-121.917372,37.728955],[-121.97247,37.728528],[-121.977662,37.698542],[-121.844992,37.701321],[-121.870733,37.740086]]]},"properties":{"id":"94568","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.215174,37.812276],[-122.260751,37.813733],[-122.249578,37.807558],[-122.234679,37.799827],[-122.215174,37.812276]]]},"properties":{"id":"94610","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.28128,37.917248],[-122.288053,37.899312],[-122.283139,37.882063],[-122.281596,37.880632],[-122.275673,37.884755],[-122.266162,37.886083],[-122.28128,37.917248]]]},"properties":{"id":"94707","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.819757,38.397314],[-120.702125,38.40971],[-120.644053,38.439649],[-120.698191,38.473383],[-120.790877,38.493054],[-120.843368,38.446215],[-120.827919,38.43844],[-120.8093,38.417984],[-120.825423,38.414855],[-120.846422,38.424995],[-120.878949,38.407386],[-120.819757,38.397314]]]},"properties":{"id":"95685","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.12207,38.668616],[-120.148329,38.638],[-120.072432,38.677804],[-120.077187,38.701026],[-120.12207,38.668616]]],[[[-120.440136,38.5288],[-120.620761,38.43226],[-120.614991,38.389201],[-120.490985,38.446286],[-120.370998,38.544613],[-120.440136,38.5288]]]]},"properties":{"id":"95666","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.843368,38.446215],[-120.890504,38.406585],[-120.878949,38.407386],[-120.846422,38.424995],[-120.827919,38.43844],[-120.843368,38.446215]]]},"properties":{"id":"95699","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.4676,39.555765],[-121.556726,39.444341],[-121.537713,39.442095],[-121.537364,39.427524],[-121.553968,39.426693],[-121.569302,39.308357],[-121.440497,39.352008],[-121.36612,39.382053],[-121.363493,39.403297],[-121.293863,39.47474],[-121.257696,39.477378],[-121.304273,39.549728],[-121.298599,39.556275],[-121.297091,39.614138],[-121.4676,39.555765]]]},"properties":{"id":"95966","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.4676,39.555765],[-121.402187,39.696175],[-121.557076,39.841216],[-121.559157,39.841073],[-121.571138,39.813844],[-121.712468,39.639222],[-121.856498,39.533582],[-121.875168,39.503862],[-121.79909,39.464563],[-121.645979,39.365828],[-121.63015,39.305668],[-121.62376,39.295621],[-121.569302,39.308357],[-121.553968,39.426693],[-121.556726,39.444341],[-121.4676,39.555765]]]},"properties":{"id":"95965","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.98651,40.00809],[-122.038795,39.883703],[-122.057905,39.822906],[-121.996739,39.751055],[-121.946615,39.725893],[-121.881856,39.731052],[-121.853826,39.761262],[-121.792488,39.761883],[-121.708567,39.855395],[-121.670861,39.945059],[-121.98651,40.00809]]]},"properties":{"id":"95973","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.610552,38.371981],[-120.547117,38.369094],[-120.532239,38.36558],[-120.453708,38.39113],[-120.490985,38.446286],[-120.614991,38.389201],[-120.610552,38.371981]]]},"properties":{"id":"95255","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.453708,38.39113],[-120.532239,38.36558],[-120.421721,38.355124],[-120.453708,38.39113]]]},"properties":{"id":"95257","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.463617,38.209314],[-120.45085,38.202722],[-120.39723,38.258598],[-120.396751,38.271273],[-120.463617,38.209314]]]},"properties":{"id":"95250","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.971186,38.167979],[-120.926878,38.194812],[-120.90579,38.197558],[-120.987602,38.207456],[-120.975206,38.177886],[-120.971186,38.167979]]]},"properties":{"id":"95254","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.116084,39.217913],[-122.396594,39.219089],[-122.479527,39.158038],[-122.489507,39.06323],[-122.449211,39.031587],[-122.340172,38.924246],[-122.261474,38.924059],[-122.060475,39.195587],[-122.116084,39.217913]]]},"properties":{"id":"95987","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.871569,37.749456],[-121.948725,37.795786],[-121.964458,37.781461],[-121.917372,37.728955],[-121.870733,37.740086],[-121.871569,37.749456]]]},"properties":{"id":"94582","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.981603,37.976618],[-122.009362,37.970005],[-121.996991,37.942412],[-121.977171,37.914723],[-121.914615,37.954986],[-121.883312,37.962424],[-121.932005,37.983299],[-121.981603,37.976618]]]},"properties":{"id":"94521","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.972347,37.861161],[-121.965377,37.843433],[-121.967251,37.835309],[-121.921069,37.843025],[-121.933485,37.85235],[-121.972347,37.861161]]]},"properties":{"id":"94528","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.112865,37.843659],[-122.113955,37.837896],[-122.109468,37.837916],[-122.105938,37.842946],[-122.112865,37.843659]]]},"properties":{"id":"94575","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.075391,41.499845],[-123.977851,41.509918],[-123.994149,41.633582],[-124.127539,41.595705],[-124.075391,41.499845]]]},"properties":{"id":"95548","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.644084,38.777757],[-120.638843,38.711588],[-120.596498,38.687488],[-120.524497,38.724374],[-120.644084,38.777757]]]},"properties":{"id":"95726","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.699713,38.773008],[-120.638843,38.711588],[-120.644084,38.777757],[-120.699713,38.773008]]]},"properties":{"id":"95709","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.42063,38.680058],[-120.370998,38.544613],[-120.214632,38.628869],[-120.246515,38.654969],[-120.42063,38.680058]]]},"properties":{"id":"95636","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.825732,38.648257],[-120.840671,38.690733],[-120.887343,38.674165],[-120.943563,38.527524],[-120.820936,38.559469],[-120.778529,38.592363],[-120.825732,38.648257]]]},"properties":{"id":"95623","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.155363,38.878494],[-120.246515,38.654969],[-120.214632,38.628869],[-120.148329,38.638],[-120.12207,38.668616],[-120.11554,38.772089],[-120.155363,38.878494]]]},"properties":{"id":"95720","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.155363,38.878494],[-120.11554,38.772089],[-120.116192,38.803103],[-120.117277,38.857285],[-120.147341,38.878373],[-120.155363,38.878494]]]},"properties":{"id":"95735","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.530275,36.575828],[-119.609765,36.461348],[-119.609057,36.433987],[-119.475033,36.390478],[-119.39484,36.443978],[-119.484525,36.53571],[-119.502594,36.575563],[-119.530275,36.575828]],[[-119.490472,36.45236],[-119.474515,36.458963],[-119.474634,36.444477],[-119.490472,36.45236]]]},"properties":{"id":"93631","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.544427,37.048208],[-119.467308,36.967592],[-119.490325,37.038606],[-119.544427,37.048208]]]},"properties":{"id":"93651","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.544427,37.048208],[-119.490325,37.038606],[-119.121526,36.902773],[-119.066905,37.004753],[-119.360678,37.169613],[-119.563784,37.13832],[-119.549413,37.09402],[-119.544427,37.048208]]]},"properties":{"id":"93602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.812931,36.807206],[-119.801883,36.837051],[-119.790649,36.846153],[-119.792635,36.876264],[-119.866335,36.847998],[-119.844326,36.800749],[-119.812931,36.807206]]]},"properties":{"id":"93711","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.932551,36.84299],[-119.880228,36.750044],[-119.844277,36.746414],[-119.840311,36.764674],[-119.844326,36.800749],[-119.866335,36.847998],[-119.932338,36.843323],[-119.932551,36.84299]]]},"properties":{"id":"93722","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.730259,36.934337],[-119.549413,37.09402],[-119.563784,37.13832],[-119.564323,37.144642],[-119.747466,37.098233],[-119.781078,37.039347],[-119.747201,36.938428],[-119.730259,36.934337]]]},"properties":{"id":"93626","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.477822,39.701338],[-122.625546,39.711787],[-122.649951,39.384839],[-122.417767,39.383975],[-122.442784,39.637082],[-122.399106,39.649319],[-122.477822,39.701338]]]},"properties":{"id":"95939","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.994801,39.457316],[-122.007833,39.390094],[-121.890013,39.383864],[-121.870261,39.463984],[-121.875168,39.503862],[-121.856498,39.533582],[-121.905994,39.536693],[-121.992528,39.533792],[-121.994801,39.457316]]]},"properties":{"id":"95920","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.751148,40.383235],[-123.637229,40.374092],[-123.544356,40.451552],[-123.544266,40.498684],[-123.813323,40.549534],[-123.828494,40.52964],[-123.83945,40.403419],[-123.751148,40.383235]]]},"properties":{"id":"95526","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.04279,40.20985],[-124.119834,40.172734],[-124.176367,40.127521],[-123.838637,39.736734],[-123.828451,39.842155],[-123.841201,40.001753],[-123.891718,40.11047],[-123.906972,40.157439],[-123.976195,40.260483],[-124.04279,40.20985]]]},"properties":{"id":"95589","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.219751,40.722563],[-124.21565,40.722634],[-124.214231,40.73115],[-124.216672,40.730912],[-124.21935,40.725682],[-124.223241,40.72355],[-124.219751,40.722563]]]},"properties":{"id":"95537","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.500854,33.252384],[-116.516201,33.167489],[-116.10319,32.878928],[-115.869794,32.857807],[-115.697175,32.963132],[-115.853808,33.122874],[-116.000835,33.234878],[-116.085148,33.339216],[-116.501119,33.286415],[-116.500854,33.252384]]]},"properties":{"id":"92004","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.685744,32.832925],[-115.715991,32.791063],[-115.690422,32.796582],[-115.685744,32.832925]]]},"properties":{"id":"92273","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.304303,37.187637],[-117.934852,37.464632],[-117.923791,37.530259],[-118.435178,37.901289],[-118.602991,37.856898],[-118.715723,37.463092],[-118.775014,37.463052],[-118.591086,37.136589],[-118.530932,37.11118],[-118.304303,37.187637]]]},"properties":{"id":"93514","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.138954,36.160858],[-116.290038,35.902962],[-116.063714,36.094524],[-116.129825,36.162745],[-116.138954,36.160858]]]},"properties":{"id":"92384","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.901358,35.962796],[-115.894957,35.959163],[-115.894957,35.971895],[-115.899454,35.97189],[-115.897273,35.966123],[-115.901358,35.962796]]]},"properties":{"id":"89061","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.305559,35.795166],[-117.208351,35.903651],[-117.333594,35.980178],[-117.35988,35.795191],[-117.305559,35.795166]]]},"properties":{"id":"93592","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.115737,35.35612],[-119.092152,35.318242],[-119.036374,35.317682],[-119.043223,35.371095],[-119.044604,35.379006],[-119.091762,35.363872],[-119.115737,35.35612]]]},"properties":{"id":"93309","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.092152,35.318242],[-119.113803,35.065084],[-118.95817,35.02656],[-119.030652,35.295818],[-119.036374,35.317682],[-119.092152,35.318242]]]},"properties":{"id":"93313","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.606973,35.571956],[-119.341235,35.3254],[-119.331654,35.441942],[-119.448018,35.500528],[-119.606973,35.571956]]]},"properties":{"id":"93206","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.740794,39.025673],[-122.540661,38.988969],[-122.449211,39.031587],[-122.489507,39.06323],[-122.651074,39.142142],[-122.74533,39.068526],[-122.735137,39.026948],[-122.742839,39.026863],[-122.740794,39.025673]]]},"properties":{"id":"95423","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.480439,38.988983],[-122.710646,38.980025],[-122.697791,38.896698],[-122.575938,38.800058],[-122.543655,38.826236],[-122.415789,38.768002],[-122.395056,38.864245],[-122.403941,38.925289],[-122.480439,38.988983]]]},"properties":{"id":"95457","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.166353,40.782107],[-120.347743,40.955809],[-120.642218,41.07841],[-120.749806,41.051749],[-120.596235,40.764164],[-120.479082,40.764288],[-120.166353,40.782107]]]},"properties":{"id":"96132","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.269007,40.144985],[-120.436029,40.239004],[-120.460673,40.205777],[-120.301733,40.114843],[-120.269007,40.144985]]]},"properties":{"id":"96121","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.488375,40.353922],[-120.370455,40.318152],[-120.323872,40.35185],[-120.521289,40.379282],[-120.488375,40.353922]]]},"properties":{"id":"96128","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.077214,34.103491],[-118.068047,34.120399],[-118.069319,34.130649],[-118.090987,34.12684],[-118.121095,34.105275],[-118.112989,34.101707],[-118.077214,34.103491]]]},"properties":{"id":"91775","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.64127,34.157924],[-118.602128,34.139446],[-118.565316,34.130383],[-118.564017,34.131147],[-118.562201,34.173325],[-118.64127,34.157924]]]},"properties":{"id":"91364","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.265232,33.885977],[-118.263334,33.839474],[-118.236774,33.839231],[-118.233976,33.846646],[-118.265232,33.885977]],[[-118.252318,33.863344],[-118.251085,33.863196],[-118.250483,33.862514],[-118.252176,33.862515],[-118.252318,33.863344]]]},"properties":{"id":"90746","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.282708,33.916422],[-118.263149,33.902376],[-118.265158,33.938348],[-118.282594,33.939181],[-118.282708,33.916422]]]},"properties":{"id":"90061","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.958214,34.01979],[-117.998071,34.02957],[-118.002474,33.989289],[-117.98782,33.974515],[-117.976652,33.975091],[-117.942232,33.974297],[-117.94985,34.011138],[-117.958214,34.01979]]]},"properties":{"id":"91745","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300193,33.989109],[-118.300379,33.91741],[-118.285945,33.916422],[-118.282708,33.916422],[-118.282594,33.939181],[-118.283204,33.989139],[-118.300193,33.989109]]]},"properties":{"id":"90044","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.139138,33.812658],[-118.077412,33.8104],[-118.063162,33.81961],[-118.064955,33.824018],[-118.081812,33.83126],[-118.093309,33.831712],[-118.125075,33.847055],[-118.154103,33.827552],[-118.139138,33.812658]]]},"properties":{"id":"90808","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.227876,34.083155],[-118.243823,34.095615],[-118.232118,34.085961],[-118.227876,34.083155]]],[[[-118.200556,34.101324],[-118.227235,34.081218],[-118.226747,34.062246],[-118.197518,34.064659],[-118.19064,34.096707],[-118.200556,34.101324]]]]},"properties":{"id":"90031","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.390298,34.057085],[-118.376174,34.059462],[-118.390295,34.072082],[-118.389672,34.06705],[-118.390298,34.057085]]]},"properties":{"id":"90211","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.390295,34.072082],[-118.376174,34.059462],[-118.364894,34.058305],[-118.363818,34.058183],[-118.361417,34.083811],[-118.370302,34.08368],[-118.389725,34.080759],[-118.390295,34.072082]]]},"properties":{"id":"90048","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.267932,34.060871],[-118.284036,34.072629],[-118.284334,34.068528],[-118.284302,34.063715],[-118.284238,34.055845],[-118.275461,34.05188],[-118.267932,34.060871]]]},"properties":{"id":"90057","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.361417,34.083811],[-118.363818,34.058183],[-118.338167,34.058981],[-118.337054,34.060564],[-118.337149,34.064928],[-118.337348,34.068911],[-118.338583,34.083457],[-118.345149,34.083455],[-118.361417,34.083811]]]},"properties":{"id":"90036","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.298169,33.812984],[-118.308254,33.809014],[-118.309486,33.779053],[-118.297492,33.78208],[-118.296128,33.781299],[-118.286737,33.797818],[-118.287508,33.808959],[-118.298169,33.812984]]]},"properties":{"id":"90710","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.327798,33.78613],[-118.318276,33.775218],[-118.309135,33.776486],[-118.309486,33.779053],[-118.308254,33.809014],[-118.327941,33.806862],[-118.327798,33.78613]]]},"properties":{"id":"90717","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.055385,33.887789],[-118.028888,33.882629],[-118.036519,33.917],[-118.047142,33.95572],[-118.050863,33.95887],[-118.088827,33.966758],[-118.093611,33.95046],[-118.09847,33.944137],[-118.089953,33.93167],[-118.055385,33.887789]]]},"properties":{"id":"90670","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.14599,34.145816],[-118.1466,34.135795],[-118.13246,34.157759],[-118.14605,34.157834],[-118.14599,34.145816]]]},"properties":{"id":"91101","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.220268,34.20503],[-118.207998,34.188166],[-118.189103,34.177312],[-118.167787,34.201583],[-118.223395,34.267244],[-118.238903,34.267084],[-118.231373,34.218977],[-118.220268,34.20503]]]},"properties":{"id":"91011","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.469438,34.162771],[-118.480521,34.129853],[-118.475671,34.126346],[-118.443396,34.130308],[-118.4487,34.164855],[-118.469485,34.164823],[-118.469438,34.162771]]]},"properties":{"id":"91403","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.276954,34.180209],[-118.255147,34.158783],[-118.255148,34.158701],[-118.233064,34.165664],[-118.271548,34.20801],[-118.288282,34.217792],[-118.27996,34.190456],[-118.276954,34.180209]]]},"properties":{"id":"91207","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.387341,34.256672],[-118.411297,34.289803],[-118.443266,34.266618],[-118.450538,34.250619],[-118.420324,34.22564],[-118.387341,34.256672]]]},"properties":{"id":"91331","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.531719,34.244565],[-118.527426,34.257266],[-118.536096,34.257266],[-118.531719,34.244565]]],[[[-118.531719,34.244565],[-118.536079,34.220727],[-118.518596,34.208401],[-118.502667,34.221208],[-118.502369,34.253888],[-118.523323,34.257339],[-118.531719,34.244565]]]]},"properties":{"id":"91325","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.331254,33.881368],[-118.326488,33.882821],[-118.334864,33.887423],[-118.331254,33.881368]]]},"properties":{"id":"90506","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.443396,34.130308],[-118.475671,34.126346],[-118.454658,34.076543],[-118.445241,34.073811],[-118.439421,34.078246],[-118.425974,34.080835],[-118.442158,34.130898],[-118.443396,34.130308]]]},"properties":{"id":"90077","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.301,33.757721],[-118.328345,33.709506],[-118.244627,33.710767],[-118.240387,33.767706],[-118.296128,33.781299],[-118.297492,33.78208],[-118.302001,33.760511],[-118.301,33.757721]]]},"properties":{"id":"90731","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.443945,34.017093],[-118.455804,34.009636],[-118.445338,33.994206],[-118.432259,33.975016],[-118.432009,33.974636],[-118.409086,33.981247],[-118.418235,34.012109],[-118.429918,34.026861],[-118.443945,34.017093]]]},"properties":{"id":"90066","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300165,34.018177],[-118.274009,34.013057],[-118.264679,34.030991],[-118.284102,34.039855],[-118.300349,34.039969],[-118.300165,34.018177]],[[-118.280329,34.017493],[-118.291535,34.018157],[-118.291538,34.025497],[-118.280224,34.021961],[-118.280329,34.017493]]]},"properties":{"id":"90007","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.204255,33.831815],[-118.176302,33.811647],[-118.161798,33.811664],[-118.139138,33.812658],[-118.154103,33.827552],[-118.167643,33.850934],[-118.204215,33.839699],[-118.204255,33.831815]]]},"properties":{"id":"90807","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.265185,33.989227],[-118.243139,33.989443],[-118.242772,34.020595],[-118.257437,34.027631],[-118.264679,34.030991],[-118.274009,34.013057],[-118.273928,33.989191],[-118.265185,33.989227]]]},"properties":{"id":"90011","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.169256,34.061489],[-118.192638,34.063153],[-118.206036,34.034909],[-118.173889,34.03335],[-118.169273,34.050737],[-118.169256,34.061489]]]},"properties":{"id":"90063","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.411297,34.289803],[-118.387341,34.256672],[-118.375225,34.256954],[-118.316871,34.286083],[-118.304399,34.36203],[-118.397893,34.353672],[-118.508341,34.33389],[-118.46798,34.291621],[-118.455848,34.280274],[-118.411297,34.289803]]]},"properties":{"id":"91342","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.304399,34.36203],[-118.316871,34.286083],[-118.322382,34.249631],[-118.290771,34.234821],[-118.272473,34.232527],[-118.238903,34.267084],[-118.223395,34.267244],[-118.178914,34.296044],[-118.206412,34.376765],[-118.25269,34.387872],[-118.304399,34.36203]]]},"properties":{"id":"91042","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.000107,34.369574],[-117.895552,34.557944],[-117.889527,34.557965],[-117.96942,34.579729],[-118.022683,34.470113],[-118.000107,34.369574]]]},"properties":{"id":"93543","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.859977,34.470912],[-117.895552,34.557944],[-118.000107,34.369574],[-117.979035,34.338068],[-117.820295,34.345263],[-117.859977,34.470912]]]},"properties":{"id":"93553","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.173987,34.017399],[-118.174402,34.001574],[-118.175455,33.998347],[-118.169347,33.986619],[-118.167058,33.97951],[-118.129423,33.970934],[-118.121307,33.97299],[-118.127095,34.00721],[-118.173987,34.017399]]]},"properties":{"id":"90040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.068047,34.120399],[-118.030768,34.107182],[-118.067784,34.148867],[-118.069319,34.130649],[-118.068047,34.120399]]]},"properties":{"id":"91007","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.025372,33.948669],[-118.033851,33.916999],[-117.976495,33.917378],[-117.976536,33.928166],[-118.025372,33.948669]]]},"properties":{"id":"90604","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.088827,33.966758],[-118.063773,34.004281],[-118.054653,34.019901],[-118.083622,34.020136],[-118.121307,33.97299],[-118.093611,33.95046],[-118.088827,33.966758]]]},"properties":{"id":"90660","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.924336,33.924613],[-117.919932,33.945958],[-117.942232,33.974297],[-117.976652,33.975091],[-117.983412,33.965],[-117.976536,33.928166],[-117.976495,33.917378],[-117.976586,33.906225],[-117.95755,33.906465],[-117.924336,33.924613]]]},"properties":{"id":"90631","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.531719,34.244565],[-118.523323,34.257339],[-118.527426,34.257266],[-118.531719,34.244565]]]},"properties":{"id":"91330","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.254943,34.041599],[-118.256716,34.040455],[-118.255845,34.039571],[-118.254093,34.040736],[-118.254943,34.041599]]]},"properties":{"id":"90079","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.239986,34.21145],[-118.241705,34.212512],[-118.24242,34.211737],[-118.239978,34.210339],[-118.239986,34.21145]]]},"properties":{"id":"91046","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.688205,37.44117],[-119.802749,37.384483],[-119.745957,37.349967],[-119.656226,37.417818],[-119.688205,37.44117]]]},"properties":{"id":"93601","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.698272,38.140042],[-122.603717,38.076063],[-122.546108,38.08008],[-122.617092,38.150507],[-122.698272,38.140042]]]},"properties":{"id":"94947","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.942723,38.248776],[-122.912817,38.232749],[-122.807629,38.141206],[-122.776158,38.12051],[-122.698272,38.140042],[-122.617092,38.150507],[-122.545376,38.158733],[-122.678864,38.278785],[-122.708336,38.303632],[-122.749855,38.33297],[-122.91632,38.349518],[-122.919785,38.317222],[-122.942723,38.248776]]]},"properties":{"id":"94952","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.599068,37.551052],[-120.631391,37.443775],[-120.538869,37.468471],[-120.437468,37.461502],[-120.283996,37.462896],[-120.319197,37.521335],[-120.288578,37.649628],[-120.294547,37.648865],[-120.521517,37.581487],[-120.599068,37.551052]]]},"properties":{"id":"95369","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.791149,37.677372],[-119.853345,37.73857],[-119.901415,37.669499],[-119.822069,37.609968],[-119.700329,37.579244],[-119.791149,37.677372]]]},"properties":{"id":"95318","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.283996,37.462896],[-120.278451,37.419639],[-120.17539,37.497318],[-120.319197,37.521335],[-120.283996,37.462896]]]},"properties":{"id":"95325","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.741555,38.96067],[-123.478381,38.917356],[-123.45664,38.914314],[-123.43504,38.993863],[-123.44486,38.993984],[-123.611491,39.025254],[-123.712169,39.091487],[-123.741555,38.96067]]]},"properties":{"id":"95459","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.751413,37.264176],[-120.712363,37.248933],[-120.668249,37.374816],[-120.667734,37.411191],[-120.667711,37.419354],[-120.731439,37.405701],[-120.805765,37.387557],[-120.850316,37.3622],[-120.751413,37.264176]]]},"properties":{"id":"95334","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.667662,37.42302],[-120.667734,37.411191],[-120.668249,37.374816],[-120.549311,37.382354],[-120.538869,37.468471],[-120.631391,37.443775],[-120.667662,37.42302]]]},"properties":{"id":"95388","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.656366,36.967232],[-120.653699,36.952823],[-120.637726,36.96741],[-120.656366,36.967232]]]},"properties":{"id":"93665","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.98324,37.376436],[-121.138182,37.39765],[-121.412549,37.389435],[-121.454138,37.273979],[-121.30833,37.168029],[-121.226804,37.134774],[-121.222507,37.138378],[-120.943852,37.29178],[-120.930182,37.310029],[-120.964223,37.345782],[-120.98324,37.376436]]]},"properties":{"id":"95360","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.907336,37.547541],[-120.985552,37.507479],[-120.958922,37.409504],[-120.831432,37.433097],[-120.721721,37.448273],[-120.739623,37.496022],[-120.790691,37.514477],[-120.907336,37.547541]]]},"properties":{"id":"95380","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.805765,37.387557],[-120.831432,37.433097],[-120.958922,37.409504],[-120.98324,37.376436],[-120.964223,37.345782],[-120.850316,37.3622],[-120.805765,37.387557]]]},"properties":{"id":"95324","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.737279,41.517835],[-121.169734,41.36305],[-121.025157,41.336258],[-120.932399,41.400842],[-120.737279,41.517835]]]},"properties":{"id":"96015","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.715723,37.463092],[-119.022363,37.585737],[-118.775014,37.463052],[-118.715723,37.463092]]]},"properties":{"id":"93546","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.497921,36.413597],[-121.20143,36.339696],[-121.199789,36.356456],[-121.095367,36.367994],[-121.295986,36.505209],[-121.356344,36.510719],[-121.46865,36.488168],[-121.56372,36.496799],[-121.497921,36.413597]]]},"properties":{"id":"93960","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.498118,36.514278],[-121.46865,36.488168],[-121.356344,36.510719],[-121.30115,36.5376],[-121.32353,36.602671],[-121.498118,36.514278]]]},"properties":{"id":"93926","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.74206,36.830255],[-121.790687,36.806429],[-121.806386,36.753315],[-121.782983,36.732053],[-121.760597,36.755979],[-121.717696,36.816595],[-121.74206,36.830255]]]},"properties":{"id":"95012","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.650003,36.625436],[-121.644852,36.62143],[-121.642029,36.626096],[-121.650003,36.625436]]]},"properties":{"id":"93962","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-122.211081,38.252215],[-122.306755,38.209041],[-122.204167,38.209699],[-122.211081,38.252215]]],[[[-122.378987,38.250714],[-122.201067,38.271974],[-122.064779,38.315912],[-122.111264,38.477108],[-122.164944,38.642462],[-122.202462,38.688976],[-122.258334,38.785532],[-122.287998,38.839931],[-122.344794,38.839423],[-122.31465,38.611904],[-122.412175,38.46459],[-122.416968,38.452478],[-122.433105,38.453343],[-122.479973,38.446391],[-122.497475,38.424306],[-122.453522,38.382392],[-122.378987,38.250714]],[[-122.380697,38.381358],[-122.385536,38.390423],[-122.367504,38.398215],[-122.371418,38.407414],[-122.357362,38.414061],[-122.354136,38.393348],[-122.380697,38.381358]]]]},"properties":{"id":"94558","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.521684,38.692016],[-122.696683,38.72101],[-122.686568,38.583769],[-122.569473,38.529659],[-122.488549,38.571781],[-122.487954,38.606672],[-122.521684,38.692016]]]},"properties":{"id":"94515","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.876911,33.802185],[-117.894463,33.796156],[-117.906241,33.790641],[-117.897433,33.774467],[-117.894732,33.774478],[-117.876019,33.778927],[-117.857359,33.777057],[-117.856348,33.795124],[-117.876911,33.802185]]]},"properties":{"id":"92868","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.744652,33.870569],[-117.734423,33.840761],[-117.711622,33.867013],[-117.744652,33.870569]]]},"properties":{"id":"92808","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.788718,33.669538],[-117.768297,33.690049],[-117.78222,33.710154],[-117.810182,33.682152],[-117.788718,33.669538]]]},"properties":{"id":"92604","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.768297,33.690049],[-117.788718,33.669538],[-117.794998,33.663279],[-117.778042,33.657588],[-117.758781,33.626908],[-117.746969,33.621193],[-117.7202,33.62828],[-117.689708,33.670974],[-117.72024,33.717746],[-117.768297,33.690049]]]},"properties":{"id":"92618","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.689708,33.670974],[-117.7202,33.62828],[-117.714735,33.622359],[-117.712747,33.620211],[-117.6945,33.606985],[-117.641853,33.660801],[-117.63763,33.66192],[-117.640465,33.663513],[-117.689708,33.670974]]]},"properties":{"id":"92630","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.746969,33.621193],[-117.714735,33.622359],[-117.7202,33.62828],[-117.746969,33.621193]]],[[[-117.712747,33.620211],[-117.716087,33.597294],[-117.70023,33.568878],[-117.671731,33.574169],[-117.6945,33.606985],[-117.712747,33.620211]]]]},"properties":{"id":"92653","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.797981,33.721677],[-117.79761,33.74005],[-117.830273,33.773257],[-117.830866,33.773261],[-117.83729,33.74204],[-117.843893,33.739588],[-117.83764,33.71126],[-117.797981,33.721677]]]},"properties":{"id":"92780","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.841984,33.610705],[-117.869241,33.584187],[-117.836738,33.564164],[-117.783883,33.595103],[-117.82804,33.62335],[-117.841984,33.610705]]]},"properties":{"id":"92657","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.122137,39.208006],[-120.14315,39.229937],[-120.1988,39.221911],[-120.242188,39.147922],[-120.198007,39.064371],[-120.157097,39.107204],[-120.085077,39.218065],[-120.122137,39.208006]]]},"properties":{"id":"96145","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.321631,39.043753],[-121.408953,39.001159],[-121.322163,38.955247],[-121.321631,39.043753]]]},"properties":{"id":"95681","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.571445,33.829602],[-116.492984,33.815881],[-116.493477,33.867515],[-116.505761,33.874782],[-116.523964,33.885735],[-116.608286,33.90125],[-116.632448,33.896315],[-116.571445,33.829602]]]},"properties":{"id":"92262","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.440755,33.836344],[-116.47703,33.759296],[-116.460047,33.713622],[-116.388418,33.772574],[-116.396713,33.810363],[-116.440755,33.836344]]]},"properties":{"id":"92270","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.303886,33.72901],[-116.321466,33.670679],[-116.28494,33.584027],[-116.233957,33.663884],[-116.233892,33.671036],[-116.266715,33.73801],[-116.303864,33.736466],[-116.303886,33.72901]]]},"properties":{"id":"92253","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.194694,33.719966],[-116.233892,33.671036],[-116.233957,33.663884],[-116.111858,33.673994],[-116.154176,33.714932],[-116.181536,33.747628],[-116.194694,33.719966]]]},"properties":{"id":"92236","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.208911,33.93932],[-117.208891,33.902826],[-117.208737,33.858941],[-117.135596,33.840501],[-117.06836,33.852965],[-117.003606,33.833634],[-117.009494,33.857049],[-116.990775,33.88337],[-117.050635,33.933768],[-117.173059,33.986014],[-117.210146,33.961215],[-117.208911,33.93932]]]},"properties":{"id":"92555","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.963246,33.514071],[-116.927965,33.427362],[-116.857095,33.426574],[-116.681777,33.42722],[-116.771413,33.591226],[-116.963246,33.514071]]]},"properties":{"id":"92536","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.685583,33.772663],[-116.773534,33.710094],[-116.809121,33.730042],[-116.741791,33.598921],[-116.518168,33.528947],[-116.407876,33.668765],[-116.443046,33.642025],[-116.512448,33.652124],[-116.685583,33.772663]]]},"properties":{"id":"92561","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.685583,33.772663],[-116.753488,33.828024],[-116.789184,33.813702],[-116.773534,33.710094],[-116.685583,33.772663]]]},"properties":{"id":"92549","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.313862,33.865746],[-117.342549,33.858041],[-117.368024,33.833537],[-117.440409,33.825485],[-117.450178,33.810417],[-117.393486,33.72988],[-117.363201,33.718316],[-117.280017,33.69742],[-117.228084,33.722818],[-117.223042,33.728524],[-117.199842,33.773297],[-117.261058,33.866223],[-117.313862,33.865746]]]},"properties":{"id":"92570","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-116.800228,33.90536],[-116.789184,33.813702],[-116.753488,33.828024],[-116.754513,33.885941],[-116.800228,33.90536]]],[[[-116.930157,34.004914],[-116.929209,33.886444],[-116.824501,33.928199],[-116.737249,33.932892],[-116.716364,34.033793],[-116.719663,34.033824],[-116.906145,34.034101],[-116.930157,34.004914]]]]},"properties":{"id":"92220","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.261058,33.866223],[-117.243838,33.859082],[-117.243573,33.902774],[-117.287357,33.916792],[-117.313862,33.865746],[-117.261058,33.866223]]]},"properties":{"id":"92518","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.193033,34.251511],[-116.227391,34.120569],[-115.089443,34.079783],[-115.05,34.22118],[-115.508753,34.519894],[-115.74343,34.47825],[-116.193033,34.251511]],[[-116.056166,34.237336],[-116.042284,34.217415],[-116.045948,34.21478],[-116.067099,34.238544],[-116.056166,34.237336]]]},"properties":{"id":"92277","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.411296,38.458132],[-121.465296,38.459788],[-121.486884,38.422933],[-121.483916,38.408739],[-121.362104,38.376308],[-121.41065,38.456781],[-121.411296,38.458132]]]},"properties":{"id":"95758","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.498306,38.276785],[-121.605425,38.285801],[-121.630825,38.291798],[-121.666985,38.177036],[-121.519519,38.203162],[-121.448142,38.255008],[-121.498306,38.276785]],[[-121.566358,38.239671],[-121.602763,38.230219],[-121.602004,38.248076],[-121.566358,38.239671]]]},"properties":{"id":"95690","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.498306,38.276785],[-121.501371,38.369421],[-121.521153,38.367408],[-121.605425,38.285801],[-121.498306,38.276785]]]},"properties":{"id":"95615","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.371749,38.452569],[-121.29755,38.452701],[-121.297884,38.519113],[-121.334934,38.529004],[-121.369864,38.522089],[-121.371749,38.452569]]]},"properties":{"id":"95829","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.355784,38.610128],[-121.345933,38.645476],[-121.375284,38.647397],[-121.406793,38.637239],[-121.413082,38.631453],[-121.41993,38.610926],[-121.378663,38.609673],[-121.355784,38.610128]]]},"properties":{"id":"95821","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.565586,38.685553],[-121.540523,38.736402],[-121.595049,38.749738],[-121.576068,38.648604],[-121.56012,38.656339],[-121.565586,38.685553]]]},"properties":{"id":"95837","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.142662,38.617013],[-121.241463,38.505559],[-121.211127,38.4547],[-121.048772,38.409808],[-121.02724,38.397686],[-121.027272,38.458034],[-121.027507,38.508292],[-121.055534,38.573153],[-121.0793,38.632857],[-121.142662,38.617013]]]},"properties":{"id":"95683","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.406993,36.656035],[-121.319415,36.606174],[-121.294247,36.720418],[-121.148544,36.853789],[-121.226804,37.134774],[-121.30833,37.168029],[-121.48106,37.118201],[-121.512065,36.94804],[-121.483002,36.76505],[-121.406993,36.656035]]]},"properties":{"id":"95023","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.287868,34.557113],[-117.322754,34.507828],[-117.346019,34.470462],[-117.255396,34.470945],[-117.253219,34.492842],[-117.278588,34.557105],[-117.287868,34.557113]]]},"properties":{"id":"92395","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.054295,34.450962],[-117.253219,34.492842],[-117.255396,34.470945],[-117.241476,34.350822],[-117.076531,34.367446],[-117.054295,34.450962]]]},"properties":{"id":"92308","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.481846,34.506908],[-117.659992,34.557782],[-117.660052,34.498629],[-117.579061,34.401732],[-117.602381,34.339182],[-117.569118,34.325941],[-117.510212,34.299769],[-117.465176,34.304597],[-117.437999,34.354128],[-117.450996,34.448351],[-117.481846,34.506908]]]},"properties":{"id":"92371","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.094568,34.211915],[-117.123869,34.257469],[-117.138743,34.231975],[-117.06824,34.221391],[-117.094568,34.211915]]]},"properties":{"id":"92382","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.481217,34.079809],[-114.435429,34.079727],[-114.229023,34.187427],[-114.222346,34.242923],[-114.48663,34.09412],[-114.481217,34.079809]]]},"properties":{"id":"92242","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.49829,35.15793],[-115.59781,34.92361],[-115.05949,34.82878],[-115.048193,35.062086],[-115.40252,35.1363],[-115.49829,35.15793]]]},"properties":{"id":"92332","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.71567,34.83169],[-116.831463,34.879915],[-116.927829,34.901548],[-116.935787,34.87205],[-116.71567,34.83169]]]},"properties":{"id":"92327","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.337332,34.237736],[-116.509818,34.296109],[-116.51116,34.118355],[-116.460306,34.075951],[-116.35251,34.075821],[-116.337332,34.237736]]]},"properties":{"id":"92284","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.299014,34.251586],[-116.665821,34.380636],[-116.771122,34.33988],[-116.682494,34.293432],[-116.509818,34.296109],[-116.337332,34.237736],[-116.299014,34.251586]]]},"properties":{"id":"92285","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.508753,34.519894],[-115.659202,34.563183],[-115.74343,34.47825],[-115.508753,34.519894]]]},"properties":{"id":"92304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.285178,34.121343],[-117.236471,34.123219],[-117.208773,34.162091],[-117.185147,34.187156],[-117.224432,34.222616],[-117.242667,34.225167],[-117.251292,34.228317],[-117.312704,34.223276],[-117.285028,34.164901],[-117.285178,34.121343]]]},"properties":{"id":"92404","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.238173,34.251424],[-117.215984,34.230691],[-117.21269,34.229151],[-117.181011,34.2443],[-117.163804,34.280504],[-117.238173,34.251424]]]},"properties":{"id":"92352","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.056166,34.237336],[-116.067099,34.238544],[-116.045948,34.21478],[-116.042284,34.217415],[-116.056166,34.237336]]]},"properties":{"id":"92278","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.166115,34.2301],[-117.181011,34.2443],[-117.21269,34.229151],[-117.224432,34.222616],[-117.185147,34.187156],[-117.166115,34.2301]]]},"properties":{"id":"92385","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.06674,32.934435],[-117.041048,32.926619],[-116.983729,32.933978],[-116.956189,32.94165],[-116.958254,32.962865],[-116.996636,33.052066],[-117.041596,33.04312],[-117.06674,32.934435]]]},"properties":{"id":"92064","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.104917,33.127044],[-117.069907,33.059509],[-117.071596,33.055914],[-117.041596,33.04312],[-116.996636,33.052066],[-116.920386,33.082833],[-117.070452,33.136083],[-117.104917,33.127044]]]},"properties":{"id":"92025","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.052901,32.853544],[-117.120147,32.84187],[-117.120988,32.841551],[-117.114217,32.788233],[-117.101482,32.79035],[-117.062761,32.821798],[-117.047323,32.833538],[-117.052901,32.853544]]]},"properties":{"id":"92124","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.049138,32.754755],[-117.050837,32.742041],[-117.012029,32.739966],[-116.964334,32.744947],[-116.944869,32.739567],[-116.945055,32.757161],[-116.988089,32.779118],[-117.049138,32.754755]]]},"properties":{"id":"91941","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.02134,32.688098],[-117.057359,32.663915],[-117.071364,32.654997],[-117.005055,32.648147],[-116.989744,32.663936],[-116.97348,32.672463],[-117.017158,32.691243],[-117.02134,32.688098]]]},"properties":{"id":"91902","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.928714,33.326765],[-117.015694,33.361317],[-117.02092,33.343047],[-116.841216,33.252111],[-116.823856,33.284536],[-116.928714,33.326765]]]},"properties":{"id":"92061","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.61919,32.974096],[-116.453755,33.103392],[-116.616071,33.152005],[-116.72141,33.0027],[-116.61919,32.974096]]]},"properties":{"id":"92036","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.326909,33.181191],[-117.360624,33.163783],[-117.315819,33.088186],[-117.31314,33.086974],[-117.266764,33.129532],[-117.326909,33.181191]]]},"properties":{"id":"92008","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.168744,32.675408],[-117.163953,32.669997],[-117.154581,32.677652],[-117.157356,32.680223],[-117.163015,32.67617],[-117.168744,32.675408]]]},"properties":{"id":"92155","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.256269,33.153309],[-117.231258,33.130865],[-117.207111,33.157806],[-117.288516,33.18381],[-117.256269,33.153309]]]},"properties":{"id":"92081","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.225688,32.7248],[-117.216942,32.723568],[-117.216855,32.727733],[-117.225688,32.7248]]]},"properties":{"id":"92147","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.471569,37.734707],[-122.506717,37.73547],[-122.498233,37.708132],[-122.469236,37.708232],[-122.468939,37.708232],[-122.462271,37.723021],[-122.471569,37.734707]]]},"properties":{"id":"94132","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.381681,37.783529],[-122.380014,37.753358],[-122.377981,37.753629],[-122.381681,37.783529]]]},"properties":{"id":"94158","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.426382,37.764646],[-122.40509,37.764628],[-122.397812,37.784667],[-122.403431,37.787642],[-122.404583,37.786731],[-122.426309,37.769603],[-122.426382,37.764646]]]},"properties":{"id":"94103","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.477308,37.765448],[-122.509833,37.763969],[-122.463361,37.753039],[-122.457536,37.763566],[-122.477308,37.765448]]]},"properties":{"id":"94122","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.277326,38.057416],[-121.281607,37.994453],[-121.25754,38.004798],[-121.150873,38.065672],[-121.13245,38.074264],[-121.277326,38.057416]]]},"properties":{"id":"95212","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.262989,37.826441],[-121.323007,37.864768],[-121.413801,37.80341],[-121.300428,37.779704],[-121.2533,37.816824],[-121.262989,37.826441]]]},"properties":{"id":"95330","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.805338,35.240698],[-120.841996,35.340806],[-120.865844,35.353858],[-120.805338,35.240698]]]},"properties":{"id":"93402","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.156311,34.72932],[-120.021022,34.836151],[-120.247518,35.137464],[-120.417631,35.081869],[-120.433036,34.985983],[-120.440557,34.920446],[-120.292192,34.764404],[-120.156311,34.72932]]]},"properties":{"id":"93454","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.329963,37.540999],[-122.330197,37.512587],[-122.276664,37.534223],[-122.293142,37.568005],[-122.302729,37.560357],[-122.329963,37.540999]]]},"properties":{"id":"94403","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.406484,37.297949],[-122.305681,37.283843],[-122.300418,37.361982],[-122.395944,37.345387],[-122.404891,37.311671],[-122.406484,37.297949]]]},"properties":{"id":"94074","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.294583,37.278333],[-122.299421,37.257222],[-122.260709,37.248561],[-122.294583,37.278333]]]},"properties":{"id":"94021","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.305681,37.283843],[-122.406484,37.297949],[-122.292952,37.107352],[-122.24266,37.190035],[-122.242473,37.215133],[-122.260709,37.248561],[-122.299421,37.257222],[-122.294583,37.278333],[-122.305681,37.283843]]]},"properties":{"id":"94060","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.400163,37.612646],[-122.37805,37.606801],[-122.391719,37.643952],[-122.403661,37.635538],[-122.400163,37.612646]]]},"properties":{"id":"94128","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.292192,34.764404],[-120.331358,34.714095],[-120.253964,34.686532],[-120.179152,34.710668],[-120.162567,34.724591],[-120.156311,34.72932],[-120.292192,34.764404]]]},"properties":{"id":"93440","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.789614,34.451349],[-119.786224,34.415602],[-119.755733,34.404741],[-119.755933,34.42237],[-119.789614,34.451349]]]},"properties":{"id":"93110","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.584472,34.428691],[-119.612172,34.420995],[-119.593483,34.418468],[-119.584472,34.428691]]]},"properties":{"id":"93067","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.560095,34.975559],[-120.602199,34.972749],[-120.649616,34.974841],[-120.549608,34.829385],[-120.543132,34.900795],[-120.558258,34.95741],[-120.560095,34.975559]]]},"properties":{"id":"93434","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.710752,34.435956],[-119.740162,34.413336],[-119.688566,34.411805],[-119.710752,34.435956]]]},"properties":{"id":"93101","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.995491,37.250381],[-121.935842,37.195357],[-121.913404,37.172141],[-121.903732,37.226181],[-121.903918,37.226543],[-121.94861,37.25786],[-121.990655,37.269106],[-121.995491,37.250381]]]},"properties":{"id":"95032","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.880698,37.368007],[-121.919394,37.372169],[-121.893293,37.339833],[-121.884891,37.328588],[-121.870558,37.313033],[-121.861178,37.303594],[-121.843983,37.31036],[-121.86826,37.32966],[-121.873826,37.359841],[-121.880698,37.368007]]]},"properties":{"id":"95112","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.925548,37.454389],[-121.915198,37.39611],[-121.887876,37.406952],[-121.831444,37.484241],[-121.855762,37.484537],[-121.917097,37.458473],[-121.925548,37.454389]]]},"properties":{"id":"95035","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.932957,37.337815],[-121.931669,37.297222],[-121.892193,37.323887],[-121.931845,37.351074],[-121.932957,37.337815]]]},"properties":{"id":"95126","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.893293,37.339833],[-121.892767,37.328814],[-121.884891,37.328588],[-121.893293,37.339833]]]},"properties":{"id":"95113","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.140956,37.395681],[-122.184078,37.334842],[-122.185458,37.323737],[-122.143823,37.31359],[-122.09525,37.332277],[-122.09601,37.38645],[-122.117852,37.40425],[-122.140956,37.395681]]]},"properties":{"id":"94022","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.848221,37.068195],[-121.937038,36.983889],[-121.939005,36.973473],[-121.869941,36.933889],[-121.84102,37.065063],[-121.848221,37.068195]]]},"properties":{"id":"95003","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.965518,36.982722],[-121.95046,36.967409],[-121.939005,36.973473],[-121.937038,36.983889],[-121.965518,36.982722]]]},"properties":{"id":"95010","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.322279,40.621899],[-121.506084,40.74653],[-121.542296,40.599592],[-121.322279,40.621899]]]},"properties":{"id":"96071","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.408277,42.000393],[-123.661363,41.38209],[-123.547441,41.382283],[-123.282973,41.468439],[-123.240893,41.49045],[-123.274949,41.695958],[-123.277369,41.741508],[-123.3546,41.741646],[-123.390792,41.784465],[-123.313868,41.802438],[-123.408277,42.000393]]]},"properties":{"id":"96039","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.151988,41.829165],[-122.876185,41.747159],[-122.821361,41.764062],[-122.616173,41.883195],[-122.661394,42.00476],[-123.102896,42.006827],[-123.151988,41.829165]]]},"properties":{"id":"96050","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.479311,41.538494],[-122.572326,41.368025],[-122.542454,41.349863],[-122.363488,41.388759],[-122.082646,41.610111],[-122.178378,41.751849],[-122.479311,41.538494]]]},"properties":{"id":"96094","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.904654,41.543568],[-122.935627,41.565886],[-122.96456,41.528627],[-122.904654,41.543568]]]},"properties":{"id":"96037","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.162843,38.101196],[-122.194805,38.056533],[-122.065808,38.107302],[-122.113885,38.170914],[-122.162843,38.101196]]]},"properties":{"id":"94510","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.102639,38.414093],[-123.002787,38.29571],[-122.971077,38.309942],[-122.98358,38.355432],[-123.102639,38.414093]]]},"properties":{"id":"94923","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.036577,38.486667],[-123.00225,38.458737],[-122.972423,38.461513],[-122.961612,38.510461],[-122.98714,38.526693],[-122.95459,38.534102],[-122.954027,38.540238],[-123.056505,38.576131],[-123.036577,38.486667]]]},"properties":{"id":"95446","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.497475,38.424306],[-122.565363,38.38672],[-122.56236,38.339269],[-122.453522,38.382392],[-122.497475,38.424306]],[[-122.520393,38.347356],[-122.518637,38.348592],[-122.51657,38.348673],[-122.518983,38.345656],[-122.520393,38.347356]]]},"properties":{"id":"95442","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.74128,38.352148],[-122.749855,38.33297],[-122.708336,38.303632],[-122.666688,38.314308],[-122.74128,38.352148]]]},"properties":{"id":"94931","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.858644,38.502387],[-122.954027,38.540238],[-122.95459,38.534102],[-122.961612,38.510461],[-122.972423,38.461513],[-122.851614,38.482013],[-122.842372,38.484576],[-122.858644,38.502387]]]},"properties":{"id":"95436","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.976175,37.696903],[-120.939067,37.654242],[-120.938724,37.616718],[-120.938345,37.616182],[-120.80167,37.61882],[-120.804291,37.682035],[-120.921914,37.719051],[-120.973832,37.735137],[-120.976175,37.696903]],[[-120.904271,37.638259],[-120.893265,37.638362],[-120.893078,37.6312],[-120.900872,37.62803],[-120.904271,37.638259]]]},"properties":{"id":"95357","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.053657,37.683127],[-120.976071,37.688884],[-120.976175,37.696903],[-120.973832,37.735137],[-120.96506,37.743771],[-121.068848,37.739528],[-121.094848,37.733328],[-121.053657,37.683127]]]},"properties":{"id":"95356","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.618132,39.182237],[-121.62376,39.295621],[-121.63015,39.305668],[-121.908269,39.303878],[-121.928332,39.198729],[-121.909402,39.190059],[-121.728635,39.167027],[-121.635161,39.181946],[-121.618132,39.182237]]]},"properties":{"id":"95953","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.728635,39.167027],[-121.909402,39.190059],[-121.880975,39.158027],[-121.768911,39.113217],[-121.728635,39.167027]]]},"properties":{"id":"95982","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.197272,35.99341],[-118.991149,35.90637],[-118.981935,35.790735],[-118.980684,35.790663],[-118.829066,35.790439],[-118.75714,35.790164],[-118.732385,35.861842],[-118.613712,35.951421],[-118.648106,36.096749],[-118.893831,36.114406],[-119.196593,36.116442],[-119.196722,36.080166],[-119.214629,36.015041],[-119.197272,35.99341]],[[-119.143098,36.051245],[-119.161061,36.051261],[-119.154555,36.058482],[-119.143787,36.058565],[-119.143098,36.051245]]]},"properties":{"id":"93257","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.187953,36.210764],[-118.966453,36.225614],[-119.181251,36.224906],[-119.187953,36.210764]]]},"properties":{"id":"93247","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.732385,35.861842],[-118.593953,35.841994],[-118.548524,35.853555],[-118.566223,35.963657],[-118.613712,35.951421],[-118.732385,35.861842]]]},"properties":{"id":"93207","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.168424,38.093471],[-120.186277,38.042703],[-120.156482,38.053384],[-120.168424,38.093471]]]},"properties":{"id":"95346","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.950532,34.267403],[-118.865911,34.246112],[-118.840025,34.241557],[-118.828464,34.249932],[-118.811858,34.290852],[-118.825995,34.358696],[-118.955226,34.335681],[-118.950532,34.267403]]]},"properties":{"id":"93021","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.929848,34.21431],[-119.00381,34.148502],[-118.97081,34.139827],[-118.885558,34.180439],[-118.929848,34.21431]]]},"properties":{"id":"91320","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.929848,34.21431],[-118.885558,34.180439],[-118.860951,34.17612],[-118.840025,34.241557],[-118.865911,34.246112],[-118.929848,34.21431]]]},"properties":{"id":"91360","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.250144,34.196848],[-119.218609,34.14426],[-119.194975,34.180753],[-119.19485,34.190078],[-119.236036,34.197729],[-119.250144,34.196848]]]},"properties":{"id":"93035","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.093042,38.925651],[-122.043422,38.831715],[-121.909957,38.867114],[-121.915313,38.925348],[-122.093042,38.925651]]]},"properties":{"id":"95937","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.073893,37.616684],[-122.013906,37.631621],[-122.012226,37.632891],[-122.074749,37.666626],[-122.10161,37.656422],[-122.073893,37.616684]]]},"properties":{"id":"94544","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.073893,37.616684],[-122.10161,37.656422],[-122.126254,37.664274],[-122.162676,37.667297],[-122.166947,37.666758],[-122.135769,37.558852],[-122.089615,37.562107],[-122.073893,37.616684]]]},"properties":{"id":"94545","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.253016,37.833557],[-122.219317,37.858475],[-122.257767,37.853081],[-122.253016,37.833557]]]},"properties":{"id":"94618","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.032697,38.729776],[-120.06762,38.706015],[-120.03337,38.696962],[-119.904315,38.933324],[-120.032697,38.729776]]]},"properties":{"id":"96120","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.702125,38.40971],[-120.631047,38.340741],[-120.610552,38.371981],[-120.614991,38.389201],[-120.620761,38.43226],[-120.644053,38.439649],[-120.702125,38.40971]]]},"properties":{"id":"95665","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.304273,39.549728],[-121.257696,39.477378],[-121.243399,39.483381],[-121.208215,39.527196],[-121.304273,39.549728]]]},"properties":{"id":"95941","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.485283,38.093381],[-120.453965,38.038208],[-120.424118,38.075893],[-120.485283,38.093381]]]},"properties":{"id":"95251","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.86351,37.967598],[-121.827667,38.02792],[-121.862462,38.06603],[-122.003691,38.013496],[-121.985202,38.012908],[-121.932005,37.983299],[-121.883312,37.962424],[-121.86351,37.967598]]]},"properties":{"id":"94565","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.028197,37.975324],[-122.009362,37.970005],[-121.981603,37.976618],[-121.999794,38.002978],[-122.028197,37.975324]]]},"properties":{"id":"94519","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.840671,38.690733],[-120.792986,38.703574],[-120.825732,38.648257],[-120.778529,38.592363],[-120.596498,38.687488],[-120.638843,38.711588],[-120.699713,38.773008],[-120.692824,38.866989],[-120.744221,38.872565],[-120.890658,38.801481],[-120.980881,38.795243],[-121.007507,38.763748],[-120.938545,38.724421],[-120.887343,38.674165],[-120.840671,38.690733]]]},"properties":{"id":"95667","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.904315,38.933324],[-120.076995,38.965765],[-120.150317,38.932128],[-120.147341,38.878373],[-120.117277,38.857285],[-120.04084,38.823465],[-120.032697,38.729776],[-119.904315,38.933324]]]},"properties":{"id":"96150","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.117277,38.857285],[-120.116192,38.803103],[-120.04084,38.823465],[-120.117277,38.857285]]]},"properties":{"id":"95721","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.085518,36.284301],[-119.959054,36.313566],[-119.959229,36.386318],[-120.031298,36.433722],[-120.157907,36.487274],[-120.157118,36.429491],[-120.211154,36.283536],[-120.085518,36.284301]]]},"properties":{"id":"93624","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.622568,36.780662],[-119.663953,36.70018],[-119.636979,36.685154],[-119.546984,36.663164],[-119.499523,36.653188],[-119.299003,36.723251],[-119.249282,36.824959],[-119.250998,36.88487],[-119.421901,36.909123],[-119.622568,36.780662]]]},"properties":{"id":"93657","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.790226,36.837252],[-119.729551,36.837591],[-119.729717,36.852152],[-119.729889,36.88142],[-119.788734,36.892559],[-119.792635,36.876264],[-119.790649,36.846153],[-119.790226,36.837252]]]},"properties":{"id":"93720","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.236331,37.203634],[-119.248158,37.211062],[-119.254222,37.197434],[-119.236331,37.203634]]]},"properties":{"id":"93605","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.120705,40.643037],[-124.202769,40.61448],[-124.151758,40.511112],[-124.094008,40.505796],[-124.087893,40.511964],[-124.075941,40.525843],[-124.094173,40.572317],[-124.120705,40.643037]]]},"properties":{"id":"95540","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.72379,40.332988],[-123.683597,40.239785],[-123.556241,40.248395],[-123.637229,40.374092],[-123.751148,40.383235],[-123.72379,40.332988]]]},"properties":{"id":"95514","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.19589,40.455921],[-124.062675,40.419624],[-124.094008,40.505796],[-124.151758,40.511112],[-124.19589,40.455921]]]},"properties":{"id":"95562","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.913454,40.321527],[-123.927978,40.329518],[-123.923853,40.316932],[-123.913603,40.30931],[-123.913454,40.321527]]]},"properties":{"id":"95571","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.562596,40.94971],[-123.653438,41.013057],[-123.7494,40.991891],[-123.77328,40.930051],[-123.698977,40.816086],[-123.602001,40.889287],[-123.562596,40.94971]]]},"properties":{"id":"95573","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.234044,40.76076],[-124.17002,40.821548],[-124.082495,40.831192],[-124.004419,40.792456],[-123.89014,40.760203],[-123.98674,40.85713],[-124.024363,40.897707],[-124.132808,40.956031],[-124.244378,40.769515],[-124.234044,40.76076]]]},"properties":{"id":"95521","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.547441,41.382283],[-123.661363,41.38209],[-123.66959,41.194976],[-123.587246,41.191776],[-123.44232,41.254146],[-123.493634,41.377464],[-123.547441,41.382283]]]},"properties":{"id":"95556","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.053576,40.816505],[-123.990937,40.618465],[-123.840204,40.55425],[-123.828494,40.52964],[-123.813323,40.549534],[-123.766959,40.648748],[-123.89014,40.760203],[-124.004419,40.792456],[-124.053576,40.816505]]]},"properties":{"id":"95549","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.542509,32.709185],[-115.413689,32.708273],[-115.447986,32.752021],[-115.542509,32.709185]]]},"properties":{"id":"92249","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.754944,33.156409],[-115.352445,33.152865],[-115.720795,33.201744],[-115.754944,33.156409]]]},"properties":{"id":"92233","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.774125,32.776482],[-115.869794,32.857807],[-116.10319,32.878928],[-116.105699,32.696877],[-116.10618,32.618592],[-115.662226,32.652337],[-115.690918,32.715775],[-115.774125,32.776482]]]},"properties":{"id":"92259","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.739185,33.433711],[-114.657806,33.412958],[-114.435429,34.079727],[-114.481217,34.079809],[-114.548453,34.079723],[-114.739185,33.433711]]]},"properties":{"id":"92225","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.892447,36.496758],[-117.887225,36.491208],[-117.870679,36.489113],[-117.892447,36.496758]]]},"properties":{"id":"93530","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.002918,35.372314],[-119.005283,35.401867],[-119.044604,35.379006],[-119.043223,35.371095],[-119.002918,35.372314]]]},"properties":{"id":"93301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.043223,35.371095],[-119.036374,35.317682],[-119.030652,35.295818],[-119.002936,35.368427],[-119.002918,35.372314],[-119.043223,35.371095]]]},"properties":{"id":"93304","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.005527,35.296113],[-118.039076,35.18412],[-118.003548,35.184161],[-117.968005,35.228188],[-118.005527,35.296113]]],[[[-118.255211,35.15449],[-118.363181,35.0487],[-118.310259,34.994295],[-118.255211,35.15449]]]]},"properties":{"id":"93501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.005527,35.296113],[-117.968005,35.228188],[-117.843501,35.360697],[-118.005527,35.296113]]]},"properties":{"id":"93519","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.932139,35.256951],[-118.923337,35.237832],[-118.896293,35.237889],[-118.905269,35.267026],[-118.932139,35.256951]]]},"properties":{"id":"93241","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.3802,35.667781],[-118.358566,35.503353],[-118.412606,35.425149],[-118.270334,35.371076],[-118.16551,35.556896],[-118.195464,35.790689],[-118.316694,35.790323],[-118.3802,35.667781]]]},"properties":{"id":"93283","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.668041,35.390609],[-117.843501,35.360697],[-117.647488,35.3618],[-117.668041,35.390609]]]},"properties":{"id":"93554","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.952646,35.009186],[-118.949868,34.897757],[-118.847789,34.721368],[-118.820915,34.665851],[-118.752972,34.649295],[-118.677055,34.708887],[-118.660972,34.783174],[-118.690753,34.818095],[-118.940947,34.972293],[-118.945395,34.986008],[-118.952646,35.009186]]]},"properties":{"id":"93243","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.916864,34.920532],[-117.895566,34.940003],[-117.915945,34.942916],[-117.916864,34.920532]]]},"properties":{"id":"93524","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.769368,36.38648],[-119.718406,36.313629],[-119.702996,36.321864],[-119.70202,36.305729],[-119.715293,36.307308],[-119.74439,36.189244],[-119.744311,36.142041],[-119.644355,36.108873],[-119.53638,36.137893],[-119.474607,36.269025],[-119.475364,36.327597],[-119.475033,36.390478],[-119.609057,36.433987],[-119.769368,36.38648]]]},"properties":{"id":"93230","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.74533,39.068526],[-122.737005,39.02848],[-122.735137,39.026948],[-122.74533,39.068526]]]},"properties":{"id":"95443","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.019697,39.317608],[-123.08184,39.530704],[-123.184993,39.420734],[-123.125316,39.255459],[-123.006036,39.235979],[-123.019697,39.317608]]]},"properties":{"id":"95469","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.996968,40.158842],[-120.281482,40.431955],[-120.323872,40.35185],[-120.370455,40.318152],[-120.329854,40.300331],[-120.068483,40.159559],[-119.996968,40.158842]]]},"properties":{"id":"96136","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.204582,40.042737],[-120.421087,39.892886],[-120.372754,39.864461],[-120.228171,39.88351],[-120.210594,39.889504],[-120.12667,39.945259],[-120.204582,40.042737]]],[[[-120.201171,39.707901],[-120.033387,39.576763],[-120.0015,39.577356],[-119.999084,39.904257],[-120.108321,39.828391],[-120.148107,39.847343],[-120.201171,39.707901]]]]},"properties":{"id":"96105","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.213909,41.059696],[-121.263935,41.184211],[-121.322635,41.183866],[-121.213909,41.059696]]]},"properties":{"id":"96068","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.928327,40.200052],[-120.992945,40.31516],[-121.085893,40.172134],[-121.060961,40.171078],[-120.936698,40.206473],[-120.928327,40.200052]]]},"properties":{"id":"96137","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.207412,41.344431],[-120.208865,41.184096],[-119.999648,41.423275],[-120.207412,41.344431]]]},"properties":{"id":"96110","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.077214,34.103491],[-118.112989,34.101707],[-118.108189,34.071654],[-118.107463,34.071545],[-118.073262,34.090412],[-118.077214,34.103491]]]},"properties":{"id":"91776","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.23751,33.80048],[-118.236774,33.839231],[-118.263334,33.839474],[-118.284917,33.846239],[-118.287508,33.808959],[-118.286737,33.797818],[-118.23751,33.80048]]]},"properties":{"id":"90745","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.021641,34.041689],[-118.054653,34.019901],[-118.063773,34.004281],[-118.049305,33.979071],[-118.002474,33.989289],[-117.998071,34.02957],[-118.010372,34.050149],[-118.021641,34.041689]]]},"properties":{"id":"90601","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.300379,33.91741],[-118.313476,33.887061],[-118.309123,33.87281],[-118.285945,33.916422],[-118.300379,33.91741]]]},"properties":{"id":"90247","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.333567,33.894661],[-118.378703,33.901794],[-118.378721,33.894646],[-118.352347,33.874215],[-118.331254,33.881368],[-118.334864,33.887423],[-118.333567,33.894661]]]},"properties":{"id":"90260","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.161798,33.811664],[-118.151346,33.804427],[-118.139138,33.812658],[-118.161798,33.811664]]],[[[-118.176302,33.811647],[-118.204255,33.831815],[-118.205608,33.790184],[-118.167642,33.789848],[-118.167645,33.791555],[-118.176302,33.811647]]]]},"properties":{"id":"90806","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.228805,33.922685],[-118.259672,33.902526],[-118.221037,33.90336],[-118.215669,33.912452],[-118.228805,33.922685]]]},"properties":{"id":"90222","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167646,33.775346],[-118.167642,33.789848],[-118.205608,33.790184],[-118.227512,33.790368],[-118.239988,33.768007],[-118.167646,33.774919],[-118.167646,33.775346]]]},"properties":{"id":"90813","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.537358,34.130637],[-118.564017,34.131147],[-118.565316,34.130383],[-118.577505,34.070578],[-118.567251,34.038401],[-118.521865,34.024999],[-118.496873,34.04807],[-118.518977,34.129031],[-118.537358,34.130637]]]},"properties":{"id":"90272","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.454106,34.058346],[-118.459995,34.051672],[-118.474465,34.044279],[-118.47096,34.041276],[-118.46146,34.03328],[-118.4162,34.053176],[-118.418614,34.061389],[-118.454106,34.058346]]]},"properties":{"id":"90025","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.274313,34.051362],[-118.256865,34.045163],[-118.253932,34.050176],[-118.255897,34.056087],[-118.256045,34.056183],[-118.267932,34.060871],[-118.275461,34.05188],[-118.274313,34.051362]]]},"properties":{"id":"90017","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.344086,34.094327],[-118.345149,34.083455],[-118.338583,34.083457],[-118.309145,34.083509],[-118.3092,34.093606],[-118.344086,34.094327]]]},"properties":{"id":"90038","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.392114,34.106261],[-118.370302,34.08368],[-118.361417,34.083811],[-118.345149,34.083455],[-118.344086,34.094327],[-118.34484,34.104012],[-118.366056,34.128968],[-118.398129,34.120193],[-118.392114,34.106261]]]},"properties":{"id":"90046","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.277162,34.095776],[-118.309211,34.09489],[-118.3092,34.093606],[-118.309145,34.083509],[-118.284211,34.083625],[-118.277162,34.095776]]]},"properties":{"id":"90029","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.272095,34.146749],[-118.254135,34.11886],[-118.253306,34.124322],[-118.254946,34.146273],[-118.25506,34.146272],[-118.255039,34.142592],[-118.257843,34.142566],[-118.256379,34.146453],[-118.272095,34.146749]]]},"properties":{"id":"91204","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.497798,34.178794],[-118.51848,34.128944],[-118.480521,34.129853],[-118.469438,34.162771],[-118.497798,34.178794]]]},"properties":{"id":"91436","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.278337,34.153205],[-118.30807,34.161224],[-118.312615,34.154477],[-118.309282,34.105381],[-118.309211,34.09489],[-118.277162,34.095776],[-118.278337,34.153205]]]},"properties":{"id":"90027","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.227695,34.038436],[-118.24524,34.04226],[-118.25033,34.037303],[-118.257437,34.027631],[-118.242772,34.020595],[-118.223361,34.017379],[-118.227695,34.038436]]]},"properties":{"id":"90021","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.093309,33.831712],[-118.081812,33.83126],[-118.061691,33.833317],[-118.058918,33.846121],[-118.098939,33.845875],[-118.093309,33.831712]]]},"properties":{"id":"90715","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.389672,34.06705],[-118.416319,34.067079],[-118.414344,34.064324],[-118.406023,34.052666],[-118.390298,34.057085],[-118.389672,34.06705]]]},"properties":{"id":"90212","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.252997,34.536544],[-118.289695,34.394764],[-118.25269,34.387872],[-118.206412,34.376765],[-118.111998,34.524229],[-118.154568,34.527785],[-118.252997,34.536544]]]},"properties":{"id":"93510","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.391371,33.872812],[-118.414124,33.875214],[-118.404713,33.850466],[-118.390308,33.854854],[-118.391371,33.872812]]]},"properties":{"id":"90254","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.025372,33.948669],[-117.976536,33.928166],[-117.983412,33.965],[-118.025372,33.948669]]]},"properties":{"id":"90603","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.71051,34.038319],[-118.709397,34.034219],[-118.703279,34.034667],[-118.704776,34.039154],[-118.71051,34.038319]]]},"properties":{"id":"90263","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.20184,33.767322],[-118.199364,33.769285],[-118.204159,33.769518],[-118.20184,33.767322]]]},"properties":{"id":"90831","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.519661,37.287897],[-119.57911,37.323616],[-119.562184,37.286148],[-119.519661,37.287897]]]},"properties":{"id":"93669","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.49374,37.082865],[-120.500614,36.995166],[-120.457208,36.965746],[-120.181082,37.061828],[-120.020309,37.119376],[-120.058885,37.191627],[-120.35218,37.203259],[-120.430314,37.14177],[-120.49374,37.082865]]]},"properties":{"id":"93610","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.492784,37.875426],[-122.626534,37.886724],[-122.628711,37.885352],[-122.634053,37.883072],[-122.457283,37.859227],[-122.492784,37.875426]]]},"properties":{"id":"94965","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.912817,38.232749],[-122.937912,38.201372],[-122.862251,38.112335],[-122.807629,38.141206],[-122.912817,38.232749]]]},"properties":{"id":"94940","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.626534,37.886724],[-122.492784,37.875426],[-122.502985,37.909325],[-122.539618,37.922123],[-122.553027,37.926011],[-122.595243,37.925665],[-122.612657,37.910475],[-122.626534,37.886724]]]},"properties":{"id":"94941","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.720282,37.995985],[-122.692252,38.004566],[-122.690172,38.024481],[-122.690174,38.030169],[-122.758947,38.0499],[-122.720282,37.995985]]]},"properties":{"id":"94938","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.319197,37.521335],[-120.17539,37.497318],[-120.001719,37.370121],[-119.802749,37.384483],[-119.688205,37.44117],[-119.698083,37.506301],[-119.822069,37.609968],[-119.901415,37.669499],[-119.925854,37.642539],[-119.925244,37.522998],[-120.070088,37.597737],[-120.288578,37.649628],[-120.319197,37.521335]]]},"properties":{"id":"95338","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.070088,37.597737],[-119.925244,37.522998],[-119.925854,37.642539],[-120.070088,37.597737]]]},"properties":{"id":"95345","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.521517,37.581487],[-120.294547,37.648865],[-120.245298,37.750543],[-120.306651,37.81027],[-120.398974,37.799607],[-120.401404,37.796689],[-120.517052,37.731224],[-120.45857,37.68711],[-120.618329,37.621108],[-120.572885,37.584908],[-120.521517,37.581487]]]},"properties":{"id":"95329","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.478381,38.917356],[-123.648784,38.841691],[-123.533746,38.768405],[-123.498956,38.778127],[-123.368261,38.782034],[-123.45664,38.914314],[-123.478381,38.917356]]]},"properties":{"id":"95445","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.401718,39.800967],[-123.447329,39.977196],[-123.544458,40.001923],[-123.707339,40.001795],[-123.670114,39.909894],[-123.541967,39.760166],[-123.580454,39.625635],[-123.349073,39.678803],[-123.390064,39.791832],[-123.401718,39.800967]]]},"properties":{"id":"95454","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.751413,37.264176],[-120.850316,37.3622],[-120.964223,37.345782],[-120.930182,37.310029],[-120.827435,37.279864],[-120.751413,37.264176]]]},"properties":{"id":"95374","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.62032,37.157023],[-120.49374,37.082865],[-120.430314,37.14177],[-120.62032,37.157023]]]},"properties":{"id":"95317","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.667662,37.42302],[-120.667711,37.419354],[-120.667734,37.411191],[-120.667662,37.42302]]]},"properties":{"id":"95312","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.998698,41.720632],[-120.207482,41.802731],[-120.274066,41.718617],[-120.274022,41.652982],[-120.264836,41.58312],[-119.998823,41.665148],[-119.998698,41.720632]]]},"properties":{"id":"96115","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.207482,41.802731],[-119.998698,41.720632],[-120.053341,41.973521],[-120.230264,41.911027],[-120.207482,41.802731]]]},"properties":{"id":"96112","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.322997,38.051571],[-119.080166,37.760057],[-119.147109,37.845095],[-119.119476,37.875866],[-119.21554,38.167767],[-119.322997,38.051571]]]},"properties":{"id":"93541","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.406993,36.656035],[-121.483002,36.76505],[-121.582248,36.824437],[-121.609699,36.754754],[-121.598243,36.719528],[-121.621791,36.658662],[-121.678988,36.682652],[-121.760597,36.755979],[-121.782983,36.732053],[-121.731576,36.65577],[-121.753578,36.589238],[-121.758368,36.554865],[-121.56372,36.496799],[-121.46865,36.488168],[-121.498118,36.514278],[-121.406993,36.656035]],[[-121.650003,36.625436],[-121.642029,36.626096],[-121.644852,36.62143],[-121.650003,36.625436]]]},"properties":{"id":"93908","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.497921,36.413597],[-121.549561,36.233382],[-121.397613,36.079071],[-121.238509,36.114256],[-121.20143,36.339696],[-121.497921,36.413597]]]},"properties":{"id":"93927","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.063546,36.342322],[-121.199789,36.356456],[-121.20143,36.339696],[-121.238509,36.114256],[-121.153161,36.00238],[-121.026993,36.117532],[-121.023467,36.12194],[-120.869908,36.16574],[-120.725068,36.175584],[-120.743793,36.199121],[-121.063546,36.342322]]]},"properties":{"id":"93930","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.621791,36.658662],[-121.598243,36.719528],[-121.648425,36.684703],[-121.621791,36.658662]]]},"properties":{"id":"93905","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.630686,39.315272],[-120.629248,39.344687],[-120.767805,39.25723],[-120.630686,39.315272]]],[[[-121.084391,39.394266],[-121.173007,39.288946],[-121.172577,39.244302],[-121.109456,39.231724],[-120.849201,39.244568],[-120.785531,39.321123],[-120.799293,39.363897],[-120.750212,39.361788],[-120.713744,39.482788],[-120.731443,39.470257],[-120.817787,39.438043],[-121.084391,39.394266]]]]},"properties":{"id":"95959","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.109456,39.231724],[-121.172577,39.244302],[-121.136513,39.209434],[-121.111247,39.205295],[-121.109456,39.231724]]]},"properties":{"id":"95975","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.849201,39.244568],[-121.109456,39.231724],[-121.111247,39.205295],[-120.989387,39.111229],[-120.908646,39.168485],[-120.865408,39.188408],[-120.857827,39.197443],[-120.849201,39.244568]]]},"properties":{"id":"95945","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.989387,39.111229],[-121.111247,39.205295],[-121.136513,39.209434],[-121.229385,39.160784],[-121.279715,39.098596],[-121.279533,39.034618],[-121.192281,39.015532],[-121.030095,39.036433],[-121.021776,39.039165],[-120.989387,39.111229]]]},"properties":{"id":"95949","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.480529,39.316119],[-120.555314,39.31561],[-120.5678,39.315559],[-120.44373,39.316168],[-120.480529,39.316119]]],[[[-120.305992,39.327445],[-120.392054,39.325142],[-120.435359,39.316193],[-120.383129,39.287334],[-120.356245,39.316296],[-120.349257,39.31634],[-120.305992,39.327445]]]]},"properties":{"id":"95728","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.084391,39.394266],[-121.136847,39.368074],[-121.190719,39.330372],[-121.193325,39.330255],[-121.173007,39.288946],[-121.084391,39.394266]]],[[[-121.084391,39.394266],[-120.817787,39.438043],[-120.937938,39.45168],[-121.027774,39.451992],[-121.084391,39.394266]]]]},"properties":{"id":"95960","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.011048,33.799187],[-117.984457,33.802885],[-118.010701,33.809992],[-118.011048,33.799187]]]},"properties":{"id":"90680","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.906241,33.790641],[-117.894463,33.796156],[-117.898081,33.799726],[-117.931765,33.832266],[-117.941478,33.828862],[-117.941417,33.803154],[-117.906241,33.790641]]]},"properties":{"id":"92802","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.883152,33.665486],[-117.909447,33.627675],[-117.90269,33.608521],[-117.889794,33.609207],[-117.841984,33.610705],[-117.82804,33.62335],[-117.84183,33.6311],[-117.86174,33.651164],[-117.864007,33.671822],[-117.878401,33.664082],[-117.883152,33.665486]]]},"properties":{"id":"92660","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.797981,33.721677],[-117.83764,33.71126],[-117.848915,33.700178],[-117.810182,33.682152],[-117.78222,33.710154],[-117.795023,33.719649],[-117.797981,33.721677]]]},"properties":{"id":"92606","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.073057,33.710736],[-118.07844,33.72415],[-118.087323,33.722763],[-118.073057,33.710736]]]},"properties":{"id":"90742","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.930693,33.701862],[-117.94185,33.733298],[-117.945978,33.732208],[-117.971945,33.719835],[-117.98035,33.70121],[-117.939512,33.687083],[-117.930693,33.701862]]]},"properties":{"id":"92708","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.66625,33.46716],[-117.572979,33.453305],[-117.509582,33.471662],[-117.575282,33.562165],[-117.590802,33.550537],[-117.663299,33.538109],[-117.67276,33.54737],[-117.686633,33.487293],[-117.683317,33.46431],[-117.66625,33.46716]]]},"properties":{"id":"92675","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.641853,33.660801],[-117.6945,33.606985],[-117.671731,33.574169],[-117.67276,33.54737],[-117.641853,33.660801]]]},"properties":{"id":"92691","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.717373,33.736179],[-117.637035,33.698441],[-117.563931,33.684573],[-117.534137,33.712584],[-117.569175,33.76461],[-117.581956,33.770685],[-117.63665,33.829975],[-117.732304,33.794891],[-117.747294,33.784917],[-117.717286,33.736985],[-117.717373,33.736179]]]},"properties":{"id":"92676","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.010701,33.809992],[-117.984457,33.802885],[-117.958865,33.802942],[-117.941417,33.803154],[-117.941478,33.828862],[-118.007708,33.831916],[-118.017303,33.80993],[-118.010701,33.809992]]]},"properties":{"id":"92804","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.876019,33.778927],[-117.855929,33.759952],[-117.83729,33.74204],[-117.830866,33.773261],[-117.857359,33.777057],[-117.876019,33.778927]]],[[[-117.848915,33.700178],[-117.83764,33.71126],[-117.843893,33.739588],[-117.8548,33.73361],[-117.861366,33.698577],[-117.848915,33.700178]]],[[[-117.76169,33.77661],[-117.830273,33.773257],[-117.79761,33.74005],[-117.76169,33.77661]]]]},"properties":{"id":"92705","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.82804,33.62335],[-117.783883,33.595103],[-117.760606,33.595732],[-117.758781,33.626908],[-117.778042,33.657588],[-117.824529,33.650075],[-117.84183,33.6311],[-117.82804,33.62335]]]},"properties":{"id":"92603","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.783287,33.946411],[-117.841825,33.946839],[-117.855,33.9063],[-117.842386,33.909894],[-117.748097,33.921432],[-117.783287,33.946411]]]},"properties":{"id":"92823","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.90269,33.608521],[-117.929442,33.601759],[-117.877408,33.587959],[-117.88219,33.600422],[-117.90269,33.608521]]]},"properties":{"id":"92661","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.839223,39.215459],[-120.848223,39.184171],[-120.836284,39.15175],[-120.788051,39.173871],[-120.759289,39.188122],[-120.630686,39.315272],[-120.767805,39.25723],[-120.839223,39.215459]]]},"properties":{"id":"95701","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.414342,38.841512],[-121.416694,38.771557],[-121.397388,38.728906],[-121.311151,38.722923],[-121.30883,38.72279],[-121.307892,38.797208],[-121.346712,38.824532],[-121.414342,38.841512]]]},"properties":{"id":"95747","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.137766,38.836559],[-121.101444,38.817229],[-121.088538,38.830375],[-121.085791,38.83445],[-121.185924,38.934013],[-121.203291,38.874955],[-121.137766,38.836559]]]},"properties":{"id":"95658","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.966104,38.97175],[-121.037502,38.915689],[-120.9434,38.935671],[-120.902107,38.953155],[-120.746319,39.003514],[-120.711292,39.189259],[-120.759289,39.188122],[-120.788051,39.173871],[-120.955651,38.991996],[-120.966104,38.97175]]]},"properties":{"id":"95631","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.356245,39.316296],[-120.3108,39.303526],[-120.311045,39.316399],[-120.349257,39.31634],[-120.356245,39.316296]]]},"properties":{"id":"95724","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.523964,33.885735],[-116.596142,33.94106],[-116.608286,33.90125],[-116.523964,33.885735]]]},"properties":{"id":"92258","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.094558,33.430505],[-117.052261,33.43023],[-116.935037,33.427159],[-116.927965,33.427362],[-116.963246,33.514071],[-116.962889,33.632379],[-117.009245,33.656308],[-117.031722,33.568577],[-117.151535,33.500743],[-117.143979,33.447295],[-117.094558,33.430505]]]},"properties":{"id":"92592","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.558302,33.945908],[-117.560039,33.898605],[-117.51406,33.910063],[-117.523437,33.964842],[-117.558302,33.945908]]]},"properties":{"id":"92860","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.133741,33.784321],[-117.132167,33.735926],[-117.068011,33.771186],[-117.133741,33.784321]]]},"properties":{"id":"92548","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.173753,33.786697],[-117.133741,33.784321],[-117.068011,33.771186],[-117.053891,33.787357],[-117.06836,33.852965],[-117.135596,33.840501],[-117.173753,33.786697]]]},"properties":{"id":"92567","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.424189,38.551612],[-121.475758,38.541064],[-121.484323,38.539613],[-121.47972,38.524835],[-121.407582,38.525073],[-121.421645,38.55102],[-121.424189,38.551612]]]},"properties":{"id":"95820","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.459061,38.55816],[-121.424189,38.551612],[-121.421645,38.55102],[-121.419995,38.562441],[-121.420564,38.582105],[-121.447198,38.58773],[-121.462649,38.582345],[-121.459061,38.55816]]]},"properties":{"id":"95819","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.242795,38.718241],[-121.290403,38.721501],[-121.292791,38.663612],[-121.259502,38.68285],[-121.242795,38.718241]]]},"properties":{"id":"95610","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.484323,38.539613],[-121.512618,38.541666],[-121.525566,38.523106],[-121.505073,38.481269],[-121.466354,38.481526],[-121.475265,38.510328],[-121.47972,38.524835],[-121.484323,38.539613]]]},"properties":{"id":"95822","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.0793,38.632857],[-121.118617,38.717118],[-121.141009,38.71198],[-121.170375,38.713004],[-121.188571,38.714308],[-121.192558,38.650104],[-121.206158,38.640304],[-121.206725,38.637463],[-121.142662,38.617013],[-121.0793,38.632857]]]},"properties":{"id":"95630","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.328421,38.599925],[-121.298375,38.571271],[-121.266454,38.568074],[-121.206725,38.637463],[-121.206158,38.640304],[-121.291486,38.630273],[-121.328421,38.599925]]]},"properties":{"id":"95670","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.292791,38.663612],[-121.312707,38.671133],[-121.291486,38.630273],[-121.206158,38.640304],[-121.192558,38.650104],[-121.259502,38.68285],[-121.292791,38.663612]]]},"properties":{"id":"95628","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.421372,38.658115],[-121.404045,38.639788],[-121.401365,38.690542],[-121.421372,38.658115]]]},"properties":{"id":"95652","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.481846,34.506908],[-117.450996,34.448351],[-117.390663,34.437414],[-117.346019,34.470462],[-117.322754,34.507828],[-117.399406,34.517667],[-117.481846,34.506908]]]},"properties":{"id":"92392","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.762478,34.208919],[-116.989645,34.121048],[-116.981683,34.120987],[-116.912368,34.09913],[-116.717848,34.122175],[-116.646999,34.206591],[-116.752966,34.218006],[-116.762478,34.208919]]]},"properties":{"id":"92305","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.40252,35.1363],[-115.23827,35.12764],[-115.22517,35.30337],[-115.49829,35.15793],[-115.40252,35.1363]]]},"properties":{"id":"92364","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.43144,34.136467],[-117.429453,34.187496],[-117.456668,34.208074],[-117.514099,34.106357],[-117.405293,34.106735],[-117.43144,34.136467]]]},"properties":{"id":"92336","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.35988,35.795191],[-117.421463,35.795601],[-117.439514,35.710948],[-117.33965,35.70834],[-117.305559,35.795166],[-117.35988,35.795191]]]},"properties":{"id":"93562","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.631312,35.344139],[-117.613506,35.332427],[-117.613583,35.376218],[-117.631894,35.376333],[-117.631312,35.344139]]]},"properties":{"id":"93558","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.337332,34.237736],[-116.35251,34.075821],[-116.227391,34.120569],[-116.193033,34.251511],[-116.299014,34.251586],[-116.337332,34.237736]]]},"properties":{"id":"92252","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.981875,34.082955],[-116.929886,34.06796],[-116.912368,34.09913],[-116.981683,34.120987],[-116.981875,34.082955]]]},"properties":{"id":"92339","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.285028,34.164901],[-117.319111,34.144497],[-117.302548,34.121332],[-117.285178,34.121343],[-117.285028,34.164901]]]},"properties":{"id":"92405","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.920386,33.082833],[-116.996636,33.052066],[-116.958254,32.962865],[-116.787617,32.949266],[-116.740755,32.987478],[-116.860996,33.20436],[-116.893145,33.164136],[-116.920386,33.082833]]]},"properties":{"id":"92065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.040705,32.783359],[-117.00588,32.794306],[-117.005597,32.807276],[-117.037041,32.840262],[-117.047323,32.833538],[-117.062761,32.821798],[-117.040705,32.783359]]]},"properties":{"id":"92119","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.25896,32.713236],[-117.226715,32.74513],[-117.224222,32.75771],[-117.255851,32.753713],[-117.25896,32.713236]]]},"properties":{"id":"92107","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.140008,33.314375],[-117.067014,33.177844],[-116.893145,33.164136],[-116.860996,33.20436],[-116.841216,33.252111],[-117.02092,33.343047],[-117.133006,33.342922],[-117.140008,33.314375]]]},"properties":{"id":"92082","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.105697,32.587551],[-117.122217,32.588915],[-117.135263,32.585616],[-117.124689,32.534175],[-117.105697,32.587551]]]},"properties":{"id":"91932","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.616071,33.152005],[-116.710012,33.264395],[-116.823856,33.284536],[-116.841216,33.252111],[-116.860996,33.20436],[-116.740755,32.987478],[-116.72141,33.0027],[-116.616071,33.152005]]]},"properties":{"id":"92070","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.61919,32.974096],[-116.698089,32.873849],[-116.595948,32.82269],[-116.565225,32.865405],[-116.61919,32.974096]]]},"properties":{"id":"91916","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.777654,32.656892],[-116.627471,32.576382],[-116.643345,32.59786],[-116.673167,32.668695],[-116.675148,32.668717],[-116.777654,32.656892]]]},"properties":{"id":"91917","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.360819,32.705156],[-116.4137,32.594045],[-116.226679,32.609052],[-116.244044,32.663544],[-116.365909,32.770965],[-116.360819,32.705156]]]},"properties":{"id":"91905","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.97348,32.672463],[-116.989744,32.663936],[-116.971066,32.652934],[-116.930304,32.647675],[-116.97348,32.672463]]]},"properties":{"id":"91914","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.100783,32.757076],[-117.114163,32.757154],[-117.117798,32.717129],[-117.08493,32.718046],[-117.063858,32.730157],[-117.058253,32.734454],[-117.100783,32.757076]]]},"properties":{"id":"92105","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.288516,33.18381],[-117.207111,33.157806],[-117.205093,33.161781],[-117.257399,33.235125],[-117.288516,33.18381]]]},"properties":{"id":"92083","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.085,32.7041],[-117.085132,32.692365],[-117.064344,32.688864],[-117.024911,32.697862],[-117.022994,32.718477],[-117.063858,32.730157],[-117.08493,32.718046],[-117.085,32.7041]]]},"properties":{"id":"92114","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.172803,32.711469],[-117.171057,32.711482],[-117.171098,32.715712],[-117.173104,32.715708],[-117.172803,32.711469]]]},"properties":{"id":"92132","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.430183,37.795854],[-122.447679,37.791703],[-122.444967,37.776958],[-122.429849,37.777919],[-122.427396,37.782057],[-122.430183,37.795854]]]},"properties":{"id":"94115","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.430183,37.795854],[-122.427396,37.782057],[-122.411886,37.788808],[-122.414826,37.794988],[-122.421076,37.812889],[-122.43235,37.805976],[-122.430183,37.795854]]]},"properties":{"id":"94109","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.398482,37.807228],[-122.404412,37.796302],[-122.404613,37.793565],[-122.399149,37.791017],[-122.388981,37.796628],[-122.398482,37.807228]]]},"properties":{"id":"94111","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.477363,37.766062],[-122.476625,37.786796],[-122.485943,37.790839],[-122.510341,37.764001],[-122.509833,37.763969],[-122.477308,37.765448],[-122.477363,37.766062]]]},"properties":{"id":"94121","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.22995,37.921597],[-121.220155,37.880806],[-121.145587,37.855715],[-121.04601,37.892949],[-121.038495,37.938568],[-121.150873,38.065672],[-121.25754,38.004798],[-121.22995,37.921597]]]},"properties":{"id":"95215","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.277476,37.951724],[-121.298755,37.965621],[-121.368712,37.967449],[-121.372072,37.968358],[-121.27586,37.945489],[-121.277476,37.951724]]]},"properties":{"id":"95203","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.32207,38.189182],[-121.300397,38.151251],[-121.29406,38.174678],[-121.32207,38.189182]]]},"properties":{"id":"95258","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.192852,38.148189],[-121.096767,38.186118],[-121.115268,38.192669],[-121.192852,38.148189]]]},"properties":{"id":"95237","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.088288,38.223739],[-121.115268,38.192669],[-121.096767,38.186118],[-121.015593,38.160575],[-120.975206,38.177886],[-120.987602,38.207456],[-120.995497,38.225405],[-121.016369,38.274402],[-121.088288,38.223739]]]},"properties":{"id":"95227","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.629586,35.165451],[-120.700319,35.179748],[-120.719403,35.176164],[-120.716493,35.172643],[-120.640144,35.128383],[-120.606811,35.129491],[-120.629586,35.165451]]]},"properties":{"id":"93449","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.34978,35.795193],[-121.129764,35.594057],[-121.062072,35.678697],[-121.185056,35.794161],[-121.243778,35.794525],[-121.34978,35.795193]]]},"properties":{"id":"93452","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.865844,35.353858],[-120.841996,35.340806],[-120.695002,35.382945],[-120.739825,35.411562],[-120.801785,35.464437],[-120.876523,35.412595],[-120.865844,35.353858]]]},"properties":{"id":"93442","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.417631,35.081869],[-120.591507,35.04743],[-120.602199,34.972749],[-120.560095,34.975559],[-120.433036,34.985983],[-120.417631,35.081869]]]},"properties":{"id":"93444","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.66304,35.302356],[-120.661454,35.298774],[-120.658584,35.29963],[-120.659157,35.302666],[-120.66304,35.302356]]]},"properties":{"id":"93410","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.276664,37.534223],[-122.330197,37.512587],[-122.339141,37.507196],[-122.303476,37.493798],[-122.261965,37.521139],[-122.265697,37.534076],[-122.276664,37.534223]]]},"properties":{"id":"94002","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.172439,37.392201],[-122.194195,37.409088],[-122.229133,37.424295],[-122.18876,37.320916],[-122.185458,37.323737],[-122.184078,37.334842],[-122.172439,37.392201]]]},"properties":{"id":"94028","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.825906,34.467872],[-119.826832,34.415857],[-119.786224,34.415602],[-119.789614,34.451349],[-119.825906,34.467872]]]},"properties":{"id":"93111","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.336984,34.417572],[-119.392332,34.41387],[-119.476822,34.372834],[-119.266891,34.234556],[-119.216442,34.235113],[-119.195243,34.287953],[-119.165914,34.300326],[-119.174243,34.391774],[-119.296371,34.390095],[-119.336984,34.417572]]]},"properties":{"id":"93001","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.714178,37.48361],[-121.791975,37.403516],[-121.732563,37.339345],[-121.620026,37.290336],[-121.566553,37.296239],[-121.462151,37.442334],[-121.472648,37.48217],[-121.608635,37.482634],[-121.714178,37.48361]]]},"properties":{"id":"95140","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.791769,37.31404],[-121.771625,37.299159],[-121.677359,37.252336],[-121.620026,37.290336],[-121.732563,37.339345],[-121.733748,37.333474],[-121.791769,37.31404]]]},"properties":{"id":"95135","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.843866,37.285738],[-121.810146,37.260264],[-121.802498,37.255721],[-121.809383,37.285245],[-121.843983,37.31036],[-121.861178,37.303594],[-121.843866,37.285738]]]},"properties":{"id":"95111","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.0578,37.38752],[-122.058148,37.381386],[-122.060076,37.375435],[-122.001736,37.352146],[-122.004415,37.376705],[-122.0478,37.38633],[-122.0578,37.38752]]]},"properties":{"id":"94086","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.906188,37.271395],[-121.903918,37.226543],[-121.903732,37.226181],[-121.874794,37.243757],[-121.869352,37.255838],[-121.877846,37.278496],[-121.906188,37.271395]]]},"properties":{"id":"95118","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.063002,37.359236],[-122.09601,37.38645],[-122.09525,37.332277],[-122.0624,37.33754],[-122.063002,37.359236]]]},"properties":{"id":"94024","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.873826,37.359841],[-121.86826,37.32966],[-121.827644,37.350835],[-121.846169,37.36794],[-121.873826,37.359841]]]},"properties":{"id":"95116","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.16019,37.43728],[-122.16763,37.44363],[-122.189879,37.42883],[-122.194195,37.409088],[-122.172439,37.392201],[-122.151789,37.415062],[-122.16019,37.43728]]]},"properties":{"id":"94305","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.94184,37.35074],[-121.93358,37.34519],[-121.93336,37.34963],[-121.94184,37.35074]]]},"properties":{"id":"95053","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.060417,37.125026],[-122.106761,37.070536],[-122.055932,37.003119],[-122.051008,37.001574],[-122.055834,37.012883],[-121.996761,37.113511],[-122.060417,37.125026]],[[-122.060468,37.049791],[-122.0584,37.051178],[-122.05456,37.051867],[-122.054126,37.05044],[-122.060468,37.049791]]]},"properties":{"id":"95018","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.060468,37.049791],[-122.054126,37.05044],[-122.05456,37.051867],[-122.0584,37.051178],[-122.060468,37.049791]]]},"properties":{"id":"95041","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.055932,37.003119],[-122.068915,36.980253],[-122.050708,36.977231],[-122.051008,37.001574],[-122.055932,37.003119]]]},"properties":{"id":"95064","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.220432,40.507335],[-122.267271,40.489465],[-122.367179,40.505473],[-122.524932,40.513925],[-122.443277,40.412288],[-122.193557,40.392311],[-122.04746,40.419909],[-122.047433,40.426978],[-122.106242,40.525376],[-122.220432,40.507335]]]},"properties":{"id":"96007","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.985607,40.438933],[-121.962609,40.41029],[-121.810178,40.374134],[-121.666318,40.352227],[-121.733578,40.484083],[-121.985607,40.438933]]]},"properties":{"id":"96059","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.258214,40.560991],[-122.368025,40.585818],[-122.367179,40.505473],[-122.267271,40.489465],[-122.258214,40.560991]]]},"properties":{"id":"96002","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.178332,40.579964],[-122.220432,40.507335],[-122.106242,40.525376],[-121.943904,40.561824],[-122.008382,40.640626],[-122.178332,40.579964]]]},"properties":{"id":"96062","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.240893,41.49045],[-122.96456,41.528627],[-122.935627,41.565886],[-122.904654,41.543568],[-122.764174,41.470552],[-122.659702,41.472422],[-122.640838,41.557357],[-122.821361,41.764062],[-122.876185,41.747159],[-123.160286,41.696068],[-123.274949,41.695958],[-123.240893,41.49045]]]},"properties":{"id":"96032","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.764174,41.470552],[-122.889435,41.238806],[-122.873167,41.20429],[-122.726819,41.246386],[-122.698076,41.276755],[-122.659702,41.472422],[-122.764174,41.470552]]]},"properties":{"id":"96014","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.896956,38.416453],[-121.942,38.453278],[-122.111264,38.477108],[-122.064779,38.315912],[-122.02545,38.322733],[-122.023883,38.325081],[-121.896956,38.416453]]]},"properties":{"id":"95688","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.942,38.453278],[-121.896956,38.416453],[-121.896863,38.366164],[-121.896852,38.364768],[-121.842059,38.278465],[-121.655814,38.313419],[-121.584462,38.473894],[-121.58307,38.545304],[-121.768525,38.530186],[-121.82832,38.529443],[-121.942,38.453278]]]},"properties":{"id":"95620","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.584462,38.473894],[-121.655814,38.313419],[-121.630825,38.291798],[-121.605425,38.285801],[-121.521153,38.367408],[-121.514121,38.388742],[-121.504139,38.466563],[-121.558069,38.501876],[-121.584462,38.473894]]]},"properties":{"id":"95612","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.429199,38.124858],[-122.566694,38.329113],[-122.577437,38.344143],[-122.678864,38.278785],[-122.545376,38.158733],[-122.483997,38.107784],[-122.429199,38.124858]]]},"properties":{"id":"94954","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.717158,38.461007],[-122.715536,38.431305],[-122.685803,38.3722],[-122.66686,38.338581],[-122.577437,38.344143],[-122.566694,38.329113],[-122.56236,38.339269],[-122.565363,38.38672],[-122.600663,38.402487],[-122.630276,38.420214],[-122.673036,38.42308],[-122.690334,38.452662],[-122.546393,38.521208],[-122.569473,38.529659],[-122.686568,38.583769],[-122.736093,38.554882],[-122.717158,38.461007]]]},"properties":{"id":"95404","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.715536,38.431305],[-122.717158,38.461007],[-122.789028,38.472766],[-122.842372,38.484576],[-122.851614,38.482013],[-122.794997,38.419752],[-122.715536,38.431305]]]},"properties":{"id":"95401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.971077,38.309942],[-122.919785,38.317222],[-122.91632,38.349518],[-122.914728,38.364487],[-122.98358,38.355432],[-122.971077,38.309942]]]},"properties":{"id":"94922","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.907336,37.547541],[-120.901395,37.551249],[-120.901848,37.56564],[-120.907336,37.547541]]]},"properties":{"id":"95328","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.465589,37.435929],[-121.20598,37.580145],[-121.330051,37.59383],[-121.471925,37.481783],[-121.472648,37.48217],[-121.462151,37.442334],[-121.465589,37.435929]]]},"properties":{"id":"95387","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.054023,37.682917],[-121.110248,37.730128],[-121.206405,37.694791],[-121.242018,37.664585],[-121.258171,37.653495],[-121.179052,37.598313],[-121.08056,37.49382],[-121.012614,37.507665],[-120.977786,37.609273],[-121.031171,37.663862],[-121.054023,37.682917]]]},"properties":{"id":"95358","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.182755,40.346732],[-122.69997,40.273673],[-122.64292,39.943721],[-122.534219,39.959802],[-122.515985,39.990355],[-122.275861,40.029412],[-122.130731,40.10115],[-122.089878,40.135776],[-121.935125,40.290173],[-121.879191,40.394775],[-121.938834,40.394885],[-121.993297,40.403821],[-122.182755,40.346732]]]},"properties":{"id":"96080","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.566281,40.836579],[-123.469061,40.935553],[-123.562596,40.94971],[-123.602001,40.889287],[-123.566281,40.836579]]]},"properties":{"id":"95563","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.726819,41.246386],[-122.873167,41.20429],[-123.037118,41.004176],[-122.978384,40.832304],[-122.888151,40.804776],[-122.861052,40.837632],[-122.605186,40.949276],[-122.515982,41.085577],[-122.726819,41.246386]]]},"properties":{"id":"96091","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.978384,40.832304],[-122.997916,40.693539],[-122.881549,40.702039],[-122.888151,40.804776],[-122.978384,40.832304]]]},"properties":{"id":"96093","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.997916,40.693539],[-123.024553,40.612086],[-122.972625,40.502012],[-122.846764,40.494806],[-122.693302,40.577815],[-122.733769,40.635184],[-122.881549,40.702039],[-122.997916,40.693539]]]},"properties":{"id":"96024","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.482386,35.993028],[-118.648106,36.096749],[-118.613712,35.951421],[-118.566223,35.963657],[-118.486626,35.9683],[-118.482386,35.993028]]]},"properties":{"id":"93208","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.57114,37.772038],[-120.401404,37.796689],[-120.398974,37.799607],[-120.344549,37.83002],[-120.339986,37.841365],[-120.333245,37.875537],[-120.523796,37.949054],[-120.566991,37.899733],[-120.652673,37.831422],[-120.57114,37.772038]]]},"properties":{"id":"95327","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.424118,38.075893],[-120.453965,38.038208],[-120.323265,38.078801],[-120.323706,38.173583],[-120.324032,38.200077],[-120.331733,38.190313],[-120.376203,38.142649],[-120.424118,38.075893]]]},"properties":{"id":"95310","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.719659,34.31922],[-118.715495,34.262109],[-118.671476,34.241484],[-118.638429,34.254468],[-118.670593,34.360692],[-118.719659,34.31922]]]},"properties":{"id":"93063","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.194982,34.175684],[-119.20831,34.175735],[-119.203003,34.168487],[-119.194485,34.170636],[-119.194982,34.175684]]]},"properties":{"id":"93043","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.261474,38.924059],[-122.340172,38.924246],[-122.403941,38.925289],[-122.395056,38.864245],[-122.344794,38.839423],[-122.287998,38.839931],[-122.229458,38.878036],[-122.261474,38.924059]]]},"properties":{"id":"95679","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.597676,39.127792],[-121.57703,38.91967],[-121.573831,38.930196],[-121.493489,38.985104],[-121.513979,39.065158],[-121.597676,39.127792]]]},"properties":{"id":"95961","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.372014,39.367675],[-121.369167,39.218701],[-121.270729,39.23777],[-121.205602,39.312435],[-121.309723,39.405462],[-121.30357,39.449816],[-121.363493,39.403297],[-121.36612,39.382053],[-121.372014,39.367675]]]},"properties":{"id":"95918","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.264178,37.921471],[-122.28128,37.917248],[-122.266162,37.886083],[-122.255593,37.880194],[-122.246348,37.880976],[-122.246668,37.885255],[-122.260523,37.920817],[-122.264178,37.921471]]]},"properties":{"id":"94708","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.02536,37.578493],[-122.089615,37.562107],[-122.135769,37.558852],[-122.067333,37.540231],[-122.038506,37.563408],[-122.02536,37.578493]]]},"properties":{"id":"94555","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.453293,37.757959],[-121.531466,37.742581],[-121.630016,37.732514],[-121.556788,37.565102],[-121.416381,37.710437],[-121.453293,37.757959]]]},"properties":{"id":"95377","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.293143,37.828077],[-122.340281,37.800628],[-122.26838,37.788028],[-122.259574,37.798552],[-122.274296,37.817431],[-122.293143,37.828077]]]},"properties":{"id":"94607","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.213559,37.857912],[-122.219317,37.858475],[-122.253016,37.833557],[-122.267861,37.816237],[-122.260751,37.813733],[-122.215174,37.812276],[-122.178199,37.804014],[-122.138402,37.80433],[-122.145384,37.82028],[-122.151337,37.825103],[-122.172277,37.845057],[-122.213559,37.857912]]]},"properties":{"id":"94611","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.197348,37.771623],[-122.223779,37.754428],[-122.225895,37.749694],[-122.256542,37.721242],[-122.205374,37.702727],[-122.201813,37.713091],[-122.165823,37.753588],[-122.197348,37.771623]]]},"properties":{"id":"94621","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.293143,37.828077],[-122.274296,37.817431],[-122.273752,37.819135],[-122.27127,37.846562],[-122.277787,37.847601],[-122.289561,37.849907],[-122.309592,37.845543],[-122.293143,37.828077]]]},"properties":{"id":"94608","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.187944,37.777823],[-122.178401,37.783822],[-122.188586,37.784446],[-122.187944,37.777823]]]},"properties":{"id":"94613","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.747251,38.548446],[-120.745867,38.54093],[-120.737147,38.546327],[-120.747251,38.548446]]]},"properties":{"id":"95675","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.508001,39.879276],[-121.652138,39.948344],[-121.695043,39.831375],[-121.669755,39.751908],[-121.571138,39.813844],[-121.559157,39.841073],[-121.508001,39.879276]]]},"properties":{"id":"95954","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.553968,39.426693],[-121.537364,39.427524],[-121.537713,39.442095],[-121.556726,39.444341],[-121.553968,39.426693]]]},"properties":{"id":"95968","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.392904,38.200832],[-120.331733,38.190313],[-120.324032,38.200077],[-120.271009,38.270281],[-120.300012,38.274583],[-120.303123,38.273222],[-120.392904,38.200832]]]},"properties":{"id":"95224","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.396594,39.219089],[-122.116084,39.217913],[-122.083972,39.330622],[-122.120101,39.414544],[-122.256982,39.384842],[-122.396594,39.219089]]]},"properties":{"id":"95955","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.914615,37.954986],[-121.977171,37.914723],[-121.933485,37.85235],[-121.921069,37.843025],[-121.895966,37.83823],[-121.824574,37.838995],[-121.777099,37.842889],[-121.823735,37.918136],[-121.914615,37.954986]]]},"properties":{"id":"94517","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.142116,37.870876],[-122.164546,37.92129],[-122.227116,37.879764],[-122.213559,37.857912],[-122.172277,37.845057],[-122.161334,37.840213],[-122.142116,37.870876]]]},"properties":{"id":"94563","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.028197,37.975324],[-122.051013,37.947977],[-122.052568,37.940471],[-122.04688,37.934066],[-121.996991,37.942412],[-122.009362,37.970005],[-122.028197,37.975324]]]},"properties":{"id":"94518","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.323509,37.930978],[-122.303189,37.939123],[-122.315381,37.956928],[-122.332723,37.951366],[-122.323509,37.930978]]]},"properties":{"id":"94805","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.048508,38.031525],[-122.065643,37.980839],[-122.051013,37.947977],[-122.028197,37.975324],[-121.999794,38.002978],[-121.985202,38.012908],[-122.003691,38.013496],[-122.048508,38.031525]]]},"properties":{"id":"94520","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.824653,41.876771],[-124.007959,41.848991],[-123.967738,41.749952],[-123.824653,41.876771]]]},"properties":{"id":"95543","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.057183,38.877489],[-121.085791,38.83445],[-121.088538,38.830375],[-121.141009,38.71198],[-121.118617,38.717118],[-121.036781,38.772992],[-121.007507,38.763748],[-120.980881,38.795243],[-120.969035,38.828067],[-121.057183,38.877489]]]},"properties":{"id":"95664","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.969035,38.828067],[-120.937,38.847574],[-120.9434,38.935671],[-121.037502,38.915689],[-121.057183,38.877489],[-120.969035,38.828067]]]},"properties":{"id":"95614","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.118617,38.717118],[-121.0793,38.632857],[-121.055534,38.573153],[-121.009233,38.686756],[-121.036781,38.772992],[-121.118617,38.717118]]]},"properties":{"id":"95762","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.938545,38.724421],[-121.009233,38.686756],[-121.055534,38.573153],[-121.027507,38.508292],[-120.943563,38.527524],[-120.887343,38.674165],[-120.938545,38.724421]]]},"properties":{"id":"95682","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.825732,38.648257],[-120.792986,38.703574],[-120.840671,38.690733],[-120.825732,38.648257]]]},"properties":{"id":"95619","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.320493,36.705028],[-120.379137,36.625386],[-120.279689,36.602762],[-120.206055,36.669496],[-120.332802,36.719628],[-120.320493,36.705028]]]},"properties":{"id":"93668","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.772434,36.713051],[-119.786065,36.553339],[-119.761621,36.489338],[-119.755212,36.489321],[-119.709429,36.583637],[-119.645929,36.667907],[-119.636979,36.685154],[-119.663953,36.70018],[-119.727452,36.721837],[-119.772674,36.721354],[-119.772434,36.713051]]]},"properties":{"id":"93725","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.814253,36.76482],[-119.812931,36.807206],[-119.844326,36.800749],[-119.840311,36.764674],[-119.814253,36.76482]]]},"properties":{"id":"93705","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.727452,36.721837],[-119.736317,36.758617],[-119.772486,36.757689],[-119.772432,36.743168],[-119.772674,36.721354],[-119.727452,36.721837]]]},"properties":{"id":"93702","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.801883,36.837051],[-119.812931,36.807206],[-119.814253,36.76482],[-119.791374,36.765051],[-119.790525,36.779623],[-119.790429,36.808501],[-119.790262,36.833965],[-119.801883,36.837051]]]},"properties":{"id":"93704","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.790429,36.808501],[-119.790525,36.779623],[-119.736258,36.779496],[-119.727336,36.794136],[-119.733319,36.808538],[-119.790429,36.808501]]]},"properties":{"id":"93726","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.014572,36.800452],[-120.00607,36.742354],[-119.880228,36.750044],[-119.932551,36.84299],[-120.0246,36.816523],[-120.021349,36.807711],[-120.014572,36.800452]]]},"properties":{"id":"93723","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.082495,40.831192],[-124.17002,40.821548],[-124.193618,40.784971],[-124.111674,40.805427],[-124.082495,40.831192]]]},"properties":{"id":"95501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.89014,40.760203],[-123.766959,40.648748],[-123.74094,40.787987],[-123.900633,40.919938],[-123.929986,40.895488],[-123.98674,40.85713],[-123.89014,40.760203]]]},"properties":{"id":"95550","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.985029,40.431187],[-123.927978,40.329518],[-123.913454,40.321527],[-123.72379,40.332988],[-123.751148,40.383235],[-123.83945,40.403419],[-123.893603,40.40847],[-123.985029,40.431187]]]},"properties":{"id":"95569","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.17002,40.821548],[-124.234044,40.76076],[-124.222983,40.753037],[-124.193618,40.784971],[-124.17002,40.821548]]]},"properties":{"id":"95564","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.810051,40.217616],[-123.784701,40.165438],[-123.70551,40.146495],[-123.706986,40.197174],[-123.761224,40.232245],[-123.810051,40.217616]]]},"properties":{"id":"95559","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.556241,40.248395],[-123.683597,40.239785],[-123.706986,40.197174],[-123.70551,40.146495],[-123.544197,40.110988],[-123.543933,40.218565],[-123.543886,40.238847],[-123.556241,40.248395]]]},"properties":{"id":"95511","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.638526,33.015611],[-115.56141,33.044512],[-115.561336,33.073153],[-115.647315,33.073647],[-115.638526,33.015611]]]},"properties":{"id":"92281","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.888232,33.394376],[-116.000835,33.234878],[-115.853808,33.122874],[-115.754944,33.156409],[-115.720795,33.201744],[-115.888232,33.394376]]]},"properties":{"id":"92275","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.128163,36.564716],[-118.166881,36.459713],[-118.035662,36.443605],[-118.128163,36.564716]]]},"properties":{"id":"93545","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.25242,35.30672],[-119.129463,35.35339],[-119.09886,35.434289],[-119.145529,35.484981],[-119.331654,35.441942],[-119.341235,35.3254],[-119.25242,35.30672]]]},"properties":{"id":"93314","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.653002,35.616265],[-119.968877,35.789498],[-119.711172,35.449445],[-119.733807,35.49904],[-119.650154,35.611752],[-119.653002,35.616265]]]},"properties":{"id":"93249","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.098157,36.074646],[-119.985656,36.09426],[-119.977051,36.168163],[-120.098157,36.074646]]]},"properties":{"id":"93239","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.948892,38.901175],[-122.817363,38.846079],[-122.724198,38.861317],[-122.697791,38.896698],[-122.710646,38.980025],[-122.740794,39.025673],[-122.742839,39.026863],[-122.81612,39.018342],[-122.949674,38.91586],[-122.948892,38.901175]]]},"properties":{"id":"95451","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.866168,39.004248],[-122.866314,39.011205],[-122.871315,39.011215],[-122.872961,39.004306],[-122.866168,39.004248]]]},"properties":{"id":"95435","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.602978,40.285581],[-120.460673,40.205777],[-120.436029,40.239004],[-120.329854,40.300331],[-120.370455,40.318152],[-120.488375,40.353922],[-120.642466,40.294739],[-120.602978,40.285581]]]},"properties":{"id":"96114","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.370312,34.201197],[-118.422619,34.221661],[-118.426702,34.207345],[-118.422521,34.193937],[-118.42247,34.193937],[-118.361049,34.193974],[-118.370312,34.201197]]]},"properties":{"id":"91605","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.668153,34.17675],[-118.64127,34.157924],[-118.562201,34.173325],[-118.562222,34.184061],[-118.562228,34.19026],[-118.570953,34.185695],[-118.579648,34.181275],[-118.579702,34.188376],[-118.588445,34.188346],[-118.614625,34.186451],[-118.668153,34.184926],[-118.668153,34.17675]]]},"properties":{"id":"91367","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.16555,34.645748],[-118.129963,34.645863],[-118.129926,34.645864],[-118.14065,34.762686],[-118.140598,34.791788],[-118.170998,34.791439],[-118.16555,34.645748]]]},"properties":{"id":"93534","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.677055,34.708887],[-118.40611,34.644992],[-118.378184,34.644826],[-118.329637,34.662693],[-118.660972,34.783174],[-118.677055,34.708887]]]},"properties":{"id":"93532","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.054303,34.646],[-118.022683,34.470113],[-117.96942,34.579729],[-117.991743,34.660588],[-118.054303,34.646]]]},"properties":{"id":"93552","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.205608,33.790184],[-118.204255,33.831815],[-118.204215,33.839699],[-118.206591,33.846567],[-118.220399,33.846572],[-118.233976,33.846646],[-118.236774,33.839231],[-118.23751,33.80048],[-118.227512,33.790368],[-118.205608,33.790184]]]},"properties":{"id":"90810","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.109848,33.909815],[-118.107997,33.887708],[-118.087806,33.880338],[-118.074895,33.880383],[-118.055385,33.887789],[-118.089953,33.93167],[-118.111518,33.920123],[-118.109848,33.909815]]]},"properties":{"id":"90650","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.919932,33.945958],[-117.868374,33.945936],[-117.857926,33.959138],[-117.87279,34.002645],[-117.89104,34.003416],[-117.94985,34.011138],[-117.942232,33.974297],[-117.919932,33.945958]]]},"properties":{"id":"91748","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.872656,34.106135],[-117.872464,34.0942],[-117.872395,34.074913],[-117.862794,34.051975],[-117.826084,34.062406],[-117.826964,34.064219],[-117.837641,34.106611],[-117.872656,34.106135]]]},"properties":{"id":"91724","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.872656,34.106135],[-117.881448,34.114055],[-117.925363,34.106965],[-117.941969,34.085893],[-117.916563,34.084655],[-117.903308,34.079027],[-117.872464,34.0942],[-117.872656,34.106135]]]},"properties":{"id":"91722","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.167664,33.768428],[-118.169788,33.74847],[-118.118946,33.740577],[-118.097404,33.77419],[-118.115271,33.775316],[-118.121256,33.775319],[-118.1227,33.775322],[-118.167664,33.768428]]]},"properties":{"id":"90803","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.176302,33.811647],[-118.167645,33.791555],[-118.149426,33.789836],[-118.151346,33.804427],[-118.161798,33.811664],[-118.176302,33.811647]]]},"properties":{"id":"90755","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.186257,33.904797],[-118.187183,33.885404],[-118.151294,33.879807],[-118.142734,33.902455],[-118.156237,33.917044],[-118.17667,33.918165],[-118.186257,33.904797]]]},"properties":{"id":"90723","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.284334,34.068528],[-118.337348,34.068911],[-118.337149,34.064928],[-118.321412,34.063581],[-118.319959,34.063547],[-118.296637,34.063589],[-118.295365,34.063595],[-118.292914,34.063604],[-118.291644,34.063683],[-118.289914,34.063691],[-118.284302,34.063715],[-118.284334,34.068528]]]},"properties":{"id":"90020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.338167,34.058981],[-118.363818,34.058183],[-118.364894,34.058305],[-118.370497,34.04141],[-118.369801,34.040019],[-118.329738,34.039872],[-118.308999,34.039939],[-118.309064,34.055846],[-118.338167,34.058981]]]},"properties":{"id":"90019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.420263,34.454322],[-118.289695,34.394764],[-118.252997,34.536544],[-118.247535,34.553194],[-118.378184,34.644826],[-118.40611,34.644992],[-118.545353,34.503942],[-118.537838,34.460191],[-118.435969,34.472246],[-118.420263,34.454322]]]},"properties":{"id":"91390","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.656665,34.419931],[-118.6929,34.406088],[-118.670593,34.360692],[-118.547357,34.324675],[-118.545017,34.329934],[-118.569579,34.387329],[-118.656665,34.419931]]]},"properties":{"id":"91381","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.318276,33.775218],[-118.425449,33.76085],[-118.335337,33.71421],[-118.301,33.757721],[-118.302001,33.760511],[-118.309135,33.776486],[-118.318276,33.775218]]]},"properties":{"id":"90275","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.4487,34.164855],[-118.443396,34.130308],[-118.442158,34.130898],[-118.414903,34.130982],[-118.413776,34.156634],[-118.412785,34.164869],[-118.4487,34.164855]]]},"properties":{"id":"91423","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.207998,34.188166],[-118.233064,34.165664],[-118.255148,34.158701],[-118.254946,34.146273],[-118.228774,34.146032],[-118.183878,34.149081],[-118.183985,34.159065],[-118.189103,34.177312],[-118.207998,34.188166]]]},"properties":{"id":"91206","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.425965,33.96588],[-118.428775,33.93097],[-118.378429,33.929008],[-118.374342,33.923661],[-118.368387,33.930946],[-118.370037,33.94542],[-118.36985,33.963824],[-118.370231,33.979929],[-118.386404,33.97709],[-118.397635,33.982093],[-118.425965,33.96588]]]},"properties":{"id":"90045","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.309282,34.105381],[-118.34484,34.104012],[-118.344086,34.094327],[-118.3092,34.093606],[-118.309211,34.09489],[-118.309282,34.105381]]]},"properties":{"id":"90028","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.418235,34.012109],[-118.409086,33.981247],[-118.397635,33.982093],[-118.386404,33.97709],[-118.369448,33.998072],[-118.373093,34.011913],[-118.41692,34.012887],[-118.418235,34.012109]]]},"properties":{"id":"90230","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.280329,34.017493],[-118.280224,34.021961],[-118.291538,34.025497],[-118.291535,34.018157],[-118.280329,34.017493]]]},"properties":{"id":"90089","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.420263,34.454322],[-118.435969,34.472246],[-118.485404,34.404437],[-118.464898,34.405523],[-118.420263,34.454322]]]},"properties":{"id":"91351","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.537838,34.460191],[-118.545353,34.503942],[-118.554719,34.503916],[-118.57907,34.460992],[-118.539045,34.427982],[-118.537838,34.460191]]]},"properties":{"id":"91354","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.317619,34.00369],[-118.357888,33.997112],[-118.358544,33.981667],[-118.33521,33.974447],[-118.317747,33.970923],[-118.317131,33.989102],[-118.317619,34.00369]]]},"properties":{"id":"90043","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.230013,33.961768],[-118.265118,33.96013],[-118.265158,33.939292],[-118.226772,33.937096],[-118.226507,33.938196],[-118.229647,33.9612],[-118.230013,33.961768]]]},"properties":{"id":"90002","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.291638,34.061793],[-118.291644,34.063683],[-118.292914,34.063604],[-118.291638,34.061793]]],[[[-118.291638,34.061793],[-118.295365,34.063595],[-118.296637,34.063589],[-118.298716,34.059733],[-118.319959,34.063547],[-118.321412,34.063581],[-118.337054,34.060564],[-118.338167,34.058981],[-118.309064,34.055846],[-118.284238,34.055845],[-118.284302,34.063715],[-118.289914,34.063691],[-118.291638,34.061793]]]]},"properties":{"id":"90005","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.378219,33.854993],[-118.390308,33.854854],[-118.404713,33.850466],[-118.401179,33.804349],[-118.374417,33.804213],[-118.372924,33.827657],[-118.378219,33.854993]]]},"properties":{"id":"90277","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.077214,34.103491],[-118.073262,34.090412],[-118.056761,34.085103],[-118.02591,34.095858],[-118.030768,34.107182],[-118.068047,34.120399],[-118.077214,34.103491]]]},"properties":{"id":"91780","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.820295,34.345263],[-117.650459,34.339025],[-117.655213,34.396953],[-117.655235,34.397222],[-117.859977,34.470912],[-117.820295,34.345263]]]},"properties":{"id":"93563","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.005865,36.909377],[-120.181082,37.061828],[-120.457208,36.965746],[-120.318853,36.865572],[-120.277536,36.768747],[-120.273649,36.774175],[-120.0246,36.816523],[-119.932551,36.84299],[-119.932338,36.843323],[-120.005865,36.909377]]]},"properties":{"id":"93637","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.564323,37.144642],[-119.596797,37.243364],[-119.747466,37.098233],[-119.564323,37.144642]]]},"properties":{"id":"93645","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.492784,37.875426],[-122.457283,37.859227],[-122.471143,37.920344],[-122.502985,37.909325],[-122.492784,37.875426]]]},"properties":{"id":"94920","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.862251,38.112335],[-122.960979,38.124925],[-122.742936,37.921288],[-122.741886,37.984926],[-122.772693,38.067944],[-122.776158,38.12051],[-122.807629,38.141206],[-122.862251,38.112335]]]},"properties":{"id":"94956","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.48749,38.04735],[-122.606192,38.058021],[-122.615592,38.020222],[-122.61277,38.022221],[-122.557589,37.993322],[-122.485361,38.01716],[-122.48749,38.04735]]]},"properties":{"id":"94903","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.513577,37.943869],[-122.509538,37.951875],[-122.540474,37.963827],[-122.552986,37.965543],[-122.556889,37.96227],[-122.58076,37.96581],[-122.595243,37.925665],[-122.553027,37.926011],[-122.519248,37.939362],[-122.5029,37.941778],[-122.513577,37.943869]]]},"properties":{"id":"94904","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.663618,38.022299],[-122.690174,38.030169],[-122.690172,38.024481],[-122.67971,38.003531],[-122.653876,37.99995],[-122.663618,38.022299]]]},"properties":{"id":"94963","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.634249,37.994647],[-122.616562,38.019646],[-122.663618,38.022299],[-122.653876,37.99995],[-122.634249,37.994647]]]},"properties":{"id":"94973","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.758947,38.0499],[-122.690174,38.030169],[-122.663618,38.022299],[-122.616562,38.019646],[-122.615592,38.020222],[-122.606192,38.058021],[-122.603717,38.076063],[-122.698272,38.140042],[-122.776158,38.12051],[-122.772693,38.067944],[-122.758947,38.0499]]]},"properties":{"id":"94946","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.791149,37.677372],[-119.700329,37.579244],[-119.658297,37.53382],[-119.583588,37.560178],[-119.805236,37.758117],[-119.8425,37.763252],[-119.791149,37.677372]]]},"properties":{"id":"95389","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.245985,38.932853],[-123.315109,38.990342],[-123.43504,38.993863],[-123.45664,38.914314],[-123.368261,38.782034],[-123.347079,38.806973],[-123.26871,38.808369],[-123.245985,38.932853]]]},"properties":{"id":"95494","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.78078,39.36509],[-123.55479,39.351546],[-123.556224,39.35603],[-123.709799,39.572079],[-123.771709,39.55335],[-123.826295,39.376885],[-123.78078,39.36509]]]},"properties":{"id":"95437","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.222507,37.138378],[-120.997354,37.013834],[-120.943852,37.29178],[-121.222507,37.138378]]]},"properties":{"id":"95322","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.35218,37.203259],[-120.361173,37.295266],[-120.510042,37.307875],[-120.542866,37.321497],[-120.70117,37.22991],[-120.664558,37.185283],[-120.62032,37.157023],[-120.430314,37.14177],[-120.35218,37.203259]]]},"properties":{"id":"95341","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.360732,41.907871],[-120.354155,41.857332],[-120.225779,41.948202],[-120.320776,41.972092],[-120.360732,41.907871]]]},"properties":{"id":"97635","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.737279,41.517835],[-120.932399,41.400842],[-120.820167,41.309536],[-120.806354,41.269516],[-120.650852,41.226349],[-120.207412,41.344431],[-120.264836,41.58312],[-120.274022,41.652982],[-120.46739,41.651883],[-120.737279,41.517835]]]},"properties":{"id":"96101","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.785869,36.376859],[-121.894808,36.3023],[-121.34978,35.795193],[-121.243778,35.794525],[-121.397613,36.079071],[-121.549561,36.233382],[-121.785869,36.376859]]]},"properties":{"id":"93920","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.002724,35.968807],[-121.114001,35.86259],[-121.144957,35.793989],[-120.858616,35.755675],[-120.731594,35.791118],[-120.790518,35.923377],[-121.002724,35.968807]]]},"properties":{"id":"93426","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.412175,38.46459],[-122.433105,38.453343],[-122.416968,38.452478],[-122.412175,38.46459]]]},"properties":{"id":"94573","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.083832,39.511202],[-120.066755,39.374713],[-120.005903,39.380839],[-120.001774,39.536772],[-120.050836,39.548302],[-120.083832,39.511202]]]},"properties":{"id":"96111","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.876434,33.75577],[-117.907224,33.752682],[-117.941985,33.745019],[-117.88513,33.73446],[-117.87648,33.7345],[-117.876434,33.75577]]]},"properties":{"id":"92703","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.732304,33.794891],[-117.776218,33.822924],[-117.798847,33.813123],[-117.81533,33.802116],[-117.830873,33.787818],[-117.830866,33.773261],[-117.830273,33.773257],[-117.76169,33.77661],[-117.75851,33.78217],[-117.747294,33.784917],[-117.732304,33.794891]]]},"properties":{"id":"92869","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.898081,33.799726],[-117.898041,33.856305],[-117.906717,33.85447],[-117.931765,33.832266],[-117.898081,33.799726]]]},"properties":{"id":"92805","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.864007,33.671822],[-117.86174,33.651164],[-117.824529,33.650075],[-117.778042,33.657588],[-117.794998,33.663279],[-117.857229,33.682685],[-117.864007,33.671822]]]},"properties":{"id":"92612","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.70023,33.568878],[-117.716087,33.597294],[-117.747973,33.597976],[-117.740718,33.537773],[-117.70023,33.568878]]]},"properties":{"id":"92656","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.733257,33.49814],[-117.686633,33.487293],[-117.67276,33.54737],[-117.671731,33.574169],[-117.70023,33.568878],[-117.740718,33.537773],[-117.733257,33.49814]]]},"properties":{"id":"92677","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.837065,33.864745],[-117.883053,33.860035],[-117.898041,33.856305],[-117.898081,33.799726],[-117.894463,33.796156],[-117.876911,33.802185],[-117.870228,33.816584],[-117.843018,33.846896],[-117.837065,33.864745]]]},"properties":{"id":"92806","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.011398,33.873314],[-117.984822,33.856658],[-117.981132,33.857941],[-117.98535,33.895603],[-118.011398,33.873314]]]},"properties":{"id":"90621","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.011398,33.873314],[-118.028679,33.873219],[-118.028581,33.866246],[-118.035093,33.839068],[-118.017303,33.80993],[-118.007708,33.831916],[-117.984822,33.856658],[-118.011398,33.873314]]]},"properties":{"id":"90620","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.007708,33.831916],[-117.941478,33.828862],[-117.931765,33.832266],[-117.906717,33.85447],[-117.911291,33.863732],[-117.94179,33.854343],[-117.981132,33.857941],[-117.984822,33.856658],[-118.007708,33.831916]]]},"properties":{"id":"92801","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.94185,33.733298],[-117.930693,33.701862],[-117.8833,33.69411],[-117.88513,33.73446],[-117.941985,33.745019],[-117.94185,33.733298]]]},"properties":{"id":"92704","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.841984,33.610705],[-117.889794,33.609207],[-117.88219,33.600422],[-117.877408,33.587959],[-117.869241,33.584187],[-117.841984,33.610705]]]},"properties":{"id":"92625","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.86174,33.651164],[-117.84183,33.6311],[-117.824529,33.650075],[-117.86174,33.651164]]]},"properties":{"id":"92617","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.017831,38.969809],[-121.185924,38.934013],[-121.085791,38.83445],[-121.057183,38.877489],[-121.037502,38.915689],[-120.966104,38.97175],[-120.972081,38.995592],[-120.97892,39.011712],[-121.017831,38.969809]]]},"properties":{"id":"95603","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.137766,38.836559],[-121.217458,38.862613],[-121.242152,38.839205],[-121.230485,38.822467],[-121.206934,38.774198],[-121.122499,38.782232],[-121.101444,38.817229],[-121.137766,38.836559]]]},"properties":{"id":"95650","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.993931,39.013748],[-121.017831,38.969809],[-120.97892,39.011712],[-120.975244,39.034502],[-120.976156,39.03248],[-120.993931,39.013748]]],[[[-120.972081,38.995592],[-120.966104,38.97175],[-120.955651,38.991996],[-120.972081,38.995592]]]]},"properties":{"id":"95703","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.157097,39.107204],[-120.198007,39.064371],[-120.152976,39.045807],[-120.139106,39.074332],[-120.157097,39.107204]]]},"properties":{"id":"96141","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.487734,38.915743],[-121.494358,38.751231],[-121.416694,38.771557],[-121.414342,38.841512],[-121.45726,38.92604],[-121.487734,38.915743]]]},"properties":{"id":"95668","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.690523,39.924456],[-120.97162,40.041759],[-120.984105,40.041915],[-120.999631,40.038874],[-121.02696,40.021758],[-121.049629,39.958367],[-121.022391,39.947247],[-120.732183,39.896053],[-120.690523,39.924456]]]},"properties":{"id":"95971","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.568929,39.761231],[-120.603597,39.706561],[-120.493081,39.706608],[-120.487992,39.706581],[-120.485563,39.756278],[-120.568929,39.761231]]]},"properties":{"id":"96106","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.388418,33.772574],[-116.460047,33.713622],[-116.443046,33.642025],[-116.407876,33.668765],[-116.372951,33.670234],[-116.356335,33.728883],[-116.388418,33.772574]]]},"properties":{"id":"92260","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.283244,33.794816],[-116.303864,33.736466],[-116.266715,33.73801],[-116.194694,33.719966],[-116.181536,33.747628],[-116.283244,33.794816]]]},"properties":{"id":"92203","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.450178,33.810417],[-117.569175,33.76461],[-117.534137,33.712584],[-117.457862,33.690219],[-117.456158,33.68916],[-117.393486,33.72988],[-117.450178,33.810417]]]},"properties":{"id":"92883","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.053891,33.787357],[-116.989277,33.772859],[-116.973389,33.772952],[-117.003606,33.833634],[-117.06836,33.852965],[-117.053891,33.787357]]]},"properties":{"id":"92582","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.151535,33.500743],[-117.168241,33.530065],[-117.322403,33.475031],[-117.143979,33.447295],[-117.151535,33.500743]]]},"properties":{"id":"92590","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.396713,33.810363],[-116.301228,33.803855],[-116.44095,33.859368],[-116.440755,33.836344],[-116.396713,33.810363]]]},"properties":{"id":"92276","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.501299,33.894249],[-117.506258,33.869004],[-117.440409,33.825485],[-117.368024,33.833537],[-117.465357,33.962459],[-117.501299,33.894249]]]},"properties":{"id":"92503","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.357236,33.891544],[-117.399111,33.975502],[-117.427644,33.968482],[-117.466055,33.964413],[-117.465357,33.962459],[-117.368024,33.833537],[-117.342549,33.858041],[-117.357236,33.891544]]]},"properties":{"id":"92504","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.800228,33.90536],[-116.754513,33.885941],[-116.719918,33.887988],[-116.737249,33.932892],[-116.824501,33.928199],[-116.800228,33.90536]]]},"properties":{"id":"92230","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.081185,33.970632],[-116.990247,33.990775],[-117.064693,34.003646],[-117.081185,33.970632]]]},"properties":{"id":"92320","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.370274,34.019444],[-117.427644,33.968482],[-117.399111,33.975502],[-117.376397,33.969054],[-117.339737,34.019426],[-117.370274,34.019444]]]},"properties":{"id":"92501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.27893,33.942724],[-117.208911,33.93932],[-117.210146,33.961215],[-117.311485,34.008519],[-117.27893,33.942724]]]},"properties":{"id":"92557","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.226976,33.703446],[-117.180335,33.706739],[-117.223042,33.728524],[-117.228084,33.722818],[-117.226976,33.703446]]]},"properties":{"id":"92586","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.514121,38.388742],[-121.486884,38.422933],[-121.465296,38.459788],[-121.466354,38.481526],[-121.505073,38.481269],[-121.504139,38.466563],[-121.514121,38.388742]]]},"properties":{"id":"95832","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.482674,38.565148],[-121.462649,38.582345],[-121.447198,38.58773],[-121.505005,38.60029],[-121.508668,38.596803],[-121.506275,38.586288],[-121.508522,38.574534],[-121.482674,38.565148]]]},"properties":{"id":"95811","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.375284,38.647397],[-121.363247,38.658272],[-121.363645,38.703274],[-121.401318,38.704508],[-121.401365,38.690542],[-121.404045,38.639788],[-121.406793,38.637239],[-121.375284,38.647397]]]},"properties":{"id":"95660","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.469519,38.629945],[-121.562835,38.627106],[-121.54789,38.598774],[-121.508668,38.596803],[-121.505005,38.60029],[-121.467588,38.61974],[-121.469519,38.629945]]]},"properties":{"id":"95833","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.345933,38.645476],[-121.355784,38.610128],[-121.346287,38.577748],[-121.328421,38.599925],[-121.291486,38.630273],[-121.312707,38.671133],[-121.328001,38.673216],[-121.345933,38.645476]]]},"properties":{"id":"95608","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.297884,38.519113],[-121.29755,38.452701],[-121.242456,38.505756],[-121.242743,38.525563],[-121.292451,38.535831],[-121.297884,38.519113]]]},"properties":{"id":"95830","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.369864,38.522089],[-121.334934,38.529004],[-121.359211,38.57175],[-121.409265,38.560434],[-121.419995,38.562441],[-121.421645,38.55102],[-121.407582,38.525073],[-121.4022,38.523931],[-121.369864,38.522089]]]},"properties":{"id":"95826","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.220417,38.33588],[-121.027084,38.300252],[-121.02724,38.397686],[-121.048772,38.409808],[-121.220417,38.33588]]]},"properties":{"id":"95638","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.32353,36.602671],[-121.30115,36.5376],[-121.295986,36.505209],[-121.095367,36.367994],[-121.063546,36.342322],[-120.743793,36.199121],[-120.597103,36.391314],[-120.931131,36.74999],[-121.294247,36.720418],[-121.319415,36.606174],[-121.32353,36.602671]]]},"properties":{"id":"95043","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.433364,34.694741],[-117.667206,34.763718],[-117.667112,34.643012],[-117.667034,34.558008],[-117.659992,34.557782],[-117.481846,34.506908],[-117.399406,34.517667],[-117.400799,34.591907],[-117.417866,34.670827],[-117.433364,34.694741]]]},"properties":{"id":"92301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.253219,34.492842],[-117.054295,34.450962],[-117.100497,34.71144],[-117.278588,34.557105],[-117.253219,34.492842]]]},"properties":{"id":"92307","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.383364,34.674405],[-117.295624,34.70274],[-117.224056,34.761554],[-117.240647,34.800241],[-117.259896,34.820269],[-117.433364,34.694741],[-117.417866,34.670827],[-117.383364,34.674405]]]},"properties":{"id":"92342","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.655213,34.396953],[-117.579061,34.401732],[-117.660052,34.498629],[-117.655235,34.397222],[-117.655213,34.396953]]]},"properties":{"id":"92372","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.314522,34.568598],[-117.295624,34.70274],[-117.383364,34.674405],[-117.337948,34.586373],[-117.314522,34.568598]]]},"properties":{"id":"92368","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.843105,34.227251],[-116.884409,34.260211],[-116.945141,34.236214],[-116.866099,34.214186],[-116.843105,34.227251]]]},"properties":{"id":"92315","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.089443,34.079783],[-114.548453,34.079723],[-114.481217,34.079809],[-114.48663,34.09412],[-115.05,34.22118],[-115.089443,34.079783]]]},"properties":{"id":"92280","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.7637,35.033014],[-116.775121,34.896697],[-116.831463,34.879915],[-116.71567,34.83169],[-116.713374,35.069453],[-116.7637,35.033014]]]},"properties":{"id":"92365","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.65056,33.968551],[-117.610913,33.960084],[-117.610482,33.968822],[-117.65056,33.968551]]],[[[-117.65056,33.968551],[-117.650627,33.983058],[-117.650819,34.004799],[-117.654503,34.021857],[-117.689527,34.04797],[-117.706907,34.046672],[-117.745378,34.020018],[-117.687255,33.971937],[-117.65056,33.968551]]]]},"properties":{"id":"91710","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-116.989645,34.121048],[-117.034747,34.091975],[-116.981875,34.082955],[-116.981683,34.120987],[-116.989645,34.121048]]],[[[-117.13891,34.09036],[-117.104315,34.040333],[-117.035966,34.091854],[-117.103468,34.102711],[-117.13891,34.09036]]]]},"properties":{"id":"92359","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.208773,34.162091],[-117.236471,34.123219],[-117.234945,34.106763],[-117.20878,34.099332],[-117.13891,34.09036],[-117.103468,34.102711],[-117.208773,34.162091]]]},"properties":{"id":"92346","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.28516,34.104577],[-117.300644,34.10499],[-117.294153,34.092149],[-117.28516,34.104577]]]},"properties":{"id":"92401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.935953,32.599246],[-116.930304,32.647675],[-116.971066,32.652934],[-116.965626,32.59032],[-116.935953,32.599246]]]},"properties":{"id":"91915","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.241325,32.932349],[-117.261181,32.938828],[-117.214044,32.875076],[-117.159788,32.87991],[-117.17741,32.926873],[-117.241325,32.932349]]]},"properties":{"id":"92121","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.071596,33.055914],[-117.083733,32.996662],[-117.106017,32.948201],[-117.06674,32.934435],[-117.041596,33.04312],[-117.071596,33.055914]]]},"properties":{"id":"92128","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.199861,33.080563],[-117.302334,33.090621],[-117.31314,33.086974],[-117.315819,33.088186],[-117.292284,33.03098],[-117.257118,33.011662],[-117.243409,33.011322],[-117.241909,33.021221],[-117.187061,33.061086],[-117.199861,33.080563]]]},"properties":{"id":"92024","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.163015,32.67617],[-117.157356,32.680223],[-117.154581,32.677652],[-117.163953,32.669997],[-117.168744,32.675408],[-117.188759,32.705379],[-117.192907,32.6879],[-117.199612,32.689958],[-117.191018,32.707868],[-117.135263,32.585616],[-117.122217,32.588915],[-117.163015,32.67617]]]},"properties":{"id":"92118","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.207123,32.788536],[-117.224222,32.75771],[-117.226715,32.74513],[-117.209555,32.742539],[-117.184275,32.740481],[-117.182322,32.742536],[-117.19633,32.759503],[-117.18321,32.767112],[-117.189612,32.798033],[-117.207123,32.788536]]]},"properties":{"id":"92110","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.131386,33.042784],[-117.172053,32.973859],[-117.146312,32.976272],[-117.083733,32.996662],[-117.071596,33.055914],[-117.069907,33.059509],[-117.131386,33.042784]]]},"properties":{"id":"92127","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.157935,32.701983],[-117.147574,32.705251],[-117.148336,32.721102],[-117.142667,32.726709],[-117.147529,32.741169],[-117.182322,32.742536],[-117.184275,32.740481],[-117.208701,32.734341],[-117.216513,32.725066],[-117.157935,32.701983]],[[-117.172803,32.711469],[-117.173104,32.715708],[-117.171098,32.715712],[-117.171057,32.711482],[-117.172803,32.711469]]]},"properties":{"id":"92101","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.067014,33.177844],[-117.070452,33.136083],[-116.920386,33.082833],[-116.893145,33.164136],[-117.067014,33.177844]]]},"properties":{"id":"92027","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.064344,32.688864],[-117.057359,32.663915],[-117.02134,32.688098],[-117.024911,32.697862],[-117.064344,32.688864]]]},"properties":{"id":"92139","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.302334,33.090621],[-117.266764,33.129532],[-117.31314,33.086974],[-117.302334,33.090621]]]},"properties":{"id":"92011","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.26917,33.300931],[-117.331152,33.223785],[-117.333422,33.208619],[-117.26134,33.241097],[-117.238859,33.256619],[-117.262154,33.286283],[-117.26917,33.300931]]]},"properties":{"id":"92057","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.243409,33.011322],[-117.241087,33.010753],[-117.241909,33.021221],[-117.243409,33.011322]]],[[[-117.211475,32.98534],[-117.205758,32.994439],[-117.213677,32.993488],[-117.217101,32.986519],[-117.211475,32.98534]]]]},"properties":{"id":"92091","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.710012,33.264395],[-116.616071,33.152005],[-116.453755,33.103392],[-116.516201,33.167489],[-116.585951,33.231504],[-116.500854,33.252384],[-116.501119,33.286415],[-116.617687,33.427354],[-116.681777,33.42722],[-116.857095,33.426574],[-116.710012,33.264395]]]},"properties":{"id":"92086","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.015694,33.361317],[-117.052261,33.43023],[-117.094558,33.430505],[-117.133006,33.342922],[-117.02092,33.343047],[-117.015694,33.361317]]]},"properties":{"id":"92059","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.477363,37.766062],[-122.477308,37.765448],[-122.457536,37.763566],[-122.454002,37.758785],[-122.428426,37.770452],[-122.429849,37.777919],[-122.444967,37.776958],[-122.477363,37.766062]]]},"properties":{"id":"94117","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.397812,37.784667],[-122.385181,37.791528],[-122.388981,37.796628],[-122.399149,37.791017],[-122.403431,37.787642],[-122.397812,37.784667]]]},"properties":{"id":"94105","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.310597,38.05758],[-121.323174,38.02107],[-121.29135,37.993217],[-121.281607,37.994453],[-121.277326,38.057416],[-121.287182,38.057463],[-121.310597,38.05758]]]},"properties":{"id":"95210","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.2533,37.816824],[-121.179284,37.774773],[-121.11708,37.826994],[-121.145587,37.855715],[-121.220155,37.880806],[-121.243092,37.879161],[-121.262989,37.826441],[-121.2533,37.816824]]]},"properties":{"id":"95336","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.74429,35.20597],[-120.716493,35.172643],[-120.719403,35.176164],[-120.74429,35.20597]]]},"properties":{"id":"93424","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.604316,35.446008],[-120.354864,35.49356],[-120.408349,35.530615],[-120.558254,35.534821],[-120.607261,35.484963],[-120.604316,35.446008]]]},"properties":{"id":"93432","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.440557,34.920446],[-120.433036,34.985983],[-120.560095,34.975559],[-120.558258,34.95741],[-120.440557,34.920446]]]},"properties":{"id":"93458","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.209011,37.467173],[-122.229133,37.424295],[-122.194195,37.409088],[-122.189879,37.42883],[-122.171706,37.44678],[-122.144933,37.4582],[-122.121447,37.476912],[-122.178182,37.498268],[-122.209011,37.467173]]]},"properties":{"id":"94025","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.288441,37.57388],[-122.293142,37.568005],[-122.276664,37.534223],[-122.265697,37.534076],[-122.249364,37.549193],[-122.288441,37.57388]]]},"properties":{"id":"94404","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.333032,37.587885],[-122.332418,37.565238],[-122.302729,37.560357],[-122.293142,37.568005],[-122.288441,37.57388],[-122.333032,37.587885]]]},"properties":{"id":"94401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.517347,37.586797],[-122.518982,37.53737],[-122.488993,37.533661],[-122.46514,37.554941],[-122.459786,37.566037],[-122.517347,37.586797]]]},"properties":{"id":"94037","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.826832,34.415857],[-119.825906,34.467872],[-120.01081,34.546874],[-120.12566,34.538875],[-120.174588,34.597832],[-120.253434,34.521261],[-120.417229,34.452265],[-119.826832,34.415857]]]},"properties":{"id":"93117","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.103843,34.650323],[-119.915769,34.719026],[-120.021022,34.836151],[-120.156311,34.72932],[-120.162567,34.724591],[-120.156988,34.685845],[-120.157064,34.681613],[-120.103843,34.650323]]]},"properties":{"id":"93441","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.549279,37.118158],[-121.621875,37.058721],[-121.749039,37.060624],[-121.581354,36.899152],[-121.512065,36.94804],[-121.48106,37.118201],[-121.549279,37.118158]]]},"properties":{"id":"95020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.791975,37.403516],[-121.714178,37.48361],[-121.831444,37.484241],[-121.887876,37.406952],[-121.862912,37.384459],[-121.846235,37.389068],[-121.791975,37.403516]]]},"properties":{"id":"95132","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.96418,37.3233],[-121.96589,37.3743],[-121.98697,37.37778],[-122.004415,37.376705],[-122.001736,37.352146],[-122.00254,37.3378],[-121.99986,37.32294],[-121.96836,37.32312],[-121.96418,37.3233]]]},"properties":{"id":"95051","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.892193,37.323887],[-121.931669,37.297222],[-121.922455,37.282627],[-121.906188,37.271395],[-121.877846,37.278496],[-121.843866,37.285738],[-121.861178,37.303594],[-121.870558,37.313033],[-121.892193,37.323887]]]},"properties":{"id":"95125","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.950212,37.328381],[-121.932957,37.337815],[-121.931845,37.351074],[-121.94575,37.37352],[-121.96589,37.3743],[-121.96418,37.3233],[-121.950212,37.328381]],[[-121.94184,37.35074],[-121.93336,37.34963],[-121.93358,37.34519],[-121.94184,37.35074]]]},"properties":{"id":"95050","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.117852,37.40425],[-122.09601,37.38645],[-122.063002,37.359236],[-122.060076,37.375435],[-122.058148,37.381386],[-122.091312,37.400534],[-122.10831,37.40808],[-122.117852,37.40425]]]},"properties":{"id":"94040","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.967936,37.418922],[-121.974706,37.46074],[-122.059936,37.445624],[-122.041135,37.427012],[-121.986838,37.412059],[-121.967936,37.418922]]]},"properties":{"id":"95002","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.979518,37.300418],[-121.994554,37.289476],[-121.990655,37.269106],[-121.96452,37.301166],[-121.979518,37.300418]]]},"properties":{"id":"95130","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.01305,36.958935],[-121.95046,36.967409],[-121.965518,36.982722],[-121.971059,36.983241],[-122.013275,36.988546],[-122.01305,36.958935]]]},"properties":{"id":"95062","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.766281,36.936367],[-121.787878,36.944957],[-121.783799,36.930906],[-121.766281,36.936367]]]},"properties":{"id":"95019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.873573,40.707468],[-122.008382,40.640626],[-121.943904,40.561824],[-121.8017,40.575187],[-121.873573,40.707468]]]},"properties":{"id":"96096","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.972625,40.502012],[-123.112755,40.397747],[-123.065432,40.291883],[-123.065426,40.286971],[-122.710322,40.303059],[-122.709274,40.347991],[-122.846764,40.494806],[-122.972625,40.502012]]]},"properties":{"id":"96076","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.709274,40.347991],[-122.443277,40.412288],[-122.524932,40.513925],[-122.531522,40.553925],[-122.693302,40.577815],[-122.846764,40.494806],[-122.709274,40.347991]]]},"properties":{"id":"96047","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.103329,40.675943],[-121.968608,40.75543],[-122.058561,40.772268],[-122.23455,40.614912],[-122.103329,40.675943]]]},"properties":{"id":"96008","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.033387,39.576763],[-120.050836,39.548302],[-120.001774,39.536772],[-120.0015,39.577356],[-120.033387,39.576763]]]},"properties":{"id":"89439","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.592447,39.522242],[-120.248291,39.550341],[-120.420356,39.602281],[-120.472868,39.5746],[-120.592447,39.522242]]]},"properties":{"id":"96126","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.731746,39.507131],[-120.833116,39.526421],[-120.937938,39.45168],[-120.817787,39.438043],[-120.731443,39.470257],[-120.731746,39.507131]]]},"properties":{"id":"95910","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.547441,41.382283],[-123.493634,41.377464],[-123.428997,41.374229],[-123.282973,41.468439],[-123.547441,41.382283]]]},"properties":{"id":"95568","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.933753,38.3013],[-122.023883,38.325081],[-122.02545,38.322733],[-122.041278,38.243631],[-121.96496,38.266844],[-121.933753,38.3013]]]},"properties":{"id":"94533","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.96496,38.266844],[-121.914826,38.27921],[-121.933753,38.3013],[-121.96496,38.266844]]]},"properties":{"id":"94535","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.041278,38.243631],[-122.02545,38.322733],[-122.064779,38.315912],[-122.201067,38.271974],[-122.211081,38.252215],[-122.204167,38.209699],[-122.201893,38.168299],[-122.162843,38.101196],[-122.113885,38.170914],[-122.068417,38.192697],[-122.041278,38.243631]]]},"properties":{"id":"94534","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.821875,38.116458],[-121.806186,38.183597],[-121.842475,38.271398],[-121.914826,38.27921],[-121.96496,38.266844],[-122.041278,38.243631],[-122.068417,38.192697],[-121.861597,38.069887],[-121.821875,38.116458]]]},"properties":{"id":"94585","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.27723,38.109841],[-122.301467,38.105532],[-122.279962,38.080898],[-122.248065,38.076636],[-122.27723,38.109841]]]},"properties":{"id":"94592","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.806186,38.183597],[-121.821875,38.116458],[-121.786561,38.147883],[-121.806186,38.183597]]]},"properties":{"id":"94512","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.036577,38.486667],[-123.050004,38.453927],[-123.049083,38.454198],[-123.053035,38.461489],[-123.047211,38.464806],[-123.046589,38.459067],[-123.048786,38.454327],[-123.000281,38.449437],[-123.00225,38.458737],[-123.036577,38.486667]],[[-123.023327,38.47491],[-123.023099,38.47383],[-123.025093,38.473057],[-123.024829,38.475448],[-123.023327,38.47491]]]},"properties":{"id":"95462","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.317492,38.558665],[-123.050004,38.453927],[-123.036577,38.486667],[-123.056505,38.576131],[-123.075604,38.655619],[-123.104207,38.740195],[-123.26871,38.808369],[-123.347079,38.806973],[-123.36458,38.651753],[-123.403019,38.64943],[-123.317492,38.558665]]]},"properties":{"id":"95421","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.91632,38.349518],[-122.749855,38.33297],[-122.74128,38.352148],[-122.794997,38.419752],[-122.851614,38.482013],[-122.972423,38.461513],[-123.00225,38.458737],[-123.000281,38.449437],[-122.914728,38.364487],[-122.91632,38.349518]],[[-122.869891,38.437441],[-122.864061,38.431674],[-122.871381,38.427581],[-122.873748,38.434448],[-122.869891,38.437441]]]},"properties":{"id":"95472","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.498956,38.778127],[-123.36458,38.651753],[-123.347079,38.806973],[-123.368261,38.782034],[-123.498956,38.778127]]]},"properties":{"id":"95412","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.869891,38.437441],[-122.873748,38.434448],[-122.871381,38.427581],[-122.864061,38.431674],[-122.869891,38.437441]]]},"properties":{"id":"95444","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.434496,39.071664],[-121.513979,39.065158],[-121.493489,38.985104],[-121.414625,38.996014],[-121.408953,39.001159],[-121.321631,39.043753],[-121.279533,39.034618],[-121.279715,39.098596],[-121.322401,39.107083],[-121.385451,39.075216],[-121.434496,39.071664]]]},"properties":{"id":"95692","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.275861,40.029412],[-122.515985,39.990355],[-122.490214,39.886897],[-122.478382,39.865931],[-122.337648,39.813178],[-122.057905,39.822906],[-122.038795,39.883703],[-122.067182,39.955624],[-122.117411,40.017714],[-122.144356,40.011556],[-122.275861,40.029412]]]},"properties":{"id":"96021","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.534219,39.959802],[-122.490214,39.886897],[-122.515985,39.990355],[-122.534219,39.959802]]]},"properties":{"id":"96029","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.978384,40.832304],[-123.037118,41.004176],[-123.08654,41.05795],[-123.111623,40.703602],[-123.024553,40.612086],[-122.997916,40.693539],[-122.978384,40.832304]]]},"properties":{"id":"96048","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.179531,35.935481],[-119.394259,35.876196],[-119.427821,35.790092],[-119.401017,35.790121],[-119.107825,35.858589],[-119.125947,35.891803],[-119.179531,35.935481]]]},"properties":{"id":"93219","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.426729,35.920192],[-119.394259,35.876196],[-119.179531,35.935481],[-119.197272,35.99341],[-119.214629,36.015041],[-119.375519,36.007593],[-119.426729,35.920192]]]},"properties":{"id":"93256","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.304965,36.588202],[-119.313657,36.530541],[-119.240831,36.507008],[-119.17863,36.5002],[-119.059487,36.577421],[-119.010491,36.596678],[-119.014425,36.657353],[-119.088295,36.657514],[-119.214393,36.659838],[-119.304965,36.588202]]]},"properties":{"id":"93647","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.179531,35.935481],[-119.125947,35.891803],[-118.991149,35.90637],[-119.197272,35.99341],[-119.179531,35.935481]]]},"properties":{"id":"93270","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.196593,36.116442],[-118.893831,36.114406],[-118.913329,36.225601],[-118.966453,36.225614],[-119.187953,36.210764],[-119.196593,36.116442]]]},"properties":{"id":"93267","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.225064,36.370792],[-119.206604,36.399409],[-119.224452,36.399472],[-119.225064,36.370792]]]},"properties":{"id":"93235","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.747655,38.312671],[-119.868005,38.357089],[-119.919874,38.32723],[-119.747655,38.312671]]]},"properties":{"id":"95314","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.344549,37.83002],[-120.306651,37.81027],[-120.245298,37.750543],[-120.244254,37.789658],[-120.339986,37.841365],[-120.344549,37.83002]]]},"properties":{"id":"95305","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.990735,34.422505],[-119.441411,34.73327],[-119.442354,34.49095],[-119.392332,34.41387],[-119.336984,34.417572],[-119.290738,34.417475],[-119.296371,34.390095],[-119.174243,34.391774],[-118.990735,34.422505]]]},"properties":{"id":"93023","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.877062,38.561285],[-121.767176,38.602731],[-121.774517,38.79288],[-121.94592,38.777235],[-121.956119,38.703547],[-121.951938,38.665792],[-121.877062,38.561285]],[[-121.805295,38.734581],[-121.806934,38.726654],[-121.818683,38.735443],[-121.805295,38.734581]]]},"properties":{"id":"95695","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.774517,38.79288],[-121.767176,38.602731],[-121.730298,38.58902],[-121.636969,38.566405],[-121.643077,38.673213],[-121.661607,38.762627],[-121.670096,38.758968],[-121.774517,38.79288]]]},"properties":{"id":"95776","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.194594,37.773522],[-122.187944,37.777823],[-122.188586,37.784446],[-122.178401,37.783822],[-122.119812,37.731633],[-122.077715,37.748209],[-122.106302,37.802194],[-122.138402,37.80433],[-122.178199,37.804014],[-122.20984,37.788334],[-122.194594,37.773522]]]},"properties":{"id":"94619","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.295896,37.880417],[-122.309339,37.89786],[-122.311845,37.897514],[-122.309592,37.845543],[-122.289561,37.849907],[-122.295896,37.880417]]]},"properties":{"id":"94710","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.127391,37.729686],[-122.148725,37.740859],[-122.201813,37.713091],[-122.205374,37.702727],[-122.166947,37.666758],[-122.162676,37.667297],[-122.161705,37.667748],[-122.159817,37.705648],[-122.122274,37.727313],[-122.127391,37.729686]]]},"properties":{"id":"94577","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.012226,37.632891],[-122.041348,37.680986],[-122.074749,37.666626],[-122.012226,37.632891]]]},"properties":{"id":"94542","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.130142,37.690901],[-122.159817,37.705648],[-122.161705,37.667748],[-122.130142,37.690901]]]},"properties":{"id":"94579","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.462151,37.442334],[-121.566553,37.296239],[-121.454138,37.273979],[-121.412549,37.389435],[-121.465589,37.435929],[-121.462151,37.442334]]],[[[-121.770614,37.598469],[-121.608635,37.482634],[-121.472648,37.48217],[-121.471925,37.481783],[-121.556788,37.565102],[-121.630016,37.732514],[-121.819614,37.674599],[-121.804003,37.627936],[-121.770614,37.598469]]]]},"properties":{"id":"94550","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.86668,37.764212],[-121.871569,37.749456],[-121.870733,37.740086],[-121.844992,37.701321],[-121.819614,37.674599],[-121.630016,37.732514],[-121.670362,37.789427],[-121.697169,37.782692],[-121.764987,37.833301],[-121.777099,37.842889],[-121.824574,37.838995],[-121.86668,37.764212]]]},"properties":{"id":"94551","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.531466,37.742581],[-121.55696,37.798837],[-121.670362,37.789427],[-121.630016,37.732514],[-121.531466,37.742581]]]},"properties":{"id":"95391","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.266329,37.874188],[-122.245269,37.872799],[-122.246348,37.880976],[-122.255593,37.880194],[-122.266329,37.874188]]]},"properties":{"id":"94720","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.392904,38.200832],[-120.303123,38.273222],[-120.39723,38.258598],[-120.45085,38.202722],[-120.439893,38.192603],[-120.404088,38.194688],[-120.392904,38.200832]]],[[[-119.639205,38.32688],[-120.004764,38.580109],[-120.300012,38.274583],[-120.271009,38.270281],[-119.652476,38.336117],[-119.639205,38.32688]]]]},"properties":{"id":"95223","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.695043,39.831375],[-121.652138,39.948344],[-121.670861,39.945059],[-121.708567,39.855395],[-121.695043,39.831375]]],[[[-121.477274,40.065126],[-121.510559,40.100515],[-121.586407,40.100522],[-121.595647,40.057714],[-121.528209,40.032509],[-121.477274,40.065126]]]]},"properties":{"id":"95942","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.757851,39.676477],[-121.669755,39.751908],[-121.695043,39.831375],[-121.708567,39.855395],[-121.792488,39.761883],[-121.881856,39.731052],[-121.946615,39.725893],[-121.997493,39.667025],[-121.992528,39.533792],[-121.905994,39.536693],[-121.757851,39.676477]]]},"properties":{"id":"95928","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.881856,39.731052],[-121.792488,39.761883],[-121.853826,39.761262],[-121.881856,39.731052]]]},"properties":{"id":"95926","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.463617,38.209314],[-120.633279,38.25934],[-120.735404,38.242654],[-120.794049,38.165288],[-120.571644,38.125862],[-120.439893,38.192603],[-120.45085,38.202722],[-120.463617,38.209314]]]},"properties":{"id":"95249","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.083972,39.330622],[-122.007833,39.390094],[-121.994801,39.457316],[-122.037285,39.481197],[-122.120101,39.414544],[-122.083972,39.330622]]]},"properties":{"id":"95970","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.940929,39.133544],[-121.91441,38.925351],[-121.833602,39.040483],[-121.939172,39.136145],[-121.940929,39.133544]]]},"properties":{"id":"95950","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.049104,37.879319],[-122.061569,37.842983],[-122.063055,37.837134],[-122.065314,37.821049],[-122.049916,37.816342],[-122.037503,37.809702],[-121.965377,37.843433],[-121.972347,37.861161],[-122.007152,37.882483],[-122.049104,37.879319]]]},"properties":{"id":"94507","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.764987,37.833301],[-121.663303,37.812452],[-121.632197,37.889288],[-121.565594,37.918193],[-121.581378,37.98276],[-121.579842,38.006436],[-121.626643,38.006245],[-121.622143,37.976141],[-121.677631,37.968842],[-121.741501,37.981373],[-121.750475,37.945634],[-121.823735,37.918136],[-121.777099,37.842889],[-121.764987,37.833301]]]},"properties":{"id":"94513","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.751535,38.024277],[-121.756753,37.992454],[-121.741501,37.981373],[-121.677631,37.968842],[-121.651721,37.984597],[-121.622143,37.976141],[-121.626643,38.006245],[-121.732565,38.02902],[-121.751535,38.024277]]]},"properties":{"id":"94561","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.651291,38.0181],[-121.608099,38.019869],[-121.64338,38.04813],[-121.651291,38.0181]]]},"properties":{"id":"94511","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.827667,38.02792],[-121.86351,37.967598],[-121.822413,37.965345],[-121.756753,37.992454],[-121.751535,38.024277],[-121.827667,38.02792]]]},"properties":{"id":"94509","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.756753,37.992454],[-121.822413,37.965345],[-121.750475,37.945634],[-121.741501,37.981373],[-121.756753,37.992454]]]},"properties":{"id":"94531","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.145384,37.82028],[-122.065314,37.821049],[-122.063055,37.837134],[-122.142116,37.870876],[-122.161334,37.840213],[-122.151337,37.825103],[-122.145384,37.82028]],[[-122.112865,37.843659],[-122.105938,37.842946],[-122.109468,37.837916],[-122.113955,37.837896],[-122.112865,37.843659]]]},"properties":{"id":"94556","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.965377,37.843433],[-122.037503,37.809702],[-121.964458,37.781461],[-121.948725,37.795786],[-121.967251,37.835309],[-121.965377,37.843433]]]},"properties":{"id":"94526","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.622143,37.976141],[-121.651721,37.984597],[-121.677631,37.968842],[-121.622143,37.976141]]]},"properties":{"id":"94548","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.234091,38.044255],[-122.206125,38.046181],[-122.2017,38.053628],[-122.225178,38.061006],[-122.225885,38.061018],[-122.232079,38.06182],[-122.269942,38.058313],[-122.234091,38.044255]]]},"properties":{"id":"94525","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.036781,38.772992],[-121.009233,38.686756],[-120.938545,38.724421],[-121.007507,38.763748],[-121.036781,38.772992]]]},"properties":{"id":"95672","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.867011,38.892254],[-120.744221,38.872565],[-120.692824,38.866989],[-120.746319,39.003514],[-120.902107,38.953155],[-120.867011,38.892254]]]},"properties":{"id":"95634","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.645929,36.667907],[-119.709429,36.583637],[-119.610566,36.634396],[-119.645929,36.667907]]]},"properties":{"id":"93625","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.211584,36.429117],[-120.443053,36.40537],[-120.597103,36.391314],[-120.743793,36.199121],[-120.725068,36.175584],[-120.477816,36.003283],[-120.315068,35.907186],[-120.182053,36.010641],[-120.154911,36.031359],[-120.134846,36.111727],[-120.21104,36.269169],[-120.211154,36.283536],[-120.157118,36.429491],[-120.211584,36.429117]]]},"properties":{"id":"93210","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.025315,36.553785],[-120.052428,36.59645],[-120.093683,36.563591],[-120.063803,36.531654],[-120.025315,36.553785]]],[[[-120.04343,36.504459],[-120.169925,36.531309],[-120.157907,36.487274],[-120.031298,36.433722],[-120.04343,36.504459]]]]},"properties":{"id":"93627","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.937992,36.741277],[-118.983177,36.752621],[-118.982441,36.741646],[-118.937992,36.741277]]]},"properties":{"id":"93628","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.490325,37.038606],[-119.467308,36.967592],[-119.421901,36.909123],[-119.250998,36.88487],[-119.131131,36.87108],[-119.121526,36.902773],[-119.490325,37.038606]]]},"properties":{"id":"93667","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.801883,36.837051],[-119.790262,36.833965],[-119.790226,36.837252],[-119.790649,36.846153],[-119.801883,36.837051]]]},"properties":{"id":"93650","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.546984,36.663164],[-119.574844,36.619647],[-119.530275,36.575828],[-119.502594,36.575563],[-119.499523,36.653188],[-119.546984,36.663164]]]},"properties":{"id":"93648","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.899011,36.582955],[-119.889866,36.604793],[-119.916913,36.604577],[-119.917057,36.582828],[-119.899011,36.582955]]]},"properties":{"id":"93652","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.312869,40.642357],[-124.202769,40.61448],[-124.120705,40.643037],[-124.143679,40.69165],[-124.219751,40.722563],[-124.223241,40.72355],[-124.222983,40.753037],[-124.234044,40.76076],[-124.244378,40.769515],[-124.312869,40.642357]]]},"properties":{"id":"95551","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-124.075941,40.525843],[-124.087893,40.511964],[-123.893603,40.40847],[-123.83945,40.403419],[-123.828494,40.52964],[-123.840204,40.55425],[-124.046488,40.572881],[-124.075941,40.525843]]]},"properties":{"id":"95528","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.457322,32.916501],[-114.967334,32.756367],[-114.979415,33.022347],[-115.352445,33.152865],[-115.754944,33.156409],[-115.853808,33.122874],[-115.697175,32.963132],[-115.457322,32.916501]],[[-115.638526,33.015611],[-115.647315,33.073647],[-115.561336,33.073153],[-115.56141,33.044512],[-115.638526,33.015611]]]},"properties":{"id":"92227","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.931822,33.426499],[-116.095368,33.525292],[-116.042634,33.598705],[-116.111858,33.673994],[-116.233957,33.663884],[-116.28494,33.584027],[-116.085148,33.339216],[-116.000835,33.234878],[-115.888232,33.394376],[-115.931822,33.426499]]]},"properties":{"id":"92274","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.980946,32.70094],[-114.689383,33.245186],[-114.856134,33.219425],[-114.979415,33.022347],[-114.967334,32.756367],[-114.980946,32.70094]]]},"properties":{"id":"92283","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.257047,34.914693],[-118.660886,34.818174],[-118.170998,34.791439],[-118.140598,34.791788],[-118.140647,34.908204],[-118.257047,34.914693]]]},"properties":{"id":"93560","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.502082,35.164107],[-119.516306,35.189581],[-119.527705,35.181151],[-119.502082,35.164107]]]},"properties":{"id":"93268","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.713676,35.551014],[-117.616195,35.680856],[-117.792997,35.652923],[-117.713676,35.551014]]]},"properties":{"id":"93555","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.195464,35.790689],[-118.16551,35.556896],[-118.008043,35.789161],[-118.195464,35.790689]]]},"properties":{"id":"93255","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.440253,35.66349],[-118.588581,35.652548],[-118.503803,35.601167],[-118.358566,35.503353],[-118.3802,35.667781],[-118.440253,35.66349]]]},"properties":{"id":"93240","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.521315,35.588412],[-118.412606,35.425149],[-118.358566,35.503353],[-118.503803,35.601167],[-118.521315,35.588412]]]},"properties":{"id":"93205","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.107825,35.858589],[-119.401017,35.790121],[-119.329948,35.746728],[-119.080437,35.645865],[-119.05555,35.743662],[-119.053437,35.790748],[-119.107825,35.858589]],[[-119.102413,35.796744],[-119.177595,35.790578],[-119.125565,35.834235],[-119.102413,35.796744]]]},"properties":{"id":"93215","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.647276,35.26063],[-118.521993,35.199978],[-118.570294,35.267903],[-118.623333,35.267613],[-118.627413,35.26362],[-118.647276,35.26063]]]},"properties":{"id":"93531","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.975167,34.422487],[-118.87595,34.470803],[-118.770257,34.56321],[-118.820915,34.665851],[-118.847789,34.721368],[-119.155084,34.81338],[-119.277501,34.872229],[-119.567296,34.945866],[-119.556358,34.820153],[-119.441411,34.73327],[-118.990735,34.422505],[-118.975167,34.422487]]]},"properties":{"id":"93252","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.555725,35.78955],[-119.653002,35.616265],[-119.650154,35.611752],[-119.606973,35.571956],[-119.448018,35.500528],[-119.246295,35.558341],[-119.241114,35.616423],[-119.329948,35.746728],[-119.401017,35.790121],[-119.427821,35.790092],[-119.555725,35.78955]]]},"properties":{"id":"93280","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.885713,39.118398],[-122.805531,39.099863],[-122.844961,39.139592],[-122.885713,39.118398]]]},"properties":{"id":"95464","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.540661,38.988969],[-122.740794,39.025673],[-122.710646,38.980025],[-122.480439,38.988983],[-122.540661,38.988969]]]},"properties":{"id":"95422","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.998919,39.20462],[-122.966029,39.164909],[-122.940539,39.177372],[-122.961809,39.196596],[-122.998919,39.20462]]]},"properties":{"id":"95493","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.466187,34.193917],[-118.469485,34.164823],[-118.4487,34.164855],[-118.448717,34.189684],[-118.466187,34.193917]]]},"properties":{"id":"91411","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.614625,34.186451],[-118.614688,34.208265],[-118.667773,34.223426],[-118.668153,34.184926],[-118.614625,34.186451]]]},"properties":{"id":"91307","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.991743,34.660588],[-117.96942,34.579729],[-117.889527,34.557965],[-117.667034,34.558008],[-117.667112,34.643012],[-117.991743,34.660588]]]},"properties":{"id":"93591","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.16555,34.645748],[-118.329637,34.662693],[-118.378184,34.644826],[-118.247535,34.553194],[-118.154568,34.527785],[-118.111998,34.524229],[-118.129963,34.645863],[-118.16555,34.645748]]]},"properties":{"id":"93551","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.885558,34.180439],[-118.97081,34.139827],[-118.832818,34.099141],[-118.792895,34.146059],[-118.860951,34.17612],[-118.885558,34.180439]]]},"properties":{"id":"91361","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.262304,33.902415],[-118.265232,33.885977],[-118.233976,33.846646],[-118.220399,33.846572],[-118.221037,33.90336],[-118.259672,33.902526],[-118.262304,33.902415]]]},"properties":{"id":"90220","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.263149,33.902376],[-118.282708,33.916422],[-118.285945,33.916422],[-118.309123,33.87281],[-118.30915,33.858348],[-118.299427,33.858315],[-118.284917,33.846239],[-118.263334,33.839474],[-118.265232,33.885977],[-118.262304,33.902415],[-118.263149,33.902376]]]},"properties":{"id":"90248","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.328682,33.847274],[-118.328518,33.824899],[-118.327941,33.806862],[-118.308254,33.809014],[-118.298169,33.812984],[-118.299427,33.858315],[-118.30915,33.858348],[-118.328682,33.847274]]]},"properties":{"id":"90501","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.12514,33.869035],[-118.108429,33.865587],[-118.107997,33.887708],[-118.109848,33.909815],[-118.142734,33.902455],[-118.151294,33.879807],[-118.146647,33.869003],[-118.12514,33.869035]]]},"properties":{"id":"90706","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.14427,33.960924],[-118.09847,33.944137],[-118.093611,33.95046],[-118.121307,33.97299],[-118.129423,33.970934],[-118.14427,33.960924]]]},"properties":{"id":"90240","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.793861,34.165149],[-117.783878,34.081026],[-117.756134,34.088734],[-117.748541,34.1126],[-117.724548,34.165396],[-117.749696,34.222855],[-117.822736,34.179606],[-117.793861,34.165149]]]},"properties":{"id":"91750","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.801335,34.042687],[-117.75019,34.058655],[-117.756134,34.088734],[-117.783878,34.081026],[-117.826964,34.064219],[-117.826084,34.062406],[-117.821365,34.031071],[-117.801335,34.042687]]]},"properties":{"id":"91768","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.748541,34.1126],[-117.711067,34.079536],[-117.70429,34.095055],[-117.693545,34.121627],[-117.677706,34.165173],[-117.724548,34.165396],[-117.748541,34.1126]]]},"properties":{"id":"91711","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.75019,34.058655],[-117.801335,34.042687],[-117.767745,34.019605],[-117.745378,34.020018],[-117.706907,34.046672],[-117.719481,34.059731],[-117.75019,34.058655]]]},"properties":{"id":"91766","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.010372,34.050149],[-117.998071,34.02957],[-117.958214,34.01979],[-117.963791,34.052635],[-117.965925,34.062562],[-118.007111,34.059795],[-118.010372,34.050149]]]},"properties":{"id":"91746","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.317747,33.970923],[-118.31772,33.945463],[-118.317753,33.930901],[-118.314247,33.91645],[-118.300379,33.91741],[-118.300193,33.989109],[-118.317131,33.989102],[-118.317747,33.970923]]]},"properties":{"id":"90047","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.317747,33.970923],[-118.33521,33.974447],[-118.34405,33.97116],[-118.343928,33.952713],[-118.31772,33.945463],[-118.317747,33.970923]]]},"properties":{"id":"90305","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.122351,33.779311],[-118.149426,33.789836],[-118.167645,33.791555],[-118.167642,33.789848],[-118.167646,33.775346],[-118.1227,33.775322],[-118.121256,33.775319],[-118.122351,33.779311]]]},"properties":{"id":"90804","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.439421,34.078246],[-118.439176,34.067505],[-118.447501,34.066281],[-118.445241,34.073811],[-118.454658,34.076543],[-118.454211,34.05848],[-118.454106,34.058346],[-118.418614,34.061389],[-118.414344,34.064324],[-118.416319,34.067079],[-118.425974,34.080835],[-118.439421,34.078246]]]},"properties":{"id":"90024","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.284211,34.083625],[-118.284036,34.072629],[-118.267932,34.060871],[-118.256045,34.056183],[-118.246484,34.071778],[-118.245418,34.075124],[-118.232118,34.085961],[-118.243823,34.095615],[-118.277162,34.095776],[-118.284211,34.083625]]]},"properties":{"id":"90026","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.480521,34.129853],[-118.51848,34.128944],[-118.518977,34.129031],[-118.496873,34.04807],[-118.483766,34.04143],[-118.474465,34.044279],[-118.459995,34.051672],[-118.454211,34.05848],[-118.454658,34.076543],[-118.475671,34.126346],[-118.480521,34.129853]]]},"properties":{"id":"90049","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.376174,34.059462],[-118.390298,34.057085],[-118.406023,34.052666],[-118.406643,34.052303],[-118.400538,34.045875],[-118.370497,34.04141],[-118.364894,34.058305],[-118.376174,34.059462]]]},"properties":{"id":"90035","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.278337,34.153205],[-118.277162,34.095776],[-118.243823,34.095615],[-118.227876,34.083155],[-118.227601,34.08304],[-118.254135,34.11886],[-118.272095,34.146749],[-118.275401,34.153166],[-118.278337,34.153205]]]},"properties":{"id":"90039","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.425449,33.76085],[-118.318276,33.775218],[-118.327798,33.78613],[-118.374417,33.804213],[-118.401179,33.804349],[-118.425449,33.76085]]]},"properties":{"id":"90274","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.1466,34.135795],[-118.145945,34.122738],[-118.142213,34.11959],[-118.113085,34.136861],[-118.113178,34.157207],[-118.13246,34.157759],[-118.1466,34.135795]]]},"properties":{"id":"91106","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.146251,34.181539],[-118.165852,34.198895],[-118.167787,34.201583],[-118.189103,34.177312],[-118.183985,34.159065],[-118.14599,34.145816],[-118.14605,34.157834],[-118.146251,34.181539]]]},"properties":{"id":"91103","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.113085,34.136861],[-118.090987,34.12684],[-118.069319,34.130649],[-118.067784,34.148867],[-118.067958,34.156821],[-118.065655,34.179334],[-118.10042,34.190442],[-118.099342,34.174642],[-118.113178,34.157207],[-118.113085,34.136861]]]},"properties":{"id":"91107","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.231373,34.218977],[-118.239986,34.21145],[-118.239978,34.210339],[-118.239973,34.210082],[-118.220268,34.20503],[-118.231373,34.218977]]]},"properties":{"id":"91020","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.351812,34.158271],[-118.387741,34.158603],[-118.388183,34.150367],[-118.361267,34.142686],[-118.345665,34.142367],[-118.345166,34.142747],[-118.351812,34.158271]]]},"properties":{"id":"91602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.39503,34.179405],[-118.414179,34.179404],[-118.412785,34.164869],[-118.413776,34.156634],[-118.388183,34.150367],[-118.387741,34.158603],[-118.39503,34.179405]]]},"properties":{"id":"91607","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.536096,34.257266],[-118.560076,34.25726],[-118.571048,34.231844],[-118.562289,34.219108],[-118.536079,34.220727],[-118.531719,34.244565],[-118.536096,34.257266]]]},"properties":{"id":"91324","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.41692,34.012887],[-118.373093,34.011913],[-118.372627,34.018471],[-118.369577,34.03508],[-118.41692,34.012887]]]},"properties":{"id":"90232","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.256865,34.045163],[-118.274313,34.051362],[-118.284102,34.039855],[-118.264679,34.030991],[-118.257437,34.027631],[-118.25033,34.037303],[-118.254093,34.040736],[-118.255845,34.039571],[-118.256716,34.040455],[-118.254943,34.041599],[-118.256865,34.045163]]]},"properties":{"id":"90015","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.3329,34.018232],[-118.372627,34.018471],[-118.373093,34.011913],[-118.369448,33.998072],[-118.357888,33.997112],[-118.317619,34.00369],[-118.31709,34.017254],[-118.3329,34.018232]]]},"properties":{"id":"90008","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.265118,33.96013],[-118.230013,33.961768],[-118.237907,33.989333],[-118.243139,33.989443],[-118.265185,33.989227],[-118.265118,33.96013]]]},"properties":{"id":"90001","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.502369,34.253888],[-118.472121,34.257323],[-118.46798,34.291621],[-118.508341,34.33389],[-118.545017,34.329934],[-118.547357,34.324675],[-118.540943,34.298794],[-118.523323,34.257339],[-118.502369,34.253888]]]},"properties":{"id":"91344","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.411297,34.289803],[-118.455848,34.280274],[-118.443266,34.266618],[-118.411297,34.289803]]]},"properties":{"id":"91340","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.486455,33.993572],[-118.455804,34.009636],[-118.443945,34.017093],[-118.453,34.027961],[-118.48299,34.012345],[-118.4952,34.004094],[-118.486455,33.993572]]]},"properties":{"id":"90405","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.47096,34.041276],[-118.490271,34.025633],[-118.48299,34.012345],[-118.453,34.027961],[-118.46146,34.03328],[-118.47096,34.041276]]]},"properties":{"id":"90404","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.490271,34.025633],[-118.505885,34.013035],[-118.4952,34.004094],[-118.48299,34.012345],[-118.490271,34.025633]]]},"properties":{"id":"90401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.378721,33.894646],[-118.391371,33.872812],[-118.390308,33.854854],[-118.378219,33.854993],[-118.353589,33.858295],[-118.352347,33.874215],[-118.378721,33.894646]]]},"properties":{"id":"90278","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.173889,34.03335],[-118.173987,34.017399],[-118.127095,34.00721],[-118.14416,34.033463],[-118.169273,34.050737],[-118.173889,34.03335]]]},"properties":{"id":"90022","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-118.169347,33.986619],[-118.175455,33.998347],[-118.169859,33.986646],[-118.169347,33.986619]]],[[[-118.189753,33.981957],[-118.192782,33.961622],[-118.158604,33.951894],[-118.14427,33.960924],[-118.129423,33.970934],[-118.167058,33.97951],[-118.189753,33.981957]]]]},"properties":{"id":"90201","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.372924,33.827657],[-118.374417,33.804213],[-118.327798,33.78613],[-118.327941,33.806862],[-118.328518,33.824899],[-118.372924,33.827657]]]},"properties":{"id":"90505","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.353589,33.858295],[-118.378219,33.854993],[-118.372924,33.827657],[-118.328518,33.824899],[-118.328682,33.847274],[-118.353589,33.858295]]]},"properties":{"id":"90503","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.047142,33.95572],[-118.036519,33.917],[-118.033851,33.916999],[-118.025372,33.948669],[-117.983412,33.965],[-117.976652,33.975091],[-117.98782,33.974515],[-118.047142,33.95572]]]},"properties":{"id":"90605","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.065655,34.179334],[-118.067958,34.156821],[-118.030139,34.179402],[-118.065655,34.179334]]]},"properties":{"id":"91024","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.256379,34.146453],[-118.257843,34.142566],[-118.255039,34.142592],[-118.25506,34.146272],[-118.256379,34.146453]]]},"properties":{"id":"91210","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.252318,33.863344],[-118.252176,33.862515],[-118.250483,33.862514],[-118.251085,33.863196],[-118.252318,33.863344]]]},"properties":{"id":"90747","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.181082,37.061828],[-120.005865,36.909377],[-119.90844,37.069158],[-120.020309,37.119376],[-120.181082,37.061828]]]},"properties":{"id":"93638","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.581979,37.28766],[-119.596797,37.243364],[-119.564323,37.144642],[-119.563784,37.13832],[-119.360678,37.169613],[-119.333848,37.226213],[-119.383748,37.257252],[-119.519661,37.287897],[-119.562184,37.286148],[-119.581979,37.28766]]]},"properties":{"id":"93643","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.617092,38.150507],[-122.546108,38.08008],[-122.482661,38.099174],[-122.483997,38.107784],[-122.545376,38.158733],[-122.617092,38.150507]]]},"properties":{"id":"94945","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.502151,37.943858],[-122.490108,37.938358],[-122.483654,37.944928],[-122.501739,37.947825],[-122.502151,37.943858]]]},"properties":{"id":"94964","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.805236,37.758117],[-120.153226,37.883891],[-120.268532,37.891243],[-120.333245,37.875537],[-120.339986,37.841365],[-120.244254,37.789658],[-119.860841,37.765018],[-119.8425,37.763252],[-119.805236,37.758117]]]},"properties":{"id":"95321","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.478381,38.917356],[-123.741555,38.96067],[-123.648784,38.841691],[-123.478381,38.917356]]]},"properties":{"id":"95468","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.63667,39.156788],[-123.611491,39.025254],[-123.44486,38.993984],[-123.395597,39.07047],[-123.42615,39.159896],[-123.429046,39.158243],[-123.488909,39.180838],[-123.583127,39.161111],[-123.63667,39.156788]]]},"properties":{"id":"95466","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.813669,40.001865],[-123.764178,39.905991],[-123.72514,39.935014],[-123.772664,40.002056],[-123.813669,40.001865]]]},"properties":{"id":"95587","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.395597,39.07047],[-123.44486,38.993984],[-123.43504,38.993863],[-123.315109,38.990342],[-123.286978,39.002337],[-123.289241,39.050453],[-123.395597,39.07047]]]},"properties":{"id":"95415","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.510042,37.307875],[-120.361173,37.295266],[-120.33385,37.295022],[-120.306311,37.345816],[-120.449499,37.401094],[-120.510042,37.307875]]]},"properties":{"id":"95340","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.361173,37.295266],[-120.35218,37.203259],[-120.058885,37.191627],[-120.176277,37.260561],[-120.266307,37.40107],[-120.269666,37.378011],[-120.33385,37.295022],[-120.361173,37.295266]]]},"properties":{"id":"95333","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.264836,41.58312],[-120.207412,41.344431],[-119.999648,41.423275],[-119.998823,41.665148],[-120.264836,41.58312]]]},"properties":{"id":"96104","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.790687,36.806429],[-121.74206,36.830255],[-121.81467,36.849517],[-121.790687,36.806429]]]},"properties":{"id":"95039","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.023467,36.12194],[-120.865371,36.125645],[-120.869908,36.16574],[-121.023467,36.12194]]]},"properties":{"id":"93954","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.002724,35.968807],[-121.026993,36.117532],[-121.153161,36.00238],[-121.114001,35.86259],[-121.002724,35.968807]]]},"properties":{"id":"93932","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.912971,36.574834],[-121.769676,36.548424],[-121.758368,36.554865],[-121.753578,36.589238],[-121.860241,36.613433],[-121.900965,36.620259],[-121.926784,36.600616],[-121.912971,36.574834]],[[-121.878437,36.59803],[-121.874224,36.600058],[-121.868416,36.596769],[-121.87735,36.593689],[-121.878437,36.59803]]]},"properties":{"id":"93940","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.860241,36.613433],[-121.753578,36.589238],[-121.731576,36.65577],[-121.816224,36.683641],[-121.860241,36.613433]]]},"properties":{"id":"93955","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.782983,36.732053],[-121.806386,36.753315],[-121.816224,36.683641],[-121.731576,36.65577],[-121.782983,36.732053]]]},"properties":{"id":"93933","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.21466,38.15492],[-122.290676,38.155204],[-122.268872,38.126181],[-122.229825,38.12315],[-122.21466,38.15492]]]},"properties":{"id":"94589","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.185924,38.934013],[-121.017831,38.969809],[-121.030095,39.036433],[-121.192281,39.015532],[-121.185924,38.934013]]]},"properties":{"id":"95602","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.98535,33.895603],[-117.981132,33.857941],[-117.94179,33.854343],[-117.942,33.88123],[-117.95755,33.906465],[-117.976586,33.906225],[-117.98535,33.895603]]]},"properties":{"id":"92833","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.81533,33.802116],[-117.82418,33.823342],[-117.798424,33.831105],[-117.798847,33.813123],[-117.776218,33.822924],[-117.74374,33.824201],[-117.833883,33.839025],[-117.870228,33.816584],[-117.876911,33.802185],[-117.856348,33.795124],[-117.830873,33.787818],[-117.81533,33.802116]]]},"properties":{"id":"92867","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.894732,33.774478],[-117.907224,33.752682],[-117.876434,33.75577],[-117.855929,33.759952],[-117.876019,33.778927],[-117.894732,33.774478]]]},"properties":{"id":"92706","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.909447,33.627675],[-117.883152,33.665486],[-117.946994,33.666689],[-117.953665,33.64321],[-117.909447,33.627675]]]},"properties":{"id":"92627","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.78222,33.710154],[-117.768297,33.690049],[-117.72024,33.717746],[-117.717373,33.736179],[-117.717286,33.736985],[-117.78222,33.710154]]]},"properties":{"id":"92620","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.98904,33.70121],[-117.988622,33.640196],[-117.960457,33.625511],[-117.953665,33.64321],[-117.946994,33.666689],[-117.939512,33.687083],[-117.98035,33.70121],[-117.98904,33.70121]]]},"properties":{"id":"92646","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.976612,33.7448],[-117.976621,33.752095],[-117.98841,33.752023],[-117.983696,33.74471],[-117.976612,33.7448]]]},"properties":{"id":"92655","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.580659,33.44595],[-117.65608,33.45903],[-117.634508,33.427856],[-117.580659,33.44595]]]},"properties":{"id":"92672","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.623632,33.664713],[-117.633159,33.575009],[-117.603946,33.566304],[-117.623632,33.664713]]]},"properties":{"id":"92688","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.633159,33.575009],[-117.663299,33.538109],[-117.590802,33.550537],[-117.603946,33.566304],[-117.633159,33.575009]]]},"properties":{"id":"92694","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.457862,33.690219],[-117.534137,33.712584],[-117.563931,33.684573],[-117.555296,33.641916],[-117.457862,33.690219]]]},"properties":{"id":"92678","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.817732,33.869269],[-117.778975,33.864726],[-117.735464,33.913251],[-117.748097,33.921432],[-117.842386,33.909894],[-117.817732,33.869269]]]},"properties":{"id":"92886","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.924447,33.883266],[-117.911291,33.863732],[-117.906717,33.85447],[-117.898041,33.856305],[-117.883053,33.860035],[-117.876891,33.89636],[-117.924447,33.883266]]]},"properties":{"id":"92831","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-117.86722,33.68671],[-117.878401,33.664082],[-117.864007,33.671822],[-117.857229,33.682685],[-117.86722,33.68671]]],[[[-117.88513,33.73446],[-117.8833,33.69411],[-117.86763,33.69178],[-117.861366,33.698577],[-117.8548,33.73361],[-117.87648,33.7345],[-117.88513,33.73446]]]]},"properties":{"id":"92707","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.060127,33.699282],[-117.988622,33.640196],[-117.98904,33.70121],[-118.023718,33.700951],[-118.060127,33.699282]]]},"properties":{"id":"92648","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.783883,33.595103],[-117.836738,33.564164],[-117.730208,33.479596],[-117.733257,33.49814],[-117.740718,33.537773],[-117.747973,33.597976],[-117.760606,33.595732],[-117.783883,33.595103]]]},"properties":{"id":"92651","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.021776,39.039165],[-120.993931,39.013748],[-120.976156,39.03248],[-120.982492,39.037822],[-120.981003,39.04367],[-120.974155,39.044685],[-120.975244,39.034502],[-120.97892,39.011712],[-120.972081,38.995592],[-120.955651,38.991996],[-120.788051,39.173871],[-120.836284,39.15175],[-120.908646,39.168485],[-120.989387,39.111229],[-121.021776,39.039165]]]},"properties":{"id":"95713","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.039412,39.237217],[-120.048031,39.2556],[-120.075869,39.251787],[-120.064082,39.236909],[-120.063827,39.23585],[-120.039412,39.237217]]]},"properties":{"id":"96148","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.075869,39.251787],[-120.048031,39.2556],[-120.039412,39.237217],[-120.023915,39.260862],[-120.076247,39.251398],[-120.075869,39.251787]]]},"properties":{"id":"96143","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.247222,38.770927],[-121.293849,38.721403],[-121.290403,38.721501],[-121.242795,38.718241],[-121.2222,38.716561],[-121.217839,38.770384],[-121.247222,38.770927]]]},"properties":{"id":"95661","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.732183,39.896053],[-120.768113,39.717919],[-120.718395,39.706674],[-120.603597,39.706561],[-120.568929,39.761231],[-120.540254,39.859806],[-120.690523,39.924456],[-120.732183,39.896053]]]},"properties":{"id":"96103","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.095368,33.525292],[-115.910085,33.527712],[-116.042634,33.598705],[-116.095368,33.525292]]]},"properties":{"id":"92254","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.301228,33.803855],[-116.283244,33.794816],[-116.181536,33.747628],[-116.154176,33.714932],[-116.444094,33.971899],[-116.505761,33.874782],[-116.493477,33.867515],[-116.44095,33.859368],[-116.301228,33.803855]]]},"properties":{"id":"92241","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.24034,33.654337],[-117.228683,33.685023],[-117.280017,33.69742],[-117.363201,33.718316],[-117.291923,33.654054],[-117.24034,33.654337]]]},"properties":{"id":"92532","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.989277,33.772859],[-117.053891,33.787357],[-117.068011,33.771186],[-117.132167,33.735926],[-117.134941,33.721464],[-117.00592,33.685577],[-116.989277,33.772859]]]},"properties":{"id":"92545","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.136739,33.626878],[-117.100191,33.551865],[-117.031722,33.568577],[-117.009245,33.656308],[-117.00592,33.685577],[-117.134941,33.721464],[-117.145198,33.702141],[-117.136739,33.626878]]]},"properties":{"id":"92596","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.100191,33.551865],[-117.136739,33.626878],[-117.171013,33.627039],[-117.17254,33.536523],[-117.100191,33.551865]]]},"properties":{"id":"92563","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.318274,33.927107],[-117.376397,33.969054],[-117.399111,33.975502],[-117.357236,33.891544],[-117.318274,33.927107]]]},"properties":{"id":"92506","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.208891,33.902826],[-117.243573,33.902774],[-117.243838,33.859082],[-117.208737,33.858941],[-117.208891,33.902826]]]},"properties":{"id":"92551","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.261058,33.866223],[-117.199842,33.773297],[-117.173753,33.786697],[-117.135596,33.840501],[-117.208737,33.858941],[-117.243838,33.859082],[-117.261058,33.866223]]]},"properties":{"id":"92571","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.562835,38.627106],[-121.469519,38.629945],[-121.474322,38.655347],[-121.56012,38.656339],[-121.576068,38.648604],[-121.562835,38.627106]]]},"properties":{"id":"95834","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.435862,38.510507],[-121.411296,38.458132],[-121.41065,38.456781],[-121.371749,38.452569],[-121.369864,38.522089],[-121.4022,38.523931],[-121.435862,38.510507]]]},"properties":{"id":"95828","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.259502,38.68285],[-121.192558,38.650104],[-121.188571,38.714308],[-121.2222,38.716561],[-121.242795,38.718241],[-121.259502,38.68285]]]},"properties":{"id":"95662","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.437999,34.354128],[-117.415919,34.325079],[-117.390663,34.437414],[-117.450996,34.448351],[-117.437999,34.354128]]]},"properties":{"id":"92344","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.839391,34.226577],[-116.838766,34.247434],[-116.822263,34.250157],[-116.812986,34.225719],[-116.762478,34.208919],[-116.752966,34.218006],[-116.920055,34.279588],[-116.925499,34.26138],[-116.884409,34.260211],[-116.843105,34.227251],[-116.839391,34.226577]]]},"properties":{"id":"92314","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.20503,35.13655],[-116.309362,35.042702],[-116.165534,35.046454],[-116.20503,35.13655]]]},"properties":{"id":"92309","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.70429,34.095055],[-117.680888,34.087374],[-117.651096,34.087421],[-117.628572,34.092558],[-117.623451,34.121565],[-117.693545,34.121627],[-117.70429,34.095055]]]},"properties":{"id":"91786","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.620136,34.1216],[-117.545411,34.121508],[-117.545267,34.136485],[-117.61235,34.16508],[-117.634222,34.164956],[-117.620136,34.1216]]]},"properties":{"id":"91701","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.181011,34.2443],[-117.166115,34.2301],[-117.138743,34.231975],[-117.123869,34.257469],[-117.163804,34.280504],[-117.181011,34.2443]]]},"properties":{"id":"92321","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.812986,34.225719],[-116.822263,34.250157],[-116.838766,34.247434],[-116.839391,34.226577],[-116.812986,34.225719]]]},"properties":{"id":"92386","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.43144,34.136467],[-117.347943,34.136018],[-117.429453,34.187496],[-117.43144,34.136467]]]},"properties":{"id":"92377","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.229023,34.187427],[-114.138365,34.288564],[-114.203185,34.310918],[-114.222346,34.242923],[-114.229023,34.187427]]]},"properties":{"id":"92267","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.12666,34.96557],[-117.240647,34.800241],[-117.224056,34.761554],[-116.935787,34.87205],[-116.927829,34.901548],[-116.83791,34.93776],[-116.7637,35.033014],[-116.713374,35.069453],[-117.12666,34.96557]]]},"properties":{"id":"92311","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.12666,34.96557],[-117.259896,34.820269],[-117.240647,34.800241],[-117.12666,34.96557]]]},"properties":{"id":"92347","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.927829,34.901548],[-116.831463,34.879915],[-116.775121,34.896697],[-116.83791,34.93776],[-116.927829,34.901548]]]},"properties":{"id":"92398","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.215984,34.230691],[-117.242667,34.225167],[-117.224432,34.222616],[-117.21269,34.229151],[-117.215984,34.230691]]]},"properties":{"id":"92378","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.037041,32.840262],[-117.005597,32.807276],[-116.961717,32.82941],[-116.938108,32.824251],[-116.962261,32.872948],[-117.037041,32.840262]]]},"properties":{"id":"92071","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.824483,32.826415],[-116.794318,32.883445],[-116.938108,32.824251],[-116.961717,32.82941],[-116.944587,32.794917],[-116.824483,32.826415]]]},"properties":{"id":"92021","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.824483,32.826415],[-116.944587,32.794917],[-116.945055,32.757161],[-116.944869,32.739567],[-116.910146,32.732031],[-116.792305,32.784509],[-116.824483,32.826415]]]},"properties":{"id":"92019","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.207111,33.157806],[-117.231258,33.130865],[-117.203704,33.087699],[-117.123407,33.132885],[-117.207111,33.157806]]]},"properties":{"id":"92078","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.226715,32.74513],[-117.25896,32.713236],[-117.225688,32.7248],[-117.216855,32.727733],[-117.216942,32.723568],[-117.216513,32.725066],[-117.208701,32.734341],[-117.209555,32.742539],[-117.226715,32.74513]]]},"properties":{"id":"92106","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.06674,32.934435],[-117.106017,32.948201],[-117.111914,32.935417],[-117.107508,32.875229],[-117.120147,32.84187],[-117.052901,32.853544],[-117.025464,32.914754],[-117.041048,32.926619],[-117.06674,32.934435]]]},"properties":{"id":"92131","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.011457,32.543857],[-117.018625,32.562038],[-117.06321,32.568085],[-117.062631,32.539733],[-117.011457,32.543857]]]},"properties":{"id":"92173","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.146312,32.976272],[-117.166861,32.93243],[-117.111914,32.935417],[-117.106017,32.948201],[-117.083733,32.996662],[-117.146312,32.976272]]]},"properties":{"id":"92129","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.964334,32.744947],[-117.012029,32.739966],[-117.022994,32.718477],[-117.024911,32.697862],[-117.02134,32.688098],[-117.017158,32.691243],[-116.9775,32.715068],[-116.964334,32.744947]]]},"properties":{"id":"91977","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.158495,33.266675],[-117.238859,33.256619],[-117.26134,33.241097],[-117.257399,33.235125],[-117.205093,33.161781],[-117.174519,33.227209],[-117.158495,33.266675]]]},"properties":{"id":"92084","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.266764,33.129532],[-117.302334,33.090621],[-117.199861,33.080563],[-117.203704,33.087699],[-117.231258,33.130865],[-117.266764,33.129532]]]},"properties":{"id":"92009","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.162955,32.781225],[-117.114217,32.788233],[-117.120988,32.841551],[-117.133995,32.840851],[-117.162955,32.781225]]]},"properties":{"id":"92123","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.971066,32.652934],[-116.989744,32.663936],[-117.005055,32.648147],[-117.016251,32.623535],[-117.005248,32.592388],[-116.965626,32.59032],[-116.971066,32.652934]]]},"properties":{"id":"91913","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.063858,32.730157],[-117.022994,32.718477],[-117.012029,32.739966],[-117.050837,32.742041],[-117.058253,32.734454],[-117.063858,32.730157]]]},"properties":{"id":"91945","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.147574,32.705251],[-117.157935,32.701983],[-117.124747,32.679291],[-117.085132,32.692365],[-117.085,32.7041],[-117.147574,32.705251]]]},"properties":{"id":"92113","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.191018,32.707868],[-117.199612,32.689958],[-117.192907,32.6879],[-117.188759,32.705379],[-117.191018,32.707868]]]},"properties":{"id":"92135","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.794318,32.883445],[-116.824483,32.826415],[-116.792305,32.784509],[-116.672424,32.709041],[-116.599921,32.797749],[-116.595948,32.82269],[-116.698089,32.873849],[-116.789906,32.88839],[-116.794318,32.883445]]]},"properties":{"id":"91901","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.100783,32.757076],[-117.058253,32.734454],[-117.050837,32.742041],[-117.049138,32.754755],[-117.048602,32.774257],[-117.101038,32.779036],[-117.100783,32.757076]]]},"properties":{"id":"92115","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.041048,32.926619],[-117.025464,32.914754],[-116.983729,32.933978],[-117.041048,32.926619]]]},"properties":{"id":"92145","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.500854,33.252384],[-116.585951,33.231504],[-116.516201,33.167489],[-116.500854,33.252384]]]},"properties":{"id":"92066","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.142667,32.726709],[-117.148336,32.721102],[-117.142597,32.722716],[-117.142667,32.726709]]]},"properties":{"id":"92134","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.426309,37.769603],[-122.404583,37.786731],[-122.411886,37.788808],[-122.427396,37.782057],[-122.429849,37.777919],[-122.428426,37.770452],[-122.426309,37.769603]]]},"properties":{"id":"94102","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.444298,37.730708],[-122.462271,37.723021],[-122.468939,37.708232],[-122.428294,37.70841],[-122.415459,37.732164],[-122.426722,37.736372],[-122.444298,37.730708]]]},"properties":{"id":"94112","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.448127,37.806896],[-122.485943,37.790839],[-122.476625,37.786796],[-122.448024,37.793122],[-122.448127,37.806896]]]},"properties":{"id":"94129","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.404613,37.793565],[-122.403431,37.787642],[-122.399149,37.791017],[-122.404613,37.793565]]]},"properties":{"id":"94104","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.462271,37.723021],[-122.444298,37.730708],[-122.459174,37.747286],[-122.471569,37.734707],[-122.462271,37.723021]]]},"properties":{"id":"94127","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.27586,37.945489],[-121.372072,37.968358],[-121.572923,38.074239],[-121.579842,38.006436],[-121.581378,37.98276],[-121.522272,37.939737],[-121.560386,37.858316],[-121.55208,37.822497],[-121.413801,37.80341],[-121.323007,37.864768],[-121.243092,37.879161],[-121.220155,37.880806],[-121.22995,37.921597],[-121.27586,37.945489]]]},"properties":{"id":"95206","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.015593,38.160575],[-121.13245,38.074264],[-121.150873,38.065672],[-121.038495,37.938568],[-120.926449,38.077421],[-120.971186,38.167979],[-120.975206,38.177886],[-121.015593,38.160575]]]},"properties":{"id":"95236","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.32207,38.189182],[-121.29406,38.174678],[-121.300397,38.151251],[-121.290139,38.151431],[-121.192852,38.148189],[-121.115268,38.192669],[-121.088288,38.223739],[-121.395592,38.221023],[-121.32207,38.189182]]]},"properties":{"id":"95220","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.700319,35.179748],[-120.629586,35.165451],[-120.552972,35.312017],[-120.646819,35.350146],[-120.700319,35.179748]]]},"properties":{"id":"93401","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.912039,35.577789],[-120.558254,35.534821],[-120.408349,35.530615],[-120.41325,35.789979],[-120.730075,35.755765],[-120.858616,35.755675],[-121.144957,35.793989],[-121.185056,35.794161],[-121.062072,35.678697],[-120.912039,35.577789]]]},"properties":{"id":"93446","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.229133,37.424295],[-122.224967,37.443965],[-122.229626,37.482088],[-122.24424,37.49407],[-122.303476,37.493798],[-122.339141,37.507196],[-122.342104,37.509035],[-122.368144,37.496047],[-122.395944,37.345387],[-122.300418,37.361982],[-122.192513,37.318795],[-122.18876,37.320916],[-122.229133,37.424295]]]},"properties":{"id":"94062","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.121447,37.476912],[-122.144933,37.4582],[-122.130425,37.434103],[-122.10203,37.41552],[-122.086207,37.450275],[-122.121447,37.476912]]]},"properties":{"id":"94303","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.412623,37.589156],[-122.374915,37.60409],[-122.37805,37.606801],[-122.400163,37.612646],[-122.424608,37.600336],[-122.412623,37.589156]]]},"properties":{"id":"94030","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.292192,34.764404],[-120.440557,34.920446],[-120.558258,34.95741],[-120.543132,34.900795],[-120.528462,34.835734],[-120.435583,34.722264],[-120.331358,34.714095],[-120.292192,34.764404]]]},"properties":{"id":"93455","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.174588,34.597832],[-120.157064,34.681613],[-120.156988,34.685845],[-120.179152,34.710668],[-120.253964,34.686532],[-120.253434,34.521261],[-120.174588,34.597832]]]},"properties":{"id":"93427","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-120.162567,34.724591],[-120.179152,34.710668],[-120.156988,34.685845],[-120.162567,34.724591]]],[[[-120.157064,34.681613],[-120.174588,34.597832],[-120.12566,34.538875],[-120.103843,34.650323],[-120.157064,34.681613]]]]},"properties":{"id":"93463","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.827644,37.350835],[-121.86826,37.32966],[-121.843983,37.31036],[-121.80792,37.313816],[-121.820407,37.341242],[-121.827644,37.350835]]]},"properties":{"id":"95122","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.843983,37.31036],[-121.809383,37.285245],[-121.771625,37.299159],[-121.791769,37.31404],[-121.80792,37.313816],[-121.843983,37.31036]]]},"properties":{"id":"95121","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.935842,37.195357],[-121.995491,37.250381],[-122.0288,37.217522],[-121.935842,37.195357]]]},"properties":{"id":"95030","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.862912,37.384459],[-121.880698,37.368007],[-121.873826,37.359841],[-121.846169,37.36794],[-121.846235,37.389068],[-121.862912,37.384459]]]},"properties":{"id":"95133","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.060076,37.375435],[-122.063002,37.359236],[-122.0624,37.33754],[-122.00254,37.3378],[-122.001736,37.352146],[-122.060076,37.375435]]]},"properties":{"id":"94087","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.144933,37.4582],[-122.171706,37.44678],[-122.16763,37.44363],[-122.16019,37.43728],[-122.130425,37.434103],[-122.144933,37.4582]]]},"properties":{"id":"94301","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.94861,37.25786],[-121.903918,37.226543],[-121.906188,37.271395],[-121.922455,37.282627],[-121.94861,37.25786]]]},"properties":{"id":"95124","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.0578,37.38752],[-122.091312,37.400534],[-122.058148,37.381386],[-122.0578,37.38752]]]},"properties":{"id":"94041","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.151789,37.415062],[-122.140956,37.395681],[-122.117852,37.40425],[-122.10831,37.40808],[-122.10203,37.41552],[-122.130425,37.434103],[-122.16019,37.43728],[-122.151789,37.415062]]]},"properties":{"id":"94306","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.549279,37.118158],[-121.62818,37.10839],[-121.621875,37.058721],[-121.549279,37.118158]]]},"properties":{"id":"95046","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.055932,37.003119],[-122.106761,37.070536],[-122.13882,37.10427],[-122.207158,37.157156],[-122.189802,36.999782],[-122.01305,36.958935],[-122.013275,36.988546],[-122.005264,37.042893],[-122.055834,37.012883],[-122.051008,37.001574],[-122.050708,36.977231],[-122.068915,36.980253],[-122.055932,37.003119]]]},"properties":{"id":"95060","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.990868,40.959029],[-121.845324,40.81243],[-121.766084,40.869723],[-121.840666,40.958579],[-121.990868,40.959029]]]},"properties":{"id":"96065","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.524932,40.513925],[-122.367179,40.505473],[-122.368025,40.585818],[-122.419181,40.718588],[-122.544635,40.695965],[-122.531522,40.553925],[-122.524932,40.513925]]]},"properties":{"id":"96001","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.544635,40.695965],[-122.709416,40.720016],[-122.745312,40.694226],[-122.733769,40.635184],[-122.693302,40.577815],[-122.531522,40.553925],[-122.544635,40.695965]]]},"properties":{"id":"96087","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.556666,40.976608],[-121.567154,40.884467],[-121.402251,40.857062],[-121.556666,40.976608]]]},"properties":{"id":"96016","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-121.011567,39.516456],[-120.904345,39.563778],[-120.906543,39.567301],[-121.011567,39.516456]]],[[[-121.027774,39.451992],[-121.0344,39.514436],[-121.015806,39.60532],[-121.129142,39.541638],[-121.134368,39.537903],[-121.141449,39.392532],[-121.136847,39.368074],[-121.084391,39.394266],[-121.027774,39.451992]]]]},"properties":{"id":"95922","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.644635,41.183452],[-122.210486,41.377749],[-122.172224,41.265471],[-122.19603,41.18444],[-122.07282,41.183097],[-121.807389,41.183285],[-121.718263,41.183387],[-121.644635,41.183452]]]},"properties":{"id":"96057","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.640838,41.557357],[-122.575356,41.563283],[-122.535227,41.646193],[-122.573983,41.814305],[-122.616173,41.883195],[-122.821361,41.764062],[-122.640838,41.557357]]]},"properties":{"id":"96097","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.44232,41.254146],[-123.408291,41.179944],[-123.08654,41.05795],[-123.037118,41.004176],[-122.873167,41.20429],[-122.889435,41.238806],[-123.428997,41.374229],[-123.493634,41.377464],[-123.44232,41.254146]]]},"properties":{"id":"96031","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.636969,38.566405],[-121.730298,38.58902],[-121.768525,38.530186],[-121.58307,38.545304],[-121.583457,38.578599],[-121.636969,38.566405]]]},"properties":{"id":"95618","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.031171,37.663862],[-120.977786,37.609273],[-120.926838,37.60931],[-120.938345,37.616182],[-120.938724,37.616718],[-121.009967,37.64607],[-121.031171,37.663862]]]},"properties":{"id":"95351","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.45726,38.92604],[-121.414625,38.996014],[-121.493489,38.985104],[-121.573831,38.930196],[-121.487734,38.915743],[-121.45726,38.92604]]]},"properties":{"id":"95674","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.067182,39.955624],[-122.038795,39.883703],[-121.98651,40.00809],[-122.011697,40.037691],[-122.067182,39.955624]]]},"properties":{"id":"96092","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-123.391409,39.977289],[-123.341029,39.977291],[-123.543933,40.218565],[-123.544197,40.110988],[-123.544458,40.001923],[-123.447329,39.977196],[-123.391409,39.977289]]]},"properties":{"id":"95595","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.53638,36.137893],[-119.475471,36.065387],[-119.196722,36.080166],[-119.196593,36.116442],[-119.187953,36.210764],[-119.181251,36.224906],[-119.181416,36.254013],[-119.287681,36.27077],[-119.474607,36.269025],[-119.53638,36.137893]]]},"properties":{"id":"93274","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.246828,38.019811],[-120.323265,38.078801],[-120.453965,38.038208],[-120.523796,37.949054],[-120.333245,37.875537],[-120.268532,37.891243],[-120.267285,37.962759],[-120.246828,38.019811]]]},"properties":{"id":"95370","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.151646,38.114121],[-120.168424,38.093471],[-120.156482,38.053384],[-120.022483,38.150336],[-120.037704,38.170099],[-120.062844,38.172132],[-120.151646,38.114121]]]},"properties":{"id":"95335","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.246828,38.019811],[-120.267285,37.962759],[-120.238964,38.020366],[-120.246828,38.019811]]]},"properties":{"id":"95372","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.037704,38.170099],[-120.016559,38.210007],[-120.062844,38.172132],[-120.037704,38.170099]]]},"properties":{"id":"95375","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.043328,38.81558],[-122.067053,38.648935],[-121.969796,38.670963],[-121.956119,38.703547],[-121.94592,38.777235],[-122.043328,38.81558]]]},"properties":{"id":"95627","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-122.261474,38.924059],[-122.229458,38.878036],[-122.175702,38.815771],[-122.202462,38.688976],[-122.164944,38.642462],[-122.067053,38.648935],[-122.043328,38.81558],[-122.043422,38.831715],[-122.093042,38.925651],[-122.261474,38.924059]]]},"properties":{"id":"95607","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.309723,39.405462],[-121.237749,39.381329],[-121.220879,39.448016],[-121.243399,39.483381],[-121.257696,39.477378],[-121.293863,39.47474],[-121.30357,39.449816],[-121.309723,39.405462]]]},"properties":{"id":"95919","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-121.385451,39.075216],[-121.322401,39.107083],[-121.321994,39.127558],[-121.385451,39.075216]]]},"properties":{"id":"95903","state_code":"06"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.97571,40.000322],[-105.052823,40.00026],[-105.058567,39.975502],[-105.015657,39.943093],[-104.987976,39.943074],[-104.978188,39.942944],[-104.97571,40.000322]]]},"properties":{"id":"80023","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.954771,39.812881],[-104.903553,39.812959],[-104.790859,39.842009],[-104.67808,39.906584],[-104.848778,39.885209],[-104.948161,39.830746],[-104.954771,39.812881]],[[-104.919725,39.843574],[-104.916492,39.845379],[-104.916573,39.841732],[-104.919725,39.843574]]]},"properties":{"id":"80022","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.188688,39.971316],[-104.288471,39.56471],[-103.999129,39.869396],[-103.946535,40.001172],[-104.188688,39.971316]]]},"properties":{"id":"80103","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.999129,39.869396],[-103.706263,39.828548],[-103.698015,40.00132],[-103.668738,40.292489],[-103.658245,40.522307],[-103.803373,40.523874],[-103.985763,40.524148],[-103.975077,40.510051],[-103.979964,40.264005],[-103.946535,40.001172],[-103.999129,39.869396]],[[-103.83566,40.271206],[-103.834186,40.274833],[-103.821513,40.269616],[-103.82971,40.266903],[-103.83566,40.271206]]]},"properties":{"id":"80701","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-104.846932,39.754889],[-104.846824,39.754888],[-104.846932,39.767966],[-104.846932,39.754889]]],[[[-104.846932,39.754889],[-104.854038,39.754985],[-104.884664,39.747467],[-104.884708,39.732846],[-104.870653,39.710169],[-104.865971,39.711053],[-104.853681,39.717965],[-104.832467,39.751455],[-104.846932,39.754889]]]]},"properties":{"id":"80010","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.974691,37.632272],[-106.039331,37.400852],[-105.910329,37.316069],[-105.743339,37.356798],[-105.485516,37.577899],[-105.974691,37.632272]]]},"properties":{"id":"81101","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.865942,39.68202],[-104.809913,39.678438],[-104.809903,39.682338],[-104.81,39.714947],[-104.853681,39.717965],[-104.865971,39.711053],[-104.865942,39.68202]]]},"properties":{"id":"80012","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.809913,39.678438],[-104.808027,39.638492],[-104.735015,39.638452],[-104.734892,39.682188],[-104.753327,39.682188],[-104.809903,39.682338],[-104.809913,39.678438]]]},"properties":{"id":"80013","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.346536,37.441793],[-102.20117,37.644201],[-102.219431,37.644161],[-102.539999,37.643734],[-102.590363,37.643601],[-102.400935,37.441913],[-102.346536,37.441793]]]},"properties":{"id":"81084","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.142591,38.082742],[-103.14241,38.075605],[-103.136219,38.076699],[-103.142591,38.082742]]]},"properties":{"id":"81038","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.258313,40.014692],[-105.135953,40.014665],[-105.131268,40.072805],[-105.261773,40.086972],[-105.280042,40.063983],[-105.260367,40.025159],[-105.258313,40.014692]]]},"properties":{"id":"80301","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.236778,40.285875],[-105.320587,40.159377],[-105.261773,40.086972],[-105.131268,40.072805],[-105.130804,40.143343],[-105.131104,40.203252],[-105.131059,40.261079],[-105.236778,40.285875]],[[-105.172854,40.103579],[-105.168858,40.105247],[-105.168839,40.101606],[-105.172854,40.103579]]]},"properties":{"id":"80503","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.264773,39.914152],[-105.314894,39.941509],[-105.359984,39.950069],[-105.400687,39.935072],[-105.429725,39.931374],[-105.40966,39.756314],[-105.397949,39.747087],[-105.323706,39.73648],[-105.143374,39.773496],[-105.137961,39.786747],[-105.165175,39.801646],[-105.165174,39.812457],[-105.238933,39.914367],[-105.264773,39.914152]]]},"properties":{"id":"80403","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.859506,38.69399],[-105.938063,38.755212],[-105.967206,38.765746],[-106.358188,38.592713],[-106.176197,38.422386],[-105.969328,38.409543],[-105.826329,38.51036],[-105.81242,38.628654],[-105.859506,38.69399]],[[-106.305551,38.54761],[-106.292041,38.553046],[-106.285216,38.549877],[-106.305551,38.54761]],[[-106.100705,38.515849],[-106.085376,38.518503],[-106.075162,38.510143],[-106.083046,38.504422],[-106.100705,38.515849]]]},"properties":{"id":"81201","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.100705,38.515849],[-106.083046,38.504422],[-106.075162,38.510143],[-106.085376,38.518503],[-106.100705,38.515849]]]},"properties":{"id":"81242","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.910329,37.316069],[-105.928361,37.210096],[-105.748116,37.136641],[-105.740698,37.102158],[-105.430978,37.248927],[-105.552653,37.2872],[-105.743339,37.356798],[-105.910329,37.316069]]]},"properties":{"id":"81151","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.024526,37.087371],[-106.005181,37.108259],[-106.037821,37.122915],[-106.056023,37.093743],[-106.024526,37.087371]]]},"properties":{"id":"81129","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.058281,38.171098],[-103.948232,38.128158],[-103.857005,38.163733],[-103.857341,38.192176],[-103.866544,38.189634],[-103.866581,38.199084],[-103.8526,38.195536],[-103.99902,38.522727],[-104.053921,38.522393],[-104.058281,38.171098]]]},"properties":{"id":"81062","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.878928,37.931531],[-104.96298,37.958142],[-105.050078,38.075961],[-105.124419,38.066306],[-105.166304,38.017104],[-104.978843,37.738887],[-104.572023,37.87968],[-104.589587,37.926699],[-104.801877,37.951647],[-104.878928,37.931531]]]},"properties":{"id":"81069","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.059582,38.853512],[-107.914244,38.835846],[-107.776018,39.047783],[-107.874709,39.072426],[-108.09385,38.980444],[-108.230404,38.877773],[-108.059582,38.853512]]]},"properties":{"id":"81413","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.009489,39.753926],[-105.036956,39.747578],[-105.053212,39.74398],[-105.053253,39.7258],[-105.052593,39.722335],[-105.018663,39.722037],[-104.987485,39.722535],[-104.987413,39.738431],[-105.009489,39.753926]]]},"properties":{"id":"80204","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.659123,39.814033],[-104.600323,39.88459],[-104.607003,39.899413],[-104.67808,39.906584],[-104.790859,39.842009],[-104.790895,39.798251],[-104.790568,39.773323],[-104.734601,39.769181],[-104.659123,39.814033]]]},"properties":{"id":"80249","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.052593,39.722335],[-105.053225,39.696769],[-105.052625,39.682286],[-105.051782,39.667777],[-105.015722,39.666947],[-105.015696,39.678684],[-105.018663,39.722037],[-105.052593,39.722335]]]},"properties":{"id":"80219","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.009489,39.753926],[-104.987413,39.738431],[-104.986116,39.741678],[-104.986115,39.743279],[-104.987376,39.743357],[-104.987416,39.744885],[-104.986117,39.744889],[-104.986105,39.747526],[-104.988227,39.749586],[-104.989446,39.74865],[-104.990311,39.749322],[-104.989101,39.750253],[-104.993597,39.75933],[-104.998206,39.766894],[-105.009489,39.753926]],[[-104.990966,39.746118],[-104.989784,39.747065],[-104.988902,39.74638],[-104.990094,39.745446],[-104.990966,39.746118]]]},"properties":{"id":"80202","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.908889,37.810941],[-107.970086,37.639587],[-107.860845,37.775517],[-107.908889,37.810941]]]},"properties":{"id":"81332","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.546375,37.632605],[-108.821416,37.88092],[-108.916993,37.631903],[-108.694763,37.631516],[-108.546375,37.632605]]]},"properties":{"id":"81320","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.5558,39.660868],[-106.534149,39.509782],[-106.434116,39.595125],[-106.448363,39.608424],[-106.5558,39.660868]]]},"properties":{"id":"81620","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.37184,39.603957],[-106.226829,39.428787],[-106.216037,39.531679],[-106.37184,39.603957]]]},"properties":{"id":"81649","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.739294,39.786163],[-106.413125,39.829696],[-106.434508,39.924914],[-106.637004,40.057344],[-106.743499,39.861022],[-106.739294,39.786163]]]},"properties":{"id":"80423","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.63704,40.157938],[-106.67847,40.104408],[-106.836974,39.919197],[-106.860894,39.87489],[-106.847651,39.847056],[-106.743499,39.861022],[-106.637004,40.057344],[-106.63704,40.157938]]]},"properties":{"id":"80463","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.037248,40.053607],[-106.860894,39.87489],[-106.836974,39.919197],[-106.974235,40.04338],[-107.037248,40.053607]]]},"properties":{"id":"80426","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.113947,39.508613],[-107.426746,39.366168],[-107.430949,39.366178],[-107.394486,39.256299],[-107.10347,38.991748],[-107.064089,38.988212],[-106.996145,39.034175],[-107.00555,39.076249],[-107.142241,39.231012],[-106.97633,39.484665],[-107.113947,39.508613]]]},"properties":{"id":"81623","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.707072,39.566295],[-104.014407,39.565999],[-103.825823,39.303325],[-103.720885,39.040099],[-103.584198,39.203399],[-103.613063,39.566174],[-103.707072,39.566295]]]},"properties":{"id":"80828","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.348226,39.128857],[-104.607652,39.00606],[-104.636903,38.940526],[-104.628389,38.926405],[-104.627546,38.921644],[-104.680616,38.853189],[-104.553871,38.853356],[-104.461293,38.88178],[-104.348226,39.128857]]]},"properties":{"id":"80831","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.748129,38.831769],[-104.79773,38.832206],[-104.795787,38.809433],[-104.77095,38.777471],[-104.757502,38.781714],[-104.748129,38.831769]]]},"properties":{"id":"80910","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.682725,38.868064],[-104.680616,38.853189],[-104.627546,38.921644],[-104.682484,38.884163],[-104.673287,38.886626],[-104.673798,38.869212],[-104.682724,38.876691],[-104.682725,38.868064]]]},"properties":{"id":"80951","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.891634,38.954537],[-104.813545,38.94124],[-104.807735,38.98028],[-104.891634,38.954537]]]},"properties":{"id":"80840","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.02802,38.924872],[-104.975267,38.948923],[-105.027655,39.019542],[-105.02802,38.924872]]]},"properties":{"id":"80819","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.553638,38.838583],[-104.460124,38.766325],[-104.461293,38.88178],[-104.553871,38.853356],[-104.553638,38.838583]]]},"properties":{"id":"80930","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.628389,38.926405],[-104.680473,38.911801],[-104.68309,38.911787],[-104.682484,38.884163],[-104.627546,38.921644],[-104.628389,38.926405]]]},"properties":{"id":"80938","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.603548,38.307415],[-104.479437,38.334853],[-104.567087,38.520001],[-104.621919,38.606456],[-104.717994,38.51956],[-104.742822,38.425282],[-104.663536,38.292196],[-104.603548,38.307415]]]},"properties":{"id":"81008","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.199297,38.37437],[-105.15234,38.36745],[-105.132061,38.369835],[-105.133649,38.352292],[-105.153949,38.354273],[-105.20107,38.35221],[-105.21631,38.258621],[-105.150945,38.25783],[-104.957134,38.358215],[-105.11374,38.43718],[-105.199297,38.37437]]]},"properties":{"id":"81226","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.153949,38.354273],[-105.133649,38.352292],[-105.132061,38.369835],[-105.15234,38.36745],[-105.153949,38.354273]]]},"properties":{"id":"81221","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.142619,40.160088],[-106.024998,39.999997],[-106.009233,40.149391],[-106.142619,40.160088]]]},"properties":{"id":"80451","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.686612,38.578658],[-106.509856,38.577881],[-106.541036,38.580516],[-106.686612,38.578658]]]},"properties":{"id":"81239","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.562003,38.625439],[-106.622325,38.658176],[-106.679187,38.599464],[-106.686612,38.578658],[-106.541036,38.580516],[-106.562003,38.625439]]]},"properties":{"id":"81237","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.978843,37.738887],[-105.086253,37.678989],[-104.925753,37.397048],[-104.351109,37.817488],[-104.386307,37.827405],[-104.572023,37.87968],[-104.978843,37.738887]]]},"properties":{"id":"81089","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.878684,39.568247],[-102.977319,39.039305],[-102.798787,39.03971],[-102.814345,39.567952],[-102.878684,39.568247]]]},"properties":{"id":"80834","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.57369,40.833972],[-103.601067,40.523123],[-103.464665,40.408164],[-103.321503,40.435581],[-103.314969,40.56419],[-103.518573,40.819556],[-103.57369,40.833972]]]},"properties":{"id":"80741","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.293429,39.046873],[-108.502997,38.999981],[-108.695825,38.898504],[-108.731351,38.844074],[-108.601024,38.499988],[-108.583682,38.499988],[-108.378698,38.668097],[-108.230404,38.877773],[-108.255408,39.097363],[-108.293429,39.046873]]]},"properties":{"id":"81527","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.059795,39.189748],[-108.09385,38.980444],[-107.874709,39.072426],[-108.059795,39.189748]]]},"properties":{"id":"81646","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045831,37.32684],[-108.729748,37.178604],[-108.554638,37.265635],[-108.426139,37.334083],[-108.517526,37.385398],[-108.6374,37.482161],[-108.73547,37.471524],[-108.849361,37.440664],[-109.045831,37.32684]]]},"properties":{"id":"81321","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.956667,38.5655],[-107.63504,38.301896],[-107.649257,38.524333],[-107.84856,38.668113],[-107.900674,38.668179],[-107.956667,38.5655]]]},"properties":{"id":"81401","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.321503,40.435581],[-103.464665,40.408164],[-103.595395,40.321563],[-103.471195,40.334401],[-103.32091,40.435585],[-103.321503,40.435581]]]},"properties":{"id":"80733","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.654314,39.220823],[-105.366974,39.129577],[-105.397881,39.203277],[-105.898888,39.472295],[-105.917072,39.457969],[-105.879801,39.352506],[-105.717132,39.197828],[-105.654314,39.220823]]]},"properties":{"id":"80456","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.267489,40.748947],[-102.051486,40.64308],[-102.051416,40.749586],[-102.267489,40.748947]]]},"properties":{"id":"80721","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.950552,39.272642],[-106.921759,39.29794],[-106.918494,39.29763],[-106.92467,39.303927],[-106.980789,39.33775],[-107.142241,39.231012],[-107.00555,39.076249],[-106.980027,39.204891],[-106.950552,39.272642]]]},"properties":{"id":"81654","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.050824,40.222662],[-109.051163,39.660472],[-108.554707,39.687779],[-108.33292,40.04623],[-108.216503,40.221762],[-108.35414,40.221985],[-108.488588,40.222336],[-109.050824,40.222662]]]},"properties":{"id":"81648","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.632485,40.266977],[-107.083588,40.363414],[-107.101378,40.22514],[-107.037928,40.225372],[-106.834608,40.177796],[-106.801173,40.15287],[-106.67847,40.104408],[-106.63704,40.157938],[-106.632485,40.266977]]]},"properties":{"id":"80467","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.943005,37.826694],[-107.883641,37.864831],[-107.938035,37.901782],[-107.948537,37.834094],[-107.943005,37.826694]]]},"properties":{"id":"81426","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.062394,39.630737],[-106.063555,39.620225],[-106.055924,39.580537],[-105.864681,39.515065],[-105.829662,39.564865],[-105.776124,39.607753],[-105.92516,39.679319],[-106.062394,39.630737]]]},"properties":{"id":"80435","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.026699,39.691026],[-106.325952,39.91375],[-106.434508,39.924914],[-106.413125,39.829696],[-106.365325,39.752918],[-106.177604,39.609056],[-106.063555,39.620225],[-106.062394,39.630737],[-106.026699,39.691026]]]},"properties":{"id":"80498","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.033544,39.129819],[-105.25728,39.129552],[-105.258377,39.057702],[-105.075378,38.864777],[-105.067559,38.86267],[-105.02802,38.924872],[-105.027655,39.019542],[-105.030677,39.085255],[-105.033544,39.129819]]]},"properties":{"id":"80863","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.075378,38.864777],[-105.258377,39.057702],[-105.268425,39.018812],[-105.234426,38.828159],[-105.075378,38.864777]]]},"properties":{"id":"80814","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.02864,39.684694],[-102.878684,39.568247],[-102.814345,39.567952],[-102.802932,39.567841],[-102.80075,39.801505],[-102.972283,39.800943],[-103.02864,39.684694]]]},"properties":{"id":"80812","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.677619,40.373335],[-104.735026,40.384677],[-104.737672,40.359459],[-104.677619,40.373335]]]},"properties":{"id":"80620","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.772586,40.285604],[-104.659528,40.218083],[-104.602738,40.203352],[-104.300587,40.261864],[-104.30071,40.29374],[-104.582926,40.348437],[-104.677619,40.373335],[-104.737672,40.359459],[-104.759623,40.354281],[-104.784663,40.333569],[-104.773047,40.289908],[-104.772586,40.285604]]]},"properties":{"id":"80645","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.562712,40.812272],[-104.715165,40.870069],[-104.905815,40.754691],[-104.924275,40.682528],[-104.886536,40.676167],[-104.658392,40.682313],[-104.562712,40.812272]]]},"properties":{"id":"80648","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.02502,39.885214],[-104.98765,39.885341],[-104.98987,39.914064],[-104.987976,39.943074],[-105.015657,39.943093],[-105.027521,39.914256],[-105.02502,39.885214]]]},"properties":{"id":"80234","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.222424,37.675881],[-106.256945,37.400974],[-106.039331,37.400852],[-105.974691,37.632272],[-106.001685,37.69791],[-106.001581,37.705337],[-106.222424,37.675881]]]},"properties":{"id":"81144","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.443556,37.310616],[-102.506943,37.227631],[-102.041857,37.141522],[-102.041585,37.644282],[-102.20117,37.644201],[-102.346536,37.441793],[-102.443556,37.310616]]]},"properties":{"id":"81090","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.639295,38.021229],[-103.772877,37.821394],[-103.578893,37.643652],[-103.404339,37.643576],[-103.355573,38.265533],[-103.501764,38.265016],[-103.540176,38.172663],[-103.628182,38.027335],[-103.628349,38.017985],[-103.639295,38.021229]],[[-103.527752,38.093232],[-103.527437,38.103664],[-103.517201,38.103388],[-103.527752,38.093232]]]},"properties":{"id":"81050","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.834637,38.18647],[-102.760117,38.114517],[-102.668224,38.267299],[-102.834481,38.266461],[-102.834637,38.18647]]]},"properties":{"id":"81092","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.042117,38.099583],[-102.874804,37.643885],[-102.842546,38.066951],[-103.042117,38.099583]]]},"properties":{"id":"81044","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.26773,40.003438],[-105.285699,39.972315],[-105.270709,39.936196],[-105.258594,40.008161],[-105.26394,40.008217],[-105.26773,40.003438]]]},"properties":{"id":"80305","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.172854,40.103579],[-105.168839,40.101606],[-105.168858,40.105247],[-105.172854,40.103579]]]},"properties":{"id":"80544","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-102.995766,38.979833],[-102.994933,39.039362],[-103.051232,39.039557],[-102.995766,38.979833]]],[[[-102.995766,38.979833],[-103.163792,38.762659],[-103.015878,38.76341],[-102.995766,38.979833]]]]},"properties":{"id":"80862","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.092639,37.268878],[-106.196004,37.342704],[-106.207216,37.291421],[-106.118644,37.281154],[-106.092639,37.268878]]]},"properties":{"id":"81124","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.430978,37.248927],[-105.740698,37.102158],[-105.718463,36.995841],[-105.664735,36.995874],[-105.68272,37.043749],[-105.57436,37.054783],[-105.5732,36.995923],[-105.155042,36.995262],[-105.154176,37.293129],[-105.165221,37.290702],[-105.430978,37.248927]],[[-105.399321,37.151992],[-105.301758,37.171432],[-105.301254,37.123706],[-105.399321,37.151992]]]},"properties":{"id":"81152","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.399321,37.151992],[-105.301254,37.123706],[-105.301758,37.171432],[-105.399321,37.151992]]]},"properties":{"id":"81126","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.857341,38.192176],[-103.8526,38.195536],[-103.866581,38.199084],[-103.866544,38.189634],[-103.857341,38.192176]]]},"properties":{"id":"81033","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.150945,38.25783],[-105.124419,38.066306],[-105.050078,38.075961],[-105.048443,38.155479],[-104.940747,38.33753],[-104.940748,38.337768],[-104.957134,38.358215],[-105.150945,38.25783]]]},"properties":{"id":"81253","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.721624,39.065255],[-107.578853,38.784139],[-107.500611,38.799206],[-107.60903,39.159621],[-107.721624,39.065255]]]},"properties":{"id":"81428","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.090798,39.754795],[-105.090873,39.725457],[-105.053253,39.7258],[-105.053212,39.74398],[-105.062645,39.762093],[-105.090798,39.754795]]]},"properties":{"id":"80214","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.990966,39.746118],[-104.990094,39.745446],[-104.988902,39.74638],[-104.989784,39.747065],[-104.990966,39.746118]]]},"properties":{"id":"80293","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.000539,39.476629],[-105.006923,39.487558],[-105.014445,39.479074],[-105.000539,39.476629]]]},"properties":{"id":"80131","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.97633,39.484665],[-107.142241,39.231012],[-106.980789,39.33775],[-106.509214,39.362298],[-106.561086,39.42982],[-106.689182,39.443839],[-106.97633,39.484665]]]},"properties":{"id":"81621","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.812796,38.81263],[-104.859168,38.828327],[-104.886575,38.824775],[-104.938986,38.792013],[-104.939216,38.730393],[-104.939744,38.696142],[-104.810435,38.746376],[-104.803698,38.765385],[-104.761423,38.752845],[-104.77095,38.777471],[-104.795787,38.809433],[-104.812796,38.81263]]]},"properties":{"id":"80906","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.747977,38.839816],[-104.715746,38.831217],[-104.70153,38.831404],[-104.69186,38.838945],[-104.680616,38.853189],[-104.682725,38.868064],[-104.721075,38.873641],[-104.747849,38.866249],[-104.747977,38.839816]]]},"properties":{"id":"80915","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.74589,38.983776],[-104.719884,38.940198],[-104.711278,38.940415],[-104.74589,38.983776]]]},"properties":{"id":"80924","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-104.818613,38.719821],[-104.803698,38.765385],[-104.810435,38.746376],[-104.818613,38.719821]]],[[[-104.865667,38.64984],[-104.746217,38.643026],[-104.746432,38.651954],[-104.847399,38.679587],[-104.865667,38.64984]]]]},"properties":{"id":"80902","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.975267,38.948923],[-105.02802,38.924872],[-105.067559,38.86267],[-105.011534,38.796982],[-104.908516,38.867862],[-104.911914,38.893374],[-104.975267,38.948923]]]},"properties":{"id":"80809","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.682724,38.876691],[-104.673798,38.869212],[-104.673287,38.886626],[-104.682484,38.884163],[-104.682724,38.876691]]]},"properties":{"id":"80939","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.602889,38.264874],[-105.6,38.287765],[-105.61509,38.29843],[-105.612555,38.264871],[-105.602889,38.264874]]]},"properties":{"id":"81232","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.753513,39.366245],[-107.552343,39.366092],[-107.644411,39.627111],[-107.753513,39.366245]]]},"properties":{"id":"81652","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.884138,39.366195],[-108.159444,39.695099],[-108.128034,39.366234],[-107.884138,39.366195]]]},"properties":{"id":"81635","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.569955,39.801022],[-105.42229,39.751309],[-105.397949,39.747087],[-105.40966,39.756314],[-105.569955,39.801022]]]},"properties":{"id":"80427","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.829538,39.990251],[-105.959047,39.799877],[-105.887622,39.7966],[-105.735925,39.804985],[-105.724125,39.819018],[-105.690348,39.851996],[-105.675798,39.932445],[-105.689127,40.015853],[-105.728066,40.041125],[-105.829538,39.990251]]]},"properties":{"id":"80482","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.945147,40.363947],[-105.634947,40.075799],[-105.666399,40.157335],[-105.653321,40.260457],[-105.822463,40.476829],[-105.854926,40.486252],[-105.945147,40.363947]]]},"properties":{"id":"80447","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.238933,39.914367],[-105.165174,39.812457],[-105.165142,39.82701],[-105.165559,39.891155],[-105.147342,39.913886],[-105.238933,39.914367]]]},"properties":{"id":"80007","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.261053,39.241856],[-105.170963,39.407825],[-105.236245,39.435552],[-105.261053,39.241856]]]},"properties":{"id":"80425","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.550192,39.419453],[-105.398049,39.314293],[-105.444922,39.565988],[-105.623875,39.567036],[-105.66211,39.566268],[-105.593453,39.43707],[-105.584464,39.484392],[-105.5287,39.464],[-105.550192,39.419453]]]},"properties":{"id":"80421","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.980417,40.34264],[-104.980074,40.269667],[-104.867508,40.275071],[-104.907207,40.406802],[-104.944568,40.406964],[-104.992979,40.40708],[-104.980417,40.34264]]]},"properties":{"id":"80534","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.096378,40.479989],[-105.033064,40.465666],[-104.996246,40.539572],[-104.944206,40.537292],[-104.944145,40.550705],[-105.078936,40.567096],[-105.096378,40.479989]]]},"properties":{"id":"80525","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.161917,40.589365],[-105.07894,40.570806],[-105.139487,40.620966],[-105.168261,40.629737],[-105.161917,40.589365]]]},"properties":{"id":"80521","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.133413,37.189907],[-104.465629,37.367182],[-104.733893,37.311499],[-104.716932,37.198632],[-104.840358,36.993363],[-104.433987,36.993738],[-104.195433,37.136789],[-104.133413,37.189907]]]},"properties":{"id":"81082","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.154176,37.293129],[-105.155042,36.995262],[-104.840358,36.993363],[-104.716932,37.198632],[-104.844982,37.258667],[-105.02898,37.339461],[-105.154176,37.293129]]]},"properties":{"id":"81091","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.940633,40.769948],[-102.924602,40.913134],[-103.1688,40.833445],[-103.171489,40.760601],[-103.055353,40.708841],[-102.940633,40.769948]]]},"properties":{"id":"80736","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.48156,39.104958],[-108.459549,39.055145],[-108.3229,39.135278],[-108.459442,39.162086],[-108.470647,39.126074],[-108.48156,39.104958]]]},"properties":{"id":"81520","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.051618,39.111653],[-109.05721,38.79955],[-108.731351,38.844074],[-108.695825,38.898504],[-108.743212,39.054825],[-109.051618,39.111653]]]},"properties":{"id":"81523","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.830598,39.366346],[-109.050765,39.366677],[-109.051296,39.193264],[-108.830598,39.366346]]]},"properties":{"id":"81525","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.429345,40.440771],[-107.756377,40.385963],[-107.439386,40.223379],[-107.429345,40.440771]]]},"properties":{"id":"81638","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.756377,40.385963],[-107.965307,40.388033],[-108.216503,40.221762],[-108.33292,40.04623],[-107.821963,39.824949],[-107.476532,39.828267],[-107.037363,40.091538],[-107.037634,40.127107],[-107.037928,40.225372],[-107.101378,40.22514],[-107.439386,40.223379],[-107.756377,40.385963]]]},"properties":{"id":"81641","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.35414,40.221985],[-108.535614,40.369591],[-108.488588,40.222336],[-108.35414,40.221985]]]},"properties":{"id":"81633","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.83566,40.271206],[-103.82971,40.266903],[-103.821513,40.269616],[-103.834186,40.274833],[-103.83566,40.271206]]]},"properties":{"id":"80705","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.051442,40.52432],[-104.300026,40.436823],[-104.30071,40.29374],[-104.300587,40.261864],[-104.296748,40.261839],[-104.150358,40.232148],[-104.11677,40.322238],[-103.975077,40.510051],[-103.985763,40.524148],[-104.051442,40.52432]]]},"properties":{"id":"80649","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.8833,38.007371],[-107.736059,37.93228],[-107.573522,38.134953],[-107.56862,38.147267],[-107.622235,38.277838],[-107.965789,38.152328],[-107.8833,38.007371]]]},"properties":{"id":"81432","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.980027,39.204891],[-106.969961,39.155863],[-106.905511,39.174168],[-106.950552,39.272642],[-106.980027,39.204891]]]},"properties":{"id":"81615","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.368599,38.266472],[-104.193872,38.127788],[-104.058281,38.171098],[-104.053921,38.522393],[-104.275471,38.521133],[-104.567087,38.520001],[-104.479437,38.334853],[-104.445576,38.273785],[-104.368599,38.266472]]]},"properties":{"id":"81025","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.794023,38.051776],[-104.646735,38.26961],[-104.678781,38.267979],[-104.940747,38.33753],[-105.048443,38.155479],[-104.794023,38.051776]]]},"properties":{"id":"81005","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.368599,38.266472],[-104.6179,38.149351],[-104.589587,37.926699],[-104.572023,37.87968],[-104.386307,37.827405],[-104.193872,38.127788],[-104.368599,38.266472]]]},"properties":{"id":"81022","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.037928,40.225372],[-107.037634,40.127107],[-106.834608,40.177796],[-107.037928,40.225372]]]},"properties":{"id":"80469","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.974235,40.04338],[-106.801173,40.15287],[-106.834608,40.177796],[-107.037634,40.127107],[-107.037363,40.091538],[-107.037248,40.053607],[-106.974235,40.04338]]]},"properties":{"id":"80483","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.460334,41.002383],[-102.420115,40.778193],[-102.419609,40.749153],[-102.306371,40.749126],[-102.379695,41.002444],[-102.460334,41.002383]]]},"properties":{"id":"80744","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.159934,40.43669],[-102.935231,39.946045],[-102.855458,40.438307],[-102.942211,40.437908],[-103.159934,40.43669]]]},"properties":{"id":"80743","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.735026,40.384677],[-104.858362,40.439972],[-104.879883,40.406803],[-104.759623,40.354281],[-104.737672,40.359459],[-104.735026,40.384677]]]},"properties":{"id":"80634","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.413354,40.479296],[-104.300026,40.436823],[-104.051442,40.52432],[-104.085613,40.698818],[-104.50451,40.815314],[-104.486875,40.624163],[-104.456896,40.576553],[-104.413144,40.51552],[-104.413354,40.479296]]]},"properties":{"id":"80611","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.658392,40.682313],[-104.888483,40.617107],[-104.850972,40.566877],[-104.486875,40.624163],[-104.50451,40.815314],[-104.562712,40.812272],[-104.658392,40.682313]]]},"properties":{"id":"80610","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.465753,39.860793],[-102.431005,39.997828],[-102.452491,40.265312],[-102.452313,40.318163],[-102.613971,39.831104],[-102.473726,39.760916],[-102.464025,39.7602],[-102.465753,39.860793]]]},"properties":{"id":"80727","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.489639,39.955685],[-104.602738,40.203352],[-104.659528,40.218083],[-104.697032,40.189375],[-104.694278,40.078335],[-104.607003,39.899413],[-104.600323,39.88459],[-104.52669,39.88379],[-104.489639,39.955685]]]},"properties":{"id":"80642","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.065106,39.788813],[-105.043848,39.796975],[-105.081437,39.802777],[-105.165175,39.801646],[-105.137961,39.786747],[-105.065106,39.788813]]]},"properties":{"id":"80002","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.988323,39.59457],[-104.990086,39.568001],[-104.970266,39.566073],[-104.922744,39.566066],[-104.922888,39.595059],[-104.988323,39.59457]]]},"properties":{"id":"80122","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.110153,39.59766],[-105.028937,39.595145],[-105.016041,39.627966],[-105.03477,39.632409],[-105.053333,39.638893],[-105.109846,39.638699],[-105.110153,39.59766]]]},"properties":{"id":"80123","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.016041,39.627966],[-105.028937,39.595145],[-105.04085,39.566145],[-105.033019,39.564921],[-104.990086,39.568001],[-104.988323,39.59457],[-104.988027,39.616922],[-104.992669,39.621048],[-105.016041,39.627966]]]},"properties":{"id":"80120","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.809913,39.678438],[-104.865942,39.68202],[-104.870882,39.674764],[-104.880086,39.650773],[-104.880322,39.641651],[-104.877728,39.643248],[-104.857203,39.653246],[-104.808027,39.638492],[-104.809913,39.678438]]]},"properties":{"id":"80014","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.033019,39.564921],[-104.996627,39.513026],[-104.970266,39.566073],[-104.990086,39.568001],[-105.033019,39.564921]]]},"properties":{"id":"80129","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.438767,37.025208],[-107.442197,37.000005],[-107.425016,37.000006],[-107.438767,37.025208]]],[[[-107.482179,37.639501],[-107.365613,37.136941],[-107.422395,37.000005],[-106.90638,37.000122],[-106.648303,37.216108],[-106.678373,37.403596],[-106.763129,37.489855],[-107.482188,37.66769],[-107.482179,37.639501]]]]},"properties":{"id":"81147","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.07514,37.469366],[-102.85065,37.148744],[-102.506943,37.227631],[-102.443556,37.310616],[-102.400935,37.441913],[-102.590363,37.643601],[-102.874804,37.643885],[-103.075284,37.520365],[-103.07514,37.469366]]]},"properties":{"id":"81073","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.400935,37.441913],[-102.443556,37.310616],[-102.346536,37.441793],[-102.400935,37.441913]]]},"properties":{"id":"81087","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.516743,40.046123],[-105.521385,40.032436],[-105.359984,39.950069],[-105.314894,39.941509],[-105.285699,39.972315],[-105.26773,40.003438],[-105.276923,40.01049],[-105.268335,40.011741],[-105.26394,40.008217],[-105.258594,40.008161],[-105.258313,40.014692],[-105.260367,40.025159],[-105.29789,40.063702],[-105.280042,40.063983],[-105.261773,40.086972],[-105.320587,40.159377],[-105.516743,40.046123]]]},"properties":{"id":"80302","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.093383,40.061142],[-105.052823,40.00026],[-104.97571,40.000322],[-104.961408,40.000337],[-104.942563,40.033095],[-104.978912,40.087496],[-105.093383,40.061142]]]},"properties":{"id":"80516","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.152068,39.994335],[-105.238933,39.914367],[-105.147342,39.913886],[-105.137579,39.939688],[-105.109265,39.97241],[-105.152068,39.994335]]]},"properties":{"id":"80027","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.358188,38.592713],[-105.967206,38.765746],[-106.409307,38.827888],[-106.451867,38.698343],[-106.358188,38.592713]]]},"properties":{"id":"81236","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.82648,38.694306],[-105.530393,38.921289],[-105.654314,39.220823],[-105.717132,39.197828],[-105.821194,39.091989],[-106.179683,39.195773],[-106.191006,39.056073],[-105.938063,38.755212],[-105.859506,38.69399],[-105.82648,38.694306]]]},"properties":{"id":"80449","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.627023,38.613252],[-102.650529,39.039645],[-102.70664,39.038789],[-102.798787,39.03971],[-102.977319,39.039305],[-102.994933,39.039362],[-102.995766,38.979833],[-103.015878,38.76341],[-103.163792,38.762659],[-103.172336,38.690058],[-103.172943,38.61245],[-103.025637,38.614994],[-102.627023,38.613252]]]},"properties":{"id":"80825","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.661296,39.752799],[-105.776124,39.607753],[-105.829662,39.564865],[-105.721601,39.565269],[-105.660541,39.752159],[-105.661296,39.752799]]]},"properties":{"id":"80444","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.96541,37.166965],[-105.748116,37.136641],[-105.928361,37.210096],[-105.96541,37.166965]]]},"properties":{"id":"81141","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.982523,37.166974],[-105.982269,37.177381],[-105.986996,37.177279],[-105.989709,37.167087],[-105.982523,37.166974]]]},"properties":{"id":"81148","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.94189,39.743764],[-104.964757,39.746943],[-104.959307,39.715312],[-104.941339,39.716229],[-104.940705,39.716223],[-104.94189,39.743764]]]},"properties":{"id":"80206","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.05321,39.653346],[-105.119035,39.652549],[-105.128614,39.638665],[-105.109846,39.638699],[-105.053333,39.638893],[-105.05321,39.653346]]]},"properties":{"id":"80235","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.880654,38.154048],[-109.041667,38.153021],[-109.041512,37.881167],[-108.821416,37.88092],[-108.546375,37.632605],[-108.331642,37.893884],[-108.713093,38.016489],[-108.772835,38.153296],[-108.880654,38.154048]]]},"properties":{"id":"81325","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.868189,39.412624],[-104.891475,39.297015],[-104.77434,39.213821],[-104.789105,39.396396],[-104.868189,39.412624]]]},"properties":{"id":"80104","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.901525,39.425338],[-104.91814,39.296831],[-104.891475,39.297015],[-104.868189,39.412624],[-104.901525,39.425338]]]},"properties":{"id":"80109","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.662896,39.129527],[-104.607652,39.00606],[-104.348226,39.128857],[-104.384903,39.241441],[-104.543552,39.288651],[-104.662891,39.245822],[-104.701964,39.189768],[-104.662896,39.129527]]]},"properties":{"id":"80106","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.911702,39.085991],[-104.900716,39.129831],[-105.029549,39.129831],[-105.033544,39.129819],[-105.030677,39.085255],[-104.911702,39.085991]]]},"properties":{"id":"80133","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.678517,39.702397],[-106.564376,39.435394],[-106.534149,39.509782],[-106.5558,39.660868],[-106.678517,39.702397]]]},"properties":{"id":"81632","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.106526,39.379978],[-103.975585,39.245339],[-103.825823,39.303325],[-104.014407,39.565999],[-104.274117,39.433042],[-104.106526,39.379978]]]},"properties":{"id":"80101","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.807735,38.98028],[-104.813545,38.94124],[-104.812851,38.932955],[-104.756821,38.938031],[-104.719884,38.940198],[-104.74589,38.983776],[-104.781103,38.998494],[-104.807735,38.98028]]]},"properties":{"id":"80920","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.891634,38.954537],[-104.8756,38.887815],[-104.874767,38.888518],[-104.828178,38.902233],[-104.812851,38.932955],[-104.813545,38.94124],[-104.891634,38.954537]]]},"properties":{"id":"80919","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.809527,38.855067],[-104.79773,38.832206],[-104.748129,38.831769],[-104.747977,38.839816],[-104.747849,38.866249],[-104.795061,38.873553],[-104.809527,38.855067]]]},"properties":{"id":"80909","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.720601,38.781254],[-104.681959,38.723079],[-104.62764,38.693993],[-104.608463,38.781047],[-104.673304,38.813043],[-104.720601,38.781254]]]},"properties":{"id":"80925","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.803698,38.765385],[-104.818613,38.719821],[-104.847399,38.679587],[-104.746432,38.651954],[-104.747426,38.739039],[-104.761423,38.752845],[-104.803698,38.765385]]]},"properties":{"id":"80913","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.608463,38.781047],[-104.553638,38.838583],[-104.553871,38.853356],[-104.680616,38.853189],[-104.69186,38.838945],[-104.673304,38.813043],[-104.608463,38.781047]]]},"properties":{"id":"80929","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.644411,39.627111],[-107.552343,39.366092],[-107.430949,39.366178],[-107.426746,39.366168],[-107.422316,39.768357],[-107.476532,39.828267],[-107.821963,39.824949],[-107.644411,39.627111]]]},"properties":{"id":"81647","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.142619,40.160088],[-106.009233,40.149391],[-106.024998,39.999997],[-105.980699,39.943888],[-105.912495,39.98351],[-105.728066,40.041125],[-105.689127,40.015853],[-105.652793,40.023772],[-105.634947,40.075799],[-105.945147,40.363947],[-106.011742,40.369488],[-106.118213,40.345037],[-106.140284,40.330103],[-106.143101,40.290593],[-106.142619,40.160088]]]},"properties":{"id":"80446","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.959047,39.799877],[-105.829538,39.990251],[-105.912495,39.98351],[-105.980699,39.943888],[-105.959047,39.799877]]]},"properties":{"id":"80442","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.652112,40.445231],[-106.632485,40.266977],[-106.63704,40.157938],[-106.637004,40.057344],[-106.434508,39.924914],[-106.325952,39.91375],[-106.143101,40.290593],[-106.140284,40.330103],[-106.652112,40.445231]]]},"properties":{"id":"80459","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.064089,38.988212],[-106.824338,38.978975],[-106.996145,39.034175],[-107.064089,38.988212]]]},"properties":{"id":"81225","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.917152,38.550995],[-106.922133,38.551018],[-106.920825,38.547525],[-106.917152,38.550995]]]},"properties":{"id":"81231","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.19276,39.705529],[-105.122328,39.663895],[-105.129079,39.682103],[-105.127978,39.696468],[-105.130575,39.725282],[-105.19276,39.705529]]]},"properties":{"id":"80228","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.914515,37.229053],[-108.186839,36.999276],[-108.037602,36.99974],[-107.914515,37.229053]]],[[[-108.00633,37.271214],[-107.712173,37.226833],[-107.591984,37.639629],[-107.839021,37.778527],[-107.860845,37.775517],[-107.970086,37.639587],[-108.034686,37.460752],[-108.00633,37.271214]]]]},"properties":{"id":"81301","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.161917,40.589365],[-105.214536,40.586783],[-105.096378,40.479989],[-105.078936,40.567096],[-105.07894,40.570806],[-105.161917,40.589365]]]},"properties":{"id":"80526","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.07894,40.570806],[-105.078936,40.567096],[-104.944145,40.550705],[-104.869934,40.54542],[-104.850972,40.566877],[-104.888483,40.617107],[-104.886536,40.676167],[-104.924275,40.682528],[-105.17055,40.841545],[-105.139487,40.620966],[-105.07894,40.570806]]]},"properties":{"id":"80524","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.431351,40.456379],[-105.415165,40.367723],[-105.266014,40.434148],[-105.364511,40.506644],[-105.449929,40.528044],[-105.431351,40.456379]]]},"properties":{"id":"80515","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.716932,37.198632],[-104.733893,37.311499],[-104.844982,37.258667],[-104.716932,37.198632]]]},"properties":{"id":"81024","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.055353,40.708841],[-102.942211,40.437908],[-102.855458,40.438307],[-102.798842,40.438503],[-102.651448,40.792959],[-102.651814,40.865445],[-102.940633,40.769948],[-103.055353,40.708841]]]},"properties":{"id":"80728","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.518573,40.819556],[-103.171489,40.760601],[-103.1688,40.833445],[-103.345511,41.001834],[-103.574522,41.001721],[-103.573813,40.859564],[-103.57369,40.833972],[-103.518573,40.819556]]]},"properties":{"id":"80745","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.242806,39.191733],[-108.255408,39.097363],[-108.230404,38.877773],[-108.09385,38.980444],[-108.059795,39.189748],[-108.158868,39.267976],[-108.242806,39.191733]]]},"properties":{"id":"81643","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.617171,39.265276],[-108.660188,39.108787],[-108.594073,39.081491],[-108.570885,39.091848],[-108.470647,39.126074],[-108.459442,39.162086],[-108.578334,39.296427],[-108.617171,39.265276]]]},"properties":{"id":"81505","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.830598,39.366346],[-109.051296,39.193264],[-109.05158,39.116535],[-108.617171,39.265276],[-108.578334,39.296427],[-108.830598,39.366346]]]},"properties":{"id":"81524","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.849361,37.440664],[-108.683051,37.558686],[-108.593995,37.562536],[-108.694763,37.631516],[-108.916993,37.631903],[-109.043488,37.484684],[-109.045831,37.32684],[-108.849361,37.440664]]]},"properties":{"id":"81331","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.060256,38.492964],[-109.060107,38.327303],[-108.880654,38.154048],[-108.772835,38.153296],[-108.759275,38.153374],[-109.060256,38.492964]]]},"properties":{"id":"81429","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.575165,38.48715],[-108.18218,38.32373],[-107.956667,38.5655],[-107.900674,38.668179],[-108.378698,38.668097],[-108.583682,38.499988],[-108.575165,38.48715]]]},"properties":{"id":"81425","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.975077,40.510051],[-104.11677,40.322238],[-103.979964,40.264005],[-103.975077,40.510051]]]},"properties":{"id":"80653","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.509214,39.362298],[-106.980789,39.33775],[-106.92467,39.303927],[-106.489103,39.248537],[-106.426487,39.361871],[-106.509214,39.362298]]]},"properties":{"id":"81642","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.921759,39.29794],[-106.880955,39.257241],[-106.889637,39.279227],[-106.918494,39.29763],[-106.921759,39.29794]]]},"properties":{"id":"81656","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.864681,39.515065],[-106.055924,39.580537],[-106.135529,39.379546],[-106.062616,39.36182],[-105.917072,39.457969],[-105.898888,39.472295],[-105.864681,39.515065]]]},"properties":{"id":"80424","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.026699,39.691026],[-106.062394,39.630737],[-105.92516,39.679319],[-105.924618,39.698972],[-106.026699,39.691026]]]},"properties":{"id":"80497","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.239925,38.677002],[-104.939216,38.730393],[-104.938986,38.792013],[-105.011534,38.796982],[-105.067559,38.86267],[-105.075378,38.864777],[-105.234426,38.828159],[-105.242556,38.696973],[-105.239925,38.677002]]]},"properties":{"id":"80813","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.939216,38.730393],[-105.239925,38.677002],[-105.109724,38.647531],[-104.994989,38.650878],[-104.939744,38.696142],[-104.939216,38.730393]]]},"properties":{"id":"80860","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.471991,40.001529],[-103.459118,39.914165],[-103.312412,39.783437],[-102.972283,39.800943],[-102.80075,39.801505],[-102.935231,39.946045],[-103.159934,40.43669],[-103.226558,40.436087],[-103.32091,40.435585],[-103.471195,40.334401],[-103.471991,40.001529]]]},"properties":{"id":"80720","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.869934,40.54542],[-104.848455,40.523373],[-104.851185,40.520963],[-104.832311,40.501811],[-104.564587,40.478741],[-104.489088,40.522484],[-104.456896,40.576553],[-104.486875,40.624163],[-104.850972,40.566877],[-104.869934,40.54542]]]},"properties":{"id":"80615","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.980074,40.269667],[-105.036469,40.243927],[-104.979148,40.233484],[-104.980074,40.269667]]]},"properties":{"id":"80542","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.803373,40.523874],[-103.573813,40.859564],[-103.574522,41.001721],[-103.914845,41.001701],[-104.085613,40.698818],[-104.051442,40.52432],[-103.985763,40.524148],[-103.803373,40.523874]]]},"properties":{"id":"80742","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.024773,39.841789],[-105.047929,39.841796],[-105.034505,39.820168],[-105.024773,39.841789]]]},"properties":{"id":"80030","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.137579,39.939688],[-105.0601,39.870945],[-105.027521,39.914256],[-105.015657,39.943093],[-105.058567,39.975502],[-105.109265,39.97241],[-105.137579,39.939688]]]},"properties":{"id":"80020","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.923499,39.638619],[-104.922888,39.595059],[-104.821003,39.594989],[-104.877728,39.643248],[-104.880322,39.641651],[-104.921193,39.644819],[-104.923499,39.638619]]]},"properties":{"id":"80111","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.94063,39.692941],[-104.922324,39.69299],[-104.922272,39.714722],[-104.940705,39.716223],[-104.941339,39.716229],[-104.940612,39.69657],[-104.94063,39.692941]]]},"properties":{"id":"80246","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.110153,39.59766],[-105.09351,39.488006],[-105.04085,39.566145],[-105.028937,39.595145],[-105.110153,39.59766]]]},"properties":{"id":"80128","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.91293,39.653089],[-104.931703,39.653044],[-104.921193,39.644819],[-104.880322,39.641651],[-104.880086,39.650773],[-104.900801,39.65311],[-104.91293,39.653089]]]},"properties":{"id":"80237","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.940705,39.716223],[-104.922272,39.714722],[-104.912696,39.717292],[-104.884708,39.732846],[-104.884664,39.747467],[-104.898767,39.747441],[-104.940635,39.74739],[-104.94189,39.743764],[-104.940705,39.716223]]]},"properties":{"id":"80220","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.85065,37.148744],[-103.07514,37.469366],[-103.086106,37.000264],[-102.886687,36.999511],[-102.85065,37.148744]]]},"properties":{"id":"81064","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.135953,40.014665],[-105.258313,40.014692],[-105.258594,40.008161],[-105.270709,39.936196],[-105.264773,39.914152],[-105.238933,39.914367],[-105.152068,39.994335],[-105.135953,40.014665]]]},"properties":{"id":"80303","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.264773,39.914152],[-105.270709,39.936196],[-105.285699,39.972315],[-105.314894,39.941509],[-105.264773,39.914152]]]},"properties":{"id":"80025","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.305551,38.54761],[-106.285216,38.549877],[-106.292041,38.553046],[-106.305551,38.54761]]]},"properties":{"id":"81227","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.650529,39.039645],[-102.627023,38.613252],[-102.585863,38.613317],[-102.327479,38.615179],[-102.354002,39.045674],[-102.409929,39.044151],[-102.520751,39.041356],[-102.650529,39.039645]]]},"properties":{"id":"80810","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.644735,38.749639],[-103.172336,38.690058],[-103.163792,38.762659],[-102.995766,38.979833],[-103.051232,39.039557],[-103.163072,39.127991],[-103.379224,39.243298],[-103.584198,39.203399],[-103.720885,39.040099],[-103.721292,38.98263],[-103.756865,38.852111],[-103.644735,38.749639]]]},"properties":{"id":"80821","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.485516,37.577899],[-105.743339,37.356798],[-105.552653,37.2872],[-105.445885,37.604507],[-105.485516,37.577899]]]},"properties":{"id":"81123","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.040012,38.828372],[-107.909224,38.768405],[-107.924406,38.828069],[-108.040012,38.828372]]]},"properties":{"id":"81410","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.040012,38.828372],[-107.924406,38.828069],[-107.914244,38.835846],[-108.059582,38.853512],[-108.040012,38.828372]]]},"properties":{"id":"81418","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.649257,38.524333],[-107.500614,38.445584],[-107.500625,38.687259],[-107.500611,38.799206],[-107.578853,38.784139],[-107.838731,38.737316],[-107.84856,38.668113],[-107.649257,38.524333]]]},"properties":{"id":"81415","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.60903,39.159621],[-107.500611,38.799206],[-107.500625,38.687259],[-107.093009,38.837001],[-107.10347,38.991748],[-107.394486,39.256299],[-107.60903,39.159621]]]},"properties":{"id":"81434","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.898767,39.747441],[-104.896609,39.778257],[-104.940487,39.772886],[-104.940635,39.74739],[-104.898767,39.747441]]]},"properties":{"id":"80207","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.986117,39.744889],[-104.987416,39.744885],[-104.987376,39.743357],[-104.986115,39.743279],[-104.986117,39.744889]]]},"properties":{"id":"80290","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.789105,39.396396],[-104.77434,39.213821],[-104.701964,39.189768],[-104.662891,39.245822],[-104.662729,39.427492],[-104.768882,39.416831],[-104.789105,39.396396]]]},"properties":{"id":"80116","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.825823,39.303325],[-103.975585,39.245339],[-104.051589,39.12837],[-103.721292,38.98263],[-103.720885,39.040099],[-103.825823,39.303325]]]},"properties":{"id":"80830","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.636903,38.940526],[-104.607652,39.00606],[-104.662896,39.129527],[-104.766571,39.129674],[-104.770686,39.099986],[-104.781103,38.998494],[-104.74589,38.983776],[-104.711278,38.940415],[-104.678141,38.940835],[-104.636903,38.940526]]]},"properties":{"id":"80908","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.911702,39.085991],[-104.770686,39.099986],[-104.766571,39.129674],[-104.900716,39.129831],[-104.911702,39.085991]]]},"properties":{"id":"80132","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.828707,38.85105],[-104.83656,38.850115],[-104.859168,38.828327],[-104.812796,38.81263],[-104.828707,38.85105]]]},"properties":{"id":"80905","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.721075,38.873641],[-104.682725,38.868064],[-104.682724,38.876691],[-104.682484,38.884163],[-104.68309,38.911787],[-104.719493,38.910927],[-104.721075,38.873641]]]},"properties":{"id":"80922","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.127586,38.940995],[-104.303763,38.775797],[-104.275471,38.521133],[-104.053921,38.522393],[-104.127586,38.940995]]]},"properties":{"id":"80864","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.554707,39.687779],[-109.051163,39.660472],[-109.050765,39.366677],[-108.830598,39.366346],[-108.578334,39.296427],[-108.459442,39.162086],[-108.3229,39.135278],[-108.242806,39.191733],[-108.158868,39.267976],[-108.128034,39.366234],[-108.159444,39.695099],[-108.554707,39.687779]]]},"properties":{"id":"81630","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.573522,38.134953],[-107.568875,37.965015],[-107.517505,37.837517],[-107.000602,37.956041],[-107.001119,38.147072],[-107.383901,38.147204],[-107.56862,38.147267],[-107.573522,38.134953]]]},"properties":{"id":"81235","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.276194,39.651656],[-105.27764,39.635105],[-105.276821,39.642579],[-105.276194,39.651656]]]},"properties":{"id":"80457","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.269725,39.561802],[-105.398949,39.566056],[-105.236245,39.435552],[-105.170963,39.407825],[-105.123517,39.434245],[-105.133132,39.466312],[-105.225046,39.516637],[-105.269725,39.561802]]]},"properties":{"id":"80433","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.27176,39.056425],[-106.506469,39.178797],[-106.577966,39.057908],[-106.27176,39.056425]]]},"properties":{"id":"81251","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.39175,37.215991],[-108.295164,37.215009],[-108.03881,37.456526],[-108.517526,37.385398],[-108.426139,37.334083],[-108.39175,37.215991]]]},"properties":{"id":"81328","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.190554,40.997578],[-105.892203,40.520747],[-105.793154,40.810317],[-105.899713,40.997091],[-106.190554,40.997578]]]},"properties":{"id":"82063","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.982222,40.464798],[-105.033064,40.465666],[-105.096378,40.479989],[-105.214536,40.586783],[-105.364511,40.506644],[-105.266014,40.434148],[-104.992979,40.40708],[-104.944568,40.406964],[-104.982222,40.464798]]]},"properties":{"id":"80538","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.516989,40.51988],[-105.431351,40.456379],[-105.449929,40.528044],[-105.516989,40.51988]]]},"properties":{"id":"80532","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.996246,40.539572],[-104.944258,40.522768],[-104.944206,40.537292],[-104.996246,40.539572]]]},"properties":{"id":"80547","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.732623,37.509232],[-103.578893,37.643652],[-103.772877,37.821394],[-104.061132,37.734704],[-104.143179,37.75833],[-104.465629,37.367182],[-104.133413,37.189907],[-103.732623,37.509232]]]},"properties":{"id":"81059","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.172336,38.690058],[-103.644735,38.749639],[-103.706672,38.680723],[-103.578397,38.517409],[-103.504666,38.516409],[-103.377631,38.52343],[-103.172943,38.61245],[-103.172336,38.690058]]]},"properties":{"id":"80823","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.651814,40.865445],[-102.653463,41.002332],[-102.84644,41.002177],[-102.924602,40.913134],[-102.940633,40.769948],[-102.651814,40.865445]]]},"properties":{"id":"80726","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.05721,38.79955],[-109.059962,38.499987],[-108.601024,38.499988],[-108.731351,38.844074],[-109.05721,38.79955]]]},"properties":{"id":"81522","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.470647,39.126074],[-108.570885,39.091848],[-108.515188,39.091801],[-108.48156,39.104958],[-108.470647,39.126074]]]},"properties":{"id":"81506","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.39864,41.002969],[-107.316591,40.880045],[-107.147049,40.94939],[-107.132458,41.003043],[-107.39864,41.002969]]]},"properties":{"id":"81653","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.575165,38.48715],[-108.4318,38.242443],[-108.243774,38.241375],[-108.18218,38.32373],[-108.575165,38.48715]]]},"properties":{"id":"81424","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.486665,38.153215],[-108.713093,38.016489],[-108.331642,37.893884],[-108.199082,37.824187],[-108.239396,38.152499],[-108.243774,38.241375],[-108.4318,38.242443],[-108.486665,38.153215]]]},"properties":{"id":"81423","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.880654,38.154048],[-109.060107,38.327303],[-109.041667,38.153021],[-108.880654,38.154048]]]},"properties":{"id":"81411","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.268425,39.018812],[-105.519079,38.916596],[-105.392205,38.697526],[-105.242556,38.696973],[-105.234426,38.828159],[-105.268425,39.018812]]]},"properties":{"id":"80816","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.48411,38.114321],[-102.539999,37.643734],[-102.219431,37.644161],[-102.48411,38.114321]]]},"properties":{"id":"81041","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.646735,38.26961],[-104.794023,38.051776],[-104.96298,37.958142],[-104.878928,37.931531],[-104.833993,37.971031],[-104.801877,37.951647],[-104.589587,37.926699],[-104.6179,38.149351],[-104.607845,38.254934],[-104.646735,38.26961]]]},"properties":{"id":"81004","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.878928,37.931531],[-104.801877,37.951647],[-104.833993,37.971031],[-104.878928,37.931531]]]},"properties":{"id":"81019","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.652112,40.445231],[-106.786175,40.903492],[-107.082883,40.699756],[-107.083588,40.363414],[-106.632485,40.266977],[-106.652112,40.445231]],[[-106.818418,40.449973],[-106.817393,40.448946],[-106.818927,40.447564],[-106.818878,40.44935],[-106.818418,40.449973]],[[-106.831186,40.486133],[-106.829395,40.488006],[-106.827086,40.486777],[-106.829044,40.484827],[-106.831186,40.486133]]]},"properties":{"id":"80487","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.855458,40.438307],[-102.935231,39.946045],[-102.80075,39.801505],[-102.613971,39.831104],[-102.452313,40.318163],[-102.473797,40.439166],[-102.798842,40.438503],[-102.855458,40.438307]]]},"properties":{"id":"80759","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.715165,40.870069],[-104.562712,40.812272],[-104.50451,40.815314],[-104.085613,40.698818],[-103.914845,41.001701],[-104.773018,40.999833],[-104.715165,40.870069]]]},"properties":{"id":"80729","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.296748,40.261839],[-104.414633,40.000637],[-104.321059,40.000827],[-104.207951,40.000832],[-104.150358,40.232148],[-104.296748,40.261839]]]},"properties":{"id":"80652","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.573813,40.859564],[-103.803373,40.523874],[-103.658245,40.522307],[-103.601067,40.523123],[-103.57369,40.833972],[-103.573813,40.859564]]]},"properties":{"id":"80754","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.939263,40.109557],[-104.923823,40.109503],[-104.923747,40.116687],[-104.939263,40.109557]]]},"properties":{"id":"80520","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.613971,39.831104],[-102.80075,39.801505],[-102.802932,39.567841],[-102.629501,39.568792],[-102.473726,39.760916],[-102.613971,39.831104]]]},"properties":{"id":"80822","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.902238,39.885241],[-104.948161,39.830746],[-104.848778,39.885209],[-104.827846,39.907144],[-104.867423,39.922782],[-104.904068,39.888693],[-104.902238,39.885241]]]},"properties":{"id":"80640","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.921904,39.914034],[-104.978188,39.942944],[-104.987976,39.943074],[-104.98987,39.914064],[-104.921904,39.914034]]]},"properties":{"id":"80241","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.983289,39.809154],[-104.998334,39.782399],[-104.998206,39.766894],[-104.993597,39.75933],[-104.940487,39.772886],[-104.896609,39.778257],[-104.884746,39.798322],[-104.884585,39.812975],[-104.903553,39.812959],[-104.954771,39.812881],[-104.983289,39.809154]]]},"properties":{"id":"80216","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.919725,39.843574],[-104.916573,39.841732],[-104.916492,39.845379],[-104.919725,39.843574]]]},"properties":{"id":"80024","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.52669,39.88379],[-104.613231,39.638288],[-104.60084,39.565846],[-104.457795,39.491458],[-104.333874,39.50048],[-104.296062,39.564657],[-104.321059,40.000827],[-104.414633,40.000637],[-104.489639,39.955685],[-104.52669,39.88379]]]},"properties":{"id":"80102","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.67994,39.638439],[-104.613231,39.638288],[-104.52669,39.88379],[-104.600323,39.88459],[-104.659123,39.814033],[-104.659953,39.740477],[-104.67994,39.638439]]]},"properties":{"id":"80137","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.81,39.714947],[-104.753327,39.682188],[-104.734892,39.682188],[-104.718894,39.74027],[-104.734601,39.769181],[-104.790568,39.773323],[-104.846932,39.767966],[-104.846824,39.754888],[-104.832467,39.751455],[-104.853681,39.717965],[-104.81,39.714947]]]},"properties":{"id":"80011","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.846932,39.754889],[-104.832467,39.751455],[-104.846824,39.754888],[-104.846932,39.754889]]]},"properties":{"id":"80045","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.884746,39.798322],[-104.854038,39.754985],[-104.846932,39.754889],[-104.846932,39.767966],[-104.790568,39.773323],[-104.790895,39.798251],[-104.884585,39.812975],[-104.884746,39.798322]]]},"properties":{"id":"80239","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.422395,37.000005],[-107.365613,37.136941],[-107.48209,37.166724],[-107.438767,37.025208],[-107.425016,37.000006],[-107.422395,37.000005]]]},"properties":{"id":"81121","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.648303,37.216108],[-106.90638,37.000122],[-106.476229,36.993758],[-106.648303,37.216108]]]},"properties":{"id":"81128","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.668224,38.267299],[-102.760117,38.114517],[-102.842546,38.066951],[-102.874804,37.643885],[-102.590363,37.643601],[-102.539999,37.643734],[-102.48411,38.114321],[-102.47975,38.26793],[-102.668224,38.267299]]]},"properties":{"id":"81052","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.130804,40.143343],[-105.093148,40.163924],[-105.131104,40.203252],[-105.130804,40.143343]]]},"properties":{"id":"80501","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.0601,39.870945],[-105.137579,39.939688],[-105.147342,39.913886],[-105.165559,39.891155],[-105.083207,39.856415],[-105.074117,39.856547],[-105.0601,39.870945]]]},"properties":{"id":"80021","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.42229,39.751309],[-105.623875,39.567036],[-105.444922,39.565988],[-105.398949,39.566056],[-105.269725,39.561802],[-105.255741,39.615511],[-105.27764,39.635105],[-105.276194,39.651656],[-105.275694,39.666774],[-105.323706,39.73648],[-105.397949,39.747087],[-105.42229,39.751309]]]},"properties":{"id":"80439","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.857005,38.163733],[-103.801442,38.112525],[-103.74764,38.113077],[-103.578397,38.517409],[-103.706672,38.680723],[-103.99902,38.522727],[-103.8526,38.195536],[-103.857341,38.192176],[-103.857005,38.163733]]]},"properties":{"id":"81063","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.924406,38.828069],[-107.909224,38.768405],[-107.838731,38.737316],[-107.578853,38.784139],[-107.721624,39.065255],[-107.776018,39.047783],[-107.914244,38.835846],[-107.924406,38.828069]]]},"properties":{"id":"81419","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.978177,39.7475],[-104.964757,39.746943],[-104.94189,39.743764],[-104.940635,39.74739],[-104.940487,39.772886],[-104.993597,39.75933],[-104.989101,39.750253],[-104.988227,39.749586],[-104.986105,39.747526],[-104.978177,39.7475]]]},"properties":{"id":"80205","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.051782,39.667777],[-105.052625,39.682286],[-105.129079,39.682103],[-105.122328,39.663895],[-105.119035,39.652549],[-105.05321,39.653346],[-105.051782,39.667777]]]},"properties":{"id":"80227","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.127978,39.696468],[-105.129079,39.682103],[-105.052625,39.682286],[-105.053225,39.696769],[-105.127978,39.696468]]]},"properties":{"id":"80232","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.768882,39.416831],[-104.872368,39.507753],[-104.911426,39.493114],[-104.927015,39.492974],[-104.901525,39.425338],[-104.868189,39.412624],[-104.789105,39.396396],[-104.768882,39.416831]]]},"properties":{"id":"80108","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.90443,39.563844],[-104.911426,39.493114],[-104.872368,39.507753],[-104.8685,39.536252],[-104.904415,39.565982],[-104.90443,39.563844]]]},"properties":{"id":"80124","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.422316,39.768357],[-107.113947,39.508613],[-106.97633,39.484665],[-106.689182,39.443839],[-106.847651,39.847056],[-106.860894,39.87489],[-107.037248,40.053607],[-107.037363,40.091538],[-107.476532,39.828267],[-107.422316,39.768357]]]},"properties":{"id":"81637","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.178806,39.273543],[-104.051589,39.12837],[-103.975585,39.245339],[-104.106526,39.379978],[-104.178806,39.273543]]]},"properties":{"id":"80835","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.733715,38.913271],[-104.719493,38.910927],[-104.68309,38.911787],[-104.680473,38.911801],[-104.678141,38.940835],[-104.711278,38.940415],[-104.719884,38.940198],[-104.756821,38.938031],[-104.733715,38.913271]]]},"properties":{"id":"80923","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.747426,38.739039],[-104.746432,38.651954],[-104.746217,38.643026],[-104.717994,38.51956],[-104.621919,38.606456],[-104.62764,38.693993],[-104.681959,38.723079],[-104.747426,38.739039]]]},"properties":{"id":"80817","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.109724,38.647531],[-105.239925,38.677002],[-105.242556,38.696973],[-105.392205,38.697526],[-105.82648,38.694306],[-105.859506,38.69399],[-105.81242,38.628654],[-105.368889,38.259399],[-105.21631,38.258621],[-105.20107,38.35221],[-105.199297,38.37437],[-105.11374,38.43718],[-105.109724,38.647531]]]},"properties":{"id":"81212","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.870422,38.744354],[-106.679187,38.599464],[-106.622325,38.658176],[-106.48423,38.689114],[-106.451867,38.698343],[-106.409307,38.827888],[-106.599214,38.997994],[-106.799689,38.97965],[-106.870422,38.744354]]]},"properties":{"id":"81210","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.482188,37.66769],[-106.763129,37.489855],[-106.694149,37.807195],[-106.693751,37.848327],[-106.778537,37.894862],[-107.000602,37.956041],[-107.517505,37.837517],[-107.482188,37.66769]]]},"properties":{"id":"81130","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.195067,37.619625],[-105.165221,37.290702],[-105.154176,37.293129],[-105.02898,37.339461],[-104.925753,37.397048],[-105.086253,37.678989],[-105.195067,37.619625]]]},"properties":{"id":"81055","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.011742,40.369488],[-106.22114,40.50938],[-106.24857,40.48419],[-106.118213,40.345037],[-106.011742,40.369488]]]},"properties":{"id":"80473","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.165142,39.82701],[-105.081447,39.827244],[-105.083207,39.856415],[-105.165559,39.891155],[-105.165142,39.82701]]]},"properties":{"id":"80005","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.165175,39.801646],[-105.081437,39.802777],[-105.081447,39.827244],[-105.165142,39.82701],[-105.165174,39.812457],[-105.165175,39.801646]]]},"properties":{"id":"80004","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.19276,39.705529],[-105.235273,39.661757],[-105.241874,39.654975],[-105.249777,39.66303],[-105.275694,39.666774],[-105.276194,39.651656],[-105.276821,39.642579],[-105.255741,39.615511],[-105.269725,39.561802],[-105.225046,39.516637],[-105.128614,39.638665],[-105.119035,39.652549],[-105.122328,39.663895],[-105.19276,39.705529]]]},"properties":{"id":"80465","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.208278,38.32245],[-103.377631,38.52343],[-103.504666,38.516409],[-103.501764,38.265016],[-103.355573,38.265533],[-103.207018,38.265833],[-103.208278,38.32245]]]},"properties":{"id":"81021","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.814345,39.567952],[-102.798787,39.03971],[-102.70664,39.038789],[-102.59156,39.569108],[-102.629501,39.568792],[-102.802932,39.567841],[-102.814345,39.567952]]]},"properties":{"id":"80861","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.907207,40.406802],[-104.879883,40.406803],[-104.858362,40.439972],[-104.832311,40.501811],[-104.851185,40.520963],[-104.851093,40.528032],[-104.848384,40.53007],[-104.848455,40.523373],[-104.869934,40.54542],[-104.944145,40.550705],[-104.944206,40.537292],[-104.944258,40.522768],[-104.982222,40.464798],[-104.944568,40.406964],[-104.907207,40.406802]]]},"properties":{"id":"80550","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.139487,40.620966],[-105.17055,40.841545],[-105.172265,40.841871],[-105.236225,40.719513],[-105.168261,40.629737],[-105.139487,40.620966]]]},"properties":{"id":"80535","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.943371,40.998213],[-104.905815,40.754691],[-104.715165,40.870069],[-104.773018,40.999833],[-104.943371,40.998213]]]},"properties":{"id":"80612","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.573582,40.341856],[-105.57358,40.336865],[-105.56829,40.340333],[-105.573582,40.341856]]]},"properties":{"id":"80511","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.732623,37.509232],[-104.133413,37.189907],[-104.195433,37.136789],[-104.056525,36.995894],[-103.425674,36.99938],[-103.732623,37.509232]]]},"properties":{"id":"81027","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.056525,36.995894],[-104.195433,37.136789],[-104.433987,36.993738],[-104.056525,36.995894]]]},"properties":{"id":"81081","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.379224,39.243298],[-103.378064,39.566201],[-103.463451,39.594212],[-103.613063,39.566174],[-103.584198,39.203399],[-103.379224,39.243298]]]},"properties":{"id":"80818","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.651448,40.792959],[-102.798842,40.438503],[-102.473797,40.439166],[-102.455967,40.607639],[-102.475131,40.607714],[-102.475149,40.611632],[-102.465764,40.612148],[-102.45599,40.609951],[-102.419609,40.749153],[-102.420115,40.778193],[-102.651448,40.792959]]]},"properties":{"id":"80731","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.515107,39.047822],[-108.515188,39.091801],[-108.570885,39.091848],[-108.594073,39.081491],[-108.569134,39.055269],[-108.515107,39.047822]]]},"properties":{"id":"81501","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.48156,39.104958],[-108.515188,39.091801],[-108.515107,39.047822],[-108.459549,39.055145],[-108.48156,39.104958]]]},"properties":{"id":"81504","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.594073,39.081491],[-108.660188,39.108787],[-108.743212,39.054825],[-108.695825,38.898504],[-108.502997,38.999981],[-108.569134,39.055269],[-108.594073,39.081491]]]},"properties":{"id":"81507","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.059795,39.189748],[-107.874709,39.072426],[-107.776018,39.047783],[-107.721624,39.065255],[-107.60903,39.159621],[-107.394486,39.256299],[-107.430949,39.366178],[-107.552343,39.366092],[-107.753513,39.366245],[-107.884138,39.366195],[-108.128034,39.366234],[-108.158868,39.267976],[-108.059795,39.189748]]]},"properties":{"id":"81624","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.678373,37.403596],[-106.523589,37.402237],[-106.694149,37.807195],[-106.763129,37.489855],[-106.678373,37.403596]]]},"properties":{"id":"81154","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.050824,40.222662],[-108.488588,40.222336],[-108.535614,40.369591],[-109.050928,40.436351],[-109.050824,40.222662]]]},"properties":{"id":"81610","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.956667,38.5655],[-108.18218,38.32373],[-108.243774,38.241375],[-108.239396,38.152499],[-107.965789,38.152328],[-107.622235,38.277838],[-107.63504,38.301896],[-107.956667,38.5655]]]},"properties":{"id":"81403","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.593453,39.43707],[-105.66211,39.566268],[-105.721601,39.565269],[-105.829662,39.564865],[-105.864681,39.515065],[-105.898888,39.472295],[-105.397881,39.203277],[-105.398049,39.314293],[-105.550192,39.419453],[-105.593453,39.43707]]]},"properties":{"id":"80448","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.530393,38.921289],[-105.82648,38.694306],[-105.392205,38.697526],[-105.519079,38.916596],[-105.530393,38.921289]]]},"properties":{"id":"80820","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.45599,40.609951],[-102.465764,40.612148],[-102.475149,40.611632],[-102.475131,40.607714],[-102.455967,40.607639],[-102.45599,40.609951]]]},"properties":{"id":"80746","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.48411,38.114321],[-102.219431,37.644161],[-102.20117,37.644201],[-102.041585,37.644282],[-102.044475,38.268749],[-102.47975,38.26793],[-102.48411,38.114321]],[[-102.210596,38.129708],[-102.228903,38.12199],[-102.228959,38.14475],[-102.210596,38.129708]]]},"properties":{"id":"81047","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.210596,38.129708],[-102.228959,38.14475],[-102.228903,38.12199],[-102.210596,38.129708]]]},"properties":{"id":"81043","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.050078,38.075961],[-104.96298,37.958142],[-104.794023,38.051776],[-105.048443,38.155479],[-105.050078,38.075961]]]},"properties":{"id":"81023","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.237956,37.867534],[-106.693751,37.848327],[-106.694149,37.807195],[-106.523589,37.402237],[-106.440839,37.399888],[-106.256945,37.400974],[-106.222424,37.675881],[-106.237956,37.867534]]]},"properties":{"id":"81132","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.64248,38.07993],[-105.841892,37.79359],[-105.672361,37.751606],[-105.457255,37.751464],[-105.473204,37.89597],[-105.64248,38.07993]]]},"properties":{"id":"81131","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.965789,38.152328],[-108.239396,38.152499],[-108.199082,37.824187],[-107.992636,37.856491],[-107.8833,38.007371],[-107.965789,38.152328]]]},"properties":{"id":"81430","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.909159,40.187934],[-104.923747,40.116687],[-104.923823,40.109503],[-104.924192,40.08773],[-104.942471,40.048222],[-104.694278,40.078335],[-104.697032,40.189375],[-104.909159,40.187934]]]},"properties":{"id":"80621","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.939263,40.109557],[-104.941318,40.087859],[-104.924192,40.08773],[-104.923823,40.109503],[-104.939263,40.109557]]]},"properties":{"id":"80530","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.979148,40.233484],[-104.909159,40.187934],[-104.697032,40.189375],[-104.659528,40.218083],[-104.772586,40.285604],[-104.791907,40.279632],[-104.791868,40.2898],[-104.773047,40.289908],[-104.784663,40.333569],[-104.867508,40.275071],[-104.980074,40.269667],[-104.979148,40.233484]]]},"properties":{"id":"80651","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.50788,40.435715],[-104.582926,40.348437],[-104.30071,40.29374],[-104.300026,40.436823],[-104.413354,40.479296],[-104.50788,40.435715]]]},"properties":{"id":"80644","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.464025,39.7602],[-102.400319,39.628696],[-102.050102,39.654147],[-102.050956,39.751023],[-102.253473,39.834428],[-102.465753,39.860793],[-102.464025,39.7602]]]},"properties":{"id":"80735","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.081437,39.802777],[-105.043848,39.796975],[-105.034719,39.798852],[-105.034505,39.820168],[-105.047929,39.841796],[-105.074117,39.856547],[-105.083207,39.856415],[-105.081447,39.827244],[-105.081437,39.802777]]]},"properties":{"id":"80003","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.11677,40.322238],[-104.150358,40.232148],[-104.207951,40.000832],[-104.188688,39.971316],[-103.946535,40.001172],[-103.979964,40.264005],[-104.11677,40.322238]]]},"properties":{"id":"80654","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.659123,39.814033],[-104.734601,39.769181],[-104.718894,39.74027],[-104.659953,39.740477],[-104.659123,39.814033]]]},"properties":{"id":"80019","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.065106,39.788813],[-105.062645,39.762093],[-105.053212,39.74398],[-105.036956,39.747578],[-105.034574,39.783332],[-105.034719,39.798852],[-105.043848,39.796975],[-105.065106,39.788813]]]},"properties":{"id":"80212","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.988813,39.667679],[-105.015696,39.678684],[-105.015722,39.666947],[-105.03477,39.632409],[-105.016041,39.627966],[-104.992669,39.621048],[-104.988813,39.667679]]]},"properties":{"id":"80110","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.809903,39.682338],[-104.753327,39.682188],[-104.81,39.714947],[-104.809903,39.682338]]]},"properties":{"id":"80017","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.988813,39.667679],[-104.992669,39.621048],[-104.988027,39.616922],[-104.923499,39.638619],[-104.921193,39.644819],[-104.931703,39.653044],[-104.940683,39.653013],[-104.987572,39.667682],[-104.988813,39.667679]]]},"properties":{"id":"80113","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.051782,39.667777],[-105.05321,39.653346],[-105.053333,39.638893],[-105.03477,39.632409],[-105.015722,39.666947],[-105.051782,39.667777]]]},"properties":{"id":"80236","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.506943,37.227631],[-102.85065,37.148744],[-102.886687,36.999511],[-102.041857,37.141522],[-102.506943,37.227631]]]},"properties":{"id":"81029","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.834637,38.18647],[-103.042117,38.099583],[-102.842546,38.066951],[-102.760117,38.114517],[-102.834637,38.18647]]]},"properties":{"id":"81057","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.400687,39.935072],[-105.359984,39.950069],[-105.521385,40.032436],[-105.652793,40.023772],[-105.689127,40.015853],[-105.675798,39.932445],[-105.442389,39.941401],[-105.400687,39.935072]]]},"properties":{"id":"80466","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.623872,40.157185],[-105.465394,40.259357],[-105.653321,40.260457],[-105.666399,40.157335],[-105.623872,40.157185]]]},"properties":{"id":"80510","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.661296,39.752799],[-105.903857,39.728815],[-105.924618,39.698972],[-105.92516,39.679319],[-105.776124,39.607753],[-105.661296,39.752799]]]},"properties":{"id":"80476","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.623875,39.567036],[-105.42229,39.751309],[-105.569955,39.801022],[-105.690348,39.851996],[-105.724125,39.819018],[-105.680788,39.783308],[-105.660541,39.752159],[-105.721601,39.565269],[-105.66211,39.566268],[-105.623875,39.567036]]]},"properties":{"id":"80452","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.5732,36.995923],[-105.57436,37.054783],[-105.68272,37.043749],[-105.664735,36.995874],[-105.5732,36.995923]]]},"properties":{"id":"81138","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.578397,38.517409],[-103.74764,38.113077],[-103.540176,38.172663],[-103.501764,38.265016],[-103.504666,38.516409],[-103.578397,38.517409]]]},"properties":{"id":"81076","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.124419,38.066306],[-105.150945,38.25783],[-105.21631,38.258621],[-105.368889,38.259399],[-105.602889,38.264874],[-105.612555,38.264871],[-105.796897,38.265047],[-105.716053,38.185273],[-105.64248,38.07993],[-105.473204,37.89597],[-105.166304,38.017104],[-105.124419,38.066306]]]},"properties":{"id":"81252","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.987572,39.667682],[-104.988675,39.692983],[-104.987501,39.716575],[-104.987485,39.722535],[-105.018663,39.722037],[-105.015696,39.678684],[-104.988813,39.667679],[-104.987572,39.667682]]]},"properties":{"id":"80223","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.898767,39.747441],[-104.884664,39.747467],[-104.854038,39.754985],[-104.884746,39.798322],[-104.896609,39.778257],[-104.898767,39.747441]]]},"properties":{"id":"80238","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.009489,39.753926],[-104.998206,39.766894],[-104.998334,39.782399],[-105.034574,39.783332],[-105.036956,39.747578],[-105.009489,39.753926]]]},"properties":{"id":"80211","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.127978,39.696468],[-105.053225,39.696769],[-105.052593,39.722335],[-105.053253,39.7258],[-105.090873,39.725457],[-105.118704,39.725359],[-105.130575,39.725282],[-105.127978,39.696468]]]},"properties":{"id":"80226","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.90443,39.563844],[-104.941999,39.503459],[-104.934385,39.498293],[-104.927015,39.492974],[-104.911426,39.493114],[-104.90443,39.563844]]]},"properties":{"id":"80130","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.09351,39.488006],[-105.133132,39.466312],[-105.123517,39.434245],[-105.014445,39.479074],[-105.006923,39.487558],[-105.000539,39.476629],[-104.934385,39.498293],[-104.941999,39.503459],[-104.996627,39.513026],[-105.033019,39.564921],[-105.04085,39.566145],[-105.09351,39.488006]]]},"properties":{"id":"80125","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.900716,39.129831],[-104.766571,39.129674],[-104.662896,39.129527],[-104.701964,39.189768],[-104.77434,39.213821],[-104.891475,39.297015],[-104.91814,39.296831],[-105.029549,39.129831],[-104.900716,39.129831]]]},"properties":{"id":"80118","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.234848,39.273603],[-104.090303,38.984408],[-103.756865,38.852111],[-103.721292,38.98263],[-104.051589,39.12837],[-104.178806,39.273543],[-104.234848,39.273603]]]},"properties":{"id":"80832","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.348226,39.128857],[-104.461293,38.88178],[-104.460124,38.766325],[-104.303763,38.775797],[-104.127586,38.940995],[-104.090303,38.984408],[-104.234848,39.273603],[-104.384903,39.241441],[-104.348226,39.128857]]]},"properties":{"id":"80808","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.733715,38.913271],[-104.781988,38.889174],[-104.795061,38.873553],[-104.747849,38.866249],[-104.721075,38.873641],[-104.719493,38.910927],[-104.733715,38.913271]]]},"properties":{"id":"80917","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.828178,38.902233],[-104.874767,38.888518],[-104.83656,38.850115],[-104.828707,38.85105],[-104.809527,38.855067],[-104.795061,38.873553],[-104.781988,38.889174],[-104.828178,38.902233]]]},"properties":{"id":"80907","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.828707,38.85105],[-104.812796,38.81263],[-104.795787,38.809433],[-104.79773,38.832206],[-104.809527,38.855067],[-104.828707,38.85105]]]},"properties":{"id":"80903","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-104.902201,38.867816],[-104.911914,38.893374],[-104.908516,38.867862],[-104.902201,38.867816]]],[[[-104.888372,38.867698],[-104.886575,38.824775],[-104.859168,38.828327],[-104.83656,38.850115],[-104.874767,38.888518],[-104.8756,38.887815],[-104.888372,38.867698]]]]},"properties":{"id":"80904","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.747426,38.739039],[-104.681959,38.723079],[-104.720601,38.781254],[-104.757502,38.781714],[-104.77095,38.777471],[-104.761423,38.752845],[-104.747426,38.739039]]]},"properties":{"id":"80911","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.303763,38.775797],[-104.460124,38.766325],[-104.553638,38.838583],[-104.608463,38.781047],[-104.62764,38.693993],[-104.621919,38.606456],[-104.567087,38.520001],[-104.275471,38.521133],[-104.303763,38.775797]]]},"properties":{"id":"80928","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.728562,38.358815],[-105.826329,38.51036],[-105.969328,38.409543],[-105.894365,38.346618],[-105.728562,38.358815]]]},"properties":{"id":"81233","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.728562,38.358815],[-105.894365,38.346618],[-105.849631,38.29312],[-105.728562,38.358815]]]},"properties":{"id":"81222","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.159444,39.695099],[-107.884138,39.366195],[-107.753513,39.366245],[-107.644411,39.627111],[-107.821963,39.824949],[-108.33292,40.04623],[-108.554707,39.687779],[-108.159444,39.695099]]]},"properties":{"id":"81650","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.422316,39.768357],[-107.426746,39.366168],[-107.113947,39.508613],[-107.422316,39.768357]]]},"properties":{"id":"81601","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.622325,38.658176],[-106.562003,38.625439],[-106.469687,38.651538],[-106.48423,38.689114],[-106.622325,38.658176]]],[[[-107.000602,37.956041],[-106.778537,37.894862],[-106.504994,38.314891],[-106.500262,38.572884],[-106.509856,38.577881],[-106.686612,38.578658],[-106.679187,38.599464],[-106.870422,38.744354],[-107.093009,38.837001],[-107.500625,38.687259],[-107.500614,38.445584],[-107.302764,38.359438],[-107.001119,38.147072],[-107.000602,37.956041]],[[-106.917152,38.550995],[-106.920825,38.547525],[-106.922133,38.551018],[-106.917152,38.550995]]]]},"properties":{"id":"81230","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.541036,38.580516],[-106.509856,38.577881],[-106.500262,38.572884],[-106.469687,38.651538],[-106.562003,38.625439],[-106.541036,38.580516]]]},"properties":{"id":"81241","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.824338,38.978975],[-107.064089,38.988212],[-107.10347,38.991748],[-107.093009,38.837001],[-106.870422,38.744354],[-106.799689,38.97965],[-106.824338,38.978975]]]},"properties":{"id":"81224","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.504994,38.314891],[-106.246641,38.350973],[-106.176197,38.422386],[-106.358188,38.592713],[-106.451867,38.698343],[-106.48423,38.689114],[-106.469687,38.651538],[-106.500262,38.572884],[-106.504994,38.314891]]]},"properties":{"id":"81248","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.128614,39.638665],[-105.225046,39.516637],[-105.133132,39.466312],[-105.09351,39.488006],[-105.110153,39.59766],[-105.109846,39.638699],[-105.128614,39.638665]]]},"properties":{"id":"80127","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.444922,39.565988],[-105.398049,39.314293],[-105.397881,39.203277],[-105.261053,39.241856],[-105.236245,39.435552],[-105.398949,39.566056],[-105.444922,39.565988]]]},"properties":{"id":"80470","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.203172,39.729756],[-105.202139,39.726137],[-105.198469,39.726853],[-105.200354,39.729551],[-105.203172,39.729756]]]},"properties":{"id":"80419","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.585863,38.613317],[-102.47975,38.26793],[-102.044475,38.268749],[-102.045082,38.615172],[-102.327479,38.615179],[-102.585863,38.613317]]]},"properties":{"id":"81071","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.046571,39.047038],[-102.050102,39.654147],[-102.400319,39.628696],[-102.430431,39.570816],[-102.425651,39.570664],[-102.409929,39.044151],[-102.354002,39.045674],[-102.046571,39.047038]]]},"properties":{"id":"80807","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.59156,39.569108],[-102.70664,39.038789],[-102.650529,39.039645],[-102.520751,39.041356],[-102.425651,39.570664],[-102.430431,39.570816],[-102.59156,39.569108]]]},"properties":{"id":"80836","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.214536,40.586783],[-105.161917,40.589365],[-105.168261,40.629737],[-105.236225,40.719513],[-105.495208,40.734481],[-105.793154,40.810317],[-105.892203,40.520747],[-105.854926,40.486252],[-105.822463,40.476829],[-105.516989,40.51988],[-105.449929,40.528044],[-105.364511,40.506644],[-105.214536,40.586783]]]},"properties":{"id":"80512","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.980074,40.269667],[-104.980417,40.34264],[-105.239238,40.309896],[-105.236778,40.285875],[-105.131059,40.261079],[-105.036469,40.243927],[-104.980074,40.269667]]]},"properties":{"id":"80513","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.431351,40.456379],[-105.516989,40.51988],[-105.822463,40.476829],[-105.653321,40.260457],[-105.465394,40.259357],[-105.405166,40.366712],[-105.415165,40.367723],[-105.431351,40.456379]],[[-105.573582,40.341856],[-105.56829,40.340333],[-105.57358,40.336865],[-105.573582,40.341856]]]},"properties":{"id":"80517","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.732623,37.509232],[-103.425674,36.99938],[-103.086106,37.000264],[-103.07514,37.469366],[-103.075284,37.520365],[-103.404339,37.643576],[-103.578893,37.643652],[-103.732623,37.509232]]]},"properties":{"id":"81049","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.314969,40.56419],[-103.226558,40.436087],[-103.159934,40.43669],[-102.942211,40.437908],[-103.055353,40.708841],[-103.171489,40.760601],[-103.518573,40.819556],[-103.314969,40.56419]]]},"properties":{"id":"80751","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.293429,39.046873],[-108.459549,39.055145],[-108.515107,39.047822],[-108.569134,39.055269],[-108.502997,38.999981],[-108.293429,39.046873]]]},"properties":{"id":"81503","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.060256,38.492964],[-108.759275,38.153374],[-108.486665,38.153215],[-108.4318,38.242443],[-108.575165,38.48715],[-108.583682,38.499988],[-108.601024,38.499988],[-109.059962,38.499987],[-109.060256,38.492964]]]},"properties":{"id":"81422","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.486665,38.153215],[-108.759275,38.153374],[-108.772835,38.153296],[-108.713093,38.016489],[-108.486665,38.153215]]]},"properties":{"id":"81431","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.658245,40.522307],[-103.668738,40.292489],[-103.595395,40.321563],[-103.464665,40.408164],[-103.601067,40.523123],[-103.658245,40.522307]]]},"properties":{"id":"80750","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.527752,38.093232],[-103.517201,38.103388],[-103.527437,38.103664],[-103.527752,38.093232]]]},"properties":{"id":"81030","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.179683,39.195773],[-105.821194,39.091989],[-105.879801,39.352506],[-105.917072,39.457969],[-106.062616,39.36182],[-106.186288,39.280666],[-106.179683,39.195773]]]},"properties":{"id":"80440","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.25728,39.129552],[-105.366974,39.129577],[-105.654314,39.220823],[-105.530393,38.921289],[-105.519079,38.916596],[-105.268425,39.018812],[-105.258377,39.057702],[-105.25728,39.129552]]]},"properties":{"id":"80827","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.593453,39.43707],[-105.550192,39.419453],[-105.5287,39.464],[-105.584464,39.484392],[-105.593453,39.43707]]]},"properties":{"id":"80475","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.419609,40.749153],[-102.45599,40.609951],[-102.455967,40.607639],[-102.473797,40.439166],[-102.452313,40.318163],[-102.452491,40.265312],[-102.051465,40.440008],[-102.051486,40.64308],[-102.267489,40.748947],[-102.306371,40.749126],[-102.419609,40.749153]]]},"properties":{"id":"80734","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.969328,38.409543],[-106.176197,38.422386],[-106.246641,38.350973],[-105.954533,38.209714],[-105.716053,38.185273],[-105.796897,38.265047],[-105.849631,38.29312],[-105.894365,38.346618],[-105.969328,38.409543]]]},"properties":{"id":"81155","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.504994,38.314891],[-106.778537,37.894862],[-106.693751,37.848327],[-106.237956,37.867534],[-105.904515,37.963064],[-105.954533,38.209714],[-106.246641,38.350973],[-106.504994,38.314891]]]},"properties":{"id":"81149","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.839021,37.778527],[-107.738283,37.905432],[-107.736059,37.93228],[-107.8833,38.007371],[-107.992636,37.856491],[-107.948537,37.834094],[-107.938035,37.901782],[-107.883641,37.864831],[-107.943005,37.826694],[-107.908889,37.810941],[-107.860845,37.775517],[-107.839021,37.778527]]]},"properties":{"id":"81435","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.216037,39.531679],[-106.226829,39.428787],[-106.206732,39.379636],[-106.135529,39.379546],[-106.055924,39.580537],[-106.063555,39.620225],[-106.177604,39.609056],[-106.216037,39.531679]]]},"properties":{"id":"80443","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.735026,40.384677],[-104.677619,40.373335],[-104.582926,40.348437],[-104.50788,40.435715],[-104.564587,40.478741],[-104.832311,40.501811],[-104.858362,40.439972],[-104.735026,40.384677]]]},"properties":{"id":"80631","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.888483,40.617107],[-104.658392,40.682313],[-104.886536,40.676167],[-104.888483,40.617107]]]},"properties":{"id":"80650","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.98571,39.849077],[-104.983289,39.809154],[-104.954771,39.812881],[-104.948161,39.830746],[-104.902238,39.885241],[-104.98765,39.885341],[-104.98571,39.849077]]]},"properties":{"id":"80229","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.978188,39.942944],[-104.921904,39.914034],[-104.904068,39.888693],[-104.867423,39.922782],[-104.843211,40.000398],[-104.961408,40.000337],[-104.97571,40.000322],[-104.978188,39.942944]]]},"properties":{"id":"80602","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.706263,39.828548],[-103.999129,39.869396],[-104.288471,39.56471],[-104.296062,39.564657],[-104.333874,39.50048],[-104.274117,39.433042],[-104.014407,39.565999],[-103.707072,39.566295],[-103.706263,39.828548]]]},"properties":{"id":"80105","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.321059,40.000827],[-104.296062,39.564657],[-104.288471,39.56471],[-104.188688,39.971316],[-104.207951,40.000832],[-104.321059,40.000827]]]},"properties":{"id":"80136","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.974691,37.632272],[-105.485516,37.577899],[-105.457255,37.751464],[-105.672361,37.751606],[-106.001685,37.69791],[-105.974691,37.632272]]]},"properties":{"id":"81146","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.001685,37.69791],[-105.672361,37.751606],[-105.841892,37.79359],[-105.889528,37.828458],[-106.001581,37.705337],[-106.001685,37.69791]]]},"properties":{"id":"81136","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.821003,39.594989],[-104.922888,39.595059],[-104.922744,39.566066],[-104.904415,39.565982],[-104.8685,39.536252],[-104.791368,39.566087],[-104.821003,39.594989]]]},"properties":{"id":"80112","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.785308,39.566088],[-104.608424,39.565922],[-104.60084,39.565846],[-104.613231,39.638288],[-104.67994,39.638439],[-104.716336,39.638472],[-104.828532,39.610989],[-104.857203,39.653246],[-104.877728,39.643248],[-104.821003,39.594989],[-104.791368,39.566087],[-104.785308,39.566088]]]},"properties":{"id":"80016","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.857203,39.653246],[-104.828532,39.610989],[-104.716336,39.638472],[-104.735015,39.638452],[-104.808027,39.638492],[-104.857203,39.653246]]]},"properties":{"id":"80015","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.718894,39.74027],[-104.734892,39.682188],[-104.735015,39.638452],[-104.716336,39.638472],[-104.67994,39.638439],[-104.659953,39.740477],[-104.718894,39.74027]]]},"properties":{"id":"80018","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.280042,40.063983],[-105.29789,40.063702],[-105.260367,40.025159],[-105.280042,40.063983]]]},"properties":{"id":"80304","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.093383,40.061142],[-105.131268,40.072805],[-105.135953,40.014665],[-105.152068,39.994335],[-105.109265,39.97241],[-105.058567,39.975502],[-105.052823,40.00026],[-105.093383,40.061142]]]},"properties":{"id":"80026","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.405166,40.366712],[-105.465394,40.259357],[-105.623872,40.157185],[-105.440319,40.142629],[-105.320587,40.159377],[-105.236778,40.285875],[-105.239238,40.309896],[-105.405166,40.366712]]]},"properties":{"id":"80540","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.131059,40.261079],[-105.131104,40.203252],[-105.093148,40.163924],[-105.130804,40.143343],[-105.131268,40.072805],[-105.093383,40.061142],[-104.978912,40.087496],[-104.941318,40.087859],[-104.939263,40.109557],[-104.923747,40.116687],[-104.909159,40.187934],[-104.979148,40.233484],[-105.036469,40.243927],[-105.131059,40.261079]]]},"properties":{"id":"80504","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.569955,39.801022],[-105.40966,39.756314],[-105.429725,39.931374],[-105.442389,39.941401],[-105.675798,39.932445],[-105.690348,39.851996],[-105.569955,39.801022]]]},"properties":{"id":"80422","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.429725,39.931374],[-105.400687,39.935072],[-105.442389,39.941401],[-105.429725,39.931374]]]},"properties":{"id":"80471","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.26773,40.003438],[-105.26394,40.008217],[-105.268335,40.011741],[-105.276923,40.01049],[-105.26773,40.003438]]]},"properties":{"id":"80310","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.409307,38.827888],[-105.967206,38.765746],[-105.938063,38.755212],[-106.191006,39.056073],[-106.27176,39.056425],[-106.577966,39.057908],[-106.599214,38.997994],[-106.409307,38.827888]]]},"properties":{"id":"81211","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.046571,39.047038],[-102.354002,39.045674],[-102.327479,38.615179],[-102.045082,38.615172],[-102.046571,39.047038]]]},"properties":{"id":"80802","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.680788,39.783308],[-105.724125,39.819018],[-105.735925,39.804985],[-105.680788,39.783308]]]},"properties":{"id":"80436","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.118644,37.281154],[-106.440839,37.399888],[-106.523589,37.402237],[-106.678373,37.403596],[-106.648303,37.216108],[-106.476229,36.993758],[-105.718463,36.995841],[-105.740698,37.102158],[-105.748116,37.136641],[-105.96541,37.166965],[-105.982523,37.166974],[-105.989709,37.167087],[-106.092639,37.268878],[-106.118644,37.281154]],[[-106.024526,37.087371],[-106.056023,37.093743],[-106.037821,37.122915],[-106.005181,37.108259],[-106.024526,37.087371]]]},"properties":{"id":"81120","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.430978,37.248927],[-105.165221,37.290702],[-105.195067,37.619625],[-105.445885,37.604507],[-105.552653,37.2872],[-105.430978,37.248927]]]},"properties":{"id":"81133","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.948232,38.128158],[-103.858215,38.025389],[-103.801442,38.112525],[-103.857005,38.163733],[-103.948232,38.128158]]]},"properties":{"id":"81058","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.987413,39.738431],[-104.987485,39.722535],[-104.987501,39.716575],[-104.979149,39.716515],[-104.978177,39.7475],[-104.986105,39.747526],[-104.986117,39.744889],[-104.986115,39.743279],[-104.984846,39.743276],[-104.984854,39.741675],[-104.986116,39.741678],[-104.987413,39.738431]]]},"properties":{"id":"80203","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.903494,39.711281],[-104.870653,39.710169],[-104.884708,39.732846],[-104.912696,39.717292],[-104.903494,39.711281]]]},"properties":{"id":"80230","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.989101,39.750253],[-104.990311,39.749322],[-104.989446,39.74865],[-104.988227,39.749586],[-104.989101,39.750253]]]},"properties":{"id":"80294","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.331642,37.893884],[-108.546375,37.632605],[-108.694763,37.631516],[-108.593995,37.562536],[-108.6374,37.482161],[-108.517526,37.385398],[-108.03881,37.456526],[-108.034686,37.460752],[-107.970086,37.639587],[-107.908889,37.810941],[-107.943005,37.826694],[-107.948537,37.834094],[-107.992636,37.856491],[-108.199082,37.824187],[-108.331642,37.893884]]]},"properties":{"id":"81323","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.970266,39.566073],[-104.996627,39.513026],[-104.941999,39.503459],[-104.90443,39.563844],[-104.904415,39.565982],[-104.922744,39.566066],[-104.970266,39.566073]]]},"properties":{"id":"80126","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.434116,39.595125],[-106.37184,39.603957],[-106.216037,39.531679],[-106.177604,39.609056],[-106.365325,39.752918],[-106.448363,39.608424],[-106.434116,39.595125]]]},"properties":{"id":"81657","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.413125,39.829696],[-106.739294,39.786163],[-106.678517,39.702397],[-106.5558,39.660868],[-106.448363,39.608424],[-106.365325,39.752918],[-106.413125,39.829696]]]},"properties":{"id":"81655","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.770686,39.099986],[-104.911702,39.085991],[-105.030677,39.085255],[-105.027655,39.019542],[-104.975267,38.948923],[-104.911914,38.893374],[-104.902201,38.867816],[-104.888372,38.867698],[-104.8756,38.887815],[-104.891634,38.954537],[-104.807735,38.98028],[-104.781103,38.998494],[-104.770686,39.099986]]]},"properties":{"id":"80921","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.715746,38.831217],[-104.704193,38.817401],[-104.70153,38.831404],[-104.715746,38.831217]]]},"properties":{"id":"80914","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.678141,38.940835],[-104.680473,38.911801],[-104.628389,38.926405],[-104.636903,38.940526],[-104.678141,38.940835]]]},"properties":{"id":"80927","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.886575,38.824775],[-104.888372,38.867698],[-104.902201,38.867816],[-104.908516,38.867862],[-105.011534,38.796982],[-104.938986,38.792013],[-104.886575,38.824775]]]},"properties":{"id":"80829","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.368889,38.259399],[-105.81242,38.628654],[-105.826329,38.51036],[-105.728562,38.358815],[-105.849631,38.29312],[-105.796897,38.265047],[-105.612555,38.264871],[-105.61509,38.29843],[-105.6,38.287765],[-105.602889,38.264874],[-105.368889,38.259399]]]},"properties":{"id":"81223","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.940902,38.417833],[-104.94185,38.558379],[-104.994989,38.650878],[-105.109724,38.647531],[-105.11374,38.43718],[-104.957134,38.358215],[-104.940748,38.337768],[-104.940902,38.417833]]]},"properties":{"id":"81240","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.15234,38.36745],[-105.199297,38.37437],[-105.20107,38.35221],[-105.153949,38.354273],[-105.15234,38.36745]]]},"properties":{"id":"81244","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.728066,40.041125],[-105.912495,39.98351],[-105.829538,39.990251],[-105.728066,40.041125]]]},"properties":{"id":"80478","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.143101,40.290593],[-106.325952,39.91375],[-106.026699,39.691026],[-105.924618,39.698972],[-105.903857,39.728815],[-105.887622,39.7966],[-105.959047,39.799877],[-105.980699,39.943888],[-106.024998,39.999997],[-106.142619,40.160088],[-106.143101,40.290593]]]},"properties":{"id":"80468","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.500614,38.445584],[-107.649257,38.524333],[-107.63504,38.301896],[-107.622235,38.277838],[-107.56862,38.147267],[-107.383901,38.147204],[-107.302764,38.359438],[-107.500614,38.445584]]]},"properties":{"id":"81220","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.166304,38.017104],[-105.473204,37.89597],[-105.457255,37.751464],[-105.485516,37.577899],[-105.445885,37.604507],[-105.195067,37.619625],[-105.086253,37.678989],[-104.978843,37.738887],[-105.166304,38.017104]]]},"properties":{"id":"81040","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.786175,40.903492],[-106.652112,40.445231],[-106.140284,40.330103],[-106.118213,40.345037],[-106.24857,40.48419],[-106.22114,40.50938],[-106.011742,40.369488],[-105.945147,40.363947],[-105.854926,40.486252],[-105.892203,40.520747],[-106.190554,40.997578],[-106.857773,41.002663],[-106.786175,40.903492]],[[-106.237554,40.85587],[-106.235963,40.824598],[-106.342992,40.831316],[-106.30789,40.87238],[-106.237554,40.85587]]]},"properties":{"id":"80480","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.143374,39.773496],[-105.323706,39.73648],[-105.275694,39.666774],[-105.249777,39.66303],[-105.235273,39.661757],[-105.19276,39.705529],[-105.130575,39.725282],[-105.118704,39.725359],[-105.142489,39.761867],[-105.143374,39.773496]],[[-105.203172,39.729756],[-105.200354,39.729551],[-105.198469,39.726853],[-105.202139,39.726137],[-105.203172,39.729756]]]},"properties":{"id":"80401","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.065106,39.788813],[-105.137961,39.786747],[-105.143374,39.773496],[-105.142489,39.761867],[-105.090798,39.754795],[-105.062645,39.762093],[-105.065106,39.788813]]]},"properties":{"id":"80033","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.27764,39.635105],[-105.255741,39.615511],[-105.276821,39.642579],[-105.27764,39.635105]]]},"properties":{"id":"80454","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.249777,39.66303],[-105.241874,39.654975],[-105.235273,39.661757],[-105.249777,39.66303]]]},"properties":{"id":"80453","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.03881,37.456526],[-108.295164,37.215009],[-108.379203,36.999513],[-108.186839,36.999276],[-107.914515,37.229053],[-108.00633,37.271214],[-108.034686,37.460752],[-108.03881,37.456526]]]},"properties":{"id":"81326","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.3229,39.135278],[-108.459549,39.055145],[-108.293429,39.046873],[-108.255408,39.097363],[-108.242806,39.191733],[-108.3229,39.135278]]]},"properties":{"id":"81526","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.148968,41.000966],[-109.050928,40.436351],[-108.535614,40.369591],[-108.35414,40.221985],[-108.216503,40.221762],[-107.965307,40.388033],[-108.148968,41.000966]]]},"properties":{"id":"81640","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.554638,37.265635],[-108.39175,37.215991],[-108.426139,37.334083],[-108.554638,37.265635]]]},"properties":{"id":"81330","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.295164,37.215009],[-108.39175,37.215991],[-108.554638,37.265635],[-108.729748,37.178604],[-108.379203,36.999513],[-108.295164,37.215009]]]},"properties":{"id":"81334","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.6374,37.482161],[-108.593995,37.562536],[-108.683051,37.558686],[-108.73547,37.471524],[-108.6374,37.482161]]]},"properties":{"id":"81327","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.668738,40.292489],[-103.698015,40.00132],[-103.471991,40.001529],[-103.471195,40.334401],[-103.595395,40.321563],[-103.668738,40.292489]]]},"properties":{"id":"80723","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.821194,39.091989],[-105.717132,39.197828],[-105.879801,39.352506],[-105.821194,39.091989]]]},"properties":{"id":"80432","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.445576,38.273785],[-104.588782,38.254354],[-104.607845,38.254934],[-104.6179,38.149351],[-104.368599,38.266472],[-104.445576,38.273785]]]},"properties":{"id":"81006","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.940747,38.33753],[-104.678781,38.267979],[-104.663536,38.292196],[-104.742822,38.425282],[-104.940902,38.417833],[-104.940748,38.337768],[-104.940747,38.33753]]]},"properties":{"id":"81007","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.818418,40.449973],[-106.818878,40.44935],[-106.818927,40.447564],[-106.817393,40.448946],[-106.818418,40.449973]]]},"properties":{"id":"80488","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.716053,38.185273],[-105.954533,38.209714],[-105.904515,37.963064],[-105.889528,37.828458],[-105.841892,37.79359],[-105.64248,38.07993],[-105.716053,38.185273]]]},"properties":{"id":"81143","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.839021,37.778527],[-107.591984,37.639629],[-107.482179,37.639501],[-107.482188,37.66769],[-107.517505,37.837517],[-107.568875,37.965015],[-107.738283,37.905432],[-107.839021,37.778527]]]},"properties":{"id":"81433","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.312412,39.783437],[-103.459118,39.914165],[-103.463451,39.594212],[-103.378064,39.566201],[-103.274566,39.566474],[-103.311006,39.710674],[-103.312412,39.783437]]]},"properties":{"id":"80740","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.311006,39.710674],[-103.02864,39.684694],[-102.972283,39.800943],[-103.312412,39.783437],[-103.311006,39.710674]]]},"properties":{"id":"80801","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.907207,40.406802],[-104.867508,40.275071],[-104.784663,40.333569],[-104.759623,40.354281],[-104.879883,40.406803],[-104.907207,40.406802]]]},"properties":{"id":"80543","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.773047,40.289908],[-104.791868,40.2898],[-104.791907,40.279632],[-104.772586,40.285604],[-104.773047,40.289908]]]},"properties":{"id":"80623","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.452491,40.265312],[-102.431005,39.997828],[-102.253473,39.834428],[-102.050956,39.751023],[-102.051465,40.440008],[-102.452491,40.265312]]]},"properties":{"id":"80758","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.430431,39.570816],[-102.400319,39.628696],[-102.464025,39.7602],[-102.473726,39.760916],[-102.629501,39.568792],[-102.59156,39.569108],[-102.430431,39.570816]]]},"properties":{"id":"80824","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.867423,39.922782],[-104.827846,39.907144],[-104.843211,40.000398],[-104.867423,39.922782]]]},"properties":{"id":"80601","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.843211,40.000398],[-104.827846,39.907144],[-104.848778,39.885209],[-104.67808,39.906584],[-104.607003,39.899413],[-104.694278,40.078335],[-104.942471,40.048222],[-104.942563,40.033095],[-104.961408,40.000337],[-104.843211,40.000398]]]},"properties":{"id":"80603","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.98765,39.885341],[-104.902238,39.885241],[-104.904068,39.888693],[-104.921904,39.914034],[-104.98987,39.914064],[-104.98765,39.885341]]]},"properties":{"id":"80233","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.98765,39.885341],[-105.02502,39.885214],[-105.015339,39.849043],[-104.98571,39.849077],[-104.98765,39.885341]]]},"properties":{"id":"80260","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.02502,39.885214],[-105.027521,39.914256],[-105.0601,39.870945],[-105.074117,39.856547],[-105.047929,39.841796],[-105.024773,39.841789],[-105.015339,39.849043],[-105.02502,39.885214]]]},"properties":{"id":"80031","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.015339,39.849043],[-105.024773,39.841789],[-105.034505,39.820168],[-105.034719,39.798852],[-105.034574,39.783332],[-104.998334,39.782399],[-104.983289,39.809154],[-104.98571,39.849077],[-105.015339,39.849043]]]},"properties":{"id":"80221","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.602738,40.203352],[-104.489639,39.955685],[-104.414633,40.000637],[-104.296748,40.261839],[-104.300587,40.261864],[-104.602738,40.203352]]]},"properties":{"id":"80643","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.904515,37.963064],[-106.237956,37.867534],[-106.222424,37.675881],[-106.001581,37.705337],[-105.889528,37.828458],[-105.904515,37.963064]]]},"properties":{"id":"81125","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.922888,39.595059],[-104.923499,39.638619],[-104.988027,39.616922],[-104.988323,39.59457],[-104.922888,39.595059]]]},"properties":{"id":"80121","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.903531,39.699709],[-104.870882,39.674764],[-104.865942,39.68202],[-104.865971,39.711053],[-104.870653,39.710169],[-104.903494,39.711281],[-104.903531,39.699709]]]},"properties":{"id":"80247","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.903531,39.699709],[-104.900801,39.65311],[-104.880086,39.650773],[-104.870882,39.674764],[-104.903531,39.699709]]]},"properties":{"id":"80231","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.922272,39.714722],[-104.922324,39.69299],[-104.91293,39.653089],[-104.900801,39.65311],[-104.903531,39.699709],[-104.903494,39.711281],[-104.912696,39.717292],[-104.922272,39.714722]]]},"properties":{"id":"80224","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.94063,39.692941],[-104.940683,39.653013],[-104.931703,39.653044],[-104.91293,39.653089],[-104.922324,39.69299],[-104.94063,39.692941]]]},"properties":{"id":"80222","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.700768,37.20189],[-107.48209,37.166724],[-107.365613,37.136941],[-107.482179,37.639501],[-107.591984,37.639629],[-107.712173,37.226833],[-107.700768,37.20189]]]},"properties":{"id":"81122","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.700768,37.20189],[-107.712389,37.000002],[-107.442197,37.000005],[-107.438767,37.025208],[-107.48209,37.166724],[-107.700768,37.20189]]]},"properties":{"id":"81137","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.207018,38.265833],[-103.355573,38.265533],[-103.404339,37.643576],[-103.075284,37.520365],[-102.874804,37.643885],[-103.042117,38.099583],[-102.834637,38.18647],[-102.834481,38.266461],[-103.207018,38.265833]],[[-103.142591,38.082742],[-103.136219,38.076699],[-103.14241,38.075605],[-103.142591,38.082742]]]},"properties":{"id":"81054","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.652793,40.023772],[-105.521385,40.032436],[-105.516743,40.046123],[-105.440319,40.142629],[-105.623872,40.157185],[-105.666399,40.157335],[-105.634947,40.075799],[-105.652793,40.023772]]]},"properties":{"id":"80481","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.440319,40.142629],[-105.516743,40.046123],[-105.320587,40.159377],[-105.440319,40.142629]]]},"properties":{"id":"80455","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.680788,39.783308],[-105.735925,39.804985],[-105.887622,39.7966],[-105.903857,39.728815],[-105.661296,39.752799],[-105.660541,39.752159],[-105.680788,39.783308]]]},"properties":{"id":"80438","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.440839,37.399888],[-106.118644,37.281154],[-106.207216,37.291421],[-106.196004,37.342704],[-106.092639,37.268878],[-105.989709,37.167087],[-105.986996,37.177279],[-105.982269,37.177381],[-105.982523,37.166974],[-105.96541,37.166965],[-105.928361,37.210096],[-105.910329,37.316069],[-106.039331,37.400852],[-106.256945,37.400974],[-106.440839,37.399888]]]},"properties":{"id":"81140","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.351109,37.817488],[-104.143179,37.75833],[-104.061132,37.734704],[-103.858215,38.025389],[-103.948232,38.128158],[-104.058281,38.171098],[-104.193872,38.127788],[-104.386307,37.827405],[-104.351109,37.817488]]]},"properties":{"id":"81039","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.230404,38.877773],[-108.378698,38.668097],[-107.900674,38.668179],[-107.84856,38.668113],[-107.838731,38.737316],[-107.909224,38.768405],[-108.040012,38.828372],[-108.059582,38.853512],[-108.230404,38.877773]]]},"properties":{"id":"81416","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.987501,39.716575],[-104.988675,39.692983],[-104.940612,39.69657],[-104.941339,39.716229],[-104.959307,39.715312],[-104.979149,39.716515],[-104.987501,39.716575]]]},"properties":{"id":"80209","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.987572,39.667682],[-104.940683,39.653013],[-104.94063,39.692941],[-104.940612,39.69657],[-104.988675,39.692983],[-104.987572,39.667682]]]},"properties":{"id":"80210","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.978177,39.7475],[-104.979149,39.716515],[-104.959307,39.715312],[-104.964757,39.746943],[-104.978177,39.7475]]]},"properties":{"id":"80218","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.986116,39.741678],[-104.984854,39.741675],[-104.984846,39.743276],[-104.986115,39.743279],[-104.986116,39.741678]]]},"properties":{"id":"80264","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.041512,37.881167],[-109.043488,37.484684],[-108.916993,37.631903],[-108.821416,37.88092],[-109.041512,37.881167]]]},"properties":{"id":"81324","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.608424,39.565922],[-104.785308,39.566088],[-104.662666,39.434398],[-104.608424,39.565922]]]},"properties":{"id":"80138","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.791368,39.566087],[-104.8685,39.536252],[-104.872368,39.507753],[-104.768882,39.416831],[-104.662729,39.427492],[-104.662666,39.434398],[-104.785308,39.566088],[-104.791368,39.566087]]]},"properties":{"id":"80134","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.366974,39.129577],[-105.25728,39.129552],[-105.033544,39.129819],[-105.029549,39.129831],[-104.91814,39.296831],[-104.901525,39.425338],[-104.927015,39.492974],[-104.934385,39.498293],[-105.000539,39.476629],[-105.014445,39.479074],[-105.123517,39.434245],[-105.170963,39.407825],[-105.261053,39.241856],[-105.397881,39.203277],[-105.366974,39.129577]]]},"properties":{"id":"80135","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.37184,39.603957],[-106.434116,39.595125],[-106.534149,39.509782],[-106.564376,39.435394],[-106.561086,39.42982],[-106.509214,39.362298],[-106.426487,39.361871],[-106.206732,39.379636],[-106.226829,39.428787],[-106.37184,39.603957]]]},"properties":{"id":"81645","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.847651,39.847056],[-106.689182,39.443839],[-106.561086,39.42982],[-106.564376,39.435394],[-106.678517,39.702397],[-106.739294,39.786163],[-106.743499,39.861022],[-106.847651,39.847056]]]},"properties":{"id":"81631","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.457795,39.491458],[-104.543552,39.288651],[-104.384903,39.241441],[-104.234848,39.273603],[-104.178806,39.273543],[-104.106526,39.379978],[-104.274117,39.433042],[-104.333874,39.50048],[-104.457795,39.491458]]]},"properties":{"id":"80117","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.457795,39.491458],[-104.60084,39.565846],[-104.608424,39.565922],[-104.662666,39.434398],[-104.662729,39.427492],[-104.662891,39.245822],[-104.543552,39.288651],[-104.457795,39.491458]]]},"properties":{"id":"80107","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.127586,38.940995],[-104.053921,38.522393],[-103.99902,38.522727],[-103.706672,38.680723],[-103.644735,38.749639],[-103.756865,38.852111],[-104.090303,38.984408],[-104.127586,38.940995]]]},"properties":{"id":"80833","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.994989,38.650878],[-104.94185,38.558379],[-104.865667,38.64984],[-104.847399,38.679587],[-104.818613,38.719821],[-104.810435,38.746376],[-104.939744,38.696142],[-104.994989,38.650878]]]},"properties":{"id":"80926","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.757502,38.781714],[-104.720601,38.781254],[-104.673304,38.813043],[-104.69186,38.838945],[-104.70153,38.831404],[-104.704193,38.817401],[-104.715746,38.831217],[-104.747977,38.839816],[-104.748129,38.831769],[-104.757502,38.781714]]]},"properties":{"id":"80916","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.812851,38.932955],[-104.828178,38.902233],[-104.781988,38.889174],[-104.733715,38.913271],[-104.756821,38.938031],[-104.812851,38.932955]]]},"properties":{"id":"80918","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.383901,38.147204],[-107.001119,38.147072],[-107.302764,38.359438],[-107.383901,38.147204]]]},"properties":{"id":"81243","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.237554,40.85587],[-106.30789,40.87238],[-106.342992,40.831316],[-106.235963,40.824598],[-106.237554,40.85587]]]},"properties":{"id":"80434","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.142489,39.761867],[-105.118704,39.725359],[-105.090873,39.725457],[-105.090798,39.754795],[-105.142489,39.761867]]]},"properties":{"id":"80215","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.47975,38.26793],[-102.585863,38.613317],[-102.627023,38.613252],[-103.025637,38.614994],[-103.208278,38.32245],[-103.207018,38.265833],[-102.834481,38.266461],[-102.668224,38.267299],[-102.47975,38.26793]]]},"properties":{"id":"81036","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.377631,38.52343],[-103.208278,38.32245],[-103.025637,38.614994],[-103.172943,38.61245],[-103.377631,38.52343]]]},"properties":{"id":"81045","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.425651,39.570664],[-102.520751,39.041356],[-102.409929,39.044151],[-102.425651,39.570664]]]},"properties":{"id":"80805","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.977319,39.039305],[-102.878684,39.568247],[-103.02864,39.684694],[-103.311006,39.710674],[-103.274566,39.566474],[-103.163072,39.127991],[-103.051232,39.039557],[-102.994933,39.039362],[-102.977319,39.039305]]]},"properties":{"id":"80815","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.206732,39.379636],[-106.426487,39.361871],[-106.489103,39.248537],[-106.506469,39.178797],[-106.27176,39.056425],[-106.191006,39.056073],[-106.179683,39.195773],[-106.186288,39.280666],[-106.135529,39.379546],[-106.206732,39.379636]]]},"properties":{"id":"80461","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.712173,37.226833],[-108.00633,37.271214],[-107.914515,37.229053],[-108.037602,36.99974],[-107.712389,37.000002],[-107.700768,37.20189],[-107.712173,37.226833]]]},"properties":{"id":"81303","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.495208,40.734481],[-105.236225,40.719513],[-105.172265,40.841871],[-105.171118,40.998332],[-105.553918,40.997401],[-105.495208,40.734481]]]},"properties":{"id":"80536","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.495208,40.734481],[-105.553918,40.997401],[-105.899713,40.997091],[-105.793154,40.810317],[-105.495208,40.734481]]]},"properties":{"id":"80545","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.992979,40.40708],[-105.266014,40.434148],[-105.415165,40.367723],[-105.405166,40.366712],[-105.239238,40.309896],[-104.980417,40.34264],[-104.992979,40.40708]]]},"properties":{"id":"80537","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.982222,40.464798],[-104.944258,40.522768],[-104.996246,40.539572],[-105.033064,40.465666],[-104.982222,40.464798]]]},"properties":{"id":"80528","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.943371,40.998213],[-105.171118,40.998332],[-105.172265,40.841871],[-105.17055,40.841545],[-104.924275,40.682528],[-104.905815,40.754691],[-104.943371,40.998213]]]},"properties":{"id":"80549","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.733893,37.311499],[-104.465629,37.367182],[-104.143179,37.75833],[-104.351109,37.817488],[-104.925753,37.397048],[-105.02898,37.339461],[-104.844982,37.258667],[-104.733893,37.311499]]]},"properties":{"id":"81020","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.378064,39.566201],[-103.379224,39.243298],[-103.163072,39.127991],[-103.274566,39.566474],[-103.378064,39.566201]]]},"properties":{"id":"80804","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.226558,40.436087],[-103.314969,40.56419],[-103.321503,40.435581],[-103.32091,40.435585],[-103.226558,40.436087]]]},"properties":{"id":"80722","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.345511,41.001834],[-103.1688,40.833445],[-102.924602,40.913134],[-102.84644,41.002177],[-103.345511,41.001834]]]},"properties":{"id":"80747","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.617171,39.265276],[-109.05158,39.116535],[-109.051618,39.111653],[-108.743212,39.054825],[-108.660188,39.108787],[-108.617171,39.265276]]]},"properties":{"id":"81521","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.316591,40.880045],[-107.14953,40.848474],[-107.147049,40.94939],[-107.316591,40.880045]]],[[[-107.316591,40.880045],[-107.39864,41.002969],[-108.148968,41.000966],[-107.965307,40.388033],[-107.756377,40.385963],[-107.429345,40.440771],[-107.316591,40.880045]]]]},"properties":{"id":"81625","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.683051,37.558686],[-108.849361,37.440664],[-108.73547,37.471524],[-108.683051,37.558686]]]},"properties":{"id":"81335","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.858215,38.025389],[-104.061132,37.734704],[-103.772877,37.821394],[-103.639295,38.021229],[-103.628182,38.027335],[-103.540176,38.172663],[-103.74764,38.113077],[-103.801442,38.112525],[-103.858215,38.025389]]]},"properties":{"id":"81067","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.628182,38.027335],[-103.639295,38.021229],[-103.628349,38.017985],[-103.628182,38.027335]]]},"properties":{"id":"81077","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.736059,37.93228],[-107.738283,37.905432],[-107.568875,37.965015],[-107.573522,38.134953],[-107.736059,37.93228]]]},"properties":{"id":"81427","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.186288,39.280666],[-106.062616,39.36182],[-106.135529,39.379546],[-106.186288,39.280666]]]},"properties":{"id":"80420","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.950552,39.272642],[-106.905511,39.174168],[-106.969961,39.155863],[-106.980027,39.204891],[-107.00555,39.076249],[-106.996145,39.034175],[-106.824338,38.978975],[-106.799689,38.97965],[-106.599214,38.997994],[-106.577966,39.057908],[-106.506469,39.178797],[-106.489103,39.248537],[-106.92467,39.303927],[-106.918494,39.29763],[-106.889637,39.279227],[-106.880955,39.257241],[-106.921759,39.29794],[-106.950552,39.272642]],[[-106.825827,39.135659],[-106.846651,39.123363],[-106.850259,39.155941],[-106.835629,39.153322],[-106.825827,39.135659]]]},"properties":{"id":"81611","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.825827,39.135659],[-106.835629,39.153322],[-106.850259,39.155941],[-106.846651,39.123363],[-106.825827,39.135659]]]},"properties":{"id":"81612","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.603548,38.307415],[-104.588782,38.254354],[-104.445576,38.273785],[-104.479437,38.334853],[-104.603548,38.307415]]]},"properties":{"id":"81001","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.607845,38.254934],[-104.588782,38.254354],[-104.603548,38.307415],[-104.663536,38.292196],[-104.678781,38.267979],[-104.646735,38.26961],[-104.607845,38.254934]]]},"properties":{"id":"81003","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.316591,40.880045],[-107.429345,40.440771],[-107.439386,40.223379],[-107.101378,40.22514],[-107.083588,40.363414],[-107.082883,40.699756],[-107.14953,40.848474],[-107.316591,40.880045]]]},"properties":{"id":"81639","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.147049,40.94939],[-107.14953,40.848474],[-107.082883,40.699756],[-106.786175,40.903492],[-106.857773,41.002663],[-107.132458,41.003043],[-107.147049,40.94939]]]},"properties":{"id":"80428","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.831186,40.486133],[-106.829044,40.484827],[-106.827086,40.486777],[-106.829395,40.488006],[-106.831186,40.486133]]]},"properties":{"id":"80477","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.974235,40.04338],[-106.836974,39.919197],[-106.67847,40.104408],[-106.801173,40.15287],[-106.974235,40.04338]]]},"properties":{"id":"80479","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.379695,41.002444],[-102.306371,40.749126],[-102.267489,40.748947],[-102.051416,40.749586],[-102.379695,41.002444]]]},"properties":{"id":"80737","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.653463,41.002332],[-102.651814,40.865445],[-102.651448,40.792959],[-102.420115,40.778193],[-102.460334,41.002383],[-102.653463,41.002332]]]},"properties":{"id":"80749","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.459118,39.914165],[-103.471991,40.001529],[-103.698015,40.00132],[-103.706263,39.828548],[-103.707072,39.566295],[-103.613063,39.566174],[-103.463451,39.594212],[-103.459118,39.914165]]]},"properties":{"id":"80757","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.942471,40.048222],[-104.924192,40.08773],[-104.941318,40.087859],[-104.978912,40.087496],[-104.942563,40.033095],[-104.942471,40.048222]]]},"properties":{"id":"80514","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.848455,40.523373],[-104.848384,40.53007],[-104.851093,40.528032],[-104.851185,40.520963],[-104.848455,40.523373]]]},"properties":{"id":"80546","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.489088,40.522484],[-104.413144,40.51552],[-104.456896,40.576553],[-104.489088,40.522484]]]},"properties":{"id":"80622","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.489088,40.522484],[-104.564587,40.478741],[-104.50788,40.435715],[-104.413354,40.479296],[-104.413144,40.51552],[-104.489088,40.522484]]]},"properties":{"id":"80624","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.465753,39.860793],[-102.253473,39.834428],[-102.431005,39.997828],[-102.465753,39.860793]]]},"properties":{"id":"80755","state_code":"08"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.411924,41.429532],[-73.542628,41.38925],[-73.543416,41.376754],[-73.470357,41.326948],[-73.42433,41.335289],[-73.405965,41.428194],[-73.411924,41.429532]]]},"properties":{"id":"06810","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.170894,41.186648],[-73.189356,41.202696],[-73.186832,41.194358],[-73.180455,41.171296],[-73.170894,41.186648]]]},"properties":{"id":"06608","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.474565,41.114696],[-73.500756,41.114414],[-73.517393,41.078172],[-73.509097,41.065735],[-73.495486,41.037527],[-73.449064,41.052997],[-73.446853,41.08248],[-73.455805,41.093389],[-73.474565,41.114696]]]},"properties":{"id":"06820","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.599163,41.099018],[-73.65565,41.012678],[-73.597355,41.006868],[-73.594627,41.030812],[-73.592448,41.090614],[-73.599163,41.099018]]]},"properties":{"id":"06830","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.541968,41.05886],[-73.558607,41.105613],[-73.592448,41.090614],[-73.57649,41.070182],[-73.550927,41.022187],[-73.495486,41.037527],[-73.509097,41.065735],[-73.533559,41.075227],[-73.533606,41.060388],[-73.529318,41.053663],[-73.545021,41.046709],[-73.541968,41.05886]]]},"properties":{"id":"06902","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.305861,41.332295],[-73.274371,41.293806],[-73.2048,41.29936],[-73.157055,41.376696],[-73.183269,41.391711],[-73.256184,41.357319],[-73.305861,41.332295]]]},"properties":{"id":"06468","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.653888,41.752993],[-72.695948,41.725052],[-72.647836,41.727899],[-72.653888,41.752993]]]},"properties":{"id":"06114","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.720345,41.776059],[-72.714782,41.756428],[-72.693827,41.765137],[-72.693773,41.767806],[-72.687962,41.766181],[-72.681831,41.76786],[-72.681771,41.770673],[-72.686653,41.774746],[-72.712997,41.798969],[-72.720345,41.776059]]]},"properties":{"id":"06105","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.785489,41.669473],[-72.789623,41.647553],[-72.750357,41.650167],[-72.757552,41.688743],[-72.785489,41.669473]]]},"properties":{"id":"06051","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.861781,41.920458],[-72.867406,41.816404],[-72.840029,41.820216],[-72.807273,41.84716],[-72.798552,41.824885],[-72.766639,41.897597],[-72.769405,41.917829],[-72.78264,41.917997],[-72.825119,41.918879],[-72.861781,41.920458]]]},"properties":{"id":"06070","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.807273,41.84716],[-72.840029,41.820216],[-72.819682,41.822453],[-72.807273,41.84716]]]},"properties":{"id":"06089","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.738395,41.892645],[-72.686137,41.802899],[-72.64564,41.805834],[-72.619264,41.868296],[-72.621734,41.904597],[-72.695906,41.922431],[-72.738395,41.892645]]]},"properties":{"id":"06095","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.577367,41.938657],[-72.623434,41.934297],[-72.621734,41.904597],[-72.619264,41.868296],[-72.587988,41.868313],[-72.577367,41.938657]]]},"properties":{"id":"06088","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.695906,41.922431],[-72.621734,41.904597],[-72.623434,41.934297],[-72.613964,41.943468],[-72.687636,41.948096],[-72.695906,41.922431]]]},"properties":{"id":"06096","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.578472,41.774015],[-72.655936,41.7543],[-72.653888,41.752993],[-72.647836,41.727899],[-72.636335,41.724899],[-72.571233,41.733799],[-72.578472,41.774015]]]},"properties":{"id":"06118","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.288695,41.823895],[-73.197524,41.804514],[-73.177713,41.870824],[-73.263041,41.916185],[-73.271543,41.886333],[-73.288695,41.823895]]]},"properties":{"id":"06756","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.506923,41.665802],[-73.488951,41.618433],[-73.456332,41.674974],[-73.501858,41.669545],[-73.506923,41.665802]]]},"properties":{"id":"06755","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.055724,41.702891],[-73.050362,41.609748],[-73.034777,41.615134],[-73.055724,41.702891]]]},"properties":{"id":"06782","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.110572,41.20027],[-73.02698,41.252309],[-73.086852,41.276596],[-73.086752,41.254596],[-73.110572,41.20027]]]},"properties":{"id":"06461","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.050051,41.55835],[-73.038561,41.560034],[-73.041954,41.572154],[-73.053761,41.569814],[-73.050051,41.55835]]]},"properties":{"id":"06710","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.956296,41.310459],[-72.989148,41.313297],[-72.992051,41.244909],[-72.984234,41.233794],[-72.932568,41.277908],[-72.951085,41.297262],[-72.956296,41.310459]]]},"properties":{"id":"06516","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.113344,41.488503],[-73.15789,41.489409],[-73.207688,41.420544],[-73.183269,41.391711],[-73.157055,41.376696],[-73.147555,41.367696],[-73.083585,41.403766],[-73.077366,41.465189],[-73.113344,41.488503]]]},"properties":{"id":"06478","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.242024,41.443198],[-72.219307,41.412123],[-72.169807,41.418317],[-72.146104,41.425468],[-72.157443,41.463472],[-72.147012,41.463539],[-72.141218,41.455866],[-72.141983,41.504173],[-72.227624,41.518698],[-72.242024,41.443198]]]},"properties":{"id":"06370","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.125889,41.560007],[-72.135416,41.504006],[-72.08102,41.492099],[-72.04612,41.555998],[-72.045616,41.557014],[-72.061225,41.560779],[-72.063583,41.570328],[-72.045326,41.573023],[-72.047864,41.594823],[-72.104121,41.600798],[-72.125195,41.563345],[-72.125889,41.560007]]]},"properties":{"id":"06360","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.157443,41.463472],[-72.146104,41.425468],[-72.095621,41.425298],[-72.070021,41.463598],[-72.08102,41.492099],[-72.135416,41.504006],[-72.141983,41.504173],[-72.141218,41.455866],[-72.157443,41.463472]]]},"properties":{"id":"06382","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.884422,41.641204],[-71.863408,41.514926],[-71.79316,41.505704],[-71.787658,41.639918],[-71.817305,41.641858],[-71.857917,41.644227],[-71.884422,41.641204]]]},"properties":{"id":"06384","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.189531,41.356333],[-72.254139,41.309787],[-72.248378,41.293019],[-72.178918,41.321778],[-72.189531,41.356333]]]},"properties":{"id":"06357","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.190427,41.834999],[-72.26886,41.762517],[-72.252643,41.728202],[-72.198968,41.731068],[-72.156321,41.753491],[-72.154297,41.834945],[-72.190427,41.834999]]]},"properties":{"id":"06250","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.310145,41.836059],[-72.26886,41.762517],[-72.190427,41.834999],[-72.217719,41.835184],[-72.310145,41.836059]],[[-72.243843,41.804504],[-72.258222,41.80299],[-72.254094,41.81762],[-72.243843,41.804504]]]},"properties":{"id":"06268","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.102166,42.028802],[-72.397431,42.033087],[-72.406746,41.957164],[-72.306849,41.940601],[-72.21698,41.958161],[-72.151654,41.959302],[-72.099525,41.966038],[-72.102166,42.028802]]]},"properties":{"id":"06076","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.896503,41.813952],[-71.792593,41.804016],[-71.794134,41.840008],[-71.80903,41.846526],[-71.794474,41.849159],[-71.796828,41.904446],[-71.91736,41.869241],[-71.920873,41.849313],[-71.896503,41.813952]],[[-71.906861,41.84846],[-71.908981,41.841919],[-71.901181,41.836972],[-71.903778,41.833357],[-71.910446,41.841137],[-71.912614,41.849305],[-71.906861,41.84846]]]},"properties":{"id":"06241","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.857917,41.644227],[-71.903926,41.719501],[-71.906158,41.741502],[-71.915308,41.746706],[-71.913943,41.74233],[-71.954983,41.634799],[-71.884422,41.641204],[-71.857917,41.644227]]]},"properties":{"id":"06374","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.014491,41.804989],[-72.022903,41.759625],[-71.913943,41.74233],[-71.915308,41.746706],[-71.914688,41.74744],[-71.896503,41.813952],[-71.920873,41.849313],[-72.014491,41.804989]]]},"properties":{"id":"06234","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.113995,41.83169],[-72.151654,41.959302],[-72.21698,41.958161],[-72.217719,41.835184],[-72.190427,41.834999],[-72.154297,41.834945],[-72.113995,41.83169]]]},"properties":{"id":"06278","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.933847,41.916861],[-71.91736,41.869241],[-71.796828,41.904446],[-71.797894,41.932364],[-71.931792,41.931574],[-71.933847,41.916861]]]},"properties":{"id":"06260","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.084277,41.698453],[-72.082519,41.695137],[-72.073753,41.698653],[-72.084277,41.698453]]],[[[-72.112392,41.697618],[-72.109169,41.684062],[-72.084277,41.698453],[-72.112392,41.697618]]]]},"properties":{"id":"06264","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.189356,41.202696],[-73.170894,41.186648],[-73.154731,41.184819],[-73.153819,41.192533],[-73.157636,41.228377],[-73.17884,41.221975],[-73.189356,41.202696]]]},"properties":{"id":"06610","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.274371,41.293806],[-73.305861,41.332295],[-73.325162,41.322295],[-73.368811,41.274975],[-73.329014,41.213425],[-73.264795,41.214459],[-73.248556,41.231977],[-73.274371,41.293806]]]},"properties":{"id":"06612","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.470357,41.326948],[-73.543416,41.376754],[-73.502532,41.237228],[-73.43819,41.265187],[-73.470357,41.326948]]]},"properties":{"id":"06877","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.533559,41.075227],[-73.509097,41.065735],[-73.517393,41.078172],[-73.526015,41.083458],[-73.533559,41.075227]]]},"properties":{"id":"06906","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.445086,41.522574],[-73.530688,41.520245],[-73.537093,41.439706],[-73.426942,41.463834],[-73.441466,41.491294],[-73.445086,41.522574]]]},"properties":{"id":"06812","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.90848,41.776043],[-72.867027,41.763724],[-72.796742,41.758799],[-72.797241,41.804699],[-72.798552,41.824885],[-72.807273,41.84716],[-72.819682,41.822453],[-72.840029,41.820216],[-72.867406,41.816404],[-72.870201,41.81613],[-72.921183,41.807117],[-72.90848,41.776043]]]},"properties":{"id":"06001","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.760585,41.905705],[-72.769405,41.917829],[-72.766639,41.897597],[-72.760585,41.905705]]]},"properties":{"id":"06081","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.687962,41.766181],[-72.693773,41.767806],[-72.693827,41.765137],[-72.687962,41.766181]]]},"properties":{"id":"06160","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.252265,41.512134],[-73.15573,41.515279],[-73.145036,41.557822],[-73.172763,41.611613],[-73.253509,41.604532],[-73.267336,41.603199],[-73.252265,41.512134]]]},"properties":{"id":"06798","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.929254,41.971524],[-72.927377,41.951109],[-72.956569,41.935817],[-72.957767,41.970548],[-72.958743,41.970497],[-72.976226,41.969645],[-73.038223,41.946888],[-73.053534,41.887324],[-72.985282,41.892099],[-72.98451,41.892155],[-72.940647,41.895196],[-72.905945,41.921696],[-72.88706,41.973121],[-72.929254,41.971524]]]},"properties":{"id":"06063","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.978041,41.87763],[-72.977396,41.878238],[-72.98451,41.892155],[-72.985282,41.892099],[-72.978041,41.87763]]],[[[-73.168319,41.902091],[-73.053534,41.887324],[-73.038223,41.946888],[-73.023718,41.988131],[-73.008745,42.038854],[-73.076559,42.040541],[-73.133833,42.02032],[-73.168319,41.902091]]]]},"properties":{"id":"06098","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.261446,41.664641],[-73.253509,41.604532],[-73.172763,41.611613],[-73.170756,41.669196],[-73.221248,41.666728],[-73.261446,41.664641]]]},"properties":{"id":"06751","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.399299,41.959288],[-73.390807,41.918871],[-73.271543,41.886333],[-73.263041,41.916185],[-73.243605,41.994021],[-73.328209,42.000802],[-73.357487,42.005014],[-73.399299,41.959288]]]},"properties":{"id":"06031","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.389441,41.719771],[-73.40845,41.680004],[-73.382124,41.683327],[-73.389441,41.719771]]],[[[-73.400247,41.746594],[-73.410642,41.788308],[-73.50793,41.791524],[-73.51808,41.666723],[-73.506923,41.665802],[-73.501858,41.669545],[-73.400247,41.746594]]]]},"properties":{"id":"06757","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.055724,41.702891],[-73.034777,41.615134],[-73.01865,41.614097],[-72.98325,41.639598],[-72.99855,41.712498],[-72.99895,41.714398],[-73.055262,41.70816],[-73.055724,41.702891]]]},"properties":{"id":"06786","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.221248,41.666728],[-73.26063,41.676441],[-73.261446,41.664641],[-73.221248,41.666728]]]},"properties":{"id":"06758","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.05475,41.514636],[-73.042264,41.551947],[-73.055624,41.569129],[-73.064209,41.588491],[-73.089113,41.575654],[-73.093853,41.570196],[-73.070114,41.514301],[-73.05475,41.514636]]]},"properties":{"id":"06708","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.369147,41.342337],[-72.425525,41.324221],[-72.41686,41.275537],[-72.337327,41.274998],[-72.369147,41.342337]]]},"properties":{"id":"06475","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.700899,41.54891],[-72.713589,41.52882],[-72.697909,41.520574],[-72.686435,41.535126],[-72.700899,41.54891]]]},"properties":{"id":"06481","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.047543,41.395834],[-73.047162,41.360531],[-73.039141,41.326654],[-73.040707,41.313891],[-72.998048,41.314097],[-72.972909,41.347396],[-72.954873,41.394475],[-73.047543,41.395834]]]},"properties":{"id":"06525","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.01817,41.519834],[-73.001811,41.471159],[-72.952392,41.464589],[-72.969365,41.530234],[-72.991418,41.528893],[-73.01817,41.519834]]]},"properties":{"id":"06712","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.948048,41.464097],[-72.886667,41.447957],[-72.850797,41.516509],[-72.852707,41.546291],[-72.871824,41.55605],[-72.917251,41.560075],[-72.94635,41.556797],[-72.956788,41.555295],[-72.969365,41.530234],[-72.952392,41.464589],[-72.948048,41.464097]]]},"properties":{"id":"06410","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.047543,41.395834],[-72.954873,41.394475],[-72.942564,41.407128],[-72.948048,41.464097],[-72.952392,41.464589],[-73.001811,41.471159],[-73.025751,41.459596],[-73.041651,41.420896],[-73.047543,41.395834]]]},"properties":{"id":"06524","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.882131,41.325673],[-71.842488,41.408714],[-71.875299,41.416036],[-71.882131,41.325673]]]},"properties":{"id":"06379","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.956618,41.487398],[-72.057797,41.480761],[-72.036985,41.399442],[-72.019827,41.399267],[-71.943498,41.428881],[-71.956618,41.487398]]]},"properties":{"id":"06339","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.066223,41.643714],[-72.063447,41.6485],[-72.067599,41.652352],[-72.075008,41.65277],[-72.066223,41.643714]]]},"properties":{"id":"06350","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.460129,41.727299],[-72.452229,41.688799],[-72.42084,41.648053],[-72.344008,41.668712],[-72.356478,41.701497],[-72.421727,41.730343],[-72.460129,41.727299]]]},"properties":{"id":"06248","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.243843,41.804504],[-72.254094,41.81762],[-72.258222,41.80299],[-72.243843,41.804504]]]},"properties":{"id":"06269","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.963021,42.026224],[-72.102166,42.028802],[-72.099525,41.966038],[-72.067943,41.914652],[-72.06029,41.91455],[-71.933847,41.916861],[-71.931792,41.931574],[-71.935586,41.943518],[-71.959598,42.016717],[-71.963021,42.026224]]]},"properties":{"id":"06281","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.792593,41.804016],[-71.896503,41.813952],[-71.914688,41.74744],[-71.906431,41.741896],[-71.89697,41.740393],[-71.839378,41.764086],[-71.791254,41.774497],[-71.792593,41.804016]]]},"properties":{"id":"06239","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.099525,41.966038],[-72.097024,41.914994],[-72.067943,41.914652],[-72.099525,41.966038]]]},"properties":{"id":"06282","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.959598,42.016717],[-71.928856,42.02532],[-71.963021,42.026224],[-71.959598,42.016717]]]},"properties":{"id":"06262","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.223129,41.202441],[-73.186832,41.194358],[-73.189356,41.202696],[-73.17884,41.221975],[-73.244168,41.226592],[-73.223129,41.202441]]]},"properties":{"id":"06606","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.592448,41.090614],[-73.594627,41.030812],[-73.578941,41.057631],[-73.57649,41.070182],[-73.592448,41.090614]]]},"properties":{"id":"06807","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.146009,41.255807],[-73.2048,41.29936],[-73.274371,41.293806],[-73.248556,41.231977],[-73.244168,41.226592],[-73.17884,41.221975],[-73.157636,41.228377],[-73.146009,41.255807]]]},"properties":{"id":"06611","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.405965,41.428194],[-73.42433,41.335289],[-73.347713,41.350236],[-73.378952,41.420523],[-73.405965,41.428194]]]},"properties":{"id":"06801","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.445086,41.522574],[-73.488951,41.618433],[-73.506923,41.665802],[-73.51808,41.666723],[-73.530688,41.520245],[-73.445086,41.522574]]]},"properties":{"id":"06784","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.681771,41.770673],[-72.664908,41.769528],[-72.646035,41.797599],[-72.64564,41.805834],[-72.686137,41.802899],[-72.686039,41.800168],[-72.686653,41.774746],[-72.681771,41.770673]]]},"properties":{"id":"06120","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.905945,41.921696],[-72.940647,41.895196],[-72.949024,41.806431],[-72.921183,41.807117],[-72.870201,41.81613],[-72.876644,41.920796],[-72.905945,41.921696]],[[-72.930231,41.835807],[-72.92873,41.835329],[-72.928898,41.834367],[-72.930231,41.835807]],[[-72.93346,41.880157],[-72.913349,41.858694],[-72.938026,41.853354],[-72.93346,41.880157]]]},"properties":{"id":"06019","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.867027,41.763724],[-72.90848,41.776043],[-72.89827,41.723177],[-72.897031,41.717272],[-72.867027,41.763724]]]},"properties":{"id":"06085","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.681831,41.76786],[-72.665114,41.765762],[-72.664908,41.769528],[-72.681771,41.770673],[-72.681831,41.76786]]]},"properties":{"id":"06103","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.757552,41.688743],[-72.750357,41.650167],[-72.715296,41.652706],[-72.706039,41.668799],[-72.705938,41.7243],[-72.714561,41.72363],[-72.762179,41.718341],[-72.761969,41.715795],[-72.757552,41.688743]]]},"properties":{"id":"06111","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.619264,41.868296],[-72.64564,41.805834],[-72.646035,41.797599],[-72.583592,41.800612],[-72.50543,41.820298],[-72.49681,41.860616],[-72.51733,41.869897],[-72.587988,41.868313],[-72.619264,41.868296]]]},"properties":{"id":"06074","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.608632,41.681131],[-72.481504,41.667518],[-72.452229,41.688799],[-72.460129,41.727299],[-72.463899,41.745572],[-72.571233,41.733799],[-72.636335,41.724899],[-72.615735,41.688099],[-72.608632,41.681131]]]},"properties":{"id":"06033","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.496601,41.921748],[-73.390807,41.918871],[-73.399299,41.959288],[-73.489126,42.011661],[-73.496601,41.921748]]]},"properties":{"id":"06039","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.322117,41.783731],[-73.38314,41.833323],[-73.410642,41.788308],[-73.400247,41.746594],[-73.391237,41.722976],[-73.293823,41.723437],[-73.293933,41.739449],[-73.322117,41.783731]]]},"properties":{"id":"06754","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.386964,41.514794],[-73.331569,41.472677],[-73.310473,41.468815],[-73.318889,41.505189],[-73.343067,41.565855],[-73.386964,41.514794]]]},"properties":{"id":"06752","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.375954,41.65801],[-73.346912,41.595202],[-73.339779,41.595822],[-73.26063,41.676441],[-73.27549,41.69522],[-73.375954,41.65801]]]},"properties":{"id":"06794","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.343067,41.565855],[-73.318889,41.505189],[-73.252265,41.512134],[-73.267336,41.603199],[-73.339779,41.595822],[-73.346912,41.595202],[-73.343067,41.565855]]]},"properties":{"id":"06783","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.058074,41.615574],[-73.089113,41.575654],[-73.064209,41.588491],[-73.058024,41.60674],[-73.058074,41.615574]]]},"properties":{"id":"06779","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.42713,41.363717],[-72.417721,41.33687],[-72.435001,41.330042],[-72.425525,41.324221],[-72.369147,41.342337],[-72.382594,41.38248],[-72.42713,41.363717]]]},"properties":{"id":"06426","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.242024,41.443198],[-72.227624,41.518698],[-72.21911,41.538815],[-72.319345,41.499047],[-72.305531,41.436282],[-72.276636,41.440449],[-72.242024,41.443198]]]},"properties":{"id":"06420","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.15573,41.515279],[-73.252265,41.512134],[-73.318889,41.505189],[-73.310473,41.468815],[-73.298403,41.448916],[-73.207688,41.420544],[-73.15789,41.489409],[-73.15573,41.515279]]]},"properties":{"id":"06488","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.886667,41.447957],[-72.948048,41.464097],[-72.942564,41.407128],[-72.911343,41.372787],[-72.895386,41.383952],[-72.85136,41.434382],[-72.886667,41.447957]]]},"properties":{"id":"06518","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.039141,41.326654],[-73.102353,41.352896],[-73.124654,41.345296],[-73.067851,41.300996],[-73.040707,41.313891],[-73.039141,41.326654]]]},"properties":{"id":"06418","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.102353,41.352896],[-73.039141,41.326654],[-73.047162,41.360531],[-73.102353,41.352896]]]},"properties":{"id":"06401","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.79316,41.505704],[-71.863408,41.514926],[-71.90286,41.520262],[-71.956618,41.487398],[-71.943498,41.428881],[-71.875299,41.416036],[-71.842488,41.408714],[-71.79316,41.505704]]]},"properties":{"id":"06359","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.32451,41.61857],[-72.21911,41.538815],[-72.204161,41.570838],[-72.194024,41.590399],[-72.157546,41.657627],[-72.185111,41.675312],[-72.239559,41.714191],[-72.334226,41.643598],[-72.32451,41.61857]]]},"properties":{"id":"06249","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.134981,41.569529],[-72.125889,41.560007],[-72.125195,41.563345],[-72.134981,41.569529]]]},"properties":{"id":"06389","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.794474,41.849159],[-71.80903,41.846526],[-71.794134,41.840008],[-71.794474,41.849159]]]},"properties":{"id":"06243","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.056104,41.701203],[-72.031216,41.647242],[-71.954983,41.634799],[-71.913943,41.74233],[-72.022903,41.759625],[-72.056104,41.701203]]]},"properties":{"id":"06331","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.092446,41.83167],[-72.112045,41.745897],[-72.1185,41.725365],[-72.112392,41.697618],[-72.084277,41.698453],[-72.073753,41.698653],[-72.056104,41.701203],[-72.022903,41.759625],[-72.014491,41.804989],[-72.044738,41.831548],[-72.092446,41.83167]]]},"properties":{"id":"06247","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.839378,41.764086],[-71.810311,41.666121],[-71.788715,41.695598],[-71.791254,41.774497],[-71.839378,41.764086]]]},"properties":{"id":"06377","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.906158,41.741502],[-71.903926,41.719501],[-71.89697,41.740393],[-71.906431,41.741896],[-71.906158,41.741502]]]},"properties":{"id":"06332","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.542628,41.38925],[-73.411924,41.429532],[-73.426942,41.463834],[-73.537093,41.439706],[-73.542628,41.38925]]]},"properties":{"id":"06811","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.587608,41.007684],[-73.578941,41.057631],[-73.594627,41.030812],[-73.597355,41.006868],[-73.587608,41.007684]]]},"properties":{"id":"06878","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.558607,41.105613],[-73.541968,41.05886],[-73.533606,41.060388],[-73.533559,41.075227],[-73.526015,41.083458],[-73.535111,41.116235],[-73.558607,41.105613]]]},"properties":{"id":"06905","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.368811,41.274975],[-73.430102,41.254328],[-73.379568,41.185777],[-73.338457,41.194539],[-73.329014,41.213425],[-73.368811,41.274975]]]},"properties":{"id":"06883","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.387073,41.170495],[-73.422233,41.160029],[-73.410582,41.107557],[-73.381327,41.118509],[-73.387073,41.170495]]]},"properties":{"id":"06851","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.456332,41.674974],[-73.488951,41.618433],[-73.445086,41.522574],[-73.441466,41.491294],[-73.386964,41.514794],[-73.343067,41.565855],[-73.346912,41.595202],[-73.375954,41.65801],[-73.382124,41.683327],[-73.40845,41.680004],[-73.456332,41.674974]]]},"properties":{"id":"06776","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.016936,41.798183],[-72.99895,41.714398],[-72.99855,41.712498],[-72.89827,41.723177],[-72.90848,41.776043],[-72.921183,41.807117],[-72.949024,41.806431],[-73.016936,41.798183]]]},"properties":{"id":"06013","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.509192,42.034141],[-72.606933,42.024995],[-72.613964,41.943468],[-72.623434,41.934297],[-72.577367,41.938657],[-72.51333,41.945401],[-72.492729,41.947497],[-72.509192,42.034141]]]},"properties":{"id":"06082","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.785489,41.669473],[-72.757552,41.688743],[-72.761969,41.715795],[-72.832508,41.690434],[-72.825418,41.668938],[-72.785489,41.669473]]]},"properties":{"id":"06053","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.789623,41.647553],[-72.785489,41.669473],[-72.825418,41.668938],[-72.822573,41.65571],[-72.814989,41.64448],[-72.789623,41.647553]]]},"properties":{"id":"06052","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.874428,42.037672],[-72.880943,41.990193],[-72.836233,41.97415],[-72.808606,41.998389],[-72.874428,42.037672]]]},"properties":{"id":"06060","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.687636,41.948096],[-72.613964,41.943468],[-72.606933,42.024995],[-72.678331,42.035585],[-72.704318,41.949265],[-72.687636,41.948096]]]},"properties":{"id":"06078","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.664908,41.769528],[-72.665114,41.765762],[-72.655936,41.7543],[-72.578472,41.774015],[-72.579949,41.782189],[-72.583592,41.800612],[-72.646035,41.797599],[-72.664908,41.769528]]]},"properties":{"id":"06108","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.26063,41.676441],[-73.221248,41.666728],[-73.170756,41.669196],[-73.141531,41.672709],[-73.141354,41.672749],[-73.222342,41.710576],[-73.275736,41.69905],[-73.27549,41.69522],[-73.26063,41.676441]]]},"properties":{"id":"06763","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.496601,41.921748],[-73.50793,41.791524],[-73.410642,41.788308],[-73.38314,41.833323],[-73.390807,41.918871],[-73.496601,41.921748]]]},"properties":{"id":"06069","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.053534,41.887324],[-73.168319,41.902091],[-73.177713,41.870824],[-73.197524,41.804514],[-73.122017,41.776558],[-73.076683,41.798451],[-73.053534,41.887324]]]},"properties":{"id":"06790","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.978041,41.87763],[-72.970922,41.877548],[-72.967872,41.875458],[-72.964085,41.87565],[-72.964736,41.878582],[-72.967852,41.879199],[-72.977396,41.878238],[-72.978041,41.87763]]]},"properties":{"id":"06061","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.236077,42.029535],[-73.328209,42.000802],[-73.243605,41.994021],[-73.236077,42.029535]]]},"properties":{"id":"06024","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.648802,41.575236],[-72.560802,41.554049],[-72.561992,41.556175],[-72.559523,41.562355],[-72.54423,41.642696],[-72.630524,41.633282],[-72.648802,41.575236]]]},"properties":{"id":"06480","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.512034,41.341599],[-72.485662,41.269024],[-72.41686,41.275537],[-72.425525,41.324221],[-72.435001,41.330042],[-72.472724,41.334209],[-72.51395,41.346403],[-72.512034,41.341599]]]},"properties":{"id":"06498","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.524062,41.376179],[-72.425663,41.400805],[-72.43371,41.423995],[-72.450983,41.433665],[-72.52917,41.423724],[-72.538782,41.421491],[-72.524062,41.376179]]]},"properties":{"id":"06412","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.552657,41.562122],[-72.559523,41.562355],[-72.561992,41.556175],[-72.552657,41.562122]]]},"properties":{"id":"06414","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.82263,41.322882],[-72.904491,41.29923],[-72.905184,41.298252],[-72.910441,41.290892],[-72.860296,41.244161],[-72.827278,41.317115],[-72.82263,41.322882]]]},"properties":{"id":"06512","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.82263,41.322882],[-72.827278,41.317115],[-72.746059,41.310605],[-72.745915,41.372234],[-72.818955,41.335652],[-72.82263,41.322882]]]},"properties":{"id":"06471","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.931444,41.303579],[-72.951085,41.297262],[-72.932568,41.277908],[-72.910441,41.290892],[-72.905184,41.298252],[-72.928462,41.302457],[-72.931444,41.303579]]]},"properties":{"id":"06519","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.928462,41.302457],[-72.918439,41.313173],[-72.921372,41.314352],[-72.931444,41.303579],[-72.928462,41.302457]]]},"properties":{"id":"06510","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.678739,41.433797],[-72.65375,41.264218],[-72.532709,41.252791],[-72.585657,41.309852],[-72.653672,41.438125],[-72.678739,41.433797]]]},"properties":{"id":"06443","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.019827,41.399267],[-71.980935,41.310154],[-71.956484,41.326567],[-71.943498,41.428881],[-72.019827,41.399267]]]},"properties":{"id":"06355","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.382594,41.38248],[-72.369147,41.342337],[-72.337327,41.274998],[-72.248378,41.293019],[-72.254139,41.309787],[-72.276636,41.440449],[-72.305531,41.436282],[-72.43371,41.423995],[-72.425663,41.400805],[-72.382594,41.38248]],[[-72.252416,41.291209],[-72.257034,41.293153],[-72.251559,41.295405],[-72.252416,41.291209]]]},"properties":{"id":"06371","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.134981,41.569529],[-72.125195,41.563345],[-72.104121,41.600798],[-72.120328,41.661925],[-72.157472,41.657798],[-72.157546,41.657627],[-72.194024,41.590399],[-72.190843,41.589564],[-72.134981,41.569529]]]},"properties":{"id":"06254","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.121915,41.37135],[-72.100162,41.30187],[-72.081321,41.312715],[-72.09292,41.382586],[-72.121915,41.37135]]]},"properties":{"id":"06320","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.26886,41.762517],[-72.310145,41.836059],[-72.309744,41.837772],[-72.421527,41.825498],[-72.418891,41.806559],[-72.410251,41.765505],[-72.328872,41.738819],[-72.246596,41.722901],[-72.252643,41.728202],[-72.26886,41.762517]]]},"properties":{"id":"06238","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.185995,41.720212],[-72.177844,41.690097],[-72.157472,41.657798],[-72.120328,41.661925],[-72.082519,41.695137],[-72.084277,41.698453],[-72.109169,41.684062],[-72.112392,41.697618],[-72.1185,41.725365],[-72.185995,41.720212]]]},"properties":{"id":"06280","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.839378,41.764086],[-71.89697,41.740393],[-71.903926,41.719501],[-71.857917,41.644227],[-71.817305,41.641858],[-71.810311,41.666121],[-71.839378,41.764086]]]},"properties":{"id":"06354","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.91736,41.869241],[-71.933847,41.916861],[-72.06029,41.91455],[-72.044738,41.831548],[-72.014491,41.804989],[-71.920873,41.849313],[-71.91736,41.869241]]]},"properties":{"id":"06259","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.165755,41.154297],[-73.154731,41.184819],[-73.170894,41.186648],[-73.180455,41.171296],[-73.179855,41.152597],[-73.165755,41.154297]]]},"properties":{"id":"06607","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.306611,41.154347],[-73.290064,41.124042],[-73.232635,41.142522],[-73.232111,41.16266],[-73.264795,41.214459],[-73.329014,41.213425],[-73.338457,41.194539],[-73.306611,41.154347]]]},"properties":{"id":"06824","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.306611,41.154347],[-73.297709,41.123823],[-73.290064,41.124042],[-73.306611,41.154347]]]},"properties":{"id":"06890","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.298403,41.448916],[-73.256184,41.357319],[-73.183269,41.391711],[-73.207688,41.420544],[-73.298403,41.448916]]]},"properties":{"id":"06482","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.347713,41.350236],[-73.325162,41.322295],[-73.305861,41.332295],[-73.256184,41.357319],[-73.298403,41.448916],[-73.310473,41.468815],[-73.331569,41.472677],[-73.378952,41.420523],[-73.347713,41.350236]]]},"properties":{"id":"06470","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.325162,41.322295],[-73.347713,41.350236],[-73.42433,41.335289],[-73.470357,41.326948],[-73.43819,41.265187],[-73.430102,41.254328],[-73.368811,41.274975],[-73.325162,41.322295]]]},"properties":{"id":"06896","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.410582,41.107557],[-73.455805,41.093389],[-73.446853,41.08248],[-73.431472,41.054536],[-73.428859,41.052384],[-73.410582,41.107557]]]},"properties":{"id":"06854","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.431472,41.054536],[-73.446853,41.08248],[-73.449064,41.052997],[-73.431472,41.054536]]]},"properties":{"id":"06853","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.420598,41.110535],[-73.420222,41.111195],[-73.421301,41.11166],[-73.421149,41.110468],[-73.420598,41.110535]]]},"properties":{"id":"06856","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.737042,41.778535],[-72.789642,41.747769],[-72.762346,41.726854],[-72.73429,41.748691],[-72.737042,41.778535]]]},"properties":{"id":"06107","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.714538,41.60431],[-72.71521,41.625885],[-72.727175,41.601994],[-72.714538,41.60431]]]},"properties":{"id":"06023","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.880943,41.990193],[-72.88706,41.973121],[-72.905945,41.921696],[-72.876644,41.920796],[-72.861781,41.920458],[-72.825119,41.918879],[-72.836233,41.97415],[-72.880943,41.990193]]]},"properties":{"id":"06090","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.481504,41.667518],[-72.608632,41.681131],[-72.627836,41.640199],[-72.630524,41.633282],[-72.54423,41.642696],[-72.505431,41.646999],[-72.481504,41.667518]]]},"properties":{"id":"06073","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.579949,41.782189],[-72.472281,41.79096],[-72.473468,41.798264],[-72.50543,41.820298],[-72.583592,41.800612],[-72.579949,41.782189]]]},"properties":{"id":"06042","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.133833,42.02032],[-73.076559,42.040541],[-73.127249,42.042054],[-73.133833,42.02032]]]},"properties":{"id":"06021","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.127249,42.042054],[-73.232944,42.045075],[-73.236077,42.029535],[-73.243605,41.994021],[-73.263041,41.916185],[-73.177713,41.870824],[-73.168319,41.902091],[-73.133833,42.02032],[-73.127249,42.042054]]]},"properties":{"id":"06058","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.134716,41.675557],[-73.068402,41.707093],[-73.114075,41.747389],[-73.134716,41.675557]]]},"properties":{"id":"06778","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.653672,41.438125],[-72.585657,41.309852],[-72.512034,41.341599],[-72.51395,41.346403],[-72.524062,41.376179],[-72.538782,41.421491],[-72.60896,41.44309],[-72.653672,41.438125]]]},"properties":{"id":"06419","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.472724,41.334209],[-72.435001,41.330042],[-72.417721,41.33687],[-72.436637,41.359853],[-72.472724,41.334209]]]},"properties":{"id":"06442","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.417721,41.33687],[-72.42713,41.363717],[-72.436637,41.359853],[-72.417721,41.33687]]]},"properties":{"id":"06409","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.529754,41.496965],[-72.52917,41.423724],[-72.450983,41.433665],[-72.468084,41.462392],[-72.529754,41.496965]]]},"properties":{"id":"06438","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.627836,41.640199],[-72.713897,41.627705],[-72.71521,41.625885],[-72.714538,41.60431],[-72.648802,41.575236],[-72.630524,41.633282],[-72.627836,41.640199]]]},"properties":{"id":"06416","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.818955,41.335652],[-72.745915,41.372234],[-72.72064,41.427397],[-72.746141,41.423297],[-72.817722,41.399449],[-72.817752,41.339982],[-72.818955,41.335652]]]},"properties":{"id":"06472","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.030962,41.553996],[-73.027248,41.555477],[-73.031004,41.55407],[-73.030962,41.553996]]],[[[-73.038561,41.560034],[-73.050051,41.55835],[-73.055624,41.569129],[-73.042264,41.551947],[-73.03226,41.554231],[-73.038561,41.560034]]]]},"properties":{"id":"06702","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.895386,41.383952],[-72.888845,41.332597],[-72.817752,41.339982],[-72.817722,41.399449],[-72.85136,41.434382],[-72.895386,41.383952]]]},"properties":{"id":"06473","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.077366,41.465189],[-73.025751,41.459596],[-73.001811,41.471159],[-73.01817,41.519834],[-73.05475,41.514636],[-73.070114,41.514301],[-73.113344,41.488503],[-73.077366,41.465189]]]},"properties":{"id":"06770","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.735241,41.252096],[-72.746059,41.310605],[-72.827278,41.317115],[-72.860296,41.244161],[-72.735241,41.252096]]]},"properties":{"id":"06405","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.083585,41.403766],[-73.041651,41.420896],[-73.025751,41.459596],[-73.077366,41.465189],[-73.083585,41.403766]]]},"properties":{"id":"06403","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.943498,41.428881],[-71.956484,41.326567],[-71.882131,41.325673],[-71.875299,41.416036],[-71.943498,41.428881]]]},"properties":{"id":"06378","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.219307,41.412123],[-72.189531,41.356333],[-72.178918,41.321778],[-72.100162,41.30187],[-72.121915,41.37135],[-72.169807,41.418317],[-72.219307,41.412123]]]},"properties":{"id":"06385","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.135416,41.504006],[-72.125889,41.560007],[-72.134981,41.569529],[-72.190843,41.589564],[-72.204161,41.570838],[-72.21911,41.538815],[-72.227624,41.518698],[-72.141983,41.504173],[-72.135416,41.504006]]]},"properties":{"id":"06334","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.406746,41.957164],[-72.492729,41.947497],[-72.51333,41.945401],[-72.51733,41.869897],[-72.49681,41.860616],[-72.428017,41.874598],[-72.306904,41.929627],[-72.306849,41.940601],[-72.406746,41.957164]]]},"properties":{"id":"06029","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.397431,42.033087],[-72.509192,42.034141],[-72.492729,41.947497],[-72.406746,41.957164],[-72.397431,42.033087]]]},"properties":{"id":"06071","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.21698,41.958161],[-72.306849,41.940601],[-72.306904,41.929627],[-72.309744,41.837772],[-72.310145,41.836059],[-72.217719,41.835184],[-72.21698,41.958161]]]},"properties":{"id":"06279","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.810311,41.666121],[-71.817305,41.641858],[-71.787658,41.639918],[-71.788715,41.695598],[-71.810311,41.666121]]]},"properties":{"id":"06373","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.906861,41.84846],[-71.912614,41.849305],[-71.910446,41.841137],[-71.903778,41.833357],[-71.901181,41.836972],[-71.908981,41.841919],[-71.906861,41.84846]]]},"properties":{"id":"06263","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.223072,41.176385],[-73.221057,41.139896],[-73.179855,41.152597],[-73.180455,41.171296],[-73.186832,41.194358],[-73.223129,41.202441],[-73.223072,41.176385]]]},"properties":{"id":"06604","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.386964,41.514794],[-73.441466,41.491294],[-73.426942,41.463834],[-73.411924,41.429532],[-73.405965,41.428194],[-73.378952,41.420523],[-73.331569,41.472677],[-73.386964,41.514794]]]},"properties":{"id":"06804","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.264795,41.214459],[-73.232111,41.16266],[-73.223072,41.176385],[-73.223129,41.202441],[-73.244168,41.226592],[-73.248556,41.231977],[-73.264795,41.214459]]]},"properties":{"id":"06825","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.526015,41.083458],[-73.517393,41.078172],[-73.500756,41.114414],[-73.5225,41.118888],[-73.535111,41.116235],[-73.526015,41.083458]]]},"properties":{"id":"06907","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.154731,41.184819],[-73.165755,41.154297],[-73.107779,41.168605],[-73.110572,41.20027],[-73.153819,41.192533],[-73.154731,41.184819]]]},"properties":{"id":"06615","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.387073,41.170495],[-73.381327,41.118509],[-73.383862,41.057697],[-73.297709,41.123823],[-73.306611,41.154347],[-73.338457,41.194539],[-73.379568,41.185777],[-73.387073,41.170495]]]},"properties":{"id":"06880","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.484898,41.211748],[-73.554783,41.179909],[-73.5225,41.118888],[-73.500756,41.114414],[-73.474565,41.114696],[-73.448906,41.153754],[-73.484898,41.211748]]]},"properties":{"id":"06840","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.71759,41.806623],[-72.797241,41.804699],[-72.796742,41.758799],[-72.789642,41.747769],[-72.737042,41.778535],[-72.720345,41.776059],[-72.712997,41.798969],[-72.71759,41.806623]]]},"properties":{"id":"06117","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.693827,41.765137],[-72.714782,41.756428],[-72.715145,41.749745],[-72.714561,41.72363],[-72.705938,41.7243],[-72.695948,41.725052],[-72.653888,41.752993],[-72.655936,41.7543],[-72.665114,41.765762],[-72.681831,41.76786],[-72.687962,41.766181],[-72.693827,41.765137]]]},"properties":{"id":"06106","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.712997,41.798969],[-72.686653,41.774746],[-72.686039,41.800168],[-72.71759,41.806623],[-72.712997,41.798969]]]},"properties":{"id":"06112","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.938037,41.590631],[-72.871824,41.55605],[-72.852707,41.546291],[-72.840244,41.569098],[-72.814989,41.64448],[-72.822573,41.65571],[-72.883388,41.649468],[-72.938501,41.644311],[-72.938037,41.590631]]]},"properties":{"id":"06489","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.51333,41.945401],[-72.577367,41.938657],[-72.587988,41.868313],[-72.51733,41.869897],[-72.51333,41.945401]]]},"properties":{"id":"06016","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.678331,42.035585],[-72.774739,42.002195],[-72.762939,41.985896],[-72.704318,41.949265],[-72.678331,42.035585]]]},"properties":{"id":"06093","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.933686,41.566263],[-72.944682,41.562862],[-72.945512,41.55958],[-72.933686,41.566263]]],[[[-72.933686,41.566263],[-72.917251,41.560075],[-72.871824,41.55605],[-72.938037,41.590631],[-72.942615,41.571126],[-72.933686,41.566263]],[[-72.90288,41.564256],[-72.901148,41.569893],[-72.899078,41.568242],[-72.900752,41.564339],[-72.90288,41.564256]]]]},"properties":{"id":"06479","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.867406,41.816404],[-72.861781,41.920458],[-72.876644,41.920796],[-72.870201,41.81613],[-72.867406,41.816404]]]},"properties":{"id":"06092","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.90288,41.564256],[-72.900752,41.564339],[-72.899078,41.568242],[-72.901148,41.569893],[-72.90288,41.564256]]]},"properties":{"id":"06467","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.023718,41.988131],[-73.038223,41.946888],[-72.976226,41.969645],[-73.023718,41.988131]]]},"properties":{"id":"06065","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.715296,41.652706],[-72.713897,41.627705],[-72.627836,41.640199],[-72.608632,41.681131],[-72.615735,41.688099],[-72.706039,41.668799],[-72.715296,41.652706]]]},"properties":{"id":"06067","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.389441,41.719771],[-73.382124,41.683327],[-73.375954,41.65801],[-73.27549,41.69522],[-73.275736,41.69905],[-73.293823,41.723437],[-73.391237,41.722976],[-73.389441,41.719771]]]},"properties":{"id":"06777","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.253509,41.604532],[-73.261446,41.664641],[-73.26063,41.676441],[-73.339779,41.595822],[-73.267336,41.603199],[-73.253509,41.604532]]]},"properties":{"id":"06793","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.468084,41.462392],[-72.450983,41.433665],[-72.43371,41.423995],[-72.305531,41.436282],[-72.319345,41.499047],[-72.394058,41.527995],[-72.477271,41.479329],[-72.468084,41.462392]]]},"properties":{"id":"06423","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.02698,41.252309],[-73.110572,41.20027],[-73.107779,41.168605],[-72.984234,41.233794],[-72.992051,41.244909],[-73.02698,41.252309]]]},"properties":{"id":"06460","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.991418,41.528893],[-73.030962,41.553996],[-73.031004,41.55407],[-73.03226,41.554231],[-73.042264,41.551947],[-73.05475,41.514636],[-73.01817,41.519834],[-72.991418,41.528893]]]},"properties":{"id":"06706","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.850797,41.516509],[-72.886667,41.447957],[-72.85136,41.434382],[-72.817722,41.399449],[-72.746141,41.423297],[-72.733886,41.485368],[-72.744742,41.497698],[-72.815181,41.50854],[-72.850797,41.516509]]]},"properties":{"id":"06492","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.086852,41.276596],[-73.02698,41.252309],[-72.992051,41.244909],[-72.989148,41.313297],[-72.998048,41.314097],[-73.040707,41.313891],[-73.067851,41.300996],[-73.086852,41.276596]]]},"properties":{"id":"06477","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.972909,41.347396],[-72.940562,41.335818],[-72.93519,41.335766],[-72.911343,41.372787],[-72.942564,41.407128],[-72.954873,41.394475],[-72.972909,41.347396]]]},"properties":{"id":"06514","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.911343,41.372787],[-72.93519,41.335766],[-72.891692,41.328471],[-72.888845,41.332597],[-72.895386,41.383952],[-72.911343,41.372787]]]},"properties":{"id":"06517","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.815181,41.50854],[-72.744742,41.497698],[-72.748878,41.544279],[-72.752181,41.578894],[-72.779721,41.575936],[-72.815181,41.50854]]]},"properties":{"id":"06450","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.15789,41.489409],[-73.113344,41.488503],[-73.070114,41.514301],[-73.093853,41.570196],[-73.145036,41.557822],[-73.15573,41.515279],[-73.15789,41.489409]]]},"properties":{"id":"06762","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.745915,41.372234],[-72.746059,41.310605],[-72.735241,41.252096],[-72.65375,41.264218],[-72.678739,41.433797],[-72.72064,41.427397],[-72.745915,41.372234]]]},"properties":{"id":"06437","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.169807,41.418317],[-72.121915,41.37135],[-72.09292,41.382586],[-72.095021,41.411598],[-72.095621,41.425298],[-72.146104,41.425468],[-72.169807,41.418317]]]},"properties":{"id":"06375","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.095021,41.411598],[-72.036985,41.399442],[-72.057797,41.480761],[-72.070021,41.463598],[-72.095621,41.425298],[-72.095021,41.411598]]]},"properties":{"id":"06335","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.252416,41.291209],[-72.251559,41.295405],[-72.257034,41.293153],[-72.252416,41.291209]]]},"properties":{"id":"06376","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.239559,41.714191],[-72.185111,41.675312],[-72.177844,41.690097],[-72.185995,41.720212],[-72.198968,41.731068],[-72.252643,41.728202],[-72.246596,41.722901],[-72.239559,41.714191]]]},"properties":{"id":"06226","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.410629,41.601798],[-72.32451,41.61857],[-72.334226,41.643598],[-72.344008,41.668712],[-72.42084,41.648053],[-72.410629,41.601798]]]},"properties":{"id":"06231","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.472281,41.79096],[-72.463899,41.745572],[-72.460129,41.727299],[-72.421727,41.730343],[-72.410251,41.765505],[-72.418891,41.806559],[-72.473468,41.798264],[-72.472281,41.79096]]]},"properties":{"id":"06043","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.931792,41.931574],[-71.797894,41.932364],[-71.853354,42.024066],[-71.935586,41.943518],[-71.931792,41.931574]]]},"properties":{"id":"06277","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.113995,41.83169],[-72.154297,41.834945],[-72.156321,41.753491],[-72.112045,41.745897],[-72.092446,41.83167],[-72.113995,41.83169]]]},"properties":{"id":"06235","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.484898,41.211748],[-73.448906,41.153754],[-73.422233,41.160029],[-73.387073,41.170495],[-73.379568,41.185777],[-73.430102,41.254328],[-73.43819,41.265187],[-73.502532,41.237228],[-73.484898,41.211748]]]},"properties":{"id":"06897","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.221057,41.139896],[-73.223072,41.176385],[-73.232111,41.16266],[-73.232635,41.142522],[-73.221057,41.139896]]]},"properties":{"id":"06605","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.599163,41.099018],[-73.615749,41.152171],[-73.65565,41.012678],[-73.599163,41.099018]]]},"properties":{"id":"06831","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.554783,41.179909],[-73.615749,41.152171],[-73.599163,41.099018],[-73.592448,41.090614],[-73.558607,41.105613],[-73.535111,41.116235],[-73.5225,41.118888],[-73.554783,41.179909]]]},"properties":{"id":"06903","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.541968,41.05886],[-73.545021,41.046709],[-73.529318,41.053663],[-73.533606,41.060388],[-73.541968,41.05886]]]},"properties":{"id":"06901","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.474565,41.114696],[-73.455805,41.093389],[-73.410582,41.107557],[-73.422233,41.160029],[-73.448906,41.153754],[-73.474565,41.114696]],[[-73.420598,41.110535],[-73.421149,41.110468],[-73.421301,41.11166],[-73.420222,41.111195],[-73.420598,41.110535]]]},"properties":{"id":"06850","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.2048,41.29936],[-73.146009,41.255807],[-73.086752,41.254596],[-73.086852,41.276596],[-73.067851,41.300996],[-73.124654,41.345296],[-73.147555,41.367696],[-73.157055,41.376696],[-73.2048,41.29936]]]},"properties":{"id":"06484","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.110572,41.20027],[-73.086752,41.254596],[-73.146009,41.255807],[-73.157636,41.228377],[-73.153819,41.192533],[-73.110572,41.20027]]]},"properties":{"id":"06614","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.99855,41.712498],[-72.98325,41.639598],[-72.938501,41.644311],[-72.883388,41.649468],[-72.892883,41.696693],[-72.897031,41.717272],[-72.89827,41.723177],[-72.99855,41.712498]]]},"properties":{"id":"06010","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.023718,41.988131],[-72.976226,41.969645],[-72.958743,41.970497],[-72.939775,42.038179],[-73.008745,42.038854],[-73.023718,41.988131]]]},"properties":{"id":"06091","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.78264,41.917997],[-72.762939,41.985896],[-72.774739,42.002195],[-72.808606,41.998389],[-72.836233,41.97415],[-72.825119,41.918879],[-72.78264,41.917997]]]},"properties":{"id":"06035","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.42084,41.648053],[-72.452229,41.688799],[-72.481504,41.667518],[-72.505431,41.646999],[-72.46673,41.583899],[-72.410629,41.601798],[-72.42084,41.648053]]]},"properties":{"id":"06447","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.933686,41.566263],[-72.942615,41.571126],[-72.944682,41.562862],[-72.933686,41.566263]]],[[[-72.933686,41.566263],[-72.945512,41.55958],[-72.94635,41.556797],[-72.917251,41.560075],[-72.933686,41.566263]]]]},"properties":{"id":"06444","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.766639,41.897597],[-72.798552,41.824885],[-72.797241,41.804699],[-72.71759,41.806623],[-72.686039,41.800168],[-72.686137,41.802899],[-72.738395,41.892645],[-72.760585,41.905705],[-72.766639,41.897597]]]},"properties":{"id":"06002","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.762179,41.718341],[-72.714561,41.72363],[-72.715145,41.749745],[-72.73429,41.748691],[-72.762346,41.726854],[-72.762179,41.718341]]]},"properties":{"id":"06110","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.769405,41.917829],[-72.760585,41.905705],[-72.738395,41.892645],[-72.695906,41.922431],[-72.687636,41.948096],[-72.704318,41.949265],[-72.762939,41.985896],[-72.78264,41.917997],[-72.769405,41.917829]]]},"properties":{"id":"06026","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.815181,41.50854],[-72.779721,41.575936],[-72.840244,41.569098],[-72.852707,41.546291],[-72.850797,41.516509],[-72.815181,41.50854]]]},"properties":{"id":"06451","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.930231,41.835807],[-72.928898,41.834367],[-72.92873,41.835329],[-72.930231,41.835807]]]},"properties":{"id":"06020","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.122017,41.776558],[-73.114075,41.747389],[-73.068402,41.707093],[-73.055262,41.70816],[-72.99895,41.714398],[-73.016936,41.798183],[-73.076683,41.798451],[-73.122017,41.776558]]]},"properties":{"id":"06791","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.957767,41.970548],[-72.956569,41.935817],[-72.927377,41.951109],[-72.929254,41.971524],[-72.957767,41.970548]]]},"properties":{"id":"06059","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.222342,41.710576],[-73.293933,41.739449],[-73.293823,41.723437],[-73.275736,41.69905],[-73.222342,41.710576]]]},"properties":{"id":"06750","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.940647,41.895196],[-72.98451,41.892155],[-72.977396,41.878238],[-72.967852,41.879199],[-72.964736,41.878582],[-72.964085,41.87565],[-72.967872,41.875458],[-72.970922,41.877548],[-72.978041,41.87763],[-72.985282,41.892099],[-73.053534,41.887324],[-73.076683,41.798451],[-73.016936,41.798183],[-72.949024,41.806431],[-72.940647,41.895196]]]},"properties":{"id":"06057","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.236077,42.029535],[-73.232944,42.045075],[-73.340659,42.048291],[-73.357487,42.005014],[-73.328209,42.000802],[-73.236077,42.029535]]]},"properties":{"id":"06018","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.430767,41.524567],[-72.477271,41.479329],[-72.394058,41.527995],[-72.430767,41.524567]]]},"properties":{"id":"06469","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.46673,41.583899],[-72.430767,41.524567],[-72.394058,41.527995],[-72.319345,41.499047],[-72.21911,41.538815],[-72.32451,41.61857],[-72.410629,41.601798],[-72.46673,41.583899]]]},"properties":{"id":"06415","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.727175,41.601994],[-72.752181,41.578894],[-72.748878,41.544279],[-72.700899,41.54891],[-72.686435,41.535126],[-72.677896,41.49623],[-72.633701,41.500201],[-72.554912,41.51105],[-72.560802,41.554049],[-72.648802,41.575236],[-72.714538,41.60431],[-72.727175,41.601994]]]},"properties":{"id":"06457","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.554912,41.51105],[-72.633701,41.500201],[-72.60896,41.44309],[-72.538782,41.421491],[-72.52917,41.423724],[-72.529754,41.496965],[-72.554912,41.51105]]]},"properties":{"id":"06441","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.748878,41.544279],[-72.744742,41.497698],[-72.733886,41.485368],[-72.677896,41.49623],[-72.686435,41.535126],[-72.697909,41.520574],[-72.713589,41.52882],[-72.700899,41.54891],[-72.748878,41.544279]]]},"properties":{"id":"06455","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.083585,41.403766],[-73.147555,41.367696],[-73.124654,41.345296],[-73.102353,41.352896],[-73.047162,41.360531],[-73.047543,41.395834],[-73.041651,41.420896],[-73.083585,41.403766]]]},"properties":{"id":"06483","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.972909,41.347396],[-72.998048,41.314097],[-72.989148,41.313297],[-72.956296,41.310459],[-72.940562,41.335818],[-72.972909,41.347396]]]},"properties":{"id":"06515","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.030962,41.553996],[-72.991418,41.528893],[-72.969365,41.530234],[-72.956788,41.555295],[-73.003675,41.575512],[-73.027248,41.555477],[-73.030962,41.553996]]]},"properties":{"id":"06705","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.891692,41.328471],[-72.904491,41.29923],[-72.82263,41.322882],[-72.818955,41.335652],[-72.817752,41.339982],[-72.888845,41.332597],[-72.891692,41.328471]]]},"properties":{"id":"06513","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.081321,41.312715],[-71.980935,41.310154],[-72.019827,41.399267],[-72.036985,41.399442],[-72.095021,41.411598],[-72.09292,41.382586],[-72.081321,41.312715]]]},"properties":{"id":"06340","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.082519,41.695137],[-72.120328,41.661925],[-72.104121,41.600798],[-72.047864,41.594823],[-72.031216,41.647242],[-72.056104,41.701203],[-72.073753,41.698653],[-72.082519,41.695137]],[[-72.066223,41.643714],[-72.075008,41.65277],[-72.067599,41.652352],[-72.063447,41.6485],[-72.066223,41.643714]]]},"properties":{"id":"06330","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.141218,41.455866],[-72.147012,41.463539],[-72.157443,41.463472],[-72.141218,41.455866]]]},"properties":{"id":"06353","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.328872,41.738819],[-72.410251,41.765505],[-72.421727,41.730343],[-72.356478,41.701497],[-72.328872,41.738819]]]},"properties":{"id":"06232","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.421527,41.825498],[-72.428017,41.874598],[-72.49681,41.860616],[-72.50543,41.820298],[-72.473468,41.798264],[-72.418891,41.806559],[-72.421527,41.825498]]]},"properties":{"id":"06066","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.246596,41.722901],[-72.328872,41.738819],[-72.356478,41.701497],[-72.344008,41.668712],[-72.334226,41.643598],[-72.239559,41.714191],[-72.246596,41.722901]]]},"properties":{"id":"06237","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.906431,41.741896],[-71.914688,41.74744],[-71.915308,41.746706],[-71.906158,41.741502],[-71.906431,41.741896]]]},"properties":{"id":"06387","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.410582,41.107557],[-73.428859,41.052384],[-73.383862,41.057697],[-73.381327,41.118509],[-73.410582,41.107557]]]},"properties":{"id":"06855","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.578941,41.057631],[-73.587608,41.007684],[-73.550927,41.022187],[-73.57649,41.070182],[-73.578941,41.057631]]]},"properties":{"id":"06870","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.737042,41.778535],[-72.73429,41.748691],[-72.715145,41.749745],[-72.714782,41.756428],[-72.720345,41.776059],[-72.737042,41.778535]]]},"properties":{"id":"06119","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.715296,41.652706],[-72.750357,41.650167],[-72.789623,41.647553],[-72.814989,41.64448],[-72.840244,41.569098],[-72.779721,41.575936],[-72.752181,41.578894],[-72.727175,41.601994],[-72.71521,41.625885],[-72.713897,41.627705],[-72.715296,41.652706]]]},"properties":{"id":"06037","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.796742,41.758799],[-72.867027,41.763724],[-72.897031,41.717272],[-72.892883,41.696693],[-72.832508,41.690434],[-72.761969,41.715795],[-72.762179,41.718341],[-72.762346,41.726854],[-72.789642,41.747769],[-72.796742,41.758799]]]},"properties":{"id":"06032","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.939775,42.038179],[-72.958743,41.970497],[-72.957767,41.970548],[-72.929254,41.971524],[-72.88706,41.973121],[-72.880943,41.990193],[-72.874428,42.037672],[-72.939775,42.038179]]]},"properties":{"id":"06027","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.892883,41.696693],[-72.883388,41.649468],[-72.822573,41.65571],[-72.825418,41.668938],[-72.832508,41.690434],[-72.892883,41.696693]]]},"properties":{"id":"06062","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.647836,41.727899],[-72.695948,41.725052],[-72.705938,41.7243],[-72.706039,41.668799],[-72.615735,41.688099],[-72.636335,41.724899],[-72.647836,41.727899]]]},"properties":{"id":"06109","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.93346,41.880157],[-72.938026,41.853354],[-72.913349,41.858694],[-72.93346,41.880157]]]},"properties":{"id":"06022","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.578472,41.774015],[-72.571233,41.733799],[-72.463899,41.745572],[-72.472281,41.79096],[-72.579949,41.782189],[-72.578472,41.774015]]]},"properties":{"id":"06040","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.222342,41.710576],[-73.141354,41.672749],[-73.134716,41.675557],[-73.114075,41.747389],[-73.122017,41.776558],[-73.197524,41.804514],[-73.288695,41.823895],[-73.322117,41.783731],[-73.293933,41.739449],[-73.222342,41.710576]]]},"properties":{"id":"06759","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.271543,41.886333],[-73.390807,41.918871],[-73.38314,41.833323],[-73.322117,41.783731],[-73.288695,41.823895],[-73.271543,41.886333]]]},"properties":{"id":"06796","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.141531,41.672709],[-73.058074,41.615574],[-73.058024,41.60674],[-73.050362,41.609748],[-73.055724,41.702891],[-73.055262,41.70816],[-73.068402,41.707093],[-73.134716,41.675557],[-73.141354,41.672749],[-73.141531,41.672709]]]},"properties":{"id":"06787","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.400247,41.746594],[-73.501858,41.669545],[-73.456332,41.674974],[-73.40845,41.680004],[-73.389441,41.719771],[-73.391237,41.722976],[-73.400247,41.746594]]]},"properties":{"id":"06785","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.340659,42.048291],[-73.489126,42.011661],[-73.399299,41.959288],[-73.357487,42.005014],[-73.340659,42.048291]]]},"properties":{"id":"06068","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.089113,41.575654],[-73.058074,41.615574],[-73.141531,41.672709],[-73.170756,41.669196],[-73.172763,41.611613],[-73.145036,41.557822],[-73.093853,41.570196],[-73.089113,41.575654]]]},"properties":{"id":"06795","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.633701,41.500201],[-72.677896,41.49623],[-72.733886,41.485368],[-72.746141,41.423297],[-72.72064,41.427397],[-72.678739,41.433797],[-72.653672,41.438125],[-72.60896,41.44309],[-72.633701,41.500201]]]},"properties":{"id":"06422","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.485662,41.269024],[-72.512034,41.341599],[-72.585657,41.309852],[-72.532709,41.252791],[-72.485662,41.269024]]]},"properties":{"id":"06413","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.425663,41.400805],[-72.524062,41.376179],[-72.51395,41.346403],[-72.472724,41.334209],[-72.436637,41.359853],[-72.42713,41.363717],[-72.382594,41.38248],[-72.425663,41.400805]]]},"properties":{"id":"06417","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.552657,41.562122],[-72.554912,41.51105],[-72.529754,41.496965],[-72.468084,41.462392],[-72.477271,41.479329],[-72.430767,41.524567],[-72.46673,41.583899],[-72.505431,41.646999],[-72.54423,41.642696],[-72.559523,41.562355],[-72.552657,41.562122]]]},"properties":{"id":"06424","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.554912,41.51105],[-72.552657,41.562122],[-72.561992,41.556175],[-72.560802,41.554049],[-72.554912,41.51105]]]},"properties":{"id":"06456","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.940562,41.335818],[-72.956296,41.310459],[-72.951085,41.297262],[-72.931444,41.303579],[-72.921372,41.314352],[-72.918439,41.313173],[-72.928462,41.302457],[-72.905184,41.298252],[-72.904491,41.29923],[-72.891692,41.328471],[-72.93519,41.335766],[-72.940562,41.335818]]]},"properties":{"id":"06511","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.050051,41.55835],[-73.053761,41.569814],[-73.041954,41.572154],[-73.038561,41.560034],[-73.03226,41.554231],[-73.031004,41.55407],[-73.027248,41.555477],[-73.003675,41.575512],[-73.01865,41.614097],[-73.034777,41.615134],[-73.050362,41.609748],[-73.058024,41.60674],[-73.064209,41.588491],[-73.055624,41.569129],[-73.050051,41.55835]]]},"properties":{"id":"06704","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.938037,41.590631],[-72.938501,41.644311],[-72.98325,41.639598],[-73.01865,41.614097],[-73.003675,41.575512],[-72.956788,41.555295],[-72.94635,41.556797],[-72.945512,41.55958],[-72.944682,41.562862],[-72.942615,41.571126],[-72.938037,41.590631]]]},"properties":{"id":"06716","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.045326,41.573023],[-72.063583,41.570328],[-72.061225,41.560779],[-72.045616,41.557014],[-72.045326,41.573023]]]},"properties":{"id":"06380","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.276636,41.440449],[-72.254139,41.309787],[-72.189531,41.356333],[-72.219307,41.412123],[-72.242024,41.443198],[-72.276636,41.440449]]]},"properties":{"id":"06333","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.954983,41.634799],[-72.031216,41.647242],[-72.047864,41.594823],[-72.045326,41.573023],[-72.045616,41.557014],[-72.04612,41.555998],[-71.90286,41.520262],[-71.863408,41.514926],[-71.884422,41.641204],[-71.954983,41.634799]]]},"properties":{"id":"06351","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.194024,41.590399],[-72.204161,41.570838],[-72.190843,41.589564],[-72.194024,41.590399]]]},"properties":{"id":"06336","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.04612,41.555998],[-72.08102,41.492099],[-72.070021,41.463598],[-72.057797,41.480761],[-71.956618,41.487398],[-71.90286,41.520262],[-72.04612,41.555998]]]},"properties":{"id":"06365","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.428017,41.874598],[-72.421527,41.825498],[-72.309744,41.837772],[-72.306904,41.929627],[-72.428017,41.874598]]]},"properties":{"id":"06084","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.151654,41.959302],[-72.113995,41.83169],[-72.092446,41.83167],[-72.044738,41.831548],[-72.06029,41.91455],[-72.067943,41.914652],[-72.097024,41.914994],[-72.099525,41.966038],[-72.151654,41.959302]]]},"properties":{"id":"06242","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.935586,41.943518],[-71.853354,42.024066],[-71.928856,42.02532],[-71.959598,42.016717],[-71.935586,41.943518]]]},"properties":{"id":"06255","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.112045,41.745897],[-72.156321,41.753491],[-72.198968,41.731068],[-72.185995,41.720212],[-72.1185,41.725365],[-72.112045,41.745897]]]},"properties":{"id":"06256","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.185111,41.675312],[-72.157546,41.657627],[-72.157472,41.657798],[-72.177844,41.690097],[-72.185111,41.675312]]]},"properties":{"id":"06266","state_code":"09"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.548439,39.070761],[-75.509085,39.038666],[-75.457292,39.085619],[-75.499299,39.10462],[-75.517419,39.094846],[-75.548439,39.070761]]]},"properties":{"id":"19962","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.57819,39.222826],[-75.586164,39.21936],[-75.583246,39.215553],[-75.57819,39.222826]]]},"properties":{"id":"19936","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.62259,39.694655],[-75.65509,39.660127],[-75.652717,39.556653],[-75.648497,39.554707],[-75.647125,39.554004],[-75.58326,39.558107],[-75.57981,39.580067],[-75.521385,39.696751],[-75.56047,39.723781],[-75.621231,39.69738],[-75.62259,39.694655]]]},"properties":{"id":"19720","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.575294,39.759303],[-75.558082,39.746997],[-75.549181,39.753846],[-75.549115,39.76277],[-75.566235,39.778349],[-75.578195,39.773231],[-75.575294,39.759303]]]},"properties":{"id":"19806","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.564076,39.735312],[-75.618538,39.730482],[-75.682692,39.699438],[-75.679186,39.697694],[-75.621231,39.69738],[-75.56047,39.723781],[-75.564076,39.735312]]]},"properties":{"id":"19804","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.56047,39.723781],[-75.521385,39.696751],[-75.508185,39.714354],[-75.526507,39.744202],[-75.549181,39.753846],[-75.558082,39.746997],[-75.564076,39.735312],[-75.56047,39.723781]]]},"properties":{"id":"19801","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.658776,39.822927],[-75.650027,39.764632],[-75.618867,39.755955],[-75.575294,39.759303],[-75.578195,39.773231],[-75.580324,39.79158],[-75.579646,39.83888],[-75.658776,39.822927]],[[-75.589483,39.790057],[-75.590413,39.795887],[-75.585611,39.795313],[-75.589483,39.790057]],[[-75.603554,39.806374],[-75.595898,39.802733],[-75.602977,39.80048],[-75.603554,39.806374]]]},"properties":{"id":"19807","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466711,38.612896],[-75.406317,38.558413],[-75.246753,38.670528],[-75.288358,38.735215],[-75.399863,38.775876],[-75.466229,38.747343],[-75.531308,38.690974],[-75.466711,38.612896]]]},"properties":{"id":"19947","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.103195,38.606862],[-75.33847,38.526594],[-75.141631,38.551889],[-75.103195,38.606862]]]},"properties":{"id":"19939","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.58326,39.558107],[-75.559117,39.561444],[-75.57981,39.580067],[-75.58326,39.558107]]]},"properties":{"id":"19706","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.618538,39.730482],[-75.618867,39.755955],[-75.650027,39.764632],[-75.702694,39.750902],[-75.682692,39.699438],[-75.618538,39.730482]]]},"properties":{"id":"19808","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.603554,39.806374],[-75.602977,39.80048],[-75.595898,39.802733],[-75.603554,39.806374]]]},"properties":{"id":"19735","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.589483,39.790057],[-75.585611,39.795313],[-75.590413,39.795887],[-75.589483,39.790057]]]},"properties":{"id":"19710","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.711834,38.691238],[-75.531308,38.690974],[-75.466229,38.747343],[-75.516091,38.768496],[-75.717704,38.765598],[-75.711834,38.691238]]]},"properties":{"id":"19933","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.613256,38.580478],[-75.634083,38.580709],[-75.624997,38.564568],[-75.613256,38.580478]]]},"properties":{"id":"19931","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.509085,39.038666],[-75.480267,39.001106],[-75.39943,39.066299],[-75.457292,39.085619],[-75.509085,39.038666]]]},"properties":{"id":"19946","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.717704,38.765598],[-75.516091,38.768496],[-75.493062,38.806464],[-75.476084,38.87275],[-75.488458,38.897428],[-75.53622,38.849939],[-75.724817,38.85544],[-75.717704,38.765598]]]},"properties":{"id":"19950","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.78888,39.653912],[-75.679186,39.697694],[-75.682692,39.699438],[-75.702694,39.750902],[-75.732523,39.779536],[-75.78888,39.653912]],[[-75.759839,39.687888],[-75.759623,39.691523],[-75.755622,39.689833],[-75.759839,39.687888]],[[-75.753529,39.676512],[-75.746812,39.678254],[-75.745652,39.674982],[-75.753529,39.676512]]]},"properties":{"id":"19711","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.521435,39.775396],[-75.490472,39.793225],[-75.527392,39.837828],[-75.579646,39.83888],[-75.580324,39.79158],[-75.566235,39.778349],[-75.549115,39.76277],[-75.521435,39.775396]]]},"properties":{"id":"19803","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.732523,39.779536],[-75.702694,39.750902],[-75.650027,39.764632],[-75.658776,39.822927],[-75.680962,39.813704],[-75.680038,39.809963],[-75.6758,39.808228],[-75.672607,39.808315],[-75.676031,39.800901],[-75.677661,39.800778],[-75.675609,39.805322],[-75.676037,39.80774],[-75.680827,39.809216],[-75.681325,39.813532],[-75.732523,39.779536]]]},"properties":{"id":"19707","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.753529,39.676512],[-75.745652,39.674982],[-75.746812,39.678254],[-75.753529,39.676512]]]},"properties":{"id":"19717","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.698022,38.515524],[-75.418666,38.452571],[-75.428466,38.471156],[-75.698022,38.515524]]]},"properties":{"id":"19940","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.226368,38.840501],[-75.267229,38.728511],[-75.166798,38.657136],[-75.102708,38.644499],[-75.074281,38.739758],[-75.218213,38.840561],[-75.226368,38.840501]]]},"properties":{"id":"19958","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.551137,39.095368],[-75.578128,39.054737],[-75.570327,39.05675],[-75.563226,39.073903],[-75.551137,39.095368]]],[[[-75.532565,39.123798],[-75.583246,39.215553],[-75.586164,39.21936],[-75.57819,39.222826],[-75.588101,39.244031],[-75.645341,39.231261],[-75.66838,39.184024],[-75.632801,39.124902],[-75.532565,39.123798]]]]},"properties":{"id":"19904","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.488458,38.897428],[-75.505569,38.927506],[-75.53622,38.849939],[-75.488458,38.897428]]]},"properties":{"id":"19954","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.5854,39.059123],[-75.581841,39.038961],[-75.560172,39.037014],[-75.570327,39.05675],[-75.578128,39.054737],[-75.5854,39.059123]]]},"properties":{"id":"19979","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.66338,39.444311],[-75.767868,39.393334],[-75.760431,39.30128],[-75.684967,39.330767],[-75.512106,39.36583],[-75.576711,39.450728],[-75.653403,39.451838],[-75.66338,39.444311]]]},"properties":{"id":"19734","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.531308,38.690974],[-75.711834,38.691238],[-75.701569,38.560736],[-75.624997,38.564568],[-75.634083,38.580709],[-75.613256,38.580478],[-75.466711,38.612896],[-75.531308,38.690974]]]},"properties":{"id":"19973","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.226368,38.840501],[-75.32433,38.85078],[-75.34512,38.828534],[-75.399863,38.775876],[-75.288358,38.735215],[-75.267229,38.728511],[-75.226368,38.840501]]]},"properties":{"id":"19968","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.131335,38.547848],[-75.131425,38.541089],[-75.119978,38.527354],[-75.131335,38.547848]]]},"properties":{"id":"19967","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.05952,38.49625],[-75.049268,38.451251],[-75.047922,38.609315],[-75.0633,38.608329],[-75.073392,38.529831],[-75.05952,38.49625]]]},"properties":{"id":"19930","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.645341,39.231261],[-75.684967,39.330767],[-75.760431,39.30128],[-75.75226,39.199459],[-75.66838,39.184024],[-75.645341,39.231261]],[[-75.667421,39.22876],[-75.665715,39.226453],[-75.670879,39.222719],[-75.667421,39.22876]]]},"properties":{"id":"19938","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.679186,39.697694],[-75.78888,39.653912],[-75.787775,39.639005],[-75.62259,39.694655],[-75.621231,39.69738],[-75.679186,39.697694]]]},"properties":{"id":"19713","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.65509,39.660127],[-75.753731,39.540343],[-75.647125,39.554004],[-75.648497,39.554707],[-75.651542,39.553622],[-75.652717,39.556653],[-75.65509,39.660127]]]},"properties":{"id":"19701","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.773766,39.466255],[-75.771448,39.473805],[-75.774946,39.480951],[-75.773766,39.466255]]]},"properties":{"id":"21912","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466711,38.612896],[-75.613256,38.580478],[-75.624997,38.564568],[-75.701569,38.560736],[-75.698022,38.515524],[-75.428466,38.471156],[-75.406317,38.558413],[-75.466711,38.612896]]]},"properties":{"id":"19956","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.288358,38.735215],[-75.246753,38.670528],[-75.166798,38.657136],[-75.267229,38.728511],[-75.288358,38.735215]]]},"properties":{"id":"19951","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.517419,39.094846],[-75.551137,39.095368],[-75.563226,39.073903],[-75.548439,39.070761],[-75.517419,39.094846]]],[[[-75.583246,39.215553],[-75.532565,39.123798],[-75.499299,39.10462],[-75.457292,39.085619],[-75.39943,39.066299],[-75.392932,39.067599],[-75.406236,39.254442],[-75.588101,39.244031],[-75.57819,39.222826],[-75.583246,39.215553]],[[-75.472407,39.117018],[-75.487973,39.125815],[-75.488869,39.132267],[-75.473022,39.123257],[-75.472407,39.117018]]]]},"properties":{"id":"19901","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.740772,39.055831],[-75.702156,39.107957],[-75.747761,39.143338],[-75.740772,39.055831]]]},"properties":{"id":"19964","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.684967,39.330767],[-75.645341,39.231261],[-75.588101,39.244031],[-75.406236,39.254442],[-75.512106,39.36583],[-75.684967,39.330767]]]},"properties":{"id":"19977","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.481099,39.804137],[-75.468868,39.779448],[-75.459739,39.822743],[-75.481099,39.804137]]]},"properties":{"id":"19703","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.564076,39.735312],[-75.558082,39.746997],[-75.575294,39.759303],[-75.618867,39.755955],[-75.618538,39.730482],[-75.564076,39.735312]]]},"properties":{"id":"19805","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.653403,39.451838],[-75.645171,39.46805],[-75.665048,39.458214],[-75.66338,39.444311],[-75.653403,39.451838]]]},"properties":{"id":"19730","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.779382,39.535554],[-75.774946,39.480951],[-75.771448,39.473805],[-75.773766,39.466255],[-75.767868,39.393334],[-75.66338,39.444311],[-75.665048,39.458214],[-75.645171,39.46805],[-75.653403,39.451838],[-75.576711,39.450728],[-75.559117,39.561444],[-75.58326,39.558107],[-75.647125,39.554004],[-75.753731,39.540343],[-75.779382,39.535554]],[[-75.578428,39.529945],[-75.577628,39.51337],[-75.591818,39.526604],[-75.578428,39.529945]]]},"properties":{"id":"19709","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.681325,39.813532],[-75.680827,39.809216],[-75.676037,39.80774],[-75.675609,39.805322],[-75.677661,39.800778],[-75.676031,39.800901],[-75.672607,39.808315],[-75.6758,39.808228],[-75.680038,39.809963],[-75.680962,39.813704],[-75.681325,39.813532]]]},"properties":{"id":"19736","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.759839,39.687888],[-75.755622,39.689833],[-75.759623,39.691523],[-75.759839,39.687888]]]},"properties":{"id":"19716","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.33847,38.526594],[-75.103195,38.606862],[-75.102708,38.644499],[-75.166798,38.657136],[-75.246753,38.670528],[-75.406317,38.558413],[-75.428466,38.471156],[-75.418666,38.452571],[-75.34039,38.452028],[-75.33847,38.526594]]]},"properties":{"id":"19966","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.34512,38.828534],[-75.493062,38.806464],[-75.516091,38.768496],[-75.466229,38.747343],[-75.399863,38.775876],[-75.34512,38.828534]]]},"properties":{"id":"19941","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.747761,39.143338],[-75.702156,39.107957],[-75.632801,39.124902],[-75.66838,39.184024],[-75.75226,39.199459],[-75.747761,39.143338]]]},"properties":{"id":"19953","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.532565,39.123798],[-75.632801,39.124902],[-75.702156,39.107957],[-75.740772,39.055831],[-75.739799,39.04362],[-75.5854,39.059123],[-75.578128,39.054737],[-75.551137,39.095368],[-75.517419,39.094846],[-75.499299,39.10462],[-75.532565,39.123798]]]},"properties":{"id":"19934","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.563226,39.073903],[-75.570327,39.05675],[-75.560172,39.037014],[-75.581841,39.038961],[-75.5854,39.059123],[-75.739799,39.04362],[-75.734675,38.979353],[-75.493039,38.967379],[-75.480267,39.001106],[-75.509085,39.038666],[-75.548439,39.070761],[-75.563226,39.073903]]]},"properties":{"id":"19943","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.472407,39.117018],[-75.473022,39.123257],[-75.488869,39.132267],[-75.487973,39.125815],[-75.472407,39.117018]]]},"properties":{"id":"19902","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.734675,38.979353],[-75.724817,38.85544],[-75.53622,38.849939],[-75.505569,38.927506],[-75.493039,38.967379],[-75.734675,38.979353]]]},"properties":{"id":"19952","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.753731,39.540343],[-75.65509,39.660127],[-75.62259,39.694655],[-75.787775,39.639005],[-75.779382,39.535554],[-75.753731,39.540343]]]},"properties":{"id":"19702","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.521435,39.775396],[-75.526507,39.744202],[-75.508185,39.714354],[-75.468868,39.779448],[-75.481099,39.804137],[-75.490472,39.793225],[-75.521435,39.775396]]]},"properties":{"id":"19809","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.527392,39.837828],[-75.490472,39.793225],[-75.481099,39.804137],[-75.459739,39.822743],[-75.527392,39.837828]]]},"properties":{"id":"19810","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.566235,39.778349],[-75.580324,39.79158],[-75.578195,39.773231],[-75.566235,39.778349]]]},"properties":{"id":"19732","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.493062,38.806464],[-75.34512,38.828534],[-75.32433,38.85078],[-75.476084,38.87275],[-75.493062,38.806464]]]},"properties":{"id":"19960","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.062223,38.474919],[-75.065393,38.451242],[-75.049268,38.451251],[-75.05952,38.49625],[-75.062223,38.474919]]]},"properties":{"id":"19944","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.476084,38.87275],[-75.32433,38.85078],[-75.226368,38.840501],[-75.218213,38.840561],[-75.392932,39.067599],[-75.39943,39.066299],[-75.480267,39.001106],[-75.493039,38.967379],[-75.505569,38.927506],[-75.488458,38.897428],[-75.476084,38.87275]]]},"properties":{"id":"19963","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.667421,39.22876],[-75.670879,39.222719],[-75.665715,39.226453],[-75.667421,39.22876]]]},"properties":{"id":"19955","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.521435,39.775396],[-75.549115,39.76277],[-75.549181,39.753846],[-75.526507,39.744202],[-75.521435,39.775396]]]},"properties":{"id":"19802","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.578428,39.529945],[-75.591818,39.526604],[-75.577628,39.51337],[-75.578428,39.529945]]]},"properties":{"id":"19731","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.648497,39.554707],[-75.652717,39.556653],[-75.651542,39.553622],[-75.648497,39.554707]]]},"properties":{"id":"19733","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.073392,38.529831],[-75.119978,38.527354],[-75.131425,38.541089],[-75.131335,38.547848],[-75.141631,38.551889],[-75.33847,38.526594],[-75.34039,38.452028],[-75.273027,38.451537],[-75.062223,38.474919],[-75.05952,38.49625],[-75.073392,38.529831]]]},"properties":{"id":"19945","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.074281,38.739758],[-75.102708,38.644499],[-75.103195,38.606862],[-75.0633,38.608329],[-75.047922,38.609315],[-75.074281,38.739758]]]},"properties":{"id":"19971","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.062223,38.474919],[-75.273027,38.451537],[-75.065393,38.451242],[-75.062223,38.474919]]]},"properties":{"id":"19975","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.131335,38.547848],[-75.119978,38.527354],[-75.073392,38.529831],[-75.0633,38.608329],[-75.103195,38.606862],[-75.141631,38.551889],[-75.131335,38.547848]]]},"properties":{"id":"19970","state_code":"10"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.052578,38.888393],[-77.019111,38.850177],[-77.018972,38.850432],[-77.003673,38.868043],[-77.005682,38.868856],[-77.007406,38.869555],[-77.010125,38.867284],[-77.009059,38.882065],[-77.012231,38.88299],[-77.010791,38.885079],[-77.009065,38.887588],[-77.012227,38.890834],[-77.015181,38.891448],[-77.0219,38.892078],[-77.031964,38.892093],[-77.033653,38.89209],[-77.039481,38.8921],[-77.041731,38.8921],[-77.044938,38.892101],[-77.046676,38.892101],[-77.048849,38.892102],[-77.052578,38.888393]],[[-77.013661,38.885059],[-77.015201,38.885072],[-77.015202,38.886047],[-77.013655,38.886049],[-77.013661,38.885059]],[[-77.024016,38.887566],[-77.021916,38.887563],[-77.019907,38.887567],[-77.019912,38.886886],[-77.021912,38.886322],[-77.024016,38.887566]],[[-77.024016,38.887566],[-77.028073,38.887565],[-77.026123,38.889064],[-77.024016,38.887566]],[[-77.028073,38.887565],[-77.029617,38.884976],[-77.031962,38.884966],[-77.031952,38.887567],[-77.028073,38.887565]],[[-77.025982,38.88249],[-77.025974,38.884847],[-77.024021,38.884845],[-77.023994,38.882546],[-77.025982,38.88249]],[[-77.015148,38.872037],[-77.015541,38.860767],[-77.019713,38.856371],[-77.020205,38.868862],[-77.015148,38.872037]]]},"properties":{"id":"20024","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.031963,38.897349],[-77.035117,38.898761],[-77.037712,38.895391],[-77.039464,38.895397],[-77.039481,38.8921],[-77.033653,38.89209],[-77.033645,38.895479],[-77.031955,38.895499],[-77.031964,38.892093],[-77.0219,38.892078],[-77.019899,38.892759],[-77.027034,38.898316],[-77.029619,38.897339],[-77.02964,38.896126],[-77.031962,38.896105],[-77.031963,38.897349]],[[-77.026002,38.894444],[-77.023972,38.894796],[-77.023975,38.893877],[-77.026002,38.894444]]]},"properties":{"id":"20004","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.054871,38.897347],[-77.056456,38.89704],[-77.055569,38.893094],[-77.053687,38.894763],[-77.054871,38.897347]]]},"properties":{"id":"20566","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.026002,38.894444],[-77.023975,38.893877],[-77.023972,38.894796],[-77.026002,38.894444]]]},"properties":{"id":"20535","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.044938,38.892101],[-77.046675,38.8935],[-77.046676,38.892101],[-77.044938,38.892101]]]},"properties":{"id":"20551","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.004325,38.967327],[-77.043491,38.961335],[-77.04994,38.944295],[-77.050526,38.933317],[-77.019033,38.937398],[-77.008034,38.942381],[-76.987833,38.954265],[-77.004325,38.967327]]]},"properties":{"id":"20011","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.941923,38.918584],[-76.977768,38.946358],[-76.99513,38.923796],[-76.943858,38.916871],[-76.941923,38.918584]]]},"properties":{"id":"20018","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.069269,38.900582],[-77.052578,38.888393],[-77.048849,38.892102],[-77.048859,38.8935],[-77.048023,38.896017],[-77.046653,38.899577],[-77.047348,38.89958],[-77.047271,38.900694],[-77.046651,38.900693],[-77.046653,38.901318],[-77.048831,38.902088],[-77.046649,38.902526],[-77.046647,38.903742],[-77.046634,38.910338],[-77.050146,38.909635],[-77.069269,38.900582]],[[-77.054871,38.897347],[-77.053687,38.894763],[-77.055569,38.893094],[-77.056456,38.89704],[-77.054871,38.897347]]]},"properties":{"id":"20037","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.013661,38.885059],[-77.013655,38.886049],[-77.015202,38.886047],[-77.015201,38.885072],[-77.013661,38.885059]]]},"properties":{"id":"20204","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.046651,38.900693],[-77.047271,38.900694],[-77.047348,38.89958],[-77.046653,38.899577],[-77.046651,38.900693]]]},"properties":{"id":"20052","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.026499,38.977286],[-77.031844,38.977988],[-77.034018,38.97372],[-77.029073,38.972516],[-77.026499,38.977286]]]},"properties":{"id":"20307","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.027408,38.928286],[-77.009044,38.926667],[-77.006952,38.931259],[-77.019033,38.937398],[-77.050526,38.933317],[-77.044785,38.927452],[-77.027408,38.928286]]]},"properties":{"id":"20010","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.006952,38.931259],[-77.008034,38.942381],[-77.019033,38.937398],[-77.006952,38.931259]]]},"properties":{"id":"20317","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.003446,38.860689],[-77.003673,38.868043],[-77.018972,38.850432],[-77.003446,38.860689]]]},"properties":{"id":"20373","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.043474,38.893514],[-77.041731,38.8921],[-77.039481,38.8921],[-77.039464,38.895397],[-77.03946,38.898813],[-77.03799,38.898639],[-77.037712,38.895391],[-77.035117,38.898761],[-77.036542,38.902525],[-77.046647,38.903742],[-77.046649,38.902526],[-77.046653,38.901318],[-77.046651,38.900693],[-77.046653,38.899577],[-77.048023,38.896017],[-77.046682,38.895316],[-77.043474,38.893514]],[[-77.043474,38.893514],[-77.043472,38.895398],[-77.043468,38.897355],[-77.041705,38.897354],[-77.041727,38.895405],[-77.043474,38.893514]]]},"properties":{"id":"20006","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.010791,38.885079],[-77.012231,38.88299],[-77.009059,38.882065],[-77.010791,38.885079]]]},"properties":{"id":"20053","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.044938,38.892101],[-77.041731,38.8921],[-77.043474,38.893514],[-77.046682,38.895316],[-77.046675,38.8935],[-77.044938,38.892101]]]},"properties":{"id":"20245","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.008171,38.89203],[-77.005905,38.892012],[-77.005889,38.893625],[-77.007575,38.893629],[-77.008171,38.89203]]]},"properties":{"id":"20510","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.074107,38.956589],[-77.090629,38.956952],[-77.104619,38.916517],[-77.066384,38.927527],[-77.074107,38.956589]]]},"properties":{"id":"20016","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.051769,38.987244],[-77.090629,38.956952],[-77.074107,38.956589],[-77.04994,38.944295],[-77.043491,38.961335],[-77.051769,38.987244]]]},"properties":{"id":"20015","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.036542,38.902525],[-77.035117,38.898761],[-77.031963,38.897349],[-77.029619,38.897339],[-77.027034,38.898316],[-77.028082,38.911125],[-77.036518,38.911124],[-77.036542,38.902525]]]},"properties":{"id":"20005","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.015148,38.872037],[-77.020205,38.868862],[-77.019713,38.856371],[-77.015541,38.860767],[-77.015148,38.872037]]]},"properties":{"id":"20319","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.028073,38.887565],[-77.031952,38.887567],[-77.031962,38.884966],[-77.029617,38.884976],[-77.028073,38.887565]]]},"properties":{"id":"20228","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.024016,38.887566],[-77.026123,38.889064],[-77.028073,38.887565],[-77.024016,38.887566]]]},"properties":{"id":"20560","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.025982,38.88249],[-77.023994,38.882546],[-77.024021,38.884845],[-77.025974,38.884847],[-77.025982,38.88249]]]},"properties":{"id":"20260","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.031964,38.892093],[-77.031955,38.895499],[-77.033645,38.895479],[-77.033653,38.89209],[-77.031964,38.892093]]]},"properties":{"id":"20230","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.048849,38.892102],[-77.046676,38.892101],[-77.046675,38.8935],[-77.048859,38.8935],[-77.048849,38.892102]]]},"properties":{"id":"20418","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.99513,38.923796],[-76.977768,38.946358],[-76.987833,38.954265],[-77.008034,38.942381],[-77.006952,38.931259],[-77.009044,38.926667],[-76.99513,38.923796]],[[-77.000982,38.931215],[-77.002983,38.940743],[-76.997546,38.940732],[-76.994723,38.934323],[-77.000982,38.931215]]]},"properties":{"id":"20017","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.963382,38.889796],[-77.003515,38.889808],[-77.005897,38.887612],[-77.009065,38.887588],[-77.010791,38.885079],[-77.009059,38.882065],[-77.010125,38.867284],[-77.009057,38.87008],[-77.007406,38.869555],[-77.005682,38.868856],[-76.978173,38.876953],[-76.963382,38.889796]],[[-76.992654,38.878585],[-76.994975,38.878943],[-76.994975,38.879284],[-76.992885,38.879278],[-76.992654,38.878585]]]},"properties":{"id":"20003","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.066384,38.927527],[-77.104619,38.916517],[-77.069269,38.900582],[-77.050146,38.909635],[-77.066384,38.927527]],[[-77.073097,38.912589],[-77.071526,38.905839],[-77.079598,38.906814],[-77.079016,38.912679],[-77.073097,38.912589]]]},"properties":{"id":"20007","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.000982,38.931215],[-76.994723,38.934323],[-76.997546,38.940732],[-77.002983,38.940743],[-77.000982,38.931215]]]},"properties":{"id":"20064","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.074107,38.956589],[-77.066384,38.927527],[-77.050146,38.909635],[-77.046634,38.910338],[-77.04663,38.911128],[-77.044785,38.927452],[-77.050526,38.933317],[-77.04994,38.944295],[-77.074107,38.956589]]]},"properties":{"id":"20008","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.037712,38.895391],[-77.03799,38.898639],[-77.03946,38.898813],[-77.039464,38.895397],[-77.037712,38.895391]]]},"properties":{"id":"20506","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.009044,38.926667],[-77.007575,38.893629],[-77.005889,38.893625],[-77.005905,38.892012],[-77.008171,38.89203],[-77.012227,38.890834],[-77.009065,38.887588],[-77.005897,38.887612],[-77.005916,38.889812],[-77.003515,38.889808],[-76.963382,38.889796],[-76.943858,38.916871],[-76.99513,38.923796],[-77.009044,38.926667]]]},"properties":{"id":"20002","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.043491,38.961335],[-77.004325,38.967327],[-77.051769,38.987244],[-77.043491,38.961335]],[[-77.026499,38.977286],[-77.029073,38.972516],[-77.034018,38.97372],[-77.031844,38.977988],[-77.026499,38.977286]]]},"properties":{"id":"20012","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.044785,38.927452],[-77.04663,38.911128],[-77.036518,38.911124],[-77.028082,38.911125],[-77.027408,38.928286],[-77.044785,38.927452]]]},"properties":{"id":"20009","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.046653,38.901318],[-77.046649,38.902526],[-77.048831,38.902088],[-77.046653,38.901318]]]},"properties":{"id":"20427","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.021916,38.887563],[-77.024016,38.887566],[-77.021912,38.886322],[-77.021916,38.887563]]]},"properties":{"id":"20553","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.005897,38.887612],[-77.003515,38.889808],[-77.005916,38.889812],[-77.005897,38.887612]]]},"properties":{"id":"20540","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.029619,38.897339],[-77.031963,38.897349],[-77.031962,38.896105],[-77.02964,38.896126],[-77.029619,38.897339]]]},"properties":{"id":"20045","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.041727,38.895405],[-77.041705,38.897354],[-77.043468,38.897355],[-77.043472,38.895398],[-77.041727,38.895405]]]},"properties":{"id":"20405","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.003446,38.860689],[-77.018972,38.850432],[-77.019111,38.850177],[-76.972974,38.843312],[-77.003446,38.860689]]]},"properties":{"id":"20032","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.003446,38.860689],[-76.972974,38.843312],[-76.9421,38.867376],[-76.978173,38.876953],[-77.005682,38.868856],[-77.003673,38.868043],[-77.003446,38.860689]]]},"properties":{"id":"20020","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.046647,38.903742],[-77.036542,38.902525],[-77.036518,38.911124],[-77.04663,38.911128],[-77.046634,38.910338],[-77.046647,38.903742]]]},"properties":{"id":"20036","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.048023,38.896017],[-77.048859,38.8935],[-77.046675,38.8935],[-77.046682,38.895316],[-77.048023,38.896017]]]},"properties":{"id":"20520","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.073097,38.912589],[-77.079016,38.912679],[-77.079598,38.906814],[-77.071526,38.905839],[-77.073097,38.912589]]]},"properties":{"id":"20057","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.021912,38.886322],[-77.019912,38.886886],[-77.019907,38.887567],[-77.021916,38.887563],[-77.021912,38.886322]]]},"properties":{"id":"20202","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.9421,38.867376],[-76.941923,38.918584],[-76.943858,38.916871],[-76.963382,38.889796],[-76.978173,38.876953],[-76.9421,38.867376]]]},"properties":{"id":"20019","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.007575,38.893629],[-77.009044,38.926667],[-77.027408,38.928286],[-77.028082,38.911125],[-77.027034,38.898316],[-77.019899,38.892759],[-77.015181,38.891448],[-77.012227,38.890834],[-77.008171,38.89203],[-77.007575,38.893629]]]},"properties":{"id":"20001","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.015181,38.891448],[-77.019899,38.892759],[-77.0219,38.892078],[-77.015181,38.891448]]]},"properties":{"id":"20565","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.992654,38.878585],[-76.992885,38.879278],[-76.994975,38.879284],[-76.994975,38.878943],[-76.992654,38.878585]]]},"properties":{"id":"20390","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.007406,38.869555],[-77.009057,38.87008],[-77.010125,38.867284],[-77.007406,38.869555]]]},"properties":{"id":"20593","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.043474,38.893514],[-77.041727,38.895405],[-77.043472,38.895398],[-77.043474,38.893514]]]},"properties":{"id":"20240","state_code":"11"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.419093,29.659656],[-82.381897,29.703365],[-82.449496,29.71098],[-82.495981,29.697377],[-82.472384,29.659825],[-82.419093,29.659656]]]},"properties":{"id":"32606","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.188943,29.72455],[-82.180034,29.780008],[-82.205851,29.84549],[-82.241061,29.852185],[-82.376686,29.839945],[-82.400207,29.832136],[-82.347301,29.703272],[-82.339157,29.666676],[-82.301776,29.666512],[-82.188943,29.72455]]]},"properties":{"id":"32609","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.350401,29.644891],[-82.339314,29.644902],[-82.339323,29.652056],[-82.355717,29.650392],[-82.36051,29.650351],[-82.350401,29.644891]]],[[[-82.369293,29.637642],[-82.350485,29.639184],[-82.350401,29.644891],[-82.369293,29.637642]]]]},"properties":{"id":"32612","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.049424,30.165981],[-82.049201,30.055904],[-81.990259,30.054919],[-81.918032,30.137818],[-81.9204,30.186941],[-81.869516,30.307426],[-81.946196,30.364883],[-82.049641,30.310815],[-82.049424,30.165981]]]},"properties":{"id":"32234","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.385084,30.508473],[-85.342086,30.566336],[-85.434782,30.56756],[-85.486309,30.520496],[-85.386756,30.435327],[-85.385789,30.480488],[-85.385084,30.508473]]]},"properties":{"id":"32438","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.084813,29.783496],[-82.094319,29.780089],[-82.079845,29.767829],[-82.084813,29.783496]]],[[[-81.913329,29.791749],[-81.86629,29.80073],[-82.029616,29.822233],[-82.06312,29.79115],[-81.913329,29.791749]]]]},"properties":{"id":"32656","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.287015,30.141734],[-82.220511,30.038179],[-82.18113,30.0626],[-82.17846,30.05867],[-82.186629,30.142742],[-82.287015,30.141734]]]},"properties":{"id":"32083","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.663476,28.232189],[-80.773962,28.328366],[-80.862815,28.347221],[-80.862827,28.091885],[-80.751388,28.084955],[-80.672016,28.184419],[-80.651189,28.206243],[-80.663476,28.232189]]]},"properties":{"id":"32940","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.555991,28.077898],[-80.589376,28.076494],[-80.581876,28.063695],[-80.55279,28.012464],[-80.532161,27.96356],[-80.498118,27.895911],[-80.478642,27.854615],[-80.441822,27.86082],[-80.555991,28.077898]]]},"properties":{"id":"32951","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.177921,26.23076],[-80.195463,26.210031],[-80.195587,26.209063],[-80.138427,26.209066],[-80.132881,26.260603],[-80.152064,26.274541],[-80.168784,26.27423],[-80.177921,26.23076]]]},"properties":{"id":"33069","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.122023,26.171607],[-80.123567,26.161866],[-80.104653,26.161838],[-80.122023,26.171607]]]},"properties":{"id":"33306","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.202416,25.972874],[-80.174521,25.973561],[-80.18334,25.994622],[-80.207589,26.01017],[-80.247806,26.001485],[-80.246763,25.971874],[-80.229593,25.972333],[-80.202416,25.972874]]]},"properties":{"id":"33023","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.123307,25.975016],[-80.113792,25.975074],[-80.115292,25.986341],[-80.130388,25.997053],[-80.167265,25.99622],[-80.18334,25.994622],[-80.174521,25.973561],[-80.157685,25.974091],[-80.123307,25.975016]]]},"properties":{"id":"33009","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.079116,30.709247],[-85.047506,30.606625],[-85.021833,30.576015],[-84.934421,30.60636],[-85.026842,30.831798],[-85.061115,30.814131],[-85.078945,30.722449],[-85.076959,30.711187],[-85.079116,30.709247]]]},"properties":{"id":"32442","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.063458,27.017456],[-82.08227,26.947158],[-82.082337,26.935457],[-82.019383,26.966738],[-82.048587,27.020158],[-82.063458,27.017456]]]},"properties":{"id":"33980","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.051946,26.843454],[-81.991486,26.884875],[-82.019383,26.966738],[-82.082337,26.935457],[-82.051946,26.843454]]]},"properties":{"id":"33950","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.048587,27.020158],[-82.019383,26.966738],[-81.99154,27.032075],[-82.05595,27.032038],[-82.048587,27.020158]]]},"properties":{"id":"33983","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.543049,28.94954],[-82.534092,28.891967],[-82.519226,28.892101],[-82.451597,28.907112],[-82.420749,28.965809],[-82.423184,28.968083],[-82.477976,28.955777],[-82.543049,28.94954]]]},"properties":{"id":"34465","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.637392,29.017763],[-82.635804,29.163146],[-82.65682,29.1372],[-82.757143,29.133033],[-82.806056,29.053173],[-82.759294,28.99247],[-82.637392,29.017763]]]},"properties":{"id":"34449","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990259,30.054919],[-81.89779,29.97856],[-81.745774,30.079455],[-81.756782,30.112335],[-81.864054,30.143602],[-81.918032,30.137818],[-81.990259,30.054919]]]},"properties":{"id":"32068","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.692116,30.14474],[-81.735395,30.134658],[-81.756782,30.112335],[-81.745774,30.079455],[-81.690458,30.042834],[-81.680215,30.12124],[-81.692116,30.14474]]]},"properties":{"id":"32003","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.8094,29.97966],[-81.812243,29.972752],[-81.802517,29.972752],[-81.8094,29.97966]]]},"properties":{"id":"32079","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.687613,26.228883],[-81.506276,26.211948],[-81.56252,26.422619],[-81.658061,26.332435],[-81.690494,26.317334],[-81.687613,26.228883]]]},"properties":{"id":"34120","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.652398,25.932999],[-81.671158,25.931749],[-81.664976,25.914409],[-81.642333,25.920903],[-81.652398,25.932999]]]},"properties":{"id":"34140","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458555,30.143801],[-82.560029,30.015817],[-82.458364,30.136449],[-82.458555,30.143801]]]},"properties":{"id":"32061","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.601212,30.130541],[-81.602223,30.168481],[-81.679573,30.190838],[-81.679626,30.190495],[-81.692116,30.14474],[-81.680215,30.12124],[-81.641665,30.125522],[-81.601212,30.130541]]]},"properties":{"id":"32223","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.660896,30.561646],[-81.765316,30.518652],[-81.733079,30.419692],[-81.639736,30.392321],[-81.601224,30.412473],[-81.583334,30.409355],[-81.608717,30.574892],[-81.660896,30.561646]]]},"properties":{"id":"32218","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.666611,30.364501],[-81.626417,30.371939],[-81.626417,30.386939],[-81.639736,30.392321],[-81.733079,30.419692],[-81.730476,30.391753],[-81.666611,30.364501]]]},"properties":{"id":"32208","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.767301,30.299401],[-81.684118,30.283239],[-81.676617,30.288739],[-81.697002,30.321593],[-81.769096,30.315098],[-81.770547,30.315082],[-81.767301,30.299401]]]},"properties":{"id":"32205","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.390331,30.324609],[-81.438526,30.323343],[-81.424607,30.306941],[-81.387568,30.307612],[-81.390331,30.324609]]]},"properties":{"id":"32266","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.552559,30.352445],[-81.601224,30.402853],[-81.626417,30.386939],[-81.626417,30.371939],[-81.613416,30.346239],[-81.552559,30.352445]]]},"properties":{"id":"32277","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.450962,30.346262],[-87.299346,30.402551],[-87.300475,30.406116],[-87.280625,30.406863],[-87.265811,30.414078],[-87.29047,30.448445],[-87.367441,30.434495],[-87.450962,30.346262]]]},"properties":{"id":"32506","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26416,29.51092],[-81.192068,29.497277],[-81.158899,29.55517],[-81.207949,29.670659],[-81.21929,29.670617],[-81.286766,29.651298],[-81.286942,29.651375],[-81.27795,29.51059],[-81.26416,29.51092]]]},"properties":{"id":"32137","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26416,29.51092],[-81.16003,29.41454],[-81.192068,29.497277],[-81.26416,29.51092]]]},"properties":{"id":"32164","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.013919,29.94409],[-85.002109,29.683163],[-84.670525,29.768883],[-84.78428,29.81438],[-84.941116,30.013436],[-85.025439,29.971399],[-85.013919,29.94409]]]},"properties":{"id":"32328","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.70226,30.621196],[-84.68955,30.613005],[-84.68875,30.617342],[-84.70226,30.621196]]],[[[-84.70226,30.621196],[-84.725161,30.705505],[-84.864693,30.711542],[-84.904253,30.633669],[-84.76609,30.59385],[-84.736509,30.59682],[-84.70226,30.621196]]]]},"properties":{"id":"32324","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.76609,30.59385],[-84.755977,30.562028],[-84.736509,30.59682],[-84.76609,30.59385]]]},"properties":{"id":"32330","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.066453,26.98552],[-80.619123,26.964551],[-80.604258,26.987507],[-80.578838,27.206043],[-80.677753,27.302218],[-80.999677,27.270259],[-81.182486,27.267178],[-81.267675,27.120976],[-81.267289,27.067329],[-81.244276,27.050309],[-81.066453,26.98552]]]},"properties":{"id":"34974","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.46088,28.59228],[-82.672737,28.6368],[-82.656053,28.576734],[-82.571456,28.518856],[-82.564531,28.520945],[-82.516332,28.533632],[-82.479437,28.533926],[-82.477205,28.533947],[-82.46088,28.59228]]]},"properties":{"id":"34613","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.516332,28.533632],[-82.548661,28.433834],[-82.485053,28.433697],[-82.479437,28.533926],[-82.516332,28.533632]]]},"properties":{"id":"34609","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.451416,28.694813],[-82.46088,28.59228],[-82.477205,28.533947],[-82.375978,28.492561],[-82.251749,28.594035],[-82.274621,28.658911],[-82.263052,28.667634],[-82.426628,28.694908],[-82.451416,28.694813]]]},"properties":{"id":"34601","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.490692,27.470873],[-81.482064,27.470845],[-81.41927,27.441871],[-81.256499,27.446067],[-81.360452,27.54716],[-81.342928,27.573764],[-81.508403,27.551377],[-81.495051,27.470867],[-81.490692,27.470873]]]},"properties":{"id":"33870","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.442954,27.996067],[-82.485213,28.001436],[-82.485549,27.981297],[-82.480988,27.975119],[-82.451208,27.970076],[-82.442941,27.977821],[-82.442954,27.996067]]]},"properties":{"id":"33603","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.326604,27.972858],[-82.403938,27.981193],[-82.43711,27.903173],[-82.403965,27.879218],[-82.333362,27.923066],[-82.32838,27.950187],[-82.326604,27.972858]]]},"properties":{"id":"33619","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.566985,27.972903],[-82.560508,28.039472],[-82.574958,28.039863],[-82.612454,28.003107],[-82.572103,27.971828],[-82.566985,27.972903]]]},"properties":{"id":"33615","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.416326,28.032616],[-82.412822,28.012071],[-82.374064,28.01776],[-82.377343,28.069267],[-82.402171,28.069115],[-82.416201,28.054439],[-82.416326,28.032616]]]},"properties":{"id":"33617","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.307661,27.884378],[-82.329949,27.827927],[-82.232805,27.821306],[-82.269293,27.857227],[-82.307661,27.884378]]]},"properties":{"id":"33569","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.514123,28.036723],[-82.485301,28.033479],[-82.48416,28.033069],[-82.484028,28.065972],[-82.481974,28.112586],[-82.502402,28.113196],[-82.514123,28.036723]]]},"properties":{"id":"33618","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.464737,27.95637],[-82.44826,27.923604],[-82.451208,27.970076],[-82.480988,27.975119],[-82.464737,27.95637]]]},"properties":{"id":"33602","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.261245,27.919278],[-82.269292,27.857304],[-82.193845,27.88798],[-82.219645,27.908311],[-82.261245,27.919278]]]},"properties":{"id":"33596","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.386392,27.673756],[-82.335068,27.767426],[-82.335047,27.773118],[-82.360314,27.781557],[-82.383333,27.734601],[-82.386392,27.673756]]]},"properties":{"id":"33573","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.219645,27.908311],[-82.193845,27.88798],[-82.187885,27.880207],[-82.180016,27.966965],[-82.203622,28.027641],[-82.245063,28.05285],[-82.260698,28.03987],[-82.252848,27.974349],[-82.219645,27.908311]]]},"properties":{"id":"33527","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.583398,30.996172],[-85.599934,30.830552],[-85.490609,30.829652],[-85.429764,30.916769],[-85.446199,30.997657],[-85.583398,30.996172]]]},"properties":{"id":"32440","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.28895,30.260318],[-83.06626,29.98754],[-83.036416,30.047859],[-83.16814,30.100544],[-83.247253,30.26067],[-83.28895,30.260318]]]},"properties":{"id":"32066","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.841344,28.748373],[-81.825716,28.71764],[-81.787954,28.742875],[-81.845387,28.75612],[-81.841344,28.748373]]]},"properties":{"id":"34797","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.890941,26.520799],[-81.93941,26.543554],[-81.973244,26.531754],[-82.028437,26.480245],[-82.031513,26.475957],[-82.023405,26.474072],[-81.965912,26.468457],[-81.894834,26.432758],[-81.821499,26.452534],[-81.844789,26.49242],[-81.876274,26.526012],[-81.890941,26.520799]]]},"properties":{"id":"33908","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.851051,26.395844],[-81.894834,26.432758],[-81.965912,26.468457],[-81.864374,26.36611],[-81.851051,26.395844]]]},"properties":{"id":"33931","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.93941,26.543554],[-81.890941,26.520799],[-81.890766,26.596507],[-81.904078,26.603201],[-81.93941,26.543554]]]},"properties":{"id":"33919","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.646454,26.616242],[-81.566219,26.683943],[-81.566258,26.68725],[-81.663095,26.673455],[-81.646454,26.616242]]]},"properties":{"id":"33972","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.102959,26.665688],[-82.196421,26.697246],[-82.189355,26.586683],[-82.104882,26.614403],[-82.102959,26.665688]]]},"properties":{"id":"33922","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.714421,26.613549],[-81.758779,26.610737],[-81.713726,26.581749],[-81.714421,26.613549]]]},"properties":{"id":"33973","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.264666,30.395396],[-84.286458,30.421771],[-84.406407,30.373875],[-84.421052,30.302407],[-84.14135,30.288785],[-84.136451,30.349482],[-84.264666,30.395396]]]},"properties":{"id":"32305","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.284345,30.436596],[-84.280666,30.434428],[-84.280664,30.439593],[-84.284345,30.436596]]]},"properties":{"id":"32399","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.397406,30.485008],[-84.28913,30.449067],[-84.277079,30.447665],[-84.263411,30.475133],[-84.359487,30.585428],[-84.397406,30.485008]]]},"properties":{"id":"32303","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.875406,30.338858],[-84.605136,30.050624],[-84.713351,30.30017],[-84.646408,30.388281],[-84.680397,30.393435],[-84.876912,30.36486],[-84.875406,30.338858]]]},"properties":{"id":"32334","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.040189,28.96013],[-81.974906,28.960006],[-81.941751,28.96018],[-81.948346,29.000183],[-81.98268,29.029361],[-82.111583,29.025669],[-82.125078,29.02191],[-82.11992,28.96006],[-82.040189,28.96013]]]},"properties":{"id":"34491","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.09047,26.94739],[-80.06635,26.944086],[-80.076028,26.970381],[-80.147931,26.98822],[-80.09047,26.94739]]]},"properties":{"id":"33469","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324969,25.920954],[-80.323392,25.879871],[-80.283551,25.881424],[-80.275027,25.881637],[-80.276728,25.925127],[-80.293226,25.925433],[-80.324969,25.920954]]]},"properties":{"id":"33014","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.341605,25.927926],[-80.340964,25.867404],[-80.322561,25.853659],[-80.323392,25.879871],[-80.324969,25.920954],[-80.341605,25.927926]]]},"properties":{"id":"33016","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.382117,25.714877],[-80.384749,25.746344],[-80.385017,25.75283],[-80.432512,25.751093],[-80.43246,25.749742],[-80.431068,25.713879],[-80.382583,25.714857],[-80.382117,25.714877]]]},"properties":{"id":"33175","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.380577,25.58484],[-80.380237,25.62741],[-80.426555,25.625884],[-80.430709,25.625679],[-80.423173,25.565869],[-80.380747,25.567179],[-80.380577,25.58484]]]},"properties":{"id":"33177","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.479969,25.725379],[-80.43246,25.749742],[-80.432512,25.751093],[-80.432863,25.760899],[-80.498395,25.76123],[-80.545128,25.666618],[-80.498817,25.682282],[-80.479969,25.725379]]]},"properties":{"id":"33194","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.254014,25.73507],[-80.260834,25.705648],[-80.245441,25.70203],[-80.211491,25.734491],[-80.212784,25.749077],[-80.254408,25.744846],[-80.254014,25.73507]]]},"properties":{"id":"33133","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.214272,25.760877],[-80.254978,25.760324],[-80.254408,25.744846],[-80.212784,25.749077],[-80.214272,25.760877]]]},"properties":{"id":"33145","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.164158,25.874765],[-80.167457,25.914156],[-80.199677,25.913234],[-80.19987,25.909864],[-80.197939,25.87325],[-80.197884,25.872312],[-80.164158,25.874765]]]},"properties":{"id":"33161","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.199545,25.778783],[-80.214792,25.778337],[-80.2147,25.774514],[-80.193655,25.775108],[-80.193831,25.779891],[-80.197881,25.778859],[-80.199545,25.778783]]]},"properties":{"id":"33128","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.384749,25.746344],[-80.382117,25.714877],[-80.334916,25.71674],[-80.33592,25.754905],[-80.384749,25.746344]]]},"properties":{"id":"33165","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.156814,25.915072],[-80.131021,25.899132],[-80.119386,25.899546],[-80.113792,25.975074],[-80.123307,25.975016],[-80.15645,25.941208],[-80.156814,25.915072]]]},"properties":{"id":"33160","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.119285,25.803595],[-80.163865,25.797191],[-80.185578,25.795149],[-80.13908,25.766187],[-80.124888,25.760045],[-80.119285,25.803595]]]},"properties":{"id":"33139","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.798415,24.851198],[-80.878041,24.805639],[-80.867848,24.789742],[-80.749544,24.832244],[-80.798415,24.851198]]]},"properties":{"id":"33001","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.843252,30.456592],[-81.962558,30.520942],[-82.017082,30.50213],[-82.049958,30.362472],[-82.049641,30.310815],[-81.946196,30.364883],[-81.880157,30.423619],[-81.843252,30.456592]]]},"properties":{"id":"32009","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.864758,30.808243],[-86.648626,30.716284],[-86.594533,30.861211],[-86.542488,30.99427],[-86.785692,30.99698],[-86.864758,30.808243]]]},"properties":{"id":"32531","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.433753,28.547697],[-81.41866,28.508675],[-81.384758,28.507126],[-81.38485,28.531057],[-81.389112,28.552096],[-81.413833,28.552805],[-81.433753,28.547697]]]},"properties":{"id":"32805","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.376919,28.558974],[-81.355406,28.531363],[-81.332048,28.53148],[-81.31515,28.564459],[-81.339806,28.571074],[-81.373109,28.582265],[-81.376919,28.558974]]]},"properties":{"id":"32803","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.374288,28.487469],[-81.444342,28.472066],[-81.408799,28.433304],[-81.350883,28.449762],[-81.350766,28.45045],[-81.352325,28.491222],[-81.374288,28.487469]]]},"properties":{"id":"32809","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.429355,28.421214],[-81.50955,28.347312],[-81.447884,28.347997],[-81.404063,28.347886],[-81.382969,28.347886],[-81.408799,28.433304],[-81.429355,28.421214]]]},"properties":{"id":"32837","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.576633,28.429865],[-81.506544,28.375355],[-81.475844,28.409609],[-81.523431,28.438852],[-81.576633,28.429865]]]},"properties":{"id":"32836","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.271358,28.509988],[-81.260621,28.448543],[-81.230454,28.451416],[-81.271358,28.509988]]]},"properties":{"id":"32829","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.55424,28.529676],[-81.532977,28.5227],[-81.508862,28.545381],[-81.504815,28.546243],[-81.511572,28.621815],[-81.563132,28.606679],[-81.55424,28.529676]]]},"properties":{"id":"34761","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.309158,28.451454],[-81.314535,28.524358],[-81.331137,28.531647],[-81.352325,28.491222],[-81.350766,28.45045],[-81.309158,28.451454]]]},"properties":{"id":"32812","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.339806,28.571074],[-81.31515,28.564459],[-81.315197,28.575322],[-81.324644,28.578933],[-81.339806,28.571074]]]},"properties":{"id":"32814","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.187118,28.495525],[-81.166531,28.451725],[-81.108242,28.451458],[-81.13334,28.499057],[-81.187118,28.495525]]]},"properties":{"id":"32831","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.103575,26.882928],[-80.151113,26.928193],[-80.290654,26.902924],[-80.264359,26.883348],[-80.131724,26.795428],[-80.107441,26.779158],[-80.107118,26.786816],[-80.103575,26.882928]]]},"properties":{"id":"33418","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.098888,26.322809],[-80.070493,26.320956],[-80.064279,26.360675],[-80.097367,26.362462],[-80.098888,26.322809]]]},"properties":{"id":"33432","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.089851,26.483642],[-80.089717,26.572161],[-80.113357,26.571993],[-80.122853,26.557196],[-80.122831,26.533427],[-80.130666,26.48385],[-80.122603,26.483853],[-80.089851,26.483642]]]},"properties":{"id":"33436","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.090719,26.424352],[-80.085293,26.48346],[-80.089851,26.483642],[-80.122603,26.483853],[-80.121906,26.42426],[-80.090719,26.424352]]]},"properties":{"id":"33445","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.111564,26.692866],[-80.109231,26.750523],[-80.134393,26.750637],[-80.147387,26.701187],[-80.128358,26.692414],[-80.111564,26.692866]]]},"properties":{"id":"33417","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.251225,26.341718],[-80.202937,26.32783],[-80.185403,26.327786],[-80.18663,26.367895],[-80.203304,26.367867],[-80.250885,26.357769],[-80.28347,26.351455],[-80.251225,26.341718]]]},"properties":{"id":"33428","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.338611,28.239517],[-82.325116,28.293592],[-82.386644,28.307464],[-82.378177,28.226153],[-82.394083,28.171127],[-82.3537,28.17128],[-82.338611,28.239517]]]},"properties":{"id":"33544","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.277382,28.311844],[-82.38344,28.323829],[-82.386644,28.307464],[-82.325116,28.293592],[-82.277382,28.311844]]]},"properties":{"id":"33576","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.699915,27.828308],[-82.753945,27.829054],[-82.764723,27.818803],[-82.763818,27.807927],[-82.699196,27.806417],[-82.699915,27.828308]]]},"properties":{"id":"33709","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.655695,27.806586],[-82.597171,27.79733],[-82.58814,27.823603],[-82.657669,27.82848],[-82.655695,27.806586]]]},"properties":{"id":"33703","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.740548,28.172786],[-82.805097,28.172181],[-82.788288,28.122628],[-82.74801,28.123022],[-82.726158,28.122916],[-82.713991,28.136382],[-82.724079,28.173221],[-82.740548,28.172786]]]},"properties":{"id":"34689","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.725194,27.936724],[-82.727227,27.900923],[-82.722432,27.893729],[-82.717156,27.886343],[-82.710829,27.877373],[-82.704347,27.868264],[-82.699092,27.923458],[-82.725194,27.936724]]]},"properties":{"id":"33760","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.974091,27.934702],[-81.891439,27.790036],[-81.833097,27.791607],[-81.821637,27.820533],[-81.662234,27.841817],[-81.676481,27.923654],[-81.694667,27.94518],[-81.760543,27.956661],[-81.763683,27.967638],[-81.857726,27.956568],[-81.871398,27.952965],[-81.974091,27.934702]]]},"properties":{"id":"33830","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.01475,28.052405],[-81.957425,28.055706],[-81.87512,28.073455],[-81.832785,28.112902],[-81.854369,28.152145],[-81.976679,28.080581],[-82.01475,28.052405]]]},"properties":{"id":"33805","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.62704,29.52116],[-81.644262,29.453787],[-81.569749,29.48063],[-81.56954,29.46144],[-81.59091,29.460222],[-81.59073,29.47605],[-81.56894,29.48613],[-81.556828,29.533992],[-81.588381,29.564893],[-81.62704,29.52116]]]},"properties":{"id":"32181","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.38406,27.37451],[-80.346007,27.366502],[-80.35037,27.42571],[-80.3882,27.41612],[-80.41024,27.37457],[-80.38406,27.37451]]]},"properties":{"id":"34981","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.417262,27.113534],[-82.391449,27.079119],[-82.293708,27.053585],[-82.282641,27.078142],[-82.272165,27.122432],[-82.363709,27.150109],[-82.361461,27.153693],[-82.417262,27.113534]]]},"properties":{"id":"34292","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.552214,27.353279],[-82.57952,27.336823],[-82.592981,27.32452],[-82.571868,27.284707],[-82.544919,27.304224],[-82.529749,27.324889],[-82.530379,27.351318],[-82.552214,27.353279]]]},"properties":{"id":"34236","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.217253,28.580305],[-82.05477,28.63744],[-82.054682,28.696374],[-82.10142,28.75429],[-82.187252,28.759835],[-82.263052,28.667634],[-82.274621,28.658911],[-82.217253,28.580305]]]},"properties":{"id":"33513","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.930784,28.898755],[-81.004339,28.856732],[-80.967895,28.790197],[-80.842922,28.791148],[-80.852799,28.900789],[-80.854309,28.903925],[-80.930784,28.898755]]]},"properties":{"id":"32759","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.183657,28.970819],[-81.256199,28.959579],[-81.2404,28.954106],[-81.183657,28.970819]]]},"properties":{"id":"32744","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.058295,29.129059],[-80.983093,29.153106],[-80.998576,29.187359],[-81.073418,29.156143],[-81.058295,29.129059]]]},"properties":{"id":"32119","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.396905,30.39826],[-86.397498,30.375],[-86.374949,30.372162],[-86.319641,30.415242],[-86.396905,30.39826]]]},"properties":{"id":"32550","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.419093,29.659656],[-82.472384,29.659825],[-82.473338,29.652699],[-82.472607,29.630794],[-82.372498,29.637653],[-82.36051,29.650351],[-82.355717,29.650392],[-82.356531,29.659444],[-82.419093,29.659656]]]},"properties":{"id":"32607","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.339157,29.666676],[-82.347301,29.703272],[-82.381897,29.703365],[-82.419093,29.659656],[-82.356531,29.659444],[-82.339171,29.659186],[-82.339157,29.666676]]]},"properties":{"id":"32605","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458063,30.201662],[-82.458555,30.143801],[-82.458364,30.136449],[-82.390787,30.139024],[-82.365569,30.184642],[-82.352892,30.224187],[-82.457507,30.257226],[-82.458063,30.201662]]]},"properties":{"id":"32072","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.706207,30.438879],[-85.76641,30.334577],[-85.767066,30.309213],[-85.668853,30.259967],[-85.61856,30.264133],[-85.587402,30.302154],[-85.655784,30.438627],[-85.706207,30.438879]]]},"properties":{"id":"32409","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458364,30.136449],[-82.560029,30.015817],[-82.529705,29.940881],[-82.448644,29.931716],[-82.423356,29.930897],[-82.426264,29.921789],[-82.418728,29.923093],[-82.248132,29.945335],[-82.220511,30.038179],[-82.287015,30.141734],[-82.390787,30.139024],[-82.458364,30.136449]]]},"properties":{"id":"32054","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.672636,28.107095],[-80.672016,28.184419],[-80.751388,28.084955],[-80.719866,28.078812],[-80.672636,28.107095]]]},"properties":{"id":"32934","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.581876,28.063695],[-80.638188,28.035192],[-80.656648,28.035346],[-80.601677,28.012897],[-80.55279,28.012464],[-80.581876,28.063695]]]},"properties":{"id":"32905","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.59167,27.873752],[-80.720535,27.910513],[-80.737283,27.99893],[-80.720298,28.042425],[-80.719866,28.078812],[-80.751388,28.084955],[-80.862827,28.091885],[-80.869745,28.012636],[-80.868881,27.822522],[-80.547397,27.733015],[-80.55091,27.76757],[-80.554462,27.822188],[-80.59167,27.873752]]]},"properties":{"id":"32948","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.806483,28.659726],[-80.851383,28.584193],[-80.794376,28.608275],[-80.806483,28.659726]]]},"properties":{"id":"32796","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.070493,26.320956],[-80.098888,26.322809],[-80.116782,26.327845],[-80.119684,26.304643],[-80.080665,26.297057],[-80.072167,26.305261],[-80.070493,26.320956]]]},"properties":{"id":"33441","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.381318,26.094069],[-80.395313,26.029345],[-80.377248,26.029375],[-80.345101,26.029706],[-80.338336,26.087675],[-80.346287,26.087682],[-80.381318,26.094069]]]},"properties":{"id":"33331","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.123127,26.208375],[-80.122023,26.171607],[-80.104653,26.161838],[-80.095427,26.157331],[-80.08875,26.204941],[-80.108563,26.209824],[-80.123127,26.208375]]]},"properties":{"id":"33308","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203172,26.164865],[-80.199406,26.135977],[-80.201278,26.123594],[-80.14645,26.122183],[-80.142792,26.129781],[-80.144655,26.148219],[-80.144129,26.1592],[-80.146436,26.166587],[-80.203172,26.164865]]]},"properties":{"id":"33311","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.312016,26.007856],[-80.310617,25.957165],[-80.294976,25.956929],[-80.262568,25.971296],[-80.246763,25.971874],[-80.247806,26.001485],[-80.279818,26.008328],[-80.312016,26.007856]]]},"properties":{"id":"33025","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.310617,25.957165],[-80.312016,26.007856],[-80.375977,26.007731],[-80.375067,25.957072],[-80.339101,25.957078],[-80.310617,25.957165]]]},"properties":{"id":"33027","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.33626,26.90776],[-82.298451,26.868792],[-82.271998,26.91595],[-82.263651,26.94533],[-82.30417,26.961532],[-82.33626,26.90776]]]},"properties":{"id":"34224","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.298451,26.868792],[-82.33626,26.90776],[-82.342603,26.89349],[-82.282636,26.813518],[-82.249816,26.786886],[-82.236937,26.871992],[-82.236717,26.877942],[-82.298451,26.868792]]]},"properties":{"id":"33946","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.204768,27.03284],[-82.146394,27.012875],[-82.124765,27.018017],[-82.063458,27.017456],[-82.048587,27.020158],[-82.05595,27.032038],[-82.057469,27.032119],[-82.177185,27.032789],[-82.203442,27.032843],[-82.204768,27.03284]]]},"properties":{"id":"33954","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.451597,28.907112],[-82.519226,28.892101],[-82.529898,28.86272],[-82.517283,28.80399],[-82.451416,28.694813],[-82.426628,28.694908],[-82.418295,28.702291],[-82.411014,28.856566],[-82.419515,28.877921],[-82.451597,28.907112]]]},"properties":{"id":"34461","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.517283,28.80399],[-82.552576,28.694635],[-82.451416,28.694813],[-82.517283,28.80399]]]},"properties":{"id":"34446","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.687112,26.109655],[-81.709392,25.964091],[-81.652398,25.932999],[-81.642333,25.920903],[-81.624807,25.870472],[-81.468043,25.808121],[-81.382274,25.873429],[-81.364491,25.910812],[-81.37754,25.980004],[-81.457185,26.052974],[-81.566883,26.094271],[-81.686619,26.160375],[-81.686608,26.160103],[-81.686718,26.147291],[-81.687112,26.109655]]]},"properties":{"id":"34114","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.086867,26.256289],[-81.357453,25.950182],[-81.364491,25.910812],[-81.382274,25.873429],[-81.371092,25.832885],[-81.34417,25.803328],[-80.879809,26.259455],[-81.086867,26.256289]]]},"properties":{"id":"34141","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.686718,26.147291],[-81.782821,26.138182],[-81.78691,26.09997],[-81.778443,26.076628],[-81.687112,26.109655],[-81.686718,26.147291]]]},"properties":{"id":"34112","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.687112,26.109655],[-81.778443,26.076628],[-81.74981,25.970503],[-81.709392,25.964091],[-81.687112,26.109655]]]},"properties":{"id":"34113","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.742552,26.272888],[-81.735938,26.21161],[-81.687402,26.212355],[-81.687613,26.228883],[-81.690494,26.317334],[-81.746167,26.316896],[-81.742552,26.272888]]]},"properties":{"id":"34119","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.101391,29.591059],[-83.251924,29.749859],[-83.2939,29.70895],[-83.306923,29.545334],[-83.2491,29.55526],[-83.101391,29.591059]]]},"properties":{"id":"32628","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.681698,30.244247],[-81.70747,30.196109],[-81.679626,30.190495],[-81.679573,30.190838],[-81.650715,30.22354],[-81.653016,30.24394],[-81.681698,30.244247]]]},"properties":{"id":"32212","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.6968,30.33179],[-81.727501,30.367875],[-81.761206,30.372073],[-81.769096,30.315098],[-81.697002,30.321593],[-81.6968,30.33179]]]},"properties":{"id":"32254","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.424607,30.306941],[-81.430095,30.252243],[-81.376317,30.252891],[-81.387568,30.307612],[-81.424607,30.306941]]]},"properties":{"id":"32250","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.226141,30.449046],[-87.241563,30.415698],[-87.192825,30.420536],[-87.226141,30.449046]]]},"properties":{"id":"32501","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.307236,30.322552],[-87.306712,30.316553],[-87.11742,30.367838],[-87.136189,30.379186],[-87.307236,30.322552]]]},"properties":{"id":"32561","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.192068,29.497277],[-81.16003,29.41454],[-81.155405,29.404464],[-81.100944,29.408626],[-81.097777,29.427094],[-81.158899,29.55517],[-81.192068,29.497277]]]},"properties":{"id":"32136","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.244276,27.050309],[-81.32165,26.91791],[-81.27169,26.768817],[-80.943048,26.776497],[-81.066453,26.98552],[-81.244276,27.050309]]]},"properties":{"id":"33471","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.566219,26.683943],[-81.646454,26.616242],[-81.66982,26.613063],[-81.662359,26.592764],[-81.565095,26.600333],[-81.533266,26.643469],[-81.566219,26.683943]]]},"properties":{"id":"33936","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.582788,28.039729],[-82.578857,28.085096],[-82.649149,28.098901],[-82.649089,28.091142],[-82.64858,28.060025],[-82.648557,28.03863],[-82.582788,28.039729]]]},"properties":{"id":"33626","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.546411,28.106219],[-82.54192,28.037912],[-82.527625,28.037313],[-82.514123,28.036723],[-82.502402,28.113196],[-82.516855,28.113192],[-82.546411,28.106219]]]},"properties":{"id":"33624","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.092814,28.036986],[-82.137542,27.987844],[-82.187011,28.027206],[-82.203622,28.027641],[-82.180016,27.966965],[-82.055634,27.970764],[-82.055651,28.02576],[-82.055947,28.036734],[-82.092814,28.036986]]]},"properties":{"id":"33566","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.205362,28.148821],[-82.245063,28.05285],[-82.203622,28.027641],[-82.187011,28.027206],[-82.092814,28.036986],[-82.055947,28.036734],[-82.055946,28.036937],[-82.085905,28.171619],[-82.105853,28.17165],[-82.205362,28.148821]]]},"properties":{"id":"33565","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.342619,28.029572],[-82.318717,27.973413],[-82.269392,27.959654],[-82.252848,27.974349],[-82.260698,28.03987],[-82.343875,28.036644],[-82.342619,28.029572]]]},"properties":{"id":"33584","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.506356,27.869037],[-82.538879,27.834966],[-82.486373,27.864605],[-82.506356,27.869037]]]},"properties":{"id":"33621","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.420144,27.793358],[-82.356945,27.791819],[-82.403167,27.85236],[-82.420144,27.793358]]]},"properties":{"id":"33534","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.205362,28.148821],[-82.105853,28.17165],[-82.106205,28.259226],[-82.187841,28.288236],[-82.188173,28.2591],[-82.179937,28.215267],[-82.21196,28.171868],[-82.205362,28.148821]]]},"properties":{"id":"33540","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.446199,30.997657],[-85.429764,30.916769],[-85.367617,30.887343],[-85.328917,30.99934],[-85.446199,30.997657]]]},"properties":{"id":"32426","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.026842,30.831798],[-84.934421,30.60636],[-84.932519,30.606393],[-84.904253,30.633669],[-84.864693,30.711542],[-84.970426,30.926687],[-85.035137,30.879819],[-85.026842,30.831798]]]},"properties":{"id":"32460","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.194435,30.925864],[-85.155343,30.927889],[-85.142731,31.000716],[-85.240092,31.000884],[-85.194435,30.925864]]]},"properties":{"id":"32445","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.55814,30.32421],[-83.448944,30.642413],[-83.61172,30.651258],[-83.736043,30.423505],[-83.82506,30.2145],[-83.70871,30.25988],[-83.59432,30.27198],[-83.55814,30.32421]]]},"properties":{"id":"32331","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.941751,28.96018],[-81.974906,28.960006],[-81.95359,28.903258],[-81.839211,28.894029],[-81.842194,28.961024],[-81.941751,28.96018]]]},"properties":{"id":"32159","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.907191,28.598435],[-81.910058,28.569209],[-81.872373,28.566165],[-81.872238,28.584204],[-81.907191,28.598435]]]},"properties":{"id":"34753","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.811386,26.612834],[-81.83913,26.679277],[-81.870551,26.653079],[-81.855601,26.604427],[-81.855531,26.597223],[-81.811386,26.612834]]]},"properties":{"id":"33916","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.83913,26.679277],[-81.811386,26.612834],[-81.803018,26.612748],[-81.767887,26.618524],[-81.69563,26.685857],[-81.760807,26.717275],[-81.83913,26.679277]]]},"properties":{"id":"33905","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.890766,26.596507],[-81.890941,26.520799],[-81.876274,26.526012],[-81.855263,26.546279],[-81.855531,26.597223],[-81.855601,26.604427],[-81.890766,26.596507]]]},"properties":{"id":"33907","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.69563,26.685857],[-81.767887,26.618524],[-81.758779,26.610737],[-81.714421,26.613549],[-81.66982,26.613063],[-81.646454,26.616242],[-81.663095,26.673455],[-81.69563,26.685857]]]},"properties":{"id":"33971","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.263411,30.475133],[-84.277079,30.447665],[-84.22656,30.454191],[-84.222441,30.454063],[-84.158615,30.485167],[-84.252118,30.501784],[-84.263411,30.475133]]]},"properties":{"id":"32308","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.222441,30.454063],[-84.22656,30.454191],[-84.264666,30.395396],[-84.136451,30.349482],[-84.075819,30.369819],[-84.074892,30.413106],[-84.222441,30.454063]]]},"properties":{"id":"32311","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.31785,30.52458],[-83.353735,30.312078],[-83.28895,30.260318],[-83.247253,30.26067],[-83.170969,30.385225],[-83.242731,30.501407],[-83.240123,30.520919],[-83.31785,30.52458]]]},"properties":{"id":"32059","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.604098,27.46736],[-82.67175,27.480709],[-82.680483,27.45396],[-82.680021,27.453517],[-82.62552,27.38962],[-82.59652,27.38932],[-82.59622,27.40522],[-82.595862,27.462369],[-82.604098,27.46736]]]},"properties":{"id":"34210","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.123989,29.330947],[-82.132731,29.309185],[-82.134751,29.295854],[-82.053903,29.24151],[-82.020007,29.281274],[-82.023496,29.321725],[-82.123989,29.330947]]]},"properties":{"id":"32617","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.374523,26.958386],[-80.290654,26.902924],[-80.151113,26.928193],[-80.178371,26.97198],[-80.374523,26.958386]]]},"properties":{"id":"33478","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.457233,25.504737],[-80.462975,25.462449],[-80.46079,25.462467],[-80.411919,25.462718],[-80.371875,25.507506],[-80.396086,25.507143],[-80.396023,25.495469],[-80.404196,25.499734],[-80.404243,25.507102],[-80.442318,25.506665],[-80.457233,25.504737]]]},"properties":{"id":"33033","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.423173,25.565869],[-80.430709,25.625679],[-80.548616,25.644819],[-80.550222,25.559271],[-80.423173,25.565869]]]},"properties":{"id":"33187","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.429937,25.68497],[-80.426555,25.625884],[-80.380237,25.62741],[-80.384579,25.686479],[-80.429987,25.687056],[-80.429937,25.68497]]]},"properties":{"id":"33186","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.334916,25.71674],[-80.285887,25.718163],[-80.28652,25.73404],[-80.287609,25.757171],[-80.334912,25.754949],[-80.33592,25.754905],[-80.334916,25.71674]]]},"properties":{"id":"33155","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.2147,25.774514],[-80.255457,25.771834],[-80.254978,25.760324],[-80.214272,25.760877],[-80.214326,25.762935],[-80.2147,25.774514]]]},"properties":{"id":"33135","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.192879,25.758009],[-80.193623,25.774134],[-80.193655,25.775108],[-80.2147,25.774514],[-80.214326,25.762935],[-80.192879,25.758009]]]},"properties":{"id":"33130","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.337263,25.805304],[-80.264608,25.808382],[-80.261872,25.808468],[-80.31373,25.847094],[-80.322561,25.853659],[-80.340964,25.867404],[-80.344031,25.869573],[-80.337263,25.805304]]]},"properties":{"id":"33166","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.332443,25.795464],[-80.264206,25.793564],[-80.264608,25.808382],[-80.337263,25.805304],[-80.332443,25.795464]]]},"properties":{"id":"33122","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.123307,25.975016],[-80.157685,25.974091],[-80.156584,25.944475],[-80.15645,25.941208],[-80.123307,25.975016]]]},"properties":{"id":"33180","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.571764,30.695383],[-86.528264,30.852305],[-86.594533,30.861211],[-86.648626,30.716284],[-86.651119,30.68232],[-86.571764,30.695383]]]},"properties":{"id":"32536","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.459383,28.683745],[-81.584605,28.712408],[-81.604315,28.729346],[-81.658082,28.67668],[-81.571965,28.606835],[-81.563132,28.606679],[-81.511572,28.621815],[-81.465088,28.631216],[-81.443527,28.640393],[-81.439725,28.67615],[-81.459383,28.683745]]]},"properties":{"id":"32703","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.443527,28.640393],[-81.465088,28.631216],[-81.467807,28.610952],[-81.416861,28.597932],[-81.386471,28.60592],[-81.386579,28.610294],[-81.398574,28.640152],[-81.443527,28.640393]]]},"properties":{"id":"32810","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.276875,28.364684],[-81.350883,28.449762],[-81.408799,28.433304],[-81.382969,28.347886],[-81.319697,28.348123],[-81.257864,28.348069],[-81.276875,28.364684]]]},"properties":{"id":"32824","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.314535,28.524358],[-81.309158,28.451454],[-81.26419,28.447982],[-81.260621,28.448543],[-81.271358,28.509988],[-81.277467,28.544709],[-81.314535,28.524358]]]},"properties":{"id":"32822","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.606965,28.376369],[-81.531627,28.347384],[-81.506544,28.375355],[-81.576633,28.429865],[-81.579316,28.432333],[-81.606965,28.376369]]]},"properties":{"id":"32830","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.314535,28.524358],[-81.277467,28.544709],[-81.276578,28.568364],[-81.278304,28.582933],[-81.315197,28.575322],[-81.31515,28.564459],[-81.332048,28.53148],[-81.331137,28.531647],[-81.314535,28.524358]]]},"properties":{"id":"32807","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.404063,28.347886],[-81.396502,28.271253],[-81.39033,28.232971],[-81.329945,28.251592],[-81.308224,28.267615],[-81.25469,28.323987],[-81.234292,28.348131],[-81.257864,28.348069],[-81.319697,28.348123],[-81.350189,28.305156],[-81.382969,28.347886],[-81.404063,28.347886]]]},"properties":{"id":"34744","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.234204,28.062255],[-80.869745,28.012636],[-80.862827,28.091885],[-80.862815,28.347221],[-80.862908,28.347487],[-81.086885,28.348085],[-81.168305,28.202935],[-81.234204,28.062255]]]},"properties":{"id":"34773","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203724,26.394203],[-80.203788,26.413489],[-80.223941,26.409571],[-80.250885,26.357769],[-80.203304,26.367867],[-80.203724,26.394203]]]},"properties":{"id":"33498","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.090152,26.750045],[-80.074597,26.695624],[-80.071101,26.690767],[-80.046363,26.690443],[-80.046126,26.724197],[-80.090152,26.750045]]]},"properties":{"id":"33401","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.107347,26.693199],[-80.112805,26.636858],[-80.07231,26.636316],[-80.068996,26.646598],[-80.071101,26.690767],[-80.074597,26.695624],[-80.107347,26.693199]]]},"properties":{"id":"33406","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.147159,26.483917],[-80.14651,26.424121],[-80.121906,26.42426],[-80.122603,26.483853],[-80.130666,26.48385],[-80.147159,26.483917]]]},"properties":{"id":"33484","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.046587,26.507386],[-80.065554,26.491484],[-80.076315,26.422787],[-80.058064,26.426452],[-80.046587,26.507386]]]},"properties":{"id":"33483","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.048763,26.803639],[-80.081464,26.817539],[-80.098558,26.80066],[-80.048763,26.803639]]]},"properties":{"id":"33403","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.073168,26.891282],[-80.081464,26.817539],[-80.048763,26.803639],[-80.031121,26.814473],[-80.052488,26.893271],[-80.073168,26.891282]]]},"properties":{"id":"33408","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.427753,28.186152],[-82.378177,28.226153],[-82.386644,28.307464],[-82.38344,28.323829],[-82.416398,28.323185],[-82.449642,28.256664],[-82.466126,28.19196],[-82.427753,28.186152]]]},"properties":{"id":"34639","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.715296,28.209848],[-82.717627,28.263865],[-82.748056,28.265674],[-82.772352,28.212916],[-82.736875,28.209748],[-82.715296,28.209848]]]},"properties":{"id":"34652","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.179937,28.215267],[-82.213256,28.233576],[-82.188173,28.2591],[-82.187841,28.288236],[-82.245591,28.288381],[-82.246194,28.218796],[-82.259741,28.171462],[-82.21196,28.171868],[-82.179937,28.215267]]]},"properties":{"id":"33541","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.736875,28.209748],[-82.772352,28.212916],[-82.805097,28.172181],[-82.740548,28.172786],[-82.736875,28.209748]]]},"properties":{"id":"34691","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.679424,27.770988],[-82.656327,27.771112],[-82.654894,27.784783],[-82.655695,27.806586],[-82.698398,27.806415],[-82.69984,27.770924],[-82.679424,27.770988]]]},"properties":{"id":"33713","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.847235,27.865395],[-82.834478,27.836316],[-82.817027,27.828906],[-82.811706,27.863653],[-82.815967,27.865625],[-82.847235,27.865395]]]},"properties":{"id":"33776","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.70892,28.012165],[-82.728931,28.010807],[-82.729578,27.990843],[-82.730072,27.955376],[-82.709218,27.937078],[-82.64882,27.979497],[-82.661692,27.978998],[-82.70892,28.012165]]]},"properties":{"id":"33759","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.727227,27.900923],[-82.762413,27.927643],[-82.770691,27.927579],[-82.784514,27.902029],[-82.787097,27.89464],[-82.722432,27.893729],[-82.727227,27.900923]]]},"properties":{"id":"33771","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.852143,27.910014],[-82.833225,27.910299],[-82.829225,27.922699],[-82.824325,27.936798],[-82.844629,27.937146],[-82.852143,27.910014]]]},"properties":{"id":"33786","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.768056,28.071387],[-81.756497,28.117501],[-81.832785,28.112902],[-81.87512,28.073455],[-81.842062,28.025803],[-81.783505,28.040426],[-81.768056,28.071387]]]},"properties":{"id":"33823","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.706747,28.022501],[-81.636269,28.018608],[-81.635519,28.044351],[-81.63522,28.055384],[-81.692178,28.105118],[-81.768056,28.071387],[-81.783505,28.040426],[-81.706747,28.022501]]]},"properties":{"id":"33881","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.635519,28.044351],[-81.636269,28.018608],[-81.631466,28.00779],[-81.60533,27.999396],[-81.586724,28.011488],[-81.61157,28.036901],[-81.635519,28.044351]]]},"properties":{"id":"33838","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.923849,27.996329],[-81.871398,27.952965],[-81.857726,27.956568],[-81.854229,27.981633],[-81.923849,27.996329]]]},"properties":{"id":"33812","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.201633,27.804798],[-81.217183,27.790847],[-81.170924,27.743649],[-81.201633,27.804798]]]},"properties":{"id":"33867","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.479345,30.104774],[-81.601212,30.130541],[-81.641665,30.125522],[-81.615524,30.005593],[-81.498318,30.065049],[-81.461675,30.087734],[-81.478979,30.104784],[-81.479345,30.104774]]]},"properties":{"id":"32259","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.452358,29.970197],[-81.312456,29.948158],[-81.368172,30.050193],[-81.461675,30.087734],[-81.498318,30.065049],[-81.452358,29.970197]]]},"properties":{"id":"32095","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.395138,29.861354],[-81.526074,29.780492],[-81.528261,29.760777],[-81.52523,29.759497],[-81.346671,29.747936],[-81.37926,29.825407],[-81.395138,29.861354]]]},"properties":{"id":"32033","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52523,29.759497],[-81.52366,29.622432],[-81.457262,29.623347],[-81.454622,29.623394],[-81.286942,29.651375],[-81.286766,29.651298],[-81.346671,29.747936],[-81.52523,29.759497]]]},"properties":{"id":"32145","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.44012,27.34516],[-80.420804,27.289022],[-80.369779,27.293565],[-80.370265,27.295875],[-80.38406,27.37451],[-80.41024,27.37457],[-80.44012,27.34516]]]},"properties":{"id":"34986","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.321226,27.242454],[-80.297436,27.23243],[-80.285008,27.245528],[-80.263111,27.298725],[-80.342072,27.350254],[-80.324318,27.271874],[-80.321226,27.242454]]]},"properties":{"id":"34952","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.35037,27.42571],[-80.346007,27.366502],[-80.342072,27.350254],[-80.263111,27.298725],[-80.286609,27.381274],[-80.301263,27.416495],[-80.35026,27.42949],[-80.35037,27.42571]]]},"properties":{"id":"34982","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.35026,27.42949],[-80.34959,27.469098],[-80.38261,27.46973],[-80.425696,27.470109],[-80.3882,27.41612],[-80.35037,27.42571],[-80.35026,27.42949]]]},"properties":{"id":"34947","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.952078,30.442383],[-87.136189,30.379186],[-87.11742,30.367838],[-86.951715,30.394795],[-86.952078,30.442383]]]},"properties":{"id":"32563","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.029515,30.599257],[-87.033592,30.597649],[-87.032075,30.595868],[-87.029515,30.599257]]]},"properties":{"id":"32530","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.530379,27.351318],[-82.529749,27.324889],[-82.497475,27.322837],[-82.49724,27.351849],[-82.505674,27.352374],[-82.530379,27.351318]]]},"properties":{"id":"34237","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.544738,27.298894],[-82.497564,27.298778],[-82.497563,27.299919],[-82.497475,27.322837],[-82.529749,27.324889],[-82.544919,27.304224],[-82.544738,27.298894]]]},"properties":{"id":"34239","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.448212,27.298799],[-82.291732,27.265341],[-82.253166,27.287755],[-82.268238,27.386304],[-82.448609,27.388605],[-82.449001,27.388608],[-82.446772,27.357125],[-82.448212,27.298799]]]},"properties":{"id":"34240","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.57952,27.389209],[-82.552214,27.353279],[-82.530379,27.351318],[-82.505674,27.352374],[-82.503009,27.381098],[-82.57952,27.389209]]]},"properties":{"id":"34234","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.501924,27.214539],[-82.544738,27.298894],[-82.544919,27.304224],[-82.571868,27.284707],[-82.516239,27.207832],[-82.501924,27.214539]]]},"properties":{"id":"34242","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.459515,27.38833],[-82.503009,27.381098],[-82.505674,27.352374],[-82.49724,27.351849],[-82.446772,27.357125],[-82.449001,27.388608],[-82.459515,27.38833]]]},"properties":{"id":"34235","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.502519,27.180118],[-82.458153,27.176058],[-82.492459,27.218306],[-82.501924,27.214539],[-82.516239,27.207832],[-82.502519,27.180118]]]},"properties":{"id":"34229","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.155517,27.098982],[-82.155533,27.075983],[-82.107348,27.080154],[-82.155517,27.098982]]]},"properties":{"id":"34289","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.16814,30.100544],[-83.036416,30.047859],[-82.783137,29.954637],[-82.793975,29.986202],[-82.87876,30.09941],[-83.06326,30.13015],[-83.16814,30.100544]]]},"properties":{"id":"32071","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.170969,30.385225],[-83.247253,30.26067],[-83.16814,30.100544],[-83.06326,30.13015],[-82.895008,30.145635],[-82.80723,30.31721],[-82.871678,30.365831],[-83.170969,30.385225]],[[-82.9626,30.30965],[-82.962511,30.275936],[-83.008708,30.275082],[-83.008999,30.311482],[-82.9626,30.30965]]]},"properties":{"id":"32060","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.344066,28.946324],[-81.275932,28.901385],[-81.257596,28.955178],[-81.294749,28.973202],[-81.344066,28.946324]]]},"properties":{"id":"32763","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.321955,29.630325],[-82.245293,29.573796],[-82.188943,29.72455],[-82.301776,29.666512],[-82.321955,29.630325]]]},"properties":{"id":"32641","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.639654,29.521288],[-82.559896,29.478349],[-82.412932,29.485092],[-82.414534,29.555462],[-82.472607,29.630794],[-82.473338,29.652699],[-82.639654,29.521288]]]},"properties":{"id":"32618","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.277071,30.327968],[-82.365569,30.184642],[-82.390787,30.139024],[-82.287015,30.141734],[-82.186629,30.142742],[-82.145454,30.143091],[-82.134273,30.362126],[-82.209444,30.423284],[-82.255401,30.354108],[-82.277071,30.327968]]]},"properties":{"id":"32040","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.134273,30.362126],[-82.145454,30.143091],[-82.049424,30.165981],[-82.049641,30.310815],[-82.049958,30.362472],[-82.134273,30.362126]]]},"properties":{"id":"32063","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.60781,30.174858],[-85.740638,30.187612],[-85.738159,30.175144],[-85.684692,30.151285],[-85.621109,30.130239],[-85.60781,30.174858]]]},"properties":{"id":"32401","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.388786,29.924385],[-85.390151,29.973987],[-85.391296,30.027635],[-85.448677,30.02877],[-85.447192,29.95196],[-85.388786,29.924385]]]},"properties":{"id":"32410","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.133368,30.38809],[-86.045857,30.351291],[-85.994712,30.311702],[-85.967864,30.385877],[-85.992147,30.406745],[-86.058878,30.401211],[-86.117346,30.400795],[-86.133368,30.38809]]],[[[-85.860373,30.497312],[-85.822882,30.481659],[-85.717302,30.550565],[-85.679628,30.657838],[-85.74264,30.667763],[-85.906049,30.643964],[-85.860373,30.497312]]]]},"properties":{"id":"32462","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.671838,27.999032],[-80.737283,27.99893],[-80.720535,27.910513],[-80.671838,27.999032]]]},"properties":{"id":"32908","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.656648,28.035346],[-80.720298,28.042425],[-80.737283,27.99893],[-80.671838,27.999032],[-80.613611,27.998343],[-80.601677,28.012897],[-80.656648,28.035346]]]},"properties":{"id":"32907","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.734688,28.402859],[-80.754956,28.349273],[-80.715376,28.349484],[-80.717794,28.355695],[-80.734688,28.402859]]]},"properties":{"id":"32922","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.532161,27.96356],[-80.604038,27.931513],[-80.598525,27.910005],[-80.498118,27.895911],[-80.532161,27.96356]]]},"properties":{"id":"32949","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.633605,28.209132],[-80.592431,28.212689],[-80.601274,28.271987],[-80.648975,28.271787],[-80.633605,28.209132]]]},"properties":{"id":"32925","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.214756,26.046479],[-80.200802,26.046925],[-80.20204,26.088114],[-80.245402,26.086922],[-80.24761,26.045591],[-80.214756,26.046479]]]},"properties":{"id":"33314","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.400541,26.135523],[-80.443026,26.061597],[-80.392935,26.09799],[-80.400541,26.135523]]]},"properties":{"id":"33327","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.250264,26.287033],[-80.251225,26.341718],[-80.28347,26.351455],[-80.297766,26.273075],[-80.250264,26.287033]]]},"properties":{"id":"33076","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.202937,26.32783],[-80.201831,26.274249],[-80.186447,26.272094],[-80.168784,26.27423],[-80.152064,26.274541],[-80.152367,26.289523],[-80.170149,26.327781],[-80.185403,26.327786],[-80.202937,26.32783]]]},"properties":{"id":"33073","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.119684,26.304643],[-80.116782,26.327845],[-80.128319,26.327675],[-80.170149,26.327781],[-80.152367,26.289523],[-80.119684,26.304643]]]},"properties":{"id":"33442","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.443026,26.061597],[-80.440928,25.956858],[-80.44049,25.956861],[-80.395313,26.029345],[-80.381318,26.094069],[-80.392935,26.09799],[-80.443026,26.061597]]]},"properties":{"id":"33332","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.109237,26.121391],[-80.143101,26.111237],[-80.139423,26.081327],[-80.108039,26.092967],[-80.102475,26.093062],[-80.098362,26.137868],[-80.102892,26.138051],[-80.109237,26.121391]]]},"properties":{"id":"33316","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.207589,26.01017],[-80.18334,25.994622],[-80.167265,25.99622],[-80.167612,26.047957],[-80.168103,26.047946],[-80.200802,26.046925],[-80.214756,26.046479],[-80.207589,26.01017]]]},"properties":{"id":"33021","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.223067,26.272181],[-80.250264,26.287033],[-80.297766,26.273075],[-80.297863,26.258381],[-80.234152,26.256985],[-80.223067,26.272181]]]},"properties":{"id":"33065","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.162283,30.407515],[-85.183123,30.330893],[-85.215713,30.222352],[-85.217404,30.20099],[-85.11566,30.199976],[-84.932519,30.606393],[-84.934421,30.60636],[-85.021833,30.576015],[-85.162283,30.407515]]]},"properties":{"id":"32424","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991486,26.884875],[-81.890542,26.806033],[-81.76074,26.7696],[-81.565931,26.769552],[-81.563767,26.944699],[-81.562202,27.033836],[-81.958667,27.03505],[-81.99154,27.032075],[-82.019383,26.966738],[-81.991486,26.884875]]]},"properties":{"id":"33982","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.637392,29.017763],[-82.759294,28.99247],[-82.761425,28.990488],[-82.70957,28.90743],[-82.534092,28.891967],[-82.543049,28.94954],[-82.565931,29.034809],[-82.637392,29.017763]]]},"properties":{"id":"34428","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.390291,28.854276],[-82.348865,28.835974],[-82.237395,28.865508],[-82.282066,28.916376],[-82.419515,28.877921],[-82.411014,28.856566],[-82.390291,28.854276]]]},"properties":{"id":"34453","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.864054,30.143602],[-81.756782,30.112335],[-81.735395,30.134658],[-81.808253,30.189046],[-81.826533,30.188739],[-81.845945,30.188538],[-81.864054,30.143602]]]},"properties":{"id":"32065","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.833558,26.287376],[-81.82165,26.219385],[-81.792283,26.211333],[-81.789867,26.272271],[-81.833855,26.287765],[-81.833558,26.287376]]]},"properties":{"id":"34108","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778443,26.076628],[-81.78691,26.09997],[-81.803421,26.09226],[-81.754229,25.968563],[-81.74981,25.970503],[-81.778443,26.076628]]]},"properties":{"id":"34101","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.62463,29.9527],[-82.793975,29.986202],[-82.783137,29.954637],[-82.744521,29.869146],[-82.62463,29.9527]]]},"properties":{"id":"32038","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.895308,27.144924],[-81.890984,27.151147],[-81.898029,27.151187],[-81.895308,27.144924]]]},"properties":{"id":"34268","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.558496,30.183222],[-81.479345,30.104774],[-81.478979,30.104784],[-81.436585,30.237046],[-81.516235,30.253058],[-81.552534,30.250113],[-81.60312,30.243035],[-81.589138,30.220709],[-81.558496,30.183222]]]},"properties":{"id":"32256","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.869516,30.307426],[-81.9204,30.186941],[-81.868048,30.188018],[-81.835743,30.247154],[-81.767301,30.299401],[-81.770547,30.315082],[-81.869516,30.307426]]]},"properties":{"id":"32221","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.796047,30.246186],[-81.681698,30.244247],[-81.653016,30.24394],[-81.665316,30.25764],[-81.684118,30.283239],[-81.767301,30.299401],[-81.835743,30.247154],[-81.796047,30.246186]]]},"properties":{"id":"32210","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.66752,30.314325],[-81.667048,30.33578],[-81.6968,30.33179],[-81.697002,30.321593],[-81.676617,30.288739],[-81.66752,30.314325]]]},"properties":{"id":"32204","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.402744,30.390499],[-81.399102,30.387901],[-81.398751,30.392039],[-81.402744,30.390499]]]},"properties":{"id":"32228","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.238319,30.503504],[-87.167989,30.454056],[-87.162167,30.506994],[-87.238319,30.503504]]]},"properties":{"id":"32504","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.467128,30.997833],[-87.290095,30.909395],[-87.163084,30.999045],[-87.467128,30.997833]]]},"properties":{"id":"32535","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.405799,30.66672],[-87.266704,30.670294],[-87.299614,30.766108],[-87.305796,30.777408],[-87.463749,30.740898],[-87.405799,30.66672]]]},"properties":{"id":"32577","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.54445,30.01103],[-84.78428,29.81438],[-84.670525,29.768883],[-84.4999,29.887032],[-84.52042,29.92337],[-84.5706,29.90614],[-84.618686,29.869145],[-84.63932,29.90705],[-84.57292,29.90968],[-84.49874,29.97261],[-84.54445,30.01103]]]},"properties":{"id":"32322","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.11566,30.199976],[-85.025439,29.971399],[-84.941116,30.013436],[-84.875406,30.338858],[-84.876912,30.36486],[-84.781787,30.516732],[-84.932519,30.606393],[-85.11566,30.199976]]]},"properties":{"id":"32321","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.40721,30.47333],[-84.518435,30.528783],[-84.530567,30.439802],[-84.413639,30.470568],[-84.40721,30.47333]]]},"properties":{"id":"32343","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.518435,30.528783],[-84.40721,30.47333],[-84.397406,30.485008],[-84.359487,30.585428],[-84.282559,30.685334],[-84.467175,30.69257],[-84.511179,30.571835],[-84.518435,30.528783]]]},"properties":{"id":"32333","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.217404,30.20099],[-85.391229,30.028188],[-85.391296,30.027635],[-85.390151,29.973987],[-85.32147,29.954819],[-85.013919,29.94409],[-85.025439,29.971399],[-85.11566,30.199976],[-85.217404,30.20099]]]},"properties":{"id":"32465","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.932291,27.551837],[-82.054467,27.595246],[-82.055016,27.512449],[-82.055753,27.338264],[-81.829029,27.340122],[-81.852589,27.440065],[-81.932291,27.551837]]]},"properties":{"id":"33865","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.479437,28.533926],[-82.485053,28.433697],[-82.402365,28.43402],[-82.373097,28.43403],[-82.375978,28.492561],[-82.477205,28.533947],[-82.479437,28.533926]]]},"properties":{"id":"34604","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.656053,28.576734],[-82.678742,28.433456],[-82.666987,28.433588],[-82.657179,28.433625],[-82.63986,28.433844],[-82.571456,28.518856],[-82.656053,28.576734]]]},"properties":{"id":"34607","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.508403,27.551377],[-81.563581,27.53077],[-81.563562,27.485478],[-81.495051,27.470867],[-81.508403,27.551377]]]},"properties":{"id":"33872","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.485301,28.033479],[-82.514123,28.036723],[-82.527625,28.037313],[-82.526103,27.996153],[-82.485549,27.981297],[-82.485213,28.001436],[-82.485301,28.033479]]]},"properties":{"id":"33614","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.481717,27.923994],[-82.443539,27.914902],[-82.44826,27.923604],[-82.464737,27.95637],[-82.482982,27.952086],[-82.483044,27.934789],[-82.481717,27.923994]]]},"properties":{"id":"33606","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.353443,28.086998],[-82.259741,28.171462],[-82.3537,28.17128],[-82.394083,28.171127],[-82.409352,28.086856],[-82.380635,28.074592],[-82.353443,28.086998]]]},"properties":{"id":"33647","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.187885,27.880207],[-82.193845,27.88798],[-82.269292,27.857304],[-82.269293,27.857227],[-82.232805,27.821306],[-82.240957,27.803639],[-82.146271,27.646285],[-82.054349,27.646382],[-82.054874,27.907726],[-82.187885,27.880207]]]},"properties":{"id":"33547","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.386392,27.673756],[-82.453735,27.64591],[-82.146271,27.646285],[-82.240957,27.803639],[-82.2667,27.763013],[-82.281531,27.759193],[-82.290456,27.770193],[-82.335068,27.767426],[-82.386392,27.673756]]]},"properties":{"id":"33598","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.232805,27.821306],[-82.329949,27.827927],[-82.335047,27.773118],[-82.335068,27.767426],[-82.290456,27.770193],[-82.2667,27.763013],[-82.240957,27.803639],[-82.232805,27.821306]]]},"properties":{"id":"33579","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.544227,28.189589],[-82.550656,28.106786],[-82.546411,28.106219],[-82.516855,28.113192],[-82.466634,28.187173],[-82.466126,28.19196],[-82.544227,28.189589]]]},"properties":{"id":"33558","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.055634,27.970764],[-82.180016,27.966965],[-82.187885,27.880207],[-82.054874,27.907726],[-82.055304,27.938178],[-82.055634,27.970764]]]},"properties":{"id":"33567","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.612454,28.003107],[-82.574958,28.039863],[-82.582788,28.039729],[-82.648557,28.03863],[-82.648524,28.017041],[-82.612454,28.003107]]]},"properties":{"id":"33635","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.466634,28.187173],[-82.516855,28.113192],[-82.502402,28.113196],[-82.481974,28.112586],[-82.461532,28.116694],[-82.466634,28.187173]]]},"properties":{"id":"33548","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.484028,28.065972],[-82.420496,28.065986],[-82.402171,28.069115],[-82.377343,28.069267],[-82.380635,28.074592],[-82.409352,28.086856],[-82.451978,28.096531],[-82.461532,28.116694],[-82.481974,28.112586],[-82.484028,28.065972]]]},"properties":{"id":"33613","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.290456,27.770193],[-82.281531,27.759193],[-82.2667,27.763013],[-82.290456,27.770193]]]},"properties":{"id":"33503","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.976679,28.080581],[-82.019005,28.215502],[-82.085905,28.171619],[-82.055946,28.036937],[-82.01475,28.052405],[-81.976679,28.080581]]]},"properties":{"id":"33810","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.44132,27.7515],[-80.46195,27.75184],[-80.46189,27.74856],[-80.44132,27.7515]]]},"properties":{"id":"32970","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.339206,30.784948],[-85.07906,30.712751],[-85.078945,30.722449],[-85.061115,30.814131],[-85.194435,30.925864],[-85.240092,31.000884],[-85.328917,30.99934],[-85.367617,30.887343],[-85.339206,30.784948]]]},"properties":{"id":"32446","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.194435,30.925864],[-85.061115,30.814131],[-85.026842,30.831798],[-85.035137,30.879819],[-85.155343,30.927889],[-85.194435,30.925864]]]},"properties":{"id":"32443","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.782229,28.594739],[-81.69245,28.568972],[-81.657212,28.595168],[-81.658028,28.65892],[-81.682974,28.662053],[-81.754842,28.698178],[-81.804494,28.650388],[-81.782229,28.594739]]]},"properties":{"id":"34715","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.684681,28.723901],[-81.75701,28.725107],[-81.754842,28.698178],[-81.682974,28.662053],[-81.684681,28.723901]]]},"properties":{"id":"34705","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.585002,28.830242],[-81.646074,28.824149],[-81.690326,28.821934],[-81.684681,28.723901],[-81.682974,28.662053],[-81.658028,28.65892],[-81.658082,28.67668],[-81.604315,28.729346],[-81.593153,28.749114],[-81.566616,28.786019],[-81.585002,28.830242]]]},"properties":{"id":"32757","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.472247,28.98148],[-81.547015,28.930982],[-81.658583,28.890901],[-81.646074,28.824149],[-81.585002,28.830242],[-81.375088,28.863366],[-81.36694,28.879227],[-81.349723,28.939641],[-81.472247,28.98148]]]},"properties":{"id":"32736","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.761182,28.822444],[-81.743209,28.873021],[-81.741221,28.913756],[-81.842194,28.961024],[-81.839211,28.894029],[-81.847519,28.829011],[-81.816252,28.808829],[-81.779863,28.817072],[-81.761182,28.822444]]]},"properties":{"id":"34788","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.566616,28.786019],[-81.414446,28.784955],[-81.413762,28.792604],[-81.375088,28.863366],[-81.585002,28.830242],[-81.566616,28.786019]]]},"properties":{"id":"32776","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.594046,28.979592],[-81.70333,29.003905],[-81.812847,29.081871],[-81.836227,28.991364],[-81.842194,28.961024],[-81.741221,28.913756],[-81.719461,28.89518],[-81.658583,28.890901],[-81.547015,28.930982],[-81.594046,28.979592]]]},"properties":{"id":"32784","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.640647,29.144296],[-81.566244,29.08098],[-81.501477,29.099725],[-81.502797,29.106274],[-81.536046,29.215896],[-81.612441,29.203538],[-81.641363,29.20496],[-81.640647,29.144296]]]},"properties":{"id":"32102","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.974143,26.652288],[-82.068798,26.563442],[-81.97419,26.607656],[-81.974156,26.652058],[-81.974143,26.652288]]]},"properties":{"id":"33991","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.068798,26.563442],[-82.028437,26.480245],[-81.973244,26.531754],[-81.974192,26.606809],[-81.97419,26.607656],[-82.068798,26.563442]]]},"properties":{"id":"33914","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.923951,26.652811],[-81.974156,26.652058],[-81.97419,26.607656],[-81.974192,26.606809],[-81.904078,26.603201],[-81.901373,26.627561],[-81.923951,26.652811]]]},"properties":{"id":"33990","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.359487,30.585428],[-84.263411,30.475133],[-84.252118,30.501784],[-84.072739,30.675455],[-84.282559,30.685334],[-84.359487,30.585428]]]},"properties":{"id":"32312","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.5919,29.33317],[-82.559896,29.478349],[-82.639654,29.521288],[-82.67672,29.52872],[-82.705605,29.490178],[-82.714621,29.476251],[-82.62374,29.31035],[-82.5919,29.33317]]]},"properties":{"id":"32621","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.9249,29.28555],[-83.077348,29.253826],[-83.022536,29.090774],[-82.9017,29.26645],[-82.9249,29.28555]]]},"properties":{"id":"32625","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.759294,28.99247],[-82.806056,29.053173],[-82.761425,28.990488],[-82.759294,28.99247]]]},"properties":{"id":"34498","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.714935,27.531425],[-82.735599,27.518384],[-82.691521,27.443517],[-82.680021,27.453517],[-82.680483,27.45396],[-82.695421,27.480816],[-82.698629,27.524338],[-82.714935,27.531425]]]},"properties":{"id":"34217","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.680483,27.45396],[-82.67175,27.480709],[-82.695421,27.480816],[-82.680483,27.45396]]]},"properties":{"id":"34215","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.992619,29.183515],[-81.958263,29.059362],[-81.98268,29.029361],[-81.948346,29.000183],[-81.836227,28.991364],[-81.812847,29.081871],[-81.820865,29.169173],[-81.992619,29.183515]]]},"properties":{"id":"32179","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.311697,28.960391],[-82.22538,28.960163],[-82.252555,29.01173],[-82.368028,29.187252],[-82.36758,29.215708],[-82.442801,29.215224],[-82.464738,29.045817],[-82.349512,28.988765],[-82.311697,28.960391]]]},"properties":{"id":"34432","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18487,29.186387],[-82.188903,29.330502],[-82.384993,29.337756],[-82.36758,29.215708],[-82.368028,29.187252],[-82.251671,29.186775],[-82.18487,29.186387]]]},"properties":{"id":"34482","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.135232,29.323164],[-82.132731,29.309185],[-82.123989,29.330947],[-82.135232,29.323164]]],[[[-82.080199,29.222939],[-82.053903,29.24151],[-82.134751,29.295854],[-82.135839,29.21534],[-82.080199,29.222939]]]]},"properties":{"id":"34479","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.914301,29.0137],[-81.904288,29.01851],[-81.912339,29.025731],[-81.914301,29.0137]]]},"properties":{"id":"32133","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.457233,25.504737],[-80.559736,25.49859],[-80.562766,25.481925],[-80.462975,25.462449],[-80.457233,25.504737]]]},"properties":{"id":"33030","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.411919,25.462718],[-80.46079,25.462467],[-80.46064,25.440523],[-80.411919,25.462718]]],[[[-80.436477,25.389124],[-80.37674,25.366958],[-80.375407,25.448028],[-80.436477,25.389124]]]]},"properties":{"id":"33035","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.385585,25.768157],[-80.432863,25.760899],[-80.432512,25.751093],[-80.385017,25.75283],[-80.385585,25.768157]]]},"properties":{"id":"33184","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439612,25.941374],[-80.483352,25.797272],[-80.385953,25.797075],[-80.337263,25.805304],[-80.344031,25.869573],[-80.439612,25.941374]]]},"properties":{"id":"33178","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.255457,25.771834],[-80.2147,25.774514],[-80.214792,25.778337],[-80.215108,25.793216],[-80.264206,25.793564],[-80.258217,25.772529],[-80.255457,25.771834]]]},"properties":{"id":"33125","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.336426,25.769605],[-80.385585,25.768157],[-80.385017,25.75283],[-80.384749,25.746344],[-80.33592,25.754905],[-80.334912,25.754949],[-80.336426,25.769605]]]},"properties":{"id":"33174","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.216553,25.831211],[-80.215108,25.793216],[-80.194455,25.795623],[-80.19653,25.832017],[-80.216553,25.831211]]]},"properties":{"id":"33127","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.363446,25.568437],[-80.331444,25.559427],[-80.306155,25.581601],[-80.380577,25.58484],[-80.380747,25.567179],[-80.363446,25.568437]]]},"properties":{"id":"33189","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.404355,24.728705],[-81.410821,24.613756],[-81.249799,24.662896],[-81.288241,24.720886],[-81.404355,24.728705]]]},"properties":{"id":"33043","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.467891,25.036735],[-80.433555,25.236375],[-80.487034,25.212306],[-80.51148,25.062589],[-80.467891,25.036735]]]},"properties":{"id":"33037","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.962558,30.520942],[-81.843252,30.456592],[-81.765316,30.518652],[-81.660896,30.561646],[-81.79134,30.67815],[-81.962558,30.520942]]]},"properties":{"id":"32011","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.550926,30.543027],[-81.434777,30.506896],[-81.465398,30.711258],[-81.550926,30.543027]]]},"properties":{"id":"32034","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.537979,30.448081],[-86.5708,30.475137],[-86.579145,30.480448],[-86.596014,30.477798],[-86.59395,30.430439],[-86.588263,30.42921],[-86.537979,30.448081]]]},"properties":{"id":"32579","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.382464,30.851163],[-86.296459,30.99375],[-86.542488,30.99427],[-86.594533,30.861211],[-86.528264,30.852305],[-86.382464,30.851163]]]},"properties":{"id":"32567","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.50794,30.534638],[-86.478624,30.482996],[-86.474329,30.479271],[-86.332768,30.475608],[-86.352299,30.468032],[-86.50794,30.534638]]]},"properties":{"id":"32578","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.672301,30.428205],[-86.735485,30.433038],[-86.742235,30.415096],[-86.714696,30.407277],[-86.672301,30.428205]]]},"properties":{"id":"32544","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.881703,30.704717],[-86.793582,30.636646],[-86.651119,30.68232],[-86.648626,30.716284],[-86.864758,30.808243],[-86.881703,30.704717]]]},"properties":{"id":"32564","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.593153,28.749114],[-81.584605,28.712408],[-81.459383,28.683745],[-81.416102,28.755702],[-81.414446,28.784955],[-81.566616,28.786019],[-81.593153,28.749114]]]},"properties":{"id":"32712","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.355406,28.531363],[-81.38485,28.531057],[-81.384758,28.507126],[-81.374288,28.487469],[-81.352325,28.491222],[-81.331137,28.531647],[-81.332048,28.53148],[-81.355406,28.531363]]]},"properties":{"id":"32806","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.508806,28.527778],[-81.508862,28.545381],[-81.532977,28.5227],[-81.508806,28.527778]]]},"properties":{"id":"34734","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.276578,28.568364],[-81.211753,28.565098],[-81.211775,28.567338],[-81.207598,28.611982],[-81.267002,28.611603],[-81.278304,28.582933],[-81.276578,28.568364]]]},"properties":{"id":"32817","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.211753,28.565098],[-81.276578,28.568364],[-81.277467,28.544709],[-81.271358,28.509988],[-81.230454,28.451416],[-81.166531,28.451725],[-81.187118,28.495525],[-81.207431,28.565148],[-81.211753,28.565098]]]},"properties":{"id":"32825","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.207431,28.565148],[-81.154201,28.565418],[-81.157808,28.61229],[-81.207598,28.611982],[-81.211775,28.567338],[-81.207431,28.565148]]]},"properties":{"id":"32826","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.979126,28.452577],[-80.87243,28.369591],[-80.896835,28.452827],[-80.932721,28.543488],[-80.98725,28.612997],[-81.106182,28.612643],[-81.09523,28.548774],[-80.979126,28.452577]]]},"properties":{"id":"32709","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.234204,28.062255],[-81.341699,28.08263],[-81.45965,28.051694],[-81.424547,28.012476],[-81.201633,27.804798],[-80.880772,27.777777],[-80.868881,27.822522],[-80.869745,28.012636],[-81.234204,28.062255]]]},"properties":{"id":"34739","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.650027,28.23255],[-81.539203,28.227667],[-81.536656,28.263606],[-81.54356,28.276185],[-81.656945,28.259217],[-81.650027,28.23255]]]},"properties":{"id":"33896","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.14651,26.424121],[-80.147159,26.483917],[-80.172167,26.48375],[-80.220065,26.483226],[-80.223941,26.409571],[-80.203788,26.413489],[-80.14651,26.424121]]]},"properties":{"id":"33446","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.112805,26.636858],[-80.107347,26.693199],[-80.111564,26.692866],[-80.128358,26.692414],[-80.145982,26.634219],[-80.112956,26.633163],[-80.112805,26.636858]]]},"properties":{"id":"33415","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.071101,26.690767],[-80.068996,26.646598],[-80.043467,26.644394],[-80.046363,26.690443],[-80.071101,26.690767]]]},"properties":{"id":"33405","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.293476,26.818951],[-80.262783,26.765254],[-80.134393,26.750637],[-80.131724,26.795428],[-80.264359,26.883348],[-80.293476,26.818951]]]},"properties":{"id":"33412","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.62921,26.855473],[-80.630982,26.86484],[-80.696736,26.783546],[-80.612603,26.812689],[-80.62921,26.855473]]]},"properties":{"id":"33476","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.18663,26.367895],[-80.185403,26.327786],[-80.170149,26.327781],[-80.128319,26.327675],[-80.135219,26.363913],[-80.137298,26.368749],[-80.18663,26.367895]]]},"properties":{"id":"33433","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.145982,26.634219],[-80.128358,26.692414],[-80.147387,26.701187],[-80.174107,26.650929],[-80.173812,26.632092],[-80.146057,26.632497],[-80.145982,26.634219]]]},"properties":{"id":"33413","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.59012,28.27403],[-82.673917,28.240084],[-82.698941,28.21699],[-82.719879,28.173173],[-82.651165,28.173266],[-82.548258,28.208722],[-82.59012,28.27403]]]},"properties":{"id":"34655","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.503112,28.321231],[-82.449642,28.256664],[-82.416398,28.323185],[-82.438567,28.321513],[-82.503112,28.321231]]]},"properties":{"id":"34637","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.600553,28.390071],[-82.569068,28.329906],[-82.503112,28.321231],[-82.438567,28.321513],[-82.43103,28.382575],[-82.402365,28.43402],[-82.485053,28.433697],[-82.548661,28.433834],[-82.57834,28.433861],[-82.600553,28.390071]]]},"properties":{"id":"34610","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.600553,28.390071],[-82.666109,28.331013],[-82.666107,28.330312],[-82.574698,28.329288],[-82.569068,28.329906],[-82.600553,28.390071]]]},"properties":{"id":"34669","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.679734,27.864822],[-82.635352,27.903903],[-82.699092,27.923458],[-82.704347,27.868264],[-82.679734,27.864822]]]},"properties":{"id":"33762","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.753945,27.829054],[-82.699915,27.828308],[-82.678757,27.82835],[-82.677938,27.844358],[-82.747085,27.848296],[-82.753945,27.829054]]]},"properties":{"id":"33781","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.709243,28.060096],[-82.64858,28.060025],[-82.649089,28.091142],[-82.713991,28.136382],[-82.726158,28.122916],[-82.709243,28.060096]]]},"properties":{"id":"34685","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.782427,27.810942],[-82.772814,27.80044],[-82.76989,27.813801],[-82.782427,27.810942]]]},"properties":{"id":"33744","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.805425,27.975954],[-82.762606,27.960588],[-82.762295,27.960586],[-82.762447,27.992588],[-82.763096,27.997252],[-82.801224,27.997796],[-82.805425,27.975954]]]},"properties":{"id":"33755","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.69984,27.770924],[-82.698398,27.806415],[-82.699196,27.806417],[-82.763818,27.807927],[-82.769023,27.795703],[-82.752623,27.776204],[-82.69984,27.770924]]]},"properties":{"id":"33710","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.713991,28.136382],[-82.649089,28.091142],[-82.649149,28.098901],[-82.651165,28.173266],[-82.719879,28.173173],[-82.724079,28.173221],[-82.713991,28.136382]]]},"properties":{"id":"34688","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.740272,28.027463],[-82.728931,28.010807],[-82.70892,28.012165],[-82.709235,28.048992],[-82.742902,28.04879],[-82.74279,28.044212],[-82.740272,28.027463]]]},"properties":{"id":"33761","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.78388,27.865268],[-82.787097,27.89464],[-82.784514,27.902029],[-82.806003,27.900621],[-82.815967,27.865625],[-82.811706,27.863653],[-82.78388,27.865268]]]},"properties":{"id":"33778","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.692178,28.105118],[-81.743964,28.182168],[-81.756497,28.117501],[-81.768056,28.071387],[-81.692178,28.105118]]]},"properties":{"id":"33850","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.01464,28.033989],[-82.055651,28.02576],[-82.055634,27.970764],[-82.055304,27.938178],[-81.976295,27.938516],[-81.983009,27.996973],[-82.01464,28.033989]]]},"properties":{"id":"33811","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.594911,29.625666],[-81.52366,29.622432],[-81.52523,29.759497],[-81.528261,29.760777],[-81.541733,29.771042],[-81.594911,29.625666]]]},"properties":{"id":"32131","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.86629,29.80073],[-81.913329,29.791749],[-81.895882,29.713137],[-81.846431,29.711511],[-81.829766,29.822904],[-81.86629,29.80073]]]},"properties":{"id":"32140","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.573626,29.348709],[-81.433992,29.398552],[-81.515228,29.486723],[-81.556828,29.533992],[-81.56894,29.48613],[-81.569749,29.48063],[-81.644262,29.453787],[-81.687924,29.467091],[-81.657547,29.392],[-81.594518,29.343947],[-81.573626,29.348709]]]},"properties":{"id":"32112","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.687924,29.467091],[-81.644262,29.453787],[-81.62704,29.52116],[-81.67824,29.479051],[-81.687924,29.467091]]]},"properties":{"id":"32193","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.307024,29.86714],[-81.37926,29.825407],[-81.346671,29.747936],[-81.286766,29.651298],[-81.21929,29.670617],[-81.307024,29.86714]]]},"properties":{"id":"32086","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.420804,27.289022],[-80.44012,27.34516],[-80.579668,27.250525],[-80.578838,27.206043],[-80.43239,27.206025],[-80.400706,27.205778],[-80.420804,27.289022]]]},"properties":{"id":"34987","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.41024,27.37457],[-80.3882,27.41612],[-80.425696,27.470109],[-80.480353,27.558106],[-80.521197,27.558589],[-80.679821,27.55847],[-80.677753,27.302218],[-80.579668,27.250525],[-80.44012,27.34516],[-80.41024,27.37457]]]},"properties":{"id":"34945","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.321226,27.242454],[-80.324318,27.271874],[-80.370265,27.295875],[-80.369779,27.293565],[-80.331821,27.205724],[-80.321226,27.242454]]]},"properties":{"id":"34984","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458153,27.176058],[-82.502519,27.180118],[-82.47388,27.111519],[-82.417262,27.113534],[-82.361461,27.153693],[-82.438891,27.190177],[-82.458153,27.176058]]]},"properties":{"id":"34275","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.349123,28.677509],[-81.343698,28.661909],[-81.32887,28.644067],[-81.315874,28.625161],[-81.27855,28.641475],[-81.325749,28.697908],[-81.349123,28.677509]]]},"properties":{"id":"32707","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.413762,28.792604],[-81.301331,28.786466],[-81.180586,28.785957],[-81.210452,28.802453],[-81.319796,28.834532],[-81.36694,28.879227],[-81.375088,28.863366],[-81.413762,28.792604]]]},"properties":{"id":"32771","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.301331,28.786466],[-81.309935,28.730489],[-81.309187,28.721707],[-81.248516,28.71168],[-81.191448,28.73932],[-81.178936,28.781886],[-81.180586,28.785957],[-81.301331,28.786466]]]},"properties":{"id":"32773","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.191448,28.73932],[-81.169945,28.655316],[-81.02751,28.704577],[-81.055525,28.784839],[-81.178936,28.781886],[-81.191448,28.73932]]]},"properties":{"id":"32732","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.753208,30.131914],[-83.4942,30.0906],[-83.59432,30.27198],[-83.70871,30.25988],[-83.753208,30.131914]]]},"properties":{"id":"32347","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983093,29.153106],[-80.958751,29.143273],[-81.023024,29.2702],[-81.039777,29.263857],[-81.022279,29.230231],[-80.998576,29.187359],[-80.983093,29.153106]]]},"properties":{"id":"32118","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983093,29.153106],[-81.02899,29.106041],[-81.021477,29.090794],[-80.935631,29.062225],[-80.911412,29.076889],[-80.958751,29.143273],[-80.983093,29.153106]]]},"properties":{"id":"32127","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.022279,29.230231],[-81.102994,29.220003],[-81.075573,29.155531],[-81.073418,29.156143],[-80.998576,29.187359],[-81.022279,29.230231]]]},"properties":{"id":"32114","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.191071,30.17118],[-84.221855,30.166565],[-84.211755,30.149141],[-84.191071,30.17118]]]},"properties":{"id":"32355","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.332768,30.475608],[-86.117346,30.400795],[-86.058878,30.401211],[-86.016816,30.544228],[-86.107295,30.560067],[-86.352299,30.468032],[-86.332768,30.475608]]]},"properties":{"id":"32439","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.74264,30.667763],[-85.821616,30.790775],[-85.826734,30.745322],[-85.906049,30.643964],[-85.74264,30.667763]]]},"properties":{"id":"32427","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.216878,29.45504],[-82.213567,29.432825],[-82.207821,29.427463],[-82.196624,29.422437],[-81.996935,29.501701],[-81.940971,29.512542],[-81.945787,29.681018],[-82.104447,29.738442],[-82.11712,29.745859],[-82.180034,29.780008],[-82.188943,29.72455],[-82.245293,29.573796],[-82.216878,29.45504]]]},"properties":{"id":"32640","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.773555,29.768111],[-82.661544,29.726131],[-82.607114,29.712833],[-82.55882,29.926512],[-82.560889,29.931279],[-82.62463,29.9527],[-82.744521,29.869146],[-82.779735,29.857735],[-82.773555,29.768111]]]},"properties":{"id":"32643","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.241061,29.852185],[-82.218773,29.899815],[-82.248132,29.945335],[-82.418728,29.923093],[-82.376884,29.866991],[-82.376686,29.839945],[-82.241061,29.852185]]]},"properties":{"id":"32622","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.301776,29.666512],[-82.339157,29.666676],[-82.339171,29.659186],[-82.339323,29.652056],[-82.339314,29.644902],[-82.339323,29.641876],[-82.334642,29.617039],[-82.321955,29.630325],[-82.301776,29.666512]]]},"properties":{"id":"32601","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.412932,29.485092],[-82.323877,29.427781],[-82.220402,29.430329],[-82.216878,29.45504],[-82.245293,29.573796],[-82.321955,29.630325],[-82.334642,29.617039],[-82.414534,29.555462],[-82.412932,29.485092]]]},"properties":{"id":"32667","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.387985,30.421724],[-85.587402,30.302154],[-85.61856,30.264133],[-85.607131,30.233266],[-85.60781,30.174858],[-85.621109,30.130239],[-85.448677,30.02877],[-85.391296,30.027635],[-85.391229,30.028188],[-85.387985,30.421724]]]},"properties":{"id":"32404","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.738159,30.175144],[-85.809123,30.17005],[-85.634625,30.06492],[-85.684692,30.151285],[-85.738159,30.175144]]]},"properties":{"id":"32408","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.17148,30.04843],[-82.047737,29.993716],[-82.049201,30.055904],[-82.049424,30.165981],[-82.145454,30.143091],[-82.186629,30.142742],[-82.17846,30.05867],[-82.17148,30.04843]]]},"properties":{"id":"32058","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.672016,28.184419],[-80.672636,28.107095],[-80.671453,28.107085],[-80.603976,28.107093],[-80.613424,28.134078],[-80.651189,28.206243],[-80.672016,28.184419]]]},"properties":{"id":"32935","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.720298,28.042425],[-80.656648,28.035346],[-80.638188,28.035192],[-80.671453,28.107085],[-80.672636,28.107095],[-80.719866,28.078812],[-80.720298,28.042425]]]},"properties":{"id":"32904","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.59516,28.379716],[-80.648975,28.372783],[-80.648975,28.271787],[-80.601274,28.271987],[-80.59516,28.379716]]]},"properties":{"id":"32931","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.251113,26.090656],[-80.250674,26.135591],[-80.297725,26.135471],[-80.297721,26.131227],[-80.297948,26.087751],[-80.251113,26.090656]]]},"properties":{"id":"33324","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.106126,26.259102],[-80.108563,26.209824],[-80.08875,26.204941],[-80.072167,26.305261],[-80.080665,26.297057],[-80.106126,26.259102]]]},"properties":{"id":"33062","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.108563,26.209824],[-80.106126,26.259102],[-80.132881,26.260603],[-80.138427,26.209066],[-80.138173,26.208996],[-80.123127,26.208375],[-80.108563,26.209824]]]},"properties":{"id":"33060","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.145425,26.118558],[-80.166365,26.07024],[-80.139423,26.081327],[-80.143101,26.111237],[-80.145425,26.118558]]]},"properties":{"id":"33315","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.377248,26.029375],[-80.375977,26.007731],[-80.312016,26.007856],[-80.3126,26.029543],[-80.345101,26.029706],[-80.377248,26.029375]]]},"properties":{"id":"33028","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.30417,26.961532],[-82.437986,27.055796],[-82.450078,27.060683],[-82.342603,26.89349],[-82.33626,26.90776],[-82.30417,26.961532]]]},"properties":{"id":"34223","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.146394,27.012875],[-82.186642,26.969106],[-82.112644,26.955098],[-82.124765,27.018017],[-82.146394,27.012875]]]},"properties":{"id":"33948","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.816697,26.173243],[-81.803421,26.09226],[-81.78691,26.09997],[-81.782821,26.138182],[-81.783962,26.159978],[-81.791511,26.174259],[-81.816697,26.173243]]]},"properties":{"id":"34102","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.746167,26.316896],[-81.804973,26.316288],[-81.833855,26.287765],[-81.789867,26.272271],[-81.742552,26.272888],[-81.746167,26.316896]]]},"properties":{"id":"34110","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.68852,30.17996],[-82.458063,30.201662],[-82.457507,30.257226],[-82.59839,30.41817],[-82.77687,30.29077],[-82.794721,30.203453],[-82.68852,30.17996]]]},"properties":{"id":"32055","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.458584,30.320286],[-81.516235,30.253058],[-81.436585,30.237046],[-81.430186,30.252243],[-81.430095,30.252243],[-81.424607,30.306941],[-81.438526,30.323343],[-81.458584,30.320286]]]},"properties":{"id":"32224","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.620727,30.321083],[-81.66752,30.314325],[-81.676617,30.288739],[-81.684118,30.283239],[-81.665316,30.25764],[-81.620394,30.264938],[-81.599401,30.313968],[-81.620727,30.321083]]]},"properties":{"id":"32207","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.661935,30.341946],[-81.666611,30.364501],[-81.730476,30.391753],[-81.727501,30.367875],[-81.6968,30.33179],[-81.667048,30.33578],[-81.661935,30.341946]]]},"properties":{"id":"32209","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.681698,30.244247],[-81.796047,30.246186],[-81.826533,30.188739],[-81.808253,30.189046],[-81.70747,30.196109],[-81.681698,30.244247]]]},"properties":{"id":"32244","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.412404,30.573552],[-87.367441,30.434495],[-87.29047,30.448445],[-87.279363,30.504317],[-87.334715,30.534538],[-87.412404,30.573552]],[[-87.346252,30.462098],[-87.346812,30.481096],[-87.331911,30.478742],[-87.332852,30.461815],[-87.346252,30.462098]]]},"properties":{"id":"32526","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.346252,30.462098],[-87.332852,30.461815],[-87.331911,30.478742],[-87.346812,30.481096],[-87.346252,30.462098]]]},"properties":{"id":"32509","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.779735,29.857735],[-82.91984,29.795714],[-82.959077,29.67116],[-82.773555,29.768111],[-82.779735,29.857735]]]},"properties":{"id":"32619","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.940658,30.577792],[-83.309249,30.634406],[-83.240123,30.520919],[-83.242731,30.501407],[-82.940658,30.577792]]]},"properties":{"id":"32053","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.182486,27.267178],[-81.295647,27.396564],[-81.401599,27.37334],[-81.563706,27.325876],[-81.562429,27.122829],[-81.267675,27.120976],[-81.182486,27.267178]]]},"properties":{"id":"33852","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.256499,27.446067],[-81.41927,27.441871],[-81.401599,27.37334],[-81.295647,27.396564],[-81.256499,27.446067]]]},"properties":{"id":"33876","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.999677,27.270259],[-81.205385,27.542973],[-81.360452,27.54716],[-81.256499,27.446067],[-81.295647,27.396564],[-81.182486,27.267178],[-80.999677,27.270259]]]},"properties":{"id":"33857","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.416326,28.032616],[-82.48416,28.033069],[-82.485301,28.033479],[-82.485213,28.001436],[-82.442954,27.996067],[-82.412822,28.012071],[-82.416326,28.032616]]]},"properties":{"id":"33604","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.482982,27.952086],[-82.551088,27.961325],[-82.533405,27.935601],[-82.483044,27.934789],[-82.482982,27.952086]]]},"properties":{"id":"33609","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.542679,27.886666],[-82.506356,27.869037],[-82.486373,27.864605],[-82.487799,27.908662],[-82.53277,27.908491],[-82.542679,27.886666]]]},"properties":{"id":"33611","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.560508,28.039472],[-82.566985,27.972903],[-82.526103,27.996153],[-82.527625,28.037313],[-82.54192,28.037912],[-82.560508,28.039472]]]},"properties":{"id":"33634","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.353443,28.086998],[-82.343875,28.036644],[-82.260698,28.03987],[-82.245063,28.05285],[-82.205362,28.148821],[-82.21196,28.171868],[-82.259741,28.171462],[-82.353443,28.086998]]]},"properties":{"id":"33592","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.307661,27.884378],[-82.333362,27.923066],[-82.403965,27.879218],[-82.403167,27.85236],[-82.356945,27.791819],[-82.360314,27.781557],[-82.335047,27.773118],[-82.329949,27.827927],[-82.307661,27.884378]]]},"properties":{"id":"33578","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.560508,28.039472],[-82.54192,28.037912],[-82.546411,28.106219],[-82.550656,28.106786],[-82.578857,28.085096],[-82.582788,28.039729],[-82.574958,28.039863],[-82.560508,28.039472]]]},"properties":{"id":"33625","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.453735,27.64591],[-82.386392,27.673756],[-82.383333,27.734601],[-82.478919,27.734032],[-82.554106,27.645295],[-82.453735,27.64591]]]},"properties":{"id":"33570","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.394083,28.171127],[-82.378177,28.226153],[-82.427753,28.186152],[-82.451978,28.096531],[-82.409352,28.086856],[-82.394083,28.171127]]]},"properties":{"id":"33559","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.43041,27.61653],[-80.521197,27.558589],[-80.480353,27.558106],[-80.398029,27.557522],[-80.41389,27.61639],[-80.43041,27.61653]]]},"properties":{"id":"32968","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.41389,27.61639],[-80.369689,27.626813],[-80.377559,27.660143],[-80.43092,27.66784],[-80.43041,27.61653],[-80.41389,27.61639]]]},"properties":{"id":"32960","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.324149,30.698672],[-85.339206,30.784948],[-85.367617,30.887343],[-85.429764,30.916769],[-85.490609,30.829652],[-85.442081,30.671908],[-85.324149,30.698672]]]},"properties":{"id":"32431","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.251797,30.756375],[-85.246323,30.762885],[-85.253064,30.765131],[-85.258938,30.760286],[-85.251797,30.756375]]]},"properties":{"id":"32447","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.97319,30.3553],[-83.96894,30.37087],[-83.99058,30.36877],[-83.99014,30.35851],[-83.97319,30.3553]]]},"properties":{"id":"32361","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.566244,29.08098],[-81.594046,28.979592],[-81.547015,28.930982],[-81.472247,28.98148],[-81.392084,29.019979],[-81.501477,29.099725],[-81.566244,29.08098]]]},"properties":{"id":"32767","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.020007,29.281274],[-81.883185,29.310453],[-81.641363,29.20496],[-81.612441,29.203538],[-81.643167,29.333047],[-81.657547,29.392],[-81.687924,29.467091],[-81.801705,29.526937],[-81.86582,29.515214],[-81.940971,29.512542],[-81.996935,29.501701],[-82.023496,29.321725],[-82.020007,29.281274]]]},"properties":{"id":"32134","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.294749,28.973202],[-81.342438,29.105312],[-81.392084,29.019979],[-81.472247,28.98148],[-81.349723,28.939641],[-81.344066,28.946324],[-81.294749,28.973202]]]},"properties":{"id":"32720","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.923951,26.652811],[-81.907058,26.726023],[-81.944461,26.740805],[-81.974143,26.652288],[-81.974156,26.652058],[-81.923951,26.652811]]]},"properties":{"id":"33909","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.565095,26.600333],[-81.662359,26.592764],[-81.661805,26.559194],[-81.563827,26.518469],[-81.565095,26.600333]]]},"properties":{"id":"33974","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.163418,26.522943],[-82.189355,26.586683],[-82.196421,26.697246],[-82.184887,26.478207],[-82.163418,26.522943]]]},"properties":{"id":"33924","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.163418,26.522943],[-82.031513,26.475957],[-82.028437,26.480245],[-82.068798,26.563442],[-82.104882,26.614403],[-82.189355,26.586683],[-82.163418,26.522943]]]},"properties":{"id":"33956","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286458,30.421771],[-84.264666,30.395396],[-84.22656,30.454191],[-84.277079,30.447665],[-84.28913,30.449067],[-84.289798,30.433391],[-84.286458,30.421771]],[[-84.284345,30.436596],[-84.280664,30.439593],[-84.280666,30.434428],[-84.284345,30.436596]]]},"properties":{"id":"32301","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.554106,27.645295],[-82.650055,27.531773],[-82.598284,27.508377],[-82.563123,27.504329],[-82.528418,27.507916],[-82.478711,27.553774],[-82.453735,27.64591],[-82.554106,27.645295]]]},"properties":{"id":"34221","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.563059,27.462443],[-82.563152,27.425703],[-82.486153,27.424671],[-82.455286,27.412734],[-82.459199,27.440989],[-82.458918,27.46162],[-82.562851,27.462444],[-82.563059,27.462443]]]},"properties":{"id":"34203","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.595862,27.462369],[-82.563059,27.462443],[-82.562851,27.462444],[-82.563123,27.504329],[-82.598284,27.508377],[-82.604098,27.46736],[-82.595862,27.462369]]]},"properties":{"id":"34205","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.478711,27.553774],[-82.528418,27.507916],[-82.483625,27.523127],[-82.478711,27.553774]]]},"properties":{"id":"34222","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.136218,29.123627],[-82.06202,29.186316],[-82.136958,29.18708],[-82.168707,29.186342],[-82.184956,29.121298],[-82.136218,29.123627]]]},"properties":{"id":"34471","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.06202,29.186316],[-82.053683,29.179116],[-82.032361,29.16521],[-82.080199,29.222939],[-82.135839,29.21534],[-82.136958,29.18708],[-82.06202,29.186316]]]},"properties":{"id":"34470","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.180681,29.330504],[-82.210049,29.423437],[-82.207821,29.427463],[-82.213567,29.432825],[-82.220402,29.430329],[-82.323877,29.427781],[-82.384993,29.337756],[-82.188903,29.330502],[-82.180681,29.330504]]]},"properties":{"id":"32686","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.316307,27.067033],[-80.43239,27.206025],[-80.578838,27.206043],[-80.604258,26.987507],[-80.564174,26.957979],[-80.38469,26.958382],[-80.316307,27.067033]]]},"properties":{"id":"34956","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.340964,25.867404],[-80.341605,25.927926],[-80.339101,25.957078],[-80.375067,25.957072],[-80.44049,25.956861],[-80.440928,25.956858],[-80.439612,25.941374],[-80.344031,25.869573],[-80.340964,25.867404]]]},"properties":{"id":"33018","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.43246,25.749742],[-80.479969,25.725379],[-80.430988,25.712515],[-80.431068,25.713879],[-80.43246,25.749742]]]},"properties":{"id":"33185","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.333609,25.629095],[-80.287978,25.630279],[-80.282489,25.643355],[-80.332068,25.643917],[-80.333609,25.629095]]]},"properties":{"id":"33158","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.423173,25.565869],[-80.550222,25.559271],[-80.560203,25.549965],[-80.445423,25.546333],[-80.364031,25.551698],[-80.363446,25.568437],[-80.380747,25.567179],[-80.423173,25.565869]]]},"properties":{"id":"33170","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.218243,25.872558],[-80.216801,25.831968],[-80.216553,25.831211],[-80.19653,25.832017],[-80.196348,25.832615],[-80.197884,25.872312],[-80.197939,25.87325],[-80.218243,25.872558]]]},"properties":{"id":"33150","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.215108,25.793216],[-80.214792,25.778337],[-80.199545,25.778783],[-80.199594,25.779749],[-80.197922,25.779799],[-80.197881,25.778859],[-80.193831,25.779891],[-80.194455,25.795623],[-80.215108,25.793216]]]},"properties":{"id":"33136","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.28812,25.77078],[-80.258217,25.772529],[-80.264206,25.793564],[-80.332443,25.795464],[-80.336426,25.769605],[-80.28812,25.77078]]]},"properties":{"id":"33126","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.19653,25.832017],[-80.194455,25.795623],[-80.185578,25.795149],[-80.163865,25.797191],[-80.160061,25.826914],[-80.160497,25.833332],[-80.196348,25.832615],[-80.19653,25.832017]]]},"properties":{"id":"33137","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.228439,25.926885],[-80.260652,25.92574],[-80.276728,25.925127],[-80.275027,25.881637],[-80.260388,25.882722],[-80.221742,25.913647],[-80.228439,25.926885]]]},"properties":{"id":"33054","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.229593,25.972333],[-80.228439,25.926885],[-80.221742,25.913647],[-80.219956,25.912593],[-80.19987,25.909864],[-80.199677,25.913234],[-80.189515,25.941449],[-80.202416,25.972874],[-80.229593,25.972333]]]},"properties":{"id":"33169","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.385585,25.768157],[-80.336426,25.769605],[-80.332443,25.795464],[-80.337263,25.805304],[-80.385953,25.797075],[-80.385585,25.768157]]]},"properties":{"id":"33172","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.156701,25.870587],[-80.160497,25.833332],[-80.160061,25.826914],[-80.111314,25.848563],[-80.115224,25.872644],[-80.156701,25.870587]]]},"properties":{"id":"33141","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.799191,30.416394],[-86.799225,30.406265],[-86.799826,30.397263],[-86.714696,30.407277],[-86.742235,30.415096],[-86.799191,30.416394]]]},"properties":{"id":"32569","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.5708,30.475137],[-86.478624,30.482996],[-86.50794,30.534638],[-86.579145,30.480448],[-86.5708,30.475137]]]},"properties":{"id":"32580","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.429355,28.421214],[-81.475844,28.409609],[-81.506544,28.375355],[-81.531627,28.347384],[-81.50955,28.347312],[-81.429355,28.421214]]]},"properties":{"id":"32821","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.511572,28.621815],[-81.504815,28.546243],[-81.4677,28.549322],[-81.467807,28.610952],[-81.465088,28.631216],[-81.511572,28.621815]]]},"properties":{"id":"32818","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.638155,28.549028],[-81.624798,28.553198],[-81.635184,28.559319],[-81.638155,28.549028]]]},"properties":{"id":"34760","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.41866,28.508675],[-81.433753,28.547697],[-81.461422,28.55022],[-81.464937,28.493692],[-81.449327,28.478809],[-81.41866,28.508675]]]},"properties":{"id":"32811","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.355406,28.531363],[-81.376919,28.558974],[-81.389112,28.552096],[-81.38485,28.531057],[-81.355406,28.531363]]]},"properties":{"id":"32801","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.464937,28.493692],[-81.461422,28.55022],[-81.4677,28.549322],[-81.504815,28.546243],[-81.508862,28.545381],[-81.508806,28.527778],[-81.508547,28.493605],[-81.464937,28.493692]]]},"properties":{"id":"32835","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.341699,28.08263],[-81.234204,28.062255],[-81.168305,28.202935],[-81.256045,28.241362],[-81.329945,28.251592],[-81.39033,28.232971],[-81.351937,28.138903],[-81.341699,28.08263]]]},"properties":{"id":"34772","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.45965,28.051694],[-81.341699,28.08263],[-81.351937,28.138903],[-81.440126,28.154305],[-81.501483,28.14275],[-81.540699,28.133419],[-81.45965,28.051694]]]},"properties":{"id":"34759","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.486132,28.258862],[-81.525243,28.306737],[-81.54356,28.276185],[-81.536656,28.263606],[-81.486132,28.258862]]]},"properties":{"id":"33848","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203788,26.413489],[-80.203724,26.394203],[-80.137853,26.394659],[-80.121606,26.402154],[-80.121906,26.42426],[-80.14651,26.424121],[-80.203788,26.413489]]]},"properties":{"id":"33496","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.712223,26.682352],[-80.568857,26.630077],[-80.498748,26.540489],[-80.385675,26.671427],[-80.585297,26.645726],[-80.729864,26.68338],[-80.712223,26.682352]]]},"properties":{"id":"33430","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.089717,26.572161],[-80.089851,26.483642],[-80.085293,26.48346],[-80.069739,26.566801],[-80.089717,26.572161]]]},"properties":{"id":"33426","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.112956,26.633163],[-80.145982,26.634219],[-80.146057,26.632497],[-80.14778,26.558006],[-80.122853,26.557196],[-80.113357,26.571993],[-80.114054,26.604051],[-80.112956,26.633163]]]},"properties":{"id":"33463","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.205785,26.55722],[-80.234516,26.540887],[-80.220065,26.483226],[-80.17217,26.527951],[-80.122831,26.533427],[-80.122853,26.557196],[-80.14778,26.558006],[-80.205785,26.55722]]]},"properties":{"id":"33472","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.112956,26.633163],[-80.114054,26.604051],[-80.069742,26.59489],[-80.07231,26.636316],[-80.112805,26.636858],[-80.112956,26.633163]]]},"properties":{"id":"33461","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.06635,26.944086],[-80.09047,26.94739],[-80.095367,26.882856],[-80.073168,26.891282],[-80.052488,26.893271],[-80.06635,26.944086]]]},"properties":{"id":"33477","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.188173,28.2591],[-82.213256,28.233576],[-82.179937,28.215267],[-82.188173,28.2591]]]},"properties":{"id":"33542","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.574698,28.329288],[-82.666107,28.330312],[-82.675765,28.28065],[-82.673917,28.240084],[-82.59012,28.27403],[-82.574698,28.329288]]]},"properties":{"id":"34654","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.666987,28.433588],[-82.663148,28.427885],[-82.657179,28.433625],[-82.666987,28.433588]]]},"properties":{"id":"34679","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.624298,27.753694],[-82.646743,27.785291],[-82.654894,27.784783],[-82.656327,27.771112],[-82.652745,27.696341],[-82.624298,27.753694]]]},"properties":{"id":"33705","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.709243,28.060096],[-82.70722,28.04897],[-82.661692,27.978998],[-82.64882,27.979497],[-82.648524,28.017041],[-82.648557,28.03863],[-82.64858,28.060025],[-82.709243,28.060096]]]},"properties":{"id":"34677","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.778924,27.835602],[-82.817027,27.828906],[-82.834478,27.836316],[-82.842713,27.837762],[-82.78776,27.779987],[-82.769023,27.795703],[-82.763818,27.807927],[-82.764723,27.818803],[-82.778924,27.835602]],[[-82.782427,27.810942],[-82.76989,27.813801],[-82.772814,27.80044],[-82.782427,27.810942]]]},"properties":{"id":"33708","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.778935,27.865345],[-82.78388,27.865268],[-82.811706,27.863653],[-82.817027,27.828906],[-82.778924,27.835602],[-82.778935,27.865345]]]},"properties":{"id":"33772","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.722432,27.893729],[-82.787097,27.89464],[-82.78388,27.865268],[-82.778935,27.865345],[-82.728932,27.871772],[-82.717822,27.879143],[-82.717156,27.886343],[-82.722432,27.893729]]]},"properties":{"id":"33773","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.770691,27.927579],[-82.829225,27.922699],[-82.833225,27.910299],[-82.836705,27.898859],[-82.806003,27.900621],[-82.784514,27.902029],[-82.770691,27.927579]]]},"properties":{"id":"33770","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.742832,27.676562],[-82.723311,27.71506],[-82.73267,27.737234],[-82.752623,27.776204],[-82.769023,27.795703],[-82.78776,27.779987],[-82.742832,27.676562]]]},"properties":{"id":"33706","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.851443,28.00194],[-81.854229,27.981633],[-81.857726,27.956568],[-81.763683,27.967638],[-81.749746,27.9967],[-81.731993,27.978398],[-81.760543,27.956661],[-81.694667,27.94518],[-81.706747,28.022501],[-81.783505,28.040426],[-81.842062,28.025803],[-81.851443,28.00194]]]},"properties":{"id":"33880","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.61157,28.036901],[-81.586724,28.011488],[-81.424547,28.012476],[-81.45965,28.051694],[-81.540699,28.133419],[-81.650027,28.23255],[-81.722791,28.190671],[-81.743964,28.182168],[-81.692178,28.105118],[-81.63522,28.055384],[-81.61157,28.036901]]]},"properties":{"id":"33844","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.957425,28.055706],[-81.957657,28.033239],[-81.851443,28.00194],[-81.842062,28.025803],[-81.87512,28.073455],[-81.957425,28.055706]]]},"properties":{"id":"33801","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.957425,28.055706],[-82.01475,28.052405],[-82.055946,28.036937],[-82.055947,28.036734],[-82.055651,28.02576],[-82.01464,28.033989],[-81.957657,28.033239],[-81.957425,28.055706]]]},"properties":{"id":"33815","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.085905,28.171619],[-82.019005,28.215502],[-82.055531,28.258928],[-82.106205,28.259226],[-82.105853,28.17165],[-82.085905,28.171619]]]},"properties":{"id":"33849","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.424406,27.855938],[-81.42802,27.859552],[-81.431922,27.857159],[-81.427904,27.85532],[-81.424406,27.855938]]]},"properties":{"id":"33856","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52366,29.622432],[-81.594911,29.625666],[-81.599221,29.606653],[-81.588381,29.564893],[-81.556828,29.533992],[-81.515228,29.486723],[-81.52366,29.622432]]]},"properties":{"id":"32187","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.594518,29.343947],[-81.657547,29.392],[-81.643167,29.333047],[-81.594518,29.343947]]]},"properties":{"id":"32139","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.461675,30.087734],[-81.368172,30.050193],[-81.430186,30.252243],[-81.436585,30.237046],[-81.478979,30.104784],[-81.461675,30.087734]]]},"properties":{"id":"32081","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.312456,29.948158],[-81.452358,29.970197],[-81.395138,29.861354],[-81.37926,29.825407],[-81.307024,29.86714],[-81.279842,29.913431],[-81.299586,29.953209],[-81.312456,29.948158]]]},"properties":{"id":"32084","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.34959,27.469098],[-80.35026,27.42949],[-80.301263,27.416495],[-80.321,27.467165],[-80.34959,27.469098]]]},"properties":{"id":"34950","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.088029,30.611372],[-86.881703,30.704717],[-86.864758,30.808243],[-86.785692,30.99698],[-86.906258,30.997587],[-87.150089,30.722042],[-87.088029,30.611372]]]},"properties":{"id":"32570","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.291732,27.265341],[-82.448212,27.298799],[-82.449156,27.269284],[-82.438891,27.190177],[-82.361461,27.153693],[-82.363709,27.150109],[-82.291732,27.265341]]]},"properties":{"id":"34241","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.439725,28.67615],[-81.443527,28.640393],[-81.398574,28.640152],[-81.388568,28.647511],[-81.388809,28.68366],[-81.388871,28.690577],[-81.439725,28.67615]]]},"properties":{"id":"32714","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.416102,28.755702],[-81.374957,28.723805],[-81.309935,28.730489],[-81.301331,28.786466],[-81.413762,28.792604],[-81.414446,28.784955],[-81.416102,28.755702]]]},"properties":{"id":"32746","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18632,28.960008],[-82.22538,28.960163],[-82.311697,28.960391],[-82.282066,28.916376],[-82.237395,28.865508],[-82.174159,28.799808],[-82.186898,28.779501],[-82.187252,28.759835],[-82.10142,28.75429],[-82.087673,28.795508],[-82.08822,28.79989],[-82.170892,28.945272],[-82.18632,28.960008]]]},"properties":{"id":"33538","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.01982,28.90185],[-82.170892,28.945272],[-82.08822,28.79989],[-82.048269,28.785823],[-82.01407,28.75585],[-81.953661,28.75533],[-81.953585,28.791247],[-81.95358,28.836434],[-81.953435,28.86356],[-82.01982,28.90185]]]},"properties":{"id":"34785","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.05477,28.63744],[-81.954072,28.580684],[-81.953994,28.748078],[-81.953661,28.75533],[-82.01407,28.75585],[-82.054682,28.696374],[-82.05477,28.63744]]]},"properties":{"id":"33514","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.9626,30.30965],[-83.008999,30.311482],[-83.008708,30.275082],[-82.962511,30.275936],[-82.9626,30.30965]]]},"properties":{"id":"32064","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.075573,29.155531],[-81.102994,29.220003],[-81.221838,29.14732],[-81.134691,29.082622],[-81.075573,29.155531]]]},"properties":{"id":"32124","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.342438,29.105312],[-81.294749,28.973202],[-81.257596,28.955178],[-81.256199,28.959579],[-81.183657,28.970819],[-81.161584,28.934663],[-81.134691,29.082622],[-81.221838,29.14732],[-81.243061,29.148227],[-81.342438,29.105312]]]},"properties":{"id":"32724","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.037527,30.722085],[-86.364917,30.738186],[-86.391837,30.70686],[-86.107295,30.560067],[-86.016816,30.544228],[-86.037527,30.722085]]]},"properties":{"id":"32435","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.587351,30.634169],[-85.591665,30.633216],[-85.591451,30.629209],[-85.586886,30.632767],[-85.587351,30.634169]]]},"properties":{"id":"32463","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.414534,29.555462],[-82.334642,29.617039],[-82.339323,29.641876],[-82.350485,29.639184],[-82.369293,29.637642],[-82.372498,29.637653],[-82.472607,29.630794],[-82.414534,29.555462]]]},"properties":{"id":"32608","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.388786,29.924385],[-85.22268,29.687697],[-85.168427,29.736574],[-85.32147,29.954819],[-85.390151,29.973987],[-85.388786,29.924385]]]},"properties":{"id":"32456","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18113,30.0626],[-82.18843,30.0485],[-82.17148,30.04843],[-82.17846,30.05867],[-82.18113,30.0626]]]},"properties":{"id":"32026","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.669786,28.507755],[-80.757576,28.491179],[-80.743177,28.434873],[-80.734688,28.402859],[-80.717794,28.355695],[-80.674359,28.40914],[-80.669786,28.507755]]]},"properties":{"id":"32953","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.14645,26.122183],[-80.201278,26.123594],[-80.20204,26.088114],[-80.200802,26.046925],[-80.168103,26.047946],[-80.166365,26.07024],[-80.145425,26.118558],[-80.14645,26.122183]]]},"properties":{"id":"33312","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.142792,26.129781],[-80.109237,26.121391],[-80.102892,26.138051],[-80.144655,26.148219],[-80.142792,26.129781]]]},"properties":{"id":"33304","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.130388,25.997053],[-80.134911,26.033832],[-80.167612,26.047957],[-80.167265,25.99622],[-80.130388,25.997053]]]},"properties":{"id":"33020","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.34388,26.131319],[-80.297721,26.131227],[-80.297725,26.135471],[-80.297984,26.164263],[-80.298451,26.186464],[-80.34388,26.131319]]]},"properties":{"id":"33323","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.252002,26.194966],[-80.298032,26.193665],[-80.299929,26.187851],[-80.298451,26.186464],[-80.297984,26.164263],[-80.244714,26.164321],[-80.251482,26.166612],[-80.252002,26.194966]]]},"properties":{"id":"33351","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.422861,28.972627],[-82.423184,28.968083],[-82.420749,28.965809],[-82.422861,28.972627]]]},"properties":{"id":"34445","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.251817,28.796073],[-82.319812,28.784851],[-82.393609,28.69882],[-82.418295,28.702291],[-82.426628,28.694908],[-82.263052,28.667634],[-82.187252,28.759835],[-82.186898,28.779501],[-82.251817,28.796073]]]},"properties":{"id":"34436","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.797232,29.83665],[-81.581207,29.840176],[-81.690458,30.042834],[-81.745774,30.079455],[-81.89779,29.97856],[-81.797232,29.83665]],[[-81.8094,29.97966],[-81.802517,29.972752],[-81.812243,29.972752],[-81.8094,29.97966]]]},"properties":{"id":"32043","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.808253,30.189046],[-81.735395,30.134658],[-81.692116,30.14474],[-81.679626,30.190495],[-81.70747,30.196109],[-81.808253,30.189046]]]},"properties":{"id":"32073","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.709392,25.964091],[-81.74981,25.970503],[-81.754229,25.968563],[-81.624807,25.870472],[-81.642333,25.920903],[-81.664976,25.914409],[-81.671158,25.931749],[-81.652398,25.932999],[-81.709392,25.964091]]]},"properties":{"id":"34145","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.742552,26.272888],[-81.789867,26.272271],[-81.792283,26.211333],[-81.735938,26.21161],[-81.742552,26.272888]]]},"properties":{"id":"34109","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.506785,26.153116],[-81.506276,26.211948],[-81.687613,26.228883],[-81.687402,26.212355],[-81.686619,26.160375],[-81.566883,26.094271],[-81.506785,26.153116]]]},"properties":{"id":"34117","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.833855,26.287765],[-81.804973,26.316288],[-81.8107,26.397969],[-81.851051,26.395844],[-81.864374,26.36611],[-81.833558,26.287376],[-81.833855,26.287765]]]},"properties":{"id":"34134","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.80723,30.31721],[-82.895008,30.145635],[-82.84522,30.09882],[-82.794721,30.203453],[-82.77687,30.29077],[-82.80723,30.31721]]]},"properties":{"id":"32094","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.148471,29.326317],[-83.114563,29.319581],[-83.12506,29.34683],[-83.148471,29.326317]]]},"properties":{"id":"32692","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.761206,30.372073],[-81.727501,30.367875],[-81.730476,30.391753],[-81.733079,30.419692],[-81.765316,30.518652],[-81.843252,30.456592],[-81.880157,30.423619],[-81.761206,30.372073]]]},"properties":{"id":"32219","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.601224,30.402853],[-81.552559,30.352445],[-81.558793,30.316554],[-81.458584,30.320286],[-81.438526,30.323343],[-81.45931,30.38394],[-81.583334,30.409355],[-81.601224,30.412473],[-81.601224,30.402853]]]},"properties":{"id":"32225","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.666611,30.364501],[-81.661935,30.341946],[-81.618368,30.327292],[-81.613416,30.346239],[-81.626417,30.371939],[-81.666611,30.364501]]]},"properties":{"id":"32206","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.438526,30.323343],[-81.390331,30.324609],[-81.392159,30.38145],[-81.427156,30.399157],[-81.45931,30.38394],[-81.438526,30.323343]]]},"properties":{"id":"32233","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.781787,30.516732],[-84.680397,30.393435],[-84.646408,30.388281],[-84.530567,30.439802],[-84.518435,30.528783],[-84.511179,30.571835],[-84.655155,30.606215],[-84.68955,30.613005],[-84.70226,30.621196],[-84.736509,30.59682],[-84.755977,30.562028],[-84.76609,30.59385],[-84.904253,30.633669],[-84.932519,30.606393],[-84.781787,30.516732]]]},"properties":{"id":"32351","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.741321,27.646277],[-81.932291,27.551837],[-81.852589,27.440065],[-81.620595,27.610905],[-81.63329,27.646467],[-81.741321,27.646277]]]},"properties":{"id":"33873","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.054467,27.595246],[-82.258626,27.555898],[-82.055016,27.512449],[-82.054467,27.595246]]],[[[-82.054467,27.595246],[-81.932291,27.551837],[-81.741321,27.646277],[-81.9508,27.740775],[-82.054349,27.646382],[-82.054467,27.595246]]]]},"properties":{"id":"33834","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.508403,27.551377],[-81.342928,27.573764],[-81.311891,27.646179],[-81.43263,27.748053],[-81.600887,27.671964],[-81.63329,27.646467],[-81.620595,27.610905],[-81.563581,27.53077],[-81.508403,27.551377]]]},"properties":{"id":"33825","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.259538,26.574713],[-81.258772,26.648562],[-81.533266,26.643469],[-81.565095,26.600333],[-81.563827,26.518469],[-81.563763,26.513324],[-81.259538,26.574713]]]},"properties":{"id":"33930","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.43103,28.382575],[-82.055305,28.390609],[-82.05489,28.449858],[-82.206692,28.523059],[-82.373097,28.43403],[-82.402365,28.43402],[-82.43103,28.382575]]]},"properties":{"id":"33523","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.564531,28.520945],[-82.58255,28.433869],[-82.57834,28.433861],[-82.548661,28.433834],[-82.516332,28.533632],[-82.564531,28.520945]]]},"properties":{"id":"34608","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.451208,27.970076],[-82.44826,27.923604],[-82.443539,27.914902],[-82.43711,27.903173],[-82.403938,27.981193],[-82.442941,27.977821],[-82.451208,27.970076]]]},"properties":{"id":"33605","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.374064,28.01776],[-82.412822,28.012071],[-82.442954,27.996067],[-82.442941,27.977821],[-82.403938,27.981193],[-82.326604,27.972858],[-82.318717,27.973413],[-82.342619,28.029572],[-82.374064,28.01776]]]},"properties":{"id":"33610","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.451978,28.096531],[-82.427753,28.186152],[-82.466126,28.19196],[-82.466634,28.187173],[-82.461532,28.116694],[-82.451978,28.096531]]]},"properties":{"id":"33549","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.402171,28.069115],[-82.420496,28.065986],[-82.416201,28.054439],[-82.402171,28.069115]]]},"properties":{"id":"33620","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.155343,30.927889],[-85.035137,30.879819],[-84.970426,30.926687],[-85.142731,31.000716],[-85.155343,30.927889]]]},"properties":{"id":"32423","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.434782,30.56756],[-85.342086,30.566336],[-85.33902,30.566286],[-85.324149,30.698672],[-85.442081,30.671908],[-85.434782,30.56756]]]},"properties":{"id":"32420","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.70333,29.003905],[-81.594046,28.979592],[-81.566244,29.08098],[-81.640647,29.144296],[-81.70333,29.003905]]]},"properties":{"id":"32702","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.839211,28.894029],[-81.95359,28.903258],[-81.953435,28.86356],[-81.95358,28.836434],[-81.847519,28.829011],[-81.839211,28.894029]]]},"properties":{"id":"34731","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.836227,28.991364],[-81.948346,29.000183],[-81.941751,28.96018],[-81.842194,28.961024],[-81.836227,28.991364]]]},"properties":{"id":"32195","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.974192,26.606809],[-81.973244,26.531754],[-81.93941,26.543554],[-81.904078,26.603201],[-81.974192,26.606809]]]},"properties":{"id":"33904","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.907058,26.726023],[-81.924638,26.757891],[-81.944461,26.740805],[-81.907058,26.726023]]],[[[-81.907058,26.726023],[-81.923951,26.652811],[-81.901373,26.627561],[-81.870551,26.653079],[-81.907058,26.726023]]]]},"properties":{"id":"33903","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.799265,26.59993],[-81.794239,26.492818],[-81.783678,26.448826],[-81.774298,26.449379],[-81.785522,26.470349],[-81.757269,26.471275],[-81.772625,26.449382],[-81.562675,26.451517],[-81.563763,26.513324],[-81.563827,26.518469],[-81.661805,26.559194],[-81.713726,26.581749],[-81.758779,26.610737],[-81.767887,26.618524],[-81.803018,26.612748],[-81.799265,26.59993]]]},"properties":{"id":"33913","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.223583,26.59732],[-82.219511,26.600933],[-82.219396,26.606138],[-82.223417,26.603482],[-82.222166,26.599713],[-82.223583,26.59732]]]},"properties":{"id":"33945","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.222441,30.454063],[-84.074892,30.413106],[-84.041188,30.507744],[-84.158615,30.485167],[-84.222441,30.454063]]]},"properties":{"id":"32317","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.14135,30.288785],[-84.421052,30.302407],[-84.4133,30.15689],[-84.413961,30.054676],[-84.318292,30.037984],[-84.211755,30.149141],[-84.221855,30.166565],[-84.191071,30.17118],[-84.14135,30.288785]]]},"properties":{"id":"32327","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.420839,30.641004],[-83.31785,30.52458],[-83.240123,30.520919],[-83.309249,30.634406],[-83.420839,30.641004]]]},"properties":{"id":"32350","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.455286,27.412734],[-82.448609,27.388605],[-82.268238,27.386304],[-82.303834,27.39865],[-82.459199,27.440989],[-82.455286,27.412734]]]},"properties":{"id":"34202","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.146271,27.646285],[-82.453735,27.64591],[-82.478711,27.553774],[-82.483625,27.523127],[-82.354539,27.493423],[-82.258626,27.555898],[-82.054467,27.595246],[-82.054349,27.646382],[-82.146271,27.646285]]]},"properties":{"id":"34219","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.486153,27.424671],[-82.459515,27.38833],[-82.449001,27.388608],[-82.448609,27.388605],[-82.455286,27.412734],[-82.486153,27.424671]]]},"properties":{"id":"34201","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.053903,29.24151],[-82.080199,29.222939],[-82.032361,29.16521],[-81.992619,29.183515],[-81.820865,29.169173],[-81.883185,29.310453],[-82.020007,29.281274],[-82.053903,29.24151]]]},"properties":{"id":"34488","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.216878,29.45504],[-82.220402,29.430329],[-82.213567,29.432825],[-82.216878,29.45504]]]},"properties":{"id":"32664","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.226929,27.173105],[-80.232871,27.212291],[-80.285008,27.245528],[-80.297436,27.23243],[-80.251323,27.16011],[-80.226929,27.173105]]]},"properties":{"id":"34994","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.09047,26.94739],[-80.147931,26.98822],[-80.183992,26.979708],[-80.178371,26.97198],[-80.151113,26.928193],[-80.103575,26.882928],[-80.095367,26.882856],[-80.09047,26.94739]]]},"properties":{"id":"33458","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.483352,25.797272],[-80.498395,25.76123],[-80.432863,25.760899],[-80.385585,25.768157],[-80.385953,25.797075],[-80.483352,25.797272]]]},"properties":{"id":"33182","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.264206,25.793564],[-80.215108,25.793216],[-80.216553,25.831211],[-80.216801,25.831968],[-80.259175,25.830676],[-80.261872,25.808468],[-80.264608,25.808382],[-80.264206,25.793564]]]},"properties":{"id":"33142","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.221742,25.913647],[-80.260388,25.882722],[-80.259052,25.870702],[-80.218383,25.876152],[-80.219956,25.912593],[-80.221742,25.913647]]]},"properties":{"id":"33167","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.156584,25.944475],[-80.157685,25.974091],[-80.174521,25.973561],[-80.202416,25.972874],[-80.189515,25.941449],[-80.156584,25.944475]]]},"properties":{"id":"33179","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.228439,25.926885],[-80.229593,25.972333],[-80.246763,25.971874],[-80.262568,25.971296],[-80.260652,25.92574],[-80.228439,25.926885]]]},"properties":{"id":"33056","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.163865,25.797191],[-80.119285,25.803595],[-80.111314,25.848563],[-80.160061,25.826914],[-80.163865,25.797191]]]},"properties":{"id":"33140","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.214272,25.760877],[-80.212784,25.749077],[-80.211491,25.734491],[-80.182475,25.73721],[-80.179143,25.751338],[-80.192879,25.758009],[-80.214326,25.762935],[-80.214272,25.760877]]]},"properties":{"id":"33129","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.193623,25.774134],[-80.192879,25.758009],[-80.179143,25.751338],[-80.173469,25.769776],[-80.193623,25.774134]]]},"properties":{"id":"33131","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.364031,25.551698],[-80.331329,25.55201],[-80.331444,25.559427],[-80.363446,25.568437],[-80.364031,25.551698]]]},"properties":{"id":"33190","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.962558,30.520942],[-81.79134,30.67815],[-81.711432,30.747578],[-82.017082,30.50213],[-81.962558,30.520942]]]},"properties":{"id":"32046","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.550926,30.543027],[-81.465398,30.711258],[-81.711432,30.747578],[-81.79134,30.67815],[-81.660896,30.561646],[-81.608717,30.574892],[-81.550926,30.543027]]]},"properties":{"id":"32097","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596014,30.477798],[-86.735485,30.433038],[-86.672301,30.428205],[-86.59395,30.430439],[-86.596014,30.477798]]]},"properties":{"id":"32547","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.582453,30.404343],[-86.51507,30.378521],[-86.397498,30.375],[-86.396905,30.39826],[-86.582453,30.404343]]]},"properties":{"id":"32541","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.391837,30.70686],[-86.364917,30.738186],[-86.382464,30.851163],[-86.528264,30.852305],[-86.571764,30.695383],[-86.391837,30.70686]]]},"properties":{"id":"32539","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.478624,30.482996],[-86.5708,30.475137],[-86.537979,30.448081],[-86.474329,30.479271],[-86.478624,30.482996]]]},"properties":{"id":"32542","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.309158,28.451454],[-81.350766,28.45045],[-81.350883,28.449762],[-81.276875,28.364684],[-81.26419,28.447982],[-81.309158,28.451454]]]},"properties":{"id":"32827","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.328013,28.625033],[-81.324644,28.578933],[-81.315197,28.575322],[-81.278304,28.582933],[-81.267002,28.611603],[-81.274964,28.641685],[-81.27855,28.641475],[-81.315874,28.625161],[-81.328013,28.625033]]]},"properties":{"id":"32792","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.413833,28.552805],[-81.389112,28.552096],[-81.376919,28.558974],[-81.373109,28.582265],[-81.386471,28.60592],[-81.416861,28.597932],[-81.413833,28.552805]]]},"properties":{"id":"32804","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.656945,28.259217],[-81.54356,28.276185],[-81.525243,28.306737],[-81.531627,28.347384],[-81.606965,28.376369],[-81.657268,28.347098],[-81.65694,28.327521],[-81.656945,28.259217]]]},"properties":{"id":"34747","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.396502,28.271253],[-81.404063,28.347886],[-81.447884,28.347997],[-81.396502,28.271253]]]},"properties":{"id":"34741","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.486132,28.258862],[-81.440126,28.154305],[-81.351937,28.138903],[-81.39033,28.232971],[-81.396502,28.271253],[-81.447884,28.347997],[-81.50955,28.347312],[-81.531627,28.347384],[-81.525243,28.306737],[-81.486132,28.258862]]]},"properties":{"id":"34746","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.107441,26.779158],[-80.131724,26.795428],[-80.134393,26.750637],[-80.109231,26.750523],[-80.090152,26.750045],[-80.046126,26.724197],[-80.048463,26.76314],[-80.107441,26.779158]]]},"properties":{"id":"33407","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.18663,26.367895],[-80.137298,26.368749],[-80.137853,26.394659],[-80.203724,26.394203],[-80.203304,26.367867],[-80.18663,26.367895]]]},"properties":{"id":"33434","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.128319,26.327675],[-80.116782,26.327845],[-80.098888,26.322809],[-80.097367,26.362462],[-80.135219,26.363913],[-80.128319,26.327675]]]},"properties":{"id":"33486","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17217,26.527951],[-80.172167,26.48375],[-80.147159,26.483917],[-80.130666,26.48385],[-80.122831,26.533427],[-80.17217,26.527951]]]},"properties":{"id":"33437","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.031121,26.814473],[-80.048763,26.803639],[-80.098558,26.80066],[-80.107118,26.786816],[-80.107441,26.779158],[-80.048463,26.76314],[-80.027909,26.772939],[-80.031121,26.814473]]]},"properties":{"id":"33404","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.187841,28.288236],[-82.106205,28.259226],[-82.055531,28.258928],[-82.055819,28.312794],[-82.055305,28.390609],[-82.43103,28.382575],[-82.438567,28.321513],[-82.416398,28.323185],[-82.38344,28.323829],[-82.277382,28.311844],[-82.245591,28.288381],[-82.187841,28.288236]]]},"properties":{"id":"33525","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.246194,28.218796],[-82.338611,28.239517],[-82.3537,28.17128],[-82.259741,28.171462],[-82.246194,28.218796]]]},"properties":{"id":"33543","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.762295,27.960586],[-82.730072,27.955376],[-82.729578,27.990843],[-82.762447,27.992588],[-82.762295,27.960586]]]},"properties":{"id":"33765","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.63522,28.055384],[-81.635519,28.044351],[-81.61157,28.036901],[-81.63522,28.055384]]]},"properties":{"id":"33851","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.650027,28.23255],[-81.540699,28.133419],[-81.501483,28.14275],[-81.539203,28.227667],[-81.650027,28.23255]]]},"properties":{"id":"33837","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.650027,28.23255],[-81.656945,28.259217],[-81.65694,28.327521],[-81.706606,28.346597],[-81.722791,28.190671],[-81.650027,28.23255]]]},"properties":{"id":"33897","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.983009,27.996973],[-81.923849,27.996329],[-81.854229,27.981633],[-81.851443,28.00194],[-81.957657,28.033239],[-82.01464,28.033989],[-81.983009,27.996973]]]},"properties":{"id":"33803","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.760543,27.956661],[-81.731993,27.978398],[-81.749746,27.9967],[-81.763683,27.967638],[-81.760543,27.956661]]]},"properties":{"id":"33839","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.801705,29.526937],[-81.687924,29.467091],[-81.67824,29.479051],[-81.599221,29.606653],[-81.594911,29.625666],[-81.541733,29.771042],[-81.581207,29.840176],[-81.797232,29.83665],[-81.829766,29.822904],[-81.846431,29.711511],[-81.82435,29.65814],[-81.778255,29.642616],[-81.806575,29.566399],[-81.837231,29.542565],[-81.801705,29.526937]]]},"properties":{"id":"32177","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.82435,29.65814],[-81.806575,29.566399],[-81.778255,29.642616],[-81.82435,29.65814]]]},"properties":{"id":"32147","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.376317,30.252891],[-81.430095,30.252243],[-81.430186,30.252243],[-81.368172,30.050193],[-81.312456,29.948158],[-81.299586,29.953209],[-81.376317,30.252891]]]},"properties":{"id":"32082","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.373887,27.557332],[-80.38261,27.46973],[-80.34959,27.469098],[-80.321,27.467165],[-80.348422,27.557248],[-80.373887,27.557332]]]},"properties":{"id":"34946","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.193638,27.121082],[-82.208882,27.070977],[-82.203442,27.032843],[-82.177185,27.032789],[-82.155533,27.075983],[-82.155517,27.098982],[-82.193638,27.121082]]]},"properties":{"id":"34286","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.177185,27.032789],[-82.057469,27.032119],[-82.057516,27.076488],[-82.107348,27.080154],[-82.155533,27.075983],[-82.177185,27.032789]]]},"properties":{"id":"34288","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.325749,28.697908],[-81.309187,28.721707],[-81.309935,28.730489],[-81.374957,28.723805],[-81.388871,28.690577],[-81.388809,28.68366],[-81.349123,28.677509],[-81.325749,28.697908]]]},"properties":{"id":"32750","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.349123,28.677509],[-81.388809,28.68366],[-81.388568,28.647511],[-81.357504,28.648518],[-81.343698,28.661909],[-81.349123,28.677509]]]},"properties":{"id":"32701","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.325749,28.697908],[-81.27855,28.641475],[-81.274964,28.641685],[-81.248516,28.71168],[-81.309187,28.721707],[-81.325749,28.697908]]]},"properties":{"id":"32708","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.248516,28.71168],[-81.274964,28.641685],[-81.267002,28.611603],[-81.207598,28.611982],[-81.157808,28.61229],[-81.169945,28.655316],[-81.191448,28.73932],[-81.248516,28.71168]]]},"properties":{"id":"32765","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.10142,28.75429],[-82.054682,28.696374],[-82.01407,28.75585],[-82.048269,28.785823],[-82.087673,28.795508],[-82.10142,28.75429]]]},"properties":{"id":"33585","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.100944,29.408626],[-81.039777,29.263857],[-81.023024,29.2702],[-81.097777,29.427094],[-81.100944,29.408626]]]},"properties":{"id":"32176","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.908923,29.006344],[-80.95096,28.950742],[-80.890479,28.972563],[-80.908923,29.006344]]]},"properties":{"id":"32132","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.275932,28.901385],[-81.344066,28.946324],[-81.349723,28.939641],[-81.36694,28.879227],[-81.319796,28.834532],[-81.275932,28.901385]]]},"properties":{"id":"32713","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.442081,30.671908],[-85.490609,30.829652],[-85.599934,30.830552],[-85.679628,30.657838],[-85.717302,30.550565],[-85.706207,30.438879],[-85.655784,30.438627],[-85.486309,30.520496],[-85.434782,30.56756],[-85.442081,30.671908]],[[-85.587351,30.634169],[-85.586886,30.632767],[-85.591451,30.629209],[-85.591665,30.633216],[-85.587351,30.634169]]]},"properties":{"id":"32428","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.350401,29.644891],[-82.350485,29.639184],[-82.339323,29.641876],[-82.339314,29.644902],[-82.350401,29.644891]]],[[[-82.350401,29.644891],[-82.36051,29.650351],[-82.372498,29.637653],[-82.369293,29.637642],[-82.350401,29.644891]]],[[[-82.339323,29.652056],[-82.339171,29.659186],[-82.356531,29.659444],[-82.355717,29.650392],[-82.339323,29.652056]]]]},"properties":{"id":"32603","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.655784,30.438627],[-85.587402,30.302154],[-85.387985,30.421724],[-85.386589,30.428668],[-85.386756,30.435327],[-85.486309,30.520496],[-85.655784,30.438627]]]},"properties":{"id":"32466","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.809123,30.17005],[-85.738159,30.175144],[-85.740638,30.187612],[-85.726065,30.215554],[-85.854728,30.227994],[-85.842422,30.188709],[-85.809123,30.17005]]]},"properties":{"id":"32407","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.967864,30.385877],[-85.917556,30.357378],[-85.8535,30.440623],[-85.822882,30.481659],[-85.860373,30.497312],[-85.992147,30.406745],[-85.967864,30.385877]]]},"properties":{"id":"32437","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.714824,30.227195],[-85.607131,30.233266],[-85.61856,30.264133],[-85.668853,30.259967],[-85.714824,30.227195]]]},"properties":{"id":"32444","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.447192,29.95196],[-85.448677,30.02877],[-85.621109,30.130239],[-85.684692,30.151285],[-85.634625,30.06492],[-85.447192,29.95196]]]},"properties":{"id":"32403","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18113,30.0626],[-82.220511,30.038179],[-82.248132,29.945335],[-82.218773,29.899815],[-82.103785,29.783568],[-82.094319,29.780089],[-82.084813,29.783496],[-82.06312,29.79115],[-82.029616,29.822233],[-82.047737,29.993716],[-82.17148,30.04843],[-82.18843,30.0485],[-82.18113,30.0626]]]},"properties":{"id":"32091","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.103785,29.783568],[-82.218773,29.899815],[-82.241061,29.852185],[-82.205851,29.84549],[-82.104355,29.783284],[-82.103785,29.783568]]]},"properties":{"id":"32044","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.720535,27.910513],[-80.59167,27.873752],[-80.598525,27.910005],[-80.604038,27.931513],[-80.613611,27.998343],[-80.671838,27.999032],[-80.720535,27.910513]]]},"properties":{"id":"32909","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.59516,28.379716],[-80.646239,28.409125],[-80.648975,28.372783],[-80.59516,28.379716]]]},"properties":{"id":"32920","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.743177,28.434873],[-80.878467,28.45301],[-80.896835,28.452827],[-80.87243,28.369591],[-80.862908,28.347487],[-80.862815,28.347221],[-80.773962,28.328366],[-80.754956,28.349273],[-80.734688,28.402859],[-80.743177,28.434873]]]},"properties":{"id":"32926","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.932721,28.543488],[-80.896835,28.452827],[-80.878467,28.45301],[-80.757576,28.491179],[-80.669786,28.507755],[-80.794376,28.608275],[-80.851383,28.584193],[-80.932721,28.543488]]]},"properties":{"id":"32780","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.478642,27.854615],[-80.498118,27.895911],[-80.598525,27.910005],[-80.59167,27.873752],[-80.554462,27.822188],[-80.478642,27.854615]]]},"properties":{"id":"32976","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.575324,28.138691],[-80.592431,28.212689],[-80.633605,28.209132],[-80.613139,28.134166],[-80.575324,28.138691]]]},"properties":{"id":"32937","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.281095,26.044605],[-80.24761,26.045591],[-80.245402,26.086922],[-80.251113,26.090656],[-80.297948,26.087751],[-80.297947,26.087665],[-80.297324,26.044128],[-80.281095,26.044605]]]},"properties":{"id":"33328","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.297324,26.044128],[-80.297947,26.087665],[-80.338336,26.087675],[-80.345101,26.029706],[-80.3126,26.029543],[-80.297324,26.044128]]]},"properties":{"id":"33330","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203951,26.200009],[-80.236071,26.197241],[-80.252002,26.194966],[-80.251482,26.166612],[-80.203172,26.164865],[-80.203951,26.200009]]]},"properties":{"id":"33319","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.297863,26.258381],[-80.298015,26.229439],[-80.240569,26.230021],[-80.233745,26.230131],[-80.234152,26.256985],[-80.297863,26.258381]]]},"properties":{"id":"33071","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.234152,26.256985],[-80.233745,26.230131],[-80.195463,26.210031],[-80.177921,26.23076],[-80.186447,26.272094],[-80.201831,26.274249],[-80.223067,26.272181],[-80.234152,26.256985]]]},"properties":{"id":"33063","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.375067,25.957072],[-80.375977,26.007731],[-80.377248,26.029375],[-80.395313,26.029345],[-80.44049,25.956861],[-80.375067,25.957072]]]},"properties":{"id":"33029","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.269135,30.320659],[-85.183123,30.330893],[-85.162283,30.407515],[-85.256654,30.477067],[-85.385789,30.480488],[-85.386756,30.435327],[-85.386589,30.428668],[-85.269135,30.320659]]]},"properties":{"id":"32430","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.215713,30.222352],[-85.183123,30.330893],[-85.269135,30.320659],[-85.215713,30.222352]]]},"properties":{"id":"32449","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.271998,26.91595],[-82.298451,26.868792],[-82.236717,26.877942],[-82.271998,26.91595]]]},"properties":{"id":"33947","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.282636,26.813518],[-82.264931,26.714948],[-82.250101,26.71712],[-82.249816,26.786886],[-82.282636,26.813518]]]},"properties":{"id":"33921","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991486,26.884875],[-82.051946,26.843454],[-82.061068,26.770423],[-81.931333,26.770051],[-81.890542,26.806033],[-81.991486,26.884875]]]},"properties":{"id":"33955","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.534092,28.891967],[-82.70957,28.90743],[-82.765871,28.782123],[-82.529898,28.86272],[-82.519226,28.892101],[-82.534092,28.891967]]]},"properties":{"id":"34429","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.565931,29.034809],[-82.543049,28.94954],[-82.477976,28.955777],[-82.464738,29.045817],[-82.565931,29.034809]]]},"properties":{"id":"34433","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.319812,28.784851],[-82.348865,28.835974],[-82.390291,28.854276],[-82.393609,28.69882],[-82.319812,28.784851]]]},"properties":{"id":"34452","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.39532,25.803253],[-81.34417,25.803328],[-81.371092,25.832885],[-81.39532,25.803253]]]},"properties":{"id":"34138","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.251924,29.749859],[-83.101391,29.591059],[-83.15733,29.5315],[-83.215564,29.390141],[-83.102724,29.328752],[-82.978879,29.532892],[-82.959077,29.67116],[-82.91984,29.795714],[-83.000811,29.825272],[-83.251924,29.749859]]]},"properties":{"id":"32680","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.306923,29.545334],[-83.409273,29.550177],[-83.215564,29.390141],[-83.15733,29.5315],[-83.2491,29.55526],[-83.306923,29.545334]]]},"properties":{"id":"32648","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.458584,30.320286],[-81.558793,30.316554],[-81.552534,30.250113],[-81.516235,30.253058],[-81.458584,30.320286]]]},"properties":{"id":"32246","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.769096,30.315098],[-81.761206,30.372073],[-81.880157,30.423619],[-81.946196,30.364883],[-81.869516,30.307426],[-81.770547,30.315082],[-81.769096,30.315098]]]},"properties":{"id":"32220","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.279363,30.504317],[-87.265984,30.504649],[-87.254619,30.503775],[-87.261153,30.569256],[-87.334715,30.534538],[-87.279363,30.504317]]]},"properties":{"id":"32534","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.238319,30.503504],[-87.162167,30.506994],[-87.21829,30.565975],[-87.261153,30.569256],[-87.254619,30.503775],[-87.238319,30.503504]]]},"properties":{"id":"32514","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.226141,30.449046],[-87.192825,30.420536],[-87.192393,30.419042],[-87.167989,30.454056],[-87.238319,30.503504],[-87.254619,30.503775],[-87.265984,30.504649],[-87.226141,30.449046]]]},"properties":{"id":"32503","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.279363,30.504317],[-87.29047,30.448445],[-87.265811,30.414078],[-87.247666,30.401825],[-87.241563,30.415698],[-87.226141,30.449046],[-87.265984,30.504649],[-87.279363,30.504317]]]},"properties":{"id":"32505","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.280625,30.406863],[-87.300475,30.406116],[-87.299346,30.402551],[-87.280625,30.406863]]]},"properties":{"id":"32511","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.155405,29.404464],[-81.268404,29.263182],[-81.243061,29.148227],[-81.221838,29.14732],[-81.102994,29.220003],[-81.039777,29.263857],[-81.100944,29.408626],[-81.155405,29.404464]]]},"properties":{"id":"32174","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.168427,29.736574],[-85.22268,29.687697],[-85.002109,29.683163],[-85.168427,29.736574]]]},"properties":{"id":"32320","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.318292,30.037984],[-84.413961,30.054676],[-84.370164,29.897559],[-84.318292,30.037984]]]},"properties":{"id":"32346","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.68875,30.617342],[-84.655155,30.606215],[-84.511179,30.571835],[-84.467175,30.69257],[-84.725161,30.705505],[-84.70226,30.621196],[-84.68875,30.617342]]]},"properties":{"id":"32352","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.959077,29.67116],[-82.978879,29.532892],[-82.705605,29.490178],[-82.67672,29.52872],[-82.661544,29.726131],[-82.773555,29.768111],[-82.959077,29.67116]]]},"properties":{"id":"32693","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.326133,26.919378],[-81.563767,26.944699],[-81.565931,26.769552],[-81.566258,26.68725],[-81.566219,26.683943],[-81.533266,26.643469],[-81.258772,26.648562],[-81.27169,26.768817],[-81.32165,26.91791],[-81.326133,26.919378]]]},"properties":{"id":"33935","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.326133,26.919378],[-81.267289,27.067329],[-81.267675,27.120976],[-81.562429,27.122829],[-81.562202,27.033836],[-81.563767,26.944699],[-81.326133,26.919378]]]},"properties":{"id":"33960","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.940658,30.577792],[-83.242731,30.501407],[-83.170969,30.385225],[-82.871678,30.365831],[-82.726708,30.531061],[-82.940658,30.577792]]]},"properties":{"id":"32052","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.375978,28.492561],[-82.373097,28.43403],[-82.206692,28.523059],[-82.217253,28.580305],[-82.251749,28.594035],[-82.375978,28.492561]]]},"properties":{"id":"34602","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.672737,28.6368],[-82.46088,28.59228],[-82.451416,28.694813],[-82.552576,28.694635],[-82.674634,28.694265],[-82.672737,28.6368]]]},"properties":{"id":"34614","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.217253,28.580305],[-82.274621,28.658911],[-82.251749,28.594035],[-82.217253,28.580305]]]},"properties":{"id":"34661","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.563696,27.34068],[-81.563706,27.325876],[-81.401599,27.37334],[-81.41927,27.441871],[-81.482064,27.470845],[-81.490692,27.470873],[-81.495051,27.470867],[-81.563562,27.485478],[-81.563696,27.34068]]]},"properties":{"id":"33875","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.533405,27.935601],[-82.53277,27.908491],[-82.487799,27.908662],[-82.481717,27.923994],[-82.483044,27.934789],[-82.533405,27.935601]]]},"properties":{"id":"33629","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.377343,28.069267],[-82.374064,28.01776],[-82.342619,28.029572],[-82.343875,28.036644],[-82.353443,28.086998],[-82.380635,28.074592],[-82.377343,28.069267]]]},"properties":{"id":"33637","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.542679,27.886666],[-82.538879,27.834966],[-82.506356,27.869037],[-82.542679,27.886666]]]},"properties":{"id":"33616","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.548258,28.208722],[-82.651165,28.173266],[-82.649149,28.098901],[-82.578857,28.085096],[-82.550656,28.106786],[-82.544227,28.189589],[-82.548258,28.208722]]]},"properties":{"id":"33556","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.420144,27.793358],[-82.478919,27.734032],[-82.383333,27.734601],[-82.360314,27.781557],[-82.356945,27.791819],[-82.420144,27.793358]]]},"properties":{"id":"33572","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.32838,27.950187],[-82.266788,27.941714],[-82.269392,27.959654],[-82.318717,27.973413],[-82.326604,27.972858],[-82.32838,27.950187]]]},"properties":{"id":"33510","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.037527,30.722085],[-86.016816,30.544228],[-86.058878,30.401211],[-85.992147,30.406745],[-85.860373,30.497312],[-85.906049,30.643964],[-85.826734,30.745322],[-86.051136,30.883127],[-86.037527,30.722085]]]},"properties":{"id":"32455","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.377559,27.660143],[-80.369689,27.626813],[-80.348167,27.557249],[-80.317128,27.557345],[-80.441822,27.86082],[-80.478642,27.854615],[-80.42919,27.753141],[-80.377559,27.660143]]]},"properties":{"id":"32963","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.075819,30.369819],[-83.992573,30.080327],[-83.736043,30.423505],[-83.61172,30.651258],[-84.007454,30.672127],[-84.041188,30.507744],[-84.074892,30.413106],[-84.075819,30.369819]],[[-83.97319,30.3553],[-83.99014,30.35851],[-83.99058,30.36877],[-83.96894,30.37087],[-83.97319,30.3553]]]},"properties":{"id":"32344","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.719461,28.89518],[-81.728976,28.8729],[-81.707736,28.835368],[-81.690326,28.821934],[-81.646074,28.824149],[-81.658583,28.890901],[-81.719461,28.89518]]]},"properties":{"id":"32726","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.719461,28.89518],[-81.741221,28.913756],[-81.743209,28.873021],[-81.728976,28.8729],[-81.719461,28.89518]]]},"properties":{"id":"32735","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.853319,28.675275],[-81.825716,28.71764],[-81.841344,28.748373],[-81.871985,28.762233],[-81.816252,28.808829],[-81.847519,28.829011],[-81.95358,28.836434],[-81.953585,28.791247],[-81.896497,28.715426],[-81.853319,28.675275]]]},"properties":{"id":"34748","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.896497,28.715426],[-81.953994,28.748078],[-81.954072,28.580684],[-81.954814,28.52384],[-81.957641,28.34518],[-81.873981,28.345415],[-81.817029,28.474819],[-81.782229,28.594739],[-81.804494,28.650388],[-81.853319,28.675275],[-81.896497,28.715426]],[[-81.907191,28.598435],[-81.872238,28.584204],[-81.872373,28.566165],[-81.910058,28.569209],[-81.907191,28.598435]]]},"properties":{"id":"34736","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.783678,26.448826],[-81.821499,26.452534],[-81.894834,26.432758],[-81.851051,26.395844],[-81.8107,26.397969],[-81.658061,26.332435],[-81.56252,26.422619],[-81.562675,26.451517],[-81.772625,26.449382],[-81.774298,26.449379],[-81.783678,26.448826]]]},"properties":{"id":"33928","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.658061,26.332435],[-81.8107,26.397969],[-81.804973,26.316288],[-81.746167,26.316896],[-81.690494,26.317334],[-81.658061,26.332435]]]},"properties":{"id":"34135","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.635804,29.163146],[-82.637392,29.017763],[-82.565931,29.034809],[-82.464738,29.045817],[-82.442801,29.215224],[-82.62216,29.23258],[-82.648457,29.230577],[-82.635804,29.163146]]]},"properties":{"id":"34431","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.776858,29.329432],[-82.804361,29.309298],[-82.800756,29.279577],[-82.76351,29.25805],[-82.776858,29.329432]]]},"properties":{"id":"32683","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.471806,27.490942],[-82.302058,27.475443],[-82.354539,27.493423],[-82.483625,27.523127],[-82.471806,27.490942]]]},"properties":{"id":"34212","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.714935,27.531425],[-82.733851,27.540187],[-82.735599,27.518384],[-82.714935,27.531425]]]},"properties":{"id":"34216","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.053683,29.179116],[-82.06202,29.186316],[-82.136218,29.123627],[-82.136195,29.066158],[-82.125078,29.02191],[-82.111583,29.025669],[-82.053552,29.087645],[-82.053683,29.179116]]]},"properties":{"id":"34480","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.111583,29.025669],[-81.98268,29.029361],[-81.958263,29.059362],[-82.053552,29.087645],[-82.111583,29.025669]]]},"properties":{"id":"34420","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.01982,28.90185],[-81.953435,28.86356],[-81.95359,28.903258],[-81.974906,28.960006],[-82.040189,28.96013],[-82.01982,28.90185]]]},"properties":{"id":"32162","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.183992,26.979708],[-80.181345,27.166715],[-80.226929,27.173105],[-80.251323,27.16011],[-80.316307,27.067033],[-80.38469,26.958382],[-80.374523,26.958386],[-80.178371,26.97198],[-80.183992,26.979708]]]},"properties":{"id":"34997","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.232871,27.212291],[-80.226929,27.173105],[-80.181345,27.166715],[-80.148193,27.164543],[-80.188087,27.247441],[-80.232871,27.212291]]]},"properties":{"id":"34996","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.250039,27.381439],[-80.286609,27.381274],[-80.263111,27.298725],[-80.285008,27.245528],[-80.232871,27.212291],[-80.188087,27.247441],[-80.250039,27.381439]]]},"properties":{"id":"34957","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.167457,25.914156],[-80.164158,25.874765],[-80.154803,25.874772],[-80.131021,25.899132],[-80.156814,25.915072],[-80.167457,25.914156]]]},"properties":{"id":"33181","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.194455,25.795623],[-80.193831,25.779891],[-80.193655,25.775108],[-80.193623,25.774134],[-80.173469,25.769776],[-80.154968,25.764277],[-80.13908,25.766187],[-80.185578,25.795149],[-80.194455,25.795623]]]},"properties":{"id":"33132","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.115224,25.872644],[-80.119386,25.899546],[-80.131021,25.899132],[-80.154803,25.874772],[-80.156701,25.870587],[-80.115224,25.872644]]]},"properties":{"id":"33154","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.404243,25.507102],[-80.404196,25.499734],[-80.396023,25.495469],[-80.396086,25.507143],[-80.404243,25.507102]]]},"properties":{"id":"33039","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.635463,24.580117],[-81.653363,24.625422],[-81.906938,24.620075],[-81.749813,24.542098],[-81.635463,24.580117]]]},"properties":{"id":"33040","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.635463,24.580117],[-81.410821,24.613756],[-81.404355,24.728705],[-81.635463,24.580117]]]},"properties":{"id":"33042","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.51148,25.062589],[-80.57279,24.992634],[-80.537918,24.97398],[-80.467891,25.036735],[-80.51148,25.062589]]]},"properties":{"id":"33070","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.798415,24.851198],[-80.749544,24.832244],[-80.537918,24.97398],[-80.57279,24.992634],[-80.798415,24.851198]]]},"properties":{"id":"33036","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.26419,28.447982],[-81.276875,28.364684],[-81.257864,28.348069],[-81.234292,28.348131],[-81.099644,28.34812],[-81.10001,28.451444],[-81.108242,28.451458],[-81.166531,28.451725],[-81.230454,28.451416],[-81.260621,28.448543],[-81.26419,28.447982]]]},"properties":{"id":"32832","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.32887,28.644067],[-81.357504,28.648518],[-81.388568,28.647511],[-81.398574,28.640152],[-81.386579,28.610294],[-81.328013,28.625033],[-81.315874,28.625161],[-81.32887,28.644067]]]},"properties":{"id":"32751","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.263866,28.26439],[-81.256045,28.241362],[-81.168305,28.202935],[-81.086885,28.348085],[-81.099644,28.34812],[-81.234292,28.348131],[-81.25469,28.323987],[-81.263866,28.26439]]]},"properties":{"id":"34771","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.319697,28.348123],[-81.382969,28.347886],[-81.350189,28.305156],[-81.319697,28.348123]]]},"properties":{"id":"34743","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.121606,26.402154],[-80.062092,26.390958],[-80.058064,26.426452],[-80.076315,26.422787],[-80.090719,26.424352],[-80.121906,26.42426],[-80.121606,26.402154]]]},"properties":{"id":"33487","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.037645,26.544876],[-80.069739,26.566801],[-80.085293,26.48346],[-80.065554,26.491484],[-80.046587,26.507386],[-80.037645,26.544876]]]},"properties":{"id":"33435","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.085293,26.48346],[-80.090719,26.424352],[-80.076315,26.422787],[-80.065554,26.491484],[-80.085293,26.48346]]]},"properties":{"id":"33444","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.068996,26.646598],[-80.07231,26.636316],[-80.069742,26.59489],[-80.045873,26.594181],[-80.043467,26.644394],[-80.068996,26.646598]]]},"properties":{"id":"33460","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.111564,26.692866],[-80.107347,26.693199],[-80.074597,26.695624],[-80.090152,26.750045],[-80.109231,26.750523],[-80.111564,26.692866]]]},"properties":{"id":"33409","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.338611,28.239517],[-82.246194,28.218796],[-82.245591,28.288381],[-82.277382,28.311844],[-82.325116,28.293592],[-82.338611,28.239517]]]},"properties":{"id":"33545","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.699707,27.733683],[-82.69984,27.770924],[-82.752623,27.776204],[-82.73267,27.737234],[-82.699707,27.733683]]]},"properties":{"id":"33707","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.679424,27.770988],[-82.69984,27.770924],[-82.699707,27.733683],[-82.704222,27.724006],[-82.687336,27.707091],[-82.677321,27.695315],[-82.679424,27.770988]]]},"properties":{"id":"33711","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.657669,27.82848],[-82.58814,27.823603],[-82.608404,27.873194],[-82.671803,27.849005],[-82.677938,27.844358],[-82.678757,27.82835],[-82.657669,27.82848]]]},"properties":{"id":"33702","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.847235,27.865395],[-82.836705,27.898859],[-82.833225,27.910299],[-82.852143,27.910014],[-82.842713,27.837762],[-82.834478,27.836316],[-82.847235,27.865395]]]},"properties":{"id":"33785","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.728931,28.010807],[-82.740272,28.027463],[-82.763096,27.997252],[-82.762447,27.992588],[-82.729578,27.990843],[-82.728931,28.010807]]]},"properties":{"id":"33763","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.844629,27.937146],[-82.824325,27.936798],[-82.805425,27.975954],[-82.801224,27.997796],[-82.844629,27.937146]]]},"properties":{"id":"33767","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.706747,28.022501],[-81.694667,27.94518],[-81.676481,27.923654],[-81.630931,27.97879],[-81.631466,28.00779],[-81.636269,28.018608],[-81.706747,28.022501]]]},"properties":{"id":"33884","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.594816,27.977323],[-81.630931,27.97879],[-81.676481,27.923654],[-81.662234,27.841817],[-81.638361,27.813483],[-81.561732,27.790871],[-81.571219,27.842818],[-81.581352,27.872078],[-81.605942,27.922762],[-81.595352,27.92448],[-81.594816,27.977323]]]},"properties":{"id":"33859","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.923849,27.996329],[-81.983009,27.996973],[-81.976295,27.938516],[-81.974091,27.934702],[-81.871398,27.952965],[-81.923849,27.996329]]]},"properties":{"id":"33813","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.358251,27.792703],[-81.34188,27.791124],[-81.341887,27.79911],[-81.358141,27.799051],[-81.358251,27.792703]]]},"properties":{"id":"33855","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.821637,27.820533],[-81.833097,27.791607],[-81.814883,27.791551],[-81.821637,27.820533]]]},"properties":{"id":"33847","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.630931,27.97879],[-81.594816,27.977323],[-81.60533,27.999396],[-81.631466,28.00779],[-81.630931,27.97879]]]},"properties":{"id":"33877","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.945787,29.681018],[-81.940971,29.512542],[-81.86582,29.515214],[-81.837231,29.542565],[-81.806575,29.566399],[-81.82435,29.65814],[-81.846431,29.711511],[-81.895882,29.713137],[-81.945787,29.681018]]]},"properties":{"id":"32148","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.307024,29.86714],[-81.21929,29.670617],[-81.207949,29.670659],[-81.279842,29.913431],[-81.307024,29.86714]]]},"properties":{"id":"32080","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.324318,27.271874],[-80.342072,27.350254],[-80.346007,27.366502],[-80.38406,27.37451],[-80.370265,27.295875],[-80.324318,27.271874]]]},"properties":{"id":"34983","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.400706,27.205778],[-80.331821,27.205724],[-80.369779,27.293565],[-80.420804,27.289022],[-80.400706,27.205778]]]},"properties":{"id":"34953","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.799191,30.416394],[-86.952078,30.442383],[-86.951715,30.394795],[-86.799225,30.406265],[-86.799191,30.416394]]]},"properties":{"id":"32566","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.437986,27.055796],[-82.30417,26.961532],[-82.263651,26.94533],[-82.255297,27.000336],[-82.255302,27.031086],[-82.293708,27.053585],[-82.391449,27.079119],[-82.437986,27.055796]]]},"properties":{"id":"34293","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.282641,27.078142],[-82.208882,27.070977],[-82.193638,27.121082],[-82.214275,27.121503],[-82.272165,27.122432],[-82.282641,27.078142]]]},"properties":{"id":"34291","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497612,27.269276],[-82.449156,27.269284],[-82.448212,27.298799],[-82.497563,27.299919],[-82.497564,27.298778],[-82.497612,27.269276]]]},"properties":{"id":"34233","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.357504,28.648518],[-81.32887,28.644067],[-81.343698,28.661909],[-81.357504,28.648518]]]},"properties":{"id":"32730","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.106182,28.612643],[-80.98725,28.612997],[-81.02751,28.704577],[-81.169945,28.655316],[-81.157808,28.61229],[-81.106182,28.612643]]]},"properties":{"id":"32766","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.11992,28.96006],[-82.18632,28.960008],[-82.170892,28.945272],[-82.01982,28.90185],[-82.040189,28.96013],[-82.11992,28.96006]]]},"properties":{"id":"34484","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.426264,29.921789],[-82.423356,29.930897],[-82.448644,29.931716],[-82.426264,29.921789]]]},"properties":{"id":"32697","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.075573,29.155531],[-81.134691,29.082622],[-81.021477,29.090794],[-81.02899,29.106041],[-81.058295,29.129059],[-81.073418,29.156143],[-81.075573,29.155531]]]},"properties":{"id":"32128","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.216267,28.84661],[-81.2404,28.954106],[-81.256199,28.959579],[-81.257596,28.955178],[-81.275932,28.901385],[-81.319796,28.834532],[-81.210452,28.802453],[-81.216267,28.84661]]]},"properties":{"id":"32725","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.151818,28.92232],[-81.216267,28.84661],[-81.210452,28.802453],[-81.180586,28.785957],[-81.178936,28.781886],[-81.055525,28.784839],[-80.967895,28.790197],[-81.004339,28.856732],[-81.151818,28.92232]]]},"properties":{"id":"32764","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.419097,29.321384],[-81.43454,29.399294],[-81.433992,29.398552],[-81.573626,29.348709],[-81.544278,29.305528],[-81.419097,29.321384]]]},"properties":{"id":"32190","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.025484,30.28347],[-86.017241,30.287378],[-86.024524,30.295645],[-86.036685,30.287063],[-86.025484,30.28347]]]},"properties":{"id":"32461","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.180034,29.780008],[-82.11712,29.745859],[-82.103195,29.746231],[-82.098628,29.746254],[-82.104355,29.783284],[-82.205851,29.84549],[-82.180034,29.780008]]]},"properties":{"id":"32694","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.913329,29.791749],[-82.06312,29.79115],[-82.084813,29.783496],[-82.079845,29.767829],[-82.098628,29.746254],[-82.103195,29.746231],[-82.104447,29.738442],[-81.945787,29.681018],[-81.895882,29.713137],[-81.913329,29.791749]]]},"properties":{"id":"32666","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.11712,29.745859],[-82.104447,29.738442],[-82.103195,29.746231],[-82.11712,29.745859]]]},"properties":{"id":"32631","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497045,29.788907],[-82.502255,29.784565],[-82.497118,29.780604],[-82.485533,29.797613],[-82.49162,29.79806],[-82.497045,29.788907]]]},"properties":{"id":"32616","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.400207,29.832136],[-82.376686,29.839945],[-82.376884,29.866991],[-82.401068,29.83269],[-82.400207,29.832136]]]},"properties":{"id":"32658","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.740638,30.187612],[-85.60781,30.174858],[-85.607131,30.233266],[-85.714824,30.227195],[-85.726065,30.215554],[-85.740638,30.187612]]]},"properties":{"id":"32405","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.045857,30.351291],[-86.079918,30.296174],[-85.842422,30.188709],[-85.854728,30.227994],[-85.994712,30.311702],[-86.045857,30.351291]],[[-86.025484,30.28347],[-86.036685,30.287063],[-86.024524,30.295645],[-86.017241,30.287378],[-86.025484,30.28347]]],[[[-85.76641,30.334577],[-85.8535,30.440623],[-85.917556,30.357378],[-85.767066,30.309213],[-85.76641,30.334577]]]]},"properties":{"id":"32413","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.878467,28.45301],[-80.743177,28.434873],[-80.757576,28.491179],[-80.878467,28.45301]]]},"properties":{"id":"32927","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.842922,28.791148],[-80.967895,28.790197],[-81.055525,28.784839],[-81.02751,28.704577],[-80.98725,28.612997],[-80.932721,28.543488],[-80.851383,28.584193],[-80.806483,28.659726],[-80.842922,28.791148]]]},"properties":{"id":"32754","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.589376,28.076494],[-80.555991,28.077898],[-80.575324,28.138691],[-80.613139,28.134166],[-80.613424,28.134078],[-80.603976,28.107093],[-80.589376,28.076494]]]},"properties":{"id":"32903","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.400541,26.135523],[-80.392935,26.09799],[-80.381318,26.094069],[-80.346287,26.087682],[-80.34388,26.131319],[-80.298451,26.186464],[-80.299929,26.187851],[-80.400541,26.135523]]]},"properties":{"id":"33326","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.34388,26.131319],[-80.346287,26.087682],[-80.338336,26.087675],[-80.297947,26.087665],[-80.297948,26.087751],[-80.297721,26.131227],[-80.34388,26.131319]]]},"properties":{"id":"33325","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.203951,26.200009],[-80.203172,26.164865],[-80.146436,26.166587],[-80.138173,26.208996],[-80.138427,26.209066],[-80.195587,26.209063],[-80.203951,26.200009]]]},"properties":{"id":"33309","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.122023,26.171607],[-80.123127,26.208375],[-80.138173,26.208996],[-80.146436,26.166587],[-80.144129,26.1592],[-80.123567,26.161866],[-80.122023,26.171607]]]},"properties":{"id":"33334","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.250264,26.287033],[-80.223067,26.272181],[-80.201831,26.274249],[-80.202937,26.32783],[-80.251225,26.341718],[-80.250264,26.287033]]]},"properties":{"id":"33067","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.297324,26.044128],[-80.3126,26.029543],[-80.312016,26.007856],[-80.279818,26.008328],[-80.281095,26.044605],[-80.297324,26.044128]]]},"properties":{"id":"33026","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.214756,26.046479],[-80.24761,26.045591],[-80.281095,26.044605],[-80.279818,26.008328],[-80.247806,26.001485],[-80.207589,26.01017],[-80.214756,26.046479]]]},"properties":{"id":"33024","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.20204,26.088114],[-80.201278,26.123594],[-80.199406,26.135977],[-80.250674,26.135591],[-80.251113,26.090656],[-80.245402,26.086922],[-80.20204,26.088114]]]},"properties":{"id":"33317","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.14645,26.122183],[-80.145425,26.118558],[-80.143101,26.111237],[-80.109237,26.121391],[-80.142792,26.129781],[-80.14645,26.122183]]]},"properties":{"id":"33301","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.250674,26.135591],[-80.199406,26.135977],[-80.203172,26.164865],[-80.251482,26.166612],[-80.244714,26.164321],[-80.250674,26.135591]]]},"properties":{"id":"33313","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.144655,26.148219],[-80.102892,26.138051],[-80.098362,26.137868],[-80.095427,26.157331],[-80.104653,26.161838],[-80.123567,26.161866],[-80.144129,26.1592],[-80.144655,26.148219]]]},"properties":{"id":"33305","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.186447,26.272094],[-80.177921,26.23076],[-80.168784,26.27423],[-80.186447,26.272094]]]},"properties":{"id":"33066","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.108039,26.092967],[-80.106929,26.058866],[-80.102475,26.093062],[-80.108039,26.092967]]],[[[-80.130388,25.997053],[-80.115292,25.986341],[-80.106855,26.054717],[-80.134911,26.033832],[-80.130388,25.997053]]]]},"properties":{"id":"33019","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.167612,26.047957],[-80.134911,26.033832],[-80.106855,26.054717],[-80.106929,26.058866],[-80.108039,26.092967],[-80.139423,26.081327],[-80.166365,26.07024],[-80.168103,26.047946],[-80.167612,26.047957]]]},"properties":{"id":"33004","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.342086,30.566336],[-85.385084,30.508473],[-85.256654,30.477067],[-85.162283,30.407515],[-85.021833,30.576015],[-85.047506,30.606625],[-85.33902,30.566286],[-85.342086,30.566336]]]},"properties":{"id":"32421","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.271998,26.91595],[-82.236717,26.877942],[-82.236937,26.871992],[-82.18636,26.948147],[-82.255297,27.000336],[-82.263651,26.94533],[-82.271998,26.91595]]]},"properties":{"id":"33981","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.204768,27.03284],[-82.255302,27.031086],[-82.255297,27.000336],[-82.18636,26.948147],[-82.186642,26.969106],[-82.146394,27.012875],[-82.204768,27.03284]]]},"properties":{"id":"33953","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.063458,27.017456],[-82.124765,27.018017],[-82.112644,26.955098],[-82.08227,26.947158],[-82.063458,27.017456]]]},"properties":{"id":"33952","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.924638,26.757891],[-81.907058,26.726023],[-81.870551,26.653079],[-81.83913,26.679277],[-81.760807,26.717275],[-81.76074,26.7696],[-81.890542,26.806033],[-81.931333,26.770051],[-81.924638,26.757891]]]},"properties":{"id":"33917","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.419515,28.877921],[-82.282066,28.916376],[-82.311697,28.960391],[-82.349512,28.988765],[-82.422861,28.972627],[-82.420749,28.965809],[-82.451597,28.907112],[-82.419515,28.877921]]]},"properties":{"id":"34442","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.348865,28.835974],[-82.319812,28.784851],[-82.251817,28.796073],[-82.174159,28.799808],[-82.237395,28.865508],[-82.348865,28.835974]]]},"properties":{"id":"34450","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.816697,26.173243],[-81.791511,26.174259],[-81.792283,26.211333],[-81.82165,26.219385],[-81.816697,26.173243]]]},"properties":{"id":"34103","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.782821,26.138182],[-81.686718,26.147291],[-81.686608,26.160103],[-81.731186,26.168588],[-81.783962,26.159978],[-81.782821,26.138182]]]},"properties":{"id":"34104","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.735938,26.21161],[-81.731186,26.168588],[-81.686608,26.160103],[-81.686619,26.160375],[-81.687402,26.212355],[-81.735938,26.21161]]]},"properties":{"id":"34116","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.37754,25.980004],[-81.364491,25.910812],[-81.357453,25.950182],[-81.37754,25.980004]]]},"properties":{"id":"34137","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.793975,29.986202],[-82.62463,29.9527],[-82.560889,29.931279],[-82.68852,30.17996],[-82.794721,30.203453],[-82.84522,30.09882],[-82.87876,30.09941],[-82.793975,29.986202]]]},"properties":{"id":"32024","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726708,30.531061],[-82.871678,30.365831],[-82.80723,30.31721],[-82.77687,30.29077],[-82.59839,30.41817],[-82.726708,30.531061]]]},"properties":{"id":"32096","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.133348,27.208211],[-82.214275,27.121503],[-82.193638,27.121082],[-82.155517,27.098982],[-82.107348,27.080154],[-82.057516,27.076488],[-81.890984,27.151147],[-81.895308,27.144924],[-81.958667,27.03505],[-81.562202,27.033836],[-81.562429,27.122829],[-81.563706,27.325876],[-81.563696,27.34068],[-81.829029,27.340122],[-82.055753,27.338264],[-82.133348,27.208211]]]},"properties":{"id":"34266","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.06626,29.98754],[-83.000811,29.825272],[-82.91984,29.795714],[-82.779735,29.857735],[-82.744521,29.869146],[-82.783137,29.954637],[-83.036416,30.047859],[-83.06626,29.98754]]]},"properties":{"id":"32008","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.558793,30.316554],[-81.599401,30.313968],[-81.620394,30.264938],[-81.60312,30.243035],[-81.552534,30.250113],[-81.558793,30.316554]]]},"properties":{"id":"32216","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.602223,30.168481],[-81.601212,30.130541],[-81.479345,30.104774],[-81.558496,30.183222],[-81.602223,30.168481]]]},"properties":{"id":"32258","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.650715,30.22354],[-81.589138,30.220709],[-81.60312,30.243035],[-81.620394,30.264938],[-81.665316,30.25764],[-81.653016,30.24394],[-81.650715,30.22354]]]},"properties":{"id":"32217","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.679573,30.190838],[-81.602223,30.168481],[-81.558496,30.183222],[-81.589138,30.220709],[-81.650715,30.22354],[-81.679573,30.190838]]]},"properties":{"id":"32257","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.826533,30.188739],[-81.796047,30.246186],[-81.835743,30.247154],[-81.868048,30.188018],[-81.845945,30.188538],[-81.826533,30.188739]]]},"properties":{"id":"32222","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.66752,30.314325],[-81.620727,30.321083],[-81.618368,30.327292],[-81.661935,30.341946],[-81.667048,30.33578],[-81.66752,30.314325]]]},"properties":{"id":"32202","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.26533,30.373668],[-87.23879,30.398448],[-87.247666,30.401825],[-87.265811,30.414078],[-87.280625,30.406863],[-87.299346,30.402551],[-87.450962,30.346262],[-87.306712,30.316553],[-87.307236,30.322552],[-87.317788,30.335501],[-87.312008,30.371829],[-87.26533,30.373668]]]},"properties":{"id":"32507","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.26533,30.373668],[-87.312008,30.371829],[-87.317788,30.335501],[-87.26533,30.373668]]]},"properties":{"id":"32508","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.467128,30.997833],[-87.463749,30.740898],[-87.305796,30.777408],[-87.290095,30.909395],[-87.467128,30.997833]]]},"properties":{"id":"32568","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.334715,30.534538],[-87.261153,30.569256],[-87.21829,30.565975],[-87.256743,30.634356],[-87.266704,30.670294],[-87.405799,30.66672],[-87.412404,30.573552],[-87.334715,30.534538]]]},"properties":{"id":"32533","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.16003,29.41454],[-81.26416,29.51092],[-81.27795,29.51059],[-81.454622,29.623394],[-81.457262,29.623347],[-81.43454,29.399294],[-81.419097,29.321384],[-81.355509,29.261896],[-81.268404,29.263182],[-81.155405,29.404464],[-81.16003,29.41454]]]},"properties":{"id":"32110","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.68955,30.613005],[-84.655155,30.606215],[-84.68875,30.617342],[-84.68955,30.613005]]]},"properties":{"id":"32332","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.620595,27.610905],[-81.852589,27.440065],[-81.829029,27.340122],[-81.563696,27.34068],[-81.563562,27.485478],[-81.563581,27.53077],[-81.620595,27.610905]]]},"properties":{"id":"33890","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.05489,28.449858],[-81.954814,28.52384],[-81.954072,28.580684],[-82.05477,28.63744],[-82.217253,28.580305],[-82.206692,28.523059],[-82.05489,28.449858]]]},"properties":{"id":"33597","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.187011,28.027206],[-82.137542,27.987844],[-82.092814,28.036986],[-82.187011,28.027206]]]},"properties":{"id":"33563","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.307661,27.884378],[-82.269293,27.857227],[-82.269292,27.857304],[-82.261245,27.919278],[-82.266788,27.941714],[-82.32838,27.950187],[-82.333362,27.923066],[-82.307661,27.884378]]]},"properties":{"id":"33511","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.266788,27.941714],[-82.261245,27.919278],[-82.219645,27.908311],[-82.252848,27.974349],[-82.269392,27.959654],[-82.266788,27.941714]]]},"properties":{"id":"33594","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.43092,27.66784],[-80.377559,27.660143],[-80.42919,27.753141],[-80.44132,27.7515],[-80.46189,27.74856],[-80.55091,27.76757],[-80.547397,27.733015],[-80.43092,27.66784]]]},"properties":{"id":"32967","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.43092,27.66784],[-80.547397,27.733015],[-80.868881,27.822522],[-80.880772,27.777777],[-80.679821,27.55847],[-80.521197,27.558589],[-80.43041,27.61653],[-80.43092,27.66784]]]},"properties":{"id":"32966","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.677187,28.558789],[-81.657074,28.563228],[-81.657212,28.595168],[-81.69245,28.568972],[-81.677187,28.558789]]]},"properties":{"id":"34756","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.803018,26.612748],[-81.811386,26.612834],[-81.855531,26.597223],[-81.855263,26.546279],[-81.799265,26.59993],[-81.803018,26.612748]]]},"properties":{"id":"33966","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.184887,26.478207],[-82.023405,26.474072],[-82.031513,26.475957],[-82.163418,26.522943],[-82.184887,26.478207]]]},"properties":{"id":"33957","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.661805,26.559194],[-81.662359,26.592764],[-81.66982,26.613063],[-81.714421,26.613549],[-81.713726,26.581749],[-81.661805,26.559194]]]},"properties":{"id":"33976","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.844789,26.49242],[-81.794239,26.492818],[-81.799265,26.59993],[-81.855263,26.546279],[-81.876274,26.526012],[-81.844789,26.49242]]]},"properties":{"id":"33912","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.289798,30.433391],[-84.413639,30.470568],[-84.530567,30.439802],[-84.646408,30.388281],[-84.713351,30.30017],[-84.661201,30.300219],[-84.406407,30.373875],[-84.286458,30.421771],[-84.289798,30.433391]]]},"properties":{"id":"32310","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.626988,29.292329],[-82.62216,29.23258],[-82.442801,29.215224],[-82.36758,29.215708],[-82.384993,29.337756],[-82.5919,29.33317],[-82.62374,29.31035],[-82.626988,29.292329]]]},"properties":{"id":"32668","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.102724,29.328752],[-82.9249,29.28555],[-82.9017,29.26645],[-82.804361,29.309298],[-82.776858,29.329432],[-82.714621,29.476251],[-82.705605,29.490178],[-82.978879,29.532892],[-83.102724,29.328752]]]},"properties":{"id":"32626","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.353735,30.312078],[-83.31785,30.52458],[-83.420839,30.641004],[-83.448944,30.642413],[-83.55814,30.32421],[-83.353735,30.312078]]]},"properties":{"id":"32340","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.459199,27.440989],[-82.303834,27.39865],[-82.302058,27.475443],[-82.471806,27.490942],[-82.458918,27.46162],[-82.459199,27.440989]]]},"properties":{"id":"34211","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.562851,27.462444],[-82.458918,27.46162],[-82.471806,27.490942],[-82.483625,27.523127],[-82.528418,27.507916],[-82.563123,27.504329],[-82.562851,27.462444]]]},"properties":{"id":"34208","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.563059,27.462443],[-82.595862,27.462369],[-82.59622,27.40522],[-82.563152,27.425703],[-82.563059,27.462443]]]},"properties":{"id":"34207","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.604098,27.46736],[-82.598284,27.508377],[-82.650055,27.531773],[-82.698629,27.524338],[-82.695421,27.480816],[-82.67175,27.480709],[-82.604098,27.46736]]]},"properties":{"id":"34209","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.053552,29.087645],[-81.958263,29.059362],[-81.992619,29.183515],[-82.032361,29.16521],[-82.053683,29.179116],[-82.053552,29.087645]]]},"properties":{"id":"34472","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.188903,29.330502],[-82.18487,29.186387],[-82.168707,29.186342],[-82.136958,29.18708],[-82.135839,29.21534],[-82.134751,29.295854],[-82.132731,29.309185],[-82.135232,29.323164],[-82.180681,29.330504],[-82.188903,29.330502]]]},"properties":{"id":"34475","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.123989,29.330947],[-82.023496,29.321725],[-81.996935,29.501701],[-82.196624,29.422437],[-82.210049,29.423437],[-82.180681,29.330504],[-82.135232,29.323164],[-82.123989,29.330947]]]},"properties":{"id":"32113","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.207821,29.427463],[-82.210049,29.423437],[-82.196624,29.422437],[-82.207821,29.427463]]]},"properties":{"id":"32681","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.147931,26.98822],[-80.076028,26.970381],[-80.148193,27.164543],[-80.181345,27.166715],[-80.183992,26.979708],[-80.147931,26.98822]]]},"properties":{"id":"33455","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.31373,25.847094],[-80.261872,25.808468],[-80.259175,25.830676],[-80.259369,25.838072],[-80.278557,25.851297],[-80.31373,25.847094]]]},"properties":{"id":"33010","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.283551,25.881424],[-80.323392,25.879871],[-80.322561,25.853659],[-80.31373,25.847094],[-80.278557,25.851297],[-80.283551,25.881424]]]},"properties":{"id":"33012","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.426555,25.625884],[-80.429937,25.68497],[-80.498817,25.682282],[-80.545128,25.666618],[-80.548616,25.644819],[-80.430709,25.625679],[-80.426555,25.625884]]]},"properties":{"id":"33196","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.278557,25.851297],[-80.259369,25.838072],[-80.259052,25.870702],[-80.260388,25.882722],[-80.275027,25.881637],[-80.283551,25.881424],[-80.278557,25.851297]]]},"properties":{"id":"33013","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.33354,25.688077],[-80.38193,25.688152],[-80.384579,25.686479],[-80.380237,25.62741],[-80.333609,25.629095],[-80.332068,25.643917],[-80.33354,25.688077]]]},"properties":{"id":"33176","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.254014,25.73507],[-80.28652,25.73404],[-80.285887,25.718163],[-80.260834,25.705648],[-80.254014,25.73507]]]},"properties":{"id":"33146","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.196348,25.832615],[-80.160497,25.833332],[-80.156701,25.870587],[-80.154803,25.874772],[-80.164158,25.874765],[-80.197884,25.872312],[-80.196348,25.832615]]]},"properties":{"id":"33138","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.254978,25.760324],[-80.255457,25.771834],[-80.258217,25.772529],[-80.28812,25.77078],[-80.287609,25.757171],[-80.28652,25.73404],[-80.254014,25.73507],[-80.254408,25.744846],[-80.254978,25.760324]]]},"properties":{"id":"33134","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.294976,25.956929],[-80.293226,25.925433],[-80.276728,25.925127],[-80.260652,25.92574],[-80.262568,25.971296],[-80.294976,25.956929]]]},"properties":{"id":"33055","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.154968,25.764277],[-80.131367,25.747677],[-80.124888,25.760045],[-80.13908,25.766187],[-80.154968,25.764277]]]},"properties":{"id":"33109","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.445423,25.546333],[-80.442318,25.506665],[-80.404243,25.507102],[-80.396086,25.507143],[-80.371875,25.507506],[-80.331329,25.55201],[-80.364031,25.551698],[-80.445423,25.546333]]]},"properties":{"id":"33032","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.878041,24.805639],[-81.105811,24.723438],[-81.086163,24.675981],[-80.867848,24.789742],[-80.878041,24.805639]],[[-81.020574,24.732306],[-81.010385,24.721913],[-81.024822,24.716543],[-81.020574,24.732306]]]},"properties":{"id":"33050","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.59395,30.430439],[-86.672301,30.428205],[-86.714696,30.407277],[-86.799826,30.397263],[-86.51507,30.378521],[-86.582453,30.404343],[-86.588263,30.42921],[-86.59395,30.430439]]]},"properties":{"id":"32548","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.677753,27.302218],[-80.578838,27.206043],[-80.579668,27.250525],[-80.677753,27.302218]]],[[[-80.677753,27.302218],[-80.679821,27.55847],[-80.880772,27.777777],[-81.201633,27.804798],[-81.170924,27.743649],[-81.142164,27.643238],[-81.205385,27.542973],[-80.999677,27.270259],[-80.677753,27.302218]]]]},"properties":{"id":"34972","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.584605,28.712408],[-81.593153,28.749114],[-81.604315,28.729346],[-81.584605,28.712408]]]},"properties":{"id":"32798","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.41866,28.508675],[-81.449327,28.478809],[-81.444342,28.472066],[-81.374288,28.487469],[-81.384758,28.507126],[-81.41866,28.508675]]]},"properties":{"id":"32839","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.4677,28.549322],[-81.461422,28.55022],[-81.433753,28.547697],[-81.413833,28.552805],[-81.416861,28.597932],[-81.467807,28.610952],[-81.4677,28.549322]]]},"properties":{"id":"32808","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.523431,28.438852],[-81.475844,28.409609],[-81.429355,28.421214],[-81.408799,28.433304],[-81.444342,28.472066],[-81.449327,28.478809],[-81.464937,28.493692],[-81.508547,28.493605],[-81.523431,28.438852]]]},"properties":{"id":"32819","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.979126,28.452577],[-81.09523,28.548774],[-81.154201,28.565418],[-81.13334,28.499057],[-81.108242,28.451458],[-81.10001,28.451444],[-80.979126,28.452577]]]},"properties":{"id":"32833","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.154201,28.565418],[-81.09523,28.548774],[-81.106182,28.612643],[-81.157808,28.61229],[-81.154201,28.565418]]]},"properties":{"id":"32820","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.329945,28.251592],[-81.256045,28.241362],[-81.263866,28.26439],[-81.308224,28.267615],[-81.329945,28.251592]]]},"properties":{"id":"34769","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.440126,28.154305],[-81.486132,28.258862],[-81.536656,28.263606],[-81.539203,28.227667],[-81.501483,28.14275],[-81.440126,28.154305]]]},"properties":{"id":"34758","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.293476,26.818951],[-80.288742,26.607658],[-80.288208,26.608381],[-80.247023,26.681669],[-80.262783,26.765254],[-80.293476,26.818951]]]},"properties":{"id":"33470","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.288742,26.607658],[-80.234516,26.540887],[-80.205785,26.55722],[-80.204003,26.631704],[-80.288208,26.608381],[-80.288742,26.607658]]]},"properties":{"id":"33449","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.748368,26.667712],[-80.712223,26.682352],[-80.729864,26.68338],[-80.748302,26.681214],[-80.748368,26.667712]]]},"properties":{"id":"33493","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.135219,26.363913],[-80.097367,26.362462],[-80.064279,26.360675],[-80.062092,26.390958],[-80.121606,26.402154],[-80.137853,26.394659],[-80.137298,26.368749],[-80.135219,26.363913]]]},"properties":{"id":"33431","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.089717,26.572161],[-80.069739,26.566801],[-80.037645,26.544876],[-80.033071,26.58403],[-80.045873,26.594181],[-80.069742,26.59489],[-80.114054,26.604051],[-80.113357,26.571993],[-80.089717,26.572161]]]},"properties":{"id":"33462","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.204003,26.631704],[-80.205785,26.55722],[-80.14778,26.558006],[-80.146057,26.632497],[-80.173812,26.632092],[-80.204003,26.631704]]]},"properties":{"id":"33467","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.073168,26.891282],[-80.095367,26.882856],[-80.103575,26.882928],[-80.107118,26.786816],[-80.098558,26.80066],[-80.081464,26.817539],[-80.073168,26.891282]]]},"properties":{"id":"33410","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.544227,28.189589],[-82.466126,28.19196],[-82.449642,28.256664],[-82.503112,28.321231],[-82.569068,28.329906],[-82.574698,28.329288],[-82.59012,28.27403],[-82.548258,28.208722],[-82.544227,28.189589]]]},"properties":{"id":"34638","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.715296,28.209848],[-82.736875,28.209748],[-82.740548,28.172786],[-82.724079,28.173221],[-82.719879,28.173173],[-82.698941,28.21699],[-82.715296,28.209848]]]},"properties":{"id":"34690","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.724784,28.342188],[-82.666109,28.331013],[-82.600553,28.390071],[-82.57834,28.433861],[-82.58255,28.433869],[-82.63986,28.433844],[-82.657179,28.433625],[-82.663148,28.427885],[-82.666987,28.433588],[-82.678742,28.433456],[-82.724784,28.342188]]]},"properties":{"id":"34667","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.646743,27.785291],[-82.607976,27.785242],[-82.597171,27.79733],[-82.655695,27.806586],[-82.654894,27.784783],[-82.646743,27.785291]]]},"properties":{"id":"33704","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.747085,27.848296],[-82.677938,27.844358],[-82.671803,27.849005],[-82.679734,27.864822],[-82.704347,27.868264],[-82.710829,27.877373],[-82.717822,27.879143],[-82.728932,27.871772],[-82.747085,27.848296]]]},"properties":{"id":"33782","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.656327,27.771112],[-82.679424,27.770988],[-82.677321,27.695315],[-82.652745,27.696341],[-82.656327,27.771112]]]},"properties":{"id":"33712","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.699915,27.828308],[-82.699196,27.806417],[-82.698398,27.806415],[-82.655695,27.806586],[-82.657669,27.82848],[-82.678757,27.82835],[-82.699915,27.828308]]]},"properties":{"id":"33714","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.815967,27.865625],[-82.806003,27.900621],[-82.836705,27.898859],[-82.847235,27.865395],[-82.815967,27.865625]]]},"properties":{"id":"33774","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.742832,27.676562],[-82.687336,27.707091],[-82.704222,27.724006],[-82.723311,27.71506],[-82.742832,27.676562]]]},"properties":{"id":"33715","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.974091,27.934702],[-81.976295,27.938516],[-82.055304,27.938178],[-82.054874,27.907726],[-82.054349,27.646382],[-81.9508,27.740775],[-81.891439,27.790036],[-81.974091,27.934702]]]},"properties":{"id":"33860","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.452118,27.762535],[-81.571219,27.842818],[-81.561732,27.790871],[-81.452118,27.762535]]]},"properties":{"id":"33827","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.019005,28.215502],[-81.976679,28.080581],[-81.854369,28.152145],[-81.957805,28.308888],[-82.055819,28.312794],[-82.055531,28.258928],[-82.019005,28.215502]]]},"properties":{"id":"33809","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.600887,27.671964],[-81.43263,27.748053],[-81.452118,27.762535],[-81.561732,27.790871],[-81.638361,27.813483],[-81.600887,27.671964]]]},"properties":{"id":"33843","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.413014,27.854556],[-81.40768,27.853584],[-81.406633,27.857234],[-81.411149,27.859712],[-81.413014,27.854556]]]},"properties":{"id":"33854","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.67824,29.479051],[-81.62704,29.52116],[-81.588381,29.564893],[-81.599221,29.606653],[-81.67824,29.479051]]]},"properties":{"id":"32189","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.615524,30.005593],[-81.526074,29.780492],[-81.395138,29.861354],[-81.452358,29.970197],[-81.498318,30.065049],[-81.615524,30.005593]]]},"properties":{"id":"32092","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.38261,27.46973],[-80.373887,27.557332],[-80.398029,27.557522],[-80.480353,27.558106],[-80.425696,27.470109],[-80.38261,27.46973]]]},"properties":{"id":"34951","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.906258,30.997587],[-87.163084,30.999045],[-87.290095,30.909395],[-87.305796,30.777408],[-87.299614,30.766108],[-87.150089,30.722042],[-86.906258,30.997587]]]},"properties":{"id":"32565","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497612,27.269276],[-82.492459,27.218306],[-82.458153,27.176058],[-82.438891,27.190177],[-82.449156,27.269284],[-82.497612,27.269276]]]},"properties":{"id":"34238","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497475,27.322837],[-82.497563,27.299919],[-82.448212,27.298799],[-82.446772,27.357125],[-82.49724,27.351849],[-82.497475,27.322837]]]},"properties":{"id":"34232","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.417262,27.113534],[-82.47388,27.111519],[-82.450078,27.060683],[-82.437986,27.055796],[-82.391449,27.079119],[-82.417262,27.113534]]]},"properties":{"id":"34285","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.416102,28.755702],[-81.459383,28.683745],[-81.439725,28.67615],[-81.388871,28.690577],[-81.374957,28.723805],[-81.416102,28.755702]]]},"properties":{"id":"32779","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.048269,28.785823],[-82.08822,28.79989],[-82.087673,28.795508],[-82.048269,28.785823]]]},"properties":{"id":"33521","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.4942,30.0906],[-83.753208,30.131914],[-83.596121,29.765012],[-83.47144,29.77811],[-83.443312,29.939304],[-83.4942,30.0906]]]},"properties":{"id":"32348","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.3945,29.77855],[-83.443312,29.939304],[-83.47144,29.77811],[-83.3945,29.77855]]]},"properties":{"id":"32356","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.852799,28.900789],[-80.911412,29.076889],[-80.935631,29.062225],[-80.908923,29.006344],[-80.890479,28.972563],[-80.854309,28.903925],[-80.852799,28.900789]]]},"properties":{"id":"32169","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.102994,29.220003],[-81.022279,29.230231],[-81.039777,29.263857],[-81.102994,29.220003]]]},"properties":{"id":"32117","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.355509,29.261896],[-81.502797,29.106274],[-81.501477,29.099725],[-81.392084,29.019979],[-81.342438,29.105312],[-81.243061,29.148227],[-81.268404,29.263182],[-81.355509,29.261896]]]},"properties":{"id":"32130","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.051136,30.883127],[-86.132722,30.993315],[-86.296459,30.99375],[-86.382464,30.851163],[-86.364917,30.738186],[-86.037527,30.722085],[-86.051136,30.883127]]]},"properties":{"id":"32433","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.607114,29.712833],[-82.661544,29.726131],[-82.67672,29.52872],[-82.639654,29.521288],[-82.473338,29.652699],[-82.472384,29.659825],[-82.495981,29.697377],[-82.607114,29.712833]]]},"properties":{"id":"32669","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.55882,29.926512],[-82.607114,29.712833],[-82.495981,29.697377],[-82.449496,29.71098],[-82.401068,29.83269],[-82.376884,29.866991],[-82.418728,29.923093],[-82.426264,29.921789],[-82.448644,29.931716],[-82.529705,29.940881],[-82.55882,29.926512]],[[-82.497045,29.788907],[-82.49162,29.79806],[-82.485533,29.797613],[-82.497118,29.780604],[-82.502255,29.784565],[-82.497045,29.788907]]]},"properties":{"id":"32615","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.347301,29.703272],[-82.400207,29.832136],[-82.401068,29.83269],[-82.449496,29.71098],[-82.381897,29.703365],[-82.347301,29.703272]]]},"properties":{"id":"32653","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.352892,30.224187],[-82.365569,30.184642],[-82.277071,30.327968],[-82.352892,30.224187]]],[[[-82.209444,30.423284],[-82.288073,30.573419],[-82.347557,30.433185],[-82.255401,30.354108],[-82.209444,30.423284]]]]},"properties":{"id":"32087","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.671453,28.107085],[-80.638188,28.035192],[-80.581876,28.063695],[-80.589376,28.076494],[-80.603976,28.107093],[-80.671453,28.107085]]]},"properties":{"id":"32901","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.715376,28.349484],[-80.663476,28.232189],[-80.651189,28.206243],[-80.613424,28.134078],[-80.613139,28.134166],[-80.633605,28.209132],[-80.648975,28.271787],[-80.648975,28.372783],[-80.646239,28.409125],[-80.674359,28.40914],[-80.717794,28.355695],[-80.715376,28.349484]]]},"properties":{"id":"32952","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.773962,28.328366],[-80.663476,28.232189],[-80.715376,28.349484],[-80.754956,28.349273],[-80.773962,28.328366]]]},"properties":{"id":"32955","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.613611,27.998343],[-80.604038,27.931513],[-80.532161,27.96356],[-80.55279,28.012464],[-80.601677,28.012897],[-80.613611,27.998343]]]},"properties":{"id":"32950","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.132881,26.260603],[-80.106126,26.259102],[-80.080665,26.297057],[-80.119684,26.304643],[-80.152367,26.289523],[-80.152064,26.274541],[-80.132881,26.260603]]]},"properties":{"id":"33064","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.240569,26.230021],[-80.236071,26.197241],[-80.203951,26.200009],[-80.195587,26.209063],[-80.195463,26.210031],[-80.233745,26.230131],[-80.240569,26.230021]]]},"properties":{"id":"33068","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.244714,26.164321],[-80.297984,26.164263],[-80.297725,26.135471],[-80.250674,26.135591],[-80.244714,26.164321]]]},"properties":{"id":"33322","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.298032,26.193665],[-80.252002,26.194966],[-80.236071,26.197241],[-80.240569,26.230021],[-80.298015,26.229439],[-80.298032,26.193665]]]},"properties":{"id":"33321","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.552576,28.694635],[-82.517283,28.80399],[-82.529898,28.86272],[-82.765871,28.782123],[-82.674634,28.694265],[-82.552576,28.694635]]]},"properties":{"id":"34448","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.477976,28.955777],[-82.423184,28.968083],[-82.422861,28.972627],[-82.349512,28.988765],[-82.464738,29.045817],[-82.477976,28.955777]]]},"properties":{"id":"34434","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.56252,26.422619],[-81.506276,26.211948],[-81.506785,26.153116],[-81.457185,26.052974],[-81.37754,25.980004],[-81.357453,25.950182],[-81.086867,26.256289],[-81.259538,26.574713],[-81.563763,26.513324],[-81.562675,26.451517],[-81.56252,26.422619]]]},"properties":{"id":"34142","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.371092,25.832885],[-81.382274,25.873429],[-81.468043,25.808121],[-81.39532,25.803253],[-81.371092,25.832885]]]},"properties":{"id":"34139","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.791511,26.174259],[-81.783962,26.159978],[-81.731186,26.168588],[-81.735938,26.21161],[-81.792283,26.211333],[-81.791511,26.174259]]]},"properties":{"id":"34105","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458063,30.201662],[-82.68852,30.17996],[-82.560889,29.931279],[-82.55882,29.926512],[-82.529705,29.940881],[-82.560029,30.015817],[-82.458555,30.143801],[-82.458063,30.201662]]]},"properties":{"id":"32025","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.958667,27.03505],[-81.895308,27.144924],[-81.898029,27.151187],[-81.890984,27.151147],[-82.057516,27.076488],[-82.057469,27.032119],[-82.05595,27.032038],[-81.99154,27.032075],[-81.958667,27.03505]]]},"properties":{"id":"34269","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.409273,29.550177],[-83.306923,29.545334],[-83.2939,29.70895],[-83.3945,29.77855],[-83.47144,29.77811],[-83.596121,29.765012],[-83.409273,29.550177]]]},"properties":{"id":"32359","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.620727,30.321083],[-81.599401,30.313968],[-81.558793,30.316554],[-81.552559,30.352445],[-81.613416,30.346239],[-81.618368,30.327292],[-81.620727,30.321083]]]},"properties":{"id":"32211","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.639736,30.392321],[-81.626417,30.386939],[-81.601224,30.402853],[-81.601224,30.412473],[-81.639736,30.392321]]],[[[-81.608717,30.574892],[-81.583334,30.409355],[-81.45931,30.38394],[-81.427156,30.399157],[-81.388705,30.39695],[-81.434777,30.506896],[-81.550926,30.543027],[-81.608717,30.574892]]]]},"properties":{"id":"32226","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.427156,30.399157],[-81.392159,30.38145],[-81.388705,30.39695],[-81.427156,30.399157]],[[-81.402744,30.390499],[-81.398751,30.392039],[-81.399102,30.387901],[-81.402744,30.390499]]]},"properties":{"id":"32227","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.192825,30.420536],[-87.241563,30.415698],[-87.247666,30.401825],[-87.23879,30.398448],[-87.192393,30.419042],[-87.192825,30.420536]]]},"properties":{"id":"32502","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.544278,29.305528],[-81.536046,29.215896],[-81.502797,29.106274],[-81.355509,29.261896],[-81.419097,29.321384],[-81.544278,29.305528]]]},"properties":{"id":"32180","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.57292,29.90968],[-84.63932,29.90705],[-84.618686,29.869145],[-84.5706,29.90614],[-84.57292,29.90968]]]},"properties":{"id":"32323","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.49874,29.97261],[-84.52042,29.92337],[-84.4999,29.887032],[-84.370164,29.897559],[-84.413961,30.054676],[-84.4133,30.15689],[-84.661201,30.300219],[-84.713351,30.30017],[-84.605136,30.050624],[-84.54445,30.01103],[-84.49874,29.97261]]]},"properties":{"id":"32358","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.32165,26.91791],[-81.244276,27.050309],[-81.267289,27.067329],[-81.326133,26.919378],[-81.32165,26.91791]]]},"properties":{"id":"33944","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.943048,26.776497],[-81.27169,26.768817],[-81.258772,26.648562],[-81.259538,26.574713],[-81.086867,26.256289],[-80.879809,26.259455],[-80.748368,26.667712],[-80.748302,26.681214],[-80.943048,26.776497]]]},"properties":{"id":"33440","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.58255,28.433869],[-82.564531,28.520945],[-82.571456,28.518856],[-82.63986,28.433844],[-82.58255,28.433869]]]},"properties":{"id":"34606","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.484028,28.065972],[-82.48416,28.033069],[-82.416326,28.032616],[-82.416201,28.054439],[-82.420496,28.065986],[-82.484028,28.065972]]]},"properties":{"id":"33612","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.526103,27.996153],[-82.566985,27.972903],[-82.572103,27.971828],[-82.551088,27.961325],[-82.482982,27.952086],[-82.464737,27.95637],[-82.480988,27.975119],[-82.485549,27.981297],[-82.526103,27.996153]]]},"properties":{"id":"33607","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.831123,30.99412],[-85.821616,30.790775],[-85.74264,30.667763],[-85.679628,30.657838],[-85.599934,30.830552],[-85.583398,30.996172],[-85.831123,30.99412]]]},"properties":{"id":"32425","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.132722,30.993315],[-86.051136,30.883127],[-85.826734,30.745322],[-85.821616,30.790775],[-85.831123,30.99412],[-86.132722,30.993315]]]},"properties":{"id":"32464","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.55091,27.76757],[-80.46189,27.74856],[-80.46195,27.75184],[-80.44132,27.7515],[-80.42919,27.753141],[-80.478642,27.854615],[-80.554462,27.822188],[-80.55091,27.76757]]]},"properties":{"id":"32958","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.369689,27.626813],[-80.41389,27.61639],[-80.398029,27.557522],[-80.373887,27.557332],[-80.348422,27.557248],[-80.348167,27.557249],[-80.369689,27.626813]]]},"properties":{"id":"32962","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.324149,30.698672],[-85.33902,30.566286],[-85.047506,30.606625],[-85.079116,30.709247],[-85.07906,30.712751],[-85.339206,30.784948],[-85.324149,30.698672]],[[-85.251797,30.756375],[-85.258938,30.760286],[-85.253064,30.765131],[-85.246323,30.762885],[-85.251797,30.756375]]]},"properties":{"id":"32448","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.078945,30.722449],[-85.07906,30.712751],[-85.079116,30.709247],[-85.076959,30.711187],[-85.078945,30.722449]]]},"properties":{"id":"32432","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.736043,30.423505],[-83.992573,30.080327],[-83.82506,30.2145],[-83.736043,30.423505]]]},"properties":{"id":"32336","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.782229,28.594739],[-81.817029,28.474819],[-81.714752,28.484714],[-81.677187,28.558789],[-81.69245,28.568972],[-81.782229,28.594739]]]},"properties":{"id":"34711","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.817029,28.474819],[-81.873981,28.345415],[-81.706606,28.346597],[-81.65694,28.327521],[-81.657268,28.347098],[-81.714752,28.484714],[-81.817029,28.474819]]]},"properties":{"id":"34714","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.55424,28.529676],[-81.563132,28.606679],[-81.571965,28.606835],[-81.657074,28.563228],[-81.677187,28.558789],[-81.714752,28.484714],[-81.657268,28.347098],[-81.606965,28.376369],[-81.579316,28.432333],[-81.55424,28.529676]],[[-81.638155,28.549028],[-81.635184,28.559319],[-81.624798,28.553198],[-81.638155,28.549028]]]},"properties":{"id":"34787","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.787954,28.742875],[-81.825716,28.71764],[-81.853319,28.675275],[-81.804494,28.650388],[-81.754842,28.698178],[-81.75701,28.725107],[-81.781335,28.74546],[-81.787954,28.742875]]]},"properties":{"id":"34737","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.684681,28.723901],[-81.690326,28.821934],[-81.707736,28.835368],[-81.761182,28.822444],[-81.779863,28.817072],[-81.781335,28.74546],[-81.75701,28.725107],[-81.684681,28.723901]]]},"properties":{"id":"32778","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.871985,28.762233],[-81.841344,28.748373],[-81.845387,28.75612],[-81.871985,28.762233]]],[[[-81.953994,28.748078],[-81.896497,28.715426],[-81.953585,28.791247],[-81.953661,28.75533],[-81.953994,28.748078]]]]},"properties":{"id":"34762","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.794239,26.492818],[-81.844789,26.49242],[-81.821499,26.452534],[-81.783678,26.448826],[-81.794239,26.492818]]]},"properties":{"id":"33967","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.931333,26.770051],[-82.061068,26.770423],[-82.102959,26.665688],[-82.104882,26.614403],[-82.068798,26.563442],[-81.974143,26.652288],[-81.944461,26.740805],[-81.924638,26.757891],[-81.931333,26.770051]]]},"properties":{"id":"33993","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.890766,26.596507],[-81.855601,26.604427],[-81.870551,26.653079],[-81.901373,26.627561],[-81.904078,26.603201],[-81.890766,26.596507]]]},"properties":{"id":"33901","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.663095,26.673455],[-81.566258,26.68725],[-81.565931,26.769552],[-81.76074,26.7696],[-81.760807,26.717275],[-81.69563,26.685857],[-81.663095,26.673455]]]},"properties":{"id":"33920","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.772625,26.449382],[-81.757269,26.471275],[-81.785522,26.470349],[-81.774298,26.449379],[-81.772625,26.449382]]]},"properties":{"id":"33965","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.072739,30.675455],[-84.252118,30.501784],[-84.158615,30.485167],[-84.041188,30.507744],[-84.007454,30.672127],[-84.072739,30.675455]]]},"properties":{"id":"32309","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28913,30.449067],[-84.397406,30.485008],[-84.40721,30.47333],[-84.413639,30.470568],[-84.289798,30.433391],[-84.28913,30.449067]]]},"properties":{"id":"32304","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.626988,29.292329],[-82.757143,29.133033],[-82.65682,29.1372],[-82.648457,29.230577],[-82.62216,29.23258],[-82.626988,29.292329]]]},"properties":{"id":"32639","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.559896,29.478349],[-82.5919,29.33317],[-82.384993,29.337756],[-82.323877,29.427781],[-82.412932,29.485092],[-82.559896,29.478349]]]},"properties":{"id":"32696","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.133348,27.208211],[-82.055753,27.338264],[-82.055016,27.512449],[-82.258626,27.555898],[-82.354539,27.493423],[-82.302058,27.475443],[-82.303834,27.39865],[-82.268238,27.386304],[-82.253166,27.287755],[-82.133348,27.208211]]]},"properties":{"id":"34251","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.57952,27.389209],[-82.503009,27.381098],[-82.459515,27.38833],[-82.486153,27.424671],[-82.563152,27.425703],[-82.59622,27.40522],[-82.59652,27.38932],[-82.57952,27.389209]]]},"properties":{"id":"34243","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.691521,27.443517],[-82.592981,27.32452],[-82.57952,27.336823],[-82.62552,27.38962],[-82.680021,27.453517],[-82.691521,27.443517]]]},"properties":{"id":"34228","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.22538,28.960163],[-82.18632,28.960008],[-82.11992,28.96006],[-82.125078,29.02191],[-82.136195,29.066158],[-82.252525,29.037272],[-82.252555,29.01173],[-82.22538,28.960163]]]},"properties":{"id":"34473","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.24415,29.129423],[-82.252525,29.037272],[-82.136195,29.066158],[-82.136218,29.123627],[-82.184956,29.121298],[-82.24415,29.129423]]]},"properties":{"id":"34476","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.252525,29.037272],[-82.24415,29.129423],[-82.251671,29.186775],[-82.368028,29.187252],[-82.252555,29.01173],[-82.252525,29.037272]]]},"properties":{"id":"34481","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.18487,29.186387],[-82.251671,29.186775],[-82.24415,29.129423],[-82.184956,29.121298],[-82.168707,29.186342],[-82.18487,29.186387]]]},"properties":{"id":"34474","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.43239,27.206025],[-80.316307,27.067033],[-80.251323,27.16011],[-80.297436,27.23243],[-80.321226,27.242454],[-80.331821,27.205724],[-80.400706,27.205778],[-80.43239,27.206025]]]},"properties":{"id":"34990","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.630982,26.86484],[-80.62921,26.855473],[-80.564174,26.957979],[-80.604258,26.987507],[-80.619123,26.964551],[-80.630982,26.86484]]]},"properties":{"id":"33438","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.339101,25.957078],[-80.341605,25.927926],[-80.324969,25.920954],[-80.293226,25.925433],[-80.294976,25.956929],[-80.310617,25.957165],[-80.339101,25.957078]]]},"properties":{"id":"33015","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.442318,25.506665],[-80.445423,25.546333],[-80.560203,25.549965],[-80.559736,25.49859],[-80.457233,25.504737],[-80.442318,25.506665]]]},"properties":{"id":"33031","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.462975,25.462449],[-80.562766,25.481925],[-80.487034,25.212306],[-80.433555,25.236375],[-80.46064,25.440523],[-80.46079,25.462467],[-80.462975,25.462449]]]},"properties":{"id":"33034","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.380237,25.62741],[-80.380577,25.58484],[-80.306155,25.581601],[-80.287978,25.630279],[-80.333609,25.629095],[-80.380237,25.62741]]]},"properties":{"id":"33157","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.429987,25.687056],[-80.430988,25.712515],[-80.479969,25.725379],[-80.498817,25.682282],[-80.429937,25.68497],[-80.429987,25.687056]]]},"properties":{"id":"33193","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.382583,25.714857],[-80.431068,25.713879],[-80.430988,25.712515],[-80.429987,25.687056],[-80.384579,25.686479],[-80.38193,25.688152],[-80.382583,25.714857]]]},"properties":{"id":"33183","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.33354,25.688077],[-80.332068,25.643917],[-80.282489,25.643355],[-80.249824,25.690189],[-80.33354,25.688077]]]},"properties":{"id":"33156","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.382583,25.714857],[-80.38193,25.688152],[-80.33354,25.688077],[-80.334916,25.71674],[-80.382117,25.714877],[-80.382583,25.714857]]]},"properties":{"id":"33173","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.285887,25.718163],[-80.334916,25.71674],[-80.33354,25.688077],[-80.249824,25.690189],[-80.245441,25.70203],[-80.260834,25.705648],[-80.285887,25.718163]]]},"properties":{"id":"33143","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.216801,25.831968],[-80.218243,25.872558],[-80.218383,25.876152],[-80.259052,25.870702],[-80.259369,25.838072],[-80.259175,25.830676],[-80.216801,25.831968]]]},"properties":{"id":"33147","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.182475,25.73721],[-80.131367,25.747677],[-80.154968,25.764277],[-80.173469,25.769776],[-80.179143,25.751338],[-80.182475,25.73721]]]},"properties":{"id":"33149","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.334912,25.754949],[-80.287609,25.757171],[-80.28812,25.77078],[-80.336426,25.769605],[-80.334912,25.754949]]]},"properties":{"id":"33144","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.219956,25.912593],[-80.218383,25.876152],[-80.218243,25.872558],[-80.197939,25.87325],[-80.19987,25.909864],[-80.219956,25.912593]]]},"properties":{"id":"33168","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.189515,25.941449],[-80.199677,25.913234],[-80.167457,25.914156],[-80.156814,25.915072],[-80.15645,25.941208],[-80.156584,25.944475],[-80.189515,25.941449]]]},"properties":{"id":"33162","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.197881,25.778859],[-80.197922,25.779799],[-80.199594,25.779749],[-80.199545,25.778783],[-80.197881,25.778859]]]},"properties":{"id":"33101","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.020574,24.732306],[-81.024822,24.716543],[-81.010385,24.721913],[-81.020574,24.732306]]]},"properties":{"id":"33051","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.386471,28.60592],[-81.373109,28.582265],[-81.339806,28.571074],[-81.324644,28.578933],[-81.328013,28.625033],[-81.386579,28.610294],[-81.386471,28.60592]]]},"properties":{"id":"32789","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.55424,28.529676],[-81.579316,28.432333],[-81.576633,28.429865],[-81.523431,28.438852],[-81.508547,28.493605],[-81.508806,28.527778],[-81.532977,28.5227],[-81.55424,28.529676]]]},"properties":{"id":"34786","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.207431,28.565148],[-81.211775,28.567338],[-81.211753,28.565098],[-81.207431,28.565148]]],[[[-81.207431,28.565148],[-81.187118,28.495525],[-81.13334,28.499057],[-81.154201,28.565418],[-81.207431,28.565148]]]]},"properties":{"id":"32828","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.247023,26.681669],[-80.174107,26.650929],[-80.147387,26.701187],[-80.134393,26.750637],[-80.262783,26.765254],[-80.247023,26.681669]]]},"properties":{"id":"33411","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.247023,26.681669],[-80.288208,26.608381],[-80.204003,26.631704],[-80.173812,26.632092],[-80.174107,26.650929],[-80.247023,26.681669]]]},"properties":{"id":"33414","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.043467,26.644394],[-80.045873,26.594181],[-80.033071,26.58403],[-80.027909,26.772939],[-80.048463,26.76314],[-80.046126,26.724197],[-80.046363,26.690443],[-80.043467,26.644394]]]},"properties":{"id":"33480","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17217,26.527951],[-80.220065,26.483226],[-80.172167,26.48375],[-80.17217,26.527951]]]},"properties":{"id":"33473","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.717627,28.263865],[-82.715296,28.209848],[-82.698941,28.21699],[-82.673917,28.240084],[-82.675765,28.28065],[-82.717627,28.263865]]]},"properties":{"id":"34653","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.675765,28.28065],[-82.666107,28.330312],[-82.666109,28.331013],[-82.724784,28.342188],[-82.748056,28.265674],[-82.717627,28.263865],[-82.675765,28.28065]]]},"properties":{"id":"34668","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.646743,27.785291],[-82.624298,27.753694],[-82.607976,27.785242],[-82.646743,27.785291]]]},"properties":{"id":"33701","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.635352,27.903903],[-82.679734,27.864822],[-82.671803,27.849005],[-82.608404,27.873194],[-82.635352,27.903903]]]},"properties":{"id":"33716","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.728932,27.871772],[-82.778935,27.865345],[-82.778924,27.835602],[-82.764723,27.818803],[-82.753945,27.829054],[-82.747085,27.848296],[-82.728932,27.871772]]]},"properties":{"id":"33777","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.782796,28.055947],[-82.74279,28.044212],[-82.742902,28.04879],[-82.74801,28.123022],[-82.788288,28.122628],[-82.781255,28.100309],[-82.772601,28.094894],[-82.779282,28.077203],[-82.782796,28.055947]]]},"properties":{"id":"34683","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.74801,28.123022],[-82.742902,28.04879],[-82.709235,28.048992],[-82.70722,28.04897],[-82.709243,28.060096],[-82.726158,28.122916],[-82.74801,28.123022]]]},"properties":{"id":"34684","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.779282,28.077203],[-82.772601,28.094894],[-82.781255,28.100309],[-82.779282,28.077203]]]},"properties":{"id":"34681","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.709235,28.048992],[-82.70892,28.012165],[-82.661692,27.978998],[-82.70722,28.04897],[-82.709235,28.048992]]]},"properties":{"id":"34695","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.762413,27.927643],[-82.762606,27.960588],[-82.805425,27.975954],[-82.824325,27.936798],[-82.829225,27.922699],[-82.770691,27.927579],[-82.762413,27.927643]]]},"properties":{"id":"33756","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.710829,27.877373],[-82.717156,27.886343],[-82.717822,27.879143],[-82.710829,27.877373]]],[[[-82.762606,27.960588],[-82.762413,27.927643],[-82.727227,27.900923],[-82.725194,27.936724],[-82.709218,27.937078],[-82.730072,27.955376],[-82.762295,27.960586],[-82.762606,27.960588]]]]},"properties":{"id":"33764","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.74279,28.044212],[-82.782796,28.055947],[-82.801224,27.997796],[-82.763096,27.997252],[-82.740272,28.027463],[-82.74279,28.044212]]]},"properties":{"id":"34698","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.9508,27.740775],[-81.741321,27.646277],[-81.63329,27.646467],[-81.600887,27.671964],[-81.638361,27.813483],[-81.662234,27.841817],[-81.821637,27.820533],[-81.814883,27.791551],[-81.833097,27.791607],[-81.891439,27.790036],[-81.9508,27.740775]]]},"properties":{"id":"33841","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.595352,27.92448],[-81.581352,27.872078],[-81.571219,27.842818],[-81.452118,27.762535],[-81.43263,27.748053],[-81.311891,27.646179],[-81.142164,27.643238],[-81.170924,27.743649],[-81.217183,27.790847],[-81.201633,27.804798],[-81.424547,28.012476],[-81.586724,28.011488],[-81.60533,27.999396],[-81.594816,27.977323],[-81.595352,27.92448]],[[-81.424406,27.855938],[-81.427904,27.85532],[-81.431922,27.857159],[-81.42802,27.859552],[-81.424406,27.855938]],[[-81.413014,27.854556],[-81.411149,27.859712],[-81.406633,27.857234],[-81.40768,27.853584],[-81.413014,27.854556]],[[-81.358251,27.792703],[-81.358141,27.799051],[-81.341887,27.79911],[-81.34188,27.791124],[-81.358251,27.792703]]]},"properties":{"id":"33898","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.832785,28.112902],[-81.756497,28.117501],[-81.743964,28.182168],[-81.722791,28.190671],[-81.706606,28.346597],[-81.873981,28.345415],[-81.957641,28.34518],[-81.957805,28.308888],[-81.854369,28.152145],[-81.832785,28.112902]]]},"properties":{"id":"33868","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.581352,27.872078],[-81.595352,27.92448],[-81.605942,27.922762],[-81.581352,27.872078]]]},"properties":{"id":"33853","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.569749,29.48063],[-81.56894,29.48613],[-81.59073,29.47605],[-81.59091,29.460222],[-81.56954,29.46144],[-81.569749,29.48063]]]},"properties":{"id":"32157","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.250039,27.381439],[-80.317128,27.557345],[-80.348167,27.557249],[-80.348422,27.557248],[-80.321,27.467165],[-80.301263,27.416495],[-80.286609,27.381274],[-80.250039,27.381439]]]},"properties":{"id":"34949","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.256743,30.634356],[-87.147967,30.569113],[-87.088029,30.611372],[-87.150089,30.722042],[-87.299614,30.766108],[-87.266704,30.670294],[-87.256743,30.634356]]]},"properties":{"id":"32571","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.881703,30.704717],[-87.088029,30.611372],[-87.147967,30.569113],[-86.793582,30.636646],[-86.881703,30.704717]],[[-87.029515,30.599257],[-87.032075,30.595868],[-87.033592,30.597649],[-87.029515,30.599257]]]},"properties":{"id":"32583","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.203442,27.032843],[-82.208882,27.070977],[-82.282641,27.078142],[-82.293708,27.053585],[-82.255302,27.031086],[-82.204768,27.03284],[-82.203442,27.032843]]]},"properties":{"id":"34287","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497564,27.298778],[-82.544738,27.298894],[-82.501924,27.214539],[-82.492459,27.218306],[-82.497612,27.269276],[-82.497564,27.298778]]]},"properties":{"id":"34231","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.06326,30.13015],[-82.87876,30.09941],[-82.84522,30.09882],[-82.895008,30.145635],[-83.06326,30.13015]]]},"properties":{"id":"32062","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.983093,29.153106],[-81.058295,29.129059],[-81.02899,29.106041],[-80.983093,29.153106]]]},"properties":{"id":"32129","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.151818,28.92232],[-81.004339,28.856732],[-80.930784,28.898755],[-80.95096,28.950742],[-80.908923,29.006344],[-80.935631,29.062225],[-81.021477,29.090794],[-81.134691,29.082622],[-81.161584,28.934663],[-81.151818,28.92232]]]},"properties":{"id":"32168","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.2404,28.954106],[-81.216267,28.84661],[-81.151818,28.92232],[-81.161584,28.934663],[-81.183657,28.970819],[-81.2404,28.954106]]]},"properties":{"id":"32738","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.890479,28.972563],[-80.95096,28.950742],[-80.930784,28.898755],[-80.854309,28.903925],[-80.890479,28.972563]]]},"properties":{"id":"32141","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.319641,30.415242],[-86.374949,30.372162],[-86.079918,30.296174],[-86.045857,30.351291],[-86.133368,30.38809],[-86.319641,30.415242]]]},"properties":{"id":"32459","state_code":"12"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.17961,31.901503],[-82.229409,31.574702],[-82.208478,31.588879],[-82.094244,31.853645],[-82.114914,31.900982],[-82.17961,31.901503]]]},"properties":{"id":"31563","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.240772,31.555677],[-82.173464,31.42279],[-82.132794,31.471262],[-82.132867,31.552139],[-82.208478,31.588879],[-82.229409,31.574702],[-82.240772,31.555677]]]},"properties":{"id":"31518","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983364,31.450931],[-83.11166,31.478229],[-83.120554,31.473559],[-83.145587,31.472276],[-83.058325,31.308259],[-83.047957,31.290009],[-82.849617,31.403189],[-82.983364,31.450931]]]},"properties":{"id":"31650","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.628975,31.36393],[-82.673317,31.187234],[-82.568925,31.207314],[-82.553919,31.369963],[-82.628975,31.36393]]]},"properties":{"id":"31552","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.628975,31.36393],[-82.553919,31.369963],[-82.437374,31.416907],[-82.627366,31.665177],[-82.736349,31.570091],[-82.789529,31.504994],[-82.678682,31.373391],[-82.628975,31.36393]]]},"properties":{"id":"31554","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.35362,32.969527],[-83.229212,32.955063],[-83.162436,32.945016],[-83.073648,32.946562],[-83.052197,33.080682],[-83.274011,33.199923],[-83.42909,33.185352],[-83.35362,32.969527]],[[-83.220149,33.048021],[-83.212446,33.047335],[-83.212583,33.043839],[-83.218308,33.043516],[-83.220149,33.048021]]]},"properties":{"id":"31061","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.41166,34.306473],[-83.427706,34.131385],[-83.357193,34.122524],[-83.297825,34.165549],[-83.321826,34.26519],[-83.388819,34.361604],[-83.41166,34.306473]]]},"properties":{"id":"30530","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.848401,34.139223],[-83.875359,34.113847],[-83.858486,34.103816],[-83.848401,34.139223]]],[[[-83.723441,34.14256],[-83.833011,34.090426],[-83.874678,34.082014],[-83.882342,34.056479],[-83.875867,34.054899],[-83.797699,34.055602],[-83.673454,34.053036],[-83.675267,34.142838],[-83.723441,34.14256]]]]},"properties":{"id":"30548","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.661892,33.916883],[-83.824235,33.964167],[-83.832718,33.967645],[-83.815501,33.908919],[-83.661892,33.916883]]]},"properties":{"id":"30620","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.826627,34.43033],[-84.65981,34.387671],[-84.582658,34.385768],[-84.590508,34.436284],[-84.617223,34.468164],[-84.621478,34.468626],[-84.802608,34.503755],[-84.825337,34.433776],[-84.826627,34.43033]]]},"properties":{"id":"30139","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.675536,34.034403],[-84.608341,34.064465],[-84.712899,34.088064],[-84.73971,34.090547],[-84.771861,34.082431],[-84.755223,34.010247],[-84.734543,33.968195],[-84.713678,33.975332],[-84.675536,34.034403]]]},"properties":{"id":"30101","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.388889,31.654727],[-83.404588,31.797673],[-83.534478,31.84766],[-83.55751,31.853278],[-83.560239,31.853277],[-83.480406,31.707108],[-83.49057,31.676699],[-83.417922,31.63107],[-83.388889,31.654727]]]},"properties":{"id":"31783","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.733149,32.833191],[-83.816618,32.953412],[-83.891685,32.864644],[-83.889078,32.835569],[-83.754746,32.831525],[-83.733149,32.833191]]]},"properties":{"id":"31220","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.122207,32.634794],[-83.185408,32.604362],[-83.226536,32.584201],[-83.262094,32.502054],[-83.26419,32.480238],[-83.189502,32.473706],[-83.081276,32.570957],[-83.122207,32.634794]]]},"properties":{"id":"31065","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.740576,30.900312],[-83.699446,30.859711],[-83.737523,30.900014],[-83.740576,30.900312]]]},"properties":{"id":"31720","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.993976,33.368496],[-84.054123,33.337558],[-84.043175,33.287281],[-83.993976,33.368496]]]},"properties":{"id":"30234","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.061559,33.826636],[-85.216537,33.765581],[-85.216506,33.689486],[-85.163231,33.685883],[-85.064181,33.687395],[-85.061559,33.826636]]]},"properties":{"id":"30110","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.061559,33.826636],[-85.064181,33.687395],[-85.041486,33.663114],[-84.977932,33.680896],[-84.896879,33.810313],[-85.004305,33.87718],[-85.070691,33.88269],[-85.061559,33.826636]]]},"properties":{"id":"30179","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.143665,34.977697],[-85.139796,34.972371],[-85.135046,34.9765],[-85.143665,34.977697]]]},"properties":{"id":"30726","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.085284,32.048277],[-81.094652,32.024423],[-81.031105,32.019409],[-81.006698,32.070562],[-81.063961,32.080496],[-81.085284,32.048277]]]},"properties":{"id":"31404","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.366922,32.159275],[-81.367481,32.126254],[-81.379574,32.133301],[-81.380696,32.144478],[-81.368525,32.160041],[-81.3829,32.167088],[-81.401931,32.150184],[-81.384727,32.079517],[-81.35588,32.041833],[-81.305068,32.092987],[-81.284699,32.173049],[-81.366922,32.159275]]]},"properties":{"id":"31302","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.479794,34.3697],[-84.360433,34.253793],[-84.290762,34.254054],[-84.246357,34.335158],[-84.26957,34.415652],[-84.32205,34.419663],[-84.366018,34.428333],[-84.374504,34.380782],[-84.479794,34.3697]]]},"properties":{"id":"30107","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.246357,34.335158],[-84.290762,34.254054],[-84.282959,34.235731],[-84.087051,34.263542],[-84.056983,34.328655],[-84.246357,34.335158]]]},"properties":{"id":"30028","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.329123,33.975183],[-83.275933,33.847977],[-83.200605,33.946852],[-83.248463,34.005589],[-83.274778,34.006192],[-83.329123,33.975183]]]},"properties":{"id":"30683","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.380927,33.950791],[-83.383744,33.9501],[-83.383172,33.948201],[-83.380266,33.948751],[-83.380927,33.950791]]]},"properties":{"id":"30609","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.054651,31.747742],[-84.958864,31.734606],[-84.95864,31.777854],[-85.054651,31.747742]]],[[[-84.960288,31.950267],[-84.957824,31.777838],[-84.798486,31.922799],[-84.960288,31.950267]]]]},"properties":{"id":"39867","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.376901,33.458587],[-82.316964,33.338497],[-82.243186,33.391337],[-82.275575,33.492097],[-82.398091,33.498081],[-82.376901,33.458587]]]},"properties":{"id":"30814","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.731645,33.489463],[-84.710386,33.337024],[-84.638921,33.500239],[-84.731645,33.489463]]]},"properties":{"id":"30265","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.869551,33.432525],[-84.879569,33.444056],[-84.874249,33.434372],[-84.869551,33.432525]]]},"properties":{"id":"30275","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.49342,30.892046],[-84.460196,31.051169],[-84.5133,31.060674],[-84.656734,31.036588],[-84.749938,30.775234],[-84.49342,30.892046]]]},"properties":{"id":"39817","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.376612,31.078883],[-84.380072,30.736875],[-84.260907,30.914001],[-84.258913,31.009924],[-84.346135,31.078908],[-84.376612,31.078883]]]},"properties":{"id":"39897","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.245595,33.783142],[-84.246273,33.786937],[-84.246498,33.787046],[-84.245595,33.783142]]],[[[-84.232497,33.763756],[-84.197838,33.736845],[-84.167904,33.798407],[-84.18887,33.83314],[-84.217588,33.81756],[-84.233832,33.78671],[-84.232497,33.763756]],[[-84.208523,33.786854],[-84.208466,33.795222],[-84.203418,33.792613],[-84.203726,33.787017],[-84.208523,33.786854]]]]},"properties":{"id":"30083","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.349175,33.753552],[-84.309672,33.739215],[-84.281287,33.747975],[-84.312557,33.765092],[-84.349175,33.753552]]]},"properties":{"id":"30317","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.208877,33.660808],[-84.170181,33.645836],[-84.115791,33.614666],[-84.074409,33.692743],[-84.088596,33.700435],[-84.170226,33.703504],[-84.197059,33.704725],[-84.208877,33.660808]]]},"properties":{"id":"30038","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.334005,33.803647],[-84.32805,33.790498],[-84.315911,33.795015],[-84.32232,33.797131],[-84.334005,33.803647]]]},"properties":{"id":"30322","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.074409,33.692743],[-84.115791,33.614666],[-84.092057,33.583837],[-84.044493,33.525776],[-84.01462,33.559029],[-84.010627,33.655084],[-84.074409,33.692743]]]},"properties":{"id":"30094","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.140815,32.013392],[-83.080712,31.851733],[-82.9691,31.925854],[-82.962452,32.049081],[-82.999471,32.074765],[-83.115784,32.070028],[-83.140815,32.013392]]]},"properties":{"id":"31060","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.140815,32.013392],[-83.294495,32.038777],[-83.177469,31.847857],[-83.10131,31.811955],[-83.080712,31.851733],[-83.140815,32.013392]]]},"properties":{"id":"31077","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.919715,31.172197],[-85.055821,31.123423],[-85.028564,31.075523],[-84.919715,31.172197]]]},"properties":{"id":"39861","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.482027,32.786734],[-82.554968,32.79264],[-82.614108,32.614922],[-82.460083,32.614478],[-82.434149,32.762258],[-82.482027,32.786734]]]},"properties":{"id":"31049","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.669881,32.592331],[-82.679377,32.506666],[-82.683497,32.460818],[-82.49055,32.496502],[-82.460083,32.614478],[-82.614108,32.614922],[-82.669881,32.592331]]]},"properties":{"id":"31002","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.393725,32.395991],[-82.379516,32.035144],[-82.354891,31.939561],[-82.225042,31.913074],[-82.194821,32.1342],[-82.211921,32.246915],[-82.232788,32.318838],[-82.267882,32.388056],[-82.335039,32.408062],[-82.393725,32.395991]]]},"properties":{"id":"30436","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.392305,34.778403],[-84.260918,34.721645],[-84.453073,34.859469],[-84.470689,34.855034],[-84.392305,34.778403]]]},"properties":{"id":"30522","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.351861,33.901241],[-85.150546,33.905578],[-85.118796,34.052828],[-85.125968,34.083228],[-85.193078,34.138208],[-85.257705,34.096675],[-85.421852,34.080822],[-85.386581,33.901719],[-85.351861,33.901241]]]},"properties":{"id":"30125","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.158446,34.195428],[-85.251814,34.139379],[-85.257705,34.096675],[-85.193078,34.138208],[-85.158446,34.195428]]]},"properties":{"id":"30147","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.998628,34.429357],[-85.088712,34.42502],[-85.087595,34.401315],[-85.035759,34.380086],[-84.998628,34.429357]]]},"properties":{"id":"30733","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.465728,33.754598],[-84.407545,33.743481],[-84.401673,33.763421],[-84.466031,33.754819],[-84.465728,33.754598]]]},"properties":{"id":"30314","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.39979,33.793601],[-84.391098,33.791655],[-84.395029,33.797344],[-84.39979,33.793601]]]},"properties":{"id":"30363","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.591662,34.463108],[-84.380348,34.548898],[-84.512263,34.625899],[-84.654522,34.582897],[-84.621478,34.468626],[-84.617223,34.468164],[-84.591662,34.463108]]]},"properties":{"id":"30175","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.582427,33.160117],[-82.661738,33.129438],[-82.661917,33.126331],[-82.582427,33.160117]]],[[[-82.657597,33.309492],[-82.584175,33.168674],[-82.487586,33.238332],[-82.534827,33.273234],[-82.657597,33.309492]]]]},"properties":{"id":"30810","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.490798,33.109156],[-82.398783,33.136966],[-82.416298,33.28698],[-82.383829,33.312106],[-82.449977,33.340505],[-82.513544,33.343708],[-82.534827,33.273234],[-82.487586,33.238332],[-82.490798,33.109156]]]},"properties":{"id":"30823","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.023676,34.048112],[-84.07401,34.001354],[-84.094404,33.968914],[-84.089572,33.963713],[-84.042694,33.958824],[-83.950838,33.984465],[-83.939105,34.036741],[-84.023676,34.048112]]]},"properties":{"id":"30043","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.042694,33.958824],[-84.013798,33.922183],[-83.948044,33.982593],[-83.950838,33.984465],[-84.042694,33.958824]]]},"properties":{"id":"30046","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.159179,33.936339],[-84.189686,33.970677],[-84.260789,33.932802],[-84.240569,33.928753],[-84.237756,33.904859],[-84.159179,33.936339]]]},"properties":{"id":"30071","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.990957,33.828183],[-83.982033,33.786054],[-83.930908,33.711727],[-83.918235,33.721955],[-83.851945,33.735159],[-83.818477,33.75882],[-83.810443,33.831711],[-83.824104,33.881848],[-83.843184,33.894775],[-83.912865,33.898698],[-83.963221,33.863403],[-83.990957,33.828183]]]},"properties":{"id":"30052","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.741659,34.378662],[-83.879821,34.321946],[-83.829666,34.249746],[-83.739497,34.365264],[-83.741659,34.378662]]]},"properties":{"id":"30501","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.785094,32.716616],[-84.757797,32.846753],[-84.809073,32.840651],[-84.803567,32.80961],[-84.855313,32.811188],[-85.021109,32.796763],[-85.122189,32.773353],[-85.091548,32.660992],[-84.998616,32.696171],[-84.785094,32.716616]]]},"properties":{"id":"31811","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.694603,32.583945],[-84.577971,32.72509],[-84.672148,32.765686],[-84.757221,32.714827],[-84.80206,32.666182],[-84.762414,32.619212],[-84.754685,32.611947],[-84.703322,32.583811],[-84.694603,32.583945]]]},"properties":{"id":"31831","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.099008,34.388997],[-82.967634,34.354218],[-83.02502,34.41079],[-83.099008,34.388997]]]},"properties":{"id":"30516","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.727019,33.438991],[-83.829306,33.406774],[-83.838357,33.398588],[-83.833138,33.39586],[-83.831545,33.392926],[-83.863058,33.368278],[-83.832621,33.20931],[-83.822261,33.180238],[-83.816048,33.131816],[-83.719026,33.146688],[-83.545876,33.171944],[-83.536931,33.401739],[-83.727019,33.438991]]]},"properties":{"id":"31064","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.632811,31.895356],[-81.575426,31.873829],[-81.545949,31.871677],[-81.632811,31.895356]]]},"properties":{"id":"31315","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.533492,31.631552],[-81.575012,31.729223],[-81.658187,31.756927],[-81.765777,31.887847],[-81.821022,31.905458],[-81.838571,31.666854],[-81.82766,31.654859],[-81.663206,31.538667],[-81.533492,31.631552]]]},"properties":{"id":"31316","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.106156,32.440286],[-84.219249,32.291337],[-84.204837,32.230573],[-84.051797,32.240259],[-84.026122,32.373269],[-84.025029,32.432868],[-84.106156,32.440286]]]},"properties":{"id":"31068","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.392316,32.414046],[-84.430215,32.166257],[-84.213211,32.159155],[-84.204837,32.230573],[-84.219249,32.291337],[-84.254613,32.372053],[-84.362648,32.41856],[-84.392316,32.414046]]]},"properties":{"id":"31806","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.470023,32.343032],[-82.59827,32.320441],[-82.574266,32.273526],[-82.538549,32.274486],[-82.470023,32.343032]]]},"properties":{"id":"30470","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590189,33.711408],[-83.674401,33.605114],[-83.643879,33.549042],[-83.591487,33.514037],[-83.590189,33.711408]]]},"properties":{"id":"30663","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.96091,32.496105],[-84.96682,32.454794],[-84.926676,32.437019],[-84.946544,32.50068],[-84.947505,32.502177],[-84.96091,32.496105]]]},"properties":{"id":"31906","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.043503,33.711948],[-82.995602,33.693583],[-82.89234,33.690342],[-82.853736,33.835875],[-82.887374,33.902993],[-82.946795,33.842674],[-83.057642,33.796666],[-83.043503,33.711948]]]},"properties":{"id":"30660","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.368639,33.467321],[-83.533736,33.434472],[-83.536931,33.401739],[-83.545876,33.171944],[-83.525624,33.174389],[-83.42909,33.185352],[-83.274011,33.199923],[-83.164207,33.35503],[-83.178101,33.380268],[-83.279931,33.483438],[-83.368639,33.467321]]]},"properties":{"id":"31024","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.45529,34.732628],[-83.415071,34.755548],[-83.387424,34.737363],[-83.352448,34.716125],[-83.324013,34.789516],[-83.324181,34.789627],[-83.421307,34.798419],[-83.421355,34.794918],[-83.426708,34.793706],[-83.426322,34.796997],[-83.422682,34.79859],[-83.537429,34.791286],[-83.45529,34.732628]]]},"properties":{"id":"30552","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.675828,31.921605],[-84.65582,31.920308],[-84.655079,31.971593],[-84.675786,31.924009],[-84.675828,31.921605]]],[[[-84.65892,32.232852],[-84.754773,32.189997],[-84.648897,31.98433],[-84.594909,31.993727],[-84.583301,32.168214],[-84.65892,32.232852]]]]},"properties":{"id":"31825","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.027813,32.111835],[-82.194821,32.1342],[-82.225042,31.913074],[-82.17961,31.901503],[-82.114914,31.900982],[-81.961193,32.07481],[-82.027813,32.111835]]]},"properties":{"id":"30453","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.362648,32.41856],[-84.254613,32.372053],[-84.197805,32.400999],[-84.319916,32.451017],[-84.362648,32.41856]]]},"properties":{"id":"31081","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.827792,32.056078],[-82.813821,32.051928],[-82.820784,32.05812],[-82.827792,32.056078]]]},"properties":{"id":"31083","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.556292,31.963144],[-84.599776,31.920171],[-84.600547,31.876597],[-84.380461,31.908423],[-84.443807,31.967456],[-84.556292,31.963144]]]},"properties":{"id":"39877","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.521344,31.351129],[-83.536402,31.543437],[-83.584664,31.595701],[-83.643153,31.596545],[-83.649534,31.554686],[-83.652809,31.389617],[-83.521344,31.351129]]]},"properties":{"id":"31793","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.000341,34.987247],[-83.819604,34.913834],[-83.810033,34.987449],[-84.000341,34.987247]]]},"properties":{"id":"30582","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42556,32.80185],[-83.536539,32.542661],[-83.517935,32.498908],[-83.497923,32.452198],[-83.340911,32.594154],[-83.265393,32.690168],[-83.282598,32.774748],[-83.42556,32.80185]]]},"properties":{"id":"31044","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.627145,32.896682],[-82.674853,32.911026],[-82.704343,32.913744],[-82.776063,32.80869],[-82.648125,32.824551],[-82.627145,32.896682]]]},"properties":{"id":"31035","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.940267,31.553741],[-81.82766,31.654859],[-81.838571,31.666854],[-81.986299,31.78708],[-82.032419,31.622276],[-81.940267,31.553741]]]},"properties":{"id":"31545","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.986618,31.769644],[-83.90435,31.6932],[-83.858254,31.684739],[-83.802223,31.803495],[-83.843778,31.826975],[-83.939437,31.847929],[-83.958008,31.835825],[-83.986618,31.769644]]]},"properties":{"id":"31796","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.311523,31.590997],[-82.276793,31.391597],[-82.2193,31.409425],[-82.173464,31.42279],[-82.240772,31.555677],[-82.311523,31.590997]]]},"properties":{"id":"31551","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.226348,31.351479],[-84.429876,31.436661],[-84.556701,31.256699],[-84.468303,31.169073],[-84.226348,31.351479]]]},"properties":{"id":"39870","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.307621,34.100199],[-83.168542,34.06551],[-83.146732,34.181924],[-83.287414,34.254286],[-83.321826,34.26519],[-83.297825,34.165549],[-83.307621,34.100199]]]},"properties":{"id":"30633","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.035759,34.380086],[-85.046191,34.340479],[-84.907956,34.303327],[-84.865067,34.271766],[-84.820754,34.314479],[-84.797797,34.368004],[-84.826627,34.43033],[-84.825337,34.433776],[-84.998628,34.429357],[-85.035759,34.380086]]]},"properties":{"id":"30103","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.653405,34.282859],[-84.797797,34.368004],[-84.820754,34.314479],[-84.725653,34.164781],[-84.657881,34.142471],[-84.627085,34.258322],[-84.653405,34.282859]]]},"properties":{"id":"30184","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.638235,32.840805],[-83.640065,32.840732],[-83.6405,32.839607],[-83.638962,32.839177],[-83.638235,32.840805]]]},"properties":{"id":"31213","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.501789,32.930725],[-83.62819,32.845827],[-83.60051,32.75974],[-83.60236,32.741267],[-83.42279,32.812021],[-83.380391,32.954568],[-83.404174,32.973606],[-83.501789,32.930725]]]},"properties":{"id":"31217","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.109588,32.64088],[-83.265393,32.690168],[-83.340911,32.594154],[-83.262094,32.502054],[-83.226536,32.584201],[-83.216745,32.626796],[-83.185408,32.604362],[-83.122207,32.634794],[-83.109588,32.64088]]]},"properties":{"id":"31017","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.714772,31.247296],[-81.866683,31.219701],[-81.91301,31.096142],[-81.840671,31.097665],[-81.766322,31.169595],[-81.714772,31.247296]]]},"properties":{"id":"31566","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.152965,31.036014],[-82.163142,31.215996],[-82.315275,31.226183],[-82.332191,31.181481],[-82.376452,31.221619],[-82.357887,31.264023],[-82.416985,31.389791],[-82.437374,31.416907],[-82.553919,31.369963],[-82.568925,31.207314],[-82.530745,31.086789],[-82.152965,31.036014]]]},"properties":{"id":"31503","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.800953,31.038832],[-83.82729,30.94903],[-83.814914,30.917079],[-83.740576,30.900312],[-83.737523,30.900014],[-83.659724,30.915511],[-83.586748,30.916713],[-83.600108,31.034677],[-83.800953,31.038832]]]},"properties":{"id":"31778","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.542066,32.239523],[-81.445434,32.275413],[-81.531392,32.43885],[-81.736947,32.39932],[-81.748683,32.271251],[-81.542066,32.239523]]]},"properties":{"id":"30415","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.939984,33.20199],[-83.822261,33.180238],[-83.832621,33.20931],[-83.939984,33.20199]]]},"properties":{"id":"30216","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.380461,31.908423],[-84.600547,31.876597],[-84.546854,31.621199],[-84.539398,31.621193],[-84.450398,31.621868],[-84.297365,31.638226],[-84.286839,31.769266],[-84.340859,31.799418],[-84.357958,31.85135],[-84.285253,31.842945],[-84.326238,31.80506],[-84.286581,31.784998],[-84.263771,31.831903],[-84.338245,31.873591],[-84.380461,31.908423]],[[-84.354316,31.724031],[-84.343267,31.713658],[-84.353053,31.714235],[-84.354316,31.724031]]]},"properties":{"id":"39842","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.815393,30.925432],[-81.936006,31.047993],[-82.080191,31.013907],[-82.131698,31.010714],[-81.890144,30.814862],[-81.831516,30.808232],[-81.793026,30.906795],[-81.815393,30.925432]]]},"properties":{"id":"31537","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.576534,30.782585],[-81.558884,30.782888],[-81.551726,30.81034],[-81.576534,30.782585]]]},"properties":{"id":"31547","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.163231,33.685883],[-85.216506,33.689486],[-85.342726,33.675952],[-85.33823,33.653117],[-85.200584,33.61475],[-85.163231,33.685883]]]},"properties":{"id":"30182","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.2208,34.94533],[-85.229326,34.963876],[-85.264707,34.941522],[-85.2208,34.94533]]]},"properties":{"id":"30742","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.864938,31.998787],[-80.840552,32.001842],[-80.849065,32.028616],[-80.883099,32.010699],[-80.864938,31.998787]]]},"properties":{"id":"31328","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.488553,34.412194],[-85.346724,34.356248],[-85.241341,34.415518],[-85.107748,34.587483],[-85.21614,34.649244],[-85.26239,34.526385],[-85.365204,34.584665],[-85.398508,34.589249],[-85.494844,34.440435],[-85.488553,34.412194]]]},"properties":{"id":"30747","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.107748,34.587483],[-85.241341,34.415518],[-85.109314,34.431796],[-85.064158,34.567783],[-85.069159,34.587184],[-85.107748,34.587483]]]},"properties":{"id":"30105","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.462207,34.286386],[-85.444671,34.1999],[-85.382623,34.197285],[-85.087595,34.401315],[-85.088712,34.42502],[-85.109314,34.431796],[-85.241341,34.415518],[-85.346724,34.356248],[-85.462207,34.286386]],[[-85.272325,34.331444],[-85.220007,34.333411],[-85.215677,34.303058],[-85.260074,34.299171],[-85.272325,34.331444]]]},"properties":{"id":"30165","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.621163,34.131291],[-84.553602,34.075999],[-84.53889,34.075836],[-84.525258,34.134645],[-84.621163,34.131291]]]},"properties":{"id":"30189","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.394352,34.002654],[-84.383329,33.992804],[-84.333156,34.009652],[-84.359657,34.077773],[-84.379295,34.118763],[-84.428905,34.073695],[-84.430353,34.062031],[-84.394352,34.002654]]]},"properties":{"id":"30075","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.425216,33.58817],[-84.489596,33.561499],[-84.472614,33.548153],[-84.427556,33.517155],[-84.422669,33.52543],[-84.425216,33.58817]]]},"properties":{"id":"30296","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.639668,31.08034],[-82.652207,31.083545],[-82.646566,31.064992],[-82.636365,31.072135],[-82.639668,31.08034]]]},"properties":{"id":"31623","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.430353,34.062031],[-84.428905,34.073695],[-84.52815,34.068214],[-84.568022,33.990528],[-84.554478,33.9796],[-84.547421,33.976388],[-84.430353,34.062031]]]},"properties":{"id":"30066","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.642903,33.797228],[-84.578132,33.743507],[-84.533546,33.779122],[-84.597767,33.815277],[-84.642903,33.797228]]]},"properties":{"id":"30168","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.786001,31.621443],[-82.736349,31.570091],[-82.627366,31.665177],[-82.627342,31.672672],[-82.644971,31.672598],[-82.762461,31.671751],[-82.786001,31.621443]]]},"properties":{"id":"31567","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.917877,31.067347],[-83.94867,31.105976],[-84.001957,31.209893],[-84.053541,31.198972],[-84.118349,31.031956],[-83.93081,31.040646],[-83.917877,31.067347]]]},"properties":{"id":"31765","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93151,31.43925],[-84.03962,31.373379],[-84.000127,31.298227],[-84.001629,31.225736],[-83.934594,31.245846],[-83.735775,31.318658],[-83.713975,31.326027],[-83.785595,31.383211],[-83.93151,31.43925]]]},"properties":{"id":"31744","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.735775,31.318658],[-83.773415,31.213894],[-83.594673,31.180428],[-83.583126,31.182095],[-83.587394,31.168946],[-83.55637,31.134178],[-83.521384,31.192876],[-83.513474,31.228489],[-83.566189,31.25],[-83.68313,31.36319],[-83.713975,31.326027],[-83.735775,31.318658]]]},"properties":{"id":"31771","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.018161,33.528292],[-82.131375,33.482532],[-82.168272,33.437275],[-82.117868,33.433335],[-82.047764,33.44826],[-82.018161,33.528292]]]},"properties":{"id":"30909","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286246,32.747626],[-84.213583,32.706249],[-84.202628,32.690018],[-84.127881,32.801049],[-83.978167,32.855537],[-84.043765,32.966004],[-84.099019,32.950575],[-84.208541,32.800944],[-84.304978,32.760619],[-84.286246,32.747626]]]},"properties":{"id":"31016","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.57449,34.815499],[-85.542505,34.662441],[-85.464107,34.689612],[-85.385639,34.874522],[-85.37199,34.935404],[-85.444545,34.901154],[-85.47201,34.881995],[-85.57449,34.815499]]]},"properties":{"id":"30738","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.379512,34.983035],[-85.444545,34.901154],[-85.37199,34.935404],[-85.342458,34.983775],[-85.379512,34.983035]]]},"properties":{"id":"30750","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.789988,30.873627],[-84.859555,30.753028],[-84.749938,30.775234],[-84.656734,31.036588],[-84.730855,31.06919],[-84.789988,30.873627]]]},"properties":{"id":"39825","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347933,33.914999],[-84.358072,33.853953],[-84.352309,33.848619],[-84.343618,33.833194],[-84.311889,33.858044],[-84.309451,33.865356],[-84.314796,33.920211],[-84.327721,33.920361],[-84.347933,33.914999]]]},"properties":{"id":"30319","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.336743,33.805432],[-84.334005,33.803647],[-84.32232,33.797131],[-84.302983,33.82134],[-84.311889,33.858044],[-84.343618,33.833194],[-84.336743,33.805432]]]},"properties":{"id":"30329","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.259716,33.805224],[-84.246273,33.786937],[-84.233832,33.78671],[-84.217588,33.81756],[-84.251743,33.815982],[-84.259716,33.805224]]]},"properties":{"id":"30021","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.418327,33.673108],[-84.349414,33.680346],[-84.346205,33.68044],[-84.359831,33.717052],[-84.398318,33.736934],[-84.418328,33.697244],[-84.418327,33.673108]]]},"properties":{"id":"30315","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.847068,32.347398],[-84.026122,32.373269],[-84.051797,32.240259],[-84.043515,32.181458],[-84.024176,32.143846],[-83.854469,32.291066],[-83.848379,32.29097],[-83.847068,32.347398]]]},"properties":{"id":"31063","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.76685,33.816056],[-84.831768,33.838398],[-84.870744,33.7779],[-84.864284,33.75006],[-84.776828,33.723231],[-84.680749,33.760656],[-84.707486,33.805795],[-84.76685,33.816056]]]},"properties":{"id":"30134","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.265941,30.631834],[-83.012521,30.690376],[-83.038848,30.694758],[-83.017465,30.732354],[-83.001246,30.860236],[-83.019419,30.849453],[-83.156409,30.877972],[-83.220325,30.7339],[-83.265941,30.631834]]]},"properties":{"id":"31636","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.016329,34.272425],[-83.178095,34.301411],[-83.287414,34.254286],[-83.146732,34.181924],[-83.016329,34.272425]]]},"properties":{"id":"30662","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.213913,32.426223],[-82.215493,32.4422],[-82.231703,32.440834],[-82.226718,32.430639],[-82.213913,32.426223]]]},"properties":{"id":"30464","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.563535,33.445934],[-84.568564,33.525386],[-84.594726,33.518707],[-84.622978,33.480311],[-84.62614,33.439037],[-84.563535,33.445934]]]},"properties":{"id":"30290","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.498731,33.804805],[-84.49377,33.764465],[-84.466031,33.754819],[-84.401673,33.763421],[-84.405626,33.776026],[-84.40753,33.778604],[-84.402714,33.780565],[-84.401339,33.778369],[-84.391142,33.781533],[-84.390586,33.781528],[-84.391098,33.791655],[-84.39979,33.793601],[-84.402959,33.818461],[-84.407614,33.820308],[-84.455876,33.82577],[-84.457881,33.824578],[-84.480134,33.817319],[-84.488964,33.81282],[-84.498731,33.804805]]]},"properties":{"id":"30318","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.352309,33.848619],[-84.358072,33.853953],[-84.374196,33.848513],[-84.361348,33.842729],[-84.352309,33.848619]]]},"properties":{"id":"30326","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.755846,33.25344],[-82.848544,33.199368],[-82.661738,33.129438],[-82.582427,33.160117],[-82.584175,33.168674],[-82.657597,33.309492],[-82.76164,33.296801],[-82.755846,33.25344]]]},"properties":{"id":"30820","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.268617,31.219561],[-81.352104,31.219334],[-81.353774,31.145672],[-81.268617,31.219561]]]},"properties":{"id":"31561","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.617223,34.468164],[-84.590508,34.436284],[-84.591662,34.463108],[-84.617223,34.468164]]],[[[-84.654523,34.583187],[-84.779475,34.608332],[-84.823262,34.550535],[-84.802608,34.503755],[-84.621478,34.468626],[-84.654522,34.582897],[-84.654523,34.583187]]]]},"properties":{"id":"30734","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93081,31.040646],[-84.118349,31.031956],[-84.175434,30.995637],[-84.095109,30.908493],[-84.077177,30.882122],[-83.997174,30.928448],[-83.950014,30.952594],[-83.93081,31.040646]]]},"properties":{"id":"31773","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.001456,33.893474],[-83.963221,33.863403],[-83.912865,33.898698],[-84.001456,33.893474]]]},"properties":{"id":"30017","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.882342,34.056479],[-83.874678,34.082014],[-83.862352,34.084997],[-83.858486,34.103816],[-83.875359,34.113847],[-83.946742,34.143218],[-84.010095,34.066818],[-84.023676,34.048112],[-83.939105,34.036741],[-83.882342,34.056479]]]},"properties":{"id":"30519","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933171,34.230543],[-83.865537,34.225384],[-83.885317,34.265053],[-83.933171,34.230543]]]},"properties":{"id":"30566","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.754685,32.611947],[-84.773367,32.549892],[-84.701495,32.53665],[-84.703322,32.583811],[-84.754685,32.611947]]]},"properties":{"id":"31829","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.080699,33.426428],[-85.286323,33.389502],[-85.236518,33.129559],[-85.074222,33.144015],[-84.963981,33.290954],[-85.015358,33.425506],[-85.080699,33.426428]]]},"properties":{"id":"30217","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630043,32.428063],[-83.498039,32.401715],[-83.497923,32.452198],[-83.517935,32.498908],[-83.661979,32.552033],[-83.719966,32.546544],[-83.71411,32.530293],[-83.630043,32.428063]]]},"properties":{"id":"31047","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.906678,32.712341],[-82.669881,32.592331],[-82.614108,32.614922],[-82.554968,32.79264],[-82.648125,32.824551],[-82.776063,32.80869],[-82.906678,32.712341]]]},"properties":{"id":"31096","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.816636,32.953552],[-83.72295,32.977328],[-83.702301,33.052071],[-83.719026,33.146688],[-83.816048,33.131816],[-83.816636,32.953552]]]},"properties":{"id":"31046","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.202888,32.979571],[-84.26955,33.012583],[-84.28615,32.971406],[-84.202911,32.962927],[-84.202888,32.979571]]]},"properties":{"id":"30285","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.027205,32.380423],[-82.939057,32.242837],[-82.944432,32.45031],[-83.027205,32.380423]]]},"properties":{"id":"31075","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.078546,32.468595],[-83.136392,32.418281],[-83.098637,32.348188],[-83.027205,32.380423],[-82.944432,32.45031],[-83.078546,32.468595]]]},"properties":{"id":"31019","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.790401,32.392547],[-82.724422,32.363576],[-82.683497,32.460818],[-82.679377,32.506666],[-82.806038,32.407068],[-82.790401,32.392547]]]},"properties":{"id":"30454","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.931249,32.227594],[-83.012286,32.186759],[-82.986631,32.149321],[-82.820784,32.05812],[-82.813821,32.051928],[-82.722949,31.960672],[-82.668573,32.034736],[-82.830558,32.257734],[-82.931249,32.227594]]]},"properties":{"id":"30411","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.979669,32.083955],[-84.198087,31.941677],[-84.104905,31.871774],[-84.039596,31.878522],[-83.997459,31.977577],[-83.969194,32.03855],[-83.976969,32.062195],[-83.979669,32.083955]]]},"properties":{"id":"31764","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.593083,31.785248],[-81.199436,31.658332],[-81.198203,31.725988],[-81.39363,31.880901],[-81.468313,31.858393],[-81.586075,31.79555],[-81.593083,31.785248]]]},"properties":{"id":"31320","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.586075,31.79555],[-81.658187,31.756927],[-81.575012,31.729223],[-81.593083,31.785248],[-81.586075,31.79555]]]},"properties":{"id":"31301","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.192565,31.578783],[-81.533492,31.631552],[-81.663206,31.538667],[-81.62429,31.452982],[-81.443392,31.330841],[-81.443185,31.357475],[-81.339631,31.446312],[-81.274361,31.500233],[-81.192565,31.578783]],[[-81.350462,31.523158],[-81.347881,31.509297],[-81.361131,31.496746],[-81.367558,31.512576],[-81.350462,31.523158]]]},"properties":{"id":"31331","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.675778,33.149822],[-84.613033,33.075118],[-84.5345,33.026398],[-84.528496,33.043027],[-84.506012,33.171874],[-84.627067,33.206422],[-84.675778,33.149822]]]},"properties":{"id":"30218","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.754291,33.223194],[-84.755293,33.17783],[-84.675778,33.149822],[-84.627067,33.206422],[-84.635725,33.22244],[-84.754291,33.223194]]]},"properties":{"id":"30251","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.493855,32.085608],[-82.478268,32.095229],[-82.463608,32.09041],[-82.538549,32.274486],[-82.574266,32.273526],[-82.493855,32.085608]]]},"properties":{"id":"30410","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.506267,32.066532],[-82.610457,32.054906],[-82.543655,31.958914],[-82.431531,31.966182],[-82.354891,31.939561],[-82.379516,32.035144],[-82.463608,32.09041],[-82.478268,32.095229],[-82.506267,32.066532]]]},"properties":{"id":"30473","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.751228,33.692969],[-83.736055,33.559972],[-83.674401,33.605114],[-83.590189,33.711408],[-83.611999,33.720697],[-83.751228,33.692969]]]},"properties":{"id":"30025","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.357887,31.264023],[-82.315275,31.226183],[-82.163142,31.215996],[-82.104324,31.271402],[-82.2193,31.409425],[-82.276793,31.391597],[-82.416985,31.389791],[-82.357887,31.264023]]]},"properties":{"id":"31516","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.26955,33.012583],[-84.248196,33.065824],[-84.359106,33.036603],[-84.397702,32.99253],[-84.28615,32.971406],[-84.26955,33.012583]]]},"properties":{"id":"30256","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.834829,32.757609],[-81.778151,32.610137],[-81.586748,32.512376],[-81.530385,32.564608],[-81.406487,32.662565],[-81.502769,32.938113],[-81.671746,32.967858],[-81.767553,32.909411],[-81.789375,32.860751],[-81.834829,32.757609]]]},"properties":{"id":"30467","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778151,32.610137],[-81.834829,32.757609],[-81.841005,32.649093],[-81.840854,32.649138],[-81.778151,32.610137]]]},"properties":{"id":"30455","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.873708,32.204504],[-85.048841,32.022215],[-85.06823,31.991857],[-84.960288,31.950267],[-84.798486,31.922799],[-84.675786,31.924009],[-84.655079,31.971593],[-84.648897,31.98433],[-84.754773,32.189997],[-84.795383,32.197595],[-84.873708,32.204504]]]},"properties":{"id":"31815","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.924893,32.222312],[-84.873708,32.204504],[-84.795383,32.197595],[-84.849604,32.230838],[-84.924893,32.222312]]]},"properties":{"id":"31814","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.346749,31.93864],[-84.432254,32.094929],[-84.443807,31.967456],[-84.380461,31.908423],[-84.338245,31.873591],[-84.346749,31.93864]]]},"properties":{"id":"31780","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.025284,32.168901],[-82.019977,32.160247],[-82.01676,32.167153],[-82.025284,32.168901]]]},"properties":{"id":"30438","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.319916,32.451017],[-84.197805,32.400999],[-84.165141,32.446407],[-84.213583,32.706249],[-84.286246,32.747626],[-84.33794,32.720647],[-84.364178,32.679364],[-84.362652,32.598385],[-84.388133,32.583236],[-84.319916,32.451017]]]},"properties":{"id":"31006","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.388133,32.583236],[-84.362652,32.598385],[-84.408566,32.59185],[-84.388133,32.583236]]]},"properties":{"id":"31039","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.371012,32.224375],[-82.367466,32.223418],[-82.366583,32.226453],[-82.369849,32.226402],[-82.371012,32.224375]]]},"properties":{"id":"30475","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.977004,32.918953],[-84.861549,32.945642],[-84.863448,33.082057],[-85.039416,33.123302],[-85.020288,32.916052],[-84.977004,32.918953]]]},"properties":{"id":"30241","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.78688,31.62281],[-83.664954,31.555932],[-83.649534,31.554686],[-83.643153,31.596545],[-83.480406,31.707108],[-83.560239,31.853277],[-83.612256,31.854089],[-83.802223,31.803495],[-83.858254,31.684739],[-83.78688,31.62281]]]},"properties":{"id":"31714","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.679975,33.599792],[-82.851954,33.443543],[-82.817279,33.377184],[-82.65315,33.457875],[-82.645081,33.468438],[-82.630188,33.493474],[-82.649705,33.608768],[-82.679975,33.599792]]]},"properties":{"id":"30821","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.65315,33.457875],[-82.649598,33.447178],[-82.645081,33.468438],[-82.65315,33.457875]]]},"properties":{"id":"30807","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.938345,31.403133],[-81.655818,31.411972],[-81.62429,31.452982],[-81.663206,31.538667],[-81.82766,31.654859],[-81.940267,31.553741],[-81.938345,31.403133]]]},"properties":{"id":"31546","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.89234,33.690342],[-82.679975,33.599792],[-82.649705,33.608768],[-82.543192,33.638828],[-82.594553,33.828429],[-82.853736,33.835875],[-82.89234,33.690342]]]},"properties":{"id":"30673","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.627342,31.672672],[-82.52142,31.710796],[-82.431531,31.966182],[-82.543655,31.958914],[-82.791845,31.847107],[-82.836366,31.816154],[-82.836525,31.779692],[-82.644971,31.672598],[-82.627342,31.672672]]]},"properties":{"id":"31539","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.046885,31.18368],[-83.165492,31.109933],[-83.180622,31.025488],[-83.179904,30.992073],[-83.032712,31.011013],[-82.969881,31.081682],[-82.966747,31.183987],[-83.046885,31.18368]]]},"properties":{"id":"31635","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.539398,31.621193],[-84.598313,31.463541],[-84.610545,31.322291],[-84.556701,31.256699],[-84.429876,31.436661],[-84.450398,31.621868],[-84.539398,31.621193]]]},"properties":{"id":"39862","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.556701,31.256699],[-84.784769,31.25313],[-84.875391,31.176774],[-84.820646,31.070609],[-84.730855,31.06919],[-84.656734,31.036588],[-84.5133,31.060674],[-84.468303,31.169073],[-84.556701,31.256699]]]},"properties":{"id":"39837","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.521412,33.128976],[-83.404174,32.973606],[-83.380391,32.954568],[-83.35362,32.969527],[-83.42909,33.185352],[-83.525624,33.174389],[-83.521412,33.128976]]]},"properties":{"id":"31033","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.561669,34.489694],[-83.486041,34.420468],[-83.413188,34.431118],[-83.45977,34.481142],[-83.499644,34.487142],[-83.561669,34.489694]]]},"properties":{"id":"30511","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.571497,34.032647],[-83.673454,34.053036],[-83.797699,34.055602],[-83.824235,33.964167],[-83.661892,33.916883],[-83.635676,33.904154],[-83.571497,34.032647]]]},"properties":{"id":"30680","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.661892,33.916883],[-83.815501,33.908919],[-83.824104,33.881848],[-83.810443,33.831711],[-83.591931,33.881372],[-83.635676,33.904154],[-83.661892,33.916883]]]},"properties":{"id":"30656","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.511924,31.327395],[-83.566189,31.25],[-83.513474,31.228489],[-83.357865,31.250348],[-83.31173,31.316257],[-83.311324,31.318793],[-83.356319,31.333065],[-83.511924,31.327395]]]},"properties":{"id":"31637","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.536402,31.543437],[-83.521344,31.351129],[-83.511924,31.327395],[-83.356319,31.333065],[-83.34944,31.477455],[-83.420699,31.553567],[-83.536402,31.543437]]]},"properties":{"id":"31794","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.754746,32.831525],[-83.889078,32.835569],[-83.920085,32.810014],[-83.887119,32.703601],[-83.871539,32.699845],[-83.776874,32.718809],[-83.755128,32.796979],[-83.754746,32.831525]]]},"properties":{"id":"31052","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42279,32.812021],[-83.60236,32.741267],[-83.602563,32.719236],[-83.597656,32.664338],[-83.548147,32.571922],[-83.536539,32.542661],[-83.42556,32.80185],[-83.42279,32.812021]]]},"properties":{"id":"31020","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.462423,30.946898],[-83.517593,30.944734],[-83.578612,30.917515],[-83.462423,30.946898]]]},"properties":{"id":"31638","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.258122,32.65994],[-82.325902,32.425814],[-82.335039,32.408062],[-82.267882,32.388056],[-82.226718,32.430639],[-82.231703,32.440834],[-82.215493,32.4422],[-82.213913,32.426223],[-81.971917,32.512282],[-82.015889,32.577561],[-82.178024,32.691052],[-82.258122,32.65994]]]},"properties":{"id":"30471","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.781305,32.417578],[-81.77995,32.419219],[-81.781632,32.420386],[-81.78313,32.41872],[-81.781305,32.417578]]]},"properties":{"id":"30460","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.016041,33.190081],[-82.273618,33.070805],[-82.194773,32.968693],[-81.963108,32.923126],[-81.934344,32.930484],[-81.792869,33.05113],[-81.721124,33.126112],[-81.852076,33.247288],[-81.925427,33.25589],[-82.016041,33.190081]]]},"properties":{"id":"30830","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.417224,32.770403],[-82.258122,32.65994],[-82.178024,32.691052],[-82.102344,32.711357],[-82.194773,32.968693],[-82.320435,32.840033],[-82.417224,32.770403]]]},"properties":{"id":"30441","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.102344,32.711357],[-81.841005,32.649093],[-81.834829,32.757609],[-81.789375,32.860751],[-81.963108,32.923126],[-82.194773,32.968693],[-82.102344,32.711357]]]},"properties":{"id":"30442","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.216329,33.335821],[-84.102585,33.272301],[-84.043175,33.287281],[-84.054123,33.337558],[-83.993976,33.368496],[-84.000987,33.394705],[-84.138486,33.377767],[-84.213296,33.391571],[-84.216329,33.335821]]]},"properties":{"id":"30248","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.286323,33.389502],[-85.080699,33.426428],[-85.096196,33.485574],[-85.18319,33.465234],[-85.290139,33.409422],[-85.286323,33.389502]]]},"properties":{"id":"30170","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.107088,32.053866],[-81.099909,32.08766],[-81.116855,32.104568],[-81.161015,32.070977],[-81.107088,32.053866]]]},"properties":{"id":"31415","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.031105,32.019409],[-81.013134,32.012013],[-81.007542,31.973801],[-81.006698,32.070562],[-81.031105,32.019409]]]},"properties":{"id":"31410","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.384964,34.037777],[-83.412126,33.971935],[-83.388302,33.954923],[-83.376761,33.957364],[-83.372214,33.956538],[-83.329123,33.975183],[-83.274778,34.006192],[-83.343306,34.053552],[-83.384964,34.037777]]]},"properties":{"id":"30601","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.360897,33.574854],[-84.343546,33.613838],[-84.341619,33.623748],[-84.353792,33.647812],[-84.401123,33.633127],[-84.404052,33.606589],[-84.360897,33.574854]]]},"properties":{"id":"30297","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.280042,33.560537],[-84.295861,33.560994],[-84.298783,33.558429],[-84.280042,33.560537]]],[[[-84.37664,33.536999],[-84.334027,33.464044],[-84.266526,33.486588],[-84.29895,33.558028],[-84.360897,33.574854],[-84.404052,33.606589],[-84.404391,33.605481],[-84.37664,33.536999]]]]},"properties":{"id":"30236","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.293061,33.588121],[-84.295861,33.560994],[-84.280042,33.560537],[-84.243655,33.612235],[-84.293061,33.588121]]]},"properties":{"id":"30273","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.353792,33.647812],[-84.341619,33.623748],[-84.314956,33.680289],[-84.346205,33.68044],[-84.349414,33.680346],[-84.353792,33.647812]]]},"properties":{"id":"30288","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.509075,33.328042],[-84.405953,33.301192],[-84.388118,33.352465],[-84.384772,33.458713],[-84.547599,33.422407],[-84.525209,33.342247],[-84.509075,33.328042]]]},"properties":{"id":"30215","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.092057,33.583837],[-84.115791,33.614666],[-84.170181,33.645836],[-84.243655,33.612235],[-84.280042,33.560537],[-84.298783,33.558429],[-84.29895,33.558028],[-84.266526,33.486588],[-84.230272,33.469116],[-84.140228,33.525397],[-84.092057,33.583837]]]},"properties":{"id":"30281","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.641225,33.860755],[-84.635769,33.854545],[-84.632519,33.858847],[-84.641225,33.860755]]],[[[-84.547705,33.945428],[-84.631031,33.86763],[-84.618238,33.859348],[-84.594503,33.861977],[-84.547705,33.945428]]]]},"properties":{"id":"30008","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.477473,33.900934],[-84.442823,33.9017],[-84.405177,33.943734],[-84.506449,33.950766],[-84.520518,33.95089],[-84.487334,33.902781],[-84.477473,33.900934]]]},"properties":{"id":"30067","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.68693,33.949127],[-84.577475,33.984566],[-84.582428,33.985857],[-84.581409,33.993888],[-84.675536,34.034403],[-84.713678,33.975332],[-84.68693,33.949127]]]},"properties":{"id":"30152","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.48757,33.823449],[-84.488964,33.81282],[-84.480134,33.817319],[-84.48757,33.823449]]],[[[-84.578481,33.848823],[-84.489661,33.825236],[-84.541659,33.897494],[-84.577129,33.864755],[-84.578481,33.848823]]]]},"properties":{"id":"30082","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.652809,31.389617],[-83.69923,31.39616],[-83.68313,31.36319],[-83.566189,31.25],[-83.511924,31.327395],[-83.521344,31.351129],[-83.652809,31.389617]]]},"properties":{"id":"31775","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075921,33.578288],[-82.157897,33.504648],[-82.165933,33.493629],[-82.158455,33.492456],[-82.162905,33.485876],[-82.131375,33.482532],[-82.018161,33.528292],[-82.011132,33.531432],[-82.075921,33.578288]]]},"properties":{"id":"30907","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.988756,32.723949],[-84.127881,32.801049],[-84.202628,32.690018],[-84.067632,32.612113],[-83.945327,32.681639],[-83.988756,32.723949]]]},"properties":{"id":"31078","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.988756,32.723949],[-83.945327,32.681639],[-83.887119,32.703601],[-83.920085,32.810014],[-83.988756,32.723949]]]},"properties":{"id":"31050","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.612256,31.854089],[-83.843778,31.826975],[-83.802223,31.803495],[-83.612256,31.854089]]]},"properties":{"id":"31712","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.608079,32.159456],[-83.693332,32.164098],[-83.902053,32.195585],[-83.976969,32.062195],[-83.969194,32.03855],[-83.961278,32.030595],[-83.628533,32.028041],[-83.608918,32.080415],[-83.607997,32.148118],[-83.608079,32.159456]],[[-83.875099,32.147239],[-83.907422,32.154487],[-83.886774,32.166065],[-83.875099,32.147239]]]},"properties":{"id":"31092","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.278279,33.904832],[-84.245618,33.870725],[-84.224865,33.8929],[-84.237756,33.904859],[-84.240569,33.928753],[-84.278279,33.904832]]]},"properties":{"id":"30340","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.076411,33.805383],[-84.167062,33.865377],[-84.18887,33.83314],[-84.167904,33.798407],[-84.163176,33.772211],[-84.058204,33.772767],[-84.076411,33.805383]]]},"properties":{"id":"30087","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.270427,33.756696],[-84.245595,33.783142],[-84.246498,33.787046],[-84.264755,33.783062],[-84.270427,33.756696]]]},"properties":{"id":"30002","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.023713,33.752808],[-84.088596,33.700435],[-84.074409,33.692743],[-84.010627,33.655084],[-83.930908,33.711727],[-83.982033,33.786054],[-84.023713,33.752808]]]},"properties":{"id":"30012","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.076411,33.805383],[-84.058204,33.772767],[-84.023713,33.752808],[-83.982033,33.786054],[-83.990957,33.828183],[-84.059346,33.849378],[-84.076411,33.805383]]]},"properties":{"id":"30039","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.346528,32.272489],[-83.337313,32.105885],[-83.294495,32.038777],[-83.140815,32.013392],[-83.115784,32.070028],[-83.028243,32.216737],[-83.098637,32.348188],[-83.203822,32.32696],[-83.346528,32.272489]]]},"properties":{"id":"31023","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.055821,31.123423],[-84.919715,31.172197],[-84.875391,31.176774],[-84.784769,31.25313],[-84.775313,31.344738],[-84.797093,31.473586],[-84.819807,31.501192],[-84.975312,31.494626],[-85.045495,31.517129],[-85.055821,31.123423]]]},"properties":{"id":"39823","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.146732,34.181924],[-83.03104,34.113309],[-82.999884,34.126047],[-82.989586,34.257448],[-83.001505,34.27695],[-83.016329,34.272425],[-83.146732,34.181924]]]},"properties":{"id":"30624","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.453073,34.859469],[-84.531473,34.988126],[-84.621483,34.988329],[-84.622158,34.925715],[-84.622752,34.857131],[-84.618637,34.855398],[-84.470689,34.855034],[-84.453073,34.859469]]]},"properties":{"id":"30541","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.195137,34.798193],[-84.158035,34.648243],[-83.994824,34.679638],[-83.939007,34.740859],[-84.14261,34.821977],[-84.195137,34.798193]]]},"properties":{"id":"30572","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.251814,34.139379],[-85.382623,34.197285],[-85.444671,34.1999],[-85.421852,34.080822],[-85.257705,34.096675],[-85.251814,34.139379]]]},"properties":{"id":"30124","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.099008,34.388997],[-83.187602,34.328656],[-83.178095,34.301411],[-83.016329,34.272425],[-83.001505,34.27695],[-82.967634,34.354218],[-83.099008,34.388997]]]},"properties":{"id":"30520","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.407614,33.820308],[-84.402959,33.818461],[-84.382503,33.813412],[-84.361348,33.842729],[-84.374196,33.848513],[-84.404728,33.863284],[-84.407614,33.820308]]]},"properties":{"id":"30305","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.584625,33.60787],[-84.531344,33.534487],[-84.523742,33.536481],[-84.502023,33.56593],[-84.584625,33.60787]]]},"properties":{"id":"30291","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.714772,31.247296],[-81.766322,31.169595],[-81.463516,31.035396],[-81.485783,31.115933],[-81.526849,31.164016],[-81.52265,31.221082],[-81.658637,31.408163],[-81.714772,31.247296]]]},"properties":{"id":"31523","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.461914,31.177202],[-81.52265,31.221082],[-81.526849,31.164016],[-81.461914,31.177202]]]},"properties":{"id":"31520","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.268617,31.219561],[-81.383903,31.316759],[-81.400777,31.13014],[-81.384876,31.127133],[-81.353774,31.145672],[-81.352104,31.219334],[-81.268617,31.219561]]]},"properties":{"id":"31522","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.480349,31.235665],[-81.459957,31.233885],[-81.467001,31.252137],[-81.483834,31.24719],[-81.480349,31.235665]]]},"properties":{"id":"31524","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.016182,34.615882],[-85.050446,34.622482],[-85.069159,34.587184],[-85.064158,34.567783],[-84.989919,34.564897],[-85.016182,34.615882]]]},"properties":{"id":"30746","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.095109,30.908493],[-84.260907,30.914001],[-84.380072,30.736875],[-84.380487,30.705728],[-84.380705,30.689993],[-84.254119,30.684261],[-84.077177,30.882122],[-84.095109,30.908493]]]},"properties":{"id":"39828","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.059999,33.886664],[-84.121941,33.921824],[-84.173287,33.887736],[-84.167062,33.865377],[-84.076411,33.805383],[-84.059346,33.849378],[-84.059999,33.886664]]]},"properties":{"id":"30047","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.616427,34.547307],[-83.526529,34.54817],[-83.513537,34.589468],[-83.641998,34.627566],[-83.616427,34.547307]]]},"properties":{"id":"30535","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.701343,34.503909],[-83.832754,34.505341],[-83.847449,34.451901],[-83.764757,34.449229],[-83.701343,34.503909]]]},"properties":{"id":"30527","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.998616,32.696171],[-84.912792,32.607629],[-84.843928,32.613636],[-84.80206,32.666182],[-84.757221,32.714827],[-84.785094,32.716616],[-84.998616,32.696171]]]},"properties":{"id":"31804","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.001505,34.27695],[-82.989586,34.257448],[-82.919779,34.244855],[-82.77341,34.288864],[-82.974917,34.47935],[-83.02502,34.41079],[-82.967634,34.354218],[-83.001505,34.27695]]]},"properties":{"id":"30643","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.589511,32.576784],[-83.536539,32.542661],[-83.548147,32.571922],[-83.589511,32.576784]]],[[[-83.589511,32.576784],[-83.599388,32.61976],[-83.676334,32.618313],[-83.689825,32.617786],[-83.703289,32.614904],[-83.71918,32.595823],[-83.719966,32.546544],[-83.661979,32.552033],[-83.589511,32.576784]]]]},"properties":{"id":"31088","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.073213,33.184877],[-84.233951,33.078463],[-84.248196,33.065824],[-84.26955,33.012583],[-84.202888,32.979571],[-84.099019,32.950575],[-84.043765,32.966004],[-84.017364,33.164486],[-84.073213,33.184877]]]},"properties":{"id":"30204","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.263771,31.831903],[-84.104905,31.871774],[-84.198087,31.941677],[-84.255743,31.949492],[-84.346749,31.93864],[-84.338245,31.873591],[-84.263771,31.831903]]]},"properties":{"id":"31787","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.997459,31.977577],[-84.039596,31.878522],[-83.958008,31.835825],[-83.939437,31.847929],[-83.922494,31.909653],[-83.997459,31.977577]]]},"properties":{"id":"31743","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.946544,32.50068],[-84.926676,32.437019],[-84.91564,32.422987],[-84.892931,32.422748],[-84.859423,32.525374],[-84.871621,32.540225],[-84.946544,32.50068]]]},"properties":{"id":"31907","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.090977,33.973933],[-83.166792,33.842899],[-83.057642,33.796666],[-82.946795,33.842674],[-83.090977,33.973933]]]},"properties":{"id":"30648","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.043503,33.711948],[-83.057642,33.796666],[-83.166792,33.842899],[-83.216456,33.837665],[-83.28248,33.78816],[-83.28034,33.761774],[-83.043503,33.711948]]]},"properties":{"id":"30667","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.39237,33.055939],[-84.528496,33.043027],[-84.5345,33.026398],[-84.50801,32.898816],[-84.506888,32.881788],[-84.47709,32.877519],[-84.397702,32.99253],[-84.359106,33.036603],[-84.39237,33.055939]]]},"properties":{"id":"30258","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.393133,33.099434],[-84.333914,33.144824],[-84.358582,33.236227],[-84.439347,33.228029],[-84.496783,33.183866],[-84.506012,33.171874],[-84.393133,33.099434]]]},"properties":{"id":"30292","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.421307,34.798419],[-83.422682,34.79859],[-83.426322,34.796997],[-83.426708,34.793706],[-83.421355,34.794918],[-83.421307,34.798419]]]},"properties":{"id":"30581","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.117868,33.433335],[-82.168272,33.437275],[-82.210402,33.414026],[-82.144635,33.390788],[-82.098289,33.421192],[-82.117868,33.433335]]]},"properties":{"id":"30905","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991827,33.470261],[-81.984211,33.468507],[-81.98555,33.473667],[-81.991827,33.470261]]]},"properties":{"id":"30912","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.195805,32.118999],[-84.255743,31.949492],[-84.198087,31.941677],[-83.979669,32.083955],[-84.024176,32.143846],[-84.043515,32.181458],[-84.195805,32.118999]]]},"properties":{"id":"31709","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.997174,30.928448],[-83.905845,30.743896],[-83.814914,30.917079],[-83.82729,30.94903],[-83.950014,30.952594],[-83.997174,30.928448]]]},"properties":{"id":"31757","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.664954,31.555932],[-83.69923,31.39616],[-83.652809,31.389617],[-83.649534,31.554686],[-83.664954,31.555932]]]},"properties":{"id":"31795","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.091084,34.796623],[-85.134279,34.809302],[-85.144789,34.767639],[-85.050818,34.712173],[-85.01143,34.802715],[-85.091084,34.796623]]]},"properties":{"id":"30740","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.357887,31.264023],[-82.376452,31.221619],[-82.332191,31.181481],[-82.315275,31.226183],[-82.357887,31.264023]]]},"properties":{"id":"31501","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.046885,31.18368],[-82.966747,31.183987],[-82.774731,31.183161],[-82.742496,31.183021],[-82.817174,31.364484],[-82.849617,31.403189],[-83.047957,31.290009],[-83.046885,31.18368]]]},"properties":{"id":"31642","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.678682,31.373391],[-82.817174,31.364484],[-82.742496,31.183021],[-82.671669,31.183739],[-82.673317,31.187234],[-82.628975,31.36393],[-82.678682,31.373391]]]},"properties":{"id":"31624","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.276793,31.391597],[-82.311523,31.590997],[-82.52142,31.710796],[-82.627342,31.672672],[-82.627366,31.665177],[-82.437374,31.416907],[-82.416985,31.389791],[-82.276793,31.391597]]]},"properties":{"id":"31510","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.775313,31.344738],[-84.610545,31.322291],[-84.598313,31.463541],[-84.664053,31.531526],[-84.797093,31.473586],[-84.775313,31.344738]]]},"properties":{"id":"39813","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996359,33.457634],[-83.164207,33.35503],[-83.274011,33.199923],[-83.052197,33.080682],[-82.948911,33.103611],[-82.848544,33.199368],[-82.755846,33.25344],[-82.783462,33.301218],[-82.817279,33.377184],[-82.851954,33.443543],[-82.996359,33.457634]]]},"properties":{"id":"31087","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.486831,34.299589],[-83.579158,34.194336],[-83.450071,34.138203],[-83.427706,34.131385],[-83.41166,34.306473],[-83.486831,34.299589]]]},"properties":{"id":"30529","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.739497,34.365264],[-83.687015,34.33896],[-83.591718,34.349826],[-83.519992,34.36308],[-83.52619,34.39334],[-83.669566,34.441448],[-83.666413,34.503598],[-83.701343,34.503909],[-83.764757,34.449229],[-83.741659,34.378662],[-83.739497,34.365264]]]},"properties":{"id":"30554","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.748687,34.150739],[-84.750023,34.110343],[-84.73971,34.090547],[-84.712899,34.088064],[-84.725653,34.164781],[-84.820754,34.314479],[-84.865067,34.271766],[-84.748687,34.150739]]]},"properties":{"id":"30121","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.880339,34.082573],[-84.771861,34.082431],[-84.73971,34.090547],[-84.750023,34.110343],[-84.768642,34.118694],[-84.748687,34.150739],[-84.865067,34.271766],[-84.907956,34.303327],[-84.955729,34.137678],[-84.880339,34.082573]]]},"properties":{"id":"30120","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.797797,34.368004],[-84.653405,34.282859],[-84.65981,34.387671],[-84.826627,34.43033],[-84.797797,34.368004]]]},"properties":{"id":"30171","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.58789,32.085977],[-83.534478,31.84766],[-83.404588,31.797673],[-83.356486,31.846509],[-83.453572,32.097608],[-83.58789,32.085977]]]},"properties":{"id":"31079","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.99567,31.780118],[-83.133287,31.608903],[-83.120554,31.473559],[-83.11166,31.478229],[-82.953303,31.656883],[-82.99567,31.780118]]]},"properties":{"id":"31798","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.34944,31.477455],[-83.356319,31.333065],[-83.311324,31.318793],[-83.284229,31.475624],[-83.34944,31.477455]]]},"properties":{"id":"31749","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.145587,31.472276],[-83.284229,31.475624],[-83.311324,31.318793],[-83.31173,31.316257],[-83.058325,31.308259],[-83.145587,31.472276]]]},"properties":{"id":"31622","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.688346,32.686732],[-83.607939,32.664496],[-83.597656,32.664338],[-83.602563,32.719236],[-83.755128,32.796979],[-83.776874,32.718809],[-83.688346,32.686732]]]},"properties":{"id":"31216","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.978167,32.855537],[-84.127881,32.801049],[-83.988756,32.723949],[-83.920085,32.810014],[-83.889078,32.835569],[-83.891685,32.864644],[-83.978167,32.855537]]]},"properties":{"id":"31066","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.578612,30.917515],[-83.517593,30.944734],[-83.462423,30.946898],[-83.44031,30.904794],[-83.475617,31.031964],[-83.574857,31.078135],[-83.600108,31.034677],[-83.586748,30.916713],[-83.578612,30.917515]]]},"properties":{"id":"31625","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93502,30.66838],[-83.743729,30.658396],[-83.742762,30.712831],[-83.699446,30.859711],[-83.740576,30.900312],[-83.814914,30.917079],[-83.905845,30.743896],[-83.93502,30.66838]]]},"properties":{"id":"31626","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.841005,32.649093],[-82.102344,32.711357],[-82.178024,32.691052],[-82.015889,32.577561],[-81.854995,32.626852],[-81.840854,32.649138],[-81.841005,32.649093]]]},"properties":{"id":"30425","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.736947,32.39932],[-81.851654,32.521819],[-81.97453,32.487746],[-81.918671,32.41541],[-81.820573,32.238447],[-81.805672,32.216804],[-81.748683,32.271251],[-81.736947,32.39932]],[[-81.781305,32.417578],[-81.78313,32.41872],[-81.781632,32.420386],[-81.77995,32.419219],[-81.781305,32.417578]]]},"properties":{"id":"30458","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.792869,33.05113],[-81.934344,32.930484],[-81.767553,32.909411],[-81.671746,32.967858],[-81.792869,33.05113]]]},"properties":{"id":"30456","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.863058,33.368278],[-83.891602,33.398035],[-83.927008,33.452539],[-84.000987,33.394705],[-83.993976,33.368496],[-84.043175,33.287281],[-84.102585,33.272301],[-84.114429,33.260736],[-84.114737,33.183479],[-84.073213,33.184877],[-84.017364,33.164486],[-83.939984,33.20199],[-83.832621,33.20931],[-83.863058,33.368278]]]},"properties":{"id":"30233","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.778311,31.619641],[-84.862896,31.586655],[-84.819807,31.501192],[-84.797093,31.473586],[-84.664053,31.531526],[-84.647228,31.643617],[-84.680937,31.620027],[-84.778311,31.619641]]]},"properties":{"id":"39846","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.675828,31.921605],[-84.680937,31.620027],[-84.647228,31.643617],[-84.546854,31.621199],[-84.600547,31.876597],[-84.599776,31.920171],[-84.65582,31.920308],[-84.675828,31.921605]]]},"properties":{"id":"39886","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.232788,32.318838],[-82.068573,32.287222],[-82.025339,32.278883],[-81.969069,32.268783],[-81.918671,32.41541],[-81.97453,32.487746],[-81.971917,32.512282],[-82.213913,32.426223],[-82.226718,32.430639],[-82.267882,32.388056],[-82.232788,32.318838]],[[-81.95948,32.390911],[-81.948037,32.391557],[-81.948025,32.389176],[-81.95948,32.390911]]]},"properties":{"id":"30439","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.200584,33.61475],[-85.18319,33.465234],[-85.096196,33.485574],[-85.041486,33.663114],[-85.064181,33.687395],[-85.163231,33.685883],[-85.200584,33.61475]],[[-85.112861,33.570004],[-85.112257,33.57533],[-85.095341,33.575372],[-85.103262,33.568717],[-85.112861,33.570004]]]},"properties":{"id":"30117","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.977932,33.680896],[-84.904893,33.605225],[-84.864284,33.75006],[-84.870744,33.7779],[-84.896879,33.810313],[-84.977932,33.680896]]]},"properties":{"id":"30180","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.464107,34.689612],[-85.428429,34.607208],[-85.279313,34.790888],[-85.235341,34.865805],[-85.264621,34.927422],[-85.338215,34.892746],[-85.385639,34.874522],[-85.464107,34.689612]]]},"properties":{"id":"30707","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.006021,30.566282],[-82.079268,30.414712],[-82.210733,30.42564],[-82.171623,30.359918],[-82.037218,30.371848],[-82.006021,30.566282]]]},"properties":{"id":"31562","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.284699,32.173049],[-81.305068,32.092987],[-81.251664,32.063156],[-81.197802,32.074567],[-81.241721,32.145371],[-81.277532,32.178306],[-81.284699,32.173049]]]},"properties":{"id":"31322","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.462207,34.286386],[-85.346724,34.356248],[-85.488553,34.412194],[-85.462207,34.286386]]]},"properties":{"id":"30730","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.516802,34.146138],[-84.525258,34.134645],[-84.53889,34.075836],[-84.52815,34.068214],[-84.428905,34.073695],[-84.379295,34.118763],[-84.359066,34.158195],[-84.516802,34.146138]]]},"properties":{"id":"30188","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.372214,33.956538],[-83.368828,33.944206],[-83.378804,33.942583],[-83.376761,33.957364],[-83.388302,33.954923],[-83.376712,33.890433],[-83.299691,33.831471],[-83.275933,33.847977],[-83.329123,33.975183],[-83.372214,33.956538]],[[-83.380927,33.950791],[-83.380266,33.948751],[-83.383172,33.948201],[-83.383744,33.9501],[-83.380927,33.950791]]]},"properties":{"id":"30605","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.960288,31.950267],[-85.06823,31.991857],[-85.14113,31.780463],[-85.054651,31.747742],[-84.95864,31.777854],[-84.957824,31.777838],[-84.960288,31.950267]]]},"properties":{"id":"39854","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.958864,31.734606],[-84.921994,31.593347],[-84.862896,31.586655],[-84.778311,31.619641],[-84.958864,31.734606]]]},"properties":{"id":"39836","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.349414,33.680346],[-84.418327,33.673108],[-84.437512,33.654027],[-84.401123,33.633127],[-84.353792,33.647812],[-84.349414,33.680346]]]},"properties":{"id":"30354","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.943351,30.960309],[-82.793296,30.983016],[-82.632787,31.102663],[-82.636762,31.1108],[-82.774731,31.183161],[-82.966747,31.183987],[-82.969881,31.081682],[-82.9705,30.94981],[-82.943351,30.960309]],[[-82.639668,31.08034],[-82.636365,31.072135],[-82.646566,31.064992],[-82.652207,31.083545],[-82.639668,31.08034]]]},"properties":{"id":"31634","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.442823,33.9017],[-84.477473,33.900934],[-84.457881,33.824578],[-84.455876,33.82577],[-84.414167,33.915865],[-84.442823,33.9017]]]},"properties":{"id":"30339","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.963994,31.555775],[-82.789529,31.504994],[-82.736349,31.570091],[-82.786001,31.621443],[-82.955112,31.585338],[-82.963994,31.555775]]]},"properties":{"id":"31533","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.934594,31.245846],[-84.001629,31.225736],[-84.001957,31.209893],[-83.94867,31.105976],[-83.934594,31.245846]]]},"properties":{"id":"31756","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93081,31.040646],[-83.950014,30.952594],[-83.82729,30.94903],[-83.800953,31.038832],[-83.820279,31.072105],[-83.917877,31.067347],[-83.93081,31.040646]]]},"properties":{"id":"31738","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.425328,33.650629],[-82.398091,33.498081],[-82.275575,33.492097],[-82.253727,33.523331],[-82.164094,33.611145],[-82.218649,33.686299],[-82.425328,33.650629]]]},"properties":{"id":"30802","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.521384,31.192876],[-83.335456,31.221382],[-83.357865,31.250348],[-83.513474,31.228489],[-83.521384,31.192876]]]},"properties":{"id":"31647","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.705643,33.295592],[-84.635369,33.321666],[-84.643201,33.325884],[-84.645294,33.33101],[-84.587077,33.362057],[-84.62614,33.439037],[-84.622978,33.480311],[-84.638921,33.500239],[-84.710386,33.337024],[-84.705643,33.295592]]]},"properties":{"id":"30277","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.794732,33.29354],[-84.754291,33.223194],[-84.635725,33.22244],[-84.709499,33.285539],[-84.794732,33.29354]]]},"properties":{"id":"30259","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.260918,34.721645],[-84.392305,34.778403],[-84.512263,34.625899],[-84.380348,34.548898],[-84.303842,34.57556],[-84.196754,34.617924],[-84.260918,34.721645]]]},"properties":{"id":"30536","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.028629,31.311573],[-84.226348,31.351479],[-84.468303,31.169073],[-84.5133,31.060674],[-84.460196,31.051169],[-84.376612,31.078883],[-84.346135,31.078908],[-84.07407,31.198747],[-84.028629,31.311573]]]},"properties":{"id":"31730","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.302983,33.82134],[-84.32232,33.797131],[-84.315911,33.795015],[-84.307792,33.790751],[-84.268494,33.785431],[-84.259716,33.805224],[-84.251743,33.815982],[-84.261953,33.84423],[-84.302983,33.82134]]]},"properties":{"id":"30033","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.197059,33.704725],[-84.241017,33.714815],[-84.305634,33.715279],[-84.30795,33.679612],[-84.208877,33.660808],[-84.197059,33.704725]]]},"properties":{"id":"30034","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.197059,33.704725],[-84.170226,33.703504],[-84.171483,33.723662],[-84.197838,33.736845],[-84.232497,33.763756],[-84.241017,33.714815],[-84.197059,33.704725]]]},"properties":{"id":"30035","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.312557,33.765092],[-84.281287,33.747975],[-84.270427,33.756696],[-84.264755,33.783062],[-84.268494,33.785431],[-84.307792,33.790751],[-84.312557,33.765092]]]},"properties":{"id":"30030","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.208523,33.786854],[-84.203726,33.787017],[-84.203418,33.792613],[-84.208466,33.795222],[-84.208523,33.786854]]]},"properties":{"id":"30072","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.693317,32.290124],[-83.848379,32.29097],[-83.854469,32.291066],[-83.855615,32.241489],[-83.693332,32.164098],[-83.608079,32.159456],[-83.693317,32.290124]]]},"properties":{"id":"31091","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.902053,32.195585],[-83.693332,32.164098],[-83.855615,32.241489],[-83.902053,32.195585]]]},"properties":{"id":"31070","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.316086,32.316884],[-81.24925,32.198648],[-81.15925,32.229736],[-81.181424,32.380433],[-81.316086,32.316884]]]},"properties":{"id":"31326","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.498674,32.500014],[-81.316086,32.316884],[-81.181424,32.380433],[-81.205117,32.427234],[-81.392192,32.527667],[-81.498674,32.500014]]]},"properties":{"id":"31329","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.359363,34.988269],[-84.488389,34.988167],[-84.431882,34.948308],[-84.388,34.96702],[-84.358891,34.973939],[-84.359363,34.988269]]]},"properties":{"id":"30555","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.509075,33.328042],[-84.496783,33.183866],[-84.439347,33.228029],[-84.405953,33.301192],[-84.509075,33.328042]]]},"properties":{"id":"30205","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.0288,34.306905],[-84.056983,34.328655],[-84.087051,34.263542],[-84.210548,34.129592],[-84.176219,34.082313],[-84.093343,34.126273],[-84.0213,34.194075],[-84.0288,34.306905]]]},"properties":{"id":"30041","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347933,33.914999],[-84.392521,33.913169],[-84.404728,33.863284],[-84.374196,33.848513],[-84.358072,33.853953],[-84.347933,33.914999]]]},"properties":{"id":"30342","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.404728,33.863284],[-84.392521,33.913169],[-84.414167,33.915865],[-84.455876,33.82577],[-84.407614,33.820308],[-84.404728,33.863284]]]},"properties":{"id":"30327","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.465728,33.754598],[-84.44293,33.705636],[-84.418328,33.697244],[-84.398318,33.736934],[-84.407205,33.741168],[-84.404461,33.744347],[-84.407545,33.743481],[-84.465728,33.754598]]]},"properties":{"id":"30310","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.261875,34.088306],[-84.327509,34.075488],[-84.315466,34.033714],[-84.272117,34.06866],[-84.261875,34.088306]]]},"properties":{"id":"30009","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.76164,33.296801],[-82.657597,33.309492],[-82.534827,33.273234],[-82.513544,33.343708],[-82.630188,33.493474],[-82.645081,33.468438],[-82.649598,33.447178],[-82.65315,33.457875],[-82.817279,33.377184],[-82.783462,33.301218],[-82.76164,33.296801]]]},"properties":{"id":"30828","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.485783,31.115933],[-81.463516,31.035396],[-81.434926,31.000244],[-81.412784,30.990835],[-81.384876,31.127133],[-81.400777,31.13014],[-81.485783,31.115933]]]},"properties":{"id":"31527","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.348511,33.598443],[-83.279931,33.483438],[-83.178101,33.380268],[-83.072624,33.530861],[-83.074395,33.544496],[-83.039364,33.547581],[-82.992958,33.535173],[-83.01015,33.585988],[-83.302178,33.725886],[-83.330685,33.674881],[-83.348511,33.598443]]]},"properties":{"id":"30642","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590189,33.711408],[-83.591487,33.514037],[-83.593,33.469604],[-83.533736,33.434472],[-83.368639,33.467321],[-83.348511,33.598443],[-83.330685,33.674881],[-83.451904,33.73846],[-83.525745,33.726431],[-83.551715,33.723047],[-83.598953,33.736486],[-83.611999,33.720697],[-83.590189,33.711408]]]},"properties":{"id":"30650","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.948044,33.982593],[-83.843184,33.894775],[-83.824104,33.881848],[-83.815501,33.908919],[-83.832718,33.967645],[-83.875867,34.054899],[-83.882342,34.056479],[-83.939105,34.036741],[-83.950838,33.984465],[-83.948044,33.982593]]]},"properties":{"id":"30019","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.486581,34.498819],[-83.526529,34.54817],[-83.616427,34.547307],[-83.666413,34.503598],[-83.669566,34.441448],[-83.561669,34.489694],[-83.499644,34.487142],[-83.486581,34.498819]]]},"properties":{"id":"30531","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.342726,33.675952],[-85.216506,33.689486],[-85.216537,33.765581],[-85.351861,33.901241],[-85.386581,33.901719],[-85.342726,33.675952]]]},"properties":{"id":"30176","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.861549,32.945642],[-84.977004,32.918953],[-85.021109,32.796763],[-84.855313,32.811188],[-84.809073,32.840651],[-84.821942,32.948918],[-84.861549,32.945642]]]},"properties":{"id":"31822","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.420699,31.553567],[-83.34944,31.477455],[-83.284229,31.475624],[-83.145587,31.472276],[-83.120554,31.473559],[-83.133287,31.608903],[-83.388889,31.654727],[-83.417922,31.63107],[-83.420699,31.553567]]]},"properties":{"id":"31774","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.357193,34.122524],[-83.427706,34.131385],[-83.450071,34.138203],[-83.463633,34.082023],[-83.384964,34.037777],[-83.343306,34.053552],[-83.357193,34.122524]]]},"properties":{"id":"30565","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.582427,33.160117],[-82.661917,33.126331],[-82.661922,33.126311],[-82.60371,33.044643],[-82.490798,33.109156],[-82.487586,33.238332],[-82.584175,33.168674],[-82.582427,33.160117]]]},"properties":{"id":"30803","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.416298,33.28698],[-82.309439,33.195541],[-82.241523,33.234405],[-82.296855,33.277349],[-82.353498,33.312318],[-82.383829,33.312106],[-82.416298,33.28698]]]},"properties":{"id":"30818","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179904,30.992073],[-83.18441,30.905824],[-83.156409,30.877972],[-83.019419,30.849453],[-83.032712,31.011013],[-83.179904,30.992073]]]},"properties":{"id":"31641","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.586075,31.79555],[-81.468313,31.858393],[-81.485693,31.90241],[-81.545949,31.871677],[-81.575426,31.873829],[-81.668753,31.859017],[-81.765777,31.887847],[-81.658187,31.756927],[-81.586075,31.79555]]]},"properties":{"id":"31313","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.18441,30.905824],[-83.229129,30.849255],[-83.220325,30.7339],[-83.156409,30.877972],[-83.18441,30.905824]]]},"properties":{"id":"31606","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.376901,33.458587],[-82.449977,33.340505],[-82.383829,33.312106],[-82.353498,33.312318],[-82.316964,33.338497],[-82.376901,33.458587]]]},"properties":{"id":"30808","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.398091,33.498081],[-82.425328,33.650629],[-82.543192,33.638828],[-82.649705,33.608768],[-82.630188,33.493474],[-82.513544,33.343708],[-82.449977,33.340505],[-82.376901,33.458587],[-82.398091,33.498081]]]},"properties":{"id":"30824","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.043515,32.181458],[-84.051797,32.240259],[-84.204837,32.230573],[-84.213211,32.159155],[-84.195805,32.118999],[-84.043515,32.181458]]]},"properties":{"id":"31711","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.583301,32.168214],[-84.431214,32.134058],[-84.430215,32.166257],[-84.392316,32.414046],[-84.57201,32.449264],[-84.657568,32.437286],[-84.657874,32.343577],[-84.65892,32.232852],[-84.583301,32.168214]]]},"properties":{"id":"31803","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.52691,32.542507],[-84.57201,32.449264],[-84.392316,32.414046],[-84.362648,32.41856],[-84.319916,32.451017],[-84.388133,32.583236],[-84.408566,32.59185],[-84.504726,32.545499],[-84.52691,32.542507]]]},"properties":{"id":"31058","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.279931,33.483438],[-83.348511,33.598443],[-83.368639,33.467321],[-83.279931,33.483438]]]},"properties":{"id":"30625","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.738484,34.987838],[-84.731208,34.979709],[-84.727166,34.988245],[-84.738484,34.987838]]]},"properties":{"id":"30751","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.967031,32.435343],[-84.971962,32.377698],[-84.91564,32.422987],[-84.926676,32.437019],[-84.96682,32.454794],[-84.967031,32.435343]]]},"properties":{"id":"31903","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.96091,32.496105],[-84.994841,32.485566],[-84.967031,32.435343],[-84.96682,32.454794],[-84.96091,32.496105]]]},"properties":{"id":"31901","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.911074,32.582915],[-84.960893,32.583236],[-84.947505,32.502177],[-84.946544,32.50068],[-84.871621,32.540225],[-84.911074,32.582915]]]},"properties":{"id":"31909","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.930908,33.711727],[-84.010627,33.655084],[-84.01462,33.559029],[-83.951533,33.628898],[-83.931512,33.650879],[-83.918235,33.721955],[-83.930908,33.711727]]]},"properties":{"id":"30013","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.895131,33.575763],[-83.898007,33.572664],[-83.894854,33.572271],[-83.893024,33.574312],[-83.895131,33.575763]]]},"properties":{"id":"30070","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.948108,34.025175],[-84.961996,33.972924],[-84.779978,33.899445],[-84.778664,33.909172],[-84.755223,34.010247],[-84.771861,34.082431],[-84.880339,34.082573],[-84.948108,34.025175]]]},"properties":{"id":"30132","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.506012,33.171874],[-84.528496,33.043027],[-84.39237,33.055939],[-84.393133,33.099434],[-84.506012,33.171874]]]},"properties":{"id":"30206","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.237791,34.998029],[-83.421859,34.99415],[-83.324401,34.941532],[-83.237791,34.998029]]]},"properties":{"id":"30537","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.018161,33.528292],[-82.047764,33.44826],[-82.006727,33.447955],[-81.991827,33.470261],[-81.981726,33.48455],[-82.011132,33.531432],[-82.018161,33.528292]]]},"properties":{"id":"30904","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.068573,32.287222],[-82.211921,32.246915],[-82.194821,32.1342],[-82.027813,32.111835],[-82.019977,32.160247],[-82.025284,32.168901],[-82.025339,32.278883],[-82.068573,32.287222]]]},"properties":{"id":"30421","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.722949,31.960672],[-82.813821,32.051928],[-82.827792,32.056078],[-82.962452,32.049081],[-82.9691,31.925854],[-82.865396,31.901237],[-82.722949,31.960672]]]},"properties":{"id":"31055","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.722949,31.960672],[-82.865396,31.901237],[-82.791845,31.847107],[-82.543655,31.958914],[-82.610457,32.054906],[-82.668573,32.034736],[-82.722949,31.960672]]]},"properties":{"id":"31549","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.584664,31.595701],[-83.49057,31.676699],[-83.480406,31.707108],[-83.643153,31.596545],[-83.584664,31.595701]]]},"properties":{"id":"31790","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28615,32.971406],[-84.397702,32.99253],[-84.47709,32.877519],[-84.402222,32.812023],[-84.341997,32.768765],[-84.304978,32.760619],[-84.208541,32.800944],[-84.202911,32.962927],[-84.28615,32.971406]]]},"properties":{"id":"30286","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.632787,31.102663],[-82.530745,31.086789],[-82.568925,31.207314],[-82.673317,31.187234],[-82.671669,31.183739],[-82.636762,31.1108],[-82.632787,31.102663]]]},"properties":{"id":"31550","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.583301,32.168214],[-84.594909,31.993727],[-84.556292,31.963144],[-84.443807,31.967456],[-84.432254,32.094929],[-84.431214,32.134058],[-84.583301,32.168214]]]},"properties":{"id":"31824","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.52142,31.710796],[-82.311523,31.590997],[-82.240772,31.555677],[-82.229409,31.574702],[-82.17961,31.901503],[-82.225042,31.913074],[-82.354891,31.939561],[-82.431531,31.966182],[-82.52142,31.710796]]]},"properties":{"id":"31513","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.208833,34.506755],[-83.162011,34.589825],[-83.338234,34.687187],[-83.425789,34.61561],[-83.451243,34.498535],[-83.45977,34.481142],[-83.413188,34.431118],[-83.399326,34.42618],[-83.285696,34.459866],[-83.208833,34.506755]]]},"properties":{"id":"30577","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.464487,33.906612],[-83.503514,33.998809],[-83.51459,34.002661],[-83.53241,34.005893],[-83.587365,33.882936],[-83.55832,33.840674],[-83.478955,33.867831],[-83.464487,33.906612]]]},"properties":{"id":"30622","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.748687,34.150739],[-84.768642,34.118694],[-84.750023,34.110343],[-84.748687,34.150739]]]},"properties":{"id":"30137","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.907956,34.303327],[-85.046191,34.340479],[-85.040029,34.182471],[-84.955729,34.137678],[-84.907956,34.303327]]]},"properties":{"id":"30145","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.068846,34.146802],[-85.125968,34.083228],[-85.118796,34.052828],[-84.986522,34.076042],[-85.04588,34.143272],[-85.068846,34.146802]]]},"properties":{"id":"30104","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.165492,31.109933],[-83.317322,31.09563],[-83.246372,30.991098],[-83.180622,31.025488],[-83.165492,31.109933]]]},"properties":{"id":"31645","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.674501,32.902937],[-83.71305,32.825758],[-83.653811,32.819995],[-83.653678,32.825685],[-83.650899,32.830593],[-83.634476,32.853668],[-83.674501,32.902937]]]},"properties":{"id":"31204","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.653678,32.825685],[-83.648043,32.826628],[-83.650899,32.830593],[-83.653678,32.825685]]]},"properties":{"id":"31207","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.498039,32.401715],[-83.346528,32.272489],[-83.203822,32.32696],[-83.26419,32.480238],[-83.262094,32.502054],[-83.340911,32.594154],[-83.497923,32.452198],[-83.498039,32.401715]]]},"properties":{"id":"31014","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.041129,31.373721],[-82.132794,31.471262],[-82.173464,31.42279],[-82.2193,31.409425],[-82.104324,31.271402],[-82.056915,31.274729],[-81.897151,31.298155],[-82.041129,31.373721]],[[-82.11848,31.419955],[-82.110235,31.419044],[-82.102909,31.413041],[-82.112137,31.409963],[-82.118465,31.412546],[-82.11848,31.419955]]]},"properties":{"id":"31557","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.542066,32.239523],[-81.533579,32.098703],[-81.384727,32.079517],[-81.401931,32.150184],[-81.415796,32.191684],[-81.445434,32.275413],[-81.542066,32.239523]]]},"properties":{"id":"31308","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969069,32.268783],[-81.820573,32.238447],[-81.918671,32.41541],[-81.969069,32.268783]]]},"properties":{"id":"30452","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.296855,33.277349],[-82.241523,33.234405],[-82.217245,33.242216],[-82.154352,33.357906],[-82.296855,33.277349]]]},"properties":{"id":"30805","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.398783,33.136966],[-82.490798,33.109156],[-82.60371,33.044643],[-82.549718,33.016999],[-82.400726,32.975015],[-82.320435,32.840033],[-82.194773,32.968693],[-82.273618,33.070805],[-82.281054,33.110354],[-82.398783,33.136966]]]},"properties":{"id":"30434","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.840671,31.097665],[-81.682654,30.955266],[-81.434926,31.000244],[-81.463516,31.035396],[-81.766322,31.169595],[-81.840671,31.097665]]]},"properties":{"id":"31565","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.551726,30.81034],[-81.565121,30.874153],[-81.601269,30.839408],[-81.654268,30.744351],[-81.412784,30.990835],[-81.434926,31.000244],[-81.576534,30.782585],[-81.551726,30.81034]]]},"properties":{"id":"31558","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.003418,34.928234],[-84.981887,34.987637],[-85.195694,34.985778],[-85.2208,34.94533],[-85.264707,34.941522],[-85.264621,34.927422],[-85.235341,34.865805],[-85.134279,34.809302],[-85.091084,34.796623],[-85.003418,34.928234]],[[-85.143665,34.977697],[-85.135046,34.9765],[-85.139796,34.972371],[-85.143665,34.977697]]]},"properties":{"id":"30736","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.981887,34.987637],[-85.003418,34.928234],[-84.99493,34.903214],[-84.988303,34.899307],[-84.976064,34.900951],[-84.835191,34.942949],[-84.810477,34.987605],[-84.981887,34.987637]]]},"properties":{"id":"30710","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.241721,32.145371],[-81.136785,32.128102],[-81.15925,32.229736],[-81.24925,32.198648],[-81.277532,32.178306],[-81.241721,32.145371]]]},"properties":{"id":"31407","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.516802,34.146138],[-84.359066,34.158195],[-84.273452,34.228105],[-84.282959,34.235731],[-84.290762,34.254054],[-84.360433,34.253793],[-84.516802,34.146138]]]},"properties":{"id":"30115","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.437512,33.654027],[-84.489899,33.632192],[-84.401123,33.633127],[-84.437512,33.654027]]]},"properties":{"id":"30337","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.360897,33.574854],[-84.29895,33.558028],[-84.298783,33.558429],[-84.295861,33.560994],[-84.293061,33.588121],[-84.343546,33.613838],[-84.360897,33.574854]]]},"properties":{"id":"30260","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.314956,33.680289],[-84.341619,33.623748],[-84.343546,33.613838],[-84.293061,33.588121],[-84.243655,33.612235],[-84.170181,33.645836],[-84.208877,33.660808],[-84.30795,33.679612],[-84.314956,33.680289]]]},"properties":{"id":"30294","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.213296,33.391571],[-84.230272,33.469116],[-84.266526,33.486588],[-84.334027,33.464044],[-84.384772,33.458713],[-84.388118,33.352465],[-84.291055,33.340775],[-84.292642,33.343708],[-84.287885,33.34443],[-84.287126,33.340345],[-84.216329,33.335821],[-84.213296,33.391571]],[[-84.313819,33.439414],[-84.320562,33.432246],[-84.321506,33.440582],[-84.313819,33.439414]]]},"properties":{"id":"30228","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.547705,33.945428],[-84.594503,33.861977],[-84.577129,33.864755],[-84.541659,33.897494],[-84.487334,33.902781],[-84.520518,33.95089],[-84.547421,33.976388],[-84.554478,33.9796],[-84.569275,33.991806],[-84.577382,33.990582],[-84.582428,33.985857],[-84.577475,33.984566],[-84.547705,33.945428]]]},"properties":{"id":"30060","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.582428,33.985857],[-84.577382,33.990582],[-84.581409,33.993888],[-84.582428,33.985857]]],[[[-84.430353,34.062031],[-84.547421,33.976388],[-84.520518,33.95089],[-84.506449,33.950766],[-84.394352,34.002654],[-84.430353,34.062031]]]]},"properties":{"id":"30062","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.618238,33.859348],[-84.632519,33.858847],[-84.635769,33.854545],[-84.680748,33.805579],[-84.642903,33.797228],[-84.597767,33.815277],[-84.578481,33.848823],[-84.577129,33.864755],[-84.594503,33.861977],[-84.618238,33.859348]]]},"properties":{"id":"30106","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.953303,31.656883],[-83.11166,31.478229],[-82.983364,31.450931],[-82.963994,31.555775],[-82.955112,31.585338],[-82.953303,31.656883]]]},"properties":{"id":"31512","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630969,31.068476],[-83.631082,31.061652],[-83.608608,31.061018],[-83.630273,31.079069],[-83.630969,31.068476]]]},"properties":{"id":"31722","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.165933,33.493629],[-82.162905,33.485876],[-82.158455,33.492456],[-82.165933,33.493629]]]},"properties":{"id":"30903","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.850713,33.511457],[-84.731645,33.489463],[-84.638921,33.500239],[-84.622978,33.480311],[-84.594726,33.518707],[-84.735712,33.624613],[-84.808934,33.574085],[-84.850713,33.511457]]]},"properties":{"id":"30268","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.018147,32.506406],[-83.846676,32.468514],[-83.71411,32.530293],[-83.719966,32.546544],[-83.71918,32.595823],[-83.871539,32.699845],[-83.887119,32.703601],[-83.945327,32.681639],[-84.067632,32.612113],[-84.018147,32.506406]]]},"properties":{"id":"31030","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.610741,31.967619],[-83.628533,32.028041],[-83.961278,32.030595],[-83.922494,31.909653],[-83.939437,31.847929],[-83.843778,31.826975],[-83.612256,31.854089],[-83.560239,31.853277],[-83.55751,31.853278],[-83.610896,31.95948],[-83.610741,31.967619]]]},"properties":{"id":"31015","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.46347,34.982973],[-85.57449,34.815499],[-85.47201,34.881995],[-85.46347,34.982973]]]},"properties":{"id":"30752","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.46347,34.982973],[-85.47201,34.881995],[-85.444545,34.901154],[-85.379512,34.983035],[-85.46347,34.982973]]]},"properties":{"id":"30757","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.188557,34.602692],[-83.980649,34.418389],[-83.864368,34.560524],[-83.994824,34.679638],[-84.158035,34.648243],[-84.188557,34.602692]]]},"properties":{"id":"30533","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28272,33.956234],[-84.289521,33.915982],[-84.278279,33.904832],[-84.240569,33.928753],[-84.260789,33.932802],[-84.264729,33.965159],[-84.28272,33.956234]]]},"properties":{"id":"30360","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.259716,33.805224],[-84.268494,33.785431],[-84.264755,33.783062],[-84.246498,33.787046],[-84.246273,33.786937],[-84.259716,33.805224]]]},"properties":{"id":"30079","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.197838,33.736845],[-84.171483,33.723662],[-84.163176,33.772211],[-84.167904,33.798407],[-84.197838,33.736845]]]},"properties":{"id":"30088","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.361348,33.842729],[-84.382503,33.813412],[-84.370073,33.792252],[-84.336743,33.805432],[-84.343618,33.833194],[-84.352309,33.848619],[-84.361348,33.842729]]]},"properties":{"id":"30324","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.028243,32.216737],[-83.115784,32.070028],[-82.999471,32.074765],[-82.986631,32.149321],[-83.012286,32.186759],[-83.028243,32.216737]]]},"properties":{"id":"31011","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93151,31.43925],[-83.785595,31.383211],[-83.77137,31.4886],[-83.82578,31.57309],[-83.78619,31.61186],[-83.78688,31.62281],[-83.858254,31.684739],[-83.90435,31.6932],[-83.983778,31.658012],[-83.93151,31.43925]]]},"properties":{"id":"31791","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.707486,33.805795],[-84.680749,33.760656],[-84.605037,33.717306],[-84.578132,33.743507],[-84.642903,33.797228],[-84.680748,33.805579],[-84.707486,33.805795]]]},"properties":{"id":"30122","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.820646,31.070609],[-84.875391,31.176774],[-84.919715,31.172197],[-85.028564,31.075523],[-84.863465,30.711494],[-84.859555,30.753028],[-84.789988,30.873627],[-84.820646,31.070609]]]},"properties":{"id":"39845","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.528827,32.444006],[-81.498674,32.500014],[-81.392192,32.527667],[-81.389205,32.595416],[-81.406487,32.662565],[-81.530385,32.564608],[-81.548006,32.489286],[-81.528827,32.444006]]]},"properties":{"id":"30446","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.258122,32.65994],[-82.417224,32.770403],[-82.434149,32.762258],[-82.460083,32.614478],[-82.49055,32.496502],[-82.325902,32.425814],[-82.258122,32.65994]],[[-82.371509,32.463486],[-82.380916,32.485135],[-82.373173,32.502913],[-82.346573,32.491262],[-82.371509,32.463486]]]},"properties":{"id":"30401","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.83929,32.14987],[-81.841601,32.143437],[-81.82022,32.145007],[-81.835763,32.155517],[-81.83929,32.14987]]]},"properties":{"id":"30423","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.938364,32.170648],[-81.941665,32.169905],[-81.934198,32.162916],[-81.935045,32.16885],[-81.938364,32.170648]]]},"properties":{"id":"30429","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.068846,34.146802],[-85.158446,34.195428],[-85.193078,34.138208],[-85.125968,34.083228],[-85.068846,34.146802]]]},"properties":{"id":"30173","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.088712,34.42502],[-84.998628,34.429357],[-84.825337,34.433776],[-84.802608,34.503755],[-84.823262,34.550535],[-84.989919,34.564897],[-85.064158,34.567783],[-85.109314,34.431796],[-85.088712,34.42502]]]},"properties":{"id":"30701","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.176219,34.082313],[-84.168152,34.067895],[-84.07401,34.001354],[-84.023676,34.048112],[-84.010095,34.066818],[-84.093343,34.126273],[-84.176219,34.082313]]]},"properties":{"id":"30024","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.162011,34.589825],[-83.208833,34.506755],[-83.267785,34.449187],[-83.187248,34.410895],[-83.101566,34.531944],[-83.162011,34.589825]]]},"properties":{"id":"30557","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.101566,34.531944],[-83.187248,34.410895],[-83.187602,34.328656],[-83.099008,34.388997],[-83.02502,34.41079],[-82.974917,34.47935],[-83.101566,34.531944]]]},"properties":{"id":"30553","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.390548,33.762273],[-84.404461,33.744347],[-84.407205,33.741168],[-84.387708,33.7469],[-84.387563,33.75124],[-84.383746,33.749],[-84.379304,33.758957],[-84.390548,33.762273]]]},"properties":{"id":"30303","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.280307,33.977598],[-84.315466,34.033714],[-84.327509,34.075488],[-84.359657,34.077773],[-84.333156,34.009652],[-84.280307,33.977598]]]},"properties":{"id":"30076","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.258913,31.009924],[-84.175434,30.995637],[-84.118349,31.031956],[-84.053541,31.198972],[-84.07407,31.198747],[-84.346135,31.078908],[-84.258913,31.009924]]]},"properties":{"id":"31779","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.641998,34.627566],[-83.513537,34.589468],[-83.425789,34.61561],[-83.338234,34.687187],[-83.352448,34.716125],[-83.387424,34.737363],[-83.45529,34.732628],[-83.537429,34.791286],[-83.554573,34.842146],[-83.656351,34.863023],[-83.681596,34.800593],[-83.641998,34.627566]]]},"properties":{"id":"30523","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.865537,34.225384],[-83.815707,34.174093],[-83.772473,34.165908],[-83.747799,34.186807],[-83.696335,34.230622],[-83.687015,34.33896],[-83.739497,34.365264],[-83.829666,34.249746],[-83.865537,34.225384]]]},"properties":{"id":"30507","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.772473,34.165908],[-83.723441,34.14256],[-83.675267,34.142838],[-83.579158,34.194336],[-83.638797,34.235466],[-83.674748,34.214081],[-83.747799,34.186807],[-83.772473,34.165908]]]},"properties":{"id":"30567","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.783462,33.301218],[-82.755846,33.25344],[-82.76164,33.296801],[-82.783462,33.301218]]]},"properties":{"id":"31045","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.150546,33.905578],[-85.070691,33.88269],[-85.004305,33.87718],[-84.961996,33.972924],[-84.948108,34.025175],[-84.986522,34.076042],[-85.118796,34.052828],[-85.150546,33.905578]]]},"properties":{"id":"30153","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.762414,32.619212],[-84.80206,32.666182],[-84.843928,32.613636],[-84.762414,32.619212]]]},"properties":{"id":"31807","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.18897,32.8909],[-85.122189,32.773353],[-85.021109,32.796763],[-84.977004,32.918953],[-85.020288,32.916052],[-85.18897,32.8909]]]},"properties":{"id":"31833","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630043,32.428063],[-83.71411,32.530293],[-83.846676,32.468514],[-83.846844,32.357739],[-83.672002,32.399617],[-83.630043,32.428063]]]},"properties":{"id":"31069","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.599388,32.61976],[-83.589511,32.576784],[-83.548147,32.571922],[-83.597656,32.664338],[-83.607939,32.664496],[-83.599388,32.61976]]]},"properties":{"id":"31098","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.545876,33.171944],[-83.719026,33.146688],[-83.702301,33.052071],[-83.521412,33.128976],[-83.525624,33.174389],[-83.545876,33.171944]]]},"properties":{"id":"31038","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.727019,33.438991],[-83.536931,33.401739],[-83.533736,33.434472],[-83.593,33.469604],[-83.734113,33.481471],[-83.727019,33.438991]]]},"properties":{"id":"31085","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.549718,33.016999],[-82.627145,32.896682],[-82.648125,32.824551],[-82.554968,32.79264],[-82.482027,32.786734],[-82.400726,32.975015],[-82.549718,33.016999]]]},"properties":{"id":"30413","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.416298,33.28698],[-82.398783,33.136966],[-82.281054,33.110354],[-82.309439,33.195541],[-82.416298,33.28698]]]},"properties":{"id":"30833","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.704343,32.913744],[-82.987358,32.846871],[-82.957066,32.708318],[-82.906678,32.712341],[-82.776063,32.80869],[-82.704343,32.913744]],[[-82.948386,32.865385],[-82.915254,32.859779],[-82.960561,32.847144],[-82.948386,32.865385]]]},"properties":{"id":"31089","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.073213,33.184877],[-84.114737,33.183479],[-84.28039,33.148114],[-84.233951,33.078463],[-84.073213,33.184877]]]},"properties":{"id":"30257","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.114429,33.260736],[-84.358582,33.236227],[-84.333914,33.144824],[-84.28039,33.148114],[-84.114737,33.183479],[-84.114429,33.260736]]]},"properties":{"id":"30224","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.202911,32.962927],[-84.208541,32.800944],[-84.099019,32.950575],[-84.202888,32.979571],[-84.202911,32.962927]]]},"properties":{"id":"31097","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.333914,33.144824],[-84.393133,33.099434],[-84.39237,33.055939],[-84.359106,33.036603],[-84.248196,33.065824],[-84.233951,33.078463],[-84.28039,33.148114],[-84.333914,33.144824]]]},"properties":{"id":"30295","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.806038,32.407068],[-82.679377,32.506666],[-82.669881,32.592331],[-82.906678,32.712341],[-82.957066,32.708318],[-82.806038,32.407068]]]},"properties":{"id":"31027","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.485693,31.90241],[-81.468313,31.858393],[-81.39363,31.880901],[-81.404926,31.936868],[-81.485693,31.90241]]]},"properties":{"id":"31309","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.212738,30.971043],[-83.197486,30.971591],[-83.207807,30.981063],[-83.212738,30.971043]]]},"properties":{"id":"31699","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.286948,30.846511],[-83.287959,30.851401],[-83.292815,30.851028],[-83.290636,30.846894],[-83.286948,30.846511]]]},"properties":{"id":"31698","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.197805,32.400999],[-84.254613,32.372053],[-84.219249,32.291337],[-84.106156,32.440286],[-84.165141,32.446407],[-84.197805,32.400999]]]},"properties":{"id":"31041","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.506267,32.066532],[-82.478268,32.095229],[-82.493855,32.085608],[-82.506267,32.066532]]]},"properties":{"id":"30412","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.55832,33.840674],[-83.598953,33.736486],[-83.551715,33.723047],[-83.52761,33.74781],[-83.55832,33.840674]]]},"properties":{"id":"30641","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.55832,33.840674],[-83.52761,33.74781],[-83.525745,33.726431],[-83.451904,33.73846],[-83.478955,33.867831],[-83.55832,33.840674]]]},"properties":{"id":"30621","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.818715,34.87695],[-84.622752,34.857131],[-84.622158,34.925715],[-84.68228,34.988181],[-84.727166,34.988245],[-84.731208,34.979709],[-84.738484,34.987838],[-84.810477,34.987605],[-84.835191,34.942949],[-84.818715,34.87695]]]},"properties":{"id":"30711","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.994841,32.485566],[-84.96091,32.496105],[-84.947505,32.502177],[-84.960893,32.583236],[-85.080784,32.608072],[-84.994841,32.485566]]]},"properties":{"id":"31904","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.537429,34.791286],[-83.422682,34.79859],[-83.421307,34.798419],[-83.324181,34.789627],[-83.554573,34.842146],[-83.537429,34.791286]]]},"properties":{"id":"30576","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.506888,32.881788],[-84.580013,32.845149],[-84.672148,32.765686],[-84.577971,32.72509],[-84.402222,32.812023],[-84.47709,32.877519],[-84.506888,32.881788]]]},"properties":{"id":"31836","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.795172,33.558176],[-82.804505,33.563999],[-82.804688,33.557638],[-82.795172,33.558176]]]},"properties":{"id":"30664","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.810033,34.987449],[-83.819604,34.913834],[-83.780401,34.792893],[-83.714186,34.793112],[-83.681596,34.800593],[-83.656351,34.863023],[-83.549381,34.992492],[-83.810033,34.987449]]]},"properties":{"id":"30546","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.279313,34.790888],[-85.428429,34.607208],[-85.398508,34.589249],[-85.365204,34.584665],[-85.21614,34.649244],[-85.107748,34.587483],[-85.069159,34.587184],[-85.050446,34.622482],[-85.050818,34.712173],[-85.144789,34.767639],[-85.279313,34.790888]]]},"properties":{"id":"30728","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.648897,31.98433],[-84.655079,31.971593],[-84.65582,31.920308],[-84.599776,31.920171],[-84.556292,31.963144],[-84.594909,31.993727],[-84.648897,31.98433]]]},"properties":{"id":"31832","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.829615,34.739627],[-83.777129,34.673608],[-83.714186,34.793112],[-83.780401,34.792893],[-83.829615,34.739627]]]},"properties":{"id":"30545","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.01143,34.802715],[-85.050818,34.712173],[-85.050446,34.622482],[-85.016182,34.615882],[-84.995113,34.615852],[-84.974962,34.893908],[-84.988303,34.899307],[-84.99493,34.903214],[-85.01143,34.802715]]]},"properties":{"id":"30720","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132955,32.840248],[-83.243461,32.8269],[-83.282598,32.774748],[-83.265393,32.690168],[-83.109588,32.64088],[-83.016691,32.683425],[-83.132955,32.840248]]]},"properties":{"id":"31042","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.185408,32.604362],[-83.216745,32.626796],[-83.226536,32.584201],[-83.185408,32.604362]]]},"properties":{"id":"31003","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.78619,31.61186],[-83.77137,31.4886],[-83.785595,31.383211],[-83.713975,31.326027],[-83.68313,31.36319],[-83.69923,31.39616],[-83.664954,31.555932],[-83.78688,31.62281],[-83.78619,31.61186]]]},"properties":{"id":"31789","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.176795,31.549016],[-84.231467,31.623343],[-84.297365,31.638226],[-84.450398,31.621868],[-84.429876,31.436661],[-84.226348,31.351479],[-84.141124,31.440179],[-84.176795,31.549016]]]},"properties":{"id":"31721","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.987358,32.846871],[-82.704343,32.913744],[-82.674853,32.911026],[-82.675891,33.068361],[-82.948911,33.103611],[-83.052197,33.080682],[-83.073648,32.946562],[-82.987358,32.846871]]]},"properties":{"id":"31082","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.687015,34.33896],[-83.696335,34.230622],[-83.674748,34.214081],[-83.638797,34.235466],[-83.591718,34.349826],[-83.687015,34.33896]]]},"properties":{"id":"30543","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.404588,31.797673],[-83.388889,31.654727],[-83.133287,31.608903],[-82.99567,31.780118],[-82.995698,31.780983],[-83.10131,31.811955],[-83.177469,31.847857],[-83.356486,31.846509],[-83.404588,31.797673]]]},"properties":{"id":"31750","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.335456,31.221382],[-83.317322,31.09563],[-83.165492,31.109933],[-83.046885,31.18368],[-83.047957,31.290009],[-83.058325,31.308259],[-83.31173,31.316257],[-83.357865,31.250348],[-83.335456,31.221382]]]},"properties":{"id":"31639","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.653811,32.819995],[-83.60051,32.75974],[-83.62819,32.845827],[-83.634476,32.853668],[-83.650899,32.830593],[-83.648043,32.826628],[-83.653678,32.825685],[-83.653811,32.819995]],[[-83.638235,32.840805],[-83.638962,32.839177],[-83.6405,32.839607],[-83.640065,32.840732],[-83.638235,32.840805]]]},"properties":{"id":"31201","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.189502,32.473706],[-83.136392,32.418281],[-83.078546,32.468595],[-83.081276,32.570957],[-83.189502,32.473706]]]},"properties":{"id":"31022","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.671746,32.967858],[-81.502769,32.938113],[-81.721124,33.126112],[-81.792869,33.05113],[-81.671746,32.967858]]]},"properties":{"id":"30426","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.682654,30.955266],[-81.840671,31.097665],[-81.91301,31.096142],[-81.936006,31.047993],[-81.815393,30.925432],[-81.682906,30.955445],[-81.682654,30.955266]]]},"properties":{"id":"31568","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.95948,32.390911],[-81.948025,32.389176],[-81.948037,32.391557],[-81.95948,32.390911]]]},"properties":{"id":"30451","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.041486,33.663114],[-85.096196,33.485574],[-85.080699,33.426428],[-85.015358,33.425506],[-84.973902,33.452679],[-84.90535,33.591329],[-84.904893,33.605225],[-84.977932,33.680896],[-85.041486,33.663114]]]},"properties":{"id":"30116","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.112861,33.570004],[-85.103262,33.568717],[-85.095341,33.575372],[-85.112257,33.57533],[-85.112861,33.570004]]]},"properties":{"id":"30118","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.756408,30.671487],[-82.727907,30.600211],[-82.584007,30.591548],[-82.576307,30.713973],[-82.703571,30.616783],[-82.756408,30.671487]]]},"properties":{"id":"31631","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.140567,32.002831],[-81.159193,31.99629],[-81.146895,31.999455],[-81.140567,32.002831]]],[[[-81.094652,32.024423],[-81.085284,32.048277],[-81.107088,32.053866],[-81.161015,32.070977],[-81.197802,32.074567],[-81.251664,32.063156],[-81.199041,32.001151],[-81.120696,32.016291],[-81.094652,32.024423]]]]},"properties":{"id":"31405","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.099909,32.08766],[-81.107088,32.053866],[-81.085284,32.048277],[-81.063961,32.080496],[-81.099909,32.08766]]]},"properties":{"id":"31401","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.210548,34.129592],[-84.087051,34.263542],[-84.282959,34.235731],[-84.273452,34.228105],[-84.215377,34.12321],[-84.210548,34.129592]]]},"properties":{"id":"30040","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.196754,34.617924],[-84.303842,34.57556],[-84.259448,34.46734],[-84.26957,34.415652],[-84.246357,34.335158],[-84.056983,34.328655],[-84.0288,34.306905],[-83.975603,34.408593],[-83.980649,34.418389],[-84.188557,34.602692],[-84.196754,34.617924]]]},"properties":{"id":"30534","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.376761,33.957364],[-83.378804,33.942583],[-83.368828,33.944206],[-83.372214,33.956538],[-83.376761,33.957364]]]},"properties":{"id":"30602","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.489899,33.632192],[-84.51562,33.655062],[-84.640658,33.664443],[-84.584625,33.60787],[-84.502023,33.56593],[-84.489596,33.561499],[-84.425216,33.58817],[-84.404391,33.605481],[-84.404052,33.606589],[-84.401123,33.633127],[-84.489899,33.632192]]]},"properties":{"id":"30349","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.37664,33.536999],[-84.422669,33.52543],[-84.427556,33.517155],[-84.384772,33.458713],[-84.334027,33.464044],[-84.37664,33.536999]]]},"properties":{"id":"30238","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.541659,33.897494],[-84.489661,33.825236],[-84.48757,33.823449],[-84.480134,33.817319],[-84.457881,33.824578],[-84.477473,33.900934],[-84.487334,33.902781],[-84.541659,33.897494]]]},"properties":{"id":"30080","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.641225,33.860755],[-84.632519,33.858847],[-84.618238,33.859348],[-84.631031,33.86763],[-84.641225,33.860755]]],[[[-84.631031,33.86763],[-84.547705,33.945428],[-84.577475,33.984566],[-84.68693,33.949127],[-84.631031,33.86763]]]]},"properties":{"id":"30064","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.724031,33.913372],[-84.76685,33.816056],[-84.707486,33.805795],[-84.680748,33.805579],[-84.635769,33.854545],[-84.641225,33.860755],[-84.631031,33.86763],[-84.68693,33.949127],[-84.713678,33.975332],[-84.734543,33.968195],[-84.724031,33.913372]]]},"properties":{"id":"30127","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.836366,31.816154],[-82.995698,31.780983],[-82.99567,31.780118],[-82.953303,31.656883],[-82.955112,31.585338],[-82.786001,31.621443],[-82.762461,31.671751],[-82.836525,31.779692],[-82.836366,31.816154]]]},"properties":{"id":"31519","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.934594,31.245846],[-83.94867,31.105976],[-83.917877,31.067347],[-83.820279,31.072105],[-83.773415,31.213894],[-83.735775,31.318658],[-83.934594,31.245846]]]},"properties":{"id":"31768","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.275575,33.492097],[-82.243186,33.391337],[-82.210402,33.414026],[-82.168272,33.437275],[-82.131375,33.482532],[-82.162905,33.485876],[-82.165933,33.493629],[-82.157897,33.504648],[-82.253727,33.523331],[-82.275575,33.492097]]]},"properties":{"id":"30813","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.317322,31.09563],[-83.389092,31.052284],[-83.38469,31.044617],[-83.397904,31.029903],[-83.397381,31.044221],[-83.475617,31.031964],[-83.44031,30.904794],[-83.408444,30.88716],[-83.309072,30.917935],[-83.246372,30.991098],[-83.317322,31.09563]]]},"properties":{"id":"31632","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.91774,33.249271],[-84.771657,33.141835],[-84.755293,33.17783],[-84.754291,33.223194],[-84.794732,33.29354],[-84.91774,33.249271]]]},"properties":{"id":"30220","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.376612,31.078883],[-84.460196,31.051169],[-84.49342,30.892046],[-84.471772,30.802582],[-84.380487,30.705728],[-84.380072,30.736875],[-84.376612,31.078883]]]},"properties":{"id":"39834","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.471772,30.802582],[-84.550801,30.697345],[-84.380705,30.689993],[-84.380487,30.705728],[-84.471772,30.802582]]]},"properties":{"id":"39815","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.224865,33.8929],[-84.245618,33.870725],[-84.247985,33.861091],[-84.261953,33.84423],[-84.251743,33.815982],[-84.217588,33.81756],[-84.18887,33.83314],[-84.167062,33.865377],[-84.173287,33.887736],[-84.224865,33.8929]]]},"properties":{"id":"30084","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.32805,33.790498],[-84.359587,33.764503],[-84.36698,33.752629],[-84.349175,33.753552],[-84.312557,33.765092],[-84.307792,33.790751],[-84.315911,33.795015],[-84.32805,33.790498]]]},"properties":{"id":"30307","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.854469,32.291066],[-84.024176,32.143846],[-83.979669,32.083955],[-83.976969,32.062195],[-83.902053,32.195585],[-83.855615,32.241489],[-83.854469,32.291066]]]},"properties":{"id":"31007","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.93151,31.43925],[-83.983778,31.658012],[-84.018404,31.650274],[-84.101445,31.618575],[-84.137769,31.442677],[-84.03962,31.373379],[-83.93151,31.43925]]]},"properties":{"id":"31705","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286839,31.769266],[-84.297365,31.638226],[-84.231467,31.623343],[-84.176975,31.622782],[-84.101445,31.618575],[-84.018404,31.650274],[-83.986618,31.769644],[-83.958008,31.835825],[-84.039596,31.878522],[-84.104905,31.871774],[-84.263771,31.831903],[-84.286581,31.784998],[-84.286839,31.769266]]]},"properties":{"id":"31763","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.415796,32.191684],[-81.401931,32.150184],[-81.3829,32.167088],[-81.415796,32.191684]]]},"properties":{"id":"31307","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.368525,32.160041],[-81.380696,32.144478],[-81.379574,32.133301],[-81.367481,32.126254],[-81.366922,32.159275],[-81.368525,32.160041]]]},"properties":{"id":"31318","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.919779,34.244855],[-82.989586,34.257448],[-82.999884,34.126047],[-82.919779,34.244855]]]},"properties":{"id":"30634","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.208833,34.506755],[-83.285696,34.459866],[-83.267785,34.449187],[-83.208833,34.506755]]]},"properties":{"id":"30538","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.36857,33.782108],[-84.390586,33.781528],[-84.391142,33.781533],[-84.390548,33.762273],[-84.379304,33.758957],[-84.363923,33.771133],[-84.36857,33.782108]]]},"properties":{"id":"30308","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.44293,33.705636],[-84.486558,33.68457],[-84.51562,33.655062],[-84.489899,33.632192],[-84.437512,33.654027],[-84.418327,33.673108],[-84.418328,33.697244],[-84.44293,33.705636]]]},"properties":{"id":"30344","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.49377,33.764465],[-84.498731,33.804805],[-84.515411,33.790334],[-84.630117,33.693116],[-84.654607,33.662858],[-84.640658,33.664443],[-84.51562,33.655062],[-84.486558,33.68457],[-84.49377,33.764465]]]},"properties":{"id":"30331","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.363923,33.771133],[-84.379304,33.758957],[-84.383746,33.749],[-84.387708,33.7469],[-84.407205,33.741168],[-84.398318,33.736934],[-84.359831,33.717052],[-84.36698,33.752629],[-84.359587,33.764503],[-84.363923,33.771133]]]},"properties":{"id":"30312","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.466031,33.754819],[-84.49377,33.764465],[-84.486558,33.68457],[-84.44293,33.705636],[-84.465728,33.754598],[-84.466031,33.754819]]]},"properties":{"id":"30311","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.200659,34.055993],[-84.272117,34.06866],[-84.315466,34.033714],[-84.280307,33.977598],[-84.262849,33.973676],[-84.214063,34.0039],[-84.200659,34.055993]]]},"properties":{"id":"30022","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.630117,33.693116],[-84.515411,33.790334],[-84.533546,33.779122],[-84.578132,33.743507],[-84.605037,33.717306],[-84.630117,33.693116]]]},"properties":{"id":"30336","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.654522,34.582897],[-84.512263,34.625899],[-84.392305,34.778403],[-84.470689,34.855034],[-84.618637,34.855398],[-84.654523,34.583187],[-84.654522,34.582897]]]},"properties":{"id":"30540","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52265,31.221082],[-81.461914,31.177202],[-81.443392,31.330841],[-81.62429,31.452982],[-81.655818,31.411972],[-81.658637,31.408163],[-81.52265,31.221082]],[[-81.480349,31.235665],[-81.483834,31.24719],[-81.467001,31.252137],[-81.459957,31.233885],[-81.480349,31.235665]]]},"properties":{"id":"31525","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.989919,34.564897],[-84.823262,34.550535],[-84.779475,34.608332],[-84.928222,34.667143],[-84.995113,34.615852],[-85.016182,34.615882],[-84.989919,34.564897]]]},"properties":{"id":"30735","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.175434,30.995637],[-84.258913,31.009924],[-84.260907,30.914001],[-84.095109,30.908493],[-84.175434,30.995637]]]},"properties":{"id":"39827","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.302178,33.725886],[-83.01015,33.585988],[-82.995602,33.693583],[-83.043503,33.711948],[-83.28034,33.761774],[-83.302178,33.725886]]]},"properties":{"id":"30669","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.072624,33.530861],[-83.039364,33.547581],[-83.074395,33.544496],[-83.072624,33.530861]]]},"properties":{"id":"30665","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.059346,33.849378],[-83.990957,33.828183],[-83.963221,33.863403],[-84.001456,33.893474],[-84.00719,33.898054],[-84.059999,33.886664],[-84.059346,33.849378]]]},"properties":{"id":"30078","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.010095,34.066818],[-83.946742,34.143218],[-84.008469,34.191711],[-84.0213,34.194075],[-84.093343,34.126273],[-84.010095,34.066818]]]},"properties":{"id":"30518","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.387424,34.737363],[-83.415071,34.755548],[-83.45529,34.732628],[-83.387424,34.737363]]]},"properties":{"id":"30573","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933171,34.230543],[-83.885317,34.265053],[-83.865537,34.225384],[-83.829666,34.249746],[-83.879821,34.321946],[-83.955917,34.23648],[-83.933171,34.230543]]]},"properties":{"id":"30504","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.975603,34.408593],[-83.847449,34.451901],[-83.832754,34.505341],[-83.864368,34.560524],[-83.980649,34.418389],[-83.975603,34.408593]]]},"properties":{"id":"30564","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.747799,34.186807],[-83.674748,34.214081],[-83.696335,34.230622],[-83.747799,34.186807]]]},"properties":{"id":"30575","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.855313,32.811188],[-84.803567,32.80961],[-84.809073,32.840651],[-84.855313,32.811188]]]},"properties":{"id":"31823","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.911074,32.582915],[-84.871621,32.540225],[-84.859423,32.525374],[-84.773367,32.549892],[-84.754685,32.611947],[-84.762414,32.619212],[-84.843928,32.613636],[-84.912792,32.607629],[-84.911074,32.582915]]]},"properties":{"id":"31820","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.140228,33.525397],[-84.230272,33.469116],[-84.213296,33.391571],[-84.138486,33.377767],[-84.140228,33.525397]]]},"properties":{"id":"30253","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.703226,32.622121],[-83.703289,32.614904],[-83.689825,32.617786],[-83.703226,32.622121]]],[[[-83.708957,32.632962],[-83.676334,32.618313],[-83.599388,32.61976],[-83.607939,32.664496],[-83.688346,32.686732],[-83.708957,32.632962]]]]},"properties":{"id":"31093","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.767553,32.909411],[-81.934344,32.930484],[-81.963108,32.923126],[-81.789375,32.860751],[-81.767553,32.909411]]]},"properties":{"id":"30822","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.790401,32.392547],[-82.830558,32.257734],[-82.668573,32.034736],[-82.610457,32.054906],[-82.655486,32.297561],[-82.724422,32.363576],[-82.790401,32.392547]]]},"properties":{"id":"30428","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.274361,31.500233],[-81.339631,31.446312],[-81.338988,31.441793],[-81.274361,31.500233]]]},"properties":{"id":"31327","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.350462,31.523158],[-81.367558,31.512576],[-81.361131,31.496746],[-81.347881,31.509297],[-81.350462,31.523158]]]},"properties":{"id":"31304","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.067632,32.612113],[-84.202628,32.690018],[-84.213583,32.706249],[-84.165141,32.446407],[-84.106156,32.440286],[-84.025029,32.432868],[-84.018147,32.506406],[-84.067632,32.612113]]]},"properties":{"id":"31076","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.693157,32.945397],[-84.653895,32.928203],[-84.50801,32.898816],[-84.5345,33.026398],[-84.613033,33.075118],[-84.693157,32.945397]]]},"properties":{"id":"30293","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.821942,32.948918],[-84.693157,32.945397],[-84.613033,33.075118],[-84.675778,33.149822],[-84.755293,33.17783],[-84.771657,33.141835],[-84.863448,33.082057],[-84.861549,32.945642],[-84.821942,32.948918]]]},"properties":{"id":"30222","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.653895,32.928203],[-84.700538,32.84464],[-84.580013,32.845149],[-84.506888,32.881788],[-84.50801,32.898816],[-84.653895,32.928203]]]},"properties":{"id":"31816","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.141124,31.440179],[-84.226348,31.351479],[-84.028629,31.311573],[-84.000127,31.298227],[-84.03962,31.373379],[-84.137769,31.442677],[-84.141124,31.440179]]]},"properties":{"id":"31716","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.59827,32.320441],[-82.655486,32.297561],[-82.610457,32.054906],[-82.506267,32.066532],[-82.493855,32.085608],[-82.574266,32.273526],[-82.59827,32.320441]]]},"properties":{"id":"30445","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.52761,33.74781],[-83.551715,33.723047],[-83.525745,33.726431],[-83.52761,33.74781]]]},"properties":{"id":"30623","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.01462,33.559029],[-84.044493,33.525776],[-83.927008,33.452539],[-83.891602,33.398035],[-83.951533,33.628898],[-84.01462,33.559029]]]},"properties":{"id":"30016","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.4141,32.133171],[-83.607997,32.148118],[-83.608918,32.080415],[-83.58789,32.085977],[-83.453572,32.097608],[-83.4141,32.133171]]]},"properties":{"id":"31071","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.554573,34.842146],[-83.324181,34.789627],[-83.324013,34.789516],[-83.237791,34.998029],[-83.324401,34.941532],[-83.342956,34.930632],[-83.381958,34.908968],[-83.407532,34.917339],[-83.482779,34.993256],[-83.549381,34.992492],[-83.656351,34.863023],[-83.554573,34.842146]]]},"properties":{"id":"30525","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.407532,34.917339],[-83.381958,34.908968],[-83.342956,34.930632],[-83.407532,34.917339]]]},"properties":{"id":"30562","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.798486,31.922799],[-84.957824,31.777838],[-84.95864,31.777854],[-84.958864,31.734606],[-84.778311,31.619641],[-84.680937,31.620027],[-84.675828,31.921605],[-84.675786,31.924009],[-84.798486,31.922799]]]},"properties":{"id":"39840","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.991827,33.470261],[-82.006727,33.447955],[-81.946752,33.367509],[-81.981726,33.48455],[-81.991827,33.470261]],[[-81.991827,33.470261],[-81.98555,33.473667],[-81.984211,33.468507],[-81.991827,33.470261]]]},"properties":{"id":"30901","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.028863,33.371628],[-82.031606,33.366836],[-82.030254,33.362709],[-82.024347,33.36596],[-82.028863,33.371628]]]},"properties":{"id":"30812","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.405953,33.301192],[-84.439347,33.228029],[-84.358582,33.236227],[-84.114429,33.260736],[-84.102585,33.272301],[-84.216329,33.335821],[-84.287126,33.340345],[-84.291055,33.340775],[-84.388118,33.352465],[-84.405953,33.301192]]]},"properties":{"id":"30223","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.432254,32.094929],[-84.346749,31.93864],[-84.255743,31.949492],[-84.195805,32.118999],[-84.213211,32.159155],[-84.430215,32.166257],[-84.431214,32.134058],[-84.432254,32.094929]]]},"properties":{"id":"31719","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.357643,32.71235],[-84.33794,32.720647],[-84.286246,32.747626],[-84.304978,32.760619],[-84.341997,32.768765],[-84.357643,32.71235]]],[[[-84.357643,32.71235],[-84.544901,32.591244],[-84.504726,32.545499],[-84.408566,32.59185],[-84.362652,32.598385],[-84.364178,32.679364],[-84.357643,32.71235]]]]},"properties":{"id":"31812","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.357643,32.71235],[-84.364178,32.679364],[-84.33794,32.720647],[-84.357643,32.71235]]],[[[-84.577971,32.72509],[-84.694603,32.583945],[-84.576935,32.591495],[-84.544901,32.591244],[-84.357643,32.71235],[-84.341997,32.768765],[-84.402222,32.812023],[-84.577971,32.72509]]]]},"properties":{"id":"31827","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996359,33.457634],[-82.851954,33.443543],[-82.679975,33.599792],[-82.89234,33.690342],[-82.995602,33.693583],[-83.01015,33.585988],[-82.992958,33.535173],[-82.996359,33.457634]],[[-82.795172,33.558176],[-82.804688,33.557638],[-82.804505,33.563999],[-82.795172,33.558176]]]},"properties":{"id":"30631","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.354316,31.724031],[-84.353053,31.714235],[-84.343267,31.713658],[-84.354316,31.724031]]]},"properties":{"id":"39885","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.236518,33.129559],[-85.18897,32.8909],[-85.020288,32.916052],[-85.039416,33.123302],[-85.074222,33.144015],[-85.236518,33.129559]]]},"properties":{"id":"30240","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.598953,33.736486],[-83.55832,33.840674],[-83.587365,33.882936],[-83.591931,33.881372],[-83.810443,33.831711],[-83.818477,33.75882],[-83.751228,33.692969],[-83.611999,33.720697],[-83.598953,33.736486]]]},"properties":{"id":"30655","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.675891,33.068361],[-82.674853,32.911026],[-82.627145,32.896682],[-82.549718,33.016999],[-82.60371,33.044643],[-82.661922,33.126311],[-82.675891,33.068361]]]},"properties":{"id":"31018","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.032419,31.622276],[-82.132867,31.552139],[-82.132794,31.471262],[-82.041129,31.373721],[-81.938345,31.403133],[-81.940267,31.553741],[-82.032419,31.622276]]]},"properties":{"id":"31560","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.453572,32.097608],[-83.356486,31.846509],[-83.177469,31.847857],[-83.294495,32.038777],[-83.337313,32.105885],[-83.4141,32.133171],[-83.453572,32.097608]]]},"properties":{"id":"31001","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.610896,31.95948],[-83.59985,31.960579],[-83.599767,31.96491],[-83.610741,31.967619],[-83.610896,31.95948]]]},"properties":{"id":"31084","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.77137,31.4886],[-83.78619,31.61186],[-83.82578,31.57309],[-83.77137,31.4886]]]},"properties":{"id":"31781","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.90435,31.6932],[-83.986618,31.769644],[-84.018404,31.650274],[-83.983778,31.658012],[-83.90435,31.6932]]]},"properties":{"id":"31772","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.094244,31.853645],[-82.208478,31.588879],[-82.132867,31.552139],[-82.032419,31.622276],[-81.986299,31.78708],[-82.094244,31.853645]]]},"properties":{"id":"31555","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.388819,34.361604],[-83.321826,34.26519],[-83.287414,34.254286],[-83.178095,34.301411],[-83.187602,34.328656],[-83.187248,34.410895],[-83.267785,34.449187],[-83.285696,34.459866],[-83.399326,34.42618],[-83.388819,34.361604]]]},"properties":{"id":"30521","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.874678,34.082014],[-83.833011,34.090426],[-83.862352,34.084997],[-83.874678,34.082014]]],[[[-83.797699,34.055602],[-83.875867,34.054899],[-83.832718,33.967645],[-83.824235,33.964167],[-83.797699,34.055602]]]]},"properties":{"id":"30011","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.772473,34.165908],[-83.815707,34.174093],[-83.848401,34.139223],[-83.858486,34.103816],[-83.862352,34.084997],[-83.833011,34.090426],[-83.723441,34.14256],[-83.772473,34.165908]]]},"properties":{"id":"30517","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.571497,34.032647],[-83.635676,33.904154],[-83.591931,33.881372],[-83.587365,33.882936],[-83.53241,34.005893],[-83.571497,34.032647]]]},"properties":{"id":"30666","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.621163,34.131291],[-84.657881,34.142471],[-84.725653,34.164781],[-84.712899,34.088064],[-84.608341,34.064465],[-84.553602,34.075999],[-84.621163,34.131291]]]},"properties":{"id":"30102","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.04588,34.143272],[-84.986522,34.076042],[-84.948108,34.025175],[-84.880339,34.082573],[-84.955729,34.137678],[-85.040029,34.182471],[-85.04588,34.143272]]]},"properties":{"id":"30178","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.582658,34.385768],[-84.65981,34.387671],[-84.653405,34.282859],[-84.627085,34.258322],[-84.530363,34.381026],[-84.582658,34.385768]]]},"properties":{"id":"30183","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.087595,34.401315],[-85.382623,34.197285],[-85.251814,34.139379],[-85.158446,34.195428],[-85.068846,34.146802],[-85.04588,34.143272],[-85.040029,34.182471],[-85.046191,34.340479],[-85.035759,34.380086],[-85.087595,34.401315]]]},"properties":{"id":"30161","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.71305,32.825758],[-83.674501,32.902937],[-83.690013,32.913069],[-83.72295,32.977328],[-83.816636,32.953552],[-83.816618,32.953412],[-83.733149,32.833191],[-83.71305,32.825758]]]},"properties":{"id":"31210","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.755128,32.796979],[-83.602563,32.719236],[-83.60236,32.741267],[-83.60051,32.75974],[-83.653811,32.819995],[-83.71305,32.825758],[-83.733149,32.833191],[-83.754746,32.831525],[-83.755128,32.796979]]]},"properties":{"id":"31206","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.26419,32.480238],[-83.203822,32.32696],[-83.098637,32.348188],[-83.136392,32.418281],[-83.189502,32.473706],[-83.26419,32.480238]]]},"properties":{"id":"31012","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.866683,31.219701],[-81.714772,31.247296],[-81.658637,31.408163],[-81.655818,31.411972],[-81.938345,31.403133],[-82.041129,31.373721],[-81.897151,31.298155],[-81.866683,31.219701]]]},"properties":{"id":"31543","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.578612,30.917515],[-83.586748,30.916713],[-83.659724,30.915511],[-83.742762,30.712831],[-83.743729,30.658396],[-83.357703,30.63736],[-83.43759,30.814363],[-83.408444,30.88716],[-83.44031,30.904794],[-83.462423,30.946898],[-83.578612,30.917515]]]},"properties":{"id":"31643","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.699446,30.859711],[-83.742762,30.712831],[-83.659724,30.915511],[-83.737523,30.900014],[-83.699446,30.859711]]]},"properties":{"id":"31629","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.533579,32.098703],[-81.542066,32.239523],[-81.748683,32.271251],[-81.805672,32.216804],[-81.781429,32.148407],[-81.533579,32.098703]]]},"properties":{"id":"31321","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.851654,32.521819],[-81.736947,32.39932],[-81.531392,32.43885],[-81.528827,32.444006],[-81.548006,32.489286],[-81.586748,32.512376],[-81.778151,32.610137],[-81.840854,32.649138],[-81.854995,32.626852],[-81.851654,32.521819]]]},"properties":{"id":"30461","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.025284,32.168901],[-82.01676,32.167153],[-82.019977,32.160247],[-82.027813,32.111835],[-81.961193,32.07481],[-81.857513,32.049847],[-81.781429,32.148407],[-81.805672,32.216804],[-81.820573,32.238447],[-81.969069,32.268783],[-82.025339,32.278883],[-82.025284,32.168901]],[[-81.938364,32.170648],[-81.935045,32.16885],[-81.934198,32.162916],[-81.941665,32.169905],[-81.938364,32.170648]],[[-81.83929,32.14987],[-81.835763,32.155517],[-81.82022,32.145007],[-81.841601,32.143437],[-81.83929,32.14987]]]},"properties":{"id":"30417","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.647228,31.643617],[-84.664053,31.531526],[-84.598313,31.463541],[-84.539398,31.621193],[-84.546854,31.621199],[-84.647228,31.643617]]]},"properties":{"id":"39866","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.565121,30.874153],[-81.682906,30.955445],[-81.815393,30.925432],[-81.793026,30.906795],[-81.695837,30.822499],[-81.601269,30.839408],[-81.565121,30.874153]]]},"properties":{"id":"31569","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.18319,33.465234],[-85.200584,33.61475],[-85.33823,33.653117],[-85.290139,33.409422],[-85.18319,33.465234]]]},"properties":{"id":"30108","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.235341,34.865805],[-85.279313,34.790888],[-85.144789,34.767639],[-85.134279,34.809302],[-85.235341,34.865805]]]},"properties":{"id":"30739","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.013134,32.012013],[-81.031105,32.019409],[-81.094652,32.024423],[-81.120696,32.016291],[-81.140567,32.002831],[-81.146895,31.999455],[-81.107087,31.919232],[-81.013134,32.012013]]]},"properties":{"id":"31406","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.657874,32.343577],[-84.849604,32.230838],[-84.795383,32.197595],[-84.754773,32.189997],[-84.65892,32.232852],[-84.657874,32.343577]]]},"properties":{"id":"31805","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.542505,34.662441],[-85.494844,34.440435],[-85.398508,34.589249],[-85.428429,34.607208],[-85.464107,34.689612],[-85.542505,34.662441]]]},"properties":{"id":"30731","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.365204,34.584665],[-85.26239,34.526385],[-85.21614,34.649244],[-85.365204,34.584665]]]},"properties":{"id":"30753","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.327509,34.075488],[-84.261875,34.088306],[-84.215377,34.12321],[-84.273452,34.228105],[-84.359066,34.158195],[-84.379295,34.118763],[-84.359657,34.077773],[-84.327509,34.075488]]]},"properties":{"id":"30004","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.530363,34.381026],[-84.479794,34.3697],[-84.374504,34.380782],[-84.395044,34.424948],[-84.366018,34.428333],[-84.32205,34.419663],[-84.259448,34.46734],[-84.303842,34.57556],[-84.380348,34.548898],[-84.591662,34.463108],[-84.590508,34.436284],[-84.582658,34.385768],[-84.530363,34.381026]]]},"properties":{"id":"30143","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.503514,33.998809],[-83.412126,33.971935],[-83.384964,34.037777],[-83.463633,34.082023],[-83.51459,34.002661],[-83.503514,33.998809]]]},"properties":{"id":"30607","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.14113,31.780463],[-85.045495,31.517129],[-84.975312,31.494626],[-84.921994,31.593347],[-84.958864,31.734606],[-85.054651,31.747742],[-85.14113,31.780463]]]},"properties":{"id":"39851","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.975312,31.494626],[-84.819807,31.501192],[-84.862896,31.586655],[-84.921994,31.593347],[-84.975312,31.494626]]]},"properties":{"id":"39824","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.943351,30.960309],[-82.851295,30.836303],[-82.793296,30.983016],[-82.943351,30.960309]]]},"properties":{"id":"31630","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.506449,33.950766],[-84.405177,33.943734],[-84.383389,33.963253],[-84.383329,33.992804],[-84.394352,34.002654],[-84.506449,33.950766]]]},"properties":{"id":"30068","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.53889,34.075836],[-84.553602,34.075999],[-84.608341,34.064465],[-84.675536,34.034403],[-84.581409,33.993888],[-84.577382,33.990582],[-84.569275,33.991806],[-84.568022,33.990528],[-84.52815,34.068214],[-84.53889,34.075836]]]},"properties":{"id":"30144","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.475617,31.031964],[-83.397381,31.044221],[-83.389092,31.052284],[-83.317322,31.09563],[-83.335456,31.221382],[-83.521384,31.192876],[-83.55637,31.134178],[-83.574857,31.078135],[-83.475617,31.031964]]]},"properties":{"id":"31620","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.389092,31.052284],[-83.397381,31.044221],[-83.397904,31.029903],[-83.38469,31.044617],[-83.389092,31.052284]]]},"properties":{"id":"31627","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.015358,33.425506],[-84.963981,33.290954],[-84.91774,33.249271],[-84.794732,33.29354],[-84.709499,33.285539],[-84.705643,33.295592],[-84.710386,33.337024],[-84.731645,33.489463],[-84.850713,33.511457],[-84.973902,33.452679],[-85.015358,33.425506]],[[-84.869551,33.432525],[-84.874249,33.434372],[-84.879569,33.444056],[-84.869551,33.432525]]]},"properties":{"id":"30263","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.627067,33.206422],[-84.506012,33.171874],[-84.496783,33.183866],[-84.509075,33.328042],[-84.525209,33.342247],[-84.587077,33.362057],[-84.645294,33.33101],[-84.635369,33.321666],[-84.705643,33.295592],[-84.709499,33.285539],[-84.635725,33.22244],[-84.627067,33.206422]]]},"properties":{"id":"30276","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.635369,33.321666],[-84.645294,33.33101],[-84.643201,33.325884],[-84.635369,33.321666]]]},"properties":{"id":"30289","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.49342,30.892046],[-84.749938,30.775234],[-84.859555,30.753028],[-84.863465,30.711494],[-84.550801,30.697345],[-84.471772,30.802582],[-84.49342,30.892046]]]},"properties":{"id":"39819","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.36698,33.752629],[-84.359831,33.717052],[-84.346205,33.68044],[-84.314956,33.680289],[-84.30795,33.679612],[-84.305634,33.715279],[-84.309672,33.739215],[-84.349175,33.753552],[-84.36698,33.752629]]]},"properties":{"id":"30316","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.359587,33.764503],[-84.32805,33.790498],[-84.334005,33.803647],[-84.336743,33.805432],[-84.370073,33.792252],[-84.36857,33.782108],[-84.363923,33.771133],[-84.359587,33.764503]]]},"properties":{"id":"30306","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.176795,31.549016],[-84.176975,31.622782],[-84.231467,31.623343],[-84.176795,31.549016]]]},"properties":{"id":"31707","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.864284,33.75006],[-84.904893,33.605225],[-84.90535,33.591329],[-84.83872,33.594592],[-84.776828,33.723231],[-84.864284,33.75006]]]},"properties":{"id":"30187","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.531392,32.43885],[-81.445434,32.275413],[-81.415796,32.191684],[-81.3829,32.167088],[-81.368525,32.160041],[-81.366922,32.159275],[-81.284699,32.173049],[-81.277532,32.178306],[-81.24925,32.198648],[-81.316086,32.316884],[-81.498674,32.500014],[-81.528827,32.444006],[-81.531392,32.43885]]]},"properties":{"id":"31312","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.205117,32.427234],[-81.389205,32.595416],[-81.392192,32.527667],[-81.205117,32.427234]]]},"properties":{"id":"31303","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.036163,34.098907],[-82.779506,33.971124],[-82.564491,33.955733],[-82.77341,34.288864],[-82.919779,34.244855],[-82.999884,34.126047],[-83.03104,34.113309],[-83.036163,34.098907]]]},"properties":{"id":"30635","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.724422,32.363576],[-82.655486,32.297561],[-82.59827,32.320441],[-82.470023,32.343032],[-82.393725,32.395991],[-82.335039,32.408062],[-82.325902,32.425814],[-82.49055,32.496502],[-82.683497,32.460818],[-82.724422,32.363576]]]},"properties":{"id":"30457","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.371509,32.463486],[-82.346573,32.491262],[-82.373173,32.502913],[-82.380916,32.485135],[-82.371509,32.463486]]]},"properties":{"id":"30448","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.961193,32.07481],[-82.114914,31.900982],[-82.094244,31.853645],[-81.986299,31.78708],[-81.838571,31.666854],[-81.821022,31.905458],[-81.857513,32.049847],[-81.961193,32.07481]]]},"properties":{"id":"30427","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.358891,34.973939],[-84.286803,34.889129],[-84.12944,34.987545],[-84.359363,34.988269],[-84.358891,34.973939]]]},"properties":{"id":"30559","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.12944,34.987545],[-84.286803,34.889129],[-84.195137,34.798193],[-84.14261,34.821977],[-84.105035,34.987443],[-84.12944,34.987545]]]},"properties":{"id":"30560","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.819604,34.913834],[-84.000341,34.987247],[-84.105035,34.987443],[-84.14261,34.821977],[-83.939007,34.740859],[-83.829615,34.739627],[-83.780401,34.792893],[-83.819604,34.913834]]]},"properties":{"id":"30512","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.568564,33.525386],[-84.563535,33.445934],[-84.547599,33.422407],[-84.384772,33.458713],[-84.427556,33.517155],[-84.472614,33.548153],[-84.523742,33.536481],[-84.531344,33.534487],[-84.568564,33.525386]]]},"properties":{"id":"30214","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.208927,33.990217],[-84.214063,34.0039],[-84.262849,33.973676],[-84.264729,33.965159],[-84.260789,33.932802],[-84.189686,33.970677],[-84.208927,33.990217]]]},"properties":{"id":"30092","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.905845,30.743896],[-83.997174,30.928448],[-84.077177,30.882122],[-84.254119,30.684261],[-83.93502,30.66838],[-83.905845,30.743896]]]},"properties":{"id":"31792","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.478955,33.867831],[-83.451904,33.73846],[-83.330685,33.674881],[-83.302178,33.725886],[-83.28034,33.761774],[-83.28248,33.78816],[-83.299691,33.831471],[-83.376712,33.890433],[-83.464487,33.906612],[-83.478955,33.867831]]]},"properties":{"id":"30677","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.208927,33.990217],[-84.189686,33.970677],[-84.159179,33.936339],[-84.127719,33.929804],[-84.089572,33.963713],[-84.094404,33.968914],[-84.208927,33.990217]]]},"properties":{"id":"30096","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.089572,33.963713],[-84.127719,33.929804],[-84.121941,33.921824],[-84.059999,33.886664],[-84.00719,33.898054],[-84.013798,33.922183],[-84.042694,33.958824],[-84.089572,33.963713]]]},"properties":{"id":"30044","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.001456,33.893474],[-83.912865,33.898698],[-83.843184,33.894775],[-83.948044,33.982593],[-84.013798,33.922183],[-84.00719,33.898054],[-84.001456,33.893474]]]},"properties":{"id":"30045","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.224865,33.8929],[-84.173287,33.887736],[-84.121941,33.921824],[-84.127719,33.929804],[-84.159179,33.936339],[-84.237756,33.904859],[-84.224865,33.8929]]]},"properties":{"id":"30093","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.865537,34.225384],[-83.933171,34.230543],[-83.955917,34.23648],[-84.008469,34.191711],[-83.946742,34.143218],[-83.875359,34.113847],[-83.848401,34.139223],[-83.815707,34.174093],[-83.865537,34.225384]]]},"properties":{"id":"30542","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.912792,32.607629],[-84.998616,32.696171],[-85.091548,32.660992],[-85.080784,32.608072],[-84.960893,32.583236],[-84.911074,32.582915],[-84.912792,32.607629]]]},"properties":{"id":"31808","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.676334,32.618313],[-83.708957,32.632962],[-83.703226,32.622121],[-83.689825,32.617786],[-83.676334,32.618313]]]},"properties":{"id":"31028","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.672002,32.399617],[-83.846844,32.357739],[-83.847068,32.347398],[-83.848379,32.29097],[-83.693317,32.290124],[-83.672002,32.399617]]]},"properties":{"id":"31025","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.675267,34.142838],[-83.673454,34.053036],[-83.571497,34.032647],[-83.53241,34.005893],[-83.51459,34.002661],[-83.463633,34.082023],[-83.450071,34.138203],[-83.579158,34.194336],[-83.675267,34.142838]]]},"properties":{"id":"30549","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.643879,33.549042],[-83.734113,33.481471],[-83.593,33.469604],[-83.591487,33.514037],[-83.643879,33.549042]]]},"properties":{"id":"30056","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.836525,31.779692],[-82.762461,31.671751],[-82.644971,31.672598],[-82.836525,31.779692]]]},"properties":{"id":"31532","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.320435,32.840033],[-82.400726,32.975015],[-82.482027,32.786734],[-82.434149,32.762258],[-82.417224,32.770403],[-82.320435,32.840033]]]},"properties":{"id":"30477","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.702301,33.052071],[-83.72295,32.977328],[-83.690013,32.913069],[-83.501789,32.930725],[-83.404174,32.973606],[-83.521412,33.128976],[-83.702301,33.052071]]]},"properties":{"id":"31032","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.944432,32.45031],[-82.939057,32.242837],[-82.931249,32.227594],[-82.830558,32.257734],[-82.790401,32.392547],[-82.806038,32.407068],[-82.957066,32.708318],[-83.016691,32.683425],[-83.109588,32.64088],[-83.122207,32.634794],[-83.081276,32.570957],[-83.078546,32.468595],[-82.944432,32.45031]]]},"properties":{"id":"31021","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.533492,31.631552],[-81.192565,31.578783],[-81.199436,31.658332],[-81.593083,31.785248],[-81.575012,31.729223],[-81.533492,31.631552]]]},"properties":{"id":"31323","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.575426,31.873829],[-81.632811,31.895356],[-81.668753,31.859017],[-81.575426,31.873829]]]},"properties":{"id":"31314","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.459194,33.886189],[-82.594553,33.828429],[-82.543192,33.638828],[-82.425328,33.650629],[-82.218649,33.686299],[-82.459194,33.886189]]]},"properties":{"id":"30817","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.994824,34.679638],[-83.864368,34.560524],[-83.832754,34.505341],[-83.701343,34.503909],[-83.666413,34.503598],[-83.616427,34.547307],[-83.641998,34.627566],[-83.777129,34.673608],[-83.829615,34.739627],[-83.939007,34.740859],[-83.994824,34.679638]]]},"properties":{"id":"30528","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.339631,31.446312],[-81.443185,31.357475],[-81.338988,31.441793],[-81.339631,31.446312]]]},"properties":{"id":"31305","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.018147,32.506406],[-84.025029,32.432868],[-84.026122,32.373269],[-83.847068,32.347398],[-83.846844,32.357739],[-83.846676,32.468514],[-84.018147,32.506406]]]},"properties":{"id":"31057","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.52691,32.542507],[-84.576935,32.591495],[-84.694603,32.583945],[-84.703322,32.583811],[-84.701495,32.53665],[-84.657568,32.437286],[-84.57201,32.449264],[-84.52691,32.542507]]]},"properties":{"id":"31801","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.68228,34.988181],[-84.622158,34.925715],[-84.621483,34.988329],[-84.68228,34.988181]]]},"properties":{"id":"30708","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.931512,33.650879],[-83.851945,33.735159],[-83.918235,33.721955],[-83.931512,33.650879]]]},"properties":{"id":"30054","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.17118,33.948507],[-83.216456,33.837665],[-83.166792,33.842899],[-83.090977,33.973933],[-83.133293,33.986254],[-83.17118,33.948507]]]},"properties":{"id":"30630","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.421859,34.99415],[-83.482779,34.993256],[-83.407532,34.917339],[-83.342956,34.930632],[-83.324401,34.941532],[-83.421859,34.99415]]]},"properties":{"id":"30568","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.098289,33.421192],[-81.925427,33.25589],[-81.852076,33.247288],[-81.946752,33.367509],[-82.006727,33.447955],[-82.047764,33.44826],[-82.117868,33.433335],[-82.098289,33.421192]],[[-82.028863,33.371628],[-82.024347,33.36596],[-82.030254,33.362709],[-82.031606,33.366836],[-82.028863,33.371628]]]},"properties":{"id":"30906","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.924893,32.222312],[-85.048841,32.022215],[-84.873708,32.204504],[-84.924893,32.222312]]]},"properties":{"id":"31821","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.576935,32.591495],[-84.52691,32.542507],[-84.504726,32.545499],[-84.544901,32.591244],[-84.576935,32.591495]]]},"properties":{"id":"31810","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.865396,31.901237],[-82.9691,31.925854],[-83.080712,31.851733],[-83.10131,31.811955],[-82.995698,31.780983],[-82.836366,31.816154],[-82.791845,31.847107],[-82.865396,31.901237]]]},"properties":{"id":"31544","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286581,31.784998],[-84.326238,31.80506],[-84.285253,31.842945],[-84.357958,31.85135],[-84.340859,31.799418],[-84.286839,31.769266],[-84.286581,31.784998]]]},"properties":{"id":"39826","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.948386,32.865385],[-82.960561,32.847144],[-82.915254,32.859779],[-82.948386,32.865385]]]},"properties":{"id":"31067","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.777129,34.673608],[-83.641998,34.627566],[-83.681596,34.800593],[-83.714186,34.793112],[-83.777129,34.673608]]]},"properties":{"id":"30571","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.988303,34.899307],[-84.974962,34.893908],[-84.976064,34.900951],[-84.988303,34.899307]]]},"properties":{"id":"30756","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.229212,32.955063],[-83.243461,32.8269],[-83.132955,32.840248],[-83.162436,32.945016],[-83.229212,32.955063]]]},"properties":{"id":"31054","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132955,32.840248],[-83.016691,32.683425],[-82.957066,32.708318],[-82.987358,32.846871],[-83.073648,32.946562],[-83.162436,32.945016],[-83.132955,32.840248]]]},"properties":{"id":"31090","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.784769,31.25313],[-84.556701,31.256699],[-84.610545,31.322291],[-84.775313,31.344738],[-84.784769,31.25313]]]},"properties":{"id":"39841","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42279,32.812021],[-83.42556,32.80185],[-83.282598,32.774748],[-83.243461,32.8269],[-83.229212,32.955063],[-83.35362,32.969527],[-83.380391,32.954568],[-83.42279,32.812021]]]},"properties":{"id":"31031","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.220149,33.048021],[-83.218308,33.043516],[-83.212583,33.043839],[-83.212446,33.047335],[-83.220149,33.048021]]]},"properties":{"id":"31062","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.486041,34.420468],[-83.52619,34.39334],[-83.519992,34.36308],[-83.486831,34.299589],[-83.41166,34.306473],[-83.388819,34.361604],[-83.399326,34.42618],[-83.413188,34.431118],[-83.486041,34.420468]]]},"properties":{"id":"30547","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.669566,34.441448],[-83.52619,34.39334],[-83.486041,34.420468],[-83.561669,34.489694],[-83.669566,34.441448]]]},"properties":{"id":"30510","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.591718,34.349826],[-83.638797,34.235466],[-83.579158,34.194336],[-83.486831,34.299589],[-83.519992,34.36308],[-83.591718,34.349826]]]},"properties":{"id":"30558","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.62819,32.845827],[-83.501789,32.930725],[-83.690013,32.913069],[-83.674501,32.902937],[-83.634476,32.853668],[-83.62819,32.845827]]]},"properties":{"id":"31211","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.131698,31.010714],[-82.080191,31.013907],[-82.056915,31.274729],[-82.104324,31.271402],[-82.163142,31.215996],[-82.152965,31.036014],[-82.131698,31.010714]]]},"properties":{"id":"31542","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.897151,31.298155],[-82.056915,31.274729],[-82.080191,31.013907],[-81.936006,31.047993],[-81.91301,31.096142],[-81.866683,31.219701],[-81.897151,31.298155]]]},"properties":{"id":"31553","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.408444,30.88716],[-83.43759,30.814363],[-83.290636,30.846894],[-83.292815,30.851028],[-83.287959,30.851401],[-83.286948,30.846511],[-83.229129,30.849255],[-83.309072,30.917935],[-83.408444,30.88716]]]},"properties":{"id":"31602","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.287771,31.954223],[-81.309001,31.982537],[-81.404926,31.936868],[-81.39363,31.880901],[-81.198203,31.725988],[-81.287771,31.954223]]]},"properties":{"id":"31324","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.854995,32.626852],[-82.015889,32.577561],[-81.971917,32.512282],[-81.97453,32.487746],[-81.851654,32.521819],[-81.854995,32.626852]]]},"properties":{"id":"30450","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.309439,33.195541],[-82.281054,33.110354],[-82.273618,33.070805],[-82.016041,33.190081],[-82.217245,33.242216],[-82.241523,33.234405],[-82.309439,33.195541]]]},"properties":{"id":"30816","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.154352,33.357906],[-82.217245,33.242216],[-82.016041,33.190081],[-81.925427,33.25589],[-82.098289,33.421192],[-82.144635,33.390788],[-82.154352,33.357906]]]},"properties":{"id":"30815","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.939984,33.20199],[-84.017364,33.164486],[-84.043765,32.966004],[-83.978167,32.855537],[-83.891685,32.864644],[-83.816618,32.953412],[-83.816636,32.953552],[-83.816048,33.131816],[-83.822261,33.180238],[-83.939984,33.20199]]]},"properties":{"id":"31029","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.654268,30.744351],[-81.601269,30.839408],[-81.695837,30.822499],[-81.831516,30.808232],[-81.890144,30.814862],[-81.654268,30.744351]]]},"properties":{"id":"31548","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.211921,32.246915],[-82.068573,32.287222],[-82.232788,32.318838],[-82.211921,32.246915]]]},"properties":{"id":"30420","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.83872,33.594592],[-84.90535,33.591329],[-84.973902,33.452679],[-84.850713,33.511457],[-84.808934,33.574085],[-84.83872,33.594592]]]},"properties":{"id":"30185","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.003418,34.928234],[-85.091084,34.796623],[-85.01143,34.802715],[-84.99493,34.903214],[-85.003418,34.928234]]]},"properties":{"id":"30755","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.264707,34.941522],[-85.229326,34.963876],[-85.2208,34.94533],[-85.195694,34.985778],[-85.327428,34.983976],[-85.338215,34.892746],[-85.264621,34.927422],[-85.264707,34.941522]]]},"properties":{"id":"30741","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.241721,32.145371],[-81.197802,32.074567],[-81.161015,32.070977],[-81.116855,32.104568],[-81.136785,32.128102],[-81.241721,32.145371]]]},"properties":{"id":"31408","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.309001,31.982537],[-81.287771,31.954223],[-81.107087,31.919232],[-81.146895,31.999455],[-81.159193,31.99629],[-81.199041,32.001151],[-81.251664,32.063156],[-81.305068,32.092987],[-81.35588,32.041833],[-81.309001,31.982537]]]},"properties":{"id":"31419","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.199041,32.001151],[-81.159193,31.99629],[-81.140567,32.002831],[-81.120696,32.016291],[-81.199041,32.001151]]]},"properties":{"id":"31409","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.107087,31.919232],[-81.007542,31.973801],[-81.013134,32.012013],[-81.107087,31.919232]]]},"properties":{"id":"31411","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.971962,32.377698],[-84.892931,32.422748],[-84.91564,32.422987],[-84.971962,32.377698]]]},"properties":{"id":"31905","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.516802,34.146138],[-84.360433,34.253793],[-84.479794,34.3697],[-84.530363,34.381026],[-84.627085,34.258322],[-84.657881,34.142471],[-84.621163,34.131291],[-84.525258,34.134645],[-84.516802,34.146138]]]},"properties":{"id":"30114","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.503514,33.998809],[-83.464487,33.906612],[-83.376712,33.890433],[-83.388302,33.954923],[-83.412126,33.971935],[-83.503514,33.998809]]]},"properties":{"id":"30606","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.343306,34.053552],[-83.274778,34.006192],[-83.248463,34.005589],[-83.307621,34.100199],[-83.297825,34.165549],[-83.357193,34.122524],[-83.343306,34.053552]]]},"properties":{"id":"30646","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.422669,33.52543],[-84.37664,33.536999],[-84.404391,33.605481],[-84.425216,33.58817],[-84.422669,33.52543]]]},"properties":{"id":"30274","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.313819,33.439414],[-84.321506,33.440582],[-84.320562,33.432246],[-84.313819,33.439414]]]},"properties":{"id":"30250","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.597767,33.815277],[-84.533546,33.779122],[-84.515411,33.790334],[-84.498731,33.804805],[-84.488964,33.81282],[-84.48757,33.823449],[-84.489661,33.825236],[-84.578481,33.848823],[-84.597767,33.815277]]]},"properties":{"id":"30126","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.831768,33.838398],[-84.76685,33.816056],[-84.724031,33.913372],[-84.778664,33.909172],[-84.779978,33.899445],[-84.831768,33.838398]]]},"properties":{"id":"30141","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.724031,33.913372],[-84.734543,33.968195],[-84.755223,34.010247],[-84.778664,33.909172],[-84.724031,33.913372]]],[[[-85.004305,33.87718],[-84.896879,33.810313],[-84.870744,33.7779],[-84.831768,33.838398],[-84.779978,33.899445],[-84.961996,33.972924],[-85.004305,33.87718]]]]},"properties":{"id":"30157","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.789529,31.504994],[-82.963994,31.555775],[-82.983364,31.450931],[-82.849617,31.403189],[-82.817174,31.364484],[-82.678682,31.373391],[-82.789529,31.504994]]]},"properties":{"id":"31535","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.773415,31.213894],[-83.820279,31.072105],[-83.800953,31.038832],[-83.600108,31.034677],[-83.574857,31.078135],[-83.55637,31.134178],[-83.587394,31.168946],[-83.594673,31.180428],[-83.773415,31.213894]],[[-83.630969,31.068476],[-83.630273,31.079069],[-83.608608,31.061018],[-83.631082,31.061652],[-83.630969,31.068476]]]},"properties":{"id":"31788","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.594673,31.180428],[-83.587394,31.168946],[-83.583126,31.182095],[-83.594673,31.180428]]]},"properties":{"id":"31747","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.157897,33.504648],[-82.075921,33.578288],[-82.164094,33.611145],[-82.253727,33.523331],[-82.157897,33.504648]]]},"properties":{"id":"30809","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.771657,33.141835],[-84.91774,33.249271],[-84.963981,33.290954],[-85.074222,33.144015],[-85.039416,33.123302],[-84.863448,33.082057],[-84.771657,33.141835]]]},"properties":{"id":"30230","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.688346,32.686732],[-83.776874,32.718809],[-83.871539,32.699845],[-83.71918,32.595823],[-83.703289,32.614904],[-83.703226,32.622121],[-83.708957,32.632962],[-83.688346,32.686732]]]},"properties":{"id":"31008","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.610896,31.95948],[-83.55751,31.853278],[-83.534478,31.84766],[-83.58789,32.085977],[-83.608918,32.080415],[-83.628533,32.028041],[-83.610741,31.967619],[-83.599767,31.96491],[-83.59985,31.960579],[-83.610896,31.95948]]]},"properties":{"id":"31072","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.26957,34.415652],[-84.259448,34.46734],[-84.32205,34.419663],[-84.26957,34.415652]]]},"properties":{"id":"30148","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.314796,33.920211],[-84.289521,33.915982],[-84.28272,33.956234],[-84.347725,33.9603],[-84.347857,33.928757],[-84.327721,33.920361],[-84.314796,33.920211]]]},"properties":{"id":"30338","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.314796,33.920211],[-84.309451,33.865356],[-84.247985,33.861091],[-84.245618,33.870725],[-84.278279,33.904832],[-84.289521,33.915982],[-84.314796,33.920211]]]},"properties":{"id":"30341","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.309451,33.865356],[-84.311889,33.858044],[-84.302983,33.82134],[-84.261953,33.84423],[-84.247985,33.861091],[-84.309451,33.865356]]]},"properties":{"id":"30345","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.232497,33.763756],[-84.233832,33.78671],[-84.246273,33.786937],[-84.245595,33.783142],[-84.270427,33.756696],[-84.281287,33.747975],[-84.309672,33.739215],[-84.305634,33.715279],[-84.241017,33.714815],[-84.232497,33.763756]]]},"properties":{"id":"30032","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.170226,33.703504],[-84.088596,33.700435],[-84.023713,33.752808],[-84.058204,33.772767],[-84.163176,33.772211],[-84.171483,33.723662],[-84.170226,33.703504]]]},"properties":{"id":"30058","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347933,33.914999],[-84.327721,33.920361],[-84.347857,33.928757],[-84.347933,33.914999]]]},"properties":{"id":"30346","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347725,33.9603],[-84.28272,33.956234],[-84.264729,33.965159],[-84.262849,33.973676],[-84.280307,33.977598],[-84.333156,34.009652],[-84.383329,33.992804],[-84.383389,33.963253],[-84.347725,33.9603]]]},"properties":{"id":"30350","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.986631,32.149321],[-82.999471,32.074765],[-82.962452,32.049081],[-82.827792,32.056078],[-82.820784,32.05812],[-82.986631,32.149321]]]},"properties":{"id":"31037","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.875099,32.147239],[-83.886774,32.166065],[-83.907422,32.154487],[-83.875099,32.147239]]]},"properties":{"id":"31051","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.176975,31.622782],[-84.176795,31.549016],[-84.141124,31.440179],[-84.137769,31.442677],[-84.101445,31.618575],[-84.176975,31.622782]]]},"properties":{"id":"31701","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.776828,33.723231],[-84.83872,33.594592],[-84.808934,33.574085],[-84.735712,33.624613],[-84.654607,33.662858],[-84.630117,33.693116],[-84.605037,33.717306],[-84.680749,33.760656],[-84.776828,33.723231]]]},"properties":{"id":"30135","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.017465,30.732354],[-83.038848,30.694758],[-83.012521,30.690376],[-83.017465,30.732354]]]},"properties":{"id":"31648","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.538549,32.274486],[-82.463608,32.09041],[-82.379516,32.035144],[-82.393725,32.395991],[-82.470023,32.343032],[-82.538549,32.274486]]]},"properties":{"id":"30474","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.531473,34.988126],[-84.453073,34.859469],[-84.260918,34.721645],[-84.196754,34.617924],[-84.188557,34.602692],[-84.158035,34.648243],[-84.195137,34.798193],[-84.286803,34.889129],[-84.358891,34.973939],[-84.388,34.96702],[-84.431882,34.948308],[-84.488389,34.988167],[-84.531473,34.988126]]]},"properties":{"id":"30513","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.62614,33.439037],[-84.587077,33.362057],[-84.525209,33.342247],[-84.547599,33.422407],[-84.563535,33.445934],[-84.62614,33.439037]]]},"properties":{"id":"30269","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.272325,34.331444],[-85.260074,34.299171],[-85.215677,34.303058],[-85.220007,34.333411],[-85.272325,34.331444]]]},"properties":{"id":"30149","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.975603,34.408593],[-84.0288,34.306905],[-84.0213,34.194075],[-84.008469,34.191711],[-83.955917,34.23648],[-83.879821,34.321946],[-83.741659,34.378662],[-83.764757,34.449229],[-83.847449,34.451901],[-83.975603,34.408593]]]},"properties":{"id":"30506","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.168152,34.067895],[-84.176219,34.082313],[-84.210548,34.129592],[-84.215377,34.12321],[-84.261875,34.088306],[-84.272117,34.06866],[-84.200659,34.055993],[-84.168152,34.067895]]]},"properties":{"id":"30005","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.07401,34.001354],[-84.168152,34.067895],[-84.200659,34.055993],[-84.214063,34.0039],[-84.208927,33.990217],[-84.094404,33.968914],[-84.07401,34.001354]]]},"properties":{"id":"30097","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.390586,33.781528],[-84.36857,33.782108],[-84.370073,33.792252],[-84.382503,33.813412],[-84.402959,33.818461],[-84.39979,33.793601],[-84.395029,33.797344],[-84.391098,33.791655],[-84.390586,33.781528]]]},"properties":{"id":"30309","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.347933,33.914999],[-84.347857,33.928757],[-84.347725,33.9603],[-84.383389,33.963253],[-84.405177,33.943734],[-84.442823,33.9017],[-84.414167,33.915865],[-84.392521,33.913169],[-84.347933,33.914999]]]},"properties":{"id":"30328","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.404461,33.744347],[-84.390548,33.762273],[-84.391142,33.781533],[-84.401339,33.778369],[-84.405626,33.776026],[-84.401673,33.763421],[-84.407545,33.743481],[-84.404461,33.744347]]]},"properties":{"id":"30313","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.401339,33.778369],[-84.402714,33.780565],[-84.40753,33.778604],[-84.405626,33.776026],[-84.401339,33.778369]]]},"properties":{"id":"30332","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.489596,33.561499],[-84.502023,33.56593],[-84.523742,33.536481],[-84.472614,33.548153],[-84.489596,33.561499]]],[[[-84.735712,33.624613],[-84.594726,33.518707],[-84.568564,33.525386],[-84.531344,33.534487],[-84.584625,33.60787],[-84.640658,33.664443],[-84.654607,33.662858],[-84.735712,33.624613]]]]},"properties":{"id":"30213","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.383746,33.749],[-84.387563,33.75124],[-84.387708,33.7469],[-84.383746,33.749]]]},"properties":{"id":"30334","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996359,33.457634],[-82.992958,33.535173],[-83.039364,33.547581],[-83.072624,33.530861],[-83.178101,33.380268],[-83.164207,33.35503],[-82.996359,33.457634]]]},"properties":{"id":"30678","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.526529,34.54817],[-83.486581,34.498819],[-83.451243,34.498535],[-83.425789,34.61561],[-83.513537,34.589468],[-83.526529,34.54817]]]},"properties":{"id":"30563","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.070691,33.88269],[-85.150546,33.905578],[-85.351861,33.901241],[-85.216537,33.765581],[-85.061559,33.826636],[-85.070691,33.88269]]]},"properties":{"id":"30113","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.672148,32.765686],[-84.580013,32.845149],[-84.700538,32.84464],[-84.757797,32.846753],[-84.785094,32.716616],[-84.757221,32.714827],[-84.672148,32.765686]]]},"properties":{"id":"31826","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.653895,32.928203],[-84.693157,32.945397],[-84.821942,32.948918],[-84.809073,32.840651],[-84.757797,32.846753],[-84.700538,32.84464],[-84.653895,32.928203]]]},"properties":{"id":"31830","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.092057,33.583837],[-84.140228,33.525397],[-84.138486,33.377767],[-84.000987,33.394705],[-83.927008,33.452539],[-84.044493,33.525776],[-84.092057,33.583837]]]},"properties":{"id":"30252","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.589511,32.576784],[-83.661979,32.552033],[-83.517935,32.498908],[-83.536539,32.542661],[-83.589511,32.576784]]]},"properties":{"id":"31005","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.498039,32.401715],[-83.630043,32.428063],[-83.672002,32.399617],[-83.693317,32.290124],[-83.608079,32.159456],[-83.607997,32.148118],[-83.4141,32.133171],[-83.337313,32.105885],[-83.346528,32.272489],[-83.498039,32.401715]]]},"properties":{"id":"31036","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.536402,31.543437],[-83.420699,31.553567],[-83.417922,31.63107],[-83.49057,31.676699],[-83.584664,31.595701],[-83.536402,31.543437]]]},"properties":{"id":"31733","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.736055,33.559972],[-83.829306,33.406774],[-83.727019,33.438991],[-83.734113,33.481471],[-83.643879,33.549042],[-83.674401,33.605114],[-83.736055,33.559972]]]},"properties":{"id":"30055","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.851945,33.735159],[-83.931512,33.650879],[-83.951533,33.628898],[-83.891602,33.398035],[-83.863058,33.368278],[-83.831545,33.392926],[-83.838357,33.398588],[-83.829306,33.406774],[-83.736055,33.559972],[-83.751228,33.692969],[-83.818477,33.75882],[-83.851945,33.735159]],[[-83.895131,33.575763],[-83.893024,33.574312],[-83.894854,33.572271],[-83.898007,33.572664],[-83.895131,33.575763]]]},"properties":{"id":"30014","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.969881,31.081682],[-83.032712,31.011013],[-83.019419,30.849453],[-83.001246,30.860236],[-82.9705,30.94981],[-82.969881,31.081682]]]},"properties":{"id":"31649","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.012286,32.186759],[-82.931249,32.227594],[-82.939057,32.242837],[-83.027205,32.380423],[-83.098637,32.348188],[-83.028243,32.216737],[-83.012286,32.186759]]]},"properties":{"id":"31009","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.887374,33.902993],[-82.853736,33.835875],[-82.594553,33.828429],[-82.459194,33.886189],[-82.564491,33.955733],[-82.779506,33.971124],[-82.887374,33.902993]]]},"properties":{"id":"30668","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.220325,30.7339],[-83.229129,30.849255],[-83.286948,30.846511],[-83.290636,30.846894],[-83.43759,30.814363],[-83.357703,30.63736],[-83.309249,30.634406],[-83.265941,30.631834],[-83.220325,30.7339]]]},"properties":{"id":"31601","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.309072,30.917935],[-83.229129,30.849255],[-83.18441,30.905824],[-83.179904,30.992073],[-83.180622,31.025488],[-83.246372,30.991098],[-83.309072,30.917935]],[[-83.212738,30.971043],[-83.207807,30.981063],[-83.197486,30.971591],[-83.212738,30.971043]]]},"properties":{"id":"31605","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.090977,33.973933],[-82.946795,33.842674],[-82.887374,33.902993],[-82.779506,33.971124],[-83.036163,34.098907],[-83.090977,33.973933]]]},"properties":{"id":"30627","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.146732,34.181924],[-83.168542,34.06551],[-83.133293,33.986254],[-83.090977,33.973933],[-83.036163,34.098907],[-83.03104,34.113309],[-83.146732,34.181924]]]},"properties":{"id":"30629","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.307621,34.100199],[-83.248463,34.005589],[-83.200605,33.946852],[-83.17118,33.948507],[-83.133293,33.986254],[-83.168542,34.06551],[-83.307621,34.100199]]]},"properties":{"id":"30628","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.820646,31.070609],[-84.789988,30.873627],[-84.730855,31.06919],[-84.820646,31.070609]]]},"properties":{"id":"39859","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.001629,31.225736],[-84.000127,31.298227],[-84.028629,31.311573],[-84.07407,31.198747],[-84.053541,31.198972],[-84.001957,31.209893],[-84.001629,31.225736]]]},"properties":{"id":"31784","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.818715,34.87695],[-84.928222,34.667143],[-84.779475,34.608332],[-84.654523,34.583187],[-84.618637,34.855398],[-84.622752,34.857131],[-84.818715,34.87695]]]},"properties":{"id":"30705","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.835191,34.942949],[-84.976064,34.900951],[-84.974962,34.893908],[-84.995113,34.615852],[-84.928222,34.667143],[-84.818715,34.87695],[-84.835191,34.942949]]]},"properties":{"id":"30721","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.838357,33.398588],[-83.831545,33.392926],[-83.833138,33.39586],[-83.838357,33.398588]]]},"properties":{"id":"30164","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.275933,33.847977],[-83.299691,33.831471],[-83.28248,33.78816],[-83.216456,33.837665],[-83.17118,33.948507],[-83.200605,33.946852],[-83.275933,33.847977]]]},"properties":{"id":"30619","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.366018,34.428333],[-84.395044,34.424948],[-84.374504,34.380782],[-84.366018,34.428333]]]},"properties":{"id":"30177","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.11848,31.419955],[-82.118465,31.412546],[-82.112137,31.409963],[-82.102909,31.413041],[-82.110235,31.419044],[-82.11848,31.419955]]]},"properties":{"id":"31556","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.530385,32.564608],[-81.586748,32.512376],[-81.548006,32.489286],[-81.530385,32.564608]]]},"properties":{"id":"30449","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.287126,33.340345],[-84.287885,33.34443],[-84.292642,33.343708],[-84.291055,33.340775],[-84.287126,33.340345]]]},"properties":{"id":"30284","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.997459,31.977577],[-83.922494,31.909653],[-83.961278,32.030595],[-83.969194,32.03855],[-83.997459,31.977577]]]},"properties":{"id":"31735","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.327428,34.983976],[-85.342458,34.983775],[-85.37199,34.935404],[-85.385639,34.874522],[-85.338215,34.892746],[-85.327428,34.983976]]]},"properties":{"id":"30725","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.661738,33.129438],[-82.848544,33.199368],[-82.948911,33.103611],[-82.675891,33.068361],[-82.661922,33.126311],[-82.661917,33.126331],[-82.661738,33.129438]]]},"properties":{"id":"31094","state_code":"13"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.709956,18.941229],[-155.76766,19.183886],[-155.920811,19.081331],[-155.709956,18.941229]]]},"properties":{"id":"96737","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-154.987674,19.725751],[-155.077214,19.626621],[-155.06724,19.60865],[-155.01372,19.51573],[-154.924046,19.597038],[-154.987674,19.725751]]]},"properties":{"id":"96749","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.706062,20.110589],[-155.490436,19.901496],[-155.437842,19.90303],[-155.395849,20.083089],[-155.695245,20.20051],[-155.706062,20.110589]]]},"properties":{"id":"96727","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.317628,19.840065],[-155.122951,19.905147],[-155.195211,19.96577],[-155.194441,19.955034],[-155.335246,19.862978],[-155.317628,19.840065]]]},"properties":{"id":"96773","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.761186,21.446837],[-157.793688,21.366166],[-157.789071,21.3532],[-157.779156,21.335832],[-157.759048,21.331853],[-157.704839,21.380695],[-157.764155,21.463344],[-157.761776,21.4501],[-157.761186,21.446837]]]},"properties":{"id":"96734","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.142449,21.507733],[-158.279549,21.567325],[-158.145653,21.363037],[-158.093353,21.452993],[-158.093516,21.454351],[-158.142449,21.507733]]]},"properties":{"id":"96792","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.914082,21.174719],[-157.014491,21.188823],[-157.014612,21.182349],[-156.925322,21.135864],[-156.914082,21.174719]]]},"properties":{"id":"96742","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.610287,21.933231],[-159.63788,21.921426],[-159.61831,21.91302],[-159.610287,21.933231]]]},"properties":{"id":"96747","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.412334,22.132476],[-159.304232,22.110888],[-159.302241,22.109307],[-159.297007,22.151796],[-159.332659,22.207901],[-159.412334,22.132476]]]},"properties":{"id":"96703","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.367326,20.937153],[-156.451896,20.90404],[-156.415774,20.904052],[-156.370438,20.87947],[-156.346071,20.918387],[-156.367326,20.937153]]]},"properties":{"id":"96779","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.335451,19.7995],[-155.34131,19.56076],[-155.258244,19.543836],[-155.077214,19.626621],[-154.987674,19.725751],[-155.082441,19.779045],[-155.335451,19.7995]]]},"properties":{"id":"96720","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.923843,19.895644],[-155.967226,19.533776],[-155.93522,19.55021],[-155.97469,19.68677],[-155.828446,19.633608],[-155.725859,19.67253],[-155.818663,19.828869],[-155.904533,19.893689],[-155.923843,19.895644]]]},"properties":{"id":"96740","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.76766,19.183886],[-155.709956,18.941229],[-155.549234,19.078993],[-155.503642,19.379931],[-155.618917,19.421963],[-155.76766,19.183886]]]},"properties":{"id":"96772","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.25305,19.40534],[-155.11929,19.28275],[-154.924046,19.597038],[-155.01372,19.51573],[-155.01426,19.50647],[-155.163988,19.435025],[-155.25305,19.40534]]]},"properties":{"id":"96778","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.904533,19.893689],[-155.818663,19.828869],[-155.80464,19.83106],[-155.904533,19.893689]]]},"properties":{"id":"96738","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.859153,21.428629],[-157.956553,21.375176],[-157.908998,21.368429],[-157.838903,21.401987],[-157.859153,21.428629]]]},"properties":{"id":"96701","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.874952,21.456328],[-157.973465,21.366478],[-157.956553,21.375176],[-157.859153,21.428629],[-157.874952,21.456328]]]},"properties":{"id":"96782","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.891846,21.357755],[-157.888079,21.360077],[-157.89271,21.363153],[-157.89415,21.360896],[-157.891846,21.357755]]]},"properties":{"id":"96859","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.813988,21.292251],[-157.834675,21.301301],[-157.834772,21.288865],[-157.820457,21.284836],[-157.813988,21.292251]]]},"properties":{"id":"96826","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.904263,21.647509],[-157.965396,21.611899],[-157.936069,21.569695],[-157.915248,21.628319],[-157.904263,21.647509]]]},"properties":{"id":"96762","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.941026,21.333744],[-157.937946,21.331376],[-157.935359,21.334736],[-157.9382,21.336115],[-157.941026,21.333744]]]},"properties":{"id":"96853","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.569268,21.920285],[-159.54021,21.896048],[-159.504372,21.899507],[-159.50192,21.92055],[-159.493602,21.929762],[-159.45534,21.96347],[-159.504322,22.050046],[-159.569268,21.920285]]]},"properties":{"id":"96741","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.329529,22.042657],[-159.501137,22.071773],[-159.504322,22.050046],[-159.45534,21.96347],[-159.383198,21.899848],[-159.329529,22.042657]]]},"properties":{"id":"96766","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.25305,19.40534],[-155.163988,19.435025],[-155.258244,19.543836],[-155.34131,19.56076],[-155.602988,19.520541],[-155.641742,19.450836],[-155.618917,19.421963],[-155.503642,19.379931],[-155.355963,19.350491],[-155.25305,19.40534]]]},"properties":{"id":"96785","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.85966,20.19733],[-155.826918,20.213787],[-155.765179,20.141594],[-155.706062,20.110589],[-155.695245,20.20051],[-155.903059,20.233461],[-155.846301,20.046035],[-155.85966,20.19733]]]},"properties":{"id":"96755","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.848701,21.305596],[-157.854995,21.286195],[-157.845489,21.279498],[-157.834772,21.288865],[-157.834675,21.301301],[-157.848701,21.305596]]]},"properties":{"id":"96814","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.838937,21.534934],[-157.834251,21.53702],[-157.858976,21.563151],[-157.838937,21.534934]]]},"properties":{"id":"96730","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.470234,22.166183],[-159.462627,22.228091],[-159.503978,22.215794],[-159.470234,22.166183]]]},"properties":{"id":"96722","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.50192,21.92055],[-159.504372,21.899507],[-159.383198,21.899848],[-159.45534,21.96347],[-159.493602,21.929762],[-159.48681,21.916],[-159.50192,21.92055]]]},"properties":{"id":"96756","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.569268,21.920285],[-159.504322,22.050046],[-159.501137,22.071773],[-159.645277,22.14213],[-159.664395,21.953303],[-159.610287,21.933231],[-159.61831,21.91302],[-159.63788,21.921426],[-159.641688,21.917929],[-159.593633,21.893556],[-159.569268,21.920285]]]},"properties":{"id":"96716","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.080041,21.112625],[-157.261022,21.230883],[-157.102823,21.095408],[-157.080041,21.112625]]]},"properties":{"id":"96770","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.194441,19.955034],[-155.266804,20.010568],[-155.335959,19.912515],[-155.335246,19.862978],[-155.194441,19.955034]]]},"properties":{"id":"96764","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.01426,19.50647],[-155.06724,19.60865],[-155.077214,19.626621],[-155.258244,19.543836],[-155.163988,19.435025],[-155.01426,19.50647]]]},"properties":{"id":"96771","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.317628,19.840065],[-155.33546,19.803457],[-155.244648,19.820994],[-155.157188,19.845911],[-155.104434,19.881055],[-155.122951,19.905147],[-155.317628,19.840065]]]},"properties":{"id":"96710","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.787972,19.474893],[-155.879154,19.446034],[-155.84566,19.39748],[-155.75381,19.40663],[-155.787972,19.474893]]]},"properties":{"id":"96726","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.813988,21.292251],[-157.820457,21.284836],[-157.810981,21.251219],[-157.759573,21.271939],[-157.758103,21.331286],[-157.759048,21.331853],[-157.779156,21.335832],[-157.813988,21.292251]]]},"properties":{"id":"96816","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.803456,21.345032],[-157.870033,21.296257],[-157.869505,21.293667],[-157.854995,21.286195],[-157.848701,21.305596],[-157.803456,21.345032]],[[-157.863692,21.304911],[-157.86119,21.303436],[-157.863266,21.301994],[-157.863692,21.304911]]]},"properties":{"id":"96813","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.970755,21.338039],[-158.032161,21.38362],[-158.044441,21.301835],[-157.968354,21.321032],[-157.970755,21.338039]]]},"properties":{"id":"96706","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.031398,21.694871],[-158.122048,21.587527],[-158.106493,21.575746],[-157.993748,21.632922],[-158.031398,21.694871]]]},"properties":{"id":"96712","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.04987,21.47833],[-158.042826,21.485492],[-158.056244,21.495517],[-158.056999,21.482566],[-158.04987,21.47833]]]},"properties":{"id":"96857","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.863692,21.304911],[-157.863266,21.301994],[-157.86119,21.303436],[-157.863692,21.304911]]]},"properties":{"id":"96850","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.50192,21.92055],[-159.48681,21.916],[-159.493602,21.929762],[-159.50192,21.92055]]]},"properties":{"id":"96765","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.163631,20.739991],[-156.144765,20.6197],[-156.058714,20.81265],[-156.163631,20.739991]]]},"properties":{"id":"96713","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.261022,21.230883],[-157.080041,21.112625],[-157.054039,21.124953],[-157.014612,21.182349],[-157.014491,21.188823],[-157.261022,21.230883]]]},"properties":{"id":"96729","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.765179,20.141594],[-155.826918,20.213787],[-155.85966,20.19733],[-155.765179,20.141594]]]},"properties":{"id":"96719","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.954869,21.30308],[-157.908998,21.368429],[-157.956553,21.375176],[-157.973465,21.366478],[-157.970755,21.338039],[-157.968354,21.321032],[-157.954869,21.30308]],[[-157.941026,21.333744],[-157.9382,21.336115],[-157.935359,21.334736],[-157.937946,21.331376],[-157.941026,21.333744]],[[-157.943227,21.346902],[-157.942839,21.356077],[-157.938006,21.363659],[-157.934788,21.349082],[-157.943227,21.346902]]]},"properties":{"id":"96818","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.279549,21.567325],[-158.142449,21.507733],[-158.071182,21.506712],[-158.106493,21.575746],[-158.122048,21.587527],[-158.279549,21.567325]]]},"properties":{"id":"96791","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.761186,21.446837],[-157.761776,21.4501],[-157.7644,21.448359],[-157.761186,21.446837]]]},"properties":{"id":"96863","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.501137,22.071773],[-159.329529,22.042657],[-159.297007,22.151796],[-159.302241,22.109307],[-159.297774,22.103284],[-159.30644,22.10001],[-159.304232,22.110888],[-159.412334,22.132476],[-159.487427,22.074902],[-159.501137,22.071773]]]},"properties":{"id":"96746","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.503978,22.215794],[-159.691008,22.167557],[-159.645277,22.14213],[-159.501137,22.071773],[-159.487427,22.074902],[-159.470234,22.166183],[-159.503978,22.215794]]]},"properties":{"id":"96714","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.362586,20.83333],[-156.230852,20.765714],[-156.346071,20.918387],[-156.370438,20.87947],[-156.362586,20.83333]]]},"properties":{"id":"96768","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.266804,20.010568],[-155.194441,19.955034],[-155.195211,19.96577],[-155.28231,20.027423],[-155.284746,20.019506],[-155.266804,20.010568]]]},"properties":{"id":"96780","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.335451,19.7995],[-155.082441,19.779045],[-155.08459,19.823133],[-155.244648,19.820994],[-155.33546,19.803457],[-155.335451,19.7995]]]},"properties":{"id":"96781","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.157188,19.845911],[-155.097464,19.875021],[-155.104434,19.881055],[-155.157188,19.845911]]]},"properties":{"id":"96728","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.06724,19.60865],[-155.01426,19.50647],[-155.01372,19.51573],[-155.06724,19.60865]]]},"properties":{"id":"96760","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.967226,19.533776],[-155.938668,19.473221],[-155.787972,19.474893],[-155.75381,19.40663],[-155.641742,19.450836],[-155.602988,19.520541],[-155.725859,19.67253],[-155.828446,19.633608],[-155.93522,19.55021],[-155.967226,19.533776]]]},"properties":{"id":"96750","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.75381,19.40663],[-155.84566,19.39748],[-155.879154,19.446034],[-155.787972,19.474893],[-155.938668,19.473221],[-155.920811,19.081331],[-155.76766,19.183886],[-155.618917,19.421963],[-155.641742,19.450836],[-155.75381,19.40663]]]},"properties":{"id":"96704","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.85966,20.19733],[-155.846301,20.046035],[-155.923843,19.895644],[-155.904533,19.893689],[-155.80464,19.83106],[-155.490436,19.901496],[-155.706062,20.110589],[-155.765179,20.141594],[-155.85966,20.19733]]]},"properties":{"id":"96743","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.284746,20.019506],[-155.405474,19.888865],[-155.335959,19.912515],[-155.266804,20.010568],[-155.284746,20.019506]]]},"properties":{"id":"96774","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.722857,21.320031],[-157.758103,21.331286],[-157.759573,21.271939],[-157.719485,21.281793],[-157.722857,21.320031]]]},"properties":{"id":"96821","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.722857,21.320031],[-157.647857,21.313929],[-157.704839,21.380695],[-157.759048,21.331853],[-157.758103,21.331286],[-157.722857,21.320031]]]},"properties":{"id":"96795","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.031398,21.694871],[-157.993748,21.632922],[-157.965396,21.611899],[-157.904263,21.647509],[-158.031398,21.694871]]]},"properties":{"id":"96731","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.936069,21.569695],[-157.907451,21.508527],[-157.896243,21.50156],[-157.838937,21.534934],[-157.858976,21.563151],[-157.915248,21.628319],[-157.936069,21.569695]]]},"properties":{"id":"96717","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-159.664395,21.953303],[-159.670017,21.947664],[-159.641688,21.917929],[-159.63788,21.921426],[-159.610287,21.933231],[-159.664395,21.953303]]],[[[-160.246596,21.825824],[-160.069438,21.899383],[-160.121833,21.964073],[-160.246596,21.825824]]]]},"properties":{"id":"96769","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.925322,21.135864],[-157.054039,21.124953],[-157.080041,21.112625],[-157.102823,21.095408],[-156.873281,21.044705],[-156.914082,21.174719],[-156.925322,21.135864]]]},"properties":{"id":"96748","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.412002,20.779712],[-156.424206,20.594205],[-156.144765,20.6197],[-156.163631,20.739991],[-156.230852,20.765714],[-156.362586,20.83333],[-156.412002,20.779712]]]},"properties":{"id":"96790","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.346071,20.918387],[-156.230852,20.765714],[-156.163631,20.739991],[-156.058714,20.81265],[-156.367326,20.937153],[-156.346071,20.918387]]]},"properties":{"id":"96708","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.014612,21.182349],[-157.054039,21.124953],[-156.925322,21.135864],[-157.014612,21.182349]]]},"properties":{"id":"96757","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.503642,19.379931],[-155.549234,19.078993],[-155.355963,19.350491],[-155.503642,19.379931]]]},"properties":{"id":"96777","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.93522,19.55021],[-155.828446,19.633608],[-155.97469,19.68677],[-155.93522,19.55021]]]},"properties":{"id":"96725","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.097464,19.875021],[-155.157188,19.845911],[-155.244648,19.820994],[-155.08459,19.823133],[-155.097464,19.875021]]]},"properties":{"id":"96783","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.044441,21.301835],[-158.032161,21.38362],[-158.059748,21.441864],[-158.093353,21.452993],[-158.145653,21.363037],[-158.044441,21.301835]]]},"properties":{"id":"96707","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.820457,21.284836],[-157.834772,21.288865],[-157.845489,21.279498],[-157.810981,21.251219],[-157.820457,21.284836]]]},"properties":{"id":"96815","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.081921,21.487607],[-158.056999,21.482566],[-158.056244,21.495517],[-158.042826,21.485492],[-158.04987,21.47833],[-158.051206,21.474948],[-158.023578,21.46238],[-157.907451,21.508527],[-157.936069,21.569695],[-157.965396,21.611899],[-157.993748,21.632922],[-158.106493,21.575746],[-158.071182,21.506712],[-158.081921,21.487607]]]},"properties":{"id":"96786","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.874952,21.456328],[-157.859153,21.428629],[-157.838903,21.401987],[-157.809655,21.36933],[-157.793688,21.366166],[-157.761186,21.446837],[-157.7644,21.448359],[-157.761776,21.4501],[-157.764155,21.463344],[-157.834251,21.53702],[-157.838937,21.534934],[-157.896243,21.50156],[-157.890352,21.483927],[-157.874952,21.456328]]]},"properties":{"id":"96744","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.412334,22.132476],[-159.332659,22.207901],[-159.462627,22.228091],[-159.470234,22.166183],[-159.487427,22.074902],[-159.412334,22.132476]]]},"properties":{"id":"96754","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.664395,21.953303],[-159.645277,22.14213],[-159.691008,22.167557],[-159.779451,22.007493],[-159.691759,21.961311],[-159.693459,21.957383],[-159.670017,21.947664],[-159.664395,21.953303]]]},"properties":{"id":"96796","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.478665,20.904108],[-156.483677,20.850901],[-156.415774,20.904052],[-156.451896,20.90404],[-156.478665,20.904108]]]},"properties":{"id":"96732","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.412002,20.779712],[-156.485691,20.797086],[-156.490691,20.792058],[-156.424206,20.594205],[-156.412002,20.779712]]]},"properties":{"id":"96753","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.965066,20.729609],[-156.830391,20.762367],[-156.884992,20.914556],[-157.024718,20.935162],[-156.965066,20.729609]]]},"properties":{"id":"96763","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-155.395849,20.083089],[-155.437842,19.90303],[-155.405474,19.888865],[-155.284746,20.019506],[-155.28231,20.027423],[-155.395849,20.083089]]]},"properties":{"id":"96776","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.907451,21.508527],[-158.023578,21.46238],[-158.028974,21.449206],[-157.890352,21.483927],[-157.896243,21.50156],[-157.907451,21.508527]]]},"properties":{"id":"96789","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.848701,21.305596],[-157.834675,21.301301],[-157.813988,21.292251],[-157.779156,21.335832],[-157.789071,21.3532],[-157.803456,21.345032],[-157.848701,21.305596]]]},"properties":{"id":"96822","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.809655,21.36933],[-157.870033,21.296257],[-157.803456,21.345032],[-157.789071,21.3532],[-157.793688,21.366166],[-157.809655,21.36933]]]},"properties":{"id":"96817","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.908998,21.368429],[-157.954869,21.30308],[-157.869505,21.293667],[-157.870033,21.296257],[-157.809655,21.36933],[-157.838903,21.401987],[-157.908998,21.368429]],[[-157.891846,21.357755],[-157.89415,21.360896],[-157.89271,21.363153],[-157.888079,21.360077],[-157.891846,21.357755]]]},"properties":{"id":"96819","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.722857,21.320031],[-157.719485,21.281793],[-157.647857,21.313929],[-157.722857,21.320031]]]},"properties":{"id":"96825","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.032161,21.38362],[-157.970755,21.338039],[-157.973465,21.366478],[-157.874952,21.456328],[-157.890352,21.483927],[-158.028974,21.449206],[-158.059748,21.441864],[-158.032161,21.38362]]]},"properties":{"id":"96797","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-158.056999,21.482566],[-158.081921,21.487607],[-158.093516,21.454351],[-158.093353,21.452993],[-158.059748,21.441864],[-158.051206,21.474948],[-158.04987,21.47833],[-158.056999,21.482566]]]},"properties":{"id":"96759","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-157.943227,21.346902],[-157.934788,21.349082],[-157.938006,21.363659],[-157.942839,21.356077],[-157.943227,21.346902]]]},"properties":{"id":"96860","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.693459,21.957383],[-159.691759,21.961311],[-159.779451,22.007493],[-159.693459,21.957383]]]},"properties":{"id":"96752","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.593633,21.893556],[-159.54021,21.896048],[-159.569268,21.920285],[-159.593633,21.893556]]]},"properties":{"id":"96705","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-159.304232,22.110888],[-159.30644,22.10001],[-159.297774,22.103284],[-159.302241,22.109307],[-159.304232,22.110888]]]},"properties":{"id":"96751","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.586683,21.035166],[-156.704213,20.930263],[-156.545914,20.778117],[-156.586683,21.035166]]]},"properties":{"id":"96761","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-156.586683,21.035166],[-156.545914,20.778117],[-156.490691,20.792058],[-156.485691,20.797086],[-156.483677,20.850901],[-156.478665,20.904108],[-156.586683,21.035166]]]},"properties":{"id":"96793","state_code":"15"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.513451,43.437153],[-116.453821,43.396097],[-116.115923,43.453944],[-116.203415,43.51923],[-116.233627,43.525942],[-116.334491,43.51729],[-116.43363,43.532074],[-116.473557,43.529436],[-116.513451,43.437153]]]},"properties":{"id":"83634","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.452787,44.462343],[-116.320358,44.444084],[-116.315203,44.628913],[-116.528215,44.581116],[-116.452787,44.462343]]]},"properties":{"id":"83632","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.270069,44.651509],[-116.583208,44.614785],[-116.528215,44.581116],[-116.315203,44.628913],[-116.270069,44.651509]]]},"properties":{"id":"83643","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.415617,42.851858],[-112.438305,42.862025],[-112.423019,42.848774],[-112.415617,42.851858]]]},"properties":{"id":"83209","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.292673,42.726176],[-112.291485,42.612032],[-112.161097,42.578723],[-112.103519,42.698772],[-112.114108,42.72578],[-112.292673,42.726176]]]},"properties":{"id":"83250","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.03975,47.15461],[-116.835083,47.045635],[-116.808103,47.032555],[-116.685289,47.037449],[-116.94121,47.214101],[-117.039932,47.240291],[-117.03975,47.15461]]]},"properties":{"id":"83870","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.573485,47.47907],[-116.645803,47.584472],[-116.825984,47.522917],[-116.756592,47.385911],[-116.735387,47.35555],[-116.65805,47.456861],[-116.573485,47.47907]]]},"properties":{"id":"83833","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.159839,43.325372],[-111.947176,43.304337],[-111.997664,43.366845],[-112.048675,43.410164],[-112.188408,43.424917],[-112.242871,43.424719],[-112.211887,43.345677],[-112.168242,43.322254],[-112.159839,43.325372]]]},"properties":{"id":"83274","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.007816,43.154172],[-112.682758,43.09962],[-112.633949,43.041273],[-112.625885,43.029302],[-112.516026,43.121153],[-112.946922,43.336108],[-113.007816,43.154172]]]},"properties":{"id":"83262","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.989384,43.858611],[-114.553719,43.496522],[-114.308162,43.654206],[-114.325252,43.667119],[-114.325558,43.667319],[-114.325764,43.723262],[-114.263015,43.825939],[-114.409945,43.895591],[-114.657916,43.918182],[-114.971258,43.938134],[-114.989384,43.858611]]]},"properties":{"id":"83340","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.153388,44.094737],[-116.035966,44.070701],[-116.03745,43.980126],[-116.036384,43.980126],[-115.947345,43.994444],[-115.739827,44.011371],[-115.696673,43.9637],[-115.682629,44.235465],[-115.899669,44.235634],[-116.153388,44.094737]]]},"properties":{"id":"83622","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.153388,44.094737],[-116.206678,44.065861],[-116.172026,44.045431],[-116.035966,44.070701],[-116.153388,44.094737]]]},"properties":{"id":"83602","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.206678,44.065861],[-116.273478,44.092491],[-116.399773,44.065543],[-116.328449,43.940283],[-116.172026,44.045431],[-116.206678,44.065861]]]},"properties":{"id":"83670","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.175756,48.355014],[-116.36857,48.266193],[-116.32923,48.166085],[-116.244993,48.153596],[-116.156549,48.195207],[-116.175756,48.355014]]]},"properties":{"id":"83836","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.72896,48.102237],[-116.511326,48.098591],[-116.529138,48.080552],[-116.32923,48.166085],[-116.36857,48.266193],[-116.583487,48.245883],[-116.611061,48.240706],[-116.71618,48.208874],[-116.72896,48.102237]]]},"properties":{"id":"83860","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.984489,43.489278],[-111.974243,43.395461],[-111.822667,43.323702],[-111.695083,43.387301],[-111.705381,43.532549],[-111.984489,43.489278]]]},"properties":{"id":"83406","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.934251,43.520949],[-111.924209,43.529779],[-111.939184,43.53204],[-111.934251,43.520949]]]},"properties":{"id":"83427","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.184494,43.309341],[-111.044055,43.189271],[-111.045491,43.501135],[-111.216158,43.571589],[-111.349698,43.483262],[-111.184494,43.309341]]]},"properties":{"id":"83428","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.557128,48.649657],[-116.339986,48.501516],[-116.243932,48.582662],[-116.557128,48.649657]]]},"properties":{"id":"83847","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.213328,49.000915],[-116.201893,48.960065],[-116.171066,48.955075],[-116.180934,49.000759],[-116.213328,49.000915]]]},"properties":{"id":"83826","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.026536,43.680863],[-116.91795,43.612256],[-116.841895,43.593273],[-116.84894,43.64724],[-116.861746,43.704261],[-116.871759,43.712407],[-117.026739,43.693707],[-117.026536,43.680863]]]},"properties":{"id":"83676","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.653415,43.696501],[-116.579698,43.684718],[-116.512666,43.758835],[-116.512559,43.807216],[-116.614762,43.807139],[-116.653415,43.696501]]]},"properties":{"id":"83644","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.548156,43.572457],[-116.665726,43.562399],[-116.716988,43.430236],[-116.513451,43.437153],[-116.473557,43.529436],[-116.548156,43.572457]]]},"properties":{"id":"83686","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.148291,42.473201],[-113.355138,42.619095],[-113.647893,42.542545],[-113.607137,42.449682],[-113.467927,42.399932],[-113.148291,42.473201]]]},"properties":{"id":"83323","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.369706,46.500419],[-116.369247,46.466921],[-116.308539,46.412998],[-116.127923,46.301316],[-116.052999,46.355491],[-115.987391,46.418964],[-116.25568,46.62407],[-116.257993,46.567013],[-116.369706,46.500419]]]},"properties":{"id":"83544","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.35577,43.236929],[-115.301481,42.884329],[-115.037262,42.911931],[-115.086771,42.925849],[-115.086367,43.052604],[-115.35577,43.236929]]]},"properties":{"id":"83633","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.89511,42.341779],[-111.983914,42.256571],[-111.976302,42.183566],[-111.951151,42.105096],[-111.933595,41.998634],[-111.836874,41.998836],[-111.815817,42.05081],[-111.720531,42.053506],[-111.700068,41.999374],[-111.507264,41.999518],[-111.524647,42.074401],[-111.590168,42.142641],[-111.578674,42.281256],[-111.562145,42.34513],[-111.736226,42.394345],[-111.89511,42.341779]]]},"properties":{"id":"83263","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.736226,42.394345],[-111.562145,42.34513],[-111.56054,42.36471],[-111.598597,42.415821],[-111.746465,42.410191],[-111.736226,42.394345]]]},"properties":{"id":"83283","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.951151,42.105096],[-111.976302,42.183566],[-112.000569,42.112483],[-111.951151,42.105096]]]},"properties":{"id":"83232","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.542306,44.276979],[-111.394582,44.199521],[-111.150907,44.344259],[-111.37476,44.750295],[-111.617343,44.550833],[-111.542306,44.276979]],[[-111.339298,44.496331],[-111.317291,44.48687],[-111.339777,44.47249],[-111.33517,44.490246],[-111.339298,44.496331]]]},"properties":{"id":"83429","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.828284,42.928697],[-115.086771,42.925849],[-115.037262,42.911931],[-114.90198,42.856553],[-114.810767,42.83902],[-114.828284,42.928697]]]},"properties":{"id":"83314","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.192311,45.567489],[-115.252863,45.92006],[-115.769179,45.934479],[-115.980041,45.724997],[-115.513342,45.520697],[-115.192311,45.567489]]]},"properties":{"id":"83525","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.509231,47.81107],[-116.764159,47.845965],[-116.85098,47.752139],[-116.851032,47.74484],[-116.720154,47.730546],[-116.516267,47.764633],[-116.509231,47.81107]]]},"properties":{"id":"83835","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.720154,47.730546],[-116.851195,47.7152],[-117.033341,47.571431],[-116.825984,47.522917],[-116.645803,47.584472],[-116.525391,47.623141],[-116.516267,47.764633],[-116.720154,47.730546]]]},"properties":{"id":"83814","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.65805,47.456861],[-116.485688,47.412919],[-116.480685,47.449159],[-116.573485,47.47907],[-116.65805,47.456861]]]},"properties":{"id":"83842","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.604549,46.735883],[-116.692504,46.860343],[-116.693584,46.860653],[-116.852874,46.807927],[-116.789748,46.650932],[-116.604549,46.735883]]]},"properties":{"id":"83871","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039499,46.913695],[-116.89171,46.813378],[-116.867546,46.809455],[-116.796603,47.012188],[-116.808103,47.032555],[-116.835083,47.045635],[-116.946953,47.067343],[-117.039886,47.019595],[-117.039499,46.913695]]]},"properties":{"id":"83855","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039886,47.019595],[-116.946953,47.067343],[-117.039734,47.127265],[-117.039886,47.019595]]]},"properties":{"id":"99128","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039821,46.456952],[-116.815235,46.471536],[-116.773036,46.619027],[-116.789658,46.643215],[-117.039698,46.603996],[-117.039821,46.456952]]]},"properties":{"id":"83832","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.45566,45.039601],[-113.691956,45.272571],[-113.894153,45.230549],[-114.031623,45.308044],[-114.043682,45.220911],[-114.093137,44.753259],[-113.839873,44.739816],[-113.851066,44.866093],[-113.45566,45.039601]]]},"properties":{"id":"83467","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.493428,46.381405],[-116.548319,46.413112],[-116.534886,46.30095],[-116.385697,46.341228],[-116.493428,46.381405]]]},"properties":{"id":"83548","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.786721,43.816455],[-111.778088,43.811571],[-111.778074,43.819758],[-111.786721,43.816455]]]},"properties":{"id":"83460","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.457646,42.718651],[-112.592333,42.546704],[-112.702133,42.460465],[-112.704578,42.328848],[-112.605021,42.343603],[-112.419995,42.502857],[-112.379609,42.646145],[-112.457646,42.718651]]]},"properties":{"id":"83212","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.97737,43.907485],[-116.962308,43.909758],[-116.963482,43.916107],[-116.980787,43.913564],[-116.97737,43.907485]]]},"properties":{"id":"97913","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.99511,47.723978],[-116.32396,47.629058],[-116.329433,47.485166],[-116.221685,47.54784],[-116.221749,47.557976],[-116.044444,47.599622],[-115.99511,47.723978]]]},"properties":{"id":"83839","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.329433,47.485166],[-116.329496,47.413781],[-116.282872,47.391081],[-116.111229,47.428756],[-116.155811,47.513566],[-116.221685,47.54784],[-116.329433,47.485166]]]},"properties":{"id":"83850","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.383048,42.599152],[-114.405655,42.254589],[-114.30894,42.247378],[-114.325764,42.572372],[-114.344705,42.585214],[-114.383048,42.599152]]]},"properties":{"id":"83341","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.220626,43.691055],[-116.268542,43.680771],[-116.235144,43.628464],[-116.220626,43.691055]]]},"properties":{"id":"83703","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.334486,43.662895],[-116.281687,43.806937],[-116.274095,43.838425],[-116.512559,43.807216],[-116.512666,43.758835],[-116.453184,43.683086],[-116.365864,43.662926],[-116.334486,43.662895]]]},"properties":{"id":"83616","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.115923,43.453944],[-115.927149,43.171445],[-115.856777,43.601438],[-115.847997,43.703593],[-115.891616,43.845885],[-116.14527,43.794844],[-116.222962,43.764528],[-116.202794,43.750004],[-116.094122,43.670272],[-116.160797,43.585106],[-116.183479,43.568723],[-116.203415,43.51923],[-116.115923,43.453944]]]},"properties":{"id":"83716","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.495767,42.897767],[-112.300768,42.905779],[-112.13641,42.905785],[-112.402566,43.071603],[-112.456612,43.036427],[-112.505985,43.021833],[-112.554943,42.958123],[-112.495767,42.897767]]]},"properties":{"id":"83202","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.121688,42.517771],[-112.306292,42.456616],[-112.126286,42.28533],[-112.044016,42.285564],[-111.895164,42.351978],[-111.875573,42.416335],[-111.916338,42.53043],[-112.121688,42.517771]]]},"properties":{"id":"83234","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.94121,47.214101],[-116.735387,47.35555],[-116.756592,47.385911],[-117.039894,47.366102],[-117.039932,47.240291],[-116.94121,47.214101]]]},"properties":{"id":"83851","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.329461,47.197521],[-116.523181,47.036436],[-116.474344,47.036938],[-116.329443,47.159592],[-116.329461,47.197521]]]},"properties":{"id":"83866","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.039734,47.127265],[-116.946953,47.067343],[-116.835083,47.045635],[-117.03975,47.15461],[-117.039734,47.127265]]]},"properties":{"id":"83824","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.456612,43.036427],[-112.505251,43.095333],[-112.505985,43.021833],[-112.456612,43.036427]]]},"properties":{"id":"83203","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.814894,43.443079],[-112.810183,43.442719],[-112.813061,43.446259],[-112.814894,43.443079]]]},"properties":{"id":"83215","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.041281,48.101027],[-116.938498,48.10214],[-117.037528,48.259824],[-117.041281,48.101027]]]},"properties":{"id":"83822","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.785363,48.744529],[-116.742929,48.28177],[-116.774904,48.238458],[-116.726511,48.185733],[-116.71618,48.208874],[-116.611061,48.240706],[-116.608438,48.251623],[-116.583487,48.245883],[-116.36857,48.266193],[-116.175756,48.355014],[-116.339986,48.501516],[-116.557128,48.649657],[-116.785363,48.744529]],[[-116.536148,48.327417],[-116.546213,48.320247],[-116.540695,48.309273],[-116.524391,48.309084],[-116.544099,48.291329],[-116.553156,48.327392],[-116.536148,48.327417]],[[-116.50927,48.310053],[-116.51267,48.309173],[-116.516616,48.310207],[-116.516631,48.312235],[-116.510644,48.312246],[-116.50927,48.310053]]]},"properties":{"id":"83864","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.536148,48.327417],[-116.553156,48.327392],[-116.544099,48.291329],[-116.524391,48.309084],[-116.540695,48.309273],[-116.546213,48.320247],[-116.536148,48.327417]]]},"properties":{"id":"83852","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.04225,47.9214],[-116.829389,47.918592],[-116.809587,48.023753],[-116.894346,48.06479],[-117.042247,47.977386],[-117.04225,47.9214]]]},"properties":{"id":"83869","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.532344,48.043144],[-116.494757,47.89018],[-116.448773,47.890228],[-116.244993,48.153596],[-116.32923,48.166085],[-116.529138,48.080552],[-116.532344,48.043144]]]},"properties":{"id":"83803","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.066863,42.361924],[-113.784415,42.198992],[-113.661105,42.313326],[-113.669913,42.314153],[-114.030246,42.381099],[-114.066863,42.361924]]]},"properties":{"id":"83346","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.520212,44.518465],[-114.812434,44.808138],[-115.294462,44.339411],[-114.99075,43.949725],[-114.971258,43.938134],[-114.657916,43.918182],[-114.461956,44.039348],[-114.520212,44.518465]]]},"properties":{"id":"83278","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.476859,44.447919],[-114.08756,44.556945],[-113.911386,44.270336],[-113.456883,44.024929],[-113.320672,44.232998],[-113.476859,44.447919]]]},"properties":{"id":"83253","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.836417,43.040892],[-115.836457,43.069544],[-115.895773,43.070123],[-115.887983,43.026728],[-115.836417,43.040892]]]},"properties":{"id":"83648","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.977428,43.825702],[-111.94382,43.758424],[-111.738447,43.66676],[-111.568839,43.811722],[-111.612658,43.812014],[-111.616571,43.812032],[-111.737832,43.898175],[-111.976833,43.927431],[-111.977428,43.825702]],[[-111.786721,43.816455],[-111.778074,43.819758],[-111.778088,43.811571],[-111.786721,43.816455]]]},"properties":{"id":"83440","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.339298,44.496331],[-111.33517,44.490246],[-111.339777,44.47249],[-111.317291,44.48687],[-111.339298,44.496331]]]},"properties":{"id":"83433","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.737832,43.898175],[-111.616571,43.812032],[-111.678044,43.897988],[-111.737832,43.898175]]]},"properties":{"id":"83448","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.828284,42.928697],[-114.810767,42.83902],[-114.790295,42.839093],[-114.710111,42.839234],[-114.594705,42.998421],[-114.594122,43.069326],[-114.828284,42.928697]]]},"properties":{"id":"83330","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.240228,46.162461],[-116.248135,46.064901],[-116.180676,46.088658],[-116.190744,46.154392],[-116.240228,46.162461]]]},"properties":{"id":"83533","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.076079,43.669623],[-111.982871,43.710005],[-112.091377,43.676549],[-112.076079,43.669623]]]},"properties":{"id":"83431","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.263871,42.631297],[-114.344705,42.585214],[-114.325764,42.572372],[-114.190183,42.537912],[-114.153899,42.499905],[-114.212543,42.631618],[-114.263871,42.631297]]]},"properties":{"id":"83325","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.539246,42.633551],[-114.635532,42.476731],[-114.67366,42.21564],[-114.496348,42.214241],[-114.405655,42.254589],[-114.383048,42.599152],[-114.453392,42.600697],[-114.539246,42.633551]]]},"properties":{"id":"83301","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.645803,47.584472],[-116.573485,47.47907],[-116.480685,47.449159],[-116.329433,47.485166],[-116.32396,47.629058],[-116.525391,47.623141],[-116.645803,47.584472]]]},"properties":{"id":"83810","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.015032,46.72977],[-117.01196,46.729868],[-117.011987,46.731086],[-117.017075,46.731113],[-117.015032,46.72977]]]},"properties":{"id":"83844","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.894153,45.230549],[-113.691956,45.272571],[-113.775355,45.409881],[-113.894153,45.230549]]]},"properties":{"id":"83462","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.189346,42.15764],[-116.178536,42.01123],[-116.072456,42.054136],[-116.114351,42.157609],[-116.189346,42.15764]]]},"properties":{"id":"89832","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.026262,42.850342],[-116.867445,43.199119],[-117.003257,43.341011],[-117.026798,43.335278],[-117.026262,42.850342]]]},"properties":{"id":"97910","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.556021,44.15171],[-116.894925,44.166462],[-116.944831,44.03622],[-116.846134,44.01514],[-116.712587,43.980753],[-116.452528,44.095701],[-116.452012,44.151417],[-116.556021,44.15171]]]},"properties":{"id":"83661","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.710956,47.517459],[-115.858119,47.509654],[-115.835233,47.432536],[-115.710956,47.517459]]]},"properties":{"id":"83846","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.365864,43.662926],[-116.339371,43.619451],[-116.338045,43.604872],[-116.314301,43.607697],[-116.314128,43.660011],[-116.334486,43.662895],[-116.365864,43.662926]]]},"properties":{"id":"83713","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.253119,43.628234],[-116.23205,43.620819],[-116.235144,43.628464],[-116.268542,43.680771],[-116.220626,43.691055],[-116.202794,43.750004],[-116.222962,43.764528],[-116.281687,43.806937],[-116.334486,43.662895],[-116.314128,43.660011],[-116.253119,43.628234]]]},"properties":{"id":"83714","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.506755,43.011675],[-111.686783,42.706202],[-111.712044,42.649374],[-111.634422,42.57027],[-111.524916,42.555991],[-111.497772,42.56445],[-111.296888,42.55961],[-111.336943,42.947696],[-111.506755,43.011675]]]},"properties":{"id":"83276","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.524647,42.074401],[-111.507264,41.999518],[-111.299959,42.001245],[-111.524647,42.074401]]]},"properties":{"id":"83287","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.822667,43.323702],[-111.947176,43.304337],[-112.159839,43.325372],[-112.162454,43.311517],[-112.170364,43.314797],[-112.168242,43.322254],[-112.211887,43.345677],[-112.119839,43.217218],[-111.647558,43.282193],[-111.687322,43.358422],[-111.695083,43.387301],[-111.822667,43.323702]]]},"properties":{"id":"83236","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.078351,43.654764],[-112.172906,43.626514],[-112.188408,43.424917],[-112.048675,43.410164],[-112.033625,43.490198],[-112.066724,43.626344],[-112.078351,43.654764]]]},"properties":{"id":"83402","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.594705,42.998421],[-114.428609,42.887183],[-114.293152,42.922962],[-114.300768,42.965742],[-114.292044,43.148856],[-114.308793,43.199801],[-114.394687,43.300991],[-114.472624,43.199874],[-114.594122,43.069326],[-114.594705,42.998421]]]},"properties":{"id":"83352","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.308162,43.654206],[-114.325764,43.723262],[-114.325558,43.667319],[-114.323359,43.668992],[-114.321508,43.671785],[-114.320247,43.671307],[-114.325252,43.667119],[-114.308162,43.654206]]]},"properties":{"id":"83353","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.81223,48.461635],[-116.863294,48.748004],[-116.891988,48.613939],[-116.81223,48.461635]]]},"properties":{"id":"83821","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.712912,48.051005],[-116.644559,48.033669],[-116.511326,48.098591],[-116.72896,48.102237],[-116.712912,48.051005]]]},"properties":{"id":"83813","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.50927,48.310053],[-116.510644,48.312246],[-116.516631,48.312235],[-116.516616,48.310207],[-116.51267,48.309173],[-116.50927,48.310053]]]},"properties":{"id":"83840","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.968577,43.299125],[-115.00829,43.342834],[-114.988427,43.299202],[-114.968577,43.299125]]]},"properties":{"id":"83322","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.853234,43.933477],[-116.8502,43.892204],[-116.871759,43.712407],[-116.861746,43.704261],[-116.802694,43.662201],[-116.84894,43.64724],[-116.841895,43.593273],[-116.759399,43.463416],[-116.716988,43.430236],[-116.665726,43.562399],[-116.642341,43.61933],[-116.653415,43.696501],[-116.614762,43.807139],[-116.712649,43.879868],[-116.853234,43.933477]],[[-116.807027,43.723149],[-116.802564,43.730947],[-116.792705,43.732257],[-116.79275,43.72334],[-116.807027,43.723149]]]},"properties":{"id":"83607","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.265096,44.394596],[-112.534199,44.129528],[-112.455418,44.101556],[-112.081781,44.484006],[-112.265096,44.394596]]]},"properties":{"id":"83423","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.450378,43.938318],[-112.455418,44.101556],[-112.534199,44.129528],[-112.495922,43.841276],[-112.450378,43.938318]]]},"properties":{"id":"83435","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.905652,46.290725],[-115.797845,46.472221],[-115.827487,46.469054],[-115.987391,46.418964],[-116.052999,46.355491],[-115.905652,46.290725]]]},"properties":{"id":"83553","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.856079,46.593972],[-114.682267,46.508119],[-114.580274,46.635409],[-114.856079,46.593972]]]},"properties":{"id":"59847","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.099054,46.06748],[-115.78163,45.932723],[-116.012508,46.097751],[-116.099054,46.06748]]]},"properties":{"id":"83552","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.851195,47.7152],[-116.720154,47.730546],[-116.851032,47.74484],[-116.851195,47.7152]]]},"properties":{"id":"83815","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.829389,47.918592],[-117.04225,47.9214],[-117.042362,47.861478],[-116.85098,47.752139],[-116.764159,47.845965],[-116.829389,47.918592]]]},"properties":{"id":"83858","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.457155,44.874208],[-113.851066,44.866093],[-113.839873,44.739816],[-113.673784,44.655868],[-113.452266,44.863609],[-113.457155,44.874208]]]},"properties":{"id":"83465","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.769591,46.198741],[-116.701977,45.996177],[-116.625523,46.037491],[-116.599353,46.249687],[-116.733237,46.189312],[-116.769591,46.198741]]]},"properties":{"id":"83555","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.003257,43.341011],[-116.91795,43.612256],[-117.026536,43.680863],[-117.026798,43.335278],[-117.003257,43.341011]]]},"properties":{"id":"83628","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.240392,47.064989],[-116.329586,47.022442],[-116.271245,46.951922],[-116.098489,47.039816],[-116.240392,47.064989]]]},"properties":{"id":"83812","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.046573,43.795479],[-111.400806,43.854665],[-111.400192,43.735248],[-111.40006,43.691375],[-111.045771,43.674135],[-111.046573,43.795479]]]},"properties":{"id":"83422","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.325764,42.572372],[-114.30894,42.247378],[-114.285788,42.380338],[-114.190183,42.537912],[-114.325764,42.572372]]]},"properties":{"id":"83334","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.473528,43.626786],[-116.339371,43.619451],[-116.365864,43.662926],[-116.453184,43.683086],[-116.512484,43.681748],[-116.473528,43.626786]]]},"properties":{"id":"83646","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.253119,43.628234],[-116.314128,43.660011],[-116.314301,43.607697],[-116.261263,43.603734],[-116.253629,43.604502],[-116.253119,43.628234]]]},"properties":{"id":"83704","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.548156,43.572457],[-116.473557,43.529436],[-116.43363,43.532074],[-116.473528,43.626786],[-116.512484,43.681748],[-116.568429,43.681127],[-116.626564,43.633986],[-116.548156,43.572457]]]},"properties":{"id":"83687","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.43526,42.24943],[-111.48141,42.191208],[-111.415407,42.187238],[-111.385085,42.198424],[-111.339979,42.208179],[-111.43526,42.24943]]]},"properties":{"id":"83261","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.444905,42.359468],[-111.346304,42.334003],[-111.35481,42.400367],[-111.455107,42.398999],[-111.444905,42.359468]]]},"properties":{"id":"83220","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.402566,43.071603],[-112.119839,43.217218],[-112.211887,43.345677],[-112.242871,43.424719],[-112.946922,43.336108],[-112.516026,43.121153],[-112.505251,43.095333],[-112.456612,43.036427],[-112.402566,43.071603]]]},"properties":{"id":"83221","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.174278,43.555914],[-114.401077,43.326276],[-114.394687,43.300991],[-114.308793,43.199801],[-114.214453,43.200173],[-114.066057,43.31795],[-114.118434,43.374905],[-114.174278,43.555914]]]},"properties":{"id":"83313","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.168539,43.200105],[-114.132045,43.200058],[-114.026317,43.200228],[-114.066057,43.31795],[-114.214453,43.200173],[-114.168539,43.200105]]]},"properties":{"id":"83348","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.744171,42.81163],[-113.74442,42.589751],[-113.68769,42.524676],[-113.647893,42.542545],[-113.355138,42.619095],[-113.348537,42.662727],[-113.744171,42.81163]]]},"properties":{"id":"83350","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.172026,44.045431],[-116.328449,43.940283],[-116.274095,43.838425],[-116.281687,43.806937],[-116.222962,43.764528],[-116.14527,43.794844],[-116.037835,43.948065],[-116.03745,43.980126],[-116.035966,44.070701],[-116.172026,44.045431]]]},"properties":{"id":"83629","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.49697,44.234279],[-115.266405,43.976212],[-114.99075,43.949725],[-115.294462,44.339411],[-115.49697,44.234279]]]},"properties":{"id":"83637","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.984489,43.489278],[-112.033625,43.490198],[-112.048675,43.410164],[-111.997664,43.366845],[-111.974243,43.395461],[-111.984489,43.489278]]]},"properties":{"id":"83404","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.349698,43.483262],[-111.687322,43.358422],[-111.647558,43.282193],[-111.589548,43.107729],[-111.184494,43.309341],[-111.349698,43.483262]]]},"properties":{"id":"83449","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.95967,43.590214],[-111.94865,43.590252],[-111.950138,43.59663],[-111.95893,43.595046],[-111.95967,43.590214]]]},"properties":{"id":"83454","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-116.367921,46.559917],[-116.257993,46.567013],[-116.25568,46.62407],[-116.30102,46.625925],[-116.367921,46.559917]]],[[[-116.367921,46.559917],[-116.578127,46.550585],[-116.70081,46.501443],[-116.548319,46.413112],[-116.493428,46.381405],[-116.433626,46.498275],[-116.367921,46.559917]]]]},"properties":{"id":"83541","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.08756,44.556945],[-113.476859,44.447919],[-113.673784,44.655868],[-113.839873,44.739816],[-114.093137,44.753259],[-114.08756,44.556945]]]},"properties":{"id":"83235","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.700068,41.999374],[-111.720531,42.053506],[-111.815817,42.05081],[-111.836874,41.998836],[-111.700068,41.999374]]]},"properties":{"id":"83237","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.712649,43.879868],[-116.614762,43.807139],[-116.512559,43.807216],[-116.274095,43.838425],[-116.328449,43.940283],[-116.399773,44.065543],[-116.452528,44.095701],[-116.712587,43.980753],[-116.712649,43.879868]],[[-116.69674,43.926091],[-116.638226,43.905097],[-116.645422,43.889024],[-116.69674,43.926091]]]},"properties":{"id":"83617","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.617285,42.838854],[-114.634648,42.65434],[-114.539246,42.633551],[-114.453392,42.600697],[-114.263871,42.631297],[-114.212543,42.631618],[-114.109497,42.665513],[-114.433455,42.850734],[-114.617285,42.838854]]]},"properties":{"id":"83338","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.349817,45.891187],[-116.048212,45.713091],[-115.980041,45.724997],[-115.769179,45.934479],[-115.78163,45.932723],[-116.099054,46.06748],[-116.180676,46.088658],[-116.248135,46.064901],[-116.349817,45.891187]]]},"properties":{"id":"83530","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.514637,46.106885],[-116.3105,46.168844],[-116.352718,46.185829],[-116.514637,46.106885]]]},"properties":{"id":"83526","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.03981,46.82594],[-116.89171,46.813378],[-117.039499,46.913695],[-117.03981,46.82594]]]},"properties":{"id":"83872","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.523411,47.036437],[-116.685289,47.037449],[-116.808103,47.032555],[-116.796603,47.012188],[-116.693584,46.860653],[-116.692504,46.860343],[-116.625216,46.914495],[-116.523411,47.036437]]]},"properties":{"id":"83834","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.043682,45.220911],[-114.031623,45.308044],[-114.349679,45.46487],[-114.735214,45.395579],[-114.601912,45.288925],[-114.043682,45.220911]]]},"properties":{"id":"83469","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.839645,46.446555],[-116.769591,46.198741],[-116.733237,46.189312],[-116.731746,46.485526],[-116.839645,46.446555]]]},"properties":{"id":"83540","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.605021,42.343603],[-112.635598,42.167566],[-112.809564,42.328032],[-112.870728,42.327892],[-113.00082,41.998302],[-112.109532,41.997598],[-112.109218,42.1169],[-112.126286,42.28533],[-112.306292,42.456616],[-112.419995,42.502857],[-112.605021,42.343603]]]},"properties":{"id":"83252","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.053059,42.773619],[-116.189346,42.15764],[-116.114351,42.157609],[-115.638357,42.851008],[-115.777192,42.941679],[-115.89567,42.982545],[-115.975421,42.946351],[-116.053059,42.773619]]]},"properties":{"id":"83604","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.937339,47.257063],[-115.812758,46.934024],[-115.047857,46.969532],[-115.191803,47.132048],[-115.57632,47.076421],[-115.89027,47.383756],[-115.937339,47.257063]]]},"properties":{"id":"83802","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.400806,43.854665],[-111.046573,43.795479],[-111.04654,43.914107],[-111.289057,43.923989],[-111.398883,43.923086],[-111.400806,43.854665]]]},"properties":{"id":"83452","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.183479,43.568723],[-116.253629,43.604502],[-116.261263,43.603734],[-116.233627,43.525942],[-116.203415,43.51923],[-116.183479,43.568723]]]},"properties":{"id":"83705","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.453821,43.396097],[-116.513451,43.437153],[-116.716988,43.430236],[-116.759399,43.463416],[-116.751431,43.297079],[-116.385485,43.197664],[-116.453821,43.396097]]]},"properties":{"id":"83641","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.138246,44.941024],[-116.290927,45.108765],[-116.149507,45.237225],[-116.425699,45.267786],[-116.35435,44.925223],[-116.177666,44.92368],[-116.138246,44.941024]]]},"properties":{"id":"83654","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.423019,42.848774],[-112.495767,42.897767],[-112.554943,42.958123],[-112.737557,42.872779],[-112.692818,42.846306],[-112.592333,42.546704],[-112.457646,42.718651],[-112.304872,42.761542],[-112.338438,42.862035],[-112.415617,42.851858],[-112.423019,42.848774]]]},"properties":{"id":"83204","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.423019,42.848774],[-112.438305,42.862025],[-112.415617,42.851858],[-112.338438,42.862035],[-112.300768,42.905779],[-112.495767,42.897767],[-112.423019,42.848774]]]},"properties":{"id":"83201","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.917638,42.617209],[-112.103519,42.698772],[-112.161097,42.578723],[-112.121688,42.517771],[-111.916338,42.53043],[-111.917638,42.617209]]]},"properties":{"id":"83246","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.43526,42.24943],[-111.339979,42.208179],[-111.288744,42.20176],[-111.196744,42.186616],[-111.209158,42.085099],[-111.062947,42.001565],[-111.047316,42.213456],[-111.176755,42.272746],[-111.047135,42.415591],[-111.235396,42.580188],[-111.374658,42.521215],[-111.497772,42.56445],[-111.524916,42.555991],[-111.455107,42.398999],[-111.35481,42.400367],[-111.346304,42.334003],[-111.444905,42.359468],[-111.56054,42.36471],[-111.562145,42.34513],[-111.578674,42.281256],[-111.43526,42.24943]]]},"properties":{"id":"83254","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.168242,43.322254],[-112.170364,43.314797],[-112.162454,43.311517],[-112.159839,43.325372],[-112.168242,43.322254]]]},"properties":{"id":"83218","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.809587,48.023753],[-116.829389,47.918592],[-116.764159,47.845965],[-116.509231,47.81107],[-116.494757,47.89018],[-116.532344,48.043144],[-116.644559,48.033669],[-116.712912,48.051005],[-116.809587,48.023753]]]},"properties":{"id":"83801","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.774904,48.238458],[-116.755984,48.170105],[-116.726511,48.185733],[-116.774904,48.238458]]]},"properties":{"id":"83841","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.511326,48.098591],[-116.644559,48.033669],[-116.532344,48.043144],[-116.529138,48.080552],[-116.511326,48.098591]]]},"properties":{"id":"83809","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.785431,43.626873],[-111.705381,43.532549],[-111.695083,43.387301],[-111.687322,43.358422],[-111.349698,43.483262],[-111.216158,43.571589],[-111.399915,43.622015],[-111.717633,43.655272],[-111.785431,43.626873]]]},"properties":{"id":"83443","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.635212,43.395092],[-113.584152,43.367497],[-113.090859,43.642583],[-113.456328,44.02343],[-113.444944,43.945248],[-113.635212,43.395092]]]},"properties":{"id":"83213","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.862668,43.645291],[-113.635212,43.395092],[-113.444944,43.945248],[-113.862668,43.645291]]]},"properties":{"id":"83255","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.320672,44.232998],[-113.456883,44.024929],[-113.456328,44.02343],[-113.090859,43.642583],[-112.995379,44.233956],[-113.320672,44.232998]]]},"properties":{"id":"83244","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.797845,46.472221],[-115.789542,46.609018],[-115.897807,46.559338],[-115.827487,46.469054],[-115.797845,46.472221]]]},"properties":{"id":"83546","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.369706,46.500419],[-116.257993,46.567013],[-116.367921,46.559917],[-116.433626,46.498275],[-116.369706,46.500419]]]},"properties":{"id":"83520","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.604549,46.735883],[-116.789748,46.650932],[-116.789658,46.643215],[-116.773036,46.619027],[-116.578127,46.550585],[-116.367921,46.559917],[-116.30102,46.625925],[-116.464946,46.707939],[-116.604549,46.735883]]]},"properties":{"id":"83537","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.604549,46.735883],[-116.464946,46.707939],[-116.329541,46.851796],[-116.421049,46.851558],[-116.625216,46.914495],[-116.692504,46.860343],[-116.604549,46.735883]]]},"properties":{"id":"83823","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.520212,44.518465],[-114.282056,44.221983],[-114.461956,44.039348],[-114.657916,43.918182],[-114.409945,43.895591],[-113.911386,44.270336],[-114.08756,44.556945],[-114.093137,44.753259],[-114.043682,45.220911],[-114.601912,45.288925],[-114.694126,45.197114],[-114.812434,44.808138],[-114.520212,44.518465]]]},"properties":{"id":"83226","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.975421,42.946351],[-116.266168,43.112553],[-116.370902,42.709159],[-116.053059,42.773619],[-115.975421,42.946351]]]},"properties":{"id":"83624","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.558524,42.971408],[-115.777192,42.941679],[-115.638357,42.851008],[-115.441666,42.909367],[-115.488668,43.0268],[-115.558524,42.971408]]]},"properties":{"id":"83627","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.534159,43.971547],[-111.578228,43.92142],[-111.398883,43.923086],[-111.289057,43.923989],[-111.047044,43.983469],[-111.394582,44.199521],[-111.542306,44.276979],[-111.635759,44.082933],[-111.561808,44.037629],[-111.534159,43.971547]]]},"properties":{"id":"83420","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.568839,43.811722],[-111.400192,43.735248],[-111.400806,43.854665],[-111.398883,43.923086],[-111.578228,43.92142],[-111.618063,43.897691],[-111.612658,43.812014],[-111.568839,43.811722]]]},"properties":{"id":"83436","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.66662,45.433541],[-115.696073,45.195904],[-115.236227,45.196624],[-114.694126,45.197114],[-114.601912,45.288925],[-114.735214,45.395579],[-115.192311,45.567489],[-115.513342,45.520697],[-115.66662,45.433541]]]},"properties":{"id":"83671","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.109497,42.665513],[-114.212543,42.631618],[-114.153899,42.499905],[-114.000691,42.526434],[-113.931799,42.535275],[-113.932021,42.591049],[-114.069419,42.641747],[-114.109497,42.665513]]]},"properties":{"id":"83335","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.827289,45.521308],[-113.973624,45.702809],[-114.151786,45.555501],[-113.827289,45.521308]]]},"properties":{"id":"83463","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.433455,42.850734],[-114.293152,42.922962],[-114.428609,42.887183],[-114.433455,42.850734]]]},"properties":{"id":"83324","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.701977,45.996177],[-116.769591,46.198741],[-116.839645,46.446555],[-116.815235,46.471536],[-117.039821,46.456952],[-116.794562,45.856049],[-116.701977,45.996177]]]},"properties":{"id":"83501","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.994236,47.566504],[-115.943119,47.529901],[-115.928691,47.498765],[-115.974085,47.492718],[-116.004307,47.463104],[-115.994289,47.435268],[-115.897271,47.415941],[-115.835233,47.432536],[-115.858119,47.509654],[-115.710956,47.517459],[-115.716081,47.577071],[-115.876656,47.626768],[-115.72377,47.696671],[-115.99511,47.723978],[-116.044444,47.599622],[-115.994236,47.566504]]]},"properties":{"id":"83873","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.943119,47.529901],[-115.974085,47.492718],[-115.928691,47.498765],[-115.943119,47.529901]]]},"properties":{"id":"83867","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.155811,47.513566],[-116.221749,47.557976],[-116.221685,47.54784],[-116.155811,47.513566]]]},"properties":{"id":"83868","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.289057,43.923989],[-111.04654,43.914107],[-111.047044,43.983469],[-111.289057,43.923989]]]},"properties":{"id":"83424","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.399915,43.622015],[-111.216158,43.571589],[-111.045491,43.501135],[-111.045771,43.674135],[-111.40006,43.691375],[-111.399915,43.622015]]]},"properties":{"id":"83455","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.0377,42.555537],[-114.872795,42.333957],[-114.770504,42.372904],[-114.839176,42.467929],[-115.0377,42.555537]]]},"properties":{"id":"83321","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.995943,44.788245],[-116.188493,44.75035],[-116.162074,44.687317],[-116.033343,44.609323],[-115.995943,44.788245]]]},"properties":{"id":"83615","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.473528,43.626786],[-116.43363,43.532074],[-116.334491,43.51729],[-116.338045,43.604872],[-116.339371,43.619451],[-116.473528,43.626786]]]},"properties":{"id":"83642","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.094122,43.670272],[-116.202794,43.750004],[-116.220626,43.691055],[-116.235144,43.628464],[-116.23205,43.620819],[-116.193445,43.603909],[-116.094122,43.670272]]]},"properties":{"id":"83702","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.338045,43.604872],[-116.334491,43.51729],[-116.233627,43.525942],[-116.261263,43.603734],[-116.314301,43.607697],[-116.338045,43.604872]]]},"properties":{"id":"83709","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.306292,42.456616],[-112.121688,42.517771],[-112.161097,42.578723],[-112.291485,42.612032],[-112.379609,42.646145],[-112.419995,42.502857],[-112.306292,42.456616]]]},"properties":{"id":"83214","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.114108,42.72578],[-112.13641,42.905785],[-112.300768,42.905779],[-112.338438,42.862035],[-112.304872,42.761542],[-112.292673,42.726176],[-112.114108,42.72578]]]},"properties":{"id":"83245","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.409975,42.187339],[-111.415407,42.187238],[-111.48141,42.191208],[-111.590168,42.142641],[-111.524647,42.074401],[-111.299959,42.001245],[-111.062947,42.001565],[-111.209158,42.085099],[-111.288744,42.20176],[-111.339979,42.208179],[-111.385085,42.198424],[-111.409975,42.187339]],[[-111.409975,42.187339],[-111.399834,42.172868],[-111.430568,42.171765],[-111.409975,42.187339]]]},"properties":{"id":"83272","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.296888,42.55961],[-111.374658,42.521215],[-111.235396,42.580188],[-111.296888,42.55961]]]},"properties":{"id":"83239","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.633949,43.041273],[-112.718124,43.058535],[-112.682758,43.09962],[-113.007816,43.154172],[-113.007744,42.906518],[-112.816427,42.863253],[-112.625885,43.029302],[-112.633949,43.041273]]]},"properties":{"id":"83210","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.037835,43.948065],[-115.891616,43.845885],[-115.847997,43.703593],[-115.696673,43.9637],[-115.739827,44.011371],[-115.943461,43.943888],[-116.036384,43.980126],[-116.03745,43.980126],[-116.037835,43.948065]]]},"properties":{"id":"83631","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.244993,48.153596],[-116.448773,47.890228],[-116.156549,48.195207],[-116.244993,48.153596]]]},"properties":{"id":"83811","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.938498,48.10214],[-117.041281,48.101027],[-117.042247,47.977386],[-116.894346,48.06479],[-116.938498,48.10214]]]},"properties":{"id":"83804","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.583487,48.245883],[-116.608438,48.251623],[-116.611061,48.240706],[-116.583487,48.245883]]]},"properties":{"id":"83825","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.634422,42.57027],[-111.712044,42.649374],[-111.917638,42.617209],[-111.916338,42.53043],[-111.875573,42.416335],[-111.746465,42.410191],[-111.598597,42.415821],[-111.634422,42.57027]]]},"properties":{"id":"83241","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.725537,42.105701],[-113.554437,41.99216],[-113.496405,42.062855],[-113.725537,42.105701]]]},"properties":{"id":"83312","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.355138,42.619095],[-113.148291,42.473201],[-113.001701,42.429484],[-112.7382,42.627064],[-112.692818,42.846306],[-112.737557,42.872779],[-112.816427,42.863253],[-113.007744,42.906518],[-113.348537,42.662727],[-113.355138,42.619095]]]},"properties":{"id":"83211","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.456883,44.024929],[-113.911386,44.270336],[-114.409945,43.895591],[-114.263015,43.825939],[-114.074411,43.738346],[-113.862668,43.645291],[-113.444944,43.945248],[-113.456328,44.02343],[-113.456883,44.024929]]]},"properties":{"id":"83251","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.520212,44.518465],[-114.461956,44.039348],[-114.282056,44.221983],[-114.520212,44.518465]]]},"properties":{"id":"83227","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.109218,42.1169],[-112.109532,41.997598],[-111.933595,41.998634],[-111.951151,42.105096],[-112.000569,42.112483],[-112.109218,42.1169]]]},"properties":{"id":"83286","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.976302,42.183566],[-111.983914,42.256571],[-112.044016,42.285564],[-112.126286,42.28533],[-112.109218,42.1169],[-112.000569,42.112483],[-111.976302,42.183566]]]},"properties":{"id":"83228","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.976833,43.927431],[-111.737832,43.898175],[-111.678044,43.897988],[-111.618063,43.897691],[-111.578228,43.92142],[-111.534159,43.971547],[-111.583621,44.018269],[-111.561808,44.037629],[-111.635759,44.082933],[-112.156304,43.985097],[-112.09718,43.971724],[-111.976833,43.927431]]]},"properties":{"id":"83445","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.561808,44.037629],[-111.583621,44.018269],[-111.534159,43.971547],[-111.561808,44.037629]]]},"properties":{"id":"83421","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.678044,43.897988],[-111.616571,43.812032],[-111.612658,43.812014],[-111.618063,43.897691],[-111.678044,43.897988]]]},"properties":{"id":"83451","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.90198,42.856553],[-115.03774,42.782889],[-114.849819,42.716568],[-114.790295,42.839093],[-114.810767,42.83902],[-114.90198,42.856553]]]},"properties":{"id":"83332","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.710111,42.839234],[-114.790295,42.839093],[-114.849819,42.716568],[-114.684665,42.660847],[-114.634648,42.65434],[-114.617285,42.838854],[-114.710111,42.839234]]]},"properties":{"id":"83355","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.672695,42.491247],[-114.684665,42.660847],[-114.849819,42.716568],[-115.03774,42.782889],[-115.0377,42.555537],[-114.839176,42.467929],[-114.672695,42.491247]]]},"properties":{"id":"83316","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.252863,45.92006],[-114.580274,46.635409],[-114.682267,46.508119],[-114.856079,46.593972],[-115.750759,46.294741],[-116.012508,46.097751],[-115.78163,45.932723],[-115.769179,45.934479],[-115.252863,45.92006]]]},"properties":{"id":"83539","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.425699,45.267786],[-116.149507,45.237225],[-116.007358,45.195376],[-115.969635,45.195412],[-115.768084,45.452315],[-116.270428,45.494873],[-116.528093,45.539603],[-116.687027,45.267857],[-116.425699,45.267786]]]},"properties":{"id":"83549","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.528093,45.539603],[-116.270428,45.494873],[-116.222126,45.574906],[-116.465256,45.61805],[-116.528093,45.539603]]]},"properties":{"id":"83542","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.149507,45.237225],[-116.290927,45.108765],[-116.007358,45.195376],[-116.149507,45.237225]]]},"properties":{"id":"83547","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.040939,47.571516],[-117.039894,47.366102],[-116.756592,47.385911],[-116.825984,47.522917],[-117.033341,47.571431],[-117.040939,47.571516]]]},"properties":{"id":"83876","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.625523,46.037491],[-116.514637,46.106885],[-116.352718,46.185829],[-116.308539,46.412998],[-116.369247,46.466921],[-116.385697,46.341228],[-116.534886,46.30095],[-116.599353,46.249687],[-116.625523,46.037491]]]},"properties":{"id":"83523","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.240228,46.162461],[-116.190744,46.154392],[-116.127923,46.301316],[-116.308539,46.412998],[-116.352718,46.185829],[-116.3105,46.168844],[-116.240228,46.162461]]]},"properties":{"id":"83543","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.731746,46.485526],[-116.733237,46.189312],[-116.599353,46.249687],[-116.534886,46.30095],[-116.548319,46.413112],[-116.70081,46.501443],[-116.731746,46.485526]]]},"properties":{"id":"83524","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.292044,43.148856],[-114.300768,42.965742],[-114.132045,43.200058],[-114.168539,43.200105],[-114.292044,43.148856]]]},"properties":{"id":"83349","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.932021,42.591049],[-113.931799,42.535275],[-113.68769,42.524676],[-113.74442,42.589751],[-113.932021,42.591049]]]},"properties":{"id":"83336","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.369247,46.466921],[-116.369706,46.500419],[-116.433626,46.498275],[-116.493428,46.381405],[-116.385697,46.341228],[-116.369247,46.466921]]]},"properties":{"id":"83545","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.809564,42.328032],[-112.635598,42.167566],[-112.605021,42.343603],[-112.704578,42.328848],[-112.809564,42.328032]]]},"properties":{"id":"83243","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.867445,43.199119],[-117.026262,42.850342],[-116.370902,42.709159],[-116.266168,43.112553],[-116.385485,43.197664],[-116.751431,43.297079],[-116.867445,43.199119]]]},"properties":{"id":"83650","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.846134,44.01514],[-116.853234,43.933477],[-116.712649,43.879868],[-116.712587,43.980753],[-116.846134,44.01514]]]},"properties":{"id":"83655","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.853234,43.933477],[-116.846134,44.01514],[-116.944831,44.03622],[-116.978152,43.907334],[-116.97737,43.907485],[-116.980787,43.913564],[-116.963482,43.916107],[-116.962308,43.909758],[-116.8502,43.892204],[-116.853234,43.933477]]]},"properties":{"id":"83619","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.7382,42.627064],[-113.001701,42.429484],[-112.870728,42.327892],[-112.809564,42.328032],[-112.704578,42.328848],[-112.702133,42.460465],[-112.7382,42.627064]]]},"properties":{"id":"83271","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.282872,47.391081],[-116.278192,47.210239],[-115.937339,47.257063],[-115.89027,47.383756],[-115.897271,47.415941],[-115.994289,47.435268],[-116.111229,47.428756],[-116.282872,47.391081]]]},"properties":{"id":"83808","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.221749,47.557976],[-116.155811,47.513566],[-116.111229,47.428756],[-115.994289,47.435268],[-116.004307,47.463104],[-115.994236,47.566504],[-116.044444,47.599622],[-116.221749,47.557976]]]},"properties":{"id":"83837","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.684665,42.660847],[-114.672695,42.491247],[-114.635532,42.476731],[-114.539246,42.633551],[-114.634648,42.65434],[-114.684665,42.660847]]]},"properties":{"id":"83328","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.983914,42.256571],[-111.89511,42.341779],[-111.895164,42.351978],[-112.044016,42.285564],[-111.983914,42.256571]]]},"properties":{"id":"83281","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.047135,42.415591],[-111.176755,42.272746],[-111.047316,42.213456],[-111.047135,42.415591]]]},"properties":{"id":"83238","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.288744,42.20176],[-111.209158,42.085099],[-111.196744,42.186616],[-111.288744,42.20176]]]},"properties":{"id":"83233","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.94121,47.214101],[-116.685289,47.037449],[-116.523411,47.036437],[-116.523181,47.036436],[-116.329461,47.197521],[-116.278192,47.210239],[-116.282872,47.391081],[-116.329496,47.413781],[-116.485688,47.412919],[-116.65805,47.456861],[-116.735387,47.35555],[-116.94121,47.214101]]]},"properties":{"id":"83861","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.329586,47.022442],[-116.240392,47.064989],[-116.329443,47.159592],[-116.474344,47.036938],[-116.329586,47.022442]]]},"properties":{"id":"83830","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.113439,43.577845],[-114.074411,43.738346],[-114.263015,43.825939],[-114.325764,43.723262],[-114.308162,43.654206],[-114.553719,43.496522],[-114.401077,43.326276],[-114.174278,43.555914],[-114.113439,43.577845]]]},"properties":{"id":"83333","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.113439,43.577845],[-114.118434,43.374905],[-114.066057,43.31795],[-114.026317,43.200228],[-113.584152,43.367497],[-113.635212,43.395092],[-113.862668,43.645291],[-114.074411,43.738346],[-114.113439,43.577845]]]},"properties":{"id":"83320","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.036384,43.980126],[-115.943461,43.943888],[-115.947345,43.994444],[-116.036384,43.980126]]]},"properties":{"id":"83666","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.992139,48.636751],[-116.891988,48.613939],[-116.863294,48.748004],[-116.992139,48.636751]]]},"properties":{"id":"83848","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.984489,43.489278],[-111.705381,43.532549],[-111.785431,43.626873],[-112.066724,43.626344],[-112.033625,43.490198],[-111.984489,43.489278]],[[-111.95967,43.590214],[-111.95893,43.595046],[-111.950138,43.59663],[-111.94865,43.590252],[-111.95967,43.590214]],[[-111.934251,43.520949],[-111.939184,43.53204],[-111.924209,43.529779],[-111.934251,43.520949]]]},"properties":{"id":"83401","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.184494,43.309341],[-111.589548,43.107729],[-111.506755,43.011675],[-111.336943,42.947696],[-111.252763,43.020611],[-111.044154,43.020052],[-111.044055,43.189271],[-111.184494,43.309341]]]},"properties":{"id":"83285","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.04904,48.896867],[-116.176533,48.780286],[-116.049284,48.620155],[-116.04904,48.896867]]]},"properties":{"id":"83845","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.213328,49.000915],[-116.785363,48.744529],[-116.557128,48.649657],[-116.243932,48.582662],[-116.049284,48.620155],[-116.176533,48.780286],[-116.04904,48.896867],[-116.180934,49.000759],[-116.171066,48.955075],[-116.201893,48.960065],[-116.213328,49.000915]]]},"properties":{"id":"83805","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.08663,43.342168],[-115.35577,43.236929],[-115.086367,43.052604],[-114.87814,43.197995],[-114.968577,43.299125],[-114.988427,43.299202],[-115.00829,43.342834],[-115.08663,43.342168]]]},"properties":{"id":"83337","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.978152,43.907334],[-117.026739,43.693707],[-116.871759,43.712407],[-116.8502,43.892204],[-116.962308,43.909758],[-116.97737,43.907485],[-116.978152,43.907334]]]},"properties":{"id":"83660","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.548156,43.572457],[-116.626564,43.633986],[-116.642341,43.61933],[-116.665726,43.562399],[-116.548156,43.572457]]]},"properties":{"id":"83651","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.807027,43.723149],[-116.79275,43.72334],[-116.792705,43.732257],[-116.802564,43.730947],[-116.807027,43.723149]]]},"properties":{"id":"83656","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.661105,42.313326],[-113.467927,42.399932],[-113.607137,42.449682],[-113.669913,42.314153],[-113.661105,42.313326]]]},"properties":{"id":"83311","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.000691,42.526434],[-114.153899,42.499905],[-114.190183,42.537912],[-114.285788,42.380338],[-114.066863,42.361924],[-114.030246,42.381099],[-114.000691,42.526434]]]},"properties":{"id":"83344","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.081781,44.484006],[-112.256676,44.520825],[-112.265096,44.394596],[-112.081781,44.484006]]]},"properties":{"id":"83446","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.198583,46.765892],[-116.150217,46.78362],[-116.211719,46.806521],[-116.198583,46.765892]]]},"properties":{"id":"83827","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.273478,44.092491],[-116.299757,44.444107],[-116.452012,44.151417],[-116.452528,44.095701],[-116.399773,44.065543],[-116.273478,44.092491]]]},"properties":{"id":"83657","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.052999,46.355491],[-116.127923,46.301316],[-116.190744,46.154392],[-116.180676,46.088658],[-116.099054,46.06748],[-116.012508,46.097751],[-115.750759,46.294741],[-115.905652,46.290725],[-116.052999,46.355491]]]},"properties":{"id":"83536","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.048212,45.713091],[-116.349817,45.891187],[-116.522726,45.676912],[-116.465256,45.61805],[-116.222126,45.574906],[-116.048212,45.713091]]]},"properties":{"id":"83554","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.254956,43.839411],[-112.436385,43.89349],[-112.450378,43.938318],[-112.495922,43.841276],[-112.254956,43.839411]]]},"properties":{"id":"83450","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.096756,43.728582],[-112.172906,43.626514],[-112.078351,43.654764],[-112.076079,43.669623],[-112.091377,43.676549],[-112.096756,43.728582]]]},"properties":{"id":"83444","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.033341,47.571431],[-116.851195,47.7152],[-116.851032,47.74484],[-116.85098,47.752139],[-117.042362,47.861478],[-117.040939,47.571516],[-117.033341,47.571431]]]},"properties":{"id":"83854","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.839645,46.446555],[-116.731746,46.485526],[-116.70081,46.501443],[-116.578127,46.550585],[-116.773036,46.619027],[-116.815235,46.471536],[-116.839645,46.446555]]]},"properties":{"id":"83535","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.796603,47.012188],[-116.867546,46.809455],[-116.852874,46.807927],[-116.693584,46.860653],[-116.796603,47.012188]]]},"properties":{"id":"83857","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.421049,46.851558],[-116.329541,46.851796],[-116.385449,46.925959],[-116.421049,46.851558]]]},"properties":{"id":"83806","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.151786,45.555501],[-114.349679,45.46487],[-114.031623,45.308044],[-113.894153,45.230549],[-113.775355,45.409881],[-113.827289,45.521308],[-114.151786,45.555501]]]},"properties":{"id":"83466","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.45566,45.039601],[-113.851066,44.866093],[-113.457155,44.874208],[-113.45566,45.039601]]]},"properties":{"id":"83468","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.72377,47.696671],[-115.876656,47.626768],[-115.716081,47.577071],[-115.72377,47.696671]]]},"properties":{"id":"83874","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.079836,44.682528],[-116.894925,44.166462],[-116.556021,44.15171],[-116.878045,44.543284],[-117.079836,44.682528]]]},"properties":{"id":"83672","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.253119,43.628234],[-116.253629,43.604502],[-116.183479,43.568723],[-116.160797,43.585106],[-116.193445,43.603909],[-116.23205,43.620819],[-116.253119,43.628234]]]},"properties":{"id":"83706","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.193445,43.603909],[-116.160797,43.585106],[-116.094122,43.670272],[-116.193445,43.603909]]]},"properties":{"id":"83712","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.512484,43.681748],[-116.453184,43.683086],[-116.512666,43.758835],[-116.579698,43.684718],[-116.568429,43.681127],[-116.512484,43.681748]]]},"properties":{"id":"83669","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.687027,45.267857],[-116.899711,44.840609],[-116.583208,44.614785],[-116.270069,44.651509],[-116.35435,44.925223],[-116.425699,45.267786],[-116.687027,45.267857]]]},"properties":{"id":"83612","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.188493,44.75035],[-115.995943,44.788245],[-115.809237,45.169866],[-115.696073,45.195904],[-115.66662,45.433541],[-115.768084,45.452315],[-115.969635,45.195412],[-116.138246,44.941024],[-116.177666,44.92368],[-116.188493,44.75035]]]},"properties":{"id":"83638","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-111.409975,42.187339],[-111.385085,42.198424],[-111.415407,42.187238],[-111.409975,42.187339]]],[[[-111.409975,42.187339],[-111.430568,42.171765],[-111.399834,42.172868],[-111.409975,42.187339]]]]},"properties":{"id":"83223","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.682758,43.09962],[-112.718124,43.058535],[-112.633949,43.041273],[-112.682758,43.09962]]]},"properties":{"id":"83277","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.325252,43.667119],[-114.320247,43.671307],[-114.321508,43.671785],[-114.323359,43.668992],[-114.325558,43.667319],[-114.325252,43.667119]]]},"properties":{"id":"83354","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.037528,48.259824],[-116.938498,48.10214],[-116.894346,48.06479],[-116.809587,48.023753],[-116.712912,48.051005],[-116.72896,48.102237],[-116.71618,48.208874],[-116.726511,48.185733],[-116.755984,48.170105],[-116.774904,48.238458],[-116.742929,48.28177],[-116.81223,48.461635],[-116.891988,48.613939],[-116.992139,48.636751],[-117.037528,48.259824]]]},"properties":{"id":"83856","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.968577,43.299125],[-114.87814,43.197995],[-114.472624,43.199874],[-114.394687,43.300991],[-114.401077,43.326276],[-114.553719,43.496522],[-114.989384,43.858611],[-115.029562,43.728478],[-115.08663,43.342168],[-115.00829,43.342834],[-114.968577,43.299125]]]},"properties":{"id":"83327","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.579698,43.684718],[-116.653415,43.696501],[-116.642341,43.61933],[-116.626564,43.633986],[-116.568429,43.681127],[-116.579698,43.684718]]]},"properties":{"id":"83605","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.861746,43.704261],[-116.84894,43.64724],[-116.802694,43.662201],[-116.861746,43.704261]]]},"properties":{"id":"83626","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.13641,42.905785],[-112.114108,42.72578],[-112.103519,42.698772],[-111.917638,42.617209],[-111.712044,42.649374],[-111.686783,42.706202],[-112.13641,42.905785]]]},"properties":{"id":"83217","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.252763,43.020611],[-111.220084,42.858305],[-111.043786,42.811144],[-111.044154,43.020052],[-111.252763,43.020611]]]},"properties":{"id":"83120","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.220084,42.858305],[-111.200099,42.698626],[-111.084479,42.680415],[-111.043786,42.811144],[-111.220084,42.858305]]]},"properties":{"id":"83111","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.030246,42.381099],[-113.669913,42.314153],[-113.607137,42.449682],[-113.647893,42.542545],[-113.68769,42.524676],[-113.931799,42.535275],[-114.000691,42.526434],[-114.030246,42.381099]]]},"properties":{"id":"83318","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.661105,42.313326],[-113.784415,42.198992],[-113.725537,42.105701],[-113.496405,42.062855],[-113.554437,41.99216],[-113.00082,41.998302],[-112.870728,42.327892],[-113.001701,42.429484],[-113.148291,42.473201],[-113.467927,42.399932],[-113.661105,42.313326]]]},"properties":{"id":"83342","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.995379,44.233956],[-113.452266,44.863609],[-113.673784,44.655868],[-113.476859,44.447919],[-113.320672,44.232998],[-112.995379,44.233956]]]},"properties":{"id":"83464","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.887983,43.026728],[-115.895773,43.070123],[-115.836457,43.069544],[-115.836417,43.040892],[-115.89567,42.982545],[-115.777192,42.941679],[-115.558524,42.971408],[-115.508761,43.129436],[-115.35577,43.236929],[-115.08663,43.342168],[-115.029562,43.728478],[-115.516954,43.87413],[-115.856777,43.601438],[-115.927149,43.171445],[-115.887983,43.026728]]]},"properties":{"id":"83647","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.301481,42.884329],[-115.35577,43.236929],[-115.508761,43.129436],[-115.488668,43.0268],[-115.441666,42.909367],[-115.301481,42.884329]]]},"properties":{"id":"83623","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.266405,43.976212],[-115.516954,43.87413],[-115.029562,43.728478],[-114.989384,43.858611],[-114.971258,43.938134],[-114.99075,43.949725],[-115.266405,43.976212]]]},"properties":{"id":"83601","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.69674,43.926091],[-116.645422,43.889024],[-116.638226,43.905097],[-116.69674,43.926091]]]},"properties":{"id":"83636","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.349817,45.891187],[-116.248135,46.064901],[-116.240228,46.162461],[-116.3105,46.168844],[-116.514637,46.106885],[-116.625523,46.037491],[-116.701977,45.996177],[-116.794562,45.856049],[-116.522726,45.676912],[-116.349817,45.891187]]]},"properties":{"id":"83522","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.436385,43.89349],[-112.254956,43.839411],[-112.09718,43.971724],[-112.156304,43.985097],[-112.436385,43.89349]]]},"properties":{"id":"83425","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.94382,43.758424],[-111.982871,43.710005],[-112.076079,43.669623],[-112.078351,43.654764],[-112.066724,43.626344],[-111.785431,43.626873],[-111.717633,43.655272],[-111.738447,43.66676],[-111.94382,43.758424]]]},"properties":{"id":"83442","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.977428,43.825702],[-112.096756,43.728582],[-112.091377,43.676549],[-111.982871,43.710005],[-111.94382,43.758424],[-111.977428,43.825702]]]},"properties":{"id":"83434","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.744171,42.81163],[-114.069419,42.641747],[-113.932021,42.591049],[-113.74442,42.589751],[-113.744171,42.81163]]]},"properties":{"id":"83347","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.89171,46.813378],[-117.03981,46.82594],[-117.039698,46.603996],[-116.789658,46.643215],[-116.789748,46.650932],[-116.852874,46.807927],[-116.867546,46.809455],[-116.89171,46.813378]],[[-117.015032,46.72977],[-117.017075,46.731113],[-117.011987,46.731086],[-117.01196,46.729868],[-117.015032,46.72977]]]},"properties":{"id":"83843","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.003257,43.341011],[-116.867445,43.199119],[-116.751431,43.297079],[-116.759399,43.463416],[-116.841895,43.593273],[-116.91795,43.612256],[-117.003257,43.341011]]]},"properties":{"id":"83639","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.872795,42.333957],[-115.037963,42.102066],[-114.499642,42.034537],[-114.496348,42.214241],[-114.67366,42.21564],[-114.770504,42.372904],[-114.872795,42.333957]]]},"properties":{"id":"83302","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.004307,47.463104],[-115.974085,47.492718],[-115.943119,47.529901],[-115.994236,47.566504],[-116.004307,47.463104]]]},"properties":{"id":"83849","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.714312,45.013594],[-116.033343,44.609323],[-116.162074,44.687317],[-115.899669,44.235634],[-115.682629,44.235465],[-115.49697,44.234279],[-115.294462,44.339411],[-114.812434,44.808138],[-114.694126,45.197114],[-115.236227,45.196624],[-115.714312,45.013594]]]},"properties":{"id":"83611","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.809237,45.169866],[-115.714312,45.013594],[-115.236227,45.196624],[-115.696073,45.195904],[-115.809237,45.169866]]]},"properties":{"id":"83677","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.878045,44.543284],[-116.452787,44.462343],[-116.528215,44.581116],[-116.583208,44.614785],[-116.899711,44.840609],[-117.079836,44.682528],[-116.878045,44.543284]]]},"properties":{"id":"83610","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.452787,44.462343],[-116.878045,44.543284],[-116.556021,44.15171],[-116.452012,44.151417],[-116.299757,44.444107],[-116.320358,44.444084],[-116.452787,44.462343]]]},"properties":{"id":"83645","state_code":"16"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.17656,40.020918],[-91.195619,40.049275],[-91.221448,40.049295],[-91.17656,40.020918]]]},"properties":{"id":"62359","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.357214,40.175758],[-91.377135,40.199142],[-91.418498,40.199623],[-91.510518,40.127326],[-91.488131,40.024576],[-91.318939,40.066526],[-91.357214,40.175758]]]},"properties":{"id":"62376","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.977967,39.788808],[-91.087,39.803685],[-91.148064,39.757062],[-91.147678,39.75707],[-91.10961,39.64667],[-90.96366,39.62254],[-90.916024,39.668155],[-90.953299,39.692895],[-90.977967,39.788808]]]},"properties":{"id":"62312","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.165843,39.837013],[-91.242283,39.77627],[-91.148064,39.757062],[-91.087,39.803685],[-91.165843,39.837013]]]},"properties":{"id":"62365","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.562543,38.918112],[-89.404996,38.741015],[-89.372068,38.741176],[-89.339299,38.821735],[-89.326983,38.946302],[-89.343387,39.027853],[-89.38972,39.027826],[-89.504479,38.974597],[-89.562543,38.918112]]]},"properties":{"id":"62246","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.326983,38.946302],[-89.255311,38.829734],[-89.231076,38.82543],[-89.195336,38.825813],[-89.245746,39.029948],[-89.299019,39.028218],[-89.343387,39.027853],[-89.326983,38.946302]]]},"properties":{"id":"62262","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.054097,42.299307],[-88.934571,42.305171],[-88.930405,42.321555],[-88.931789,42.32676],[-89.054097,42.299307]]]},"properties":{"id":"61114","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.789492,41.350319],[-89.839788,41.484966],[-89.857349,41.486789],[-89.876075,41.379683],[-89.823422,41.337554],[-89.789492,41.350319]]]},"properties":{"id":"61344","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.631652,41.603134],[-89.708431,41.584619],[-89.72009,41.514842],[-89.715086,41.506743],[-89.758371,41.496664],[-89.685109,41.470089],[-89.669351,41.460569],[-89.627058,41.467032],[-89.500726,41.480443],[-89.516381,41.618781],[-89.631652,41.603134]]]},"properties":{"id":"61376","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.278065,41.327969],[-89.28245,41.333259],[-89.335324,41.301916],[-89.332956,41.301167],[-89.281434,41.313413],[-89.271188,41.320816],[-89.278065,41.327969]]]},"properties":{"id":"61322","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.780143,41.212694],[-89.777415,41.15527],[-89.705904,41.126932],[-89.677015,41.112366],[-89.592,41.1122],[-89.520708,41.172318],[-89.625656,41.241191],[-89.747559,41.233922],[-89.780143,41.212694]]]},"properties":{"id":"61421","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.823422,41.337554],[-89.856762,41.328538],[-89.830084,41.228687],[-89.780143,41.212694],[-89.747559,41.233922],[-89.741681,41.316383],[-89.789492,41.350319],[-89.823422,41.337554]]]},"properties":{"id":"61345","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.459719,40.42063],[-88.460262,40.369493],[-88.460302,40.31867],[-88.313724,40.354673],[-88.309483,40.398771],[-88.459719,40.42063]]]},"properties":{"id":"61845","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.084761,39.865295],[-88.009488,39.879432],[-88.056828,39.937852],[-88.105589,39.935706],[-88.1229,39.910822],[-88.084761,39.865295]]]},"properties":{"id":"61852","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.216636,39.798944],[-88.088949,39.792065],[-88.084445,39.792065],[-88.074996,39.812994],[-88.084761,39.865295],[-88.1229,39.910822],[-88.160531,39.923651],[-88.176871,39.879744],[-88.198352,39.879518],[-88.216636,39.798944]]]},"properties":{"id":"61956","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.346571,40.270962],[-88.460418,40.281935],[-88.463335,40.163868],[-88.462881,40.148306],[-88.388796,40.156772],[-88.351345,40.214969],[-88.346571,40.270962]]]},"properties":{"id":"61853","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.104596,40.054868],[-88.009736,40.047339],[-87.982036,40.076047],[-87.973071,40.191153],[-87.970256,40.196405],[-87.994611,40.224983],[-88.001657,40.239665],[-88.04013,40.254238],[-88.085885,40.224722],[-88.104596,40.054868]]]},"properties":{"id":"61873","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.942103,40.225482],[-87.994611,40.224983],[-87.970256,40.196405],[-87.961419,40.192756],[-87.960482,40.18552],[-87.969823,40.185473],[-87.973071,40.191153],[-87.982036,40.076047],[-87.939949,40.083635],[-87.92293,40.19287],[-87.942103,40.225482]]]},"properties":{"id":"61859","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.970256,40.196405],[-87.973071,40.191153],[-87.969823,40.185473],[-87.960482,40.18552],[-87.961419,40.192756],[-87.970256,40.196405]]]},"properties":{"id":"61871","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.519107,40.105272],[-88.462966,40.025533],[-88.425986,40.04017],[-88.462881,40.148306],[-88.463335,40.163868],[-88.519107,40.105272]]]},"properties":{"id":"61884","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.64792,39.487699],[-87.531375,39.405901],[-87.531709,39.483922],[-87.64792,39.487699]]]},"properties":{"id":"62423","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.860495,38.976782],[-88.929411,38.918913],[-88.826373,38.824434],[-88.685286,38.785253],[-88.580767,38.875972],[-88.754162,38.913127],[-88.777928,38.91968],[-88.860495,38.976782]]]},"properties":{"id":"62838","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.79031,38.655643],[-89.75193,38.553879],[-89.733475,38.553719],[-89.644117,38.553499],[-89.642814,38.575342],[-89.596025,38.691277],[-89.716806,38.687798],[-89.79031,38.655643]],[[-89.756565,38.595792],[-89.752029,38.600678],[-89.74719,38.600713],[-89.747238,38.593922],[-89.756565,38.595792]]]},"properties":{"id":"62293","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.42421,39.622572],[-88.456895,39.593319],[-88.43592,39.563499],[-88.266414,39.565031],[-88.265888,39.598007],[-88.42421,39.622572]]]},"properties":{"id":"61931","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.798978,41.719232],[-87.752759,41.690948],[-87.73973,41.690891],[-87.721125,41.71317],[-87.721654,41.727788],[-87.741067,41.734524],[-87.794634,41.733574],[-87.798978,41.719232]]]},"properties":{"id":"60453","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.889432,41.819672],[-87.907793,41.774687],[-87.895911,41.742433],[-87.862832,41.754043],[-87.801961,41.806424],[-87.802268,41.813966],[-87.839931,41.805636],[-87.861776,41.818009],[-87.889432,41.819672]]]},"properties":{"id":"60525","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.816327,42.018749],[-87.846618,42.041184],[-87.865637,42.027024],[-87.855851,41.989057],[-87.816327,42.018749]]]},"properties":{"id":"60068","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.92483,42.177758],[-87.911046,42.153133],[-87.910489,42.153135],[-87.820893,42.152537],[-87.851868,42.203724],[-87.886881,42.200234],[-87.92483,42.177758]]]},"properties":{"id":"60015","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.72747,42.055256],[-87.708856,42.052429],[-87.709717,42.040944],[-87.660338,42.041047],[-87.669758,42.071629],[-87.73242,42.064666],[-87.72747,42.055256]]]},"properties":{"id":"60201","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.219699,42.014562],[-88.165948,41.995218],[-88.144728,42.016142],[-88.154324,42.029954],[-88.138491,42.039571],[-88.178996,42.03902],[-88.193936,42.045525],[-88.203167,42.045059],[-88.219699,42.014562]]]},"properties":{"id":"60107","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.800287,41.762699],[-87.825321,41.762169],[-87.81822,41.733109],[-87.811086,41.709296],[-87.798053,41.70441],[-87.798978,41.719232],[-87.794634,41.733574],[-87.794875,41.751781],[-87.800287,41.762699]]]},"properties":{"id":"60455","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.834204,41.898624],[-87.859764,41.890703],[-87.863164,41.871182],[-87.840761,41.864342],[-87.833273,41.859975],[-87.827325,41.879175],[-87.834204,41.898624]]]},"properties":{"id":"60153","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.846842,41.717964],[-87.811086,41.709296],[-87.81822,41.733109],[-87.848028,41.739833],[-87.846842,41.717964]]]},"properties":{"id":"60457","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.788608,41.968861],[-87.787735,41.982899],[-87.78834,41.982897],[-87.856369,41.983628],[-87.869617,41.973133],[-87.857447,41.959583],[-87.836699,41.95936],[-87.788608,41.968861]]]},"properties":{"id":"60656","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.144728,42.016142],[-88.122238,41.989112],[-88.060581,41.999514],[-88.031315,42.021595],[-88.031254,42.025042],[-88.060313,42.028141],[-88.091562,42.026844],[-88.099568,42.026564],[-88.154324,42.029954],[-88.144728,42.016142]]]},"properties":{"id":"60193","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.840761,41.864342],[-87.842735,41.850711],[-87.832873,41.850911],[-87.833273,41.859975],[-87.840761,41.864342]]]},"properties":{"id":"60141","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.781972,41.80436],[-87.802094,41.797847],[-87.800287,41.762699],[-87.794875,41.751781],[-87.745641,41.756927],[-87.741822,41.76192],[-87.741464,41.771138],[-87.740318,41.793054],[-87.743391,41.816511],[-87.781972,41.80436]]]},"properties":{"id":"60638","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.741822,41.76192],[-87.741629,41.757303],[-87.741067,41.734524],[-87.721436,41.734862],[-87.687395,41.735444],[-87.683047,41.757379],[-87.686682,41.757478],[-87.741464,41.771138],[-87.741822,41.76192]]]},"properties":{"id":"60652","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.741629,41.757303],[-87.741822,41.76192],[-87.745641,41.756927],[-87.741629,41.757303]]],[[[-87.684646,41.792023],[-87.740318,41.793054],[-87.741464,41.771138],[-87.686682,41.757478],[-87.684646,41.792023]]]]},"properties":{"id":"60629","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.766645,41.960491],[-87.765648,41.931738],[-87.726979,41.93179],[-87.726382,41.931799],[-87.727828,41.960947],[-87.727869,41.962531],[-87.766645,41.960491]]]},"properties":{"id":"60641","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.679102,41.990687],[-87.718655,41.98294],[-87.720903,41.98291],[-87.727869,41.962531],[-87.727828,41.960947],[-87.678233,41.961466],[-87.674609,41.983357],[-87.679102,41.990687]]]},"properties":{"id":"60625","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.62516,41.926869],[-87.677525,41.932745],[-87.678135,41.932285],[-87.670201,41.917886],[-87.669703,41.917902],[-87.648272,41.91092],[-87.615956,41.918831],[-87.62516,41.926869]]]},"properties":{"id":"60614","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.658857,42.023431],[-87.679742,42.019441],[-87.678943,41.997919],[-87.650803,41.998485],[-87.658857,42.023431]]]},"properties":{"id":"60626","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.628222,41.89752],[-87.647745,41.896399],[-87.647622,41.889049],[-87.641378,41.888632],[-87.633966,41.887064],[-87.628004,41.887506],[-87.628222,41.89752]]]},"properties":{"id":"60654","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.713469,41.549753],[-87.713331,41.534424],[-87.713295,41.529137],[-87.7059,41.527961],[-87.664645,41.524334],[-87.66257,41.5388],[-87.698529,41.549832],[-87.713469,41.549753]]]},"properties":{"id":"60422","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.670909,41.447347],[-87.66172,41.467511],[-87.654734,41.486071],[-87.674329,41.50407],[-87.698161,41.506164],[-87.703276,41.49163],[-87.709872,41.464377],[-87.709689,41.456439],[-87.670909,41.447347]]]},"properties":{"id":"60466","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.651255,38.915206],[-87.807875,38.869279],[-87.757052,38.834895],[-87.531145,38.852507],[-87.519469,38.942981],[-87.651255,38.915206]]]},"properties":{"id":"62427","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.581763,42.006758],[-88.676014,41.933268],[-88.665403,41.917858],[-88.660047,41.849756],[-88.602297,41.821117],[-88.479453,41.936117],[-88.47787,41.971894],[-88.477872,41.982765],[-88.581763,42.006758]]]},"properties":{"id":"60151","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.061215,41.633808],[-88.973528,41.483532],[-88.825965,41.485317],[-88.816219,41.523018],[-88.819106,41.68222],[-88.828746,41.732219],[-88.940191,41.709815],[-89.052058,41.641253],[-89.061215,41.633808]]]},"properties":{"id":"60518","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.119631,40.399396],[-89.264441,40.361868],[-89.263578,40.317169],[-89.263418,40.310661],[-89.148102,40.245116],[-89.07812,40.28249],[-89.093652,40.319804],[-89.119631,40.399396]]]},"properties":{"id":"61754","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.855551,40.12021],[-88.840701,40.116832],[-88.842861,40.127743],[-88.866561,40.127364],[-88.878659,40.130405],[-88.878923,40.119694],[-88.855551,40.12021]]]},"properties":{"id":"61750","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.404688,39.725124],[-88.175788,39.709891],[-88.12031,39.727682],[-88.216636,39.798944],[-88.198352,39.879518],[-88.35787,39.879419],[-88.396672,39.864381],[-88.423071,39.768756],[-88.404688,39.725124]]]},"properties":{"id":"61953","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.074996,39.812994],[-88.065576,39.792128],[-88.084445,39.792065],[-88.088949,39.792065],[-88.082855,39.768518],[-87.986337,39.722095],[-87.908677,39.798979],[-87.928025,39.861548],[-87.956532,39.879724],[-88.009488,39.879432],[-88.084761,39.865295],[-88.074996,39.812994]]]},"properties":{"id":"61942","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.959968,41.945547],[-88.003327,41.9489],[-88.049494,41.954219],[-88.056042,41.928383],[-88.048192,41.907386],[-87.999053,41.905038],[-87.969172,41.912184],[-87.96651,41.931238],[-87.959968,41.945547]]]},"properties":{"id":"60101","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.001767,41.713272],[-88.030463,41.72666],[-88.029327,41.695531],[-88.001767,41.713272]]],[[[-88.063968,41.749924],[-88.069009,41.728134],[-88.030561,41.72893],[-88.012457,41.74377],[-88.052407,41.779155],[-88.086288,41.763874],[-88.063968,41.749924]]]]},"properties":{"id":"60517","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.14413,41.887873],[-88.146323,41.857291],[-88.078992,41.859208],[-88.090467,41.888471],[-88.124588,41.88778],[-88.127456,41.887737],[-88.14413,41.887873]]]},"properties":{"id":"60187","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.090467,41.888471],[-88.078992,41.859208],[-88.071904,41.816826],[-88.047954,41.831574],[-88.04829,41.90386],[-88.082207,41.896964],[-88.090467,41.888471]]]},"properties":{"id":"60137","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.319274,41.969003],[-88.340391,41.897722],[-88.262276,41.899236],[-88.262975,41.937027],[-88.27595,41.969471],[-88.284911,41.976029],[-88.319274,41.969003]]]},"properties":{"id":"60174","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.880677,39.850768],[-87.835836,39.747125],[-87.812293,39.744029],[-87.809092,39.83986],[-87.880677,39.850768]]]},"properties":{"id":"61940","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.860495,38.976782],[-88.777928,38.91968],[-88.786497,38.999559],[-88.824473,39.093463],[-88.917963,39.104284],[-88.860495,38.976782]]]},"properties":{"id":"62458","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.245746,39.029948],[-89.195336,38.825813],[-89.165954,38.825486],[-88.953016,38.867827],[-88.955641,38.914646],[-89.06612,39.027072],[-89.176054,39.057252],[-89.235625,39.068073],[-89.245746,39.029948]]]},"properties":{"id":"62471","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.165954,38.825486],[-89.010577,38.788934],[-88.999696,38.823283],[-89.165954,38.825486]]]},"properties":{"id":"62892","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.262477,40.837359],[-88.219855,40.733195],[-88.123975,40.720003],[-88.125952,40.778095],[-88.126719,40.807195],[-88.128072,40.851336],[-88.185388,40.85028],[-88.262477,40.837359]]]},"properties":{"id":"60959","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.35234,40.70275],[-88.29152,40.63219],[-88.23529,40.63243],[-88.23788,40.70404],[-88.219855,40.733195],[-88.262477,40.837359],[-88.33623,40.81779],[-88.35234,40.70275]]]},"properties":{"id":"60921","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.076752,37.994268],[-89.034994,37.979461],[-89.065159,37.99426],[-89.076752,37.994268]]]},"properties":{"id":"62822","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.844976,37.950677],[-88.826454,37.950667],[-88.834336,37.965214],[-88.844976,37.950677]]]},"properties":{"id":"62856","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.0062,38.125197],[-89.054633,38.124735],[-89.129823,38.111892],[-89.133183,38.052216],[-89.053681,38.037668],[-89.012129,38.062257],[-89.0062,38.125197]]]},"properties":{"id":"62884","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362074,40.473643],[-90.368604,40.554194],[-90.446739,40.552305],[-90.503601,40.506626],[-90.447783,40.452056],[-90.362074,40.473643]]]},"properties":{"id":"61459","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.251325,40.73369],[-90.328003,40.714119],[-90.291008,40.65252],[-90.21467,40.647438],[-90.138805,40.713243],[-90.251325,40.73369]]]},"properties":{"id":"61544","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.193177,37.776905],[-88.077319,37.805267],[-88.092159,37.890947],[-88.146288,37.858728],[-88.326432,37.824818],[-88.290077,37.776654],[-88.193177,37.776905]]]},"properties":{"id":"62979","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.54391,41.59738],[-88.557782,41.597081],[-88.54948,41.613647],[-88.547975,41.624103],[-88.58359,41.62193],[-88.619489,41.576805],[-88.600604,41.57067],[-88.59973,41.55338],[-88.596906,41.486243],[-88.595371,41.427786],[-88.4332,41.48094],[-88.4042,41.52697],[-88.54391,41.59738]]]},"properties":{"id":"60541","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.484853,37.994738],[-88.494377,37.994897],[-88.49443,37.966206],[-88.484853,37.994738]]]},"properties":{"id":"62829","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.297152,40.628052],[-91.269135,40.521164],[-91.247851,40.63839],[-91.297152,40.628052]]]},"properties":{"id":"62358","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.018114,40.282772],[-90.978144,40.260055],[-90.798827,40.222837],[-90.757423,40.192711],[-90.740293,40.221496],[-90.67794,40.278562],[-90.754026,40.281017],[-90.867871,40.36981],[-90.974317,40.395785],[-91.018114,40.282772]]]},"properties":{"id":"62367","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.900969,41.054999],[-90.927161,41.02948],[-90.901072,41.048551],[-90.900969,41.054999]]],[[[-90.984657,41.147785],[-90.900892,41.069748],[-90.879721,41.142333],[-90.903852,41.15706],[-90.984657,41.147785]]]]},"properties":{"id":"61442","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.319486,41.324295],[-90.280601,41.324206],[-90.202377,41.382859],[-90.328637,41.41144],[-90.328983,41.396873],[-90.319486,41.324295]]]},"properties":{"id":"61274","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.847171,40.87284],[-87.673261,40.890929],[-87.673549,40.920134],[-87.673948,40.956159],[-87.813338,41.004932],[-87.831661,40.88774],[-87.847171,40.87284]]]},"properties":{"id":"60951","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526248,40.672857],[-87.525696,40.81725],[-87.576904,40.826612],[-87.59077,40.817377],[-87.650112,40.709075],[-87.526248,40.672857]]]},"properties":{"id":"60966","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.360504,39.089872],[-88.323893,38.945357],[-88.246095,39.097744],[-88.316701,39.1159],[-88.360504,39.089872]]]},"properties":{"id":"62479","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.037283,38.966726],[-88.114494,38.847895],[-87.976495,38.849673],[-87.992285,38.894991],[-88.026949,38.922146],[-88.03795,38.923111],[-88.037942,38.937601],[-88.027317,38.937504],[-88.037283,38.966726]]]},"properties":{"id":"62475","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.719648,38.483712],[-88.838751,38.486934],[-88.866117,38.483569],[-88.923491,38.425026],[-88.793112,38.43145],[-88.745737,38.475477],[-88.719648,38.483712]]]},"properties":{"id":"62889","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.965304,38.475857],[-88.979578,38.475497],[-89.042207,38.408832],[-89.034262,38.392552],[-88.923491,38.425026],[-88.866117,38.483569],[-88.959913,38.475984],[-88.965304,38.475857]]]},"properties":{"id":"62830","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.745737,38.475477],[-88.643898,38.364271],[-88.642243,38.457539],[-88.650989,38.475631],[-88.699121,38.487664],[-88.719648,38.483712],[-88.745737,38.475477]]]},"properties":{"id":"62851","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.146162,39.137672],[-90.171027,39.086683],[-90.123538,39.086962],[-90.090993,39.142224],[-90.146162,39.137672]]]},"properties":{"id":"62079","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.919187,37.596793],[-88.794211,37.556643],[-88.718225,37.599458],[-88.803336,37.67791],[-88.928426,37.59716],[-88.919187,37.596793]]]},"properties":{"id":"62922","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.602551,37.425266],[-88.596428,37.526202],[-88.631228,37.536102],[-88.803759,37.51233],[-88.768431,37.408083],[-88.677474,37.384342],[-88.602551,37.425266]]]},"properties":{"id":"62985","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.558335,42.049973],[-88.57759,42.065316],[-88.573273,42.053791],[-88.558335,42.049973]]]},"properties":{"id":"60109","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.745612,41.207464],[-87.991248,41.205331],[-87.895606,41.147731],[-87.836474,41.147898],[-87.729605,41.158166],[-87.720975,41.192906],[-87.745612,41.207464]]]},"properties":{"id":"60914","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.54391,41.59738],[-88.54948,41.613647],[-88.557782,41.597081],[-88.54391,41.59738]]]},"properties":{"id":"60536","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.442444,40.83895],[-90.499593,40.816615],[-90.443438,40.759593],[-90.329093,40.758057],[-90.30997,40.801278],[-90.333138,40.845143],[-90.442444,40.83895]]]},"properties":{"id":"61410","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.357458,41.020246],[-90.353865,41.020211],[-90.351457,41.034773],[-90.369141,41.034983],[-90.357458,41.020246]]]},"properties":{"id":"61439","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.20674,42.410571],[-88.197532,42.409249],[-88.189528,42.407295],[-88.199217,42.473474],[-88.199528,42.49457],[-88.23789,42.494895],[-88.276448,42.44028],[-88.276727,42.419346],[-88.20674,42.410571]]]},"properties":{"id":"60081","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.876157,42.435655],[-87.971971,42.480197],[-88.003479,42.44832],[-87.989028,42.400189],[-87.9154,42.399799],[-87.876157,42.435655]]]},"properties":{"id":"60083","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.29068,42.209546],[-88.286488,42.190121],[-88.247501,42.191107],[-88.199378,42.202966],[-88.209621,42.24869],[-88.29068,42.209546]]]},"properties":{"id":"60013","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.83282,41.06876],[-88.834041,41.01715],[-88.846262,40.973228],[-88.81576,40.97408],[-88.806259,40.98515],[-88.83282,41.06876]]],[[[-88.83282,41.06876],[-88.77536,41.098593],[-88.64296,41.10776],[-88.722018,41.194086],[-88.931743,41.207264],[-89.009195,41.148649],[-89.047356,41.104787],[-88.930631,41.044499],[-88.930654,41.030573],[-88.91117,41.03043],[-88.83282,41.06876]]]]},"properties":{"id":"61364","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.800696,41.485272],[-88.707443,41.458319],[-88.65404,41.456457],[-88.596906,41.486243],[-88.59973,41.55338],[-88.611646,41.567252],[-88.600604,41.57067],[-88.619489,41.576805],[-88.67376,41.58681],[-88.688294,41.589651],[-88.816219,41.523018],[-88.825965,41.485317],[-88.800696,41.485272]]]},"properties":{"id":"60551","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.066592,41.009358],[-89.12446,40.94123],[-89.047718,40.925749],[-88.988388,41.014412],[-89.066592,41.009358]]]},"properties":{"id":"61358","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.707443,41.458319],[-88.800696,41.485272],[-88.770645,41.442498],[-88.760256,41.455966],[-88.750074,41.455062],[-88.708183,41.458191],[-88.707443,41.458319]]]},"properties":{"id":"60549","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.770645,41.442498],[-88.750074,41.455062],[-88.760256,41.455966],[-88.770645,41.442498]]]},"properties":{"id":"60557","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.813201,38.569304],[-87.651529,38.568166],[-87.610877,38.643917],[-87.722287,38.643275],[-87.777656,38.622351],[-87.813201,38.569304]]]},"properties":{"id":"62460","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.390256,41.837569],[-89.390177,41.822728],[-89.361453,41.822744],[-89.370956,41.837578],[-89.390256,41.837569]]]},"properties":{"id":"61057","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.42352,40.61734],[-88.31045,40.6175],[-88.29152,40.63219],[-88.35234,40.70275],[-88.42854,40.67272],[-88.45947,40.64661],[-88.42352,40.61734]]]},"properties":{"id":"61775","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.614362,40.669491],[-88.56433,40.616572],[-88.45947,40.64661],[-88.42854,40.67272],[-88.451112,40.830981],[-88.465987,40.8468],[-88.621282,40.771685],[-88.614362,40.669491]]]},"properties":{"id":"61739","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.93092,40.97189],[-88.846262,40.973228],[-88.834041,41.01715],[-88.91117,41.03043],[-88.930654,41.030573],[-88.93092,40.97189]]]},"properties":{"id":"61333","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.650528,40.341499],[-89.715175,40.3334],[-89.752869,40.318556],[-89.772084,40.303722],[-89.60139,40.232923],[-89.563686,40.24793],[-89.565188,40.32072],[-89.650528,40.341499]]]},"properties":{"id":"62682","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.362509,39.964059],[-89.348857,39.961147],[-89.348792,39.971428],[-89.3625,39.968465],[-89.362509,39.964059]]]},"properties":{"id":"62541","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.569161,40.491017],[-90.557211,40.500029],[-90.570174,40.505935],[-90.569161,40.491017]]]},"properties":{"id":"61416","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.349451,42.298618],[-88.351297,42.356577],[-88.413566,42.414409],[-88.471683,42.414246],[-88.579637,42.356657],[-88.551265,42.266647],[-88.471789,42.241422],[-88.412877,42.228422],[-88.379397,42.254715],[-88.349451,42.298618]]]},"properties":{"id":"60098","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.953235,40.502735],[-89.017918,40.441641],[-88.902798,40.517317],[-88.953235,40.502735]]]},"properties":{"id":"61704","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.460262,40.369493],[-88.459719,40.42063],[-88.497401,40.515103],[-88.553725,40.529167],[-88.612868,40.500309],[-88.574309,40.376784],[-88.460262,40.369493]]]},"properties":{"id":"61770","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.78133,40.559711],[-88.806899,40.515986],[-88.797384,40.501324],[-88.686817,40.500794],[-88.703915,40.573759],[-88.78133,40.559711]]]},"properties":{"id":"61730","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.923365,39.747393],[-88.81123,39.681922],[-88.756929,39.710665],[-88.801679,39.791796],[-88.837028,39.762989],[-88.888966,39.752373],[-88.923365,39.747393]]]},"properties":{"id":"61925","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.048688,39.776195],[-89.055915,39.773579],[-89.055786,39.758109],[-89.037972,39.772253],[-89.048688,39.776195]]]},"properties":{"id":"62514","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.134716,39.910598],[-89.029695,39.875292],[-89.028822,39.875644],[-89.011846,39.962905],[-89.104866,40.00555],[-89.143457,39.91792],[-89.134716,39.910598]]]},"properties":{"id":"62573","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.99953,39.768613],[-88.980434,39.765631],[-88.969119,39.777574],[-88.99953,39.768613]]]},"properties":{"id":"62532","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.029987,38.998714],[-89.914973,38.99882],[-89.852671,38.998502],[-89.860557,39.056392],[-89.909057,39.100153],[-90.05771,39.071071],[-90.029987,38.998714]]]},"properties":{"id":"62014","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.848559,39.078016],[-89.817522,39.078185],[-89.792841,39.108022],[-89.792797,39.117422],[-89.788711,39.144996],[-89.795402,39.196996],[-89.906955,39.150695],[-89.909057,39.100153],[-89.860557,39.056392],[-89.860308,39.060052],[-89.848559,39.078016]]]},"properties":{"id":"62033","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.080487,39.521042],[-89.925414,39.44571],[-89.90688,39.500561],[-89.907519,39.52225],[-90.07451,39.521104],[-90.080487,39.521042]]]},"properties":{"id":"62667","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.147666,38.984768],[-90.216979,38.903076],[-90.131907,38.857948],[-90.107879,38.889434],[-90.096385,38.92152],[-90.089484,38.923504],[-90.069222,38.923746],[-90.047883,38.980065],[-90.041555,38.998642],[-90.147666,38.984768]]]},"properties":{"id":"62002","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.669585,38.888492],[-89.778036,38.939665],[-89.815125,38.874266],[-89.827132,38.815468],[-89.719123,38.819619],[-89.669427,38.873853],[-89.669585,38.888492]]]},"properties":{"id":"62001","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.934401,38.938044],[-89.852574,38.889082],[-89.852585,38.896456],[-89.833415,38.896225],[-89.84307,38.881802],[-89.815125,38.874266],[-89.778036,38.939665],[-89.769927,38.955351],[-89.771945,38.961392],[-89.77815,38.961473],[-89.852671,38.998502],[-89.914973,38.99882],[-89.934184,38.951215],[-89.934401,38.938044]]]},"properties":{"id":"62097","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.027724,38.954796],[-90.047883,38.980065],[-90.069222,38.923746],[-90.065167,38.897134],[-90.053785,38.878104],[-90.020462,38.866048],[-89.973948,38.890265],[-90.027724,38.954796]]]},"properties":{"id":"62010","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.053888,38.803916],[-90.083782,38.833419],[-90.067082,38.789267],[-90.053888,38.803916]]]},"properties":{"id":"62087","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.90514,38.759095],[-90.038604,38.745948],[-90.025394,38.734022],[-89.984878,38.730869],[-89.928415,38.745017],[-89.90514,38.759095]]]},"properties":{"id":"62034","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.095928,38.844852],[-90.038361,38.843449],[-90.020462,38.866048],[-90.053785,38.878104],[-90.103457,38.852533],[-90.095928,38.844852]]]},"properties":{"id":"62095","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.84307,38.881802],[-89.833415,38.896225],[-89.852585,38.896456],[-89.852574,38.889082],[-89.84307,38.881802]]]},"properties":{"id":"62046","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.913452,38.657478],[-89.874739,38.64974],[-89.818896,38.655848],[-89.848099,38.756395],[-89.90514,38.759095],[-89.928415,38.745017],[-89.928296,38.72581],[-89.913452,38.657478]]]},"properties":{"id":"62294","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.817394,38.762412],[-89.77817,38.742591],[-89.719123,38.819619],[-89.827132,38.815468],[-89.817394,38.762412]]]},"properties":{"id":"62061","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.4611,40.878738],[-89.274296,40.851309],[-89.217341,40.874239],[-89.1668,40.89739],[-89.184758,40.926495],[-89.23167,40.976823],[-89.237973,40.98389],[-89.324679,40.981181],[-89.46157,40.929204],[-89.497514,40.87873],[-89.4611,40.878738]]]},"properties":{"id":"61570","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.824987,40.122899],[-89.646088,40.153547],[-89.632918,40.151192],[-89.60139,40.232923],[-89.772084,40.303722],[-89.810008,40.288624],[-89.828971,40.125679],[-89.824987,40.122899]]]},"properties":{"id":"62664","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.904566,40.296858],[-89.810008,40.288624],[-89.772084,40.303722],[-89.752869,40.318556],[-89.828163,40.382603],[-89.904566,40.296858]]]},"properties":{"id":"61532","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.815001,39.909568],[-89.994759,39.981704],[-89.994506,39.901925],[-89.815001,39.909568]]]},"properties":{"id":"62688","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.604996,41.26421],[-90.590395,41.285365],[-90.608727,41.313475],[-90.627938,41.328242],[-90.839807,41.314067],[-90.840432,41.156968],[-90.764809,41.068603],[-90.745355,41.06833],[-90.627293,41.132155],[-90.585979,41.247329],[-90.596831,41.258042],[-90.610358,41.254952],[-90.604996,41.26421]]]},"properties":{"id":"61231","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.492055,41.340734],[-90.584412,41.313117],[-90.590395,41.285365],[-90.604996,41.26421],[-90.596831,41.258042],[-90.585979,41.247329],[-90.500797,41.261825],[-90.445018,41.267983],[-90.434089,41.315349],[-90.492055,41.340734]]]},"properties":{"id":"61281","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.903852,41.15706],[-90.937652,41.332327],[-91.07207,41.333361],[-90.984657,41.147785],[-90.903852,41.15706]]]},"properties":{"id":"61272","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.257285,38.387109],[-90.332042,38.396104],[-90.255759,38.131907],[-90.257001,38.224235],[-90.257285,38.387109]]]},"properties":{"id":"62295","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.208235,38.088794],[-90.094455,38.018223],[-90.036385,38.05815],[-90.034602,38.126909],[-90.119079,38.172631],[-90.133097,38.153749],[-90.132533,38.148184],[-90.138327,38.148181],[-90.138319,38.154071],[-90.208235,38.088794]]]},"properties":{"id":"62277","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.642037,39.688301],[-90.437834,39.714184],[-90.437696,39.727192],[-90.465522,39.78268],[-90.645185,39.706288],[-90.642037,39.688301]]]},"properties":{"id":"62621","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.69978,40.586325],[-89.756239,40.602122],[-89.756167,40.596958],[-89.69978,40.586325]]],[[[-89.69978,40.586325],[-89.688845,40.558512],[-89.675256,40.554054],[-89.658003,40.567438],[-89.637101,40.622238],[-89.610339,40.65199],[-89.624834,40.666781],[-89.675311,40.680416],[-89.718157,40.691774],[-89.735471,40.666988],[-89.69978,40.586325]]]]},"properties":{"id":"61607","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.595536,40.699601],[-89.583897,40.688937],[-89.576097,40.692437],[-89.551045,40.721705],[-89.574287,40.7319],[-89.583974,40.732019],[-89.593523,40.732058],[-89.594614,40.703134],[-89.595536,40.699601]]]},"properties":{"id":"61603","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.776493,40.558332],[-89.784236,40.5493],[-89.741322,40.550806],[-89.776493,40.558332]]]},"properties":{"id":"61539","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.312889,38.082774],[-89.133183,38.052216],[-89.129823,38.111892],[-89.1895,38.177396],[-89.296158,38.214177],[-89.312889,38.082774]]]},"properties":{"id":"62888","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.306412,38.258447],[-89.319415,38.213757],[-89.296158,38.214177],[-89.1895,38.177396],[-89.147416,38.212896],[-89.182711,38.286982],[-89.183414,38.257358],[-89.206472,38.257946],[-89.204731,38.281331],[-89.306412,38.258447]]]},"properties":{"id":"62831","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.66886,40.076497],[-88.703132,39.967469],[-88.692,39.967336],[-88.668727,39.952552],[-88.462567,39.944376],[-88.462966,40.025533],[-88.519107,40.105272],[-88.56342,40.120833],[-88.66886,40.076497]]]},"properties":{"id":"61856","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.837215,39.707598],[-90.953299,39.692895],[-90.916024,39.668155],[-90.80006,39.67128],[-90.837215,39.707598]]]},"properties":{"id":"62357","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.212396,37.173618],[-89.149919,37.112862],[-89.121171,37.112792],[-89.087296,37.164664],[-89.146035,37.204991],[-89.212396,37.173618]]]},"properties":{"id":"62996","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.146035,37.204991],[-89.156998,37.241687],[-89.255039,37.232136],[-89.255923,37.188113],[-89.212396,37.173618],[-89.146035,37.204991]]]},"properties":{"id":"62976","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.182405,41.250299],[-89.192188,41.25779],[-89.172647,41.258248],[-89.163705,41.310187],[-89.206514,41.312222],[-89.271188,41.320816],[-89.281434,41.313413],[-89.270012,41.176687],[-89.167394,41.192301],[-89.16262,41.192312],[-89.162686,41.207079],[-89.182405,41.250299]],[[-89.250284,41.266518],[-89.240598,41.257503],[-89.259784,41.257234],[-89.250284,41.266518]]]},"properties":{"id":"61326","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.045445,38.603672],[-88.096857,38.56945],[-87.929101,38.570002],[-88.045445,38.603672]]]},"properties":{"id":"62452","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.393639,41.489382],[-90.404215,41.481869],[-90.400017,41.472037],[-90.391291,41.473382],[-90.371553,41.49009],[-90.391961,41.499704],[-90.393639,41.489382]]]},"properties":{"id":"61239","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.391961,41.499704],[-90.426145,41.4724],[-90.406435,41.478682],[-90.393639,41.489382],[-90.391961,41.499704]]]},"properties":{"id":"61282","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.980819,38.526604],[-89.934251,38.468592],[-89.823087,38.481807],[-89.823702,38.532743],[-89.879478,38.548305],[-89.952641,38.570711],[-89.980819,38.526604]]]},"properties":{"id":"62221","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.816162,38.350442],[-89.856197,38.254725],[-89.703857,38.335877],[-89.703889,38.34235],[-89.759956,38.355465],[-89.816162,38.350442]]]},"properties":{"id":"62255","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.068895,38.61895],[-90.111193,38.61872],[-90.098347,38.587901],[-90.08735,38.571398],[-90.042302,38.584482],[-90.051964,38.602821],[-90.068895,38.61895]]]},"properties":{"id":"62203","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.168909,38.66065],[-90.167376,38.652729],[-90.163135,38.65009],[-90.159751,38.660369],[-90.168909,38.66065]]]},"properties":{"id":"62059","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.831984,38.560934],[-89.871422,38.571631],[-89.879478,38.548305],[-89.823702,38.532743],[-89.831984,38.560934]]]},"properties":{"id":"62225","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.715536,39.801136],[-89.653563,39.765762],[-89.651992,39.795267],[-89.658504,39.800745],[-89.715536,39.801136]]]},"properties":{"id":"62704","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.711211,39.812637],[-89.595213,39.801873],[-89.525484,39.806679],[-89.564767,39.874329],[-89.645303,39.928219],[-89.742628,39.873159],[-89.758485,39.822589],[-89.711211,39.812637]]]},"properties":{"id":"62707","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.32213,39.549583],[-90.3255,39.549576],[-90.340253,39.535107],[-90.320616,39.534867],[-90.32213,39.549583]]]},"properties":{"id":"62663","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.675356,39.302749],[-88.561871,39.345746],[-88.686441,39.404372],[-88.722351,39.317549],[-88.675356,39.302749]]]},"properties":{"id":"62465","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.906413,41.142063],[-89.985367,40.995847],[-89.985479,40.974495],[-89.886642,40.975094],[-89.824401,40.98531],[-89.777415,41.15527],[-89.780143,41.212694],[-89.830084,41.228687],[-89.868113,41.178478],[-89.906413,41.142063]]]},"properties":{"id":"61483","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.610339,40.65199],[-89.637101,40.622238],[-89.621816,40.629414],[-89.57459,40.630137],[-89.616067,40.661045],[-89.610339,40.65199]]]},"properties":{"id":"61610","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.726222,40.479084],[-89.715175,40.3334],[-89.650528,40.341499],[-89.57467,40.438015],[-89.582779,40.450431],[-89.726222,40.479084]]]},"properties":{"id":"61534","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.295445,37.599259],[-89.329174,37.542798],[-89.261321,37.468438],[-89.149216,37.529124],[-89.134873,37.534153],[-89.115576,37.598923],[-89.153668,37.600469],[-89.295445,37.599259]]]},"properties":{"id":"62920","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.63519,40.08705],[-87.64473,40.08699],[-87.644495,40.079088],[-87.62544,40.072367],[-87.63519,40.08705]]],[[[-87.625446,40.087925],[-87.701834,40.14216],[-87.714757,40.101869],[-87.694188,40.115515],[-87.647199,40.109532],[-87.625446,40.087925]]]]},"properties":{"id":"61832","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.653486,39.936319],[-87.532799,39.930374],[-87.532308,40.011827],[-87.579418,40.020649],[-87.677134,40.013315],[-87.708225,39.950186],[-87.653486,39.936319]]]},"properties":{"id":"61846","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.81895,40.25587],[-87.89214,40.32798],[-87.942103,40.225482],[-87.92293,40.19287],[-87.82219,40.21708],[-87.81895,40.25587]]]},"properties":{"id":"61812","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.623524,40.270879],[-87.529973,40.25057],[-87.529275,40.30166],[-87.66039,40.32876],[-87.691771,40.314213],[-87.70084,40.30274],[-87.710932,40.314228],[-87.738669,40.288709],[-87.623524,40.270879]]]},"properties":{"id":"61811","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.258058,38.440779],[-88.508923,38.446017],[-88.51555,38.426656],[-88.496148,38.313666],[-88.476697,38.296741],[-88.406962,38.299879],[-88.329542,38.25584],[-88.306678,38.256168],[-88.260348,38.285834],[-88.213986,38.292767],[-88.207283,38.357048],[-88.217234,38.396298],[-88.258058,38.440779]]]},"properties":{"id":"62837","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.56342,38.461123],[-88.563602,38.439042],[-88.544343,38.438583],[-88.548844,38.460668],[-88.56342,38.461123]]],[[[-88.56342,38.461123],[-88.556006,38.475304],[-88.536561,38.540421],[-88.574734,38.548877],[-88.650989,38.475631],[-88.642243,38.457539],[-88.56342,38.461123]]]]},"properties":{"id":"62850","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.288429,38.256481],[-88.225519,38.191573],[-88.197136,38.242197],[-88.194613,38.256616],[-88.213986,38.292767],[-88.260348,38.285834],[-88.288429,38.256481]]]},"properties":{"id":"62820","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.197136,38.242197],[-88.225519,38.191573],[-88.225584,38.152849],[-88.277455,38.05384],[-88.153331,37.966103],[-88.036186,37.94386],[-88.01033,38.026525],[-88.041749,38.037924],[-87.971308,38.12817],[-88.131514,38.212664],[-88.197136,38.242197]]]},"properties":{"id":"62821","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.165995,41.681447],[-88.164363,41.637937],[-88.13022,41.65344],[-88.111388,41.675238],[-88.128012,41.711151],[-88.160101,41.698222],[-88.165995,41.681447]]]},"properties":{"id":"60490","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.002265,41.475088],[-88.004821,41.523756],[-88.078685,41.524093],[-88.079491,41.466346],[-88.022102,41.467664],[-88.002265,41.475088]]]},"properties":{"id":"60433","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.66172,41.467511],[-87.670909,41.447347],[-87.670569,41.420352],[-87.670342,41.39849],[-87.526218,41.38529],[-87.525705,41.470283],[-87.577339,41.469992],[-87.66172,41.467511]]]},"properties":{"id":"60417","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.180734,41.557965],[-88.230141,41.550189],[-88.25451,41.52825],[-88.20799,41.47174],[-88.197353,41.474844],[-88.197575,41.486452],[-88.180734,41.557965]]]},"properties":{"id":"60404","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.02159,37.745159],[-89.044131,37.721239],[-89.021486,37.677011],[-89.002693,37.596316],[-88.928426,37.59716],[-88.803336,37.67791],[-88.752534,37.73044],[-88.817049,37.848623],[-88.835664,37.859744],[-88.997556,37.784788],[-89.016307,37.773928],[-89.02154,37.756298],[-89.02159,37.745159]]]},"properties":{"id":"62959","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.197645,42.435815],[-89.194501,42.44908],[-89.20436,42.443422],[-89.231918,42.441375],[-89.218751,42.430122],[-89.197645,42.435815]]]},"properties":{"id":"61079","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.102693,40.257005],[-91.122764,40.195397],[-91.084975,40.180024],[-91.046749,40.179855],[-90.988724,40.193981],[-90.978144,40.260055],[-91.018114,40.282772],[-91.102693,40.257005]]]},"properties":{"id":"62316","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.326983,38.946302],[-89.339299,38.821735],[-89.255311,38.829734],[-89.326983,38.946302]]]},"properties":{"id":"62284","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.529401,39.028048],[-89.684603,39.013847],[-89.623342,38.903956],[-89.562543,38.918112],[-89.504479,38.974597],[-89.506624,39.027969],[-89.529401,39.028048]]]},"properties":{"id":"62086","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.529401,39.028048],[-89.506624,39.027969],[-89.519027,39.02812],[-89.52618,39.037252],[-89.529401,39.028048]]]},"properties":{"id":"62077","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.798533,42.327852],[-88.804148,42.4921],[-88.87913,42.493822],[-88.880012,42.459131],[-88.87974,42.341847],[-88.798533,42.327852]]]},"properties":{"id":"61065","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.064327,42.288762],[-89.090847,42.273034],[-89.091855,42.269999],[-89.05699,42.267029],[-88.940682,42.274268],[-88.934571,42.305171],[-89.054097,42.299307],[-89.064327,42.288762]]]},"properties":{"id":"61107","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.120614,42.49699],[-89.019871,42.463007],[-88.92147,42.457629],[-88.880012,42.459131],[-88.87913,42.493822],[-89.120614,42.49699]]]},"properties":{"id":"61080","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.033349,39.445204],[-90.895857,39.368763],[-90.81223,39.48733],[-90.916702,39.544719],[-91.033349,39.445204]]]},"properties":{"id":"62366","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.71491,39.307366],[-90.751301,39.29246],[-90.738709,39.280964],[-90.71491,39.307366]]]},"properties":{"id":"62070","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.050813,40.103651],[-89.994951,40.034806],[-89.903715,40.134037],[-90.050813,40.103651]]]},"properties":{"id":"62673","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.061599,40.119294],[-90.302386,40.09189],[-90.287302,40.062103],[-90.273357,40.054498],[-90.055556,39.981641],[-89.994869,40.010663],[-89.994951,40.034806],[-90.050813,40.103651],[-90.061599,40.119294]]]},"properties":{"id":"62627","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.257973,40.135073],[-88.257447,40.080312],[-88.238394,40.068933],[-88.232478,40.083497],[-88.231073,40.098781],[-88.229111,40.135033],[-88.231995,40.135005],[-88.257973,40.135073]]]},"properties":{"id":"61820","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.36851,39.996494],[-88.420336,39.879216],[-88.396672,39.864381],[-88.35787,39.879419],[-88.317058,39.937253],[-88.36851,39.996494]]]},"properties":{"id":"61872","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.251543,39.655428],[-89.404713,39.597115],[-89.432278,39.568096],[-89.432976,39.548601],[-89.393733,39.518583],[-89.312007,39.443785],[-89.156811,39.523877],[-89.157271,39.582351],[-89.251543,39.655428]]]},"properties":{"id":"62568","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.393733,39.518583],[-89.402506,39.43668],[-89.312007,39.443785],[-89.393733,39.518583]]]},"properties":{"id":"62556","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.092853,39.376422],[-88.008129,39.188508],[-87.953934,39.144168],[-87.91412,39.184875],[-87.96168,39.369402],[-88.063925,39.406043],[-88.092853,39.376422]]]},"properties":{"id":"62420","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.078711,42.067001],[-88.079693,42.049306],[-88.060203,42.035523],[-88.060313,42.028141],[-88.031254,42.025042],[-88.030466,42.050649],[-88.033094,42.063385],[-88.058681,42.077177],[-88.062284,42.078023],[-88.078711,42.067001]]]},"properties":{"id":"60173","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.698565,41.632245],[-87.675935,41.627214],[-87.661419,41.638756],[-87.667148,41.675834],[-87.681321,41.677283],[-87.695354,41.677048],[-87.704167,41.651948],[-87.698565,41.632245]]]},"properties":{"id":"60406","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.758108,41.661386],[-87.766511,41.646691],[-87.776529,41.631868],[-87.717117,41.610768],[-87.698184,41.622255],[-87.698565,41.632245],[-87.725169,41.651863],[-87.758108,41.661386]]]},"properties":{"id":"60445","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.946917,41.810726],[-87.946821,41.781562],[-87.908196,41.789615],[-87.918569,41.82571],[-87.952441,41.817745],[-87.946917,41.810726]]]},"properties":{"id":"60521","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.889432,41.819672],[-87.900293,41.826422],[-87.918592,41.826418],[-87.918569,41.82571],[-87.908196,41.789615],[-87.907793,41.774687],[-87.889432,41.819672]]]},"properties":{"id":"60558","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.710799,42.095445],[-87.72451,42.08783],[-87.722599,42.082723],[-87.702761,42.089666],[-87.710799,42.095445]]]},"properties":{"id":"60043","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.820893,42.152537],[-87.910489,42.153135],[-87.889807,42.108452],[-87.888074,42.102126],[-87.888067,42.078509],[-87.867108,42.087335],[-87.814409,42.094433],[-87.798994,42.105376],[-87.78226,42.123527],[-87.789522,42.152473],[-87.820893,42.152537]]]},"properties":{"id":"60062","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.805119,41.890538],[-87.804986,41.886983],[-87.804977,41.886701],[-87.792788,41.886901],[-87.792734,41.890221],[-87.805119,41.890538]]]},"properties":{"id":"60301","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.660338,42.041047],[-87.709717,42.040944],[-87.708662,42.019144],[-87.679742,42.019441],[-87.658857,42.023431],[-87.660338,42.041047]]]},"properties":{"id":"60202","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.765648,41.931738],[-87.766645,41.960491],[-87.786812,41.960673],[-87.836699,41.95936],[-87.857447,41.959583],[-87.854205,41.945988],[-87.849588,41.937286],[-87.826254,41.93775],[-87.785689,41.931002],[-87.765648,41.931738]]]},"properties":{"id":"60634","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.903362,41.89773],[-87.920568,41.897365],[-87.917181,41.869082],[-87.897591,41.884888],[-87.878485,41.892744],[-87.903362,41.89773]]]},"properties":{"id":"60163","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.882839,41.87116],[-87.897591,41.884888],[-87.917181,41.869082],[-87.912577,41.847867],[-87.882839,41.87116]]]},"properties":{"id":"60162","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.632313,41.629838],[-87.624403,41.622638],[-87.58058,41.609346],[-87.578573,41.637513],[-87.632313,41.629838]]]},"properties":{"id":"60419","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.563724,41.5865],[-87.582157,41.577646],[-87.583692,41.557472],[-87.582632,41.542621],[-87.525437,41.539887],[-87.525302,41.581531],[-87.563724,41.5865]]]},"properties":{"id":"60438","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.645491,41.830758],[-87.625804,41.828725],[-87.592614,41.831477],[-87.603387,41.850132],[-87.631552,41.867427],[-87.646195,41.85992],[-87.645491,41.830758]]]},"properties":{"id":"60616","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.786812,41.960673],[-87.766645,41.960491],[-87.727869,41.962531],[-87.720903,41.98291],[-87.787735,41.982899],[-87.788608,41.968861],[-87.786812,41.960673]]]},"properties":{"id":"60630","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.741203,41.895093],[-87.706556,41.895553],[-87.704516,41.910201],[-87.726487,41.909384],[-87.775586,41.908752],[-87.775113,41.894265],[-87.741203,41.895093]]]},"properties":{"id":"60651","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.710016,41.997377],[-87.680136,41.997895],[-87.678943,41.997919],[-87.679742,42.019441],[-87.708662,42.019144],[-87.709973,42.011851],[-87.710016,41.997377]]]},"properties":{"id":"60645","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.77097,41.469782],[-87.709872,41.464377],[-87.703276,41.49163],[-87.77097,41.469782]]]},"properties":{"id":"60471","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.805748,41.908904],[-87.824511,41.908562],[-87.834204,41.898624],[-87.827325,41.879175],[-87.804986,41.886983],[-87.805119,41.890538],[-87.805748,41.908904]]]},"properties":{"id":"60305","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.63311,41.876863],[-87.622985,41.878302],[-87.633754,41.879386],[-87.633694,41.87685],[-87.63311,41.876863]]]},"properties":{"id":"60604","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.651255,38.915206],[-87.519469,38.942981],[-87.654957,39.110541],[-87.66963,39.073735],[-87.651255,38.915206]]]},"properties":{"id":"62451","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.984142,38.791238],[-87.956406,38.649365],[-87.928305,38.636241],[-87.907847,38.882818],[-87.964843,38.850011],[-87.984142,38.791238]]]},"properties":{"id":"62421","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.43356,39.178644],[-88.396426,39.183252],[-88.470906,39.215029],[-88.470939,39.193246],[-88.43356,39.178644]]],[[[-88.470934,39.185978],[-88.469233,39.077172],[-88.360504,39.089872],[-88.316701,39.1159],[-88.433812,39.17124],[-88.470934,39.185978]]]]},"properties":{"id":"62467","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.008129,39.188508],[-88.092853,39.376422],[-88.185256,39.375036],[-88.204935,39.346136],[-88.230969,39.182169],[-88.223475,39.17132],[-88.060358,39.173045],[-88.008129,39.188508]]]},"properties":{"id":"62428","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.77471,41.987066],[-88.8583,41.97196],[-88.8223,41.848823],[-88.715121,41.805738],[-88.660047,41.849756],[-88.665403,41.917858],[-88.698925,41.937621],[-88.77471,41.987066]]]},"properties":{"id":"60115","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.000596,42.007372],[-88.9419,41.978264],[-88.897791,42.022746],[-88.93922,42.09058],[-88.968165,42.064855],[-89.000596,42.007372]]]},"properties":{"id":"60129","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.602346,41.806846],[-88.635938,41.719366],[-88.603488,41.713584],[-88.489875,41.722029],[-88.527899,41.804472],[-88.602346,41.806846]]]},"properties":{"id":"60511","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.082855,39.768518],[-88.088949,39.792065],[-88.216636,39.798944],[-88.12031,39.727682],[-88.091713,39.745461],[-88.082855,39.768518]]]},"properties":{"id":"61919","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.113452,41.815016],[-88.146683,41.831375],[-88.215556,41.820669],[-88.231191,41.820018],[-88.232031,41.776657],[-88.195986,41.778463],[-88.100359,41.793113],[-88.113452,41.815016]]]},"properties":{"id":"60563","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.055619,41.785988],[-88.073172,41.815238],[-88.113452,41.815016],[-88.100359,41.793113],[-88.086288,41.763874],[-88.052407,41.779155],[-88.055619,41.785988]]]},"properties":{"id":"60532","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.012457,41.74377],[-88.030561,41.72893],[-88.030463,41.72666],[-88.001767,41.713272],[-87.973042,41.731374],[-87.96468,41.768407],[-87.989938,41.766019],[-88.012457,41.74377]]]},"properties":{"id":"60561","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.208324,41.944118],[-88.262975,41.937027],[-88.262276,41.899236],[-88.27611,41.869111],[-88.211403,41.850431],[-88.177859,41.838183],[-88.170783,41.842334],[-88.145569,41.893644],[-88.145538,41.89437],[-88.14537,41.901128],[-88.174698,41.929564],[-88.208324,41.944118]]]},"properties":{"id":"60185","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.948066,41.860966],[-87.991911,41.845762],[-88.00376,41.835468],[-87.985879,41.818866],[-87.952441,41.817745],[-87.918569,41.82571],[-87.918592,41.826418],[-87.919333,41.847679],[-87.948066,41.860966]]]},"properties":{"id":"60523","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.588102,39.582958],[-87.596244,39.586209],[-87.596053,39.578391],[-87.588102,39.582958]]]},"properties":{"id":"61955","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.168443,39.246914],[-89.241903,39.199292],[-89.176054,39.057252],[-89.06612,39.027072],[-88.945679,39.129156],[-88.941039,39.147366],[-88.950026,39.158002],[-89.067995,39.216287],[-89.168443,39.246914]]]},"properties":{"id":"62080","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.953016,38.867827],[-89.165954,38.825486],[-88.999696,38.823283],[-88.976817,38.823599],[-88.93962,38.824109],[-88.953016,38.867827]]]},"properties":{"id":"62885","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.30996,40.559088],[-88.247639,40.501181],[-88.185307,40.530849],[-88.194515,40.574723],[-88.23529,40.63243],[-88.29152,40.63219],[-88.31045,40.6175],[-88.30996,40.559088]]]},"properties":{"id":"60952","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.131623,40.983044],[-88.28655,41.02318],[-88.320423,41.022811],[-88.35957,41.00775],[-88.28495,40.97961],[-88.130722,40.939228],[-88.13105,40.968475],[-88.131623,40.983044]]]},"properties":{"id":"60919","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.835239,37.86296],[-88.826454,37.950667],[-88.844976,37.950677],[-88.960819,37.92804],[-88.965404,37.908612],[-88.988594,37.917868],[-89.011579,37.917966],[-89.039927,37.878511],[-89.055993,37.862044],[-89.00522,37.862126],[-88.997226,37.859517],[-89.002221,37.855019],[-89.000738,37.839746],[-88.835239,37.86296]]]},"properties":{"id":"62896","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.036236,40.629342],[-90.037199,40.622103],[-90.024252,40.625709],[-90.036236,40.629342]]]},"properties":{"id":"61553","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.138805,40.713243],[-90.21467,40.647438],[-90.214617,40.596975],[-90.102097,40.625946],[-90.100714,40.713072],[-90.138805,40.713243]]]},"properties":{"id":"61432","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.346195,37.826281],[-88.410537,37.748163],[-88.42828,37.683475],[-88.36613,37.599431],[-88.331662,37.599084],[-88.290077,37.776654],[-88.326432,37.824818],[-88.346195,37.826281]]]},"properties":{"id":"62934","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.500248,39.521198],[-90.581055,39.521728],[-90.593256,39.484586],[-90.613694,39.395775],[-90.613887,39.394963],[-90.526769,39.363699],[-90.50207,39.361415],[-90.497037,39.470201],[-90.49453,39.481162],[-90.494516,39.484809],[-90.500248,39.521198]]]},"properties":{"id":"62050","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.494516,39.484809],[-90.485371,39.485078],[-90.477913,39.473507],[-90.492277,39.473536],[-90.49453,39.481162],[-90.497037,39.470201],[-90.264613,39.441504],[-90.152041,39.433825],[-90.153667,39.502043],[-90.153683,39.502927],[-90.15375,39.520315],[-90.172202,39.520218],[-90.32213,39.549583],[-90.320616,39.534867],[-90.340253,39.535107],[-90.3255,39.549576],[-90.358092,39.56843],[-90.430452,39.560051],[-90.43975,39.554766],[-90.500248,39.521198],[-90.494516,39.484809]]]},"properties":{"id":"62082","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.47314,39.182915],[-90.526769,39.363699],[-90.613887,39.394963],[-90.604133,39.284184],[-90.608396,39.173225],[-90.603569,39.117592],[-90.470419,39.175068],[-90.47314,39.182915]]]},"properties":{"id":"62027","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.526769,39.363699],[-90.47314,39.182915],[-90.295498,39.225013],[-90.259524,39.305588],[-90.352073,39.387051],[-90.50207,39.361415],[-90.526769,39.363699]]]},"properties":{"id":"62016","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.47195,41.181643],[-88.50957,41.13748],[-88.571757,41.122874],[-88.569726,41.108345],[-88.56598,41.085131],[-88.39805,41.00738],[-88.35957,41.00775],[-88.320423,41.022811],[-88.28707,41.03776],[-88.26846,41.0525],[-88.316118,41.112754],[-88.416116,41.168469],[-88.47195,41.181643]]]},"properties":{"id":"60420","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.610659,38.256287],[-88.704606,38.125195],[-88.70487,38.110701],[-88.704987,38.103398],[-88.425461,38.211894],[-88.610659,38.256287]]]},"properties":{"id":"62828","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.269135,40.521164],[-91.297152,40.628052],[-91.374208,40.463993],[-91.236374,40.491286],[-91.249863,40.520919],[-91.269135,40.521164]]]},"properties":{"id":"62354","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.677474,37.384342],[-88.688452,37.319569],[-88.602254,37.247787],[-88.435391,37.171768],[-88.396465,37.421236],[-88.390216,37.437907],[-88.404887,37.496952],[-88.539707,37.511539],[-88.602551,37.425266],[-88.677474,37.384342]]]},"properties":{"id":"62938","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.013899,40.895619],[-90.940274,40.89516],[-90.844518,40.894586],[-90.844778,40.942055],[-90.862834,41.048436],[-90.901072,41.048551],[-90.927161,41.02948],[-91.013899,40.895619]]]},"properties":{"id":"61469","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.831802,40.696161],[-90.8462,40.701973],[-90.846175,40.696458],[-90.831802,40.696161]]]},"properties":{"id":"61471","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.396585,41.469401],[-90.322321,41.440351],[-90.241089,41.524995],[-90.317884,41.508755],[-90.341245,41.511379],[-90.352297,41.508805],[-90.371553,41.49009],[-90.391291,41.473382],[-90.396585,41.469401]]]},"properties":{"id":"61241","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.026192,41.708287],[-90.131348,41.736648],[-90.19826,41.757982],[-90.218531,41.671993],[-90.185609,41.584653],[-90.069611,41.569053],[-89.978182,41.676775],[-89.978238,41.719507],[-90.026192,41.708287]]]},"properties":{"id":"61250","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.877968,40.808715],[-88.128072,40.851336],[-88.126719,40.807195],[-87.89393,40.798303],[-87.877968,40.808715]]]},"properties":{"id":"60930","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.821673,40.698106],[-87.882613,40.769544],[-87.881222,40.740511],[-87.840814,40.697547],[-87.821673,40.698106]]]},"properties":{"id":"60928","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.73521,40.712482],[-87.725576,40.71464],[-87.719771,40.723128],[-87.746581,40.728964],[-87.73521,40.712482]]]},"properties":{"id":"60974","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.027317,38.937504],[-88.037942,38.937601],[-88.03795,38.923111],[-88.026949,38.922146],[-88.027317,38.937504]]]},"properties":{"id":"62459","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.959899,38.483228],[-88.965304,38.475857],[-88.959913,38.475984],[-88.959899,38.483228]]],[[[-88.979578,38.475497],[-89.074475,38.431031],[-89.042207,38.408832],[-88.979578,38.475497]]]]},"properties":{"id":"62893","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.164292,39.157684],[-90.166593,39.150813],[-90.161674,39.152324],[-90.164292,39.157684]]]},"properties":{"id":"62030","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.147666,38.984768],[-90.227194,39.032156],[-90.290243,39.026767],[-90.312787,38.958547],[-90.327688,38.931061],[-90.216979,38.903076],[-90.147666,38.984768]]]},"properties":{"id":"62035","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.896796,42.505792],[-90.068514,42.508158],[-90.035134,42.445004],[-89.94985,42.444459],[-89.925769,42.459318],[-89.896796,42.505792]]]},"properties":{"id":"61087","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.486106,42.507583],[-90.502419,42.396751],[-90.403154,42.327646],[-90.296306,42.371999],[-90.254704,42.41534],[-90.381884,42.507005],[-90.486106,42.507583]]]},"properties":{"id":"61036","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.187078,42.508045],[-90.162575,42.404577],[-90.132273,42.402856],[-90.035134,42.445004],[-90.068514,42.508158],[-90.187078,42.508045]]]},"properties":{"id":"61001","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.769854,42.422935],[-89.925323,42.444587],[-89.944911,42.444414],[-89.943125,42.356695],[-89.857796,42.311945],[-89.768477,42.313722],[-89.764396,42.332134],[-89.749217,42.330867],[-89.735501,42.421888],[-89.769854,42.422935]]]},"properties":{"id":"61048","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925769,42.459318],[-89.94985,42.444459],[-89.944911,42.444414],[-89.925323,42.444587],[-89.925769,42.459318]]]},"properties":{"id":"61059","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.984219,37.306785],[-88.928754,37.359939],[-89.020795,37.389241],[-89.04454,37.388693],[-89.072367,37.319306],[-89.08127,37.29098],[-88.984219,37.306785]]]},"properties":{"id":"62923","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.395011,41.794643],[-88.317557,41.788855],[-88.30134,41.797395],[-88.299103,41.818898],[-88.324826,41.822019],[-88.344226,41.825069],[-88.397649,41.827985],[-88.395011,41.794643]]]},"properties":{"id":"60542","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.375176,41.896927],[-88.395788,41.854106],[-88.27611,41.869111],[-88.262276,41.899236],[-88.340391,41.897722],[-88.375176,41.896927]]]},"properties":{"id":"60134","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.408424,42.110436],[-88.393866,42.08835],[-88.352681,42.103342],[-88.374132,42.132291],[-88.408424,42.110436]]]},"properties":{"id":"60136","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.186535,40.928721],[-90.070398,40.887716],[-90.070563,40.959722],[-90.184695,40.975729],[-90.186535,40.928721]]]},"properties":{"id":"61428","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.144914,42.30357],[-88.106678,42.293696],[-88.019039,42.306094],[-87.963631,42.345],[-87.997889,42.366546],[-88.049525,42.385918],[-88.144914,42.30357]]]},"properties":{"id":"60030","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.960298,42.206909],[-87.93508,42.221215],[-87.974006,42.269386],[-87.994147,42.226833],[-87.965011,42.204477],[-87.960298,42.206909]]]},"properties":{"id":"60061","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.924091,42.222196],[-87.886881,42.200234],[-87.851868,42.203724],[-87.799034,42.224047],[-87.817021,42.269332],[-87.90534,42.275343],[-87.924091,42.222196]]]},"properties":{"id":"60045","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.901987,42.311237],[-87.90534,42.275343],[-87.817021,42.269332],[-87.820266,42.30448],[-87.856172,42.297708],[-87.892415,42.309463],[-87.901987,42.311237]]]},"properties":{"id":"60044","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.820319,42.328858],[-87.856172,42.297708],[-87.820266,42.30448],[-87.820319,42.328858]]]},"properties":{"id":"60088","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.708183,41.458191],[-88.800623,41.28128],[-88.693589,41.223362],[-88.664878,41.238223],[-88.65404,41.456457],[-88.707443,41.458319],[-88.708183,41.458191]]]},"properties":{"id":"61341","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.08769,41.456187],[-89.077335,41.323737],[-89.010604,41.326633],[-88.973211,41.469083],[-89.081943,41.467733],[-89.078349,41.461151],[-89.086223,41.459897],[-89.08769,41.456187]]]},"properties":{"id":"61373","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.414471,41.772697],[-89.419369,41.767407],[-89.405414,41.76792],[-89.414471,41.772697]]]},"properties":{"id":"61324","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.66292,40.972686],[-88.45334,40.93417],[-88.41547,40.94898],[-88.39805,41.00738],[-88.56598,41.085131],[-88.64331,41.03363],[-88.66292,40.972686]]]},"properties":{"id":"60460","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.64331,41.03363],[-88.77536,41.098593],[-88.83282,41.06876],[-88.806259,40.98515],[-88.66292,40.972686],[-88.64331,41.03363]]]},"properties":{"id":"61319","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.486083,40.202516],[-89.375012,40.233622],[-89.404531,40.2793],[-89.544445,40.233632],[-89.486083,40.202516]]]},"properties":{"id":"62643","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.563686,40.24793],[-89.544445,40.233632],[-89.404531,40.2793],[-89.366103,40.287404],[-89.377771,40.338487],[-89.565188,40.32072],[-89.563686,40.24793]]]},"properties":{"id":"62635","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.394793,39.939899],[-89.404969,39.932733],[-89.385886,39.917963],[-89.394793,39.939899]]]},"properties":{"id":"62519","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.27868,40.220586],[-89.296918,40.226698],[-89.311653,40.222112],[-89.305626,40.200979],[-89.27868,40.220586]]]},"properties":{"id":"61751","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.561492,40.367655],[-90.67794,40.278562],[-90.593218,40.238025],[-90.517764,40.266595],[-90.525446,40.336666],[-90.561492,40.367655]]]},"properties":{"id":"61440","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.276448,42.44028],[-88.23789,42.494895],[-88.378403,42.494518],[-88.365874,42.43127],[-88.330759,42.430234],[-88.276448,42.44028]]]},"properties":{"id":"60071","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.276448,42.44028],[-88.330759,42.430234],[-88.306093,42.376842],[-88.290289,42.382185],[-88.276727,42.419346],[-88.276448,42.44028]]]},"properties":{"id":"60072","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.283071,40.572443],[-89.266499,40.485552],[-89.266181,40.471028],[-89.151736,40.455849],[-89.117364,40.531433],[-89.19337,40.595694],[-89.269617,40.602506],[-89.283071,40.572443]]]},"properties":{"id":"61732","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.794947,40.717062],[-88.96755,40.66465],[-88.91364,40.643411],[-88.78133,40.559711],[-88.703915,40.573759],[-88.651175,40.669345],[-88.794947,40.717062]]]},"properties":{"id":"61753","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.103899,39.852869],[-89.134716,39.910598],[-89.143457,39.91792],[-89.180693,39.917275],[-89.217523,39.813224],[-89.141817,39.80091],[-89.09634,39.845433],[-89.103899,39.852869]]]},"properties":{"id":"62551","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.036276,39.182159],[-90.090993,39.142224],[-90.123538,39.086962],[-90.05771,39.071071],[-89.909057,39.100153],[-89.906955,39.150695],[-90.036693,39.203661],[-90.036276,39.182159]]]},"properties":{"id":"62685","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.173059,38.680239],[-90.117455,38.676888],[-90.066116,38.663019],[-90.025394,38.734022],[-90.038604,38.745948],[-90.075855,38.779519],[-90.111811,38.80567],[-90.117707,38.805748],[-90.181615,38.660369],[-90.17772,38.660536],[-90.173059,38.680239]]]},"properties":{"id":"62040","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.083782,38.833419],[-90.053888,38.803916],[-90.038361,38.843449],[-90.095928,38.844852],[-90.083782,38.833419]]]},"properties":{"id":"62084","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.959899,38.483228],[-88.959913,38.475984],[-88.866117,38.483569],[-88.838751,38.486934],[-88.879695,38.541879],[-88.964776,38.562045],[-88.959899,38.483228]]]},"properties":{"id":"62853","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.010529,38.735437],[-88.845877,38.700648],[-88.976817,38.823599],[-88.999696,38.823283],[-89.010577,38.788934],[-89.010529,38.735437]]]},"properties":{"id":"62807","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.065766,40.838186],[-88.93071,40.84048],[-88.93139,40.92774],[-89.047718,40.925749],[-89.12446,40.94123],[-89.184758,40.926495],[-89.1668,40.89739],[-89.065766,40.838186]]]},"properties":{"id":"61760","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.604996,41.26421],[-90.610358,41.254952],[-90.596831,41.258042],[-90.604996,41.26421]]]},"properties":{"id":"61263","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.585979,41.247329],[-90.627293,41.132155],[-90.541905,41.151287],[-90.534738,41.151365],[-90.500797,41.261825],[-90.585979,41.247329]]]},"properties":{"id":"61486","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.937652,41.332327],[-90.903852,41.15706],[-90.879721,41.142333],[-90.840432,41.156968],[-90.839807,41.314067],[-90.849685,41.352998],[-90.937652,41.332327]]]},"properties":{"id":"61260","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.257001,38.224235],[-90.255759,38.131907],[-90.208235,38.088794],[-90.138319,38.154071],[-90.133097,38.153749],[-90.119079,38.172631],[-90.119125,38.208859],[-90.257001,38.224235]]]},"properties":{"id":"62244","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.133097,38.153749],[-90.138319,38.154071],[-90.138327,38.148181],[-90.132533,38.148184],[-90.133097,38.153749]]]},"properties":{"id":"62279","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.700216,39.421157],[-89.605872,39.38535],[-89.551039,39.421693],[-89.551736,39.484672],[-89.589065,39.50254],[-89.682975,39.4942],[-89.700216,39.421157]]]},"properties":{"id":"62533","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.680184,39.051071],[-89.698801,39.028275],[-89.684603,39.013847],[-89.529401,39.028048],[-89.52618,39.037252],[-89.585916,39.098407],[-89.680184,39.051071]]]},"properties":{"id":"62091","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.410499,39.842352],[-90.325656,39.788475],[-90.315804,39.809326],[-90.410499,39.842352]]]},"properties":{"id":"62631","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.437834,39.714184],[-90.642037,39.688301],[-90.630574,39.670494],[-90.581055,39.521728],[-90.500248,39.521198],[-90.43975,39.554766],[-90.43957,39.56313],[-90.430452,39.560051],[-90.358092,39.56843],[-90.294163,39.648431],[-90.437834,39.714184]]]},"properties":{"id":"62694","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.056528,42.064003],[-89.000596,42.007372],[-88.968165,42.064855],[-89.039731,42.078509],[-89.056528,42.064003]]]},"properties":{"id":"61049","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.193472,42.29112],[-89.216377,42.203773],[-89.239562,42.154359],[-89.16052,42.178206],[-89.10462,42.237681],[-89.095342,42.257092],[-89.098119,42.273152],[-89.099585,42.274554],[-89.193472,42.29112]]]},"properties":{"id":"61102","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.965992,41.93101],[-88.962425,41.935141],[-88.97093,41.935063],[-88.965992,41.93101]]]},"properties":{"id":"60113","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.624834,40.666781],[-89.610339,40.65199],[-89.616067,40.661045],[-89.583897,40.688937],[-89.595536,40.699601],[-89.59568,40.695386],[-89.624834,40.666781]]]},"properties":{"id":"61602","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.546646,38.316862],[-89.426243,38.215161],[-89.319415,38.213757],[-89.306412,38.258447],[-89.2578,38.349835],[-89.32258,38.390894],[-89.389042,38.459784],[-89.463566,38.412483],[-89.546646,38.316862]]]},"properties":{"id":"62263","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.707886,39.792261],[-88.707964,39.802988],[-88.745749,39.814174],[-88.745701,39.799532],[-88.707886,39.792261]]]},"properties":{"id":"61936","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.156123,39.660366],[-91.10961,39.64667],[-91.147678,39.75707],[-91.156123,39.660366]]]},"properties":{"id":"62345","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.561927,37.627275],[-88.724177,37.734199],[-88.752534,37.73044],[-88.803336,37.67791],[-88.718225,37.599458],[-88.708546,37.599277],[-88.631228,37.536102],[-88.596428,37.526202],[-88.546857,37.515321],[-88.541769,37.582357],[-88.561927,37.627275]]]},"properties":{"id":"62987","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.675319,37.745323],[-88.561927,37.627275],[-88.541769,37.582357],[-88.42828,37.683475],[-88.410537,37.748163],[-88.516714,37.80931],[-88.596513,37.818699],[-88.675319,37.745323]],[[-88.517039,37.77557],[-88.51268,37.757888],[-88.525524,37.763026],[-88.517039,37.77557]]]},"properties":{"id":"62946","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.742464,41.439261],[-90.745679,41.415965],[-90.726041,41.41619],[-90.714982,41.439676],[-90.742464,41.439261]]]},"properties":{"id":"61232","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.341245,41.511379],[-90.378185,41.519811],[-90.376877,41.505913],[-90.352297,41.508805],[-90.341245,41.511379]]]},"properties":{"id":"61236","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.756565,38.595792],[-89.747238,38.593922],[-89.74719,38.600713],[-89.752029,38.600678],[-89.756565,38.595792]]]},"properties":{"id":"62289","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.669662,39.619761],[-89.725246,39.523108],[-89.59505,39.539193],[-89.631686,39.604548],[-89.669662,39.619761]]]},"properties":{"id":"62530","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.437939,40.109308],[-90.407603,40.09368],[-90.216958,40.183985],[-90.423124,40.188628],[-90.437939,40.109308]]]},"properties":{"id":"62624","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.862124,40.104771],[-90.824615,40.105101],[-90.795884,40.124787],[-90.842878,40.141431],[-90.882675,40.104604],[-90.862124,40.104771]]],[[[-90.798827,40.222837],[-90.893092,40.162952],[-90.833603,40.148836],[-90.757423,40.192711],[-90.798827,40.222837]]]]},"properties":{"id":"62344","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.195352,40.73314],[-89.292845,40.792132],[-89.350003,40.748199],[-89.329614,40.660774],[-89.309079,40.644732],[-89.251987,40.657393],[-89.185186,40.667453],[-89.195352,40.73314]]]},"properties":{"id":"61530","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.553269,40.604666],[-89.531157,40.553502],[-89.495481,40.568212],[-89.514164,40.604574],[-89.553269,40.604666]]]},"properties":{"id":"61535","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.761464,39.99852],[-87.75324,40.087407],[-87.813579,40.093536],[-87.9017,40.067057],[-87.8818,39.963083],[-87.86113,39.95323],[-87.78577,39.96894],[-87.761464,39.99852]]]},"properties":{"id":"61841","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.694188,40.115515],[-87.676227,40.080328],[-87.644495,40.079088],[-87.64473,40.08699],[-87.647199,40.109532],[-87.694188,40.115515]]],[[[-87.62544,40.072367],[-87.579418,40.020649],[-87.532308,40.011827],[-87.530791,40.19183],[-87.623524,40.270879],[-87.738669,40.288709],[-87.74743,40.206855],[-87.79806,40.19582],[-87.79799,40.19035],[-87.71955,40.14765],[-87.701834,40.14216],[-87.625446,40.087925],[-87.63519,40.08705],[-87.62544,40.072367]]]]},"properties":{"id":"61834","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.66039,40.32876],[-87.529275,40.30166],[-87.527388,40.425197],[-87.77949,40.40134],[-87.845493,40.37183],[-87.66039,40.32876]]]},"properties":{"id":"60963","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.536561,38.540421],[-88.556006,38.475304],[-88.548844,38.460668],[-88.544343,38.438583],[-88.51555,38.426656],[-88.508923,38.446017],[-88.275727,38.51106],[-88.293631,38.556472],[-88.383,38.587848],[-88.536561,38.540421]]]},"properties":{"id":"62823","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.756036,41.790332],[-89.761799,41.795881],[-89.762107,41.787534],[-89.756036,41.790332]]]},"properties":{"id":"61037","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.00522,37.862126],[-89.002221,37.855019],[-88.997226,37.859517],[-89.00522,37.862126]]]},"properties":{"id":"62841","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.974583,42.388732],[-89.045868,42.390053],[-89.060529,42.327106],[-88.966503,42.369996],[-88.974583,42.388732]]]},"properties":{"id":"61115","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.194501,42.44908],[-89.197645,42.435815],[-89.232461,42.392933],[-89.184982,42.377644],[-89.098249,42.411725],[-89.059419,42.397554],[-89.019871,42.463007],[-89.120614,42.49699],[-89.262669,42.49819],[-89.194501,42.44908]]]},"properties":{"id":"61072","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.427735,39.908755],[-91.358661,39.90271],[-91.357983,39.9645],[-91.421919,39.933074],[-91.427735,39.908755]]]},"properties":{"id":"62301","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.195619,40.049275],[-91.17656,40.020918],[-91.166716,39.998714],[-91.147255,40.08003],[-91.220389,40.057767],[-91.195619,40.049275]]]},"properties":{"id":"62325","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.220389,40.057767],[-91.221448,40.049295],[-91.195619,40.049275],[-91.220389,40.057767]]],[[[-91.14634,40.136902],[-91.28764,40.197785],[-91.354567,40.198763],[-91.357214,40.175758],[-91.318939,40.066526],[-91.286069,40.022324],[-91.220389,40.057767],[-91.147255,40.08003],[-91.14634,40.136902]]]]},"properties":{"id":"62351","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.122764,40.195397],[-91.266777,40.214483],[-91.28764,40.197785],[-91.14634,40.136902],[-91.114514,40.1291],[-91.084975,40.180024],[-91.122764,40.195397]]]},"properties":{"id":"62349","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.377135,40.199142],[-91.357214,40.175758],[-91.354567,40.198763],[-91.377135,40.199142]]]},"properties":{"id":"62348","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.374297,37.218728],[-89.41742,37.16191],[-89.307205,37.117848],[-89.289415,37.13207],[-89.374297,37.218728]]]},"properties":{"id":"62969","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59805,38.776618],[-89.576615,38.77925],[-89.573098,38.803434],[-89.59805,38.776618]]]},"properties":{"id":"62273","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.798533,42.327852],[-88.87974,42.341847],[-88.930405,42.321555],[-88.934571,42.305171],[-88.940682,42.274268],[-88.940731,42.260809],[-88.891274,42.174618],[-88.837951,42.153377],[-88.769099,42.160644],[-88.739788,42.160834],[-88.783847,42.327834],[-88.798533,42.327852]]]},"properties":{"id":"61008","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.940731,42.260809],[-88.970291,42.250281],[-88.97943,42.226842],[-88.978919,42.15199],[-88.95021,42.152191],[-88.891274,42.174618],[-88.940731,42.260809]]]},"properties":{"id":"61016","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.666953,42.327816],[-88.579637,42.356657],[-88.471683,42.414246],[-88.507131,42.494778],[-88.736519,42.492763],[-88.705613,42.355037],[-88.666953,42.327816]]]},"properties":{"id":"60033","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.92147,42.457629],[-88.974583,42.388732],[-88.966503,42.369996],[-88.931789,42.32676],[-88.930405,42.321555],[-88.87974,42.341847],[-88.880012,42.459131],[-88.92147,42.457629]]]},"properties":{"id":"61011","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.837951,42.153377],[-88.822406,42.045163],[-88.783414,42.045314],[-88.698942,42.049648],[-88.769099,42.160644],[-88.837951,42.153377]]]},"properties":{"id":"60145","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.862124,40.104771],[-90.914479,39.93015],[-90.824615,40.105101],[-90.862124,40.104771]]]},"properties":{"id":"62375","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.625656,41.241191],[-89.520708,41.172318],[-89.482731,41.163092],[-89.399688,41.233714],[-89.516072,41.321017],[-89.625635,41.321933],[-89.625656,41.241191]]]},"properties":{"id":"61368","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.715086,41.506743],[-89.72009,41.514842],[-89.720051,41.506658],[-89.715086,41.506743]]]},"properties":{"id":"61346","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.163705,41.310187],[-89.172647,41.258248],[-89.182405,41.250299],[-89.162686,41.207079],[-89.106318,41.235962],[-89.11122,41.265037],[-89.111176,41.250535],[-89.145296,41.250475],[-89.117021,41.272815],[-89.105742,41.324301],[-89.126751,41.454802],[-89.166051,41.454254],[-89.179405,41.386407],[-89.182916,41.372404],[-89.182489,41.364398],[-89.164614,41.364632],[-89.164214,41.340337],[-89.177722,41.355459],[-89.163705,41.310187]]]},"properties":{"id":"61354","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.482731,41.163092],[-89.32927,41.147931],[-89.356671,41.233235],[-89.395536,41.233704],[-89.399688,41.233714],[-89.482731,41.163092]]]},"properties":{"id":"61560","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.362309,41.524491],[-89.344311,41.544411],[-89.373537,41.553753],[-89.362309,41.524491]]]},"properties":{"id":"61374","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.35449,41.288777],[-89.375064,41.286708],[-89.373985,41.276839],[-89.35479,41.276909],[-89.35449,41.288777]]]},"properties":{"id":"61315","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.715639,39.379704],[-90.791774,39.307142],[-90.715977,39.191468],[-90.676835,39.143442],[-90.652852,39.220694],[-90.675395,39.25157],[-90.738709,39.280964],[-90.751301,39.29246],[-90.71491,39.307366],[-90.715639,39.379704]]]},"properties":{"id":"62045","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.80214,42.018634],[-89.78319,41.930302],[-89.667892,41.930282],[-89.685141,42.024779],[-89.80214,42.018634]]]},"properties":{"id":"61051","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.07615,42.196865],[-90.020582,42.00739],[-89.978734,42.000405],[-89.860007,42.058393],[-89.919146,42.18012],[-89.939435,42.226034],[-90.029336,42.225668],[-90.07615,42.196865]]]},"properties":{"id":"61053","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.938423,39.952145],[-88.028598,39.966821],[-88.056828,39.937852],[-88.009488,39.879432],[-87.956532,39.879724],[-87.938423,39.952145]]]},"properties":{"id":"61816","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.368702,40.025624],[-88.36851,39.996494],[-88.317058,39.937253],[-88.176871,39.879744],[-88.160531,39.923651],[-88.180555,40.025454],[-88.216041,40.039819],[-88.238179,40.039719],[-88.262771,40.032841],[-88.368702,40.025624]]]},"properties":{"id":"61880","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.021502,40.387862],[-88.194108,40.370546],[-88.040616,40.356487],[-88.021502,40.387862]]]},"properties":{"id":"60949","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.321355,39.771955],[-89.405897,39.814807],[-89.470789,39.770839],[-89.409978,39.729819],[-89.345857,39.720614],[-89.321355,39.771955]]]},"properties":{"id":"62545","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.345857,39.720614],[-89.409978,39.729819],[-89.478535,39.649195],[-89.443225,39.59287],[-89.423391,39.593559],[-89.404713,39.597115],[-89.251543,39.655428],[-89.251564,39.684648],[-89.270317,39.714546],[-89.345857,39.720614]]]},"properties":{"id":"62531","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.063925,39.406043],[-87.96168,39.369402],[-87.94138,39.445295],[-87.960179,39.481309],[-88.050101,39.478545],[-88.063925,39.406043]]]},"properties":{"id":"62474","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.299762,38.790548],[-88.253851,38.599502],[-88.147909,38.534536],[-88.147814,38.569086],[-88.224798,38.804099],[-88.227974,38.81797],[-88.286362,38.833982],[-88.299762,38.790548]]]},"properties":{"id":"62868","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.359766,38.761454],[-88.355333,38.76759],[-88.366163,38.767895],[-88.359766,38.761454]]]},"properties":{"id":"62879","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.719123,38.819619],[-89.77817,38.742591],[-89.716806,38.687798],[-89.596025,38.691277],[-89.587255,38.702518],[-89.528308,38.727002],[-89.669427,38.873853],[-89.719123,38.819619]]]},"properties":{"id":"62249","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.267942,39.345586],[-88.35099,39.396049],[-88.4015,39.374307],[-88.341387,39.301777],[-88.267942,39.345586]]]},"properties":{"id":"62469","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.73973,41.690891],[-87.752759,41.690948],[-87.773454,41.690449],[-87.778159,41.674082],[-87.758108,41.661386],[-87.725169,41.651863],[-87.704167,41.651948],[-87.695354,41.677048],[-87.73973,41.690891]]]},"properties":{"id":"60803","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.809418,41.684319],[-87.778159,41.674082],[-87.773454,41.690449],[-87.797857,41.699926],[-87.809418,41.684319]]]},"properties":{"id":"60482","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.725169,41.651863],[-87.698565,41.632245],[-87.704167,41.651948],[-87.725169,41.651863]]]},"properties":{"id":"60472","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.869617,41.973133],[-87.856369,41.983628],[-87.855851,41.989057],[-87.865637,42.027024],[-87.940582,42.030014],[-87.943377,42.022671],[-87.950018,42.019994],[-87.931085,41.993576],[-87.92,41.958151],[-87.890763,41.95803],[-87.869617,41.973133]]]},"properties":{"id":"60018","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.750334,42.15276],[-87.789522,42.152473],[-87.78226,42.123527],[-87.725967,42.125012],[-87.750334,42.15276]]]},"properties":{"id":"60022","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.761815,42.004653],[-87.727274,41.99725],[-87.710016,41.997377],[-87.709973,42.011851],[-87.747816,42.015405],[-87.761815,42.004653]]]},"properties":{"id":"60712","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.789771,41.606438],[-87.776529,41.631868],[-87.766511,41.646691],[-87.8201,41.649347],[-87.86409,41.644922],[-87.847862,41.598938],[-87.823448,41.601841],[-87.789771,41.606438]]]},"properties":{"id":"60462","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.174698,41.929564],[-88.166055,41.941747],[-88.165948,41.995218],[-88.219699,42.014562],[-88.262853,41.978187],[-88.208324,41.944118],[-88.174698,41.929564]]]},"properties":{"id":"60103","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.849588,41.937286],[-87.852523,41.919347],[-87.825457,41.911015],[-87.826254,41.93775],[-87.849588,41.937286]]]},"properties":{"id":"60171","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.795533,42.055227],[-87.763921,42.055382],[-87.769884,42.062544],[-87.795533,42.055227]]]},"properties":{"id":"60029","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.882839,41.87116],[-87.912577,41.847867],[-87.919333,41.847679],[-87.918592,41.826418],[-87.900293,41.826422],[-87.865464,41.845534],[-87.868111,41.871044],[-87.882839,41.87116]]]},"properties":{"id":"60154","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.840761,41.864342],[-87.863164,41.871182],[-87.868111,41.871044],[-87.865464,41.845534],[-87.85312,41.846064],[-87.842735,41.850711],[-87.840761,41.864342]]]},"properties":{"id":"60155","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.887282,41.900164],[-87.878805,41.903445],[-87.883778,41.907558],[-87.887282,41.900164]]]},"properties":{"id":"60165","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.655883,41.58634],[-87.698184,41.622255],[-87.717117,41.610768],[-87.713863,41.587272],[-87.706586,41.586212],[-87.655883,41.58634]]]},"properties":{"id":"60428","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.705669,41.866405],[-87.739932,41.86596],[-87.73881,41.83292],[-87.694967,41.832279],[-87.695907,41.866511],[-87.705669,41.866405]]]},"properties":{"id":"60623","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.645491,41.830758],[-87.646195,41.85992],[-87.666457,41.86702],[-87.695907,41.866511],[-87.694967,41.832279],[-87.686299,41.83026],[-87.645491,41.830758]]]},"properties":{"id":"60608","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.669522,41.888837],[-87.706542,41.895079],[-87.705669,41.866405],[-87.695907,41.866511],[-87.666457,41.86702],[-87.669522,41.888837]]]},"properties":{"id":"60612","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.633895,41.884469],[-87.624296,41.882119],[-87.617253,41.880921],[-87.616444,41.882335],[-87.628004,41.887506],[-87.633966,41.887064],[-87.633895,41.884469]]]},"properties":{"id":"60601","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.718655,41.98294],[-87.679102,41.990687],[-87.680136,41.997895],[-87.710016,41.997377],[-87.727274,41.99725],[-87.718655,41.98294]]]},"properties":{"id":"60659","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.046303,41.99189],[-87.995418,41.992661],[-87.962867,41.983321],[-87.931085,41.993576],[-87.950018,42.019994],[-87.959935,42.02516],[-87.96597,42.029644],[-87.983854,42.040517],[-88.031315,42.021595],[-88.060581,41.999514],[-88.055079,41.991537],[-88.051013,41.991701],[-88.046303,41.99189]]]},"properties":{"id":"60007","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.655949,41.579063],[-87.698529,41.549832],[-87.66257,41.5388],[-87.635944,41.538778],[-87.626648,41.560717],[-87.63486,41.577149],[-87.636395,41.574303],[-87.653447,41.579083],[-87.655949,41.579063]]]},"properties":{"id":"60430","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.790448,41.53671],[-87.843228,41.528478],[-87.907438,41.49098],[-87.906436,41.469589],[-87.903548,41.454305],[-87.778147,41.469814],[-87.771079,41.528128],[-87.790448,41.53671]]]},"properties":{"id":"60423","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.091562,42.026844],[-88.060313,42.028141],[-88.060203,42.035523],[-88.091562,42.026844]]],[[[-88.138491,42.039571],[-88.154324,42.029954],[-88.099568,42.026564],[-88.096066,42.04871],[-88.104214,42.048338],[-88.138491,42.039571]]]]},"properties":{"id":"60194","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.794875,41.751781],[-87.794634,41.733574],[-87.741067,41.734524],[-87.741629,41.757303],[-87.745641,41.756927],[-87.794875,41.751781]]]},"properties":{"id":"60459","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.858503,39.092209],[-87.832848,38.883054],[-87.807875,38.869279],[-87.651255,38.915206],[-87.66963,39.073735],[-87.773734,39.108462],[-87.858503,39.092209]]]},"properties":{"id":"62454","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.060358,39.173045],[-88.095249,39.103995],[-88.048336,39.08279],[-87.973876,39.083664],[-87.953934,39.144168],[-88.008129,39.188508],[-88.060358,39.173045]]]},"properties":{"id":"62481","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.676014,41.933268],[-88.581763,42.006758],[-88.558335,42.049973],[-88.573273,42.053791],[-88.57759,42.065316],[-88.601635,42.085255],[-88.698942,42.049648],[-88.783414,42.045314],[-88.77471,41.987066],[-88.698925,41.937621],[-88.676014,41.933268]]]},"properties":{"id":"60178","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.602297,41.821117],[-88.660047,41.849756],[-88.715121,41.805738],[-88.706345,41.731036],[-88.6795,41.718936],[-88.635938,41.719366],[-88.602346,41.806846],[-88.602297,41.821117]]]},"properties":{"id":"60520","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.941279,41.891752],[-88.941304,41.862921],[-88.905576,41.848657],[-88.823593,41.847278],[-88.8223,41.848823],[-88.8583,41.97196],[-88.887978,42.022839],[-88.897791,42.022746],[-88.9419,41.978264],[-88.941279,41.891752]]]},"properties":{"id":"60150","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.887978,42.022839],[-88.8583,41.97196],[-88.77471,41.987066],[-88.783414,42.045314],[-88.822406,42.045163],[-88.887978,42.022839]]]},"properties":{"id":"60111","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.9419,41.978264],[-89.000596,42.007372],[-89.056528,42.064003],[-89.095265,42.054111],[-89.114701,42.042582],[-89.115008,42.06279],[-89.13407,42.063433],[-89.198609,41.932279],[-89.121836,41.861082],[-88.941279,41.891752],[-88.9419,41.978264]],[[-88.965992,41.93101],[-88.97093,41.935063],[-88.962425,41.935141],[-88.965992,41.93101]]]},"properties":{"id":"61068","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.84254,40.282616],[-89.029222,40.253365],[-89.047833,40.211812],[-88.86096,40.224474],[-88.822631,40.260093],[-88.84254,40.282616]]]},"properties":{"id":"61777","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.120448,41.960401],[-88.125303,41.963673],[-88.12358,41.960323],[-88.120448,41.960401]]],[[[-88.120448,41.960401],[-88.123656,41.95297],[-88.140136,41.939924],[-88.101069,41.931255],[-88.056042,41.928383],[-88.049494,41.954219],[-88.051085,41.960743],[-88.071043,41.96659],[-88.120448,41.960401]]]]},"properties":{"id":"60108","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.049494,41.954219],[-88.003327,41.9489],[-87.995418,41.992661],[-88.046303,41.99189],[-88.051089,41.987257],[-88.051085,41.960743],[-88.049494,41.954219]]]},"properties":{"id":"60143","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.128012,41.711151],[-88.069009,41.728134],[-88.063968,41.749924],[-88.167253,41.747618],[-88.160101,41.698222],[-88.128012,41.711151]]]},"properties":{"id":"60565","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.344226,41.825069],[-88.337165,41.832837],[-88.324826,41.822019],[-88.299103,41.818898],[-88.231191,41.820018],[-88.215556,41.820669],[-88.211403,41.850431],[-88.27611,41.869111],[-88.395788,41.854106],[-88.413453,41.831017],[-88.397649,41.827985],[-88.344226,41.825069]]]},"properties":{"id":"60510","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.969172,41.912184],[-87.999053,41.905038],[-87.991911,41.845762],[-87.948066,41.860966],[-87.969172,41.912184]]]},"properties":{"id":"60181","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.532799,39.930374],[-87.653486,39.936319],[-87.710289,39.880907],[-87.533228,39.883],[-87.532799,39.930374]]]},"properties":{"id":"61870","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.982513,38.335343],[-88.00513,38.416699],[-88.024097,38.501523],[-88.14785,38.496908],[-88.147627,38.466744],[-88.150536,38.268528],[-88.1509,38.256097],[-87.982513,38.335343]]]},"properties":{"id":"62806","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.16482,38.411928],[-88.207283,38.357048],[-88.213986,38.292767],[-88.150536,38.268528],[-88.147627,38.466744],[-88.16482,38.411928]]]},"properties":{"id":"62833","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.679858,39.138984],[-88.626143,39.033643],[-88.503846,39.009037],[-88.469233,39.077172],[-88.470934,39.185978],[-88.470939,39.193246],[-88.470906,39.215029],[-88.568003,39.229874],[-88.581679,39.215315],[-88.679858,39.138984]]]},"properties":{"id":"62401","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.044146,39.288735],[-89.067995,39.216287],[-88.950026,39.158002],[-88.935689,39.289015],[-89.016701,39.306054],[-89.044146,39.288735]]]},"properties":{"id":"62431","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.813533,39.325599],[-88.917195,39.296531],[-88.935689,39.289015],[-88.950026,39.158002],[-88.941039,39.147366],[-88.817632,39.258838],[-88.801376,39.303456],[-88.813533,39.325599]]]},"properties":{"id":"62422","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.28293,40.9215],[-88.35722,40.92055],[-88.336669,40.830527],[-88.33623,40.81779],[-88.262477,40.837359],[-88.185388,40.85028],[-88.28293,40.9215]]]},"properties":{"id":"60929","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.058711,37.906639],[-89.062696,37.892498],[-89.060441,37.862057],[-89.058394,37.862051],[-89.055993,37.862044],[-89.039927,37.878511],[-89.058711,37.906639]]]},"properties":{"id":"62999","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.129823,38.111892],[-89.054633,38.124735],[-89.105563,38.22807],[-89.147416,38.212896],[-89.1895,38.177396],[-89.129823,38.111892]]]},"properties":{"id":"62883","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.104581,40.468905],[-90.170874,40.545619],[-90.180512,40.560359],[-90.215159,40.560454],[-90.274676,40.542975],[-90.254034,40.451408],[-90.104742,40.462296],[-90.104581,40.468905]]]},"properties":{"id":"61427","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.920946,40.611477],[-89.925408,40.625298],[-89.940292,40.625411],[-89.920946,40.611477]]],[[[-89.920946,40.611477],[-89.872463,40.513127],[-89.784236,40.5493],[-89.776493,40.558332],[-89.756167,40.596958],[-89.756239,40.602122],[-89.790178,40.624134],[-89.851774,40.653475],[-89.900977,40.618074],[-89.920946,40.611477]]]]},"properties":{"id":"61533","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.847202,40.732915],[-89.948421,40.654449],[-89.940292,40.625411],[-89.925408,40.625298],[-89.900977,40.618074],[-89.851774,40.653475],[-89.847202,40.732915]]]},"properties":{"id":"61569","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.352073,39.387051],[-90.259524,39.305588],[-90.169661,39.269252],[-90.08793,39.319953],[-90.113905,39.404629],[-90.152041,39.433825],[-90.264613,39.441504],[-90.320464,39.403762],[-90.279194,39.391332],[-90.296213,39.375794],[-90.322628,39.390606],[-90.352073,39.387051]]]},"properties":{"id":"62044","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.259524,39.305588],[-90.295498,39.225013],[-90.210685,39.225517],[-90.148069,39.261947],[-90.169661,39.269252],[-90.259524,39.305588]]]},"properties":{"id":"62081","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.295498,39.225013],[-90.47314,39.182915],[-90.470419,39.175068],[-90.448057,39.153004],[-90.221862,39.203832],[-90.210685,39.225517],[-90.295498,39.225013]]]},"properties":{"id":"62054","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.24337,41.179448],[-88.283706,41.206281],[-88.308761,41.232363],[-88.416116,41.168469],[-88.316118,41.112754],[-88.22375,41.17282],[-88.24337,41.179448]]]},"properties":{"id":"60424","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.586912,41.195367],[-88.571757,41.122874],[-88.50957,41.13748],[-88.569871,41.239026],[-88.586912,41.195367]]]},"properties":{"id":"60437","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.22375,41.17282],[-88.316118,41.112754],[-88.26846,41.0525],[-88.164678,41.130365],[-88.22375,41.17282]]]},"properties":{"id":"60961","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.571757,41.122874],[-88.586912,41.195367],[-88.664878,41.238223],[-88.693589,41.223362],[-88.722018,41.194086],[-88.64296,41.10776],[-88.569726,41.108345],[-88.571757,41.122874]]]},"properties":{"id":"60470","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.832919,38.21438],[-88.815074,38.170475],[-88.796992,38.126236],[-88.704606,38.125195],[-88.610659,38.256287],[-88.702209,38.271932],[-88.753757,38.275943],[-88.832919,38.21438]]]},"properties":{"id":"62810","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.231884,40.358098],[-91.256794,40.284739],[-91.143744,40.283571],[-91.231884,40.358098]]]},"properties":{"id":"62313","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.944895,40.51256],[-90.94567,40.461115],[-90.799783,40.522896],[-90.790088,40.537852],[-90.808531,40.636373],[-90.88481,40.638426],[-90.944895,40.51256]]]},"properties":{"id":"61420","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.404887,37.496952],[-88.36613,37.599431],[-88.42828,37.683475],[-88.541769,37.582357],[-88.546857,37.515321],[-88.539707,37.511539],[-88.404887,37.496952]]]},"properties":{"id":"62947","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.940274,40.89516],[-90.923096,40.807093],[-90.872631,40.784145],[-90.831356,40.786231],[-90.78826,40.821114],[-90.787319,40.894168],[-90.844518,40.894586],[-90.940274,40.89516]]]},"properties":{"id":"61418","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.069611,41.569053],[-90.185609,41.584653],[-90.241089,41.524995],[-90.322321,41.440351],[-90.328637,41.41144],[-90.202377,41.382859],[-90.086984,41.353018],[-89.972479,41.475154],[-90.069611,41.569053]]]},"properties":{"id":"61254","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.948296,41.477338],[-90.001439,41.340657],[-89.905034,41.346745],[-89.876075,41.379683],[-89.857349,41.486789],[-89.85746,41.511456],[-89.910264,41.518228],[-89.921125,41.521835],[-89.948296,41.477338]]]},"properties":{"id":"61234","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.500797,41.261825],[-90.534738,41.151365],[-90.438122,41.136808],[-90.437657,41.151252],[-90.386301,41.238082],[-90.396998,41.252673],[-90.445018,41.267983],[-90.500797,41.261825]]]},"properties":{"id":"61465","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.055372,40.984548],[-87.813338,41.004932],[-87.84914,41.047732],[-88.002609,41.044307],[-88.055372,40.984548]]]},"properties":{"id":"60922","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.511664,37.692691],[-89.438615,37.691981],[-89.450558,37.778706],[-89.498967,37.77748],[-89.511664,37.692691]]]},"properties":{"id":"62940","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.331424,37.658307],[-89.408629,37.689082],[-89.428181,37.625219],[-89.32515,37.626865],[-89.331424,37.658307]]]},"properties":{"id":"62975","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.105563,38.22807],[-89.054633,38.124735],[-89.0062,38.125197],[-88.986161,38.125132],[-88.967987,38.221672],[-89.02271,38.300073],[-89.040426,38.271404],[-89.105563,38.22807]]]},"properties":{"id":"62894","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.984219,37.306785],[-88.843485,37.249641],[-88.753614,37.301488],[-88.800635,37.335343],[-88.928754,37.359939],[-88.984219,37.306785]]]},"properties":{"id":"62908","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.928754,37.359939],[-88.800635,37.335343],[-88.768431,37.408083],[-88.803759,37.51233],[-88.928775,37.487119],[-88.947952,37.4882],[-89.020795,37.389241],[-88.928754,37.359939]]]},"properties":{"id":"62995","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.688452,37.319569],[-88.677474,37.384342],[-88.768431,37.408083],[-88.800635,37.335343],[-88.753614,37.301488],[-88.688452,37.319569]]]},"properties":{"id":"62943","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.317557,41.788855],[-88.331541,41.737906],[-88.28542,41.732387],[-88.26198,41.746456],[-88.30134,41.797395],[-88.317557,41.788855]]]},"properties":{"id":"60505","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.283527,41.721151],[-88.388651,41.709283],[-88.39176,41.70742],[-88.31569,41.60683],[-88.270673,41.631755],[-88.25379,41.694263],[-88.283527,41.721151]]]},"properties":{"id":"60543","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.729507,41.134819],[-87.656345,41.065885],[-87.52661,41.121453],[-87.526508,41.20914],[-87.720975,41.192906],[-87.729605,41.158166],[-87.729507,41.134819]]]},"properties":{"id":"60954","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.31448,41.57797],[-88.2946,41.56381],[-88.230141,41.550189],[-88.180734,41.557965],[-88.165168,41.587079],[-88.31448,41.57797]]]},"properties":{"id":"60586","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.283527,41.721151],[-88.25379,41.694263],[-88.235347,41.715517],[-88.235658,41.724929],[-88.284657,41.724029],[-88.283527,41.721151]]]},"properties":{"id":"60503","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.600604,41.57067],[-88.611646,41.567252],[-88.59973,41.55338],[-88.600604,41.57067]]]},"properties":{"id":"60537","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.401035,41.042172],[-90.439423,41.063995],[-90.477745,41.063705],[-90.488426,40.976569],[-90.442444,40.83895],[-90.333138,40.845143],[-90.327605,40.874406],[-90.296427,40.922084],[-90.317467,40.93074],[-90.297943,40.940514],[-90.222593,40.98797],[-90.256743,41.021209],[-90.353865,41.020211],[-90.357458,41.020246],[-90.401035,41.042172]]]},"properties":{"id":"61401","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.186535,40.928721],[-90.327605,40.874406],[-90.333138,40.845143],[-90.30997,40.801278],[-90.100122,40.871896],[-90.070398,40.887716],[-90.186535,40.928721]]]},"properties":{"id":"61436","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.852869,42.493169],[-87.971581,42.494843],[-87.971971,42.480197],[-87.876157,42.435655],[-87.797422,42.413646],[-87.793303,42.449715],[-87.852869,42.493169]]]},"properties":{"id":"60099","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.101757,42.397255],[-88.172662,42.328929],[-88.160664,42.293794],[-88.144914,42.30357],[-88.049525,42.385918],[-88.101757,42.397255]]]},"properties":{"id":"60073","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.856172,42.297708],[-87.820319,42.328858],[-87.815742,42.356738],[-87.892415,42.309463],[-87.856172,42.297708]]]},"properties":{"id":"60064","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.245121,42.186762],[-88.199378,42.202966],[-88.247501,42.191107],[-88.245121,42.186762]]]},"properties":{"id":"60021","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.800623,41.28128],[-88.708183,41.458191],[-88.750074,41.455062],[-88.770645,41.442498],[-88.800696,41.485272],[-88.825965,41.485317],[-88.973528,41.483532],[-88.973211,41.469083],[-89.010604,41.326633],[-88.951486,41.227646],[-88.940069,41.218756],[-88.800623,41.28128]]]},"properties":{"id":"61350","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.081943,41.467733],[-89.086223,41.459897],[-89.078349,41.461151],[-89.081943,41.467733]]]},"properties":{"id":"61372","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.941279,41.891752],[-89.121836,41.861082],[-89.11242,41.81755],[-89.083427,41.803204],[-89.053111,41.716155],[-89.009117,41.745761],[-88.941304,41.862921],[-88.941279,41.891752]]]},"properties":{"id":"60553","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.320423,41.022811],[-88.28655,41.02318],[-88.28707,41.03776],[-88.320423,41.022811]]]},"properties":{"id":"60920","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.1735,40.004537],[-89.1993,39.917109],[-89.180693,39.917275],[-89.143457,39.91792],[-89.104866,40.00555],[-89.144372,40.020114],[-89.1735,40.004537]]]},"properties":{"id":"62543","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.1735,40.004537],[-89.253741,40.058971],[-89.376221,40.01948],[-89.3625,39.968465],[-89.348792,39.971428],[-89.348857,39.961147],[-89.362509,39.964059],[-89.394793,39.939899],[-89.385886,39.917963],[-89.311332,39.896712],[-89.1993,39.917109],[-89.1735,40.004537]]]},"properties":{"id":"62548","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.60139,40.232923],[-89.632918,40.151192],[-89.550942,40.102167],[-89.486083,40.202516],[-89.544445,40.233632],[-89.563686,40.24793],[-89.60139,40.232923]]]},"properties":{"id":"62671","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.379397,42.254715],[-88.228684,42.278122],[-88.349451,42.298618],[-88.379397,42.254715]]]},"properties":{"id":"60012","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.378403,42.494518],[-88.507131,42.494778],[-88.471683,42.414246],[-88.413566,42.414409],[-88.365874,42.43127],[-88.378403,42.494518]]]},"properties":{"id":"60034","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.902798,40.517317],[-89.017918,40.441641],[-89.028445,40.494568],[-89.06152,40.561372],[-89.062114,40.576054],[-89.117364,40.531433],[-89.151736,40.455849],[-89.118662,40.406835],[-89.003284,40.400436],[-88.894164,40.356025],[-88.80646,40.472283],[-88.825781,40.487114],[-88.883658,40.517059],[-88.902798,40.517317]]]},"properties":{"id":"61705","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.883658,40.517059],[-88.825781,40.487114],[-88.797384,40.501324],[-88.806899,40.515986],[-88.883658,40.517059]]],[[[-88.883658,40.517059],[-88.925394,40.576633],[-89.06152,40.561372],[-89.028445,40.494568],[-88.953235,40.502735],[-88.902798,40.517317],[-88.883658,40.517059]]]]},"properties":{"id":"61761","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.925394,40.576633],[-88.883658,40.517059],[-88.806899,40.515986],[-88.78133,40.559711],[-88.91364,40.643411],[-88.925394,40.576633]]]},"properties":{"id":"61776","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.669581,40.398856],[-88.631562,40.340166],[-88.612562,40.339971],[-88.574309,40.376784],[-88.612868,40.500309],[-88.688141,40.471384],[-88.669581,40.398856]]]},"properties":{"id":"61722","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.102762,40.808896],[-89.113681,40.758147],[-89.100254,40.662891],[-89.052423,40.65777],[-88.9847,40.664954],[-88.93028,40.81146],[-88.93071,40.84048],[-89.065766,40.838186],[-89.102762,40.808896]]]},"properties":{"id":"61738","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.555719,40.281341],[-88.479502,40.281799],[-88.460302,40.31867],[-88.460262,40.369493],[-88.574309,40.376784],[-88.612562,40.339971],[-88.555719,40.281341]]]},"properties":{"id":"61724","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.745302,39.946076],[-88.745749,39.814174],[-88.707964,39.802988],[-88.639099,39.879259],[-88.640563,39.893878],[-88.692,39.967336],[-88.703132,39.967469],[-88.745302,39.946076]]]},"properties":{"id":"61818","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.688046,40.112851],[-88.764385,40.026306],[-88.745302,39.946076],[-88.703132,39.967469],[-88.66886,40.076497],[-88.688046,40.112851]]]},"properties":{"id":"61830","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.906467,39.39344],[-89.966214,39.36192],[-90.022177,39.314631],[-90.036693,39.203661],[-89.906955,39.150695],[-89.795402,39.196996],[-89.738737,39.249387],[-89.703001,39.310322],[-89.788642,39.338074],[-89.812266,39.391716],[-89.813195,39.39909],[-89.906467,39.39344]]]},"properties":{"id":"62626","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.700216,39.421157],[-89.788642,39.338074],[-89.703001,39.310322],[-89.605637,39.370669],[-89.605872,39.38535],[-89.700216,39.421157]]]},"properties":{"id":"62572","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.792797,39.117422],[-89.783619,39.104581],[-89.774143,39.071481],[-89.726774,39.020847],[-89.698801,39.028275],[-89.680184,39.051071],[-89.788711,39.144996],[-89.792797,39.117422]]]},"properties":{"id":"62069","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59505,39.539193],[-89.725246,39.523108],[-89.759537,39.52295],[-89.767618,39.526818],[-89.841491,39.549955],[-89.907519,39.52225],[-89.90688,39.500561],[-89.682975,39.4942],[-89.589065,39.50254],[-89.59505,39.539193]]]},"properties":{"id":"62690","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.079128,39.709845],[-90.182296,39.609096],[-90.172202,39.520218],[-90.15375,39.520315],[-90.153683,39.502927],[-90.153667,39.502043],[-90.080487,39.521042],[-90.07451,39.521104],[-90.002781,39.66128],[-90.079128,39.709845]]]},"properties":{"id":"62638","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.913452,38.657478],[-89.910561,38.647381],[-89.874739,38.64974],[-89.913452,38.657478]]],[[[-89.928296,38.72581],[-89.984878,38.730869],[-90.025394,38.734022],[-90.066116,38.663019],[-90.07991,38.644875],[-90.047208,38.64787],[-89.956558,38.657896],[-89.913452,38.657478],[-89.928296,38.72581]]]]},"properties":{"id":"62234","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.848099,38.756395],[-89.818896,38.655848],[-89.79031,38.655643],[-89.716806,38.687798],[-89.77817,38.742591],[-89.817394,38.762412],[-89.848099,38.756395]]]},"properties":{"id":"62281","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.168909,38.66065],[-90.159751,38.660369],[-90.158968,38.660352],[-90.173059,38.680239],[-90.17772,38.660536],[-90.168909,38.66065]]]},"properties":{"id":"62090","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.162434,41.147122],[-89.305992,41.135778],[-89.333027,41.132961],[-89.358597,41.103665],[-89.29514,41.09672],[-89.144885,41.058813],[-89.104896,41.104299],[-89.162434,41.147122]]]},"properties":{"id":"61336","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.601374,40.857248],[-89.57257,40.973281],[-89.61053,40.98807],[-89.677212,40.973781],[-89.664652,40.901075],[-89.601374,40.857248]]]},"properties":{"id":"61526","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.869399,37.238647],[-89.022498,37.279844],[-89.109483,37.270151],[-89.043695,37.200746],[-88.863687,37.207823],[-88.858541,37.216354],[-88.869399,37.238647]]]},"properties":{"id":"62941","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.119079,38.172631],[-90.034602,38.126909],[-89.888648,38.166039],[-89.899061,38.220755],[-89.989547,38.308293],[-89.998757,38.305201],[-90.119125,38.208859],[-90.119079,38.172631]]]},"properties":{"id":"62278","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.079128,39.709845],[-90.002781,39.66128],[-89.967036,39.671626],[-89.993843,39.792727],[-90.123563,39.809056],[-90.079128,39.709845]]]},"properties":{"id":"62601","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.512172,42.1718],[-89.495008,42.113553],[-89.345449,42.099063],[-89.294861,42.116106],[-89.306284,42.202726],[-89.357928,42.231224],[-89.396639,42.230844],[-89.512172,42.1718]]]},"properties":{"id":"61047","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.522295,42.215236],[-89.517057,42.171787],[-89.512172,42.1718],[-89.396639,42.230844],[-89.455285,42.250323],[-89.493742,42.244134],[-89.522295,42.215236]]]},"properties":{"id":"61039","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.622948,40.687803],[-89.675311,40.680416],[-89.624834,40.666781],[-89.59568,40.695386],[-89.622948,40.687803]]]},"properties":{"id":"61605","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.72039,40.738773],[-89.688523,40.812956],[-89.736598,40.813183],[-89.756052,40.817592],[-89.756776,40.743007],[-89.72039,40.738773]]]},"properties":{"id":"61528","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.502344,40.878771],[-89.500781,40.879197],[-89.499889,40.881731],[-89.50151,40.880973],[-89.502344,40.878771]]]},"properties":{"id":"61562","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.683991,38.205337],[-89.592395,38.13206],[-89.510592,38.097053],[-89.456252,38.201806],[-89.643343,38.299522],[-89.684524,38.219433],[-89.683991,38.205337]]]},"properties":{"id":"62237","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.643343,38.299522],[-89.456252,38.201806],[-89.426243,38.215161],[-89.546646,38.316862],[-89.662383,38.320609],[-89.643343,38.299522]]]},"properties":{"id":"62268","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.573702,40.192413],[-88.687993,40.142269],[-88.688046,40.112851],[-88.66886,40.076497],[-88.56342,40.120833],[-88.573702,40.192413]]]},"properties":{"id":"61839","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.963918,39.578838],[-90.916702,39.544719],[-90.81223,39.48733],[-90.69681,39.51641],[-90.64821,39.56017],[-90.656382,39.560232],[-90.656267,39.567775],[-90.644353,39.5639],[-90.593256,39.484586],[-90.581055,39.521728],[-90.630574,39.670494],[-90.80006,39.67128],[-90.916024,39.668155],[-90.96366,39.62254],[-90.963918,39.578838]]]},"properties":{"id":"62363","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.742005,39.786283],[-90.75496,39.78403],[-90.744751,39.778793],[-90.742005,39.786283]]]},"properties":{"id":"62362","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.963918,39.578838],[-91.115883,39.541231],[-91.033349,39.445204],[-90.916702,39.544719],[-90.963918,39.578838]]]},"properties":{"id":"62370","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.596428,37.526202],[-88.602551,37.425266],[-88.539707,37.511539],[-88.546857,37.515321],[-88.596428,37.526202]]]},"properties":{"id":"62928","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.149919,37.112862],[-89.212396,37.173618],[-89.255923,37.188113],[-89.270419,37.122915],[-89.18857,37.081093],[-89.149919,37.112862]]]},"properties":{"id":"62964","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.18857,37.081093],[-89.172081,37.06831],[-89.121171,37.112792],[-89.149919,37.112862],[-89.18857,37.081093]]]},"properties":{"id":"62963","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.167394,41.192301],[-89.270012,41.176687],[-89.305992,41.135778],[-89.162434,41.147122],[-89.167394,41.192301]]]},"properties":{"id":"61335","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.795819,38.200004],[-89.84743,38.1203],[-89.850845,38.111217],[-89.764615,38.025922],[-89.732133,38.015453],[-89.658118,38.058762],[-89.59341,38.043666],[-89.592395,38.13206],[-89.683991,38.205337],[-89.701319,38.219323],[-89.795819,38.200004]]]},"properties":{"id":"62286","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.987462,38.424712],[-89.965291,38.38095],[-89.816167,38.35097],[-89.823087,38.481807],[-89.934251,38.468592],[-89.987462,38.424712]]]},"properties":{"id":"62243","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.104477,38.556069],[-90.160778,38.598459],[-90.204599,38.58735],[-90.224055,38.573914],[-90.194434,38.547946],[-90.152558,38.541596],[-90.104477,38.556069]]]},"properties":{"id":"62206","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.595213,39.801873],[-89.711211,39.812637],[-89.715536,39.801136],[-89.658504,39.800745],[-89.642055,39.803843],[-89.595213,39.801873]]]},"properties":{"id":"62702","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.463403,39.868184],[-89.405897,39.814807],[-89.321355,39.771955],[-89.298421,39.786618],[-89.311332,39.896712],[-89.385886,39.917963],[-89.404969,39.932733],[-89.442787,39.933055],[-89.483347,39.918613],[-89.463403,39.868184]]]},"properties":{"id":"62515","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.496765,39.803351],[-89.492033,39.788184],[-89.470789,39.770839],[-89.405897,39.814807],[-89.463403,39.868184],[-89.496765,39.803351]]]},"properties":{"id":"62520","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.450636,40.254352],[-90.517764,40.266595],[-90.593218,40.238025],[-90.668043,40.190536],[-90.695884,40.103794],[-90.582544,39.979858],[-90.567514,39.983815],[-90.437939,40.109308],[-90.423124,40.188628],[-90.450636,40.254352]]]},"properties":{"id":"62681","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.437939,40.109308],[-90.567514,39.983815],[-90.513747,39.987891],[-90.444092,40.016231],[-90.407603,40.09368],[-90.437939,40.109308]]]},"properties":{"id":"62639","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.57467,40.438015],[-89.650528,40.341499],[-89.565188,40.32072],[-89.377771,40.338487],[-89.378991,40.382156],[-89.491969,40.435432],[-89.57467,40.438015]]]},"properties":{"id":"61734","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.491969,40.435432],[-89.378991,40.382156],[-89.379424,40.396712],[-89.382771,40.461904],[-89.418839,40.479314],[-89.491969,40.435432]]]},"properties":{"id":"61747","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.453355,37.600195],[-89.521564,37.572156],[-89.424472,37.403499],[-89.37729,37.510701],[-89.453355,37.600195]]]},"properties":{"id":"62998","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.845493,40.37183],[-87.912836,40.370866],[-87.89214,40.32798],[-87.81895,40.25587],[-87.74743,40.206855],[-87.738669,40.288709],[-87.710932,40.314228],[-87.691771,40.314213],[-87.66039,40.32876],[-87.845493,40.37183]]]},"properties":{"id":"61865","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.795228,40.458518],[-87.801241,40.470434],[-87.812029,40.466201],[-87.795228,40.458518]]]},"properties":{"id":"60932","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.181853,38.446405],[-89.14527,38.423196],[-89.158819,38.452809],[-89.181853,38.446405]]]},"properties":{"id":"62848","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.370787,38.225636],[-88.285382,38.176833],[-88.225584,38.152849],[-88.225519,38.191573],[-88.288429,38.256481],[-88.306678,38.256168],[-88.329542,38.25584],[-88.370491,38.255413],[-88.370787,38.225636]]]},"properties":{"id":"62862","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.193026,41.799881],[-90.244812,41.769511],[-90.218531,41.671993],[-90.19826,41.757982],[-90.193026,41.799881]]]},"properties":{"id":"61230","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.978182,41.676775],[-89.86342,41.723112],[-89.841907,41.737795],[-89.853474,41.757989],[-89.978238,41.719507],[-89.978182,41.676775]]]},"properties":{"id":"61261","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.078685,41.524093],[-88.004821,41.523756],[-87.994276,41.554874],[-88.078592,41.552683],[-88.086433,41.524878],[-88.078685,41.524093]]]},"properties":{"id":"60432","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.002265,41.475088],[-87.906436,41.469589],[-87.907438,41.49098],[-87.957604,41.555711],[-87.984038,41.555114],[-87.994276,41.554874],[-88.004821,41.523756],[-88.002265,41.475088]]]},"properties":{"id":"60451","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.817049,37.848623],[-88.752534,37.73044],[-88.724177,37.734199],[-88.744056,37.797691],[-88.817049,37.848623]]]},"properties":{"id":"62974","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.040202,37.773845],[-89.026184,37.760084],[-89.02154,37.756298],[-89.016307,37.773928],[-89.040202,37.773845]]]},"properties":{"id":"62933","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.293371,40.836424],[-89.4611,40.878738],[-89.497514,40.87873],[-89.524973,40.849236],[-89.532509,40.830229],[-89.473485,40.747743],[-89.350003,40.748199],[-89.292845,40.792132],[-89.293371,40.836424]]]},"properties":{"id":"61548","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.932113,40.112138],[-91.009392,40.077589],[-91.030612,39.924188],[-90.916809,39.887284],[-90.914479,39.93015],[-90.862124,40.104771],[-90.882675,40.104604],[-90.932113,40.112138]]]},"properties":{"id":"62324","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.009392,40.077589],[-91.114514,40.1291],[-91.14634,40.136902],[-91.147255,40.08003],[-91.166716,39.998714],[-91.166796,39.97684],[-91.147273,39.947852],[-91.030612,39.924188],[-91.009392,40.077589]]]},"properties":{"id":"62320","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.787747,39.840658],[-90.916609,39.845075],[-90.977967,39.788808],[-90.953299,39.692895],[-90.837215,39.707598],[-90.79942,39.79107],[-90.787747,39.840658]]]},"properties":{"id":"62314","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.257888,37.091416],[-89.172081,37.06831],[-89.18857,37.081093],[-89.270419,37.122915],[-89.289415,37.13207],[-89.307205,37.117848],[-89.257888,37.091416]]]},"properties":{"id":"62914","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.48606,37.334829],[-89.457983,37.241878],[-89.343653,37.284947],[-89.321689,37.306242],[-89.48606,37.334829]]]},"properties":{"id":"62957","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.669427,38.873853],[-89.528308,38.727002],[-89.480774,38.696494],[-89.404996,38.741015],[-89.562543,38.918112],[-89.623342,38.903956],[-89.669585,38.888492],[-89.669427,38.873853]],[[-89.59805,38.776618],[-89.573098,38.803434],[-89.576615,38.77925],[-89.59805,38.776618]]]},"properties":{"id":"62275","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.273551,38.734667],[-89.250071,38.742015],[-89.231076,38.82543],[-89.255311,38.829734],[-89.339299,38.821735],[-89.372068,38.741176],[-89.273551,38.734667]]]},"properties":{"id":"62253","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.504479,38.974597],[-89.38972,39.027826],[-89.462529,39.057168],[-89.519027,39.02812],[-89.506624,39.027969],[-89.504479,38.974597]]]},"properties":{"id":"62019","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.783847,42.327834],[-88.739788,42.160834],[-88.705638,42.182511],[-88.666953,42.327816],[-88.705613,42.355037],[-88.783847,42.327834]]]},"properties":{"id":"61038","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.824615,40.105101],[-90.914479,39.93015],[-90.916809,39.887284],[-90.916609,39.845075],[-90.787747,39.840658],[-90.760391,39.858883],[-90.582544,39.979858],[-90.695884,40.103794],[-90.795884,40.124787],[-90.824615,40.105101]]]},"properties":{"id":"62353","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.747559,41.233922],[-89.625656,41.241191],[-89.625635,41.321933],[-89.67031,41.375834],[-89.741681,41.316383],[-89.747559,41.233922]]]},"properties":{"id":"61314","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.398224,41.43657],[-89.403022,41.42947],[-89.393555,41.429375],[-89.398224,41.43657]]]},"properties":{"id":"61323","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.625635,41.321933],[-89.516072,41.321017],[-89.627058,41.467032],[-89.669351,41.460569],[-89.660915,41.453201],[-89.67031,41.375834],[-89.625635,41.321933]]]},"properties":{"id":"61379","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.179405,41.386407],[-89.227658,41.386066],[-89.182916,41.372404],[-89.179405,41.386407]]]},"properties":{"id":"61329","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.193222,41.585178],[-89.18126,41.481649],[-89.166051,41.454254],[-89.126751,41.454802],[-89.08769,41.456187],[-89.086223,41.459897],[-89.081943,41.467733],[-88.973211,41.469083],[-88.973528,41.483532],[-89.061215,41.633808],[-89.12238,41.64224],[-89.1672,41.628707],[-89.193222,41.585178]]]},"properties":{"id":"61342","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.604965,39.021219],[-90.62629,38.97507],[-90.468509,38.969168],[-90.572527,38.998872],[-90.575007,39.01943],[-90.604965,39.021219]]]},"properties":{"id":"62013","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.652852,39.220694],[-90.676835,39.143442],[-90.604965,39.021219],[-90.575007,39.01943],[-90.603569,39.117592],[-90.608396,39.173225],[-90.652852,39.220694]]]},"properties":{"id":"62047","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.020582,42.00739],[-90.153959,42.087202],[-90.112,41.929715],[-90.016493,41.930455],[-89.978437,41.931187],[-89.978734,42.000405],[-90.020582,42.00739]]]},"properties":{"id":"61285","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.092421,41.771799],[-90.016493,41.930455],[-90.112,41.929715],[-90.193026,41.799881],[-90.19826,41.757982],[-90.131348,41.736648],[-90.092421,41.771799]]]},"properties":{"id":"61252","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.685988,42.090314],[-89.824855,42.197276],[-89.919146,42.18012],[-89.860007,42.058393],[-89.80214,42.018634],[-89.685141,42.024779],[-89.68566,42.064623],[-89.685988,42.090314]]]},"properties":{"id":"61046","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.459719,40.42063],[-88.309483,40.398771],[-88.290421,40.384093],[-88.290963,40.457356],[-88.291006,40.47193],[-88.252848,40.472042],[-88.247639,40.501181],[-88.30996,40.559088],[-88.45926,40.530039],[-88.497401,40.515103],[-88.459719,40.42063]]]},"properties":{"id":"60936","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.462881,40.148306],[-88.425986,40.04017],[-88.368702,40.025624],[-88.388446,40.109886],[-88.388479,40.113185],[-88.388796,40.156772],[-88.462881,40.148306]]]},"properties":{"id":"61875","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.899641,39.865321],[-87.86113,39.95323],[-87.8818,39.963083],[-87.938423,39.952145],[-87.956532,39.879724],[-87.928025,39.861548],[-87.899641,39.865321]]]},"properties":{"id":"61810","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.629077,39.648384],[-89.631686,39.604548],[-89.59505,39.539193],[-89.589065,39.50254],[-89.551736,39.484672],[-89.432976,39.548601],[-89.432278,39.568096],[-89.439217,39.586978],[-89.442724,39.59104],[-89.443225,39.59287],[-89.478535,39.649195],[-89.587245,39.692467],[-89.624855,39.680221],[-89.629077,39.648384]]]},"properties":{"id":"62558","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.404713,39.597115],[-89.423391,39.593559],[-89.439579,39.589894],[-89.439217,39.586978],[-89.432278,39.568096],[-89.404713,39.597115]]]},"properties":{"id":"62540","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.195442,39.305478],[-89.157937,39.380655],[-89.250889,39.377917],[-89.224047,39.348828],[-89.21437,39.348889],[-89.21437,39.338283],[-89.223534,39.337843],[-89.195442,39.305478]]]},"properties":{"id":"62083","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.251564,39.684648],[-89.115695,39.655196],[-89.058232,39.684115],[-89.066453,39.779492],[-89.104156,39.787246],[-89.126223,39.789337],[-89.270317,39.714546],[-89.251564,39.684648]]]},"properties":{"id":"62513","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.884719,39.176117],[-87.91412,39.184875],[-87.953934,39.144168],[-87.973876,39.083664],[-87.992285,38.894991],[-87.976495,38.849673],[-87.964843,38.850011],[-87.907847,38.882818],[-87.832848,38.883054],[-87.858503,39.092209],[-87.884719,39.176117]]]},"properties":{"id":"62449","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.580767,38.875972],[-88.685286,38.785253],[-88.683618,38.767519],[-88.583341,38.70966],[-88.41856,38.735769],[-88.361014,38.793509],[-88.361777,38.898538],[-88.469935,38.92753],[-88.568841,38.878069],[-88.580767,38.875972]]]},"properties":{"id":"62858","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.624977,38.550329],[-89.581817,38.479062],[-89.577639,38.483159],[-89.573728,38.487301],[-89.624977,38.550329]]]},"properties":{"id":"62215","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.266432,39.459711],[-88.35099,39.396049],[-88.267942,39.345586],[-88.204935,39.346136],[-88.185256,39.375036],[-88.266432,39.459711]]]},"properties":{"id":"62440","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67611,39.677421],[-88.722487,39.579392],[-88.697736,39.520915],[-88.570103,39.513215],[-88.471039,39.520409],[-88.43592,39.563499],[-88.456895,39.593319],[-88.54222,39.710066],[-88.67611,39.677421]]]},"properties":{"id":"61951","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.798978,41.719232],[-87.798053,41.70441],[-87.797857,41.699926],[-87.773454,41.690449],[-87.752759,41.690948],[-87.798978,41.719232]]]},"properties":{"id":"60415","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.800287,41.762699],[-87.802094,41.797847],[-87.801961,41.806424],[-87.862832,41.754043],[-87.858072,41.750184],[-87.825321,41.762169],[-87.800287,41.762699]]]},"properties":{"id":"60501","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.166055,41.941747],[-88.140136,41.939924],[-88.123656,41.95297],[-88.12358,41.960323],[-88.125303,41.963673],[-88.122238,41.989112],[-88.144728,42.016142],[-88.165948,41.995218],[-88.166055,41.941747]]]},"properties":{"id":"60133","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.895911,41.742433],[-87.914265,41.716746],[-87.848028,41.739833],[-87.858072,41.750184],[-87.862832,41.754043],[-87.895911,41.742433]]]},"properties":{"id":"60480","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.848028,41.739833],[-87.81822,41.733109],[-87.825321,41.762169],[-87.858072,41.750184],[-87.848028,41.739833]]]},"properties":{"id":"60458","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.959935,42.02516],[-87.943377,42.022671],[-87.940582,42.030014],[-87.888066,42.074454],[-87.888067,42.078509],[-87.888074,42.102126],[-87.951284,42.095383],[-87.957565,42.072792],[-87.96597,42.029644],[-87.959935,42.02516]]]},"properties":{"id":"60056","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.804199,41.86498],[-87.804706,41.879124],[-87.804977,41.886701],[-87.804986,41.886983],[-87.827325,41.879175],[-87.833273,41.859975],[-87.832873,41.850911],[-87.803944,41.857735],[-87.804199,41.86498]]]},"properties":{"id":"60130","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.028752,42.104262],[-88.033094,42.063385],[-88.030466,42.050649],[-87.995715,42.046052],[-88.010572,42.095573],[-88.028752,42.104262]]]},"properties":{"id":"60008","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.566651,41.795509],[-87.624089,41.794665],[-87.624907,41.765514],[-87.587129,41.766051],[-87.56301,41.781232],[-87.566651,41.795509]]]},"properties":{"id":"60637","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.706556,41.895553],[-87.741203,41.895093],[-87.739932,41.86596],[-87.705669,41.866405],[-87.706542,41.895079],[-87.706556,41.895553]]]},"properties":{"id":"60624","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.647622,41.889049],[-87.641027,41.876752],[-87.641378,41.888632],[-87.647622,41.889049]]]},"properties":{"id":"60661","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.666457,41.86702],[-87.646195,41.85992],[-87.631552,41.867427],[-87.63311,41.876863],[-87.633694,41.87685],[-87.641027,41.876752],[-87.647622,41.889049],[-87.667069,41.888852],[-87.669522,41.888837],[-87.666457,41.86702]]]},"properties":{"id":"60607","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.633709,41.955326],[-87.677931,41.9464],[-87.677525,41.932745],[-87.62516,41.926869],[-87.633709,41.955326]]]},"properties":{"id":"60657","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.634217,41.969819],[-87.678233,41.961466],[-87.677931,41.9464],[-87.633709,41.955326],[-87.634217,41.969819]]]},"properties":{"id":"60613","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.726979,41.93179],[-87.726487,41.909384],[-87.704516,41.910201],[-87.670201,41.917886],[-87.678135,41.932285],[-87.726382,41.931799],[-87.726979,41.93179]]]},"properties":{"id":"60647","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.625268,41.809249],[-87.583448,41.812433],[-87.592614,41.831477],[-87.625804,41.828725],[-87.625268,41.809249]]]},"properties":{"id":"60653","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.7059,41.527961],[-87.698161,41.506164],[-87.674329,41.50407],[-87.664645,41.524334],[-87.7059,41.527961]]]},"properties":{"id":"60461","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.789771,41.606438],[-87.740355,41.577392],[-87.713863,41.587272],[-87.717117,41.610768],[-87.776529,41.631868],[-87.789771,41.606438]]]},"properties":{"id":"60452","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.721125,41.71317],[-87.682511,41.713667],[-87.682291,41.728236],[-87.687395,41.735444],[-87.721436,41.734862],[-87.721654,41.727788],[-87.721125,41.71317]]]},"properties":{"id":"60805","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.773734,39.108462],[-87.66963,39.073735],[-87.654957,39.110541],[-87.628727,39.157427],[-87.653864,39.157585],[-87.774764,39.145292],[-87.773734,39.108462]]]},"properties":{"id":"62433","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.907847,38.882818],[-87.928305,38.636241],[-87.912286,38.570104],[-87.855316,38.57001],[-87.827361,38.569699],[-87.813201,38.569304],[-87.777656,38.622351],[-87.772457,38.800128],[-87.757052,38.834895],[-87.807875,38.869279],[-87.832848,38.883054],[-87.907847,38.882818]]]},"properties":{"id":"62466","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.322211,39.239807],[-88.294183,39.22561],[-88.280332,39.229329],[-88.260635,39.212439],[-88.230969,39.182169],[-88.204935,39.346136],[-88.267942,39.345586],[-88.341387,39.301777],[-88.377951,39.251005],[-88.37794,39.229278],[-88.322211,39.239807]]]},"properties":{"id":"62468","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.294023,39.20534],[-88.260635,39.212439],[-88.280332,39.229329],[-88.294183,39.22561],[-88.294023,39.20534]]],[[[-88.322211,39.239807],[-88.37794,39.229278],[-88.396426,39.183252],[-88.43356,39.178644],[-88.433812,39.17124],[-88.316701,39.1159],[-88.246095,39.097744],[-88.241487,39.112449],[-88.294023,39.20534],[-88.322211,39.239807]]]]},"properties":{"id":"62445","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.715121,41.805738],[-88.8223,41.848823],[-88.823593,41.847278],[-88.828746,41.732219],[-88.819106,41.68222],[-88.737776,41.699499],[-88.706345,41.731036],[-88.715121,41.805738]]]},"properties":{"id":"60556","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.172875,40.100438],[-89.144611,40.039059],[-89.057452,40.071622],[-89.145973,40.136357],[-89.172875,40.100438]]]},"properties":{"id":"61749","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.762138,40.428126],[-88.862219,40.312082],[-88.84254,40.282616],[-88.822631,40.260093],[-88.784498,40.268006],[-88.631562,40.340166],[-88.669581,40.398856],[-88.762138,40.428126]]]},"properties":{"id":"61752","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.222638,40.205538],[-89.253599,40.076871],[-89.172875,40.100438],[-89.145973,40.136357],[-89.146279,40.151121],[-89.147685,40.216252],[-89.222638,40.205538]]]},"properties":{"id":"62512","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.119729,40.618922],[-88.23788,40.70404],[-88.23529,40.63243],[-88.194515,40.574723],[-88.118248,40.560802],[-88.119729,40.618922]]]},"properties":{"id":"60962","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.880398,38.059433],[-88.781057,38.037902],[-88.70487,38.110701],[-88.704606,38.125195],[-88.796992,38.126236],[-88.860873,38.126419],[-88.880398,38.059433]]]},"properties":{"id":"62836","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.826454,37.950667],[-88.835239,37.86296],[-88.835664,37.859744],[-88.817049,37.848623],[-88.744056,37.797691],[-88.70708,37.784351],[-88.623411,37.929835],[-88.655994,37.951339],[-88.683369,37.987647],[-88.760238,38.009902],[-88.834336,37.965214],[-88.826454,37.950667]]]},"properties":{"id":"62890","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.760238,38.009902],[-88.683369,37.987647],[-88.704987,38.103398],[-88.70487,38.110701],[-88.781057,38.037902],[-88.760238,38.009902]]]},"properties":{"id":"62860","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.332071,40.406982],[-90.362074,40.473643],[-90.447783,40.452056],[-90.49754,40.336206],[-90.39189,40.327451],[-90.332071,40.406982]]]},"properties":{"id":"61482","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.104742,40.462296],[-90.09774,40.451546],[-90.104581,40.468905],[-90.104742,40.462296]]]},"properties":{"id":"61519","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.954811,40.392239],[-89.985195,40.392107],[-90.029219,40.378722],[-90.210506,40.290882],[-90.219396,40.278947],[-90.200625,40.183947],[-90.199556,40.183945],[-90.050917,40.209185],[-89.973174,40.217068],[-89.942159,40.27548],[-89.954811,40.392239]]]},"properties":{"id":"62644","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.36613,37.599431],[-88.404887,37.496952],[-88.390216,37.437907],[-88.314703,37.438927],[-88.171762,37.465439],[-88.157994,37.577356],[-88.241175,37.647751],[-88.331662,37.599084],[-88.36613,37.599431]]]},"properties":{"id":"62931","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.49453,39.481162],[-90.492277,39.473536],[-90.477913,39.473507],[-90.485371,39.485078],[-90.494516,39.484809],[-90.49453,39.481162]]]},"properties":{"id":"62078","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.4332,41.48094],[-88.595371,41.427786],[-88.554365,41.29731],[-88.475039,41.261836],[-88.324142,41.301111],[-88.248463,41.346742],[-88.259965,41.393101],[-88.297819,41.41796],[-88.4332,41.48094]]]},"properties":{"id":"60450","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.231269,41.288914],[-88.248463,41.346742],[-88.324142,41.301111],[-88.313629,41.243615],[-88.246213,41.260673],[-88.231269,41.288914]]]},"properties":{"id":"60416","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.25451,41.52825],[-88.2946,41.56381],[-88.31448,41.57797],[-88.4042,41.52697],[-88.4332,41.48094],[-88.297819,41.41796],[-88.20799,41.47174],[-88.25451,41.52825]]]},"properties":{"id":"60447","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.56342,38.461123],[-88.548844,38.460668],[-88.556006,38.475304],[-88.56342,38.461123]]],[[[-88.56342,38.461123],[-88.642243,38.457539],[-88.643898,38.364271],[-88.702209,38.271932],[-88.610659,38.256287],[-88.425461,38.211894],[-88.408329,38.211837],[-88.370787,38.225636],[-88.370491,38.255413],[-88.476697,38.296741],[-88.496148,38.313666],[-88.563602,38.439042],[-88.56342,38.461123]]]]},"properties":{"id":"62895","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.277455,38.05384],[-88.225584,38.152849],[-88.285382,38.176833],[-88.408804,38.154361],[-88.374052,38.017636],[-88.277455,38.05384]]]},"properties":{"id":"62835","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.094874,40.720594],[-90.980946,40.681262],[-90.985776,40.800391],[-91.091569,40.822813],[-91.094874,40.720594]]]},"properties":{"id":"61425","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.831356,40.786231],[-90.789594,40.686399],[-90.711401,40.805822],[-90.711269,40.818668],[-90.78826,40.821114],[-90.831356,40.786231]]]},"properties":{"id":"61478","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.628624,41.030315],[-90.570679,41.036453],[-90.667849,41.060972],[-90.628624,41.030315]]],[[[-90.537948,40.809599],[-90.517126,40.961767],[-90.552375,41.006377],[-90.571046,41.021891],[-90.628868,41.022602],[-90.825386,40.938322],[-90.787319,40.894168],[-90.711269,40.818668],[-90.711401,40.805822],[-90.614878,40.787987],[-90.537948,40.809599]]]]},"properties":{"id":"61462","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.972479,41.475154],[-90.086984,41.353018],[-90.03035,41.323057],[-90.001439,41.340657],[-89.948296,41.477338],[-89.972479,41.475154]]]},"properties":{"id":"61235","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.086984,41.353018],[-90.202377,41.382859],[-90.280601,41.324206],[-90.267215,41.244179],[-90.199353,41.206017],[-90.133994,41.204785],[-90.124424,41.203246],[-90.115515,41.205044],[-90.050005,41.282684],[-90.03035,41.323057],[-90.086984,41.353018]]]},"properties":{"id":"61238","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.125952,40.778095],[-87.881222,40.740511],[-87.882613,40.769544],[-87.89393,40.798303],[-88.126719,40.807195],[-88.125952,40.778095]]]},"properties":{"id":"60938","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.128072,40.851336],[-87.877968,40.808715],[-87.847171,40.87284],[-87.831661,40.88774],[-88.129512,40.898249],[-88.128072,40.851336]]]},"properties":{"id":"60911","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.813338,41.004932],[-88.055372,40.984548],[-88.13105,40.968475],[-88.130722,40.939228],[-88.129512,40.898249],[-87.831661,40.88774],[-87.813338,41.004932]]]},"properties":{"id":"60927","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.813338,41.004932],[-87.673948,40.956159],[-87.618983,41.0084],[-87.656345,41.065885],[-87.729507,41.134819],[-87.798056,41.078144],[-87.812992,41.071308],[-87.84914,41.047732],[-87.813338,41.004932]]]},"properties":{"id":"60964","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.618983,41.0084],[-87.673948,40.956159],[-87.673549,40.920134],[-87.526005,40.951486],[-87.526207,41.010355],[-87.618983,41.0084]]]},"properties":{"id":"60912","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.578158,40.548178],[-87.743979,40.531205],[-87.614772,40.503654],[-87.578158,40.548178]]]},"properties":{"id":"60973","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.061067,37.597093],[-89.115576,37.598923],[-89.134873,37.534153],[-89.062418,37.535286],[-89.049544,37.560125],[-89.061067,37.597093]]],[[[-89.114526,37.64283],[-89.216434,37.665286],[-89.294569,37.621675],[-89.295445,37.599259],[-89.153668,37.600469],[-89.114526,37.64283]]]]},"properties":{"id":"62958","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.600975,37.735595],[-89.558445,37.803668],[-89.637871,37.887685],[-89.671484,37.900959],[-89.784035,37.881296],[-89.600975,37.735595]]]},"properties":{"id":"62280","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.558445,37.803668],[-89.600975,37.735595],[-89.516634,37.69271],[-89.511664,37.692691],[-89.498967,37.77748],[-89.558445,37.803668]]]},"properties":{"id":"62950","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.060358,39.173045],[-88.223475,39.17132],[-88.20942,39.107993],[-88.095249,39.103995],[-88.060358,39.173045]]]},"properties":{"id":"62432","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.171027,39.086683],[-90.146162,39.137672],[-90.161674,39.152324],[-90.166593,39.150813],[-90.164292,39.157684],[-90.221862,39.203832],[-90.448057,39.153004],[-90.483355,39.075093],[-90.360681,39.03243],[-90.290243,39.026767],[-90.227194,39.032156],[-90.171027,39.086683]]]},"properties":{"id":"62052","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.470419,39.175068],[-90.603569,39.117592],[-90.575007,39.01943],[-90.572527,38.998872],[-90.483355,39.075093],[-90.448057,39.153004],[-90.470419,39.175068]]]},"properties":{"id":"62031","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.254704,42.41534],[-90.162575,42.404577],[-90.187078,42.508045],[-90.381884,42.507005],[-90.254704,42.41534]]]},"properties":{"id":"61075","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.928426,37.59716],[-89.002693,37.596316],[-89.049544,37.560125],[-89.062418,37.535286],[-88.947952,37.4882],[-88.928775,37.487119],[-88.919187,37.596793],[-88.928426,37.59716]]]},"properties":{"id":"62939","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.317557,41.788855],[-88.395011,41.794643],[-88.443878,41.721786],[-88.417603,41.722102],[-88.331541,41.737906],[-88.317557,41.788855]]]},"properties":{"id":"60506","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.417603,41.722102],[-88.388651,41.709283],[-88.283527,41.721151],[-88.284657,41.724029],[-88.28542,41.732387],[-88.331541,41.737906],[-88.417603,41.722102]]]},"properties":{"id":"60538","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.479453,41.936117],[-88.602297,41.821117],[-88.602346,41.806846],[-88.527899,41.804472],[-88.413453,41.831017],[-88.395788,41.854106],[-88.375176,41.896927],[-88.479453,41.936117]],[[-88.520566,41.834103],[-88.52358,41.83635],[-88.520685,41.838242],[-88.520566,41.834103]]]},"properties":{"id":"60119","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.798056,41.078144],[-87.811001,41.079799],[-87.812992,41.071308],[-87.798056,41.078144]]]},"properties":{"id":"60910","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.143999,41.107072],[-88.146869,41.115985],[-88.153643,41.115835],[-88.1537,41.106628],[-88.143999,41.107072]]]},"properties":{"id":"60969","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526218,41.38529],[-87.670342,41.39849],[-87.71187,41.369136],[-87.66938,41.296778],[-87.526731,41.298052],[-87.526218,41.38529]]]},"properties":{"id":"60401","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.71187,41.369136],[-87.870358,41.396535],[-87.919968,41.30854],[-87.781583,41.291257],[-87.66938,41.296778],[-87.71187,41.369136]]]},"properties":{"id":"60468","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.297943,40.940514],[-90.296427,40.922084],[-90.327605,40.874406],[-90.186535,40.928721],[-90.184695,40.975729],[-90.184352,41.019366],[-90.222593,40.98797],[-90.297943,40.940514]]]},"properties":{"id":"61448","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.243152,41.151894],[-90.323511,41.122842],[-90.333001,41.063787],[-90.256743,41.021209],[-90.222593,40.98797],[-90.184352,41.019366],[-90.155401,41.048315],[-90.243152,41.151894]]]},"properties":{"id":"61467","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.198787,42.375881],[-88.194334,42.374716],[-88.15465,42.399437],[-88.189528,42.407295],[-88.197532,42.409249],[-88.198803,42.396163],[-88.198787,42.375881]]]},"properties":{"id":"60020","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.940069,41.218756],[-88.931743,41.207264],[-88.722018,41.194086],[-88.693589,41.223362],[-88.800623,41.28128],[-88.940069,41.218756]]]},"properties":{"id":"61325","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.126751,41.454802],[-89.105742,41.324301],[-89.077335,41.323737],[-89.08769,41.456187],[-89.126751,41.454802]]]},"properties":{"id":"61301","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.531145,38.852507],[-87.757052,38.834895],[-87.772457,38.800128],[-87.722287,38.643275],[-87.610877,38.643917],[-87.531145,38.852507]]]},"properties":{"id":"62439","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.267881,41.942511],[-89.29228,41.895325],[-89.202568,41.78593],[-89.11242,41.81755],[-89.121836,41.861082],[-89.198609,41.932279],[-89.267881,41.942511]]]},"properties":{"id":"61006","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.279565,41.747983],[-89.275592,41.74629],[-89.275551,41.747309],[-89.279565,41.747983]]]},"properties":{"id":"61331","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.510244,42.077502],[-89.68566,42.064623],[-89.685141,42.024779],[-89.667892,41.930282],[-89.605365,41.895261],[-89.543423,41.905829],[-89.527549,41.91378],[-89.449304,41.930852],[-89.472634,41.988688],[-89.510244,42.077502]]]},"properties":{"id":"61064","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.93071,40.84048],[-88.93028,40.81146],[-88.795362,40.80608],[-88.75725,40.82865],[-88.767126,40.828496],[-88.79713,40.93048],[-88.81576,40.97408],[-88.846262,40.973228],[-88.93092,40.97189],[-88.93139,40.92774],[-88.93071,40.84048]]]},"properties":{"id":"61740","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.96755,40.66465],[-88.794947,40.717062],[-88.795362,40.80608],[-88.93028,40.81146],[-88.9847,40.664954],[-88.96755,40.66465]]]},"properties":{"id":"61744","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.253741,40.058971],[-89.1735,40.004537],[-89.144372,40.020114],[-89.144611,40.039059],[-89.172875,40.100438],[-89.253599,40.076871],[-89.253741,40.058971]]]},"properties":{"id":"62518","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.272961,40.310519],[-89.263418,40.310661],[-89.263578,40.317169],[-89.272961,40.310519]]],[[[-89.272961,40.310519],[-89.366103,40.287404],[-89.404531,40.2793],[-89.375012,40.233622],[-89.296918,40.226698],[-89.27868,40.220586],[-89.222638,40.205538],[-89.147685,40.216252],[-89.148102,40.245116],[-89.263418,40.310661],[-89.272961,40.310519]]]]},"properties":{"id":"61723","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.579368,40.048769],[-89.719677,40.025694],[-89.769309,39.914322],[-89.760981,39.916405],[-89.645976,39.96119],[-89.629973,39.961459],[-89.531305,40.00557],[-89.571558,40.039395],[-89.579368,40.048769]]]},"properties":{"id":"62613","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.713323,40.543809],[-90.59907,40.52115],[-90.578654,40.586611],[-90.578688,40.63074],[-90.69223,40.632141],[-90.713323,40.543809]]]},"properties":{"id":"61438","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.578654,40.586611],[-90.446627,40.56907],[-90.559983,40.630448],[-90.578688,40.63074],[-90.578654,40.586611]]]},"properties":{"id":"61470","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.379397,42.254715],[-88.412877,42.228422],[-88.398317,42.204805],[-88.29068,42.209546],[-88.209621,42.24869],[-88.208305,42.251409],[-88.220469,42.267677],[-88.226791,42.27973],[-88.228684,42.278122],[-88.379397,42.254715]]]},"properties":{"id":"60014","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.151736,40.455849],[-89.266181,40.471028],[-89.265024,40.398465],[-89.264441,40.361868],[-89.119631,40.399396],[-89.118662,40.406835],[-89.151736,40.455849]]]},"properties":{"id":"61774","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.91364,40.643411],[-88.96755,40.66465],[-88.9847,40.664954],[-89.052423,40.65777],[-89.062114,40.576054],[-89.06152,40.561372],[-88.925394,40.576633],[-88.91364,40.643411]]]},"properties":{"id":"61748","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.651175,40.669345],[-88.703915,40.573759],[-88.686817,40.500794],[-88.688141,40.471384],[-88.612868,40.500309],[-88.553725,40.529167],[-88.564397,40.603035],[-88.56433,40.616572],[-88.614362,40.669491],[-88.651175,40.669345]]]},"properties":{"id":"61728","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.894164,40.356025],[-88.862219,40.312082],[-88.762138,40.428126],[-88.80646,40.472283],[-88.894164,40.356025]]]},"properties":{"id":"61736","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.029695,39.875292],[-89.081796,39.85288],[-89.09634,39.845433],[-89.141817,39.80091],[-89.126223,39.789337],[-89.104156,39.787246],[-88.955786,39.837439],[-88.957431,39.847524],[-88.957543,39.85479],[-89.028822,39.875644],[-89.029695,39.875292]]]},"properties":{"id":"62522","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.081796,39.85288],[-89.029695,39.875292],[-89.134716,39.910598],[-89.103899,39.852869],[-89.081796,39.85288]]],[[[-88.874677,39.911431],[-88.918421,39.965122],[-89.011846,39.962905],[-89.028822,39.875644],[-88.957543,39.85479],[-88.874677,39.911431]],[[-88.956129,39.964212],[-88.947774,39.913348],[-88.974072,39.907737],[-88.993,39.934328],[-88.955643,39.934979],[-88.956129,39.964212]]]]},"properties":{"id":"62526","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.058232,39.684115],[-88.866812,39.65294],[-88.81123,39.681922],[-88.923365,39.747393],[-88.969119,39.777574],[-88.980434,39.765631],[-88.99953,39.768613],[-89.037972,39.772253],[-89.055786,39.758109],[-89.055915,39.773579],[-89.048688,39.776195],[-89.066453,39.779492],[-89.058232,39.684115]]]},"properties":{"id":"62544","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67611,39.677421],[-88.54222,39.710066],[-88.52966,39.791965],[-88.707886,39.792261],[-88.745701,39.799532],[-88.801712,39.799074],[-88.801679,39.791796],[-88.756929,39.710665],[-88.67611,39.677421]]]},"properties":{"id":"61937","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.955786,39.837439],[-88.954519,39.851173],[-88.957431,39.847524],[-88.955786,39.837439]]]},"properties":{"id":"62523","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.113905,39.404629],[-90.08793,39.319953],[-90.022177,39.314631],[-89.966214,39.36192],[-90.113905,39.404629]]]},"properties":{"id":"62649","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.811391,39.078397],[-89.797513,39.085805],[-89.792703,39.071447],[-89.774143,39.071481],[-89.783619,39.104581],[-89.792841,39.108022],[-89.817522,39.078185],[-89.811391,39.078397]]]},"properties":{"id":"62009","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.848559,39.078016],[-89.860308,39.060052],[-89.848138,39.059916],[-89.848559,39.078016]]]},"properties":{"id":"62093","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.813195,39.39909],[-89.812266,39.391716],[-89.793433,39.391776],[-89.802255,39.399074],[-89.813195,39.39909]]]},"properties":{"id":"62672","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.027724,38.954796],[-89.973948,38.890265],[-89.934401,38.938044],[-89.934184,38.951215],[-90.027724,38.954796]]]},"properties":{"id":"62067","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.111811,38.80567],[-90.075855,38.779519],[-90.067082,38.789267],[-90.083782,38.833419],[-90.095928,38.844852],[-90.103457,38.852533],[-90.111811,38.80567]]]},"properties":{"id":"62048","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.089484,38.923504],[-90.096385,38.92152],[-90.107879,38.889434],[-90.065167,38.897134],[-90.069222,38.923746],[-90.089484,38.923504]]]},"properties":{"id":"62018","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.008492,38.70927],[-89.01404,38.562835],[-88.964776,38.562045],[-88.879695,38.541879],[-88.81312,38.678705],[-88.845877,38.700648],[-89.010529,38.735437],[-89.008492,38.70927]]]},"properties":{"id":"62881","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.100134,38.691231],[-89.095603,38.575838],[-89.01404,38.562835],[-89.008492,38.70927],[-89.100134,38.691231]]]},"properties":{"id":"62870","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.324679,40.981181],[-89.29514,41.09672],[-89.358597,41.103665],[-89.381481,41.080164],[-89.447693,40.973246],[-89.46157,40.929204],[-89.324679,40.981181]]]},"properties":{"id":"61540","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.199556,40.183945],[-90.302386,40.09189],[-90.061599,40.119294],[-90.050917,40.209185],[-90.199556,40.183945]]]},"properties":{"id":"62617","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.602254,37.247787],[-88.676274,37.135027],[-88.435391,37.171768],[-88.602254,37.247787]]]},"properties":{"id":"62910","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.022498,37.279844],[-88.869399,37.238647],[-88.843485,37.249641],[-88.984219,37.306785],[-89.08127,37.29098],[-89.022498,37.279844]]]},"properties":{"id":"62956","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.994759,39.981704],[-89.815001,39.909568],[-89.769309,39.914322],[-89.719677,40.025694],[-89.824987,40.122899],[-89.828971,40.125679],[-89.885844,40.134535],[-89.903715,40.134037],[-89.994951,40.034806],[-89.994869,40.010663],[-89.994759,39.981704]]]},"properties":{"id":"62675","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.849685,41.352998],[-90.839807,41.314067],[-90.627938,41.328242],[-90.66549,41.372355],[-90.849685,41.352998]]]},"properties":{"id":"61279","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.257285,38.387109],[-90.102175,38.396895],[-90.153766,38.484682],[-90.217882,38.489898],[-90.222417,38.495157],[-90.264056,38.521025],[-90.332042,38.396104],[-90.257285,38.387109]]]},"properties":{"id":"62236","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.219475,39.624714],[-90.217366,39.628868],[-90.229073,39.62901],[-90.223983,39.625297],[-90.219475,39.624714]]]},"properties":{"id":"62695","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.239562,42.154359],[-89.23441,42.05901],[-89.189439,42.063027],[-89.153202,42.063393],[-89.143925,42.150273],[-89.16052,42.178206],[-89.239562,42.154359]]]},"properties":{"id":"61084","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.336808,41.902319],[-89.29228,41.895325],[-89.267881,41.942511],[-89.189439,42.063027],[-89.23441,42.05901],[-89.294861,42.116106],[-89.345449,42.099063],[-89.472634,41.988688],[-89.449304,41.930852],[-89.336808,41.902319]]]},"properties":{"id":"61061","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.198609,41.932279],[-89.13407,42.063433],[-89.153202,42.063393],[-89.189439,42.063027],[-89.267881,41.942511],[-89.198609,41.932279]]]},"properties":{"id":"61015","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.587117,37.987352],[-89.594266,37.988066],[-89.594731,37.977257],[-89.585178,37.977101],[-89.587117,37.987352]]]},"properties":{"id":"62997","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.508125,37.968519],[-89.59341,38.043666],[-89.658118,38.058762],[-89.642498,37.927227],[-89.508125,37.968519]],[[-89.587117,37.987352],[-89.585178,37.977101],[-89.594731,37.977257],[-89.594266,37.988066],[-89.587117,37.987352]]]},"properties":{"id":"62272","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.573702,40.192413],[-88.56342,40.120833],[-88.519107,40.105272],[-88.463335,40.163868],[-88.460418,40.281935],[-88.479502,40.281799],[-88.555719,40.281341],[-88.573702,40.192413]]]},"properties":{"id":"61854","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.69681,39.51641],[-90.687423,39.396621],[-90.613694,39.395775],[-90.593256,39.484586],[-90.644353,39.5639],[-90.64821,39.56017],[-90.69681,39.51641]]]},"properties":{"id":"62361","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.644353,39.5639],[-90.656267,39.567775],[-90.656382,39.560232],[-90.64821,39.56017],[-90.644353,39.5639]]]},"properties":{"id":"62352","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.79942,39.79107],[-90.837215,39.707598],[-90.80006,39.67128],[-90.630574,39.670494],[-90.642037,39.688301],[-90.645185,39.706288],[-90.615404,39.75122],[-90.742005,39.786283],[-90.744751,39.778793],[-90.75496,39.78403],[-90.79942,39.79107]]]},"properties":{"id":"62340","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.10961,39.64667],[-91.156123,39.660366],[-91.178012,39.598196],[-91.115883,39.541231],[-90.963918,39.578838],[-90.96366,39.62254],[-91.10961,39.64667]]]},"properties":{"id":"62356","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.146035,37.204991],[-89.087296,37.164664],[-89.043695,37.200746],[-89.109483,37.270151],[-89.156998,37.241687],[-89.146035,37.204991]]]},"properties":{"id":"62970","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.899061,38.220755],[-89.888648,38.166039],[-89.84743,38.1203],[-89.795819,38.200004],[-89.85303,38.220296],[-89.899061,38.220755]]]},"properties":{"id":"62217","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.924597,37.959849],[-89.834996,37.904443],[-89.903394,37.954133],[-89.924597,37.959849]]]},"properties":{"id":"63673","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.850845,38.111217],[-89.870386,38.06783],[-89.834688,37.987709],[-89.764615,38.025922],[-89.850845,38.111217]]]},"properties":{"id":"62297","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.224798,38.804099],[-88.147814,38.569086],[-88.096857,38.56945],[-88.045445,38.603672],[-87.956406,38.649365],[-87.984142,38.791238],[-88.224798,38.804099]]]},"properties":{"id":"62450","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.045445,38.603672],[-87.929101,38.570002],[-87.912286,38.570104],[-87.928305,38.636241],[-87.956406,38.649365],[-88.045445,38.603672]]]},"properties":{"id":"62419","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.781608,41.451995],[-90.849685,41.352998],[-90.66549,41.372355],[-90.714982,41.439676],[-90.726041,41.41619],[-90.745679,41.415965],[-90.742464,41.439261],[-90.750142,41.449632],[-90.781608,41.451995]]]},"properties":{"id":"61284","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.356524,41.578292],[-90.339197,41.584033],[-90.337354,41.577354],[-90.355808,41.577725],[-90.310532,41.53384],[-90.23835,41.656282],[-90.340229,41.655632],[-90.36467,41.579539],[-90.356524,41.578292]]]},"properties":{"id":"61275","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.042302,38.584482],[-89.99898,38.571477],[-89.959972,38.578092],[-89.966301,38.614426],[-90.051964,38.602821],[-90.042302,38.584482]]]},"properties":{"id":"62208","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.217882,38.489898],[-90.153766,38.484682],[-90.145377,38.51868],[-90.217882,38.489898]]],[[[-90.194434,38.547946],[-90.224055,38.573914],[-90.264056,38.521025],[-90.222417,38.495157],[-90.194434,38.547946]]]]},"properties":{"id":"62240","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.111193,38.61872],[-90.068895,38.61895],[-90.047208,38.64787],[-90.07991,38.644875],[-90.132354,38.637496],[-90.111193,38.61872]]]},"properties":{"id":"62204","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.517039,37.77557],[-88.525524,37.763026],[-88.51268,37.757888],[-88.517039,37.77557]]]},"properties":{"id":"62965","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.642055,39.803843],[-89.658504,39.800745],[-89.651992,39.795267],[-89.642055,39.803843]]]},"properties":{"id":"62701","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.67794,40.278562],[-90.740293,40.221496],[-90.668043,40.190536],[-90.593218,40.238025],[-90.67794,40.278562]]]},"properties":{"id":"61452","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.917889,39.478029],[-88.88075,39.370486],[-88.813533,39.325599],[-88.801376,39.303456],[-88.722351,39.317549],[-88.686441,39.404372],[-88.728989,39.449775],[-88.902112,39.521675],[-88.917889,39.478029]]]},"properties":{"id":"62565","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.705904,41.126932],[-89.735061,41.130408],[-89.735147,41.112286],[-89.677015,41.112366],[-89.705904,41.126932]]]},"properties":{"id":"61426","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485826,42.360611],[-89.493742,42.244134],[-89.455285,42.250323],[-89.414898,42.34663],[-89.485826,42.360611]]]},"properties":{"id":"61067","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485826,42.360611],[-89.414898,42.34663],[-89.408604,42.352225],[-89.455074,42.50118],[-89.502615,42.501628],[-89.536313,42.461028],[-89.495678,42.360408],[-89.485826,42.360611]]]},"properties":{"id":"61070","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.939949,40.083635],[-87.9017,40.067057],[-87.813579,40.093536],[-87.79799,40.19035],[-87.79806,40.19582],[-87.82219,40.21708],[-87.92293,40.19287],[-87.939949,40.083635]],[[-87.844842,40.118145],[-87.83696,40.11932],[-87.83729,40.114283],[-87.849556,40.114136],[-87.844842,40.118145]]]},"properties":{"id":"61844","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.701834,40.14216],[-87.71955,40.14765],[-87.79799,40.19035],[-87.813579,40.093536],[-87.75324,40.087407],[-87.714757,40.101869],[-87.701834,40.14216]]]},"properties":{"id":"61858","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.651529,38.568166],[-87.813201,38.569304],[-87.827361,38.569699],[-87.73486,38.48004],[-87.651529,38.568166]]]},"properties":{"id":"62410","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.455247,38.603578],[-88.584533,38.607427],[-88.574734,38.548877],[-88.536561,38.540421],[-88.383,38.587848],[-88.455247,38.603578]]]},"properties":{"id":"62878","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.563602,38.439042],[-88.496148,38.313666],[-88.51555,38.426656],[-88.544343,38.438583],[-88.563602,38.439042]]]},"properties":{"id":"62886","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.260348,38.285834],[-88.306678,38.256168],[-88.288429,38.256481],[-88.260348,38.285834]]],[[[-88.329542,38.25584],[-88.406962,38.299879],[-88.370491,38.255413],[-88.329542,38.25584]]]]},"properties":{"id":"62809","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.041749,38.037924],[-88.01033,38.026525],[-88.012131,38.033845],[-88.034132,38.032045],[-88.041749,38.037924]]]},"properties":{"id":"62861","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.016493,41.930455],[-90.092421,41.771799],[-90.026192,41.708287],[-89.978238,41.719507],[-89.853474,41.757989],[-89.864544,41.888153],[-89.978437,41.931187],[-90.016493,41.930455]]]},"properties":{"id":"61270","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.131348,41.736648],[-90.026192,41.708287],[-90.092421,41.771799],[-90.131348,41.736648]]]},"properties":{"id":"61251","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.075263,41.594069],[-88.143045,41.583594],[-88.152916,41.582392],[-88.152872,41.581819],[-88.142734,41.575708],[-88.081059,41.55273],[-88.078592,41.552683],[-87.994276,41.554874],[-87.984038,41.555114],[-88.01175,41.63476],[-88.060157,41.640677],[-88.075263,41.594069]]]},"properties":{"id":"60441","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.040202,37.773845],[-89.058394,37.862051],[-89.060441,37.862057],[-89.087861,37.862139],[-89.105135,37.862193],[-89.129555,37.817687],[-89.151401,37.811046],[-89.151677,37.773956],[-89.093194,37.730666],[-89.044131,37.721239],[-89.02159,37.745159],[-89.026184,37.760084],[-89.040202,37.773845]],[[-89.119936,37.780392],[-89.122629,37.781404],[-89.122711,37.785906],[-89.119268,37.784932],[-89.119936,37.780392]],[[-89.091552,37.792577],[-89.08896,37.807222],[-89.077591,37.811254],[-89.077747,37.795511],[-89.091552,37.792577]]]},"properties":{"id":"62918","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.091855,42.269999],[-89.095342,42.257092],[-89.10462,42.237681],[-89.056208,42.237984],[-89.05699,42.267029],[-89.091855,42.269999]]]},"properties":{"id":"61104","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.358067,42.238481],[-89.359418,42.236377],[-89.356619,42.236334],[-89.358067,42.238481]]]},"properties":{"id":"61077","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.4611,40.878738],[-89.293371,40.836424],[-89.274296,40.851309],[-89.4611,40.878738]]]},"properties":{"id":"61545","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.009392,40.077589],[-90.932113,40.112138],[-91.046749,40.179855],[-91.084975,40.180024],[-91.114514,40.1291],[-91.009392,40.077589]]]},"properties":{"id":"62339","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.286069,40.022324],[-91.166796,39.97684],[-91.166716,39.998714],[-91.17656,40.020918],[-91.221448,40.049295],[-91.220389,40.057767],[-91.286069,40.022324]]]},"properties":{"id":"62338","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.261321,37.468438],[-89.329174,37.542798],[-89.37729,37.510701],[-89.424472,37.403499],[-89.48606,37.334829],[-89.321689,37.306242],[-89.251668,37.335275],[-89.258069,37.335593],[-89.255561,37.346607],[-89.248444,37.357887],[-89.261321,37.468438]]]},"properties":{"id":"62952","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.887978,42.022839],[-88.822406,42.045163],[-88.837951,42.153377],[-88.891274,42.174618],[-88.95021,42.152191],[-88.93922,42.09058],[-88.897791,42.022746],[-88.887978,42.022839]]]},"properties":{"id":"60146","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.876075,41.379683],[-89.905034,41.346745],[-89.856762,41.328538],[-89.823422,41.337554],[-89.876075,41.379683]]],[[[-89.839788,41.484966],[-89.789492,41.350319],[-89.741681,41.316383],[-89.67031,41.375834],[-89.660915,41.453201],[-89.685109,41.470089],[-89.758371,41.496664],[-89.839788,41.484966]]]]},"properties":{"id":"61361","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.278065,41.327969],[-89.206514,41.312222],[-89.163705,41.310187],[-89.177722,41.355459],[-89.182489,41.364398],[-89.182916,41.372404],[-89.227658,41.386066],[-89.277554,41.407807],[-89.277763,41.371343],[-89.258067,41.357107],[-89.277748,41.358678],[-89.281518,41.349653],[-89.28245,41.333259],[-89.278065,41.327969]]]},"properties":{"id":"61362","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.193222,41.585178],[-89.381686,41.599328],[-89.373537,41.553753],[-89.344311,41.544411],[-89.362309,41.524491],[-89.3444,41.512288],[-89.315888,41.451628],[-89.18126,41.481649],[-89.193222,41.585178]]]},"properties":{"id":"61330","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.315888,41.451628],[-89.325783,41.422451],[-89.310201,41.444314],[-89.315888,41.451628]]]},"properties":{"id":"61337","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.685109,41.470089],[-89.660915,41.453201],[-89.669351,41.460569],[-89.685109,41.470089]]]},"properties":{"id":"61338","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.81223,39.48733],[-90.895857,39.368763],[-90.791774,39.307142],[-90.715639,39.379704],[-90.687423,39.396621],[-90.69681,39.51641],[-90.81223,39.48733]]]},"properties":{"id":"62355","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.238394,40.068933],[-88.238179,40.039719],[-88.216041,40.039819],[-88.232478,40.083497],[-88.238394,40.068933]]],[[[-88.388446,40.109886],[-88.368702,40.025624],[-88.262771,40.032841],[-88.257405,40.076137],[-88.304819,40.113152],[-88.257973,40.135073],[-88.231995,40.135005],[-88.195637,40.224181],[-88.251094,40.253016],[-88.270348,40.267431],[-88.351345,40.214969],[-88.388796,40.156772],[-88.388479,40.113185],[-88.37108,40.113248],[-88.374275,40.110511],[-88.388446,40.109886]]]]},"properties":{"id":"61822","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.257447,40.080312],[-88.257973,40.135073],[-88.304819,40.113152],[-88.257405,40.076137],[-88.257447,40.080312]]]},"properties":{"id":"61821","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.290421,40.384093],[-88.309483,40.398771],[-88.313724,40.354673],[-88.346571,40.270962],[-88.351345,40.214969],[-88.270348,40.267431],[-88.25238,40.355219],[-88.290421,40.384093]]]},"properties":{"id":"61840","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.912836,40.370866],[-87.964184,40.385112],[-88.001657,40.239665],[-87.994611,40.224983],[-87.942103,40.225482],[-87.89214,40.32798],[-87.912836,40.370866]]]},"properties":{"id":"61862","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.902743,39.583549],[-89.128168,39.608383],[-89.157271,39.582351],[-89.156811,39.523877],[-89.137915,39.494817],[-89.01096,39.478917],[-88.917889,39.478029],[-88.902112,39.521675],[-88.902743,39.583549]]]},"properties":{"id":"62510","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.7488,39.297098],[-87.618964,39.301691],[-87.531375,39.405901],[-87.64792,39.487699],[-87.848663,39.476401],[-87.7488,39.297098]]]},"properties":{"id":"62441","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.774764,39.145292],[-87.653864,39.157585],[-87.764473,39.223173],[-87.820798,39.192131],[-87.774764,39.145292]]]},"properties":{"id":"62478","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.820798,39.192131],[-87.884719,39.176117],[-87.858503,39.092209],[-87.773734,39.108462],[-87.774764,39.145292],[-87.820798,39.192131]]]},"properties":{"id":"62413","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.286362,38.833982],[-88.33629,38.879873],[-88.361777,38.898538],[-88.361014,38.793509],[-88.299762,38.790548],[-88.286362,38.833982]]]},"properties":{"id":"62434","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.976817,38.823599],[-88.845877,38.700648],[-88.81312,38.678705],[-88.697518,38.693366],[-88.683618,38.767519],[-88.685286,38.785253],[-88.826373,38.824434],[-88.93962,38.824109],[-88.976817,38.823599]]]},"properties":{"id":"62854","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.577639,38.483159],[-89.419014,38.494899],[-89.485326,38.56573],[-89.498135,38.565974],[-89.573728,38.487301],[-89.577639,38.483159]]]},"properties":{"id":"62218","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.439522,38.601848],[-89.424206,38.601974],[-89.424262,38.605578],[-89.442751,38.608089],[-89.439522,38.601848]]]},"properties":{"id":"62219","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.570103,39.513215],[-88.490243,39.38776],[-88.447467,39.388238],[-88.471039,39.520409],[-88.570103,39.513215]]]},"properties":{"id":"61928","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.423071,39.768756],[-88.519019,39.791942],[-88.52966,39.791965],[-88.54222,39.710066],[-88.456895,39.593319],[-88.42421,39.622572],[-88.404688,39.725124],[-88.423071,39.768756]]]},"properties":{"id":"61911","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.95127,42.117228],[-87.951284,42.095383],[-87.888074,42.102126],[-87.889807,42.108452],[-87.95127,42.117228]]]},"properties":{"id":"60070","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.725967,42.125012],[-87.78226,42.123527],[-87.798994,42.105376],[-87.771379,42.08762],[-87.72451,42.08783],[-87.710799,42.095445],[-87.703526,42.101725],[-87.725967,42.125012]]]},"properties":{"id":"60093","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.710799,42.095445],[-87.702761,42.089666],[-87.722599,42.082723],[-87.72451,42.08783],[-87.771379,42.08762],[-87.758984,42.064991],[-87.744863,42.064837],[-87.73242,42.064666],[-87.669758,42.071629],[-87.703526,42.101725],[-87.710799,42.095445]]]},"properties":{"id":"60091","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.816327,42.018749],[-87.855851,41.989057],[-87.856369,41.983628],[-87.78834,41.982897],[-87.790766,42.000531],[-87.816327,42.018749]]]},"properties":{"id":"60631","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.740355,41.577392],[-87.789771,41.606438],[-87.823448,41.601841],[-87.790448,41.53671],[-87.771079,41.528128],[-87.742941,41.571616],[-87.740355,41.577392]]]},"properties":{"id":"60477","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.178996,42.03902],[-88.179027,42.061113],[-88.193936,42.045525],[-88.178996,42.03902]]],[[[-88.242558,42.067034],[-88.270805,42.06646],[-88.280072,42.012602],[-88.284911,41.976029],[-88.27595,41.969471],[-88.262853,41.978187],[-88.219699,42.014562],[-88.203167,42.045059],[-88.242558,42.067034]]]]},"properties":{"id":"60120","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.920288,41.9313],[-87.864639,41.91507],[-87.852523,41.919347],[-87.849588,41.937286],[-87.854205,41.945988],[-87.890763,41.95803],[-87.92,41.958151],[-87.920288,41.9313]]]},"properties":{"id":"60131","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.802676,41.823715],[-87.830694,41.817406],[-87.839931,41.805636],[-87.802268,41.813966],[-87.802676,41.823715]]]},"properties":{"id":"60534","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.709717,42.040944],[-87.708856,42.052429],[-87.72747,42.055256],[-87.709717,42.040944]]]},"properties":{"id":"60203","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.835693,41.684236],[-87.913072,41.677383],[-87.912395,41.657126],[-87.86409,41.644922],[-87.8201,41.649347],[-87.816465,41.681701],[-87.835693,41.684236]]]},"properties":{"id":"60464","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.803944,41.857735],[-87.832873,41.850911],[-87.842735,41.850711],[-87.85312,41.846064],[-87.852294,41.845801],[-87.830694,41.817406],[-87.802676,41.823715],[-87.803944,41.857735]]]},"properties":{"id":"60546","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.062986,42.13729],[-88.050222,42.16096],[-88.118706,42.241023],[-88.19874,42.255642],[-88.208305,42.251409],[-88.209621,42.24869],[-88.199378,42.202966],[-88.245121,42.186762],[-88.257697,42.1488],[-88.238381,42.106605],[-88.238291,42.092348],[-88.179801,42.067326],[-88.14466,42.067236],[-88.111441,42.067088],[-88.092404,42.084606],[-88.062986,42.13729]]]},"properties":{"id":"60010","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.969172,41.912184],[-87.948066,41.860966],[-87.919333,41.847679],[-87.912577,41.847867],[-87.917181,41.869082],[-87.920568,41.897365],[-87.920288,41.9313],[-87.96651,41.931238],[-87.969172,41.912184]]]},"properties":{"id":"60126","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.563724,41.5865],[-87.525302,41.581531],[-87.524842,41.630235],[-87.578483,41.644729],[-87.578573,41.637513],[-87.58058,41.609346],[-87.563724,41.5865]]]},"properties":{"id":"60409","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.653447,41.579083],[-87.636395,41.574303],[-87.636384,41.582342],[-87.653447,41.579083]]],[[[-87.706586,41.586212],[-87.713469,41.549753],[-87.698529,41.549832],[-87.655949,41.579063],[-87.655883,41.58634],[-87.706586,41.586212]]]]},"properties":{"id":"60429","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.633824,41.881946],[-87.633754,41.879386],[-87.622985,41.878302],[-87.617253,41.880921],[-87.624296,41.882119],[-87.633824,41.881946]]]},"properties":{"id":"60603","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.678233,41.961466],[-87.634217,41.969819],[-87.648855,41.984231],[-87.674609,41.983357],[-87.678233,41.961466]]]},"properties":{"id":"60640","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.742941,41.571616],[-87.713331,41.534424],[-87.713469,41.549753],[-87.706586,41.586212],[-87.713863,41.587272],[-87.740355,41.577392],[-87.742941,41.571616]]]},"properties":{"id":"60478","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.703276,41.49163],[-87.698161,41.506164],[-87.7059,41.527961],[-87.713295,41.529137],[-87.771079,41.528128],[-87.778147,41.469814],[-87.77097,41.469782],[-87.703276,41.49163]]]},"properties":{"id":"60443","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.905576,41.848657],[-88.941508,41.760774],[-88.940191,41.709815],[-88.828746,41.732219],[-88.823593,41.847278],[-88.905576,41.848657]]]},"properties":{"id":"60550","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.894164,40.356025],[-89.003284,40.400436],[-89.093652,40.319804],[-89.07812,40.28249],[-89.029222,40.253365],[-88.84254,40.282616],[-88.862219,40.312082],[-88.894164,40.356025]]]},"properties":{"id":"61745","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.145569,41.893644],[-88.14413,41.887873],[-88.127456,41.887737],[-88.145538,41.89437],[-88.145569,41.893644]]],[[[-88.174698,41.929564],[-88.14537,41.901128],[-88.124588,41.88778],[-88.090467,41.888471],[-88.082207,41.896964],[-88.101069,41.931255],[-88.140136,41.939924],[-88.166055,41.941747],[-88.174698,41.929564]]]]},"properties":{"id":"60188","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.00376,41.835468],[-87.991911,41.845762],[-87.999053,41.905038],[-88.048192,41.907386],[-88.04829,41.90386],[-88.047954,41.831574],[-88.00376,41.835468]]]},"properties":{"id":"60148","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.26198,41.746456],[-88.28542,41.732387],[-88.284657,41.724029],[-88.235658,41.724929],[-88.206005,41.7472],[-88.195986,41.778463],[-88.232031,41.776657],[-88.26198,41.746456]]]},"properties":{"id":"60504","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.073172,41.815238],[-88.055619,41.785988],[-87.98476,41.788021],[-87.985879,41.818866],[-88.00376,41.835468],[-88.047954,41.831574],[-88.071904,41.816826],[-88.073172,41.815238]]]},"properties":{"id":"60515","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.862813,39.647596],[-87.891309,39.64316],[-87.880989,39.614115],[-87.862813,39.647596]]]},"properties":{"id":"61949","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.14785,38.496908],[-88.024097,38.501523],[-87.953044,38.482044],[-87.855316,38.57001],[-87.912286,38.570104],[-87.929101,38.570002],[-88.096857,38.56945],[-88.147814,38.569086],[-88.147909,38.534536],[-88.14785,38.496908]]]},"properties":{"id":"62476","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.786497,38.999559],[-88.61763,39.010852],[-88.626143,39.033643],[-88.679858,39.138984],[-88.73358,39.172474],[-88.824473,39.093463],[-88.786497,38.999559]]]},"properties":{"id":"62411","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.73358,39.172474],[-88.679858,39.138984],[-88.581679,39.215315],[-88.694037,39.244851],[-88.735994,39.237474],[-88.73358,39.172474]]]},"properties":{"id":"62461","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.735994,39.237474],[-88.817632,39.258838],[-88.941039,39.147366],[-88.945679,39.129156],[-88.917963,39.104284],[-88.824473,39.093463],[-88.73358,39.172474],[-88.735994,39.237474]]]},"properties":{"id":"62414","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.945679,39.129156],[-89.06612,39.027072],[-88.955641,38.914646],[-88.929411,38.918913],[-88.860495,38.976782],[-88.917963,39.104284],[-88.945679,39.129156]]]},"properties":{"id":"62418","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.975193,40.557328],[-88.118248,40.560802],[-88.194515,40.574723],[-88.185307,40.530849],[-87.972454,40.48566],[-87.975193,40.557328]]]},"properties":{"id":"60948","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.45947,40.64661],[-88.56433,40.616572],[-88.564397,40.603035],[-88.459349,40.573568],[-88.42352,40.61734],[-88.45947,40.64661]]]},"properties":{"id":"61731","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.988594,37.917868],[-88.965404,37.908612],[-88.960819,37.92804],[-88.988594,37.917868]]]},"properties":{"id":"62874","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362074,40.473643],[-90.332071,40.406982],[-90.259237,40.406644],[-90.254034,40.451408],[-90.274676,40.542975],[-90.329711,40.585931],[-90.368604,40.554194],[-90.362074,40.473643]]]},"properties":{"id":"61477","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.319971,40.287061],[-90.219396,40.278947],[-90.210506,40.290882],[-90.259237,40.406644],[-90.332071,40.406982],[-90.39189,40.327451],[-90.319971,40.287061]]]},"properties":{"id":"61441","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.037098,40.495187],[-90.039446,40.487878],[-90.029811,40.487916],[-90.029701,40.494179],[-90.037098,40.495187]]]},"properties":{"id":"61524","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.153331,37.966103],[-88.181601,37.908058],[-88.146288,37.858728],[-88.092159,37.890947],[-88.036186,37.94386],[-88.153331,37.966103]]]},"properties":{"id":"62867","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.181601,37.908058],[-88.374487,37.87832],[-88.346195,37.826281],[-88.326432,37.824818],[-88.146288,37.858728],[-88.181601,37.908058]]]},"properties":{"id":"62871","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.031662,41.350547],[-88.186578,41.376996],[-88.259965,41.393101],[-88.248463,41.346742],[-88.231269,41.288914],[-88.207157,41.242518],[-88.215128,41.201897],[-88.12843,41.189393],[-88.011812,41.205604],[-88.011891,41.208191],[-87.976359,41.293012],[-88.031662,41.350547]]]},"properties":{"id":"60481","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.65404,41.456457],[-88.664878,41.238223],[-88.586912,41.195367],[-88.569871,41.239026],[-88.554365,41.29731],[-88.595371,41.427786],[-88.596906,41.486243],[-88.65404,41.456457]]]},"properties":{"id":"61360","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.569871,41.239026],[-88.50957,41.13748],[-88.47195,41.181643],[-88.475039,41.261836],[-88.554365,41.29731],[-88.569871,41.239026]]]},"properties":{"id":"60479","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.246213,41.260673],[-88.313629,41.243615],[-88.308761,41.232363],[-88.283706,41.206281],[-88.243925,41.194439],[-88.244155,41.201546],[-88.234766,41.23081],[-88.246213,41.260673]]]},"properties":{"id":"60407","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.425461,38.211894],[-88.704987,38.103398],[-88.683369,37.987647],[-88.655994,37.951339],[-88.49443,37.966206],[-88.494377,37.994897],[-88.484853,37.994738],[-88.374429,37.995054],[-88.374052,38.017636],[-88.408804,38.154361],[-88.408329,38.211837],[-88.425461,38.211894]]]},"properties":{"id":"62859","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.408329,38.211837],[-88.408804,38.154361],[-88.285382,38.176833],[-88.370787,38.225636],[-88.408329,38.211837]]]},"properties":{"id":"62887","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.143744,40.283571],[-91.102693,40.257005],[-91.018114,40.282772],[-90.974317,40.395785],[-90.950775,40.435339],[-90.94567,40.461115],[-90.944895,40.51256],[-91.023564,40.480273],[-91.249863,40.520919],[-91.236374,40.491286],[-91.24258,40.403379],[-91.226931,40.372748],[-91.231884,40.358098],[-91.143744,40.283571]],[[-91.172047,40.471368],[-91.169276,40.470048],[-91.170323,40.468102],[-91.173795,40.46878],[-91.172047,40.471368]]]},"properties":{"id":"62321","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.236374,40.491286],[-91.374208,40.463993],[-91.376736,40.390939],[-91.258084,40.395033],[-91.24258,40.403379],[-91.236374,40.491286]]]},"properties":{"id":"62341","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.264406,40.269884],[-91.266777,40.214483],[-91.122764,40.195397],[-91.102693,40.257005],[-91.143744,40.283571],[-91.256794,40.284739],[-91.264406,40.269884]]]},"properties":{"id":"62380","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.950775,40.435339],[-90.974317,40.395785],[-90.867871,40.36981],[-90.950775,40.435339]]]},"properties":{"id":"62374","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.787319,40.894168],[-90.825386,40.938322],[-90.844778,40.942055],[-90.844518,40.894586],[-90.787319,40.894168]]],[[[-90.787319,40.894168],[-90.78826,40.821114],[-90.711269,40.818668],[-90.787319,40.894168]]]]},"properties":{"id":"61447","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.88481,40.638426],[-90.808531,40.636373],[-90.872631,40.784145],[-90.923096,40.807093],[-90.985776,40.800391],[-90.980946,40.681262],[-90.951792,40.667009],[-90.88481,40.638426]]]},"properties":{"id":"61480","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.978182,41.676775],[-90.069611,41.569053],[-89.972479,41.475154],[-89.948296,41.477338],[-89.921125,41.521835],[-89.907051,41.525014],[-89.910264,41.518228],[-89.85746,41.511456],[-89.825248,41.693229],[-89.86342,41.723112],[-89.978182,41.676775]]]},"properties":{"id":"61277","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526248,40.672857],[-87.650112,40.709075],[-87.725576,40.71464],[-87.73521,40.712482],[-87.821673,40.698106],[-87.840814,40.697547],[-87.867428,40.630458],[-87.806703,40.581218],[-87.785009,40.581588],[-87.790316,40.559928],[-87.78392,40.531421],[-87.743979,40.531205],[-87.578158,40.548178],[-87.52631,40.549473],[-87.526248,40.672857]]]},"properties":{"id":"60953","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.578158,40.548178],[-87.614772,40.503654],[-87.743979,40.531205],[-87.78392,40.531421],[-87.81989,40.501515],[-87.812029,40.466201],[-87.801241,40.470434],[-87.795228,40.458518],[-87.77949,40.40134],[-87.527388,40.425197],[-87.52631,40.549473],[-87.578158,40.548178]]]},"properties":{"id":"60942","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.516634,37.69271],[-89.521564,37.572156],[-89.453355,37.600195],[-89.428181,37.625219],[-89.408629,37.689082],[-89.438615,37.691981],[-89.511664,37.692691],[-89.516634,37.69271]]]},"properties":{"id":"62942","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.680162,37.914146],[-89.671484,37.900959],[-89.637871,37.887685],[-89.447772,37.953471],[-89.447185,37.982292],[-89.508125,37.968519],[-89.642498,37.927227],[-89.680162,37.914146]]]},"properties":{"id":"62916","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.329174,37.542798],[-89.295445,37.599259],[-89.294569,37.621675],[-89.32515,37.626865],[-89.428181,37.625219],[-89.453355,37.600195],[-89.37729,37.510701],[-89.329174,37.542798]]]},"properties":{"id":"62905","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.923491,38.425026],[-89.034262,38.392552],[-89.02271,38.300073],[-88.967987,38.221672],[-88.869964,38.229366],[-88.793459,38.3206],[-88.793112,38.43145],[-88.923491,38.425026]]]},"properties":{"id":"62864","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.227194,39.032156],[-90.147666,38.984768],[-90.041555,38.998642],[-90.029987,38.998714],[-90.05771,39.071071],[-90.123538,39.086962],[-90.171027,39.086683],[-90.227194,39.032156]]]},"properties":{"id":"62012","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.483355,39.075093],[-90.572527,38.998872],[-90.468509,38.969168],[-90.450813,38.967769],[-90.385757,38.956439],[-90.371721,38.988507],[-90.360681,39.03243],[-90.483355,39.075093]]]},"properties":{"id":"62037","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.371721,38.988507],[-90.312787,38.958547],[-90.290243,39.026767],[-90.360681,39.03243],[-90.371721,38.988507]]]},"properties":{"id":"62022","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.296306,42.371999],[-90.403154,42.327646],[-90.264168,42.194799],[-90.215086,42.2104],[-90.296306,42.371999]]]},"properties":{"id":"61041","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.082265,37.462458],[-89.04454,37.388693],[-89.020795,37.389241],[-88.947952,37.4882],[-89.062418,37.535286],[-89.134873,37.534153],[-89.149216,37.529124],[-89.082265,37.462458]]]},"properties":{"id":"62912","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.803759,37.51233],[-88.631228,37.536102],[-88.708546,37.599277],[-88.794211,37.556643],[-88.919187,37.596793],[-88.928775,37.487119],[-88.803759,37.51233]]]},"properties":{"id":"62972","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.718225,37.599458],[-88.794211,37.556643],[-88.708546,37.599277],[-88.718225,37.599458]]]},"properties":{"id":"62967","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.280072,42.012602],[-88.340794,41.990763],[-88.357795,41.98533],[-88.319274,41.969003],[-88.284911,41.976029],[-88.280072,42.012602]]]},"properties":{"id":"60177","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.344226,41.825069],[-88.324826,41.822019],[-88.337165,41.832837],[-88.344226,41.825069]]]},"properties":{"id":"60539","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.52661,41.121453],[-87.656345,41.065885],[-87.618983,41.0084],[-87.526207,41.010355],[-87.52661,41.121453]]]},"properties":{"id":"60958","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.018036,41.088177],[-88.011812,41.205604],[-88.12843,41.189393],[-88.145508,41.13072],[-88.145048,41.116025],[-88.018036,41.088177]]]},"properties":{"id":"60913","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.145301,41.594759],[-88.13022,41.65344],[-88.164363,41.637937],[-88.270673,41.631755],[-88.31569,41.60683],[-88.31448,41.57797],[-88.165168,41.587079],[-88.162581,41.587683],[-88.152872,41.581819],[-88.152916,41.582392],[-88.145301,41.594759]]]},"properties":{"id":"60544","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.333001,41.063787],[-90.323511,41.122842],[-90.331217,41.151354],[-90.437657,41.151252],[-90.438122,41.136808],[-90.439423,41.063995],[-90.401035,41.042172],[-90.333001,41.063787]]]},"properties":{"id":"61472","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.118706,42.241023],[-88.050222,42.16096],[-88.016993,42.153704],[-87.984994,42.153626],[-87.965011,42.204477],[-87.994147,42.226833],[-88.082032,42.243962],[-88.118706,42.241023]]]},"properties":{"id":"60047","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.997889,42.366546],[-87.963631,42.345],[-87.928255,42.344857],[-87.884788,42.385157],[-87.9154,42.399799],[-87.989028,42.400189],[-87.997889,42.366546]]]},"properties":{"id":"60031","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.15465,42.399437],[-88.194334,42.374716],[-88.172662,42.328929],[-88.101757,42.397255],[-88.140227,42.405997],[-88.15465,42.399437]]]},"properties":{"id":"60041","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.811314,42.215356],[-87.817872,42.210681],[-87.817967,42.196224],[-87.808344,42.196233],[-87.811314,42.215356]]]},"properties":{"id":"60040","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.197532,42.409249],[-88.20674,42.410571],[-88.208773,42.398542],[-88.198803,42.396163],[-88.197532,42.409249]]],[[[-88.351297,42.356577],[-88.349451,42.298618],[-88.228684,42.278122],[-88.226791,42.27973],[-88.290289,42.382185],[-88.306093,42.376842],[-88.351297,42.356577]]]]},"properties":{"id":"60050","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.179313,42.29859],[-88.226791,42.27973],[-88.220469,42.267677],[-88.198721,42.2582],[-88.179313,42.29859]]]},"properties":{"id":"60042","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.077335,41.323737],[-89.105742,41.324301],[-89.117021,41.272815],[-89.11122,41.265037],[-89.106318,41.235962],[-88.951486,41.227646],[-89.010604,41.326633],[-89.077335,41.323737]]]},"properties":{"id":"61348","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.11122,41.265037],[-89.117021,41.272815],[-89.145296,41.250475],[-89.111176,41.250535],[-89.11122,41.265037]]]},"properties":{"id":"61316","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.047356,41.104787],[-89.009195,41.148649],[-89.16262,41.192312],[-89.167394,41.192301],[-89.162434,41.147122],[-89.104896,41.104299],[-89.047356,41.104787]]]},"properties":{"id":"61334","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.104896,41.104299],[-89.144885,41.058813],[-89.14354,41.04428],[-89.066592,41.009358],[-88.988388,41.014412],[-88.930631,41.044499],[-89.047356,41.104787],[-89.104896,41.104299]]]},"properties":{"id":"61377","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.86342,41.723112],[-89.825248,41.693229],[-89.709213,41.656993],[-89.632379,41.671561],[-89.601777,41.769438],[-89.623377,41.792836],[-89.841907,41.737795],[-89.86342,41.723112]]]},"properties":{"id":"61071","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.052058,41.641253],[-89.053111,41.716155],[-89.083427,41.803204],[-89.12238,41.64224],[-89.061215,41.633808],[-89.052058,41.641253]]]},"properties":{"id":"61318","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.59907,40.52115],[-90.503601,40.506626],[-90.446739,40.552305],[-90.446627,40.56907],[-90.578654,40.586611],[-90.59907,40.52115]]]},"properties":{"id":"61422","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.447783,40.452056],[-90.561492,40.367655],[-90.525446,40.336666],[-90.49754,40.336206],[-90.447783,40.452056]]]},"properties":{"id":"61411","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.119631,40.399396],[-89.093652,40.319804],[-89.003284,40.400436],[-89.118662,40.406835],[-89.119631,40.399396]]]},"properties":{"id":"61772","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.028445,40.494568],[-89.017918,40.441641],[-88.953235,40.502735],[-89.028445,40.494568]]]},"properties":{"id":"61701","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.564397,40.603035],[-88.553725,40.529167],[-88.497401,40.515103],[-88.45926,40.530039],[-88.459349,40.573568],[-88.564397,40.603035]]]},"properties":{"id":"61720","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.888966,39.752373],[-88.837028,39.762989],[-88.889441,39.790942],[-88.888966,39.752373]]]},"properties":{"id":"62549","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925414,39.44571],[-90.080487,39.521042],[-90.153667,39.502043],[-90.152041,39.433825],[-90.113905,39.404629],[-89.966214,39.36192],[-89.906467,39.39344],[-89.925414,39.44571]]]},"properties":{"id":"62674","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.771945,38.961392],[-89.763554,38.959724],[-89.754801,38.965666],[-89.77815,38.961473],[-89.771945,38.961392]]]},"properties":{"id":"62058","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.160778,38.598459],[-90.149529,38.61335],[-90.132354,38.637496],[-90.07991,38.644875],[-90.066116,38.663019],[-90.117455,38.676888],[-90.158968,38.660352],[-90.159751,38.660369],[-90.163135,38.65009],[-90.167376,38.652729],[-90.168909,38.66065],[-90.17772,38.660536],[-90.181615,38.660369],[-90.204599,38.58735],[-90.160778,38.598459]]]},"properties":{"id":"62201","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.158968,38.660352],[-90.117455,38.676888],[-90.173059,38.680239],[-90.158968,38.660352]]]},"properties":{"id":"62060","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.928415,38.745017],[-89.984878,38.730869],[-89.928296,38.72581],[-89.928415,38.745017]]]},"properties":{"id":"62062","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.697518,38.693366],[-88.81312,38.678705],[-88.879695,38.541879],[-88.838751,38.486934],[-88.719648,38.483712],[-88.699121,38.487664],[-88.697518,38.693366]]]},"properties":{"id":"62849","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.824401,40.98531],[-89.696564,40.973793],[-89.638565,41.06135],[-89.638516,41.090395],[-89.630476,41.090397],[-89.631568,41.0758],[-89.61099,41.03189],[-89.57279,41.07568],[-89.592,41.1122],[-89.677015,41.112366],[-89.735147,41.112286],[-89.735061,41.130408],[-89.705904,41.126932],[-89.777415,41.15527],[-89.824401,40.98531]]]},"properties":{"id":"61491","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.631568,41.0758],[-89.630476,41.090397],[-89.638516,41.090395],[-89.638565,41.06135],[-89.631568,41.0758]]]},"properties":{"id":"61424","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.989547,38.308293],[-89.899061,38.220755],[-89.85303,38.220296],[-89.856197,38.254725],[-89.816162,38.350442],[-89.816167,38.35097],[-89.965291,38.38095],[-90.02942,38.352198],[-89.996012,38.308355],[-89.989547,38.308293]]]},"properties":{"id":"62264","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.473929,39.261066],[-89.483594,39.224585],[-89.357292,39.122815],[-89.325953,39.188159],[-89.434238,39.261159],[-89.473929,39.261066]]]},"properties":{"id":"62051","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.490905,39.135864],[-89.506149,39.129265],[-89.492454,39.122588],[-89.490905,39.135864]]]},"properties":{"id":"62089","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.84211,39.610271],[-89.927315,39.652917],[-89.967036,39.671626],[-90.002781,39.66128],[-90.07451,39.521104],[-89.907519,39.52225],[-89.841491,39.549955],[-89.84211,39.610271]]]},"properties":{"id":"62692","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.760839,39.731088],[-89.800677,39.79779],[-89.993843,39.792727],[-89.967036,39.671626],[-89.927315,39.652917],[-89.794606,39.711865],[-89.760839,39.731088]]]},"properties":{"id":"62670","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.594614,40.703134],[-89.622948,40.687803],[-89.59568,40.695386],[-89.595536,40.699601],[-89.594614,40.703134]],[[-89.613183,40.697086],[-89.614695,40.697123],[-89.614675,40.698063],[-89.612599,40.698082],[-89.613183,40.697086]]]},"properties":{"id":"61606","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.756776,40.743007],[-89.833245,40.733655],[-89.847202,40.732915],[-89.851774,40.653475],[-89.790178,40.624134],[-89.735471,40.666988],[-89.718157,40.691774],[-89.718119,40.711049],[-89.72039,40.738773],[-89.756776,40.743007]]]},"properties":{"id":"61536","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.550594,40.738737],[-89.574287,40.7319],[-89.551045,40.721705],[-89.547676,40.725199],[-89.550594,40.738737]]],[[[-89.583974,40.732019],[-89.565036,40.758543],[-89.623848,40.734958],[-89.593523,40.732058],[-89.583974,40.732019]]]]},"properties":{"id":"61614","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.886642,40.975094],[-89.985479,40.974495],[-89.985395,40.902259],[-89.927209,40.916698],[-89.888532,40.916157],[-89.886642,40.975094]]]},"properties":{"id":"61451","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.704087,38.368151],[-89.704417,38.415667],[-89.759956,38.355465],[-89.703889,38.34235],[-89.703915,38.349829],[-89.722614,38.361698],[-89.704087,38.368151]]],[[[-89.684524,38.219433],[-89.643343,38.299522],[-89.662383,38.320609],[-89.703857,38.335877],[-89.856197,38.254725],[-89.85303,38.220296],[-89.795819,38.200004],[-89.701319,38.219323],[-89.684524,38.219433]]]]},"properties":{"id":"62257","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.834996,37.904443],[-89.784035,37.881296],[-89.671484,37.900959],[-89.680162,37.914146],[-89.732133,38.015453],[-89.764615,38.025922],[-89.834688,37.987709],[-89.903394,37.954133],[-89.834996,37.904443]]]},"properties":{"id":"62233","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.406435,41.478682],[-90.426145,41.4724],[-90.400017,41.472037],[-90.404215,41.481869],[-90.406435,41.478682]]],[[[-90.503633,41.518233],[-90.537834,41.460733],[-90.485876,41.442579],[-90.43193,41.456834],[-90.461432,41.523533],[-90.503633,41.518233]]]]},"properties":{"id":"61265","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.07207,41.333361],[-90.937652,41.332327],[-90.849685,41.352998],[-90.781608,41.451995],[-91.07207,41.333361]]]},"properties":{"id":"61259","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.910561,38.647381],[-89.871422,38.571631],[-89.831984,38.560934],[-89.75193,38.553879],[-89.79031,38.655643],[-89.818896,38.655848],[-89.874739,38.64974],[-89.910561,38.647381]]]},"properties":{"id":"62254","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.036202,38.498333],[-90.036262,38.482277],[-90.035929,38.459321],[-90.036202,38.498333]]],[[[-90.020896,38.509191],[-89.980819,38.526604],[-89.952641,38.570711],[-89.959972,38.578092],[-89.99898,38.571477],[-90.020896,38.509191]]]]},"properties":{"id":"62226","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.036262,38.482277],[-90.098826,38.555089],[-90.104477,38.556069],[-90.152558,38.541596],[-90.145377,38.51868],[-90.153766,38.484682],[-90.102175,38.396895],[-90.045303,38.37447],[-90.038894,38.410781],[-90.035929,38.459321],[-90.036262,38.482277]]]},"properties":{"id":"62260","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.561927,37.627275],[-88.675319,37.745323],[-88.70708,37.784351],[-88.744056,37.797691],[-88.724177,37.734199],[-88.561927,37.627275]]]},"properties":{"id":"62917","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.725246,39.523108],[-89.669662,39.619761],[-89.685509,39.62681],[-89.769952,39.639674],[-89.84211,39.610271],[-89.841491,39.549955],[-89.767618,39.526818],[-89.767786,39.536523],[-89.75611,39.542648],[-89.759537,39.52295],[-89.725246,39.523108]]]},"properties":{"id":"62615","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.717166,42.459298],[-89.769854,42.422935],[-89.735501,42.421888],[-89.688151,42.444892],[-89.717166,42.459298]]]},"properties":{"id":"61050","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.255561,37.346607],[-89.258069,37.335593],[-89.251668,37.335275],[-89.255561,37.346607]]]},"properties":{"id":"62961","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.647199,40.109532],[-87.64473,40.08699],[-87.63519,40.08705],[-87.625446,40.087925],[-87.647199,40.109532]]]},"properties":{"id":"61833","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.710932,40.314228],[-87.70084,40.30274],[-87.691771,40.314213],[-87.710932,40.314228]]]},"properties":{"id":"61848","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.74743,40.206855],[-87.81895,40.25587],[-87.82219,40.21708],[-87.79806,40.19582],[-87.74743,40.206855]]]},"properties":{"id":"61831","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.86743,38.351381],[-87.870139,38.348544],[-87.863666,38.348271],[-87.86387,38.354055],[-87.86743,38.351381]]]},"properties":{"id":"62852","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.614878,40.787987],[-90.509173,40.753563],[-90.472638,40.744362],[-90.443438,40.759593],[-90.499593,40.816615],[-90.537948,40.809599],[-90.614878,40.787987]]]},"properties":{"id":"61417","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.204731,38.281331],[-89.206472,38.257946],[-89.183414,38.257358],[-89.182711,38.286982],[-89.204731,38.281331]]]},"properties":{"id":"62876","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.704417,38.415667],[-89.704087,38.368151],[-89.703915,38.349829],[-89.703889,38.34235],[-89.703857,38.335877],[-89.662383,38.320609],[-89.546646,38.316862],[-89.463566,38.412483],[-89.634768,38.451105],[-89.704417,38.415667]]]},"properties":{"id":"62214","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.253851,38.599502],[-88.293631,38.556472],[-88.275727,38.51106],[-88.258058,38.440779],[-88.217234,38.396298],[-88.16482,38.411928],[-88.147627,38.466744],[-88.14785,38.496908],[-88.147909,38.534536],[-88.253851,38.599502]]]},"properties":{"id":"62446","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.508923,38.446017],[-88.258058,38.440779],[-88.275727,38.51106],[-88.508923,38.446017]]]},"properties":{"id":"62842","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.971308,38.12817],[-87.985274,38.215486],[-88.131514,38.212664],[-87.971308,38.12817]]]},"properties":{"id":"62827","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.957604,41.555711],[-87.907438,41.49098],[-87.843228,41.528478],[-87.85728,41.557688],[-87.909809,41.573338],[-87.957604,41.555711]]]},"properties":{"id":"60448","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.143045,41.583594],[-88.143463,41.594791],[-88.145301,41.594759],[-88.152916,41.582392],[-88.143045,41.583594]]],[[[-88.081059,41.55273],[-88.135144,41.551377],[-88.142734,41.575708],[-88.152872,41.581819],[-88.162581,41.587683],[-88.150425,41.521734],[-88.086433,41.524878],[-88.078592,41.552683],[-88.081059,41.55273]]]]},"properties":{"id":"60435","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.129555,37.817687],[-89.137888,37.843893],[-89.151291,37.843915],[-89.151401,37.811046],[-89.129555,37.817687]]]},"properties":{"id":"62949","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.060529,42.327106],[-89.045868,42.390053],[-89.059419,42.397554],[-89.098249,42.411725],[-89.099585,42.274554],[-89.098119,42.273152],[-89.090847,42.273034],[-89.064327,42.288762],[-89.060529,42.327106]]]},"properties":{"id":"61103","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.05699,42.267029],[-89.056208,42.237984],[-88.979716,42.239909],[-88.970291,42.250281],[-88.940731,42.260809],[-88.940682,42.274268],[-89.05699,42.267029]]]},"properties":{"id":"61108","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.1668,40.89739],[-89.217341,40.874239],[-89.102762,40.808896],[-89.065766,40.838186],[-89.1668,40.89739]]]},"properties":{"id":"61516","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.113681,40.758147],[-89.195352,40.73314],[-89.185186,40.667453],[-89.191224,40.661545],[-89.100254,40.662891],[-89.113681,40.758147]]]},"properties":{"id":"61771","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.274055,39.874972],[-91.165843,39.837013],[-91.087,39.803685],[-90.977967,39.788808],[-90.916609,39.845075],[-90.916809,39.887284],[-91.030612,39.924188],[-91.147273,39.947852],[-91.274055,39.874972]]]},"properties":{"id":"62347","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.046749,40.179855],[-90.932113,40.112138],[-90.882675,40.104604],[-90.842878,40.141431],[-90.833603,40.148836],[-90.893092,40.162952],[-90.988724,40.193981],[-91.046749,40.179855]]]},"properties":{"id":"62346","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.41742,37.16191],[-89.438354,37.161403],[-89.257888,37.091416],[-89.307205,37.117848],[-89.41742,37.16191]]]},"properties":{"id":"62962","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.022498,37.279844],[-89.08127,37.29098],[-89.072367,37.319306],[-89.230808,37.331867],[-89.255039,37.232136],[-89.156998,37.241687],[-89.109483,37.270151],[-89.022498,37.279844]]]},"properties":{"id":"62992","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.804148,42.4921],[-88.798533,42.327852],[-88.783847,42.327834],[-88.705613,42.355037],[-88.736519,42.492763],[-88.804148,42.4921]]]},"properties":{"id":"61012","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.277763,41.371343],[-89.281518,41.349653],[-89.277748,41.358678],[-89.258067,41.357107],[-89.277763,41.371343]]]},"properties":{"id":"61359","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.468509,38.969168],[-90.62629,38.97507],[-90.676326,38.967249],[-90.450813,38.967769],[-90.468509,38.969168]]]},"properties":{"id":"62036","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.264168,42.194799],[-90.153959,42.087202],[-90.020582,42.00739],[-90.07615,42.196865],[-90.215086,42.2104],[-90.264168,42.194799]]]},"properties":{"id":"61074","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.123563,39.809056],[-89.993843,39.792727],[-89.994506,39.901925],[-89.994759,39.981704],[-89.994869,40.010663],[-90.055556,39.981641],[-90.135695,39.873086],[-90.123563,39.809056]]]},"properties":{"id":"62612","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.104596,40.054868],[-88.10432,40.0201],[-88.105589,39.935706],[-88.056828,39.937852],[-88.028598,39.966821],[-88.009736,40.047339],[-88.104596,40.054868]]]},"properties":{"id":"61877","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.420336,39.879216],[-88.36851,39.996494],[-88.368702,40.025624],[-88.425986,40.04017],[-88.462966,40.025533],[-88.462567,39.944376],[-88.469638,39.879056],[-88.420336,39.879216]]]},"properties":{"id":"61851","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.028598,39.966821],[-87.938423,39.952145],[-87.8818,39.963083],[-87.9017,40.067057],[-87.939949,40.083635],[-87.982036,40.076047],[-88.009736,40.047339],[-88.028598,39.966821]]]},"properties":{"id":"61849","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.04013,40.254238],[-88.001657,40.239665],[-87.964184,40.385112],[-87.98979,40.390922],[-88.021502,40.387862],[-88.040616,40.356487],[-88.078507,40.268947],[-88.04013,40.254238]]]},"properties":{"id":"61847","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.252848,40.472042],[-88.290963,40.457356],[-88.290421,40.384093],[-88.25238,40.355219],[-88.194108,40.370546],[-88.021502,40.387862],[-87.98979,40.390922],[-87.972454,40.48566],[-88.185307,40.530849],[-88.247639,40.501181],[-88.252848,40.472042]]]},"properties":{"id":"60957","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.972454,40.48566],[-87.98979,40.390922],[-87.964184,40.385112],[-87.912836,40.370866],[-87.845493,40.37183],[-87.77949,40.40134],[-87.795228,40.458518],[-87.812029,40.466201],[-87.81989,40.501515],[-87.972454,40.48566]]]},"properties":{"id":"60960","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.251543,39.655428],[-89.157271,39.582351],[-89.128168,39.608383],[-89.115695,39.655196],[-89.251564,39.684648],[-89.251543,39.655428]]]},"properties":{"id":"62567","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.866812,39.65294],[-89.058232,39.684115],[-89.115695,39.655196],[-89.128168,39.608383],[-88.902743,39.583549],[-88.828109,39.600029],[-88.866812,39.65294]]]},"properties":{"id":"62550","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.156811,39.523877],[-89.312007,39.443785],[-89.278576,39.429082],[-89.137915,39.494817],[-89.156811,39.523877]]]},"properties":{"id":"62555","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.764473,39.223173],[-87.653864,39.157585],[-87.628727,39.157427],[-87.618964,39.301691],[-87.7488,39.297098],[-87.764473,39.223173]]]},"properties":{"id":"62477","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.880989,39.614115],[-87.872226,39.482854],[-87.848663,39.476401],[-87.64792,39.487699],[-87.531709,39.483922],[-87.532736,39.673944],[-87.773095,39.733664],[-87.862813,39.647596],[-87.880989,39.614115]],[[-87.588102,39.582958],[-87.596053,39.578391],[-87.596244,39.586209],[-87.588102,39.582958]]]},"properties":{"id":"61944","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.383,38.587848],[-88.293631,38.556472],[-88.253851,38.599502],[-88.299762,38.790548],[-88.361014,38.793509],[-88.41856,38.735769],[-88.455247,38.603578],[-88.383,38.587848]],[[-88.359766,38.761454],[-88.366163,38.767895],[-88.355333,38.76759],[-88.359766,38.761454]]]},"properties":{"id":"62824","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.41856,38.735769],[-88.583341,38.70966],[-88.584533,38.607427],[-88.455247,38.603578],[-88.41856,38.735769]]]},"properties":{"id":"62839","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.571503,38.582755],[-89.587255,38.702518],[-89.596025,38.691277],[-89.642814,38.575342],[-89.571503,38.582755]]]},"properties":{"id":"62216","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.644117,38.553499],[-89.733475,38.553719],[-89.704417,38.415667],[-89.634768,38.451105],[-89.581817,38.479062],[-89.624977,38.550329],[-89.644117,38.553499]],[[-89.680451,38.47582],[-89.685993,38.494953],[-89.667469,38.495101],[-89.667404,38.480456],[-89.680451,38.47582]]]},"properties":{"id":"62265","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.961024,42.132389],[-87.984994,42.153626],[-88.016993,42.153704],[-88.004678,42.093787],[-87.957565,42.072792],[-87.951284,42.095383],[-87.95127,42.117228],[-87.961024,42.132389]]]},"properties":{"id":"60004","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.95127,42.117228],[-87.889807,42.108452],[-87.910489,42.153135],[-87.911046,42.153133],[-87.961024,42.132389],[-87.95127,42.117228]]]},"properties":{"id":"60090","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.92483,42.177758],[-87.960298,42.206909],[-87.965011,42.204477],[-87.984994,42.153626],[-87.961024,42.132389],[-87.911046,42.153133],[-87.92483,42.177758]]]},"properties":{"id":"60089","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.852294,41.845801],[-87.861776,41.818009],[-87.839931,41.805636],[-87.830694,41.817406],[-87.852294,41.845801]]]},"properties":{"id":"60513","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.744863,42.064837],[-87.747816,42.015405],[-87.709973,42.011851],[-87.708662,42.019144],[-87.709717,42.040944],[-87.72747,42.055256],[-87.73242,42.064666],[-87.744863,42.064837]]]},"properties":{"id":"60076","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.779387,42.023585],[-87.763921,42.055382],[-87.795533,42.055227],[-87.825579,42.05488],[-87.779387,42.023585]]]},"properties":{"id":"60053","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.785002,41.909148],[-87.805748,41.908904],[-87.805119,41.890538],[-87.792734,41.890221],[-87.792788,41.886901],[-87.804977,41.886701],[-87.804706,41.879124],[-87.774675,41.880152],[-87.775113,41.894265],[-87.775586,41.908752],[-87.785002,41.909148]]]},"properties":{"id":"60302","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.774675,41.880152],[-87.804706,41.879124],[-87.804199,41.86498],[-87.779048,41.865392],[-87.774139,41.865459],[-87.774675,41.880152]]]},"properties":{"id":"60304","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.091401,42.065317],[-88.111441,42.067088],[-88.14466,42.067236],[-88.091401,42.065317]]],[[[-88.179801,42.067326],[-88.238291,42.092348],[-88.242558,42.067034],[-88.203167,42.045059],[-88.193936,42.045525],[-88.179027,42.061113],[-88.179801,42.067326]]]]},"properties":{"id":"60192","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.908196,41.789615],[-87.946821,41.781562],[-87.962685,41.781188],[-87.96468,41.768407],[-87.973042,41.731374],[-87.941046,41.703436],[-87.914265,41.716746],[-87.895911,41.742433],[-87.907793,41.774687],[-87.908196,41.789615]]]},"properties":{"id":"60527","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.883778,41.907558],[-87.864639,41.91507],[-87.920288,41.9313],[-87.920568,41.897365],[-87.903362,41.89773],[-87.887282,41.900164],[-87.883778,41.907558]]]},"properties":{"id":"60164","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.091401,42.065317],[-88.104214,42.048338],[-88.096066,42.04871],[-88.079693,42.049306],[-88.078711,42.067001],[-88.092404,42.084606],[-88.111441,42.067088],[-88.091401,42.065317]]]},"properties":{"id":"60195","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.079693,42.049306],[-88.096066,42.04871],[-88.099568,42.026564],[-88.091562,42.026844],[-88.060203,42.035523],[-88.079693,42.049306]]],[[[-88.14466,42.067236],[-88.179801,42.067326],[-88.179027,42.061113],[-88.178996,42.03902],[-88.138491,42.039571],[-88.104214,42.048338],[-88.091401,42.065317],[-88.14466,42.067236]]]]},"properties":{"id":"60169","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.578483,41.644729],[-87.524842,41.630235],[-87.524805,41.686411],[-87.586051,41.707765],[-87.585199,41.65566],[-87.578483,41.644729]]]},"properties":{"id":"60633","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.740318,41.793054],[-87.684646,41.792023],[-87.683478,41.793853],[-87.686299,41.83026],[-87.694967,41.832279],[-87.73881,41.83292],[-87.743391,41.816511],[-87.740318,41.793054]]]},"properties":{"id":"60632","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.681321,41.677283],[-87.667148,41.675834],[-87.642274,41.670531],[-87.643819,41.721451],[-87.682291,41.728236],[-87.682511,41.713667],[-87.681321,41.677283]]]},"properties":{"id":"60643","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.653929,41.757846],[-87.683047,41.757379],[-87.687395,41.735444],[-87.682291,41.728236],[-87.643819,41.721451],[-87.624371,41.729036],[-87.624759,41.758237],[-87.653929,41.757846]]]},"properties":{"id":"60620","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.684646,41.792023],[-87.686682,41.757478],[-87.683047,41.757379],[-87.653929,41.757846],[-87.654874,41.794172],[-87.683478,41.793853],[-87.684646,41.792023]]]},"properties":{"id":"60636","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.625804,41.828725],[-87.645491,41.830758],[-87.686299,41.83026],[-87.683478,41.793853],[-87.654874,41.794172],[-87.625701,41.794643],[-87.625268,41.809249],[-87.625804,41.828725]]]},"properties":{"id":"60609","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.586639,41.722323],[-87.585824,41.751506],[-87.587129,41.766051],[-87.624907,41.765514],[-87.624759,41.758237],[-87.624371,41.729036],[-87.586639,41.722323]]]},"properties":{"id":"60619","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.63311,41.876863],[-87.631552,41.867427],[-87.603387,41.850132],[-87.609247,41.882334],[-87.616444,41.882335],[-87.617253,41.880921],[-87.622985,41.878302],[-87.63311,41.876863]]]},"properties":{"id":"60605","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.523661,41.759907],[-87.585824,41.751506],[-87.586639,41.722323],[-87.586051,41.707765],[-87.524805,41.686411],[-87.523661,41.759907]]]},"properties":{"id":"60617","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.654734,41.486071],[-87.577339,41.469992],[-87.525705,41.470283],[-87.525437,41.539887],[-87.582632,41.542621],[-87.635944,41.538778],[-87.66257,41.5388],[-87.664645,41.524334],[-87.674329,41.50407],[-87.654734,41.486071]]]},"properties":{"id":"60411","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.698565,41.632245],[-87.698184,41.622255],[-87.675935,41.627214],[-87.698565,41.632245]]]},"properties":{"id":"60469","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.294023,39.20534],[-88.294183,39.22561],[-88.322211,39.239807],[-88.294023,39.20534]]],[[[-88.294023,39.20534],[-88.241487,39.112449],[-88.20942,39.107993],[-88.223475,39.17132],[-88.230969,39.182169],[-88.260635,39.212439],[-88.294023,39.20534]]]]},"properties":{"id":"62436","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.86096,40.224474],[-88.825189,40.150518],[-88.755661,40.185689],[-88.784498,40.268006],[-88.822631,40.260093],[-88.86096,40.224474]]]},"properties":{"id":"61735","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.784498,40.268006],[-88.755661,40.185689],[-88.687993,40.142269],[-88.573702,40.192413],[-88.555719,40.281341],[-88.612562,40.339971],[-88.631562,40.340166],[-88.784498,40.268006]]]},"properties":{"id":"61842","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.835836,39.747125],[-87.908677,39.798979],[-87.986337,39.722095],[-87.94653,39.646776],[-87.891309,39.64316],[-87.862813,39.647596],[-87.773095,39.733664],[-87.812293,39.744029],[-87.835836,39.747125]]]},"properties":{"id":"61917","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.055079,41.991537],[-88.071043,41.96659],[-88.051085,41.960743],[-88.051089,41.987257],[-88.051013,41.991701],[-88.055079,41.991537]]]},"properties":{"id":"60157","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.30134,41.797395],[-88.26198,41.746456],[-88.232031,41.776657],[-88.231191,41.820018],[-88.299103,41.818898],[-88.30134,41.797395]],[[-88.241106,41.775307],[-88.2451,41.777355],[-88.242251,41.779125],[-88.241106,41.775307]]]},"properties":{"id":"60502","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.215556,41.820669],[-88.146683,41.831375],[-88.177859,41.838183],[-88.211403,41.850431],[-88.215556,41.820669]]]},"properties":{"id":"60555","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.931085,41.993576],[-87.962867,41.983321],[-87.959968,41.945547],[-87.96651,41.931238],[-87.920288,41.9313],[-87.92,41.958151],[-87.931085,41.993576]]]},"properties":{"id":"60106","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.052407,41.779155],[-88.012457,41.74377],[-87.989938,41.766019],[-87.98476,41.788021],[-88.055619,41.785988],[-88.052407,41.779155]]]},"properties":{"id":"60516","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.952441,41.817745],[-87.985879,41.818866],[-87.98476,41.788021],[-87.989938,41.766019],[-87.96468,41.768407],[-87.962685,41.781188],[-87.946917,41.810726],[-87.952441,41.817745]]]},"properties":{"id":"60559","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.946821,41.781562],[-87.946917,41.810726],[-87.962685,41.781188],[-87.946821,41.781562]]]},"properties":{"id":"60514","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.003327,41.9489],[-87.959968,41.945547],[-87.962867,41.983321],[-87.995418,41.992661],[-88.003327,41.9489]]]},"properties":{"id":"60191","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.78577,39.96894],[-87.86113,39.95323],[-87.899641,39.865321],[-87.880677,39.850768],[-87.809092,39.83986],[-87.77706,39.88087],[-87.78577,39.96894]]]},"properties":{"id":"61876","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.953044,38.482044],[-88.024097,38.501523],[-88.00513,38.416699],[-87.945128,38.442074],[-87.953044,38.482044]]]},"properties":{"id":"62815","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.539157,38.973052],[-88.469935,38.92753],[-88.361777,38.898538],[-88.33629,38.879873],[-88.323893,38.945357],[-88.360504,39.089872],[-88.469233,39.077172],[-88.503846,39.009037],[-88.539157,38.973052]]]},"properties":{"id":"62424","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.235625,39.068073],[-89.176054,39.057252],[-89.241903,39.199292],[-89.2509,39.188301],[-89.235625,39.068073]]]},"properties":{"id":"62011","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.31045,40.6175],[-88.42352,40.61734],[-88.459349,40.573568],[-88.45926,40.530039],[-88.30996,40.559088],[-88.31045,40.6175]]]},"properties":{"id":"61773","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.053566,38.016232],[-89.053681,38.037668],[-89.133183,38.052216],[-89.177597,37.950311],[-89.150755,37.945185],[-89.058711,37.906639],[-89.039927,37.878511],[-89.011579,37.917966],[-89.002362,37.987389],[-88.999384,37.978169],[-89.028449,37.979698],[-89.034994,37.979461],[-89.076752,37.994268],[-89.067511,38.003114],[-89.053551,38.008879],[-89.053566,38.016232]]]},"properties":{"id":"62865","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.053551,38.008879],[-89.035705,38.009134],[-89.034881,38.023682],[-89.053566,38.016232],[-89.053551,38.008879]]]},"properties":{"id":"62891","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.076752,37.994268],[-89.065159,37.99426],[-89.067511,38.003114],[-89.076752,37.994268]]]},"properties":{"id":"62825","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.920946,40.611477],[-89.900977,40.618074],[-89.925408,40.625298],[-89.920946,40.611477]]],[[[-90.170874,40.545619],[-90.104581,40.468905],[-90.09774,40.451546],[-89.965138,40.443429],[-89.905582,40.451506],[-89.872463,40.513127],[-89.920946,40.611477],[-89.940292,40.625411],[-90.024252,40.625709],[-90.037199,40.622103],[-90.036236,40.629342],[-90.102097,40.625946],[-90.214617,40.596975],[-90.215159,40.560454],[-90.180512,40.560359],[-90.16087,40.567645],[-90.161365,40.545696],[-90.170874,40.545619]],[[-90.037098,40.495187],[-90.029701,40.494179],[-90.029811,40.487916],[-90.039446,40.487878],[-90.037098,40.495187]],[[-90.047769,40.495176],[-90.060412,40.485641],[-90.060439,40.494317],[-90.047769,40.495176]]]]},"properties":{"id":"61520","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.259237,40.406644],[-90.210506,40.290882],[-90.029219,40.378722],[-89.965138,40.443429],[-90.09774,40.451546],[-90.104742,40.462296],[-90.254034,40.451408],[-90.259237,40.406644]]]},"properties":{"id":"61542","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.965138,40.443429],[-90.029219,40.378722],[-89.985195,40.392107],[-89.905582,40.451506],[-89.965138,40.443429]]]},"properties":{"id":"61543","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.022222,40.634956],[-91.023564,40.480273],[-90.944895,40.51256],[-90.88481,40.638426],[-90.951792,40.667009],[-91.022222,40.634956]]]},"properties":{"id":"61450","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.799783,40.522896],[-90.94567,40.461115],[-90.950775,40.435339],[-90.867871,40.36981],[-90.754026,40.281017],[-90.799783,40.522896]]]},"properties":{"id":"62326","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.157994,37.577356],[-88.171762,37.465439],[-88.133242,37.57414],[-88.157994,37.577356]]]},"properties":{"id":"62919","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.940274,40.89516],[-91.013899,40.895619],[-91.091569,40.822813],[-90.985776,40.800391],[-90.923096,40.807093],[-90.940274,40.89516]]]},"properties":{"id":"61437","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.146084,40.649157],[-91.022222,40.634956],[-90.951792,40.667009],[-90.980946,40.681262],[-91.094874,40.720594],[-91.146084,40.649157]]]},"properties":{"id":"61454","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.764809,41.068603],[-90.840432,41.156968],[-90.879721,41.142333],[-90.900892,41.069748],[-90.900969,41.054999],[-90.901072,41.048551],[-90.862834,41.048436],[-90.764809,41.068603]]]},"properties":{"id":"61476","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.825386,40.938322],[-90.628868,41.022602],[-90.628624,41.030315],[-90.667849,41.060972],[-90.745355,41.06833],[-90.764809,41.068603],[-90.862834,41.048436],[-90.844778,40.942055],[-90.825386,40.938322]]]},"properties":{"id":"61453","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.485876,41.442579],[-90.491694,41.36963],[-90.328983,41.396873],[-90.328637,41.41144],[-90.322321,41.440351],[-90.396585,41.469401],[-90.43193,41.456834],[-90.485876,41.442579]]]},"properties":{"id":"61240","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.434089,41.315349],[-90.319486,41.324295],[-90.328983,41.396873],[-90.491694,41.36963],[-90.492055,41.340734],[-90.434089,41.315349]]]},"properties":{"id":"61273","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.868113,41.178478],[-90.050005,41.282684],[-90.115515,41.205044],[-90.110399,41.194136],[-90.124154,41.19396],[-90.124424,41.203246],[-90.133994,41.204785],[-90.063279,41.080708],[-90.039896,41.070553],[-89.906413,41.142063],[-89.868113,41.178478]]]},"properties":{"id":"61434","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.910264,41.518228],[-89.907051,41.525014],[-89.921125,41.521835],[-89.910264,41.518228]]]},"properties":{"id":"61258","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.975193,40.557328],[-87.972454,40.48566],[-87.81989,40.501515],[-87.78392,40.531421],[-87.790316,40.559928],[-87.806703,40.581218],[-87.867428,40.630458],[-87.958917,40.615053],[-87.975193,40.557328]]]},"properties":{"id":"60924","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.590669,40.83465],[-87.59077,40.817377],[-87.576904,40.826612],[-87.590669,40.83465]]]},"properties":{"id":"60945","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.216434,37.665286],[-89.114526,37.64283],[-89.061067,37.597093],[-89.049544,37.560125],[-89.002693,37.596316],[-89.021486,37.677011],[-89.131407,37.744966],[-89.212846,37.700411],[-89.216434,37.665286]]]},"properties":{"id":"62902","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.294569,37.621675],[-89.216434,37.665286],[-89.212846,37.700411],[-89.295698,37.700856],[-89.331424,37.658307],[-89.32515,37.626865],[-89.294569,37.621675]]]},"properties":{"id":"62903","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.105135,37.862193],[-89.151176,37.861999],[-89.283884,37.849575],[-89.202765,37.773586],[-89.151677,37.773956],[-89.151401,37.811046],[-89.151291,37.843915],[-89.137888,37.843893],[-89.129555,37.817687],[-89.105135,37.862193]]]},"properties":{"id":"62924","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.027317,38.937504],[-88.026949,38.922146],[-87.992285,38.894991],[-87.973876,39.083664],[-88.048336,39.08279],[-88.037283,38.966726],[-88.027317,38.937504]]]},"properties":{"id":"62480","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.832919,38.21438],[-88.869964,38.229366],[-88.967987,38.221672],[-88.986161,38.125132],[-88.958565,38.125299],[-88.815074,38.170475],[-88.832919,38.21438]]]},"properties":{"id":"62816","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.793112,38.43145],[-88.793459,38.3206],[-88.753757,38.275943],[-88.702209,38.271932],[-88.643898,38.364271],[-88.745737,38.475477],[-88.793112,38.43145]]]},"properties":{"id":"62814","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.029336,42.225668],[-89.939435,42.226034],[-89.921048,42.283887],[-89.943125,42.356695],[-89.944911,42.444414],[-89.94985,42.444459],[-90.035134,42.445004],[-90.132273,42.402856],[-90.029336,42.225668]]]},"properties":{"id":"61085","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.486106,42.507583],[-90.654027,42.478503],[-90.502419,42.396751],[-90.486106,42.507583]]]},"properties":{"id":"61025","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.857796,42.311945],[-89.943125,42.356695],[-89.921048,42.283887],[-89.857796,42.311945]]]},"properties":{"id":"61044","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.257697,42.1488],[-88.335507,42.13256],[-88.238381,42.106605],[-88.257697,42.1488]]]},"properties":{"id":"60110","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.335414,42.083564],[-88.270805,42.06646],[-88.242558,42.067034],[-88.238291,42.092348],[-88.238381,42.106605],[-88.335507,42.13256],[-88.369402,42.154056],[-88.374132,42.132291],[-88.352681,42.103342],[-88.335414,42.083564]]]},"properties":{"id":"60118","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.520566,41.834103],[-88.520685,41.838242],[-88.52358,41.83635],[-88.520566,41.834103]]]},"properties":{"id":"60144","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.381121,42.16984],[-88.398317,42.204805],[-88.412877,42.228422],[-88.471789,42.241422],[-88.510849,42.181774],[-88.517986,42.153774],[-88.408424,42.110436],[-88.374132,42.132291],[-88.369402,42.154056],[-88.381121,42.16984]]]},"properties":{"id":"60142","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.729605,41.158166],[-87.836474,41.147898],[-87.8647,41.135715],[-87.895606,41.147731],[-87.991248,41.205331],[-88.011891,41.208191],[-88.011812,41.205604],[-88.018036,41.088177],[-88.002609,41.044307],[-87.84914,41.047732],[-87.812992,41.071308],[-87.811001,41.079799],[-87.798056,41.078144],[-87.729507,41.134819],[-87.729605,41.158166]]]},"properties":{"id":"60901","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.22375,41.17282],[-88.164678,41.130365],[-88.145508,41.13072],[-88.12843,41.189393],[-88.215128,41.201897],[-88.244155,41.201546],[-88.243925,41.194439],[-88.24337,41.179448],[-88.22375,41.17282]]]},"properties":{"id":"60935","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.230141,41.550189],[-88.2946,41.56381],[-88.25451,41.52825],[-88.230141,41.550189]]],[[[-88.165168,41.587079],[-88.180734,41.557965],[-88.197575,41.486452],[-88.150425,41.521734],[-88.162581,41.587683],[-88.165168,41.587079]]]]},"properties":{"id":"60431","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.184695,40.975729],[-90.070563,40.959722],[-90.013951,41.025557],[-90.039896,41.070553],[-90.063279,41.080708],[-90.155401,41.048315],[-90.184352,41.019366],[-90.184695,40.975729]]]},"properties":{"id":"61485","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.251325,40.73369],[-90.138805,40.713243],[-90.100714,40.713072],[-90.100521,40.757093],[-90.081245,40.844747],[-90.100122,40.871896],[-90.30997,40.801278],[-90.329093,40.758057],[-90.251325,40.73369]]]},"properties":{"id":"61458","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.329093,40.758057],[-90.443438,40.759593],[-90.472638,40.744362],[-90.328003,40.714119],[-90.251325,40.73369],[-90.329093,40.758057]]]},"properties":{"id":"61474","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.081245,40.844747],[-90.008014,40.859505],[-89.985395,40.902259],[-89.985479,40.974495],[-89.985367,40.995847],[-90.013951,41.025557],[-90.070563,40.959722],[-90.070398,40.887716],[-90.100122,40.871896],[-90.081245,40.844747]]]},"properties":{"id":"61489","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.008014,40.859505],[-90.005078,40.745321],[-89.948421,40.654449],[-89.847202,40.732915],[-89.833245,40.733655],[-89.927209,40.916698],[-89.985395,40.902259],[-90.008014,40.859505]]]},"properties":{"id":"61529","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.852869,42.493169],[-87.793303,42.449715],[-87.797272,42.49192],[-87.852869,42.493169]]]},"properties":{"id":"60096","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.901987,42.311237],[-87.928255,42.344857],[-87.963631,42.345],[-88.019039,42.306094],[-87.974006,42.269386],[-87.93508,42.221215],[-87.924091,42.222196],[-87.90534,42.275343],[-87.901987,42.311237]]]},"properties":{"id":"60048","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.082032,42.243962],[-87.994147,42.226833],[-87.974006,42.269386],[-88.019039,42.306094],[-88.106678,42.293696],[-88.082032,42.243962]]]},"properties":{"id":"60060","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.19874,42.255642],[-88.118706,42.241023],[-88.082032,42.243962],[-88.106678,42.293696],[-88.144914,42.30357],[-88.160664,42.293794],[-88.179313,42.29859],[-88.198721,42.2582],[-88.19874,42.255642]]]},"properties":{"id":"60084","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.960298,42.206909],[-87.92483,42.177758],[-87.886881,42.200234],[-87.924091,42.222196],[-87.93508,42.221215],[-87.960298,42.206909]]]},"properties":{"id":"60069","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.605365,41.895261],[-89.623377,41.792836],[-89.601777,41.769438],[-89.49684,41.707626],[-89.361437,41.794435],[-89.390177,41.822728],[-89.390256,41.837569],[-89.336808,41.902319],[-89.449304,41.930852],[-89.527549,41.91378],[-89.514621,41.902166],[-89.543423,41.905829],[-89.605365,41.895261]],[[-89.414471,41.772697],[-89.405414,41.76792],[-89.419369,41.767407],[-89.414471,41.772697]]]},"properties":{"id":"61021","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.209575,41.671173],[-89.381899,41.613813],[-89.381686,41.599328],[-89.193222,41.585178],[-89.1672,41.628707],[-89.209575,41.671173]]]},"properties":{"id":"61367","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.667892,41.930282],[-89.78319,41.930302],[-89.864544,41.888153],[-89.853474,41.757989],[-89.841907,41.737795],[-89.623377,41.792836],[-89.605365,41.895261],[-89.667892,41.930282]],[[-89.756036,41.790332],[-89.762107,41.787534],[-89.761799,41.795881],[-89.756036,41.790332]]]},"properties":{"id":"61081","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.75725,40.82865],[-88.621282,40.771685],[-88.465987,40.8468],[-88.45334,40.93417],[-88.66292,40.972686],[-88.806259,40.98515],[-88.81576,40.97408],[-88.79713,40.93048],[-88.75823,40.90146],[-88.767126,40.828496],[-88.75725,40.82865]]]},"properties":{"id":"61764","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.45334,40.93417],[-88.465987,40.8468],[-88.451112,40.830981],[-88.336669,40.830527],[-88.35722,40.92055],[-88.41547,40.94898],[-88.45334,40.93417]]]},"properties":{"id":"61769","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.35957,41.00775],[-88.39805,41.00738],[-88.41547,40.94898],[-88.35722,40.92055],[-88.28293,40.9215],[-88.28495,40.97961],[-88.35957,41.00775]]]},"properties":{"id":"60934","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.767126,40.828496],[-88.75823,40.90146],[-88.79713,40.93048],[-88.767126,40.828496]]]},"properties":{"id":"61743","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.442219,40.07407],[-89.550942,40.102167],[-89.632918,40.151192],[-89.646088,40.153547],[-89.579368,40.048769],[-89.571558,40.039395],[-89.442219,40.07407]]]},"properties":{"id":"62666","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.799783,40.522896],[-90.754026,40.281017],[-90.67794,40.278562],[-90.561492,40.367655],[-90.447783,40.452056],[-90.503601,40.506626],[-90.59907,40.52115],[-90.713323,40.543809],[-90.790088,40.537852],[-90.799783,40.522896]],[[-90.569161,40.491017],[-90.570174,40.505935],[-90.557211,40.500029],[-90.569161,40.491017]]]},"properties":{"id":"61455","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.251987,40.657393],[-89.269617,40.602506],[-89.19337,40.595694],[-89.191224,40.661545],[-89.185186,40.667453],[-89.251987,40.657393]]]},"properties":{"id":"61729","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.969119,39.777574],[-88.923365,39.747393],[-88.888966,39.752373],[-88.889441,39.790942],[-88.837028,39.762989],[-88.801679,39.791796],[-88.801712,39.799074],[-88.860171,39.90818],[-88.874677,39.911431],[-88.957543,39.85479],[-88.957431,39.847524],[-88.954519,39.851173],[-88.955786,39.837439],[-89.104156,39.787246],[-89.066453,39.779492],[-89.048688,39.776195],[-89.037972,39.772253],[-88.99953,39.768613],[-88.969119,39.777574]]]},"properties":{"id":"62521","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.081796,39.85288],[-89.103899,39.852869],[-89.09634,39.845433],[-89.081796,39.85288]]]},"properties":{"id":"62537","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.771945,38.961392],[-89.769927,38.955351],[-89.763554,38.959724],[-89.771945,38.961392]]],[[[-89.852671,38.998502],[-89.77815,38.961473],[-89.754801,38.965666],[-89.726774,39.020847],[-89.774143,39.071481],[-89.792703,39.071447],[-89.811391,39.078397],[-89.817522,39.078185],[-89.848559,39.078016],[-89.848138,39.059916],[-89.860308,39.060052],[-89.860557,39.056392],[-89.852671,38.998502]]]]},"properties":{"id":"62088","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.925414,39.44571],[-89.906467,39.39344],[-89.813195,39.39909],[-89.802255,39.399074],[-89.793433,39.391776],[-89.812266,39.391716],[-89.788642,39.338074],[-89.700216,39.421157],[-89.682975,39.4942],[-89.90688,39.500561],[-89.925414,39.44571]]]},"properties":{"id":"62640","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.792841,39.108022],[-89.783619,39.104581],[-89.792797,39.117422],[-89.792841,39.108022]]]},"properties":{"id":"62023","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.027724,38.954796],[-89.934184,38.951215],[-89.914973,38.99882],[-90.029987,38.998714],[-90.041555,38.998642],[-90.047883,38.980065],[-90.027724,38.954796]]]},"properties":{"id":"62021","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.57257,40.973281],[-89.601374,40.857248],[-89.58316,40.842412],[-89.573093,40.824336],[-89.558833,40.820794],[-89.524973,40.849236],[-89.497514,40.87873],[-89.46157,40.929204],[-89.447693,40.973246],[-89.57257,40.973281]],[[-89.502344,40.878771],[-89.50151,40.880973],[-89.499889,40.881731],[-89.500781,40.879197],[-89.502344,40.878771]]]},"properties":{"id":"61523","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.904566,40.296858],[-89.828163,40.382603],[-89.954811,40.392239],[-89.942159,40.27548],[-89.904566,40.296858]]]},"properties":{"id":"61567","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.646088,40.153547],[-89.824987,40.122899],[-89.719677,40.025694],[-89.579368,40.048769],[-89.646088,40.153547]]]},"properties":{"id":"62642","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.575852,39.181306],[-89.483594,39.224585],[-89.473929,39.261066],[-89.474018,39.275642],[-89.587859,39.261626],[-89.575852,39.181306]]]},"properties":{"id":"62015","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.294163,39.648431],[-90.223983,39.625297],[-90.229073,39.62901],[-90.217366,39.628868],[-90.219475,39.624714],[-90.182296,39.609096],[-90.079128,39.709845],[-90.123563,39.809056],[-90.135695,39.873086],[-90.251639,39.873397],[-90.315804,39.809326],[-90.325656,39.788475],[-90.437696,39.727192],[-90.437834,39.714184],[-90.294163,39.648431]]]},"properties":{"id":"62650","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.902112,39.521675],[-88.728989,39.449775],[-88.697736,39.520915],[-88.722487,39.579392],[-88.828109,39.600029],[-88.902743,39.583549],[-88.902112,39.521675]]]},"properties":{"id":"62534","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.143925,42.150273],[-89.153202,42.063393],[-89.13407,42.063433],[-89.115008,42.06279],[-89.095265,42.054111],[-89.056528,42.064003],[-89.039731,42.078509],[-89.056688,42.150952],[-89.143925,42.150273]]]},"properties":{"id":"61020","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.23441,42.05901],[-89.239562,42.154359],[-89.216377,42.203773],[-89.306284,42.202726],[-89.294861,42.116106],[-89.23441,42.05901]]]},"properties":{"id":"61010","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.674934,42.208344],[-89.628808,42.170785],[-89.517057,42.171787],[-89.522295,42.215236],[-89.674934,42.208344]]]},"properties":{"id":"61007","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.558833,40.820794],[-89.573093,40.824336],[-89.559994,40.809137],[-89.558833,40.820794]]]},"properties":{"id":"61552","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.696564,40.973793],[-89.824401,40.98531],[-89.886642,40.975094],[-89.888532,40.916157],[-89.756052,40.817592],[-89.736598,40.813183],[-89.664652,40.901075],[-89.677212,40.973781],[-89.696564,40.973793]]]},"properties":{"id":"61559","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.565036,40.758543],[-89.583974,40.732019],[-89.574287,40.7319],[-89.550594,40.738737],[-89.555994,40.760437],[-89.558494,40.759637],[-89.565036,40.758543]]]},"properties":{"id":"61616","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.613183,40.697086],[-89.612599,40.698082],[-89.614675,40.698063],[-89.614695,40.697123],[-89.613183,40.697086]]]},"properties":{"id":"61625","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.456252,38.201806],[-89.510592,38.097053],[-89.447185,37.982292],[-89.447772,37.953471],[-89.374309,37.952099],[-89.354622,37.951939],[-89.312889,38.082774],[-89.296158,38.214177],[-89.319415,38.213757],[-89.426243,38.215161],[-89.456252,38.201806]]]},"properties":{"id":"62274","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.447185,37.982292],[-89.510592,38.097053],[-89.592395,38.13206],[-89.59341,38.043666],[-89.508125,37.968519],[-89.447185,37.982292]]]},"properties":{"id":"62238","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.640563,39.893878],[-88.668727,39.952552],[-88.692,39.967336],[-88.640563,39.893878]]]},"properties":{"id":"61855","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.270012,41.176687],[-89.281434,41.313413],[-89.332956,41.301167],[-89.356671,41.233235],[-89.32927,41.147931],[-89.333027,41.132961],[-89.305992,41.135778],[-89.270012,41.176687]]]},"properties":{"id":"61327","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.250284,41.266518],[-89.259784,41.257234],[-89.240598,41.257503],[-89.250284,41.266518]]]},"properties":{"id":"61340","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.094455,38.018223],[-89.940687,37.970874],[-89.941033,38.032023],[-90.036385,38.05815],[-90.094455,38.018223]]]},"properties":{"id":"62261","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.618537,41.485032],[-90.537834,41.460733],[-90.503633,41.518233],[-90.618537,41.485032]]]},"properties":{"id":"61201","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.310532,41.53384],[-90.355808,41.577725],[-90.356524,41.578292],[-90.36467,41.579539],[-90.370998,41.578435],[-90.392027,41.557476],[-90.420053,41.557435],[-90.461432,41.523533],[-90.43193,41.456834],[-90.396585,41.469401],[-90.391291,41.473382],[-90.400017,41.472037],[-90.426145,41.4724],[-90.391961,41.499704],[-90.371553,41.49009],[-90.352297,41.508805],[-90.376877,41.505913],[-90.378185,41.519811],[-90.341245,41.511379],[-90.317884,41.508755],[-90.310532,41.53384]],[[-90.393639,41.489382],[-90.406435,41.478682],[-90.404215,41.481869],[-90.393639,41.489382]]]},"properties":{"id":"61244","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.355808,41.577725],[-90.337354,41.577354],[-90.339197,41.584033],[-90.356524,41.578292],[-90.355808,41.577725]]]},"properties":{"id":"61278","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.098826,38.555089],[-90.036262,38.482277],[-90.036202,38.498333],[-90.020896,38.509191],[-89.99898,38.571477],[-90.042302,38.584482],[-90.08735,38.571398],[-90.098826,38.555089]]]},"properties":{"id":"62223","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.194434,38.547946],[-90.222417,38.495157],[-90.217882,38.489898],[-90.145377,38.51868],[-90.152558,38.541596],[-90.194434,38.547946]]]},"properties":{"id":"62239","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.651992,39.795267],[-89.653563,39.765762],[-89.643844,39.713743],[-89.595213,39.801873],[-89.642055,39.803843],[-89.651992,39.795267]]]},"properties":{"id":"62703","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.643051,39.69917],[-89.760839,39.731088],[-89.794606,39.711865],[-89.769952,39.639674],[-89.685509,39.62681],[-89.629077,39.648384],[-89.624855,39.680221],[-89.643051,39.69917]]]},"properties":{"id":"62629","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.653563,39.765762],[-89.715536,39.801136],[-89.711211,39.812637],[-89.758485,39.822589],[-89.800677,39.79779],[-89.760839,39.731088],[-89.643051,39.69917],[-89.643844,39.713743],[-89.653563,39.765762]]]},"properties":{"id":"62711","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.88075,39.370486],[-88.917195,39.296531],[-88.813533,39.325599],[-88.88075,39.370486]]]},"properties":{"id":"62438","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.359654,42.389882],[-89.408604,42.352225],[-89.414898,42.34663],[-89.455285,42.250323],[-89.396639,42.230844],[-89.357928,42.231224],[-89.223755,42.348727],[-89.223749,42.351218],[-89.232942,42.376859],[-89.359654,42.389882]],[[-89.358067,42.238481],[-89.356619,42.236334],[-89.359418,42.236377],[-89.358067,42.238481]]]},"properties":{"id":"61063","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.502615,42.501628],[-89.719399,42.505215],[-89.717166,42.459298],[-89.688151,42.444892],[-89.628597,42.43959],[-89.536313,42.461028],[-89.502615,42.501628]]]},"properties":{"id":"61060","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.749217,42.330867],[-89.764396,42.332134],[-89.757429,42.328111],[-89.749217,42.330867]]]},"properties":{"id":"61027","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.473485,40.747743],[-89.501341,40.659921],[-89.403835,40.642972],[-89.329614,40.660774],[-89.350003,40.748199],[-89.473485,40.747743]]]},"properties":{"id":"61571","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.532509,40.830229],[-89.555994,40.760437],[-89.550594,40.738737],[-89.547676,40.725199],[-89.576097,40.692437],[-89.583897,40.688937],[-89.616067,40.661045],[-89.57459,40.630137],[-89.553269,40.604666],[-89.514164,40.604574],[-89.501341,40.659921],[-89.473485,40.747743],[-89.532509,40.830229]]]},"properties":{"id":"61611","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.383362,40.571089],[-89.495481,40.568212],[-89.531157,40.553502],[-89.582779,40.450431],[-89.57467,40.438015],[-89.491969,40.435432],[-89.418839,40.479314],[-89.383362,40.571089]]]},"properties":{"id":"61568","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.283071,40.572443],[-89.355855,40.615078],[-89.383362,40.571089],[-89.418839,40.479314],[-89.382771,40.461904],[-89.266499,40.485552],[-89.283071,40.572443]]]},"properties":{"id":"61755","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.309079,40.644732],[-89.269617,40.602506],[-89.251987,40.657393],[-89.309079,40.644732]]]},"properties":{"id":"61742","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.789502,40.664236],[-90.69223,40.632141],[-90.578688,40.63074],[-90.559983,40.630448],[-90.509173,40.753563],[-90.614878,40.787987],[-90.711401,40.805822],[-90.789594,40.686399],[-90.789502,40.664236]]]},"properties":{"id":"61473","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.628868,41.022602],[-90.571046,41.021891],[-90.570679,41.036453],[-90.628624,41.030315],[-90.628868,41.022602]]],[[[-90.552375,41.006377],[-90.517126,40.961767],[-90.516972,40.976715],[-90.552375,41.006377]]]]},"properties":{"id":"61435","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.13022,41.65344],[-88.145301,41.594759],[-88.143463,41.594791],[-88.075263,41.594069],[-88.060157,41.640677],[-88.052838,41.680834],[-88.111388,41.675238],[-88.13022,41.65344]]]},"properties":{"id":"60446","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.870358,41.396535],[-87.71187,41.369136],[-87.670342,41.39849],[-87.670569,41.420352],[-87.709689,41.456439],[-87.709872,41.464377],[-87.77097,41.469782],[-87.778147,41.469814],[-87.903548,41.454305],[-87.870358,41.396535]]]},"properties":{"id":"60449","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.143045,41.583594],[-88.075263,41.594069],[-88.143463,41.594791],[-88.143045,41.583594]]],[[[-88.081059,41.55273],[-88.142734,41.575708],[-88.135144,41.551377],[-88.081059,41.55273]]]]},"properties":{"id":"60403","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.002265,41.475088],[-88.022102,41.467664],[-88.038779,41.394449],[-88.031662,41.350547],[-87.976359,41.293012],[-87.919968,41.30854],[-87.870358,41.396535],[-87.903548,41.454305],[-87.906436,41.469589],[-88.002265,41.475088]]]},"properties":{"id":"60442","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.246213,41.260673],[-88.234766,41.23081],[-88.207157,41.242518],[-88.231269,41.288914],[-88.246213,41.260673]]]},"properties":{"id":"60408","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.000738,37.839746],[-88.997556,37.784788],[-88.835664,37.859744],[-88.835239,37.86296],[-89.000738,37.839746]]]},"properties":{"id":"62951","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.090847,42.273034],[-89.098119,42.273152],[-89.095342,42.257092],[-89.091855,42.269999],[-89.090847,42.273034]]],[[[-89.193472,42.29112],[-89.099585,42.274554],[-89.098249,42.411725],[-89.184982,42.377644],[-89.223749,42.351218],[-89.223755,42.348727],[-89.193472,42.29112]]]]},"properties":{"id":"61101","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.979716,42.239909],[-88.97943,42.226842],[-88.970291,42.250281],[-88.979716,42.239909]]]},"properties":{"id":"61112","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.421919,39.933074],[-91.357983,39.9645],[-91.358661,39.90271],[-91.427735,39.908755],[-91.365125,39.758723],[-91.312986,39.758074],[-91.274055,39.874972],[-91.147273,39.947852],[-91.166796,39.97684],[-91.286069,40.022324],[-91.318939,40.066526],[-91.488131,40.024576],[-91.421919,39.933074]]]},"properties":{"id":"62305","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.988724,40.193981],[-90.893092,40.162952],[-90.798827,40.222837],[-90.978144,40.260055],[-90.988724,40.193981]]]},"properties":{"id":"62311","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.242283,39.77627],[-91.165843,39.837013],[-91.274055,39.874972],[-91.312986,39.758074],[-91.242283,39.77627]]]},"properties":{"id":"62360","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.256794,40.284739],[-91.231884,40.358098],[-91.226931,40.372748],[-91.258084,40.395033],[-91.376736,40.390939],[-91.510518,40.127326],[-91.418498,40.199623],[-91.264406,40.269884],[-91.256794,40.284739]]]},"properties":{"id":"62379","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.457983,37.241878],[-89.438354,37.161403],[-89.41742,37.16191],[-89.374297,37.218728],[-89.343653,37.284947],[-89.457983,37.241878]]]},"properties":{"id":"62990","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.374297,37.218728],[-89.289415,37.13207],[-89.270419,37.122915],[-89.255923,37.188113],[-89.255039,37.232136],[-89.230808,37.331867],[-89.251668,37.335275],[-89.321689,37.306242],[-89.343653,37.284947],[-89.374297,37.218728]]]},"properties":{"id":"62988","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.705638,42.182511],[-88.739788,42.160834],[-88.769099,42.160644],[-88.698942,42.049648],[-88.601635,42.085255],[-88.588657,42.15359],[-88.705638,42.182511]]]},"properties":{"id":"60135","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.760391,39.858883],[-90.571754,39.839326],[-90.583534,39.87675],[-90.537665,39.919545],[-90.513747,39.987891],[-90.567514,39.983815],[-90.582544,39.979858],[-90.760391,39.858883]]]},"properties":{"id":"62378","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.627058,41.467032],[-89.516072,41.321017],[-89.399688,41.233714],[-89.395536,41.233704],[-89.38536,41.2771],[-89.393131,41.304409],[-89.375064,41.286708],[-89.35449,41.288777],[-89.335324,41.301916],[-89.28245,41.333259],[-89.281518,41.349653],[-89.277763,41.371343],[-89.277554,41.407807],[-89.325783,41.422451],[-89.315888,41.451628],[-89.3444,41.512288],[-89.500726,41.480443],[-89.627058,41.467032]],[[-89.398224,41.43657],[-89.393555,41.429375],[-89.403022,41.42947],[-89.398224,41.43657]]]},"properties":{"id":"61356","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.373537,41.553753],[-89.381686,41.599328],[-89.381899,41.613813],[-89.496889,41.638512],[-89.516381,41.618781],[-89.500726,41.480443],[-89.3444,41.512288],[-89.362309,41.524491],[-89.373537,41.553753]],[[-89.461958,41.50396],[-89.451768,41.503899],[-89.451968,41.494847],[-89.466438,41.494851],[-89.461958,41.50396]]]},"properties":{"id":"61349","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.177722,41.355459],[-89.164214,41.340337],[-89.164614,41.364632],[-89.182489,41.364398],[-89.177722,41.355459]]]},"properties":{"id":"61320","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.050005,41.282684],[-89.868113,41.178478],[-89.830084,41.228687],[-89.856762,41.328538],[-89.905034,41.346745],[-90.001439,41.340657],[-90.03035,41.323057],[-90.050005,41.282684]]]},"properties":{"id":"61443","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.461958,41.50396],[-89.466438,41.494851],[-89.451968,41.494847],[-89.451768,41.503899],[-89.461958,41.50396]]]},"properties":{"id":"61328","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.676835,39.143442],[-90.715977,39.191468],[-90.676326,38.967249],[-90.62629,38.97507],[-90.604965,39.021219],[-90.676835,39.143442]]]},"properties":{"id":"62006","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.978437,41.931187],[-89.864544,41.888153],[-89.78319,41.930302],[-89.80214,42.018634],[-89.860007,42.058393],[-89.978734,42.000405],[-89.978437,41.931187]]]},"properties":{"id":"61014","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.674934,42.208344],[-89.723192,42.246105],[-89.824855,42.197276],[-89.685988,42.090314],[-89.628808,42.170785],[-89.674934,42.208344]]]},"properties":{"id":"61078","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.768477,42.313722],[-89.857796,42.311945],[-89.921048,42.283887],[-89.939435,42.226034],[-89.919146,42.18012],[-89.824855,42.197276],[-89.723192,42.246105],[-89.768477,42.313722]]]},"properties":{"id":"61062","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.335759,39.988242],[-90.273357,40.054498],[-90.287302,40.062103],[-90.444092,40.016231],[-90.513747,39.987891],[-90.537665,39.919545],[-90.350522,39.96176],[-90.335759,39.988242]]]},"properties":{"id":"62618","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.583534,39.87675],[-90.448456,39.835957],[-90.410499,39.842352],[-90.315804,39.809326],[-90.251639,39.873397],[-90.341364,39.961569],[-90.350522,39.96176],[-90.537665,39.919545],[-90.583534,39.87675]]]},"properties":{"id":"62611","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.460418,40.281935],[-88.346571,40.270962],[-88.313724,40.354673],[-88.460302,40.31867],[-88.479502,40.281799],[-88.460418,40.281935]]]},"properties":{"id":"61843","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.194108,40.370546],[-88.25238,40.355219],[-88.270348,40.267431],[-88.251094,40.253016],[-88.078507,40.268947],[-88.040616,40.356487],[-88.194108,40.370546]]]},"properties":{"id":"61866","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.35787,39.879419],[-88.198352,39.879518],[-88.176871,39.879744],[-88.317058,39.937253],[-88.35787,39.879419]]]},"properties":{"id":"61863","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.01096,39.478917],[-89.137915,39.494817],[-89.278576,39.429082],[-89.278665,39.407021],[-89.250889,39.377917],[-89.157937,39.380655],[-89.195442,39.305478],[-89.160824,39.290669],[-89.044146,39.288735],[-89.016701,39.306054],[-89.01096,39.478917]]]},"properties":{"id":"62557","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.321355,39.771955],[-89.345857,39.720614],[-89.270317,39.714546],[-89.126223,39.789337],[-89.141817,39.80091],[-89.217523,39.813224],[-89.298421,39.786618],[-89.321355,39.771955]]]},"properties":{"id":"62547","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.457697,39.290426],[-89.474018,39.275642],[-89.473929,39.261066],[-89.434238,39.261159],[-89.288088,39.20661],[-89.2509,39.188301],[-89.241903,39.199292],[-89.168443,39.246914],[-89.160824,39.290669],[-89.195442,39.305478],[-89.223534,39.337843],[-89.224047,39.348828],[-89.250889,39.377917],[-89.278665,39.407021],[-89.438954,39.319669],[-89.457697,39.290426]]]},"properties":{"id":"62075","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.983145,39.583538],[-87.960179,39.481309],[-87.94138,39.445295],[-87.872226,39.482854],[-87.880989,39.614115],[-87.891309,39.64316],[-87.94653,39.646776],[-87.983145,39.583538]]]},"properties":{"id":"61933","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.683618,38.767519],[-88.697518,38.693366],[-88.699121,38.487664],[-88.650989,38.475631],[-88.574734,38.548877],[-88.584533,38.607427],[-88.583341,38.70966],[-88.683618,38.767519]]]},"properties":{"id":"62899","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.246095,39.097744],[-88.323893,38.945357],[-88.33629,38.879873],[-88.286362,38.833982],[-88.227974,38.81797],[-88.114494,38.847895],[-88.037283,38.966726],[-88.048336,39.08279],[-88.095249,39.103995],[-88.20942,39.107993],[-88.241487,39.112449],[-88.246095,39.097744]]]},"properties":{"id":"62448","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.498135,38.565974],[-89.571503,38.582755],[-89.642814,38.575342],[-89.644117,38.553499],[-89.624977,38.550329],[-89.573728,38.487301],[-89.498135,38.565974]]]},"properties":{"id":"62245","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.485326,38.56573],[-89.480774,38.696494],[-89.528308,38.727002],[-89.587255,38.702518],[-89.571503,38.582755],[-89.498135,38.565974],[-89.485326,38.56573]]]},"properties":{"id":"62230","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.259229,38.538639],[-89.261252,38.544988],[-89.273096,38.544937],[-89.270867,38.535824],[-89.259229,38.538639]]]},"properties":{"id":"62250","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.215236,38.740253],[-89.13869,38.701855],[-89.100134,38.691231],[-89.008492,38.70927],[-89.010529,38.735437],[-89.010577,38.788934],[-89.165954,38.825486],[-89.195336,38.825813],[-89.231076,38.82543],[-89.250071,38.742015],[-89.215236,38.740253]]]},"properties":{"id":"62875","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.680451,38.47582],[-89.667404,38.480456],[-89.667469,38.495101],[-89.685993,38.494953],[-89.680451,38.47582]]]},"properties":{"id":"62266","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.471039,39.520409],[-88.447467,39.388238],[-88.4015,39.374307],[-88.35099,39.396049],[-88.266432,39.459711],[-88.266414,39.565031],[-88.43592,39.563499],[-88.471039,39.520409]]]},"properties":{"id":"61938","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.550735,39.292565],[-88.377951,39.251005],[-88.341387,39.301777],[-88.4015,39.374307],[-88.447467,39.388238],[-88.490243,39.38776],[-88.517862,39.338538],[-88.550735,39.292565]]]},"properties":{"id":"62447","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.778159,41.674082],[-87.809418,41.684319],[-87.816465,41.681701],[-87.8201,41.649347],[-87.766511,41.646691],[-87.758108,41.661386],[-87.778159,41.674082]]]},"properties":{"id":"60463","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.795533,42.055227],[-87.769884,42.062544],[-87.758984,42.064991],[-87.771379,42.08762],[-87.798994,42.105376],[-87.814409,42.094433],[-87.829077,42.077771],[-87.867108,42.087335],[-87.888067,42.078509],[-87.888066,42.074454],[-87.840318,42.056843],[-87.825579,42.05488],[-87.795533,42.055227]]]},"properties":{"id":"60025","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.840318,42.056843],[-87.846618,42.041184],[-87.816327,42.018749],[-87.790766,42.000531],[-87.767673,42.008423],[-87.779387,42.023585],[-87.825579,42.05488],[-87.840318,42.056843]]]},"properties":{"id":"60714","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.788608,41.968861],[-87.836699,41.95936],[-87.786812,41.960673],[-87.788608,41.968861]]]},"properties":{"id":"60706","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.931454,41.643535],[-87.909809,41.573338],[-87.85728,41.557688],[-87.847862,41.598938],[-87.86409,41.644922],[-87.912395,41.657126],[-87.931454,41.643535]]]},"properties":{"id":"60467","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.811086,41.709296],[-87.846842,41.717964],[-87.835693,41.684236],[-87.816465,41.681701],[-87.809418,41.684319],[-87.797857,41.699926],[-87.798053,41.70441],[-87.811086,41.709296]]]},"properties":{"id":"60465","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.883778,41.907558],[-87.878805,41.903445],[-87.887282,41.900164],[-87.903362,41.89773],[-87.878485,41.892744],[-87.859764,41.890703],[-87.834204,41.898624],[-87.824511,41.908562],[-87.825457,41.911015],[-87.852523,41.919347],[-87.864639,41.91507],[-87.883778,41.907558]]]},"properties":{"id":"60160","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.058681,42.077177],[-88.033094,42.063385],[-88.028752,42.104262],[-88.062986,42.13729],[-88.092404,42.084606],[-88.078711,42.067001],[-88.062284,42.078023],[-88.058681,42.077177]]]},"properties":{"id":"60067","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.062986,42.13729],[-88.028752,42.104262],[-88.010572,42.095573],[-88.004678,42.093787],[-88.016993,42.153704],[-88.050222,42.16096],[-88.062986,42.13729]]]},"properties":{"id":"60074","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.582157,41.577646],[-87.63486,41.577149],[-87.626648,41.560717],[-87.583692,41.557472],[-87.582157,41.577646]]]},"properties":{"id":"60476","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.624403,41.622638],[-87.636384,41.582342],[-87.636395,41.574303],[-87.63486,41.577149],[-87.582157,41.577646],[-87.563724,41.5865],[-87.58058,41.609346],[-87.624403,41.622638]]]},"properties":{"id":"60473","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.681321,41.677283],[-87.682511,41.713667],[-87.721125,41.71317],[-87.73973,41.690891],[-87.695354,41.677048],[-87.681321,41.677283]]]},"properties":{"id":"60655","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.56301,41.781232],[-87.587129,41.766051],[-87.585824,41.751506],[-87.523661,41.759907],[-87.56301,41.781232]]]},"properties":{"id":"60649","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.775113,41.894265],[-87.774675,41.880152],[-87.774139,41.865459],[-87.739932,41.86596],[-87.741203,41.895093],[-87.775113,41.894265]]]},"properties":{"id":"60644","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.678233,41.961466],[-87.727828,41.960947],[-87.726382,41.931799],[-87.678135,41.932285],[-87.677525,41.932745],[-87.677931,41.9464],[-87.678233,41.961466]]]},"properties":{"id":"60618","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.628004,41.887506],[-87.616444,41.882335],[-87.609247,41.882334],[-87.621952,41.904232],[-87.628222,41.89752],[-87.628004,41.887506]]]},"properties":{"id":"60611","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.648272,41.91092],[-87.669703,41.917902],[-87.667069,41.888852],[-87.647622,41.889049],[-87.647745,41.896399],[-87.648272,41.91092]]]},"properties":{"id":"60642","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.633824,41.881946],[-87.624296,41.882119],[-87.633895,41.884469],[-87.633824,41.881946]]]},"properties":{"id":"60602","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.679102,41.990687],[-87.674609,41.983357],[-87.648855,41.984231],[-87.650803,41.998485],[-87.678943,41.997919],[-87.680136,41.997895],[-87.679102,41.990687]]]},"properties":{"id":"60660","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.779048,41.865392],[-87.781972,41.80436],[-87.743391,41.816511],[-87.73881,41.83292],[-87.739932,41.86596],[-87.774139,41.865459],[-87.779048,41.865392]]]},"properties":{"id":"60804","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.950018,42.019994],[-87.943377,42.022671],[-87.959935,42.02516],[-87.950018,42.019994]]],[[[-87.983854,42.040517],[-87.96597,42.029644],[-87.957565,42.072792],[-88.004678,42.093787],[-88.010572,42.095573],[-87.995715,42.046052],[-87.983854,42.040517]]]]},"properties":{"id":"60005","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.577339,41.469992],[-87.654734,41.486071],[-87.66172,41.467511],[-87.577339,41.469992]]]},"properties":{"id":"60475","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.721436,41.734862],[-87.741067,41.734524],[-87.721654,41.727788],[-87.721436,41.734862]]]},"properties":{"id":"60456","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.120448,41.960401],[-88.12358,41.960323],[-88.123656,41.95297],[-88.120448,41.960401]]],[[[-88.046303,41.99189],[-88.051013,41.991701],[-88.051089,41.987257],[-88.046303,41.99189]]],[[[-88.120448,41.960401],[-88.071043,41.96659],[-88.055079,41.991537],[-88.060581,41.999514],[-88.122238,41.989112],[-88.125303,41.963673],[-88.120448,41.960401]]]]},"properties":{"id":"60172","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.470934,39.185978],[-88.433812,39.17124],[-88.43356,39.178644],[-88.470939,39.193246],[-88.470934,39.185978]]],[[[-88.470906,39.215029],[-88.396426,39.183252],[-88.37794,39.229278],[-88.377951,39.251005],[-88.550735,39.292565],[-88.568003,39.229874],[-88.470906,39.215029]]]]},"properties":{"id":"62462","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.698925,41.937621],[-88.665403,41.917858],[-88.676014,41.933268],[-88.698925,41.937621]]]},"properties":{"id":"60112","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.6795,41.718936],[-88.67376,41.58681],[-88.619489,41.576805],[-88.58359,41.62193],[-88.603488,41.713584],[-88.635938,41.719366],[-88.6795,41.718936]]]},"properties":{"id":"60548","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.579637,42.356657],[-88.666953,42.327816],[-88.705638,42.182511],[-88.588657,42.15359],[-88.517986,42.153774],[-88.510849,42.181774],[-88.551265,42.266647],[-88.579637,42.356657]]]},"properties":{"id":"60152","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.941304,41.862921],[-89.009117,41.745761],[-88.941508,41.760774],[-88.905576,41.848657],[-88.941304,41.862921]]]},"properties":{"id":"60530","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.104866,40.00555],[-89.011846,39.962905],[-88.918421,39.965122],[-88.898521,39.994885],[-88.812372,40.055148],[-88.80321,40.069973],[-89.057452,40.071622],[-89.144611,40.039059],[-89.144372,40.020114],[-89.104866,40.00555]]]},"properties":{"id":"61756","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.147685,40.216252],[-89.146279,40.151121],[-89.047833,40.211812],[-89.029222,40.253365],[-89.07812,40.28249],[-89.148102,40.245116],[-89.147685,40.216252]]]},"properties":{"id":"61778","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.074996,39.812994],[-88.084445,39.792065],[-88.065576,39.792128],[-88.074996,39.812994]]]},"properties":{"id":"61941","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.206005,41.7472],[-88.167253,41.747618],[-88.063968,41.749924],[-88.086288,41.763874],[-88.100359,41.793113],[-88.195986,41.778463],[-88.206005,41.7472]]]},"properties":{"id":"60540","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.241106,41.775307],[-88.242251,41.779125],[-88.2451,41.777355],[-88.241106,41.775307]]]},"properties":{"id":"60519","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.069009,41.728134],[-88.128012,41.711151],[-88.111388,41.675238],[-88.052838,41.680834],[-88.029327,41.695531],[-88.030463,41.72666],[-88.030561,41.72893],[-88.069009,41.728134]]]},"properties":{"id":"60440","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.167253,41.747618],[-88.206005,41.7472],[-88.235658,41.724929],[-88.235347,41.715517],[-88.165995,41.681447],[-88.160101,41.698222],[-88.167253,41.747618]]]},"properties":{"id":"60564","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.208324,41.944118],[-88.262853,41.978187],[-88.27595,41.969471],[-88.262975,41.937027],[-88.208324,41.944118]]]},"properties":{"id":"60184","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.773095,39.733664],[-87.532736,39.673944],[-87.533228,39.883],[-87.710289,39.880907],[-87.77706,39.88087],[-87.809092,39.83986],[-87.812293,39.744029],[-87.773095,39.733664]]]},"properties":{"id":"61924","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.00513,38.416699],[-87.982513,38.335343],[-87.954798,38.335308],[-87.908904,38.379157],[-87.916264,38.385757],[-87.945128,38.442074],[-88.00513,38.416699]]]},"properties":{"id":"62818","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.539157,38.973052],[-88.503846,39.009037],[-88.626143,39.033643],[-88.61763,39.010852],[-88.539157,38.973052]]]},"properties":{"id":"62473","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.955641,38.914646],[-88.953016,38.867827],[-88.93962,38.824109],[-88.826373,38.824434],[-88.929411,38.918913],[-88.955641,38.914646]]]},"properties":{"id":"62880","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.363019,39.111501],[-89.299019,39.028218],[-89.245746,39.029948],[-89.235625,39.068073],[-89.2509,39.188301],[-89.288088,39.20661],[-89.325953,39.188159],[-89.357292,39.122815],[-89.363019,39.111501]]]},"properties":{"id":"62032","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.28495,40.97961],[-88.28293,40.9215],[-88.185388,40.85028],[-88.128072,40.851336],[-88.129512,40.898249],[-88.130722,40.939228],[-88.28495,40.97961]]]},"properties":{"id":"60946","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.006686,40.659953],[-88.123975,40.720003],[-88.219855,40.733195],[-88.23788,40.70404],[-88.119729,40.618922],[-88.006686,40.659953]]]},"properties":{"id":"60968","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.924119,38.059874],[-88.880398,38.059433],[-88.860873,38.126419],[-88.919421,38.125652],[-88.924119,38.059874]]]},"properties":{"id":"62897","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.053551,38.008879],[-89.067511,38.003114],[-89.065159,37.99426],[-89.034994,37.979461],[-89.028449,37.979698],[-89.002362,37.987389],[-89.011579,37.917966],[-88.988594,37.917868],[-88.960819,37.92804],[-88.844976,37.950677],[-88.834336,37.965214],[-88.760238,38.009902],[-88.781057,38.037902],[-88.880398,38.059433],[-88.924119,38.059874],[-89.012129,38.062257],[-89.053681,38.037668],[-89.053566,38.016232],[-89.034881,38.023682],[-89.035705,38.009134],[-89.053551,38.008879]]]},"properties":{"id":"62812","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.002362,37.987389],[-89.028449,37.979698],[-88.999384,37.978169],[-89.002362,37.987389]]]},"properties":{"id":"62819","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.058711,37.906639],[-89.150755,37.945185],[-89.151176,37.861999],[-89.105135,37.862193],[-89.087861,37.862139],[-89.062696,37.892498],[-89.058711,37.906639]]]},"properties":{"id":"62983","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.319971,40.287061],[-90.450636,40.254352],[-90.423124,40.188628],[-90.216958,40.183985],[-90.200625,40.183947],[-90.219396,40.278947],[-90.319971,40.287061]]]},"properties":{"id":"61501","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.39189,40.327451],[-90.49754,40.336206],[-90.525446,40.336666],[-90.517764,40.266595],[-90.450636,40.254352],[-90.319971,40.287061],[-90.39189,40.327451]]]},"properties":{"id":"61484","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.329711,40.585931],[-90.274676,40.542975],[-90.215159,40.560454],[-90.214617,40.596975],[-90.21467,40.647438],[-90.291008,40.65252],[-90.329711,40.585931]]]},"properties":{"id":"61431","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.102097,40.625946],[-90.036236,40.629342],[-90.024252,40.625709],[-89.940292,40.625411],[-89.948421,40.654449],[-90.005078,40.745321],[-90.100521,40.757093],[-90.100714,40.713072],[-90.102097,40.625946]]]},"properties":{"id":"61531","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.170874,40.545619],[-90.161365,40.545696],[-90.16087,40.567645],[-90.180512,40.560359],[-90.170874,40.545619]]]},"properties":{"id":"61433","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.410537,37.748163],[-88.346195,37.826281],[-88.374487,37.87832],[-88.414869,37.907535],[-88.522834,37.906784],[-88.516714,37.80931],[-88.410537,37.748163]]]},"properties":{"id":"62930","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.497037,39.470201],[-90.50207,39.361415],[-90.352073,39.387051],[-90.322628,39.390606],[-90.320464,39.403762],[-90.264613,39.441504],[-90.497037,39.470201]]]},"properties":{"id":"62092","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.243925,41.194439],[-88.283706,41.206281],[-88.24337,41.179448],[-88.243925,41.194439]]]},"properties":{"id":"60474","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.20799,41.47174],[-88.297819,41.41796],[-88.259965,41.393101],[-88.186578,41.376996],[-88.175076,41.40362],[-88.163671,41.46435],[-88.197353,41.474844],[-88.20799,41.47174]]]},"properties":{"id":"60410","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.374429,37.995054],[-88.484853,37.994738],[-88.49443,37.966206],[-88.655994,37.951339],[-88.623411,37.929835],[-88.568144,37.906764],[-88.522834,37.906784],[-88.414869,37.907535],[-88.374429,37.995054]]]},"properties":{"id":"62817","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.675319,37.745323],[-88.596513,37.818699],[-88.568144,37.906764],[-88.623411,37.929835],[-88.70708,37.784351],[-88.675319,37.745323]]]},"properties":{"id":"62935","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.377135,40.199142],[-91.354567,40.198763],[-91.28764,40.197785],[-91.266777,40.214483],[-91.264406,40.269884],[-91.418498,40.199623],[-91.377135,40.199142]]]},"properties":{"id":"62373","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.314703,37.438927],[-88.390216,37.437907],[-88.396465,37.421236],[-88.314703,37.438927]]]},"properties":{"id":"62982","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.872631,40.784145],[-90.808531,40.636373],[-90.789502,40.664236],[-90.789594,40.686399],[-90.831356,40.786231],[-90.872631,40.784145]],[[-90.831802,40.696161],[-90.846175,40.696458],[-90.8462,40.701973],[-90.831802,40.696161]]]},"properties":{"id":"61460","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.445018,41.267983],[-90.396998,41.252673],[-90.380418,41.252346],[-90.32238,41.209802],[-90.267215,41.244179],[-90.280601,41.324206],[-90.319486,41.324295],[-90.434089,41.315349],[-90.445018,41.267983]]]},"properties":{"id":"61262","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.32238,41.209802],[-90.331217,41.151354],[-90.323511,41.122842],[-90.243152,41.151894],[-90.199353,41.206017],[-90.267215,41.244179],[-90.32238,41.209802]]]},"properties":{"id":"61490","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.124424,41.203246],[-90.124154,41.19396],[-90.110399,41.194136],[-90.115515,41.205044],[-90.124424,41.203246]]]},"properties":{"id":"61419","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.063279,41.080708],[-90.133994,41.204785],[-90.199353,41.206017],[-90.243152,41.151894],[-90.155401,41.048315],[-90.063279,41.080708]]]},"properties":{"id":"61414","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.123975,40.720003],[-88.006686,40.659953],[-87.958917,40.615053],[-87.867428,40.630458],[-87.840814,40.697547],[-87.881222,40.740511],[-88.125952,40.778095],[-88.123975,40.720003]]]},"properties":{"id":"60955","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.806703,40.581218],[-87.790316,40.559928],[-87.785009,40.581588],[-87.806703,40.581218]]]},"properties":{"id":"60926","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.006686,40.659953],[-88.119729,40.618922],[-88.118248,40.560802],[-87.975193,40.557328],[-87.958917,40.615053],[-88.006686,40.659953]]]},"properties":{"id":"60918","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.312889,38.082774],[-89.354622,37.951939],[-89.282182,37.937319],[-89.177597,37.950311],[-89.133183,38.052216],[-89.312889,38.082774]]]},"properties":{"id":"62832","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.140881,38.358438],[-89.040426,38.271404],[-89.02271,38.300073],[-89.034262,38.392552],[-89.042207,38.408832],[-89.074475,38.431031],[-89.145423,38.401666],[-89.140881,38.358438]]]},"properties":{"id":"62898","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.796992,38.126236],[-88.815074,38.170475],[-88.958565,38.125299],[-88.919421,38.125652],[-88.860873,38.126419],[-88.796992,38.126236]]]},"properties":{"id":"62846","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.753757,38.275943],[-88.793459,38.3206],[-88.869964,38.229366],[-88.832919,38.21438],[-88.753757,38.275943]]]},"properties":{"id":"62872","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.2578,38.349835],[-89.306412,38.258447],[-89.204731,38.281331],[-89.182711,38.286982],[-89.147416,38.212896],[-89.105563,38.22807],[-89.040426,38.271404],[-89.140881,38.358438],[-89.2578,38.349835]]]},"properties":{"id":"62808","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.2578,38.349835],[-89.140881,38.358438],[-89.145423,38.401666],[-89.14527,38.423196],[-89.181853,38.446405],[-89.204888,38.447116],[-89.32258,38.390894],[-89.2578,38.349835]]]},"properties":{"id":"62877","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.296306,42.371999],[-90.215086,42.2104],[-90.07615,42.196865],[-90.029336,42.225668],[-90.132273,42.402856],[-90.162575,42.404577],[-90.254704,42.41534],[-90.296306,42.371999]]]},"properties":{"id":"61028","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.335507,42.13256],[-88.257697,42.1488],[-88.245121,42.186762],[-88.247501,42.191107],[-88.286488,42.190121],[-88.381121,42.16984],[-88.369402,42.154056],[-88.335507,42.13256]]]},"properties":{"id":"60102","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.393866,42.08835],[-88.477872,41.982765],[-88.47787,41.971894],[-88.357795,41.98533],[-88.340794,41.990763],[-88.335414,42.083564],[-88.352681,42.103342],[-88.393866,42.08835]]]},"properties":{"id":"60124","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.527899,41.804472],[-88.489875,41.722029],[-88.48567,41.71732],[-88.443878,41.721786],[-88.395011,41.794643],[-88.397649,41.827985],[-88.413453,41.831017],[-88.527899,41.804472]]]},"properties":{"id":"60554","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.895606,41.147731],[-87.8647,41.135715],[-87.836474,41.147898],[-87.895606,41.147731]]]},"properties":{"id":"60915","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.991248,41.205331],[-87.745612,41.207464],[-87.781583,41.291257],[-87.919968,41.30854],[-87.976359,41.293012],[-88.011891,41.208191],[-87.991248,41.205331]]]},"properties":{"id":"60950","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.781583,41.291257],[-87.745612,41.207464],[-87.720975,41.192906],[-87.526508,41.20914],[-87.526731,41.298052],[-87.66938,41.296778],[-87.781583,41.291257]]]},"properties":{"id":"60940","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.452434,41.691259],[-88.547975,41.624103],[-88.54948,41.613647],[-88.54391,41.59738],[-88.4042,41.52697],[-88.31448,41.57797],[-88.31569,41.60683],[-88.39176,41.70742],[-88.452434,41.691259]]]},"properties":{"id":"60560","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.985367,40.995847],[-89.906413,41.142063],[-90.039896,41.070553],[-90.013951,41.025557],[-89.985367,40.995847]]]},"properties":{"id":"61449","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.297943,40.940514],[-90.317467,40.93074],[-90.296427,40.922084],[-90.297943,40.940514]]]},"properties":{"id":"61430","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.971971,42.480197],[-87.971581,42.494843],[-88.199528,42.49457],[-88.199217,42.473474],[-88.140184,42.41622],[-88.003479,42.44832],[-87.971971,42.480197]]]},"properties":{"id":"60002","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.928255,42.344857],[-87.901987,42.311237],[-87.892415,42.309463],[-87.815742,42.356738],[-87.799075,42.38461],[-87.884788,42.385157],[-87.928255,42.344857]]]},"properties":{"id":"60085","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.789522,42.152473],[-87.750334,42.15276],[-87.799034,42.224047],[-87.851868,42.203724],[-87.820893,42.152537],[-87.789522,42.152473]],[[-87.811314,42.215356],[-87.808344,42.196233],[-87.817967,42.196224],[-87.817872,42.210681],[-87.811314,42.215356]]]},"properties":{"id":"60035","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.049525,42.385918],[-87.997889,42.366546],[-87.989028,42.400189],[-88.003479,42.44832],[-88.140184,42.41622],[-88.140227,42.405997],[-88.101757,42.397255],[-88.049525,42.385918]]]},"properties":{"id":"60046","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.220469,42.267677],[-88.208305,42.251409],[-88.19874,42.255642],[-88.198721,42.2582],[-88.220469,42.267677]]],[[[-88.290289,42.382185],[-88.226791,42.27973],[-88.179313,42.29859],[-88.160664,42.293794],[-88.172662,42.328929],[-88.194334,42.374716],[-88.198787,42.375881],[-88.208773,42.398542],[-88.20674,42.410571],[-88.276727,42.419346],[-88.290289,42.382185]]]]},"properties":{"id":"60051","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.951486,41.227646],[-89.106318,41.235962],[-89.162686,41.207079],[-89.16262,41.192312],[-89.009195,41.148649],[-88.931743,41.207264],[-88.940069,41.218756],[-88.951486,41.227646]],[[-88.980571,41.184943],[-88.998054,41.184993],[-88.998106,41.192595],[-88.981083,41.192634],[-88.980571,41.184943]]]},"properties":{"id":"61370","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.361437,41.794435],[-89.49684,41.707626],[-89.496889,41.638512],[-89.381899,41.613813],[-89.209575,41.671173],[-89.241207,41.758069],[-89.361437,41.794435]],[[-89.279565,41.747983],[-89.275551,41.747309],[-89.275592,41.74629],[-89.279565,41.747983]]]},"properties":{"id":"61310","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.601777,41.769438],[-89.632379,41.671561],[-89.631652,41.603134],[-89.516381,41.618781],[-89.496889,41.638512],[-89.49684,41.707626],[-89.601777,41.769438]]]},"properties":{"id":"61042","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.009117,41.745761],[-89.053111,41.716155],[-89.052058,41.641253],[-88.940191,41.709815],[-88.941508,41.760774],[-89.009117,41.745761]]]},"properties":{"id":"61353","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.35234,40.70275],[-88.33623,40.81779],[-88.336669,40.830527],[-88.451112,40.830981],[-88.42854,40.67272],[-88.35234,40.70275]]]},"properties":{"id":"61741","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.794947,40.717062],[-88.651175,40.669345],[-88.614362,40.669491],[-88.621282,40.771685],[-88.75725,40.82865],[-88.795362,40.80608],[-88.794947,40.717062]]]},"properties":{"id":"61726","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.64331,41.03363],[-88.56598,41.085131],[-88.569726,41.108345],[-88.64296,41.10776],[-88.77536,41.098593],[-88.64331,41.03363]]]},"properties":{"id":"61313","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.91117,41.03043],[-88.834041,41.01715],[-88.83282,41.06876],[-88.91117,41.03043]]]},"properties":{"id":"61311","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.486083,40.202516],[-89.550942,40.102167],[-89.442219,40.07407],[-89.376221,40.01948],[-89.253741,40.058971],[-89.253599,40.076871],[-89.222638,40.205538],[-89.27868,40.220586],[-89.305626,40.200979],[-89.311653,40.222112],[-89.296918,40.226698],[-89.375012,40.233622],[-89.486083,40.202516]]]},"properties":{"id":"62656","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.531305,40.00557],[-89.442787,39.933055],[-89.404969,39.932733],[-89.394793,39.939899],[-89.362509,39.964059],[-89.3625,39.968465],[-89.376221,40.01948],[-89.442219,40.07407],[-89.571558,40.039395],[-89.531305,40.00557]]]},"properties":{"id":"62634","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.808531,40.636373],[-90.790088,40.537852],[-90.713323,40.543809],[-90.69223,40.632141],[-90.789502,40.664236],[-90.808531,40.636373]]]},"properties":{"id":"61475","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.062114,40.576054],[-89.052423,40.65777],[-89.100254,40.662891],[-89.191224,40.661545],[-89.19337,40.595694],[-89.117364,40.531433],[-89.062114,40.576054]]]},"properties":{"id":"61725","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.956129,39.964212],[-88.955643,39.934979],[-88.993,39.934328],[-88.974072,39.907737],[-88.947774,39.913348],[-88.956129,39.964212]]]},"properties":{"id":"62535","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.587859,39.261626],[-89.738737,39.249387],[-89.795402,39.196996],[-89.788711,39.144996],[-89.680184,39.051071],[-89.585916,39.098407],[-89.575852,39.181306],[-89.587859,39.261626]]]},"properties":{"id":"62056","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.605637,39.370669],[-89.703001,39.310322],[-89.738737,39.249387],[-89.587859,39.261626],[-89.474018,39.275642],[-89.457697,39.290426],[-89.605637,39.370669]]]},"properties":{"id":"62560","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.075855,38.779519],[-90.038604,38.745948],[-89.90514,38.759095],[-89.848099,38.756395],[-89.817394,38.762412],[-89.827132,38.815468],[-89.815125,38.874266],[-89.84307,38.881802],[-89.852574,38.889082],[-89.934401,38.938044],[-89.973948,38.890265],[-90.020462,38.866048],[-90.038361,38.843449],[-90.053888,38.803916],[-90.067082,38.789267],[-90.075855,38.779519]]]},"properties":{"id":"62025","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.053785,38.878104],[-90.065167,38.897134],[-90.107879,38.889434],[-90.131907,38.857948],[-90.117707,38.805748],[-90.111811,38.80567],[-90.103457,38.852533],[-90.053785,38.878104]]]},"properties":{"id":"62024","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.23167,40.976823],[-89.184758,40.926495],[-89.12446,40.94123],[-89.066592,41.009358],[-89.14354,41.04428],[-89.234167,40.984378],[-89.23167,40.976823]]]},"properties":{"id":"61369","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.381481,41.080164],[-89.57279,41.07568],[-89.61099,41.03189],[-89.61053,40.98807],[-89.57257,40.973281],[-89.447693,40.973246],[-89.381481,41.080164]]]},"properties":{"id":"61565","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.638565,41.06135],[-89.696564,40.973793],[-89.677212,40.973781],[-89.61053,40.98807],[-89.61099,41.03189],[-89.631568,41.0758],[-89.638565,41.06135]]]},"properties":{"id":"61479","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.828163,40.382603],[-89.752869,40.318556],[-89.715175,40.3334],[-89.726222,40.479084],[-89.675256,40.554054],[-89.688845,40.558512],[-89.741322,40.550806],[-89.784236,40.5493],[-89.872463,40.513127],[-89.905582,40.451506],[-89.985195,40.392107],[-89.954811,40.392239],[-89.828163,40.382603]]]},"properties":{"id":"61546","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.810008,40.288624],[-89.904566,40.296858],[-89.942159,40.27548],[-89.973174,40.217068],[-89.885844,40.134535],[-89.828971,40.125679],[-89.810008,40.288624]]]},"properties":{"id":"62633","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.858541,37.216354],[-88.84006,37.198581],[-88.676274,37.135027],[-88.602254,37.247787],[-88.688452,37.319569],[-88.753614,37.301488],[-88.843485,37.249641],[-88.869399,37.238647],[-88.858541,37.216354]]]},"properties":{"id":"62960","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.537834,41.460733],[-90.618537,41.485032],[-90.750142,41.449632],[-90.742464,41.439261],[-90.714982,41.439676],[-90.66549,41.372355],[-90.627938,41.328242],[-90.608727,41.313475],[-90.584412,41.313117],[-90.492055,41.340734],[-90.491694,41.36963],[-90.485876,41.442579],[-90.537834,41.460733]]]},"properties":{"id":"61264","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.627293,41.132155],[-90.745355,41.06833],[-90.667849,41.060972],[-90.570679,41.036453],[-90.571046,41.021891],[-90.552375,41.006377],[-90.516972,40.976715],[-90.488426,40.976569],[-90.477745,41.063705],[-90.541905,41.151287],[-90.627293,41.132155]]]},"properties":{"id":"61412","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.608727,41.313475],[-90.590395,41.285365],[-90.584412,41.313117],[-90.608727,41.313475]]]},"properties":{"id":"61276","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.257285,38.387109],[-90.257001,38.224235],[-90.119125,38.208859],[-89.998757,38.305201],[-89.995997,38.305169],[-89.99602,38.307074],[-89.996012,38.308355],[-90.02942,38.352198],[-90.045303,38.37447],[-90.102175,38.396895],[-90.257285,38.387109]]]},"properties":{"id":"62298","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.462529,39.057168],[-89.363019,39.111501],[-89.357292,39.122815],[-89.483594,39.224585],[-89.575852,39.181306],[-89.585916,39.098407],[-89.52618,39.037252],[-89.519027,39.02812],[-89.462529,39.057168]],[[-89.490905,39.135864],[-89.492454,39.122588],[-89.506149,39.129265],[-89.490905,39.135864]]]},"properties":{"id":"62049","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.223534,39.337843],[-89.21437,39.338283],[-89.21437,39.348889],[-89.224047,39.348828],[-89.223534,39.337843]]]},"properties":{"id":"62076","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.570103,39.513215],[-88.697736,39.520915],[-88.728989,39.449775],[-88.686441,39.404372],[-88.561871,39.345746],[-88.517862,39.338538],[-88.490243,39.38776],[-88.570103,39.513215]]]},"properties":{"id":"61957","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.828109,39.600029],[-88.722487,39.579392],[-88.67611,39.677421],[-88.756929,39.710665],[-88.81123,39.681922],[-88.866812,39.65294],[-88.828109,39.600029]]]},"properties":{"id":"61914","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.056688,42.150952],[-89.039731,42.078509],[-88.968165,42.064855],[-88.93922,42.09058],[-88.95021,42.152191],[-88.978919,42.15199],[-89.056688,42.150952]]]},"properties":{"id":"61052","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.510244,42.077502],[-89.472634,41.988688],[-89.345449,42.099063],[-89.495008,42.113553],[-89.510244,42.077502]]]},"properties":{"id":"61054","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.543423,41.905829],[-89.514621,41.902166],[-89.527549,41.91378],[-89.543423,41.905829]]]},"properties":{"id":"61091","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.688523,40.812956],[-89.58316,40.842412],[-89.601374,40.857248],[-89.664652,40.901075],[-89.736598,40.813183],[-89.688523,40.812956]]]},"properties":{"id":"61525","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.639099,39.879259],[-88.707964,39.802988],[-88.707886,39.792261],[-88.52966,39.791965],[-88.519019,39.791942],[-88.556382,39.849933],[-88.639099,39.879259]]]},"properties":{"id":"61929","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.639099,39.879259],[-88.556382,39.849933],[-88.469638,39.879056],[-88.462567,39.944376],[-88.668727,39.952552],[-88.640563,39.893878],[-88.639099,39.879259]]]},"properties":{"id":"61813","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.888648,38.166039],[-90.034602,38.126909],[-90.036385,38.05815],[-89.941033,38.032023],[-89.870386,38.06783],[-89.850845,38.111217],[-89.84743,38.1203],[-89.888648,38.166039]]]},"properties":{"id":"62242","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.340229,41.655632],[-90.23835,41.656282],[-90.218531,41.671993],[-90.244812,41.769511],[-90.340229,41.655632]]]},"properties":{"id":"61242","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.23835,41.656282],[-90.310532,41.53384],[-90.317884,41.508755],[-90.241089,41.524995],[-90.185609,41.584653],[-90.218531,41.671993],[-90.23835,41.656282]]]},"properties":{"id":"61257","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.952641,38.570711],[-89.879478,38.548305],[-89.871422,38.571631],[-89.910561,38.647381],[-89.913452,38.657478],[-89.956558,38.657896],[-89.966301,38.614426],[-89.959972,38.578092],[-89.952641,38.570711]]]},"properties":{"id":"62269","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.035929,38.459321],[-90.038894,38.410781],[-89.987462,38.424712],[-89.934251,38.468592],[-89.980819,38.526604],[-90.020896,38.509191],[-90.036202,38.498333],[-90.035929,38.459321]]]},"properties":{"id":"62220","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.704087,38.368151],[-89.722614,38.361698],[-89.703915,38.349829],[-89.704087,38.368151]]]},"properties":{"id":"62282","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.535409,39.910679],[-89.564767,39.874329],[-89.525484,39.806679],[-89.496765,39.803351],[-89.463403,39.868184],[-89.483347,39.918613],[-89.535409,39.910679]]]},"properties":{"id":"62561","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.759537,39.52295],[-89.75611,39.542648],[-89.767786,39.536523],[-89.767618,39.526818],[-89.759537,39.52295]]]},"properties":{"id":"62689","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.564767,39.874329],[-89.535409,39.910679],[-89.629973,39.961459],[-89.645976,39.96119],[-89.645303,39.928219],[-89.564767,39.874329]]]},"properties":{"id":"62684","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.629077,39.648384],[-89.685509,39.62681],[-89.669662,39.619761],[-89.631686,39.604548],[-89.629077,39.648384]]]},"properties":{"id":"62536","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.794606,39.711865],[-89.927315,39.652917],[-89.84211,39.610271],[-89.769952,39.639674],[-89.794606,39.711865]]]},"properties":{"id":"62661","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.430452,39.560051],[-90.43957,39.56313],[-90.43975,39.554766],[-90.430452,39.560051]]]},"properties":{"id":"62610","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.768477,42.313722],[-89.723192,42.246105],[-89.674934,42.208344],[-89.522295,42.215236],[-89.493742,42.244134],[-89.485826,42.360611],[-89.495678,42.360408],[-89.628597,42.43959],[-89.688151,42.444892],[-89.735501,42.421888],[-89.749217,42.330867],[-89.757429,42.328111],[-89.764396,42.332134],[-89.768477,42.313722]],[[-89.642844,42.377282],[-89.635649,42.380066],[-89.628632,42.372023],[-89.640805,42.369061],[-89.642844,42.377282]]]},"properties":{"id":"61032","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.642844,42.377282],[-89.640805,42.369061],[-89.628632,42.372023],[-89.635649,42.380066],[-89.642844,42.377282]]]},"properties":{"id":"61013","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.514164,40.604574],[-89.495481,40.568212],[-89.383362,40.571089],[-89.355855,40.615078],[-89.403835,40.642972],[-89.501341,40.659921],[-89.514164,40.604574]]]},"properties":{"id":"61550","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.269617,40.602506],[-89.309079,40.644732],[-89.329614,40.660774],[-89.403835,40.642972],[-89.355855,40.615078],[-89.283071,40.572443],[-89.269617,40.602506]]]},"properties":{"id":"61733","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.579418,40.020649],[-87.62544,40.072367],[-87.644495,40.079088],[-87.676227,40.080328],[-87.677134,40.013315],[-87.579418,40.020649]]]},"properties":{"id":"61883","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.623524,40.270879],[-87.530791,40.19183],[-87.529973,40.25057],[-87.623524,40.270879]]]},"properties":{"id":"61814","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.32258,38.390894],[-89.204888,38.447116],[-89.348018,38.516686],[-89.388946,38.494333],[-89.389042,38.459784],[-89.32258,38.390894]]]},"properties":{"id":"62803","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.634768,38.451105],[-89.463566,38.412483],[-89.389042,38.459784],[-89.388946,38.494333],[-89.419014,38.494899],[-89.577639,38.483159],[-89.581817,38.479062],[-89.634768,38.451105]]]},"properties":{"id":"62271","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.207283,38.357048],[-88.16482,38.411928],[-88.217234,38.396298],[-88.207283,38.357048]]]},"properties":{"id":"62843","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.931454,41.643535],[-88.01175,41.63476],[-87.984038,41.555114],[-87.957604,41.555711],[-87.909809,41.573338],[-87.931454,41.643535]]]},"properties":{"id":"60491","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.086433,41.524878],[-88.150425,41.521734],[-88.197575,41.486452],[-88.197353,41.474844],[-88.163671,41.46435],[-88.079491,41.466346],[-88.078685,41.524093],[-88.086433,41.524878]]]},"properties":{"id":"60436","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.022102,41.467664],[-88.079491,41.466346],[-88.163671,41.46435],[-88.175076,41.40362],[-88.038779,41.394449],[-88.022102,41.467664]]]},"properties":{"id":"60421","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.359654,42.389882],[-89.232942,42.376859],[-89.232461,42.392933],[-89.197645,42.435815],[-89.218751,42.430122],[-89.231918,42.441375],[-89.20436,42.443422],[-89.194501,42.44908],[-89.262669,42.49819],[-89.371748,42.500162],[-89.359654,42.389882]]]},"properties":{"id":"61024","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.113681,40.758147],[-89.102762,40.808896],[-89.217341,40.874239],[-89.274296,40.851309],[-89.293371,40.836424],[-89.292845,40.792132],[-89.195352,40.73314],[-89.113681,40.758147]]]},"properties":{"id":"61561","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.365125,39.758723],[-91.178012,39.598196],[-91.156123,39.660366],[-91.147678,39.75707],[-91.148064,39.757062],[-91.242283,39.77627],[-91.312986,39.758074],[-91.365125,39.758723]]]},"properties":{"id":"62343","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.726774,39.020847],[-89.754801,38.965666],[-89.763554,38.959724],[-89.769927,38.955351],[-89.778036,38.939665],[-89.669585,38.888492],[-89.623342,38.903956],[-89.684603,39.013847],[-89.698801,39.028275],[-89.726774,39.020847]]]},"properties":{"id":"62074","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.966503,42.369996],[-89.060529,42.327106],[-89.064327,42.288762],[-89.054097,42.299307],[-88.931789,42.32676],[-88.966503,42.369996]]]},"properties":{"id":"61111","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.059419,42.397554],[-89.045868,42.390053],[-88.974583,42.388732],[-88.92147,42.457629],[-89.019871,42.463007],[-89.059419,42.397554]]]},"properties":{"id":"61073","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.79942,39.79107],[-90.75496,39.78403],[-90.742005,39.786283],[-90.615404,39.75122],[-90.571754,39.839326],[-90.760391,39.858883],[-90.787747,39.840658],[-90.79942,39.79107]]]},"properties":{"id":"62323","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.315888,41.451628],[-89.310201,41.444314],[-89.325783,41.422451],[-89.277554,41.407807],[-89.227658,41.386066],[-89.179405,41.386407],[-89.166051,41.454254],[-89.18126,41.481649],[-89.315888,41.451628]],[[-89.180965,41.437753],[-89.180748,41.423155],[-89.238775,41.422487],[-89.219665,41.44103],[-89.180965,41.437753]]]},"properties":{"id":"61312","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.180965,41.437753],[-89.219665,41.44103],[-89.238775,41.422487],[-89.180748,41.423155],[-89.180965,41.437753]]]},"properties":{"id":"61317","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.825248,41.693229],[-89.85746,41.511456],[-89.857349,41.486789],[-89.839788,41.484966],[-89.758371,41.496664],[-89.715086,41.506743],[-89.720051,41.506658],[-89.72009,41.514842],[-89.708431,41.584619],[-89.709213,41.656993],[-89.825248,41.693229]]]},"properties":{"id":"61283","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.32927,41.147931],[-89.482731,41.163092],[-89.520708,41.172318],[-89.592,41.1122],[-89.57279,41.07568],[-89.381481,41.080164],[-89.358597,41.103665],[-89.333027,41.132961],[-89.32927,41.147931]]]},"properties":{"id":"61537","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.687423,39.396621],[-90.715639,39.379704],[-90.71491,39.307366],[-90.738709,39.280964],[-90.675395,39.25157],[-90.604133,39.284184],[-90.613887,39.394963],[-90.613694,39.395775],[-90.687423,39.396621]]]},"properties":{"id":"62053","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.652852,39.220694],[-90.608396,39.173225],[-90.604133,39.284184],[-90.675395,39.25157],[-90.652852,39.220694]]]},"properties":{"id":"62065","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.251639,39.873397],[-90.135695,39.873086],[-90.055556,39.981641],[-90.273357,40.054498],[-90.335759,39.988242],[-90.341364,39.961569],[-90.251639,39.873397]]]},"properties":{"id":"62691","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.335759,39.988242],[-90.350522,39.96176],[-90.341364,39.961569],[-90.335759,39.988242]]]},"properties":{"id":"62622","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.257405,40.076137],[-88.262771,40.032841],[-88.238179,40.039719],[-88.238394,40.068933],[-88.257447,40.080312],[-88.257405,40.076137]]]},"properties":{"id":"61874","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.231073,40.098781],[-88.200721,40.134449],[-88.229111,40.135033],[-88.231073,40.098781]]]},"properties":{"id":"61801","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.229111,40.135033],[-88.200721,40.134449],[-88.231073,40.098781],[-88.232478,40.083497],[-88.216041,40.039819],[-88.180555,40.025454],[-88.10432,40.0201],[-88.104596,40.054868],[-88.085885,40.224722],[-88.195637,40.224181],[-88.231995,40.135005],[-88.229111,40.135033]]]},"properties":{"id":"61802","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.180555,40.025454],[-88.160531,39.923651],[-88.1229,39.910822],[-88.105589,39.935706],[-88.10432,40.0201],[-88.180555,40.025454]]]},"properties":{"id":"61864","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.085885,40.224722],[-88.04013,40.254238],[-88.078507,40.268947],[-88.251094,40.253016],[-88.195637,40.224181],[-88.085885,40.224722]]]},"properties":{"id":"61878","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.388446,40.109886],[-88.374275,40.110511],[-88.37108,40.113248],[-88.388479,40.113185],[-88.388446,40.109886]]]},"properties":{"id":"61815","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.442724,39.59104],[-89.439217,39.586978],[-89.439579,39.589894],[-89.442724,39.59104]]]},"properties":{"id":"62570","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.393733,39.518583],[-89.432976,39.548601],[-89.551736,39.484672],[-89.551039,39.421693],[-89.438954,39.319669],[-89.278665,39.407021],[-89.278576,39.429082],[-89.312007,39.443785],[-89.402506,39.43668],[-89.393733,39.518583]]]},"properties":{"id":"62546","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.442724,39.59104],[-89.439579,39.589894],[-89.423391,39.593559],[-89.443225,39.59287],[-89.442724,39.59104]]]},"properties":{"id":"62517","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.470789,39.770839],[-89.492033,39.788184],[-89.587245,39.692467],[-89.478535,39.649195],[-89.409978,39.729819],[-89.470789,39.770839]]]},"properties":{"id":"62563","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.605637,39.370669],[-89.457697,39.290426],[-89.438954,39.319669],[-89.551039,39.421693],[-89.605872,39.38535],[-89.605637,39.370669]]]},"properties":{"id":"62538","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.7488,39.297098],[-87.848663,39.476401],[-87.872226,39.482854],[-87.94138,39.445295],[-87.96168,39.369402],[-87.91412,39.184875],[-87.884719,39.176117],[-87.820798,39.192131],[-87.764473,39.223173],[-87.7488,39.297098]]]},"properties":{"id":"62442","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.754162,38.913127],[-88.580767,38.875972],[-88.568841,38.878069],[-88.754162,38.913127]]]},"properties":{"id":"62426","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.61763,39.010852],[-88.786497,38.999559],[-88.777928,38.91968],[-88.754162,38.913127],[-88.568841,38.878069],[-88.469935,38.92753],[-88.539157,38.973052],[-88.61763,39.010852]]]},"properties":{"id":"62443","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.095603,38.575838],[-89.14996,38.611642],[-89.348018,38.516686],[-89.204888,38.447116],[-89.181853,38.446405],[-89.158819,38.452809],[-89.14527,38.423196],[-89.145423,38.401666],[-89.074475,38.431031],[-88.979578,38.475497],[-88.965304,38.475857],[-88.959899,38.483228],[-88.964776,38.562045],[-89.01404,38.562835],[-89.095603,38.575838]],[[-89.259229,38.538639],[-89.270867,38.535824],[-89.273096,38.544937],[-89.261252,38.544988],[-89.259229,38.538639]]]},"properties":{"id":"62801","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.404996,38.741015],[-89.480774,38.696494],[-89.485326,38.56573],[-89.419014,38.494899],[-89.388946,38.494333],[-89.348018,38.516686],[-89.14996,38.611642],[-89.13869,38.701855],[-89.215236,38.740253],[-89.273551,38.734667],[-89.372068,38.741176],[-89.404996,38.741015]],[[-89.439522,38.601848],[-89.442751,38.608089],[-89.424262,38.605578],[-89.424206,38.601974],[-89.439522,38.601848]]]},"properties":{"id":"62231","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.095603,38.575838],[-89.100134,38.691231],[-89.13869,38.701855],[-89.14996,38.611642],[-89.095603,38.575838]]]},"properties":{"id":"62882","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.986337,39.722095],[-88.082855,39.768518],[-88.091713,39.745461],[-88.133923,39.637565],[-88.072738,39.573672],[-87.983145,39.583538],[-87.94653,39.646776],[-87.986337,39.722095]]]},"properties":{"id":"61943","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.195816,39.623291],[-88.265888,39.598007],[-88.266414,39.565031],[-88.266432,39.459711],[-88.185256,39.375036],[-88.092853,39.376422],[-88.063925,39.406043],[-88.050101,39.478545],[-88.072738,39.573672],[-88.133923,39.637565],[-88.195816,39.623291]]]},"properties":{"id":"61920","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.072738,39.573672],[-88.050101,39.478545],[-87.960179,39.481309],[-87.983145,39.583538],[-88.072738,39.573672]]]},"properties":{"id":"61912","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.091713,39.745461],[-88.12031,39.727682],[-88.175788,39.709891],[-88.195816,39.623291],[-88.133923,39.637565],[-88.091713,39.745461]]]},"properties":{"id":"61930","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.404688,39.725124],[-88.42421,39.622572],[-88.265888,39.598007],[-88.195816,39.623291],[-88.175788,39.709891],[-88.404688,39.725124]]]},"properties":{"id":"61910","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.940582,42.030014],[-87.865637,42.027024],[-87.846618,42.041184],[-87.840318,42.056843],[-87.888066,42.074454],[-87.940582,42.030014]]]},"properties":{"id":"60016","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.747816,42.015405],[-87.744863,42.064837],[-87.758984,42.064991],[-87.769884,42.062544],[-87.763921,42.055382],[-87.779387,42.023585],[-87.767673,42.008423],[-87.761815,42.004653],[-87.747816,42.015405]]]},"properties":{"id":"60077","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.867108,42.087335],[-87.829077,42.077771],[-87.814409,42.094433],[-87.867108,42.087335]]]},"properties":{"id":"60026","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.847862,41.598938],[-87.85728,41.557688],[-87.843228,41.528478],[-87.790448,41.53671],[-87.823448,41.601841],[-87.847862,41.598938]]]},"properties":{"id":"60487","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.001767,41.713272],[-88.029327,41.695531],[-88.052838,41.680834],[-88.060157,41.640677],[-88.01175,41.63476],[-87.931454,41.643535],[-87.912395,41.657126],[-87.913072,41.677383],[-87.941046,41.703436],[-87.973042,41.731374],[-88.001767,41.713272]]]},"properties":{"id":"60439","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.869617,41.973133],[-87.890763,41.95803],[-87.854205,41.945988],[-87.857447,41.959583],[-87.869617,41.973133]]]},"properties":{"id":"60176","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.826254,41.93775],[-87.825457,41.911015],[-87.824511,41.908562],[-87.805748,41.908904],[-87.785002,41.909148],[-87.785689,41.931002],[-87.826254,41.93775]]]},"properties":{"id":"60707","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.889432,41.819672],[-87.861776,41.818009],[-87.852294,41.845801],[-87.85312,41.846064],[-87.865464,41.845534],[-87.900293,41.826422],[-87.889432,41.819672]]]},"properties":{"id":"60526","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.897591,41.884888],[-87.882839,41.87116],[-87.868111,41.871044],[-87.863164,41.871182],[-87.859764,41.890703],[-87.878485,41.892744],[-87.897591,41.884888]]]},"properties":{"id":"60104","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.667148,41.675834],[-87.661419,41.638756],[-87.632313,41.629838],[-87.578573,41.637513],[-87.578483,41.644729],[-87.585199,41.65566],[-87.642274,41.670531],[-87.667148,41.675834]]]},"properties":{"id":"60827","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.632313,41.629838],[-87.661419,41.638756],[-87.675935,41.627214],[-87.698184,41.622255],[-87.655883,41.58634],[-87.655949,41.579063],[-87.653447,41.579083],[-87.636384,41.582342],[-87.624403,41.622638],[-87.632313,41.629838]]]},"properties":{"id":"60426","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.626648,41.560717],[-87.635944,41.538778],[-87.582632,41.542621],[-87.583692,41.557472],[-87.626648,41.560717]]]},"properties":{"id":"60425","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.643819,41.721451],[-87.642274,41.670531],[-87.585199,41.65566],[-87.586051,41.707765],[-87.586639,41.722323],[-87.624371,41.729036],[-87.643819,41.721451]]]},"properties":{"id":"60628","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.624907,41.765514],[-87.624089,41.794665],[-87.625701,41.794643],[-87.654874,41.794172],[-87.653929,41.757846],[-87.624759,41.758237],[-87.624907,41.765514]]]},"properties":{"id":"60621","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.641378,41.888632],[-87.641027,41.876752],[-87.633694,41.87685],[-87.633754,41.879386],[-87.633824,41.881946],[-87.633895,41.884469],[-87.633966,41.887064],[-87.641378,41.888632]]]},"properties":{"id":"60606","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.583448,41.812433],[-87.625268,41.809249],[-87.625701,41.794643],[-87.624089,41.794665],[-87.566651,41.795509],[-87.583448,41.812433]]]},"properties":{"id":"60615","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.787735,41.982899],[-87.720903,41.98291],[-87.718655,41.98294],[-87.727274,41.99725],[-87.761815,42.004653],[-87.767673,42.008423],[-87.790766,42.000531],[-87.78834,41.982897],[-87.787735,41.982899]]]},"properties":{"id":"60646","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.726979,41.93179],[-87.765648,41.931738],[-87.785689,41.931002],[-87.785002,41.909148],[-87.775586,41.908752],[-87.726487,41.909384],[-87.726979,41.93179]]]},"properties":{"id":"60639","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.706542,41.895079],[-87.669522,41.888837],[-87.667069,41.888852],[-87.669703,41.917902],[-87.670201,41.917886],[-87.704516,41.910201],[-87.706556,41.895553],[-87.706542,41.895079]]]},"properties":{"id":"60622","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.615956,41.918831],[-87.648272,41.91092],[-87.647745,41.896399],[-87.628222,41.89752],[-87.621952,41.904232],[-87.615956,41.918831]]]},"properties":{"id":"60610","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.802676,41.823715],[-87.802268,41.813966],[-87.801961,41.806424],[-87.802094,41.797847],[-87.781972,41.80436],[-87.779048,41.865392],[-87.804199,41.86498],[-87.803944,41.857735],[-87.802676,41.823715]]]},"properties":{"id":"60402","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.816219,41.523018],[-88.688294,41.589651],[-88.737776,41.699499],[-88.819106,41.68222],[-88.816219,41.523018]]]},"properties":{"id":"60531","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67376,41.58681],[-88.6795,41.718936],[-88.706345,41.731036],[-88.737776,41.699499],[-88.688294,41.589651],[-88.67376,41.58681]]]},"properties":{"id":"60552","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.57759,42.065316],[-88.558335,42.049973],[-88.581763,42.006758],[-88.477872,41.982765],[-88.393866,42.08835],[-88.408424,42.110436],[-88.517986,42.153774],[-88.588657,42.15359],[-88.601635,42.085255],[-88.57759,42.065316]]]},"properties":{"id":"60140","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.145973,40.136357],[-89.057452,40.071622],[-88.80321,40.069973],[-88.825189,40.150518],[-88.86096,40.224474],[-89.047833,40.211812],[-89.146279,40.151121],[-89.145973,40.136357]],[[-88.855551,40.12021],[-88.878923,40.119694],[-88.878659,40.130405],[-88.866561,40.127364],[-88.842861,40.127743],[-88.840701,40.116832],[-88.855551,40.12021]]]},"properties":{"id":"61727","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.812372,40.055148],[-88.764385,40.026306],[-88.688046,40.112851],[-88.687993,40.142269],[-88.755661,40.185689],[-88.825189,40.150518],[-88.80321,40.069973],[-88.812372,40.055148]]]},"properties":{"id":"61882","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.556382,39.849933],[-88.519019,39.791942],[-88.423071,39.768756],[-88.396672,39.864381],[-88.420336,39.879216],[-88.469638,39.879056],[-88.556382,39.849933]]]},"properties":{"id":"61913","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.101069,41.931255],[-88.082207,41.896964],[-88.04829,41.90386],[-88.048192,41.907386],[-88.056042,41.928383],[-88.101069,41.931255]]]},"properties":{"id":"60139","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.146683,41.831375],[-88.113452,41.815016],[-88.073172,41.815238],[-88.071904,41.816826],[-88.078992,41.859208],[-88.146323,41.857291],[-88.170783,41.842334],[-88.177859,41.838183],[-88.146683,41.831375]]]},"properties":{"id":"60189","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.145538,41.89437],[-88.127456,41.887737],[-88.124588,41.88778],[-88.14537,41.901128],[-88.145538,41.89437]]],[[[-88.146323,41.857291],[-88.14413,41.887873],[-88.145569,41.893644],[-88.170783,41.842334],[-88.146323,41.857291]]]]},"properties":{"id":"60190","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.908677,39.798979],[-87.835836,39.747125],[-87.880677,39.850768],[-87.899641,39.865321],[-87.928025,39.861548],[-87.908677,39.798979]]]},"properties":{"id":"61932","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.197136,38.242197],[-88.131514,38.212664],[-87.985274,38.215486],[-87.987911,38.256957],[-87.954798,38.335308],[-87.982513,38.335343],[-88.1509,38.256097],[-88.194613,38.256616],[-88.197136,38.242197]]]},"properties":{"id":"62844","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.26846,41.0525],[-88.28707,41.03776],[-88.28655,41.02318],[-88.131623,40.983044],[-88.143999,41.107072],[-88.1537,41.106628],[-88.153643,41.115835],[-88.146869,41.115985],[-88.145048,41.116025],[-88.145508,41.13072],[-88.164678,41.130365],[-88.26846,41.0525]]]},"properties":{"id":"60917","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.252848,40.472042],[-88.291006,40.47193],[-88.290963,40.457356],[-88.252848,40.472042]]]},"properties":{"id":"60933","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.559983,40.630448],[-90.446627,40.56907],[-90.446739,40.552305],[-90.368604,40.554194],[-90.329711,40.585931],[-90.291008,40.65252],[-90.328003,40.714119],[-90.472638,40.744362],[-90.509173,40.753563],[-90.559983,40.630448]]]},"properties":{"id":"61415","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.047769,40.495176],[-90.060439,40.494317],[-90.060412,40.485641],[-90.047769,40.495176]]]},"properties":{"id":"61563","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.077319,37.805267],[-88.193177,37.776905],[-88.241175,37.647751],[-88.157994,37.577356],[-88.133242,37.57414],[-88.077319,37.805267]]]},"properties":{"id":"62984","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.193177,37.776905],[-88.290077,37.776654],[-88.331662,37.599084],[-88.241175,37.647751],[-88.193177,37.776905]]]},"properties":{"id":"62954","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.277455,38.05384],[-88.374052,38.017636],[-88.374429,37.995054],[-88.414869,37.907535],[-88.374487,37.87832],[-88.181601,37.908058],[-88.153331,37.966103],[-88.277455,38.05384]]]},"properties":{"id":"62869","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.322628,39.390606],[-90.296213,39.375794],[-90.279194,39.391332],[-90.320464,39.403762],[-90.322628,39.390606]]]},"properties":{"id":"62098","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.148069,39.261947],[-90.036276,39.182159],[-90.036693,39.203661],[-90.022177,39.314631],[-90.08793,39.319953],[-90.169661,39.269252],[-90.148069,39.261947]]]},"properties":{"id":"62630","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.324142,41.301111],[-88.475039,41.261836],[-88.47195,41.181643],[-88.416116,41.168469],[-88.308761,41.232363],[-88.313629,41.243615],[-88.324142,41.301111]]]},"properties":{"id":"60444","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.023564,40.480273],[-91.022222,40.634956],[-91.146084,40.649157],[-91.247851,40.63839],[-91.269135,40.521164],[-91.249863,40.520919],[-91.023564,40.480273]]]},"properties":{"id":"62330","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.258084,40.395033],[-91.226931,40.372748],[-91.24258,40.403379],[-91.258084,40.395033]]]},"properties":{"id":"62334","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.172047,40.471368],[-91.173795,40.46878],[-91.170323,40.468102],[-91.169276,40.470048],[-91.172047,40.471368]]]},"properties":{"id":"62336","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.32238,41.209802],[-90.380418,41.252346],[-90.386301,41.238082],[-90.437657,41.151252],[-90.331217,41.151354],[-90.32238,41.209802]]]},"properties":{"id":"61413","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.380418,41.252346],[-90.396998,41.252673],[-90.386301,41.238082],[-90.380418,41.252346]]]},"properties":{"id":"61468","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.018036,41.088177],[-88.145048,41.116025],[-88.146869,41.115985],[-88.143999,41.107072],[-88.131623,40.983044],[-88.13105,40.968475],[-88.055372,40.984548],[-88.002609,41.044307],[-88.018036,41.088177]]]},"properties":{"id":"60941","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.847171,40.87284],[-87.877968,40.808715],[-87.89393,40.798303],[-87.882613,40.769544],[-87.821673,40.698106],[-87.73521,40.712482],[-87.746581,40.728964],[-87.719771,40.723128],[-87.725576,40.71464],[-87.650112,40.709075],[-87.59077,40.817377],[-87.590669,40.83465],[-87.673261,40.890929],[-87.847171,40.87284]]]},"properties":{"id":"60970","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.525696,40.81725],[-87.526005,40.951486],[-87.673549,40.920134],[-87.673261,40.890929],[-87.590669,40.83465],[-87.576904,40.826612],[-87.525696,40.81725]]]},"properties":{"id":"60931","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.408629,37.689082],[-89.331424,37.658307],[-89.295698,37.700856],[-89.202765,37.773586],[-89.283884,37.849575],[-89.283439,37.862943],[-89.412806,37.886906],[-89.450558,37.778706],[-89.438615,37.691981],[-89.408629,37.689082]]]},"properties":{"id":"62966","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.637871,37.887685],[-89.558445,37.803668],[-89.498967,37.77748],[-89.450558,37.778706],[-89.412806,37.886906],[-89.374309,37.952099],[-89.447772,37.953471],[-89.637871,37.887685]]]},"properties":{"id":"62907","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.093194,37.730666],[-89.151677,37.773956],[-89.202765,37.773586],[-89.295698,37.700856],[-89.212846,37.700411],[-89.131407,37.744966],[-89.093194,37.730666]]]},"properties":{"id":"62901","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.282182,37.937319],[-89.283439,37.862943],[-89.283884,37.849575],[-89.151176,37.861999],[-89.150755,37.945185],[-89.177597,37.950311],[-89.282182,37.937319]],[[-89.240167,37.943606],[-89.237023,37.930263],[-89.248084,37.928194],[-89.240167,37.943606]]]},"properties":{"id":"62932","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.240167,37.943606],[-89.248084,37.928194],[-89.237023,37.930263],[-89.240167,37.943606]]]},"properties":{"id":"62927","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.412806,37.886906],[-89.283439,37.862943],[-89.282182,37.937319],[-89.354622,37.951939],[-89.374309,37.952099],[-89.412806,37.886906]]]},"properties":{"id":"62994","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.224798,38.804099],[-87.984142,38.791238],[-87.964843,38.850011],[-87.976495,38.849673],[-88.114494,38.847895],[-88.227974,38.81797],[-88.224798,38.804099]]]},"properties":{"id":"62425","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.161674,39.152324],[-90.146162,39.137672],[-90.090993,39.142224],[-90.036276,39.182159],[-90.148069,39.261947],[-90.210685,39.225517],[-90.221862,39.203832],[-90.164292,39.157684],[-90.161674,39.152324]]]},"properties":{"id":"62063","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.312787,38.958547],[-90.371721,38.988507],[-90.385757,38.956439],[-90.327688,38.931061],[-90.312787,38.958547]]]},"properties":{"id":"62028","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.340794,41.990763],[-88.280072,42.012602],[-88.270805,42.06646],[-88.335414,42.083564],[-88.340794,41.990763]]]},"properties":{"id":"60123","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.357795,41.98533],[-88.47787,41.971894],[-88.479453,41.936117],[-88.375176,41.896927],[-88.340391,41.897722],[-88.319274,41.969003],[-88.357795,41.98533]]]},"properties":{"id":"60175","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.489875,41.722029],[-88.603488,41.713584],[-88.58359,41.62193],[-88.547975,41.624103],[-88.452434,41.691259],[-88.48567,41.71732],[-88.489875,41.722029]]]},"properties":{"id":"60545","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.388651,41.709283],[-88.417603,41.722102],[-88.443878,41.721786],[-88.48567,41.71732],[-88.452434,41.691259],[-88.39176,41.70742],[-88.388651,41.709283]]]},"properties":{"id":"60512","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.25379,41.694263],[-88.270673,41.631755],[-88.164363,41.637937],[-88.165995,41.681447],[-88.235347,41.715517],[-88.25379,41.694263]]]},"properties":{"id":"60585","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.256743,41.021209],[-90.333001,41.063787],[-90.401035,41.042172],[-90.357458,41.020246],[-90.369141,41.034983],[-90.351457,41.034773],[-90.353865,41.020211],[-90.256743,41.021209]]]},"properties":{"id":"61488","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.008014,40.859505],[-90.081245,40.844747],[-90.100521,40.757093],[-90.005078,40.745321],[-90.008014,40.859505]]]},"properties":{"id":"61572","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.884788,42.385157],[-87.799075,42.38461],[-87.797422,42.413646],[-87.876157,42.435655],[-87.9154,42.399799],[-87.884788,42.385157]]]},"properties":{"id":"60087","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.930631,41.044499],[-88.988388,41.014412],[-89.047718,40.925749],[-88.93139,40.92774],[-88.93092,40.97189],[-88.930654,41.030573],[-88.930631,41.044499]]]},"properties":{"id":"61321","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.980571,41.184943],[-88.981083,41.192634],[-88.998106,41.192595],[-88.998054,41.184993],[-88.980571,41.184943]]]},"properties":{"id":"61332","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.772457,38.800128],[-87.777656,38.622351],[-87.722287,38.643275],[-87.772457,38.800128]]]},"properties":{"id":"62417","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.336808,41.902319],[-89.390256,41.837569],[-89.370956,41.837578],[-89.361453,41.822744],[-89.390177,41.822728],[-89.361437,41.794435],[-89.241207,41.758069],[-89.202568,41.78593],[-89.29228,41.895325],[-89.336808,41.902319]]]},"properties":{"id":"61031","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.209575,41.671173],[-89.1672,41.628707],[-89.12238,41.64224],[-89.083427,41.803204],[-89.11242,41.81755],[-89.202568,41.78593],[-89.241207,41.758069],[-89.209575,41.671173]]]},"properties":{"id":"61378","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.265024,40.398465],[-89.379424,40.396712],[-89.378991,40.382156],[-89.377771,40.338487],[-89.366103,40.287404],[-89.272961,40.310519],[-89.263578,40.317169],[-89.264441,40.361868],[-89.265024,40.398465]]]},"properties":{"id":"61721","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.442787,39.933055],[-89.531305,40.00557],[-89.629973,39.961459],[-89.535409,39.910679],[-89.483347,39.918613],[-89.442787,39.933055]]]},"properties":{"id":"62693","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.29068,42.209546],[-88.398317,42.204805],[-88.381121,42.16984],[-88.286488,42.190121],[-88.29068,42.209546]]]},"properties":{"id":"60156","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.413566,42.414409],[-88.351297,42.356577],[-88.306093,42.376842],[-88.330759,42.430234],[-88.365874,42.43127],[-88.413566,42.414409]]]},"properties":{"id":"60097","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.551265,42.266647],[-88.510849,42.181774],[-88.471789,42.241422],[-88.551265,42.266647]]]},"properties":{"id":"60180","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.686817,40.500794],[-88.797384,40.501324],[-88.825781,40.487114],[-88.80646,40.472283],[-88.762138,40.428126],[-88.669581,40.398856],[-88.688141,40.471384],[-88.686817,40.500794]]]},"properties":{"id":"61737","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.898521,39.994885],[-88.860171,39.90818],[-88.801712,39.799074],[-88.745701,39.799532],[-88.745749,39.814174],[-88.745302,39.946076],[-88.764385,40.026306],[-88.812372,40.055148],[-88.898521,39.994885]]]},"properties":{"id":"62501","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.918421,39.965122],[-88.874677,39.911431],[-88.860171,39.90818],[-88.898521,39.994885],[-88.918421,39.965122]]]},"properties":{"id":"62554","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.217523,39.813224],[-89.180693,39.917275],[-89.1993,39.917109],[-89.311332,39.896712],[-89.298421,39.786618],[-89.217523,39.813224]]]},"properties":{"id":"62539","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.792703,39.071447],[-89.797513,39.085805],[-89.811391,39.078397],[-89.792703,39.071447]]]},"properties":{"id":"62085","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.324679,40.981181],[-89.237973,40.98389],[-89.234167,40.984378],[-89.14354,41.04428],[-89.144885,41.058813],[-89.29514,41.09672],[-89.324679,40.981181]]]},"properties":{"id":"61375","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.237973,40.98389],[-89.23167,40.976823],[-89.234167,40.984378],[-89.237973,40.98389]]]},"properties":{"id":"61541","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.050917,40.209185],[-90.061599,40.119294],[-90.050813,40.103651],[-89.903715,40.134037],[-89.885844,40.134535],[-89.973174,40.217068],[-90.050917,40.209185]]]},"properties":{"id":"62655","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.84006,37.198581],[-88.858541,37.216354],[-88.863687,37.207823],[-88.84006,37.198581]]]},"properties":{"id":"62953","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.993843,39.792727],[-89.800677,39.79779],[-89.758485,39.822589],[-89.742628,39.873159],[-89.760981,39.916405],[-89.769309,39.914322],[-89.815001,39.909568],[-89.994506,39.901925],[-89.993843,39.792727]]]},"properties":{"id":"62677","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.541905,41.151287],[-90.477745,41.063705],[-90.439423,41.063995],[-90.438122,41.136808],[-90.534738,41.151365],[-90.541905,41.151287]]]},"properties":{"id":"61466","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.99602,38.307074],[-89.995997,38.305169],[-89.998757,38.305201],[-89.989547,38.308293],[-89.996012,38.308355],[-89.99602,38.307074]]]},"properties":{"id":"62248","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.434238,39.261159],[-89.325953,39.188159],[-89.288088,39.20661],[-89.434238,39.261159]]]},"properties":{"id":"62094","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.462529,39.057168],[-89.38972,39.027826],[-89.343387,39.027853],[-89.299019,39.028218],[-89.363019,39.111501],[-89.462529,39.057168]]]},"properties":{"id":"62017","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.168443,39.246914],[-89.067995,39.216287],[-89.044146,39.288735],[-89.160824,39.290669],[-89.168443,39.246914]]]},"properties":{"id":"62553","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.465522,39.78268],[-90.437696,39.727192],[-90.325656,39.788475],[-90.410499,39.842352],[-90.448456,39.835957],[-90.465522,39.78268]]]},"properties":{"id":"62628","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.448456,39.835957],[-90.583534,39.87675],[-90.571754,39.839326],[-90.615404,39.75122],[-90.645185,39.706288],[-90.465522,39.78268],[-90.448456,39.835957]]]},"properties":{"id":"62665","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.223983,39.625297],[-90.294163,39.648431],[-90.358092,39.56843],[-90.3255,39.549576],[-90.32213,39.549583],[-90.172202,39.520218],[-90.182296,39.609096],[-90.219475,39.624714],[-90.223983,39.625297]]]},"properties":{"id":"62668","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.095265,42.054111],[-89.115008,42.06279],[-89.114701,42.042582],[-89.095265,42.054111]]]},"properties":{"id":"61043","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.517057,42.171787],[-89.628808,42.170785],[-89.685988,42.090314],[-89.68566,42.064623],[-89.510244,42.077502],[-89.495008,42.113553],[-89.512172,42.1718],[-89.517057,42.171787]]]},"properties":{"id":"61030","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.056688,42.150952],[-88.978919,42.15199],[-88.97943,42.226842],[-88.979716,42.239909],[-89.056208,42.237984],[-89.10462,42.237681],[-89.16052,42.178206],[-89.143925,42.150273],[-89.056688,42.150952]]]},"properties":{"id":"61109","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.623848,40.734958],[-89.718119,40.711049],[-89.718157,40.691774],[-89.675311,40.680416],[-89.622948,40.687803],[-89.594614,40.703134],[-89.593523,40.732058],[-89.623848,40.734958]]]},"properties":{"id":"61604","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.718119,40.711049],[-89.623848,40.734958],[-89.565036,40.758543],[-89.558494,40.759637],[-89.559994,40.809137],[-89.573093,40.824336],[-89.58316,40.842412],[-89.688523,40.812956],[-89.72039,40.738773],[-89.718119,40.711049]]]},"properties":{"id":"61615","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.69978,40.586325],[-89.756167,40.596958],[-89.776493,40.558332],[-89.741322,40.550806],[-89.688845,40.558512],[-89.69978,40.586325]]],[[[-89.69978,40.586325],[-89.735471,40.666988],[-89.790178,40.624134],[-89.756239,40.602122],[-89.69978,40.586325]]]]},"properties":{"id":"61547","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.756052,40.817592],[-89.888532,40.916157],[-89.927209,40.916698],[-89.833245,40.733655],[-89.756776,40.743007],[-89.756052,40.817592]]]},"properties":{"id":"61517","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.230808,37.331867],[-89.072367,37.319306],[-89.04454,37.388693],[-89.082265,37.462458],[-89.248444,37.357887],[-89.255561,37.346607],[-89.251668,37.335275],[-89.230808,37.331867]]]},"properties":{"id":"62926","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.172647,41.258248],[-89.192188,41.25779],[-89.182405,41.250299],[-89.172647,41.258248]]]},"properties":{"id":"61363","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.642498,37.927227],[-89.658118,38.058762],[-89.732133,38.015453],[-89.680162,37.914146],[-89.642498,37.927227]]]},"properties":{"id":"62288","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.684524,38.219433],[-89.701319,38.219323],[-89.683991,38.205337],[-89.684524,38.219433]]]},"properties":{"id":"62292","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.941033,38.032023],[-89.940687,37.970874],[-89.924597,37.959849],[-89.903394,37.954133],[-89.834688,37.987709],[-89.870386,38.06783],[-89.941033,38.032023]]]},"properties":{"id":"62241","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.420053,41.557435],[-90.392027,41.557476],[-90.370998,41.578435],[-90.420053,41.557435]]]},"properties":{"id":"61256","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.956558,38.657896],[-90.047208,38.64787],[-90.068895,38.61895],[-90.051964,38.602821],[-89.966301,38.614426],[-89.956558,38.657896]]]},"properties":{"id":"62232","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.038894,38.410781],[-90.045303,38.37447],[-90.02942,38.352198],[-89.965291,38.38095],[-89.987462,38.424712],[-90.038894,38.410781]]]},"properties":{"id":"62285","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.149529,38.61335],[-90.160778,38.598459],[-90.104477,38.556069],[-90.098826,38.555089],[-90.08735,38.571398],[-90.098347,38.587901],[-90.149529,38.61335]]]},"properties":{"id":"62207","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.149529,38.61335],[-90.098347,38.587901],[-90.111193,38.61872],[-90.132354,38.637496],[-90.149529,38.61335]]]},"properties":{"id":"62205","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.75193,38.553879],[-89.831984,38.560934],[-89.823702,38.532743],[-89.823087,38.481807],[-89.816167,38.35097],[-89.816162,38.350442],[-89.759956,38.355465],[-89.704417,38.415667],[-89.733475,38.553719],[-89.75193,38.553879]]]},"properties":{"id":"62258","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.522834,37.906784],[-88.568144,37.906764],[-88.596513,37.818699],[-88.516714,37.80931],[-88.522834,37.906784]]]},"properties":{"id":"62977","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.643051,39.69917],[-89.624855,39.680221],[-89.587245,39.692467],[-89.492033,39.788184],[-89.496765,39.803351],[-89.525484,39.806679],[-89.595213,39.801873],[-89.643844,39.713743],[-89.643051,39.69917]]]},"properties":{"id":"62712","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.645976,39.96119],[-89.760981,39.916405],[-89.742628,39.873159],[-89.645303,39.928219],[-89.645976,39.96119]]]},"properties":{"id":"62625","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.795884,40.124787],[-90.695884,40.103794],[-90.668043,40.190536],[-90.740293,40.221496],[-90.757423,40.192711],[-90.833603,40.148836],[-90.842878,40.141431],[-90.795884,40.124787]]]},"properties":{"id":"62319","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.817632,39.258838],[-88.735994,39.237474],[-88.694037,39.244851],[-88.675356,39.302749],[-88.722351,39.317549],[-88.801376,39.303456],[-88.817632,39.258838]]]},"properties":{"id":"62444","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.917889,39.478029],[-89.01096,39.478917],[-89.016701,39.306054],[-88.935689,39.289015],[-88.917195,39.296531],[-88.88075,39.370486],[-88.917889,39.478029]]]},"properties":{"id":"62571","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.694037,39.244851],[-88.581679,39.215315],[-88.568003,39.229874],[-88.550735,39.292565],[-88.517862,39.338538],[-88.561871,39.345746],[-88.675356,39.302749],[-88.694037,39.244851]]]},"properties":{"id":"62463","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.896796,42.505792],[-89.925769,42.459318],[-89.925323,42.444587],[-89.769854,42.422935],[-89.717166,42.459298],[-89.719399,42.505215],[-89.896796,42.505792]]]},"properties":{"id":"61089","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.628597,42.43959],[-89.495678,42.360408],[-89.536313,42.461028],[-89.628597,42.43959]]]},"properties":{"id":"61018","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.455074,42.50118],[-89.408604,42.352225],[-89.359654,42.389882],[-89.371748,42.500162],[-89.455074,42.50118]]]},"properties":{"id":"61019","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.621816,40.629414],[-89.658003,40.567438],[-89.675256,40.554054],[-89.726222,40.479084],[-89.582779,40.450431],[-89.531157,40.553502],[-89.553269,40.604666],[-89.57459,40.630137],[-89.621816,40.629414]],[[-89.648928,40.495867],[-89.648663,40.490905],[-89.663782,40.490707],[-89.663821,40.49441],[-89.648928,40.495867]]]},"properties":{"id":"61554","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.648928,40.495867],[-89.663821,40.49441],[-89.663782,40.490707],[-89.648663,40.490905],[-89.648928,40.495867]]]},"properties":{"id":"61564","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.266499,40.485552],[-89.382771,40.461904],[-89.379424,40.396712],[-89.265024,40.398465],[-89.266181,40.471028],[-89.266499,40.485552]]]},"properties":{"id":"61759","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.261321,37.468438],[-89.248444,37.357887],[-89.082265,37.462458],[-89.149216,37.529124],[-89.261321,37.468438]]]},"properties":{"id":"62906","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.708225,39.950186],[-87.761464,39.99852],[-87.78577,39.96894],[-87.77706,39.88087],[-87.710289,39.880907],[-87.653486,39.936319],[-87.708225,39.950186]]]},"properties":{"id":"61850","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.761464,39.99852],[-87.708225,39.950186],[-87.677134,40.013315],[-87.676227,40.080328],[-87.694188,40.115515],[-87.714757,40.101869],[-87.75324,40.087407],[-87.761464,39.99852]]]},"properties":{"id":"61817","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.844842,40.118145],[-87.849556,40.114136],[-87.83729,40.114283],[-87.83696,40.11932],[-87.844842,40.118145]]]},"properties":{"id":"61857","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.855316,38.57001],[-87.953044,38.482044],[-87.945128,38.442074],[-87.916264,38.385757],[-87.895139,38.387499],[-87.895414,38.379071],[-87.908904,38.379157],[-87.954798,38.335308],[-87.987911,38.256957],[-87.73486,38.48004],[-87.827361,38.569699],[-87.855316,38.57001]],[[-87.86743,38.351381],[-87.86387,38.354055],[-87.863666,38.348271],[-87.870139,38.348544],[-87.86743,38.351381]]]},"properties":{"id":"62863","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.916264,38.385757],[-87.908904,38.379157],[-87.895414,38.379071],[-87.895139,38.387499],[-87.916264,38.385757]]]},"properties":{"id":"62811","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.517126,40.961767],[-90.537948,40.809599],[-90.499593,40.816615],[-90.442444,40.83895],[-90.488426,40.976569],[-90.516972,40.976715],[-90.517126,40.961767]]]},"properties":{"id":"61423","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.709213,41.656993],[-89.708431,41.584619],[-89.631652,41.603134],[-89.632379,41.671561],[-89.709213,41.656993]]]},"properties":{"id":"61243","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.709689,41.456439],[-87.670569,41.420352],[-87.670909,41.447347],[-87.709689,41.456439]]]},"properties":{"id":"60484","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.02159,37.745159],[-89.02154,37.756298],[-89.026184,37.760084],[-89.02159,37.745159]]],[[[-89.00522,37.862126],[-89.055993,37.862044],[-89.058394,37.862051],[-89.040202,37.773845],[-89.016307,37.773928],[-88.997556,37.784788],[-89.000738,37.839746],[-89.002221,37.855019],[-89.00522,37.862126]]]]},"properties":{"id":"62948","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.091552,37.792577],[-89.077747,37.795511],[-89.077591,37.811254],[-89.08896,37.807222],[-89.091552,37.792577]]]},"properties":{"id":"62921","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.119936,37.780392],[-89.119268,37.784932],[-89.122711,37.785906],[-89.122629,37.781404],[-89.119936,37.780392]]]},"properties":{"id":"62915","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.184982,42.377644],[-89.232461,42.392933],[-89.232942,42.376859],[-89.223749,42.351218],[-89.184982,42.377644]]],[[[-89.223755,42.348727],[-89.357928,42.231224],[-89.306284,42.202726],[-89.216377,42.203773],[-89.193472,42.29112],[-89.223755,42.348727]]]]},"properties":{"id":"61088","state_code":"17"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.100059,41.121629],[-85.013699,41.127107],[-84.987947,41.156425],[-85.025227,41.200096],[-85.032771,41.199803],[-85.063701,41.175745],[-85.100059,41.121629]]]},"properties":{"id":"46835","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.179811,41.07467],[-85.189703,41.045615],[-85.161207,41.060869],[-85.137977,41.064271],[-85.126465,41.082828],[-85.142668,41.084027],[-85.179811,41.07467]]]},"properties":{"id":"46802","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.161207,41.060869],[-85.164925,41.04232],[-85.136167,41.016384],[-85.136554,41.031594],[-85.136226,41.060632],[-85.137977,41.064271],[-85.161207,41.060869]]]},"properties":{"id":"46807","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.165905,41.177687],[-85.063701,41.175745],[-85.032771,41.199803],[-85.09363,41.236503],[-85.171139,41.215972],[-85.165905,41.177687]]]},"properties":{"id":"46845","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.686049,39.297115],[-85.715166,39.232624],[-85.724671,39.1599],[-85.697906,39.159999],[-85.669281,39.211359],[-85.686049,39.297115]]]},"properties":{"id":"47244","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.097025,40.635465],[-87.22986,40.693252],[-87.362777,40.736517],[-87.362932,40.736517],[-87.526225,40.649778],[-87.526206,40.578767],[-87.416266,40.47683],[-87.341966,40.53756],[-87.138897,40.577044],[-87.096413,40.606453],[-87.097025,40.635465]]]},"properties":{"id":"47944","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.138897,40.577044],[-87.341966,40.53756],[-87.330443,40.476492],[-87.178663,40.489656],[-87.138897,40.577044]]]},"properties":{"id":"47971","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.446294,40.522519],[-85.44433,40.37914],[-85.388372,40.378708],[-85.277431,40.378372],[-85.220162,40.486683],[-85.409729,40.566848],[-85.446421,40.538406],[-85.446294,40.522519]]]},"properties":{"id":"47348","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.220162,40.486683],[-85.277431,40.378372],[-85.285672,40.326242],[-85.219015,40.321044],[-85.130647,40.387318],[-85.147355,40.458415],[-85.200677,40.486778],[-85.220162,40.486683]]]},"properties":{"id":"47336","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229839,39.973341],[-86.24142,40.041557],[-86.27946,40.05553],[-86.35534,39.93142],[-86.330763,39.924168],[-86.263511,39.92489],[-86.240252,39.932541],[-86.229839,39.973341]]]},"properties":{"id":"46077","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.325987,39.873492],[-86.330763,39.924168],[-86.35534,39.93142],[-86.429053,39.923658],[-86.438962,39.828004],[-86.414215,39.799136],[-86.362599,39.799979],[-86.328012,39.859269],[-86.325987,39.873492]]]},"properties":{"id":"46112","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.345434,39.277187],[-86.390692,39.339599],[-86.428242,39.310773],[-86.371993,39.223556],[-86.345434,39.277187]]]},"properties":{"id":"47468","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.760084,40.528985],[-86.694747,40.461767],[-86.617074,40.467809],[-86.591545,40.51845],[-86.582426,40.590848],[-86.525164,40.692419],[-86.525213,40.717196],[-86.620481,40.707298],[-86.65881,40.722051],[-86.748782,40.623301],[-86.771295,40.593976],[-86.760084,40.528985]],[[-86.728007,40.667521],[-86.719147,40.669616],[-86.719147,40.665847],[-86.728007,40.667521]]]},"properties":{"id":"46923","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.81262,40.64934],[-86.748782,40.623301],[-86.65881,40.722051],[-86.63921,40.88309],[-86.63945,40.89763],[-86.711726,40.912186],[-86.77585,40.91246],[-86.791636,40.898356],[-86.83388,40.80723],[-86.851407,40.712203],[-86.81262,40.64934]],[[-86.73324,40.88457],[-86.7413,40.88348],[-86.74613,40.869],[-86.75536,40.86983],[-86.74829,40.883631],[-86.73324,40.88457]]]},"properties":{"id":"47960","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.771295,40.593976],[-86.91164,40.56862],[-86.823258,40.495483],[-86.760084,40.528985],[-86.771295,40.593976]]]},"properties":{"id":"47920","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.63921,40.88309],[-86.620481,40.707298],[-86.525213,40.717196],[-86.580744,40.802656],[-86.600324,40.883762],[-86.63945,40.89763],[-86.63921,40.88309]]]},"properties":{"id":"47926","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.84763,38.549385],[-85.994634,38.412183],[-85.966323,38.377591],[-85.833168,38.433391],[-85.801602,38.495392],[-85.84763,38.549385]]]},"properties":{"id":"47106","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.745514,38.353458],[-85.751475,38.267998],[-85.633658,38.393168],[-85.719632,38.370939],[-85.745514,38.353458]]]},"properties":{"id":"47130","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.851142,39.450884],[-86.917854,39.487082],[-86.944012,39.497985],[-86.997649,39.421042],[-86.906048,39.32639],[-86.851142,39.450884]]]},"properties":{"id":"47868","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.308697,38.419542],[-86.362488,38.286979],[-86.338635,38.250202],[-86.276055,38.252121],[-86.275072,38.272761],[-86.255045,38.422685],[-86.308697,38.419542]]]},"properties":{"id":"47145","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.610046,38.395522],[-86.549543,38.306383],[-86.588544,38.411969],[-86.610046,38.395522]]]},"properties":{"id":"47175","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.751344,38.355373],[-86.774551,38.332129],[-86.771702,38.278523],[-86.764345,38.248482],[-86.641791,38.227228],[-86.641549,38.263326],[-86.659402,38.394943],[-86.681424,38.394767],[-86.751344,38.355373]]]},"properties":{"id":"47513","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.109056,39.072699],[-85.037844,39.029526],[-85.095867,39.146256],[-85.109056,39.072699]]]},"properties":{"id":"47032","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.533105,39.452614],[-85.404392,39.453129],[-85.553827,39.496685],[-85.594683,39.482933],[-85.602951,39.453315],[-85.533105,39.452614]]]},"properties":{"id":"46156","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.192565,39.381614],[-85.270655,39.361006],[-85.324858,39.247708],[-85.330586,39.206129],[-85.323183,39.206134],[-85.319411,39.20425],[-85.217399,39.197261],[-85.096686,39.310149],[-85.131124,39.38352],[-85.192565,39.381614]]]},"properties":{"id":"47006","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.099235,41.4254],[-84.979388,41.398328],[-84.943221,41.49218],[-84.96857,41.530948],[-84.99768,41.532556],[-85.128181,41.48949],[-85.099235,41.4254]]]},"properties":{"id":"46793","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.685539,40.119378],[-85.633573,40.0932],[-85.575982,40.119494],[-85.560927,40.214158],[-85.575623,40.21892],[-85.681194,40.191662],[-85.685539,40.119378]]]},"properties":{"id":"46012","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.287882,40.207868],[-85.280001,40.076587],[-85.215226,40.116874],[-85.245677,40.227279],[-85.288289,40.226791],[-85.287882,40.207868]]]},"properties":{"id":"47383","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.280001,40.076587],[-85.185642,39.997249],[-85.142429,39.981288],[-85.121648,40.004262],[-85.202745,40.091591],[-85.215226,40.116874],[-85.280001,40.076587]]]},"properties":{"id":"47354","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.403242,40.204076],[-85.409586,40.207281],[-85.410211,40.200278],[-85.403242,40.204076]]]},"properties":{"id":"47306","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.44433,40.37914],[-85.446294,40.522519],[-85.526925,40.473977],[-85.547951,40.456294],[-85.526564,40.452128],[-85.493667,40.394327],[-85.484818,40.388572],[-85.47818,40.379006],[-85.44433,40.37914]]]},"properties":{"id":"46989","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.920112,41.508391],[-85.966792,41.522006],[-86.098349,41.505017],[-86.05867,41.390087],[-86.058408,41.361191],[-85.966792,41.36147],[-85.885818,41.438846],[-85.920112,41.508391]]]},"properties":{"id":"46550","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.653089,41.345593],[-85.653598,41.431136],[-85.69782,41.47606],[-85.706705,41.481589],[-85.769717,41.496609],[-85.817149,41.434909],[-85.767596,41.349552],[-85.736732,41.322378],[-85.653089,41.345593]]]},"properties":{"id":"46567","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.08161,39.819344],[-85.221118,39.788449],[-85.101735,39.714968],[-85.08161,39.819344]]]},"properties":{"id":"47357","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.904013,39.313681],[-84.910043,39.305911],[-84.896756,39.305803],[-84.904013,39.313681]]]},"properties":{"id":"47035","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.205368,39.418066],[-85.192565,39.381614],[-85.131124,39.38352],[-85.057036,39.460254],[-85.205368,39.418066]]]},"properties":{"id":"47030","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.415133,41.143877],[-86.41511,41.139253],[-86.408135,41.137015],[-86.415133,41.143877]]]},"properties":{"id":"46922","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.96613,41.084446],[-85.95275,40.999146],[-85.926878,40.993171],[-85.780267,41.058668],[-85.96613,41.084446]]]},"properties":{"id":"46982","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.242773,38.231129],[-87.199807,38.231038],[-87.200906,38.314435],[-87.316219,38.381474],[-87.407583,38.375501],[-87.43297,38.267004],[-87.410061,38.236603],[-87.396177,38.245131],[-87.391626,38.250249],[-87.391651,38.24512],[-87.381673,38.245199],[-87.261203,38.252994],[-87.261186,38.257832],[-87.256745,38.258554],[-87.255249,38.252008],[-87.242773,38.231129]],[[-87.375616,38.278194],[-87.374209,38.272718],[-87.380291,38.272645],[-87.379987,38.281772],[-87.375616,38.278194]]]},"properties":{"id":"47660","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.381673,38.245199],[-87.355202,38.14287],[-87.33542,38.121983],[-87.206387,38.156002],[-87.242773,38.231129],[-87.255249,38.252008],[-87.261203,38.252994],[-87.381673,38.245199]]]},"properties":{"id":"47619","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.446421,40.538406],[-85.757112,40.564988],[-85.769015,40.558767],[-85.779346,40.450653],[-85.626672,40.488745],[-85.526925,40.473977],[-85.446294,40.522519],[-85.446421,40.538406]]]},"properties":{"id":"46953","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.80686,40.574454],[-85.81232,40.652072],[-85.857431,40.661876],[-85.858217,40.661683],[-85.957602,40.564613],[-85.863791,40.5223],[-85.80686,40.574454]]]},"properties":{"id":"46919","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.905042,40.232295],[-85.862758,40.1777],[-85.843749,40.177917],[-85.774459,40.277572],[-85.747627,40.306281],[-85.746916,40.371574],[-85.862364,40.392417],[-85.885745,40.291377],[-85.905042,40.232295]]]},"properties":{"id":"46036","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.683002,39.165746],[-86.682093,39.056588],[-86.682099,39.027565],[-86.550493,39.010249],[-86.548919,39.013536],[-86.544929,39.010994],[-86.537457,38.999648],[-86.534233,39.164601],[-86.683002,39.165746]]]},"properties":{"id":"47403","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.965469,39.074551],[-87.091213,39.082076],[-87.081437,39.009027],[-87.016303,38.994477],[-86.965469,39.074551]]]},"properties":{"id":"47465","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.881875,40.162901],[-85.939527,40.153206],[-86.02057,40.087408],[-86.023229,40.000205],[-85.918993,39.991052],[-85.86226,39.99214],[-85.862238,40.010719],[-85.863278,40.103861],[-85.843793,40.161372],[-85.843749,40.177917],[-85.862758,40.1777],[-85.881875,40.162901]]]},"properties":{"id":"46060","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.065166,39.956201],[-86.076484,39.926948],[-86.076183,39.92695],[-86.036593,39.927071],[-86.01898,39.941861],[-85.918993,39.991052],[-86.023229,40.000205],[-86.065166,39.956201]]]},"properties":{"id":"46038","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.862123,39.973055],[-85.86226,39.99214],[-85.918993,39.991052],[-86.01898,39.941861],[-85.939026,39.932069],[-85.917863,39.953067],[-85.862123,39.973055]]]},"properties":{"id":"46037","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.036593,39.927071],[-86.076183,39.92695],[-86.098321,39.884491],[-86.046132,39.88342],[-86.036593,39.927071]]]},"properties":{"id":"46250","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.18851,38.015392],[-86.03088,37.991841],[-86.078949,38.120968],[-86.131035,38.077116],[-86.18851,38.015392]]]},"properties":{"id":"47135","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.03277,38.410704],[-86.135212,38.448618],[-86.160471,38.42045],[-86.141984,38.355007],[-86.074156,38.353049],[-86.03277,38.410704]]]},"properties":{"id":"47164","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.221118,39.788449],[-85.08161,39.819344],[-85.079049,39.826274],[-85.117326,39.876097],[-85.200945,39.873799],[-85.255983,39.873192],[-85.271965,39.787973],[-85.221118,39.788449]],[[-85.21096,39.817581],[-85.196782,39.817578],[-85.196788,39.809832],[-85.211776,39.806295],[-85.21096,39.817581]]]},"properties":{"id":"47327","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.99578,40.564265],[-86.03188,40.477514],[-85.975506,40.406227],[-85.862117,40.406889],[-85.862398,40.435882],[-85.863791,40.5223],[-85.957602,40.564613],[-85.99578,40.564265]]]},"properties":{"id":"46936","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.336064,40.904126],[-85.452146,41.005232],[-85.538178,41.003515],[-85.643841,41.002305],[-85.642617,40.929247],[-85.64198,40.914738],[-85.573021,40.730359],[-85.563004,40.726726],[-85.390206,40.770741],[-85.336064,40.904126]]]},"properties":{"id":"46750","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.562294,40.653193],[-85.439875,40.668201],[-85.44788,40.610256],[-85.409816,40.610161],[-85.318951,40.610659],[-85.31685,40.668922],[-85.335122,40.727234],[-85.390206,40.770741],[-85.563004,40.726726],[-85.60066,40.66771],[-85.562294,40.653193]]]},"properties":{"id":"46792","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.963535,41.22182],[-86.930418,41.151036],[-86.872297,41.148192],[-86.843012,41.269084],[-86.930079,41.236798],[-86.963535,41.22182]]]},"properties":{"id":"46374","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.20285,38.842024],[-85.234382,38.913949],[-85.249875,38.957393],[-85.361597,38.986988],[-85.462199,38.851271],[-85.525555,38.833294],[-85.54236,38.835925],[-85.572928,38.768427],[-85.506689,38.727805],[-85.425758,38.732869],[-85.246283,38.731521],[-85.20285,38.842024]]]},"properties":{"id":"47250","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.518495,38.614337],[-85.428568,38.586353],[-85.428283,38.586325],[-85.425758,38.732869],[-85.506689,38.727805],[-85.518495,38.614337]]]},"properties":{"id":"47243","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.779226,39.013459],[-85.686783,39.130859],[-85.724294,39.130691],[-85.798804,39.068536],[-85.779226,39.013459]]]},"properties":{"id":"47273","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.219761,38.855339],[-87.241164,38.877215],[-87.287876,38.881879],[-87.287868,38.858752],[-87.241708,38.795135],[-87.241446,38.802619],[-87.227986,38.808016],[-87.219761,38.855339]]]},"properties":{"id":"47528","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.835399,40.968736],[-85.869592,40.913235],[-85.830942,40.89755],[-85.642617,40.929247],[-85.643841,41.002305],[-85.724148,41.101514],[-85.780267,41.058668],[-85.926878,40.993171],[-85.851214,40.976619],[-85.833132,40.977036],[-85.835399,40.968736]],[[-85.731512,41.041718],[-85.723211,41.038989],[-85.738813,41.033344],[-85.739673,41.037365],[-85.731512,41.041718]]]},"properties":{"id":"46962","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.966172,41.144139],[-85.985427,41.158234],[-85.985151,41.143735],[-85.966172,41.144139]]]},"properties":{"id":"46508","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.310489,41.689389],[-85.292724,41.700199],[-85.279689,41.685002],[-85.194994,41.663861],[-85.195023,41.667507],[-85.24998,41.759799],[-85.543599,41.759433],[-85.496597,41.697229],[-85.310489,41.689389]]]},"properties":{"id":"46746","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.356418,41.592346],[-87.296243,41.577788],[-87.297312,41.579945],[-87.287928,41.60922],[-87.357486,41.61368],[-87.357126,41.609014],[-87.356418,41.592346]]]},"properties":{"id":"46402","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.494283,41.696211],[-87.49033,41.657553],[-87.46238,41.678305],[-87.494283,41.696211]]]},"properties":{"id":"46394","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.25343,41.47101],[-87.393227,41.473491],[-87.436378,41.460724],[-87.465593,41.421125],[-87.41653,41.348018],[-87.309091,41.340081],[-87.219625,41.434595],[-87.219953,41.470702],[-87.25343,41.47101]]]},"properties":{"id":"46307","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.41653,41.348018],[-87.465593,41.421125],[-87.48943,41.42127],[-87.526129,41.399327],[-87.526729,41.312383],[-87.41653,41.348018]]]},"properties":{"id":"46303","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.583308,41.752148],[-86.581725,41.620411],[-86.536054,41.657693],[-86.583308,41.752148]]]},"properties":{"id":"46371","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.929432,41.492277],[-86.854428,41.491823],[-86.837863,41.530179],[-86.896532,41.601879],[-86.951555,41.617272],[-86.969266,41.574013],[-86.929432,41.492277]]]},"properties":{"id":"46391","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.81386,41.432776],[-86.826306,41.34646],[-86.746119,41.317144],[-86.711754,41.375345],[-86.717028,41.418818],[-86.81386,41.432776]]]},"properties":{"id":"46340","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.702569,41.528716],[-86.701841,41.521386],[-86.692033,41.52527],[-86.692432,41.531041],[-86.702569,41.528716]]]},"properties":{"id":"46345","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.401319,39.014839],[-86.495668,38.991784],[-86.308855,38.875614],[-86.401319,39.014839]]]},"properties":{"id":"47436","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.681194,40.191662],[-85.729906,40.219621],[-85.843793,40.161372],[-85.863278,40.103861],[-85.805471,40.045957],[-85.731594,40.034912],[-85.708359,40.080358],[-85.685539,40.119378],[-85.681194,40.191662]]]},"properties":{"id":"46011","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.732722,40.270188],[-85.721807,40.263739],[-85.717521,40.277011],[-85.732722,40.270188]]]},"properties":{"id":"46063","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.149249,39.761219],[-86.150176,39.722414],[-86.132609,39.722992],[-86.113111,39.723144],[-86.093003,39.733655],[-86.08242,39.69459],[-86.047114,39.7065],[-86.049758,39.75491],[-86.082971,39.760318],[-86.136863,39.762389],[-86.149224,39.761716],[-86.149249,39.761219]]]},"properties":{"id":"46203","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.954013,39.869646],[-86.010198,39.84066],[-86.009756,39.813329],[-85.952944,39.812067],[-85.953478,39.840383],[-85.954013,39.869646]]]},"properties":{"id":"46235","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.102713,39.825663],[-86.064655,39.810965],[-86.009756,39.813329],[-86.010198,39.84066],[-86.005725,39.851902],[-86.038086,39.869181],[-86.047026,39.865029],[-86.102933,39.847491],[-86.102713,39.825663]]]},"properties":{"id":"46226","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.156128,39.871131],[-86.156199,39.846796],[-86.102933,39.847491],[-86.047026,39.865029],[-86.046132,39.88342],[-86.098321,39.884491],[-86.157832,39.883082],[-86.156128,39.871131]]]},"properties":{"id":"46220","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.239071,39.764573],[-86.240098,39.823969],[-86.275806,39.823436],[-86.266848,39.76481],[-86.239071,39.764573]]]},"properties":{"id":"46224","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.135495,39.797756],[-86.102713,39.825663],[-86.102933,39.847491],[-86.156199,39.846796],[-86.157192,39.797326],[-86.135495,39.797756]]]},"properties":{"id":"46205","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.159827,39.868406],[-86.190686,39.824558],[-86.1968,39.796091],[-86.157192,39.797326],[-86.156199,39.846796],[-86.156128,39.871131],[-86.159827,39.868406]]]},"properties":{"id":"46208","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.282862,39.651099],[-86.28013,39.653731],[-86.282862,39.654779],[-86.282862,39.651099]]]},"properties":{"id":"46183","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.149224,39.761716],[-86.173921,39.766983],[-86.173202,39.764271],[-86.149249,39.761219],[-86.149224,39.761716]]]},"properties":{"id":"46204","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.155139,41.376047],[-86.274755,41.47279],[-86.29611,41.462659],[-86.295913,41.457055],[-86.329425,41.461773],[-86.329838,41.478099],[-86.368351,41.47761],[-86.466324,41.389333],[-86.473148,41.323969],[-86.466572,41.291888],[-86.347301,41.243349],[-86.192777,41.295839],[-86.155139,41.376047]]]},"properties":{"id":"46563","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.801188,38.746348],[-86.837003,38.52646],[-86.742515,38.526171],[-86.682446,38.566417],[-86.683102,38.686561],[-86.683185,38.702274],[-86.683371,38.721815],[-86.683407,38.755451],[-86.747712,38.824653],[-86.801188,38.746348]]]},"properties":{"id":"47581","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.904252,38.904204],[-86.9042,38.889539],[-86.891678,38.90421],[-86.895104,38.904209],[-86.904252,38.904204]]],[[[-86.847591,38.904307],[-86.815438,38.869446],[-86.794425,38.904367],[-86.831419,38.904325],[-86.847591,38.904307]]]]},"properties":{"id":"47522","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.05363,39.985325],[-87.057904,39.985385],[-87.057822,39.981738],[-87.05363,39.985325]]]},"properties":{"id":"47916","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.091904,40.127547],[-87.090659,39.981897],[-87.090453,39.969002],[-87.057822,39.981738],[-87.057904,39.985385],[-87.05363,39.985325],[-87.01784,40.134852],[-87.0919,40.142071],[-87.091904,40.127547]]]},"properties":{"id":"47990","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.091051,39.945655],[-87.131999,39.874813],[-87.009849,39.844833],[-86.959449,39.866264],[-86.963837,39.902656],[-87.091051,39.945655]]]},"properties":{"id":"47989","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.93629,39.866104],[-86.686836,39.853333],[-86.695287,39.908498],[-86.827345,39.946528],[-86.963837,39.902656],[-86.959449,39.866264],[-86.93629,39.866104]]]},"properties":{"id":"47954","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.53622,41.302556],[-85.46197,41.265181],[-85.346543,41.293464],[-85.346476,41.307946],[-85.309172,41.365879],[-85.370777,41.452815],[-85.491435,41.43828],[-85.51074,41.390199],[-85.53622,41.302556]]]},"properties":{"id":"46701","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.570248,38.456809],[-86.439165,38.411846],[-86.424374,38.428469],[-86.317744,38.48515],[-86.514979,38.604283],[-86.567018,38.553887],[-86.570248,38.456809]]]},"properties":{"id":"47454","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.424374,38.428469],[-86.308697,38.419542],[-86.255045,38.422685],[-86.212323,38.422093],[-86.234268,38.481428],[-86.307941,38.521239],[-86.317744,38.48515],[-86.424374,38.428469]]]},"properties":{"id":"47125","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.215682,38.499932],[-86.17254,38.74544],[-86.227109,38.777506],[-86.275281,38.763795],[-86.310058,38.733131],[-86.327141,38.688367],[-86.307941,38.521239],[-86.234268,38.481428],[-86.215682,38.499932]]]},"properties":{"id":"47108","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.780285,38.117846],[-86.716048,38.136128],[-86.646049,38.131775],[-86.607343,38.229837],[-86.641791,38.227228],[-86.764345,38.248482],[-86.786888,38.199694],[-86.780285,38.117846]]]},"properties":{"id":"47515","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.602335,38.133561],[-86.621014,38.061047],[-86.533042,38.073339],[-86.503234,38.168916],[-86.544071,38.189803],[-86.567171,38.166441],[-86.602335,38.133561]]]},"properties":{"id":"47551","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.533042,38.073339],[-86.497027,38.045883],[-86.461022,38.121087],[-86.476696,38.159706],[-86.503234,38.168916],[-86.533042,38.073339]]],[[[-86.756266,37.92116],[-86.580425,37.92312],[-86.596698,37.9839],[-86.603717,38.002045],[-86.756266,37.92116]]]]},"properties":{"id":"47520","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596698,37.9839],[-86.580425,37.92312],[-86.525098,37.968702],[-86.596698,37.9839]]]},"properties":{"id":"47574","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.090983,38.305365],[-87.173718,38.313036],[-87.200906,38.314435],[-87.199807,38.231038],[-87.181396,38.231377],[-87.109238,38.232786],[-87.073113,38.269184],[-87.090983,38.305365]]]},"properties":{"id":"47585","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.496799,41.004393],[-86.711726,40.912186],[-86.63945,40.89763],[-86.600324,40.883762],[-86.468152,40.924413],[-86.496799,41.004393]]]},"properties":{"id":"46985","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.429808,39.742581],[-85.427511,39.744373],[-85.431055,39.744404],[-85.429808,39.742581]]]},"properties":{"id":"46155","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.099889,41.675202],[-86.19645,41.660526],[-86.215241,41.661315],[-86.215742,41.650761],[-86.162066,41.564563],[-86.137748,41.550247],[-86.060521,41.586531],[-86.060611,41.593772],[-86.099889,41.675202]]]},"properties":{"id":"46544","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.055666,38.732877],[-85.883712,38.575401],[-85.873427,38.57554],[-85.821408,38.573258],[-85.773583,38.619995],[-85.673825,38.60567],[-85.670333,38.60573],[-85.682294,38.736766],[-85.721032,38.793777],[-85.896263,38.75049],[-85.992641,38.767401],[-86.025996,38.762097],[-86.055666,38.732877]]]},"properties":{"id":"47170","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.268995,37.878026],[-87.036667,37.904119],[-87.018094,37.988552],[-87.081944,37.959338],[-87.268445,37.879164],[-87.268995,37.878026]]]},"properties":{"id":"47635","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.088817,38.053118],[-87.013234,38.083175],[-87.015407,38.094374],[-87.018026,38.118283],[-87.053271,38.118222],[-87.054431,38.101133],[-87.088817,38.053118]]]},"properties":{"id":"47537","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.746119,41.317144],[-86.650272,41.172068],[-86.621304,41.172004],[-86.5171,41.229671],[-86.466572,41.291888],[-86.473148,41.323969],[-86.570525,41.360532],[-86.711754,41.375345],[-86.746119,41.317144]]]},"properties":{"id":"46534","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.920889,40.399441],[-86.746089,40.272795],[-86.695208,40.281342],[-86.695123,40.321617],[-86.694669,40.411036],[-86.694747,40.461767],[-86.760084,40.528985],[-86.823258,40.495483],[-86.867183,40.475611],[-86.878593,40.417339],[-86.898484,40.418266],[-86.920889,40.399441]],[[-86.761479,40.484487],[-86.765408,40.485857],[-86.767202,40.489403],[-86.76151,40.487387],[-86.761479,40.484487]],[[-86.771819,40.381206],[-86.768097,40.36962],[-86.780776,40.369703],[-86.785799,40.381199],[-86.771819,40.381206]]]},"properties":{"id":"47905","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.547745,37.977194],[-87.502416,37.97675],[-87.491788,38.035027],[-87.57037,38.07964],[-87.577709,38.062659],[-87.563965,37.977413],[-87.561262,37.977371],[-87.547745,37.977194]]]},"properties":{"id":"47711","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.697765,38.051762],[-87.590533,37.985562],[-87.577709,38.062659],[-87.588754,38.079883],[-87.596996,38.14358],[-87.671444,38.126781],[-87.697093,38.108575],[-87.697765,38.051762]]]},"properties":{"id":"47720","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.502416,37.97675],[-87.547745,37.977194],[-87.548221,37.941807],[-87.502416,37.97675]]]},"properties":{"id":"47714","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.462293,39.578241],[-87.455075,39.574334],[-87.445875,39.593049],[-87.462508,39.592874],[-87.462293,39.578241]]]},"properties":{"id":"47863","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.830942,40.89755],[-85.737898,40.883523],[-85.680195,40.852137],[-85.64198,40.914738],[-85.642617,40.929247],[-85.830942,40.89755]]]},"properties":{"id":"46990","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.234268,38.481428],[-86.212323,38.422093],[-86.160471,38.42045],[-86.135212,38.448618],[-86.150117,38.459584],[-86.215682,38.499932],[-86.234268,38.481428]]]},"properties":{"id":"47120","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.851936,39.744724],[-84.855757,39.737455],[-84.847769,39.737533],[-84.851936,39.744724]]]},"properties":{"id":"47324","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.16772,40.954591],[-85.147925,40.917108],[-85.076978,40.930263],[-85.085562,40.937208],[-85.136167,41.016384],[-85.164925,41.04232],[-85.16772,40.954591]]]},"properties":{"id":"46819","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.853719,41.240844],[-84.937466,41.182356],[-84.803508,41.198237],[-84.803589,41.271273],[-84.853719,41.240844]]]},"properties":{"id":"46743","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.226664,41.08628],[-85.179811,41.07467],[-85.142668,41.084027],[-85.149078,41.117864],[-85.165953,41.128985],[-85.226664,41.08628]]]},"properties":{"id":"46808","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.293301,41.104734],[-85.294363,41.103332],[-85.292255,41.102758],[-85.292018,41.103768],[-85.293301,41.104734]]]},"properties":{"id":"46704","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.870617,39.282401],[-85.866133,39.279951],[-85.866142,39.284787],[-85.870617,39.282401]]]},"properties":{"id":"47226","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.341966,40.53756],[-87.416266,40.47683],[-87.396647,40.411143],[-87.324561,40.425526],[-87.330443,40.476492],[-87.341966,40.53756]]]},"properties":{"id":"47921","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.52678,40.461952],[-87.396647,40.411143],[-87.416266,40.47683],[-87.526206,40.578767],[-87.52678,40.461952]]]},"properties":{"id":"47917","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.211799,40.837138],[-87.353912,40.822053],[-87.362777,40.736517],[-87.22986,40.693252],[-87.211799,40.837138]]]},"properties":{"id":"47948","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.27946,40.05553],[-86.31379,40.0987],[-86.35534,39.93142],[-86.27946,40.05553]]]},"properties":{"id":"46075","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.582426,40.590848],[-86.374023,40.585901],[-86.373697,40.628496],[-86.525164,40.692419],[-86.582426,40.590848]]]},"properties":{"id":"46917","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.375238,40.503357],[-86.591545,40.51845],[-86.617074,40.467809],[-86.592599,40.472296],[-86.396163,40.486743],[-86.375501,40.489007],[-86.375238,40.503357]]]},"properties":{"id":"46913","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.728007,40.667521],[-86.719147,40.665847],[-86.719147,40.669616],[-86.728007,40.667521]]]},"properties":{"id":"47997","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.63921,40.88309],[-86.65881,40.722051],[-86.620481,40.707298],[-86.63921,40.88309]]]},"properties":{"id":"47950","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.468152,40.924413],[-86.600324,40.883762],[-86.580744,40.802656],[-86.431889,40.840865],[-86.468233,40.909884],[-86.468152,40.924413]]]},"properties":{"id":"46978","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.291847,40.866105],[-86.283915,40.909422],[-86.314206,40.90937],[-86.468233,40.909884],[-86.431889,40.840865],[-86.291847,40.866105]]]},"properties":{"id":"46950","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.351421,40.568566],[-86.351243,40.566067],[-86.346702,40.565513],[-86.346633,40.569447],[-86.351421,40.568566]]]},"properties":{"id":"46998","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.633658,38.393168],[-85.467156,38.510631],[-85.504562,38.51996],[-85.637524,38.514288],[-85.724956,38.53052],[-85.73516,38.50628],[-85.735216,38.427019],[-85.719632,38.370939],[-85.633658,38.393168]]]},"properties":{"id":"47111","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.801602,38.495392],[-85.833168,38.433391],[-85.829335,38.404231],[-85.735216,38.427019],[-85.73516,38.50628],[-85.801602,38.495392]]]},"properties":{"id":"47143","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.966323,38.377591],[-85.943808,38.338919],[-85.89377,38.302062],[-85.813941,38.378771],[-85.829335,38.404231],[-85.833168,38.433391],[-85.966323,38.377591]]]},"properties":{"id":"47119","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.145239,39.387423],[-87.23957,39.427919],[-87.208607,39.302072],[-87.141625,39.358284],[-87.145239,39.387423]]]},"properties":{"id":"47846","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.17402,39.648666],[-87.17796,39.644355],[-87.183932,39.645652],[-87.180557,39.649268],[-87.176112,39.64888],[-87.175905,39.649653],[-87.308481,39.6662],[-87.382601,39.695653],[-87.382418,39.607937],[-87.402603,39.565772],[-87.226909,39.577105],[-87.143252,39.583935],[-87.17402,39.648666]]]},"properties":{"id":"47874","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.17402,39.648666],[-87.143252,39.583935],[-87.086355,39.69309],[-87.175905,39.649653],[-87.176112,39.64888],[-87.17402,39.648666]]]},"properties":{"id":"47837","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.07122,39.539266],[-87.075149,39.539543],[-87.073581,39.535899],[-87.071453,39.53641],[-87.07122,39.539266]]]},"properties":{"id":"47853","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.439165,38.411846],[-86.362488,38.286979],[-86.308697,38.419542],[-86.424374,38.428469],[-86.439165,38.411846]]]},"properties":{"id":"47140","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.979106,38.773271],[-86.998458,38.681398],[-86.961122,38.672255],[-86.975925,38.525],[-86.978271,38.502508],[-86.853386,38.510167],[-86.837003,38.52646],[-86.801188,38.746348],[-86.904123,38.868684],[-86.979106,38.773271]]]},"properties":{"id":"47553","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.962952,39.136817],[-84.870835,39.073029],[-84.820147,39.21871],[-84.902932,39.251039],[-84.962952,39.136817]]]},"properties":{"id":"47025","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.928335,39.352427],[-84.960316,39.277387],[-84.902932,39.251039],[-84.820147,39.21871],[-84.81812,39.371521],[-84.928335,39.352427]],[[-84.904013,39.313681],[-84.896756,39.305803],[-84.910043,39.305911],[-84.904013,39.313681]]]},"properties":{"id":"47060","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.982915,39.245029],[-84.996128,39.154847],[-84.962952,39.136817],[-84.902932,39.251039],[-84.960316,39.277387],[-84.982915,39.245029]]]},"properties":{"id":"47022","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.324858,39.247708],[-85.270655,39.361006],[-85.29687,39.410557],[-85.297575,39.453275],[-85.404392,39.453129],[-85.533105,39.452614],[-85.684494,39.364848],[-85.684515,39.350049],[-85.686049,39.297115],[-85.669281,39.211359],[-85.440478,39.182776],[-85.324858,39.247708]],[[-85.335283,39.311218],[-85.325888,39.311188],[-85.325919,39.306709],[-85.335283,39.311218]]]},"properties":{"id":"47240","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.803971,41.324942],[-84.804484,41.500348],[-84.943221,41.49218],[-84.979388,41.398328],[-84.906793,41.341544],[-84.803971,41.324942]]]},"properties":{"id":"46721","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.443491,40.298962],[-85.532249,40.249432],[-85.44268,40.178379],[-85.403389,40.185287],[-85.411492,40.185241],[-85.410211,40.200278],[-85.409586,40.207281],[-85.403242,40.204076],[-85.443491,40.298962]]]},"properties":{"id":"47304","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.375467,40.076381],[-85.333818,40.063121],[-85.280001,40.076587],[-85.287882,40.207868],[-85.368695,40.191698],[-85.403389,40.185287],[-85.44268,40.178379],[-85.475042,40.120591],[-85.461453,40.076797],[-85.442547,40.076686],[-85.394453,40.076462],[-85.38571,40.090897],[-85.375467,40.076381]]]},"properties":{"id":"47302","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.576696,40.291737],[-85.557937,40.276773],[-85.532249,40.249432],[-85.443491,40.298962],[-85.482869,40.378848],[-85.578589,40.379524],[-85.576696,40.291737]]]},"properties":{"id":"47342","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.849399,38.354642],[-86.855462,38.277648],[-86.771702,38.278523],[-86.774551,38.332129],[-86.793087,38.367071],[-86.849399,38.354642]]]},"properties":{"id":"47575","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.742515,38.526171],[-86.681773,38.436815],[-86.681424,38.394767],[-86.659402,38.394943],[-86.610046,38.395522],[-86.588544,38.411969],[-86.570248,38.456809],[-86.567018,38.553887],[-86.682446,38.566417],[-86.742515,38.526171]]]},"properties":{"id":"47432","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.942519,38.20293],[-86.904612,38.138694],[-86.845743,38.118002],[-86.836862,38.117961],[-86.786888,38.199694],[-86.764345,38.248482],[-86.771702,38.278523],[-86.855462,38.277648],[-86.942519,38.20293]]]},"properties":{"id":"47532","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.29961,40.254967],[-87.310121,40.211449],[-87.155244,40.208242],[-87.148622,40.207442],[-87.092211,40.204892],[-87.092563,40.214806],[-87.092546,40.236637],[-87.092148,40.366612],[-87.178034,40.410426],[-87.315693,40.418266],[-87.29961,40.254967]]]},"properties":{"id":"47918","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.817717,39.394563],[-84.818303,39.393952],[-84.817647,39.391816],[-84.817717,39.394563]]]},"properties":{"id":"45053","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.5171,41.229671],[-86.467073,41.162287],[-86.415133,41.143877],[-86.408135,41.137015],[-86.346698,41.17204],[-86.347301,41.243349],[-86.466572,41.291888],[-86.5171,41.229671]]]},"properties":{"id":"46511","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.114842,40.996346],[-86.207546,40.909186],[-86.167873,40.880564],[-85.982085,40.913191],[-85.980375,40.998926],[-86.114842,40.996346]]]},"properties":{"id":"46951","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.407327,38.43632],[-87.462892,38.533519],[-87.596294,38.453512],[-87.421376,38.436215],[-87.407327,38.43632]]]},"properties":{"id":"47640","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.57037,38.07964],[-87.491788,38.035027],[-87.470688,38.035023],[-87.472955,38.064314],[-87.467212,38.169568],[-87.596996,38.14358],[-87.588754,38.079883],[-87.57037,38.07964]]]},"properties":{"id":"47725","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.57833,40.412356],[-85.786757,40.450634],[-85.862398,40.435882],[-85.862117,40.406889],[-85.862364,40.392417],[-85.746916,40.371574],[-85.578589,40.379524],[-85.482869,40.378848],[-85.47818,40.379006],[-85.484818,40.388572],[-85.496822,40.382401],[-85.507466,40.388124],[-85.493667,40.394327],[-85.526564,40.452128],[-85.568766,40.412157],[-85.568995,40.407385],[-85.578403,40.40841],[-85.57833,40.412356]]]},"properties":{"id":"46928","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.863791,40.5223],[-85.862398,40.435882],[-85.786757,40.450634],[-85.779346,40.450653],[-85.769015,40.558767],[-85.769034,40.561192],[-85.80686,40.574454],[-85.863791,40.5223]]]},"properties":{"id":"46986","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.738656,38.935421],[-86.729398,38.904532],[-86.683006,38.883213],[-86.669127,38.880455],[-86.553471,38.912061],[-86.546948,38.918863],[-86.521068,38.991991],[-86.537457,38.999648],[-86.544929,39.010994],[-86.550493,39.010249],[-86.682099,39.027565],[-86.738656,38.935421]]]},"properties":{"id":"47462","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.02057,40.087408],[-85.939527,40.153206],[-86.128254,40.131148],[-86.128083,40.094532],[-86.116181,40.091903],[-86.02057,40.087408]]]},"properties":{"id":"46034","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.937587,39.927137],[-85.939026,39.932069],[-86.01898,39.941861],[-86.036593,39.927071],[-86.046132,39.88342],[-86.047026,39.865029],[-86.038086,39.869181],[-85.997452,39.891032],[-85.937587,39.927137]]]},"properties":{"id":"46256","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.548981,39.698839],[-85.484275,39.742946],[-85.587282,39.786641],[-85.625551,39.78623],[-85.652033,39.71148],[-85.61504,39.704235],[-85.548981,39.698839]]]},"properties":{"id":"46115","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.414215,39.799136],[-86.442688,39.721686],[-86.345416,39.728782],[-86.326786,39.763774],[-86.362599,39.799979],[-86.414215,39.799136]]]},"properties":{"id":"46123","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.468739,39.546766],[-86.250589,39.591046],[-86.250133,39.604046],[-86.249885,39.633645],[-86.252024,39.633543],[-86.35552,39.631485],[-86.437289,39.663726],[-86.479633,39.60078],[-86.468739,39.546766]]]},"properties":{"id":"46158","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.429123,39.802958],[-85.445552,39.802097],[-85.437851,39.799539],[-85.429123,39.802958]]]},"properties":{"id":"47337","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.387417,40.004047],[-85.382939,40.002667],[-85.382928,40.005035],[-85.386123,40.006287],[-85.387417,40.004047]]]},"properties":{"id":"47361","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.573021,40.730359],[-85.64198,40.914738],[-85.680195,40.852137],[-85.640221,40.757495],[-85.639902,40.740506],[-85.573021,40.730359]]]},"properties":{"id":"46702","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.795913,38.867573],[-85.948535,38.823006],[-85.992641,38.767401],[-85.896263,38.75049],[-85.758219,38.814548],[-85.748156,38.820079],[-85.795913,38.867573]]]},"properties":{"id":"47229","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.301464,41.130119],[-87.352587,41.013524],[-87.314833,40.984065],[-87.179797,41.12977],[-87.301464,41.130119]]]},"properties":{"id":"47943","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.276554,40.925983],[-87.520235,40.851805],[-87.353912,40.822053],[-87.211799,40.837138],[-87.207129,40.857662],[-87.276554,40.925983]]]},"properties":{"id":"47922","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.537813,38.897373],[-85.777785,38.911036],[-85.796082,38.884531],[-85.645375,38.828825],[-85.54236,38.835925],[-85.525555,38.833294],[-85.537813,38.897373]]]},"properties":{"id":"47227","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.20285,38.842024],[-85.246283,38.731521],[-84.990102,38.778728],[-84.984555,38.86543],[-84.987978,38.875685],[-85.098676,38.903011],[-85.13636,38.903183],[-85.18415,38.870986],[-85.20285,38.842024]]]},"properties":{"id":"47043","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.61481,38.988075],[-85.61266,38.980517],[-85.60413,38.985324],[-85.61481,38.988075]]]},"properties":{"id":"47282","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.970975,41.326293],[-85.708157,41.290813],[-85.666592,41.301879],[-85.736732,41.322378],[-85.767596,41.349552],[-85.955683,41.350667],[-85.970975,41.326293]]]},"properties":{"id":"46538","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.058408,41.361191],[-86.026197,41.21704],[-85.997265,41.217415],[-85.998516,41.267247],[-85.970975,41.326293],[-85.955683,41.350667],[-85.966792,41.36147],[-86.058408,41.361191]]]},"properties":{"id":"46524","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.481636,41.568853],[-85.460395,41.525433],[-85.316486,41.517794],[-85.194032,41.517324],[-85.194022,41.517991],[-85.214079,41.581421],[-85.481636,41.568853]],[[-85.271607,41.533621],[-85.271549,41.531155],[-85.274473,41.532635],[-85.271607,41.533621]]]},"properties":{"id":"46795","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.24998,41.759799],[-85.195023,41.667507],[-85.084141,41.748382],[-85.090099,41.75967],[-85.24998,41.759799]]]},"properties":{"id":"46776","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.279689,41.685002],[-85.292724,41.700199],[-85.310489,41.689389],[-85.279689,41.685002]]]},"properties":{"id":"46771","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.480318,41.523084],[-87.451759,41.522909],[-87.432538,41.566367],[-87.43254,41.566603],[-87.466683,41.569693],[-87.480436,41.567444],[-87.480318,41.523084]]]},"properties":{"id":"46322","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526539,41.189335],[-87.526587,41.16609],[-87.446963,41.174354],[-87.526539,41.189335]]]},"properties":{"id":"46376","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.394719,41.759894],[-86.583251,41.759526],[-86.583308,41.752148],[-86.536054,41.657693],[-86.487029,41.642125],[-86.414281,41.650172],[-86.404314,41.686581],[-86.394719,41.759894]]]},"properties":{"id":"46552","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.752981,41.759963],[-86.896532,41.601879],[-86.837863,41.530179],[-86.695317,41.491313],[-86.582024,41.489833],[-86.582375,41.56264],[-86.581725,41.620411],[-86.583308,41.752148],[-86.583251,41.759526],[-86.752981,41.759963]],[[-86.702569,41.528716],[-86.692432,41.531041],[-86.692033,41.52527],[-86.701841,41.521386],[-86.702569,41.528716]]]},"properties":{"id":"46350","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.826306,41.34646],[-86.81386,41.432776],[-86.854428,41.491823],[-86.929432,41.492277],[-86.930296,41.376012],[-86.826306,41.34646]]]},"properties":{"id":"46390","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.683185,38.702274],[-86.669937,38.71254],[-86.683371,38.721815],[-86.683185,38.702274]]]},"properties":{"id":"47437","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.113111,39.723144],[-86.08242,39.69459],[-86.093003,39.733655],[-86.113111,39.723144]]]},"properties":{"id":"46107","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.005725,39.851902],[-85.997452,39.891032],[-86.038086,39.869181],[-86.005725,39.851902]]]},"properties":{"id":"46216","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.347301,41.243349],[-86.346698,41.17204],[-86.152832,41.172283],[-86.178366,41.259576],[-86.192777,41.295839],[-86.347301,41.243349]]]},"properties":{"id":"46501","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.274755,41.47279],[-86.155139,41.376047],[-86.05867,41.390087],[-86.098349,41.505017],[-86.137748,41.550247],[-86.162066,41.564563],[-86.244704,41.564439],[-86.274755,41.47279]],[[-86.176186,41.524646],[-86.176264,41.527938],[-86.156935,41.527934],[-86.156883,41.524743],[-86.176186,41.524646]]]},"properties":{"id":"46506","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.318147,41.549653],[-86.329838,41.478099],[-86.329425,41.461773],[-86.29611,41.462659],[-86.274755,41.47279],[-86.244704,41.564439],[-86.318147,41.549653]]]},"properties":{"id":"46536","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.835399,40.968736],[-85.851214,40.976619],[-85.926878,40.993171],[-85.95275,40.999146],[-85.980375,40.998926],[-85.982085,40.913191],[-85.943739,40.896344],[-85.869592,40.913235],[-85.835399,40.968736]]]},"properties":{"id":"46974","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.371262,39.546539],[-86.371256,39.537489],[-86.369177,39.539046],[-86.369234,39.542958],[-86.371262,39.546539]]]},"properties":{"id":"46111","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.352587,41.013524],[-87.526411,41.05879],[-87.525787,40.851792],[-87.520235,40.851805],[-87.276554,40.925983],[-87.29506,40.955005],[-87.295088,40.949206],[-87.302239,40.94925],[-87.30219,40.955029],[-87.295788,40.955005],[-87.314833,40.984065],[-87.352587,41.013524]]]},"properties":{"id":"47963","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.467459,41.516855],[-85.385278,41.474835],[-85.316486,41.517794],[-85.460395,41.525433],[-85.467768,41.525397],[-85.467459,41.516855]]]},"properties":{"id":"46784","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.176112,39.64888],[-87.180557,39.649268],[-87.183932,39.645652],[-87.17796,39.644355],[-87.17402,39.648666],[-87.176112,39.64888]]]},"properties":{"id":"47836","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.567171,38.166441],[-86.604906,38.133522],[-86.602335,38.133561],[-86.567171,38.166441]]]},"properties":{"id":"47514","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.255249,38.252008],[-87.256745,38.258554],[-87.261186,38.257832],[-87.261203,38.252994],[-87.255249,38.252008]]]},"properties":{"id":"47584","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.066473,41.564451],[-86.969266,41.574013],[-86.951555,41.617272],[-86.99512,41.659124],[-87.009872,41.690053],[-87.144914,41.619152],[-87.124582,41.586463],[-87.066473,41.564451]]]},"properties":{"id":"46304","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.77585,40.91246],[-86.803693,40.91249],[-86.791636,40.898356],[-86.77585,40.91246]]],[[[-86.77585,40.91246],[-86.711726,40.912186],[-86.496799,41.004393],[-86.473906,41.084273],[-86.621304,41.172004],[-86.650272,41.172068],[-86.797822,41.128663],[-86.796174,41.05633],[-86.77585,40.91246]]]]},"properties":{"id":"46996","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.686836,39.853333],[-86.93629,39.866104],[-86.937368,39.792988],[-86.686795,39.790647],[-86.686836,39.853333]]]},"properties":{"id":"46172","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.93727,39.77577],[-86.76757,39.732143],[-86.726735,39.732094],[-86.686814,39.776111],[-86.686795,39.790647],[-86.937368,39.792988],[-86.93727,39.77577]]]},"properties":{"id":"46105","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.882925,40.106655],[-85.053349,40.077395],[-85.068456,40.026902],[-84.967324,40.005226],[-84.810825,40.005077],[-84.809737,40.048911],[-84.882925,40.106655]]]},"properties":{"id":"47355","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.16524,40.106426],[-85.072601,40.092873],[-85.083118,40.250389],[-85.195632,40.291144],[-85.204674,40.264051],[-85.16524,40.106426]]]},"properties":{"id":"47340","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.070913,39.990147],[-85.053057,39.938452],[-84.996177,39.917936],[-84.912121,39.922073],[-84.967324,40.005226],[-85.068456,40.026902],[-85.070913,39.990147]]]},"properties":{"id":"47393","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.61504,39.704235],[-85.628734,39.611174],[-85.594049,39.598628],[-85.548981,39.698839],[-85.61504,39.704235]]]},"properties":{"id":"46104","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.250593,41.687893],[-86.236911,41.667336],[-86.21644,41.694489],[-86.235514,41.692937],[-86.262678,41.694558],[-86.250593,41.687893]]]},"properties":{"id":"46617","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.217541,41.76043],[-86.272495,41.760402],[-86.267208,41.722613],[-86.235514,41.692937],[-86.21644,41.694489],[-86.217319,41.745447],[-86.217541,41.76043]]]},"properties":{"id":"46637","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.630613,39.519417],[-85.651763,39.614608],[-85.786747,39.639997],[-85.809949,39.640151],[-85.87649,39.568943],[-85.925266,39.52361],[-85.93982,39.509567],[-85.908263,39.459104],[-85.846268,39.384041],[-85.750741,39.416954],[-85.630613,39.519417]]]},"properties":{"id":"46176","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.365037,38.016394],[-87.264762,37.929194],[-87.132035,38.002876],[-87.140989,38.02848],[-87.206387,38.156002],[-87.33542,38.121983],[-87.365037,38.016394]]]},"properties":{"id":"47601","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.541092,41.389531],[-86.570525,41.360532],[-86.473148,41.323969],[-86.466324,41.389333],[-86.541092,41.389531]]]},"properties":{"id":"46531","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.771819,40.381206],[-86.785799,40.381199],[-86.780776,40.369703],[-86.768097,40.36962],[-86.771819,40.381206]]]},"properties":{"id":"47941","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.46281,39.607536],[-87.463061,39.621842],[-87.439464,39.622473],[-87.437248,39.607636],[-87.420228,39.600624],[-87.417733,39.60157],[-87.382418,39.607937],[-87.382601,39.695653],[-87.382139,39.724867],[-87.450765,39.767973],[-87.532989,39.759846],[-87.532194,39.607306],[-87.46281,39.607536]]]},"properties":{"id":"47842","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.42652,39.484536],[-87.385427,39.484362],[-87.351703,39.484465],[-87.41362,39.514436],[-87.42652,39.484536]]]},"properties":{"id":"47804","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.532194,39.607306],[-87.531646,39.347888],[-87.42552,39.448336],[-87.42652,39.484536],[-87.41362,39.514436],[-87.402603,39.565772],[-87.382418,39.607937],[-87.417733,39.60157],[-87.417686,39.599257],[-87.419537,39.597831],[-87.420228,39.600624],[-87.437248,39.607636],[-87.46281,39.607536],[-87.532194,39.607306]],[[-87.460496,39.50429],[-87.46662,39.51071],[-87.454034,39.520023],[-87.460496,39.50429]],[[-87.462293,39.578241],[-87.462508,39.592874],[-87.445875,39.593049],[-87.455075,39.574334],[-87.462293,39.578241]]]},"properties":{"id":"47885","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.737898,40.883523],[-85.640221,40.757495],[-85.680195,40.852137],[-85.737898,40.883523]]]},"properties":{"id":"46941","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.117326,39.876097],[-85.079049,39.826274],[-84.994617,39.863347],[-84.996177,39.917936],[-85.053057,39.938452],[-85.107216,39.917438],[-85.117326,39.876097]]]},"properties":{"id":"47345","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.21096,39.817581],[-85.211776,39.806295],[-85.196788,39.809832],[-85.196782,39.817578],[-85.21096,39.817581]]]},"properties":{"id":"47335","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.280685,40.911611],[-85.284178,40.917044],[-85.291573,40.908281],[-85.28774,40.907291],[-85.280685,40.911611]]]},"properties":{"id":"46799","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.802391,40.586596],[-85.126038,40.567651],[-85.163144,40.56736],[-85.071854,40.524972],[-84.802667,40.528232],[-84.802391,40.586596]]]},"properties":{"id":"47326","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.135084,41.265136],[-85.086633,41.265527],[-85.020002,41.259115],[-84.973073,41.296401],[-84.906793,41.341544],[-84.979388,41.398328],[-85.099235,41.4254],[-85.155654,41.395319],[-85.155418,41.380678],[-85.135084,41.265136]]]},"properties":{"id":"46706","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.484179,39.17386],[-85.440478,39.182776],[-85.669281,39.211359],[-85.697906,39.159999],[-85.724294,39.130691],[-85.686783,39.130859],[-85.484179,39.17386]]]},"properties":{"id":"47283","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.081082,40.854686],[-87.207129,40.857662],[-87.211799,40.837138],[-87.22986,40.693252],[-87.097025,40.635465],[-87.097393,40.663844],[-87.081082,40.854686]]]},"properties":{"id":"47977","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.315693,40.418266],[-87.178034,40.410426],[-87.178663,40.489656],[-87.330443,40.476492],[-87.324561,40.425526],[-87.315693,40.418266]]]},"properties":{"id":"47975","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.24142,40.041557],[-86.128083,40.094532],[-86.128254,40.131148],[-86.137651,40.189149],[-86.175948,40.216725],[-86.185352,40.216601],[-86.213832,40.216243],[-86.3459,40.16411],[-86.31379,40.0987],[-86.27946,40.05553],[-86.24142,40.041557]]]},"properties":{"id":"46069","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.251308,39.473907],[-86.390692,39.339599],[-86.345434,39.277187],[-86.190427,39.303711],[-86.186172,39.441015],[-86.18681,39.46119],[-86.251308,39.473907]]]},"properties":{"id":"46160","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.58696,40.387938],[-86.525074,40.431259],[-86.592599,40.472296],[-86.617074,40.467809],[-86.694747,40.461767],[-86.694669,40.411036],[-86.58696,40.387938]]]},"properties":{"id":"46065","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.291847,40.866105],[-86.205926,40.844392],[-86.178275,40.851604],[-86.167873,40.880564],[-86.207546,40.909186],[-86.283915,40.909422],[-86.291847,40.866105]]]},"properties":{"id":"46988","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.724956,38.53052],[-85.686077,38.566918],[-85.718026,38.599453],[-85.821408,38.573258],[-85.873427,38.57554],[-85.84763,38.549385],[-85.801602,38.495392],[-85.73516,38.50628],[-85.724956,38.53052]]]},"properties":{"id":"47126","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.724956,38.53052],[-85.637524,38.514288],[-85.686077,38.566918],[-85.724956,38.53052]]]},"properties":{"id":"47163","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.504562,38.51996],[-85.542103,38.553724],[-85.648106,38.605643],[-85.670333,38.60573],[-85.673825,38.60567],[-85.718026,38.599453],[-85.686077,38.566918],[-85.637524,38.514288],[-85.504562,38.51996]]]},"properties":{"id":"47141","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.428568,38.586353],[-85.542103,38.553724],[-85.504562,38.51996],[-85.467156,38.510631],[-85.423038,38.532299],[-85.421548,38.538265],[-85.417813,38.540238],[-85.428283,38.586325],[-85.428568,38.586353]]]},"properties":{"id":"47162","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.648106,38.605643],[-85.542103,38.553724],[-85.428568,38.586353],[-85.518495,38.614337],[-85.648106,38.605643]]]},"properties":{"id":"47147","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.197442,39.487681],[-87.202117,39.484913],[-87.189189,39.479485],[-87.18885,39.487009],[-87.197442,39.487681]]]},"properties":{"id":"47881","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.044259,39.342508],[-87.141625,39.358284],[-87.208607,39.302072],[-87.208606,39.301579],[-87.155502,39.213332],[-87.066363,39.168494],[-87.036826,39.320595],[-87.044259,39.342508]]]},"properties":{"id":"47841","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.356799,40.431848],[-86.375506,40.402749],[-86.318472,40.330397],[-86.252271,40.323075],[-86.242986,40.357117],[-86.356799,40.431848]]]},"properties":{"id":"46039","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.375506,40.402749],[-86.413235,40.286696],[-86.318472,40.330397],[-86.375506,40.402749]]]},"properties":{"id":"46057","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.109056,39.072699],[-85.205702,39.056219],[-85.130102,38.960611],[-85.120057,38.939607],[-85.099477,38.908681],[-85.044175,38.903],[-84.973605,38.952801],[-85.037844,39.029526],[-85.109056,39.072699]]]},"properties":{"id":"47018","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.095867,39.146256],[-85.055839,39.177163],[-85.187157,39.137204],[-85.245257,39.074617],[-85.205702,39.056219],[-85.109056,39.072699],[-85.095867,39.146256]]]},"properties":{"id":"47031","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.131124,39.38352],[-85.096686,39.310149],[-84.982915,39.245029],[-84.960316,39.277387],[-84.928335,39.352427],[-84.81741,39.406398],[-84.815749,39.47945],[-84.87238,39.523453],[-84.891381,39.523707],[-85.004844,39.524228],[-85.120347,39.519403],[-85.057036,39.460254],[-85.131124,39.38352]]]},"properties":{"id":"47012","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.230787,41.274194],[-85.182909,41.270832],[-85.174123,41.336693],[-85.346476,41.307946],[-85.346543,41.293464],[-85.230787,41.274194]]]},"properties":{"id":"46763","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.443491,40.298962],[-85.403242,40.204076],[-85.410211,40.200278],[-85.411492,40.185241],[-85.368695,40.191698],[-85.287882,40.207868],[-85.288289,40.226791],[-85.330657,40.291646],[-85.388372,40.378708],[-85.44433,40.37914],[-85.47818,40.379006],[-85.482869,40.378848],[-85.443491,40.298962]]]},"properties":{"id":"47303","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.215226,40.116874],[-85.202745,40.091591],[-85.16524,40.106426],[-85.204674,40.264051],[-85.245677,40.227279],[-85.215226,40.116874]]]},"properties":{"id":"47368","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.920112,41.508391],[-85.769717,41.496609],[-85.706705,41.481589],[-85.757371,41.508491],[-85.906669,41.6267],[-86.001912,41.562269],[-85.966792,41.522006],[-85.920112,41.508391]]]},"properties":{"id":"46526","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.051867,41.760429],[-86.217541,41.76043],[-86.217319,41.745447],[-86.191073,41.722119],[-86.095963,41.724504],[-86.048763,41.713952],[-86.051867,41.760429]]]},"properties":{"id":"46530","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.035082,39.635124],[-84.96117,39.726772],[-84.965352,39.726747],[-85.053292,39.714819],[-85.035082,39.635124]]]},"properties":{"id":"47325","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.352585,39.763557],[-85.300656,39.663734],[-85.301382,39.758631],[-85.352585,39.763557]]]},"properties":{"id":"46127","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.310121,40.211449],[-87.355314,40.02148],[-87.175957,40.026029],[-87.091904,40.127547],[-87.0919,40.142071],[-87.092211,40.204892],[-87.148622,40.207442],[-87.14401,40.203641],[-87.155261,40.205347],[-87.155244,40.208242],[-87.310121,40.211449]],[[-87.151732,40.165233],[-87.129592,40.167427],[-87.129586,40.164247],[-87.150563,40.159025],[-87.151732,40.165233]]]},"properties":{"id":"47987","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.175957,40.026029],[-87.090659,39.981897],[-87.091904,40.127547],[-87.175957,40.026029]]]},"properties":{"id":"47949","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.29687,39.410557],[-85.270655,39.361006],[-85.192565,39.381614],[-85.205368,39.418066],[-85.29687,39.410557]]]},"properties":{"id":"47036","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.268531,40.948634],[-86.264205,40.94484],[-86.262691,40.947206],[-86.268531,40.948634]]]},"properties":{"id":"46931","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.501189,38.400829],[-87.719019,38.392528],[-87.724037,38.381137],[-87.687176,38.336961],[-87.622773,38.289039],[-87.484438,38.274338],[-87.501189,38.400829]]]},"properties":{"id":"47670","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.410061,38.236603],[-87.43297,38.267004],[-87.484438,38.274338],[-87.622773,38.289039],[-87.652674,38.208581],[-87.647419,38.189221],[-87.466545,38.223217],[-87.410061,38.236603]]]},"properties":{"id":"47648","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.596294,38.453512],[-87.743691,38.414387],[-87.752735,38.400412],[-87.719019,38.392528],[-87.501189,38.400829],[-87.421376,38.436215],[-87.596294,38.453512]]]},"properties":{"id":"47666","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.410061,38.236603],[-87.466545,38.223217],[-87.467212,38.169568],[-87.472955,38.064314],[-87.355202,38.14287],[-87.381673,38.245199],[-87.391651,38.24512],[-87.396177,38.245131],[-87.410061,38.236603]]]},"properties":{"id":"47613","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.786757,40.450634],[-85.57833,40.412356],[-85.568766,40.412157],[-85.526564,40.452128],[-85.547951,40.456294],[-85.626672,40.488745],[-85.779346,40.450653],[-85.786757,40.450634]]]},"properties":{"id":"46938","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.639902,40.740506],[-85.829354,40.67321],[-85.826986,40.663744],[-85.835099,40.663714],[-85.833382,40.672536],[-85.857431,40.661876],[-85.81232,40.652072],[-85.60066,40.66771],[-85.563004,40.726726],[-85.573021,40.730359],[-85.639902,40.740506]]]},"properties":{"id":"46940","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.023229,40.000205],[-86.108718,40.022163],[-86.12928,40.014698],[-86.112427,39.934152],[-86.100506,39.926884],[-86.094801,39.926881],[-86.065166,39.956201],[-86.023229,40.000205]]]},"properties":{"id":"46033","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.952247,39.763125],[-85.951735,39.697049],[-85.942721,39.682502],[-85.925627,39.682725],[-85.838295,39.710758],[-85.933333,39.775164],[-85.952247,39.763125]]]},"properties":{"id":"46163","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.22247,38.115995],[-86.194904,38.106468],[-86.157202,38.111362],[-86.164047,38.085286],[-86.131035,38.077116],[-86.078949,38.120968],[-86.050775,38.163269],[-86.053165,38.163253],[-86.053144,38.166155],[-86.047704,38.163279],[-85.975764,38.185829],[-86.001803,38.264181],[-86.066618,38.285845],[-86.0879,38.282622],[-86.137389,38.287833],[-86.180987,38.267943],[-86.275072,38.272761],[-86.276055,38.252121],[-86.3296,38.181805],[-86.277116,38.146034],[-86.22247,38.115995]]]},"properties":{"id":"47112","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.686795,39.790647],[-86.686814,39.776111],[-86.57673,39.834428],[-86.610901,39.8937],[-86.695193,39.915707],[-86.695287,39.908498],[-86.686836,39.853333],[-86.686795,39.790647]]]},"properties":{"id":"46165","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.543033,39.834268],[-86.438962,39.828004],[-86.429053,39.923658],[-86.506028,39.922912],[-86.543033,39.834268]]]},"properties":{"id":"46167","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.437289,39.663726],[-86.35552,39.631485],[-86.342372,39.654026],[-86.345416,39.728782],[-86.442688,39.721686],[-86.473086,39.708857],[-86.437289,39.663726]]]},"properties":{"id":"46168","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.345416,39.728782],[-86.342372,39.654026],[-86.326584,39.671795],[-86.28903,39.763488],[-86.310698,39.764047],[-86.326786,39.763774],[-86.345416,39.728782]]]},"properties":{"id":"46231","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.325987,39.873492],[-86.259892,39.867159],[-86.263511,39.92489],[-86.330763,39.924168],[-86.325987,39.873492]]]},"properties":{"id":"46278","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.604826,39.663538],[-86.657002,39.571563],[-86.665156,39.538086],[-86.603712,39.542991],[-86.575641,39.60091],[-86.604826,39.663538]]]},"properties":{"id":"46180","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.447748,39.799743],[-85.427697,39.758034],[-85.352585,39.763557],[-85.301382,39.758631],[-85.301584,39.787579],[-85.362388,39.845586],[-85.410311,39.827374],[-85.429123,39.802958],[-85.437851,39.799539],[-85.445552,39.802097],[-85.447748,39.799743]]]},"properties":{"id":"47352","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.362388,39.845586],[-85.301584,39.787579],[-85.271965,39.787973],[-85.255983,39.873192],[-85.362388,39.845586]]]},"properties":{"id":"47387","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.323073,40.549726],[-86.375238,40.503357],[-86.375501,40.489007],[-86.193221,40.465709],[-86.03188,40.477514],[-85.99578,40.564265],[-86.032699,40.607501],[-86.165266,40.605145],[-86.323073,40.549726]]]},"properties":{"id":"46901","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.308855,38.875614],[-86.276811,38.846187],[-86.192829,38.904533],[-86.200751,38.961857],[-86.19389,39.048559],[-86.205848,39.048541],[-86.318133,39.04881],[-86.401319,39.014839],[-86.308855,38.875614]]]},"properties":{"id":"47264","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.375098,41.174125],[-87.301464,41.130119],[-87.179797,41.12977],[-87.141771,41.144191],[-87.145863,41.281153],[-87.290734,41.211531],[-87.340657,41.182566],[-87.333538,41.173434],[-87.31438,41.173482],[-87.324979,41.159462],[-87.342337,41.182508],[-87.353785,41.179971],[-87.375098,41.174125]]]},"properties":{"id":"46310","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.930759,40.912418],[-86.979519,41.046901],[-86.969119,41.071343],[-87.141771,41.144191],[-87.179797,41.12977],[-87.314833,40.984065],[-87.295788,40.955005],[-87.29506,40.955005],[-87.276554,40.925983],[-87.207129,40.857662],[-87.081082,40.854686],[-87.042682,40.852479],[-86.930759,40.912418]]]},"properties":{"id":"47978","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.865711,40.377261],[-84.862323,40.386206],[-84.871382,40.386436],[-84.865711,40.377261]]]},"properties":{"id":"47381","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.93982,39.509567],[-85.980129,39.57591],[-86.00883,39.58161],[-86.158751,39.558696],[-86.18681,39.46119],[-86.186172,39.441015],[-86.079335,39.388271],[-86.064052,39.373284],[-86.027856,39.38938],[-85.908263,39.459104],[-85.93982,39.509567]]]},"properties":{"id":"46131","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.10629,39.63579],[-86.250133,39.604046],[-86.250589,39.591046],[-86.239106,39.554989],[-86.15879,39.56304],[-86.00883,39.58161],[-85.980129,39.57591],[-85.971477,39.601314],[-86.044638,39.637224],[-86.10152,39.63585],[-86.10629,39.63579]]]},"properties":{"id":"46143","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.158751,39.558696],[-86.00883,39.58161],[-86.15879,39.56304],[-86.158751,39.558696]]]},"properties":{"id":"46184","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.262455,38.7289],[-87.241708,38.795135],[-87.287868,38.858752],[-87.33423,38.859492],[-87.393118,38.811638],[-87.351978,38.728684],[-87.34475,38.710565],[-87.262455,38.7289]]]},"properties":{"id":"47512","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.998516,41.267247],[-85.765367,41.221279],[-85.708157,41.290813],[-85.970975,41.326293],[-85.998516,41.267247]]]},"properties":{"id":"46582","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.978293,41.259663],[-85.975257,41.258589],[-85.975383,41.261716],[-85.978358,41.261824],[-85.978293,41.259663]]]},"properties":{"id":"46502","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.155139,41.376047],[-86.192777,41.295839],[-86.178366,41.259576],[-86.054542,41.196404],[-86.026197,41.21704],[-86.058408,41.361191],[-86.05867,41.390087],[-86.155139,41.376047]]]},"properties":{"id":"46504","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.650721,41.759095],[-85.656718,41.610538],[-85.656243,41.594087],[-85.617796,41.596443],[-85.560454,41.633029],[-85.496597,41.697229],[-85.543599,41.759433],[-85.650721,41.759095]]]},"properties":{"id":"46565","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.560454,41.633029],[-85.617796,41.596443],[-85.616548,41.534943],[-85.467768,41.525397],[-85.460395,41.525433],[-85.481636,41.568853],[-85.560454,41.633029]]]},"properties":{"id":"46571","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.357126,41.609014],[-87.393842,41.559722],[-87.356118,41.569108],[-87.356418,41.592346],[-87.357126,41.609014]]]},"properties":{"id":"46404","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.413098,41.55902],[-87.432538,41.566367],[-87.451759,41.522909],[-87.393337,41.507636],[-87.389408,41.522611],[-87.413098,41.55902]]]},"properties":{"id":"46319","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.309091,41.340081],[-87.290734,41.211531],[-87.145863,41.281153],[-87.102293,41.280961],[-87.113598,41.348153],[-87.219625,41.434595],[-87.309091,41.340081]]]},"properties":{"id":"46341","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.581725,41.620411],[-86.582375,41.56264],[-86.528733,41.577948],[-86.487029,41.642125],[-86.536054,41.657693],[-86.581725,41.620411]]]},"properties":{"id":"46365","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.573202,38.795569],[-86.310058,38.733131],[-86.275281,38.763795],[-86.276811,38.846187],[-86.308855,38.875614],[-86.495668,38.991784],[-86.521068,38.991991],[-86.546948,38.918863],[-86.546771,38.907801],[-86.553471,38.912061],[-86.669127,38.880455],[-86.573202,38.795569]],[[-86.341003,38.765822],[-86.34632,38.766971],[-86.343123,38.769964],[-86.341003,38.765822]],[[-86.52126,38.881623],[-86.534731,38.887744],[-86.526707,38.904177],[-86.509053,38.889535],[-86.52126,38.881623]]]},"properties":{"id":"47421","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.52126,38.881623],[-86.509053,38.889535],[-86.526707,38.904177],[-86.534731,38.887744],[-86.52126,38.881623]]]},"properties":{"id":"47451","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.553471,38.912061],[-86.546771,38.907801],[-86.546948,38.918863],[-86.553471,38.912061]]]},"properties":{"id":"47420","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.190686,39.824558],[-86.226004,39.823902],[-86.240098,39.823969],[-86.239071,39.764573],[-86.21384,39.756151],[-86.173197,39.763937],[-86.173202,39.764271],[-86.173921,39.766983],[-86.1968,39.796091],[-86.190686,39.824558]]]},"properties":{"id":"46222","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229612,39.867683],[-86.226004,39.823902],[-86.190686,39.824558],[-86.159827,39.868406],[-86.201749,39.868071],[-86.229612,39.867683]]]},"properties":{"id":"46228","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.173921,39.766983],[-86.149224,39.761716],[-86.136863,39.762389],[-86.131838,39.788151],[-86.135495,39.797756],[-86.157192,39.797326],[-86.1968,39.796091],[-86.173921,39.766983]]]},"properties":{"id":"46202","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.574169,39.290914],[-86.650269,39.239971],[-86.658902,39.241245],[-86.692595,39.180289],[-86.683002,39.165746],[-86.534233,39.164601],[-86.532367,39.164592],[-86.512438,39.333935],[-86.521375,39.338867],[-86.574169,39.290914]]]},"properties":{"id":"47404","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.035469,40.164084],[-87.092563,40.214806],[-87.092211,40.204892],[-87.0919,40.142071],[-87.01784,40.134852],[-87.035469,40.164084]]]},"properties":{"id":"47994","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.603712,39.542991],[-86.468739,39.546766],[-86.479633,39.60078],[-86.575641,39.60091],[-86.603712,39.542991]]]},"properties":{"id":"46157","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.446963,41.174354],[-87.446799,41.166426],[-87.439144,41.164455],[-87.437091,41.171431],[-87.446963,41.174354]]]},"properties":{"id":"46379","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.623563,41.324113],[-85.664139,41.1388],[-85.59299,41.138558],[-85.585229,41.295328],[-85.596745,41.324303],[-85.623563,41.324113]]]},"properties":{"id":"46764","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.552927,41.173217],[-86.537256,41.171905],[-86.540819,41.179034],[-86.552927,41.173217]]]},"properties":{"id":"46968","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.882925,40.106655],[-84.916621,40.265856],[-85.083118,40.250389],[-85.072601,40.092873],[-85.053349,40.077395],[-84.882925,40.106655]],[[-84.921248,40.239612],[-84.911069,40.236917],[-84.921234,40.234288],[-84.921248,40.239612]]]},"properties":{"id":"47394","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.202745,40.091591],[-85.121648,40.004262],[-85.070913,39.990147],[-85.068456,40.026902],[-85.053349,40.077395],[-85.072601,40.092873],[-85.16524,40.106426],[-85.202745,40.091591]]]},"properties":{"id":"47358","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.360288,39.00004],[-85.296863,39.099629],[-85.441236,39.139697],[-85.443719,39.043261],[-85.360288,39.00004]]]},"properties":{"id":"47023","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.330586,39.206129],[-85.319411,39.20425],[-85.323183,39.206134],[-85.330586,39.206129]]]},"properties":{"id":"47034","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229831,41.661335],[-86.215241,41.661315],[-86.19645,41.660526],[-86.196615,41.694726],[-86.21644,41.694489],[-86.236911,41.667336],[-86.229831,41.661335]]]},"properties":{"id":"46615","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.372864,41.620948],[-86.307337,41.653752],[-86.270828,41.658853],[-86.272479,41.672863],[-86.404314,41.686581],[-86.414281,41.650172],[-86.372864,41.620948]]]},"properties":{"id":"46619","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.272479,41.672863],[-86.270828,41.658853],[-86.229831,41.661335],[-86.236911,41.667336],[-86.250593,41.687893],[-86.270315,41.677507],[-86.272479,41.672863]]]},"properties":{"id":"46601","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.262678,41.694558],[-86.235514,41.692937],[-86.267208,41.722613],[-86.267213,41.722395],[-86.262678,41.694558]]]},"properties":{"id":"46556","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.95247,39.600787],[-85.925266,39.52361],[-85.87649,39.568943],[-85.941642,39.623869],[-85.95247,39.600787]]]},"properties":{"id":"46110","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.938484,41.580266],[-84.805591,41.603187],[-84.806209,41.674584],[-85.084141,41.748382],[-85.195023,41.667507],[-85.194994,41.663861],[-85.189116,41.627424],[-85.120908,41.606882],[-84.938484,41.580266]]]},"properties":{"id":"46703","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.092148,40.366612],[-87.092546,40.236637],[-87.022687,40.240537],[-87.022633,40.258873],[-87.036178,40.412348],[-87.092148,40.366612]]]},"properties":{"id":"47992","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.095741,40.577462],[-87.092148,40.366612],[-87.036178,40.412348],[-86.920889,40.399441],[-86.898484,40.418266],[-86.896598,40.425272],[-86.867183,40.475611],[-86.823258,40.495483],[-86.91164,40.56862],[-87.095741,40.577462]],[[-86.913979,40.43136],[-86.910386,40.428046],[-86.916724,40.420381],[-86.916709,40.431353],[-86.913979,40.43136]]]},"properties":{"id":"47906","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.577709,38.062659],[-87.57037,38.07964],[-87.588754,38.079883],[-87.577709,38.062659]]],[[[-87.586634,37.977989],[-87.563965,37.977413],[-87.577709,38.062659],[-87.590533,37.985562],[-87.586634,37.977989]]]]},"properties":{"id":"47710","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.272926,39.310178],[-87.379429,39.2737],[-87.307884,39.25914],[-87.272926,39.310178]]]},"properties":{"id":"47866","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.460496,39.50429],[-87.454034,39.520023],[-87.46662,39.51071],[-87.460496,39.50429]]]},"properties":{"id":"47876","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.411313,39.467503],[-87.40704,39.467517],[-87.407103,39.475439],[-87.412663,39.475282],[-87.411313,39.467503]]]},"properties":{"id":"47809","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.851214,40.976619],[-85.835399,40.968736],[-85.833132,40.977036],[-85.851214,40.976619]]]},"properties":{"id":"46943","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.883712,38.575401],[-86.055666,38.732877],[-86.17254,38.74544],[-86.215682,38.499932],[-86.150117,38.459584],[-85.883712,38.575401]]]},"properties":{"id":"47167","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.278142,40.596182],[-85.126084,40.574804],[-85.088482,40.611678],[-85.192954,40.625798],[-85.278142,40.596182]]]},"properties":{"id":"46759","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.072205,40.830907],[-85.071096,40.757467],[-85.013348,40.728957],[-84.802269,40.718579],[-84.802674,40.885489],[-84.93967,40.919763],[-85.063533,40.943541],[-85.085562,40.937208],[-85.076978,40.930263],[-85.072205,40.830907]]]},"properties":{"id":"46733","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.013559,40.699947],[-84.802369,40.674791],[-84.802269,40.718579],[-85.013348,40.728957],[-85.013559,40.699947]]]},"properties":{"id":"46772","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.998363,40.962604],[-84.93967,40.919763],[-84.802674,40.885489],[-84.803253,41.053144],[-84.903961,41.065905],[-84.931695,41.033622],[-84.998363,40.962604]]]},"properties":{"id":"46773","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.147925,40.917108],[-85.271147,40.911713],[-85.223313,40.873817],[-85.204371,40.814815],[-85.137401,40.815459],[-85.072205,40.830907],[-85.076978,40.930263],[-85.147925,40.917108]]]},"properties":{"id":"46777","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.136167,41.016384],[-85.085562,40.937208],[-85.063533,40.943541],[-84.998363,40.962604],[-84.931695,41.033622],[-85.034681,41.037019],[-85.136554,41.031594],[-85.136167,41.016384]]]},"properties":{"id":"46816","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.452146,41.005232],[-85.336064,40.904126],[-85.285948,40.917043],[-85.257803,40.97477],[-85.259931,41.006103],[-85.260303,41.006037],[-85.336119,41.036045],[-85.452146,41.005232]]]},"properties":{"id":"46783","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.962564,39.304868],[-85.947559,39.281081],[-85.943607,39.295923],[-85.962564,39.304868]]]},"properties":{"id":"47280","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.138897,40.577044],[-87.178663,40.489656],[-87.178034,40.410426],[-87.092148,40.366612],[-87.095741,40.577462],[-87.096413,40.606453],[-87.138897,40.577044]]]},"properties":{"id":"47970","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.757112,40.564988],[-85.446421,40.538406],[-85.409729,40.566848],[-85.409816,40.610161],[-85.44788,40.610256],[-85.562294,40.653193],[-85.60066,40.66771],[-85.81232,40.652072],[-85.80686,40.574454],[-85.769034,40.561192],[-85.757112,40.564988]]]},"properties":{"id":"46952","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.485629,40.161635],[-86.64812,40.0545],[-86.515566,39.922883],[-86.506028,39.922912],[-86.429053,39.923658],[-86.35534,39.93142],[-86.31379,40.0987],[-86.3459,40.16411],[-86.485629,40.161635]]]},"properties":{"id":"46052","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.64812,40.0545],[-86.695832,40.047203],[-86.695274,39.981264],[-86.695193,39.915707],[-86.610901,39.8937],[-86.515566,39.922883],[-86.64812,40.0545]]]},"properties":{"id":"46147","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.46779,40.17704],[-86.618064,40.17834],[-86.695701,40.143527],[-86.695738,40.058526],[-86.695832,40.047203],[-86.64812,40.0545],[-86.485629,40.161635],[-86.46779,40.17704]]]},"properties":{"id":"46071","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.695274,39.981264],[-86.695832,40.047203],[-86.695738,40.058526],[-86.799383,40.16919],[-86.923263,40.164174],[-87.035469,40.164084],[-87.01784,40.134852],[-87.05363,39.985325],[-87.057822,39.981738],[-87.090453,39.969002],[-87.091006,39.953009],[-87.091051,39.945655],[-86.963837,39.902656],[-86.827345,39.946528],[-86.695274,39.981264]],[[-86.921384,39.947315],[-86.92147,39.961953],[-86.913281,39.958034],[-86.921384,39.947315]]]},"properties":{"id":"47933","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.063175,39.167993],[-86.951437,39.232173],[-86.942881,39.290516],[-86.950984,39.310362],[-86.965797,39.313922],[-87.036826,39.320595],[-87.066363,39.168494],[-87.063175,39.167993]]]},"properties":{"id":"47427","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.240958,39.098954],[-87.091242,39.082076],[-87.063175,39.167993],[-87.066363,39.168494],[-87.155502,39.213332],[-87.240509,39.227418],[-87.315498,39.157267],[-87.240958,39.098954]]]},"properties":{"id":"47438","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.09679,39.525149],[-87.089404,39.517858],[-87.082582,39.523144],[-87.082577,39.530656],[-87.09679,39.525149]]]},"properties":{"id":"47857","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.998458,38.681398],[-86.975925,38.525],[-86.961122,38.672255],[-86.998458,38.681398]]]},"properties":{"id":"47519","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.194032,41.517324],[-85.173295,41.394998],[-85.155654,41.395319],[-85.099235,41.4254],[-85.128181,41.48949],[-85.13461,41.496612],[-85.194022,41.517991],[-85.194032,41.517324]]]},"properties":{"id":"46730","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.160784,38.53097],[-87.147074,38.467425],[-87.110638,38.418519],[-87.073238,38.422354],[-87.023109,38.507262],[-87.095442,38.529387],[-87.160784,38.53097]]]},"properties":{"id":"47564","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.793087,38.367071],[-86.774551,38.332129],[-86.751344,38.355373],[-86.793087,38.367071]]]},"properties":{"id":"47580","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.29811,39.525476],[-85.300405,39.649188],[-85.358644,39.612051],[-85.29811,39.525476]]]},"properties":{"id":"46133","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.355314,40.02148],[-87.310121,40.211449],[-87.29961,40.254967],[-87.522001,40.196401],[-87.530791,40.193958],[-87.532018,40.081192],[-87.434948,40.026438],[-87.355314,40.02148]]]},"properties":{"id":"47932","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.151732,40.165233],[-87.150563,40.159025],[-87.129586,40.164247],[-87.129592,40.167427],[-87.151732,40.165233]]]},"properties":{"id":"47958","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.501189,38.400829],[-87.484438,38.274338],[-87.43297,38.267004],[-87.407583,38.375501],[-87.407327,38.43632],[-87.421376,38.436215],[-87.501189,38.400829]]]},"properties":{"id":"47649","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.753037,38.216234],[-87.688525,38.161165],[-87.647419,38.189221],[-87.652674,38.208581],[-87.753037,38.216234]]]},"properties":{"id":"47612","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.375616,38.278194],[-87.379987,38.281772],[-87.380291,38.272645],[-87.374209,38.272718],[-87.375616,38.278194]]]},"properties":{"id":"47683","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.57833,40.412356],[-85.578403,40.40841],[-85.568995,40.407385],[-85.568766,40.412157],[-85.57833,40.412356]]]},"properties":{"id":"46930","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.757112,40.564988],[-85.769034,40.561192],[-85.769015,40.558767],[-85.757112,40.564988]]]},"properties":{"id":"46987","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.832333,39.160799],[-86.682093,39.056588],[-86.683002,39.165746],[-86.692595,39.180289],[-86.832333,39.160799]]]},"properties":{"id":"47459","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.965469,39.074551],[-87.016303,38.994477],[-87.016884,38.945812],[-86.945849,38.903488],[-86.914855,38.904027],[-86.904161,38.917228],[-86.895104,38.904209],[-86.891678,38.90421],[-86.847591,38.904307],[-86.831419,38.904325],[-86.738656,38.935421],[-86.682099,39.027565],[-86.682093,39.056588],[-86.832333,39.160799],[-86.893144,39.167901],[-86.965469,39.074551]]]},"properties":{"id":"47424","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.063175,39.167993],[-87.091242,39.082076],[-87.091213,39.082076],[-86.965469,39.074551],[-86.893144,39.167901],[-86.951437,39.232173],[-87.063175,39.167993]]]},"properties":{"id":"47471","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.16736,38.952232],[-87.101308,38.904019],[-87.016884,38.945812],[-87.016303,38.994477],[-87.081437,39.009027],[-87.16736,38.952232]]]},"properties":{"id":"47443","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.738656,38.935421],[-86.831419,38.904325],[-86.794425,38.904367],[-86.729398,38.904532],[-86.738656,38.935421]]]},"properties":{"id":"47453","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.02057,40.087408],[-86.116181,40.091903],[-86.108718,40.022163],[-86.023229,40.000205],[-86.02057,40.087408]]]},"properties":{"id":"46062","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.917863,39.953067],[-85.843003,39.885529],[-85.770915,39.915612],[-85.862123,39.973055],[-85.917863,39.953067]],[[-85.786768,39.968107],[-85.786576,39.951915],[-85.809238,39.960243],[-85.786768,39.968107]]]},"properties":{"id":"46040","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.157845,39.930189],[-86.157889,39.94162],[-86.172237,39.926895],[-86.157845,39.930189]]]},"properties":{"id":"46290","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.100506,39.926884],[-86.157845,39.930189],[-86.157832,39.883082],[-86.098321,39.884491],[-86.076183,39.92695],[-86.076484,39.926948],[-86.094801,39.926881],[-86.100506,39.926884]]]},"properties":{"id":"46240","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.838295,39.710758],[-85.925627,39.682725],[-85.809949,39.640151],[-85.786747,39.639997],[-85.76808,39.699068],[-85.838295,39.710758]]]},"properties":{"id":"46130","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.578601,39.865466],[-85.594272,39.916577],[-85.638775,39.945718],[-85.711014,39.930651],[-85.635478,39.844484],[-85.59677,39.851866],[-85.578601,39.865466]]]},"properties":{"id":"46186","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.952944,39.812067],[-86.009756,39.813329],[-86.008981,39.762147],[-85.952247,39.763125],[-85.933333,39.775164],[-85.952944,39.812067]]]},"properties":{"id":"46229","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.86226,39.99214],[-85.862123,39.973055],[-85.770915,39.915612],[-85.711014,39.930651],[-85.638775,39.945718],[-85.653002,40.004121],[-85.731594,40.034912],[-85.805471,40.045957],[-85.862238,40.010719],[-85.86226,39.99214]]]},"properties":{"id":"46064","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.164047,38.085286],[-86.157202,38.111362],[-86.194904,38.106468],[-86.22247,38.115995],[-86.164047,38.085286]]]},"properties":{"id":"47110","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.141984,38.355007],[-86.137389,38.287833],[-86.0879,38.282622],[-86.052755,38.296016],[-86.073864,38.331247],[-86.074156,38.353049],[-86.141984,38.355007]]]},"properties":{"id":"47161","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.57673,39.834428],[-86.686814,39.776111],[-86.579653,39.687599],[-86.473086,39.708857],[-86.442688,39.721686],[-86.414215,39.799136],[-86.438962,39.828004],[-86.543033,39.834268],[-86.57673,39.834428]]]},"properties":{"id":"46122","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.613471,39.686748],[-86.610871,39.68673],[-86.610839,39.68772],[-86.613466,39.687749],[-86.613471,39.686748]]]},"properties":{"id":"46103","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.241887,40.80686],[-85.223313,40.873817],[-85.271147,40.911713],[-85.280685,40.911611],[-85.28774,40.907291],[-85.291573,40.908281],[-85.284178,40.917044],[-85.285948,40.917043],[-85.336064,40.904126],[-85.390206,40.770741],[-85.335122,40.727234],[-85.335035,40.742031],[-85.241887,40.80686]]]},"properties":{"id":"46770","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.803725,40.338545],[-84.802667,40.528232],[-85.071854,40.524972],[-85.147355,40.458415],[-85.130647,40.387318],[-85.093534,40.307912],[-84.881919,40.309414],[-84.803725,40.338545]],[[-84.865711,40.377261],[-84.871382,40.386436],[-84.862323,40.386206],[-84.865711,40.377261]]]},"properties":{"id":"47371","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.645375,38.828825],[-85.748156,38.820079],[-85.758219,38.814548],[-85.721032,38.793777],[-85.682294,38.736766],[-85.572928,38.768427],[-85.54236,38.835925],[-85.645375,38.828825]]]},"properties":{"id":"47230","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.444849,38.942136],[-85.514894,38.948473],[-85.537813,38.897373],[-85.525555,38.833294],[-85.462199,38.851271],[-85.444849,38.942136]]]},"properties":{"id":"47231","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.686783,39.130859],[-85.779226,39.013459],[-85.777785,38.911036],[-85.537813,38.897373],[-85.514894,38.948473],[-85.484179,39.17386],[-85.686783,39.130859]],[[-85.61481,38.988075],[-85.60413,38.985324],[-85.61266,38.980517],[-85.61481,38.988075]]]},"properties":{"id":"47265","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.239106,39.554989],[-86.251308,39.473907],[-86.18681,39.46119],[-86.158751,39.558696],[-86.15879,39.56304],[-86.239106,39.554989]]]},"properties":{"id":"46106","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.538178,41.003515],[-85.59299,41.138558],[-85.664139,41.1388],[-85.724096,41.10307],[-85.724148,41.101514],[-85.643841,41.002305],[-85.538178,41.003515]]]},"properties":{"id":"46787","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.271607,41.533621],[-85.274473,41.532635],[-85.271549,41.531155],[-85.271607,41.533621]]]},"properties":{"id":"46786","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.33674,41.567539],[-87.298131,41.569726],[-87.296243,41.577788],[-87.356418,41.592346],[-87.356118,41.569108],[-87.33674,41.567539]]]},"properties":{"id":"46407","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.413098,41.55902],[-87.393842,41.559722],[-87.357126,41.609014],[-87.357486,41.61368],[-87.433101,41.648793],[-87.432359,41.613107],[-87.43254,41.566603],[-87.432538,41.566367],[-87.413098,41.55902]]]},"properties":{"id":"46406","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.524743,41.660435],[-87.490326,41.657334],[-87.49033,41.657553],[-87.494283,41.696211],[-87.524743,41.660435]]],[[[-87.525244,41.60136],[-87.480941,41.592079],[-87.461456,41.610842],[-87.490318,41.617992],[-87.524642,41.625735],[-87.525244,41.60136]]]]},"properties":{"id":"46320","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.393227,41.473491],[-87.25343,41.47101],[-87.317003,41.523881],[-87.336192,41.523981],[-87.389408,41.522611],[-87.393337,41.507636],[-87.393227,41.473491]]]},"properties":{"id":"46410","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.477153,41.475395],[-87.436378,41.460724],[-87.393227,41.473491],[-87.393337,41.507636],[-87.451759,41.522909],[-87.480318,41.523084],[-87.490743,41.52315],[-87.477153,41.475395]]]},"properties":{"id":"46375","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.298138,39.822519],[-86.310698,39.764047],[-86.28903,39.763488],[-86.266848,39.76481],[-86.275806,39.823436],[-86.298138,39.822519]]]},"properties":{"id":"46214","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.08242,39.69459],[-86.113111,39.723144],[-86.132609,39.722992],[-86.10152,39.63585],[-86.044638,39.637224],[-86.044481,39.680598],[-86.047114,39.7065],[-86.08242,39.69459]]]},"properties":{"id":"46237","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.21384,39.756151],[-86.309732,39.662977],[-86.282862,39.654779],[-86.28013,39.653731],[-86.282862,39.651099],[-86.252024,39.633543],[-86.249885,39.633645],[-86.242924,39.633744],[-86.189096,39.721878],[-86.173197,39.763937],[-86.21384,39.756151]]]},"properties":{"id":"46221","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.650349,39.303343],[-86.653371,39.298869],[-86.650478,39.296116],[-86.650349,39.303343]]]},"properties":{"id":"47464","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.022687,40.240537],[-86.937478,40.215009],[-86.81899,40.214869],[-87.022633,40.258873],[-87.022687,40.240537]]]},"properties":{"id":"47981","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.627713,39.499339],[-86.632303,39.476796],[-86.607666,39.491956],[-86.627713,39.499339]]],[[[-86.641612,39.470291],[-86.772882,39.433179],[-86.761007,39.413054],[-86.65114,39.441161],[-86.641612,39.470291]]]]},"properties":{"id":"47456","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.525787,40.851792],[-87.526031,40.736885],[-87.362932,40.736517],[-87.362777,40.736517],[-87.353912,40.822053],[-87.520235,40.851805],[-87.525787,40.851792]]]},"properties":{"id":"47951","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.338401,41.172747],[-85.46197,41.265181],[-85.53622,41.302556],[-85.585229,41.295328],[-85.59299,41.138558],[-85.538178,41.003515],[-85.452146,41.005232],[-85.336119,41.036045],[-85.336871,41.079865],[-85.338401,41.172747]]]},"properties":{"id":"46725","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.467459,41.516855],[-85.491435,41.43828],[-85.370777,41.452815],[-85.385278,41.474835],[-85.467459,41.516855]]]},"properties":{"id":"46794","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.514979,38.604283],[-86.607666,38.687045],[-86.683102,38.686561],[-86.682446,38.566417],[-86.567018,38.553887],[-86.514979,38.604283]]]},"properties":{"id":"47469","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.320253,39.85224],[-87.367988,39.867648],[-87.382139,39.724867],[-87.382601,39.695653],[-87.308481,39.6662],[-87.323049,39.709351],[-87.333465,39.725178],[-87.325048,39.729138],[-87.293001,39.795598],[-87.320253,39.85224]]]},"properties":{"id":"47862","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.293001,39.795598],[-87.235888,39.808002],[-87.186278,39.953077],[-87.320253,39.85224],[-87.293001,39.795598]]]},"properties":{"id":"47832","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.010753,39.779561],[-86.93727,39.77577],[-86.937368,39.792988],[-86.93629,39.866104],[-86.959449,39.866264],[-87.009849,39.844833],[-87.010753,39.779561]]]},"properties":{"id":"46175","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.113598,41.348153],[-87.102293,41.280961],[-86.963535,41.22182],[-86.930079,41.236798],[-86.946606,41.361523],[-87.065602,41.389295],[-87.113598,41.348153]]]},"properties":{"id":"46347","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.22168,41.561172],[-87.163059,41.536023],[-87.124582,41.586463],[-87.144914,41.619152],[-87.222932,41.632596],[-87.222035,41.593536],[-87.22168,41.561172]]]},"properties":{"id":"46368","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.921248,40.239612],[-84.921234,40.234288],[-84.911069,40.236917],[-84.921248,40.239612]]]},"properties":{"id":"47382","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.267213,41.722395],[-86.270315,41.677507],[-86.250593,41.687893],[-86.262678,41.694558],[-86.267213,41.722395]]]},"properties":{"id":"46616","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.925627,39.682725],[-85.942721,39.682502],[-85.941642,39.623869],[-85.87649,39.568943],[-85.809949,39.640151],[-85.925627,39.682725]]]},"properties":{"id":"46126","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.978836,38.031203],[-87.018094,37.988552],[-87.036667,37.904119],[-86.885072,37.964232],[-86.942879,38.015906],[-86.978836,38.031203]]]},"properties":{"id":"47615","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.065902,41.556131],[-84.99768,41.532556],[-84.96857,41.530948],[-84.938484,41.580266],[-85.120908,41.606882],[-85.065902,41.556131]]]},"properties":{"id":"46779","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.294322,39.190339],[-87.308561,39.199426],[-87.315121,39.186298],[-87.294322,39.190339]]]},"properties":{"id":"47855","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.910425,38.849055],[-84.984555,38.86543],[-84.990102,38.778728],[-84.908073,38.787501],[-84.910425,38.849055]]]},"properties":{"id":"47020","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.870749,38.900905],[-84.910425,38.849055],[-84.908073,38.787501],[-84.870749,38.900905]]]},"properties":{"id":"47038","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.913979,40.43136],[-86.916709,40.431353],[-86.916724,40.420381],[-86.910386,40.428046],[-86.913979,40.43136]]]},"properties":{"id":"47907","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.013137,40.347847],[-86.202826,40.36003],[-86.185352,40.216601],[-86.175948,40.216725],[-85.905042,40.232295],[-85.885745,40.291377],[-86.013137,40.347847]],[[-85.947392,40.283397],[-85.948519,40.283397],[-85.948519,40.285103],[-85.946013,40.284521],[-85.947392,40.283397]],[[-86.150734,40.287054],[-86.152125,40.289126],[-86.148151,40.290364],[-86.147539,40.288048],[-86.150734,40.287054]]]},"properties":{"id":"46072","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.996177,39.917936],[-84.994617,39.863347],[-84.965352,39.726747],[-84.96117,39.726772],[-84.814373,39.72662],[-84.811674,39.964087],[-84.912121,39.922073],[-84.996177,39.917936]],[[-84.851936,39.744724],[-84.847769,39.737533],[-84.855757,39.737455],[-84.851936,39.744724]]]},"properties":{"id":"47374","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.575158,37.966565],[-87.561262,37.977371],[-87.563965,37.977413],[-87.586634,37.977989],[-87.588073,37.975492],[-87.575158,37.966565]]]},"properties":{"id":"47708","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.434948,40.026438],[-87.532018,40.081192],[-87.532308,40.011607],[-87.439389,39.959061],[-87.434948,40.026438]]]},"properties":{"id":"47974","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.437248,39.607636],[-87.439464,39.622473],[-87.463061,39.621842],[-87.46281,39.607536],[-87.437248,39.607636]]]},"properties":{"id":"47884","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.351703,39.484465],[-87.238964,39.52062],[-87.226909,39.577105],[-87.402603,39.565772],[-87.41362,39.514436],[-87.351703,39.484465]]]},"properties":{"id":"47805","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.530262,40.235984],[-87.47197,40.265127],[-87.386669,40.258065],[-87.337685,40.297949],[-87.529258,40.303595],[-87.530262,40.235984]]]},"properties":{"id":"47991","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.530729,40.199412],[-87.530791,40.193958],[-87.522001,40.196401],[-87.530729,40.199412]]]},"properties":{"id":"47982","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.27886,40.683469],[-85.335035,40.742031],[-85.335122,40.727234],[-85.31685,40.668922],[-85.27886,40.683469]]]},"properties":{"id":"46766","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.937466,41.182356],[-84.903961,41.065905],[-84.803253,41.053144],[-84.803508,41.198237],[-84.937466,41.182356]]]},"properties":{"id":"46797","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.165905,41.177687],[-85.165953,41.128985],[-85.149078,41.117864],[-85.106123,41.116842],[-85.100059,41.121629],[-85.063701,41.175745],[-85.165905,41.177687]]]},"properties":{"id":"46825","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.161207,41.060869],[-85.189703,41.045615],[-85.259931,41.006103],[-85.257803,40.97477],[-85.16772,40.954591],[-85.164925,41.04232],[-85.161207,41.060869]]]},"properties":{"id":"46809","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.136226,41.060632],[-85.058431,41.054938],[-85.043318,41.086665],[-85.087674,41.078944],[-85.126465,41.082828],[-85.137977,41.064271],[-85.136226,41.060632]]]},"properties":{"id":"46803","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.013699,41.127107],[-85.043318,41.086665],[-85.058431,41.054938],[-85.034681,41.037019],[-84.931695,41.033622],[-84.903961,41.065905],[-84.937466,41.182356],[-84.987947,41.156425],[-85.013699,41.127107]]]},"properties":{"id":"46774","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.135084,41.265136],[-85.182909,41.270832],[-85.230787,41.274194],[-85.232029,41.23481],[-85.171139,41.215972],[-85.09363,41.236503],[-85.086633,41.265527],[-85.135084,41.265136]]]},"properties":{"id":"46748","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.937466,41.182356],[-84.853719,41.240844],[-84.986367,41.229749],[-85.025227,41.200096],[-84.987947,41.156425],[-84.937466,41.182356]]]},"properties":{"id":"46741","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.09363,41.236503],[-85.032771,41.199803],[-85.025227,41.200096],[-84.986367,41.229749],[-85.020002,41.259115],[-85.086633,41.265527],[-85.09363,41.236503]]]},"properties":{"id":"46765","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.274886,41.08425],[-85.260303,41.006037],[-85.259931,41.006103],[-85.189703,41.045615],[-85.179811,41.07467],[-85.226664,41.08628],[-85.274886,41.08425]]]},"properties":{"id":"46804","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.336119,41.036045],[-85.260303,41.006037],[-85.274886,41.08425],[-85.336871,41.079865],[-85.336119,41.036045]]]},"properties":{"id":"46814","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.847245,39.056512],[-86.088336,39.094017],[-86.095405,38.963452],[-85.948535,38.823006],[-85.795913,38.867573],[-85.796082,38.884531],[-85.777785,38.911036],[-85.779226,39.013459],[-85.798804,39.068536],[-85.847245,39.056512]]]},"properties":{"id":"47274","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.409729,40.566848],[-85.220162,40.486683],[-85.200677,40.486778],[-85.163144,40.56736],[-85.126038,40.567651],[-85.126084,40.574804],[-85.278142,40.596182],[-85.318951,40.610659],[-85.409816,40.610161],[-85.409729,40.566848]]]},"properties":{"id":"47359","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.190427,39.303711],[-86.345434,39.277187],[-86.371993,39.223556],[-86.370292,39.165448],[-86.318133,39.04881],[-86.205848,39.048541],[-86.08241,39.246809],[-86.084094,39.299638],[-86.166382,39.291091],[-86.190427,39.303711]]]},"properties":{"id":"47448","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.079335,39.388271],[-86.166382,39.291091],[-86.084094,39.299638],[-86.066036,39.344771],[-86.05585,39.34499],[-86.064052,39.373284],[-86.079335,39.388271]]]},"properties":{"id":"46164","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.748782,40.623301],[-86.81262,40.64934],[-87.01896,40.64873],[-87.097393,40.663844],[-87.097025,40.635465],[-87.096413,40.606453],[-87.095741,40.577462],[-86.91164,40.56862],[-86.771295,40.593976],[-86.748782,40.623301]]]},"properties":{"id":"47923","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.189483,40.771642],[-86.197665,40.764471],[-86.184346,40.76542],[-86.189483,40.771642]]]},"properties":{"id":"46961","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.238964,39.52062],[-87.248899,39.428064],[-87.23957,39.427919],[-87.145239,39.387423],[-87.014533,39.473572],[-87.013041,39.606742],[-87.01167,39.713953],[-87.086355,39.69309],[-87.143252,39.583935],[-87.226909,39.577105],[-87.238964,39.52062]],[[-87.07122,39.539266],[-87.071453,39.53641],[-87.073581,39.535899],[-87.075149,39.539543],[-87.07122,39.539266]],[[-87.197442,39.487681],[-87.18885,39.487009],[-87.189189,39.479485],[-87.202117,39.484913],[-87.197442,39.487681]],[[-87.09679,39.525149],[-87.082577,39.530656],[-87.082582,39.523144],[-87.089404,39.517858],[-87.09679,39.525149]]]},"properties":{"id":"47834","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.694669,40.411036],[-86.695123,40.321617],[-86.58696,40.387938],[-86.694669,40.411036]]]},"properties":{"id":"46058","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.247301,40.244609],[-86.213832,40.216243],[-86.185352,40.216601],[-86.202826,40.36003],[-86.242992,40.373661],[-86.242986,40.357117],[-86.252271,40.323075],[-86.247301,40.244609]]]},"properties":{"id":"46049","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.031263,38.902516],[-87.092951,38.800851],[-87.064573,38.771443],[-86.979106,38.773271],[-86.904123,38.868684],[-86.9042,38.889539],[-86.904252,38.904204],[-86.914855,38.904027],[-86.945849,38.903488],[-87.031263,38.902516]]]},"properties":{"id":"47562","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.998458,38.681398],[-86.979106,38.773271],[-87.064573,38.771443],[-87.114253,38.74114],[-87.095442,38.529387],[-87.023109,38.507262],[-86.978271,38.502508],[-86.975925,38.525],[-86.998458,38.681398]]]},"properties":{"id":"47558","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.241446,38.802619],[-87.114253,38.74114],[-87.064573,38.771443],[-87.092951,38.800851],[-87.141861,38.847958],[-87.227986,38.808016],[-87.241446,38.802619]]]},"properties":{"id":"47568","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.982915,39.245029],[-85.096686,39.310149],[-85.217399,39.197261],[-85.187157,39.137204],[-85.055839,39.177163],[-84.996128,39.154847],[-84.982915,39.245029]]]},"properties":{"id":"47041","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.335283,39.311218],[-85.325919,39.306709],[-85.325888,39.311188],[-85.335283,39.311218]]]},"properties":{"id":"47263","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.174123,41.336693],[-85.182909,41.270832],[-85.135084,41.265136],[-85.155418,41.380678],[-85.174123,41.336693]]]},"properties":{"id":"46738","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.194032,41.517324],[-85.316486,41.517794],[-85.385278,41.474835],[-85.370777,41.452815],[-85.309172,41.365879],[-85.173295,41.394998],[-85.194032,41.517324]]]},"properties":{"id":"46755","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.375467,40.076381],[-85.38571,40.090897],[-85.394453,40.076462],[-85.375467,40.076381]]]},"properties":{"id":"47367","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.576175,40.083871],[-85.605235,40.02262],[-85.576075,40.004646],[-85.529104,39.971889],[-85.403956,40.026321],[-85.442547,40.076686],[-85.461453,40.076797],[-85.576175,40.083871]],[[-85.44755,40.008883],[-85.437308,40.007934],[-85.437138,40.001361],[-85.447661,40.00121],[-85.44755,40.008883]]]},"properties":{"id":"47356","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.605235,40.02262],[-85.576175,40.083871],[-85.575982,40.119494],[-85.633573,40.0932],[-85.640245,40.086392],[-85.633939,40.0159],[-85.605235,40.02262]]]},"properties":{"id":"46017","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.073113,38.269184],[-87.00786,38.203438],[-86.942519,38.20293],[-86.855462,38.277648],[-86.849399,38.354642],[-87.042776,38.360386],[-87.090983,38.305365],[-87.073113,38.269184]]]},"properties":{"id":"47542","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.109238,38.232786],[-87.00786,38.203438],[-87.073113,38.269184],[-87.109238,38.232786]]]},"properties":{"id":"47541","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.711252,41.759036],[-85.772944,41.682097],[-85.656718,41.610538],[-85.650721,41.759095],[-85.711252,41.759036]]]},"properties":{"id":"46540","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.880422,41.667022],[-85.772944,41.682097],[-85.711252,41.759036],[-85.888769,41.759443],[-85.88863,41.706521],[-85.880422,41.667022]]]},"properties":{"id":"46507","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.885818,41.438846],[-85.817149,41.434909],[-85.769717,41.496609],[-85.920112,41.508391],[-85.885818,41.438846]]]},"properties":{"id":"46553","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.150117,38.459584],[-86.135212,38.448618],[-86.03277,38.410704],[-85.994634,38.412183],[-85.84763,38.549385],[-85.873427,38.57554],[-85.883712,38.575401],[-86.150117,38.459584]]]},"properties":{"id":"47165","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.895736,38.274904],[-86.001803,38.264181],[-85.975764,38.185829],[-85.927264,38.208241],[-85.895736,38.274904]]]},"properties":{"id":"47136","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.078949,38.120968],[-86.03088,37.991841],[-85.89976,38.180444],[-85.927264,38.208241],[-85.975764,38.185829],[-86.047704,38.163279],[-86.050775,38.163269],[-86.078949,38.120968]]]},"properties":{"id":"47117","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.81741,39.406398],[-84.928335,39.352427],[-84.81812,39.371521],[-84.817647,39.391816],[-84.818303,39.393952],[-84.817717,39.394563],[-84.81741,39.406398]]]},"properties":{"id":"47016","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.035082,39.635124],[-85.004844,39.524228],[-84.891381,39.523707],[-84.814769,39.624832],[-84.814373,39.72662],[-84.96117,39.726772],[-85.035082,39.635124]]]},"properties":{"id":"47353","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.468233,40.909884],[-86.314206,40.90937],[-86.462584,41.113194],[-86.473906,41.084273],[-86.496799,41.004393],[-86.468152,40.924413],[-86.468233,40.909884]]]},"properties":{"id":"46939","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.076312,41.114028],[-86.114842,40.996346],[-85.980375,40.998926],[-85.95275,40.999146],[-85.96613,41.084446],[-86.038173,41.114613],[-86.076312,41.114028]]]},"properties":{"id":"46910","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.038173,41.114613],[-85.985427,41.158234],[-85.966172,41.144139],[-85.931349,41.174642],[-85.997265,41.217415],[-86.026197,41.21704],[-86.054542,41.196404],[-86.094522,41.172765],[-86.076312,41.114028],[-86.038173,41.114613]]]},"properties":{"id":"46539","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.838152,38.283378],[-87.916716,38.15664],[-87.883199,38.197162],[-87.808138,38.257863],[-87.838152,38.283378]]]},"properties":{"id":"47616","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.806991,38.111262],[-87.697093,38.108575],[-87.671444,38.126781],[-87.688525,38.161165],[-87.753037,38.216234],[-87.808138,38.257863],[-87.883199,38.197162],[-87.806991,38.111262]]]},"properties":{"id":"47633","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.526925,40.473977],[-85.626672,40.488745],[-85.547951,40.456294],[-85.526925,40.473977]]]},"properties":{"id":"46933","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.942881,39.290516],[-86.893144,39.167901],[-86.832333,39.160799],[-86.692595,39.180289],[-86.658902,39.241245],[-86.761007,39.413054],[-86.772882,39.433179],[-86.851142,39.450884],[-86.906048,39.32639],[-86.947585,39.311641],[-86.950984,39.310362],[-86.942881,39.290516]]]},"properties":{"id":"47460","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.240925,39.074424],[-87.300037,39.031548],[-87.240797,38.994483],[-87.240753,39.009056],[-87.240925,39.074424]]]},"properties":{"id":"47848","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.141984,38.355007],[-86.180987,38.267943],[-86.137389,38.287833],[-86.141984,38.355007]]]},"properties":{"id":"47166","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.212323,38.422093],[-86.255045,38.422685],[-86.275072,38.272761],[-86.180987,38.267943],[-86.141984,38.355007],[-86.160471,38.42045],[-86.212323,38.422093]]]},"properties":{"id":"47115","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.052755,38.296016],[-86.0879,38.282622],[-86.066618,38.285845],[-86.052755,38.296016]]]},"properties":{"id":"47114","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.604826,39.663538],[-86.575641,39.60091],[-86.479633,39.60078],[-86.437289,39.663726],[-86.473086,39.708857],[-86.579653,39.687599],[-86.604826,39.663538]]]},"properties":{"id":"46118","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.394453,40.076462],[-85.442547,40.076686],[-85.403956,40.026321],[-85.329425,40.0354],[-85.333818,40.063121],[-85.375467,40.076381],[-85.394453,40.076462]]]},"properties":{"id":"47386","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.464928,39.874904],[-85.461314,39.877425],[-85.462331,39.882125],[-85.46759,39.879368],[-85.464928,39.874904]]]},"properties":{"id":"47344","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.192829,38.904533],[-86.276811,38.846187],[-86.275281,38.763795],[-86.227109,38.777506],[-86.109343,38.853868],[-86.112754,38.898408],[-86.192829,38.904533]]]},"properties":{"id":"47260","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.809737,40.048911],[-84.803725,40.338545],[-84.881919,40.309414],[-84.916621,40.265856],[-84.882925,40.106655],[-84.809737,40.048911]]]},"properties":{"id":"47390","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.484179,39.17386],[-85.514894,38.948473],[-85.444849,38.942136],[-85.443719,39.043261],[-85.441236,39.139697],[-85.440478,39.182776],[-85.484179,39.17386]]]},"properties":{"id":"47223","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.650011,38.517728],[-87.426127,38.558669],[-87.370499,38.674257],[-87.34475,38.710565],[-87.351978,38.728684],[-87.482082,38.792306],[-87.497476,38.767647],[-87.650011,38.517728]]]},"properties":{"id":"47591","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.219761,38.855339],[-87.214676,38.877221],[-87.241164,38.877215],[-87.219761,38.855339]]]},"properties":{"id":"47596","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.522939,38.90239],[-87.497476,38.767647],[-87.482082,38.792306],[-87.393118,38.811638],[-87.33423,38.859492],[-87.315362,38.889054],[-87.316989,38.901758],[-87.522939,38.90239]]]},"properties":{"id":"47561","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.393118,38.811638],[-87.482082,38.792306],[-87.351978,38.728684],[-87.393118,38.811638]]]},"properties":{"id":"47516","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.480436,41.567444],[-87.525302,41.581531],[-87.525061,41.522729],[-87.490743,41.52315],[-87.480318,41.523084],[-87.480436,41.567444]]]},"properties":{"id":"46321","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.340657,41.182566],[-87.333448,41.195402],[-87.354641,41.199636],[-87.345179,41.186454],[-87.353785,41.179971],[-87.342337,41.182508],[-87.340657,41.182566]]]},"properties":{"id":"46377","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.826306,41.34646],[-86.930296,41.376012],[-86.946606,41.361523],[-86.930079,41.236798],[-86.843012,41.269084],[-86.746119,41.317144],[-86.826306,41.34646]]]},"properties":{"id":"46348","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.747627,40.306281],[-85.576696,40.291737],[-85.578589,40.379524],[-85.746916,40.371574],[-85.747627,40.306281]]]},"properties":{"id":"46070","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.685539,40.119378],[-85.708359,40.080358],[-85.640245,40.086392],[-85.633573,40.0932],[-85.685539,40.119378]]]},"properties":{"id":"46016","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.157621,39.722456],[-86.15839,39.6351],[-86.10629,39.63579],[-86.10152,39.63585],[-86.132609,39.722992],[-86.150176,39.722414],[-86.157621,39.722456]]]},"properties":{"id":"46227","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.574169,39.290914],[-86.650349,39.303343],[-86.650478,39.296116],[-86.650269,39.239971],[-86.574169,39.290914]]]},"properties":{"id":"47429","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.512603,39.182412],[-86.510944,39.178845],[-86.507252,39.179774],[-86.512603,39.182412]]]},"properties":{"id":"47406","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.923263,40.164174],[-86.799383,40.16919],[-86.771104,40.187354],[-86.809485,40.214856],[-86.81899,40.214869],[-86.937478,40.215009],[-86.923263,40.164174]]]},"properties":{"id":"47955","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.917854,39.487082],[-86.851142,39.450884],[-86.772882,39.433179],[-86.641612,39.470291],[-86.632303,39.476796],[-86.627713,39.499339],[-86.665156,39.538086],[-86.657002,39.571563],[-86.701604,39.557646],[-86.903222,39.545897],[-86.917854,39.487082]]]},"properties":{"id":"46120","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.566639,41.388266],[-85.596745,41.324303],[-85.585229,41.295328],[-85.53622,41.302556],[-85.51074,41.390199],[-85.566639,41.388266]]]},"properties":{"id":"46760","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.942881,39.290516],[-86.951437,39.232173],[-86.893144,39.167901],[-86.942881,39.290516]]]},"properties":{"id":"47431","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.76757,39.732143],[-86.93727,39.77577],[-87.010753,39.779561],[-87.01167,39.713953],[-87.013041,39.606742],[-86.903222,39.545897],[-86.701604,39.557646],[-86.710792,39.572173],[-86.76757,39.732143]]]},"properties":{"id":"46135","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.784256,38.043444],[-86.814744,37.998704],[-86.805111,37.993633],[-86.784256,38.043444]]],[[[-86.810352,38.111225],[-86.774911,38.070242],[-86.716048,38.136128],[-86.780285,38.117846],[-86.810352,38.111225]]]]},"properties":{"id":"47588","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.700762,37.897556],[-87.735342,38.015245],[-87.862284,38.056228],[-87.973204,38.063318],[-87.700762,37.897556]]]},"properties":{"id":"47620","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.76757,39.732143],[-86.710792,39.572173],[-86.726735,39.732094],[-86.76757,39.732143]]]},"properties":{"id":"46128","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.296863,39.099629],[-85.245257,39.074617],[-85.187157,39.137204],[-85.217399,39.197261],[-85.319411,39.20425],[-85.330586,39.206129],[-85.324858,39.247708],[-85.440478,39.182776],[-85.441236,39.139697],[-85.296863,39.099629]]]},"properties":{"id":"47037","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.750741,39.416954],[-85.700977,39.407293],[-85.602951,39.453315],[-85.594683,39.482933],[-85.630613,39.519417],[-85.750741,39.416954]]]},"properties":{"id":"46182","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.215742,41.650761],[-86.307337,41.653752],[-86.372864,41.620948],[-86.318147,41.549653],[-86.244704,41.564439],[-86.162066,41.564563],[-86.215742,41.650761]]]},"properties":{"id":"46614","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.272495,41.760402],[-86.394719,41.759894],[-86.404314,41.686581],[-86.272479,41.672863],[-86.270315,41.677507],[-86.267213,41.722395],[-86.267208,41.722613],[-86.272495,41.760402]]]},"properties":{"id":"46628","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.00786,38.203438],[-87.109238,38.232786],[-87.181396,38.231377],[-87.072332,38.11828],[-87.053271,38.118222],[-87.018026,38.118283],[-86.96847,38.11851],[-86.904612,38.138694],[-86.942519,38.20293],[-87.00786,38.203438]]]},"properties":{"id":"47523","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.140989,38.02848],[-87.132035,38.002876],[-87.081944,37.959338],[-87.018094,37.988552],[-86.978836,38.031203],[-87.013234,38.083175],[-87.088817,38.053118],[-87.140989,38.02848]]]},"properties":{"id":"47611","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.837331,38.113295],[-86.838356,38.111279],[-86.83471,38.111285],[-86.837331,38.113295]]]},"properties":{"id":"47536","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.300037,39.031548],[-87.383837,39.021316],[-87.392105,39.015143],[-87.489906,39.012158],[-87.542437,38.975587],[-87.522939,38.90239],[-87.316989,38.901758],[-87.240797,38.994483],[-87.300037,39.031548]]]},"properties":{"id":"47838","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.202826,40.36003],[-86.013137,40.347847],[-85.994573,40.405983],[-86.203093,40.40325],[-86.242992,40.373661],[-86.202826,40.36003]]]},"properties":{"id":"46068","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.53302,39.910357],[-87.532989,39.759846],[-87.450765,39.767973],[-87.428995,39.834884],[-87.438525,39.862143],[-87.53302,39.910357]]]},"properties":{"id":"47847","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.365037,38.016394],[-87.449906,37.991204],[-87.451199,37.940954],[-87.268995,37.878026],[-87.268445,37.879164],[-87.264762,37.929194],[-87.365037,38.016394]]]},"properties":{"id":"47630","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.318951,40.610659],[-85.278142,40.596182],[-85.192954,40.625798],[-85.27886,40.683469],[-85.31685,40.668922],[-85.318951,40.610659]]]},"properties":{"id":"46781","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.071096,40.757467],[-85.137401,40.815459],[-85.204371,40.814815],[-85.241887,40.80686],[-85.335035,40.742031],[-85.27886,40.683469],[-85.192954,40.625798],[-85.088482,40.611678],[-85.049434,40.648661],[-85.013559,40.699947],[-85.013348,40.728957],[-85.071096,40.757467]]]},"properties":{"id":"46714","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.063533,40.943541],[-84.93967,40.919763],[-84.998363,40.962604],[-85.063533,40.943541]]]},"properties":{"id":"46745","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.836497,39.131268],[-85.947559,39.281081],[-85.962564,39.304868],[-86.027719,39.345609],[-86.05585,39.34499],[-86.066036,39.344771],[-86.08241,39.246809],[-86.205848,39.048541],[-86.19389,39.048559],[-86.088336,39.094017],[-85.847245,39.056512],[-85.836497,39.131268]],[[-85.883174,39.059783],[-85.889523,39.056746],[-85.890938,39.062231],[-85.883174,39.059783]]]},"properties":{"id":"47201","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.863833,39.324646],[-85.715166,39.232624],[-85.686049,39.297115],[-85.684515,39.350049],[-85.85746,39.349312],[-85.863833,39.324646]]]},"properties":{"id":"47246","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526031,40.736885],[-87.526225,40.649778],[-87.362932,40.736517],[-87.526031,40.736885]]]},"properties":{"id":"47942","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.827345,39.946528],[-86.695287,39.908498],[-86.695193,39.915707],[-86.695274,39.981264],[-86.827345,39.946528]]]},"properties":{"id":"47968","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.186172,39.441015],[-86.190427,39.303711],[-86.166382,39.291091],[-86.079335,39.388271],[-86.186172,39.441015]]]},"properties":{"id":"46181","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.095405,38.963452],[-86.088336,39.094017],[-86.19389,39.048559],[-86.200751,38.961857],[-86.112754,38.898408],[-86.109343,38.853868],[-86.093641,38.868966],[-86.095405,38.963452]]]},"properties":{"id":"47235","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.431889,40.840865],[-86.580744,40.802656],[-86.525213,40.717196],[-86.525164,40.692419],[-86.373697,40.628496],[-86.337838,40.649088],[-86.26103,40.757736],[-86.197665,40.764471],[-86.189483,40.771642],[-86.205926,40.844392],[-86.291847,40.866105],[-86.431889,40.840865]]]},"properties":{"id":"46947","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.762938,38.348993],[-85.745514,38.353458],[-85.719632,38.370939],[-85.735216,38.427019],[-85.829335,38.404231],[-85.813941,38.378771],[-85.762938,38.348993]]]},"properties":{"id":"47172","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.813941,38.378771],[-85.89377,38.302062],[-85.895736,38.274904],[-85.927264,38.208241],[-85.89976,38.180444],[-85.792316,38.287433],[-85.762938,38.348993],[-85.813941,38.378771]]]},"properties":{"id":"47150","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.417813,38.540238],[-85.421548,38.538265],[-85.423038,38.532299],[-85.417813,38.540238]]]},"properties":{"id":"47104","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.379429,39.2737],[-87.272926,39.310178],[-87.208606,39.301579],[-87.208607,39.302072],[-87.23957,39.427919],[-87.248899,39.428064],[-87.389975,39.455619],[-87.42552,39.448336],[-87.531646,39.347888],[-87.604141,39.259458],[-87.567348,39.259318],[-87.379429,39.2737]],[[-87.297635,39.387884],[-87.302996,39.391164],[-87.296271,39.391154],[-87.297635,39.387884]]]},"properties":{"id":"47802","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.193221,40.465709],[-86.375501,40.489007],[-86.375568,40.460945],[-86.375646,40.448871],[-86.356799,40.431848],[-86.242986,40.357117],[-86.242992,40.373661],[-86.203093,40.40325],[-86.193221,40.465709]]]},"properties":{"id":"46979","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.338635,38.250202],[-86.476696,38.159706],[-86.461022,38.121087],[-86.3296,38.181805],[-86.276055,38.252121],[-86.338635,38.250202]]]},"properties":{"id":"47137","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.659402,38.394943],[-86.641549,38.263326],[-86.549543,38.306383],[-86.610046,38.395522],[-86.659402,38.394943]]]},"properties":{"id":"47116","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.241708,38.795135],[-87.262455,38.7289],[-87.245586,38.594388],[-87.242097,38.54493],[-87.160784,38.53097],[-87.095442,38.529387],[-87.114253,38.74114],[-87.241446,38.802619],[-87.241708,38.795135]]]},"properties":{"id":"47501","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.037844,39.029526],[-84.973605,38.952801],[-84.877624,39.031213],[-84.870835,39.073029],[-84.962952,39.136817],[-84.996128,39.154847],[-85.055839,39.177163],[-85.095867,39.146256],[-85.037844,39.029526]]]},"properties":{"id":"47001","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.346476,41.307946],[-85.174123,41.336693],[-85.155418,41.380678],[-85.155654,41.395319],[-85.173295,41.394998],[-85.309172,41.365879],[-85.346476,41.307946]]]},"properties":{"id":"46710","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.13461,41.496612],[-85.065902,41.556131],[-85.120908,41.606882],[-85.189116,41.627424],[-85.214079,41.581421],[-85.194022,41.517991],[-85.13461,41.496612]]]},"properties":{"id":"46747","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.557937,40.276773],[-85.575623,40.21892],[-85.560927,40.214158],[-85.475042,40.120591],[-85.44268,40.178379],[-85.532249,40.249432],[-85.557937,40.276773]]]},"properties":{"id":"47396","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.368695,40.191698],[-85.411492,40.185241],[-85.403389,40.185287],[-85.368695,40.191698]]]},"properties":{"id":"47305","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.576175,40.083871],[-85.461453,40.076797],[-85.475042,40.120591],[-85.560927,40.214158],[-85.575982,40.119494],[-85.576175,40.083871]]]},"properties":{"id":"47334","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.681194,40.191662],[-85.575623,40.21892],[-85.557937,40.276773],[-85.576696,40.291737],[-85.747627,40.306281],[-85.774459,40.277572],[-85.729906,40.219621],[-85.681194,40.191662]],[[-85.732722,40.270188],[-85.717521,40.277011],[-85.721807,40.263739],[-85.732722,40.270188]]]},"properties":{"id":"46001","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.793352,38.428453],[-86.681773,38.436815],[-86.742515,38.526171],[-86.837003,38.52646],[-86.853386,38.510167],[-86.793352,38.428453]]]},"properties":{"id":"47527","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.060521,41.586531],[-86.001912,41.562269],[-85.906669,41.6267],[-85.906188,41.627121],[-86.061074,41.663785],[-86.060611,41.593772],[-86.060521,41.586531]]]},"properties":{"id":"46517","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.038627,41.680352],[-85.88863,41.706521],[-85.888769,41.759443],[-86.051867,41.760429],[-86.048763,41.713952],[-86.038627,41.680352]]]},"properties":{"id":"46514","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.906669,41.6267],[-85.757371,41.508491],[-85.656243,41.594087],[-85.656718,41.610538],[-85.772944,41.682097],[-85.880422,41.667022],[-85.906188,41.627121],[-85.906669,41.6267]]]},"properties":{"id":"46528","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.656243,41.594087],[-85.757371,41.508491],[-85.706705,41.481589],[-85.69782,41.47606],[-85.616548,41.534943],[-85.617796,41.596443],[-85.656243,41.594087]]]},"properties":{"id":"46543","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.098349,41.505017],[-85.966792,41.522006],[-86.001912,41.562269],[-86.060521,41.586531],[-86.137748,41.550247],[-86.098349,41.505017]]]},"properties":{"id":"46573","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.289181,39.525465],[-85.120347,39.519403],[-85.004844,39.524228],[-85.035082,39.635124],[-85.053292,39.714819],[-85.101735,39.714968],[-85.221118,39.788449],[-85.271965,39.787973],[-85.301584,39.787579],[-85.301382,39.758631],[-85.300656,39.663734],[-85.300405,39.649188],[-85.29811,39.525476],[-85.289181,39.525465]]]},"properties":{"id":"47331","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.074156,38.353049],[-86.073864,38.331247],[-85.943808,38.338919],[-85.966323,38.377591],[-85.994634,38.412183],[-86.03277,38.410704],[-86.074156,38.353049]]]},"properties":{"id":"47124","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.289181,39.525465],[-85.297575,39.453275],[-85.29687,39.410557],[-85.205368,39.418066],[-85.057036,39.460254],[-85.120347,39.519403],[-85.289181,39.525465]]]},"properties":{"id":"47024","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.346698,41.17204],[-86.408135,41.137015],[-86.41511,41.139253],[-86.415133,41.143877],[-86.467073,41.162287],[-86.462584,41.113194],[-86.314206,40.90937],[-86.283915,40.909422],[-86.207546,40.909186],[-86.114842,40.996346],[-86.076312,41.114028],[-86.094522,41.172765],[-86.152832,41.172283],[-86.346698,41.17204]],[[-86.268531,40.948634],[-86.262691,40.947206],[-86.264205,40.94484],[-86.268531,40.948634]]]},"properties":{"id":"46975","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.466545,38.223217],[-87.647419,38.189221],[-87.688525,38.161165],[-87.671444,38.126781],[-87.596996,38.14358],[-87.467212,38.169568],[-87.466545,38.223217]]]},"properties":{"id":"47639","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.391651,38.24512],[-87.391626,38.250249],[-87.396177,38.245131],[-87.391651,38.24512]]]},"properties":{"id":"47654","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.493667,40.394327],[-85.507466,40.388124],[-85.496822,40.382401],[-85.484818,40.388572],[-85.493667,40.394327]]]},"properties":{"id":"46957","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.016884,38.945812],[-87.101308,38.904019],[-87.098771,38.903974],[-87.031263,38.902516],[-86.945849,38.903488],[-87.016884,38.945812]]]},"properties":{"id":"47449","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.240797,38.994483],[-87.316989,38.901758],[-87.315362,38.889054],[-87.287876,38.881879],[-87.241164,38.877215],[-87.214676,38.877221],[-87.219761,38.855339],[-87.227986,38.808016],[-87.141861,38.847958],[-87.098771,38.903974],[-87.101308,38.904019],[-87.16736,38.952232],[-87.240753,39.009056],[-87.240797,38.994483]]]},"properties":{"id":"47578","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.12928,40.014698],[-86.229839,39.973341],[-86.240252,39.932541],[-86.203087,39.926625],[-86.172237,39.926895],[-86.157889,39.94162],[-86.112427,39.934152],[-86.12928,40.014698]]]},"properties":{"id":"46032","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.843003,39.885529],[-85.917863,39.953067],[-85.939026,39.932069],[-85.937587,39.927137],[-85.954013,39.869646],[-85.953478,39.840383],[-85.843003,39.885529]]]},"properties":{"id":"46055","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.172237,39.926895],[-86.203087,39.926625],[-86.201749,39.868071],[-86.159827,39.868406],[-86.156128,39.871131],[-86.157832,39.883082],[-86.157845,39.930189],[-86.172237,39.926895]]]},"properties":{"id":"46260","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.578601,39.865466],[-85.505088,39.887749],[-85.529104,39.971889],[-85.576075,40.004646],[-85.594272,39.916577],[-85.578601,39.865466]],[[-85.524059,39.901803],[-85.52631,39.909657],[-85.516947,39.909692],[-85.517112,39.898734],[-85.524059,39.901803]]]},"properties":{"id":"47384","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.635478,39.844484],[-85.625551,39.78623],[-85.587282,39.786641],[-85.59677,39.851866],[-85.635478,39.844484]]]},"properties":{"id":"46117","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.726735,39.732094],[-86.710792,39.572173],[-86.701604,39.557646],[-86.657002,39.571563],[-86.604826,39.663538],[-86.579653,39.687599],[-86.686814,39.776111],[-86.726735,39.732094]],[[-86.613471,39.686748],[-86.613466,39.687749],[-86.610839,39.68772],[-86.610871,39.68673],[-86.613471,39.686748]]]},"properties":{"id":"46121","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.444131,39.86029],[-85.410311,39.827374],[-85.362388,39.845586],[-85.255983,39.873192],[-85.200945,39.873799],[-85.252136,39.946842],[-85.329425,40.0354],[-85.403956,40.026321],[-85.529104,39.971889],[-85.505088,39.887749],[-85.46759,39.879368],[-85.462331,39.882125],[-85.461314,39.877425],[-85.464928,39.874904],[-85.444131,39.86029]],[[-85.387417,40.004047],[-85.386123,40.006287],[-85.382928,40.005035],[-85.382939,40.002667],[-85.387417,40.004047]]]},"properties":{"id":"47362","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.444131,39.86029],[-85.447748,39.799743],[-85.445552,39.802097],[-85.429123,39.802958],[-85.410311,39.827374],[-85.444131,39.86029]]]},"properties":{"id":"47385","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.505088,39.887749],[-85.578601,39.865466],[-85.59677,39.851866],[-85.587282,39.786641],[-85.484275,39.742946],[-85.427697,39.758034],[-85.447748,39.799743],[-85.444131,39.86029],[-85.464928,39.874904],[-85.46759,39.879368],[-85.505088,39.887749]]]},"properties":{"id":"46148","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.44755,40.008883],[-85.447661,40.00121],[-85.437138,40.001361],[-85.437308,40.007934],[-85.44755,40.008883]]]},"properties":{"id":"47388","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.252136,39.946842],[-85.200945,39.873799],[-85.117326,39.876097],[-85.107216,39.917438],[-85.142429,39.981288],[-85.185642,39.997249],[-85.252136,39.946842]]]},"properties":{"id":"47346","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.03188,40.477514],[-86.193221,40.465709],[-86.203093,40.40325],[-85.994573,40.405983],[-85.975506,40.406227],[-86.03188,40.477514]]]},"properties":{"id":"46902","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.141771,41.144191],[-86.969119,41.071343],[-86.930418,41.151036],[-86.963535,41.22182],[-87.102293,41.280961],[-87.145863,41.281153],[-87.141771,41.144191]]]},"properties":{"id":"46392","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.77585,40.91246],[-86.796174,41.05633],[-86.979519,41.046901],[-86.930759,40.912418],[-86.803693,40.91249],[-86.77585,40.91246]]]},"properties":{"id":"47946","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.93033,40.8232],[-86.83388,40.80723],[-86.791636,40.898356],[-86.803693,40.91249],[-86.930759,40.912418],[-87.042682,40.852479],[-86.93033,40.8232]]]},"properties":{"id":"47959","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.081082,40.854686],[-87.097393,40.663844],[-87.01896,40.64873],[-87.0234,40.69201],[-86.93033,40.8232],[-87.042682,40.852479],[-87.081082,40.854686]]]},"properties":{"id":"47995","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.200677,40.486778],[-85.147355,40.458415],[-85.071854,40.524972],[-85.163144,40.56736],[-85.200677,40.486778]]]},"properties":{"id":"47369","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.748156,38.820079],[-85.645375,38.828825],[-85.796082,38.884531],[-85.795913,38.867573],[-85.748156,38.820079]]]},"properties":{"id":"47270","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.18415,38.870986],[-85.183761,38.928779],[-85.234382,38.913949],[-85.20285,38.842024],[-85.18415,38.870986]]]},"properties":{"id":"47224","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.15839,39.6351],[-86.242924,39.633744],[-86.249885,39.633645],[-86.250133,39.604046],[-86.10629,39.63579],[-86.15839,39.6351]]]},"properties":{"id":"46142","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.245586,38.594388],[-87.262455,38.7289],[-87.34475,38.710565],[-87.370499,38.674257],[-87.245586,38.594388]]]},"properties":{"id":"47597","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.426127,38.558669],[-87.650011,38.517728],[-87.743691,38.414387],[-87.596294,38.453512],[-87.462892,38.533519],[-87.426006,38.540575],[-87.426127,38.558669]]]},"properties":{"id":"47524","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.736732,41.322378],[-85.666592,41.301879],[-85.652946,41.33372],[-85.653089,41.345593],[-85.736732,41.322378]]]},"properties":{"id":"46555","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.287928,41.60922],[-87.297312,41.579945],[-87.222035,41.593536],[-87.222932,41.632596],[-87.287928,41.60922]]]},"properties":{"id":"46403","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.393842,41.559722],[-87.413098,41.55902],[-87.389408,41.522611],[-87.336192,41.523981],[-87.33674,41.567539],[-87.356118,41.569108],[-87.393842,41.559722]]]},"properties":{"id":"46408","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.932895,41.718939],[-86.99512,41.659124],[-86.951555,41.617272],[-86.896532,41.601879],[-86.752981,41.759963],[-86.932895,41.718939]]]},"properties":{"id":"46360","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.698024,41.48766],[-86.703842,41.458292],[-86.68589,41.458253],[-86.676723,41.476406],[-86.698024,41.48766]]]},"properties":{"id":"46346","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.341003,38.765822],[-86.343123,38.769964],[-86.34632,38.766971],[-86.341003,38.765822]]]},"properties":{"id":"47467","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.607666,38.687045],[-86.514979,38.604283],[-86.317744,38.48515],[-86.307941,38.521239],[-86.327141,38.688367],[-86.607666,38.687045]]]},"properties":{"id":"47452","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.573202,38.795569],[-86.669127,38.880455],[-86.683006,38.883213],[-86.747712,38.824653],[-86.683407,38.755451],[-86.573202,38.795569]]]},"properties":{"id":"47470","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.786768,39.968107],[-85.809238,39.960243],[-85.786576,39.951915],[-85.786768,39.968107]]]},"properties":{"id":"46048","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.259892,39.867159],[-86.325987,39.873492],[-86.328012,39.859269],[-86.298138,39.822519],[-86.275806,39.823436],[-86.240098,39.823969],[-86.226004,39.823902],[-86.229612,39.867683],[-86.259892,39.867159]]]},"properties":{"id":"46254","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.149249,39.761219],[-86.173202,39.764271],[-86.173197,39.763937],[-86.189096,39.721878],[-86.157621,39.722456],[-86.150176,39.722414],[-86.149249,39.761219]]]},"properties":{"id":"46225","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.329425,41.461773],[-86.295913,41.457055],[-86.29611,41.462659],[-86.329425,41.461773]]]},"properties":{"id":"46537","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.108888,40.614048],[-86.099403,40.612648],[-86.099574,40.635845],[-86.108968,40.635816],[-86.108888,40.614048]]]},"properties":{"id":"46959","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.761007,39.413054],[-86.658902,39.241245],[-86.650269,39.239971],[-86.650478,39.296116],[-86.653371,39.298869],[-86.650349,39.303343],[-86.574169,39.290914],[-86.521375,39.338867],[-86.583887,39.386639],[-86.65114,39.441161],[-86.761007,39.413054]]]},"properties":{"id":"47433","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.544929,39.010994],[-86.548919,39.013536],[-86.550493,39.010249],[-86.544929,39.010994]]]},"properties":{"id":"47434","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.65114,39.441161],[-86.583887,39.386639],[-86.607666,39.491956],[-86.632303,39.476796],[-86.641612,39.470291],[-86.65114,39.441161]]]},"properties":{"id":"46166","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.640291,39.521722],[-86.643036,39.523081],[-86.643028,39.520255],[-86.640291,39.521722]]]},"properties":{"id":"46125","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.130102,38.960611],[-85.249875,38.957393],[-85.234382,38.913949],[-85.183761,38.928779],[-85.135832,38.929578],[-85.120057,38.939607],[-85.130102,38.960611]]]},"properties":{"id":"47017","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.135832,38.929578],[-85.183761,38.928779],[-85.18415,38.870986],[-85.13636,38.903183],[-85.135832,38.929578]]],[[[-85.098676,38.903011],[-84.987978,38.875685],[-85.044175,38.903],[-85.099477,38.908681],[-85.098676,38.903011]]]]},"properties":{"id":"47011","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.965797,39.313922],[-86.950984,39.310362],[-86.947585,39.311641],[-86.965797,39.313922]]]},"properties":{"id":"47455","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.325048,39.729138],[-87.333465,39.725178],[-87.323049,39.709351],[-87.325048,39.729138]]]},"properties":{"id":"47860","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.810352,38.111225],[-86.780285,38.117846],[-86.786888,38.199694],[-86.836862,38.117961],[-86.837331,38.113295],[-86.83471,38.111285],[-86.810352,38.111225]]]},"properties":{"id":"47577","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.596698,37.9839],[-86.525098,37.968702],[-86.497027,38.045883],[-86.533042,38.073339],[-86.621014,38.061047],[-86.603717,38.002045],[-86.596698,37.9839]]]},"properties":{"id":"47525","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.316219,38.381474],[-87.147074,38.467425],[-87.160784,38.53097],[-87.242097,38.54493],[-87.426006,38.540575],[-87.462892,38.533519],[-87.407327,38.43632],[-87.407583,38.375501],[-87.316219,38.381474]]]},"properties":{"id":"47567","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.147074,38.467425],[-87.316219,38.381474],[-87.200906,38.314435],[-87.173718,38.313036],[-87.110638,38.418519],[-87.147074,38.467425]]]},"properties":{"id":"47598","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.066473,41.564451],[-87.124582,41.586463],[-87.163059,41.536023],[-87.157887,41.504063],[-87.157946,41.499992],[-87.219953,41.470702],[-87.219625,41.434595],[-87.113598,41.348153],[-87.065602,41.389295],[-87.066473,41.564451]]]},"properties":{"id":"46385","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.009872,41.690053],[-86.99512,41.659124],[-86.932895,41.718939],[-87.009872,41.690053]]]},"properties":{"id":"46301","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.735342,38.015245],[-87.700762,37.897556],[-87.588073,37.975492],[-87.586634,37.977989],[-87.590533,37.985562],[-87.697765,38.051762],[-87.735342,38.015245]]]},"properties":{"id":"47712","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.797822,41.128663],[-86.650272,41.172068],[-86.746119,41.317144],[-86.843012,41.269084],[-86.872297,41.148192],[-86.797822,41.128663]]]},"properties":{"id":"46366","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.578971,39.576504],[-85.555949,39.582638],[-85.557582,39.591769],[-85.579563,39.583042],[-85.581954,39.598479],[-85.578971,39.576504]]]},"properties":{"id":"46146","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.217319,41.745447],[-86.21644,41.694489],[-86.196615,41.694726],[-86.191073,41.722119],[-86.217319,41.745447]]]},"properties":{"id":"46635","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.700977,39.407293],[-85.750741,39.416954],[-85.846268,39.384041],[-85.85746,39.349312],[-85.684515,39.350049],[-85.684494,39.364848],[-85.700977,39.407293]]]},"properties":{"id":"47234","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.072332,38.11828],[-87.181396,38.231377],[-87.199807,38.231038],[-87.242773,38.231129],[-87.206387,38.156002],[-87.140989,38.02848],[-87.088817,38.053118],[-87.054431,38.101133],[-87.072332,38.11828]]]},"properties":{"id":"47637","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.866397,38.103962],[-86.979242,38.114817],[-87.015407,38.094374],[-87.013234,38.083175],[-86.978836,38.031203],[-86.942879,38.015906],[-86.866397,38.103962]]]},"properties":{"id":"47550","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.379429,39.2737],[-87.567348,39.259318],[-87.488773,39.220711],[-87.280274,39.237069],[-87.307884,39.25914],[-87.379429,39.2737]]]},"properties":{"id":"47850","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.896598,40.425272],[-86.898484,40.418266],[-86.878593,40.417339],[-86.896598,40.425272]]]},"properties":{"id":"47901","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.755067,40.272783],[-86.771043,40.282729],[-86.771133,40.2734],[-86.755067,40.272783]]]},"properties":{"id":"47983","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.947392,40.283397],[-85.946013,40.284521],[-85.948519,40.285103],[-85.948519,40.283397],[-85.947392,40.283397]]]},"properties":{"id":"46047","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.548221,37.941807],[-87.548482,37.924039],[-87.451199,37.940954],[-87.449906,37.991204],[-87.470688,38.035023],[-87.491788,38.035027],[-87.502416,37.97675],[-87.548221,37.941807]]]},"properties":{"id":"47715","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.575158,37.966565],[-87.548482,37.924039],[-87.548221,37.941807],[-87.547745,37.977194],[-87.561262,37.977371],[-87.575158,37.966565]]]},"properties":{"id":"47713","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.417551,39.884244],[-87.377574,39.879708],[-87.397055,39.88704],[-87.417551,39.884244]]]},"properties":{"id":"47966","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.389975,39.455619],[-87.385427,39.484362],[-87.42652,39.484536],[-87.42552,39.448336],[-87.389975,39.455619]],[[-87.411313,39.467503],[-87.412663,39.475282],[-87.407103,39.475439],[-87.40704,39.467517],[-87.411313,39.467503]]]},"properties":{"id":"47807","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.640221,40.757495],[-85.737898,40.883523],[-85.830942,40.89755],[-85.869592,40.913235],[-85.943739,40.896344],[-85.943339,40.877412],[-85.940585,40.707044],[-85.858217,40.661683],[-85.857431,40.661876],[-85.833382,40.672536],[-85.829354,40.67321],[-85.639902,40.740506],[-85.640221,40.757495]]]},"properties":{"id":"46992","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.833382,40.672536],[-85.835099,40.663714],[-85.826986,40.663744],[-85.829354,40.67321],[-85.833382,40.672536]]]},"properties":{"id":"46984","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.530262,40.235984],[-87.530729,40.199412],[-87.522001,40.196401],[-87.29961,40.254967],[-87.315693,40.418266],[-87.324561,40.425526],[-87.396647,40.411143],[-87.52678,40.461952],[-87.529258,40.303595],[-87.337685,40.297949],[-87.386669,40.258065],[-87.47197,40.265127],[-87.530262,40.235984]]]},"properties":{"id":"47993","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.470688,38.035023],[-87.449906,37.991204],[-87.365037,38.016394],[-87.33542,38.121983],[-87.355202,38.14287],[-87.472955,38.064314],[-87.470688,38.035023]]]},"properties":{"id":"47610","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.137401,40.815459],[-85.071096,40.757467],[-85.072205,40.830907],[-85.137401,40.815459]]]},"properties":{"id":"46731","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.049434,40.648661],[-85.088482,40.611678],[-85.126084,40.574804],[-85.126038,40.567651],[-84.802391,40.586596],[-84.802282,40.615908],[-85.049434,40.648661]]]},"properties":{"id":"46740","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.013699,41.127107],[-85.100059,41.121629],[-85.106123,41.116842],[-85.087674,41.078944],[-85.043318,41.086665],[-85.013699,41.127107]]]},"properties":{"id":"46815","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.338401,41.172747],[-85.336871,41.079865],[-85.274886,41.08425],[-85.226664,41.08628],[-85.165953,41.128985],[-85.165905,41.177687],[-85.171139,41.215972],[-85.232029,41.23481],[-85.338401,41.172747]],[[-85.293301,41.104734],[-85.292018,41.103768],[-85.292255,41.102758],[-85.294363,41.103332],[-85.293301,41.104734]]]},"properties":{"id":"46818","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.136226,41.060632],[-85.136554,41.031594],[-85.034681,41.037019],[-85.058431,41.054938],[-85.136226,41.060632]]]},"properties":{"id":"46806","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.346543,41.293464],[-85.46197,41.265181],[-85.338401,41.172747],[-85.232029,41.23481],[-85.230787,41.274194],[-85.346543,41.293464]]]},"properties":{"id":"46723","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.715166,39.232624],[-85.863833,39.324646],[-85.943607,39.295923],[-85.947559,39.281081],[-85.836497,39.131268],[-85.724671,39.1599],[-85.715166,39.232624]],[[-85.870617,39.282401],[-85.866142,39.284787],[-85.866133,39.279951],[-85.870617,39.282401]]]},"properties":{"id":"47203","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.836497,39.131268],[-85.847245,39.056512],[-85.798804,39.068536],[-85.724294,39.130691],[-85.697906,39.159999],[-85.724671,39.1599],[-85.836497,39.131268]]]},"properties":{"id":"47232","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.263511,39.92489],[-86.259892,39.867159],[-86.229612,39.867683],[-86.201749,39.868071],[-86.203087,39.926625],[-86.240252,39.932541],[-86.263511,39.92489]]]},"properties":{"id":"46268","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.618064,40.17834],[-86.695544,40.221646],[-86.771104,40.187354],[-86.695701,40.143527],[-86.618064,40.17834]]]},"properties":{"id":"46035","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.370292,39.165448],[-86.509462,39.168096],[-86.526905,39.164307],[-86.532367,39.164592],[-86.534233,39.164601],[-86.537457,38.999648],[-86.521068,38.991991],[-86.495668,38.991784],[-86.401319,39.014839],[-86.318133,39.04881],[-86.370292,39.165448]],[[-86.507621,39.069544],[-86.508197,39.071097],[-86.505577,39.071134],[-86.506604,39.069295],[-86.507621,39.069544]]]},"properties":{"id":"47401","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.591545,40.51845],[-86.375238,40.503357],[-86.323073,40.549726],[-86.374023,40.585901],[-86.582426,40.590848],[-86.591545,40.51845]]]},"properties":{"id":"46929","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.525074,40.431259],[-86.375646,40.448871],[-86.375568,40.460945],[-86.396163,40.486743],[-86.592599,40.472296],[-86.525074,40.431259]]]},"properties":{"id":"46920","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.695123,40.321617],[-86.695208,40.281342],[-86.695544,40.221646],[-86.618064,40.17834],[-86.46779,40.17704],[-86.247301,40.244609],[-86.252271,40.323075],[-86.318472,40.330397],[-86.413235,40.286696],[-86.375506,40.402749],[-86.356799,40.431848],[-86.375646,40.448871],[-86.525074,40.431259],[-86.58696,40.387938],[-86.695123,40.321617]]]},"properties":{"id":"46041","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.26103,40.757736],[-86.337838,40.649088],[-86.166623,40.678404],[-86.26103,40.757736]],[[-86.198561,40.692973],[-86.198536,40.696586],[-86.191875,40.69662],[-86.191826,40.692537],[-86.198561,40.692973]]]},"properties":{"id":"46994","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.26103,40.757736],[-86.166623,40.678404],[-86.160001,40.649652],[-86.014171,40.646748],[-85.940585,40.707044],[-85.943339,40.877412],[-86.178275,40.851604],[-86.205926,40.844392],[-86.189483,40.771642],[-86.184346,40.76542],[-86.197665,40.764471],[-86.26103,40.757736]],[[-86.113678,40.815007],[-86.124026,40.822077],[-86.11518,40.828567],[-86.113678,40.815007]]]},"properties":{"id":"46970","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.167873,40.880564],[-86.178275,40.851604],[-85.943339,40.877412],[-85.943739,40.896344],[-85.982085,40.913191],[-86.167873,40.880564]]]},"properties":{"id":"46926","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.198561,40.692973],[-86.191826,40.692537],[-86.191875,40.69662],[-86.198536,40.696586],[-86.198561,40.692973]]]},"properties":{"id":"46967","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.751475,38.267998],[-85.745514,38.353458],[-85.762938,38.348993],[-85.792316,38.287433],[-85.751475,38.267998]]]},"properties":{"id":"47129","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.673825,38.60567],[-85.773583,38.619995],[-85.821408,38.573258],[-85.718026,38.599453],[-85.673825,38.60567]]]},"properties":{"id":"47177","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.272926,39.310178],[-87.307884,39.25914],[-87.280274,39.237069],[-87.240509,39.227418],[-87.155502,39.213332],[-87.208606,39.301579],[-87.272926,39.310178]]]},"properties":{"id":"47858","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.997649,39.421042],[-86.944012,39.497985],[-87.014533,39.473572],[-87.145239,39.387423],[-87.141625,39.358284],[-87.044259,39.342508],[-86.997649,39.421042]]]},"properties":{"id":"47840","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.362488,38.286979],[-86.439165,38.411846],[-86.570248,38.456809],[-86.588544,38.411969],[-86.549543,38.306383],[-86.641549,38.263326],[-86.641791,38.227228],[-86.607343,38.229837],[-86.544071,38.189803],[-86.503234,38.168916],[-86.476696,38.159706],[-86.338635,38.250202],[-86.362488,38.286979]],[[-86.470967,38.294028],[-86.459455,38.243634],[-86.47648,38.238854],[-86.492904,38.269885],[-86.470967,38.294028]]]},"properties":{"id":"47118","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.098771,38.903974],[-87.141861,38.847958],[-87.092951,38.800851],[-87.031263,38.902516],[-87.098771,38.903974]]]},"properties":{"id":"47529","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.684494,39.364848],[-85.533105,39.452614],[-85.602951,39.453315],[-85.700977,39.407293],[-85.684494,39.364848]]]},"properties":{"id":"47272","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.548981,39.698839],[-85.594049,39.598628],[-85.581954,39.598479],[-85.579563,39.583042],[-85.557582,39.591769],[-85.555949,39.582638],[-85.578971,39.576504],[-85.553827,39.496685],[-85.404392,39.453129],[-85.297575,39.453275],[-85.289181,39.525465],[-85.29811,39.525476],[-85.358644,39.612051],[-85.300405,39.649188],[-85.300656,39.663734],[-85.352585,39.763557],[-85.427697,39.758034],[-85.484275,39.742946],[-85.548981,39.698839]],[[-85.429808,39.742581],[-85.431055,39.744404],[-85.427511,39.744373],[-85.429808,39.742581]]]},"properties":{"id":"46173","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.973073,41.296401],[-84.803845,41.304221],[-84.803971,41.324942],[-84.906793,41.341544],[-84.973073,41.296401]]]},"properties":{"id":"46785","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.388372,40.378708],[-85.330657,40.291646],[-85.285672,40.326242],[-85.277431,40.378372],[-85.388372,40.378708]]]},"properties":{"id":"47338","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.023109,38.507262],[-87.073238,38.422354],[-87.042776,38.360386],[-86.849399,38.354642],[-86.793087,38.367071],[-86.793352,38.428453],[-86.853386,38.510167],[-86.978271,38.502508],[-87.023109,38.507262]]]},"properties":{"id":"47546","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.681773,38.436815],[-86.793352,38.428453],[-86.793087,38.367071],[-86.751344,38.355373],[-86.681424,38.394767],[-86.681773,38.436815]]]},"properties":{"id":"47521","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.173718,38.313036],[-87.090983,38.305365],[-87.042776,38.360386],[-87.073238,38.422354],[-87.110638,38.418519],[-87.173718,38.313036]]]},"properties":{"id":"47590","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.061074,41.663785],[-85.906188,41.627121],[-85.880422,41.667022],[-85.88863,41.706521],[-86.038627,41.680352],[-86.061074,41.663785]]]},"properties":{"id":"46516","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.767596,41.349552],[-85.817149,41.434909],[-85.885818,41.438846],[-85.966792,41.36147],[-85.955683,41.350667],[-85.767596,41.349552]]]},"properties":{"id":"46542","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.467768,41.525397],[-85.616548,41.534943],[-85.69782,41.47606],[-85.653598,41.431136],[-85.566639,41.388266],[-85.51074,41.390199],[-85.491435,41.43828],[-85.467459,41.516855],[-85.467768,41.525397]]]},"properties":{"id":"46767","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.001803,38.264181],[-85.895736,38.274904],[-85.89377,38.302062],[-85.943808,38.338919],[-86.073864,38.331247],[-86.052755,38.296016],[-86.066618,38.285845],[-86.001803,38.264181]]]},"properties":{"id":"47122","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.439389,39.959061],[-87.377173,39.879013],[-87.367988,39.867648],[-87.320253,39.85224],[-87.186278,39.953077],[-87.091006,39.953009],[-87.090453,39.969002],[-87.090659,39.981897],[-87.175957,40.026029],[-87.355314,40.02148],[-87.434948,40.026438],[-87.439389,39.959061]]]},"properties":{"id":"47952","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.155244,40.208242],[-87.155261,40.205347],[-87.14401,40.203641],[-87.148622,40.207442],[-87.155244,40.208242]]]},"properties":{"id":"47969","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.467073,41.162287],[-86.5171,41.229671],[-86.621304,41.172004],[-86.473906,41.084273],[-86.462584,41.113194],[-86.467073,41.162287]],[[-86.552927,41.173217],[-86.540819,41.179034],[-86.537256,41.171905],[-86.552927,41.173217]]]},"properties":{"id":"46960","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.752735,38.400412],[-87.724037,38.381137],[-87.719019,38.392528],[-87.752735,38.400412]]],[[[-87.687176,38.336961],[-87.838152,38.283378],[-87.808138,38.257863],[-87.753037,38.216234],[-87.652674,38.208581],[-87.622773,38.289039],[-87.687176,38.336961]]]]},"properties":{"id":"47665","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.44788,40.610256],[-85.439875,40.668201],[-85.562294,40.653193],[-85.44788,40.610256]]]},"properties":{"id":"46991","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.895104,38.904209],[-86.904161,38.917228],[-86.914855,38.904027],[-86.904252,38.904204],[-86.895104,38.904209]]]},"properties":{"id":"47457","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.881875,40.162901],[-86.137651,40.189149],[-86.128254,40.131148],[-85.939527,40.153206],[-85.881875,40.162901]]]},"properties":{"id":"46030","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.137651,40.189149],[-85.881875,40.162901],[-85.862758,40.1777],[-85.905042,40.232295],[-86.175948,40.216725],[-86.137651,40.189149]]]},"properties":{"id":"46031","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.24142,40.041557],[-86.229839,39.973341],[-86.12928,40.014698],[-86.108718,40.022163],[-86.116181,40.091903],[-86.128083,40.094532],[-86.24142,40.041557]]]},"properties":{"id":"46074","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.164047,38.085286],[-86.22247,38.115995],[-86.277116,38.146034],[-86.18851,38.015392],[-86.131035,38.077116],[-86.164047,38.085286]]]},"properties":{"id":"47142","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.047704,38.163279],[-86.053144,38.166155],[-86.053165,38.163253],[-86.050775,38.163269],[-86.047704,38.163279]]]},"properties":{"id":"47160","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.524059,39.901803],[-85.517112,39.898734],[-85.516947,39.909692],[-85.52631,39.909657],[-85.524059,39.901803]]]},"properties":{"id":"47351","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.109343,38.853868],[-86.227109,38.777506],[-86.17254,38.74544],[-86.055666,38.732877],[-86.025996,38.762097],[-86.093641,38.868966],[-86.109343,38.853868]]]},"properties":{"id":"47281","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.896263,38.75049],[-85.721032,38.793777],[-85.758219,38.814548],[-85.896263,38.75049]]]},"properties":{"id":"47102","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.093534,40.307912],[-85.195688,40.295341],[-85.195632,40.291144],[-85.083118,40.250389],[-84.916621,40.265856],[-84.881919,40.309414],[-85.093534,40.307912]]]},"properties":{"id":"47380","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.506689,38.727805],[-85.572928,38.768427],[-85.682294,38.736766],[-85.670333,38.60573],[-85.648106,38.605643],[-85.518495,38.614337],[-85.506689,38.727805]]]},"properties":{"id":"47138","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.044638,39.637224],[-85.971477,39.601314],[-85.95247,39.600787],[-85.941642,39.623869],[-85.942721,39.682502],[-85.951735,39.697049],[-86.044481,39.680598],[-86.044638,39.637224]]]},"properties":{"id":"46259","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.468739,39.546766],[-86.603712,39.542991],[-86.665156,39.538086],[-86.627713,39.499339],[-86.607666,39.491956],[-86.583887,39.386639],[-86.521375,39.338867],[-86.512438,39.333935],[-86.428242,39.310773],[-86.390692,39.339599],[-86.251308,39.473907],[-86.239106,39.554989],[-86.250589,39.591046],[-86.468739,39.546766]],[[-86.640291,39.521722],[-86.643028,39.520255],[-86.643036,39.523081],[-86.640291,39.521722]]]},"properties":{"id":"46151","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.33423,38.859492],[-87.287868,38.858752],[-87.287876,38.881879],[-87.315362,38.889054],[-87.33423,38.859492]]]},"properties":{"id":"47535","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.96613,41.084446],[-85.780267,41.058668],[-85.724148,41.101514],[-85.724096,41.10307],[-85.74377,41.127498],[-85.931349,41.174642],[-85.966172,41.144139],[-85.985151,41.143735],[-85.985427,41.158234],[-86.038173,41.114613],[-85.96613,41.084446]]]},"properties":{"id":"46510","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.765367,41.221279],[-85.998516,41.267247],[-85.997265,41.217415],[-85.931349,41.174642],[-85.74377,41.127498],[-85.765367,41.221279]],[[-85.978293,41.259663],[-85.978358,41.261824],[-85.975383,41.261716],[-85.975257,41.258589],[-85.978293,41.259663]],[[-85.829687,41.23404],[-85.794427,41.224535],[-85.806659,41.203487],[-85.829687,41.23404]]]},"properties":{"id":"46580","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.653598,41.431136],[-85.653089,41.345593],[-85.652946,41.33372],[-85.623563,41.324113],[-85.596745,41.324303],[-85.566639,41.388266],[-85.653598,41.431136]]]},"properties":{"id":"46732","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.22168,41.561172],[-87.222035,41.593536],[-87.297312,41.579945],[-87.296243,41.577788],[-87.298131,41.569726],[-87.311316,41.554727],[-87.22168,41.561172]]]},"properties":{"id":"46405","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.432359,41.613107],[-87.433101,41.648793],[-87.46238,41.678305],[-87.49033,41.657553],[-87.490326,41.657334],[-87.490318,41.617992],[-87.461456,41.610842],[-87.432359,41.613107]]]},"properties":{"id":"46312","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.41653,41.348018],[-87.526729,41.312383],[-87.526539,41.189335],[-87.446963,41.174354],[-87.437091,41.171431],[-87.375098,41.174125],[-87.353785,41.179971],[-87.345179,41.186454],[-87.354641,41.199636],[-87.333448,41.195402],[-87.340657,41.182566],[-87.290734,41.211531],[-87.309091,41.340081],[-87.41653,41.348018]]]},"properties":{"id":"46356","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.480941,41.592079],[-87.466683,41.569693],[-87.43254,41.566603],[-87.432359,41.613107],[-87.461456,41.610842],[-87.480941,41.592079]]]},"properties":{"id":"46323","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.525244,41.60136],[-87.525302,41.581531],[-87.480436,41.567444],[-87.466683,41.569693],[-87.480941,41.592079],[-87.525244,41.60136]]]},"properties":{"id":"46324","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.48943,41.42127],[-87.465593,41.421125],[-87.436378,41.460724],[-87.477153,41.475395],[-87.48943,41.42127]]]},"properties":{"id":"46373","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.837863,41.530179],[-86.854428,41.491823],[-86.81386,41.432776],[-86.717028,41.418818],[-86.676723,41.476406],[-86.68589,41.458253],[-86.703842,41.458292],[-86.698024,41.48766],[-86.695317,41.491313],[-86.837863,41.530179]]]},"properties":{"id":"46382","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.698024,41.48766],[-86.676723,41.476406],[-86.717028,41.418818],[-86.711754,41.375345],[-86.570525,41.360532],[-86.541092,41.389531],[-86.582024,41.489833],[-86.695317,41.491313],[-86.698024,41.48766]]]},"properties":{"id":"46532","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.082971,39.760318],[-86.049758,39.75491],[-86.008981,39.762147],[-86.009756,39.813329],[-86.064655,39.810965],[-86.085208,39.788865],[-86.082971,39.760318]]]},"properties":{"id":"46219","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.309732,39.662977],[-86.21384,39.756151],[-86.239071,39.764573],[-86.266848,39.76481],[-86.28903,39.763488],[-86.326584,39.671795],[-86.309732,39.662977]]]},"properties":{"id":"46241","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.082971,39.760318],[-86.085208,39.788865],[-86.131838,39.788151],[-86.136863,39.762389],[-86.082971,39.760318]]]},"properties":{"id":"46201","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.997452,39.891032],[-86.005725,39.851902],[-86.010198,39.84066],[-85.954013,39.869646],[-85.937587,39.927137],[-85.997452,39.891032]]]},"properties":{"id":"46236","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.15839,39.6351],[-86.157621,39.722456],[-86.189096,39.721878],[-86.242924,39.633744],[-86.15839,39.6351]]]},"properties":{"id":"46217","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.113678,40.815007],[-86.11518,40.828567],[-86.124026,40.822077],[-86.113678,40.815007]]]},"properties":{"id":"46958","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.509462,39.168096],[-86.370292,39.165448],[-86.371993,39.223556],[-86.428242,39.310773],[-86.512438,39.333935],[-86.532367,39.164592],[-86.526905,39.164307],[-86.521014,39.174089],[-86.509462,39.168096]],[[-86.512603,39.182412],[-86.507252,39.179774],[-86.510944,39.178845],[-86.512603,39.182412]]]},"properties":{"id":"47408","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.509462,39.168096],[-86.521014,39.174089],[-86.526905,39.164307],[-86.509462,39.168096]]]},"properties":{"id":"47405","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.507621,39.069544],[-86.506604,39.069295],[-86.505577,39.071134],[-86.508197,39.071097],[-86.507621,39.069544]]]},"properties":{"id":"47458","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.921384,39.947315],[-86.913281,39.958034],[-86.92147,39.961953],[-86.921384,39.947315]]]},"properties":{"id":"47965","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.526411,41.05879],[-87.352587,41.013524],[-87.301464,41.130119],[-87.375098,41.174125],[-87.437091,41.171431],[-87.439144,41.164455],[-87.446799,41.166426],[-87.446963,41.174354],[-87.526587,41.16609],[-87.526411,41.05879]]]},"properties":{"id":"46349","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.295788,40.955005],[-87.30219,40.955029],[-87.302239,40.94925],[-87.295088,40.949206],[-87.29506,40.955005],[-87.295788,40.955005]]]},"properties":{"id":"47964","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.323049,39.709351],[-87.308481,39.6662],[-87.175905,39.649653],[-87.086355,39.69309],[-87.01167,39.713953],[-87.010753,39.779561],[-87.009849,39.844833],[-87.131999,39.874813],[-87.235888,39.808002],[-87.293001,39.795598],[-87.325048,39.729138],[-87.323049,39.709351]]]},"properties":{"id":"47872","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.186278,39.953077],[-87.235888,39.808002],[-87.131999,39.874813],[-87.091051,39.945655],[-87.091006,39.953009],[-87.186278,39.953077]]]},"properties":{"id":"47859","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.607343,38.229837],[-86.646049,38.131775],[-86.604906,38.133522],[-86.567171,38.166441],[-86.544071,38.189803],[-86.607343,38.229837]]]},"properties":{"id":"47576","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.883199,38.197162],[-87.916716,38.15664],[-87.973204,38.063318],[-87.862284,38.056228],[-87.806991,38.111262],[-87.883199,38.197162]]]},"properties":{"id":"47631","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.651763,39.614608],[-85.630613,39.519417],[-85.594683,39.482933],[-85.553827,39.496685],[-85.578971,39.576504],[-85.581954,39.598479],[-85.594049,39.598628],[-85.628734,39.611174],[-85.651763,39.614608]]]},"properties":{"id":"46150","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.095963,41.724504],[-86.191073,41.722119],[-86.196615,41.694726],[-86.19645,41.660526],[-86.099889,41.675202],[-86.095963,41.724504]]]},"properties":{"id":"46545","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.270828,41.658853],[-86.307337,41.653752],[-86.215742,41.650761],[-86.215241,41.661315],[-86.229831,41.661335],[-86.270828,41.658853]]]},"properties":{"id":"46613","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.651807,39.663696],[-85.65182,39.647641],[-85.642299,39.647612],[-85.64231,39.660543],[-85.651807,39.663696]]]},"properties":{"id":"46144","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.264762,37.929194],[-87.268445,37.879164],[-87.081944,37.959338],[-87.132035,38.002876],[-87.264762,37.929194]]]},"properties":{"id":"47634","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.60117,39.135656],[-87.61941,39.172089],[-87.542437,38.975587],[-87.489906,39.012158],[-87.60117,39.135656]]]},"properties":{"id":"47861","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.61941,39.172089],[-87.60117,39.135656],[-87.523627,39.154345],[-87.488773,39.220711],[-87.567348,39.259318],[-87.604141,39.259458],[-87.61941,39.172089]]]},"properties":{"id":"47849","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.392105,39.015143],[-87.383837,39.021316],[-87.391752,39.021476],[-87.392105,39.015143]]]},"properties":{"id":"47865","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.761479,40.484487],[-86.76151,40.487387],[-86.767202,40.489403],[-86.765408,40.485857],[-86.761479,40.484487]]]},"properties":{"id":"47924","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.438525,39.862143],[-87.416579,39.852829],[-87.417551,39.884244],[-87.397055,39.88704],[-87.377574,39.879708],[-87.377173,39.879013],[-87.439389,39.959061],[-87.532308,40.011607],[-87.53302,39.910357],[-87.438525,39.862143]]]},"properties":{"id":"47928","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.499017,39.273928],[-87.493383,39.275905],[-87.497908,39.277905],[-87.499017,39.273928]]]},"properties":{"id":"47869","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.912121,39.922073],[-84.811674,39.964087],[-84.810825,40.005077],[-84.967324,40.005226],[-84.912121,39.922073]]]},"properties":{"id":"47341","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.223313,40.873817],[-85.241887,40.80686],[-85.204371,40.814815],[-85.223313,40.873817]]]},"properties":{"id":"46791","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.73324,40.88457],[-86.74829,40.883631],[-86.75536,40.86983],[-86.74613,40.869],[-86.7413,40.88348],[-86.73324,40.88457]]]},"properties":{"id":"47925","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.013559,40.699947],[-85.049434,40.648661],[-84.802282,40.615908],[-84.802369,40.674791],[-85.013559,40.699947]]]},"properties":{"id":"46711","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.142668,41.084027],[-85.126465,41.082828],[-85.087674,41.078944],[-85.106123,41.116842],[-85.149078,41.117864],[-85.142668,41.084027]]]},"properties":{"id":"46805","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.986367,41.229749],[-84.853719,41.240844],[-84.803589,41.271273],[-84.803845,41.304221],[-84.973073,41.296401],[-85.020002,41.259115],[-84.986367,41.229749]]]},"properties":{"id":"46788","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.271147,40.911713],[-85.147925,40.917108],[-85.16772,40.954591],[-85.257803,40.97477],[-85.285948,40.917043],[-85.284178,40.917044],[-85.280685,40.911611],[-85.271147,40.911713]]]},"properties":{"id":"46798","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.027719,39.345609],[-85.962564,39.304868],[-85.943607,39.295923],[-85.863833,39.324646],[-85.85746,39.349312],[-85.846268,39.384041],[-85.908263,39.459104],[-86.027856,39.38938],[-86.027719,39.345609]]]},"properties":{"id":"46124","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.883174,39.059783],[-85.890938,39.062231],[-85.889523,39.056746],[-85.883174,39.059783]]]},"properties":{"id":"47247","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.485629,40.161635],[-86.3459,40.16411],[-86.213832,40.216243],[-86.247301,40.244609],[-86.46779,40.17704],[-86.485629,40.161635]]]},"properties":{"id":"46050","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.396163,40.486743],[-86.375568,40.460945],[-86.375501,40.489007],[-86.396163,40.486743]]]},"properties":{"id":"46915","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.373697,40.628496],[-86.374023,40.585901],[-86.323073,40.549726],[-86.165266,40.605145],[-86.160001,40.649652],[-86.166623,40.678404],[-86.337838,40.649088],[-86.373697,40.628496]],[[-86.351421,40.568566],[-86.346633,40.569447],[-86.346702,40.565513],[-86.351243,40.566067],[-86.351421,40.568566]]]},"properties":{"id":"46932","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.036826,39.320595],[-86.965797,39.313922],[-86.947585,39.311641],[-86.906048,39.32639],[-86.997649,39.421042],[-87.044259,39.342508],[-87.036826,39.320595]]]},"properties":{"id":"47833","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.470967,38.294028],[-86.492904,38.269885],[-86.47648,38.238854],[-86.459455,38.243634],[-86.470967,38.294028]]]},"properties":{"id":"47123","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.065902,41.556131],[-85.13461,41.496612],[-85.128181,41.48949],[-84.99768,41.532556],[-85.065902,41.556131]]]},"properties":{"id":"46705","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.804484,41.500348],[-84.805591,41.603187],[-84.938484,41.580266],[-84.96857,41.530948],[-84.943221,41.49218],[-84.804484,41.500348]]]},"properties":{"id":"46742","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.330657,40.291646],[-85.288289,40.226791],[-85.245677,40.227279],[-85.204674,40.264051],[-85.195632,40.291144],[-85.195688,40.295341],[-85.219015,40.321044],[-85.285672,40.326242],[-85.330657,40.291646]]]},"properties":{"id":"47320","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.095963,41.724504],[-86.099889,41.675202],[-86.060611,41.593772],[-86.061074,41.663785],[-86.038627,41.680352],[-86.048763,41.713952],[-86.095963,41.724504]]]},"properties":{"id":"46561","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.815155,39.548687],[-84.87238,39.523453],[-84.815749,39.47945],[-84.815155,39.548687]]]},"properties":{"id":"47010","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.178366,41.259576],[-86.152832,41.172283],[-86.094522,41.172765],[-86.054542,41.196404],[-86.178366,41.259576]]]},"properties":{"id":"46570","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.091242,39.082076],[-87.240958,39.098954],[-87.240925,39.074424],[-87.240753,39.009056],[-87.16736,38.952232],[-87.081437,39.009027],[-87.091213,39.082076],[-87.091242,39.082076]]]},"properties":{"id":"47441","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.065166,39.956201],[-86.094801,39.926881],[-86.076484,39.926948],[-86.065166,39.956201]]],[[[-86.157845,39.930189],[-86.100506,39.926884],[-86.112427,39.934152],[-86.157889,39.94162],[-86.157845,39.930189]]]]},"properties":{"id":"46280","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.770915,39.915612],[-85.843003,39.885529],[-85.953478,39.840383],[-85.952944,39.812067],[-85.933333,39.775164],[-85.838295,39.710758],[-85.76808,39.699068],[-85.652033,39.71148],[-85.625551,39.78623],[-85.635478,39.844484],[-85.711014,39.930651],[-85.770915,39.915612]]]},"properties":{"id":"46140","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.651763,39.614608],[-85.628734,39.611174],[-85.61504,39.704235],[-85.652033,39.71148],[-85.76808,39.699068],[-85.786747,39.639997],[-85.651763,39.614608]],[[-85.651807,39.663696],[-85.64231,39.660543],[-85.642299,39.647612],[-85.65182,39.647641],[-85.651807,39.663696]]]},"properties":{"id":"46161","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.633939,40.0159],[-85.653002,40.004121],[-85.638775,39.945718],[-85.594272,39.916577],[-85.576075,40.004646],[-85.605235,40.02262],[-85.633939,40.0159]]]},"properties":{"id":"46056","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.951735,39.697049],[-85.952247,39.763125],[-86.008981,39.762147],[-86.049758,39.75491],[-86.047114,39.7065],[-86.044481,39.680598],[-85.951735,39.697049]]]},"properties":{"id":"46239","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.515566,39.922883],[-86.610901,39.8937],[-86.57673,39.834428],[-86.543033,39.834268],[-86.506028,39.922912],[-86.515566,39.922883]]]},"properties":{"id":"46149","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.362599,39.799979],[-86.326786,39.763774],[-86.310698,39.764047],[-86.298138,39.822519],[-86.328012,39.859269],[-86.362599,39.799979]]]},"properties":{"id":"46234","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.35552,39.631485],[-86.252024,39.633543],[-86.282862,39.651099],[-86.282862,39.654779],[-86.309732,39.662977],[-86.326584,39.671795],[-86.342372,39.654026],[-86.35552,39.631485]]]},"properties":{"id":"46113","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.280001,40.076587],[-85.333818,40.063121],[-85.329425,40.0354],[-85.252136,39.946842],[-85.185642,39.997249],[-85.280001,40.076587]]]},"properties":{"id":"47360","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.994573,40.405983],[-86.013137,40.347847],[-85.885745,40.291377],[-85.862364,40.392417],[-85.862117,40.406889],[-85.975506,40.406227],[-85.994573,40.405983]]]},"properties":{"id":"46076","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.192829,38.904533],[-86.112754,38.898408],[-86.200751,38.961857],[-86.192829,38.904533]]],[[[-85.948535,38.823006],[-86.095405,38.963452],[-86.093641,38.868966],[-86.025996,38.762097],[-85.992641,38.767401],[-85.948535,38.823006]]]]},"properties":{"id":"47220","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.930418,41.151036],[-86.969119,41.071343],[-86.979519,41.046901],[-86.796174,41.05633],[-86.797822,41.128663],[-86.872297,41.148192],[-86.930418,41.151036]]]},"properties":{"id":"47957","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.219015,40.321044],[-85.195688,40.295341],[-85.093534,40.307912],[-85.130647,40.387318],[-85.219015,40.321044]]]},"properties":{"id":"47373","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.980129,39.57591],[-85.93982,39.509567],[-85.925266,39.52361],[-85.95247,39.600787],[-85.971477,39.601314],[-85.980129,39.57591]]]},"properties":{"id":"46162","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.370499,38.674257],[-87.426127,38.558669],[-87.426006,38.540575],[-87.242097,38.54493],[-87.245586,38.594388],[-87.370499,38.674257]]]},"properties":{"id":"47557","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.664139,41.1388],[-85.623563,41.324113],[-85.652946,41.33372],[-85.666592,41.301879],[-85.708157,41.290813],[-85.765367,41.221279],[-85.74377,41.127498],[-85.724096,41.10307],[-85.664139,41.1388]]]},"properties":{"id":"46562","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.829687,41.23404],[-85.806659,41.203487],[-85.794427,41.224535],[-85.829687,41.23404]]]},"properties":{"id":"46590","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.560454,41.633029],[-85.481636,41.568853],[-85.214079,41.581421],[-85.189116,41.627424],[-85.194994,41.663861],[-85.279689,41.685002],[-85.310489,41.689389],[-85.496597,41.697229],[-85.560454,41.633029]]]},"properties":{"id":"46761","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.33674,41.567539],[-87.336192,41.523981],[-87.317003,41.523881],[-87.311316,41.554727],[-87.298131,41.569726],[-87.33674,41.567539]]]},"properties":{"id":"46409","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.317003,41.523881],[-87.25343,41.47101],[-87.219953,41.470702],[-87.157946,41.499992],[-87.191273,41.499872],[-87.177867,41.511171],[-87.157887,41.504063],[-87.163059,41.536023],[-87.22168,41.561172],[-87.311316,41.554727],[-87.317003,41.523881]]]},"properties":{"id":"46342","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.490326,41.657334],[-87.524743,41.660435],[-87.524642,41.625735],[-87.490318,41.617992],[-87.490326,41.657334]]]},"properties":{"id":"46327","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.477153,41.475395],[-87.490743,41.52315],[-87.525061,41.522729],[-87.526129,41.399327],[-87.48943,41.42127],[-87.477153,41.475395]]]},"properties":{"id":"46311","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.368351,41.47761],[-86.528733,41.577948],[-86.582375,41.56264],[-86.582024,41.489833],[-86.541092,41.389531],[-86.466324,41.389333],[-86.368351,41.47761]]]},"properties":{"id":"46574","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.528733,41.577948],[-86.368351,41.47761],[-86.329838,41.478099],[-86.318147,41.549653],[-86.372864,41.620948],[-86.414281,41.650172],[-86.487029,41.642125],[-86.528733,41.577948]]]},"properties":{"id":"46554","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.573202,38.795569],[-86.683407,38.755451],[-86.683371,38.721815],[-86.669937,38.71254],[-86.683185,38.702274],[-86.683102,38.686561],[-86.607666,38.687045],[-86.327141,38.688367],[-86.310058,38.733131],[-86.573202,38.795569]]]},"properties":{"id":"47446","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.774459,40.277572],[-85.843749,40.177917],[-85.843793,40.161372],[-85.729906,40.219621],[-85.774459,40.277572]]]},"properties":{"id":"46044","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.731594,40.034912],[-85.653002,40.004121],[-85.633939,40.0159],[-85.640245,40.086392],[-85.708359,40.080358],[-85.731594,40.034912]]]},"properties":{"id":"46013","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.863278,40.103861],[-85.862238,40.010719],[-85.805471,40.045957],[-85.863278,40.103861]]]},"properties":{"id":"46051","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.102713,39.825663],[-86.135495,39.797756],[-86.131838,39.788151],[-86.085208,39.788865],[-86.064655,39.810965],[-86.102713,39.825663]]]},"properties":{"id":"46218","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.160001,40.649652],[-86.165266,40.605145],[-86.032699,40.607501],[-86.014171,40.646748],[-86.160001,40.649652]],[[-86.108888,40.614048],[-86.108968,40.635816],[-86.099574,40.635845],[-86.099403,40.612648],[-86.108888,40.614048]]]},"properties":{"id":"46914","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.940585,40.707044],[-86.014171,40.646748],[-86.032699,40.607501],[-85.99578,40.564265],[-85.957602,40.564613],[-85.858217,40.661683],[-85.940585,40.707044]]]},"properties":{"id":"46911","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.799383,40.16919],[-86.695738,40.058526],[-86.695701,40.143527],[-86.771104,40.187354],[-86.799383,40.16919]]]},"properties":{"id":"47940","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.035469,40.164084],[-86.923263,40.164174],[-86.937478,40.215009],[-87.022687,40.240537],[-87.092546,40.236637],[-87.092563,40.214806],[-87.035469,40.164084]]]},"properties":{"id":"47967","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.771133,40.2734],[-86.809485,40.214856],[-86.771104,40.187354],[-86.695544,40.221646],[-86.695208,40.281342],[-86.746089,40.272795],[-86.755067,40.272783],[-86.771133,40.2734]]]},"properties":{"id":"47930","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.342337,41.182508],[-87.324979,41.159462],[-87.31438,41.173482],[-87.333538,41.173434],[-87.340657,41.182566],[-87.342337,41.182508]]]},"properties":{"id":"46381","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.120057,38.939607],[-85.135832,38.929578],[-85.13636,38.903183],[-85.098676,38.903011],[-85.099477,38.908681],[-85.120057,38.939607]]],[[[-85.044175,38.903],[-84.987978,38.875685],[-84.984555,38.86543],[-84.910425,38.849055],[-84.870749,38.900905],[-84.877624,39.031213],[-84.973605,38.952801],[-85.044175,38.903]]]]},"properties":{"id":"47040","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.621014,38.061047],[-86.602335,38.133561],[-86.604906,38.133522],[-86.646049,38.131775],[-86.716048,38.136128],[-86.774911,38.070242],[-86.784256,38.043444],[-86.805111,37.993633],[-86.756266,37.92116],[-86.603717,38.002045],[-86.621014,38.061047]]]},"properties":{"id":"47586","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.065602,41.389295],[-86.946606,41.361523],[-86.930296,41.376012],[-86.929432,41.492277],[-86.969266,41.574013],[-87.066473,41.564451],[-87.065602,41.389295]]]},"properties":{"id":"46383","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.157887,41.504063],[-87.177867,41.511171],[-87.191273,41.499872],[-87.157946,41.499992],[-87.157887,41.504063]]]},"properties":{"id":"46393","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.862284,38.056228],[-87.735342,38.015245],[-87.697765,38.051762],[-87.697093,38.108575],[-87.806991,38.111262],[-87.862284,38.056228]]]},"properties":{"id":"47638","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.013041,39.606742],[-87.014533,39.473572],[-86.944012,39.497985],[-86.917854,39.487082],[-86.903222,39.545897],[-87.013041,39.606742]]]},"properties":{"id":"46171","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.296863,39.099629],[-85.360288,39.00004],[-85.361597,38.986988],[-85.249875,38.957393],[-85.130102,38.960611],[-85.205702,39.056219],[-85.245257,39.074617],[-85.296863,39.099629]]]},"properties":{"id":"47042","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.176186,41.524646],[-86.156883,41.524743],[-86.156935,41.527934],[-86.176264,41.527938],[-86.176186,41.524646]]]},"properties":{"id":"46595","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.018026,38.118283],[-87.015407,38.094374],[-86.979242,38.114817],[-86.96847,38.11851],[-87.018026,38.118283]]]},"properties":{"id":"47552","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.979242,38.114817],[-86.866397,38.103962],[-86.845743,38.118002],[-86.904612,38.138694],[-86.96847,38.11851],[-86.979242,38.114817]]]},"properties":{"id":"47579","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.866397,38.103962],[-86.942879,38.015906],[-86.885072,37.964232],[-86.814744,37.998704],[-86.784256,38.043444],[-86.774911,38.070242],[-86.810352,38.111225],[-86.83471,38.111285],[-86.838356,38.111279],[-86.837331,38.113295],[-86.836862,38.117961],[-86.845743,38.118002],[-86.866397,38.103962]]]},"properties":{"id":"47531","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.084141,41.748382],[-84.806209,41.674584],[-85.090099,41.75967],[-85.084141,41.748382]]]},"properties":{"id":"46737","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.315498,39.157267],[-87.523627,39.154345],[-87.60117,39.135656],[-87.489906,39.012158],[-87.392105,39.015143],[-87.391752,39.021476],[-87.383837,39.021316],[-87.300037,39.031548],[-87.240925,39.074424],[-87.240958,39.098954],[-87.315498,39.157267]]]},"properties":{"id":"47882","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.523627,39.154345],[-87.315498,39.157267],[-87.240509,39.227418],[-87.280274,39.237069],[-87.488773,39.220711],[-87.523627,39.154345]],[[-87.294322,39.190339],[-87.315121,39.186298],[-87.308561,39.199426],[-87.294322,39.190339]]]},"properties":{"id":"47879","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.036178,40.412348],[-87.022633,40.258873],[-86.81899,40.214869],[-86.809485,40.214856],[-86.771133,40.2734],[-86.771043,40.282729],[-86.755067,40.272783],[-86.746089,40.272795],[-86.920889,40.399441],[-87.036178,40.412348]]]},"properties":{"id":"47909","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.896598,40.425272],[-86.878593,40.417339],[-86.867183,40.475611],[-86.896598,40.425272]]]},"properties":{"id":"47904","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.150734,40.287054],[-86.147539,40.288048],[-86.148151,40.290364],[-86.152125,40.289126],[-86.150734,40.287054]]]},"properties":{"id":"46045","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.87238,39.523453],[-84.815155,39.548687],[-84.814769,39.624832],[-84.891381,39.523707],[-84.87238,39.523453]]]},"properties":{"id":"47003","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.417551,39.884244],[-87.416579,39.852829],[-87.428995,39.834884],[-87.450765,39.767973],[-87.382139,39.724867],[-87.367988,39.867648],[-87.377173,39.879013],[-87.377574,39.879708],[-87.417551,39.884244]]]},"properties":{"id":"47854","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.389975,39.455619],[-87.248899,39.428064],[-87.238964,39.52062],[-87.351703,39.484465],[-87.385427,39.484362],[-87.389975,39.455619]]]},"properties":{"id":"47803","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.297635,39.387884],[-87.296271,39.391154],[-87.302996,39.391164],[-87.297635,39.387884]]]},"properties":{"id":"47871","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.417733,39.60157],[-87.420228,39.600624],[-87.419537,39.597831],[-87.417686,39.599257],[-87.417733,39.60157]]]},"properties":{"id":"47880","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.731512,41.041718],[-85.739673,41.037365],[-85.738813,41.033344],[-85.723211,41.038989],[-85.731512,41.041718]]]},"properties":{"id":"46946","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.994617,39.863347],[-85.079049,39.826274],[-85.08161,39.819344],[-85.101735,39.714968],[-85.053292,39.714819],[-84.965352,39.726747],[-84.994617,39.863347]]]},"properties":{"id":"47330","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.142429,39.981288],[-85.107216,39.917438],[-85.053057,39.938452],[-85.070913,39.990147],[-85.121648,40.004262],[-85.142429,39.981288]]]},"properties":{"id":"47339","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.851407,40.712203],[-86.83388,40.80723],[-86.93033,40.8232],[-87.0234,40.69201],[-86.851407,40.712203]]]},"properties":{"id":"47980","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.851407,40.712203],[-87.0234,40.69201],[-87.01896,40.64873],[-86.81262,40.64934],[-86.851407,40.712203]]]},"properties":{"id":"47929","state_code":"18"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.585586,40.957256],[-94.675988,41.158068],[-94.748032,41.144089],[-94.834081,41.158453],[-94.927576,41.137294],[-94.910171,41.052495],[-94.804742,40.842358],[-94.719334,40.833779],[-94.700304,40.845253],[-94.585586,40.957256]],[[-94.814981,41.05506],[-94.814949,41.044217],[-94.832923,41.044172],[-94.832951,41.055194],[-94.814981,41.05506]]]},"properties":{"id":"50841","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.432791,43.167879],[-91.604451,43.22221],[-91.673662,43.197808],[-91.673658,43.190559],[-91.637631,43.058546],[-91.605958,43.000987],[-91.503621,42.970958],[-91.489164,42.974861],[-91.432791,43.167879]]]},"properties":{"id":"52162","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.637898,40.590853],[-92.638891,40.768938],[-92.695798,40.768756],[-92.78114,40.750591],[-92.771373,40.68048],[-92.757123,40.588902],[-92.637898,40.590853]]]},"properties":{"id":"52572","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.659401,42.077912],[-93.659369,42.137519],[-93.737502,42.166645],[-93.737168,41.97961],[-93.736919,41.950533],[-93.639113,41.972085],[-93.630711,42.022839],[-93.639334,42.022747],[-93.639493,42.02275],[-93.644728,42.032288],[-93.659401,42.077912]]]},"properties":{"id":"50014","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.281169,42.122734],[-94.164765,42.122807],[-94.106055,42.166366],[-94.106178,42.202587],[-94.115796,42.209916],[-94.223044,42.209839],[-94.397561,42.209125],[-94.378122,42.151676],[-94.281169,42.122734]]]},"properties":{"id":"50217","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.17338,42.729522],[-92.311441,42.887689],[-92.337408,42.907064],[-92.358113,42.8776],[-92.317592,42.729926],[-92.297676,42.729847],[-92.17338,42.729522]]]},"properties":{"id":"50676","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.297676,42.729847],[-92.258549,42.6422],[-92.179965,42.642119],[-92.140759,42.714697],[-92.17338,42.729522],[-92.297676,42.729847]]]},"properties":{"id":"50668","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.704943,42.686896],[-91.7212,42.570434],[-91.668044,42.55627],[-91.609048,42.556445],[-91.607059,42.643973],[-91.612138,42.68761],[-91.704943,42.686896]]]},"properties":{"id":"50650","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.071889,42.561099],[-95.05311,42.719887],[-95.111873,42.777805],[-95.229787,42.763269],[-95.280075,42.56084],[-95.19025,42.561055],[-95.071889,42.561099]]]},"properties":{"id":"50588","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.914487,42.47419],[-94.877099,42.348014],[-94.824843,42.368563],[-94.804997,42.368395],[-94.786313,42.442485],[-94.835635,42.488449],[-94.914487,42.47419]]]},"properties":{"id":"50561","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.455693,42.35417],[-94.435159,42.209282],[-94.397526,42.209161],[-94.397367,42.336269],[-94.455693,42.35417]]]},"properties":{"id":"50538","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.629098,42.049032],[-94.648452,42.049171],[-94.64848,42.043612],[-94.629117,42.039265],[-94.629098,42.049032]]]},"properties":{"id":"51459","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.853909,41.468228],[-94.911286,41.331184],[-94.795729,41.331104],[-94.776807,41.330959],[-94.853909,41.468228]]]},"properties":{"id":"50274","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.200442,41.672756],[-91.366448,41.598373],[-91.366876,41.553942],[-91.36768,41.510328],[-91.174818,41.503591],[-91.151067,41.519],[-91.200442,41.672756]]]},"properties":{"id":"52776","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.191386,41.847925],[-91.123041,41.837734],[-91.113219,41.91768],[-91.191062,41.917898],[-91.191386,41.847925]]]},"properties":{"id":"52337","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.35029,42.877055],[-93.320755,42.93168],[-93.379101,43.009487],[-93.457886,43.009627],[-93.477625,43.009697],[-93.497989,42.980818],[-93.458644,42.848644],[-93.379719,42.870442],[-93.35029,42.877055]]]},"properties":{"id":"50479","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.635993,42.995203],[-93.557585,42.937647],[-93.497989,42.980818],[-93.477625,43.009697],[-93.497812,43.038712],[-93.635993,42.995203]]]},"properties":{"id":"50449","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.50528,43.060939],[-95.564172,42.968131],[-95.554539,42.939162],[-95.554334,42.948398],[-95.546234,42.948462],[-95.56393,42.910101],[-95.467436,42.879536],[-95.38818,43.068528],[-95.50528,43.060939]]]},"properties":{"id":"51058","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.384442,43.213023],[-92.554381,43.212813],[-92.49536,43.156446],[-92.455686,43.127004],[-92.384442,43.213023]]]},"properties":{"id":"50603","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.554101,43.023236],[-92.49536,43.156446],[-92.554381,43.212813],[-92.594204,43.212783],[-92.68763,43.19848],[-92.83798,43.082904],[-92.798634,43.009041],[-92.762141,42.994472],[-92.653311,42.942996],[-92.554101,43.023236]],[[-92.594149,43.160929],[-92.587014,43.160967],[-92.586964,43.155915],[-92.594046,43.155868],[-92.594149,43.160929]]]},"properties":{"id":"50616","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.081063,43.141013],[-92.080976,43.212947],[-92.120999,43.213102],[-92.140963,43.212952],[-92.081063,43.141013]]],[[[-92.081063,43.141013],[-92.138594,43.023086],[-92.08148,42.979698],[-92.051106,42.964847],[-91.943208,43.030351],[-91.932473,43.063093],[-91.933366,43.069415],[-92.081063,43.141013]]]]},"properties":{"id":"52171","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.85342,40.897855],[-93.747722,40.81215],[-93.671469,40.856165],[-93.585285,40.877888],[-93.575683,40.898459],[-93.614586,40.965474],[-93.85342,40.897855]]]},"properties":{"id":"50264","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.275877,42.928672],[-91.293701,42.881959],[-91.285618,42.826535],[-91.272664,42.81223],[-91.094942,42.835708],[-91.147122,42.964454],[-91.250703,42.964157],[-91.275877,42.928672]]]},"properties":{"id":"52049","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.404011,41.996859],[-90.529697,42.038039],[-90.548659,42.032838],[-90.548338,41.920102],[-90.446723,41.891512],[-90.404011,41.996859]]]},"properties":{"id":"52731","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.404011,41.996859],[-90.446723,41.891512],[-90.378999,41.870617],[-90.334834,41.9026],[-90.368943,42.004298],[-90.404011,41.996859]]]},"properties":{"id":"52750","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.413223,41.801133],[-90.349647,41.801153],[-90.351624,41.808396],[-90.413223,41.801133]]]},"properties":{"id":"52757","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.801247,42.022127],[-90.792757,42.197358],[-90.871856,42.189995],[-90.860599,42.033405],[-90.859632,42.007754],[-90.801247,42.022127]]]},"properties":{"id":"52207","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.852864,41.643792],[-93.776986,41.614878],[-93.77391,41.649206],[-93.836385,41.658171],[-93.852864,41.643792]]]},"properties":{"id":"50323","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.280002,41.62578],[-94.168168,41.702926],[-94.181671,41.717326],[-94.202664,41.731726],[-94.251034,41.746215],[-94.28067,41.731711],[-94.280002,41.62578]]]},"properties":{"id":"50146","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.260866,41.80562],[-94.250928,41.863121],[-94.275252,41.913846],[-94.338296,41.86301],[-94.338311,41.820165],[-94.260866,41.80562]]]},"properties":{"id":"50128","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.169302,42.471257],[-91.212227,42.580923],[-91.212994,42.594857],[-91.278931,42.61121],[-91.341269,42.542663],[-91.321321,42.470768],[-91.220043,42.423874],[-91.18968,42.433809],[-91.169302,42.471257]]]},"properties":{"id":"52041","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.18968,42.433809],[-91.109872,42.321672],[-91.047951,42.360954],[-91.067905,42.429835],[-91.169302,42.471257],[-91.18968,42.433809]]]},"properties":{"id":"52078","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.199841,43.500857],[-95.25,43.396459],[-95.150358,43.375093],[-95.118789,43.37886],[-95.106842,43.371339],[-95.052372,43.342252],[-94.972173,43.370875],[-94.95422,43.430527],[-94.954232,43.433435],[-94.934626,43.500931],[-95.199841,43.500857]]]},"properties":{"id":"51360","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.106842,43.371339],[-95.14874,43.372169],[-95.150358,43.375093],[-95.25,43.396459],[-95.308855,43.298332],[-95.241487,43.255422],[-95.052457,43.255404],[-95.032853,43.255304],[-95.052372,43.342252],[-95.106842,43.371339]]]},"properties":{"id":"51351","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.636331,42.208236],[-90.593251,42.382113],[-90.665198,42.367474],[-90.687632,42.207742],[-90.636331,42.208236]]]},"properties":{"id":"52054","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963795,42.818354],[-92.068448,42.750626],[-92.040655,42.729564],[-91.981655,42.744129],[-91.943798,42.818305],[-91.963795,42.818354]]]},"properties":{"id":"50681","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.726321,42.896787],[-91.667599,42.817891],[-91.607713,42.79673],[-91.606982,42.853169],[-91.726321,42.896787]]]},"properties":{"id":"52169","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.673658,43.190559],[-91.722979,43.058357],[-91.637631,43.058546],[-91.673658,43.190559]]]},"properties":{"id":"52133","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.435154,42.593811],[-93.420643,42.593799],[-93.420424,42.601039],[-93.435154,42.593811]]]},"properties":{"id":"50227","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.844922,40.814646],[-95.774143,40.644472],[-95.713208,40.734658],[-95.718047,40.737889],[-95.844922,40.814646]]]},"properties":{"id":"51648","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.628988,42.15183],[-94.495186,42.074263],[-94.378122,42.151676],[-94.397561,42.209125],[-94.397526,42.209161],[-94.435159,42.209282],[-94.62881,42.180689],[-94.628988,42.15183]]]},"properties":{"id":"50050","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.865804,42.411685],[-92.76269,42.455903],[-92.755266,42.466533],[-92.829849,42.499337],[-92.865804,42.411685]]]},"properties":{"id":"50642","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.672847,42.528009],[-92.755266,42.466533],[-92.76269,42.455903],[-92.671032,42.391424],[-92.572838,42.42762],[-92.633197,42.527862],[-92.672847,42.528009]]]},"properties":{"id":"50624","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.710527,42.52776],[-92.716696,42.527741],[-92.716625,42.52411],[-92.711811,42.524144],[-92.710527,42.52776]]]},"properties":{"id":"50673","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.37114,41.626944],[-94.28364,41.620462],[-94.280002,41.62578],[-94.28067,41.731711],[-94.434985,41.75267],[-94.37114,41.626944]]]},"properties":{"id":"50216","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.069462,42.530171],[-94.098695,42.434971],[-94.068909,42.383697],[-93.931906,42.383705],[-93.971617,42.544219],[-93.971583,42.558139],[-93.991034,42.558189],[-94.069462,42.530171]]]},"properties":{"id":"50532","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.852173,43.255329],[-93.872042,43.30848],[-93.970331,43.284259],[-93.99017,43.208238],[-93.930808,43.169448],[-93.852173,43.255329]]]},"properties":{"id":"50484","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.49794,41.585605],[-95.574797,41.506162],[-95.507617,41.447637],[-95.383455,41.5638],[-95.450727,41.602475],[-95.49794,41.585605]]]},"properties":{"id":"51570","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.787049,40.798264],[-91.718455,40.74057],[-91.660506,40.755483],[-91.622234,40.799239],[-91.718647,40.864716],[-91.787049,40.798264]]]},"properties":{"id":"52630","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.853039,42.731705],[-93.852686,42.834283],[-93.91162,42.877615],[-94.01066,42.80385],[-94.049898,42.731621],[-94.010606,42.724573],[-93.853039,42.731705]]]},"properties":{"id":"50542","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.853039,42.731705],[-94.010606,42.724573],[-94.010703,42.644722],[-93.991034,42.558189],[-93.971583,42.558139],[-93.793473,42.629991],[-93.853039,42.731705]]]},"properties":{"id":"50533","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.381287,42.41417],[-95.362037,42.268565],[-95.323058,42.283065],[-95.322632,42.400266],[-95.342107,42.421386],[-95.381287,42.41417]]]},"properties":{"id":"51431","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.253812,41.531672],[-92.355389,41.509646],[-92.355047,41.452243],[-92.258422,41.423752],[-92.239325,41.438252],[-92.253812,41.531672]],[[-92.335715,41.45218],[-92.335804,41.459434],[-92.328927,41.459467],[-92.328397,41.453967],[-92.335715,41.45218]]]},"properties":{"id":"50136","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.377524,41.674225],[-92.258041,41.610711],[-92.358065,41.688488],[-92.377524,41.674225]]]},"properties":{"id":"52221","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.832094,41.654061],[-92.805166,41.845081],[-92.902893,41.819791],[-92.95378,41.819736],[-92.883537,41.645292],[-92.863168,41.620982],[-92.832094,41.654061]]]},"properties":{"id":"50135","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.150225,41.615429],[-93.234069,41.501249],[-93.281646,41.488856],[-93.250194,41.461066],[-93.158199,41.433357],[-93.131866,41.433028],[-92.969421,41.508317],[-92.967551,41.508216],[-93.037471,41.574637],[-93.11635,41.63701],[-93.150225,41.615429]]]},"properties":{"id":"50170","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.2058,41.776603],[-93.204529,41.777679],[-93.207239,41.777679],[-93.207254,41.776611],[-93.2058,41.776603]]]},"properties":{"id":"50127","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.042777,40.900061],[-91.970274,40.80135],[-91.900957,40.813578],[-91.880765,40.901232],[-92.039632,40.914606],[-92.042777,40.900061]]]},"properties":{"id":"52535","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.248435,42.007624],[-91.249768,42.004179],[-91.241883,42.004586],[-91.241976,42.007853],[-91.248435,42.007624]]]},"properties":{"id":"52312","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.180453,41.240476],[-92.179974,41.162662],[-92.042748,41.163438],[-92.011219,41.250945],[-92.058365,41.272579],[-92.180453,41.240476]]]},"properties":{"id":"52576","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.475042,41.423129],[-92.460507,41.408464],[-92.411393,41.37951],[-92.325468,41.339712],[-92.258422,41.423752],[-92.355047,41.452243],[-92.411911,41.466696],[-92.475042,41.423129]]]},"properties":{"id":"50268","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.364228,43.298293],[-94.285655,43.254694],[-94.152805,43.241518],[-94.118924,43.313246],[-94.167937,43.371205],[-94.364363,43.327305],[-94.384157,43.327308],[-94.364228,43.298293]]]},"properties":{"id":"50517","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.088833,43.458951],[-94.168179,43.385646],[-94.167937,43.371205],[-94.118924,43.313246],[-94.029632,43.320824],[-94.030112,43.444247],[-94.088833,43.458951]]]},"properties":{"id":"50451","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.150897,43.500105],[-94.088833,43.458951],[-94.030112,43.444247],[-94.030751,43.499955],[-94.150897,43.500105]]]},"properties":{"id":"56027","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.029632,43.320824],[-93.970331,43.284259],[-93.872042,43.30848],[-93.852317,43.327506],[-93.852262,43.499743],[-93.892288,43.499774],[-93.892185,43.473327],[-93.932087,43.473312],[-93.930401,43.499814],[-94.030751,43.499955],[-94.030112,43.444247],[-94.029632,43.320824]]]},"properties":{"id":"50424","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.444596,40.605988],[-91.508927,40.553141],[-91.500695,40.468104],[-91.36747,40.476227],[-91.369661,40.588822],[-91.444596,40.605988]]]},"properties":{"id":"52639","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.68502,42.05874],[-91.677592,42.089277],[-91.701396,42.092481],[-91.780414,42.073552],[-91.734256,42.000514],[-91.705079,42.011124],[-91.70127,42.034709],[-91.68502,42.05874]]]},"properties":{"id":"52411","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.253343,41.088259],[-93.24863,41.11031],[-93.260582,41.102584],[-93.253343,41.088259]]]},"properties":{"id":"50272","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.960608,43.500069],[-96.32966,43.5003],[-96.329666,43.447503],[-96.330328,43.433072],[-96.273755,43.346098],[-96.116487,43.346057],[-95.998198,43.418254],[-95.960608,43.500069]]]},"properties":{"id":"51246","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.874255,41.244553],[-93.770418,41.23521],[-93.673506,41.249051],[-93.692771,41.314905],[-93.736856,41.369251],[-93.745443,41.370333],[-93.790399,41.360388],[-93.790386,41.360352],[-93.849911,41.34335],[-93.908057,41.261369],[-93.874255,41.244553]]]},"properties":{"id":"50240","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.851163,41.24095],[-92.99459,41.238636],[-92.985019,41.196293],[-92.908265,41.160941],[-92.822697,41.161076],[-92.802726,41.161228],[-92.812617,41.21194],[-92.851163,41.24095]]]},"properties":{"id":"50044","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.082517,41.408693],[-92.970914,41.36078],[-92.875384,41.308879],[-92.870824,41.299342],[-92.798431,41.407631],[-92.785715,41.486554],[-92.824177,41.508664],[-92.871421,41.508522],[-92.967551,41.508216],[-92.969421,41.508317],[-93.082517,41.408693]]]},"properties":{"id":"50219","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.357349,41.36375],[-93.347223,41.291625],[-93.309379,41.286405],[-93.158199,41.433357],[-93.250194,41.461066],[-93.328236,41.436341],[-93.357219,41.421537],[-93.357349,41.36375]]]},"properties":{"id":"50225","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.970914,41.36078],[-92.981128,41.284494],[-92.875384,41.308879],[-92.970914,41.36078]]]},"properties":{"id":"50119","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.231645,42.077695],[-93.236498,41.954596],[-93.231765,41.931414],[-93.116275,41.942934],[-93.05869,42.007932],[-93.117171,42.084723],[-93.195286,42.106661],[-93.231618,42.10667],[-93.231645,42.077695]]]},"properties":{"id":"50247","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.117171,42.084723],[-93.05869,42.007932],[-92.999843,41.985751],[-92.947063,41.92998],[-92.902804,41.919992],[-92.867931,41.934913],[-92.867824,41.942234],[-92.858025,41.942262],[-92.858225,41.935002],[-92.765662,41.938822],[-92.747268,42.011681],[-92.766697,42.092883],[-92.786676,42.181256],[-92.854601,42.166634],[-92.933348,42.188081],[-92.962374,42.166328],[-93.088161,42.106529],[-93.117171,42.084723]],[[-92.785166,42.007347],[-92.76612,42.013955],[-92.766115,41.99932],[-92.785166,42.007347]]]},"properties":{"id":"50158","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.786676,42.181256],[-92.766697,42.092883],[-92.650258,42.138074],[-92.600975,42.152411],[-92.601375,42.239105],[-92.728612,42.296807],[-92.767969,42.282426],[-92.786676,42.181256]],[[-92.689897,42.260895],[-92.698468,42.260962],[-92.698454,42.268098],[-92.689898,42.26801],[-92.689897,42.260895]]]},"properties":{"id":"50635","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.017534,43.415047],[-93.018616,43.416793],[-93.019835,43.415062],[-93.017534,43.415047]]]},"properties":{"id":"50426","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.156074,40.871977],[-95.346608,40.930885],[-95.384964,40.90155],[-95.384704,40.829128],[-95.193866,40.771303],[-95.156074,40.871977]]]},"properties":{"id":"51638","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442856,42.848424],[-94.442954,42.908073],[-94.560435,42.937812],[-94.619872,42.881952],[-94.619938,42.87131],[-94.629414,42.871316],[-94.629452,42.878535],[-94.639488,42.878525],[-94.639114,42.790302],[-94.541025,42.747184],[-94.462606,42.790349],[-94.442856,42.848424]]]},"properties":{"id":"50581","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.037635,42.677811],[-95.860025,42.677397],[-95.860118,42.691848],[-95.859116,42.895035],[-95.95785,42.909774],[-96.056269,42.910005],[-96.037635,42.677811]]]},"properties":{"id":"51050","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.227384,42.623151],[-94.363775,42.645096],[-94.46291,42.630936],[-94.443022,42.573307],[-94.325054,42.537686],[-94.227384,42.623151]]]},"properties":{"id":"50524","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.596896,41.575385],[-93.529614,41.553889],[-93.503248,41.620979],[-93.529534,41.673095],[-93.535601,41.670454],[-93.535235,41.665315],[-93.546065,41.666772],[-93.574011,41.651092],[-93.583104,41.617359],[-93.5969,41.586831],[-93.596896,41.575385]]]},"properties":{"id":"50317","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.641901,41.582324],[-93.644851,41.588044],[-93.643734,41.595974],[-93.703595,41.595885],[-93.709817,41.591834],[-93.703592,41.562647],[-93.641901,41.582324]]]},"properties":{"id":"50312","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.577806,41.445955],[-93.461955,41.392898],[-93.374595,41.421606],[-93.441286,41.507589],[-93.443097,41.511843],[-93.529614,41.553889],[-93.573122,41.498959],[-93.577806,41.445955]]]},"properties":{"id":"50047","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.305943,41.949362],[-92.366047,41.992825],[-92.376175,41.984024],[-92.394957,41.987553],[-92.415221,42.001913],[-92.49326,41.978302],[-92.473909,41.862439],[-92.36092,41.819302],[-92.318086,41.8488],[-92.305943,41.949362]]]},"properties":{"id":"52215","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.64935,42.014295],[-92.712528,41.901382],[-92.688516,41.862282],[-92.610621,41.848224],[-92.512832,41.862305],[-92.473909,41.862439],[-92.49326,41.978302],[-92.64935,42.014295]]]},"properties":{"id":"52339","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.318086,41.8488],[-92.36092,41.819302],[-92.376978,41.775465],[-92.298816,41.797952],[-92.318086,41.8488]]]},"properties":{"id":"52232","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.914487,42.47419],[-94.91463,42.546848],[-95.041309,42.518073],[-95.089204,42.474347],[-95.108978,42.385911],[-94.916328,42.325375],[-94.877099,42.348014],[-94.914487,42.47419]]]},"properties":{"id":"50583","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.108978,42.385911],[-95.148623,42.369567],[-95.130521,42.326068],[-94.994343,42.238875],[-94.955596,42.253374],[-94.916328,42.325375],[-95.108978,42.385911]]]},"properties":{"id":"51450","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.451833,41.655356],[-90.451988,41.612219],[-90.37878,41.577078],[-90.339859,41.656599],[-90.432539,41.655856],[-90.451833,41.655356]]]},"properties":{"id":"52753","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.66604,41.646829],[-90.563565,41.618039],[-90.451833,41.655356],[-90.432539,41.655856],[-90.471235,41.698962],[-90.626698,41.683726],[-90.665778,41.676511],[-90.675682,41.654727],[-90.66604,41.646829]]]},"properties":{"id":"52748","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.689897,42.260895],[-92.689898,42.26801],[-92.698454,42.268098],[-92.698468,42.260962],[-92.689897,42.260895]]]},"properties":{"id":"50652","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.178468,40.769051],[-92.086011,40.747998],[-91.970274,40.80135],[-92.042777,40.900061],[-92.112985,40.878598],[-92.178387,40.835569],[-92.17836,40.802915],[-92.178468,40.769051]]]},"properties":{"id":"52551","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.479902,40.681738],[-93.470701,40.609103],[-93.393856,40.608773],[-93.479902,40.681738]]]},"properties":{"id":"50052","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.397367,42.336269],[-94.397526,42.209161],[-94.397561,42.209125],[-94.223044,42.209839],[-94.164446,42.296227],[-94.222926,42.325368],[-94.393013,42.354219],[-94.397367,42.336269]]]},"properties":{"id":"50543","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.991034,42.558189],[-94.010703,42.644722],[-94.089967,42.597963],[-94.089921,42.565252],[-94.069462,42.530171],[-93.991034,42.558189]]]},"properties":{"id":"50594","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.098695,42.434971],[-94.145602,42.371642],[-94.068909,42.383697],[-94.098695,42.434971]]]},"properties":{"id":"50569","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.497353,43.499622],[-93.576522,43.499644],[-93.636962,43.429888],[-93.577078,43.338313],[-93.51771,43.313456],[-93.398963,43.405711],[-93.497353,43.499622]]]},"properties":{"id":"50450","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398963,43.405711],[-93.51771,43.313456],[-93.497653,43.277148],[-93.438158,43.26992],[-93.378993,43.342609],[-93.374528,43.371626],[-93.398963,43.405711]]]},"properties":{"id":"50446","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.242484,42.37331],[-96.369402,42.308983],[-96.327706,42.249992],[-96.154405,42.286103],[-96.173324,42.354411],[-96.242484,42.37331]]]},"properties":{"id":"51052","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.840502,42.312156],[-96.010403,42.314872],[-96.000342,42.284266],[-95.884116,42.247762],[-95.815896,42.254979],[-95.840502,42.312156]]]},"properties":{"id":"51044","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.431915,41.598368],[-94.470964,41.417232],[-94.470987,41.403149],[-94.433363,41.388554],[-94.415206,41.602349],[-94.431915,41.598368]]]},"properties":{"id":"50164","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.260714,41.200397],[-94.241637,41.157134],[-94.203903,41.157039],[-94.127617,41.22585],[-94.241705,41.243738],[-94.260714,41.200397]]]},"properties":{"id":"50155","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.753756,40.818497],[-92.78114,40.750591],[-92.695798,40.768756],[-92.71967,40.813867],[-92.753756,40.818497]]]},"properties":{"id":"52593","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.220604,42.210742],[-92.318321,42.297492],[-92.362402,42.259181],[-92.416512,42.137828],[-92.29913,42.079501],[-92.26078,42.065428],[-92.182965,42.079742],[-92.220604,42.210742]]]},"properties":{"id":"52224","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.218306,42.414759],[-92.210199,42.413669],[-92.212123,42.426202],[-92.219151,42.426306],[-92.218306,42.414759]]]},"properties":{"id":"50634","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.601375,42.239105],[-92.600975,42.152411],[-92.532881,42.122997],[-92.416512,42.137828],[-92.362402,42.259181],[-92.465092,42.297194],[-92.514232,42.311611],[-92.601375,42.239105]]]},"properties":{"id":"50675","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.620397,42.122822],[-93.540916,42.106728],[-93.50194,42.122957],[-93.507528,42.209274],[-93.505638,42.252833],[-93.605543,42.270762],[-93.604443,42.242075],[-93.595997,42.234858],[-93.60818,42.231122],[-93.608117,42.238329],[-93.717849,42.223836],[-93.747148,42.209429],[-93.737502,42.166645],[-93.659369,42.137519],[-93.620397,42.122822]]]},"properties":{"id":"50248","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.145168,42.296204],[-94.115796,42.209916],[-94.106178,42.202587],[-93.979816,42.209955],[-93.931881,42.302979],[-94.125867,42.310428],[-94.145168,42.296204]]]},"properties":{"id":"50530","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.377403,42.877473],[-92.554451,42.788149],[-92.554444,42.773652],[-92.539326,42.690058],[-92.423323,42.679548],[-92.317592,42.729926],[-92.358113,42.8776],[-92.377403,42.877473]]]},"properties":{"id":"50677","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.668044,42.55627],[-91.656925,42.363255],[-91.59743,42.344769],[-91.539644,42.420293],[-91.578861,42.541986],[-91.609048,42.556445],[-91.668044,42.55627]]]},"properties":{"id":"50654","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.475425,42.44791],[-94.645965,42.473739],[-94.67838,42.473794],[-94.786313,42.442485],[-94.804997,42.368395],[-94.629504,42.310591],[-94.475368,42.368597],[-94.475425,42.44791]]]},"properties":{"id":"50579","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.955596,42.253374],[-94.916708,42.209856],[-94.803304,42.209752],[-94.824843,42.368563],[-94.877099,42.348014],[-94.916328,42.325375],[-94.955596,42.253374]]]},"properties":{"id":"51433","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.976216,41.996871],[-94.97439,42.003694],[-94.976155,42.008057],[-94.976216,41.996871]]]},"properties":{"id":"51444","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.365925,41.768958],[-91.366448,41.598373],[-91.200442,41.672756],[-91.185247,41.693325],[-91.308357,41.778385],[-91.365925,41.768958]]]},"properties":{"id":"52358","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.093087,41.961417],[-90.924971,41.920658],[-90.898484,41.946245],[-90.859632,42.007754],[-90.860599,42.033405],[-90.912891,42.033591],[-91.065613,42.003905],[-91.093087,41.961417]]]},"properties":{"id":"52323","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.102687,43.104311],[-92.925325,43.111897],[-92.924805,43.22721],[-93.024217,43.241878],[-93.082548,43.22005],[-93.083406,43.205786],[-93.102687,43.104311]]]},"properties":{"id":"50458","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.102378,43.052619],[-93.290298,43.082923],[-93.320217,43.046401],[-93.281119,42.951096],[-93.123484,42.922061],[-93.063041,42.994572],[-93.102378,43.052619]]]},"properties":{"id":"50469","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.024217,43.241878],[-93.024123,43.284565],[-93.099984,43.284139],[-93.141565,43.255106],[-93.091742,43.215145],[-93.082548,43.22005],[-93.024217,43.241878]]]},"properties":{"id":"50464","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.760016,42.662984],[-95.741036,42.909713],[-95.800492,42.909497],[-95.859116,42.895035],[-95.860118,42.691848],[-95.819949,42.611447],[-95.780446,42.633301],[-95.760016,42.662984]]]},"properties":{"id":"51035","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.239137,43.213455],[-92.384442,43.213023],[-92.455686,43.127004],[-92.337595,42.93631],[-92.199242,43.03757],[-92.239137,43.213455]]]},"properties":{"id":"50659","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.789196,41.162035],[-93.620267,41.143512],[-93.557556,41.161271],[-93.557654,41.175885],[-93.673506,41.249051],[-93.770418,41.23521],[-93.789196,41.162035]]]},"properties":{"id":"50210","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.614586,40.965474],[-93.620267,41.143512],[-93.789196,41.162035],[-93.85971,41.128361],[-93.900528,40.896761],[-93.85342,40.897855],[-93.614586,40.965474]]]},"properties":{"id":"50213","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.770418,41.23521],[-93.874255,41.244553],[-93.927548,41.156742],[-93.85971,41.128361],[-93.789196,41.162035],[-93.770418,41.23521]]]},"properties":{"id":"50257","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.900284,40.8532],[-93.900528,40.896761],[-94.014816,40.940306],[-94.014803,40.897031],[-94.01528,40.796553],[-93.957513,40.742478],[-93.910264,40.738536],[-93.863455,40.802714],[-93.900284,40.8532]]]},"properties":{"id":"50108","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.50528,43.060939],[-95.38818,43.068528],[-95.368525,43.068778],[-95.34846,43.098464],[-95.38809,43.284165],[-95.427431,43.313305],[-95.525058,43.314161],[-95.565225,43.257067],[-95.56487,43.157037],[-95.50528,43.060939]]]},"properties":{"id":"51346","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.308855,43.298332],[-95.387994,43.298669],[-95.38809,43.284165],[-95.34846,43.098464],[-95.259992,43.127057],[-95.241487,43.255422],[-95.308855,43.298332]]]},"properties":{"id":"51338","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.547178,42.876046],[-91.50361,42.796018],[-91.368983,42.753746],[-91.368468,42.755006],[-91.325929,42.800693],[-91.285618,42.826535],[-91.293701,42.881959],[-91.504501,42.931749],[-91.547178,42.876046]]]},"properties":{"id":"52043","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.605958,43.000987],[-91.740916,43.007424],[-91.725731,42.903586],[-91.726321,42.896787],[-91.606982,42.853169],[-91.547178,42.876046],[-91.504501,42.931749],[-91.503621,42.970958],[-91.605958,43.000987]]]},"properties":{"id":"52141","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.180267,43.080263],[-91.220945,43.080424],[-91.329535,43.008884],[-91.250703,42.964157],[-91.147122,42.964454],[-91.174408,43.038001],[-91.180267,43.080263]]]},"properties":{"id":"52157","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.801247,42.022127],[-90.859632,42.007754],[-90.898484,41.946245],[-90.791004,41.865621],[-90.762514,41.86562],[-90.732603,41.930993],[-90.733217,41.989374],[-90.801247,42.022127]]]},"properties":{"id":"52254","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.249259,42.156434],[-95.208368,41.994773],[-95.169404,41.99472],[-95.168766,42.167299],[-95.168398,42.210827],[-95.187661,42.210791],[-95.194444,42.210823],[-95.249259,42.156434]]]},"properties":{"id":"51465","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.768807,42.048434],[-95.72053,41.935701],[-95.653576,41.965107],[-95.64311,42.124425],[-95.768807,42.048434]]]},"properties":{"id":"51060","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.047509,41.704353],[-93.931003,41.615029],[-93.852994,41.677705],[-93.824378,41.742179],[-93.850728,41.760947],[-93.93095,41.776946],[-94.047509,41.704353]]]},"properties":{"id":"50063","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.012762,41.503191],[-94.007474,41.540797],[-94.059092,41.524933],[-94.012762,41.503191]]]},"properties":{"id":"50069","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.17836,40.802915],[-92.284557,40.899343],[-92.333101,40.906227],[-92.466188,40.899215],[-92.609871,40.78718],[-92.638891,40.768938],[-92.637898,40.590853],[-92.269758,40.598909],[-92.249575,40.693475],[-92.178468,40.769051],[-92.17836,40.802915]]]},"properties":{"id":"52537","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.284557,40.899343],[-92.17836,40.802915],[-92.178387,40.835569],[-92.178973,40.877554],[-92.284557,40.899343]]]},"properties":{"id":"52560","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.112985,40.878598],[-92.148058,40.894889],[-92.178973,40.877554],[-92.178387,40.835569],[-92.112985,40.878598]]]},"properties":{"id":"52588","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.671469,40.856165],[-93.632161,40.696092],[-93.556566,40.696268],[-93.556599,40.725053],[-93.585285,40.877888],[-93.671469,40.856165]]]},"properties":{"id":"50103","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.585285,40.877888],[-93.556599,40.725053],[-93.480388,40.724636],[-93.412929,40.884222],[-93.556613,40.927419],[-93.575683,40.898459],[-93.585285,40.877888]]]},"properties":{"id":"50123","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.180204,40.800257],[-91.256589,40.906796],[-91.256579,40.870755],[-91.180204,40.800257]]]},"properties":{"id":"52655","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.342429,40.743813],[-91.328146,40.741702],[-91.264326,40.63409],[-91.112467,40.696301],[-91.257696,40.75533],[-91.295474,40.775014],[-91.34987,40.792678],[-91.342427,40.744564],[-91.342429,40.743813]]]},"properties":{"id":"52658","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.737026,42.382057],[-90.764763,42.474703],[-90.833674,42.485635],[-90.862779,42.470597],[-90.898386,42.382302],[-90.763141,42.382023],[-90.737026,42.382057]]]},"properties":{"id":"52068","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.364213,43.500265],[-94.542522,43.500519],[-94.621063,43.399606],[-94.620688,43.378087],[-94.442693,43.327336],[-94.384157,43.327308],[-94.364363,43.327305],[-94.364213,43.500265]]]},"properties":{"id":"50514","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.810319,43.198173],[-92.869061,43.097395],[-92.83798,43.082904],[-92.68763,43.19848],[-92.804868,43.212904],[-92.810319,43.198173]]]},"properties":{"id":"50435","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.671453,40.923211],[-95.751761,40.919696],[-95.746388,40.901711],[-95.689819,40.828676],[-95.614244,40.872966],[-95.578244,40.901612],[-95.671453,40.923211]]]},"properties":{"id":"51653","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.001897,42.261681],[-93.001674,42.209267],[-92.933348,42.188081],[-92.854601,42.166634],[-92.84559,42.296454],[-92.924517,42.325117],[-93.001897,42.261681]]]},"properties":{"id":"50621","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.938216,42.375739],[-92.924517,42.325117],[-92.84559,42.296454],[-92.767969,42.282426],[-92.728612,42.296807],[-92.671032,42.391424],[-92.76269,42.455903],[-92.865804,42.411685],[-92.938216,42.375739]]]},"properties":{"id":"50638","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.501773,42.403283],[-93.505638,42.252833],[-93.507528,42.209274],[-93.424496,42.209283],[-93.366126,42.223791],[-93.405279,42.380343],[-93.462942,42.41259],[-93.501773,42.403283]],[[-93.405129,42.238302],[-93.405153,42.252781],[-93.395301,42.25279],[-93.395289,42.238292],[-93.405129,42.238302]]]},"properties":{"id":"50230","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.812336,43.227208],[-93.787332,43.225176],[-93.792639,43.218718],[-93.733764,43.198415],[-93.5271,43.198724],[-93.497564,43.213107],[-93.497635,43.255468],[-93.497653,43.277148],[-93.51771,43.313456],[-93.577078,43.338313],[-93.733993,43.327724],[-93.852317,43.327506],[-93.872042,43.30848],[-93.852173,43.255329],[-93.812336,43.227208]]]},"properties":{"id":"50436","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.5271,43.198724],[-93.733764,43.198415],[-93.734036,43.009667],[-93.6954,42.980936],[-93.635993,42.995203],[-93.497812,43.038712],[-93.5271,43.198724]]]},"properties":{"id":"50438","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.911257,42.908197],[-93.91162,42.877615],[-93.852686,42.834283],[-93.744188,42.84868],[-93.675663,42.908467],[-93.6954,42.980936],[-93.734036,43.009667],[-93.871886,43.0095],[-93.911257,42.908197]]]},"properties":{"id":"50447","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.001674,42.209267],[-93.001897,42.261681],[-93.118075,42.295665],[-93.117823,42.201503],[-93.103035,42.158299],[-93.053998,42.140452],[-93.001674,42.209267]]]},"properties":{"id":"50258","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.557042,41.759057],[-95.769757,41.863276],[-95.828356,41.812667],[-95.842424,41.790539],[-95.616922,41.646041],[-95.557805,41.689168],[-95.557042,41.759057]]]},"properties":{"id":"51579","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.773744,41.419523],[-95.62201,41.354893],[-95.594498,41.361622],[-95.59108,41.506031],[-95.689331,41.559174],[-95.755943,41.506397],[-95.784774,41.478191],[-95.773744,41.419523]]]},"properties":{"id":"51559","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.433385,41.190729],[-91.542043,41.16287],[-91.543628,41.142031],[-91.540293,41.131106],[-91.505545,41.075373],[-91.370646,41.084045],[-91.370256,41.175768],[-91.433385,41.190729]]]},"properties":{"id":"52659","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.56425,40.799098],[-91.406713,40.629578],[-91.342427,40.744564],[-91.34987,40.792678],[-91.409324,40.812682],[-91.516355,40.813411],[-91.56425,40.799098]],[[-91.521371,40.769661],[-91.5164,40.773312],[-91.516385,40.762393],[-91.521266,40.762346],[-91.521371,40.769661]]]},"properties":{"id":"52656","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.475706,43.500281],[-92.39579,43.428939],[-92.316282,43.500538],[-92.475706,43.500281]]]},"properties":{"id":"52134","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.193116,42.722846],[-94.207446,42.729587],[-94.207257,42.716635],[-94.193116,42.722846]]]},"properties":{"id":"50529","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.010703,42.644722],[-94.010606,42.724573],[-94.049898,42.731621],[-94.069538,42.731648],[-94.10991,42.659327],[-94.089967,42.597963],[-94.010703,42.644722]]]},"properties":{"id":"50591","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.187709,42.951438],[-94.090104,42.877402],[-94.050634,42.87791],[-93.990797,42.907799],[-94.034905,43.023434],[-94.249337,42.994988],[-94.187709,42.951438]]]},"properties":{"id":"50560","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.624064,42.407123],[-95.662345,42.5223],[-95.701352,42.523506],[-95.728456,42.385179],[-95.624064,42.407123]]]},"properties":{"id":"51018","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.832941,41.617163],[-91.834185,41.686702],[-91.911051,41.687758],[-91.968359,41.731011],[-91.987738,41.730991],[-91.993462,41.723423],[-92.007017,41.723868],[-92.183107,41.673928],[-92.182899,41.601299],[-92.052234,41.571216],[-91.832941,41.617163]]]},"properties":{"id":"52361","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.156922,41.570728],[-92.153505,41.57277],[-92.16285,41.573621],[-92.162784,41.570726],[-92.156922,41.570728]]]},"properties":{"id":"52308","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.435523,41.674041],[-92.450548,41.509437],[-92.431283,41.509483],[-92.355389,41.509646],[-92.253812,41.531672],[-92.21979,41.531705],[-92.221214,41.606458],[-92.254042,41.609149],[-92.258041,41.610711],[-92.377524,41.674225],[-92.435523,41.674041]]]},"properties":{"id":"52222","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.902804,41.919992],[-92.902893,41.819791],[-92.805166,41.845081],[-92.688516,41.862282],[-92.712528,41.901382],[-92.765662,41.938822],[-92.858225,41.935002],[-92.867931,41.934913],[-92.902804,41.919992]]]},"properties":{"id":"50106","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.234069,41.501249],[-93.150225,41.615429],[-93.270843,41.659122],[-93.348681,41.600999],[-93.234069,41.501249]]]},"properties":{"id":"50228","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.883537,41.645292],[-92.964205,41.601566],[-92.871421,41.508522],[-92.824177,41.508664],[-92.863168,41.620982],[-92.883537,41.645292]]]},"properties":{"id":"50251","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.037471,41.574637],[-92.967551,41.508216],[-92.871421,41.508522],[-92.964205,41.601566],[-93.037471,41.574637]]]},"properties":{"id":"50232","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.900957,40.813578],[-91.814049,40.783744],[-91.787049,40.798264],[-91.718647,40.864716],[-91.718268,40.900805],[-91.780527,40.929665],[-91.880765,40.901232],[-91.900957,40.813578]]]},"properties":{"id":"52651","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.71545,41.745774],[-91.714849,41.791151],[-91.778912,41.789089],[-91.831589,41.784548],[-91.834185,41.686702],[-91.832941,41.617163],[-91.789315,41.600079],[-91.711048,41.608508],[-91.646769,41.69418],[-91.71545,41.745774]]]},"properties":{"id":"52322","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.641223,41.845715],[-91.734191,41.87658],[-91.783101,41.832965],[-91.778912,41.789089],[-91.714849,41.791151],[-91.633178,41.809778],[-91.641223,41.845715]]]},"properties":{"id":"52338","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.868709,41.256333],[-91.773896,41.329441],[-91.838838,41.38013],[-91.946102,41.495087],[-91.96389,41.49373],[-92.024535,41.410193],[-92.021863,41.298428],[-92.058365,41.272579],[-92.011219,41.250945],[-91.91143,41.254568],[-91.868709,41.256333]]]},"properties":{"id":"52248","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.335715,41.45218],[-92.328397,41.453967],[-92.328927,41.459467],[-92.335804,41.459434],[-92.335715,41.45218]]]},"properties":{"id":"50255","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442688,43.155085],[-94.521213,43.046224],[-94.344374,43.024065],[-94.364007,43.141251],[-94.383844,43.168727],[-94.442688,43.155085]]]},"properties":{"id":"50598","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.5307,41.975945],[-91.618834,42.023998],[-91.661156,41.981672],[-91.642816,41.967349],[-91.599261,41.951554],[-91.509822,41.918296],[-91.5307,41.975945]]]},"properties":{"id":"52403","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.705079,42.011124],[-91.673969,41.982858],[-91.661156,41.981672],[-91.618834,42.023998],[-91.643445,42.06065],[-91.667937,42.055073],[-91.70127,42.034709],[-91.705079,42.011124]]]},"properties":{"id":"52402","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.696879,42.135494],[-91.779764,42.123835],[-91.780414,42.073552],[-91.701396,42.092481],[-91.673887,42.135343],[-91.696879,42.135494]]]},"properties":{"id":"52341","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.48537,41.249646],[-91.600805,41.252899],[-91.608029,41.20222],[-91.608058,41.197965],[-91.542043,41.16287],[-91.433385,41.190729],[-91.48537,41.249646]]]},"properties":{"id":"52621","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.097886,43.279499],[-96.116487,43.346057],[-96.273755,43.346098],[-96.351052,43.316179],[-96.354023,43.257853],[-96.247892,43.2576],[-96.097886,43.279499]]]},"properties":{"id":"51235","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.490323,43.223293],[-96.354023,43.257853],[-96.351052,43.316179],[-96.369449,43.389572],[-96.521671,43.385762],[-96.490323,43.223293]]]},"properties":{"id":"51240","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.329666,43.447503],[-96.338297,43.433061],[-96.330328,43.433072],[-96.329666,43.447503]]]},"properties":{"id":"51242","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.241506,41.341268],[-94.241486,41.287093],[-94.241705,41.243738],[-94.127617,41.22585],[-94.000304,41.22054],[-93.908057,41.261369],[-93.849911,41.34335],[-93.875467,41.349214],[-93.885084,41.346295],[-93.884535,41.352562],[-93.885443,41.388562],[-94.01411,41.493043],[-94.193322,41.361623],[-94.241506,41.341268]]]},"properties":{"id":"50273","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.203903,41.157039],[-94.111579,41.070122],[-94.030498,41.058289],[-93.965718,41.156739],[-94.000304,41.22054],[-94.127617,41.22585],[-94.203903,41.157039]]]},"properties":{"id":"50149","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.637619,41.512079],[-93.707322,41.512073],[-93.790572,41.429009],[-93.703198,41.422976],[-93.577806,41.445955],[-93.573122,41.498959],[-93.626708,41.512055],[-93.637619,41.512079]]]},"properties":{"id":"50211","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.617368,41.283885],[-92.607997,41.285412],[-92.608065,41.293923],[-92.622178,41.292527],[-92.617368,41.283885]]]},"properties":{"id":"52595","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.908265,41.160941],[-92.985019,41.196293],[-93.056672,41.169552],[-93.061494,41.160718],[-92.908265,41.160941]]]},"properties":{"id":"50116","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.236815,41.16081],[-93.099217,41.160867],[-93.061494,41.160718],[-93.056672,41.169552],[-93.175741,41.200951],[-93.234296,41.182613],[-93.236815,41.16081]]]},"properties":{"id":"50057","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.999843,41.985751],[-92.999755,41.877002],[-92.947063,41.92998],[-92.999843,41.985751]]]},"properties":{"id":"50120","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.079885,41.11618],[-95.229345,41.173907],[-95.269915,41.174039],[-95.308408,41.159741],[-95.346608,40.930885],[-95.156074,40.871977],[-95.156199,40.901035],[-95.079452,41.059693],[-95.079885,41.11618]]]},"properties":{"id":"51566","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.290998,41.42288],[-91.174818,41.503591],[-91.36768,41.510328],[-91.368521,41.423178],[-91.289777,41.409482],[-91.290998,41.42288]]]},"properties":{"id":"52766","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.564172,42.968131],[-95.50528,43.060939],[-95.56487,43.157037],[-95.703646,43.128032],[-95.70325,43.048058],[-95.564172,42.968131]]]},"properties":{"id":"51245","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.546234,42.948462],[-95.554334,42.948398],[-95.554539,42.939162],[-95.546234,42.948462]]]},"properties":{"id":"51009","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.623257,43.499905],[-95.584749,43.373705],[-95.525058,43.314161],[-95.427431,43.313305],[-95.499985,43.499971],[-95.623257,43.499905]]]},"properties":{"id":"51354","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.125793,40.629156],[-95.108184,40.61048],[-95.106239,40.623906],[-95.125793,40.629156]]]},"properties":{"id":"51637","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.638739,42.638928],[-94.618853,42.56],[-94.501366,42.645167],[-94.560251,42.688636],[-94.638739,42.638928]]]},"properties":{"id":"50571","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.503248,41.620979],[-93.432725,41.600676],[-93.406291,41.600418],[-93.406434,41.679645],[-93.522468,41.688399],[-93.529534,41.673095],[-93.503248,41.620979]]]},"properties":{"id":"50009","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.699215,41.863347],[-93.692443,41.870638],[-93.698056,41.877868],[-93.698038,41.870581],[-93.699215,41.863347]]]},"properties":{"id":"50243","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.84171,41.175858],[-95.86483,41.289964],[-95.872631,41.290028],[-95.90284,41.274374],[-95.914091,41.272428],[-95.84171,41.175858]]]},"properties":{"id":"51501","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.708291,41.332734],[-95.62201,41.354893],[-95.773744,41.419523],[-95.789204,41.40535],[-95.79435,41.350856],[-95.708291,41.332734]]]},"properties":{"id":"51576","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.376059,40.939973],[-94.453376,40.856179],[-94.47147,40.725934],[-94.471084,40.711825],[-94.407414,40.725822],[-94.321049,40.736766],[-94.242673,40.855152],[-94.337387,40.942227],[-94.376059,40.939973]]]},"properties":{"id":"50845","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.556651,40.790812],[-94.47147,40.725934],[-94.453376,40.856179],[-94.556651,40.790812]]]},"properties":{"id":"50840","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.170567,42.547184],[-95.089204,42.474347],[-95.041309,42.518073],[-95.071889,42.561099],[-95.19025,42.561055],[-95.170567,42.547184]]]},"properties":{"id":"50567","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.563565,41.618039],[-90.568427,41.55375],[-90.522386,41.546892],[-90.451988,41.612219],[-90.451833,41.655356],[-90.563565,41.618039]]]},"properties":{"id":"52807","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.712224,41.464292],[-90.786282,41.452888],[-90.710315,41.453494],[-90.712224,41.464292]]]},"properties":{"id":"52728","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.383455,41.5638],[-95.251531,41.534713],[-95.147057,41.592083],[-95.124843,41.6206],[-95.102648,41.68815],[-95.271722,41.761103],[-95.286094,41.761148],[-95.344727,41.76165],[-95.392578,41.71805],[-95.397484,41.718086],[-95.441354,41.732775],[-95.441551,41.67484],[-95.450727,41.602475],[-95.383455,41.5638]]]},"properties":{"id":"51537","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.534403,41.950459],[-93.46337,41.92834],[-93.356164,41.964712],[-93.329337,42.09265],[-93.366471,42.122953],[-93.424937,42.123156],[-93.50194,42.122957],[-93.540916,42.106728],[-93.534403,41.950459]]]},"properties":{"id":"50201","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.231645,42.077695],[-93.329337,42.09265],[-93.356164,41.964712],[-93.347683,41.95018],[-93.236498,41.954596],[-93.231645,42.077695]]]},"properties":{"id":"50056","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.639334,42.022747],[-93.633488,42.023102],[-93.63348,42.025069],[-93.63899,42.02539],[-93.639493,42.02275],[-93.639334,42.022747]]]},"properties":{"id":"50011","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.416512,42.137828],[-92.532881,42.122997],[-92.415408,42.029127],[-92.29913,42.079501],[-92.416512,42.137828]]]},"properties":{"id":"52217","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.747268,42.011681],[-92.765662,41.938822],[-92.712528,41.901382],[-92.64935,42.014295],[-92.663453,42.028745],[-92.747268,42.011681]]]},"properties":{"id":"50173","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.680586,40.791828],[-94.585763,40.798747],[-94.700304,40.845253],[-94.719334,40.833779],[-94.680586,40.791828]]]},"properties":{"id":"50862","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.044231,40.68895],[-92.121628,40.60159],[-91.969984,40.605125],[-92.024965,40.667137],[-92.044231,40.68895]]]},"properties":{"id":"52542","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.495676,41.306627],[-93.543104,41.190398],[-93.500089,41.182894],[-93.347223,41.291625],[-93.357349,41.36375],[-93.495676,41.306627]]]},"properties":{"id":"50166","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.461955,41.392898],[-93.495676,41.306627],[-93.357349,41.36375],[-93.357219,41.421537],[-93.374595,41.421606],[-93.461955,41.392898]]]},"properties":{"id":"50001","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.745443,41.370333],[-93.736856,41.369251],[-93.731475,41.37727],[-93.746024,41.377522],[-93.745443,41.370333]]]},"properties":{"id":"50160","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.600805,41.252899],[-91.48537,41.249646],[-91.484025,41.402045],[-91.638009,41.395936],[-91.600805,41.252899]]]},"properties":{"id":"52201","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.479902,40.681738],[-93.393856,40.608773],[-93.39851,40.580105],[-93.33395,40.580441],[-93.480388,40.724636],[-93.556599,40.725053],[-93.556566,40.696268],[-93.479902,40.681738]]]},"properties":{"id":"50008","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.380216,42.519183],[-96.410263,42.509178],[-96.407938,42.500157],[-96.374585,42.493273],[-96.332716,42.512017],[-96.380216,42.519183]]]},"properties":{"id":"51105","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.410263,42.509178],[-96.380216,42.519183],[-96.430397,42.541214],[-96.410263,42.509178]]]},"properties":{"id":"51104","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.557916,42.90869],[-93.675663,42.908467],[-93.744188,42.84868],[-93.617131,42.760262],[-93.538406,42.789283],[-93.498616,42.857255],[-93.557916,42.90869]]]},"properties":{"id":"50421","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.70069,41.547372],[-94.642762,41.366473],[-94.58572,41.403327],[-94.608154,41.616373],[-94.744221,41.645532],[-94.70069,41.547372]]]},"properties":{"id":"50002","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.527565,40.985807],[-94.376059,40.939973],[-94.337387,40.942227],[-94.30109,40.970885],[-94.241637,41.157134],[-94.260714,41.200397],[-94.547455,41.157806],[-94.527565,40.985807]],[[-94.466501,41.044073],[-94.456891,41.043367],[-94.457,41.036059],[-94.466478,41.036142],[-94.466501,41.044073]]]},"properties":{"id":"50801","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.58572,41.403327],[-94.642762,41.366473],[-94.642619,41.345483],[-94.652451,41.170274],[-94.604422,41.186625],[-94.546985,41.229973],[-94.470987,41.403149],[-94.470964,41.417232],[-94.58572,41.403327]]]},"properties":{"id":"50846","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.079452,41.059693],[-95.118458,40.900957],[-94.919477,40.84279],[-94.85266,40.827955],[-94.835523,40.833175],[-94.910171,41.052495],[-94.927576,41.137294],[-94.96628,41.173041],[-94.984891,41.158475],[-94.988452,41.144682],[-94.975,41.144619],[-94.975093,41.137397],[-94.988632,41.138547],[-95.079885,41.11618],[-95.079452,41.059693]]]},"properties":{"id":"50864","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.370261,43.189372],[-91.306411,43.12848],[-91.25008,43.278862],[-91.354513,43.292313],[-91.370261,43.189372]]]},"properties":{"id":"52170","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.891732,40.822921],[-93.021083,40.846928],[-93.001605,40.750641],[-92.891732,40.822921]]]},"properties":{"id":"52574","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.102648,41.68815],[-95.124843,41.6206],[-95.055525,41.631137],[-95.093161,41.688352],[-95.102648,41.68815]]]},"properties":{"id":"51543","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.305943,41.949362],[-92.318086,41.8488],[-92.298816,41.797952],[-92.240415,41.790105],[-92.202583,41.81776],[-92.200873,41.862701],[-92.211163,41.942616],[-92.279832,41.978716],[-92.305943,41.949362]]]},"properties":{"id":"52208","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.833285,42.176157],[-91.957868,42.067452],[-91.932333,42.021407],[-91.854831,42.036766],[-91.809503,42.160845],[-91.833285,42.176157]]]},"properties":{"id":"52332","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.41268,42.53062],[-92.437037,42.437681],[-92.370567,42.326485],[-92.347457,42.326445],[-92.221542,42.406667],[-92.218306,42.414759],[-92.219151,42.426306],[-92.219472,42.449641],[-92.282668,42.459364],[-92.335684,42.495299],[-92.41268,42.53062]]]},"properties":{"id":"50701","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.969725,41.964773],[-94.047985,41.906619],[-94.028249,41.863151],[-93.940678,41.795153],[-93.93095,41.776946],[-93.850728,41.760947],[-93.814992,41.798608],[-93.883157,41.950532],[-93.969725,41.964773]]]},"properties":{"id":"50276","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.261881,42.077995],[-94.261137,41.964594],[-94.16474,42.01536],[-94.164787,42.106968],[-94.261881,42.077995]]]},"properties":{"id":"50107","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.883157,41.950532],[-93.814992,41.798608],[-93.777976,41.801284],[-93.708092,41.863357],[-93.698038,41.870581],[-93.698056,41.877868],[-93.698134,41.921546],[-93.736919,41.950533],[-93.737168,41.97961],[-93.883157,41.950532]]]},"properties":{"id":"50156","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.106178,42.202587],[-94.106055,42.166366],[-93.984661,42.123118],[-93.96574,42.19515],[-93.979816,42.209955],[-94.106178,42.202587]]]},"properties":{"id":"50223","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.199242,43.03757],[-92.337595,42.93631],[-92.337408,42.907064],[-92.311441,42.887689],[-92.08148,42.979698],[-92.138594,43.023086],[-92.199242,43.03757]]]},"properties":{"id":"50630","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.761123,42.403146],[-91.786385,42.508305],[-91.835191,42.578139],[-91.993964,42.558313],[-92.019825,42.542779],[-91.986705,42.386216],[-91.944012,42.369822],[-91.774836,42.405261],[-91.761123,42.403146]]]},"properties":{"id":"50644","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.753105,42.372713],[-91.761123,42.403146],[-91.774836,42.405261],[-91.753105,42.372713]]]},"properties":{"id":"52326","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.824796,42.70196],[-91.864338,42.643247],[-91.825437,42.599997],[-91.764082,42.701606],[-91.804881,42.701931],[-91.824796,42.70196]]]},"properties":{"id":"50671","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.933439,42.909724],[-94.913869,42.83632],[-94.875009,42.733682],[-94.737791,42.776005],[-94.757406,42.909114],[-94.885584,42.953347],[-94.913823,42.953229],[-94.933439,42.909724]]]},"properties":{"id":"50554","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.966033,42.833133],[-92.925862,42.764817],[-92.849344,42.802985],[-92.966048,42.847629],[-92.966033,42.833133]]]},"properties":{"id":"50611","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.731766,42.657033],[-92.849078,42.686379],[-92.858542,42.499403],[-92.829849,42.499337],[-92.755266,42.466533],[-92.672847,42.528009],[-92.672822,42.636486],[-92.731766,42.657033]],[[-92.710527,42.52776],[-92.711811,42.524144],[-92.716625,42.52411],[-92.716696,42.527741],[-92.710527,42.52776]]]},"properties":{"id":"50665","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.025375,42.862107],[-93.074714,42.847585],[-93.094287,42.835802],[-93.058823,42.818658],[-92.966033,42.833133],[-92.966048,42.847629],[-93.025375,42.862107]]]},"properties":{"id":"50605","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.783843,42.181741],[-94.745396,41.956353],[-94.629273,41.964769],[-94.629117,42.039265],[-94.64848,42.043612],[-94.648452,42.049171],[-94.629098,42.049032],[-94.628988,42.15183],[-94.62881,42.180689],[-94.687362,42.180957],[-94.702028,42.180991],[-94.783843,42.181741]]]},"properties":{"id":"51443","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.213604,41.317826],[-94.965383,41.273476],[-95.155316,41.37513],[-95.213604,41.317826]]]},"properties":{"id":"51544","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.924971,41.920658],[-91.093087,41.961417],[-91.113219,41.91768],[-91.123041,41.837734],[-91.015513,41.78634],[-91.013016,41.800893],[-90.922951,41.917684],[-90.924971,41.920658]]]},"properties":{"id":"52216","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.307598,41.816319],[-91.191386,41.847925],[-91.191062,41.917898],[-91.190746,42.004577],[-91.288259,41.994956],[-91.307835,41.983994],[-91.307598,41.816319]]]},"properties":{"id":"52306","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398349,43.255389],[-93.438158,43.26992],[-93.497653,43.277148],[-93.497635,43.255468],[-93.398349,43.255389]]]},"properties":{"id":"50434","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.102378,43.052619],[-93.063041,42.994572],[-93.004984,42.980046],[-92.798634,43.009041],[-92.83798,43.082904],[-92.869061,43.097395],[-92.925325,43.111897],[-93.102687,43.104311],[-93.102378,43.052619]]]},"properties":{"id":"50468","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.320561,42.83354],[-93.094287,42.835802],[-93.074714,42.847585],[-93.123484,42.922061],[-93.281119,42.951096],[-93.320755,42.93168],[-93.35029,42.877055],[-93.320561,42.83354]]]},"properties":{"id":"50475","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.636251,42.882389],[-95.681298,42.764407],[-95.690009,42.662913],[-95.536402,42.605165],[-95.505828,42.605463],[-95.466083,42.864735],[-95.466954,42.864724],[-95.622845,42.895705],[-95.636251,42.882389]]]},"properties":{"id":"51012","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.536402,42.605165],[-95.603877,42.576201],[-95.662345,42.5223],[-95.624064,42.407123],[-95.575116,42.39352],[-95.438732,42.469772],[-95.486212,42.576417],[-95.505828,42.605463],[-95.536402,42.605165]]]},"properties":{"id":"51025","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.860025,42.677397],[-95.937792,42.51853],[-95.810756,42.560819],[-95.819949,42.611447],[-95.860118,42.691848],[-95.860025,42.677397]]]},"properties":{"id":"51048","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.219742,43.241247],[-92.239137,43.213455],[-92.199242,43.03757],[-92.138594,43.023086],[-92.081063,43.141013],[-92.140963,43.212952],[-92.219742,43.241247]]]},"properties":{"id":"52154","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.014816,40.940306],[-93.900528,40.896761],[-93.85971,41.128361],[-93.927548,41.156742],[-93.965718,41.156739],[-94.030498,41.058289],[-94.014816,40.940306]]]},"properties":{"id":"50174","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.052457,43.255404],[-95.057351,43.067212],[-95.043205,43.018512],[-95.033346,43.012028],[-94.973842,43.024646],[-94.913661,43.035967],[-94.959725,43.211149],[-95.032853,43.255304],[-95.052457,43.255404]]]},"properties":{"id":"51333","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.057351,43.067212],[-95.19145,43.024866],[-95.184034,43.010313],[-95.116888,42.968133],[-95.044415,43.006903],[-95.043205,43.018512],[-95.057351,43.067212]]]},"properties":{"id":"51343","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.033346,43.012028],[-95.043205,43.018512],[-95.044415,43.006903],[-95.033346,43.012028]]]},"properties":{"id":"51341","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.607713,42.79673],[-91.551826,42.758808],[-91.50361,42.796018],[-91.547178,42.876046],[-91.606982,42.853169],[-91.607713,42.79673]]]},"properties":{"id":"52077","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.349647,41.801153],[-90.413223,41.801133],[-90.446583,41.763844],[-90.434205,41.756867],[-90.433093,41.755892],[-90.316562,41.728928],[-90.24238,41.782964],[-90.349647,41.801153]]]},"properties":{"id":"52730","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.687632,42.207742],[-90.776263,42.205153],[-90.792757,42.197358],[-90.801247,42.022127],[-90.733217,41.989374],[-90.548659,42.032838],[-90.529697,42.038039],[-90.54904,42.101856],[-90.525157,42.139425],[-90.586748,42.153073],[-90.597577,42.149735],[-90.596776,42.156943],[-90.636331,42.208236],[-90.687632,42.207742]]]},"properties":{"id":"52060","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.562517,42.210908],[-95.594239,42.211071],[-95.652231,42.161346],[-95.64311,42.124425],[-95.653576,41.965107],[-95.614559,41.979359],[-95.534718,42.027785],[-95.478998,42.095494],[-95.562517,42.210908]]]},"properties":{"id":"51439","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.809702,41.53329],[-93.815631,41.600449],[-93.852864,41.643792],[-93.836385,41.658171],[-93.852994,41.677705],[-93.931003,41.615029],[-93.897193,41.531253],[-93.863485,41.520249],[-93.827575,41.51949],[-93.809702,41.53329]]]},"properties":{"id":"50263","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.156969,41.688364],[-94.150592,41.589859],[-94.059092,41.524933],[-94.007474,41.540797],[-93.950447,41.533947],[-93.897193,41.531253],[-93.931003,41.615029],[-94.047509,41.704353],[-94.156969,41.688364]]]},"properties":{"id":"50003","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.950447,41.533947],[-93.863485,41.520249],[-93.897193,41.531253],[-93.950447,41.533947]]]},"properties":{"id":"50038","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.237196,40.965376],[-91.042159,40.964374],[-91.006736,40.985935],[-91.070191,41.073014],[-91.198188,41.073414],[-91.256134,41.00152],[-91.237196,40.965376]]]},"properties":{"id":"52637","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.237196,40.965376],[-91.256134,41.00152],[-91.352484,41.038233],[-91.352457,40.987072],[-91.271731,40.944272],[-91.264573,40.939976],[-91.237196,40.965376]]]},"properties":{"id":"52660","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.954232,43.433435],[-94.95422,43.430527],[-94.939278,43.429782],[-94.93926,43.437708],[-94.954232,43.433435]]]},"properties":{"id":"51363","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.499985,43.499971],[-95.427431,43.313305],[-95.38809,43.284165],[-95.387994,43.298669],[-95.387787,43.500226],[-95.499985,43.499971]]]},"properties":{"id":"51345","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.928257,43.069565],[-91.933366,43.069415],[-91.932473,43.063093],[-91.928257,43.069565]]]},"properties":{"id":"52166","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.869061,43.097395],[-92.810319,43.198173],[-92.924805,43.22721],[-92.925325,43.111897],[-92.869061,43.097395]]]},"properties":{"id":"50471","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.594149,43.160929],[-92.594046,43.155868],[-92.586964,43.155915],[-92.587014,43.160967],[-92.594149,43.160929]]]},"properties":{"id":"50620","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.320725,42.644015],[-93.309863,42.383214],[-93.280616,42.383126],[-93.148928,42.415525],[-93.134321,42.478347],[-93.184451,42.600241],[-93.20358,42.629391],[-93.243316,42.629569],[-93.25484,42.643755],[-93.320725,42.644015]]]},"properties":{"id":"50126","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.605485,42.697726],[-93.627399,42.629856],[-93.597397,42.564912],[-93.499497,42.572212],[-93.435154,42.593811],[-93.420424,42.601039],[-93.360143,42.644273],[-93.399929,42.702732],[-93.478207,42.73136],[-93.498766,42.745819],[-93.605485,42.697726]]]},"properties":{"id":"50071","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.164765,42.122807],[-94.281169,42.122734],[-94.261881,42.077995],[-94.164787,42.106968],[-94.164765,42.122807]]]},"properties":{"id":"50064","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.570418,41.776611],[-94.744042,41.746591],[-94.744219,41.688528],[-94.744198,41.659938],[-94.744221,41.645532],[-94.608154,41.616373],[-94.431915,41.598368],[-94.415206,41.602349],[-94.37114,41.626944],[-94.434985,41.75267],[-94.43529,41.776949],[-94.473722,41.784194],[-94.570418,41.776611]]]},"properties":{"id":"50115","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.792639,43.218718],[-93.787332,43.225176],[-93.812336,43.227208],[-93.792639,43.218718]]]},"properties":{"id":"50432","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.002336,41.809354],[-95.876455,41.761886],[-95.842424,41.790539],[-95.828356,41.812667],[-95.93701,41.863467],[-96.002336,41.809354]]]},"properties":{"id":"51564","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.920846,41.443449],[-95.90021,41.423038],[-95.784774,41.478191],[-95.755943,41.506397],[-95.867061,41.685974],[-95.881872,41.695645],[-95.905984,41.7087],[-95.934181,41.674711],[-96.11466,41.603703],[-95.920846,41.443449]]]},"properties":{"id":"51555","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.98249,41.965021],[-96.137076,41.950773],[-96.10911,41.836043],[-95.977721,41.899397],[-95.98249,41.965021]]]},"properties":{"id":"51523","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.717284,41.061359],[-91.778674,40.931799],[-91.780527,40.929665],[-91.718268,40.900805],[-91.50414,40.839299],[-91.434765,40.98744],[-91.505545,41.075373],[-91.540293,41.131106],[-91.549956,41.138352],[-91.543628,41.142031],[-91.542043,41.16287],[-91.608058,41.197965],[-91.717284,41.061359]]]},"properties":{"id":"52641","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.718268,40.900805],[-91.718647,40.864716],[-91.622234,40.799239],[-91.56425,40.799098],[-91.516355,40.813411],[-91.50414,40.839299],[-91.718268,40.900805]]]},"properties":{"id":"52649","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.748044,41.070359],[-91.778674,40.931799],[-91.717284,41.061359],[-91.716783,41.092124],[-91.748044,41.070359]]]},"properties":{"id":"52635","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.363775,42.645096],[-94.227384,42.623151],[-94.209502,42.630957],[-94.10991,42.659327],[-94.069538,42.731648],[-94.134953,42.801048],[-94.22729,42.834181],[-94.266551,42.826716],[-94.324866,42.755877],[-94.363775,42.645096]],[[-94.193116,42.722846],[-94.207257,42.716635],[-94.207446,42.729587],[-94.193116,42.722846]]]},"properties":{"id":"50548","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.134953,42.801048],[-94.069538,42.731648],[-94.049898,42.731621],[-94.01066,42.80385],[-94.050634,42.87791],[-94.090104,42.877402],[-94.134953,42.801048]]]},"properties":{"id":"50545","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.900153,41.54035],[-91.789744,41.580473],[-91.789315,41.600079],[-91.832941,41.617163],[-92.052234,41.571216],[-91.900153,41.54035]]]},"properties":{"id":"52325","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.974527,41.774655],[-91.968359,41.731011],[-91.911051,41.687758],[-91.898497,41.783602],[-91.974527,41.774655]]]},"properties":{"id":"52334","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.987738,41.730991],[-92.007017,41.723868],[-91.993462,41.723423],[-91.987738,41.730991]]]},"properties":{"id":"52220","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.057954,41.862627],[-92.95378,41.819736],[-92.902893,41.819791],[-92.902804,41.919992],[-92.947063,41.92998],[-92.999755,41.877002],[-93.057954,41.862627]]]},"properties":{"id":"50141","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.270843,41.659122],[-93.348423,41.719457],[-93.37238,41.725673],[-93.406434,41.679645],[-93.406291,41.600418],[-93.348681,41.600999],[-93.270843,41.659122]]]},"properties":{"id":"50169","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.037644,40.984863],[-92.039632,40.914606],[-91.880765,40.901232],[-91.780527,40.929665],[-91.778674,40.931799],[-91.748044,41.070359],[-91.888395,41.14746],[-92.004972,41.119762],[-92.114013,41.076273],[-92.037644,40.984863]],[[-91.963186,41.019645],[-91.966814,41.021007],[-91.966071,41.023418],[-91.963386,41.024612],[-91.963186,41.019645]]]},"properties":{"id":"52556","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.269462,41.010527],[-92.23653,40.971215],[-92.159915,40.928746],[-92.037644,40.984863],[-92.114013,41.076273],[-92.15441,41.126824],[-92.227445,41.061942],[-92.269462,41.010527]]]},"properties":{"id":"52533","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.011219,41.250945],[-92.042748,41.163438],[-92.004972,41.119762],[-91.888395,41.14746],[-91.91143,41.254568],[-92.011219,41.250945]]]},"properties":{"id":"52585","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.642851,41.565283],[-91.676873,41.410424],[-91.638009,41.395936],[-91.484025,41.402045],[-91.483995,41.423848],[-91.519828,41.51101],[-91.519356,41.554782],[-91.54155,41.558296],[-91.642851,41.565283]]]},"properties":{"id":"52327","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.033584,42.143951],[-91.034266,42.080438],[-90.902736,42.137638],[-90.948648,42.179569],[-91.014057,42.202991],[-91.033584,42.143951]]]},"properties":{"id":"52321","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.419001,42.007633],[-91.365585,41.97641],[-91.307835,41.983994],[-91.288259,41.994956],[-91.36478,42.070529],[-91.419001,42.007633]]]},"properties":{"id":"52305","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.131835,42.120996],[-91.131712,42.067504],[-91.034266,42.080438],[-91.033584,42.143951],[-91.131835,42.120996]]]},"properties":{"id":"52212","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.258422,41.423752],[-92.325468,41.339712],[-92.325904,41.264915],[-92.180453,41.240476],[-92.058365,41.272579],[-92.021863,41.298428],[-92.136711,41.398895],[-92.239325,41.438252],[-92.258422,41.423752]]]},"properties":{"id":"52591","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.285655,43.254694],[-94.286004,43.157165],[-94.088819,43.132149],[-94.049173,43.179057],[-94.152805,43.241518],[-94.285655,43.254694]]]},"properties":{"id":"50522","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.601255,40.574755],[-91.622986,40.54272],[-91.523703,40.455641],[-91.500695,40.468104],[-91.508927,40.553141],[-91.601255,40.574755]]]},"properties":{"id":"52619","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.342429,40.743813],[-91.343936,40.727714],[-91.328146,40.741702],[-91.342429,40.743813]]]},"properties":{"id":"52624","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.521371,40.769661],[-91.521266,40.762346],[-91.516385,40.762393],[-91.5164,40.773312],[-91.521371,40.769661]]]},"properties":{"id":"52657","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.937312,43.272371],[-95.743376,43.258141],[-95.703846,43.287423],[-95.683861,43.316388],[-95.860445,43.359993],[-95.937312,43.272371]]]},"properties":{"id":"51232","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.717718,41.379073],[-92.555281,41.321856],[-92.460507,41.408464],[-92.475042,41.423129],[-92.555948,41.50915],[-92.642131,41.538117],[-92.747918,41.50871],[-92.785715,41.486554],[-92.798431,41.407631],[-92.717718,41.379073]]]},"properties":{"id":"50207","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.411911,41.466696],[-92.431283,41.509483],[-92.450548,41.509437],[-92.555948,41.50915],[-92.475042,41.423129],[-92.411911,41.466696]]]},"properties":{"id":"50027","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.175741,41.200951],[-93.280675,41.280736],[-93.234296,41.182613],[-93.175741,41.200951]],[[-93.246739,41.219013],[-93.245038,41.226285],[-93.2373,41.226221],[-93.237245,41.21517],[-93.246739,41.219013]]]},"properties":{"id":"50062","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.782079,41.146318],[-95.751761,40.919696],[-95.671453,40.923211],[-95.63168,41.08784],[-95.67105,41.131345],[-95.691918,41.160007],[-95.782079,41.146318]]]},"properties":{"id":"51534","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.52281,41.109487],[-95.556507,41.116734],[-95.501026,40.916072],[-95.422824,40.952384],[-95.442162,41.08782],[-95.52281,41.109487]]]},"properties":{"id":"51540","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.712433,43.500079],[-92.830258,43.499555],[-92.830394,43.428903],[-92.790577,43.400124],[-92.672623,43.414885],[-92.712433,43.500079]]]},"properties":{"id":"50476","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.001009,42.211761],[-96.001088,42.197152],[-95.941173,42.198729],[-96.001009,42.211761]]]},"properties":{"id":"51051","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.005417,42.125295],[-95.904758,42.002297],[-95.866237,41.979138],[-95.804464,42.063263],[-95.904022,42.175852],[-95.969636,42.168964],[-96.005417,42.125295]]]},"properties":{"id":"51010","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.301299,42.128065],[-96.154634,42.213879],[-96.154405,42.286103],[-96.327706,42.249992],[-96.301299,42.128065]]]},"properties":{"id":"51055","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.63976,41.063145],[-92.734728,40.93005],[-92.696439,40.898613],[-92.605877,40.898902],[-92.510674,40.917484],[-92.63976,41.063145]]]},"properties":{"id":"52536","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.302554,40.57956],[-95.131592,40.577503],[-95.108184,40.61048],[-95.125793,40.629156],[-95.125701,40.638686],[-95.256074,40.610912],[-95.302554,40.57956]]]},"properties":{"id":"51630","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.108184,40.61048],[-95.131592,40.577503],[-94.914896,40.575068],[-94.914742,40.631095],[-94.914732,40.635121],[-95.106239,40.623906],[-95.108184,40.61048]]]},"properties":{"id":"51631","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.817152,43.183587],[-94.821565,43.083053],[-94.796831,43.038652],[-94.698319,43.009649],[-94.57536,43.017898],[-94.580378,43.21818],[-94.817152,43.183587]]]},"properties":{"id":"50536","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.193563,42.649179],[-96.095714,42.649013],[-96.037635,42.677811],[-96.056269,42.910005],[-96.07839,42.909792],[-96.115642,42.90994],[-96.233533,42.909347],[-96.353613,42.865526],[-96.399479,42.764398],[-96.193563,42.649179]],[[-96.272287,42.807778],[-96.269574,42.814777],[-96.262566,42.809263],[-96.272287,42.807778]]]},"properties":{"id":"51031","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.095714,42.649013],[-96.174336,42.561425],[-96.115623,42.561313],[-95.957631,42.475074],[-95.937792,42.51853],[-95.860025,42.677397],[-96.037635,42.677811],[-96.095714,42.649013]]]},"properties":{"id":"51028","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.538458,42.908771],[-96.632784,42.761786],[-96.448701,42.735704],[-96.399479,42.764398],[-96.353613,42.865526],[-96.450376,42.909392],[-96.509695,42.909036],[-96.519373,42.909131],[-96.538458,42.908771]]]},"properties":{"id":"51001","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.541025,42.747184],[-94.639114,42.790302],[-94.737791,42.776005],[-94.875009,42.733682],[-94.854883,42.704189],[-94.736827,42.626831],[-94.638739,42.638928],[-94.560251,42.688636],[-94.541025,42.747184]]]},"properties":{"id":"50574","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.697614,41.65222],[-93.666634,41.650839],[-93.679061,41.687953],[-93.675486,41.704055],[-93.753511,41.754547],[-93.750402,41.721966],[-93.755933,41.651738],[-93.697614,41.65222]]]},"properties":{"id":"50131","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.784774,41.478191],[-95.90021,41.423038],[-95.789204,41.40535],[-95.773744,41.419523],[-95.784774,41.478191]]]},"properties":{"id":"51542","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.155316,41.37513],[-95.15485,41.433871],[-95.137164,41.534052],[-95.147057,41.592083],[-95.251531,41.534713],[-95.264473,41.418456],[-95.251306,41.33198],[-95.232236,41.317842],[-95.213604,41.317826],[-95.155316,41.37513]]]},"properties":{"id":"51577","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.471084,40.711825],[-94.642601,40.571437],[-94.389203,40.571453],[-94.404778,40.675517],[-94.407414,40.725822],[-94.471084,40.711825]]]},"properties":{"id":"50836","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.321049,40.736766],[-94.407414,40.725822],[-94.404778,40.675517],[-94.338027,40.681923],[-94.321049,40.736766]]]},"properties":{"id":"50835","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.109525,40.796921],[-94.01528,40.796553],[-94.014803,40.897031],[-94.105285,40.919135],[-94.142613,40.919219],[-94.133138,40.897561],[-94.109525,40.796921]]]},"properties":{"id":"50074","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.533035,41.524933],[-90.4339,41.548426],[-90.425302,41.558733],[-90.429344,41.580431],[-90.40573,41.568333],[-90.37878,41.577078],[-90.451988,41.612219],[-90.522386,41.546892],[-90.533035,41.524933]]]},"properties":{"id":"52722","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.40573,41.568333],[-90.429344,41.580431],[-90.425302,41.558733],[-90.4339,41.548426],[-90.40573,41.568333]]]},"properties":{"id":"52767","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.582969,41.525171],[-90.566017,41.518149],[-90.533035,41.524933],[-90.522386,41.546892],[-90.568427,41.55375],[-90.583259,41.552265],[-90.582914,41.528339],[-90.582969,41.525171]]]},"properties":{"id":"52803","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.397484,41.718086],[-95.392578,41.71805],[-95.393865,41.719623],[-95.397484,41.718086]]]},"properties":{"id":"51578","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.017403,43.062848],[-96.07839,42.909792],[-96.056269,42.910005],[-95.95785,42.909774],[-95.899558,43.025788],[-96.017403,43.062848]]]},"properties":{"id":"51003","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.505971,41.136615],[-92.496428,41.161623],[-92.505966,41.161588],[-92.505971,41.136615]]]},"properties":{"id":"52566","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.097454,40.74652],[-93.116788,40.862821],[-93.119396,40.898347],[-93.21207,40.811958],[-93.173515,40.724632],[-93.097454,40.74652]]]},"properties":{"id":"52583","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.636962,43.429888],[-93.696234,43.414631],[-93.733993,43.327724],[-93.577078,43.338313],[-93.636962,43.429888]]]},"properties":{"id":"50453","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.950985,43.202199],[-91.948049,43.206577],[-91.958083,43.210345],[-91.958085,43.206435],[-91.950985,43.202199]]]},"properties":{"id":"52168","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.617458,42.695281],[-93.675975,42.644556],[-93.627399,42.629856],[-93.605485,42.697726],[-93.617458,42.695281]]]},"properties":{"id":"50101","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.415206,41.602349],[-94.433363,41.388554],[-94.317789,41.364983],[-94.292517,41.556761],[-94.28364,41.620462],[-94.37114,41.626944],[-94.415206,41.602349]]]},"properties":{"id":"50250","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.527565,40.985807],[-94.547455,41.157806],[-94.604422,41.186625],[-94.652451,41.170274],[-94.675988,41.158068],[-94.585586,40.957256],[-94.527565,40.985807]]]},"properties":{"id":"50859","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.814981,41.05506],[-94.832951,41.055194],[-94.832923,41.044172],[-94.814949,41.044217],[-94.814981,41.05506]]]},"properties":{"id":"50839","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.700097,41.331044],[-94.776807,41.330959],[-94.795729,41.331104],[-94.834081,41.158453],[-94.748032,41.144089],[-94.700097,41.331044]]]},"properties":{"id":"50853","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.25008,43.278862],[-91.079573,43.22803],[-91.276007,43.453693],[-91.411939,43.432677],[-91.461016,43.392171],[-91.354513,43.292313],[-91.25008,43.278862]]]},"properties":{"id":"52151","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.576211,43.360153],[-91.604451,43.22221],[-91.432791,43.167879],[-91.386378,43.135276],[-91.370261,43.189372],[-91.354513,43.292313],[-91.461016,43.392171],[-91.576211,43.360153]]]},"properties":{"id":"52172","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.116788,40.862821],[-93.097454,40.74652],[-93.030193,40.740414],[-93.001605,40.750641],[-93.021083,40.846928],[-93.039242,40.870852],[-93.116788,40.862821]]]},"properties":{"id":"52581","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.098684,41.115686],[-93.119396,40.898347],[-93.116788,40.862821],[-93.039242,40.870852],[-92.983418,40.912536],[-92.98956,41.073656],[-93.098684,41.115686]]]},"properties":{"id":"52569","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.055525,41.631137],[-95.011657,41.616515],[-94.961504,41.660056],[-94.744219,41.688528],[-94.744042,41.746591],[-94.841419,41.862679],[-94.859761,41.862823],[-94.956337,41.862978],[-95.093338,41.760593],[-95.093265,41.731627],[-95.093161,41.688352],[-95.055525,41.631137]]]},"properties":{"id":"50025","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.744198,41.659938],[-94.961504,41.660056],[-95.011657,41.616515],[-95.040959,41.562859],[-94.971712,41.555307],[-94.801222,41.518739],[-94.70069,41.547372],[-94.744221,41.645532],[-94.744198,41.659938]]]},"properties":{"id":"50076","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.910281,42.260467],[-91.910412,42.216512],[-91.890829,42.248135],[-91.910281,42.260467]]]},"properties":{"id":"52345","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.220604,42.210742],[-92.182965,42.079742],[-92.144055,42.058039],[-92.124572,42.07961],[-92.142927,42.210611],[-92.220604,42.210742]]]},"properties":{"id":"52229","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.134691,41.949328],[-92.158419,41.86265],[-92.045957,41.891759],[-92.02717,41.949201],[-92.134691,41.949328]]]},"properties":{"id":"52209","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.742194,42.352566],[-91.948106,42.29749],[-91.959598,42.297503],[-91.910281,42.260467],[-91.890829,42.248135],[-91.64538,42.238872],[-91.62522,42.269832],[-91.656879,42.35482],[-91.742194,42.352566]]]},"properties":{"id":"52352","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.83147,41.86942],[-91.818388,41.876383],[-91.831643,41.883581],[-91.83147,41.86942]]]},"properties":{"id":"52351","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.809503,42.160845],[-91.854831,42.036766],[-91.83453,42.000514],[-91.734256,42.000514],[-91.780414,42.073552],[-91.779764,42.123835],[-91.809503,42.160845]]]},"properties":{"id":"52324","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.253485,42.470469],[-92.311709,42.481411],[-92.282668,42.459364],[-92.219472,42.449641],[-92.219603,42.457214],[-92.253485,42.470469]]]},"properties":{"id":"50707","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.423323,42.679548],[-92.539326,42.690058],[-92.554387,42.622449],[-92.554489,42.613578],[-92.372248,42.642287],[-92.423323,42.679548]]]},"properties":{"id":"50647","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.176368,41.863307],[-94.202664,41.731726],[-94.181671,41.717326],[-94.067432,41.776871],[-94.028249,41.863151],[-94.047985,41.906619],[-94.124065,41.950159],[-94.176368,41.863307]]]},"properties":{"id":"50220","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.931893,42.368647],[-93.931881,42.302979],[-93.979816,42.209955],[-93.96574,42.19515],[-93.835229,42.209643],[-93.815698,42.325106],[-93.931893,42.368647]]]},"properties":{"id":"50249","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.774836,42.405261],[-91.944012,42.369822],[-91.948106,42.29749],[-91.742194,42.352566],[-91.753105,42.372713],[-91.774836,42.405261]]]},"properties":{"id":"52329","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.835191,42.578139],[-91.786385,42.508305],[-91.7212,42.570434],[-91.704943,42.686896],[-91.764082,42.701606],[-91.825437,42.599997],[-91.835191,42.578139]]]},"properties":{"id":"50607","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.05311,42.719887],[-94.915135,42.676156],[-94.854883,42.704189],[-94.875009,42.733682],[-94.913869,42.83632],[-95.092506,42.821992],[-95.111873,42.777805],[-95.05311,42.719887]]]},"properties":{"id":"50510","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.116888,42.968133],[-95.184034,43.010313],[-95.21135,43.010463],[-95.190641,42.851142],[-95.092643,42.823683],[-95.033663,42.901692],[-95.116888,42.968133]]]},"properties":{"id":"50585","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.33942,42.836643],[-95.388084,42.605364],[-95.348095,42.561167],[-95.280075,42.56084],[-95.229787,42.763269],[-95.24945,42.814724],[-95.293702,42.851008],[-95.33942,42.836643]]]},"properties":{"id":"51002","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.24945,42.814724],[-95.229787,42.763269],[-95.111873,42.777805],[-95.092506,42.821992],[-95.092643,42.823683],[-95.190641,42.851142],[-95.24945,42.814724]]]},"properties":{"id":"50576","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.058823,42.818658],[-93.08491,42.730839],[-93.045773,42.709109],[-92.947103,42.671893],[-92.907491,42.700838],[-92.925862,42.764817],[-92.966033,42.833133],[-93.058823,42.818658]]]},"properties":{"id":"50625","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.475368,42.368597],[-94.629504,42.310591],[-94.687403,42.188064],[-94.687362,42.180957],[-94.62881,42.180689],[-94.435159,42.209282],[-94.455693,42.35417],[-94.475368,42.368597]]]},"properties":{"id":"51453","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.15485,41.433871],[-95.060146,41.548379],[-95.137164,41.534052],[-95.15485,41.433871]]]},"properties":{"id":"51552","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.200442,41.672756],[-91.151067,41.519],[-91.135318,41.533367],[-91.133307,41.645609],[-91.185247,41.693325],[-91.200442,41.672756]]]},"properties":{"id":"52720","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.191062,41.917898],[-91.113219,41.91768],[-91.093087,41.961417],[-91.065613,42.003905],[-91.137042,42.048429],[-91.190746,42.004577],[-91.191062,41.917898]]]},"properties":{"id":"52320","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.378993,43.342609],[-93.438158,43.26992],[-93.398349,43.255389],[-93.349169,43.255591],[-93.350028,43.291774],[-93.378993,43.342609]]]},"properties":{"id":"50444","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.467436,42.879536],[-95.56393,42.910101],[-95.622845,42.895705],[-95.466954,42.864724],[-95.467436,42.879536]]]},"properties":{"id":"51029","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.388084,42.605364],[-95.486212,42.576417],[-95.438732,42.469772],[-95.381287,42.41417],[-95.342107,42.421386],[-95.348095,42.561167],[-95.388084,42.605364]]]},"properties":{"id":"51020","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.111523,42.646093],[-91.167248,42.73254],[-91.250903,42.665933],[-91.278931,42.61121],[-91.212994,42.594857],[-91.111523,42.646093]]]},"properties":{"id":"52035","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.674827,42.577699],[-91.015101,42.71932],[-91.01509,42.717553],[-90.946922,42.682538],[-90.89759,42.595157],[-90.771882,42.575469],[-90.731222,42.582122],[-90.674827,42.577699]]]},"properties":{"id":"52073","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.733217,41.989374],[-90.732603,41.930993],[-90.626187,41.894685],[-90.599821,41.901938],[-90.595318,41.912178],[-90.590042,41.901883],[-90.548338,41.920102],[-90.548659,42.032838],[-90.733217,41.989374]]]},"properties":{"id":"52037","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.349647,41.801153],[-90.24238,41.782964],[-90.149599,41.977528],[-90.262711,42.0334],[-90.296436,42.004565],[-90.334834,41.9026],[-90.378999,41.870617],[-90.351624,41.808396],[-90.349647,41.801153]],[[-90.252374,41.975492],[-90.252474,41.990031],[-90.24269,41.989948],[-90.242685,41.975367],[-90.252374,41.975492]]]},"properties":{"id":"52732","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.599821,41.901938],[-90.590042,41.901883],[-90.595318,41.912178],[-90.599821,41.901938]]]},"properties":{"id":"52774","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.296151,42.18638],[-90.262711,42.0334],[-90.149599,41.977528],[-90.296151,42.18638]]]},"properties":{"id":"52070","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.440528,41.863236],[-95.36369,41.89287],[-95.41142,41.93423],[-95.493949,41.948166],[-95.482552,42.018598],[-95.534718,42.027785],[-95.614559,41.979359],[-95.440528,41.863236]]]},"properties":{"id":"51528","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.362037,42.268565],[-95.400992,42.210743],[-95.381973,42.167782],[-95.36257,42.138869],[-95.273584,42.148704],[-95.249259,42.156434],[-95.194444,42.210823],[-95.323058,42.283065],[-95.362037,42.268565]]]},"properties":{"id":"51448","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.168168,41.702926],[-94.280002,41.62578],[-94.28364,41.620462],[-94.292517,41.556761],[-94.184614,41.530776],[-94.150592,41.589859],[-94.156969,41.688364],[-94.168168,41.702926]]]},"properties":{"id":"50233","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.900284,40.8532],[-93.747722,40.81215],[-93.85342,40.897855],[-93.900528,40.896761],[-93.900284,40.8532]]]},"properties":{"id":"50262","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.800228,40.645478],[-93.863455,40.802714],[-93.910264,40.738536],[-93.855758,40.659354],[-93.800228,40.645478]]]},"properties":{"id":"50067","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.061149,42.520563],[-91.212227,42.580923],[-91.169302,42.471257],[-91.067905,42.429835],[-91.061149,42.520563]]]},"properties":{"id":"52040","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.043964,42.603215],[-91.111523,42.646093],[-91.212994,42.594857],[-91.212227,42.580923],[-91.061149,42.520563],[-91.015944,42.537156],[-91.015768,42.558966],[-91.043964,42.603215]]]},"properties":{"id":"52065","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.264573,40.939976],[-91.256617,40.908568],[-91.042159,40.964374],[-91.237196,40.965376],[-91.264573,40.939976]]]},"properties":{"id":"52650","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.409324,40.812682],[-91.271731,40.944272],[-91.352457,40.987072],[-91.434765,40.98744],[-91.50414,40.839299],[-91.516355,40.813411],[-91.409324,40.812682]]]},"properties":{"id":"52645","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.009435,41.16624],[-91.070191,41.073014],[-91.006736,40.985935],[-90.95546,40.986185],[-91.009435,41.16624]]]},"properties":{"id":"52646","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.387994,43.298669],[-95.308855,43.298332],[-95.25,43.396459],[-95.199841,43.500857],[-95.387787,43.500226],[-95.387994,43.298669]]]},"properties":{"id":"51347","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.932892,42.876206],[-91.963795,42.818354],[-91.943798,42.818305],[-91.854633,42.818004],[-91.884312,42.906366],[-91.932892,42.876206]]]},"properties":{"id":"52164","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.830394,43.428903],[-93.024092,43.342336],[-93.024123,43.284565],[-93.024217,43.241878],[-92.924805,43.22721],[-92.810319,43.198173],[-92.804868,43.212904],[-92.594135,43.255327],[-92.594048,43.284334],[-92.672756,43.400276],[-92.790577,43.400124],[-92.830394,43.428903]]]},"properties":{"id":"50461","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.594135,43.255327],[-92.804868,43.212904],[-92.68763,43.19848],[-92.594204,43.212783],[-92.594135,43.255327]]]},"properties":{"id":"50460","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.462942,42.41259],[-93.405279,42.380343],[-93.309863,42.383214],[-93.320725,42.644015],[-93.360143,42.644273],[-93.420424,42.601039],[-93.420643,42.593799],[-93.435154,42.593811],[-93.499497,42.572212],[-93.462942,42.41259]]]},"properties":{"id":"50006","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.379719,42.870442],[-93.478207,42.73136],[-93.399929,42.702732],[-93.369874,42.731057],[-93.370013,42.74558],[-93.360167,42.745608],[-93.360078,42.731038],[-93.320561,42.83354],[-93.35029,42.877055],[-93.379719,42.870442]]]},"properties":{"id":"50452","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.746388,40.901711],[-95.813465,40.901693],[-95.844922,40.814646],[-95.718047,40.737889],[-95.689819,40.828676],[-95.746388,40.901711]]]},"properties":{"id":"51654","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.713208,40.734658],[-95.599987,40.687076],[-95.537229,40.728495],[-95.543532,40.786859],[-95.614244,40.872966],[-95.689819,40.828676],[-95.718047,40.737889],[-95.713208,40.734658]]]},"properties":{"id":"51652","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.454377,41.921014],[-94.532282,41.877648],[-94.473722,41.784194],[-94.43529,41.776949],[-94.338311,41.820165],[-94.338296,41.86301],[-94.454377,41.921014]]]},"properties":{"id":"50026","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.231903,42.314022],[-93.118075,42.295665],[-93.001897,42.261681],[-92.924517,42.325117],[-92.938216,42.375739],[-92.963927,42.383496],[-93.148928,42.415525],[-93.280616,42.383126],[-93.231903,42.314022]]]},"properties":{"id":"50627","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.405129,42.238302],[-93.395289,42.238292],[-93.395301,42.25279],[-93.405153,42.252781],[-93.405129,42.238302]]]},"properties":{"id":"50102","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.616079,41.641425],[-95.689331,41.559174],[-95.59108,41.506031],[-95.574797,41.506162],[-95.49794,41.585605],[-95.616079,41.641425]]]},"properties":{"id":"51563","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.426148,43.356651],[-92.298865,43.320402],[-92.198875,43.500556],[-92.316282,43.500538],[-92.39579,43.428939],[-92.426148,43.356651]]]},"properties":{"id":"52155","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.824105,43.17737],[-91.903365,43.25528],[-92.062622,43.255562],[-92.095917,43.227616],[-92.080976,43.212947],[-91.844884,43.126507],[-91.824105,43.17737]],[[-91.950985,43.202199],[-91.958085,43.206435],[-91.958083,43.210345],[-91.948049,43.206577],[-91.950985,43.202199]]]},"properties":{"id":"52132","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.22729,42.834181],[-94.187709,42.951438],[-94.249337,42.994988],[-94.286136,42.966133],[-94.344547,42.826816],[-94.266551,42.826716],[-94.22729,42.834181]]]},"properties":{"id":"50519","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.10991,42.659327],[-94.209502,42.630957],[-94.089921,42.565252],[-94.089967,42.597963],[-94.10991,42.659327]]]},"properties":{"id":"50516","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442954,42.908073],[-94.442856,42.848424],[-94.364271,42.819396],[-94.344547,42.826816],[-94.286136,42.966133],[-94.305728,42.965941],[-94.442954,42.908073]]]},"properties":{"id":"50570","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.348095,42.561167],[-95.342107,42.421386],[-95.322632,42.400266],[-95.245,42.429092],[-95.170567,42.547184],[-95.19025,42.561055],[-95.280075,42.56084],[-95.348095,42.561167]]]},"properties":{"id":"51053","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.728456,42.385179],[-95.728998,42.356241],[-95.594239,42.211071],[-95.562517,42.210908],[-95.555382,42.21093],[-95.575116,42.39352],[-95.624064,42.407123],[-95.728456,42.385179]]]},"properties":{"id":"51006","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.974527,41.774655],[-91.898497,41.783602],[-91.831589,41.784548],[-91.778912,41.789089],[-91.783101,41.832965],[-91.831379,41.861851],[-91.947911,41.845405],[-91.974527,41.774655]],[[-91.904742,41.793141],[-91.90434,41.796076],[-91.89274,41.793974],[-91.901235,41.789608],[-91.904742,41.793141]]]},"properties":{"id":"52203","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.838838,41.38013],[-91.807643,41.394956],[-91.753726,41.434528],[-91.789744,41.580473],[-91.900153,41.54035],[-91.946102,41.495087],[-91.838838,41.38013]]]},"properties":{"id":"52356","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.902736,42.137638],[-90.912891,42.033591],[-90.860599,42.033405],[-90.871856,42.189995],[-90.898268,42.206475],[-90.948648,42.179569],[-90.902736,42.137638]]]},"properties":{"id":"52309","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.11635,41.63701],[-93.183239,41.746429],[-93.348328,41.744563],[-93.348423,41.719457],[-93.270843,41.659122],[-93.150225,41.615429],[-93.11635,41.63701]]]},"properties":{"id":"50054","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963186,41.019645],[-91.963386,41.024612],[-91.966071,41.023418],[-91.966814,41.021007],[-91.963186,41.019645]]]},"properties":{"id":"52557","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.355047,41.452243],[-92.355389,41.509646],[-92.431283,41.509483],[-92.411911,41.466696],[-92.355047,41.452243]]]},"properties":{"id":"50104","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.410775,41.267301],[-92.325904,41.264915],[-92.325468,41.339712],[-92.411393,41.37951],[-92.410817,41.272082],[-92.410775,41.267301]]]},"properties":{"id":"52550","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.305728,42.965941],[-94.344374,43.024065],[-94.521213,43.046224],[-94.560542,43.018241],[-94.560435,42.937812],[-94.442954,42.908073],[-94.305728,42.965941]]]},"properties":{"id":"50597","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.601255,40.574755],[-91.508927,40.553141],[-91.444596,40.605988],[-91.406713,40.629578],[-91.56425,40.799098],[-91.622234,40.799239],[-91.660506,40.755483],[-91.601255,40.574755]]]},"properties":{"id":"52625","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.68502,42.05874],[-91.70127,42.034709],[-91.667937,42.055073],[-91.68502,42.05874]]]},"properties":{"id":"52233","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.618834,42.023998],[-91.5307,41.975945],[-91.482056,42.003045],[-91.521089,42.129952],[-91.579414,42.138095],[-91.673887,42.135343],[-91.701396,42.092481],[-91.677592,42.089277],[-91.643445,42.06065],[-91.618834,42.023998]]]},"properties":{"id":"52302","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.337877,41.335874],[-91.445912,41.392876],[-91.483995,41.423848],[-91.484025,41.402045],[-91.48537,41.249646],[-91.433385,41.190729],[-91.370256,41.175768],[-91.320651,41.205065],[-91.192189,41.269225],[-91.196972,41.269238],[-91.337877,41.335874]],[[-91.379357,41.262877],[-91.370555,41.262946],[-91.369998,41.255703],[-91.379452,41.255302],[-91.379357,41.262877]]]},"properties":{"id":"52738","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.152573,41.335448],[-91.1192,41.359726],[-91.119686,41.334242],[-91.151787,41.334447],[-91.144787,41.283504],[-91.110422,41.237281],[-90.832544,41.455398],[-90.87202,41.519552],[-90.881676,41.531453],[-90.900444,41.536946],[-91.057718,41.53588],[-91.135318,41.533367],[-91.151067,41.519],[-91.174818,41.503591],[-91.290998,41.42288],[-91.152573,41.335448]]]},"properties":{"id":"52761","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.441843,41.124084],[-93.442104,41.003231],[-93.356155,40.898042],[-93.289001,40.884684],[-93.098703,41.122865],[-93.099217,41.160867],[-93.236815,41.16081],[-93.441843,41.124084]],[[-93.253343,41.088259],[-93.260582,41.102584],[-93.24863,41.11031],[-93.253343,41.088259]]]},"properties":{"id":"50049","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.347223,41.291625],[-93.500089,41.182894],[-93.441843,41.124084],[-93.236815,41.16081],[-93.234296,41.182613],[-93.280675,41.280736],[-93.309379,41.286405],[-93.347223,41.291625]]]},"properties":{"id":"50139","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.998198,43.418254],[-95.860445,43.359993],[-95.841098,43.500018],[-95.960608,43.500069],[-95.998198,43.418254]]]},"properties":{"id":"51243","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.273755,43.346098],[-96.330328,43.433072],[-96.338297,43.433061],[-96.369449,43.389572],[-96.351052,43.316179],[-96.273755,43.346098]]]},"properties":{"id":"51230","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.790572,41.429009],[-93.838348,41.428442],[-93.885443,41.388562],[-93.884535,41.352562],[-93.875467,41.349214],[-93.849911,41.34335],[-93.790386,41.360352],[-93.795068,41.359174],[-93.795401,41.366036],[-93.79051,41.366519],[-93.790399,41.360388],[-93.745443,41.370333],[-93.746024,41.377522],[-93.731475,41.37727],[-93.736856,41.369251],[-93.692771,41.314905],[-93.703198,41.422976],[-93.790572,41.429009]]]},"properties":{"id":"50229","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.908057,41.261369],[-94.000304,41.22054],[-93.965718,41.156739],[-93.927548,41.156742],[-93.874255,41.244553],[-93.908057,41.261369]]]},"properties":{"id":"50222","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.467199,41.299068],[-92.410817,41.272082],[-92.411393,41.37951],[-92.460507,41.408464],[-92.555281,41.321856],[-92.467199,41.299068]]]},"properties":{"id":"52586","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.328236,41.436341],[-93.250194,41.461066],[-93.281646,41.488856],[-93.328407,41.490921],[-93.328236,41.436341]]]},"properties":{"id":"50252","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.969421,41.508317],[-93.131866,41.433028],[-93.082517,41.408693],[-92.969421,41.508317]]]},"properties":{"id":"50214","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.766697,42.092883],[-92.747268,42.011681],[-92.663453,42.028745],[-92.650258,42.138074],[-92.766697,42.092883]]]},"properties":{"id":"50632","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.195286,42.106661],[-93.16623,42.189323],[-93.231608,42.178007],[-93.231618,42.10667],[-93.195286,42.106661]]]},"properties":{"id":"50239","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.565649,41.18906],[-95.68308,41.1891],[-95.691918,41.160007],[-95.666715,41.16008],[-95.67105,41.131345],[-95.63168,41.08784],[-95.556507,41.116734],[-95.52281,41.109487],[-95.505081,41.160129],[-95.565649,41.18906]]]},"properties":{"id":"51571","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.442162,41.08782],[-95.308408,41.159741],[-95.269915,41.174039],[-95.3083,41.188756],[-95.346765,41.159907],[-95.505081,41.160129],[-95.52281,41.109487],[-95.442162,41.08782]]]},"properties":{"id":"51541","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.024092,43.342336],[-92.830394,43.428903],[-92.830258,43.499555],[-93.071156,43.499641],[-93.084154,43.400853],[-93.084002,43.386102],[-93.024092,43.342336]],[[-93.017534,43.415047],[-93.019835,43.415062],[-93.018616,43.416793],[-93.017534,43.415047]]]},"properties":{"id":"50472","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.804464,42.063263],[-95.866237,41.979138],[-95.735066,41.935764],[-95.72053,41.935701],[-95.768807,42.048434],[-95.804464,42.063263]]]},"properties":{"id":"51572","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.116603,42.385962],[-96.173324,42.354411],[-96.154405,42.286103],[-96.154634,42.213879],[-96.048859,42.126043],[-96.005417,42.125295],[-95.969636,42.168964],[-95.941173,42.198729],[-96.001088,42.197152],[-96.001009,42.211761],[-96.000342,42.284266],[-96.010403,42.314872],[-96.058287,42.335448],[-96.116603,42.385962]]]},"properties":{"id":"51026","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.726576,41.53808],[-90.784513,41.545627],[-90.87202,41.519552],[-90.832544,41.455398],[-90.786282,41.452888],[-90.712224,41.464292],[-90.726576,41.53808]]]},"properties":{"id":"52726","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.151787,41.334447],[-91.119686,41.334242],[-91.1192,41.359726],[-91.152573,41.335448],[-91.151787,41.334447]]]},"properties":{"id":"52749","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.749394,43.17162],[-95.703646,43.128032],[-95.56487,43.157037],[-95.565225,43.257067],[-95.703846,43.287423],[-95.743376,43.258141],[-95.749394,43.17162]]]},"properties":{"id":"51248","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.580378,43.21818],[-94.57536,43.017898],[-94.560542,43.018241],[-94.521213,43.046224],[-94.442688,43.155085],[-94.502009,43.218416],[-94.560363,43.241442],[-94.580378,43.21818]]]},"properties":{"id":"50528","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.219457,42.561804],[-96.449731,42.627616],[-96.460779,42.56152],[-96.430397,42.541214],[-96.380216,42.519183],[-96.332716,42.512017],[-96.272612,42.522632],[-96.219457,42.561804]]]},"properties":{"id":"51108","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.193563,42.649179],[-96.399479,42.764398],[-96.448701,42.735704],[-96.44942,42.664152],[-96.193563,42.649179]]]},"properties":{"id":"51038","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.272287,42.807778],[-96.262566,42.809263],[-96.269574,42.814777],[-96.272287,42.807778]]]},"properties":{"id":"51008","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.639488,42.878525],[-94.705152,42.908953],[-94.757406,42.909114],[-94.737791,42.776005],[-94.639114,42.790302],[-94.639488,42.878525]]]},"properties":{"id":"50546","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.619872,42.881952],[-94.629452,42.878535],[-94.629414,42.871316],[-94.619938,42.87131],[-94.619872,42.881952]]]},"properties":{"id":"50573","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.697743,41.608174],[-93.697614,41.65222],[-93.755933,41.651738],[-93.77391,41.649206],[-93.776986,41.614878],[-93.726779,41.614858],[-93.703769,41.607646],[-93.697743,41.608174]]]},"properties":{"id":"50322","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.503248,41.620979],[-93.529614,41.553889],[-93.443097,41.511843],[-93.432725,41.600676],[-93.503248,41.620979]]]},"properties":{"id":"50327","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.703592,41.562647],[-93.712705,41.512085],[-93.707322,41.512073],[-93.637619,41.512079],[-93.641901,41.582324],[-93.703592,41.562647]]]},"properties":{"id":"50321","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.497258,41.834835],[-93.462865,41.794426],[-93.37238,41.725673],[-93.348423,41.719457],[-93.348328,41.744563],[-93.347991,41.828696],[-93.347683,41.95018],[-93.356164,41.964712],[-93.46337,41.92834],[-93.497258,41.834835]]]},"properties":{"id":"50161","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.634899,41.619242],[-93.64386,41.608264],[-93.643734,41.595974],[-93.644851,41.588044],[-93.616706,41.595659],[-93.612217,41.609389],[-93.634899,41.619242]]]},"properties":{"id":"50314","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626708,41.512055],[-93.573122,41.498959],[-93.529614,41.553889],[-93.596896,41.575385],[-93.626708,41.512055]]]},"properties":{"id":"50320","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.251531,41.534713],[-95.383455,41.5638],[-95.507617,41.447637],[-95.479946,41.418867],[-95.264473,41.418456],[-95.251531,41.534713]]]},"properties":{"id":"51521","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.872631,41.290028],[-95.86483,41.289964],[-95.79435,41.350856],[-95.789204,41.40535],[-95.90021,41.423038],[-95.920846,41.443449],[-95.872631,41.290028]]]},"properties":{"id":"51526","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.441354,41.732775],[-95.519743,41.776402],[-95.557042,41.759057],[-95.557805,41.689168],[-95.441551,41.67484],[-95.441354,41.732775]]]},"properties":{"id":"51562","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.440633,41.848742],[-95.519743,41.776402],[-95.441354,41.732775],[-95.397484,41.718086],[-95.393865,41.719623],[-95.392578,41.71805],[-95.344727,41.76165],[-95.440633,41.848742]]]},"properties":{"id":"51530","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.443236,43.133756],[-96.538458,42.908771],[-96.519373,42.909131],[-96.519278,42.923494],[-96.509474,42.923529],[-96.509695,42.909036],[-96.450376,42.909392],[-96.3324,43.069697],[-96.372155,43.113263],[-96.443236,43.133756]]]},"properties":{"id":"51023","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.09729,43.12814],[-96.159803,43.011635],[-96.115642,42.90994],[-96.07839,42.909792],[-96.017403,43.062848],[-95.998205,43.09875],[-96.018824,43.127993],[-96.076502,43.13557],[-96.09729,43.12814]]]},"properties":{"id":"51041","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.159803,43.011635],[-96.263594,43.040832],[-96.233533,42.909347],[-96.115642,42.90994],[-96.159803,43.011635]]]},"properties":{"id":"51036","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.509695,42.909036],[-96.509474,42.923529],[-96.519278,42.923494],[-96.519373,42.909131],[-96.509695,42.909036]]]},"properties":{"id":"51011","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.424937,42.123156],[-93.424496,42.209283],[-93.507528,42.209274],[-93.50194,42.122957],[-93.424937,42.123156]]]},"properties":{"id":"50236","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.466501,41.044073],[-94.466478,41.036142],[-94.457,41.036059],[-94.456891,41.043367],[-94.466501,41.044073]]]},"properties":{"id":"50842","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.269462,41.010527],[-92.294712,40.949117],[-92.23653,40.971215],[-92.269462,41.010527]]]},"properties":{"id":"52530","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.903365,43.25528],[-91.938115,43.389784],[-92.062622,43.255562],[-91.903365,43.25528]]]},"properties":{"id":"52165","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.961224,42.443758],[-96.058287,42.335448],[-96.010403,42.314872],[-95.840502,42.312156],[-95.786555,42.371062],[-95.961224,42.443758]]]},"properties":{"id":"51004","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.071156,43.499641],[-93.497353,43.499622],[-93.398963,43.405711],[-93.374528,43.371626],[-93.084154,43.400853],[-93.071156,43.499641]]]},"properties":{"id":"50459","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.605485,42.697726],[-93.498766,42.745819],[-93.538406,42.789283],[-93.617131,42.760262],[-93.617458,42.695281],[-93.605485,42.697726]]]},"properties":{"id":"50470","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.433363,41.388554],[-94.470987,41.403149],[-94.546985,41.229973],[-94.241486,41.287093],[-94.241506,41.341268],[-94.317789,41.364983],[-94.433363,41.388554]]]},"properties":{"id":"50849","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.927576,41.137294],[-94.834081,41.158453],[-94.795729,41.331104],[-94.911286,41.331184],[-94.965383,41.273476],[-94.96628,41.173041],[-94.927576,41.137294]]]},"properties":{"id":"50843","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.971712,41.555307],[-94.868743,41.504586],[-94.801222,41.518739],[-94.971712,41.555307]]]},"properties":{"id":"50042","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.744219,41.688528],[-94.961504,41.660056],[-94.744198,41.659938],[-94.744219,41.688528]]]},"properties":{"id":"50117","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.629273,41.964769],[-94.745396,41.956353],[-94.771389,41.94974],[-94.841419,41.862679],[-94.744042,41.746591],[-94.570418,41.776611],[-94.570935,41.877678],[-94.629273,41.964769]]]},"properties":{"id":"50058","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.165964,41.974535],[-95.093304,41.776945],[-95.093338,41.760593],[-94.956337,41.862978],[-94.95683,41.950303],[-94.976216,41.996871],[-94.976155,42.008057],[-95.053478,42.008204],[-95.165964,41.974535]]]},"properties":{"id":"51455","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.134691,41.949328],[-92.149193,41.963793],[-92.211163,41.942616],[-92.200873,41.862701],[-92.158419,41.86265],[-92.134691,41.949328]]]},"properties":{"id":"52257","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.890829,42.248135],[-91.910412,42.216512],[-91.833285,42.176157],[-91.809503,42.160845],[-91.779764,42.123835],[-91.696879,42.135494],[-91.656634,42.197063],[-91.64538,42.238872],[-91.890829,42.248135]]]},"properties":{"id":"52213","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.990312,42.050674],[-92.008636,41.977953],[-91.930204,41.948868],[-91.932333,42.021407],[-91.957868,42.067452],[-91.990312,42.050674]]]},"properties":{"id":"52315","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.734191,41.87658],[-91.833654,41.96401],[-91.872074,41.949091],[-91.831643,41.883581],[-91.818388,41.876383],[-91.83147,41.86942],[-91.831379,41.861851],[-91.783101,41.832965],[-91.734191,41.87658]]]},"properties":{"id":"52228","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.064766,42.297259],[-92.142927,42.210611],[-92.062503,42.297253],[-92.064766,42.297259]]]},"properties":{"id":"52313","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.202583,41.81776],[-92.183107,41.673928],[-92.007017,41.723868],[-91.987738,41.730991],[-91.968359,41.731011],[-91.974527,41.774655],[-91.947911,41.845405],[-91.96225,41.862039],[-92.045957,41.891759],[-92.158419,41.86265],[-92.200873,41.862701],[-92.202583,41.81776]]]},"properties":{"id":"52301","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.372248,42.642287],[-92.554489,42.613578],[-92.633197,42.527862],[-92.572838,42.42762],[-92.437037,42.437681],[-92.41268,42.53062],[-92.337579,42.64226],[-92.372248,42.642287]]]},"properties":{"id":"50613","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.423323,42.679548],[-92.372248,42.642287],[-92.337579,42.64226],[-92.278201,42.642209],[-92.258549,42.6422],[-92.297676,42.729847],[-92.317592,42.729926],[-92.423323,42.679548]]]},"properties":{"id":"50622","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.554444,42.773652],[-92.712195,42.678826],[-92.731766,42.657033],[-92.672822,42.636486],[-92.554387,42.622449],[-92.539326,42.690058],[-92.554444,42.773652]]]},"properties":{"id":"50670","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.672895,42.907126],[-92.732105,42.818278],[-92.712195,42.678826],[-92.554444,42.773652],[-92.554451,42.788149],[-92.59389,42.907132],[-92.672895,42.907126]]]},"properties":{"id":"50619","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.762141,42.994472],[-92.9557,42.907472],[-93.025375,42.862107],[-92.966048,42.847629],[-92.849344,42.802985],[-92.732105,42.818278],[-92.672895,42.907126],[-92.653311,42.942996],[-92.762141,42.994472]]]},"properties":{"id":"50636","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.824843,42.368563],[-94.803304,42.209752],[-94.783843,42.181741],[-94.702028,42.180991],[-94.687403,42.188064],[-94.629504,42.310591],[-94.804997,42.368395],[-94.824843,42.368563]]]},"properties":{"id":"51449","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.645965,42.473739],[-94.475425,42.44791],[-94.443033,42.473378],[-94.443022,42.573307],[-94.46291,42.630936],[-94.501366,42.645167],[-94.618853,42.56],[-94.645965,42.473739]]]},"properties":{"id":"50563","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.745396,41.956353],[-94.783843,42.181741],[-94.803304,42.209752],[-94.916708,42.209856],[-94.955982,42.107608],[-94.976155,42.008057],[-94.97439,42.003694],[-94.976216,41.996871],[-94.95683,41.950303],[-94.860191,41.938733],[-94.771389,41.94974],[-94.745396,41.956353]]]},"properties":{"id":"51401","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.771389,41.94974],[-94.860191,41.938733],[-94.859761,41.862823],[-94.841419,41.862679],[-94.771389,41.94974]]]},"properties":{"id":"51440","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.072874,42.152833],[-94.955982,42.107608],[-94.916708,42.209856],[-94.955596,42.253374],[-94.994343,42.238875],[-95.168398,42.210827],[-95.168766,42.167299],[-95.072874,42.152833]]]},"properties":{"id":"51436","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.503618,41.85995],[-91.617191,41.860984],[-91.641223,41.845715],[-91.633178,41.809778],[-91.529394,41.724872],[-91.365925,41.768958],[-91.308357,41.778385],[-91.313673,41.804459],[-91.413198,41.859839],[-91.503618,41.85995]]]},"properties":{"id":"52333","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.859227,41.771507],[-90.898373,41.771392],[-90.937649,41.698823],[-90.918832,41.669896],[-90.899232,41.669755],[-90.830912,41.698495],[-90.859227,41.771507]]]},"properties":{"id":"52765","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.791004,41.865621],[-90.898484,41.946245],[-90.924971,41.920658],[-90.922951,41.917684],[-90.898436,41.815205],[-90.898373,41.771392],[-90.859227,41.771507],[-90.786212,41.771369],[-90.791004,41.865621]]]},"properties":{"id":"52777","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.281119,42.951096],[-93.320217,43.046401],[-93.379101,43.009487],[-93.320755,42.93168],[-93.281119,42.951096]]]},"properties":{"id":"50477","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.083406,43.205786],[-93.082548,43.22005],[-93.091742,43.215145],[-93.083406,43.205786]]]},"properties":{"id":"50467","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.804881,42.701931],[-91.764082,42.701606],[-91.704943,42.686896],[-91.612138,42.68761],[-91.551826,42.758808],[-91.607713,42.79673],[-91.667599,42.817891],[-91.804881,42.701931]]]},"properties":{"id":"50606","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.449035,42.959957],[-91.275877,42.928672],[-91.250703,42.964157],[-91.329535,43.008884],[-91.43908,42.993571],[-91.449035,42.959957]]]},"properties":{"id":"52047","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.275877,42.928672],[-91.449035,42.959957],[-91.489164,42.974861],[-91.503621,42.970958],[-91.504501,42.931749],[-91.293701,42.881959],[-91.275877,42.928672]]]},"properties":{"id":"52072","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.946922,42.682538],[-91.01509,42.717553],[-91.043964,42.603215],[-91.015768,42.558966],[-90.91881,42.525477],[-90.89759,42.595157],[-90.946922,42.682538]],[[-90.946922,42.682538],[-90.946841,42.67503],[-90.956037,42.675041],[-90.950696,42.683637],[-90.946922,42.682538]]]},"properties":{"id":"52053","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.946922,42.682538],[-90.950696,42.683637],[-90.956037,42.675041],[-90.946841,42.67503],[-90.946922,42.682538]]]},"properties":{"id":"52066","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.252374,41.975492],[-90.242685,41.975367],[-90.24269,41.989948],[-90.252474,41.990031],[-90.252374,41.975492]]]},"properties":{"id":"52701","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.670051,41.76363],[-90.762514,41.86562],[-90.791004,41.865621],[-90.786212,41.771369],[-90.743643,41.741527],[-90.670051,41.76363]]]},"properties":{"id":"52729","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.208368,41.994773],[-95.249259,42.156434],[-95.273584,42.148704],[-95.298102,42.08024],[-95.36257,42.138869],[-95.381973,42.167782],[-95.478998,42.095494],[-95.534718,42.027785],[-95.482552,42.018598],[-95.41142,41.93423],[-95.36369,41.89287],[-95.324863,41.878631],[-95.208368,41.994773]]]},"properties":{"id":"51442","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.776986,41.614878],[-93.852864,41.643792],[-93.815631,41.600449],[-93.734707,41.60039],[-93.719846,41.597678],[-93.726779,41.614858],[-93.776986,41.614878]]]},"properties":{"id":"50325","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.184614,41.530776],[-94.193322,41.361623],[-94.01411,41.493043],[-94.012762,41.503191],[-94.059092,41.524933],[-94.150592,41.589859],[-94.184614,41.530776]]]},"properties":{"id":"50072","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.156969,41.688364],[-94.047509,41.704353],[-93.93095,41.776946],[-93.940678,41.795153],[-94.067432,41.776871],[-94.181671,41.717326],[-94.168168,41.702926],[-94.156969,41.688364]]]},"properties":{"id":"50167","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.827575,41.51949],[-93.838348,41.428442],[-93.790572,41.429009],[-93.707322,41.512073],[-93.712705,41.512085],[-93.774158,41.532335],[-93.809702,41.53329],[-93.827575,41.51949]]]},"properties":{"id":"50061","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.294712,40.949117],[-92.333101,40.906227],[-92.284557,40.899343],[-92.178973,40.877554],[-92.148058,40.894889],[-92.159915,40.928746],[-92.23653,40.971215],[-92.294712,40.949117]]]},"properties":{"id":"52554","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.01528,40.796553],[-94.109525,40.796921],[-94.12883,40.782559],[-94.129464,40.617091],[-93.957513,40.742478],[-94.01528,40.796553]]]},"properties":{"id":"50133","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.937106,40.575209],[-93.646992,40.578488],[-93.652229,40.63827],[-93.800228,40.645478],[-93.855758,40.659354],[-93.937106,40.575209]]]},"properties":{"id":"50065","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.609048,42.556445],[-91.578861,42.541986],[-91.489106,42.60043],[-91.607059,42.643973],[-91.609048,42.556445]]]},"properties":{"id":"52038","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.271731,40.944272],[-91.409324,40.812682],[-91.34987,40.792678],[-91.295474,40.775014],[-91.256579,40.870755],[-91.256589,40.906796],[-91.256617,40.908568],[-91.264573,40.939976],[-91.271731,40.944272]]]},"properties":{"id":"52623","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.14874,43.372169],[-95.106842,43.371339],[-95.118789,43.37886],[-95.14874,43.372169]]]},"properties":{"id":"51331","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.771882,42.575469],[-90.833674,42.485635],[-90.764763,42.474703],[-90.707987,42.493033],[-90.731222,42.582122],[-90.771882,42.575469]]]},"properties":{"id":"52002","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.061149,42.520563],[-91.067905,42.429835],[-91.047951,42.360954],[-90.971543,42.38244],[-91.015944,42.537156],[-91.061149,42.520563]]]},"properties":{"id":"52046","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.542522,43.500519],[-94.718466,43.500885],[-94.621063,43.399606],[-94.542522,43.500519]]]},"properties":{"id":"50531","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.659439,43.291642],[-94.816641,43.284338],[-94.876468,43.255144],[-94.913651,43.226523],[-94.817152,43.183587],[-94.580378,43.21818],[-94.560363,43.241442],[-94.659439,43.291642]]]},"properties":{"id":"51342","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.051106,42.964847],[-91.932892,42.876206],[-91.884312,42.906366],[-91.884327,42.920907],[-91.943208,43.030351],[-92.051106,42.964847]]]},"properties":{"id":"52147","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.740916,43.007424],[-91.605958,43.000987],[-91.637631,43.058546],[-91.722979,43.058357],[-91.754255,43.03841],[-91.740916,43.007424]]]},"properties":{"id":"52135","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.360078,42.731038],[-93.360167,42.745608],[-93.370013,42.74558],[-93.369874,42.731057],[-93.360078,42.731038]]]},"properties":{"id":"50431","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.434022,40.814857],[-95.450202,40.600701],[-95.373864,40.597085],[-95.354607,40.640592],[-95.197287,40.72798],[-95.193866,40.771303],[-95.384704,40.829128],[-95.434022,40.814857]]]},"properties":{"id":"51601","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.537229,40.728495],[-95.599987,40.687076],[-95.48825,40.624623],[-95.537229,40.728495]]]},"properties":{"id":"51650","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.454377,41.921014],[-94.495186,42.074263],[-94.628988,42.15183],[-94.629098,42.049032],[-94.629117,42.039265],[-94.629273,41.964769],[-94.570935,41.877678],[-94.532282,41.877648],[-94.454377,41.921014]]]},"properties":{"id":"51462","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.148928,42.415525],[-92.963927,42.383496],[-93.00291,42.462411],[-93.134321,42.478347],[-93.148928,42.415525]]]},"properties":{"id":"50672","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.597397,42.564912],[-93.601136,42.419836],[-93.596357,42.405388],[-93.501773,42.403283],[-93.462942,42.41259],[-93.499497,42.572212],[-93.597397,42.564912]]]},"properties":{"id":"50271","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.931893,42.368647],[-93.815698,42.325106],[-93.777139,42.368407],[-93.679672,42.441542],[-93.695762,42.557531],[-93.971617,42.544219],[-93.931906,42.383705],[-93.931893,42.368647]]]},"properties":{"id":"50595","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.597397,42.564912],[-93.627399,42.629856],[-93.675975,42.644556],[-93.676128,42.601171],[-93.695762,42.557531],[-93.679672,42.441542],[-93.601136,42.419836],[-93.597397,42.564912]]]},"properties":{"id":"50034","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.911257,42.908197],[-93.871886,43.0095],[-93.930975,43.052494],[-94.034865,43.037998],[-94.034905,43.023434],[-93.990797,42.907799],[-93.911257,42.908197]]]},"properties":{"id":"50430","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.088819,43.132149],[-94.034865,43.037998],[-93.930975,43.052494],[-93.930808,43.169448],[-93.99017,43.208238],[-94.049173,43.179057],[-94.088819,43.132149]]]},"properties":{"id":"50483","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.117823,42.201503],[-93.118075,42.295665],[-93.231903,42.314022],[-93.256035,42.209027],[-93.231608,42.178007],[-93.16623,42.189323],[-93.117823,42.201503]]]},"properties":{"id":"50206","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.867061,41.685974],[-95.755943,41.506397],[-95.689331,41.559174],[-95.616079,41.641425],[-95.616922,41.646041],[-95.842424,41.790539],[-95.876455,41.761886],[-95.905984,41.7087],[-95.881872,41.695645],[-95.867169,41.697758],[-95.867061,41.685974]]]},"properties":{"id":"51546","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.881872,41.695645],[-95.867061,41.685974],[-95.867169,41.697758],[-95.881872,41.695645]]]},"properties":{"id":"51550","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.904758,42.002297],[-95.98249,41.965021],[-95.977721,41.899397],[-95.93701,41.863467],[-95.828356,41.812667],[-95.769757,41.863276],[-95.735066,41.935764],[-95.866237,41.979138],[-95.904758,42.002297]]]},"properties":{"id":"51558","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.672756,43.400276],[-92.594048,43.284334],[-92.426148,43.356651],[-92.39579,43.428939],[-92.475706,43.500281],[-92.613233,43.500213],[-92.672623,43.414885],[-92.672756,43.400276]]]},"properties":{"id":"50466","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.120999,43.213102],[-92.080976,43.212947],[-92.095917,43.227616],[-92.120999,43.213102]]]},"properties":{"id":"52163","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.22729,42.834181],[-94.134953,42.801048],[-94.090104,42.877402],[-94.187709,42.951438],[-94.22729,42.834181]]]},"properties":{"id":"50558","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.904742,41.793141],[-91.901235,41.789608],[-91.89274,41.793974],[-91.90434,41.796076],[-91.904742,41.793141]]]},"properties":{"id":"52307","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.525157,42.139425],[-90.54904,42.101856],[-90.397289,42.116831],[-90.525157,42.139425]]]},"properties":{"id":"52074","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.586748,42.153073],[-90.596776,42.156943],[-90.597577,42.149735],[-90.586748,42.153073]]]},"properties":{"id":"52030","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.347991,41.828696],[-93.246874,41.820154],[-93.238465,41.83452],[-93.231765,41.931414],[-93.236498,41.954596],[-93.347683,41.95018],[-93.347991,41.828696]]]},"properties":{"id":"50055","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.238465,41.83452],[-93.154796,41.862617],[-93.116275,41.942934],[-93.231765,41.931414],[-93.238465,41.83452]]]},"properties":{"id":"50234","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.445912,41.392876],[-91.368521,41.423178],[-91.36768,41.510328],[-91.366876,41.553942],[-91.519828,41.51101],[-91.483995,41.423848],[-91.445912,41.392876]]]},"properties":{"id":"52755","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.530163,41.661254],[-91.557229,41.685677],[-91.559269,41.668016],[-91.533718,41.671517],[-91.530163,41.661254]]]},"properties":{"id":"52245","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.642231,41.72334],[-91.609187,41.670149],[-91.568602,41.66727],[-91.559267,41.666382],[-91.559269,41.668016],[-91.557229,41.685677],[-91.577506,41.716727],[-91.642231,41.72334]]]},"properties":{"id":"52241","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.54155,41.558296],[-91.519356,41.554782],[-91.528733,41.565993],[-91.54155,41.558296]]]},"properties":{"id":"52235","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.71545,41.745774],[-91.642231,41.72334],[-91.577506,41.716727],[-91.529394,41.724872],[-91.633178,41.809778],[-91.714849,41.791151],[-91.71545,41.745774]]]},"properties":{"id":"52317","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.711048,41.608508],[-91.789315,41.600079],[-91.789744,41.580473],[-91.753726,41.434528],[-91.676873,41.410424],[-91.642851,41.565283],[-91.711048,41.608508]]]},"properties":{"id":"52247","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.088819,43.132149],[-94.286004,43.157165],[-94.364007,43.141251],[-94.344374,43.024065],[-94.305728,42.965941],[-94.286136,42.966133],[-94.249337,42.994988],[-94.034905,43.023434],[-94.034865,43.037998],[-94.088819,43.132149]]]},"properties":{"id":"50511","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.251081,43.50012],[-94.168179,43.385646],[-94.088833,43.458951],[-94.150897,43.500105],[-94.251081,43.50012]]]},"properties":{"id":"50556","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.834011,41.978594],[-91.672487,41.976845],[-91.673969,41.982858],[-91.705079,42.011124],[-91.734256,42.000514],[-91.83453,42.000514],[-91.834011,41.978594]]]},"properties":{"id":"52405","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.144787,41.283504],[-91.182222,41.276424],[-91.192189,41.269225],[-91.320651,41.205065],[-91.198188,41.073414],[-91.070191,41.073014],[-91.009435,41.16624],[-91.110422,41.237281],[-91.144787,41.283504]]]},"properties":{"id":"52653","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.098703,41.122865],[-93.289001,40.884684],[-93.259996,40.85153],[-93.263294,40.843009],[-93.21207,40.811958],[-93.119396,40.898347],[-93.098684,41.115686],[-93.098703,41.122865]]]},"properties":{"id":"50238","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.443236,43.133756],[-96.372155,43.113263],[-96.21545,43.142023],[-96.247892,43.2576],[-96.354023,43.257853],[-96.490323,43.223293],[-96.443236,43.133756]]]},"properties":{"id":"51247","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.790399,41.360388],[-93.79051,41.366519],[-93.795401,41.366036],[-93.795068,41.359174],[-93.790386,41.360352],[-93.790399,41.360388]]]},"properties":{"id":"50033","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.798431,41.407631],[-92.870824,41.299342],[-92.831852,41.271736],[-92.717718,41.379073],[-92.798431,41.407631]]]},"properties":{"id":"50143","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.99459,41.238636],[-92.851163,41.24095],[-92.831852,41.271736],[-92.870824,41.299342],[-92.875384,41.308879],[-92.981128,41.284494],[-92.99459,41.238636]]]},"properties":{"id":"50256","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.103035,42.158299],[-93.088161,42.106529],[-92.962374,42.166328],[-93.053998,42.140452],[-93.103035,42.158299]]]},"properties":{"id":"50005","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.785166,42.007347],[-92.766115,41.99932],[-92.76612,42.013955],[-92.785166,42.007347]]]},"properties":{"id":"50142","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.867931,41.934913],[-92.858225,41.935002],[-92.858025,41.942262],[-92.867824,41.942234],[-92.867931,41.934913]]]},"properties":{"id":"50078","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.813465,40.901693],[-95.746388,40.901711],[-95.751761,40.919696],[-95.782079,41.146318],[-95.873877,41.073136],[-95.813465,40.901693]]]},"properties":{"id":"51561","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.790577,43.400124],[-92.672756,43.400276],[-92.672623,43.414885],[-92.790577,43.400124]]]},"properties":{"id":"50454","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.137076,41.950773],[-95.98249,41.965021],[-95.904758,42.002297],[-96.005417,42.125295],[-96.048859,42.126043],[-96.274608,42.085386],[-96.137076,41.950773]]]},"properties":{"id":"51040","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.639955,41.092078],[-92.802726,41.161228],[-92.822697,41.161076],[-92.98956,41.073656],[-92.983418,40.912536],[-92.734728,40.93005],[-92.63976,41.063145],[-92.639955,41.092078]]]},"properties":{"id":"52531","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.899232,41.669755],[-90.860565,41.633102],[-90.791872,41.683834],[-90.830912,41.698495],[-90.899232,41.669755]]],[[[-90.784513,41.545627],[-90.860571,41.625867],[-90.881676,41.531453],[-90.87202,41.519552],[-90.784513,41.545627]]]]},"properties":{"id":"52769","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.784513,41.545627],[-90.726576,41.53808],[-90.685654,41.603633],[-90.66604,41.646829],[-90.675682,41.654727],[-90.791872,41.683834],[-90.860565,41.633102],[-90.860571,41.625867],[-90.784513,41.545627]]]},"properties":{"id":"52773","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.841098,43.500018],[-95.860445,43.359993],[-95.683861,43.316388],[-95.584749,43.373705],[-95.623257,43.499905],[-95.841098,43.500018]]]},"properties":{"id":"51249","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.156074,40.871977],[-95.193866,40.771303],[-95.197287,40.72798],[-95.125701,40.638686],[-95.125793,40.629156],[-95.106239,40.623906],[-94.914732,40.635121],[-94.919477,40.84279],[-95.118458,40.900957],[-95.156199,40.901035],[-95.156074,40.871977]],[[-95.153672,40.735227],[-95.153843,40.73205],[-95.159061,40.729393],[-95.159095,40.739049],[-95.153672,40.735227]]]},"properties":{"id":"51632","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.448701,42.735704],[-96.632784,42.761786],[-96.515178,42.627569],[-96.449688,42.649381],[-96.44942,42.664152],[-96.448701,42.735704]]]},"properties":{"id":"51062","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.406434,41.679645],[-93.37238,41.725673],[-93.462865,41.794426],[-93.530444,41.73185],[-93.522468,41.688399],[-93.406434,41.679645]]]},"properties":{"id":"50035","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.610428,41.936024],[-93.570717,41.820489],[-93.497258,41.834835],[-93.46337,41.92834],[-93.534403,41.950459],[-93.610428,41.936024]]]},"properties":{"id":"50046","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.708092,41.863357],[-93.777976,41.801284],[-93.753511,41.754547],[-93.675486,41.704055],[-93.639398,41.791329],[-93.692443,41.870638],[-93.699215,41.863347],[-93.708092,41.863357]]]},"properties":{"id":"50226","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.734707,41.60039],[-93.774158,41.532335],[-93.712705,41.512085],[-93.703592,41.562647],[-93.709817,41.591834],[-93.719846,41.597678],[-93.734707,41.60039]]]},"properties":{"id":"50265","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.605239,41.589651],[-93.601798,41.58908],[-93.59991,41.590728],[-93.600651,41.592848],[-93.605239,41.589651]]]},"properties":{"id":"50319","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.505081,41.160129],[-95.346765,41.159907],[-95.537392,41.203632],[-95.565649,41.18906],[-95.505081,41.160129]]]},"properties":{"id":"51549","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.497947,41.375226],[-95.55586,41.303922],[-95.536753,41.289336],[-95.289053,41.259882],[-95.232236,41.317842],[-95.251306,41.33198],[-95.497947,41.375226]]]},"properties":{"id":"51560","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.610621,41.848224],[-92.629638,41.666897],[-92.499916,41.674102],[-92.512832,41.862305],[-92.610621,41.848224]]]},"properties":{"id":"50157","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.36092,41.819302],[-92.473909,41.862439],[-92.512832,41.862305],[-92.499916,41.674102],[-92.435523,41.674041],[-92.377524,41.674225],[-92.358065,41.688488],[-92.376978,41.775465],[-92.36092,41.819302]]]},"properties":{"id":"52211","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.583259,41.552265],[-90.685654,41.603633],[-90.726576,41.53808],[-90.712224,41.464292],[-90.710315,41.453494],[-90.678818,41.460126],[-90.582914,41.528339],[-90.583259,41.552265]]]},"properties":{"id":"52804","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.271722,41.761103],[-95.093265,41.731627],[-95.093338,41.760593],[-95.093304,41.776945],[-95.285197,41.82093],[-95.286094,41.761148],[-95.271722,41.761103]]]},"properties":{"id":"51446","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.644728,42.032288],[-93.630711,42.022839],[-93.639113,41.972085],[-93.61685,41.949416],[-93.610428,41.936024],[-93.534403,41.950459],[-93.540916,42.106728],[-93.620397,42.122822],[-93.659401,42.077912],[-93.644728,42.032288]]]},"properties":{"id":"50010","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.346614,42.209229],[-93.366471,42.122953],[-93.329337,42.09265],[-93.231645,42.077695],[-93.231618,42.10667],[-93.231608,42.178007],[-93.256035,42.209027],[-93.346614,42.209229]]]},"properties":{"id":"50278","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.680586,40.791828],[-94.719334,40.833779],[-94.804742,40.842358],[-94.835523,40.833175],[-94.85266,40.827955],[-94.794957,40.741491],[-94.680586,40.791828]]]},"properties":{"id":"50848","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.441286,41.507589],[-93.374595,41.421606],[-93.357219,41.421537],[-93.328236,41.436341],[-93.328407,41.490921],[-93.441286,41.507589]]]},"properties":{"id":"50118","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.838838,41.38013],[-91.773896,41.329441],[-91.807643,41.394956],[-91.838838,41.38013]]]},"properties":{"id":"52359","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.868709,41.256333],[-91.744883,41.182588],[-91.608029,41.20222],[-91.600805,41.252899],[-91.638009,41.395936],[-91.676873,41.410424],[-91.753726,41.434528],[-91.807643,41.394956],[-91.773896,41.329441],[-91.868709,41.256333]]]},"properties":{"id":"52353","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.852262,43.499743],[-93.852317,43.327506],[-93.733993,43.327724],[-93.696234,43.414631],[-93.773734,43.499682],[-93.852262,43.499743]]]},"properties":{"id":"50478","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.773734,43.499682],[-93.696234,43.414631],[-93.636962,43.429888],[-93.576522,43.499644],[-93.773734,43.499682]]]},"properties":{"id":"50473","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.460779,42.56152],[-96.440308,42.489595],[-96.392648,42.48141],[-96.390971,42.483345],[-96.407938,42.500157],[-96.410263,42.509178],[-96.430397,42.541214],[-96.460779,42.56152]]]},"properties":{"id":"51103","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.135644,42.429147],[-96.116603,42.385962],[-96.058287,42.335448],[-95.961224,42.443758],[-95.957631,42.475074],[-96.115623,42.561313],[-96.135644,42.429147]]]},"properties":{"id":"51039","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.219457,42.561804],[-96.272612,42.522632],[-96.252806,42.445876],[-96.135644,42.429147],[-96.115623,42.561313],[-96.174336,42.561425],[-96.219457,42.561804]]]},"properties":{"id":"51030","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.084154,43.400853],[-93.374528,43.371626],[-93.378993,43.342609],[-93.350028,43.291774],[-93.141176,43.327645],[-93.084002,43.386102],[-93.084154,43.400853]]]},"properties":{"id":"50448","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.852686,42.834283],[-93.853039,42.731705],[-93.793473,42.629991],[-93.676128,42.601171],[-93.675975,42.644556],[-93.617458,42.695281],[-93.617131,42.760262],[-93.744188,42.84868],[-93.852686,42.834283]]]},"properties":{"id":"50525","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.853909,41.468228],[-94.776807,41.330959],[-94.700097,41.331044],[-94.642619,41.345483],[-94.642762,41.366473],[-94.70069,41.547372],[-94.801222,41.518739],[-94.868743,41.504586],[-94.853909,41.468228]]]},"properties":{"id":"50020","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.652451,41.170274],[-94.642619,41.345483],[-94.700097,41.331044],[-94.748032,41.144089],[-94.675988,41.158068],[-94.652451,41.170274]]]},"properties":{"id":"50837","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.910171,41.052495],[-94.835523,40.833175],[-94.804742,40.842358],[-94.910171,41.052495]]]},"properties":{"id":"50857","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.306411,43.12848],[-91.220945,43.080424],[-91.180267,43.080263],[-91.177222,43.080247],[-91.079573,43.22803],[-91.25008,43.278862],[-91.306411,43.12848]]]},"properties":{"id":"52146","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.670808,43.500598],[-91.576211,43.360153],[-91.461016,43.392171],[-91.411939,43.432677],[-91.465268,43.500684],[-91.670808,43.500598]]]},"properties":{"id":"52140","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.386378,43.135276],[-91.43908,42.993571],[-91.329535,43.008884],[-91.220945,43.080424],[-91.306411,43.12848],[-91.370261,43.189372],[-91.386378,43.135276]]]},"properties":{"id":"52159","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.88657,40.647238],[-93.077788,40.584512],[-92.827853,40.588602],[-92.88657,40.647238]]]},"properties":{"id":"52549","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.757123,40.588902],[-92.771373,40.68048],[-92.88657,40.647238],[-92.827853,40.588602],[-92.757123,40.588902]]]},"properties":{"id":"52555","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.221542,42.406667],[-92.347457,42.326445],[-92.318321,42.297492],[-92.220604,42.210742],[-92.142927,42.210611],[-92.064766,42.297259],[-92.0647,42.362564],[-92.221542,42.406667]]]},"properties":{"id":"50651","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.144055,42.058039],[-92.149193,41.963793],[-92.134691,41.949328],[-92.02717,41.949201],[-92.008636,41.977953],[-91.990312,42.050674],[-92.124572,42.07961],[-92.144055,42.058039]]]},"properties":{"id":"52346","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.29913,42.079501],[-92.415408,42.029127],[-92.415221,42.001913],[-92.394957,41.987553],[-92.366047,41.992825],[-92.305943,41.949362],[-92.279832,41.978716],[-92.26078,42.065428],[-92.29913,42.079501]]]},"properties":{"id":"52225","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.854831,42.036766],[-91.932333,42.021407],[-91.930204,41.948868],[-91.929516,41.9198],[-91.872074,41.949091],[-91.833654,41.96401],[-91.834011,41.978594],[-91.83453,42.000514],[-91.854831,42.036766]]]},"properties":{"id":"52206","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.370567,42.326485],[-92.465092,42.297194],[-92.362402,42.259181],[-92.318321,42.297492],[-92.347457,42.326445],[-92.370567,42.326485]]]},"properties":{"id":"50612","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.437037,42.437681],[-92.572838,42.42762],[-92.514232,42.311611],[-92.465092,42.297194],[-92.370567,42.326485],[-92.437037,42.437681]]]},"properties":{"id":"50643","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.047985,41.906619],[-93.969725,41.964773],[-93.984661,42.123118],[-94.106055,42.166366],[-94.164765,42.122807],[-94.164787,42.106968],[-94.16474,42.01536],[-94.124065,41.950159],[-94.047985,41.906619]]]},"properties":{"id":"50212","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.261137,41.964594],[-94.275252,41.913846],[-94.250928,41.863121],[-94.176368,41.863307],[-94.124065,41.950159],[-94.16474,42.01536],[-94.261137,41.964594]]]},"properties":{"id":"50235","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.659194,41.902774],[-93.61685,41.949416],[-93.639113,41.972085],[-93.736919,41.950533],[-93.698134,41.921546],[-93.659194,41.902774]]]},"properties":{"id":"50134","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.699215,41.863347],[-93.698038,41.870581],[-93.708092,41.863357],[-93.699215,41.863347]]],[[[-93.698056,41.877868],[-93.692443,41.870638],[-93.639398,41.791329],[-93.619946,41.791325],[-93.600282,41.829423],[-93.659194,41.902774],[-93.698134,41.921546],[-93.698056,41.877868]]]]},"properties":{"id":"50244","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.08148,42.979698],[-92.311441,42.887689],[-92.17338,42.729522],[-92.140759,42.714697],[-92.068448,42.750626],[-91.963795,42.818354],[-91.932892,42.876206],[-92.051106,42.964847],[-92.08148,42.979698]]]},"properties":{"id":"50674","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.742194,42.352566],[-91.656879,42.35482],[-91.656925,42.363255],[-91.668044,42.55627],[-91.7212,42.570434],[-91.786385,42.508305],[-91.761123,42.403146],[-91.753105,42.372713],[-91.742194,42.352566]]]},"properties":{"id":"50682","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.62522,42.269832],[-91.43242,42.242394],[-91.417797,42.238394],[-91.402936,42.238395],[-91.363263,42.296445],[-91.422107,42.310803],[-91.59743,42.344769],[-91.656925,42.363255],[-91.656879,42.35482],[-91.62522,42.269832]]]},"properties":{"id":"52218","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.092506,42.821992],[-94.913869,42.83632],[-94.933439,42.909724],[-95.033663,42.901692],[-95.092643,42.823683],[-95.092506,42.821992]]]},"properties":{"id":"50565","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.30921,43.010363],[-95.293702,42.851008],[-95.24945,42.814724],[-95.190641,42.851142],[-95.21135,43.010463],[-95.30921,43.010363]]]},"properties":{"id":"51033","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.38818,43.068528],[-95.467436,42.879536],[-95.466954,42.864724],[-95.466083,42.864735],[-95.33942,42.836643],[-95.293702,42.851008],[-95.30921,43.010363],[-95.368525,43.068778],[-95.38818,43.068528]]]},"properties":{"id":"51047","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.849344,42.802985],[-92.925862,42.764817],[-92.907491,42.700838],[-92.849078,42.686379],[-92.731766,42.657033],[-92.712195,42.678826],[-92.732105,42.818278],[-92.849344,42.802985]]]},"properties":{"id":"50602","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.947103,42.671893],[-92.947545,42.528626],[-92.858542,42.499403],[-92.849078,42.686379],[-92.907491,42.700838],[-92.947103,42.671893]]]},"properties":{"id":"50604","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.672822,42.636486],[-92.672847,42.528009],[-92.633197,42.527862],[-92.554489,42.613578],[-92.554387,42.622449],[-92.672822,42.636486]]]},"properties":{"id":"50660","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.91463,42.546848],[-94.914487,42.47419],[-94.835635,42.488449],[-94.777034,42.517125],[-94.736827,42.626831],[-94.854883,42.704189],[-94.915135,42.676156],[-94.91463,42.546848]],[[-94.899554,42.661586],[-94.894753,42.653498],[-94.902267,42.653591],[-94.899554,42.661586]]]},"properties":{"id":"50540","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.956337,41.862978],[-94.859761,41.862823],[-94.860191,41.938733],[-94.95683,41.950303],[-94.956337,41.862978]]]},"properties":{"id":"51463","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.229345,41.173907],[-94.984891,41.158475],[-94.96628,41.173041],[-94.965383,41.273476],[-95.213604,41.317826],[-95.232236,41.317842],[-95.289053,41.259882],[-95.3083,41.188756],[-95.269915,41.174039],[-95.229345,41.173907]]]},"properties":{"id":"51535","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.229345,41.173907],[-95.079885,41.11618],[-94.988632,41.138547],[-94.988452,41.144682],[-94.984891,41.158475],[-95.229345,41.173907]]]},"properties":{"id":"51532","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.413198,41.859839],[-91.313673,41.804459],[-91.307598,41.816319],[-91.307835,41.983994],[-91.365585,41.97641],[-91.413198,41.859839]]]},"properties":{"id":"52253","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.133307,41.645609],[-91.135318,41.533367],[-91.057718,41.53588],[-91.093961,41.644487],[-91.133307,41.645609]]]},"properties":{"id":"52760","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.5271,43.198724],[-93.497812,43.038712],[-93.477625,43.009697],[-93.457886,43.009627],[-93.497564,43.213107],[-93.5271,43.198724]]]},"properties":{"id":"50482","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.319933,43.255202],[-93.290298,43.082923],[-93.102378,43.052619],[-93.102687,43.104311],[-93.083406,43.205786],[-93.091742,43.215145],[-93.141565,43.255106],[-93.319933,43.255202]]]},"properties":{"id":"50401","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.819949,42.611447],[-95.810756,42.560819],[-95.701352,42.523506],[-95.662345,42.5223],[-95.603877,42.576201],[-95.780446,42.633301],[-95.819949,42.611447]]]},"properties":{"id":"51061","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.341269,42.542663],[-91.278931,42.61121],[-91.250903,42.665933],[-91.284986,42.667223],[-91.414983,42.601118],[-91.341269,42.542663]]]},"properties":{"id":"52050","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.368943,42.004298],[-90.334834,41.9026],[-90.296436,42.004565],[-90.354076,42.018799],[-90.368943,42.004298]]]},"properties":{"id":"52727","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.36257,42.138869],[-95.298102,42.08024],[-95.273584,42.148704],[-95.36257,42.138869]]]},"properties":{"id":"51441","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.440528,41.863236],[-95.614559,41.979359],[-95.653576,41.965107],[-95.72053,41.935701],[-95.735066,41.935764],[-95.769757,41.863276],[-95.557042,41.759057],[-95.519743,41.776402],[-95.440633,41.848742],[-95.440528,41.863236]]]},"properties":{"id":"51529","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.815896,42.254979],[-95.652231,42.161346],[-95.594239,42.211071],[-95.728998,42.356241],[-95.786555,42.371062],[-95.840502,42.312156],[-95.815896,42.254979]]]},"properties":{"id":"51019","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.755933,41.651738],[-93.750402,41.721966],[-93.824378,41.742179],[-93.852994,41.677705],[-93.836385,41.658171],[-93.77391,41.649206],[-93.755933,41.651738]]]},"properties":{"id":"50111","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.539644,42.420293],[-91.59743,42.344769],[-91.422107,42.310803],[-91.403419,42.361826],[-91.539644,42.420293]]]},"properties":{"id":"52330","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.257696,40.75533],[-91.112467,40.696301],[-90.95546,40.986185],[-91.006736,40.985935],[-91.042159,40.964374],[-91.256617,40.908568],[-91.256589,40.906796],[-91.180204,40.800257],[-91.257696,40.75533]]]},"properties":{"id":"52601","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.352484,41.038233],[-91.256134,41.00152],[-91.198188,41.073414],[-91.320651,41.205065],[-91.370256,41.175768],[-91.370646,41.084045],[-91.352484,41.038233]]]},"properties":{"id":"52640","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.257696,40.75533],[-91.180204,40.800257],[-91.256579,40.870755],[-91.295474,40.775014],[-91.257696,40.75533]]]},"properties":{"id":"52638","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.934626,43.500931],[-94.954232,43.433435],[-94.93926,43.437708],[-94.939278,43.429782],[-94.95422,43.430527],[-94.972173,43.370875],[-94.876468,43.255144],[-94.816641,43.284338],[-94.678742,43.341789],[-94.620688,43.378087],[-94.621063,43.399606],[-94.718466,43.500885],[-94.934626,43.500931]]]},"properties":{"id":"51334","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.118789,43.37886],[-95.150358,43.375093],[-95.14874,43.372169],[-95.118789,43.37886]]]},"properties":{"id":"51355","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.763141,42.382023],[-90.898386,42.382302],[-90.938787,42.360902],[-90.898268,42.206475],[-90.871856,42.189995],[-90.792757,42.197358],[-90.776263,42.205153],[-90.763141,42.382023]]]},"properties":{"id":"52032","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.833674,42.485635],[-90.771882,42.575469],[-90.89759,42.595157],[-90.91881,42.525477],[-90.862779,42.470597],[-90.833674,42.485635]]]},"properties":{"id":"52039","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.884327,42.920907],[-91.725731,42.903586],[-91.740916,43.007424],[-91.754255,43.03841],[-91.839083,43.081925],[-91.928257,43.069565],[-91.932473,43.063093],[-91.943208,43.030351],[-91.884327,42.920907]]]},"properties":{"id":"52175","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.844884,43.126507],[-92.080976,43.212947],[-92.081063,43.141013],[-91.933366,43.069415],[-91.928257,43.069565],[-91.839083,43.081925],[-91.844884,43.126507]]]},"properties":{"id":"52144","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.824105,43.17737],[-91.844884,43.126507],[-91.839083,43.081925],[-91.754255,43.03841],[-91.722979,43.058357],[-91.673658,43.190559],[-91.673662,43.197808],[-91.824105,43.17737]]]},"properties":{"id":"52161","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.713208,40.734658],[-95.774143,40.644472],[-95.373923,40.580503],[-95.373864,40.597085],[-95.450202,40.600701],[-95.48825,40.624623],[-95.599987,40.687076],[-95.713208,40.734658]]]},"properties":{"id":"51640","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.537229,40.728495],[-95.48825,40.624623],[-95.450202,40.600701],[-95.434022,40.814857],[-95.461629,40.829384],[-95.543532,40.786859],[-95.537229,40.728495]]]},"properties":{"id":"51639","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.570935,41.877678],[-94.570418,41.776611],[-94.473722,41.784194],[-94.532282,41.877648],[-94.570935,41.877678]]]},"properties":{"id":"50029","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.938216,42.375739],[-92.865804,42.411685],[-92.829849,42.499337],[-92.858542,42.499403],[-92.947545,42.528626],[-93.00291,42.462411],[-92.963927,42.383496],[-92.938216,42.375739]]]},"properties":{"id":"50680","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.118924,43.313246],[-94.152805,43.241518],[-94.049173,43.179057],[-93.99017,43.208238],[-93.970331,43.284259],[-94.029632,43.320824],[-94.118924,43.313246]]]},"properties":{"id":"50480","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.231903,42.314022],[-93.280616,42.383126],[-93.309863,42.383214],[-93.405279,42.380343],[-93.366126,42.223791],[-93.346614,42.209229],[-93.256035,42.209027],[-93.231903,42.314022]]]},"properties":{"id":"50122","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.426148,43.356651],[-92.594048,43.284334],[-92.594135,43.255327],[-92.594204,43.212783],[-92.554381,43.212813],[-92.384442,43.213023],[-92.239137,43.213455],[-92.219742,43.241247],[-92.298865,43.320402],[-92.426148,43.356651]]]},"properties":{"id":"50628","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.930194,43.500639],[-92.198875,43.500556],[-92.298865,43.320402],[-92.219742,43.241247],[-92.140963,43.212952],[-92.120999,43.213102],[-92.095917,43.227616],[-92.062622,43.255562],[-91.938115,43.389784],[-91.930194,43.500639]]]},"properties":{"id":"52136","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.344582,42.756718],[-94.462606,42.790349],[-94.541025,42.747184],[-94.560251,42.688636],[-94.501366,42.645167],[-94.46291,42.630936],[-94.363775,42.645096],[-94.324866,42.755877],[-94.344582,42.756718]]]},"properties":{"id":"50541","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.240415,41.790105],[-92.254042,41.609149],[-92.221214,41.606458],[-92.182899,41.601299],[-92.183107,41.673928],[-92.202583,41.81776],[-92.240415,41.790105]]]},"properties":{"id":"52251","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.898497,41.783602],[-91.911051,41.687758],[-91.834185,41.686702],[-91.831589,41.784548],[-91.898497,41.783602]]]},"properties":{"id":"52236","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.246874,41.820154],[-93.179145,41.746335],[-93.057954,41.862627],[-93.154796,41.862617],[-93.238465,41.83452],[-93.246874,41.820154]]]},"properties":{"id":"50028","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.744883,41.182588],[-91.868709,41.256333],[-91.91143,41.254568],[-91.888395,41.14746],[-91.748044,41.070359],[-91.716783,41.092124],[-91.744883,41.182588]]]},"properties":{"id":"52540","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.148058,40.894889],[-92.112985,40.878598],[-92.042777,40.900061],[-92.039632,40.914606],[-92.037644,40.984863],[-92.159915,40.928746],[-92.148058,40.894889]]]},"properties":{"id":"52567","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.131835,42.120996],[-91.402936,42.238395],[-91.417797,42.238394],[-91.432397,42.230953],[-91.364636,42.112466],[-91.36478,42.070529],[-91.288259,41.994956],[-91.190746,42.004577],[-91.137042,42.048429],[-91.131712,42.067504],[-91.131835,42.120996]],[[-91.248435,42.007624],[-91.241976,42.007853],[-91.241883,42.004586],[-91.249768,42.004179],[-91.248435,42.007624]]]},"properties":{"id":"52205","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.902736,42.137638],[-91.034266,42.080438],[-91.131712,42.067504],[-91.137042,42.048429],[-91.065613,42.003905],[-90.912891,42.033591],[-90.902736,42.137638]]]},"properties":{"id":"52362","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442693,43.327336],[-94.502009,43.218416],[-94.442688,43.155085],[-94.383844,43.168727],[-94.364228,43.298293],[-94.384157,43.327308],[-94.442693,43.327336]]]},"properties":{"id":"50539","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.503618,41.85995],[-91.413198,41.859839],[-91.365585,41.97641],[-91.419001,42.007633],[-91.482056,42.003045],[-91.5307,41.975945],[-91.509822,41.918296],[-91.503618,41.85995]]]},"properties":{"id":"52314","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.579414,42.138095],[-91.521089,42.129952],[-91.364636,42.112466],[-91.432397,42.230953],[-91.43242,42.242394],[-91.62522,42.269832],[-91.64538,42.238872],[-91.656634,42.197063],[-91.579414,42.138095]]]},"properties":{"id":"52214","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.509822,41.918296],[-91.599261,41.951554],[-91.617191,41.860984],[-91.503618,41.85995],[-91.509822,41.918296]]]},"properties":{"id":"52227","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.673887,42.135343],[-91.579414,42.138095],[-91.656634,42.197063],[-91.696879,42.135494],[-91.673887,42.135343]]]},"properties":{"id":"52202","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.379357,41.262877],[-91.379452,41.255302],[-91.369998,41.255703],[-91.370555,41.262946],[-91.379357,41.262877]]]},"properties":{"id":"52737","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.246739,41.219013],[-93.237245,41.21517],[-93.2373,41.226221],[-93.245038,41.226285],[-93.246739,41.219013]]]},"properties":{"id":"50163","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.933348,42.188081],[-93.001674,42.209267],[-93.053998,42.140452],[-92.962374,42.166328],[-92.933348,42.188081]]]},"properties":{"id":"50148","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.691918,41.160007],[-95.67105,41.131345],[-95.666715,41.16008],[-95.691918,41.160007]]]},"properties":{"id":"51554","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.712433,43.500079],[-92.672623,43.414885],[-92.613233,43.500213],[-92.712433,43.500079]]]},"properties":{"id":"50455","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.741771,43.055121],[-95.70325,43.048058],[-95.703646,43.128032],[-95.749394,43.17162],[-95.781948,43.098737],[-95.781457,43.055298],[-95.741771,43.055121]]]},"properties":{"id":"51231","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.781457,43.055298],[-95.899558,43.025788],[-95.95785,42.909774],[-95.859116,42.895035],[-95.800492,42.909497],[-95.741771,43.055121],[-95.781457,43.055298]]]},"properties":{"id":"51022","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.781948,43.098737],[-95.998205,43.09875],[-96.017403,43.062848],[-95.899558,43.025788],[-95.781457,43.055298],[-95.781948,43.098737]]]},"properties":{"id":"51238","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.3324,43.069697],[-96.450376,42.909392],[-96.353613,42.865526],[-96.233533,42.909347],[-96.263594,43.040832],[-96.3324,43.069697]]]},"properties":{"id":"51027","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.570717,41.820489],[-93.580872,41.82064],[-93.581006,41.79138],[-93.530444,41.73185],[-93.462865,41.794426],[-93.497258,41.834835],[-93.570717,41.820489]]]},"properties":{"id":"50073","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.697614,41.65222],[-93.697743,41.608174],[-93.64386,41.608264],[-93.634899,41.619242],[-93.666634,41.650839],[-93.697614,41.65222]]]},"properties":{"id":"50310","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.5969,41.586831],[-93.616706,41.595659],[-93.644851,41.588044],[-93.641901,41.582324],[-93.596896,41.575385],[-93.5969,41.586831]],[[-93.605239,41.589651],[-93.600651,41.592848],[-93.59991,41.590728],[-93.601798,41.58908],[-93.605239,41.589651]]]},"properties":{"id":"50309","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.535601,41.670454],[-93.546065,41.666772],[-93.535235,41.665315],[-93.535601,41.670454]]]},"properties":{"id":"50032","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.497947,41.375226],[-95.251306,41.33198],[-95.264473,41.418456],[-95.479946,41.418867],[-95.497947,41.375226]]]},"properties":{"id":"51536","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.346765,41.159907],[-95.3083,41.188756],[-95.289053,41.259882],[-95.536753,41.289336],[-95.537392,41.203632],[-95.346765,41.159907]]]},"properties":{"id":"51525","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.142613,40.919219],[-94.30109,40.970885],[-94.337387,40.942227],[-94.242673,40.855152],[-94.223707,40.854787],[-94.133138,40.897561],[-94.142613,40.919219]]]},"properties":{"id":"50861","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.245,42.429092],[-95.322632,42.400266],[-95.323058,42.283065],[-95.194444,42.210823],[-95.187661,42.210791],[-95.130521,42.326068],[-95.148623,42.369567],[-95.245,42.429092]]]},"properties":{"id":"51458","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.582914,41.528339],[-90.678818,41.460126],[-90.581615,41.515188],[-90.582969,41.525171],[-90.582914,41.528339]]]},"properties":{"id":"52802","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.471235,41.698962],[-90.432405,41.739425],[-90.434205,41.756867],[-90.446583,41.763844],[-90.59523,41.769278],[-90.61816,41.758453],[-90.626698,41.683726],[-90.471235,41.698962]]]},"properties":{"id":"52756","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.626698,41.683726],[-90.61816,41.758453],[-90.670051,41.76363],[-90.743643,41.741527],[-90.665778,41.676511],[-90.626698,41.683726]]]},"properties":{"id":"52746","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.663453,42.028745],[-92.64935,42.014295],[-92.49326,41.978302],[-92.415221,42.001913],[-92.415408,42.029127],[-92.532881,42.122997],[-92.600975,42.152411],[-92.650258,42.138074],[-92.663453,42.028745]]]},"properties":{"id":"52342","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.794957,40.741491],[-94.914742,40.631095],[-94.914896,40.575068],[-94.642601,40.571437],[-94.471084,40.711825],[-94.47147,40.725934],[-94.556651,40.790812],[-94.585763,40.798747],[-94.680586,40.791828],[-94.794957,40.741491]]]},"properties":{"id":"50833","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.111579,41.070122],[-94.203903,41.157039],[-94.241637,41.157134],[-94.30109,40.970885],[-94.142613,40.919219],[-94.105285,40.919135],[-94.111579,41.070122]]]},"properties":{"id":"50830","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.480388,40.724636],[-93.33395,40.580441],[-93.212396,40.581625],[-93.173515,40.724632],[-93.21207,40.811958],[-93.263294,40.843009],[-93.288565,40.833487],[-93.301387,40.849455],[-93.259996,40.85153],[-93.289001,40.884684],[-93.356155,40.898042],[-93.412929,40.884222],[-93.480388,40.724636]]]},"properties":{"id":"50060","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.325054,42.537686],[-94.443022,42.573307],[-94.443033,42.473378],[-94.397671,42.4733],[-94.305884,42.483656],[-94.325054,42.537686]]]},"properties":{"id":"50518","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.227384,42.623151],[-94.325054,42.537686],[-94.305884,42.483656],[-94.24265,42.412732],[-94.222926,42.325368],[-94.164446,42.296227],[-94.145168,42.296204],[-94.125867,42.310428],[-94.145602,42.371642],[-94.098695,42.434971],[-94.069462,42.530171],[-94.089921,42.565252],[-94.209502,42.630957],[-94.227384,42.623151]]]},"properties":{"id":"50501","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.115796,42.209916],[-94.145168,42.296204],[-94.164446,42.296227],[-94.223044,42.209839],[-94.115796,42.209916]]]},"properties":{"id":"50544","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.930401,43.499814],[-93.932087,43.473312],[-93.892185,43.473327],[-93.892288,43.499774],[-93.930401,43.499814]]]},"properties":{"id":"50465","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.36519,42.420368],[-96.411124,42.38606],[-96.369402,42.308983],[-96.242484,42.37331],[-96.252662,42.425981],[-96.36519,42.420368]]]},"properties":{"id":"51054","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.135644,42.429147],[-96.252806,42.445876],[-96.252662,42.425981],[-96.242484,42.37331],[-96.173324,42.354411],[-96.116603,42.385962],[-96.135644,42.429147]]]},"properties":{"id":"51007","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.024092,43.342336],[-93.084002,43.386102],[-93.141176,43.327645],[-93.099984,43.284139],[-93.024123,43.284565],[-93.024092,43.342336]]]},"properties":{"id":"50440","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.184614,41.530776],[-94.292517,41.556761],[-94.317789,41.364983],[-94.241506,41.341268],[-94.193322,41.361623],[-94.184614,41.530776]]]},"properties":{"id":"50070","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.470964,41.417232],[-94.431915,41.598368],[-94.608154,41.616373],[-94.58572,41.403327],[-94.470964,41.417232]]]},"properties":{"id":"50048","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.411939,43.432677],[-91.276007,43.453693],[-91.465268,43.500684],[-91.411939,43.432677]]]},"properties":{"id":"52160","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.696439,40.898613],[-92.71967,40.813867],[-92.695798,40.768756],[-92.638891,40.768938],[-92.609871,40.78718],[-92.605877,40.898902],[-92.696439,40.898613]]]},"properties":{"id":"52594","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.734728,40.93005],[-92.983418,40.912536],[-93.039242,40.870852],[-93.021083,40.846928],[-92.891732,40.822921],[-92.753756,40.818497],[-92.71967,40.813867],[-92.696439,40.898613],[-92.734728,40.93005]]]},"properties":{"id":"52571","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.753756,40.818497],[-92.891732,40.822921],[-93.001605,40.750641],[-93.030193,40.740414],[-93.097296,40.584014],[-93.077788,40.584512],[-92.88657,40.647238],[-92.771373,40.68048],[-92.78114,40.750591],[-92.753756,40.818497]]]},"properties":{"id":"52544","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.124843,41.6206],[-95.147057,41.592083],[-95.137164,41.534052],[-95.060146,41.548379],[-95.040959,41.562859],[-95.011657,41.616515],[-95.055525,41.631137],[-95.124843,41.6206]]]},"properties":{"id":"51531","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.279832,41.978716],[-92.211163,41.942616],[-92.149193,41.963793],[-92.144055,42.058039],[-92.182965,42.079742],[-92.26078,42.065428],[-92.279832,41.978716]]]},"properties":{"id":"52249","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.83147,41.86942],[-91.831643,41.883581],[-91.872074,41.949091],[-91.929516,41.9198],[-91.96225,41.862039],[-91.947911,41.845405],[-91.831379,41.861851],[-91.83147,41.86942]]]},"properties":{"id":"52318","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.02717,41.949201],[-92.045957,41.891759],[-91.96225,41.862039],[-91.929516,41.9198],[-91.930204,41.948868],[-92.008636,41.977953],[-92.02717,41.949201]]]},"properties":{"id":"52354","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.311709,42.481411],[-92.335684,42.495299],[-92.282668,42.459364],[-92.311709,42.481411]]]},"properties":{"id":"50702","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.278201,42.642209],[-92.121468,42.522854],[-92.08236,42.552571],[-92.179965,42.642119],[-92.258549,42.6422],[-92.278201,42.642209]]]},"properties":{"id":"50626","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.253485,42.470469],[-92.219603,42.457214],[-92.199614,42.469266],[-92.253485,42.470469]]]},"properties":{"id":"50667","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.221542,42.406667],[-92.0647,42.362564],[-91.986705,42.386216],[-92.019825,42.542779],[-92.08236,42.552571],[-92.121468,42.522854],[-92.199614,42.469266],[-92.219603,42.457214],[-92.219472,42.449641],[-92.219151,42.426306],[-92.212123,42.426202],[-92.210199,42.413669],[-92.218306,42.414759],[-92.221542,42.406667]]]},"properties":{"id":"50648","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963727,42.642181],[-92.040655,42.729564],[-92.068448,42.750626],[-92.140759,42.714697],[-92.179965,42.642119],[-92.08236,42.552571],[-92.019825,42.542779],[-91.993964,42.558313],[-91.963727,42.642181]],[[-92.078856,42.702238],[-92.073563,42.702258],[-92.073543,42.700259],[-92.07881,42.700247],[-92.078856,42.702238]]]},"properties":{"id":"50629","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.948106,42.29749],[-91.944012,42.369822],[-91.986705,42.386216],[-92.0647,42.362564],[-92.064766,42.297259],[-92.062503,42.297253],[-91.959598,42.297503],[-91.948106,42.29749]]]},"properties":{"id":"52210","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.05311,42.719887],[-95.071889,42.561099],[-95.041309,42.518073],[-94.91463,42.546848],[-94.915135,42.676156],[-95.05311,42.719887]]]},"properties":{"id":"50568","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.466083,42.864735],[-95.505828,42.605463],[-95.486212,42.576417],[-95.388084,42.605364],[-95.33942,42.836643],[-95.466083,42.864735]]]},"properties":{"id":"51005","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.947545,42.528626],[-92.947103,42.671893],[-93.045773,42.709109],[-93.184451,42.600241],[-93.134321,42.478347],[-93.00291,42.462411],[-92.947545,42.528626]]]},"properties":{"id":"50601","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.063041,42.994572],[-93.123484,42.922061],[-93.074714,42.847585],[-93.025375,42.862107],[-92.9557,42.907472],[-93.004984,42.980046],[-93.063041,42.994572]]]},"properties":{"id":"50433","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.736827,42.626831],[-94.777034,42.517125],[-94.67838,42.473794],[-94.645965,42.473739],[-94.618853,42.56],[-94.638739,42.638928],[-94.736827,42.626831]]]},"properties":{"id":"50575","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.955982,42.107608],[-95.072874,42.152833],[-95.053478,42.008204],[-94.976155,42.008057],[-94.955982,42.107608]]]},"properties":{"id":"51430","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.168766,42.167299],[-95.169404,41.99472],[-95.165964,41.974535],[-95.053478,42.008204],[-95.072874,42.152833],[-95.168766,42.167299]]]},"properties":{"id":"51467","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.313673,41.804459],[-91.308357,41.778385],[-91.185247,41.693325],[-91.133307,41.645609],[-91.093961,41.644487],[-90.995957,41.684428],[-91.015513,41.78634],[-91.123041,41.837734],[-91.191386,41.847925],[-91.307598,41.816319],[-91.313673,41.804459]]]},"properties":{"id":"52772","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.918832,41.669896],[-90.900444,41.536946],[-90.881676,41.531453],[-90.860571,41.625867],[-90.860565,41.633102],[-90.899232,41.669755],[-90.918832,41.669896]]]},"properties":{"id":"52747","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.497564,43.213107],[-93.457886,43.009627],[-93.379101,43.009487],[-93.320217,43.046401],[-93.290298,43.082923],[-93.319933,43.255202],[-93.349169,43.255591],[-93.398349,43.255389],[-93.497635,43.255468],[-93.497564,43.213107]]]},"properties":{"id":"50428","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.497989,42.980818],[-93.557585,42.937647],[-93.557916,42.90869],[-93.498616,42.857255],[-93.458644,42.848644],[-93.497989,42.980818]]]},"properties":{"id":"50457","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.690009,42.662913],[-95.760016,42.662984],[-95.780446,42.633301],[-95.603877,42.576201],[-95.536402,42.605165],[-95.690009,42.662913]]]},"properties":{"id":"51049","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.681298,42.764407],[-95.636251,42.882389],[-95.681914,42.895428],[-95.681298,42.764407]]]},"properties":{"id":"51037","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.554539,42.939162],[-95.564172,42.968131],[-95.70325,43.048058],[-95.741771,43.055121],[-95.800492,42.909497],[-95.741036,42.909713],[-95.681914,42.895428],[-95.636251,42.882389],[-95.622845,42.895705],[-95.56393,42.910101],[-95.546234,42.948462],[-95.554539,42.939162]]]},"properties":{"id":"51046","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.59389,42.907132],[-92.436112,42.90704],[-92.554101,43.023236],[-92.653311,42.942996],[-92.672895,42.907126],[-92.59389,42.907132]]]},"properties":{"id":"50658","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.620267,41.143512],[-93.614586,40.965474],[-93.575683,40.898459],[-93.556613,40.927419],[-93.556586,40.996857],[-93.557556,41.161271],[-93.620267,41.143512]]]},"properties":{"id":"50275","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.796831,43.038652],[-94.821565,43.083053],[-94.913661,43.035967],[-94.973842,43.024646],[-94.913823,42.953229],[-94.885584,42.953347],[-94.796831,43.038652]]]},"properties":{"id":"50515","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.241487,43.255422],[-95.259992,43.127057],[-95.19145,43.024866],[-95.057351,43.067212],[-95.052457,43.255404],[-95.241487,43.255422]]]},"properties":{"id":"51301","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.259992,43.127057],[-95.34846,43.098464],[-95.368525,43.068778],[-95.30921,43.010363],[-95.21135,43.010463],[-95.184034,43.010313],[-95.19145,43.024866],[-95.259992,43.127057]]]},"properties":{"id":"51357","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.325929,42.800693],[-91.368468,42.755006],[-91.277962,42.73989],[-91.325929,42.800693]]]},"properties":{"id":"52044","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.468621,42.612858],[-91.414983,42.601118],[-91.284986,42.667223],[-91.277962,42.73989],[-91.368468,42.755006],[-91.368983,42.753746],[-91.468621,42.612858]]]},"properties":{"id":"52042","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.043964,42.603215],[-91.01509,42.717553],[-91.015101,42.71932],[-91.094942,42.835708],[-91.272664,42.81223],[-91.167248,42.73254],[-91.111523,42.646093],[-91.043964,42.603215]]]},"properties":{"id":"52052","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.177222,43.080247],[-91.180267,43.080263],[-91.174408,43.038001],[-91.177222,43.080247]]]},"properties":{"id":"52158","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.354076,42.018799],[-90.370681,42.121024],[-90.397289,42.116831],[-90.54904,42.101856],[-90.529697,42.038039],[-90.404011,41.996859],[-90.368943,42.004298],[-90.354076,42.018799]]]},"properties":{"id":"52069","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.370681,42.121024],[-90.354076,42.018799],[-90.296436,42.004565],[-90.262711,42.0334],[-90.296151,42.18638],[-90.306647,42.19047],[-90.370681,42.121024]]]},"properties":{"id":"52064","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.41142,41.93423],[-95.482552,42.018598],[-95.493949,41.948166],[-95.41142,41.93423]]]},"properties":{"id":"51520","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.440528,41.863236],[-95.440633,41.848742],[-95.344727,41.76165],[-95.286094,41.761148],[-95.285197,41.82093],[-95.324863,41.878631],[-95.36369,41.89287],[-95.440528,41.863236]]]},"properties":{"id":"51527","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.208368,41.994773],[-95.324863,41.878631],[-95.285197,41.82093],[-95.093304,41.776945],[-95.165964,41.974535],[-95.169404,41.99472],[-95.208368,41.994773]]]},"properties":{"id":"51454","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.777976,41.801284],[-93.814992,41.798608],[-93.850728,41.760947],[-93.824378,41.742179],[-93.750402,41.721966],[-93.753511,41.754547],[-93.777976,41.801284]]]},"properties":{"id":"50109","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.434985,41.75267],[-94.28067,41.731711],[-94.251034,41.746215],[-94.260866,41.80562],[-94.338311,41.820165],[-94.43529,41.776949],[-94.434985,41.75267]]]},"properties":{"id":"50277","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.01411,41.493043],[-93.885443,41.388562],[-93.838348,41.428442],[-93.827575,41.51949],[-93.863485,41.520249],[-93.950447,41.533947],[-94.007474,41.540797],[-94.012762,41.503191],[-94.01411,41.493043]]]},"properties":{"id":"50261","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.605877,40.898902],[-92.609871,40.78718],[-92.466188,40.899215],[-92.510674,40.917484],[-92.605877,40.898902]]]},"properties":{"id":"52552","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.249575,40.693475],[-92.222717,40.59975],[-92.121628,40.60159],[-92.044231,40.68895],[-92.086011,40.747998],[-92.178468,40.769051],[-92.249575,40.693475]]]},"properties":{"id":"52570","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.863455,40.802714],[-93.800228,40.645478],[-93.652229,40.63827],[-93.632161,40.696092],[-93.671469,40.856165],[-93.747722,40.81215],[-93.900284,40.8532],[-93.863455,40.802714]]]},"properties":{"id":"50144","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.414983,42.601118],[-91.468621,42.612858],[-91.489106,42.60043],[-91.578861,42.541986],[-91.539644,42.420293],[-91.403419,42.361826],[-91.393126,42.361831],[-91.321321,42.470768],[-91.341269,42.542663],[-91.414983,42.601118]]]},"properties":{"id":"52057","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.321321,42.470768],[-91.393126,42.361831],[-91.220043,42.423874],[-91.321321,42.470768]]]},"properties":{"id":"52223","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.109872,42.321672],[-91.18968,42.433809],[-91.220043,42.423874],[-91.393126,42.361831],[-91.403419,42.361826],[-91.422107,42.310803],[-91.363263,42.296445],[-91.130079,42.295761],[-91.109872,42.321672]]]},"properties":{"id":"52237","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.033584,42.143951],[-91.014057,42.202991],[-91.130079,42.295761],[-91.363263,42.296445],[-91.402936,42.238395],[-91.131835,42.120996],[-91.033584,42.143951]]]},"properties":{"id":"52310","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.707987,42.493033],[-90.655924,42.491708],[-90.674827,42.577699],[-90.731222,42.582122],[-90.707987,42.493033]]]},"properties":{"id":"52001","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.776263,42.205153],[-90.687632,42.207742],[-90.665198,42.367474],[-90.737026,42.382057],[-90.763141,42.382023],[-90.776263,42.205153]]]},"properties":{"id":"52079","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.678742,43.341789],[-94.816641,43.284338],[-94.659439,43.291642],[-94.678742,43.341789]]]},"properties":{"id":"51365","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.824558,42.716532],[-91.854633,42.818004],[-91.943798,42.818305],[-91.981655,42.744129],[-91.824558,42.716532]]]},"properties":{"id":"50655","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.004984,42.980046],[-92.9557,42.907472],[-92.762141,42.994472],[-92.798634,43.009041],[-93.004984,42.980046]]]},"properties":{"id":"50653","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.25484,42.643755],[-93.242714,42.643689],[-93.243316,42.629569],[-93.20358,42.629391],[-93.08491,42.730839],[-93.058823,42.818658],[-93.094287,42.835802],[-93.320561,42.83354],[-93.360078,42.731038],[-93.369874,42.731057],[-93.399929,42.702732],[-93.360143,42.644273],[-93.320725,42.644015],[-93.25484,42.643755]]]},"properties":{"id":"50441","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.384964,40.90155],[-95.422824,40.952384],[-95.501026,40.916072],[-95.512063,40.901652],[-95.461629,40.829384],[-95.434022,40.814857],[-95.384704,40.829128],[-95.384964,40.90155]]]},"properties":{"id":"51645","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.614244,40.872966],[-95.543532,40.786859],[-95.461629,40.829384],[-95.512063,40.901652],[-95.578244,40.901612],[-95.614244,40.872966]]]},"properties":{"id":"51649","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.454377,41.921014],[-94.338296,41.86301],[-94.275252,41.913846],[-94.261137,41.964594],[-94.261881,42.077995],[-94.281169,42.122734],[-94.378122,42.151676],[-94.495186,42.074263],[-94.454377,41.921014]]]},"properties":{"id":"50129","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.767969,42.282426],[-92.84559,42.296454],[-92.854601,42.166634],[-92.786676,42.181256],[-92.767969,42.282426]]]},"properties":{"id":"50609","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.679672,42.441542],[-93.777139,42.368407],[-93.757303,42.339576],[-93.601059,42.383572],[-93.596357,42.405388],[-93.601136,42.419836],[-93.679672,42.441542]]]},"properties":{"id":"50132","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.501773,42.403283],[-93.596357,42.405388],[-93.601059,42.383572],[-93.605543,42.270762],[-93.505638,42.252833],[-93.501773,42.403283]]]},"properties":{"id":"50075","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.608117,42.238329],[-93.60818,42.231122],[-93.595997,42.234858],[-93.604443,42.242075],[-93.608117,42.238329]]]},"properties":{"id":"50231","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.078629,41.761963],[-96.079257,41.699312],[-95.934181,41.674711],[-95.905984,41.7087],[-95.876455,41.761886],[-96.002336,41.809354],[-96.078629,41.761963]]]},"properties":{"id":"51557","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.977721,41.899397],[-96.10911,41.836043],[-96.078629,41.761963],[-96.002336,41.809354],[-95.93701,41.863467],[-95.977721,41.899397]]]},"properties":{"id":"51545","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.934181,41.674711],[-96.079257,41.699312],[-96.11466,41.603703],[-95.934181,41.674711]]]},"properties":{"id":"51556","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.543628,41.142031],[-91.549956,41.138352],[-91.540293,41.131106],[-91.543628,41.142031]]]},"properties":{"id":"52647","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.462606,42.790349],[-94.344582,42.756718],[-94.364271,42.819396],[-94.442856,42.848424],[-94.462606,42.790349]]]},"properties":{"id":"50520","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.324866,42.755877],[-94.266551,42.826716],[-94.344547,42.826816],[-94.364271,42.819396],[-94.344582,42.756718],[-94.324866,42.755877]]]},"properties":{"id":"50582","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.575116,42.39352],[-95.555382,42.21093],[-95.400992,42.210743],[-95.362037,42.268565],[-95.381287,42.41417],[-95.438732,42.469772],[-95.575116,42.39352]]]},"properties":{"id":"51445","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.961224,42.443758],[-95.786555,42.371062],[-95.728998,42.356241],[-95.728456,42.385179],[-95.701352,42.523506],[-95.810756,42.560819],[-95.937792,42.51853],[-95.957631,42.475074],[-95.961224,42.443758]]]},"properties":{"id":"51016","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.156622,41.510156],[-91.96389,41.49373],[-91.946102,41.495087],[-91.900153,41.54035],[-92.052234,41.571216],[-92.182899,41.601299],[-92.221214,41.606458],[-92.21979,41.531705],[-92.156622,41.510156]],[[-92.156922,41.570728],[-92.162784,41.570726],[-92.16285,41.573621],[-92.153505,41.57277],[-92.156922,41.570728]]]},"properties":{"id":"52316","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.347991,41.828696],[-93.348328,41.744563],[-93.183239,41.746429],[-93.179145,41.746335],[-93.246874,41.820154],[-93.347991,41.828696]],[[-93.2058,41.776603],[-93.207254,41.776611],[-93.207239,41.777679],[-93.204529,41.777679],[-93.2058,41.776603]]]},"properties":{"id":"50168","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.629638,41.666897],[-92.610621,41.848224],[-92.688516,41.862282],[-92.805166,41.845081],[-92.832094,41.654061],[-92.756985,41.5812],[-92.629747,41.610075],[-92.629638,41.666897]]]},"properties":{"id":"50112","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.672487,41.976845],[-91.834011,41.978594],[-91.833654,41.96401],[-91.734191,41.87658],[-91.641223,41.845715],[-91.617191,41.860984],[-91.599261,41.951554],[-91.642816,41.967349],[-91.672487,41.976845]]]},"properties":{"id":"52404","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.136711,41.398895],[-92.021863,41.298428],[-92.024535,41.410193],[-92.131822,41.424103],[-92.136711,41.398895]]]},"properties":{"id":"52231","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.492393,41.264049],[-92.486868,41.161655],[-92.410233,41.161942],[-92.410775,41.267301],[-92.410817,41.272082],[-92.467199,41.299068],[-92.492393,41.264049]]]},"properties":{"id":"52561","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.285655,43.254694],[-94.364228,43.298293],[-94.383844,43.168727],[-94.364007,43.141251],[-94.286004,43.157165],[-94.285655,43.254694]]]},"properties":{"id":"50559","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.369661,40.588822],[-91.264326,40.63409],[-91.328146,40.741702],[-91.343936,40.727714],[-91.342429,40.743813],[-91.342427,40.744564],[-91.406713,40.629578],[-91.444596,40.605988],[-91.369661,40.588822]]]},"properties":{"id":"52627","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.500695,40.468104],[-91.523703,40.455641],[-91.36747,40.476227],[-91.500695,40.468104]]]},"properties":{"id":"52632","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.661156,41.981672],[-91.673969,41.982858],[-91.672487,41.976845],[-91.642816,41.967349],[-91.661156,41.981672]]]},"properties":{"id":"52401","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.337877,41.335874],[-91.196972,41.269238],[-91.182222,41.276424],[-91.144787,41.283504],[-91.151787,41.334447],[-91.152573,41.335448],[-91.290998,41.42288],[-91.289777,41.409482],[-91.337877,41.335874]]]},"properties":{"id":"52754","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.368521,41.423178],[-91.445912,41.392876],[-91.337877,41.335874],[-91.289777,41.409482],[-91.368521,41.423178]]]},"properties":{"id":"52739","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.556586,40.996857],[-93.442104,41.003231],[-93.441843,41.124084],[-93.500089,41.182894],[-93.543104,41.190398],[-93.557654,41.175885],[-93.557556,41.161271],[-93.556586,40.996857]]]},"properties":{"id":"50151","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.412929,40.884222],[-93.356155,40.898042],[-93.442104,41.003231],[-93.556586,40.996857],[-93.556613,40.927419],[-93.412929,40.884222]]]},"properties":{"id":"50068","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.116487,43.346057],[-96.097886,43.279499],[-96.059402,43.258324],[-95.949459,43.257777],[-95.937312,43.272371],[-95.860445,43.359993],[-95.998198,43.418254],[-96.116487,43.346057]]]},"properties":{"id":"51237","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.32966,43.5003],[-96.521671,43.385762],[-96.369449,43.389572],[-96.338297,43.433061],[-96.329666,43.447503],[-96.32966,43.5003]]]},"properties":{"id":"51241","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.875467,41.349214],[-93.884535,41.352562],[-93.885084,41.346295],[-93.875467,41.349214]]]},"properties":{"id":"50218","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.639955,41.092078],[-92.526927,41.089938],[-92.5252,41.086003],[-92.505971,41.136615],[-92.505966,41.161588],[-92.573448,41.197629],[-92.812617,41.21194],[-92.802726,41.161228],[-92.639955,41.092078]]]},"properties":{"id":"52553","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.492393,41.264049],[-92.573448,41.197629],[-92.505966,41.161588],[-92.496428,41.161623],[-92.486868,41.161655],[-92.492393,41.264049]]]},"properties":{"id":"52543","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.999843,41.985751],[-93.05869,42.007932],[-93.116275,41.942934],[-93.154796,41.862617],[-93.057954,41.862627],[-92.999755,41.877002],[-92.999843,41.985751]]]},"properties":{"id":"50162","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.16623,42.189323],[-93.195286,42.106661],[-93.117171,42.084723],[-93.088161,42.106529],[-93.103035,42.158299],[-93.117823,42.201503],[-93.16623,42.189323]]]},"properties":{"id":"50051","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.556507,41.116734],[-95.63168,41.08784],[-95.671453,40.923211],[-95.578244,40.901612],[-95.512063,40.901652],[-95.501026,40.916072],[-95.556507,41.116734]]]},"properties":{"id":"51551","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.86483,41.289964],[-95.84171,41.175858],[-95.873877,41.073136],[-95.782079,41.146318],[-95.691918,41.160007],[-95.68308,41.1891],[-95.670257,41.275289],[-95.708291,41.332734],[-95.79435,41.350856],[-95.86483,41.289964]]]},"properties":{"id":"51503","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.308408,41.159741],[-95.442162,41.08782],[-95.422824,40.952384],[-95.384964,40.90155],[-95.346608,40.930885],[-95.308408,41.159741]]]},"properties":{"id":"51533","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.048859,42.126043],[-96.154634,42.213879],[-96.301299,42.128065],[-96.274608,42.085386],[-96.048859,42.126043]]]},"properties":{"id":"51063","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.884116,42.247762],[-96.000342,42.284266],[-96.001009,42.211761],[-95.941173,42.198729],[-95.969636,42.168964],[-95.904022,42.175852],[-95.884116,42.247762]]]},"properties":{"id":"51056","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.98956,41.073656],[-92.822697,41.161076],[-92.908265,41.160941],[-93.061494,41.160718],[-93.099217,41.160867],[-93.098703,41.122865],[-93.098684,41.115686],[-92.98956,41.073656]]]},"properties":{"id":"50150","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.988632,41.138547],[-94.975093,41.137397],[-94.975,41.144619],[-94.988452,41.144682],[-94.988632,41.138547]]]},"properties":{"id":"50847","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.197287,40.72798],[-95.354607,40.640592],[-95.256074,40.610912],[-95.125701,40.638686],[-95.197287,40.72798]]]},"properties":{"id":"51636","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.153672,40.735227],[-95.159095,40.739049],[-95.159061,40.729393],[-95.153843,40.73205],[-95.153672,40.735227]]]},"properties":{"id":"51656","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.885584,42.953347],[-94.757406,42.909114],[-94.705152,42.908953],[-94.698319,43.009649],[-94.796831,43.038652],[-94.885584,42.953347]]]},"properties":{"id":"50527","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.449731,42.627616],[-96.449688,42.649381],[-96.515178,42.627569],[-96.440308,42.489595],[-96.460779,42.56152],[-96.449731,42.627616]]]},"properties":{"id":"51109","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.899554,42.661586],[-94.902267,42.653591],[-94.894753,42.653498],[-94.899554,42.661586]]]},"properties":{"id":"50593","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.570717,41.820489],[-93.610428,41.936024],[-93.61685,41.949416],[-93.659194,41.902774],[-93.600282,41.829423],[-93.580872,41.82064],[-93.570717,41.820489]]]},"properties":{"id":"50124","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.574011,41.651092],[-93.60064,41.680414],[-93.679061,41.687953],[-93.666634,41.650839],[-93.634899,41.619242],[-93.612217,41.609389],[-93.583104,41.617359],[-93.574011,41.651092]]]},"properties":{"id":"50313","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.639398,41.791329],[-93.675486,41.704055],[-93.679061,41.687953],[-93.60064,41.680414],[-93.600513,41.776767],[-93.619946,41.791325],[-93.639398,41.791329]]]},"properties":{"id":"50023","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626708,41.512055],[-93.596896,41.575385],[-93.641901,41.582324],[-93.637619,41.512079],[-93.626708,41.512055]]]},"properties":{"id":"50315","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.703769,41.607646],[-93.703595,41.595885],[-93.643734,41.595974],[-93.64386,41.608264],[-93.697743,41.608174],[-93.703769,41.607646]]]},"properties":{"id":"50311","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.719846,41.597678],[-93.709817,41.591834],[-93.703595,41.595885],[-93.703769,41.607646],[-93.726779,41.614858],[-93.719846,41.597678]]]},"properties":{"id":"50324","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.616706,41.595659],[-93.5969,41.586831],[-93.583104,41.617359],[-93.612217,41.609389],[-93.616706,41.595659]]]},"properties":{"id":"50316","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.90284,41.274374],[-95.905899,41.301038],[-95.927642,41.29844],[-95.914091,41.272428],[-95.90284,41.274374]]]},"properties":{"id":"51510","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.68308,41.1891],[-95.565649,41.18906],[-95.537392,41.203632],[-95.536753,41.289336],[-95.55586,41.303922],[-95.575014,41.303891],[-95.670257,41.275289],[-95.68308,41.1891]]]},"properties":{"id":"51575","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.62201,41.354893],[-95.708291,41.332734],[-95.670257,41.275289],[-95.575014,41.303891],[-95.594498,41.361622],[-95.62201,41.354893]]]},"properties":{"id":"51548","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.479946,41.418867],[-95.507617,41.447637],[-95.574797,41.506162],[-95.59108,41.506031],[-95.594498,41.361622],[-95.575014,41.303891],[-95.55586,41.303922],[-95.497947,41.375226],[-95.479946,41.418867]]]},"properties":{"id":"51553","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.756985,41.5812],[-92.747918,41.50871],[-92.642131,41.538117],[-92.629747,41.610075],[-92.756985,41.5812]]]},"properties":{"id":"50242","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.404778,40.675517],[-94.389203,40.571453],[-94.28745,40.571661],[-94.338027,40.681923],[-94.404778,40.675517]]]},"properties":{"id":"50860","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.242673,40.855152],[-94.321049,40.736766],[-94.338027,40.681923],[-94.28745,40.571661],[-94.120008,40.572903],[-94.129464,40.617091],[-94.12883,40.782559],[-94.223707,40.854787],[-94.242673,40.855152]]]},"properties":{"id":"50854","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.685654,41.603633],[-90.583259,41.552265],[-90.568427,41.55375],[-90.563565,41.618039],[-90.66604,41.646829],[-90.685654,41.603633]]]},"properties":{"id":"52806","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.582969,41.525171],[-90.581615,41.515188],[-90.566017,41.518149],[-90.582969,41.525171]]]},"properties":{"id":"52801","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.791872,41.683834],[-90.675682,41.654727],[-90.665778,41.676511],[-90.743643,41.741527],[-90.786212,41.771369],[-90.859227,41.771507],[-90.830912,41.698495],[-90.791872,41.683834]]]},"properties":{"id":"52745","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.630711,42.022839],[-93.644728,42.032288],[-93.639493,42.02275],[-93.63899,42.02539],[-93.63348,42.025069],[-93.633488,42.023102],[-93.639334,42.022747],[-93.630711,42.022839]]]},"properties":{"id":"50012","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.366047,41.992825],[-92.394957,41.987553],[-92.376175,41.984024],[-92.366047,41.992825]]]},"properties":{"id":"52348","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.014816,40.940306],[-94.030498,41.058289],[-94.111579,41.070122],[-94.105285,40.919135],[-94.014803,40.897031],[-94.014816,40.940306]]]},"properties":{"id":"50254","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.718455,40.74057],[-91.787049,40.798264],[-91.814049,40.783744],[-91.87464,40.660359],[-91.852849,40.608811],[-91.824199,40.610304],[-91.718455,40.74057]]]},"properties":{"id":"52620","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.970274,40.80135],[-92.086011,40.747998],[-92.044231,40.68895],[-92.024965,40.667137],[-91.87464,40.660359],[-91.814049,40.783744],[-91.900957,40.813578],[-91.970274,40.80135]]]},"properties":{"id":"52565","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.510674,40.917484],[-92.466188,40.899215],[-92.333101,40.906227],[-92.294712,40.949117],[-92.269462,41.010527],[-92.227445,41.061942],[-92.410233,41.161942],[-92.486868,41.161655],[-92.496428,41.161623],[-92.505971,41.136615],[-92.5252,41.086003],[-92.534784,41.082432],[-92.535137,41.089615],[-92.526927,41.089938],[-92.639955,41.092078],[-92.63976,41.063145],[-92.510674,40.917484]]]},"properties":{"id":"52501","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.692771,41.314905],[-93.673506,41.249051],[-93.557654,41.175885],[-93.543104,41.190398],[-93.495676,41.306627],[-93.461955,41.392898],[-93.577806,41.445955],[-93.703198,41.422976],[-93.692771,41.314905]]]},"properties":{"id":"50125","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.125867,42.310428],[-93.931881,42.302979],[-93.931893,42.368647],[-93.931906,42.383705],[-94.068909,42.383697],[-94.145602,42.371642],[-94.125867,42.310428]],[[-94.11555,42.353987],[-94.09866,42.354322],[-94.084883,42.340202],[-94.115257,42.339496],[-94.11555,42.353987]]]},"properties":{"id":"50557","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.397092,42.396004],[-94.24265,42.412732],[-94.305884,42.483656],[-94.397671,42.4733],[-94.397092,42.396004]]]},"properties":{"id":"50566","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.36519,42.420368],[-96.252662,42.425981],[-96.252806,42.445876],[-96.272612,42.522632],[-96.332716,42.512017],[-96.374585,42.493273],[-96.390971,42.483345],[-96.392648,42.48141],[-96.38066,42.446768],[-96.36519,42.420368]]]},"properties":{"id":"51106","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.141176,43.327645],[-93.350028,43.291774],[-93.349169,43.255591],[-93.319933,43.255202],[-93.141565,43.255106],[-93.099984,43.284139],[-93.141176,43.327645]]]},"properties":{"id":"50456","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.241486,41.287093],[-94.546985,41.229973],[-94.604422,41.186625],[-94.547455,41.157806],[-94.260714,41.200397],[-94.241705,41.243738],[-94.241486,41.287093]]]},"properties":{"id":"50858","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.585586,40.957256],[-94.700304,40.845253],[-94.585763,40.798747],[-94.556651,40.790812],[-94.453376,40.856179],[-94.376059,40.939973],[-94.527565,40.985807],[-94.585586,40.957256]]]},"properties":{"id":"50851","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.432791,43.167879],[-91.489164,42.974861],[-91.449035,42.959957],[-91.43908,42.993571],[-91.386378,43.135276],[-91.432791,43.167879]]]},"properties":{"id":"52156","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.938115,43.389784],[-91.903365,43.25528],[-91.824105,43.17737],[-91.673662,43.197808],[-91.604451,43.22221],[-91.576211,43.360153],[-91.670808,43.500598],[-91.930194,43.500639],[-91.938115,43.389784]]]},"properties":{"id":"52101","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.173515,40.724632],[-93.212396,40.581625],[-93.097296,40.584014],[-93.030193,40.740414],[-93.097454,40.74652],[-93.173515,40.724632]]]},"properties":{"id":"52590","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.060146,41.548379],[-95.15485,41.433871],[-95.155316,41.37513],[-94.965383,41.273476],[-94.911286,41.331184],[-94.853909,41.468228],[-94.868743,41.504586],[-94.971712,41.555307],[-95.040959,41.562859],[-95.060146,41.548379]]]},"properties":{"id":"50022","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.959598,42.297503],[-92.062503,42.297253],[-92.142927,42.210611],[-92.124572,42.07961],[-91.990312,42.050674],[-91.957868,42.067452],[-91.833285,42.176157],[-91.910412,42.216512],[-91.910281,42.260467],[-91.959598,42.297503]]]},"properties":{"id":"52349","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.253485,42.470469],[-92.199614,42.469266],[-92.121468,42.522854],[-92.278201,42.642209],[-92.337579,42.64226],[-92.41268,42.53062],[-92.335684,42.495299],[-92.311709,42.481411],[-92.253485,42.470469]]]},"properties":{"id":"50703","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.728612,42.296807],[-92.601375,42.239105],[-92.514232,42.311611],[-92.572838,42.42762],[-92.671032,42.391424],[-92.728612,42.296807]]]},"properties":{"id":"50669","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.883157,41.950532],[-93.737168,41.97961],[-93.737502,42.166645],[-93.747148,42.209429],[-93.835229,42.209643],[-93.96574,42.19515],[-93.984661,42.123118],[-93.969725,41.964773],[-93.883157,41.950532]]]},"properties":{"id":"50036","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.49536,43.156446],[-92.554101,43.023236],[-92.436112,42.90704],[-92.377403,42.877473],[-92.358113,42.8776],[-92.337408,42.907064],[-92.337595,42.93631],[-92.455686,43.127004],[-92.49536,43.156446]]]},"properties":{"id":"50645","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.59389,42.907132],[-92.554451,42.788149],[-92.377403,42.877473],[-92.436112,42.90704],[-92.59389,42.907132]]]},"properties":{"id":"50666","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963727,42.642181],[-91.993964,42.558313],[-91.835191,42.578139],[-91.825437,42.599997],[-91.864338,42.643247],[-91.963727,42.642181]]]},"properties":{"id":"50641","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.963727,42.642181],[-91.864338,42.643247],[-91.824796,42.70196],[-91.824558,42.716532],[-91.981655,42.744129],[-92.040655,42.729564],[-91.963727,42.642181]]]},"properties":{"id":"50662","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.033346,43.012028],[-95.044415,43.006903],[-95.116888,42.968133],[-95.033663,42.901692],[-94.933439,42.909724],[-94.913823,42.953229],[-94.973842,43.024646],[-95.033346,43.012028]]]},"properties":{"id":"51366","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.397671,42.4733],[-94.443033,42.473378],[-94.475425,42.44791],[-94.475368,42.368597],[-94.455693,42.35417],[-94.397367,42.336269],[-94.393013,42.354219],[-94.397092,42.396004],[-94.397671,42.4733]]]},"properties":{"id":"50586","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.67838,42.473794],[-94.777034,42.517125],[-94.835635,42.488449],[-94.786313,42.442485],[-94.67838,42.473794]]]},"properties":{"id":"50551","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.702028,42.180991],[-94.687362,42.180957],[-94.687403,42.188064],[-94.702028,42.180991]]]},"properties":{"id":"51451","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.900444,41.536946],[-90.918832,41.669896],[-90.937649,41.698823],[-90.995957,41.684428],[-91.093961,41.644487],[-91.057718,41.53588],[-90.900444,41.536946]]]},"properties":{"id":"52778","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.015513,41.78634],[-90.995957,41.684428],[-90.937649,41.698823],[-90.898373,41.771392],[-90.898436,41.815205],[-91.013016,41.800893],[-91.015513,41.78634]]]},"properties":{"id":"52721","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.922951,41.917684],[-91.013016,41.800893],[-90.898436,41.815205],[-90.922951,41.917684]]]},"properties":{"id":"52255","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.760016,42.662984],[-95.690009,42.662913],[-95.681298,42.764407],[-95.681914,42.895428],[-95.741036,42.909713],[-95.760016,42.662984]]]},"properties":{"id":"51014","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.817152,43.183587],[-94.913651,43.226523],[-94.959725,43.211149],[-94.913661,43.035967],[-94.821565,43.083053],[-94.817152,43.183587]]]},"properties":{"id":"51358","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.052372,43.342252],[-95.032853,43.255304],[-94.959725,43.211149],[-94.913651,43.226523],[-94.876468,43.255144],[-94.972173,43.370875],[-95.052372,43.342252]]]},"properties":{"id":"51364","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.50361,42.796018],[-91.551826,42.758808],[-91.612138,42.68761],[-91.607059,42.643973],[-91.489106,42.60043],[-91.468621,42.612858],[-91.368983,42.753746],[-91.50361,42.796018]]]},"properties":{"id":"52076","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.284986,42.667223],[-91.250903,42.665933],[-91.167248,42.73254],[-91.272664,42.81223],[-91.285618,42.826535],[-91.325929,42.800693],[-91.277962,42.73989],[-91.284986,42.667223]]]},"properties":{"id":"52048","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.626187,41.894685],[-90.59523,41.769278],[-90.446583,41.763844],[-90.413223,41.801133],[-90.351624,41.808396],[-90.378999,41.870617],[-90.446723,41.891512],[-90.548338,41.920102],[-90.590042,41.901883],[-90.599821,41.901938],[-90.626187,41.894685]]]},"properties":{"id":"52742","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.762514,41.86562],[-90.670051,41.76363],[-90.61816,41.758453],[-90.59523,41.769278],[-90.626187,41.894685],[-90.732603,41.930993],[-90.762514,41.86562]]]},"properties":{"id":"52751","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.400992,42.210743],[-95.555382,42.21093],[-95.562517,42.210908],[-95.478998,42.095494],[-95.381973,42.167782],[-95.400992,42.210743]]]},"properties":{"id":"51461","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.652231,42.161346],[-95.815896,42.254979],[-95.884116,42.247762],[-95.904022,42.175852],[-95.804464,42.063263],[-95.768807,42.048434],[-95.64311,42.124425],[-95.652231,42.161346]]]},"properties":{"id":"51034","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.940678,41.795153],[-94.028249,41.863151],[-94.067432,41.776871],[-93.940678,41.795153]]]},"properties":{"id":"50039","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.815631,41.600449],[-93.809702,41.53329],[-93.774158,41.532335],[-93.734707,41.60039],[-93.815631,41.600449]]]},"properties":{"id":"50266","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.176368,41.863307],[-94.250928,41.863121],[-94.260866,41.80562],[-94.251034,41.746215],[-94.202664,41.731726],[-94.176368,41.863307]]]},"properties":{"id":"50066","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.222717,40.59975],[-92.249575,40.693475],[-92.269758,40.598909],[-92.222717,40.59975]]]},"properties":{"id":"52584","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.957513,40.742478],[-94.129464,40.617091],[-94.120008,40.572903],[-93.937106,40.575209],[-93.855758,40.659354],[-93.910264,40.738536],[-93.957513,40.742478]]]},"properties":{"id":"50140","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.479902,40.681738],[-93.556566,40.696268],[-93.632161,40.696092],[-93.652229,40.63827],[-93.646992,40.578488],[-93.39851,40.580105],[-93.393856,40.608773],[-93.470701,40.609103],[-93.479902,40.681738]]]},"properties":{"id":"50147","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.434765,40.98744],[-91.352457,40.987072],[-91.352484,41.038233],[-91.370646,41.084045],[-91.505545,41.075373],[-91.434765,40.98744]]]},"properties":{"id":"52644","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.473798,42.381458],[-90.655924,42.491708],[-90.707987,42.493033],[-90.764763,42.474703],[-90.737026,42.382057],[-90.665198,42.367474],[-90.593251,42.382113],[-90.473798,42.381458]]]},"properties":{"id":"52003","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.130079,42.295761],[-91.014057,42.202991],[-90.948648,42.179569],[-90.898268,42.206475],[-90.938787,42.360902],[-90.971543,42.38244],[-91.047951,42.360954],[-91.109872,42.321672],[-91.130079,42.295761]]]},"properties":{"id":"52033","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.015944,42.537156],[-90.971543,42.38244],[-90.938787,42.360902],[-90.898386,42.382302],[-90.862779,42.470597],[-90.91881,42.525477],[-91.015768,42.558966],[-91.015944,42.537156]]]},"properties":{"id":"52045","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.593251,42.382113],[-90.636331,42.208236],[-90.596776,42.156943],[-90.586748,42.153073],[-90.525157,42.139425],[-90.397289,42.116831],[-90.370681,42.121024],[-90.306647,42.19047],[-90.473798,42.381458],[-90.593251,42.382113]]]},"properties":{"id":"52031","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.620688,43.378087],[-94.678742,43.341789],[-94.659439,43.291642],[-94.560363,43.241442],[-94.502009,43.218416],[-94.442693,43.327336],[-94.620688,43.378087]]]},"properties":{"id":"50578","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.725731,42.903586],[-91.884327,42.920907],[-91.884312,42.906366],[-91.854633,42.818004],[-91.824558,42.716532],[-91.824796,42.70196],[-91.804881,42.701931],[-91.667599,42.817891],[-91.726321,42.896787],[-91.725731,42.903586]]]},"properties":{"id":"52142","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.078856,42.702238],[-92.07881,42.700247],[-92.073543,42.700259],[-92.073563,42.702258],[-92.078856,42.702238]]]},"properties":{"id":"50664","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.498616,42.857255],[-93.538406,42.789283],[-93.498766,42.745819],[-93.478207,42.73136],[-93.379719,42.870442],[-93.458644,42.848644],[-93.498616,42.857255]]]},"properties":{"id":"50420","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.184451,42.600241],[-93.045773,42.709109],[-93.08491,42.730839],[-93.20358,42.629391],[-93.184451,42.600241]]]},"properties":{"id":"50633","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.243316,42.629569],[-93.242714,42.643689],[-93.25484,42.643755],[-93.243316,42.629569]]]},"properties":{"id":"50041","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.717849,42.223836],[-93.608117,42.238329],[-93.604443,42.242075],[-93.605543,42.270762],[-93.601059,42.383572],[-93.757303,42.339576],[-93.717849,42.223836]]]},"properties":{"id":"50130","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.835229,42.209643],[-93.747148,42.209429],[-93.717849,42.223836],[-93.757303,42.339576],[-93.777139,42.368407],[-93.815698,42.325106],[-93.835229,42.209643]]]},"properties":{"id":"50246","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.971617,42.544219],[-93.695762,42.557531],[-93.676128,42.601171],[-93.793473,42.629991],[-93.971583,42.558139],[-93.971617,42.544219]]]},"properties":{"id":"50599","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.871886,43.0095],[-93.734036,43.009667],[-93.733764,43.198415],[-93.792639,43.218718],[-93.812336,43.227208],[-93.852173,43.255329],[-93.930808,43.169448],[-93.930975,43.052494],[-93.871886,43.0095]]]},"properties":{"id":"50423","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.6954,42.980936],[-93.675663,42.908467],[-93.557916,42.90869],[-93.557585,42.937647],[-93.635993,42.995203],[-93.6954,42.980936]]]},"properties":{"id":"50439","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.424496,42.209283],[-93.424937,42.123156],[-93.366471,42.122953],[-93.346614,42.209229],[-93.366126,42.223791],[-93.424496,42.209283]]]},"properties":{"id":"50154","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.616079,41.641425],[-95.49794,41.585605],[-95.450727,41.602475],[-95.441551,41.67484],[-95.557805,41.689168],[-95.616922,41.646041],[-95.616079,41.641425]]]},"properties":{"id":"51565","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.716783,41.092124],[-91.717284,41.061359],[-91.608058,41.197965],[-91.608029,41.20222],[-91.744883,41.182588],[-91.716783,41.092124]]]},"properties":{"id":"52654","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.050634,42.87791],[-94.01066,42.80385],[-93.91162,42.877615],[-93.911257,42.908197],[-93.990797,42.907799],[-94.050634,42.87791]]]},"properties":{"id":"50577","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.376978,41.775465],[-92.358065,41.688488],[-92.258041,41.610711],[-92.254042,41.609149],[-92.240415,41.790105],[-92.298816,41.797952],[-92.376978,41.775465]]]},"properties":{"id":"52347","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.239325,41.438252],[-92.136711,41.398895],[-92.131822,41.424103],[-92.156622,41.510156],[-92.21979,41.531705],[-92.253812,41.531672],[-92.239325,41.438252]]]},"properties":{"id":"52355","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.883537,41.645292],[-92.95378,41.819736],[-93.057954,41.862627],[-93.179145,41.746335],[-93.183239,41.746429],[-93.11635,41.63701],[-93.037471,41.574637],[-92.964205,41.601566],[-92.883537,41.645292]]]},"properties":{"id":"50208","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.863168,41.620982],[-92.824177,41.508664],[-92.785715,41.486554],[-92.747918,41.50871],[-92.756985,41.5812],[-92.832094,41.654061],[-92.863168,41.620982]]]},"properties":{"id":"50153","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.432725,41.600676],[-93.443097,41.511843],[-93.441286,41.507589],[-93.328407,41.490921],[-93.281646,41.488856],[-93.234069,41.501249],[-93.348681,41.600999],[-93.406291,41.600418],[-93.432725,41.600676]]]},"properties":{"id":"50237","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.15441,41.126824],[-92.114013,41.076273],[-92.004972,41.119762],[-92.042748,41.163438],[-92.179974,41.162662],[-92.15441,41.126824]]]},"properties":{"id":"52580","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.179974,41.162662],[-92.180453,41.240476],[-92.325904,41.264915],[-92.410775,41.267301],[-92.410233,41.161942],[-92.227445,41.061942],[-92.15441,41.126824],[-92.179974,41.162662]]]},"properties":{"id":"52563","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.71545,41.745774],[-91.646769,41.69418],[-91.642231,41.72334],[-91.71545,41.745774]]]},"properties":{"id":"52340","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.557229,41.685677],[-91.530163,41.661254],[-91.539019,41.652776],[-91.609187,41.670149],[-91.642231,41.72334],[-91.646769,41.69418],[-91.711048,41.608508],[-91.642851,41.565283],[-91.54155,41.558296],[-91.528733,41.565993],[-91.519356,41.554782],[-91.519828,41.51101],[-91.366876,41.553942],[-91.366448,41.598373],[-91.365925,41.768958],[-91.529394,41.724872],[-91.577506,41.716727],[-91.557229,41.685677]]]},"properties":{"id":"52240","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.559267,41.666382],[-91.533718,41.671517],[-91.559269,41.668016],[-91.559267,41.666382]]],[[[-91.568602,41.66727],[-91.609187,41.670149],[-91.539019,41.652776],[-91.568602,41.66727]]]]},"properties":{"id":"52246","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.568602,41.66727],[-91.539019,41.652776],[-91.530163,41.661254],[-91.533718,41.671517],[-91.559267,41.666382],[-91.568602,41.66727]]]},"properties":{"id":"52242","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.156622,41.510156],[-92.131822,41.424103],[-92.024535,41.410193],[-91.96389,41.49373],[-92.156622,41.510156]]]},"properties":{"id":"52335","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.364213,43.500265],[-94.364363,43.327305],[-94.167937,43.371205],[-94.168179,43.385646],[-94.251081,43.50012],[-94.364213,43.500265]]]},"properties":{"id":"50590","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.718455,40.74057],[-91.824199,40.610304],[-91.622986,40.54272],[-91.601255,40.574755],[-91.660506,40.755483],[-91.718455,40.74057]]]},"properties":{"id":"52626","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.43242,42.242394],[-91.432397,42.230953],[-91.417797,42.238394],[-91.43242,42.242394]]]},"properties":{"id":"52219","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.521089,42.129952],[-91.482056,42.003045],[-91.419001,42.007633],[-91.36478,42.070529],[-91.364636,42.112466],[-91.521089,42.129952]]]},"properties":{"id":"52336","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.68502,42.05874],[-91.667937,42.055073],[-91.643445,42.06065],[-91.677592,42.089277],[-91.68502,42.05874]]]},"properties":{"id":"52328","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.182222,41.276424],[-91.196972,41.269238],[-91.192189,41.269225],[-91.182222,41.276424]]]},"properties":{"id":"52752","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.949459,43.257777],[-96.018824,43.127993],[-95.998205,43.09875],[-95.781948,43.098737],[-95.749394,43.17162],[-95.743376,43.258141],[-95.937312,43.272371],[-95.949459,43.257777]],[[-95.935086,43.240721],[-95.94176,43.248096],[-95.927003,43.247772],[-95.935086,43.240721]]]},"properties":{"id":"51201","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.247892,43.2576],[-96.21545,43.142023],[-96.09729,43.12814],[-96.076502,43.13557],[-96.059402,43.258324],[-96.097886,43.279499],[-96.247892,43.2576]]]},"properties":{"id":"51239","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.812617,41.21194],[-92.573448,41.197629],[-92.492393,41.264049],[-92.467199,41.299068],[-92.555281,41.321856],[-92.717718,41.379073],[-92.831852,41.271736],[-92.851163,41.24095],[-92.812617,41.21194]],[[-92.677737,41.28021],[-92.678419,41.263577],[-92.68381,41.26356],[-92.68637,41.278039],[-92.677737,41.28021]],[[-92.617368,41.283885],[-92.622178,41.292527],[-92.608065,41.293923],[-92.607997,41.285412],[-92.617368,41.283885]]]},"properties":{"id":"52577","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.677737,41.28021],[-92.68637,41.278039],[-92.68381,41.26356],[-92.678419,41.263577],[-92.677737,41.28021]]]},"properties":{"id":"52534","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.158199,41.433357],[-93.309379,41.286405],[-93.280675,41.280736],[-93.175741,41.200951],[-93.056672,41.169552],[-92.985019,41.196293],[-92.99459,41.238636],[-92.981128,41.284494],[-92.970914,41.36078],[-93.082517,41.408693],[-93.131866,41.433028],[-93.158199,41.433357]]]},"properties":{"id":"50138","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.118458,40.900957],[-95.079452,41.059693],[-95.156199,40.901035],[-95.118458,40.900957]]]},"properties":{"id":"51573","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.525058,43.314161],[-95.584749,43.373705],[-95.683861,43.316388],[-95.703846,43.287423],[-95.565225,43.257067],[-95.525058,43.314161]]]},"properties":{"id":"51350","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.354607,40.640592],[-95.373864,40.597085],[-95.373923,40.580503],[-95.302554,40.57956],[-95.256074,40.610912],[-95.354607,40.640592]]]},"properties":{"id":"51647","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.698319,43.009649],[-94.705152,42.908953],[-94.639488,42.878525],[-94.629452,42.878535],[-94.619872,42.881952],[-94.560435,42.937812],[-94.560542,43.018241],[-94.57536,43.017898],[-94.698319,43.009649]]]},"properties":{"id":"50562","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.449731,42.627616],[-96.219457,42.561804],[-96.174336,42.561425],[-96.095714,42.649013],[-96.193563,42.649179],[-96.44942,42.664152],[-96.449688,42.649381],[-96.449731,42.627616]]]},"properties":{"id":"51024","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.60064,41.680414],[-93.574011,41.651092],[-93.546065,41.666772],[-93.535601,41.670454],[-93.529534,41.673095],[-93.522468,41.688399],[-93.530444,41.73185],[-93.581006,41.79138],[-93.600513,41.776767],[-93.60064,41.680414]]]},"properties":{"id":"50021","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.581006,41.79138],[-93.580872,41.82064],[-93.600282,41.829423],[-93.619946,41.791325],[-93.600513,41.776767],[-93.581006,41.79138]]]},"properties":{"id":"50007","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.642131,41.538117],[-92.555948,41.50915],[-92.450548,41.509437],[-92.435523,41.674041],[-92.499916,41.674102],[-92.629638,41.666897],[-92.629747,41.610075],[-92.642131,41.538117]]]},"properties":{"id":"50171","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.223707,40.854787],[-94.12883,40.782559],[-94.109525,40.796921],[-94.133138,40.897561],[-94.223707,40.854787]]]},"properties":{"id":"50863","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.245,42.429092],[-95.148623,42.369567],[-95.108978,42.385911],[-95.089204,42.474347],[-95.170567,42.547184],[-95.245,42.429092]]]},"properties":{"id":"50535","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.994343,42.238875],[-95.130521,42.326068],[-95.187661,42.210791],[-95.168398,42.210827],[-94.994343,42.238875]]]},"properties":{"id":"51466","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.432405,41.739425],[-90.471235,41.698962],[-90.432539,41.655856],[-90.339859,41.656599],[-90.316562,41.728928],[-90.433093,41.755892],[-90.432405,41.739425]]]},"properties":{"id":"52768","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.434205,41.756867],[-90.432405,41.739425],[-90.433093,41.755892],[-90.434205,41.756867]]]},"properties":{"id":"52758","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.271722,41.761103],[-95.102648,41.68815],[-95.093161,41.688352],[-95.093265,41.731627],[-95.271722,41.761103]]]},"properties":{"id":"51447","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.076502,43.13557],[-96.018824,43.127993],[-95.949459,43.257777],[-96.059402,43.258324],[-96.076502,43.13557]]]},"properties":{"id":"51234","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.21545,43.142023],[-96.372155,43.113263],[-96.3324,43.069697],[-96.263594,43.040832],[-96.159803,43.011635],[-96.09729,43.12814],[-96.21545,43.142023]]]},"properties":{"id":"51250","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.935086,43.240721],[-95.927003,43.247772],[-95.94176,43.248096],[-95.935086,43.240721]]]},"properties":{"id":"51244","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.659401,42.077912],[-93.620397,42.122822],[-93.659369,42.137519],[-93.659401,42.077912]]]},"properties":{"id":"50105","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.794957,40.741491],[-94.85266,40.827955],[-94.919477,40.84279],[-94.914732,40.635121],[-94.914742,40.631095],[-94.794957,40.741491]]]},"properties":{"id":"51646","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.024965,40.667137],[-91.969984,40.605125],[-91.852849,40.608811],[-91.87464,40.660359],[-92.024965,40.667137]]]},"properties":{"id":"52573","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.526927,41.089938],[-92.535137,41.089615],[-92.534784,41.082432],[-92.5252,41.086003],[-92.526927,41.089938]]]},"properties":{"id":"52548","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.259996,40.85153],[-93.301387,40.849455],[-93.288565,40.833487],[-93.263294,40.843009],[-93.259996,40.85153]]]},"properties":{"id":"50165","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.397092,42.396004],[-94.393013,42.354219],[-94.222926,42.325368],[-94.24265,42.412732],[-94.397092,42.396004]]]},"properties":{"id":"50523","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.11555,42.353987],[-94.115257,42.339496],[-94.084883,42.340202],[-94.09866,42.354322],[-94.11555,42.353987]]]},"properties":{"id":"50521","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.374585,42.493273],[-96.407938,42.500157],[-96.390971,42.483345],[-96.374585,42.493273]]]},"properties":{"id":"51101","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.38066,42.446768],[-96.411124,42.38606],[-96.36519,42.420368],[-96.38066,42.446768]]]},"properties":{"id":"51111","state_code":"19"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.306327,37.79103],[-95.324725,37.732925],[-95.306441,37.732762],[-95.19736,37.732539],[-95.088082,37.732476],[-95.087839,37.761405],[-95.087883,37.774356],[-95.306327,37.79103]]]},"properties":{"id":"66772","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.061467,37.834311],[-94.942944,37.986548],[-94.98798,38.022987],[-95.077876,38.037706],[-95.099101,38.037758],[-95.061467,37.834311]]]},"properties":{"id":"66716","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.194784,38.390198],[-95.065128,38.302168],[-94.973491,38.389308],[-95.010464,38.389457],[-95.157164,38.433632],[-95.194784,38.390198]]]},"properties":{"id":"66033","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.43376,39.754644],[-95.470864,39.696331],[-95.405288,39.594749],[-95.377149,39.565668],[-95.339692,39.696647],[-95.339641,39.740285],[-95.43376,39.754644]]]},"properties":{"id":"66424","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.922424,38.435879],[-99.03276,38.362858],[-98.857816,38.23261],[-98.692822,38.202335],[-98.692697,38.231566],[-98.631441,38.44077],[-98.646227,38.448721],[-98.848561,38.477822],[-98.922424,38.435879]]]},"properties":{"id":"67530","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.61454,37.978171],[-94.805229,37.99382],[-94.860243,37.950315],[-94.955853,37.761683],[-94.953928,37.746952],[-94.869146,37.659854],[-94.795125,37.659484],[-94.667407,37.687822],[-94.617645,37.761243],[-94.61454,37.978171]]]},"properties":{"id":"66701","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.639133,39.812277],[-95.75015,39.725736],[-95.750437,39.696603],[-95.676363,39.683177],[-95.620155,39.725367],[-95.639133,39.812277]]]},"properties":{"id":"66527","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.042196,37.868769],[-97.061991,37.752429],[-97.053154,37.743262],[-96.998699,37.745334],[-96.968833,37.886248],[-97.042196,37.868769]]]},"properties":{"id":"67144","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.525557,37.705306],[-96.580465,37.650537],[-96.525966,37.654951],[-96.525557,37.705306]]]},"properties":{"id":"67012","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.819458,37.385757],[-95.961605,37.386636],[-96.091526,37.248856],[-96.017186,37.179397],[-95.927907,37.197703],[-95.778969,37.349165],[-95.819458,37.385757]]]},"properties":{"id":"67344","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.796127,37.351482],[-94.905848,37.412156],[-94.904824,37.368715],[-94.904863,37.296266],[-94.85049,37.298448],[-94.796127,37.351482]]]},"properties":{"id":"66724","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.846772,36.99882],[-94.83537,36.99877],[-94.834655,37.002146],[-94.846526,37.002141],[-94.846772,36.99882]]]},"properties":{"id":"66778","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.074164,37.164409],[-95.041312,37.259524],[-95.108642,37.304523],[-95.249468,37.207869],[-95.268167,37.106054],[-95.249123,37.102181],[-95.211803,37.096141],[-95.074164,37.164409]]]},"properties":{"id":"67356","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.089886,37.065694],[-100.035812,37.079294],[-100.091663,37.182842],[-100.089886,37.065694]]]},"properties":{"id":"67840","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.051287,39.526736],[-96.941982,39.394057],[-96.913478,39.484593],[-96.902678,39.536587],[-96.93182,39.566439],[-97.033151,39.566532],[-97.051287,39.526736]]]},"properties":{"id":"67447","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.666509,39.37936],[-97.816318,39.481538],[-97.891608,39.48116],[-97.93084,39.233261],[-97.901379,39.233799],[-97.666194,39.364842],[-97.666509,39.37936]]]},"properties":{"id":"67445","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.610492,39.364741],[-97.462883,39.422692],[-97.462727,39.524321],[-97.666509,39.37936],[-97.666194,39.364842],[-97.610492,39.364741]]]},"properties":{"id":"67417","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.105699,37.303097],[-97.097373,37.157732],[-96.866564,37.127525],[-96.815944,37.186259],[-96.861076,37.389078],[-97.031546,37.390358],[-97.105699,37.303097]]]},"properties":{"id":"67156","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.893495,38.566183],[-96.80069,38.60954],[-96.708024,38.681761],[-96.927225,38.78314],[-97.001632,38.754098],[-97.022483,38.628957],[-96.985593,38.595058],[-96.893495,38.566183]]]},"properties":{"id":"67449","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.246304,39.827658],[-95.26518,39.827685],[-95.26511,39.805801],[-95.246304,39.827658]]],[[[-95.246304,39.827658],[-95.15319,39.906841],[-95.1997,39.903342],[-95.339735,39.92506],[-95.283943,39.842106],[-95.246304,39.827658]]]]},"properties":{"id":"66035","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.148844,38.97434],[-95.260489,38.949966],[-95.288123,38.860123],[-95.149078,38.877114],[-95.148844,38.97434]]]},"properties":{"id":"66046","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.857512,37.825277],[-98.91287,38.101915],[-99.022136,38.000236],[-99.022488,37.8544],[-98.995863,37.732866],[-98.857512,37.825277]]]},"properties":{"id":"67557","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.525518,37.563654],[-96.344335,37.60674],[-96.327799,37.664722],[-96.525308,37.73765],[-96.525557,37.705306],[-96.525966,37.654951],[-96.525518,37.563654]]]},"properties":{"id":"67122","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.039447,38.856109],[-99.041987,38.729674],[-99.0385,39.016173],[-99.102842,39.057005],[-99.159083,39.026643],[-99.087747,38.864392],[-99.076851,38.885792],[-99.039782,38.876299],[-99.039447,38.856109]]]},"properties":{"id":"67640","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.31854,38.882876],[-98.369513,38.697078],[-98.257082,38.580329],[-98.16475,38.609368],[-98.09515,38.755003],[-98.061256,38.870952],[-98.281484,38.882925],[-98.31854,38.882876]]]},"properties":{"id":"67439","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.31854,38.882876],[-98.484805,38.882612],[-98.578733,38.696845],[-98.57206,38.696833],[-98.479958,38.696916],[-98.424701,38.681467],[-98.369513,38.697078],[-98.31854,38.882876]]]},"properties":{"id":"67490","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.595054,38.967079],[-96.538395,38.78287],[-96.348806,38.738933],[-96.279683,38.738949],[-96.298934,38.826155],[-96.501214,39.014897],[-96.602429,39.010722],[-96.595054,38.967079]]]},"properties":{"id":"66834","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.537572,39.003214],[-100.597282,38.700087],[-100.541184,38.699991],[-100.329494,38.716677],[-100.318333,38.786713],[-100.37169,38.97953],[-100.408604,39.003034],[-100.537572,39.003214]]]},"properties":{"id":"67736","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.15176,38.842325],[-100.318333,38.786713],[-100.329494,38.716677],[-100.286353,38.552825],[-100.102149,38.508478],[-100.085265,38.544512],[-100.010473,38.652488],[-100.022565,38.798059],[-100.15176,38.842325]]]},"properties":{"id":"67584","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.956635,39.480195],[-99.959232,39.189989],[-99.791798,39.175972],[-99.773008,39.183168],[-99.74408,39.248724],[-99.69563,39.545437],[-99.732178,39.538977],[-99.956635,39.480195]]]},"properties":{"id":"67642","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.08929,39.178117],[-100.164044,39.189992],[-100.15176,38.842325],[-100.022565,38.798059],[-100.022066,38.827159],[-100.03336,39.160807],[-100.08929,39.178117]]]},"properties":{"id":"67631","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.761343,37.736603],[-100.742358,37.387596],[-100.543154,37.44563],[-100.524843,37.708006],[-100.652251,37.736275],[-100.725198,37.736481],[-100.761343,37.736603]]]},"properties":{"id":"67837","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.44262,37.999389],[-97.610517,37.955991],[-97.592143,37.866861],[-97.518834,37.842594],[-97.263221,37.926883],[-97.44262,37.999389]],[[-97.518909,37.882329],[-97.518049,37.891145],[-97.509568,37.890632],[-97.509711,37.883329],[-97.518909,37.882329]]]},"properties":{"id":"67135","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.427057,38.101106],[-97.500424,38.1159],[-97.533254,38.115906],[-97.610517,37.955991],[-97.44262,37.999389],[-97.427057,38.101106]]]},"properties":{"id":"67056","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.910244,37.736771],[-100.887032,37.315202],[-100.742556,37.373112],[-100.742358,37.387596],[-100.761343,37.736603],[-100.910244,37.736771]]]},"properties":{"id":"67877","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.227137,38.262237],[-100.22709,37.914018],[-99.991454,37.913078],[-99.873763,37.913214],[-99.7343,38.014806],[-99.808689,38.26186],[-100.227137,38.262237]]]},"properties":{"id":"67854","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.808689,38.26186],[-99.7343,38.014806],[-99.661569,38.014663],[-99.642991,38.116492],[-99.642634,38.261898],[-99.749794,38.261735],[-99.808689,38.26186]]]},"properties":{"id":"67849","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.490398,39.129387],[-95.41851,39.134208],[-95.413887,39.273848],[-95.517698,39.252427],[-95.490398,39.129387]]]},"properties":{"id":"66070","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.931921,39.697212],[-97.988082,39.913909],[-98.043206,39.888502],[-98.082666,39.755338],[-98.06283,39.697166],[-97.931884,39.691245],[-97.931921,39.697212]]]},"properties":{"id":"66942","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.797477,38.94162],[-94.769814,38.914798],[-94.764142,38.920065],[-94.761094,38.985592],[-94.761096,38.985982],[-94.797298,38.974488],[-94.797477,38.94162]]]},"properties":{"id":"66219","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.758716,39.043693],[-94.761096,38.985982],[-94.761094,38.985592],[-94.723669,38.985599],[-94.718682,38.99287],[-94.723892,39.043998],[-94.758716,39.043693]]]},"properties":{"id":"66216","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.908991,38.828238],[-94.893486,38.854566],[-94.880663,38.854586],[-94.888744,38.815224],[-94.871376,38.816773],[-94.769814,38.914798],[-94.797477,38.94162],[-94.852979,38.941395],[-94.908739,38.952744],[-94.982665,38.956766],[-94.993633,38.876936],[-94.908991,38.828238]]]},"properties":{"id":"66061","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.844088,38.981676],[-94.841943,38.999086],[-94.9088,38.989279],[-94.908739,38.952744],[-94.852979,38.941395],[-94.844088,38.981676]]]},"properties":{"id":"66227","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.649003,39.014893],[-94.649141,39.044029],[-94.658844,39.044038],[-94.693077,39.007633],[-94.649064,39.007624],[-94.649003,39.014893]]]},"properties":{"id":"66202","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.742196,38.792868],[-94.861842,38.78186],[-94.956814,38.73851],[-94.964042,38.702009],[-94.759978,38.679788],[-94.740871,38.687127],[-94.686351,38.781798],[-94.686348,38.800336],[-94.742196,38.792868]]]},"properties":{"id":"66083","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.630319,38.978704],[-94.630234,38.932806],[-94.607959,38.936854],[-94.608437,38.978724],[-94.630319,38.978704]]]},"properties":{"id":"66206","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.649189,38.84011],[-94.64922,38.883736],[-94.667802,38.886675],[-94.686496,38.886506],[-94.686332,38.84002],[-94.649189,38.84011]]]},"properties":{"id":"66223","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.001607,38.94214],[-95.001622,38.948414],[-95.004997,38.947879],[-95.001881,38.945424],[-95.003629,38.942132],[-95.001607,38.94214]]]},"properties":{"id":"66019","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.789706,37.561776],[-97.71644,37.529957],[-97.669872,37.590419],[-97.735469,37.73413],[-97.807823,37.733855],[-97.899659,37.704791],[-97.880231,37.604504],[-97.789706,37.561776]]]},"properties":{"id":"67025","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.502909,37.369372],[-95.420787,37.295555],[-95.394925,37.310646],[-95.412423,37.383947],[-95.434447,37.383906],[-95.502909,37.369372]]]},"properties":{"id":"67341","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.9382,39.387113],[-94.909881,39.342729],[-94.879088,39.375703],[-94.9382,39.387113]]]},"properties":{"id":"66027","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.877033,38.498906],[-95.750414,38.515136],[-95.683224,38.675721],[-95.780538,38.702259],[-95.986466,38.695322],[-95.877033,38.498906]]]},"properties":{"id":"66523","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.647449,38.232065],[-97.832899,38.304195],[-97.943486,38.275038],[-98.017286,38.246249],[-97.977081,38.201554],[-97.841177,38.153255],[-97.684122,38.173936],[-97.647449,38.232065]]]},"properties":{"id":"67546","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.924224,38.515307],[-97.943486,38.275038],[-97.832899,38.304195],[-97.869233,38.464242],[-97.924224,38.515307]]]},"properties":{"id":"67491","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.353165,38.275336],[-97.24357,38.232862],[-97.150703,38.260867],[-97.166516,38.447375],[-97.298092,38.435006],[-97.353248,38.333397],[-97.353165,38.275336]]]},"properties":{"id":"67063","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.353248,38.333397],[-97.298092,38.435006],[-97.37188,38.435041],[-97.353248,38.333397]]]},"properties":{"id":"67073","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.339514,38.246681],[-97.338644,38.24979],[-97.353254,38.249173],[-97.348873,38.244497],[-97.339514,38.246681]]]},"properties":{"id":"67053","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.351264,39.899927],[-96.370048,39.841895],[-96.344559,39.791015],[-96.239595,39.805492],[-96.201525,40.000852],[-96.314591,40.000901],[-96.351264,39.899927]]]},"properties":{"id":"66403","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.638588,40.001305],[-96.48254,39.942991],[-96.463712,40.001023],[-96.638588,40.001305]]]},"properties":{"id":"66518","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.738423,37.349079],[-95.72452,37.327121],[-95.710899,37.337474],[-95.738423,37.349079]]]},"properties":{"id":"67363","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.719818,37.500919],[-95.819458,37.385757],[-95.778969,37.349165],[-95.738423,37.349079],[-95.710899,37.337474],[-95.656544,37.348148],[-95.562007,37.383947],[-95.597206,37.476999],[-95.719818,37.500919]]]},"properties":{"id":"66757","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.351092,39.566154],[-96.293091,39.336971],[-96.204368,39.347709],[-96.184002,39.344001],[-96.109337,39.390885],[-96.08909,39.566124],[-96.089072,39.595141],[-96.10781,39.595176],[-96.239195,39.609702],[-96.351092,39.566154]]]},"properties":{"id":"66521","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.807658,38.552513],[-99.7889,38.440673],[-99.660266,38.523984],[-99.585087,38.696537],[-99.769298,38.739962],[-99.807658,38.552513]]]},"properties":{"id":"67521","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.085265,38.544512],[-100.102149,38.508478],[-100.244425,38.291356],[-100.244393,38.26229],[-100.227137,38.262237],[-99.808689,38.26186],[-99.749794,38.261735],[-99.7889,38.440673],[-99.807658,38.552513],[-100.029385,38.580222],[-100.085265,38.544512]]]},"properties":{"id":"67560","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.461435,40.002072],[-99.644195,40.001821],[-99.627153,39.87206],[-99.459252,39.871758],[-99.461435,40.002072]]]},"properties":{"id":"67647","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.419488,38.480516],[-99.380321,38.3494],[-99.253237,38.30482],[-99.124542,38.348584],[-99.088189,38.377458],[-99.125406,38.493744],[-99.253508,38.494315],[-99.419488,38.480516]]]},"properties":{"id":"67575","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.383912,39.567397],[-99.265502,39.567412],[-99.240811,39.683674],[-99.383912,39.567397]]]},"properties":{"id":"67639","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.557578,39.480768],[-96.51985,39.392135],[-96.519789,39.319898],[-96.390072,39.304542],[-96.293091,39.336971],[-96.351092,39.566154],[-96.351471,39.56616],[-96.557578,39.480768]]]},"properties":{"id":"66549","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-101.298263,39.178887],[-101.204952,39.235973],[-101.242122,39.279846],[-101.298263,39.178887]]],[[[-101.300911,39.597383],[-101.241957,39.308727],[-101.22605,39.62635],[-101.300911,39.597383]]]]},"properties":{"id":"67743","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.300911,39.597383],[-101.413913,39.568361],[-101.510602,39.568373],[-101.391717,39.135117],[-101.349409,39.135309],[-101.298263,39.178887],[-101.242122,39.279846],[-101.241957,39.308727],[-101.300911,39.597383]]]},"properties":{"id":"67732","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.032571,38.085936],[-97.811695,38.086447],[-97.841177,38.153255],[-97.977081,38.201554],[-98.004995,38.17324],[-98.032571,38.085936]]]},"properties":{"id":"67502","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.381197,38.086033],[-98.252119,38.071777],[-98.179067,38.072062],[-98.004995,38.17324],[-97.977081,38.201554],[-98.017286,38.246249],[-98.03396,38.26058],[-98.257218,38.275208],[-98.417774,38.173116],[-98.472794,38.172757],[-98.47267,38.121977],[-98.381197,38.086033]]]},"properties":{"id":"67579","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.517078,37.721921],[-97.590709,37.721548],[-97.625592,37.721374],[-97.634994,37.590794],[-97.534206,37.591667],[-97.516891,37.621412],[-97.516708,37.649769],[-97.517078,37.721921]]]},"properties":{"id":"67052","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.406217,37.520276],[-97.42554,37.578494],[-97.480261,37.615],[-97.516891,37.621412],[-97.534206,37.591667],[-97.587794,37.475166],[-97.494542,37.397747],[-97.44679,37.43327],[-97.406217,37.520276]]]},"properties":{"id":"67026","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.189654,37.621182],[-97.153043,37.621215],[-97.153048,37.635768],[-97.152964,37.650313],[-97.175512,37.650224],[-97.189654,37.621182]]]},"properties":{"id":"67232","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.761748,39.050843],[-95.687198,39.04892],[-95.685962,39.051806],[-95.678427,39.067006],[-95.751361,39.070699],[-95.761748,39.050843]]]},"properties":{"id":"66606","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.711936,39.000622],[-95.71564,38.967179],[-95.654139,38.957096],[-95.614394,38.986112],[-95.683789,39.00063],[-95.711453,39.000566],[-95.711936,39.000622]]]},"properties":{"id":"66609","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.51324,39.134655],[-101.576274,39.568455],[-101.638647,39.568459],[-101.947282,39.568327],[-101.969452,39.1332],[-101.922958,39.133245],[-101.682043,39.133837],[-101.51324,39.134655]]]},"properties":{"id":"67735","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.841965,39.698152],[-98.935123,39.683826],[-98.916443,39.567986],[-98.85903,39.567948],[-98.761153,39.567636],[-98.841965,39.698152]]]},"properties":{"id":"67638","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.492294,37.113673],[-97.465239,36.998685],[-97.237667,36.998917],[-97.256682,37.144695],[-97.275633,37.188539],[-97.492294,37.113673]]]},"properties":{"id":"67140","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.131933,38.978895],[-96.298934,38.826155],[-96.279683,38.738949],[-96.131137,38.739069],[-95.965513,38.906451],[-96.085712,38.986425],[-96.131933,38.978895]]]},"properties":{"id":"66423","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.049561,39.740672],[-97.068454,39.726144],[-97.049668,39.726124],[-97.049561,39.740672]]],[[[-97.049561,39.740672],[-97.030869,39.731306],[-97.050111,39.58106],[-97.033151,39.566532],[-96.93182,39.566439],[-96.937705,39.783728],[-97.049561,39.740672]]]]},"properties":{"id":"66943","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.238371,39.929825],[-97.256163,39.755595],[-97.218389,39.755636],[-97.107126,39.944338],[-97.10515,40.001725],[-97.238159,40.001387],[-97.238371,39.929825]]]},"properties":{"id":"66958","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.614863,37.733046],[-95.779203,37.733804],[-95.77106,37.646626],[-95.632856,37.652992],[-95.614576,37.653597],[-95.614863,37.733046]]]},"properties":{"id":"66717","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.633196,39.14562],[-94.616514,39.113764],[-94.607354,39.113444],[-94.588658,39.151163],[-94.650647,39.154092],[-94.633196,39.14562]]]},"properties":{"id":"66115","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.280854,38.038026],[-95.281068,37.82009],[-95.087883,37.774356],[-95.087839,37.761405],[-95.069567,37.761421],[-95.061467,37.834311],[-95.099101,38.037758],[-95.280854,38.038026]]]},"properties":{"id":"66755","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.213771,38.168648],[-95.280749,38.103528],[-95.280854,38.038026],[-95.099101,38.037758],[-95.077876,38.037706],[-95.076751,38.182665],[-95.213771,38.168648]]]},"properties":{"id":"66039","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.235245,39.542159],[-95.281946,39.503751],[-95.300785,39.484744],[-95.254153,39.419362],[-95.235245,39.542159]]]},"properties":{"id":"66016","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.676393,39.870124],[-95.673002,40.000338],[-95.74898,40.000457],[-95.732948,39.87032],[-95.676393,39.870124]]]},"properties":{"id":"66515","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.152612,37.928794],[-97.078689,37.898046],[-97.018252,38.019332],[-97.013756,38.028427],[-97.219055,37.933848],[-97.152612,37.928794]]]},"properties":{"id":"67154","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.620735,37.955765],[-96.661152,37.751962],[-96.525925,37.792368],[-96.523853,37.970642],[-96.620735,37.955765]]]},"properties":{"id":"67132","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.716045,37.577533],[-96.641266,37.431988],[-96.52569,37.476405],[-96.525518,37.563654],[-96.525966,37.654951],[-96.580465,37.650537],[-96.716045,37.577533]]]},"properties":{"id":"67072","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.597554,38.521974],[-96.409993,38.518227],[-96.35378,38.521657],[-96.260889,38.608134],[-96.348806,38.738933],[-96.538395,38.78287],[-96.650086,38.812226],[-96.649418,38.710442],[-96.597554,38.521974]]]},"properties":{"id":"66846","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.33144,39.429829],[-97.445479,39.233659],[-97.427031,39.175472],[-97.371154,39.131961],[-97.333806,39.132273],[-97.185224,39.205853],[-97.001062,39.252089],[-96.96091,39.30676],[-96.960875,39.335814],[-96.941982,39.394057],[-97.051287,39.526736],[-97.126195,39.539212],[-97.1453,39.524303],[-97.33144,39.429829]]]},"properties":{"id":"67432","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.1453,39.524303],[-97.368814,39.45691],[-97.368947,39.422192],[-97.33144,39.429829],[-97.1453,39.524303]]]},"properties":{"id":"67468","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.53732,39.6536],[-97.462727,39.524321],[-97.462883,39.422692],[-97.368947,39.422192],[-97.368814,39.45691],[-97.350527,39.755647],[-97.368561,39.755525],[-97.368562,39.741006],[-97.53732,39.6536]]]},"properties":{"id":"66938","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.030825,38.055441],[-95.958565,37.902103],[-95.922282,37.93811],[-95.738904,38.039136],[-95.757555,38.115758],[-95.922026,38.170641],[-95.977347,38.171109],[-96.030825,38.055441]]]},"properties":{"id":"66852","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.702459,38.039342],[-95.518798,38.068053],[-95.518549,38.096367],[-95.610224,38.176478],[-95.757555,38.115758],[-95.738904,38.039136],[-95.702459,38.039342]]]},"properties":{"id":"66857","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.079122,38.521081],[-95.987199,38.405055],[-95.950498,38.392113],[-95.877033,38.498906],[-95.986466,38.695322],[-96.022597,38.709809],[-96.069832,38.651669],[-96.079122,38.521081]]]},"properties":{"id":"66868","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.135176,37.475508],[-97.199528,37.412968],[-97.163947,37.317984],[-97.105699,37.303097],[-97.031546,37.390358],[-97.023012,37.468919],[-97.135176,37.475508]]]},"properties":{"id":"67146","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.256682,37.144695],[-97.140337,37.143449],[-97.097373,37.157732],[-97.105699,37.303097],[-97.163947,37.317984],[-97.240897,37.304306],[-97.275633,37.188539],[-97.256682,37.144695]]]},"properties":{"id":"67119","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.147721,36.999033],[-97.140337,37.143449],[-97.256682,37.144695],[-97.237667,36.998917],[-97.147721,36.999033]]]},"properties":{"id":"67051","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.273547,39.799081],[-100.180351,39.56689],[-100.161667,39.567277],[-100.112865,39.567186],[-100.063127,39.653805],[-100.133458,39.791638],[-100.273547,39.799081]]]},"properties":{"id":"67629","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.227345,39.754886],[-95.207455,39.674513],[-95.114435,39.714686],[-95.227345,39.754886]]]},"properties":{"id":"66008","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.38206,39.046483],[-95.435797,38.955506],[-95.318378,38.942586],[-95.260466,38.953627],[-95.260475,38.956912],[-95.285425,39.032162],[-95.38206,39.046483]]]},"properties":{"id":"66049","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.318378,38.942586],[-95.43746,38.897398],[-95.381211,38.814603],[-95.288123,38.860123],[-95.260489,38.949966],[-95.260482,38.950837],[-95.260466,38.953627],[-95.318378,38.942586]]]},"properties":{"id":"66047","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.035959,38.974748],[-95.130543,38.968428],[-95.148844,38.97434],[-95.149078,38.877114],[-95.099947,38.807566],[-95.093398,38.782195],[-95.037675,38.840615],[-95.002346,38.872658],[-95.019321,38.942287],[-95.035959,38.974748]]]},"properties":{"id":"66025","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.435797,38.955506],[-95.38206,39.046483],[-95.483495,39.056502],[-95.500254,39.056656],[-95.500759,38.967665],[-95.435168,38.942397],[-95.435797,38.955506]]]},"properties":{"id":"66050","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.51191,39.321015],[-99.534017,39.132504],[-99.426576,39.059378],[-99.222566,39.059341],[-99.149514,39.103965],[-99.157868,39.307098],[-99.157353,39.36503],[-99.492489,39.379455],[-99.51191,39.321015]]]},"properties":{"id":"67663","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.134314,38.740524],[-99.227892,38.696935],[-99.12544,38.696967],[-99.098262,38.696882],[-99.134314,38.740524]]]},"properties":{"id":"67660","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.09515,38.755003],[-98.032998,38.69646],[-97.856148,38.60961],[-97.814864,38.697136],[-97.817316,38.899963],[-97.928681,38.900126],[-98.021459,38.871177],[-98.061256,38.870952],[-98.09515,38.755003]]]},"properties":{"id":"67425","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.46336,38.117191],[-100.4335,37.737254],[-100.34274,37.627414],[-100.21481,37.736989],[-100.22709,37.914018],[-100.227137,38.262237],[-100.244393,38.26229],[-100.464287,38.263111],[-100.46336,38.117191]]]},"properties":{"id":"67835","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.10708,37.562116],[-99.888174,37.580658],[-99.797145,37.680769],[-99.991454,37.913078],[-100.22709,37.914018],[-100.21481,37.736989],[-100.17992,37.562578],[-100.10708,37.562116]],[[-99.941255,37.729578],[-99.938967,37.733867],[-99.934237,37.73255],[-99.938371,37.727026],[-99.941255,37.729578]]]},"properties":{"id":"67801","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.033564,39.18975],[-100.03336,39.160807],[-100.022066,38.827159],[-99.800901,38.797922],[-99.791798,39.175972],[-99.959232,39.189989],[-100.033564,39.18975]]]},"properties":{"id":"67672","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.089785,37.787168],[-101.46655,37.891924],[-101.527063,37.736314],[-101.52581,37.388392],[-101.520244,37.388376],[-101.321445,37.388114],[-101.089653,37.387721],[-101.089667,37.736337],[-101.089785,37.787168]]]},"properties":{"id":"67880","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.043692,37.914004],[-101.963072,37.998152],[-102.044368,38.144372],[-102.043692,37.914004]]]},"properties":{"id":"67836","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.802313,36.998718],[-97.783621,37.110721],[-97.783024,37.168698],[-97.911659,37.124238],[-97.911462,36.998621],[-97.802313,36.998718]]]},"properties":{"id":"67018","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.859194,37.371337],[-97.893872,37.240518],[-97.84794,37.241364],[-97.859194,37.371337]]]},"properties":{"id":"67036","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.747192,37.168941],[-97.603269,37.285558],[-97.585153,37.329283],[-97.76666,37.386433],[-97.807057,37.386293],[-97.859413,37.385958],[-97.859194,37.371337],[-97.84794,37.241364],[-97.783024,37.168698],[-97.783621,37.110721],[-97.747192,37.168941]]]},"properties":{"id":"67004","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.089653,37.387721],[-101.011787,37.315122],[-100.887032,37.315202],[-100.910244,37.736771],[-101.089667,37.736337],[-101.089653,37.387721]]]},"properties":{"id":"67870","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.584794,38.349386],[-99.642634,38.261898],[-99.642991,38.116492],[-99.459884,38.088159],[-99.47362,38.276695],[-99.509851,38.349355],[-99.584794,38.349386]]]},"properties":{"id":"67523","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.62663,39.332254],[-95.598611,39.303171],[-95.589059,39.303141],[-95.570395,39.404568],[-95.636139,39.390139],[-95.62663,39.332254]]]},"properties":{"id":"66419","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.923508,39.492514],[-95.811674,39.56591],[-95.845395,39.580461],[-95.920138,39.580484],[-95.923508,39.492514]]]},"properties":{"id":"66416","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.311193,39.288711],[-95.254686,39.245251],[-95.180142,39.288693],[-95.179928,39.390088],[-95.25393,39.390344],[-95.329211,39.33284],[-95.311193,39.288711]]]},"properties":{"id":"66097","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.723669,38.985599],[-94.723645,38.935082],[-94.704748,38.934949],[-94.705016,38.978376],[-94.705099,38.992965],[-94.718682,38.99287],[-94.723669,38.985599]]]},"properties":{"id":"66214","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.686351,38.781798],[-94.608867,38.752691],[-94.607879,38.839829],[-94.649189,38.84011],[-94.686332,38.84002],[-94.70518,38.840094],[-94.686354,38.803612],[-94.686348,38.800336],[-94.686351,38.781798]]]},"properties":{"id":"66085","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.841943,38.999086],[-94.791527,39.043575],[-94.800928,39.043514],[-94.871745,39.059315],[-94.908765,38.991401],[-94.9088,38.989279],[-94.841943,38.999086]]]},"properties":{"id":"66226","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.649003,39.014893],[-94.607483,39.033869],[-94.607382,39.044086],[-94.643874,39.044038],[-94.649141,39.044029],[-94.649003,39.014893]]]},"properties":{"id":"66205","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.816327,38.985269],[-94.797298,38.974488],[-94.761096,38.985982],[-94.758716,39.043693],[-94.789177,39.043612],[-94.816327,38.985269]]]},"properties":{"id":"66217","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.908991,38.828238],[-94.993633,38.876936],[-95.002346,38.872658],[-95.037675,38.840615],[-94.956814,38.73851],[-94.861842,38.78186],[-94.871376,38.816773],[-94.888744,38.815224],[-94.908991,38.828238]]]},"properties":{"id":"66030","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.118832,37.806037],[-97.972822,37.696901],[-97.899659,37.704791],[-97.807823,37.733855],[-97.807471,37.777791],[-97.866639,37.8685],[-98.050301,37.883126],[-98.118714,37.82486],[-98.118832,37.806037]]]},"properties":{"id":"67570","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.395533,37.134276],[-95.465152,37.134337],[-95.431565,36.999279],[-95.267876,36.999525],[-95.249123,37.102181],[-95.268167,37.106054],[-95.395533,37.134276]]]},"properties":{"id":"67342","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.018169,39.418893],[-95.083279,39.259625],[-95.133702,39.17235],[-94.988329,39.179243],[-94.900478,39.201501],[-94.83623,39.219027],[-94.889103,39.288803],[-94.909881,39.342729],[-94.9382,39.387113],[-94.968973,39.418879],[-95.018169,39.418893]]]},"properties":{"id":"66048","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.131136,39.02271],[-95.130543,38.968428],[-95.035959,38.974748],[-94.927608,39.002784],[-95.001543,39.058278],[-95.131136,39.02271]]]},"properties":{"id":"66052","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.714097,38.462117],[-94.793441,38.440304],[-94.917905,38.336342],[-94.861311,38.26568],[-94.825131,38.265342],[-94.612684,38.29879],[-94.61327,38.446674],[-94.714097,38.462117]]]},"properties":{"id":"66040","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.612684,38.29879],[-94.825131,38.265342],[-94.814756,38.252048],[-94.750518,38.124317],[-94.613798,38.109926],[-94.612684,38.29879]]]},"properties":{"id":"66075","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.899843,38.446958],[-94.941471,38.403693],[-94.917905,38.336342],[-94.793441,38.440304],[-94.899843,38.446958]]]},"properties":{"id":"66026","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.682043,39.133837],[-101.55854,38.699769],[-101.446165,38.758425],[-101.349409,39.135309],[-101.391717,39.135117],[-101.51324,39.134655],[-101.682043,39.133837]]]},"properties":{"id":"67761","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.501391,39.823625],[-96.482306,39.768757],[-96.482546,39.823563],[-96.501391,39.823625]]],[[[-96.501391,39.823625],[-96.482546,39.823563],[-96.463613,39.823477],[-96.482312,39.870435],[-96.501391,39.823625]]]]},"properties":{"id":"66438","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.920138,39.580484],[-95.845395,39.580461],[-95.854656,39.739898],[-95.920443,39.783392],[-96.007536,39.725437],[-96.03295,39.580595],[-95.920138,39.580484]]]},"properties":{"id":"66428","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.010473,38.652488],[-100.029385,38.580222],[-99.807658,38.552513],[-99.769298,38.739962],[-99.782466,38.791291],[-99.800901,38.797922],[-100.022066,38.827159],[-100.022565,38.798059],[-100.010473,38.652488]]]},"properties":{"id":"67572","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.565459,38.646738],[-95.637397,38.664924],[-95.620059,38.622928],[-95.564241,38.623106],[-95.565459,38.646738]]]},"properties":{"id":"66543","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.611929,38.768051],[-95.79781,38.818193],[-95.780538,38.702259],[-95.683224,38.675721],[-95.630673,38.709823],[-95.611929,38.768051]]]},"properties":{"id":"66537","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.649687,38.869861],[-95.674427,38.933811],[-95.73171,38.94248],[-95.835532,38.86926],[-95.649687,38.869861]]]},"properties":{"id":"66546","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.489749,39.37935],[-98.504647,39.683528],[-98.607499,39.639974],[-98.655947,39.509859],[-98.583255,39.329069],[-98.489749,39.37935]]]},"properties":{"id":"67437","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.150458,38.029011],[-99.022136,38.000236],[-98.91287,38.101915],[-98.876327,38.174421],[-99.124542,38.348584],[-99.253237,38.30482],[-99.277481,38.15984],[-99.150458,38.029011]]]},"properties":{"id":"67550","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.242632,39.130119],[-96.204368,39.347709],[-96.293091,39.336971],[-96.390072,39.304542],[-96.394611,39.176831],[-96.372481,39.101616],[-96.242377,39.086674],[-96.242632,39.130119]]]},"properties":{"id":"66547","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.812036,37.967627],[-97.866639,37.8685],[-97.807471,37.777791],[-97.702178,37.907757],[-97.812036,37.967627]]]},"properties":{"id":"67543","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.706989,39.943736],[-97.80098,40.002055],[-97.931825,40.002072],[-97.835858,39.871092],[-97.744659,39.885667],[-97.706989,39.943736]]]},"properties":{"id":"66964","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.257218,38.275208],[-98.03396,38.26058],[-98.071867,38.449256],[-98.312464,38.478358],[-98.376177,38.420209],[-98.330963,38.304173],[-98.330904,38.27518],[-98.257218,38.275208]]]},"properties":{"id":"67554","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.585487,39.20114],[-96.585444,39.186016],[-96.576394,39.189751],[-96.576116,39.204236],[-96.585487,39.20114]]]},"properties":{"id":"66506","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.712839,39.566564],[-96.806544,39.566423],[-96.902678,39.536587],[-96.913478,39.484593],[-96.756519,39.382],[-96.717139,39.40719],[-96.68409,39.478797],[-96.712839,39.566564]]]},"properties":{"id":"66554","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.157353,39.36503],[-99.157868,39.307098],[-99.046672,39.335926],[-99.044398,39.568035],[-99.066203,39.568093],[-99.137946,39.567993],[-99.157353,39.36503]]]},"properties":{"id":"67675","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.32766,38.696831],[-99.309281,38.655135],[-99.284489,38.696861],[-99.32766,38.696831]]]},"properties":{"id":"67553","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.66269,38.958758],[-98.726203,38.740332],[-98.74575,38.696777],[-98.578733,38.696845],[-98.484805,38.882612],[-98.499474,38.957884],[-98.66269,38.958758]]]},"properties":{"id":"67634","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.192651,38.700753],[-101.2619,38.70149],[-101.127611,38.409671],[-101.192651,38.700753]]]},"properties":{"id":"67863","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.735469,37.73413],[-97.669872,37.590419],[-97.634994,37.590794],[-97.625592,37.721374],[-97.680929,37.74963],[-97.735469,37.73413]]]},"properties":{"id":"67050","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.28829,37.520626],[-97.153108,37.504717],[-97.153043,37.621215],[-97.189654,37.621182],[-97.286348,37.59347],[-97.307035,37.56439],[-97.28829,37.520626]]]},"properties":{"id":"67037","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.480666,37.684008],[-97.480854,37.65015],[-97.398856,37.646546],[-97.389135,37.651636],[-97.390576,37.683886],[-97.480666,37.684008]]]},"properties":{"id":"67209","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.189834,37.79613],[-97.208313,37.79607],[-97.208158,37.781527],[-97.189791,37.786269],[-97.189834,37.79613]]]},"properties":{"id":"67055","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.711936,39.000622],[-95.946709,38.993617],[-95.946868,38.928445],[-95.743476,38.95659],[-95.71564,38.967179],[-95.711936,39.000622]]]},"properties":{"id":"66610","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.769203,39.029062],[-95.724931,39.029489],[-95.719913,39.028342],[-95.719616,39.023833],[-95.724972,39.024061],[-95.712879,39.016704],[-95.706225,39.029708],[-95.70615,39.036976],[-95.69686,39.036932],[-95.696898,39.029702],[-95.687593,39.029702],[-95.687198,39.04892],[-95.761748,39.050843],[-95.769203,39.029062]]]},"properties":{"id":"66604","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.391321,37.390836],[-97.240897,37.304306],[-97.163947,37.317984],[-97.199528,37.412968],[-97.313579,37.450157],[-97.391321,37.390836]]]},"properties":{"id":"67013","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.218389,39.755636],[-97.256163,39.755595],[-97.256148,39.741066],[-97.218948,39.668228],[-97.050111,39.58106],[-97.030869,39.731306],[-97.049668,39.726124],[-97.068454,39.726144],[-97.218389,39.755636]]]},"properties":{"id":"66953","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.369199,40.001966],[-97.369215,39.929401],[-97.238371,39.929825],[-97.238159,40.001387],[-97.369199,40.001966]]]},"properties":{"id":"66955","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.628762,37.980506],[-95.555639,37.834645],[-95.519224,37.863755],[-95.519163,37.979036],[-95.628762,37.980506]]]},"properties":{"id":"66761","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.63512,39.122364],[-94.635334,39.091994],[-94.622959,39.093407],[-94.616514,39.113764],[-94.633196,39.14562],[-94.63512,39.122364]]]},"properties":{"id":"66101","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.394705,39.427673],[-95.329211,39.33284],[-95.25393,39.390344],[-95.254153,39.419362],[-95.300785,39.484744],[-95.394705,39.427673]]]},"properties":{"id":"66060","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.207455,39.674513],[-95.265045,39.652959],[-95.235245,39.542159],[-95.254153,39.419362],[-95.25393,39.390344],[-95.179928,39.390088],[-95.018169,39.418893],[-94.968973,39.418879],[-95.021522,39.67065],[-95.041055,39.679417],[-95.114435,39.714686],[-95.207455,39.674513]]]},"properties":{"id":"66002","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.377149,39.565668],[-95.281946,39.503751],[-95.235245,39.542159],[-95.265045,39.652959],[-95.339692,39.696647],[-95.377149,39.565668]]]},"properties":{"id":"66041","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.348436,37.18154],[-98.495068,37.166829],[-98.363776,36.998034],[-98.237211,36.997965],[-98.23726,37.03589],[-98.274841,37.094315],[-98.348436,37.18154]]]},"properties":{"id":"67061","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.014609,38.478474],[-98.959228,38.652782],[-99.042626,38.696807],[-99.098262,38.696882],[-99.12544,38.696967],[-99.088491,38.493554],[-99.014609,38.478474]]]},"properties":{"id":"67565","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.955853,37.761683],[-94.906125,37.950342],[-94.942944,37.986548],[-95.061467,37.834311],[-95.069567,37.761421],[-95.019953,37.732611],[-94.953928,37.746952],[-94.955853,37.761683]]]},"properties":{"id":"66779","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.942327,37.601838],[-95.019953,37.732611],[-95.069567,37.761421],[-95.087839,37.761405],[-95.088082,37.732476],[-95.143091,37.660144],[-95.160874,37.573067],[-95.087921,37.485724],[-94.942327,37.601838]]]},"properties":{"id":"66780","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.86036,38.073658],[-94.970061,38.168038],[-95.076751,38.182665],[-95.077876,38.037706],[-94.98798,38.022987],[-94.86036,38.073658]]]},"properties":{"id":"66010","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.018252,38.019332],[-97.078689,37.898046],[-97.042196,37.868769],[-96.968833,37.886248],[-96.932287,37.905187],[-97.018252,38.019332]]]},"properties":{"id":"67123","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.767408,37.984515],[-96.620735,37.955765],[-96.523853,37.970642],[-96.522782,38.08637],[-96.639426,38.086348],[-96.694676,38.0862],[-96.767408,37.984515]]]},"properties":{"id":"66842","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.597982,38.38756],[-96.43109,38.392218],[-96.409993,38.518227],[-96.597554,38.521974],[-96.666711,38.521952],[-96.597982,38.38756]]]},"properties":{"id":"66869","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.355336,38.292658],[-96.473787,38.172381],[-96.404571,38.086057],[-96.358076,38.088655],[-96.067319,38.215376],[-96.116043,38.303315],[-96.355336,38.292658]]]},"properties":{"id":"66865","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.92925,37.01715],[-95.074164,37.164409],[-95.211803,37.096141],[-95.213539,36.999577],[-94.931508,36.999174],[-94.92925,37.01715]]]},"properties":{"id":"67336","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.126195,39.539212],[-97.051287,39.526736],[-97.033151,39.566532],[-97.050111,39.58106],[-97.218948,39.668228],[-97.126195,39.539212]]]},"properties":{"id":"66962","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.691886,38.434062],[-95.728982,38.329004],[-95.59201,38.259371],[-95.507968,38.346583],[-95.508256,38.375758],[-95.508199,38.448453],[-95.691886,38.434062]]]},"properties":{"id":"66871","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.199528,37.412968],[-97.135176,37.475508],[-97.153108,37.504717],[-97.28829,37.520626],[-97.32381,37.506291],[-97.313579,37.450157],[-97.199528,37.412968]]]},"properties":{"id":"67110","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.705159,37.46963],[-94.723301,37.442569],[-94.690208,37.44031],[-94.66796,37.469375],[-94.705159,37.46963]]]},"properties":{"id":"66763","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.273547,39.799081],[-100.366643,39.806659],[-100.404323,39.712268],[-100.32939,39.567607],[-100.180351,39.56689],[-100.273547,39.799081]]]},"properties":{"id":"67643","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.084065,38.944797],[-97.130734,38.812838],[-97.075083,38.812632],[-97.084065,38.944797]]]},"properties":{"id":"67441","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.037926,38.827314],[-97.001632,38.754098],[-96.927225,38.78314],[-96.89029,38.855762],[-96.92704,38.856672],[-97.037926,38.827314]]]},"properties":{"id":"67492","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.898587,39.761522],[-94.907014,39.725101],[-94.859035,39.754116],[-94.898587,39.761522]]]},"properties":{"id":"66024","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.121092,38.61721],[-95.065097,38.622856],[-94.993244,38.694842],[-95.093398,38.782195],[-95.099947,38.807566],[-95.232625,38.71686],[-95.121092,38.61721]]]},"properties":{"id":"66092","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.97717,37.542769],[-98.922002,37.733283],[-98.995863,37.732866],[-99.022488,37.8544],[-99.096218,37.839726],[-99.178096,37.733546],[-99.193638,37.450268],[-99.165062,37.435559],[-98.97717,37.542769]]]},"properties":{"id":"67059","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.632594,37.733449],[-99.55958,37.734888],[-99.557681,37.734925],[-99.606492,37.956336],[-99.632594,37.733449]]]},"properties":{"id":"67563","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.172423,37.729828],[-96.327799,37.664722],[-96.344335,37.60674],[-96.142588,37.543866],[-96.172423,37.729828]]]},"properties":{"id":"67137","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.424701,38.681467],[-98.387725,38.56534],[-98.312711,38.521924],[-98.257082,38.580329],[-98.369513,38.697078],[-98.424701,38.681467]]]},"properties":{"id":"67459","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.856148,38.60961],[-98.032998,38.69646],[-98.022248,38.666955],[-98.015211,38.522362],[-97.924224,38.515307],[-97.869233,38.464242],[-97.773496,38.492901],[-97.801152,38.609446],[-97.856148,38.60961]]]},"properties":{"id":"67464","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.09515,38.755003],[-98.16475,38.609368],[-98.022248,38.666955],[-98.032998,38.69646],[-98.09515,38.755003]]]},"properties":{"id":"67454","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.581862,38.695273],[-95.565459,38.646738],[-95.564241,38.623106],[-95.61984,38.550697],[-95.508439,38.477445],[-95.490035,38.506496],[-95.500804,38.724252],[-95.581862,38.695273]]]},"properties":{"id":"66528","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.650086,38.812226],[-96.538395,38.78287],[-96.595054,38.967079],[-96.668138,38.870345],[-96.650086,38.812226]]]},"properties":{"id":"66849","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.063127,39.653805],[-100.112865,39.567186],[-100.031314,39.465443],[-99.956635,39.480195],[-99.732178,39.538977],[-99.702957,39.741081],[-99.758899,39.740859],[-100.063127,39.653805]]]},"properties":{"id":"67645","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.033564,39.18975],[-100.031314,39.465443],[-100.112865,39.567186],[-100.161667,39.567277],[-100.163741,39.218989],[-100.164044,39.189992],[-100.08929,39.178117],[-100.033564,39.18975]]]},"properties":{"id":"67650","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.044464,38.262412],[-101.579395,38.263047],[-101.567482,38.263145],[-101.567094,38.699669],[-101.91031,38.697889],[-102.045212,38.697567],[-102.044464,38.262412]]]},"properties":{"id":"67879","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.245376,37.42183],[-98.318816,37.529481],[-98.349804,37.384056],[-98.349492,37.310973],[-98.258272,37.355135],[-98.245376,37.42183]]]},"properties":{"id":"67159","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.993271,38.248603],[-97.150703,38.260867],[-97.24357,38.232862],[-97.244231,38.203846],[-97.191382,38.102212],[-97.031074,38.0864],[-96.968877,38.114952],[-96.993271,38.248603]]]},"properties":{"id":"66866","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.124218,39.283485],[-96.016561,39.265983],[-95.997988,39.368911],[-96.034729,39.390803],[-96.109337,39.390885],[-96.184002,39.344001],[-96.124218,39.283485]]]},"properties":{"id":"66422","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.490398,39.129387],[-95.517698,39.252427],[-95.589059,39.303141],[-95.598611,39.303171],[-95.619049,39.216185],[-95.571457,39.116426],[-95.488329,39.101751],[-95.490398,39.129387]]]},"properties":{"id":"66512","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.034729,39.390803],[-96.08909,39.566124],[-96.109337,39.390885],[-96.034729,39.390803]]]},"properties":{"id":"66432","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607986,38.912924],[-94.662761,38.915505],[-94.667739,38.912866],[-94.667802,38.886675],[-94.64922,38.883736],[-94.60796,38.888639],[-94.607986,38.912924]]]},"properties":{"id":"66209","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.686496,38.886506],[-94.742364,38.883646],[-94.70518,38.840094],[-94.686332,38.84002],[-94.686496,38.886506]]]},"properties":{"id":"66221","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.312215,39.161309],[-98.281484,38.882925],[-98.061256,38.870952],[-98.021459,38.871177],[-97.984145,39.052797],[-98.209804,39.219291],[-98.312215,39.161309]]]},"properties":{"id":"67455","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.705282,39.031009],[-98.66269,38.958758],[-98.499474,38.957884],[-98.471164,39.161781],[-98.489879,39.205334],[-98.601615,39.263816],[-98.657365,39.234234],[-98.705282,39.031009]]]},"properties":{"id":"67648","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.191246,39.233892],[-98.209804,39.219291],[-97.984145,39.052797],[-97.95627,39.030883],[-97.92884,39.117973],[-97.929746,39.219273],[-98.191246,39.233892]]]},"properties":{"id":"67418","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.191246,39.233892],[-98.30271,39.317435],[-98.37712,39.379012],[-98.433369,39.364787],[-98.489879,39.205334],[-98.471164,39.161781],[-98.312215,39.161309],[-98.209804,39.219291],[-98.191246,39.233892]]]},"properties":{"id":"67452","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.814756,38.252048],[-94.970061,38.168038],[-94.86036,38.073658],[-94.832905,38.037442],[-94.805572,38.05186],[-94.750518,38.124317],[-94.814756,38.252048]]]},"properties":{"id":"66056","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.482306,38.478389],[-97.463841,38.260804],[-97.390173,38.246419],[-97.353165,38.275336],[-97.353248,38.333397],[-97.37188,38.435041],[-97.371932,38.522161],[-97.482306,38.478389]]]},"properties":{"id":"67428","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.837708,38.30481],[-96.993271,38.248603],[-96.968877,38.114952],[-96.840628,38.187262],[-96.837708,38.30481]]]},"properties":{"id":"66851","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.948172,39.386158],[-97.929374,39.378842],[-97.929507,39.393543],[-97.948172,39.386158]]]},"properties":{"id":"67478","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.730286,37.069633],[-95.70785,37.049893],[-95.709611,37.059469],[-95.720057,37.069582],[-95.728848,37.069619],[-95.730286,37.069633]]]},"properties":{"id":"67340","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.742572,39.031243],[-98.705282,39.031009],[-98.657365,39.234234],[-98.733008,39.23501],[-98.742572,39.031243]]]},"properties":{"id":"67649","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.824377,39.292762],[-98.733008,39.23501],[-98.657365,39.234234],[-98.601615,39.263816],[-98.583255,39.329069],[-98.655947,39.509859],[-98.806049,39.481766],[-98.936559,39.323859],[-98.824377,39.292762]]]},"properties":{"id":"67473","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.733008,39.23501],[-98.824377,39.292762],[-98.918127,39.220205],[-98.873107,39.060032],[-98.779656,38.991016],[-98.742572,39.031243],[-98.733008,39.23501]]]},"properties":{"id":"67673","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.53851,39.088736],[-97.705332,39.001614],[-97.64083,38.957999],[-97.538277,38.958052],[-97.529509,38.994045],[-97.53851,39.088736]]]},"properties":{"id":"67422","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.556458,37.703605],[-98.682001,37.718477],[-98.849136,37.777139],[-98.922002,37.733283],[-98.97717,37.542769],[-98.75678,37.470535],[-98.601782,37.558262],[-98.556581,37.587102],[-98.556458,37.703605]]]},"properties":{"id":"67124","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.721258,37.824808],[-98.682001,37.718477],[-98.556458,37.703605],[-98.237168,37.774222],[-98.362572,37.897439],[-98.471956,37.894422],[-98.710606,37.824744],[-98.721258,37.824808]]]},"properties":{"id":"67583","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.004995,38.17324],[-98.179067,38.072062],[-98.142218,38.043052],[-98.08748,38.035458],[-98.032571,38.085936],[-98.004995,38.17324]]]},"properties":{"id":"67561","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.270541,37.926829],[-98.252119,38.071777],[-98.381197,38.086033],[-98.343954,37.911642],[-98.270541,37.926829]]]},"properties":{"id":"67568","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.118714,37.82486],[-98.160551,37.941372],[-98.270541,37.926829],[-98.343954,37.911642],[-98.362572,37.897439],[-98.237168,37.774222],[-98.228545,37.776243],[-98.118832,37.806037],[-98.118714,37.82486]]]},"properties":{"id":"67514","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.702178,37.907757],[-97.807471,37.777791],[-97.807823,37.733855],[-97.735469,37.73413],[-97.680929,37.74963],[-97.601321,37.807855],[-97.518834,37.842594],[-97.592143,37.866861],[-97.702178,37.907757]]]},"properties":{"id":"67108","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.519088,39.856493],[-97.533009,39.769517],[-97.368562,39.741006],[-97.368561,39.755525],[-97.368773,39.856057],[-97.462888,39.885939],[-97.519088,39.856493]]]},"properties":{"id":"66940","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.835858,39.871092],[-97.819473,39.711669],[-97.786067,39.653729],[-97.670059,39.696801],[-97.744659,39.885667],[-97.835858,39.871092]]]},"properties":{"id":"66966","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.722003,39.124176],[-96.706818,39.098688],[-96.701254,39.119179],[-96.722003,39.124176]]]},"properties":{"id":"66517","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.152964,37.650313],[-97.152941,37.723292],[-97.189657,37.723103],[-97.18953,37.686626],[-97.189515,37.65014],[-97.175512,37.650224],[-97.152964,37.650313]]]},"properties":{"id":"67230","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.370502,37.580403],[-97.334037,37.573493],[-97.346717,37.651609],[-97.389135,37.651636],[-97.398856,37.646546],[-97.370502,37.580403]]]},"properties":{"id":"67217","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.313579,37.450157],[-97.32381,37.506291],[-97.406217,37.520276],[-97.44679,37.43327],[-97.391321,37.390836],[-97.313579,37.450157]]]},"properties":{"id":"67120","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.337493,37.722588],[-97.336893,37.693461],[-97.325252,37.686072],[-97.298955,37.6863],[-97.298484,37.715516],[-97.293692,37.722815],[-97.299048,37.722765],[-97.337493,37.722588]]]},"properties":{"id":"67214","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.336893,37.693461],[-97.337493,37.722588],[-97.386487,37.725661],[-97.389668,37.722652],[-97.389381,37.683907],[-97.343905,37.685411],[-97.336893,37.693461]]]},"properties":{"id":"67203","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.587794,37.475166],[-97.715983,37.489138],[-97.76666,37.386433],[-97.585153,37.329283],[-97.548833,37.358694],[-97.494542,37.397747],[-97.587794,37.475166]]]},"properties":{"id":"67031","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.71644,37.529957],[-97.789706,37.561776],[-97.807057,37.386293],[-97.76666,37.386433],[-97.715983,37.489138],[-97.71644,37.529957]]]},"properties":{"id":"67106","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.595702,38.986015],[-95.500759,38.967665],[-95.500254,39.056656],[-95.579956,39.0541],[-95.590079,39.052609],[-95.613939,39.030657],[-95.595702,38.986015]]]},"properties":{"id":"66542","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.678427,39.067006],[-95.685962,39.051806],[-95.670943,39.047885],[-95.662517,39.060208],[-95.660684,39.061803],[-95.676181,39.065067],[-95.678427,39.067006]]]},"properties":{"id":"66603","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.662517,39.060208],[-95.590079,39.052609],[-95.579956,39.0541],[-95.62546,39.083298],[-95.660684,39.061803],[-95.662517,39.060208]]]},"properties":{"id":"66616","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.692822,38.202335],[-98.61935,38.07757],[-98.527704,38.201758],[-98.692697,38.231566],[-98.692822,38.202335]]]},"properties":{"id":"67545","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.802313,36.998718],[-97.465239,36.998685],[-97.492294,37.113673],[-97.58348,37.170358],[-97.622838,37.198867],[-97.747192,37.168941],[-97.783621,37.110721],[-97.802313,36.998718]]]},"properties":{"id":"67022","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.085712,38.986425],[-95.965513,38.906451],[-95.946747,38.906432],[-95.946868,38.928445],[-95.946709,38.993617],[-95.983888,39.029831],[-95.976579,39.112245],[-96.03906,39.126527],[-96.136234,39.190099],[-96.147539,39.158842],[-96.085712,38.986425]]]},"properties":{"id":"66507","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.047161,39.133147],[-102.045212,38.697567],[-101.91031,38.697889],[-101.922958,39.133245],[-101.969452,39.1332],[-102.047161,39.133147]]]},"properties":{"id":"67762","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.643874,39.044038],[-94.649572,39.077616],[-94.679698,39.09976],[-94.710738,39.093656],[-94.800928,39.043514],[-94.791527,39.043575],[-94.789177,39.043612],[-94.758716,39.043693],[-94.723892,39.043998],[-94.658844,39.044038],[-94.649141,39.044029],[-94.643874,39.044038]]]},"properties":{"id":"66106","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.426072,38.154805],[-95.518549,38.096367],[-95.518798,38.068053],[-95.519132,37.988486],[-95.299176,38.038067],[-95.280854,38.038026],[-95.280749,38.103528],[-95.426072,38.154805]]]},"properties":{"id":"66015","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.861311,38.26568],[-95.06525,38.287654],[-95.076751,38.182665],[-94.970061,38.168038],[-94.814756,38.252048],[-94.825131,38.265342],[-94.861311,38.26568]]]},"properties":{"id":"66014","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.470699,39.577538],[-95.47825,39.478562],[-95.469136,39.449646],[-95.394705,39.427673],[-95.300785,39.484744],[-95.281946,39.503751],[-95.377149,39.565668],[-95.405288,39.594749],[-95.470699,39.577538]]]},"properties":{"id":"66023","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.692697,38.231566],[-98.527704,38.201758],[-98.472672,38.201983],[-98.461693,38.347594],[-98.443447,38.442994],[-98.461801,38.463668],[-98.631441,38.44077],[-98.692697,38.231566]]]},"properties":{"id":"67526","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.124542,38.348584],[-98.876327,38.174421],[-98.857816,38.23261],[-99.03276,38.362858],[-99.088189,38.377458],[-99.124542,38.348584]]]},"properties":{"id":"67567","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.942944,37.986548],[-94.906125,37.950342],[-94.860243,37.950315],[-94.805229,37.99382],[-94.832905,38.037442],[-94.86036,38.073658],[-94.98798,38.022987],[-94.942944,37.986548]]]},"properties":{"id":"66754","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.667407,37.687822],[-94.795125,37.659484],[-94.741302,37.601121],[-94.668193,37.572121],[-94.617347,37.591218],[-94.617966,37.682217],[-94.667407,37.687822]]]},"properties":{"id":"66711","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.854656,39.739898],[-95.845395,39.580461],[-95.811674,39.56591],[-95.751106,39.589046],[-95.750437,39.696603],[-95.75015,39.725736],[-95.78894,39.740056],[-95.854656,39.739898]]]},"properties":{"id":"66550","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.968877,38.114952],[-97.031074,38.0864],[-97.013756,38.028427],[-97.018252,38.019332],[-96.932287,37.905187],[-96.767408,37.984515],[-96.694676,38.0862],[-96.840628,38.187262],[-96.968877,38.114952]]]},"properties":{"id":"66840","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.153043,37.621215],[-97.153108,37.504717],[-97.026522,37.56323],[-97.080377,37.635997],[-97.153048,37.635768],[-97.153043,37.621215]]]},"properties":{"id":"67133","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.191382,38.102212],[-97.298871,38.115395],[-97.244231,38.203846],[-97.24357,38.232862],[-97.353165,38.275336],[-97.390173,38.246419],[-97.378783,38.191997],[-97.427057,38.101106],[-97.44262,37.999389],[-97.263221,37.926883],[-97.219055,37.933848],[-97.013756,38.028427],[-97.031074,38.0864],[-97.191382,38.102212]],[[-97.134484,38.058317],[-97.125251,38.058151],[-97.125565,38.05023],[-97.134394,38.049771],[-97.134484,38.058317]],[[-97.339514,38.246681],[-97.348873,38.244497],[-97.353254,38.249173],[-97.338644,38.24979],[-97.339514,38.246681]],[[-97.357488,38.074733],[-97.336819,38.071824],[-97.342006,38.062122],[-97.357488,38.074733]]]},"properties":{"id":"67114","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.134484,38.058317],[-97.134394,38.049771],[-97.125565,38.05023],[-97.125251,38.058151],[-97.134484,38.058317]]]},"properties":{"id":"67041","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.522782,38.08637],[-96.458909,38.086315],[-96.404571,38.086057],[-96.473787,38.172381],[-96.639858,38.21334],[-96.639426,38.086348],[-96.522782,38.08637]]]},"properties":{"id":"66862","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.409993,38.518227],[-96.43109,38.392218],[-96.355336,38.292658],[-96.116043,38.303315],[-96.079252,38.332226],[-95.987199,38.405055],[-96.079122,38.521081],[-96.132809,38.578823],[-96.188349,38.579161],[-96.35378,38.521657],[-96.409993,38.518227]]]},"properties":{"id":"66801","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.017186,37.179397],[-96.091526,37.248856],[-96.226914,37.300902],[-96.344718,37.228579],[-96.380947,37.200227],[-96.345284,36.999293],[-96.184708,36.999338],[-96.181865,37.019937],[-96.181631,37.029046],[-96.17242,37.029046],[-96.172494,37.021896],[-96.023258,37.09485],[-96.017186,37.179397]]]},"properties":{"id":"67361","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.184708,36.999338],[-96.035772,36.998967],[-96.009516,37.080267],[-96.023258,37.09485],[-96.172494,37.021896],[-96.181865,37.019937],[-96.184708,36.999338]]]},"properties":{"id":"67360","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.525265,37.244305],[-96.380947,37.200227],[-96.344718,37.228579],[-96.380146,37.40349],[-96.525296,37.403387],[-96.525265,37.244305]]]},"properties":{"id":"67346","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.905848,37.412156],[-94.796127,37.351482],[-94.652844,37.207381],[-94.618313,37.194687],[-94.617722,37.338418],[-94.631612,37.338316],[-94.631518,37.352835],[-94.61761,37.352844],[-94.616724,37.506904],[-94.68629,37.51352],[-94.725262,37.499115],[-94.905848,37.412156]],[[-94.705159,37.46963],[-94.66796,37.469375],[-94.690208,37.44031],[-94.723301,37.442569],[-94.705159,37.46963]]]},"properties":{"id":"66762","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.050419,39.645935],[-101.947282,39.568327],[-101.638647,39.568459],[-101.56237,40.002706],[-102.050419,39.645935]]]},"properties":{"id":"67756","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.083547,39.104243],[-97.000533,39.118066],[-96.932523,39.154363],[-97.001062,39.252089],[-97.185224,39.205853],[-97.185127,39.147901],[-97.083547,39.104243]]]},"properties":{"id":"67487","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.877033,38.498906],[-95.950498,38.392113],[-95.904437,38.297594],[-95.821079,38.263402],[-95.728982,38.329004],[-95.691886,38.434062],[-95.69175,38.516572],[-95.750414,38.515136],[-95.877033,38.498906]]]},"properties":{"id":"66856","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067319,38.215376],[-95.977347,38.171109],[-95.922026,38.170641],[-95.821079,38.263402],[-95.904437,38.297594],[-96.079252,38.332226],[-96.116043,38.303315],[-96.067319,38.215376]]]},"properties":{"id":"66854","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.677236,37.417623],[-96.71568,37.244146],[-96.607909,37.244041],[-96.525265,37.244305],[-96.525296,37.403387],[-96.52569,37.476405],[-96.641266,37.431988],[-96.677236,37.417623]]]},"properties":{"id":"67023","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.925631,37.475706],[-97.023012,37.468919],[-97.031546,37.390358],[-96.861076,37.389078],[-96.822409,37.394255],[-96.925631,37.475706]]]},"properties":{"id":"67131","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.087694,37.471335],[-94.904824,37.368715],[-94.905848,37.412156],[-94.725262,37.499115],[-94.724315,37.513816],[-94.760146,37.580346],[-94.905879,37.616318],[-94.942327,37.601838],[-95.087921,37.485724],[-95.087694,37.471335]]]},"properties":{"id":"66743","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.686246,37.528222],[-94.724315,37.513816],[-94.725262,37.499115],[-94.68629,37.51352],[-94.686246,37.528222]]]},"properties":{"id":"66735","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.905879,37.616318],[-94.760146,37.580346],[-94.741302,37.601121],[-94.795125,37.659484],[-94.869146,37.659854],[-94.905879,37.616318]]]},"properties":{"id":"66734","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.366643,39.806659],[-100.273547,39.799081],[-100.133458,39.791638],[-100.056433,40.001632],[-100.365562,40.001823],[-100.366643,39.806659]]]},"properties":{"id":"67653","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.529509,38.994045],[-97.427648,38.855953],[-97.427544,38.798058],[-97.35394,38.783481],[-97.371145,39.10297],[-97.460235,39.110861],[-97.53851,39.088736],[-97.529509,38.994045]]]},"properties":{"id":"67480","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.285425,39.032162],[-95.260475,38.956912],[-95.251105,38.958891],[-95.251056,38.963407],[-95.244079,38.964324],[-95.245822,38.956675],[-95.255863,38.955837],[-95.260482,38.950837],[-95.260489,38.949966],[-95.148844,38.97434],[-95.130543,38.968428],[-95.131136,39.02271],[-95.187811,39.102131],[-95.255869,39.113158],[-95.270578,39.094813],[-95.285425,39.032162]]]},"properties":{"id":"66044","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.093398,38.782195],[-94.993244,38.694842],[-94.964042,38.702009],[-94.956814,38.73851],[-95.037675,38.840615],[-95.093398,38.782195]]]},"properties":{"id":"66021","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.606492,37.956336],[-99.557681,37.734925],[-99.395602,37.73397],[-99.30503,37.733705],[-99.317865,38.00053],[-99.368675,38.087723],[-99.459884,38.088159],[-99.642991,38.116492],[-99.661569,38.014663],[-99.606492,37.956336]]]},"properties":{"id":"67547","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.087747,38.864392],[-99.159083,39.026643],[-99.204746,39.016337],[-99.134314,38.740524],[-99.098262,38.696882],[-99.042626,38.696807],[-99.041987,38.729674],[-99.039447,38.856109],[-99.087747,38.864392]]]},"properties":{"id":"67671","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.34274,37.627414],[-100.32456,37.533208],[-100.17992,37.562578],[-100.21481,37.736989],[-100.34274,37.627414]]]},"properties":{"id":"67841","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.343112,37.474803],[-100.091663,37.182842],[-100.091622,37.285597],[-100.10708,37.562116],[-100.17992,37.562578],[-100.32456,37.533208],[-100.343112,37.474803]]]},"properties":{"id":"67844","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.941255,37.729578],[-99.938371,37.727026],[-99.934237,37.73255],[-99.938967,37.733867],[-99.941255,37.729578]]]},"properties":{"id":"67843","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.355624,38.510733],[-95.174807,38.550138],[-95.121092,38.61721],[-95.232625,38.71686],[-95.371394,38.738801],[-95.399346,38.738868],[-95.397057,38.550227],[-95.355624,38.510733]]]},"properties":{"id":"66067","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.010464,38.389457],[-95.07487,38.476968],[-95.156987,38.462749],[-95.157164,38.433632],[-95.010464,38.389457]]]},"properties":{"id":"66042","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.386953,39.22008],[-100.408604,39.003034],[-100.37169,38.97953],[-100.28493,39.219178],[-100.386953,39.22008]]]},"properties":{"id":"67751","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.37169,38.97953],[-100.318333,38.786713],[-100.15176,38.842325],[-100.164044,39.189992],[-100.163741,39.218989],[-100.28493,39.219178],[-100.37169,38.97953]]]},"properties":{"id":"67752","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.458909,38.086315],[-96.081915,37.906479],[-96.067739,37.997627],[-96.358076,38.088655],[-96.404571,38.086057],[-96.458909,38.086315]]]},"properties":{"id":"66853","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.102625,37.83634],[-96.068,37.830169],[-96.067938,37.83715],[-96.102625,37.83634]]]},"properties":{"id":"66863","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.893051,37.196968],[-97.911659,37.124238],[-97.783024,37.168698],[-97.84794,37.241364],[-97.893872,37.240518],[-97.893051,37.196968]]]},"properties":{"id":"67049","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.244231,38.203846],[-97.298871,38.115395],[-97.191382,38.102212],[-97.244231,38.203846]]]},"properties":{"id":"67151","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.378783,38.191997],[-97.500424,38.1159],[-97.427057,38.101106],[-97.378783,38.191997]]]},"properties":{"id":"67062","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.964253,39.116321],[-94.988329,39.179243],[-95.133702,39.17235],[-95.187811,39.102131],[-95.131136,39.02271],[-95.001543,39.058278],[-94.964253,39.116321]]]},"properties":{"id":"66086","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.429489,40.002398],[-98.353004,39.828416],[-98.193896,39.943994],[-98.156844,40.002241],[-98.429489,40.002398]]]},"properties":{"id":"66936","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.353004,39.828416],[-98.427537,39.668889],[-98.38949,39.654362],[-98.082666,39.755338],[-98.043206,39.888502],[-98.099076,39.943635],[-98.193896,39.943994],[-98.353004,39.828416]]]},"properties":{"id":"66956","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.504647,39.683528],[-98.489749,39.37935],[-98.433369,39.364787],[-98.37712,39.379012],[-98.351403,39.618276],[-98.38949,39.654362],[-98.427537,39.668889],[-98.467373,39.69786],[-98.504647,39.683528]]]},"properties":{"id":"67430","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.504455,40.002291],[-98.467373,39.69786],[-98.427537,39.668889],[-98.353004,39.828416],[-98.429489,40.002398],[-98.504455,40.002291]]]},"properties":{"id":"66941","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.723669,38.985599],[-94.761094,38.985592],[-94.764142,38.920065],[-94.74227,38.920517],[-94.723645,38.935082],[-94.723669,38.985599]]]},"properties":{"id":"66215","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.64922,38.883736],[-94.649189,38.84011],[-94.607879,38.839829],[-94.60796,38.888639],[-94.64922,38.883736]]]},"properties":{"id":"66224","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.888744,38.815224],[-94.880663,38.854586],[-94.893486,38.854566],[-94.908991,38.828238],[-94.888744,38.815224]]]},"properties":{"id":"66031","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.972822,37.696901],[-97.880582,37.554167],[-97.880231,37.604504],[-97.899659,37.704791],[-97.972822,37.696901]]]},"properties":{"id":"67111","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.249468,37.207869],[-95.108642,37.304523],[-95.123774,37.384119],[-95.232954,37.4713],[-95.288331,37.456647],[-95.412423,37.383947],[-95.394925,37.310646],[-95.339124,37.237921],[-95.249468,37.207869]]]},"properties":{"id":"67357","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.679171,38.700038],[-100.68642,38.48453],[-100.541184,38.699991],[-100.597282,38.700087],[-100.679171,38.700038]]]},"properties":{"id":"67850","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.889103,39.288803],[-94.83623,39.219027],[-94.83479,39.221035],[-94.889103,39.288803]]]},"properties":{"id":"66043","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.471164,39.161781],[-98.499474,38.957884],[-98.484805,38.882612],[-98.31854,38.882876],[-98.281484,38.882925],[-98.312215,39.161309],[-98.471164,39.161781]]]},"properties":{"id":"67481","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.260889,38.608134],[-96.188349,38.579161],[-96.132809,38.578823],[-96.069832,38.651669],[-96.022597,38.709809],[-96.019959,38.738918],[-96.131137,38.739069],[-96.279683,38.738949],[-96.348806,38.738933],[-96.260889,38.608134]]]},"properties":{"id":"66833","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463613,39.823477],[-96.370048,39.841895],[-96.351264,39.899927],[-96.445494,40.001013],[-96.463712,40.001023],[-96.48254,39.942991],[-96.482554,39.928465],[-96.482224,39.899415],[-96.482312,39.870435],[-96.463613,39.823477]]]},"properties":{"id":"66406","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.489749,39.37935],[-98.583255,39.329069],[-98.601615,39.263816],[-98.489879,39.205334],[-98.433369,39.364787],[-98.489749,39.37935]]]},"properties":{"id":"67485","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.778969,37.349165],[-95.927907,37.197703],[-95.855569,37.110185],[-95.801169,37.080626],[-95.800994,37.033316],[-95.787508,37.033252],[-95.730286,37.069633],[-95.728848,37.069619],[-95.663804,37.144045],[-95.594636,37.18076],[-95.656544,37.348148],[-95.710899,37.337474],[-95.72452,37.327121],[-95.738423,37.349079],[-95.778969,37.349165]]]},"properties":{"id":"67301","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.042053,37.07521],[-101.827318,37.156103],[-101.767393,37.388788],[-101.889348,37.389008],[-102.041642,37.38919],[-102.042053,37.07521]]]},"properties":{"id":"67953","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.379214,37.543964],[-95.434447,37.383906],[-95.412423,37.383947],[-95.288331,37.456647],[-95.325986,37.594465],[-95.379214,37.543964]]]},"properties":{"id":"66740","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.538277,38.958052],[-97.427648,38.855953],[-97.529509,38.994045],[-97.538277,38.958052]]]},"properties":{"id":"67470","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.206447,39.655303],[-100.942872,39.567984],[-100.889261,39.654911],[-101.060361,40.002359],[-101.293988,40.002567],[-101.206447,39.655303]]]},"properties":{"id":"67730","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.889261,39.654911],[-100.942872,39.567984],[-100.850857,39.423321],[-100.796699,39.669242],[-100.815474,39.712264],[-100.852046,39.712363],[-100.889261,39.654911]]]},"properties":{"id":"67734","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.796699,39.669242],[-100.850857,39.423321],[-100.833294,39.307102],[-100.721278,39.263566],[-100.591398,39.262762],[-100.608292,39.379137],[-100.741099,39.654759],[-100.796699,39.669242]]]},"properties":{"id":"67753","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.050301,37.883126],[-98.08748,38.035458],[-98.142218,38.043052],[-98.160551,37.941372],[-98.118714,37.82486],[-98.050301,37.883126]]]},"properties":{"id":"67566","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514357,40.001884],[-97.593721,40.002051],[-97.519088,39.856493],[-97.462888,39.885939],[-97.514357,40.001884]]]},"properties":{"id":"66959","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.346717,37.651609],[-97.337451,37.649714],[-97.338127,37.679088],[-97.343905,37.685411],[-97.389381,37.683907],[-97.390576,37.683886],[-97.389135,37.651636],[-97.346717,37.651609]]]},"properties":{"id":"67213","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.259825,37.723004],[-97.262309,37.726816],[-97.262302,37.724958],[-97.259825,37.723004]]],[[[-97.26216,37.686491],[-97.18953,37.686626],[-97.189657,37.723103],[-97.259215,37.723004],[-97.26216,37.686491]]]]},"properties":{"id":"67206","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.17156,37.804594],[-97.189834,37.79613],[-97.189791,37.786269],[-97.189657,37.723103],[-97.152941,37.723292],[-97.152939,37.741872],[-97.17156,37.804594]]]},"properties":{"id":"67228","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.339254,37.825023],[-97.337493,37.722588],[-97.299048,37.722765],[-97.299133,37.768177],[-97.294874,37.81047],[-97.339254,37.825023]]]},"properties":{"id":"67219","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.481103,37.722383],[-97.517282,37.736327],[-97.517078,37.721921],[-97.516708,37.649769],[-97.48081,37.64904],[-97.480854,37.65015],[-97.480666,37.684008],[-97.481103,37.722383]]]},"properties":{"id":"67235","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.262524,37.781353],[-97.245051,37.795905],[-97.294874,37.81047],[-97.299133,37.768177],[-97.262524,37.781353]]]},"properties":{"id":"67067","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.933045,37.257204],[-100.633354,37.051598],[-100.742556,37.373112],[-100.887032,37.315202],[-101.011787,37.315122],[-100.933045,37.257204]]]},"properties":{"id":"67859","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.673049,39.21644],[-95.766376,39.21653],[-95.812377,39.216535],[-95.871784,39.085087],[-95.751361,39.070699],[-95.678427,39.067006],[-95.676181,39.065067],[-95.673148,39.090953],[-95.673049,39.21644]]]},"properties":{"id":"66618","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.696898,39.029702],[-95.69686,39.036932],[-95.70615,39.036976],[-95.706225,39.029708],[-95.696898,39.029702]]]},"properties":{"id":"66621","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.711453,39.000566],[-95.683789,39.00063],[-95.675434,39.02973],[-95.687593,39.029702],[-95.696898,39.029702],[-95.706225,39.029708],[-95.712879,39.016704],[-95.711453,39.000566]]]},"properties":{"id":"66611","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.871784,39.085087],[-95.812377,39.216535],[-95.849206,39.216508],[-95.8871,39.216958],[-95.909668,39.087854],[-95.871784,39.085087]]]},"properties":{"id":"66539","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.441681,39.480912],[-100.608292,39.379137],[-100.591398,39.262762],[-100.563532,39.219897],[-100.386953,39.22008],[-100.28493,39.219178],[-100.163741,39.218989],[-100.161667,39.567277],[-100.180351,39.56689],[-100.32939,39.567607],[-100.441681,39.480912]]]},"properties":{"id":"67740","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.841965,39.698152],[-98.761153,39.567636],[-98.654298,39.683344],[-98.729035,40.002321],[-98.934847,40.002181],[-98.935746,39.915527],[-98.935728,39.901069],[-98.841965,39.698152]]]},"properties":{"id":"66967","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.935123,39.683826],[-98.841965,39.698152],[-98.935728,39.901069],[-98.954495,39.915577],[-98.986426,39.698343],[-98.935123,39.683826]]]},"properties":{"id":"66932","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.494542,37.397747],[-97.548833,37.358694],[-97.58348,37.170358],[-97.492294,37.113673],[-97.275633,37.188539],[-97.240897,37.304306],[-97.391321,37.390836],[-97.44679,37.43327],[-97.494542,37.397747]]]},"properties":{"id":"67152","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.548833,37.358694],[-97.585153,37.329283],[-97.603269,37.285558],[-97.622838,37.198867],[-97.58348,37.170358],[-97.548833,37.358694]]]},"properties":{"id":"67103","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.23487,39.05789],[-96.242377,39.086674],[-96.372481,39.101616],[-96.501214,39.014897],[-96.298934,38.826155],[-96.131933,38.978895],[-96.230631,39.053356],[-96.242176,39.049169],[-96.242208,39.055757],[-96.23487,39.05789]]]},"properties":{"id":"66401","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.23487,39.05789],[-96.242208,39.055757],[-96.242176,39.049169],[-96.230631,39.053356],[-96.23487,39.05789]]]},"properties":{"id":"66501","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.369215,39.929401],[-97.368773,39.856057],[-97.368561,39.755525],[-97.350527,39.755647],[-97.256148,39.741066],[-97.256163,39.755595],[-97.238371,39.929825],[-97.369215,39.929401]]]},"properties":{"id":"66944","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.107126,39.944338],[-96.993533,39.929319],[-96.89966,40.001484],[-97.10515,40.001725],[-97.107126,39.944338]]]},"properties":{"id":"66946","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.649572,39.077616],[-94.643874,39.044038],[-94.607382,39.044086],[-94.607223,39.089709],[-94.608651,39.090064],[-94.649572,39.077616]]]},"properties":{"id":"66103","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.807378,39.123633],[-94.83514,39.123282],[-94.871745,39.059315],[-94.800928,39.043514],[-94.710738,39.093656],[-94.740772,39.102286],[-94.807378,39.123633]]]},"properties":{"id":"66111","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.616514,39.113764],[-94.622959,39.093407],[-94.608651,39.090064],[-94.607223,39.089709],[-94.607354,39.113444],[-94.616514,39.113764]]]},"properties":{"id":"66118","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.695261,39.653048],[-95.564182,39.602259],[-95.470699,39.577538],[-95.405288,39.594749],[-95.470864,39.696331],[-95.620155,39.725367],[-95.676363,39.683177],[-95.695261,39.653048]]]},"properties":{"id":"66439","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.570125,39.535078],[-95.47825,39.478562],[-95.470699,39.577538],[-95.564182,39.602259],[-95.570125,39.535078]]]},"properties":{"id":"66058","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.458584,37.384073],[-98.531327,37.167214],[-98.495068,37.166829],[-98.348436,37.18154],[-98.349492,37.310973],[-98.349804,37.384056],[-98.458584,37.384073]]]},"properties":{"id":"67138","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.556508,37.557842],[-98.556581,37.587102],[-98.601782,37.558262],[-98.592899,37.558121],[-98.556508,37.557842]]],[[[-98.556508,37.557842],[-98.592854,37.543574],[-98.610764,37.4706],[-98.464951,37.384086],[-98.464748,37.528986],[-98.556508,37.557842]]]]},"properties":{"id":"67065","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.922424,38.435879],[-99.014609,38.478474],[-99.088491,38.493554],[-99.125406,38.493744],[-99.088189,38.377458],[-99.03276,38.362858],[-98.922424,38.435879]]]},"properties":{"id":"67511","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.673002,40.000338],[-95.676393,39.870124],[-95.639133,39.812277],[-95.620155,39.725367],[-95.470864,39.696331],[-95.43376,39.754644],[-95.415197,39.912226],[-95.437316,40.000209],[-95.673002,40.000338]]]},"properties":{"id":"66434","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.935345,39.865658],[-95.920443,39.783392],[-95.854656,39.739898],[-95.78894,39.740056],[-95.732948,39.87032],[-95.74898,40.000457],[-95.882585,40.000475],[-95.977017,39.892091],[-95.936872,39.868259],[-95.935345,39.865658]]]},"properties":{"id":"66534","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.837708,38.30481],[-96.840628,38.187262],[-96.694676,38.0862],[-96.639426,38.086348],[-96.639858,38.21334],[-96.653616,38.300332],[-96.819475,38.348502],[-96.837708,38.30481]]]},"properties":{"id":"66843","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.380947,37.200227],[-96.525265,37.244305],[-96.607909,37.244041],[-96.607359,36.998938],[-96.345284,36.999293],[-96.380947,37.200227]]]},"properties":{"id":"67024","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.009516,37.080267],[-96.035772,36.998967],[-95.96427,36.999104],[-95.964571,37.067579],[-96.009516,37.080267]]]},"properties":{"id":"67355","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.758957,37.084144],[-94.702109,37.055145],[-94.743204,37.090759],[-94.758957,37.084144]]]},"properties":{"id":"66770","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.908209,37.266811],[-95.041312,37.259524],[-95.074164,37.164409],[-94.92925,37.01715],[-94.758957,37.084144],[-94.743204,37.090759],[-94.704339,37.163191],[-94.704438,37.177901],[-94.7049,37.222025],[-94.908209,37.266811]]]},"properties":{"id":"66725","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.85049,37.298448],[-94.705007,37.236659],[-94.652844,37.207381],[-94.796127,37.351482],[-94.85049,37.298448]]]},"properties":{"id":"66781","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.050419,39.645935],[-102.047161,39.133147],[-101.969452,39.1332],[-101.947282,39.568327],[-102.050419,39.645935]]]},"properties":{"id":"67741","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.821079,38.263402],[-95.922026,38.170641],[-95.757555,38.115758],[-95.610224,38.176478],[-95.59201,38.259371],[-95.728982,38.329004],[-95.821079,38.263402]]]},"properties":{"id":"66839","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.140337,37.143449],[-97.147721,36.999033],[-96.8229,36.99921],[-96.866564,37.127525],[-97.097373,37.157732],[-97.140337,37.143449]]]},"properties":{"id":"67005","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.617722,37.338418],[-94.61761,37.352844],[-94.631518,37.352835],[-94.631612,37.338316],[-94.617722,37.338418]]]},"properties":{"id":"66760","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.083547,39.104243],[-97.084065,38.944797],[-97.075083,38.812632],[-97.037926,38.827314],[-96.92704,38.856672],[-97.000533,39.118066],[-97.083547,39.104243]]]},"properties":{"id":"67431","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.869817,39.072354],[-96.896824,39.089607],[-96.932523,39.154363],[-97.000533,39.118066],[-96.92704,38.856672],[-96.89029,38.855762],[-96.668138,38.870345],[-96.595054,38.967079],[-96.602429,39.010722],[-96.706077,39.09126],[-96.869817,39.072354]]]},"properties":{"id":"66441","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.246304,39.827658],[-95.283943,39.842106],[-95.26518,39.827685],[-95.246304,39.827658]]],[[[-95.114435,39.714686],[-95.041055,39.679417],[-95.021897,39.893974],[-95.15319,39.906841],[-95.246304,39.827658],[-95.26511,39.805801],[-95.302732,39.769385],[-95.227345,39.754886],[-95.114435,39.714686]]]]},"properties":{"id":"66087","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.898587,39.761522],[-95.021897,39.893974],[-95.041055,39.679417],[-95.021522,39.67065],[-94.907014,39.725101],[-94.898587,39.761522]]]},"properties":{"id":"66090","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.611929,38.768051],[-95.630673,38.709823],[-95.581862,38.695273],[-95.500804,38.724252],[-95.399346,38.738868],[-95.371394,38.738801],[-95.381211,38.814603],[-95.43746,38.897398],[-95.437797,38.897684],[-95.575709,38.869653],[-95.611929,38.768051]]]},"properties":{"id":"66524","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.260466,38.953627],[-95.260482,38.950837],[-95.255863,38.955837],[-95.245822,38.956675],[-95.244079,38.964324],[-95.251056,38.963407],[-95.251105,38.958891],[-95.260475,38.956912],[-95.260466,38.953627]]]},"properties":{"id":"66045","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.317865,38.00053],[-99.30503,37.733705],[-99.178096,37.733546],[-99.096218,37.839726],[-99.150582,37.999825],[-99.317865,38.00053]]]},"properties":{"id":"67552","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.438005,38.509412],[-99.357654,38.696803],[-99.560564,38.725557],[-99.585087,38.696537],[-99.660266,38.523984],[-99.586011,38.473169],[-99.493362,38.509386],[-99.438005,38.509412]]]},"properties":{"id":"67556","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.991454,37.913078],[-99.797145,37.680769],[-99.760418,37.705439],[-99.873763,37.913214],[-99.991454,37.913078]]]},"properties":{"id":"67882","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.500804,38.724252],[-95.490035,38.506496],[-95.397057,38.550227],[-95.399346,38.738868],[-95.500804,38.724252]]]},"properties":{"id":"66076","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.07487,38.476968],[-95.010464,38.389457],[-94.973491,38.389308],[-94.941471,38.403693],[-94.899843,38.446958],[-95.065074,38.566195],[-95.07487,38.476968]]]},"properties":{"id":"66064","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.833294,39.307102],[-101.019406,39.133037],[-101.036329,38.959849],[-100.955775,38.786003],[-100.762964,38.787802],[-100.721278,39.263566],[-100.833294,39.307102]]]},"properties":{"id":"67748","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.067938,37.83715],[-96.068,37.830169],[-96.031672,37.837114],[-96.067938,37.83715]]],[[[-96.067938,37.83715],[-96.081915,37.906479],[-96.458909,38.086315],[-96.522782,38.08637],[-96.523853,37.970642],[-96.525925,37.792368],[-96.525308,37.73765],[-96.327799,37.664722],[-96.172423,37.729828],[-96.125049,37.750811],[-96.102625,37.83634],[-96.067938,37.83715]]]]},"properties":{"id":"67045","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.148108,37.209968],[-98.274841,37.094315],[-98.23726,37.03589],[-98.177732,36.997939],[-97.911462,36.998621],[-97.911659,37.124238],[-97.893051,37.196968],[-98.148108,37.209968]]]},"properties":{"id":"67003","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.916464,37.385577],[-98.245376,37.42183],[-98.258272,37.355135],[-98.148108,37.209968],[-97.893051,37.196968],[-97.893872,37.240518],[-97.859194,37.371337],[-97.859413,37.385958],[-97.916464,37.385577]]]},"properties":{"id":"67058","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.263221,37.926883],[-97.518834,37.842594],[-97.481873,37.825076],[-97.426942,37.810006],[-97.420193,37.807589],[-97.339254,37.825023],[-97.294874,37.81047],[-97.245051,37.795905],[-97.170709,37.840052],[-97.152612,37.928794],[-97.219055,37.933848],[-97.263221,37.926883]]]},"properties":{"id":"67147","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.766376,39.21653],[-95.673049,39.21644],[-95.619049,39.216185],[-95.598611,39.303171],[-95.62663,39.332254],[-95.766376,39.21653]]]},"properties":{"id":"66440","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.08909,39.566124],[-96.034729,39.390803],[-95.997988,39.368911],[-95.923258,39.390359],[-95.904708,39.404876],[-95.923508,39.492514],[-95.920138,39.580484],[-96.03295,39.580595],[-96.089035,39.609618],[-96.089072,39.595141],[-96.08909,39.566124]]]},"properties":{"id":"66540","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.180142,39.288693],[-95.254686,39.245251],[-95.255869,39.113158],[-95.187811,39.102131],[-95.133702,39.17235],[-95.083279,39.259625],[-95.180142,39.288693]]]},"properties":{"id":"66054","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.931884,39.691245],[-98.06283,39.697166],[-98.09431,39.567126],[-97.931844,39.566921],[-97.931884,39.691245]]]},"properties":{"id":"66963","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.931921,39.697212],[-97.819473,39.711669],[-97.835858,39.871092],[-97.931825,40.002072],[-97.988439,40.002192],[-97.988082,39.913909],[-97.931921,39.697212]]]},"properties":{"id":"66939","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.658297,38.978569],[-94.705016,38.978376],[-94.704748,38.934949],[-94.667562,38.934239],[-94.658244,38.934782],[-94.658297,38.978569]]]},"properties":{"id":"66212","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.658844,39.044038],[-94.723892,39.043998],[-94.718682,38.99287],[-94.705099,38.992965],[-94.693077,39.007633],[-94.658844,39.044038]]]},"properties":{"id":"66203","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.658297,38.978569],[-94.658244,38.934782],[-94.630234,38.932806],[-94.630319,38.978704],[-94.658297,38.978569]]]},"properties":{"id":"66207","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.667739,38.912866],[-94.742423,38.912854],[-94.742364,38.883646],[-94.686496,38.886506],[-94.667802,38.886675],[-94.667739,38.912866]]]},"properties":{"id":"66213","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.789706,37.561776],[-97.880231,37.604504],[-97.880582,37.554167],[-97.935478,37.473074],[-97.916464,37.385577],[-97.859413,37.385958],[-97.807057,37.386293],[-97.789706,37.561776]]]},"properties":{"id":"67118","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.245376,37.42183],[-97.916464,37.385577],[-97.935478,37.473074],[-98.282184,37.55825],[-98.318668,37.543909],[-98.318816,37.529481],[-98.245376,37.42183]]]},"properties":{"id":"67142","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.228545,37.776243],[-98.282184,37.55825],[-97.935478,37.473074],[-97.880582,37.554167],[-97.972822,37.696901],[-98.118832,37.806037],[-98.228545,37.776243]]]},"properties":{"id":"67068","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.458584,37.384073],[-98.349804,37.384056],[-98.318816,37.529481],[-98.318668,37.543909],[-98.464748,37.528986],[-98.464951,37.384086],[-98.458584,37.384073]]]},"properties":{"id":"67112","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.807378,39.123633],[-94.745597,39.128542],[-94.735479,39.128516],[-94.741858,39.170318],[-94.83479,39.221035],[-94.83623,39.219027],[-94.900478,39.201501],[-94.901398,39.128632],[-94.83514,39.123282],[-94.807378,39.123633]]]},"properties":{"id":"66109","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.95627,39.030883],[-97.928681,38.900126],[-97.817316,38.899963],[-97.64083,38.957999],[-97.705332,39.001614],[-97.92884,39.117973],[-97.95627,39.030883]]]},"properties":{"id":"67484","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.955775,38.786003],[-101.036329,38.959849],[-101.145259,39.133822],[-101.204952,39.235973],[-101.298263,39.178887],[-101.349409,39.135309],[-101.446165,38.758425],[-101.2619,38.70149],[-101.192651,38.700753],[-100.955775,38.786003]]]},"properties":{"id":"67764","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.145259,39.133822],[-101.036329,38.959849],[-101.019406,39.133037],[-101.145259,39.133822]]]},"properties":{"id":"67747","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.188349,38.579161],[-96.260889,38.608134],[-96.35378,38.521657],[-96.188349,38.579161]]]},"properties":{"id":"66835","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.132809,38.578823],[-96.079122,38.521081],[-96.069832,38.651669],[-96.132809,38.578823]]]},"properties":{"id":"66830","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.574037,38.501169],[-97.773496,38.492901],[-97.869233,38.464242],[-97.832899,38.304195],[-97.647449,38.232065],[-97.593017,38.26266],[-97.574037,38.501169]]]},"properties":{"id":"67460","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.993271,38.248603],[-96.837708,38.30481],[-96.819475,38.348502],[-96.819628,38.421045],[-97.095894,38.507624],[-97.165263,38.449662],[-97.166516,38.447375],[-97.150703,38.260867],[-96.993271,38.248603]]]},"properties":{"id":"66861","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.590433,39.553463],[-96.557578,39.480768],[-96.351471,39.56616],[-96.344559,39.791015],[-96.370048,39.841895],[-96.463613,39.823477],[-96.482546,39.823563],[-96.482306,39.768757],[-96.53888,39.711111],[-96.590433,39.553463]]]},"properties":{"id":"66427","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.759978,38.679788],[-94.714097,38.462117],[-94.61327,38.446674],[-94.610093,38.694461],[-94.740871,38.687127],[-94.759978,38.679788]]]},"properties":{"id":"66053","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.96427,36.999104],[-95.819366,36.999166],[-95.787508,37.033252],[-95.800994,37.033316],[-95.819656,37.051736],[-95.801169,37.080626],[-95.855569,37.110185],[-95.964571,37.067579],[-95.96427,36.999104]]]},"properties":{"id":"67333","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.709102,38.522022],[-96.666711,38.521952],[-96.597554,38.521974],[-96.649418,38.710442],[-96.708024,38.681761],[-96.80069,38.60954],[-96.709102,38.522022]]]},"properties":{"id":"66873","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.325986,37.594465],[-95.288331,37.456647],[-95.232954,37.4713],[-95.160874,37.573067],[-95.143091,37.660144],[-95.19736,37.732539],[-95.306441,37.732762],[-95.325986,37.594465]]]},"properties":{"id":"66733","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.123774,37.384119],[-95.087694,37.471335],[-95.087921,37.485724],[-95.160874,37.573067],[-95.232954,37.4713],[-95.123774,37.384119]]]},"properties":{"id":"66771","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.68347,39.799216],[-99.477951,39.756073],[-99.459252,39.871758],[-99.627153,39.87206],[-99.68347,39.799216]]]},"properties":{"id":"67664","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.508439,38.477445],[-95.61984,38.550697],[-95.69175,38.516572],[-95.691886,38.434062],[-95.508199,38.448453],[-95.508439,38.477445]]]},"properties":{"id":"66510","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.459252,39.871758],[-99.477951,39.756073],[-99.460364,39.567514],[-99.383912,39.567397],[-99.240811,39.683674],[-99.178704,39.727224],[-99.104682,39.930248],[-99.104461,40.002168],[-99.461435,40.002072],[-99.459252,39.871758]]]},"properties":{"id":"67661","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.240811,39.683674],[-99.265502,39.567412],[-99.137946,39.567993],[-99.066203,39.568093],[-99.066293,39.654845],[-99.122679,39.727309],[-99.178704,39.727224],[-99.240811,39.683674]]]},"properties":{"id":"67644","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.204368,39.347709],[-96.242632,39.130119],[-96.147539,39.158842],[-96.136234,39.190099],[-96.124218,39.283485],[-96.184002,39.344001],[-96.204368,39.347709]]]},"properties":{"id":"66407","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.519789,39.319898],[-96.394611,39.176831],[-96.390072,39.304542],[-96.519789,39.319898]]]},"properties":{"id":"66535","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.721258,37.824808],[-98.849541,37.82528],[-98.849136,37.777139],[-98.682001,37.718477],[-98.721258,37.824808]]]},"properties":{"id":"67066","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.204952,39.235973],[-101.145259,39.133822],[-101.019406,39.133037],[-100.833294,39.307102],[-100.850857,39.423321],[-100.942872,39.567984],[-101.206447,39.655303],[-101.22605,39.62635],[-101.241957,39.308727],[-101.242122,39.279846],[-101.204952,39.235973]]]},"properties":{"id":"67701","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.142218,38.043052],[-98.179067,38.072062],[-98.252119,38.071777],[-98.270541,37.926829],[-98.160551,37.941372],[-98.142218,38.043052]]]},"properties":{"id":"67510","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.53732,39.6536],[-97.368562,39.741006],[-97.533009,39.769517],[-97.561693,39.65355],[-97.53732,39.6536]]]},"properties":{"id":"66930","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.80098,40.002055],[-97.706989,39.943736],[-97.669234,40.002133],[-97.80098,40.002055]]]},"properties":{"id":"68325","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.514357,40.001884],[-97.462888,39.885939],[-97.368773,39.856057],[-97.369215,39.929401],[-97.369199,40.001966],[-97.514357,40.001884]]]},"properties":{"id":"66960","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.701254,39.119179],[-96.625282,39.280126],[-96.717139,39.40719],[-96.756519,39.382],[-96.756237,39.334746],[-96.763133,39.240791],[-96.719655,39.131513],[-96.722003,39.124176],[-96.701254,39.119179]]]},"properties":{"id":"66503","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.937705,39.783728],[-96.93182,39.566439],[-96.902678,39.536587],[-96.806544,39.566423],[-96.806552,39.737526],[-96.936816,39.812915],[-96.937705,39.783728]]]},"properties":{"id":"66933","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.584794,38.349386],[-99.509851,38.349355],[-99.493362,38.509386],[-99.586011,38.473169],[-99.584794,38.349386]]]},"properties":{"id":"67513","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.856148,38.60961],[-97.801152,38.609446],[-97.761722,38.7112],[-97.814864,38.697136],[-97.856148,38.60961]]]},"properties":{"id":"67442","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.406217,37.520276],[-97.32381,37.506291],[-97.28829,37.520626],[-97.307035,37.56439],[-97.334037,37.573493],[-97.370502,37.580403],[-97.42554,37.578494],[-97.406217,37.520276]]]},"properties":{"id":"67060","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.590709,37.721548],[-97.601321,37.807855],[-97.680929,37.74963],[-97.625592,37.721374],[-97.590709,37.721548]]]},"properties":{"id":"67001","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.386487,37.725661],[-97.337493,37.722588],[-97.339254,37.825023],[-97.420193,37.807589],[-97.386487,37.725661]]]},"properties":{"id":"67204","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.481103,37.722383],[-97.480666,37.684008],[-97.390576,37.683886],[-97.389381,37.683907],[-97.389668,37.722652],[-97.481103,37.722383]]]},"properties":{"id":"67212","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.299133,37.768177],[-97.299048,37.722765],[-97.293692,37.722815],[-97.286066,37.722887],[-97.262302,37.724958],[-97.262309,37.726816],[-97.262524,37.781353],[-97.299133,37.768177]]]},"properties":{"id":"67220","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.480261,37.615],[-97.42554,37.578494],[-97.370502,37.580403],[-97.398856,37.646546],[-97.480854,37.65015],[-97.48081,37.64904],[-97.480261,37.615]]]},"properties":{"id":"67215","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.715983,37.489138],[-97.587794,37.475166],[-97.534206,37.591667],[-97.634994,37.590794],[-97.669872,37.590419],[-97.71644,37.529957],[-97.715983,37.489138]]]},"properties":{"id":"67149","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.518909,37.882329],[-97.509711,37.883329],[-97.509568,37.890632],[-97.518049,37.891145],[-97.518909,37.882329]]]},"properties":{"id":"67016","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.683789,39.00063],[-95.614394,38.986112],[-95.595702,38.986015],[-95.613939,39.030657],[-95.673453,39.029754],[-95.675434,39.02973],[-95.683789,39.00063]]]},"properties":{"id":"66605","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.743476,38.95659],[-95.73171,38.94248],[-95.674427,38.933811],[-95.654139,38.957096],[-95.71564,38.967179],[-95.743476,38.95659]]]},"properties":{"id":"66619","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.724972,39.024061],[-95.719616,39.023833],[-95.719913,39.028342],[-95.724931,39.029489],[-95.724972,39.024061]]]},"properties":{"id":"66622","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.965513,38.906451],[-96.131137,38.739069],[-96.019959,38.738918],[-95.909347,38.870001],[-95.946747,38.906432],[-95.965513,38.906451]]]},"properties":{"id":"66431","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.986426,39.698343],[-99.066293,39.654845],[-99.066203,39.568093],[-99.044398,39.568035],[-98.916443,39.567986],[-98.935123,39.683826],[-98.986426,39.698343]]]},"properties":{"id":"67628","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.049561,39.740672],[-97.049668,39.726124],[-97.030869,39.731306],[-97.049561,39.740672]]],[[[-97.049561,39.740672],[-96.937705,39.783728],[-96.936816,39.812915],[-96.993533,39.929319],[-97.107126,39.944338],[-97.218389,39.755636],[-97.068454,39.726144],[-97.049561,39.740672]]]]},"properties":{"id":"66968","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.724077,37.598661],[-95.632856,37.652992],[-95.77106,37.646626],[-95.724077,37.598661]]]},"properties":{"id":"66714","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.922282,37.93811],[-95.83297,37.734011],[-95.779203,37.733804],[-95.614863,37.733046],[-95.525222,37.747338],[-95.555639,37.834645],[-95.628762,37.980506],[-95.702459,38.039342],[-95.738904,38.039136],[-95.922282,37.93811]]]},"properties":{"id":"66783","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.087883,37.774356],[-95.281068,37.82009],[-95.3064,37.818181],[-95.306327,37.79103],[-95.087883,37.774356]]]},"properties":{"id":"66732","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.508439,38.477445],[-95.508199,38.448453],[-95.508256,38.375758],[-95.378628,38.390165],[-95.340934,38.419236],[-95.355624,38.510733],[-95.397057,38.550227],[-95.490035,38.506496],[-95.508439,38.477445]]]},"properties":{"id":"66095","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.702459,38.039342],[-95.628762,37.980506],[-95.519163,37.979036],[-95.519132,37.988486],[-95.518798,38.068053],[-95.702459,38.039342]]]},"properties":{"id":"66758","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.570395,39.404568],[-95.589059,39.303141],[-95.517698,39.252427],[-95.413887,39.273848],[-95.311193,39.288711],[-95.329211,39.33284],[-95.394705,39.427673],[-95.469136,39.449646],[-95.570395,39.404568]]]},"properties":{"id":"66088","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.923039,36.999558],[-98.604158,36.998841],[-98.640103,37.138608],[-98.847997,37.15213],[-98.923039,36.999558]]]},"properties":{"id":"67057","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.681614,37.384265],[-98.756892,37.383733],[-98.885968,37.384128],[-99.002142,37.280412],[-99.000902,37.110428],[-99.000301,36.999467],[-98.923039,36.999558],[-98.847997,37.15213],[-98.681614,37.384265]]]},"properties":{"id":"67071","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.604158,36.998841],[-98.363776,36.998034],[-98.495068,37.166829],[-98.531327,37.167214],[-98.640103,37.138608],[-98.604158,36.998841]]]},"properties":{"id":"67070","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.904571,38.652182],[-98.848561,38.477822],[-98.646227,38.448721],[-98.57206,38.696833],[-98.578733,38.696845],[-98.74575,38.696777],[-98.904571,38.652182]]]},"properties":{"id":"67544","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.726203,38.740332],[-98.779656,38.991016],[-98.873107,39.060032],[-99.027075,39.04581],[-99.0385,39.016173],[-99.041987,38.729674],[-99.042626,38.696807],[-98.959228,38.652782],[-98.904571,38.652182],[-98.74575,38.696777],[-98.726203,38.740332]]]},"properties":{"id":"67665","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.461801,38.463668],[-98.480083,38.565398],[-98.479958,38.696916],[-98.57206,38.696833],[-98.646227,38.448721],[-98.631441,38.44077],[-98.461801,38.463668]]]},"properties":{"id":"67525","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.906125,37.950342],[-94.955853,37.761683],[-94.860243,37.950315],[-94.906125,37.950342]]]},"properties":{"id":"66769","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.667407,37.687822],[-94.617966,37.682217],[-94.617645,37.761243],[-94.667407,37.687822]]]},"properties":{"id":"66741","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.998699,37.745334],[-96.844709,37.693994],[-96.661152,37.751962],[-96.620735,37.955765],[-96.767408,37.984515],[-96.932287,37.905187],[-96.968833,37.886248],[-96.998699,37.745334]]]},"properties":{"id":"67042","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.080377,37.635997],[-97.053154,37.743262],[-97.061991,37.752429],[-97.152939,37.741872],[-97.152941,37.723292],[-97.152964,37.650313],[-97.153048,37.635768],[-97.080377,37.635997]]]},"properties":{"id":"67002","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.639858,38.21334],[-96.473787,38.172381],[-96.355336,38.292658],[-96.43109,38.392218],[-96.597982,38.38756],[-96.653616,38.300332],[-96.639858,38.21334]]]},"properties":{"id":"66845","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.819723,38.522464],[-96.709102,38.522022],[-96.80069,38.60954],[-96.893495,38.566183],[-96.893383,38.522463],[-96.819723,38.522464]]]},"properties":{"id":"66838","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.964571,37.067579],[-95.855569,37.110185],[-95.927907,37.197703],[-96.017186,37.179397],[-96.023258,37.09485],[-96.009516,37.080267],[-95.964571,37.067579]]]},"properties":{"id":"67347","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.041312,37.259524],[-94.908209,37.266811],[-94.923028,37.281688],[-94.932211,37.28174],[-94.932114,37.288887],[-94.923018,37.289593],[-94.904863,37.296266],[-94.904824,37.368715],[-95.087694,37.471335],[-95.123774,37.384119],[-95.108642,37.304523],[-95.041312,37.259524]]]},"properties":{"id":"66753","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.704438,37.177901],[-94.704339,37.163191],[-94.686659,37.168398],[-94.704438,37.177901]]]},"properties":{"id":"66728","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.888174,37.580658],[-100.10708,37.562116],[-100.091622,37.285597],[-100.000619,37.28458],[-99.8342,37.36032],[-99.888174,37.580658]]]},"properties":{"id":"67865","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.350527,39.755647],[-97.368814,39.45691],[-97.1453,39.524303],[-97.126195,39.539212],[-97.218948,39.668228],[-97.256148,39.741066],[-97.350527,39.755647]]]},"properties":{"id":"66937","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-97.427031,39.175472],[-97.460235,39.110861],[-97.371145,39.10297],[-97.371154,39.131961],[-97.427031,39.175472]]],[[[-97.185224,39.205853],[-97.333806,39.132273],[-97.185127,39.147901],[-97.185224,39.205853]]]]},"properties":{"id":"67458","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.819473,39.711669],[-97.931921,39.697212],[-97.931884,39.691245],[-97.931844,39.566921],[-97.891608,39.48116],[-97.816318,39.481538],[-97.786067,39.653729],[-97.819473,39.711669]]]},"properties":{"id":"66948","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.610492,39.364741],[-97.666194,39.364842],[-97.901379,39.233799],[-97.593423,39.219418],[-97.610492,39.364741]]]},"properties":{"id":"67436","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.396675,36.999838],[-99.000301,36.999467],[-99.000902,37.110428],[-99.301963,37.440123],[-99.410625,37.381813],[-99.512523,37.381158],[-99.396675,36.999838]]]},"properties":{"id":"67029","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.000902,37.110428],[-99.002142,37.280412],[-99.001429,37.377302],[-99.165062,37.435559],[-99.193638,37.450268],[-99.301963,37.440123],[-99.000902,37.110428]]]},"properties":{"id":"67155","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.861076,37.389078],[-96.815944,37.186259],[-96.71568,37.244146],[-96.677236,37.417623],[-96.822409,37.394255],[-96.861076,37.389078]]]},"properties":{"id":"67019","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.760146,37.580346],[-94.724315,37.513816],[-94.686246,37.528222],[-94.668193,37.572121],[-94.741302,37.601121],[-94.760146,37.580346]]]},"properties":{"id":"66712","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.62862,39.654613],[-100.441681,39.480912],[-100.32939,39.567607],[-100.404323,39.712268],[-100.62862,39.654613]]]},"properties":{"id":"67635","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.741099,39.654759],[-100.608292,39.379137],[-100.441681,39.480912],[-100.62862,39.654613],[-100.741099,39.654759]]]},"properties":{"id":"67757","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.096159,38.609357],[-97.095728,38.536644],[-97.040661,38.55107],[-96.985593,38.595058],[-97.022483,38.628957],[-97.096159,38.609357]]]},"properties":{"id":"67475","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.172423,37.729828],[-96.142588,37.543866],[-96.042854,37.459831],[-95.962507,37.446015],[-95.960689,37.719742],[-96.125049,37.750811],[-96.172423,37.729828]]]},"properties":{"id":"67047","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.426576,39.059378],[-99.560564,38.725557],[-99.357654,38.696803],[-99.32766,38.696831],[-99.284489,38.696861],[-99.25371,38.696934],[-99.227892,38.696935],[-99.134314,38.740524],[-99.204746,39.016337],[-99.222566,39.059341],[-99.426576,39.059378]],[[-99.33682,38.714114],[-99.329276,38.715278],[-99.328805,38.711581],[-99.336948,38.711468],[-99.33682,38.714114]]]},"properties":{"id":"67601","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.652251,37.736275],[-100.524843,37.708006],[-100.4335,37.737254],[-100.46336,38.117191],[-100.664927,37.87936],[-100.652251,37.736275]]]},"properties":{"id":"67853","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.955775,38.786003],[-101.192651,38.700753],[-101.127611,38.409671],[-101.125438,38.264509],[-101.103268,38.264557],[-100.929449,38.221571],[-100.685272,38.366235],[-100.68642,38.48453],[-100.679171,38.700038],[-100.762964,38.787802],[-100.955775,38.786003]]]},"properties":{"id":"67871","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.706077,39.09126],[-96.706818,39.098688],[-96.722003,39.124176],[-96.719655,39.131513],[-96.830985,39.139228],[-96.869817,39.072354],[-96.706077,39.09126]]]},"properties":{"id":"66442","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.721278,39.263566],[-100.762964,38.787802],[-100.679171,38.700038],[-100.597282,38.700087],[-100.537572,39.003214],[-100.563532,39.219897],[-100.591398,39.262762],[-100.721278,39.263566]]]},"properties":{"id":"67738","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.685272,38.366235],[-100.464287,38.263111],[-100.244393,38.26229],[-100.244425,38.291356],[-100.286353,38.552825],[-100.329494,38.716677],[-100.541184,38.699991],[-100.68642,38.48453],[-100.685272,38.366235]]]},"properties":{"id":"67839","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.662714,39.279418],[-99.74408,39.248724],[-99.773008,39.183168],[-99.70608,39.132268],[-99.534017,39.132504],[-99.51191,39.321015],[-99.662714,39.279418]]]},"properties":{"id":"67657","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.662714,39.279418],[-99.51191,39.321015],[-99.492489,39.379455],[-99.602279,39.408681],[-99.662714,39.279418]]]},"properties":{"id":"67632","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.460364,39.567514],[-99.477951,39.756073],[-99.68347,39.799216],[-99.702957,39.741081],[-99.732178,39.538977],[-99.69563,39.545437],[-99.602074,39.469525],[-99.460364,39.567514]]]},"properties":{"id":"67646","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067739,37.997627],[-96.081915,37.906479],[-96.067938,37.83715],[-96.031672,37.837114],[-95.958565,37.902103],[-96.030825,38.055441],[-96.067739,37.997627]]]},"properties":{"id":"66870","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.258272,37.355135],[-98.349492,37.310973],[-98.348436,37.18154],[-98.274841,37.094315],[-98.148108,37.209968],[-98.258272,37.355135]]]},"properties":{"id":"67009","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.23726,37.03589],[-98.237211,36.997965],[-98.177732,36.997939],[-98.23726,37.03589]]]},"properties":{"id":"67150","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.720388,38.064739],[-97.812036,37.967627],[-97.702178,37.907757],[-97.592143,37.866861],[-97.610517,37.955991],[-97.533254,38.115906],[-97.619979,38.130225],[-97.720388,38.064739]]]},"properties":{"id":"67020","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.016561,39.265983],[-95.998446,39.216527],[-95.8871,39.216958],[-95.849206,39.216508],[-95.923258,39.390359],[-95.997988,39.368911],[-96.016561,39.265983]]]},"properties":{"id":"66418","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.046542,40.002186],[-98.099076,39.943635],[-98.043206,39.888502],[-97.988082,39.913909],[-97.988439,40.002192],[-98.046542,40.002186]]]},"properties":{"id":"66970","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.729035,40.002321],[-98.654298,39.683344],[-98.607499,39.639974],[-98.504647,39.683528],[-98.467373,39.69786],[-98.504455,40.002291],[-98.729035,40.002321]]]},"properties":{"id":"66952","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.769814,38.914798],[-94.871376,38.816773],[-94.861842,38.78186],[-94.742196,38.792868],[-94.686354,38.803612],[-94.70518,38.840094],[-94.742364,38.883646],[-94.742423,38.912854],[-94.74227,38.920517],[-94.764142,38.920065],[-94.769814,38.914798]]]},"properties":{"id":"66062","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.395602,37.73397],[-99.410625,37.381813],[-99.301963,37.440123],[-99.193638,37.450268],[-99.178096,37.733546],[-99.30503,37.733705],[-99.395602,37.73397]]]},"properties":{"id":"67054","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.594636,37.18076],[-95.663804,37.144045],[-95.476545,37.134919],[-95.503725,37.158514],[-95.594636,37.18076]]]},"properties":{"id":"67351","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.83514,39.123282],[-94.901398,39.128632],[-94.964253,39.116321],[-95.001543,39.058278],[-94.927608,39.002784],[-94.908765,38.991401],[-94.871745,39.059315],[-94.83514,39.123282]]]},"properties":{"id":"66012","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.567094,38.699669],[-101.567482,38.263145],[-101.447696,38.263626],[-101.158356,38.264441],[-101.125438,38.264509],[-101.127611,38.409671],[-101.2619,38.70149],[-101.446165,38.758425],[-101.55854,38.699769],[-101.567094,38.699669]]]},"properties":{"id":"67861","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.482224,39.899415],[-96.482554,39.928465],[-96.48254,39.942991],[-96.638588,40.001305],[-96.731102,40.001439],[-96.788139,39.812988],[-96.683815,39.740363],[-96.53888,39.711111],[-96.482306,39.768757],[-96.501391,39.823625],[-96.482312,39.870435],[-96.482224,39.899415]]]},"properties":{"id":"66508","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.805768,40.001495],[-96.806328,39.813037],[-96.788139,39.812988],[-96.731102,40.001439],[-96.805768,40.001495]]]},"properties":{"id":"66412","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.445494,40.001013],[-96.351264,39.899927],[-96.314591,40.000901],[-96.445494,40.001013]]]},"properties":{"id":"66541","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.470518,37.416428],[-100.494034,37.000877],[-100.089886,37.065694],[-100.091663,37.182842],[-100.343112,37.474803],[-100.470518,37.416428]]]},"properties":{"id":"67864","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.543154,37.44563],[-100.742358,37.387596],[-100.742556,37.373112],[-100.633354,37.051598],[-100.633327,37.000103],[-100.494034,37.000877],[-100.470518,37.416428],[-100.543154,37.44563]]]},"properties":{"id":"67869","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.759978,38.679788],[-94.964042,38.702009],[-94.993244,38.694842],[-95.065097,38.622856],[-95.065074,38.566195],[-94.899843,38.446958],[-94.793441,38.440304],[-94.714097,38.462117],[-94.759978,38.679788]]]},"properties":{"id":"66071","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.801169,37.080626],[-95.819656,37.051736],[-95.800994,37.033316],[-95.801169,37.080626]]]},"properties":{"id":"67364","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.827318,37.156103],[-101.663874,36.994774],[-101.555259,36.995324],[-101.520244,37.388376],[-101.52581,37.388392],[-101.767393,37.388788],[-101.827318,37.156103]]]},"properties":{"id":"67954","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.889348,37.389008],[-101.925074,37.738106],[-102.041664,37.687487],[-102.041642,37.38919],[-101.889348,37.389008]]]},"properties":{"id":"67862","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.182866,39.769972],[-96.239195,39.609702],[-96.10781,39.595176],[-96.089035,39.609618],[-96.033095,39.725633],[-96.145205,39.783887],[-96.182866,39.769972]]]},"properties":{"id":"66415","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.147167,40.000479],[-96.145205,39.783887],[-96.033095,39.725633],[-96.007536,39.725437],[-95.920443,39.783392],[-95.935345,39.865658],[-95.944183,39.863146],[-95.94421,39.870332],[-95.936872,39.868259],[-95.977017,39.892091],[-96.050849,40.000695],[-96.147167,40.000479]]]},"properties":{"id":"66538","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.050849,40.000695],[-95.977017,39.892091],[-95.882585,40.000475],[-96.050849,40.000695]]]},"properties":{"id":"66408","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.201525,40.000852],[-96.239595,39.805492],[-96.182866,39.769972],[-96.145205,39.783887],[-96.147167,40.000479],[-96.201525,40.000852]]]},"properties":{"id":"66404","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.936872,39.868259],[-95.94421,39.870332],[-95.944183,39.863146],[-95.935345,39.865658],[-95.936872,39.868259]]]},"properties":{"id":"66522","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.68347,39.799216],[-99.627153,39.87206],[-99.644195,40.001821],[-99.832217,40.001563],[-99.758899,39.740859],[-99.702957,39.741081],[-99.68347,39.799216]]]},"properties":{"id":"67622","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.758899,39.740859],[-99.832217,40.001563],[-100.056433,40.001632],[-100.133458,39.791638],[-100.063127,39.653805],[-99.758899,39.740859]]]},"properties":{"id":"67654","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.630673,38.709823],[-95.683224,38.675721],[-95.750414,38.515136],[-95.69175,38.516572],[-95.61984,38.550697],[-95.564241,38.623106],[-95.620059,38.622928],[-95.637397,38.664924],[-95.565459,38.646738],[-95.581862,38.695273],[-95.630673,38.709823]]]},"properties":{"id":"66451","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.79781,38.818193],[-95.611929,38.768051],[-95.575709,38.869653],[-95.649687,38.869861],[-95.835532,38.86926],[-95.83577,38.869262],[-95.79781,38.818193]]]},"properties":{"id":"66414","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.459884,38.088159],[-99.368675,38.087723],[-99.277481,38.15984],[-99.253237,38.30482],[-99.380321,38.3494],[-99.47362,38.276695],[-99.459884,38.088159]]]},"properties":{"id":"67574","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.509851,38.349355],[-99.47362,38.276695],[-99.380321,38.3494],[-99.419488,38.480516],[-99.438005,38.509412],[-99.493362,38.509386],[-99.509851,38.349355]]]},"properties":{"id":"67559","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.935746,39.915527],[-98.954495,39.915577],[-98.935728,39.901069],[-98.935746,39.915527]]],[[[-98.935746,39.915527],[-98.934847,40.002181],[-99.104461,40.002168],[-99.104682,39.930248],[-99.122679,39.727309],[-99.066293,39.654845],[-98.986426,39.698343],[-98.954495,39.915577],[-98.935746,39.915527]]]]},"properties":{"id":"66951","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.590433,39.553463],[-96.68409,39.478797],[-96.717139,39.40719],[-96.625282,39.280126],[-96.51985,39.392135],[-96.557578,39.480768],[-96.590433,39.553463]]]},"properties":{"id":"66520","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.811695,38.086447],[-98.032571,38.085936],[-98.08748,38.035458],[-98.050301,37.883126],[-97.866639,37.8685],[-97.812036,37.967627],[-97.720388,38.064739],[-97.811695,38.086447]],[[-97.955015,38.043845],[-97.932887,38.039035],[-97.930539,38.020478],[-97.94937,38.01316],[-97.955015,38.043845]]]},"properties":{"id":"67501","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.955015,38.043845],[-97.94937,38.01316],[-97.930539,38.020478],[-97.932887,38.039035],[-97.955015,38.043845]]]},"properties":{"id":"67505","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.417774,38.173116],[-98.257218,38.275208],[-98.330904,38.27518],[-98.417774,38.173116]]]},"properties":{"id":"67512","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.756519,39.382],[-96.913478,39.484593],[-96.941982,39.394057],[-96.960875,39.335814],[-96.756237,39.334746],[-96.756519,39.382]]]},"properties":{"id":"66449","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.726203,38.740332],[-98.66269,38.958758],[-98.705282,39.031009],[-98.742572,39.031243],[-98.779656,38.991016],[-98.726203,38.740332]]]},"properties":{"id":"67626","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.298746,37.65001],[-97.286348,37.59347],[-97.189654,37.621182],[-97.175512,37.650224],[-97.189515,37.65014],[-97.271748,37.650092],[-97.298746,37.65001]]]},"properties":{"id":"67210","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.298955,37.6863],[-97.325252,37.686072],[-97.338127,37.679088],[-97.337451,37.649714],[-97.298746,37.65001],[-97.298955,37.6863]]]},"properties":{"id":"67211","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.338127,37.679088],[-97.325252,37.686072],[-97.336893,37.693461],[-97.343905,37.685411],[-97.338127,37.679088]]]},"properties":{"id":"67202","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.481873,37.825076],[-97.499232,37.758258],[-97.480831,37.751426],[-97.426942,37.810006],[-97.481873,37.825076]]]},"properties":{"id":"67101","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.516708,37.649769],[-97.516891,37.621412],[-97.480261,37.615],[-97.48081,37.64904],[-97.516708,37.649769]]]},"properties":{"id":"67227","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.933045,37.257204],[-101.011787,37.315122],[-101.089653,37.387721],[-101.321445,37.388114],[-101.1039,37.199062],[-100.933045,37.257204]]]},"properties":{"id":"67952","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.976579,39.112245],[-95.909668,39.087854],[-95.8871,39.216958],[-95.998446,39.216527],[-96.03906,39.126527],[-95.976579,39.112245]]]},"properties":{"id":"66533","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.576274,39.568455],[-101.51324,39.134655],[-101.391717,39.135117],[-101.510602,39.568373],[-101.576274,39.568455]]]},"properties":{"id":"67733","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.61935,38.07757],[-98.710606,37.824744],[-98.471956,37.894422],[-98.47267,38.121977],[-98.472794,38.172757],[-98.472672,38.201983],[-98.527704,38.201758],[-98.61935,38.07757]]]},"properties":{"id":"67578","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.085712,38.986425],[-96.147539,39.158842],[-96.242632,39.130119],[-96.242377,39.086674],[-96.23487,39.05789],[-96.230631,39.053356],[-96.131933,38.978895],[-96.085712,38.986425]]]},"properties":{"id":"66526","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.561569,37.558716],[-95.614576,37.653597],[-95.632856,37.652992],[-95.724077,37.598661],[-95.719818,37.500919],[-95.597206,37.476999],[-95.561569,37.558716]]]},"properties":{"id":"66710","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.555639,37.834645],[-95.525222,37.747338],[-95.324725,37.732925],[-95.306327,37.79103],[-95.3064,37.818181],[-95.317709,37.82021],[-95.519224,37.863755],[-95.555639,37.834645]]]},"properties":{"id":"66748","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.39739,38.242364],[-95.213771,38.168648],[-95.076751,38.182665],[-95.06525,38.287654],[-95.065128,38.302168],[-95.194784,38.390198],[-95.378628,38.390165],[-95.508256,38.375758],[-95.507968,38.346583],[-95.39739,38.242364]]]},"properties":{"id":"66032","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.610224,38.176478],[-95.518549,38.096367],[-95.426072,38.154805],[-95.39739,38.242364],[-95.507968,38.346583],[-95.59201,38.259371],[-95.610224,38.176478]]]},"properties":{"id":"66093","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.157164,38.433632],[-95.156987,38.462749],[-95.340934,38.419236],[-95.378628,38.390165],[-95.194784,38.390198],[-95.157164,38.433632]]]},"properties":{"id":"66080","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.001429,37.377302],[-99.002142,37.280412],[-98.885968,37.384128],[-99.001429,37.377302]]]},"properties":{"id":"67143","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.959228,38.652782],[-99.014609,38.478474],[-98.922424,38.435879],[-98.848561,38.477822],[-98.904571,38.652182],[-98.959228,38.652782]]]},"properties":{"id":"67564","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.61454,37.978171],[-94.614089,38.037057],[-94.805572,38.05186],[-94.832905,38.037442],[-94.805229,37.99382],[-94.61454,37.978171]]]},"properties":{"id":"66738","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.019953,37.732611],[-94.942327,37.601838],[-94.905879,37.616318],[-94.869146,37.659854],[-94.953928,37.746952],[-95.019953,37.732611]]]},"properties":{"id":"66746","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.339735,39.92506],[-95.1997,39.903342],[-95.437316,40.000209],[-95.415197,39.912226],[-95.339735,39.92506]]]},"properties":{"id":"66094","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.732948,39.87032],[-95.78894,39.740056],[-95.75015,39.725736],[-95.639133,39.812277],[-95.676393,39.870124],[-95.732948,39.87032]]]},"properties":{"id":"66425","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.844709,37.693994],[-96.998699,37.745334],[-97.053154,37.743262],[-97.080377,37.635997],[-97.026522,37.56323],[-96.918931,37.59965],[-96.844709,37.693994]]]},"properties":{"id":"67010","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.925631,37.475706],[-96.822409,37.394255],[-96.677236,37.417623],[-96.641266,37.431988],[-96.716045,37.577533],[-96.844131,37.563272],[-96.925631,37.475706]]]},"properties":{"id":"67008","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.819475,38.348502],[-96.653616,38.300332],[-96.597982,38.38756],[-96.666711,38.521952],[-96.709102,38.522022],[-96.819723,38.522464],[-96.819628,38.421045],[-96.819475,38.348502]]]},"properties":{"id":"66850","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.172494,37.021896],[-96.17242,37.029046],[-96.181631,37.029046],[-96.181865,37.019937],[-96.172494,37.021896]]]},"properties":{"id":"67334","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.743204,37.090759],[-94.702109,37.055145],[-94.66749,36.99867],[-94.618313,37.194687],[-94.652844,37.207381],[-94.705007,37.236659],[-94.7049,37.222025],[-94.704438,37.177901],[-94.686659,37.168398],[-94.704339,37.163191],[-94.743204,37.090759]]]},"properties":{"id":"66739","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.56237,40.002706],[-101.638647,39.568459],[-101.576274,39.568455],[-101.510602,39.568373],[-101.413913,39.568361],[-101.487636,40.002657],[-101.56237,40.002706]]]},"properties":{"id":"67731","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.035812,37.079294],[-99.541116,36.99961],[-99.543637,37.35224],[-99.634339,37.352554],[-100.000619,37.28458],[-100.091622,37.285597],[-100.091663,37.182842],[-100.035812,37.079294]]]},"properties":{"id":"67831","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.68724,37.690698],[-99.719451,37.430969],[-99.634339,37.352554],[-99.543637,37.35224],[-99.543189,37.381124],[-99.55958,37.734888],[-99.632594,37.733449],[-99.68724,37.690698]]]},"properties":{"id":"67834","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.371145,39.10297],[-97.35394,38.783481],[-97.242278,38.75459],[-97.130734,38.812838],[-97.084065,38.944797],[-97.083547,39.104243],[-97.185127,39.147901],[-97.333806,39.132273],[-97.371154,39.131961],[-97.371145,39.10297]],[[-97.259926,39.023886],[-97.263997,39.028179],[-97.258527,39.030462],[-97.259926,39.023886]]]},"properties":{"id":"67410","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.610492,39.364741],[-97.593423,39.219418],[-97.445479,39.233659],[-97.33144,39.429829],[-97.368947,39.422192],[-97.462883,39.422692],[-97.610492,39.364741]]]},"properties":{"id":"67466","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.616724,37.506904],[-94.617347,37.591218],[-94.668193,37.572121],[-94.686246,37.528222],[-94.68629,37.51352],[-94.616724,37.506904]]]},"properties":{"id":"66756","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.738825,40.002116],[-100.815523,39.741321],[-100.815474,39.712264],[-100.796699,39.669242],[-100.741099,39.654759],[-100.62862,39.654613],[-100.404323,39.712268],[-100.366643,39.806659],[-100.365562,40.001823],[-100.738825,40.002116]]]},"properties":{"id":"67749","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.242278,38.75459],[-97.261034,38.609307],[-97.096159,38.609357],[-97.022483,38.628957],[-97.001632,38.754098],[-97.037926,38.827314],[-97.075083,38.812632],[-97.130734,38.812838],[-97.242278,38.75459]]]},"properties":{"id":"67451","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.207455,39.674513],[-95.227345,39.754886],[-95.302732,39.769385],[-95.339641,39.740285],[-95.339692,39.696647],[-95.265045,39.652959],[-95.207455,39.674513]]]},"properties":{"id":"66017","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.232625,38.71686],[-95.099947,38.807566],[-95.149078,38.877114],[-95.288123,38.860123],[-95.381211,38.814603],[-95.371394,38.738801],[-95.232625,38.71686]]]},"properties":{"id":"66006","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.674427,38.933811],[-95.649687,38.869861],[-95.575709,38.869653],[-95.437797,38.897684],[-95.435168,38.942397],[-95.500759,38.967665],[-95.595702,38.986015],[-95.614394,38.986112],[-95.654139,38.957096],[-95.674427,38.933811]]]},"properties":{"id":"66409","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.344335,37.60674],[-96.525518,37.563654],[-96.52569,37.476405],[-96.525296,37.403387],[-96.380146,37.40349],[-96.253754,37.388207],[-96.143839,37.446056],[-96.042854,37.459831],[-96.142588,37.543866],[-96.344335,37.60674]]]},"properties":{"id":"67349","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.83297,37.734011],[-95.960689,37.719742],[-95.962507,37.446015],[-95.961605,37.386636],[-95.819458,37.385757],[-95.719818,37.500919],[-95.724077,37.598661],[-95.77106,37.646626],[-95.779203,37.733804],[-95.83297,37.734011]]]},"properties":{"id":"66736","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.824377,39.292762],[-98.936559,39.323859],[-99.046672,39.335926],[-99.157868,39.307098],[-99.149514,39.103965],[-99.102842,39.057005],[-99.0385,39.016173],[-99.027075,39.04581],[-98.918127,39.220205],[-98.824377,39.292762]]]},"properties":{"id":"67651","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.039447,38.856109],[-99.039782,38.876299],[-99.076851,38.885792],[-99.087747,38.864392],[-99.039447,38.856109]]]},"properties":{"id":"67674","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.929449,38.221571],[-101.089886,37.829115],[-101.089785,37.787168],[-101.089667,37.736337],[-100.910244,37.736771],[-100.761343,37.736603],[-100.725198,37.736481],[-100.664927,37.87936],[-100.46336,38.117191],[-100.464287,38.263111],[-100.685272,38.366235],[-100.929449,38.221571]]]},"properties":{"id":"67846","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.103268,38.264557],[-101.102999,37.930459],[-101.089886,37.829115],[-100.929449,38.221571],[-101.103268,38.264557]]]},"properties":{"id":"67851","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.664927,37.87936],[-100.725198,37.736481],[-100.652251,37.736275],[-100.664927,37.87936]]]},"properties":{"id":"67868","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.68724,37.690698],[-99.632594,37.733449],[-99.606492,37.956336],[-99.661569,38.014663],[-99.7343,38.014806],[-99.873763,37.913214],[-99.760418,37.705439],[-99.68724,37.690698]]]},"properties":{"id":"67876","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.927225,38.78314],[-96.708024,38.681761],[-96.649418,38.710442],[-96.650086,38.812226],[-96.668138,38.870345],[-96.89029,38.855762],[-96.927225,38.78314]]]},"properties":{"id":"66872","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.69563,39.545437],[-99.74408,39.248724],[-99.662714,39.279418],[-99.602279,39.408681],[-99.602074,39.469525],[-99.69563,39.545437]]]},"properties":{"id":"67625","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.033564,39.18975],[-100.08929,39.178117],[-100.03336,39.160807],[-100.033564,39.18975]]],[[[-100.033564,39.18975],[-99.959232,39.189989],[-99.956635,39.480195],[-100.031314,39.465443],[-100.033564,39.18975]]]]},"properties":{"id":"67659","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.358076,38.088655],[-96.067739,37.997627],[-96.030825,38.055441],[-95.977347,38.171109],[-96.067319,38.215376],[-96.358076,38.088655]]]},"properties":{"id":"66860","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.031672,37.837114],[-96.068,37.830169],[-96.102625,37.83634],[-96.125049,37.750811],[-95.960689,37.719742],[-95.83297,37.734011],[-95.922282,37.93811],[-95.958565,37.902103],[-96.031672,37.837114]]]},"properties":{"id":"66777","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.357488,38.074733],[-97.342006,38.062122],[-97.336819,38.071824],[-97.357488,38.074733]]]},"properties":{"id":"67117","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.619979,38.130225],[-97.684122,38.173936],[-97.841177,38.153255],[-97.811695,38.086447],[-97.720388,38.064739],[-97.619979,38.130225]]]},"properties":{"id":"67522","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.463841,38.260804],[-97.593017,38.26266],[-97.647449,38.232065],[-97.684122,38.173936],[-97.619979,38.130225],[-97.533254,38.115906],[-97.500424,38.1159],[-97.378783,38.191997],[-97.390173,38.246419],[-97.463841,38.260804]]]},"properties":{"id":"67107","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.124218,39.283485],[-96.136234,39.190099],[-96.03906,39.126527],[-95.998446,39.216527],[-96.016561,39.265983],[-96.124218,39.283485]]]},"properties":{"id":"66536","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.673148,39.090953],[-95.62546,39.083298],[-95.579956,39.0541],[-95.500254,39.056656],[-95.483495,39.056502],[-95.511052,39.069295],[-95.571457,39.116426],[-95.619049,39.216185],[-95.673049,39.21644],[-95.673148,39.090953]]]},"properties":{"id":"66617","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.413887,39.273848],[-95.41851,39.134208],[-95.270578,39.094813],[-95.255869,39.113158],[-95.254686,39.245251],[-95.311193,39.288711],[-95.413887,39.273848]]]},"properties":{"id":"66066","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.571457,39.116426],[-95.511052,39.069295],[-95.488329,39.101751],[-95.571457,39.116426]]]},"properties":{"id":"66429","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.09431,39.567126],[-98.06283,39.697166],[-98.082666,39.755338],[-98.38949,39.654362],[-98.351403,39.618276],[-98.233757,39.567397],[-98.09431,39.567126]]]},"properties":{"id":"66949","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.742423,38.912854],[-94.667739,38.912866],[-94.662761,38.915505],[-94.667562,38.934239],[-94.704748,38.934949],[-94.723645,38.935082],[-94.74227,38.920517],[-94.742423,38.912854]]]},"properties":{"id":"66210","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.667562,38.934239],[-94.662761,38.915505],[-94.607986,38.912924],[-94.607959,38.936854],[-94.630234,38.932806],[-94.658244,38.934782],[-94.667562,38.934239]]]},"properties":{"id":"66211","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.003629,38.942132],[-95.001881,38.945424],[-95.004997,38.947879],[-95.001622,38.948414],[-95.001607,38.94214],[-94.982665,38.956766],[-94.908739,38.952744],[-94.9088,38.989279],[-94.908765,38.991401],[-94.927608,39.002784],[-95.035959,38.974748],[-95.019321,38.942287],[-95.003629,38.942132]]]},"properties":{"id":"66018","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607483,39.033869],[-94.649003,39.014893],[-94.649064,39.007624],[-94.658297,38.978569],[-94.630319,38.978704],[-94.608437,38.978724],[-94.607483,39.033869]]]},"properties":{"id":"66208","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.562007,37.383947],[-95.502909,37.369372],[-95.434447,37.383906],[-95.379214,37.543964],[-95.561569,37.558716],[-95.597206,37.476999],[-95.562007,37.383947]]]},"properties":{"id":"66776","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.339124,37.237921],[-95.395533,37.134276],[-95.268167,37.106054],[-95.249468,37.207869],[-95.339124,37.237921]]]},"properties":{"id":"67330","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.249123,37.102181],[-95.267876,36.999525],[-95.213539,36.999577],[-95.211803,37.096141],[-95.249123,37.102181]]]},"properties":{"id":"67332","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.730286,37.069633],[-95.787508,37.033252],[-95.819366,36.999166],[-95.431565,36.999279],[-95.465152,37.134337],[-95.476545,37.134919],[-95.663804,37.144045],[-95.728848,37.069619],[-95.720057,37.069582],[-95.709611,37.059469],[-95.70785,37.049893],[-95.730286,37.069633]]]},"properties":{"id":"67337","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.286353,38.552825],[-100.244425,38.291356],[-100.102149,38.508478],[-100.286353,38.552825]]]},"properties":{"id":"67518","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.018169,39.418893],[-95.179928,39.390088],[-95.180142,39.288693],[-95.083279,39.259625],[-95.018169,39.418893]]]},"properties":{"id":"66020","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.964253,39.116321],[-94.901398,39.128632],[-94.900478,39.201501],[-94.988329,39.179243],[-94.964253,39.116321]]]},"properties":{"id":"66007","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.984145,39.052797],[-98.021459,38.871177],[-97.928681,38.900126],[-97.95627,39.030883],[-97.984145,39.052797]]]},"properties":{"id":"67423","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.06525,38.287654],[-94.861311,38.26568],[-94.917905,38.336342],[-94.941471,38.403693],[-94.973491,38.389308],[-95.065128,38.302168],[-95.06525,38.287654]]]},"properties":{"id":"66072","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.750518,38.124317],[-94.805572,38.05186],[-94.614089,38.037057],[-94.613798,38.109926],[-94.750518,38.124317]]]},"properties":{"id":"66767","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.371924,38.565709],[-97.165263,38.449662],[-97.095894,38.507624],[-97.095728,38.536644],[-97.096159,38.609357],[-97.261034,38.609307],[-97.371924,38.565709]]]},"properties":{"id":"67483","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.165263,38.449662],[-97.371924,38.565709],[-97.371932,38.522161],[-97.37188,38.435041],[-97.298092,38.435006],[-97.166516,38.447375],[-97.165263,38.449662]]]},"properties":{"id":"67438","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.239595,39.805492],[-96.344559,39.791015],[-96.351471,39.56616],[-96.351092,39.566154],[-96.239195,39.609702],[-96.182866,39.769972],[-96.239595,39.805492]]]},"properties":{"id":"66544","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.590433,39.553463],[-96.53888,39.711111],[-96.683815,39.740363],[-96.712839,39.566564],[-96.68409,39.478797],[-96.590433,39.553463]]]},"properties":{"id":"66411","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.042053,37.07521],[-101.663874,36.994774],[-101.827318,37.156103],[-102.042053,37.07521]]]},"properties":{"id":"67950","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.143091,37.660144],[-95.088082,37.732476],[-95.19736,37.732539],[-95.143091,37.660144]]]},"properties":{"id":"66775","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.7889,38.440673],[-99.749794,38.261735],[-99.642634,38.261898],[-99.584794,38.349386],[-99.586011,38.473169],[-99.660266,38.523984],[-99.7889,38.440673]]]},"properties":{"id":"67516","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.918127,39.220205],[-99.027075,39.04581],[-98.873107,39.060032],[-98.918127,39.220205]]]},"properties":{"id":"67658","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.817316,38.899963],[-97.814864,38.697136],[-97.761722,38.7112],[-97.63164,38.725444],[-97.539227,38.754692],[-97.427544,38.798058],[-97.427648,38.855953],[-97.538277,38.958052],[-97.64083,38.957999],[-97.817316,38.899963]]]},"properties":{"id":"67401","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.150458,38.029011],[-99.277481,38.15984],[-99.368675,38.087723],[-99.317865,38.00053],[-99.150582,37.999825],[-99.150458,38.029011]]]},"properties":{"id":"67529","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.857512,37.825277],[-98.995863,37.732866],[-98.922002,37.733283],[-98.849136,37.777139],[-98.849541,37.82528],[-98.857512,37.825277]]]},"properties":{"id":"67021","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.060361,40.002359],[-100.889261,39.654911],[-100.852046,39.712363],[-100.815523,39.741321],[-100.882852,40.002157],[-101.060361,40.002359]]]},"properties":{"id":"67744","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.47267,38.121977],[-98.471956,37.894422],[-98.362572,37.897439],[-98.343954,37.911642],[-98.381197,38.086033],[-98.47267,38.121977]]]},"properties":{"id":"67581","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.744659,39.885667],[-97.670059,39.696801],[-97.561693,39.65355],[-97.533009,39.769517],[-97.519088,39.856493],[-97.593721,40.002051],[-97.669234,40.002133],[-97.706989,39.943736],[-97.744659,39.885667]]]},"properties":{"id":"66935","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.015211,38.522362],[-98.071867,38.449256],[-98.03396,38.26058],[-98.017286,38.246249],[-97.943486,38.275038],[-97.924224,38.515307],[-98.015211,38.522362]]]},"properties":{"id":"67457","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.96091,39.30676],[-96.763133,39.240791],[-96.756237,39.334746],[-96.960875,39.335814],[-96.96091,39.30676]]]},"properties":{"id":"66531","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.539227,38.754692],[-97.63164,38.725444],[-97.548827,38.609108],[-97.539227,38.754692]]]},"properties":{"id":"67416","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.426942,37.810006],[-97.480831,37.751426],[-97.481103,37.722383],[-97.389668,37.722652],[-97.386487,37.725661],[-97.420193,37.807589],[-97.426942,37.810006]]]},"properties":{"id":"67205","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.262524,37.781353],[-97.262309,37.726816],[-97.259825,37.723004],[-97.259215,37.723004],[-97.189657,37.723103],[-97.189791,37.786269],[-97.208158,37.781527],[-97.208313,37.79607],[-97.189834,37.79613],[-97.17156,37.804594],[-97.170709,37.840052],[-97.245051,37.795905],[-97.262524,37.781353]]]},"properties":{"id":"67226","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.499232,37.758258],[-97.517282,37.736327],[-97.481103,37.722383],[-97.480831,37.751426],[-97.499232,37.758258]]]},"properties":{"id":"67223","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.518834,37.842594],[-97.601321,37.807855],[-97.590709,37.721548],[-97.517078,37.721921],[-97.517282,37.736327],[-97.499232,37.758258],[-97.481873,37.825076],[-97.518834,37.842594]]]},"properties":{"id":"67030","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.293692,37.722815],[-97.298484,37.715516],[-97.286066,37.722887],[-97.293692,37.722815]]]},"properties":{"id":"67260","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.933045,37.257204],[-101.1039,37.199062],[-101.112028,36.997776],[-100.633327,37.000103],[-100.633354,37.051598],[-100.933045,37.257204]]]},"properties":{"id":"67901","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.62546,39.083298],[-95.673148,39.090953],[-95.676181,39.065067],[-95.660684,39.061803],[-95.62546,39.083298]]]},"properties":{"id":"66608","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.769203,39.029062],[-95.983888,39.029831],[-95.946709,38.993617],[-95.711936,39.000622],[-95.711453,39.000566],[-95.712879,39.016704],[-95.724972,39.024061],[-95.724931,39.029489],[-95.769203,39.029062]]]},"properties":{"id":"66614","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.743476,38.95659],[-95.946868,38.928445],[-95.946747,38.906432],[-95.909347,38.870001],[-95.83577,38.869262],[-95.835532,38.86926],[-95.73171,38.94248],[-95.743476,38.95659]]]},"properties":{"id":"66402","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.520244,37.388376],[-101.555259,36.995324],[-101.112028,36.997776],[-101.1039,37.199062],[-101.321445,37.388114],[-101.520244,37.388376]]]},"properties":{"id":"67951","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.682043,39.133837],[-101.922958,39.133245],[-101.91031,38.697889],[-101.567094,38.699669],[-101.55854,38.699769],[-101.682043,39.133837]]]},"properties":{"id":"67758","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.735479,39.128516],[-94.63512,39.122364],[-94.633196,39.14562],[-94.650647,39.154092],[-94.741858,39.170318],[-94.735479,39.128516]]]},"properties":{"id":"66104","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.635334,39.091994],[-94.679698,39.09976],[-94.649572,39.077616],[-94.608651,39.090064],[-94.622959,39.093407],[-94.635334,39.091994]]]},"properties":{"id":"66105","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.63512,39.122364],[-94.735479,39.128516],[-94.745597,39.128542],[-94.740772,39.102286],[-94.710738,39.093656],[-94.679698,39.09976],[-94.635334,39.091994],[-94.63512,39.122364]]]},"properties":{"id":"66102","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.807378,39.123633],[-94.740772,39.102286],[-94.745597,39.128542],[-94.807378,39.123633]]]},"properties":{"id":"66112","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.519163,37.979036],[-95.519224,37.863755],[-95.317709,37.82021],[-95.299176,38.038067],[-95.519132,37.988486],[-95.519163,37.979036]]]},"properties":{"id":"66749","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.281068,37.82009],[-95.280854,38.038026],[-95.299176,38.038067],[-95.317709,37.82021],[-95.3064,37.818181],[-95.281068,37.82009]]]},"properties":{"id":"66751","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.614576,37.653597],[-95.561569,37.558716],[-95.379214,37.543964],[-95.325986,37.594465],[-95.306441,37.732762],[-95.324725,37.732925],[-95.525222,37.747338],[-95.614863,37.733046],[-95.614576,37.653597]]]},"properties":{"id":"66720","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.426072,38.154805],[-95.280749,38.103528],[-95.213771,38.168648],[-95.39739,38.242364],[-95.426072,38.154805]]]},"properties":{"id":"66091","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.811674,39.56591],[-95.923508,39.492514],[-95.904708,39.404876],[-95.636139,39.390139],[-95.570395,39.404568],[-95.469136,39.449646],[-95.47825,39.478562],[-95.570125,39.535078],[-95.625957,39.528594],[-95.751106,39.589046],[-95.811674,39.56591]]]},"properties":{"id":"66436","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.464951,37.384086],[-98.610764,37.4706],[-98.681614,37.384265],[-98.847997,37.15213],[-98.640103,37.138608],[-98.531327,37.167214],[-98.458584,37.384073],[-98.464951,37.384086]]]},"properties":{"id":"67104","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.601782,37.558262],[-98.75678,37.470535],[-98.756892,37.383733],[-98.681614,37.384265],[-98.610764,37.4706],[-98.592854,37.543574],[-98.592899,37.558121],[-98.601782,37.558262]]]},"properties":{"id":"67134","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.75678,37.470535],[-98.97717,37.542769],[-99.165062,37.435559],[-99.001429,37.377302],[-98.885968,37.384128],[-98.756892,37.383733],[-98.75678,37.470535]]]},"properties":{"id":"67028","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.480083,38.565398],[-98.387725,38.56534],[-98.424701,38.681467],[-98.479958,38.696916],[-98.480083,38.565398]]]},"properties":{"id":"67450","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.415197,39.912226],[-95.43376,39.754644],[-95.339641,39.740285],[-95.302732,39.769385],[-95.26511,39.805801],[-95.26518,39.827685],[-95.283943,39.842106],[-95.339735,39.92506],[-95.415197,39.912226]]]},"properties":{"id":"66532","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.625957,39.528594],[-95.695261,39.653048],[-95.676363,39.683177],[-95.750437,39.696603],[-95.751106,39.589046],[-95.625957,39.528594]]]},"properties":{"id":"66516","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.918931,37.59965],[-97.026522,37.56323],[-97.153108,37.504717],[-97.135176,37.475508],[-97.023012,37.468919],[-96.925631,37.475706],[-96.844131,37.563272],[-96.918931,37.59965]]]},"properties":{"id":"67039","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.525925,37.792368],[-96.661152,37.751962],[-96.844709,37.693994],[-96.918931,37.59965],[-96.844131,37.563272],[-96.716045,37.577533],[-96.580465,37.650537],[-96.525557,37.705306],[-96.525308,37.73765],[-96.525925,37.792368]]]},"properties":{"id":"67074","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.078689,37.898046],[-97.152612,37.928794],[-97.170709,37.840052],[-97.17156,37.804594],[-97.152939,37.741872],[-97.061991,37.752429],[-97.042196,37.868769],[-97.078689,37.898046]]]},"properties":{"id":"67017","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.380146,37.40349],[-96.344718,37.228579],[-96.226914,37.300902],[-96.21327,37.315366],[-96.253754,37.388207],[-96.380146,37.40349]]]},"properties":{"id":"67353","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.961605,37.386636],[-95.962507,37.446015],[-96.042854,37.459831],[-96.143839,37.446056],[-96.21327,37.315366],[-96.226914,37.300902],[-96.091526,37.248856],[-95.961605,37.386636]]]},"properties":{"id":"67352","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.66749,36.99867],[-94.702109,37.055145],[-94.758957,37.084144],[-94.92925,37.01715],[-94.931508,36.999174],[-94.846772,36.99882],[-94.846526,37.002141],[-94.834655,37.002146],[-94.83537,36.99877],[-94.66749,36.99867]]]},"properties":{"id":"66713","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.705007,37.236659],[-94.85049,37.298448],[-94.904863,37.296266],[-94.923018,37.289593],[-94.923028,37.281688],[-94.908209,37.266811],[-94.7049,37.222025],[-94.705007,37.236659]]]},"properties":{"id":"66773","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.923018,37.289593],[-94.932114,37.288887],[-94.932211,37.28174],[-94.923028,37.281688],[-94.923018,37.289593]]]},"properties":{"id":"66782","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.487636,40.002657],[-101.413913,39.568361],[-101.300911,39.597383],[-101.22605,39.62635],[-101.206447,39.655303],[-101.293988,40.002567],[-101.487636,40.002657]]]},"properties":{"id":"67745","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.8342,37.36032],[-99.719451,37.430969],[-99.68724,37.690698],[-99.760418,37.705439],[-99.797145,37.680769],[-99.888174,37.580658],[-99.8342,37.36032]]]},"properties":{"id":"67842","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.543637,37.35224],[-99.541116,36.99961],[-99.396675,36.999838],[-99.512523,37.381158],[-99.543189,37.381124],[-99.543637,37.35224]]]},"properties":{"id":"67127","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.670059,39.696801],[-97.786067,39.653729],[-97.816318,39.481538],[-97.666509,39.37936],[-97.462727,39.524321],[-97.53732,39.6536],[-97.561693,39.65355],[-97.670059,39.696801]]]},"properties":{"id":"66901","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.30271,39.317435],[-98.191246,39.233892],[-97.929746,39.219273],[-97.93084,39.233261],[-97.891608,39.48116],[-97.931844,39.566921],[-98.09431,39.567126],[-98.233757,39.567397],[-98.30271,39.317435]],[[-97.948172,39.386158],[-97.929507,39.393543],[-97.929374,39.378842],[-97.948172,39.386158]]]},"properties":{"id":"67420","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.079252,38.332226],[-95.904437,38.297594],[-95.950498,38.392113],[-95.987199,38.405055],[-96.079252,38.332226]]]},"properties":{"id":"66864","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.71568,37.244146],[-96.815944,37.186259],[-96.866564,37.127525],[-96.8229,36.99921],[-96.607359,36.998938],[-96.607909,37.244041],[-96.71568,37.244146]]]},"properties":{"id":"67038","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.815523,39.741321],[-100.852046,39.712363],[-100.815474,39.712264],[-100.815523,39.741321]]],[[[-100.815523,39.741321],[-100.738825,40.002116],[-100.882852,40.002157],[-100.815523,39.741321]]]]},"properties":{"id":"67739","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.242278,38.75459],[-97.35394,38.783481],[-97.427544,38.798058],[-97.539227,38.754692],[-97.548827,38.609108],[-97.545194,38.521729],[-97.482306,38.478389],[-97.371932,38.522161],[-97.371924,38.565709],[-97.261034,38.609307],[-97.242278,38.75459]]]},"properties":{"id":"67448","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.259926,39.023886],[-97.258527,39.030462],[-97.263997,39.028179],[-97.259926,39.023886]]]},"properties":{"id":"67482","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.022136,38.000236],[-99.150458,38.029011],[-99.150582,37.999825],[-99.096218,37.839726],[-99.022488,37.8544],[-99.022136,38.000236]]]},"properties":{"id":"67519","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.143839,37.446056],[-96.253754,37.388207],[-96.21327,37.315366],[-96.143839,37.446056]]]},"properties":{"id":"67345","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.70608,39.132268],[-99.782466,38.791291],[-99.769298,38.739962],[-99.585087,38.696537],[-99.560564,38.725557],[-99.426576,39.059378],[-99.534017,39.132504],[-99.70608,39.132268]]]},"properties":{"id":"67637","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.33682,38.714114],[-99.336948,38.711468],[-99.328805,38.711581],[-99.329276,38.715278],[-99.33682,38.714114]]]},"properties":{"id":"67667","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.022248,38.666955],[-98.16475,38.609368],[-98.257082,38.580329],[-98.312711,38.521924],[-98.312464,38.478358],[-98.071867,38.449256],[-98.015211,38.522362],[-98.022248,38.666955]]]},"properties":{"id":"67444","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.387725,38.56534],[-98.480083,38.565398],[-98.461801,38.463668],[-98.443447,38.442994],[-98.376177,38.420209],[-98.312464,38.478358],[-98.312711,38.521924],[-98.387725,38.56534]]]},"properties":{"id":"67427","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.125438,38.264509],[-101.158356,38.264441],[-101.102999,37.930459],[-101.103268,38.264557],[-101.125438,38.264509]]]},"properties":{"id":"67838","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.174807,38.550138],[-95.156987,38.462749],[-95.07487,38.476968],[-95.065074,38.566195],[-95.065097,38.622856],[-95.121092,38.61721],[-95.174807,38.550138]]]},"properties":{"id":"66079","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.355624,38.510733],[-95.340934,38.419236],[-95.156987,38.462749],[-95.174807,38.550138],[-95.355624,38.510733]]]},"properties":{"id":"66078","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.372481,39.101616],[-96.394611,39.176831],[-96.519789,39.319898],[-96.51985,39.392135],[-96.625282,39.280126],[-96.701254,39.119179],[-96.706818,39.098688],[-96.706077,39.09126],[-96.602429,39.010722],[-96.501214,39.014897],[-96.372481,39.101616]],[[-96.585487,39.20114],[-96.576116,39.204236],[-96.576394,39.189751],[-96.585444,39.186016],[-96.585487,39.20114]]]},"properties":{"id":"66502","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.830985,39.139228],[-96.896824,39.089607],[-96.869817,39.072354],[-96.830985,39.139228]]]},"properties":{"id":"66514","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.386953,39.22008],[-100.563532,39.219897],[-100.537572,39.003214],[-100.408604,39.003034],[-100.386953,39.22008]]]},"properties":{"id":"67737","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.782466,38.791291],[-99.70608,39.132268],[-99.773008,39.183168],[-99.791798,39.175972],[-99.800901,38.797922],[-99.782466,38.791291]]]},"properties":{"id":"67656","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.767393,37.388788],[-101.52581,37.388392],[-101.527063,37.736314],[-101.690407,37.736978],[-101.925074,37.738106],[-101.889348,37.389008],[-101.767393,37.388788]]]},"properties":{"id":"67855","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.543154,37.44563],[-100.470518,37.416428],[-100.343112,37.474803],[-100.32456,37.533208],[-100.34274,37.627414],[-100.4335,37.737254],[-100.524843,37.708006],[-100.543154,37.44563]]]},"properties":{"id":"67867","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.044368,38.144372],[-101.963072,37.998152],[-102.043692,37.914004],[-102.041664,37.687487],[-101.925074,37.738106],[-101.690407,37.736978],[-101.579395,38.263047],[-102.044464,38.262412],[-102.044368,38.144372]]]},"properties":{"id":"67878","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.579395,38.263047],[-101.690407,37.736978],[-101.527063,37.736314],[-101.46655,37.891924],[-101.447696,38.263626],[-101.567482,38.263145],[-101.579395,38.263047]]]},"properties":{"id":"67857","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.695261,39.653048],[-95.625957,39.528594],[-95.570125,39.535078],[-95.564182,39.602259],[-95.695261,39.653048]]]},"properties":{"id":"66552","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.923258,39.390359],[-95.849206,39.216508],[-95.812377,39.216535],[-95.766376,39.21653],[-95.62663,39.332254],[-95.636139,39.390139],[-95.904708,39.404876],[-95.923258,39.390359]]]},"properties":{"id":"66509","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.490398,39.129387],[-95.488329,39.101751],[-95.511052,39.069295],[-95.483495,39.056502],[-95.38206,39.046483],[-95.285425,39.032162],[-95.270578,39.094813],[-95.41851,39.134208],[-95.490398,39.129387]]]},"properties":{"id":"66073","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.30271,39.317435],[-98.233757,39.567397],[-98.351403,39.618276],[-98.37712,39.379012],[-98.30271,39.317435]]]},"properties":{"id":"67446","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.156844,40.002241],[-98.193896,39.943994],[-98.099076,39.943635],[-98.046542,40.002186],[-98.156844,40.002241]]]},"properties":{"id":"68978","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.705099,38.992965],[-94.705016,38.978376],[-94.658297,38.978569],[-94.649064,39.007624],[-94.693077,39.007633],[-94.705099,38.992965]]]},"properties":{"id":"66204","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.791527,39.043575],[-94.841943,38.999086],[-94.844088,38.981676],[-94.816327,38.985269],[-94.789177,39.043612],[-94.791527,39.043575]]]},"properties":{"id":"66218","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.844088,38.981676],[-94.852979,38.941395],[-94.797477,38.94162],[-94.797298,38.974488],[-94.816327,38.985269],[-94.844088,38.981676]]]},"properties":{"id":"66220","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.742196,38.792868],[-94.686348,38.800336],[-94.686354,38.803612],[-94.742196,38.792868]]],[[[-94.686351,38.781798],[-94.740871,38.687127],[-94.610093,38.694461],[-94.608867,38.752691],[-94.686351,38.781798]]]]},"properties":{"id":"66013","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.447696,38.263626],[-101.46655,37.891924],[-101.089785,37.787168],[-101.089886,37.829115],[-101.102999,37.930459],[-101.158356,38.264441],[-101.447696,38.263626]]]},"properties":{"id":"67860","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-98.556508,37.557842],[-98.592899,37.558121],[-98.592854,37.543574],[-98.556508,37.557842]]],[[[-98.556508,37.557842],[-98.464748,37.528986],[-98.318668,37.543909],[-98.282184,37.55825],[-98.228545,37.776243],[-98.237168,37.774222],[-98.556458,37.703605],[-98.556581,37.587102],[-98.556508,37.557842]]]]},"properties":{"id":"67035","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.410625,37.381813],[-99.395602,37.73397],[-99.557681,37.734925],[-99.55958,37.734888],[-99.543189,37.381124],[-99.512523,37.381158],[-99.410625,37.381813]]]},"properties":{"id":"67109","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.656544,37.348148],[-95.594636,37.18076],[-95.503725,37.158514],[-95.420787,37.295555],[-95.502909,37.369372],[-95.562007,37.383947],[-95.656544,37.348148]]]},"properties":{"id":"67335","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.465152,37.134337],[-95.395533,37.134276],[-95.339124,37.237921],[-95.394925,37.310646],[-95.420787,37.295555],[-95.503725,37.158514],[-95.476545,37.134919],[-95.465152,37.134337]]]},"properties":{"id":"67354","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.022597,38.709809],[-95.986466,38.695322],[-95.780538,38.702259],[-95.79781,38.818193],[-95.83577,38.869262],[-95.909347,38.870001],[-96.019959,38.738918],[-96.022597,38.709809]]]},"properties":{"id":"66413","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.801152,38.609446],[-97.773496,38.492901],[-97.574037,38.501169],[-97.545194,38.521729],[-97.548827,38.609108],[-97.63164,38.725444],[-97.761722,38.7112],[-97.801152,38.609446]]]},"properties":{"id":"67456","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.482306,38.478389],[-97.545194,38.521729],[-97.574037,38.501169],[-97.593017,38.26266],[-97.463841,38.260804],[-97.482306,38.478389]]]},"properties":{"id":"67443","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.095894,38.507624],[-96.819628,38.421045],[-96.819723,38.522464],[-96.893383,38.522463],[-97.040661,38.55107],[-97.095728,38.536644],[-97.095894,38.507624]]]},"properties":{"id":"66858","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.040661,38.55107],[-96.893383,38.522463],[-96.893495,38.566183],[-96.985593,38.595058],[-97.040661,38.55107]]]},"properties":{"id":"66859","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.806552,39.737526],[-96.806544,39.566423],[-96.712839,39.566564],[-96.683815,39.740363],[-96.788139,39.812988],[-96.806328,39.813037],[-96.806552,39.737526]]]},"properties":{"id":"66548","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.089035,39.609618],[-96.10781,39.595176],[-96.089072,39.595141],[-96.089035,39.609618]]],[[[-96.089035,39.609618],[-96.03295,39.580595],[-96.007536,39.725437],[-96.033095,39.725633],[-96.089035,39.609618]]]]},"properties":{"id":"66417","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.010473,38.652488],[-100.085265,38.544512],[-100.029385,38.580222],[-100.010473,38.652488]]]},"properties":{"id":"67515","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.654298,39.683344],[-98.761153,39.567636],[-98.85903,39.567948],[-98.806049,39.481766],[-98.655947,39.509859],[-98.607499,39.639974],[-98.654298,39.683344]]]},"properties":{"id":"67474","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.936559,39.323859],[-98.806049,39.481766],[-98.85903,39.567948],[-98.916443,39.567986],[-99.044398,39.568035],[-99.046672,39.335926],[-98.936559,39.323859]]]},"properties":{"id":"67623","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.705332,39.001614],[-97.53851,39.088736],[-97.460235,39.110861],[-97.427031,39.175472],[-97.445479,39.233659],[-97.593423,39.219418],[-97.901379,39.233799],[-97.93084,39.233261],[-97.929746,39.219273],[-97.92884,39.117973],[-97.705332,39.001614]]]},"properties":{"id":"67467","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.104682,39.930248],[-99.178704,39.727224],[-99.122679,39.727309],[-99.104682,39.930248]]]},"properties":{"id":"67621","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.443447,38.442994],[-98.461693,38.347594],[-98.330963,38.304173],[-98.376177,38.420209],[-98.443447,38.442994]]]},"properties":{"id":"67524","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.461693,38.347594],[-98.472672,38.201983],[-98.472794,38.172757],[-98.417774,38.173116],[-98.330904,38.27518],[-98.330963,38.304173],[-98.461693,38.347594]]]},"properties":{"id":"67573","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.492489,39.379455],[-99.157353,39.36503],[-99.137946,39.567993],[-99.265502,39.567412],[-99.383912,39.567397],[-99.460364,39.567514],[-99.602074,39.469525],[-99.602279,39.408681],[-99.492489,39.379455]]]},"properties":{"id":"67669","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.357654,38.696803],[-99.438005,38.509412],[-99.419488,38.480516],[-99.253508,38.494315],[-99.25371,38.696934],[-99.284489,38.696861],[-99.309281,38.655135],[-99.32766,38.696831],[-99.357654,38.696803]]]},"properties":{"id":"67548","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.25371,38.696934],[-99.253508,38.494315],[-99.125406,38.493744],[-99.088491,38.493554],[-99.12544,38.696967],[-99.227892,38.696935],[-99.25371,38.696934]]]},"properties":{"id":"67520","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.271748,37.650092],[-97.189515,37.65014],[-97.18953,37.686626],[-97.26216,37.686491],[-97.271748,37.650092]]]},"properties":{"id":"67207","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.337451,37.649714],[-97.346717,37.651609],[-97.334037,37.573493],[-97.307035,37.56439],[-97.286348,37.59347],[-97.298746,37.65001],[-97.337451,37.649714]]]},"properties":{"id":"67216","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.26216,37.686491],[-97.259215,37.723004],[-97.259825,37.723004],[-97.262302,37.724958],[-97.286066,37.722887],[-97.298484,37.715516],[-97.298955,37.6863],[-97.26216,37.686491]]]},"properties":{"id":"67208","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.26216,37.686491],[-97.298955,37.6863],[-97.298746,37.65001],[-97.271748,37.650092],[-97.26216,37.686491]]]},"properties":{"id":"67218","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.673453,39.029754],[-95.613939,39.030657],[-95.590079,39.052609],[-95.662517,39.060208],[-95.670943,39.047885],[-95.673453,39.029754]]]},"properties":{"id":"66607","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.871784,39.085087],[-95.909668,39.087854],[-95.976579,39.112245],[-95.983888,39.029831],[-95.769203,39.029062],[-95.761748,39.050843],[-95.751361,39.070699],[-95.871784,39.085087]]]},"properties":{"id":"66615","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.687198,39.04892],[-95.687593,39.029702],[-95.675434,39.02973],[-95.673453,39.029754],[-95.670943,39.047885],[-95.685962,39.051806],[-95.687198,39.04892]]]},"properties":{"id":"66612","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.692822,38.202335],[-98.857816,38.23261],[-98.876327,38.174421],[-98.91287,38.101915],[-98.857512,37.825277],[-98.849541,37.82528],[-98.721258,37.824808],[-98.710606,37.824744],[-98.61935,38.07757],[-98.692822,38.202335]]]},"properties":{"id":"67576","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.603269,37.285558],[-97.747192,37.168941],[-97.622838,37.198867],[-97.603269,37.285558]]]},"properties":{"id":"67105","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.806328,39.813037],[-96.805768,40.001495],[-96.89966,40.001484],[-96.993533,39.929319],[-96.936816,39.812915],[-96.806552,39.737526],[-96.806328,39.813037]]]},"properties":{"id":"66945","state_code":"20"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.632399,37.125113],[-85.526881,37.10945],[-85.352771,37.192432],[-85.544876,37.37498],[-85.679125,37.278026],[-85.681554,37.226509],[-85.645453,37.170457],[-85.632399,37.125113]]]},"properties":{"id":"42743","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.283052,37.290772],[-85.219099,37.265043],[-85.192175,37.272895],[-85.147169,37.304271],[-85.044502,37.419116],[-85.168995,37.39574],[-85.186317,37.384382],[-85.195791,37.370719],[-85.283052,37.290772]]]},"properties":{"id":"42733","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.392039,36.91444],[-86.39085,36.786345],[-86.272356,36.867039],[-86.392039,36.91444]]]},"properties":{"id":"42122","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.903673,36.938354],[-88.85508,36.950396],[-88.887377,36.983596],[-88.979431,37.028047],[-89.097,37.015262],[-89.100864,36.944016],[-89.08916,36.94043],[-88.903673,36.938354]]]},"properties":{"id":"42087","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.937394,37.152585],[-88.945128,37.151879],[-88.94466,37.137683],[-88.937394,37.152585]]]},"properties":{"id":"42022","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.824813,36.97491],[-88.826731,36.951769],[-88.815952,36.9541],[-88.824813,36.97491]]]},"properties":{"id":"42060","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.758552,37.096641],[-85.755426,37.026786],[-85.696354,37.07855],[-85.758398,37.099736],[-85.758552,37.096641]]]},"properties":{"id":"42154","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.875895,38.131737],[-83.801976,38.279248],[-83.848355,38.296949],[-83.886878,38.266598],[-83.977924,38.192118],[-83.875895,38.131737]]]},"properties":{"id":"40374","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.56133,36.795918],[-83.572313,36.835584],[-83.661866,36.796604],[-83.580011,36.786226],[-83.56133,36.795918]]]},"properties":{"id":"40958","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.56133,36.795918],[-83.541757,36.792009],[-83.489502,36.764418],[-83.485814,36.783293],[-83.502341,36.820189],[-83.490703,36.836737],[-83.56133,36.795918]]]},"properties":{"id":"40845","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726992,38.348507],[-82.817454,38.373935],[-82.84136,38.38023],[-82.828558,38.292722],[-82.791909,38.244356],[-82.699471,38.261913],[-82.726992,38.348507]]]},"properties":{"id":"41168","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.004207,38.66388],[-84.070949,38.765919],[-84.222562,38.684488],[-84.205346,38.582039],[-84.181159,38.557501],[-84.01888,38.584064],[-84.004207,38.66388]]]},"properties":{"id":"41004","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.415104,37.626319],[-83.517192,37.611196],[-83.563303,37.546566],[-83.469149,37.374654],[-83.468573,37.374498],[-83.400868,37.338235],[-83.357366,37.358003],[-83.378451,37.397545],[-83.385288,37.412713],[-83.375595,37.422592],[-83.279321,37.467043],[-83.134099,37.457784],[-83.131505,37.460329],[-83.093344,37.497903],[-82.948544,37.503167],[-83.017109,37.55159],[-83.247232,37.667439],[-83.415104,37.626319]],[[-83.205424,37.597581],[-83.209406,37.587755],[-83.228574,37.592709],[-83.215486,37.607552],[-83.205424,37.597581]]]},"properties":{"id":"41339","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.378451,37.397545],[-83.375595,37.422592],[-83.385288,37.412713],[-83.378451,37.397545]]]},"properties":{"id":"41390","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.481724,37.995655],[-86.46971,37.930976],[-86.451397,37.938497],[-86.389138,37.96532],[-86.393733,37.975842],[-86.412856,37.988928],[-86.481724,37.995655]]]},"properties":{"id":"40171","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.363422,38.030781],[-86.38775,37.971716],[-86.309727,37.980038],[-86.310499,37.989614],[-86.363422,38.030781]]],[[[-86.490854,38.045735],[-86.481724,37.995655],[-86.412856,37.988928],[-86.372544,38.034043],[-86.433423,38.066642],[-86.490854,38.045735]]]]},"properties":{"id":"40157","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.761812,37.943411],[-85.632132,37.844397],[-85.599678,37.869442],[-85.558714,37.994693],[-85.596213,38.077274],[-85.701854,38.062743],[-85.820814,38.047808],[-85.850225,38.04515],[-85.878791,37.996781],[-85.761812,37.943411]],[[-85.647812,37.926269],[-85.666006,37.93188],[-85.662681,37.946286],[-85.653393,37.92982],[-85.647812,37.926269]]]},"properties":{"id":"40165","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.701854,38.062743],[-85.719976,38.085257],[-85.790154,38.065842],[-85.820814,38.047808],[-85.701854,38.062743]]]},"properties":{"id":"40109","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.12934,36.981888],[-87.054871,36.997864],[-87.082621,36.919793],[-87.056485,36.866093],[-86.74897,37.024402],[-86.904348,37.164801],[-87.033656,37.077005],[-87.061021,37.06681],[-87.146207,37.043801],[-87.12934,36.981888]]]},"properties":{"id":"42256","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.470854,39.095933],[-84.494135,39.102865],[-84.506161,39.095177],[-84.497594,39.07682],[-84.497612,39.056311],[-84.494814,39.05158],[-84.465595,39.04997],[-84.470854,39.095933]]]},"properties":{"id":"41071","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.874994,38.266937],[-82.877722,38.281238],[-82.912211,38.28875],[-82.912524,38.269874],[-82.874994,38.266937]]]},"properties":{"id":"41146","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.927675,38.089862],[-82.77407,38.077452],[-82.741942,38.088909],[-82.813503,38.212911],[-82.815257,38.217364],[-82.983938,38.176411],[-82.927675,38.089862]]]},"properties":{"id":"41180","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.761699,37.460268],[-84.857199,37.523639],[-84.931684,37.505691],[-85.043284,37.502747],[-85.043542,37.441403],[-84.853603,37.399945],[-84.727566,37.362362],[-84.706992,37.405323],[-84.687811,37.419112],[-84.756774,37.454185],[-84.761699,37.460268]]]},"properties":{"id":"40437","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.259691,37.070467],[-87.586675,36.95834],[-87.602666,36.936338],[-87.673674,36.793699],[-87.538163,36.736897],[-87.456835,36.709331],[-87.294334,36.878839],[-87.261843,37.057462],[-87.259691,37.070467]]]},"properties":{"id":"42240","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.682749,36.703366],[-87.567461,36.653565],[-87.548487,36.656287],[-87.538163,36.736897],[-87.673674,36.793699],[-87.706405,36.785226],[-87.71244,36.720022],[-87.682749,36.703366]]]},"properties":{"id":"42236","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.682749,36.703366],[-87.666697,36.647],[-87.567461,36.653565],[-87.682749,36.703366]]]},"properties":{"id":"42254","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.080527,38.115118],[-84.286461,38.067028],[-84.290492,38.030384],[-84.339955,37.931761],[-84.288628,37.910775],[-84.129699,37.867706],[-84.080337,37.853066],[-84.001967,37.837592],[-83.96631,37.930768],[-83.982268,37.959704],[-84.080527,38.115118]]]},"properties":{"id":"40391","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.750503,37.128677],[-83.724045,37.116002],[-83.717748,37.131517],[-83.750503,37.128677]]]},"properties":{"id":"40941","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.808726,37.320901],[-83.71781,37.338805],[-83.801423,37.364295],[-83.808726,37.320901]]]},"properties":{"id":"40983","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.266082,37.625688],[-87.089458,37.567259],[-87.053462,37.546474],[-86.950165,37.553477],[-86.953377,37.615707],[-87.044611,37.708016],[-87.117907,37.697029],[-87.266082,37.625688]]]},"properties":{"id":"42376","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.275941,37.133888],[-86.170111,37.132771],[-86.066802,37.128478],[-86.051202,37.201483],[-86.04957,37.215767],[-86.111276,37.243372],[-86.250113,37.266038],[-86.333619,37.27879],[-86.336814,37.326017],[-86.380114,37.32447],[-86.428019,37.274167],[-86.399165,37.169896],[-86.275941,37.133888]]]},"properties":{"id":"42210","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.069049,38.143876],[-82.97077,38.050954],[-82.95279,38.052827],[-82.927675,38.089862],[-82.983938,38.176411],[-83.011892,38.199806],[-83.069049,38.143876]]],[[[-83.158793,38.008182],[-83.074124,38.002739],[-83.069049,38.143876],[-83.162941,38.156276],[-83.259212,38.120148],[-83.212389,38.027126],[-83.158793,38.008182]]]]},"properties":{"id":"41171","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.910875,37.766657],[-83.756471,37.708537],[-83.7221,37.71642],[-83.831832,37.764956],[-83.910875,37.766657]]]},"properties":{"id":"40472","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.642325,38.344986],[-83.524641,38.254787],[-83.452848,38.381799],[-83.545732,38.482899],[-83.621339,38.504535],[-83.642325,38.344986]]]},"properties":{"id":"41093","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726338,37.344891],[-82.721847,37.308697],[-82.700274,37.335267],[-82.716632,37.352603],[-82.726338,37.344891]]]},"properties":{"id":"41669","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.816986,37.500761],[-82.951113,37.412154],[-82.947234,37.399462],[-82.830527,37.466007],[-82.830419,37.467133],[-82.773962,37.440523],[-82.775076,37.4685],[-82.816986,37.500761]]]},"properties":{"id":"41630","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.021686,38.22031],[-85.065885,38.191524],[-84.971663,38.120502],[-84.864908,38.116926],[-84.787458,38.137075],[-84.730289,38.208302],[-84.768355,38.344282],[-84.870482,38.356751],[-84.899006,38.377975],[-84.997667,38.335586],[-85.021686,38.22031]],[[-84.855464,38.1824],[-84.865856,38.170197],[-84.874565,38.171608],[-84.862593,38.180767],[-84.855464,38.1824]]]},"properties":{"id":"40601","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.502638,38.548959],[-84.703443,38.573796],[-84.668647,38.404879],[-84.660302,38.407692],[-84.488202,38.442038],[-84.45148,38.448722],[-84.502638,38.548959]]]},"properties":{"id":"41010","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.718755,36.907145],[-88.688602,36.942728],[-88.815739,36.953595],[-88.775906,36.886971],[-88.764027,36.877874],[-88.718755,36.907145]]]},"properties":{"id":"42069","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.175566,37.523177],[-86.2009,37.329481],[-86.065793,37.359833],[-86.03994,37.413953],[-86.047598,37.449933],[-86.054861,37.474312],[-86.175566,37.523177]]]},"properties":{"id":"42726","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.185952,37.665892],[-86.221895,37.680644],[-86.243898,37.643684],[-86.27527,37.593519],[-86.175566,37.523177],[-86.054861,37.474312],[-86.05399,37.495743],[-86.064073,37.507902],[-86.185952,37.665892]]]},"properties":{"id":"42712","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.398308,37.47601],[-86.427857,37.459528],[-86.389131,37.425304],[-86.398308,37.47601]]]},"properties":{"id":"42762","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.697687,37.116995],[-85.632399,37.125113],[-85.645453,37.170457],[-85.710528,37.162429],[-85.774588,37.139932],[-85.697687,37.116995]]]},"properties":{"id":"42214","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.928438,37.636478],[-85.868821,37.564649],[-85.838664,37.568708],[-85.716835,37.681367],[-85.680488,37.732284],[-85.737777,37.81179],[-85.760024,37.814218],[-85.906612,37.80035],[-85.918325,37.766754],[-86.059816,37.717992],[-85.928438,37.636478]]]},"properties":{"id":"42701","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.950354,37.407087],[-85.80105,37.419153],[-85.794815,37.446396],[-86.047598,37.449933],[-86.03994,37.413953],[-85.950354,37.407087]]]},"properties":{"id":"42784","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.928438,37.636478],[-86.059816,37.717992],[-86.161192,37.742691],[-86.180449,37.750338],[-86.221895,37.680644],[-86.185952,37.665892],[-86.011906,37.583553],[-85.928438,37.636478]]]},"properties":{"id":"42724","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.096513,36.931453],[-83.192642,36.916502],[-83.190317,36.913585],[-83.189673,36.895397],[-83.097411,36.931982],[-83.096513,36.931453]]]},"properties":{"id":"40870","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.936601,36.966447],[-82.939354,36.966242],[-82.936973,36.960371],[-82.936601,36.966447]]]},"properties":{"id":"40807","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.886164,37.850573],[-87.833758,37.785499],[-87.754778,37.671332],[-87.734545,37.638376],[-87.644652,37.636649],[-87.627832,37.642805],[-87.624373,37.651334],[-87.749904,37.868622],[-87.886164,37.850573]]]},"properties":{"id":"42406","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.117794,36.763577],[-89.095346,36.754561],[-89.123531,36.785217],[-89.117794,36.763577]]]},"properties":{"id":"42032","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.4504,37.252434],[-87.475219,37.22954],[-87.449635,37.233997],[-87.4504,37.252434]]]},"properties":{"id":"42440","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.583685,38.261026],[-85.578338,38.224629],[-85.552589,38.223381],[-85.555705,38.226315],[-85.55616,38.248868],[-85.519473,38.255637],[-85.521155,38.230997],[-85.505447,38.223108],[-85.514159,38.286451],[-85.574168,38.286895],[-85.583685,38.261026]]]},"properties":{"id":"40223","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.705247,38.285256],[-85.675588,38.239384],[-85.636303,38.228617],[-85.623445,38.237687],[-85.67681,38.2998],[-85.705247,38.285256]],[[-85.663936,38.253575],[-85.667871,38.257415],[-85.663972,38.259044],[-85.659502,38.253432],[-85.663936,38.253575]]]},"properties":{"id":"40207","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.765564,38.205152],[-85.759868,38.200317],[-85.753545,38.212792],[-85.742059,38.229891],[-85.767838,38.23354],[-85.782928,38.220108],[-85.786619,38.215127],[-85.773538,38.205904],[-85.765564,38.205152]]]},"properties":{"id":"40208","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.574168,38.286895],[-85.605549,38.275557],[-85.583685,38.261026],[-85.574168,38.286895]]]},"properties":{"id":"40242","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.633001,38.161602],[-85.602608,38.101493],[-85.582395,38.076119],[-85.528053,38.084379],[-85.527347,38.084634],[-85.615993,38.181313],[-85.633001,38.161602]]]},"properties":{"id":"40291","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.505447,38.223108],[-85.4698,38.198646],[-85.410197,38.231139],[-85.385872,38.30801],[-85.468206,38.285309],[-85.507682,38.307093],[-85.526966,38.31795],[-85.514159,38.286451],[-85.505447,38.223108]]]},"properties":{"id":"40245","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.748773,38.126285],[-85.78616,38.124446],[-85.790154,38.065842],[-85.719976,38.085257],[-85.701463,38.115038],[-85.748773,38.126285]]]},"properties":{"id":"40118","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.692309,38.194342],[-85.692514,38.197169],[-85.697451,38.196899],[-85.698146,38.194261],[-85.692668,38.192061],[-85.692309,38.194342]]]},"properties":{"id":"40231","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.649132,38.298718],[-85.648523,38.303482],[-85.654782,38.301762],[-85.649132,38.298718]]]},"properties":{"id":"40025","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.70026,37.959026],[-82.778895,37.939198],[-82.784706,37.908106],[-82.765153,37.9016],[-82.72141,37.910211],[-82.70026,37.959026]]]},"properties":{"id":"41232","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.921027,37.992984],[-82.989014,37.963968],[-82.98406,37.950948],[-82.921027,37.992984]]]},"properties":{"id":"41226","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.793758,37.792214],[-82.780065,37.779899],[-82.783515,37.792133],[-82.793758,37.792214]]]},"properties":{"id":"41268","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.130211,37.33506],[-83.177311,37.321432],[-83.189713,37.302681],[-83.116612,37.308728],[-83.1327,37.314457],[-83.130211,37.33506]]],[[[-83.114392,37.35486],[-83.109625,37.344263],[-83.106301,37.351409],[-83.056204,37.388851],[-83.131505,37.460329],[-83.134099,37.457784],[-83.124348,37.404973],[-83.121782,37.39203],[-83.114392,37.35486]]]]},"properties":{"id":"41722","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.886896,37.299585],[-82.930395,37.307961],[-82.943265,37.294239],[-82.914774,37.27341],[-82.886896,37.299585]]],[[[-82.948508,37.242841],[-82.907562,37.210615],[-82.874947,37.237571],[-82.874886,37.238294],[-82.924781,37.257629],[-82.948508,37.242841]]]]},"properties":{"id":"41836","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.832441,37.396584],[-82.848873,37.370266],[-82.832404,37.330469],[-82.748131,37.30622],[-82.756007,37.377515],[-82.796001,37.417691],[-82.832441,37.396584]]]},"properties":{"id":"41862","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.875569,36.733201],[-83.887305,36.794968],[-83.944704,36.7895],[-83.934768,36.750367],[-83.880146,36.733731],[-83.875569,36.733201]]]},"properties":{"id":"40921","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.817405,36.780577],[-83.830129,36.739856],[-83.797481,36.784277],[-83.817405,36.780577]]]},"properties":{"id":"40995","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.773531,38.058409],[-82.778895,37.939198],[-82.70026,37.959026],[-82.673417,37.913464],[-82.676346,37.892208],[-82.612393,37.879272],[-82.579956,37.943593],[-82.501703,37.935474],[-82.610039,38.1754],[-82.699471,38.261913],[-82.791909,38.244356],[-82.813503,38.212911],[-82.741942,38.088909],[-82.773531,38.058409]]]},"properties":{"id":"41230","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.650398,37.689525],[-83.65556,37.691978],[-83.684265,37.68723],[-83.650398,37.689525]]]},"properties":{"id":"41397","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.314006,37.064234],[-83.323874,37.12403],[-83.342873,37.118885],[-83.355867,37.109475],[-83.355984,37.109388],[-83.377525,37.093368],[-83.314006,37.064234]]]},"properties":{"id":"41775","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.758546,37.155648],[-82.764149,37.151388],[-82.784977,37.125082],[-82.792542,37.120992],[-82.809946,37.074981],[-82.726688,37.114794],[-82.674243,37.136371],[-82.731426,37.153954],[-82.739852,37.156802],[-82.758546,37.155648]]]},"properties":{"id":"41837","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.565379,37.196099],[-82.575266,37.205562],[-82.591843,37.195514],[-82.586322,37.185864],[-82.565379,37.196099]]]},"properties":{"id":"41517","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.953479,37.076441],[-83.035531,37.023941],[-82.997539,37.005382],[-82.941632,37.074302],[-82.953479,37.076441]]]},"properties":{"id":"41833","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.733851,37.229806],[-82.694501,37.199099],[-82.682245,37.228129],[-82.706886,37.258249],[-82.733851,37.229806]]]},"properties":{"id":"41825","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.758546,37.155648],[-82.777903,37.212441],[-82.814394,37.187676],[-82.780338,37.150308],[-82.764149,37.151388],[-82.758546,37.155648]]]},"properties":{"id":"41855","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.005109,37.12167],[-83.048892,37.169421],[-83.134066,37.104146],[-83.06837,37.050776],[-83.005109,37.12167]]]},"properties":{"id":"41731","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.377583,37.352421],[-88.384543,37.266745],[-88.355613,37.291771],[-88.377583,37.352421]]]},"properties":{"id":"42047","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.416816,36.94692],[-84.575028,36.868689],[-84.340212,36.835816],[-84.297416,36.945931],[-84.358024,36.9594],[-84.416816,36.94692]]]},"properties":{"id":"42634","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.340212,36.835816],[-84.355095,36.715344],[-84.349793,36.657573],[-84.227292,36.592045],[-84.050949,36.590417],[-83.956234,36.645491],[-83.964468,36.709914],[-83.987028,36.755928],[-84.165053,36.819934],[-84.340212,36.835816]]]},"properties":{"id":"40769","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.089458,37.567259],[-87.136872,37.484922],[-87.135629,37.485153],[-87.043957,37.493663],[-87.053462,37.546474],[-87.089458,37.567259]]]},"properties":{"id":"42352","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.525643,37.769497],[-84.445999,37.651279],[-84.337755,37.638887],[-84.190173,37.654793],[-84.099307,37.660853],[-84.091794,37.698566],[-84.129699,37.867706],[-84.288628,37.910775],[-84.48512,37.840637],[-84.525643,37.769497]]]},"properties":{"id":"40475","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.903901,38.768182],[-83.960746,38.657591],[-83.923183,38.654182],[-83.842284,38.72607],[-83.903901,38.768182]],[[-83.927619,38.703543],[-83.924077,38.707649],[-83.919062,38.705572],[-83.92221,38.70012],[-83.927619,38.703543]]]},"properties":{"id":"41034","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.05372,37.917456],[-86.252846,38.047533],[-86.310499,37.989614],[-86.309727,37.980038],[-86.297488,37.954667],[-86.187647,37.917709],[-86.086971,37.910687],[-86.05372,37.917456]]]},"properties":{"id":"40108","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.372544,38.034043],[-86.412856,37.988928],[-86.393733,37.975842],[-86.38775,37.971716],[-86.363422,38.030781],[-86.372544,38.034043]]]},"properties":{"id":"40161","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.756469,37.751079],[-84.773422,37.756814],[-84.775514,37.745685],[-84.756469,37.751079]]]},"properties":{"id":"40310","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.502838,36.614876],[-85.502207,36.662798],[-85.624189,36.675961],[-85.587566,36.616477],[-85.502838,36.614876]]]},"properties":{"id":"42151","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.871275,37.906209],[-83.768783,37.918371],[-83.774374,37.949865],[-83.779768,38.006471],[-83.780847,38.011058],[-83.982268,37.959704],[-83.96631,37.930768],[-83.871275,37.906209]]]},"properties":{"id":"40337","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.294227,37.82071],[-83.299653,37.759258],[-83.252144,37.751658],[-83.263286,37.772486],[-83.294227,37.82071]]]},"properties":{"id":"41408","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.164603,37.24806],[-87.171472,37.235215],[-87.151872,37.235845],[-87.164603,37.24806]]]},"properties":{"id":"42367","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.913714,37.177601],[-86.98544,37.196009],[-87.017952,37.177783],[-87.049593,37.163844],[-87.052079,37.158309],[-87.061021,37.06681],[-87.033656,37.077005],[-86.913714,37.177601]]]},"properties":{"id":"42324","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.099803,37.416042],[-87.012017,37.345727],[-86.983823,37.347673],[-86.939788,37.437379],[-87.043957,37.493663],[-87.135629,37.485153],[-87.099803,37.416042]]]},"properties":{"id":"42328","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.983823,37.347673],[-87.012017,37.345727],[-86.991191,37.32558],[-86.983823,37.347673]]]},"properties":{"id":"42369","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.507682,38.307093],[-85.468206,38.285309],[-85.4839,38.317134],[-85.507682,38.307093]]]},"properties":{"id":"40056","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.296297,37.310804],[-83.356813,37.336247],[-83.325518,37.281975],[-83.305735,37.280522],[-83.296297,37.310804]]]},"properties":{"id":"41754","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.114392,37.35486],[-83.121782,37.39203],[-83.136113,37.393733],[-83.134782,37.347299],[-83.114392,37.35486]]]},"properties":{"id":"41712","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.706886,37.258249],[-82.682245,37.228129],[-82.653972,37.235634],[-82.586094,37.318069],[-82.5836,37.31647],[-82.575595,37.316795],[-82.572103,37.371608],[-82.616215,37.399445],[-82.642806,37.380637],[-82.664592,37.367861],[-82.657772,37.34639],[-82.710805,37.285323],[-82.732693,37.271502],[-82.706886,37.258249]]]},"properties":{"id":"41572","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.259355,37.578334],[-82.310061,37.546951],[-82.284028,37.515021],[-82.256251,37.570946],[-82.259355,37.578334]]]},"properties":{"id":"41543","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.36093,37.372638],[-82.302327,37.349752],[-82.302833,37.350083],[-82.354026,37.387142],[-82.36093,37.372638]]]},"properties":{"id":"41513","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.70514,36.981945],[-84.696561,37.102788],[-84.811674,37.173532],[-84.901112,37.116297],[-85.003151,36.954659],[-84.972809,36.910375],[-84.70514,36.981945]]]},"properties":{"id":"42544","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.596495,37.062716],[-84.57971,37.070192],[-84.593853,37.080346],[-84.607228,37.070802],[-84.596495,37.062716]]]},"properties":{"id":"42533","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.188844,37.458601],[-84.233135,37.33549],[-84.164901,37.422685],[-84.188844,37.458601]]]},"properties":{"id":"40460","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.526851,36.845646],[-86.461055,36.802251],[-86.39085,36.786345],[-86.392039,36.91444],[-86.439653,36.982781],[-86.526851,36.845646]]]},"properties":{"id":"42104","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.186317,37.384382],[-85.196906,37.378207],[-85.195791,37.370719],[-85.186317,37.384382]]]},"properties":{"id":"42758","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.71244,36.720022],[-87.706405,36.785226],[-87.699769,36.929779],[-87.75453,36.983802],[-87.891206,36.959904],[-88.00496,36.909496],[-87.71244,36.720022]]]},"properties":{"id":"42211","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.043365,37.765708],[-88.124938,37.570074],[-87.891168,37.558099],[-87.78907,37.563518],[-87.734545,37.638376],[-87.754778,37.671332],[-87.895365,37.73207],[-88.043365,37.765708]]]},"properties":{"id":"42437","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.05433,37.21085],[-84.946128,37.1419],[-84.826825,37.200979],[-84.776783,37.198536],[-84.771257,37.202506],[-84.81165,37.266021],[-84.822899,37.347994],[-84.853603,37.399945],[-85.043542,37.441403],[-85.044502,37.419116],[-85.147169,37.304271],[-85.05433,37.21085]]]},"properties":{"id":"42539","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.783947,36.910572],[-85.788294,36.828705],[-85.596214,36.818039],[-85.59115,36.819677],[-85.578818,36.842461],[-85.633089,36.866842],[-85.642554,36.865333],[-85.652667,36.875361],[-85.74747,36.962762],[-85.7574,36.937503],[-85.783947,36.910572]]]},"properties":{"id":"42166","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.769161,38.016004],[-83.773403,38.019363],[-83.780847,38.011058],[-83.779768,38.006471],[-83.769161,38.016004]]]},"properties":{"id":"40334","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.75565,36.800576],[-83.705974,36.796849],[-83.687953,36.836315],[-83.708931,36.827061],[-83.75565,36.800576]]]},"properties":{"id":"40939","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.562851,36.764287],[-83.572112,36.673565],[-83.584238,36.641593],[-83.461016,36.66486],[-83.459459,36.697387],[-83.489502,36.764418],[-83.541757,36.792009],[-83.562851,36.764287]]]},"properties":{"id":"40856","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.706824,38.990741],[-84.638743,38.934872],[-84.609133,38.957166],[-84.596475,38.969954],[-84.642979,39.055237],[-84.697001,39.044437],[-84.706824,38.990741]]]},"properties":{"id":"41042","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.623752,39.074353],[-84.775662,39.128495],[-84.777848,39.097725],[-84.778116,39.088094],[-84.697001,39.044437],[-84.642979,39.055237],[-84.622223,39.06247],[-84.623752,39.074353]]]},"properties":{"id":"41048","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.378451,37.397545],[-83.357366,37.358003],[-83.35728,37.358127],[-83.257942,37.408386],[-83.215189,37.443167],[-83.279321,37.467043],[-83.375595,37.422592],[-83.378451,37.397545]]]},"properties":{"id":"41348","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.134099,37.457784],[-83.279321,37.467043],[-83.215189,37.443167],[-83.204936,37.424277],[-83.124348,37.404973],[-83.134099,37.457784]]]},"properties":{"id":"41317","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.427295,37.68845],[-86.421167,37.856712],[-86.451397,37.938497],[-86.46971,37.930976],[-86.60376,37.859344],[-86.633468,37.844057],[-86.595478,37.715199],[-86.465537,37.621093],[-86.438959,37.655251],[-86.427295,37.68845]]]},"properties":{"id":"40143","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.286941,37.671007],[-86.357816,37.634642],[-86.352268,37.626114],[-86.27527,37.593519],[-86.243898,37.643684],[-86.286941,37.671007]]]},"properties":{"id":"40145","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.221895,37.680644],[-86.254647,37.738311],[-86.357082,37.782633],[-86.286941,37.671007],[-86.243898,37.643684],[-86.221895,37.680644]]]},"properties":{"id":"40140","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.271798,38.016411],[-85.221799,38.085159],[-85.22326,38.085747],[-85.304823,38.145595],[-85.373964,38.131948],[-85.42883,38.118383],[-85.527347,38.084634],[-85.528053,38.084379],[-85.476616,38.018773],[-85.39968,37.93994],[-85.383403,37.939839],[-85.352535,37.94496],[-85.271798,38.016411]]]},"properties":{"id":"40071","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.947114,38.005085],[-85.990407,37.95383],[-85.92557,37.992253],[-85.878791,37.996781],[-85.850225,38.04515],[-85.947114,38.005085]]]},"properties":{"id":"40177","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.615993,38.181313],[-85.527347,38.084634],[-85.42883,38.118383],[-85.4698,38.198646],[-85.505447,38.223108],[-85.521155,38.230997],[-85.555705,38.226315],[-85.552589,38.223381],[-85.615836,38.193686],[-85.615993,38.181313]]]},"properties":{"id":"40299","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.90616,38.116887],[-85.947114,38.005085],[-85.850225,38.04515],[-85.820814,38.047808],[-85.790154,38.065842],[-85.78616,38.124446],[-85.829296,38.147067],[-85.90616,38.116887]]]},"properties":{"id":"40272","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.417608,37.00538],[-86.285739,37.013695],[-86.269844,37.081655],[-86.275941,37.133888],[-86.399165,37.169896],[-86.466415,37.152623],[-86.667359,37.018268],[-86.610328,36.89118],[-86.526851,36.845646],[-86.439653,36.982781],[-86.416596,37.003776],[-86.420163,37.001296],[-86.41827,37.005611],[-86.417608,37.00538]]]},"properties":{"id":"42101","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.57004,37.200733],[-87.642666,37.269036],[-87.73413,37.298843],[-87.761209,37.302107],[-87.69254,37.100022],[-87.616038,37.067982],[-87.624602,37.120944],[-87.561834,37.19667],[-87.57004,37.200733]]]},"properties":{"id":"42408","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.16789,36.532255],[-88.488447,36.539903],[-88.498243,36.516198],[-88.5233,36.501759],[-88.183246,36.49944],[-88.16789,36.532255]]]},"properties":{"id":"42049","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.360714,36.728161],[-88.360803,36.710698],[-88.236384,36.681434],[-88.32222,36.745993],[-88.360604,36.735449],[-88.360714,36.728161]]]},"properties":{"id":"42020","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.494135,39.102865],[-84.470854,39.095933],[-84.462971,39.102124],[-84.485244,39.1137],[-84.494135,39.102865]]]},"properties":{"id":"41073","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.241435,38.836348],[-84.375543,38.850025],[-84.422765,38.824851],[-84.418107,38.806596],[-84.419583,38.717606],[-84.28464,38.75504],[-84.241435,38.836348]]]},"properties":{"id":"41006","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.903673,36.938354],[-88.834973,36.861407],[-88.7786,36.880708],[-88.775906,36.886971],[-88.815739,36.953595],[-88.815952,36.9541],[-88.826731,36.951769],[-88.85508,36.950396],[-88.903673,36.938354]]]},"properties":{"id":"42035","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.124411,38.643081],[-85.176283,38.584493],[-85.077654,38.503069],[-85.055556,38.513936],[-85.049232,38.570706],[-85.124411,38.643081]]]},"properties":{"id":"40075","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.049232,38.570706],[-85.029154,38.507901],[-84.955794,38.472703],[-84.900979,38.613036],[-84.934295,38.632909],[-85.078208,38.65461],[-85.124411,38.643081],[-85.049232,38.570706]],[[-85.010603,38.51291],[-85.010975,38.514963],[-85.008391,38.514674],[-85.010603,38.51291]]]},"properties":{"id":"41098","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.243406,38.67375],[-85.434088,38.594924],[-85.433189,38.524375],[-85.405886,38.518943],[-85.273364,38.500061],[-85.26931,38.504215],[-85.229151,38.627205],[-85.243406,38.67375]]]},"properties":{"id":"40006","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.880105,37.292174],[-83.943718,37.250551],[-83.863257,37.090259],[-83.871315,37.054919],[-83.721219,37.002209],[-83.719925,36.995246],[-83.589521,36.955786],[-83.531214,36.943122],[-83.536681,36.992119],[-83.528031,37.025706],[-83.539184,37.070107],[-83.580959,37.166274],[-83.68834,37.336776],[-83.71781,37.338805],[-83.808726,37.320901],[-83.880105,37.292174]],[[-83.750503,37.128677],[-83.717748,37.131517],[-83.724045,37.116002],[-83.750503,37.128677]]]},"properties":{"id":"40962","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.007981,36.76502],[-85.026277,36.806688],[-85.047395,36.781602],[-85.007981,36.76502]]]},"properties":{"id":"42603","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.578818,36.842461],[-85.59115,36.819677],[-85.499908,36.857285],[-85.578818,36.842461]]]},"properties":{"id":"42731","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.207406,37.332566],[-86.336814,37.326017],[-86.333619,37.27879],[-86.248002,37.277339],[-86.215175,37.31238],[-86.207406,37.332566]]]},"properties":{"id":"42207","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.483832,38.065469],[-84.479926,38.041314],[-84.453474,38.025781],[-84.419845,38.041068],[-84.451098,38.07792],[-84.483832,38.065469]]]},"properties":{"id":"40505","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.514689,38.032045],[-84.551302,38.015531],[-84.571578,38.002167],[-84.530068,37.976329],[-84.513662,37.993759],[-84.501687,38.025568],[-84.50614,38.026378],[-84.514689,38.032045]]]},"properties":{"id":"40503","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.479926,38.041314],[-84.483832,38.065469],[-84.523279,38.058584],[-84.514689,38.032045],[-84.50614,38.026378],[-84.500307,38.028679],[-84.492849,38.036752],[-84.503771,38.049501],[-84.498184,38.054591],[-84.490222,38.046511],[-84.490225,38.040358],[-84.479926,38.041314]]]},"properties":{"id":"40508","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.565728,38.04574],[-84.649258,38.043207],[-84.646601,38.000211],[-84.592335,37.986428],[-84.571578,38.002167],[-84.551302,38.015531],[-84.565728,38.04574]]]},"properties":{"id":"40513","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.490225,38.040358],[-84.490222,38.046511],[-84.498184,38.054591],[-84.503771,38.049501],[-84.492849,38.036752],[-84.490225,38.040358]]]},"properties":{"id":"40507","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.712918,37.469568],[-82.771616,37.478639],[-82.775076,37.4685],[-82.773962,37.440523],[-82.767931,37.427657],[-82.764204,37.426046],[-82.685174,37.386284],[-82.672472,37.408832],[-82.687834,37.449075],[-82.686095,37.45489],[-82.712918,37.469568]]]},"properties":{"id":"41647","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.703443,38.573796],[-84.502638,38.548959],[-84.48715,38.587329],[-84.446972,38.701061],[-84.517245,38.722722],[-84.738447,38.595701],[-84.703443,38.573796]]]},"properties":{"id":"41097","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.542201,36.64002],[-88.635498,36.585608],[-88.653405,36.576477],[-88.645599,36.502193],[-88.5233,36.501759],[-88.498243,36.516198],[-88.542201,36.64002]]]},"properties":{"id":"42079","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.718755,36.907145],[-88.633422,36.855633],[-88.58161,36.858944],[-88.556315,36.897366],[-88.528015,36.942547],[-88.678573,36.9589],[-88.688602,36.942728],[-88.718755,36.907145]]]},"properties":{"id":"42027","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.756747,38.532418],[-82.760865,38.548441],[-82.766965,38.56055],[-82.875051,38.705319],[-83.061247,38.559181],[-83.027938,38.486518],[-82.905584,38.408189],[-82.75591,38.465422],[-82.73067,38.487006],[-82.756747,38.532418]]]},"properties":{"id":"41144","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.760865,38.548441],[-82.708154,38.548222],[-82.766965,38.56055],[-82.760865,38.548441]]]},"properties":{"id":"41183","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.199034,38.616843],[-83.179963,38.501012],[-83.027938,38.486518],[-83.061247,38.559181],[-83.093318,38.573772],[-83.154524,38.620761],[-83.199034,38.616843]]]},"properties":{"id":"41141","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.675769,37.735951],[-86.772898,37.64369],[-86.800344,37.599848],[-86.792938,37.559548],[-86.765848,37.560214],[-86.730481,37.56469],[-86.641606,37.559747],[-86.640253,37.699505],[-86.653593,37.732607],[-86.675769,37.735951]]]},"properties":{"id":"42343","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.968042,37.775396],[-86.161192,37.742691],[-86.059816,37.717992],[-85.918325,37.766754],[-85.968042,37.775396]]]},"properties":{"id":"40162","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.502341,36.820189],[-83.485814,36.783293],[-83.424482,36.770878],[-83.502341,36.820189]]]},"properties":{"id":"40819","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.141585,36.89667],[-83.14404,36.883437],[-83.117851,36.893163],[-83.126215,36.900684],[-83.141585,36.89667]]]},"properties":{"id":"40849","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.489502,36.764418],[-83.459459,36.697387],[-83.3815,36.743597],[-83.424482,36.770878],[-83.485814,36.783293],[-83.489502,36.764418]]]},"properties":{"id":"40863","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.338423,36.852275],[-83.370663,36.844852],[-83.354547,36.827821],[-83.338423,36.852275]]]},"properties":{"id":"40854","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.4576,36.863156],[-83.418966,36.935323],[-83.447187,36.923943],[-83.489813,36.895413],[-83.4576,36.863156]]]},"properties":{"id":"40840","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.829304,37.355654],[-85.950354,37.407087],[-86.03994,37.413953],[-86.065793,37.359833],[-86.027832,37.250324],[-85.806211,37.232053],[-85.77583,37.283735],[-85.763868,37.319932],[-85.829304,37.355654]]]},"properties":{"id":"42765","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.033817,36.595682],[-88.890341,36.634297],[-88.82315,36.669718],[-88.919971,36.772406],[-89.095346,36.754561],[-89.117794,36.763577],[-89.104861,36.629318],[-89.033817,36.595682]]]},"properties":{"id":"42031","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.616783,37.526054],[-87.616193,37.471851],[-87.598608,37.459388],[-87.560808,37.470637],[-87.354286,37.510786],[-87.354556,37.528374],[-87.375145,37.569988],[-87.616783,37.526054]]]},"properties":{"id":"42456","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.880105,37.292174],[-83.945068,37.346018],[-84.056424,37.284611],[-84.050439,37.268075],[-83.943718,37.250551],[-83.880105,37.292174]]]},"properties":{"id":"40402","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.164901,37.422685],[-84.233135,37.33549],[-84.27117,37.214129],[-84.239242,37.240523],[-84.137898,37.319759],[-84.164901,37.422685]]]},"properties":{"id":"40445","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.785725,38.253691],[-85.834002,38.268715],[-85.865815,38.2134],[-85.800186,38.219216],[-85.786645,38.248488],[-85.785725,38.253691]]]},"properties":{"id":"40211","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.738281,38.251799],[-85.698169,38.24489],[-85.675588,38.239384],[-85.705247,38.285256],[-85.744801,38.267204],[-85.738281,38.251799]],[[-85.692875,38.247268],[-85.685209,38.249983],[-85.683187,38.247526],[-85.688402,38.245175],[-85.692875,38.247268]]]},"properties":{"id":"40206","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.698169,38.24489],[-85.719902,38.225769],[-85.713067,38.217316],[-85.689064,38.20207],[-85.651185,38.221537],[-85.636303,38.228617],[-85.675588,38.239384],[-85.698169,38.24489]]]},"properties":{"id":"40205","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.615836,38.193686],[-85.552589,38.223381],[-85.578338,38.224629],[-85.623445,38.237687],[-85.636303,38.228617],[-85.651185,38.221537],[-85.615836,38.193686]]]},"properties":{"id":"40220","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.501983,39.057119],[-84.519061,39.049469],[-84.502985,38.970523],[-84.494943,38.918553],[-84.444698,38.912086],[-84.428487,38.975104],[-84.494814,39.05158],[-84.497612,39.056311],[-84.501983,39.057119]]]},"properties":{"id":"41015","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.019658,37.345895],[-83.047651,37.346337],[-83.03352,37.331066],[-83.019658,37.345895]]]},"properties":{"id":"41725","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.070787,37.015528],[-84.062023,37.024396],[-84.067685,37.028018],[-84.070787,37.015528]]],[[[-84.070787,37.015528],[-84.105832,37.001654],[-84.16106,37.003388],[-84.297416,36.945931],[-84.340212,36.835816],[-84.165053,36.819934],[-84.069694,36.887141],[-84.043179,36.887167],[-83.972619,36.827521],[-83.972203,36.827615],[-83.986277,36.872885],[-84.002899,36.989709],[-84.070787,37.015528]]]]},"properties":{"id":"40701","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.759195,36.803044],[-83.75565,36.800576],[-83.708931,36.827061],[-83.707957,36.893485],[-83.69956,36.963233],[-83.665625,36.956],[-83.679372,36.924069],[-83.615552,36.891261],[-83.609795,36.896302],[-83.589521,36.955786],[-83.719925,36.995246],[-83.775119,36.951217],[-83.797028,36.904254],[-83.826063,36.840468],[-83.759195,36.803044]]]},"properties":{"id":"40935","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.77407,38.077452],[-82.927675,38.089862],[-82.95279,38.052827],[-82.919385,37.992447],[-82.831585,37.967869],[-82.822906,37.96313],[-82.778895,37.939198],[-82.773531,38.058409],[-82.77407,38.077452]]]},"properties":{"id":"41124","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.48239,37.021129],[-83.5275,37.025663],[-83.522219,36.999324],[-83.48239,37.021129]]]},"properties":{"id":"40979","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.751546,37.266673],[-82.751532,37.221943],[-82.736594,37.229018],[-82.733851,37.229806],[-82.706886,37.258249],[-82.732693,37.271502],[-82.751546,37.266673]]]},"properties":{"id":"41812","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.751532,37.221943],[-82.777903,37.212441],[-82.758546,37.155648],[-82.739852,37.156802],[-82.74363,37.160387],[-82.724782,37.184831],[-82.736594,37.229018],[-82.751532,37.221943]]]},"properties":{"id":"41838","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.302563,38.330678],[-83.261252,38.345554],[-83.262522,38.379311],[-83.312865,38.36664],[-83.317626,38.332094],[-83.302563,38.330678]]]},"properties":{"id":"41135","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.424739,37.150943],[-88.566061,37.075204],[-88.482957,37.022566],[-88.434682,37.04742],[-88.424739,37.150943]]]},"properties":{"id":"42058","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.480629,36.738692],[-84.480452,36.743712],[-84.484111,36.744036],[-84.485193,36.74074],[-84.480629,36.738692]]]},"properties":{"id":"42631","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.448905,37.869615],[-82.452786,37.813811],[-82.409831,37.837736],[-82.411409,37.842193],[-82.448905,37.869615]]]},"properties":{"id":"41203","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.927619,38.703543],[-83.92221,38.70012],[-83.919062,38.705572],[-83.924077,38.707649],[-83.927619,38.703543]]]},"properties":{"id":"41062","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.738391,36.620496],[-85.866334,36.710932],[-85.920663,36.625999],[-85.738391,36.620496]]]},"properties":{"id":"42140","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.417908,37.92471],[-83.49508,37.861906],[-83.484258,37.84391],[-83.373522,37.858563],[-83.349663,37.86662],[-83.417908,37.92471]]]},"properties":{"id":"41425","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.164603,37.24806],[-87.151872,37.235845],[-87.107355,37.209615],[-87.096934,37.251439],[-87.080825,37.25026],[-86.988288,37.302688],[-86.991191,37.32558],[-87.012017,37.345727],[-87.099803,37.416042],[-87.181442,37.405065],[-87.207901,37.375967],[-87.286361,37.280832],[-87.250434,37.280568],[-87.164603,37.24806]],[[-87.139793,37.336699],[-87.136304,37.320115],[-87.170926,37.315626],[-87.162777,37.354731],[-87.139793,37.336699]]]},"properties":{"id":"42330","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.352535,37.94496],[-85.378211,37.932294],[-85.397184,37.883035],[-85.313237,37.809879],[-85.278616,37.84412],[-85.196072,37.876436],[-85.162525,37.945403],[-85.167834,37.971809],[-85.271798,38.016411],[-85.352535,37.94496]]]},"properties":{"id":"40008","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.162525,37.945403],[-85.196072,37.876436],[-85.152797,37.897661],[-85.162525,37.945403]]]},"properties":{"id":"40012","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.39968,37.93994],[-85.378211,37.932294],[-85.383403,37.939839],[-85.39968,37.93994]]]},"properties":{"id":"40020","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.765848,37.560214],[-86.770244,37.541587],[-86.793207,37.531916],[-86.813689,37.551474],[-86.792938,37.559548],[-86.800344,37.599848],[-86.950165,37.553477],[-87.053462,37.546474],[-87.043957,37.493663],[-86.939788,37.437379],[-86.786016,37.490376],[-86.730481,37.56469],[-86.765848,37.560214]]]},"properties":{"id":"42347","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.900979,38.613036],[-84.955794,38.472703],[-84.870482,38.356751],[-84.768355,38.344282],[-84.668647,38.404879],[-84.703443,38.573796],[-84.738447,38.595701],[-84.776476,38.637675],[-84.900979,38.613036]]]},"properties":{"id":"40359","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.167206,37.094851],[-83.182104,37.021115],[-83.178972,37.020039],[-83.160647,37.016334],[-83.055084,37.020362],[-83.049517,37.029685],[-83.06837,37.050776],[-83.134066,37.104146],[-83.167206,37.094851]]]},"properties":{"id":"41763","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.294807,37.580939],[-82.308231,37.551852],[-82.310061,37.546951],[-82.259355,37.578334],[-82.259948,37.597395],[-82.294807,37.580939]]]},"properties":{"id":"41567","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075507,37.555896],[-82.119457,37.520142],[-82.095152,37.448385],[-82.075507,37.555896]]]},"properties":{"id":"41568","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.303061,37.60857],[-82.292156,37.651131],[-82.31803,37.626898],[-82.303061,37.60857]]]},"properties":{"id":"41527","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.499846,37.426015],[-82.504563,37.42707],[-82.506459,37.432465],[-82.511864,37.436327],[-82.514341,37.433281],[-82.507091,37.431144],[-82.504097,37.424927],[-82.499393,37.424317],[-82.499846,37.426015]]]},"properties":{"id":"41526","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.406432,37.467453],[-82.436877,37.470686],[-82.417728,37.432747],[-82.389985,37.456606],[-82.406432,37.467453]]],[[[-82.414015,37.365257],[-82.429865,37.371736],[-82.499846,37.426015],[-82.499393,37.424317],[-82.503172,37.337433],[-82.414015,37.365257]]]]},"properties":{"id":"41562","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.388609,37.418153],[-82.302833,37.350083],[-82.302327,37.349752],[-82.254513,37.337741],[-82.202576,37.374163],[-82.255011,37.396531],[-82.268705,37.429549],[-82.368268,37.426458],[-82.391694,37.420887],[-82.388609,37.418153]]]},"properties":{"id":"41548","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.255011,37.396531],[-82.208303,37.438274],[-82.268705,37.429549],[-82.255011,37.396531]]]},"properties":{"id":"41524","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.213046,36.786301],[-87.12921,36.757822],[-87.06464,36.767059],[-87.056281,36.795877],[-87.056485,36.866093],[-87.082621,36.919793],[-87.12934,36.981888],[-87.146207,37.043801],[-87.261843,37.057462],[-87.294334,36.878839],[-87.272537,36.822534],[-87.213046,36.786301]]]},"properties":{"id":"42220","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.082621,36.919793],[-87.054871,36.997864],[-87.12934,36.981888],[-87.082621,36.919793]]]},"properties":{"id":"42280","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.7054,37.471418],[-87.658532,37.428776],[-87.616193,37.471851],[-87.616783,37.526054],[-87.644652,37.636649],[-87.734545,37.638376],[-87.78907,37.563518],[-87.7054,37.471418]]]},"properties":{"id":"42409","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.946128,37.1419],[-85.05433,37.21085],[-85.130371,37.191863],[-85.090996,37.188631],[-84.95153,37.126274],[-84.946128,37.1419]]]},"properties":{"id":"42528","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.45631,36.966574],[-85.526881,37.10945],[-85.632399,37.125113],[-85.697687,37.116995],[-85.696354,37.07855],[-85.755426,37.026786],[-85.74747,36.962762],[-85.652667,36.875361],[-85.633089,36.866842],[-85.578818,36.842461],[-85.499908,36.857285],[-85.45183,36.938138],[-85.45631,36.966574]]]},"properties":{"id":"42129","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.45631,36.966574],[-85.45183,36.938138],[-85.329754,36.930683],[-85.45631,36.966574]]]},"properties":{"id":"42715","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.401659,36.650701],[-86.348155,36.691937],[-86.39085,36.786345],[-86.461055,36.802251],[-86.635885,36.826861],[-86.728359,36.775351],[-86.751603,36.724476],[-86.729415,36.649001],[-86.401659,36.650701]]]},"properties":{"id":"42134","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.401659,36.650701],[-86.104827,36.635061],[-86.348155,36.691937],[-86.401659,36.650701]]]},"properties":{"id":"42120","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.04673,36.696297],[-86.062673,36.632865],[-86.032049,36.630688],[-86.04673,36.696297]]]},"properties":{"id":"42153","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.167834,37.971809],[-85.162525,37.945403],[-85.152797,37.897661],[-85.059587,37.890433],[-85.030528,37.891538],[-84.796327,37.970088],[-84.864908,38.116926],[-84.971663,38.120502],[-85.101646,38.03727],[-85.167834,37.971809]]]},"properties":{"id":"40342","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.170111,37.132771],[-86.275941,37.133888],[-86.269844,37.081655],[-86.204176,36.933784],[-86.136291,36.932116],[-86.029354,37.05031],[-86.122293,37.07429],[-86.137256,37.069133],[-86.147268,37.089345],[-86.170111,37.132771]]]},"properties":{"id":"42171","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.056117,36.828892],[-86.03913,36.789871],[-85.925994,36.799037],[-86.033621,36.849857],[-86.056117,36.828892]]]},"properties":{"id":"42123","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.801976,38.279248],[-83.875895,38.131737],[-83.773403,38.019363],[-83.769161,38.016004],[-83.759807,38.008595],[-83.71729,38.003288],[-83.717707,38.023293],[-83.689671,38.109021],[-83.646546,38.170963],[-83.759388,38.288238],[-83.801976,38.279248]]]},"properties":{"id":"40360","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.080527,38.115118],[-83.982268,37.959704],[-83.780847,38.011058],[-83.773403,38.019363],[-83.875895,38.131737],[-83.977924,38.192118],[-84.012219,38.1613],[-84.080527,38.115118]]]},"properties":{"id":"40353","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.705536,38.497214],[-82.63443,38.436758],[-82.601477,38.442724],[-82.665411,38.505787],[-82.705536,38.497214]]]},"properties":{"id":"41101","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.922579,37.689628],[-85.028169,37.682929],[-85.039298,37.631247],[-85.027812,37.557786],[-84.940721,37.625835],[-84.922579,37.689628]]]},"properties":{"id":"40468","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.205424,37.597581],[-83.215486,37.607552],[-83.228574,37.592709],[-83.209406,37.587755],[-83.205424,37.597581]]]},"properties":{"id":"41366","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.655272,37.842521],[-86.653593,37.732607],[-86.640253,37.699505],[-86.595478,37.715199],[-86.633468,37.844057],[-86.655272,37.842521]]]},"properties":{"id":"40111","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.254647,37.738311],[-86.221895,37.680644],[-86.180449,37.750338],[-86.253882,37.781917],[-86.254647,37.738311]]]},"properties":{"id":"40115","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.894297,37.179222],[-86.779745,37.229124],[-86.899268,37.212313],[-86.894376,37.179288],[-86.894297,37.179222]]]},"properties":{"id":"42273","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.163502,37.143074],[-88.102776,37.050193],[-88.00496,36.909496],[-87.891206,36.959904],[-88.01724,37.134942],[-88.163502,37.143074]]]},"properties":{"id":"42038","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.183246,36.49944],[-88.055717,36.633767],[-88.16789,36.532255],[-88.183246,36.49944]]]},"properties":{"id":"42076","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.149294,36.748635],[-88.236384,36.681434],[-88.360803,36.710698],[-88.460012,36.686064],[-88.488447,36.539903],[-88.16789,36.532255],[-88.055717,36.633767],[-88.110831,36.74715],[-88.149294,36.748635]]]},"properties":{"id":"42071","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.126929,36.79475],[-88.360604,36.735449],[-88.32222,36.745993],[-88.149294,36.748635],[-88.110831,36.74715],[-88.126929,36.79475]]]},"properties":{"id":"42048","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.485244,39.1137],[-84.462971,39.102124],[-84.440986,39.109557],[-84.485244,39.1137]]]},"properties":{"id":"41074","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.434088,38.594924],[-85.243406,38.67375],[-85.333099,38.736228],[-85.434088,38.594924]]]},"properties":{"id":"40045","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.828558,38.292722],[-82.874994,38.266937],[-82.912524,38.269874],[-82.815257,38.217364],[-82.813503,38.212911],[-82.791909,38.244356],[-82.828558,38.292722]]]},"properties":{"id":"41132","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.822899,37.347994],[-84.81165,37.266021],[-84.724343,37.23578],[-84.720712,37.238328],[-84.719838,37.263296],[-84.752211,37.290986],[-84.816901,37.334806],[-84.822899,37.347994]]]},"properties":{"id":"42566","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.826825,37.200979],[-84.811674,37.173532],[-84.776783,37.198536],[-84.826825,37.200979]]],[[[-84.81165,37.266021],[-84.771257,37.202506],[-84.724343,37.23578],[-84.81165,37.266021]]]]},"properties":{"id":"42516","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.586675,36.95834],[-87.616038,37.067982],[-87.69254,37.100022],[-87.75453,36.983802],[-87.699769,36.929779],[-87.602666,36.936338],[-87.586675,36.95834]]]},"properties":{"id":"42215","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.596214,36.818039],[-85.436442,36.618655],[-85.296002,36.625718],[-85.215405,36.854467],[-85.232026,36.925069],[-85.329754,36.930683],[-85.45183,36.938138],[-85.499908,36.857285],[-85.59115,36.819677],[-85.596214,36.818039]]]},"properties":{"id":"42717","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.738391,36.620496],[-85.587566,36.616477],[-85.624189,36.675961],[-85.502207,36.662798],[-85.502838,36.614876],[-85.436442,36.618655],[-85.596214,36.818039],[-85.788294,36.828705],[-85.757609,36.797861],[-85.861729,36.756615],[-85.878143,36.780059],[-85.901976,36.766077],[-85.866334,36.710932],[-85.738391,36.620496]]]},"properties":{"id":"42167","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.117907,37.697029],[-87.044611,37.708016],[-86.953219,37.796936],[-87.043691,37.834669],[-87.108858,37.776177],[-87.117907,37.697029]]]},"properties":{"id":"42303","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.977026,37.923718],[-86.928983,37.807506],[-86.876666,37.811754],[-86.776497,37.950155],[-86.977026,37.923718]]]},"properties":{"id":"42351","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.065793,37.359833],[-86.2009,37.329481],[-86.207406,37.332566],[-86.215175,37.31238],[-86.111276,37.243372],[-86.04957,37.215767],[-86.027832,37.250324],[-86.065793,37.359833]]]},"properties":{"id":"42729","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.910875,37.766657],[-84.001967,37.837592],[-84.080337,37.853066],[-84.091794,37.698566],[-84.099307,37.660853],[-84.114751,37.611302],[-84.090364,37.566227],[-83.90557,37.542856],[-83.784448,37.639408],[-83.756471,37.708537],[-83.910875,37.766657]]]},"properties":{"id":"40336","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.514689,38.032045],[-84.523279,38.058584],[-84.537624,38.065165],[-84.565728,38.04574],[-84.551302,38.015531],[-84.514689,38.032045]]]},"properties":{"id":"40504","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.848355,38.296949],[-83.801976,38.279248],[-83.759388,38.288238],[-83.642325,38.344986],[-83.621339,38.504535],[-83.641852,38.525381],[-83.745477,38.493912],[-83.819455,38.470219],[-83.855815,38.332788],[-83.848355,38.296949]]]},"properties":{"id":"41041","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.76645,37.742493],[-82.732085,37.703926],[-82.64075,37.720182],[-82.659089,37.779983],[-82.668195,37.785272],[-82.714623,37.793542],[-82.772199,37.788402],[-82.780065,37.779899],[-82.76645,37.742493]]]},"properties":{"id":"41265","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.772896,37.72956],[-82.732085,37.703926],[-82.76645,37.742493],[-82.786175,37.74592],[-82.772896,37.72956]]]},"properties":{"id":"41602","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.786175,37.74592],[-82.848737,37.729167],[-82.772896,37.72956],[-82.786175,37.74592]]]},"properties":{"id":"41216","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.659524,37.418352],[-82.642806,37.380637],[-82.616215,37.399445],[-82.641895,37.44553],[-82.655309,37.444561],[-82.659524,37.418352]]]},"properties":{"id":"41660","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726394,37.303691],[-82.710805,37.285323],[-82.657772,37.34639],[-82.700274,37.335267],[-82.721847,37.308697],[-82.726394,37.303691]]]},"properties":{"id":"41667","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.813888,37.509497],[-82.798972,37.520181],[-82.833897,37.536101],[-82.813888,37.509497]]]},"properties":{"id":"41622","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.830527,37.466007],[-82.829919,37.449527],[-82.830419,37.467133],[-82.830527,37.466007]]]},"properties":{"id":"41643","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.70729,38.207454],[-84.730289,38.208302],[-84.787458,38.137075],[-84.64304,38.116121],[-84.633206,38.13556],[-84.70729,38.207454]]]},"properties":{"id":"40347","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.923405,38.768204],[-84.83478,38.751309],[-84.774887,38.765797],[-84.727977,38.770495],[-84.778093,38.854574],[-84.795039,38.85727],[-84.964412,38.77825],[-84.971868,38.758789],[-84.937948,38.763387],[-84.93786,38.763686],[-84.923405,38.768204]]]},"properties":{"id":"41095","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.83478,38.751309],[-84.782298,38.689284],[-84.753669,38.685093],[-84.774887,38.765797],[-84.83478,38.751309]]]},"properties":{"id":"41046","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.199489,37.522858],[-84.090364,37.566227],[-84.114751,37.611302],[-84.190173,37.654793],[-84.337755,37.638887],[-84.407999,37.538376],[-84.369394,37.46212],[-84.369661,37.461115],[-84.199489,37.522858]],[[-84.288823,37.571598],[-84.292612,37.570351],[-84.29348,37.576117],[-84.288831,37.575266],[-84.288823,37.571598]]]},"properties":{"id":"40403","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.727977,38.770495],[-84.774887,38.765797],[-84.753669,38.685093],[-84.776476,38.637675],[-84.738447,38.595701],[-84.517245,38.722722],[-84.510939,38.752275],[-84.67876,38.775425],[-84.727977,38.770495]]]},"properties":{"id":"41035","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.871416,36.612474],[-88.706038,36.548709],[-88.653405,36.576477],[-88.635498,36.585608],[-88.816715,36.664324],[-88.82315,36.669718],[-88.890341,36.634297],[-88.871416,36.612474]]]},"properties":{"id":"42088","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.775906,36.886971],[-88.7786,36.880708],[-88.764027,36.877874],[-88.775906,36.886971]]]},"properties":{"id":"42061","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.741491,37.462152],[-85.647276,37.48241],[-85.721811,37.494488],[-85.738611,37.474121],[-85.741491,37.462152]]],[[[-85.611917,37.478051],[-85.575797,37.405016],[-85.567363,37.406666],[-85.577499,37.496071],[-85.611917,37.478051]]]]},"properties":{"id":"42716","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.808332,37.731549],[-86.772898,37.64369],[-86.675769,37.735951],[-86.807779,37.74911],[-86.808332,37.731549]]]},"properties":{"id":"42368","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.716835,37.681367],[-85.584751,37.531089],[-85.548954,37.530013],[-85.471493,37.648191],[-85.475202,37.690258],[-85.550987,37.740094],[-85.680488,37.732284],[-85.716835,37.681367]]]},"properties":{"id":"40051","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.936973,36.960371],[-82.904601,36.919157],[-82.873777,36.912299],[-82.869212,36.972253],[-82.936601,36.966447],[-82.936973,36.960371]]]},"properties":{"id":"40855","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.102005,36.828032],[-83.216602,36.823833],[-83.275203,36.81567],[-83.285571,36.814922],[-83.300153,36.77765],[-83.298642,36.757133],[-83.280372,36.72693],[-83.132215,36.785218],[-83.102005,36.828032]]]},"properties":{"id":"40815","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.330999,36.784314],[-83.340933,36.745271],[-83.308818,36.754232],[-83.330999,36.784314]]]},"properties":{"id":"40830","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.396919,37.057068],[-83.418966,36.935323],[-83.4576,36.863156],[-83.490703,36.836737],[-83.367582,36.880841],[-83.234327,36.922112],[-83.196216,36.932412],[-83.146804,36.955404],[-83.12209,37.000997],[-83.160647,37.016334],[-83.178972,37.020039],[-83.239358,36.986218],[-83.269865,36.964457],[-83.370063,37.048639],[-83.396919,37.057068]]]},"properties":{"id":"40810","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.950354,37.407087],[-85.829304,37.355654],[-85.80105,37.419153],[-85.950354,37.407087]]]},"properties":{"id":"42713","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.364495,37.80361],[-87.304382,37.892184],[-87.505215,37.907264],[-87.450672,37.887427],[-87.389796,37.822578],[-87.364495,37.80361]]]},"properties":{"id":"42451","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.231957,38.335774],[-85.157919,38.321558],[-85.120417,38.431671],[-85.149885,38.435291],[-85.210302,38.414696],[-85.231957,38.335774]]]},"properties":{"id":"40019","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.290833,38.460924],[-85.23099,38.452691],[-85.18356,38.484228],[-85.26931,38.504215],[-85.273364,38.500061],[-85.290833,38.460924]]]},"properties":{"id":"40070","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.170116,38.432001],[-85.168134,38.433158],[-85.173514,38.433561],[-85.172097,38.432176],[-85.170116,38.432001]]]},"properties":{"id":"40058","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.323544,38.384627],[-85.304534,38.448909],[-85.40052,38.498068],[-85.43815,38.460184],[-85.522244,38.390163],[-85.464045,38.378439],[-85.436642,38.37174],[-85.323544,38.384627]]]},"properties":{"id":"40031","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.770336,37.328625],[-87.73413,37.298843],[-87.642666,37.269036],[-87.604647,37.396363],[-87.598608,37.459388],[-87.616193,37.471851],[-87.658532,37.428776],[-87.78401,37.341745],[-87.770336,37.328625]]]},"properties":{"id":"42441","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.90557,37.542856],[-84.090364,37.566227],[-84.199489,37.522858],[-84.188844,37.458601],[-84.164901,37.422685],[-84.137898,37.319759],[-84.056424,37.284611],[-83.945068,37.346018],[-83.847723,37.444807],[-83.824013,37.487246],[-83.90557,37.542856]],[[-83.940304,37.392142],[-83.944289,37.398293],[-83.936802,37.39293],[-83.940304,37.392142]],[[-84.103726,37.444325],[-84.067968,37.448494],[-84.079394,37.42547],[-84.103726,37.444325]]]},"properties":{"id":"40447","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.787458,38.137075],[-84.864908,38.116926],[-84.796327,37.970088],[-84.765204,37.847835],[-84.709918,37.861952],[-84.671221,37.904412],[-84.654644,37.936998],[-84.640477,37.948447],[-84.646601,38.000211],[-84.649258,38.043207],[-84.625079,38.116397],[-84.64304,38.116121],[-84.787458,38.137075]]]},"properties":{"id":"40383","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.654644,37.936998],[-84.64037,37.943271],[-84.640477,37.948447],[-84.654644,37.936998]]]},"properties":{"id":"40339","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.745688,37.831561],[-82.766993,37.829669],[-82.757449,37.806711],[-82.744474,37.813605],[-82.735048,37.832579],[-82.745688,37.831561]]]},"properties":{"id":"41260","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.822906,37.96313],[-82.806343,37.910929],[-82.784706,37.908106],[-82.778895,37.939198],[-82.822906,37.96313]]]},"properties":{"id":"41257","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.829782,37.844078],[-82.792221,37.839545],[-82.789389,37.85553],[-82.80852,37.874659],[-82.83408,37.862083],[-82.829782,37.844078]]]},"properties":{"id":"41274","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.711622,37.838539],[-82.735048,37.832579],[-82.744474,37.813605],[-82.713606,37.807853],[-82.711622,37.838539]]]},"properties":{"id":"41271","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996437,37.230553],[-82.948508,37.242841],[-82.924781,37.257629],[-82.914774,37.27341],[-82.943265,37.294239],[-82.9534,37.29251],[-82.996437,37.230553]]]},"properties":{"id":"41834","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.401288,37.316544],[-83.349178,37.283712],[-83.325518,37.281975],[-83.356813,37.336247],[-83.391617,37.330448],[-83.401288,37.316544]]],[[[-83.210764,37.309694],[-83.241891,37.268241],[-83.237499,37.26449],[-83.230008,37.267299],[-83.225072,37.264614],[-83.222371,37.264958],[-83.220012,37.26831],[-83.217018,37.266503],[-83.214035,37.267066],[-83.211343,37.262825],[-83.214855,37.263159],[-83.218921,37.266728],[-83.223301,37.264288],[-83.226421,37.264421],[-83.230558,37.266673],[-83.235943,37.264404],[-83.23988,37.264933],[-83.242252,37.268022],[-83.287001,37.217273],[-83.203008,37.175774],[-83.146322,37.210247],[-83.132702,37.215579],[-83.098578,37.236263],[-83.068272,37.246677],[-83.033228,37.230635],[-83.015743,37.281197],[-83.033292,37.309337],[-83.106685,37.34613],[-83.109625,37.344263],[-83.114392,37.35486],[-83.134782,37.347299],[-83.136113,37.393733],[-83.121782,37.39203],[-83.124348,37.404973],[-83.204936,37.424277],[-83.257942,37.408386],[-83.35728,37.358127],[-83.28815,37.316622],[-83.210764,37.309694]],[[-83.276074,37.223153],[-83.272245,37.217463],[-83.279351,37.216747],[-83.276074,37.223153]],[[-83.210764,37.309694],[-83.177311,37.321432],[-83.130211,37.33506],[-83.116612,37.308728],[-83.189713,37.302681],[-83.210764,37.309694]]]]},"properties":{"id":"41701","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.832441,37.396584],[-82.796001,37.417691],[-82.796548,37.418139],[-82.830879,37.412164],[-82.832441,37.396584]]]},"properties":{"id":"41861","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.615552,36.891261],[-83.679372,36.924069],[-83.707957,36.893485],[-83.708931,36.827061],[-83.687953,36.836315],[-83.615552,36.891261]]]},"properties":{"id":"40997","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.239242,37.240523],[-84.050439,37.268075],[-84.056424,37.284611],[-84.137898,37.319759],[-84.239242,37.240523]]]},"properties":{"id":"40729","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.326526,37.130834],[-83.355867,37.109475],[-83.342873,37.118885],[-83.326526,37.130834]]],[[[-83.326526,37.130834],[-83.323874,37.12403],[-83.314006,37.064234],[-83.314,37.064164],[-83.296115,37.117822],[-83.226805,37.152936],[-83.203008,37.175774],[-83.287001,37.217273],[-83.32334,37.224894],[-83.326526,37.130834]]]]},"properties":{"id":"41776","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.314,37.064164],[-83.295373,37.04608],[-83.239759,36.986318],[-83.239358,36.986218],[-83.182104,37.021115],[-83.167206,37.094851],[-83.196436,37.112355],[-83.296115,37.117822],[-83.314,37.064164]]]},"properties":{"id":"41777","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.399815,37.052878],[-83.410998,37.068609],[-83.539184,37.070107],[-83.528031,37.025706],[-83.5275,37.025663],[-83.48239,37.021129],[-83.412443,37.041781],[-83.399815,37.052878]]]},"properties":{"id":"40827","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.507886,37.235877],[-83.511106,37.211022],[-83.497214,37.218457],[-83.459349,37.292537],[-83.507886,37.235877]]]},"properties":{"id":"41762","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.370063,37.048639],[-83.269865,36.964457],[-83.239759,36.986318],[-83.295373,37.04608],[-83.370063,37.048639]]]},"properties":{"id":"40816","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.814394,37.187676],[-82.792542,37.120992],[-82.784977,37.125082],[-82.780338,37.150308],[-82.814394,37.187676]]]},"properties":{"id":"41815","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.953347,37.190867],[-82.93418,37.138031],[-82.896073,37.157876],[-82.906701,37.183323],[-82.917516,37.203468],[-82.953347,37.190867]]]},"properties":{"id":"41826","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.693624,37.197025],[-82.657835,37.19755],[-82.653972,37.235634],[-82.682245,37.228129],[-82.694501,37.199099],[-82.693624,37.197025]]]},"properties":{"id":"41835","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.850128,37.034932],[-82.858343,37.052288],[-82.881943,37.041081],[-82.850128,37.034932]]],[[[-82.850128,37.034932],[-82.833325,36.992062],[-82.726688,37.114794],[-82.809946,37.074981],[-82.807943,37.069953],[-82.837411,37.041997],[-82.850128,37.034932]]]]},"properties":{"id":"40826","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.355613,37.291771],[-88.384543,37.266745],[-88.373242,37.169635],[-88.304731,37.185154],[-88.355613,37.291771]]]},"properties":{"id":"42028","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.12921,36.757822],[-87.060849,36.642963],[-86.980882,36.64437],[-87.06464,36.767059],[-87.12921,36.757822]]]},"properties":{"id":"42204","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.674462,36.999766],[-86.644851,36.879383],[-86.610328,36.89118],[-86.667359,37.018268],[-86.674462,36.999766]]]},"properties":{"id":"42274","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.486969,36.674208],[-84.605192,36.598777],[-84.227292,36.592045],[-84.349793,36.657573],[-84.458852,36.670756],[-84.486969,36.674208]]]},"properties":{"id":"42649","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.099803,37.416042],[-87.135629,37.485153],[-87.136872,37.484922],[-87.181826,37.495633],[-87.30548,37.460103],[-87.181442,37.405065],[-87.099803,37.416042]]]},"properties":{"id":"42350","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.39592,37.614289],[-87.396625,37.611972],[-87.391928,37.613058],[-87.39592,37.614289]]]},"properties":{"id":"42322","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.190173,37.654793],[-84.114751,37.611302],[-84.099307,37.660853],[-84.190173,37.654793]]],[[[-84.129699,37.867706],[-84.091794,37.698566],[-84.080337,37.853066],[-84.129699,37.867706]]]]},"properties":{"id":"40385","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.288823,37.571598],[-84.288831,37.575266],[-84.29348,37.576117],[-84.292612,37.570351],[-84.288823,37.571598]]]},"properties":{"id":"40404","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.943982,37.688668],[-82.990547,37.643608],[-82.910028,37.647591],[-82.943982,37.688668]]]},"properties":{"id":"41464","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.252846,38.047533],[-86.433423,38.066642],[-86.372544,38.034043],[-86.363422,38.030781],[-86.310499,37.989614],[-86.252846,38.047533]]]},"properties":{"id":"40104","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.985167,37.925556],[-85.991722,37.941607],[-85.996928,37.94158],[-85.987762,37.922148],[-85.985167,37.925556]]]},"properties":{"id":"40155","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.597801,37.824524],[-83.609024,37.836586],[-83.612368,37.834032],[-83.597801,37.824524]]],[[[-83.689309,37.742996],[-83.694128,37.796024],[-83.63106,37.825372],[-83.768783,37.918371],[-83.871275,37.906209],[-83.831832,37.764956],[-83.7221,37.71642],[-83.689309,37.742996]]]]},"properties":{"id":"40380","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.050154,37.187119],[-86.98544,37.196009],[-86.913714,37.177601],[-86.894376,37.179288],[-86.899268,37.212313],[-86.988288,37.302688],[-87.080825,37.25026],[-87.087023,37.243974],[-87.096934,37.251439],[-87.107355,37.209615],[-87.081051,37.173687],[-87.050154,37.187119]]]},"properties":{"id":"42337","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.096934,37.251439],[-87.087023,37.243974],[-87.080825,37.25026],[-87.096934,37.251439]]]},"properties":{"id":"42332","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.207901,37.375967],[-87.317711,37.353901],[-87.36916,37.317354],[-87.286361,37.280832],[-87.207901,37.375967]]]},"properties":{"id":"42325","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.737777,37.81179],[-85.680488,37.732284],[-85.550987,37.740094],[-85.568291,37.863312],[-85.599678,37.869442],[-85.632132,37.844397],[-85.737777,37.81179]]]},"properties":{"id":"40107","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.740565,37.446788],[-86.741812,37.453983],[-86.743052,37.450404],[-86.740565,37.446788]]]},"properties":{"id":"42370","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.922617,37.384699],[-86.931056,37.386128],[-86.941116,37.372306],[-86.926274,37.36985],[-86.922617,37.384699]]]},"properties":{"id":"42354","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.488717,38.47793],[-85.43815,38.460184],[-85.40052,38.498068],[-85.405886,38.518943],[-85.433189,38.524375],[-85.488717,38.47793]]]},"properties":{"id":"40077","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.609712,38.434817],[-85.538885,38.382959],[-85.522244,38.390163],[-85.43815,38.460184],[-85.488717,38.47793],[-85.609712,38.434817]]]},"properties":{"id":"40026","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.292156,37.651131],[-82.303061,37.60857],[-82.299231,37.603603],[-82.259074,37.597757],[-82.191872,37.601365],[-82.200604,37.627911],[-82.271733,37.663956],[-82.292156,37.651131]]],[[[-82.292156,37.651131],[-82.302182,37.675667],[-82.333136,37.740848],[-82.452535,37.669601],[-82.453056,37.665444],[-82.387014,37.651903],[-82.337922,37.621708],[-82.31803,37.626898],[-82.292156,37.651131]]]]},"properties":{"id":"41514","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.103717,37.552838],[-82.120354,37.521144],[-82.119457,37.520142],[-82.075507,37.555896],[-82.103717,37.552838]]]},"properties":{"id":"41547","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.299231,37.603603],[-82.303061,37.60857],[-82.31803,37.626898],[-82.337922,37.621708],[-82.345734,37.551634],[-82.308231,37.551852],[-82.294807,37.580939],[-82.299231,37.603603]]]},"properties":{"id":"41519","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.586094,37.318069],[-82.588295,37.315907],[-82.585615,37.314546],[-82.5836,37.31647],[-82.586094,37.318069]]]},"properties":{"id":"41538","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.232026,36.925069],[-85.215405,36.854467],[-85.053285,36.865504],[-84.972809,36.910375],[-85.003151,36.954659],[-85.20754,36.990855],[-85.218151,36.960753],[-85.232026,36.925069]]]},"properties":{"id":"42629","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.304823,38.145595],[-85.329696,38.181394],[-85.392146,38.137571],[-85.373964,38.131948],[-85.304823,38.145595]]]},"properties":{"id":"40022","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.526851,36.845646],[-86.610328,36.89118],[-86.644851,36.879383],[-86.635885,36.826861],[-86.461055,36.802251],[-86.526851,36.845646]]]},"properties":{"id":"42170","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.886164,37.850573],[-88.043365,37.765708],[-87.895365,37.73207],[-87.833758,37.785499],[-87.886164,37.850573]]]},"properties":{"id":"42461","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.269844,37.081655],[-86.285739,37.013695],[-86.226309,36.912633],[-86.204176,36.933784],[-86.269844,37.081655]]]},"properties":{"id":"42159","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.866546,37.488731],[-87.890747,37.460418],[-87.866653,37.463849],[-87.866546,37.488731]]]},"properties":{"id":"42463","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.130371,37.191863],[-85.05433,37.21085],[-85.147169,37.304271],[-85.192175,37.272895],[-85.130371,37.191863]]],[[[-85.130371,37.191863],[-85.219099,37.265043],[-85.283052,37.290772],[-85.352771,37.192432],[-85.526881,37.10945],[-85.45631,36.966574],[-85.329754,36.930683],[-85.232026,36.925069],[-85.218151,36.960753],[-85.265637,36.963931],[-85.283135,36.999168],[-85.211256,37.032798],[-85.205046,36.997624],[-85.090996,37.188631],[-85.130371,37.191863]]]]},"properties":{"id":"42728","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.221799,38.085159],[-85.271798,38.016411],[-85.167834,37.971809],[-85.101646,38.03727],[-85.221799,38.085159]]]},"properties":{"id":"40046","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.122293,37.07429],[-86.029354,37.05031],[-85.996961,37.073859],[-86.061995,37.13209],[-86.066802,37.128478],[-86.170111,37.132771],[-86.147268,37.089345],[-86.122293,37.07429]]]},"properties":{"id":"42160","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.7574,36.937503],[-85.802577,36.928956],[-85.783947,36.910572],[-85.7574,36.937503]]]},"properties":{"id":"42130","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.719823,37.992663],[-83.6347,38.026865],[-83.709507,38.009317],[-83.71729,38.003288],[-83.719823,37.992663]]],[[[-83.689671,38.109021],[-83.63442,38.026841],[-83.493803,38.034236],[-83.493625,38.04872],[-83.520937,38.104768],[-83.532916,38.120393],[-83.635195,38.187525],[-83.646546,38.170963],[-83.689671,38.109021]]]]},"properties":{"id":"40371","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.795039,38.85727],[-84.778093,38.854574],[-84.743294,38.853885],[-84.638743,38.934872],[-84.706824,38.990741],[-84.828639,38.897022],[-84.795039,38.85727]]]},"properties":{"id":"41091","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.627369,38.847798],[-84.67876,38.775425],[-84.510939,38.752275],[-84.532288,38.791937],[-84.568615,38.834862],[-84.627369,38.847798]]]},"properties":{"id":"41030","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.878439,38.361299],[-83.991254,38.43481],[-84.1021,38.459379],[-84.164099,38.35179],[-84.1462,38.311644],[-84.106512,38.271653],[-84.012219,38.1613],[-83.977924,38.192118],[-83.886878,38.266598],[-83.878439,38.361299]]]},"properties":{"id":"40311","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.453658,38.334028],[-84.614367,38.353993],[-84.70729,38.207454],[-84.633206,38.13556],[-84.401847,38.207889],[-84.442661,38.283236],[-84.453658,38.334028]]]},"properties":{"id":"40324","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.164099,38.35179],[-84.1021,38.459379],[-84.181159,38.557501],[-84.205346,38.582039],[-84.239725,38.578164],[-84.45148,38.448722],[-84.488202,38.442038],[-84.453658,38.334028],[-84.442661,38.283236],[-84.164099,38.35179]]]},"properties":{"id":"41031","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.451098,38.07792],[-84.419845,38.041068],[-84.290492,38.030384],[-84.286461,38.067028],[-84.365638,38.13541],[-84.451098,38.07792]]]},"properties":{"id":"40516","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.788645,37.574406],[-84.859129,37.544268],[-84.847326,37.547894],[-84.788645,37.574406]]]},"properties":{"id":"40440","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.004207,38.66388],[-83.960746,38.657591],[-83.903901,38.768182],[-84.03582,38.772228],[-84.070949,38.765919],[-84.004207,38.66388]]]},"properties":{"id":"41002","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28464,38.75504],[-84.419583,38.717606],[-84.446972,38.701061],[-84.48715,38.587329],[-84.239725,38.578164],[-84.205346,38.582039],[-84.222562,38.684488],[-84.28464,38.75504]]]},"properties":{"id":"41040","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.387911,37.86863],[-86.352374,37.848236],[-86.305736,37.918286],[-86.297488,37.954667],[-86.309727,37.980038],[-86.38775,37.971716],[-86.393733,37.975842],[-86.389138,37.96532],[-86.387911,37.86863]]]},"properties":{"id":"40176","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.187647,37.917709],[-86.297488,37.954667],[-86.305736,37.918286],[-86.204348,37.842018],[-86.119779,37.862715],[-86.187647,37.917709]]]},"properties":{"id":"40142","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.528053,38.084379],[-85.582395,38.076119],[-85.596213,38.077274],[-85.558714,37.994693],[-85.476616,38.018773],[-85.528053,38.084379]]]},"properties":{"id":"40047","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.647812,37.926269],[-85.653393,37.92982],[-85.662681,37.946286],[-85.666006,37.93188],[-85.647812,37.926269]]]},"properties":{"id":"40110","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.934833,37.30383],[-88.17795,37.162739],[-88.178462,37.161246],[-88.163502,37.143074],[-88.01724,37.134942],[-87.934833,37.30383]]]},"properties":{"id":"42411","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.392532,39.032131],[-84.379698,39.002929],[-84.350441,38.966583],[-84.302965,39.002693],[-84.421402,39.051053],[-84.38083,39.037121],[-84.392532,39.032131]]]},"properties":{"id":"41059","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.08916,36.94043],[-88.897598,36.837567],[-88.834973,36.861407],[-88.903673,36.938354],[-89.08916,36.94043]]]},"properties":{"id":"42023","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.934295,38.632909],[-84.937948,38.763387],[-84.971868,38.758789],[-85.049848,38.678016],[-85.078208,38.65461],[-84.934295,38.632909]]]},"properties":{"id":"41083","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.853603,37.399945],[-84.822899,37.347994],[-84.816901,37.334806],[-84.727566,37.362362],[-84.853603,37.399945]]]},"properties":{"id":"42541","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.465399,36.677756],[-87.436324,36.640846],[-87.33598,36.641659],[-87.329946,36.676699],[-87.456835,36.709331],[-87.538163,36.736897],[-87.548487,36.656287],[-87.465399,36.677756]]]},"properties":{"id":"42262","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.4504,37.252434],[-87.449635,37.233997],[-87.419759,37.131086],[-87.281888,37.088676],[-87.328785,37.2037],[-87.37729,37.295521],[-87.4504,37.252434]]]},"properties":{"id":"42464","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.445812,38.001151],[-84.530068,37.976329],[-84.542775,37.966154],[-84.48512,37.840637],[-84.288628,37.910775],[-84.339955,37.931761],[-84.445812,38.001151]]]},"properties":{"id":"40515","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.447187,36.923943],[-83.412443,37.041781],[-83.48239,37.021129],[-83.522219,36.999324],[-83.536681,36.992119],[-83.531214,36.943122],[-83.503488,36.905775],[-83.489813,36.895413],[-83.447187,36.923943]]]},"properties":{"id":"40874","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.215405,36.854467],[-85.296002,36.625718],[-84.993427,36.617156],[-85.007981,36.76502],[-85.047395,36.781602],[-85.026277,36.806688],[-85.053285,36.865504],[-85.215405,36.854467]]]},"properties":{"id":"42602","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.70514,36.981945],[-84.972809,36.910375],[-85.053285,36.865504],[-85.026277,36.806688],[-85.007981,36.76502],[-84.993427,36.617156],[-84.778454,36.60329],[-84.582717,36.826749],[-84.578158,36.867133],[-84.620231,36.896321],[-84.679165,36.980914],[-84.70514,36.981945]]]},"properties":{"id":"42633","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.248161,37.432694],[-88.242672,37.434097],[-88.24428,37.437348],[-88.249323,37.439483],[-88.248161,37.432694]]]},"properties":{"id":"42084","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.367647,37.401883],[-88.377583,37.352421],[-88.355613,37.291771],[-88.304731,37.185154],[-88.278922,37.185243],[-88.21898,37.182387],[-88.358466,37.404752],[-88.367647,37.401883]]]},"properties":{"id":"42078","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.953377,37.615707],[-86.808332,37.731549],[-86.807779,37.74911],[-86.876666,37.811754],[-86.928983,37.807506],[-86.953219,37.796936],[-87.044611,37.708016],[-86.953377,37.615707]]]},"properties":{"id":"42366","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.500307,38.028679],[-84.50614,38.026378],[-84.501687,38.025568],[-84.500307,38.028679]]]},"properties":{"id":"40506","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.738846,37.619002],[-82.72187,37.61994],[-82.720801,37.631097],[-82.738846,37.619002]]]},"properties":{"id":"41621","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.696119,37.370781],[-82.756007,37.377515],[-82.748131,37.30622],[-82.726394,37.303691],[-82.721847,37.308697],[-82.726338,37.344891],[-82.716469,37.354017],[-82.685482,37.364539],[-82.696119,37.370781]]]},"properties":{"id":"41606","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.556315,36.897366],[-88.484183,36.866605],[-88.463763,36.946622],[-88.484875,36.957084],[-88.528015,36.942547],[-88.556315,36.897366]]]},"properties":{"id":"42082","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.04335,38.662636],[-83.093318,38.573772],[-83.061247,38.559181],[-82.875051,38.705319],[-82.997171,38.728952],[-83.04335,38.662636]]]},"properties":{"id":"41175","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.928438,37.636478],[-86.011906,37.583553],[-86.031544,37.555281],[-85.991749,37.532367],[-85.868821,37.564649],[-85.928438,37.636478]]]},"properties":{"id":"42740","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.502341,36.820189],[-83.424482,36.770878],[-83.3815,36.743597],[-83.372417,36.797898],[-83.377079,36.842227],[-83.367582,36.880841],[-83.490703,36.836737],[-83.502341,36.820189]]]},"properties":{"id":"40873","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.097411,36.931982],[-83.096554,36.931232],[-83.096513,36.931453],[-83.097411,36.931982]]],[[[-83.190317,36.913585],[-83.234327,36.922112],[-83.367582,36.880841],[-83.377079,36.842227],[-83.370663,36.844852],[-83.338423,36.852275],[-83.287553,36.862826],[-83.222407,36.882507],[-83.189673,36.895397],[-83.190317,36.913585]]]]},"properties":{"id":"40806","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.175921,36.855441],[-83.184583,36.843858],[-83.169571,36.845504],[-83.175921,36.855441]]]},"properties":{"id":"40847","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.31877,36.790824],[-83.330999,36.784314],[-83.308818,36.754232],[-83.298642,36.757133],[-83.300153,36.77765],[-83.31877,36.790824]]]},"properties":{"id":"40964","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.763868,37.319932],[-85.77583,37.283735],[-85.681554,37.226509],[-85.679125,37.278026],[-85.720638,37.317798],[-85.763868,37.319932]]]},"properties":{"id":"42722","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.627832,37.642805],[-87.495331,37.647547],[-87.408547,37.683563],[-87.389899,37.727693],[-87.624373,37.651334],[-87.627832,37.642805]]]},"properties":{"id":"42452","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.023281,38.484045],[-85.004173,38.432075],[-84.975694,38.430832],[-84.994577,38.501085],[-85.023281,38.484045]]]},"properties":{"id":"40007","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.604647,37.396363],[-87.642666,37.269036],[-87.57004,37.200733],[-87.475219,37.22954],[-87.4504,37.252434],[-87.37729,37.295521],[-87.36916,37.317354],[-87.317711,37.353901],[-87.35286,37.441821],[-87.355144,37.446308],[-87.543974,37.378624],[-87.604647,37.396363]],[[-87.509387,37.2632],[-87.530826,37.278585],[-87.526935,37.293632],[-87.48889,37.277998],[-87.509387,37.2632]]]},"properties":{"id":"42431","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.560808,37.470637],[-87.598608,37.459388],[-87.604647,37.396363],[-87.543974,37.378624],[-87.560808,37.470637]]]},"properties":{"id":"42436","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.103726,37.444325],[-84.079394,37.42547],[-84.067968,37.448494],[-84.103726,37.444325]]]},"properties":{"id":"40481","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.719902,38.225769],[-85.741144,38.23078],[-85.742059,38.229891],[-85.753545,38.212792],[-85.744961,38.206309],[-85.713067,38.217316],[-85.719902,38.225769]]]},"properties":{"id":"40217","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.89507,38.187028],[-85.827132,38.153228],[-85.797804,38.174591],[-85.786619,38.215127],[-85.782928,38.220108],[-85.800186,38.219216],[-85.865815,38.2134],[-85.89507,38.187028]]]},"properties":{"id":"40216","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.692875,38.247268],[-85.688402,38.245175],[-85.683187,38.247526],[-85.685209,38.249983],[-85.692875,38.247268]]]},"properties":{"id":"40280","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.649174,37.809655],[-84.671221,37.904412],[-84.709918,37.861952],[-84.716925,37.815389],[-84.649174,37.809655]]]},"properties":{"id":"40390","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.962476,37.896016],[-82.902153,37.853086],[-82.857517,37.870896],[-82.831585,37.967869],[-82.919385,37.992447],[-82.921027,37.992984],[-82.98406,37.950948],[-82.962476,37.896016]]]},"properties":{"id":"41219","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.015743,37.281197],[-83.033228,37.230635],[-82.997288,37.230258],[-82.996437,37.230553],[-82.9534,37.29251],[-83.015743,37.281197]]],[[[-83.021114,37.178075],[-83.000142,37.19571],[-83.002274,37.198988],[-83.068272,37.246677],[-83.098578,37.236263],[-83.072792,37.206748],[-83.07427,37.205247],[-83.081521,37.20539],[-83.07284,37.189555],[-83.021114,37.178075]]]]},"properties":{"id":"41773","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.106301,37.351409],[-83.109625,37.344263],[-83.106685,37.34613],[-83.106301,37.351409]]],[[[-83.130211,37.33506],[-83.1327,37.314457],[-83.116612,37.308728],[-83.130211,37.33506]]]]},"properties":{"id":"41739","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.796001,37.417691],[-82.756007,37.377515],[-82.764204,37.426046],[-82.767931,37.427657],[-82.796548,37.418139],[-82.796001,37.417691]]]},"properties":{"id":"41859","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.830148,36.854783],[-83.817405,36.780577],[-83.797481,36.784277],[-83.759195,36.803044],[-83.826063,36.840468],[-83.830148,36.854783]]]},"properties":{"id":"40903","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.741379,37.474794],[-85.741491,37.462152],[-85.738611,37.474121],[-85.741379,37.474794]]],[[[-85.584751,37.531089],[-85.716835,37.681367],[-85.838664,37.568708],[-85.768208,37.449361],[-85.741379,37.474794],[-85.721811,37.494488],[-85.647276,37.48241],[-85.629791,37.471236],[-85.611917,37.478051],[-85.577499,37.496071],[-85.584751,37.531089]]]]},"properties":{"id":"42748","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.070787,37.015528],[-84.067685,37.028018],[-84.097979,37.038115],[-84.105832,37.001654],[-84.070787,37.015528]]],[[[-84.070787,37.015528],[-84.002899,36.989709],[-83.982651,36.989839],[-84.062023,37.024396],[-84.070787,37.015528]]]]},"properties":{"id":"40740","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.022115,38.007047],[-82.989014,37.963968],[-82.921027,37.992984],[-82.919385,37.992447],[-82.95279,38.052827],[-82.97077,38.050954],[-83.022115,38.007047]]]},"properties":{"id":"41159","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.241891,37.268241],[-83.305735,37.280522],[-83.325518,37.281975],[-83.349178,37.283712],[-83.32334,37.224894],[-83.287001,37.217273],[-83.242252,37.268022],[-83.241891,37.268241]]]},"properties":{"id":"41723","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.492423,37.163226],[-83.435124,37.177113],[-83.447968,37.199242],[-83.49755,37.173545],[-83.492423,37.163226]]]},"properties":{"id":"41766","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.784977,37.125082],[-82.764149,37.151388],[-82.780338,37.150308],[-82.784977,37.125082]]],[[[-82.739852,37.156802],[-82.731426,37.153954],[-82.711987,37.173991],[-82.72884,37.171805],[-82.74363,37.160387],[-82.739852,37.156802]]],[[[-82.896073,37.157876],[-82.925612,37.116023],[-82.941632,37.074302],[-82.997539,37.005382],[-82.98454,36.997903],[-82.938967,37.015224],[-82.881943,37.041081],[-82.858343,37.052288],[-82.843555,37.062291],[-82.807943,37.069953],[-82.809946,37.074981],[-82.792542,37.120992],[-82.814394,37.187676],[-82.777903,37.212441],[-82.751532,37.221943],[-82.751546,37.266673],[-82.799467,37.263189],[-82.874886,37.238294],[-82.874947,37.237571],[-82.906701,37.183323],[-82.896073,37.157876]]]]},"properties":{"id":"41858","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.906701,37.183323],[-82.874947,37.237571],[-82.907562,37.210615],[-82.917516,37.203468],[-82.906701,37.183323]]]},"properties":{"id":"41824","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.850128,37.034932],[-82.837411,37.041997],[-82.843555,37.062291],[-82.858343,37.052288],[-82.850128,37.034932]]],[[[-82.850128,37.034932],[-82.881943,37.041081],[-82.938967,37.015224],[-82.869192,36.974186],[-82.833325,36.992062],[-82.850128,37.034932]]]]},"properties":{"id":"40862","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.048892,37.169421],[-83.005109,37.12167],[-82.968273,37.113487],[-82.970885,37.122809],[-82.977429,37.173434],[-83.000142,37.19571],[-83.021114,37.178075],[-83.048892,37.169421]]]},"properties":{"id":"41804","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.74897,37.024402],[-87.056485,36.866093],[-87.056281,36.795877],[-86.911036,36.736313],[-86.751603,36.724476],[-86.728359,36.775351],[-86.690759,37.005153],[-86.74897,37.024402]]]},"properties":{"id":"42276","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.973984,36.644511],[-86.729415,36.649001],[-86.751603,36.724476],[-86.911036,36.736313],[-86.973984,36.644511]]]},"properties":{"id":"42202","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.824813,36.97491],[-88.815952,36.9541],[-88.815739,36.953595],[-88.688602,36.942728],[-88.678573,36.9589],[-88.581234,37.09338],[-88.720618,37.140991],[-88.814348,37.010302],[-88.824813,36.97491]]]},"properties":{"id":"42001","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.46173,36.681742],[-84.486969,36.674208],[-84.458852,36.670756],[-84.46173,36.681742]]]},"properties":{"id":"42638","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.317711,37.353901],[-87.207901,37.375967],[-87.181442,37.405065],[-87.30548,37.460103],[-87.35286,37.441821],[-87.317711,37.353901]]]},"properties":{"id":"42372","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.904157,37.602733],[-83.017109,37.55159],[-82.948544,37.503167],[-82.886469,37.533223],[-82.904157,37.602733]]]},"properties":{"id":"41632","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.348007,37.656118],[-85.375316,37.574171],[-85.380821,37.522483],[-85.18745,37.451219],[-85.109763,37.53583],[-85.097382,37.631101],[-85.348007,37.656118]],[[-85.338478,37.57556],[-85.339981,37.566776],[-85.350885,37.569372],[-85.344843,37.575749],[-85.338478,37.57556]]]},"properties":{"id":"40033","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.053597,37.705401],[-85.10084,37.781201],[-85.278616,37.84412],[-85.313237,37.809879],[-85.401784,37.730658],[-85.348007,37.656118],[-85.097382,37.631101],[-85.039298,37.631247],[-85.028169,37.682929],[-85.053597,37.705401]]]},"properties":{"id":"40069","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.332436,37.058176],[-88.434682,37.04742],[-88.482957,37.022566],[-88.484875,36.957084],[-88.463763,36.946622],[-88.324535,37.003015],[-88.295836,37.035082],[-88.332436,37.058176]]]},"properties":{"id":"42029","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.442668,37.795391],[-82.603879,37.701662],[-82.563561,37.684844],[-82.452535,37.669601],[-82.333136,37.740848],[-82.333634,37.764685],[-82.442668,37.795391]]]},"properties":{"id":"41250","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.659089,37.779983],[-82.546345,37.851769],[-82.65023,37.823974],[-82.668195,37.785272],[-82.659089,37.779983]]]},"properties":{"id":"41214","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.409831,37.837736],[-82.452786,37.813811],[-82.442668,37.795391],[-82.333634,37.764685],[-82.409831,37.837736]]]},"properties":{"id":"41231","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.923743,38.593482],[-83.745477,38.493912],[-83.641852,38.525381],[-83.624679,38.611553],[-83.644913,38.636343],[-83.842284,38.72607],[-83.923183,38.654182],[-83.923743,38.593482]]]},"properties":{"id":"41056","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.212389,38.027126],[-83.21234,37.960399],[-83.182438,37.961306],[-83.158793,38.008182],[-83.212389,38.027126]]]},"properties":{"id":"41421","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.473324,37.848171],[-85.473741,37.844946],[-85.459709,37.845801],[-85.468172,37.848149],[-85.473324,37.848171]]]},"properties":{"id":"40048","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.010603,38.51291],[-85.008391,38.514674],[-85.010975,38.514963],[-85.010603,38.51291]]]},"properties":{"id":"40363","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.226805,37.152936],[-83.196436,37.112355],[-83.167206,37.094851],[-83.134066,37.104146],[-83.048892,37.169421],[-83.021114,37.178075],[-83.07284,37.189555],[-83.134533,37.20356],[-83.146322,37.210247],[-83.203008,37.175774],[-83.226805,37.152936]]]},"properties":{"id":"41774","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.200604,37.627911],[-82.191872,37.601365],[-82.206918,37.580971],[-82.170547,37.556794],[-82.168004,37.557888],[-82.126525,37.582496],[-82.200604,37.627911]]]},"properties":{"id":"41544","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.103717,37.552838],[-82.126525,37.582496],[-82.168004,37.557888],[-82.120354,37.521144],[-82.103717,37.552838]]]},"properties":{"id":"41528","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.226788,37.502899],[-82.223439,37.470039],[-82.191949,37.452228],[-82.149776,37.410228],[-82.095152,37.448385],[-82.119457,37.520142],[-82.120354,37.521144],[-82.168004,37.557888],[-82.170547,37.556794],[-82.226788,37.502899]]]},"properties":{"id":"41553","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.616215,37.399445],[-82.572103,37.371608],[-82.604612,37.410284],[-82.616215,37.399445]]]},"properties":{"id":"41560","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.455184,37.286364],[-82.501071,37.271624],[-82.482494,37.265987],[-82.455184,37.286364]]]},"properties":{"id":"41534","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.366035,37.456974],[-82.389985,37.456606],[-82.417728,37.432747],[-82.391694,37.420887],[-82.368268,37.426458],[-82.366035,37.456974]]],[[[-82.413995,37.531534],[-82.436877,37.470686],[-82.406432,37.467453],[-82.413995,37.531534]]]]},"properties":{"id":"41557","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.223439,37.470039],[-82.366035,37.456974],[-82.368268,37.426458],[-82.268705,37.429549],[-82.208303,37.438274],[-82.191949,37.452228],[-82.223439,37.470039]]]},"properties":{"id":"41554","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.610949,36.987548],[-84.620231,36.896321],[-84.578158,36.867133],[-84.575028,36.868689],[-84.416816,36.94692],[-84.606078,36.995285],[-84.610949,36.987548]]]},"properties":{"id":"42519","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.24933,36.641721],[-87.060849,36.642963],[-87.12921,36.757822],[-87.213046,36.786301],[-87.24933,36.641721]]]},"properties":{"id":"42234","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.439653,36.982781],[-86.392039,36.91444],[-86.272356,36.867039],[-86.226309,36.912633],[-86.285739,37.013695],[-86.417608,37.00538],[-86.416596,37.003776],[-86.439653,36.982781]]]},"properties":{"id":"42103","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.152797,37.897661],[-85.196072,37.876436],[-85.278616,37.84412],[-85.10084,37.781201],[-85.053279,37.812366],[-85.059587,37.890433],[-85.152797,37.897661]]]},"properties":{"id":"40078","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.219099,37.265043],[-85.130371,37.191863],[-85.192175,37.272895],[-85.219099,37.265043]]]},"properties":{"id":"42753","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.205046,36.997624],[-85.211256,37.032798],[-85.283135,36.999168],[-85.265637,36.963931],[-85.218151,36.960753],[-85.20754,36.990855],[-85.205046,36.997624]]]},"properties":{"id":"42741","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.221799,38.085159],[-85.101646,38.03727],[-84.971663,38.120502],[-85.065885,38.191524],[-85.22326,38.085747],[-85.221799,38.085159]]]},"properties":{"id":"40076","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.979431,37.028047],[-88.887377,36.983596],[-88.94466,37.137683],[-88.945128,37.151879],[-88.937394,37.152585],[-89.057614,37.18903],[-89.094279,37.143984],[-88.979431,37.028047]]]},"properties":{"id":"42056","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.070215,36.877294],[-86.056117,36.828892],[-86.033621,36.849857],[-86.070215,36.877294]]]},"properties":{"id":"42156","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.580011,36.786226],[-83.562851,36.764287],[-83.541757,36.792009],[-83.56133,36.795918],[-83.580011,36.786226]]],[[[-83.956234,36.645491],[-83.930762,36.587691],[-83.898869,36.586705],[-83.845016,36.610757],[-83.611458,36.678043],[-83.580011,36.786226],[-83.661866,36.796604],[-83.705974,36.796849],[-83.75565,36.800576],[-83.759195,36.803044],[-83.797481,36.784277],[-83.830129,36.739856],[-83.865636,36.714708],[-83.956234,36.645491]]]]},"properties":{"id":"40977","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.705974,36.796849],[-83.661866,36.796604],[-83.572313,36.835584],[-83.541123,36.87859],[-83.609795,36.896302],[-83.615552,36.891261],[-83.687953,36.836315],[-83.705974,36.796849]]]},"properties":{"id":"40902","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.572313,36.835584],[-83.56133,36.795918],[-83.490703,36.836737],[-83.4576,36.863156],[-83.489813,36.895413],[-83.503488,36.905775],[-83.541123,36.87859],[-83.572313,36.835584]]]},"properties":{"id":"40988","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.807542,39.109914],[-84.837734,38.988796],[-84.778116,39.088094],[-84.777848,39.097725],[-84.807542,39.109914]]]},"properties":{"id":"41080","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.744888,37.713075],[-84.922579,37.689628],[-84.940721,37.625835],[-84.859129,37.544268],[-84.788645,37.574406],[-84.680188,37.623502],[-84.744888,37.713075]]]},"properties":{"id":"40422","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.248692,37.669616],[-83.415104,37.626319],[-83.247232,37.667439],[-83.248692,37.669616]]]},"properties":{"id":"41385","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.427295,37.68845],[-86.438959,37.655251],[-86.411853,37.617709],[-86.352268,37.626114],[-86.357816,37.634642],[-86.427295,37.68845]]]},"properties":{"id":"40178","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.086971,37.910687],[-86.119779,37.862715],[-86.204348,37.842018],[-86.253882,37.781917],[-86.180449,37.750338],[-86.161192,37.742691],[-85.968042,37.775396],[-85.971687,37.860823],[-86.05372,37.917456],[-86.086971,37.910687]]]},"properties":{"id":"40175","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.465595,39.04997],[-84.494814,39.05158],[-84.428487,38.975104],[-84.379698,39.002929],[-84.392532,39.032131],[-84.401924,39.03915],[-84.38083,39.037121],[-84.421402,39.051053],[-84.433553,39.072842],[-84.465595,39.04997]],[[-84.46788,39.043778],[-84.464508,39.034848],[-84.471001,39.03066],[-84.470317,39.039569],[-84.46788,39.043778]]]},"properties":{"id":"41076","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.38083,39.037121],[-84.401924,39.03915],[-84.392532,39.032131],[-84.38083,39.037121]]]},"properties":{"id":"41085","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.776699,36.826808],[-88.7786,36.880708],[-88.834973,36.861407],[-88.897598,36.837567],[-88.919971,36.772406],[-88.82315,36.669718],[-88.816715,36.664324],[-88.776699,36.826808]]]},"properties":{"id":"42039","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.357784,38.065923],[-83.520937,38.104768],[-83.493625,38.04872],[-83.433942,38.033974],[-83.357784,38.065923]]],[[[-83.524641,38.254787],[-83.635195,38.187525],[-83.532916,38.120393],[-83.324715,38.08538],[-83.259212,38.120148],[-83.162941,38.156276],[-83.341347,38.319521],[-83.452848,38.381799],[-83.524641,38.254787]]]]},"properties":{"id":"40351","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.294334,36.878839],[-87.456835,36.709331],[-87.329946,36.676699],[-87.272537,36.822534],[-87.294334,36.878839]]]},"properties":{"id":"42266","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.050439,37.268075],[-84.239242,37.240523],[-84.27117,37.214129],[-84.289076,37.15174],[-84.29369,37.145742],[-84.314139,37.004624],[-84.133619,37.081835],[-84.131538,37.084654],[-83.863257,37.090259],[-83.943718,37.250551],[-84.050439,37.268075]]]},"properties":{"id":"40741","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.761209,37.302107],[-87.73413,37.298843],[-87.770336,37.328625],[-87.761209,37.302107]]],[[[-87.904573,37.398949],[-87.813413,37.350645],[-87.78401,37.341745],[-87.658532,37.428776],[-87.7054,37.471418],[-87.904573,37.398949]]]]},"properties":{"id":"42450","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.043691,37.834669],[-86.953219,37.796936],[-86.928983,37.807506],[-86.977026,37.923718],[-87.043691,37.834669]]]},"properties":{"id":"42355","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.772898,37.64369],[-86.808332,37.731549],[-86.953377,37.615707],[-86.950165,37.553477],[-86.800344,37.599848],[-86.772898,37.64369]]]},"properties":{"id":"42378","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.458301,38.012626],[-84.513662,37.993759],[-84.530068,37.976329],[-84.445812,38.001151],[-84.458301,38.012626]]]},"properties":{"id":"40517","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.759388,38.288238],[-83.646546,38.170963],[-83.635195,38.187525],[-83.524641,38.254787],[-83.642325,38.344986],[-83.759388,38.288238]]]},"properties":{"id":"41049","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.837559,37.552302],[-82.763091,37.542451],[-82.714249,37.533003],[-82.707801,37.543556],[-82.715766,37.590826],[-82.750352,37.592784],[-82.847828,37.61273],[-82.837559,37.552302]]]},"properties":{"id":"41649","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.777096,37.488311],[-82.712918,37.469568],[-82.686095,37.45489],[-82.681478,37.481768],[-82.711812,37.524382],[-82.714249,37.533003],[-82.763091,37.542451],[-82.777096,37.488311]]]},"properties":{"id":"41655","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.687834,37.449075],[-82.672472,37.408832],[-82.659524,37.418352],[-82.655309,37.444561],[-82.687834,37.449075]]],[[[-82.65274,37.523435],[-82.641895,37.44553],[-82.616215,37.399445],[-82.604612,37.410284],[-82.617006,37.54674],[-82.642001,37.555243],[-82.646935,37.551506],[-82.660191,37.532172],[-82.65274,37.523435]]]]},"properties":{"id":"41635","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.771616,37.478639],[-82.712918,37.469568],[-82.777096,37.488311],[-82.771616,37.478639]]]},"properties":{"id":"41619","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.642806,37.380637],[-82.659524,37.418352],[-82.672472,37.408832],[-82.685174,37.386284],[-82.696119,37.370781],[-82.685482,37.364539],[-82.664592,37.367861],[-82.642806,37.380637]]]},"properties":{"id":"41604","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.855464,38.1824],[-84.862593,38.180767],[-84.874565,38.171608],[-84.865856,38.170197],[-84.855464,38.1824]]]},"properties":{"id":"40604","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.768355,38.344282],[-84.730289,38.208302],[-84.70729,38.207454],[-84.614367,38.353993],[-84.660302,38.407692],[-84.668647,38.404879],[-84.768355,38.344282]]]},"properties":{"id":"40379","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.496855,37.575836],[-84.407999,37.538376],[-84.337755,37.638887],[-84.445999,37.651279],[-84.496855,37.575836]]]},"properties":{"id":"40461","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.58161,36.858944],[-88.513377,36.821326],[-88.484183,36.866605],[-88.556315,36.897366],[-88.58161,36.858944]]],[[[-88.633422,36.855633],[-88.718755,36.907145],[-88.764027,36.877874],[-88.7786,36.880708],[-88.776699,36.826808],[-88.633422,36.855633]]]]},"properties":{"id":"42051","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.741379,37.474794],[-85.738611,37.474121],[-85.721811,37.494488],[-85.741379,37.474794]]],[[[-85.80105,37.419153],[-85.829304,37.355654],[-85.763868,37.319932],[-85.720638,37.317798],[-85.61033,37.390983],[-85.629791,37.471236],[-85.647276,37.48241],[-85.741491,37.462152],[-85.741379,37.474794],[-85.768208,37.449361],[-85.794815,37.446396],[-85.80105,37.419153]]]]},"properties":{"id":"42757","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.629791,37.471236],[-85.61033,37.390983],[-85.575797,37.405016],[-85.611917,37.478051],[-85.629791,37.471236]]]},"properties":{"id":"42764","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.806211,37.232053],[-85.710528,37.162429],[-85.645453,37.170457],[-85.681554,37.226509],[-85.77583,37.283735],[-85.806211,37.232053]]]},"properties":{"id":"42746","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.756747,38.532418],[-82.73067,38.487006],[-82.72009,38.493985],[-82.756747,38.532418]]]},"properties":{"id":"41139","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.047598,37.449933],[-85.794815,37.446396],[-85.768208,37.449361],[-85.838664,37.568708],[-85.868821,37.564649],[-85.991749,37.532367],[-86.05399,37.495743],[-86.054861,37.474312],[-86.047598,37.449933]]]},"properties":{"id":"42776","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.222407,36.882507],[-83.231442,36.832909],[-83.216602,36.823833],[-83.102005,36.828032],[-83.072836,36.854457],[-83.073167,36.905998],[-83.034166,36.943551],[-83.096554,36.931232],[-83.097411,36.931982],[-83.189673,36.895397],[-83.222407,36.882507]],[[-83.175921,36.855441],[-83.169571,36.845504],[-83.184583,36.843858],[-83.175921,36.855441]],[[-83.141585,36.89667],[-83.126215,36.900684],[-83.117851,36.893163],[-83.14404,36.883437],[-83.141585,36.89667]]]},"properties":{"id":"40828","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.275203,36.81567],[-83.216602,36.823833],[-83.231442,36.832909],[-83.25379,36.835601],[-83.275203,36.81567]]]},"properties":{"id":"40818","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.239759,36.986318],[-83.269865,36.964457],[-83.239358,36.986218],[-83.239759,36.986318]]],[[[-83.239358,36.986218],[-83.178972,37.020039],[-83.182104,37.021115],[-83.239358,36.986218]]]]},"properties":{"id":"40808","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.749904,37.868622],[-87.624373,37.651334],[-87.389899,37.727693],[-87.364495,37.80361],[-87.389796,37.822578],[-87.456418,37.812452],[-87.450672,37.887427],[-87.505215,37.907264],[-87.749904,37.868622]]]},"properties":{"id":"42420","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.450672,37.887427],[-87.456418,37.812452],[-87.389796,37.822578],[-87.450672,37.887427]]]},"properties":{"id":"42458","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.833758,37.785499],[-87.895365,37.73207],[-87.754778,37.671332],[-87.833758,37.785499]]]},"properties":{"id":"42462","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.273364,38.500061],[-85.405886,38.518943],[-85.40052,38.498068],[-85.304534,38.448909],[-85.290833,38.460924],[-85.273364,38.500061]]]},"properties":{"id":"40055","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.543974,37.378624],[-87.355144,37.446308],[-87.354286,37.510786],[-87.560808,37.470637],[-87.543974,37.378624]]]},"properties":{"id":"42413","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.67681,38.2998],[-85.623445,38.237687],[-85.578338,38.224629],[-85.583685,38.261026],[-85.605549,38.275557],[-85.646634,38.317187],[-85.657702,38.321515],[-85.67681,38.2998]],[[-85.649132,38.298718],[-85.654782,38.301762],[-85.648523,38.303482],[-85.649132,38.298718]]]},"properties":{"id":"40222","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.739637,38.243938],[-85.738281,38.251799],[-85.744801,38.267204],[-85.761488,38.272267],[-85.771761,38.263385],[-85.739637,38.243938]]]},"properties":{"id":"40202","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.750978,38.167404],[-85.744961,38.206309],[-85.753545,38.212792],[-85.759868,38.200317],[-85.750978,38.167404]]]},"properties":{"id":"40209","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.410197,38.231139],[-85.4698,38.198646],[-85.42883,38.118383],[-85.373964,38.131948],[-85.392146,38.137571],[-85.410197,38.231139]]]},"properties":{"id":"40023","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.72141,37.910211],[-82.765153,37.9016],[-82.743363,37.882245],[-82.726684,37.857739],[-82.676346,37.892208],[-82.673417,37.913464],[-82.72141,37.910211]]]},"properties":{"id":"41254","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.65023,37.823974],[-82.668943,37.845695],[-82.711622,37.838539],[-82.713606,37.807853],[-82.714623,37.793542],[-82.668195,37.785272],[-82.65023,37.823974]]]},"properties":{"id":"41204","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.822906,37.96313],[-82.831585,37.967869],[-82.857517,37.870896],[-82.83408,37.862083],[-82.80852,37.874659],[-82.806343,37.910929],[-82.822906,37.96313]]]},"properties":{"id":"41255","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.786175,37.74592],[-82.76645,37.742493],[-82.780065,37.779899],[-82.793758,37.792214],[-82.836185,37.821866],[-82.915267,37.796522],[-82.933325,37.7856],[-82.88974,37.710809],[-82.848737,37.729167],[-82.786175,37.74592]]]},"properties":{"id":"41222","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537102,39.09772],[-84.550236,39.082707],[-84.519061,39.049469],[-84.501983,39.057119],[-84.497594,39.07682],[-84.506161,39.095177],[-84.537102,39.09772]]]},"properties":{"id":"41011","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.422765,38.824851],[-84.444698,38.912086],[-84.494943,38.918553],[-84.555156,38.870599],[-84.568615,38.834862],[-84.532288,38.791937],[-84.418107,38.806596],[-84.422765,38.824851]]]},"properties":{"id":"41063","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.497594,39.07682],[-84.501983,39.057119],[-84.497612,39.056311],[-84.497594,39.07682]]]},"properties":{"id":"41014","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983767,37.417392],[-83.093344,37.497903],[-83.131505,37.460329],[-83.056204,37.388851],[-82.991144,37.355614],[-82.981675,37.392231],[-82.983767,37.417392]]]},"properties":{"id":"41772","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.056204,37.388851],[-83.106301,37.351409],[-83.106685,37.34613],[-83.033292,37.309337],[-83.03352,37.331066],[-83.047651,37.346337],[-83.019658,37.345895],[-82.991144,37.355614],[-83.056204,37.388851]]]},"properties":{"id":"41740","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.03352,37.331066],[-83.033292,37.309337],[-83.015743,37.281197],[-82.9534,37.29251],[-82.943265,37.294239],[-82.930395,37.307961],[-82.89045,37.344249],[-82.922182,37.352836],[-82.949445,37.34809],[-82.981675,37.392231],[-82.991144,37.355614],[-83.019658,37.345895],[-83.03352,37.331066]]]},"properties":{"id":"41822","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.848873,37.370266],[-82.89045,37.344249],[-82.930395,37.307961],[-82.886896,37.299585],[-82.844215,37.295217],[-82.832404,37.330469],[-82.848873,37.370266]]]},"properties":{"id":"41844","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.917596,37.377087],[-82.922182,37.352836],[-82.89045,37.344249],[-82.848873,37.370266],[-82.832441,37.396584],[-82.830879,37.412164],[-82.829919,37.449527],[-82.830527,37.466007],[-82.947234,37.399462],[-82.917596,37.377087]]]},"properties":{"id":"41839","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.964468,36.709914],[-83.880146,36.733731],[-83.934768,36.750367],[-83.944704,36.7895],[-83.887305,36.794968],[-83.875569,36.733201],[-83.865636,36.714708],[-83.830129,36.739856],[-83.817405,36.780577],[-83.830148,36.854783],[-83.844368,36.89498],[-83.854642,36.903486],[-83.864379,36.895657],[-83.876217,36.86034],[-83.884772,36.89223],[-83.881218,36.904743],[-83.859619,36.918862],[-83.87959,36.942625],[-83.842931,36.975659],[-83.721219,37.002209],[-83.871315,37.054919],[-83.953191,36.98519],[-83.986277,36.872885],[-83.972203,36.827615],[-83.987028,36.755928],[-83.964468,36.709914]]]},"properties":{"id":"40906","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.165053,36.819934],[-83.987028,36.755928],[-83.972203,36.827615],[-83.972619,36.827521],[-84.069694,36.887141],[-84.165053,36.819934]]]},"properties":{"id":"40759","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.721219,37.002209],[-83.842931,36.975659],[-83.833159,36.949232],[-83.775119,36.951217],[-83.719925,36.995246],[-83.721219,37.002209]]]},"properties":{"id":"40946","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.773531,38.058409],[-82.741942,38.088909],[-82.77407,38.077452],[-82.773531,38.058409]]]},"properties":{"id":"41201","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.970885,37.122809],[-82.950863,37.116206],[-82.93418,37.138031],[-82.953347,37.190867],[-82.977429,37.173434],[-82.970885,37.122809]]]},"properties":{"id":"41832","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.72884,37.171805],[-82.724782,37.184831],[-82.74363,37.160387],[-82.72884,37.171805]]]},"properties":{"id":"41849","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.950863,37.116206],[-82.925612,37.116023],[-82.896073,37.157876],[-82.93418,37.138031],[-82.950863,37.116206]]]},"properties":{"id":"41845","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.06464,36.767059],[-86.980882,36.64437],[-86.973984,36.644511],[-86.911036,36.736313],[-87.056281,36.795877],[-87.06464,36.767059]]]},"properties":{"id":"42265","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.458852,36.670756],[-84.349793,36.657573],[-84.355095,36.715344],[-84.403503,36.718096],[-84.46173,36.681742],[-84.458852,36.670756]]]},"properties":{"id":"42635","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.582717,36.826749],[-84.403503,36.718096],[-84.355095,36.715344],[-84.340212,36.835816],[-84.575028,36.868689],[-84.578158,36.867133],[-84.582717,36.826749]],[[-84.480629,36.738692],[-84.485193,36.74074],[-84.484111,36.744036],[-84.480452,36.743712],[-84.480629,36.738692]]]},"properties":{"id":"42653","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.338478,37.57556],[-85.344843,37.575749],[-85.350885,37.569372],[-85.339981,37.566776],[-85.338478,37.57556]]]},"properties":{"id":"40063","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.324535,37.003015],[-88.187948,36.933035],[-88.240371,36.981911],[-88.295836,37.035082],[-88.324535,37.003015]]]},"properties":{"id":"42044","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.668943,37.845695],[-82.65023,37.823974],[-82.546345,37.851769],[-82.579956,37.943593],[-82.612393,37.879272],[-82.668943,37.845695]]]},"properties":{"id":"41262","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.548388,37.913913],[-83.609024,37.836586],[-83.597801,37.824524],[-83.574799,37.819797],[-83.49508,37.861906],[-83.417908,37.92471],[-83.433942,38.033974],[-83.493625,38.04872],[-83.493803,38.034236],[-83.526858,37.969182],[-83.515753,37.916523],[-83.548388,37.913913]]]},"properties":{"id":"40387","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.37729,37.295521],[-87.328785,37.2037],[-87.250434,37.280568],[-87.286361,37.280832],[-87.36916,37.317354],[-87.37729,37.295521]]]},"properties":{"id":"42344","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.436642,38.37174],[-85.464045,38.378439],[-85.457633,38.36375],[-85.436642,38.37174]]]},"properties":{"id":"40010","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.469149,37.374654],[-83.571013,37.276638],[-83.507886,37.235877],[-83.459349,37.292537],[-83.401288,37.316544],[-83.391617,37.330448],[-83.402746,37.332353],[-83.468573,37.374498],[-83.469149,37.374654]]]},"properties":{"id":"41721","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.276074,37.223153],[-83.279351,37.216747],[-83.272245,37.217463],[-83.276074,37.223153]]]},"properties":{"id":"41713","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.098578,37.236263],[-83.132702,37.215579],[-83.134533,37.20356],[-83.07284,37.189555],[-83.081521,37.20539],[-83.072792,37.206748],[-83.098578,37.236263]]]},"properties":{"id":"41746","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.575595,37.316795],[-82.522276,37.283421],[-82.503172,37.337433],[-82.499393,37.424317],[-82.504097,37.424927],[-82.507091,37.431144],[-82.514341,37.433281],[-82.511864,37.436327],[-82.506459,37.432465],[-82.504563,37.42707],[-82.499846,37.426015],[-82.429865,37.371736],[-82.413826,37.366473],[-82.407132,37.363677],[-82.369809,37.39592],[-82.388609,37.418153],[-82.391694,37.420887],[-82.417728,37.432747],[-82.436877,37.470686],[-82.413995,37.531534],[-82.368582,37.554045],[-82.393659,37.596838],[-82.453056,37.665444],[-82.452535,37.669601],[-82.563561,37.684844],[-82.60481,37.609612],[-82.608611,37.590018],[-82.61364,37.564118],[-82.617006,37.54674],[-82.604612,37.410284],[-82.572103,37.371608],[-82.575595,37.316795]]]},"properties":{"id":"41501","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.284028,37.515021],[-82.239882,37.506488],[-82.230951,37.563354],[-82.256251,37.570946],[-82.284028,37.515021]]]},"properties":{"id":"41555","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.501071,37.271624],[-82.531993,37.260753],[-82.502466,37.225204],[-82.410327,37.253104],[-82.455184,37.286364],[-82.482494,37.265987],[-82.501071,37.271624]]]},"properties":{"id":"41512","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.407132,37.363677],[-82.413826,37.366473],[-82.414015,37.365257],[-82.503172,37.337433],[-82.522276,37.283421],[-82.537776,37.261131],[-82.531993,37.260753],[-82.501071,37.271624],[-82.455184,37.286364],[-82.410327,37.253104],[-82.254513,37.337741],[-82.302327,37.349752],[-82.36093,37.372638],[-82.407132,37.363677]]]},"properties":{"id":"41522","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.191872,37.601365],[-82.259074,37.597757],[-82.259948,37.597395],[-82.259355,37.578334],[-82.256251,37.570946],[-82.230951,37.563354],[-82.206918,37.580971],[-82.191872,37.601365]]]},"properties":{"id":"41531","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.292156,37.651131],[-82.271733,37.663956],[-82.302182,37.675667],[-82.292156,37.651131]]]},"properties":{"id":"41503","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.436729,37.271858],[-84.289076,37.15174],[-84.27117,37.214129],[-84.233135,37.33549],[-84.188844,37.458601],[-84.199489,37.522858],[-84.369661,37.461115],[-84.436729,37.271858]]]},"properties":{"id":"40456","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.005019,36.629517],[-85.920663,36.625999],[-85.866334,36.710932],[-85.901976,36.766077],[-85.925994,36.799037],[-86.03913,36.789871],[-86.04673,36.696297],[-86.005019,36.629517]]]},"properties":{"id":"42133","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.814348,37.010302],[-88.789738,37.180118],[-89.057614,37.18903],[-88.937394,37.152585],[-88.94466,37.137683],[-88.887377,36.983596],[-88.85508,36.950396],[-88.826731,36.951769],[-88.824813,36.97491],[-88.814348,37.010302]]]},"properties":{"id":"42053","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.528031,37.025706],[-83.536681,36.992119],[-83.522219,36.999324],[-83.5275,37.025663],[-83.528031,37.025706]]],[[[-83.609795,36.896302],[-83.541123,36.87859],[-83.503488,36.905775],[-83.531214,36.943122],[-83.589521,36.955786],[-83.609795,36.896302]]]]},"properties":{"id":"40913","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.580011,36.786226],[-83.611458,36.678043],[-83.572112,36.673565],[-83.562851,36.764287],[-83.580011,36.786226]]]},"properties":{"id":"40813","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.845016,36.610757],[-83.898869,36.586705],[-83.840492,36.585139],[-83.845016,36.610757]]],[[[-83.930762,36.587691],[-83.956234,36.645491],[-84.050949,36.590417],[-83.930762,36.587691]]]]},"properties":{"id":"40940","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.012219,38.1613],[-84.106512,38.271653],[-84.138083,38.269045],[-84.1462,38.311644],[-84.164099,38.35179],[-84.442661,38.283236],[-84.401847,38.207889],[-84.365638,38.13541],[-84.286461,38.067028],[-84.080527,38.115118],[-84.012219,38.1613]]]},"properties":{"id":"40361","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.63443,38.436758],[-82.705536,38.497214],[-82.72009,38.493985],[-82.73067,38.487006],[-82.75591,38.465422],[-82.817454,38.373935],[-82.726992,38.348507],[-82.63443,38.436758]]]},"properties":{"id":"41102","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.039298,37.631247],[-85.097382,37.631101],[-85.109763,37.53583],[-85.043284,37.502747],[-84.931684,37.505691],[-85.027812,37.557786],[-85.039298,37.631247]]]},"properties":{"id":"40328","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.923743,38.593482],[-83.923183,38.654182],[-83.960746,38.657591],[-84.004207,38.66388],[-84.01888,38.584064],[-83.962185,38.540973],[-83.923743,38.593482]]]},"properties":{"id":"41044","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.68834,37.336776],[-83.571013,37.276638],[-83.469149,37.374654],[-83.563303,37.546566],[-83.824013,37.487246],[-83.847723,37.444807],[-83.801423,37.364295],[-83.71781,37.338805],[-83.68834,37.336776]]]},"properties":{"id":"41314","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.756471,37.708537],[-83.784448,37.639408],[-83.684265,37.68723],[-83.65556,37.691978],[-83.667245,37.757457],[-83.67378,37.764953],[-83.689309,37.742996],[-83.7221,37.71642],[-83.756471,37.708537]]],[[[-83.599704,37.661892],[-83.517192,37.611196],[-83.415104,37.626319],[-83.248692,37.669616],[-83.262172,37.712657],[-83.484258,37.84391],[-83.49508,37.861906],[-83.574799,37.819797],[-83.630105,37.74459],[-83.599704,37.661892]]]]},"properties":{"id":"41301","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.784448,37.639408],[-83.90557,37.542856],[-83.824013,37.487246],[-83.563303,37.546566],[-83.517192,37.611196],[-83.599704,37.661892],[-83.650398,37.689525],[-83.684265,37.68723],[-83.784448,37.639408]]]},"properties":{"id":"41311","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.451397,37.938497],[-86.421167,37.856712],[-86.387911,37.86863],[-86.389138,37.96532],[-86.451397,37.938497]]],[[[-86.357082,37.782633],[-86.254647,37.738311],[-86.253882,37.781917],[-86.204348,37.842018],[-86.305736,37.918286],[-86.352374,37.848236],[-86.357082,37.782633]]]]},"properties":{"id":"40146","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.46971,37.930976],[-86.481724,37.995655],[-86.490854,38.045735],[-86.60376,37.859344],[-86.46971,37.930976]]]},"properties":{"id":"40170","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.378211,37.932294],[-85.352535,37.94496],[-85.383403,37.939839],[-85.378211,37.932294]]],[[[-85.378211,37.932294],[-85.39968,37.93994],[-85.476616,38.018773],[-85.558714,37.994693],[-85.599678,37.869442],[-85.568291,37.863312],[-85.397184,37.883035],[-85.378211,37.932294]]]]},"properties":{"id":"40013","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.033656,37.077005],[-86.904348,37.164801],[-86.894297,37.179222],[-86.894376,37.179288],[-86.913714,37.177601],[-87.033656,37.077005]]]},"properties":{"id":"42339","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.454925,37.288732],[-86.466415,37.152623],[-86.399165,37.169896],[-86.428019,37.274167],[-86.454925,37.288732]]]},"properties":{"id":"42275","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.17795,37.162739],[-87.934833,37.30383],[-87.813413,37.350645],[-87.904573,37.398949],[-87.938518,37.446858],[-88.061332,37.505332],[-88.358466,37.404752],[-88.21898,37.182387],[-88.193519,37.147256],[-88.163502,37.143074],[-88.178462,37.161246],[-88.197719,37.178921],[-88.184791,37.182293],[-88.17795,37.162739]],[[-88.248161,37.432694],[-88.249323,37.439483],[-88.24428,37.437348],[-88.242672,37.434097],[-88.248161,37.432694]]]},"properties":{"id":"42064","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.32222,36.745993],[-88.236384,36.681434],[-88.149294,36.748635],[-88.32222,36.745993]]]},"properties":{"id":"42036","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.456633,36.76317],[-88.486463,36.699047],[-88.460012,36.686064],[-88.360803,36.710698],[-88.360714,36.728161],[-88.456633,36.76317]]]},"properties":{"id":"42054","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.049848,38.678016],[-85.113625,38.716093],[-85.333099,38.736228],[-85.243406,38.67375],[-85.229151,38.627205],[-85.176283,38.584493],[-85.124411,38.643081],[-85.078208,38.65461],[-85.049848,38.678016]]]},"properties":{"id":"41008","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.0704,38.280979],[-83.080081,38.282223],[-83.08032,38.273561],[-83.066504,38.272675],[-83.0704,38.280979]]]},"properties":{"id":"41142","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.699769,36.929779],[-87.706405,36.785226],[-87.673674,36.793699],[-87.602666,36.936338],[-87.699769,36.929779]]]},"properties":{"id":"42232","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.588549,37.124815],[-87.419759,37.131086],[-87.449635,37.233997],[-87.475219,37.22954],[-87.57004,37.200733],[-87.561834,37.19667],[-87.555402,37.183004],[-87.5627,37.179784],[-87.588549,37.124815]]]},"properties":{"id":"42442","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.57049,37.165292],[-83.535485,37.103292],[-83.491384,37.108789],[-83.492423,37.163226],[-83.49755,37.173545],[-83.497214,37.218457],[-83.511106,37.211022],[-83.57049,37.165292]]]},"properties":{"id":"41714","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.891168,37.558099],[-88.124938,37.570074],[-88.061332,37.505332],[-87.938518,37.446858],[-87.891168,37.558099]]]},"properties":{"id":"42459","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.776497,37.950155],[-86.876666,37.811754],[-86.807779,37.74911],[-86.675769,37.735951],[-86.653593,37.732607],[-86.655272,37.842521],[-86.776497,37.950155]]]},"properties":{"id":"42348","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.089458,37.567259],[-87.266082,37.625688],[-87.408547,37.683563],[-87.495331,37.647547],[-87.375145,37.569988],[-87.354556,37.528374],[-87.181826,37.495633],[-87.136872,37.484922],[-87.089458,37.567259]],[[-87.39592,37.614289],[-87.391928,37.613058],[-87.396625,37.611972],[-87.39592,37.614289]]]},"properties":{"id":"42327","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.419845,38.041068],[-84.453474,38.025781],[-84.458301,38.012626],[-84.445812,38.001151],[-84.339955,37.931761],[-84.290492,38.030384],[-84.419845,38.041068]]]},"properties":{"id":"40509","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.745477,38.493912],[-83.923743,38.593482],[-83.962185,38.540973],[-83.948473,38.479355],[-83.819455,38.470219],[-83.745477,38.493912]]]},"properties":{"id":"41055","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.750352,37.592784],[-82.715766,37.590826],[-82.683691,37.617374],[-82.720801,37.631097],[-82.72187,37.61994],[-82.738846,37.619002],[-82.750352,37.592784]]]},"properties":{"id":"41601","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.685482,37.364539],[-82.716469,37.354017],[-82.716632,37.352603],[-82.700274,37.335267],[-82.657772,37.34639],[-82.664592,37.367861],[-82.685482,37.364539]]]},"properties":{"id":"41650","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.64258,37.56958],[-82.608611,37.590018],[-82.60481,37.609612],[-82.650231,37.616013],[-82.674474,37.574072],[-82.64258,37.56958]]]},"properties":{"id":"41642","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.756007,37.377515],[-82.696119,37.370781],[-82.685174,37.386284],[-82.764204,37.426046],[-82.756007,37.377515]]]},"properties":{"id":"41636","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.034725,36.502979],[-89.033817,36.595682],[-89.104861,36.629318],[-89.034725,36.502979]]]},"properties":{"id":"42050","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.776699,36.826808],[-88.816715,36.664324],[-88.635498,36.585608],[-88.542201,36.64002],[-88.486463,36.699047],[-88.456633,36.76317],[-88.513377,36.821326],[-88.58161,36.858944],[-88.633422,36.855633],[-88.776699,36.826808]]]},"properties":{"id":"42066","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.75591,38.465422],[-82.905584,38.408189],[-82.84136,38.38023],[-82.817454,38.373935],[-82.75591,38.465422]]]},"properties":{"id":"41121","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.708154,38.548222],[-82.760865,38.548441],[-82.756747,38.532418],[-82.72009,38.493985],[-82.705536,38.497214],[-82.665411,38.505787],[-82.708154,38.548222]]]},"properties":{"id":"41169","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.106381,38.675356],[-83.04335,38.662636],[-82.997171,38.728952],[-83.106381,38.675356]]]},"properties":{"id":"41174","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.987762,37.922148],[-85.957215,37.864166],[-85.945406,37.86147],[-85.92557,37.992253],[-85.990407,37.95383],[-85.985167,37.925556],[-85.987762,37.922148]]]},"properties":{"id":"40121","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.906612,37.80035],[-85.945406,37.86147],[-85.957215,37.864166],[-85.971687,37.860823],[-85.968042,37.775396],[-85.918325,37.766754],[-85.906612,37.80035]]]},"properties":{"id":"40160","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.073167,36.905998],[-82.970792,36.857641],[-82.873777,36.912299],[-82.904601,36.919157],[-83.034166,36.943551],[-83.073167,36.905998]]]},"properties":{"id":"40843","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.837411,37.041997],[-82.807943,37.069953],[-82.843555,37.062291],[-82.837411,37.041997]]],[[[-82.98454,36.997903],[-83.146804,36.955404],[-83.196216,36.932412],[-83.192642,36.916502],[-83.096513,36.931453],[-83.096554,36.931232],[-83.034166,36.943551],[-82.904601,36.919157],[-82.936973,36.960371],[-82.939354,36.966242],[-82.936601,36.966447],[-82.869212,36.972253],[-82.869192,36.974186],[-82.938967,37.015224],[-82.98454,36.997903]]]]},"properties":{"id":"40823","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.287553,36.862826],[-83.338423,36.852275],[-83.354547,36.827821],[-83.372417,36.797898],[-83.3815,36.743597],[-83.459459,36.697387],[-83.461016,36.66486],[-83.287038,36.714281],[-83.280372,36.72693],[-83.298642,36.757133],[-83.308818,36.754232],[-83.340933,36.745271],[-83.330999,36.784314],[-83.31877,36.790824],[-83.285571,36.814922],[-83.275203,36.81567],[-83.25379,36.835601],[-83.287553,36.862826]]]},"properties":{"id":"40831","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.287553,36.862826],[-83.25379,36.835601],[-83.231442,36.832909],[-83.222407,36.882507],[-83.287553,36.862826]]]},"properties":{"id":"40801","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.970792,36.857641],[-83.073167,36.905998],[-83.072836,36.854457],[-82.970792,36.857641]]]},"properties":{"id":"40927","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.077654,38.503069],[-85.120417,38.431671],[-85.157919,38.321558],[-85.072738,38.304861],[-84.997667,38.335586],[-84.899006,38.377975],[-85.004173,38.432075],[-85.023281,38.484045],[-85.054859,38.512755],[-85.055556,38.513936],[-85.077654,38.503069]]]},"properties":{"id":"40057","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.004173,38.432075],[-84.899006,38.377975],[-84.870482,38.356751],[-84.955794,38.472703],[-85.029154,38.507901],[-85.054859,38.512755],[-85.023281,38.484045],[-84.994577,38.501085],[-84.975694,38.430832],[-85.004173,38.432075]]]},"properties":{"id":"40036","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.940304,37.392142],[-83.936802,37.39293],[-83.944289,37.398293],[-83.940304,37.392142]]]},"properties":{"id":"40434","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.646634,38.317187],[-85.605549,38.275557],[-85.574168,38.286895],[-85.514159,38.286451],[-85.526966,38.31795],[-85.537461,38.331989],[-85.646634,38.317187]]]},"properties":{"id":"40241","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.786619,38.215127],[-85.797804,38.174591],[-85.768462,38.198538],[-85.773538,38.205904],[-85.786619,38.215127]]]},"properties":{"id":"40215","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.90616,38.116887],[-85.829296,38.147067],[-85.827132,38.153228],[-85.89507,38.187028],[-85.90616,38.116887]]]},"properties":{"id":"40258","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.659853,38.164393],[-85.640428,38.161811],[-85.64036,38.161619],[-85.633001,38.161602],[-85.615993,38.181313],[-85.615836,38.193686],[-85.651185,38.221537],[-85.689064,38.20207],[-85.692309,38.194342],[-85.692668,38.192061],[-85.672451,38.173598],[-85.659853,38.164393]]]},"properties":{"id":"40218","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.646634,38.317187],[-85.537461,38.331989],[-85.538885,38.382959],[-85.609712,38.434817],[-85.657702,38.321515],[-85.646634,38.317187]]]},"properties":{"id":"40059","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.902153,37.853086],[-82.962476,37.896016],[-83.004649,37.859247],[-82.950297,37.786879],[-82.933325,37.7856],[-82.915267,37.796522],[-82.902153,37.853086]]]},"properties":{"id":"41238","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.622223,39.06247],[-84.551651,38.987807],[-84.502985,38.970523],[-84.519061,39.049469],[-84.550236,39.082707],[-84.56974,39.083659],[-84.623752,39.074353],[-84.622223,39.06247]]]},"properties":{"id":"41017","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.418107,38.806596],[-84.532288,38.791937],[-84.510939,38.752275],[-84.517245,38.722722],[-84.446972,38.701061],[-84.419583,38.717606],[-84.418107,38.806596]]]},"properties":{"id":"41033","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.068272,37.246677],[-83.002274,37.198988],[-82.997288,37.230258],[-83.033228,37.230635],[-83.068272,37.246677]]]},"properties":{"id":"41759","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.000142,37.19571],[-82.977429,37.173434],[-82.953347,37.190867],[-82.917516,37.203468],[-82.907562,37.210615],[-82.948508,37.242841],[-82.996437,37.230553],[-82.997288,37.230258],[-83.002274,37.198988],[-83.000142,37.19571]]]},"properties":{"id":"41847","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.981675,37.392231],[-82.949445,37.34809],[-82.917596,37.377087],[-82.947234,37.399462],[-82.951113,37.412154],[-82.983767,37.417392],[-82.981675,37.392231]]]},"properties":{"id":"41831","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.844215,37.295217],[-82.799467,37.263189],[-82.751546,37.266673],[-82.732693,37.271502],[-82.710805,37.285323],[-82.726394,37.303691],[-82.748131,37.30622],[-82.832404,37.330469],[-82.844215,37.295217]]]},"properties":{"id":"41828","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.844368,36.89498],[-83.830148,36.854783],[-83.826063,36.840468],[-83.797028,36.904254],[-83.822945,36.910803],[-83.844368,36.89498]]]},"properties":{"id":"40915","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.87959,36.942625],[-83.859619,36.918862],[-83.854642,36.903486],[-83.844368,36.89498],[-83.822945,36.910803],[-83.838589,36.933952],[-83.87959,36.942625]]]},"properties":{"id":"40923","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.471493,37.648191],[-85.548954,37.530013],[-85.490835,37.514071],[-85.456325,37.591824],[-85.45906,37.606311],[-85.471493,37.648191]]]},"properties":{"id":"40052","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.326526,37.130834],[-83.342873,37.118885],[-83.323874,37.12403],[-83.326526,37.130834]]],[[[-83.492423,37.163226],[-83.491384,37.108789],[-83.355984,37.109388],[-83.355867,37.109475],[-83.326526,37.130834],[-83.32334,37.224894],[-83.349178,37.283712],[-83.401288,37.316544],[-83.459349,37.292537],[-83.497214,37.218457],[-83.49755,37.173545],[-83.447968,37.199242],[-83.435124,37.177113],[-83.492423,37.163226]]]]},"properties":{"id":"41749","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.491384,37.108789],[-83.535485,37.103292],[-83.539184,37.070107],[-83.410998,37.068609],[-83.377525,37.093368],[-83.355984,37.109388],[-83.491384,37.108789]]]},"properties":{"id":"40868","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.418966,36.935323],[-83.396919,37.057068],[-83.399815,37.052878],[-83.412443,37.041781],[-83.447187,36.923943],[-83.418966,36.935323]]]},"properties":{"id":"40858","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.146804,36.955404],[-82.98454,36.997903],[-82.997539,37.005382],[-83.035531,37.023941],[-83.049517,37.029685],[-83.055084,37.020362],[-83.12209,37.000997],[-83.146804,36.955404]]]},"properties":{"id":"41819","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.005109,37.12167],[-83.06837,37.050776],[-83.049517,37.029685],[-83.035531,37.023941],[-82.953479,37.076441],[-82.968273,37.113487],[-83.005109,37.12167]]]},"properties":{"id":"41821","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.941632,37.074302],[-82.925612,37.116023],[-82.950863,37.116206],[-82.970885,37.122809],[-82.968273,37.113487],[-82.953479,37.076441],[-82.941632,37.074302]]]},"properties":{"id":"41848","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.720712,37.238328],[-84.553881,37.17889],[-84.468778,37.284088],[-84.494687,37.322164],[-84.575216,37.342472],[-84.68689,37.330385],[-84.707063,37.303094],[-84.719838,37.263296],[-84.720712,37.238328]]]},"properties":{"id":"42567","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.29984,37.177235],[-88.304731,37.185154],[-88.373242,37.169635],[-88.29984,37.177235]]],[[[-88.265487,37.124314],[-88.217084,37.110611],[-88.193519,37.147256],[-88.21898,37.182387],[-88.278922,37.185243],[-88.29984,37.177235],[-88.265487,37.124314]]]]},"properties":{"id":"42083","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.814348,37.010302],[-88.720618,37.140991],[-88.789738,37.180118],[-88.814348,37.010302]]]},"properties":{"id":"42086","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.678573,36.9589],[-88.528015,36.942547],[-88.484875,36.957084],[-88.482957,37.022566],[-88.566061,37.075204],[-88.581234,37.09338],[-88.678573,36.9589]]]},"properties":{"id":"42003","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.109763,37.53583],[-85.18745,37.451219],[-85.168995,37.39574],[-85.044502,37.419116],[-85.043542,37.441403],[-85.043284,37.502747],[-85.109763,37.53583]]]},"properties":{"id":"40009","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.546345,37.851769],[-82.659089,37.779983],[-82.64075,37.720182],[-82.603879,37.701662],[-82.442668,37.795391],[-82.452786,37.813811],[-82.448905,37.869615],[-82.470947,37.899478],[-82.501703,37.935474],[-82.579956,37.943593],[-82.546345,37.851769]]]},"properties":{"id":"41224","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.470947,37.899478],[-82.448905,37.869615],[-82.411409,37.842193],[-82.470947,37.899478]]]},"properties":{"id":"41267","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.548388,37.913913],[-83.515753,37.916523],[-83.526858,37.969182],[-83.548388,37.913913]]]},"properties":{"id":"40316","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.042535,37.18321],[-87.049593,37.163844],[-87.017952,37.177783],[-87.042535,37.18321]]]},"properties":{"id":"42323","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.139793,37.336699],[-87.162777,37.354731],[-87.170926,37.315626],[-87.136304,37.320115],[-87.139793,37.336699]]]},"properties":{"id":"42374","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.055084,37.020362],[-83.160647,37.016334],[-83.12209,37.000997],[-83.055084,37.020362]]]},"properties":{"id":"41735","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.257942,37.408386],[-83.204936,37.424277],[-83.215189,37.443167],[-83.257942,37.408386]]]},"properties":{"id":"41367","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.453056,37.665444],[-82.393659,37.596838],[-82.387014,37.651903],[-82.453056,37.665444]]]},"properties":{"id":"41571","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.208303,37.438274],[-82.255011,37.396531],[-82.202576,37.374163],[-82.149776,37.410228],[-82.191949,37.452228],[-82.208303,37.438274]]]},"properties":{"id":"41566","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.302833,37.350083],[-82.388609,37.418153],[-82.369809,37.39592],[-82.354026,37.387142],[-82.302833,37.350083]]]},"properties":{"id":"41540","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.724343,37.23578],[-84.771257,37.202506],[-84.776783,37.198536],[-84.811674,37.173532],[-84.696561,37.102788],[-84.553881,37.17889],[-84.720712,37.238328],[-84.724343,37.23578]]]},"properties":{"id":"42553","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.289076,37.15174],[-84.436729,37.271858],[-84.468778,37.284088],[-84.553881,37.17889],[-84.696561,37.102788],[-84.70514,36.981945],[-84.679165,36.980914],[-84.610949,36.987548],[-84.606078,36.995285],[-84.29369,37.145742],[-84.289076,37.15174]]]},"properties":{"id":"42503","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.679165,36.980914],[-84.620231,36.896321],[-84.610949,36.987548],[-84.679165,36.980914]]]},"properties":{"id":"42518","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.410197,38.231139],[-85.392146,38.137571],[-85.329696,38.181394],[-85.330137,38.308672],[-85.385872,38.30801],[-85.410197,38.231139]]]},"properties":{"id":"40067","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.901112,37.116297],[-84.95153,37.126274],[-85.090996,37.188631],[-85.205046,36.997624],[-85.20754,36.990855],[-85.003151,36.954659],[-84.901112,37.116297]]]},"properties":{"id":"42642","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.030528,37.891538],[-84.765204,37.847835],[-84.796327,37.970088],[-85.030528,37.891538]]]},"properties":{"id":"40372","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.979431,37.028047],[-89.094279,37.143984],[-89.097,37.015262],[-88.979431,37.028047]]]},"properties":{"id":"42024","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.136291,36.932116],[-86.070215,36.877294],[-86.033621,36.849857],[-85.925994,36.799037],[-85.901976,36.766077],[-85.878143,36.780059],[-85.788294,36.828705],[-85.783947,36.910572],[-85.802577,36.928956],[-85.7574,36.937503],[-85.74747,36.962762],[-85.755426,37.026786],[-85.758552,37.096641],[-85.996961,37.073859],[-86.029354,37.05031],[-86.136291,36.932116]]]},"properties":{"id":"42141","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.061995,37.13209],[-85.996961,37.073859],[-85.758552,37.096641],[-85.758398,37.099736],[-85.774588,37.139932],[-86.051202,37.201483],[-86.061995,37.13209]]]},"properties":{"id":"42127","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.611458,36.678043],[-83.845016,36.610757],[-83.840492,36.585139],[-83.584238,36.641593],[-83.572112,36.673565],[-83.611458,36.678043]]]},"properties":{"id":"40965","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.807542,39.109914],[-84.777848,39.097725],[-84.775662,39.128495],[-84.807542,39.109914]]],[[[-84.778116,39.088094],[-84.837734,38.988796],[-84.828639,38.897022],[-84.706824,38.990741],[-84.697001,39.044437],[-84.778116,39.088094]]]]},"properties":{"id":"41005","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.642979,39.055237],[-84.596475,38.969954],[-84.551651,38.987807],[-84.622223,39.06247],[-84.642979,39.055237]]]},"properties":{"id":"41018","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.743294,38.853885],[-84.627369,38.847798],[-84.568615,38.834862],[-84.555156,38.870599],[-84.609133,38.957166],[-84.638743,38.934872],[-84.743294,38.853885]]]},"properties":{"id":"41094","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.778093,38.854574],[-84.727977,38.770495],[-84.67876,38.775425],[-84.627369,38.847798],[-84.743294,38.853885],[-84.778093,38.854574]]]},"properties":{"id":"41092","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.027812,37.557786],[-84.931684,37.505691],[-84.857199,37.523639],[-84.847326,37.547894],[-84.859129,37.544268],[-84.940721,37.625835],[-85.027812,37.557786]]]},"properties":{"id":"40464","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.641606,37.559747],[-86.576824,37.539061],[-86.435376,37.514074],[-86.466029,37.583184],[-86.465537,37.621093],[-86.595478,37.715199],[-86.640253,37.699505],[-86.641606,37.559747]]]},"properties":{"id":"40119","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.632132,37.844397],[-85.761812,37.943411],[-85.760024,37.814218],[-85.737777,37.81179],[-85.632132,37.844397]]]},"properties":{"id":"40150","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.719976,38.085257],[-85.701854,38.062743],[-85.596213,38.077274],[-85.582395,38.076119],[-85.602608,38.101493],[-85.643537,38.118292],[-85.701463,38.115038],[-85.719976,38.085257]]]},"properties":{"id":"40229","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.904348,37.164801],[-86.74897,37.024402],[-86.690759,37.005153],[-86.674462,36.999766],[-86.667359,37.018268],[-86.466415,37.152623],[-86.454925,37.288732],[-86.612095,37.395123],[-86.740932,37.31596],[-86.80825,37.291386],[-86.779745,37.229124],[-86.894297,37.179222],[-86.904348,37.164801]]]},"properties":{"id":"42261","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.389131,37.425304],[-86.427857,37.459528],[-86.398308,37.47601],[-86.435376,37.514074],[-86.576824,37.539061],[-86.603696,37.493943],[-86.612095,37.395123],[-86.454925,37.288732],[-86.428019,37.274167],[-86.380114,37.32447],[-86.389131,37.425304]]]},"properties":{"id":"42721","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.80825,37.291386],[-86.74896,37.433289],[-86.740565,37.446788],[-86.743052,37.450404],[-86.741812,37.453983],[-86.744708,37.474875],[-86.786016,37.490376],[-86.939788,37.437379],[-86.983823,37.347673],[-86.991191,37.32558],[-86.988288,37.302688],[-86.899268,37.212313],[-86.779745,37.229124],[-86.80825,37.291386]],[[-86.922617,37.384699],[-86.926274,37.36985],[-86.941116,37.372306],[-86.931056,37.386128],[-86.922617,37.384699]]]},"properties":{"id":"42320","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.934833,37.30383],[-88.01724,37.134942],[-87.891206,36.959904],[-87.75453,36.983802],[-87.69254,37.100022],[-87.761209,37.302107],[-87.770336,37.328625],[-87.78401,37.341745],[-87.813413,37.350645],[-87.934833,37.30383]]]},"properties":{"id":"42445","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.486463,36.699047],[-88.542201,36.64002],[-88.498243,36.516198],[-88.488447,36.539903],[-88.460012,36.686064],[-88.486463,36.699047]]]},"properties":{"id":"42040","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.462971,39.102124],[-84.470854,39.095933],[-84.465595,39.04997],[-84.433553,39.072842],[-84.440986,39.109557],[-84.462971,39.102124]]]},"properties":{"id":"41075","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.444698,38.912086],[-84.422765,38.824851],[-84.375543,38.850025],[-84.350441,38.966583],[-84.379698,39.002929],[-84.428487,38.975104],[-84.444698,38.912086]]]},"properties":{"id":"41001","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.100864,36.944016],[-89.123531,36.785217],[-89.095346,36.754561],[-88.919971,36.772406],[-88.897598,36.837567],[-89.08916,36.94043],[-89.100864,36.944016]]]},"properties":{"id":"42021","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.113625,38.716093],[-85.049848,38.678016],[-84.971868,38.758789],[-84.964412,38.77825],[-85.113625,38.716093]]]},"properties":{"id":"41045","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.259691,37.070467],[-87.281888,37.088676],[-87.419759,37.131086],[-87.588549,37.124815],[-87.624602,37.120944],[-87.616038,37.067982],[-87.586675,36.95834],[-87.259691,37.070467]]]},"properties":{"id":"42217","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.465399,36.677756],[-87.475678,36.640396],[-87.436324,36.640846],[-87.465399,36.677756]]]},"properties":{"id":"42223","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.863257,37.090259],[-84.131538,37.084654],[-84.107785,37.075196],[-84.122486,37.067688],[-84.133619,37.081835],[-84.314139,37.004624],[-84.358024,36.9594],[-84.297416,36.945931],[-84.16106,37.003388],[-84.097979,37.038115],[-84.067685,37.028018],[-84.062023,37.024396],[-83.982651,36.989839],[-83.953191,36.98519],[-83.871315,37.054919],[-83.863257,37.090259]]]},"properties":{"id":"40744","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.945068,37.346018],[-83.880105,37.292174],[-83.808726,37.320901],[-83.801423,37.364295],[-83.847723,37.444807],[-83.945068,37.346018]]]},"properties":{"id":"40486","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.17795,37.162739],[-88.184791,37.182293],[-88.197719,37.178921],[-88.178462,37.161246],[-88.17795,37.162739]]]},"properties":{"id":"42037","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.323643,37.692013],[-87.32325,37.694713],[-87.325481,37.694566],[-87.323643,37.692013]]]},"properties":{"id":"42356","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.051202,37.201483],[-86.066802,37.128478],[-86.061995,37.13209],[-86.051202,37.201483]]],[[[-86.111276,37.243372],[-86.215175,37.31238],[-86.248002,37.277339],[-86.250113,37.266038],[-86.111276,37.243372]]]]},"properties":{"id":"42259","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.97077,38.050954],[-83.069049,38.143876],[-83.074124,38.002739],[-83.022115,38.007047],[-82.97077,38.050954]]]},"properties":{"id":"41149","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.592335,37.986428],[-84.542775,37.966154],[-84.530068,37.976329],[-84.571578,38.002167],[-84.592335,37.986428]]]},"properties":{"id":"40514","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.878439,38.361299],[-83.855815,38.332788],[-83.819455,38.470219],[-83.948473,38.479355],[-83.991254,38.43481],[-83.878439,38.361299]]]},"properties":{"id":"41039","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.772896,37.72956],[-82.848737,37.729167],[-82.88974,37.710809],[-82.940763,37.716338],[-82.943982,37.688668],[-82.910028,37.647591],[-82.901665,37.62721],[-82.883201,37.627445],[-82.818041,37.637387],[-82.818869,37.620131],[-82.848328,37.613026],[-82.847828,37.61273],[-82.750352,37.592784],[-82.738846,37.619002],[-82.720801,37.631097],[-82.683691,37.617374],[-82.650231,37.616013],[-82.60481,37.609612],[-82.563561,37.684844],[-82.603879,37.701662],[-82.64075,37.720182],[-82.732085,37.703926],[-82.772896,37.72956]]]},"properties":{"id":"41653","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.848328,37.613026],[-82.883201,37.627445],[-82.901665,37.62721],[-82.904157,37.602733],[-82.886469,37.533223],[-82.83977,37.547614],[-82.837559,37.552302],[-82.847828,37.61273],[-82.848328,37.613026]]]},"properties":{"id":"41616","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.65274,37.523435],[-82.681478,37.481768],[-82.686095,37.45489],[-82.687834,37.449075],[-82.655309,37.444561],[-82.641895,37.44553],[-82.65274,37.523435]]]},"properties":{"id":"41631","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.711812,37.524382],[-82.681478,37.481768],[-82.65274,37.523435],[-82.660191,37.532172],[-82.711812,37.524382]]],[[[-82.683691,37.617374],[-82.715766,37.590826],[-82.707801,37.543556],[-82.671489,37.564339],[-82.674474,37.574072],[-82.650231,37.616013],[-82.683691,37.617374]]]]},"properties":{"id":"41603","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.608611,37.590018],[-82.64258,37.56958],[-82.641553,37.557171],[-82.61364,37.564118],[-82.608611,37.590018]]]},"properties":{"id":"41659","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.773962,37.440523],[-82.830419,37.467133],[-82.829919,37.449527],[-82.830879,37.412164],[-82.796548,37.418139],[-82.767931,37.427657],[-82.773962,37.440523]]]},"properties":{"id":"41666","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.64258,37.56958],[-82.674474,37.574072],[-82.671489,37.564339],[-82.646935,37.551506],[-82.642001,37.555243],[-82.641553,37.557171],[-82.64258,37.56958]]]},"properties":{"id":"41663","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.072738,38.304861],[-85.021686,38.22031],[-84.997667,38.335586],[-85.072738,38.304861]]]},"properties":{"id":"40003","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.571138,36.553779],[-89.539232,36.497934],[-89.485427,36.497491],[-89.47993,36.569087],[-89.571138,36.553779]]]},"properties":{"id":"38079","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.680188,37.623502],[-84.505084,37.536513],[-84.496855,37.575836],[-84.445999,37.651279],[-84.525643,37.769497],[-84.649174,37.809655],[-84.716925,37.815389],[-84.744888,37.713075],[-84.680188,37.623502]]]},"properties":{"id":"40444","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.505084,37.536513],[-84.680188,37.623502],[-84.788645,37.574406],[-84.847326,37.547894],[-84.857199,37.523639],[-84.761699,37.460268],[-84.749339,37.462683],[-84.756774,37.454185],[-84.687811,37.419112],[-84.59245,37.43321],[-84.505084,37.536513]]]},"properties":{"id":"40484","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.603696,37.493943],[-86.744708,37.474875],[-86.741812,37.453983],[-86.740565,37.446788],[-86.74896,37.433289],[-86.740932,37.31596],[-86.612095,37.395123],[-86.603696,37.493943]]]},"properties":{"id":"42349","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.544876,37.37498],[-85.352771,37.192432],[-85.283052,37.290772],[-85.195791,37.370719],[-85.196906,37.378207],[-85.186317,37.384382],[-85.168995,37.39574],[-85.18745,37.451219],[-85.380821,37.522483],[-85.490835,37.514071],[-85.548954,37.530013],[-85.584751,37.531089],[-85.577499,37.496071],[-85.567363,37.406666],[-85.544876,37.37498]]]},"properties":{"id":"42718","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.185952,37.665892],[-86.064073,37.507902],[-86.031544,37.555281],[-86.011906,37.583553],[-86.185952,37.665892]]]},"properties":{"id":"42732","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.234327,36.922112],[-83.190317,36.913585],[-83.192642,36.916502],[-83.196216,36.932412],[-83.234327,36.922112]]]},"properties":{"id":"40865","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.377079,36.842227],[-83.372417,36.797898],[-83.354547,36.827821],[-83.370663,36.844852],[-83.377079,36.842227]]]},"properties":{"id":"40824","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.614367,38.353993],[-84.453658,38.334028],[-84.488202,38.442038],[-84.660302,38.407692],[-84.614367,38.353993]]]},"properties":{"id":"40370","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.785725,38.253691],[-85.786645,38.248488],[-85.767838,38.23354],[-85.742059,38.229891],[-85.741144,38.23078],[-85.739637,38.243938],[-85.771761,38.263385],[-85.785725,38.253691]]]},"properties":{"id":"40203","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.663936,38.253575],[-85.659502,38.253432],[-85.663972,38.259044],[-85.667871,38.257415],[-85.663936,38.253575]]]},"properties":{"id":"40041","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.773538,38.205904],[-85.768462,38.198538],[-85.765564,38.205152],[-85.773538,38.205904]]],[[[-85.744961,38.206309],[-85.750978,38.167404],[-85.747059,38.152888],[-85.672451,38.173598],[-85.692668,38.192061],[-85.698146,38.194261],[-85.697451,38.196899],[-85.692514,38.197169],[-85.692309,38.194342],[-85.689064,38.20207],[-85.713067,38.217316],[-85.744961,38.206309]]]]},"properties":{"id":"40213","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.672451,38.173598],[-85.747059,38.152888],[-85.748773,38.126285],[-85.701463,38.115038],[-85.643537,38.118292],[-85.64036,38.161619],[-85.640428,38.161811],[-85.659853,38.164393],[-85.672451,38.173598]]]},"properties":{"id":"40219","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.64036,38.161619],[-85.643537,38.118292],[-85.602608,38.101493],[-85.633001,38.161602],[-85.64036,38.161619]]]},"properties":{"id":"40228","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.806343,37.910929],[-82.80852,37.874659],[-82.789389,37.85553],[-82.743363,37.882245],[-82.765153,37.9016],[-82.784706,37.908106],[-82.806343,37.910929]]],[[[-82.950297,37.786879],[-82.940763,37.716338],[-82.88974,37.710809],[-82.933325,37.7856],[-82.950297,37.786879]]],[[[-82.676346,37.892208],[-82.726684,37.857739],[-82.745688,37.831561],[-82.735048,37.832579],[-82.711622,37.838539],[-82.668943,37.845695],[-82.612393,37.879272],[-82.676346,37.892208]]],[[[-82.780065,37.779899],[-82.772199,37.788402],[-82.757449,37.806711],[-82.766993,37.829669],[-82.792221,37.839545],[-82.829782,37.844078],[-82.836185,37.821866],[-82.793758,37.792214],[-82.783515,37.792133],[-82.780065,37.779899]]]]},"properties":{"id":"41240","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726684,37.857739],[-82.743363,37.882245],[-82.789389,37.85553],[-82.792221,37.839545],[-82.766993,37.829669],[-82.745688,37.831561],[-82.726684,37.857739]]]},"properties":{"id":"41263","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.555156,38.870599],[-84.494943,38.918553],[-84.502985,38.970523],[-84.551651,38.987807],[-84.596475,38.969954],[-84.609133,38.957166],[-84.555156,38.870599]]]},"properties":{"id":"41051","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.924781,37.257629],[-82.874886,37.238294],[-82.799467,37.263189],[-82.844215,37.295217],[-82.886896,37.299585],[-82.914774,37.27341],[-82.924781,37.257629]]]},"properties":{"id":"41843","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.838589,36.933952],[-83.833159,36.949232],[-83.842931,36.975659],[-83.87959,36.942625],[-83.838589,36.933952]]]},"properties":{"id":"40943","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.775119,36.951217],[-83.833159,36.949232],[-83.838589,36.933952],[-83.822945,36.910803],[-83.797028,36.904254],[-83.775119,36.951217]]]},"properties":{"id":"40953","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.097979,37.038115],[-84.16106,37.003388],[-84.105832,37.001654],[-84.097979,37.038115]]]},"properties":{"id":"40737","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.131538,37.084654],[-84.133619,37.081835],[-84.122486,37.067688],[-84.107785,37.075196],[-84.131538,37.084654]]]},"properties":{"id":"40743","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.673417,37.913464],[-82.70026,37.959026],[-82.72141,37.910211],[-82.673417,37.913464]]]},"properties":{"id":"41264","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.226805,37.152936],[-83.296115,37.117822],[-83.196436,37.112355],[-83.226805,37.152936]]]},"properties":{"id":"41764","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.657835,37.19755],[-82.709069,37.17963],[-82.711987,37.173991],[-82.731426,37.153954],[-82.674243,37.136371],[-82.586322,37.185864],[-82.591843,37.195514],[-82.575266,37.205562],[-82.565379,37.196099],[-82.533377,37.206501],[-82.537776,37.261131],[-82.522276,37.283421],[-82.575595,37.316795],[-82.5836,37.31647],[-82.585615,37.314546],[-82.588295,37.315907],[-82.586094,37.318069],[-82.653972,37.235634],[-82.657835,37.19755]]]},"properties":{"id":"41537","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.724782,37.184831],[-82.72884,37.171805],[-82.711987,37.173991],[-82.709069,37.17963],[-82.693624,37.197025],[-82.694501,37.199099],[-82.733851,37.229806],[-82.736594,37.229018],[-82.724782,37.184831]]]},"properties":{"id":"41840","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179963,38.501012],[-83.199034,38.616843],[-83.644913,38.636343],[-83.624679,38.611553],[-83.545732,38.482899],[-83.452848,38.381799],[-83.341347,38.319521],[-83.317626,38.332094],[-83.312865,38.36664],[-83.262522,38.379311],[-83.261252,38.345554],[-83.302563,38.330678],[-83.186411,38.469428],[-83.179963,38.501012]]]},"properties":{"id":"41179","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.29984,37.177235],[-88.278922,37.185243],[-88.304731,37.185154],[-88.29984,37.177235]]],[[[-88.367647,37.401883],[-88.424739,37.150943],[-88.434682,37.04742],[-88.332436,37.058176],[-88.265487,37.124314],[-88.29984,37.177235],[-88.373242,37.169635],[-88.384543,37.266745],[-88.377583,37.352421],[-88.367647,37.401883]]]]},"properties":{"id":"42081","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.295836,37.035082],[-88.240371,36.981911],[-88.222139,37.018708],[-88.217084,37.110611],[-88.265487,37.124314],[-88.332436,37.058176],[-88.295836,37.035082]]]},"properties":{"id":"42045","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.690759,37.005153],[-86.728359,36.775351],[-86.635885,36.826861],[-86.644851,36.879383],[-86.674462,36.999766],[-86.690759,37.005153]]]},"properties":{"id":"42206","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.222139,37.018708],[-88.102776,37.050193],[-88.163502,37.143074],[-88.193519,37.147256],[-88.217084,37.110611],[-88.222139,37.018708]]]},"properties":{"id":"42055","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.910028,37.647591],[-82.990547,37.643608],[-82.943982,37.688668],[-82.940763,37.716338],[-82.950297,37.786879],[-83.004649,37.859247],[-83.263286,37.772486],[-83.252144,37.751658],[-83.262172,37.712657],[-83.248692,37.669616],[-83.247232,37.667439],[-83.017109,37.55159],[-82.904157,37.602733],[-82.901665,37.62721],[-82.910028,37.647591]]]},"properties":{"id":"41465","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.45906,37.606311],[-85.41174,37.586591],[-85.375316,37.574171],[-85.348007,37.656118],[-85.401784,37.730658],[-85.475202,37.690258],[-85.471493,37.648191],[-85.45906,37.606311]],[[-85.395457,37.659402],[-85.399684,37.663674],[-85.396912,37.666267],[-85.389591,37.661831],[-85.395457,37.659402]]]},"properties":{"id":"40037","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.395457,37.659402],[-85.389591,37.661831],[-85.396912,37.666267],[-85.399684,37.663674],[-85.395457,37.659402]]]},"properties":{"id":"40049","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.086971,37.910687],[-86.187647,37.917709],[-86.119779,37.862715],[-86.086971,37.910687]]]},"properties":{"id":"40117","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.98544,37.196009],[-87.050154,37.187119],[-87.042535,37.18321],[-87.017952,37.177783],[-86.98544,37.196009]]]},"properties":{"id":"42326","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.050154,37.187119],[-87.081051,37.173687],[-87.052079,37.158309],[-87.049593,37.163844],[-87.042535,37.18321],[-87.050154,37.187119]]]},"properties":{"id":"42321","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.878439,38.361299],[-83.886878,38.266598],[-83.848355,38.296949],[-83.855815,38.332788],[-83.878439,38.361299]]]},"properties":{"id":"40350","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.792938,37.559548],[-86.813689,37.551474],[-86.793207,37.531916],[-86.770244,37.541587],[-86.765848,37.560214],[-86.792938,37.559548]]]},"properties":{"id":"42338","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.356813,37.336247],[-83.296297,37.310804],[-83.28815,37.316622],[-83.35728,37.358127],[-83.357366,37.358003],[-83.400868,37.338235],[-83.402746,37.332353],[-83.391617,37.330448],[-83.356813,37.336247]]]},"properties":{"id":"41727","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.210764,37.309694],[-83.189713,37.302681],[-83.177311,37.321432],[-83.210764,37.309694]]],[[[-83.210764,37.309694],[-83.28815,37.316622],[-83.296297,37.310804],[-83.305735,37.280522],[-83.241891,37.268241],[-83.210764,37.309694]]]]},"properties":{"id":"41719","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.072792,37.206748],[-83.081521,37.20539],[-83.07427,37.205247],[-83.072792,37.206748]]]},"properties":{"id":"41760","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.170547,37.556794],[-82.206918,37.580971],[-82.230951,37.563354],[-82.239882,37.506488],[-82.226788,37.502899],[-82.170547,37.556794]]]},"properties":{"id":"41558","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.259074,37.597757],[-82.299231,37.603603],[-82.294807,37.580939],[-82.259948,37.597395],[-82.259074,37.597757]]]},"properties":{"id":"41535","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.537776,37.261131],[-82.533377,37.206501],[-82.502466,37.225204],[-82.531993,37.260753],[-82.537776,37.261131]]]},"properties":{"id":"41563","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.393659,37.596838],[-82.368582,37.554045],[-82.345734,37.551634],[-82.337922,37.621708],[-82.387014,37.651903],[-82.393659,37.596838]]]},"properties":{"id":"41564","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.413826,37.366473],[-82.429865,37.371736],[-82.414015,37.365257],[-82.413826,37.366473]]],[[[-82.407132,37.363677],[-82.36093,37.372638],[-82.354026,37.387142],[-82.369809,37.39592],[-82.407132,37.363677]]]]},"properties":{"id":"41559","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.520937,38.104768],[-83.357784,38.065923],[-83.324715,38.08538],[-83.532916,38.120393],[-83.520937,38.104768]]]},"properties":{"id":"40313","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.329696,38.181394],[-85.304823,38.145595],[-85.22326,38.085747],[-85.065885,38.191524],[-85.021686,38.22031],[-85.072738,38.304861],[-85.157919,38.321558],[-85.231957,38.335774],[-85.304761,38.332921],[-85.330137,38.308672],[-85.329696,38.181394]]]},"properties":{"id":"40065","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.891168,37.558099],[-87.938518,37.446858],[-87.904573,37.398949],[-87.7054,37.471418],[-87.78907,37.563518],[-87.891168,37.558099]],[[-87.866546,37.488731],[-87.866653,37.463849],[-87.890747,37.460418],[-87.866546,37.488731]]]},"properties":{"id":"42404","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.495331,37.647547],[-87.627832,37.642805],[-87.644652,37.636649],[-87.616783,37.526054],[-87.375145,37.569988],[-87.495331,37.647547]]]},"properties":{"id":"42455","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.04673,36.696297],[-86.032049,36.630688],[-86.005019,36.629517],[-86.04673,36.696297]]],[[[-86.04673,36.696297],[-86.03913,36.789871],[-86.056117,36.828892],[-86.070215,36.877294],[-86.136291,36.932116],[-86.204176,36.933784],[-86.226309,36.912633],[-86.272356,36.867039],[-86.39085,36.786345],[-86.348155,36.691937],[-86.104827,36.635061],[-86.062673,36.632865],[-86.04673,36.696297]]]]},"properties":{"id":"42164","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.758398,37.099736],[-85.696354,37.07855],[-85.697687,37.116995],[-85.774588,37.139932],[-85.758398,37.099736]]],[[[-85.774588,37.139932],[-85.710528,37.162429],[-85.806211,37.232053],[-86.027832,37.250324],[-86.04957,37.215767],[-86.051202,37.201483],[-85.774588,37.139932]]]]},"properties":{"id":"42749","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.689671,38.109021],[-83.717707,38.023293],[-83.709507,38.009317],[-83.6347,38.026865],[-83.63442,38.026841],[-83.689671,38.109021]]]},"properties":{"id":"40358","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.71729,38.003288],[-83.709507,38.009317],[-83.717707,38.023293],[-83.71729,38.003288]]],[[[-83.71729,38.003288],[-83.759807,38.008595],[-83.769161,38.016004],[-83.779768,38.006471],[-83.774374,37.949865],[-83.719823,37.992663],[-83.71729,38.003288]]]]},"properties":{"id":"40346","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.1462,38.311644],[-84.138083,38.269045],[-84.106512,38.271653],[-84.1462,38.311644]]]},"properties":{"id":"40348","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.401847,38.207889],[-84.633206,38.13556],[-84.64304,38.116121],[-84.625079,38.116397],[-84.537624,38.065165],[-84.523279,38.058584],[-84.483832,38.065469],[-84.451098,38.07792],[-84.365638,38.13541],[-84.401847,38.207889]]]},"properties":{"id":"40511","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.726992,38.348507],[-82.699471,38.261913],[-82.610039,38.1754],[-82.601477,38.442724],[-82.63443,38.436758],[-82.726992,38.348507]]]},"properties":{"id":"41129","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28464,38.75504],[-84.222562,38.684488],[-84.070949,38.765919],[-84.03582,38.772228],[-84.230411,38.827437],[-84.241435,38.836348],[-84.28464,38.75504]]]},"properties":{"id":"41043","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.991254,38.43481],[-83.948473,38.479355],[-83.962185,38.540973],[-84.01888,38.584064],[-84.181159,38.557501],[-84.1021,38.459379],[-83.991254,38.43481]]]},"properties":{"id":"41064","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.466029,37.583184],[-86.411853,37.617709],[-86.438959,37.655251],[-86.465537,37.621093],[-86.466029,37.583184]]]},"properties":{"id":"40152","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.427295,37.68845],[-86.357816,37.634642],[-86.286941,37.671007],[-86.357082,37.782633],[-86.352374,37.848236],[-86.387911,37.86863],[-86.421167,37.856712],[-86.427295,37.68845]]]},"properties":{"id":"40144","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.175566,37.523177],[-86.27527,37.593519],[-86.352268,37.626114],[-86.411853,37.617709],[-86.466029,37.583184],[-86.435376,37.514074],[-86.398308,37.47601],[-86.389131,37.425304],[-86.380114,37.32447],[-86.336814,37.326017],[-86.207406,37.332566],[-86.2009,37.329481],[-86.175566,37.523177]]]},"properties":{"id":"42754","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.80825,37.291386],[-86.740932,37.31596],[-86.74896,37.433289],[-86.80825,37.291386]]]},"properties":{"id":"42333","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.456633,36.76317],[-88.360714,36.728161],[-88.360604,36.735449],[-88.126929,36.79475],[-88.187948,36.933035],[-88.324535,37.003015],[-88.463763,36.946622],[-88.484183,36.866605],[-88.513377,36.821326],[-88.456633,36.76317]]]},"properties":{"id":"42025","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.350441,38.966583],[-84.375543,38.850025],[-84.241435,38.836348],[-84.230411,38.827437],[-84.302965,39.002693],[-84.350441,38.966583]]]},"properties":{"id":"41007","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.46788,39.043778],[-84.470317,39.039569],[-84.471001,39.03066],[-84.464508,39.034848],[-84.46788,39.043778]]]},"properties":{"id":"41099","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.054859,38.512755],[-85.029154,38.507901],[-85.049232,38.570706],[-85.055556,38.513936],[-85.054859,38.512755]]],[[[-85.176283,38.584493],[-85.229151,38.627205],[-85.26931,38.504215],[-85.18356,38.484228],[-85.149885,38.435291],[-85.120417,38.431671],[-85.077654,38.503069],[-85.176283,38.584493]]]]},"properties":{"id":"40011","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.186411,38.469428],[-83.011892,38.199806],[-82.983938,38.176411],[-82.815257,38.217364],[-82.912524,38.269874],[-82.912211,38.28875],[-82.877722,38.281238],[-82.874994,38.266937],[-82.828558,38.292722],[-82.84136,38.38023],[-82.905584,38.408189],[-83.027938,38.486518],[-83.179963,38.501012],[-83.186411,38.469428]]]},"properties":{"id":"41143","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.011892,38.199806],[-83.186411,38.469428],[-83.302563,38.330678],[-83.317626,38.332094],[-83.341347,38.319521],[-83.162941,38.156276],[-83.069049,38.143876],[-83.011892,38.199806]],[[-83.0704,38.280979],[-83.066504,38.272675],[-83.08032,38.273561],[-83.080081,38.282223],[-83.0704,38.280979]]]},"properties":{"id":"41164","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.707063,37.303094],[-84.752211,37.290986],[-84.719838,37.263296],[-84.707063,37.303094]]],[[[-84.706992,37.405323],[-84.68689,37.330385],[-84.575216,37.342472],[-84.59245,37.43321],[-84.687811,37.419112],[-84.706992,37.405323]]]]},"properties":{"id":"40489","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.727566,37.362362],[-84.816901,37.334806],[-84.752211,37.290986],[-84.707063,37.303094],[-84.68689,37.330385],[-84.706992,37.405323],[-84.727566,37.362362]]]},"properties":{"id":"40442","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.811674,37.173532],[-84.826825,37.200979],[-84.946128,37.1419],[-84.95153,37.126274],[-84.901112,37.116297],[-84.811674,37.173532]]]},"properties":{"id":"42565","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.5627,37.179784],[-87.555402,37.183004],[-87.561834,37.19667],[-87.624602,37.120944],[-87.588549,37.124815],[-87.5627,37.179784]]]},"properties":{"id":"42453","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.68834,37.336776],[-83.580959,37.166274],[-83.57049,37.165292],[-83.511106,37.211022],[-83.507886,37.235877],[-83.571013,37.276638],[-83.68834,37.336776]]]},"properties":{"id":"40972","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.535485,37.103292],[-83.57049,37.165292],[-83.580959,37.166274],[-83.539184,37.070107],[-83.535485,37.103292]]]},"properties":{"id":"40914","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.304382,37.892184],[-87.364495,37.80361],[-87.389899,37.727693],[-87.408547,37.683563],[-87.266082,37.625688],[-87.117907,37.697029],[-87.108858,37.776177],[-87.304382,37.892184]],[[-87.323643,37.692013],[-87.325481,37.694566],[-87.32325,37.694713],[-87.323643,37.692013]]]},"properties":{"id":"42301","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.248002,37.277339],[-86.333619,37.27879],[-86.250113,37.266038],[-86.248002,37.277339]]]},"properties":{"id":"42285","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.122293,37.07429],[-86.147268,37.089345],[-86.137256,37.069133],[-86.122293,37.07429]]]},"properties":{"id":"42163","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.158793,38.008182],[-83.182438,37.961306],[-83.21234,37.960399],[-83.212389,38.027126],[-83.259212,38.120148],[-83.324715,38.08538],[-83.357784,38.065923],[-83.433942,38.033974],[-83.417908,37.92471],[-83.349663,37.86662],[-83.294227,37.82071],[-83.263286,37.772486],[-83.004649,37.859247],[-82.962476,37.896016],[-82.98406,37.950948],[-82.989014,37.963968],[-83.022115,38.007047],[-83.074124,38.002739],[-83.158793,38.008182]]]},"properties":{"id":"41472","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.501687,38.025568],[-84.513662,37.993759],[-84.458301,38.012626],[-84.453474,38.025781],[-84.479926,38.041314],[-84.490225,38.040358],[-84.492849,38.036752],[-84.500307,38.028679],[-84.501687,38.025568]]]},"properties":{"id":"40502","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.625079,38.116397],[-84.649258,38.043207],[-84.565728,38.04574],[-84.537624,38.065165],[-84.625079,38.116397]]]},"properties":{"id":"40510","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.816986,37.500761],[-82.775076,37.4685],[-82.771616,37.478639],[-82.777096,37.488311],[-82.763091,37.542451],[-82.837559,37.552302],[-82.83977,37.547614],[-82.833897,37.536101],[-82.798972,37.520181],[-82.813888,37.509497],[-82.816986,37.500761]]]},"properties":{"id":"41645","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.948544,37.503167],[-83.093344,37.497903],[-82.983767,37.417392],[-82.951113,37.412154],[-82.816986,37.500761],[-82.813888,37.509497],[-82.833897,37.536101],[-82.83977,37.547614],[-82.886469,37.533223],[-82.948544,37.503167]]]},"properties":{"id":"41640","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.848328,37.613026],[-82.818869,37.620131],[-82.818041,37.637387],[-82.883201,37.627445],[-82.848328,37.613026]]]},"properties":{"id":"41607","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.61364,37.564118],[-82.641553,37.557171],[-82.642001,37.555243],[-82.617006,37.54674],[-82.61364,37.564118]]]},"properties":{"id":"41605","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.671489,37.564339],[-82.707801,37.543556],[-82.714249,37.533003],[-82.711812,37.524382],[-82.660191,37.532172],[-82.646935,37.551506],[-82.671489,37.564339]]]},"properties":{"id":"41615","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.716469,37.354017],[-82.726338,37.344891],[-82.716632,37.352603],[-82.716469,37.354017]]]},"properties":{"id":"41612","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.033817,36.595682],[-89.034725,36.502979],[-88.645599,36.502193],[-88.653405,36.576477],[-88.706038,36.548709],[-88.852797,36.547006],[-88.871416,36.612474],[-88.890341,36.634297],[-89.033817,36.595682]]]},"properties":{"id":"42041","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.923405,38.768204],[-84.93786,38.763686],[-84.937948,38.763387],[-84.934295,38.632909],[-84.900979,38.613036],[-84.776476,38.637675],[-84.782298,38.689284],[-84.83478,38.751309],[-84.923405,38.768204]]]},"properties":{"id":"41086","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.369394,37.46212],[-84.407999,37.538376],[-84.496855,37.575836],[-84.505084,37.536513],[-84.59245,37.43321],[-84.575216,37.342472],[-84.494687,37.322164],[-84.369394,37.46212]]]},"properties":{"id":"40419","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.502638,38.548959],[-84.45148,38.448722],[-84.239725,38.578164],[-84.48715,38.587329],[-84.502638,38.548959]]]},"properties":{"id":"41003","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.782298,38.689284],[-84.776476,38.637675],[-84.753669,38.685093],[-84.782298,38.689284]]]},"properties":{"id":"41052","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.871416,36.612474],[-88.852797,36.547006],[-88.706038,36.548709],[-88.871416,36.612474]]]},"properties":{"id":"42085","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.730481,37.56469],[-86.786016,37.490376],[-86.744708,37.474875],[-86.603696,37.493943],[-86.576824,37.539061],[-86.641606,37.559747],[-86.730481,37.56469]]]},"properties":{"id":"42361","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.61033,37.390983],[-85.720638,37.317798],[-85.679125,37.278026],[-85.544876,37.37498],[-85.567363,37.406666],[-85.575797,37.405016],[-85.61033,37.390983]]]},"properties":{"id":"42782","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.031544,37.555281],[-86.064073,37.507902],[-86.05399,37.495743],[-85.991749,37.532367],[-86.031544,37.555281]]]},"properties":{"id":"42788","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132215,36.785218],[-83.280372,36.72693],[-83.287038,36.714281],[-83.132215,36.785218]]]},"properties":{"id":"40820","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.285571,36.814922],[-83.31877,36.790824],[-83.300153,36.77765],[-83.285571,36.814922]]]},"properties":{"id":"40829","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.304534,38.448909],[-85.323544,38.384627],[-85.304761,38.332921],[-85.231957,38.335774],[-85.210302,38.414696],[-85.23099,38.452691],[-85.290833,38.460924],[-85.304534,38.448909]]]},"properties":{"id":"40068","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.149885,38.435291],[-85.18356,38.484228],[-85.23099,38.452691],[-85.210302,38.414696],[-85.149885,38.435291]],[[-85.170116,38.432001],[-85.172097,38.432176],[-85.173514,38.433561],[-85.168134,38.433158],[-85.170116,38.432001]]]},"properties":{"id":"40050","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.509387,37.2632],[-87.48889,37.277998],[-87.526935,37.293632],[-87.530826,37.278585],[-87.509387,37.2632]]]},"properties":{"id":"42410","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.800186,38.219216],[-85.782928,38.220108],[-85.767838,38.23354],[-85.786645,38.248488],[-85.800186,38.219216]]]},"properties":{"id":"40210","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.521155,38.230997],[-85.519473,38.255637],[-85.55616,38.248868],[-85.555705,38.226315],[-85.521155,38.230997]]]},"properties":{"id":"40243","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.738281,38.251799],[-85.739637,38.243938],[-85.741144,38.23078],[-85.719902,38.225769],[-85.698169,38.24489],[-85.738281,38.251799]]]},"properties":{"id":"40204","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.829296,38.147067],[-85.78616,38.124446],[-85.748773,38.126285],[-85.747059,38.152888],[-85.750978,38.167404],[-85.759868,38.200317],[-85.765564,38.205152],[-85.768462,38.198538],[-85.797804,38.174591],[-85.827132,38.153228],[-85.829296,38.147067]]]},"properties":{"id":"40214","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.834002,38.268715],[-85.785725,38.253691],[-85.771761,38.263385],[-85.761488,38.272267],[-85.834002,38.268715]]]},"properties":{"id":"40212","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.671221,37.904412],[-84.649174,37.809655],[-84.525643,37.769497],[-84.48512,37.840637],[-84.542775,37.966154],[-84.592335,37.986428],[-84.646601,38.000211],[-84.640477,37.948447],[-84.64037,37.943271],[-84.654644,37.936998],[-84.671221,37.904412]]]},"properties":{"id":"40356","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.915267,37.796522],[-82.836185,37.821866],[-82.829782,37.844078],[-82.83408,37.862083],[-82.857517,37.870896],[-82.902153,37.853086],[-82.915267,37.796522]]]},"properties":{"id":"41256","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.713606,37.807853],[-82.744474,37.813605],[-82.757449,37.806711],[-82.772199,37.788402],[-82.714623,37.793542],[-82.713606,37.807853]]]},"properties":{"id":"41234","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.56974,39.083659],[-84.550236,39.082707],[-84.537102,39.09772],[-84.56974,39.083659]]]},"properties":{"id":"41016","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.917596,37.377087],[-82.949445,37.34809],[-82.922182,37.352836],[-82.917596,37.377087]]]},"properties":{"id":"41817","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.069694,36.887141],[-83.972619,36.827521],[-84.043179,36.887167],[-84.069694,36.887141]]]},"properties":{"id":"40771","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.956234,36.645491],[-83.865636,36.714708],[-83.875569,36.733201],[-83.880146,36.733731],[-83.964468,36.709914],[-83.956234,36.645491]]]},"properties":{"id":"40763","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.002899,36.989709],[-83.986277,36.872885],[-83.953191,36.98519],[-83.982651,36.989839],[-84.002899,36.989709]]]},"properties":{"id":"40734","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.859619,36.918862],[-83.881218,36.904743],[-83.884772,36.89223],[-83.876217,36.86034],[-83.864379,36.895657],[-83.854642,36.903486],[-83.859619,36.918862]]]},"properties":{"id":"40949","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.679372,36.924069],[-83.665625,36.956],[-83.69956,36.963233],[-83.707957,36.893485],[-83.679372,36.924069]]]},"properties":{"id":"40982","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.410998,37.068609],[-83.399815,37.052878],[-83.396919,37.057068],[-83.370063,37.048639],[-83.295373,37.04608],[-83.314,37.064164],[-83.314006,37.064234],[-83.377525,37.093368],[-83.410998,37.068609]]]},"properties":{"id":"40844","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.709069,37.17963],[-82.657835,37.19755],[-82.693624,37.197025],[-82.709069,37.17963]]]},"properties":{"id":"41810","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.093318,38.573772],[-83.04335,38.662636],[-83.106381,38.675356],[-83.154524,38.620761],[-83.093318,38.573772]]]},"properties":{"id":"41166","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.545732,38.482899],[-83.624679,38.611553],[-83.641852,38.525381],[-83.621339,38.504535],[-83.545732,38.482899]]]},"properties":{"id":"41189","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.761699,37.460268],[-84.756774,37.454185],[-84.749339,37.462683],[-84.761699,37.460268]]]},"properties":{"id":"40448","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.494687,37.322164],[-84.468778,37.284088],[-84.436729,37.271858],[-84.369661,37.461115],[-84.369394,37.46212],[-84.494687,37.322164]]]},"properties":{"id":"40409","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.46173,36.681742],[-84.403503,36.718096],[-84.582717,36.826749],[-84.778454,36.60329],[-84.605192,36.598777],[-84.486969,36.674208],[-84.46173,36.681742]]]},"properties":{"id":"42647","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.181826,37.495633],[-87.354556,37.528374],[-87.354286,37.510786],[-87.355144,37.446308],[-87.35286,37.441821],[-87.30548,37.460103],[-87.181826,37.495633]]]},"properties":{"id":"42371","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.41174,37.586591],[-85.456325,37.591824],[-85.490835,37.514071],[-85.380821,37.522483],[-85.375316,37.574171],[-85.41174,37.586591]]]},"properties":{"id":"40060","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.456325,37.591824],[-85.41174,37.586591],[-85.45906,37.606311],[-85.456325,37.591824]]]},"properties":{"id":"40062","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.768783,37.918371],[-83.63106,37.825372],[-83.612368,37.834032],[-83.609024,37.836586],[-83.548388,37.913913],[-83.526858,37.969182],[-83.493803,38.034236],[-83.63442,38.026841],[-83.6347,38.026865],[-83.719823,37.992663],[-83.774374,37.949865],[-83.768783,37.918371]]]},"properties":{"id":"40322","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.053279,37.812366],[-85.053597,37.705401],[-85.028169,37.682929],[-84.922579,37.689628],[-84.744888,37.713075],[-84.716925,37.815389],[-84.709918,37.861952],[-84.765204,37.847835],[-85.030528,37.891538],[-85.059587,37.890433],[-85.053279,37.812366]],[[-84.756469,37.751079],[-84.775514,37.745685],[-84.773422,37.756814],[-84.756469,37.751079]]]},"properties":{"id":"40330","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.652667,36.875361],[-85.642554,36.865333],[-85.633089,36.866842],[-85.652667,36.875361]]]},"properties":{"id":"42124","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.878143,36.780059],[-85.861729,36.756615],[-85.757609,36.797861],[-85.788294,36.828705],[-85.878143,36.780059]]]},"properties":{"id":"42157","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.373522,37.858563],[-83.299653,37.759258],[-83.294227,37.82071],[-83.349663,37.86662],[-83.373522,37.858563]]]},"properties":{"id":"41352","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.373522,37.858563],[-83.484258,37.84391],[-83.262172,37.712657],[-83.252144,37.751658],[-83.299653,37.759258],[-83.373522,37.858563]]]},"properties":{"id":"41332","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.151872,37.235845],[-87.171472,37.235215],[-87.164603,37.24806],[-87.250434,37.280568],[-87.328785,37.2037],[-87.281888,37.088676],[-87.259691,37.070467],[-87.261843,37.057462],[-87.146207,37.043801],[-87.061021,37.06681],[-87.052079,37.158309],[-87.081051,37.173687],[-87.107355,37.209615],[-87.151872,37.235845]]]},"properties":{"id":"42345","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.568291,37.863312],[-85.550987,37.740094],[-85.475202,37.690258],[-85.401784,37.730658],[-85.313237,37.809879],[-85.397184,37.883035],[-85.568291,37.863312]],[[-85.473324,37.848171],[-85.468172,37.848149],[-85.459709,37.845801],[-85.473741,37.844946],[-85.473324,37.848171]]]},"properties":{"id":"40004","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.522244,38.390163],[-85.538885,38.382959],[-85.537461,38.331989],[-85.526966,38.31795],[-85.507682,38.307093],[-85.4839,38.317134],[-85.468206,38.285309],[-85.385872,38.30801],[-85.330137,38.308672],[-85.304761,38.332921],[-85.323544,38.384627],[-85.436642,38.37174],[-85.457633,38.36375],[-85.464045,38.378439],[-85.522244,38.390163]]]},"properties":{"id":"40014","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.132702,37.215579],[-83.146322,37.210247],[-83.134533,37.20356],[-83.132702,37.215579]]]},"properties":{"id":"41751","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.400868,37.338235],[-83.468573,37.374498],[-83.402746,37.332353],[-83.400868,37.338235]]]},"properties":{"id":"41745","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.242252,37.268022],[-83.23988,37.264933],[-83.235943,37.264404],[-83.230558,37.266673],[-83.226421,37.264421],[-83.223301,37.264288],[-83.218921,37.266728],[-83.214855,37.263159],[-83.211343,37.262825],[-83.214035,37.267066],[-83.217018,37.266503],[-83.220012,37.26831],[-83.222371,37.264958],[-83.225072,37.264614],[-83.230008,37.267299],[-83.237499,37.26449],[-83.241891,37.268241],[-83.242252,37.268022]]]},"properties":{"id":"41729","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.223439,37.470039],[-82.226788,37.502899],[-82.239882,37.506488],[-82.284028,37.515021],[-82.310061,37.546951],[-82.308231,37.551852],[-82.345734,37.551634],[-82.368582,37.554045],[-82.413995,37.531534],[-82.406432,37.467453],[-82.389985,37.456606],[-82.366035,37.456974],[-82.223439,37.470039]]]},"properties":{"id":"41539","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.694128,37.796024],[-83.689309,37.742996],[-83.67378,37.764953],[-83.694128,37.796024]]]},"properties":{"id":"40376","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.001967,37.837592],[-83.910875,37.766657],[-83.831832,37.764956],[-83.871275,37.906209],[-83.96631,37.930768],[-84.001967,37.837592]]]},"properties":{"id":"40312","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.694128,37.796024],[-83.67378,37.764953],[-83.667245,37.757457],[-83.630105,37.74459],[-83.574799,37.819797],[-83.597801,37.824524],[-83.612368,37.834032],[-83.63106,37.825372],[-83.694128,37.796024]]]},"properties":{"id":"41360","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.606078,36.995285],[-84.416816,36.94692],[-84.358024,36.9594],[-84.314139,37.004624],[-84.29369,37.145742],[-84.606078,36.995285]]]},"properties":{"id":"42501","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.272537,36.822534],[-87.329946,36.676699],[-87.33598,36.641659],[-87.24933,36.641721],[-87.213046,36.786301],[-87.272537,36.822534]]]},"properties":{"id":"42286","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.417608,37.00538],[-86.41827,37.005611],[-86.420163,37.001296],[-86.416596,37.003776],[-86.417608,37.00538]]]},"properties":{"id":"42102","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.10084,37.781201],[-85.053597,37.705401],[-85.053279,37.812366],[-85.10084,37.781201]]]},"properties":{"id":"40040","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.65556,37.691978],[-83.650398,37.689525],[-83.599704,37.661892],[-83.630105,37.74459],[-83.667245,37.757457],[-83.65556,37.691978]]]},"properties":{"id":"41365","state_code":"21"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.720971,30.891893],[-92.819034,30.817892],[-92.77236,30.817559],[-92.720971,30.891893]]]},"properties":{"id":"70638","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.253523,29.685273],[-91.264768,29.709642],[-91.293646,29.658633],[-91.253523,29.685273]]],[[[-91.2123,29.6883],[-91.082602,29.626368],[-91.012432,29.708039],[-91.061156,29.769344],[-91.109462,29.823945],[-91.179313,29.728191],[-91.220814,29.709206],[-91.2123,29.6883]],[[-91.109476,29.66876],[-91.108701,29.671081],[-91.101469,29.670741],[-91.101655,29.668525],[-91.109476,29.66876]]]]},"properties":{"id":"70380","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.712853,30.479032],[-93.700817,30.166226],[-93.519936,30.316476],[-93.526051,30.402402],[-93.577533,30.460925],[-93.712853,30.479032]]]},"properties":{"id":"70661","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.187162,32.148412],[-93.121188,31.97261],[-93.015642,31.943339],[-92.814737,32.146907],[-92.87485,32.21496],[-92.997126,32.236964],[-93.187162,32.148412]]]},"properties":{"id":"71070","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.002742,32.584095],[-92.956566,32.474068],[-92.908424,32.430416],[-92.813665,32.641983],[-92.828049,32.714388],[-92.95346,32.66413],[-93.002742,32.584095]]]},"properties":{"id":"71001","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.445317,32.644006],[-93.464974,32.534554],[-93.446147,32.535523],[-93.445317,32.644006]]]},"properties":{"id":"71067","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.813368,32.511187],[-94.031901,32.518797],[-94.043258,32.516199],[-94.043041,32.50121],[-93.938496,32.443882],[-93.844623,32.456277],[-93.813368,32.511187]]]},"properties":{"id":"71119","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.790423,31.615699],[-91.78891,31.605097],[-91.781553,31.613544],[-91.790423,31.615699]]]},"properties":{"id":"71377","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.169114,30.432646],[-91.149533,30.470352],[-91.19653,30.469365],[-91.205707,30.407991],[-91.183837,30.404574],[-91.178078,30.411394],[-91.176426,30.417259],[-91.171802,30.416465],[-91.169114,30.432646]],[[-91.188343,30.451424],[-91.183597,30.452635],[-91.183464,30.450509],[-91.187016,30.448286],[-91.188343,30.451424]]]},"properties":{"id":"70802","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.290512,32.568514],[-91.153689,32.627963],[-91.161022,32.8052],[-91.290512,32.568514]]]},"properties":{"id":"71286","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.174495,30.761289],[-91.042042,30.809496],[-91.056636,30.889795],[-91.124485,30.904781],[-91.174495,30.761289]]]},"properties":{"id":"70730","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.6475,31.970428],[-91.740581,32.012014],[-91.766112,31.960513],[-91.779711,31.888523],[-91.776973,31.873386],[-91.6475,31.970428]]]},"properties":{"id":"71378","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.894729,32.15155],[-91.862842,31.965659],[-91.766112,31.960513],[-91.740581,32.012014],[-91.489954,32.089188],[-91.492718,32.203349],[-91.636226,32.304994],[-91.771539,32.248052],[-91.894729,32.15155]]]},"properties":{"id":"71295","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.48571,31.499054],[-92.480625,31.453231],[-92.447482,31.429222],[-92.368108,31.445582],[-92.251792,31.449238],[-92.196131,31.477877],[-92.305002,31.587146],[-92.373087,31.702678],[-92.512198,31.701815],[-92.513069,31.548553],[-92.48571,31.499054]]]},"properties":{"id":"71467","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.538639,31.499463],[-92.48571,31.499054],[-92.513069,31.548553],[-92.538639,31.499463]]]},"properties":{"id":"71407","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.753719,29.979233],[-91.732892,29.830023],[-91.580616,29.863042],[-91.544683,29.864249],[-91.571557,29.949098],[-91.639362,29.975937],[-91.753719,29.979233]]]},"properties":{"id":"70544","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.9766,30.137895],[-92.074185,30.099829],[-92.074753,30.095217],[-92.06668,30.035354],[-91.997965,30.017588],[-91.989247,30.006196],[-91.94831,30.058296],[-91.9766,30.137895]]]},"properties":{"id":"70592","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.414924,30.535166],[-91.437477,30.542731],[-91.502136,30.550162],[-91.566666,30.54183],[-91.599668,30.520789],[-91.557819,30.488518],[-91.517106,30.373198],[-91.48886,30.407498],[-91.433597,30.453309],[-91.414924,30.535166]]]},"properties":{"id":"70757","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.407953,30.426289],[-91.48886,30.407498],[-91.517106,30.373198],[-91.420388,30.223622],[-91.326029,30.347071],[-91.407953,30.426289]]]},"properties":{"id":"70740","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.125283,30.313232],[-91.110914,30.256266],[-91.15415,30.213384],[-91.120296,30.193335],[-91.048727,30.241484],[-91.044718,30.251857],[-91.021014,30.321489],[-91.125283,30.313232]]]},"properties":{"id":"70776","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.142305,30.319893],[-91.110914,30.256266],[-91.125283,30.313232],[-91.142305,30.319893]]]},"properties":{"id":"70780","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.220715,30.035567],[-90.279495,30.006797],[-90.228712,30.008469],[-90.220715,30.035567]]]},"properties":{"id":"70065","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.177185,29.998464],[-90.145773,29.997147],[-90.145121,30.02194],[-90.180628,30.023999],[-90.177185,29.998464]]]},"properties":{"id":"70002","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.044141,29.883012],[-90.034127,29.847849],[-90.008056,29.895528],[-90.018206,29.906736],[-90.041871,29.929013],[-90.044141,29.883012]]]},"properties":{"id":"70056","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.134284,29.742677],[-90.115905,29.667259],[-90.081359,29.768474],[-90.134284,29.742677]]]},"properties":{"id":"70067","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.019828,30.21793],[-92.003899,30.207934],[-91.976619,30.2109],[-91.949944,30.280972],[-92.036005,30.250639],[-92.019828,30.21793]]]},"properties":{"id":"70501","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.18782,29.546567],[-90.411266,29.518902],[-90.43767,29.463609],[-90.377943,29.309222],[-90.078239,29.466125],[-90.18782,29.546567]]]},"properties":{"id":"70354","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.589067,29.57767],[-90.648818,29.593451],[-90.59397,29.535135],[-90.58354,29.512674],[-90.489062,29.519344],[-90.454141,29.557682],[-90.589067,29.57767]]]},"properties":{"id":"70343","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.489062,29.519344],[-90.58354,29.512674],[-90.409423,29.192783],[-90.377943,29.309222],[-90.43767,29.463609],[-90.489062,29.519344]]]},"properties":{"id":"70377","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.447906,30.685944],[-90.634603,30.649827],[-90.631226,30.569511],[-90.576398,30.577335],[-90.400375,30.569731],[-90.447906,30.685944]]]},"properties":{"id":"70443","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.814737,32.146907],[-93.015642,31.943339],[-92.987973,31.876294],[-92.929997,31.854091],[-92.800576,32.057843],[-92.791563,32.146597],[-92.814737,32.146907]]]},"properties":{"id":"71031","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.987379,30.053836],[-89.952298,30.022586],[-89.947518,30.079988],[-89.987379,30.053836]]]},"properties":{"id":"70128","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.075562,29.950808],[-90.07598,29.949477],[-90.075325,29.949327],[-90.074931,29.950455],[-90.075562,29.950808]]]},"properties":{"id":"70163","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.08311,32.4992],[-92.11535,32.491424],[-92.164744,32.276697],[-92.034397,32.277068],[-91.937428,32.484355],[-92.08311,32.4992]]]},"properties":{"id":"71202","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.057939,29.836873],[-89.985107,29.56561],[-89.964478,29.67281],[-89.918943,29.868901],[-90.008056,29.895528],[-90.034127,29.847849],[-90.057939,29.836873]]]},"properties":{"id":"70037","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.442817,29.349435],[-89.374285,29.303213],[-89.441772,29.349882],[-89.442817,29.349435]]]},"properties":{"id":"70091","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.604646,29.383655],[-89.585578,29.374618],[-89.579347,29.376068],[-89.596336,29.388916],[-89.604646,29.383655]]]},"properties":{"id":"70050","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.423597,30.604018],[-91.439861,30.543551],[-91.388826,30.569012],[-91.364958,30.568992],[-91.365088,30.58805],[-91.387544,30.60316],[-91.423597,30.604018]]]},"properties":{"id":"70752","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.726451,30.864942],[-91.653498,30.915795],[-91.662506,30.970362],[-91.658321,30.994365],[-91.750119,31.018814],[-91.796521,30.982842],[-91.801676,30.914685],[-91.726451,30.864942]]]},"properties":{"id":"70753","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.606669,31.245228],[-92.734203,31.225702],[-92.727235,31.173174],[-92.721413,31.108878],[-92.613872,31.143734],[-92.613307,31.216298],[-92.606669,31.245228]]]},"properties":{"id":"71424","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.627363,31.098155],[-92.479135,30.952391],[-92.431149,30.97094],[-92.398254,31.047219],[-92.470874,31.101099],[-92.627363,31.098155]]]},"properties":{"id":"71430","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.311145,32.199295],[-93.318537,32.17022],[-93.29298,32.177565],[-93.311145,32.199295]]]},"properties":{"id":"71034","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.58174,32.535094],[-91.596987,32.640898],[-91.637587,32.667341],[-91.913057,32.505902],[-91.937428,32.484355],[-92.034397,32.277068],[-91.955066,32.269371],[-91.760841,32.335355],[-91.634931,32.405371],[-91.58174,32.535094]],[[-91.859882,32.486393],[-91.858372,32.489007],[-91.854672,32.488614],[-91.855025,32.485678],[-91.859882,32.486393]]]},"properties":{"id":"71269","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.67263,31.535884],[-93.599674,31.328135],[-93.468482,31.496124],[-93.460747,31.513195],[-93.45015,31.505316],[-93.332313,31.538031],[-93.423163,31.666533],[-93.48072,31.70613],[-93.494676,31.69431],[-93.67263,31.535884]]]},"properties":{"id":"71449","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.429166,29.997767],[-90.398483,29.986959],[-90.392272,30.004507],[-90.410583,30.018171],[-90.429166,29.997767]]]},"properties":{"id":"70079","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.821001,30.722693],[-90.676878,30.720288],[-90.64168,30.824472],[-90.691575,30.999429],[-90.825829,30.999361],[-90.854461,30.949922],[-90.839207,30.724545],[-90.821001,30.722693]]]},"properties":{"id":"70441","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.66613,30.011322],[-90.647323,30.044275],[-90.812582,29.979702],[-90.79795,29.959203],[-90.706022,29.901038],[-90.656312,29.889246],[-90.66613,30.011322]]]},"properties":{"id":"70090","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.712142,30.077486],[-90.688996,30.040367],[-90.690452,30.075447],[-90.712142,30.077486]]]},"properties":{"id":"70052","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.65137,30.054285],[-90.637184,30.050432],[-90.627311,30.075627],[-90.666191,30.073856],[-90.65137,30.054285]]]},"properties":{"id":"70076","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.040583,30.785589],[-92.172414,30.762461],[-92.193956,30.659333],[-92.021467,30.620125],[-91.874324,30.598304],[-91.970835,30.725686],[-91.98091,30.72912],[-91.977875,30.731789],[-91.970665,30.72992],[-91.944136,30.816262],[-91.980599,30.794892],[-92.040583,30.785589]]]},"properties":{"id":"70589","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.021467,30.620125],[-92.193956,30.659333],[-92.25412,30.517916],[-92.150858,30.422338],[-92.05654,30.423496],[-92.043625,30.436145],[-91.950912,30.504378],[-92.021467,30.620125]],[[-92.182089,30.524598],[-92.182015,30.51841],[-92.192407,30.516216],[-92.187714,30.524801],[-92.182089,30.524598]]]},"properties":{"id":"70570","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.908545,30.091167],[-91.915779,30.072669],[-91.883612,30.063666],[-91.908545,30.091167]]]},"properties":{"id":"70519","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.018996,30.316542],[-90.003141,30.392517],[-90.120857,30.361286],[-90.018996,30.316542]]]},"properties":{"id":"70448","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.737537,30.323276],[-89.707647,30.326578],[-89.850851,30.544672],[-89.874897,30.539717],[-89.864497,30.495104],[-89.799557,30.339754],[-89.762625,30.330431],[-89.737537,30.323276]]]},"properties":{"id":"70452","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.400375,30.569731],[-90.476426,30.545868],[-90.485719,30.545462],[-90.5669,30.52613],[-90.567016,30.50433],[-90.361707,30.506844],[-90.36303,30.535765],[-90.400375,30.569731]],[[-90.471879,30.516843],[-90.468507,30.519194],[-90.468095,30.513404],[-90.471937,30.513285],[-90.471879,30.516843]]]},"properties":{"id":"70401","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.361707,30.506844],[-90.338668,30.480498],[-90.277987,30.505382],[-90.272503,30.535719],[-90.286471,30.541563],[-90.36303,30.535765],[-90.361707,30.506844]]]},"properties":{"id":"70455","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.478668,32.05012],[-91.349159,31.845282],[-91.18031,31.970057],[-91.478668,32.05012]]]},"properties":{"id":"71366","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.926791,29.7016],[-90.967281,29.693675],[-90.952184,29.671501],[-90.926393,29.670814],[-90.926791,29.7016]]]},"properties":{"id":"70352","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.628481,32.900067],[-92.512333,32.820494],[-92.48069,32.934963],[-92.439805,33.011604],[-92.685117,33.014277],[-92.690184,32.999747],[-92.628481,32.900067]]]},"properties":{"id":"71277","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.614377,29.766995],[-92.61772,29.578444],[-92.267211,29.758018],[-92.193906,30.020739],[-92.242518,30.098431],[-92.287393,30.127866],[-92.361497,30.078546],[-92.408349,30.053721],[-92.614935,29.919262],[-92.614143,29.774096],[-92.614377,29.766995]]]},"properties":{"id":"70548","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.159409,29.948883],[-92.157092,29.947301],[-92.156103,29.949617],[-92.158783,29.950453],[-92.159409,29.948883]]]},"properties":{"id":"70575","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.45548,32.968268],[-93.44585,32.960648],[-93.437281,32.965769],[-93.45548,32.968268]]]},"properties":{"id":"71021","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.538661,30.999388],[-91.625027,30.999166],[-91.646016,30.944346],[-91.568055,30.940025],[-91.538661,30.999388]]]},"properties":{"id":"70712","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.735895,30.177169],[-92.627799,30.092128],[-92.625484,30.09233],[-92.591128,30.189338],[-92.573618,30.216095],[-92.56331,30.236133],[-92.559461,30.27616],[-92.625161,30.353776],[-92.60406,30.400042],[-92.775047,30.378962],[-92.757675,30.320454],[-92.705648,30.211447],[-92.739163,30.187122],[-92.735895,30.177169]]]},"properties":{"id":"70546","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.598299,30.65924],[-92.77236,30.817559],[-92.819034,30.817892],[-92.836549,30.653171],[-92.868287,30.621639],[-92.721252,30.557736],[-92.619507,30.524975],[-92.585229,30.559228],[-92.598299,30.65924]]]},"properties":{"id":"70655","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.976699,30.825259],[-92.976886,30.77404],[-92.918073,30.757749],[-92.976699,30.825259]]]},"properties":{"id":"70644","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.977478,30.254609],[-90.87077,30.284479],[-90.795737,30.282983],[-90.829065,30.317461],[-90.891728,30.345244],[-90.997993,30.34116],[-91.006339,30.337795],[-91.021014,30.321489],[-91.044718,30.251857],[-90.977478,30.254609]]]},"properties":{"id":"70769","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.131805,31.094301],[-92.10784,31.019466],[-92.091907,31.019475],[-92.015053,31.049632],[-91.99278,31.070446],[-92.131805,31.094301]]]},"properties":{"id":"71350","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.018601,30.909263],[-92.104555,30.961054],[-92.057208,30.84968],[-92.002451,30.874255],[-92.018601,30.909263]]]},"properties":{"id":"71333","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.978234,30.861198],[-93.17118,30.951108],[-93.268382,30.926791],[-93.268165,30.911984],[-93.297006,30.911939],[-93.299602,30.926388],[-93.432924,30.981493],[-93.514061,30.87319],[-93.452497,30.707468],[-93.334584,30.675125],[-93.173317,30.616785],[-93.046026,30.657323],[-92.977324,30.724144],[-92.978234,30.861198]]]},"properties":{"id":"70634","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.997126,32.236964],[-92.87485,32.21496],[-92.867056,32.285102],[-92.894317,32.360629],[-92.908424,32.430416],[-92.956566,32.474068],[-93.091654,32.36877],[-93.090918,32.319423],[-92.997126,32.236964]]]},"properties":{"id":"71008","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.28187,32.505648],[-93.236693,32.411506],[-93.156939,32.387754],[-93.173917,32.565232],[-93.249578,32.559056],[-93.28187,32.505648]]]},"properties":{"id":"71024","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.783571,32.786091],[-93.524183,32.821721],[-93.555544,32.906817],[-93.520095,32.957528],[-93.599575,33.019047],[-93.814553,33.019371],[-93.820672,32.892744],[-93.805075,32.805021],[-93.783571,32.786091]]]},"properties":{"id":"71064","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.376582,32.882123],[-93.555544,32.906817],[-93.524183,32.821721],[-93.455263,32.761131],[-93.336852,32.829324],[-93.376582,32.882123]]]},"properties":{"id":"71018","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.755724,32.46922],[-93.735965,32.467053],[-93.702576,32.495252],[-93.707662,32.500267],[-93.755343,32.496951],[-93.75563,32.478915],[-93.755724,32.46922]]]},"properties":{"id":"71104","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.870985,32.815206],[-93.805075,32.805021],[-93.820672,32.892744],[-93.837433,32.894616],[-93.869468,32.817777],[-93.870985,32.815206]]]},"properties":{"id":"71029","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.300963,30.051953],[-93.282246,29.915776],[-93.166058,29.942821],[-93.128674,30.103659],[-93.128834,30.176654],[-93.180124,30.197558],[-93.214031,30.19717],[-93.300963,30.051953]]]},"properties":{"id":"70607","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.262993,31.968667],[-92.005658,31.890655],[-92.005618,31.897191],[-92.005434,31.927296],[-92.262993,31.968667]]]},"properties":{"id":"71441","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282246,29.915776],[-93.530545,30.051755],[-93.602369,30.052057],[-93.722517,30.050872],[-93.228625,29.776856],[-93.079525,29.933756],[-93.166058,29.942821],[-93.282246,29.915776]]]},"properties":{"id":"70631","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.735895,30.177169],[-92.907195,30.129934],[-92.909418,30.120666],[-92.887605,30.082475],[-92.949854,30.08223],[-92.802853,29.976214],[-92.801791,29.976537],[-92.627799,30.092128],[-92.735895,30.177169]]]},"properties":{"id":"70549","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.771899,32.931805],[-92.746541,32.99634],[-92.76736,32.99886],[-92.849031,32.986876],[-92.897199,32.881828],[-92.832639,32.860419],[-92.771899,32.931805]]]},"properties":{"id":"71079","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.757901,32.809139],[-92.548635,32.720659],[-92.512333,32.820494],[-92.628481,32.900067],[-92.771899,32.931805],[-92.832639,32.860419],[-92.774036,32.834375],[-92.757901,32.809139]]]},"properties":{"id":"71222","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.839207,30.724545],[-90.854461,30.949922],[-91.023406,30.953533],[-91.056636,30.889795],[-91.042042,30.809496],[-90.950306,30.715883],[-90.891,30.673185],[-90.839207,30.724545]]]},"properties":{"id":"70722","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.121293,30.364489],[-91.183837,30.404574],[-91.205707,30.407991],[-91.220718,30.388326],[-91.235862,30.371692],[-91.151521,30.337809],[-91.121293,30.364489]]]},"properties":{"id":"70820","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.13011,30.433186],[-91.1081,30.397413],[-91.006339,30.337795],[-90.997993,30.34116],[-91.047314,30.391188],[-91.080784,30.431644],[-91.086387,30.430944],[-91.13011,30.433186]],[[-91.091514,30.388848],[-91.092395,30.389279],[-91.090198,30.392493],[-91.089544,30.391599],[-91.091514,30.388848]]]},"properties":{"id":"70809","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.10887,30.47128],[-91.032815,30.474452],[-91.032408,30.496341],[-91.048591,30.505854],[-91.056227,30.501569],[-91.088354,30.498894],[-91.113693,30.480672],[-91.109044,30.471275],[-91.10887,30.47128]]]},"properties":{"id":"70814","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.188343,30.451424],[-91.187016,30.448286],[-91.183464,30.450509],[-91.183597,30.452635],[-91.188343,30.451424]]]},"properties":{"id":"70801","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.093657,30.530899],[-91.056227,30.501569],[-91.048591,30.505854],[-91.021686,30.572012],[-91.034789,30.595581],[-91.050888,30.576118],[-91.093657,30.530899]]]},"properties":{"id":"70818","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.997993,30.34116],[-90.891728,30.345244],[-90.969401,30.405138],[-91.047314,30.391188],[-90.997993,30.34116]]]},"properties":{"id":"70817","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.091514,30.388848],[-91.089544,30.391599],[-91.090198,30.392493],[-91.092395,30.389279],[-91.091514,30.388848]]]},"properties":{"id":"70836","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.825829,30.999361],[-91.17614,30.99908],[-91.18797,30.935608],[-91.159856,30.947794],[-91.023406,30.953533],[-90.854461,30.949922],[-90.825829,30.999361]]]},"properties":{"id":"70761","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.280738,30.965071],[-92.357379,30.94102],[-92.211343,30.793131],[-92.280738,30.965071]]]},"properties":{"id":"71367","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.307154,30.544859],[-92.330305,30.566393],[-92.333482,30.546347],[-92.307154,30.544859]]]},"properties":{"id":"70524","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.425082,30.7868],[-92.427192,30.770025],[-92.415445,30.771545],[-92.415449,30.78084],[-92.425082,30.7868]]]},"properties":{"id":"70576","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.766112,31.960513],[-91.862842,31.965659],[-91.779711,31.888523],[-91.766112,31.960513]]]},"properties":{"id":"71243","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.798309,31.59497],[-92.681666,31.396009],[-92.631944,31.390478],[-92.525961,31.412838],[-92.538639,31.499463],[-92.663163,31.633772],[-92.758038,31.655035],[-92.798309,31.59497]]]},"properties":{"id":"71417","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.9766,30.137895],[-91.94831,30.058296],[-91.915779,30.072669],[-91.908545,30.091167],[-91.910617,30.1893],[-91.978562,30.181105],[-91.9766,30.137895]]]},"properties":{"id":"70518","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.288852,30.349014],[-91.325586,30.346857],[-91.326029,30.347071],[-91.420388,30.223622],[-91.224356,30.025336],[-91.221849,30.026129],[-91.15415,30.213384],[-91.110914,30.256266],[-91.142305,30.319893],[-91.142105,30.323293],[-91.222302,30.323293],[-91.288852,30.349014]]]},"properties":{"id":"70764","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.381049,32.542102],[-92.457979,32.670266],[-92.518374,32.675389],[-92.537393,32.641793],[-92.512139,32.395919],[-92.415378,32.457311],[-92.381049,32.542102]]]},"properties":{"id":"71227","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.059921,29.219289],[-90.088723,29.162622],[-90.049583,29.186167],[-90.059921,29.219289]]]},"properties":{"id":"70358","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.205909,29.973478],[-90.169303,29.94419],[-90.140074,29.947904],[-90.1277,29.968282],[-90.203636,29.973321],[-90.205909,29.973478]]]},"properties":{"id":"70121","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.279533,29.967971],[-90.240501,29.965255],[-90.237625,29.975555],[-90.228712,30.008469],[-90.279495,30.006797],[-90.280057,29.991706],[-90.280296,29.972618],[-90.279533,29.967971]]]},"properties":{"id":"70062","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.074185,30.099829],[-92.079607,30.103834],[-92.074753,30.095217],[-92.074185,30.099829]]]},"properties":{"id":"70558","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.504815,29.766042],[-90.523923,29.698388],[-90.528173,29.681192],[-90.441512,29.621235],[-90.315965,29.692448],[-90.349423,29.69539],[-90.397295,29.770378],[-90.504815,29.766042]]]},"properties":{"id":"70355","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.782853,29.730329],[-90.797437,29.67939],[-90.773379,29.667647],[-90.73966,29.683569],[-90.782853,29.730329]]]},"properties":{"id":"70359","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.821001,30.722693],[-90.835444,30.649625],[-90.756598,30.649963],[-90.727996,30.710532],[-90.676878,30.720288],[-90.821001,30.722693]]]},"properties":{"id":"70453","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.573425,30.44929],[-90.64006,30.40938],[-90.677291,30.320916],[-90.560339,30.29676],[-90.473872,30.343393],[-90.540159,30.436544],[-90.573425,30.44929]]]},"properties":{"id":"70462","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.400375,30.569731],[-90.576398,30.577335],[-90.5669,30.52613],[-90.485719,30.545462],[-90.479884,30.552687],[-90.476426,30.545868],[-90.400375,30.569731]]]},"properties":{"id":"70466","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.015642,31.943339],[-93.121188,31.97261],[-93.239499,31.903605],[-93.040551,31.829863],[-93.021879,31.831307],[-92.987973,31.876294],[-93.015642,31.943339]]]},"properties":{"id":"71411","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.107523,29.9679],[-90.112045,29.942749],[-90.093841,29.941569],[-90.089313,29.954342],[-90.107523,29.9679]]]},"properties":{"id":"70125","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.08311,32.4992],[-92.129299,32.555996],[-92.117099,32.495845],[-92.11535,32.491424],[-92.08311,32.4992]]]},"properties":{"id":"71201","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.633246,29.454144],[-89.63536,29.446908],[-89.604646,29.383655],[-89.596336,29.388916],[-89.633246,29.454144]]],[[[-89.441772,29.349882],[-89.406269,29.338885],[-89.579347,29.376068],[-89.585578,29.374618],[-89.474998,29.326822],[-89.442817,29.349435],[-89.441772,29.349882]]]]},"properties":{"id":"70041","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.6536,30.594096],[-91.592928,30.570594],[-91.55477,30.632866],[-91.660449,30.686598],[-91.6536,30.594096]]]},"properties":{"id":"70732","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.459012,31.181259],[-92.470874,31.101099],[-92.398254,31.047219],[-92.251532,31.091273],[-92.459012,31.181259]]]},"properties":{"id":"71346","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.760841,32.335355],[-91.955066,32.269371],[-91.894729,32.15155],[-91.771539,32.248052],[-91.760841,32.335355]]]},"properties":{"id":"71259","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.519142,30.052518],[-90.546188,30.101205],[-90.614212,30.095884],[-90.595031,30.050891],[-90.519142,30.052518]]]},"properties":{"id":"70084","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.647323,30.044275],[-90.66613,30.011322],[-90.538391,30.013401],[-90.47966,30.028673],[-90.647323,30.044275]]]},"properties":{"id":"70049","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.813302,30.615371],[-91.943846,30.81634],[-91.944136,30.816262],[-91.970665,30.72992],[-91.970835,30.725686],[-91.874324,30.598304],[-91.869138,30.587938],[-91.813302,30.615371]]]},"properties":{"id":"71358","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.021467,30.620125],[-91.950912,30.504378],[-91.859674,30.451235],[-91.869138,30.587938],[-91.874324,30.598304],[-92.021467,30.620125]]]},"properties":{"id":"70577","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.182089,30.524598],[-92.187714,30.524801],[-92.192407,30.516216],[-92.182015,30.51841],[-92.182089,30.524598]]]},"properties":{"id":"70550","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.038705,30.412321],[-92.043625,30.436145],[-92.05654,30.423496],[-92.038705,30.412321]]]},"properties":{"id":"70541","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.253523,29.685273],[-91.2123,29.6883],[-91.220814,29.709206],[-91.224441,29.718473],[-91.264768,29.709642],[-91.253523,29.685273]]]},"properties":{"id":"70342","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.976545,30.553168],[-89.942441,30.561429],[-89.874897,30.539717],[-89.850851,30.544672],[-89.869069,30.62426],[-90.044742,30.685475],[-90.079913,30.68065],[-89.976545,30.553168]]]},"properties":{"id":"70431","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.874897,30.539717],[-89.942441,30.561429],[-89.879834,30.499453],[-89.864497,30.495104],[-89.874897,30.539717]]]},"properties":{"id":"70464","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.797437,29.67939],[-90.848355,29.631125],[-90.919192,29.557855],[-90.796528,29.53538],[-90.710191,29.598721],[-90.773379,29.667647],[-90.797437,29.67939]]]},"properties":{"id":"70360","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.268382,30.926791],[-93.299602,30.926388],[-93.297006,30.911939],[-93.268165,30.911984],[-93.268382,30.926791]]]},"properties":{"id":"70659","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.288852,30.349014],[-91.243468,30.371332],[-91.235862,30.371692],[-91.220718,30.388326],[-91.325586,30.346857],[-91.288852,30.349014]]]},"properties":{"id":"70719","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.317611,32.995897],[-91.317986,32.984417],[-91.29835,32.984489],[-91.304167,32.997022],[-91.317611,32.995897]]]},"properties":{"id":"71253","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.559461,30.27616],[-92.56331,30.236133],[-92.530994,30.270526],[-92.559461,30.27616]]]},"properties":{"id":"70537","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.361497,30.078546],[-92.447291,30.139555],[-92.463422,30.197939],[-92.522296,30.193405],[-92.591128,30.189338],[-92.625484,30.09233],[-92.408349,30.053721],[-92.361497,30.078546]]]},"properties":{"id":"70559","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.591128,30.189338],[-92.522296,30.193405],[-92.573618,30.216095],[-92.591128,30.189338]]]},"properties":{"id":"70556","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.004796,30.598687],[-93.014279,30.437858],[-92.974421,30.471715],[-93.004796,30.598687]]]},"properties":{"id":"70658","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.782121,30.274843],[-90.795737,30.282983],[-90.87077,30.284479],[-90.869709,30.208108],[-90.785217,30.138422],[-90.728278,30.149511],[-90.708229,30.15342],[-90.632811,30.221409],[-90.782121,30.274843]]]},"properties":{"id":"70774","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.008121,31.325797],[-92.1903,31.25128],[-92.192468,31.217197],[-92.143135,31.200422],[-91.994828,31.229561],[-92.008121,31.325797]]]},"properties":{"id":"71331","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.712853,30.479032],[-93.577533,30.460925],[-93.452497,30.707468],[-93.514061,30.87319],[-93.558596,30.868822],[-93.712853,30.479032]]]},"properties":{"id":"70653","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.173317,30.616785],[-93.334584,30.675125],[-93.353643,30.534348],[-93.277482,30.50426],[-93.173317,30.616785]]]},"properties":{"id":"70652","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.577533,30.460925],[-93.526051,30.402402],[-93.353643,30.534348],[-93.334584,30.675125],[-93.452497,30.707468],[-93.577533,30.460925]]]},"properties":{"id":"70660","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.791563,32.146597],[-92.522524,32.146495],[-92.481588,32.146521],[-92.572245,32.312809],[-92.694074,32.278337],[-92.721086,32.259382],[-92.740038,32.279944],[-92.867056,32.285102],[-92.87485,32.21496],[-92.814737,32.146907],[-92.791563,32.146597]]]},"properties":{"id":"71251","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.524183,32.821721],[-93.783571,32.786091],[-93.826,32.71793],[-93.779278,32.672796],[-93.627598,32.585163],[-93.455263,32.761131],[-93.524183,32.821721]]]},"properties":{"id":"71006","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.9746,33.019475],[-93.924583,32.916595],[-93.837433,32.894616],[-93.820672,32.892744],[-93.814553,33.019371],[-93.9746,33.019475]]]},"properties":{"id":"71044","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.273954,30.294487],[-93.207486,30.26185],[-93.195834,30.266184],[-93.111908,30.306108],[-93.13112,30.397699],[-93.232872,30.403152],[-93.283072,30.403058],[-93.273954,30.294487]]]},"properties":{"id":"70611","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.214031,30.19717],[-93.180124,30.197558],[-93.195834,30.266184],[-93.207486,30.26185],[-93.277053,30.197927],[-93.214031,30.19717]]]},"properties":{"id":"70601","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.713124,31.679677],[-91.466306,31.75097],[-91.575721,31.88256],[-91.597117,31.884713],[-91.694521,31.765733],[-91.713124,31.679677]]]},"properties":{"id":"71326","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.920976,32.08314],[-93.893121,31.961556],[-93.614227,32.171829],[-93.750516,32.168356],[-93.853696,32.158602],[-93.920976,32.08314]]]},"properties":{"id":"71032","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.980931,30.535555],[-90.891,30.673185],[-90.950306,30.715883],[-90.959354,30.715582],[-90.954265,30.685374],[-90.959712,30.670452],[-91.021686,30.572012],[-91.048591,30.505854],[-91.032408,30.496341],[-90.974807,30.481763],[-90.980931,30.535555]]]},"properties":{"id":"70739","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.157599,30.558874],[-91.093657,30.530899],[-91.050888,30.576118],[-91.211987,30.580502],[-91.157599,30.558874]]]},"properties":{"id":"70714","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.023406,30.953533],[-91.159856,30.947794],[-91.124485,30.904781],[-91.056636,30.889795],[-91.023406,30.953533]]]},"properties":{"id":"70789","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.540022,30.567502],[-92.343633,30.577145],[-92.446953,30.71481],[-92.598299,30.65924],[-92.585229,30.559228],[-92.540022,30.567502]],[[-92.432407,30.673455],[-92.432365,30.678037],[-92.426386,30.678004],[-92.425132,30.673944],[-92.432407,30.673455]]]},"properties":{"id":"70554","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.94831,30.058296],[-91.989247,30.006196],[-91.983341,29.95759],[-91.972233,29.941167],[-91.982594,29.912459],[-91.972585,29.837032],[-91.856463,29.737749],[-91.732892,29.830023],[-91.753719,29.979233],[-91.815989,30.047686],[-91.883612,30.063666],[-91.915779,30.072669],[-91.94831,30.058296]],[[-91.91566,29.909877],[-91.8874,29.906263],[-91.892667,29.894256],[-91.914447,29.896737],[-91.91566,29.909877]]]},"properties":{"id":"70560","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.15415,30.213384],[-91.221849,30.026129],[-91.16024,30.045548],[-91.105951,30.062478],[-91.059273,30.213113],[-91.120296,30.193335],[-91.15415,30.213384]]]},"properties":{"id":"70788","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.048727,30.241484],[-91.120296,30.193335],[-91.059273,30.213113],[-91.048727,30.241484]]]},"properties":{"id":"70721","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.202751,30.029827],[-90.193069,29.9995],[-90.177185,29.998464],[-90.180628,30.023999],[-90.202751,30.029827]]]},"properties":{"id":"70006","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.085573,29.914405],[-90.084205,29.833869],[-90.057939,29.836873],[-90.034127,29.847849],[-90.044141,29.883012],[-90.071672,29.919304],[-90.072572,29.919004],[-90.085573,29.914405]]]},"properties":{"id":"70058","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.120185,30.127657],[-92.168694,30.130321],[-92.242518,30.098431],[-92.193906,30.020739],[-92.06668,30.035354],[-92.074753,30.095217],[-92.079607,30.103834],[-92.086723,30.126102],[-92.110546,30.130759],[-92.120185,30.127657]]]},"properties":{"id":"70555","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.972576,30.293312],[-92.101837,30.29085],[-92.049505,30.247104],[-92.036005,30.250639],[-91.949944,30.280972],[-91.972576,30.293312]]]},"properties":{"id":"70507","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.773379,29.667647],[-90.710191,29.598721],[-90.648818,29.593451],[-90.589067,29.57767],[-90.578176,29.58906],[-90.703859,29.687087],[-90.73966,29.683569],[-90.773379,29.667647]]]},"properties":{"id":"70364","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.021879,31.831307],[-93.032571,31.823253],[-93.040551,31.829863],[-93.239499,31.903605],[-93.279528,31.887074],[-93.041844,31.596787],[-93.05655,31.509515],[-93.03525,31.507382],[-92.995204,31.573678],[-92.906231,31.656992],[-92.90241,31.656836],[-92.924441,31.784644],[-92.929997,31.854091],[-92.987973,31.876294],[-93.021879,31.831307]]]},"properties":{"id":"71457","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.112045,29.942749],[-90.121963,29.909899],[-90.085573,29.914405],[-90.072572,29.919004],[-90.08663,29.931845],[-90.093841,29.941569],[-90.112045,29.942749]]]},"properties":{"id":"70115","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.06926,32.52966],[-92.071535,32.529519],[-92.069733,32.527835],[-92.06926,32.52966]]]},"properties":{"id":"71209","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.808996,29.596464],[-89.927178,29.658272],[-89.895879,29.756853],[-90.018218,29.759908],[-89.953563,29.652681],[-89.800413,29.579859],[-89.808996,29.596464]]]},"properties":{"id":"70040","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.353092,30.72177],[-91.471168,30.625188],[-91.342317,30.677331],[-91.353092,30.72177]]]},"properties":{"id":"70783","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.355557,30.626778],[-91.34212,30.61013],[-91.316954,30.637529],[-91.355557,30.626778]]]},"properties":{"id":"70736","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.413131,29.860572],[-90.407478,29.906995],[-90.418235,29.92613],[-90.459991,29.894197],[-90.462364,29.873619],[-90.413131,29.860572]]]},"properties":{"id":"70080","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.410583,30.018171],[-90.546188,30.101205],[-90.519142,30.052518],[-90.429166,29.997767],[-90.410583,30.018171]]]},"properties":{"id":"70068","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.779739,30.055852],[-90.850753,30.109632],[-90.825965,29.994468],[-90.787505,30.012483],[-90.784584,30.044006],[-90.779739,30.055852]]]},"properties":{"id":"70723","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.003141,30.392517],[-90.018996,30.316542],[-89.952255,30.25572],[-89.799557,30.339754],[-89.864497,30.495104],[-89.879834,30.499453],[-89.975472,30.421582],[-90.003141,30.392517]]]},"properties":{"id":"70445","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.286471,30.541563],[-90.252114,30.693431],[-90.298597,30.678384],[-90.337593,30.664563],[-90.35304,30.693412],[-90.447906,30.685944],[-90.400375,30.569731],[-90.36303,30.535765],[-90.286471,30.541563]]]},"properties":{"id":"70446","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.510245,30.818831],[-90.53292,30.824794],[-90.510696,30.818758],[-90.510245,30.818831]]]},"properties":{"id":"70465","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.298597,30.678384],[-90.35304,30.693412],[-90.337593,30.664563],[-90.298597,30.678384]]]},"properties":{"id":"70442","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.927177,29.715915],[-90.926791,29.7016],[-90.926393,29.670814],[-90.848355,29.631125],[-90.797437,29.67939],[-90.782853,29.730329],[-90.927177,29.715915]]]},"properties":{"id":"70395","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.48069,32.934963],[-92.19273,32.771518],[-92.061854,32.765637],[-92.069105,33.008163],[-92.439805,33.011604],[-92.48069,32.934963]]]},"properties":{"id":"71260","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.432924,30.981493],[-93.509215,31.032982],[-93.558596,30.868822],[-93.514061,30.87319],[-93.432924,30.981493]]]},"properties":{"id":"70639","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.433597,30.453309],[-91.407953,30.426289],[-91.326029,30.347071],[-91.325586,30.346857],[-91.220718,30.388326],[-91.205707,30.407991],[-91.19653,30.469365],[-91.197205,30.506864],[-91.311143,30.575237],[-91.364958,30.568992],[-91.388826,30.569012],[-91.414924,30.535166],[-91.433597,30.453309]]]},"properties":{"id":"70767","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.235173,30.321358],[-92.347151,30.409634],[-92.363929,30.392585],[-92.35961,30.341661],[-92.235173,30.321358]]]},"properties":{"id":"70516","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.474508,30.259991],[-92.408419,30.235591],[-92.447291,30.139555],[-92.361497,30.078546],[-92.287393,30.127866],[-92.35961,30.341661],[-92.363929,30.392585],[-92.408857,30.363447],[-92.474508,30.259991]]]},"properties":{"id":"70526","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.168694,30.130321],[-92.120185,30.127657],[-92.112945,30.211919],[-92.193096,30.247829],[-92.168694,30.130321]]]},"properties":{"id":"70529","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.014279,30.437858],[-93.004796,30.598687],[-92.978718,30.626906],[-93.046026,30.657323],[-93.173317,30.616785],[-93.277482,30.50426],[-93.232872,30.403152],[-93.13112,30.397699],[-93.037136,30.418182],[-93.014279,30.437858]]]},"properties":{"id":"70657","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.721252,30.557736],[-92.797526,30.437612],[-92.775047,30.378962],[-92.60406,30.400042],[-92.619507,30.524975],[-92.721252,30.557736]]]},"properties":{"id":"70532","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.87077,30.284479],[-90.977478,30.254609],[-90.954766,30.157727],[-90.918861,30.149789],[-90.869709,30.208108],[-90.87077,30.284479]]]},"properties":{"id":"70737","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.05404,29.993308],[-91.064388,30.001822],[-91.065582,29.994626],[-91.05404,29.993308]]]},"properties":{"id":"70391","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.104555,30.961054],[-92.018601,30.909263],[-91.966643,31.000144],[-92.015053,31.049632],[-92.091907,31.019475],[-92.104555,30.961054]]]},"properties":{"id":"71327","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.915766,30.952772],[-91.948327,30.848841],[-91.815258,30.848107],[-91.801676,30.914685],[-91.796521,30.982842],[-91.920076,31.019049],[-91.915766,30.952772]]]},"properties":{"id":"71369","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.231304,31.108351],[-92.251532,31.091273],[-92.228138,31.083146],[-92.231304,31.108351]]],[[[-92.23152,31.110085],[-92.131805,31.094301],[-91.99278,31.070446],[-91.709336,31.153846],[-91.680219,31.190222],[-91.813067,31.248469],[-91.83428,31.266344],[-91.994828,31.229561],[-92.143135,31.200422],[-92.232265,31.116243],[-92.23152,31.110085]]]]},"properties":{"id":"71351","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.131805,31.094301],[-92.23152,31.110085],[-92.231304,31.108351],[-92.228138,31.083146],[-92.227124,31.074759],[-92.10784,31.019466],[-92.131805,31.094301]]]},"properties":{"id":"71341","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.23273,31.255678],[-92.1903,31.25128],[-92.008121,31.325797],[-92.160512,31.466192],[-92.196131,31.477877],[-92.251792,31.449238],[-92.23273,31.255678]]]},"properties":{"id":"71328","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.353643,30.534348],[-93.526051,30.402402],[-93.519936,30.316476],[-93.501333,30.314071],[-93.283072,30.403058],[-93.232872,30.403152],[-93.277482,30.50426],[-93.353643,30.534348]]]},"properties":{"id":"70633","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.894317,32.360629],[-92.867056,32.285102],[-92.740038,32.279944],[-92.694074,32.278337],[-92.572245,32.312809],[-92.570505,32.384363],[-92.805807,32.454011],[-92.894317,32.360629]]]},"properties":{"id":"71268","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.42819,32.235897],[-93.311145,32.199295],[-93.29298,32.177565],[-93.212905,32.199281],[-93.203942,32.283805],[-93.251714,32.365459],[-93.364369,32.410095],[-93.372781,32.410153],[-93.42819,32.235897]]]},"properties":{"id":"71068","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043127,32.937721],[-94.043155,32.697436],[-93.972585,32.705697],[-93.960739,32.807958],[-93.872953,32.804249],[-93.870985,32.815206],[-93.869468,32.817777],[-93.924431,32.899079],[-94.043127,32.937721]]]},"properties":{"id":"71082","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.778024,32.361665],[-93.844688,32.391928],[-94.024144,32.313876],[-94.042832,32.312464],[-94.042648,32.224995],[-93.860327,32.253673],[-93.763631,32.344738],[-93.778024,32.361665]]]},"properties":{"id":"71047","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.844623,32.456277],[-93.830537,32.419244],[-93.830349,32.419425],[-93.780102,32.470954],[-93.79838,32.512831],[-93.813368,32.511187],[-93.844623,32.456277]]]},"properties":{"id":"71109","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.830349,32.419425],[-93.771353,32.420648],[-93.755724,32.46922],[-93.75563,32.478915],[-93.780102,32.470954],[-93.830349,32.419425]]]},"properties":{"id":"71108","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.938496,32.443882],[-94.043041,32.50121],[-94.04298,32.388203],[-93.968485,32.413794],[-93.938496,32.443882]]]},"properties":{"id":"71033","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.04298,32.388203],[-94.042832,32.312464],[-94.024144,32.313876],[-93.968485,32.413794],[-94.04298,32.388203]]]},"properties":{"id":"71007","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.860327,32.253673],[-94.042648,32.224995],[-94.042719,32.086236],[-93.920976,32.08314],[-93.853696,32.158602],[-93.851613,32.214968],[-93.860327,32.253673]]]},"properties":{"id":"71046","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.501333,30.314071],[-93.508787,30.196862],[-93.325427,30.213227],[-93.273954,30.294487],[-93.283072,30.403058],[-93.501333,30.314071]]]},"properties":{"id":"70663","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.180124,30.197558],[-93.128834,30.176654],[-93.111908,30.306108],[-93.195834,30.266184],[-93.180124,30.197558]]]},"properties":{"id":"70615","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.398925,31.922065],[-92.313497,31.870084],[-92.264198,31.881279],[-92.290753,31.844266],[-92.264902,31.789924],[-92.142162,31.715349],[-92.008283,31.728683],[-91.989752,31.783561],[-92.005757,31.847915],[-92.005658,31.890655],[-92.262993,31.968667],[-92.312092,32.057755],[-92.398925,31.922065]]]},"properties":{"id":"71465","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.956991,29.928109],[-93.010461,29.722254],[-92.61772,29.578444],[-92.614377,29.766995],[-92.614143,29.774096],[-92.614935,29.919262],[-92.801791,29.976537],[-92.802853,29.976214],[-92.956991,29.928109]]]},"properties":{"id":"70643","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.010461,29.722254],[-92.956991,29.928109],[-93.079525,29.933756],[-93.228625,29.776856],[-93.010461,29.722254]]]},"properties":{"id":"70632","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.642264,31.48122],[-91.83428,31.266344],[-91.813067,31.248469],[-91.769042,31.325481],[-91.642264,31.48122]]]},"properties":{"id":"71354","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.157599,30.558874],[-91.211987,30.580502],[-91.314839,30.579791],[-91.311143,30.575237],[-91.197205,30.506864],[-91.149422,30.510262],[-91.157599,30.558874]]]},"properties":{"id":"70807","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.171802,30.416465],[-91.178078,30.411394],[-91.183837,30.404574],[-91.121293,30.364489],[-91.1081,30.397413],[-91.13011,30.433186],[-91.169114,30.432646],[-91.171802,30.416465]]]},"properties":{"id":"70808","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.954265,30.685374],[-90.959354,30.715582],[-91.026233,30.713184],[-90.954265,30.685374]]],[[[-90.959712,30.670452],[-91.034789,30.595581],[-91.021686,30.572012],[-90.959712,30.670452]]]]},"properties":{"id":"70770","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.405079,32.618862],[-91.596987,32.640898],[-91.58174,32.535094],[-91.366668,32.537837],[-91.405079,32.618862]]]},"properties":{"id":"71237","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.797526,30.437612],[-92.907026,30.383452],[-92.894935,30.372402],[-92.920515,30.360154],[-92.929329,30.344066],[-92.911383,30.246732],[-92.913573,30.216822],[-92.893772,30.156321],[-92.907195,30.129934],[-92.735895,30.177169],[-92.739163,30.187122],[-92.757675,30.320454],[-92.775047,30.378962],[-92.797526,30.437612]]]},"properties":{"id":"70591","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.003899,30.207934],[-92.086723,30.126102],[-92.079607,30.103834],[-92.074185,30.099829],[-91.9766,30.137895],[-91.978562,30.181105],[-91.976619,30.2109],[-92.003899,30.207934]]]},"properties":{"id":"70508","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.142162,31.715349],[-92.305002,31.587146],[-92.196131,31.477877],[-92.160512,31.466192],[-92.008283,31.728683],[-92.142162,31.715349]]]},"properties":{"id":"71342","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.381049,32.542102],[-92.26592,32.583452],[-92.219179,32.611476],[-92.457979,32.670266],[-92.381049,32.542102]]]},"properties":{"id":"71234","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.651383,32.525154],[-92.644321,32.523974],[-92.645051,32.528529],[-92.652094,32.529201],[-92.651383,32.525154]]]},"properties":{"id":"71272","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.73157,30.52366],[-90.756598,30.649963],[-90.835444,30.649625],[-90.89622,30.53487],[-90.846928,30.439726],[-90.73157,30.52366]]]},"properties":{"id":"70785","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.891,30.673185],[-90.980931,30.535555],[-90.980489,30.535575],[-90.963817,30.531688],[-90.89622,30.53487],[-90.835444,30.649625],[-90.821001,30.722693],[-90.839207,30.724545],[-90.891,30.673185]]]},"properties":{"id":"70706","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.782121,30.274843],[-90.632811,30.221409],[-90.560339,30.29676],[-90.677291,30.320916],[-90.738279,30.2865],[-90.782121,30.274843]]]},"properties":{"id":"70449","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.782121,30.274843],[-90.738279,30.2865],[-90.809931,30.35639],[-90.829065,30.317461],[-90.795737,30.282983],[-90.782121,30.274843]]]},"properties":{"id":"70733","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.069792,32.562803],[-91.306696,32.537861],[-91.339253,32.538202],[-91.290166,32.219574],[-91.124043,32.211104],[-91.069792,32.562803]]]},"properties":{"id":"71282","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.727378,32.849867],[-91.57941,32.886234],[-91.727425,32.927563],[-91.727378,32.849867]]]},"properties":{"id":"71223","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.137747,32.675465],[-91.967818,32.688277],[-92.061854,32.765637],[-92.19273,32.771518],[-92.137747,32.675465]]]},"properties":{"id":"71280","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.253597,31.53793],[-93.041844,31.596787],[-93.279528,31.887074],[-93.379943,31.878284],[-93.423163,31.666533],[-93.332313,31.538031],[-93.253597,31.53793]]]},"properties":{"id":"71469","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.840947,31.601233],[-92.949936,31.440854],[-92.943392,31.426826],[-92.850625,31.418875],[-92.86419,31.348857],[-92.90544,31.324642],[-92.829358,31.240748],[-92.814182,31.24884],[-92.681666,31.396009],[-92.798309,31.59497],[-92.840947,31.601233]]]},"properties":{"id":"71447","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.906231,31.656992],[-92.931995,31.608703],[-92.898957,31.576188],[-92.922438,31.547324],[-92.968275,31.564716],[-92.995204,31.573678],[-93.03525,31.507382],[-92.949936,31.440854],[-92.840947,31.601233],[-92.90241,31.656836],[-92.906231,31.656992]]]},"properties":{"id":"71416","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.997123,29.999313],[-90.010902,30.045972],[-90.038983,30.045094],[-90.044788,29.986058],[-90.021698,29.980719],[-89.997123,29.999313]]]},"properties":{"id":"70126","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.076716,29.966753],[-90.086345,29.954339],[-90.075562,29.950808],[-90.074931,29.950455],[-90.071815,29.949934],[-90.071472,29.950903],[-90.065658,29.95859],[-90.076716,29.966753]]]},"properties":{"id":"70112","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.071815,29.949934],[-90.070539,29.949666],[-90.070172,29.950603],[-90.071472,29.950903],[-90.071815,29.949934]]]},"properties":{"id":"70139","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.164744,32.276697],[-92.11535,32.491424],[-92.117099,32.495845],[-92.266,32.503109],[-92.335227,32.467928],[-92.36962,32.41622],[-92.37692,32.41376],[-92.284443,32.27703],[-92.164744,32.276697]]]},"properties":{"id":"71292","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.633246,29.454144],[-89.964478,29.67281],[-89.985107,29.56561],[-89.63536,29.446908],[-89.633246,29.454144]]]},"properties":{"id":"70083","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.800413,29.579859],[-89.752182,29.539027],[-89.745967,29.587715],[-89.808996,29.596464],[-89.800413,29.579859]]]},"properties":{"id":"70082","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.423597,30.604018],[-91.481767,30.625188],[-91.491894,30.626679],[-91.502136,30.550162],[-91.437477,30.542731],[-91.439861,30.543551],[-91.423597,30.604018]]]},"properties":{"id":"70762","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497298,31.227908],[-92.451786,31.326966],[-92.483235,31.333154],[-92.606669,31.245228],[-92.613307,31.216298],[-92.497298,31.227908]]]},"properties":{"id":"71303","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497298,31.227908],[-92.494298,31.223651],[-92.438278,31.310194],[-92.451786,31.326966],[-92.497298,31.227908]]]},"properties":{"id":"71301","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.497298,31.227908],[-92.613307,31.216298],[-92.613872,31.143734],[-92.627363,31.098155],[-92.470874,31.101099],[-92.459012,31.181259],[-92.494298,31.223651],[-92.497298,31.227908]]]},"properties":{"id":"71485","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.45015,31.505316],[-93.468482,31.496124],[-93.599674,31.328135],[-93.575194,31.229786],[-93.434577,31.290127],[-93.326166,31.364186],[-93.236104,31.364507],[-93.253597,31.53793],[-93.332313,31.538031],[-93.45015,31.505316]]]},"properties":{"id":"71429","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.327279,31.350697],[-93.434577,31.290127],[-93.575194,31.229786],[-93.519717,31.064908],[-93.327279,31.350697]]]},"properties":{"id":"71403","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.468482,31.496124],[-93.45015,31.505316],[-93.460747,31.513195],[-93.468482,31.496124]]]},"properties":{"id":"71426","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.321891,29.932971],[-90.275527,29.940189],[-90.278358,29.962472],[-90.321891,29.932971]]]},"properties":{"id":"70031","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.53292,30.824794],[-90.510245,30.818831],[-90.443612,30.7911],[-90.279311,30.791812],[-90.346748,30.912005],[-90.347255,31.00053],[-90.691575,30.999429],[-90.64168,30.824472],[-90.567171,30.824806],[-90.53292,30.824794]]]},"properties":{"id":"70444","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.779739,30.055852],[-90.784584,30.044006],[-90.753935,30.01819],[-90.70016,30.033412],[-90.723469,30.069923],[-90.779739,30.055852]]]},"properties":{"id":"70763","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.688996,30.040367],[-90.712142,30.077486],[-90.723469,30.069923],[-90.70016,30.033412],[-90.688996,30.040367]]]},"properties":{"id":"70071","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.580616,29.863042],[-91.526774,29.85957],[-91.544683,29.864249],[-91.580616,29.863042]]]},"properties":{"id":"70514","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.277987,30.505382],[-90.053948,30.482708],[-89.976545,30.553168],[-90.079913,30.68065],[-90.121842,30.688367],[-90.272503,30.535719],[-90.277987,30.505382]]]},"properties":{"id":"70435","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.762625,30.330431],[-89.868766,30.246282],[-89.796377,30.209823],[-89.737537,30.323276],[-89.762625,30.330431]]]},"properties":{"id":"70458","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.471879,30.516843],[-90.471937,30.513285],[-90.468095,30.513404],[-90.468507,30.519194],[-90.471879,30.516843]]]},"properties":{"id":"70402","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.575721,31.88256],[-91.466306,31.75097],[-91.394614,31.747263],[-91.349159,31.845282],[-91.478668,32.05012],[-91.501973,32.048766],[-91.575721,31.88256]]]},"properties":{"id":"71375","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.478668,32.05012],[-91.18031,31.970057],[-91.124043,32.211104],[-91.290166,32.219574],[-91.492718,32.203349],[-91.489954,32.089188],[-91.501973,32.048766],[-91.478668,32.05012]]]},"properties":{"id":"71357","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.702847,29.281259],[-90.409404,29.03972],[-90.409423,29.192783],[-90.58354,29.512674],[-90.59397,29.535135],[-90.675655,29.433449],[-90.702847,29.281259]]]},"properties":{"id":"70344","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.727622,29.397833],[-90.702847,29.281259],[-90.675655,29.433449],[-90.722566,29.430049],[-90.727622,29.397833]]]},"properties":{"id":"70353","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.193906,30.020739],[-92.267211,29.758018],[-92.074361,29.804871],[-91.997965,30.017588],[-92.06668,30.035354],[-92.193906,30.020739]],[[-92.159409,29.948883],[-92.158783,29.950453],[-92.156103,29.949617],[-92.157092,29.947301],[-92.159409,29.948883]]]},"properties":{"id":"70510","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.270704,31.116575],[-93.303445,31.131026],[-93.286463,31.107591],[-93.270704,31.116575]]]},"properties":{"id":"71461","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.121842,30.688367],[-90.079913,30.68065],[-90.044742,30.685475],[-89.922895,30.887882],[-89.94011,31.001507],[-90.194392,31.000753],[-90.346748,30.912005],[-90.279311,30.791812],[-90.255648,30.711905],[-90.121842,30.688367]]]},"properties":{"id":"70438","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.237934,32.856526],[-93.238607,33.017992],[-93.392059,33.018296],[-93.396168,32.994237],[-93.376582,32.882123],[-93.336852,32.829324],[-93.237934,32.856526]]]},"properties":{"id":"71072","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.515476,30.872403],[-91.581276,30.74139],[-91.534111,30.734255],[-91.353092,30.72177],[-91.342317,30.677331],[-91.33087,30.658677],[-91.310061,30.653961],[-91.18797,30.935608],[-91.17614,30.99908],[-91.493546,30.999015],[-91.515476,30.872403]],[[-91.466183,30.953344],[-91.446168,30.983252],[-91.425501,30.959883],[-91.466183,30.953344]]]},"properties":{"id":"70775","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.536469,32.017422],[-92.522524,32.146495],[-92.791563,32.146597],[-92.800576,32.057843],[-92.536469,32.017422]]]},"properties":{"id":"71422","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.420635,31.916175],[-92.536469,32.017422],[-92.800576,32.057843],[-92.929997,31.854091],[-92.924441,31.784644],[-92.879742,31.810272],[-92.67218,31.709498],[-92.619728,31.761158],[-92.384835,31.796668],[-92.420635,31.916175]],[[-92.777207,31.959053],[-92.7736,31.97079],[-92.758759,31.968836],[-92.759968,31.963035],[-92.771002,31.964138],[-92.777207,31.959053]]]},"properties":{"id":"71483","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.529192,30.387996],[-92.625161,30.353776],[-92.559461,30.27616],[-92.530994,30.270526],[-92.474508,30.259991],[-92.408857,30.363447],[-92.529192,30.387996]]]},"properties":{"id":"70543","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.279714,30.538767],[-92.347151,30.409634],[-92.235173,30.321358],[-92.173101,30.287733],[-92.142184,30.298817],[-92.116008,30.327987],[-92.150858,30.422338],[-92.25412,30.517916],[-92.279714,30.538767]]]},"properties":{"id":"70525","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.463422,30.197939],[-92.447291,30.139555],[-92.408419,30.235591],[-92.463422,30.197939]]]},"properties":{"id":"70534","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.598299,30.65924],[-92.446953,30.71481],[-92.502091,30.899494],[-92.707607,30.921246],[-92.720971,30.891893],[-92.77236,30.817559],[-92.598299,30.65924]]]},"properties":{"id":"71463","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.893414,30.698543],[-92.868287,30.621639],[-92.836549,30.653171],[-92.896426,30.712187],[-92.893414,30.698543]]]},"properties":{"id":"70654","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.977324,30.724144],[-93.046026,30.657323],[-92.978718,30.626906],[-92.978717,30.626973],[-92.893414,30.698543],[-92.896426,30.712187],[-92.918073,30.757749],[-92.976886,30.77404],[-92.977324,30.724144]]]},"properties":{"id":"70637","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.869709,30.208108],[-90.918861,30.149789],[-90.902381,30.115629],[-90.785217,30.138422],[-90.869709,30.208108]]]},"properties":{"id":"70778","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.927177,29.715915],[-90.782853,29.730329],[-90.73966,29.683569],[-90.703859,29.687087],[-90.524953,29.782383],[-90.478451,29.836569],[-90.656312,29.889246],[-90.706022,29.901038],[-90.885589,29.905353],[-90.953649,29.852922],[-91.006743,29.714771],[-90.927177,29.715915]]]},"properties":{"id":"70301","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.224356,30.025336],[-91.179313,29.728191],[-91.109462,29.823945],[-91.138102,29.963713],[-91.16024,30.045548],[-91.221849,30.026129],[-91.224356,30.025336]]]},"properties":{"id":"70339","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.23273,31.255678],[-92.192468,31.217197],[-92.1903,31.25128],[-92.23273,31.255678]]]},"properties":{"id":"71323","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.978234,30.861198],[-92.977324,30.724144],[-92.976886,30.77404],[-92.976699,30.825259],[-92.978234,30.861198]]]},"properties":{"id":"70662","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.203942,32.283805],[-93.090918,32.319423],[-93.091654,32.36877],[-93.156939,32.387754],[-93.236693,32.411506],[-93.251714,32.365459],[-93.203942,32.283805]]]},"properties":{"id":"71045","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.813665,32.641983],[-92.908424,32.430416],[-92.894317,32.360629],[-92.805807,32.454011],[-92.756997,32.532419],[-92.72003,32.542364],[-92.724701,32.631278],[-92.813665,32.641983]]]},"properties":{"id":"71275","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.779278,32.672796],[-93.744233,32.51967],[-93.724807,32.508766],[-93.619898,32.541717],[-93.627598,32.585163],[-93.779278,32.672796]]]},"properties":{"id":"71111","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.392059,33.018296],[-93.599575,33.019047],[-93.520095,32.957528],[-93.45548,32.968268],[-93.437281,32.965769],[-93.396168,32.994237],[-93.392059,33.018296]]]},"properties":{"id":"71075","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.609463,32.531097],[-93.601358,32.460737],[-93.531255,32.468161],[-93.532773,32.530499],[-93.609463,32.531097]]]},"properties":{"id":"71110","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.872953,32.804249],[-93.960739,32.807958],[-93.972585,32.705697],[-93.92907,32.770294],[-93.872953,32.804249]]]},"properties":{"id":"71061","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.830537,32.419244],[-93.844623,32.456277],[-93.938496,32.443882],[-93.968485,32.413794],[-94.024144,32.313876],[-93.844688,32.391928],[-93.830537,32.419244]]]},"properties":{"id":"71129","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.508787,30.196862],[-93.501333,30.314071],[-93.519936,30.316476],[-93.700817,30.166226],[-93.722517,30.050872],[-93.602369,30.052057],[-93.508787,30.196862]]]},"properties":{"id":"70668","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.508787,30.196862],[-93.602369,30.052057],[-93.530545,30.051755],[-93.312527,30.052102],[-93.308991,30.183642],[-93.325427,30.213227],[-93.508787,30.196862]]]},"properties":{"id":"70665","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.273954,30.294487],[-93.325427,30.213227],[-93.308991,30.183642],[-93.277053,30.197927],[-93.207486,30.26185],[-93.273954,30.294487]]]},"properties":{"id":"70669","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.214031,30.19717],[-93.277053,30.197927],[-93.308991,30.183642],[-93.312527,30.052102],[-93.300963,30.051953],[-93.214031,30.19717]]]},"properties":{"id":"70605","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.005757,31.847915],[-91.908693,31.811175],[-91.927398,31.854608],[-92.005618,31.897191],[-92.005658,31.890655],[-92.005757,31.847915]]],[[[-91.975814,31.927562],[-92.311912,32.216459],[-92.312218,32.146123],[-92.312092,32.057755],[-92.262993,31.968667],[-92.005434,31.927296],[-91.975814,31.927562]]]]},"properties":{"id":"71435","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.005757,31.847915],[-91.989752,31.783561],[-91.89573,31.76256],[-91.908693,31.811175],[-92.005757,31.847915]]]},"properties":{"id":"71401","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.849031,32.986876],[-92.76736,32.99886],[-92.7674,33.0149],[-92.8567,33.01626],[-92.849031,32.986876]]],[[[-92.771899,32.931805],[-92.628481,32.900067],[-92.690184,32.999747],[-92.746541,32.99634],[-92.771899,32.931805]]]]},"properties":{"id":"71256","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.757901,32.809139],[-92.828049,32.714388],[-92.813665,32.641983],[-92.724701,32.631278],[-92.537393,32.641793],[-92.518374,32.675389],[-92.548635,32.720659],[-92.757901,32.809139]]]},"properties":{"id":"71235","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.466306,31.75097],[-91.713124,31.679677],[-91.727768,31.621432],[-91.586357,31.516973],[-91.502253,31.595396],[-91.394614,31.747263],[-91.466306,31.75097]]]},"properties":{"id":"71334","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.769042,31.325481],[-91.813067,31.248469],[-91.680219,31.190222],[-91.769042,31.325481]]]},"properties":{"id":"71316","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.763631,32.344738],[-93.860327,32.253673],[-93.851613,32.214968],[-93.7176,32.226563],[-93.75151,32.339821],[-93.763631,32.344738]]]},"properties":{"id":"71078","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.042719,32.086236],[-93.902423,31.89667],[-93.893121,31.961556],[-93.920976,32.08314],[-94.042719,32.086236]]]},"properties":{"id":"71049","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.7176,32.226563],[-93.750516,32.168356],[-93.614227,32.171829],[-93.554768,32.192312],[-93.686834,32.316747],[-93.75151,32.339821],[-93.7176,32.226563]]]},"properties":{"id":"71027","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.169114,30.432646],[-91.13011,30.433186],[-91.086387,30.430944],[-91.10887,30.47128],[-91.109044,30.471275],[-91.149533,30.470352],[-91.169114,30.432646]]]},"properties":{"id":"70806","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.142305,30.319893],[-91.125283,30.313232],[-91.021014,30.321489],[-91.006339,30.337795],[-91.1081,30.397413],[-91.121293,30.364489],[-91.151521,30.337809],[-91.142105,30.323293],[-91.142305,30.319893]]]},"properties":{"id":"70810","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.032815,30.474452],[-91.10887,30.47128],[-91.086387,30.430944],[-91.080784,30.431644],[-91.027189,30.452283],[-91.032815,30.474452]]]},"properties":{"id":"70815","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.13545,30.506304],[-91.149422,30.510262],[-91.197205,30.506864],[-91.19653,30.469365],[-91.149533,30.470352],[-91.109044,30.471275],[-91.113693,30.480672],[-91.13545,30.506304]]]},"properties":{"id":"70805","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.195808,30.718684],[-91.026233,30.713184],[-90.959354,30.715582],[-90.950306,30.715883],[-91.042042,30.809496],[-91.174495,30.761289],[-91.195808,30.718684]]]},"properties":{"id":"70777","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.176426,30.417259],[-91.178078,30.411394],[-91.171802,30.416465],[-91.176426,30.417259]]]},"properties":{"id":"70803","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.398254,31.047219],[-92.431149,30.97094],[-92.411027,30.888852],[-92.410831,30.891432],[-92.357379,30.94102],[-92.280738,30.965071],[-92.227124,31.074759],[-92.228138,31.083146],[-92.251532,31.091273],[-92.398254,31.047219]]]},"properties":{"id":"71325","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.432407,30.673455],[-92.425132,30.673944],[-92.426386,30.678004],[-92.432365,30.678037],[-92.432407,30.673455]]]},"properties":{"id":"70580","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.798309,31.59497],[-92.758038,31.655035],[-92.879742,31.810272],[-92.924441,31.784644],[-92.90241,31.656836],[-92.840947,31.601233],[-92.798309,31.59497]]]},"properties":{"id":"71454","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.983341,29.95759],[-91.982594,29.912459],[-91.972233,29.941167],[-91.983341,29.95759]]]},"properties":{"id":"70528","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.91566,29.909877],[-91.914447,29.896737],[-91.892667,29.894256],[-91.8874,29.906263],[-91.91566,29.909877]]]},"properties":{"id":"70513","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.757446,30.266718],[-91.910617,30.1893],[-91.908545,30.091167],[-91.883612,30.063666],[-91.815989,30.047686],[-91.715218,30.090454],[-91.657643,30.106272],[-91.620576,30.069584],[-91.617573,30.068975],[-91.757446,30.266718]]]},"properties":{"id":"70582","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.433597,30.453309],[-91.48886,30.407498],[-91.407953,30.426289],[-91.433597,30.453309]]]},"properties":{"id":"70772","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.525337,32.383589],[-92.312009,32.246527],[-92.284443,32.27703],[-92.37692,32.41376],[-92.415378,32.457311],[-92.512139,32.395919],[-92.525337,32.383589]]]},"properties":{"id":"71238","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.240501,29.965255],[-90.169303,29.94419],[-90.205909,29.973478],[-90.237625,29.975555],[-90.240501,29.965255]]]},"properties":{"id":"70123","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.019828,30.21793],[-92.110546,30.130759],[-92.086723,30.126102],[-92.003899,30.207934],[-92.019828,30.21793]]]},"properties":{"id":"70503","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.078239,29.466125],[-90.377943,29.309222],[-90.409423,29.192783],[-90.409404,29.03972],[-90.088723,29.162622],[-90.059921,29.219289],[-90.078239,29.466125]]]},"properties":{"id":"70357","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.454141,29.557682],[-90.441512,29.621235],[-90.528173,29.681192],[-90.541385,29.67761],[-90.578176,29.58906],[-90.589067,29.57767],[-90.454141,29.557682]]]},"properties":{"id":"70374","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.189079,29.68325],[-90.411266,29.518902],[-90.18782,29.546567],[-90.189079,29.68325]]]},"properties":{"id":"70345","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.315965,29.692448],[-90.441512,29.621235],[-90.454141,29.557682],[-90.489062,29.519344],[-90.43767,29.463609],[-90.411266,29.518902],[-90.189079,29.68325],[-90.315965,29.692448]]]},"properties":{"id":"70373","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.524953,29.782383],[-90.504815,29.766042],[-90.397295,29.770378],[-90.373847,29.85901],[-90.413131,29.860572],[-90.462364,29.873619],[-90.478451,29.836569],[-90.524953,29.782383]]]},"properties":{"id":"70030","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.384835,31.796668],[-92.344955,31.754846],[-92.264902,31.789924],[-92.290753,31.844266],[-92.313497,31.870084],[-92.398925,31.922065],[-92.420635,31.916175],[-92.384835,31.796668]]]},"properties":{"id":"71479","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.963817,30.531688],[-90.980489,30.535575],[-90.980931,30.535555],[-90.974807,30.481763],[-90.989957,30.464711],[-90.969401,30.405138],[-90.891728,30.345244],[-90.829065,30.317461],[-90.809931,30.35639],[-90.846928,30.439726],[-90.89622,30.53487],[-90.963817,30.531688]]]},"properties":{"id":"70726","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.727425,32.927563],[-91.57941,32.886234],[-91.500993,32.88664],[-91.435782,33.006099],[-91.7307,33.007483],[-91.727425,32.927563]]]},"properties":{"id":"71250","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.492215,31.748803],[-93.48072,31.70613],[-93.423163,31.666533],[-93.379943,31.878284],[-93.426881,31.889579],[-93.492215,31.748803]]]},"properties":{"id":"71450","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.052331,29.958381],[-90.013871,29.947003],[-90.012102,29.94598],[-90.01048,29.9496],[-89.99681,29.973186],[-90.021698,29.980719],[-90.044788,29.986058],[-90.058122,29.985172],[-90.059921,29.97856],[-90.052331,29.958381]]]},"properties":{"id":"70117","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.381049,32.542102],[-92.415378,32.457311],[-92.37692,32.41376],[-92.36962,32.41622],[-92.335227,32.467928],[-92.266,32.503109],[-92.26592,32.583452],[-92.381049,32.542102]]]},"properties":{"id":"71225","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.592928,30.570594],[-91.566666,30.54183],[-91.502136,30.550162],[-91.491894,30.626679],[-91.531009,30.654621],[-91.55477,30.632866],[-91.592928,30.570594]]]},"properties":{"id":"70755","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.355557,30.626778],[-91.387544,30.60316],[-91.365088,30.58805],[-91.34212,30.61013],[-91.355557,30.626778]]]},"properties":{"id":"70773","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.814182,31.24884],[-92.829358,31.240748],[-92.829148,31.194801],[-92.742153,31.188211],[-92.774691,31.232685],[-92.814182,31.24884]]]},"properties":{"id":"71472","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.639192,31.709418],[-93.774269,31.698695],[-93.760872,31.599783],[-93.639192,31.709418]]]},"properties":{"id":"71462","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.760872,31.599783],[-93.67263,31.535884],[-93.494676,31.69431],[-93.522358,31.749608],[-93.597234,31.765837],[-93.639192,31.709418],[-93.760872,31.599783]]]},"properties":{"id":"71486","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.899,29.930744],[-89.912622,29.913566],[-89.899323,29.876617],[-89.875363,29.880627],[-89.899,29.930744]]]},"properties":{"id":"70092","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.341789,29.981658],[-90.344299,29.937725],[-90.280296,29.972618],[-90.280057,29.991706],[-90.341789,29.981658]]]},"properties":{"id":"70087","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.224441,29.718473],[-91.288225,29.702088],[-91.343843,29.766087],[-91.372584,29.698468],[-91.293646,29.658633],[-91.264768,29.709642],[-91.224441,29.718473]]]},"properties":{"id":"70392","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.856463,29.737749],[-91.520785,29.885718],[-91.526774,29.85957],[-91.580616,29.863042],[-91.732892,29.830023],[-91.856463,29.737749]]]},"properties":{"id":"70538","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.252114,30.693431],[-90.286471,30.541563],[-90.272503,30.535719],[-90.121842,30.688367],[-90.255648,30.711905],[-90.252114,30.693431]]]},"properties":{"id":"70437","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.799557,30.339754],[-89.952255,30.25572],[-89.868766,30.246282],[-89.762625,30.330431],[-89.799557,30.339754]]]},"properties":{"id":"70460","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.785572,30.825859],[-89.922895,30.887882],[-90.044742,30.685475],[-89.869069,30.62426],[-89.785572,30.825859]],[[-89.913179,30.662122],[-89.897499,30.665075],[-89.903401,30.65241],[-89.913179,30.662122]]]},"properties":{"id":"70427","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.476426,30.545868],[-90.479884,30.552687],[-90.485719,30.545462],[-90.476426,30.545868]]]},"properties":{"id":"70451","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.822068,29.451502],[-90.727622,29.397833],[-90.722566,29.430049],[-90.822068,29.451502]]]},"properties":{"id":"70397","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.19273,32.771518],[-92.48069,32.934963],[-92.512333,32.820494],[-92.548635,32.720659],[-92.518374,32.675389],[-92.457979,32.670266],[-92.219179,32.611476],[-92.138119,32.66077],[-92.137747,32.675465],[-92.19273,32.771518]]]},"properties":{"id":"71241","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.206697,31.067763],[-93.186436,31.116026],[-93.235316,31.116474],[-93.218427,31.029063],[-93.206697,31.067763]]]},"properties":{"id":"71459","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.000112,31.248619],[-92.993434,31.315003],[-93.052646,31.322418],[-93.000112,31.248619]]]},"properties":{"id":"71474","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.922895,30.887882],[-89.785572,30.825859],[-89.94011,31.001507],[-89.922895,30.887882]]]},"properties":{"id":"70426","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.637587,32.667341],[-91.596987,32.640898],[-91.405079,32.618862],[-91.364203,32.729107],[-91.596081,32.754798],[-91.629371,32.697685],[-91.637587,32.667341]]]},"properties":{"id":"71266","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.568055,30.940025],[-91.590969,30.917592],[-91.515476,30.872403],[-91.493546,30.999015],[-91.538661,30.999388],[-91.568055,30.940025]]]},"properties":{"id":"70782","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.536469,32.017422],[-92.420635,31.916175],[-92.398925,31.922065],[-92.312092,32.057755],[-92.312218,32.146123],[-92.481588,32.146521],[-92.522524,32.146495],[-92.536469,32.017422]]]},"properties":{"id":"71473","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.173101,30.287733],[-92.193096,30.247829],[-92.112945,30.211919],[-92.049505,30.247104],[-92.101837,30.29085],[-92.142184,30.298817],[-92.173101,30.287733]]]},"properties":{"id":"70583","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.004796,30.598687],[-92.974421,30.471715],[-92.978717,30.626973],[-92.978718,30.626906],[-93.004796,30.598687]]]},"properties":{"id":"70651","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.105951,30.062478],[-91.16024,30.045548],[-91.138102,29.963713],[-91.064388,30.001822],[-91.05404,29.993308],[-91.029334,29.989423],[-90.999636,30.012088],[-90.959724,30.006507],[-90.963693,30.06645],[-91.105951,30.062478]]]},"properties":{"id":"70341","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.029334,29.989423],[-90.992282,29.96885],[-90.999636,30.012088],[-91.029334,29.989423]]]},"properties":{"id":"70393","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.212905,32.199281],[-93.187162,32.148412],[-92.997126,32.236964],[-93.090918,32.319423],[-93.203942,32.283805],[-93.212905,32.199281]]]},"properties":{"id":"71016","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.364369,32.410095],[-93.251714,32.365459],[-93.236693,32.411506],[-93.28187,32.505648],[-93.353252,32.454123],[-93.364369,32.410095]]]},"properties":{"id":"71039","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.445317,32.644006],[-93.446147,32.535523],[-93.330258,32.536749],[-93.249578,32.559056],[-93.173917,32.565232],[-93.147784,32.584885],[-93.10983,32.650822],[-93.237808,32.825465],[-93.237934,32.856526],[-93.336852,32.829324],[-93.455263,32.761131],[-93.445317,32.644006]]]},"properties":{"id":"71055","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.437281,32.965769],[-93.44585,32.960648],[-93.45548,32.968268],[-93.520095,32.957528],[-93.555544,32.906817],[-93.376582,32.882123],[-93.396168,32.994237],[-93.437281,32.965769]]]},"properties":{"id":"71071","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.755343,32.496951],[-93.78028,32.510916],[-93.79838,32.512831],[-93.780102,32.470954],[-93.75563,32.478915],[-93.755343,32.496951]]]},"properties":{"id":"71103","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.13112,30.397699],[-93.111908,30.306108],[-93.128834,30.176654],[-93.128674,30.103659],[-92.893772,30.156321],[-92.913573,30.216822],[-92.929038,30.238078],[-92.911383,30.246732],[-92.929329,30.344066],[-93.037136,30.418182],[-93.13112,30.397699]]]},"properties":{"id":"70647","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.909418,30.120666],[-92.949854,30.08223],[-92.887605,30.082475],[-92.909418,30.120666]]]},"properties":{"id":"70646","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.862842,31.965659],[-91.894729,32.15155],[-91.955066,32.269371],[-92.034397,32.277068],[-92.164744,32.276697],[-92.284443,32.27703],[-92.312009,32.246527],[-92.311912,32.216459],[-91.975814,31.927562],[-91.919147,31.917472],[-91.862842,31.965659]]]},"properties":{"id":"71418","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.801791,29.976537],[-92.614935,29.919262],[-92.408349,30.053721],[-92.625484,30.09233],[-92.627799,30.092128],[-92.801791,29.976537]]]},"properties":{"id":"70542","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.694521,31.765733],[-91.597117,31.884713],[-91.6475,31.970428],[-91.776973,31.873386],[-91.694521,31.765733]]]},"properties":{"id":"71368","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.919147,31.917472],[-91.927398,31.854608],[-91.908693,31.811175],[-91.89573,31.76256],[-91.727768,31.621432],[-91.713124,31.679677],[-91.694521,31.765733],[-91.776973,31.873386],[-91.779711,31.888523],[-91.862842,31.965659],[-91.919147,31.917472]]]},"properties":{"id":"71340","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.008121,31.325797],[-91.994828,31.229561],[-91.83428,31.266344],[-91.642264,31.48122],[-91.586357,31.516973],[-91.727768,31.621432],[-91.89573,31.76256],[-91.989752,31.783561],[-92.008283,31.728683],[-92.160512,31.466192],[-92.008121,31.325797]],[[-91.790423,31.615699],[-91.781553,31.613544],[-91.78891,31.605097],[-91.790423,31.615699]]]},"properties":{"id":"71343","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.927398,31.854608],[-91.919147,31.917472],[-91.975814,31.927562],[-92.005434,31.927296],[-92.005618,31.897191],[-91.927398,31.854608]]]},"properties":{"id":"71425","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.237808,32.825465],[-93.10983,32.650822],[-92.95346,32.66413],[-92.828049,32.714388],[-92.757901,32.809139],[-92.774036,32.834375],[-92.897199,32.881828],[-93.237808,32.825465]]]},"properties":{"id":"71040","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.95346,32.66413],[-93.10983,32.650822],[-93.147784,32.584885],[-93.002742,32.584095],[-92.95346,32.66413]]]},"properties":{"id":"71003","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.897199,32.881828],[-92.774036,32.834375],[-92.832639,32.860419],[-92.897199,32.881828]]]},"properties":{"id":"71048","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.760841,32.335355],[-91.771539,32.248052],[-91.636226,32.304994],[-91.634931,32.405371],[-91.760841,32.335355]]]},"properties":{"id":"71219","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.290166,32.219574],[-91.339253,32.538202],[-91.366668,32.537837],[-91.58174,32.535094],[-91.634931,32.405371],[-91.636226,32.304994],[-91.492718,32.203349],[-91.290166,32.219574]]]},"properties":{"id":"71232","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.649776,30.080676],[-91.715218,30.090454],[-91.815989,30.047686],[-91.753719,29.979233],[-91.639362,29.975937],[-91.649776,30.080676]]]},"properties":{"id":"70563","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.997965,30.017588],[-92.074361,29.804871],[-91.972585,29.837032],[-91.982594,29.912459],[-91.983341,29.95759],[-91.989247,30.006196],[-91.997965,30.017588]]]},"properties":{"id":"70533","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.572245,32.312809],[-92.481588,32.146521],[-92.312218,32.146123],[-92.311912,32.216459],[-92.312009,32.246527],[-92.525337,32.383589],[-92.570505,32.384363],[-92.572245,32.312809]]]},"properties":{"id":"71226","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.694074,32.278337],[-92.740038,32.279944],[-92.721086,32.259382],[-92.694074,32.278337]]]},"properties":{"id":"71247","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.071672,29.919304],[-90.044141,29.883012],[-90.041871,29.929013],[-90.058547,29.945334],[-90.071672,29.919304]]]},"properties":{"id":"70053","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.141052,29.74031],[-90.115905,29.667259],[-90.134284,29.742677],[-90.141052,29.74031]]]},"properties":{"id":"70036","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.049505,30.247104],[-92.112945,30.211919],[-92.120185,30.127657],[-92.110546,30.130759],[-92.019828,30.21793],[-92.036005,30.250639],[-92.049505,30.247104]]]},"properties":{"id":"70506","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.680314,30.394716],[-91.802826,30.394285],[-91.964352,30.36142],[-91.972576,30.293312],[-91.949944,30.280972],[-91.976619,30.2109],[-91.978562,30.181105],[-91.910617,30.1893],[-91.757446,30.266718],[-91.680314,30.394716]]]},"properties":{"id":"70517","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.541385,29.67761],[-90.523923,29.698388],[-90.504815,29.766042],[-90.524953,29.782383],[-90.703859,29.687087],[-90.578176,29.58906],[-90.541385,29.67761]]]},"properties":{"id":"70394","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.290753,31.844266],[-92.264198,31.881279],[-92.313497,31.870084],[-92.290753,31.844266]]]},"properties":{"id":"71480","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.756997,32.532419],[-92.726985,32.510032],[-92.72003,32.542364],[-92.756997,32.532419]]]},"properties":{"id":"71245","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.361707,30.506844],[-90.567016,30.50433],[-90.596393,30.476294],[-90.573425,30.44929],[-90.540159,30.436544],[-90.338668,30.480498],[-90.361707,30.506844]]]},"properties":{"id":"70403","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.927835,32.330911],[-90.941593,32.329076],[-90.934599,32.321738],[-90.927835,32.330911]]]},"properties":{"id":"71233","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.061854,32.765637],[-91.967818,32.688277],[-91.952449,32.671798],[-91.814313,32.688574],[-91.727378,32.849867],[-91.727425,32.927563],[-91.7307,33.007483],[-92.069105,33.008163],[-92.061854,32.765637]]]},"properties":{"id":"71220","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.4557,32.235957],[-93.444481,32.072423],[-93.441766,31.989358],[-93.438357,31.903294],[-93.426881,31.889579],[-93.379943,31.878284],[-93.279528,31.887074],[-93.239499,31.903605],[-93.121188,31.97261],[-93.187162,32.148412],[-93.212905,32.199281],[-93.29298,32.177565],[-93.318537,32.17022],[-93.311145,32.199295],[-93.42819,32.235897],[-93.4557,32.235957]]]},"properties":{"id":"71019","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.86419,31.348857],[-92.943392,31.426826],[-92.949936,31.440854],[-93.03525,31.507382],[-93.05655,31.509515],[-92.991988,31.360473],[-92.90544,31.324642],[-92.86419,31.348857]]]},"properties":{"id":"71455","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.906231,31.656992],[-92.995204,31.573678],[-92.968275,31.564716],[-92.931995,31.608703],[-92.906231,31.656992]]]},"properties":{"id":"71456","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.943392,31.426826],[-92.86419,31.348857],[-92.850625,31.418875],[-92.943392,31.426826]]]},"properties":{"id":"71427","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.044788,29.986058],[-90.038983,30.045094],[-90.08247,30.031538],[-90.086291,29.992683],[-90.058122,29.985172],[-90.044788,29.986058]]]},"properties":{"id":"70122","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.086291,29.992683],[-90.113699,29.981151],[-90.107523,29.9679],[-90.089313,29.954342],[-90.086345,29.954339],[-90.076716,29.966753],[-90.059921,29.97856],[-90.058122,29.985172],[-90.086291,29.992683]]]},"properties":{"id":"70119","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.010902,30.045972],[-89.997123,29.999313],[-89.952298,30.022586],[-89.987379,30.053836],[-90.010902,30.045972]]]},"properties":{"id":"70127","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.059921,29.97856],[-90.076716,29.966753],[-90.065658,29.95859],[-90.058641,29.953149],[-90.052331,29.958381],[-90.059921,29.97856]]]},"properties":{"id":"70116","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.406269,29.338885],[-89.441772,29.349882],[-89.374285,29.303213],[-89.406269,29.338885]]]},"properties":{"id":"70038","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.534111,30.734255],[-91.531009,30.654621],[-91.491894,30.626679],[-91.481767,30.625188],[-91.471168,30.625188],[-91.353092,30.72177],[-91.534111,30.734255]]]},"properties":{"id":"70760","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.802826,30.394285],[-91.680314,30.394716],[-91.557819,30.488518],[-91.599668,30.520789],[-91.658371,30.556802],[-91.749501,30.546472],[-91.813302,30.615371],[-91.869138,30.587938],[-91.859674,30.451235],[-91.802826,30.394285]]]},"properties":{"id":"70750","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.368108,31.445582],[-92.447482,31.429222],[-92.406105,31.370815],[-92.368108,31.445582]]]},"properties":{"id":"71405","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.99681,29.973186],[-90.01048,29.9496],[-89.994349,29.940298],[-89.984245,29.968442],[-89.996452,29.97304],[-89.99681,29.973186]]]},"properties":{"id":"70032","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.945365,29.982401],[-89.984245,29.968442],[-89.994349,29.940298],[-89.94857,29.92587],[-89.93032,29.949631],[-89.94067,29.980245],[-89.945365,29.982401]]]},"properties":{"id":"70043","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.899,29.930744],[-89.93032,29.949631],[-89.94857,29.92587],[-89.912622,29.913566],[-89.899,29.930744]]]},"properties":{"id":"70075","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.538391,30.013401],[-90.459991,29.894197],[-90.418235,29.92613],[-90.396103,29.949688],[-90.47966,30.028673],[-90.538391,30.013401]]]},"properties":{"id":"70057","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.349423,29.69539],[-90.241615,29.868602],[-90.275527,29.940189],[-90.321891,29.932971],[-90.396103,29.949688],[-90.418235,29.92613],[-90.407478,29.906995],[-90.373847,29.85901],[-90.397295,29.770378],[-90.349423,29.69539]]]},"properties":{"id":"70070","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.407478,29.906995],[-90.413131,29.860572],[-90.373847,29.85901],[-90.407478,29.906995]]]},"properties":{"id":"70039","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.443612,30.7911],[-90.567171,30.824806],[-90.64168,30.824472],[-90.676878,30.720288],[-90.727996,30.710532],[-90.634603,30.649827],[-90.447906,30.685944],[-90.35304,30.693412],[-90.298597,30.678384],[-90.252114,30.693431],[-90.255648,30.711905],[-90.279311,30.791812],[-90.443612,30.7911]]]},"properties":{"id":"70422","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.943846,30.81634],[-91.813302,30.615371],[-91.749501,30.546472],[-91.815258,30.848107],[-91.948327,30.848841],[-91.943846,30.81634]]]},"properties":{"id":"71353","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.802826,30.394285],[-91.859674,30.451235],[-91.950912,30.504378],[-92.043625,30.436145],[-92.038705,30.412321],[-92.041512,30.375624],[-91.964352,30.36142],[-91.802826,30.394285]]]},"properties":{"id":"70512","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.109476,29.66876],[-91.101655,29.668525],[-91.101469,29.670741],[-91.108701,29.671081],[-91.109476,29.66876]]]},"properties":{"id":"70340","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.124568,30.418609],[-90.244346,30.479738],[-90.243789,30.402374],[-90.162483,30.37099],[-90.124568,30.418609]]]},"properties":{"id":"70447","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.053948,30.482708],[-90.041347,30.440255],[-89.975472,30.421582],[-89.879834,30.499453],[-89.942441,30.561429],[-89.976545,30.553168],[-90.053948,30.482708]]]},"properties":{"id":"70420","state_code":"22"}},
]};

export default map;